function makeConditionalGridObject(object) {
    if (object.hide !== true) {
        let returnObject = JSON.parse(JSON.stringify(object));
        let returnObjectVariables = returnObject;
        for (let i = 0; i < returnObjectVariables.length; i++) {
            if (returnObjectVariables[i]["grid"] === 4) {
                returnObjectVariables[i]["grid"] = 2;
            }
        }
        return returnObject;
    }
}

function makeReadOnlyObject(object) {
    let returnObject = JSON.parse(JSON.stringify(object));
    let returnObjectVariables = returnObject;
    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["readOnly"] = true;
        returnObjectVariables[i]["required"] = false;
    }
    return returnObject;
}


const loanCLosingJson = [
    {
        varName: "customerCB",
        type: "textApiCall",
        label: "Customer CB",
        required: true,
        grid: 2,
    },
    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
        grid: 2,
    },
    {
        varName: "loanAccounts",
        type: "select",
        label: "Loan Accounts",
        grid: 3,
        "enum": [],
        "onKeyDown": true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "productName",
        "type": "textApiCall",
        "required": true,
        "label": "Product Name",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "casaAccount",
        "type": "textApiCall",
        "required": true,
        "label": "Casa Account",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        varName: "casaAccountStatus",
        type: "text",
        label: "Casa Account Status",
        grid: 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "showCasaDetails",
        "conditionalVarValue": "YES",
    },
    {
        varName: "loanAccountBalance",
        type: "text",
        label: "Pay Off Amt",
        grid: 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "dailyInterest",
        "type": "text",
        "label": "Daily Interest",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        varName: "loanAccountExpiryDate",
        type: "date",
        label: "Loan Account Expiry Date",
        grid: 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",

    },
    {
        varName: "casaBalance",
        type: "text",
        label: "Casa Balance",
        grid: 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "showCasaDetails",
        "conditionalVarValue": "YES",
    },
    {
        varName: "rateOfEsf",
        type: "select",
        enum: [
            "0",".5","1", "2", "3", "4"
        ],
        label: "Rate of ESF",
        grid: 2,
        "required": true,
        "onKeyDown": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "earlySettlementFee",
        "type": "text",
        "label": "Early Settlement Fee",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "rateOfEsfApiCall",
        "conditionalVarValue": "YES",
    },
    {
        varName: "vat",
        type: "text",
        label: "VAT",
        grid: 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "rateOfEsfApiCall",
        "conditionalVarValue": "YES",
    },
    {
        varName: "totalAmount",
        type: "text",
        label: "Total Payoff Balance",
        grid: 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "rateOfEsfApiCall",
        "conditionalVarValue": "YES",
    },
    {
        varName: "exciseDuty",
        type: "text",
        label: "Excise Duty",
        grid: 2,
        readOnly: true,
        required: true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    // {
    //     "varName": "waiver",
    //     "type": "text",
    //     "label": "Waiver",
    //     "grid": 2
    // },
    {
        "varName": "loanAccountStatus",
        "type": "text",
        "label": "Loan Account Status",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "solId",
        "type": "text",
        "label": "Sol Id",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "branchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "classificationLoanStatus",
        "type": "text",
        "label": "Classification Loan Status",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        type: "empty",
        grid: 12,
    },
];

const CSLoanClosingJsonForm = loanCLosingJson.concat([
    {
        "varName": "loanClosingApplication",
        "type": "file",
        "label": "Loan Closing Application",
        "grid": 3
    },
    {
        type: "empty",
        grid: 12,
    },
]);

const BMClosingJsonForm = makeReadOnlyObject(makeConditionalGridObject(JSON.parse(JSON.stringify(
    [...loanCLosingJson]
))));

let csReadOnlyForm = makeReadOnlyObject(JSON.parse(JSON.stringify(loanCLosingJson)));

const CheckerLoanClosingJsonForm = makeConditionalGridObject(JSON.parse(JSON.stringify([...csReadOnlyForm])));

const nocMakerLoanClosingJsonForm = makeConditionalGridObject(JSON.parse(JSON.stringify(csReadOnlyForm.concat([

    {
        varName: "dateOfTheDelivery",
        type: "date",
        label: "Date of the Delivery",
        required: true,
        grid: 4,
    },
    {
        varName: "nocCheckerId",
        type: "text",
        label: "NOC Checker ID",
        required: true,
        onKeyDown: true,
        lowerCase: true,
        grid: 4,
    },

    //
    // {
    //     varName: "fileDeliveryStatus",
    //     type: "select",
    //     label: "NOC Status (File Delivery)",
    //     required: true,
    //     grid: 4,
    //     "enum": [
    //         "Under Process",
    //         "Ready to Deliver",
    //         "Delivered",
    //         "Acknowledgement",
    //         "Send Back to Archive",
    //     ]
    // },
    {
        varName: "chequeStatus",
        type: "select",
        label: "NOC ISSUING TYPE",
        required: true,
        "onKeyDown": true,
        grid: 4,
        "enum": [
            "With Cheque other then Auto Loan",
            "Without Cheque",
            "Without Cheque for Auto Loan",
            "With Cheque for Auto Loan",
            "With Security Details",
        ]
    },

//With Cheque other then Auto Loan
    {
        varName: "nocIssueDate",
        type: "date",
        label: "NOC Issue Date",
        required: true,
        grid: 4,
        "conditional": true,
        conditionalVarName: "chequeStatus",
        conditionalVarValue: "With Cheque other then Auto Loan",
    },
    {
        varName: "udcNo",
        type: "text",
        label: "UDC No.",
        required: true,
        grid: 4,
        "conditional": true,
        conditionalVarName: "chequeStatus",
        conditionalVarValue: "With Cheque other then Auto Loan",
    },
    {
        varName: "bankNameUdc",
        type: "text",
        label: "BANK NAME (UDC)",
        required: true,
        grid: 4,
        "conditional": true,
        conditionalVarName: "chequeStatus",
        conditionalVarValue: "With Cheque other then Auto Loan",
    },
    {
        varName: "pdcNo",
        type: "text",
        label: "PDC. No.",
        required: true,
        grid: 4,
        "conditional": true,
        conditionalVarName: "chequeStatus",
        conditionalVarValue: "With Cheque other then Auto Loan",
    },
    {
        varName: "bankNamePdc",
        type: "text",
        label: "BANK NAME (PDC)",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque other then Auto Loan",
    },

    //Without Cheque for Auto Loan

    {
        varName: "customerTitle",
        type: "text",
        label: "Customer Title",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "Without Cheque for Auto Loan",
    },

    {
        varName: "brtaAuthority",
        type: "text",
        label: "BRTA Authority ",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "Without Cheque for Auto Loan",
    },
    {
        varName: "engineNo",
        type: "text",
        label: "Engine No.",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "Without Cheque for Auto Loan",
    },
    {
        varName: "chassisNo",
        type: "text",
        label: "Chassis No.",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "Without Cheque for Auto Loan",
    },
    {
        varName: "registrationNo",
        type: "text",
        label: "Registration No.",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "Without Cheque for Auto Loan",
    },
    {
        varName: "ownerName",
        type: "text",
        label: "Owner Name",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "Without Cheque for Auto Loan",
    },
    //With Cheque for Auto Loan

    {
        varName: "nocIssueDate",
        type: "date",
        label: "NOC issue date",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque for Auto Loan",
    },

    {
        varName: "customerTitle",
        type: "text",
        label: "Customer Title",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque for Auto Loan",
    },
    {
        varName: "udcNo",
        type: "text",
        label: "UDC No.",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque for Auto Loan",
    },
    {
        varName: "bankNameUdc",
        type: "text",
        label: "BANK NAME (UDC)",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque for Auto Loan",
    },
    {
        varName: "pdcNo",
        type: "text",
        label: "PDC. No.",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque for Auto Loan",
    },
    {
        varName: "bankNamePdc",
        type: "text",
        label: "BANK NAME  (PDC)",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque for Auto Loan",
    },
    {
        varName: "brtaAuthority",
        type: "text",
        label: "BRTA Authority",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque for Auto Loan",
    },
    {
        varName: "chassisNo",
        type: "text",
        label: "Chassis No.",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque for Auto Loan",
    },
    {
        varName: "registrationNo",
        type: "text",
        label: "Registration No.",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque for Auto Loan",
    },
    {
        varName: "engineNo",
        type: "text",
        label: "Engine No.",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque for Auto Loan",
    },
    {
        varName: "ownerName",
        type: "text",
        label: "Owner Name",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque for Auto Loan",
    },
    //With Security Details
    {
        varName: "businessName",
        type: "text",
        label: "Business Name",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName":"chequeStatus",
        "conditionalVarValue": "With Security Details",
    },
    {
        varName: "proprietorName",
        type: "text",
        label: "Proprietor Name",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Security Details",
    },
    {
        varName: "chequeNO",
        type: "text",
        label: "Cheque No.",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Security Details",
    },
    {
        varName: "numOfSecurityDetails",
        type: "select",
        enum: ["1","2","3","4","5","6","7","8","9","10"],
        label: "Number of Security",
        required: true,
        "onKeyDown": true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Security Details",
    },

    {
        type: "empty",
        grid: 12,
    },


]))));

export {
    CSLoanClosingJsonForm,
    BMClosingJsonForm,
    CheckerLoanClosingJsonForm,
    nocMakerLoanClosingJsonForm
}
