import React, {Component} from "react";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import CardBody from "../../Card/CardBody";
import withStyles from "@material-ui/core/styles/withStyles";
import AccountDetails from "../fdr/AccountDetails";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import SdnAndDedupeResultShowing from "./SdnAndDedupeResultShowing";
import {Dialog} from "@material-ui/core";
import Button from "@material/react-button";
import Grid from "@material-ui/core/Grid";


const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

    button: {
        margin: theme.spacing(1),
    }
});


class DedupFullResultButton extends Component {
    state = {
        fileUploadData: {},
        getSplitFile: [],
        multipleScanningphotoShow: null,
        inputData: {},
        dropdownSearchData: {}

    };

    constructor(props) {
        super(props);


    }


    updateComponent = () => {
        this.forceUpdate();
    };


    close = () => {
        this.props.closeModal();
    };
    closeIcon = () => {
        this.props.closeIcon();
    };
    handleSubmit = (event) => {

        event.preventDefault();
        this.setState({
            multipleScanningphotoShow: false
        })


    };

    handleChangeSdnResult = (event) => {
        event.preventDefault();
        this.setState({
            SdnAndDedupeResultShowingCommon: true
        })

    };
    closeModalForSdnResult = () => {
        this.setState({
            SdnAndDedupeResultShowingCommon: false
        })
    }
    renderSDNDedupeResult = () => {
            return (
                <Button
                   className="btn btn-outline-primary btn-sm"
                      style={{
                         backgroundColor: "red",
                         float:"right",
                         color: "white",
                          borderRadius: 3,
                        /*  paddingLeft: 10,
                         paddingRight: 10,
                         paddingTop: 3,
                         paddingBottom: 3,
                         borderRadius: 3,
                         border: 1,
                         verticalAlign: 'left',
                         position: "absolute",
                         marginTop: "5px",
                         right: 50,*/
                     }}

                    type='button' value='add more'
                    onClick={(event) => this.handleChangeSdnResult(event)}
                >
                    Dedupe & SDN Full Result
                </Button>
            )


    };

    render() {
        const {classes} = this.props;

        return (
           <>
                    <Dialog
                        fullWidth="true"
                        maxWidth="md"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.SdnAndDedupeResultShowingCommon}>
                        <DialogContent className={classes.dialogPaper}>
                            <SdnAndDedupeResultShowing merchantDedup={this.props.merchantDedup}  closeModal={this.closeModalForSdnResult}
                                                       branchName={this.props.branchName}
                                                       inputData={this.props.inputData}
                                                       subServiceType={this.props.subServiceType}
                                                       category={this.props.category}
                                                       serviceType={this.props.serviceType}
                                                       subCategory={this.props.subCategory}
                                                       freeFlag2={this.props.freeFlag2 === "externalUserClosedRequest" ? true : false}
                                                       serviceType={this.props.serviceType}
                                                       customerName={this.props.customerName}
                                                       accountNumber={this.props.accountNumber}
                                                       cbNumber={this.props.cbNumber}
                                                       solId={this.props.solId} globalSearch={true}
                                                       closeIcon={this.closeModalForSdnResult}
                                                       appId={this.props.appId }/>
                        </DialogContent>
                    </Dialog>
                    <CardBody>
                        {this.renderSDNDedupeResult()}
                    </CardBody>

              </>
        )
    }

}

export default withStyles(styles)(DedupFullResultButton);