import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid/index";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import FileTypeComponent from '../../JsonForm/FileTypeComponent';
import axios from 'axios/index';
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";


var fileUpload = {
    "varName": "scanningFile",
    "type": "file",
    "accept": ".pdf",
    "label": "Scan and Upload File",
    "grid": 12
};

class FileUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            inputData: {},
            showValue: false,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            searchValue: false,
            fileUploadData: {},
            uploadModal: false,
            preview: false

        };
        this.handleSubmit = this.handleSubmit.bind(this);


    }


    updateComponent = () => {
        this.forceUpdate();
    };


    componentDidMount() {


    }


    renderSubmitButton = () => {

        return (

            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'middle',
                }}
                onClick={this.handleSubmit}

            >
                Submit
            </button>

        )

    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    handleSubmit = (event) => {
        event.preventDefault();
        this.forceUpdate();

        if (this.state.fileUploadData.scanningFile !== undefined) {
            let fileUploadPath = backEndServerURL + "/case/upload";
            let types = this.props.name;
            let files = this.state.fileUploadData.scanningFile;
            console.log(files);
            let formData = new FormData();
            formData.append("appId", this.props.appId);
            formData.append("file", files);
            formData.append("type", types);
            axios({
                method: 'post',
                url: fileUploadPath,
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'content-type': 'multipart/form-data'
                }
            })
                .then((response) => {

                    console.log(response);
                    console.log("file upload successful !");

                    this.props.closeModal()
                })
                .catch((error) => {
                    console.log(error)
                })
        }


    };


    render() {
        console.log(this.state.inputData);
        return (
            <div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >File Upload<a><CloseIcon onClick={this.props.closeModal} style={{
                            position: 'absolute',
                            right: 10,
                            color: "#000000"
                        }}/></a></h4>
                    </CardHeader>
                    <CardBody>

                        <Grid container>


                            {FileTypeComponent.restrictedFile(this.state, this.updateComponent, fileUpload)}


                        </Grid>
                        <br/>


                        <center>
                            {
                                this.renderSubmitButton()
                            }
                        </center>
                    </CardBody>


                </Card>
            </div>
        )
    }

}

export default FileUpload;
