import {TpKycForIndividual} from "../../WorkflowJsonForm4";
import {startReturnSubmission} from "../../../../Common/Constant";
function listOfReturnSubmission(date) {
    /*     let currentDate=new Date();
         let dataList=[];
         for (let i = date; i < currentDate.getFullYear(); i++) {
             dataList.push(date+"-"+Number(date+1));
             i=date+1;
          }*/
    let dataList=[
        "2017-2018",
        "2018-2019",
        "2019-2020",
         "2020-2021",
        "2021-2022",
        "2022-2023",
    ];
    return dataList;


}
function makeReadOnlyObject(object) {
    let returnObject = JSON.parse(JSON.stringify(object));
    let returnObjectVariables = returnObject;
    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["readOnly"] = true;
        returnObjectVariables[i]["required"] = false;
    }
    return returnObject;
}

let day = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",

];

const maintenanceForCs = [
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "varName": "12DigitTINChange",
        "type": "title",
        "label": "E Tin Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true
    },
    {
        "varName": "tinUpdateOld",
        "type": "text",
        "label": "Existing TIN No",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true
    },
    {
        "varName": "tin",
        "type": "text",
        "label": "New TIN No",
        "validation": "tin",
        "required": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true
    },
    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 2,
        "required":true,
        "conditionalArray": true,
        "conditionalArrayList": ["digitTinChange", "tinopen"],
        "conditionalArrayValue": [true, true],
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid": 2,
        "required":true,
        "conditionalArray": true,
        "conditionalArrayList": ["digitTinChange", "tinopen"],
        "conditionalArrayValue": [true, true],
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission","digitTinChange", "tinopen"],
        "conditionalArrayValue": ["Y",true, true],
        "grid":2,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "required":true,
        "label": "Tax Submission Year",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission","digitTinChange", "tinopen"],
        "conditionalArrayValue": ["N",true, true],
        "grid": 2,
    },
    {
        "varName": "nidChange",
        "type": "title",
        "label": "NID Card Number update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "nidChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidCardOld",
        "type": "text",
        "label": "Existing NID Number",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "nidChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidNumberNew",
        "type": "text",
        "label": "New NID Number",
        "validation": "nid",
        "required": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "nidChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidNumberVerification",
        "type": "select",
        "label": "NID Verification Confirmation",
        "enum": ["Y", "N"],
        "required": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "nidChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidVerificationLink",
        "type": "button",
        "label": "NID Verification Link",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "nidChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidOrsmartcardChange",
        "type": "title",
        "label": "NID/Smart Card Number update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "smartcardOld",
        "type": "text",
        "label": "Existing Smart Card Number",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "smartCardNew",
        "type": "text",
        "label": "New Smart Card Number",
        "validation": "length",
        "maxLength": 10,
        "minLength": 10,
        "required": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "smartCardVerification",
        "type": "select",
        "label": "Smart Card Verification Confirmation",
        "enum": ["Y", "N"],
        "required": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidVerificationLink",
        "type": "button",
        "label": "Smart Card Verification Link",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidCardOld",
        "type": "text",
        "label": "Existing NID Number",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidCardNew",
        "type": "text",
        "label": "New NID Number",
        "validation": "nid",
        "required": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidCardVerification",
        "type": "select",
        "label": "NID Verification Confirmation",
        "enum": ["Y", "N"],
        "required": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidVerificationLink",
        "type": "button",
        "label": "NID Verification Link",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "MailingContactNumberChange",
        "type": "title",
        "label": "Designated Contact Number Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "existingPhoneNumber",
        "type": "text",
        "label": "Existing Phone Number",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "mobileType",
        "type": "select",
        "label": "Mobile Type",
        "enum": [
            "Local",
            "Overseas"
        ],
        "onKeyDown": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "commPhoneLocalCode",
        "type": "autoCompleteValueReturn",
        "label": "Country Code",
        "enum": [],
        "grid": 3,
        "conditionalArray": true,
        "conditionalArrayList": ["mailingContactNumberChange", "mobileType"],
        "conditionalArrayValue": [true, "Overseas"],
        "required": true,
    },
    {
        "varName": "newPhoneNumber",
        "type": "text",
        "label": "New Phone Number",
        "validation": "phone",
        "phonePrefix": true,
        "required": true,
        "grid": 3,
        "conditionalArray": true,
        "conditionalArrayList": ["mailingContactNumberChange", "mobileType"],
        "conditionalArrayValue": [true, "Local"],
    },
    {
        "varName": "newPhoneNumber",
        "type": "text",
        "label": "New Phone Number",
        "validation": "numeric",
        "required": true,
        "grid": 3,
        "conditionalArray": true,
        "conditionalArrayList": ["mailingContactNumberChange", "mobileType"],
        "conditionalArrayValue": [true, "Overseas"],
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "EmailAddressChange",
        "type": "title",
        "label": "Email Address Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true

    },
    {
        "varName": "existingEmailId",
        "type": "text",
        "label": "Existing Designated Email Address",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "emailId",
        "type": "text",
        "label": "New Designated Email Address",
        "validation": "email",
        "required": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "oldEStatementFacility",
        "type": "select",
        "label": "E Statement Enrollment",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true,
        "enum": [
            "YES",
            "NO"
        ]
    },
    // {
    //     "varName": "emailTypeTwo",
    //     "type": "autoCompleteValueReturn",
    //     "enum": [],
    //     "readOnly": true,
    //     "conditional": true,
    //     "conditionalVarName": "emailAddressChange",
    //     "conditionalVarValue": true,
    //     "label": "Email Type",
    //     "grid": 3,
    // },
    {
        "type": "empty",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "existingEmailIdTwo",
        "type": "text",
        "label": "Existing Other Email Address",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "emailIdTwo",
        "type": "text",
        "label": "New Email ID",
        "validation": "email",
        // "required": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "EstatementEnrollment",
        "type": "title",
        "label": "E-statement Enrollment",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true

    },
    {
        "varName": "existingEmailId",
        "type": "text",
        "label": "Existing Designated Email Address",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true
    },
    {
        "varName": "eStatementFacility",
        "type": "select",
        "label": "E Statement Enrollment",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true,
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "type": "empty",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true
    },

    {
        "varName": "CurrencyAdd",
        "type": "title",
        "label": "Currency Add",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true
    },
    {
        "varName": "existingCurrency",
        "type": "text",
        "label": "Existing Currency",
        "grid": 3,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true
    },
    {
        "varName": "newCurrency",
        "type": "autoCompleteValueReturn",
        "label": "New Currency",
        "enum": [],
        "required": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true
    },
    {
        "varName": "AddressChange",
        "type": "title",
        "label": "Address Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },


    {
        "type": "title",
        "label": "Mailing Address",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "maillingAdressmailingAddressField1",
        "type": "text",
        "label": "Mailing Address Field 1",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "maillingAdressmailingAddressField2",
        "type": "text",
        "label": "Mailing Address Field 2",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true,
        "grid": 3
    },

    {
        "varName": "maillingAdresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "maillingAdressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "maillingAdresspostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "maillingAdresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "type": "title",
        "label": "Home Address",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "homeAddresshomeAddressField1",
        "type": "text",
        "label": "Home Address Field 1",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "homeAddresshomeAddressField2",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "homeAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "homeAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "homeAddresspostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "homeAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "type": "title",
        "label": "Work Address",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "workAddressworkAddressField1",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "workAddressworkAddressField2",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "workAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "workAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "workAddresspostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "workAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "type": "title",
        "label": "Present Address",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "presentAddresspresentAddressField1",
        "type": "text",
        "label": "Present Address Field 1",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "presentAddresspresentAddressField2",
        "type": "text",
        "label": "Present Address Field 2",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "presentAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "presentAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "presentAddresspostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "presentAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "type": "title",
        "label": "NRE Address",
        "grid": 12,
        "conditionalArray": true,
        "conditionalArrayList": ["addressChange", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "Y"],
    },
    {
        "varName": "nreAddressnreAddressField1",
        "type": "text",
        "label": "NRE Address Field 1",
        "grid": 3,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["addressChange", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "Y"],
    },

    {
        "varName": "nreAddressnreAddressField2",
        "type": "text",
        "label": "NRE Address Field 2",
        "grid": 3,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["addressChange", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "Y"],
    },

    {
        "varName": "nreAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "readOnly": true,
        "label": "City",
        "grid": 3,
        "conditionalArray": true,
        "conditionalArrayList": ["addressChange", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "Y"],
    },

    {
        "varName": "nreAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 3,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["addressChange", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "Y"],
    },

    {
        "varName": "nreAddresspostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 3,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["addressChange", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "Y"],
    },

    {
        "varName": "nreAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "readOnly": true,
        "label": "Country",
        "grid": 3,
        "conditionalArray": true,
        "conditionalArrayList": ["addressChange", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "Y"],
    },

    //
    // {
    //     "varName": "communicationAddress",
    //     "type": "text",
    //     "label": "Communication Address",
    //     "readOnly": true,
    //     "grid": 3,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "presentAddress",
    //     "type": "text",
    //     "label": "Present Address",
    //     "readOnly": true,
    //     "grid": 3,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "officeAddress",
    //     "type": "text",
    //     "label": "Office Address",
    //     "readOnly": true,
    //     "grid": 3,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "permanentAddress",
    //     "type": "text",
    //     "label": "Permanent Address",
    //     "readOnly": true,
    //     "grid": 3,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "nreAddressnreAddressField1",
    //     "type": "text",
    //     "label": "NRE Address",
    //     "grid": 3,
    //     "readOnly": true,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["addressChange"],
    //     "conditionalArrayValue": [true,],
    // },
    {
        "varName": "TitleChange",
        "type": "title",
        "label": "Title Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "titleOld",
        "type": "autoCompleteValueReturn",
        "label": "Existing Account Title",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "existingNameOld",
        "type": "text",
        "label": "Existing Full Name",
        "validation": "string",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "existingShortName",
        "type": "text",
        "label": "Existing Short Name",
        "validation": "string",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "gender",
        "type": "select",
        "enum": [
            "M",
            "F",
            // "TRANSGENDER",
            "O"
        ],
        "readOnly": true,
        "label": "Gender",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "uploadSignature",
        "type": "checkbox",
        "label": "Signature Card Upload",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "PhotoIDChange",
        "type": "title",
        "label": "Photo ID Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "passportNumberpassportNumberUniqueId",
        "type": "text",
        "label": "Existing Passport No.",
        // "validation": "numeric",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "birthCertificatebirthCertificateNumberUniqueId",
        "type": "text",
        "label": "Existing  Birth Certificate No.",
        // "validation": "numeric",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "chairmanCertificateUniqueId",
        "type": "text",
        "label": "Existing Chairman Certificate No.",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingDrivingLicense",
        "type": "text",
        "label": "Existing Driving License No.",
        // "validation": "numeric",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingResidentId",
        "type": "text",
        "label": "Existing Resident Id No.",
        "validation": "numeric",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "OtherContactNumberChange",
        "type": "title",
        "label": "Other Contact Number Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingResidenceCountryCode",
        "type": "text",
        "label": "Existing Residence Country Code",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,
        // "validation": "phone",
    },
    {
        "varName": "existingResidenceNumber",
        "type": "text",
        "label": "Existing Residence Contact Number",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,
        // "validation": "phone",
    },
    {
        "varName": "existingOfficeCountryCode",
        "type": "text",
        "label": "Existing Office Country Code",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,
        // "validation": "phone",
    },
    {
        "varName": "existingOfficeNumber",
        "type": "text",
        "label": "Existing Office Contact Number",
        "readOnly": true,
        "grid": 3,
        // "phone": true,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,
        // "errorMessage": "Error",
        // "validation": "phone",

    },
    // {
    //     "varName": "existingMobileNumber",
    //     "type": "text",
    //     "label": "Existing Mobile Number",
    //     // "phone": true,
    //     "grid": 3,
    //     "readOnly": true,
    //     "conditional": true,
    //     "conditionalVarName": "otherContactNumberChange",
    //     "conditionalVarValue": true,
    //     // "errorMessage": "Error",
    //     // "validation": "phone",
    //
    // },
    {
        "varName": "OtherInformationChange",
        "type": "title",
        "label": "Other Information Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "titleOld",
        "type": "autoCompleteValueReturn",
        "label": "Existing Account Title",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "gender",
        "type": "select",
        "enum": [
            "M",
            "F",
            // "TRANSGENDER",
            "O"
        ],
        "readOnly": true,
        "label": "Gender",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "profession",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Profession",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "constitutionCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Constitution",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "occupationCodeCB",
        "type": "select",
        "enum": [],
        "label": "Occupation Code(CB)",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "occupationCodeAccount",
        "type": "select",
        "enum": [],
        "label": "Occupation Code(Account)",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "fatherName",
        "type": "text",
        "label": "Father's Name",
        "validation": "string",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "motherName",
        "type": "text",
        "label": "Mother's Name",
        "validation": "string",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "maritalStatus",
        "type": "autoCompleteValueReturn",
        "label": "Marital Status",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "spouseName",
        "type": "text",
        "label": "Spouse Name",
        "validation": "string",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "dobOld",
        "type": "date",
        "label": "DOB",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "employerName",
        "type": "text",
        "label": "Employer Name",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sector Code",
        "grid": 3,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["otherInformationChange", "showSecCode"],
        "conditionalArrayValue": [true, true]
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Sector Code",
        "grid": 3,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["otherInformationChange", "sectorCodeApicall", "showSecCode"],
        "conditionalArrayValue": [true, true, true]
    },
    {
        "varName": "DormantAccountActivation",
        "type": "title",
        "label": "Dormant Account Activation",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "onlyDormantAccountActivation",
        "conditionalVarValue": true
    },
    {
        "varName": "DormantAccountActivation",
        "type": "title",
        "label": "Dormant Account Activation And Data Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "dormantAccountActivation",
        "conditionalVarValue": true
    },
    {
        "varName": "accountStatus",
        "type": "text",
        "validation": "string",
        "label": "Account Status",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "onlyDormantAccountActivation",
        "conditionalVarValue": true
    },
    {
        "varName": "accountStatus",
        "type": "text",
        "validation": "string",
        "label": "Account Status",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "dormantAccountActivation",
        "conditionalVarValue": true
    },
    {
        "varName": "ProjectRelatedDataUpdateADUP,CIB",
        "type": "title",
        "label": "Data Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "projectRelatedDataUpdateADUP",
        "conditionalVarValue": true

    },
    {
        "varName": "tpKyc",
        "type": "title",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "tpKyc",
        "conditionalVarValue": true
    },
    {
        "varName": "siCreation",
        "type": "title",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "siCreation",
        "conditionalVarValue": true
    },

    {
        "varName": "modifyExistingSi",
        "type": "title",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "modifyExistingSi",
        "conditionalVarValue": true
    },
    {
        "varName": "deleteExistingSi",
        "type": "title",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "deleteExistingSi",
        "conditionalVarValue": true
    },
    {
        "varName": "accountFreezeMark",
        "type": "title",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "accountFreezeMark",
        "conditionalVarValue": true
    },
    {
        "varName": "accountUnfreezeMark",
        "type": "title",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "accountUnfreezeMark",
        "conditionalVarValue": true
    },
    {
        "varName": "decreasedMark",
        "type": "title",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "decreasedMark",
        "conditionalVarValue": true
    },
    {
        "varName": "SignatureCard",
        "type": "title",
        "label": "Signature Card Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "signatureCard",
        "conditionalVarValue": true

    },
    {
        "varName": "NomineeUpdate",
        "type": "title",
        "label": "Nominee Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true

    },
    {
        "varName": "nomineeNumberOld",
        "type": "text",
        "label": "Existing No of Nominee",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "nomineeRegNo",
    //     "type": "text",
    //     "label": "Registration No",
    //     "grid": 3,
    //     "readOnly": true,
    //     "conditional": true,
    //     "conditionalVarName": "nomineeChange",
    //     "conditionalVarValue": true
    // },
    {
        "type": "empty",
        "grid": 12
    },
];
const csBearer = [
    {
        "type": "empty",
        "grid": 12
    },
    {
        "varName": "csBearer",
        "type": "select",
        "onKeyDown": true,
        "label": "Bearer Status/Customer Visited",
        "enum": [
            "YES",
            "NO"
        ],
        "required": true,
        "grid": 3,

    },
    {
        "varName": "bearerApproval",
        "type": "select",
        "required": true,
        "onKeyDown": true,
        "label": "Bearer Approval",
        "grid": 3,
        "enum": [
            "BM Approval",
            // "Call Center Approval",
            // "BM & Call Center Approval"

        ],
        "conditional": true,
        "conditionalVarName": "showBearerField",
        "conditionalVarValue": true,
    }

];

let maintenanceForBm = {};
maintenanceForBm = makeReadOnlyObject(JSON.parse(JSON.stringify(maintenanceForCs)));

const maintenanceForCallAndBom = [
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "varName": "12DigitTINChange",
        "type": "title",
        "label": "E Tin Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true
    },
    {
        "varName": "tinUpdateOld",
        "type": "text",
        "label": "Existing TIN No",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true
    },
    {
        "varName": "tin",
        "type": "text",
        "label": "New TIN No",
        "validation": "tin",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidChange",
        "type": "title",
        "label": "NID Card Number update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "nidChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidCardOld",
        "type": "text",
        "label": "Existing NID Number",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidNumberNew",
        "type": "text",
        "label": "New NID Number",
        "validation": "numeric",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidNumberVerification",
        "type": "select",
        "label": "NID Verification Confirmation",
        "enum": ["Y", "N"],
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidOrsmartcardChange",
        "type": "title",
        "label": "NID/Smart Card Number update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "smartcardOld",
        "type": "text",
        "label": "Existing Smart Card Number",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "smartCardNew",
        "type": "text",
        "label": "New Smart Card Number",
        "validation": "numeric",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "smartCardVerification",
        "type": "select",
        "label": "Smart Card Verification Confirmation",
        "enum": ["Y", "N"],
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "type": "empty",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidCardOld",
        "type": "text",
        "label": "Existing NID Number",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidCardNew",
        "type": "text",
        "label": "New NID Number",
        "validation": "numeric",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidCardVerification",
        "type": "select",
        "label": "NID Verification Confirmation",
        "enum": ["Y", "N"],
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "MailingContactNumberChange",
        "type": "title",
        "label": "Designated Contact Number Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "existingPhoneNumber",
        "type": "text",
        "label": "Existing Phone Number",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "mobileType",
        "type": "select",
        "label": "Mobile Type",
        "enum": [
            "Local",
            "Overseas"
        ],
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "commPhoneLocalCode",
        "type": "autoCompleteValueReturn",
        "label": "Country Code",
        "enum": [],
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["mailingContactNumberChange", "mobileType"],
        "conditionalArrayValue": [true, "Overseas"],
        "required": true,
    },
    {
        "varName": "newPhoneNumber",
        "type": "text",
        "label": "New Phone Number",
        "validation": "phone",
        "phonePrefix": true,
        "readOnly": true,
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["mailingContactNumberChange", "mobileType"],
        "conditionalArrayValue": [true, "Local"],
    },
    {
        "varName": "newPhoneNumber",
        "type": "text",
        "label": "New Phone Number",
        "validation": "numeric",
        "readOnly": true,
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["mailingContactNumberChange", "mobileType"],
        "conditionalArrayValue": [true, "Overseas"],
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "EmailAddressChange",
        "type": "title",
        "label": "Email Address Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true

    },
    // {
    //     "varName": "emailTypeOne",
    //     "type": "autoCompleteValueReturn",
    //     "enum": [],
    //     "readOnly": true,
    //     "label": "Email Type",
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "emailAddressChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "existingEmailId",
        "type": "text",
        "label": "Existing Designated Email Address",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "emailId",
        "type": "text",
        "label": "New Designated Email Address",
        "validation": "email",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "oldEStatementFacility",
        "type": "select",
        "label": "E Statement Enrollment",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true,
        "enum": [
            "YES",
            "NO"
        ]
    },
    // {
    //     "varName": "emailTypeTwo",
    //     "type": "autoCompleteValueReturn",
    //     "enum": [],
    //     "readOnly": true,
    //     "conditional": true,
    //     "conditionalVarName": "emailAddressChange",
    //     "conditionalVarValue": true,
    //     "label": "Email Type",
    //     "grid": 6,
    // },
    {
        "type": "empty",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "existingEmailIdTwo",
        "type": "text",
        "label": "Existing Other Email Address",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "emailIdTwo",
        "type": "text",
        "label": "New Email ID",
        "validation": "email",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "EstatementEnrollment",
        "type": "title",
        "label": "E-statement Enrollment",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true

    },
    {
        "varName": "existingEmailId",
        "type": "text",
        "label": "Existing Designated Email Address",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true
    },
    {
        "varName": "eStatementFacility",
        "type": "select",
        "label": "E Statement Enrollment",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true,
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "type": "empty",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true
    },
    {
        "varName": "CurrencyAdd",
        "type": "title",
        "label": "Currency Add",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true

    },
    {
        "varName": "existingCurrency",
        "type": "text",
        "label": "Existing Currency",
        "grid": 6,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true
    },
    {
        "varName": "newCurrency",
        "type": "autoCompleteValueReturn",
        "label": "New Currency",
        "enum": [],
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true
    },
    {
        "varName": "AddressChange",
        "type": "title",
        "label": "Address Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "type": "title",
        "label": "Mailing Address",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "maillingAdressmailingAddressField1",
        "type": "text",
        "label": "Mailing Address Field 1",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "maillingAdressmailingAddressField2",
        "type": "text",
        "label": "Mailing Address Field 2",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true,
        "grid": 6
    },

    {
        "varName": "maillingAdresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "maillingAdressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "maillingAdresspostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "maillingAdresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "type": "title",
        "label": "Home Address",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "homeAddresshomeAddressField1",
        "type": "text",
        "label": "Home Address Field 1",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "homeAddresshomeAddressField2",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "homeAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "homeAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "homeAddresspostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "homeAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "type": "title",
        "label": "Work Address",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "workAddressworkAddressField1",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "workAddressworkAddressField2",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "workAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "workAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "workAddresspostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "workAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "type": "title",
        "label": "Present Address",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "presentAddresspresentAddressField1",
        "type": "text",
        "label": "Present Address Field 1",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "presentAddresspresentAddressField2",
        "type": "text",
        "label": "Present Address Field 2",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "presentAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "presentAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "presentAddresspostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "presentAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "type": "title",
        "label": "NRE Address",
        "grid": 12,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["addressChange", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "Y"],
    },
    {
        "varName": "nreAddressnreAddressField1",
        "type": "text",
        "label": "NRE Address Field 1",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["addressChange", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "Y"],
    },

    {
        "varName": "nreAddressnreAddressField2",
        "type": "text",
        "label": "NRE Address Field 2",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["addressChange", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "Y"],
    },

    {
        "varName": "nreAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["addressChange", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "Y"],
    },

    {
        "varName": "nreAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["addressChange", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "Y"],
    },

    {
        "varName": "nreAddresspostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["addressChange", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "Y"],
    },

    {
        "varName": "nreAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "readOnly": true,
        "label": "Country",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["addressChange", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "Y"],
    },
    {
        "varName": "TitleChange",
        "type": "title",
        "label": "Title Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "titleOld",
        "type": "autoCompleteValueReturn",
        "label": "Existing Account Title",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "existingNameOld",
        "type": "text",
        "label": "Existing Name",
        "validation": "string",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "existingShortName",
        "type": "text",
        "label": "Existing Short Name",
        "validation": "string",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "gender",
        "type": "select",
        "enum": [
            "M",
            "F",
            // "TRANSGENDER",
            "O"
        ],
        "readOnly": true,
        "label": "Gender",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "uploadSignature",
        "type": "checkbox",
        "label": "Signature Card Upload",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "PhotoIDChange",
        "type": "title",
        "label": "Photo ID Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "passportNumberpassportNumberUniqueId",
        "type": "text",
        "label": "Existing Passport No.",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "birthCertificatebirthCertificateNumberUniqueId",
        "type": "text",
        "label": "Existing  Birth Certificate No.",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "chairmanCertificateUniqueId",
        "type": "text",
        "label": "Existing Chairman Certificate No.",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingDrivingLicense",
        "type": "text",
        "label": "Existing Driving License No.",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingResidentId",
        "type": "text",
        "label": "Existing Resident Id No.",
        "validation": "numeric",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "OtherContactNumberChange",
        "type": "title",
        "label": "Other Contact Number Change",
        "grid": 12,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingResidenceCountryCode",
        "type": "text",
        "label": "Existing Residence Country Code",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,
        // "validation": "phone",
    },
    {
        "varName": "existingResidenceNumber",
        "type": "text",
        "label": "Existing Residence Contact Number",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,
    },
    // {
    //     "varName": "existingOfficeNumber",
    //     "type": "text",
    //     "label": "Existing Office Number",
    //     "grid": 6,
    //     "readOnly": true,
    //     "conditional": true,
    //     "conditionalVarName": "otherContactNumberChange",
    //     "conditionalVarValue": true,
    //
    // },
    {
        "varName": "existingOfficeCountryCode",
        "type": "text",
        "label": "Existing Office Country Code",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,
        // "validation": "phone",
    },
    {
        "varName": "existingOfficeNumber",
        "type": "text",
        "label": "Existing Office Contact Number",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "OtherInformationChange",
        "type": "title",
        "label": "Other Information Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "titleOld",
        "type": "autoCompleteValueReturn",
        "label": "Existing Account Title",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "gender",
        "type": "select",
        "enum": [
            "M",
            "F",
            // "TRANSGENDER",
            "O"
        ],
        "readOnly": true,
        "label": "Gender",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "profession",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Profession",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "constitutionCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Constitution",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "occupationCodeCB",
        "type": "select",
        "enum": [],
        "label": "Occupation Code(CB)",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "occupationCodeAccount",
        "type": "select",
        "enum": [],
        "label": "Occupation Code(Account)",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "fatherName",
        "type": "text",
        "label": "Father's Name",
        "validation": "string",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "motherName",
        "type": "text",
        "label": "Mother's Name",
        "validation": "string",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "maritalStatus",
        "type": "autoCompleteValueReturn",
        "label": "Marital Status",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "spouseName",
        "type": "text",
        "label": "Spouse Name",
        "validation": "string",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "dobOld",
        "type": "date",
        "label": "DOB",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "employerName",
        "type": "text",
        "label": "Employer Name",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sector Code",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["otherInformationChange", "showSecCode"],
        "conditionalArrayValue": [true, true]
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Sector Code",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["otherInformationChange", "sectorCodeApicall", "showSecCode"],
        "conditionalArrayValue": [true, true, true]
    },
    {
        "varName": "DormantAccountActivation",
        "type": "title",
        "label": "Dormant Account Activation",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "onlyDormantAccountActivation",
        "conditionalVarValue": true
    },
    {
        "varName": "DormantAccountActivation",
        "type": "title",
        "label": "Dormant Account Activation And Data Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "dormantAccountActivation",
        "conditionalVarValue": true
    },
    {
        "varName": "accountStatus",
        "type": "select",
        "enum": [
            "A",
            "D"
        ],
        "readOnly": true,
        "label": "Account Status",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "onlyDormantAccountActivation",
        "conditionalVarValue": true
    },
    {
        "varName": "accountStatus",
        "type": "select",
        "enum": [
            "A",
            "D"
        ],
        "readOnly": true,
        "label": "Account Status",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dormantAccountActivation",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "dormantActivationCharge",
    //     "type": "select",
    //     "enum": [
    //         "Y",
    //         "N"
    //     ],
    //     "label": "Dormant Activation Charge",
    //     "grid": 6,
    //     "readOnly": true,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["dormantAccountActivation", "showDormant"],
    //     "conditionalArrayValue": [true, true]
    // },
    {
        "varName": "ProjectRelatedDataUpdateADUP,CIB",
        "type": "title",
        "label": "Data Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "projectRelatedDataUpdateADUP",
        "conditionalVarValue": true

    },
    {
        "varName": "tpKyc",
        "type": "title",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "tpKyc",
        "conditionalVarValue": true
    },
    {
        "varName": "siCreation",
        "type": "title",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "siCreation",
        "conditionalVarValue": true
    },
    {
        "varName": "modifyExistingSi",
        "type": "title",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "modifyExistingSi",
        "conditionalVarValue": true
    },
    {
        "varName": "deleteExistingSi",
        "type": "title",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "deleteExistingSi",
        "conditionalVarValue": true
    },
    {
        "varName": "accountFreezeMark",
        "type": "title",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "accountFreezeMark",
        "conditionalVarValue": true
    },
    {
        "varName": "accountUnfreezeMark",
        "type": "title",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "accountUnfreezeMark",
        "conditionalVarValue": true
    },
    {
        "varName": "decreasedMark",
        "type": "title",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "decreasedMark",
        "conditionalVarValue": true
    },
    {
        "varName": "SignatureCard",
        "type": "title",
        "label": "Signature Card Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "signatureCard",
        "conditionalVarValue": true

    },
    {
        "varName": "NomineeUpdate",
        "type": "title",
        "label": "Nominee Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true

    },
    {
        "varName": "nomineeNumberOld",
        "type": "text",
        "label": "Existing No of Nominee",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "nomineeRegNo",
    //     "type": "text",
    //     "label": "Registration No",
    //     "grid": 6,
    //     "readOnly": true,
    //     "conditional": true,
    //     "conditionalVarName": "nomineeChange",
    //     "conditionalVarValue": true
    // },
    {
        "type": "empty",
        "grid": 12
    },
];
const maintenanceForMaker = [
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "varName": "12DigitTINChange",
        "type": "title",
        "label": "E Tin Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true
    },
    {
        "varName": "tinUpdateOld",
        "type": "text",
        "label": "Existing TIN No",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true
    },
    {
        "varName": "tin",
        "type": "text",
        "label": "New TIN No",
        "validation": "tin",
        // "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true
    },
    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 2,
        "required":true,
        "conditionalArray": true,
        "conditionalArrayList": ["digitTinChange", "tinopen"],
        "conditionalArrayValue": [true, true],
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid": 2,
        "required":true,
        "conditionalArray": true,
        "conditionalArrayList": ["digitTinChange", "tinopen"],
        "conditionalArrayValue": [true, true],
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission","digitTinChange", "tinopen"],
        "conditionalArrayValue": ["Y",true, true],
        "grid":2,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "required":true,
        "label": "Tax Submission Year",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission","digitTinChange", "tinopen"],
        "conditionalArrayValue": ["N",true, true],
        "grid": 2,
    },
    {
        "varName": "eTinDeleteFlag",
        "type": "select",
        "label": "Delete Flag",
        "enum": ["Y", "N"],
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidChange",
        "type": "title",
        "label": "NID Card Number update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "nidChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidCardOld",
        "type": "text",
        "label": "Existing NID Number",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidNumberNew",
        "type": "text",
        "label": "New NID Number",
        "validation": "numeric",
        // "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidNumberVerification",
        "type": "select",
        "label": "NID Verification Confirmation",
        "enum": ["Y", "N"],
        // "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidOnlyDeleteFlag",
        "type": "select",
        "label": "Delete Flag",
        "enum": ["Y", "N"],
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidOrsmartcardChange",
        "type": "title",
        "label": "NID/Smart Card Number update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "smartcardOld",
        "type": "text",
        "label": "Existing Smart Card Number",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "smartCardNew",
        "type": "text",
        "label": "New Smart Card Number",
        "validation": "numeric",
        // "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "smartCardDeleteFlag",
        "type": "select",
        "label": "Smart Card Verification Confirmation",
        "enum": ["Y", "N"],
        // "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidOrsmartcardChange",
        "type": "title",
        "label": "NID/Smart Card Number update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "type": "empty",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidCardOld",
        "type": "text",
        "label": "Existing NID Number",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidCardNew",
        "type": "text",
        "label": "New NID Number",
        "validation": "numeric",
        // "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidCardVerification",
        "type": "select",
        "label": "NID Verification Confirmation",
        "enum": ["Y", "N"],
        // "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidDeleteFlag",
        "type": "select",
        "label": "Delete Flag",
        "enum": ["Y", "N"],
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "MailingContactNumberChange",
        "type": "title",
        "label": "Designated Contact Number Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "existingPhoneNumber",
        "type": "text",
        "label": "Existing Phone Number",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "mobileType",
        "type": "select",
        "label": "Mobile Type",
        "enum": [
            "Local",
            "Overseas"
        ],
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "commPhoneLocalCode",
        "type": "autoCompleteValueReturn",
        "label": "Country Code",
        "enum": [],
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["mailingContactNumberChange", "mobileType"],
        "conditionalArrayValue": [true, "Overseas"],
        "required": true,
    },
    {
        "varName": "newPhoneNumber",
        "type": "text",
        "label": "New Phone Number",
        "validation": "phone",
        "phonePrefix": true,
        "required": true,
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["mailingContactNumberChange", "mobileType"],
        "conditionalArrayValue": [true, "Local"],
    },
    {
        "varName": "newPhoneNumber",
        "type": "text",
        "label": "New Phone Number",
        "validation": "numeric",
        "required": true,
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["mailingContactNumberChange", "mobileType"],
        "conditionalArrayValue": [true, "Overseas"],
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "EmailAddressChange",
        "type": "title",
        "label": "Email Address Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true

    },
    // {
    //     "varName": "emailTypeOne",
    //     "type": "autoCompleteValueReturn",
    //     "enum": [],
    //     "readOnly": true,
    //     "label": "Email Type",
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "emailAddressChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "existingEmailId",
        "type": "text",
        "label": "Existing Designated Email Address",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "emailId",
        "type": "text",
        "label": "New Designated Email Address",
        "validation": "email",
        "required": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "eStatementFacility",
        "type": "select",
        "label": "E Statement Enrollment",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true,
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "emailId1DeleteFlag",
        "type": "select",
        "enum": ["Y", "N"],
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true,
        "label": "Delete Flag",
        "grid": 6,
    },
    {
        "type": "empty",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "existingEmailIdTwo",
        "type": "text",
        "label": "Existing Other Email Address",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "emailIdTwo",
        "type": "text",
        "label": "New Email ID",
        "validation": "email",
        // "required": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "emailId2DeleteFlag",
        "type": "select",
        "enum": ["Y", "N"],
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true,
        "label": "Delete Flag",
        "grid": 6,
    },
    {
        "varName": "CurrencyAdd",
        "type": "title",
        "label": "Currency Add",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true

    },
    {
        "varName": "existingCurrency",
        "type": "text",
        "label": "Existing Currency",
        "grid": 6,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true
    },
    {
        "varName": "newCurrency",
        "type": "autoCompleteValueReturn",
        "label": "New Currency",
        "enum": [],
        "required": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true
    },
    {
        "varName": "AddressChange",
        "type": "title",
        "label": "Address Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true

    },
    {
        "type": "title",
        "label": "Mailing Address",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "maillingAdressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "maillingAdressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        "label": "Address type",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "maillingAdressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        "label": "Address label",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "maillingAdressmailingAddressField1",
        "type": "text",
        "label": "Mailing Address Field 1",
        "validationB": "length",
        "maxLength": 45,
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "maillingAdressaddrssType"],
        "conditionalArrayValue": [true, "Mailing"]
    },

    {
        "varName": "maillingAdressmailingAddressField2",
        "type": "text",
        "label": "Mailing Address Field 2",
        "validationB": "length",
        "maxLength": 45,
        "grid": 6,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "maillingAdressaddrssType"],
        "conditionalArrayValue": [true, "Mailing"]
    },

    {
        "varName": "maillingAdresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "maillingAdressaddrssType"],
        "conditionalArrayValue": [true, "Mailing"]
    },

    {
        "varName": "maillingAdressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "maillingAdressaddrssType"],
        "conditionalArrayValue": [true, "Mailing"]
    },

    {
        "varName": "maillingAdresspostalCode",
        "type": "text",
        "label": "Postal Code",
        "validationB": "length",
        "maxLength": 10,
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "maillingAdressaddrssType"],
        "conditionalArrayValue": [true, "Mailing"]
    },

    {
        "varName": "maillingAdresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "maillingAdressaddrssType"],
        "conditionalArrayValue": [true, "Mailing"]
    },
    {
        "type": "title",
        "label": "Present Address",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "presentAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "required": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Future/OnSite",
            "value": "Future/OnSite"
        }],
        "label": "Address type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Future/OnSite",
            "value": "Future/OnSite"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "presentAddresspresentAddressField1",
        "type": "text",
        "label": "Present Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "presentAddressaddrssType"],
        "conditionalArrayValue": [true, "Future/OnSite"]

    },

    {
        "varName": "presentAddresspresentAddressField2",
        "type": "text",
        "label": "Present Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "presentAddressaddrssType"],
        "conditionalArrayValue": [true, "Future/OnSite"]
    },

    {
        "varName": "presentAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "presentAddressaddrssType"],
        "conditionalArrayValue": [true, "Future/OnSite"]
    },

    {
        "varName": "presentAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "presentAddressaddrssType"],
        "conditionalArrayValue": [true, "Future/OnSite"]
    },

    {
        "varName": "presentAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "presentAddressaddrssType"],
        "conditionalArrayValue": [true, "Future/OnSite"]
    },

    {
        "varName": "presentAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "presentAddressaddrssType"],
        "conditionalArrayValue": [true, "Future/OnSite"]
    },
    {
        "varName": "presentAddressDeleteFlag",
        "type": "select",
        "enum": ["Y", "N"],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "presentAddressaddrssType"],
        "conditionalArrayValue": [true, "Future/OnSite"]
    },
    {
        "type": "title",
        "label": "Work Address",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "workAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "workAddressaddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Work",
            "value": "Work"
        }],
        "label": "Address type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "workAddressaddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Work",
            "value": "Work"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "workAddressworkAddressField1",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "workAddressaddressType"],
        "conditionalArrayValue": [true, "Work"]
    },

    {
        "varName": "workAddressworkAddressField2",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "workAddressaddressType"],
        "conditionalArrayValue": [true, "Work"]
    },

    {
        "varName": "workAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "workAddressaddressType"],
        "conditionalArrayValue": [true, "Work"]
    },

    {
        "varName": "workAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "workAddressaddressType"],
        "conditionalArrayValue": [true, "Work"]
    },

    {
        "varName": "workAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "workAddressaddressType"],
        "conditionalArrayValue": [true, "Work"]
    },

    {
        "varName": "workAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "workAddressaddressType"],
        "conditionalArrayValue": [true, "Work"]
    },
    {
        "varName": "workAddressDeleteFlag",
        "type": "select",
        "enum": ["Y", "N"],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "workAddressaddressType"],
        "conditionalArrayValue": [true, "Work"]
    },
    {
        "type": "title",
        "label": "Home Address",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "homeAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "required": true,
        "label": "Address Format",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "homeAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Home",
            "value": "Home"
        }],
        "onKeyDown": true,
        "label": "Address type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "homeAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Home",
            "value": "Home"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "homeAddresshomeAddressField1",
        "type": "text",
        "label": "Home Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "homeAddressaddrssType"],
        "conditionalArrayValue": [true, "Home"]
    },

    {
        "varName": "homeAddresshomeAddressField2",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "homeAddressaddrssType"],
        "conditionalArrayValue": [true, "Home"]
    },

    {
        "varName": "homeAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "homeAddressaddrssType"],
        "conditionalArrayValue": [true, "Home"]
    },

    {
        "varName": "homeAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "homeAddressaddrssType"],
        "conditionalArrayValue": [true, "Home"]
    },

    {
        "varName": "homeAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "homeAddressaddrssType"],
        "conditionalArrayValue": [true, "Home"]
    },

    {
        "varName": "homeAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "homeAddressaddrssType"],
        "conditionalArrayValue": [true, "Home"]
    },
    {
        "varName": "homeAddressDeleteFlag",
        "type": "select",
        "enum": ["Y", "N"],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "homeAddressaddrssType"],
        "conditionalArrayValue": [true, "Home"]
    },
    {
        "type": "title",
        "label": "NRE Address",
        "grid": 12,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange"],
        "conditionalArrayValue": [true],
    },
    {
        "varName": "nreFlag",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Non Resident",
        "onKeyDown": true,
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange"],
        "conditionalArrayValue": [true],
    },
    {
        "varName": "chairmanCertificateturnedNonResidentOn",
        "type": "date",
        "label": "Turned Non Resident On",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "Y"],
    },
    {
        "varName": "nreAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "Y"],
        "grid": 6,
    },

    {
        "varName": "nreAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "NRERelative",
            "value": "NRERelative"
        }],
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "Y"],
        "label": "Address type",
        "grid": 6,
    },

    {
        "varName": "nreAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "NRERelative",
            "value": "NRERelative"
        }],
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "nreAddressaddrssType", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "NRERelative", "Y"],
        "label": "Address label",
        "grid": 6,
    },
    {
        "varName": "nreAddressnreAddressField1",
        "type": "text",
        "label": "NRE Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "nreAddressaddrssType", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "NRERelative", "Y"],

    },

    {
        "varName": "nreAddressnreAddressField2",
        "type": "text",
        "label": "NRE Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "nreAddressaddrssType", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "NRERelative", "Y"],
    },

    {
        "varName": "nreAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "nreAddressaddrssType", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "NRERelative", "Y"],
    },

    {
        "varName": "nreAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "nreAddressaddrssType", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "NRERelative", "Y"],
    },

    {
        "varName": "nreAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "nreAddressaddrssType", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "NRERelative", "Y"],
    },

    {
        "varName": "nreAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "nreAddressaddrssType", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "NRERelative", "Y"],

    },
    {
        "varName": "nreAddressDeleteFlag",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["addressChange", "nreAddressaddrssType", "chairmanCertificatenonResident"],
        "conditionalArrayValue": [true, "NRERelative", "Y"],
    },
    {
        "varName": "TitleChange",
        "type": "title",
        "label": "Title Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "titleOld",
        "type": "autoCompleteValueReturn",
        "label": "Existing Account Title",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "existingNameOld",
        "type": "text",
        "required": true,
        "label": "Existing Name",
        "validation": "string",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "existingShortName",
        "type": "text",
        "required": true,
        "label": "Existing Short Name",
        "validation": "string",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "gender",
        "type": "select",
        "enum": [
            "M",
            "F",
            // "TRANSGENDER",
            "O"
        ],
        "label": "Gender",
        "required": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "uploadSignature",
        "type": "checkbox",
        "readOnly": true,
        "label": "Signature Card Upload",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "PhotoIDChange",
        "type": "title",
        "label": "Photo ID Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,
    },
    {
        "type": "title",
        "label": "Passport Number",
        "grid": 12,
        "conditionalArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId"],
        "conditionalArrayValue": [true, true],
    },
    {
        "varName": "passportNumberdocumentTypeP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId"],
        "conditionalArrayValue": [true, true],
    },

    {
        "varName": "passportNumberdocumentTypeDescriptionP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId"],
        "conditionalArrayValue": [true, true],
    },

    {
        "varName": "passportNumberdocumentCodeP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "PASSPORT",
            "value": "PASSPORT IDENTITY"
        }],
        "label": "Document Code",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId"],
        "conditionalArrayValue": [true, true],
    },

    {
        "varName": "passportNumberpassportNumberUniqueId",
        "type": "text",
        "label": "Passport Number/Unique ID",
        "grid": 6,
        "validation": "passport",
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId", "passportNumberdocumentCodeP"],
        "conditionalArrayValue": [true, true, "PASSPORT"]
    },

    {
        "varName": "passportNumberplaceOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Place of Issue",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId", "passportNumberdocumentCodeP"],
        "conditionalArrayValue": [true, true, "PASSPORT"]
    },

    {
        "varName": "passportNumberissueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId", "passportNumberdocumentCodeP"],
        "conditionalArrayValue": [true, true, "PASSPORT"]
    },

    {
        "varName": "passportNumbercountryOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country of Issue",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId", "passportNumberdocumentCodeP"],
        "conditionalArrayValue": [true, true, "PASSPORT"]
    },

    {
        "varName": "passportNumberexpiryDate",
        "type": "date",
        "label": "Expiry Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId", "passportNumberdocumentCodeP"],
        "conditionalArrayValue": [true, true, "PASSPORT"]
    },
    {
        "varName": "passportDeleteFlag",
        "type": "select",
        "enum": ["Y", "N"],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId", "passportNumberdocumentCodeP"],
        "conditionalArrayValue": [true, true, "PASSPORT"]
    },
    {
        "type": "title",
        "label": "Birth Certificate",
        "grid": 12,
        "conditionalArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId"],
        "conditionalArrayValue": [true, true],
    },
    {
        "varName": "birthCertificatedocumentTypeB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId"],
        "conditionalArrayValue": [true, true],
    },

    {
        "varName": "birthCertificatedocumentTypeDescriptionB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId"],
        "conditionalArrayValue": [true, true],
    },

    {
        "varName": "birthCertificatedocumentCodeB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "BC",
            "value": "BIRTH CERTIFICATE"
        }],
        "label": "Document Code",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId"],
        "conditionalArrayValue": [true, true],
    },

    {
        "varName": "birthCertificatebirthCertificateNumberUniqueId",
        "type": "text",
        "label": "Birth Certificate Number/Unique ID",
        "grid": 6,
        "validation": "birthCertificateNumber",
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId", "birthCertificatedocumentCodeB"],
        "conditionalArrayValue": [true, true, "BC"]
    },
    {
        "varName": "birthCertificateDeleteFlag",
        "type": "select",
        "enum": ["Y", "N"],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId", "birthCertificatedocumentCodeB"],
        "conditionalArrayValue": [true, true, "BC"]
    },
    {
        "type": "title",
        "label": "Chairman Certificate",
        "grid": 12,
        "conditionalArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId"],
        "conditionalArrayValue": [true, true],
    },

    {
        "varName": "chairmanCertificatedocumentTypeCC",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId"],
        "conditionalArrayValue": [true, true],
    },

    {
        "varName": "chairmanCertificatedocumentTypeDescriptionCC",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId"],
        "conditionalArrayValue": [true, true],
    },

    {
        "varName": "chairmanCertificatedocumentCodeCC",
        "type": "autoCompleteValueReturn",
        "enum": [{"masterDataName": "documentCode", "key": "CHAC", "value": "CHAIRMAN CERTIFICATE"}],
        "label": "Document Code",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId"],
        "conditionalArrayValue": [true, true],
    },

    {
        "varName": "chairmanCertificateUniqueId",
        "type": "text",
        "label": "Chairman Certificate/Unique ID",
        "grid": 6,
        "validationB": "length",
        "maxLength": 20,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId", "chairmanCertificatedocumentCodeCC"],
        "conditionalArrayValue": [true, true, "CHAC"]
    },
    {
        "varName": "chairmanCertificateDeleteFlag",
        "type": "select",
        "label": "Delete Flag",
        "enum": ["Y", "N"],
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId", "chairmanCertificatedocumentCodeCC"],
        "conditionalArrayValue": [true, true, "CHAC"]
    },
    {
        "type": "title",
        "label": "Driving License",
        "grid": 12,
        "conditionalArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId"],
        "conditionalArrayValue": [true, true],
    },
    {
        "varName": "drivingLicensedocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId"],
        "conditionalArrayValue": [true, true],
    },

    {
        "varName": "drivingLicensedocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId"],
        "conditionalArrayValue": [true, true],
    },

    {
        "varName": "drivingLicensedocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "DL",
            "value": "DRIVING LICENSE"
        },],
        "label": "Document Code",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId"],
        "conditionalArrayValue": [true, true],
    },
    {
        "varName": "drivingLicenseNumberUniqueId",
        "type": "text",
        "label": "Driving ID Card Number/Unique ID",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId", "drivingLicensedocumentCodeN"],
        "conditionalArrayValue": [true, true, "DL"]
    },
    {
        "varName": "drivingLicenseDeleteFlag",
        "type": "select",
        "enum": ["Y", "N"],
        "label": "Delete Flag",
        "grid": 6,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId", "drivingLicensedocumentCodeN"],
        "conditionalArrayValue": [true, true, "DL"]
    },


    {
        "type": "title",
        "label": "Resident ID",
        "grid": 12,
        "conditionalArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId"],
        "conditionalArrayValue": [true, true],
    },
    {
        "varName": "residentdocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId"],
        "conditionalArrayValue": [true, true],
    },

    {
        "varName": "residentdocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId"],
        "conditionalArrayValue": [true, true],
    },

    {
        "varName": "residentdocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "RESID",
            "value": "RESIDENT ID CARD"
        },],
        "label": "Document Code ",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId"],
        "conditionalArrayValue": [true, true],
    },
    {
        "varName": "residentNumberUniqueId",
        "type": "text",
        "label": "Resident ID",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId", "residentdocumentCodeN"],
        "conditionalArrayValue": [true, true, "RESID"]
    },
    {
        "varName": "residentIdDeleteFlag",
        "type": "select",
        "label": "Delete Flag",
        "enum": ["Y", "N"],
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["photoIdChange", "showPhotoId", "residentdocumentCodeN"],
        "conditionalArrayValue": [true, true, "RESID"]
    },
    {
        "varName": "OtherContactNumberChange",
        "type": "title",
        "label": "Other Contact Number Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "type": "title",
        "label": "Contact Number - Phone 2",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "contactNumbercontactType2",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH2",
            "value": "COMMPH2"
        }],
        "label": "Contact Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,
    },

    {
        "varName": "contactNumberphoneNoEmailId2",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No.",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["otherContactNumberChange", "contactNumbercontactType2"],
        "conditionalArrayValue": [true, "COMMPH2"]
    },
    {
        "varName": "existingResidenceNumber",
        "type": "text",
        "label": "Existing Residence Contact Number",
        "grid": 6,
        "readOnly": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["otherContactNumberChange", "contactNumbercontactType2"],
        "conditionalArrayValue": [true, "COMMPH2"]
    },
    {
        "varName": "mobileTypecontactNumberphoneNo2",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required": true,
        "label": "Mobile Type",
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["otherContactNumberChange", "contactNumbercontactType2"],
        "conditionalArrayValue": [true, "COMMPH2"],
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["otherContactNumberChange", "contactNumbercontactType2", "mobileTypecontactNumberphoneNo2"],
        "conditionalArrayValue": [true, "COMMPH2", "Overseas"],
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo2",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["otherContactNumberChange", "contactNumbercontactType2", "mobileTypecontactNumberphoneNo2"],
        "conditionalArrayValue": [true, "COMMPH2", "Local"],

    },
    {
        "varName": "contactNumberphoneNo2",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["otherContactNumberChange", "contactNumbercontactType2", "mobileTypecontactNumberphoneNo2"],
        "conditionalArrayValue": [true, "COMMPH2", "Overseas"],
    },
    {
        "varName": "PhoneNumber2DeleteFlag",
        "type": "select",
        "enum": ["Y", "N"],
        "label": "Delete Flag",
        "grid": 6,
        // "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["otherContactNumberChange", "contactNumbercontactType2"],
        "conditionalArrayValue": [true, "COMMPH2"],
    },

    {
        "type": "title",
        "label": "Contact Number - Phone 3",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "contactNumbercontactType3",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "WORKPH1",
            "value": "WORKPH1"
        }],
        "label": "Contact Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,
    },

    {
        "varName": "contactNumberphoneNoEmailId3",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No.",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["otherContactNumberChange", "contactNumbercontactType3"],
        "conditionalArrayValue": [true, "WORKPH1"],
    },
    {
        "varName": "existingOfficeNumber",
        "type": "text",
        "label": "Existing Office Contact Number",
        "grid": 6,
        "readOnly": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["otherContactNumberChange", "contactNumbercontactType3"],
        "conditionalArrayValue": [true, "WORKPH1"],
    },
    {
        "varName": "mobileTypecontactNumbercontactType3",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required": true,
        "label": "Mobile Type",
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["otherContactNumberChange", "contactNumbercontactType3"],
        "conditionalArrayValue": [true, "WORKPH1"],
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo3",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["otherContactNumberChange", "contactNumbercontactType3", "mobileTypecontactNumbercontactType3"],
        "conditionalArrayValue": [true, "WORKPH1", "Overseas"],
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo3",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["otherContactNumberChange", "contactNumbercontactType3", "mobileTypecontactNumbercontactType3"],
        "conditionalArrayValue": [true, "WORKPH1", "Local"],
    },
    {
        "varName": "contactNumberphoneNo3",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["otherContactNumberChange", "contactNumbercontactType3", "mobileTypecontactNumbercontactType3"],
        "conditionalArrayValue": [true, "WORKPH1", "Overseas"],
    },
    {
        "varName": "PhoneNumber3DeleteFlag",
        "type": "select",
        "label": "Delete Flag",
        "enum": ["Y", "N"],
        "grid": 6,
        // "required": true,
        "masterData": true,
        "masterDataArray": true,
        "conditionalArrayList": ["otherContactNumberChange", "contactNumbercontactType3"],
        "conditionalArrayValue": [true, "WORKPH1"],
    },
    {
        "varName": "OtherInformationChange",
        "type": "title",
        "label": "Other Information Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "titleOld",
        "type": "autoCompleteValueReturn",
        "label": "Existing Account Title",
        "required": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "gender",
        "type": "select",
        "enum": [
            "M",
            "F",
            // "TRANSGENDER",
            "O"
        ],
        "required": true,
        "label": "Gender",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "profession",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Profession",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "constitutionCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Constitution",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "occupationCodeCB",
        "type": "select",
        "enum": [],
        "label": "Occupation Code(CB)",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "occupationCodeAccount",
        "type": "select",
        "enum": [],
        "label": "Occupation Code(Account)",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "fatherName",
        "type": "text",
        "label": "Father's Name",
        "validation": "string",
        // "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "motherName",
        "type": "text",
        "label": "Mother's Name",
        "validation": "string",
        // "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "maritalStatus",
        "type": "autoCompleteValueReturn",
        "label": "Marital Status",
        // "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "spouseName",
        "type": "text",
        "label": "Spouse Name",
        "validation": "string",
        // "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "dobOld",
        "type": "date",
        "label": "DOB",
        // "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "employerName",
        "type": "text",
        "label": "Employer Name",
        // "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sector Code",
        "grid": 6,
        "onKeyDown": true,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["otherInformationChange", "showSecCode"],
        "conditionalArrayValue": [true, true]
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Sector Code",
        "grid": 6,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["otherInformationChange", "sectorCodeApicall", "showSecCode"],
        "conditionalArrayValue": [true, true, true]
    },
    {
        "varName": "DormantAccountActivation",
        "type": "title",
        "label": "Dormant Account Activation",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "onlyDormantAccountActivation",
        "conditionalVarValue": true
    },
    {
        "varName": "DormantAccountActivation",
        "type": "title",
        "label": "Dormant Account Activation And Data Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "dormantAccountActivation",
        "conditionalVarValue": true
    },
    {
        "varName": "accountStatus",
        "type": "select",
        "enum": [
            "A",
            "D"
        ],
        "required": true,
        "label": "Account Status",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "onlyDormantAccountActivation",
        "conditionalVarValue": true
    },
    {
        "varName": "accountStatus",
        "type": "select",
        "enum": [
            "A",
            "D"
        ],
        "required": true,
        "label": "Account Status",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dormantAccountActivation",
        "conditionalVarValue": true
    },
    {
        "varName": "dormantActivationCharge",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "label": "Dormant Activation Charge",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "onlyDormantAccountActivation",
        "conditionalVarValue": true
        // "conditionalArray": true,
        // "conditionalArrayList": ["dormantAccountActivation", "showDormant"],
        // "conditionalArrayValue": [true, true]
    },
    {
        "varName": "dormantActivationCharge",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "label": "Dormant Activation Charge",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "dormantAccountActivation",
        "conditionalVarValue": true
    },
    {
        "varName": "ProjectRelatedDataUpdateADUP,CIB",
        "type": "title",
        "label": "Data Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "projectRelatedDataUpdateADUP",
        "conditionalVarValue": true

    },
    {
        "varName": "SignatureCard",
        "type": "title",
        "label": "Signature Card Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "signatureCard",
        "conditionalVarValue": true

    },
    {
        "varName": "tpKyc",
        "type": "title",
        // "label": "TP KYC Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "tpKyc",
        "conditionalVarValue": true
    },
    {
        "varName": "siCreation",
        "type": "title",
        // "label": "TP KYC Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "siCreation",
        "conditionalVarValue": true
    },
    {
        "varName": "modifyExistingSi",
        "type": "title",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "modifyExistingSi",
        "conditionalVarValue": true
    },
    {
        "varName": "deleteExistingSi",
        "type": "title",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "deleteExistingSi",
        "conditionalVarValue": true
    },
    {
        "varName": "accountFreezeMark",
        "type": "title",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "accountFreezeMark",
        "conditionalVarValue": true
    },
    {
        "varName": "accountUnfreezeMark",
        "type": "title",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "accountUnfreezeMark",
        "conditionalVarValue": true
    },
    {
        "varName": "decreasedMark",
        "type": "title",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "decreasedMark",
        "conditionalVarValue": true
    },
    {
        "varName": "NomineeUpdate",
        "type": "title",
        "label": "Nominee Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true

    },
    {
        "varName": "nomineeNumberOld",
        "type": "text",
        "label": "Existing No of Nominee",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nomineeRegNo",
        "type": "text",
        "label": "Registration No",
        "grid": 6,
        // "readOnly": true,
        "required": true,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true
    },
    {
        "type": "empty",
        "grid": 12
    },
];

let maintenanceForChecker = {};
maintenanceForChecker = makeReadOnlyObject(JSON.parse(JSON.stringify(maintenanceForMaker)));


let jsonForOnlyLinkedCB = [
    {
        "type": "title",
        "label": "MIS RELATED",
        "grid": 12,
    },
    {
        "varName": "miscustomerIdCifId",
        "type": "text",
        //"required": true,
        "label": "Customer ID/CIF ID",
        "grid": 6,
        "readOnly": true,
        // "required": true,
    },
    {
        "varName": "mistitle",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //mistitle=inputData.customerTitle
        "label": "Title",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "miscustomerNameLastName",
        "type": "text",
        "required": true,
        "validationB": "length",
        "maxLength": 80,
        //inputData.miscustomerNameLastName=inputData.customerName;
        "label": "Customer Name/Last Name",
        "grid": 6,
    },

    {
        "varName": "misshortName",
        "type": "text",
        "required": true,
        "validationB": "length",
        "maxLength": 10,
        //inputData.misshortName=inputData.shortName;

        "label": "Short Name",
        "grid": 6,
    },


    {
        "varName": "miscustomerStatusNormalStaffPriorityEtc",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Customer Status",
        //inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.customerStatus
        "grid": 6,
        "required": true,
    },

    {
        "varName": "misoccupationCodePriorityCodeStaff",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "misconstitution",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Constitution",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "misstaffIndicatorStatus",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "label": "Staff Indicator/Status",
        "grid": 6,
    },

    {
        "varName": "misstaffIdNumber",
        "type": "text",
        "label": "Staff ID Number",
        "validation": "staffId",
        "grid": 6,
    },

    {
        "varName": "mispriorityCenterCodeFreeCode1",
        "type": "select",
        "enum": [
           "PR104",
            "PR142",
            "PR177",
            "PR186",
            "PR187",
            "PR195",
            "PR208",
            "PR169",
        ],
        "label": "Priority Center Code/Free Code 1",
        "grid": 6,
    },

    {
        "varName": "mispriorityMonitoringRmCodeFreeCode3",
        "type": "textApiCall",
        "enum": [],
        "label": "Priority Monitoring  RM Code",
        "grid": 6,
    },
    {
        "varName": "mispriorityMonitoringRmCodeFreeCode3Name",
        "type": "text",
        "label": "Priority Monitoring  RM Code Name",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "mispriorityMonitoringRmCodeFreeCode3ApiCall",
        "conditionalVarValue": true,
        "grid": 6,
    },

    {
        "varName": "misprimaryCbHolderForPriorityFreeText8",
        "type": "text",
        "label": "Primary CB Holder for Priority",
        "grid": 6,
    },

    {
        "varName": "misrelationshipWithPrimaryCbHolderFreeText9",
        "type": "text",
        "label": "Relationship with Primary CB Holder",
        "grid": 6,
    },
    {
        "varName": "misbbkSegmentCodeFreeCode7",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "BBK Segment Code",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "mismigratedNidVerificationFreeText2",
        "type": "text",
        "label": "Migrated NID Verification",
        "grid": 6,
    },

    {
        "varName": "mismigratedSmartCardNumberFreeText5",
        "type": "text",
        "label": "Migrated Smart Card Number",
        "grid": 6,
    },

    {
        "varName": "mispepStatusFreeText10",
        "type": "text",
        "label": "PEP Status",
        "grid": 6,
    },

    {
        "varName": "mismigratedCifsDocumentNoFreeText13",
        "type": "text",
        "label": "Migrated CIFs Document No",
        "grid": 6,
    },
    {
        "varName": "misfreeCodeFreeText1",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText2",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText3",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misavailedTradeServices",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Availed Trade services",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Contact Number - Email",
        "grid": 12,
    },
    {
        "varName": "contactNumberEmailpreferedEmailIdType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "COMMEML",
            "value": "COMMEML"
        }],
        "readOnly": true,
        "label": "Prefered Email ID type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailType1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "COMMEML",
            "value": "COMMEML"
        }],
        "label": "Email Type",
        "readOnly": true,
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId4",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "EMAIL",
            "value": "EMAIL"
        }],
        "label": "Phone No./Email ID",
        "readOnly": true,
        "grid": 6,
    },

    {
        "varName": "contactNumberEmaildesignatedEmailAddressEmailId1",
        "type": "text",
        "label": "Designated Email Address/Email ID 1",
        "grid": 6,
    },
    {
        "varName": "contactNumberEmaildesignatedEmailAddressEmailId1Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,


    },
    {
        "type": "title",
        "label": "Contact Number - Phone",
        "grid": 12,
    },
    {
        "varName": "contactNumberpreferredContactNoType1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH1",
            "value": "COMMPH1"
        }],
        "label": "Preferred Contact no type",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 1",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH1",
            "value": "COMMPH1"
        }],
        "label": "Contact Type",
        "grid": 6,
        "readOnly": true,
    },

    {
        "varName": "contactNumberphoneNoEmailId1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No./Email ID",
        "grid": 6,
        "readOnly": true,
    },
    {
        "varName": "mobileTypecontactNumberphoneNo1",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required": true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType1",
        "conditionalVarValue": "COMMPH1",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Overseas",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNo1",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo1",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Overseas",

    },
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "type": "title",
        "label": "Primary Introducer details",
        "grid": 12,
    },
    {
        "varName": "nidVerified",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "required": true,
        "label": "Is All NID/Smart Card Document Verified?",
        "grid": 6,

    },
    {
        "varName": "primryintroducerCustomerIdCifId",
        "type": "textApiCall",
        //inputData.primryintroducerCustomerIdCifId=inputData.introducerId
        "label": "Introducer Customer Id/CIF ID",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },


    {
        "varName": "primryiintroducerNameLastName",
        "type": "text",
        //inputData.primryiintroducerNameLastName=inputData.introducerName
        "label": "Introducer Name/Last Name",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["introducerIdApiCall", "nidVerified"],
        "conditionalArrayValue": [true, "NO"]

    },

    {
        "varName": "primryiintroducerStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Introducer Status",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },

    {
        "varName": "primryicifTypePrimaryIntroducerDetails",
        "type": "select",
        "enum": [
            "Retail",
            "Corporate",
        ],
        "label": "CIF Type/Primary Introducer Details",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "type": "title",
        "label": "CRM MIS",
        "grid": 12,
    },
    {
        "type": "title",
        "label": "Basic Information Update",
        "grid": 12,
    },
    {
        "varName": "basicInformationUpdateprimarySolId",
        "type": "text",
        "enum": [],
        "readOnly": true,
        "label": "Primary Sol ID",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdategender",
        "type": "select",
        "enum": [
            "M",
            "F",
            "TRANSGENDER",
            "O"
        ],
        "label": "Gender",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdatefatherNamePrefferedName",
        "type": "text",
        "label": "Father Name/Preffered Name",
        "validationB": "length",
        "maxLength": 50,
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdatemotherNameMothersMaidenName",
        "type": "text",
        "label": "Mother Name/Mothers Maiden Name",
        "validationB": "length",
        "maxLength": 50,
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdatemaritalStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Marital Status",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "basicInformationUpdatespouseNamePurgeRemarks",
        "type": "text",
        "label": "Spouse Name/Purge Remarks",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "basicInformationUpdatemaritalStatus",
        "conditionalVarValue": "MARID",
        "validationB": "length",
        "maxLength": 240,

    },

    {
        "varName": "basicInformationUpdatedob",
        "type": "date",
        "maxDate": true,
        "onKeyDown": true,
        //inputData.basicInformationUpdatedob=inputData.gender
        "label": "DOB",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdateminorStatusMinorIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "readOnly": true,
        "label": "Minor Status/Minor Indicator",
        "conditional": true,
        "conditionalVarName": "basicInformationUpdatedobApiCall",
        "conditionalVarValue": "YES",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateseniorCitizenIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "readOnly": true,
        "label": "Senior Citizen Indicator",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdatedobApiCall",
        "conditionalVarValue": "YES",
        "required": true,
    },
    {
        "type": "title",
        "label": "Gurdian Details - Only Visible In Case Minor Account Holder",
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",

        "grid": 12,
    },


    {
        "varName": "guardianDetailsrelation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation *",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsbankRelationType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Bank Relation Type *",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsrelation1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation *",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsguardianSelectionGurdian",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Guardian Selection/Gurdian",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsgurdianCifNumberCustomer",
        "type": "textApiCall",
        "label": "Gurdian CIF Number/Customer *",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailslastName",
        "type": "text",
        "enum": [],
        "label": "Last Name *",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "guardianDetailsgurdianCifNumberCustomerApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "type": "title",
        "label": "National ID Card",
        "grid": 12,
    },
    {
        "varName": "natioanlIdCarddocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "natioanlIdCarddocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "natioanlIdCarddocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "NID",
            "value": "NID CARD IDENTITY"
        }],
        "label": "Document Code ",
        "grid": 6,

    },

    {
        "varName": "nationalIdCardNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "National ID Card Number/Unique ID",
        "grid": 6,
        "validation": "nid",
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "varName": "natioanlIdCardnidVerificationConfirmationIsDocumentVerified1",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "varName": "natioanlIdCardissueDateN",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },
    {
        "varName": "natioanlIdCardDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "type": "title",
        "label": "Smart ID Card",
        "grid": 12,
    },
    {
        "varName": "smartIdCarddocumentTypeS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "smartIdCarddocumentTypeDescriptionS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "smartIdCarddocumentCodeS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "RSNID",
            "value": "RSNID"
        }],
        "label": "Document Code ",
        "grid": 6,
        //"required": true,
    },

    {
        "varName": "smartCardNumberUniqueId",
        "type": "text",
        "label": "Smart Card Number/Unique ID",
        "grid": 6,
        //"required": true,
        "validation": "smartCard",
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"

    },

    {
        "varName": "smartIdCardnidVerificationConfirmationIsDocumentVerified",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },

    {
        "varName": "smartIdCardissueDateS",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },
    {
        "varName": "smartIdCardDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },
    {
        "type": "title",
        "label": "Passport Number",
        "grid": 12,
    },
    {
        "varName": "passportNumberdocumentTypeP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "passportNumberdocumentTypeDescriptionP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        //inputData.passportNumberdocumentTypeDescriptionP=inputData.passport
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "passportNumberdocumentCodeP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "PASSPORT",
            "value": "PASSPORT IDENTITY"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "passportNumberpassportNumberUniqueId",
        "type": "textApiCall",
        "label": "Passport Number/Unique ID",
        "grid": 6,
        "validation": "passport",
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberplaceOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Place of Issue",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberissueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumbercountryOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country of Issue",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberexpiryDate",
        "type": "date",
        "label": "Expiry Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },
    {
        "varName": "passportNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },
    {
        "type": "title",
        "label": "Driving License",
        "grid": 12,
    },
    {
        "varName": "drivingLicensedocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "drivingLicensedocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "drivingLicensedocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "DL",
            "value": "DRIVING LICENSE"
        },],
        "label": "Document Code",
        "grid": 6,

    },

    {
        "varName": "drivingLicenseNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "Driving ID Card Number/Unique ID",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "drivingLicensedocumentCodeN",
        "conditionalVarValue": "DL"
    },
    {
        "varName": "drivingLicenseNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "drivingLicensedocumentCodeN",
        "conditionalVarValue": "DL"
    },

    {
        "type": "title",
        "label": "Resident ID",
        "grid": 12,
    },
    {
        "varName": "residentdocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "residentdocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "residentdocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "RESID",
            "value": "RESIDENT ID CARD"
        },],
        "label": "Document Code ",
        "grid": 6,

    },

    {
        "varName": "residentNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "Resident ID",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "residentdocumentCodeN",
        "conditionalVarValue": "RESID"
    },
    {
        "varName": "residentNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "residentdocumentCodeN",
        "conditionalVarValue": "RESID"
    },
    {
        "type": "title",
        "label": "Birth Certificate",
        "grid": 12,
    },
    {
        "varName": "birthCertificatedocumentTypeB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "birthCertificatedocumentTypeDescriptionB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "birthCertificatedocumentCodeB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "BC",
            "value": "BIRTH CERTIFICATE"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "birthCertificatebirthCertificateNumberUniqueId",
        "type": "text",
        "label": "Birth Certificate Number/Unique ID",
        //inputData.birthCertificatebirthCertificateNumberUniqueId=inputData.birthCertificateNo
        "grid": 6,
        "validation": "birthCertificateNumber",
        "required": true,
        "masterData": true,
        "conditionalVarName": "birthCertificatedocumentCodeB",
        "conditionalVarValue": "BC"
    },
    {
        "varName": "birthCertificateDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "birthCertificatedocumentCodeB",
        "conditionalVarValue": "BC"
    },
    {
        "type": "title",
        "label": "Chairman Certificate",
        "grid": 12,
    },
    {
        "varName": "chairmanCertificatedocumentTypeCC",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "chairmanCertificatedocumentTypeDescriptionCC",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "chairmanCertificatedocumentCodeCC",
        "type": "autoCompleteValueReturn",
        "enum": [{"masterDataName": "documentCode", "key": "CHAC", "value": "CHAIRMAN CERTIFICATE"}],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificateUniqueId",
        "type": "text",
        "label": "Chairman Certificate/Unique ID",
        "grid": 6,
        "validationB": "length",
        "maxLength": 20,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatedocumentCodeCC",
        "conditionalVarValue": "CHAC"
    },
    {
        "varName": "chairmanCertificateDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatedocumentCodeCC",
        "conditionalVarValue": "CHAC"
    },


    {
        "type": "title",
        "label": "Other Photo ID",
        "grid": 12,
    },


    {
        "varName": "photoIddocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "photoIddocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "photoIddocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "photoIdUniqueId",
        "type": "text",
        "label": "Unique ID",
        "grid": 6,
    },
    {
        "varName": "photoIdDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
    },
    {
        "type": "empty",
        "grid": 12,

    },
    {
        "varName": "chairmanCertificateplaceOfBirthFreeText14",
        "type": "text",
        "label": "Place of Birth/Free Text 14",
        "grid": 6,

    },

    {
        "varName": "chairmanCertificatecountryOfBirthFreeText15",
        "type": "text",
        "label": "Country of Birth/Free Text 15",
        "grid": 6,

    },

    {
        "varName": "Customersnationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Nationality",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "chairmanCertificatenonResident",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Non Resident",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "chairmanCertificateturnedNonResidentOn",
        "type": "date",
        "label": "Turned Non Resident On",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },
    {
        "type": "title",
        "label": "E-Tin",
        "grid": 12,
    },
    {
        "varName": "etindocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "etindocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "etindocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "etineTinNumber",
        "type": "text",
        //inputData.etineTinNumber=inputData.eTin
        "label": "E-Tin Number/Unique ID",
        "grid": 6,
        "validation": "etin",
        "required": true,
        "masterData": true,
        "conditionalVarName": "etindocumentCode",
        "conditionalVarValue": "ETIN"
    },
    {
        "varName": "etinDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "etindocumentCode",
        "conditionalVarValue": "ETIN"
    },
    {
        "type": "title",
        "label": "Employer Data",
        "grid": 12,
    },
    {
        "varName": "employerDataprofessionEmploymentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "employmentType",
            "key": "MEDIA CELEBRITY",
            "value": "MEDIA CELEBRITY"
        }],
        "label": "Profession /Employment Type",
        "grid": 6,
    },


    {
        "varName": "employerDatanameOfEmployerEmployerName",
        "type": "text",
        "label": "Name of Employer/Employer Name",
        "grid": 6,
    },

    {
        "varName": "employerDatamonthlyIncomeGrossIncome",
        "type": "text",
        "label": "Monthly Income/Gross income",
        "grid": 6,
        "required": true,
    },

    {
        "type": "title",
        "label": "Contact Number - Phone 2",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType2",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH2",
            "value": "COMMPH2"
        }],
        "label": "Contact Type",
        "grid": 6,

    },

    {
        "varName": "contactNumberphoneNoEmailId2",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No.",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2"
    },
    {
        "varName": "mobileTypecontactNumberphoneNo2",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required": true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Overseas",
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo2",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo2",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Overseas",

    },
    {
        "varName": "contactNumber2Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2"
    },

    {
        "type": "title",
        "label": "Contact Number - Phone 3",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType3",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "WORKPH1",
            "value": "WORKPH1"
        }],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNoEmailId3",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No.",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1"
    },
    {
        "varName": "mobileTypecontactNumbercontactType3",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required": true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo3",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Overseas",
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo3",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo3",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Overseas",

    },
    {
        "varName": "contactNumber3Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1"
    },

    {
        "type": "title",
        "label": "Contact Number - Email",
        "grid": 12,
    },

    {
        "varName": "contactNumberEmailemailType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "HOMEEML",
            "value": "HOMEEML"
        }],
        "required": true,
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "EMAIL",
            "value": "EMAIL"
        }],
        "label": "Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailId2",
        "type": "text",
        "label": "Email ID 2",
        "grid": 6,
    },
    {
        "varName": "contactNumberEmailemailId2Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "required": true,
        "label": "Delete Flag",
        "grid": 6,
    },

    {
        "type": "title",
        "label": "Mailing Address",
        "grid": 12,
    },
    {
        "varName": "maillingAdresspreferredAddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        //inputData.maillingAdresspreferredAddressType=inputData.SelectCommunicationAddress

        "label": "Preferred Address type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        "label": "Addrss type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressmailingAddressField1",
        "type": "text",
        //inputData.maillingAdressmailingAddressField1=inputData.communicationAddress1
        "label": "Mailing Address Field 1",
        "validationB": "length",
        "maxLength": 45,
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressmailingAddressField2",
        "type": "text",
        //inputData.maillingAdressmailingAddressField2=inputData.communicationAddress2
        "label": "Mailing Address Field 2",
        "validationB": "length",
        "maxLength": 45,
        "grid": 6,
    },

    {
        "varName": "maillingAdresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdresscity=inputData.city2

        "label": "City",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdressstate=inputData.state2

        "label": "Division(State)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdresspostalCode",
        "type": "text",
        "label": "Postal Code",
        "maxLength": 6,
        "validationB": "length",

        //inputData.maillingAdresspostalCode=inputData.postalCode3
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdresscountry=inputData.nationality
        "label": "Country",
        "grid": 6,
        "required": true,
    },

    {
        "type": "title",
        "label": "Home Address",
        "grid": 12,
    },
    {
        "varName": "homeAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "required": true,
        "label": "Address Format",
        "grid": 6,

    },

    {
        "varName": "homeAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Home",
            "value": "Home"
        }],
        "onKeyDown": true,
        "label": "Address type",
        "grid": 6,

    },

    {
        "varName": "homeAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Home",
            "value": "Home"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,

    },

    {
        "varName": "homeAddresshomeAddressField1",
        "type": "text",
        "label": "Home Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresshomeAddressField2",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],

        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "label": "Postal Code",
        "maxLength": 6,
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },
    {
        "varName": "homeAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },
    {
        "type": "title",
        "label": "Work Address",
        "grid": 12,
    },
    {
        "varName": "workAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "workAddressaddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Work",
            "value": "Work"
        }],
        "label": "Address type",
        "grid": 6,
    },

    {
        "varName": "workAddressaddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Work",
            "value": "Work"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "workAddressworkAddressField1",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddressworkAddressField2",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "label": "Postal Code",
        "maxLength": 6,
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "masterData": true,
        "required": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },
    {
        "varName": "workAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },
    //1present address
    {
        "type": "title",
        "label": "Present Address",
        "grid": 12,
    },
    {
        "varName": "presentAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "required": true,
        "grid": 6,
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Future/OnSite",
            "value": "Future/OnSite"
        }],
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Future/OnSite",
            "value": "Future/OnSite"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "presentAddresspresentAddressField1",
        "type": "text",
        "label": "Present Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"

    },

    {
        "varName": "presentAddresspresentAddressField2",
        "type": "text",
        "label": "Present Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "label": "Postal Code",
        "maxLength": 6,
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },
    {
        "varName": "presentAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    //1NRE address
    {
        "type": "title",
        "label": "NRE Address",
        "grid": 12,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
    },
    {
        "varName": "nreAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
        "grid": 6,
    },

    {
        "varName": "nreAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "NRERelative",
            "value": "NRERelative"
        }],
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "nreAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "NRERelative",
            "value": "NRERelative"
        }],
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "nreAddressnreAddressField1",
        "type": "text",
        "label": "NRE Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"

    },

    {
        "varName": "nreAddressnreAddressField2",
        "type": "text",
        "label": "NRE Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "label": "Postal Code",
        "maxLength": 6,
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"

    },
    {
        "varName": "nreAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

];

let jsonForOnlyLinkedCBChecker = {};
jsonForOnlyLinkedCBChecker = makeReadOnlyObject(JSON.parse(JSON.stringify(jsonForOnlyLinkedCB)));

export {
    maintenanceForCs as maintenanceFirst,
    csBearer,
    maintenanceForBm,
    maintenanceForCallAndBom,
    maintenanceForMaker,
    maintenanceForChecker,
    jsonForOnlyLinkedCB,
    jsonForOnlyLinkedCBChecker
}