import React, {Component} from "react";
import Card from "../Card/Card";
import Notification from "../NotificationMessage/Notification";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";
import {Dialog} from "@material-ui/core";
import TableComponent from "../workflow/CommonComponent/TableComponent";
import {commonInboxButton} from "../DeliverableManagement/configuration";
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";

let Form = [
    {
        "varName": "searchType",
        "type": "radio",
        "enum": [
            "Login Log",
            "Activity Log",
        ],
        "label": "Search Type",
        "grid": 2,
        "required": true,
    },
    {
        "type": "empty",
        "grid": 12
    },
    {
        "varName": "username",
        "type": "text",
        "label": "User Name",
        "grid": 2,
        "required": true,
    },
    {
        "type": "empty",
        "grid": 1
    },
    {
        "varName": "startDate",
        "type": "date",
        "label": "Start Date",
        "maxDate": true,
        "grid": 2,
        "required": true,
    },
    {
        "type": "empty",
        "grid": 1
    },
    {
        "varName": "endDate",
        "type": "date",
        "label": "End Date",
        "maxDate": true,
        "grid": 2,
        "required": true,
    },
    {
        "type": "empty",
        "grid": 1
    }
    /* {
         "varName": "userMobileNumber",
         "type": "text",
         "label": "User Mobile Number",
         "grid": 2
     },
     {
         "type": "empty",
         "grid": 1
     },*/

];
let validation = [
    {
        "varName": "firstName",
        "type": "text",
        "label": "First Name",
        "grid": 2,
        "required": true,
    },
    {
        "type": "empty",
        "grid": 1
    },
    {
        "varName": "lastName",
        "type": "text",
        "label": "Last Name",
        "grid": 2,
        "required": true,
    },
    {
        "type": "empty",
        "grid": 1
    },
    {
        "varName": "username",
        "type": "text",
        "label": "User Name",
        "grid": 2,
        "required": true,
    },
    {
        "type": "empty",
        "grid": 1
    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "grid": 2,
        "validation": "email",
        "required": true,
    },
    {
        "type": "empty",
        "grid": 1
    },
    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile Number",
        "grid": 2,
        "validation": "phone",
        "required": true,
        "phonePrefix": true,
    },
    {
        "type": "empty",
        "grid": 1
    },

    /* {
         "varName": "userMobileNumber",
         "type": "text",
         "label": "User Mobile Number",
         "grid": 2
     },
     {
         "type": "empty",
         "grid": 1
     },*/

];


class UsersLoggedIn extends Component {
    state = {
        inputData: {
            searchType: "Login Log"
        },
        selectedDate: {},
        getData: false,
        workstation: false,
        role: false,
        showValue: true,
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        tableArray: [],
        err: false,
        loading: true,
        varValue: {
            searchType: "Login Log"
        },
        errorArray: {},
        errorMessages: {},

        title: "",
        notificationMessage: "",
        errorType: "success",
        alert: false,
        otherRequestTitle: "",
        columns: [
            {field: 'userName', title: 'User ID'},
            {field: 'remote_ip', title: 'Remote IP'},
            {field: 'role', title: 'Role'},
            {field: 'action', title: 'Action'},
        ]
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    renderForceLogout = (username) => {
        this.setState({
            loading: true,
            getData: false
        });
        let url = backEndServerURL + "/users/forceLogout/" + username;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    loading: false,
                    title: "Successful!",
                    notificationMessage: "Successfully Logout!",
                    alert: true,
                });
                this.renderGetAllUser()
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    getData: true,
                    title: "Warning!",
                    notificationMessage: "Operation not successful!",
                    alert: true,
                })
            })

    };
    createTableData = (username) => {

        if (username === LocalstorageEncrypt.encryptStorageFunction.getItem("username")) {
            return "";
        } else {
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                        width: 113,
                    }}
                    onClick={() => this.renderForceLogout(username)}
                >
                    Logout
                </button>
            )
        }


    };

    handleChangePage = (page) => {
        return 0;
    };

    renderTopLabelButton = () => {
        return [{
            name: "Logout",
            style: {...commonInboxButton},
            triggerFunction: this.functionForLogout
        }]
    };

    functionForLogout = (event, data) => {
        event.preventDefault();
        let selectedAppId = [];
        for (let item of data) {
            selectedAppId.push(item.userName)
        }
        this.setState({
            loading: true,
            getData: false
        });
        let single_url = backEndServerURL + "/users/forceLogoutBulk";
        axios.post(single_url, selectedAppId, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    loading: false,
                    title: "Successful!",
                    notificationMessage: "Successfully Logout!",
                    alert: true,
                });
                this.renderGetAllUser()
            }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false,
                title: "Warning!",
                notificationMessage: "Operation not successful!",
                alert: true,
                getData: true
            })
        })
    };


    renderUserDetails = () => {
        if (this.state.getData === true) {
            return (
                <TableComponent tableData={this.state.tableArray} tableColumns={this.state.columns}
                                loader={this.state.loading} maxBodyHeight="450px"
                                totalRow={this.state.tableArray.length} page={0} whiteSpace={"wrap"}
                                selection={true}
                                handleChangePage={this.handleChangePage} rowsPerPage={this.state.tableArray.length}
                                tableButton={this.renderTopLabelButton()}
                />
            )
            // return (
            //     <div>
            //         <Table
            //
            //             tableHovor="yes"
            //             tableHeaderColor="primary"
            //             tableHead={["User Name", "Branch Name", "Remote Ip", "Role", "SOL", "Action"]}
            //             tableData={this.state.tableArray}
            //             tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
            //         />
            //
            //
            //     </div>
            //
            //
            // )
        }
        // else if (this.state.getData === true) {
        //     return (
        //         <div>
        //             <Table
        //
        //                 tableHovor="yes"
        //                 tableHeaderColor="primary"
        //                 tableHead={["User Name", "Role", "Branch Name", "Sol ID", "Pc Host name", "Remote IP", "Event Type", "Event Description", "Event Time", "Prev Data", "Post Data", "User Status"]}
        //                 tableData={this.state.tableArray}
        //                 tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
        //             />
        //
        //
        //         </div>
        //     )
        //
        // }
    };
    renderGetAllUser = () => {
        let tableArray = [];
        let url = backEndServerURL + "/users/loggedIn";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((data) => {
                    // data.userName, data.branchName, data.lastLoginIP, data.userRoleName, data.solId, this.createTableData(data.userName)
                    tableArray.push(
                        {
                            userName: data.userName,
                            branch_name: data.branchName,
                            remote_ip: data.lastLoginIP,
                            role: data.userRoleName,
                            sol: data.solId,
                            action: this.createTableData(data.userName)
                        }
                    );
                });
                console.log(tableArray);
                this.setState({
                    tableArray: tableArray,
                    getData: true,
                    loading: false
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    tableArray: tableArray,
                    loading: false
                })
            })
    };

    componentDidMount() {
        this.renderGetAllUser()
    }


    updateComponent = () => {
        this.forceUpdate();
    };


    render() {
        return (
            <section>
                <Card>
                    {/*<CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h6>User All Logged </h6>

                    </CardHeader>*/}
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        open={this.state.loading}>
                        <DialogContent>

                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    {this.renderNotification()}
                    {this.renderUserDetails()}
                </Card>

            </section>


        )
    }

}

export default UsersLoggedIn;