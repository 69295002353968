import React, {useEffect, useState} from "react";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import CardHeader from "../../../Card/CardHeader";
import CardBody from "../../../Card/CardBody";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import CBNotFound from "../../CASA/CBNotFound";
import Grid from "@material-ui/core/Grid";
import Card from "../../../Card/Card";
import loader from "../../../../Static/loader.gif";
import Notification from "../../../NotificationMessage/Notification";
import theme from "../../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import CommonApi from "../../Maintenance/common/CommonApi";
import SignatureButton from "../../Maintenance/SignatureButton";
import GridContainer from "../../../Grid/GridContainer";
import CheckBox from "@material-ui/core/Checkbox/Checkbox";
import UploadComponent from "../../Maintenance/casa/UploadComponent";
import {requisitionField} from "./CustomDutyJsonForm"
import axios from "axios";
import {backEndServerURL} from "../../../../Common/Constant";
import MyValidation from "../../../JsonForm/MyValidation";
import MaintenanceUtility from "../../Maintenance/static/MaintenanceUtility";
import Table from "../../../Table/Table";
import SelectComponent from "../../../JsonForm/SelectComponent";
import TextFieldComponent from "../../../JsonForm/TextFieldComponent";
import SdnForFundTransfer from "../SdnForFundTransfer";

const documentType = [

    {label: 'Page 1'},
    {label: 'Page 2'},
    {label: 'Page 3'},
    {label: 'Page 4'},
    {label: 'Page 5'},
    {label: 'Page 6'},
    {label: 'Page 7'},
    {label: 'Page 8'},
    {label: 'Page 9'},
    {label: 'Page 10'},
    {label: 'Page 11'},
    {label: 'Page 12'},
    {label: 'Page 13'},
    {label: 'Page 14'},
    {label: 'Page 15'},
    {label: 'Page 16'},
    {label: 'Page 17'},
    {label: 'Page 18'},
    {label: 'Page 19'},
    {label: 'Page 20'},

];

let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];

let jsonForm = [
    {
        "varName": "source",
        "label": "Source",
        "type": "select",
        "enum": [
            "Finacle",
            "Ababil"
        ],
        "grid": 2,
        "required": true
    },
    {
        "varName": "accountNumber",
        "label": "Account Number",
        "type": "text",
        "grid": 2,
        "multiline": true,
        "required": true
    },
];

const MakerRequisition = (props) => {
    const [stateValue, setStateValue] = useState({
        showSearchField: false,
        inputData: {},
        varValue: {},
        showValue: false,
        showRequisitionField: false,
        loading: true,
        title: "",
        notificationMessage: "",
        cbNotFound: false,
        disabled: false,
        SdnForFundTransfer: false,
        getData: true,
        errorArray: {},
        selectedDate: {},
        errorMessages: {},
        appId: 0,
        getRemarks: [],
        values: []
    });


    useEffect(() => {
        if (props.appId !== undefined) {
            CommonApi.getRemarkDetails(props.appId)
                .then((remarksArray) => {
                    setStateValue((prevState => {
                        return {
                            ...prevState,
                            getRemarks: remarksArray
                        }
                    }));
                })
                .catch((error) => {
                    console.log(error)
                });

            CommonApi.getVariables(props.appId)
                .then((response) => {
                    let inputData = {...response.data};
                    if (inputData.source === "Ababil") {
                        CommonApi.ababilAccountDetails(response.data.accountNumber).then((response) => {
                            inputData.accountBalance = response.data.acctEftvBalance;
                            setStateValue((prevState => {
                                return {
                                    ...prevState,
                                    appId: props.appId,
                                    inputData: inputData,
                                    varValue: inputData,
                                    showValue: true,
                                    getData: true,
                                    showRequisitionField: true,
                                    loading: false
                                }
                            }));
                        }).catch((error) => {
                            errorModal(error)
                        });
                    } else {
                        CommonApi.getCustAccountDetails(response.data.accountNumber).then((response) => {
                            inputData.accountBalance = response.data.acctEftvBalance;
                            setStateValue((prevState => {
                                return {
                                    ...prevState,
                                    appId: props.appId,
                                    inputData: inputData,
                                    varValue: inputData,
                                    showValue: true,
                                    getData: true,
                                    showRequisitionField: true,
                                    loading: false
                                }
                            }));
                        }).catch((error) => {
                            errorModal(error)
                        });
                    }

                })
                .catch((error) => {
                    errorModal(error)
                })
        } else {
            setStateValue((prevState => {
                return {
                    ...prevState,
                    showSearchField: true,
                    loading: false
                }
            }));
        }
    }, [props.appId]);

    const errorModal = (error) => {
        setStateValue((prevState => {
            return {
                ...prevState,
                cbNotFound: true,
                title: error.response.data.message,
                loading: false,
            }
        }));
    };


    const updateComponent = () => {

    };


    const resetState = (title, bool, showValue, inputData, loading, showRequisitionField) => {
        setStateValue((prevState => {
            return {
                ...prevState,
                inputData: inputData || stateValue.inputData,
                varValue: inputData || stateValue.inputData,
                showValue: showValue || stateValue.showValue,
                getData: true,
                showRequisitionField: showRequisitionField,
                loading: loading || false,
                disabled: false,
                title: title,
                cbNotFound: bool,
            }
        }));
    };

    const renderNotification = () => {
        if (stateValue.alert) {
            return (
                <Notification type="success" stopNotification={stopNotification} title={stateValue.title}
                              message={stateValue.notificationMessage}/>
            )
        }
    };


    const stopNotification = () => {
        setStateValue((prevState => {
            return {
                ...prevState,
                alert: false,
            }
        }));
    };

    const setSearchInputData = (inputData) => {
        CommonApi.searchDedupWithSDN(stateValue.inputData.accountNumber).then((sdnResponse) => {
            inputData = {...inputData, ...sdnResponse};
            inputData.uploadFileAlertRequest = true;
            inputData.transferType = "RTGS";
            inputData.beneficiaryAccountNumber = "1619602000696";
            inputData.beneficiaryAccountName = "CUSTOM DUTY (ASYCUDA) COLLECTION";
            inputData.beneficiaryBankName = "SONALI BANK LTD.";
            inputData.beneficiaryBranchName = "WAPDA BUILDING CORPORATE -> DHAKA-SOUTH";
            findActiveRoutingNumber(inputData)
        }).catch((error) => {
            resetState(error.response.data.message, true, true, undefined, false, false);
        });
    };

    const searchHandler = () => {
        setStateValue((prevState => {
            return {
                ...prevState,
                loading: true,
            }
        }));
        let inputData = {...stateValue.inputData};
        if (stateValue.inputData.source === "Finacle") {
            CommonApi.getCustAccountDetails(stateValue.inputData.accountNumber).then((response) => {
                if (response.data.acctStatus === "D") {
                    resetState("Dormant account is not acceptable!", true, true, inputData, false, false);
                } else if (response.data.freezeCode === "D" || response.data.freezeCode === "T") {
                    resetState("Freeze account is not acceptable!", true, true, inputData, false, false);
                } else if (response.data.lienDetails && response.data.lienDetails.length > 0) {
                    resetState("Lien account is not acceptable!", true, true, inputData, false, false);
                } else {
                    inputData.cbNumber = response.data.cifId;
                    inputData.customerName = response.data.acctName;
                    inputData.currency = response.data.crncyCode;
                    inputData.solId = response.data.solId;
                    inputData.showAccountBalance = response.data.schmCode !== "SBSTF";
                    inputData.accountBalance = response.data.acctEftvBalance;
                    setSearchInputData(inputData)
                }
            }).catch((error) => {
                errorModal(error)
            });
        }
        else{
            CommonApi.ababilAccountDetails(stateValue.inputData.accountNumber).then((response) => {
                inputData.cbNumber = response.data.cifId;
                inputData.customerName = response.data.acctName;
                inputData.currency = response.data.crncyCode;
                inputData.solId = response.data.solId;
                inputData.showAccountBalance = response.data.productCode !== "SBSTF";
                inputData.accountBalance = response.data.acctEftvBalance;
                setSearchInputData(inputData)
            }).catch((error) => {
                errorModal(error)
            });
        }
    };


    const findActiveRoutingNumber = (inputData) => {
        let routingUrl = backEndServerURL + "/finMasterData/activeRoutingNo/" + inputData.beneficiaryBankName + "/" + inputData.beneficiaryBranchName;
        axios.get(routingUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            inputData.routingNumber = response.data;
            let url = backEndServerURL + "/startCase/maker_fund_transfer_data_capture";
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((caseResponse) => {
                    inputData.caseId = caseResponse.caseId;
                    setTimeout(() => {
                        setStateValue((prevState => {
                            return {
                                ...prevState,
                                appId: caseResponse.data.id,
                            }
                        }));
                        resetState(undefined, false, true, inputData, false, true);
                    }, 1000)
                })
                .catch((error) => {
                    errorModal(error)
                });
        }).catch((error) => {
            errorModal(error)
        })
    };

    const amountConvert = (event, data) => {
        let inputData = {...stateValue.inputData};
        setStateValue((prevState => {
            return {
                ...prevState,
                getData: false,
                loading: true,
                disabled: true,
            }
        }));
        inputData.showAmountConvertField = false;
        if (data === "transferAmount") {
            let form = [
                {
                    "varName": "transferAmount",
                    "type": "text",
                    "validation": "length",
                    "maxLength": 14,
                    "required": true
                }
            ];
            let error = MyValidation.defaultValidation(form, stateValue);
            if (error === true) {
                setStateValue((prevState => {
                    return {
                        ...prevState,
                        getData: false,
                        loading: true,
                        disabled: true,
                    }
                }));
                return 0;
            } else {
                let amount = inputData.transferAmount.split(".");
                if (2 < amount.length) {
                    setStateValue((prevState => {
                        return {
                            ...prevState,
                            inputData: inputData,
                            varValue: inputData,
                            getData: true,
                            loading: false,
                            cbNotFound: true,
                            title: "Invalid Amount!"
                        }
                    }));
                } else {
                    let url = backEndServerURL + "/GetWordFromDecimalNumber";
                    let postData = {};
                    postData.number = inputData.transferAmount;
                    axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            inputData.amountInWord = response.data;
                            inputData.showAmountConvertField = true;
                            setTimeout(() => {
                                setStateValue((prevState => {
                                    return {
                                        ...prevState,
                                        inputData: inputData,
                                        varValue: inputData,
                                        getData: true,
                                        loading: false
                                    }
                                }));
                            }, 1000)
                        })
                        .catch((error) => {
                            setStateValue((prevState => {
                                return {
                                    ...prevState,
                                    inputData: inputData,
                                    varValue: inputData,
                                    getData: true,
                                    loading: false,
                                    cbNotFound: true,
                                    title: error.response.data.message
                                }
                            }));
                        });
                }
            }
        }
    };

    const signature = () => {
        if (stateValue.getData) {
            return <SignatureButton accountNumber={stateValue.inputData.accountNumber} signatureType="multiple"
                                    classes={props} requestType={stateValue.inputData.source}/>;
        }
    };

    const renderSearchField = () => {
        if (stateValue.showSearchField === true && stateValue.getData) {
            return (
                <>
                    {CommonJsonFormComponent.renderJsonForm(stateValue, jsonForm, updateComponent)}
                    <Grid item xs={6}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                marginTop: '15px',
                            }}
                            onClick={searchHandler}
                            type="submit"
                        >
                            Search
                        </button>
                        &nbsp;&nbsp;
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                marginTop: '15px',
                            }}
                            onClick={() => window.location.reload()}
                            type="submit"
                        >
                            Reset
                        </button>
                    </Grid>
                    <Grid item xs={12}><br/></Grid>
                </>
            )
        }
    };

    const handleChangeUploadFileAlertRequest = (event) => {
        let data = event.target.checked;
        let inputData = {...stateValue.inputData};
        inputData.uploadFileAlertRequest = data;
        console.log(data);
        setStateValue((prevState => {
            return {
                ...prevState,
                inputData: inputData,
                varValue: inputData,
                cbNotFound: data === false,
                title: data === false ? "Without Document Process!!" : "",
            }
        }));
    };

    const previewImage = (value) => {
        let inputData = {...stateValue.inputData};
        inputData.previewImage = value === "submit";
        setStateValue((prevState => {
            return {
                ...prevState,
                inputData: inputData,
                varValue: inputData,
            }
        }));
    };

    const uploadFunction = () => {
        const {classes} = props;
        if (stateValue.getData) {
            return (
                <GridContainer>
                    <Grid item xs={.5}>
                        <CheckBox
                            style={{marginTop: "-6px"}}
                            checked={stateValue.inputData.uploadFileAlertRequest}
                            defaultValue={stateValue.inputData.uploadFileAlertRequest}
                            onChange={(event) => handleChangeUploadFileAlertRequest(event)}/>
                    </Grid>
                    <Grid item xs={11}>
                        <UploadComponent indexList={documentType} previewImage={previewImage}
                                         appId={stateValue.appId} classes={classes}/>
                    </Grid>
                </GridContainer>
            )
        }
    };

    const handleSubmit = () => {
        setStateValue((prevState => {
            return {
                ...prevState,
                getData: false,
                loading: true,
                disabled: true,
            }
        }));
        let error = MyValidation.defaultValidation(requisitionField, stateValue);
        if (error === true) {
            setStateValue((prevState => {
                return {
                    ...prevState,
                    getData: true,
                    loading: false,
                    disabled: false,
                }
            }));
            return false;
        } else if (stateValue.inputData.uploadFileAlertRequest && stateValue.inputData.previewImage === undefined) {
            setStateValue((prevState => {
                return {
                    ...prevState,
                    getData: true,
                    loading: false,
                    disabled: false,
                    cbNotFound: true,
                    title: "Please Upload File!"
                }
            }));
            return false;
        } else {

            let postData = {...stateValue.inputData};
            let remarksData = {};
            postData.serviceType = "FundTransfer";
            postData.category = stateValue.inputData.transferType;
            postData.subCategory = "Custom Duty Payment";
            postData.subCategory = "Custom Duty Payment";
            postData.subServiceType = "NewFundTransfer";
            postData.freeFlag1="Fund Transfer SD End Initilized";
            remarksData.remark = postData.csRemarks;
            remarksData.map = {...postData, csRemarks: undefined};
            let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + stateValue.appId;
            axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                setStateValue((prevState => {
                    return {
                        ...prevState,
                        getData: true,
                        loading: false,
                        disabled: false,
                        title: "Successful!",
                        notificationMessage: "Successfully Routed!",
                        alert: true,
                    }
                }));
                if (props.appId !== undefined) {
                    props.closeModal()
                } else {
                    setTimeout(function () {
                        window.location.reload()
                    }, 1000);
                }
            }).catch((error) => {
                console.log(error);
                errorModal(error)
            });
        }
    };

    const renderRemarksData = () => {
        if (stateValue.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks</b>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={stateValue.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    const onKeyDownChange = (data) => {
        let postData = {...stateValue.inputData};
        postData[data] = stateValue.selectedDate[data];
        setStateValue((prevState => {
            return {
                ...prevState,
                inputData: postData,
                varValue: postData
            }
        }));
    };

    const handleChangeSdnResult = (bool) => {
        setStateValue((prevState => {
            return {
                ...prevState,
                SdnForFundTransfer: bool
            }
        }));
    };

    const handleSDNSearchButton = () => {
        return (<div>
                <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => handleChangeSdnResult(true)}
                >
                    SDN View
                </button>
            </div>
        )
    };

    const renderRequisitionField = () => {
        if (stateValue.getData && stateValue.showRequisitionField) {
            return <>
                {CommonJsonFormComponent.renderJsonForm(stateValue, requisitionField, updateComponent, amountConvert)}
                <Grid item xs={12}>{signature()}</Grid>
                <Grid item xs={12}>{uploadFunction()}</Grid>
                <Grid item xs={12}>{handleSDNSearchButton()}</Grid>

                <Grid item xs={12}>
                    {renderRemarksData()}
                </Grid>
                <Grid item
                      xs={12}>{CommonJsonFormComponent.renderJsonForm(stateValue, csRemarks, updateComponent)}
                </Grid>
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: '15px',
                    }}
                    onClick={handleSubmit}
                    type="submit"
                >
                    Submit
                </button>
            </>
        }
    };

    const renderHeader = () => {
        if (props.appId !== undefined) {
            return MaintenanceUtility.renderFundTransferHeader(props.freeFlag1, props.caseId, props.solId, props.cbNumber, props.accountNumber, props.customerName, props.closeIcon)
        } else {
            return <h4 style={{color: "white"}} >Custom Duty Payment</h4>
        }
    };

    const closeCBNotFound = () => {
        setStateValue((prevState => {
            return {
                ...prevState,
                cbNotFound: false
            }
        }));
    };


    return (
        <>
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {renderHeader()}
                </CardHeader>
                <br/>
                <CardBody>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        open={stateValue.cbNotFound}>
                        <DialogContent>
                            <CBNotFound
                                closeModal={closeCBNotFound}
                                title={stateValue.title}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        open={stateValue.loading}>
                        <DialogContent>
                            <center><img src={loader} alt=""/></center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="md"
                        open={stateValue.SdnForFundTransfer}>
                        <DialogContent>
                            <SdnForFundTransfer
                                closeModal={() => handleChangeSdnResult(false)}
                                subServiceType="Fund Transfer"
                                searchTableData={true}
                                dedupData={stateValue.inputData.sdnData}
                            />
                        </DialogContent>
                    </Dialog>
                    <Grid container spacing={0}>
                        <ThemeProvider theme={theme}>
                            {renderSearchField()}
                            {renderRequisitionField()}
                        </ThemeProvider>
                    </Grid>
                    <br/>
                    {renderNotification()}
                </CardBody>
            </Card>
        </>
    )
};

export default MakerRequisition;