import React, {Component} from "react";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../../Static/loader.gif";
import Card from "../../../Card/Card";
import CardHeader from "../../../Card/CardHeader";
import CardBody from "../../../Card/CardBody";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {
    documentationMakerJsonFormPartOne,
    documentationMakerJsonFormPartTwo,
    retailLoanJsonFormForDocumentation
} from "./JsonForm";
import Notification from "../../../NotificationMessage/Notification";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Table from "../../../Table/Table";
import CloseIcon from "@material-ui/icons/Close";
import MyValidation from "../../../JsonForm/MyValidation";
import withStyles from "@material-ui/core/styles/withStyles";
import TextFieldComponent from "../../../JsonForm/TextFieldComponent";
import DateComponent from "../../../JsonForm/DateComponent";
import SelectComponent from "../../../JsonForm/SelectComponent";
import ErrorModal from "../../AssetOps/Common/ErrorModal";

let remarks = [
    {
        "varName": "remarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];
let jsonForDiscrepancyType =
    {
        "varName": "discrepancyType",
        "type": "text",
        "label": "Type",
        "grid": 2
    };
let jsonForDiscrepancyDetails =
    {
        "varName": "discrepancyDetails",
        "type": "text",
        "label": "Details",
        "multiline": true,
        "grid": 2
    };
let jsonForDiscrepancySolvedDate =
    {
        "varName": "discrepancySolvedDate",
        "type": "date",
        "label": "Solved Date",
        "grid": 2
    };
let jsonForDiscrepancyDeferral =
    {
        "varName": "discrepancyDeferral",
        "type": "select",
        "label": "Deferral",
        "enum": [
            "Yes",
            "No"
        ],
        "grid": 2
    };

let deferralDetails = [
    {
        "label": "Deferral Details (Some Sub Fields)",
        "type": "title",
        "grid": 12
    },
    {
        "varName": "type",
        "label": "Type",
        "type": "text",
        "grid": 2
    },
    {
        "varName": "deferralTakenDate",
        "label": "Deferral taken date",
        "type": "date",
        "grid": 2
    },
    {
        "varName": "details",
        "label": "Details",
        "type": "text",
        "multiline": true,
        "grid": 2
    },
    {
        "varName": "solvedDate",
        "label": "Solved Date",
        "type": "date",
        "grid": 2
    },
    {
        "varName": "fileUpload",
        "label": "Approval",
        "type": "file",
        "grid": 2
    },
    {
        "varName": "deferralApprovalExpiry ",
        "label": "Deferral Approval Expiry ",
        "type": "date",
        "grid": 2
    },
];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class documentationInbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: false,
            loading: true,
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            title: "",
            notificationMessage: "",
            alert: false,
            appId: 0,
            getRemarks: [],
            discrepancies: [],
            fileUploadData: {},
            getData: false,
            errorModal: false,
            getImageLink: [],
            loader: false,
            getImageBoolean: false,
        }
    }

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    componentDidMount() {
        let start_url = backEndServerURL + "/variables/" + this.props.appId;
        axios
            .get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {

                let discrepancies = [];

                if (response.data.NumberOfdiscrepancyDetails > 0) {
                    for (let i = 0; i < response.data.NumberOfdiscrepancyDetails; i++) {
                        discrepancies.push(i + 1);
                    }
                }

                let remarksArray = [];
                let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        response.data.map((data) => {
                            remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                        });
                        this.setState({
                            getRemarks: remarksArray,
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    });

                let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);
                        this.setState({
                            getImageLink: response.data,
                            getImageBoolean: true,
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                this.setState({
                    inputData: response.data,
                    varValue: this.copyJson(response.data),
                    discrepancies: discrepancies,
                    showValue: true,
                    appId: this.props.appId,
                    getData: true,
                    loading: false
                });

            })
            .catch(error => {
                console.log(error);
            });
    }

    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {

        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{width: "100%"}}>
                    <br/>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                    </div>

                </div>

            )
        }

    };

    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, remarks, this.updateComponent)
    };


    submitHandler = (data) => {

        let error = MyValidation.defaultValidation(documentationMakerJsonFormPartTwo, this.state);
        this.forceUpdate();

        if (error === true) {
            return 0
        } else {

            if (this.state.fileUploadData.fileUpload !== undefined) {
                this.setState({
                    loader: true
                });

                let uploadUrl = backEndServerURL + "/case/upload";
                let formData = new FormData();
                formData.append("appId", this.state.appId);
                formData.append("file", this.state.fileUploadData["fileUpload"]);
                formData.append("type", "file");

                axios({
                    method: "post",
                    url: uploadUrl,
                    data: formData,
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                        "content-type": "multipart/form-data"
                    }
                })
                    .then(response => {
                        if (response.status === 200) {
                            this.functionForRoute(data)
                        } else {
                            this.setState({
                                loader: false,
                                errorModal: true,
                                title: "Failed to Upload File."
                            })
                        }
                    })
                    .catch(error => {
                        this.setState({
                            loader: false,
                            errorModal: true,
                            title: "Failed to Upload File."
                        })
                    });
            } else {
                this.functionForRoute(data)
            }
        }
    };

    closeErrorModal = () => {
        this.setState({
            errorModal: false
        })
    };

    functionForRoute = (data) => {
        let remark_url = backEndServerURL + "/appRemarkSave/" + this.state.inputData.remarks + "/" + this.state.appId;
        axios.post(remark_url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

            })
            .catch(error => {
                console.log(error)
            });

        let postData = this.state.inputData;
        postData.document_maker_approved = data;
        postData.remarks = undefined;
        postData.NumberOfdiscrepancyDetails = this.state.discrepancies.length === 0 ? 0 : this.state.discrepancies.length;

        let url = backEndServerURL + "/variables/" + this.state.appId;
        axios
            .post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {

                let caseRouteUrl =
                    backEndServerURL + "/case/route/" + this.state.appId;
                axios
                    .get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => {
                        this.setState({
                            loader: false,
                            title: "Successful!",
                            notificationMessage: "Successfully Routed!",
                            alert: true,
                        });

                        this.props.closeModal();

                    })
                    .catch(error => {
                        console.log(error);
                    });


            })
            .catch(error => {
                console.log(error)
            });
    };

    renderForm = () => {
        if (this.state.getData) {
            return (
                <ThemeProvider theme={theme}>
                    {CommonJsonFormComponent.renderJsonForm(this.state, retailLoanJsonFormForDocumentation.concat(documentationMakerJsonFormPartOne), this.updateComponent)}
                </ThemeProvider>
            )
        }
    };

    renderFormPartTwo = () => {
        if (this.state.getData) {
            return (
                <ThemeProvider theme={theme}>
                    {CommonJsonFormComponent.renderJsonForm(this.state, documentationMakerJsonFormPartTwo, this.updateComponent)}
                </ThemeProvider>
            )
        }
    };
    renderDeferralDetails = () => {
        if (this.state.getData) {
            return (
                <ThemeProvider theme={theme}>
                    {CommonJsonFormComponent.renderJsonForm(this.state, deferralDetails, this.updateComponent)}
                </ThemeProvider>
            )
        }
    };

    renderAddButtonShow = () => {
        return (
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    float: 'left',
                    verticalAlign: 'left',
                }}
                type='button' value='add more'
                onClick={this.addClick.bind(this)}
            >+ New Discrepancies</button>
        )

    };

    addClick() {
        let defaultNumber = this.state.discrepancies.slice(-1).pop() === undefined ? 0 : this.state.discrepancies.slice(-1).pop();

        this.setState(prevState => ({
            discrepancies: [...prevState.discrepancies, defaultNumber + 1],
        }));
    };

    removeClick(i, event) {
        event.preventDefault();
        let discrepanciesExist = this.state.discrepancies.indexOf(i);
        if (discrepanciesExist > -1) {
            this.state.discrepancies.splice(discrepanciesExist, 1);
            this.updateComponent();
        }
    };

    dynamicDiscrepancyType = (i) => {
        let field = JSON.parse(JSON.stringify(jsonForDiscrepancyType));
        field.varName = "discrepancyType" + i;
        console.log("........", field);
        return TextFieldComponent.text(this.state, this.updateComponent, field);
    };

    dynamicDiscrepancyDetails = (i) => {
        let field = JSON.parse(JSON.stringify(jsonForDiscrepancyDetails));
        field.varName = "discrepancyDetails" + i;
        return TextFieldComponent.text(this.state, this.updateComponent, field);
    };

    dynamicDiscrepancyDate = (i) => {
        let field = JSON.parse(JSON.stringify(jsonForDiscrepancySolvedDate));
        field.varName = "discrepancySolvedDate" + i;
        return DateComponent.date(this.state, this.updateComponent, field);
    };

    dynamicDiscrepancyDeferral = (i) => {
        let field = JSON.parse(JSON.stringify(jsonForDiscrepancyDeferral));
        field.varName = "discrepancyDeferral" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };

    addDiscrepancyForm() {
        return this.state.discrepancies.map((el, i) =>
            <React.Fragment>
                <Grid container spacing={0}>
                    <ThemeProvider theme={theme}>
                        <Grid item xs={2}>
                            {
                                this.dynamicDiscrepancyType(el)
                            }
                        </Grid>
                        <Grid item xs={2}>
                            {
                                this.dynamicDiscrepancyDetails(el)
                            }
                        </Grid>
                        <Grid item xs={2}>
                            {
                                this.dynamicDiscrepancyDate(el)
                            }
                        </Grid>
                        <Grid item xs={2}>
                            {
                                this.dynamicDiscrepancyDeferral(el)
                            }
                        </Grid>
                        <Grid item xs={1.5}>
                            <button
                                style={{float: "right", marginTop: "15px"}}
                                className="btn btn-outline-primary btn-sm"
                                type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                            >
                                Remove
                            </button>
                        </Grid>
                    </ThemeProvider>
                </Grid>
            </React.Fragment>
        )

    };

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <a
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: "middle",
                                display: "block",
                                marginTop: 20,
                            }}
                            target="_blank"
                            href={backEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                        >
                            {splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </a>
                        &nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };


    render() {
        const {classes, onClose} = this.props;

        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <React.Fragment>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >
                                Retail Loan Archiving
                                <a><CloseIcon onClick={this.props.closeModal} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a>
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    {this.renderForm()}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <br/>
                                {this.renderAddButtonShow()}
                                <br/>
                                <br/>
                            </Grid>
                            {this.addDiscrepancyForm()}
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    {this.renderDeferralDetails()}
                                </Grid>
                                <br/>
                                <br/>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    {this.renderFormPartTwo()}
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <ThemeProvider theme={theme}>
                                        {this.mappingPhoto()}
                                    </ThemeProvider>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <ThemeProvider theme={theme}>
                                        {this.renderRemarksData()}

                                        <Grid item xs={12}>

                                        </Grid>

                                        {this.renderRemarks()}
                                    </ThemeProvider>
                                </Grid>
                            </Grid>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.loader}>
                                <DialogContent className={classes.dialogPaper}>

                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.errorModal}>
                                <DialogContent className={classes.dialogPaper}>

                                    <ErrorModal
                                        closeModal={this.closeErrorModal}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>

                            <Grid item xs="3" style={{marginTop: "10px"}}>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        marginRight: "10px",
                                    }}
                                    onClick={() => this.submitHandler('YES')}
                                >
                                    Submit
                                </button>
                                &nbsp;
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        marginRight: "10px",
                                    }}
                                    onClick={() => this.submitHandler("NO")}
                                >
                                    Return
                                </button>
                            </Grid>
                            {
                                this.renderNotification()
                            }
                        </CardBody>
                    </Card>
                </React.Fragment>
            );
        }
    }
}

export default withStyles(styles)(documentationInbox)