import React, {Component} from "react";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../JsonForm/CustomeTheme2";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import Notification from "../NotificationMessage/Notification";
import {backEndServerURL} from "../../Common/Constant";
import MyValidation from "../JsonForm/MyValidation";
import axios from "axios";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";
import ConfirmationModal from "../workflow/FundTransfer/ConfirmationModal";
let Form = [
    {
        varName: "incomingType",
        type: "select",
        enum: ["Courier", "By post (Registered/ Normal)", "By hand"],
        label: "Incoming Types",
        required: true,
        grid: 3,
    },
    {
        varName: "senderName",
        type: "text",
        label: "Sender Name",
        required: true,
        grid: 3,
    },
    {
        varName: "receiverDocumentType",
        type: "select",
        enum: ["Officials", "By Name"],
        label: "Document Type",
        required: true,
        grid: 3,
    },
    {
        varName: "officialConcern",
        type: "autoCompleteValueReturn",
        enum: [],
        label: "Official Concern",
        required: true,
        grid: 3,
        conditional: true,
        conditionalVarName: "receiverDocumentType",
        conditionalVarValue: "Officials",
    },
    {
        varName: "department",
        type: "select",
        enum: [],
        onKeyDown: true,
        label: "Department",
        required: true,
        grid: 3,
    },
    {
        varName: "receiver",
        type: "autoCompleteValueReturn",
        onKeyDown: true,
        enum: [],
        label: "Receiver Name",
        required: true,
        grid: 3,
        conditional: true,
        conditionalVarName: "showReceiverUsers",
        conditionalVarValue: true,

    },
    {
        varName: "contactNumber",
        type: "text",
        label: "Contact Number",
        required: true,
        readOnly: true,
        grid: 3,
        conditional: true,
        conditionalVarName: "showContactNo",
        conditionalVarValue: true,
    },
    {
        varName: "date",
        type: "date",
        dayAdd: 0,
        label: "In Date & Time",
        required: true,
        grid: 3,
    },
    // {
    //     varName: "referenceNumber",
    //     type: "text",
    //     label: "Reference Number",
    //     readOnly: true,
    //     grid: 3,
    // },
    {
        varName: "documentDetails",
        type: "text",
        label: "Document Details",
        required: true,
        grid: 3,
    },
];

class IncomingDispatchIntiator extends Component {
    state = {
        inputData: {},
        selectedDate: {},
        getData: false,
        workstation: false,
        role: false,
        showValue: false,
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        values: [],
        err: false,
        varValue: [],
        errorArray: {},
        errorMessages: {},
        errorWorkPlace: false,
        errorgroup: false,
        title: "",
        loading: true,
        notificationMessage: "",
        alert: false,
        getGroupListData: false,
        getworkplaceTypeListData: false,
        workplace: "",
        type: "success",
        submitButton: false,
        allUsersFromApi:[],
        confirmationAlert: false,
        refNum: null,
    };
    emptyValueRemove = (data) => {
        if (data === "" || data === " " || data === "null" || data === "undefined")
            data = "";
        return data;
    };
    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };
    handleSubmit = (event) => {
        event.preventDefault();
        let input = {...this.state.inputData};
        this.setState({
            loading: true,
            inputData: this.copyJson(input),
            varValue: this.copyJson(input),
        });

        let validationData = [];
        let error = MyValidation.defaultValidation(this.copyJson(Form), this.state);
        this.forceUpdate();
        if (error === true) {
            console.log("Not working");
            this.setState({
                loading: false,
            });
            return 0;
        }
        if (this.props.update !== undefined && this.props.update) {
            this.setState({
                title: "Do you want to confirm?",
                confirmationAlert: true,
                loading: false,
            });
        } else {
            let start_url = backEndServerURL + "/startCase/cheque_book_request_start_initial";
            axios.get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,},})
                .then((response) => {
                    this.setState({
                        appId: response.data.id,
                        caseId: response.data.caseId,
                        refNum: response.data.caseId,
                    });
                    let inputData = {...this.state.inputData}
                    inputData.referenceNumber = response.data.caseId;
                    this.setState({
                        inputData: this.copyJson(inputData),
                        varValue: this.copyJson(inputData)
                    })
                    this.setState({
                        title: `Reference Number: ${response.data.caseId}`,
                        confirmationAlert: true,
                        loading: false,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
     
      
    };

    renderButton = () => {
        return (
            <button
                className="btn btn-outline-primary  btn-sm"
                style={{
                    marginTop: "18px",
                }}
                onClick={this.handleSubmit}
            >
                {this.props.update ? "Re-Assign" : "Received" }
                
            </button>
        );
    };
    componentDidMount() {
        let userObj={}
        let allUsers = [];
        let urls = backEndServerURL + "/user/gdlc/all";
        axios.get(urls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,},})
            .then((users) => {
                
                for (let i = 0; i < users.data.length; i++) {
                    allUsers.push({
                        value: users.data[i].value,
                        key: users.data[i].key,
                    })
                }
                if(this.props.update){                   
                    allUsers.map((user)=>{
                        console.log(user,"BABL001")
                        if(user.value===this.props.rowData.officialConcern){
                                userObj.key= user.key;
                                userObj.value= user.value;
                            
                            return;
        
                        }
                    })
                    console.log(userObj,"BABL002")
                }
         
                for (let form of Form) {
                    if (form.type === "autoCompleteValueReturn" && form.varName === "officialConcern") {
                        form.enum = allUsers;
                    }
                }
            })
            .catch((error) => {
            });
        let roleUrl = backEndServerURL + "/user/GDLC/roles/all";
        axios.get(roleUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,},})
            .then((roles) => {
                for (let form of Form) {
                    if (form.type === "select" && form.varName === "department") {
                        form.enum = roles.data;
                    }
                }
               
            })
            .catch((error) => {
            });
        if (this.props.update !== undefined && this.props.update) {
            let inputData = {...this.props.rowData};
            inputData.officialConcern = userObj;
            inputData.showReceiverUsers = true;
            inputData.incomingType = inputData.incomingType;
            inputData.receiverDocumentType = inputData.receiverDocumentType;
            inputData.showContactNo = true;
            inputData.contactNumber = inputData.contactNumber;
            console.log(inputData,"ADNAN")
            let url = backEndServerURL + "/username/" + inputData.department;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,},})
                .then((response) => {
                    
                    let array = [];
                    for (let i = 0; i < response.data.length; i++) {
                        array.push({
                            value: response.data[i].value,
                            key: response.data[i].key,
                            contactNo: response.data[i].contactNo,
                        })
                    }
                    for (let form of Form) {
                        if (form.type === "autoCompleteValueReturn" && form.varName === "receiver") {
                            form.enum = array
                        }
                    }
                    let userObject={}
                    array.map((user)=>{
                       
                        if(user.key === inputData.receiver){
                                userObject.key= user.key;
                                userObject.value= user.value;
                            
                            return;
        
                        }
                    })
                    inputData.receiver = userObject;
                   
                  
                    setTimeout(() => {
                        this.setState({
                            inputData: this.copyJson(inputData),
                            varValue: this.copyJson(inputData),
                            loading: false,
                            getData: true,
                            showValue: true,
                        });

                    }, 1000)
                })
                .catch((error) => {
                    this.setState({
                        type: "error",
                        title: "Oops!! Failed to load users!",
                        notificationMessage: "Failed!",
                        alert: true,
                        loading: false,
                    });
                })
                
            console.log(this.state.inputData);
        } else {
            setTimeout(()=>{
                this.setState({
                    showValue: true,
                    getData: true,
                    loading: false,
                });
            },1000)
          
        }
    }

    close = () => {
        this.props.closeModal()
    }
    updateComponent = () => {
        this.forceUpdate();
    };
    whenDropDownClikced = (data) => {
        console.log(data)

        if(data.varName === "receiver") {
            this.state.inputData.showContactNo = false
             this.setState({
                loading: true,
                getData: false,
            });
           
            this.forceUpdate();
         
            console.log("TURJOY001", this.state.inputData.receiver.contactNo)
         
            
           
           
            this.state.inputData.contactNumber = this.state.inputData.receiver.contactNo;
            this.state.varValue.contactNumber = this.state.inputData.receiver.contactNo;
            this.state.inputData.showContactNo = true
                
               
              
            this.forceUpdate();
                setTimeout(() => {
                    this.setState({
                        inputData: this.copyJson(this.state.inputData),
                        varValue: this.copyJson(this.state.inputData),
                        getData: true,
                        loading: false,
                    })
                }, 500);
                
        }
        if (data.varName === "department" && this.state.inputData[data.varName] !== undefined && this.state.inputData[data.varName] !== null) {
            this.setState({
                loading: true
            });
            // this.state.inputData.showContactNo = false
            this.state.inputData.showReceiverUsers = false;
            this.forceUpdate();
            let url = backEndServerURL + "/username/" + this.state.inputData.department;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,},})
                .then((response) => {
                    this.state.inputData.showReceiverUsers = true;
                    let array = [];
                    for (let i = 0; i < response.data.length; i++) {
                        array.push({
                            value: response.data[i].value,
                            key: response.data[i].key,
                            contactNo: response.data[i].contactNo,
                        })
                    }
                    for (let form of Form) {
                        if (form.type === "autoCompleteValueReturn" && form.varName === "receiver") {
                            form.enum = array
                        }
                    }
                    this.forceUpdate();
                    setTimeout(() => {
                        this.setState({
                            inputData: this.copyJson(this.state.inputData),
                            varValue: this.copyJson(this.state.inputData),
                            getData: true,
                            loading: false,
                        })

                    }, 100)
                })
                .catch((error) => {
                    this.setState({
                        type: "error",
                        title: "Oops!! Failed to load users!",
                        notificationMessage: "Failed!",
                        alert: true,
                        loading: false,
                    });
                })
        }
    };
    renderJsonForm = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, Form, this.updateComponent, undefined, this.whenDropDownClikced);
    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type={this.state.type}
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false,
        });
    };
    renderHeader = () => {
        if (this.props.update !== undefined && this.props.update) {
            return (
                <h4 style={{color: "white"}}>
                    Re-Assign - Dispatch Management (Incoming)
                    <a>
                        <CloseIcon
                            onClick={this.close}
                            style={{
                                position: "absolute",
                                right: 10,
                                color: "white",
                            }}
                        />
                    </a>
                </h4>
            );
        } else {
            return <h4 style={{color: "white"}}>Dispatch Management (Incoming)</h4>;
        }
    };
    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
            signatureModal: false,
            reference: false,
        });
    };

    functionAfterSubmit = () =>{
        if (this.props.update !== undefined && this.props.update) {
            let inputData = this.copyJson({...this.state.inputData});
            let payload = {};
            payload.incomingType = inputData.incomingType;
            payload.senderName = inputData.senderName;
            payload.receiverDocumentType = inputData.receiverDocumentType;
            if (inputData.officialConcern !== undefined && inputData.officialConcern !== null) {
                payload.officialConcern = inputData.officialConcern.value;
            }
            payload.department = inputData.department;
            payload.receiver = inputData.receiver.key;
            payload.contactNumber = inputData.contactNumber;
            payload.date = inputData.date;
            payload.referenceNumber = inputData.referenceNumber;
            payload.documentDetails = inputData.documentDetails;
            let url = backEndServerURL + "/reassignIncomingRequisition/" + this.props.rowId;
            axios
                .post(url, payload, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    },
                })
                .then((response) => {
                    this.props.closeModal();
                    setTimeout(function () {
                        window.location.reload()
                    }, 1000);
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                    });
                });


        } else {
            let postData = this.state.inputData;
            postData.values = this.state.values;
            postData.freeFlag1 = "FDD";
            postData.category = "Incoming";
            postData.subCategory = "Dispatch";
            postData.serviceType = "Debit Card";
            postData.subServiceType = "Chequebook";
            postData.customerName = this.state.inputData.customerName;
            postData.appId = this.state.appId;
            postData.chequebook_approval = "CLOSED";
            let inputData = this.copyJson({...this.state.inputData});
            let payload = {};
            payload.incomingType = inputData.incomingType;
            payload.senderName = inputData.senderName;
            payload.receiverDocumentType = inputData.receiverDocumentType;
            if (inputData.officialConcern !== undefined && inputData.officialConcern !== null) {
                payload.officialConcern = inputData.officialConcern.value;
            }
            payload.department = inputData.department;
            payload.receiver = inputData.receiver.key;
            payload.contactNumber = inputData.contactNumber;
            payload.date = inputData.date;
            payload.referenceNumber = this.state.refNum;
            payload.documentDetails = inputData.documentDetails;

            let url = backEndServerURL + "/variables/" + this.state.appId;
            axios
                .post(url, postData, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    },
                })
                .then((response) => {
                    let url = backEndServerURL + "/add/incomingRequisitionSave";
                    axios
                        .post(url, payload, {
                            headers: {
                                Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                            },
                        })
                        .then((response) => {
                            let caseRouteUrl =
                                backEndServerURL + "/case/route/" + this.state.appId;
                            axios
                                .get(caseRouteUrl, {
                                    headers: {
                                        Authorization: `Bearer ${sessionStorage.getItem(
                                            "accessToken"
                                        )}`,
                                    },
                                })
                                .then((response) => {
                                    console.log("response is", response.data);
                                    this.setState({
                                        type: "success",
                                        title: "Successfully done!",
                                        notificationMessage: "Successfully done!",
                                        alert: true,
                                        loading: false,
                                    });
                                    let mail_url =
                                    backEndServerURL + "/mail-notification/incoming/" + this.state.refNum;
                                axios
                                    .post(mail_url,{}, {
                                        headers: {
                                            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                                        },
                                    })
                                    .then((res) => {
                                       
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                                    setTimeout(function () {
                                        window.location.reload()
                                    }, 3500);
                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.setState({
                                        loading: false,
                                    });
                                });
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                })
                .catch((error) => {
                    console.log("variables request error");
                });
        }
    }

    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                confirmationAlert: false,
                loading: true,
            });
          // all submit
          this.functionAfterSubmit();
        } else {
            this.setState({
                confirmationAlert: false,
                loading: false,
            })
        }
    };
    render() {
        const {classes} = this.props;
        return (
            <Card>
                <CardHeader
                    style={{
                        backgroundColor: "#142398",
                    }}
                >
                    {this.renderHeader()}
                </CardHeader>
                <CardBody>
                    <div>
                        <Dialog fullWidth="true" maxWidth="sm" open={this.state.loading}>
                            <DialogContent>
                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                // className={classes.modal}
                                // classes={{paper: classes.dialogPaper}}
                                open={this.state.confirmationAlert}>
                                <DialogContent 
                                // className={classes.dialogPaper}
                                >
                                    <ConfirmationModal
                                        closeModal={this.closeConfirmation}
                                        title={this.state.title}
                                        cancelHide={true}
                                    />
                                </DialogContent>
                            </Dialog>
                        <ThemeProvider theme={theme}>
                            <Grid container>
                                {this.renderNotification()}
                                {this.state.getData && this.renderJsonForm()}
                                <br/>
                                <Grid item xs="12"></Grid>

                                <Grid item xs={12}>
                                    {this.renderButton()}
                                </Grid>
                            </Grid>
                        </ThemeProvider>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

export default IncomingDispatchIntiator;
