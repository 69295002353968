import React from "react";
import {backEndServerURL, frontEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Table from "../../Table/Table";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Notification from "../../NotificationMessage/Notification";
import Grid from "@material-ui/core/Grid";
import Functions from "../../../Common/Functions";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CardBody from "../../Card/CardBody";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import ImageCrop from "../CASA/ImageCrop";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme";
import withStyles from "@material-ui/core/styles/withStyles";
import TextFieldComponent from "../../JsonForm/TextFieldComponent";
import GridList from "@material-ui/core/GridList";
import AssignedCropImage from "../CASA/AssignedCropImage";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import MyValidation from "../../JsonForm/MyValidation";
import DedupResultFunction from "../../DedupResultFunction";
import loader from "../../../Static/loader.gif";
import {CBDataJsonForm} from "../WorkflowJsonForm4";
import WaiverList from "../CommonComponent/WaiverList";
import ConfirmAlert from "../CommonComponent/ConfirmAlert";
import SdnResultFunction from "../../SdnResultFunction";
import MandateAndBeneficiaryResult from "../../MandateAndBeneficiaryResult";
import {fdKYC} from "../WorkflowJsonFormArin";
import AccountDetails from "./AccountDetails";
import SecondaryCbFormDetails from "../CASA/SecondaryCbFormDetails";
import DeferalList from "../CommonComponent/DeferalList";
import ErrorModal from "../CommonComponent/ErrorModal";
import SubmittedCaseHistory from "../CommonComponent/SubmittedCaseHistory";
import FlowSummeryButton from "../CommonComponent/FlowSummeryButton";
import AccountStatement from "./AccountStatement";
import SecondaryJointCbTagging from "../CASA/SecondaryJointCbTagging";
import SignatureButton from "../Maintenance/SignatureButton";
import FileMappingEdit from "../CommonComponent/FileMappingEdit";
import FileMapping from "../CommonComponent/FileMapping";

const arrayListIndividual = [

    {label: 'AOF1'},
    {label: 'AOF2'},
    {label: 'AOF3'},
    {label: 'AOF4'},
    {label: 'AOF5'},
    {label: 'AOF6'},
    {label: 'AOF7'},
    {label: 'AOF8'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'TP'},
    {label: 'IIF1'},
    {label: 'IIF2'},
    {label: 'NID'},
    {label: 'PASSPORT'},
    {label: 'DRIVING LICENSE'},
    {label: 'BIRTH CERTIFICATE'},
    {label: 'NOMINEE NID 1'},
    {label: 'NOMINEE NID 2'},
    {label: 'NOMINEE NID 3'},
    {label: 'NOMINEE NID 4'},
    {label: 'NOMINEE NID 5'},
    {label: 'NOMINEE NID 6'},
    {label: 'NOMINEE PASSPORT 1'},
    {label: 'NOMINEE PASSPORT 2'},
    {label: 'NOMINEE PASSPORT 3'},
    {label: 'NOMINEE PASSPORT 4'},
    {label: 'NOMINEE PASSPORT 5'},
    {label: 'NOMINEE DRIVING LICENSE 1'},
    {label: 'NOMINEE DRIVING LICENSE 2'},
    {label: 'NOMINEE DRIVING LICENSE 3'},
    {label: 'NOMINEE DRIVING LICENSE 4'},
    {label: 'NOMINEE DRIVING LICENSE 5'},
    {label: 'NOMINEE BIRTH CERTIFICATE 1'},
    {label: 'NOMINEE BIRTH CERTIFICATE 2'},
    {label: 'NOMINEE BIRTH CERTIFICATE 3'},
    {label: 'NOMINEE BIRTH CERTIFICATE 4'},
    {label: 'NOMINEE BIRTH CERTIFICATE 5'},
    {label: 'OTHERS'},

];
let objectForNominee = [];
let taggingForm = [
    {
        "varName": "customerId",
        "type": "text",
        "label": "Customer Id",
        "grid": 6,
    },
    {
        "varName": "schemeCode",
        "type": "text",
        "label": "Scheme Code",
        "grid": 6,
    },
    {
        "varName": "acOpenDate",
        "type": "",
        "label": "Ac Open Date",
        "grid": 6,
    },
    {
        "varName": "solId",
        "type": "text",
        "label": "Ac Pref Int Cr",
        "grid": 6,
    },
    {
        "varName": "interestCreditAcId",
        "type": "text",
        "label": "Interest Credit Ac Id",
        "grid": 6,
    },
    {
        "varName": "withHoldingTaxLevel",
        "type": "text",
        "label": "With Holding Tax Level",
        "grid": 6,
    },
    {
        "varName": "withholdingTaxTax",
        "type": "text",
        "label": "Withholding Tax",
        "grid": 6,
    },
    {
        "varName": "depositinstallAmount",
        "type": "text",
        "label": "Depositinstall Amount",
        "grid": 6,
    },
    {
        "varName": "depositPeriod",
        "type": "text",
        "label": "Deposit Period",
        "grid": 6,
    },
    {
        "varName": "fdValueDate",
        "type": "date",
        "label": "Value Date",
        "grid": 6,
    },
    {
        "varName": "repaymentAcId",
        "type": "textApiCall",
        "label": "Repay A/C ID",
        "grid": 6,

    },
    {
        "varName": "repaymentName",
        "type": "textApiCall",
        "label": "Repayment Name",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "repaymentAccountApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "autoClosureClosure",
        "type": "text",
        "label": "Auto Closure",
        "grid": 6,
    },
    {
        "varName": "autoRenewalRenewal",
        "type": "text",
        "label": "Auto Renewal",
        "grid": 6,
    }, {
        "varName": "post",
        "type": "text",
        "label": "Postal Code 2",
        "validationB": "length",
        "maxLength": 6,
        "grid": 6,
    },
    {
        "varName": "sectorCode",
        "type": "text",
        "label": "Sector Code",
        "grid": 6,
    },
    {
        "varName": "subCectorCode",
        "type": "text",
        "label": "Sub Sector Code",
        "grid": 6,
    },
    {
        "varName": "occupationCode",
        "type": "text",
        "label": "Occupation Code",
        "grid": 6,
    },
    {
        "varName": "freeCodeCode1",
        "type": "text",
        "label": "Free Code 1",
        "grid": 6,
    },
    {
        "varName": "freeCodeCode2",
        "type": "text",
        "label": "Free Code 2",
        "grid": 6,
    },
    {
        "varName": "freeCodeCode3",
        "type": "text",
        "label": "Free Code 3",
        "grid": 6,
    },
    {
        "varName": "tranCreationDuringOv",
        "type": "text",
        "label": "Tran Creation During Ov",
        "grid": 6,
    },
    {
        "varName": "fundingAc",
        "type": "text",
        "label": "Funding Ac",
        "grid": 6,
    },
    {
        "varName": "typeOfTransaction",
        "type": "text",
        "label": "Type Of Transaction",
        "grid": 6,
    },

];
let makerRemarks = [
    {
        "varName": "makerRemarks",
        "type": "textArea",
        "label": "Maker Remarks",
        "grid": 12
    }]
;
const numberOfNominee = [
    {title: '1'},
    {title: '2'},
    {title: '3'},
    {title: '4'},
    {title: '5'},
    {title: '6'},
    {title: '7'},
    {title: '8'},
    {title: '9'},
    {title: '10'},
    {title: '11'},
    {title: '12'},
    {title: '13'},
    {title: '14'},
    {title: '15'},
    {title: '16'},
    {title: '17'},
    {title: '18'},
    {title: '19'},
    {title: '20'},
    {title: 'NA'}
];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "125%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};

let accountOpeningForm = [
    /* {
         "varName": "customerId",
         "type": "text",
         "label": "Customer ID",
         "required": true,
         "grid": 6,

     },

     {
         "varName": "customerName",
         "type": "text",
         "label": "Title",
         "grid": 6,
         required: true,
         "multiline": true
     },

     {
         "varName": "customerName",
         "type": "text",
         "label": "Customer Name",
         "grid": 6,
         "length": 80,
         required: true,
         "multiline": true
     },

     {
         "varName": "shortName",
         "type": "text",
         "label": "Short Name",
         "grid": 6,
         "length": 10,
         required: true,
     },

     {
         "varName": "email",
         "type": "text",
         "label": "Email",
         "grid": 6,
         required: true,
         "multiline": true
     },

     {
         "varName": "phone",
         "type": "text",
         "label": "Phone No 1 ",
         "grid": 6,
         "multiline": true
     },

     /!*
         {
             "label": "AOF 2",
             "type": "title",
             "grid": 12,
         },
     *!/

     {
         "varName": "introducerCustomerId",
         "type": "text",
         "label": "Introducer Customer ID",
         "grid": 6,
         required: true,
     },

     {
         "varName": "introducerName",
         "type": "text",
         "label": "Introducer Name",
         "grid": 6,
         "multiline": true,
         required: true,
     },

     {
         "varName": "introducerStaff",
         "type": "text",
         "label": "Introducer Staff",
         "grid": 6,
         required: true,
     },

     {
         "varName": "applicantMinor",
         "type": "select",
         "label": "Applicant Minor",
         "grid": 6,
         "enum": ["Yes", "No"],
     },
     {
         "varName": "gurdian",
         "type": "text",
         "label": "Gurdian",
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "applicantMinor",
         "conditionalVarValue": "Yes",
         required: true,
     },

     {
         "varName": "gurdianName",
         "type": "text",
         "label": "Gurdian Name",
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "applicantMinor",
         "conditionalVarValue": "Yes",
         required: true,
     },

     {
         "varName": "address23",
         "type": "text",
         "label": "Address",
         "multiline": true,
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "applicantMinor",
         "conditionalVarValue": "Yes",
         required: true,
     },

     {
         "varName": "city1",
         "type": "select",
         "enum": ["Dhaka", "Chittagong", "Barishal", "Rajshahi", "Khulna"],
         "label": "City",
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "applicantMinor",
         "conditionalVarValue": "Yes",
         required: true,
     },

     {
         "varName": "state1",
         "type": "text",
         "label": "State",
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "applicantMinor",
         "conditionalVarValue": "Yes",
         required: true,
         "multiline": true
     },

     {
         "varName": "postal",
         "type": "text",
         "label": "Postal",
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "applicantMinor",
         "conditionalVarValue": "Yes",
         required: true,
     },

     {
         "varName": "country3",
         "type": "select",
         "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
         "label": "Country",
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "applicantMinor",
         "conditionalVarValue": "Yes",
         required: true,

     },

     /!*    {
             "label": "IIF Page 1",
             "type": "title",
             "grid": 12,
         },*!/
     {
         "varName": "gender",
         "type": "select",
         "enum": ["Male", "Female"],
         "label": "Gender",
         "grid": 6,
         required: true,
     },

     {
         "varName": "residentStatus",
         "type": "select",
         "label": "Resident Status",
         "enum": ["Resident", "Non-Resident"],
         "grid": 6,
         required: true,
     },

     {
         "varName": "nid",
         "type": "text",
         "label": "National Id Card",
         "grid": 6,
         required: true,
     },

     {
         "varName": "dob",
         "type": "date",
         "label": "DOB",
         "grid": 6,
         "maxDate": true,
         required: true,
     },

     {
         "varName": "father",
         "type": "text",
         "label": "Father ",
         "grid": 6,
         required: true,
     },

     {
         "varName": "mother",
         "type": "text",
         "label": "Mother",
         "grid": 6,
         required: true,
     },

     {
         "varName": "maritialStatus",
         "type": "select",
         "label": "Maritial Status",
         "enum": ["Married", "Un Married"],
         "grid": 6,
         required: true,
     },
     {
         "varName": "spouse",
         "type": "text",
         "label": "Spouse",
         "conditional": true,
         "conditionalVarName": "maritialStatus",
         "conditionalVarValue": "Married",
         "grid": 6,
         required: true,
     },

     {
         "varName": "pangirNo",
         "type": "text",
         "label": "PAN GIR No",
         "grid": 6,
     },

     {
         "varName": "passport",
         "type": "text",
         "label": "Passport No",
         "grid": 6,
     },

     // {
     //     "varName": "issueDate",
     //     "type": "text",
     //     "label": "Issue Date",
     //     "grid": 12,
     // },

     {
         "varName": "passportDetails",
         "type": "text",
         "label": "Passport Details",
         "grid": 6,
     },

     // {
     //     "varName": "expiryDate",
     //     "type": "text",
     //     "label": "Expiry Date",
     //     "grid": 12,
     // },

     {
         "varName": "freeText5",
         "type": "text",
         "label": "Free Text 5",
         "grid": 6,
     },

     {
         "varName": "freeText13",
         "type": "text",
         "label": "Free Text 13",
         "grid": 6,

     },
     {
         "varName": "freeText14",
         "type": "text",
         "label": "Free Text 14",
         "grid": 6,
     },
     {
         "varName": "freeText15",
         "type": "text",
         "label": "Free Text 15",
         "grid": 6,
     },
     /!*    {
             "label": "IIF Page 2",
             "type": "title",
             "grid": 12,
         },*!/
     {
         "varName": "comAddress",
         "type": "text",
         "label": "Communication Address 1",
         "grid": 6,
         required: true,
         "multiline": true
     },

     {
         "varName": "comAddress2",
         "type": "text",
         "label": "Communication Address 2",
         "grid": 6,
         "multiline": true
     },

     {
         "varName": "city",
         "type": "text",
         "required": true,
         "label": "City",
         "grid": 6,
         "multiline": true
     }, {
         "varName": "state",
         "type": "text",
         "required": true,
         "label": "State",
         "grid": 6,
         "multiline": true
     }, {
         "varName": "post",
         "type": "text",
         "required": true,
         "label": "Post",
         "grid": 6,
         "multiline": true
     }, {
         "varName": "country",
         "type": "select",
         "required": true,
         "enum": [
             "Bangladesh",
             "Japan",
             "Other",
         ],
         "label": "Country",
         "grid": 6,
     },
     {
         "varName": "phoneNo12",
         "type": "text",
         "label": "Phone No 1 ",
         "grid": 6,
         "length": 13,
         required: true,
     },

     {
         "varName": "phoneNo21",
         "type": "text",
         "label": "Phone No 2",
         "grid": 6,
     },

     {
         "varName": "permanentAddress1",
         "type": "text",
         "label": "Permanent Address 1",
         "grid": 6,
         required: true,
         "multiline": true
     },

     {
         "varName": "permanentAddress2",
         "type": "text",
         "label": "Permanent Address 2",
         "grid": 6,
         "multiline": true
     },

     // {
     //     "varName": "city3",
     //     "type": "select",
     //     "enum": ["Dhaka","Chittagong","Barishal","Rajshahi","Khulna"],
     //     "label": "City",
     //     "grid": 6,
     //     required: true,
     // },

     {
         "varName": "state3",
         "type": "text",
         "label": "State",
         "grid": 6,
         required: true,
     },

     {
         "varName": "postalCode4",
         "type": "text",
         "label": "Postal Code",
         "grid": 6,
         required: true,
     },

     {
         "varName": "country5",
         "type": "select",
         "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
         "label": "Country",
         "grid": 6,
         required: true,
     },

     {
         "varName": "phoneNo22",
         "type": "text",
         "label": "Phone No 2",
         "grid": 6,
     },

     {
         "varName": "email2",
         "type": "text",
         "label": "Email",
         "grid": 6,
     },

     {
         "varName": "employerAddress1",
         "type": "text",
         "label": "Employer Address 1",
         "grid": 6,
         "multiline": true,
         required: true,
     },

     {
         "varName": "employerAddress2",
         "type": "text",
         "label": "Employer Address 2",
         "grid": 6,
         "multiline": true
     },

     {
         "varName": "city4",
         "type": "select",
         "enum": ["Dhaka", "Chittagong", "Barishal", "Rajshahi", "Khulna"],
         "label": "City",
         "grid": 6,
         required: true,
     },

     {
         "varName": "state4",
         "type": "text",
         "label": "State",
         "grid": 6,
         required: true,
     },

     {
         "varName": "postalCode5",
         "type": "text",
         "label": "Postal Code",
         "grid": 6,
     },

     {
         "varName": "country12",
         "type": "select",
         "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
         "label": "Country",
         "grid": 6,
     },

     {
         "varName": "phoneNo13",
         "type": "text",
         "label": "Phone No 1 ",
         "grid": 6,
         "length": 13,
     },

     {
         "varName": "phoneNo23",
         "type": "text",
         "label": "Phone No 2",
         "grid": 6,
     },

     {
         "varName": "telexNo1",
         "type": "text",
         "label": "Telex No",
         "grid": 6,
     },

     {
         "varName": "email3",
         "type": "text",
         "label": "Email",
         "grid": 6,
         "multiline": true
     },

     {
         "varName": "faxNo",
         "type": "text",
         "label": "Fax No",
         "grid": 6,
     },

     {
         "label": "KYC",
         "type": "title",
         "grid": 12,
     },
     {
         "varName": "acNo",
         "type": "text",
         "label": "Ac No",
         "grid": 6,
         "length": 13,
         required: true,
     },

     {
         "varName": "acTitle",
         "type": "text",
         "label": "Ac Title",
         "grid": 6,
         "multiline": true,
     },

     {
         "varName": "customerOccupation",
         "type": "select",
         "enum": ["Teacher", "Doctor", "House-Wife", "Privet Job Holder"],
         "label": "Customer Occupation",
         "grid": 6,
         required: true,
     },

     {
         "varName": "docCollectToEnsure",
         "type": "text",
         "label": "To Ensure Source Of Fund",
         "grid": 6,
         required: true,
     },

     {
         "varName": "collectedDocHaveBeenVerified",
         "type": "select",
         "enum": ["Yes", "No"],
         "label": "Collected Doc Have Been Verified",
         "grid": 6,
         required: true,
     },

     {
         "varName": "howTheAddress",
         "type": "select",
         "enum": ["Yes", "No"],
         "label": "How The Address Is Verified",
         "grid": 6,
         required: true,
     },

     {
         "varName": "hasTheBeneficialOwner",
         "type": "select",
         "enum": ["Yes", "No"],
         "label": "Beneficial Owner Identified",
         "grid": 6,
         required: true,
     },

     {
         "varName": "whatDoesTheCustomer",
         "type": "select",
         "enum": ["Yes", "No"],
         "label": "Is The Customer Engaged",
         "grid": 6,
         required: true,
     },

     {
         "varName": "customersMonthlyIncome",
         "type": "select",
         "enum": [20000, 50000, 100000],
         "label": "Customers Monthly Income",
         "grid": 6,
         required: true,
     },*/
    //// FDR ////
    /* {
         "label": "FDR Opening Form",
         "type": "title",
         "grid": 12,
     },*/ {
        "varName": "customerId",
        "type": "text",
        "label": "Customer Id",
        "grid": 6,
    },
    {
        "varName": "schemeCode",
        "type": "text",
        "label": "Scheme Code",
        "grid": 6,
    },
    {
        "varName": "acOpenDate",
        "type": "",
        "label": "Ac Open Date",
        "grid": 6,
    },
    {
        "varName": "solId",
        "type": "text",
        "label": "Ac Pref Int Cr",
        "grid": 6,
    },
    {
        "varName": "interestCreditAcId",
        "type": "text",
        "label": "Interest Credit Ac Id",
        "grid": 6,
    },
    {
        "varName": "withHoldingTaxLevel",
        "type": "text",
        "label": "With Holding Tax Level",
        "grid": 6,
    },
    {
        "varName": "withholdingTaxTax",
        "type": "text",
        "label": "Withholding Tax",
        "grid": 6,
    },
    {
        "varName": "depositinstallAmount",
        "type": "text",
        "label": "Depositinstall Amount",
        "grid": 6,
    },
    {
        "varName": "depositPeriod",
        "type": "text",
        "label": "Deposit Period",
        "grid": 6,
    },
    {
        "varName": "fdValueDate",
        "type": "date",
        "label": "Value Date",
        "grid": 6,
    },
    {
        "varName": "repaymentAcId",
        "type": "textApiCall",
        "label": "Repay A/C ID",
        "grid": 6,

    },
    {
        "varName": "repaymentName",
        "type": "textApiCall",
        "label": "Repayment Name",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "repaymentAccountApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "autoClosureClosure",
        "type": "text",
        "label": "Auto Closure",
        "grid": 6,
    },
    {
        "varName": "autoRenewalRenewal",
        "type": "text",
        "label": "Auto Renewal",
        "grid": 6,
    }, {
        "varName": "post",
        "type": "text",
        "label": "Postal Code 2",
        "grid": 6,
    },
    {
        "varName": "sectorCode",
        "type": "text",
        "label": "Sector Code",
        "grid": 6,
    },
    {
        "varName": "subCectorCode",
        "type": "text",
        "label": "Sub Sector Code",
        "grid": 6,
    },
    {
        "varName": "occupationCode",
        "type": "text",
        "label": "Occupation Code",
        "grid": 6,
    },
    {
        "varName": "freeCodeCode1",
        "type": "text",
        "label": "Free Code 1",
        "grid": 6,
    },
    {
        "varName": "freeCodeCode2",
        "type": "text",
        "label": "Free Code 2",
        "grid": 6,
    },
    {
        "varName": "freeCodeCode3",
        "type": "text",
        "label": "Free Code 3",
        "grid": 6,
    },
    {
        "varName": "tranCreationDuringOv",
        "type": "text",
        "label": "Tran Creation During Ov",
        "grid": 6,
    },
    {
        "varName": "fundingAc",
        "type": "text",
        "label": "Funding Ac",
        "grid": 6,
    },
    {
        "varName": "typeOfTransaction",
        "type": "text",
        "label": "Type Of Transaction",
        "grid": 6,
    },

    //Kyc
    {
        "varName": "functionCode",
        "type": "text",
        "label": "Function Code",
        "grid": 6,

    },
    {
        "varName": "customerType",
        "type": "text",
        "label": "Customer Type",
        "grid": 6,

    }
    ,
    {
        "varName": "customerAccount",
        "type": "text",
        "label": "CUSTOMER ACCOUNT",
        "grid": 6,

    }
    ,
    {
        "varName": "accountTitle",
        "type": "text",
        "label": "Account Title",
        "grid": 6,

    },
    {
        "varName": "typeOfAccount",
        "type": "text",
        "label": "Type of Account",
        "grid": 6,

    }
    ,
    {
        "varName": "accountReferenceNo",
        "type": "text",
        "label": "Account or Reference No",
        "grid": 6,

    }
    ,
    {
        "varName": "nameOfficerOpeningAccount",
        "type": "text",
        "label": "Name of Officer Opening the Account",
        "grid": 6,

    }
    ,
    {
        "varName": "customersOccupation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Customers Occupation (Details)",
        "grid": 6,

    }
    ,
    {
        "varName": "customersProbableMonthlyIncome",
        "type": "text",
        "label": "Customers probable monthly income",
        "grid": 6,

    }
    ,
    {
        "varName": "sourcesOfFund",
        "type": "text",
        "label": "Sources of Fund",
        "grid": 6,

    },

    {
        "varName": "documentsCollectedEnsureSourceOfFund",
        "type": "text",
        "label": "Documents collected to ensure the source of fund",
        "grid": 6,

    },
    {
        "varName": "collectedDocumentsVerified",
        "type": "text",
        "label": "Collected documents have been verified or not?  ",
        "grid": 6,

    }
    ,
    {
        "varName": "accountVerified",
        "type": "text",
        "label": "How the address (es) of the account holder has been verified? (Details)",
        "grid": 6,

    }
    ,
    {
        "varName": "BeneficiaAccountIdentified",
        "type": "text",
        "label": "Has the Beneficial owner of the account been identified?",
        "grid": 6,

    }
    ,
    {
        "varName": "passportNo",
        "type": "text",
        "label": "Passport No",
        "grid": 6,

    }
    ,
    {
        "varName": "nationalIDCardNo",
        "type": "text",
        "label": "National ID Card No",
        "grid": 6,

    }
    ,
    {
        "varName": "birthCertificateNo",
        "type": "text",
        "label": "Birth Certificate No",
        "grid": 6,

    }
    ,
    {
        "varName": "tinNo",
        "type": "text",
        "label": "Tin No",
        "grid": 6,

    }
    ,
    {
        "varName": "vatRegistrationNo",
        "type": "text",
        "label": "VAT Registration No",
        "grid": 6,

    }
    ,
    {
        "varName": "companyRegistrationNo",
        "type": "text",
        "label": "Company Registration No",
        "grid": 6,

    }
    ,
    {
        "varName": "drivingLicenceNo",
        "type": "text",
        "label": "Driving Licence No",
        "grid": 6,

    }
    ,
    {
        "varName": "otherDocumentation",
        "type": "text",
        "label": "Other Documentation",
        "grid": 6,

    }
    ,
    {
        "varName": "reasonopeningAccountForeigner:",
        "type": "text",
        "label": "Reason for opening the account for Non-Resident and Foreigner:",
        "grid": 6,

    }
    ,
    {
        "varName": "typeOfVisa",
        "type": "text",
        "label": "Type of VISA",
        "grid": 6,

    }
    ,
    {
        "varName": "dateOfExpire",
        "type": "date",
        "label": "Date Of Expire",
        "grid": 6,

    }
    ,
    {
        "varName": "workPermitPermission",
        "type": "text",
        "label": "Is Work permit and permission to open account form competent authority is taken for open account of work permit holder?  ",
        "grid": 6,

    }
    ,
    {
        "varName": "CustomerPolitically",
        "type": "select",
        "label": "Is The customer a Politically Exposed Person (PEP) or Influential Person (IP)or Head or high officials of international organization and their family members or close associates?",
        "enum": [
            "Has the approval been taken from senior management? ",
            "Has interviewed the customer face to face?",
            "NO",
        ],
        "grid": 6,

    }
    ,
    {
        "varName": "matchCustomername",
        "type": "select",
        "label": "Is there any match to the customer name with the terrorist activity?",
        "enum": [
            "YES",
            "NO"
        ],
        "grid": 6,

    },
    {
        "varName": "matchCustomernameDetails",
        "type": "select",
        "label": "Details",
        "enum": [
            "YES",
            "NO"
        ],
        "grid": 6,

    }
    ,
    {
        "varName": "riskGrading",
        "type": "text",
        "label": "Risk Grading",
        "enum": [
            "HIGH",
            "LOW"
        ],
        "grid": 6,

    },


    //Sd part
    {
        "varName": "solId",
        "type": "text",
        "label": "Sol Id",
        "grid": 6,
    },

    {
        "varName": "cifId",
        "type": "text",
        "label": "Cif Id",
        "grid": 6,
    },

    {
        "varName": "ccy",
        "type": "text",
        "label": "Ccy",
        "grid": 6,
    },

    {
        "varName": "schemeCode",
        "type": "text",
        "label": "Scheme Code",
        "grid": 6,
    },

    {
        "varName": "acName",
        "type": "text",
        "label": "A/C. Name",
        "grid": 6,
    },

    {
        "varName": "acOpeningDate",
        "type": "date",
        "label": "A/C Opening Date",
        "grid": 6,
    },

    {
        "varName": "taxCategory",
        "type": "text",
        "label": "Tax Category",
        "grid": 6,
    },

    {
        "varName": "withHoldingTaxLevel",
        "type": "text",
        "label": "With Holding Tax Level",
        "grid": 6,
    },

    {
        "varName": "withholdingTaxBorneBy",
        "type": "text",
        "label": "Withholding Tax Borne By",
        "grid": 6,
    },

    {
        "varName": "withholdingTaxPcnt",
        "type": "text",
        "label": "Withholding Tax Pcnt.",
        "grid": 6,
    },

    {
        "varName": "valueDate",
        "type": "date",
        "label": "Value Date",
        "grid": 6,
    },

    {
        "varName": "depositinstallAmount",
        "type": "text",
        "label": "Deposit/Install Amount",
        "grid": 6,
    },

    {
        "varName": "depositPeriodMonthsdays",
        "type": "text",
        "label": "Deposit Period (Months/Days)",
        "grid": 6,
    },

    {
        "varName": "maturityDate",
        "type": "date",
        "label": "Maturity Date",
        "grid": 6,
    },

    {
        "varName": "maturityValue",
        "type": "text",
        "label": "Maturity Value",
        "grid": 6,
    },

    {
        "varName": "repaymentAcId",
        "type": "textApiCall",
        "label": "Repay A/C ID",
        "grid": 6,

    },
    {
        "varName": "repaymentName",
        "type": "textApiCall",
        "label": "Repayment Name",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "repaymentAccountApiCall",
        "conditionalVarValue": "YES",
    },

    {
        "varName": "nominationPcnt",
        "type": "text",
        "label": "Nomination Pcnt.",
        "grid": 6,
    },

    {
        "varName": "sectorCode",
        "type": "text",
        "label": "Sector Code",
        "grid": 6,
    },

    {
        "varName": "subSectorCode",
        "type": "text",
        "label": "Sub Sector Code",
        "grid": 6,
    },

    {
        "varName": "occupationCode",
        "type": "text",
        "label": "Occupation Code",
        "grid": 6,
    },

    {
        "varName": "freeCode1SpecialTaxagentOutlet",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Free Code 1 (Special Tax/Agent Outlet)",
        "grid": 6,
    },

    {
        "varName": "rmCode",
        "type": "text",
        "label": "Free Code 3 (Rm Code)",
        "grid": 6,
    },

    {
        "varName": "freeCode7DepositCode",
        "type": "text",
        "label": "Free Code 7 (Deposit Code)",
        "grid": 6,
    },

    {
        "varName": "relationType",
        "type": "text",
        "label": "Relation Type ",
        "grid": 6,
    },

    {
        "varName": "relationCode",
        "type": "text",
        "label": "Relation Code",
        "grid": 6,
    },

    {
        "varName": "designationCode",
        "type": "text",
        "label": "Designation Code",
        "grid": 6,
    },

    {
        "varName": "cifId",
        "type": "text",
        "label": "Cif. Id",
        "grid": 6,
    },

    {
        "varName": "etinNumber",
        "type": "text",
        "label": "E-Tin Number",
        "grid": 6,
    },

    {
        "varName": "cifId",
        "type": "text",
        "label": "Cif Id",
        "grid": 6,
    },

    {
        "varName": "nomineesName",
        "type": "text",
        "label": "Nominee'S Name",
        "grid": 6,
    },

    {
        "varName": "relationship",
        "type": "text",
        "label": "Relationship",
        "grid": 6,
    },

    {
        "varName": "addressLine1",
        "type": "text",
        "label": "Address Line1",
        "grid": 6,
    },

    {
        "varName": "addressLine2",
        "type": "text",
        "label": "Address Line2",
        "grid": 6,
    },

    {
        "varName": "nomineePhotoIdNo",
        "type": "text",
        "label": "Nominee Photo Id No.",
        "grid": 6,
    },

    {
        "varName": "city",
        "type": "select",
        "label": "City ",
        "grid": 6,
    },

    {
        "varName": "state",
        "type": "select",
        "label": "State ",
        "grid": 6,
    },

    {
        "varName": "country",
        "type": "select",
        "label": "Country ",
        "grid": 6,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "nomineeMinor",
        "type": "select",
        "label": "Nominee Minor?",
        "grid": 6,
    },

    {
        "varName": "dateOfBirth",
        "type": "date",
        "label": "Date Of Birth",
        "grid": 6,
    },

    {
        "varName": "nominationPent",
        "type": "text",
        "label": "Nomination Pent.",
        "grid": 6,
    },

    {
        "varName": "guardianName",
        "type": "text",
        "label": "Guardian Name ",
        "grid": 6,
    },

    {
        "varName": "guardianCode",
        "type": "text",
        "label": "Guardian Code",
        "grid": 6,
    },

    {
        "varName": "addressLine1",
        "type": "text",
        "label": "Address Line1",
        "grid": 6,
    },

    {
        "varName": "addressLine2",
        "type": "text",
        "label": "Address Line2",
        "grid": 6,
    },

    {
        "varName": "guardianPhotoIdNo",
        "type": "text",
        "label": "Guardian Photo Id No.",
        "grid": 6,
    },

    {
        "varName": "city",
        "type": "select",
        "label": "City ",
        "grid": 6,
    },

    {
        "varName": "state",
        "type": "select",
        "label": "State ",
        "grid": 6,
    },

    {
        "varName": "country",
        "type": "select",
        "label": "Country ",
        "grid": 6,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "siSrlNo",
        "type": "text",
        "label": "Si Srl. No.",
        "grid": 6,
    },

    {
        "varName": "standingInstructionsFrequency",
        "type": "select",
        "label": "Standing Instructions Frequency  ",
        "grid": 6,
    },

    {
        "varName": "nextExecutionDate",
        "type": "date",
        "label": "Next Execution Date",
        "grid": 6,
    },

    {
        "varName": "temporaryAcId",
        "type": "text",
        "label": "Temporary A/C. Id",
        "grid": 6,
    },
];

let tagAccountOpeningForm = [
    {
        "label": "FDR Opening Form",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "customerId",
        "type": "text",
        "label": "Customer Id",
        "grid": 6,
    },
    {
        "varName": "schemeCode",
        "type": "text",
        "label": "Scheme Code",
        "grid": 6,
    },
    {
        "varName": "acOpenDate",
        "type": "",
        "label": "Ac Open Date",
        "grid": 6,
    },
    {
        "varName": "solId",
        "type": "text",
        "label": "Ac Pref Int Cr",
        "grid": 6,
    },
    {
        "varName": "interestCreditAcId",
        "type": "text",
        "label": "Interest Credit Ac Id",
        "grid": 6,
    },
    {
        "varName": "withHoldingTaxLevel",
        "type": "text",
        "label": "With Holding Tax Level",
        "grid": 6,
    },
    {
        "varName": "withholdingTaxTax",
        "type": "text",
        "label": "Withholding Tax",
        "grid": 6,
    },
    {
        "varName": "depositinstallAmount",
        "type": "text",
        "label": "Depositinstall Amount",
        "grid": 6,
    },
    {
        "varName": "depositPeriod",
        "type": "text",
        "label": "Deposit Period",
        "grid": 6,
    },
    {
        "varName": "fdValueDate",
        "type": "date",
        "label": "Value Date",
        "grid": 6,
    },
    {
        "varName": "repaymentAcId",
        "type": "textApiCall",
        "label": "Repay A/C ID",
        "grid": 6,

    },
    {
        "varName": "repaymentName",
        "type": "textApiCall",
        "label": "Repayment Name",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "repaymentAccountApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "autoClosureClosure",
        "type": "text",
        "label": "Auto Closure",
        "grid": 6,
    },
    {
        "varName": "autoRenewalRenewal",
        "type": "text",
        "label": "Auto Renewal",
        "grid": 6,
    }, {
        "varName": "post",
        "type": "text",
        "label": "Postal Code 2",
        "grid": 6,
    },
    {
        "varName": "sectorCode",
        "type": "text",
        "label": "Sector Code",
        "grid": 6,
    },
    {
        "varName": "subCectorCode",
        "type": "text",
        "label": "Sub Sector Code",
        "grid": 6,
    },
    {
        "varName": "occupationCode",
        "type": "text",
        "label": "Occupation Code",
        "grid": 6,
    },
    {
        "varName": "freeCodeCode1",
        "type": "text",
        "label": "Free Code 1",
        "grid": 6,
    },
    {
        "varName": "freeCodeCode2",
        "type": "text",
        "label": "Free Code 2",
        "grid": 6,
    },
    {
        "varName": "freeCodeCode3",
        "type": "text",
        "label": "Free Code 3",
        "grid": 6,
    },
    {
        "varName": "tranCreationDuringOv",
        "type": "text",
        "label": "Tran Creation During Ov",
        "grid": 6,
    },
    {
        "varName": "fundingAc",
        "type": "text",
        "label": "Funding Ac",
        "grid": 6,
    },
    {
        "varName": "typeOfTransaction",
        "type": "text",
        "label": "Type Of Transaction",
        "grid": 6,
    }
];
let dpsAccountOpeningForm = [
    {
        "label": "DPS Opening Form",
        "type": "title",
        "grid": 12,
    },

    {
        "varName": "solId",
        "type": "text",
        "readOnly": true,
        "label": "Sol Id",
        "grid": 6,
    },
    {
        "varName": "customerId",
        "type": "text",
        "readOnly": true,
        "label": "Customer Id",
        "grid": 6,
    },
    {
        "varName": "schemeCode",
        "type": "text",
        "required": true,
        "label": "Scheme Code",
        "grid": 6,
    },
    {
        "varName": "aCOpenDate",
        "type": "date",
        "readOnly": true,
        "label": "A/C Open Date",
        "grid": 6,
    },
    {
        "varName": "valueDate",
        "type": "date",
        "required": true,
        "label": "Value Date",
        "grid": 6,
    },
    {
        "varName": "depositPeriod",
        "type": "text",
        "required": true,
        "label": "Deposit Period",
        "grid": 6,
    },
    {
        "varName": "depositInstallmentAmount",
        "type": "text",
        "required": true,
        "label": "Deposit/Installment Amount",
        "grid": 6,
    },
    {
        "varName": "repayAccountId",
        "type": "text",
        "required": true,
        "label": "Repay Account Id",
        "grid": 6,
    },
    {
        "varName": "cifIdIndividualId",
        "type": "text",
        "label": "Cif Id/Individual Id",
        "grid": 6,
    },
    /* {
         "varName":"numberOfNominee",
         "type":"text",
         "required":true,
         "label":"Number Of Nominee",
         "grid":6,
     },
     {
         "varName":"nomineeName",
         "type":"text",
         "label":"Nominee Name",
         "grid":6,
     },
     {
         "varName":"relationship",
         "type":"text",
         "label":"Relationship",
         "grid":6,
     },
     {
         "varName":"percentage",
         "type":"text",
         "label":"Percentage",
         "grid":6,
     },
     {
         "varName":"addressField1",
         "type":"text",
         "label":"Address Field 1 ",
         "grid":6,
     },
     {
         "varName":"addressField2",
         "type":"text",
         "label":"Address Field 2",
         "grid":6,
     },
     {
         "varName":"city",
         "type":"text",
         "label":"City",
         "grid":6,
     },
     {
         "varName":"state",
         "type":"text",
         "label":"State",
         "grid":6,
     },
     {
         "varName":"postalCode",
         "type":"text",
         "label":"Postal Code",
         "grid":6,
     },
     {
         "varName":"country",
         "type":"text",
         "label":"Country",
         "grid":6,
     },
     {
         "varName":"photoIdNumberRegNo",
         "type":"checkbox",
         "label":"Photo ID Number/Reg No.",
         "grid":6,
     },
     {
         "varName":"nomineeDob",
         "type":"text",
         "label":"Nominee Dob",
         "grid":6,
     },
     {
         "varName":"nomineeMinor",
         "type":"text",
         "label":"Nominee Minor",
         "grid":6,
     },
     {
         "varName":"guardianSName",
         "type":"text",
         "label":"Guardian'S Name",
         "grid":6,
     },
     {
         "varName":"guardianCode",
         "type":"text",
         "label":"Guardian Code",
         "grid":6,
     },
     {
         "varName":"guardianPhotoId",
         "type":"text",
         "label":"Guardian Photo Id",
         "grid":6,
     },
     {
         "varName":"addressField3",
         "type":"text",
         "label":"Address Field 1",
         "grid":6,
     },
     {
         "varName":"addressField4",
         "type":"text",
         "label":"Address Field 2",
         "grid":6,
     },
     {
         "varName":"city1",
         "type":"select",
         "enum":[
           "DHAKA",
           "KHULNA",
           "JESSORE",
         ],
         "required":true,
         "label":"City",
         "grid":6,
     },
     {
         "varName":"state1",
         "type":"select",
         "enum":[
             "DHAKA",
             "KHULNA",
             "JESSORE",
         ],
         "required":true,
         "label":"State",
         "grid":6,
     },
     {
         "varName":"postal",
         "type":"text",
         "label":"Postal ",
         "grid":6,
     },
     {
         "varName":"country1",
         "type":"select",
         "enum":[
             "BD",
             "Japan",
             "India",
         ],
         "label":"Country",
         "grid":6,
     },*/
    {
        "varName": "sectorCode",
        "type": "text",
        "readOnly": true,
        "label": "Sector Code",
        "grid": 6,
    },
    {
        "varName": "subSectorCode",
        "type": "text",
        "readOnly": true,
        "label": "Sub Sector Code",
        "grid": 6,
    },
    {
        "varName": "occupationCode",
        "type": "text",
        "readOnly": true,
        "label": "Occupation Code",
        "grid": 6,
    },
    {
        "varName": "agentOutletFreeCode1",
        "type": "text",
        "readOnly": true,
        "label": "Agent Outlet/Free Code 1",
        "grid": 6,
    },
    {
        "varName": "rmCodeFreeCode3",
        "type": "text",
        "required": true,
        "label": "Rm Code/ Free Code 3",
        "grid": 6,
    },
    {
        "varName": "depositCodeFreeCode7",
        "type": "text",
        "required": true,
        "label": "Deposit Code/Free Code 7",
        "grid": 6,
    },
    {
        "varName": "standingAccountFrequency",
        "type": "text",
        "required": true,
        "label": "Standing Account Frequency",
        "grid": 6,
    },
    {
        "varName": "nextExecutionDate",
        "type": "date",
        "required": true,
        "label": "Next Execution Date",
        "grid": 6,
    },
];

var LoanCheckbox = [
    {
        "varName": "loan",
        "type": "checkbox",
        "label": "Loan",
        "grid": 12
    },

];


var loanTextField = {
    "varName": "loanText",
    "type": "text",
    "label": "Loan",
    "grid": 12,
    "readOnly": true,
};
var creditCardBox = [
    {
        "varName": "creditCard",
        "type": "checkbox",
        "label": "Credit Card",
        "grid": 12
    },

];


var creditCardTextField = {
    "varName": "creditCardText",
    "type": "text",
    "label": "Credit Card",
    "grid": 12,
    "readOnly": true,
};

var letterOfCreditFieldCheckbox = [{
    "varName": "letterOfCredit",
    "type": "checkbox",
    "label": "Letter Of Credit",
    "grid": 12,
    "readOnly": true,

}];
var letterOfCreditTextField = {

    "varName": "letterOfCreditText",
    "type": "text",
    "label": "Letter Of Credit",
    "grid": 12,
    "readOnly": true,

};

class OpeningSDM extends React.Component {
    state = {
        message: "",
        selectedDate: {},
        appData: {},
        getData: false,
        interestRateApiFlag: true,
        varValue: [],
        redirectLogin: false,
        title: "",
        notificationMessage: "",
        alert: false,
        getDeferalList: [],
        inputData: {},
        getImageLink: [],
        getImageBoolean: false,
        imageModalBoolean: false,
        signetureModalBoolean: false,
        getCheckerList: [],
        selectImage: "",
        FormCasaFlow: "",
        errorMessages: {},
        errorArray: {},
        getTagList: {},
        getMappingCropImage: false,
        imageCropModal: false,
        getRemarks: [],

        nomineeNumber: 0,
        getNominee: false,
        objectForNominee: [],
        loading: false,
        requestType: "",
        uploadModal: false,
        getMappingAllImage: false,
        city: [],
        getCity: false,
        stateCode: [],
        getState: false,
        country: [],
        getCountry: false,
        currency: [],
        confirmAlert: false,
        actionType: "",
        relationType: [],
        relationCode: [],
        designationCode: [],
        cbDataMapping: false,
        nomineeRelationShipCodeList: [],
        getGuardianCode: [],
        fdrSchemeCodeList: [],
        dpsSchemeCodeList: [],
        customerStatusList: [],
        nomineeExistNot: false,
        getDownloadListFlag: false,
        downLoadFileList: [],
        fileNotFoundTittle: "",
        tagCbDetailsModal: false,
        jointCbtagCbDetailsModal: false,
        editMode: false,
        tagCb: "",
        errorModalBoolean: false,
        errorTittle: "",


        showValue: false,

        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        values: [],
        customerName: [],
        deferalType: [],
        expireDate: [],
        other: [],

        getAllDefferal: [],

        occupationCodeList: [],
        customerNumber: 0,
        linkrelationCode: 0,
        jointAccountCustomerNumber: 0,

        getgenerateForm: false,
        renderCumModalopen: false,
        generateAccountNo: '',
        getDedupData: {},
        jointDedupData: {},
        jointSearchTableData: [],
        propritorshipData: [],
        dedupData: [],
        tagClick: false,
        getTaggingList: {},
        getTaggingCustomerList: {},
        taggingData: [],
        relatedData: {},
        searchTableData: false,
        searchTableRelatedData: false,
        cbGenerateModal: false,
        errorMessage: "Invalid Code",
        type: "successs",
        dispatchMode: [],
        savingsSchemeCodeList: [],
        currentSchemeCodeList: [],
        titleList: [],
        chequebookWaiverType: [],
        rmCodeList: [],
        phoneEmailType: [],
        phoneOrEmail: [],
        relationshipList: [],
        constitutionList: [],
        waiverFieldList: [],
        ccepCodeList: [],
        introducerStatus: [],
        relationCodeList: [],
        getCurrency: false,
        despatchmodeList: [],
        sectorCodeList: [],
        subsectorCodeList: [],
        depositCodeList: [],
        tradeServiceList: [],
        agentBankingOutletList: [],
        subSegmentList: [],
        maritalStatusList: [],
        cbDataAfterSet: false,
        accountDataAfterSet: false,
        kycAutopopulateFlag: false,
        TpAutopopulateFlag: false,
        phoneNumCountryCodeList: [],
        documentList: [],
        getDocument: false,
        accountStatement: false,
        getnonResident: [],
        getfreecode7: [],
        employmentTypeList: [],
        placeOfIssueList: [],
        despatchList: [],


    };
    renderSericeForm = (() => {
        if (this.props.subServiceType === 'Tag FDR Opening' || this.props.subServiceType === 'Individual DPS Opening') {
            if (this.state.getData) {
                return (
                    CommonJsonFormComponent.renderJsonForm(this.state, this.props.serviceForm, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
                )
            }
        } else {
            return (
                <div>

                </div>
            )
        }

    });
    renderMisRelatedForm = (() => {
        if (this.props.freeFlag1 !== 'FDR Account Opening - with Existing Operative Account' && this.props.freeFlag1 !== 'DPS Account Opening -with Existing Operative Account' && this.props.freeFlag2 === "withKyc") {
            if (this.state.getData) {
                return (
                    CommonJsonFormComponent.renderJsonForm(this.state, this.checkReadOnlyField(this.props.jsonFormMisRelated), this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
                )
            }
        } else {
            return (
                <div>

                </div>
            )
        }

    });
    renderTPandKYCForm = (() => {
        if (this.props.freeFlag1 !== 'FDR Account Opening - with Existing Operative Account' && this.props.freeFlag1 !== 'DPS Account Opening -with Existing Operative Account' && this.props.freeFlag2 === "withKyc") {
            if (this.state.getData) {
                return (
                    CommonJsonFormComponent.renderJsonForm(this.state, this.checkReadOnlyField(fdKYC), this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
                )
            }
        } else {
            return (
                <div>

                </div>
            )
        }

    });

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, makerRemarks, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
            )
        }
    };

    copyObejct = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        /*  for (var prop in clone)
              if (clone[prop] === '' || clone[prop] === null || clone[prop] === 'undefined')
                  delete clone[prop];*/
        return clone;
    };

    dynamicApiCall = (fieldName, apiType) => {
        let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(fieldName);
                console.log(response);

                if (apiType === "city") {
                    this.findByVarNameApiData("maillingAdresscity", response.data);
                    this.findByVarNameApiData("homeAddresscity", response.data);
                    this.findByVarNameApiData("workAddresscity", response.data);
                    this.findByVarNameApiData("presentAddresscity", response.data);
                    this.findByVarNameApiData("nreAddresscity", response.data);
                    this.findByVarNameApiData("mailingAddresscity1", response.data);
                    this.findByVarNameApiData("homeAddresscity1", response.data);
                    this.findByVarNameApiData("workAddresscity1", response.data);
                    this.findByVarNameApiData("workAddresscity2", response.data);


                    this.setState({
                        city: response.data,
                        getCity: true
                    })

                } else if (apiType === "state") {

                    this.findByVarNameApiData("maillingAdressstate", response.data);
                    this.findByVarNameApiData("homeAddressstate", response.data);
                    this.findByVarNameApiData("workAddressstate", response.data);
                    this.findByVarNameApiData("presentAddressstate", response.data);
                    this.findByVarNameApiData("nreAddressstate", response.data);
                    this.findByVarNameApiData("mailingAddressstate1", response.data);
                    this.findByVarNameApiData("homeAddressstate1", response.data);
                    this.findByVarNameApiData("workAddressstate1", response.data);
                    this.findByVarNameApiData("workAddressstate2", response.data);

                    this.setState({
                        stateCode: response.data,
                        getState: true,
                    })
                } else if (apiType === "country") {
                    this.findByVarNameApiData("passportNumbercountryOfIssue", response.data);
                    this.findByVarNameApiData("maillingAdresscountry", response.data);
                    this.findByVarNameApiData("homeAddresscountry", response.data);
                    this.findByVarNameApiData("workAddresscountry", response.data);
                    this.findByVarNameApiData("Customersnationality", response.data);
                    this.findByVarNameApiData("presentAddresscountry", response.data);
                    this.findByVarNameApiData("nreAddresscountry", response.data);
                    this.findByVarNameApiData("mailingAddresscountry1", response.data);
                    this.findByVarNameApiData("homeAddresscountry1", response.data);
                    this.findByVarNameApiData("workAddresscountry1", response.data);
                    this.findByVarNameApiData("workAddresscountry2", response.data);
                    //this.findByVarNameApiData("passportNumberplaceOfIssue", response.data)


                    this.setState({
                        country: response.data,
                        getCountry: true
                    })
                } else if (apiType === "currency") {

                    //this.findByVarNameApiData("workAddressCurrency", response.data)
                    //  this.findByVarNameApiData("homeAddressCurrency", response.data)
                    {
                        this.findByVarNameApiData("ccy", response.data);
                        this.findByVarNameApiData("dpsccy", response.data)
                    }
                    this.setState({
                        currency: response.data,

                    })

                } else if (apiType === "phoneCountryCode") {

                    this.findByVarNameApiData("countryCodecontactNumberphoneNo1", response.data);
                    this.findByVarNameApiData("countryCodecontactNumberphoneNo2", response.data);
                    this.findByVarNameApiData("countryCodecontactNumberphoneNo3", response.data);
                    this.setState({
                        phoneNumCountryCodeList: response.data
                    })
                } else if (apiType === "customerStatus") {
                    this.findByVarNameApiData("miscustomerStatusNormalStaffPriorityEtc", response.data);
                    this.setState({
                        customerStatusList: [
                            {
                                "masterDataName": "customerStatus",
                                "key": "NOR",
                                "value": "NOR"
                            },
                            {
                                "masterDataName": "customerStatus",
                                "key": "STAFF",
                                "value": "STAFF"
                            },
                            {
                                "masterDataName": "customerStatus",
                                "key": "PRIOR",
                                "value": "PRIOR"
                            }
                        ],
                    })
                } else if (apiType === "savingsSchemeCode") {

                    this.setState({
                        savingsSchemeCodeList: response.data,
                    })
                } else if (apiType === "currentSchemeCode") {

                    this.setState({
                        currentSchemeCodeList: response.data,
                    })
                } else if (apiType === "sourceOfFund") {
                    this.findByVarNameApiData("sourceOfFund", response.data);
                    this.findByVarNameApiData("documentsCollectedToEnsureTheSourceOfFund", response.data)

                } else if (apiType === "placeOfIssue") {
                    this.findByVarNameApiData("passportNumberplaceOfIssue", response.data);
                    this.setState({
                        placeOfIssueList: response.data
                    })
                } else if (apiType === "despatchMode") {
                    this.findByVarNameApiData("aof1despatchModePrintedEStatementBoth", response.data);
                    this.setState({
                        despatchList: response.data
                    })
                } else if (apiType === "constitution") {
                    this.findByVarNameApiData("misconstitution", response.data);
                    this.setState({
                        constitutionList: response.data
                    })

                } else if (apiType === "monthlyIncome") {
                    this.findByVarNameApiData("customerMonthlyIncome", response.data)


                } else if (apiType === "accountOpen") {
                    this.findByVarNameApiData("howWasAccountOpened", response.data)


                } else if (apiType === "designationCode") {
                    this.findByVarNameApiData("relatedCbTaggingdesignationCode", response.data)

                } else if (apiType === "tradeService") {
                    this.findByVarNameApiData("misavailedTradeServices", response.data);
                    this.setState({
                        tradeServiceList: response.data
                    })

                } else if (apiType === "modOfOperation") {
                    this.findByVarNameApiData("schemeCodeChangemodeOfOperation", response.data);
                    this.setState({
                        despatchmodeList: response.data
                    })

                } else if (apiType === "introducerStatus") {
                    this.findByVarNameApiData("primryiintroducerStatus", response.data);
                    this.setState({
                        introducerStatus: response.data
                    })
                } else if (apiType === "bbkSegmentCode") {
                    this.findByVarNameApiData("misbbkSegmentCodeFreeCode7", response.data);
                    this.setState({
                        getfreecode7: response.data
                    })
                } else if (apiType === "priorityCenterCode") {
                    //this.findByVarNameApiData("mispriorityCenterCodeFreeCode1", response.data)
                } else if (apiType === "contactType") {
                    //this.findByVarNameApiData("contactNumbercontactType1", response.data)
                    // this.findByVarNameApiData("contactNumbercontactType2", response.data)
                    //this.findByVarNameApiData("contactNumbercontactType3", response.data)
                    // this.findByVarNameApiData("contactNumberpreferredContactNoType1", response.data)

                } else if (apiType === "addressType") {
                    //this.findByVarNameApiData("workAddressaddressType", response.data)
                    // this.findByVarNameApiData("maillingAdressaddrssType", response.data)
                    // this.findByVarNameApiData("maillingAdresspreferredAddressType", response.data)
                    //  this.findByVarNameApiData("homeAddressaddrssType", response.data)
                    // this.findByVarNameApiData("presentAddressaddrssType", response.data)
                    //this.findByVarNameApiData("nreAddressaddrssType", response.data)

                } else if (apiType === "guardianCode") {
                    this.findByVarNameApiData("guardianDetailsguardianSelectionGurdian", response.data);


                    this.setState({
                        getGuardianCode: response.data
                    })

                } else if (apiType === "accountStatement") {
                    //this.findByVarNameApiData("fincoreDataUpdateaccountStatement", response.data)
                    //  this.findByVarNameApiData("fincoreDataUpdateaccountStatement", response.data)
                } else if (apiType === "subSegment") {
                    this.findByVarNameApiData("crmMissubSegment", response.data);
                    this.setState({
                        subSegmentList: response.data
                    })

                } else if (apiType === "subSectorCode") {
                    this.findByVarNameApiData("fincoreDataUpdatesubSectorCode", response.data);
                    this.findByVarNameApiData("dpssubSectorCode", response.data);
                    this.setState({
                        subsectorCodeList: response.data
                    })
                } else if (apiType === "sectorCode") {
                    this.findByVarNameApiData("dpssectorCode", response.data);
                    this.findByVarNameApiData("fincoreDataUpdatesectorCode", response.data);
                    this.findByVarNameApiData(fieldName, response.data);
                    this.findByVarNameApiData("fincoreDataUpdatesectorCode", response.data);
                    this.setState({
                        sectorCodeList: response.data
                    })
                } else if (apiType === "depositCode") {
                    this.findByVarNameApiData("fincoreDataUpdatedepositCodeFreeCode7", response.data);
                    this.setState({
                        depositCodeList: response.data
                    })
                } else if (apiType === "dispatchMode") {
                    //  this.findByVarNameApiData("aof1despatchModePrintedEStatementBoth", response.data)
                    this.findByVarNameApiData("fincoreDataUpdatedispatchMode", response.data);
                    console.log("llllllllllll");
                    console.log(response.data);
                    this.setState({
                        dispatchMode: response.data
                    })
                } else if (apiType === "rmCode") {
                    this.findByVarNameApiData("mispriorityMonitoringRmCodeFreeCode3", response.data);
                    this.setState({
                        rmCodeList: response.dat
                    })

                } else if (apiType === "accountStatement") {
                    this.findByVarNameApiData("aof1statement", response.data)
                    //this.findByVarNameApiData("fincoreDataUpdateaccountStatement", response.data)
                } else if (apiType === "statementFrequency") {
                    this.findByVarNameApiData("fincoreDataUpdatestatementFrequency", response.data);
                    this.findByVarNameApiData("aof1statement", response.data)
                } else if (apiType === "chequebookWaiverFunctionCode") {
                    // this.findByVarNameApiData("misfunctionCodeForChequeBookWaiver", response.data)
                    // this.findByVarNameApiData("schemeCodeChangefunctionForSchemeChangeInCaseOfInstaPack", response.data)
                } else if (apiType === "chequebookWaiverType") {
                    this.findByVarNameApiData("miswaiverTypeForChequeBookWaiver", response.data);
                    this.setState({
                        chequebookWaiverType: response.data
                    })
                } else if (apiType === "branch") {
                    this.findByVarNameApiData("otherBankbranchName", response.data)

                } else if (apiType === "relationCode") {
                    this.findByVarNameApiData("relatedCbTaggingrelationCode", response.data);
                    this.setState({
                        relationCodeList: response.data
                    })
                } else if (apiType === "nomineeRelationCode") {
                    this.findByVarNameApiData("guardianDetailsrelation1", response.data);
                    this.setState({
                        nomineeRelationShipCodeList: response.data,
                    })
                } else if (apiType === "addressFormat") {
                    this.findByVarNameApiData("maillingAdressaddressFormat", response.data);
                    this.findByVarNameApiData("homeAddressaddressFormat", response.data);
                    this.findByVarNameApiData("workAddressaddressFormat", response.data);
                    this.findByVarNameApiData("presentAddressaddressFormat", response.data);
                    this.findByVarNameApiData("mailingAddressaddressFormat1", response.data);
                    this.findByVarNameApiData("homeAddressaddressFormat1", response.data);
                    this.findByVarNameApiData("workAddressaddressFormat1", response.data);
                    this.findByVarNameApiData("workAddressaddressFormat2", response.data)

                } else if (apiType === "agentBankingOutlet") {
                    this.findByVarNameApiData("misagentBankingOutletFreeCode1", response.data);
                    this.findByVarNameApiData("freeCode1SpecialTaxagentOutlet", response.data);
                    this.findByVarNameApiData("dpsfreeCode1SpecialTaxagentOutlet", response.data);
                    this.setState({
                        agentBankingOutletList: response.data
                    })
                } else if (apiType === "waiverField") {
                    this.findByVarNameApiData("miswaiverFieldFreeCode10", response.data);
                    this.setState({
                        waiverFieldList: response.data
                    })
                    // this.findByVarNameApiData("misdebitCardIssueFreeCode10", response.data)

                } else if (apiType === "ccepCode") {
                    this.findByVarNameApiData("misccepCodeFreeCode9", response.data);
                    this.setState({
                        ccepCodeList: response.data
                    })

                } else if (apiType === "nonResident") {
                    this.findByVarNameApiData("chairmanCertificatenonResident", response.data);
                    this.setState({
                        getnonResident: response.data
                    })

                } else if (apiType === "agentBankingOutlet") {
                    this.findByVarNameApiData("misagentBankingOutletFreeCode1", response.data);
                    this.setState({
                        agentBankingOutletList: response.data
                    })
                } else if (apiType === "maritalStatus") {
                    this.findByVarNameApiData("basicInformationUpdatemaritalStatus", response.data);
                    this.findByVarNameApiData("basicInformationmaritalStatus", response.data);
                    this.setState({
                        maritalStatusList: response.data
                    })

                } else if (apiType === "employmentType") {
                    this.findByVarNameApiData("employerDataprofessionEmploymentType", response.data);
                    this.findByVarNameApiData("etinprofessionEmploymentType", response.data);
                    this.findByVarNameApiData("employerDataemploymentType", response.data);
                    this.setState({
                        employmentTypeList: response.data
                    })
                } else if (apiType === "relationship") {
                    this.findByVarNameApiData("guardianDetailsrelation", response.data);
                    this.findByVarNameApiData("guardianDetailsrelation1", response.data);
                    this.setState({
                        relationshipList: response.data
                    })
                } else if (apiType === "relationType") {
                    this.findByVarNameApiData("relatedCbTaggingrelationType", response.data)

                } else if (apiType === "contactType") {
                    // this.findByVarNameApiData("contactNumberpreferredContactNoType1", response.data)
                    /*  this.findByVarNameApiData("contactNumbercontactType1", response.data)
                      this.findByVarNameApiData("contactNumbercontactType", response.data)
                      this.findByVarNameApiData("contactNumbercontactType2", response.data)
                      this.findByVarNameApiData("contactNumbercontactType3", response.data)
                      this.findByVarNameApiData("contactNumberpreferredContactNoType1", response.data)
                      this.findByVarNameApiData("contactNumberpreferredContactNoType1", response.data)*/


                } else if (apiType === "title") {
                    this.findByVarNameApiData("mistitle", response.data);
                    this.findByVarNameApiData("relatedCbTaggingtitle", response.data);
                    this.findByVarNameApiData("aCTitle", response.data);
                    this.setState({
                        titleList: response.data
                    })


                } else if (apiType === "documentCode") {
                    //this.findByVarNameApiData("natioanlIdCarddocumentCodeN", response.data)
                    //this.findByVarNameApiData("passportNumberdocumentCodeP", response.data)
                    // this.findByVarNameApiData("birthCertificatedocumentCodeB", response.data)
                    // this.findByVarNameApiData("chairmanCertificatedocumentCodeCC", response.data)
                    // this.findByVarNameApiData("smartIdCarddocumentCodeS", response.data)
                    // this.findByVarNameApiData("nationalIdCarddocumentCode", response.data)
                    // this.findByVarNameApiData("smartlIdCarddocumentCode", response.data)
                    //  this.findByVarNameApiData("passportNumberdocumentCode", response.data)
                    // this.findByVarNameApiData("birthCertificatedocumentCode", response.data)
                    // this.findByVarNameApiData("chairmanCertificatedocumentCode", response.data)
                    //  this.findByVarNameApiData("etindocumentCode", response.data)
                    this.findByVarNameApiData("photoIddocumentCode", response.data)

                } else if (apiType === "bankName") {
                    this.findByVarNameApiData("otherBankbankName", response.data)


                } else if (apiType === "segment") {
                    this.findByVarNameApiData("misbbkSegmentCodeFreeCode7", response.data)


                } else if (apiType === "phoneOrEmailId") {
                    this.findByVarNameApiData("contactNumberphoneNoEmailId1", response.data);
                    this.findByVarNameApiData("contactNumberphoneNoEmailId2", response.data);
                    this.findByVarNameApiData("contactNumberphoneNoEmailId3", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId4", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId1", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId2", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId", response.data);
                    this.setState({
                        phoneOrEmail: response.data
                    })
                } else if (apiType === "customerOccupation") {

                    this.findByVarNameApiData("customersOccupation", response.data)

                } else if (apiType === "bankRelationType") {

                    this.findByVarNameApiData("guardianDetailsbankRelationType", response.data)

                } else if (apiType === "emailType") {

                    this.findByVarNameApiData("contactNumberEmailemailType1", response.data);
                    this.findByVarNameApiData("contactNumberEmailemailType2", response.data);
                    this.findByVarNameApiData("contactNumberEmailemailType", response.data);
                    this.findByVarNameApiData("contactNumberEmailpreferedEmailIdType", response.data);
                    this.setState({
                        phoneEmailType: response.data
                    })
                } else if (apiType === "addressVariety") {

                    this.findByVarNameApiData("howTheAddressOfTheAccountHolderHasBeenVerified", response.data)

                } else if (apiType === "customerBusiness") {

                    this.findByVarNameApiData("whatDoesTheCustomerDoInWhatTypeOfBusineessIsTheCustomerEngaged", response.data)

                } else if (apiType === "dpsSchemeCode") {
                    if (this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account') {
                        this.findByVarNameApiData("schemeCode", response.data);
                        this.findByVarNameApiData("dpsschemeCode", response.data);

                        this.setState({
                            fdrSchemeCodeList: response.data,
                        })
                    }

                } else if (apiType === "fdrSchemeCode") {
                    if (this.props.freeFlag1 !== 'DPS Account Opening -with Existing Operative Account') {

                        this.findByVarNameApiData("schemeCode", response.data);

                        this.setState({
                            fdrSchemeCodeList: response.data,
                        })
                    }
                } else if (apiType === "customerStatus") {
                    this.findByVarNameApiData("miscustomerStatusNormalStaffPriorityEtc", response.data);
                    this.setState({
                        customerStatusList: response.data
                    })
                } else if (apiType === "sourceOfFund") {
                    this.findByVarNameApiData("documentsCollectedToEnsureTheSourceOfFund", response.data)

                } else if (apiType === "constitution") {
                    this.findByVarNameApiData("misconstitution", response.data)

                } else if (apiType === "designationCode") {
                    this.findByVarNameApiData("relatedCbTaggingdesignationCode", response.data);
                    this.findByVarNameApiData("designationCode", response.data);
                    this.setState({

                        designationCode: response.data,
                    })

                } else if (apiType === "tradeService") {
                    this.findByVarNameApiData("misavailedTradeServices", response.data)

                } else if (apiType === "modOfOperation") {
                    this.findByVarNameApiData("schemeCodeChangemodeOfOperation", response.data)

                } else if (apiType === "introducerStatus") {
                    this.findByVarNameApiData("primryiintroducerStatus", response.data)
                } else if (apiType === "bbkSegmentCode") {
                    this.findByVarNameApiData("misbbkSegmentCodeFreeCode7", response.data)
                } else if (apiType === "priorityCenterCode") {
                    this.findByVarNameApiData("mispriorityCenterCodeFreeCode1", response.data)
                } else if (apiType === "contactType") {
                    this.findByVarNameApiData("contactNumbercontactType1", response.data);
                    this.findByVarNameApiData("contactNumbercontactType2", response.data);
                    this.findByVarNameApiData("contactNumbercontactType3", response.data);
                    this.findByVarNameApiData("contactNumberpreferredContactNoType1", response.data)
                } else if (apiType === "addressType") {
                    this.findByVarNameApiData("workAddressaddressType", response.data);
                    this.findByVarNameApiData("maillingAdressaddrssType", response.data);
                    this.findByVarNameApiData("maillingAdresspreferredAddressType", response.data);
                    this.findByVarNameApiData("homeAddressaddrssType", response.data);
                    this.findByVarNameApiData("presentAddressaddrssType", response.data)

                } else if (apiType === "guardianCode") {
                    this.findByVarNameApiData("guardianDetailsguardianSelectionGurdian", response.data);

                    this.findByVarNameApiData(fieldName, response.data);

                    this.setState({
                        getGuardianCode: response.data
                    })

                } else if (apiType === "accountStatement") {
                    this.findByVarNameApiData("fincoreDataUpdateaccountStatement", response.data)

                } else if (apiType === "subSegment") {
                    this.findByVarNameApiData("crmMissubSegment", response.data)

                }
                /* else if (apiType === "subSectorCode") {
                    this.findByVarNameApiData("fincoreDataUpdatesubSectorCode", response.data)

                }*/
                else if (apiType === "depositCode") {
                    this.findByVarNameApiData("fincoreDataUpdatedepositCodeFreeCode7", response.data);
                    this.findByVarNameApiData("freeCode7DepositCode", response.data)

                } else if (apiType === "dispatchMode") {
                    // this.findByVarNameApiData("aof1despatchModePrintedEStatementBoth", response.data)
                    this.findByVarNameApiData("fincoreDataUpdatedispatchMode", response.data);
                    console.log("llllllllllll");
                    console.log(response.data);
                    this.setState({
                        dispatchMode: response.data
                    })
                } else if (apiType === "rmCode") {
                    this.findByVarNameApiData("mispriorityMonitoringRmCodeFreeCode3", response.data)
                } else if (apiType === "accountStatement") {
                    this.findByVarNameApiData("aof1statement", response.data);
                    this.findByVarNameApiData("fincoreDataUpdateaccountStatement", response.data)
                } else if (apiType === "statementFrequency") {
                    this.findByVarNameApiData("fincoreDataUpdatestatementFrequency", response.data);
                    this.findByVarNameApiData("aof1statement", response.data)
                } else if (apiType === "chequebookWaiverFunctionCode") {
                    this.findByVarNameApiData("misfunctionCodeForChequeBookWaiver", response.data);
                    this.findByVarNameApiData("schemeCodeChangefunctionForSchemeChangeInCaseOfInstaPack", response.data)
                } else if (apiType === "chequebookWaiverType") {
                    this.findByVarNameApiData("miswaiverTypeForChequeBookWaiver", response.data)
                } else if (apiType === "branch") {
                    this.findByVarNameApiData("otherBankbranchName", response.data)

                } else if (apiType === "relationCode") {
                    this.findByVarNameApiData("relatedCbTaggingrelationCode", response.data);
                    this.findByVarNameApiData("relationCode", response.data);
                    this.setState({
                        relationCode: response.data,
                    })
                } else if (apiType === "addressFormat") {
                    this.findByVarNameApiData("maillingAdressaddressFormat", response.data);
                    this.findByVarNameApiData("homeAddressaddressFormat", response.data);
                    this.findByVarNameApiData("workAddressaddressFormat", response.data);
                    this.findByVarNameApiData("presentAddressaddressFormat", response.data);
                    this.findByVarNameApiData("mailingAddressaddressFormat1", response.data);
                    this.findByVarNameApiData("homeAddressaddressFormat1", response.data);
                    this.findByVarNameApiData("workAddressaddressFormat1", response.data);
                    this.findByVarNameApiData("workAddressaddressFormat2", response.data)

                } else if (apiType === "agentBankingOutlet") {
                    this.findByVarNameApiData("misagentBankingOutletFreeCode1", response.data)

                } else if (apiType === "waiverField") {
                    this.findByVarNameApiData("miswaiverFieldFreeCode10", response.data);
                    this.findByVarNameApiData("misdebitCardIssueFreeCode10", response.data)

                } else if (apiType === "ccepCode") {
                    this.findByVarNameApiData("misccepCodeFreeCode9", response.data)

                } else if (apiType === "nonResident") {
                    this.findByVarNameApiData("chairmanCertificatenonResident", response.data)

                } else if (apiType === "agentBankingOutlet") {
                    this.findByVarNameApiData("misagentBankingOutletFreeCode1", response.data)
                } else if (apiType === "maritalStatus") {
                    this.findByVarNameApiData("basicInformationUpdatemaritalStatus", response.data);
                    this.findByVarNameApiData("basicInformationmaritalStatus", response.data)


                } else if (apiType === "employmentType") {
                    this.findByVarNameApiData("employerDataprofessionEmploymentType", response.data);
                    this.findByVarNameApiData("etinprofessionEmploymentType", response.data);
                    this.findByVarNameApiData("employerDataemploymentType", response.data)
                } else if (apiType === "relationship") {
                    this.findByVarNameApiData("guardianDetailsrelation", response.data);
                    this.findByVarNameApiData("guardianDetailsrelation1", response.data)
                } else if (apiType === "relationType") {
                    this.findByVarNameApiData("relatedCbTaggingrelationType", response.data);
                    {
                        this.findByVarNameApiData("relationType", response.data)
                    }
                    this.setState({
                        relationType: response.data,

                    })

                } else if (apiType === "contactType") {
                    this.findByVarNameApiData("contactNumbercontactType1", response.data);
                    this.findByVarNameApiData("contactNumbercontactType", response.data);
                    this.findByVarNameApiData("contactNumbercontactType2", response.data);
                    this.findByVarNameApiData("contactNumbercontactType3", response.data);
                    this.findByVarNameApiData("contactNumberpreferredContactNoType1", response.data);
                    this.findByVarNameApiData("contactNumberpreferredContactNoType1", response.data)

                } else if (apiType === "title") {
                    this.findByVarNameApiData("mistitle", response.data);
                    this.findByVarNameApiData("relatedCbTaggingtitle", response.data)


                } else if (apiType === "documentCode") {
                    this.findByVarNameApiData("natioanlIdCarddocumentCodeN", response.data);
                    this.findByVarNameApiData("passportNumberdocumentCodeP", response.data);
                    this.findByVarNameApiData("birthCertificatedocumentCodeB", response.data);
                    this.findByVarNameApiData("chairmanCertificatedocumentCodeCC", response.data);
                    this.findByVarNameApiData("smartIdCarddocumentCodeS", response.data);
                    this.findByVarNameApiData("nationalIdCarddocumentCode", response.data);
                    this.findByVarNameApiData("smartlIdCarddocumentCode", response.data);
                    this.findByVarNameApiData("passportNumberdocumentCode", response.data);
                    this.findByVarNameApiData("birthCertificatedocumentCode", response.data);
                    this.findByVarNameApiData("chairmanCertificatedocumentCode", response.data);
                    this.findByVarNameApiData("etindocumentCode", response.data)

                } else if (apiType === "bankName") {
                    this.findByVarNameApiData("otherBankbankName", response.data)


                } else if (apiType === "segment") {
                    this.findByVarNameApiData("misbbkSegmentCodeFreeCode7", response.data)


                } else if (apiType === "phoneOrEmailId") {
                    this.findByVarNameApiData("contactNumberphoneNoEmailId1", response.data);
                    this.findByVarNameApiData("contactNumberphoneNoEmailId2", response.data);
                    this.findByVarNameApiData("contactNumberphoneNoEmailId3", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId4", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId1", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId2", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId", response.data)

                } else if (apiType === "customerOccupation") {

                    this.findByVarNameApiData("customersOccupation", response.data)

                } else if (apiType === "bankRelationType") {

                    this.findByVarNameApiData("guardianDetailsbankRelationType", response.data)

                } else if (apiType === "emailType") {

                    this.findByVarNameApiData("contactNumberEmailemailType1", response.data);
                    this.findByVarNameApiData("contactNumberEmailemailType2", response.data);
                    this.findByVarNameApiData("contactNumberEmailemailType", response.data);
                    this.findByVarNameApiData("contactNumberEmailpreferedEmailIdType", response.data)

                } else if (apiType === "addressVariety") {

                    this.findByVarNameApiData("howTheAddressOfTheAccountHolderHasBeenVerified", response.data)

                } else if (apiType === "customerBusiness") {

                    this.findByVarNameApiData("whatDoesTheCustomerDoInWhatTypeOfBusineessIsTheCustomerEngaged", response.data)

                }
                /*  else if (apiType === "accountOpen") {

                      this.findByVarNameApiData("", response.data)

                  }
                  else if (apiType === "monthlyIncome") {

                      this.findByVarNameApiData("", response.data)

                  }*/


            })
            .catch((error) => {
                console.log(error);
            })
    };

    /* dynamicApiCall=(fieldName,apiType)=>{
         let codeUrl = backEndServerURL + "/finMasterData/"+apiType;
         axios.get(codeUrl, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
             .then((response) => {
                 console.log(fieldName);
                 console.log(response);
                 if(apiType==="city"){
                     {
                         this.findByVarNameApiData(fieldName, response.data)
                     }
                     this.setState({
                         city:response.data,
                         getCity: true,

                     })
                 }
                 else if(apiType==="state"){
                     {
                         this.findByVarNameApiData(fieldName, response.data)
                     }
                     this.setState({
                         stateCode: response.data,
                         getState: false,

                     })
                 }
                 else if(apiType==="guardianCode"){
                     {
                         this.findByVarNameApiData(fieldName, response.data)
                     }
                     this.setState({
                         getGuardianCode: response.data,


                     })
                 }
                 else if(apiType==="country"){
                     {
                         this.findByVarNameApiData(fieldName, response.data)
                     }
                     this.setState({
                         country:  response.data,
                         getCountry: true,

                     })
                 }
                 else if(apiType==="currency"){
                     {
                         this.findByVarNameApiData("ccy", response.data)
                     }
                     this.setState({
                         currency:  response.data,

                     })
                 } else if(apiType==="designationCode"){
                     {
                         this.findByVarNameApiData("designationCode", response.data)
                     }
                     this.setState({

                         designationCode:response.data,
                     })

                 }
                 else if(apiType==="relationCode"){
                     {
                         this.findByVarNameApiData("relationCode", response.data)
                     }
                     this.setState({
                         relationCode:response.data,
                     })

                 } else if(apiType==="relationType"){
                     {
                         this.findByVarNameApiData("relationCode", response.data)
                     }
                     this.setState({
                         relationType:response.data,

                     })

                 } else if(apiType==="sourceOfFund"){
                     {
                         this.findByVarNameApiData("documentsCollectedToEnsureTheSourceOfFund", response.data)
                     }

                 }else if(apiType==="addressVariety"){
                     {
                         this.findByVarNameApiData("howTheAddressOfTheAccountHolderHasBeenVerified", response.data)
                     }

                 } else if(apiType==="depositCode"){
                     {
                         this.findByVarNameApiData("freeCode7DepositCode", response.data)
                     }

                 }
                 else{
                     {
                         this.findByVarNameApiData(fieldName, response.data)
                     }

                 }

             })
             .catch((error) => {
                 console.log(error);
             })
     }*/
    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = this.props.commonJsonForm;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                this.props.commonJsonForm[i].enum = getValue;
            }
        }
        jsonArray = this.props.commonJsonFormLast;

        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                this.props.commonJsonFormLast[i].enum = getValue;
            }
        }
        if (this.props.freeFlag1 !== 'FDR Account Opening - with Existing Operative Account' && this.props.freeFlag1 !== 'DPS Account Opening -with Existing Operative Account' && this.props.freeFlag2 === "withKyc") {
            jsonArray = this.props.jsonFormMisRelated;

            for (let i = 0; i < jsonArray.length; i++) {
                let jsonObject = jsonArray[i];
                if (jsonObject.varName === searchVarname) {
                    this.props.jsonFormMisRelated[i].enum = getValue;
                }
            }
        }
        jsonArray = fdKYC;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                fdKYC[i].enum = getValue;
            }
        }
    };

    dynamicApiCallDocumentType = (fieldName, apiType) => {
        let codeUrl = backEndServerURL + "/finMasterData/custom/uniqueDocType/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (apiType === "documentCodeDocumentType") {
                    // this.findByVarNameApiData("natioanlIdCarddocumentTypeN", response.data)
                    // this.findByVarNameApiData("smartIdCarddocumentTypeS", response.data)
                    //this.findByVarNameApiData("passportNumberdocumentTypeP", response.data)
                    // this.findByVarNameApiData("birthCertificatedocumentTypeB", response.data)
                    // this.findByVarNameApiData("chairmanCertificatedocumentTypeCC", response.data)
                    //  this.findByVarNameApiData("etindocumentType", response.data)
                    this.findByVarNameApiData("photoIddocumentType", response.data)

                } else if (apiType === "documentCodeDocumentTypeDesc") {
                    //  this.findByVarNameApiData("natioanlIdCarddocumentTypeDescriptionN", response.data)
                    // this.findByVarNameApiData("smartIdCarddocumentTypeDescriptionS", response.data)
                    /// this.findByVarNameApiData("passportNumberdocumentTypeDescriptionP", response.data)
                    // this.findByVarNameApiData("birthCertificatedocumentTypeDescriptionB", response.data)
                    // this.findByVarNameApiData("chairmanCertificatedocumentTypeDescriptionCC", response.data)
                    // this.findByVarNameApiData("etindocumentTypeDescription", response.data)
                    this.findByVarNameApiData("photoIddocumentTypeDescription", response.data)

                }

            })
            .catch((error) => {
                console.log(error)
            })
    };

    stopLoading = () => {
        this.setState({
            loading: false
        })
    };

    apiDataSet = (data, inputData, accountRelated, category) => {
        if (data !== undefined && data !== null && data !== "") {
            inputData.fincoreDataUpdatestatementFrequency1 = "Monthly";
            inputData.fincoreDataUpdatestatementFrequency3 = "Next Day";

            inputData.nomineeRegNo = 0;
            inputData.nomineeRelationship = "ZZZ";
            inputData.percentageNominee = "100.00";
            inputData.nomineeAddress1 = "SAME AS LINK A/C";
            inputData.nomineeCityCode = "ZZZ";
            inputData.nomineeStateCode = "ZZZ";
            inputData.nomineePostalCode = "9999";
            inputData.nomineeCountry = "ZZZ";
            inputData.photoIdNumberRegNo = "0";
            inputData.nomineeDob = new Date("1900-01-01T00:00:00.000");
            inputData.nomineeMinor = "N";
            inputData.tranCreationDuring = "V";
            inputData.sectorCodeApiCall = true;
            inputData.fdMaturityDateApicall = "YES";
            inputData.taxCategory = {
                "masterDataName": "taxCategory",
                "key": "W",
                "value": "W-A/c.Level Withholding Tax"
            };

            inputData.withHoldingTaxLevel = {
                "masterDataName": "withHoldingTaxLevel",
                "key": "A",
                "value": "A-A/c.Level"
            };
            inputData.withholdingTaxBorneBy = {
                "masterDataName": "withholdingTaxBorneBy",
                "key": "P",
                "value": "Principal A/c.Holder Only"
            };
            if (accountRelated === "branchData") {
                inputData.dpssolId = data;
                inputData.solId = data;
                inputData.accountId = data + "0149148001";
                inputData.dpssolId = data;
                inputData.missolId = data;
            }
            /*    if(this.props.freeFlag1 === 'FDR Account Opening -Without Operative Account-NTB'){
                    inputData.etin=inputData.tin;
                }
    */
            if (category === 'YES' && accountRelated === "DPS Account Opening -with Existing Operative Account") {
                inputData.dpscifId=this.props.cbNumber;
                if(data.currency!==undefined && data.currency!==""){
                    inputData.dpsccy=this.findByVarNameGetKeyValue(data.currency, this.state.currency)
                }
                if(data.customerName!==undefined){
                    inputData.dpsaccountName=data.customerName;
                    inputData.customerName=data.customerName;
                }
                if(data.shortName!==undefined){
                    inputData.shortName= data.shortName;
                }

                inputData.debitAccountNumber = inputData.accountNumber;
                inputData.nomineeName = "SAME AS " + inputData.accountNumber;
                // if (inputData.maturity === "Renew Principal Only and Credit Interest to") {
                inputData.interestCreditAccount = inputData.interestCreditAccount;
                inputData.interestCreditName = inputData.interestCreditName;
                inputData.interestCreditAccountApiCall = "YES";
                inputData.interestCreditAccountVisible = "YES";

                // }
                /*else {
                    inputData.interestCreditAccount = "";
                    inputData.interestCreditName = "";
                    inputData.interestCreditAccountApiCall = "NO";
                    inputData.interestCreditAccountVisible = "NO";

                }*/
                //if (inputData.maturity === "Encashment at Maturity") {
                inputData.repaymentAcId = inputData.repaymentAccount;
                inputData.repaymentName = inputData.repaymentName;
                inputData.repaymentAccountApiCall = "YES";
                inputData.repaymentAcIdVisible = "YES";

                // }
                /*else {
                        inputData.repaymentAcId = "";
                        inputData.repaymentName = "";
                        inputData.repaymentAccountApiCall = "NO";
                        inputData.repaymentAcIdVisible = "NO";

                    }*/
                if (inputData.buttonCount === undefined || inputData.buttonCount === null) {
                    inputData.buttonCount = 0;
                    let buttonData = [];

                    if (this.props.freeFlag1 !== 'FDR Account Opening - with Existing Operative Account' && this.props.freeFlag1 !== 'DPS Account Opening -with Existing Operative Account' && this.props.freeFlag2 === "withKyc") {
                        buttonData.push("updateCbInfo");
                        inputData.buttonFlow = buttonData
                    }
                    buttonData.push("updateAccountInfo");
                    inputData.buttonFlow = buttonData;
                    if (this.props.freeFlag1 !== 'FDR Account Opening - with Existing Operative Account' && this.props.freeFlag1 !== 'DPS Account Opening -with Existing Operative Account' && this.props.freeFlag2 === "withKyc") {
                        buttonData.push("updateKycInfo");
                        inputData.buttonFlow = buttonData
                    }
                    buttonData.push("finalApproval");
                    inputData.buttonFlow = buttonData
                    /*  this.setState({
                          inputData:this.emptyValueRemove(inputData),
                       })
                       this.state.inputData=this.emptyValueRemove(inputData);
                       this.forceUpdate();*/
                }
                //Extra
                /*  if (inputData.currency !== undefined && inputData.currency.key !== undefined) {
                      inputData.dpsccy = inputData.currency
                  } else {
                      inputData.dpsccy = this.findByVarNameGetKeyValue(inputData.currency, this.state.currency)
                  }*/
                inputData.taxCategory = {
                    "masterDataName": "taxCategory",
                    "key": "W",
                    "value": "W-A/c.Level Withholding Tax"
                };

                inputData.withHoldingTaxLevel = {
                    "masterDataName": "withHoldingTaxLevel",
                    "key": "A",
                    "value": "A-A/c.Level"
                };
                inputData.withholdingTaxBorneBy = {
                    "masterDataName": "withholdingTaxBorneBy",
                    "key": "P",
                    "value": "Principal A/c.Holder Only"
                };

                inputData.dpspaymentAccountId = inputData.debitAccountNumber;
                inputData.dpspaymentAccountName = inputData.debitAccountName;
                inputData.dpspaymentAccountIdApiCall = "YES";

                //Extra
                /* inputData.dpscifId = inputData.cbNumber;*/
                inputData.miscustomerIdCifId = inputData.cbNumber;
                /* inputData.dpsaccountName = inputData.customerName;*/
                inputData.dpsaccountOpeningDate = inputData.dpsfdValueDate;
                inputData.clearingLimitDR = inputData.clearingLimitDRs;
                inputData.clearingLimitCR = inputData.clearingLimitCRs;
                inputData.TransferLimitCR = inputData.TransferLimitCRs;


                //inputData.schemeCode = inputData.dpsdpsSchemeCode
                //inputData.ccy = inputData.currency
                //inputData.taxCategory =//dropdown
                // inputData.withHoldingTaxLabel =//dropdown
                //inputData.withHoldingTaxBorneBy = //dropdown
                if (data.ETINBLOCK !== undefined && data.ETINBLOCK.referenceNum!==undefined && (data.ETINBLOCK.referenceNum.trim()).length==12) {
                    inputData.withholdingTaxPcnt = 10
                } else {
                    inputData.withholdingTaxPcnt = 15
                }
                if (inputData.nomineeExistNot === "Y") {
                    this.setState({
                        nomineeExistNot: true
                    })
                }
                // inputData.nomineeExistNot = inputData.nomineeTypes
                //inputData.nomineeType = inputData.nomineeTypes

                //inputData.maturity = inputData.maturitys
                // inputData.valueDate = inputData.dpsfdValueDate;
                //inputData.depositAmount = inputData.dpsamount
                //inputData.depositTenure = inputData.dpstenureType;
                //inputData.depositPeriodMonthsdays = inputData.dpstenure;
                //inputData.maturityDate = inputData.dpsfdMaturityDate
                //inputData.maturityValue =    //input field text
                //inputData.paymentAccountId = inputData.debitAccountNumber  //repayment account id
                // inputData.sectorCode = inputData.sectorCode
                // inputData.sectorCodeApiCall = true
                //inputData.subSectorCode = inputData.subSectorCode
                //inputData.occupationCode = inputData.occupationCode
                //inputData.freeCode1SpecialTaxagentOutlet= inputData.
                // inputData.rmCode = inputData.rmCode
                inputData.rmCodeApiCall = "YES";
                //inputData.rmCodeName = inputData.rmCodeName;
                if (inputData.dpsaccountType === "Monthly Interest Paying FD") {
                    inputData.dpsfreeCode6 = 8
                } else {
                    inputData.dpsfreeCode6 = 4
                }

                inputData.rmCodeName = inputData.rmCodeName;
                // inputData.freeCode7DepositCode = inputData.dpsdepositType
                if (data.ETINBLOCK !== undefined) {
                    inputData.dpsetinNumber = data.ETINBLOCK.referenceNum;
                    inputData.dpsetin = data.ETINBLOCK.referenceNum
                }
                //inputData.siSrlNo =   //input field
                //inputData.standingInstructionsFrequency =   //dropdown field===text
                //inputData.nextExecutionDate =   //date input field
            }
            else if (accountRelated !== "DPS Account Opening -with Existing Operative Account") {
                inputData.cifId=this.props.cbNumber;
                if(data.currency!==undefined && data.currency!==""){
                    inputData.ccy=this.findByVarNameGetKeyValue(data.currency, this.state.currency);
                 }
                if(data.customerName!==undefined){
                    inputData.acName=data.customerName;
                    inputData.customerName=data.customerName;
                }
                if(data.shortName!==undefined){
                    inputData.shortName= data.shortName;
                }

                if (this.state.FormCasaFlow === 'YES') {
                    inputData.debitAccountNumber = inputData.accountNumber;
                    inputData.nomineeName = "SAME AS " + inputData.accountNumber
                } else {
                    inputData.nomineeName = "SAME AS " + inputData.debitAccountNumber
                }

                inputData.nomineeName = "SAME AS " + inputData.debitAccountNumber;
                //  if (inputData.maturity === "Renew Principal Only and Credit Interest to") {
                inputData.interestCreditAccount = inputData.interestCreditAccount;
                inputData.interestCreditName = inputData.interestCreditName;
                inputData.interestCreditAccountApiCall = "YES";
                inputData.interestCreditAccountVisible = "YES";

                // }
                /* else {
                        inputData.interestCreditAccount = "";
                        inputData.interestCreditName = "";
                        inputData.interestCreditAccountApiCall = "NO";
                        inputData.interestCreditAccountVisible = "NO";

                    }*/
                //  if (inputData.maturity === "Encashment at Maturity") {
                inputData.repaymentAcId = inputData.repaymentAccount;
                inputData.repaymentName = inputData.repaymentName;
                inputData.repaymentAccountApiCall = "YES";
                inputData.repaymentAcIdVisible = "YES";

                // }
                /*else {
                    inputData.repaymentAcId = "";
                    inputData.repaymentName = "";
                    inputData.repaymentAccountApiCall = "NO";
                    inputData.repaymentAcIdVisible = "NO";

                }*/
                if (inputData.buttonCount === undefined || inputData.buttonCount === null) {
                    inputData.buttonCount = 0;
                    let buttonData = [];

                    if (this.props.freeFlag1 !== 'FDR Account Opening - with Existing Operative Account' && this.props.freeFlag1 !== 'DPS Account Opening -with Existing Operative Account' && this.props.freeFlag2 === "withKyc") {
                        buttonData.push("updateCbInfo");
                        inputData.buttonFlow = buttonData
                    }
                    buttonData.push("updateAccountInfo");
                    inputData.buttonFlow = buttonData;
                    if (this.props.freeFlag1 !== 'FDR Account Opening - with Existing Operative Account' && this.props.freeFlag1 !== 'DPS Account Opening -with Existing Operative Account' && this.props.freeFlag2 === "withKyc") {
                        buttonData.push("updateKycInfo");
                        inputData.buttonFlow = buttonData
                    }
                    buttonData.push("finalApproval");
                    inputData.buttonFlow = buttonData
                    /*  this.setState({
                          inputData:this.emptyValueRemove(inputData),
                       })
                       this.state.inputData=this.emptyValueRemove(inputData);
                       this.forceUpdate();*/
                }
                //Extra
                /* if (inputData.currency !== undefined && inputData.currency.key !== undefined) {
                     inputData.ccy = inputData.currency;
                 } else {
                     inputData.ccy = this.findByVarNameGetKeyValue(inputData.currency, this.state.currency);
                 }*/
                inputData.taxCategory = {
                    "masterDataName": "taxCategory",
                    "key": "W",
                    "value": "W-A/c.Level Withholding Tax"
                };

                inputData.withHoldingTaxLevel = {
                    "masterDataName": "withHoldingTaxLevel",
                    "key": "A",
                    "value": "A-A/c.Level"
                };
                inputData.withholdingTaxBorneBy = {
                    "masterDataName": "withholdingTaxBorneBy",
                    "key": "P",
                    "value": "Principal A/c.Holder Only"
                };
                //Extra
                // inputData.cifId = inputData.cbNumber;
                inputData.miscustomerIdCifId = inputData.cbNumber;
                /*  if(inputData.currency!==undefined && inputData.currency.key!==undefined){
                      inputData.ccy = inputData.currency
                  }
                  else if (category === 'YES') {
                      inputData.ccy = inputData.currency
                  }
                 else if (inputData.currencyNtb !== undefined) {
                      inputData.ccy = this.findByVarNameGetKeyValue(inputData.currencyNtb, this.state.currency)

                  } else {
                      inputData.ccy = this.findByVarNameGetKeyValue(inputData.currency, this.state.currency)

                  }
    */
                // inputData.schemeCode = inputData.fdrSchemeCode
                //Extra
                /*  inputData.acName = inputData.customerName;
                  inputData.shortName = data.shortName;*/
                inputData.acOpeningDate = inputData.fdValueDate;
                //inputData.accountPrefInt= inputData.


                //inputData.taxCategory= inputData.
                //inputData.withHoldingTaxLevel= inputData.
                // inputData.withholdingTaxBorneBy= inputData.
                if (data.ETINBLOCK !== undefined && data.ETINBLOCK.referenceNum!==undefined && (data.ETINBLOCK.referenceNum.trim()).length==12) {
                    inputData.withholdingTaxPcnt = 10
                } else {
                    inputData.withholdingTaxPcnt = 15
                }
                // inputData.valueDate = inputData.fdValueDate;
                // inputData.depositinstallAmount = inputData.amount;
                // inputData.depositTenure = inputData.tenureType;
                // inputData.depositPeriodMonthsdays = inputData.tenure;
                // inputData.maturityDate = inputData.fdMaturityDate;
                // inputData.maturityValue= inputData.

                if (inputData.nomineeExistNot === "Y") {
                    this.setState({
                        nomineeExistNot: true
                    })
                }
                inputData.autoRenewal = inputData.autoRenewals;

                if (inputData.autoRenewals === "Yes") {
                    inputData.autoClosure = "No"
                } else if (inputData.autoRenewals === "No") {
                    inputData.autoClosure = "Yes"
                }
                //inputData.sectorCode = inputData.sectorCode
                inputData.sectorCodeApiCall = true;
                // inputData.subSectorCode = inputData.subSectorCode
                //inputData.occupationCode = inputData.occupationCode
                //inputData.freeCode1SpecialTaxagentOutlet= inputData.
                //inputData.rmCode = inputData.rmCode
                inputData.rmCodeApiCall = "YES";
                //inputData.rmCodeName = inputData.rmCodeName;
                if (inputData.accountType === "Monthly Interest Paying FD") {
                    inputData.freeCode6 = 8
                } else {
                    inputData.freeCode6 = 4
                }

                // inputData.freeCode7DepositCode = inputData.depositType
                inputData.flowCode = "TO";
                inputData.amtType = "F";
                // inputData.amt= inputData.
                //inputData.particulars= inputData.
                inputData.tranCreationDuring = "V";
                inputData.transactionType = "TRANSFER";
                inputData.debitAccountId = inputData.debitAccountNumber;
                if (data.ETINBLOCK !== undefined) {
                    inputData.etinNumber = data.ETINBLOCK.referenceNum;
                    inputData.etin = data.ETINBLOCK.referenceNum
                }

            }
            else if (accountRelated === 'DPS Account Opening -with Existing Operative Account') {
                inputData.dpscifId=this.props.cbNumber;
                if(data.currency!==undefined  && data.currency!==""){
                    inputData.dpsccy=this.findByVarNameGetKeyValue(data.currency, this.state.currency)
                }
                if(data.customerName!==undefined){
                    inputData.dpsaccountName=data.customerName;
                    inputData.customerName=data.customerName;
                 }
                if(data.shortName!==undefined){
                    inputData.shortName= data.shortName;
                }

                inputData.nomineeName = "SAME AS " + inputData.debitAccountNumber;
                if (inputData.buttonCount === undefined || inputData.buttonCount === null) {
                    inputData.buttonCount = 0;
                    let buttonData = [];

                    if (this.props.freeFlag1 !== 'FDR Account Opening - with Existing Operative Account' && this.props.freeFlag1 !== 'DPS Account Opening -with Existing Operative Account' && this.props.freeFlag2 === "withKyc") {
                        buttonData.push("updateCbInfo");
                        inputData.buttonFlow = buttonData
                    }
                    buttonData.push("updateAccountInfo");
                    inputData.buttonFlow = buttonData;
                    if (this.props.freeFlag1 !== 'FDR Account Opening - with Existing Operative Account' && this.props.freeFlag1 !== 'DPS Account Opening -with Existing Operative Account' && this.props.freeFlag2 === "withKyc") {
                        buttonData.push("updateKycInfo");
                        inputData.buttonFlow = buttonData
                    }
                    buttonData.push("finalApproval");
                    inputData.buttonFlow = buttonData
                    /*  this.setState({
                          inputData:this.emptyValueRemove(inputData),
                       })
                       this.state.inputData=this.emptyValueRemove(inputData);
                       this.forceUpdate();*/
                }
                /*if (inputData.currency !== undefined && inputData.currency.key !== undefined) {
                    inputData.dpsccy = inputData.currency
                } else {
                    inputData.dpsccy = this.findByVarNameGetKeyValue(inputData.currency, this.state.currency)
                }*/
                inputData.taxCategory = {
                    "masterDataName": "taxCategory",
                    "key": "W",
                    "value": "W-A/c.Level Withholding Tax"
                };

                inputData.withHoldingTaxLevel = {
                    "masterDataName": "withHoldingTaxLevel",
                    "key": "A",
                    "value": "A-A/c.Level"
                };
                inputData.withholdingTaxBorneBy = {
                    "masterDataName": "withholdingTaxBorneBy",
                    "key": "P",
                    "value": "Principal A/c.Holder Only"
                };
                inputData.dpspaymentAccountId = inputData.debitAccountNumber;
                inputData.dpspaymentAccountName = inputData.debitAccountName;
                inputData.dpspaymentAccountIdApiCall = "YES";
                //Extra
                /*  inputData.dpscifId = inputData.cbNumber;*/
                //inputData.schemeCode = inputData.dpsSchemeCode
                console.log(this.state.currency);

                //  inputData.ccy = this.findByVarNameGetKeyValue(inputData.currency, this.state.currency)
                //Extra
                /*   inputData.dpsaccountName = inputData.customerName;
                   inputData.dpsshortName = data.shortName;*/
                inputData.dpsaccountOpeningDate = inputData.fdValueDate;
                //inputData.taxCategory =//dropdown
                // inputData.withHoldingTaxLabel =//dropdown
                //inputData.withHoldingTaxBorneBy = //dropdown
                if (data.ETINBLOCK !== undefined && data.ETINBLOCK.referenceNum!==undefined && (data.ETINBLOCK.referenceNum.trim()).length==12) {
                    inputData.withholdingTaxPcnt = 10
                } else {
                    inputData.withholdingTaxPcnt = 15
                }
                if (inputData.dpsnomineeExistNot === "Y") {
                    this.setState({
                        dpsnomineeExistNot: true
                    })
                }
                // inputData.valueDate = inputData.fdValueDate;
                // inputData.depositAmount = inputData.amount
                //  inputData.depositTenure = inputData.tenureType;
                //  inputData.depositPeriodMonthsdays = inputData.tenure;
                //  inputData.maturityDate = inputData.fdMaturityDate
                //inputData.maturityValue =    //input field text
                // inputData.paymentAccountId = inputData.debitAccountNumber  //repayment account id
                //  inputData.sectorCode = inputData.sectorCode
                inputData.sectorCodeApiCall = true;
                //  inputData.subSectorCode = inputData.subSectorCode
                //  inputData.occupationCode = inputData.occupationCode
                //inputData.freeCode1SpecialTaxagentOutlet= inputData.
                //  inputData.rmCode = inputData.rmCode
                inputData.rmCodeApiCall = "YES";
                //  inputData.rmCodeName = inputData.rmCodeName;
                if (inputData.dpsaccountType === "Monthly Interest Paying FD") {
                    inputData.dpsfreeCode6 = 8
                } else {
                    inputData.dpsfreeCode6 = 4
                }

                //  inputData.rmCodeName = inputData.rmCodeName
                //  inputData.freeCode7DepositCode = inputData.depositType
                if (data.ETINBLOCK !== undefined) {
                    inputData.dpsetinNumber = data.ETINBLOCK.referenceNum;
                    inputData.dpsetin = data.ETINBLOCK.referenceNum
                }
                //inputData.siSrlNo =   //input field
                //inputData.standingInstructionsFrequency =   //dropdown field===text
                //inputData.nextExecutionDate =   //date input field
            }
            if (accountRelated === "accountRelated") {

                inputData.misagentBankingOutletFreeCode1 = this.findByVarNameGetKeyValue(data.agentbankingoutlet, this.state.agentBankingOutletList); //acco


                inputData.fincoreDataUpdatecashLimitDr = data.drcashlim;
                inputData.functionCode = "ADD";
                inputData.customerType = "Individual";
                inputData.fincoreDataUpdatecashLimitCr = data.crcashlim;
                inputData.fincoreDataUpdateclearingLimitDr = data.drclrlim;
                inputData.fincoreDataUpdateclearingLimitCr = data.crclrlim;
                inputData.fincoreDataUpdatetransferLimitDr = data.drtxlim;
                inputData.fincoreDataUpdatetransferLimitCr = data.crtxlim;
                inputData.fincoreDataUpdatenotes = data.notes;
                inputData.miscustomerIdCifId = data.cbNumber;
                inputData.misaCNumber = data.accountNumber;
                inputData.accountOrReference = data.accountNumber;


                // inputData.fincoreDataUpdateaccountStatement=data. ;
                // inputData.fincoreDataUpdatestatementFrequency=data. ;
                inputData.fincoreDataUpdatedispatchMode = data.despatchmode;
                inputData.fincoreDataUpdatenextPrintDate = data.acctStmtNxtPrintDt;
                // inputData.fincoreDataUpdateaCStatus=data. ;
                inputData.fincoreDataUpdatedormantActivationCharge = data.dormchrg;
                // inputData.fincoreDataUpdatenomineeSelect=data. ;
                // inputData.fincoreDataUpdateaCInformation=data. ;
                inputData.fincoreDataUpdatesectorCode = this.findByVarNameGetKeyValue(data.sectorcode, this.state.sectorCodeList);
                inputData.fincoreDataUpdatesubSectorCode = this.findByVarNameGetKeyValue(data.subsectorcode, this.state.subsectorCodeList);

                inputData.sectorCodeApicall = true;
                inputData.fincoreDataUpdateoccupationCode = data.occupationcode;
                inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3 = data.rmcode;
                //inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = false
                // inputData.fincoreDataUpdatefreeText3=data. ;
                // inputData.fincoreDataUpdatefreeCode6=data. ;
                inputData.fincoreDataUpdatedepositCodeFreeCode7 = this.findByVarNameGetKeyValue(data.depositcode, this.state.depositCodeList);
                // inputData.fincoreDataUpdatefreeCode8=data. ;
                inputData.miswaiverFieldFreeCode10 = this.findByVarNameGetKeyValue(data.waiverfield, this.state.waiverFieldList);

                inputData.misccepCodeFreeCode9 = this.findByVarNameGetKeyValue(data.ccepcode, this.state.ccepCodeList);
                inputData.aof1smsAlertFreeText11 = data.smsalert;
                inputData.fincoreDataUpdatenotes = data.notes;
                inputData.schemeCodeChangemodeOfOperation = this.findByVarNameGetKeyValue(data.modeOfOper, this.state.despatchmodeList);
                //kyc
                inputData.customerAccount = data.accountNumber;

                inputData.aCTitle = data.accountName;

                inputData.accountType = data.accountNumber;
                inputData.accountOrReference = data.accountNumber;
                inputData.TpCustomerAccount = data.accountNumber;
                inputData.TpAccountName = data.accountName;
                if (data.schemeType === "SBA") {
                    inputData.accountType = "SAVINGS";
                    inputData.TpAccountType = "SAVINGS";
                    this.findByVarNameApiData("schemeCodeChangetargetSchemeCodeInCaseOfInstaPack", this.state.savingsSchemeCodeList)

                } else {
                    inputData.accountType = "CURRENTS";
                    inputData.TpAccountType = "CURRENTS";
                    this.findByVarNameApiData("schemeCodeChangetargetSchemeCodeInCaseOfInstaPack", this.state.currentSchemeCodeList)
                }

                inputData.TpAccountNumber = data.accountNumber;
                if (data.acctStmtMode === "S") {
                    inputData.fincoreDataUpdateaccountStatement = "S-STATEMENT";

                } else {
                    inputData.fincoreDataUpdateaccountStatement = "NONE"

                }

                inputData.aof1despatchModePrintedEStatementBoth = this.findByVarNameGetKeyValue(data.despatchmode, this.state.despatchList)
                /*   if (data.despatchmode==="E") {
                      inputData.aof1despatchModePrintedEStatementBoth = {
                          "masterDataName": "dispatchMode",
                          "key": "Email only",
                          "value": "Email only"
                      }

                  } else if (getInputData.statementFacility === "E-Statement") {


                  }
              else if (getInputData.statementFacility === "NO") {
                      inputData.aof1despatchModePrintedEStatementBoth = {
                          "masterDataName": "dispatchMode",
                          "key": "No Despatch",
                          "value": "No Despatch"
                      }

                  }*/

            }
            else if (accountRelated === "waiverType") {
                inputData.miswaiverTypeForChequeBookWaiver = this.findByVarNameGetKeyValue(data, this.state.chequebookWaiverType)

            }
            else if (accountRelated === "cbExtraInformation") {
                inputData.primryintroducerCustomerIdCifId = data.intrd_cif_id;
                inputData.introducercifType = data.intrd_cif_type;
                inputData.misbbkSegmentCodeFreeCode7 = this.findByVarNameGetKeyValue(data.freecode7, this.state.getfreecode7);              //

                inputData.mispriorityCenterCodeFreeCode1 = data.freecode1;
                inputData.mispriorityMonitoringRmCodeFreeCode3 = data.freecode3;
                inputData.misprimaryCbHolderForPriorityFreeText8 = data.free_text_8;
                inputData.misrelationshipWithPrimaryCbHolderFreeText9 = data.free_text_9;
                inputData.mismigratedNidVerificationFreeText2 = data.free_text_2;
                inputData.mismigratedSmartCardNumberFreeText5 = data.free_text_5;
                inputData.mispepStatusFreeText10 = data.free_text_11;
                inputData.mismigratedCifsDocumentNoFreeText13 = data.free_text_13;
                inputData.misavailedTradeServices = this.findByVarNameGetKeyValue(data.trade_services_availed, this.state.tradeServiceList);                   //
                inputData.primryintroducerCustomerIdCifId = data.intrd_cif_id;
                inputData.primryicifTypePrimaryIntroducerDetails = data.cif_type;
                inputData.chairmanCertificateplaceOfBirthFreeText14 = data.free_text_14;
                inputData.chairmanCertificatecountryOfBirthFreeText15 = data.free_text_15;
                inputData.employerDatamonthlyIncomeGrossIncome = data.gross_income;
                inputData.chairmanCertificatenonResident = this.findByVarNameGetKeyValue(data.customer_nre_flg, this.state.getnonResident);  //
                inputData.chairmanCertificateturnedNonResidentOn = data.date_of_becoming_nre
            }
            else if (accountRelated === "FDR Account Opening - with Existing CB Only" || accountRelated === "FDR Account Opening -Without Operative Account-NTB") {
                inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = false;
                inputData.passportNumberpassportNumberUniqueIdApiCall = true;
                inputData.nationalIdCardNumberUniqueIdApiCall = true;
                inputData.birthCertificatebirthCertificateNumberUniqueIdApiCall = true;
                inputData.electronicTaxIdApiCall = true;
                inputData.misoccupationCodePriorityCodeStaff = data.occupationcode;

                inputData.misstaffIdNumber = data.staffEmployeeID;
                inputData.misstaffIndicatorStatus = data.staffFlag;
                inputData.misccepCodeFreeCode9 = this.findByVarNameGetKeyValue(data.ccepcode, this.state.ccepCodeList);
                inputData.misprimarySolId = data.branchCode;
                inputData.basicInformationUpdateprimarySolId = data.branchCode;
                inputData.miscustomerNameLastName = data.customerName;
                inputData.miscustomerNameLastName = data.customerName;
                inputData.misshortName = data.shortName;
                inputData.misaCNumber = data.accountNumber;
                inputData.customerAccount = data.accountNumber;
                inputData.crmMissubSegment = this.findByVarNameGetKeyValue(data.subSegment, this.state.subSegmentList);
                inputData.mistitle = this.findByVarNameGetKeyValue(data.salutation, this.state.titleList);
                inputData.aCTitle = data.salutation;
                inputData.miscustomerStatusNormalStaffPriorityEtc = this.findByVarNameGetKeyValue(data.custStatus, this.state.customerStatusList);
                inputData.misconstitution = this.findByVarNameGetKeyValue(data.constitutionCode, this.state.constitutionList);
                inputData.mispriorityMonitoringRmCodeFreeCode3 = data.monitoringRMCode;
                inputData.misfunctionCodeForChequeBookWaiver = "A";
                inputData.aof1currencyCodeCcy = data.currency;
                inputData.misoccupationCodePriorityCodeStaff = data.occupationCode;
                inputData.aof1smsAlertFreeText11 = this.findByVarNameGetKeyValue(data.currencyCode, this.state.currency);
                inputData.schemeCodeChangefunctionForSchemeChangeInCaseOfInstaPack = "T";
                inputData.primryiintroducerStatus = this.findByVarNameGetKeyValue(data.statusOfIntroducer, this.state.introducerStatus);
                inputData.primryiintroducerNameLastName = data.nameOfIntroducer;
                inputData.basicInformationUpdategender = data.gender;
                inputData.basicInformationUpdatefatherNamePrefferedName = data.fatherName;
                inputData.basicInformationUpdatemotherNameMothersMaidenName = data.motherName;
                inputData.basicInformationUpdatemotherNameMothersMaidenName = data.motherName;
                inputData.basicInformationUpdatemaritalStatus = this.findByVarNameGetKeyValue(data.maritalStatus, this.state.maritalStatusList);
                inputData.basicInformationUpdatespouseNamePurgeRemarks = data.spouseName;
                let birthDate = data.birthDate;
                if (birthDate && birthDate !== -1) {
                    let date = (birthDate).split('T')[0].split("-");
                    inputData.basicInformationUpdatedob = `${date[1]}/${date[2]}/${date[0]}`;
                    setTimeout(() => {
                        let x = 18;
                        var currentDate = new Date();

                        let someDate = new Date();
                        let numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        let date = someDate.getDate();
                        let month = someDate.getMonth() + 1;
                        let year = someDate.getFullYear();
                        let convertDate = year + "-" + month + "-" + date;
                        let newDate = new Date(convertDate);
                        let stringToDate = new Date(newDate.toDateString());

                        let inputDate = new Date(birthDate);

                        if (stringToDate >= inputDate) {
                            inputData.basicInformationUpdatedobApiCall = "YES";
                            inputData.basicInformationUpdateminorStatusMinorIndicator = "N"

                            //varValue.basicInformationUpdateminorStatusMinorIndicator = "Y"
                        } else {
                            inputData.basicInformationUpdatedobApiCall = "YES";
                            inputData.basicInformationUpdateminorStatusMinorIndicator = "Y"
                            //this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
                        }
                        x = 60;
                        currentDate = new Date();
                        someDate = new Date();
                        numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        date = someDate.getDate();
                        month = someDate.getMonth() + 1;
                        year = someDate.getFullYear();
                        convertDate = year + "-" + month + "-" + date;
                        newDate = new Date(convertDate);
                        stringToDate = new Date(newDate.toDateString());

                        inputDate = new Date(birthDate);
                        if (stringToDate >= inputDate) {
                            inputData.basicInformationUpdateseniorCitizenIndicator = "Y"


                        } else {

                            inputData.basicInformationUpdateseniorCitizenIndicator = "N"
                        }
                    }, 500)
                }
                inputData.basicInformationUpdateminorStatusMinorIndicator = data.isMinor;
                inputData.basicInformationUpdateseniorCitizenIndicator = data.seniorCitizen;
                inputData.fincoreDataUpdatefreeText10 = data.waiverfield;


                inputData.introducerIdApiCall = true;
                inputData.drivingLicensedocumentTypeN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.drivingLicensedocumentTypeDescriptionN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.residentdocumentTypeN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.residentdocumentTypeDescriptionN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.natioanlIdCarddocumentTypeN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.natioanlIdCarddocumentTypeDescriptionN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.natioanlIdCarddocumentCodeN = {
                    "masterDataName": "documentCode",
                    "key": "NID",
                    "value": "NID CARD IDENTITY"
                };
                //Passport
                inputData.passportNumberdocumentTypeP = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.passportNumberdocumentTypeDescriptionP = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.passportNumberdocumentCodeP = {
                    "masterDataName": "documentCode",
                    "key": "PASSPORT",
                    "value": "PASSPORT IDENTITY"
                };

                inputData.smartIdCarddocumentTypeS = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.smartIdCarddocumentTypeDescriptionS = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.smartIdCarddocumentCodeS = {
                    "masterDataName": "documentCode",
                    "key": "RSNID",
                    "value": "RSNID"
                };

                inputData.birthCertificatedocumentTypeB = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.birthCertificatedocumentTypeDescriptionB = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.birthCertificatedocumentCodeB = {
                    "masterDataName": "documentCode",
                    "key": "BC",
                    "value": "BIRTH CERTIFICATE"
                };
                inputData.chairmanCertificatedocumentTypeCC = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.chairmanCertificatedocumentTypeDescriptionCC = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.chairmanCertificatedocumentCodeCC = {
                    "masterDataName": "documentCode",
                    "key": "CHAC",
                    "value": "CHAIRMAN CERTIFICATE"
                };

                inputData.etindocumentType = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.etindocumentTypeDescription = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.etindocumentCode = {
                    "masterDataName": "documentCode",
                    "key": "ETIN",
                    "value": "TIN CARD IDENTITY"
                };
                inputData.contactNumberEmailpreferedEmailIdType = {
                    "masterDataName": "emailType",
                    "key": "COMMEML",
                    "value": "COMMEML"
                };
                inputData.contactNumberEmailemailType1 = {
                    "masterDataName": "emailType",
                    "key": "COMMEML",
                    "value": "COMMEML"
                };
                inputData.contactNumberEmailphoneNoEmailId4 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "EMAIL",
                    "value": "EMAIL"
                };
                inputData.contactNumberpreferredContactNoType1 = {
                    "masterDataName": "contactType",
                    "key": "COMMPH1",
                    "value": "COMMPH1"
                };
                inputData.contactNumbercontactType1 = {
                    "masterDataName": "contactType",
                    "key": "COMMPH1",
                    "value": "COMMPH1"
                };
                inputData.contactNumberphoneNoEmailId1 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "PHONE",
                    "value": "PHONE"
                };
                inputData.contactNumbercontactType2 = {
                    "masterDataName": "contactType",
                    "key": "COMMPH2",
                    "value": "COMMPH2"
                };
                inputData.contactNumberphoneNoEmailId2 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "PHONE",
                    "value": "PHONE"
                };
                inputData.contactNumbercontactType3 = {
                    "masterDataName": "contactType",
                    "key": "WORKPH1",
                    "value": "WORKPH1"
                };
                inputData.contactNumberphoneNoEmailId3 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "PHONE",
                    "value": "PHONE"
                };
                //inputData.contactNumberphoneNo3=data. ;

                inputData.contactNumberEmailemailType = {
                    "masterDataName": "emailType",
                    "key": "HOMEEML",
                    "value": "HOMEEML"
                };
                inputData.contactNumberEmailphoneNoEmailId = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "EMAIL",
                    "value": "EMAIL"
                };
                /*  inputData.employerDataprofessionEmploymentType = {
                      "masterDataName": "employmentType",
                      "key": "MEDIA CELEBRITY",
                      "value": "MEDIA CELEBRITY"
                  };
                  inputData.employerDataemploymentType = {
                      "masterDataName": "employmentType",
                      "key": "MEDIA CELEBRITY",
                      "value": "MEDIA CELEBRITY"
                  };*/
                inputData.maillingAdresspreferredAddressType = {
                    "masterDataName": "addressType",
                    "key": "Mailing",
                    "value": "Mailing"
                };
                inputData.maillingAdressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };
                inputData.maillingAdressaddrssType = {
                    "masterDataName": "addressType",
                    "key": "Mailing",
                    "value": "Mailing"
                };
                inputData.maillingAdressaddrssType = {
                    "masterDataName": "addressType",
                    "key": "Mailing",
                    "value": "Mailing"
                };
                inputData.homeAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };
                inputData.homeAddressaddrssType = {
                    "masterDataName": "addressType",
                    "key": "Home",
                    "value": "Home"
                };
                inputData.homeAddressaddrssType = {
                    "masterDataName": "addressType",
                    "key": "Home",
                    "value": "Home"
                };
                inputData.workAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };
                inputData.nreAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };
                inputData.nreAddressaddrssType =
                    {
                        "masterDataName": "addressType",
                        "key": "NRERelative",
                        "value": "NRERelative"
                    };
                inputData.workAddressaddressType = {
                    "masterDataName": "addressType",
                    "key": "Work",
                    "value": "Work"
                };
                inputData.workAddressaddressType = {
                    "masterDataName": "addressType",
                    "key": "Work",
                    "value": "Work"
                };
                inputData.presentAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };

                inputData.presentAddressaddrssType = {
                    "masterDataName": "addressType",
                    "key": "Future/OnSite",
                    "value": "Future/OnSite"
                };
                inputData.Customersnationality = this.findByVarNameGetKeyValue(data.nationality, this.state.country);
                if (data.NIDBLOCK !== undefined) {
                    inputData.nationalIdCardNumberUniqueId = data.NIDBLOCK.referenceNum;
                    inputData.nationalIdCardNo = data.NIDBLOCK.referenceNum;
                    inputData.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = data.NIDBLOCK.isDocumentVerified;
                    inputData.nationalIdCardNo = data.NIDBLOCK.referenceNum;
                    let natioanlIdCardissueDateN = data.NIDBLOCK.issueDt;
                    if (natioanlIdCardissueDateN && natioanlIdCardissueDateN !== -1) {
                        let date = (natioanlIdCardissueDateN).split('T')[0].split("-");
                        inputData.natioanlIdCardissueDateN = `${date[1]}/${date[2]}/${date[0]}`
                    }
                }
                if (data.RSNIDBLOCK !== undefined) {

                    inputData.smartCardNumberUniqueId = data.RSNIDBLOCK.referenceNum;
                    let smartCardissueDateN = data.RSNIDBLOCK.issueDt;
                    if (smartCardissueDateN && smartCardissueDateN !== -1) {
                        let date = (smartCardissueDateN).split('T')[0].split("-");
                        inputData.smartIdCardissueDateS = `${date[1]}/${date[2]}/${date[0]}`
                    }


                }
                inputData.residentdocumentCodeN = {
                    "masterDataName": "documentCode",
                    "key": "RESID",
                    "value": "RESIDENT ID CARD"
                };

                inputData.drivingLicensedocumentCodeN = {
                    "masterDataName": "documentCode",
                    "key": "DL",
                    "value": "DRIVING LICENSE"

                };

                if (data.RESIDBLOCK !== undefined) {
                    inputData.residentNumberUniqueId = data.RESIDBLOCK.referenceNum;
                }
                if (data.DLBLOCK !== undefined) {
                    inputData.drivingLicenseNumberUniqueId = data.DLBLOCK.referenceNum;
                }
                if (data.FutureOnSiteBLOCK !== undefined) {

                    inputData.presentAddresspresentAddressField1 = data.FutureOnSiteBLOCK.addrLine1;
                    inputData.presentAddresspresentAddressField2 = data.FutureOnSiteBLOCK.addrLine2;
                    inputData.presentAddresscity = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.city, this.state.city);
                    inputData.presentAddressstate = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.state, this.state.stateCode);
                    inputData.presentAddresspostalCode = data.FutureOnSiteBLOCK.postalCode;
                    inputData.presentAddresscountry = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.country, this.state.country);
                }
                if (data.NRERelativeBLOCK !== undefined) {

                    inputData.nreAddressnreAddressField1 = data.NRERelativeBLOCK.addrLine1;
                    inputData.nreAddressnreAddressField2 = data.NRERelativeBLOCK.addrLine2;
                    inputData.nreAddresscity = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.city, this.state.city);
                    inputData.nreAddressstate = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.state, this.state.stateCode);
                    inputData.nreAddresspostalCode = data.NRERelativeBLOCK.postalCode;
                    inputData.nreAddresscountry = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.country, this.state.country);
                }

                if (data.PASSPORTBLOCK !== undefined) {

                    inputData.passportNumberpassportNumberUniqueId = data.PASSPORTBLOCK.referenceNum;
                    inputData.passportNo = data.PASSPORTBLOCK.referenceNum;
                    inputData.passportNo = data.PASSPORTBLOCK.referenceNum;
                    inputData.passportNumberplaceOfIssue = this.findByVarNameGetKeyValue(data.PASSPORTBLOCK.placeOfIssue, this.state.placeOfIssueList);
                    let passportNumberissueDate = data.PASSPORTBLOCK.issueDt;
                    if (passportNumberissueDate && passportNumberissueDate !== -1) {
                        let date = (passportNumberissueDate).split('T')[0].split("-");
                        inputData.passportNumberissueDate = `${date[1]}/${date[2]}/${date[0]}`
                    }
                    inputData.passportNumbercountryOfIssue = this.findByVarNameGetKeyValue(data.PASSPORTBLOCK.countryOfIssue, this.state.country);
                }


                //inputData.passportNumberexpiryDate=data. ;
                if (data.BCBLOCK !== undefined) {

                    inputData.birthCertificatebirthCertificateNumberUniqueId = data.BCBLOCK.referenceNum;
                    inputData.birthCertificateNo = data.BCBLOCK.referenceNum;
                    inputData.birthCertificateNo = data.BCBLOCK.referenceNum;

                }
                if (data.chairmanCertificate !== undefined) {

                    inputData.chairmanCertificateUniqueId = data.chairmanCertificate;
                }
                // inputData.chairmanCertificateplaceOfBirthFreeText14=data. ;
                //inputData.chairmanCertificatecountryOfBirthFreeText15=data. ;
                //  inputData.chairmanCertificatenationality=data. ;
                // inputData.chairmanCertificatenonResident=data. ;
                // inputData.chairmanCertificateturnedNonResidentOn=data. ;
                if (data.ETINBLOCK !== undefined) {

                    inputData.etineTinNumber = data.ETINBLOCK.referenceNum;
                    inputData.electronicTaxId = data.ETINBLOCK.referenceNum;


                }

                if (data.nameOfEmployer !== undefined) {
                    inputData.employerDataprofessionEmploymentType = this.findByVarNameGetKeyValue(data.employmentStatus, this.state.employmentTypeList);

                    inputData.employerDatanameOfEmployerEmployerName = data.nameOfEmployer;
                }
                if (data.COMMEMLBLOCK !== undefined) {
                    //inputData.employerDatamonthlyIncomeGrossIncome=data. ;

                    inputData.contactNumberEmaildesignatedEmailAddressEmailId1 = data.COMMEMLBLOCK.emailInfo
                    /* if(data.COMMEMLBLOCK.phoneNumCountryCode==="88"){
                         inputData.mobileTypecontactNumberphoneNo2 ="88";
                     }
                     else{
                         inputData.mobileTypecontactNumberphoneNo2 ="0";
                     }*/
                }
                if (data.COMMPH1BLOCK !== undefined) {

                    inputData.contactNumberphoneNo1 = data.COMMPH1BLOCK.phoneNumLocalCode;
                    if (data.COMMPH1BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypecontactNumberphoneNo1 = "Local";
                    } else {
                        inputData.mobileTypecontactNumberphoneNo1 = "Overseas";
                        inputData.countryCodecontactNumberphoneNo1 = this.findByVarNameGetKeyValue(data.COMMPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)
                    }
                }
                if (data.WORKPH1BLOCK !== undefined) {

                    inputData.contactNumberphoneNo3 = data.WORKPH1BLOCK.phoneNumLocalCode;
                    if (data.WORKPH1BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypecontactNumbercontactType3 = "Local";
                    } else {
                        inputData.mobileTypecontactNumbercontactType3 = "Overseas";
                        inputData.countryCodecontactNumberphoneNo3 = this.findByVarNameGetKeyValue(data.WORKPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)

                    }
                }
                if (data.COMMPH2BLOCK !== undefined) {

                    inputData.contactNumberphoneNo2 = data.COMMPH2BLOCK.phoneNumLocalCode;

                    if (data.COMMPH2BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypecontactNumberphoneNo2 = "Local";
                    } else {
                        inputData.mobileTypecontactNumberphoneNo2 = "Overseas";
                        inputData.countryCodecontactNumberphoneNo2 = this.findByVarNameGetKeyValue(data.COMMPH2BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)

                    }
                }
                if (data.HOMEEMLBLOCK !== undefined) {


                    inputData.contactNumberEmailemailId2 = data.HOMEEMLBLOCK.emailInfo;
                }
                if (data.MailingBLOCK !== undefined) {


                    inputData.maillingAdressmailingAddressField1 = data.MailingBLOCK.addrLine1;
                    inputData.maillingAdressmailingAddressField2 = data.MailingBLOCK.addrLine2;
                    inputData.maillingAdresscity = this.findByVarNameGetKeyValue(data.MailingBLOCK.city, this.state.city);
                    inputData.maillingAdressstate = this.findByVarNameGetKeyValue(data.MailingBLOCK.state, this.state.stateCode);
                    inputData.maillingAdresspostalCode = data.MailingBLOCK.postalCode;
                    inputData.maillingAdresscountry = this.findByVarNameGetKeyValue(data.MailingBLOCK.country, this.state.country);

                }
                if (data.HomeBLOCK !== undefined) {

                    inputData.homeAddresshomeAddressField1 = data.HomeBLOCK.addrLine1;
                    inputData.homeAddresshomeAddressField2 = data.HomeBLOCK.addrLine2;
                    inputData.homeAddresscity = this.findByVarNameGetKeyValue(data.HomeBLOCK.city, this.state.city);

                    inputData.homeAddressstate = this.findByVarNameGetKeyValue(data.HomeBLOCK.state, this.state.stateCode);
                    inputData.homeAddresspostalCode = data.HomeBLOCK.postalCode;
                    inputData.homeAddresscountry = this.findByVarNameGetKeyValue(data.HomeBLOCK.country, this.state.country);
                }
                if (data.WorkBLOCK !== undefined) {

                    inputData.workAddressworkAddressField1 = data.WorkBLOCK.addrLine1;
                    inputData.workAddressworkAddressField2 = data.WorkBLOCK.addrLine2;
                    inputData.workAddresscity = this.findByVarNameGetKeyValue(data.WorkBLOCK.city, this.state.city);
                    inputData.workAddressstate = this.findByVarNameGetKeyValue(data.WorkBLOCK.state, this.state.stateCode);
                    inputData.workAddresspostalCode = data.WorkBLOCK.postalCode;
                    inputData.workAddresscountry = this.findByVarNameGetKeyValue(data.WorkBLOCK.countryCode, this.state.country);

                }

            }
            if (inputData.nextExecutionDate !== null && inputData.nextExecutionDate !== undefined) {
                let date = new Date(inputData.nextExecutionDate);
                var str = inputData.nextExecutionDate;
                var n = str.search("/");
                if (n !== -1) {
                    let nextExecutionDateOnly = date.toDateString();
                    if (nextExecutionDateOnly !== "Invalid Date") {
                        nextExecutionDateOnly = (inputData.nextExecutionDate).split("/")[1];
                        if (nextExecutionDateOnly.length == 2) {
                            if (nextExecutionDateOnly[0] === 0) {
                                nextExecutionDateOnly = nextExecutionDateOnly[1];
                            } else {
                                nextExecutionDateOnly = nextExecutionDateOnly;
                            }

                        } else {
                            nextExecutionDateOnly = nextExecutionDateOnly;
                        }
                        inputData.fincoreDataUpdatestatementFrequency2 = nextExecutionDateOnly;
                    }


                } else {
                    str = inputData.nextExecutionDate;
                    n = str.search("T");
                    if (n !== -1) {
                        let nextExecutionDateOnly = date.toDateString();
                        if (nextExecutionDateOnly !== "Invalid Date") {
                            nextExecutionDateOnly = ((inputData.nextExecutionDate).split("T")[0]).split("-")[2];
                            str = ((inputData.nextExecutionDate).split("T")[0]);
                            n = str.search("-");
                            if (n !== -1) {
                                if (nextExecutionDateOnly.length == 2) {
                                    if (nextExecutionDateOnly[0] === 0) {
                                        nextExecutionDateOnly = nextExecutionDateOnly[1];
                                    } else {
                                        nextExecutionDateOnly = nextExecutionDateOnly;
                                    }

                                } else {
                                    nextExecutionDateOnly = nextExecutionDateOnly;
                                }
                                inputData.fincoreDataUpdatestatementFrequency2 = nextExecutionDateOnly;
                            }


                        }

                    }

                }

            }
        }


        return inputData;
    };

    renderDownLoadFileLink = () => {
        if (this.state.getData && this.state.getDownloadListFlag) {
            return (this.state.downLoadFileList.map((button) => {
                    return button
                })
            )

        } else {
            return <h5><b>{this.state.fileNotFoundTittle}</b></h5>
        }
    };
    renderSignatureAndDebitAccount = () => {
        if (this.state.getData) {
            return <a className="btn btn-sm btn-danger" style={{"color": "#ffffff"}}
                      onClick={() => this.openSignature(this.state.inputData.debitAccountNumber)}> Signature Card View</a>
        }
    };

    openSignature = (accountNumber) => {
        let url = frontEndServerURL + "/signatureCardView/" + accountNumber;
        window.open(url)
    };


    downloadDocument = (name) => {
        let url = backEndServerURL + "/file/" + name + "/" + sessionStorage.getItem("accessToken");
        window.open(url)
    };

    createDownloadFileLink = (sl, data) => {
        return (
            <React.Fragment>
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}
                    onClick={() => this.downloadDocument(data)}
                >
                    {sl}
                </button>
                &nbsp;&nbsp;
            </React.Fragment>

        )
    };

    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "Finacle System Error!"
                }
            } else {
                return "Finacle System Error!"
            }
        } else {
            return "Finacle System Error!"
        }
    };

    variableSetFunction = (object) => {
        console.log(object);
        let variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    inputData: this.emptyValueRemove(object),
                    varValue: this.emptyValueRemove(object),

                });
                this.forceUpdate()
            })
            .catch((error) => {
                console.log(error)
            })

    };

    componentDidMount() {
        let downLoadFileList = [];
        let url = backEndServerURL + "/case/files/pdfFilesFromImages/" + this.props.appId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((data, index) => {
                    downLoadFileList.push(this.createDownloadFileLink(`Document ${index + 1}`, data))
                });
                console.log(response.data);
                this.setState({
                    getDownloadListFlag: true,
                    downLoadFileList: downLoadFileList
                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    fileNotFoundTittle: this.errorObjectCheck(error),
                })
            });
        {
            this.dynamicApiCall("customersOccupation", "customerOccupation")
        }
        if (this.props.titleName === "DPS Account Opening -with Existing Operative Account") {
            let object = {
                //accountAcquisition: "Branch/Citygem-Retail",
                //currency: "BDT",
                nameOfScheme: "Goal Based DPS",
                //natureAccount: "Individual-Retail",
                tenureType: "Month",
            };

            let url = backEndServerURL + "/FDRDPSSchemeCode/getDPSTenure";
            axios.post(url, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    let jsonArrayServiceList = this.props.commonJsonForm;
                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "dpsdepositPeriodMonthsdays") {
                            /* this.state.inputData.depositPeriodMonthsdays.enum = [];
                             this.state.varValue.depositPeriodMonthsdays.enum = [];
                             this.forceUpdate()*/
                            this.props.commonJsonForm[i].enum = response.data;
                            this.forceUpdate();
                            console.log(this.props.commonJsonForm[i])
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);

                })

        }
        if (this.props.titleName === "FDR Account Opening - with Existing CB Only" || this.props.titleName === "FDR Account Opening -Without Operative Account-NTB") {

            {
                this.dynamicApiCallDocumentType("documentCodeDocumentType", "documentCodeDocumentType")
            }
            {
                this.dynamicApiCallDocumentType("documentCodeDocumentTypeDesc", "documentCodeDocumentTypeDesc")
            }
            {
                this.dynamicApiCall("city2", "city")
            }
            {
                this.dynamicApiCall("placeOfIssue", "placeOfIssue")
            }
            {
                this.dynamicApiCall("state2", "state",)
            }
            {
                this.dynamicApiCall("currency", "currency")
            }
            {
                this.dynamicApiCall("nationality", "country")
            }
            {
                this.dynamicApiCall("documentsCollectedToEnsureTheSourceOfFund", "sourceOfFund")
            }
            {
                this.dynamicApiCall("customersOccupation", "customerOccupation")
            }
            {
                this.dynamicApiCall("addressVariety", "addressVariety")
            }
            {
                this.dynamicApiCall("whatDoesTheCustomerDoInWhatTypeOfBusineessIsTheCustomerEngaged", "customerBusiness")
            }
            {
                this.dynamicApiCall("monthlyIncome", "monthlyIncome")
            }
            {
                this.dynamicApiCall("howWasAccountOpened", "accountOpen")
            }
            {
                this.dynamicApiCall("constitution", "constitution")
            }
            {
                this.dynamicApiCall("waiverField", "waiverField")
            }
            {
                this.dynamicApiCall("bankRelationType", "bankRelationType")
            }
            {
                this.dynamicApiCall("nomineeRelationCode", "nomineeRelationCode")
            }
            /* {
                 this.dynamicApiCall("emailType", "emailType")
             }*/
            /*  {
                  this.dynamicApiCall("phoneOrEmailId", "phoneOrEmailId")
              }*/

            {
                this.dynamicApiCall("ccepCode", "ccepCode")
            }
            {
                this.dynamicApiCall("maritalStatus", "maritalStatus")
            }

            /* {
                 this.dynamicApiCall("bankName", "bankName")
             }*/
            {
                this.dynamicApiCall("title", "title")
            }
            {
                this.dynamicApiCall("documentCode", "documentCode")
            }
            {
                this.dynamicApiCall("relationship", "relationship")
            }
            /*  {
                  this.dynamicApiCall("contactType", "contactType")
              }*/
            {
                this.dynamicApiCall("employmentType", "employmentType")
            }
            {
                this.dynamicApiCall("agentBankingOutlet", "agentBankingOutlet")
            }
            {
                this.dynamicApiCall("nonResident", "nonResident")
            }
            {
                this.dynamicApiCall("customerStatus", "customerStatus")
            }
            /*  {
                  this.dynamicApiCall("addressFormat", "addressFormat")
              }*/
            /* {
                 this.dynamicApiCall("branch", "branch")
             }*/
            {
                this.dynamicApiCall("relationCode", "relationCode")
            }
            {
                this.dynamicApiCall("relationType", "relationType")
            }
            {
                this.dynamicApiCall("dispatchMode", "dispatchMode")
            }
            {
                this.dynamicApiCall("statementFrequency", "statementFrequency")
            }
            {
                this.dynamicApiCall("chequebookWaiverFunctionCode", "chequebookWaiverFunctionCode")
            }
            {
                this.dynamicApiCall("chequebookWaiverType", "chequebookWaiverType")
            }
            /*  {
                  this.dynamicApiCall("rmCode", "rmCode")
              }*/
            {
                this.dynamicApiCall("subSegment", "subSegment")
            }

            {
                this.dynamicApiCall("designationCode", "designationCode")
            }
            {
                this.dynamicApiCall("sectorCode", "sectorCode")
            }
            {
                this.dynamicApiCall("subSectorCode", "subSectorCode")
            }
            {
                this.dynamicApiCall("depositCode", "depositCode")
            }
            {
                this.dynamicApiCall("tradeService", "tradeService")
            }
            {
                this.dynamicApiCall("addressType", "addressType")
            }
            {
                this.dynamicApiCall("guardianCode", "guardianCode")
            }
            {
                this.dynamicApiCall("accountStatement", "accountStatement")
            }

            {
                this.dynamicApiCall("modOfOperation", "modOfOperation")
            }
            {
                this.dynamicApiCall("introducerStatus", "introducerStatus")
            }
            {
                this.dynamicApiCall("bbkSegmentCode", "bbkSegmentCode")
            }
            {
                this.dynamicApiCall("priorityCenterCode", "priorityCenterCode")
            }
            {
                this.dynamicApiCall("priorityCenterCode", "priorityCenterCode")
            }
            {
                this.dynamicApiCall("schemeCodeForSavings", "savingsSchemeCode")
            }
            {
                this.dynamicApiCall("schemeCodeForCurrent", "currentSchemeCode")
            }
            {
                this.dynamicApiCall("phoneCountryCode", "phoneCountryCode")
            }

            {
                this.dynamicApiCall("fdrSchemeCode", "fdrSchemeCode")
            }
            {
                this.dynamicApiCall("dpsSchemeCode", "dpsSchemeCode")
            }
            {
                this.dynamicApiCall("customerStatus", "customerStatus")
            }
            {
                this.dynamicApiCall("depositCode", "depositCode")
            }
            let occupationCodeUrl = backEndServerURL + "/occupationCode/getAll";
            axios.get(occupationCodeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log("lllllllllllllllllpppp");
                    console.log(response.data);
                    this.findByVarNameApiData("misoccupationCodePriorityCodeStaff", response.data);
                    this.findByVarNameApiData("fincoreDataUpdateoccupationCode", response.data);
                    this.setState({
                        occupationCodeList: response.data
                    })
                    //this.findByVarNameApiData("customersOccupation", response.data)

                })
                .catch((error) => {
                    console.log(error)
                })

        }
        else {

            {
                this.dynamicApiCallDocumentType("documentCodeDocumentType", "documentCodeDocumentType")
            }
            {
                this.dynamicApiCallDocumentType("documentCodeDocumentTypeDesc", "documentCodeDocumentTypeDesc")
            }
            {
                this.dynamicApiCall("city", "city")
            }
            {
                this.dynamicApiCall("state", "state")
            }
            {
                this.dynamicApiCall("currency", "currency")
            }
            {
                this.dynamicApiCall("nationality", "country")
            }
            {
                this.dynamicApiCall("country", "country")
            }
            {
                this.dynamicApiCall("fdrSchemeCode", "fdrSchemeCode")
            }
            {
                this.dynamicApiCall("dpsSchemeCode", "dpsSchemeCode")
            }
            {
                this.dynamicApiCall("documentsCollectedToEnsureTheSourceOfFund", "sourceOfFund")
            }

            {
                this.dynamicApiCall("documentsCollectedToEnsureTheSourceOfFund", "sourceOfFund")
            }
            {
                this.dynamicApiCall("nomineeRelationCode", "nomineeRelationCode")
            }
            {
                this.dynamicApiCall("customersOccupation", "customerOccupation")
            }
            {
                this.dynamicApiCall("relationCode", "relationCode")
            }
            {
                this.dynamicApiCall("relationType", "relationType")
            }
            {
                this.dynamicApiCall("sectorCode", "sectorCode")
            }
            {
                this.dynamicApiCall("subSectorCode", "subSectorCode")
            }
            {
                this.dynamicApiCall("guardianCode", "guardianCode")
            }
            {
                this.dynamicApiCall("designationCode", "designationCode")
            }
            {
                this.dynamicApiCall("relationCode", "relationCode")
            }
            {
                this.dynamicApiCall("sourceOfFund", "sourceOfFund")
            }
            {
                this.dynamicApiCall("addressVariety", "addressVariety")
            }
            {
                this.dynamicApiCall("depositCode", "depositCode")
            }
            {
                this.dynamicApiCall("agentBankingOutlet", "agentBankingOutlet")
            }
        }

        let occupationCodeUrl = backEndServerURL + "/occupationCode/getAll";
        axios.get(occupationCodeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                console.log(response.data);
                {
                    this.findByVarNameApiData("occupationCode", response.data);
                    this.findByVarNameApiData("dpsoccupationCode", response.data)
                }


            })
            .catch((error) => {
                console.log(error);
            });

        this.setState({
            loading: true,
        });
        var remarksArray = [];
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        FormCasaFlow: response.data.FormCasaFlow
                    });
                    let tableArrayData = [];
                    let object = [];
                    object.push("cbNumber", "relatedPartyType", "relatedCbTaggingrelationCode", "requestCharacter", "person");
                    let cbTagUrl = backEndServerURL + "/secondaryDataCB/" + this.props.appId;
                    axios.post(cbTagUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            response.data.map((data, index) => {
                                tableArrayData.push(this.createTaggingData(data));

                            });
                            this.setState({
                                getTagList: tableArrayData
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    console.log(response.data);
                    let inputData = this.emptyValueRemove(response.data);
                    let varValue = this.emptyValueRemove(response.data);
                    inputData.makerRemarks = undefined;
                    varValue.makerRemarks = undefined;

                    if(inputData.ccy!==undefined && inputData.ccy.key!==undefined){
                        inputData.ccy=inputData.ccy;
                        varValue.ccy=inputData.ccy;
                    }
                    else{
                        inputData.ccy = this.findByVarNameGetKeyValue(inputData.ccy, this.state.currency)
                        varValue.ccy = this.findByVarNameGetKeyValue(inputData.ccy, this.state.currency)
                    }
                    if(inputData.dpsccy!==undefined && inputData.dpsccy.key!==undefined){
                        inputData.dpsccy=inputData.dpsccy;
                        varValue.dpsccy=inputData.dpsccy;
                    }
                    else{
                        inputData.dpsccy = this.findByVarNameGetKeyValue(inputData.dpsccy, this.state.currency)
                        varValue.dpsccy = this.findByVarNameGetKeyValue(inputData.dpsccy, this.state.currency)

                     }

                    if(inputData.repaymentAccountApiCall==="YES" && inputData.repaymentName===undefined){
                        inputData.repaymentName=inputData.customerName;
                        varValue.repaymentName=inputData.customerName;
                    }
                    if(inputData.interestCreditAccountApiCall==="YES" && inputData.interestCreditName===undefined){
                        inputData.interestCreditName=inputData.customerName;
                        varValue.interestCreditName=inputData.customerName;
                    }
                    if(inputData.acName===undefined){
                        inputData.acName=inputData.customerName;
                        varValue.acName=inputData.customerName;
                    }
                    if(inputData.dpsaccountName===undefined){
                        inputData.dpsaccountName=inputData.customerName;
                        varValue.dpsaccountName=inputData.customerName;
                    }
                    if(inputData.dpspaymentAccountIdApiCall==="YES" && inputData.dpspaymentAccountName===undefined){
                        inputData.dpspaymentAccountName=inputData.customerName;
                        varValue.dpspaymentAccountName=inputData.customerName;
                    }
                    let url = backEndServerURL + '/TermDepositAccountAllowedForSD/' + this.props.appId;

                    if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                        url = backEndServerURL + '/DPSAccountAllowedForSD/' + this.props.appId;

                    }

                    //   if (this.props.freeFlag1 === "FDR Account Opening - with Existing Operative Account" || this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            if (response.data === false) {
                                this.state.inputData.allFieldReadOnly = "YES";
                                inputData.allFieldReadOnly = "YES";
                                this.forceUpdate()
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    setTimeout(() => {
                        console.log(inputData);
                        this.state.inputData = inputData;
                        this.state.varValue = varValue;
                        this.forceUpdate()
                    }, 500);

                    /*  if (this.props.titleName !== "DPS Account Opening -with Existing Operative Account") {
                         let object = {};
                         if (inputData.currency !== undefined && inputData.currency.key !== undefined) {
                             object.currency = inputData.currency.key
                         } else {
                             object.currency = inputData.currency
                         }
                         if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                             object.natureAccount = "Individual-Retail";
                         } else {
                             object.natureAccount = "Non-Individual"
                         }
                         object.accountType = inputData.accountType;
                         object.accountAcquisition = inputData.accountAcquisition;
                         object.tenureType = inputData.tenureType;

                         let url = backEndServerURL + "/FDRDPSSchemeCode/getTenor";
                         axios.post(url, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                             .then((response) => {
                                 let jsonArrayServiceList = this.props.commonJsonForm;
                                 for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                     let jsonObjects = jsonArrayServiceList[i];
                                     if (jsonObjects.varName === "depositPeriodMonthsdays") {
                                         /!* this.state.inputData.depositPeriodMonthsdays.enum = [];
                               this.state.varValue.depositPeriodMonthsdays.enum = [];
                               this.forceUpdate()*!/
                                         this.props.commonJsonForm[i].enum = response.data;
                                         this.forceUpdate()
                                         console.log(this.props.commonJsonForm[i])
                                     }
                                 }
                                 jsonArrayServiceList = this.props.commonJsonFormLast;
                                 for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                     let jsonObjects = jsonArrayServiceList[i];
                                     if (jsonObjects.varName === "depositPeriodMonthsdays") {
                                         this.props.commonJsonForm[i].enum = [];
                                         this.props.commonJsonFormLast[i].enum = response.data;
                                         console.log(this.props.commonJsonFormLast[i])
                                     }
                                 }


                             })
                             .catch((error) => {
                                 console.log(error)

                             })
                     }*/
                    setTimeout(() => {
                        this.variableSetFunction(this.state.inputData);
                        let inputDatas = this.state.inputData;
                        let varValues = this.state.varValue;

                        if (this.props.appId !== undefined && ((response.data.checker_approval === undefined && response.data.dataSaved == undefined) || (response.data.submitedFlag === undefined))) {
                            let url = backEndServerURL + "/getSolIdFromAppId/" + this.props.appId;/*"2801187408001"*/
                            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((branchData) => {
                                    let inputDataBranchData = this.apiDataSet(branchData.data, inputDatas, "branchData");
                                    let varValueBranchData = this.apiDataSet(branchData.data, varValues, "branchData");
                                    let getCBUrl2 = backEndServerURL + "/getCustomerCIFDetails/" + this.props.cbNumber;/*"2801187408001"*/
                                    axios.get(getCBUrl2, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((cb2DataResponse) => {
                                            let inputData = this.apiDataSet(cb2DataResponse.data, inputDataBranchData, "cbExtraInformation");
                                            let varValue = this.apiDataSet(cb2DataResponse.data, varValueBranchData, "cbExtraInformation");
                                            setTimeout(() => {
                                                let url = backEndServerURL + "/cbsCustomerGet/" + this.props.cbNumber;
                                                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                    .then((response) => {
                                                        let object = {};

                                                        //inputData.buttonFlow = buttonData;
                                                        object.amount = inputData.amount;


                                                        // object.valueDate = inputData.fdValueDate
                                                        object.valueDate = inputData.fdValueDate;
                                                        // object.valueDate = "10/14/2020"
                                                        //object.maturityDate = "2022-10-14"
                                                        object.maturityDate = inputData.fdMaturityDate;
                                                        console.log(object);
                                                        url = backEndServerURL + "/exciseDuty";
                                                        if (this.props.freeFlag1 !== 'DPS Account Opening -with Existing Operative Account') {
                                                            axios.post(url, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                                .then((exciseDutyData) => {
                                                                    inputData.amt = exciseDutyData.data.amt;
                                                                    inputData.particulars = exciseDutyData.data.particulars;
                                                                    varValue.amt = exciseDutyData.data.amt;
                                                                    varValue.particulars = exciseDutyData.data.particulars;
                                                                    inputData.custStatus = response.data.custStatus;
                                                                    varValue.custStatus = response.data.custStatus;
                                                                    setTimeout(() => {
                                                                        this.setState({
                                                                            inputData: this.emptyValueRemove(this.apiDataSet(response.data, inputData, this.props.freeFlag1, this.state.FormCasaFlow)),
                                                                            varValue: this.emptyValueRemove(this.apiDataSet(response.data, varValue, this.props.freeFlag1, this.state.FormCasaFlow)),
                                                                            showValue: true,
                                                                            tagData: true,
                                                                            getData: true,
                                                                            staticData: true,
                                                                            loading: false

                                                                        });
                                                                    })
                                                                }, 500)
                                                        } else {
                                                            if (this.state.FormCasaFlow === 'YES' && this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                                                                if (inputData.currency !== undefined && inputData.currency.key !== undefined) {
                                                                    inputData.dpsccy = inputData.currency
                                                                } else {
                                                                    inputData.dpsccy = this.findByVarNameGetKeyValue(inputData.currency, this.state.currency)
                                                                }

                                                            } else if (this.props.freeFlag1 !== "DPS Account Opening -with Existing Operative Account") {
                                                                if (inputData.currency !== undefined && inputData.currency.key !== undefined) {
                                                                    inputData.ccy = inputData.currency;
                                                                } else {
                                                                    inputData.ccy = this.findByVarNameGetKeyValue(inputData.currency, this.state.currency);
                                                                }
                                                            } else if (this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account') {
                                                                if (inputData.currency !== undefined && inputData.currency.key !== undefined) {
                                                                    inputData.dpsccy = inputData.currency
                                                                } else {
                                                                    inputData.dpsccy = this.findByVarNameGetKeyValue(inputData.currency, this.state.currency)
                                                                }

                                                            }
                                                            setTimeout(() => {
                                                                this.setState({
                                                                    inputData: this.emptyValueRemove(this.apiDataSet(response.data, inputData, this.props.freeFlag1, this.state.FormCasaFlow)),
                                                                    varValue: this.emptyValueRemove(this.apiDataSet(response.data, varValue, this.props.freeFlag1, this.state.FormCasaFlow)),
                                                                    showValue: true,
                                                                    tagData: true,
                                                                    getData: true,
                                                                    staticData: true,
                                                                    loading: false
                                                                })
                                                            }, 500)
                                                        }

                                                    })
                                                    .catch((error) => {
                                                        console.log(error);
                                                        this.setState({
                                                            inputData: this.emptyValueRemove(inputData),
                                                            varValue: this.emptyValueRemove(varValue),
                                                            showValue: false,
                                                            tagData: true,
                                                            getData: false,
                                                            staticData: true,
                                                            loading: false,
                                                            errorModalBoolean: true,
                                                            errorTittle: this.errorObjectCheck(error),
                                                        });

                                                    })


                                            }, 500)


                                        })
                                        .catch((error) => {
                                            this.setState({
                                                inputData: this.emptyValueRemove(inputDataBranchData),
                                                varValue: this.emptyValueRemove(varValueBranchData),
                                                showValue: false,
                                                tagData: true,
                                                getData: false,
                                                staticData: true,
                                                loading: false,
                                                errorModalBoolean: true,
                                                errorTittle: this.errorObjectCheck(error),
                                            })
                                        })

                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.setState({
                                        inputData: this.emptyValueRemove(inputDatas),
                                        varValue: this.emptyValueRemove(varValues),
                                        showValue: false,
                                        tagData: true,
                                        getData: false,
                                        staticData: true,
                                        loading: false,
                                        errorModalBoolean: true,
                                        errorTittle: this.errorObjectCheck(error),
                                    })
                                })

                        }
                        else {

                            let inputData = this.state.inputData;
                            let varValue = this.state.varValue;
                            inputData.acOpeningDate = inputData.fdValueDate;
                            varValue.acOpeningDate = varValue.fdValueDate;
                            let url = backEndServerURL + "/getSolIdFromAppId/" + this.props.appId;/*"2801187408001"*/
                            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((branchData) => {
                                    inputData.dpssolId = branchData.data;
                                    inputData.solId = branchData.data;
                                    inputData.accountId = branchData.data + "0149148001";
                                    inputData.dpssolId = branchData.data;
                                    inputData.missolId = branchData.data;
                                    varValue.dpssolId = branchData.data;
                                    varValue.solId = branchData.data;
                                    varValue.accountId = branchData.data + "0149148001";
                                    varValue.dpssolId = branchData.data;
                                    varValue.missolId = branchData.data;

                                    if (this.state.FormCasaFlow === 'YES' && this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                                        if (inputData.currency !== undefined && inputData.currency.key !== undefined) {
                                            inputData.dpsccy = inputData.currency
                                        } else {
                                            inputData.dpsccy = this.findByVarNameGetKeyValue(inputData.currency, this.state.currency)
                                        }

                                    } else if (this.props.freeFlag1 !== "DPS Account Opening -with Existing Operative Account") {
                                        if (inputData.currency !== undefined && inputData.currency.key !== undefined) {
                                            inputData.ccy = inputData.currency;
                                        } else {
                                            inputData.ccy = this.findByVarNameGetKeyValue(inputData.currency, this.state.currency);
                                        }
                                    } else if (this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account') {
                                        if (inputData.currency !== undefined && inputData.currency.key !== undefined) {
                                            inputData.dpsccy = inputData.currency
                                        } else {
                                            inputData.dpsccy = this.findByVarNameGetKeyValue(inputData.currency, this.state.currency)
                                        }
                                    }
                                    //if (inputData.maturity === "Renew Principal Only and Credit Interest to") {
                                    inputData.interestCreditAccount = inputData.interestCreditAccount;
                                    inputData.interestCreditName = inputData.interestCreditName;
                                    inputData.interestCreditAccountApiCall = "YES";
                                    inputData.interestCreditAccountVisible = "YES";
                                    varValue.interestCreditAccount = varValue.interestCreditAccount;
                                    varValue.interestCreditName = varValue.interestCreditName;
                                    varValue.interestCreditAccountApiCall = "YES";
                                    varValue.interestCreditAccountVisible = "YES";
                                    // }
                                    /*else {
                                            inputData.interestCreditAccount = "";
                                            inputData.interestCreditName = "";
                                            inputData.interestCreditAccountApiCall = "NO";
                                            inputData.interestCreditAccountVisible = "NO";
                                            varValue.interestCreditAccount = "";
                                            varValue.interestCreditName = "";
                                            varValue.interestCreditAccountApiCall = "NO";
                                            varValue.interestCreditAccountVisible = "NO";
                                        }*/
                                    // if (inputData.maturity === "Encashment at Maturity") {
                                    inputData.repaymentAcId = inputData.repaymentAccount;
                                    inputData.repaymentName = inputData.repaymentName;
                                    inputData.repaymentAccountApiCall = "YES";
                                    inputData.repaymentAcIdVisible = "YES";
                                    varValue.repaymentAcId = inputData.repaymentAccount;
                                    varValue.repaymentName = inputData.repaymentName;
                                    varValue.repaymentAccountApiCall = "YES";
                                    varValue.repaymentAcIdVisible = "YES";
                                    //   }
                                    /*else {
                                            inputData.repaymentAcId = "";
                                            inputData.repaymentName = "";
                                            inputData.repaymentAccountApiCall = "NO";
                                            inputData.repaymentAcIdVisible = "NO";
                                            varValue.repaymentAcId = "";
                                            varValue.repaymentName = "";
                                            varValue.repaymentAccountApiCall = "NO";
                                            varValue.repaymentAcIdVisible = "NO";
                                        }*/
                                    setTimeout(() => {
                                        this.setState({
                                            inputData: this.emptyValueRemove(inputData),
                                            varValue: this.emptyValueRemove(varValue),
                                            showValue: true,
                                            tagData: true,
                                            getData: true,
                                            staticData: true,
                                            loading: false
                                        })
                                    }, 1000)
                                })
                                .catch((error)=>{
                                    if (this.state.FormCasaFlow === 'YES' && this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                                        if (inputData.currency !== undefined && inputData.currency.key !== undefined) {
                                            inputData.dpsccy = inputData.currency
                                        } else {
                                            inputData.dpsccy = this.findByVarNameGetKeyValue(inputData.currency, this.state.currency)
                                        }

                                    } else if (this.props.freeFlag1 !== "DPS Account Opening -with Existing Operative Account") {
                                        if (inputData.currency !== undefined && inputData.currency.key !== undefined) {
                                            inputData.ccy = inputData.currency;
                                        } else {
                                            inputData.ccy = this.findByVarNameGetKeyValue(inputData.currency, this.state.currency);
                                        }
                                    } else if (this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account') {
                                        if (inputData.currency !== undefined && inputData.currency.key !== undefined) {
                                            inputData.dpsccy = inputData.currency
                                        } else {
                                            inputData.dpsccy = this.findByVarNameGetKeyValue(inputData.currency, this.state.currency)
                                        }
                                    }
                                    //if (inputData.maturity === "Renew Principal Only and Credit Interest to") {
                                    inputData.interestCreditAccount = inputData.interestCreditAccount;
                                    inputData.interestCreditName = inputData.interestCreditName;
                                    inputData.interestCreditAccountApiCall = "YES";
                                    inputData.interestCreditAccountVisible = "YES";
                                    varValue.interestCreditAccount = varValue.interestCreditAccount;
                                    varValue.interestCreditName = varValue.interestCreditName;
                                    varValue.interestCreditAccountApiCall = "YES";
                                    varValue.interestCreditAccountVisible = "YES";
                                    // }
                                    /*else {
                                            inputData.interestCreditAccount = "";
                                            inputData.interestCreditName = "";
                                            inputData.interestCreditAccountApiCall = "NO";
                                            inputData.interestCreditAccountVisible = "NO";
                                            varValue.interestCreditAccount = "";
                                            varValue.interestCreditName = "";
                                            varValue.interestCreditAccountApiCall = "NO";
                                            varValue.interestCreditAccountVisible = "NO";
                                        }*/
                                    // if (inputData.maturity === "Encashment at Maturity") {
                                    inputData.repaymentAcId = inputData.repaymentAccount;
                                    inputData.repaymentName = inputData.repaymentName;
                                    inputData.repaymentAccountApiCall = "YES";
                                    inputData.repaymentAcIdVisible = "YES";
                                    varValue.repaymentAcId = inputData.repaymentAccount;
                                    varValue.repaymentName = inputData.repaymentName;
                                    varValue.repaymentAccountApiCall = "YES";
                                    varValue.repaymentAcIdVisible = "YES";
                                    //   }
                                    /*else {
                                            inputData.repaymentAcId = "";
                                            inputData.repaymentName = "";
                                            inputData.repaymentAccountApiCall = "NO";
                                            inputData.repaymentAcIdVisible = "NO";
                                            varValue.repaymentAcId = "";
                                            varValue.repaymentName = "";
                                            varValue.repaymentAccountApiCall = "NO";
                                            varValue.repaymentAcIdVisible = "NO";
                                        }*/
                                    setTimeout(() => {
                                        this.setState({
                                            inputData: this.emptyValueRemove(inputData),
                                            varValue: this.emptyValueRemove(varValue),
                                            showValue: true,
                                            tagData: true,
                                            getData: true,
                                            staticData: true,
                                            loading: false
                                        })
                                    }, 1000)
                                })

                        }
                        //ffff
                        let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                        axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                console.log(response.data);
                                response.data.map((data) => {
                                    if (data.remarks !== 'undefined') {
                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                    }
                                });
                                this.setState({
                                    getRemarks: remarksArray
                                })
                            })
                            .catch((error) => {
                                console.log(error)
                            });
                        let varValue = this.copyObejct(response.data);
                        let inputData = this.copyObejct(response.data);

                        let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                        axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                            let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                                console.log(response.data);
                                this.setState({
                                    getImageLink: response.data,
                                    getImageBoolean: true
                                })
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            console.log(response.data);
                            let tableArray = [];
                            response.data.map((deferal) => {
                                tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, deferal.status));
                            });
                            this.setState({
                                getDeferalList: tableArray
                            })
                        })
                            .catch((error) => {
                                console.log(error);
                            })


                    }, 500)
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false,
                            })
                        });


                    let checkerListUrl = backEndServerURL + "/checkers";
                    axios.get(checkerListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                        let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                        axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                console.log(response.data);
                                let tableArray = [];
                                response.data.map((deferal) => {
                                    tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, deferal.status));
                                });
                                this.setState({
                                    getDeferalList: tableArray
                                })
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                        console.log(response.data);
                        this.setState({
                            getCheckerList: response.data,
                        })
                    }, 1000)

                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {
        if (this.state.getData && this.state.getRemarks.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "Raised By", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />
                    <br/>
                </div>
            )
        }
    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {
        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    returnLoanField = () => {
        if (this.state.inputData.loan === true)
            return TextFieldComponent
                .text(this.state, this.updateComponent, loanTextField)
    };

    returnCreditCardField = () => {
        if (this.state.inputData.creditCard === true)
            return TextFieldComponent.text(this.state, this.updateComponent, creditCardTextField)
    };

    returnLetterOfCreditField = () => {
        if (this.state.inputData.letterOfCredit === true)
            return TextFieldComponent.text(this.state, this.updateComponent, letterOfCreditTextField)
    };

    renderServiceTagging() {
        return (
            <React.Fragment>
                <br/>
                <br/>
                {
                    CommonJsonFormComponent.renderJsonForm(this.state, LoanCheckbox, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
                }
                {
                    this.returnLoanField()
                }
                <Grid item xs={12}></Grid>
                {
                    CommonJsonFormComponent.renderJsonForm(this.state, creditCardBox, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
                }
                {
                    this.returnCreditCardField()
                }
                <Grid item xs={12}></Grid>
                {
                    CommonJsonFormComponent.renderJsonForm(this.state, letterOfCreditFieldCheckbox, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
                }
                {
                    this.returnLetterOfCreditField()
                }
                <br/>
                <br/>
            </React.Fragment>
        );
    }

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    validationForHandleConfirm = (actionType) => {
        console.log(this.state.inputData);
        let errors = MyValidation.defaultValidation(makerRemarks, this.state);
        this.forceUpdate();

        if (errors === true) {
            console.log("Not working");
            return 0;
        }
        if (actionType === "submitClosed") {
            let dependencyField = [];

            dependencyField.push({

                    "varName": "makerRemarks",
                    "type": "textArea",
                    "required": true,
                    "label": "Maker Remarks",
                    "grid": 12
                }

            );
            let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
            this.forceUpdate();
            if (error === true) {
                this.setState({
                    loading: false
                });
                return 0;
            }

            this.setState({
                actionType: actionType,
                confirmAlert: true,

            })

        }
        if (actionType === "submitReturn") {
            let dependencyField = [];

            dependencyField.push({

                    "varName": "makerRemarks",
                    "type": "textArea",
                    "required": true,
                    "label": "Maker Remarks",
                    "grid": 12
                }

            );
            let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
            this.forceUpdate();
            if (error === true) {
                this.setState({
                    loading: false
                });
                return 0;
            }

            this.setState({
                actionType: actionType,
                confirmAlert: true,

            })

        }
        else if (actionType === "handleSubmitSave") {
            this.setState({
                loading: false,
                actionType: actionType,
                confirmAlert: true,

            })

        }
        else if (actionType === "submitApprove") {
            let percentage = 0;
            let dependencyFields = this.props.commonJsonForm.concat(this.props.commonJsonFormLast, objectForNominee);
            if (this.props.freeFlag1 !== 'FDR Account Opening - with Existing Operative Account' && this.props.freeFlag1 !== 'DPS Account Opening -with Existing Operative Account' && this.props.freeFlag2 === "withKyc") {
                dependencyFields = this.props.commonJsonForm.concat(this.props.commonJsonFormLast, objectForNominee, this.props.jsonFormMisRelated, fdKYC);

            }
            if ((this.props.freeFlag1 !== "DPS Account Opening -with Existing Operative Account" && this.state.inputData.nomineeType === "New Nominee Assignment" ) || (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account" && this.state.inputData.dpsnomineeType === "New Nominee Assignment")) {
                if (this.state.inputData["numberOfNominee"] !== undefined) {
                    for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
                        percentage += Number(this.state.inputData["percentageNominee" + (i + 1)]);

                    }
                    if (percentage !== 0) {
                        if (percentage !== 100) {
                            for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
                                dependencyFields.push({
                                    "varName": "percentageNominee" + (i + 1),
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 6,
                                    required: true,
                                })

                            }
                            this.setState({
                                loading: false,
                                errorModalBoolean: true,
                                errorTittle: "Nominee Percentage should be 100%",
                            });
                            return 0;

                        }
                    }
                }
            }
            this.setState({
                loading: false,
            });

            //let data = (this.props.commonJsonForm.concat(this.props.commonJsonFormLast));
            let errors = MyValidation.defaultValidation(this.emptyValueRemove(this.checkReadOnlyField(dependencyFields)), this.state);
            this.forceUpdate();

            if (errors === true) {
                console.log("validation");
                return 0;
            }
            /*  if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                  if (Number(this.state.inputData.availableAmount) < Number(this.state.inputData.depositAmount)) {
                      this.setState({
                          loading: false,
                          errorModalBoolean: true,
                          errorMessage: "Insufficient Fund",
                      })
                      return 0;
                  } else if (this.state.inputData.depositAmount < 500) {
                      this.setState({
                          loading: false,
                          errorModalBoolean: true,
                          errorMessage: "Minimum balance Should be 500",
                      })
                      return 0;
                  }
              }
              else {
                  if (Number(this.state.inputData.availableAmount) < Number(this.state.inputData.depositinstallAmount)) {
                      this.setState({
                          loading: false,
                          errorModalBoolean: true,
                          errorMessage: "Insufficient Fund",
                      })
                      return 0;
                  } else if (this.state.inputData.depositinstallAmount < 10000) {
                      this.setState({
                          loading: false,
                          errorModalBoolean: true,
                          errorMessage: "Minimum balance Should be 10000",
                      })
                      return 0;
                  }
              }*/
            let dependencyField = this.props.commonJsonForm;
            let error = MyValidation.defaultValidation(this.emptyValueRemove(this.checkReadOnlyField(dependencyField)), this.state);
            this.forceUpdate();
            if (error === true) {
                this.setState({
                    loading: false
                });
                return 0;
            }
            if ((this.props.freeFlag1 !== "DPS Account Opening -with Existing Operative Account" && this.state.inputData.nomineeType === "New Nominee Assignment" ) || (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account" && this.state.inputData.dpsnomineeType === "New Nominee Assignment")) {
                if (this.state.inputData.numberOfNominee === undefined || this.state.inputData.numberOfNominee === null) {

                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Number Of Nominee Field Mandatory!",
                    });
                    return 0;

                }
            }

            this.setState({
                actionType: actionType,
                confirmAlert: true,

            })

        }

    };

    closeConfirmAlert = (data) => {
        this.setState({
            confirmAlert: false
        });
        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "submitApprove") {
            this.state.inputData.maker_update_all_info_send_to = "CHECKER";
            this.handleSubmit();
        }
        else if (data === "YES" && this.state.actionType === "submitClosed") {
             this.state.inputData.maker_update_all_info_send_to = "FORCEFULLYCLOSED";
            this.handleClosed();
        } else if (data === "YES" && this.state.actionType === "submitReturn") {
            this.state.inputData.maker_update_all_info_send_to = "CS";
            this.state.inputData.apiButton = "UPDATE";
            this.handleSubmit();
        } else if (data === "YES" && this.state.actionType === "handleSubmitSave") {
            this.handleSubmitSave()
        }
    };

    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()

        {
            this.validationForHandleConfirm(type)
        }


    };

    handleSubmitSave = () => {

        let variableSetUrl = backEndServerURL + "/save/" + this.props.appId;
        this.setState({
            loading: true,
        });
        let data = this.state.inputData;
        data.makerRemarks = undefined;
        data.dataSaved = true;
        data.submitedFlag = true;
        if (this.props.freeFlag1 !== 'DPS Account Opening -with Existing Operative Account') {
            if(data.schemeCode!==undefined && data.schemeCode.value!==undefined){
                data.freeFlag4= data.schemeCode.value;
            }
        } else {
            if(data.dpsschemeCode!==undefined && data.dpsschemeCode.value!==undefined){
                data.freeFlag4= data.dpsschemeCode.value;
            }

        }
        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);

                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Saved!",
                    alert: true,
                    loading: false,

                });
                this.props.closeModal()
                //


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,

                })
            });

    };

    handleSubmit = () => {
        let saveDataDeferralRemarksAndRoute = {};
        let dependencyField = [];
        let error = null;

        if (this.state.inputData.autoRenewal === "Yes" && this.state.inputData.autoClosure === "Yes") {
            this.setState({
                loading: false,
                errorModalBoolean: true,
                errorTittle: "Please select the correct Renewal and Closure instructions.",
            });

            return 0;
        } else if (this.state.inputData.autoRenewal === "No" && this.state.inputData.autoClosure === "No") {
            this.setState({
                loading: false,
                errorModalBoolean: true,
                errorTittle: "Please select the correct Renewal and Closure instructions.",
            });

            return 0;
        }
        if (this.state.inputData.maker_update_all_info_send_to === "CS") {
            dependencyField.push({

                "varName": "makerRemarks",
                "type": "textArea",
                "required": true,
                "label": "Maker Remarks",
                "grid": 12,

            } )
        } else {

        }


        error = MyValidation.defaultValidation(this.checkReadOnlyField(this.emptyValueRemove(dependencyField.concat(objectForNominee,makerRemarks))), this.state);
        this.forceUpdate();

        if (error === true) {
            console.log("Not working");
            return 0;
        }


        this.setState({
            loading: true,
        });
        var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        if (this.state.inputData.makerRemarks !== undefined && this.state.inputData.makerRemarks !== null) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.makerRemarks;
        }

        let inputData = this.state.inputData;
        inputData.sectorCodeApicall = true;
        if (inputData.FdrDpsApiButton === null || inputData.FdrDpsApiButton === undefined) {
            inputData.FdrDpsApiButton = inputData.buttonFlow[0];
            inputData.buttonCount = 1;
        }
        /*    if (this.state.FormCasaFlow != "CASA" && this.state.inputData.maker_update_all_info_send_to === "CS" && this.props.freeFlag1 !== 'DPS Account Opening -with Existing Operative Account') {
                dependencyField = this.props.commonJsonForm;
                inputData.fdValueDate = inputData.valueDate;
                inputData.tenureType = inputData.depositTenure;
                inputData.tenure = inputData.depositPeriodMonthsdays;
                inputData.autoRenewals = inputData.autoRenewal;
                inputData.maturity = inputData.maturityDate;
                if(inputData.maturity==="Renew Principal Only and Credit Interest to"){
                    inputData.interestCreditAccount = inputData.interestCreditAccount;
                }
                else{
                    inputData.interestCreditAccount = "";
                }

                inputData.repaymentAccount = inputData.repaymentAcId;
                inputData.amount = inputData.depositinstallAmount;
                inputData.fdrSchemeCode = inputData.schemeCode;
                inputData.fdMaturityDate = inputData.maturityDate;
                inputData.etin = inputData.etin;
                inputData.depositeType = inputData.depositeType;
                inputData.occupationCode = inputData.occupationCode;
                inputData.sectorCode = inputData.sectorCode;
                inputData.subSectorCode = inputData.subSectorCode;
                inputData.rmCode = inputData.rmCode;
                inputData.rmCodeName = inputData.rmCodeName;
                inputData.nomineeType = inputData.nomineeType;
                inputData.nomineeExistNot = inputData.nomineeExistNot;
            }
            else if (this.state.FormCasaFlow != "CASA" && this.state.inputData.maker_update_all_info_send_to === "CS" && this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account') {
                inputData.fdValueDate = inputData.valueDate;
                inputData.tenureType = inputData.depositTenure;
                inputData.tenure = inputData.depositPeriodMonthsdays;
                inputData.dpsSchemeCode = inputData.schemeCode;
                inputData.amount = inputData.depositAmount;
                inputData.nextExecutionDate = inputData.nextExecutionDate;
                inputData.fdMaturityDate = inputData.maturityDate;
                inputData.occupationCode = inputData.occupationCode;
                inputData.sectorCode = inputData.sectorCode;
                inputData.subSectorCode = inputData.subSectorCode;
                inputData.rmCodeName = inputData.rmCodeName;
                inputData.nomineeType = inputData.nomineeType;
                inputData.nomineeExistNot = inputData.nomineeExistNot;
            }
            else if (this.state.FormCasaFlow === "CASA" && this.state.inputData.maker_update_all_info_send_to === "CS" && this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account') {
                inputData.dpsfdValueDate = inputData.valueDate;
                inputData.dpstenureType = inputData.depositTenure;
                inputData.dpstenure = inputData.depositPeriodMonthsdays;
                inputData.dpsdpsSchemeCode = inputData.schemeCode;
                inputData.dpsamount = inputData.depositAmount;
                inputData.nextExecutionDate = inputData.nextExecutionDate;
                inputData.dpsfdMaturityDate = inputData.maturityDate;
                inputData.occupationCode = inputData.occupationCode;
                inputData.sectorCode = inputData.sectorCode;
                inputData.dpssubSectorCode = inputData.subSectorCode;
                inputData.rmCodeName = inputData.rmCodeName;
                inputData.nomineeTypes = inputData.nomineeType;
                inputData.nomineeExistNots = inputData.nomineeExistNot;
            }*/
        if (this.props.freeFlag1 !== 'DPS Account Opening -with Existing Operative Account') {
            if(inputData.schemeCode!==undefined && inputData.schemeCode.value!==undefined){
                inputData.freeFlag4= inputData.schemeCode.value;
            }
        } else {
            if(inputData.dpsschemeCode!==undefined && inputData.dpsschemeCode.value!==undefined){
                inputData.freeFlag4= inputData.dpsschemeCode.value;
            }

        }
        inputData.employerDataemploymentType = inputData.employerDataprofessionEmploymentType;
        inputData.submitedFlag = true;
        if (this.state.inputData.maker_update_all_info_send_to === "CS") {

            if (this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account') {
                inputData.dpsccy = inputData.currency;
            } else {
                inputData.ccy = inputData.currency;
            }


        }
        saveDataDeferralRemarksAndRoute.map = inputData;
        var url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            console.log(response.data);
            this.setState({
                title: "Successfull!",
                notificationMessage: "Successfully Routed!",
                alert: true,
                loading: false,
            });
            this.props.closeModal();
        })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                })
            });

    };
    handleClosed = () => {
        let saveDataDeferralRemarksAndRoute = {};
        let dependencyField = [];
        let error = null;

        if (this.state.inputData.autoRenewal === "Yes" && this.state.inputData.autoClosure === "Yes") {
            this.setState({
                loading: false,
                errorModalBoolean: true,
                errorTittle: "Please select the correct Renewal and Closure instructions.",
            });

            return 0;
        } else if (this.state.inputData.autoRenewal === "No" && this.state.inputData.autoClosure === "No") {
            this.setState({
                loading: false,
                errorModalBoolean: true,
                errorTittle: "Please select the correct Renewal and Closure instructions.",
            });

            return 0;
        }
        if (this.state.inputData.maker_update_all_info_send_to === "CS") {
            dependencyField.push({

                "varName": "makerRemarks",
                "type": "textArea",
                "required": true,
                "label": "Maker Remarks",
                "grid": 12,

            } )
        } else {

        }


        error = MyValidation.defaultValidation(this.checkReadOnlyField(this.emptyValueRemove(dependencyField.concat(objectForNominee))), this.state);
        this.forceUpdate();

        if (error === true) {
            console.log("Not working");
            return 0;
        }


        this.setState({
            loading: true,
        });
        var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        if (this.state.inputData.makerRemarks !== undefined && this.state.inputData.makerRemarks !== null) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.makerRemarks;
        }

        let inputData = this.state.inputData;
        inputData.sectorCodeApicall = true;
        if (inputData.FdrDpsApiButton === null || inputData.FdrDpsApiButton === undefined) {
            inputData.FdrDpsApiButton = inputData.buttonFlow[0];
            inputData.buttonCount = 1;
        }
        /*    if (this.state.FormCasaFlow != "CASA" && this.state.inputData.maker_update_all_info_send_to === "CS" && this.props.freeFlag1 !== 'DPS Account Opening -with Existing Operative Account') {
                dependencyField = this.props.commonJsonForm;
                inputData.fdValueDate = inputData.valueDate;
                inputData.tenureType = inputData.depositTenure;
                inputData.tenure = inputData.depositPeriodMonthsdays;
                inputData.autoRenewals = inputData.autoRenewal;
                inputData.maturity = inputData.maturityDate;
                if(inputData.maturity==="Renew Principal Only and Credit Interest to"){
                    inputData.interestCreditAccount = inputData.interestCreditAccount;
                }
                else{
                    inputData.interestCreditAccount = "";
                }

                inputData.repaymentAccount = inputData.repaymentAcId;
                inputData.amount = inputData.depositinstallAmount;
                inputData.fdrSchemeCode = inputData.schemeCode;
                inputData.fdMaturityDate = inputData.maturityDate;
                inputData.etin = inputData.etin;
                inputData.depositeType = inputData.depositeType;
                inputData.occupationCode = inputData.occupationCode;
                inputData.sectorCode = inputData.sectorCode;
                inputData.subSectorCode = inputData.subSectorCode;
                inputData.rmCode = inputData.rmCode;
                inputData.rmCodeName = inputData.rmCodeName;
                inputData.nomineeType = inputData.nomineeType;
                inputData.nomineeExistNot = inputData.nomineeExistNot;
            }
            else if (this.state.FormCasaFlow != "CASA" && this.state.inputData.maker_update_all_info_send_to === "CS" && this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account') {
                inputData.fdValueDate = inputData.valueDate;
                inputData.tenureType = inputData.depositTenure;
                inputData.tenure = inputData.depositPeriodMonthsdays;
                inputData.dpsSchemeCode = inputData.schemeCode;
                inputData.amount = inputData.depositAmount;
                inputData.nextExecutionDate = inputData.nextExecutionDate;
                inputData.fdMaturityDate = inputData.maturityDate;
                inputData.occupationCode = inputData.occupationCode;
                inputData.sectorCode = inputData.sectorCode;
                inputData.subSectorCode = inputData.subSectorCode;
                inputData.rmCodeName = inputData.rmCodeName;
                inputData.nomineeType = inputData.nomineeType;
                inputData.nomineeExistNot = inputData.nomineeExistNot;
            }
            else if (this.state.FormCasaFlow === "CASA" && this.state.inputData.maker_update_all_info_send_to === "CS" && this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account') {
                inputData.dpsfdValueDate = inputData.valueDate;
                inputData.dpstenureType = inputData.depositTenure;
                inputData.dpstenure = inputData.depositPeriodMonthsdays;
                inputData.dpsdpsSchemeCode = inputData.schemeCode;
                inputData.dpsamount = inputData.depositAmount;
                inputData.nextExecutionDate = inputData.nextExecutionDate;
                inputData.dpsfdMaturityDate = inputData.maturityDate;
                inputData.occupationCode = inputData.occupationCode;
                inputData.sectorCode = inputData.sectorCode;
                inputData.dpssubSectorCode = inputData.subSectorCode;
                inputData.rmCodeName = inputData.rmCodeName;
                inputData.nomineeTypes = inputData.nomineeType;
                inputData.nomineeExistNots = inputData.nomineeExistNot;
            }*/
        if (this.props.freeFlag1 !== 'DPS Account Opening -with Existing Operative Account') {
            if(inputData.schemeCode!==undefined && inputData.schemeCode.value!==undefined){
                inputData.freeFlag4= inputData.schemeCode.value;
            }
        } else {
            if(inputData.dpsschemeCode!==undefined && inputData.dpsschemeCode.value!==undefined){
                inputData.freeFlag4= inputData.dpsschemeCode.value;
            }

        }
        inputData.employerDataemploymentType = inputData.employerDataprofessionEmploymentType;
        inputData.submitedFlag = true;
        if (this.state.inputData.maker_update_all_info_send_to === "CS") {

            if (this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account') {
                inputData.dpsccy = inputData.currency;
            } else {
                inputData.ccy = inputData.currency;
            }


        }
        saveDataDeferralRemarksAndRoute.map = inputData;
        var url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            console.log(response.data);
            this.setState({
                title: "Successfull!",
                notificationMessage: "Successfully Routed!",
                alert: true,
                loading: false,
            });
            this.props.closeModal();
        })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                })
            });

    };

    submitApprove = () => {
        this.state.inputData.maker_update_all_info_send_to = "CHECKER";
        this.handleSubmit();
    };

    submitReturn = () => {
        this.state.inputData.maker_update_all_info_send_to = "CS";
        this.handleSubmit();
    };

    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;
        console.log(value);
        console.log(getKeyValue);

        if (value !== undefined && value !== null && Array.isArray(jsonArray) && jsonArray.length > 0) {
            for (let i = 0; i < jsonArray.length; i++) {
                let jsonObject = jsonArray[i];
                console.log(jsonObject);
                if (value === jsonObject.key) {
                    return jsonObject;
                    i = jsonArray.length;

                }
            }
        }


    };

    onKeyDownChange = (data) => {
        let inputData = this.state.inputData;
        let varValue = this.state.varValue;

        for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
            if (data.varName === "nomineeDob" + (i + 1)) {
                this.setState({
                    loading: true
                });
                this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                //  this.forceUpdate()
                setTimeout(() => {
                    let x = 18;
                    let currentDate = new Date();
                    let someDate = new Date();
                    let numberOfDaysToAdd = x;
                    someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let convertDate = year + "-" + month + "-" + date;
                    let newDate = new Date(convertDate);
                    let stringToDate = new Date(newDate.toDateString());
                    let inputDate = new Date(this.state.inputData["nomineeDob" + (i + 1)]);
                    if (stringToDate >= inputDate) {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "NO";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = true;
                        // this.state.varValue["nomineeMinor" + i] == "NO";
                    } else {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "YES";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = true;


                    }

                    this.forceUpdate()
                }, 1000);
                this.setState({
                    showValue: true,
                    getData: true,
                    staticData: true,
                    loading: false
                })


            }
        }
        if (data.varName === "sectorCode") {
            this.setState({
                loading: true
            });
            this.state.inputData.sectorCodeApicall = false;
            this.state.inputData.varValue = false;
            let codeUrl = backEndServerURL + "/finMasterData/custom/subSector/" + this.state.inputData.sectorCode["key"];//{sectorCode}
            axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    {
                        this.findByVarNameApiData("subSectorCode", response.data)
                    }
                    this.state.inputData.sectorCodeApiCall = true;
                    this.state.varValue.sectorCodeApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        loading: false
                    })

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })
        } else if (data.varName === "dpssectorCode") {
            this.setState({
                loading: true
            });
            this.state.inputData.sectorCodeApicall = false;
            let codeUrl = backEndServerURL + "/finMasterData/custom/subSector/" + this.state.inputData.dpssectorCode["key"];//{sectorCode}
            axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    {
                        this.findByVarNameApiData("dpssubSectorCode", response.data)
                    }
                    this.state.inputData.sectorCodeApicall = true;
                    this.state.varValue.sectorCodeApicall = true;
                    this.forceUpdate();
                    this.setState({
                        loading: false
                    })

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })
        } else if (data.varName == "basicInformationUpdatedob") {
            this.state.inputData.basicInformationUpdatedobApiCall = "NO";
            this.state.varValue.basicInformationUpdatedobApiCall = "NO";
            this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N";
            this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N";
            //this.updateComponent()
            //if(data.value==="basicInformationUpdatedob"){
            setTimeout(() => {
                let x = 18;
                var currentDate = new Date();

                let someDate = new Date();
                let numberOfDaysToAdd = x;
                someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                let date = someDate.getDate();
                let month = someDate.getMonth() + 1;
                let year = someDate.getFullYear();
                let convertDate = year + "-" + month + "-" + date;
                let newDate = new Date(convertDate);
                let stringToDate = new Date(newDate.toDateString());

                let inputDate = new Date(this.state.inputData.basicInformationUpdatedob);
                console.log(stringToDate);
                console.log(this.state.inputData.basicInformationUpdatedob);
                console.log(inputDate);


                if (stringToDate >= inputDate) {
                    this.state.inputData.basicInformationUpdatedobApiCall = "YES";
                    this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N";
                    this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
                } else {
                    this.state.inputData.basicInformationUpdatedobApiCall = "YES";
                    this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "Y";
                    this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "Y"
                }

                x = 60;
                currentDate = new Date();
                someDate = new Date();
                numberOfDaysToAdd = x;
                someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                date = someDate.getDate();
                month = someDate.getMonth() + 1;
                year = someDate.getFullYear();
                convertDate = year + "-" + month + "-" + date;
                newDate = new Date(convertDate);
                stringToDate = new Date(newDate.toDateString());

                inputDate = new Date(this.state.inputData.basicInformationUpdatedob);

                if (stringToDate >= inputDate) {
                    inputData.basicInformationUpdateseniorCitizenIndicator = "Y";
                    varValue.basicInformationUpdateseniorCitizenIndicator = "Y"


                } else {

                    inputData.basicInformationUpdateseniorCitizenIndicator = "N";
                    varValue.basicInformationUpdateseniorCitizenIndicator = "N"
                }


                this.forceUpdate()

            }, 500)

        } else if (data.varName === "debitAccountStatement") {
            this.setState({
                accountStatement: true
            })
        }
        else if (data.varName === "updateInterestRate" && (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account" || this.props.serviceType === "DPS Opening")) {
            let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
            let data = {};
            if(this.state.inputData.dpsschemeCode!==undefined && this.state.inputData.dpsschemeCode.key!==undefined){
                data.schemeCode = this.state.inputData.dpsschemeCode.key;
            }
            data.amount = this.state.inputData.dpsdepositAmount;
            data.tenure = this.state.inputData.dpsdepositPeriodMonthsdays;
            data.tenorType = this.state.inputData.dpsdepositTenure;

            //data.tenureType = this.state.inputData.depositTenure;
            if(this.state.inputData.dpsccy!==undefined && this.state.inputData.dpsccy.key!==undefined ){
                data.currency =this.state.inputData.dpsccy.key;
            }
            else{
                data.currency = this.state.inputData.ccy;
            }
            if(this.state.inputData.dpsvalueDate!==undefined){
                data.inputDate = this.state.inputData.dpsvalueDate;
            }
            else if(this.state.inputData.valueDate!==undefined){
                data.inputDate = this.state.inputData.valueDate;
            }

            console.log(data);
            //this.state.inputData.interestRateApicall = "NO";
            /* this.state.inputData.interestRate = "";
             this.state.varValue.interestRate = "";
             this.forceUpdate()*/
            this.setState({
                loading: true,
                interestRateApiFlag: false,
                staticData: false,
            });
            axios.post(interestCalculateAPi, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.interestRate = response.data;
                    this.state.varValue.interestRate = response.data;
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate();
                    this.setState({
                        interestRateApiFlag: true,
                        loading: false,
                        staticData: true,
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate();
                    this.setState({
                        interestRateApiFlag: true,
                        loading: false,
                        staticData: true,
                        errorTittle: "Invalid Input !",
                        errorModalBoolean: true,


                    })
                })

        } else if (data.varName === "updateInterestRate") {
            let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
            let data = {};
            if(this.state.inputData.schemeCode!==undefined && this.state.inputData.schemeCode.key!==undefined) {
                data.schemeCode = this.state.inputData.schemeCode.key;
            }
            data.amount = this.state.inputData.depositinstallAmount;
            data.tenure = this.state.inputData.depositPeriodMonthsdays;
            data.tenorType = this.state.inputData.depositTenure;
            if(this.state.inputData.valueDate!==undefined){
                data.inputDate = this.state.inputData.valueDate;
            }
            else if(this.state.inputData.dpsvalueDate!==undefined){
                data.inputDate = this.state.inputData.dpsvalueDate;
            }
            //data.tenureType = this.state.inputData.depositTenure;
            data.currency = this.state.inputData.ccy;
            console.log(data);
            this.setState({
                loading: true,
                staticData: false,
                interestRateApiFlag: false,
            });
            //this.state.inputData.interestRateApicall = "NO";
            /* this.state.inputData.interestRate = "";
             this.state.varValue.interestRate = "";
             this.forceUpdate()*/
            axios.post(interestCalculateAPi, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.interestRate = response.data;
                    this.state.varValue.interestRate = response.data;
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate();
                    this.setState({
                        interestRateApiFlag: true,
                        loading: false,
                        staticData: true,
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate();
                    this.setState({
                        interestRateApiFlag: true,
                        loading: false,
                        staticData: true,
                        errorTittle: "Invalid Input !",
                        errorModalBoolean: true,
                    })
                })

        }
        /*     else if (data.varName === "maturity") {
            this.setState({
                loading: true
            })
            this.state.inputData.fdrSchemeCodeApiCall = false
            let object = {
                currency: typeof this.state.inputData.currency === "object" ? this.state.inputData.currency.key : this.state.inputData.currency,
            };
            if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                object.natureAccount = "Individual-Retail";
            } else {
                object.natureAccount = "Non-Individual"
            }
            object.accountType = this.state.inputData.accountType;
            object.accountAcquisition = this.state.inputData.accountAcquisition;
            object.tenureType = this.state.inputData.tenureType;
            object.tenure = this.state.inputData.tenure;
            object.autoRenewals = this.state.inputData.autoRenewals;
            object.maturity = this.state.inputData.maturity;
            let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getSchemeCode";//{sectorCode}
            axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    this.state.inputData.fdrSchemeCode = response.data
                    this.state.varValue.fdrSchemeCode = response.data
                    this.state.inputData.fdrSchemeCodeApiCall = true
                    this.forceUpdate()
                    this.setState({
                        loading: false
                    })

                })
                .catch((error) => {
                    console.log(error)
                    this.setState({
                        loading: false
                    })
                })
        }*/
        if (this.props.titleName === "DPS Account Opening -with Existing Operative Account" && (data.varName === "dpsvalueDate" || data.varName === "dpsdepositTenure" || data.varName === "dpsdepositPeriodMonthsdays")) {
            this.state.inputData.fdMaturityDateApicall = "NO";
            this.state.varValue.fdMaturityDateApicall = "NO";
            this.forceUpdate();
            let someDate = new Date();
            if (this.state.inputData.dpsvalueDate !== undefined && this.state.inputData.dpsvalueDate !== null && this.state.inputData.dpsvalueDate !== -1) {
                someDate = new Date(this.state.inputData.dpsvalueDate);
            }
            let x = this.state.inputData.dpsdepositPeriodMonthsdays;
            let numberOfMonthsToAdd = parseInt(x, 10);
            someDate.setMonth(someDate.getMonth() + numberOfMonthsToAdd);
            let dates = someDate.getDate();
            let month = someDate.getMonth() + 1;
            if (month <= 9) {
                month = "0" + month;
            }
            if (dates <= 9) {
                dates = "0" + dates;
            }

            let year = someDate.getFullYear();
            let calculateDate = dates + '-' + month + '-' + year;
            if (calculateDate === "NaN-NaN-NaN") {
                calculateDate = ""
            }
            setTimeout(() => {
                this.state.inputData.dpsmaturityDate = calculateDate;
                this.state.varValue.dpsmaturityDate = calculateDate;
                this.state.inputData.fdMaturityDateApicall = "YES";
                this.state.varValue.fdMaturityDateApicall = "YES";
                this.forceUpdate()
            }, 100)

        } else if (this.props.titleName !== "DPS Account Opening -with Existing Operative Account" && (data.varName === "valueDate" || data.varName === "depositTenure" || data.varName === "depositPeriodMonthsdays")) {
            /*     this.state.inputData.depositeTypeApicall = false;
                 this.state.varValue.depositeTypeApicall = false;
                 this.forceUpdate();*/
            let object = {};
            if (this.state.inputData.currency !== undefined && this.state.inputData.currency.key !== undefined) {
                object.currency = this.state.inputData.currency.key
            } else {
                object.currency = this.state.inputData.currency
            }
            if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                object.natureAccount = "Individual-Retail";
            } else {
                object.natureAccount = "Non-Individual"
            }
            object.accountType = this.state.inputData.accountType;
            object.accountAcquisition = this.state.inputData.accountAcquisition;
            object.tenureType = this.state.inputData.depositTenure;
            let url = backEndServerURL + "/FDRDPSSchemeCode/getTenorByTenorType/" + this.state.inputData.depositTenure;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    let jsonArrayServiceList = this.props.commonJsonForm;
                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "depositPeriodMonthsdays") {
                            /* this.state.inputData.depositPeriodMonthsdays.enum = [];
                              this.state.varValue.depositPeriodMonthsdays.enum = [];
                              this.forceUpdate()*/
                            this.props.commonJsonForm[i].enum = [];
                            this.props.commonJsonForm[i].enum = response.data;
                            this.forceUpdate();
                            console.log(this.props.commonJsonForm[i])
                        }
                    }
                    jsonArrayServiceList = this.props.commonJsonFormLast;
                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "depositPeriodMonthsdays") {
                            this.props.commonJsonFormLast[i].enum = [];
                            this.props.commonJsonFormLast[i].enum = response.data;
                            console.log(this.props.commonJsonFormLast[i])
                        }
                    }


                })
                .catch((error) => {
                    console.log(error)

                });
            if (data.varName === "depositPeriodMonthsdays") {
                this.state.inputData.depositeTypeApicall = false;
                this.state.varValue.depositeTypeApicall = false;
                this.forceUpdate();
                object.tenure = this.state.inputData.depositPeriodMonthsdays;
                url = backEndServerURL + "/FDRDPSSchemeCode/getRenewal";
                axios.post(url, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        let jsonArrayServiceList = this.props.commonJsonForm;
                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "depositeType") {
                                /* this.state.inputData.depositPeriodMonthsdays.enum = [];
                                 this.state.varValue.depositPeriodMonthsdays.enum = [];
                                 this.forceUpdate()*/
                                this.state.inputData.depositeType = response.data.depositCode;
                                this.state.varValue.depositeType = response.data.depositCode;
                                this.state.inputData.depositeTypeApicall = true;
                                this.state.varValue.depositeTypeApicall = true;
                                this.forceUpdate()

                            }
                        }
                        jsonArrayServiceList = this.props.commonJsonFormLast;
                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "depositeType") {
                                this.state.inputData.depositeType = response.data.depositCode;
                                this.state.varValue.depositeType = response.data.depositCode;
                                this.state.inputData.depositeTypeApicall = true;
                                this.state.varValue.depositeTypeApicall = true;
                                this.forceUpdate()
                            }
                        }


                    })
                    .catch((error) => {
                        this.state.inputData.depositeTypeApicall = true;
                        this.state.varValue.depositeTypeApicall = true;
                        this.forceUpdate()
                    })
            }


            this.state.inputData.fdMaturityDateApicall = "NO";
            this.state.varValue.fdMaturityDateApicall = "NO";
            this.forceUpdate();
            let someDate = new Date();
            if (this.state.inputData.valueDate !== undefined && this.state.inputData.valueDate !== null && this.state.inputData.valueDate !== -1) {
                someDate = new Date(this.state.inputData.valueDate);
            }
            let x = this.state.inputData.depositPeriodMonthsdays;
            let numberOfMonthsToAdd = parseInt(x, 10);
            let dates = "";
            let month = "";
            if (this.state.inputData.depositTenure === "Day") {
                someDate.setDate(someDate.getDate() + numberOfMonthsToAdd);
                dates = someDate.getDate();
                month = someDate.getMonth() + 1;

            } else {
                someDate.setMonth(someDate.getMonth() + numberOfMonthsToAdd);
                dates = someDate.getDate();
                month = someDate.getMonth() + 1;

            }
            if (month <= 9) {
                month = "0" + month;
            }
            if (dates <= 9) {
                dates = "0" + dates;
            }

            let year = someDate.getFullYear();
            let calculateDate = dates + '-' + month + '-' + year;
            if (calculateDate === "NaN-NaN-NaN") {
                calculateDate = ""
            }
            setTimeout(() => {
                this.state.inputData.maturityDate = calculateDate;
                this.state.varValue.maturityDate = calculateDate;
                this.state.inputData.fdMaturityDateApicall = "YES";
                this.state.varValue.fdMaturityDateApicall = "YES";
                this.forceUpdate()
            }, 100)
        }

    };

    onKeyDownForDedup = (event, data, secondVarName) => {
        for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {

            if (data === "nomineecifId" + (i + 1)) {
                this.setState({
                    loading: true
                });

                this.state.inputData["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.varValue["nomineecifIdApiCall" + (i + 1)] = false;
                let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData["nomineecifId" + (i + 1)];
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);

                        this.state.inputData["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.inputData["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.inputData["nomineeAddress2" + (i + 1)] = response.data.mailingAddress2;
                        this.state.inputData["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.inputData["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.inputData["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.inputData["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.inputData["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.varValue["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.varValue["nomineeAddress2" + (i + 1)] = response.data.mailingAddress2;
                        this.state.varValue["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.varValue["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.varValue["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.varValue["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        let x = 18;
                        let currentDate = new Date();
                        let someDate = new Date();
                        let numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        let date = someDate.getDate();
                        let month = someDate.getMonth() + 1;
                        let year = someDate.getFullYear();
                        let convertDate = year + "-" + month + "-" + date;
                        let newDate = new Date(convertDate);
                        let stringToDate = new Date(newDate.toDateString());

                        let inputDate = new Date(response.data.birthDate);
                        this.state.inputData["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        this.state.varValue["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        if (stringToDate >= inputDate) {
                            this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "NO";

                            // this.state.varValue["nomineeMinor" + i] == "NO";
                        } else {
                            this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "YES";


                        }

                        /*this.state.inputData["nomineeGuardiansName" + i] =response.data.
                        this.state.inputData["nomineeGuardianCode" + i] =response.data.
                        this.state.inputData["guardianPhotoId" + i] =response.data.
                        this.state.inputData["nomineeGuardianAddress" + i] =response.data.
                        this.state.inputData["nomineeGuardianAddress2" + i]=response.data.
                        this.state.inputData["nomineeGuardianCityCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.city)
                        this.state.inputData["nomineeGuardianStateCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.state)
                       this.state.inputData["nomineeGuardianPostalCode" + i]=response.data.
                      this.state.inputData["nomineeGuardianCountry2" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.country)
*/
                        this.state.inputData["nomineecifIdApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineecifIdApiCall" + (i + 1)] = true;
                        this.forceUpdate();

                        console.log(this.state.inputData);
                        this.setState({
                            //varValue: this.state.varValue,
                            //inputData: this.state.inputData,
                            showValue: true,
                            getData: true,
                            staticData: true,
                            loading: false
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                            errorTittle: "Invalid Introducer ID",
                            errorModalBoolean: true,


                        })
                    })

            }
        }
        if (data === "dpsrmCode") {
            this.setState({
                loading: true
            });
            this.state.inputData.rmCodeApiCall = "NO";
            this.state.varValue.rmCodeApiCall = "NO";
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.dpsrmCodeName = response.data;
                    this.state.varValue.dpsrmCodeName = response.data;
                    console.log(response.data);
                    this.state.inputData.rmCodeApiCall = "YES";
                    this.state.varValue.rmCodeApiCall = "YES";
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        varValue: this.state.varValue,

                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid RM Code"
                    })

                })
        } else if (data === "rmCode") {
            this.setState({
                loading: true
            });
            this.state.inputData.rmCodeApiCall = "NO";
            this.state.varValue.rmCodeApiCall = "NO";
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.rmCodeName = response.data;
                    this.state.varValue.rmCodeName = response.data;
                    console.log(response.data);
                    this.state.inputData.rmCodeApiCall = "YES";
                    this.state.varValue.rmCodeApiCall = "YES";
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        varValue: this.state.varValue,

                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid RM Code"
                    })

                })
        } else if (data === "debitAccountNumber") {
            this.setState({
                loading: true
            });
            this.state.inputData.debitAccountNumberApiCall = "NO";
            this.state.varValue.debitAccountNumberApiCall = "NO";
            this.forceUpdate();
            let urls = backEndServerURL + "/intgr/accountBalanceInfo/" + (this.state.inputData[secondVarName]);
            axios.get(urls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);

                    this.state.inputData.debitAccountName = response.data.name;
                    this.state.varValue.debitAccountName = response.data.name;

                    this.state.inputData.debitAccountNumberApiCall = "YES";
                    this.state.varValue.debitAccountNumberApiCall = "YES";
                    this.setState({
                        showValue: true,
                        loading: false,
                        getData: true,
                        staticData: true,
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Debit Account Number!",
                    })
                })
        } else if (data === "interestCreditAccount") {
            this.setState({
                loading: true
            });
            this.state.inputData.interestCreditAccountApiCall = "No";
            this.state.varValue.interestCreditAccountApiCall = "NO";
            this.forceUpdate();
            let urls = backEndServerURL + "/intgr/accountBalanceInfo/" + (this.state.inputData[secondVarName]);
            axios.get(urls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);

                    this.state.inputData.interestCreditName = response.data.name;
                    this.state.varValue.interestCreditName = response.data.name;

                    this.state.inputData.interestCreditAccountApiCall = "YES";
                    this.state.varValue.interestCreditAccountApiCall = "YES";
                    this.setState({
                        showValue: true,
                        loading: false,
                        getData: true,
                        staticData: true,
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Account Number!",
                    })
                })
        } else if (data === "repaymentAcId") {
            this.setState({
                loading: true
            });
            this.state.inputData.repaymentAccountApiCall = "No";
            this.state.varValue.repaymentAccountApiCall = "No";
            this.forceUpdate();
            let urls = backEndServerURL + "/intgr/accountBalanceInfo/" + (this.state.inputData[secondVarName]);
            axios.get(urls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);

                    this.state.inputData.repaymentName = response.data.name;
                    this.state.varValue.repaymentName = response.data.name;

                    this.state.inputData.repaymentAccountApiCall = "YES";
                    this.state.varValue.repaymentAccountApiCall = "YES";
                    this.setState({
                        showValue: true,
                        loading: false,
                        getData: true,
                        staticData: true,
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Account Number!",
                    })
                })
        } else if (data === "dpspaymentAccountId") {
            this.setState({
                loading: true
            });
            this.state.inputData.dpspaymentAccountIdApiCall = "No";
            this.state.varValue.dpspaymentAccountIdApiCall = "No";
            this.forceUpdate();
            let urls = backEndServerURL + "/intgr/accountBalanceInfo/" + (this.state.inputData[secondVarName]);
            axios.get(urls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);

                    this.state.inputData.dpspaymentAccountName = response.data.name;
                    this.state.varValue.dpspaymentAccountName = response.data.name;

                    this.state.inputData.dpspaymentAccountIdApiCall = "YES";
                    this.state.varValue.dpspaymentAccountIdApiCall = "YES";
                    this.setState({
                        showValue: true,
                        loading: false,
                        getData: true,
                        staticData: true,
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Account Number!",
                    })
                })
        }


    };

    stopLoading = (value) => {
        if (value === "yes") {
            setTimeout(() => {
                this.setState({
                    loading: false,
                });
            }, 1000)
        } else {
            this.setState({
                getData: false,
                searchData: false,
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    renderCommonForm = () => {

        if (this.state.getData && this.state.interestRateApiFlag) {
            return (
                <AccountDetails interestRate={this.state.inputData.interestRate}
                                transactionDate={this.state.inputData.transactionDate}
                                taxWaiverChange={this.state.inputData.taxWaiverChange}
                                preventialRateChange={this.state.inputData.preventialRateChange}
                                taxWaiverChanges={this.state.inputData.taxWaiverChanges}
                                preventialRateChangs={this.state.inputData.preventialRateChanges}
                                transactionID={this.state.inputData.transactionID}
                                clearingLimitDR={this.state.inputData.clearingLimitDR}
                                clearingLimitCR={this.state.inputData.clearingLimitCR}
                                TransferLimitCR={this.state.inputData.TransferLimitCR} tin={this.state.inputData.etin}
                                maturity={this.state.inputData.maturity} amount={this.state.inputData.availableAmount}
                                freeFlag1={this.props.freeFlag1} cbNumber={this.state.inputData.cbNumber}
                                classes={this.props}
                                casaAccountNumber={this.state.inputData.accountNumber}
                                accountNumber={this.state.inputData.debitAccountNumber} stopLoading={this.stopLoading}
                                create={true}
                                crmStatus={this.state.inputData.custStatus}/>
            )

        }

    };

    makeReadOnlyObject = (object) => {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            returnObjectVariables[i]["readOnly"] = true;
        }
        return returnObject;


    };

    checkReadOnlyField = (jsonForm) => {
        if (this.state.inputData.allFieldReadOnly === "YES") {
            return this.makeReadOnlyObject(jsonForm)
        } else {
            return jsonForm
        }
    };

    renderEditForm = () => {

        if (this.state.getData /*&& (this.props.freeFlag1 !== 'FDR Account Opening - with Existing CB Only' || this.state.cbDataMapping)*/) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.checkReadOnlyField(this.props.commonJsonForm), this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
            )

        }
        /*else if (this.props.subServiceType === 'Tag FDR Opening' || this.props.subServiceType === 'INDIVIDUAL' || this.props.subServiceType === "Individual A/C") {

           if (this.state.getData) {
               return (
                   CommonJsonFormComponent.renderJsonForm(this.state, tagAccountOpeningForm, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
               )
           }
       } */
        else if (this.props.serviceType === 'DPS Opening') {

            if (this.state.getData) {
                return (
                    CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
                )
            }
        }

    };

    renderEditFormLast = () => {

        if (this.state.getData /*&& (this.props.freeFlag1 !== 'FDR Account Opening - with Existing CB Only' || this.state.cbDataMapping)*/) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.checkReadOnlyField(this.props.commonJsonFormLast), this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
            )

        }
        /*else if (this.props.subServiceType === 'Tag FDR Opening' || this.props.subServiceType === 'INDIVIDUAL' || this.props.subServiceType === "Individual A/C") {

           if (this.state.getData) {
               return (
                   CommonJsonFormComponent.renderJsonForm(this.state, tagAccountOpeningForm, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
               )
           }
       } */
        else if (this.props.serviceType === 'DPS Opening') {

            if (this.state.getData) {
                return (
                    CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
                )
            }
        }

    };

    renderFlowSUmmeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton multiTaskLink={true} cbNumber={this.state.inputData.cbNumber}
                                   appId={this.props.appId}
                                   customerName={this.state.inputData.miscustomerNameLastName}
                                   accountNumber={this.props.accountNumber}
                                   solId={this.props.solId}/>
            )
        }
    };

    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Deferral Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Deferral Type", "Raise By", "Raise Date", "Expire Date", "Status"]}
                        tableData={this.state.getDeferalList}
                        tableAllign={['left', 'left']}
                    />
                    <br/>
                </div>

            )
        }

    };

    handleChange = (event) => {
        event.target.name = event.target.value
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };
    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    close = () => {
        this.props.closeModal();
    };
    renderSubmitButton = () => {
        if (this.state.getData && this.state.loading === false) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        //onClick={this.submitApprove}
                        onClick={(event) => this.handleCommon(event, "submitApprove")}

                    > Submit
                    </button>

                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleCommon(event, "handleSubmitSave")}
                    >Save
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        //type='button' value='add more'
                        // onClick={this.submitReturn}
                        onClick={(event) => this.handleCommon(event, "submitReturn")}

                    > Return
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    {this.state.inputData.allFieldReadOnly === "YES" &&  <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        //type='button' value='add more'
                        // onClick={this.submitReturn}
                        onClick={(event) => this.handleCommon(event, "submitClosed")}

                    > Final Approval
                    </button>}


                </div>

            )
        }
    };
    updateComponent = () => {
        this.forceUpdate();
    };
    mappingCropImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingCropImage: true
        })
    };

    getSignature = (event) => {
        event.preventDefault();
        this.setState({
            // selectImage:event.target.value,
            signetureModalBoolean: true
        })
    };
    viewImageModal = (event) => {
        event.preventDefault();
        this.setState({
            selectImage: event.target.value,
            imageModalBoolean: true
        })
    };
    closeModal = () => {
        this.setState({
            imageModalBoolean: false,
            signetureModalBoolean: false,
            getMappingCropImage: false,
            imageCropModal: false,
            errorModalBoolean: false,
            uploadModal: false,
            getMappingAllImage: false,

        })
    };

    renderSignatureCardCrop = () => {
        if (this.state.getData) {
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',
                    }}
                    type='button' value='add more'
                    onClick={this.cropImage}
                >Signature Card Crop
                </button>
            )
        }
    };
    renderCropedImage = () => {
        if (this.state.inputData.assignedCropedImage === true && this.state.getData) {
            return (
                <button
                    style={{}}
                    className="btn btn-outline-primary btn-sm"
                    onClick={this.mappingCropImage}>
                    Signature Card
                </button>
            )
        }
    };
    cropImage = (event) => {
        event.preventDefault();
        this.setState({
            imageCropModal: true
        })
    };
    mappingCropImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingCropImage: true
        })
    };

    renderRelatedPartyNumber = () => {
        return (
            <React.Fragment>
                <Grid item>
                    <label className="input-label-common">Related Party Number</label>
                </Grid>
                <Grid item>
                    <Autocomplete onChange={(event, option) => this.handleChangeRelatedPartyNumber(event, option)}
                                  defaultValue={this.state.inputData["numberOfRelatedParty"]}
                                  options={numberOfNominee} getOptionLabel={option => option.title}
                                  renderInput={(params) => <TextField {...params} variant="outlined"
                                                                      style={{paddingRight: 20}} fullWidth/>}
                    />
                </Grid>
            </React.Fragment>

        )
    };
    renderNomineeNumber = () => {
        if (this.state.getData && (this.props.freeFlag1 !== "DPS Account Opening -with Existing Operative Account" && this.state.inputData.nomineeType === "New Nominee Assignment" ) || (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account" && this.state.inputData.dpsnomineeType === "New Nominee Assignment")) {
            return (
                <React.Fragment>
                    <Grid item>
                        <label className="input-label-common">Number of Nominee</label>
                    </Grid>
                    <Grid item>
                        <Autocomplete onChange={(event, option) => this.handleChangeNomineNumber(event, option)}
                                      defaultValue={{title: this.state.inputData.numberOfNominee}}
                                      required={true}
                                      InputProps={{
                                          //style:Object.assign(styles,firstStyle),
                                          readOnly: this.state.inputData.allFieldReadOnly === "YES" ? true : false
                                      }}
                            //aria-readonly={this.checkReadOnlyField(}
                                      options={numberOfNominee} getOptionLabel={option => option.title}
                                      renderInput={(params) => <TextField {...params} variant="outlined"
                                                                          style={{paddingRight: 20}} fullWidth/>}
                        />
                    </Grid>
                </React.Fragment>

            )
        }

    };
    handleChangeNomineNumber = (event, option) => {
        event.preventDefault();
        if (option !== null) {
            this.state.inputData["numberOfNominee"] = option.title;
            this.forceUpdate()

        } else {
            this.state.inputData["numberOfNominee"] = option;
            this.forceUpdate()
        }

    };
    handleChangeRelatedPartyNumber = (event, option) => {
        event.preventDefault();
        if (option !== null) {
            this.state.inputData["numberOfRelatedParty"] = option.title;
            this.forceUpdate()

        } else {
            this.state.inputData["numberOfRelatedParty"] = option;
            this.forceUpdate()
        }

    };
    renderRelatedParty = () => {

        var sl;
        let objectForRelatedParty = [];
        for (var i = 0; i < this.state.inputData["numberOfRelatedParty"]; i++) {
            sl = i + 1;

            objectForRelatedParty.push(
                {
                    "type": "title",
                    "label": "RELATED PARTY  " + sl,
                    "grid": 12,
                },

                {
                    "varName": "relationType" + i,
                    "type": "autoCompleteValueReturn",
                    "enum": this.state.relationType,
                    "label": "Relation Type ",
                    "grid": 6,
                },

                {
                    "varName": "relationCode" + i,
                    "type": "autoCompleteValueReturn",
                    "enum": this.state.relationCode,
                    "label": "Relation Code",
                    "grid": 6,
                },

                {
                    "varName": "designationCode" + i,
                    "type": "autoCompleteValueReturn",
                    "enum": this.state.designationCode,
                    "label": "Designation Code",
                    "grid": 6,
                },
                //Nominee Information


                {
                    "varName": "cifId" + i,
                    "type": "text",
                    "label": "CIF ID",
                    "grid": 6,

                }
            )

        }

        return (
            CommonJsonFormComponent.renderJsonForm(this.state, objectForRelatedParty, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
        )

    };
    renderNomineeForm = () => {

        var sl;
        objectForNominee = [];
        if (this.state.inputData["numberOfNominee"] !== undefined && this.state.inputData["numberOfNominee"] !== null) {
            objectForNominee.push(
                {
                    "type": "title",
                    "label": "Nominee",
                    "grid": 12,
                },
                {
                    "varName": "nomineeRegNo",
                    "type": "text",
                    "label": "Registration No.",
                    "grid": 6,
                    "required": true,

                },
            )
        }

        for (var i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
            sl = i + 1;
            //inputData["nomineecifIdApiCall"+i]=false
            //varValue["nomineecifIdApiCall"+i]=false
            objectForNominee.push(
                {
                    "varName": "Nominee " + sl,
                    "type": "title",
                    "label": "Nominee  " + sl,
                    "grid": 12,
                },
                {
                    "varName": "search" + sl,
                    "type": "select",
                    "enum": [
                        "CIF ID",
                        "INFORMATION",
                    ],
                    "label": "Input CIF ID/Information ?",
                    "grid": 6,


                },
                {
                    "varName": "nomineecifId" + sl,
                    "type": "textApiCall",
                    "label": "CIF ID",
                    "grid": 6,
                    "conditional": true,
                    "conditionalVarName": "search" + sl,
                    "conditionalVarValue": "CIF ID",

                },

                {
                    "varName": "nomineeName" + sl,
                    "type": "text",
                    "label": "Nominee Name",
                    "grid": 6,
                    "multiline": true,
                    "readOnly": true,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    "conditionalArrayValue": ["CIF ID", true],
                },
                {
                    "varName": "nomineeName" + sl,
                    "type": "text",
                    "label": "Nominee Name",
                    "grid": 6,
                    required: true,
                    "multiline": true,
                    "conditional": true,
                    "conditionalVarName": "search" + sl,
                    "conditionalVarValue": "INFORMATION",

                },
                {
                    "varName": "nomineeRelationship" + sl,
                    "type": "autoCompleteValueReturn",
                    "enum": this.state.nomineeRelationShipCodeList,
                    "label": "Relationship",
                    "grid": 6,
                    "required": true,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    "conditionalArrayValue": ["CIF ID", true],


                },
                {
                    "varName": "nomineeRelationship" + sl,
                    "type": "autoCompleteValueReturn",
                    "enum": this.state.nomineeRelationShipCodeList,
                    "label": "Relationship",
                    "grid": 6,
                    "conditional": true,
                    "conditionalVarName": "search" + sl,
                    "conditionalVarValue": "INFORMATION",
                    required: true,

                },
                {
                    "varName": "percentageNominee" + sl,
                    "type": "text",
                    "label": "Percentage %",
                    "grid": 6,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    "conditionalArrayValue": ["CIF ID", true],
                    required: true,

                },
                {
                    "varName": "percentageNominee" + sl,
                    "type": "text",
                    "label": "Percentage %",
                    "grid": 6,
                    "conditional": true,
                    "conditionalVarName": "search" + sl,
                    "conditionalVarValue": "INFORMATION",
                    required: true,

                },

                {
                    "varName": "nomineeAddress1" + sl,
                    "type": "text",
                    "label": "Address 1 ",
                    "validationB": "length",
                    "maxLength": 45,
                    "grid": 6,
                    "multiline": true,
                    "readOnly": true,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    "conditionalArrayValue": ["CIF ID", true],
                },
                {
                    "varName": "nomineeAddress1" + sl,
                    "type": "text",
                    "label": "Address 1 ",
                    "validationB": "length",
                    "maxLength": 45,
                    "grid": 6,
                    required: true,
                    "multiline": true,
                    "conditional": true,
                    "conditionalVarName": "search" + sl,
                    "conditionalVarValue": "INFORMATION",
                },

                {
                    "varName": "nomineeAddress2" + sl,
                    "type": "text",
                    "label": "Address 2",
                    "validationB": "length",
                    "maxLength": 45,
                    "grid": 6,
                    "multiline": true,
                    "readOnly": true,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    "conditionalArrayValue": ["CIF ID", true],
                },
                {
                    "varName": "nomineeAddress2" + sl,
                    "type": "text",
                    "label": "Address 2",
                    "validationB": "length",
                    "maxLength": 45,
                    "grid": 6,
                    "multiline": true,
                    "conditional": true,
                    "conditionalVarName": "search" + sl,
                    "conditionalVarValue": "INFORMATION",
                },
                /*  {
                        "varName": "nomineenomineephotoIdNo" + i,
                        "type": "text",
                        "label": "NOMINEE PHOTO ID NO.",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "nomineecifIdApiCall" + i,
                        "conditionalVarValue": true,
                    },
    */
                {
                    "varName": "nomineeCityCode" + sl,
                    "type": "autoCompleteValueReturn",
                    "enum": this.state.city,
                    "label": "City Code",
                    "grid": 6,
                    "readOnly": true,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    "conditionalArrayValue": ["CIF ID", true],
                },
                {
                    "varName": "nomineeCityCode" + sl,
                    "type": "autoCompleteValueReturn",
                    "enum": this.state.city,
                    "label": "City Code",
                    "grid": 6,
                    required: true,
                    "conditional": true,
                    "conditionalVarName": "search" + sl,
                    "conditionalVarValue": "INFORMATION",
                },

                {
                    "varName": "nomineeStateCode" + sl,
                    "type": "autoCompleteValueReturn",
                    "enum": this.state.stateCode,
                    "label": "State Code",
                    "grid": 6,
                    "readOnly": true,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    "conditionalArrayValue": ["CIF ID", true],
                },
                {
                    "varName": "nomineeStateCode" + sl,
                    "type": "autoCompleteValueReturn",
                    "enum": this.state.stateCode,
                    "label": "State Code",
                    "grid": 6,
                    required: true,
                    "conditional": true,
                    "conditionalVarName": "search" + sl,
                    "conditionalVarValue": "INFORMATION",
                },

                {
                    "varName": "nomineePostalCode" + sl,
                    "type": "text",
                    "label": "Postal Code",
                    "grid": 6,
                    "validationB": "length",
                    "maxLength": 6,
                    required: true,
                    "conditional": true,
                    "conditionalVarName": "search" + sl,
                    "conditionalVarValue": "INFORMATION",
                },
                {
                    "varName": "nomineePostalCode" + sl,
                    "type": "text",
                    "label": "Postal Code",
                    "grid": 6,
                    "validationB": "length",
                    "maxLength": 6,
                    "readOnly": true,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    "conditionalArrayValue": ["CIF ID", true],
                },

                {
                    "varName": "nomineeCountry" + sl,
                    "type": "autoCompleteValueReturn",
                    "enum": this.state.country,
                    "label": "Country",
                    "grid": 6,
                    "readOnly": true,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    "conditionalArrayValue": ["CIF ID", true],
                },
                {
                    "varName": "nomineeCountry" + sl,
                    "type": "autoCompleteValueReturn",
                    "enum": this.state.country,
                    "label": "Country",
                    "grid": 6,
                    required: true,
                    "conditional": true,
                    "conditionalVarName": "search" + sl,
                    "conditionalVarValue": "INFORMATION",
                },

                {
                    "varName": "photoIdNumberRegNo" + sl,
                    "type": "text",
                    "label": "Photo ID Number/Reg No.",
                    "grid": 6,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    "conditionalArrayValue": ["CIF ID", true],
                },
                {
                    "varName": "photoIdNumberRegNo" + sl,
                    "type": "text",
                    "label": "Photo ID Number/Reg No.",
                    "grid": 6,
                    "conditional": true,
                    "conditionalVarName": "search" + sl,
                    "conditionalVarValue": "INFORMATION",
                },
                {
                    "varName": "nomineeDob" + sl,
                    "type": "date",
                    "label": "DOB",
                    "maxDate": true,
                    "grid": 6,
                    "readOnly": true,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    "conditionalArrayValue": ["CIF ID", true],
                },
                {
                    "varName": "nomineeDob" + sl,
                    "type": "date",
                    "label": "DOB",
                    "maxDate": true,
                    "grid": 6,
                    "onKeyDown": true,
                    "required": true,
                    "conditional": true,
                    "conditionalVarName": "search" + sl,
                    "conditionalVarValue": "INFORMATION",
                },


                {
                    "varName": "nomineeMinor" + sl,
                    "type": "select",
                    "label": "Nominee Minor",
                    "enum": ["YES", "NO"],
                    "grid": 6,
                    "readOnly": true,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    "conditionalArrayValue": ["CIF ID", true],
                    /*    "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "CIF ID",*/
                },
                {
                    "varName": "nomineeMinor" + sl,
                    "type": "select",
                    "label": "Nominee Minor",
                    "enum": ["YES", "NO"],
                    "grid": 6,
                    "readOnly": true,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                    "conditionalArrayValue": ["INFORMATION", true],
                    /*  "conditional": true,
                      "conditionalVarName": "search" + sl,
                      "conditionalVarValue": "INFORMATION",*/
                },
                {
                    "type": "title",
                    "label": "Guardian Details  " + sl,
                    "grid": 12,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                    "conditionalArrayValue": ["CIF ID", "YES"],
                },
                {
                    "varName": "nomineeGuardiansName" + sl,
                    "type": "text",
                    "label": "Guardians Name",
                    "grid": 6,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                    "conditionalArrayValue": ["CIF ID", "YES"],
                    "multiline": true,
                    "required": true,


                },

                {
                    "varName": "nomineeGuardianCode" + sl,
                    "type": "autoCompleteValueReturn",
                    "enum": this.state.getGuardianCode,
                    "label": "Guardian Code",
                    "grid": 6,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                    "conditionalArrayValue": ["CIF ID", "YES"],
                    "required": true,

                },
                {
                    "varName": "guardianPhotoId" + sl,
                    "type": "text",
                    "label": "Guardian Photo Id",
                    "grid": 6,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                    "conditionalArrayValue": ["CIF ID", "YES"],
                },

                {
                    "varName": "nomineeGuardianAddress" + sl,
                    "type": "text",
                    "label": "Address 1",
                    "validationB": "length",
                    "maxLength": 45,
                    "grid": 6,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                    "conditionalArrayValue": ["CIF ID", "YES"],
                    "multiline": true,
                    "required": true,
                },
                {
                    "varName": "nomineeGuardianAddress2" + sl,
                    "type": "text",
                    "label": "Address 2",
                    "validationB": "length",
                    "maxLength": 45,
                    "grid": 6,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                    "conditionalArrayValue": ["CIF ID", "YES"],
                    "multiline": true,
                },

                {
                    "varName": "nomineeGuardianCityCode" + sl,
                    "type": "autoCompleteValueReturn",
                    "enum": this.state.city,
                    "label": "City Code",
                    "grid": 6,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                    "conditionalArrayValue": ["CIF ID", "YES"],
                    "required": true,
                },

                {
                    "varName": "nomineeGuardianStateCode" + sl,
                    "type": "autoCompleteValueReturn",
                    "enum": this.state.stateCode,
                    "label": "State Code",
                    "grid": 6,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                    "conditionalArrayValue": ["CIF ID", "YES"],
                    "required": true,
                },

                {
                    "varName": "nomineeGuardianPostalCode" + sl,
                    "type": "text",
                    "label": "Postal Code",
                    "grid": 6,
                    "validationB": "length",
                    "maxLength": 6,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                    "conditionalArrayValue": ["CIF ID", "YES"],
                    "required": true,
                },

                {
                    "varName": "nomineeGuardianCountry2" + sl,
                    "type": "autoCompleteValueReturn",
                    "enum": this.state.country,
                    "label": "Country",
                    "grid": 6,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                    "conditionalArrayValue": ["CIF ID", "YES"],
                    "required": true,
                },

                //Information guardian details
                {
                    "type": "title",
                    "label": "Guardian Details  " + sl,
                    "grid": 12,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                    "conditionalArrayValue": ["INFORMATION", "YES"],
                },
                {
                    "varName": "nomineeGuardiansName" + sl,
                    "type": "text",
                    "label": "Guardians Name",
                    "grid": 6,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                    "conditionalArrayValue": ["INFORMATION", "YES"],
                    "multiline": true,
                    "required": true,


                },

                {
                    "varName": "nomineeGuardianCode" + sl,
                    "type": "autoCompleteValueReturn",
                    "enum": this.state.getGuardianCode,
                    "label": "Guardian Code",
                    "grid": 6,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                    "conditionalArrayValue": ["INFORMATION", "YES"],
                    "required": true,

                },
                {
                    "varName": "guardianPhotoId" + sl,
                    "type": "text",
                    "label": "Guardian Photo Id",
                    "grid": 6,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                    "conditionalArrayValue": ["INFORMATION", "YES"],
                },

                {
                    "varName": "nomineeGuardianAddress" + sl,
                    "type": "text",
                    "label": "Address 1",
                    "validationB": "length",
                    "maxLength": 45,
                    "grid": 6,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                    "conditionalArrayValue": ["INFORMATION", "YES"],
                    "multiline": true,
                    "required": true,
                },
                {
                    "varName": "nomineeGuardianAddress2" + sl,
                    "type": "text",
                    "label": "Address 2",
                    "validationB": "length",
                    "maxLength": 45,
                    "grid": 6,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                    "conditionalArrayValue": ["INFORMATION", "YES"],
                    "multiline": true,
                },

                {
                    "varName": "nomineeGuardianCityCode" + sl,
                    "type": "autoCompleteValueReturn",
                    "enum": this.state.city,
                    "label": "City Code",
                    "grid": 6,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                    "conditionalArrayValue": ["INFORMATION", "YES"],
                    "required": true,
                },

                {
                    "varName": "nomineeGuardianStateCode" + sl,
                    "type": "autoCompleteValueReturn",
                    "enum": this.state.stateCode,
                    "label": "State Code",
                    "grid": 6,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                    "conditionalArrayValue": ["INFORMATION", "YES"],
                    "required": true,
                },

                {
                    "varName": "nomineeGuardianPostalCode" + sl,
                    "type": "text",
                    "label": "Postal Code",
                    "grid": 6,
                    "validationB": "length",
                    "maxLength": 6,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                    "conditionalArrayValue": ["INFORMATION", "YES"],
                    "required": true,
                },

                {
                    "varName": "nomineeGuardianCountry2" + sl,
                    "type": "autoCompleteValueReturn",
                    "enum": this.state.country,
                    "label": "Country",
                    "grid": 6,
                    "conditionalArray": true,
                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                    "conditionalArrayValue": ["INFORMATION", "YES"],
                    "required": true,
                },
            )

        }
        if (this.state.getData &&  (this.props.freeFlag1 !== "DPS Account Opening -with Existing Operative Account" && this.state.inputData.nomineeType === "New Nominee Assignment" ) || (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account" && this.state.inputData.dpsnomineeType === "New Nominee Assignment")) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.checkReadOnlyField(objectForNominee), this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
            )
        }


    };

    getTaggingData = (index, data, customerNo) => {

        /*  console.log(index)
          console.log(data)
          console.log(customerNo);
          let customerList=[];
          customerList.push(data);
          customerList.push(customerNo);
          this.state.getTaggingList["customer"+index] = customerList;
          //this.state.getTaggingCustomerList[index] = customerNo;
          this.setState({
              tagClick: true
          })
          console.log(this.state.getTaggingList);*/
        let tableArrayData = [];
        let object = [];

        object.push("cbNumber", "relatedPartyType", "relatedCbTaggingrelationCode", "requestCharacter", "person");
        let cbTagUrl = backEndServerURL + "/secondaryDataCB/" + this.props.appId;
        axios.post(cbTagUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((data, index) => {
                    tableArrayData.push(this.createTaggingData(data));
                });
                this.setState({
                    getTagList: tableArrayData,

                });

                this.forceUpdate();
            })
            .catch((error) => {
                console.log(error);
            })

    };
    renderDedupComponent = () => {
        if (this.state.getData && this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" /* && (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C")*/) {
            return (
                <div style={{marginTop: "15px"}}>
                    <DedupResultFunction WithJointCb={this.state.inputData.WithJointCb} freeFlag1={this.props.freeFlag1}
                                         fdrDps={true}
                                         customerName={this.props.customerName}
                                         accountNumber={this.props.accountNumber} solId={this.props.solId}
                                         freeFlag4={this.props.freeFlag4}

                                         introducerId={this.state.inputData.primryintroducerCustomerIdCifId}
                                         introducerName={this.state.inputData.primryiintroducerNameLastName}
                                         radioButton="NO" category={this.props.category} taggingForm={CBDataJsonForm}
                                         removingTaggingData={this.removingTaggingData}
                                         renderTagging={this.renderTagging}
                                         getTaggingData={this.getTaggingData}
                                         serviceType={this.props.serviceType}
                                         subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        } else if (this.state.getData   /* && (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C")*/) {
            return (
                <div style={{marginTop: "15px"}}>
                    <DedupResultFunction WithJointCb={true} freeFlag1={this.props.freeFlag1}
                                         fdrDps={true}
                                         customerName={this.props.customerName}
                                         accountNumber={this.props.accountNumber} solId={this.props.solId}
                                         freeFlag4={this.props.freeFlag4}
                                         introducerId={this.state.inputData.primryintroducerCustomerIdCifId}
                                         introducerName={this.state.inputData.primryiintroducerNameLastName}
                                         category={this.props.category} taggingForm={CBDataJsonForm}
                                         removingTaggingData={this.removingTaggingData}
                                         renderTagging={this.renderTagging}
                                         serviceType={this.props.serviceType}
                                         getTaggingData={this.getTaggingData}
                                         subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };
    renderSdnComponent = () => {
        if (this.state.getData) {
            return (
                <div style={{marginTop: "15px"}}>
                    <SdnResultFunction freeFlag1={this.props.freeFlag1} customerName={this.props.customerName}
                                       accountNumber={this.props.accountNumber} solId={this.props.solId}
                                       freeFlag4={this.props.freeFlag4}
                                       introducerId={this.state.inputData.primryintroducerCustomerIdCifId}
                                       introducerName={this.state.inputData.primryiintroducerNameLastName}
                                       category={this.props.category} taggingForm={CBDataJsonForm}
                                       removingTaggingData={this.removingTaggingData}
                                       getTaggingData={this.getTaggingData}
                                       serviceType={this.props.serviceType}
                                       subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };
    tagCbDetailsModalClose = () => {
        this.setState({
            tagCbDetailsModal: false,
            jointCbtagCbDetailsModal: false,

        })
    };
    tagCbDetailsModal = (event, customer, cb, relatedPartyType, relationCode, requestCharacter, requestType) => {

        event.preventDefault();
        if (requestCharacter === "true") {
            this.setState({
                editMode: true,
                jointCbtagCbDetailsModal: true,
                tagCb: cb,
                relatedPartyType: relatedPartyType,
                customerNumber: customer,
                linkrelationCode: relationCode,
                requestType: requestType
            })
        } else {
            this.setState({
                editMode: true,
                tagCbDetailsModal: true,
                tagCb: cb,
                relatedPartyType: relatedPartyType,
                customerNumber: customer,
                linkrelationCode: relationCode,
                requestType: requestType
            })
        }


    };
    tagCbDelete = (event, id) => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        let url = backEndServerURL + "/secondaryCB/delete/" + id;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let tableArrayData = [];
                let cbTagUrl = backEndServerURL + "/secondaryCB/" + this.props.appId;
                axios.get(cbTagUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);
                        response.data.map((data, index) => {
                            tableArrayData.push(this.createTaggingData(data));
                        });
                        this.setState({
                            loading: false,
                            getTagList: tableArrayData,

                        });

                        this.forceUpdate();
                    })
                    .catch((error) => {
                        this.setState({
                            loading: false,
                        });
                        console.log(error);
                    })
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                });
                console.log(error);
            })

    };
    createTaggingData = (data) => {
        console.log(data);
        return (
            [data.cbNumber, data.customerName, data.relatedCbTaggingrelationCode, <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'right',
                }}
                type='button' value='add more'
                onClick={(event) => this.tagCbDetailsModal(event, data.person, data.cbNumber, data.relatedPartyType, data.relatedCbTaggingrelationCode, data.requestCharacter, data.requestType)}
            >Edit
            </button>,
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',
                    }}
                    type='button' value='add more'
                    onClick={(event) => this.tagCbDelete(event, data.id)}
                >Delete
                </button>]


        )
    };

    renderTagging = () => {
        let tableArrayData = [];
        let object = [];
        object.push("cbNumber", "relatedPartyType", "relatedCbTaggingrelationCode", "requestCharacter", "person", "requestType");
        let cbTagUrl = backEndServerURL + "/secondaryDataCB/" + this.props.appId;
        axios.post(cbTagUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((data, index) => {
                    tableArrayData.push(this.createTaggingData(data));

                });
                this.setState({
                    getTagList: tableArrayData
                });
                this.forceUpdate();
            })
            .catch((error) => {
                console.log(error);
            })


    };


    renderTagList = () => {

        if (this.state.getData && this.state.getTagList.length > 0) {

            return (
                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Link CB List</h4>

                        </CardHeader>
                    </paper>
                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["CB Number", "Customer Name", "Relation Code", "Action"]}

                        tableData={this.state.getTagList}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />
                    <br/>


                </div>

            )
        }

    };
    renderDedupComponentMandateAndBeneficiary = () => {
        if (this.state.getData) {
            return (
                <div style={{marginTop: "15px"}}>
                    <MandateAndBeneficiaryResult WithJointCb={this.state.inputData.WithJointCb}
                                                 fdrDps={true}
                                                 freeFlag1={this.props.freeFlag1} customerName={this.props.customerName}
                                                 accountNumber={this.props.accountNumber} solId={this.props.solId}
                                                 freeFlag4={this.props.freeFlag4}
                                                 introducerId={this.state.inputData.primryintroducerCustomerIdCifId}
                                                 introducerName={this.state.inputData.primryiintroducerNameLastName}
                                                 category={this.props.category} taggingForm={CBDataJsonForm}
                                                 removingTaggingData={this.removingTaggingData}
                                                 getTaggingData={this.getTaggingData}
                                                 serviceType={this.props.serviceType}
                                                 subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };
    closeStatement = () => {
        this.setState({
            accountStatement: false
        })
    };
    renderTaggingEdit = () => {
        if (this.state.getData) {
            return (
                <SecondaryCbFormDetails freeFlag1={this.props.freeFlag1}
                    // editMode={this.state.editMode}
                                        customerName={this.props.customerName}
                                        accountNumber={this.props.accountNumber}
                                        solId={this.props.solId}
                                        freeFlag4={this.props.freeFlag4}
                                        relationCode={this.state.linkrelationCode}
                                        customerNumber={this.state.customerNumber}
                                        tagingModalCbnumber={this.state.customerNumber}
                                        appId={this.props.appId}
                                        requestType={this.state.requestType}
                                        makerEnd={true}
                                        relationCode={this.state.linkrelationCode}
                    //customerNumber={this.state.tagCb}
                                        tagCb={this.state.tagCb}
                                        cbNumber={this.state.tagCb}
                                        tagForm={CBDataJsonForm}
                                        renderTagging={this.renderTagging}
                                        taging={this.renderTagging}
                                        serviceType={this.props.serviceType}
                                        closeModal={this.tagCbDetailsModalClose}/>
            )
        }
    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };
    renderUploadButton = () => {
        if (this.state.getData) {
            return (

                <button

                    style={{
                        width: 100,

                    }}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.uploadModal}

                >
                    Upload File
                </button>
            )
        }
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    renderAssignedImage = () => {
        if (this.state.getData) {
            return (
                <button
                    style={{
                        width: 120,

                    }}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.mappingAllImage}

                >
                    Preview Document
                </button>
            )
        }

    };
    submitModal = (data) => {
        this.state.inputData.previewImage = true;
        this.updateComponent();
        this.setState({
            uploadModal: false,
            getMappingAllImage: false,
        })

    };
    renderMappingImageEditModal = () => {


        return (
            <FileMappingEdit dropDownOption={arrayListIndividual}
                             appId={this.props.appId}
                             submitModal={this.submitModal}
                             closeModal={this.closeModal}/>
        )

    };
    renderMappingImageModal = () => {
        return (
            <FileMapping dropDownOption={arrayListIndividual}
                         submitModal={this.submitModal}
                         appId={this.props.appId}
                         closeModal={this.closeModal}/>
        )

    };

    render() {
        const {classes} = this.props;
        {
            Functions.redirectToLogin(this.state)
        }
        return (

            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        {this.renderNotification()}
                        <CardBody>
                            <GridList cellHeight={window.innerHeight} cols={1}>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.imageCropModal}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ImageCrop subServiceType={this.props.subServiceType} appId={this.props.appId}
                                                   closeModal={this.closeModal}/>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.loading}>
                                    <DialogContent className={classes.dialogPaper}>

                                        <center>
                                            <img src={loader} alt=""/>
                                        </center>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    open={this.state.uploadModal}>
                                    <DialogContent>
                                        {this.renderMappingImageModal()}

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    open={this.state.getMappingAllImage}>
                                    <DialogContent>
                                        {this.renderMappingImageEditModal()}

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.getMappingCropImage}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <AssignedCropImage subServiceType={this.props.subServiceType}
                                                           appId={this.props.appId} closeModal={this.closeModal}/>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.confirmAlert}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ConfirmAlert validationForHandleConfirm={this.validationForHandleConfirm}
                                                      closeModal={this.closeConfirmAlert}/>

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    fullScreen={true}
                                    open={this.state.tagCbDetailsModal}>
                                    <DialogContent className={classes.dialogPaper}>
                                        {this.renderTaggingEdit()}
                                    </DialogContent>

                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    fullScreen={true}
                                    open={this.state.jointCbtagCbDetailsModal}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <SecondaryJointCbTagging freeFlag1={this.props.freeFlag1}
                                                                 customerName={this.props.customerName}
                                                                 accountNumber={this.props.accountNumber}
                                                                 solId={this.props.solId}
                                                                 freeFlag4={this.props.freeFlag4}
                                                                 relationCode={this.state.linkrelationCode}
                                                                 customerNumber={this.state.customerNumber}
                                                                 tagingModalCbnumber={this.state.customerNumber}
                                                                 appId={this.props.appId}
                                                                 makerEnd={true}
                                                                 tagCb={this.state.tagCb}
                                                                 cbNumber={this.state.tagCb}
                                                                 tagForm={CBDataJsonForm}
                                                                 renderTagging={this.renderTagging}
                                                                 taging={this.renderTagging}
                                                                 serviceType={this.props.serviceType}
                                                                 closeModal={this.tagCbDetailsModalClose}/>
                                    </DialogContent>

                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="md"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.history}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <SubmittedCaseHistory multiTaskLink={true}
                                                              customerName={this.state.customerName}
                                                              accountNumber={this.state.accountNumber}
                                                              solId={this.state.solId} globalSearch={true}
                                                              closeIcon={this.closeHistoryModal}
                                                              appId={this.state.appId}
                                                              closeModal={this.closeHistoryModal}/>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.errorModalBoolean}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ErrorModal title={this.state.errorTittle} closeModal={this.closeModal}
                                                    subServiceType={this.props.subServiceType} appId={this.props.appId}
                                        />

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.accountStatement}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <AccountStatement closeModal={this.closeStatement}
                                                          accountNumber={this.state.inputData.debitAccountNumber}/>
                                    </DialogContent>
                                </Dialog>

                                <div>
                                    <Grid container spacing={1}>
                                        <ThemeProvider theme={theme}>
                                            {/*{this.renderSericeForm()}*/}
                                            <Grid item xs={12}>
                                                {this.renderDownLoadFileLink()}
                                            </Grid>

                                            {this.renderCommonForm()}
                                            <Grid item xs={12}>
                                                {this.renderSignatureAndDebitAccount()}
                                            </Grid>
                                            {this.renderMisRelatedForm()}
                                            {this.renderEditForm()}
                                            {/*   <Grid item xs={6}>
                                            {this.renderRelatedPartyNumber()}
                                        </Grid>

                                            {this.renderRelatedParty()}
*/}
                                            <Grid item xs={6}>
                                                {this.renderNomineeNumber()}
                                            </Grid>
                                            <Grid item xs={6}>
                                            </Grid>
                                            {this.renderNomineeForm()}

                                            {this.renderEditFormLast()}
                                            {this.renderTPandKYCForm()}

                                            <Grid item xs={12}>
                                                <DeferalList appId={this.props.appId}/>
                                            </Grid>
                                            <Grid item={12}>

                                                <WaiverList appId={this.props.appId}/>
                                            </Grid>
                                            <Grid item xs='12'>
                                                {this.renderRemarksData()}
                                            </Grid>

                                        </ThemeProvider>
                                        <Grid item xs='12'>
                                            {this.renderDedupComponent()}
                                        </Grid>

                                        <Grid item xs='12'>
                                            {this.renderDedupComponentMandateAndBeneficiary()}
                                        </Grid>
                                        <Grid item xs='12'>
                                            {this.renderTagList()}
                                        </Grid>
                                        <Grid item xs='12'>
                                            {this.renderSdnComponent()}
                                        </Grid>

                                        <Grid item xs='12'>
                                            {this.renderUploadButton()}
                                            &nbsp;
                                            {this.renderAssignedImage()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.renderFlowSUmmeryButton()}
                                        </Grid>

                                        <br/>
                                        <ThemeProvider theme={theme}>
                                            {this.renderRemarks()}
                                        </ThemeProvider>
                                    </Grid>
                                    <br/>

                                    {this.renderSubmitButton()}
                                </div>
                            </GridList>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}

export default withStyles(styles)(OpeningSDM);
