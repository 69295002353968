import {assetOpsBackEndServerURL} from "../../../Common/Constant";
import axios from "axios";

class AssetCommonFunctions {
    static getLoanAccountDetails(cbNumber, loanAccountNumber) {
        let loanTypeUrl = assetOpsBackEndServerURL + "/getLoanAccountDetails/" + cbNumber + "/" + loanAccountNumber;
        return new Promise((resolve, reject) => {
            axios.get(loanTypeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }

    static getCustLoanAccountDetails(postData) {
        let url = assetOpsBackEndServerURL + "/custLoanAccountDetails";
        return new Promise((resolve, reject) => {
            axios.post(url, postData, {
                headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,},
            })
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }

    static custODAccountDetails(cb, odAccount) {
        let url = assetOpsBackEndServerURL + "/custODAccountDetails/"+cb+"/"+odAccount;
        return new Promise((resolve, reject) => {
            axios.get(url, {
                headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,},
            })
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }
    static schemeCodeAllowedForSecuredOD(schemeCode) {
        let url = assetOpsBackEndServerURL + "/schemeCodeAllowedForSecuredOD/"+schemeCode;
        return new Promise((resolve, reject) => {
            axios.get(url,  {
                headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,},
            })
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }

    static doRoute(postData,appId) {
        let url = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + appId;
        return new Promise((resolve, reject) => {
            axios.post(url, postData, {
                headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,},
            })
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }
}

export default AssetCommonFunctions;