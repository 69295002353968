import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../../Static/css/RelationShipView.css";
import {assetOpsBackEndServerURL, frontEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Table from "../../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import Notification from "../../../NotificationMessage/Notification";
import loader from "../../../../Static/loader.gif";
import AssignedCropImage from "../../CASA/AssignedCropImage";
import ErrorModal from "../Common/ErrorModal";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";


const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

const jsonForm = [

    {
        varName: "customerCB",
        type: "text",
        label: "Customer CB",
        required: true,
        readOnly: true,
        grid: 4,
    },


    // {
    //     varName: "serviceType",
    //     type: "select",
    //     label: "Service Type",
    //     required: true,
    //     readOnly:true,
    //     grid: 4,
    //     "enum": [
    //         "A",
    //         "B",
    //     ]
    // },


    {
        varName: "loanAccounts",
        type: "text",
        label: "Loan Accounts",
        readOnly: true,
        grid: 4,

    },


    {
        varName: "loanType",
        type: "test",
        label: "Loan Type",
        required: true,
        readOnly: true,
        grid: 4,
    },

    {
        varName: "closedBy",
        type: "select",
        label: "Closed By",
        required: true,
        // readOnly:true,
        grid: 4,
        "enum": [
            "FD Encashment",
            "Cash",
        ]
    },


    {
        "varName": "casaAccount",
        "type": "text",
        "label": "Casa Account",
        readOnly: true,
        "grid": 4
    },


    {
        varName: "casaAccountStatus",
        type: "text",
        label: "Casa Account Status",
        readOnly: true,
        grid: 4,
    },


    {
        varName: "loanAccountBalance",
        type: "text",
        label: "Loan Account Balance",
        readOnly: true,
        grid: 4,

    },


    {
        "varName": "dailyInterest",
        "type": "text",
        "label": "Daily Interest",
        readOnly: true,
        "grid": 4
    },


    {
        varName: "loanAccountExpiryDate",
        type: "text",
        label: "Loan Account Expiry Date",
        readOnly: true,
        grid: 4,

    },


    {
        varName: "casaBalance",
        type: "text",
        label: "Casa Balance",
        readOnly: true,
        grid: 4,
    },


    {
        "varName": "earlySettlementFee",
        "type": "text",
        "label": "Early Settlement Fee",
        readOnly: true,
        "grid": 4
    },


    {
        varName: "vat",
        type: "text",
        label: "VAT(15% of ESF)",
        readOnly: true,
        grid: 4,

    },


    {
        varName: "exciseDuty",
        type: "text",
        label: "Excise Duty",
        readOnly: true,
        grid: 4,
    },


    {
        "varName": "waiver",
        "type": "text",
        "label": "Waiver",
        readOnly: true,
        "grid": 4
    },


    //Loan Category

    {
        "varName": "loanCategory",
        "type": "select",
        "required": true,
        "label": "Loan Category",
        "grid": 4,
        "enum": [
            "OD",
            "EMI",
        ]
    },

    {
        type: "empty",
        grid: 12,
    },


];

let closingMakerRemarks = [
    {
        "varName": "closingMakerRemarks",
        "type": "textArea",
        "label": "Closing Maker Remarks",
        "grid": 12
    }]
;


class GenerateEncashmentRequest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            passingData: '',
            loading: true,
            setOpen: false,
            loader: null,
            uploadImageModal: false,
            uploadPrintModal: false,
            imageLink: {},
            tableData: [],
            sourceMappingData: [],
            getsearchValue: [],
            getCustomerId: '',
            getAccountType: '',
            accountOpeningFromModal: false,
            newAcoountOpeningModal: false,
            searchTableData: false,
            dataNotFound: false,
            CustomerModal: false,
            uniqueId: '',
            IDENTIFICATION_NO: '',
            id: '',
            anchorEl: null,
            anchorE2: null,
            individualDropdownOpen: null,
            SelectedDropdownSearchData: null,
            csDeferralPage: "",
            getDeferralList: [],
            getDocument: false,
            digitTinChange: false,
            titleChange: false,
            nomineeChange: false,
            updateChangePhotoId: false,
            contactNumberChange: false,
            emailAddressChange: false,
            estatementEnrollment: false,
            addressChange: false,
            otherInformationChange: false,
            signatureCard: false,
            dormantAccountActivation: false,
            dormantAccountDataUpdate: false,
            schemeMaintenanceLinkChange: false,
            schemeMaintenance: false,
            mandateUpdateChange: false,
            cityLive: false,
            projectRelatedDataUpdateADUP: false,
            accountSchemeClose: false,
            lockerSIOpen: false,
            lockerSIClose: false,
            others: false,
            bearerApproval: '',
            csBearer: '',
            // accountNumber:'',

            routeCase: false,
            accountNumberNew: false,
            inputData: {
                closing_maker_approved: '',
            },

            labelOpen: false,
            labelName: "",
            selectImage: "",
            imageModalBoolean: false,
            imgeListLinkSHow: false,
            getImageLink: [],
            getImageBoolean: false,
            SelectedData: false,
            csDeferalPage: "",
            values: [],
            appId: this.props.match.params.appId,
            csDataCapture: '',
            message: "",
            appData: {},
            getData: false,
            getNewCase: false,
            varValue: {},
            caseId: "",
            title: "",
            notificationMessage: "",
            app_uid: "-1",
            alert: false,
            redirectLogin: false,
            type: [],
            dueDate: '',
            selectedDate: {},
            dropdownSearchData: {},
            AddDeferal: false,
            debitCard: "",
            showValue: false,
            getDeferalList: [],
            deferalNeeded: false,
            uploadModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            loaderNeeded: null,
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            uploadButtonClick: false,
            returnData: false,
            returnDataBm: false,
            getNumberofNominee: false,
            getNumberofExistingNominee: false,
            objectForNominee: [],
            objectForExistingNominee: [],
            getNominee: false,
            getExistingNominee: false,
            numberOfMultipleSelect: 0,
            croppedImage: false,
            fileUploadData: {},
            submitButtonClick: false,
            errorModal: false


        }

    }


    // componentDidUpdate(prevProps) {
    //     if (this.props.match.params.appId !== prevProps.match.params.appId) {
    //         console.log("working....");
    //         this.setState({
    //             appId: this.props.match.params.appId,
    //             loading: true,
    //         });
    //     }
    // }
    renderFlowSUmmeryButton = () => {
        console.log(this.state)
        console.log(this.props)
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.customerCB} appId={this.state.appId}
                                   caseId={this.props.caseId}
                                   customerName={this.state.inputData.customerName} accountNumber={this.state.inputData.accountNumber}
                                   category={this.state.inputData.category}
                                   subCategory={this.state.inputData.subCategory}
                                   solId={this.props.solId}/>
            )
        }
    };

    componentDidMount() {
        console.log("i am here");
        console.log(this.state.appId);

        var remarksArray = [];

        if (this.state.appId !== undefined) {


            let getCommentsUrl = assetOpsBackEndServerURL + "/appRemarkGet/" + this.state.appId;
            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                console.log(response.data);
                response.data.map((data) => {
                    if (data.remarks !== 'undefined') {
                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                    }
                });
                this.setState({
                    getRemarks: remarksArray
                })
            })
                .catch((error) => {
                    console.log(error)
                });

            let url = assetOpsBackEndServerURL + '/variables/' + this.state.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log("get input Data");
                    console.log(response.data);
                    this.setState({
                        varValue: this.removeNullValue(response.data),
                        inputData: this.removeNullValue(response.data),
                        appData: response.data,
                        getData: true,
                        showValue: true,
                        loading: false
                    });


                    let imageUrl = assetOpsBackEndServerURL + "/case/files/" + this.state.appId;
                    axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response1) => {
                            console.log(response1.data);
                            this.setState({
                                getImageLink: response1.data,
                                getImageBoolean: true
                            })
                            /*let getCommentsUrl = assetOpsBackEndServerURL + "/appRemarkGet/" + this.state.appId;
                            axios.get(getCommentsUrl, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
                                .then((response) => {

                                    console.log(response.data);
                                    response.data.map((data) => {

                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                    })
                                    this.setState({
                                        getRemarks: remarksArray
                                    })
                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.setState({
                                        loading: false
                                    })
                                })


                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false
                            })
                        })
                    this.setState({
                        getDeferalList: tableArray
                    })*/

                        })
                        .catch((error) => {
                            console.log(error);
                        })


                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })

                });

        }


    }

    handleClose = () => {
        this.setState({
            setOpen: false
        })

    };

    updateComponent = () => {
        this.forceUpdate();
    };


    renderForm = () => {
        return (

            CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent)

        )

    };


    viewImageModal = (event) => {
        event.preventDefault();

        this.setState({
            selectImage: event.target.value,
            imageModalBoolean: true
        })


    };


    accountDetailsModal = () => {

        this.setState({
            accountDetailsModal: false
        });
        this.closeModal();
    };
    renderImageLink = () => {

        if (this.state.imgeListLinkSHow && this.state.getImageLink !== undefined) {

            return (
                this.state.getImageLink.map((data) => {
                    return (
                        <Grid item={6}>
                            <button type="submit" value={data} onClick={this.viewImageModal}>{data}</button>
                        </Grid>
                    )
                })

            )


        } else if (this.state.getImageBoolean) {

            return (
                this.state.getImageLink.map((data) => {
                    return (

                        <Grid item={6}>
                            <button type="submit" value={data} onClick={this.viewImageModal}>{data}</button>
                        </Grid>

                    )
                })


            )
        }


    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };


    closeUploadModal = (data) => {
        this.setState({
            uploadButtonClick: true,
            uploadModal: false,
            getMappingAllImage: false,
        })
    };
    closeCroppedModal = () => {
        this.setState({
            croppedImage: false
        })
    };
    handleChangeCroppedImage = (event) => {
        event.preventDefault();
        this.setState({
            croppedImage: true
        })
    };
    renderCroppedImage = () => {
        if (this.state.inputData["signatureCard"] === true) {
            return (
                <Grid item xs={2}>

                    <button
                        style={{
                            marginTop: "18px"
                        }}
                        className="btn btn-outline-primary btn-sm"

                        onClick={this.handleChangeCroppedImage}

                    >
                        Signature Card
                    </button>
                </Grid>
            )
        }
    };

    commonData = () => {


        return this.renderForm()


    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={"Route to BM"}
                              message={"Successfully Routed"}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    renderEncashmentRequestButton = () => {
        if (this.state.inputData.closedBy === "FD Encashment") {
            return (
                // <div>
                //     <Button style={{
                //         backgroundColor: "red",
                //         color: "white",
                //         paddingLeft: 10,
                //         paddingRight: 10,
                //         paddingTop: 3,
                //         paddingBottom: 3,
                //         borderRadius: 3,
                //         border: 1,
                //         textDecoration: "none"
                //     }} onClick ={this.detailsView()}>Generate Encashment Request</Button>&nbsp;&nbsp;
                // </div>
                <Grid item xs={4}>
                    <a onClick={() => {
                        this.detailsView()
                    }}>
                        <label
                            style={{
                                backgroundColor: "red",
                                color: "white",
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 3,
                                paddingBottom: 3,
                                borderRadius: 3,
                                border: 1,
                                textDecoration: "none",
                                cursor: "pointer"
                            }}><font size="2"><b> Generate Encashment Request</b></font></label>

                    </a>
                </Grid>
            )
        }
    };

    detailsView = () => {
        let url = "/GenerateEncashmentRequest/" + this.state.appId;
        window.open(frontEndServerURL + url)
    };

    handleSubmitButton = () => {
        if (this.state.getData) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                    >padding....
                    </button>
                </div>


            )
        }
    };

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    renderFormTable = () => {
        return (
            <div style={{padding: "10px 5px 20px", width: '100%'}}>
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <table className="assetTable" style={{borderCollapse: 'collapse', "width": "100%"}}>
                        <tr>
                            <th scope="row">Product Name</th>
                            <td>{this.state.inputData.productName}</td>
                            <th scope="row">Loan Amount</th>
                            <td>{this.state.inputData.loanAmt}</td>
                        </tr>
                        <tr>
                            <th scope="row">Scheme Code</th>
                            <td>{this.state.inputData.schemeCode}</td>
                            <th scope="row">Pay Of Balance</th>
                            <td>{this.state.inputData.loanAccountBalance}</td>
                        </tr>
                        <tr>
                            <th scope="row">Date of Disbursement</th>
                            <td>{this.state.inputData.disbursementDate}</td>
                            <th scope="row">Early Settlement Fee</th>
                            <td>{this.state.inputData.earlySettlementFee}</td>
                        </tr>
                        <tr>
                            <th scope="row">Loan Expiry Date</th>
                            <td>{this.state.inputData.loanAccountExpiryDate}</td>
                            <th scope="row">VAT</th>
                            <td>{this.state.inputData.vat}</td>
                        </tr>
                        <tr>
                            <th scope="row">Maturity Status</th>
                            <td>{this.state.inputData.maturityStatus}</td>
                            <th scope="row">Excise Duty</th>
                            <td>{this.state.inputData.exciseDuty}</td>
                        </tr>

                        <tr>
                            <th scope="row">Total Settlement Amount</th>
                            <td>{this.state.inputData.totalAmount}</td>
                            <th scope="row">CASA BALANCE</th>
                            <td>{this.state.inputData.casaBalance}</td>
                        </tr>

                    </table>
                </div>
            </div>
        )
    };


    close = () => {
        this.props.closeModal();
    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        });
        this.closeModal();
    };
    uploadPrintModal = () => {
        this.setState({
            uploadPrintModal: true
        });
        this.closeModal();
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };

    renderRemarks = () => {


        return (

            CommonJsonFormComponent.renderJsonForm(this.state, closingMakerRemarks, this.updateComponent)

        )

    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{width: '100%'}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    };


    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            //let i=0;
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <Button target={"_blank"}
                                href={assetOpsBackEndServerURL + "/file/" + data + '/' + sessionStorage.getItem("accessToken")}
                                style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    borderRadius: 3,
                                    border: 1,
                                    textDecoration: "none"
                                }}
                                target={"_blank"}>{splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </Button>&nbsp;&nbsp;
                    </React.Fragment>
                )
            })
        }
    };

    closeErrorModal = () => {
        this.setState({
            errorModal: false
        })
    };

    render() {

        console.log("inputData");
        console.log(this.state.varValue);
        console.log(this.state.inputData);
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <Card>

                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.croppedImage}>
                        <DialogContent className={classes.dialogPaper}>
                            <AssignedCropImage closeModal={this.closeCroppedModal} appId={this.state.appId}/>

                        </DialogContent>
                    </Dialog>

                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >
                            Closing Maker Secured Loan Closing</h4>
                    </CardHeader>

                    <CardBody>

                        {this.renderNotification()}


                        <br/>

                        <ThemeProvider theme={theme}>
                            <Grid container spacing={1}>

                                {this.commonData()}
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.errorModal}>
                                    <DialogContent className={classes.dialogPaper}>

                                        <ErrorModal
                                            closeModal={this.closeErrorModal}
                                            title={this.state.title}
                                        />
                                    </DialogContent>
                                </Dialog>

                                <Grid item xs={12}></Grid>

                                {this.mappingPhoto()}

                                <Grid item xs={12}></Grid>

                                {this.renderFormTable()}


                                <br/>
                                {this.renderFlowSUmmeryButton()}
                                <br/>
                                {this.renderRemarksData()}
                                <br/>
                                {this.handleSubmitButton()}

                            </Grid>
                        </ThemeProvider>

                    </CardBody>

                </Card>
            );
        }


    }

}

export default withStyles(styles)(GenerateEncashmentRequest);
