import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../../Common/Functions';
import Notification from "../../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../../JsonForm/CustomeTheme";
import DialogContent from "@material-ui/core/DialogContent/index";
import loader from "../../../../Static/loader.gif";
import {Dialog} from "@material-ui/core/index";
import MyValidation from "../../../JsonForm/MyValidation";
import {CBDataJsonFormNew} from "../../WorkflowJsonForm4";
import CBNotFound from "../../CASA/CBNotFound";
import Card from "../../../Card/Card";
import CardHeader from "../../../Card/CardHeader";
import CloseIcon from "@material-ui/icons/Close";
import CardBody from "../../../Card/CardBody";
import GridList from "@material-ui/core/GridList";
import VerifyMakerPhoto from "../../VerifyMakerPhoto";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};

class ExistingMandateDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            getData: false,
            varValue: [],
            showValue: false,
            redirectLogin: false,
            title: "",
            notificationMessage: "",
            alert: false,
            inputData: {tagList: {}},
            selectedDate: {},
            values: [],
            getDeferalList: [],
            loading: true,
            objectForNominee: [],
            fileUploadData: {},
            imageCropModal: false,
            errorModalBoolean: false,
            getRemarks: [],
            err: false,
            errorArray: {},
            errorMessages: {},
            errorMessage: "Invalid RM Code!",
            getMappingCropImage: false,
            nomineeNumber: 0,
            getNominee: false,
            type: "success",
            labelOpen: false,
            labelName: "",
            labelData: [],
            existingObjectForNominee: [],
            existingNominee: false,
            getInitialNewNominee: 0,
            disabled: false,
            oldNomineeData: [],
            // city: [],
            state: [],
            // country: [],
            guardianCode: [],
            relationCode: [],
            oldNominee: false,
            customerDetails: {},
            city: [],
            dispatchMode: [],
            getCity: false,
            stateCode: [],
            getGuardianCode: [],
            savingsSchemeCodeList: [],
            currentSchemeCodeList: [],
            getState: false,
            country: [],
            titleList: [],
            chequebookWaiverType: [],
            rmCodeList: [],
            phoneEmailType: [],
            phoneOrEmail: [],
            getCountry: false,
            currency: [],
            relationshipList: [],
            constitutionList: [],
            waiverFieldList: [],
            ccepCodeList: [],
            introducerStatus: [],
            relationCodeList: [],
            getCurrency: false,
            confirmAlert: false,
            actionType: "",
            despatchmodeList: [],
            agentBankingOutletList: [],
            maritalStatusList: [],
            cbDataAfterSet: false,
            accountDataAfterSet: false,
            kycAutopopulateFlag: false,
            TpAutopopulateFlag: false,
            tradeServiceList: [],
            appData: {},
            SelectedDropdownSearchData: null,
            dropdownSearchData: {},
            customerName: [],
            deferalType: [],
            expireDate: [],
            other: [],
            getCheckerList: [],
            getAllDefferal: [],
            occupationCodeList: [],
            jointAccountCustomerNumber: 0,
            getgenerateForm: false,
            renderCumModalopen: false,
            generateAccountNo: '',
            getDedupData: {},
            jointDedupData: {},
            jointSearchTableData: [],
            propritorshipData: [],
            dedupData: [],
            tagClick: false,
            getTaggingList: {},
            getTaggingCustomerList: {},
            taggingData: [],
            relatedData: {},
            searchTableData: false,
            searchTableRelatedData: false,
            cbGenerateModal: false,
            customerStatusList: [],
            sectorCodeList: [],
            subsectorCodeList: [],
            depositCodeList: [],
            subSegmentList: [],
            jsonArray: [],
            showForm: false,
            phoneNumCountryCodeList: [],
            getnonResident: [],
            getfreecode7: [],
            cbNotFound: false,
            documentList: [],
            employmentTypeList: [],
            getDocument: false,
            placeOfIssueList: [],
            despatchList: [],

        };


    }


    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined" ||
                clone[prop] === "-1"
            )
                delete clone[prop];
        return clone;
    };


    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;

        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (value === jsonObject.key) {
                return jsonObject;
            }
        }


    };

    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = this.state.jsonArray;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                this.state.jsonArray[i].enum = getValue;

            }
        }
    };

    apiDataSet = (data, inputData, accountRelated) => {
        if (data !== undefined && data !== null && data !== "") {

            if (accountRelated === "accountRelated") {

                inputData.misagentBankingOutletFreeCode1 = this.findByVarNameGetKeyValue(data.agentbankingoutlet, this.state.agentBankingOutletList); //acco


                inputData.fincoreDataUpdatecashLimitDr = data.drcashlim;
                inputData.functionCode = "ADD";
                inputData.customerType = "Individual";
                inputData.fincoreDataUpdatecashLimitCr = data.crcashlim;
                inputData.fincoreDataUpdateclearingLimitDr = data.drclrlim;
                inputData.fincoreDataUpdateclearingLimitCr = data.crclrlim;
                inputData.fincoreDataUpdatetransferLimitDr = data.drtxlim;
                inputData.fincoreDataUpdatetransferLimitCr = data.crtxlim;
                inputData.fincoreDataUpdatenotes = data.notes;
                inputData.miscustomerIdCifId = data.cbNumber;
                inputData.misaCNumber = data.accountNumber;
                inputData.accountOrReference = data.accountNumber;


                // inputData.fincoreDataUpdateaccountStatement=data. ;
                // inputData.fincoreDataUpdatestatementFrequency=data. ;
                inputData.fincoreDataUpdatedispatchMode = data.despatchmode;
                if (data.acctStmtNxtPrintDt !== "") {
                    inputData.fincoreDataUpdatenextPrintDate = data.acctStmtNxtPrintDt;
                } else {
                    let someDate = new Date();
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let newDate = new Date(year, month - 1, date,);
                    if (this.state.inputData["fincoreDataUpdatenextPrintDate"] === undefined) {
                        this.state.selectedDate["fincoreDataUpdatenextPrintDate"] = newDate;
                        inputData.fincoreDataUpdatenextPrintDate = (newDate !== null) ? newDate.toLocaleString('en-US').split(',')[0] : "";
                    }
                }
                // inputData.fincoreDataUpdateaCStatus=data. ;
                inputData.fincoreDataUpdatedormantActivationCharge = data.dormchrg;
                // inputData.fincoreDataUpdatenomineeSelect=data. ;
                // inputData.fincoreDataUpdateaCInformation=data. ;
                inputData.fincoreDataUpdatesectorCode = this.findByVarNameGetKeyValue(data.sectorcode, this.state.sectorCodeList);
                inputData.fincoreDataUpdatesubSectorCode = this.findByVarNameGetKeyValue(data.subsectorcode, this.state.subsectorCodeList);

                inputData.sectorCodeApicall = true;
                inputData.fincoreDataUpdateoccupationCode = data.occupationcode;
                inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3 = data.rmcode;
                //inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = false
                // inputData.fincoreDataUpdatefreeText3=data. ;
                // inputData.fincoreDataUpdatefreeCode6=data. ;
                inputData.fincoreDataUpdatedepositCodeFreeCode7 = this.findByVarNameGetKeyValue(data.depositcode, this.state.depositCodeList);
                // inputData.fincoreDataUpdatefreeCode8=data. ;
                inputData.miswaiverFieldFreeCode10 = this.findByVarNameGetKeyValue(data.waiverfield, this.state.waiverFieldList);

                inputData.misccepCodeFreeCode9 = this.findByVarNameGetKeyValue(data.ccepcode, this.state.ccepCodeList);
                inputData.aof1smsAlertFreeText11 = data.smsalert;
                inputData.fincoreDataUpdatenotes = data.notes;
                inputData.schemeCodeChangemodeOfOperation = this.findByVarNameGetKeyValue(data.modeOfOper, this.state.despatchmodeList);
                //kyc
                inputData.customerAccount = data.accountNumber;

                inputData.aCTitle = data.accountName;

                inputData.accountType = data.accountNumber;
                inputData.accountOrReference = data.accountNumber;
                inputData.TpCustomerAccount = data.accountNumber;
                inputData.TpAccountName = data.accountName;
                if (data.schemeType === "SBA") {
                    inputData.accountType = "SAVINGS";
                    inputData.TpAccountType = "SAVINGS";
                    this.findByVarNameApiData("schemeCodeChangetargetSchemeCodeInCaseOfInstaPack", this.state.savingsSchemeCodeList)

                } else {
                    inputData.accountType = "CURRENT";
                    inputData.TpAccountType = "CURRENTS";
                    this.findByVarNameApiData("schemeCodeChangetargetSchemeCodeInCaseOfInstaPack", this.state.currentSchemeCodeList)
                }

                inputData.TpAccountNumber = data.accountNumber;
                if (data.acctStmtMode === "S") {
                    inputData.fincoreDataUpdateaccountStatement = "S-STATEMENT";

                } else {
                    inputData.fincoreDataUpdateaccountStatement = "NONE"

                }

                inputData.aof1despatchModePrintedEStatementBoth = this.findByVarNameGetKeyValue(data.despatchmode, this.state.despatchList)
                /*   if (data.despatchmode==="E") {
                      inputData.aof1despatchModePrintedEStatementBoth = {
                          "masterDataName": "dispatchMode",
                          "key": "Email only",
                          "value": "Email only"
                      }

                  } else if (getInputData.statementFacility === "E-Statement") {


                  }
              else if (getInputData.statementFacility === "NO") {
                      inputData.aof1despatchModePrintedEStatementBoth = {
                          "masterDataName": "dispatchMode",
                          "key": "No Despatch",
                          "value": "No Despatch"
                      }

                  }*/


            } else if (accountRelated === "cbExtraInformation") {
                inputData.mispriorityCenterCodeFreeCode1 = data.freecode1;
                inputData.mispriorityMonitoringRmCodeFreeCode3 = data.freecode3;
                inputData.misprimaryCbHolderForPriorityFreeText8 = data.free_text_8;
                inputData.misrelationshipWithPrimaryCbHolderFreeText9 = data.free_text_9;
                inputData.misbbkSegmentCodeFreeCode7 = this.findByVarNameGetKeyValue(data.freecode7, this.state.getfreecode7);              //
                inputData.mismigratedNidVerificationFreeText2 = data.free_text_2;
                inputData.mismigratedSmartCardNumberFreeText5 = data.free_text_5;
                inputData.mispepStatusFreeText10 = data.free_text_11;
                inputData.mismigratedCifsDocumentNoFreeText13 = data.free_text_13;
                inputData.misavailedTradeServices = this.findByVarNameGetKeyValue(data.trade_services_availed, this.state.tradeServiceList);                   //
                inputData.primryintroducerCustomerIdCifId = data.intrd_cif_id;
                inputData.primryicifTypePrimaryIntroducerDetails = data.intrd_cif_type;
                inputData.chairmanCertificateplaceOfBirthFreeText14 = data.free_text_14;
                inputData.chairmanCertificatecountryOfBirthFreeText15 = data.free_text_15;
                inputData.employerDatamonthlyIncomeGrossIncome = data.gross_income;
                // inputData.chairmanCertificatenonResident = this.findByVarNameGetKeyValue(data.customer_nre_flg, this.state.getnonResident);  //
                inputData.chairmanCertificateturnedNonResidentOn = data.date_of_becoming_nre
            } else {
                inputData.miscustomerIdCifId = this.props.cbNumber;
                inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = false;
                inputData.passportNumberpassportNumberUniqueIdApiCall = true;
                inputData.nationalIdCardNumberUniqueIdApiCall = true;
                inputData.birthCertificatebirthCertificateNumberUniqueIdApiCall = true;
                inputData.electronicTaxIdApiCall = true;
                inputData.misoccupationCodePriorityCodeStaff = data.occupationcode;
                inputData.missolId = data.branchCode;
                inputData.basicInformationUpdateprimarySolId = data.branchCode;
                inputData.misstaffIdNumber = data.staffEmployeeID;
                inputData.misstaffIndicatorStatus = data.staffFlag;
                inputData.misccepCodeFreeCode9 = this.findByVarNameGetKeyValue(data.ccepcode, this.state.ccepCodeList);
                if(this.props.existingRelationCode !== undefined){
                    inputData.relatedCbTaggingrelationCode = this.findByVarNameGetKeyValue(this.props.existingRelationCode, this.state.relationCodeList);
                }
                inputData.misprimarySolId = data.branchCode;

                inputData.miscustomerNameLastName = data.customerName;
                inputData.misshortName = data.shortName;
                inputData.misaCNumber = data.accountNumber;
                inputData.customerAccount = data.accountNumber;
                inputData.crmMissubSegment = this.findByVarNameGetKeyValue(data.subSegment, this.state.subSegmentList);
                inputData.mistitle = this.findByVarNameGetKeyValue(data.salutation, this.state.titleList);
                inputData.aCTitle = data.salutation;
                inputData.miscustomerStatusNormalStaffPriorityEtc = this.findByVarNameGetKeyValue(data.custStatus, this.state.customerStatusList);
                inputData.misconstitution = this.findByVarNameGetKeyValue(data.constitutionCode, this.state.constitutionList);
                inputData.mispriorityMonitoringRmCodeFreeCode3 = data.monitoringRMCode;
                inputData.misfunctionCodeForChequeBookWaiver = "A";
                inputData.aof1currencyCodeCcy = data.currency;
                inputData.misoccupationCodePriorityCodeStaff = data.occupationCode;
                inputData.aof1smsAlertFreeText11 = this.findByVarNameGetKeyValue(data.currencyCode, this.state.currency);
                inputData.schemeCodeChangefunctionForSchemeChangeInCaseOfInstaPack = "T";
                inputData.primryiintroducerStatus = this.findByVarNameGetKeyValue(data.statusOfIntroducer, this.state.introducerStatus);
                inputData.primryiintroducerNameLastName = data.nameOfIntroducer;
                inputData.basicInformationUpdategender = data.gender;
                inputData.basicInformationUpdatefatherNamePrefferedName = data.fatherName;
                inputData.basicInformationUpdatemotherNameMothersMaidenName = data.motherName;
                inputData.basicInformationUpdatemotherNameMothersMaidenName = data.motherName;
                inputData.basicInformationUpdatemaritalStatus = this.findByVarNameGetKeyValue(data.maritalStatus, this.state.maritalStatusList);
                inputData.basicInformationUpdatespouseNamePurgeRemarks = data.spouseName;
                let birthDate = data.birthDate;
                if (birthDate && birthDate !== -1) {
                    let date = (birthDate).split('T')[0].split("-");
                    inputData.basicInformationUpdatedob = `${date[1]}/${date[2]}/${date[0]}`;
                    setTimeout(() => {
                        let x = 18;
                        var currentDate = new Date();

                        let someDate = new Date();
                        let numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        let date = someDate.getDate();
                        let month = someDate.getMonth() + 1;
                        let year = someDate.getFullYear();
                        let convertDate = year + "-" + month + "-" + date;
                        let newDate = new Date(convertDate);
                        let stringToDate = new Date(newDate.toDateString());

                        let inputDate = new Date(birthDate);

                        if (stringToDate >= inputDate) {
                            inputData.basicInformationUpdatedobApiCall = "YES";
                            inputData.basicInformationUpdateminorStatusMinorIndicator = "N"

                            //varValue.basicInformationUpdateminorStatusMinorIndicator = "Y"
                        } else {
                            inputData.basicInformationUpdatedobApiCall = "YES";
                            inputData.basicInformationUpdateminorStatusMinorIndicator = "Y"
                            //this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
                        }
                        x = 60;
                        currentDate = new Date();
                        someDate = new Date();
                        numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        date = someDate.getDate();
                        month = someDate.getMonth() + 1;
                        year = someDate.getFullYear();
                        convertDate = year + "-" + month + "-" + date;
                        newDate = new Date(convertDate);
                        stringToDate = new Date(newDate.toDateString());

                        inputDate = new Date(birthDate);
                        if (stringToDate >= inputDate) {
                            inputData.basicInformationUpdateseniorCitizenIndicator = "Y"


                        } else {

                            inputData.basicInformationUpdateseniorCitizenIndicator = "N"
                        }
                    }, 1000)
                }
                inputData.basicInformationUpdateminorStatusMinorIndicator = data.isMinor;
                inputData.basicInformationUpdateseniorCitizenIndicator = data.seniorCitizen;
                inputData.fincoreDataUpdatefreeText10 = data.waiverfield;


                inputData.introducerIdApiCall = true;
                inputData.drivingLicensedocumentTypeN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.drivingLicensedocumentTypeDescriptionN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.residentdocumentTypeN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.residentdocumentTypeDescriptionN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.natioanlIdCarddocumentTypeN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.natioanlIdCarddocumentTypeDescriptionN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                //Passport
                inputData.passportNumberdocumentTypeP = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.passportNumberdocumentTypeDescriptionP = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.smartIdCarddocumentTypeS = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.smartIdCarddocumentTypeDescriptionS = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };


                inputData.birthCertificatedocumentTypeB = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.birthCertificatedocumentTypeDescriptionB = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.chairmanCertificatedocumentTypeCC = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.chairmanCertificatedocumentTypeDescriptionCC = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.etindocumentType = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.etindocumentTypeDescription = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.contactNumberEmailpreferedEmailIdType = {
                    "masterDataName": "emailType",
                    "key": "COMMEML",
                    "value": "COMMEML"
                };
                inputData.contactNumberEmailemailType1 = {
                    "masterDataName": "emailType",
                    "key": "COMMEML",
                    "value": "COMMEML"
                };
                inputData.contactNumberEmailphoneNoEmailId4 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "EMAIL",
                    "value": "EMAIL"
                };
                inputData.contactNumberpreferredContactNoType1 = {
                    "masterDataName": "contactType",
                    "key": "COMMPH1",
                    "value": "COMMPH1"
                };

                inputData.contactNumberphoneNoEmailId1 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "PHONE",
                    "value": "PHONE"
                };

                inputData.contactNumberphoneNoEmailId2 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "PHONE",
                    "value": "PHONE"
                };

                inputData.contactNumberphoneNoEmailId3 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "PHONE",
                    "value": "PHONE"
                };
                //inputData.contactNumberphoneNo3=data. ;

                inputData.contactNumberEmailemailType = {
                    "masterDataName": "emailType",
                    "key": "HOMEEML",
                    "value": "HOMEEML"
                };
                inputData.contactNumberEmailphoneNoEmailId = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "EMAIL",
                    "value": "EMAIL"
                };

                inputData.maillingAdresspreferredAddressType = {
                    "masterDataName": "addressType",
                    "key": "Mailing",
                    "value": "Mailing"
                };
                inputData.maillingAdressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };
                inputData.maillingAdressaddrssType = {
                    "masterDataName": "addressType",
                    "key": "Mailing",
                    "value": "Mailing"
                };
                inputData.maillingAdressaddrssType = {
                    "masterDataName": "addressType",
                    "key": "Mailing",
                    "value": "Mailing"
                };
                inputData.homeAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };

                inputData.workAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };

                inputData.presentAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };
                inputData.nreAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };
                inputData.nreAddressaddrssType =
                    {
                        "masterDataName": "addressType",
                        "key": "NRERelative",
                        "value": "NRERelative"
                    };

                inputData.Customersnationality = this.findByVarNameGetKeyValue(data.nationality, this.state.country);
                if (data.NIDBLOCK !== undefined) {
                    inputData.natioanlIdCarddocumentCodeN = {
                        "masterDataName": "documentCode",
                        "key": "NID",
                        "value": "NID CARD IDENTITY"
                    };
                    inputData.nationalIdCardNumberUniqueId = data.NIDBLOCK.referenceNum;
                    inputData.nationalIdCardNo = data.NIDBLOCK.referenceNum;
                    inputData.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = data.NIDBLOCK.isDocumentVerified;
                    inputData.nationalIdCardNo = data.NIDBLOCK.referenceNum;
                    let natioanlIdCardissueDateN = data.NIDBLOCK.issueDt;
                    if (natioanlIdCardissueDateN && natioanlIdCardissueDateN !== -1) {
                        let date = (natioanlIdCardissueDateN).split('T')[0].split("-");
                        inputData.natioanlIdCardissueDateN = `${date[1]}/${date[2]}/${date[0]}`
                    }
                } else {
                    inputData.natioanlIdCarddocumentCodeN = null;
                }

                if (data.RSNIDBLOCK !== undefined) {
                    inputData.smartIdCarddocumentCodeS = {
                        "masterDataName": "documentCode",
                        "key": "RSNID",
                        "value": "RSNID"
                    };
                    inputData.smartCardNumberUniqueId = data.RSNIDBLOCK.referenceNum;
                    let smartCardissueDateN = data.RSNIDBLOCK.issueDt;
                    if (smartCardissueDateN && smartCardissueDateN !== -1) {
                        let date = (smartCardissueDateN).split('T')[0].split("-");
                        inputData.smartIdCardissueDateS = `${date[1]}/${date[2]}/${date[0]}`
                    }
                } else {
                    inputData.smartIdCarddocumentCodeS = null;
                }

                //delete flag start
                inputData.contactNumberEmaildesignatedEmailAddressEmailId1Delete = "N";
                inputData.natioanlIdCardDelete = "N";
                inputData.smartIdCardDelete = "N";
                inputData.passportNumberDelete = "N";
                inputData.drivingLicenseNumberDelete = "N";
                inputData.residentNumberDelete = "N";
                inputData.birthCertificateDelete = "N";
                inputData.chairmanCertificateDelete = "N";
                inputData.photoIdDelete = "N";
                inputData.etinDelete = "N";
                inputData.contactNumber2Delete = "N";
                inputData.contactNumber3Delete = "N";
                inputData.contactNumberEmailemailId2Delete = "N";
                inputData.homeAddressDelete = "N";
                inputData.workAddressDelete = "N";
                inputData.nreAddressDelete = "N";
                inputData.presentAddressDelete = "N";
                inputData.presentAddressDelete = "N";
                //delete flag End

                if (data.RESIDBLOCK !== undefined) {
                    inputData.residentdocumentCodeN = {
                        "masterDataName": "documentCode",
                        "key": "RESID",
                        "value": "RESIDENT ID CARD"
                    };
                    inputData.residentNumberUniqueId = data.RESIDBLOCK.referenceNum;
                } else {
                    inputData.residentdocumentCodeN = null;
                }

                if (data.DLBLOCK !== undefined) {
                    inputData.drivingLicensedocumentCodeN = {
                        "masterDataName": "documentCode",
                        "key": "DL",
                        "value": "DRIVING LICENSE"

                    };
                    inputData.drivingLicenseNumberUniqueId = data.DLBLOCK.referenceNum;
                } else {
                    inputData.drivingLicensedocumentCodeN = null;
                }

                if (data.FutureOnSiteBLOCK !== undefined) {
                    inputData.presentAddressaddrssType = {
                        "masterDataName": "addressType",
                        "key": "Future/OnSite",
                        "value": "Future/OnSite"
                    };
                    inputData.presentAddresspresentAddressField1 = data.FutureOnSiteBLOCK.addrLine1;
                    inputData.presentAddresspresentAddressField2 = data.FutureOnSiteBLOCK.addrLine2;
                    inputData.presentAddresscity = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.city, this.state.city);
                    inputData.presentAddressstate = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.state, this.state.stateCode);
                    inputData.presentAddresspostalCode = data.FutureOnSiteBLOCK.postalCode;
                    inputData.presentAddresscountry = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.country, this.state.country);
                } else {
                    inputData.presentAddressaddrssType = null
                }

                inputData.chairmanCertificatenonResident = this.findByVarNameGetKeyValue(data.isNRE, this.state.getnonResident);
                if (data.NRERelativeBLOCK !== undefined) {
                    inputData.nreAddressnreAddressField1 = data.NRERelativeBLOCK.addrLine1;
                    inputData.nreAddressnreAddressField2 = data.NRERelativeBLOCK.addrLine2;
                    inputData.nreAddresscity = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.city, this.state.city);
                    inputData.nreAddressstate = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.state, this.state.stateCode);
                    inputData.nreAddresspostalCode = data.NRERelativeBLOCK.postalCode;
                    inputData.nreAddresscountry = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.country, this.state.country);
                }

                if (data.PASSPORTBLOCK !== undefined) {
                    inputData.passportNumberdocumentCodeP = {
                        "masterDataName": "documentCode",
                        "key": "PASSPORT",
                        "value": "PASSPORT IDENTITY"
                    };
                    inputData.passportNumberpassportNumberUniqueId = data.PASSPORTBLOCK.referenceNum;
                    inputData.passportNo = data.PASSPORTBLOCK.referenceNum;
                    inputData.passportNo = data.PASSPORTBLOCK.referenceNum;
                    inputData.passportNumberplaceOfIssue = this.findByVarNameGetKeyValue(data.PASSPORTBLOCK.placeOfIssue, this.state.placeOfIssueList);

                    let passportNumberissueDate = data.PASSPORTBLOCK.issueDt;
                    if (passportNumberissueDate && passportNumberissueDate !== -1) {
                        let date = (passportNumberissueDate).split('T')[0].split("-");
                        inputData.passportNumberissueDate = `${date[1]}/${date[2]}/${date[0]}`
                    }
                    inputData.passportNumbercountryOfIssue = this.findByVarNameGetKeyValue(data.PASSPORTBLOCK.countryOfIssue, this.state.country);
                    inputData.passportNumberexpiryDate = data.PASSPORTBLOCK.expireDt;
                } else {
                    inputData.passportNumberdocumentCodeP = null
                }

                if (data.BCBLOCK !== undefined) {
                    inputData.birthCertificatedocumentCodeB = {
                        "masterDataName": "documentCode",
                        "key": "BC",
                        "value": "BIRTH CERTIFICATE"
                    };
                    inputData.birthCertificatebirthCertificateNumberUniqueId = data.BCBLOCK.referenceNum;
                    inputData.birthCertificateNo = data.BCBLOCK.referenceNum;
                    inputData.birthCertificateNo = data.BCBLOCK.referenceNum;
                } else {
                    inputData.birthCertificatedocumentCodeB = null;
                }

                if (data.chairmanCertificate !== undefined) {
                    inputData.chairmanCertificatedocumentCodeCC = {
                        "masterDataName": "documentCode",
                        "key": "CHAC",
                        "value": "CHAIRMAN CERTIFICATE"
                    };
                    inputData.chairmanCertificateUniqueId = data.chairmanCertificate;
                } else {
                    inputData.chairmanCertificatedocumentCodeCC = null;
                }

                if (data.ETINBLOCK !== undefined) {
                    inputData.etindocumentCode = {
                        "masterDataName": "documentCode",
                        "key": "ETIN",
                        "value": "TIN CARD IDENTITY"
                    };
                    inputData.etineTinNumber = data.ETINBLOCK.referenceNum;
                    inputData.electronicTaxId = data.ETINBLOCK.referenceNum;
                } else {
                    inputData.etindocumentCode = null
                }

                if (data.HomeBLOCK !== undefined) {
                    inputData.homeAddressaddrssType = {
                        "masterDataName": "addressType",
                        "key": "Home",
                        "value": "Home"
                    };
                    inputData.homeAddresshomeAddressField1 = data.HomeBLOCK.addrLine1;
                    inputData.homeAddresshomeAddressField2 = data.HomeBLOCK.addrLine2;
                    inputData.homeAddresscity = this.findByVarNameGetKeyValue(data.HomeBLOCK.city, this.state.city);

                    inputData.homeAddressstate = this.findByVarNameGetKeyValue(data.HomeBLOCK.state, this.state.stateCode);
                    inputData.homeAddresspostalCode = data.HomeBLOCK.postalCode;
                    inputData.homeAddresscountry = this.findByVarNameGetKeyValue(data.HomeBLOCK.country, this.state.country);
                } else {
                    inputData.homeAddressaddrssType = null
                }

                if (data.WorkBLOCK !== undefined) {
                    inputData.workAddressaddressType = {
                        "masterDataName": "addressType",
                        "key": "Work",
                        "value": "Work"
                    };
                    inputData.workAddressworkAddressField1 = data.WorkBLOCK.addrLine1;
                    inputData.workAddressworkAddressField2 = data.WorkBLOCK.addrLine2;
                    inputData.workAddresscity = this.findByVarNameGetKeyValue(data.WorkBLOCK.city, this.state.city);
                    inputData.workAddressstate = this.findByVarNameGetKeyValue(data.WorkBLOCK.state, this.state.stateCode);
                    inputData.workAddresspostalCode = data.WorkBLOCK.postalCode;
                    inputData.workAddresscountry = this.findByVarNameGetKeyValue(data.WorkBLOCK.countryCode, this.state.country);
                } else {
                    inputData.workAddressaddressType = null
                }

                if (data.COMMPH1BLOCK !== undefined) {
                    inputData.contactNumbercontactType1 = {
                        "masterDataName": "contactType",
                        "key": "COMMPH1",
                        "value": "COMMPH1"
                    };
                    inputData.contactNumberphoneNo1 = data.COMMPH1BLOCK.phoneNumLocalCode;
                    if (data.COMMPH1BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypecontactNumberphoneNo1 = "Local";
                    } else {
                        inputData.mobileTypecontactNumberphoneNo1 = "Overseas";
                        inputData.countryCodecontactNumberphoneNo1 = this.findByVarNameGetKeyValue(data.COMMPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)
                    }
                } else {
                    inputData.contactNumbercontactType1 = null;
                }

                if (data.WORKPH1BLOCK !== undefined) {
                    inputData.contactNumbercontactType3 = {
                        "masterDataName": "contactType",
                        "key": "WORKPH1",
                        "value": "WORKPH1"
                    };
                    inputData.contactNumberphoneNo3 = data.WORKPH1BLOCK.phoneNumLocalCode;
                    if (data.WORKPH1BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypecontactNumbercontactType3 = "Local";
                    } else {
                        inputData.mobileTypecontactNumbercontactType3 = "Overseas";
                        inputData.countryCodecontactNumberphoneNo3 = this.findByVarNameGetKeyValue(data.WORKPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)

                    }
                } else {
                    inputData.contactNumbercontactType3 = null;
                }
                if (data.COMMPH2BLOCK !== undefined) {
                    inputData.contactNumbercontactType2 = {
                        "masterDataName": "contactType",
                        "key": "COMMPH2",
                        "value": "COMMPH2"
                    };
                    inputData.contactNumberphoneNo2 = data.COMMPH2BLOCK.phoneNumLocalCode;
                    if (data.COMMPH2BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypecontactNumberphoneNo2 = "Local";
                    } else {
                        inputData.mobileTypecontactNumberphoneNo2 = "Overseas";
                        inputData.countryCodecontactNumberphoneNo2 = this.findByVarNameGetKeyValue(data.COMMPH2BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)

                    }
                } else {
                    inputData.contactNumbercontactType2 = null;
                }

                if (data.MailingBLOCK !== undefined) {

                    inputData.maillingAdressmailingAddressField1 = data.MailingBLOCK.addrLine1;
                    inputData.maillingAdressmailingAddressField2 = data.MailingBLOCK.addrLine2;
                    inputData.maillingAdresscity = this.findByVarNameGetKeyValue(data.MailingBLOCK.city, this.state.city);
                    inputData.maillingAdressstate = this.findByVarNameGetKeyValue(data.MailingBLOCK.state, this.state.stateCode);
                    inputData.maillingAdresspostalCode = data.MailingBLOCK.postalCode;
                    inputData.maillingAdresscountry = this.findByVarNameGetKeyValue(data.MailingBLOCK.country, this.state.country);

                }
                if (data.HOMEEMLBLOCK !== undefined) {


                    inputData.contactNumberEmailemailId2 = data.HOMEEMLBLOCK.emailInfo;
                }
                if (data.nameOfEmployer !== undefined) {
                    inputData.employerDataprofessionEmploymentType = this.findByVarNameGetKeyValue(data.employmentStatus, this.state.employmentTypeList);

                    inputData.employerDatanameOfEmployerEmployerName = data.nameOfEmployer;
                }
                if (data.COMMEMLBLOCK !== undefined) {
                    inputData.contactNumberEmaildesignatedEmailAddressEmailId1 = data.COMMEMLBLOCK.emailInfo
                }
            }
        }
        return inputData;
    };
    dynamicApiCallDocumentType = (fieldName, apiType) => {
        let codeUrl = backEndServerURL + "/finMasterData/custom/uniqueDocType/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (apiType === "documentCodeDocumentType") {
                    this.findByVarNameApiData("photoIddocumentType", response.data)
                } else if (apiType === "documentCodeDocumentTypeDesc") {
                    this.findByVarNameApiData("photoIddocumentTypeDescription", response.data)
                }

            })
            .catch((error) => {
                console.log(error)
            })
    };

    componentDidMount() {
        let fileUrl = backEndServerURL + "/case/files/" + this.props.appId;
        axios.get(fileUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    documentList: response.data,
                    getDocument: true
                })
            })
            .catch((error) => {
                console.log(error);
            });
        this.functionForFinacleMasterData();
        if (this.props.readOnly === true) {
            const jsonArray = JSON.parse(JSON.stringify(CBDataJsonFormNew));
            for (let i = 0; i < jsonArray.length; i++) {
                let jsonObjectLast = jsonArray[i];
                if (this.props.readOnly !== undefined && this.props.readOnly === true && jsonObjectLast.type !== "title") {
                    jsonArray[i].readOnly = true
                } else {
                    jsonArray[i].readOnly = false
                }
            }
            let highlightUrl = backEndServerURL + "/secondaryInfo/updatedByMaker/" + this.props.appId + "/E" + this.props.cbNumber;
            axios.get(highlightUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let fieldVarName = response.data;
                    for (let i = 0; i < fieldVarName.length; i++) {
                        let obj = jsonArray.find(item => item.varName === fieldVarName[i]);
                        if (obj !== undefined) {
                            obj.highlight = true
                        }
                    }
                    let fileUrl = backEndServerURL + "/secondaryCB/" + this.props.appId + "/E" + this.props.cbNumber;
                    axios.get(fileUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            this.setState({
                                jsonArray: jsonArray,
                                varValue: response.data,
                                inputData: response.data,
                                getData: true,
                                showValue: true,
                                showForm: true,
                                loading: false

                            })
                        })
                        .catch((error) => {
                            console.log(error);
                            this.fetchCBData().then((res) => {
                                this.setState({
                                    jsonArray: jsonArray,
                                    varValue: res,
                                    inputData: this.copyJson(res),
                                    getData: true,
                                    showValue: true,
                                    showForm: true,
                                    loading: false
                                })
                            })
                        })
                })
                .catch((error) => {
                    console.log(error);
                });
        } else if (this.props.cbNumber !== undefined && !this.props.readOnly) {
            const jsonArray = JSON.parse(JSON.stringify(CBDataJsonFormNew));
            for (let i = 0; i < jsonArray.length; i++) {
                let jsonObjectLast = jsonArray[i];

                if (jsonArray[i].varName === "schemeCodeChangemodeOfOperation" || jsonArray[i].varName === "aof1despatchModePrintedEStatementBoth" || jsonArray[i].varName === "aof1smsAlertFreeText11" || jsonArray[i].varName === "nidVerified") {
                    jsonArray[i].required = false
                }

                if (this.props.readOnly !== undefined && this.props.readOnly === true && jsonObjectLast.type !== "title") {
                    jsonArray[i].readOnly = true
                } else {
                    jsonArray[i].readOnly = false
                }
            }
            this.setState({
                jsonArray: jsonArray,
                showForm: true
            });

            let fileUrl = backEndServerURL + "/secondaryCB/" + this.props.appId + "/E" + this.props.cbNumber;
            axios.get(fileUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let inputData = {...response.data};
                    this.setState({
                        inputData: inputData,
                        varValue: inputData,
                        showValue: true,
                        getData: true,
                        loading: false
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.fetchCBData()
                });
        }

    }

    functionForSaveInquiry = (value) => {
        let saveInquiryData = backEndServerURL + "/secondaryInfo/setInquiryActivity/" + this.props.appId + "/E" + this.props.cbNumber;
        axios.post(saveInquiryData, value, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((saveResponse) => {
            })
            .catch((error) => {
                console.log(error);
            });
    };

    functionForFinacleMasterData = () => {
        this.dynamicApiCallDocumentType("documentCodeDocumentType", "documentCodeDocumentType");
        this.dynamicApiCallDocumentType("documentCodeDocumentTypeDesc", "documentCodeDocumentTypeDesc");

        this.dynamicApiCall("placeOfIssue", "placeOfIssue");
        this.dynamicApiCall("city2", "city");
        this.dynamicApiCall("documentCode", "documentCode");

        this.dynamicApiCall("state2", "state",);

        this.dynamicApiCall("currency", "currency");

        this.dynamicApiCall("nationality", "country");
        this.dynamicApiCall("nomineeRelationCode", "nomineeRelationCode");

        this.dynamicApiCall("documentsCollectedToEnsureTheSourceOfFund", "sourceOfFund");

        this.dynamicApiCall("customersOccupation", "customerOccupation");

        this.dynamicApiCall("addressVariety", "addressVariety");

        this.dynamicApiCall("whatDoesTheCustomerDoInWhatTypeOfBusineessIsTheCustomerEngaged", "customerBusiness");

        this.dynamicApiCall("monthlyIncome", "monthlyIncome");

        this.dynamicApiCall("howWasAccountOpened", "accountOpen");

        this.dynamicApiCall("constitution", "constitution");

        this.dynamicApiCall("waiverField", "waiverField");

        this.dynamicApiCall("bankRelationType", "bankRelationType");

        this.dynamicApiCall("customerStatus", "customerStatus");

        this.dynamicApiCall("ccepCode", "ccepCode");

        this.dynamicApiCall("maritalStatus", "maritalStatus");

        this.dynamicApiCall("title", "title");

        this.dynamicApiCall("relationship", "relationship");

        this.dynamicApiCall("employmentType", "employmentType");

        this.dynamicApiCall("agentBankingOutlet", "agentBankingOutlet");

        this.dynamicApiCall("nonResident", "nonResident");

        this.dynamicApiCall("relationCode", "relationCode");

        this.dynamicApiCall("relationType", "relationType");

        this.dynamicApiCall("dispatchMode", "dispatchMode");

        this.dynamicApiCall("statementFrequency", "statementFrequency");

        this.dynamicApiCall("chequebookWaiverFunctionCode", "chequebookWaiverFunctionCode");

        this.dynamicApiCall("chequebookWaiverType", "chequebookWaiverType");

        this.dynamicApiCall("subSegment", "subSegment");

        this.dynamicApiCall("designationCode", "designationCode");

        this.dynamicApiCall("sectorCode", "sectorCode");

        this.dynamicApiCall("subSectorCode", "subSectorCode");

        this.dynamicApiCall("depositCode", "depositCode");

        this.dynamicApiCall("tradeService", "tradeService");

        this.dynamicApiCall("guardianCode", "guardianCode");
        this.dynamicApiCall("addressType", "addressType");

        this.dynamicApiCall("accountStatement", "accountStatement");

        this.dynamicApiCall("modOfOperation", "modOfOperation");

        this.dynamicApiCall("introducerStatus", "introducerStatus");

        this.dynamicApiCall("bbkSegmentCode", "bbkSegmentCode");

        this.dynamicApiCall("priorityCenterCode", "priorityCenterCode");

        this.dynamicApiCall("priorityCenterCode", "priorityCenterCode");

        this.dynamicApiCall("schemeCodeForSavings", "savingsSchemeCode");

        this.dynamicApiCall("schemeCodeForCurrent", "currentSchemeCode");

        this.dynamicApiCall("phoneCountryCode", "phoneCountryCode");


        let occupationCodeUrl = backEndServerURL + "/occupationCode/getAll";
        axios.get(occupationCodeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.findByVarNameApiData("misoccupationCodePriorityCodeStaff", response.data);
                this.findByVarNameApiData("fincoreDataUpdateoccupationCode", response.data);
                this.setState({
                    occupationCodeList: response.data
                })
                //this.findByVarNameApiData("customersOccupation", response.data)

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    errorMessage: error.response.data.message,
                    errorModalBoolean: true
                })
            });

    };

    fetchCBData = () => {
        return new Promise((resolve, reject) => {
            let input = {};
            let vValue = {};
            let url = backEndServerURL + "/cbsCustomerGet/" + this.props.cbNumber;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((cbDatResponse) => {
                    let dataInputs = this.apiDataSet(cbDatResponse.data, input);
                    let datavarValues = this.apiDataSet(cbDatResponse.data, vValue);
                    this.forceUpdate();
                    let getCBUrl2 = backEndServerURL + "/getCustomerCIFDetails/" + this.props.cbNumber;/*"2801187408001"*/
                    axios.get(getCBUrl2, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((cb2DataResponse) => {
                            let dataInput = this.apiDataSet(cb2DataResponse.data, dataInputs, "cbExtraInformation");
                            let datavarValue = this.apiDataSet(cb2DataResponse.data, datavarValues, "cbExtraInformation");
                            setTimeout(() => {
                                resolve(dataInput);
                                this.functionForSaveInquiry(dataInput);
                                this.setState({
                                    inputData: dataInput,
                                    varValue: datavarValue,
                                    showValue: true,
                                    getData: true,
                                    loading: false
                                });
                            }, 500)
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false,
                                errorMessage: error.response.data.message,
                                errorModalBoolean: true
                            })
                        });
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                    this.setState({
                        loading: false,
                        errorMessage: error.response.data.message,
                        errorModalBoolean: true
                    })
                })
        })
    };

    dynamicApiCall = (fieldName, apiType) => {
        let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(fieldName);
                console.log(response);
                if (apiType === "city") {
                    this.findByVarNameApiData("maillingAdresscity", response.data);
                    this.findByVarNameApiData("homeAddresscity", response.data);
                    this.findByVarNameApiData("workAddresscity", response.data);
                    this.findByVarNameApiData("presentAddresscity", response.data);
                    this.findByVarNameApiData("nreAddresscity", response.data);
                    this.findByVarNameApiData("mailingAddresscity1", response.data);
                    this.findByVarNameApiData("homeAddresscity1", response.data);
                    this.findByVarNameApiData("workAddresscity1", response.data);
                    this.findByVarNameApiData("workAddresscity2", response.data);

                    this.setState({
                        city: response.data,
                        getCity: true
                    })

                } else if (apiType === "phoneCountryCode") {

                    this.findByVarNameApiData("countryCodecontactNumberphoneNo1", response.data);
                    this.findByVarNameApiData("countryCodecontactNumberphoneNo2", response.data);
                    this.findByVarNameApiData("countryCodecontactNumberphoneNo3", response.data);
                    this.setState({
                        phoneNumCountryCodeList: response.data
                    })
                } else if (apiType === "state") {

                    this.findByVarNameApiData("maillingAdressstate", response.data);
                    this.findByVarNameApiData("homeAddressstate", response.data);
                    this.findByVarNameApiData("workAddressstate", response.data);
                    this.findByVarNameApiData("presentAddressstate", response.data);
                    this.findByVarNameApiData("nreAddressstate", response.data);
                    this.findByVarNameApiData("mailingAddressstate1", response.data);
                    this.findByVarNameApiData("homeAddressstate1", response.data);
                    this.findByVarNameApiData("workAddressstate1", response.data);
                    this.findByVarNameApiData("workAddressstate2", response.data);

                    this.setState({
                        stateCode: response.data,
                        getState: true,
                    })
                } else if (apiType === "country") {
                    this.findByVarNameApiData("passportNumbercountryOfIssue", response.data);
                    this.findByVarNameApiData("maillingAdresscountry", response.data);
                    this.findByVarNameApiData("homeAddresscountry", response.data);
                    this.findByVarNameApiData("workAddresscountry", response.data);
                    this.findByVarNameApiData("Customersnationality", response.data);
                    this.findByVarNameApiData("presentAddresscountry", response.data);
                    this.findByVarNameApiData("nreAddresscountry", response.data);
                    this.findByVarNameApiData("mailingAddresscountry1", response.data);
                    this.findByVarNameApiData("homeAddresscountry1", response.data);
                    this.findByVarNameApiData("workAddresscountry1", response.data);
                    this.findByVarNameApiData("workAddresscountry2", response.data);
                    // this.findByVarNameApiData("passportNumberplaceOfIssue", response.data)

                    this.setState({
                        country: response.data,
                        getCountry: true
                    });
                } else if (apiType === "currency") {

                    this.findByVarNameApiData("workAddressCurrency", response.data);
                    this.findByVarNameApiData("homeAddressCurrency", response.data);
                    this.findByVarNameApiData("aof1currencyCodeCcy", response.data);
                    this.setState({
                        currency: response.data,
                        getCurrency: true
                    })
                } else if (apiType === "customerStatus") {
                    this.findByVarNameApiData("miscustomerStatusNormalStaffPriorityEtc", response.data);
                    this.setState({
                        customerStatusList: response.data,
                    })
                } else if (apiType === "savingsSchemeCode") {

                    this.setState({
                        savingsSchemeCodeList: response.data,
                    })
                } else if (apiType === "nomineeRelationCode") {

                    this.findByVarNameApiData("guardianDetailsrelation1", response.data)

                } else if (apiType === "currentSchemeCode") {

                    this.setState({
                        currentSchemeCodeList: response.data,
                    })
                } else if (apiType === "sourceOfFund") {
                    this.findByVarNameApiData("sourceOfFund", response.data);
                    this.findByVarNameApiData("documentsCollectedToEnsureTheSourceOfFund", response.data)

                } else if (apiType === "constitution") {
                    this.findByVarNameApiData("misconstitution", response.data);
                    this.setState({
                        constitutionList: response.data
                    })

                } else if (apiType === "monthlyIncome") {
                    this.findByVarNameApiData("customerMonthlyIncome", response.data)


                } else if (apiType === "accountOpen") {
                    this.findByVarNameApiData("howWasAccountOpened", response.data)


                } else if (apiType === "designationCode") {
                    this.findByVarNameApiData("relatedCbTaggingdesignationCode", response.data)

                } else if (apiType === "tradeService") {
                    this.findByVarNameApiData("misavailedTradeServices", response.data);
                    this.setState({
                        tradeServiceList: response.data
                    })

                } else if (apiType === "modOfOperation") {
                    this.findByVarNameApiData("schemeCodeChangemodeOfOperation", response.data);
                    this.setState({
                        despatchmodeList: response.data
                    })

                } else if (apiType === "introducerStatus") {
                    this.findByVarNameApiData("primryiintroducerStatus", response.data);
                    this.setState({
                        introducerStatus: response.data
                    })
                } else if (apiType === "bbkSegmentCode") {
                    this.findByVarNameApiData("misbbkSegmentCodeFreeCode7", response.data);
                    this.setState({
                        getfreecode7: response.data
                    })

                } else if (apiType === "guardianCode") {
                    this.findByVarNameApiData("guardianDetailsguardianSelectionGurdian", response.data);
                    this.setState({
                        getGuardianCode: response.data
                    })

                } else if (apiType === "subSegment") {
                    this.findByVarNameApiData("crmMissubSegment", response.data);
                    this.setState({
                        subSegmentList: response.data
                    })

                } else if (apiType === "subSectorCode") {
                    this.findByVarNameApiData("fincoreDataUpdatesubSectorCode", response.data);
                    this.findByVarNameApiData("subSectorCode", response.data);
                    this.setState({
                        subsectorCodeList: response.data
                    })
                } else if (apiType === "sectorCode") {
                    this.findByVarNameApiData("fincoreDataUpdatesectorCode", response.data);
                    this.findByVarNameApiData("sectorCode", response.data);
                    this.setState({
                        sectorCodeList: response.data
                    })
                } else if (apiType === "depositCode") {
                    this.findByVarNameApiData("fincoreDataUpdatedepositCodeFreeCode7", response.data);
                    this.setState({
                        depositCodeList: response.data
                    })
                } else if (apiType === "dispatchMode") {
                    // this.findByVarNameApiData("aof1despatchModePrintedEStatementBoth", response.data)
                    this.findByVarNameApiData("fincoreDataUpdatedispatchMode", response.data);
                    console.log("llllllllllll");
                    console.log(response.data);
                    this.setState({
                        dispatchMode: response.data
                    })
                } else if (apiType === "rmCode") {
                    this.findByVarNameApiData("mispriorityMonitoringRmCodeFreeCode3", response.data);
                    this.setState({
                        rmCodeList: response.dat
                    })

                } else if (apiType === "accountStatement") {
                    this.findByVarNameApiData("aof1statement", response.data)
                    //this.findByVarNameApiData("fincoreDataUpdateaccountStatement", response.data)
                } else if (apiType === "statementFrequency") {
                    this.findByVarNameApiData("fincoreDataUpdatestatementFrequency", response.data);
                    this.findByVarNameApiData("aof1statement", response.data)
                } else if (apiType === "chequebookWaiverFunctionCode") {
                    // this.findByVarNameApiData("misfunctionCodeForChequeBookWaiver", response.data)
                    // this.findByVarNameApiData("schemeCodeChangefunctionForSchemeChangeInCaseOfInstaPack", response.data)
                } else if (apiType === "chequebookWaiverType") {
                    this.findByVarNameApiData("miswaiverTypeForChequeBookWaiver", response.data);
                    this.setState({
                        chequebookWaiverType: response.data
                    })
                } else if (apiType === "branch") {
                    this.findByVarNameApiData("otherBankbranchName", response.data)

                } else if (apiType === "relationCode") {
                    this.findByVarNameApiData("relatedCbTaggingrelationCode", response.data);
                    this.setState({
                        relationCodeList: response.data
                    })
                } else if (apiType === "addressFormat") {
                    this.findByVarNameApiData("maillingAdressaddressFormat", response.data);
                    this.findByVarNameApiData("homeAddressaddressFormat", response.data);
                    this.findByVarNameApiData("workAddressaddressFormat", response.data);
                    this.findByVarNameApiData("presentAddressaddressFormat", response.data);
                    this.findByVarNameApiData("mailingAddressaddressFormat1", response.data);
                    this.findByVarNameApiData("homeAddressaddressFormat1", response.data);
                    this.findByVarNameApiData("workAddressaddressFormat1", response.data);
                    this.findByVarNameApiData("workAddressaddressFormat2", response.data)

                } else if (apiType === "agentBankingOutlet") {
                    this.findByVarNameApiData("misagentBankingOutletFreeCode1", response.data);
                    this.setState({
                        agentBankingOutletList: response.data
                    })
                } else if (apiType === "waiverField") {
                    this.findByVarNameApiData("miswaiverFieldFreeCode10", response.data);
                    this.setState({
                        waiverFieldList: response.data
                    })
                    // this.findByVarNameApiData("misdebitCardIssueFreeCode10", response.data)

                } else if (apiType === "ccepCode") {
                    this.findByVarNameApiData("misccepCodeFreeCode9", response.data);
                    this.setState({
                        ccepCodeList: response.data
                    })

                } else if (apiType === "nonResident") {
                    this.findByVarNameApiData("chairmanCertificatenonResident", response.data);
                    this.setState({
                        getnonResident: response.data
                    })

                } else if (apiType === "agentBankingOutlet") {
                    this.findByVarNameApiData("misagentBankingOutletFreeCode1", response.data);
                    this.setState({
                        agentBankingOutletList: response.data
                    })
                } else if (apiType === "maritalStatus") {
                    this.findByVarNameApiData("basicInformationUpdatemaritalStatus", response.data);
                    this.findByVarNameApiData("basicInformationmaritalStatus", response.data);
                    this.findByVarNameApiData("maritalStatus", response.data);
                    this.setState({
                        maritalStatusList: response.data
                    })

                } else if (apiType === "employmentType") {
                    this.findByVarNameApiData("employerDataprofessionEmploymentType", response.data);
                    this.findByVarNameApiData("etinprofessionEmploymentType", response.data);
                    this.findByVarNameApiData("employerDataemploymentType", response.data);
                    this.setState({
                        employmentTypeList: response.data
                    })

                } else if (apiType === "placeOfIssue") {
                    this.findByVarNameApiData("passportNumberplaceOfIssue", response.data);
                    this.setState({
                        placeOfIssueList: response.data
                    })
                } else if (apiType === "despatchMode") {
                    this.findByVarNameApiData("aof1despatchModePrintedEStatementBoth", response.data);
                    this.setState({
                        despatchList: response.data
                    })
                } else if (apiType === "relationship") {
                    this.findByVarNameApiData("guardianDetailsrelation", response.data);
                    this.setState({
                        relationshipList: response.data
                    })
                } else if (apiType === "relationType") {
                    this.findByVarNameApiData("relatedCbTaggingrelationType", response.data)

                } else if (apiType === "title") {
                    if (fieldName === "titleOld") {
                        let JsonArrayForOther = this.props.commonJsonForm;
                        for (let i = 0; i < JsonArrayForOther.length; i++) {
                            let jsonObjectLast = JsonArrayForOther[i];
                            if (jsonObjectLast.varName === "titleOld") {
                                JsonArrayForOther[i].enum = response.data;
                            }
                        }
                    }
                    this.findByVarNameApiData("titleOld", response.data);
                    this.findByVarNameApiData("mistitle", response.data);
                    this.findByVarNameApiData("relatedCbTaggingtitle", response.data);
                    this.findByVarNameApiData("aCTitle", response.data);
                    this.setState({
                        titleList: response.data
                    })


                } else if (apiType === "documentCode") {
                    this.findByVarNameApiData("photoIddocumentCode", response.data)

                } else if (apiType === "bankName") {
                    this.findByVarNameApiData("otherBankbankName", response.data)


                } else if (apiType === "segment") {
                    this.findByVarNameApiData("misbbkSegmentCodeFreeCode7", response.data)


                } else if (apiType === "phoneOrEmailId") {
                    this.findByVarNameApiData("contactNumberphoneNoEmailId1", response.data);
                    this.findByVarNameApiData("contactNumberphoneNoEmailId2", response.data);
                    this.findByVarNameApiData("contactNumberphoneNoEmailId3", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId4", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId1", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId2", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId", response.data);
                    this.setState({
                        phoneOrEmail: response.data
                    })
                } else if (apiType === "customerOccupation") {

                    this.findByVarNameApiData("customersOccupation", response.data)

                } else if (apiType === "bankRelationType") {

                    this.findByVarNameApiData("guardianDetailsbankRelationType", response.data)

                } else if (apiType === "emailType") {

                    this.findByVarNameApiData("contactNumberEmailemailType1", response.data);
                    this.findByVarNameApiData("contactNumberEmailemailType2", response.data);
                    this.findByVarNameApiData("contactNumberEmailemailType", response.data);
                    this.findByVarNameApiData("contactNumberEmailpreferedEmailIdType", response.data);
                    this.setState({
                        phoneEmailType: response.data
                    })
                } else if (apiType === "addressVariety") {

                    this.findByVarNameApiData("howTheAddressOfTheAccountHolderHasBeenVerified", response.data)

                } else if (apiType === "customerBusiness") {

                    this.findByVarNameApiData("whatDoesTheCustomerDoInWhatTypeOfBusineessIsTheCustomerEngaged", response.data)

                }
            })
            .catch((error) => {
                console.log(error);
            })
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.type} stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
        });
        let object = {};
        object.appId = this.props.appId;
        object.person = "E" + this.props.cbNumber;
        object.cb = "E" + this.props.cbNumber;
        if (this.props.readOnly === true) {
            object.appData = this.state.inputData;
            let url = backEndServerURL + "/secondaryCBUpdate/" + this.props.appId + "/" + object.person + "/" + this.props.cbNumber;
            axios.post(url, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    if (response.status === 200) {
                        this.state.inputData.apiButtonType = "COMPLETED";
                        object.appData = this.state.inputData;
                        object.requestType = "Completed";
                        object.cb = this.props.cbNumber;
                        let urlsecondaryCB = backEndServerURL + "/saveExistingMandateInfo";
                        axios.post(urlsecondaryCB, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                this.props.getTExistingMandateData();
                                this.props.closeModal();
                                this.setState({
                                    loading: false
                                })
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    loading: false,
                                    cbNotFound: true,
                                    title: error.response.data.message,
                                })
                            })
                    }
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message,
                    })
                })

        } else {
            this.forceUpdate();
            let errorForAdup = MyValidation.defaultValidation(this.state.jsonArray, this.state);
            this.forceUpdate();
            if (errorForAdup === true) {
                this.setState({
                    loading: false
                });
                return 0;
            } else {
                this.state.inputData.apiButtonType = "CB UPDATE";
                object.appData = this.state.inputData;
                object.requestType = "Waiting for Complete";
                let urlsecondaryCB = backEndServerURL + "/saveExistingMandateInfo";
                axios.post(urlsecondaryCB, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);
                        this.props.closeModal();
                        this.setState({
                            loading: false
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                            cbNotFound: true,
                            title: error.response.data.message,
                        })
                    })
            }
        }
    };

    onKeyDownForDedup = (event, data) => {
        let oldNominee = this.state.inputData.nomineeNumberOld ? parseInt(this.state.inputData.nomineeNumberOld) : 0;

        let totalNominee = oldNominee + this.state.inputData["numberOfNominee"];

        for (let i = 0; i < totalNominee; i++) {
            if (data === "nomineecifId" + (i + 1)) {
                this.setState({
                    loading: true
                });
                this.state.inputData["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.varValue["nomineecifIdApiCall" + (i + 1)] = false;
                let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData["nomineecifId" + (i + 1)];
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.state.varValue["search" + (i + 1)] = this.state.inputData["search" + (i + 1)];
                        this.state.varValue["nomineecifId" + (i + 1)] = this.state.inputData["nomineecifId" + (i + 1)];
                        this.state.inputData["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.inputData["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.inputData["nomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.inputData["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.inputData["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.inputData["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.inputData["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.inputData["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.varValue["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.varValue["nomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.varValue["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.varValue["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.varValue["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.varValue["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        let x = 60;
                        let currentDate = new Date();
                        let someDate = new Date();
                        let numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        let date = someDate.getDate();
                        let month = someDate.getMonth() + 1;
                        let year = someDate.getFullYear();
                        let convertDate = year + "-" + month + "-" + date;
                        let newDate = new Date(convertDate);
                        let stringToDate = new Date(newDate.toDateString());

                        let inputDate = new Date(response.data.birthDate);
                        this.state.inputData["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        this.state.varValue["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        if (stringToDate >= inputDate) {
                            this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "YES";

                            // this.state.varValue["nomineeMinor" + i] == "NO";
                        } else {

                            this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "NO";

                            //this.state.varValue["nomineeMinor" + i] == "YES";
                        }

                        /*this.state.inputData["nomineeGuardiansName" + i] =response.data.
                        this.state.inputData["nomineeGuardianCode" + i] =response.data.
                        this.state.inputData["guardianPhotoId" + i] =response.data.
                        this.state.inputData["nomineeGuardianAddress" + i] =response.data.
                        this.state.inputData["nomineeGuardianAddress2" + i]=response.data.
                        this.state.inputData["nomineeGuardianCityCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.city)
                        this.state.inputData["nomineeGuardianStateCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.state)
                       this.state.inputData["nomineeGuardianPostalCode" + i]=response.data.
                      this.state.inputData["nomineeGuardianCountry2" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.country)
*/
                        this.state.inputData["nomineecifIdApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineecifIdApiCall" + (i + 1)] = true;
                        this.forceUpdate();

                        console.log(this.state.inputData);
                        this.setState({
                            //varValue: this.state.varValue,
                            //inputData: this.state.inputData,
                            showValue: true,
                            getData: true,
                            loading: false
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                            errorTittle: "Invalid Introducer ID",
                            errorModalBoolean: true,
                        })
                    })

            }
        }
        if (data === "mispriorityMonitoringRmCodeFreeCode3") {
            this.setState({
                loading: true
            });
            this.state.inputData.mispriorityMonitoringRmCodeFreeCode3ApiCall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData.mispriorityMonitoringRmCodeFreeCode3;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.mispriorityMonitoringRmCodeFreeCode3Name = response.data;
                    this.state.varValue.mispriorityMonitoringRmCodeFreeCode3Name = response.data;
                    console.log(response.data);
                    this.state.inputData.mispriorityMonitoringRmCodeFreeCode3ApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Invalid RM Number!",
                        loading: false
                    })

                })
        } else if (data === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3") {
            this.setState({
                loading: true
            });
            this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3RmName = response.data;
                    this.state.varValue.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3RmName = response.data;
                    this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Invalid RM Number!",
                        loading: false
                    })

                })
        } else if (data === "primryintroducerCustomerIdCifId") {
            this.setState({
                loading: true
            });
            this.state.inputData.introducerIdApiCall = false;
            this.state.varValue.introducerIdApiCall = false;
            let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData.primryintroducerCustomerIdCifId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.primryiintroducerNameLastName = response.data.customerName;
                    this.state.varValue.primryiintroducerNameLastName = response.data.customerName;
                    console.log(response.data);

                    this.state.inputData.introducerIdApiCall = true;
                    this.state.varValue.introducerIdApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })


                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Introducer ID",

                    })
                })
        } else if (data === "guardianDetailsgurdianCifNumberCustomer") {
            this.setState({
                loading: true
            });
            this.state.inputData.guardianDetailsgurdianCifNumberCustomerApiCall = "NO";
            this.forceUpdate();
            let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData.guardianDetailsgurdianCifNumberCustomer;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.guardianDetailslastName = response.data.customerName;
                    this.state.varValue.guardianDetailslastName = response.data.customerName;
                    console.log(response.data);


                    this.state.inputData.guardianDetailsgurdianCifNumberCustomerApiCall = "YES";
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Number"
                    })
                })
        } else if (this.state.inputData.natioanlIdCarddocumentCodeN === {
            "masterDataName": "documentCode",
            "key": "NID",
            "value": "NID CARD IDENTITY"
        } || this.state.inputData.natioanlIdCarddocumentCodeN === {
            "masterDataName": "documentCode",
            "key": "RSNID",
            "value": "RSNID"
        }) {
            this.state.inputData.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = "Y";
            this.state.varValue.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = "Y"
        }

    };

    onKeyDownChange = (data) => {
        let oldNominee = this.state.inputData.nomineeNumberOld ? parseInt(this.state.inputData.nomineeNumberOld) : 0;

        let totalNominee = oldNominee + this.state.inputData["numberOfNominee"];
        for (let i = 0; i < totalNominee; i++) {
            if (data.varName === "search" + (i + 1)) {
                this.state.inputData["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.varValue["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                this.state.inputData["nomineecifId" + (i + 1)] = "";
                this.state.varValue["nomineecifId" + (i + 1)] = "";
                this.state.inputData["nomineeName" + (i + 1)] = "";
                this.state.inputData["nomineeAddress1" + (i + 1)] = "";
                this.state.inputData["nomineeAddress2" + (i + 1)] = "";
                this.state.inputData["nomineeCityCode" + (i + 1)] = "";
                this.state.inputData["nomineeStateCode" + (i + 1)] = "";
                this.state.inputData["nomineePostalCode" + (i + 1)] = "";
                this.state.inputData["nomineeCountry" + (i + 1)] = "";
                this.state.inputData["percentageNominee" + (i + 1)] = "";
                this.state.inputData["nomineeRelationship" + (i + 1)] = "";
                this.state.varValue["percentageNominee" + (i + 1)] = "";
                this.state.varValue["nomineeRelationship" + (i + 1)] = "";
                this.state.inputData["nomineeDob" + (i + 1)] = "";
                this.state.varValue["nomineeDob" + (i + 1)] = "";
                this.state.varValue["nomineeName" + (i + 1)] = "";
                this.state.varValue["nomineeAddress1" + (i + 1)] = "";
                this.state.varValue["nomineeAddress2" + (i + 1)] = "";
                this.state.varValue["nomineeCityCode" + (i + 1)] = "";
                this.state.varValue["nomineeStateCode" + (i + 1)] = "";
                this.state.varValue["nomineePostalCode" + (i + 1)] = "";
                this.state.varValue["nomineeCountry" + (i + 1)] = "";
                this.state.varValue["nomineeDob" + (i + 1)] = "";
                this.state.inputData["nomineeMinor" + (i + 1)] = "";
                this.state.varValue["nomineeMinor" + (i + 1)] = "";
                this.forceUpdate();
            }
            if (data.varName === "nomineeDob" + (i + 1)) {
                this.setState({
                    loading: true
                });
                this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                //  this.forceUpdate()
                setTimeout(() => {
                    let x = 18;
                    let currentDate = new Date();
                    let someDate = new Date();
                    let numberOfDaysToAdd = x;
                    someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let convertDate = year + "-" + month + "-" + date;
                    let newDate = new Date(convertDate);
                    let stringToDate = new Date(newDate.toDateString());
                    let inputDate = new Date(this.state.inputData["nomineeDob" + (i + 1)]);
                    if (stringToDate >= inputDate) {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "NO";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                        // this.state.varValue["nomineeMinor" + i] == "NO";
                    } else {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "YES";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = true;


                    }

                    this.forceUpdate()
                }, 1000);
                this.setState({
                    showValue: true,
                    getData: true,
                    loading: false
                })


            }
        }


        let inputData = this.state.inputData;
        let varValue = this.state.varValue;
        if (data.varName === "homeAddressaddrssType") {

            if (this.state.inputData.homeAddressaddrssType === {
                "masterDataName": "addressType",
                "key": "Mailing",
                "value": "Mailing"
            }) {
                this.state.inputData.homeAddressFieldShow = false;
                this.state.varValue.homeAddressFieldShow = false;
                Functions.sleep(500).then(() => {
                    this.state.inputData.homeAddresshomeAddressField1 = this.state.inputData.maillingAdressmailingAddressField1;
                    this.state.varValue.homeAddresshomeAddressField1 = this.state.inputData.maillingAdressmailingAddressField1;

                    this.state.inputData.homeAddresshomeAddressField2 = this.state.inputData.maillingAdressmailingAddressField2;
                    this.state.varValue.homeAddresshomeAddressField2 = this.state.inputData.maillingAdressmailingAddressField2;

                    this.state.inputData.homeAddresscity = this.state.inputData.maillingAdresscity;
                    this.state.varValue.homeAddresscity = this.state.inputData.maillingAdresscity;

                    this.state.inputData.homeAddressstate = this.state.inputData.maillingAdressstate;
                    this.state.varValue.homeAddressstate = this.state.inputData.maillingAdressstate;

                    this.state.inputData.homeAddresspostalCode = this.state.inputData.maillingAdresspostalCode;
                    this.state.varValue.homeAddresspostalCode = this.state.inputData.maillingAdresspostalCode;

                    this.state.inputData.homeAddresscountry = this.state.inputData.maillingAdresscountry;
                    this.state.varValue.homeAddresscountry = this.state.inputData.maillingAdresscountry;
                    this.state.inputData.homeAddressFieldShow = true;
                    this.state.varValue.homeAddressFieldShow = true;
                    this.forceUpdate()
                })
            }


        } else if (data.varName === "fincoreDataUpdatesectorCode" || data.varName === "sectorCode") {
            this.state.inputData.sectorCodeApicall = false;
            let codeUrl = backEndServerURL + "/finMasterData/custom/subSector/" + this.state.inputData[data.varName]["key"];//{sectorCode}
            axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    {
                        this.findByVarNameApiData("fincoreDataUpdatesubSectorCode", response.data)
                    }
                    {
                        this.findByVarNameApiData("subSectorCode", response.data)
                    }
                    this.state.inputData.sectorCodeApicall = true;
                    this.forceUpdate()


                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (data.varName === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3" || data.varName === "mispriorityMonitoringRmCodeFreeCode3") {
            let getData = "";
            if (data.varName === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3") {
                getData = "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3"
            } else {
                getData = "mispriorityMonitoringRmCodeFreeCode3"

            }
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + getData;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        errorMessage: "RM Code Is Not Found,Please again Try!",
                        errorModalBoolean: true
                    })
                })
                .catch((error) => {
                    console.log(error)

                })
        } else if (data.varName == "basicInformationUpdatedob") {
            this.state.inputData.basicInformationUpdatedobApiCall = "NO";
            this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N";
            this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N";
            //this.updateComponent()
            //if(data.value==="basicInformationUpdatedob"){
            setTimeout(() => {
                let x = 18;
                var currentDate = new Date();

                let someDate = new Date();
                let numberOfDaysToAdd = x;
                someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                let date = someDate.getDate();
                let month = someDate.getMonth() + 1;
                let year = someDate.getFullYear();
                let convertDate = year + "-" + month + "-" + date;
                let newDate = new Date(convertDate);
                let stringToDate = new Date(newDate.toDateString());

                let inputDate = new Date(this.state.inputData.basicInformationUpdatedob);

                if (stringToDate >= inputDate) {
                    this.state.inputData.basicInformationUpdatedobApiCall = "YES";
                    this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N";
                    this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
                } else {
                    this.state.inputData.basicInformationUpdatedobApiCall = "YES";
                    this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "Y";
                    this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "Y"
                }

                x = 60;
                currentDate = new Date();
                someDate = new Date();
                numberOfDaysToAdd = x;
                someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                date = someDate.getDate();
                month = someDate.getMonth() + 1;
                year = someDate.getFullYear();
                convertDate = year + "-" + month + "-" + date;
                newDate = new Date(convertDate);
                stringToDate = new Date(newDate.toDateString());

                inputDate = new Date(this.state.inputData.basicInformationUpdatedob);
                if (stringToDate >= inputDate) {
                    inputData.basicInformationUpdateseniorCitizenIndicator = "Y";
                    varValue.basicInformationUpdateseniorCitizenIndicator = "Y"


                } else {

                    inputData.basicInformationUpdateseniorCitizenIndicator = "N";
                    varValue.basicInformationUpdateseniorCitizenIndicator = "N"
                }

                this.forceUpdate()

            }, 1000)

        }
    };

    renderForm = () => {
        return (
            <>
                {
                    CommonJsonFormComponent.renderJsonForm(this.state, this.state.jsonArray, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
                }
            </>
        )

    };

    renderJsonFormFirst = () => {
        if (this.state.getData) {
            return (
                <React.Fragment>
                    {this.renderForm()}
                </React.Fragment>
            )
        }
    };

    renderButton = () => {
        if (this.state.getData) {
            return (
                <div style={{marginTop: "10px", textAlign: "center"}}>
                    {(this.state.inputData.apiButtonType === "CB UPDATE" && this.props.readOnly) && <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',

                            }}
                            disabled={this.state.disabled}
                            type='button' value='add more'
                            onClick={this.handleSubmit}
                        >CB UPDATE
                        </button>
                        &nbsp;&nbsp;&nbsp;</>}
                    {(this.props.readOnly === undefined) && <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',

                            }}
                            disabled={this.state.disabled}
                            type='button' value='add more'
                            onClick={this.handleSubmit}
                        >SUBMIT
                        </button>
                        &nbsp;&nbsp;&nbsp;</>}
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={() => this.props.closeModal()}
                    >CLOSE
                    </button>
                </div>
            )
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
        });
    };

    inboxCasePhoto = () => {
        return (<VerifyMakerPhoto documentList={this.state.documentList}/>)
    };


    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state)

        return (
            <div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >Information Update<a><CloseIcon onClick={this.close} style={{
                            position: 'absolute',
                            right: 10,
                            color: "#000000"
                        }}/></a></h4>
                    </CardHeader>
                    <CardBody>
                        <Grid container spacing={1}>
                            <Grid item xs={8}>
                                {this.inboxCasePhoto()}
                            </Grid>
                            <Grid item xs={4}>
                                <GridList cellHeight={window.innerHeight} cols={1}>
                                    <div>
                                        <React.Fragment>
                                            <Dialog
                                                fullWidth="true"
                                                maxWidth="sm"
                                                className={classes.modal}
                                                classes={{paper: classes.dialogPaper}}
                                                open={this.state.loading}>
                                                <DialogContent className={classes.dialogPaper}>
                                                    <center>
                                                        <img src={loader} alt=""/>
                                                    </center>
                                                </DialogContent>
                                            </Dialog>
                                            <Dialog
                                                fullWidth="true"
                                                maxWidth="sm"
                                                className={classes.modal}
                                                classes={{paper: classes.dialogPaper}}
                                                open={this.state.cbNotFound}>
                                                <DialogContent className={classes.dialogPaper}>
                                                    <CBNotFound
                                                        closeModal={this.closeModalCBNotFound}
                                                        title={this.state.title}
                                                    />
                                                </DialogContent>
                                            </Dialog>
                                            <Grid container spacing={0}>
                                                <ThemeProvider theme={theme}>
                                                    {this.renderNotification()}
                                                    {this.renderJsonFormFirst()}
                                                </ThemeProvider>
                                            </Grid>
                                            {this.renderButton()}
                                            <Grid item xs={12}><br/></Grid>
                                        </React.Fragment>
                                    </div>
                                </GridList>
                            </Grid>
                        </Grid>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default withStyles(styles)(ExistingMandateDetails);