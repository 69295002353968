import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../Common/Constant";
import Functions from '../../../Common/Functions';
import GridList from "@material-ui/core/GridList/index";
import Grid from "@material-ui/core/Grid/index";
import CommonJsonFormComponent from '../../JsonForm/CommonJsonFormComponent';
import GridItem from "../../Grid/GridItem.jsx";
import Table from "../../Table/Table";
import axios from 'axios/index';
import Notification from "../../NotificationMessage/Notification";
import DateComponent from "../../JsonForm/DateComponent";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import {HombujsonFormReadOnly} from "../WorkflowJsonFormRashed"
import MyValidation from "../../JsonForm/MyValidation";
import WaiverList from "./MerchantWaiverList";
import ConfirmAlert from "../CommonComponent/ConfirmAlert";
import ErrorModal from "../CommonComponent/ErrorModal";
import DedupFullResultButton from "../CommonComponent/DedupFullResultButton";


let checkerRemarks = [
    {
        "varName": "mouCheckerRemarks",
        "type": "textArea",
        "label": "Remarks",
        "required": true,
        "grid": 12
    }];


var agreementSignUpDate = {
    "varName": "agreementSignUpDate",
    "type": "date",
    "label": "Agreement Sign Up Date",
    "grid": 4,
    "readOnly": true
};


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};

class splitChecker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: true,
            getDeferalList: [],
            defferalData: false,
            getData: false,
            images: [],
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            sendTo: false,
            documentList: [],
            getDocument: false,
            selectedDate: {},
            agreementSignUpDate: "",
            remarks: false,
            getRemarkList: [],
            disabled: false,
            loading: true,
            alowApiCall: false,
            errorModalBoolean: false,
            errorTittle: "",
             closeConfirmAlert:false,
            confirmAlert:"",
        };


    }

    modalClose = () => {
        this.props.closeModal();
    };

    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };

    componentDidMount() {
        //console.log(this.props.appId)


        let url = backEndServerURL + "/variables/" + this.props.appId;

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
               let inputData= response.data;
               let varValue= response.data;
                inputData.mouCheckerRemarks = null;
                varValue.mouCheckerRemarks = null;
                if (inputData.apiButton === undefined) {

                    inputData.apiButton = "createMerchantCorporateCustomer";
                    varValue.apiButton = "createMerchantCorporateCustomer";
                }
                this.setState({
                    varValue: varValue,
                    inputData: this.copyJson(inputData),
                    getData: true,
                    loading: false
                });

                let documentsUrl = backEndServerURL + "/case/files/" + this.props.appId;
                axios.get(documentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        this.setState({
                            // images: response.data,
                            documentList: response.data,
                            getDocument: true
                        });

                        let defferalUrl = backEndServerURL + "/case/deferral/" + this.props.appId;

                        axios.get(defferalUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {

                                console.log(response.data);
                                if (response.data == '') {
                                    console.log('empty')
                                } else {
                                    let deferealData = [];

                                    response.data.map((deferal) => {

                                        var status;
                                        if (deferal.status === "ACTIVE") {
                                            status = "Approved"
                                        } else if (deferal.status === "APPROVAL_WAITING") {
                                            status = "NOT APPROVED"
                                        } else {
                                            status = "Not Approved";
                                        }

                                        deferealData.push(this.createTableForDeferal(deferal.id, deferal.type,deferal.deferalCategory,deferal.deferalSubCategory,deferal.dueDate, deferal.appliedBy, deferal.applicationDate,deferal.deferalDstId,deferal.deferalRmCode, status))
                                    });

                                    this.setState({
                                        getDeferalList: deferealData,
                                        defferalData: true
                                    })
                                }

                            })
                            .catch((error) => {
                                console.log(error)
                            })

                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            });


        let remarksUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;

        axios.get(remarksUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                if (response.data.length > 0) {
                    let remarkData = [];


                    response.data.map((res) => {

                        remarkData.push(this.createTableForRemarks(res.remarks, res.createByUserName, res.applicationRemarksDate, res.createByUserRole))

                    });

                    this.setState({
                        getRemarkList: remarkData,
                        remarks: true
                    });

                    console.log(this.state.getRemarkList)
                } else {
                    this.setState({
                        remarks: false
                    })
                }


            })
            .catch((error) => {
                console.log(error)
            })
    }


    createTableForRemarks = (remarks, name, a, b) => {
        return ([
            remarks, name, a, b
        ])
    };

    renderRemarksTable = () => {

        if (this.state.getRemarkList.length > 0) {
            return (
                <div style={{marginBottom: '20px', marginTop: '20px'}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b style={{padding: '20px 10px 10px'}}>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarkList}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>
            )
        }
    };


    createTableForDeferal = (id, type, category,subCategory,dueDate, appliedBy, applicationDate, dstId,rmCode,status) => {
        return ([
            type, category,subCategory,dueDate, appliedBy, applicationDate, dstId,rmCode,status
        ])
    };

    renderDeferalApprovalTableData = () => {

        if (this.state.defferalData === true && this.state.getDeferalList.length > 0) {

            return (
                <div style={{marginTop: '10px'}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b style={{padding: "10px"}}>Deferral List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type","Category","Expire Days", "Expire Date", "Raise By", "Raise Date","DST ID","RM Code", "Status"]}                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        }
    };


    updateComponent = () => {
        this.forceUpdate();
    };


    renderFormWithData = () => {
        if (this.state.getData) {
            return CommonJsonFormComponent.renderJsonForm(this.state, HombujsonFormReadOnly, this.updateComponent)
        }
    };

    renderRemarks = () => {

        return (

            CommonJsonFormComponent.renderJsonForm(this.state, checkerRemarks, this.updateComponent)

        )
    };

    submitHandler = () => {
        this.setState({
            disabled: true,
            loading: true,
        });

        if (this.state.inputData.mouCheckerRemarks !== null) {
            var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.mouCheckerRemarks + "/" + this.props.appId;
            axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    //console.log(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        let variablesUrl = backEndServerURL + "/variables/" + this.props.appId;

        let postDate = this.state.inputData;
        postDate.mou_checker_approval = "APPROVED";
        postDate.mouCheckerRemarks = undefined;

        axios.post(variablesUrl, postDate, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                axios.get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.setState({
                            title: "Successful!",
                           // notificationMessage: "Approved Successfully!",
                            notificationMessage: "Successfully Routed!",
                            alert: true,
                            disabled: false,
                            loading: false,
                        });
                        this.modalClose()
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            disabled: false,
                            loading: false,
                        });
                    })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    disabled: false,
                    loading: false,
                });
            })

    };

    agreementSignUp = () => {
        let field = JSON.parse(JSON.stringify(agreementSignUpDate));
        field.varName = "agreementSignUpDate";
        return DateComponent.date(this.state, this.updateComponent, field);
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    returnHandler = (data) => {

        let error = MyValidation.defaultValidation(checkerRemarks, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                disabled: true,
                loading: true,
            });

            if (this.state.inputData.mouCheckerRemarks !== null) {
                var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.mouCheckerRemarks + "/" + this.props.appId;
                axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        //console.log(response.data)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }

            let deferalCheckUrl = backEndServerURL + "/variables/" + this.props.appId;

            let postData = this.state.inputData;
            postData.mou_checker_approval = "RETURN";
            postData.mouCheckerRemarks = undefined;

            axios.post(deferalCheckUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                    axios.get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            this.setState({
                                title: "Successful!",
                               // notificationMessage: "Return Successfully!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                disabled: false,
                                loading: false,
                            });
                            this.modalClose()
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                disabled: false,
                                loading: false,
                            });
                        })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        disabled: false,
                        loading: false,
                    });
                });
        }
    };

    functionForWaiver = () => {
        if (this.state.getData) {
            return (
                <>
                    <br/>
                    <WaiverList appId={this.props.appId}/>
                </>
            )
        }
    };
    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "Found Error in finacle database!"
                }
            } else {
                return "Found Error in finacle database!"
            }
        } else {
            return "Found Error in finacle database!"
        }
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        return clone;
        /* for (let prop in clone)
             if (clone[prop] === '' || clone[prop] === ' '  || clone[prop] === "null"   ||  clone[prop] === "undefined" )
                 delete clone[prop];
         return clone;*/
    };
    variableSetFunction=(data)=>{
        let variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                 this.setState({
                    inputData: this.emptyValueRemove(data),
                    varValue: this.emptyValueRemove(data),
                    getData: true,
                    loading: false,
                    alowApiCall: true
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    approveHandler = (actionType) => {
        this.setState({
            loading: true,
        });
        if (actionType === "createMerchantCorporateCustomer") {
            let url = backEndServerURL + "/createMerchantCorporateCustomer/" + this.props.appId;
            axios.post(url,{}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                   // if(response.data.responseCode==="100" && response.data.corporateCustomerId!==undefined && response.data.corporateCustomerId!==null){
                        let inputData = this.state.inputData;
                        inputData.corporateCustomerId=response.data.corporateCustomerId;
                        inputData.apiButton = "createSettlementMerchantInterBankAccount";
                        this.variableSetFunction(inputData)
                   /* }
                    else{
                        this.setState({
                            alowApiCall: true,
                            errorModalBoolean: true,
                            errorTittle: this.errorObjectCheck(response.data.responseMessage),
                            loading: false,

                        })
                    }*/

                })
                .catch((error) => {
                    this.setState({
                        alowApiCall: true,
                        errorModalBoolean: true,
                        errorTittle: this.errorObjectCheck(error),
                        loading: false,

                    })
                })
        } else if (actionType==="createSettlementMerchantInterBankAccount" || actionType === "createTransitMerchantInterBankAccount") {
            let url = backEndServerURL + "/createMerchantInterBankAccount/" + this.props.appId;
            axios.post(url,{}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let inputData = this.state.inputData;
                    inputData.apiButton = "createRetailer";
                    this.variableSetFunction(inputData)
                })
                .catch((error) => {
                    this.setState({
                        alowApiCall: true,
                        errorModalBoolean: true,
                        errorTittle: this.errorObjectCheck(error),
                        loading: false,

                    })
                })
        }
        else if (actionType === "createRetailer") {
            let url = backEndServerURL + "/createRetailer/" + this.props.appId;
            axios.post(url,{}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let inputData = this.state.inputData;
                    inputData.apiButton = "finalApproval";
                    this.variableSetFunction(inputData)
                })
                .catch((error) => {
                    this.setState({
                        alowApiCall: true,
                        errorModalBoolean: true,
                        errorTittle: this.errorObjectCheck(error),
                        loading: false,

                    })
                })
        }
        else if (actionType === "finalApproval") {

            let variablesUrl = backEndServerURL + "/variables/" + this.props.appId;

            let postData = this.state.inputData;
            postData.mou_checker_approval = "APPROVED";

            axios.post(variablesUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    console.log('Approved');

                    let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                    axios.get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log('Case Route Successful');
                            this.modalClose();

                            this.setState({
                                title: "Successfull!",
                                // notificationMessage: "Approved Successfully!",
                                notificationMessage: "Successfully Routed!",
                                alert: true

                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        })

                })
                .catch((error) => {
                    console.log(error)
                });

            if (this.state.inputData.mouCheckerRemarks !== null) {

                var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.mouCheckerRemarks + "/" + this.props.appId;
                axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        }

    };
    validationForHandleConfirm = (actionType) => {
        this.setState({
            loading: true,
        });
        let dependencyField = [];
        if (actionType === "NOTAPPROVED" || actionType === "RETURN_TO_CS") {
            dependencyField.push({

                "varName": "mouRemarks",
                "type": "textArea",
                "required": true,
                "label": "  Remarks",
                "grid": 12
            })

        }
        this.setState({
            loading: false,
        });

        this.setState({
            loading: false,
            confirmAlert: true,
            actionType: actionType
        })


    };
    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()

        {
            this.validationForHandleConfirm(type)
        }


    };
    closeConfirmAlert = (data) => {
        this.setState({
            confirmAlert: false
        });
        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "APPROVED" ) {
            this.approveHandler(this.state.inputData.apiButton)
        } else if (data === "YES" && this.state.actionType === "RETURN_TO_CS") {
            this.returnHandler("RETURN_TO_CS")
        }

    };
    renderSubmitButton = () => {
        if (this.state.getData) {
            if (this.state.inputData.apiButton === undefined && this.state.loading === false) {
                return (
                    <Grid item xs={12}>

                        <div>
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}

                                type='button' value='add more'
                                onClick={(event) => this.handleCommon(event, "APPROVED")}

                            >Approve
                            </button>


                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}
                                checkerApproval

                                type='button' value='add more'
                                onClick={(event) => this.handleCommon(event, "RETURN_TO_CS")}

                            >Return
                            </button>
                        </div>


                    </Grid>

                )
            } else {
                return (
                    <Grid item xs={12}>

                        <div>

                            {this.state.inputData.apiButton === "createMerchantCorporateCustomer" && this.state.loading === false &&
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}

                                type='button' value='add more'
                                onClick={(event) => this.handleCommon(event, "APPROVED")}

                            >Create Merchant Corporate Customer
                            </button>}

                            {(this.state.inputData.apiButton === "createSettlementMerchantInterBankAccount" || this.state.inputData.apiButton==="createTransitMerchantInterBankAccount" ) && this.state.loading === false &&
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}

                                type='button' value='add more'
                                onClick={(event) => this.handleCommon(event, "APPROVED")}

                            >Create Merchant Inter Bank Account
                            </button>}
                            {this.state.inputData.apiButton === "createRetailer" && this.state.loading === false &&
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}

                                type='button' value='add more'
                                onClick={(event) => this.handleCommon(event, "APPROVED")}

                            >Create Retailer
                            </button>}
                            &nbsp;&nbsp;&nbsp;&nbsp;

                            {this.state.inputData.apiButton === "finalApproval" && this.state.loading === false &&
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}

                                type='button' value='add more'
                                onClick={(event) => this.handleCommon(event, "APPROVED")}

                            >Final Approval
                            </button>}

                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}
                                checkerApproval

                                type='button' value='add more'
                                onClick={(event) => this.handleCommon(event, "RETURN_TO_CS")}

                            >Return
                            </button>


                        </div>


                    </Grid>

                )
            }
        } else {
            return (
                <div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
            )
        }


    };
    closeModal = () => {
        this.setState({

             errorModalBoolean: false


        })
    };
    renderSDNDedupeResult = () => {
        if (  this.state.getData) {
            return (
                <DedupFullResultButton  merchantDedup={true} closeModal={this.closeModalForSdnResult}
                                       branchName={this.props.branchName}
                                       inputData={this.state.inputData}
                                       subServiceType="Joint Account"
                                       category="CASA"
                                       serviceType="Account Opening"
                                       subCategory="A/C Opening"
                                       freeFlag2={this.props.freeFlag2 === "externalUserClosedRequest" ? true : false}
                                       serviceType={this.props.serviceType!==undefined?this.props.serviceType:this.state.inputData.serviceType}
                                       customerName={this.props.customerName}
                                       accountNumber={this.props.accountNumber}
                                       cbNumber={this.props.cbNumber}
                                       solId={this.props.solId} globalSearch={true}
                                       closeIcon={this.closeModalForSdnResult}
                                       appId={this.props.appId!==undefined?this.props.appId:this.state.caseId}/>


            )
        }

    };
    render() {
        console.log('....SplitApproval......');
        console.log(this.props.appId);
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }


            return (
                <GridList cellHeight={window.innerHeight} cols={1}>
                    <GridItem xs={12} sm={12} md={12}>
                        <Grid container>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.errorModalBoolean}>
                                <DialogContent className={classes.dialogPaper}>
                                    <ErrorModal title={this.state.errorTittle}
                                                closeModal={this.closeModal}
                                                subServiceType={this.props.subServiceType}
                                                appId={this.props.appId}
                                    />

                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.loading}>
                                <DialogContent className={classes.dialogPaper}>

                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.confirmAlert}>
                                <DialogContent className={classes.dialogPaper}>
                                    <ConfirmAlert closeModal={this.closeConfirmAlert}/>

                                </DialogContent>
                            </Dialog>
                            {   this.renderSDNDedupeResult()}
                            <Grid item xs={12}>
                            </Grid>
                            {
                                this.renderFormWithData()
                            }

                            {/*{*/}
                            {/*    this.merchantTypeForm()*/}
                            {/*}*/}
                            <Grid item xs={12}>
                                {
                                    this.renderDeferalApprovalTableData()
                                }
                            </Grid>

                            <Grid item xs={12}>
                                {
                                    this.functionForWaiver()
                                }
                            </Grid>

                            <Grid item xs={12}>
                                {
                                    this.renderRemarksTable()
                                }
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    this.renderRemarks()
                                }
                            </Grid>

                            {this.renderSubmitButton()}
                            {
                                this.renderNotification()
                            }


                        </Grid>
                    </GridItem>
                </GridList>

            )



    }


}

export default withStyles(styles)(splitChecker);
