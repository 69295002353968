import React, {Component} from "react";
import Grid from "@material-ui/core/Grid/index";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios/index";
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import DebitCardCheckerSplit from "../DebitCard/DebitCardCheckerSplit";
import CardHeader from "../../Card/CardHeader";
import CloseIcon from "@material-ui/icons/Close";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import loader from "../../../Static/loader.gif";
import SignatureButton from "../../workflow/Maintenance/SignatureButton";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};
let pdfForm = [
    {
        varName: "eqmNumber",
        type: "text",
        label: "EQM Number",
        grid: 2,
        readOnly: true,
    },
    {
        varName: "accountNumber",
        type: "text",
        label: "Account Number",
        grid: 2,
        readOnly: true
    },
    {
        varName: "customerName",
        type: "text",
        label: "Customer Name",
        readOnly: true,
        multiline: true,
        grid: 2
    },
    {
        varName: "accountStatus",
        type: "text",
        label: "Account Status",
        readOnly: true,
        grid: 2,
    },
    {
        "label": "A/C Balance",
        "type": "text",
        "varName": "accountBalance",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "showAccountBalance",
        "conditionalVarValue": true,
    },
    {
        varName: "accountType",
        type: "text",
        label: "Account Type",
        required: true,
        readOnly: true,
        grid: 2,
    },
    {
        varName: "mobileNumber",
        type: "text",
        label: "Mobile Number",
        // required: true,
        readOnly: true,
        grid: 2,
    },
    {
        varName: "numberOfChequeBook",
        type: "select",
        label: "No. of Cheque Book",
        required: true,
        grid: 2,
        enum: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]
    },
    {
        varName: "pageofChequebook",
        type: "select",
        label: "Page of Chequebook",
        required: true,
        grid: 2,
        conditionalOrValue: true,
        conditionalVarName: "accountType",
        conditionalOrList: ["Savings", "Savings(Alo)", "Savings(Ababil Alo)"],
        enum: ["10", "25"]
    },
    {
        varName: "pageofChequebook",
        type: "select",
        label: "Page of Chequebook",
        required: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "accountType",
        conditionalVarValue: "Current",
        enum: ["25", "50", "100"]
    },
    {
        varName: "deliveryType",
        type: "text",
        label: "Delivery Type",
        required: true,
        readOnly: true,
        grid: 2,
    },

    {
        varName: "branchName",
        type: "autoCompleteValueReturn",
        label: "Branch Name",
        required: true,
        conditional: true,
        conditionalVarName: "deliveryType",
        conditionalVarValue: "Branch",
        grid: 2,
    },
    {
        varName: "customerAddress",
        type: "text",
        label: "Customer Address",
        required: true,
        conditional: true,
        conditionalVarName: "deliveryType",
        conditionalVarValue: "Courier",
        grid: 2,
    },
    {
        varName: "customerType",
        type: "text",
        readOnly: true,
        label: "Customer Type",
        // enum: ["General", "Priority", "Sapphire"],
        grid: 2,
    },
    {
        varName: "checqueBookDesign",
        type: "select",
        label: "Chequebook Design",
        conditional: true,
        conditionalVarName: "customerType",
        conditionalVarValue: "Priority",
        grid: 2,
        enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5"],
    },
    {
        varName: "checqueBookDesign",
        type: "select",
        label: "Chequebook Design",
        conditional: true,
        conditionalVarName: "customerType",
        conditionalVarValue: "Sapphire",
        grid: 2,
        enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5", "Design Code 6"],
    },
    {
        varName: "chequeBookType",
        type: "select",
        label: "Cheque Book Type",
        enum: ["Normal", "A4"],
        grid: 2,
    },
];

class ChequeBookSplit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: [],
            showValue: false,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            autoComplete: {},
            errorArray: {},
            errorMessages: {},
            searchValue: true,
            appId: 0,
            getMappingAllImage: false,
            fileUploadData: {},
            uploadModal: false,
            preview: false,
            pastDocumentModal: false,
            documentList: [],
            getDocument: false,
            getData: false,
            loading: true,
            signatureModal: false,
            branch: []
        };
    }

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    getVariables() {
        let url = backEndServerURL + "/variables/" + this.props.appId;
        axios
            .get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let varValue = response.data;
                varValue.branchName = this.findByVarNameGetKeyValue(response.data.deliveryBranchSolId, this.state.branch);
                setTimeout(() => {
                    this.setState({
                        varValue: this.copyJson(varValue),
                        appData: this.copyJson(response.data),
                        inputData: this.copyJson(response.data),
                        showValue: true,
                        getData: true,
                        loading: false,
                        appId: this.props.appId
                    });
                }, 1000)
            })
            .catch(error => {
                console.log(error);
            });
    }

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
            signatureModal: false,
        })
    };

    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;

        if (value && (typeof value !== "string")) {
            return value
        } else {
            console.log(value);
            for (let i = 0; i < jsonArray.length; i++) {
                let jsonObject = jsonArray[i];
                if (value === jsonObject.key) {
                    return jsonObject;
                }
            }
        }
    };

    getBranchAll() {
        let branch = [];
        let branchUrl = backEndServerURL + "/workplaceUnit/getInitialUnits";
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((item) => {
                    branch.push({key: item.solId, value: item.name})
                });
                this.setState({
                    branch: branch
                });
                for (let form of pdfForm) {
                    if (form.type === "autoCompleteValueReturn" && form.varName === "branchName") {
                        form.enum = branch
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    signatureCard = () => {
        this.setState({
            signatureModal: true
        })
    };
    updateComponent = () => {
        this.forceUpdate();
    };

    componentDidMount() {

        if (this.props.appId !== undefined) {
            this.getBranchAll();
            this.getVariables();
        } else {
            this.setState({
                getData: true
            });
        }
    }

    inboxCase = () => {
        return (
            <DebitCardCheckerSplit
                closeModal={this.props.closeModal}
                documentList={this.state.documentList}
                appId={this.props.appId}
                form={this.renderFormWithData}
            />
        );
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type="success"
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        });
    };

    saveHandler = event => {
        event.preventDefault();
        let DeliveryBranchIfo = this.state.inputData.branchName;
        let postData = this.state.inputData;
        postData.deliveryBranchSolId = DeliveryBranchIfo.key;
        postData.branchName = DeliveryBranchIfo.value;

        let url = backEndServerURL + "/variables/" + this.state.appId;
        axios
            .post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let caseRouteUrl =
                    backEndServerURL + "/case/route/" + this.state.appId;
                axios
                    .get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => {

                        this.setState({
                            title: "Successful!",
                            notificationMessage: "Successfully Routed!",
                            alert: true,
                            getData: false
                        });
                        this.props.closeModal()


                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {

                console.log('Cheque Book request error');
            });


        // let uploadUrl = backEndServerURL + "/case/upload";
        // let formData = new FormData();
        // formData.append("appId", this.state.appId);
        // formData.append("file", this.state.fileUploadData["fileUpload"]);
        // formData.append("type", "file");
        //
        // axios({
        //     method: "post",
        //     url: uploadUrl,
        //     data: formData,
        //     headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,"content-type": "multipart/form-data"}
        // })
        //     .then(response => {
        //
        //     })
        //     .catch(error => {
        //         this.setState({
        //             cbNotFound: true,
        //             title: 'File Missing'
        //         });
        //     });
    };

    handleClose = (event) => {
        event.preventDefault();
        let variableSetUrl = backEndServerURL + "/case/close/" + this.state.appId;
        axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Close!",
                        alert: true
                    });
                    this.props.closeModal();
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };
    signature = () => {
        if (this.state.getData) {
            return (
                <>
                    <SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
                                     classes={this.props}/>
                </>
            )
        }
    };

    render() {
        const {classes, onClose} = this.props;
        if (this.state.getData) {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >FDD Payorder Requisition (<b> Customer
                                Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                                <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                                <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}
                                <b> SOLID:</b>{this.props.solId !== undefined ? this.props.solId : ""})<a><CloseIcon
                                    onClick={this.props.closeModal} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a></h4>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        pdfForm,
                                        this.updateComponent
                                    )}
                                    {/*<a*/}
                                    {/*    className="btn btn-outline-primary btn-sm"*/}
                                    {/*    style={{*/}
                                    {/*        marginTop: 20,*/}
                                    {/*    }}*/}
                                    {/*    target="_blank"*/}
                                    {/*    href={backEndServerURL + "/file/" + this.props.file}*/}
                                    {/*>*/}
                                    {/*    Preview Document*/}
                                    {/*</a>*/}
                                </ThemeProvider>
                            </Grid>
                            <Grid item xs={12}>
                                {this.signature()}
                            </Grid>

                            <Grid item xs="3" style={{marginTop: "10px"}}>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        marginRight: "10px",
                                    }}
                                    onClick={this.saveHandler}
                                >
                                    Submit
                                </button>
                                &nbsp;
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={this.handleClose}
                                >
                                    Close
                                </button>
                            </Grid>
                            {
                                this.renderNotification()
                            }
                        </CardBody>
                    </Card>
                </div>
            );
        } else {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        }
    }

}

export default withStyles(styles)(ChequeBookSplit);
