import Table from "../Table/Table.jsx";
import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import {Dialog, Grow} from "@material-ui/core";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import {backEndServerURL} from "../../Common/Constant";
import BranchAddEditDelete from './BranchAddEditDelete';
import axios from "axios";
import 'antd/dist/antd.css';
import DialogContent from "@material-ui/core/DialogContent";
import Functions from '../../Common/Functions';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    dialogPaper: {
        overflow: "visible"
    }
};
const jsonForm = {
    "variables": [

        {
            "varName": "branchName",
            "type": "text",
            "label": "Branch Name",
            "number": false,
            "grid": 2,
        },
        {
            "varName": "createdBy",
            "type": "text",
            "label": "Created By",
            "grid": 2,
        },
        {
            "varName": "modifiedBy",
            "type": "text",
            "label": "Modified By",
            "grid": 2,
        }


    ],

};

function Transition(props) {

    return <Grow in={true} timeout="auto" {...props} />;
}

class Branch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addBranch: false,
            editBranch: false,
            BranchName: '',
            open: true,
            branchId: '',
            tableData: [[" ", " "]],
            branchData: [[" ", " "]],
            redirectLogin: false,


        }
    }

    addBranch() {
        this.setState({
            addBranch: true,
        });
    }

    editBranch = (id) => {
        this.setState({
            editBranch: true,
            branchId: id
        })

    };

    cancelBranchModal = () => {
        this.setState({addBranch: false});
    };
    cancelEditBranchModal = () => {
        this.setState({editBranch: false});
    };
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    closeModal = () => {
        this.setState({
            addBranch: false,
            editBranch: false
        });
    };
    createTableData = (id, branchName, solId, createdBy, modifiedBy, createdDate, modifiedDate, status) => {

        return ([branchName, solId, createdBy, modifiedBy, createdDate, modifiedDate, status,

            <button
                className="btn btn-outline-primary"
                style={{
                    verticalAlign: 'middle',
                }}
                onClick={() => this.editBranch(id)}>
                Edit</button>
        ]);

    };

    /*  getSubmitedForm = (object) => {

          let objectTable = {};
          let tableArray = [];
          for(let variable in object ){
              let trimData=object[variable].trim();
              if(trimData!=='')
                  objectTable[variable] =trimData;
          }

          this.state.tableData.map((branch) => {
              let showable = true;
              for (let variable in objectTable) {
                  if (objectTable[variable] !== branch[variable])
                      showable = false;
              }
              if (showable)
                  tableArray.push(this.createTableData(branch.id, branch.branchName, branch.solId, branch.createdBy, branch.modifiedBy, branch.createdDate, branch.modifiedDate, branch.status));


          })
          this.setState({
              branchData: tableArray
          })


      }*/


    componentDidMount() {
        let url = backEndServerURL + "/branchMaster/getAll";
        let getAllBranch = [];
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((branch) => {

                    getAllBranch.push(this.createTableData(branch.id, branch.branchName, branch.solId, branch.createdBy, branch.modifiedBy, branch.createdDate, branch.modifiedDate, branch.status));


                });
                this.setState({
                    branchData: getAllBranch
                })


            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
            });
    }

    branchAddModal = () => {

        return (
            <BranchAddEditDelete close={this.closeModal} data={this.state.tableData}
                                 name="Add New branch"/>
        )
    };
    branchEditModal = () => {
        return (
            <BranchAddEditDelete close={this.closeModal} data={this.state.tableData}
                                 name="Edit branch" id={this.state.branchId}/>
        )
    };


    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (
            <section>

                <Dialog
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.addBranch}
                >
                    <DialogContent className={classes.dialogPaper}>

                        {this.branchAddModal()}
                    </DialogContent>
                </Dialog>
                <Dialog

                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.editBranch}

                >
                    <DialogContent className={classes.dialogPaper}>

                        {this.branchEditModal()}
                    </DialogContent>

                </Dialog>


                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>Branch List</h4>
                            </CardHeader>
                            <CardBody>
                                <div>

                                    <button
                                        className="btn btn-outline-primary"
                                        style={{
                                            verticalAlign: 'middle',
                                        }}

                                        onClick={() => {
                                            this.setState({
                                                addBranch: true
                                            })
                                        }
                                        }
                                    >
                                        Add New branch

                                    </button>


                                    <Table
                                        tableHeaderColor="primary"
                                        tableHead={["Branch Name", "Sol Id", "Created by", "Modified by", "Created Date", "Modified Date", "Status", "Action"]}
                                        tableData={this.state.branchData}
                                        tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>


                </GridContainer>
            </section>
        );
    }
}

export default withStyles(styles)(Branch);
