import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../Grid/GridItem.jsx";
import GridContainer from "../../Grid/GridContainer.jsx";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CloseIcon from '@material-ui/icons/Close';
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Table from "../../Table/Table";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import Button from "@material/react-button";
import AccountStatement from "../fdr/AccountStatement";
import SdnAndDedupeResultShowing from "./SdnAndDedupeResultShowing";
import DeferalList from "./DeferalList";
import WaiverList from "./WaiverList";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

class EmployeeDeatils extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDate: {},
            inputData: {},
            appId: 0,
            varValue: {},
            loading: true,
            showValue: false,
            getData: false,
            fileUploadData: {},
            errorArray: {},
            errorMessages: {},
            selectType: "",
            tableData: [],
            file: {},
            defferalId: "",
            uploadModal: false,
            getMappingAllImage: false,
            notificationMessage: "",
            getDownloadListFlag: false,
            SdnAndDedupeResultShowing: false,
            fileNotFoundTittle: "",
            downLoadFileList: [],
            getUserStaticData: false,

        }
    }

    updateComponent = () => {
        this.forceUpdate();
    };
    closeModalForCustomerDetails=()=>{
        this.setState({
            loading:false,
            userDeatils:false,
        })
    }

    createTableData = (username, firstName, email, phone, group) => {


        return ([username  , firstName, email,phone,this.props.groupName
        ])

    };
    downloadDocument = (name) => {
        let url = backEndServerURL + "/file/" + name + "/" + sessionStorage.getItem("accessToken");
        window.open(url)
    };

    createDownloadFileLink = (sl, data) => {
        return (
            <React.Fragment>
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}
                    onClick={() => this.downloadDocument(data)}
                >
                    {sl}
                </button>
                &nbsp;&nbsp;
            </React.Fragment>

        )
    };
    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "Finacle System Error!"
                }
            } else {
                return "Finacle System Error!"
            }
        } else {
            return "Finacle System Error!"
        }
    };

    componentDidMount() {

        let url = backEndServerURL + "/getUserStaticData/" + this.props.userId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let tableArray = [];
                let data=response.data;
                    tableArray.push(this.createTableData(data.username, data.firstName, data.email, data.phone,""));

                this.setState({
                    tableData: tableArray,
                    varValue: response.data,
                    showValue: true,
                    getData: true,
                    loading: false,
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    getData: true,
                    loading: false,
                })
            })


    }

    renderCustomerDetails = () => {
        if (this.state.getData) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "2px"}}>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"

                            tableHead={["Employee Id", "Employee Name", "Email Address","Mobile Number","Employee Role"]}
                            tableData={this.state.tableData}
                            tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                        />

                        <br/>

                    </div>
                </div>


            )
        }
    };





    close = () => {
        this.props.closeModal()
    };

    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };
    closeUploadModal = () => {
        this.setState({
            uploadModal: false,
            getMappingAllImage: false,
        })
    };


    closeIcon = () => {
        this.props.closeIcon()
    };
    renderSiCbNumber = () => {
        if (this.props.recpmtid !== undefined && this.props.recpmtid !== null) {
            return (
                <><b> SI CB number:</b>{this.props.recpmtid}</>
            )
        } else {
            return ""

        }
    };

    render() {

        const {classes} = this.props;


        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} > User Details<a><CloseIcon
                                    onClick={this.closeIcon} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a></h4>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <Grid container spacing={3}>
                                    <ThemeProvider theme={theme}>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.loading}>
                                            <DialogContent className={classes.dialogPaper}>

                                                <center>
                                                    <img src={loader} alt=""/>
                                                </center>
                                            </DialogContent>
                                        </Dialog>

                                        <Grid item xs={12}>
                                            {this.renderCustomerDetails()}
                                            <br/>
                                        </Grid>


                                    </ThemeProvider>


                                </Grid>
                            </div>


                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

        );
    }



}

export default withStyles(styles)(EmployeeDeatils);
