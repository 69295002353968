import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Functions from '../../../Common/Functions';
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import Notification from "../../NotificationMessage/Notification";
import loading from "../../../Static/loader.gif";
import CBNotFound from "../CASA/CBNotFound";
import TableComponent from "../CommonComponent/TableComponent";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";

const filterField = [
    {
        varName: "cbNumber",
        label: "CB Number",
        type: "textDedup",
        grid: 2,
    },
    {
        varName: "minAvgSba",
        label: "Min AVG_SBA",
        type: "textDedup",
        grid: 2,
    },
    {
        varName: "maxAvgSba",
        label: "Max AVG_SBA",
        type: "textDedup",
        grid: 2,
    },
    {
        varName: "minAvgCaa",
        label: "Min AVG_CAA",
        type: "textDedup",
        grid: 2,
    },
    {
        varName: "maxAvgCaa",
        label: "Max AVG_CAA",
        type: "textDedup",
        grid: 2,
    },
    {
        varName: "minBalanceFd",
        label: "Min BALANCE_FD",
        type: "textDedup",
        grid: 2,
    },
    {
        varName: "maxBalanceFd",
        label: "Max BALANCE_FD",
        type: "textDedup",
        grid: 2,
    },
    {
        varName: "minBalanceDps",
        label: "Min BALANCE_DPS",
        type: "textDedup",
        grid: 2,
    },
    {
        varName: "maxBalanceDps",
        label: "Max BALANCE_DPS",
        type: "textDedup",
        grid: 2,
    },
    {
        varName: "minBalanceAl",
        label: "Min BALANCE_AL",
        type: "textDedup",
        grid: 2,
    },
    {
        varName: "maxBalanceAl",
        label: "Max BALANCE_AL",
        type: "textDedup",
        grid: 2,
    },
    {
        varName: "minBalancePl",
        label: "Min BALANCE_PL",
        type: "textDedup",
        grid: 2,
    },
    {
        varName: "maxBalancePl",
        label: "Max BALANCE_PL",
        type: "textDedup",
        grid: 2,
    },
    {
        varName: "minBalanceHl",
        label: "Min BALANCE_HL",
        type: "textDedup",
        grid: 2,
    },
    {
        varName: "maxBalanceHl",
        label: "Max BALANCE_HL",
        type: "textDedup",
        grid: 2,
    },
    {
        varName: "minBalanceSme",
        label: "Min BALANCE_SME",
        type: "textDedup",
        grid: 2,
    }, {
        varName: "maxBalanceSme",
        label: "Max BALANCE_SME",
        type: "textDedup",
        grid: 2,
    },
    {
        varName: "minBalanceSnd",
        label: "Min BALANCE_SND",
        type: "textDedup",
        grid: 2,
    },
    {
        varName: "maxBalanceSnd",
        label: "Max BALANCE_SND",
        type: "textDedup",
        grid: 2,
    },
    {
        varName: "minRvValue",
        label: "Min RV_VALUE",
        type: "textDedup",
        grid: 2,
    },
    {
        varName: "maxRvValue",
        label: "Max RV_VALUE",
        type: "textDedup",
        grid: 2,
    },
    {
        varName: "customerType",
        label: "CUSTOMER_TYPE",
        type: "select",
        enum: [
            "MASS",
            "AFFLUENT",
            "HNI"
        ],
        grid: 2,
    },
    // {
    //     varName: "minGph",
    //     label: "Min GPH",
    //     type: "text",
    //     grid: 2,
    // },
    // {
    //     varName: "maxGph",
    //     label: "Max GPH",
    //     type: "text",
    //     grid: 2,
    // },
    // {
    //     varName: "minGsa",
    //     label: "Min GSA",
    //     type: "text",
    //     grid: 2,
    // },
    // {
    //     varName: "maxGsa",
    //     label: "Max GSA",
    //     type: "text",
    //     grid: 2,
    // },
    {
        varName: "bandMovement",
        label: "BAND_MOVEMENT",
        type: "select",
        enum: [
            "No Change",
            "Upgrade",
            "Downgrade",
        ],
        grid: 2,
    },
];


const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

class ListForRV extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            loading: true,
            cbNotFound: false,
            getData: false,
            varValue: {},
            title: "",
            notificationMessage: "",
            alert: false,
            selectedDate: {},
            showValue: false,
            openHierarchy: false,
            err: false,
            searchData: false,
            showTable: false,
            errorArray: {},
            errorMessages: {},
            tableData: [],
            totalRow: 0,
            rowsPerPage: 250,
            tableColumns: [
                {title: "RV_CB", field: "RV_CB"},
                {title: "CUST_NAME", field: "CUST_NAME", type: 'numeric'},
                {title: "AVG_SBA", field: "AVG_SBA"},
                {title: "AVG_CAA", field: "AVG_CAA", type: 'numeric'},
                {title: "BALANCE_FD", field: "BALANCE_FD", type: 'numeric'},
                {title: "BALANCE_DPS", field: "BALANCE_DPS", type: 'numeric'},
                {title: "BALANCE_AL", field: "BALANCE_AL", type: 'numeric'},
                {title: "BALANCE_PL", field: "BALANCE_PL", type: 'numeric'},
                {title: "BALANCE_HL", field: "BALANCE_HL", type: 'numeric'},
                {title: "BALANCE_SME", field: "BALANCE_SME", type: 'numeric'},
                {title: "BALANCE_SND", field: "BALANCE_SND", type: 'numeric'},
                {title: "RV_VALUE", field: "RV_VALUE", type: 'numeric'},
                {title: "HOME_SOL_ID", field: "HOME_SOL_ID", type: 'numeric'},
                {title: "HOME_SOL", field: "HOME_SOL"},
                {title: "HOME_CLUSTER", field: "HOME_CLUSTER"},
                {title: "REGION_NAME", field: "REGION_NAME"},
                {title: "RM_CODE", field: "RM_CODE"},
                {title: "RM_NAME", field: "RM_NAME"},
                {title: "CITYGEM", field: "CITYGEM"},
                {title: "BAND_CURRENT_MONTH", field: "BAND_CURRENT_MONTH"},
                {title: "BAND_PREVIOUS_MONTH", field: "BAND_PREVIOUS_MONTH"},
                {title: "BAND_MOVEMENT", field: "BAND_MOVEMENT", type: 'numeric'},
                {title: "ACTUAL_BAND_MOVEMENT", field: "ACTUAL_BAND_MOVEMENT", type: 'numeric'},
                {title: "CUSTOMER_TYPE", field: "CUSTOMER_TYPE"},
                {title: "HAS_CA", field: "HAS_CA", type: 'numeric'},
                {title: "HAS_FOREIGN_CURRENCY_CA", field: "HAS_FOREIGN_CURRENCY_CA", type: 'numeric'},
                {title: "HAS_SA", field: "HAS_SA", type: 'numeric'},
                {title: "CITY_ALO", field: "CITY_ALO", type: 'numeric'},
                {title: "FD", field: "FD", type: 'numeric'},
                {title: "OD_A_FD", field: "OD_A_FD", type: 'numeric'},
                {title: "STUDENT", field: "STUDENT", type: 'numeric'},
                {title: "LOCKER", field: "LOCKER", type: 'numeric'},
                {title: "AMEX_CREDIT_CARD", field: "AMEX_CREDIT_CARD", type: 'numeric'},
                {title: "VISA_CREDIT_CARD", field: "VISA_CREDIT_CARD", type: 'numeric'},
                {title: "CITY_MAXX_DEBIT_CARD", field: "CITY_MAXX_DEBIT_CARD", type: 'numeric'},
                {title: "HL", field: "HL", type: 'numeric'},
                {title: "PL", field: "PL", type: 'numeric'},
                {title: "AL", field: "AL", type: 'numeric'},
                {title: "SECURED_LOAN_OD", field: "SECURED_LOAN_OD", type: 'numeric'},
                {title: "SECURED_LOAN_TL", field: "SECURED_LOAN_TL", type: 'numeric'},
                {title: "SL_BULLET_PAYMENT", field: "SL_BULLET_PAYMENT", type: 'numeric'},
                {title: "CITY_ALO_LOAN", field: "CITY_ALO_LOAN", type: 'numeric'},
                {title: "HAS_SME_LOAN", field: "HAS_SME_LOAN", type: 'numeric'},
                {title: "GPH", field: "GPH", type: 'numeric'},
                {title: "GSA", field: "GSA", type: 'numeric'},
                {title: "BEFTN_TRAN_LAST_3_MONTH", field: "BEFTN_TRAN_LAST_3_MONTH", type: 'numeric'},
                {title: "SI_TRAN_LAST_3_MONTH", field: "SI_TRAN_LAST_3_MONTH", type: 'numeric'},
                {title: "RTGS_TRAN_LAST_3_MONTH", field: "RTGS_TRAN_LAST_3_MONTH", type: 'numeric'},
                {title: "E_STATEMNT_REGISTRATION", field: "E_STATEMNT_REGISTRATION", type: 'numeric'},
                {title: "CITY_TOUCH_LAST_3_MONTH", field: "CITY_TOUCH_LAST_3_MONTH", type: 'numeric'},
                {title: "HAS_STUDENT_FILE", field: "HAS_STUDENT_FILE", type: 'numeric'},
                {title: "DEBIT_CARD_TRAN_LAST_3_MONTH", field: "DEBIT_CARD_TRAN_LAST_3_MONTH", type: 'numeric'},
                {title: "GENDER", field: "GENDER"},
                {title: "AGE", field: "AGE", type: 'numeric'},
                {title: "CUST_NTB_DATE", field: "CUST_NTB_DATE"},
                {title: "MOBILE", field: "MOBILE"},
                {title: "EMAIL", field: "EMAIL"},
            ],
            page: 0,
            rvValue: 0
        }
    }

    componentDidMount() {
        let url = backEndServerURL + "/rvData/getValueDate";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    rvValue: response.data
                })
            })
            .catch((error) => {
                console.log(error);
            });


        this.handleChangePage(0)
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderCloseIcon = () => {
        return (
            <h4 style={{color: "white"}} >List for RV (Value Date: {this.state.rvValue})</h4>
        )
    };

    closeModalCBNotFound = () => {
        this.setState({
            openHierarchy: false,
            cbNotFound: false
        })
    };

    handleChangePage = (pageNumber) => {
        if (this.state.searchData) {
            this.searchHandler("", pageNumber)
        } else {
            this.setState({
                loading: true,
                showTable: false,
            });
            let url = backEndServerURL + "/rvData/getMyRvData/" + LocalstorageEncrypt.encryptStorageFunction.getItem("username") + "/" + pageNumber;
            // let url = backEndServerURL + "/rvData/getMyRvData/20190413/" + pageNumber;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let tableData = [];
                    let rowData;
                    for (let i = 0; i < response.data.data.length; i++) {
                        let res = response.data.data[i];
                        rowData = {
                            "RV_CB": res[0],
                            "CUST_NAME": res[1],
                            "AVG_SBA": res[2],
                            "AVG_CAA": res[3],
                            "BALANCE_FD": res[4],
                            "BALANCE_DPS": res[5],
                            "BALANCE_AL": res[6],
                            "BALANCE_PL": res[7],
                            "BALANCE_HL": res[8],
                            "BALANCE_SME": res[9],
                            "BALANCE_SND": res[10],
                            "RV_VALUE": res[11],
                            "HOME_SOL_ID": res[12],
                            "HOME_SOL": res[13],
                            "HOME_CLUSTER": res[14],
                            "REGION_NAME": res[15],
                            "RM_CODE": res[16],
                            "RM_NAME": res[17],
                            "CITYGEM": res[18],
                            "BAND_CURRENT_MONTH": res[19],
                            "BAND_PREVIOUS_MONTH": res[20],
                            "BAND_MOVEMENT": res[21],
                            "ACTUAL_BAND_MOVEMENT": res[22],
                            "CUSTOMER_TYPE": res[23],
                            "HAS_CA": res[24],
                            "HAS_FOREIGN_CURRENCY_CA": res[25],
                            "HAS_SA": res[26],
                            "CITY_ALO": res[27],
                            "FD": res[28],
                            "OD_A_FD": res[29],
                            "STUDENT": res[30],
                            "LOCKER": res[31],
                            "AMEX_CREDIT_CARD": res[32],
                            "VISA_CREDIT_CARD": res[33],
                            "CITY_MAXX_DEBIT_CARD": res[34],
                            "HL": res[35],
                            "PL": res[36],
                            "AL": res[37],
                            "SECURED_LOAN_OD": res[38],
                            "SECURED_LOAN_TL": res[39],
                            "SL_BULLET_PAYMENT": res[40],
                            "CITY_ALO_LOAN": res[41],
                            "HAS_SME_LOAN": res[42],
                            "GPH": res[43],
                            "GSA": res[44],
                            "BEFTN_TRAN_LAST_3_MONTH": res[45],
                            "SI_TRAN_LAST_3_MONTH": res[46],
                            "RTGS_TRAN_LAST_3_MONTH": res[47],
                            "E_STATEMNT_REGISTRATION": res[48],
                            "CITY_TOUCH_LAST_3_MONTH": res[49],
                            "HAS_STUDENT_FILE": res[50],
                            "DEBIT_CARD_TRAN_LAST_3_MONTH": res[51],
                            "GENDER": res[52],
                            "AGE": res[53],
                            "CUST_NTB_DATE": res[54].split("T")[0],
                            "MOBILE": res[55],
                            "EMAIL": res[56],
                        };
                        tableData.push(rowData);
                    }
                    this.setState({
                        page: pageNumber,
                        totalRow: response.data.count,
                        tableData: tableData,
                        getData: true,
                        showTable: true,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        cbNotFound: true,
                        title: "Something Went Wrong!",
                        showTable: true,
                        loading: false,
                        getData: false
                    })
                });
        }
    };

    stopPrevent = (e) => {
        e.preventDefault();
        console.log(".....")
    };

    triggerCreateFunction = (event, data) => {
        event.preventDefault();
        let url = backEndServerURL + "/wso2/rvData/getMyRVFile/" + LocalstorageEncrypt.encryptStorageFunction.getItem("username");
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
    };

    resetHandler = () => {
        window.location.reload();
    };

    searchHandler = (event, pageNumber) => {
        this.setState({
            page: 0,
            totalRow: 0,
            tableData: [],
            loading: true,
            showTable: false,
        });

        let postData = {...this.state.inputData};
        if (this.state.inputData.bandMovement) {
            postData.bandMovement = this.state.inputData.bandMovement === "No Change" ? "0" : this.state.inputData.bandMovement === "Upgrade" ? "-1" : "+1";
        }

        let pageN = pageNumber ? pageNumber : 0;
        console.log(pageN);
        let url = backEndServerURL + "/rvData/filter/" + LocalstorageEncrypt.encryptStorageFunction.getItem("username") + "/" + pageN;
        axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let tableData = [];
                let rowData;
                for (let i = 0; i < response.data.data.length; i++) {
                    let res = response.data.data[i];
                    rowData = {
                        "RV_CB": res[0],
                        "CUST_NAME": res[1],
                        "AVG_SBA": res[2],
                        "AVG_CAA": res[3],
                        "BALANCE_FD": res[4],
                        "BALANCE_DPS": res[5],
                        "BALANCE_AL": res[6],
                        "BALANCE_PL": res[7],
                        "BALANCE_HL": res[8],
                        "BALANCE_SME": res[9],
                        "BALANCE_SND": res[10],
                        "RV_VALUE": res[11],
                        "HOME_SOL_ID": res[12],
                        "HOME_SOL": res[13],
                        "HOME_CLUSTER": res[14],
                        "REGION_NAME": res[15],
                        "RM_CODE": res[16],
                        "RM_NAME": res[17],
                        "CITYGEM": res[18],
                        "BAND_CURRENT_MONTH": res[19],
                        "BAND_PREVIOUS_MONTH": res[20],
                        "BAND_MOVEMENT": res[21],
                        "ACTUAL_BAND_MOVEMENT": res[22],
                        "CUSTOMER_TYPE": res[23],
                        "HAS_CA": res[24],
                        "HAS_FOREIGN_CURRENCY_CA": res[25],
                        "HAS_SA": res[26],
                        "CITY_ALO": res[27],
                        "FD": res[28],
                        "OD_A_FD": res[29],
                        "STUDENT": res[30],
                        "LOCKER": res[31],
                        "AMEX_CREDIT_CARD": res[32],
                        "VISA_CREDIT_CARD": res[33],
                        "CITY_MAXX_DEBIT_CARD": res[34],
                        "HL": res[35],
                        "PL": res[36],
                        "AL": res[37],
                        "SECURED_LOAN_OD": res[38],
                        "SECURED_LOAN_TL": res[39],
                        "SL_BULLET_PAYMENT": res[40],
                        "CITY_ALO_LOAN": res[41],
                        "HAS_SME_LOAN": res[42],
                        "GPH": res[43],
                        "GSA": res[44],
                        "BEFTN_TRAN_LAST_3_MONTH": res[45],
                        "SI_TRAN_LAST_3_MONTH": res[46],
                        "RTGS_TRAN_LAST_3_MONTH": res[47],
                        "E_STATEMNT_REGISTRATION": res[48],
                        "CITY_TOUCH_LAST_3_MONTH": res[49],
                        "HAS_STUDENT_FILE": res[50],
                        "DEBIT_CARD_TRAN_LAST_3_MONTH": res[51],
                        "GENDER": res[52],
                        "AGE": res[53],
                        "CUST_NTB_DATE": res[54],
                        "MOBILE": res[55],
                        "EMAIL": res[56],
                    };
                    tableData.push(rowData);
                }
                this.setState({
                    page: pageN,
                    totalRow: response.data.count,
                    tableData: tableData,
                    showTable: true,
                    searchData: true,
                    loading: false
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    showTable: true,
                    searchData: false,
                })
            });
    };

    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.searchHandler(event);
        }
    };


    renderFilterField = () => {
        const {classes} = this.props;
        return (
            <div className={classes.root} style={{marginBottom: 30}}>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Open Filter</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={0}>
                            {CommonJsonFormComponent.renderJsonForm(this.state, filterField, this.updateComponent, this.onKeyDownForDedup)}
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    marginTop: '15px',
                                }}
                                onClick={this.searchHandler}
                                type="submit"
                            >
                                Search
                            </button>
                            &nbsp;
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    marginTop: '15px',
                                }}
                                onClick={this.resetHandler}
                                type="submit"
                            >
                                Reset
                            </button>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );
    };


    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)
        }

        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderCloseIcon()}
                </CardHeader>
                <CardBody>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loading} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <ThemeProvider theme={theme}>
                        {this.state.getData && this.renderFilterField()}

                        {(this.state.getData && this.state.showTable) &&
                        <TableComponent tableData={this.state.tableData} tableColumns={this.state.tableColumns}
                                        loader={this.state.loading} maxBodyHeight="450px" totalRow={this.state.totalRow}
                                        page={this.state.page} selection={false}
                                        handleChangePage={this.handleChangePage} rowsPerPage={this.state.rowsPerPage}
                                        tableButton={[{
                                            name: "Download",
                                            triggerFunction: this.triggerCreateFunction
                                        }]}
                        />}
                        <Grid container>
                            {this.renderNotification()}
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                        </Grid>
                    </ThemeProvider>
                </CardBody>
            </Card>

        );


    }

}

export default withStyles(styles)(ListForRV);
