import {CALLFdrEncashmentMaintenance} from "./../WorkflowJsonForm3";
import {maintenanceForCallAndBom as CallCenterAccountMaintenance} from "./static/JsonForCasaMaintenance"

import React, {Component} from "react";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import VerifyMakerPhoto from "./../VerifyMakerPhoto";
import Grid from "@material-ui/core/Grid";
import CardBody from "../../Card/CardBody";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import VerifyCallCenter from "./static/VerifyCallCenter";
import MaintenanceUtility from "./static/MaintenanceUtility";

class CallCenterInboxCase extends Component {
    state = {
        sendTo: false,
        documentList: [],
        getDocument: false
    };

    constructor(props) {
        super(props);


    }

    componentDidMount() {
        let fileUrl = backEndServerURL + "/case/files/" + this.props.appUid;
        axios.get(fileUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(":d");
                console.log(response.data);
                this.setState({
                    documentList: response.data,
                    getDocument: true
                })
            })
            .catch((error) => {
                console.log(error);
            })
    }

    maintenance = () => {

        if (this.props.serviceType === 'Maintenance' && this.props.subServiceType === "AccountMaintenance" && this.props.taskTitle === "call_center_maintenance_approval") {

            return (<VerifyCallCenter closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                      delStatus={this.props.delStatus} serviceType={this.props.serviceType}
                                      subServiceType={this.props.subServiceType}
                                      commonJsonForm={CallCenterAccountMaintenance}
                                      titleName="Maker Update All Information"
                                      appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Maintenance' && this.props.subServiceType === "FDRMaintenance" && this.props.taskTitle === "maintenance_call_center_approval") {

            return (<VerifyCallCenter closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                      serviceType={this.props.serviceType}
                                      subServiceType={this.props.subServiceType}
                                      titleName="FDR Maintenance"
                                      subCategory={this.props.subCategory}
                                      commonJsonForm={CALLFdrEncashmentMaintenance}
                                      appId={this.props.appUid}/>)

        }
    };
    inboxCase = () => {

        if (this.props.serviceType === "Maintenance") {
            return (
                this.maintenance()
            )
        }

    };


    close = () => {
        this.props.closeModal();
    };
    inboxCasePhoto = () => {
        return (<VerifyMakerPhoto closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                  delStatus={this.props.delStatus} serviceType={this.props.serviceType}
                                  subServiceType={this.props.subServiceType}

                                  titleName="Maker Update All Information"
                                  documentList={this.state.documentList}/>)


    };


    renderHeader = () => {
        if (this.props.subServiceType === "AccountMaintenance" && this.props.serviceType === "Maintenance") {
            return MaintenanceUtility.renderMaintenanceHeader(this.props.freeFlag1, this.props.freeFlag3, this.props.cbNumber, this.props.accountNumber, this.props.customerName, this.closeIcon);
            // return (
            //     <h4 style={{color: "white"}} >CASA Static Data Maintenance (<b> Customer
            //         Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
            //         {this.props.freeFlag2 === "linkedCB" ? <>
            //             <b> CB:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}</> : <>
            //          <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
            //         <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}<b> AC SOL Id:</b>{this.props.freeFlag3 !== undefined ? this.props.freeFlag3 : ""}</>})<a><CloseIcon onClick={this.closeIcon} style={{
            //             position: 'absolute',
            //             right: 10,
            //             color: "#000000"
            //         }}/></a></h4>
            //
            // );
        } else {
            return <h4 style={{color: "white"}} >{this.props.serviceType}</h4>
        }
    };

    render() {

        return (
            <div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {this.renderHeader()}
                    </CardHeader>
                    <CardBody>
                        <Grid container spacing={0}>

                            <Grid item xs={8}>

                                {this.inboxCasePhoto()}

                            </Grid>


                            <Grid item xs={4}>
                                {this.inboxCase()}
                            </Grid>


                        </Grid>
                    </CardBody>
                </Card>
            </div>

        )

    }

}

export default CallCenterInboxCase;