import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import 'semantic-ui-offline/semantic.min.css';
import MaterialTable from "material-table";
import {createMuiTheme} from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import TablePagination from "@material-ui/core/TablePagination";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import {buttonStyle, localization, options, tableIcons,} from "../../DeliverableManagement/configuration";
import MyValidation from "../../JsonForm/MyValidation";
import Button from "@material/react-button";
import axiosDownload from '../../DeliverableManagement/FunctionForFileDownload';
import CBNotFound from "../CASA/CBNotFound";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '5px',
                fontSize: '0.8rem'
            }
        },

    }
});

let SearchForm = [
    {
        varName: "currentDate",
        type: "date",
        label: "Select Date",
        required: true,
        maxDate: true,
        grid: 2,
    },
    {
        varName: "requestType",
        type: "select",
        enum: [
            "BOND",
            "SANCHAYPATRA"
        ],
        required: true,
        label: "Request Type",
        grid: 2,
    },
];
let SearchFormForSD = [
    {
        varName: "currentDate",
        type: "date",
        label: "Select Date",
        required: true,
        maxDate: true,
        grid: 2,
    },
    {
        varName: "requestType",
        type: "select",
        enum: [],
        required: true,
        onKeyDown: true,
        label: "Request Type",
        grid: 2,
    },
    {
        varName: "branch",
        type: "autoCompleteValueReturn",
        enum: [],
        label: "Select Branch",
        grid: 2,
        required: true,
    },
];


class Filter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            inputData: {},
            allData: [],
            pageNumber: 0,
            totalRow: 0,
            loading: true,
            showValue: false,
            alert: false,
            cbNotFound: false,
            appId: 0,
            title: "",
            currentDate: 0,
            notificationMessage: "",
            reportType: "",
            columnsForCs: [
                {title: 'SL', field: 'sl',},
                {title: 'CB No.', field: 'cbNumber',},
                {title: 'Tracking No.', field: 'trackingNumber',},
                {title: 'A/c No.', field: 'acNumber'},
                {title: 'Applicant\'s Name', field: 'applicantName'},
                {title: 'Category', field: 'category'},
                {title: 'Request Type', field: 'reqType'},
            ],
            columnsForSD: [
                {title: 'SL', field: 'sl',},
                {title: 'Transaction Type', field: 'transactionType',},
                {title: 'Tracking No.', field: 'trackingNumber',},
                {title: 'Registration No./Script No.', field: 'regNumber'},
                {title: 'Customer Name', field: 'customerName'},
                {title: 'Amount', field: 'amount'},
                {title: 'Remarks', field: 'remarks'},
            ],
        };
    }


    dateConverter = (value) => {

        return value.substr(0, 10);
    };

    componentDidMount() {
        let defaultDate = new Date();
        let isoDate = defaultDate.toISOString();
        let currentDate = isoDate.substr(0, 10);
        this.setState({
            currentDate: currentDate
        });

        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace").indexOf("SD") !== -1) {
            let url = backEndServerURL + "/getBondOrSanchayapatraOptionsForUser";
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                for (let item of SearchFormForSD) {
                    if (item.varName === "requestType") {
                        item.enum = response.data
                    }
                }
                this.setState({
                    loading: false
                })
            }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    cbNotFound: true,
                    title: "Unable to Fetch Request Type!"
                })
            })
        } else {
            this.setState({
                loading: false
            })
        }


    }

    updateComponent = () => {
        this.forceUpdate();
    };


    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === undefined || clone[prop] === "-1")
                delete clone[prop];
        return clone;
    };


    fetchDataFromAPI = (event, pageNumber) => {
        event.preventDefault();
        let page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        let input = this.removeNullValue(this.state.inputData);
        let error = false;
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace").indexOf("SD") !== -1) {
            error = MyValidation.defaultValidation(SearchFormForSD, this.state);
        } else {
            error = MyValidation.defaultValidation(SearchForm, this.state);
        }
        this.forceUpdate();
        if (error === true || Object.keys(input).length === 0) {
            return 0;
        } else {
            this.setState({
                loading: true,
            });
            let searchUrl = backEndServerURL + "/csAndsdFiterTable/" + page;
            axios.post(searchUrl, input, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    if (response.status === 200) {

                        let array = [];
                        let responseArray = [];
                        let rowData;
                        let responseRowData;

                        if (response.data.tableData.content.length > 0) {
                            for (let i = 0; i < response.data.tableData.content.length; i++) {
                                responseRowData = response.data.tableData.content[i].appId;
                                if (response.data.userGroupName === "CS") {
                                    rowData = {
                                        sl: i + 1,
                                        id: response.data.tableData.content[i].AppId,
                                        cbNumber: response.data.tableData.content[i].cbNumber,
                                        trackingNumber: response.data.tableData.content[i].caseId,
                                        acNumber: response.data.tableData.content[i].accountNumber,
                                        applicantName: response.data.tableData.content[i].customerName,
                                        category: response.data.tableData.content[i].category,
                                        reqType: response.data.tableData.content[i].requestType,
                                    };
                                } else {
                                    rowData = {
                                        sl: i + 1,
                                        id: response.data.tableData.content[i].AppId,
                                        transactionType: response.data.tableData.content[i].transactionType,
                                        trackingNumber: response.data.tableData.content[i].caseId,
                                        regNumber: response.data.tableData.content[i].registrationNumber,
                                        customerName: response.data.tableData.content[i].customerName,
                                        amount: response.data.tableData.content[i].amount,
                                        remarks: response.data.tableData.content[i].remarks,
                                    };
                                }
                                array.push(rowData);
                                responseArray.push(responseRowData);
                            }

                            this.setState({
                                reportType: response.data.userGroupName,
                                allData: array,
                                responseData: responseArray,
                                inputData: {...input},
                                varValue: {...input},
                                showValue: true,
                                loading: false,
                                pageNumber: page,
                                totalRow: response.data.tableData.totalElements,
                            });
                        } else {
                            this.setState({
                                cbNotFound: true,
                                title: "No Data Found!",
                                allData: array,
                                responseData: responseArray,
                                inputData: {...input},
                                varValue: {...input},
                                showValue: true,
                                loading: false,
                            })
                        }

                    }
                })
                .catch(error => {
                    this.setState({
                        inputData: {...input},
                        varValue: {...input},
                        pageNumber: page,
                        showValue: true,
                        loading: false
                    })
                });
        }
    };

    actionForReport = (event, rowData, props) => {
        event.preventDefault();
        let allSelectValue = (rowData, props.data);
        if (allSelectValue === undefined) return 0;
        console.log(allSelectValue[0].id);
        let rowIdArray = [];
        if (allSelectValue != null) {
            for (let i = 0; i < allSelectValue.length; i++) {
                rowIdArray.push(allSelectValue[i].id);
            }
            let postData = {...this.state.inputData};
            postData.idList = rowIdArray;
            let download_url = backEndServerURL + "/csAndsdFiterPdfResource";
            axiosDownload.downloadFile(download_url, 'POST', postData, `${this.state.currentDate}.pdf`)
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
        })
    };

    renderJsonForm = () => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace").indexOf("SD") !== -1) {
            return CommonJsonFormComponent.renderJsonForm(
                this.state,
                SearchFormForSD,
                this.updateComponent,
                "",
                this.onKeyDownChange
            )
        } else {
            return CommonJsonFormComponent.renderJsonForm(
                this.state,
                SearchForm,
                this.updateComponent,
            )
        }
    };

    onKeyDownChange = (data) => {
        if (data.varName === "requestType") {
            let inputData = this.state.inputData;
            this.setState({
                loading: true
            });
            let url = backEndServerURL + "/getBranchNameForBondSanchayapatra/" + this.state.inputData.requestType;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                for (let item of SearchFormForSD) {
                    if (item.varName === "branch") {
                        item.enum = response.data
                    }
                }
                this.setState({
                    inputData: inputData,
                    varValue: this.removeNullValue(inputData),
                    showValue: true,
                    loading: false
                })
            }).catch((error) => {
                console.log(error);
                this.setState({
                    inputData: inputData,
                    varValue: this.removeNullValue(inputData),
                    showValue: true,
                    loading: false,
                    cbNotFound: true,
                    title: "Unable to Fetch Branch List!"
                })
            })
        }
    };

    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >
                                Filter Bond Sanchayapatra
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {this.renderJsonForm()}
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            height: 30,
                                            marginTop: 20,
                                        }}
                                        onClick={e => {
                                            this.fetchDataFromAPI(e, '0')
                                        }}
                                    >
                                        Search
                                    </button>
                                </ThemeProvider>
                            </Grid>
                            <br/>
                            {this.state.allData.length > 0 && <div>
                                <MuiThemeProvider theme={themeO}>
                                    <div style={{maxWidth: "100%"}}>
                                        <MaterialTable
                                            columns={this.state.reportType === "CS" ? this.state.columnsForCs : this.state.columnsForSD}
                                            data={this.state.allData}
                                            title={" "}
                                            options={{...options}}
                                            localization={localization}
                                            icons={tableIcons}
                                            components={{
                                                Pagination: props => (
                                                    <TablePagination
                                                        {...props}
                                                        rowsPerPageOptions={[100]}
                                                        count={this.state.totalRow}
                                                        page={this.state.pageNumber}
                                                        onChangePage={(e, page) => {
                                                            this.fetchDataFromAPI(e, page)
                                                        }
                                                        }
                                                    />
                                                ),
                                                Actions: props => (
                                                    <React.Fragment>
                                                        &nbsp;
                                                        <Button
                                                            style={buttonStyle}
                                                            onClick={(event, rowData) => this.actionForReport(event, rowData, props)}
                                                        >
                                                            Generate Report
                                                        </Button>
                                                    </React.Fragment>
                                                )
                                            }}
                                        />
                                    </div>
                                </MuiThemeProvider>
                            </div>}
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                        </CardBody>
                    </Card>
                </div>
            );
        }

    }

}

export default withStyles(styles)(Filter);
