import React, { Component } from "react";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import { ThemeProvider } from "@material-ui/styles";
import theme from "../JsonForm/CustomeTheme2";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import Notification from "../NotificationMessage/Notification";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { backEndServerURL } from "../../Common/Constant";
import MyValidation from "../JsonForm/MyValidation";
import axios from "axios";
import Functions from "../../Common/Functions";
import { Dialog } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";
import Swal from "sweetalert2";
let Form = [
  {
    varName: "stampCategory",
    type: "select",
    label: "Stamp Category",
    enum: ["Revenue", "Judicial"],
    required: true,
    grid: 6,
  },
  {
    varName: "valueAmount",
    type: "text",
    label: "Value Amount",
    "validation":"numeric",
    required: true,
    grid: 6,
  },
 
  {
    varName: "stampDescription",
    type: "text",
    label: "Stamp Description",
    required: true,
    grid: 6,
  },

  {
    varName: "stampCode",
    type: "text",
    label: "Stamp Code",
    required: true,
    grid: 6,
  },
 
];
let workplace = [
  { title: "BRANCH" },
  { title: "OPERATIONS" },
  { title: "CSU" },
];
let groupList = [];
let workplaceTypeList = [];

class InsuranceMasterDataValueCalculation extends Component {
  state = {
    inputData: {},
    selectedDate: {},
    getData: false,
    workstation: false,
    role: false,
    showValue: false,
    SelectedDropdownSearchData: null,
    dropdownSearchData: {},

    err: false,
    varValue: [],
    errorArray: {},
    errorMessages: {},
    errorWorkPlace: false,
    errorgroup: false,
    title: "",
    notificationMessage: "",
    alert: false,
    getGroupListData: false,
    getworkplaceTypeListData: false,
    workplace: "",
    type: "success",
    submitButton: false,
  };
  emptyValueRemove = (data) => {
    if (data === "" || data === " " || data === "null" || data === "undefined")
      data = "";
    return data;
  };
  reload =()=>{
    setTimeout(function () {
        window.location.reload();
      }, 1000);
}
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    console.log(this.state.inputData);

    // let workplaceTypeArray = [{
    //     varName:"workplaceType",
    //     type:"text",
    //     required:true
    // }]

    let error = MyValidation.defaultValidation(
      this.emptyValueRemove(Form),
      this.state
    );

    this.forceUpdate();

    if (
      error === true ||
      this.state.inputData.workplaceType === null ||
      this.state.inputData.groupName === null
    ) {
      console.log("Not working");
      this.setState({
        loading: false,
      });
      return 0;
    }
    let url = backEndServerURL + "/add/insuranceMasterData";
    let data = this.state.inputData;

    this.setState({
      loading: true,
    });
    

  
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
      .then((response) => {
        console.log('refetch data',this.props.showTableData(backEndServerURL + '/get/insuranceMasterDataList', 0));
        console.log(response.data.status !== "UNSUCESSFULL")
        this.props.showTableData(backEndServerURL + '/get/insuranceMasterDataList', 0);
        if (response.data.status !== "UNSUCESSFULL") {
          console.log(response.data.status !== "UNSUCESSFULL")
          console.log(this.props.showTableData)
          if(this.props.showTableData !== undefined){           
            this.setState({
              type: "success",
              title: "success!",
              notificationMessage: "Data added successfully!",
              alert: true,
              loading: false,
            });
          this.props.closeModal();

          }
          //this.reload()
          //this.closeIcon()
          // setTimeout(function () {
          //   window.location.reload();
          // }, 10000);
        } else {
          this.setState({
            type: "warning",
            title: "warning!",
            notificationMessage: "Data already exists!",
            alert: true,
            loading: false,
          });
          // this.reload()
          // this.closeIcon()
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          type: "error",
          title: "error!!",
          notificationMessage: Functions.errorObjectCheck(error),
          alert: true,
          loading: false,
        });
        // this.reload()
        this.closeIcon()
      });
    }
  renderButton = () => {
    // if(this.state.submitButton){
    return (
      <button
        className="btn btn-outline-primary   btn-sm"
        style={{
          marginTop: "18px",
        }}
        onClick={this.handleSubmit}
      >
        Create
      </button>
    );
    // }
  };

  componentDidMount() {
    this.setState({
      submitButton: false,
    });
  }

  updateComponent = () => {
    this.forceUpdate();
  };

  renderJsonForm = () => {
    return CommonJsonFormComponent.renderJsonForm(
      this.state,
      Form,
      this.updateComponent
    );
  };

  renderNotification = () => {
    if (this.state.alert) {
      return (
        <Notification
          type={this.state.type}
          stopNotification={this.stopNotification}
          title={this.state.title}
          message={this.state.notificationMessage}
        />
      );
    }
  };

  stopNotification = () => {
    this.setState({
      alert: false,
    });
  };
  closeIcon = () => {
    if (this.props.appId !== undefined) {
      this.props.closeModal();
    } else {
      this.props.closeModal();
    }
  };
  renderHeader = () => {
    if (this.props.appId !== undefined) {
      return (
        <h6 style={{ color: "white" }}>
          Create Master Data (Stamp)
          <a>
            <CloseIcon
              onClick={this.closeIcon}
              style={{
                position: "absolute",
                backgroundColor: "#142398",
                right: 10,
                color: "white",
              }}
            />
          </a>
        </h6>
      );
    } else {
      return (
        <h6 style={{ color: "white" }}>
          Create Master Data (Stamp)
          <a>
            <CloseIcon
              onClick={this.closeIcon}
              style={{
                position: "absolute",
                right: 10,
                backgroundColor: "#142398",
                color: "white",
              }}
            />
          </a>
        </h6>
      );
    }
  };

  render() {
    return (
      <>
        {" "}
        <Card>
          <CardHeader
            style={{
              backgroundColor: "#142398",
              color: "#ffffff",
            }}
          >
            {this.renderHeader()}
          </CardHeader>
          <CardBody>
            <div>
              <Dialog fullWidth="true" maxWidth="sm" open={this.state.loading}>
                <DialogContent>
                  <center>
                    <img src={loader} alt="" />
                  </center>
                </DialogContent>
              </Dialog>
              <ThemeProvider theme={theme}>
                <Grid container>
                  {this.renderNotification()}
                  {this.renderJsonForm()}
                  <Grid item xs={12}>
                    {this.renderButton()}
                  </Grid>
                </Grid>
              </ThemeProvider>
            </div>
          </CardBody>
        </Card>{" "}
      </>
    );
  }
}

export default InsuranceMasterDataValueCalculation;
