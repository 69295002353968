import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import Functions from '../../Common/Functions';
import theme from "../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import Notification from "../NotificationMessage/Notification";
import loading from "../../Static/loader.gif";
import CBNotFound from "./CASA/CBNotFound";
import TableComponent from "./CommonComponent/TableComponent";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import {Menu} from "semantic-ui-react";
import {commonInboxButton, commonInboxTabActive, commonInboxTabInactive} from "../DeliverableManagement/configuration";
import Badge from "@material-ui/core/Badge";
import {assignPending, suspendedRequest} from "./ColumnNameForInbox"
import SubmittedCaseHistory from "./CommonComponent/SubmittedCaseHistory";
import ConfirmAlert from "./CommonComponent/ConfirmAlert";
import Button from "@material/react-button";
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";

const filterField = [
    {
        "varName": "caseId",
        "type": "textDedup",
        "label": "LWF Case Number",
        "grid": 2,
    },
    {
        "varName": "referenceNumber",
        "type": "text",
        "label": "Reference Number",
        "grid": 2,
    },
    {
        "varName": "category",
        "type": "select",
        "enum": [
            "CASA",
            "FDR",
            "DPS",
            "Chequebook",
            "RTGS",
            "BEFTN",
            "Salary",
            "Debit Card Issuance",
            "BOND",
            "SANCHAYPATRA",
            "Debit Card",
            "Credit Card",
            "CARDS",
            "ADC",
            "Internet Banking",
            "Remittance",
            "Pay Order and FDD Bulk",
            "Locker",
            "Delivarable",
            "Deferral",
        ],
        "label": "Category",
        "grid": 2,
    },
    {
        "varName": "subCategory",
        "type": "select",
        "enum": [
            "A/C Opening",
            "Opening",
            "Schem Account opeing",
            "TD Open/FDR",
            "Maintenance",
            "Single Requisition",
            "Opening",
            "Service",
            "Single Transaction",
            "Bulk Transaction",
            "Requisition",
            "Chequebook",
            "Bulk Insta Debit Card",
            "Bulk Requisition",
            "CASA Closer",
            "FDR Closer",
            "FDR Maintenace",
            "CARD Against FDR/ DPS",
            "Tracker",
            "Instant Card",
            "General Card Requisition",
            "CARD Cheque Requisition",
            "City Touch Enrollment",
            "SMS Discountinue",
            "Outward",
            "Inward",
            "Student File",
            "Medical File",
            "Searcher",
            "Others",
            "Pay Order Bulk Requisition",
            "FDD Bulk Requisition",
            "Others",
            "Requistion",
            "Surrender",
            "Locker Maintenace",
            "Reconcilation Branch",
            "Insurance Upload",
            "Insta Pack Requisition",
            "Insta Card Requisition",
            "Insta Pack Tracker",
            "Insta Card Tracker",
            "WL Priority",
            "WL Non Priority",
            "CASA Defferal",
            "FD Deferal",
            "Others",
            "FDR",
            "Encashment",
            "ISSUANCE - Wage Earners Development Bond",
            "ISSUANCE - U.S. Dollar Investment Bond",
            "ISSUANCE - U.S. Dollar Premium Bond",
            "ISSUANCE - 5 Years Bangladesh Sanchaya Patra",
            "ISSUANCE - 3 Months Interest Based Sanchaya Patra",
            "ISSUANCE - Family Sanchaya Patra",
            "ISSUANCE - Pensioners Savings Certificate",
            "ENCASHMENT - Wage Earners Development Bond - ENCASHMENT PAYMENT",
            "ENCASHMENT - Wage Earners Development Bond - INTEREST PAYMENT",
            "ENCASHMENT - U.S. Dollar Investment Bond - ENCASHMENT PAYMENT",
            "ENCASHMENT - U.S. Dollar Investment Bond - INTEREST PAYMENT",
            "ENCASHMENT - U.S. Dollar Premium Bond - ENCASHMENT PAYMENT",
            "ENCASHMENT - U.S. Dollar Premium Bond - INTEREST PAYMENT",
            "ENCASHMENT - 5 Years Bangladesh Sanchaya Patra - ENCASHMENT PAYMENT",
            "ENCASHMENT - 5 Years Bangladesh Sanchaya Patra - INTEREST PAYMENT",
            "ENCASHMENT - 3 Months Interest Based Sanchaya Patra - ENCASHMENT PAYMENT",
            "ENCASHMENT - 3 Months Interest Based Sanchaya Patra - INTEREST PAYMENT",
            "ENCASHMENT - Family Sanchaya Patra - ENCASHMENT PAYMENT",
            "ENCASHMENT - Family Sanchaya Patra - INTEREST PAYMENT",
            "ENCASHMENT - Pensioners Savings Certificate - ENCASHMENT PAYMENT",
            "ENCASHMENT - Pensioners Savings Certificate - INTEREST PAYMENT",
            "MAINTENANCE - Wage Earners Development Bond - NOMINEE CHANG",
            "MAINTENANCE - Wage Earners Development Bond - ACES ENROLLMENT",
            "MAINTENANCE - Wage Earners Development Bond - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - Wage Earners Development Bond - DUPLICATE ISSUANCE",
            "MAINTENANCE - Wage Earners Development Bond - CERTIFICATE",
            "MAINTENANCE - U.S. Dollar Investment Bond - NOMINEE CHANG",
            "MAINTENANCE - U.S. Dollar Investment Bond - ACES ENROLLMENT",
            "MAINTENANCE - U.S. Dollar Investment Bond - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - U.S. Dollar Investment Bond - DUPLICATE ISSUANCE",
            "MAINTENANCE - U.S. Dollar Investment Bond - CERTIFICATE",
            "MAINTENANCE - U.S. Dollar Premium Bond - NOMINEE CHANG",
            "MAINTENANCE - U.S. Dollar Premium Bond - ACES ENROLLMENT",
            "MAINTENANCE - U.S. Dollar Premium Bond - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - U.S. Dollar Premium Bond - DUPLICATE ISSUANCE",
            "MAINTENANCE - U.S. Dollar Premium Bond - CERTIFICATE",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - NOMINEE CHANG",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - ACES ENROLLMENT",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - DUPLICATE ISSUANCE",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - CERTIFICATE",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - NOMINEE CHANG",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - ACES ENROLLMENT",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - DUPLICATE ISSUANCE",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - CERTIFICATE",
            "MAINTENANCE - Family Sanchaya Patra - NOMINEE CHANG",
            "MAINTENANCE - Family Sanchaya Patra - ACES ENROLLMENT",
            "MAINTENANCE - Family Sanchaya Patra - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - Family Sanchaya Patra - DUPLICATE ISSUANCE",
            "MAINTENANCE - Family Sanchaya Patra - CERTIFICATE",
            "MAINTENANCE - Pensioners Savings Certificate - NOMINEE CHANG",
            "MAINTENANCE - Pensioners Savings Certificate - ACES ENROLLMENT",
            "MAINTENANCE - Pensioners Savings Certificate - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - Pensioners Savings Certificate - DUPLICATE ISSUANCE",
            "MAINTENANCE - Pensioners Savings Certificate - CERTIFICATE",
        ],
        "label": "Sub Category",
        "grid": 2,
    },
    {
        "varName": "branch_id",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Branch",
        "grid": 2,
    },
    {
        "varName": "cbNumber",
        "type": "textDedup",
        "label": "CB Number",
        "grid": 2,
    },
    {
        "varName": "accountNumber",
        "type": "textDedup",
        "label": "Account No.",
        "grid": 2,
    },
    {
        "varName": "customerName",
        "type": "textDedup",
        "label": "Customer name",
        "grid": 2,
    },
    {
        "varName": "fromDate",
        "type": "date",
        "label": "From Date",
        "grid": 2,
    },
    {
        "varName": "toDate",
        "type": "date",
        "label": "To Date",
        "grid": 2,
    },
    {
        "varName": "urgency",
        "type": "select",
        "enum": [
            "High",
            "Medium",
            "Low",
        ],
        "label": "Urgency",
        "grid": 2,
    },
    {
        "varName": "cutOffTime",
        "type": "select",
        "enum": [
            "Before",
            "After",
        ],
        "label": "Cut Off Time",
        "grid": 2,
    },
];


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

class ClaimCase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            loading: true,
            cbNotFound: false,
            getData: false,
            varValue: {},
            title: "",
            notificationMessage: "",
            alert: false,
            selectedDate: {},
            showValue: true,
            openHierarchy: false,
            err: false,
            searchData: false,
            activeItem: "",
            showTable: false,
            errorArray: {},
            errorMessages: {},
            tableData: [],
            totalRow: 0,
            rowsPerPage: 50,
            tableColumns: [],
            page: 0,
            getCountPending: 0,
            getCountReturn: 0,
            getCountInbox: 0,
            getCountRectified: 0,
            getCountGroupInbox: 0,
            showInbox: false,
            serviceType: "",
            subServiceType: "",
            category: "",
            subCategory: "",
            solId: "",
            appUid: "",
            delStatus: "",
            taskTitle: "",
            status: "",
            freeFlag1: "",
            freeFlag2: "",
            freeFlag3: "",
            freeFlag4: "",
            recpmtid: "",
            cb_number: "",
            account_number: "",
            customer_name: "",
            CaseId: "",
            urgency: "",
            sentByUsername: "",
            jointAccountCustomerNumber: "",
            getCountRemittanceBranch: 0,
            getCountDormantWithoutRectified: 0,
            getCountDormantRectified: 0,
            apiWorkFlowGroup: ""
        }
    }


    renderTableColumn = () => {
        if (this.state.activeItem === "SUSPENDED REQUEST") {
            return [...suspendedRequest];
        } else {
            return [...assignPending];
        }
    };


    componentDidMount() {
        let value = (LocalstorageEncrypt.encryptStorageFunction.getItem("menus")).indexOf("CS PENDING") !== -1 ? "CS PENDING" : "MAKER PENDING";
        this.headerSelect(value);
        let branch = [];
        let branchUrl = backEndServerURL + "/workplaceUnit/getInitialUnits";
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((item) => {
                    branch.push({key: item.solId, value: item.name})
                });
                for (let form of filterField) {
                    if (form.type === "autoCompleteValueReturn" && form.varName === "branch_id") {
                        form.enum = branch
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    getData: true,
                    showTable: true,
                    loading: false,
                });
            });
    }


    fetchTableData = (url, page) => {
        //this.functionForCategoryCount();
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(response => {
            let tableData = [];
            response.data.content.map((message, index) => {
                tableData.push({
                    SL: page > 0 ? ((index + 1) + (Number(this.state.rowsPerPage) * page)) : index + 1,
                    CaseId: message.caseId,
                    customer_name: message.customerName,
                    cb_number: message.cbNumber,
                    account_number: message.accountNumber,
                    businessSegment: message.businessSegment,
                    category_type: this.returnCategory(message.branchingCategory, message.freeFlag5, message.category),
                    sub_category_type: this.returnSubCategory(message.branchingSubCategory, message.freeFlag6, message.subCategory),
                    Service: message.priorityDesc,
                    Date: message.delInitTime.replace("T", " "),
                    currentUserName: message.currentUserName,
                    branch_name: message.branchName,
                    Status: message.delStatus,
                    freeFlag1: message.freeFlag1,
                    freeFlag2: message.freeFlag2,
                    freeFlag3: message.freeFlag3,
                    freeFlag4: message.freeFlag4,
                    recpmtid: message.recpmtid,
                    freeFlag5: message.freeFlag5,
                    freeFlag6: message.freeFlag6,
                    appId: message.appId,
                    serviceType: message.serviceType,
                    subServiceType: message.subServiceType,
                    taskTitle: message.taskTitle,
                    solId: message.solId,
                    urgency: message.urgency,
                    returnedBack: message.returnBackTo ? message.returnBackTo : "",
                    returnedBy: message.sentByUsername,
                    requestStatus: message.status,
                    makerId: message.lastMakerUser,
                    Unlock: this.returnUnlockButton(message.appId, "Unlock"),
                    CaseClose: this.returnCaseCloseButton(message.appId, "Delete"),
                    revert: this.revertCase(message.appId),
                    initUser: message.initiatedBy,

                });
            });
            this.setState({
                page: response.data.number,
                totalRow: response.data.totalElements,
                tableData: tableData,
                getData: true,
                showTable: true,
                loading: false
            })

        }).catch(error => {
            console.log(error);
            this.setState({
                loading: false,
                showTable: true,
                searchData: false,
            })
        });
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderCloseIcon = () => {
        return (
            <h4 style={{color: "white"}} >All Inbox</h4>
        )
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    handleChangePage = (pageNumber) => {
        this.setState({
            loading: true,
            getData: false,
            showTable: false
        });
        if (this.state.searchData) {
            this.searchHandler("", pageNumber)
        } else {
            this.functionForGetTabWiseUrl(this.state.activeItem, pageNumber);
        }
    };


    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'null' || clone[prop] === null || clone[prop] === 'undefined')
                delete clone[prop];
        return clone;
    };


    returnCategory = (branchingCategory, freeFlag5, category) => {
        if (branchingCategory !== undefined && branchingCategory !== null && branchingCategory !== "") {
            return branchingCategory
        } else if (freeFlag5 !== undefined && freeFlag5 !== null && freeFlag5 !== "") {
            return freeFlag5
        } else {
            return category
        }
    };

    returnSubCategory = (branchingSubCategory, freeFlag6, subCategory) => {
        if (branchingSubCategory !== undefined && branchingSubCategory !== null && branchingSubCategory !== "") {
            return branchingSubCategory
        } else if (freeFlag6 !== undefined && freeFlag6 !== null && freeFlag6 !== "") {
            return freeFlag6
        } else {
            return subCategory
        }
    };

    searchHandler = (event, pageNumber) => {
        let workflowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        this.setState({
            page: 0,
            totalRow: 0,
            tableData: [],
            loading: true,
            showTable: false,
        });
        let pageN = pageNumber ? pageNumber : 0;
        let postData = this.removeNullValue(this.state.inputData);
        let url;
          if(this.state.activeItem==="CS RETURN PENDING"){
            url = backEndServerURL + "/FilterPendingRequestOfOfSameBranch/CS RETURN PENDING/" + pageN;
        }
        else if(this.state.activeItem==="MAKER RETURN PENDING"){
            url = backEndServerURL + "/FilterPendingRequestOfOfSD/MAKER RETURN PENDING/" + pageN;
        }
        else if (this.state.activeItem === "SUSPENDED REQUEST") {
            if (workflowGroup === "MAKER" || workflowGroup === "CHECKER") {
                url = backEndServerURL + "/FilterPendingRequestOfOfSD/SUSPENDED REQUEST/" + pageN;
            } else {
                url = backEndServerURL + "/FilterPendingRequestOfOfSameBranch/SUSPENDED REQUEST/" + pageN;
            }
        } else {
            if (this.state.apiWorkFlowGroup === "MAKER" || this.state.apiWorkFlowGroup === "CHECKER") {
                url = backEndServerURL + "/FilterPendingRequestOfOfSD/" + this.state.apiWorkFlowGroup + "/" + pageN;
            } else {
                url = backEndServerURL + "/FilterPendingRequestOfOfSameBranch/" + this.state.apiWorkFlowGroup + "/" + pageN;
            }
        }
        axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(response => {
            let tableData = [];
            response.data.content.map((message, index) => {
                tableData.push({
                    SL: pageNumber > 0 ? ((index + 1) + (Number(this.state.rowsPerPage) * pageNumber)) : index + 1,
                    CaseId: message.caseId,
                    customer_name: message.customerName,
                    cb_number: message.cbNumber,
                    account_number: message.accountNumber,
                    businessSegment: message.businessSegment,
                    category_type: this.returnCategory(message.branchingCategory, message.freeFlag5, message.category),
                    sub_category_type: this.returnSubCategory(message.branchingSubCategory, message.freeFlag6, message.subCategory),
                    Service: message.priorityDesc,
                    Date: message.delInitTime.replace("T", " "),
                    currentUserName: message.currentUserName,
                    branch_name: message.branchName,
                    Status: message.delStatus,
                    freeFlag1: message.freeFlag1,
                    freeFlag2: message.freeFlag2,
                    freeFlag3: message.freeFlag3,
                    freeFlag4: message.freeFlag4,
                    recpmtid: message.recpmtid,
                    freeFlag5: message.freeFlag5,
                    freeFlag6: message.freeFlag6,
                    appId: message.appId,
                    serviceType: message.serviceType,
                    subServiceType: message.subServiceType,
                    taskTitle: message.taskTitle,
                    solId: message.solId,
                    urgency: message.urgency,
                    returnedBack: message.returnBackTo ? message.returnBackTo : "",
                    returnedBy: message.sentByUsername,
                    jointAccountCustomerNumber: message.jointAccountCustomerNumber,
                    requestStatus: message.status,
                    makerId: message.lastMakerUser,
                    Unlock: this.returnUnlockButton(message.appId, "Unlock"),
                    CaseClose: this.returnCaseCloseButton(message.appId, "Delete"),
                    revert: this.revertCase(message.appId),
                });
            });
            this.setState({
                page: response.data.number,
                totalRow: response.data.totalElements,
                tableData: tableData,
                getData: true,
                showTable: true,
                searchData: true,
                loading: false
            })

        }).catch(error => {
            console.log(error);
            this.setState({
                loading: false,
                showTable: true,
                searchData: false,
            })
        });
    };

    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.searchHandler(event, 0);
        }
    };


    renderFilterField = () => {
        return (
            <Grid container>
                {CommonJsonFormComponent.renderJsonForm(this.state, filterField, this.updateComponent, this.onKeyDownForDedup)}
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: '15px',
                    }}
                    onClick={(e) => this.searchHandler(e, 0)}
                    type="submit"
                >
                    Search
                </button>
                &nbsp;
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: '15px',
                    }}
                    onClick={() => window.location.reload()}
                    type="submit"
                >
                    Reset
                </button>
            </Grid>
        );
    };


    headerSelect = (value) => {
        this.setState({
            searchData: false,
            activeItem: value,
            loading: true,
            getData: false,
            showTable: false,
            page: 0,
            tableData: [],
        }, this.functionForGetTabWiseUrl(value, 0));
    };

    functionForGetTabWiseUrl = (value, page) => {
        this.forceUpdate();
        let workflowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        let urls = "";
        let apiWorkFlowGroup = "";
        if (value === "CS PENDING") {
            apiWorkFlowGroup = "CS";
        }
        else if (value === "CS RETURN PENDING") {
            apiWorkFlowGroup = "CS";
        }
        else if (value === "BOM PENDING") {
            apiWorkFlowGroup = "BOM";
        } else if (value === "BM PENDING") {
            apiWorkFlowGroup = "BM";
        } else if (value === "MAKER PENDING") {
            apiWorkFlowGroup = "MAKER";
        } else if (value === "MAKER RETURN PENDING") {
            apiWorkFlowGroup = "MAKER";
        } else if (value === "CHECKER PENDING") {
            apiWorkFlowGroup = "CHECKER";
        }
        if (value === "SUSPENDED REQUEST") {
            if (workflowGroup === "MAKER" || workflowGroup === "CHECKER") {
                urls = backEndServerURL + "/getAllSuspendedRequestBySD/" + page;
            } else {
                urls = backEndServerURL + "/getAllSuspendedRequestBySol/" + page;
            }
        } else {
            if(value === "MAKER RETURN PENDING"){
                urls = backEndServerURL + '/GetAllPendingRequestOfOfSD/MAKER RETURN PENDING/' + page;
            }
            else if(value === "CS RETURN PENDING"){
                urls = backEndServerURL + '/GetAllPendingRequestOfOfSameBranch/CS RETURN PENDING/'+ page;

            }
            else if (apiWorkFlowGroup === "MAKER" || apiWorkFlowGroup === "CHECKER") {
                urls = backEndServerURL + '/GetAllPendingRequestOfOfSD/' + apiWorkFlowGroup + "/" + page;
            } else {
                urls = backEndServerURL + '/GetAllPendingRequestOfOfSameBranch/' + apiWorkFlowGroup + "/" + page;
            }
        }
        this.setState({
            apiWorkFlowGroup: apiWorkFlowGroup
        });
        this.fetchTableData(urls, page)
    };

    renderMenu = () => {
        return (
            <div style={{marginRight: "8px"}}>
                <Menu pointing style={{overflowX: 'auto', overflowY: 'hidden'}}>
                    {this.renderMenuItem("CS PENDING", "CS PENDING", this.state.getCountGroupInbox, "CS PENDING")}
                    {this.renderMenuItem("CS PENDING", "CS RETURN PENDING", this.state.getCountGroupInbox, "CS RETURN PENDING")}
                    {this.renderMenuItem("BM PENDING", "BM PENDING", this.state.getCountGroupInbox, "BM PENDING")}
                    {this.renderMenuItem("BOM PENDING", "BOM PENDING", this.state.getCountGroupInbox, "BOM PENDING")}
                    {this.renderMenuItem("MAKER PENDING", "MAKER RETURN PENDING", this.state.getCountGroupInbox, "MAKER RETURN PENDING")}
                    {this.renderMenuItem("MAKER PENDING", "MAKER PENDING", this.state.getCountGroupInbox, "MAKER PENDING")}
                    {this.renderMenuItem("CHECKER PENDING", "CHECKER PENDING", this.state.getCountGroupInbox, "CHECKER PENDING")}
                    {this.renderMenuItem("SUSPENDED REQUEST", "SUSPENDED REQUEST", this.state.getCountGroupInbox, "SUSPENDED REQUEST")}
                </Menu>
            </div>
        );
    };

    renderMenuItem = (permission, activeItem, badge, name) => {
        if ((LocalstorageEncrypt.encryptStorageFunction.getItem("menus")).indexOf(permission) !== -1) {
            return (
                <Menu.Item
                    name={activeItem}
                    active={this.state.activeItem === activeItem}
                    style={this.state.activeItem === activeItem ? commonInboxTabActive : commonInboxTabInactive}
                    onClick={e => {
                        this.headerSelect(activeItem);
                    }}
                >
                    {this.renderBadge(badge, name)}
                </Menu.Item>
            )
        }
    };

    renderBadge = (value, name) => {
        if (value !== undefined && value > 0) {
            return (
                <Badge color="primary">
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        } else {
            return (
                <Badge color="primary">
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        }
    };

    renderInboxCaseSubmitted = () => {
        return (
            <SubmittedCaseHistory delStatus={this.state.delStatus} closeModal={this.closeIcon}
                                  closeIcon={this.closeIcon}
                                  appId={this.state.appUid}
                                  subCategory={this.state.subCategory}
                                  category={this.state.category} serviceType={this.state.serviceType}
                                  freeFlag1={this.state.freeFlag1}
                                  freeFlag2={this.state.freeFlag2}
                                  freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                                  recpmtid={this.state.recpmtid}
                                  accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                                  customerName={this.state.customer_name}
                                  solId={this.state.solId}
                                  subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
        )
    };


    onRowClick = (event, rowData) => {
        event.preventDefault();
        this.setInboxProps(rowData)
    };

    returnUnlockButton = (appId, action) => {
        if(this.state.activeItem!=="CS RETURN PENDING" && this.state.activeItem!=="MAKER RETURN PENDING")
        return (
            <>
                <Button
                    style={commonInboxButton}
                    onClick={(event) => this.handleCommon(event, appId, "Unlock")}
                >
                    Unlock
                </Button>
            </>
        )
    };

    returnCaseCloseButton = (appId, action) => {
        return (
            <>
                <Button
                    style={commonInboxButton}
                    onClick={(event) => this.handleCommon(event, appId, "Delete")}
                >
                    Case Suspend
                </Button>
            </>
        )
    };

    revertCase = (appId) => {
        return (
            <>
                <Button
                    style={commonInboxButton}
                    onClick={(event) => this.handleCommon(event, appId, "revert")}
                >
                    Revert Case
                </Button>
            </>
        )
    };

    setInboxProps = (rowData) => {
        console.log(rowData);
        this.setState({
            CaseId: rowData.CaseId,
            serviceType: rowData.serviceType,
            subServiceType: rowData.subServiceType,
            category: rowData.category_type,
            subCategory: rowData.sub_category_type,
            solId: rowData.solId,
            appUid: rowData.appId,
            delStatus: rowData.Status,
            taskTitle: rowData.taskTitle,
            status: rowData.requestStatus,
            freeFlag1: rowData.freeFlag1,
            freeFlag2: rowData.freeFlag2,
            freeFlag3: rowData.freeFlag3,
            freeFlag4: rowData.freeFlag4,
            recpmtid: rowData.recpmtid,
            cb_number: rowData.cb_number,
            account_number: rowData.account_number,
            customer_name: rowData.customer_name,
            urgency: rowData.urgency,
            sentByUsername: rowData.returnedBy,
            showInbox: true,
            showValue: true,
            loading: false
        });
    };

    viewCaseUnlock = (appUid) => {
        this.setState({
            inboxModal: false,
            inboxModalSubmitted: false,
            loading: true,
            showTable: false,
            actionFlag: "Unlock",
        });
        let url = backEndServerURL + "/case/unClaim";
        axios.post(url, [appUid], {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let urls = "";
                if (this.state.apiWorkFlowGroup === "MAKER" || this.state.apiWorkFlowGroup === "CHECKER") {
                    urls = backEndServerURL + '/GetAllPendingRequestOfOfSD/' + this.state.apiWorkFlowGroup + "/" + this.state.page;
                } else {
                    urls = backEndServerURL + '/GetAllPendingRequestOfOfSameBranch/' + this.state.apiWorkFlowGroup + "/" + this.state.page;

                }
                this.fetchTableData(urls, this.state.page);
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully UnLock!",
                    alert: true,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                })
            })
    };

    viewCaseDelete = (appId, type) => {
        let workflowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        this.setState({
            inboxModal: false,
            inboxModalSubmitted: false,
            loading: true,
            showTable: false,
            actionFlag: "Delete",
        });
        let url = backEndServerURL + "/case/" + type + "/" + appId;
        axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let urls;
                if (this.state.activeItem === "SUSPENDED REQUEST") {
                    if (workflowGroup === "MAKER" || workflowGroup === "CHECKER") {
                        urls = backEndServerURL + '/getAllSuspendedRequestBySD/' + this.state.page;
                    } else {
                        urls = backEndServerURL + '/getAllSuspendedRequestBySol/' + this.state.page;
                    }
                } else if (this.state.apiWorkFlowGroup === "MAKER" || this.state.apiWorkFlowGroup === "CHECKER") {
                    urls = backEndServerURL + '/GetAllPendingRequestOfOfSD/' + this.state.apiWorkFlowGroup + "/" + this.state.page;
                } else {
                    urls = backEndServerURL + '/GetAllPendingRequestOfOfSameBranch/' + this.state.apiWorkFlowGroup + "/" + this.state.page;

                }
                this.fetchTableData(urls, this.state.page);
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Close!",
                    alert: true,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                })
            })
    };

    handleCommon = (event, actionAppId, type) => {
        event.preventDefault();
        this.setState({
            actionAppId: actionAppId,
            actionType: type,
            confirmAlert: true,
        })
    };

    closeConfirmAlert = (data) => {
        if (data !== "YES") {
            this.setState({
                confirmAlert: false,
                loading: false,
            });
        } else {
            this.setState({
                confirmAlert: false,
                loading: true,
            });

            if (data === "YES" && this.state.actionType === "Unlock") {
                this.viewCaseUnlock(this.state.actionAppId)
            } else if (data === "YES" && (this.state.actionType === "Delete" || this.state.actionType === "revert")) {
                let type = this.state.actionType === "revert" ? "ACTIVE" : "SUSPENDED";
                this.viewCaseDelete(this.state.actionAppId, type)
            }
        }

    };

    renderTable = () => {
        return (
            (this.state.getData && this.state.showTable) &&
            <TableComponent tableData={this.state.tableData} tableColumns={this.renderTableColumn()}
                            onRowClick={this.onRowClick} selection={false} loader={this.state.loading}
                            maxBodyHeight="450px"
                            totalRow={this.state.totalRow} page={this.state.page} whiteSpace={"wrap"}
                            handleChangePage={this.handleChangePage} rowsPerPage={this.state.rowsPerPage}
            />
        )
    };

    closeModal = () => {
        this.setState({
            showInbox: false,
            getData: false,
            showTable: false,
            loading: true,
        }, this.functionForGetTabWiseUrl(this.state.activeItem, this.state.page))
    };

    closeIcon = () => {
        this.setState({
            showInbox: false,
            getData: true,
            showTable: true,
            loading: false
        })
    };

    renderInboxComponent = () => {
        return this.renderInboxCaseSubmitted()
    };

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderCloseIcon()}
                </CardHeader>
                <CardBody>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loading} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.confirmAlert}>
                        <DialogContent className={classes.dialogPaper}>
                            <ConfirmAlert actionFlag={this.state.actionType} remarksAdd={true}
                                          appUid={this.state.actionAppId}
                                          closeModal={this.closeConfirmAlert}/>

                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        fullScreen={true}
                        open={this.state.showInbox}>
                        <DialogContent className={classes.dialogPaper}>
                            {this.renderInboxComponent()}
                        </DialogContent>
                    </Dialog>
                    <ThemeProvider theme={theme}>
                        {this.renderFilterField()}
                        <Grid item xs={12}><br/></Grid>
                        {this.renderMenu()}
                        {this.renderTable()}
                        {this.renderNotification()}
                    </ThemeProvider>
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(styles)(ClaimCase);
