import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import 'semantic-ui-offline/semantic.min.css';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import SelectComponent from "../../JsonForm/SelectComponent"
import AutoCompleteComponent from "../../JsonForm/AutoCompleteComponent";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

let jsonForCustomerCategory = {
    type: "select",
    enum: ["Account", "AMEX", "VISA/Master", "Others"],
    label: "Select Customer category",
    onKeyDown: true,
    grid: 2,
};

let jsonForCallCategory = {
    type: "autoComplete",
    label: "Select Call category",
    grid: 2,
};

let jsonForm = [
    {
        varName: "groupName",
        type: "text",
        label: "Group Name",
        required: true,
        grid: 2,
    }
];


class CreateGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            err: false,
            errorArray: {},
            errorMessages: {},
            varValue: {},
            inputData: {},
            showValue: false,
            alert: false,
            title: "",
            autoComplete: {},
            notificationMessage: "",
            dynamicField: []
        };
    }


    componentDidMount() {
        // let url = backEndServerURL + "/card/call_category";
        // axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
        //     .then((response) => {
        //          if(response.status === 200){
        //              let callCategory = [];
        //              for (let item of response.data){
        //                  callCategory.push(item.label)
        //              }
        //              jsonForCallCategory.enum = callCategory
        //          }
        //     })
        //     .catch((error) => {
        //         console.log(error)
        //     })
    }

    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    addClick = () => {
        let randomNumber = Math.floor(Math.random() * 100000000000);
        this.setState(prevState => ({
            dynamicField: [...prevState.dynamicField, randomNumber],
        }));
    };

    renderAddButtonShow = () => {
        return (
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    float: 'left',
                    verticalAlign: 'left',
                }}
                type='button' value='add more'
                onClick={this.addClick}
            >+ New</button>
        )

    };

    removeClick(i, event) {
        event.preventDefault();
        let formExist = this.state.dynamicField.indexOf(i);
        if (formExist !== -1) {
            this.state.dynamicField.splice(formExist, 1);
            this.updateComponent();
        }
    };


    dynamicCustomerCategoryForm = (i) => {
        let field = JSON.parse(JSON.stringify(jsonForCustomerCategory));
        field.varName = "customerCategory" + i;
        return SelectComponent.select(this.state, this.updateComponent, field, this.onKeyDownChange);
    };

    onKeyDownChange = (data) => {
        console.log(jsonForCustomerCategory);
        let randomNumber = data.varName.replace(/[^0-9]/g, '');
        if (data && this.state.inputData[data.varName]) {
            let cCategory = this.state.inputData[data.varName] === "VISA/Master" ? "visaMaster" : this.state.inputData[data.varName] === "Others" ? "other" : this.state.inputData[data.varName].toLowerCase();
            let url = backEndServerURL + "/getCallCategory/" + cCategory;
            let callCategory = [];
            let autoComplete = {};
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    if (response.status === 200) {
                        response.data.map((item) => {
                            callCategory.push({label: item.label})
                        });
                        autoComplete["callCategory" + randomNumber] = callCategory;
                        console.log(autoComplete);
                        this.setState({
                            autoComplete: {...this.state.autoComplete, ...autoComplete},
                        });
                        console.log(this.state.autoComplete)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });

        }
    };

    dynamicCallCategoryForm = (i) => {
        let field = JSON.parse(JSON.stringify(jsonForCallCategory));
        field.varName = "callCategory" + i;
        return AutoCompleteComponent.renderSelect(this.state, this.updateComponent, field);
    };

    addFileTypeForm() {
        return this.state.dynamicField.map((el, i) =>
            <React.Fragment>
                <Grid xs='12'>
                </Grid>
                <Grid item xs={2}>
                    {
                        this.dynamicCustomerCategoryForm(el)
                    }
                </Grid>
                <Grid item xs={2}>
                    {
                        this.dynamicCallCategoryForm(el)
                    }
                </Grid>
                <Grid item xs={1.5}>
                    <button
                        style={{float: "right", marginTop: "15px"}}
                        className="btn btn-outline-primary btn-sm"
                        type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                    >
                        Remove
                    </button>
                </Grid>
            </React.Fragment>
        )

    };

    handleSubmit = () => {
        let error = MyValidation.defaultValidation(jsonForm, this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        } else {
            let url = backEndServerURL + "/CreateNewGroupForCardCallCategory/" + this.state.inputData.groupName;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        title: "Successful",
                        notificationMessage: "Group Create Successful!",
                        alert: true
                    });
                    setTimeout(() => {
                        window.location.reload()
                    }, 300)
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        title: "Warning",
                        notificationMessage: "Failed to Create Group!",
                        alert: true
                    })
                });
        }
    };

    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >
                                Create Group
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        jsonForm,
                                        this.updateComponent
                                    )}
                                    {this.renderNotification()}
                                    {/*<Grid item xs='12'>*/}
                                    {/*    <br/>*/}
                                    {/*</Grid>*/}
                                    {/*<Grid item xs='12'>*/}
                                    {/*    {this.renderAddButtonShow()}*/}
                                    {/*</Grid>*/}
                                    {/*{this.addFileTypeForm()}*/}
                                    {/*<Grid item xs='12'>*/}
                                    {/*</Grid>*/}
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            height: 30,
                                            marginTop: 17,
                                        }}
                                        onClick={this.handleSubmit}
                                    >
                                        Submit
                                    </button>
                                </ThemeProvider>
                            </Grid>
                        </CardBody>
                    </Card>
                </div>
            );
        }

    }

}

export default withStyles(styles)(CreateGroup);
