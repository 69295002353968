import React from "react";
import FormSample from './JsonForm/FormSample';
import CardHeader from "./Card/CardHeader";
import Card from "./Card/Card";
import CardBody from "./Card/CardBody";
import GridItem from "./Grid/GridItem";
import GridContainer from "./Grid/GridContainer";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from 'axios';
import Table from "./Table/Table";
import {backEndServerURL} from "../Common/Constant";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Dialog, Popover, TextField} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import Pageview from '@material-ui/icons/Pageview';
import Customer from "./360View/Customer";
import Notification from "./NotificationMessage/Notification";
import loader from '../Static/loader.gif';
import {
    CSjsonFormIndividualAccountOpeningSearch,
    CSjsonFormNonIndividualProprietorshipAccountOpeningSearch,
} from './workflow/WorkflowJsonForm2';
import CBNotFound from "./workflow/CASA/CBNotFound";
import Grid from "@material-ui/core/Grid";
import DedupLiabilityService from "./workflow/DedupLiabilityService";
import {
    CSJsonFormForCasaIndividualFdr,
    CSJsonFormForCasaIndividualTagFdr,
    CSJsonFormForCasaIndividualTagFdr2,
    CSLockerOpeningForm,
} from "./workflow/WorkflowJsonFormArin";
import AccountList from "./workflow/fdr/AccountList";
import LocalstorageEncrypt from "./JsonForm/LocalstorageEncrypt";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


class LockerDidupSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: [],
            getDedupData: false,
            dedupData: [[" ", " "]],
            propritorshipData: [[" ", " "]],
            relatedData: [[" ", " "]],
            tableData: [],
            sourceMappingData: [],
            getsearchValue: [],
            getCustomerId: '',
            getAccountType: '',
            accountOpeningFromModal: false,
            SelectedData: '',
            tabMenuSelect: 'INDIVIDUAL',
            existingAcoountOpeningModal: false,
            newAcoountOpeningModal: false,
            searchTableData: null,
            searchTableRelatedData: null,
            oldAccountData: [],
            inputData: {},
            dataNotFound: false,
            CustomerModal: false,
            uniqueId: '',
            IDENTIFICATION_NO: '',
            id: '',
            alert: false,
            value: "INDIVIDUAL",
            NonIndividualabel: "",
            individualLabel: "Individual A/C",
            content: "INDIVIDUAL",
            anchorEl: null,
            anchorE2: null,
            individualDropdownOpen: null,
            objectForJoinAccount: {},
            handleChangeJoint: 0,
            cbNotFound: false,
            loading: false,
            jointSearchTable: [],
            propritorship: false,
            propritor: false,
            individualDedupData: {},
            jointDedupData: {},
            companyDedupData: {},


            notificationMessage: "CB number / NID / Passport / Birth Certificate or Driving License is Required!!"


        };

        this.handleChange = this.handleChange.bind(this);
        this.handleClickNonIndividual = this.handleClickNonIndividual.bind(this);
        this.handleClickIndividual = this.handleClickIndividual.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
    };

    handleClickNonIndividual(event) {

        event.stopPropagation();
        this.setState({
            anchorEl: event.currentTarget,

        });
    }

    handleClickIndividual(event) {

        event.stopPropagation();
        this.setState({
            anchorE2: event.currentTarget,
            individualDropdownOpen: true
        });
    }

    handleClose() {
        this.setState({
            anchorEl: null,
            anchorE2: null,
            individualDropdownOpen: false
        });
    }

    handleMenuItemClick(menuItem) {
        this.handleClose();
        this.setState({
            NonIndividualabel: menuItem,
            content: menuItem,
            value: "More"
        });
    }

    handleMenuItemClickIndividual(menuItem) {
        this.handleClose();
        this.setState({
            individualLabel: menuItem,
            content: menuItem,
            value: "More"
        });
    }

    renderExistingAccountOpeningForm = (customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType) => {
        let arrayData = {
            nid: nid,
            passport: passport,
            customerName: customerName,
            phone: phone,
            dob: dob,
            email: email,
            registration: registration
        };

        this.setState({
            existingAcoountOpeningModal: true,
            oldAccountData: arrayData,
            getCustomerId: customerId,
            getAccountType: accountSource,
            newAcoountOpeningModal: false,
        })
    };
    renderNewAccountOpeingForm = () => {
        this.setState({
            newAcoountOpeningModal: true,

        })
    };
    CustomerModal = (uniqueId) => {
        this.setState({
            CustomerModal: true,
            IDENTIFICATION_NO: uniqueId

        })
    };
    closeCustomerModal = () => {
        this.setState({
            CustomerModal: false,

        })
    };
    closeModal = () => {
        this.setState({
            existingAcoountOpeningModal: false,
            newAcoountOpeningModal: false,
            cbNotFound: false
        })
    };
    radioButtonChange = (event) => {

        let variable = {};
        variable[event.target.name] = event.target.value
        /* this.setState({
             getRadioButtonData: variable
         })
 */
    };

    createTableData = (randomNumber, id, uniqueId, customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType) => {
        //this.renderCustomerNumber(sl);
        if (this.state.content === 'Joint Account') {
            if (accountSource === "TRANZWARE") {
                return ([uniqueId, customerId, accountSource, customerName, nid, passport, tin, matchType, "", "",
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId)}
                    >
                        {/*<Fab size="small" color="secondary" aria-label="pageview">*/}

                        <Pageview/>
                        {/*</Fab>*/}

                    </button>])
            } else {
                return (

                    [/*<input type="radio" onChange={(event) => this.radioButtonChange(event)} name={randomNumber}
                            value={customerId}/>*/, uniqueId, customerId, accountSource, customerName, nid, passport, tin, matchType,
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'middle',
                            }}

                            onClick={() => this.CustomerModal(uniqueId)}
                        >
                            {/*<Fab size="small" color="secondary" aria-label="pageview">*/}

                            <Pageview/>
                            {/*</Fab>*/}

                        </button>


                    ])
            }
        } else {
            if (accountSource === "TRANZWARE") {
                return ([uniqueId, customerId, accountSource, customerName, nid, passport, tin, matchType, "", "",
                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>])
            } else if (this.state.content === 'NONINDIVIDUAL' || this.state.content === 'Proprietorship A/C') {
                return ([uniqueId, customerId, accountSource, customerName, nid, passport, tin, matchType,


                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId)}
                    >
                        {/*<Fab size="small" color="secondary" aria-label="pageview">*/}

                        <Pageview/>
                        {/*</Fab>*/}

                    </button>


                ])
            } else {
                if (accountSource === "ABABIL") {
                    return ([uniqueId, customerId, accountSource, customerName, nid, passport, tin, matchType,

                        [<div>
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'middle',
                                    width: 70,
                                }}
                                onClick={() => this.renderExistingAccountOpeningForm(customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType)}>
                                Locker
                            </button>
                            {/*<button*/}
                            {/*    className="btn btn-outline-primary btn-sm"*/}
                            {/*    style={{*/}
                            {/*        verticalAlign: 'middle',*/}
                            {/*        width: 70,*/}
                            {/*    }}*/}
                            {/*    onClick={() => this.renderExistingAccountOpeningForm(customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType)}>*/}
                            {/*    FINACLEA</button>*/}
                        </div>],

                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'middle',
                            }}

                            onClick={() => this.CustomerModal(uniqueId)}
                        >
                            {/*<Fab size="small" color="secondary" aria-label="pageview">*/}

                            <Pageview/>
                            {/*</Fab>*/}

                        </button>

                    ])
                } else {
                    return ([uniqueId, customerId, accountSource, customerName, nid, passport, tin, matchType,

                        [<div>
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'middle',
                                    width: 70,
                                }}
                                onClick={() => this.renderExistingAccountOpeningForm(customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType)}>
                                Locker
                            </button>
                            {/*<button*/}
                            {/*    className="btn btn-outline-primary btn-sm"*/}
                            {/*    style={{*/}
                            {/*        verticalAlign: 'middle',*/}
                            {/*        width: 70,*/}
                            {/*    }}*/}
                            {/*    onClick={() => this.renderExistingAccountOpeningForm(customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType)}>*/}
                            {/*    ABABILA</button>*/}
                        </div>],

                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'middle',
                            }}

                            onClick={() => this.CustomerModal(uniqueId)}
                        >
                            {/*<Fab size="small" color="secondary" aria-label="pageview">*/}

                            <Pageview/>
                            {/*</Fab>*/}

                        </button>


                    ])
                }
            }
        }

    };
    createTableDataWithoutProprietorship = (id, uniqueId, customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType) => {
        //this.renderCustomerNumber(sl);
        if (this.state.content === 'Company Account') {
            if (accountSource === "TRANZWARE") {
                return ([uniqueId, customerId, accountSource, customerName, nid, passport, tin, matchType, "", "",
                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>])
            } else {
                return (

                    [uniqueId, customerId, accountSource, customerName, nid, passport, tin, matchType,
                        <button
                            className="btn"
                            style={{
                                verticalAlign: 'middle',
                            }}

                            onClick={() => this.CustomerModal(uniqueId)}
                        >
                            <Fab size="small" color="secondary" aria-label="pageview">

                                <Pageview/>
                            </Fab>

                        </button>


                    ])
            }
        }


    };
    createTableproCompany = (id, uniqueId, customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType) => {


        if (this.state.content === 'NONINDIVIDUAL' || this.state.content === 'Proprietorship A/C') {
            return ([uniqueId, customerId, accountSource, customerName, nid, passport, tin, matchType,

                [<div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'middle',
                            width: 70,
                        }}
                        onClick={() => this.renderExistingAccountOpeningForm(customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType)}>
                        {accountSource}</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'middle',
                            width: 70,
                        }}
                        onClick={() => this.renderExistingAccountOpeningForm(customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType)}>
                        ABABIL
                    </button>
                </div>],

                <button
                    className="btn"
                    style={{
                        verticalAlign: 'middle',
                    }}

                    onClick={() => this.CustomerModal(uniqueId)}
                >
                    <Fab size="small" color="secondary" aria-label="pageview">

                        <Pageview/>
                    </Fab>

                </button>


            ])

        } else {
            return ([uniqueId, customerId, accountSource, customerName, nid, passport, tin, matchType,
                [<div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'middle',
                            width: 70,
                        }}
                        onClick={() => this.renderExistingAccountOpeningForm(customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType)}>
                        {accountSource}</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'middle',
                            width: 70,
                        }}
                        onClick={() => this.renderExistingAccountOpeningForm(customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType)}>
                        ABABIL
                    </button>
                </div>],

                <button
                    className="btn"
                    style={{
                        verticalAlign: 'middle',
                    }}

                    onClick={() => this.CustomerModal(uniqueId)}
                >
                    <Fab size="small" color="secondary" aria-label="pageview">

                        <Pageview/>
                    </Fab>

                </button>


            ])
        }

    };

    createRelatedTableData = (id, mainCB, relatedCB, relatedCustomerName, relationship) => {

        if (this.state.content === 'Joint Account' || this.state.content === 'Company Account') {
            return ([mainCB, relatedCB, relatedCustomerName, relationship, [<div>
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                        width: 70,

                    }}
                    onClick={() => this.renderNewAccountOpeingForm()}
                >
                    FINACLE
                </button>
                &nbsp;&nbsp;&nbsp;
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                        width: 70,

                    }}
                    onClick={() => this.renderNewAccountOpeingForm()}
                >
                    ABABIL
                </button>
            </div>]])

        } else {
            return ([mainCB, relatedCB, relatedCustomerName, relationship])

        }
    };

    renderReloading(status) {
        this.setState({

            searchTableData: status,
            searchTableRelatedData: status,

        })
    }

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="error" stopNotification={this.stopNotification} title="Required!!"
                              message={this.state.notificationMessage}/>
            )
        }

    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    getSubmitedForm = (object) => {

        console.log(object);

        this.renderReloading(null);
        this.setState({
            loading: true
        });

        let postData = {};
        let postDataProprietorship = {};

        if (this.state.content === 'INDIVIDUAL' || this.state.content === 'Individual A/C') {
            if (object.cbNumber === "" && object.nid === "" && object.passport === "" && object.registrationNo === "") {
                this.setState({
                    notificationMessage: "CB number / NID / Passport / Birth Certificate or Driving License is Required!!",
                    alert: true,
                    loading: false
                });
                return;
            }
            var dateString;
            if (object.dob !== undefined && object.dob !== -1) {

                let da = object.dob.split(",");


                if (da != "" && da.length > 0) {
                    let monthDateYear = da[0].split("/");
                    let month, date;

                    if (monthDateYear[0].length === 1)
                        month = "0" + monthDateYear[0];
                    else
                        month = monthDateYear[0];
                    if (monthDateYear[1].length === 1)
                        date = "0" + monthDateYear[1];
                    else date = monthDateYear[1];
                    dateString = monthDateYear[2] + "-" + month + "-" + date;
                } else {
                    dateString = "1000-01-01";
                }
            } else {
                dateString = "1000-01-01";
            }


            postData = {
                "cbNumber": object.cbNumber,
                "nid": object.nid,
                "passport": object.passport,
                "customerName": object.customerName,
                "dob": dateString,
                "gender": object.gender,
                "fathersName": "",
                "mothersName": "",
                "email": object.email,
                "phone": object.phone,
                "tin": object.tin,
                "registrationNo": object.registrationNo,
                "constitutionType": "",
                "sources": ["FINACLE", "ABABIL", "TRANZWARE"],

            };

        } else if (this.state.content === 'Joint Account') {

            let objectForJoinAccount = {"customerInfoList": []};
            for (let j = 0; j < this.state.handleChangeJoint; j++) {
                if (object["cbNumber" + j] === "" && object["nid" + j] === "" && object["passport" + j] === "" && object["registrationNo" + j] === "") {
                    this.setState({
                        notificationMessage: "Customer" + (j + 1) + "CB number / NID / Passport / Birth Certificate or Driving License is Required!!",
                        alert: true
                    });
                    return;
                }

                let da = object["dob" + j].split(",");

                let dateString;

                if (da != "" && da.length > 0) {
                    let monthDateYear = da[0].split("/");
                    let month, date;

                    if (monthDateYear[0].length === 1)
                        month = "0" + monthDateYear[0];
                    else
                        month = monthDateYear[0];
                    if (monthDateYear[1].length === 1)
                        date = "0" + monthDateYear[1];
                    else date = monthDateYear[1];
                    dateString = monthDateYear[2] + "-" + month + "-" + date;
                } else {
                    dateString = "1000-01-01";
                }


                objectForJoinAccount.customerInfoList.push({
                    "cbNumber": object["cbNumber" + j],
                    "nid": object["nid" + j],
                    "passport": object["passport" + j],
                    "customerName": object["customerName" + j],
                    "dob": "",
                    "gender": object["gender" + j],
                    "fathersName": "",
                    "mothersName": "",
                    "email": object["email" + j],
                    "phone": object["phone" + j],
                    "tin": object["tin" + j],
                    "registrationNo": object["registrationNo" + j],
                    "constitutionType": "",
                    "sources": ["FINACLE", "ABABIL", "TRANZWARE"]
                })

            }

            postData = objectForJoinAccount;

        } else if (this.state.content === 'NONINDIVIDUAL' || this.state.content === 'Proprietorship A/C') {
            if ((object.companyEtin === "" || object.companyEtin === undefined) && object.cbNumber === "") {
                this.setState({
                    notificationMessage: "E-TIN is required!!",
                    alert: true,
                    loading: false
                });
                return;
            }

            var dateString;
            if (object.dob !== undefined && object.dob !== -1) {
                let da = object.dob.split(",");


                if (da != "" && da.length > 0) {
                    let monthDateYear = da[0].split("/");
                    let month, date;

                    if (monthDateYear[0].length === 1)
                        month = "0" + monthDateYear[0];
                    else
                        month = monthDateYear[0];
                    if (monthDateYear[1].length === 1)
                        date = "0" + monthDateYear[1];
                    else date = monthDateYear[1];
                    dateString = monthDateYear[2] + "-" + month + "-" + date;
                } else {
                    dateString = "1000-01-01";
                }
            } else {
                dateString = "1000-01-01";
            }
            postDataProprietorship = {
                "cbNumber": object.companyCbNumber,
                "nid": "",
                "passport": "",
                "customerName": object.companyName,
                "gender": "",
                "fathersName": "",
                "mothersName": "",
                "email": object.companyEmail,
                "phone": object.companyMobileNo,
                "tin": object.companyEtin,
                "registrationNo": "",
                "constitutionType": "",
                "sources": ["FINACLE", "ABABIL", "TRANZWARE"],

            };
            postData = {
                "cbNumber": object.cbNumber,
                "nid": object.nid,
                "passport": object.passport,
                "customerName": object.customerName,
                "dob": dateString,
                "gender": object.gender,
                "fathersName": "",
                "mothersName": "",
                "email": object.email,
                "phone": object.phone,
                "tin": object.tin,
                "registrationNo": object.registrationNo,
                "constitutionType": "",
                "sources": ["FINACLE", "ABABIL", "TRANZWARE"],

            };

        } else if (this.state.content === 'Company Account') {
            let objectForJoinAccount = {"customerInfoList": []};
            for (let j = 0; j < this.state.handleChangeJoint; j++) {
                if (object["cbNumber" + j] === "" && object["nid" + j] === "" && object["passport" + j] === "" && object["registrationNo" + j] === "") {
                    this.setState({
                        notificationMessage: "Customer" + (j + 1) + "CB number / NID / Passport / Birth Certificate or Driving License is Required!!",
                        alert: true
                    });
                    return;
                }

                let da = object["dob" + j].split(",");

                let dateString;

                if (da != "" && da.length > 0) {
                    let monthDateYear = da[0].split("/");
                    let month, date;

                    if (monthDateYear[0].length === 1)
                        month = "0" + monthDateYear[0];
                    else
                        month = monthDateYear[0];
                    if (monthDateYear[1].length === 1)
                        date = "0" + monthDateYear[1];
                    else date = monthDateYear[1];
                    dateString = monthDateYear[2] + "-" + month + "-" + date;
                } else {
                    dateString = "1000-01-01";
                }


                objectForJoinAccount.customerInfoList.push({
                    "cbNumber": object["cbNumber" + j],
                    "nid": object["nid" + j],
                    "passport": object["passport" + j],
                    "customerName": object["customerName" + j],
                    "dob": "",
                    "gender": object["gender" + j],
                    "fathersName": "",
                    "mothersName": "",
                    "email": object["email" + j],
                    "phone": object["phone" + j],
                    "tin": object["tin" + j],
                    "registrationNo": object["registrationNo" + j],
                    "constitutionType": "",
                    "sources": ["FINACLE", "ABABIL", "TRANZWARE"]
                })

            }

            postData = objectForJoinAccount;
            if ((object.companyEtin === "" || object.companyEtin === undefined) && object.cbNumber === "") {
                this.setState({
                    notificationMessage: "E-TIN is required!!",
                    alert: true,
                    loading: false
                });
                return;
            }


            postDataProprietorship = {
                "cbNumber": object.companyCbNumber,
                "nid": "",
                "passport": "",
                "customerName": object.companyName,
                "gender": "",
                "fathersName": "",
                "mothersName": "",
                "email": object.companyEmail,
                "phone": object.companyMobileNo,
                "tin": object.companyEtin,
                "registrationNo": "",
                "constitutionType": "",
                "sources": ["FINACLE", "ABABIL", "TRANZWARE"],

            };


        }

        this.renderReloading(false);
        if (this.state.content === 'Joint Account') {
            let tableArray = [];
            let relatedTableArray = [];

            //set 02/02/1983, 00:00:00 to 1983-02-02 format

            let url = backEndServerURL + "/jointDedup";


            axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.setState({
                        sourceMappingData: response.data.sourceMapping,
                        jointDedupData: response.data

                    });


                    let jointDedupSearchTable = [];


                    if (response.data.highMatchCustomers !== null && Array.isArray(response.data.highMatchCustomers)) {
                        /*    var sl=0;
                       response.data.highMatchCustomers.map((dedup) => {

                           sl++;

                          dedup.map((dedup)=>{

                              tableArray.push(this.createTableData(sl,dedup.id, dedup.identification_no, dedup.maincustid, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, "Higher-Match"));
                          })

                       });*/
                        var randomNumber = 0;

                        response.data.highMatchCustomers.map((dedup, i) => {
                            let customer = [];
                            randomNumber = "joint" + randomNumber + 1;
                            dedup.map((dedup) => {

                                customer.push(this.createTableData(randomNumber, dedup.id, dedup.identification_no, dedup.maincustid, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, "Higher-Match"));
                            });
                            jointDedupSearchTable.push(customer);

                        });


                    }


                    // if (response.data.mediumMatchCustomers !== null && Array.isArray(response.data.mediumMatchCustomers)) {
                    //     var sl=0;
                    //     response.data.mediumMatchCustomers.map((dedup) => {
                    //         sl++;
                    //         dedup.map((dedup)=>{
                    //             tableArray.push(this.createTableData(sl,dedup.id, dedup.identification_no, dedup.maincustid, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, "Medium-Match"));
                    //         })
                    //
                    //
                    //
                    //     });
                    // }

                    if (response.data.relatedCustomers !== null && Array.isArray(response.data.relatedCustomers)) {
                        response.data.relatedCustomers.map((dedup) => {

                            relatedTableArray.push(this.createRelatedTableData(dedup.id, dedup.mainCB, dedup.relatedCB, dedup.relatedCustomerName, dedup.relationship));

                        });
                    }

                    this.setState({

                        jointSearchTable: jointDedupSearchTable,
                        relatedData: relatedTableArray,
                        searchTableData: true,
                        searchTableRelatedData: true,
                        loading: false,
                        getsearchValue: object,

                    })

                })
                .catch((error) => {

                    this.setState({
                        jointSearchTable: [],
                        relatedData: [],
                        searchTableData: true,
                        searchTableRelatedData: true,
                        loading: false,
                    })

                });
        } else {
            if (this.state.content === 'Company Account') {
                let tableArray = [];
                let relatedTableArray = [];

                //set 02/02/1983, 00:00:00 to 1983-02-02 format

                let url = backEndServerURL + "/jointDedup";


                axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        console.log(response.data);
                        this.setState({
                            sourceMappingData: response.data.sourceMapping,
                            jointDedupData: response.data

                        });


                        let jointDedupSearchTable = [];


                        if (response.data.highMatchCustomers !== null && Array.isArray(response.data.highMatchCustomers)) {
                            /*    var sl=0;
                           response.data.highMatchCustomers.map((dedup) => {

                               sl++;

                              dedup.map((dedup)=>{

                                  tableArray.push(this.createTableData(sl,dedup.id, dedup.identification_no, dedup.maincustid, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, "Higher-Match"));
                              })

                           });*/


                            response.data.highMatchCustomers.map((dedup, i) => {
                                let customer = [];

                                dedup.map((dedup) => {

                                    customer.push(this.createTableDataWithoutProprietorship(dedup.id, dedup.identification_no, dedup.maincustid, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, "Higher-Match"));
                                });
                                jointDedupSearchTable.push(customer);

                            });


                        }


                        // if (response.data.mediumMatchCustomers !== null && Array.isArray(response.data.mediumMatchCustomers)) {
                        //     var sl=0;
                        //     response.data.mediumMatchCustomers.map((dedup) => {
                        //         sl++;
                        //         dedup.map((dedup)=>{
                        //             tableArray.push(this.createTableData(sl,dedup.id, dedup.identification_no, dedup.maincustid, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, "Medium-Match"));
                        //         })
                        //
                        //
                        //
                        //     });
                        // }

                        if (response.data.relatedCustomers !== null && Array.isArray(response.data.relatedCustomers)) {
                            response.data.relatedCustomers.map((dedup) => {

                                relatedTableArray.push(this.createRelatedTableData(dedup.id, dedup.mainCB, dedup.relatedCB, dedup.relatedCustomerName, dedup.relationship));

                            });
                        }

                        this.setState({

                            jointSearchTable: jointDedupSearchTable,
                            relatedData: relatedTableArray,
                            searchTableData: true,
                            searchTableRelatedData: true,
                            loading: false,
                            getsearchValue: object,

                        })

                    })
                    .catch((error) => {

                        this.setState({
                            jointSearchTable: [],
                            relatedData: [],
                            searchTableData: true,
                            searchTableRelatedData: true,
                            loading: false,
                        })

                    });
            }
            if (this.state.content === 'NONINDIVIDUAL' || this.state.content === 'Proprietorship A/C') {
                let tableArray = [];
                let relatedTableArray = [];

                //set 02/02/1983, 00:00:00 to 1983-02-02 format

                let url = backEndServerURL + "/dedup";


                axios.post(url, postDataProprietorship, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        console.log(response.data);
                        this.setState({
                            sourceMappingData: response.data.sourceMapping,
                            companyDedupData: response.data

                        });
                        if (object.companyCbNumber !== "" && (response.data.highMatchCustomers.length < 1 && response.data.mediumMatchCustomers.length < 1 && response.data.relatedCustomers.length < 1)) {
                            this.setState({
                                cbNotFound: true,
                                searchTableData: null,
                                searchTableRelatedData: null,
                                loading: false,
                            })
                        } else {

                            if (response.data.highMatchCustomers !== null && Array.isArray(response.data.highMatchCustomers)) {

                                response.data.highMatchCustomers.map((dedup) => {
                                    tableArray.push(this.createTableproCompany(dedup.id, dedup.identification_no, dedup.maincustid, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, "Higher-Match"));

                                });

                            }

                            if (response.data.mediumMatchCustomers !== null && Array.isArray(response.data.mediumMatchCustomers)) {

                                response.data.mediumMatchCustomers.map((dedup) => {

                                    tableArray.push(this.createTableproCompany(dedup.id, dedup.identification_no, dedup.maincustid, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, "Medium-Match"));

                                });
                            }

                            if (response.data.relatedCustomers !== null && Array.isArray(response.data.relatedCustomers)) {
                                response.data.relatedCustomers.map((dedup) => {

                                    relatedTableArray.push(this.createRelatedTableData(dedup.id, dedup.mainCB, dedup.relatedCB, dedup.relatedCustomerName));

                                });
                            }
                            this.setState({

                                propritorshipData: tableArray,
                                relatedData: relatedTableArray,
                                searchTableData: true,
                                searchTableRelatedData: true,
                                getsearchValue: object,
                                loading: false,

                            })
                        }


                    })
                    .catch((error) => {

                        this.setState({
                            propritorshipData: [],
                            relatedData: [],
                            searchTableData: true,
                            searchTableRelatedData: true,
                            loading: false,
                        })

                    });

            }
            if (this.state.content === 'NONINDIVIDUAL' || this.state.content === 'Proprietorship A/C') {
                let tableArray = [];
                let relatedTableArray = [];

                //set 02/02/1983, 00:00:00 to 1983-02-02 format

                let url = backEndServerURL + "/dedup";


                axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {


                        console.log(response.data);
                        this.setState({
                            sourceMappingData: response.data.sourceMapping,
                            individualDedupData: response.data


                        });
                        if (object.cbNumber !== "" && (response.data.highMatchCustomers.length < 1 && response.data.mediumMatchCustomers.length < 1 && response.data.relatedCustomers.length < 1)) {
                            this.setState({
                                cbNotFound: true,
                                searchTableData: null,
                                searchTableRelatedData: null,
                                loading: false,
                            })
                        } else {

                            if (response.data.highMatchCustomers !== null && Array.isArray(response.data.highMatchCustomers)) {

                                response.data.highMatchCustomers.map((dedup) => {
                                    tableArray.push(this.createTableData("", dedup.id, dedup.identification_no, dedup.maincustid, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, "Higher-Match"));

                                });

                            }

                            if (response.data.mediumMatchCustomers !== null && Array.isArray(response.data.mediumMatchCustomers)) {

                                response.data.mediumMatchCustomers.map((dedup) => {

                                    tableArray.push(this.createTableData("", dedup.id, dedup.identification_no, dedup.maincustid, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, "Medium-Match"));

                                });
                            }

                            if (response.data.relatedCustomers !== null && Array.isArray(response.data.relatedCustomers)) {
                                response.data.relatedCustomers.map((dedup) => {

                                    relatedTableArray.push(this.createRelatedTableData(dedup.id, dedup.mainCB, dedup.relatedCB, dedup.relatedCustomerName));

                                });
                            }
                            this.setState({

                                dedupData: tableArray,
                                relatedData: relatedTableArray,
                                searchTableData: true,
                                searchTableRelatedData: true,
                                getsearchValue: object,
                                loading: false,

                            })
                        }


                    })
                    .catch((error) => {

                        this.setState({
                            dedupData: [],
                            relatedData: [],
                            searchTableData: true,
                            searchTableRelatedData: true,
                            loading: false,
                        })

                    });

            } else if (this.state.content === 'Company Account') {
                let tableArray = [];
                let relatedTableArray = [];

                //set 02/02/1983, 00:00:00 to 1983-02-02 format

                let url = backEndServerURL + "/dedup";


                axios.post(url, postDataProprietorship, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        console.log(response.data);
                        this.setState({
                            sourceMappingData: response.data.sourceMapping,
                            companyDedupData: response.data

                        });
                        if (object.companyCbNumber !== "" && (response.data.highMatchCustomers.length < 1 && response.data.mediumMatchCustomers.length < 1 && response.data.relatedCustomers.length < 1)) {
                            this.setState({
                                cbNotFound: true,
                                searchTableData: null,
                                searchTableRelatedData: null,
                                loading: false,
                            })
                        } else {

                            if (response.data.highMatchCustomers !== null && Array.isArray(response.data.highMatchCustomers)) {

                                response.data.highMatchCustomers.map((dedup) => {
                                    tableArray.push(this.createTableproCompany(dedup.id, dedup.identification_no, dedup.maincustid, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, "Higher-Match"));

                                });

                            }

                            if (response.data.mediumMatchCustomers !== null && Array.isArray(response.data.mediumMatchCustomers)) {

                                response.data.mediumMatchCustomers.map((dedup) => {

                                    tableArray.push(this.createTableproCompany(dedup.id, dedup.identification_no, dedup.maincustid, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, "Medium-Match"));

                                });
                            }

                            if (response.data.relatedCustomers !== null && Array.isArray(response.data.relatedCustomers)) {
                                response.data.relatedCustomers.map((dedup) => {

                                    relatedTableArray.push(this.createRelatedTableData(dedup.id, dedup.mainCB, dedup.relatedCB, dedup.relatedCustomerName));

                                });
                            }
                            this.setState({

                                propritorshipData: tableArray,
                                relatedData: relatedTableArray,
                                searchTableData: true,
                                searchTableRelatedData: true,
                                getsearchValue: object,
                                loading: false,

                            })
                        }


                    })
                    .catch((error) => {

                        this.setState({
                            propritorshipData: [],
                            relatedData: [],
                            searchTableData: true,
                            searchTableRelatedData: true,
                            loading: false,
                        })

                    });


            } else {
                console.log(postData);
                let tableArray = [];
                let relatedTableArray = [];

                //set 02/02/1983, 00:00:00 to 1983-02-02 format

                let url = backEndServerURL + "/dedup";
                axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);
                        this.setState({
                            sourceMappingData: response.data.sourceMapping,
                            individualDedupData: response.data
                        });
                        if (object.cbNumber !== "" && (response.data.highMatchCustomers.length < 1 && response.data.mediumMatchCustomers.length < 1 && response.data.relatedCustomers.length < 1)) {
                            this.setState({
                                cbNotFound: true,
                                searchTableData: null,
                                searchTableRelatedData: null,
                                loading: false,
                            })
                        } else {

                            if (response.data.highMatchCustomers !== null && Array.isArray(response.data.highMatchCustomers)) {

                                response.data.highMatchCustomers.map((dedup) => {
                                    tableArray.push(this.createTableData("", dedup.id, dedup.identification_no, dedup.maincustid, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, "Higher-Match"));

                                });

                            }

                            if (response.data.mediumMatchCustomers !== null && Array.isArray(response.data.mediumMatchCustomers)) {

                                response.data.mediumMatchCustomers.map((dedup) => {

                                    tableArray.push(this.createTableData("", dedup.id, dedup.identification_no, dedup.maincustid, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, "Medium-Match"));

                                });
                            }

                            if (response.data.relatedCustomers !== null && Array.isArray(response.data.relatedCustomers)) {
                                response.data.relatedCustomers.map((dedup) => {

                                    relatedTableArray.push(this.createRelatedTableData(dedup.id, dedup.mainCB, dedup.relatedCB, dedup.relatedCustomerName));

                                });
                            }
                            this.setState({

                                dedupData: tableArray,
                                relatedData: relatedTableArray,
                                searchTableData: true,
                                searchTableRelatedData: true,
                                getsearchValue: object,
                                loading: false,

                            })
                        }


                    })
                    .catch((error) => {

                        this.setState({
                            dedupData: [],
                            relatedData: [],
                            searchTableData: true,
                            searchTableRelatedData: true,
                            loading: false,
                        })

                    });

            }
        }


    };
    handleChangeJoint = (event) => {
        event.preventDefault();
        this.setState({
            handleChangeJoint: event.target.value
        })

    };
    handleSubmitJointAccountForm = () => {

        if (this.state.handleChangeJoint > 1) {
            let objectForJoinAccount = {"variables": []};

            var sl;
            for (var i = 0; i < this.state.handleChangeJoint; i++) {
                sl = i + 1;
                objectForJoinAccount.variables.push(
                    {
                        "varName": "Customer " + i,
                        "type": "title",
                        "label": "Applicant: " + sl

                    },
                    {
                        "varName": "cbNumber" + i,
                        "type": "text",
                        "label": "CB Number",

                    },
                    {
                        "varName": "nid" + i,
                        "type": "text",
                        "label": "NID +",


                    },
                    {
                        "varName": "passport" + i,
                        "type": "text",
                        "label": "Passport +",


                    },
                    {
                        "varName": "customerName" + i,
                        "type": "text",
                        "label": "Customer Name",
                        "required": true

                    },
                    {
                        "varName": "dob" + i,
                        "type": "date",
                        "label": "Date Of Birth",

                        "dates": true,
                        "required": true


                    },
                    {
                        "varName": "email" + i,
                        "type": "text",
                        "label": "Email",

                        "email": true,


                    },

                    {
                        "varName": "phone" + i,
                        "type": "text",
                        "label": "Phone Number",
                        "required": true


                    },

                    {
                        "varName": "tin" + i,
                        "type": "text",
                        "label": "E-Tin",


                    },


                    {
                        "varName": "registrationNo" + i,
                        "type": "text",
                        "label": "Birth Cer./Driving License +",


                    },
                    {
                        "varName": "nationality" + i,
                        "type": "select",
                        "label": "Nationality",
                        "enum": [
                            "BANGLADESH",
                            "JAPAN",
                            "INDIA",
                        ],
                        "required": true

                    },
                )

            }
            this.setState({
                objectForJoinAccount: objectForJoinAccount,
                content: "Joint Account"

            });
            this.renderSearchForm();

        }
    };
    handleSubmitWithoutProprietorshipAccountForm = () => {

        if (this.state.handleChangeJoint > 1) {
            let objectForJoinAccount = {"variables": []};
            objectForJoinAccount.variables.push(
                {
                    "varName": "companyTitle",
                    "type": "title",
                    "label": "Company dedup",

                },
                {
                    "varName": "companyCbNumber",
                    "type": "text",
                    "label": "CB Number",
                    "required": false,
                    "readOnly": false
                },

                {
                    "varName": "companyName",
                    "type": "text",
                    "label": "Company Name",
                    "required": false,
                    "readOnly": false

                },

                {
                    "varName": "companyEmail",
                    "type": "text",
                    "label": "Email",
                    "required": false,
                    "email": true,
                    "readOnly": false


                },

                {
                    "varName": "companyMobileNo",
                    "type": "text",
                    "label": "Mobile Number",
                    "required": false

                },

                {
                    "varName": "companyEtin",
                    "type": "text",
                    "label": "Company ETin",
                    "required": false,


                },
                {
                    "varName": "companyTradeLicense",
                    "type": "text",
                    "label": "Trade License",
                    "required": false,


                },
                {
                    "varName": "certificate",
                    "type": "text",
                    "label": "Certificate Of Incorporation",
                    "required": false,


                },
            );
            var sl;
            for (var i = 0; i < this.state.handleChangeJoint; i++) {
                sl = i + 1;
                objectForJoinAccount.variables.push(
                    {
                        "varName": "Customer " + i,
                        "type": "title",
                        "label": "Customer: " + sl

                    },
                    {
                        "varName": "cbNumber" + i,
                        "type": "text",
                        "label": "CB Number",

                    },
                    {
                        "varName": "nid" + i,
                        "type": "text",
                        "label": "NID +",


                    },
                    {
                        "varName": "passport" + i,
                        "type": "text",
                        "label": "Passport +",


                    },
                    {
                        "varName": "customerName" + i,
                        "type": "text",
                        "label": "Customer Name",
                        "required": true

                    },
                    {
                        "varName": "dob" + i,
                        "type": "date",
                        "label": "Date Of Birth",

                        "dates": true,
                        "required": true


                    },
                    {
                        "varName": "email" + i,
                        "type": "text",
                        "label": "Email",

                        "email": true,


                    },

                    {
                        "varName": "phone" + i,
                        "type": "text",
                        "label": "Phone Number",
                        "required": true


                    },

                    {
                        "varName": "tin" + i,
                        "type": "text",
                        "label": "e-Tin",


                    },


                    {
                        "varName": "registrationNo" + i,
                        "type": "text",
                        "label": "Birth Cer./Driving License +",


                    },
                    {
                        "varName": "nationality" + i,
                        "type": "select",
                        "label": "Nationality",
                        "enum": [
                            "BANGLADESH",
                            "JAPAN",
                            "INDIA",
                        ],
                        "required": true

                    },
                )

            }
            this.setState({
                objectForJoinAccount: objectForJoinAccount,
                content: "Company Account"

            });
            this.renderSearchForm();

        }
    };
    updateComponent = () => {
        this.forceUpdate();
    };
    renderSearchForm = () => {
        if (this.state.content === 'INDIVIDUAL' || this.state.content === 'Individual A/C') {
            return (
                <FormSample close={this.closeModal} grid="2" buttonName="Search" onSubmit={this.getSubmitedForm}
                            validation="INDIVIDUAL" jsonForm={CSjsonFormIndividualAccountOpeningSearch}/>
            )
        } else if (this.state.content === 'Joint A/C') {
            return (
                <Grid item xs={12}>
                    <TextField name="jointAccount" label="Number Of Customer" onChange={this.handleChangeJoint}/>

                    <div>
                        <br/>
                        <br/>
                        <center>
                            <button onClick={this.handleSubmitJointAccountForm} className="btn btn-danger">
                                GO
                            </button>
                        </center>
                    </div>
                </Grid>
            )
        } else if (this.state.content === 'Joint Account') {
            return (

                <FormSample close={this.closeModal} grid="2" buttonName="Submit" onSubmit={this.getSubmitedForm}
                            arrayLength={this.state.handleChangeJoint} validation="JOINT"
                            jsonForm={this.state.objectForJoinAccount}/>
            )
        } else if (this.state.content === 'NONINDIVIDUAL' || this.state.content === 'Proprietorship A/C') {

            return (
                <FormSample close={this.closeModal} grid="2" buttonName="Search" onSubmit={this.getSubmitedForm}
                            validation="INDIVIDUAL"
                            jsonForm={CSjsonFormNonIndividualProprietorshipAccountOpeningSearch}/>

            )
        } else if (this.state.content === 'Company Account') {
            return (
                <FormSample close={this.closeModal} grid="2" buttonName="Submit" onSubmit={this.getSubmitedForm}
                            arrayLength={this.state.handleChangeJoint} validation="JOINT"
                            jsonForm={this.state.objectForJoinAccount}/>

            )
        } else if (this.state.content === 'Partnership A/C' || this.state.content === 'Limited Company A/C' || this.state.content === 'Others') {
            return (
                <Grid item xs={12}>
                    <TextField name="jointAccount" label="Number Of Customer" onChange={this.handleChangeJoint}/>

                    <div>
                        <br/>
                        <br/>
                        <center>
                            <button onClick={this.handleSubmitWithoutProprietorshipAccountForm}
                                    className="btn btn-danger">
                                GO
                            </button>
                        </center>
                    </div>
                </Grid>
            )

        } else {
        }
    };
    handleChange = (name, value) => {
        if (value === 'INDIVIDUAL') {
            this.setState({individualLabel: 'Individual A/C'})
        }
        if (value === 'NONINDIVIDUAL') {
            this.setState({
                NonIndividualabel: 'Proprietorship A/C',
                "individualLabel": ""
            })
        }
        this.setState({

            tabMenuSelect: value, content: value,
            searchTableData: null,
            searchTableRelatedData: null,
        })
    };


    renderJointSearchtabledata = () => {
        return (
            this.state.jointSearchTable.map((tableData, index) => {
                return (

                    <div>
                        <center>
                            <br/>
                            <Grid item xs={12}>
                                <h3>Customer {index + 1}</h3>
                            </Grid>
                        </center>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Customer ID", "CB No", "Source", "Customer Name", "NID", "Passport", "E-Tin", "Match-Type", "View"]}

                            tableData={tableData}
                            tableAllign={['left', 'left', 'left', 'left', 'right', 'right', 'left', 'left', 'right', 'right']}
                        />
                    </div>

                )
            })
        )


    };
    renderWithoutProprietorshipSearchtabledata = () => {
        return (
            this.state.jointSearchTable.map((tableData, index) => {
                return (

                    <div>
                        <center>
                            <br/>
                            <Grid item xs={12}>
                                <h3>Customer {index + 1}</h3>
                            </Grid>
                        </center>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Customer ID", "CB No", "Source", "Customer Name", "NID", "Passport", "E-Tin", "Match-Type", "View"]}

                            tableData={tableData}
                            tableAllign={['left', 'left', 'left', 'left', 'left', 'right', 'right', 'left', 'left', 'right', 'right']}
                        />
                    </div>

                )
            })
        )


    };

    searchTableData = (style) => {
        if (this.state.searchTableData && (this.state.content === 'INDIVIDUAL' || this.state.content === 'Individual A/C')) {
            return (

                <React.Fragment>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>Search Result</h5>

                        </CardHeader>
                    </paper>

                    {/*<div>*/}

                    {/*    <button*/}
                    {/*        className="btn btn-outline-primary btn-sm"*/}
                    {/*        style={{*/}
                    {/*            verticalAlign: 'right',*/}
                    {/*            position: "absolute",*/}
                    {/*            marginTop:"5px",*/}
                    {/*            right: 10,*/}

                    {/*        }}*/}
                    {/*        onClick={() => this.renderNewAccountOpeingForm()}>*/}
                    {/*        New Account Creation*/}
                    {/*    </button>*/}

                    {/*</div>*/}
                    <br/>
                    <br/>
                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Customer ID", "CB No", "Source", "Customer Name", "NID", "Passport", "E-Tin", "Match-Type", "    Action", "View"]}

                        tableData={this.state.dedupData}
                        tableAllign={['left', 'left', 'left', 'left', 'left', 'right', 'right', 'left', 'left', 'left', "left"]}
                    />

                    <br/>


                </React.Fragment>

            )


        } else if (this.state.searchTableRelatedData && (this.state.content !== 'Joint Account' && this.state.content !== 'Individual A/C' && this.state.content !== 'INDIVIDUAL')) {

            return (
                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>Company/Business Result</h5>

                        </CardHeader>
                    </paper>
                    <br/>
                    <div>

                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                                position: "absolute",
                                right: 10,

                            }}
                            onClick={() => this.renderNewAccountOpeingForm()}>
                            New Account Creation
                        </button>

                    </div>
                    <br/>
                    <br/>
                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Customer ID", "CB No", "Source", "Customer Name", "NID", "Passport", "E-Tin", "Match-Type", "  Action", "View"]}

                        tableData={this.state.propritorshipData}
                        tableAllign={['left', 'left', 'left', 'left', 'left', 'right', 'right', 'left', 'left', 'right']}
                    />
                    <br/>
                </div>
            )
        } else if (this.state.searchTableRelatedData && this.state.content == 'Joint Account') {

            return (
                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>Related CB</h5>
                        </CardHeader>
                    </paper>
                    <br/>
                    {/*<div>*/}
                    {/*<button*/}
                    {/*    className="btn btn-outline-primary btn-sm"*/}
                    {/*    style={{*/}
                    {/*        verticalAlign: 'right',*/}
                    {/*        position: "absolute",*/}
                    {/*        right: 10,*/}

                    {/*    }}*/}
                    {/*    onClick={() => this.renderNewAccountOpeingForm()}>*/}
                    {/*    New Account Creation*/}
                    {/*</button>*/}
                    {/*    <br/>*/}
                    {/*</div>*/}
                    <div>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Main CB", "Related CB", "Customer Name", "RelationShip", "  Action"]}

                            tableData={this.state.relatedData}
                            tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left',]}
                        />


                        <br/>


                    </div>

                </div>


            )


        } else {

        }


    };
    searchTableRelatedData = (style) => {
        if (this.state.searchTableRelatedData && (this.state.content === 'INDIVIDUAL' || this.state.content === 'Individual A/C')) {

            return (

                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>Related CB</h5>

                        </CardHeader>
                    </paper>
                    <br/>
                    <div>

                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Main CB", "Related CB", "Customer Name", "RelationShip"]}

                            tableData={this.state.relatedData}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>


                    </div>

                </div>

            )
        } else if (this.state.searchTableRelatedData && (this.state.content === 'Proprietorship A/C' || this.state.content === "NONINDIVIDUAL")) {
            return (
                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>Proprietor Result</h5>

                        </CardHeader>
                    </paper>
                    <br/>
                    <div>

                        {/* <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',
                        position: "absolute",
                        right: 10,

                    }}
                    onClick={() => this.renderNewAccountOpeingForm()}>
                    New Account Creation
                </button>*/}

                    </div>
                    <br/>
                    <br/>
                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Customer ID", "CB No", "Source", "Customer Name", "NID", "Passport", "E-Tin", "Match-Type", "View"]}

                        tableData={this.state.dedupData}
                        tableAllign={['left', 'left', 'left', 'left', 'left', 'right', 'right', 'left', 'left', 'left', "left"]}
                    />

                    <br/>


                </div>
            )
        } else if (this.state.searchTableRelatedData && (this.state.content !== 'Joint Account' && this.state.content !== 'Individual A/C' && this.state.content !== 'INDIVIDUAL')) {
            return (
                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>Proprietor Result</h5>

                        </CardHeader>
                    </paper>
                    <br/>

                    <br/>
                    <br/>
                    {this.renderJointSearchtabledata()}
                    <br/>
                </div>


            )


        } else if (this.state.searchTableRelatedData && (this.state.content === 'Joint Account' && this.state.content !== 'Individual A/C' && this.state.content !== 'INDIVIDUAL')) {
            return (
                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>Individual Result</h5>

                        </CardHeader>
                    </paper>
                    <br/>
                    <div>


                    </div>
                    <br/>
                    <br/>
                    {this.renderJointSearchtabledata()}
                    <br/>
                </div>


            )


        }

    };
    searchTableSDNData = (style) => {

        if (this.state.searchTableRelatedData && this.state.content === 'Joint Account') {

            return (

                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>SDN Verification</h5>

                        </CardHeader>
                    </paper>


                    <Table
                        tableHeaderColor="primary"
                        tableHead={["Customer Name", "Country", "Date Of Birth"]}
                        tableData={[
                            ["", "", ""],


                        ]}
                        tableAllign={['left', 'left', 'left']}
                    />


                </div>

            )
        } else if (this.state.searchTableRelatedData && this.state.content !== 'Joint Account') {

            return (

                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>SDN Verification</h5>

                        </CardHeader>
                    </paper>


                    <Table
                        tableHeaderColor="primary"
                        tableHead={["Customer Name", "Country", "Date Of Birth"]}
                        tableData={[
                            ["", "", ""],


                        ]}
                        tableAllign={['left', 'left', 'left']}
                    />

                </div>

            )
        } else {

        }

    };
    searchTableNidVerification = (style) => {

        if (this.state.searchTableRelatedData && this.state.content === 'Joint Account') {

            return (

                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>NID Verification </h5>

                        </CardHeader>
                    </paper>


                    <Table
                        tableHeaderColor="primary"
                        tableHead={["Customer Name", "CB Number", "Nid"]}
                        tableData={[
                            ["", "", ""],


                        ]}
                        tableAllign={['left', 'left', 'left']}
                    />
                    <br/>
                    <center><h3><a target="_blank" href="http://www.nidw.gov.bd/"
                                   style={{position: 'absolute', color: "#000000"}}> NID Check </a></h3>
                    </center>


                </div>

            )
        } else if (this.state.searchTableRelatedData && this.state.content !== 'Joint Account') {

            return (

                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>NID Verification </h5>

                        </CardHeader>
                    </paper>

                    <Table
                        tableHeaderColor="primary"
                        tableHead={["Customer Name", "CB Number", "Nid"]}
                        tableData={[
                            ["", "", ""],


                        ]}
                        tableAllign={['left', 'left', 'left']}
                    />

                    <br/>

                    <center><h3><a target="_blank" href="http://www.nidw.gov.bd/"
                                   style={{position: 'absolute', color: "#000000"}}> NID Check </a></h3>
                    </center>


                </div>

            )
        } else {

        }

    };

    render() {
        const {value} = this.state;
        const open = Boolean(this.state.anchorEl);
        const {classes, onClose} = this.props;
        return (
            <section>
                {this.renderNotification()}
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.CustomerModal}
                >
                    <DialogContent className={classes.dialogPaper}>
                        <Customer closeModal={this.closeCustomerModal}
                                  IDENTIFICATION_NO={this.state.IDENTIFICATION_NO}/>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.existingAcoountOpeningModal}>
                    <DialogContent className={classes.dialogPaper}>
                        <AccountList
                            closeModal={this.closeModal}
                            getAccountType={this.props.getAccountType}
                            accountType={this.props.accountType}
                            customerId={this.props.getCustomerId}
                            serviceType="Locker Opening"
                            csNewFDRJsonFom={CSJsonFormForCasaIndividualFdr}
                            commonJsonForm={CSJsonFormForCasaIndividualTagFdr}
                            serviceJsonForm={CSJsonFormForCasaIndividualTagFdr2}
                            lockerOpening={CSLockerOpeningForm}
                            subServiceType={this.state.content}
                            type={this.props.subServiceType}
                            searchValue={this.props.searchValue}
                            jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                            individualDedupData={this.props.individualDedupData}
                            jointDedupData={this.props.jointDedupData}
                            companyDedupData={this.props.companyDedupData}
                        />
                    </DialogContent>
                </Dialog>

                <Dialog
                    fullWidth="true"
                    maxWidth="xl"

                    open={this.state.newAcoountOpeningModal}>
                    <DialogContent className={classes.dialogPaper}>

                        <DedupLiabilityService
                            closeModal={this.closeModal}
                            getAccountType={this.state.getAccountType}
                            accountType={this.state.tabMenuSelect}
                            jointAccountCustomerNumber={this.state.handleChangeJoint}
                            workplace={LocalstorageEncrypt.encryptStorageFunction.getItem('workplace')}
                            serviceType="Account Opening"
                            subServiceType={this.state.content}
                            individualDedupData={this.state.individualDedupData}
                            jointDedupData={this.state.jointDedupData}
                            companyDedupData={this.state.companyDedupData}
                            searchValue={this.state.getsearchValue}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.cbNotFound}>
                    <DialogContent className={classes.dialogPaper}>

                        <CBNotFound
                            closeModal={this.closeModal}
                            getAccountType={this.state.getAccountType}
                            accountType={this.state.tabMenuSelect}
                            serviceType="Account Opening"
                            subServiceType={this.state.content}
                            searchValue={this.state.getsearchValue}

                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>


                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>

                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>
                                <paper>
                                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                        <h5>Locker Account Creation</h5>
                                    </CardHeader>
                                </paper>
                                <CardHeader color="red">
                                    <h6 color="red" className={classes.cardTitleWhite}>
                                        <Paper>
                                            <Tabs
                                                indicatorColor="secondary"
                                                textColor="secondary"
                                                aria-label="icon label tabs example"
                                                value={this.state.tabMenuSelect}
                                                onChange={this.handleChange}
                                            >
                                                <Tab
                                                    classes={{wrapper: classes.Tab}}
                                                    value="INDIVIDUAL"
                                                    label={<div>
                                                        <a>INDIVIDUAL A/C </a><br/>
                                                        <a style={{color: "#999999"}}> {this.state.individualLabel + "    "} </a>
                                                    </div>}
                                                    onClick={this.handleClickIndividual}
                                                />
                                            </Tabs>
                                            <Popover
                                                open={this.state.individualDropdownOpen}
                                                anchorEl={this.state.anchorE2}
                                                onClose={this.handleClose}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "center"
                                                }}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}
                                            >
                                                <MenuItem
                                                    onClick={() => this.handleMenuItemClickIndividual("Individual A/C")}>
                                                    Individual A/C
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => this.handleMenuItemClickIndividual("Joint A/C")}>
                                                    Joint A/C
                                                </MenuItem>
                                            </Popover>
                                        </Paper>
                                    </h6>
                                </CardHeader>
                            </div>
                            <Paper square>
                                <CardBody>
                                    <div>
                                        {this.renderSearchForm()}
                                    </div>
                                </CardBody>
                            </Paper>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            {this.searchTableData()}
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>
                                {this.searchTableRelatedData()}
                            </div>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>
                                {this.searchTableSDNData()}
                            </div>
                        </Card>
                    </GridItem>
                </GridContainer>


                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>

                                {this.searchTableNidVerification()}
                            </div>
                        </Card>
                    </GridItem>

                </GridContainer>

            </section>
        )


    }

}


export default withStyles(styles)(LockerDidupSearch);


