import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "../../../Card/Card.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../../Common/Functions';
import Notification from "../../../NotificationMessage/Notification";
import Table from "../../../Table/Table";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Grid from "@material-ui/core/Grid/index";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../../JsonForm/CustomeTheme";
import {Dialog} from "@material-ui/core/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import loader from "../../../../Static/loader.gif";
import Label from "./Label";
import SignatureButton from "../SignatureButton";
import PreviewMappingImage from "../casa/PreviewMappingImage";
import GridList from "@material-ui/core/GridList";
import MyValidation from "../../../JsonForm/MyValidation";
import CBNotFound from "../../CASA/CBNotFound";
import CheckList from "./CheckList";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButton";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};
let bomRemarks = [
    {
        "varName": "bomRemarks",
        "type": "textArea",
        "label": "BOM Remarks",
        "required": true,
        "grid": 12
    }]
;

let bearer = [
    {
        "type": "empty",
        "grid": 12
    },
    {
        "varName": "csBearer",
        "type": "select",
        "label": "Bearer Status/Customer Visited",
        "readOnly": true,
        "enum": [
            "YES",
            "NO"
        ],
        "grid": 6
    },
    {
        "varName": "bearerApproval",
        "type": "select",
        "label": "Bearer Approval",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "csBearer",
        "conditionalVarValue": "YES",
        "enum": [
            "BM Approval",
            "Call Center Approval",
            "BM & Call Center Approval"

        ]
    }
];

class VerifyDocumentBOM2 extends React.Component {
    state = {
        message: "",
        appData: {},
        getData: false,
        varValue: [],
        redirectLogin: false,
        title: "",
        notificationMessage: "",
        alert: false,
        getDeferalList: [],
        inputData: {},
        getImageLink: [],
        getImageBoolean: false,
        imageModalBoolean: false,
        selectImage: "",
        err: false,
        errorArray: {},
        errorMessages: {},
        getRemarks: [],
        getMappingAllImage: false,
        loading: true,
        labelData: [],
        city: [],
        state: [],
        country: [],
        objectForInitialNewNominee: [],
        cbNotFound: false,
        disabled: false,
        customerDetails: {},
        selectedDate: {},
        checkList: false,
        confirmationAlert: false,
        triggerButton: "",
        linkedCB: false
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        }

    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined" || clone[prop] === "-1")
                delete clone[prop];
        return clone;
    };

    componentDidMount() {
        var remarksArray = [];
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let highlightUrl = backEndServerURL + "/case/fields/updatedByCS/" + this.props.appId;
                    axios.get(highlightUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            let fieldVarName = response.data;
                            console.log(fieldVarName);
                            let jsonForm = this.props.jsonForm;
                            let highLightArray = [];
                            for (let i = 0; i < fieldVarName.length; i++) {
                                let obj = jsonForm.find(item => item.varName === fieldVarName[i]);
                                if (obj !== undefined) {
                                    highLightArray.push(obj)
                                }
                            }
                            for (let i = 0; i < highLightArray.length; i++) {
                                highLightArray[i].highlight = true
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    this.setState({
                        linkedCB: response.data.freeFlag2 === "linkedCB"
                    });
                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    console.log(response.data);
                                    this.setState({
                                        getImageLink: response.data,
                                        getImageBoolean: true
                                    })
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                            console.log(response.data);
                            let tableArray = [];
                            var status = "";
                            response.data.map((deferal) => {
                                if (deferal.status === "ACTIVE") {
                                    status = "Approved By BM"
                                }
                                tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));

                            });
                            this.setState({
                                getDeferalList: tableArray
                            });
                            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {

                                    console.log(response.data);
                                    response.data.map((data) => {

                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                    });
                                    this.setState({
                                        getRemarks: remarksArray
                                    })
                                })
                                .catch((error) => {
                                    console.log(error)
                                })


                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    this.setState({
                        varValue: this.removeNullValue(response.data),
                        inputData: this.removeNullValue(response.data),
                        getData: true,
                        showValue: true,
                        appData: response.data,
                        // loading: false
                    }, this.stopLoadingForLinkedCB);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

    }

    stopLoadingForLinkedCB = () => {
        if (this.state.inputData.showLabel) {
            this.setState({
                loading: false
            })
        }
    };
    stopLoading = (value) => {
        if (value === "yes") {
            this.setState({
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    renderExistingNomineeForm = () => {
        if (this.state.getData && this.state.inputData.nomineeChange) {

            var sl;
            let objectForExistingNominee = [];
            for (var i = 0; i < this.state.inputData.nomineeNumberOld; i++) {
                sl = i + 1;
                objectForExistingNominee.push(
                    {
                        "varName": "Nominee " + i,
                        "type": "title",
                        "label": "Nominee  " + sl,
                        "grid": 12,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeName" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeRelationship" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": [],
                        "label": "Relationship",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeDob" + sl,
                        "type": "date",
                        "label": "D.O.B",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "percentageNominee" + sl,
                        "type": "text",
                        "label": "Percentage %",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeAddress1" + sl,
                        "type": "text",
                        "label": "Address Field 1",
                        "grid": 6,
                        "readOnly": true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeAddress2" + sl,
                        "type": "text",
                        "label": "Address Field 2",
                        "grid": 6,
                        "readOnly": true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "label": "City",
                        "grid": 6,
                        "readOnly": true,
                        "enumType": "city",
                        "enum": [],
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "label": "State",
                        "grid": 6,
                        "readOnly": true,
                        "enumType": "state",
                        "enum": [],
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineePostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        "readOnly": true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeCountry" + sl,
                        "type": "autoCompleteValueReturn",
                        "label": "Country",
                        "grid": 6,
                        "readOnly": true,
                        "enumType": "country",
                        "enum": [],
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "photoIdNumberRegNo" + sl,
                        "type": "text",
                        "label": "Photo Id No/Registration No.",
                        "grid": 6,
                        "readOnly": true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeMinor" + sl,
                        "type": "text",
                        "label": "Nominee Minor",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "type": "title",
                        "label": "Guardian Details  " + sl,
                        "grid": 12,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },
                    {
                        "varName": "nomineeGuardiansName" + sl,
                        "type": "text",
                        "label": "Guardian's name",
                        "grid": 6,
                        "readOnly": true,
                        "multiline": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },
                    {
                        "varName": "nomineeGuardianCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "guardianCode",
                        "enum": [],
                        "label": "Guardian Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },
                    {
                        "varName": "guardianPhotoId" + sl,
                        "type": "text",
                        "label": "Guardian Photo Id",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },

                    {
                        "varName": "nomineeGuardianAddress" + sl,
                        "type": "text",
                        "label": "Address 1",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },
                    {
                        "varName": "nomineeGuardianAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },

                    {
                        "varName": "nomineeGuardianCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "city",
                        "enum": [],
                        "readOnly": true,
                        "label": "City Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },

                    {
                        "varName": "nomineeGuardianStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "state",
                        "enum": [],
                        "label": "State Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },

                    {
                        "varName": "nomineeGuardianPostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },

                    {
                        "varName": "nomineeGuardianCountry2" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "country",
                        "enum": [],
                        "readOnly": true,
                        "label": "Country",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },
                )

            }


            return (
                CommonJsonFormComponent.renderJsonForm(this.state, objectForExistingNominee, this.updateComponent)
            )

        }
    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    renderDefferalData = () => {


        if (this.state.getDeferalList.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Deferral List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    };

    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                confirmationAlert: false,
                disabled: true,
                loading: true,
            });

            if (this.state.triggerButton === "RETURN") {
                let postData = {...this.state.inputData};
                postData.bom_maintenance_approval = "RETURN";
                this.caseRouteApi(postData)
            } else {
                let postData = {...this.state.inputData};
                if (this.state.triggerButton === "APPROVED") {
                    if ('digitTinChange' in postData) {
                        delete postData.digitTinChange
                    }
                    if ('nidChange' in postData) {
                        delete postData.nidChange
                    }
                    if ('nidOrsmartcardChange' in postData) {
                        delete postData.nidOrsmartcardChange
                    }
                    postData.mailingContactNumberChange = false;
                    postData.emailAddressChange = false;
                    postData.currencyAdd = false;
                }
                postData.bom_maintenance_approval = this.state.triggerButton;
                let accountUpdate = new Promise((resolve, reject) => {
                    if (this.state.triggerButton === "APPROVED" && (this.state.varValue.digitTinChange || this.state.varValue.nidChange || this.state.varValue.nidOrsmartcardChange || this.state.varValue.mailingContactNumberChange || this.state.varValue.emailAddressChange || this.state.varValue.currencyAdd)) {
                        var variableSetUrl = backEndServerURL + "/casaStaticMaintenanceUpdate/" + this.props.appId;
                        axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                if (response.status === 200) {
                                    let sendSmsUrl = backEndServerURL + "/accountMaintenance/sendSms/" + this.state.inputData.accountNumber;
                                    let postData = {};
                                    postData.digitTinChange = this.state.inputData.digitTinChange;
                                    postData.nidChange = this.state.inputData.nidChange;
                                    postData.nidOrsmartcardChange = this.state.inputData.nidOrsmartcardChange;
                                    postData.mailingContactNumberChange = this.state.inputData.mailingContactNumberChange;
                                    postData.emailAddressChange = this.state.inputData.emailAddressChange;
                                    postData.currencyAdd = this.state.inputData.currencyAdd;
                                    postData.photoIdChange = this.state.inputData.photoIdChange;
                                    postData.otherContactNumberChange = this.state.inputData.otherContactNumberChange;
                                    postData.titleChange = this.state.inputData.titleChange;
                                    postData.nomineeChange = this.state.inputData.nomineeChange;
                                    postData.addressChange = this.state.inputData.addressChange;
                                    postData.otherInformationChange = this.state.inputData.otherInformationChange;
                                    postData.signatureCard = this.state.inputData.signatureCard;
                                    postData.onlyDormantAccountActivation = this.state.inputData.onlyDormantAccountActivation;
                                    postData.dormantAccountActivation = this.state.inputData.dormantAccountActivation;
                                    postData.projectRelatedDataUpdateADUP = this.state.inputData.projectRelatedDataUpdateADUP;
                                    postData.mobileNumber = this.state.inputData.existingPhoneNumber;
                                    axios.post(sendSmsUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response) => {
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                    resolve("Call Case Route API")
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                                reject(error);
                            });
                    } else {
                        resolve("Call Case Route API")
                    }
                });

                accountUpdate.then((value) => {
                    if (value === "Call Case Route API") {
                        this.caseRouteApi(postData);
                    }
                }).catch((value) => {
                    if (value) {
                        this.setState({
                            cbNotFound: true,
                            title: value.response.data.message,
                            disabled: false,
                            loading: false
                        });
                    }
                });
            }
        } else {
            this.setState({
                confirmationAlert: false
            })
        }
    };

    handleSubmit = (event, data) => {
        event.preventDefault();
        this.state.triggerButton = data;
        if (data === "RETURN") {
            let error = MyValidation.defaultValidation(bomRemarks, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
        }
        this.setState({
            title: "Do you want to confirm?",
            confirmationAlert: true
        })
    };


    caseRouteApi = (postData) => {
        let remarksData = {};
        remarksData.remark = this.state.inputData.bomRemarks;
        remarksData.map = {...postData, bomRemarks: undefined};
        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            this.setState({
                title: "Successful!",
                notificationMessage: "Successfully Routed!",
                alert: true,
                disabled: false,
                loading: false
            });
            this.props.closeModal();
        }).catch((error) => {
            console.log(error);
            this.setState({
                disabled: false,
                loading: false
            })
        });
    };

    renderEditForm = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.jsonForm, this.updateComponent)
            )
        }
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };

    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <center>
                    <Grid item xs={12}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',

                            }}
                            disabled={this.state.disabled}
                            type='button' value='add more'

                            onClick={(event) => this.handleSubmit(event, "APPROVED")}
                        >Approve
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',

                            }}
                            disabled={this.state.disabled}
                            type='button' value='add more'
                            onClick={(event) => this.handleSubmit(event, "RETURN")}

                        >Return
                        </button>
                    </Grid>
                </center>

            )
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (

                CommonJsonFormComponent.renderJsonForm(this.state, bomRemarks, this.updateComponent)

            )
        }
    };

    viewAttachedImages = () => {
        const {classes} = this.props;
        if (this.state.getData && this.state.inputData.previewImage) {
            return <><br/><PreviewMappingImage appId={this.props.appId} classes={classes}/></>
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
        })
    };

    renderLabelAndSignature = () => {
        if (this.state.getData) {
            return (
                <>
                    <Label stopLoading={this.stopLoading}
                           linkedCB={this.state.linkedCB}
                           accountNumber={this.state.inputData.accountNumber}
                           style={{margin: "0 auto"}} classes={this.props}
                           cbNumber={this.state.inputData.cbNumber} crmStatus={this.state.inputData.crmStatus}/>
                    <Grid item xs={12}>
                        <br/>
                    </Grid>
                    {this.renderSignature()}
                    &nbsp;
                </>
            )
        } else {
            return this.renderSignature()
        }
    };

    renderSignature = () => {
        if (this.state.getData) {
            return <SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
                                    classes={this.props}/>
        }
    };

    renderBearerForm = () => {
        if (!this.state.loading) {
            return CommonJsonFormComponent.renderJsonForm(this.state, bearer, this.updateComponent)

        }
    };

    viewCheckList = () => {
        if (this.state.getData && this.state.inputData.titleChange) {
            return (
                <div>
                    <br/>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={this.openCheckList}
                    >View Check List
                    </button>
                </div>
            )
        }
    };

    openCheckList = () => {
        this.setState({
            checkList: true
        })
    };

    checkListClose = () => {
        this.setState({
            checkList: false
        })
    };

    renderFlowSummeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                   customerName={this.state.inputData.customerName}
                                   accountNumber={this.state.inputData.accountNumber}
                                   solId={this.state.inputData.freeFlag3}/>
            )
        }
    };

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Card>
                        <CardBody>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.loading}>
                                <DialogContent className={classes.dialogPaper}>
                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="md"
                                open={this.state.checkList}>
                                <DialogContent>
                                    <CheckList closeModal={this.checkListClose} inputData={this.state.inputData}/>
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.confirmationAlert}>
                                <DialogContent className={classes.dialogPaper}>
                                    <ConfirmationModal
                                        closeModal={this.closeConfirmation}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <ThemeProvider theme={theme}>
                                <div>
                                    <Grid container spacing={0}>
                                        {this.renderLabelAndSignature()}
                                        {this.renderEditForm()}
                                        {this.renderExistingNomineeForm()}
                                        {this.renderBearerForm()}
                                        <Grid item xs={12}>
                                            <br/>
                                            {this.renderDefferalData()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.renderRemarksData()}
                                        </Grid>
                                        {this.viewAttachedImages()}
                                        <Grid item xs={12}>
                                            <br/>
                                            {this.renderFlowSummeryButton()}
                                        </Grid>
                                        {this.viewCheckList()}
                                        {this.renderNotification()}
                                        {this.renderRemarks()}
                                        <Grid item xs={12}>
                                            <br/>
                                        </Grid>
                                        {this.renderSubmitButton()}
                                    </Grid>
                                </div>
                            </ThemeProvider>
                        </CardBody>
                    </Card>
                </div>
            </GridList>
        )
    }
}

export default withStyles(styles)(VerifyDocumentBOM2);
