import React, {Component} from "react";
import Grid from "@material-ui/core/Grid/index";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Card from "../../../Card/Card.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import axios from "axios/index";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import Notification from "../../../NotificationMessage/Notification";
import Table from "../../../Table/Table";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import MyValidation from "../../../JsonForm/MyValidation";
import {InterestTransferReadOnlyForm} from "./InterestTransferJsonForm"
import CardHeader from "../../../Card/CardHeader";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmAlert from "../../CommonComponent/ConfirmAlert";
import CommonApi from "../../Maintenance/common/CommonApi";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";
import DynamicFileAttachment from "../Common/DynamicFileAttachment";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let remarks = [
    {
        "varName": "makerRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12,
    }];

class MakerApproval extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: false,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            appId: 0,
            getMappingAllImage: false,
            fileUploadData: {},
            getImageLink: [],
            getImageBoolean: false,
            imageName: [],
            getData: false,
            getRemarks: [],
            loading: true,
            confirmAlert: false,
            actionType: "",
            submitButtonClick: false,
            passingData: "",

        };
    }

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    componentDidMount() {
        CommonApi.getVariablesNew(this.props.appId, assetOpsBackEndServerURL).then(response => {
            let inputData = {...response.data};
            inputData.makerRemarks = undefined;
            CommonApi.getFilesNew(this.props.appId, assetOpsBackEndServerURL).then((fileResponse) => {
                CommonApi.getRemarkDetailsNew(this.props.appId, assetOpsBackEndServerURL).then((remarksArray) => {
                    this.setState({
                        varValue: this.copyJson(inputData),
                        inputData: inputData,
                        currentTask: response.data.currentTask,
                        getImageLink: fileResponse,
                        getImageBoolean: true,
                        imageName: fileResponse,
                        getRemarks: remarksArray,
                        getData: true,
                        appId: this.props.appId,
                        showValue: true,
                        loading: false,
                    })
                }).catch((error) => {
                    console.log(error)
                });
            }).catch((error) => {
                console.log(error);
            });
        }).catch(error => {
            console.log(error);
        });
    }

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type="success"
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        });
    };

    functionFoFile = (status) => {
        this.setState({
            loading:true,
            submitButtonClick: false,

        })
        if (status === "success" || status === "No File Found") {

            let saveDataDeferralRemarksAndRoute = {};
            if (this.state.inputData.makerRemarks !== undefined && this.state.inputData.makerRemarks !== null) {
                saveDataDeferralRemarksAndRoute.remark = this.state.inputData.makerRemarks;
            }
            saveDataDeferralRemarksAndRoute.map = {
                ...(this.state.inputData.request_type === "interest_transfer_request" || this.state.inputData.request_type === "security_replacement" || this.state.inputData.request_type === "partial_security_release" || this.state.inputData.request_type === "eftn_account_change") && {closing_maker_approved: this.state.passingData},
                ...(this.state.inputData.request_type === "interest_rate_change" || this.state.inputData.request_type === "link_account_change") && {approved: this.state.passingData},
                ...(this.state.inputData.request_type === "duplicate_noc") && {noc_maker_approved: this.state.passingData},
                ...(this.state.inputData.request_type === "duplicate_sanctioned_letter") && {document_maker_approved: this.state.passingData}

            };
            let caseRouteUrl = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
            axios
                .post(caseRouteUrl, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    this.setState({
                        loading: false,
                        alert: true,
                        title: "Successful!",
                        notificationMessage: response.data,
                    });
                    this.props.closeModal()

                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        loading: false,
                    })
                });
        } else {
            this.setState({
                errorModal: true,
                loading: false,
                title: "Failed to Upload File."
            })
        }
    };

    handleSubmit = (data) => {
        this.setState({
            passingData: data,
            submitButtonClick: true
        });
    };
    returnHandler = () => {
        this.setState({
            loading:true,
        })
        let saveDataDeferralRemarksAndRoute = {};
        if (this.state.inputData.makerRemarks !== undefined && this.state.inputData.makerRemarks !== null) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.makerRemarks;
        }
        const returnPostData = {
            ...(this.state.inputData.request_type === "interest_transfer_request" || this.state.inputData.request_type === "security_replacement" || this.state.inputData.request_type === "partial_security_release" || this.state.inputData.request_type === "eftn_account_change") && {closing_maker_approved: "NO"},
            ...(this.state.inputData.request_type === "interest_rate_change" || this.state.inputData.request_type === "link_account_change") && {approved: "NO"},
            ...(this.state.inputData.request_type === "duplicate_noc" ) && {noc_maker_approved : "NO"},
            ...(this.state.inputData.request_type === "duplicate_sanctioned_letter" ) && {document_maker_approved: "NO"}
        };
        saveDataDeferralRemarksAndRoute.map = returnPostData;
        let caseRouteUrl = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios
            .post(caseRouteUrl, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                this.setState({
                    loading:false,
                    alert: true,
                    title: "Successful!",
                    notificationMessage: response.data,
                });
                this.props.closeModal()
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading:false,
                })
            });


    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, remarks, this.updateComponent)
    };


    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{width: "100%"}}>
                    <br/>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>

                </div>

            )
        }

    };

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <a
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: "middle",
                                display: "block",
                                marginTop: 20,
                            }}
                            target="_blank"
                            href={assetOpsBackEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                        >
                            {splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </a>
                        &nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };
    closeConfirmAlert = (data, appId) => {
        this.setState({
            confirmAlert: false,
        });

        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "approveHandler") {

            this.handleSubmit("YES")
        } else if (data === "YES" && this.state.actionType === "returnHandler") {

            this.handleSubmit("NO")
        }
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        return clone;
        /* for (let prop in clone)
             if (clone[prop] === '' || clone[prop] === ' '  || clone[prop] === "null"   ||  clone[prop] === "undefined" )
                 delete clone[prop];
         return clone;*/
    };
    validationForHandleConfirm = (actionType) => {
        let error = MyValidation.defaultValidation(remarks, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }
        this.setState({
            appId: this.state.appId,
            confirmAlert: false,
        });
        if (actionType === "approveHandler") {
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })


        } else if (actionType === "returnHandler") {
            let dependencyField = [];
            dependencyField.push({
                "varName": "makerRemarks",
                "type": "textArea",
                "required": true,
                "label": "Remarks",
                "grid": 12
            })
            let error = MyValidation.defaultValidation(dependencyField, this.state);
            this.forceUpdate();

            if (error === true) {
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })


        }


    };
    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()

        {
            this.validationForHandleConfirm(type)
        }


    };
    renderSubmitButton = () => {
        if (this.state.getData && this.state.loading === false) {
            return (
                <React.Fragment>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            marginRight: "10px",
                        }}
                        // onClick={this.approveHandler}
                        onClick={(event) => this.handleCommon(event, "approveHandler")}

                    >
                        Submit
                    </button>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        // onClick={this.returnHandler}
                        onClick={(event) => this.handleCommon(event, "returnHandler")}

                    >
                        Return
                    </button>
                </React.Fragment>
            )
        }
    }
    renderJsonForm=()=>{
        if(this.state.getData){
            return(
                CommonJsonFormComponent.renderJsonForm(this.state, InterestTransferReadOnlyForm, this.updateComponent)

            )
        }
}
    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b>Other Service Request</b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.state.inputData.customerName !== undefined ? this.state.inputData.customerName : ""}
                    <b> A/C:</b>{this.state.inputData.accountNumber !== undefined ? this.state.inputData.accountNumber : ""}
                    <b> CB Number:</b>{this.state.inputData.cbNumber !== undefined ? this.state.inputData.cbNumber : ""}
                    <b> SOLID:</b>{this.props.solId !== undefined ? this.props.solId : ""}  <b> Case
                        ID:</b>{this.props.caseId !== undefined ? this.props.caseId  : ""} )<a><CloseIcon
                        onClick={this.props.closeModal} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Other Service Request
                </h4>
            )
        }
    };
    renderFlowSUmmeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                   caseId={this.props.caseId}
                                   customerName={this.state.inputData.customerName} accountNumber={this.state.inputData.accountNumber}
                                   serviceType={this.state.inputData.serviceType}
                                   category={this.state.inputData.category}
                                   subCategory={this.state.inputData.subCategory}
                                   solId={this.props.solId}/>
            )
        }
    };
    renderDynamicFileUpload = () => {
        if (!this.state.loading) {
            return <DynamicFileAttachment appId={this.state.appId}
                                          submitButtonClick={this.state.submitButtonClick}
                                          functionFoFile={this.functionFoFile}/>
        }
    };


    render() {
        const {classes, onClose} = this.props;

            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            {this.renderHeader()}
                        </CardHeader>
                        <CardBody>
                            <Grid container spacing={0}>
                                <ThemeProvider theme={theme}>
                                    {this.renderJsonForm()}
                                    <Grid item xs={12}></Grid>
                                    {this.mappingPhoto()}
                                    {this.state.getImageBoolean && <Grid item xs={12}></Grid>}
                                    {this.renderDynamicFileUpload()}
                                </ThemeProvider>
                                <Grid container spacing={0}>
                                    <ThemeProvider theme={theme}>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.loading}>
                                            <DialogContent className={classes.dialogPaper}>

                                                <center>
                                                    <img src={loader} alt=""/>
                                                </center>
                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.confirmAlert}>
                                            <DialogContent className={classes.dialogPaper}>
                                                <ConfirmAlert validationForHandleConfirm={this.validationForHandleConfirm}
                                                              closeModal={this.closeConfirmAlert}


                                                />

                                            </DialogContent>
                                        </Dialog>
                                        {
                                            this.renderRemarksData()
                                        }
                                        <Grid item xs={12}></Grid>
                                        {this.renderFlowSUmmeryButton()}
                                        {this.renderRemarks()}
                                    </ThemeProvider>
                                </Grid>
                            </Grid>

                            <Grid item xs="3" style={{marginTop: "20px"}}>
                                {/*<center>*/}
                                {this.renderSubmitButton()}
                            </Grid>
                            {
                                this.renderNotification()
                            }
                        </CardBody>
                    </Card>
                </div>
            );

    }

}

export default withStyles(styles)(MakerApproval);
