import React from "react";
import Functions from "../../../Common/Functions";
import Table from "../../Table/Table";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../../Card/CardHeader";
import {Dialog, MuiThemeProvider} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Card from "../../Card/Card";
import withStyles from "@material-ui/core/styles/withStyles";
import Fab from '@material-ui/core/Fab';
import Pageview from '@material-ui/icons/Pageview';
import MaterialTable from "material-table";
import {buttonStyle, localization, options, tableIcons} from "../configuration";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material/react-button";
import {createMuiTheme} from "@material-ui/core/styles";
import CardBody from "../../Card/CardBody";
import TableComponent from "../../workflow/CommonComponent/TableComponent";
import CloseIcon from "@material-ui/icons/Close";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};
let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '5px',
            }
        },
    }
});


class TableInModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            getData: false,
            redirectLogin: false,
            title: "",
            notificationMessage: "",
            alert: false,
            inputData: {},
            selectedDate: {},
            loading: true,
            rowsPerPage: 100,
        };
    }

    componentDidMount() {
        this.setState({
            loading: false,
            getData: true,
        })
    }


    updateComponent = () => {
        this.forceUpdate();
    };
    tableChoice() {
        console.log(this.props.rowLength);

    if (this.state.getData && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.props.columns}
                                data={this.props.allData}
                                title={""}
                                options={{...options,paging: false,selection: false}}
                                localization={localization}
                                icons={tableIcons}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        }
    }

    handleClose = (event) => {
        event.preventDefault();
        this.props.closeModal()
    }

    render() {
        const {classes} = this.props;
        {Functions.redirectToLogin(this.state)}
        console.log("Props" + this.props);
        return (
            <div>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >{this.props.title}<a><CloseIcon
                                    onClick={(event) => this.handleClose(event)}
                                    style={{
                                        position: 'absolute',
                                        right: 10,
                                        color: "#000000"
                                    }}/></a></h4>
                            </CardHeader>
                            <CardBody>
                                    {this.tableChoice()}
                            </CardBody>
                        </Card>
            </div>
        )
    }
}

export default withStyles(styles)(TableInModal);
