import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import TextFieldComponent from "../../../JsonForm/TextFieldComponent";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import SelectComponent from "../../../JsonForm/SelectComponent";
import Table from "../../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import Notification from "../../../NotificationMessage/Notification";
import loader from "../../../../Static/loader.gif";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import MyValidation from "../../../JsonForm/MyValidation";
import SignatureButton from "../SignatureButton";
import UploadComponent from "../casa/UploadComponent";
import CBNotFound from "../../CASA/CBNotFound";
import DateComponentDeferal from "../../../JsonForm/DateComponentDeferal";
import GridContainer from "../../../Grid/GridContainer";
import CheckBox from "@material-ui/core/Checkbox/Checkbox";
import {csBearer, maintenanceFirst} from "./JsonForCasaMaintenance"
import Label from "./Label";
import MaintenanceUtility from "./MaintenanceUtility";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButton";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};
var deferalOther = {
    "varName": "deferalOther",
    "type": "text",
    "label": "Others",
};
var deferal = {
    "varName": "deferalType",
    "type": "select",
    "label": "Deferral Type",
    "enum": [
        "Applicant Photograph",
        "Nominee Photograph",
        "Passport",
        "Address proof",
        "Transaction profile",
        "Others"
    ],

};
var date = {
    "varName": "expireDate",
    "type": "text",
    "minDate": true,
    "label": "Expire Date",

};

const maintenanceRequestType = [
    {title: 'E Tin Update'},
    {title: 'NID Card Number update'},
    {title: 'Smart Card Number update'},
    {title: 'Designated Contact Number Change'},
    {title: 'Email Address Update'},
    {title: 'Address Change'},
    {title: 'Other Photo ID Change'},
    {title: 'Other Contact Number Change'},
    {title: 'Other Information Change'},
];

let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];

class LinkedAccountMaintenance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            loader: null,
            imageLink: {},
            tableData: [],
            cbNotFound: false,
            routeCase: false,
            values: [],
            appId: '',
            loading: true,
            getData: false,
            getNewCase: false,
            caseId: "",
            title: "",
            notificationMessage: "",
            alert: false,
            redirectLogin: false,
            selectedDate: {},
            showValue: false,
            getDeferalList: [],
            deferalNeeded: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            returnData: false,
            returnDataBm: false,
            getNumberofNominee: false,
            objectForNominee: [],
            objectForExistingNominee: [],
            getNominee: false,
            getExistingNominee: false,
            numberOfMultipleSelect: 0,
            labelData: [],
            fileUploadData: [],
            objectForInitialNewNominee: [],
            getInitialNewNominee: 0,
            disabled: false,
            city: [],
            state: [],
            country: [],
            guardianCode: [],
            relationCode: [],
            currency: [],
            masterDataTitle: [],
            phoneNumCountryCodeList: [],
            emailType: [],
            maritalStatus: [],
            selectedRequest: [],
            occupationCodeList: [],
            employmentType: [],
            constitutionList: []
        }
    }

    getSearchvalue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === undefined || clone[prop] === null)
                delete clone[prop];
        return clone;
    };

    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;

        if (value && (typeof value !== "string")) {
            return value
        } else {
            for (let i = 0; i < jsonArray.length; i++) {
                let jsonObject = jsonArray[i];
                if (value === jsonObject.key) {
                    return jsonObject;
                }
            }
        }
    };

    addDeferalForm() {


        return this.state.values.map((el, i) =>
            <React.Fragment>
                <Grid item xs={3}>
                    {
                        this.dynamicDeferral(el)
                    }
                </Grid>

                <Grid item xs={3}>
                    {
                        this.dynamicDate(el)
                    }

                </Grid>
                <Grid item xs={3}>
                    {this.dynamicDeferralOther(el)}

                </Grid>
                <Grid item xs={3}>
                    <button
                        style={{
                            float: "left",
                            marginTop: "15px"
                        }}

                        className="btn btn-outline-primary btn-sm"
                        type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                    >
                        Remove
                    </button>


                </Grid>
            </React.Fragment>
        )

    }

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    componentDidMount() {
        if (this.state.inputData["csBearer"] === "NO") {
            this.state.inputData["csBearer"] = "";
        }
        let occupationCodeUrl = backEndServerURL + "/occupationCode/getAll";
        axios.get(occupationCodeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.findByVarNameApiData("occupationCodeCB", response.data);
                this.findByVarNameApiData("occupationCodeAccount", response.data);
                this.setState({
                    occupationCodeList: response.data
                })
            })
            .catch((error) => {
                console.log(error);
            });

        this.dynamicApiCallForNomineeUpdate("emailType", "emailType");
        this.dynamicApiCallForNomineeUpdate("city", "city");
        this.dynamicApiCallForNomineeUpdate("state", "state");
        this.dynamicApiCallForNomineeUpdate("country", "country");
        this.dynamicApiCallForNomineeUpdate("guardianCode", "guardianCode");
        this.dynamicApiCallForNomineeUpdate("relationCode", "relationCode");
        this.dynamicApiCallForNomineeUpdate("maritalStatus", "maritalStatus");
        this.dynamicApiCallForNomineeUpdate("currency", "currency");
        this.dynamicApiCallForNomineeUpdate("title", "title");
        this.dynamicApiCallForNomineeUpdate("phoneCountryCode", "phoneCountryCode");
        this.dynamicApiCallForNomineeUpdate("constitution", "constitution");
        this.dynamicApiCallForNomineeUpdate("employmentType", "employmentType");
        if (this.props.appId !== undefined) {

            let url = backEndServerURL + '/variables/' + this.props.appId;

            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    let remarksArray = [];

                    let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                    axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            if (response.data.length > 0) {
                                this.setState({
                                    fileUploadData: response.data,
                                })
                            }

                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            let tableArray = [];
                            var status = "";
                            response.data.map((deferal) => {
                                if (deferal.status === "ACTIVE") {
                                    status = "Approved"
                                } else if (deferal.status === "NOTAPPROVED") {
                                    status = "NOT APPROVED"
                                } else {
                                    status = deferal.status;
                                }
                                tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));
                            });
                            this.setState({
                                getDeferalList: tableArray
                            });

                            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {


                                    response.data.map((data) => {

                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                    });
                                    this.setState({
                                        getRemarks: remarksArray
                                    })
                                })
                                .catch((error) => {
                                    console.log(error)
                                })


                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    this.state.inputData["csDeferal"] = "NO";


                    let varValue = response.data;
                    this.setState({
                        getData: true,
                        varValue: this.getSearchvalue(varValue),
                        appData: response.data,
                        numberOfMultipleSelect: response.data.numberOfMultipleSelect,
                        inputData: this.getSearchvalue(response.data),
                        showValue: true,
                        appId: this.props.appId,
                        searchData: true,
                        loading: false

                    });

                })
                .catch((error) => {
                    console.log(error);

                });
        } else {
            this.setState({
                loader: true,
                loading: true,
            });
            let getDataByCB = backEndServerURL + "/cbsCustomerGet/" + this.props.match.params.cbNumber;
            axios.get(getDataByCB, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        let inputData = response.data;
                        inputData.emailTypeOne = {
                            "masterDataName": "emailType",
                            "key": "COMMEML",
                            "value": "COMMEML"
                        };
                        inputData.emailTypeTwo = {
                            "masterDataName": "emailType",
                            "key": "HOMEEML",
                            "value": "HOMEEML"
                        };
                        if (response.data.PASSPORTBLOCK !== undefined) {
                            inputData.passportBlockExist = true;
                            inputData.passportNumberpassportNumberUniqueId = response.data.PASSPORTBLOCK.referenceNum;
                            let passportNumberissueDate = response.data.PASSPORTBLOCK.issueDt;
                            if (passportNumberissueDate && passportNumberissueDate !== -1) {
                                let date = (passportNumberissueDate).split('T')[0].split("-");
                                inputData.passportNumberissueDate = `${date[1]}/${date[2]}/${date[0]}`
                            }
                            let passExpDate = response.data.PASSPORTBLOCK.expireDt;
                            if (passExpDate && passExpDate !== -1) {
                                let date = (passExpDate).split('T')[0].split("-");
                                inputData.passportExpiryDate = `${date[1]}/${date[2]}/${date[0]}`
                            }
                        }
                        if (response.data.HOMEEMLBLOCK !== undefined) {
                            inputData.existingEmailIdTwo = response.data.HOMEEMLBLOCK.emailInfo;
                        }
                        if (response.data.COMMEMLBLOCK !== undefined) {
                            inputData.existingEmailId = response.data.COMMEMLBLOCK.emailInfo;
                            inputData.emailType = this.findByVarNameGetKeyValue(response.data.COMMEMLBLOCK.phoneEmailType, this.state.emailType);
                        }
                        if (response.data.COMMPH1BLOCK !== undefined) {
                            inputData.mobileType = "Local";
                            inputData.existingPhoneNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                        }
                        if (response.data.NIDBLOCK !== undefined) {
                            inputData.nidCardOld = response.data.NIDBLOCK.referenceNum;
                            inputData.nidCardVerification = response.data.NIDBLOCK.isDocumentVerified;
                            inputData.nidNumberVerification = response.data.NIDBLOCK.isDocumentVerified;
                        }
                        if (response.data.RSNIDBLOCK !== undefined) {
                            inputData.smartcardOld = response.data.RSNIDBLOCK.referenceNum;
                            inputData.smartCardVerification = response.data.RSNIDBLOCK.isDocumentVerified;
                        }
                        if (response.data.ETINBLOCK !== undefined) {
                            inputData.tinUpdateOld = response.data.ETINBLOCK.referenceNum;
                        }
                        if (response.data.FutureOnSiteBLOCK !== undefined) {
                            inputData.presentAddress = response.data.FutureOnSiteBLOCK.addrLine1;
                        }
                        if (response.data.HomeBLOCK !== undefined) {
                            inputData.permanentAddress = response.data.HomeBLOCK.addrLine1;
                        }
                        if (response.data.WorkBLOCK !== undefined) {
                            inputData.officeAddress = response.data.WorkBLOCK.addrLine1;
                        }
                        if (response.data.MailingBLOCK !== undefined) {
                            inputData.communicationAddress = response.data.MailingBLOCK.addrLine1;
                        }
                        // if (response.data.CELLPHBLOCK !== undefined) {
                        //     inputData.existingMobileNumber = response.data.CELLPHBLOCK.phoneNumLocalCode;
                        // }
                        if (response.data.WORKPH1BLOCK !== undefined) {
                            inputData.existingOfficeCountryCode = response.data.WORKPH1BLOCK.phoneNumCountryCode;
                            inputData.existingOfficeNumber = response.data.WORKPH1BLOCK.phoneNumLocalCode;
                        }
                        if (response.data.COMMPH2BLOCK !== undefined) {
                            inputData.existingResidenceNumber = response.data.COMMPH2BLOCK.phoneNumLocalCode;
                            inputData.existingResidenceCountryCode = response.data.COMMPH2BLOCK.phoneNumCountryCode;
                        }
                        if (response.data.RESIDBLOCK !== undefined) {
                            inputData.existingResidentId = response.data.RESIDBLOCK.referenceNum;
                        }
                        if (response.data.isNRE === "Y") {
                            inputData.chairmanCertificatenonResident = "Y"
                        }
                        if (response.data.NRERelativeBLOCK !== undefined) {
                            inputData.nreAddressnreAddressField1 = response.data.NRERelativeBLOCK.addrLine1;
                        }
                        inputData.uploadFileAlertRequest = true;
                        inputData.cbNumber = this.props.match.params.cbNumber;
                        inputData.titleOld = this.findByVarNameGetKeyValue(response.data.salutation, this.state.masterDataTitle);
                        inputData.existingNameOld = response.data.customerName;
                        inputData.existingShortName = response.data.shortName;
                        inputData.gender = response.data.gender;
                        inputData.constitutionCode = this.findByVarNameGetKeyValue(response.data.constitutionCode, this.state.constitutionList);
                        inputData.profession = this.findByVarNameGetKeyValue(response.data.employmentStatus, this.state.employmentType);
                        inputData.occupationCodeCB = response.data.occupationCode;
                        inputData.crmStatus = response.data.custStatus;
                        inputData.customerName = response.data.customerName;
                        inputData.motherAccountSolId = response.data.branchCode;
                        inputData.nidOrsmartcardOld = response.data.NIDNumber;
                        // inputData.existingResidenceNumber = response.data.HOMEPH1;
                        inputData.cityTouchEmailId = response.data.COMMEML;
                        inputData.mobileAccounts = response.data.HOMEPH1;
                        inputData.fatherName = response.data.fatherName;
                        inputData.motherName = response.data.motherName;
                        inputData.maritalStatus = this.findByVarNameGetKeyValue(response.data.maritalStatus, this.state.maritalStatus);
                        inputData.spouseName = response.data.spouseName;
                        inputData.employerName = response.data.nameOfEmployer;
                        let date = response.data.birthDate.split("T")[0].split("-");
                        inputData.dobOld = `${date[1]}/${date[2]}/${date[0]}`;
                        if (response.data.PASSPORTBLOCK !== undefined) {

                            inputData.passportNumberpassportNumberUniqueId = response.data.PASSPORTBLOCK.referenceNum;
                            inputData.passportNumberplaceOfIssue = this.findByVarNameGetKeyValue(response.data.PASSPORTBLOCK.placeOfIssue, this.state.country);
                            let passportNumberissueDate = response.data.PASSPORTBLOCK.issueDt;
                            if (passportNumberissueDate && passportNumberissueDate !== -1) {
                                let date = (passportNumberissueDate).split('T')[0].split("-");
                                inputData.passportNumberissueDate = `${date[1]}/${date[2]}/${date[0]}`
                            }
                            inputData.passportNumbercountryOfIssue = this.findByVarNameGetKeyValue(response.data.PASSPORTBLOCK.countryOfIssue, this.state.country);
                        }


                        if (response.data.BCBLOCK !== undefined) {
                            inputData.birthCertificatebirthCertificateNumberUniqueId = response.data.BCBLOCK.referenceNum;
                        }
                        if (response.data.chairmanCertificate !== undefined) {

                            inputData.chairmanCertificateUniqueId = response.data.chairmanCertificate;
                        }
                        if (response.data.DLBLOCK !== undefined) {
                            inputData.existingDrivingLicense = response.data.DLBLOCK.referenceNum;
                        }
                        inputData.existingCurrency = response.data.currency;

                        this.setState({
                            inputData: inputData,
                            varValue: this.getSearchvalue(inputData)
                        });
                        this.renderCaseStart();
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        }
    }

    renderCaseStart = () => {
        setTimeout(() => {
            let url = backEndServerURL + "/startCase/cs_maintenance";

            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        this.setState({
                            caseId: response.data.caseId,
                            appId: response.data.id,
                            getData: true,
                            showValue: true,
                            loader: false,
                            loading: false
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loader: false,
                        loading: false
                    })
                })

        }, 1000)

    };

    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = maintenanceFirst;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                maintenanceFirst[i].enum = getValue;
            }
        }
    };

    dynamicApiCallForNomineeUpdate = (enumType, apiType) => {
        let apiUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(apiUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    if (enumType === "city") {
                        this.setState({
                            city: response.data
                        })
                    }
                    if (enumType === "maritalStatus") {
                        this.findByVarNameApiData("maritalStatus", response.data);
                        this.setState({
                            maritalStatus: response.data
                        })
                    }
                    if (apiType === "phoneCountryCode") {
                        this.findByVarNameApiData("commPhoneLocalCode", response.data);
                        this.setState({
                            phoneNumCountryCodeList: response.data
                        })
                    }
                    if (enumType === "emailType") {
                        this.setState({
                            emailType: response.data
                        })
                    }
                    if (enumType === "state") {
                        this.setState({
                            state: response.data
                        })
                    }
                    if (enumType === "country") {
                        this.setState({
                            country: response.data
                        })
                    }
                    if (enumType === "guardianCode") {
                        this.setState({
                            guardianCode: response.data
                        })
                    }
                    if (enumType === "relationCode") {
                        this.setState({
                            relationCode: response.data
                        })
                    }
                    if (enumType === "currency") {
                        this.findByVarNameApiData("newCurrency", response.data);
                        this.setState({
                            currency: response.data
                        })
                    }
                    if (enumType === "title") {
                        this.setState({
                            masterDataTitle: response.data
                        })
                    }
                    if (apiType === "constitution") {
                        this.findByVarNameApiData("constitutionCode", response.data);
                        this.setState({
                            constitutionList: response.data
                        })

                    }
                    if (apiType === "employmentType") {
                        this.findByVarNameApiData("profession", response.data);
                        this.setState({
                            employmentType: response.data
                        })
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px", "width": '100%'}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    };

    renderDefferalData = () => {


        if (this.state.getDeferalList.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px", "width": "100%"}}>
                        <b>Deferral List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>

                </div>
            )
        }

    };

    renderAddButtonShow = () => {

        return (
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    width: 100,
                    float: 'left',
                    verticalAlign: 'right',

                }}

                type='button' value='add more'
                onClick={this.addClick.bind(this)}


            >Add Deferral</button>
        )

    };

    dynamicDeferral = (i) => {
        let field = JSON.parse(JSON.stringify(deferal));
        field.varName = "deferalType" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };

    dynamicDeferralOther = (i) => {
        if (this.state.inputData["deferalType" + i] === "Others") {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        } else {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        }
    };

    dynamicDate = (i) => {
        let field = JSON.parse(JSON.stringify(date));
        field.varName = "expireDate" + i;
        return DateComponentDeferal.date(this.state, this.updateComponent, field);
    };

    removeClick(i, event) {
        event.preventDefault();
        let pos = this.state.values.indexOf(i);
        if (pos > -1) {
            this.state.values.splice(pos, 1);
            this.updateComponent();
            if (this.state.values.length > 0) {
                this.state.inputData["csDeferal"] = "YES"
            } else {
                this.state.inputData["csDeferal"] = "NO"
            }
        }


    }

    // renderCustomerDetails = () => {
    //     if (this.props.match.params.acNumber && this.state.getData) {
    //         return (
    //             <Label cbNumber={this.props.match.params.cbNumber} classes={this.props}
    //                    accountNumber={this.props.match.params.acNumber}/>
    //         )
    //     }
    // };


    renderRemarks = () => {
        if (this.state.getData) {

            return (

                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)

            )
        }

    };

    uploadFunction = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return (
                <GridContainer>
                    <Grid item xs={.5}>
                        <CheckBox
                            style={{marginTop: "-6px"}}
                            checked={this.state.inputData.uploadFileAlertRequest}
                            defaultValue={this.state.inputData.uploadFileAlertRequest}
                            onChange={(event) => this.handleChangeuploadFileAlertRequest(event)}/>
                    </Grid>
                    <Grid item xs={11}>
                        <UploadComponent previewImage={this.previewImage} appId={this.state.appId} classes={classes}/>
                    </Grid>
                </GridContainer>
            )
        }
    };

    handleChangeuploadFileAlertRequest = (event) => {
        let data = event.target.checked;
        this.state.inputData.uploadFileAlertRequest = data;
        this.updateComponent();
        if (data === false) {
            this.setState({
                cbNotFound: true,
                title: "Without Document Process!!",
            })
        }
    };

    previewImage = (value) => {
        this.state.inputData["previewImage"] = value === "submit";
        this.forceUpdate();
    };

    addClick() {
        let randomNumber = Math.floor(Math.random() * 100000000000);

        this.setState(prevState => ({
            values: [...prevState.values, randomNumber],

        }));

        this.state.inputData["csDeferal"] = "YES";
    };

    renderMaintenanceRequest = () => {
        if (this.state.getData && !this.state.inputData.freeFlag1) {
            return (
                <React.Fragment>
                    <Grid container spacing={1}>
                        <ThemeProvider theme={theme}>
                            <Grid xs={12}></Grid>
                            <Grid item xs={5}>
                                <Grid item>
                                    <label className="input-label-common">Select Maintenance Request</label>
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        options={maintenanceRequestType}
                                        onChange={(event, option) => this.handleChangeMultipleSelect(event, option)}
                                        getOptionLabel={option => option.title}
                                        size="small"
                                        renderInput={params => (
                                            <TextField

                                                {...params}
                                                variant="outlined" fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </ThemeProvider>
                    </Grid>
                </React.Fragment>
            )
        }
    };

    renderSearchData = () => {
        if (this.state.numberOfMultipleSelect > 0) {
            return (
                <div>
                    <ThemeProvider theme={theme}>
                        <Grid container>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, maintenanceFirst, this.updateComponent, "", this.keyDownForChange)
                            }
                        </Grid>
                    </ThemeProvider>
                </div>
            )
        }
    };

    keyDownForCheck = (event, data) => {

    };

    keyDownForChange = (data) => {
        if (data.varName === "mobileType") {
            this.state.varValue["newPhoneNumber"] = this.state.inputData["newPhoneNumber"];
            this.forceUpdate();
        }
        if (data.varName === "csBearer") {
            if (this.state.inputData["csBearer"] === "YES") {
                this.state.varValue["bearerApproval"] = "BM Approval";
                this.state.inputData["bearerApproval"] = "BM Approval";
                this.state.inputData["showBearerField"] = true;
            } else {
                this.state.varValue["bearerApproval"] = "";
                this.state.inputData["bearerApproval"] = "";
                this.state.inputData["showBearerField"] = false;
            }
            this.forceUpdate();
        }
    };


    commonData = () => {

        if (this.state.numberOfMultipleSelect > 0) {

            return (
                <Grid container spacing={0}>
                    <ThemeProvider theme={theme}>
                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, csBearer, this.updateComponent, this.keyDownForCheck, this.keyDownForChange)
                        }
                        <br/><br/>
                        <Grid item xs='12'>

                        </Grid>
                        {
                            this.renderAddButtonShow()
                        }

                        <Grid item xs='12'>

                        </Grid>
                        {
                            this.addDeferalForm()
                        }
                        <Grid item xs='12'>

                        </Grid>
                        <Grid item xs={12}>
                            {this.renderDefferalData()}
                        </Grid>
                        <Grid item xs={12}>

                            {this.renderRemarksData()}
                        </Grid>
                        <Grid item xs={12}>
                            {this.uploadFunction()}
                        </Grid>
                        <Grid item xs={12}>
                            <br/>
                            {this.renderFlowSummeryButton()}
                        </Grid>
                        {this.renderRemarks()}
                    </ThemeProvider>
                </Grid>
            )
        }

    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    handleSubmitClose = (event) => {
        event.preventDefault();
        let variableSetUrl = backEndServerURL + "/case/close/" + this.state.appId;
        axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Close!",
                        alert: true
                    });
                    this.props.closeModal();
                }
            })
            .catch((error) => {
                console.log(error)
            });

    };

    handleSubmitButton = () => {
        if (this.state.numberOfMultipleSelect > 0) {
            return (
                <ThemeProvider theme={theme}>
                    <Grid>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{}}
                            onClick={this.handleSubmit}
                            disabled={this.state.disabled}
                        >
                            Submit
                        </button>
                        {/*&nbsp;&nbsp;&nbsp;*/}
                        {/*{this.props.taskTitle !== undefined &&*/}
                        {/*<button*/}
                        {/*    className="btn btn-outline-info btn-sm"*/}
                        {/*    disabled={this.state.disabled}*/}
                        {/*    onClick={this.handleSubmitClose}*/}
                        {/*>*/}
                        {/*    Close*/}
                        {/*</button>}*/}
                    </Grid>
                </ThemeProvider>
            )
        }
    };

    handleSubmit = (event) => {
        event.preventDefault();
        let error = MyValidation.defaultValidation(maintenanceFirst.concat(csBearer), this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            if (this.state.inputData.mailingContactNumberChange) {
                if (this.state.inputData.mobileType === "Local") {
                    this.state.inputData.commPhoneLocalCode = "88";
                }
            }
            if (this.state.inputData.uploadFileAlertRequest && (this.state.fileUploadData.length === 0 && this.state.inputData.previewImage === undefined)) {
                this.setState({
                    cbNotFound: true,
                    title: "Please Upload File!"
                })
            } else {
                this.setState({
                    disabled: true
                });
                let imageUrl = backEndServerURL + "/case/files/" + this.state.appId;
                axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        if (this.state.inputData["csDeferal"] === "YES") {
                            let defType = [];
                            let expDate = [];
                            var deferralData = {};
                            let appId = this.state.appId;
                            for (let i = 0; i < this.state.values.length; i++) {
                                let value = this.state.values[i];
                                let deferalType = this.state.inputData["deferalType" + value];
                                if (deferalType === "other") {
                                    deferalType = this.state.inputData["deferalOther" + value];
                                }
                                defType.push(deferalType);
                                let expireDate = this.state.inputData["expireDate" + value];
                                expDate.push(expireDate);

                            }
                            deferralData.appId = appId;
                            deferralData.type = defType;
                            deferralData.dueDate = expDate
                        }

                        let data = this.state.inputData;
                        data.cs_deferal = this.state.inputData["csDeferal"];
                        data.csRemarks = undefined;
                        data.serviceType = "Maintenance";
                        data.subServiceType = "AccountMaintenance";
                        data.category = "CASA";
                        data.freeFlag5 = "Maintenance";
                        data.subCategory = "Maintenance";
                        data.freeFlag6 = "Customer Information Update";
                        data.numberOfMultipleSelect = this.state.numberOfMultipleSelect;
                        data.showLabel = true;
                        if (this.props.appId === undefined) {
                            let requestData = "";
                            for (let item of [...new Set(this.state.selectedRequest)]) {
                                let temp = requestData;
                                requestData = requestData === "" ? item.title : temp + ", " + item.title;
                            }
                            data.freeFlag1 = `${requestData}`;
                            data.accountNumber = this.props.match.params.acNumber;
                        } else {
                            data.accountNumber = this.state.inputData.accountNumber
                        }
                        data.freeFlag2 = "linkedCB";

                        if (this.state.inputData.bearerApproval === "BM Approval") {
                            data.cs_bearer = "bm_approval";
                        } else if (this.state.inputData.bearerApproval === "BM & Call Center Approval") {
                            data.cs_bearer = "both_approval";
                        } else if (this.state.inputData.bearerApproval === "Call Center Approval") {
                            data.cs_bearer = "call_center_approval";
                        } else if ((this.state.inputData.csDeferal === "NO" || this.state.inputData.csDeferal === undefined) && this.state.inputData.csBearer === "NO") {
                            data.cs_deferal = "NO";
                            data.cs_bearer = "NO";
                        }
                        if (this.state.inputData.bearerApproval === "BM Approval" && this.state.inputData.bm_approval === "APPROVE") {
                            data.cs_bearer = "CALL_CENTER";
                        }
                        data.freeFlag4 = data.cs_bearer;

                        let remarksData = {};
                        remarksData.remark = this.state.inputData.csRemarks;
                        remarksData.map = {...data, csRemarks: undefined};
                        remarksData.bulkDeferralDTO = deferralData;
                        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
                        axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                            this.setState({
                                inputData: {},
                                varValue: {},
                                getData: false,
                                title: "Successful!",
                                loading: false,
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                disabled: false
                            });
                            if (this.props.appId !== undefined) {
                                this.props.closeModal();
                            } else {
                                window.close()
                            }
                        }).catch((error) => {
                            console.log(error);
                            this.setState({
                                disabled: false,
                                loading: false
                            })
                        });


                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            disabled: false,
                            loading: false
                        })
                    })
            }
        }

    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false

        })
    };


    close = () => {
        this.props.closeModal();
    };

    handleChangeMultipleSelect = (event, option) => {
        event.preventDefault();
        let selectedData = {...this.state.inputData};
        let selectedArray = option;
        if (selectedArray.length > 0) {
            selectedData.digitTinChange = false;
            selectedData.nidChange = false;
            selectedData.nidOrsmartcardChange = false;
            selectedData.titleChange = false;
            selectedData.nomineeChange = false;
            selectedData.photoIdChange = false;
            selectedData.mailingContactNumberChange = false;
            selectedData.otherContactNumberChange = false;
            selectedData.emailAddressChange = false;
            selectedData.addressChange = false;
            selectedData.otherInformationChange = false;
            selectedData.signatureCard = false;
            selectedData.dormantAccountActivation = false;
            selectedData.projectRelatedDataUpdateADUP = false;
            selectedData.currencyAdd = false;

            for (var i = 0; i < option.length; i++) {
                if (option[i].title === "E Tin Update") {
                    selectedData.digitTinChange = true
                }
                if (option[i].title === "NID Card Number update") {
                    selectedData.nidChange = true
                }
                if (option[i].title === "Smart Card Number update") {
                    selectedData.nidOrsmartcardChange = true
                }
                if (option[i].title === "Other Photo ID Change") {
                    selectedData.photoIdChange = true
                }
                if (option[i].title === "Designated Contact Number Change") {
                    selectedData.mailingContactNumberChange = true
                }
                if (option[i].title === "Other Contact Number Change") {
                    selectedData.otherContactNumberChange = true
                }
                if (option[i].title === "Email Address Update") {
                    selectedData.emailAddressChange = true
                }
                if (option[i].title === "Title Change") {
                    selectedData.titleChange = true
                }
                if (option[i].title === "Address Change") {
                    selectedData.addressChange = true
                }
                if (option[i].title === "Other Information Change") {
                    selectedData.otherInformationChange = true
                }
                if (option[i].title === "Signature Card Update") {
                    selectedData.signatureCard = true
                }
                if (option[i].title === "Dormant Account Activation") {
                    selectedData.dormantAccountActivation = true
                }
                if (option[i].title === "Currency Add") {
                    selectedData.currencyAdd = true
                }
            }
        }
        this.setState({
            inputData: selectedData,
            selectedRequest: selectedArray,
            numberOfMultipleSelect: selectedArray.length
        })
    };

    signature = () => {
        if (this.state.getData) {
            return <SignatureButton
                accountNumber={this.props.appId !== undefined ? this.state.inputData.accountNumber : this.props.match.params.acNumber}
                signatureType="multiple" classes={this.props}/>
        }
    };

    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return MaintenanceUtility.renderMaintenanceHeader(this.props.freeFlag1, this.props.caseId, this.props.solId, this.props.cbNumber, this.props.accountNumber, this.props.customerName, this.close)
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Customer Information Update (<b> Customer
                    Name:</b>{this.state.inputData.customerName !== undefined ? this.state.inputData.customerName : ""}<b> CB:</b>{this.state.inputData.cbNumber !== undefined ? this.state.inputData.cbNumber : ""})
                </h4>
            )
        }
    };
    stopLoading = (value) => {
        if (value === "yes") {
            setTimeout(() => {
                this.setState({
                    loading: false,
                });
            }, 1000)
        } else {
            this.setState({
                getData: false,
                searchData: false,
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };
    renderDetailsInformtion = () => {
        if (this.state.getData) {
            let acNumber = this.props.appId === undefined ? this.props.match.params.acNumber : this.state.inputData.accountNumber;
            let cbNumber = this.props.appId === undefined ? this.props.match.params.cbNumber : this.state.inputData.cbNumber;
            return (
                <Label
                    parameterAccountNumber={acNumber}
                    cbNumber={cbNumber}
                    classes={this.props}
                    accountNumber={acNumber}
                    stopLoading={this.stopLoading}
                    linkedCB={true}
                    create={true}
                    crmStatus={this.state.inputData.crmStatus}/>
            )
        }
    };

    renderFlowSummeryButton = () => {
        if (this.state.getData && this.props.appId !== undefined) {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                   customerName={this.state.inputData.customerName}
                                   accountNumber={this.state.inputData.accountNumber}
                                   solId={this.state.inputData.freeFlag3}/>
            )
        }
    };

    render() {

        const {classes} = this.props;
        Functions.redirectToLogin(this.state);

        if (this.state.loading) {
            return (
                <>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>

                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                </>
            )
        } else {
            return (
                <Card>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loader}>
                        <DialogContent className={classes.dialogPaper}>

                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>

                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>


                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {this.renderHeader()}
                    </CardHeader>
                    <CardBody>
                        {this.renderNotification()}
                        <br/>
                        {this.renderDetailsInformtion()}
                        <br/>
                        {this.signature()}
                        <br/>
                        {this.renderMaintenanceRequest()}
                        <br/>
                        {this.renderSearchData()}
                        {this.commonData()}
                        <br/>
                        {this.handleSubmitButton()}
                    </CardBody>
                </Card>
            );
        }

    }

}

export default withStyles(styles)(LinkedAccountMaintenance);

