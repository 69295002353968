import React from "react";
import FormSample from '../JsonForm/FormSample';
import {backEndServerURL} from '../../Common/Constant';
import axios from 'axios';
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import withStyles from "@material-ui/core/styles/withStyles";
import Functions from '../../Common/Functions';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};
const jsonForm = {
    "variables": [

        {
            "varName": "constitutionName",
            "type": "text",
            "label": "Constitution Name",
            "required": true,
            "number": false,
        },
        {
            "varName": "status",
            "type": "select",
            "label": "status",
            "required": false,
            "select": true,
            "enum": [
                "ACTIVE",
                "INACTIVE"
            ]


        },
    ],

};


class ConstitutionAddEditDelete extends React.Component {
    renderEditForm = (() => {
        if (this.state.getConstitutionData) {
            return (
                <FormSample close={this.props.close} showValue={true} varValue={this.state.varValue} grid="12"
                            buttonName="Submit"
                            onSubmit={this.getSubmitedForm} jsonForm={jsonForm}/>
            )
        } else if (this.props.id === undefined) {
            return (
                <FormSample close={this.props.close} grid="12" buttonName="Submit" onSubmit={this.getSubmitedForm}
                            jsonForm={jsonForm}/>
            )
        } else {

        }
    });

    constructor(props) {
        super(props);
        this.state = {
            varValue: [],
            getConstitutionData: false,
            redirectLogin: false,
        }
    }

    firstLetterUpperCase = (string) => {
        var str = string.trim();
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    };

    getSubmitedForm = (object) => {
        if (this.props.id !== undefined) {
            let url = backEndServerURL + "/constitutionMaster/update/" + this.props.id;
            axios.post(url,
                {

                    "constitutionName": this.firstLetterUpperCase(object.constitutionName),

                    "status": object.status,

                }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    this.props.onUpdate();

                    console.log("Successfully Added");

                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                });
        } else {
            let url = backEndServerURL + "/constitutionMaster/add";

            axios.post(url,
                {

                    constitutionName: this.firstLetterUpperCase(object.constitutionName),

                    status: object.status,

                }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.props.onAdd(object);
                    console.log("Successfully Added");

                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                });
        }
    };

    componentDidMount() {
        if (this.props.id !== undefined) {
            let url = backEndServerURL + "/constitutionMaster/get/" + this.props.id;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let varValue = [];
                    varValue["constitutionName"] = response.data.constitutionName;
                    varValue["status"] = response.data.status;
                    this.setState({
                        varValue: varValue,
                        getConstitutionData: true

                    })
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                })
        }

    }

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (

            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>{this.props.name}</h4>
                        </CardHeader>
                        <CardBody>
                            <div>

                                {this.renderEditForm()}

                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }

}

export default withStyles(styles)(ConstitutionAddEditDelete);