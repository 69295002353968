import React from "react";
import CardHeader from "./Card/CardHeader";
import Card from "./Card/Card";
import CardBody from "./Card/CardBody";
import GridItem from "./Grid/GridItem";
import GridContainer from "./Grid/GridContainer";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from 'axios';
import Table from "./Table/Table";
import {backEndServerURL, frontEndServerURL} from "../Common/Constant";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Dialog, Popover, TextField} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pageview from '@material-ui/icons/Pageview';
import Customer from "./360View/Customer";
import Notification from "./NotificationMessage/Notification";
import loader from '../Static/loader.gif';
import {
    CSjsonFormIndividualAccountOpeningSearch,
    CSjsonFormIndividualAccountOpeningSearchBeneficiary,
    CSjsonFormIndividualAccountOpeningSearchmandate,
    CSjsonFormNonIndividualCompanyAccountOpeningSearch,
    CSjsonFormNonIndividualProprietorshipAccountOpeningSearch,
    CSjsonFormNonIndividualWithoutProprietorshipAccountOpeningSearch,
} from './workflow/WorkflowJsonForm4';
import CBNotFound from "./workflow/CASA/CBNotFound";
import Grid from "@material-ui/core/Grid";
import DedupLiabilityService from "./workflow/DedupLiabilityService";
import FinacleService from "./workflow/FinacleService";
import {ThemeProvider} from "@material-ui/styles";
import theme from "./JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "./JsonForm/CommonJsonFormComponent";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import MyValidation from "./JsonForm/MyValidation";
import MyValidationDedup from "./JsonForm/MyValidationDedup";
import validator from "validator";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FileDownload from "./workflow/CommonComponent/FileDownload";
import Button from "@material/react-button";
import CheckBox from "@material-ui/core/Checkbox/Checkbox";
import MyValidationDedupeWithCompany from "./JsonForm/MyValidationDedupeWithCompany";
import NewMerchant from "../Components/workflow/Merchant/DstCaseStart";
import LocalstorageEncrypt from "./JsonForm/LocalstorageEncrypt";

const dedupJsonForm = [
    {
        "varName": "merchantType",
        "type": "select",
        "label": "Merchant Type",
        "enum": [
            "Sole Proprietorship",
            "Limited Liability",
            "Partnership",
            "Others"
        ],
        "required": true,
        "grid": 2

    },
    {
        "varName": "merchantName",
        "type": "text",
        "validation": "string",
        "label": "Merchant Name",
        "grid": 2,
        "multiline": true,
        "required": true,
    },
    {
        "varName": "proprietorName",
        "type": "text",
        "validation": "string",
        "label": "Proprietor Name",
        "required": true,
        "multiline": true,
        "grid": 2

    },
    {
        "varName": "dba",
        "type": "text",
        "label": "DBA Name",
        "validation": "string",
        "required": true,
        "multiline": true,
        "grid": 2
    },
    {
        "label": "Contact Number",
        "type": "text",
        "varName": "mobileNumber",
        "grid": 2,
        "multiline": true,
        "required": true,
        "validation": "phone"
    },
    {
        "label": "Address",
        "type": "text",
        "varName": "address",
        "grid": 2,
        "multiline": true,
        "required": true,
    },
];

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};
const numberOfCustomerForMerchant = [
    {title: '1'},
    {title: '2'},
    {title: '3'},
    {title: '4'},
    {title: '5'},
    {title: '6'},
    {title: '7'},
    {title: '8'},
    {title: '9'},
    {title: '10'},
    {title: '11'},
    {title: '12'},
    {title: '13'},
    {title: '14'},
    {title: '15'},
    {title: '16'},
    {title: '17'},
    {title: '18'},
    {title: '19'},
    {title: '20'},


];
const numberOfCustomer = [
    {title: '2'},
    {title: '3'},
    {title: '4'},
    {title: '5'},
    {title: '6'},
    {title: '7'},
    {title: '8'},
    {title: '9'},
    {title: '10'},
    {title: '11'},
    {title: '12'},
    {title: '13'},
    {title: '14'},
    {title: '15'},
    {title: '16'},
    {title: '17'},
    {title: '18'},
    {title: '19'},
    {title: '20'},


];
const jointType = [
    {title: 'Individual Customer'},
    {title: 'Existing Joint CB /  Account'},


];
let jointCbNumber = [
    {
        "varName": "jointCbNumber",
        "type": "textDedup",
        "label": "Existing Joint CB Number",
        "required": true,
        "errorMessage": "Error",
        "grid": 2,


    },
    {
        "varName": "jointAccountNumber",
        "type": "textDedup",
        "label": "Existing Joint Account Number",
        "errorMessage": "Error",
        "grid": 2,


    },
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "varName": "mandate",
        "type": "select",
        "enum": [
            "YES",
            "NO"
        ],
        "label": "Mandate ",

        "grid": 2,

    },
    {
        "varName": "beneficiary",
        "type": "select",
        "enum": [
            "YES",
            "NO"
        ],
        "label": "Beneficial Owner",

        "grid": 2,

    },
];
let objectJsonFromForPropertership = [];

class CustomerDedupSearchForMerchant extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            SelectedData: false,
            fileUploadData: [],
            selectedDate: {},
            dropdownSearchData: {},
            getDedupData: false,
            dedupData: [[" ", " "]],
            propritorshipData: [[" ", " "]],
            relatedData: [[" ", " "]],
            sdnDataForNonIndividual: [[" ", " "]],
            sdnDataMandate: [[" ", " "]],
            sdnDataBeneficiary: [[" ", " "]],
            sdnData: [[" ", " "]],
            tableData: [],
            sourceMappingData: [],
            getsearchValue: [],
            getCustomerId: 0,
            getmatchBy: "",
            cbTypeForAllowAccountOpening: "",
            getAccountType: '',
            accountOpeningFromModal: false,
            tabMenuSelect: 'Individual A/C',
            existingAcoountOpeningModal: false,
            newAcoountOpeningModal: false,
            searchTableData: null,
            searchTableRelatedData: null,
            searchTableSdnData: null,
            oldAccountData: [],
            inputData: {mandate: "NO"},
            dataNotFound: false,
            CustomerModal: false,
            uniqueId: '',
            IDENTIFICATION_NO: '',
            cbNumber: '',
            customerName: '',
            dedupeCustomerName: '',
            id: '',
            alert: false,
            value: "Individual A/C",
            NonIndividualabel: "",
            individualLabel: "Individual A/C",
            content: "Individual A/C",
            contentType: "Individual A/C",
            getType: "Individual A/C",
            anchorEl: null,
            anchorE2: null,
            anchorE3: null,
            individualDropdownOpen: null,
            objectForJoinAccount: [],
            objectForSdnAccount: [],
            apiGetData: false,
            handleChangeJoint: 0,
            handleChangeJointType: 0,
            handleChangeSdn: 0,
            cbNotFound: false,
            loading: true,
            jointSearchTable: [],
            jointGetTaggingName: [],
            checkboxjointData: [],
            propritorship: false,
            propritor: false,
            individualDedupData: {},
            mandateindividualDedupData: {},
            mandateindividualDedupSDNData: {},
            beneficiaryindividualDedupData: {},
            beneficiaryindividualDedupSDNData: {},
            jointDedupData: {},
            companyDedupData: {},
            err: false,
            errorArray: {},
            errorMessages: {},
            nationalityApiData: [],
            countryCode: [],
            title: "Applicant CB Not Found",
            getError: false,
            getCompnayData: true,
            getPropritorData: true,
            downloadDataForCompany: false,
            downloadDataForCompanyProprietor: false,
            whengetCbnumbernewAccountCreationOff: false,
            mandateDedupData: [[" ", " "]],
            beneficiaryDedupData: [[" ", " "]],
            mandateBoolean: false,
            beneficiaryBoolean: false,
            businessSegment: "",

            notificationMessage: "CB number / NID / Passport / Birth Certificate or Driving License is Required!!",
            individualDedupeCheckbox: true,
            individualDedupeCheckboxNewButton: false,
            showValue: false,
            getData: false,
            getCity: [],
            getState: [],
            WithJointCb: false,
            companyDedupeBlock: false,
            postData: {},
            postDataProprietorship: {},
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleClickNonIndividual = this.handleClickNonIndividual.bind(this);
        this.handleClickIndividual = this.handleClickIndividual.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
    };

    handleClickNonIndividual(event) {

        event.stopPropagation();
        this.state.inputData = {};
        this.state.varValue = {};
        let inputData = this.state.inputData;
        let varValue = this.state.varValue;
        inputData.individualDedupeCheckbox = false;
        varValue.individualDedupeCheckbox = false;
        inputData.mobileTypeIndividual = "Local";
        inputData.mobileTypeMandate = "Local";
        inputData.mobileTypeBeneficiary = "Local";
        inputData.mobileTypeProprietorship = "Local";
        inputData.mobileTypeWithoutProprietorship = "Local";
        inputData.mobileTypeWithoutProprietorshipCompany = "Local";
        inputData.mobileTypeNonIndividualCompany = "Local";
        inputData.mobileTypeNGOSOCIETY = "Local";
        inputData.mobileTypeIndividualProprietorship = "Local";
        varValue.mobileTypeIndividual = "Local";
        varValue.mobileTypeMandate = "Local";
        varValue.mobileTypeBeneficiary = "Local";
        varValue.mobileTypeProprietorship = "Local";
        varValue.mobileTypeWithoutProprietorship = "Local";
        varValue.mobileTypeWithoutProprietorshipCompany = "Local";
        varValue.mobileTypeNonIndividualCompany = "Local";
        varValue.mobileTypeNGOSOCIETY = "Local";
        varValue.mobileTypeIndividualProprietorship = "Local";
        inputData.mandatenationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        inputData.beneficiarynationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        inputData.nationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        varValue.mandatenationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        varValue.beneficiarynationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        varValue.nationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        inputData.mandate = "NO";
        varValue.mandate = "NO";
        inputData.beneficiary = "NO";
        varValue.beneficiary = "NO";
        this.forceUpdate();
        this.setState({
            apiGetData: false,
            inputData: inputData,
            varValue: varValue,
            anchorEl: event.currentTarget,
            mandateBoolean: false,
            beneficiaryBoolean: false,

        });
    }

    handleClickIndividual(event) {

        event.stopPropagation();
        this.state.inputData = {};
        this.state.varValue = {};
        let inputData = this.state.inputData;
        let varValue = this.state.varValue;
        inputData.individualDedupeCheckbox = true;
        varValue.individualDedupeCheckbox = true;
        inputData.mobileTypeIndividual = "Local";
        inputData.mobileTypeMandate = "Local";
        inputData.mobileTypeBeneficiary = "Local";
        inputData.mobileTypeProprietorship = "Local";
        inputData.mobileTypeWithoutProprietorship = "Local";
        inputData.mobileTypeWithoutProprietorshipCompany = "Local";
        inputData.mobileTypeNonIndividualCompany = "Local";
        inputData.mobileTypeNGOSOCIETY = "Local";
        inputData.mobileTypeIndividualProprietorship = "Local";
        varValue.mobileTypeIndividual = "Local";
        varValue.mobileTypeMandate = "Local";
        varValue.mobileTypeBeneficiary = "Local";
        varValue.mobileTypeProprietorship = "Local";
        varValue.mobileTypeWithoutProprietorship = "Local";
        varValue.mobileTypeWithoutProprietorshipCompany = "Local";
        varValue.mobileTypeNonIndividualCompany = "Local";
        varValue.mobileTypeNGOSOCIETY = "Local";
        varValue.mobileTypeIndividualProprietorship = "Local";
        inputData.mandatenationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        inputData.beneficiarynationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        inputData.nationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        varValue.mandatenationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        varValue.beneficiarynationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        varValue.nationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        inputData.mandate = "NO";
        varValue.mandate = "NO";
        inputData.beneficiary = "NO";
        varValue.beneficiary = "NO";
        this.forceUpdate();
        this.setState({
            //apiGetData: false,
            inputData: inputData,
            varValue: varValue,
            anchorE2: event.currentTarget,
            mandateBoolean: false,
            beneficiaryBoolean: false,
            // individualDropdownOpen: true,
        });
    };

    handleClickIndividual3(event) {

        event.stopPropagation();
        this.state.inputData = {};
        this.state.varValue = {};
        let inputData = this.state.inputData;
        let varValue = this.state.varValue;
        inputData.individualDedupeCheckbox = true;
        varValue.individualDedupeCheckbox = true;
        inputData.mobileTypeIndividual = "Local";
        inputData.mobileTypeMandate = "Local";
        inputData.mobileTypeBeneficiary = "Local";
        inputData.mobileTypeProprietorship = "Local";
        inputData.mobileTypeWithoutProprietorship = "Local";
        inputData.mobileTypeWithoutProprietorshipCompany = "Local";
        inputData.mobileTypeNonIndividualCompany = "Local";
        inputData.mobileTypeNGOSOCIETY = "Local";
        inputData.mobileTypeIndividualProprietorship = "Local";
        varValue.mobileTypeIndividual = "Local";
        varValue.mobileTypeMandate = "Local";
        varValue.mobileTypeBeneficiary = "Local";
        varValue.mobileTypeProprietorship = "Local";
        varValue.mobileTypeWithoutProprietorship = "Local";
        varValue.mobileTypeWithoutProprietorshipCompany = "Local";
        varValue.mobileTypeNonIndividualCompany = "Local";
        varValue.mobileTypeNGOSOCIETY = "Local";
        varValue.mobileTypeIndividualProprietorship = "Local";
        inputData.mandatenationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        inputData.beneficiarynationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        inputData.nationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        varValue.mandatenationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        varValue.beneficiarynationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        varValue.nationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        inputData.mandate = "NO";
        varValue.mandate = "NO";
        inputData.beneficiary = "NO";
        varValue.beneficiary = "NO";
        this.forceUpdate();
        this.setState({
            apiGetData: false,
            inputData: inputData,
            varValue: varValue,
            anchorE3: event.currentTarget,
            mandateBoolean: false,
            beneficiaryBoolean: false,
            individualDropdownOpen3: true,
        });
    };

    handleClose() {
        this.setState({
            anchorEl: null,
            anchorE2: null,
            anchorE3: null,
            mandateBoolean: false,
            beneficiaryBoolean: false,
            individualDropdownOpen: false,
            err: false,
            apiGetData: true,
            handleChangeJoint: 0,
            errorArray: {},
            sdnDataForNonIndividualFlag: false,
            searchTableSdnData: false,
            errorMessages: {},


        });
    }


    handleMenuItemClick(menuItem) {

        this.handleClose();
        let inputData = this.state.inputData;
        let varValue = this.state.varValue;
        inputData.dedupeType = menuItem;
        varValue.dedupeType = menuItem;
        inputData.individualDedupeCheckbox = true;
        varValue.individualDedupeCheckbox = true;
        inputData.mobileTypeIndividual = "Local";
        inputData.mobileTypeMandate = "Local";
        inputData.mobileTypeBeneficiary = "Local";
        inputData.mobileTypeProprietorship = "Local";
        inputData.mobileTypeWithoutProprietorship = "Local";
        inputData.mobileTypeWithoutProprietorshipCompany = "Local";
        inputData.mobileTypeNonIndividualCompany = "Local";
        inputData.mobileTypeNGOSOCIETY = "Local";
        inputData.mobileTypeIndividualProprietorship = "Local";
        varValue.mobileTypeIndividual = "Local";
        varValue.mobileTypeMandate = "Local";
        varValue.mobileTypeBeneficiary = "Local";
        varValue.mobileTypeProprietorship = "Local";
        varValue.mobileTypeWithoutProprietorship = "Local";
        varValue.mobileTypeWithoutProprietorshipCompany = "Local";
        varValue.mobileTypeNonIndividualCompany = "Local";
        varValue.mobileTypeNGOSOCIETY = "Local";
        varValue.mobileTypeIndividualProprietorship = "Local";
        inputData.mandatenationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        inputData.beneficiarynationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        inputData.nationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        varValue.mandatenationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        varValue.beneficiarynationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        varValue.nationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        inputData.mandate = "NO";
        varValue.mandate = "NO";
        inputData.beneficiary = "NO";
        varValue.beneficiary = "NO";
        if (menuItem !== 'Partnership A/C') {
            this.state.inputData.withoutpartnershipFlag = true;

        } else {
            this.state.inputData.withoutpartnershipFlag = false;
        }
        this.setState({
            inputData: inputData,
            varValue: varValue,
            showValue: true,
            NonIndividualabel: menuItem,
            content: menuItem,
            contentType: menuItem,
            getType: menuItem,
            value: "More",
            sdnDataForNonIndividualFlag: false,
            initialization: true,
            individualDedupeCheckbox: true,
            searchTableSdnData: false,
            handleChangeJoint: 0,

        });
    }

    closeInitialization = () => {
        this.setState({
            initialization: false
        });
    };

    handleMenuItemClickIndividual(menuItem) {
        this.state.inputData.dedupeType = menuItem;
        this.state.varValue.dedupeType = menuItem;
        this.handleClose();
        this.setState({
            individualLabel: menuItem,
            content: menuItem,
            contentType: menuItem,
            getType: menuItem,
            sdnDataForNonIndividualFlag: false,
            handleChangeJoint: 0,
            value: "More",
            initialization: true,
            searchTableSdnData: false,


        });

    }

    renderExistingAccountOpeningForm = (businessSegment, customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType, cbType, matchBy) => {

        let arrayData = {
            nid: nid,

            passport: passport,

            customerName: customerName,
            phone: phone,
            dob: dob,
            email: email,
            registration: registration
        };

        this.setState({
            businessSegment: businessSegment,
            existingAcoountOpeningModal: true,
            oldAccountData: arrayData,
            dedupeCustomerName: customerName,
            getCustomerId: customerId,
            getmatchBy: matchBy,
            cbTypeForAllowAccountOpening: cbType,
            getAccountType: accountSource,
            newAcoountOpeningModal: false,

        })
    };
    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;
        console.log(value);
        console.log(getKeyValue);


        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            console.log(jsonObject);
            if (value === jsonObject.key) {
                return jsonObject;
                i = jsonArray.length;

            }
        }


    };
    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;
        console.log(value);
        console.log(getKeyValue);


        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            console.log(jsonObject);
            if (value === jsonObject.key) {
                return jsonObject;
                i = jsonArray.length;

            }
        }


    };
    renderNewAccountOpeingForm = () => {
        if (this.state.content === "Joint Account") {
            console.log(this.state.jointGetTaggingName);
            console.log(this.state.jointGetTaggingName[1]);
            this.setState({
                loading: true
            });


            if (this.state.jointGetTaggingName[1] !== undefined) {
                let url = backEndServerURL + "/cbsCustomerGet/" + this.state.jointGetTaggingName[1].customerId;
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);
                        this.state.inputData.customerName = "";
                        this.state.inputData.jointEmail = "";
                        this.state.inputData.jointMobileNumber = "";
                        this.state.inputData.jointCommunicationAddress1 = "";
                        this.state.inputData.jointCommunicationAddress2 = "";
                        this.state.inputData.jointCity = "";
                        this.state.inputData.jointState = "";
                        this.state.inputData.jointPostalCode = "";
                        this.state.inputData.jointCountry = "";
                        let jointCustomerName = "";
                        let jointEmail = "";
                        let jointPhone = "";
                        let designatedJointPhone = "";
                        let jointCommunicationAddress1 = "";
                        let jointCommunicationAddress2 = "";
                        let jointCity = "";
                        let jointState = "";
                        let jointPostalCode = "";
                        let jointCountry = "";
                        let jointCustomerNameList = [];
                        for (let i = 0; i < this.state.handleChangeJoint; i++) {
                            if (this.state.jointGetTaggingName[i + 1] !== null && this.state.jointGetTaggingName[i + 1] !== undefined) {
                                jointCustomerNameList.push(this.state.jointGetTaggingName[i + 1].customerName);
                                if (i === 0) {
                                    jointCommunicationAddress1 = response.data.MailingBLOCK.addrLine1;
                                    jointCommunicationAddress2 = response.data.MailingBLOCK.addrLine2;
                                    jointCity = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.cityCode, this.state.getCity);
                                    jointState = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.stateCode, this.state.getState);
                                    jointPostalCode = response.data.MailingBLOCK.postalCode;
                                    jointCountry = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.countryCode, this.state.nationalityApiData);

                                    if (response.data.COMMPH1BLOCK !== undefined) {
                                        jointPhone = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                        jointEmail = response.data.COMMPH1BLOCK.emailInfo;
                                        this.state.inputData.designatedMobileNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                        this.state.inputData.designatedEmail = response.data.COMMPH1BLOCK.emailInfo;
                                        // this.state.inputData.contactNumberphoneNo1 =  response.data.COMMPH1BLOCK.phoneNumLocalCode
                                        if (response.data.COMMPH1BLOCK.phoneNumCountryCode === "88") {
                                            // this.state.inputData.mobileTypecontactNumberphoneNo1 = "Local";
                                            this.state.inputData.mobileTypeIndividual = "Local";
                                        } else {
                                            //this.state.inputData.mobileTypecontactNumberphoneNo1 = "Overseas";
                                            this.state.inputData.mobileTypeIndividual = "Overseas";
                                            this.state.inputData.countryCodeIndividual = this.findByVarNameGetKeyValue(response.data.COMMPH1BLOCK.phoneNumCountryCode, this.state.countryCode)
                                            //this.state.inputData.countryCodecontactNumberphoneNo1=this.findByVarNameGetKeyValue( response.data.COMMPH1BLOCK.phoneNumCountryCode,this.state.countryCode)
                                        }
                                        this.state.inputData.mobileTypecontactNumberJoint = true
                                    }


                                }

                            } else {
                                let custernameVar = "customerName" + i;
                                console.log(this.state.inputData);
                                if (this.state.inputData["cbNumber" + i] !== "") {
                                    continue;
                                }

                                jointCustomerNameList.push(this.state.inputData[custernameVar]);
                                if (i === 0) {
                                    jointEmail = this.state.inputData.email0;
                                    jointPhone = this.state.inputData.phone0;
                                }


                            }

                        }

                        for (let i = 0; i < jointCustomerNameList.length; i++) {

                            jointCustomerName = jointCustomerName + jointCustomerNameList[i];
                            if (i < jointCustomerNameList.length - 2) {
                                jointCustomerName = jointCustomerName + " , ";
                            } else if (i === jointCustomerNameList.length - 2) {
                                jointCustomerName = jointCustomerName + " AND ";
                            }
                        }
                        console.log(jointCustomerName);
                        this.state.inputData.customerName = jointCustomerName;
                        this.state.inputData.jointEmail = jointEmail;
                        this.state.inputData.jointMobileNumber = jointPhone;
                        this.state.inputData.communicationAddress1 = jointCommunicationAddress1;
                        this.state.inputData.communicationAddress2 = jointCommunicationAddress2;
                        this.state.inputData.city2 = jointCity;
                        this.state.inputData.state2 = jointState;
                        this.state.inputData.postalCode3 = jointPostalCode;

                        this.state.inputData.city = jointCity;
                        this.state.inputData.state = jointState;
                        this.state.inputData.postalCode = jointPostalCode;

                        this.state.inputData.country = jointCountry;
                        this.forceUpdate();
                        this.setState({
                            loading: false,
                            newAcoountOpeningModal: true,


                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                            newAcoountOpeningModal: true,

                        })
                    })
            } else {
                this.state.inputData.customerName = "";
                this.state.inputData.jointEmail = "";
                this.state.inputData.jointMobileNumber = "";
                this.state.inputData.communicationAddress1 = "";
                this.state.inputData.communicationAddress2 = "";
                this.state.inputData.city2 = "";
                this.state.inputData.state2 = "";
                this.state.inputData.postalCode3 = "";
                this.state.inputData.country = "";
                let jointCustomerName = "";
                let jointEmail = "";
                let jointPhone = "";
                let jointCommunicationAddress1 = "";
                let jointCommunicationAddress2 = "";
                let jointCity = "";
                let jointState = "";
                let jointPostalCode = "";
                let jointCountry = "";
                let jointCustomerNameList = [];
                for (let i = 0; i < this.state.handleChangeJoint; i++) {
                    if (this.state.jointGetTaggingName[i + 1] !== null && this.state.jointGetTaggingName[i + 1] !== undefined) {
                        jointCustomerNameList.push(this.state.jointGetTaggingName[i + 1].customerName);
                        if (i === 0) {
                            console.log(this.state.jointGetTaggingName[i + 1]);
                            jointEmail = this.state.jointGetTaggingName[i + 1].email;
                            jointPhone = this.state.jointGetTaggingName[i + 1].phone;
                        }

                    } else {
                        let custernameVar = "customerName" + i;
                        console.log(this.state.inputData);
                        if (this.state.inputData["cbNumber" + i] !== "") {
                            continue;
                        }
                        jointCustomerNameList.push(this.state.inputData[custernameVar]);
                        if (i === 0) {
                            jointEmail = this.state.inputData.email0;
                            jointPhone = this.state.inputData.phone0;
                        }


                    }

                }

                for (let i = 0; i < jointCustomerNameList.length; i++) {

                    jointCustomerName = jointCustomerName + jointCustomerNameList[i];
                    if (i < jointCustomerNameList.length - 2) {
                        jointCustomerName = jointCustomerName + " , ";
                    } else if (i === jointCustomerNameList.length - 2) {
                        jointCustomerName = jointCustomerName + " AND ";
                    }
                }

                this.state.inputData.customerName = jointCustomerName;
                this.state.inputData.jointEmail = jointEmail;
                this.state.inputData.jointMobileNumber = jointPhone;
                this.state.inputData.customerTitle = "JOINT";

                this.state.inputData.designatedMobileNumber = jointPhone;
                this.state.inputData.designatedEmail = jointEmail;


                this.forceUpdate();
                this.setState({
                    loading: false,
                    newAcoountOpeningModal: true,

                })
            }
            this.setState({
                checkboxjointData: this.emptyStringSet(this.state.inputData)
            })

        } else {
            this.setState({
                loading: false,
                newAcoountOpeningModal: true,

            })
        }


    };
    CustomerModal = (uniqueId, customerName, customerId, id) => {
        let data = {};
        data.identificationNumber = uniqueId;
        data.cbNumber = customerId;
        data.customerName = customerName;
        data.identity = id;
        LocalstorageEncrypt.encryptStorageFunction.setItem(id, JSON.stringify(data));
        LocalstorageEncrypt.encryptStorageFunction.setItem("lwfTo360", true);
        let url = "/Customer/" + id;       // window.open(frontEndServerURL + url)
        let topandleft=(window.innerHeight)- (window.innerHeight * 0.75);
        window.open(frontEndServerURL + url , "windowName", "scrollbars=1,height= "+ window.innerHeight * 0.65 +" ,width="+window.innerWidth * 0.65 + ", top="+topandleft +", left="+ topandleft +"");
        /* this.setState({
             CustomerModal: true,
             customerName: customerName,
             cbNumber: customerId,
             IDENTIFICATION_NO: uniqueId

         })*/
    };
    closeCustomerModal = () => {
        this.setState({
            CustomerModal: false,

        })
    };
    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false

        })
    };
    removeSearchResult = () => {
        this.setState({

            showValue: false,
            existingAcoountOpeningModal: false,
            newAcoountOpeningModal: false,
        });
        this.handleChange("NEW", "INDIVIDUAL")
    };
    closeModal = () => {
        this.setState({
            existingAcoountOpeningModal: false,
            newAcoountOpeningModal: false,
        })

    };
    radioButtonChangeSetName = (customerName, phone, email, randomNumber, id, customerId) => {
        /* if (this.state.jointGetTaggingName[randomNumber] === customerName) {
             this.state.jointGetTaggingName[randomNumber] = undefined
         } else {*/
        let data = {};
        data.customerName = customerName;
        /*  if(phone.length>10){
             data.phone = phone.substr(phone.length-11)
         }
         else{
             data.phone = phone
         }*/

        //data.email = email
        data.id = id;
        data.customerId = customerId;
        //data.nationality = customerId
        this.state.jointGetTaggingName[randomNumber] = data;
        this.forceUpdate();


        console.log(this.state.jointGetTaggingName)
    };

    createTableData = (randomNumber, id, uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchedBy, matchType) => {
        //this.renderCustomerNumber(sl);
        if (this.state.content === 'Joint Account' || this.state.content === "Existing Joint CB /  Account") {
            if (accountSource === "TRANZWARE") {
                return ([
                    <FormControlLabel
                        value={id}
                        control={<Radio
                            onChange={(event) => this.radioButtonChangeSetName(customerName, phone, email, randomNumber, id, customerId)}/>}

                    />


                    /*<input type="radio"
                                onChange={(event) => this.radioButtonChangeSetName(customerName, randomNumber)}
                                name={this.state.jointGetTaggingName[randomNumber]}
                                value={randomNumber}/>*/, uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, matchedBy, matchType,
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >
                        {/*<Fab size="small" color="secondary" aria-label="pageview">*/}
                        {/* <a href="/Customer" target="_blank"><Link target="_blank" to={{
                            pathname: '/Customer',
                            state: {
                                IDENTIFICATION_NO: uniqueId
                            }
                        }}> <Pageview/></Link> </a>*/}
                        <Pageview/>

                        {/*</Fab>*/}

                    </button>])
            } else {
                return (

                    [
                        <FormControlLabel
                            value={id}
                            control={<Radio
                                onChange={(event) => this.radioButtonChangeSetName(customerName, phone, email, randomNumber, id, customerId)}/>}

                        />
                        , uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, matchedBy, matchType,
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'middle',
                            }}

                            onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                        >
                            {/*<Fab size="small" color="secondary" aria-label="pageview">*/}

                            {/* {/* <a href="/Customer" target="_blank"><Link target="_blank" to={{
                            pathname: '/Customer',
                            state: {
                                IDENTIFICATION_NO: uniqueId
                            }
                        }}> <Pageview/></Link> </a>*/}
                            <Pageview/>
                            {/*</Fab>*/}

                        </button>


                    ])
            }
        } else {
            if (accountSource === "TRANZWARE") {
                return ([uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, matchedBy, matchType,/* "",*/ "", /*<button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                        width: 113,
                    }}
                    onClick={() => this.renderNewAccountOpeingForm()}>
                    CONVENTIONAL</button>,

                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'middle',
                            width: 113,
                        }}
                        onClick={() => this.renderNewAccountOpeingForm()}>
                        ISLAMIC
                    </button>,*/
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >

                        <Pageview/>


                    </button>])
            } else if (this.state.content === 'NONINDIVIDUAL' || this.state.content === 'Proprietorship A/C') {
                return ([uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, matchedBy, matchType,


                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >
                        {/*<Fab size="small" color="secondary" aria-label="pageview">*/}


                        {/* <a href="/Customer" target="_blank"><Link target="_blank" to={{
                            pathname: '/Customer',
                            state: {
                                IDENTIFICATION_NO: uniqueId
                            }
                        }}> <Pageview/></Link> </a>*/}
                        <Pageview/>
                        {/*</Fab>*/}

                    </button>


                ])
            } else {
                if (accountSource === "ABABIL") {
                    return ([uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, matchedBy, matchType,

                        /*    <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'middle',
                                    width: 113,
                                }}
                                onClick={() => this.renderExistingAccountOpeningForm("ISLAMIC", customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType, cbType)}>
                                ISLAMIC</button>,*/

                        this.props.nidBlockShowing !== false && this.props.merchantDedup!==true && <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'middle',
                                width: 113,
                            }}
                            onClick={() => this.renderExistingAccountOpeningForm("CONVENTIONAL", customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType, cbType, matchedBy)}>
                            CONVENTIONAL
                        </button>,

                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'middle',
                            }}

                            onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                        >
                            {/*<Fab size="small" color="secondary" aria-label="pageview">*/}
                            {/* <a href="/Customer" target="_blank"><Link target="_blank" to={{
                            pathname: '/Customer',
                            state: {
                                IDENTIFICATION_NO: uniqueId
                            }
                        }}> <Pageview/></Link> </a>*/}
                            <Pageview/>

                            {/*</Fab>*/}

                        </button>

                    ])
                } else {
                    return ([uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, matchedBy, matchType,

                        this.props.nidBlockShowing !== false && this.props.merchantDedup!==true &&  <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'middle',
                                width: 113,
                            }}
                            onClick={() => this.renderExistingAccountOpeningForm("CONVENTIONAL", customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType, cbType, matchedBy)}>
                            CONVENTIONAL</button>,
                        /*  <button
                              className="btn btn-outline-primary btn-sm"
                              style={{
                                  verticalAlign: 'middle',
                                  width: 113,
                              }}
                              onClick={() => this.renderExistingAccountOpeningForm("ISLAMIC", customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType, cbType)}>
                              ISLAMIC
                          </button>,*/

                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'middle',
                            }}

                            onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                        >
                            {/*<Fab size="small" color="secondary" aria-label="pageview">*/}

                            {/* <a href="/Customer" target="_blank"><Link target="_blank" to={{
                            pathname: '/Customer',
                            state: {
                                IDENTIFICATION_NO: uniqueId
                            }
                        }}> <Pageview/></Link> </a>*/}
                            <Pageview/>
                            {/*</Fab>*/}

                        </button>


                    ])
                }
            }
        }

    };
    createTableDataMandate = (randomNumber, id, uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchedBy, matchType) => {
        //this.renderCustomerNumber(sl);
        if (accountSource === "TRANZWARE") {
            return ([uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, matchedBy, matchType,
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}

                    onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                >

                    <Pageview/>


                </button>])
        } else {
            if (accountSource === "ABABIL") {
                return ([/*<RadioGroup
                        name="customer1"
                        value={this.state.jointGetTaggingName[randomNumber]}
                    >
                        <FormControlLabel
                            value={customerName}
                            control={<Radio
                                onChange={(event) => this.radioButtonChangeSetName(customerName, randomNumber)}/>}

                        />
                    </RadioGroup>,*/uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, matchedBy, matchType,


                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >

                        <Pageview/>


                    </button>

                ])
            } else {
                return ([/*<RadioGroup
                        name="customer1"
                        value={this.state.jointGetTaggingName[randomNumber]}
                    >
                        <FormControlLabel
                            value={customerName}
                            control={<Radio
                                onChange={(event) => this.radioButtonChangeSetName(customerName, randomNumber)}/>}

                        />
                    </RadioGroup>,*/ uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, matchedBy, matchType,


                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >

                        <Pageview/>

                    </button>


                ])
            }
        }


    };
    createTableDataWithoutProprietorship = (id, uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchby, matchType) => {
        //this.renderCustomerNumber(sl);
        if (this.state.content === 'Partnership A/C' || this.state.content === 'Private/Public Limited Company Account' || this.state.content === 'NGO/NPO/Club/Society Account' || this.state.content === 'Educational/Religious Institution Account' || this.state.content === 'Government Account' || this.state.content === 'Other Account') {
            if (accountSource === "TRANZWARE") {
                return ([uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, matchby, matchType,
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >
                        {/*<Fab size="small" color="secondary" aria-label="pageview">*/}

                        {/* <a href="/Customer" target="_blank"><Link target="_blank" to={{
                            pathname: '/Customer',
                            state: {
                                IDENTIFICATION_NO: uniqueId
                            }
                        }}> <Pageview/></Link> </a>*/}
                        <Pageview/>
                        {/*</Fab>*/}

                    </button>])
            } else {
                return (

                    [uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, matchType, matchType,
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'middle',
                            }}

                            onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                        >
                            {/*<Fab size="small" color="secondary" aria-label="pageview">*/}

                            {/* <a href="/Customer" target="_blank"><Link target="_blank" to={{
                            pathname: '/Customer',
                            state: {
                                IDENTIFICATION_NO: uniqueId
                            }
                        }}> <Pageview/></Link> </a>*/}
                            <Pageview/>
                            {/*</Fab>*/}

                        </button>


                    ])
            }
        }


    };
    createTableproCompany = (id, uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matched_by, matchType) => {


        if (this.state.content === 'NONINDIVIDUAL' || this.state.content === 'Proprietorship A/C') {
            return ([uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, matched_by, matchType,
                this.props.nidBlockShowing !== false  &&  this.props.merchantDedup!==true && <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                        width: 113,
                    }}
                    onClick={() => this.renderExistingAccountOpeningForm("CONVENTIONAL", customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType, cbType, matched_by)}>
                    CONVENTIONAL</button>,
                /* <button
                     className="btn btn-outline-primary btn-sm"
                     style={{
                         verticalAlign: 'middle',
                         width: 113,
                     }}
                     onClick={() => this.renderExistingAccountOpeningForm("ISLAMIC", customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType, cbType)}>
                     ISLAMIC
                 </button>,*/

                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}

                    onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                >
                    {/*<Fab size="small" color="secondary" aria-label="pageview">*/}

                    {/* <a href="/Customer" target="_blank"><Link target="_blank" to={{
                            pathname: '/Customer',
                            state: {
                                IDENTIFICATION_NO: uniqueId
                            }
                        }}> <Pageview/></Link> </a>*/}
                    <Pageview/>
                    {/*</Fab>*/}

                </button>

            ])

        } else {
            return ([uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, matched_by, matchType,
                this.props.nidBlockShowing !== false &&  this.props.merchantDedup!==true && <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                        width: 113,
                    }}
                    onClick={() => this.renderExistingAccountOpeningForm("CONVENTIONAL", customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType, cbType, matched_by)}>
                    CONVENTIONAL</button>,
                /*<button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                        width: 113,
                    }}
                    onClick={() => this.renderExistingAccountOpeningForm("ISLAMIC", customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType, cbType)}>
                    ISLAMIC
                </button>,*/

                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}

                    onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                >
                    {/*<Fab size="small" color="secondary" aria-label="pageview">*/}

                    {/* <a href="/Customer" target="_blank"><Link target="_blank" to={{
                            pathname: '/Customer',
                            state: {
                                IDENTIFICATION_NO: uniqueId
                            }
                        }}> <Pageview/></Link> </a>*/}
                    <Pageview/>

                    {/*</Fab>*/}

                </button>


            ])
        }

    };

    createRelatedTableData = (id, uniqueId, mainCB, relatedCB, relatedCustomerName, relationship) => {
        console.log(relatedCB);

        if (this.state.content === 'Joint Account' || this.state.content === "Existing Joint CB /  Account") {
            return ([uniqueId, mainCB, relatedCB, relatedCustomerName, relationship,
                this.props.nidBlockShowing !== false && this.props.merchantDedup!==true &&  <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                        width: 113,

                    }}

                    // onClick={() => this.renderNewAccountOpeingForm()}
                    onClick={() => this.renderExistingAccountOpeningForm("CONVENTIONAL", relatedCB, null, relatedCustomerName, null, null, null, null, null, null, null, null, "RETAIL")}

                >
                    CONVENTIONAL
                </button>
                /*  ,
                  <button
                      className="btn btn-outline-primary btn-sm"
                      style={{
                          verticalAlign: 'middle',
                          width: 113,

                      }}
                      onClick={() => this.renderExistingAccountOpeningForm("ISLAMIC", relatedCB, null, null, null, null, null, null, null, null, null, null)}
                  >
                      ISLAMIC
                  </button>
  */
            ])

        } else {
            return ([uniqueId, mainCB, relatedCB, relatedCustomerName, relationship])

        }
    };

    createSdnTableData = (country, dob, matchType, name, type) => {


        return ([name, dob, matchType, type, country])


    };

    renderReloading(status) {
        this.setState({

            searchTableData: status,
            mandateBoolean: status,
            beneficiaryBoolean: status,
            searchTableRelatedData: status,

        })
    }

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="error" stopNotification={this.stopNotification} title="Required!!"
                              message={this.state.notificationMessage}/>
            )
        }

    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    trimFunction = (emptyString) => {
        if (emptyString !== null && emptyString !== undefined && emptyString !== "") {
            let string = emptyString;
            let typeOfString = typeof string;
            if (typeOfString === "string" || typeOfString === "number") {
                return string.trim()
            } else if (typeOfString === "object") {
                return string.key;
            } else {
                return ""
            }


        } else {
            return ""
        }

    };
    emptyStringSet = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let i = 0; i < clone.length; i++) {
            let data = clone[i]["varName"];
            if (clone[i]["varName"] !== undefined) {
                data = data.trim()

            }
            //let data = clone[i]["varName"]

            if (this.state.inputData[data] !== null && this.state.inputData[data] !== undefined && !validator.isEmpty(this.trimFunction(this.state.inputData[data]))) {
                let getData = this.state.inputData[data];
                let typeOfString = typeof getData;

                if (typeOfString === "string" || typeOfString === "number") {
                    this.state.inputData[data] = (this.state.inputData[data]).trim()
                } else if (typeOfString === "object") {
                    this.state.inputData[data] = (this.state.inputData[data]);
                }

            } else {
                this.state.inputData[data] = ""
            }


        }
        return clone;
    };
    reloadOff = () => {
        if ((this.state.getCompnayData === false && this.state.getPropritorData === false) || this.state.content === "NGO/NPO/Club/Society Account") {
            this.setState({
                loading: false
            })
        }
    };
    trimFunctionInputData = (emptyString) => {

        if (emptyString !== null && emptyString !== undefined && emptyString !== "") {
            let string = emptyString;
            let typeOfString = typeof string;
            if (typeOfString === "string" || typeOfString === "number") {
                return string.trim()
            } else if (typeOfString === "object") {
                return string.key;
            } else {
                return ""
            }


        } else {
            return ""
        }

    };
    dateConverter = (value) => {
        return (value).split(',')[0];

    };
    getPhoneNumberData = (data) => {
        if (data !== undefined && data !== "") {
            return "88" + data;
        } else {
            return "";
        }
    }
    handleSubmit = (event) => {
        if(this.props.companyDedupeBlock !== true){
            if (this.state.content === "NGO/NPO/Club/Society Account") {
                this.state.inputData.freeFlag3 = "Company Account"
            } else if (this.state.content === "Existing Joint CB /  Account") {
                this.state.inputData.freeFlag3 = "Joint Account";
            } else {
                this.state.inputData.freeFlag3 = this.state.content;
            }


            this.state.inputData.WithJointCb = false;
            this.forceUpdate();
            this.setState({
                merchantDedupValidation:this.props.merchantDedup!==undefined && this.props.merchantDedup!==null?true:false,
                getCompnayData: true,
                mandateBoolean: false,
                beneficiaryBoolean: false,
                getPropritorData: true,
                getError: false,
                downloadDataForCompany: false,
                downloadDataForCompanyProprietor: false,
                whengetCbnumbernewAccountCreationOff: false,
                individualDedupeCheckboxNewButton: false,
                sdnDataForNonIndividualFlag: false,
                jointGetTaggingName: []

            });
            event.preventDefault();

            var dependencyField = [];
            var error = false;
            var errorForPropertership = false;
            var errorMandate = false;
            var errorBeneficiary = false;
            let s1 = false;

            console.log(this.state.inputData);
            if (this.props.merchantDedup === true) {
                let errors = MyValidation.defaultValidation(dedupJsonForm, this.state);
                this.forceUpdate();

                if (errors === true) {
                    return 0;
                }
                if (this.state.handleChangeJoint < 1) {
                    this.setState({
                        /* title:"Aplicant Minor",
                         cbNotFound:true,*/
                        alert: true,
                        notificationMessage: "CB or Individual Dedupe Mandatory Field",
                    });
                    return 0;
                }
            }

            if (this.state.content === 'INDIVIDUAL' || this.state.content === 'Individual A/C') {
                if ((this.state.inputData.cbNumber !== "" && this.state.inputData.cbNumber !== undefined && this.state.inputData.cbNumber !== null) || (this.state.inputData.accountNumber !== "" && this.state.inputData.accountNumber !== undefined && this.state.inputData.accountNumber !== null)) {
                    this.setState({
                        whengetCbnumbernewAccountCreationOff: true
                    })
                }


                if (this.state.inputData.dob !== undefined && this.state.inputData.dob !== null) {
                    let x = 18;
                    var currentDate = new Date();

                    let someDate = new Date();
                    let numberOfDaysToAdd = parseInt(x, 10);
                    someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let convertDate = year + "-" + month + "-" + date;
                    let newDate = new Date(convertDate);
                    let stringToDate = new Date(newDate.toDateString());

                    let inputDate = new Date(this.state.inputData.dob);
                    if (stringToDate < inputDate) {
                        this.setState({
                            /* title:"Aplicant Minor",
                             cbNotFound:true,*/
                            alert: true,
                            notificationMessage: "Applicant is Minor.Please Open Joint Account",
                        });
                        return 0;
                    }
                }
                console.log(this.state.inputData);
                error = MyValidationDedup.CustomValidationIndividual(this.emptyStringSet(CSjsonFormIndividualAccountOpeningSearch), this.state);

                console.log(this.state.inputData)
            } else if (this.state.content === 'Joint Account') {
                error = MyValidationDedup.CustomValidationJoint(this.emptyStringSet(this.state.objectForJoinAccount), this.state)

            } else if (this.state.content === 'Existing Joint CB /  Account') {
                error = MyValidationDedup.CustomValidationJointCbandAccount(this.emptyStringSet(jointCbNumber), this.state)


            } else if (this.props.companyDedupeBlock !== true && (this.state.content === 'NONINDIVIDUAL' || this.state.content === 'Proprietorship A/C')) {
                if ((this.state.inputData.companyCbNumber !== "" && this.state.inputData.companyCbNumber !== undefined && this.state.inputData.companyCbNumber !== null) || (this.state.inputData.companyAccountNumber !== "" && this.state.inputData.companyAccountNumber !== undefined && this.state.inputData.companyAccountNumber !== null)) {
                    if (this.state.inputData.companyCbNumber.trim() !== "" || this.state.inputData.companyAccountNumber.trim() !== "") {
                        this.setState({
                            whengetCbnumbernewAccountCreationOff: true
                        })
                    }

                }
                /* if ((this.state.individualDedupeCheckbox && this.state.inputData.cbNumber !== "" && this.state.inputData.cbNumber !== undefined && this.state.inputData.cbNumber !== null)) {
                     if (this.state.inputData.cbNumber.trim() !== "") {
                         this.setState({
                             whengetCbnumbernewAccountCreationOff: true
                         })
                     }
                 }*/

                if (this.state.individualDedupeCheckbox && this.state.inputData.dob !== undefined && this.state.inputData.dob !== null) {
                    let x = 18;
                    var currentDate = new Date();

                    let someDate = new Date();
                    let numberOfDaysToAdd = parseInt(x, 10);
                    someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let convertDate = year + "-" + month + "-" + date;
                    let newDate = new Date(convertDate);
                    let stringToDate = new Date(newDate.toDateString());

                    let inputDate = new Date(this.state.inputData.dob);

                    if (stringToDate < inputDate) {
                        this.setState({
                            /* title:"Aplicant Minor",
                             cbNotFound:true,*/
                            alert: true,
                            notificationMessage: "Your Applicant Minor",
                        });
                        return 0;
                    }
                }

                if (this.state.individualDedupeCheckbox) {
                    errorForPropertership = MyValidationDedup.CustomValidationIndividual(this.emptyStringSet(objectJsonFromForPropertership), this.state)
                }
                error = MyValidationDedupeWithCompany.CustomValidationProprietorship(this.emptyStringSet(CSjsonFormNonIndividualProprietorshipAccountOpeningSearch), this.state);

                this.forceUpdate();
                console.log(this.state.errorArray);
                console.log(this.state.errorMessages)

            }
            /*
                    else if (this.state.content === 'Partnership A/C' || this.state.content === 'Private/Public Limited Company Account' || this.state.content === "Other Account") {
                        if ((this.state.inputData.companyCbNumber !== "" && this.state.inputData.companyCbNumber !== undefined && this.state.inputData.companyCbNumber !== null) || (this.state.inputData.companyAccountNumber !== "" && this.state.inputData.companyAccountNumber !== undefined && this.state.inputData.companyAccountNumber !== null) ) {
                            if (this.state.inputData.companyCbNumber.trim() !== "" || this.state.inputData.companyAccountNumber.trim() !== "") {
                                this.setState({
                                    whengetCbnumbernewAccountCreationOff: true
                                })
                            }

                        }
                       /!* if ((this.state.individualDedupeCheckbox && this.state.inputData.cbNumber !== "" && this.state.inputData.cbNumber !== undefined && this.state.inputData.cbNumber !== null) || (this.state.inputData.accountNumber !== "" && this.state.inputData.accountNumber !== undefined && this.state.inputData.accountNumber !== null)) {
                            if (this.state.inputData.cbNumber.trim() !== "") {
                                this.setState({
                                    whengetCbnumbernewAccountCreationOff: true
                                })
                            }
                        }*!/

                        if (this.state.individualDedupeCheckbox && this.state.inputData.dob !== undefined && this.state.inputData.dob !== null) {
                            let x = 18;
                            var currentDate = new Date();

                            let someDate = new Date();
                            let numberOfDaysToAdd = parseInt(x, 10)
                            someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                            let date = someDate.getDate();
                            let month = someDate.getMonth() + 1;
                            let year = someDate.getFullYear();
                            let convertDate = year + "-" + month + "-" + date;
                            let newDate = new Date(convertDate);
                            let stringToDate = new Date(newDate.toDateString());

                            let inputDate = new Date(this.state.inputData.dob);

                            if (stringToDate < inputDate) {
                                this.setState({
                                    /!* title:"Aplicant Minor",
                                     cbNotFound:true,*!/
                                    alert: true,
                                    notificationMessage: "Your Applicant Minor",
                                })
                                return 0;
                            }
                        }
                        error = MyValidationDedup.CustomValidationProprietorship(this.emptyStringSet(CSjsonFormNonIndividualProprietorshipAccountOpeningSearch), this.state)

                        if (this.state.individualDedupeCheckbox) {
                            errorForPropertership = MyValidationDedupeWithCompany.CustomValidationJoint(this.emptyStringSet(this.state.objectForJoinAccount), this.state)

                        }
                    }
            */
            else if (this.state.content === 'Partnership A/C' || this.state.content === 'Private/Public Limited Company Account' || this.state.content === 'NGO/NPO/Club/Society Account' || this.state.content === 'Educational/Religious Institution Account' || this.state.content === 'Government Account' || this.state.content === 'Other Account') {
                if ((this.state.inputData.companyCbNumber !== "" && this.state.inputData.companyCbNumber !== undefined && this.state.inputData.companyCbNumber !== null) || (this.state.inputData.companyAccountNumber !== "" && this.state.inputData.companyAccountNumber !== undefined && this.state.inputData.companyAccountNumber !== null)) {
                    if (this.state.inputData.companyCbNumber.trim() !== "" || this.state.inputData.companyAccountNumber.trim() !== "") {
                        this.setState({
                            whengetCbnumbernewAccountCreationOff: true
                        })
                    }

                }
                /*for (var i = 0; i < this.state.handleChangeJoint; i++) {
                    if ((this.state.inputData["cbNumber" + i] !== "" && this.state.inputData["cbNumber" + i] !== undefined && this.state.inputData["cbNumber" + i] !== null) || (this.state.inputData["accountNumber" + i] !== "" && this.state.inputData["accountNumber" + i] !== undefined && this.state.inputData["accountNumber" + i] !== null)) {
                        if (this.state.inputData["cbNumber" + i].trim() !== "" || this.state.inputData["accountNumber" + i].trim() !== "") {
                            this.setState({
                                whengetCbnumbernewAccountCreationOff: true
                            })
                        }

                    }
                }*/


                error = MyValidationDedup.CustomValidationCompany(this.emptyStringSet(CSjsonFormNonIndividualProprietorshipAccountOpeningSearch), this.state, this.state.content);
                errorForPropertership = MyValidationDedupeWithCompany.CustomValidationJoint(this.emptyStringSet(this.state.objectForJoinAccount), this.state)

            }
            if (this.state.inputData.mandate === "YES") {

                if (this.state.inputData.mandatedob !== undefined && this.state.inputData.mandatedob !== null) {
                    let x = 18;
                    var currentDate = new Date();

                    let someDate = new Date();
                    let numberOfDaysToAdd = parseInt(x, 10);
                    someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let convertDate = year + "-" + month + "-" + date;
                    let newDate = new Date(convertDate);
                    let stringToDate = new Date(newDate.toDateString());

                    let inputDate = new Date(this.state.inputData.mandatedob);

                    if (stringToDate < inputDate) {
                        this.setState({
                            /* title:"Aplicant Minor",
                             cbNotFound:true,*/
                            alert: true,
                            notificationMessage: "Mandate Applicant is Minor.Please Open Joint Account",
                        });
                        return 0;
                    }
                }

                errorMandate = MyValidationDedup.CustomValidationIndividualmandate((CSjsonFormIndividualAccountOpeningSearchmandate), this.state);

                console.log(this.state.inputData)
            }

            if (this.state.inputData.beneficiary === "YES") {

                if (this.state.inputData.beneficiarydob !== undefined && this.state.inputData.beneficiarydob !== null) {
                    let x = 18;
                    var currentDate = new Date();

                    let someDate = new Date();
                    let numberOfDaysToAdd = parseInt(x, 10);
                    someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let convertDate = year + "-" + month + "-" + date;
                    let newDate = new Date(convertDate);
                    let stringToDate = new Date(newDate.toDateString());

                    let inputDate = new Date(this.state.inputData.beneficiarydob);

                    if (stringToDate < inputDate) {
                        this.setState({
                            /* title:"Aplicant Minor",
                             cbNotFound:true,*/
                            alert: true,
                            notificationMessage: "Beneficiary Owner Applicant is Minor.Please Open Joint Account",
                        });
                        return 0;
                    }
                }

                errorBeneficiary = MyValidationDedup.CustomValidationIndividualbeneficiary((CSjsonFormIndividualAccountOpeningSearchmandate), this.state);

                console.log(this.state.inputData)
            }
            this.forceUpdate();
            if (error === true || errorBeneficiary === true || errorMandate === true || errorForPropertership === true) {
                this.setState({
                    loading: false,
                });
                return 0;

            } else {
                this.setState({
                    errorMessages: {},
                })

            }
            if (this.state.content === 'Partnership A/C' || this.state.content === 'Private/Public Limited Company Account' || this.state.content === 'NGO/NPO/Club/Society Account' || this.state.content === 'Educational/Religious Institution Account' || this.state.content === 'Government Account' || this.state.content === 'Other Account') {

                if (this.state.handleChangeJoint > 1) {

                } else {
                    return 0;
                }
            }


            if (this.state.content === 'Company Account' || this.state.content === 'Partnership A/C' || this.state.content === 'Private/Public Limited Company Account' || this.state.content === 'Other Account' || this.state.content === "NGO/NPO/Club/Society Account" || this.state.content === 'NONINDIVIDUAL' || this.state.content === 'Proprietorship A/C') {
                this.setState({
                    loading: true,
                    searchTableData: false,
                    searchTableRelatedData: false,
                    title: "Company CB Not Found",
                    getError: false,

                })
            } else {
                this.setState({
                    loading: true,
                    searchTableData: false,
                    searchTableRelatedData: false,
                    title: "Applicant CB Not Found",
                    getError: false,


                })
            }
            console.log(this.state.inputData);
            this.renderReloading(null);
        }
        else{
            this.setState({
                loading: true
            })
        }

        var postData = {};
        var postDatamandate = {};
        var postDatabeneficiary = {};
        var postDataProprietorship = {};
        var postDataProprietorshipa = [];

        if (this.state.content !== 'Partnership A/C' && this.state.content !== 'Private/Public Limited Company Account' && this.state.content !== 'NGO/NPO/Club/Society Account' && this.state.content !== 'Educational/Religious Institution Account' && this.state.content !== 'Government Account' && this.state.content !== 'Other Account') {
            var dateString;
            if (this.state.inputData.mandatedob !== undefined && this.state.inputData.mandatedob !== -1) {

                let da = this.state.inputData.mandatedob.split(",");


                if (da != "" && da.length > 0) {
                    let monthDateYear = da[0].split("/");
                    let month, date;

                    if (monthDateYear[0].length === 1)
                        month = "0" + monthDateYear[0];
                    else
                        month = monthDateYear[0];
                    if (monthDateYear[1].length === 1)
                        date = "0" + monthDateYear[1];
                    else date = monthDateYear[1];
                    dateString = monthDateYear[2] + "-" + month + "-" + date;
                } else {
                    dateString = "1000-01-01";
                }
            } else {
                dateString = "1000-01-01";
            }
            let mandatenid = "";

            mandatenid = this.state.inputData.mandatenid
            postDatamandate = {
                "cbNumber": this.trimFunctionInputData((this.state.inputData.mandatecbNumber)),
                "accountNumber": this.trimFunctionInputData((this.state.inputData.mandateaccountNumber)),
                "nid": this.trimFunctionInputData((mandatenid)),
                "smartCard": this.trimFunctionInputData(((this.state.inputData.mandatesmartCard))),
                "passport": this.trimFunctionInputData((this.state.inputData.mandatepassport)),
                "customerName": this.trimFunctionInputData((this.state.inputData.mandatecustomerName)),
                "dob": dateString,
                "gender": "",
                "fathersName": "",
                "mothersName": "",
                "email": this.trimFunctionInputData((this.state.inputData.mandateemail)),
                "nationality": this.trimFunctionInputData((this.state.inputData.mandatenationality)),
                "phone": this.getPhoneNumberData(this.trimFunctionInputData((this.state.inputData.mandatephone))),
                "tin": this.trimFunctionInputData((this.state.inputData.mandatetin)),
                "registrationNo": this.trimFunctionInputData((this.state.inputData.mandateregistrationNo)),
                "constitutionType": "",
                "sources": ["FINACLE", "ABABIL", "TRANZWARE"],

            };
            if (this.state.inputData.beneficiarydob !== undefined && this.state.inputData.beneficiarydob !== -1) {

                let da = this.state.inputData.beneficiarydob.split(",");


                if (da != "" && da.length > 0) {
                    let monthDateYear = da[0].split("/");
                    let month, date;

                    if (monthDateYear[0].length === 1)
                        month = "0" + monthDateYear[0];
                    else
                        month = monthDateYear[0];
                    if (monthDateYear[1].length === 1)
                        date = "0" + monthDateYear[1];
                    else date = monthDateYear[1];
                    dateString = monthDateYear[2] + "-" + month + "-" + date;
                } else {
                    dateString = "1000-01-01";
                }
            } else {
                dateString = "1000-01-01";
            }
            let beneficiarynid = "";

            beneficiarynid = this.state.inputData.beneficiarynid

            postDatabeneficiary = {
                "cbNumber": this.trimFunctionInputData((this.state.inputData.beneficiarycbNumber)),
                "accountNumber": this.trimFunctionInputData((this.state.inputData.beneficiaryaccountNumber)),
                "nid": this.trimFunctionInputData((beneficiarynid)),
                "smartCard": this.trimFunctionInputData(((this.state.inputData.beneficiarysmartCard))),
                "passport": this.trimFunctionInputData((this.state.inputData.beneficiarypassport)),
                "customerName": this.trimFunctionInputData((this.state.inputData.beneficiarycustomerName)),
                "dob": dateString,
                "gender": "",
                "fathersName": "",
                "mothersName": "",
                "email": this.trimFunctionInputData((this.state.inputData.beneficiaryemail)),
                "nationality": this.trimFunctionInputData((this.state.inputData.beneficiarynationality)),
                "phone": this.getPhoneNumberData(this.trimFunctionInputData((this.state.inputData.beneficiaryphone))),
                "tin": this.trimFunctionInputData((this.state.inputData.beneficiarytin)),
                "registrationNo": this.trimFunctionInputData((this.state.inputData.beneficiaryregistrationNo)),
                "constitutionType": "",
                "sources": ["FINACLE", "ABABIL", "TRANZWARE"],

            };

        }

        if (this.state.content === 'INDIVIDUAL' || this.state.content === 'Individual A/C') {
            var dateString;
            if (this.state.inputData.dob !== undefined && this.state.inputData.dob !== -1) {

                let da = this.state.inputData.dob.split(",");


                if (da != "" && da.length > 0) {
                    let monthDateYear = da[0].split("/");
                    let month, date;

                    if (monthDateYear[0].length === 1)
                        month = "0" + monthDateYear[0];
                    else
                        month = monthDateYear[0];
                    if (monthDateYear[1].length === 1)
                        date = "0" + monthDateYear[1];
                    else date = monthDateYear[1];
                    dateString = monthDateYear[2] + "-" + month + "-" + date;
                } else {
                    dateString = "1000-01-01";
                }
            } else {
                dateString = "1000-01-01";
            }
            let nid = "";

            nid = this.state.inputData.nid

            postData = {
                "cbNumber": this.trimFunctionInputData((this.state.inputData.cbNumber)),
                "accountNumber": this.trimFunctionInputData((this.state.inputData.accountNumber)),
                "nid": this.trimFunctionInputData((nid)),
                "smartCard": this.trimFunctionInputData(((this.state.inputData.smartCard))),
                "passport": this.trimFunctionInputData((this.state.inputData.passport)),
                "customerName": this.trimFunctionInputData((this.state.inputData.customerName)),
                "dob": dateString,
                "gender": "",
                "fathersName": "",
                "mothersName": "",
                "email": this.trimFunctionInputData((this.state.inputData.email)),
                "nationality": this.trimFunctionInputData((this.state.inputData.nationality)),
                "phone": this.getPhoneNumberData(this.trimFunctionInputData((this.state.inputData.phone))),
                "tin": this.trimFunctionInputData((this.state.inputData.tin)),
                "registrationNo": this.trimFunctionInputData((this.state.inputData.registrationNo)),
                "constitutionType": "",
                "sources": ["FINACLE", "ABABIL", "TRANZWARE"],

            };

        } else if (this.state.content === 'Joint Account') {

            let objectForJoinAccount = {"customerInfoList": []};
            for (let j = 0; j < this.state.handleChangeJoint; j++) {

                if (this.state.inputData["dob" + j] !== undefined && this.state.inputData["dob" + j] !== -1) {
                    let da = this.state.inputData["dob" + j].split(",");

                    var dateString;

                    if (da != "" && da.length > 0) {

                        let monthDateYear = da[0].split("/");
                        let month, date;

                        if (monthDateYear[0].length === 1)
                            month = "0" + monthDateYear[0];
                        else
                            month = monthDateYear[0];
                        if (monthDateYear[1].length === 1)
                            date = "0" + monthDateYear[1];
                        else date = monthDateYear[1];
                        dateString = monthDateYear[2] + "-" + month + "-" + date;

                    } else {
                        dateString = "1000-01-01";
                    }
                } else {
                    dateString = "1000-01-01";
                }
                let nid = "";

                nid = this.state.inputData["nid" + j]

                objectForJoinAccount.customerInfoList.push({
                    "cbNumber": this.trimFunctionInputData((this.state.inputData["cbNumber" + j])),
                    "accountNumber": this.trimFunctionInputData((this.state.inputData["accountNumber" + j])),
                    "nid": this.trimFunctionInputData((nid)),
                    "smartCard": this.trimFunctionInputData(((this.state.inputData["smartCard" + j]))),
                    "passport": this.trimFunctionInputData((this.state.inputData["passport" + j])),
                    "customerName": this.trimFunctionInputData((this.state.inputData["customerName" + j])),
                    "dob": dateString,
                    "gender": "",
                    "fathersName": "",
                    "mothersName": "",
                    "nationality": this.trimFunctionInputData((this.state.inputData["nationality" + j])),
                    "email": this.trimFunctionInputData((this.state.inputData["email" + j])),
                    "phone": this.getPhoneNumberData(this.trimFunctionInputData((this.state.inputData["phone" + j]))),
                    "tin": this.trimFunctionInputData((this.state.inputData["tin" + j])),
                    "registrationNo": this.trimFunctionInputData((this.state.inputData["registrationNo" + j])),
                    "constitutionType": "",
                    "sources": ["FINACLE", "ABABIL", "TRANZWARE"]
                })

            }

            postData = objectForJoinAccount;

        } else if (this.state.content === 'NONINDIVIDUAL' || this.state.content === 'Proprietorship A/C') {
            let dateString;
            if (this.state.inputData.dob !== undefined && this.state.inputData.dob !== -1) {
                let da = this.state.inputData.dob.split(",");


                if (da != "" && da.length > 0) {
                    let monthDateYear = da[0].split("/");
                    let month, date;

                    if (monthDateYear[0].length === 1)
                        month = "0" + monthDateYear[0];
                    else
                        month = monthDateYear[0];
                    if (monthDateYear[1].length === 1)
                        date = "0" + monthDateYear[1];
                    else date = monthDateYear[1];
                    dateString = monthDateYear[2] + "-" + month + "-" + date;
                } else {
                    dateString = "1000-01-01";
                }
            } else {
                dateString = "1000-01-01";
            }
            postDataProprietorship = {
                "cbNumber": this.trimFunctionInputData((this.state.inputData.companyCbNumber)),
                "accountNumber": this.trimFunctionInputData((this.state.inputData.companyAccountNumber)),
                "nid": "",
                "smartCard": "",
                "passport": "",
                "customerName": this.trimFunctionInputData((this.state.inputData.companyName)),
                "gender": "",
                "dob": dateString,
                "fathersName": "",
                "mothersName": "",
                "nationality": this.trimFunctionInputData((this.state.inputData.nationality)),
                "email": this.trimFunctionInputData((this.state.inputData.companyEmail)),
                "phone": this.getPhoneNumberData(this.trimFunctionInputData((this.state.inputData.companyMobileNo))),
                "tradelisence": this.trimFunctionInputData((this.state.inputData.companyTradeLicense)),
                "registrationNumber": this.trimFunctionInputData((this.state.inputData.registrationNumber)),
                "certificateOfIncorporation": this.trimFunctionInputData((this.state.inputData.certificateOfIncorporation)),
                "certificateOfCommencement": this.trimFunctionInputData((this.state.inputData.certificateOfCommencement)),
                "ngoBureauCertificate": this.trimFunctionInputData((this.state.inputData.ngoBureauCertificate)),
                "societyRegistrationNumber": this.trimFunctionInputData((this.state.inputData.societyRegistrationNumber)),
                "clubRegistrationNumber": this.trimFunctionInputData((this.state.inputData.clubRegistrationNumber)),
                "governmentPermissionLetter": this.trimFunctionInputData((this.state.inputData.governmentPermissionLetter)),
                "otherIdCorporate": this.trimFunctionInputData((this.state.inputData.otherIdCorporate)),
                "tin": this.trimFunctionInputData((this.state.inputData.companyEtin)),
                "registrationNo": "",
                "constitutionType": this.props.companyDedupeBlock === true ? "companyDedupe" : "",
                "sources": ["FINACLE", "ABABIL", "TRANZWARE"],

            };
            let nid = "";
            nid = this.state.inputData.nid

            postData = {
                "cbNumber": this.trimFunctionInputData((this.state.inputData.cbNumber)),
                "accountNumber": this.trimFunctionInputData((this.state.inputData.accountNumber)),
                "nid": this.trimFunctionInputData((nid)),
                "smartCard": this.trimFunctionInputData(((this.state.inputData.smartCard))),
                "passport": this.trimFunctionInputData((this.state.inputData.passport)),
                "customerName": this.trimFunctionInputData((this.state.inputData.customerName)),
                "dob": dateString,
                "gender": "",
                "fathersName": "",
                "mothersName": "",
                "nationality": this.trimFunctionInputData((this.state.inputData.nationality)),
                "email": this.trimFunctionInputData((this.state.inputData.email)),
                "phone": this.getPhoneNumberData(this.trimFunctionInputData((this.state.inputData.phone))),
                "tin": this.trimFunctionInputData((this.state.inputData.tin)),
                "registrationNo": this.trimFunctionInputData((this.state.inputData.registrationNo)),
                "constitutionType": this.props.companyDedupeBlock === true ? "companyDedupe" : "",
                "sources": ["FINACLE", "ABABIL", "TRANZWARE"],
                //if(this.props.companyDedupeBlock===true && this.state.content==="NONINDIVIDUAL" ){
                //  }
            };

        } else if (this.state.content === 'Partnership A/C' || this.state.content === 'Private/Public Limited Company Account' || this.state.content === 'NGO/NPO/Club/Society Account' || this.state.content === 'Educational/Religious Institution Account' || this.state.content === 'Government Account' || this.state.content === 'Other Account') {
            let objectForJoinAccount = {"customerInfoList": []};
            for (let j = 0; j < this.state.handleChangeJoint; j++) {
                let dateString;
                if (this.state.inputData["dob" + j] !== undefined && this.state.inputData["dob" + j] !== -1) {

                    let da = this.state.inputData["dob" + j].split(",");


                    if (da != "" && da.length > 0) {
                        let monthDateYear = da[0].split("/");
                        let month, date;

                        if (monthDateYear[0].length === 1)
                            month = "0" + monthDateYear[0];
                        else
                            month = monthDateYear[0];
                        if (monthDateYear[1].length === 1)
                            date = "0" + monthDateYear[1];
                        else date = monthDateYear[1];
                        dateString = monthDateYear[2] + "-" + month + "-" + date;
                    } else {
                        dateString = "1000-01-01";
                    }
                } else {
                    dateString = "1000-01-01";
                }

                let nid = "";

                nid = this.state.inputData["nid" + j]

                objectForJoinAccount.customerInfoList.push({
                    "cbNumber": this.trimFunctionInputData((this.state.inputData["cbNumber" + j])),
                    "accountNumber": this.trimFunctionInputData((this.state.inputData["accountNumber" + j])),
                    "nid": this.trimFunctionInputData((nid)),
                    "smartCard": this.trimFunctionInputData(((this.state.inputData["smartCard" + j]))),
                    "passport": this.trimFunctionInputData((this.state.inputData["passport" + j])),
                    "customerName": this.trimFunctionInputData((this.state.inputData["customerName" + j])),
                    "dob": dateString,
                    "gender": "",
                    "fathersName": "",
                    "mothersName": "",
                    "nationality": this.trimFunctionInputData((this.state.inputData["nationality" + j])),
                    "email": this.trimFunctionInputData((this.state.inputData["email" + j])),
                    "phone": this.getPhoneNumberData(this.trimFunctionInputData((this.state.inputData["phone" + j]))),
                    "tin": this.trimFunctionInputData((this.state.inputData["tin" + j])),
                    "registrationNo": this.trimFunctionInputData((this.state.inputData["registrationNo" + j])),
                    "constitutionType": "",
                    "sources": ["FINACLE", "ABABIL", "TRANZWARE"]
                })

            }

            postData = objectForJoinAccount;

            postDataProprietorship = {
                "cbNumber": this.trimFunctionInputData((this.state.inputData.companyCbNumber)),
                "accountNumber": this.trimFunctionInputData((this.state.inputData.companyAccountNumber)),
                "nid": "",
                "smartCard": "",
                "passport": "",
                "customerName": this.trimFunctionInputData((this.state.inputData.companyName)),
                "gender": "",
                "fathersName": "",
                "mothersName": "",
                "dob": "1000-01-01",
                "email": this.trimFunctionInputData((this.state.inputData.companyEmail)),
                "nationality": this.trimFunctionInputData((this.state.inputData.nationality)),
                "phone": this.getPhoneNumberData(this.trimFunctionInputData((this.state.inputData.companyMobileNo))),
                //
                "tradelisence": this.trimFunctionInputData((this.state.inputData.companyTradeLicense)),
                "registrationNumber": this.trimFunctionInputData((this.state.inputData.registrationNumber)),
                "certificateOfIncorporation": this.trimFunctionInputData((this.state.inputData.certificateOfIncorporation)),
                "certificateOfCommencement": this.trimFunctionInputData((this.state.inputData.certificateOfCommencement)),
                "ngoBureauCertificate": this.trimFunctionInputData((this.state.inputData.ngoBureauCertificate)),
                "societyRegistrationNumber": this.trimFunctionInputData((this.state.inputData.societyRegistrationNumber)),
                "clubRegistrationNumber": this.trimFunctionInputData((this.state.inputData.clubRegistrationNumber)),
                "governmentPermissionLetter": this.trimFunctionInputData((this.state.inputData.governmentPermissionLetter)),
                "otherIdCorporate": this.trimFunctionInputData((this.state.inputData.otherIdCorporate)),
                "tin": this.trimFunctionInputData((this.state.inputData.companyEtin)),
                "registrationNo": "",
                "constitutionType": "",
                "sources": ["FINACLE", "ABABIL", "TRANZWARE"],

            };


        }
        /*   else if (this.state.content === "NGO/NPO/Club/Society Account") {
               let objectForJoinAccount = {"customerInfoList": []};
               for (let j = 0; j < this.state.handleChangeJoint; j++) {
                   let dateString;
                   if (this.state.inputData["dob" + j] !== undefined && this.state.inputData["dob" + j] !== -1) {

                       let da = this.state.inputData["dob" + j].split(",");


                       if (da != "" && da.length > 0) {
                           let monthDateYear = da[0].split("/");
                           let month, date;

                           if (monthDateYear[0].length === 1)
                               month = "0" + monthDateYear[0];
                           else
                               month = monthDateYear[0];
                           if (monthDateYear[1].length === 1)
                               date = "0" + monthDateYear[1];
                           else date = monthDateYear[1];
                           dateString = monthDateYear[2] + "-" + month + "-" + date;
                       } else {
                           dateString = "1000-01-01";
                       }
                   } else {
                       dateString = "1000-01-01";
                   }

                   let nid = "";
                   if (this.state.inputData["smartCard" + j] !== undefined && this.state.inputData["smartCard" + j].length > 9) {
                       nid = (this.state.inputData["smartCard" + j]).trim()
                   } else {
                       nid = this.state.inputData["nid" + j]
                   }
                   objectForJoinAccount.customerInfoList.push({
                       "cbNumber": this.trimFunctionInputData((this.state.inputData["cbNumber" + j])),
                       "accountNumber": this.trimFunctionInputData((this.state.inputData["accountNumber" + j])),
                       "nid": this.trimFunctionInputData((nid)),
                       "passport": this.trimFunctionInputData((this.state.inputData["passport" + j])),
                       "customerName": this.trimFunctionInputData((this.state.inputData["customerName" + j])),
                       "dob": dateString,
                       "gender": "",
                       "fathersName": "",
                       "mothersName": "",
                       "nationality": this.trimFunctionInputData((this.state.inputData["nationality" + j])),
                       "email": this.trimFunctionInputData((this.state.inputData["email" + j])),
                       "phone": "88" + this.trimFunctionInputData((this.state.inputData["phone" + j])),
                       "tin": this.trimFunctionInputData((this.state.inputData["tin" + j])),
                       "registrationNo": this.trimFunctionInputData((this.state.inputData["registrationNo" + j])),
                       "constitutionType": "",
                       "sources": ["FINACLE", "ABABIL", "TRANZWARE"]
                   })

               }

               postData = objectForJoinAccount;

               postDataProprietorship = {
                   "cbNumber": "",
                   "accountNumber": "",
                   "nid": "",
                   "passport": "",
                   "customerName": this.trimFunctionInputData((this.state.inputData.companyName)),
                   "gender": "",
                   "fathersName": "",
                   "mothersName": "",
                   "dob": "1000-01-01",
                   "email": this.trimFunctionInputData((this.state.inputData.companyEmail)),
                   "nationality": this.trimFunctionInputData((this.state.inputData.nationality)),
                   "phone": "88" + this.trimFunctionInputData((this.state.inputData.companyMobileNo)),
                   "tin": "",
                   "registrationNo": "",
                   "constitutionType": "",
                   "sources": ["FINACLE", "ABABIL", "TRANZWARE"],

               };


           }*/

        this.renderReloading(false);
        if (this.state.individualDedupeCheckbox) {
            this.setState({
                individualDedupeCheckboxNewButton: true
            })
        }

        let searchInputData = this.emptyStringSet(this.state.inputData);
        if (this.state.content === 'Joint Account') {
            let tableArray = [];
            let relatedTableArray = [];

            //set 02/02/1983, 00:00:00 to 1983-02-02 format

            let url = backEndServerURL + "/jointDedup/JNT";
            console.log(postData);

            axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                        console.log(response.data);
                        this.setState({
                            sourceMappingData: response.data.sourceMapping,
                            jointDedupData: response.data

                        });

                        let jointDedupSearchTable = [];
                        let sdnTableArray = [];
                        var notFound = 0;
                        for (let i = 0; i < this.state.handleChangeJoint; i++) {
                            let number = i + 1;
                            if (this.state.inputData["cbNumber" + i] !== "" && this.state.inputData["cbNumber" + i] !== undefined && this.state.inputData["cbNumber" + i] !== null && !validator.isEmpty(this.trimFunction(this.state.inputData["cbNumber" + i])) && (response.data.highMatchCustomers[i].length < 1 && response.data.mediumMatchCustomers[i].length < 1)) {
                                this.setState({
                                    cbNotFound: true,
                                    getError: true,

                                    title: this.numberToCharacter(number) + " Applicant " + " CB  / Account Not Found",
                                    getResultPropritor: true,
                                    searchTableData: false,
                                    loading: false,
                                    jointSearchTable: [],
                                    relatedData: [],
                                    searchTableSdnData: false,
                                    searchTableRelatedData: false,
                                });
                                notFound = this.state.handleChangeJoint;
                            }

                        }
                        if (notFound !== this.state.handleChangeJoint) {
                            if (response.data.highMatchCustomers !== null && Array.isArray(response.data.highMatchCustomers)) {

                                var randomNumber = 0;

                                response.data.highMatchCustomers.map((dedup, i) => {
                                    let customer = [];
                                    randomNumber = (randomNumber + 1);

                                    dedup.map((dedup) => {
                                        customer.push(this.createTableData(randomNumber, dedup.id, dedup.identification_no_mod, dedup.maincustid, dedup.sz_contitution, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, dedup.matched_by, "Higher-Match"));
                                    });

                                    if (response.data.mediumMatchCustomers !== null && Array.isArray(response.data.mediumMatchCustomers)) {

                                        let medium = response.data.mediumMatchCustomers[i];
                                        medium.map((medium) => {
                                            customer.push(this.createTableData(randomNumber, medium.id, medium.identification_no_mod, medium.maincustid, medium.sz_contitution, medium.sz_source, medium.sz_full_name, medium.sz_national_id, medium.sz_passport, medium.sz_tin_number, medium.cust_comu_phone_num, medium.dt_birth, medium.email_id, medium.sz_registration_no, medium.matched_by, "Medium-Match"));

                                        })

                                    }


                                    jointDedupSearchTable.push(customer);

                                });


                            }
                        }


                        console.log(jointDedupSearchTable);
                        console.log(jointDedupSearchTable);

                        if (!this.state.cbNotFound) {
                            if (response.data.relatedCustomers !== null && Array.isArray(response.data.relatedCustomers)) {
                                response.data.relatedCustomers.map((dedup) => {

                                    relatedTableArray.push(this.createRelatedTableData(dedup.id,/*dedup.mainCB,*/ dedup.identificationNoMod, dedup.mainCB, dedup.relatedCB, dedup.relatedCustomerName, dedup.relationship));

                                });
                            }
                            if (response.data.sdnResult !== null && Array.isArray(response.data.sdnResult)) {

                                response.data.sdnResult.map((data, i) => {
                                    let sdnTableList = [];
                                    if (Array.isArray(data)) {
                                        data.map((data) => {

                                            sdnTableList.push(this.createSdnTableData(data.country, data.dob, data.matchType, data.name, data.type));
                                        });
                                        sdnTableArray.push(sdnTableList);
                                    }


                                });


                            }

                        }
                        console.log(sdnTableArray);
                        this.setState({

                            jointSearchTable: jointDedupSearchTable,
                            relatedData: relatedTableArray,
                            sdnData: sdnTableArray,
                            searchTableData: true,
                            searchTableSdnData: true,
                            searchTableRelatedData: true,
                            loading: false,
                            getsearchValue: this.state.inputData,

                        })
                    }
                )
                .catch((error) => {

                    this.setState({
                        cbNotFound: true,
                        searchTableData: false,
                        searchTableRelatedData: false,
                        loading: false,
                        getError: true,
                        title: "Network Issue,Please Check Your Network",

                        jointSearchTable: [],
                        relatedData: [],
                        sdnData: [],
                        searchTableSdnData: true,

                    })

                });
            {
                this.mandateAndBeneficiaryApiCall(postDatamandate, postDatabeneficiary)
            }
        } else if (this.state.content === 'Existing Joint CB /  Account') {

            let tableArray = [];
            let relatedTableArray = [];

            //set 02/02/1983, 00:00:00 to 1983-02-02 format
            let jointCbNumber = this.state.inputData.jointCbNumber;
            if (jointCbNumber === undefined || jointCbNumber === "") {
                let s1 = (this.state.inputData.jointAccountNumber).toString();
                let s2 = s1.substr(3);
                let cbData = s2.substring(0, s2.length - 3);
                jointCbNumber = "CB" + cbData;
            }
            let url = backEndServerURL + "/jointDedup/WithJointCb/" + jointCbNumber;

            axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.WithJointCb = true;
                    this.forceUpdate();
                    this.setState({
                        WithJointCb: true,
                        handleChangeJoint: response.data.noOfRelatedCustomer,
                        sourceMappingData: response.data.sourceMapping,
                        jointDedupData: response.data

                    });

                    let jointDedupSearchTable = [];
                    let sdnTableArray = [];
                    var notFound = 0;
                    if (this.state.inputData.jointCbNumber !== "" && this.state.inputData.jointCbNumber !== undefined && !validator.isEmpty(this.trimFunction(this.state.inputData["jointCbNumber"])) && (response.data.highMatchCustomers.length < 1 && response.data.mediumMatchCustomers.length < 1 && response.data.relatedCustomers.length < 1)) {
                        this.setState({
                            cbNotFound: true,
                            searchTableData: false,
                            searchTableRelatedData: false,
                            loading: false,
                            getError: true,
                            title: "Applicant CB  / Account Not Found"
                        });
                        {
                            this.reloadOff()
                        }
                    } else {
                        if (response.data.highMatchCustomers !== null && Array.isArray(response.data.highMatchCustomers)) {

                            var randomNumber = 0;

                            response.data.highMatchCustomers.map((dedup, i) => {
                                let customer = [];
                                randomNumber = (randomNumber + 1);

                                dedup.map((dedup) => {
                                    customer.push(this.createTableData(randomNumber, dedup.id, dedup.identification_no_mod, dedup.maincustid, dedup.sz_contitution, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, dedup.matched_by, "Higher-Match"));
                                });

                                if (response.data.mediumMatchCustomers !== null && Array.isArray(response.data.mediumMatchCustomers)) {

                                    let medium = response.data.mediumMatchCustomers[i];
                                    medium.map((medium) => {
                                        customer.push(this.createTableData(randomNumber, medium.id, medium.identification_no_mod, medium.maincustid, medium.sz_contitution, medium.sz_source, medium.sz_full_name, medium.sz_national_id, medium.sz_passport, medium.sz_tin_number, medium.cust_comu_phone_num, medium.dt_birth, medium.email_id, medium.sz_registration_no, medium.matched_by, "Medium-Match"));

                                    })

                                }


                                jointDedupSearchTable.push(customer);

                            });


                        }
                        if (!this.state.cbNotFound) {
                            if (response.data.relatedCustomers.highMatchCustomers !== null && Array.isArray(response.data.relatedCustomers.highMatchCustomers)) {
                                response.data.relatedCustomers.highMatchCustomers.map((dedup) => {

                                    relatedTableArray.push(this.createRelatedTableData(dedup.id,/*dedup.mainCB,*/ dedup.identification_no_mod, dedup.sz_contitution, dedup.maincustid, dedup.sz_source, dedup.sz_full_name));

                                });
                            }
                            if (response.data.sdnResult !== null && Array.isArray(response.data.sdnResult)) {

                                response.data.sdnResult.map((data, i) => {
                                    let sdnTableList = [];
                                    if (Array.isArray(data)) {
                                        data.map((data) => {

                                            sdnTableList.push(this.createSdnTableData(data.country, data.dob, data.matchType, data.name, data.type));
                                        });
                                        sdnTableArray.push(sdnTableList);
                                    }


                                });


                            }

                        }
                        console.log(sdnTableArray);
                        this.setState({

                            jointSearchTable: jointDedupSearchTable,
                            relatedData: relatedTableArray,
                            sdnData: sdnTableArray,
                            searchTableData: true,
                            searchTableSdnData: true,
                            searchTableRelatedData: true,
                            loading: false,
                            getsearchValue: this.state.inputData,

                        })
                    }
                })
                .catch((error) => {

                    this.setState({
                        cbNotFound: true,
                        searchTableData: false,
                        searchTableRelatedData: false,
                        loading: false,
                        getError: true,
                        title: "Network Issue,Please Check Your Network",

                        jointSearchTable: [],
                        relatedData: [],
                        sdnData: [],
                        searchTableSdnData: true,

                    })

                });
            {
                this.mandateAndBeneficiaryApiCall(postDatamandate, postDatabeneficiary)
            }
        } else {
            if (this.state.content === 'Partnership A/C' || this.state.content === 'Private/Public Limited Company Account' || this.state.content === 'NGO/NPO/Club/Society Account' || this.state.content === 'Educational/Religious Institution Account' || this.state.content === 'Government Account' || this.state.content === 'Other Account') {
                //set 02/02/1983, 00:00:00 to 1983-02-02 format
                let tableArray = [];
                let relatedTableArray = [];
                let sdnTableArray = [];
                let url = backEndServerURL + "/dedupWithSdn";
                axios.post(url, postDataProprietorship, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        console.log(response.data);
                        this.setState({
                            sourceMappingData: response.data.sourceMapping,
                            companyDedupData: response.data

                        });

                        if (this.state.inputData["companyCbNumber"] !== "" && this.state.inputData["companyCbNumber"] !== undefined && this.state.inputData["companyCbNumber"] !== null && !validator.isEmpty(this.trimFunction(this.state.inputData["companyCbNumber"])) && (response.data.highMatchCustomers.length < 1 && response.data.mediumMatchCustomers.length < 1)) {
                            this.setState({
                                getError: true,
                                cbNotFound: true,
                                searchTableData: false,
                                title: "Company CB  / Account Not Found",
                                searchTableRelatedData: false,
                                getCompnayData: false,


                            });
                            {
                                this.reloadOff()
                            }
                        } else {

                            if (response.data.highMatchCustomers !== null && Array.isArray(response.data.highMatchCustomers)) {

                                response.data.highMatchCustomers.map((dedup) => {
                                    tableArray.push(this.createTableproCompany(dedup.id, dedup.identification_no_mod, dedup.maincustid, dedup.sz_contitution, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, dedup.matched_by, "Higher-Match"));

                                });

                            }

                            if (response.data.mediumMatchCustomers !== null && Array.isArray(response.data.mediumMatchCustomers)) {

                                response.data.mediumMatchCustomers.map((dedup) => {

                                    tableArray.push(this.createTableproCompany(dedup.id, dedup.identification_no_mod, dedup.maincustid, dedup.sz_contitution, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, dedup.matched_by, "Medium-Match"));

                                });
                            }

                            if (response.data.relatedCustomers !== null && Array.isArray(response.data.relatedCustomers)) {
                                response.data.relatedCustomers.map((dedup) => {

                                    relatedTableArray.push(this.createRelatedTableData(dedup.id, dedup.identificationNoMod, dedup.mainCB, dedup.relatedCB, dedup.relatedCustomerName, dedup.relationship));

                                });
                            }
                            if (response.data.sdnResult !== null && Array.isArray(response.data.sdnResult)) {
                                response.data.sdnResult.map((data) => {
                                    sdnTableArray.push(this.createSdnTableData(data.country, data.dob, data.matchType, data.name, data.type));

                                });
                            }
                            console.log(sdnTableArray);
                            this.setState({
                                propritorshipData: tableArray,
                                relatedData: relatedTableArray,
                                sdnData: sdnTableArray,
                                searchTableSdnData: true,
                                searchTableData: true,
                                downloadDataForCompany: true,
                                getsearchValue: this.state.inputData,
                                getCompnayData: false,

                            });
                            {
                                this.reloadOff()
                            }

                        }

                        this.companyJointDedupeSearch(postData)

                    })
                    .catch((error) => {

                        this.setState({
                            loading: false,
                            propritorshipData: [],
                            relatedData: [],
                            sdnData: [],
                            searchTableData: false,
                            searchTableSdnData: true,
                            searchTableRelatedData: false,
                            getCompanyData: false,
                        });
                        {
                            this.reloadOff()
                        }
                    });

            }
            /* else if (this.state.content === "NGO/NPO/Club/Society Account") {
                 //set 02/02/1983, 00:00:00 to 1983-02-02 format
                 let tableArray = [];
                 let relatedTableArray = [];
                 let sdnTableArray = [];
                 let url = backEndServerURL + "/dedupWithSdn";
                 axios.post(url, postDataProprietorship, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                     .then((response) => {

                         console.log(response.data)
                         /!*  this.setState({
                               sourceMappingData: response.data.sourceMapping,
                               companyDedupData: response.data

                           });*!/


                       if (response.data.highMatchCustomers !== null && Array.isArray(response.data.highMatchCustomers)) {

                              response.data.highMatchCustomers.map((dedup) => {
                                  tableArray.push(this.createTableproCompany(dedup.id, dedup.identification_no_mod, dedup.maincustid, dedup.sz_contitution, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, dedup.matched_by, "Higher-Match"));

                              });

                          }

                          if (response.data.mediumMatchCustomers !== null && Array.isArray(response.data.mediumMatchCustomers)) {

                              response.data.mediumMatchCustomers.map((dedup) => {

                                  tableArray.push(this.createTableproCompany(dedup.id, dedup.identification_no_mod, dedup.maincustid, dedup.sz_contitution, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, dedup.matched_by, "Medium-Match"));

                              });
                          }

                          if (response.data.relatedCustomers !== null && Array.isArray(response.data.relatedCustomers)) {
                              response.data.relatedCustomers.map((dedup) => {

                                  relatedTableArray.push(this.createRelatedTableData(dedup.id, dedup.identificationNoMod, dedup.mainCB, dedup.relatedCB, dedup.relatedCustomerName, dedup.relationship));

                              });
                          }
                          if (response.data.sdnResult !== null && Array.isArray(response.data.sdnResult)) {
                              response.data.sdnResult.map((data) => {
                                  sdnTableArray.push(this.createSdnTableData(data.country, data.dob, data.matchType, data.name, data.type));

                              });
                          }
                         this.setState({

                             propritorshipData: tableArray,
                             relatedData: relatedTableArray,
                             sdnData: sdnTableArray,
                             searchTableSdnData: true,
                             searchTableData: true,
                             downloadDataForCompany: true,
                             getsearchValue: this.state.inputData,
                             getCompnayData: false,

                         })
                        /!* {
                             this.reloadOff()
                         }*!/


                     })
                     .catch((error) => {

                         this.setState({
                             loading:false,
                             propritorshipData: [],
                             relatedData: [],
                             sdnData: [],
                             searchTableData: false,
                             searchTableSdnData: true,
                             searchTableRelatedData: false,
                             getCompanyData: false,
                         })
                         {
                             this.reloadOff()
                         }
                     });

                 this.companyJointDedupeSearch(postData)


             }*/
            else if (this.state.content === 'NONINDIVIDUAL' || this.state.content === 'Proprietorship A/C') {

                let tableArray = [];
                let relatedTableArray = [];
                let sdnTableArray = [];
                let sdnDataForNonIndividual = [];

                //set 02/02/1983, 00:00:00 to 1983-02-02 format

                let url = backEndServerURL + "/dedupWithSdn";

                console.log("com");
                console.log(postDataProprietorship);
                axios.post(url, postDataProprietorship, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        console.log(response.data);
                        this.setState({
                            sourceMappingData: response.data.sourceMapping,
                            companyDedupData: response.data,


                        });
                        if (this.state.inputData["companyCbNumber"] !== "" && this.state.inputData["companyCbNumber"] !== undefined && this.state.inputData["companyCbNumber"] !== null && !validator.isEmpty(this.trimFunction(this.state.inputData["companyCbNumber"])) && (response.data.highMatchCustomers.length < 1 && response.data.mediumMatchCustomers.length < 1)) {

                            this.setState({
                                cbNotFound: true,
                                getError: true,
                                title: "Company CB  / Account Not Found",
                                getResultPropritor: true,
                                searchTableData: false,
                                searchTableRelatedData: false,
                                getCompnayData: false,

                            });
                            {
                                this.reloadOff()
                            }
                        } else {

                            if (response.data.highMatchCustomers !== null && Array.isArray(response.data.highMatchCustomers)) {

                                response.data.highMatchCustomers.map((dedup) => {
                                    tableArray.push(this.createTableproCompany(dedup.id, dedup.identification_no_mod, dedup.maincustid, dedup.sz_contitution, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, dedup.matched_by, "Higher-Match"));

                                });

                            }

                            if (response.data.mediumMatchCustomers !== null && Array.isArray(response.data.mediumMatchCustomers)) {

                                response.data.mediumMatchCustomers.map((dedup) => {

                                    tableArray.push(this.createTableproCompany(dedup.id, dedup.identification_no_mod, dedup.maincustid, dedup.sz_contitution, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, dedup.matched_by, "Medium-Match"));

                                });
                            }
                            if (response.data.sdnResult !== null && Array.isArray(response.data.sdnResult)) {
                                response.data.sdnResult.map((data) => {
                                    sdnTableArray.push(this.createSdnTableData(data.country, data.dob, data.matchType, data.name, data.type));

                                });
                            }
                            if (response.data.relatedCustomers !== null && Array.isArray(response.data.relatedCustomers)) {
                                response.data.relatedCustomers.map((dedup) => {

                                    relatedTableArray.push(this.createRelatedTableData(dedup.id, dedup.identificationNoMod, dedup.mainCB, dedup.relatedCB, dedup.relatedCustomerName, dedup.relationship));

                                });
                            }
                            this.setState({

                                propritorshipData: tableArray,
                                relatedData: relatedTableArray,
                                sdnData: sdnTableArray,
                                searchTableSdnData: true,
                                searchTableData: true,
                                downloadDataForCompany: true,
                                getsearchValue: this.state.inputData,
                                getCompnayData: false,

                            });
                            {
                                this.reloadOff()
                            }
                        }
                        let tableArrayProprietor = [];
                        let relatedTableArrayProprietor = [];
                        if (this.props.companyDedupeBlock === true) {
                            this.setState({
                                loading: false,
                                companyDedupeBlock: true,
                                dedupData: tableArrayProprietor,
                                relatedData: relatedTableArrayProprietor,
                                sdnData: sdnTableArray,
                                sdnDataForNonIndividual: sdnDataForNonIndividual,
                                searchTableSdnData: true,
                                searchTableData: true,
                                downloadDataForCompanyProprietor: true,
                                // searchTableRelatedData: true,
                                getsearchValue: this.state.inputData,
                                getPropritorData: false
                            })
                        } else if (this.state.individualDedupeCheckbox) {
                            url = backEndServerURL + "/dedupWithSdn";
                            console.log("dedup data");
                            console.log(postData);
                            axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    console.log(response.data);
                                    this.setState({
                                        sourceMappingData: response.data.sourceMapping,
                                        individualDedupData: response.data
                                    });
                                    if (this.state.inputData["cbNumber"] !== "" && this.state.inputData["cbNumber"] !== undefined && this.state.inputData["cbNumber"] !== null && !validator.isEmpty(this.trimFunction(this.state.inputData["cbNumber"])) && (response.data.highMatchCustomers.length < 1 && response.data.mediumMatchCustomers.length < 1)) {

                                        this.setState({
                                            cbNotFound: true,
                                            searchTableData: false,
                                            searchTableRelatedData: false,
                                            getPropritorData: false,
                                            getError: true,
                                            title: "Applicant CB  / Account Not Found"
                                        });
                                        {
                                            this.reloadOff()
                                        }
                                    } else {

                                        if (response.data.highMatchCustomers !== null && Array.isArray(response.data.highMatchCustomers)) {

                                            response.data.highMatchCustomers.map((dedup) => {
                                                tableArrayProprietor.push(this.createTableData("", dedup.id, dedup.identification_no_mod, dedup.maincustid, dedup.sz_contitution, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, dedup.matched_by, "Higher-Match"));

                                            });

                                        }

                                        if (response.data.mediumMatchCustomers !== null && Array.isArray(response.data.mediumMatchCustomers)) {

                                            response.data.mediumMatchCustomers.map((dedup) => {

                                                tableArrayProprietor.push(this.createTableData("", dedup.id, dedup.identification_no_mod, dedup.maincustid, dedup.sz_contitution, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, dedup.matched_by, "Medium-Match"));

                                            });
                                        }

                                        if (response.data.relatedCustomers !== null && Array.isArray(response.data.relatedCustomers)) {
                                            response.data.relatedCustomers.map((dedup) => {

                                                relatedTableArrayProprietor.push(this.createRelatedTableData(dedup.id, dedup.identificationNoMod, dedup.mainCB, dedup.relatedCB, dedup.relatedCustomerName, dedup.relationship));

                                            });
                                        }
                                        if (response.data.sdnResult !== null && Array.isArray(response.data.sdnResult)) {
                                            response.data.sdnResult.map((data) => {
                                                sdnDataForNonIndividual.push(this.createSdnTableData(data.country, data.dob, data.matchType, data.name, data.type));

                                            });
                                        }
                                        this.setState({

                                            dedupData: tableArrayProprietor,
                                            relatedData: relatedTableArrayProprietor,
                                            sdnDataForNonIndividual: sdnDataForNonIndividual,
                                            searchTableSdnData: true,
                                            sdnDataForNonIndividualFlag: true,
                                            downloadDataForCompanyProprietor: true,
                                            searchTableRelatedData: true,
                                            getsearchValue: this.state.inputData,
                                            getPropritorData: false

                                        });
                                        {
                                            this.reloadOff()
                                        }
                                    }


                                })
                                .catch((error) => {

                                    this.setState({
                                        dedupData: [],
                                        relatedData: [],
                                        sdnData: [],
                                        searchTableSdnData: true,
                                        searchTableData: true,
                                        downloadDataForCompanyProprietor: true,
                                        searchTableRelatedData: true,
                                        getPropritorData: false
                                    });
                                    {
                                        this.reloadOff()
                                    }

                                });
                        } else {
                            this.setState({
                                loading: false,
                                dedupData: tableArrayProprietor,
                                relatedData: relatedTableArrayProprietor,
                                sdnData: sdnTableArray,
                                sdnDataForNonIndividual: sdnDataForNonIndividual,
                                searchTableSdnData: true,
                                sdnDataForNonIndividualFlag: true,
                                searchTableData: true,
                                downloadDataForCompanyProprietor: true,
                                searchTableRelatedData: true,
                                getsearchValue: this.state.inputData,
                                getPropritorData: false
                            })
                        }

                    })
                    .catch((error) => {

                        this.setState({
                            loading: false,
                            propritorshipData: [],
                            relatedData: [],
                            searchTableData: null,
                            sdnData: [],
                            sdnDataForNonIndividual: [],
                            searchTableSdnData: true,
                            downloadDataForCompany: true,
                            searchTableRelatedData: null,
                            getCompnayData: false
                        });
                        {
                            this.reloadOff()
                        }


                    });

                //set 02/02/1983, 00:00:00 to 1983-02-02 format


                {
                    this.props.companyDedupeBlock !== true && this.mandateAndBeneficiaryApiCall(postDatamandate, postDatabeneficiary)
                }

            } else {
                let tableArray = [];
                let relatedTableArray = [];
                let sdnTableArray = [];
                //set 02/02/1983, 00:00:00 to 1983-02-02 format

                let url = backEndServerURL + "/dedupWithSdn";
                console.log("data");
                console.log(postData);
                axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);
                        this.setState({
                            sourceMappingData: response.data.sourceMapping,
                            individualDedupData: response.data
                        });
                        if (this.state.inputData.cbNumber !== "" && this.state.inputData.cbNumber !== undefined && !validator.isEmpty(this.trimFunction(this.state.inputData["cbNumber"])) && (response.data.highMatchCustomers.length < 1 && response.data.mediumMatchCustomers.length < 1 && response.data.relatedCustomers.length < 1)) {
                            this.setState({
                                cbNotFound: true,
                                searchTableData: false,
                                searchTableRelatedData: false,
                                loading: false,
                                getError: true,
                                title: "Applicant CB  / Account Not Found"
                            })
                        } else {

                            if (response.data.highMatchCustomers !== null && Array.isArray(response.data.highMatchCustomers)) {

                                response.data.highMatchCustomers.map((dedup) => {
                                    tableArray.push(this.createTableData("", dedup.id, dedup.identification_no_mod, dedup.maincustid, dedup.sz_contitution, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, dedup.matched_by, "Higher-Match"));

                                });

                            }

                            if (response.data.mediumMatchCustomers !== null && Array.isArray(response.data.mediumMatchCustomers)) {

                                response.data.mediumMatchCustomers.map((dedup) => {

                                    tableArray.push(this.createTableData("", dedup.id, dedup.identification_no_mod, dedup.maincustid, dedup.sz_contitution, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, dedup.matched_by, "Medium-Match"));

                                });
                            }

                            if (response.data.relatedCustomers !== null && Array.isArray(response.data.relatedCustomers)) {
                                response.data.relatedCustomers.map((dedup) => {

                                    relatedTableArray.push(this.createRelatedTableData(dedup.id, dedup.identificationNoMod, dedup.mainCB, dedup.relatedCB, dedup.relatedCustomerName, dedup.relationship, dedup.relationship));

                                });
                            }
                            if (response.data.sdnResult !== null && Array.isArray(response.data.sdnResult)) {
                                response.data.sdnResult.map((data) => {
                                    sdnTableArray.push(this.createSdnTableData(data.country, data.dob, data.matchType, data.name, data.type));

                                });
                            }
                            this.setState({

                                dedupData: tableArray,
                                relatedData: relatedTableArray,
                                sdnData: sdnTableArray,
                                searchTableSdnData: true,
                                searchTableData: true,
                                searchTableRelatedData: true,
                                getsearchValue: searchInputData,
                                loading: false,

                            })
                        }


                    })
                    .catch((error) => {
                        this.setState({
                            cbNotFound: true,
                            searchTableData: false,
                            searchTableRelatedData: false,
                            loading: false,
                            getError: true,
                            title: "Network Issue,Please Check Your Network",
                            dedupData: [],
                            relatedData: [],
                            sdnData: [],
                            searchTableSdnData: true,

                        })

                    });

            }
            {
                this.mandateAndBeneficiaryApiCall(postDatamandate, postDatabeneficiary)
            }

        }
        this.setState({
            postData: postData,
            postDataProprietorship: postDataProprietorship,
        })
    };
    companyJointDedupeSearch = (postData) => {
        if (this.state.individualDedupeCheckbox) {
            console.log(postData);
            let url = backEndServerURL + "/jointDedup";
            let relatedTableArray = [];
            axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    console.log(response.data);
                    this.setState({
                        sourceMappingData: response.data.sourceMapping,
                        jointDedupData: response.data

                    });


                    let jointDedupSearchTable = [];
                    let sdnTableArray = [];
                    let sdnDataForNonIndividual = [];
                    var notFound = 0;
                    for (let i = 0; i < this.state.handleChangeJoint; i++) {
                        if (this.state.inputData["cbNumber" + i] !== "" && this.state.inputData["cbNumber" + i] !== undefined && this.state.inputData["cbNumber" + i] !== null && !validator.isEmpty(this.trimFunction(this.state.inputData["cbNumber" + i])) && (response.data.highMatchCustomers[i].length < 1 && response.data.mediumMatchCustomers[i].length < 1)) {
                            let number = i + 1;
                            this.setState({
                                cbNotFound: true,
                                getError: true,
                                title: this.numberToCharacter(number) + " Applicant " + " CB  / Account Not Found",
                                getResultPropritor: true,
                                searchTableData: false,
                                searchTableRelatedData: false,
                                getPropritorData: false,
                                jointSearchTable: [],
                                relatedData: [],

                            });
                            {
                                this.reloadOff()
                            }
                            notFound = this.state.handleChangeJoint;
                        }

                    }
                    if (notFound !== this.state.handleChangeJoint) {
                        /*
                                                if (response.data.highMatchCustomers !== null && Array.isArray(response.data.highMatchCustomers)) {

                                                    response.data.highMatchCustomers.map((dedup, i) => {
                                                        let customer = [];

                                                        dedup.map((dedup) => {

                                                            customer.push(this.createTableDataWithoutProprietorship(dedup.id, dedup.identification_no_mod, dedup.maincustid, dedup.sz_contitution, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, dedup.matched_by, "Higher-Match"));
                                                        })
                                                        jointDedupSearchTable.push(customer);

                                                    });


                                                }
                        */
                        if (response.data.highMatchCustomers !== null && Array.isArray(response.data.highMatchCustomers)) {

                            var randomNumber = 0;

                            response.data.highMatchCustomers.map((dedup, i) => {
                                let customer = [];
                                randomNumber = (randomNumber + 1);

                                dedup.map((dedup) => {
                                    customer.push(this.createTableDataWithoutProprietorship(dedup.id, dedup.identification_no_mod, dedup.maincustid, dedup.sz_contitution, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, dedup.matched_by, "Higher-Match"));
                                });

                                if (response.data.mediumMatchCustomers !== null && Array.isArray(response.data.mediumMatchCustomers)) {

                                    let medium = response.data.mediumMatchCustomers[i];
                                    medium.map((medium) => {
                                        customer.push(this.createTableDataWithoutProprietorship(medium.id, medium.identification_no_mod, medium.maincustid, medium.sz_contitution, medium.sz_source, medium.sz_full_name, medium.sz_national_id, medium.sz_passport, medium.sz_tin_number, medium.cust_comu_phone_num, medium.dt_birth, medium.email_id, medium.sz_registration_no, medium.matched_by, "Medium-Match"));

                                    })

                                }


                                jointDedupSearchTable.push(customer);

                            });


                        }


                        if (response.data.relatedCustomers !== null && Array.isArray(response.data.relatedCustomers)) {
                            response.data.relatedCustomers.map((dedup) => {

                                relatedTableArray.push(this.createRelatedTableData(dedup.id, dedup.identificationNoMod, dedup.mainCB, dedup.relatedCB, dedup.relatedCustomerName, dedup.relationship));

                            });
                        }


                    }
                    if (response.data.sdnResult !== null && Array.isArray(response.data.sdnResult)) {

                        response.data.sdnResult.map((data, i) => {
                            let sdnTableList = [];
                            if (Array.isArray(data)) {
                                data.map((data) => {

                                    sdnTableList.push(this.createSdnTableData(data.country, data.dob, data.matchType, data.name, data.type));
                                });
                                sdnDataForNonIndividual.push(sdnTableList);
                            }


                        });


                    }

                    this.setState({
                        jointSearchTable: jointDedupSearchTable,
                        sdnDataForNonIndividual: sdnDataForNonIndividual,
                        // sdnData: sdnTableArray,
                        sdnDataForNonIndividualFlag: true,
                        searchTableSdnData: true,
                        searchTableData: true,
                        downloadDataForCompanyProprietor: true,
                        searchTableRelatedData: true,
                        getPropritorData: false,

                        getsearchValue: this.state.inputData,

                    });
                    {
                        this.reloadOff()
                    }

                })
                .catch((error) => {

                    this.setState({
                        jointSearchTable: [],
                        relatedData: [],
                        sdnData: [],
                        searchTableSdnData: true,
                        downloadDataForCompanyProprietor: true,
                        searchTableData: false,
                        searchTableRelatedData: false,
                        getPropritorData: false,
                    });
                    {
                        this.reloadOff()
                    }

                });

        } else {
            this.setState({
                sdnDataForNonIndividualFlag: false,
                /*    downloadDataForCompanyProprietor: true,
                    getPropritorData: false,
                    searchTableSdnData: true,
                   searchTableData: true,
                   searchTableRelatedData: true,*/
                searchTableRelatedData: true,
                loading: false,

            })
        }


    };
    mandateAndBeneficiaryApiCall = (postDatamandate, postDatabeneficiary) => {
        if (this.state.inputData.mandate === 'YES') {
            let tableArray = [];
            let relatedTableArray = [];
            let sdnTableArray = [];
            //set 02/02/1983, 00:00:00 to 1983-02-02 format

            let url = backEndServerURL + "/dedupWithSdn";
            console.log("data");
            axios.post(url, postDatamandate, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.setState({
                        mandateindividualDedupData: response.data
                    });
                    if (this.state.inputData.mandatecbNumber !== "" && this.state.inputData.mandatecbNumber !== undefined && !validator.isEmpty(this.trimFunction(this.state.inputData["mandatecbNumber"])) && (response.data.highMatchCustomers.length < 1 && response.data.mediumMatchCustomers.length < 1 && response.data.relatedCustomers.length < 1)) {
                        this.setState({
                            cbNotFound: true,
                            searchTableData: false,
                            searchTableRelatedData: false,
                            loading: false,
                            getError: true,
                            title: "Applicant CB  / Account Not Found"
                        })
                    } else {

                        if (response.data.highMatchCustomers !== null && Array.isArray(response.data.highMatchCustomers)) {

                            response.data.highMatchCustomers.map((dedup) => {
                                tableArray.push(this.createTableDataMandate("", dedup.id, dedup.identification_no_mod, dedup.maincustid, dedup.sz_contitution, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, dedup.matched_by, "Higher-Match"));

                            });

                        }

                        if (response.data.mediumMatchCustomers !== null && Array.isArray(response.data.mediumMatchCustomers)) {

                            response.data.mediumMatchCustomers.map((dedup) => {

                                tableArray.push(this.createTableDataMandate("", dedup.id, dedup.identification_no_mod, dedup.maincustid, dedup.sz_contitution, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, dedup.matched_by, "Medium-Match"));

                            });
                        }
                        if (response.data.sdnResult !== null && Array.isArray(response.data.sdnResult)) {
                            response.data.sdnResult.map((data) => {
                                sdnTableArray.push(this.createSdnTableData(data.country, data.dob, data.matchType, data.name, data.type));

                            });
                        }
                        this.setState({

                            mandateDedupData: tableArray,
                            sdnDataMandate: sdnTableArray,
                            mandateBoolean: true,
                            /* searchTableSdnData: true,
                             searchTableData: true,
                             searchTableRelatedData: true,
                             getsearchValue: this.state.inputData,*/
                            loading: false,

                        })
                    }


                })
                .catch((error) => {

                    this.setState({
                        mandateDedupData: [],
                        mandateBoolean: true,
                        loading: false,
                    })

                });

        }
        if (this.state.inputData.beneficiary === 'YES') {
            let tableArray = [];
            let relatedTableArray = [];
            let sdnTableArray = [];
            //set 02/02/1983, 00:00:00 to 1983-02-02 format

            let url = backEndServerURL + "/dedupWithSdn";
            console.log("data");
            axios.post(url, postDatabeneficiary, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.setState({
                        beneficiaryindividualDedupData: response.data
                    });
                    if (this.state.inputData.beneficiarycbNumber !== "" && this.state.inputData.beneficiarycbNumber !== undefined && !validator.isEmpty(this.trimFunction(this.state.inputData["beneficiarycbNumber"])) && (response.data.highMatchCustomers.length < 1 && response.data.mediumMatchCustomers.length < 1 && response.data.relatedCustomers.length < 1)) {
                        this.setState({
                            cbNotFound: true,
                            searchTableData: false,
                            searchTableRelatedData: false,
                            loading: false,
                            getError: true,
                            title: "Applicant CB  / Account Not Found"
                        })
                    } else {

                        if (response.data.highMatchCustomers !== null && Array.isArray(response.data.highMatchCustomers)) {

                            response.data.highMatchCustomers.map((dedup) => {
                                tableArray.push(this.createTableDataMandate("", dedup.id, dedup.identification_no_mod, dedup.maincustid, dedup.sz_contitution, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, dedup.matched_by, "Higher-Match"));

                            });

                        }

                        if (response.data.mediumMatchCustomers !== null && Array.isArray(response.data.mediumMatchCustomers)) {

                            response.data.mediumMatchCustomers.map((dedup) => {

                                tableArray.push(this.createTableDataMandate("", dedup.id, dedup.identification_no_mod, dedup.maincustid, dedup.sz_contitution, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, dedup.matched_by, "Medium-Match"));

                            });
                        }
                        if (response.data.sdnResult !== null && Array.isArray(response.data.sdnResult)) {
                            response.data.sdnResult.map((data) => {
                                sdnTableArray.push(this.createSdnTableData(data.country, data.dob, data.matchType, data.name, data.type));

                            });
                        }
                        this.setState({

                            beneficiaryDedupData: tableArray,
                            sdnDataBeneficiary: sdnTableArray,
                            beneficiaryBoolean: true,
                            /* searchTableSdnData: true,
                             searchTableData: true,
                             searchTableRelatedData: true,
                             getsearchValue: this.state.inputData,*/
                            loading: false,

                        })
                    }


                })
                .catch((error) => {

                    this.setState({
                        beneficiaryDedupData: [],
                        beneficiaryBoolean: true,
                        loading: false,
                    })

                });

        }
    };

    handleChangeJoint = (event, option) => {
        event.preventDefault();
        if (option !== null) {
            this.setState({
                handleChangeJoint: option.title
            })
        } else {
            this.setState({
                handleChangeJoint: 0
            })

        }

    };
    handleChangeJointType = (event, option) => {
        event.preventDefault();
        if (option !== null) {
            this.setState({
                apiGetData: false
            });
            setTimeout(() => {
                if (option.title === "Individual Customer") {
                    this.setState({
                        content: "Individual Customer",
                        apiGetData: true,
                        handleChangeJointType: option.title
                    })
                } else if (option.title === "Existing Joint CB /  Account") {
                    this.setState({
                        content: "Existing Joint CB /  Account",
                        apiGetData: true,
                        handleChangeJointType: option.title
                    })
                }
            }, 100)
        } else {
            this.setState({
                apiGetData: true,
                handleChangeJointType: option
            })
        }

    };
    handleChangeSdn = (event, option) => {
        event.preventDefault();
        if (option !== null) {
            this.setState({
                handleChangeSdn: option.title
            })
        } else {
            this.setState({
                handleChangeSdn: option
            })
        }

    };
    numberToCharacter = (i) => {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    };
    handleJointAccountForms = (inputDatas,varValues,jointNumberOfCustomer) => {
        let handleChangeJoint=this.state.handleChangeJoint;
        if(jointNumberOfCustomer!==undefined && jointNumberOfCustomer!==null){
            handleChangeJoint=jointNumberOfCustomer
        }

        if (handleChangeJoint > 1 ) {
            let objectForJoinAccount = [];
            let inputData = this.state.inputData;
            let varValue = this.state.varValue;
            if(inputDatas!==undefined){
                inputData=inputDatas;
            }
            if(varValues!==undefined){
                varValue=varValues;
            }

            var sl;
            for (var i = 0; i < handleChangeJoint; i++) {
                sl = i + 1;
                /*nationalityApiData*/
                inputData["mobileTypeJoint" + i] = "Local";
                varValue["mobileTypeJoint" + i] = "Local";
                inputData["nationality" + i] = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
                varValue["nationality" + i] = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
                objectForJoinAccount.push(
                    {
                        "varName": "applicant" + i,
                        "type": "titleLeft",
                        "label": this.numberToCharacter(sl) + " Applicant ",
                        "grid": 12,

                    },
                    {
                        "varName": "cbNumber" + i,
                        "type": "textDedup",
                        "label": "Existing CB Number",
                        "grid": 2,

                    },
                    {
                        "varName": "accountNumber" + i,
                        "type": "textDedup",
                        "label": "Account Number",
                        "grid": 2,

                    },
                    {
                        "varName": "customerName" + i,
                        "type": "textDedup",
                        "label": "Customer Name *",
                        "asteriks": true,
                        "grid": 2,

                    },
                    {
                        "varName": "nid" + i,
                        "type": "textDedup",
                        "validation": "nid",
                        "label": "NID +",

                        "grid": 2,


                    },
                    {
                        "varName": "smartCard" + i,
                        "type": "textDedup",
                        "validation": "smartCard",
                        "label": "Smart Card +",

                        "grid": 2,


                    },
                    {
                        "varName": "passport" + i,
                        "type": "textDedup",
                        "validation": "passport",
                        "label": "Passport +",
                        "grid": 2,


                    },
                    {
                        "varName": "registrationNo" + i,
                        "type": "textDedup",
                        "validation": "birthCertificateNumber",
                        "label": "Birth Cer.+",
                        "grid": 2,


                    },
                    {
                        "varName": "drivingLicense" + i,
                        "type": "textDedup",
                        "label": "Driving License",
                        "grid": 2,

                    },
                    {
                        "varName": "dob" + i,
                        "type": "date",
                        "label": "Date Of Birth *",
                        "asteriks": true,
                        "grid": 2,


                    },
                    {
                        "varName": "mobileTypeJoint" + i,
                        "type": "select",
                        "enum": [
                            "Local",
                            "Overseas",
                        ],
                        "required": true,
                        "label": "Mobile Type *",
                        "asteriks": true,

                        "grid": 2,
                    },
                    {
                        "varName": "countryCode" + i,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.countryCode,
                        "label": "Country Code ",
                        "asteriks": true,
                        "conditional": true,
                        "conditionalVarName": "mobileTypeJoint" + i,
                        "conditionalVarValue": "Overseas",
                        "grid": 2,
                    },
                    {
                        "varName": "phone" + i,
                        "type": "textDedup",
                        "phonePrefix": true,
                        "validation": "phone" + i,
                        "label": "Mobile Number *",
                        "asteriks": true,
                        "conditional": true,
                        "conditionalVarName": "mobileTypeJoint" + i,
                        "conditionalVarValue": "Local",
                        "grid": 2,


                    },
                    {
                        "varName": "phone" + i,
                        "type": "textDedup",
                        "label": "Mobile Number",
                        "asteriks": true,
                        "conditional": true,
                        "conditionalVarName": "mobileTypeJoint" + i,
                        "conditionalVarValue": "Overseas",
                        "grid": 2,


                    },
                    /*  {
                          "varName": "phone" + i,
                          "type": "textDedup",
                          "phonePrefix": true,
                          "validation": "phone",
                          "label": "Phone Number *",
                          "asteriks": true,
                          "grid": 2,

                      },*/

                    {
                        "varName": "email" + i,
                        "type": "textDedup",
                        "validation": "email",
                        "label": "Email",

                        "grid": 2,
                    },


                    {
                        "varName": "tin" + i,
                        "type": "textDedup",
                        "validation": "etin",
                        "label": "E-Tin",
                        "grid": 2,


                    },
                    {
                        "varName": "nationality" + i,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.nationalityApiData,
                        "label": "Nationality *",
                        "asteriks": true,
                        "grid": 2,
                    },
                )


            }
            objectForJoinAccount.push(
                {
                    "type": "empty",
                    "grid": 12,
                },
                {
                    "varName": "mandate",
                    "type": "select",
                    "enum": [
                        "YES",
                        "NO"
                    ],
                    "label": "Mandate ",

                    "grid": 2,

                },
                {
                    "varName": "beneficiary",
                    "type": "select",
                    "enum": [
                        "YES",
                        "NO"
                    ],
                    "label": "Beneficial Owner",

                    "grid": 2,

                },
            );
            this.setState({
                inputData: inputData,
                varValue: varValue,
                showValue: true,
                apiGetData: true,
                objectForJoinAccount: objectForJoinAccount,
                content: "Joint Account"

            });
            console.log("objectForJoinAccount"+objectForJoinAccount)
            this.renderSearchForm();

        } else {
            this.setState({
                objectForJoinAccount: [],

            });
            this.renderSearchForm();

        }
    };
    handleSubmitJointAccountForm = () => {
        let handleChangeJoint=this.state.handleChangeJoint;


        if (handleChangeJoint >= 1 && this.state.content === 'Individual Customer') {
            let objectForJoinAccount = [];
            let inputData = this.state.inputData;
            let varValue = this.state.varValue;

            var sl;
            for (var i = 0; i < handleChangeJoint; i++) {
                sl = i + 1;
                /*nationalityApiData*/
                inputData["mobileTypeJoint" + i] = "Local";
                varValue["mobileTypeJoint" + i] = "Local";
                inputData["nationality" + i] = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
                varValue["nationality" + i] = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
                objectForJoinAccount.push(
                    {
                        "varName": "applicant" + i,
                        "type": "titleLeft",
                        "label": this.numberToCharacter(sl) + " Applicant ",
                        "grid": 12,

                    },
                    {
                        "varName": "cbNumber" + i,
                        "type": "textDedup",
                        "label": "Existing CB Number",
                        "grid": 2,

                    },
                    {
                        "varName": "accountNumber" + i,
                        "type": "textDedup",
                        "label": "Account Number",
                        "grid": 2,

                    },
                    {
                        "varName": "customerName" + i,
                        "type": "textDedup",
                        "label": "Customer Name *",
                        "asteriks": true,
                        "grid": 2,

                    },
                    {
                        "varName": "nid" + i,
                        "type": "textDedup",
                        "validation": "nid",
                        "label": "NID +",

                        "grid": 2,


                    },
                    {
                        "varName": "smartCard" + i,
                        "type": "textDedup",
                        "validation": "smartCard",
                        "label": "Smart Card +",

                        "grid": 2,


                    },
                    {
                        "varName": "passport" + i,
                        "type": "textDedup",
                        "validation": "passport",
                        "label": "Passport +",
                        "grid": 2,


                    },
                    {
                        "varName": "registrationNo" + i,
                        "type": "textDedup",
                        "validation": "birthCertificateNumber",
                        "label": "Birth Cer.+",
                        "grid": 2,


                    },
                    {
                        "varName": "drivingLicense" + i,
                        "type": "textDedup",
                        "label": "Driving License",
                        "grid": 2,

                    },
                    {
                        "varName": "dob" + i,
                        "type": "date",
                        "label": "Date Of Birth *",
                        "asteriks": true,
                        "grid": 2,


                    },
                    {
                        "varName": "mobileTypeJoint" + i,
                        "type": "select",
                        "enum": [
                            "Local",
                            "Overseas",
                        ],
                        "required": true,
                        "label": "Mobile Type *",
                        "asteriks": true,

                        "grid": 2,
                    },
                    {
                        "varName": "countryCode" + i,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.countryCode,
                        "label": "Country Code ",
                        "asteriks": true,
                        "conditional": true,
                        "conditionalVarName": "mobileTypeJoint" + i,
                        "conditionalVarValue": "Overseas",
                        "grid": 2,
                    },
                    {
                        "varName": "phone" + i,
                        "type": "textDedup",
                        "phonePrefix": true,
                        "validation": "phone" + i,
                        "label": "Mobile Number *",
                        "asteriks": true,
                        "conditional": true,
                        "conditionalVarName": "mobileTypeJoint" + i,
                        "conditionalVarValue": "Local",
                        "grid": 2,


                    },
                    {
                        "varName": "phone" + i,
                        "type": "textDedup",
                        "label": "Mobile Number",
                        "asteriks": true,
                        "conditional": true,
                        "conditionalVarName": "mobileTypeJoint" + i,
                        "conditionalVarValue": "Overseas",
                        "grid": 2,


                    },
                    /*  {
                          "varName": "phone" + i,
                          "type": "textDedup",
                          "phonePrefix": true,
                          "validation": "phone",
                          "label": "Phone Number *",
                          "asteriks": true,
                          "grid": 2,

                      },*/

                    {
                        "varName": "email" + i,
                        "type": "textDedup",
                        "validation": "email",
                        "label": "Email",

                        "grid": 2,
                    },


                    {
                        "varName": "tin" + i,
                        "type": "textDedup",
                        "validation": "etin",
                        "label": "E-Tin",
                        "grid": 2,


                    },
                    {
                        "varName": "nationality" + i,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.nationalityApiData,
                        "label": "Nationality *",
                        "asteriks": true,
                        "grid": 2,
                    },
                )


            }
            objectForJoinAccount.push(
                {
                    "type": "empty",
                    "grid": 12,
                },
                {
                    "varName": "mandate",
                    "type": "select",
                    "enum": [
                        "YES",
                        "NO"
                    ],
                    "label": "Mandate ",

                    "grid": 2,

                },
                {
                    "varName": "beneficiary",
                    "type": "select",
                    "enum": [
                        "YES",
                        "NO"
                    ],
                    "label": "Beneficial Owner",

                    "grid": 2,

                },
            );
            this.setState({
                inputData: inputData,
                varValue: varValue,
                showValue: true,
                apiGetData: true,
                objectForJoinAccount: objectForJoinAccount,
                content: "Joint Account"

            });
            console.log("objectForJoinAccount"+objectForJoinAccount)
            this.renderSearchForm();

        } else {
            this.setState({
                objectForJoinAccount: [],

            });
            this.renderSearchForm();

        }
    };
    handleSubmitSdnAccountForm = () => {

        if (this.state.handleChangeSdn > 1) {
            let objectForSdnAccount = [];
            let inputData = this.state.inputData;
            let varValue = this.state.varValue;
            var sl;
            for (var i = 0; i < this.state.handleChangeSdn; i++) {
                sl = i + 1;
                /*nationalityApiData*/
                inputData["nationality" + i] = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
                varValue["nationality" + i] = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
                objectForSdnAccount.push(
                    {
                        "varName": "applicant" + i,
                        "type": "titleLeft",
                        "label": this.numberToCharacter(sl) + " SDN Applicant ",
                        "grid": 12,

                    },

                    {
                        "varName": "customerName" + i,
                        "type": "textDedup",
                        "label": "Customer Name *",
                        "asteriks": true,
                        "grid": 2,

                    },
                    {
                        "varName": "nid" + i,
                        "type": "textDedup",
                        "validation": "nid",
                        "label": "NID +",

                        "grid": 2,


                    },
                    {
                        "varName": "passport" + i,
                        "type": "textDedup",
                        "validation": "passport",
                        "label": "Passport +",
                        "grid": 2,


                    },

                    {
                        "varName": "dob" + i,
                        "type": "date",
                        "label": "Date Of Birth *",
                        "asteriks": true,
                        "grid": 2,


                    },

                    {
                        "varName": "nationality" + i,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.nationalityApiData,
                        "label": "Nationality *",
                        "asteriks": true,
                        "grid": 2,
                    },
                )


            }
            this.setState({
                inputData: inputData,
                varValue: varValue,
                showValue: true,
                objectForSdnAccount: objectForSdnAccount,

            });
            this.renderSearchForm();

        } else {
            this.setState({
                objectForSdnAccount: [],
            });
            this.renderSearchForm();

        }

    };

    handleSubmitWithoutProprietorshipAccountForm = () => {

        if (this.state.handleChangeJoint > 1) {
            let objectForJoinAccount = [];
            let inputData = this.state.inputData;
            let varValue = this.state.varValue;

            var sl;
            for (var i = 0; i < this.state.handleChangeJoint; i++) {
                sl = i + 1;
                /*nationalityApiData*/
                inputData["mobileTypeJoint" + i] = "Local";
                varValue["mobileTypeJoint" + i] = "Local";
                inputData["nationality" + i] = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
                varValue["nationality" + i] = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
                objectForJoinAccount.push(
                    {
                        "varName": "applicant" + i,
                        "type": "titleLeft",
                        "label": this.numberToCharacter(sl) + " Applicant ",
                        "grid": 12,

                    },
                    {
                        "varName": "cbNumber" + i,
                        "type": "textDedup",
                        "label": "CB Number",
                        "grid": 2,

                    },
                    {
                        "varName": "accountNumber" + i,
                        "type": "textDedup",
                        "label": "Account Number",
                        "grid": 2,

                    },
                    {
                        "varName": "customerName" + i,
                        "type": "textDedup",
                        "label": "Customer Name *",
                        "asteriks": true,
                        "grid": 2,

                    },
                    {
                        "varName": "nid" + i,
                        "type": "textDedup",
                        "validation": "nid",
                        "label": "NID +",

                        "grid": 2,


                    },
                    {
                        "varName": "smartCard" + i,
                        "type": "textDedup",
                        "validation": "smartCard",
                        "label": "Smart Card +",

                        "grid": 2,


                    },
                    {
                        "varName": "passport" + i,
                        "type": "textDedup",
                        "validation": "passport",
                        "label": "Passport +",
                        "grid": 2,


                    },
                    {
                        "varName": "registrationNo" + i,
                        "type": "textDedup",
                        "validation": "birthCertificateNumber",
                        "label": "Birth Cer.+",
                        "grid": 2,


                    },
                    {
                        "varName": "drivingLicense" + i,
                        "type": "textDedup",
                        "label": "Driving License",
                        "grid": 2,

                    },
                    {
                        "varName": "dob" + i,
                        "type": "date",
                        "label": "Date Of Birth *",
                        "asteriks": true,
                        "grid": 2,


                    },
                    {
                        "varName": "mobileTypeJoint" + i,
                        "type": "select",
                        "enum": [
                            "Local",
                            "Overseas",
                        ],
                        "required": true,
                        "label": "Mobile Type *",
                        "asteriks": true,

                        "grid": 2,
                    },
                    {
                        "varName": "countryCode" + i,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.countryCode,
                        "label": "Country Code ",
                        "asteriks": true,
                        "conditional": true,
                        "conditionalVarName": "mobileTypeJoint" + i,
                        "conditionalVarValue": "Overseas",
                        "grid": 2,
                    },
                    {
                        "varName": "phone" + i,
                        "type": "textDedup",
                        "phonePrefix": true,
                        "validation": "phone" + i,
                        "label": "Mobile Number *",
                        "asteriks": true,
                        "conditional": true,
                        "conditionalVarName": "mobileTypeJoint" + i,
                        "conditionalVarValue": "Local",
                        "grid": 2,


                    },
                    {
                        "varName": "phone" + i,
                        "type": "textDedup",
                        "label": "Mobile Number",
                        "asteriks": true,
                        "conditional": true,
                        "conditionalVarName": "mobileTypeJoint" + i,
                        "conditionalVarValue": "Overseas",
                        "grid": 2,


                    },
                    /*  {
                          "varName": "phone" + i,
                          "type": "textDedup",
                          "phonePrefix": true,
                          "validation": "phone",
                          "label": "Phone Number *",
                          "asteriks": true,
                          "grid": 2,

                      },*/

                    {
                        "varName": "email" + i,
                        "type": "textDedup",
                        "validation": "email",
                        "label": "Email",

                        "grid": 2,
                    },


                    {
                        "varName": "tin" + i,
                        "type": "textDedup",
                        "validation": "etin",
                        "label": "E-Tin",
                        "grid": 2,


                    },
                    {
                        "varName": "nationality" + i,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.nationalityApiData,
                        "label": "Nationality *",
                        "asteriks": true,
                        "grid": 2,
                    },
                )


            }
            if (this.state.content === 'NGO/NPO/Club/Society Account') {
                this.setState({
                    inputData: inputData,
                    varValue: varValue,
                    showValue: true,
                    objectForJoinAccount: objectForJoinAccount,
                    // content: "NGO/NPO/Club/Society Account",
                    //contentType: "NGO/NPO/Club/Society Account"

                })
            } else {
                this.setState({
                    inputData: inputData,
                    varValue: varValue,
                    showValue: true,
                    objectForJoinAccount: objectForJoinAccount,
                    contentType: this.state.content,
                    // content: "Company Account",


                })
            }
            this.renderSearchForm();

        } else {
            this.setState({
                showValue: true,
                objectForJoinAccount: [],
                contentType: this.state.content,


            });
            this.renderSearchForm();

        }
    };
    updateComponent = () => {
        this.forceUpdate();
    };


    dynamicApiCall = (fieldName, apiType) => {
        let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(fieldName);
                console.log(response);
                if (fieldName === "nationality") {
                    {
                        this.findByVarNameApiData(fieldName, response.data, CSjsonFormIndividualAccountOpeningSearch)
                    }
                    {
                        this.findByVarNameApiData("mandatenationality", response.data, CSjsonFormIndividualAccountOpeningSearchmandate)
                    }
                    {
                        this.findByVarNameApiData("beneficiarynationality", response.data, CSjsonFormIndividualAccountOpeningSearchBeneficiary)
                    }
                    {
                        this.findByVarNameApiData(fieldName, response.data, CSjsonFormNonIndividualProprietorshipAccountOpeningSearch)
                    }

                    this.setState({
                        apiGetData: true,
                        nationalityApiData: response.data,
                        loading: false
                    })
                } else if (fieldName === "city") {
                    this.setState({
                        getCity: response.data
                    })
                } else if (fieldName === "state") {
                    this.setState({
                        getState: response.data
                    })
                } else if (fieldName === "countryCode") {
                    {
                        this.findByVarNameApiData("countryCodeIndividual", response.data, CSjsonFormIndividualAccountOpeningSearch)
                    }

                    {
                        this.findByVarNameApiData("countryCodeMandate", response.data, CSjsonFormIndividualAccountOpeningSearchmandate)
                    }
                    {
                        this.findByVarNameApiData("countryCodeBeneficiary", response.data, CSjsonFormIndividualAccountOpeningSearchBeneficiary)
                    }
                    {
                        this.findByVarNameApiData("countryCodeCompanyProprietorship", response.data, CSjsonFormNonIndividualProprietorshipAccountOpeningSearch)
                    }
                    {
                        this.findByVarNameApiData("countryCodeWithoutProprietorshipCompany", response.data, CSjsonFormNonIndividualWithoutProprietorshipAccountOpeningSearch)
                    }
                    {
                        this.findByVarNameApiData("countryCodeNonIndividualCompany", response.data, CSjsonFormNonIndividualCompanyAccountOpeningSearch)
                    }
                    this.setState({
                        countryCode: response.data
                    })
                }


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    apiGetData: true,
                    loading: false
                })
            })
    };


    findByVarNameApiData = (searchVarname, getValue, jsonForm) => {
        let jsonArray = jsonForm;

        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                console.log(getValue.key);
                let data = [];
                jsonForm[i].enum = getValue;
                console.log(jsonForm[i])

            }
        }


    };

    objectReplaceAnotherObject=(NewObject)=>{
        let oldObjects = this.props.getDedupData;
        let NewObjects = NewObject;
        for (let i = 0; i < NewObjects.length; i++) {
            for (let j = 0; j < oldObjects.length; j++) {
                if(NewObjects[i]===oldObjects[j]){
                    delete oldObjects[j];
                }
                else{
                    NewObjects.push(oldObjects[j]);
                }

            }




        }
        console.log(NewObjects)
        console.log("NewObjects")
        return NewObjects;
    }
    componentDidMount() {
        //alert(this.props.inputData.searchDedupeInputData.freeFlag3)
        /*  if (this.props.companyDedupeBlock === true) {
              this.setState({
                  getData: true,
                  apiGetData: true,
                  value: "NONINDIVIDUAL",
                  NonIndividualabel: "Proprietorship A/C",
                  individualLabel: "Proprietorship A/C",
                  content: "Proprietorship A/C",
                  contentType: "Proprietorship A/C",
                  getType: "Proprietorship A/C",
                  tabMenuSelect: 'NONINDIVIDUAL',
              })

          }*/
        let url = backEndServerURL + "/cbsCustomerGet/undefined";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

            })
            .catch((error)=>{
                console.log(error.response)

            })
        let inputData = this.state.inputData;
        let varValue = this.state.varValue;
        inputData.accountNumber=inputData.searchAccountNumber;
        varValue.accountNumber=varValue.searchAccountNumber;
        if(this.props.merchantDedup===true){
            this.setState({
                //  content: "Joint Type",
                contentType: "Joint Type",
                content: "Individual Customer",
                apiGetData: true,
                handleChangeJointType: "Individual Customer",
            })
        }
        if (this.props.nidBlockShowing === false) {
            inputData.nidBlockShowing = false
            varValue.nidBlockShowing = false
        } else {
            inputData.nidBlockShowing = true;
            varValue.nidBlockShowing = true;
        }
        inputData.companyDedupeBlockBoolean = this.props.companyDedupeBlock;
        varValue.companyDedupeBlockBoolean = this.props.companyDedupeBlock;
        inputData.individualDedupeCheckbox = true;
        varValue.individualDedupeCheckbox = true;
        inputData.mobileTypeIndividual = "Local";
        inputData.mobileTypeMandate = "Local";
        inputData.mobileTypeBeneficiary = "Local";
        inputData.mobileTypeProprietorship = "Local";
        inputData.mobileTypeWithoutProprietorship = "Local";
        inputData.mobileTypeWithoutProprietorshipCompany = "Local";
        inputData.mobileTypeNonIndividualCompany = "Local";
        inputData.mobileTypeNGOSOCIETY = "Local";
        inputData.mobileTypeIndividualProprietorship = "Local";
        varValue.mobileTypeIndividual = "Local";
        varValue.mobileTypeMandate = "Local";
        varValue.mobileTypeBeneficiary = "Local";
        varValue.mobileTypeProprietorship = "Local";
        varValue.mobileTypeWithoutProprietorship = "Local";
        varValue.mobileTypeWithoutProprietorshipCompany = "Local";
        varValue.mobileTypeNonIndividualCompany = "Local";
        varValue.mobileTypeNGOSOCIETY = "Local";
        varValue.mobileTypeIndividualProprietorship = "Local";
        inputData.mandatenationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        inputData.beneficiarynationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        inputData.nationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        varValue.mandatenationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        varValue.beneficiarynationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        varValue.nationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        if(this.props.readOnlyField){
            let individualDedupData={};
            let jointDedupData={};
            let beneficiaryDedupData={};
            let mandateDedupData={};



            if(this.props.inputData.individualDedupData!==undefined && this.props.inputData.individualDedupData!==null && this.props.inputData.individualDedupData.indDedupDto!==undefined){
                if(this.props.inputData.individualDedupData.indDedupDto!==undefined){
                    individualDedupData=this.objectReplaceAnotherObject(this.props.inputData.individualDedupData.indDedupDto);
                }

            }
            if(this.props.inputData.jointDedupData!==undefined && this.props.inputData.jointDedupData!==null && this.props.inputData.jointDedupData.indDedupDto!==undefined){
                if(this.props.inputData.jointDedupData.indDedupDto!==undefined){
                    jointDedupData=this.objectReplaceAnotherObject(this.props.inputData.jointDedupData.indDedupDto);
                }

            }
            if(this.props.inputData.beneficiaryDedupData!==undefined && this.props.inputData.beneficiaryDedupData!==null && this.props.inputData.beneficiaryDedupData.indDedupDto!==undefined){
                if(this.props.inputData.beneficiaryDedupData.indDedupDto!==undefined){
                    beneficiaryDedupData=this.objectReplaceAnotherObject(this.props.inputData.beneficiaryDedupData.indDedupDto);
                }

            }
            if(this.props.inputData.mandateDedupData!==undefined && this.props.inputData.mandateDedupData!==null && this.props.inputData.mandateDedupData.indDedupDto!==undefined){
                if(this.props.inputData.mandateDedupData.indDedupDto!==undefined){
                    mandateDedupData=this.objectReplaceAnotherObject(this.props.inputData.mandateDedupData.indDedupDto);
                }

            }

            inputData=(individualDedupData);
            /*   inputData.add(jointDedupData);
               inputData.add(beneficiaryDedupData);
               inputData.add(mandateDedupData);*/
            varValue =  inputData;
            console.log( this.props.inputData.individualDedupData)
            console.log( this.props.inputData)
            console.log( "new data")
            console.log(inputData)


            let content="";
            if (inputData.freeFlag3 === "Joint Account") {
                content= "Existing Joint CB /  Account";
            } else if (inputData.freeFlag3 ===   'Partnership A/C' || inputData.freeFlag3  === 'Private/Public Limited Company Account' || inputData.freeFlag3 === 'NGO/NPO/Club/Society Account' || inputData.freeFlag3 === 'Educational/Religious Institution Account' || inputData.freeFlag3 === 'Government Account' || inputData.freeFlag3 === 'Other Account') {
                content=  "Company Account";
            } else if (inputData.freeFlag3  === 'INDIVIDUAL') {
                content=  "Individual A/C";
            } else if (inputData.freeFlag3   === 'NONINDIVIDUAL' || inputData.freeFlag3 === 'Proprietorship A/C') {
                content=  "Proprietorship A/C";
            } else {
                content=  inputData.freeFlag3  ;
            }
            if(inputData.jointNumberOfCustomer>0){
                content="Individual Customer";
            }
            this.setState({
                inputData: inputData,
                varValue: varValue,
                showValue: true,
                getData: true,
                loading:false,
                handleChangeJoint:inputData.jointNumberOfCustomer,
                content: content,
                contentType: content,
                apiGetData: false,
            })
            if(inputData.jointNumberOfCustomer>0){
                this.setState({
                    content: "Joint Account",
                    apiGetData: true,
                })
                this.handleJointAccountForms(inputData,varValue,inputData.jointNumberOfCustomer)
            }

        }
        else{
            this.setState({


                inputData: inputData,
                varValue: varValue,
                showValue: true,
                getData: true,
            });
            this.dynamicApiCall("nationality", "country");
            this.dynamicApiCall("state", "state");
            this.dynamicApiCall("city", "city");
            this.dynamicApiCall("countryCode", "phoneCountryCode")
        }




    }

    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.handleSubmit(event);

        }

    };
    handleChangeIndividual = (event) => {

        let data = event.target.checked;

        this.state.inputData[event.target.name] = data;
        this.forceUpdate();
        this.setState({
            individualDedupeCheckbox: data
        })


    };
    handleChangeSdnCheckbox = (event) => {

        let data = event.target.checked;

        this.state.inputData[event.target.name] = data;
        this.forceUpdate();
        this.setState({
            manualSdnCheckbox: data
        })


    };
    renderindividualDedupeCheckbox = () => {
        if (this.state.individualDedupeCheckbox || this.props.merchantDedup===true) {
            return (
                <Grid item xs={12}>
                    <Grid container>

                        <ThemeProvider theme={theme}>
                            <Grid item xs={12}>
                                <label className="input-label-common">Number Of Customer * </label>
                            </Grid>
                            <Grid item xs={2}>
                                <Autocomplete onChange={(event, option) => this.handleChangeJoint(event, option)}
                                              required={true}

                                              options={this.props.merchantDedup===true?numberOfCustomerForMerchant:numberOfCustomer} getOptionLabel={option => option.title}
                                              renderInput={(params) => <TextField {...params} variant="outlined"
                                                                                  helperText={this.state.handleChangeJoint > 1 ? "" : "required"}
                                                                                  error={this.state.handleChangeJoint > 1 ? false : true}
                                                                                  style={{paddingRight: 20}}
                                                                                  fullWidth/>}
                                />
                            </Grid>


                            <Grid item xs={9}>

                                <button

                                    onClick={this.handleSubmitWithoutProprietorshipAccountForm}
                                    className="btn btn-danger btn-sm">
                                    GO
                                </button>
                            </Grid>
                            <Grid item xs={12}>
                            </Grid>


                        </ThemeProvider>
                    </Grid>
                </Grid>
            )
        }
    };
    renderindividualSdnCheckbox = () => {
        if (this.state.manualSdnCheckbox) {
            return (
                <Grid item xs={12}>
                    <Grid container>

                        <ThemeProvider theme={theme}>
                            <Grid item xs={12}>
                                <label className="input-label-common">Number Of SDN Customer</label>
                            </Grid>
                            <Grid item xs={2}>
                                <Autocomplete
                                    required={true}
                                    onChange={(event, option) => this.handleChangeSdn(event, option)}
                                    options={this.props.merchantDedup===true?numberOfCustomerForMerchant:numberOfCustomer}  getOptionLabel={option => option.title}
                                    renderInput={(params) => <TextField {...params} variant="outlined"
                                                                        style={{paddingRight: 20}}
                                                                        fullWidth/>}
                                />
                            </Grid>


                            <Grid item xs={9}>

                                <button

                                    onClick={this.handleSubmitSdnAccountForm}
                                    className="btn btn-danger btn-sm">
                                    GO
                                </button>
                            </Grid>
                            <Grid item xs={12}>
                            </Grid>


                        </ThemeProvider>
                    </Grid>
                </Grid>
            )
        }
    };
    renderProprietorJsonForm = () => {
        if (this.state.individualDedupeCheckbox) {
            objectJsonFromForPropertership = [
                {
                    "varName": "cbNumber",
                    "type": "textDedup",
                    "label": "CB Number",
                    "errorMessage": "Error",
                    "grid": 2,


                },
                {
                    "varName": "accountNumber",
                    "type": "textDedup",
                    "label": "Account Number",
                    "grid": 2,

                },

                {
                    "varName": "customerName",
                    "type": "textDedup",
                    "label": "Customer Name *",
                    "asteriks": true,
                    "multiline": true,
                    "grid": 2,


                },
                {
                    "varName": "nid",
                    "type": "textDedup",
                    "validation": "nid",
                    "label": "NID +",
                    "errorMessage": "Error",
                    "grid": 2,

                },
                {
                    "varName": "smartCard",
                    "type": "textDedup",
                    "validation": "smartCard",
                    "label": "Smart Card +",
                    "errorMessage": "Error",
                    "grid": 2,


                },
                {
                    "varName": "passport",
                    "type": "textDedup",
                    "validation": "passport",
                    "label": "Passport +",
                    "grid": 2,


                },
                {
                    "varName": "registrationNo",
                    "type": "textDedup",
                    "validation": "birthCertificateNumber",
                    "label": "Birth Cer. +",
                    "grid": 2,
                },
                {
                    "varName": "drivingLicense",
                    "type": "textDedup",
                    "label": "Driving License",
                    "grid": 2,
                },
                {
                    "varName": "dob",
                    "type": "date",
                    "label": "Date Of Birth *",
                    "asteriks": true,
                    "maxDate": true,
                    "grid": 2,


                },
                {
                    "varName": "mobileTypeIndividual",
                    "type": "select",
                    "enum": [
                        "Local",
                        "Overseas",
                    ],
                    // "required":true,
                    "label": "Mobile Type *",
                    "asteriks": true,

                    "grid": 2,
                },

                {
                    "varName": "countryCodeIndividual",
                    "type": "autoCompleteValueReturn",
                    "enum": [],
                    "label": "Country Code ",
                    "asteriks": true,
                    "conditional": true,
                    "conditionalVarName": "mobileTypeIndividual",
                    "conditionalVarValue": "Overseas",
                    "grid": 2,
                },
                {
                    "varName": "phone",
                    "type": "textDedup",
                    "phonePrefix": true,
                    "validation": "phone",
                    "label": "Mobile Number *",
                    "asteriks": true,
                    "conditional": true,
                    "conditionalVarName": "mobileTypeIndividual",
                    "conditionalVarValue": "Local",
                    "grid": 2,


                },
                {
                    "varName": "phone",
                    "type": "textDedup",
                    "label": "Mobile Number",
                    "asteriks": true,
                    "conditional": true,
                    "conditionalVarName": "mobileTypeIndividual",
                    "conditionalVarValue": "Overseas",
                    "grid": 2,


                },
                {
                    "varName": "email",
                    "type": "textDedup",
                    "validation": "email",
                    "multiline": true,
                    "label": "Email",
                    "grid": 2,


                },


                {
                    "varName": "tin",
                    "type": "textDedup",
                    "etin": true,
                    "validation": "etin",
                    "label": "E-Tin",
                    "grid": 2,


                },

                {
                    "varName": "nationality",
                    "type": "autoCompleteValueReturn",
                    "enum": [],
                    "label": "Nationality *",
                    "asteriks": true,

                    "grid": 2,
                },
                {
                    "type": "empty",
                    "grid": 12,
                },
                {
                    "varName": "mandate",
                    "type": "select",
                    "enum": [
                        "YES",
                        "NO"
                    ],
                    "label": "Mandate ",

                    "grid": 2,

                },
                {
                    "varName": "beneficiary",
                    "type": "select",
                    "enum": [
                        "YES",
                        "NO"
                    ],
                    "label": "Beneficial Owner",

                    "grid": 2,

                },


            ];
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(objectJsonFromForPropertership), this.updateComponent, this.onKeyDownForDedup)

            )
        }
    };
    renderSearchFormForMerchant=()=>{
        if(this.state.getData && this.props.merchantDedup===true){
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(dedupJsonForm) , this.updateComponent, this.onKeyDownForDedup)
            )

        }
    }
    makeReadOnlyObject = (object) => {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;
        if(this.props.readOnlyField===true){
            for (let i = 0; i < returnObjectVariables.length; i++) {
                returnObjectVariables[i]["readOnly"] = true;
            }
        }

        return returnObject;


    };
    renderSearchForm = () => {
        if (this.state.getData && this.state.apiGetData) {
            if (this.state.apiGetData && (this.state.content === 'INDIVIDUAL' || this.state.content === 'Individual A/C')) {
                if (this.state.inputData.mandate === "YES" && this.state.inputData.beneficiary === "YES") {
                    return (
                        CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(CSjsonFormIndividualAccountOpeningSearch.concat(CSjsonFormIndividualAccountOpeningSearchmandate, CSjsonFormIndividualAccountOpeningSearchBeneficiary)), this.updateComponent, this.onKeyDownForDedup)
                    )
                } else if (this.state.inputData.mandate === "YES") {
                    return (
                        CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(CSjsonFormIndividualAccountOpeningSearch.concat(CSjsonFormIndividualAccountOpeningSearchmandate)), this.updateComponent, this.onKeyDownForDedup)
                    )
                } else if (this.state.inputData.beneficiary === "YES") {
                    return (
                        CommonJsonFormComponent.renderJsonForm(this.state,this.makeReadOnlyObject( CSjsonFormIndividualAccountOpeningSearch.concat(CSjsonFormIndividualAccountOpeningSearchBeneficiary)), this.updateComponent, this.onKeyDownForDedup)
                    )
                } else if (this.state.inputData.mandate !== "YES" && this.state.inputData.beneficiary !== "YES") {
                    return (
                        CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(CSjsonFormIndividualAccountOpeningSearch), this.updateComponent, this.onKeyDownForDedup)
                    )
                }

            }
            else if (this.state.apiGetData && this.state.content === 'Individual Customer' && this.state.content !== 'Joint Type') {
                return (
                    <Grid item xs={12}>
                        <Grid container>

                            <ThemeProvider theme={theme}>
                                <Grid item xs={12}>
                                    <label className="input-label-common">Number Of Customer *</label>
                                </Grid>
                                <Grid item xs={2}>
                                    <Autocomplete onChange={(event, option) => this.handleChangeJoint(event, option)}
                                        // defaultValue={this.state.handleChangeJoint}
                                                  options={this.props.merchantDedup===true?numberOfCustomerForMerchant:numberOfCustomer}  getOptionLabel={option => option.title}
                                                  renderInput={(params) => <TextField {...params} variant="outlined"

                                                                                      style={{paddingRight: 20}}
                                                                                      fullWidth/>}
                                    />
                                </Grid>


                                <Grid item xs={9}>

                                    <button

                                        onClick={this.handleSubmitJointAccountForm}
                                        className="btn btn-danger btn-sm">
                                        GO
                                    </button>
                                </Grid>
                                <Grid item xs={12}>
                                </Grid>


                            </ThemeProvider>


                        </Grid>


                    </Grid>

                )
            }
            else if (this.state.apiGetData && this.state.content === 'Joint Type' && this.state.content !== 'Individual Customer') {
                return (
                    <Grid item xs={12}>
                        <Grid container>

                            <ThemeProvider theme={theme}>
                                <Grid item xs={12}>
                                    <label className="input-label-common">CB or Individual Dedupe</label>
                                </Grid>
                                <Grid item xs={2}>
                                    <Autocomplete
                                        onChange={(event, option) => this.handleChangeJointType(event, option)}
                                        // defaultValue={this.state.handleChangeJointType}
                                        options={jointType} getOptionLabel={option => option.title}
                                        renderInput={(params) => <TextField {...params} variant="outlined"

                                                                            style={{paddingRight: 20}}
                                                                            fullWidth/>}
                                    />
                                </Grid>


                                {/*   <Grid item xs={9}>

                                    <button

                                        onClick={this.handleSubmitJointAccountForm}
                                        className="btn btn-danger btn-sm">
                                        GO
                                    </button>
                                </Grid>
                                <Grid item xs={12}>
                                </Grid>*/}


                            </ThemeProvider>


                        </Grid>


                    </Grid>

                )
            } else if (this.state.apiGetData && this.state.content === 'Existing Joint CB /  Account') {
                if (this.state.inputData.mandate === "YES" && this.state.inputData.beneficiary === "YES") {
                    return (
                        CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(jointCbNumber.concat(CSjsonFormIndividualAccountOpeningSearchmandate, CSjsonFormIndividualAccountOpeningSearchBeneficiary)), this.updateComponent, this.onKeyDownForDedup)
                    )
                } else if (this.state.inputData.mandate === "YES") {
                    return (
                        CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(jointCbNumber.concat(CSjsonFormIndividualAccountOpeningSearchmandate)), this.updateComponent, this.onKeyDownForDedup)
                    )
                } else if (this.state.inputData.beneficiary === "YES") {
                    return (
                        CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(jointCbNumber.concat(CSjsonFormIndividualAccountOpeningSearchBeneficiary)), this.updateComponent, this.onKeyDownForDedup)
                    )
                } else if (this.state.inputData.mandate !== "YES" && this.state.inputData.beneficiary !== "YES") {
                    return (
                        CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(jointCbNumber), this.updateComponent, this.onKeyDownForDedup)
                    )
                }
            } else if (this.state.apiGetData && this.state.content === 'Joint Account') {
                if (this.state.inputData.mandate === "YES" && this.state.inputData.beneficiary === "YES") {
                    return (
                        CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(this.state.objectForJoinAccount.concat(CSjsonFormIndividualAccountOpeningSearchmandate, CSjsonFormIndividualAccountOpeningSearchBeneficiary)), this.updateComponent, this.onKeyDownForDedup)
                    )
                } else if (this.state.inputData.mandate === "YES") {
                    return (
                        CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(this.state.objectForJoinAccount.concat(CSjsonFormIndividualAccountOpeningSearchmandate)), this.updateComponent, this.onKeyDownForDedup)
                    )
                } else if (this.state.inputData.beneficiary === "YES") {
                    return (
                        CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(this.state.objectForJoinAccount.concat(CSjsonFormIndividualAccountOpeningSearchBeneficiary)), this.updateComponent, this.onKeyDownForDedup)
                    )
                } else if (this.state.inputData.mandate !== "YES" && this.state.inputData.beneficiary !== "YES") {
                    return (
                        CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(this.state.objectForJoinAccount), this.updateComponent, this.onKeyDownForDedup)
                    )
                }

            } else if (this.state.apiGetData && this.state.content === 'NONINDIVIDUAL' || this.state.content === 'Proprietorship A/C') {

                if (this.props.companyDedupeBlock !== true && this.state.inputData.mandate === "YES" && this.state.inputData.beneficiary === "YES") {

                    return (
                        <React.Fragment>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(CSjsonFormNonIndividualProprietorshipAccountOpeningSearch), this.updateComponent, this.onKeyDownForDedup)
                            }
                            {this.renderIndividualDedupe()}

                            {this.renderProprietorJsonForm()}
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(CSjsonFormIndividualAccountOpeningSearchmandate.concat(CSjsonFormIndividualAccountOpeningSearchBeneficiary)), this.updateComponent, this.onKeyDownForDedup)
                            }

                        </React.Fragment>
                    )
                } else if (this.props.companyDedupeBlock !== true && this.state.inputData.mandate === "YES") {
                    return (
                        <React.Fragment>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(CSjsonFormNonIndividualProprietorshipAccountOpeningSearch), this.updateComponent, this.onKeyDownForDedup)
                            }
                            {this.renderIndividualDedupe()}

                            {this.renderProprietorJsonForm()}
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(CSjsonFormIndividualAccountOpeningSearchmandate), this.updateComponent, this.onKeyDownForDedup)

                            }

                        </React.Fragment>
                    )

                } else if (this.props.companyDedupeBlock !== true && this.state.inputData.beneficiary === "YES") {
                    return (
                        <React.Fragment>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(CSjsonFormNonIndividualProprietorshipAccountOpeningSearch), this.updateComponent, this.onKeyDownForDedup)
                            }
                            {this.renderIndividualDedupe()}

                            {this.renderProprietorJsonForm()}
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(CSjsonFormIndividualAccountOpeningSearchBeneficiary), this.updateComponent, this.onKeyDownForDedup)
                            }

                        </React.Fragment>
                    )

                } else if (this.state.inputData.mandate !== "YES" && this.state.inputData.beneficiary !== "YES") {
                    return (
                        <React.Fragment>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(CSjsonFormNonIndividualProprietorshipAccountOpeningSearch), this.updateComponent, this.onKeyDownForDedup)
                            }
                            {this.props.companyDedupeBlock !== true && this.renderIndividualDedupe()}

                            {this.props.companyDedupeBlock !== true && this.renderProprietorJsonForm()}


                        </React.Fragment>
                    )
                }

            } else if (this.state.apiGetData && (this.state.content === 'Partnership A/C' || this.state.content === 'Private/Public Limited Company Account' || this.state.content === 'NGO/NPO/Club/Society Account' || this.state.content === 'Educational/Religious Institution Account' || this.state.content === 'Government Account' || this.state.content === 'Other Account')) {
                return (
                    <React.Fragment>
                        {CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(CSjsonFormNonIndividualProprietorshipAccountOpeningSearch), this.updateComponent, this.onKeyDownForDedup)}
                        {this.renderIndividualDedupe()}
                        {this.renderManualSDN()}

                        {this.renderindividualDedupeCheckbox()}
                        {this.renderindividualSdnCheckbox()}
                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(this.state.objectForJoinAccount), this.updateComponent, this.onKeyDownForDedup)

                        }
                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(this.state.objectForSdnAccount), this.updateComponent, this.onKeyDownForDedup)

                        }
                    </React.Fragment>
                )


            }
            /*   else if (this.state.apiGetData && this.state.content === 'Company Account') {
                   return (
                       <React.Fragment>
                           {CommonJsonFormComponent.renderJsonForm(this.state, CSjsonFormNonIndividualCompanyAccountOpeningSearch, this.updateComponent, this.onKeyDownForDedup)}
                           { this.renderIndividualDedupe()}
                           {this.renderindividualDedupeCheckbox()}
                           {
                               CommonJsonFormComponent.renderJsonForm(this.state, this.state.objectForJoinAccount, this.updateComponent, this.onKeyDownForDedup)

                           }
                       </React.Fragment>
                   )


               }
               else if (this.state.apiGetData && (this.state.content === 'NGO/NPO/Club/Society Account' || this.state.content === "NGO/NPO/Club/Society Account")) {
                   return (
                       <React.Fragment>
                           {CommonJsonFormComponent.renderJsonForm(this.state, CSjsonFormNonIndividualNGOSOCIETYAccountOpeningSearch, this.updateComponent, this.onKeyDownForDedup)}
                           { this.renderIndividualDedupe()}
                           {this.renderindividualDedupeCheckbox()}
                           {
                               CommonJsonFormComponent.renderJsonForm(this.state, this.state.objectForJoinAccount, this.updateComponent, this.onKeyDownForDedup)

                           }
                       </React.Fragment>
                   )


               }
               else if (this.state.apiGetData && this.state.content === 'NGO/NPO/Club/Society Account') {
                   return (
                       <React.Fragment>
                           {CommonJsonFormComponent.renderJsonForm(this.state, CSjsonFormNonIndividualNGOSOCIETYAccountOpeningSearch, this.updateComponent, this.onKeyDownForDedup)}
                           { this.renderIndividualDedupe()}
                           {this.renderindividualDedupeCheckbox()}
                       </React.Fragment>

                   )

               }
               else if (this.state.apiGetData && this.state.content === 'Partnership A/C' || this.state.content === 'Private/Public Limited Company Account' || this.state.content === 'Other Account') {
                   return (
                       <React.Fragment>
                           {CommonJsonFormComponent.renderJsonForm(this.state, CSjsonFormNonIndividualCompanyAccountOpeningSearch, this.updateComponent, this.onKeyDownForDedup)}
                           { this.renderIndividualDedupe()}
                           {this.renderindividualDedupeCheckbox()}

                       </React.Fragment>

                   )


               }*/
            else {
            }
        }


    };
    renderIndividualDedupe = () => {
        return (
            <Grid item xs={12}>
                <FormControlLabel

                    control={
                        <CheckBox
                            checked={this.state.inputData.individualDedupeCheckbox}
                            //defaultValue={this.state.inputData.individualDedupeCheckbox}
                            name="individualDedupeCheckbox"
                            label="Individual Dedupe?"
                            disabled={true}
                            onChange={(event) => this.handleChangeIndividual(event)}/>


                    }
                    label="Individual Dedupe?"
                />

            </Grid>
        )
    };
    renderManualSDN = () => {
        return (
            <Grid item xs={12}>
                <FormControlLabel

                    control={
                        <CheckBox
                            checked={this.state.inputData.manualSdnCheckbox}
                            //defaultValue={this.state.inputData.individualDedupeCheckbox}
                            name="manualSdnCheckbox"
                            label="Manual SDN?"
                            onChange={(event) => this.handleChangeSdnCheckbox(event)}/>


                    }
                    label="Manual SDN?"
                />
            </Grid>
        )
    };
    handleChange = (name, value) => {
        this.state.inputData = {};
        this.state.varValue = {};
        let inputData = this.state.inputData;
        let varValue = this.state.varValue;
        inputData.individualDedupeCheckbox = true;
        varValue.individualDedupeCheckbox = true;
        inputData.mobileTypeIndividual = "Local";
        inputData.mobileTypeMandate = "Local";
        inputData.mobileTypeBeneficiary = "Local";
        inputData.mobileTypeProprietorship = "Local";
        inputData.mobileTypeWithoutProprietorship = "Local";
        inputData.mobileTypeWithoutProprietorshipCompany = "Local";
        inputData.mobileTypeNonIndividualCompany = "Local";
        inputData.mobileTypeNGOSOCIETY = "Local";
        inputData.mobileTypeIndividualProprietorship = "Local";
        varValue.mobileTypeIndividual = "Local";
        varValue.mobileTypeMandate = "Local";
        varValue.mobileTypeBeneficiary = "Local";
        varValue.mobileTypeProprietorship = "Local";
        varValue.mobileTypeWithoutProprietorship = "Local";
        varValue.mobileTypeWithoutProprietorshipCompany = "Local";
        varValue.mobileTypeNonIndividualCompany = "Local";
        varValue.mobileTypeNGOSOCIETY = "Local";
        varValue.mobileTypeIndividualProprietorship = "Local";
        inputData.mandatenationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        inputData.beneficiarynationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        inputData.nationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        varValue.mandatenationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        varValue.beneficiarynationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        varValue.nationality = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
        inputData.mandate = "NO";
        varValue.mandate = "NO";
        inputData.beneficiary = "NO";
        varValue.beneficiary = "NO";
        inputData.dedupeType = value;
        varValue.dedupeType = value;
        this.forceUpdate();

        if (value === 'INDIVIDUAL' || value === "Individual A/C") {
            this.setState({
                individualLabel: 'Individual A/C', initialization: true, inputData: inputData,
                varValue: varValue,
                mandateBoolean: false,
                beneficiaryBoolean: false,
            })
        }
        if (value === 'Joint A/C') {
            this.setState({
                individualLabel: 'Joint A/C', initialization: true, inputData: inputData,
                varValue: varValue,
                mandateBoolean: false,
                beneficiaryBoolean: false,
            })
        }
        if (value === 'NONINDIVIDUAL') {
            this.setState({
                NonIndividualabel: 'Proprietorship A/C',
                "individualLabel": "",
                initialization: true,
                inputData: inputData,
                varValue: varValue,
                searchTableSdnData: false,
                sdnDataForNonIndividualFlag: false,
                mandateBoolean: false,
                beneficiaryBoolean: false,
            })
        }
        this.setState({

            tabMenuSelect: value,
            content: value,
            getType: value,
            searchTableData: null,
            searchTableSdnData: false,
            sdnDataForNonIndividualFlag: false,
            searchTableRelatedData: null,
            err: false,
            errorArray: {},
            errorMessages: {},

        })
    };


    renderJointSearchtabledata = () => {
        if (this.state.content === "Joint Account" || this.state.content === "Existing Joint CB /  Account") {
            return (
                this.state.jointSearchTable.map((tableData, index) => {
                    return (

                        <div>
                            <center>
                                <br/>
                                <Grid item xs={12}>
                                    <h3>{this.numberToCharacter(index + 1) + " Applicant "}</h3>

                                </Grid>
                            </center>
                            <RadioGroup
                                name="customer"
                                value={this.state.jointGetTaggingName[index + 1] === undefined ? "" : this.state.jointGetTaggingName[index + 1].id}
                            >

                                <Table
                                    tableHovor="yes"
                                    tableHeaderColor="primary"
                                    tableHead={["Checkbox", "Customer ID", "CB No", "CB Type", "Source", "Customer Name", "NID", "Passport", "E-Tin", "Match By", "Match-Type", "360 View"]}

                                    tableData={tableData}
                                    tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', "left", "left"]}
                                />
                            </RadioGroup>
                        </div>

                    )
                })
            )


        } else if (this.state.jointSearchTable !== undefined && this.state.jointSearchTable.length > 0) {

            return (
                this.state.jointSearchTable.map((tableData, index) => {
                    return (

                        <div>
                            <center>
                                <br/>
                                <Grid item xs={12}>
                                    <h3>{this.numberToCharacter(index + 1) + " Applicant "}</h3>

                                </Grid>
                            </center>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Customer ID", "CB No", "CB Type", "Source", "Customer Name", "NID", "Passport", "E-Tin", "Match By", "Match-Type", "360 View"]}

                                tableData={tableData}
                                tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', "left"]}
                            />
                        </div>

                    )
                })
            )
        } else {
            return (
                <div>
                    <center>

                        <h6 style={{
                            color: '#ff1f28'
                        }}>Data Not Found</h6>
                        <br/>
                    </center>

                </div>
            )
        }

    };
    returnJointSdnResultTable = (tableData) => {
        if (tableData.length > 0) {
            return (
                <Table
                    tableHeaderColor="primary"

                    tableHead={["Name", "Date Of Birth", "Match Type", "Type", "Country"]}

                    tableData={tableData}
                    tableAllign={['left', 'left', 'left', 'left', 'left']}
                />
            )
        } else {
            return (
                <div>
                    <center>

                        <h6 style={{
                            color: '#ff1f28'
                        }}>No Match Found</h6>
                        <br/>
                    </center>

                </div>
            )
        }

    };
    renderSdnNonindividualResult = () => {
        return (
            this.state.sdnData.map((tableData, index) => {
                return (

                    <div>
                        <center>
                            <Grid item xs={12}>
                                <h5>{this.numberToCharacter(index + 1) + " Applicant "}</h5>

                            </Grid>
                        </center>

                        {this.returnJointSdnResultTable(tableData)}
                    </div>

                )
            })
        )
        /* return (

             <div>
                 <paper>
                     <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                         <h5>SDN Verification</h5>

                     </CardHeader>
                 </paper>



                 <Table
                     tableHeaderColor="primary"

                     tableHead={["Name", "Date Of Birth", "Match Type", "Type", "Country"]}

                     tableData={this.state.sdnData}
                     tableAllign={['left', 'left', 'left', 'left', 'left']}
                 />


             </div>

         )
       */

    };
    renderWithoutProprietorshipSearchtabledata = () => {
        return (
            this.state.jointSearchTable.map((tableData, index) => {
                return (

                    <div>
                        <center>
                            <br/>
                            <Grid item xs={12}>
                                <h3>{this.numberToCharacter(index + 1) + " Applicant "}</h3>

                            </Grid>
                        </center>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Customer ID", "CB No", "CB Type", "Source", "Customer Name", "NID", "Passport", "E-Tin", "Match By", "Match-Type", "360 View"]}

                            tableData={tableData}
                            tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', "left"]}
                        />
                    </div>

                )
            })
        )


    };


    downloadDedupdata = () => {
        if (this.state.searchTableData && this.props.nidBlockShowing === false) {
            return (
                <Button
                    style={{
                        backgroundColor: "red",
                        color: "white",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 3,
                        paddingBottom: 3,
                        borderRadius: 3,
                        border: 1,
                        verticalAlign: 'left',
                        position: "absolute",
                        marginTop: "5px",
                        right: 50,
                    }}
                    onClick={(event) => this.DedupDataDownloadApi(event, this.state.content)}
                >
                    Download Dedupe Result (PDF)
                </Button>

            )

        } else if (this.state.searchTableData) {
            return (
                <Button
                    style={{
                        backgroundColor: "green",
                        color: "white",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 3,
                        paddingBottom: 3,
                        borderRadius: 3,
                        border: 1,
                        verticalAlign: 'left',
                        position: "absolute",
                        marginTop: "5px",
                        right: 200,
                    }}
                    onClick={(event) => this.DedupDataDownloadApi(event, this.state.content)}
                >
                    Download Dedupe Result
                </Button>

            )

        }
    };
    downloadSDNDedupdata = () => {
        if (this.props.nidBlockShowing === false) {
            /* let csvData = [];
             csvData.push(["Customer ID", "CB No", "Source", "Customer Name", "NID", "Passport", "Birth Certificate", "E-Tin", "Phone", "Email", "Nationality"]);
             if (this.state.content === "INDIVIDUAL" || this.state.content === "Individual A/C") {

                 if (this.state.individualDedupData.highMatchCustomers !== null && Array.isArray(this.state.individualDedupData.highMatchCustomers)) {
                     this.state.individualDedupData.highMatchCustomers.map((data) => {
                         csvData.push([data.identification_no_mod, data.maincustid, data.sz_source, data.sz_full_name, data.sz_national_id, data.sz_passport, data.sz_registration_no, data.sz_tin_number, data.cust_comu_phone_num, data.email_id, null])
                     });
                 }
                 if (this.state.individualDedupData.mediumMatchCustomers !== null && Array.isArray(this.state.individualDedupData.mediumMatchCustomers)) {
                     this.state.individualDedupData.mediumMatchCustomers.map((tableArray) => {
                         csvData.push([tableArray.identification_no_mod, tableArray.maincustid, tableArray.sz_source, tableArray.sz_full_name, tableArray.sz_national_id, tableArray.sz_passport, tableArray.sz_registration_no, tableArray.sz_tin_number, tableArray.cust_comu_phone_num, tableArray.email_id, null])
                     });
                 }
                 if (this.state.individualDedupData.relatedCustomers !== null && Array.isArray(this.state.individualDedupData.relatedCustomers)) {
                     this.state.individualDedupData.relatedCustomers.map((related) => {
                         csvData.push([related.identificationNoMod, related.mainCB, null, related.relatedCustomerName, null, null, null, null, null, null, null])
                     })
                 }
             } else if (this.state.content === "Joint Account") {
                 if (this.state.jointDedupData.highMatchCustomers !== null && Array.isArray(this.state.jointDedupData.highMatchCustomers)) {
                     this.state.jointDedupData.highMatchCustomers.map((dedup, i) => {
                         dedup.map((dedup) => {
                             csvData.push([dedup.identification_no_mod, dedup.maincustid, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_registration_no, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.email_id, null]);
                         })

                     });
                 }
                 if (this.state.jointDedupData.relatedCustomers !== null && Array.isArray(this.state.jointDedupData.relatedCustomers)) {
                     this.state.jointDedupData.relatedCustomers.map((related) => {
                         csvData.push([related.identificationNoMod, related.mainCB, null, related.relatedCustomerName, null, null, null, null, null, null, null])
                     });
                 }

             } else if (this.state.content === "Proprietorship A/C" || this.state.content === "NONINDIVIDUAL" && (this.state.downloadDataForCompany && this.state.downloadDataForCompanyProprietor)) {
                 //Company Result
                 if (this.state.companyDedupData.highMatchCustomers !== null && Array.isArray(this.state.companyDedupData.highMatchCustomers)) {
                     this.state.companyDedupData.highMatchCustomers.map((data) => {
                         csvData.push([data.identification_no_mod, data.maincustid, data.sz_source, data.sz_full_name, data.sz_national_id, data.sz_passport, data.sz_registration_no, data.sz_tin_number, data.cust_comu_phone_num, data.email_id, null])
                     });
                 }
                 if (this.state.companyDedupData.mediumMatchCustomers !== null && Array.isArray(this.state.companyDedupData.mediumMatchCustomers)) {
                     this.state.companyDedupData.mediumMatchCustomers.map((tableArray) => {
                         csvData.push([tableArray.identification_no_mod, tableArray.maincustid, tableArray.sz_source, tableArray.sz_full_name, tableArray.sz_national_id, tableArray.sz_passport, tableArray.sz_registration_no, tableArray.sz_tin_number, tableArray.cust_comu_phone_num, tableArray.email_id, null])
                     });
                 }
                 //Proprietor Result  Result
                 if (this.state.individualDedupData.highMatchCustomers !== null && Array.isArray(this.state.individualDedupData.highMatchCustomers)) {
                     this.state.individualDedupData.highMatchCustomers.map((data) => {
                         csvData.push([data.identification_no_mod, data.maincustid, data.sz_source, data.sz_full_name, data.sz_national_id, data.sz_passport, data.sz_registration_no, data.sz_tin_number, data.cust_comu_phone_num, data.email_id, null])
                     });
                 }
                 if (this.state.individualDedupData.mediumMatchCustomers !== null && Array.isArray(this.state.individualDedupData.mediumMatchCustomers)) {
                     this.state.individualDedupData.mediumMatchCustomers.map((tableArray) => {
                         csvData.push([tableArray.identification_no_mod, tableArray.maincustid, tableArray.sz_source, tableArray.sz_full_name, tableArray.sz_national_id, tableArray.sz_passport, tableArray.sz_registration_no, tableArray.sz_tin_number, tableArray.cust_comu_phone_num, tableArray.email_id, null])
                     });
                 }
                 if (this.state.companyDedupData.relatedCustomers !== null && Array.isArray(this.state.companyDedupData.relatedCustomers)) {
                     this.state.companyDedupData.relatedCustomers.map((related) => {
                         csvData.push([related.identificationNoMod, related.mainCB, null, related.relatedCustomerName, null, null, null, null, null, null, null])
                     });
                 }
                 /!* this.state.individualDedupData.relatedCustomers.map((related) => {

                           csvData.push([related.identificationNoMod, related.mainCB, null, related.relatedCustomerName, null, null, null, null, null, null, null])

                       });*!/
             } else if (this.state.content === "Company Account" && (this.state.downloadDataForCompany && this.state.downloadDataForCompanyProprietor)) {
                 //Company Result
                 if (this.state.companyDedupData.highMatchCustomers !== null && Array.isArray(this.state.companyDedupData.highMatchCustomers)) {
                     this.state.companyDedupData.highMatchCustomers.map((data) => {
                         csvData.push([data.identification_no_mod, data.maincustid, data.sz_source, data.sz_full_name, data.sz_national_id, data.sz_passport, data.sz_registration_no, data.sz_tin_number, data.cust_comu_phone_num, data.email_id, null])
                     });
                 }
                 if (this.state.companyDedupData.mediumMatchCustomers !== null && Array.isArray(this.state.companyDedupData.mediumMatchCustomers)) {
                     this.state.companyDedupData.mediumMatchCustomers.map((tableArray) => {
                         csvData.push([tableArray.identification_no_mod, tableArray.maincustid, tableArray.sz_source, tableArray.sz_full_name, tableArray.sz_national_id, tableArray.sz_passport, tableArray.sz_registration_no, tableArray.sz_tin_number, tableArray.cust_comu_phone_num, tableArray.email_id, null])
                     });
                 }
                 //Joint Result  Result
                 if (this.state.jointDedupData.highMatchCustomers !== null && Array.isArray(this.state.jointDedupData.highMatchCustomers)) {
                     this.state.jointDedupData.highMatchCustomers.map((dedup, i) => {
                         dedup.map((dedup) => {
                             csvData.push([dedup.identification_no_mod, dedup.maincustid, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_registration_no, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.email_id, null]);
                         })

                     });
                 }
                 if (this.state.jointDedupData.relatedCustomers !== null && Array.isArray(this.state.jointDedupData.relatedCustomers)) {
                     this.state.jointDedupData.relatedCustomers.map((related) => {
                         csvData.push([related.identificationNoMod, related.mainCB, null, related.relatedCustomerName, null, null, null, null, null, null, null])

                     });
                 }


             }
 */
            return (
                <Button
                    style={{
                        backgroundColor: "red",
                        color: "white",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 3,
                        paddingBottom: 3,
                        borderRadius: 3,
                        border: 1,
                        verticalAlign: 'left',
                        position: "absolute",
                        marginTop: "5px",
                        right: 50,
                    }}
                    onClick={(event) => this.sdnDedupDataDownloadApi(event, this.state.content)}
                >
                    Download SDN Result (PDF)
                </Button>

            )

        }
    };
    open = (e) => {
        e.preventDefault();
        this.setState({
            merchantModal: true
        })
    };

    merchantModal = () => {
        this.setState({
            merchantModal: false
        })
    };
    rendernewAccountCreationbutton = () => {
        if (this.props.merchantDedup!==true && this.props.nidBlockShowing !== false && (this.state.whengetCbnumbernewAccountCreationOff === false || this.state.content === "Joint Account")) {
            //if((this.state.individualDedupeCheckbox && this.state.individualDedupeCheckboxNewButton)|| (this.state.content==="Joint Account" /*|| this.state.content==="INDIVIDUAL" || this.state.content==="Individual A/C"*/) ){
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'left',
                        position: "absolute",
                        marginTop: "5px",
                        right: 10,

                    }}
                    onClick={() => this.renderNewAccountOpeingForm()}>
                    New Account Creation
                </button>
            )
            //}

        }
        else if ( this.props.merchantDedup===true) {
            //if((this.state.individualDedupeCheckbox && this.state.individualDedupeCheckboxNewButton)|| (this.state.content==="Joint Account" /*|| this.state.content==="INDIVIDUAL" || this.state.content==="Individual A/C"*/) ){
            return (
                <button
                    style={{
                        float: 'right',
                        verticalAlign: 'right',
                        backgroundColor: 'red',
                        color: '#ffffff'
                    }}
                    className="btn btn-outline-primary btn-sm"
                    type='submit'
                    onClick={this.open}
                >
                    + New Merchant
                </button>
            )
            //}

        }

    };
    downloadType = () => {
        if (this.props.nidBlockShowing !== false) {
            return ".xlsx"
        } else {
            return ".pdf"
        }
    }
    DedupDataDownloadApi = (event, type) => {
        event.preventDefault();
        let data = {};
        if (this.state.inputData.mandate === "YES") {
            data.mandateDedupData = this.state.mandateindividualDedupData
        }
        if (this.state.inputData.beneficiary === 'YES') {
            data.beneficiaryDedupData = this.state.beneficiaryindividualDedupData
        }
        let downloadurl = backEndServerURL + "/dedup/downloadExcel";
        if (this.props.companyDedupeBlock === true) {
            downloadurl = backEndServerURL + "/companyDedupReport";
        } else if (this.props.nidBlockShowing === false) {

            downloadurl = backEndServerURL + "/dedupReport";
        }
        if (type === "INDIVIDUAL" || type === "Individual A/C") {
            downloadurl = backEndServerURL + "/dedupReport";
            data.individualDedupData = this.state.individualDedupData;
            data.dedupType = type;
            if (this.props.nidBlockShowing === false) {
                data = {};
                data = this.state.postData;
            }
            this.setState({
                loading: true
            })
            FileDownload.downloadFile(downloadurl, "POST", data, "Dedupe Result_" + ((new Date()).toLocaleString('en-US').split(',')[0]).split('T')[0] + this.downloadType(), type)
                .then((res) => {
                    this.setState({
                        loading: false,
                    })
                }).catch((err) => {
                this.setState({
                    loading: false,
                })
            })

        } else if (type === "Joint Account" || type === "Existing Joint CB /  Account") {
            data.jointDedupData = this.state.jointDedupData;

            data.dedupType = "Joint Account";
            if (this.props.nidBlockShowing === false) {
                data = {};
                data = this.state.postData;
            }
            this.setState({
                loading: true
            })
            FileDownload.downloadFile(downloadurl, "POST", data, "Dedupe Result_" + ((new Date()).toLocaleString('en-US').split(',')[0]).split('T')[0] + this.downloadType(), type)
                .then((res) => {
                    this.setState({
                        loading: false,
                    })
                }).catch((err) => {
                this.setState({
                    loading: false,
                })
            })

        } else if (type === "Proprietorship A/C" || type === "NONINDIVIDUAL") {

            data.individualDedupData = this.state.individualDedupData;
            data.companyDedupData = this.state.companyDedupData;
            data.dedupType = type;
            if (this.props.nidBlockShowing === false) {
                data = {};
                data = this.state.postDataProprietorship;
            }
            this.setState({
                loading: true
            })
            FileDownload.downloadFile(downloadurl, "POST", data, "Dedupe Result_" + ((new Date()).toLocaleString('en-US').split(',')[0]).split('T')[0] + this.downloadType(), type)
                .then((res) => {
                    this.setState({
                        loading: false,
                    })
                }).catch((err) => {
                this.setState({
                    loading: false,
                })
            })


        } else if (type === 'Partnership A/C' || type === 'Private/Public Limited Company Account' || type === 'NGO/NPO/Club/Society Account' || type === 'Educational/Religious Institution Account' || type === 'Government Account' || type === 'Other Account') {

            data.jointDedupData = this.state.jointDedupData;
            data.companyDedupData = this.state.companyDedupData;
            data.dedupType = type;
            if (this.props.nidBlockShowing === false) {
                data = {};
                data = this.state.postDataProprietorship;
            }
            this.setState({
                loading: true
            })
            FileDownload.downloadFile(downloadurl, "POST", data, "Dedupe Result_" + ((new Date()).toLocaleString('en-US').split(',')[0]).split('T')[0] + this.downloadType(), type)
                .then((res) => {
                    this.setState({
                        loading: false,
                    })
                }).catch((err) => {
                this.setState({
                    loading: false,
                })
            })
        } else {
            alert("please type select")
        }
    };
    sdnDedupDataDownloadApi = (event, type) => {
        event.preventDefault();
        let data = {};
        if (this.state.inputData.mandate === "YES") {
            data.mandateDedupData = this.state.mandateindividualDedupData
        }
        if (this.state.inputData.beneficiary === 'YES') {
            data.beneficiaryDedupData = this.state.beneficiaryindividualDedupData
        }
        let downloadurl = backEndServerURL + "/dedup/downloadExcel";
        if (this.props.companyDedupeBlock === true) {
            downloadurl = backEndServerURL + "/companySDNReport";
        } else if (this.props.nidBlockShowing === false) {
            downloadurl = backEndServerURL + "/sdnReport";
        }
        if (type === "INDIVIDUAL" || type === "Individual A/C") {
            downloadurl = backEndServerURL + "/sdnReport";
            data.individualDedupData = this.state.individualDedupData;
            data.dedupType = type;
            console.log(data);
            if (this.props.nidBlockShowing === false) {
                data = {};
                data = this.state.postData;
            }
            this.setState({
                loading: true
            })
            FileDownload.downloadFile(downloadurl, "POST", data, "SDN Dedupe Result_" + ((new Date()).toLocaleString('en-US').split(',')[0]).split('T')[0] + this.downloadType(), type)
                .then((res) => {
                    this.setState({
                        loading: false,
                    })
                }).catch((err) => {
                this.setState({
                    loading: false,
                })
            })
        } else if (type === "Joint Account" || type === "Existing Joint CB /  Account") {
            data.jointDedupData = this.state.jointDedupData;

            data.dedupType = "Joint Account";
            if (this.props.nidBlockShowing === false) {
                data = {};
                data = this.state.postData;
            }
            this.setState({
                loading: true
            })
            FileDownload.downloadFile(downloadurl, "POST", data, "SDN Dedupe Result_" + ((new Date()).toLocaleString('en-US').split(',')[0]).split('T')[0] + this.downloadType(), type)
                .then((res) => {
                    this.setState({
                        loading: false,
                    })
                }).catch((err) => {
                this.setState({
                    loading: false,
                })
            })
        } else if (type === "Proprietorship A/C" || type === "NONINDIVIDUAL") {

            data.individualDedupData = this.state.individualDedupData;
            data.companyDedupData = this.state.companyDedupData;
            data.dedupType = type;
            if (this.props.nidBlockShowing === false) {
                data = {};
                data = this.state.postDataProprietorship;
            }
            this.setState({
                loading: true
            })
            FileDownload.downloadFile(downloadurl, "POST", data, "SDN Dedupe Result_" + ((new Date()).toLocaleString('en-US').split(',')[0]).split('T')[0] + this.downloadType(), type)
                .then((res) => {
                    this.setState({
                        loading: false,
                    })
                }).catch((err) => {
                this.setState({
                    loading: false,
                })
            })

        } else if (type === 'Partnership A/C' || type === 'Private/Public Limited Company Account' || type === 'NGO/NPO/Club/Society Account' || type === 'Educational/Religious Institution Account' || type === 'Government Account' || type === 'Other Account') {

            data.jointDedupData = this.state.jointDedupData;
            data.companyDedupData = this.state.companyDedupData;
            data.dedupType = type;
            if (this.props.nidBlockShowing === false) {
                data = {};
                data = this.state.postDataProprietorship;
            }
            this.setState({
                loading: true
            })
            FileDownload.downloadFile(downloadurl, "POST", data, "SDN Dedupe Result_" + ((new Date()).toLocaleString('en-US').split(',')[0]).split('T')[0] + this.downloadType(), type)
                .then((res) => {
                    this.setState({
                        loading: false,
                    })
                }).catch((err) => {
                this.setState({
                    loading: false,
                })
            })
        } else {
            alert("please type select")
        }
    };
    searchTableResultForMandate = (classes) => {
        if (!(this.state.getError) && this.state.mandateBoolean) {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>

                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>
                                <React.Fragment>
                                    <paper>
                                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                            <h5>Mandate Result </h5>

                                        </CardHeader>
                                    </paper>

                                    <Table

                                        tableHovor="yes"
                                        tableHeaderColor="primary"
                                        tableHead={["Customer ID", "CB No", "CB Type", "Source", "Customer Name", "NID", "Passport", "E-Tin", "Match By", "Match-Type", "360 View"]}

                                        tableData={this.state.mandateDedupData}
                                        tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', "left"]}
                                    />

                                    <br/>


                                </React.Fragment></div>
                        </Card>
                    </GridItem>

                </GridContainer>


            )


        }


    };
    searchTableResultForBeneficiary = (classes) => {
        if (!(this.state.getError) && this.state.beneficiaryBoolean) {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>

                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>
                                <React.Fragment>
                                    <paper>
                                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                            <h5> Beneficial Owner Result </h5>

                                        </CardHeader>
                                    </paper>

                                    <Table

                                        tableHovor="yes"
                                        tableHeaderColor="primary"
                                        tableHead={["Customer ID", "CB No", "CB Type", "Source", "Customer Name", "NID", "Passport", "E-Tin", "Match By", "Match-Type", "360 View"]}

                                        tableData={this.state.beneficiaryDedupData}
                                        tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', "left"]}
                                    />

                                    <br/>


                                </React.Fragment></div>
                        </Card>
                    </GridItem>

                </GridContainer>


            )


        }


    };
    searchTableData = (style) => {
        if (!(this.state.getError) && this.state.searchTableData && (this.state.content === 'INDIVIDUAL' || this.state.content === 'Individual A/C')) {
            return (

                <React.Fragment>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>Search Result </h5>

                        </CardHeader>
                    </paper>

                    <div>
                        {this.downloadDedupdata()}
                        {this.rendernewAccountCreationbutton()}

                    </div>
                    <br/>
                    <br/>
                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Customer ID", "CB No", "CB Type", "Source", "Customer Name", "NID", "Passport", "E-Tin", "Match By", "Match-Type", "    Action", "360 View"]}

                        tableData={this.state.dedupData}
                        tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', "left", "left", "left"]}
                    />

                    <br/>


                </React.Fragment>

            )


        } else if (!(this.state.getError) && (this.state.searchTableRelatedData || this.state.companyDedupeBlock === true) && (this.state.content !== 'Joint Account' && this.state.content !== "Existing Joint CB /  Account" && this.state.content !== 'Individual A/C' && this.state.content !== 'INDIVIDUAL')) {

            return (
                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>Company/Business Result </h5>

                        </CardHeader>
                    </paper>

                    <div>
                        {this.downloadDedupdata()}
                        {this.rendernewAccountCreationbutton()}

                    </div>
                    <br/>
                    <br/>

                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Customer ID", "CB No", "CB Type", "Source", "Customer Name", "NID", "Passport", "E-Tin", "Match By", "Match-Type", "  Action", "360 View"]}

                        tableData={this.state.propritorshipData}
                        tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', "left", "left", "left"]}
                    />

                    <br/>


                </div>


            )
        } else if (!(this.state.getError) && this.state.searchTableRelatedData && (this.state.content == 'Joint Account')) {

            return (
                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>Related CB </h5>

                        </CardHeader>
                    </paper>
                    <div>
                        {this.downloadDedupdata()}
                        {this.rendernewAccountCreationbutton()}

                    </div>
                    <br/>
                    <br/>
                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Customer ID", /*"CB Type", */"Main CB", "Related CB", "Customer Name", "RelationShip", "Action", ""]}

                        tableData={this.state.relatedData}
                        tableAllign={['left', 'left', 'left', 'left', 'left', "left"]}
                    />


                    <br/>


                </div>


            )


        } else if (!(this.state.getError) && this.state.searchTableRelatedData && (this.state.content === "Existing Joint CB /  Account")) {

            return (
                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>Related CB </h5>

                        </CardHeader>
                    </paper>
                    <div>
                        {this.downloadDedupdata()}
                        {/*{this.rendernewAccountCreationbutton()}*/}

                    </div>
                    <br/>
                    <br/>
                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Customer ID", "CB Type", "CB Number", "Source", "Customer Name", "Action", ""]}

                        tableData={this.state.relatedData}
                        tableAllign={['left', 'left', 'left', 'left', 'left', "left", "left"]}
                    />


                    <br/>


                </div>


            )


        } else if (this.state.mandateBoolean || this.state.beneficiaryBoolean) {
            return (<CircularProgress style={{marginLeft: '50%'}}/>)
        }


    };
    searchTableRelatedData = (style) => {
        if (!(this.state.getError) && this.state.searchTableRelatedData && (this.state.content === 'INDIVIDUAL' || this.state.content === 'Individual A/C')) {

            return (

                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>Related CB</h5>

                        </CardHeader>
                    </paper>
                    <br/>
                    <div>

                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Customer ID", "Main CB", "Related CB", "Customer Name", "Relationship"]}

                            tableData={this.state.relatedData}
                            tableAllign={['left', 'left', 'left', 'left', 'left']}
                        />

                        <br/>


                    </div>

                </div>

            )
        } else if (!(this.state.getError) && this.state.searchTableRelatedData && (this.state.content === 'Proprietorship A/C' || this.state.content === "NONINDIVIDUAL")) {
            return (
                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>Proprietor Result</h5>

                        </CardHeader>
                    </paper>

                    <div>

                        {/* <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'left',
                        position: "absolute",
                        right: 10,

                    }}
                    onClick={() => this.renderNewAccountOpeingForm()}>
                    New Account Creation
                </button>*/}

                    </div>

                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Customer ID", "CB No", "CB Type", "Source", "Customer Name", "NID", "Passport", "E-Tin", "Match By", "Match-Type", "360 View"]}

                        tableData={this.state.dedupData}
                        tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', "left", "left"]}
                    />

                    <br/>


                </div>
            )
        } else if (!(this.state.getError) && this.state.searchTableRelatedData && (this.state.content !== 'Joint Account' && this.state.content !== "Existing Joint CB /  Account" && this.state.content !== 'Individual A/C' && this.state.content !== 'INDIVIDUAL')) {
            return (
                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>Partners Dedupe Result</h5>

                        </CardHeader>
                    </paper>

                    {this.renderJointSearchtabledata()}

                </div>


            )


        } else if (!(this.state.getError) && this.state.searchTableRelatedData && ((this.state.content === 'Joint Account' || this.state.content === "Existing Joint CB /  Account") && this.state.content !== 'Individual A/C' && this.state.content !== 'INDIVIDUAL')) {
            return (
                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>Individual Result</h5>

                        </CardHeader>
                    </paper>


                    {this.renderJointSearchtabledata()}
                    <br/>
                </div>


            )


        }
        /*  else if(this.state.mandateBoolean || this.state.beneficiaryBoolean) {
              return (<CircularProgress style={{marginLeft: '50%'}}/>)
          }*/
    };
    searchTableRelatedDataOnlyNonIndividual = (classes) => {
        if (!(this.state.getError) && this.state.searchTableRelatedData && (this.state.content !== 'INDIVIDUAL' && this.state.content !== 'Individual A/C' && this.state.content !== 'Joint Account' && this.state.content !== "Existing Joint CB /  Account")) {

            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>

                                <div>
                                    <paper>
                                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                            <h5>Related CB</h5>

                                        </CardHeader>
                                    </paper>
                                    <br/>
                                    <div>

                                        <Table

                                            tableHovor="yes"
                                            tableHeaderColor="primary"
                                            tableHead={["Customer ID", "Main CB", "Related CB", "Customer Name", "Relationship"]}

                                            tableData={this.state.relatedData}
                                            tableAllign={['left', 'left', 'left', 'left', 'left']}
                                        />

                                        <br/>


                                    </div>

                                </div>
                            </div>
                        </Card>
                    </GridItem>

                </GridContainer>
            )
        }


    };
    renderMandateSdnData = () => {
        if (!(this.state.getError) && this.state.searchTableSdnData && this.state.searchTableData) {

            if (this.state.inputData.mandate === 'YES') {
                if (!(this.state.getError) && this.state.searchTableSdnData && this.state.searchTableData && this.state.sdnDataMandate.length < 1) {
                    return (
                        <div>

                            <center>
                                <center>
                                    <Grid item xs={12}>
                                        <h5>Mandate Applicant</h5>

                                    </Grid>
                                </center>
                                <h6 style={{
                                    color: '#ff1f28'
                                }}>No Match Found</h6>
                            </center>

                        </div>

                    )
                } else {
                    return (
                        <div>
                            <center>
                                <Grid item xs={12}>
                                    <h5>Mandate Applicant</h5>

                                </Grid>
                            </center>
                            <Table
                                tableHeaderColor="primary"

                                tableHead={["Name", "Date Of Birth", "Match Type", "Type", "Country"]}

                                tableData={this.state.sdnDataMandate}
                                tableAllign={['left', 'left', 'left', 'left', 'left']}
                            />
                        </div>


                    )
                }
            }

        }
    };
    renderBeneficiarySdnData = () => {
        if (!(this.state.getError) && this.state.searchTableSdnData && this.state.searchTableData) {


            if (this.state.inputData.beneficiary === 'YES') {
                if (!(this.state.getError) && this.state.searchTableSdnData && this.state.searchTableData && this.state.sdnDataBeneficiary.length < 1) {
                    return (
                        <div>


                            <center>
                                <center>
                                    <Grid item xs={12}>
                                        <h5>Beneficiary Owner Applicant</h5>

                                    </Grid>
                                </center>
                                <h6 style={{
                                    color: '#ff1f28'
                                }}>No Match Found</h6>
                            </center>

                        </div>

                    )
                } else {
                    return (
                        <div>
                            <center>
                                <Grid item xs={12}>
                                    <h5>Beneficiary Owner Applicant</h5>

                                </Grid>
                            </center>
                            <Table
                                tableHeaderColor="primary"

                                tableHead={["Name", "Date Of Birth", "Match Type", "Type", "Country"]}

                                tableData={this.state.sdnDataBeneficiary}
                                tableAllign={['left', 'left', 'left', 'left', 'left']}
                            />
                        </div>

                    )
                }
            }
        }
    };
    searchTableSDNData = (style) => {
        if (!(this.state.getError) && this.state.searchTableSdnData && this.state.searchTableData && (this.state.content === 'Joint Account')) {

            return (
                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>SDN Verification</h5>

                        </CardHeader>
                    </paper>

                    {this.downloadSDNDedupdata()}
                    <br/>
                    {this.renderSdnNonindividualResult()}
                    <br/>
                </div>


            )

        } else if (!(this.state.getError) && this.state.searchTableSdnData && this.state.searchTableData && this.state.sdnData.length < 1 && (this.state.content === 'INDIVIDUAL' || this.state.content === 'Individual A/C')) {
            return (
                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>SDN Verification</h5>

                        </CardHeader>
                    </paper>
                    {this.downloadSDNDedupdata()}
                    <br/>
                    <center>
                        <h5>Individual SDN Result</h5>
                    </center>
                    <center>
                        <h6 style={{
                            color: '#ff1f28'
                        }}>No Match Found</h6>
                    </center>

                </div>

            )
        } else if (!(this.state.getError) && this.state.searchTableSdnData && this.state.searchTableData && this.state.sdnData.length < 1) {
            return (
                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>SDN Verification</h5>

                        </CardHeader>
                    </paper>
                    {this.downloadSDNDedupdata()}
                    <br/>
                    <center>
                        <h5>Company SDN Result</h5>
                    </center>

                    <center>
                        <h6 style={{
                            color: '#ff1f28'
                        }}>No Match Found</h6>
                    </center>

                </div>

            )
        } else if (!(this.state.getError) && this.state.searchTableSdnData && this.state.searchTableData && (this.state.content === 'INDIVIDUAL' || this.state.content === 'Individual A/C')) {


            return (

                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>SDN Verification</h5>

                        </CardHeader>
                    </paper>

                    {this.downloadSDNDedupdata()}
                    <br/>
                    <center>
                        <h5>Individual SDN Result</h5>

                    </center>

                    <Table
                        tableHeaderColor="primary"

                        tableHead={["Name", "Date Of Birth", "Match Type", "Type", "Country"]}

                        tableData={this.state.sdnData}
                        tableAllign={['left', 'left', 'left', 'left', 'left']}
                    />


                </div>

            )

        } else if (!(this.state.getError) && this.state.searchTableSdnData && this.state.searchTableData) {


            return (

                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>SDN Verification</h5>

                        </CardHeader>
                    </paper>

                    {this.downloadSDNDedupdata()}
                    <br/>
                    <center>
                        <h5>Company SDN Result</h5>

                    </center>

                    <Table
                        tableHeaderColor="primary"

                        tableHead={["Name", "Date Of Birth", "Match Type", "Type", "Country"]}

                        tableData={this.state.sdnData}
                        tableAllign={['left', 'left', 'left', 'left', 'left']}
                    />


                </div>

            )

        }


    };
    searchTableSDNDataForNonIndividual = (style) => {
        if (!(this.state.getError) && this.state.sdnDataForNonIndividualFlag && this.state.sdnDataForNonIndividual.length < 1 && (this.state.content === 'NONINDIVIDUAL' || this.state.content === 'Proprietorship A/C')) {
            return (
                <div>
                    <center>
                        <h5>Individual SDN Result</h5>
                    </center>
                    {this.downloadSDNDedupdata()}
                    <br/>
                    <center>
                        <h6 style={{
                            color: '#ff1f28'
                        }}>No Match Found</h6>
                    </center>

                </div>

            )
        } else if (!(this.state.getError) && this.state.sdnDataForNonIndividualFlag && (this.state.content === 'NONINDIVIDUAL' || this.state.content === 'Proprietorship A/C')) {
            return (

                <div>
                    {this.downloadSDNDedupdata()}
                    <br/>
                    <center>
                        <h5>Individual SDN Result</h5>

                    </center>

                    <Table
                        tableHeaderColor="primary"

                        tableHead={["Name", "Date Of Birth", "Match Type", "Type", "Country"]}

                        tableData={this.state.sdnDataForNonIndividual}
                        tableAllign={['left', 'left', 'left', 'left', 'left']}
                    />


                </div>

            )

        } else if (!(this.state.getError) && this.state.sdnDataForNonIndividualFlag && (this.state.content !== 'INDIVIDUAL' && this.state.content !== 'Individual A/C' && this.state.content !== 'Joint Account' && this.state.content !== 'Existing Joint CB /  Account' && this.state.content !== 'NONINDIVIDUAL' && this.state.content !== 'Proprietorship A/C')) {

            return (
                this.state.sdnDataForNonIndividual.map((tableData, index) => {
                    return (

                        <div>
                            <center>
                                <Grid item xs={12}>
                                    <h5>{this.numberToCharacter(index + 1) + " Applicant "}</h5>

                                </Grid>
                            </center>

                            {this.returnJointSdnResultTable(tableData)}
                        </div>

                    )
                })
            )
        }


    };

    searchTableNidVerification = (style) => {

        if (!(this.state.getError) && this.state.searchTableRelatedData && (this.state.content === 'Joint Account' || this.state.content === "Existing Joint CB /  Account")) {

            return (

                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>NID/Smart Card Verification </h5>

                        </CardHeader>

                    </paper>

                    <div>
                        <center>
                            <h4 style={{color: "white"}} >

                                <a
                                    style={{
                                        verticalAlign: 'left',
                                        position: "absolute",

                                        marginTop: "5px",
                                        marginBottom: "10px",
                                    }}
                                    target="_blank"
                                    href="https://prportal.nidw.gov.bd/partner-portal/login">
                                    NID/Smart Card </a> <br/><br/>


                            </h4>
                        </center>

                    </div>
                    {/* <br/>
                    <br/>
                    <Table
                        tableHeaderColor="primary"
                        tableHead={["Customer Name", "Father Name", "NID"]}
                        tableData={[
                            ["", "", ""],


                        ]}
                        tableAllign={['left', 'left', 'left']}
                    />*/}


                </div>

            )
        } else if (!(this.state.getError) && this.state.searchTableRelatedData && this.state.content !== 'Joint Account' && this.state.content !== "Existing Joint CB /  Account") {

            return (

                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>NID/Smart Card Verification</h5>

                        </CardHeader>

                    </paper>
                    <div>
                        <center>
                            <h4 style={{color: "white"}} >

                                <a
                                    style={{
                                        verticalAlign: 'left',
                                        position: "absolute",

                                        marginTop: "5px",
                                        marginBottom: "10px",
                                    }}
                                    target="_blank"
                                    href="https://prportal.nidw.gov.bd/partner-portal/login">
                                    NID/Smart Card </a> <br/><br/>

                            </h4>
                        </center>
                    </div>
                    {/* <br/>
                    <br/>
                    <Table
                        tableHeaderColor="primary"
                        tableHead={["Customer Name", "Father Name", "NID"]}
                        tableData={[
                            ["", "", ""],


                        ]}
                        tableAllign={['left', 'left', 'left']}
                    />*/}


                </div>

            )
        } else {

        }

    };
    handleSubmitRefresh = (event) => {
        event.preventDefault();
        window.location.reload()

    };
    renderSearchButton = () => {
        if (this.state.getData && this.state.apiGetData && this.props.readOnlyField!==true) {
            return (
                <React.Fragment>
                    <button
                        className="btn btn-outline-primary  btn-sm"
                        style={{
                            marginTop: "15px"
                        }}
                        onClick={this.handleSubmit}

                    >
                        Search
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-warning  btn-sm"
                        style={{
                            marginTop: "15px"
                        }}
                        onClick={this.handleSubmitRefresh}

                    >
                        Refresh
                    </button>
                </React.Fragment>
            )
        }
    };
    renderContent = () => {
        if (this.state.content === "Existing Joint CB /  Account") {
            return "Joint Account"
        } else if (this.state.content === 'Partnership A/C' || this.state.content === 'Private/Public Limited Company Account' || this.state.content === 'NGO/NPO/Club/Society Account' || this.state.content === 'Educational/Religious Institution Account' || this.state.content === 'Government Account' || this.state.content === 'Other Account') {
            return "Company Account"
        } else if (this.state.content === 'Individual A/C') {
            return "INDIVIDUAL";
        } else if (this.state.content === 'NONINDIVIDUAL' || this.state.content === 'Proprietorship A/C') {
            return "Proprietorship A/C";
        } else {
            return this.state.content;
        }
    };
    renderSearchData = (searchData) => {
        let inputData=searchData;
        // inputData.searchDedupeInputData=[{searchData}];
        inputData.jointNumberOfCustomer= this.state.handleChangeJoint;
        inputData.searchAccountNumber= inputData.accountNumber;
        if(inputData.jointAccountNumber!==undefined){
            inputData.searchingAccountNumber=inputData.jointAccountNumber;
        }
        else if(inputData.accountNumber!==undefined){
            inputData.searchingAccountNumber=inputData.accountNumber;
        }

        inputData.accountNumber=undefined;
        return inputData;
    };

    render() {

        const {value} = this.state;
        const open = Boolean(this.state.anchorEl);

        const {classes, onClose} = this.props;
        return (
            <section>
                {this.renderNotification()
                }
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.CustomerModal}

                >
                    <DialogContent className={classes.dialogPaper}>

                        <Customer cbNumber={this.state.cbNumber} customerName={this.state.customerName}
                                  closeModal={this.closeCustomerModal}
                                  IDENTIFICATION_NO={this.state.IDENTIFICATION_NO}/>
                    </DialogContent>
                </Dialog>

                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    //fullScreen={true}
                    open={this.state.existingAcoountOpeningModal}>
                    <DialogContent className={classes.dialogPaper}>
                        <FinacleService
                            getmatchBy={this.state.getmatchBy}
                            businessSegment={this.state.businessSegment}
                            freeFlag3={this.state.inputData.freeFlag3}
                            closeModal={this.closeModal}
                            removeSearchResult={this.removeSearchResult}
                            getAccountType={this.state.getAccountType}
                            accountType={this.state.tabMenuSelect}
                            getInputData={this.state.inputData}
                            jointAccountCustomerNumber={this.state.handleChangeJoint}
                            checkboxjointData={this.state.checkboxjointData}
                            workplace={LocalstorageEncrypt.encryptStorageFunction.getItem('workplace')}
                            AccountType="Existing"
                            customerName={this.state.customerName}
                            dedupeCustomerName={this.state.dedupeCustomerName}
                            serviceType="ExistAccountOpening"
                            accountOpeningType="ExistAccountOpening"
                            subServiceType={this.renderContent()}
                            customerType={this.renderContent()}
                            getType={this.props.getType}
                            getCustomerId={this.state.getCustomerId}
                            cbTypeForAllowAccountOpening={this.state.cbTypeForAllowAccountOpening}
                            individualDedupData={this.state.individualDedupData}
                            mandateindividualDedupData={this.state.mandateindividualDedupData}
                            beneficiaryindividualDedupData={this.state.beneficiaryindividualDedupData}
                            jointDedupData={this.state.jointDedupData}
                            companyDedupData={this.state.companyDedupData}
                            sdnData={this.state.sdnData}
                            searchValue={this.renderSearchData(this.state.getsearchValue)}

                        />

                    </DialogContent>
                </Dialog>

                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    //fullScreen={true}
                    open={this.state.newAcoountOpeningModal}>
                    <DialogContent className={classes.dialogPaper}>

                        <DedupLiabilityService
                            closeModal={this.closeModal}
                            freeFlag3={this.state.inputData.freeFlag3}
                            removeSearchResult={this.removeSearchResult}
                            getAccountType={this.state.getAccountType}
                            jointRadioGetName={this.state.jointGetTaggingName}
                            accountType={this.state.tabMenuSelect}
                            jointAccountCustomerNumber={this.state.handleChangeJoint}
                            checkboxjointData={this.state.checkboxjointData}
                            getInputData={this.state.inputData}
                            workplace={LocalstorageEncrypt.encryptStorageFunction.getItem('workplace')}
                            AccountType="FDR Account Opening -Without Operative Account-NTB"
                            serviceType="Account Opening"
                            subServiceType={this.renderContent()}
                            getType={this.props.getType}
                            individualDedupData={this.state.individualDedupData}
                            mandateindividualDedupData={this.state.mandateindividualDedupData}
                            beneficiaryindividualDedupData={this.state.beneficiaryindividualDedupData}
                            jointDedupData={this.state.jointDedupData}
                            companyDedupData={this.state.companyDedupData}
                            sdnData={this.state.sdnData}
                            searchValue={this.renderSearchData(this.state.getsearchValue)}

                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.cbNotFound}>
                    <DialogContent className={classes.dialogPaper}>

                        <CBNotFound
                            closeModal={this.closeModalCBNotFound}
                            getAccountType={this.state.getAccountType}
                            title={this.state.title}
                            accountType={this.state.tabMenuSelect}
                            serviceType="Account Opening"
                            subServiceType={this.state.content}
                            searchValue={this.renderSearchData(this.state.getsearchValue)}

                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>


                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>

                        <Card>
                            {this.props.readOnlyField!==true &&
                                <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>
                                    <paper>
                                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                            {this.props.merchantDedup!==true  &&  <h3><b>Customer Dedupe & SDN Search </b></h3>}
                                            {this.props.merchantDedup!==true &&  <h5><b>Please input either “Existing CB Number / Account Number” or Data in
                                                mandatory fields for conducting Dedupe & SDN search</b></h5>}
                                            {this.props.merchantDedup===true &&  <h3><b>Merchant On Boarding Dedup Search</b></h3>}

                                        </CardHeader>
                                    </paper>
                                    <CardHeader color="red">

                                        <h6 color="red" className={classes.cardTitleWhite}>

                                            <Paper>
                                                {this.props.getgenerateForm!==true  &&    <Tabs
                                                    indicatorColor="secondary"
                                                    textColor="secondary"
                                                    aria-label="icon label tabs example"
                                                    value={this.state.tabMenuSelect}
                                                    onChange={this.handleChange}
                                                >
                                                    {this.props.merchantDedup!==true &&  <Tab
                                                        classes={{wrapper: classes.Tab}}
                                                        value="Individual A/C"
                                                        label={<div>
                                                            <a>Individual A/C</a><br/>
                                                            <a style={{color: "#999999"}}> </a>
                                                        </div>}
                                                        onClick={this.handleClickIndividual}
                                                        /*  value="INDIVIDUAL"
                                                          label="INDIVIDUAL A/C Opening"
                                                          onClick={() => this.setState({content: "INDIVIDUAL"})}*/

                                                    />}
                                                    {this.props.nidBlockShowing !== false && this.props.merchantDedup!==true && <Tab
                                                        classes={{wrapper: classes.Tab}}
                                                        value="Joint Type"
                                                        label={<div>
                                                            <a>Joint Type</a><br/>
                                                            <a style={{color: "#999999"}}> </a>
                                                        </div>}
                                                        onClick={this.handleClickIndividual3}
                                                        /*  value="INDIVIDUAL"
                                                          label="INDIVIDUAL A/C Opening"
                     LienMarking                                     onClick={() => this.setState({content: "INDIVIDUAL"})}*/

                                                    />}
                                                    {this.props.merchantDedup!==true && this.props.nidBlockShowing !== false && this.props.companyDedupeBlock !== true &&
                                                        <Tab
                                                            classes={{wrapper: classes.Tab}}
                                                            value="NONINDIVIDUAL"
                                                            label={<div>
                                                                <a>NON-INDIVIDUAL A/C</a><br/>
                                                                <a style={{color: "#999999"}}>  {this.state.NonIndividualabel} </a>
                                                            </div>}
                                                            onClick={this.handleClickNonIndividual}


                                                            //onClick={() => this.setState({content: this.state.label})}
                                                        />}
                                                    {this.props.companyDedupeBlock === true && <Tab
                                                        classes={{wrapper: classes.Tab}}
                                                        value="NONINDIVIDUAL"
                                                        label={<div>
                                                            <a>Company Dedupe</a><br/>

                                                        </div>}
                                                        onClick={() =>
                                                            this.setState({
                                                                searchTableRelatedData: false,
                                                                companyDedupeBlock: false,
                                                            })}


                                                        //onClick={() => this.setState({content: this.state.label})}
                                                    />}
                                                </Tabs>  }
                                                <Popover
                                                    open={this.state.individualDropdownOpen}
                                                    anchorEl={this.state.anchorE2}
                                                    onClose={this.handleClose}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "center"
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "center"
                                                    }}
                                                >
                                                    <MenuItem
                                                        onClick={() => this.handleMenuItemClickIndividual("Individual A/C")}>
                                                        Individual A/C
                                                    </MenuItem>


                                                </Popover>
                                                <Popover
                                                    open={this.state.individualDropdownOpen3}
                                                    anchorEl={this.state.anchorE3}
                                                    onClose={this.handleClose}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "center"
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "center"
                                                    }}
                                                >

                                                    <MenuItem
                                                        onClick={() => this.handleMenuItemClickIndividual("Joint Type")}>
                                                        Joint A/C
                                                    </MenuItem>


                                                </Popover>

                                                <Popover
                                                    open={open}
                                                    anchorEl={this.state.anchorEl}
                                                    onClose={this.handleClose}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "center"
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "center"
                                                    }}
                                                >
                                                    <MenuItem
                                                        onClick={() => this.handleMenuItemClick("Proprietorship A/C")}>
                                                        Proprietorship A/C
                                                    </MenuItem>
                                                    {this.props.companyDedupeBlock !== true && <MenuItem
                                                        onClick={() => this.handleMenuItemClick("Partnership A/C")}>
                                                        Partnership A/C
                                                    </MenuItem>
                                                    }
                                                    {this.props.companyDedupeBlock !== true && <MenuItem
                                                        onClick={() => this.handleMenuItemClick("Private/Public Limited Company Account")}>
                                                        Private/Public Limited Company Account
                                                    </MenuItem>
                                                    }
                                                    {this.props.companyDedupeBlock !== true && <MenuItem
                                                        onClick={() => this.handleMenuItemClick("NGO/NPO/Club/Society Account")}>
                                                        NGO/NPO/Club/Society Account
                                                    </MenuItem>
                                                    }
                                                    {this.props.companyDedupeBlock !== true && <MenuItem
                                                        onClick={() => this.handleMenuItemClick("Educational/Religious Institution Account")}>
                                                        Educational/Religious Institution Account

                                                    </MenuItem>}
                                                    {this.props.companyDedupeBlock !== true && <MenuItem
                                                        onClick={() => this.handleMenuItemClick("Government Account")}>
                                                        Government Account
                                                    </MenuItem>
                                                    }
                                                    {this.props.companyDedupeBlock !== true &&
                                                        <MenuItem onClick={() => this.handleMenuItemClick("Other Account")}>
                                                            Other Account
                                                        </MenuItem>
                                                    }

                                                </Popover>


                                            </Paper>


                                        </h6>
                                    </CardHeader>
                                </div>
                            }
                            {this.props.readOnlyField===true &&
                                <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>
                                    <paper>
                                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                            <h3><b>Customer Dedupe & SDN Search History </b></h3>

                                        </CardHeader>
                                    </paper>

                                </div>
                            }
                            {this.props.readOnlyField!==true &&   <Paper square>
                                <CardBody>

                                    <div>
                                        <ThemeProvider theme={theme}>

                                            <Grid container>

                                                {this.renderSearchFormForMerchant()}
                                                {this.renderSearchForm()}
                                                <Grid item xs={12}>
                                                    {this.renderSearchButton()}

                                                </Grid>
                                            </Grid>
                                        </ThemeProvider>
                                    </div>
                                </CardBody>
                            </Paper>}
                            {this.props.readOnlyField===true &&
                                <Paper square>
                                    <CardBody>

                                        <div>
                                            <ThemeProvider theme={theme}>

                                                <Grid container>

                                                    {this.renderSearchFormForMerchant()}
                                                    {this.renderSearchForm()}

                                                </Grid>
                                            </ThemeProvider>
                                        </div>
                                    </CardBody>
                                </Paper>}

                        </Card>
                    </GridItem>

                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>

                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>
                                {this.searchTableData()}
                            </div>
                        </Card>
                    </GridItem>

                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>

                                {this.searchTableRelatedData()}
                            </div>
                        </Card>
                    </GridItem>

                </GridContainer>

                {this.searchTableRelatedDataOnlyNonIndividual(classes)}
                {this.searchTableResultForMandate(classes)}
                {this.searchTableResultForBeneficiary(classes)}
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>

                                {this.searchTableSDNData()}
                                {this.searchTableSDNDataForNonIndividual()}
                                {this.renderMandateSdnData()}
                                {this.renderBeneficiarySdnData()}

                            </div>
                        </Card>
                    </GridItem>
                </GridContainer>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    open={this.state.merchantModal}>
                    <DialogContent>
                        <NewMerchant
                            getmatchBy={this.state.getmatchBy}
                            businessSegment={this.state.businessSegment}
                            freeFlag3={this.state.inputData.freeFlag3}
                            removeSearchResult={this.removeSearchResult}
                            getAccountType={this.state.getAccountType}
                            accountType={this.state.tabMenuSelect}
                            getInputData={this.state.inputData}
                            jointAccountCustomerNumber={this.state.handleChangeJoint}
                            checkboxjointData={this.state.checkboxjointData}
                            workplace={LocalstorageEncrypt.encryptStorageFunction.getItem('workplace')}
                            AccountType="Existing"
                            customerName={this.state.customerName}
                            dedupeCustomerName={this.state.dedupeCustomerName}
                            serviceType="ExistAccountOpening"
                            accountOpeningType="ExistAccountOpening"
                            subServiceType={this.renderContent()}
                            customerType={this.renderContent()}
                            getType={this.props.getType}
                            getCustomerId={this.state.getCustomerId}
                            cbTypeForAllowAccountOpening={this.state.cbTypeForAllowAccountOpening}
                            individualDedupData={this.state.individualDedupData}
                            mandateindividualDedupData={this.state.mandateindividualDedupData}
                            beneficiaryindividualDedupData={this.state.beneficiaryindividualDedupData}
                            jointDedupData={this.state.jointDedupData}
                            companyDedupData={this.state.companyDedupData}
                            sdnData={this.state.sdnData}
                            searchValue={this.renderSearchData(this.state.getsearchValue)}
                            merchantValue={this.renderSearchData(this.state.getsearchValue)}
                            getRadioButtonData={this.state.getRadioButtonData}
                            closeModal={this.merchantModal}/>
                    </DialogContent>
                </Dialog>
                {this.props.nidBlockShowing !== false && <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>
                                {this.searchTableNidVerification()}
                            </div>
                        </Card>
                    </GridItem>

                </GridContainer>}

            </section>
        )


    }

}


export default withStyles(styles)(CustomerDedupSearchForMerchant);


