import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import {Menu} from 'semantic-ui-react';
import 'semantic-ui-offline/semantic.min.css';
import Button from "@material/react-button";
import MaterialTable from "material-table";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {createMuiTheme} from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import axiosDownload from '../FunctionForFileDownload';
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import loader from "../../../Static/loader.gif";
import MailNotification from "../../workflow/CommonComponent/MailNotification";
import {buttonStyle, localization, options, tabActive, tabInactive, tableIcons} from "../configuration";
import MyValidation from "../../JsonForm/MyValidation";
import Notification from "../../NotificationMessage/Notification";
import CBNotFound from "../../workflow/CASA/CBNotFound";
import CpvForm from "./CpvForm";
import FailedListModal from "./FailedListModal";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let SearchForm = [
    {
        varName: "accountNumber",
        type: "text",
        "validation": "numeric",
        label: "Account Number",
        grid: 2,
        // required: true
    },
    {
        varName: "constitution",
        type: "autoCompleteValueReturn",
        enum: [],
        label: "Account Type",
        grid: 2,
        // required: true
    },
    {
        varName: "startDate",
        type: "date",
        label: "Start Date",
        grid: 2,
        // required: true
    },

    {
        varName: "endDate",
        type: "date",
        label: "End Date",
        grid: 2,
        // required: true
    }
];


let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '5px',
            }
        },

    }
});

class WelcomeLetterCreation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: false,
            currentDate: 0,
            appData: "",
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            getData: true,
            priorityStatus: this.props.match.params.priority,
            rowClickedId: 0,
            fileUploadData: {},
            responseData: [],
            activeItem: "All",
            formModal: false,
            formId: 0,
            backgroundColor: "red",
            uploadModal: false,
            merchantModal: false,
            caseId: "",
            cbNotFound: false,
            title: "",
            mailModal: false,
            loading: true,
            dataPassArray: [],
            selectedRowIdArray: [],
            buttonName: "Claim",
            notificationMessage: "",
            alert: false,
            showData: true,
            searchItem: "",
            selectedCount: 0,
            pageNumber: 0,
            totalRow: 0,
            rowsPerPage: 100,
            allData: [],
            loader: true,
            failedList: [],
            failedModal: false,
            columns: [
                {title: "B. Name", field: "branchName"},
                {title: "A/c Number", field: "accountNumber"},
                {title: "A/c Type", field: "accountType"},
                {title: "Customer Name", field: "customerName"},
                {title: "Address", field: "address"},
                {title: "City", field: "city"},
                {title: "Mobile", field: "mobile"},
                {title: "Batch No", field: "batchNo"},
                {title: "status", field: "status"},
                {title: "A/c Open Date", field: "account_open_date"},
                {title: "Generation Date", field: "generation_date"}
            ],
            returnColumns: [
                {title: "B. Name", field: "branchName"},
                {title: "A/c Number", field: "accountNumber"},
                {title: "A/c Type", field: "accountType"},
                {title: "Customer Name", field: "customerName"},
                {title: "Address", field: "address"},
                {title: "City", field: "city"},
                {title: "Mobile", field: "mobile"},
                {title: "Batch No", field: "batchNo"},
                {title: "A/c Open Date", field: "account_open_date"},
                {title: "Generation Date", field: "generation_date"},
                {title: "Update File Date", field: "update_file_date"}
            ],
            deliveredColumns: [
                {title: "B. Name", field: "branchName"},
                {title: "A/c Number", field: "accountNumber"},
                {title: "A/c Type", field: "accountType"},
                {title: "Customer Name", field: "customerName"},
                {title: "Address", field: "address"},
                {title: "City", field: "city"},
                {title: "Mobile", field: "mobile"},
                {title: "Batch No", field: "batchNo"},
                {title: "A/c Open Date", field: "account_open_date"},
                {title: "Generation Date", field: "generation_date"},
                {title: "Delivery Date", field: "delivery_date"}
            ],
            unconfirmedColumns: [
                {title: "B. Name", field: "branchName"},
                {title: "A/c Number", field: "accountNumber"},
                {title: "A/c Type", field: "accountType"},
                {title: "Customer Name", field: "customerName"},
                {title: "Address", field: "address"},
                {title: "City", field: "city"},
                {title: "Mobile", field: "mobile"},
                {title: "Batch No", field: "batchNo"},
                {title: "A/c Open Date", field: "account_open_date"},
                {title: "Generation Date", field: "generation_date"},
                {title: "Delivery Date", field: "delivery_date"},
                {title: "File", field: "file_upload"}
            ],
            cpvColumns: [
                {title: "B. Name", field: "branchName"},
                {title: "A/c Number", field: "accountNumber"},
                {title: "A/c Type", field: "accountType"},
                {title: "Customer Name", field: "customerName"},
                {title: "Address", field: "address"},
                {title: "City", field: "city"},
                {title: "Mobile", field: "mobile"},
                {title: "Batch No", field: "batchNo"},
                {title: "A/c Open Date", field: "account_open_date"},
                {title: "Generation Date", field: "generation_date"},
                {title: "Delivery Date", field: "delivery_date"},
                {title: "File", field: "file_upload"},
                {title: "Action", field: "form_action"}
            ],
            Buttoncolumns: [
                {title: "B. Name", field: "branchName"},
                {title: "A/c Number", field: "accountNumber"},
                {title: "A/c Type", field: "accountType"},
                {title: "Customer Name", field: "customerName"},
                {title: "Address", field: "address"},
                {title: "City", field: "city"},
                {title: "Mobile", field: "mobile"},
                {title: "Batch No", field: "batchNo"},
                {title: "status", field: "status"},
                {title: "A/c Open Date", field: "account_open_date"},
                {title: "Generation Date", field: "generation_date"},
            ]
        };
    }

    handleItemClick = (e, {name, style}) =>
        this.setState({
            activeItem: name,
            backgroundColor: style
        });

    headerSelect = (event, value) => {
        event.preventDefault();
        this.setState({
            loader: true,
            pageNumber: 0
        }, this.fetchDataFromAPI(value));
    };

    functionForGenerateTab = (event, value) => {
        event.preventDefault();
        this.setState({
            loader: true,
            pageNumber: 0,
            activeItem: value,
        });

        if (this.state.searchItem !== "") {
            this.searchData(this, this.state.pageNumber, value);
        } else {
            let url = backEndServerURL + "/getWelcomeLetterByStatus/Generate/" + this.state.priorityStatus;

            axios
                .get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    console.log(response);
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.length; i++) {
                        responseRowData = response.data[i].solId;
                        rowData = {
                            id: response.data[i].id,
                            branchName: response.data[i].branchName,
                            customerId: response.data[i].customerId,
                            accountNumber: response.data[i].accountNumber,
                            accountType: response.data[i].constitution,
                            title: response.data[i].title,
                            customerName: response.data[i].accountName,
                            account_open_date: this.dateConverter(response.data[i].accountOpenDate),
                            address: response.data[i].addressLine1,
                            city: response.data[i].city,
                            mobile: response.data[i].phone,
                            batchNo: response.data[i].batchNo,
                            status: response.data[i].status,
                            generation_date: this.dateConverter(response.data[i].generationDate),
                            update_file_date: this.dateConverter(response.data[i].modifiedDate),
                            delivery_date: this.dateConverter(response.data[i].modifiedDate),
                            file_upload: this.viewFile(response.data[i].id),
                            form_action: this.viewForm(response.data[i].id),
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData);
                    }

                    this.setState({
                        allData: array,
                        responseData: responseArray,
                        totalRow: response.data.length,
                        loader: false
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    dateConverter = (value) => {
        if (value === null || value === undefined) {
            return ' ';
        } else {
            let dateString;
            if (value.length > 10) {
                dateString = value.substr(0, 10);
            } else {
                dateString = value
            }
            return dateString
        }
    };

    closeMailModal = (value) => {
        if (value === "cancel") {
            this.setState({
                mailModal: false
            })
        } else {
            this.setState({
                loader: true,
                mailModal: false
            });
            let postData = value;
            postData.idList = this.state.selectedRowIdArray;
            let mailUrl = backEndServerURL + "/welcomeLetterSendToCourier";
            axios
                .post(mailUrl, postData, {
                    headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                })
                .then(response => {
                    if (response.status === 200) {
                        this.functionForNotificationMessage("Successful!", "Mail Send Successful");
                        let single_url = backEndServerURL + "/updateWelcomeLetterStatus/Send to Courier";
                        axios
                            .post(single_url, this.state.selectedRowIdArray, {
                                headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                            })
                            .then(response => {
                                if (response.status === 200) {
                                    this.functionForNotificationMessage("Successful!", "Status Change Successful");
                                    this.fetchDataFromAPI(this.state.activeItem)
                                }
                            })
                            .catch(error => {
                                this.functionForNotificationMessage("Failed!", "Status Change Failed");
                                console.log(error);
                            })
                    }
                })
                .catch(error => {
                    this.functionForNotificationMessage("Failed!", "Mail Send Failed");
                    console.log(error);
                });
        }
    };

    closeFormModal = (value) => {
        if (value === "success") {
            this.setState({
                formModal: false,
                loader: true
            });
            this.fetchDataFromAPI(this.state.activeItem)
        } else {
            this.setState({
                formModal: false,
            });
        }
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === undefined)
                delete clone[prop];
        return clone;
    };

    functionForNotificationMessage = (title, message) => {
        this.setState({
            title: title,
            notificationMessage: message,
            alert: true,
        })

    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    searchData = (e, page, status) => {
        // e.preventDefault();
        let inputValue = {...this.state.inputData};
        inputValue.constitution = this.state.inputData.constitution ? this.state.inputData.constitution.value : "";
        let postData = this.removeNullValue(inputValue);
        let error = MyValidation.defaultValidation(SearchForm, this.state);
        this.forceUpdate();

        if (error === true || Object.keys(postData).length === 0) {
            return 0;
        }

        if (status === "All") {
            this.setState({
                loader: true,
                pageNumber: page,
                activeItem: "All"
            });
        } else {
            this.setState({
                loader: true,
                pageNumber: page,
                activeItem: status
            });
        }

        let inbox_url = backEndServerURL + "/filterWelcomeLetterAccountNumberBetweenDate/" + status + "/" + this.props.match.params.priority + "/" + page;


        axios
            .post(inbox_url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].solId;
                    rowData = {
                        id: response.data.content[i].id,
                        branchName: response.data.content[i].branchName,
                        customerId: response.data.content[i].customerId,
                        accountNumber: response.data.content[i].accountNumber,
                        accountType: response.data.content[i].constitution,
                        title: response.data.content[i].title,
                        customerName: response.data.content[i].accountName,
                        account_open_date: this.dateConverter(response.data.content[i].accountOpenDate),
                        address: response.data.content[i].addressLine1,
                        city: response.data.content[i].city,
                        mobile: response.data.content[i].phone,
                        batchNo: response.data.content[i].batchNo,
                        status: response.data.content[i].status,
                        generation_date: this.dateConverter(response.data.content[i].generationDate),
                        update_file_date: this.dateConverter(response.data.content[i].modifiedDate),
                        delivery_date: this.dateConverter(response.data.content[i].modifiedDate),
                        file_upload: this.viewFile(response.data.content[i].id),
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    totalRow: response.data.totalElements,
                    varValue: {...postData},
                    showValue: true,
                    loading: false,
                    loader: false,
                    searchItem: this.state.activeItem,
                });

            })
            .catch(error => {
                console.log(error);
            });

    };

    resetHandler = () => {
        window.location.reload();
    };

    fetchDataFromAPI = (status) => {
        this.setState({
            pageNumber: 0,
            activeItem: status,
        });

        if (this.state.searchItem !== "") {
            this.searchData(this, this.state.pageNumber, status);
        } else {
            this.setState({
                activeItem: status,
                loader: true
            });

            let url;
            if (status === 'All') {
                url = backEndServerURL + "/getallWelcomeLetter/0/" + this.state.priorityStatus;
            } else {
                url = backEndServerURL + "/getWelcomeLetterStatus/" + status + "/0/" + this.state.priorityStatus;
            }

            axios
                .get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        responseRowData = response.data.content[i].solId;
                        rowData = {
                            id: response.data.content[i].id,
                            branchName: response.data.content[i].branchName,
                            customerId: response.data.content[i].customerId,
                            accountNumber: response.data.content[i].accountNumber,
                            accountType: response.data.content[i].constitution,
                            title: response.data.content[i].title,
                            customerName: response.data.content[i].accountName,
                            account_open_date: this.dateConverter(response.data.content[i].accountOpenDate),
                            address: response.data.content[i].addressLine1,
                            city: response.data.content[i].city,
                            mobile: response.data.content[i].phone,
                            batchNo: response.data.content[i].batchNo,
                            status: response.data.content[i].status,
                            generation_date: this.dateConverter(response.data.content[i].generationDate),
                            update_file_date: this.dateConverter(response.data.content[i].modifiedDate),
                            delivery_date: this.dateConverter(response.data.content[i].modifiedDate),
                            file_upload: this.viewFile(response.data.content[i].id),
                            form_action: this.viewForm(response.data.content[i].id),
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData);
                    }

                    this.setState({
                        allData: array,
                        responseData: responseArray,
                        totalRow: response.data.totalElements,
                        loader: false
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    handleChangePage = (pageNumber, status) => {
        let page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        this.setState({
            loader: true
        });

        let inbox_url;
        if (status === this.state.searchItem) {
            this.searchData(this, page, status);
        } else if (this.state.activeItem === status) {
            if (status === 'All') {
                inbox_url = backEndServerURL + "/getallWelcomeLetter/" + page + "/" + this.state.priorityStatus;
            } else {
                inbox_url = backEndServerURL + "/getWelcomeLetterStatus/" + status + "/" + page + "/" + this.state.priorityStatus;
            }

            axios
                .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        responseRowData = response.data.content[i].solId;
                        rowData = {
                            id: response.data.content[i].id,
                            branchName: response.data.content[i].branchName,
                            customerId: response.data.content[i].customerId,
                            accountNumber: response.data.content[i].accountNumber,
                            accountType: response.data.content[i].constitution,
                            title: response.data.content[i].title,
                            customerName: response.data.content[i].accountName,
                            account_open_date: this.dateConverter(response.data.content[i].accountOpenDate),
                            address: response.data.content[i].addressLine1,
                            city: response.data.content[i].city,
                            mobile: response.data.content[i].phone,
                            batchNo: response.data.content[i].batchNo,
                            generation_date: this.dateConverter(response.data.content[i].generationDate),
                            update_file_date: this.dateConverter(response.data.content[i].modifiedDate),
                            delivery_date: this.dateConverter(response.data.content[i].modifiedDate),
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData);
                    }

                    this.setState({
                        allData: array,
                        responseData: responseArray,
                        activeItem: status,
                        pageNumber: page,
                        totalRow: response.data.totalElements,
                        loader: false
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }

    };


    componentDidMount() {
        if (this.state.priorityStatus !== undefined) {
            let apiUrl = backEndServerURL + "/finMasterData/" + "constitution";
            axios.get(apiUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        for (let i = 0; i < SearchForm.length; i++) {
                            let jsonObject = SearchForm[i] ? SearchForm[i] : {};
                            if (jsonObject.varName === "constitution") {
                                jsonObject.enum = response.data;
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            let open_url = backEndServerURL + "/getallWelcomeLetter/0/" + this.state.priorityStatus;
            axios
                .get(open_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        responseRowData = response.data.content[i].solId;
                        rowData = {
                            id: response.data.content[i].id,
                            branchName: response.data.content[i].branchName,
                            customerId: response.data.content[i].customerId,
                            accountNumber: response.data.content[i].accountNumber,
                            accountType: response.data.content[i].constitution,
                            title: response.data.content[i].title,
                            customerName: response.data.content[i].accountName,
                            account_open_date: this.dateConverter(response.data.content[i].accountOpenDate),
                            address: response.data.content[i].addressLine1,
                            city: response.data.content[i].city,
                            mobile: response.data.content[i].phone,
                            batchNo: response.data.content[i].batchNo,
                            status: response.data.content[i].status,
                            generation_date: this.dateConverter(response.data.content[i].generationDate),
                            update_file_date: this.dateConverter(response.data.content[i].modifiedDate),
                            delivery_date: this.dateConverter(response.data.content[i].modifiedDate),
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData);
                    }

                    this.setState({
                        allData: array,
                        responseData: responseArray,
                        loader: false,
                        loading: false,
                        totalRow: response.data.totalElements,
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }

        let defaultDate = new Date();
        let isoDate = defaultDate.toISOString();
        let currentDate = isoDate.substr(0, 10);

        this.setState({
            currentDate: currentDate
        });


    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.priority !== prevProps.match.params.priority) {
            this.setState({
                inputData: {},
                varValue: {},
                selectedDate: {},
                activeItem: "All",
                priorityStatus: this.props.match.params.priority,
                loading: true,
                loader: true,
            });
            let open_url = backEndServerURL + "/getallWelcomeLetter/0/" + this.props.match.params.priority;
            axios
                .get(open_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        responseRowData = response.data.content[i].solId;
                        rowData = {
                            id: response.data.content[i].id,
                            branchName: response.data.content[i].branchName,
                            customerId: response.data.content[i].customerId,
                            accountNumber: response.data.content[i].accountNumber,
                            accountType: response.data.content[i].constitution,
                            title: response.data.content[i].title,
                            customerName: response.data.content[i].accountName,
                            account_open_date: this.dateConverter(response.data.content[i].accountOpenDate),
                            address: response.data.content[i].addressLine1,
                            city: response.data.content[i].city,
                            mobile: response.data.content[i].phone,
                            batchNo: response.data.content[i].batchNo,
                            status: response.data.content[i].status,
                            generation_date: this.dateConverter(response.data.content[i].generationDate),
                            update_file_date: this.dateConverter(response.data.content[i].modifiedDate),
                            delivery_date: this.dateConverter(response.data.content[i].modifiedDate),
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData);
                    }

                    this.setState({
                        allData: array,
                        responseData: responseArray,
                        loader: false,
                        loading: false,
                        totalRow: response.data.totalElements,
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    viewUploadFileFunction = (appId) => {
        if (this.state.activeItem === "Unconfirmed Address Returned") {
            let download_url = backEndServerURL + "/welcomeLetter/viewUnconfirmedFile/" + appId;
            axiosDownload.downloadFile(download_url, 'GET', {}, `${this.state.currentDate}-welcome-letter-unconfirmed-address-returned.pdf`).then((response) => {
                if (response === "File Not Found") {
                    this.setState({
                        cbNotFound: true,
                        title: "File Not Found!"
                    })
                }
            })

        } else if (this.state.activeItem === "CPV Request") {
            let download_url = backEndServerURL + "/welcomeLetter/viewCPVDoc/" + appId;
            axiosDownload.downloadFile(download_url, 'GET', {}, `${this.state.currentDate}-welcome-letter-cpv-request.pdf`).then((response) => {
                if (response === "File Not Found") {
                    this.setState({
                        cbNotFound: true,
                        title: "File Not Found!"
                    })
                }
            })
        }

    };

    updateComponent = () => {
        this.forceUpdate();
    };

    open = data => {
        this.setState({
            dataPassArray: data,
            merchantModal: true
        });
    };

    viewForm = (appId) => {
        return (
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'middle',
                }}

                onClick={() => this.viewFormFunction(appId)}
            >

                <EditIcon/>

            </button>
        )
    };

    viewFormFunction = (appId) => {
        if (this.state.activeItem === "CPV Request") {
            this.setState({
                formModal: true,
                formId: appId
            })
        }
    };

    viewFile = (appId) => {
        return (
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'middle',
                }}

                onClick={() => this.viewUploadFileFunction(appId)}
            >

                <FileCopyIcon/>

            </button>
        )
    };
    closeFailedModal = () => {
        this.setState({
            failedList: [],
            failedModal: false
        })
    };

    mailNotification = () => {
        const {classes, onClose} = this.props;
        return (
            <>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.mailModal}>
                    <DialogContent className={classes.dialogPaper}>
                        <MailNotification closeModal={this.closeMailModal} processName="WELCOME LETTER"/>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.formModal}>
                    <DialogContent className={classes.dialogPaper}>
                        <CpvForm closeModal={this.closeFormModal} id={this.state.formId}/>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.failedModal}>
                    <DialogContent className={classes.dialogPaper}>
                        <FailedListModal closeModal={this.closeFailedModal} failedList={this.state.failedList}/>
                    </DialogContent>
                </Dialog>
            </>
        )
    };

    renderHeader = () => {
        return (
            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                <h4 style={{color: "white"}} >Deliverable Management (Welcome Letter
                    - {this.props.match.params.priority === "priority" ? "Priority" : "Non Priority"})</h4>
            </CardHeader>
        )
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
        })
    };

    render() {
        const {rows, columns} = this.state;
        const {activeItem} = this.state;
        const {classes, onClose} = this.props;
        if (this.state.loading) {

            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    {
                        this.renderNotification()
                    }
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >
                                Search Welcome Letter
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        SearchForm,
                                        this.updateComponent
                                    )}
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            display: "block",
                                            height: 30,
                                            marginTop: 15,
                                        }}
                                        onClick={e => {
                                            this.searchData(e, 0, "All");
                                        }}
                                    >
                                        Search
                                    </button>
                                    &nbsp;
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            display: "block",
                                            height: 30,
                                            marginTop: 15,
                                        }}
                                        onClick={this.resetHandler}
                                    >
                                        Reset
                                    </button>
                                </ThemeProvider>
                            </Grid>
                        </CardBody>
                    </Card>
                    <Card>
                        {
                            this.renderHeader()
                        }
                        <CardBody>
                            {
                                this.mailNotification()
                            }

                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>

                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>

                            <div>
                                <React.Fragment>
                                    <div></div>
                                    <div>
                                        <Menu pointing>
                                            <Menu.Item
                                                name="All"
                                                active={activeItem === "All"}
                                                style={
                                                    activeItem === "All"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "All");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Generate"
                                                active={activeItem === "Generate"}
                                                style={
                                                    activeItem === "Generate"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                //     onClick={this.handleItemClick}
                                                onClick={e => {
                                                    this.functionForGenerateTab(e, "Generate");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Send to Courier"
                                                active={activeItem === "Send to Courier"}
                                                style={
                                                    activeItem === "Send to Courier"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Send to Courier");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Return from Courier"
                                                active={activeItem === "Return from Courier"}
                                                style={
                                                    activeItem === "Return from Courier"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Return from Courier");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Delivered"
                                                active={activeItem === "Delivered"}
                                                style={
                                                    activeItem === "Delivered"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Delivered");
                                                }}
                                            />

                                            <Menu.Item
                                                name="Unconfirmed Address Returned"
                                                active={activeItem === "Unconfirmed Address Returned"}
                                                style={
                                                    activeItem === "Unconfirmed Address Returned"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Unconfirmed Address Returned");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Unconfirmed Address Marked"
                                                active={activeItem === "Unconfirmed Address Marked"}
                                                style={
                                                    activeItem === "Unconfirmed Address Marked"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Unconfirmed Address Marked");
                                                }}
                                            />
                                            <Menu.Item
                                                name="CPV Request"
                                                active={activeItem === "CPV Request"}
                                                style={
                                                    activeItem === "CPV Request"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "CPV Request");
                                                }}
                                            />
                                            <Menu.Item
                                                name="CPV Verified"
                                                active={activeItem === "CPV Verified"}
                                                style={
                                                    activeItem === "CPV Verified"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "CPV Verified");
                                                }}
                                            />

                                        </Menu>

                                        {/*<Segment>*/}
                                        {this.tableChoice()}
                                        {/*</Segment>*/}
                                    </div>
                                </React.Fragment>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    actionForGenerateToSendToCourier = (event, rowData, props) => {
        if (this.state.activeItem === "Generate") {

            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            this.setState({
                mailModal: true
            });

            let rowIdArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                this.setState({
                    selectedRowIdArray: rowIdArray
                })
            }
        }
    };

    actionForGenerateToWLDownload = (event, rowData, props) => {
        if (this.state.activeItem === "Generate") {
            this.setState({
                loader: true
            });
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let download_url = backEndServerURL + "/generateAllWelcomeLetters/Generate";
                axiosDownload.downloadFile(download_url, 'POST', rowIdArray, `${this.state.currentDate}-welcome-letter.pdf`).then((response) => {
                    if (response) {
                        // this.fetchDataFromAPI('Generate')
                        this.setState({
                            loader: false
                        })
                    }
                }).catch((error) => {
                    this.setState({
                        loader: false
                    })
                });

            }
        }
    };

    actionForSendToCourierToWLDownload = (event, rowData, props) => {
        if (this.state.activeItem === "Send to Courier") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let download_url = backEndServerURL + "/generateAllWelcomeLetters/Send to Courier";
                axiosDownload.downloadFile(download_url, 'POST', rowIdArray, `${this.state.currentDate}-welcome-letter.pdf`);

                this.fetchDataFromAPI('Send to Courier')
            }
        }
    };


    actionForReturnFromCourierToSendToBranch = (event, rowData, props) => {
        if (this.state.activeItem === "Return from Courier") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url =
                    backEndServerURL +
                    "/updateWelcomeLetterStatus/Branch Received (Return by courier)";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.fetchDataFromAPI('Return from Courier')

                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForUnconfirmedAddressReturnedToDownload = (event, rowData, props) => {
        if (this.state.activeItem === "Unconfirmed Address Returned") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                let download_url = backEndServerURL + "/generateAllWelcomeLetters/Generate";
                axiosDownload.downloadFile(download_url, 'POST', rowIdArray, `${this.state.currentDate}-welcome-letter.pdf`);

                this.fetchDataFromAPI('Unconfirmed Address Returned')
            }
        }
    };

    actionForUnconfirmedAddressReturnedToUnconfirmedAddressMarked = (event, rowData, props) => {
        if (this.state.activeItem === "Unconfirmed Address Returned") {
            this.setState({
                loader: true,
            });
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let finacel_url = backEndServerURL + "/unconfirmedAddressUpdate";
                axios
                    .post(finacel_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            if (response.status === 200 && response.data.updateFailed.length > 0) {
                                this.setState({
                                    failedList: response.data.updateFailed,
                                    failedModal: true,
                                })
                            }
                            if (response.status === 200 && response.data.successfullyUpdated.length > 0) {
                                this.functionForNotificationMessage("Successful!", "Status Update Successful");
                                this.fetchDataFromAPI('Unconfirmed Address Returned')
                            }
                            if (response.status === 200 && response.data.successfullyUpdated.length === 0) {
                                this.setState({
                                    loader: false
                                })
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            cbNotFound: true,
                            loader: false,
                            title: "Unable to connect with Finacle API!"
                        });
                    });
            }
        }
    };

    actionForCpvRequestToSendToCPV = (event, rowData, props) => {
        if (this.state.activeItem === "CPV Request") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                // let finacle_url = backEndServerURL + "/updateWelcomeLetterStatus/CPV Verified/";
                // axios
                //     .post(finacle_url, rowIdArray, {
                //         headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                //     })
                //     .then(response => {
                //         if (response.status === 200) {
                let single_url = backEndServerURL + "/updateWelcomeLetterStatus/CPV Verified/";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            this.functionForNotificationMessage("Successful!", "Status Update Successful");
                            this.fetchDataFromAPI('CPV Request')
                        }
                    })
                    .catch(error => {
                        this.functionForNotificationMessage("Failed!", "Status Update Failed");
                        console.log(error);
                    });
                //     }
                // })
                // .catch(error => {
                //     this.setState({
                //         cbNotFound: true,
                //         title: "Unable to connect with Finacle API!"
                //     });
                //     console.log(error);
                // });
            }
        }
    };

    actionForCpvVerifiedToDownload = (event, rowData, props) => {
        if (this.state.activeItem === "CPV Verified") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;

            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let download_url = backEndServerURL + "/generateAllWelcomeLetters/Generate";
                axiosDownload.downloadFile(download_url, 'POST', rowIdArray, `${this.state.currentDate}-welcome-letter.pdf`);

                this.fetchDataFromAPI('CPV Verified')

            }
        }
    };

    tableChoice() {
        if (this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>

                            <CircularProgress style={{marginLeft: '50%'}}/>

                        </div>
                    </MuiThemeProvider>
                </div>
            )
        } else if (this.state.activeItem === "All" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={""}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'All')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "Generate" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={""}
                                options={{...options, pageSize: this.state.totalRow}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Generate')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForGenerateToSendToCourier(event, rowData, props)}
                                            >
                                                Send to Courier
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForGenerateToWLDownload(event, rowData, props)}
                                            >
                                                WL Download
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "Send to Courier" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={""}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Send to Courier')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForSendToCourierToWLDownload(event, rowData, props)}
                                            >
                                                WL Download
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "Return from Courier" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.returnColumns}
                                data={this.state.allData}
                                title={""}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Return from Courier')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <Button
                                            style={buttonStyle}
                                            onClick={(event, rowData) => this.actionForReturnFromCourierToSendToBranch(event, rowData, props)}
                                        >
                                            Send to Branch
                                        </Button>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Unconfirmed Address Returned" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.unconfirmedColumns}
                                data={this.state.allData}
                                title={""}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, "Unconfirmed Address Returned")
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForUnconfirmedAddressReturnedToDownload(event, rowData, props)}
                                            >
                                                Download
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForUnconfirmedAddressReturnedToUnconfirmedAddressMarked(event, rowData, props)}
                                            >
                                                Unconfirmed Address marked
                                            </Button>
                                        </React.Fragment>

                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Unconfirmed Address Marked" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={""}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, "Unconfirmed Address Marked")
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "CPV Request" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.cpvColumns}
                                data={this.state.allData}
                                title={""}
                                options={{...options, selection: false}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, "Unconfirmed Address Marked")
                                            }
                                            }
                                        />
                                    ),
                                    // Actions: props => (
                                    //     <Button
                                    //         style={buttonStyle}
                                    //         onClick={(event, rowData) => this.actionForCpvRequestToSendToCPV(event, rowData, props)}
                                    //     >
                                    //         Send to CPV Verification
                                    //     </Button>
                                    // )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "CPV Verified" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={""}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, "CPV Verified")
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <Button
                                            style={buttonStyle}
                                            onClick={(event, rowData) => this.actionForCpvVerifiedToDownload(event, rowData, props)}
                                        >
                                            Download
                                        </Button>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.deliveredColumns}
                                data={this.state.allData}
                                title={""}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        }
    }
}

export default withStyles(styles)(WelcomeLetterCreation);
