import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid/index";
import axios from 'axios/index';
import {backEndServerURL} from "../../../Common/Constant";
import Table from "../../Table/Table";

class MbuDefferalPos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            getWaiverList: [],
            getData: false,
        }
    }


    componentDidMount() {

        let waiverListUrl = backEndServerURL + "/case/waiver/" + this.props.appId;
        axios.get(waiverListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                let tableArray = [];
                var status = "";
                response.data.map((waiver) => {
                    if (waiver.status === "APPROVAL_WAITING") {
                        status = "Waiting For Approval"
                    } else if (waiver.status === "ACTIVE") {
                        status = "Approved By DFS"
                    } else {
                        status = waiver.status
                    }


                    tableArray.push(this.createTableData(waiver.id, waiver.type, waiver.remarks, waiver.appliedBy, waiver.applicationDate, status));

                });

                this.setState({
                    getWaiverList: tableArray,
                    getData: true,
                })

            })
            .catch((error) => {
                console.log(error);
            })

    }


    createTableData = (id, type, remarks, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, remarks, dueDate, appliedBy, applicationDate, status
        ])

    };

    renderWaiverTableData = () => {

        if (this.state.getData && this.state.getWaiverList.length > 0) {

            return (
                <div style={{marginTop: '10px', marginBottom: "10px"}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b style={{padding: '10px'}}>Waiver List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Waiver Type", "Remarks", "Raise By", "Raise Date", "Status", ""]}
                            tableData={this.state.getWaiverList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        } else {
            return ""
        }
    };


    render() {

        return (
            <Grid item xs='12'>
                {
                    this.renderWaiverTableData()
                }
            </Grid>
        );

    }
}

export default MbuDefferalPos;