import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../Grid/GridItem.jsx";
import GridContainer from "../../Grid/GridContainer.jsx";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Notification from "../../NotificationMessage/Notification";
import {CSInterestPayment} from '../../workflow/WorkflowJsonForm4';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import MyValidation from "../../JsonForm/MyValidation";
import FileMapping from "../CommonComponent/FileMapping";
import FileMappingEdit from "../CommonComponent/FileMappingEdit";
import loader from "../../../Static/loader.gif";
import ErrorModal from "../CommonComponent/ErrorModal";
import CheckBox from "@material-ui/core/Checkbox/Checkbox";
import FunctionForGetAcDetails from "../CommonComponent/FunctionForGetAcDetails";
import CommonApi from "../Maintenance/common/CommonApi";

let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};
const arrayListBond = [
    {label: 'APPLICANT PASSPORT'},
    {label: 'APPLICANT EMPLOYMENT AGREEMENTS'},
    {label: 'APPLICANT WORK PERMITS'},
    {label: 'APPLICANT ETN'},
    {label: 'OTHERS'},
];

const arrayListSanchaypatra = [
    {label: 'APPLICANT BB FORM'},
    {label: 'APPLICANT DEBIT INSTRUCTION'},
    {label: 'APPLICANT NID'},
    {label: 'APPLICANT ETN'},
    {label: 'APPLICANT NOMINEE NID'},
];

let bondProjectTypeSanchoypatra = [
    {
        "varName": "selectTypeOfProject",
        "type": "select",
        "required": true,
        "enum": [
            "5 Years Bangladesh Sanchaya Patra",
            "3 Months Interest Based Sanchaya Patra",
            "Family Sanchaya Patra",
            "Pensioners Savings Certificate",
        ],
        "label": "SELECT TYPE OF PROJECT",
        "grid": 3,
    },
];

let bondProjectTypeBond = [
    {
        "varName": "selectTypeOfProject",
        "type": "select",
        "required": true,
        "enum": [
            "Wage Earners Development Bond",
            "U.S. Dollar Investment Bond",
            "U.S. Dollar Premium Bond"
        ],
        "label": "SELECT TYPE OF PROJECT",
        "grid": 3,
    },
];

let bondRequestType = [
    {
        "varName": "selectTypeOfRequest",
        "type": "select",
        "enum": [
            "BOND",
            "SANCHAYPATRA"
        ],
        "required": true,
        "label": "SELECT TYPE OF REQUEST",
        "grid": 3,
    },
];

let source = [{
    "varName": "source",
    "type": "select",
    "required": true,
    "enum": ["FINACLE", "ABABIL"],
    "onKeyDown": true,
    "label": "Source",
    "grid": 3,
}];

let typeOfPayment = [
    {
        "varName": "typeOfPayment",
        "type": "select",
        "required": true,
        "enum": [
            "ENCASHMENT PAYMENT",
            "INTEREST PAYMENT",
        ],
        "label": "SELECT TYPE OF PAYMENT",
        "grid": 3,
    },
];

class Encashment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: {},
            inputData: {},
            varValue: {},
            appId: 0,
            getCheckerList: [],
            checkerListShow: false,
            fileUploadData: {},
            errorArray: {},
            errorMessages: {},
            selectType: "",
            title: "",
            alert: false,
            getMappingAllImage: false,
            message: "",
            notificationMessage: "",
            uploadModal: false,
            loading: true,
            errorModalBoolean: false,
            accountDataBoolean: false,
            getData: false,
            uploadedFileName: "",
            showValue: false,
            sourceChanged: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    updateComponent = () => {
        this.forceUpdate();
    };
    onKeyDownChange = (data) => {
        if (data.varName === "source") {
            this.setState({
                sourceChanged: false,
                loading: true,
            })
            let inputData = {...this.state.inputData};
            inputData.accountNumber = undefined;
            inputData.cbNumber = undefined;
            inputData.customerName = undefined;
            inputData.businessSegment=inputData.source;


            setTimeout(() => {
                this.setState({
                    inputData: inputData,
                    varValue: inputData,
                    sourceChanged: true,
                    loading: false,
                })
            }, 1000)
            this.forceUpdate();
        }
    }

    onBlurFunctionForApiCall = (event) => {
        event.preventDefault();
        let inputData = this.state.inputData;

        if (this.state.inputData.source !== undefined && this.state.inputData.source === "FINACLE" && this.state.inputData.accountNumber !== undefined && this.state.inputData.accountNumber !== null && (this.state.inputData.accountNumber).trim() !== "") {
            this.setState({
                loading: true
            });

            FunctionForGetAcDetails.getAccountInfo(this.state.inputData.accountNumber).then((response) => {
                inputData.cbNumber = response.data.cifId;
                inputData.customerName = response.data.acctName;
                this.state.inputData.accountNumberApiCall = "YES";
                this.updateComponent();
                this.setState({
                    inputData: inputData,
                    varValue: inputData,
                    showValue: true,
                    loading: false,
                    accountDataBoolean: false,
                })
            }).catch((error) => {
                inputData.cbNumber = "";
                inputData.customerName = "";
                this.setState({
                    inputData: inputData,
                    varValue: inputData,
                    loading: false,
                    errorModalBoolean: true,
                    errorTittle: error.response.data.message,
                    accountDataBoolean: true,
                })
            });
        } else if (this.state.inputData.source !== undefined && this.state.inputData.source === "ABABIL" && this.state.inputData.accountNumber !== undefined && this.state.inputData.accountNumber !== null && (this.state.inputData.accountNumber).trim() !== "") {
            this.setState({
                loading: true
            });

            CommonApi.ababilAccountDetails(this.state.inputData.accountNumber).then((response) => {
                inputData.cbNumber = response.data.cifId;
                inputData.customerName = response.data.acctName;
                if (inputData.selectTypeOfRequest === "BOND") {
                    inputData.currency = {
                        key: response.data.currencyCode,
                        value: response.data.currencyCode
                    };
                }

                this.state.inputData.accountNumberApiCall = "YES";
                this.updateComponent();
                this.setState({
                    inputData: inputData,
                    varValue: inputData,
                    showValue: true,
                    loading: false,
                    accountDataBoolean: false,
                })
            }).catch((error) => {
                inputData.cbNumber = "";
                inputData.customerName = "";
                this.setState({
                    inputData: inputData,
                    varValue: inputData,
                    loading: false,
                    errorModalBoolean: true,
                    errorTittle: error.response.data.message,
                    accountDataBoolean: true,
                })
            });
        }

    };

    componentDidMount() {
        let url = backEndServerURL + "/startCase/bond_case_start";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let inputData = {};
                let varValue = {};
                inputData.uploadFileAlertRequest = true;
                varValue.uploadFileAlertRequest = true;
                varValue.urgencyValue = "Regular";
                inputData.urgencyValue = "Regular";
                this.setState({
                    inputData: inputData,
                    varValue: varValue,
                    appId: response.data.id,
                    checkerListShow: true,
                    loading: false,
                    getData: true,
                    showValue: true
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                })
            })


    }

    bondRequestType = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, bondRequestType, this.updateComponent, this.onBlurFunctionForApiCall, "")

            )
        }

    };

    bondProjectType = () => {
        if (this.state.inputData.selectTypeOfRequest === "BOND") {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, bondProjectTypeBond, this.updateComponent, this.onBlurFunctionForApiCall, "")

            )
        } else if (this.state.inputData.selectTypeOfRequest === "SANCHAYPATRA") {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, bondProjectTypeSanchoypatra, this.updateComponent, this.onBlurFunctionForApiCall, "")

            )
        }
    };

    typeOfPayment = () => {
        if (this.state.inputData.selectTypeOfRequest && this.state.inputData.selectTypeOfProject) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, typeOfPayment, this.updateComponent, this.onBlurFunctionForApiCall, "")

            )
        }
    };

    renderSources = () => {
        if (this.state.inputData.selectTypeOfProject && this.state.inputData.selectTypeOfRequest && this.state.inputData.typeOfPayment) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, source, this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)
            )
        }
    };

    renderJsonForm = () => {
        if (this.state.inputData.selectTypeOfRequest && this.state.inputData.selectTypeOfProject && this.state.inputData.typeOfPayment && this.state.inputData.source && this.state.sourceChanged) {
            return (
                <React.Fragment>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, CSInterestPayment, this.updateComponent, this.onBlurFunctionForApiCall, "")
                    }
                </React.Fragment>
            )
        }
    };

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    handleSubmitDraft = (event) => {
        event.preventDefault();
        let data = {...this.state.inputData};
        data.serviceType = "bond_issuance_serve";
        data.category = this.state.inputData.selectTypeOfRequest;
        data.subCategory = `ENCASHMENT - ${this.state.inputData.selectTypeOfProject} - ${this.state.inputData.typeOfPayment}`;
        data.freeFlag1 = this.state.inputData.selectTypeOfRequest;
        data.freeFlag2 = this.state.inputData.typeOfPayment;
        if (this.state.inputData.typeOfPayment === "ENCASHMENT PAYMENT") {
            data.proprietorName = "Encashment Payment – from Encashment Payment";
        } else {
            data.proprietorName = "Interest Payment – from Encashment Payment";
        }
        data.freeFlag3 = "ENCASHMENT";
        data.subServiceType = "ENCASH" + this.state.inputData.selectTypeOfProject;
        data.freeFlag4 = this.state.inputData.registrationNumber;
        data.freeFlag5 = this.state.inputData.selectTypeOfProject;
        data.freeFlag6 = this.state.inputData.amount;
        let variableSetUrl = backEndServerURL + "/save/" + this.state.appId;
        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Close!",
                        alert: true
                    });
                    setTimeout(function () {
                        window.location.reload()
                    }, 1000);
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };
    handleSubmit = (event) => {
        event.preventDefault();
        console.log(this.state.inputData);

        let error = MyValidation.defaultValidation(this.emptyValueRemove(CSInterestPayment.concat(bondRequestType, bondProjectTypeBond, bondProjectTypeSanchoypatra)), this.state);

        this.forceUpdate();

        if (error === true) {
            return 0;
        }
        if (this.state.accountDataBoolean) {
            this.setState({
                errorModalBoolean: true,
                errorTittle: "Invalid Account Number",
            });
            return 0;
        }

        if (this.state.inputData.uploadFileAlertRequest === true && this.state.inputData.previewImage !== true) {
            this.setState({
                errorModalBoolean: true,
                errorTittle: "Please Upload The File!"
            });
            return 0;
        }
        this.setState({
            loading: true,
        });
        if (this.state.fileUploadData.scanningFile !== undefined) {
            let fileUploadPath = backEndServerURL + "/case/upload";
            let types = 'Attachments';
            let files = this.state.fileUploadData.scanningFile;
            console.log(files);
            let formData = new FormData();
            formData.append("appId", this.state.appId);
            formData.append("file", files);
            formData.append("type", types);
            axios({
                method: 'post',
                url: fileUploadPath,
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'content-type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        let data = {...this.state.inputData};
        data.serviceType = "bond_issuance_serve";
        data.category = this.state.inputData.selectTypeOfRequest;
        data.subCategory = `ENCASHMENT - ${this.state.inputData.selectTypeOfProject} - ${this.state.inputData.typeOfPayment}`;
        data.freeFlag1 = this.state.inputData.selectTypeOfRequest;
        data.freeFlag2 = this.state.inputData.typeOfPayment;
        data.freeFlag3 = "ENCASHMENT";
        data.cs_deferal = "NO";
        if (this.state.inputData.typeOfPayment === "ENCASHMENT PAYMENT") {
            data.proprietorName = "Encashment Payment – from Encashment Payment";
        } else {
            data.proprietorName = "Interest Payment – from Encashment Payment";
        }
        data.subServiceType = "ENCASH" + this.state.inputData.selectTypeOfProject;
        data.freeFlag4 = this.state.inputData.registrationNumber;
        data.freeFlag5 = this.state.inputData.selectTypeOfProject;
        data.freeFlag6 = this.state.inputData.amount;
        let remarksData = {};
        remarksData.remark = this.state.inputData.csRemarks;
        remarksData.map = {...data, csRemarks: undefined};
        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
        axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            this.state.inputData.selectTypeOfProject = undefined;
            this.state.inputData.selectTypeOfRequest = undefined;
            this.setState({
                title: "Successful!",
                notificationMessage: "Successfully Routed!",
                alert: true,
                loading: false,
            });
            setTimeout(function () {
                window.location.reload()
            }, 1000);
        }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false
            })
        });
    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    close = () => {
        this.props.closeModal()
    };
    renderRemarks = () => {
        if (this.state.inputData.selectTypeOfRequest && this.state.inputData.selectTypeOfProject && this.state.inputData.typeOfPayment) {

            return (

                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)

            )
        }

    };
    closeUploadModal = () => {
        this.setState({
            uploadModal: false,
            getMappingAllImage: false
        })

    };
    submitModal = (data) => {
        if (data === "SUBMIT") {
            this.state.inputData.previewImage = true;
            this.updateComponent()
        }
        this.setState({
            uploadModal: false,
            getMappingAllImage: false
        })

    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    closeModal = () => {
        this.setState({

            getMappingAllImage: false,
            errorModalBoolean: false

        })
    };
    renderUploadButton = () => {
        if (this.state.inputData.selectTypeOfRequest && this.state.inputData.selectTypeOfProject && this.state.inputData.typeOfPayment) {
            return (
                <button

                    className="btn btn-outline-primary btn-sm"

                    onClick={this.uploadModal}

                >
                    Upload File
                </button>
            )
        }
    };
    handleChangeuploadFileAlertRequest = (event) => {
        let data = event.target.checked;
        this.state.inputData.uploadFileAlertRequest = data;
        this.updateComponent();
        if (data === false) {
            this.setState({
                errorModalBoolean: true,
                errorTittle: "Without Document Process!!",
            })
        }
    };
    renderUploadCheckbox = () => {
        if (this.state.inputData.selectTypeOfRequest && this.state.inputData.selectTypeOfProject && this.state.inputData.typeOfPayment) {
            return (
                <CheckBox
                    checked={this.state.inputData.uploadFileAlertRequest}
                    defaultValue={this.state.inputData.uploadFileAlertRequest}
                    name="smsAlertRequest"
                    label="SMS Alert Request"
                    onChange={(event) => this.handleChangeuploadFileAlertRequest(event)}/>


            )
        }
    };
    renderAssignedImage = () => {
        if (this.state.inputData.previewImage === true && this.state.inputData.selectTypeOfRequest && this.state.inputData.selectTypeOfProject && this.state.inputData.typeOfPayment) {
            return (
                <button
                    style={{
                        width: 120,

                    }}
                    className="btn btn-outline-primary btn-sm"
                    onClick={this.mappingAllImage}
                >
                    Preview Document
                </button>
            )
        }
    };
    renderSubmitButton = () => {
        if (this.state.inputData.selectTypeOfRequest && this.state.inputData.selectTypeOfProject && this.state.inputData.typeOfPayment) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'middle',
                        }}
                        onClick={this.handleSubmit}
                    >
                        Submit
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-info btn-sm"
                        style={{}}
                        onClick={this.handleSubmitDraft}
                    >
                        Save
                    </button>
                </div>
            )
        }
    };

    viewFileName = (file) => {
        if (file !== undefined) {
            this.setState({
                uploadedFileName: file.name
            });
        }
    };

    renderMappingImageModal = () => {
        if (this.state.inputData.selectTypeOfRequest === "BOND") {
            return (
                <FileMapping dropDownOption={arrayListBond}
                             serviceType={this.state.inputData.selectTypeOfRequest}
                             appId={this.state.appId}
                             submitModal={this.submitModal}
                             viewFileName={this.viewFileName}
                             closeModal={this.closeUploadModal}/>
            )
        } else {
            return (
                <FileMapping dropDownOption={arrayListSanchaypatra}
                             serviceType={this.state.inputData.selectTypeOfRequest}
                             appId={this.state.appId}
                             viewFileName={this.viewFileName}
                             submitModal={this.submitModal}
                             closeModal={this.closeUploadModal}/>
            )
        }
    };
    renderMappingImageEditModal = () => {
        if (this.state.inputData.selectTypeOfRequest === "BOND") {
            return (
                <FileMappingEdit dropDownOption={arrayListBond}
                                 submitModal={this.submitModal}
                                 serviceType={this.state.inputData.selectTypeOfRequest}
                                 appId={this.state.appId}
                                 closeModal={this.closeUploadModal}/>
            )
        } else {
            return (
                <FileMappingEdit dropDownOption={arrayListSanchaypatra}
                                 submitModal={this.submitModal}
                                 serviceType={this.state.inputData.selectTypeOfRequest}
                                 appId={this.state.appId}
                                 closeModal={this.closeUploadModal}/>
            )
        }
    };

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >SP Payment/Encashment </h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <Grid container>
                                        <ThemeProvider theme={theme}>
                                            <Dialog
                                                fullWidth="true"
                                                maxWidth="xl"
                                                open={this.state.uploadModal}>
                                                <DialogContent>
                                                    {this.renderMappingImageModal()}
                                                </DialogContent>
                                            </Dialog>
                                            <Dialog
                                                fullWidth="true"
                                                maxWidth="xl"
                                                open={this.state.getMappingAllImage}>
                                                <DialogContent>
                                                    {this.renderMappingImageEditModal()}
                                                </DialogContent>
                                            </Dialog>

                                            <Dialog
                                                fullWidth="true"
                                                maxWidth="mD"
                                                className={classes.modal}
                                                classes={{paper: classes.dialogPaper}}
                                                open={this.state.errorModalBoolean}>
                                                <DialogContent className={classes.dialogPaper}>
                                                    <ErrorModal title={this.state.errorTittle}
                                                                closeModal={this.closeModal}
                                                                subServiceType={this.props.subServiceType}
                                                                appId={this.props.appId}
                                                    />

                                                </DialogContent>
                                            </Dialog>
                                            {this.renderNotification()}
                                            <Grid item xs={12}>
                                                {this.bondRequestType()}
                                            </Grid>

                                            <Grid item xs={12}>
                                                {this.bondProjectType()}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {this.typeOfPayment()}
                                            </Grid>
                                            {this.renderSources()}
                                            {this.renderJsonForm()}
                                            <Grid item xs={12}>
                                            </Grid>
                                            {this.renderUploadCheckbox()}
                                            <Grid item xs={1}>
                                                {this.renderUploadButton()}
                                            </Grid>
                                            <Grid item xs={1}>
                                                {this.renderAssignedImage()}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {this.state.inputData.selectTypeOfProject && this.state.uploadedFileName !== "" &&
                                                <p style={{color: "red"}}>{this.state.uploadedFileName}</p>}
                                            </Grid>
                                            <br/>
                                            {this.renderRemarks()}
                                        </ThemeProvider>
                                    </Grid>
                                </div>
                                <br/>
                                {this.renderSubmitButton()}
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }
    }
}

export default withStyles(styles)(Encashment);
