import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TablePagination from '@material-ui/core/TablePagination/index';
import TableRow from '@material-ui/core/TableRow/index';
import TableSortLabel from '@material-ui/core/TableSortLabel/index';
import Paper from '@material-ui/core/Paper/index';
import Tooltip from '@material-ui/core/Tooltip/index';
import axios from "axios/index";

import {backEndServerURL} from "../../../Common/Constant";

import {Dialog} from "@material-ui/core/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import CSInboxCase from '../CSInboxCase';
import BOMInboxCase from '../BOMInboxCase';
import BMInboxCase from '../BMInboxCase';

import GridItem from "../../Grid/GridItem";
import CardHeader from "../../Card/CardHeader";
import CardBody from "../../Card/CardBody";
import GridContainer from "../../Grid/GridContainer";
import Functions from '../../../Common/Functions';
import MakerInboxCase from "../MakerInboxCase";
import CheckerInboxCase from "../CheckerInboxCase";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../JsonForm/CustomeTheme";
import AbhApproval from "../AGENT/AbhApproval";
import AgentBanking from "../AGENT/AgentBanking";
import Grid from "@material-ui/core/Grid/index";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import 'semantic-ui-offline/semantic.min.css';
import ABHCheckerInbox from "../AGENT/ABHCheckerInbox";
import MerchantInboxCase from "../Merchant/MerchantInboxCase";
import Checkbox from "@material-ui/core/Checkbox";
import SelectComponent from "../../JsonForm/SelectComponent";
import Notification from "../../NotificationMessage/Notification";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";

let bondProjectTypeBond = [
    {
        "varName": "selectTypeOfProject",
        "type": "select",
        "enum": [
            "Wage Earners Development Bond",
            "U.S. Dollar Investment Bond",
            "U.S. Dollar Premium Bond"
        ],
        "label": "SELECT TYPE OF PROJECT",
        "grid": 3,
    },
];
let bondRequestType = [
    {
        "varName": "selectTypeOfRequest",
        "type": "select",
        "enum": [
            "BOND",
            "SANCHAYPATRA"
        ],
        "label": "SELECT TYPE OF REQUEST",
        "grid": 3,
    },
];

const filteringJsonForm = [
    {
        "varName": "customer_name",
        "type": "text",
        "label": "Customer Name",
        "grid": 2,

    },
    {
        "varName": "cb_number",
        "type": "text",
        "grid": 2,
        "label": "CB Number",
    },
    {
        "varName": "branchName",
        "type": "select",
        "enum": [
            "MOTHIJHEEL",
            "GULSHAN BRANCH"
        ],
        "grid": 2,
        "label": "Branch Name",
    },
    {
        "varName": "category",
        "type": "select",
        "grid": 2,
        "label": "Category Type",
        "enum": [
            "CASA",
            "FDR",
            "DPS",
            "SANCHOYPATRA",
            "BOND",
            "CARDS"
        ]
    },
    {
        "varName": "subCategory",
        "type": "select",
        "label": "Sub Category Type",
        "grid": 2,
        "enum": [
            "A/C Opening",
            "Maintenance",
            "Closing",
        ]
    },
    {
        "varName": "urgency",
        "type": "select",
        "label": "Urgency",
        "grid": 2,
        "enum": [
            "HIGH",
            "NORMAL",
        ]
    },


];


let counter = 0;

function createData(index, customer_name, cb_number, account_number, appUid, service_type, subservice_type, branch_id, currentUserName, taskTitle, jointAccountCustomerNumber, delInitTime, category, subCategory) {


    return {
        id: index + 1,
        customer_name,
        cb_number,
        account_number,
        appUid,
        service_type,
        subservice_type,
        branch_id,
        currentUserName,
        taskTitle,
        jointAccountCustomerNumber,
        delInitTime,
        category,
        subCategory
    };
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
    //{id: 'id', numeric: true, disablePadding: true, label: 'SL'},
    /*
        {id: 'SL', numeric: false, disablePadding: true, label: 'SL'},
    */
    {id: 'checkbox', numeric: false, disablePadding: true, label: 'Checkbox'},

    {id: 'customer_name', numeric: false, disablePadding: true, label: 'Name'},
    {id: 'cb_number', numeric: false, disablePadding: true, label: 'CB Number'},
    {id: 'account_number', numeric: false, disablePadding: true, label: 'Account Number'},
    {id: 'category_type', numeric: false, disablePadding: true, label: 'Category'},
    {id: 'sub_category_type', numeric: false, disablePadding: true, label: 'Sub Category'},
    {id: 'Date', numeric: false, disablePadding: true, label: 'Date of Activity'},
    {id: 'currentUserName', numeric: false, disablePadding: true, label: 'Current User'},
    {id: 'branch_name', numeric: false, disablePadding: true, label: 'Branch Name'},
    /*  {id: 'View', numeric: false, disablePadding: false, label: 'Action'}*/
];

class TableContentHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {order, orderBy} = this.props;

        return (
            <TableHead>
                <TableRow style={{height: 30, backgroundColor: "#E8E4E3"}}>

                    {rows.map(
                        row => (
                            <TableCell
                                key={row.id}
                                align={row.numeric ? 'right' : 'left'}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        <b> {row.label}</b>
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        ),
                        this,
                    )}
                </TableRow>
            </TableHead>
        );
    }
}

TableContentHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


const styles = theme => ({
    root: {
        width: '100%',
        border: '1'
        //marginTop: theme.spacing.unit * 2,
    },
    table: {
        minWidth: 1020,
    },
    tableCell: {
        padding: 3,
    },
    tableCells: {
        padding: 0,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    container: {
        overflow: 'auto',
        padding: '10px'
    },
    Tab: {
        flexDirection: "row-reverse"
    },
    left: {
        float: 'left',
        width: '200px'
    },

    right: {
        marginLeft: '210px'
    }
});

class ClaimCase extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'id',
        selected: [],
        data: [],
        getInboxCaseData: [],
        page: 0,
        rowsPerPage: 25,
        renderModal: false,
        appUid: '',
        inboxModal: false,
        serviceType: '',
        subserviceType: '',
        jointAccountCustomerNumber: '',
        taskTitle: '',
        redirectLogin: false,
        selectTab: "",
        err: false,
        errorArray: {},
        errorMessages: {},
        errorReturn: {},
        inputData: {},
        getData: false,
        activeItem: 'Inbox',
        backgroundColor: 'red',
        getAllMaker: [],
        getAllSdTeam: [],

        SelectedSDTeam: "",
        SelectedSDTeamMember: "",
    };

    componentDidMount() {


        const data = [];

        if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") == "MERCHANT") {
            console.log("success");
            var url = backEndServerURL + '/workspaceClaimable';
        } else {
            var url = backEndServerURL + '/claimable';
        }


        axios.get(url, {
            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
        })
            .then(response => {
                console.log("okl");
                console.log(response.data);
                let sdTeamUrl = backEndServerURL + "/sdTeam/get";
                axios.get(sdTeamUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.setState({
                            getAllSdTeam: response.data,
                            getData: true
                        })


                    })
                    .catch((error) => {
                        console.log(error);
                    });
                var urgency = "";
                response.data.map((message, index) => {

                    if (message.urgency === 1) {
                        urgency = "HIGH"
                    } else {
                        urgency = "NORMAL"
                    }
                    data.push(createData(index, message.customerName, message.cbNumber, message.accountNumber, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory));

                });

                this.setState({
                    data: data,
                    getInboxCaseData: data,

                });


            })

            .catch(error => {
                console.log(error);

            });


    }


    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({order, orderBy});
    };

    handleSelectAllClick = event => {
        if (event.target.checked) {
            this.setState(state => ({selected: state.data.map(n => n.id)}));
            return;
        }
        this.setState({selected: []});
    };

    handleClick = (event, id) => {

        const {selected} = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({selected: newSelected});
        console.log(this.state.selected)
    };
    handleChangePage = (event, page) => {
        this.setState({page});
    };

    handleChangeRowsPerPage = event => {
        this.setState({rowsPerPage: event.target.value});
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    viewCase = (appUid, serviceType, subserviceType) => {

        let url = backEndServerURL + "/case/claim/" + appUid;
        axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Claimed!",
                    alert: true
                });
                const data = [];

                let url = backEndServerURL + '/claimable';


                axios.get(url, {
                    headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                })
                    .then(response => {
                        console.log(response.data);


                        response.data.map((message) => {
                            data.push(createData(message.id, message.customerName, message.cbNumber, message.accountNumber, message.appId, message.serviceType, message.subServiceType, message.solId));

                        });

                        this.setState({
                            data: data,
                            getClaimCaseCaseData: data,

                        });


                    })

                    .catch(error => {
                        console.log(error);

                    });
            })
            .catch((error) => {
                console.log(error);
            })


    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    closeModal = () => {
        this.setState({
            inboxModal: false,


        });
        const data = [];


        if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") == "MERCHANT") {
            console.log("success");
            var url = backEndServerURL + '/workspaceClaimable';
        } else {
            var url = backEndServerURL + '/claimable';
        }

        axios.get(url, {
            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
        })
            .then(response => {
                console.log(response.data);


                response.data.map((message, index) => {
                    let date = message.delInitTime.replace("T", " ").split("T")[0];
                    data.push(createData(index, message.customerName, message.cbNumber, message.accountNumber, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory));

                });

                this.setState({
                    data: data,
                    getClaimCaseCaseData: data,
                    getData: true

                });


            })

            .catch(error => {
                console.log(error);

            });


    };
    renderInboxCase = () => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles").indexOf('DSTCS') !== -1) {
            return (
                <CSInboxCase closeModal={this.closeModal} appUid={this.state.appUid}
                             serviceType={this.state.serviceType} taskTitle={this.state.taskTitle}
                             subServiceType={this.state.subserviceType}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles").indexOf('CS') !== -1) {
            return (
                <CSInboxCase closeModal={this.closeModal} appUid={this.state.appUid}
                             serviceType={this.state.serviceType} taskTitle={this.state.taskTitle}
                             subServiceType={this.state.subserviceType}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles").indexOf('APPROVALOFFICER') !== -1) {
            return (
                <AbhApproval closeModal={this.closeModal} appUid={this.state.appUid}
                             serviceType={this.state.serviceType} taskTitle={this.state.taskTitle}
                             subServiceType={this.state.subserviceType}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles").indexOf('ABHCHECKER') !== -1) {

            return (
                <ABHCheckerInbox closeModal={this.closeModal} appUid={this.state.appUid}
                                 serviceType={this.state.serviceType} taskTitle={this.state.taskTitle}
                                 subServiceType={this.state.subserviceType}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles").indexOf('ABHMAKER') !== -1) {
            return (
                <AgentBanking closeModal={this.closeModal} appId={this.state.appUid}
                              serviceType={this.state.serviceType} taskTitle={this.state.taskTitle}
                              subServiceType={this.state.subserviceType}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles").indexOf('DSTBOM') !== -1) {
            return (
                <BOMInboxCase closeModal={this.closeModal} appUid={this.state.appUid}
                              serviceType={this.state.serviceType} taskTitle={this.state.taskTitle}
                              subServiceType={this.state.subserviceType}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace").indexOf("BUSINESS_DIVISION") !== -1) {

            return (
                <BMInboxCase closeModal={this.closeModal} appUid={this.state.appUid}
                             serviceType={this.state.serviceType} taskTitle={this.state.taskTitle}
                             subServiceType={this.state.subserviceType}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles").indexOf('BOM') !== -1) {
            return (
                <BOMInboxCase closeModal={this.closeModal} appUid={this.state.appUid}
                              serviceType={this.state.serviceType} taskTitle={this.state.taskTitle}
                              subServiceType={this.state.subserviceType}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles").indexOf('BM') !== -1) {

            return (
                <BMInboxCase closeModal={this.closeModal} appUid={this.state.appUid}
                             serviceType={this.state.serviceType} taskTitle={this.state.taskTitle}
                             subServiceType={this.state.subserviceType}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles").indexOf('MAKER') !== -1) {

            return (
                <MakerInboxCase closeModal={this.closeModal} appUid={this.state.appUid}
                                serviceType={this.state.serviceType} taskTitle={this.state.taskTitle}
                                subServiceType={this.state.subserviceType}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles").indexOf('MAKER') !== -1) {

            return (
                <MakerInboxCase closeModal={this.closeModal} appUid={this.state.appUid}
                                serviceType={this.state.serviceType} taskTitle={this.state.taskTitle}
                                subServiceType={this.state.subserviceType}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles").indexOf('CHECKER') !== -1) {

            return (
                <CheckerInboxCase closeModal={this.closeModal} appUid={this.state.appUid}
                                  serviceType={this.state.serviceType} taskTitle={this.state.taskTitle}
                                  subServiceType={this.state.subserviceType}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles").indexOf('MERCHANT') !== -1) {

            return (
                <MerchantInboxCase closeModal={this.closeModal} appId={this.state.appUid}
                                   serviceType={this.state.serviceType} taskTitle={this.state.taskTitle}
                                   subServiceType={this.state.subserviceType}/>
            )
        }

    };
    updateComponent = () => {
        this.forceUpdate(


        )
    };
    renderFilterForm = () => {

        return (
            CommonJsonFormComponent.renderJsonForm(this.state, filteringJsonForm, this.updateComponent)
        )


    };
    renderSelectSDTeamList = () => {
        if (this.state.getData) {
            let sdTeamList = {
                "varName": "sdTeamList",
                "type": "select",
                "label": "Select SD Team",
                "grid": 5,
                "enum": []
            };

            this.state.getAllSdTeam.map((data) => {

                sdTeamList.enum.push(data)
            });
            return (
                <Grid item xs={3}>
                    {
                        SelectComponent.select(this.state, this.updateComponent, sdTeamList)

                    }
                </Grid>
            )

        }
    };
    renderMakerListAPiCall = () => {
        if (this.state.inputData.sdTeamList !== undefined && this.state.inputData.sdTeamList !== null && this.state.inputData.sdTeamList !== "") {


            let sdTeamUrl = backEndServerURL + "/reassign/getUsers/" + this.state.inputData.sdTeamList;
            axios.get(sdTeamUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    console.log(response.data);
                    this.setState({
                        getAllMaker: response.data,
                        getData: true
                    })
                })
                .catch((error) => {
                    console.log(error);
                })

        }
    };
    renderSelectSDMemberList = () => {
        if (this.state.inputData.sdTeamList !== undefined && this.state.inputData.sdTeamList !== null && this.state.inputData.sdTeamList !== "") {
            var sdTeamMemberList = {
                "varName": "sdTeamMemberList",
                "type": "select",
                "label": "Select Maker",
                "grid": 5,
                "enum": []
            };

            this.state.getAllMaker.map((data) => {

                sdTeamMemberList.enum.push(data)
            });
            return (
                <Grid item xs={3}>

                    {
                        SelectComponent.select(this.state, this.updateComponent, sdTeamMemberList)

                    }
                </Grid>
            )

        }


    };
    renderAssignButton = () => {
        if (this.state.inputData.sdTeamMemberList !== undefined && this.state.inputData.sdTeamMemberList !== null && this.state.inputData.sdTeamMemberList !== "") {
            return (
                <Grid item xs={6}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{

                            marginTop: "15px"


                        }}
                        onClick={this.multipleCaseAssign}
                    >
                        Assign
                    </button>
                </Grid>
            )


        }
    };


    getFilterSubmited = (event) => {
        event.preventDefault();
        counter = 0;
        let objectTable = {};
        let tableArray = [];
        console.log(this.state.inputData);
        for (let variable in this.state.inputData) {
            let trimData = this.state.inputData[variable].trim();
            if (trimData !== '')
                objectTable[variable] = trimData;
        }
        console.log(objectTable);
        this.state.getInboxCaseData.map((inboxCase, index) => {
            let showable = true;
            for (let variable in objectTable) {
                if (objectTable[variable] !== inboxCase[variable])
                    showable = false;
            }

            if (showable)
                //let date = inboxCase.delInitTime.split("T")[0];
                tableArray.push(createData(index, inboxCase.customerName, inboxCase.cb_number, inboxCase.account_number, inboxCase.appUid, inboxCase.service_type, inboxCase.subservice_type, inboxCase.branch_id, inboxCase.currentUserName, inboxCase.taskTitle, inboxCase.jointAccountCustomerNumber, inboxCase.delInitTime, inboxCase.category, inboxCase.subCategory, inboxCase.delStatus));

        });
        this.setState({
            data: tableArray
        })
    };

    returnModalSizeOnRole() {
        let role = LocalstorageEncrypt.encryptStorageFunction.getItem("roles");
        if (role !== undefined) {
            if (role.indexOf("MAKER") !== -1 || role.indexOf("CHECKER") !== -1)
                return true;
            return false;
        }
        return false;
    }

    handleChange = (name, value) => {
        this.setState({
            selectTab: value
        })
    };
    multipleCaseAssign = (event) => {
        event.preventDefault();
        console.log(this.state.selected);

        let url = backEndServerURL + "/case/reassign/" + this.state.inputData.sdTeamMemberList;
        axios.post(url, this.state.selected, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully " + this.state.selected.length + " Case Assign!",
                    alert: true
                });
                const data = [];

                let url = backEndServerURL + '/claimable';


                axios.get(url, {
                    headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                })
                    .then(response => {
                        console.log(response.data);
                        let sdTeamUrl = backEndServerURL + "/sdTeam/get";
                        axios.get(sdTeamUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                this.setState({
                                    getAllSdTeam: response.data,
                                    getData: true
                                })


                            })
                            .catch((error) => {
                                console.log(error);
                            });
                        var urgency = "";
                        response.data.map((message, index) => {

                            if (message.urgency === 1) {
                                urgency = "HIGH"
                            } else {
                                urgency = "NORMAL"
                            }
                            data.push(createData(index, message.customerName, message.cbNumber, message.accountNumber, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory));

                        });

                        this.setState({
                            data: data,
                            getInboxCaseData: data,

                        });


                    })

                    .catch(error => {
                        console.log(error);

                    });

            })
            .catch((error) => {
                console.log(error);
            });
        console.log("kk")
    };
    updateComponent = () => {
        this.forceUpdate();
    };

    /* renderTeamMemberList = () => {
         if (this.state.getData) {
             return this.state.getAllMaker.map((data) => {
                 return (
                     <MenuItem key={data} value={data}>{data}</MenuItem>
                 )
             })

         }


     };*/
    /*  handleChangeSelectedSDTeamList = (name, value) => {
          this.setState({
              SelectedSDTeam: value,
          });
          let sdTeamUrl = backEndServerURL + "/reassign/getUsers/" + value;
          axios.get(sdTeamUrl, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
              .then((response) => {

                  console.log(response.data);
                  this.setState({
                      getAllMaker: response.data,
                      getData: true
                  })
              })
              .catch((error) => {
                  console.log(error);
              })

      };*/
    /* handleChangeSelectedSDTeamMember = (name, value) => {
         this.setState({
             SelectedSDTeamMember: value,
         });

     };*/

    /*  renderSDTeamList = () => {
          if (this.state.getData) {
              return this.state.getAllSdTeam.map((data) => {
                  return (
                      <MenuItem key={data} value={data}>{data}</MenuItem>
                  )
              })

          }


      }*/
    render() {
        const {classes} = this.props;
        const {data, order, orderBy, selected, rowsPerPage, page} = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <section>
                <br/>

                <Paper className={classes.root}>

                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>

                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>Search</h4>


                            </CardHeader>

                            <CardBody>
                                <Grid container spacing={1}>
                                    <ThemeProvider theme={theme}>

                                        {this.renderFilterForm()}

                                    </ThemeProvider>


                                </Grid>
                                <br/>


                                <button
                                    className="btn btn-outline-primary"
                                    style={{
                                        verticalAlign: 'middle',
                                    }}
                                    onClick={this.getFilterSubmited}

                                >
                                    Search
                                </button>


                            </CardBody>

                        </GridItem>
                    </GridContainer>

                </Paper>

                <br/>


                <Paper className={classes.root}>
                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>

                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>Reassign Case</h4>

                            </CardHeader>

                            <ThemeProvider theme={theme}>
                                <Grid container>

                                    {/*  <Grid item xs={3}>
                                       <FormControl>
                                        <InputLabel>Select SD Team</InputLabel>

                                        <Select name="select" value={this.state.SelectedSDTeam}
                                                onChange={event => this.handleChangeSelectedSDTeamList(event.target.name, event.target.value)}>
                                            {this.renderSDTeamList()}

                                        </Select>
                                    </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl>
                                            <InputLabel>Select Maker</InputLabel>

                                            <Select name="select" value={this.state.SelectedSDTeamMember}
                                                    onChange={event => this.handleChangeSelectedSDTeamMember(event.target.name, event.target.value)}>
                                                {this.renderTeamMemberList()}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <button
                                            className="btn btn-outline-primary btn-sm"
                                            style={{

                                                float: 'right',


                                            }}
                                            onClick={this.multipleCaseAssign}
                                        >
                                            Assign
                                        </button>
                                    </Grid>*/}
                                    {this.renderNotification()}
                                    {this.renderSelectSDTeamList()}
                                    {this.renderMakerListAPiCall()}
                                    {this.renderSelectSDMemberList()}
                                    {this.renderAssignButton()}
                                </Grid>
                            </ThemeProvider>
                            <div className={classes.tableWrapper}>
                                <Table className={classes.table} aria-labelledby="tableTitle">
                                    <TableContentHead
                                        sty
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={this.handleSelectAllClick}
                                        onRequestSort={this.handleRequestSort}
                                        rowCount={data.length}
                                    />
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="xl"
                                        fullScreen={true}
                                        open={this.state.inboxModal}>
                                        <DialogContent>

                                            {this.renderInboxCase()}
                                        </DialogContent>
                                    </Dialog>
                                    <TableBody>
                                        {stableSort(data, getSorting(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map(n => {
                                                const isSelected = this.isSelected(n.id);
                                                return (
                                                    <TableRow
                                                        hover
                                                        onClick={event => this.handleClick(event, n.appUid)}
                                                        role="checkbox"
                                                        aria-checked={isSelected}
                                                        tabIndex={-1}
                                                        key={n.id}
                                                        // onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber)}
                                                        selected={isSelected}
                                                    >
                                                        {/* <TableCell className={classes.tableCell} align="left">
                                                            {n.id}
                                                        </TableCell>*/}
                                                        <TableCell padding="none">
                                                            <Checkbox
                                                                // onClick={event => this.handleClick(event, n.appUid)}
                                                                role="checkbox"
                                                                aria-checked={isSelected}
                                                                tabIndex={-1}
                                                                key={n.appUid}
                                                                selected={isSelected}
                                                                inputProps={{
                                                                    'aria-label': 'primary checkbox',
                                                                }}
                                                            />
                                                        </TableCell>


                                                        <TableCell className={classes.tableCell} align="left">
                                                            {n.customer_name}
                                                        </TableCell>

                                                        <TableCell className={classes.tableCell}
                                                                   align="left">{n.cb_number}</TableCell>
                                                        <TableCell className={classes.tableCell}
                                                                   align="left">{n.account_number}</TableCell>
                                                        <TableCell className={classes.tableCell}
                                                                   align="left">{n.category}</TableCell>
                                                        <TableCell className={classes.tableCell}
                                                                   align="left">{n.subCategory}</TableCell>
                                                        <TableCell className={classes.tableCell}
                                                                   align="left">{n.delInitTime}</TableCell>
                                                        <TableCell className={classes.tableCell}
                                                                   align="left">{n.currentUserName}</TableCell>
                                                        <TableCell className={classes.tableCell}
                                                                   align="left">{n.branch_id}</TableCell>
                                                        <TableCell className={classes.tableCell} align="left">


                                                        </TableCell>


                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{height: 49 * emptyRows}}>
                                                <TableCell colSpan={6}/>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </div>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />

                        </GridItem>
                    </GridContainer>
                </Paper>
            </section>
        );
    }


}

ClaimCase.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ClaimCase);
