import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Functions from '../../../Common/Functions';
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import Notification from "../../NotificationMessage/Notification";
import loading from "../../../Static/loader.gif";
import CBNotFound from "../CASA/CBNotFound";
import TableComponent from "../CommonComponent/TableComponent";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {Menu} from "semantic-ui-react";
import {
    commonInboxButton,
    commonInboxTabActive,
    commonInboxTabInactive
} from "../../DeliverableManagement/configuration";
import Badge from "@material-ui/core/Badge";
import {rowsForDeferral} from "../ColumnNameForInbox"
import CaseViewPage from "./CaseViewPage";
import BOMInboxCase from "../BOMInboxCase";
import SubmittedCaseHistory from "./SubmittedCaseHistory";
import VerifyMakerPhoto from "../VerifyMakerPhoto";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";

const filterField = [
    {
        "varName": "caseId",
        "type": "textDedup",
        "label": "LWF Case Number",
        "grid": 2,
    },

    {
        "varName": "deferralId",
        "type": "textDedup",
        "label": "Deferral ID",
        "grid": 2,
    },
    {
        "varName": "category",
        "type": "select",
        "enum": [
            "CASA",
            "FDR",
            "DPS",
            "Chequebook",
            "RTGS",
            "BEFTN",
            "Salary",
            "Debit Card Issuance",
            "BOND",
            "SANCHAYPATRA",
            "Debit Card",
            "Credit Card",
            "CARDS",
            "ADC",
            "Internet Banking",
            "Remittance",
            "Pay Order and FDD Bulk",
            "Locker",
            "Delivarable",
            "Deferral",
        ],
        "label": "Category",
        "grid": 2,
    },
    {
        "varName": "subCategory",
        "type": "select",
        "enum": [
            "A/C Opening",
            "Opening",
            "Schem Account opeing",
            "TD Open/FDR",
            "Maintenance",
            "Single Requisition",
            "Opening",
            "Service",
            "Single Transaction",
            "Bulk Transaction",
            "Requisition",
            "Chequebook",
            "Bulk Insta Debit Card",
            "Bulk Requisition",
            "CASA Closer",
            "FDR Closer",
            "FDR Maintenace",
            "CARD Against FDR/ DPS",
            "Tracker",
            "Instant Card",
            "General Card Requisition",
            "CARD Cheque Requisition",
            "City Touch Enrollment",
            "SMS Discountinue",
            "Outward",
            "Inward",
            "Student File",
            "Medical File",
            "Searcher",
            "Others",
            "Pay Order Bulk Requisition",
            "FDD Bulk Requisition",
            "Others",
            "Requistion",
            "Surrender",
            "Locker Maintenace",
            "Reconcilation Branch",
            "Insurance Upload",
            "Insta Pack Requisition",
            "Insta Card Requisition",
            "Insta Pack Tracker",
            "Insta Card Tracker",
            "WL Priority",
            "WL Non Priority",
            "CASA Defferal",
            "FD Deferal",
            "Others",
            "FDR",
            "Encashment",
            "ISSUANCE - Wage Earners Development Bond",
            "ISSUANCE - U.S. Dollar Investment Bond",
            "ISSUANCE - U.S. Dollar Premium Bond",
            "ISSUANCE - 5 Years Bangladesh Sanchaya Patra",
            "ISSUANCE - 3 Months Interest Based Sanchaya Patra",
            "ISSUANCE - Family Sanchaya Patra",
            "ISSUANCE - Pensioners Savings Certificate",
            "ENCASHMENT - Wage Earners Development Bond - ENCASHMENT PAYMENT",
            "ENCASHMENT - Wage Earners Development Bond - INTEREST PAYMENT",
            "ENCASHMENT - U.S. Dollar Investment Bond - ENCASHMENT PAYMENT",
            "ENCASHMENT - U.S. Dollar Investment Bond - INTEREST PAYMENT",
            "ENCASHMENT - U.S. Dollar Premium Bond - ENCASHMENT PAYMENT",
            "ENCASHMENT - U.S. Dollar Premium Bond - INTEREST PAYMENT",
            "ENCASHMENT - 5 Years Bangladesh Sanchaya Patra - ENCASHMENT PAYMENT",
            "ENCASHMENT - 5 Years Bangladesh Sanchaya Patra - INTEREST PAYMENT",
            "ENCASHMENT - 3 Months Interest Based Sanchaya Patra - ENCASHMENT PAYMENT",
            "ENCASHMENT - 3 Months Interest Based Sanchaya Patra - INTEREST PAYMENT",
            "ENCASHMENT - Family Sanchaya Patra - ENCASHMENT PAYMENT",
            "ENCASHMENT - Family Sanchaya Patra - INTEREST PAYMENT",
            "ENCASHMENT - Pensioners Savings Certificate - ENCASHMENT PAYMENT",
            "ENCASHMENT - Pensioners Savings Certificate - INTEREST PAYMENT",
            "MAINTENANCE - Wage Earners Development Bond - NOMINEE CHANG",
            "MAINTENANCE - Wage Earners Development Bond - ACES ENROLLMENT",
            "MAINTENANCE - Wage Earners Development Bond - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - Wage Earners Development Bond - DUPLICATE ISSUANCE",
            "MAINTENANCE - Wage Earners Development Bond - CERTIFICATE",
            "MAINTENANCE - U.S. Dollar Investment Bond - NOMINEE CHANG",
            "MAINTENANCE - U.S. Dollar Investment Bond - ACES ENROLLMENT",
            "MAINTENANCE - U.S. Dollar Investment Bond - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - U.S. Dollar Investment Bond - DUPLICATE ISSUANCE",
            "MAINTENANCE - U.S. Dollar Investment Bond - CERTIFICATE",
            "MAINTENANCE - U.S. Dollar Premium Bond - NOMINEE CHANG",
            "MAINTENANCE - U.S. Dollar Premium Bond - ACES ENROLLMENT",
            "MAINTENANCE - U.S. Dollar Premium Bond - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - U.S. Dollar Premium Bond - DUPLICATE ISSUANCE",
            "MAINTENANCE - U.S. Dollar Premium Bond - CERTIFICATE",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - NOMINEE CHANG",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - ACES ENROLLMENT",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - DUPLICATE ISSUANCE",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - CERTIFICATE",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - NOMINEE CHANG",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - ACES ENROLLMENT",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - DUPLICATE ISSUANCE",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - CERTIFICATE",
            "MAINTENANCE - Family Sanchaya Patra - NOMINEE CHANG",
            "MAINTENANCE - Family Sanchaya Patra - ACES ENROLLMENT",
            "MAINTENANCE - Family Sanchaya Patra - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - Family Sanchaya Patra - DUPLICATE ISSUANCE",
            "MAINTENANCE - Family Sanchaya Patra - CERTIFICATE",
            "MAINTENANCE - Pensioners Savings Certificate - NOMINEE CHANG",
            "MAINTENANCE - Pensioners Savings Certificate - ACES ENROLLMENT",
            "MAINTENANCE - Pensioners Savings Certificate - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - Pensioners Savings Certificate - DUPLICATE ISSUANCE",
            "MAINTENANCE - Pensioners Savings Certificate - CERTIFICATE",
        ],
        "label": "Sub Category",
        "grid": 2,
    },
    /*{
        "varName": "branch_id",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Branch",
        "grid": 2,
    },*/
    {
        "varName": "cbNumber",
        "type": "textDedup",
        "label": "CB Number",
        "grid": 2,
    },
    {
        "varName": "accountNumber",
        "type": "textDedup",
        "label": "Account No.",
        "grid": 2,
    },
    /*{
        "varName": "customerName",
        "type": "textDedup",
        "label": "Customer name",
        "grid": 2,
    },*/
    {
        "varName": "fromDate",
        "type": "date",
        "label": "From Date",
        "grid": 2,
    },
    {
        "varName": "toDate",
        "type": "date",
        "label": "To Date",
        "grid": 2,
    },
    /*{
        "varName": "urgency",
        "type": "select",
        "enum": [
            "High",
            "Medium",
            "Low",
        ],
        "label": "Urgency",
        "grid": 2,
    },*/
    /*{
        "varName": "cutOffTime",
        "type": "select",
        "enum": [
            "Before",
            "After",
        ],
        "label": "Cut Off Time",
        "grid": 2,
    },*/
];


const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

class CSDeferalClose extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            loading: true,
            cbNotFound: false,
            getData: false,
            varValue: {},
            title: "",
            notificationMessage: "",
            alert: false,
            selectedDate: {},
            showValue: false,
            openHierarchy: false,
            err: false,
            searchData: false,
            activeItem: "Group Inbox",
            showTable: false,
            errorArray: {},
            errorMessages: {},
            tableData: [],
            totalRow: 0,
            rowsPerPage: 100,
            tableColumns: [],
            page: 0,
            getCountPending: 0,
            getCountReturn: 0,
            getCountInbox: 0,
            getCountRectified: 0,
            getCountGroupInbox: 0,
            showInbox: false,
            serviceType: "",
            subServiceType: "",
            category: "",
            subCategory: "",
            solId: "",
            appUid: "",
            delStatus: "",
            taskTitle: "",
            status: "",
            freeFlag1: "",
            freeFlag2: "",
            freeFlag3: "",
            freeFlag4: "",
            recpmtid: "",
            cb_number: "",
            deferralId: "",
            deferralType: "",
            deferralDocType: "",
            account_number: "",
            customer_name: "",
            urgency: "",
            sentByUsername: "",
            jointAccountCustomerNumber: "",
            expire: "",
            expireStatus: "",
            CaseId: "",
            documentList: [],
            getDocument: false
        }
    }


    renderTableColumn = () => {
        let tableColumn = [];
        tableColumn = [...rowsForDeferral];

        return tableColumn;
    };


    getApiUrl = () => {
        let url = "";

        const data = [];

        let workFlowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        console.log(workFlowGroup);
        // if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "CHECKER" || LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "CASA CHECKER (MAINTENANCE)") {
        if (workFlowGroup === "CHECKER" || workFlowGroup === "MOU_CHECKER") {
            url = backEndServerURL + "/deferral/SDclaimable/withoutRectified/0";
            this.setState({
                activeItem: 'Group Inbox',
            });
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "BOM" || workFlowGroup === "MOU_MAKER") {

            url = backEndServerURL + "/deferral/BOMEndGroup/0";
        } else {

            url = backEndServerURL + '/deferral/CSEndOpen/0';
            this.setState({
                /*                activeItem: 'ACTIVE',*/
                activeItem: 'OPEN',
            });
        }


        return url;
    };

    functionForCategoryCount = () => {
        let countUrl = backEndServerURL + "/inbox/getCountCategoryWise";
        axios.get(countUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let getData = response.data;
                this.setState({
                    getCountInbox: getData.inbox,
                    getCountReturn: getData.return,
                    getCountRectified: getData.rectified,
                    getCountSubmit: getData.inbox,
                    getCountPending: getData.draft,
                    getCountGroupInbox: getData.teamGroup,
                    getCountCertificateInbox: getData.inbox,
                    getCountCompleteInbox: getData.inbox,
                });
                LocalstorageEncrypt.encryptStorageFunction.setItem("inboxNumberOfCount", Number(getData.inbox) + Number(getData.return) + Number(getData.draft));
            })
            .catch((error) => {
                console.log(error);
            });
    };


    componentDidMount() {
        let branch = [];
        let branchUrl = backEndServerURL + "/workplaceUnit/getInitialUnits";
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((item) => {
                    branch.push({key: item.solId, value: item.name})
                });
                for (let form of filterField) {
                    if (form.type === "autoCompleteValueReturn" && form.varName === "branch_id") {
                        form.enum = branch
                    }
                }
                // this.functionForCategoryCount();
                this.fetchTableData(this.getApiUrl(), 0)
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    getData: true,
                    showTable: true,
                    loading: false,
                });
            });
    }


    fetchTableData = (url, page) => {
        //// this.functionForCategoryCount();
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(response => {
            let tableData = [];
            console.log(response.data.content)
            response.data.content.map((message, index) => {
                tableData.push({
                    SL: page > 0 ? ((index + 1) + (100 * page)) : index + 1,
                    CaseId: message.caseId,
                    deferralId: message.deferralId,
                    deferralType: message.deferralType,
                    deferralDocType: message.deferralDocType,
                    customer_name: message.customerName,
                    cb_number: message.cbNumber,
                    account_number: message.accountNumber,
                    businessSegment: message.businessSegment,
                    category_type: this.returnCategory(message.branchingCategory, message.freeFlag5, message.category),
                    sub_category_type: this.returnSubCategory(message.branchingSubCategory, message.freeFlag6, message.subCategory),
                    Service: message.priorityDesc,
                    Date: message.delInitTime.replace("T", " "),
                    currentUserName: message.currentUserName,
                    branch_name: message.branchName,
                    Status: this.customizeStatus(message.delStatus),
                    freeFlag1: message.freeFlag1,
                    freeFlag2: message.freeFlag2,
                    freeFlag3: message.freeFlag3,
                    freeFlag4: message.freeFlag4,
                    recpmtid: message.recpmtid,
                    freeFlag5: message.freeFlag5,
                    freeFlag6: message.freeFlag6,
                    appId: message.appId,
                    serviceType: message.serviceType,
                    subServiceType: message.subServiceType,
                    taskTitle: message.taskTitle,
                    solId: message.solId,
                    urgency: message.urgency,
                    returnedBack: message.returnBackTo ? message.returnBackTo : "",
                    returnedBy: message.sentByUsername,
                    jointAccountCustomerNumber: message.jointAccountCustomerNumber,
                    requestStatus: this.customizeStatus(message.status),
                    makerId: message.lastMakerUser,
                    expire: message.expire,
                    expireStatus: message.expireStatus
                });
            });
            this.setState({
                page: response.data.number,
                totalRow: response.data.totalElements,
                tableData: tableData,
                getData: true,
                showTable: true,
                loading: false
            })

        }).catch(error => {
            console.log(error);
            this.setState({
                loading: false,
                showTable: true,
                searchData: false,
            })
        });
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderCloseIcon = () => {
        return (
            <h4 style={{color: "white"}} >My Deferral</h4>
        )
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    handleChangePage = (pageNumber) => {
        this.setState({
            loading: true,
            getData: false,
            showTable: false
        });
        if (this.state.searchData) {
            this.searchHandler("", pageNumber)
        } else {
            this.functionForGetTabWiseUrl(this.state.activeItem, pageNumber);
        }
    };


    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'null' || clone[prop] === null || clone[prop] === 'undefined')
                delete clone[prop];
        return clone;
    };


    returnCategory = (branchingCategory, freeFlag5, category) => {
        if (branchingCategory !== undefined && branchingCategory !== null && branchingCategory !== "") {
            return branchingCategory
        } else if (freeFlag5 !== undefined && freeFlag5 !== null && freeFlag5 !== "") {
            return freeFlag5
        } else {
            return category
        }
    };

    returnSubCategory = (branchingSubCategory, freeFlag6, subCategory) => {
        if (branchingSubCategory !== undefined && branchingSubCategory !== null && branchingSubCategory !== "") {
            return branchingSubCategory
        } else if (freeFlag6 !== undefined && freeFlag6 !== null && freeFlag6 !== "") {
            return freeFlag6
        } else {
            return subCategory
        }
    };
    customizeStatus = (status) => {
        if (status === "CLOSE_WAITING") {
            return "Waiting for SD Approval"
        } else if (status === "CLOSE_WAITING_RETURN") {
            return "Return from Checker"
        } else if (status === "CLOSE_WAITING_BOM") {
            return "Waiting for BOM Approval"
        } else if (status === "CLOSE_WAITING_RETURN_BOM") {
            return "Return from BOM"
        } else if (status === "INACTIVE") {
            return "Closed";
        } else {
            return status;
        }
    }
    searchHandler = (event, pageNumber) => {
        this.setState({
            page: 0,
            totalRow: 0,
            tableData: [],
            loading: true,
            showTable: false,
        });
        let pageN = pageNumber ? pageNumber : 0;
        let postData = this.removeNullValue(this.state.inputData);
        if (this.state.activeItem === "Group Inbox" && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf("INTERNET_BANKING") !== -1) {
            postData.tabName = "INTERNET_BANKING"
        } else if (this.state.activeItem === "Group Inbox") {
            postData.tabName = "Group Inbox"
        }
        let workFlowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        let status = (this.state.activeItem === "Group Inbox" && (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "BOM") || workFlowGroup === "MOU_MAKER") ? "BOMGROUP"
            : (this.state.activeItem === "Self-Inbox" && (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "BOM") || workFlowGroup === "MOU_MAKER") ? "BOMSELF"
                : (this.state.activeItem === "Group Inbox" && (workFlowGroup === "CHECKER" || workFlowGroup === "MOU_CHECKER")) ? "CHECKERGROUP"
                    : (this.state.activeItem === "Self-Inbox" && (workFlowGroup === "CHECKER" || workFlowGroup === "MOU_CHECKER")) ? "CHECKERSELF"
                        : (this.state.activeItem === "COMPLETE" && (workFlowGroup === "CHECKER" || workFlowGroup === "MOU_CHECKER")) ? "CHECKERCOMPLETE"
                            : this.state.activeItem === "Pending" ? "Saved" : this.state.activeItem === "Certificate" ? "Enrollment" : this.state.activeItem;
        let url = backEndServerURL + "/deferral/filterUserInbox/" + status + "/" + pageN;
        axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(response => {
            let tableData = [];
            response.data.content.map((message, index) => {
                tableData.push({
                    SL: pageNumber > 0 ? ((index + 1) + (100 * pageNumber)) : index + 1,
                    CaseId: message.caseId,
                    deferralId: message.deferralId,
                    deferralType: message.deferralType,
                    deferralDocType: message.deferralDocType,
                    customer_name: message.customerName,
                    cb_number: message.cbNumber,
                    account_number: message.accountNumber,
                    businessSegment: message.businessSegment,
                    category_type: this.returnCategory(message.branchingCategory, message.freeFlag5, message.category),
                    sub_category_type: this.returnSubCategory(message.branchingSubCategory, message.freeFlag6, message.subCategory),
                    Service: message.priorityDesc,
                    Date: message.delInitTime.replace("T", " "),
                    currentUserName: message.currentUserName,
                    branch_name: message.branchName,
                    Status: this.customizeStatus(message.delStatus),
                    freeFlag1: message.freeFlag1,
                    freeFlag2: message.freeFlag2,
                    freeFlag3: message.freeFlag3,
                    freeFlag4: message.freeFlag4,
                    recpmtid: message.recpmtid,
                    freeFlag5: message.freeFlag5,
                    freeFlag6: message.freeFlag6,
                    appId: message.appId,
                    serviceType: message.serviceType,
                    subServiceType: message.subServiceType,
                    taskTitle: message.taskTitle,
                    solId: message.solId,
                    urgency: message.urgency,
                    returnedBack: message.returnBackTo ? message.returnBackTo : "",
                    returnedBy: message.sentByUsername,
                    jointAccountCustomerNumber: message.jointAccountCustomerNumber,
                    requestStatus: this.customizeStatus(message.status),
                    makerId: message.lastMakerUser,
                    expire: message.expire,
                    expireStatus: message.expireStatus,
                });
            });
            this.setState({
                page: response.data.number,
                totalRow: response.data.totalElements,
                tableData: tableData,
                getData: true,
                showTable: true,
                searchData: true,
                loading: false
            })

        }).catch(error => {
            console.log(error);
            this.setState({
                loading: false,
                showTable: true,
                searchData: false,
            })
        });
    };

    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.searchHandler(event, 0);
        }
    };


    renderFilterField = () => {
        return (
            <Grid container>
                {CommonJsonFormComponent.renderJsonForm(this.state, filterField, this.updateComponent, this.onKeyDownForDedup)}
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: '15px',
                        marginLeft: '15px',
                    }}
                    onClick={(e) => this.searchHandler(e, 0)}
                    type="submit"
                >
                    Search
                </button>
                &nbsp;
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: '15px',
                        marginLeft: '5px',
                    }}
                    onClick={() => window.location.reload()}
                    type="submit"
                >
                    Reset
                </button>
            </Grid>
        );
    };


    headerSelect = (event, value) => {
        event.preventDefault();
        this.setState({
            searchData: false,
            activeItem: value,
            loading: true,
            getData: false,
            showTable: false,
            page: 0,
            tableData: [],
        }, this.functionForGetTabWiseUrl(value, 0));
    };

    functionForGetTabWiseUrl = (value, page) => {
        let workFlowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        this.forceUpdate();
        let urls = "";
        /*  if (value === "ACTIVE" && LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "CS") {
              urls = backEndServerURL + "/deferral/CSEnd/" + page;
          }*/
        if (value === "OPEN" && (workFlowGroup === "CS" || workFlowGroup === "MBU")) {
            urls = backEndServerURL + "/deferral/CSEndOpen/" + page;
        } else if (value === "EXPIRE" && (workFlowGroup === "CS" || workFlowGroup === "MBU")) {
            urls = backEndServerURL + "/deferral/CSEndExpire/" + page;
        } else if (value === "COMPLETE" && (workFlowGroup === "CS" || workFlowGroup === "MBU")) {
            urls = backEndServerURL + "/deferral/CSEndComplete/" + page;
        } else if (value === "RETURN" && (workFlowGroup === "CS" || workFlowGroup === "MBU")) {
            urls = backEndServerURL + "/deferral/returnCSEnd/" + page;
        } else if (value === "SUBMIT" && (workFlowGroup === "CS" || workFlowGroup === "MBU")) {
            urls = backEndServerURL + "/deferral/submitCSEnd/" + page;
        } else if (value === "Group Inbox" && (workFlowGroup === "CHECKER" || workFlowGroup === "MOU_CHECKER")) {

            urls = backEndServerURL + "/deferral/SDclaimable/withoutRectified/" + page;
        } else if (value === "Self-Inbox" && (workFlowGroup === "CHECKER" || workFlowGroup === "MOU_CHECKER")) {

            urls = backEndServerURL + "/deferral/closable/" + page;
        } else if (value === "COMPLETE" && (workFlowGroup === "CHECKER" || workFlowGroup === "MOU_CHECKER")) {
            urls = backEndServerURL + "/deferral/checkerComplete/" + page;
        } else if (value === "Group Inbox" && (workFlowGroup === "BOM" || workFlowGroup === "MOU_MAKER")) {

            urls = backEndServerURL + "/deferral/BOMEndGroup/" + page;
        } else if (value === "Self-Inbox" && (workFlowGroup === "BOM" || workFlowGroup === "MOU_MAKER")) {

            urls = backEndServerURL + "/deferral/BOMEndSelf/" + page;
        } else if (value === "SUBMIT" && (workFlowGroup === "BOM" || workFlowGroup === "MOU_MAKER")) {
            urls = backEndServerURL + "/deferral/submitBOMEnd/" + page;
        } else {
            urls = backEndServerURL + "/deferral/CSEndOpen/" + page;
        }
        this.fetchTableData(urls, page)
    };

    functionForCaseLockOrUnlock = (event, data) => {
        event.preventDefault();
        let selectedAppId = [];
        console.log(data);
        for (let item of data) {
            selectedAppId.push(item.deferralId)
        }
        console.log(selectedAppId)
        this.setState({
            loading: true,
            getData: false,
            showTable: false
        });
        let single_url = "";
        /*        if (this.state.activeItem === "Return" || this.state.activeItem === "DocumentUpload" || this.state.activeItem === "Group Inbox" || this.state.activeItem === "Remittance Group Inbox" || this.state.activeItem === "Remittance Group Inbox SD" || this.state.activeItem === "Dormant Activation Group Inbox" || this.state.activeItem === "Account Opening Group Inbox" || this.state.activeItem === "Maintenance Group Inbox") {
                    single_url = backEndServerURL + "/case/claim/";
                }*/

        single_url = backEndServerURL + "/deferral/case/unclaim/";


        axios.post(single_url, selectedAppId, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.functionForGetTabWiseUrl(this.state.activeItem, this.state.page);
            }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false,
                getData: true,
                showTable: true
            })
        })
    };

    renderTopLabelButton = () => {
        /* if ((LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MAKER" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CHECKER") && (this.state.activeItem === "Group Inbox" || this.state.activeItem === "Account Opening Group Inbox" || this.state.activeItem === "Remittance Group Inbox" || this.state.activeItem === "Dormant Activation Group Inbox" || this.state.activeItem === 'Maintenance Group Inbox' || this.state.activeItem === "Remittance Group Inbox SD" || this.state.activeItem === "Return")) {
             return [{
                 name: "LOCK",
                 style: {...commonInboxButton},
                 triggerFunction: this.functionForCaseLockOrUnlock
             }]
         } else if (this.state.activeItem === "Group Inbox" || this.state.activeItem === "Remittance Group Inbox" || this.state.activeItem === "Dormant Activation Group Inbox" || this.state.activeItem === "Maintenance Group Inbox" || this.state.activeItem === "Account Opening Group Inbox" || this.state.activeItem === "Return") {
             return [{
                 name: "LOCK",
                 style: {...commonInboxButton},
                 triggerFunction: this.functionForCaseLockOrUnlock
             }]
         } else if (this.state.activeItem === "DocumentUpload" || this.state.activeItem === "Group Inbox" || this.state.activeItem === "Remittance Group Inbox" || this.state.activeItem === "Dormant Activation Group Inbox" || this.state.activeItem === "Maintenance Group Inbox" || this.state.activeItem === "Account Opening Group Inbox" || this.state.activeItem === "Return") {
             return [{
                 name: "LOCK",
                 style: {...commonInboxButton},
                 triggerFunction: this.functionForCaseLockOrUnlock
             }]
         } else */

        if ((this.state.activeItem === "Self-Inbox" && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") !== "MAKER") || (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") !== "MAKER" && this.state.activeItem === "Return")) {
            return [{
                name: "UNLOCK",
                style: {...commonInboxButton},
                triggerFunction: this.functionForCaseLockOrUnlock
            }]
        }
    };

    renderMenu = () => {
        return (
            <div style={{marginRight: "8px"}}>
                <Menu pointing>
                    {this.renderMenuItem("DEFERRAL GROUP INBOX", "Group Inbox", this.state.getCountReturn, "Group Inbox")}
                    {this.renderMenuItem("DEFERRAL SELF INBOX", "Self-Inbox", this.state.getCountReturn, "Self-Inbox")}
                    {/*{this.renderMenuItem("DEFERRAL ACTIVE", "ACTIVE", this.state.getCountReturn, "ACTIVE")}*/}
                    {this.renderMenuItem("DEFERRAL OPEN", "OPEN", this.state.getCountReturn, "OPEN")}
                    {this.renderMenuItem("DEFERRAL EXPIRE", "EXPIRE", this.state.getCountReturn, "EXPIRE")}
                    {this.renderMenuItem("DEFERRAL RETURN", "RETURN", this.state.getCountReturn, "RETURN")}
                    {this.renderMenuItem("DEFERRAL COMPLETE", "COMPLETE", this.state.getCountReturn, "COMPLETE")}
                    {this.renderMenuItem("DEFERRAL SUBMIT", "SUBMIT", this.state.getCountReturn, "SUBMIT")}

                </Menu>
            </div>
        );
    };

    renderMenuItem = (permission, activeItem, badge, name) => {

        let workFlowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");

        if (LocalstorageEncrypt.encryptStorageFunction.getItem("menus").indexOf(permission) !== -1) {
            /*
                    if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "CS" && (activeItem === "ACTIVE" || activeItem === "RETURN" || activeItem === "SUBMIT" ||  activeItem === "OPEN" ||  activeItem === "EXPIRE" ||  activeItem === "COMPLETE" )) {
            */
            // if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "CS" && (activeItem === "RETURN" || activeItem === "SUBMIT" ||  activeItem === "OPEN" ||  activeItem === "EXPIRE" ||  activeItem === "COMPLETE" )) {
            return (
                <Menu.Item
                    name={activeItem}
                    active={this.state.activeItem === activeItem}
                    style={this.state.activeItem === activeItem ? commonInboxTabActive : commonInboxTabInactive}
                    onClick={e => {
                        this.headerSelect(e, activeItem);
                    }}
                >
                    {this.renderBadge(badge, name)}
                </Menu.Item>
            )
            // } else if ((LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "CHECKER" || LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "CASA CHECKER (MAINTENANCE)" || LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "BOM") && (activeItem === "Group Inbox" || activeItem === "Self-Inbox" || activeItem === "SUBMIT")) {
        } /*else if ((workFlowGroup === "CHECKER" && (activeItem === "Group Inbox" || activeItem === "Self-Inbox" || activeItem === "COMPLETE")) || (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "BOM" && (activeItem === "Group Inbox" || activeItem === "Self-Inbox" || activeItem === "SUBMIT"))) {
            return (
                <Menu.Item
                    name={activeItem}
                    active={this.state.activeItem === activeItem}
                    style={this.state.activeItem === activeItem ? commonInboxTabActive : commonInboxTabInactive}
                    onClick={e => {
                        this.headerSelect(e, activeItem);
                    }}
                >
                    {this.renderBadge(badge, name)}
                </Menu.Item>
            )
        }*/

    };

    renderBadge = (value, name) => {
        if (value !== undefined && value > 0) {
            return (
                <Badge /**/ color="primary">
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        } else {
            return (
                <Badge color="primary">
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        }
    };


    onRowClick = (event, rowData) => {
        event.preventDefault();
        this.setState({
            loading: true,
        });
        let workFlowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        if ((LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MBU" || LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "BOM" || workFlowGroup === "MOU_MAKER")) {
            if ((LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MBU" || LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "BOM" || workFlowGroup === "MOU_MAKER")) {
                let url = backEndServerURL + "/deferral/case/claim/" + rowData.deferralId;
                axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => {
                        if (this.state.activeItem !== "SUBMIT" || this.state.activeItem !== "COMPLETE") {
                            this.setInboxProps(rowData)
                        } else {
                            this.setState({
                                loading: false
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })

            } else {
                if (this.state.activeItem !== "SUBMIT" || this.state.activeItem !== "COMPLETE") {
                    this.setInboxProps(rowData)
                } else {
                    this.setState({
                        loading: false
                    })
                }
            }
        } else {
            if ((workFlowGroup === "CHECKER" || workFlowGroup === "MOU_CHECKER" || LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "BOM" || workFlowGroup === "MOU_MAKER")) {
                let url = backEndServerURL + "/deferral/case/claim/" + rowData.deferralId;
                axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => {
                        if (this.state.activeItem !== "SUBMIT" || this.state.activeItem !== "COMPLETE") {
                            this.setInboxProps(rowData)
                        } else {
                            this.setState({
                                loading: false
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })

            } else {
                if (this.state.activeItem !== "SUBMIT" || this.state.activeItem !== "COMPLETE") {
                    this.setInboxProps(rowData)
                } else {
                    this.setState({
                        loading: false
                    })
                }
            }
        }

    };

    setInboxProps = (rowData) => {
        console.log(rowData);
        this.setState({
            CaseId: rowData.CaseId,
            serviceType: rowData.serviceType,
            subServiceType: rowData.subServiceType,
            category: rowData.category_type,
            subCategory: rowData.sub_category_type,
            solId: rowData.solId,
            appUid: rowData.appId,
            delStatus: this.customizeStatus(rowData.Status),
            taskTitle: rowData.taskTitle,
            status: this.customizeStatus(rowData.requestStatus),
            freeFlag1: rowData.freeFlag1,
            freeFlag2: rowData.freeFlag2,
            freeFlag3: rowData.freeFlag3,
            freeFlag4: rowData.freeFlag4,
            recpmtid: rowData.recpmtid,
            cb_number: rowData.cb_number,
            account_number: rowData.account_number,
            customer_name: rowData.customer_name,
            urgency: rowData.urgency,
            sentByUsername: rowData.returnedBy,
            jointAccountCustomerNumber: rowData.jointAccountCustomerNumber,
            deferralId: rowData.deferralId,
            deferralType: rowData.deferralType,
            deferralDocType: rowData.deferralDocType,
            showInbox: (this.state.activeItem !== "SUBMIT" && this.state.activeItem !== "COMPLETE" ? true : false),
            loading: false
        });
    };


    renderTable = () => {
        return (
            (this.state.getData && this.state.showTable === true) &&
            <div className="deliverable">
                <TableComponent tableData={this.state.tableData} tableColumns={this.renderTableColumn()}
                                onRowClick={this.onRowClick} loader={this.state.loading} maxBodyHeight="450px"
                                totalRow={this.state.totalRow} page={this.state.page} whiteSpace={"wrap"}
                                selection={(this.state.activeItem === "Self-Inbox" || this.state.activeItem === "Return")}
                                handleChangePage={this.handleChangePage} rowsPerPage={this.state.rowsPerPage}
                                tableButton={this.renderTopLabelButton()}

                />
            </div>

        )
    };

    closeModal = (user, data, action) => {
        let userType = "";
        let workFlowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");

        let forwaredUser = "";
        if (user === "CS" || user === "MBU") {
            forwaredUser = "BOM!"
            if (workFlowGroup === "MBU" || workFlowGroup === "MOU_MAKER" || workFlowGroup === "MOU_CHECKER") {
                forwaredUser = "MOU MAKER!"
            }
        } else if (user === "BOM" || user === "MOU_MAKER") {
            forwaredUser = "CHECKER!"
            if (workFlowGroup === "MBU" || workFlowGroup === "MOU_MAKER" || workFlowGroup === "MOU_CHECKER") {
                forwaredUser = "MOU CHECKER"
            }
        } else if ((user === "BOM" || user === "MOU_MAKER") && action !== "APPROVED") {
            forwaredUser = "CS!!"
            if (workFlowGroup === "MBU" || workFlowGroup === "MOU_MAKER" || workFlowGroup === "MOU_CHECKER") {
                forwaredUser = "MBU"
            }
        } else if ((user === "CHECKER" || user === "MOU_CHECKER" || user === "CASA CHECKER (MAINTENANCE)") && action !== "APPROVED") {
            forwaredUser = "CS!!"
            if (workFlowGroup === "MBU" || workFlowGroup === "MOU_MAKER" || workFlowGroup === "MOU_CHECKER") {
                forwaredUser = "MBU"
            }
        } else if ((user === "BOM" || user === "MOU_MAKER") && action === "APPROVED") {
            forwaredUser = "CHECKER!!"
            if (workFlowGroup === "MBU" || workFlowGroup === "MOU_MAKER" || workFlowGroup === "MOU_CHECKER") {
                forwaredUser = "MOU CHECKER";
            }
        }
        if (user === "CS" || user === "MBU") {
            this.setState({
                title: "Successfull!",
                notificationMessage: "Forward To " + forwaredUser,
                alert: true,
            })
        } else if ((user === "BOM" || user === "MOU_MAKER") && action === "APPROVED") {

            this.setState({
                title: "Successfull!",
                notificationMessage: "Forward To " + forwaredUser,
                alert: true,
            })
        } else if ((user === "BOM" || user === "MOU_MAKER") && action !== "APPROVED") {

            this.setState({
                title: "Successfull!",
                notificationMessage: "Forward To " + forwaredUser,
                alert: true,
            })
        } else if ((user === "CHECKER" || user === "MOU_CHECKER" || user === "CASA CHECKER (MAINTENANCE)") && action === "APPROVED") {
            this.setState({
                title: "Successfull!",
                notificationMessage: "Successfully Close!",
                alert: true,

            })
        } else if ((user === "CHECKER" || user === "MOU_CHECKER" || user === "CASA CHECKER (MAINTENANCE)") && action !== "APPROVED") {

            this.setState({
                title: "Successfull!",
                notificationMessage: "Forward To " + forwaredUser,
                alert: true,

            })
        } else if (user === "CLOSED" || action === "CLOSED") {
            this.setState({
                title: "Successfull!",
                notificationMessage: "Permanent Closed!",
                alert: true,

            })
        }

        this.setState({
            showInbox: false,
            getData: false,
            showTable: false,
            loading: true,
        }, this.functionForGetTabWiseUrl(this.state.activeItem, this.state.page))
    };
    closeIcon = () => {
        if (this.state.activeItem === "Group Inbox") {
            this.closeModal()
        } else {
            this.setState({
                showInbox: false,
                loading: false,
            })
        }
    };

    inboxCasePhoto = () => {
        return (<VerifyMakerPhoto closeModal={this.props.closeModal} customerName={this.props.customerName}
                                  subCategory={this.props.subCategory}
                                  category={this.props.category}
                                  caseId={this.props.CaseId}
                                  solId={this.props.solId}
                                  accountNumber={this.props.accountNumber}
                                  cbNumber={this.props.cbNumber} closeIcon={this.props.closeIcon}
                                  serviceType={this.props.serviceType}

                                  subServiceType={this.props.subServiceType}

                                  titleName="Maker Update All Information"
                                  documentList={this.state.documentList}/>)


    };

    renderInboxCase = () => {
        if ((this.state.getData && this.state.showTable)) {
            return (
                /*  <CaseViewPage closeModal={this.closeModal} closeIcon={this.closeIcon} deferralId={this.state.deferralId}
                                appId={this.state.appUid}
                                serviceType={this.state.serviceType} taskTitle={this.state.taskTitle}
                                subServiceType={this.state.subserviceType}/>*/
                <CaseViewPage delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeIcon}
                              appId={this.state.appUid} CaseId={this.state.CaseId}
                              deferralId={this.state.deferralId}
                              subCategory={this.state.subCategory}
                              branch={this.state.branchName}
                              subCategory={this.state.subCategory} category={this.state.category}
                              serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                              freeFlag2={this.state.freeFlag2}
                              freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                              recpmtid={this.state.recpmtid}
                              accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                              customerName={this.state.customer_name}
                              solId={this.state.solId}
                              subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
            )
        }

    }

    renderInboxCaseSubmitted = () => {
        return (
            <SubmittedCaseHistory delStatus={this.state.delStatus} closeModal={this.closeIcon}
                                  closeIcon={this.closeIcon}
                                  appId={this.state.appUid}
                                  subCategory={this.state.subCategory}
                                  CaseId={this.state.CaseId}
                                  subCategory={this.state.subCategory}
                                  category={this.state.category} serviceType={this.state.serviceType}
                                  freeFlag1={this.state.freeFlag1}
                                  freeFlag2={this.state.freeFlag2}
                                  freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                                  recpmtid={this.state.recpmtid}
                                  accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                                  customerName={this.state.customer_name}
                                  solId={this.state.solId}
                                  subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
        )
    };

    renderInboxComponent = () => {
        if (this.state.activeItem === "SUBMIT" || this.state.activeItem === "COMPLETE") {
            // return 0;
        } else {
            return this.renderInboxCase()
        }
    };

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderCloseIcon()}
                </CardHeader>
                <CardBody>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loading} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        fullScreen={true}
                        open={this.state.showInbox}>
                        <DialogContent className={classes.dialogPaper}>
                            {this.renderInboxComponent()}
                        </DialogContent>
                    </Dialog>
                    <ThemeProvider theme={theme}>
                        {this.renderFilterField()}
                        <Grid item xs={12}><br/></Grid>
                        <Grid item xs={12}><br/></Grid>
                        {this.renderMenu()}
                        {this.renderTable()}
                        {this.renderNotification()}
                    </ThemeProvider>
                </CardBody>
            </Card>
        );
    }

}

export default withStyles(styles)(CSDeferalClose);
