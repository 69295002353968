import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import 'semantic-ui-offline/semantic.min.css';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import CloseIcon from "@material-ui/icons/Close";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import NTBCount from "./NTBCount";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let jsonForm = [
    {
        varName: "fromDate",
        type: "date",
        label: "From Date",
        grid: 3,
        required: true
    },
    {
        varName: "toDate",
        type: "date",
        label: "To Date",
        grid: 3,
        required: true
    },
];

let jsonFormDownload = [
    {
        varName: "fromDate",
        type: "date",
        label: "From Date",
        grid: 3,
    },
    {
        varName: "toDate",
        type: "date",
        label: "To Date",
        grid: 3,
    },
    {
        varName: "cardProductType",
        required: true,
        type: "select",
        label: "Card Cheque Type",
        grid: 3,
    },
];


class NTBModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            err: false,
            selectedDate: {},
            errorArray: {},
            errorMessages: {},
            varValue: {},
            inputData: {},
            title: "",
            notificationMessage: "",
            loading: true,
            openNtbModal: false
        };
    }


    componentDidMount() {
        if (this.props.modalType !== "NTB") {
            let inbox_url = backEndServerURL + "/cardCheque/getProductType";
            axios.get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    for (let item of jsonFormDownload) {
                        if (item.varName === "cardProductType") {
                            item.enum = response.data
                        }
                    }
                    this.setState({
                        loading: false
                    })
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
        } else {
            this.setState({
                loading: false
            })
        }
    }

    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    functionForForward = () => {
        let error = false;
        if (this.props.modalType === "NTB") {
            error = MyValidation.defaultValidation(jsonForm, this.state);
        } else {
            error = MyValidation.defaultValidation(jsonFormDownload, this.state);
        }
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            if (this.props.modalType === "NTB") {
                this.setState({
                    openNtbModal: true
                })
            } else {
                this.props.closeModal(this.state.inputData)
            }
        }
    };

    renderJsonForm = () => {
        if (this.props.modalType === "NTB") {
            return CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent)
        } else {
            return CommonJsonFormComponent.renderJsonForm(this.state, jsonFormDownload, this.updateComponent)
        }
    };

    closeNtbCount = () => {
        this.setState((prev) => ({
            openNtbModal: !prev.openNtbModal
        }))
    };

    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >
                                {this.props.modalType === "NTB" ? "NTB Cheque Book Request" : "Download Text"}
                                <a><CloseIcon onClick={() => this.props.closeModal()} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a>
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.openNtbModal}>
                                <DialogContent className={classes.dialogPaper}>
                                    <NTBCount closeModal={this.closeNtbCount} inputData={this.state.inputData}
                                              parentClose={this.props.closeModal}/>
                                </DialogContent>
                            </Dialog>
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {this.renderJsonForm()}
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        onClick={this.functionForForward}
                                        style={{
                                            height: 30,
                                            marginTop: 15,
                                        }}
                                    >
                                        Submit
                                    </button>
                                </ThemeProvider>
                            </Grid>
                        </CardBody>
                    </Card>
                </div>
            );
        }

    }

}

export default withStyles(styles)(NTBModal);
