function makeReadOnlyObject(object) {
    let returnObject = JSON.parse(JSON.stringify(object));
    let returnObjectVariables = returnObject;
    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["readOnly"] = true;
        returnObjectVariables[i]["required"] = false;
    }
    return returnObject;
}

function changeGrid(object) {
    let returnObject = JSON.parse(JSON.stringify(object));
    let returnObjectVariables = returnObject;
    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["grid"] = 6;
    }
    return returnObject;
}


const requisitionField = [
    {
        "varName": "accountNumber",
        "label": "Account Number",
        "type": "text",
        "grid": 2,
        "multiline": true,
        "readOnly": true
    },
    {
        "varName": "customerName",
        "label": "Customer Name",
        "type": "text",
        "grid": 2,
        "multiline": true,
        "readOnly": true
    },
    {
        "varName": "transferType",
        "label": "Transfer Type",
        "type": "text",
        "grid": 2,
        "multiline": true,
        "readOnly": true
    },
    {
        "varName": "transferAmount",
        "label": "Transfer Amount",
        "type": "textApiCall",
        "validation": "compareValue",
        "compareVarName": "accountBalance",
        "compareLabel": "Account Balance",
        "grid": 2,
        "multiline": true,
        "required": true
    },
    {
        "label": "Amount in Word",
        "type": "text",
        "varName": "amountInWord",
        "grid": 2,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "showAmountConvertField",
        "conditionalVarValue": true,
        "readOnly": true,
    },
    {
        "varName": "beneficiaryAccountNumber",
        "label": "Beneficiary Account Number",
        "type": "text",
        "grid": 2,
        "multiline": true,
        "readOnly": true
    },
    {
        "varName": "beneficiaryAccountName",
        "label": "Beneficiary Account Name",
        "type": "text",
        "grid": 2,
        "multiline": true,
        "readOnly": true
    },
    {
        "varName": "beneficiaryBankName",
        "label": "Beneficiary Bank Name",
        "type": "select",
        "enum": [],
        "grid": 2,
        "multiline": true,
        "readOnly": true
    },
    {
        "varName": "beneficiaryBranchName",
        "label": "Beneficiary Branch Name",
        "type": "select",
        "enum": [],
        "grid": 2,
        "multiline": true,
        "readOnly": true
    },
    {
        "varName": "routingNumber",
        "label": "Routing Number",
        "type": "text",
        "grid": 2,
        "multiline": true,
        "readOnly": true
    },
    {
        "varName": "customOfficeCode",
        "label": "Custom Office Code",
        "type": "autoCompleteValueReturn",
        enum: [],
        "grid": 2,
        "required": true
    },
    {
        "varName": "registrationYear",
        "label": "Registration Year",
        "type": "select",
        enum: ["2018","2019","2020","2021","2022"],
        "grid": 2,
        "multiline": true,
        "required": true
    },
    {
        "varName": "registrationNumber",
        "label": "Registration Number",
        "type": "text",
        // "validation": "length",
        // "maxLength": 12,
        // "minLength": 12,
        "grid": 2,
        "multiline": true,
        validation: "numeric",
        "required": true
    },
    {
        "varName": "declarantCode",
        "label": "Declarant Code",
        "type": "text",
        // "validation": "length",
        // "maxLength": 18,
        // "minLength": 18,
        "grid": 2,
        "multiline": true,
        validation: "numeric",
        "required": true
    },
    {
        "varName": "mobileNumber",
        "label": "Mobile Number",
        "type": "text",
        "validation": "length",
        "maxLength": 11,
        "minLength": 11,
        "grid": 2,
        "multiline": true,
        "required": true
    }
];

let jsonForBom = {};
jsonForBom = changeGrid(makeReadOnlyObject(JSON.parse(JSON.stringify(requisitionField))));

let jsonForBm = {};
jsonForBm = makeReadOnlyObject(JSON.parse(JSON.stringify(requisitionField)));


export {
    requisitionField,
    jsonForBom,
    jsonForBm
}