import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import {Menu} from 'semantic-ui-react';
import 'semantic-ui-offline/semantic.min.css';
import Button from "@material/react-button";
import MaterialTable from "material-table";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import axiosDownload from '../FunctionForFileDownload';
import TablePagination from "@material-ui/core/TablePagination";
import {createMuiTheme} from "@material-ui/core/styles";
import {buttonStyle, localization, options, tabActive, tabInactive, tableIcons} from "../configuration";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import MailNotification from "../../workflow/CommonComponent/MailNotification";
import withStyles from "@material-ui/core/styles/withStyles";
import FailedListModal from "../WelcomeLetter/FailedListModal";
import loader from "../../../Static/loader.gif";


let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '5px',
            }
        },

    }
});

let SearchForm = [
    {
        varName: "fromDate",
        type: "date",
        label: "From Date",
        grid: 2,
    },
    {
        varName: "toDate",
        type: "date",
        label: "To Date",
        grid: 2,
    },
    {
        varName: "caseId",
        type: "text",
        validation: "numeric",
        label: "Case ID",
        grid: 2,
    },
    {
        varName: "clientId",
        type: "text",
        validation: "numeric",
        label: "Client ID",
        grid: 2,
    },
    {
        varName: "branchName",
        type: "autoCompleteValueReturn",
        label: "Branch Name",
        grid: 2,
    },
    {
        varName: "productType",
        type: "select",
        label: "Product Type",
        grid: 2,
        enum: [
            "City Maxx Instant debit card",
            "CUP Instant debit card",
            "City Gem Instant debit card",
            "City Alo Instant Debit Card",
            "City MaxX Islamic instant debit card",
            "Visa City Alo Islamic Instant Debit Card",
            "Mastercard Islamic Instant Debit Card"
        ]
    },

];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class ManageInstaDebitCard extends Component {
    state = {activeItem: "home", backgroundColor: "red"};

    constructor(props) {
        super(props);
        this.state = {
            currentDate: 0,
            appData: "",
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            getData: true,
            inputData: {},
            fileUploadData: {},
            responseData: [],
            activeItem: "New",
            backgroundColor: "red",
            showValue: false,
            uploadModal: false,
            merchantModal: false,
            caseId: "",
            title: "",
            mailModal: false,
            selectedRowIdArray: [],
            dataPassArray: [],
            buttonName: "Claim",
            notificationMessage: "",
            alert: false,
            showData: true,
            pageNumber: 0,
            totalRow: 0,
            searchItem: "",
            rowsPerPage: 100,
            autoComplete: {},
            selectedCount: 0,
            failedModal: false,
            failedList: [],
            loader: true,
            allData: [],
            allColumns: [
                {title: "Case ID", field: "caseId"},
                {title: "Product Type", field: "productType"},
                {title: "Branch Name", field: "branchName"},
                {title: "Quantity", field: "quantity"},
                {title: "Client Id", field: "clientId"},
                {title: "Card Number", field: "maskedCardNumber"},
                {title: "Status", field: "status"},
                {title: "Batch No", field: "batchNo"},
                {title: "SOL Id", field: "solId"},
                {title: "Tranzware Completed Date", field: "tranzwareCompletedDate"},
                {title: "Dispatch Date", field: "sendToCourierDate"},
                {title: "Courier Name", field: "courierName"},
                {title: "Branch Receiver ID", field: "branchReceiverId"},
                {title: "Receiver Name", field: "receiverName"},
                {title: "Receiver Date & Time", field: "receiverDateAndTime"},
                {title: "Deliver User ID", field: "deliverUserId"},
                {title: "Name", field: "deliverUserName"},
                {title: "Date & Time", field: "deliverDateAndTime"},
                {title: "Date", field: "date"}
            ],
            columns: [
                {title: "Case ID", field: "caseId"},
                {title: "Product Type", field: "productType"},
                {title: "Branch Name", field: "branchName"},
                {title: "Quantity", field: "quantity"},
                {title: "Client Id", field: "clientId"},
                {title: "Card Number", field: "maskedCardNumber"},
                {title: "Status", field: "status"},
                {title: "Batch No", field: "batchNo"},
                {title: "SOL Id", field: "solId"},
                {title: "Date", field: "date"}
            ],
            newcolumns: [
                {title: "Case ID", field: "caseId"},
                {title: "Product Type", field: "productType"},
                {title: "Branch Name", field: "branchName"},
                {title: "Quantity", field: "quantity"},
                {title: "Status", field: "status"},
                {title: "SOL Id", field: "solId"},
                {title: "Date", field: "date"}
            ],

        };
    }

    handleItemClick = (e, {name, style}) =>
        this.setState({
            activeItem: name,
            backgroundColor: style
        });

    headerSelect = (event, value) => {
        event.preventDefault();

        this.setState({
            loader: true,
            pageNumber: 0
        });

        if (value === "All") {
            this.fetchDataFromAPI('All', 'getInstaDebitCardAll/page/0')
        } else if (value === "New" || value === "XML Downloaded" || value === "Cancel") {
            this.fetchDataFromAPI(value, 'getInstaDebitCardNewStatus')
        } else if (value === "Failed") {
            this.fetchDataFromAPI('Failed', 'getInstaDebitCardNewStatus')
        } else {
            this.fetchDataFromAPI(value)
        }
    };

    fetchDataFromAPI = (status, url) => {
        if (this.state.searchItem !== "") {
            this.searchData(this, status)
        } else {
            this.setState({
                activeItem: status,
                loader: true
            });

            let urlSegment;
            let inbox_url;
            if (url === undefined) {
                urlSegment = 'getinstadebitcardstatus';
            } else {
                urlSegment = url;
            }

            if (status === 'All') {
                inbox_url = backEndServerURL + "/" + urlSegment;
            } else {
                inbox_url = backEndServerURL + "/" + urlSegment + "/" + status + "/0";
            }
            axios
                .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    console.log(response);
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        rowData = {
                            id: response.data.content[i].id,
                            caseId: response.data.content[i].appId,
                            productType: response.data.content[i].productType,
                            cardType: response.data.content[i].cardType,
                            clientId: response.data.content[i].clientId,
                            maskedCardNumber: response.data.content[i].maskedCardNumber,
                            branchName: response.data.content[i].branchName,
                            userName: response.data.content[i].customerName,
                            status: response.data.content[i].status,
                            remarks: response.data.content[i].remarks,
                            date: this.dateConverter(response.data.content[i].branchInitiatingDate),
                            batchNo: response.data.content[i].batchNo,
                            solId: response.data.content[i].solId,
                            quantity: response.data.content[i].quantity,
                            tranzwareCompletedDate: response.data.content[i].tranzwareCompletedDate,
                            sendToCourierDate: response.data.content[i].sendToCourierDate,
                            courierName: response.data.content[i].courierName,
                            branchReceiverId: response.data.content[i].branchReciverId,
                            receiverName: response.data.content[i].branchReciverName,
                            receiverDateAndTime: response.data.content[i].branchReciverDate,
                            deliverUserId: response.data.content[i].branchDeleverdId,
                            deliverUserName: response.data.content[i].branchDeleverdName,
                            deliverDateAndTime: response.data.content[i].branchDeleverdDate,
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData);
                    }

                    this.setState({
                        allData: array,
                        responseData: responseArray,
                        rowsPerPage: response.data.numberOfElements,
                        pageNumber: response.data.number,
                        totalRow: response.data.totalElements,
                        loader: false
                    });
                    console.log(this.state.allData);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    handleChangePage = (pageNumber, status, bool) => {
        let page = 0;
        if (bool !== undefined) {
            page = pageNumber;
        } else {
            page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        }
        if (this.state.searchItem !== "") {
            this.searchData(this, status, page);
        } else {
            let inbox_url;
            if (this.state.activeItem === status) {
                this.setState({
                    loader: true
                });
                if (status === 'All') {
                    inbox_url = backEndServerURL + "/getInstaDebitCardAll/page/" + page;
                } else if (status === 'New' || status === "XML Downloaded" || status === "Cancel") {
                    inbox_url = backEndServerURL + "/getInstaDebitCardNewStatus/" + status + "/" + page;
                } else {
                    inbox_url = backEndServerURL + "/getinstadebitcardstatus/" + status + "/" + page;
                }
                let array = [];
                let responseArray = [];
                axios
                    .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => {
                        let rowData;
                        let responseRowData;
                        for (let i = 0; i < response.data.content.length; i++) {
                            responseRowData = response.data.content[i].appId;
                            rowData = {
                                id: response.data.content[i].id,
                                caseId: response.data.content[i].appId,
                                productType: response.data.content[i].productType,
                                cardType: response.data.content[i].cardType,
                                clientId: response.data.content[i].clientId,
                                maskedCardNumber: response.data.content[i].maskedCardNumber,
                                branchName: response.data.content[i].branchName,
                                userName: response.data.content[i].customerName,
                                status: response.data.content[i].status,
                                remarks: response.data.content[i].remarks,
                                date: this.dateConverter(response.data.content[i].branchInitiatingDate),
                                batchNo: response.data.content[i].batchNo,
                                solId: response.data.content[i].solId,
                                quantity: response.data.content[i].quantity,
                                tranzwareCompletedDate: response.data.content[i].tranzwareCompletedDate,
                                sendToCourierDate: response.data.content[i].sendToCourierDate,
                                courierName: response.data.content[i].courierName,
                                branchReceiverId: response.data.content[i].branchReciverId,
                                receiverName: response.data.content[i].branchReciverName,
                                receiverDateAndTime: response.data.content[i].branchReciverDate,
                                deliverUserId: response.data.content[i].branchDeleverdId,
                                deliverUserName: response.data.content[i].branchDeleverdName,
                                deliverDateAndTime: response.data.content[i].branchDeleverdDate,
                            };
                            array.push(rowData);
                            responseArray.push(responseRowData);
                        }

                        this.setState({
                            allData: array,
                            responseData: responseArray,
                            activeItem: this.state.activeItem,
                            rowsPerPage: response.data.numberOfElements,
                            pageNumber: response.data.number,
                            totalRow: response.data.totalElements,
                            loader: false
                        });
                        console.log(this.state.totalRow, this.state.pageNumber, this.state.rowsPerPage);
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            allData: array,
                            loader: false
                        })
                    });
            }
        }
    };

    dateConverter = (value) => {
        return value.substr(0, 10);
    };

    componentDidMount() {

        let branch = [];
        let branchUrl = backEndServerURL + "/workplaceUnit/get/BRANCH";
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((item) => {
                    branch.push({key: item.solId, value: item.unitName})
                });
                for (let item of SearchForm) {
                    if (item.varName === "branchName") {
                        item.enum = branch
                    }
                }
                this.fetchDataFromAPI("New", 'getInstaDebitCardNewStatus');
                let defaultDate = new Date();
                let isoDate = defaultDate.toISOString();
                let currentDate = isoDate.substr(0, 10);

                this.setState({
                    currentDate: currentDate
                });
            })
            .catch((error) => {
                console.log(error)
            });

        //inbox call
        // let inbox_url = backEndServerURL + "/getInstaDebitCardAll/page/0";
        // axios
        //     .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
        //     .then(response => {
        //         console.log(response.data);
        //         let array = [];
        //         let responseArray = [];
        //         let rowData;
        //         let responseRowData;
        //         for (let i = 0; i < response.data.content.length; i++) {
        //             responseRowData = response.data.content[i].appId;
        //             rowData = {
        //                 id: response.data.content[i].id,
        //                 caseId: response.data.content[i].appId,
        //                 productType: response.data.content[i].productType,
        //                 cardType: response.data.content[i].cardType,
        //                 clientId: response.data.content[i].clientId,
        //                 maskedCardNumber: response.data.content[i].maskedCardNumber,
        //                 branchName: response.data.content[i].branchName,
        //                 userName: response.data.content[i].customerName,
        //                 status: response.data.content[i].status,
        //                 remarks: response.data.content[i].remarks,
        //                 date: this.dateConverter(response.data.content[i].branchInitiatingDate),
        //                 batchNo: response.data.content[i].batchNo,
        //                 solId: response.data.content[i].solId,
        //                 quantity: response.data.content[i].quantity,
        //                 tranzwareCompletedDate: response.data.content[i].tranzwareCompletedDate,
        //                 sendToCourierDate: response.data.content[i].sendToCourierDate,
        //                 courierName: response.data.content[i].courierName,
        //                 branchReceiverId: response.data.content[i].branchReciverId,
        //                 receiverName: response.data.content[i].branchReciverName,
        //                 receiverDateAndTime: response.data.content[i].branchReciverDate,
        //                 deliverUserId: response.data.content[i].branchDeleverdId,
        //                 deliverUserName: response.data.content[i].branchDeleverdName,
        //                 deliverDateAndTime: response.data.content[i].branchDeleverdDate,
        //             };
        //             array.push(rowData);
        //             responseArray.push(responseRowData);
        //         }
        //
        //         this.setState({
        //             allData: array,
        //             responseData: responseArray,
        //             rowsPerPage: response.data.numberOfElements,
        //             pageNumber: response.data.number,
        //             totalRow: response.data.totalElements,
        //             loader: false
        //         });
        //         console.log(this.state.allData);
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     });

    }

    updateComponent = () => {
        this.forceUpdate();
    };

    open = data => {
        this.setState({
            dataPassArray: data,
            merchantModal: true
        });
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === undefined)
                delete clone[prop];
        return clone;
    };

    searchData = (e, status, page) => {

        let postData = this.removeNullValue(this.state.inputData);

        if (Object.keys(postData).length === 0) {
            return 0;
        }

        let inbox_url;
        if (page === undefined) {
            this.setState({
                loader: true,
                activeItem: status,
            });
            inbox_url = backEndServerURL + "/filterInstaDebitCard/" + status + "/0";
        } else {
            this.setState({
                loader: true,
                activeItem: status,
            });
            inbox_url = backEndServerURL + "/filterInstaDebitCard/" + status + "/" + page;
        }


        axios
            .post(inbox_url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {

                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].appId;
                    rowData = {
                        id: response.data.content[i].id,
                        caseId: response.data.content[i].appId,
                        productType: response.data.content[i].productType,
                        cardType: response.data.content[i].cardType,
                        clientId: response.data.content[i].clientId,
                        maskedCardNumber: response.data.content[i].maskedCardNumber,
                        branchName: response.data.content[i].branchName,
                        userName: response.data.content[i].customerName,
                        status: response.data.content[i].status,
                        remarks: response.data.content[i].remarks,
                        date: this.dateConverter(response.data.content[i].branchInitiatingDate),
                        batchNo: response.data.content[i].batchNo,
                        solId: response.data.content[i].solId,
                        quantity: response.data.content[i].quantity,
                        tranzwareCompletedDate: response.data.content[i].tranzwareCompletedDate,
                        sendToCourierDate: response.data.content[i].sendToCourierDate,
                        courierName: response.data.content[i].courierName,
                        branchReceiverId: response.data.content[i].branchReciverId,
                        receiverName: response.data.content[i].branchReciverName,
                        receiverDateAndTime: response.data.content[i].branchReciverDate,
                        deliverUserId: response.data.content[i].branchDeleverdId,
                        deliverUserName: response.data.content[i].branchDeleverdName,
                        deliverDateAndTime: response.data.content[i].branchDeleverdDate,
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    inputData: {...postData},
                    varValue: {...postData},
                    showValue: true,
                    loader: false,
                    loading: false,
                    rowsPerPage: response.data.numberOfElements,
                    pageNumber: response.data.number,
                    totalRow: response.data.totalElements,
                    searchItem: status,
                });
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
            });
    };

    mailNotification = () => {
        const {classes} = this.props;
        return (
            <Dialog
                fullWidth="true"
                maxWidth="md"
                className={classes.modal}
                classes={{paper: classes.dialogPaper}}
                open={this.state.mailModal}>
                <DialogContent className={classes.dialogPaper}>
                    <MailNotification closeModal={this.closeMailModal} processName="INSTA DEBIT CARD"/>
                </DialogContent>
            </Dialog>
        )
    };

    closeMailModal = (value) => {
        if (value === "cancel") {
            this.setState({
                mailModal: false
            })
        } else {
            this.setState({
                mailModal: false
            });
            let postData = value;
            postData.idList = this.state.selectedRowIdArray;
            let mailUrl = backEndServerURL + "/instaDebitCardSendMail/Tranzware Completed";
            axios
                .post(mailUrl, postData, {
                    headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                })
                .then(response => {
                    this.fetchDataFromAPI('Tranzware Completed')
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    closeFailedModal = () => {
        this.setState({
            failedModal: false
        })
    };

    resetHandler = () => {
        window.location.reload();
    };

    render() {
        const {classes} = this.props;
        const {activeItem} = this.state;
        return (
            <div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >
                            Search Debit Card
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <Grid container>
                            <ThemeProvider theme={theme}>
                                {CommonJsonFormComponent.renderJsonForm(
                                    this.state,
                                    SearchForm,
                                    this.updateComponent
                                )}
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        height: 30,
                                        marginTop: 15,
                                    }}
                                    onClick={e => {
                                        this.searchData(e, this.state.activeItem);
                                    }}
                                >
                                    Search
                                </button>
                                &nbsp;
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        height: 30,
                                        marginTop: 15,
                                    }}
                                    onClick={this.resetHandler}
                                >
                                    Reset
                                </button>
                            </ThemeProvider>
                        </Grid>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >Deliverable Management (Insta Debit Card)</h4>
                    </CardHeader>
                    <CardBody>
                        {
                            this.mailNotification()
                        }
                        <Dialog
                            fullWidth="true"
                            maxWidth="md"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.failedModal}>
                            <DialogContent className={classes.dialogPaper}>
                                <FailedListModal titleHeader="Failed List"
                                                 closeModal={this.closeFailedModal}
                                                 failedList={this.state.failedList}/>
                            </DialogContent>
                        </Dialog>
                        <div className="deliverable">
                            <React.Fragment>
                                <div>
                                    <Menu pointing>
                                        <Menu.Item
                                            name="All"
                                            active={activeItem === "All"}
                                            style={
                                                activeItem === "All"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "All");
                                            }}
                                        />

                                        <Menu.Item
                                            name="New"
                                            active={activeItem === "New"}
                                            style={
                                                activeItem === "New"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "New");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Failed"
                                            active={activeItem === "Failed"}
                                            style={
                                                activeItem === "Failed"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Failed");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Cancel"
                                            active={activeItem === "Cancel"}
                                            style={
                                                activeItem === "Cancel"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Cancel");
                                            }}
                                        />
                                        <Menu.Item
                                            name="XML Downloaded"
                                            active={activeItem === "XML Downloaded"}
                                            style={
                                                activeItem === "XML Downloaded"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "XML Downloaded");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Tranzware Completed"
                                            active={activeItem === "Tranzware Completed"}
                                            style={
                                                activeItem === "Tranzware Completed"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Tranzware Completed");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Send to Courier"
                                            active={activeItem === "Send to Courier"}
                                            style={
                                                activeItem === "Send to Courier"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Send to Courier");
                                            }}
                                        />
                                        {/*<Menu.Item*/}
                                        {/*    name="Returned"*/}
                                        {/*    active={activeItem === "Returned"}*/}
                                        {/*    style={*/}
                                        {/*        activeItem === "Returned"*/}
                                        {/*            ? tabActive*/}
                                        {/*            : tabInactive*/}
                                        {/*    }*/}
                                        {/*    onClick={e => {*/}
                                        {/*        this.headerSelect(e, "Returned");*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                        <Menu.Item
                                            name="Deliver to Branch"
                                            active={activeItem === "Deliver to Branch"}
                                            style={
                                                activeItem === "Deliver to Branch"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Deliver to Branch");
                                            }}
                                        />

                                        <Menu.Item
                                            name="Branch Received"
                                            active={activeItem === "Branch Received"}
                                            style={
                                                activeItem === "Branch Received"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Branch Received");
                                            }}
                                        />
                                    </Menu>

                                    {/*<Segment>*/}
                                    {this.tableChoice()}
                                    {/*</Segment>*/}
                                </div>
                            </React.Fragment>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }

    actionForNewToProcced = (event, rowData, props) => {
        if (this.state.activeItem === "New") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];
            let debit_card_name = '';

            if (allSelectValue != null) {
                this.setState({
                    loader: true
                });
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                    debit_card_name = (debit_card_name === '' ? debit_card_name : debit_card_name + '_') + allSelectValue[i].productType
                }

                let downloadXML = backEndServerURL + "/getDebitCardXMLFile";
                axiosDownload.downloadFile(downloadXML, 'POST', rowIdArray, `${debit_card_name}_${this.state.currentDate}.xml`)
                    .then(response => {
                        //             let single_url =
                        //     backEndServerURL + "/instadebitcardapproved/";
                        // axios
                        //     .post(single_url, rowIdArray, {
                        //         headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                        //     })
                        //     .then(response => {
                        this.fetchDataFromAPI('New', 'getInstaDebitCardNewStatus')
                        // })
                        // .catch(error => {
                        //     console.log(error);
                        // });
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            loader: false
                        })
                    });

            }
        }
    };

    actionForNewToCancel = (event, rowData, props) => {
        if (this.state.activeItem === "New") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url = backEndServerURL + "/updateInstantDebitCardRequisitionStatus/Cancel";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.fetchDataFromAPI('New')
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForXMLToProcced = (event, rowData, props) => {
        if (this.state.activeItem === "XML Downloaded") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];

            if (allSelectValue != null) {
                this.setState({
                    loader: true
                });
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url = backEndServerURL + "/instantDebitCardUploadXMLToTranzare";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.data.FailedList.length > 0) {
                            this.setState({
                                loader: false,
                                failedModal: true,
                                failedList: response.data.FailedList
                            })
                        }
                        this.fetchDataFromAPI('XML Downloaded', "getInstaDebitCardNewStatus")
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            loader: false
                        })
                    });
            }
        }
    };

    actionForTranzwareCompletedToProcced = (event, rowData, props) => {
        if (this.state.activeItem === "Tranzware Completed") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            this.setState({
                mailModal: true
            });

            let rowIdArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                this.setState({
                    selectedRowIdArray: rowIdArray,
                });

            }
        }
    };

    actionForTranzwareCompletedToProccedAll = (event, rowData, props) => {
        if (this.state.activeItem === "Tranzware Completed") {
            this.setState({
                mailModal: true,
                selectedRowIdArray: [-1],
            });
        }
    };

    actionForDestroyRequestToDestroy = (event, rowData, props) => {
        if (this.state.activeItem === "Destroy Request") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url =
                    backEndServerURL +
                    "/updateinstadebitcardstatus/Destroy";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.fetchDataFromAPI('Destroy Request')
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForSendToCourierToExcelDownload = (event, rowData, props) => {
        if (this.state.activeItem === "Send to Courier") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];
            if (allSelectValue != null) {
                this.setState({
                    loader: true
                });
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let download_url =
                    backEndServerURL +
                    "/getinstadebitcardExcelResource/Send to Courier";

                axiosDownload.downloadFile(download_url, 'POST', rowIdArray, 'insta-debit-card.xlsx').then((res) => {
                    if (res) {
                        this.setState({
                            loader: false
                        })
                    }
                }).catch((err) => {
                    if (err) {
                        this.setState({
                            loader: false
                        })
                    }
                })

            }
        }
    };


    actionForSendToCourierToSendToBranch = (event, rowData, props) => {
        if (this.state.activeItem === "Send to Courier") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url =
                    backEndServerURL +
                    "/updateinstadebitcardstatus/Deliver to Branch";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.fetchDataFromAPI('Send to Courier')
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForSendToBranchToBranchReceived = (event, rowData, props) => {
        if (this.state.activeItem === "Deliver to Branch") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url =
                    backEndServerURL +
                    "/updateinstadebitcardstatus/Branch Received";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.fetchDataFromAPI('Deliver to Branch')
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };


    renderUrl = () => {
        this.handleChangePage('-5', this.state.activeItem, true)
    };

    downloadReport = (url, name) => {
        this.setState({
            loader: true,
        });
        let download_url = backEndServerURL + "/" + url;
        axiosDownload.downloadFile(download_url, 'POST', this.state.inputData, name).then((result) => {
            this.setState({
                loader: false
            })
        }).catch((error) => {
            this.setState({
                loader: false
            });
        })
    };


    tableChoice() {
        const {classes} = this.props;
        if (this.state.loader) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loader}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else if (this.state.activeItem === "All" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.allColumns}
                                data={this.state.allData}
                                title={" "}
                                // options={{...options, pageSize: this.state.rowsPerPage, selection: false}}
                                options={{...options, pageSize: this.state.rowsPerPage}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'All')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.downloadReport('GetInstaDebitCardTranzwareCompletedReport', 'tranzware-completed-report.xlsx')}
                                            >
                                                Tranzware Completed Report
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.downloadReport('GetInstaDebitCardTranzwareCompletedWithChangeStatusReport', 'branch-received.xlsx')}
                                            >
                                                Full History Report
                                            </Button>
                                            &nbsp;
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "New" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.newcolumns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'New')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForNewToProcced(event, rowData, props)}
                                            >
                                                XML Download
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForNewToCancel(event, rowData, props)}
                                            >
                                                Cancel
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Failed" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.newcolumns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Failed')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                            >
                                                Proceed
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Cancel" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.newcolumns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Cancel')
                                            }
                                            }
                                        />
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "XML Downloaded" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.newcolumns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'XML Downloaded')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForXMLToProcced(event, rowData, props)}
                                            >
                                                Proceed
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Tranzware Completed" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Tranzware Completed')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForTranzwareCompletedToProcced(event, rowData, props)}
                                            >
                                                Proceed
                                            </Button>
                                            {/*&nbsp;*/}
                                            {/*<Button*/}
                                            {/*    style={buttonStyle}*/}
                                            {/*    onClick={(event, rowData) => this.actionForTranzwareCompletedToProccedAll(event, rowData, props)}*/}
                                            {/*>*/}
                                            {/*    Proceed All*/}
                                            {/*</Button>*/}
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Destroy Request" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Destroy Request')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForDestroyRequestToDestroy(event, rowData, props)}
                                            >
                                                Destroy
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Send to Courier" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Send to Courier')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForSendToCourierToSendToBranch(event, rowData, props)}
                                            >
                                                Send to Branch
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForSendToCourierToExcelDownload(event, rowData, props)}
                                            >
                                                Download Excel
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Deliver to Branch" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Deliver to Branch')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForSendToBranchToBranchReceived(event, rowData, props)}
                                            >
                                                Branch Received
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Branch Received" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Branch Received')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        }
    }
}

export default withStyles(styles)(ManageInstaDebitCard);
