import React, {Component} from 'react';
import SortableTree, {toggleExpandedForAll} from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import {Dialog, Grow} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../Components/Grid/GridItem.jsx";
import GridContainer from "../../Components/Grid/GridContainer.jsx";
import Card from "../../Components/Card/Card.jsx";
import CardHeader from "../../Components/Card/CardHeader.jsx";
import CardBody from "../../Components/Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import {backEndServerURL} from '../../Common/Constant';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Functions from '../../Common/Functions';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    fab: {
        backgroundColor: '#ff3443',
        '&:hover': {
            backgroundColor: "#ff3443"
        }
    }
};

function Transition(props) {

    return <Grow in={true} timeout="auto" {...props} />;
}


class SubMenuAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canDrag: false,
            addMenu: false,
            gotSubMenuData: false,
            menuId: "",
            position: "",
            title: "",
            left: "",
            children: [],
            treeData: [],
            selected: false,
            expanded: true,
            redirectLogin: false,
        };

    }

    toggleNodeExpansion = (expanded) => {
        this.setState((prevState) => ({
            treeData: toggleExpandedForAll({
                treeData: prevState.treeData,
                expanded,
            }),
        }));
    };
    handleChange = event => {
        this.setState({[event.target.name]: event.target.value});
        this.setState({selected: true});
    };

    handleSubmit = (event) => {
        event.preventDefault();

        let url = backEndServerURL + "/menu/add";

        axios.post(url, {
            id: this.state.menuId,
            name: this.state.title,
            position: this.state.position
        }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                console.log("submited")
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
            })

    };

    componentDidMount() {

        let url = backEndServerURL + "/menu/reactSortableMenu";

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    treeData: [response.data],
                    gotSubMenuData: true,

                });
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
            });
    }

    addMenu = () => {
        this.setState({
            addMenu: true,

        })

    };

    closeModal = () => {
        this.setState({
            addMenu: false,
        });
    };

    renderTreeData = () => {
        if (this.state.gotSubMenuData) {
            return (
                <SortableTree
                    treeData={this.state.treeData}
                    canDrag={this.state.canDrag}
                    expanded={this.state.expanded}
                    generateNodeProps={menuInfo => ({
                        buttons: [
                            <button
                                className="btn btn-outline-success"
                                style={{
                                    verticalAlign: 'middle',
                                }}

                                onClick={() => {

                                    const {node} = menuInfo;
                                    console.log(node);
                                    this.setState({
                                        menuId: node['menuId'],
                                        addMenu: true
                                    })
                                }
                                }
                            >
                                ℹ
                            </button>,
                        ],
                    })}
                />
            );
        }
    };

    render() {

        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (
            <section>
                <Dialog
                    open={this.state.addMenu}
                    fullWidth="true"
                    maxWidth="sm"
                    onClose={this.closeModal}
                    TransitionComponent={Transition}
                >
                    <div style={{'max-height': 'calc(100vh )'}}>

                        <GridContainer>


                            <GridItem xs={6} sm={12} md={12}>
                                <Card>
                                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                        <h4 className={classes.cardTitleWhite}>Add Sub Menu</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <div className={classes.root}>

                                            <form className={classes.form}>
                                                <Grid container spacing={24}>
                                                    <Grid item xs={12} sm={3}>

                                                        <FormControl margin="normal" fullWidth>
                                                            <InputLabel htmlFor="title">Menu Name</InputLabel>
                                                            <Input onChange={this.handleChange} id="title" name="title"
                                                                   autoComplete="title" autoFocus/>
                                                        </FormControl>
                                                        <FormControl margin="normal">
                                                            Position
                                                            <select onChange={this.handleChange} name="position"
                                                                    id="position">
                                                                <option>Please Select</option>
                                                                <option value="RIGHT" id="position" name="RIGHT">RIGHT
                                                                </option>
                                                                <option value="CHILD" id="position" name="CHILD">CHILD
                                                                </option>
                                                            </select>
                                                        </FormControl>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                    </Grid>
                                                </Grid>
                                                <center>
                                                    <Button
                                                        type="primary" className={classes.Buttoncolorchange}
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={this.handleSubmit}
                                                    >
                                                        Create
                                                    </Button>
                                                </center>
                                            </form>
                                        </div>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </div>
                </Dialog>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>Menu</h4>
                            </CardHeader>
                            <CardBody>
                                <div style={{height: 1000}}>
                                    <div className="wrapper">
                                        <div className="bar-wrapper">
                                            <button onClick={this.toggleNodeExpansion.bind(this, true)}>
                                                Expand all
                                            </button>
                                        </div>
                                    </div>
                                    {this.renderTreeData()}
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </section>
        );
    }
}

export default withStyles(styles)(SubMenuAdd);