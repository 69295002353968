import React, {Component} from "react";
import Grid from "@material-ui/core/Grid/index";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import axios from "axios/index";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import Notification from "../../../NotificationMessage/Notification";
import Table from "../../../Table/Table";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import MyValidation from "../../../JsonForm/MyValidation";
import {InterestTransferReadOnlyForm} from "./InterestTransferJsonForm"
import CommonApi from "../../Maintenance/common/CommonApi";
import CloseIcon from "@material-ui/icons/Close";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";
import Functions from "../../../../Common/Functions";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";
import CBNotFound from "../../CASA/CBNotFound";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'
    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },
};

let bomRemarks = [
    {
        "varName": "bomRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12,
    }];

class BomApproval extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: false,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            appId: 0,
            getMappingAllImage: false,
            fileUploadData: {},
            getImageLink: [],
            getImageBoolean: false,
            imageName: [],
            getData: false,
            getRemarks: [],
            loading: true,
            confirmAlert: false,
            actionType: "",
        };
    }

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    componentDidMount() {
        if (this.props.appId !== undefined) {
            CommonApi.getVariablesNew(this.props.appId, assetOpsBackEndServerURL).then((response) => {
                let inputData = {...response.data};
                inputData.bomRemarks = undefined;
                CommonApi.getFilesNew(this.props.appId, assetOpsBackEndServerURL).then((fileResponse) => {
                    CommonApi.getRemarkDetailsNew(this.props.appId, assetOpsBackEndServerURL).then((remarksArray) => {
                        this.setState({
                            getRemarks: remarksArray,
                            getImageLink: fileResponse,
                            getImageBoolean: true,
                            getData: true,
                            showValue: true,
                            varValue: this.removeNullValue(inputData),
                            inputData: this.removeNullValue(inputData),
                            loading: false
                        })
                    }).catch((error) => {
                        console.log(error);
                        this.setState({
                            cbNotFound: true,
                            title: error.response.data.message,
                            loading: false
                        })
                    });
                }).catch((error) => {
                    console.log(error);
                    this.setState({
                        cbNotFound: true,
                        title: error.response.data.message,
                        loading: false
                    })
                });
            }).catch((error) => {
                console.log(error);
                this.setState({
                    cbNotFound: true,
                    title: error.response.data.message,
                    loading: false
                })
            });
        }
    }

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type="success"
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        });
    };

    approveHandler = () => {
        this.setState({
            loading: true
        })
        let saveDataDeferralRemarksAndRoute = {};
        if (this.state.inputData.bomRemarks !== undefined && this.state.inputData.bomRemarks !== null) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.bomRemarks;
        }
        let postData = {};
        postData.bm_approved = "YES";
        if (this.state.inputData.interestRequestType === "Interest transfer request") {
            postData.request_type = "interest_transfer_request";
        } else if (this.state.inputData.interestRequestType === "Security replacement") {
            postData.request_type = "security_replacement";
        } else if (this.state.inputData.interestRequestType === "Partial Security release") {
            postData.request_type = "partial_security_release";
        } else if (this.state.inputData.interestRequestType === "Interest rate Changes") {
            postData.request_type = "interest_rate_change";
        } else if (this.state.inputData.interestRequestType === "Link account Changes") {
            postData.request_type = "link_account_change";
        }else if (this.state.inputData.interestRequestType === "EFTN Account Change") {
            postData.request_type = "eftn_account_change";
        } else if (this.state.inputData.interestRequestType === "Duplicate NOC") {
            postData.request_type = "duplicate_noc";
        } else if (this.state.inputData.interestRequestType === "Duplicate Sanctioned Letter") {
            postData.request_type = "duplicate_sanctioned_letter";
        }
        saveDataDeferralRemarksAndRoute.map = postData;
        let caseRouteUrl = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios
            .post(caseRouteUrl, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                this.setState({
                    alert: true,
                    loading: false,
                    title: "Successful!",
                    notificationMessage: response.data,
                });
                this.props.closeModal()
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false,
                })
            });
    };
    returnHandler = () => {
        this.setState({
            loading: true,
        })
        let saveDataDeferralRemarksAndRoute = {};
        if (this.state.inputData.bomRemarks !== undefined && this.state.inputData.bomRemarks !== null) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.bomRemarks;
        }
        saveDataDeferralRemarksAndRoute.map = {bm_approved: "NO"}
        let caseRouteUrl = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios
            .post(caseRouteUrl, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                this.setState({
                    loading: false,
                    alert: true,
                    title: "Successful!",
                    notificationMessage: response.data,
                });
                this.props.closeModal()
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false,
                })
            });
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, bomRemarks, this.updateComponent)
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{width: "100%"}}>
                    <br/>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <a
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: "middle",
                                display: "block",
                                marginTop: 20,
                            }}
                            target="_blank"
                            href={assetOpsBackEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                        >
                            {splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </a>
                        &nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };
    closeConfirmAlert = (data, appId) => {
        this.setState({
            confirmAlert: false,
        });
        if (data === "ok" && this.state.actionType === "approveHandler") {
            this.approveHandler()
        } else if (data === "ok" && this.state.actionType === "returnHandler") {
            this.returnHandler()
        }
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        return clone;
    };
    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    validationForHandleConfirm = (actionType) => {
        let error = MyValidation.defaultValidation(bomRemarks, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }
        this.setState({
            appId: this.state.appId,
            confirmAlert: false,
        });
        if (actionType === "approveHandler") {
            this.setState({
                actionType: actionType,
                confirmAlert: true,
                title: "Do You want to Confirm?"
            })
        } else if (actionType === "returnHandler") {
            let dependencyField = [];
            dependencyField.push({
                "varName": "bomRemarks",
                "type": "textArea",
                "required": true,
                "label": "Remarks",
                "grid": 12
            })
            let error = MyValidation.defaultValidation(dependencyField, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,
                title: "Do You want to Confirm?"

            })
        }
    };
    handleCommon = (event, type) => {
        event.preventDefault();
        {
            this.validationForHandleConfirm(type)
        }
    };
    renderSubmitButton = () => {
        if (this.state.getData && this.state.loading === false) {
            return (
                <React.Fragment>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            marginRight: "10px",
                        }}
                        onClick={(event) => this.handleCommon(event, "approveHandler")}
                    >
                        Submit
                    </button>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={(event) => this.handleCommon(event, "returnHandler")}

                    >
                        Return
                    </button>
                </React.Fragment>
            )
        }
    }
    renderForm = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, InterestTransferReadOnlyForm, this.updateComponent)

            )
        }
    }
    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b>Other Service Request</b>
                    <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> CB Number:</b>{this.state.inputData.cbNumber}
                        <b> SOLID:</b>{this.props.solId} <b> Case
                            ID:</b> {this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                            onClick={this.props.closeModal}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Other Service Request{this.props.appId !== undefined ?
                    <a><CloseIcon onClick={this.props.closeModal} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a> : ""}</h4>
            )
        }
    };
    renderFlowSUmmeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                   caseId={this.props.caseId}
                                   customerName={this.state.inputData.customerName}
                                   accountNumber={this.state.inputData.accountNumber}
                                   serviceType={this.state.inputData.serviceType}
                                   category={this.state.inputData.category}
                                   subCategory={this.state.inputData.subCategory}
                                   solId={this.props.solId}/>
            )
        }
    };

    render() {
        const {classes} = this.props;
        {
            Functions.redirectToLogin(this.state)
        }
        return (
            <div>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.confirmAlert}>
                    <DialogContent className={classes.dialogPaper}>
                        <ConfirmationModal
                            closeModal={this.closeConfirmAlert}
                            title={this.state.title}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.cbNotFound}>
                    <DialogContent className={classes.dialogPaper}>
                        <CBNotFound
                            closeModal={this.closeModalCBNotFound}
                            title={this.state.title}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                <ThemeProvider theme={theme}>
                    <Grid container spacing={1}>
                        {this.renderNotification()}
                        {this.renderForm()}
                        {this.mappingPhoto()}
                        {this.state.getImageBoolean && <Grid item xs={12}></Grid>}
                    </Grid>
                    {this.renderRemarksData()}
                    <Grid item xs={12}><br/></Grid>
                    {this.renderFlowSUmmeryButton()}
                    {this.renderRemarks()}
                    <Grid item xs={12}><br/></Grid>
                    {this.renderSubmitButton()}
                </ThemeProvider>
            </div>
        )
    }
}

export default withStyles(styles)(BomApproval);
