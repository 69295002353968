import React, {Component} from "react";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import CloseIcon from "@material-ui/icons/Close";
import CBNotFound from "../../workflow/CASA/CBNotFound";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Notification from "../../NotificationMessage/Notification";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "../../Table/Table";
import loader from "../../../Static/loader.gif";
import MyValidation from "../../JsonForm/MyValidation";
import SignatureButtonForClientId from "../../workflow/CommonComponent/SignatureButtonForClientId";
import OtherCardDetails from "./OtherCardDetails";

let populateForm = [
    {
        varName: "eqmNumber",
        type: "text",
        label: "EQM Number",
        grid: 2,
        readOnly: true,
        validation: "numeric"
    },
    {
        varName: "clintId",
        type: "textApiCall",
        readOnly: true,
        validation: "numeric",
        label: "Credit Card Client Id",
        grid: 2
    },
    {
        varName: "cardNumber",
        type: "autoCompleteValueReturn",
        label: "Card Number",
        onKeyDown: true,
        readOnly: true,
        grid: 2
    },
    {
        varName: "customerName",
        type: "text",
        label: "Customer Name",
        readOnly: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "showCustName",
        conditionalVarValue: true
    },
    {
        varName: "cardProductName",
        type: "text",
        label: "Card Product Name",
        readOnly: true,
        grid: 2,
        validateReadOnly: true,
        conditional: true,
        conditionalVarName: "showChequeType",
        conditionalVarValue: true
    },
    {
        varName: "cardChequeType",
        type: "text",
        label: "Card Cheque Type",
        readOnly: true,
        validateReadOnly: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "showChequeType",
        conditionalVarValue: true
    },
    {
        varName: "chequeType",
        type: "text",
        label: "Cheque Type",
        validateReadOnly: true,
        readOnly: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "showChequeType",
        conditionalVarValue: true
    },
    {
        varName: "customerAddress",
        type: "text",
        label: "Customer Address",
        conditional: true,
        conditionalVarName: "showCustAddress",
        conditionalVarValue: true,
        readOnly: true,
        grid: 4
    },
];

const jsonForFile = [
    {
        varName: "fileUpload",
        type: "file",
        label: "Upload",
        accept: ".pdf",
        grid: 2,
        errorMessage: "Error"
    }
];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];

class CreditCardChequeBookRequisition extends Component {
    constructor() {
        super();
        this.state = {
            appData: "",
            autoComplete: {},
            fileUploadData: {},
            appId: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            inputData: {},
            showValue: false,
            varValue: {},
            getData: false,
            cbNotFound: false,
            cardModal: false,
            title: "",
            notificationMessage: "",
            alert: false,
            signatureModal: false,
            getRemarks: [],
            getImageLink: [],
            getImageBoolean: false,
            imageName: [],
            loading: true,
        };
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
            signatureModal: false,
        })
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    signatureCard = () => {
        this.setState({
            signatureModal: true
        })
    };
    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };


    componentDidMount() {

        if (this.props.appId !== undefined) {
            let url = backEndServerURL + "/variables/" + this.props.appId;
            axios
                .get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    console.log(response.data);
                    let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                    axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            this.setState({
                                getImageLink: response.data,
                                getImageBoolean: true,
                                imageName: response.data
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    this.setState({
                        varValue: this.copyJson(response.data),
                        inputData: this.copyJson(response.data),
                        autoComplete: {},
                        showValue: true,
                        appId: this.props.appId
                    });
                    let remarksArray = [];
                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            response.data.map((data) => {

                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                            });
                            this.setState({
                                getRemarks: remarksArray,
                                getData: true,
                                showValue: true,
                                loading: false
                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {

        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{width: "100%"}}>
                    <br/>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                    </div>

                </div>

            )
        }

    };

    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
    };

    saveHandler = event => {
        event.preventDefault();
        let uploadUrl = backEndServerURL + "/case/upload";
        let formData = new FormData();
        formData.append("appId", this.state.appId);
        formData.append("file", this.state.fileUploadData["fileUpload"]);
        formData.append("type", "file");

        axios({
            method: "post",
            url: uploadUrl,
            data: formData,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                "content-type": "multipart/form-data"
            }
        })
            .then(response => {
                console.log('file upload successful')
            })
            .catch(error => {
                console.log(error)
            });

        let error = MyValidation.defaultValidation(populateForm, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            let remark_url = backEndServerURL + "/appRemarkSave/" + this.state.inputData.csRemarks + "/" + this.state.appId;
            axios.post(remark_url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log("remark save");

                })
                .catch(error => {
                    console.log(error)
                });
            let postData = this.state.inputData;
            postData.category = "Credit Card";
            postData.subCategory = "Chequebook";
            postData.serviceType = "Credit Card";
            postData.subServiceType = "Chequebook";
            postData.csRemarks = undefined;

            let url = backEndServerURL + "/variables/" + this.state.appId;
            axios
                .post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    let caseRouteUrl =
                        backEndServerURL + "/case/route/" + this.state.appId;
                    axios
                        .get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then(response => {
                            this.setState({
                                title: "Successful!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                getData: false
                            });
                            this.props.closeModal();
                        })
                        .catch(error => {
                            console.log(error);
                        });
                })
                .catch(error => {
                    console.log(error)
                });
        }

    };

    clintFunction(event) {
        event.preventDefault();
        this.setState({
            loading: true
        });
        this.state.inputData.clintId = event.target.value;
        let autoComplete = {};
        let card = [];
        let clintUrl = backEndServerURL + "/intgr/cardList/" + this.state.inputData.clintId;
        axios
            .get(clintUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {

                response.data.map((item) => {
                    card.push({label: item})
                });
                this.state.inputData["clintId"] = this.state.inputData.clintId;
                this.state.varValue["clintId"] = this.state.inputData.clintId;

                autoComplete.cardNumber = card;
                this.setState({
                    autoComplete: autoComplete,
                    showValue: true,
                    loading: false,
                });
                console.log(response.data)

            })
            .catch(error => {
                this.setState({
                    loading: false,
                    cbNotFound: true,
                    title: 'Client Id Not Found'
                });
            });
    }

    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b> Credit Card Cheque Requisition </b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                    <b> Card Number:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                    <b> Case ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                        onClick={this.props.closeModal} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Credit Card Cheque Book Requisition
                </h4>
            )
        }
    };

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                return (
                    <React.Fragment>
                        <a
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: "middle",
                                display: "block",
                                marginTop: 20,
                            }}
                            target="_blank"
                            href={backEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                        >
                            Document Preview
                        </a>
                        &nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };

    handleClose = (event) => {
        event.preventDefault();
        let variableSetUrl = backEndServerURL + "/case/close/" + this.state.appId;
        axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Close!",
                        alert: true
                    });
                    this.props.closeModal();
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };

    cardInformation = () => {
        this.setState(prev => ({
            cardModal: !prev.cardModal
        }))
    };

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <React.Fragment>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            {
                                this.renderHeader()
                            }
                        </CardHeader>
                        <CardBody>
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <ThemeProvider theme={theme}>
                                        {CommonJsonFormComponent.renderJsonForm(
                                            this.state,
                                            populateForm,
                                            this.updateComponent
                                        )}
                                        <Grid item xs={12}></Grid>
                                        {
                                            this.mappingPhoto()
                                        }
                                        <br/>
                                        <Grid item xs={12}></Grid>
                                        {CommonJsonFormComponent.renderJsonForm(
                                            this.state,
                                            jsonForFile,
                                            this.updateComponent
                                        )}
                                    </ThemeProvider>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <ThemeProvider theme={theme}>
                                        {this.state.inputData.clintId !== undefined &&
                                        <SignatureButtonForClientId clientId={this.state.inputData.clintId} classes={this.props}/>}
                                        {(this.state.inputData.clientCardDetailsList !== undefined && this.state.inputData.clientCardDetailsList.length > 0) && <button
                                            className="btn btn-outline-primary btn-sm"
                                            style={{
                                                marginRight: "10px",
                                                marginTop: "10px"
                                            }}
                                            onClick={this.cardInformation}
                                        >
                                            Other Cards details
                                        </button>}
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="md"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.cardModal}>
                                            <DialogContent className={classes.dialogPaper}>

                                                <OtherCardDetails
                                                    clientCardDetailsList={this.state.inputData.clientCardDetailsList}
                                                    closeModal={this.cardInformation}
                                                />
                                            </DialogContent>
                                        </Dialog>
                                        {this.renderRemarksData()}
                                        <Grid item xs={12}></Grid>
                                        {this.renderRemarks()}
                                    </ThemeProvider>
                                </Grid>
                            </Grid>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>

                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>

                            <Grid item xs="3" style={{marginTop: "10px"}}>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        marginRight: "10px",
                                    }}
                                    onClick={this.saveHandler}
                                >
                                    Submit
                                </button>
                                &nbsp;
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={this.handleClose}
                                >
                                    Close
                                </button>
                            </Grid>
                            {
                                this.renderNotification()
                            }
                        </CardBody>
                    </Card>
                </React.Fragment>
            );
        }

    }
}

export default withStyles(styles)(CreditCardChequeBookRequisition);
