import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Functions from '../../../Common/Functions';
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import Notification from "../../NotificationMessage/Notification";
import loading from "../../../Static/loader.gif";
import MyValidation from "../../JsonForm/MyValidation";
import CBNotFound from "../CASA/CBNotFound";

const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

const jsonForm = [
    {
        varName: "clusterId",
        label: "Cluster Id",
        grid: 3,
        type: "text",
        multiline: true,
        readOnly: true
    },
    {
        varName: "userName",
        label: "User Id",
        grid: 3,
        multiline: true,
        type: "textApiCall",
    },
    {
        varName: "branchName",
        label: "Branch Name",
        grid: 3,
        readOnly: true,
        multiline: true,
        type: "text",
        conditional: true,
        conditionalVarName: "showField",
        conditionalVarValue: true
    },
    {
        varName: "status",
        label: "Status",
        grid: 3,
        multiline: true,
        readOnly: true,
        type: "text",
        conditional: true,
        conditionalVarName: "showField",
        conditionalVarValue: true
    },
];

class ClusterTagForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            loading: true,
            cbNotFound: false,
            getData: false,
            varValue: {},
            title: "",
            notificationMessage: "",
            alert: false,
            selectedDate: {},
            showValue: true,
            showForm: false,
            err: false,
            errorArray: {},
            errorMessages: {},
        }
    }

    componentDidMount() {
        if (this.props.clusterName !== undefined) {
            let inputValue = {};
            inputValue.clusterId = this.props.clusterName;
            setTimeout(() => {
                this.setState({
                    inputData: inputValue,
                    varValue: inputValue,
                    getData: true,
                    loading: false
                })
            }, 1000)
        }
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    functionForFetchData = (event, data) => {
        if (data === "userName") {
            this.setState({
                loading: true
            });
            this.state.inputData.showField = false;
            this.forceUpdate();
            let url = backEndServerURL + "/user/get/" + this.state.inputData.userName;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let inputData = {...this.state.inputData};
                    inputData.showField = true;
                    inputData.branchName = response.data.branchName;
                    inputData.status = response.data.status;
                    this.setState({
                        inputData: inputData,
                        varValue: inputData,
                        loading: false,
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                    })
                });
        }
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderCloseIcon = () => {
        return (
            <h4 style={{color: "white"}} >Business Hierarchy<a><CloseIcon onClick={() => this.props.closeModal()} style={{
                position: 'absolute',
                right: 10,
                color: "#000000"
            }}/></a></h4>
        )
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    handleSubmit = () => {
        let error = MyValidation.defaultValidation(jsonForm, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            let clusterId = this.props.clusterId;
            let userId = this.state.inputData.userName;
            let url = backEndServerURL + "/user/clusterHeadTagging/" + clusterId + "/" + userId;
            axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Data Save Successful!",
                        alert: true,
                    });
                    this.props.closeModal();
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        cbNotFound: true,
                        title: error.response.data.message,
                    });
                });
        }
    };

    handleSubmitButton = () => {
        if (this.state.getData) {
            return (
                <ThemeProvider theme={theme}>
                    <Grid item xs={12}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{}}
                            onClick={this.handleSubmit}
                        >
                            Submit
                        </button>
                        &nbsp;&nbsp;
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={() => this.props.closeModal()}
                        >
                            Close
                        </button>
                    </Grid>
                </ThemeProvider>
            )
        }
    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderCloseIcon()}
                </CardHeader>
                <CardBody>
                    <ThemeProvider theme={theme}>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>
                                <center>
                                    <img src={loading} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Grid container>
                            {this.renderNotification()}
                            {this.state.getData === true && CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent, this.functionForFetchData)}
                            <Grid item xs={12}>
                            </Grid>
                            {this.handleSubmitButton()}
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                        </Grid>
                    </ThemeProvider>
                </CardBody>
            </Card>

        );


    }

}

export default withStyles(styles)(ClusterTagForm);
