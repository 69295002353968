import TextField from "@material-ui/core/TextField";
import React from "react";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
import validator from "validator";
import LocalstorageEncrypt from "./LocalstorageEncrypt";
import CommonApi from "../workflow/Maintenance/common/CommonApi";

const styles = {
    root: {
        background: "black"
    },
    input: {
        color: "white"
    }
};

class TextFieldComponent {
    static trimFunction = (emptyString) => {
        let typeOfString = typeof emptyString;
        if (typeOfString === "string") {
            if (emptyString !== null && emptyString !== undefined && emptyString !== "") {
                let string = emptyString.trim();
                return string;
            } else {
                return ""
            }
        } else {
            if (emptyString !== null && emptyString !== undefined && emptyString !== "") {
                return "object"
            } else {
                return ""
            }
        }


    };

    static handleChange(event, field, state, update) {
        // let inputData = state.inputData;
        // inputData[event.target.name] = event.target.value;
        let string = event.target.value;
        if (field.phonePrefix === true) {
            //state.inputData[event.target.name] = "88" + string;
            state.inputData[event.target.name] = string;
        } else {
            /*  if(field.validation==="email"){
                  state.inputData[event.target.name] = string;
              }*/
            if (field.lowerCase === true) {
                state.inputData[event.target.name] = string;
            } else {

                state.inputData[event.target.name] = string.toUpperCase();
                if (field.instatntValidation === true && string !== "" && string !== undefined && string !== null && (
                    !validator.isLength(this.trimFunction(string), {
                        min: 1,
                        max: 45
                    }))) {
                    state.errorArray[event.target.name] = true;
                    state.errorMessages[event.target.name] = "Length Should be Upto 45 digit";
                    //console.log(field.varName);
                    update()
                } else if (field.instatntValidation === true) {

                    state.errorArray[event.target.name] = false;
                    state.errorMessages[event.target.name] = "";
                    //console.log(field.varName);
                    update()
                }

            }
        }
        //update(inputData);
    }

    static returnDefaultValue(field, state) {
        if (state.showValue) {
            let readOnlyData = "";
            if (state.varValue === undefined || state.varValue == null)
                return "";
            readOnlyData = state.varValue[field.varName];
            return readOnlyData;
        }
    }

    static returnPrefixString(field) {
        if (field.phonePrefix === true) {
            return (
                <InputAdornment position="start">88</InputAdornment>

            )
        }

    }

    static asteriksFunction = (field) => {
        if (field.required === true && field.type === "date" && field.asteriks === undefined) {
            return (
                <Grid item>
                    <label className="input-label-common">{field.label}(MM-DD-YYYY) *</label>
                </Grid>
            )
        } else if (field.type === "date") {
            return (
                <Grid item>
                    <label className="input-label-common">{field.label}(MM-DD-YYYY) </label>
                </Grid>
            )
        } else if (field.required === true && field.asteriks === undefined) {
            return (
                <Grid item>
                    <label className="input-label-common">{field.label} *</label>
                </Grid>
            )
        } else {
            return (
                <Grid item>
                    <label className="input-label-common">{field.label}</label>
                </Grid>
            )
        }
    };


    static text(state, update, field, onKeyDownChange) {
        if(field.wordFromAmount === true && field.readOnly!==true &&  state.inputData[field.varName + "WithWordApiFlagFirstTime"]!=true){
           let amount=state.varValue[field.varName]!==undefined && state.varValue[field.varName]!==null ?state.varValue[field.varName]:state.inputData[field.varName];
           if(amount!==undefined && amount!==null){
               if(state.inputData[field.varName+"WithWordApiFlag"]!==undefined && state.inputData[field.varName+"WithWordApiFlag"]===true){
                   state.inputData[field.varName+"WithWordApiFlag"]=  false;
                   update();
               }
               CommonApi.wordFromDecimalNumber(amount)
                   .then((response)=>{
                       let getData=response.data;
                       state.inputData[field.varName + "WithWord"] = response.data;
                       state.varValue[field.varName + "WithWord"] = getData;
                       state.inputData[field.varName + "WithWordApiFlag"] = true;
                       state.inputData[field.varName + "WithWordApiFlagFirstTime"] = true;
                       update();
                   })
                   .catch((error)=>{

                   })

           }

        }
        else if(field.etinBlock === true){

            if(state.inputData[field.varName]!==undefined  && state.inputData[field.varName].trim().length>0){
                state.inputData[field.varName + "open"] = true;
                state.inputData[field.varName + "open"] = true;
            }
            else{
                state.inputData[field.varName + "open"] = false;
                state.inputData[field.varName + "open"] = false;
            }


        }

        let styles = {};
        if (field.readOnly === true && field.highlight === true) {
            const secondStyle = {
                color: '#ffffff',
                background: 'red'
            };
            styles = Object.assign(styles, secondStyle)
        } else if (field.readOnly === true) {
            const firstStyle = {
                color: '#212F3D',
                background: '#f8f9fa'
            };
            styles = Object.assign(styles, firstStyle)
        } else if (field.highlight === true) {
            const secondStyle = {
                color: '#ffffff',
                background: 'red'
            };
            styles = Object.assign(styles, secondStyle)
        }

        return (
            <React.Fragment>
                {
                    this.asteriksFunction(field)
                }
                <Grid item>
                    <TextField

                        helperText={state.errorMessages[field.varName]}
                        error={state.errorArray[field.varName]}
                        variant="outlined"
                        defaultValue={this.returnDefaultValue(field, state)}
                        required={field.readOnly === true ? false : field.required}
                        key={field.varName}
                        name={field.varName}
                        disabled={field.readOnly}

                        //label={field.label}
                        multiline={true}
                        onBlur={(event) => {
                            if(field.wordFromAmount === true){
                                let amount=state.inputData[field.varName]!==undefined && state.inputData[field.varName]!==null ?state.inputData[field.varName]:state.varValue[field.varName];
                                     if(state.inputData[field.varName+"WithWordApiFlag"]!==undefined && state.inputData[field.varName+"WithWordApiFlag"]===true){
                                        state.inputData[field.varName+"WithWordApiFlag"]=  false;
                                        update();
                                    }
                                    CommonApi.wordFromDecimalNumber(amount)
                                        .then((response)=>{
                                            let getData=response.data;
                                            state.inputData[field.varName + "WithWord"] = response.data;
                                            state.varValue[field.varName + "WithWord"] = getData;
                                            state.inputData[field.varName + "WithWordApiFlag"] = true;
                                            update();
                                        })
                                        .catch((error)=>{

                                        })



                            }
                            else if(field.etinBlock === true){
                                if(state.inputData[field.varName]!==undefined  && state.inputData[field.varName].trim().length>0){
                                    state.inputData[field.varName + "open"] = true;
                                }
                                else if(state.inputData[field.varName]!==undefined  && state.inputData[field.varName].trim().length<1){
                                    state.inputData[field.varName + "open"] = false;
                                }
                                update();

                            }

                            if (field.onKeyDown === true) {
                                let data = {};
                                data.varName = field.varName;
                                data.request = field.request;
                                //data.value=option;
                                onKeyDownChange(data);
                            }
                        }}
                        onChange={(event) => this.handleChange(event, field, state, update)}
                        InputProps={{
                            style: styles,
                            startAdornment: this.returnPrefixString(field),


                            /* style: {
                                 color: "red"
                             }*/
                        }}

                        //InputLabelProps={{shrink:true}}
                        fullWidth
                        style={{paddingRight: 20}}
                    />
                </Grid>
            </React.Fragment>

        )


    }

}

export default withStyles(styles)(TextFieldComponent);
