import React, {Component} from "react";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../JsonForm/CustomeTheme2";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import Notification from "../NotificationMessage/Notification";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {backEndServerURL} from "../../Common/Constant";
import MyValidation from "../JsonForm/MyValidation";
import axios from "axios";
import Functions from "../../Common/Functions";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";
import SelectComponent from "../JsonForm/SelectComponent";
import TextFieldComponent from "../JsonForm/TextFieldComponent";
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";
import ReferenceModal from "../DeliverableManagement/ReferenceModal";
let Remarks = [
    {
        "varName": "remarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];
var stampType =[
    {
        "varName": "stampType",
        "type": "select",
        "enum":[],
        "required":true,
        "label": "Stamp Type",

    }];
var jsonForm =[
    {
        varName: "receiver",
        type: "select",
        enum: [],
        label: "Receiver",
        required: true,
        grid: 2,
      },

    // {
    //     "varName": "reciver",
    //     "type": "select",
    //     "enum":[
    //         "COURIER",
    //         "PION"
    //     ],
    //     "required":true,
    //     "label": "Delivery  Type",
    //     "grid":6
    // },
    // {
    //     "varName": "name",
    //     "type": "text",
    //     "required":true,
    //     "multiline":true,
    //     "label": "Vendor Name",
    //     "conditional": true,
    //     "conditionalVarName": "deliveryType",
    //     "conditionalVarValue": "COURIER",
    //     "grid":6
    // },
    // {
    //     "varName": "name",
    //     "type": "text",
    //     "required":true,
        
    //     "label": "Vendor Name",
    //     "conditional": true,
    //     "conditionalVarName": "deliveryType",
    //     "conditionalVarValue": "PION",
    //     "grid":6
    // },


];
var quantity =[
    {
        "varName": "quantity",
        "type": "text",
        "label": "Quantity",
        "required":true

    }];
let Form = [
   

];

let workplace = [
    {title: "BRANCH"},
    {title: "OPERATIONS"},
    {title: "CSU"},
];
let groupList = [];
let workplaceTypeList = [];


let urls = backEndServerURL + "/userName/get/all";
axios
  .get(urls, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
    },
  })
  .then((response) => {
    console.log(response.data);
    let userData = [];
    response.data.map((user) => {
      userData.push(user.username);
    });
    Form = [
            
      {
        varName: "receiver",
        type: "select",
        enum: userData,
        label: "Receiver",
        required: true,
        grid: 6,
      },

      
    ];
    this.setState({
      dataSetFlag: true,
      showValue: true,
    });
  })
  .catch((error) => {});
class RequestInitialization extends Component {
    state = {
        inputData: {},
        selectedDate: {},
        getData: false,
        workstation: false,
        role: false,
        showValue: false,
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        values: [],
        err: false,
        varValue: [],
        errorArray: {},
        errorMessages: {},
        errorWorkPlace: false,
        errorgroup: false,
        title: "",
        notificationMessage: "",
        alert: false,
        getGroupListData: false,
        getworkplaceTypeListData: false,
        workplace: "",
        type: "success",
        submitButton: false,
    };
    emptyValueRemove = (data) => {
        if (data === '' || data === ' ' || data === "null" || data === "undefined")
            data = "";
        return data;
    };
    handleSubmit = (event) => {

        event.preventDefault();
        this.setState({
            loading: true
        });
        /*  let error = MyValidation.defaultValidation(RequestInitialization, this.state);
          this.forceUpdate();
          if (error === true) {
              return 0;
          } else {*/
        let validationData=[];
         let error = MyValidation.defaultValidation(this.emptyValueRemove(Form), this.state);
        this.forceUpdate();

        if (error === true  ) {
            console.log("Not working");
            this.setState({
                loading: false
            });
            return 0;
        }
         
         let postData = this.state.inputData;
          postData.availableStock = this.state.inputData.availableStock;
          postData.remarks = this.state.inputData.remarks;
          postData.rowIdArray=this.props.rowIdArray;
        let url = backEndServerURL + "/update/incomingRequisitionReassign/"+this.state.inputData.receiver   ;
        axios
            .post(url, this.props.rowIdArray, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                this.props.closeModal();
                this.setState({
                      title: "Successful!",
                    notificationMessage: "Successfully Updated!",
                    alert: true,
                    loading:false,
                })


            })
            .catch(error => {
                this.setState({
                    loading:false,
                })
             });
        //  }
    };
    renderButton = () => {
// if(this.state.submitButton){
        return (

            <button
                className="btn btn-outline-primary  btn-sm"
                style={{
                    marginTop: "18px"
                }}
                onClick={this.handleSubmit}

            >
                Submit
            </button>


        )
        // }

    };

    getMasterdataAll=()=>{
        let url = backEndServerURL + "/get/insuranceMasterDataList";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let stampList=[];
                response.data.map((data)=>{
                    stampList.push(data.stampDescription)
                })
                this.setState({
                    stampTypeList:stampList,
                    getData:true
                })
            })
            .catch((error)=>{
                console.log(error);
            })
    }
     componentDidMount() {
        if(this.props.getId!==undefined){
            this.setState({
                submitButton: false,

            });
            this.setState({

                showValue:true,
                getData:true
            })
        }
        else{
            this.setState({
                submitButton: false,


            });
        }





    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderJsonForm = () => {
        return (
            CommonJsonFormComponent.renderJsonForm(this.state, Form, this.updateComponent)
        )

    };



    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.type} stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    close=()=>{
        this.props.closeModal()
     }
    renderHeader = () => {
             return (
                <h4 className="text-white">Re Assign<a><CloseIcon onClick={this.close} style={{
                    position: 'absolute',
                    right: 10,
                    color: "white"
                }}/></a></h4>
            )

    };


     closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
            signatureModal: false,
            reference: false
        })
    };

    jsonForm = () => {
 
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, Form, this.updateComponent)

            )
       

    };
    render() {
        return (

            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderHeader()}

                </CardHeader>
                <CardBody>
                    <div>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            open={this.state.loading}>
                            <DialogContent>

                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>

                        <ThemeProvider theme={theme}>

                            <Grid container>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"

                                    open={this.state.reference}>
                                    <DialogContent  >
                                        <ReferenceModal
                                            closeModal={this.closeModalCBNotFound}
                                            referenceTitle={this.state.referenceTitle}
                                        />
                                    </DialogContent>
                                </Dialog>
                                {this.renderNotification()}


                                {this.jsonForm()}
                                <br/>


                                <Grid item xs={12}>
                                    {this.renderButton()}
                                </Grid>

                            </Grid>

                        </ThemeProvider>


                    </div>


                </CardBody>
            </Card>


        )
    }

}

export default RequestInitialization;