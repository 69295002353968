import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../../JsonForm/CustomeTheme2";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import 'semantic-ui-offline/semantic.min.css';
import withStyles from "@material-ui/core/styles/withStyles";
import Notification from "../../../NotificationMessage/Notification";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../../Static/loader.gif";
import {Dialog} from "@material-ui/core";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class ActionComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            err: false,
            errorArray: {},
            errorMessages: {},
            varValue: {},
            inputData: {},
            title: "",
            notificationMessage: "",
            loading: false,
        };
    }


    componentDidMount() {

    }

    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    handleSubmit = (type, status) => {
        this.setState({
            loading: true
        });
        let postData = {};
        postData.oldStatus = status === 2 ? 1 : 2;
        postData.isActive = status;
        postData.transferType = type;
        let url = backEndServerURL + "/updateBranchTransferTypeStatus";
        axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.props.handleChangePage(0);
                this.setState({
                    loading: false,
                });
                this.props.closeModal();
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    cbNotFound: true,
                    title: "Something Went Wrong!",
                    loading: false,
                })
            });
    };

    render() {
        const {classes} = this.props;
        return (
            <div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >
                            RTGS BEFTN BRANCH UPDATE
                            <a><CloseIcon onClick={() => this.props.closeModal()} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a>
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>
                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Grid container>
                            <ThemeProvider theme={theme}>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={() => this.handleSubmit("RTGS", 2)}
                                    style={{
                                        height: 30,
                                        marginTop: 20,
                                    }}
                                >
                                    RTGS Temporary Disable
                                </button>
                                &nbsp;
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={() => this.handleSubmit("RTGS", 1)}
                                    style={{
                                        height: 30,
                                        marginTop: 20,
                                    }}
                                >
                                    RTGS Enable
                                </button>
                                &nbsp;
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={() => this.handleSubmit("BEFTN", 2)}
                                    style={{
                                        height: 30,
                                        marginTop: 20,
                                    }}
                                >
                                    EFTN Temporary Disable
                                </button>
                                &nbsp;
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={() => this.handleSubmit("BEFTN", 1)}
                                    style={{
                                        height: 30,
                                        marginTop: 20,
                                    }}
                                >
                                    EFTN Enable
                                </button>
                            </ThemeProvider>
                        </Grid>
                    </CardBody>
                </Card>
            </div>
        );
    }

}

export default withStyles(styles)(ActionComponent);
