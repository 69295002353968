import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import axios from "axios";

import {backEndServerURL} from "../../Common/Constant";

import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";

import GridItem from "../Grid/GridItem";
import CardHeader from "../Card/CardHeader";

import GridContainer from "../Grid/GridContainer";

import Functions from '../../Common/Functions';
import Notification from "../NotificationMessage/Notification";
import Checkbox from "@material-ui/core/Checkbox";


let counter = 0;

function createData(appId, customer_name, cb_number, account_number, appUid, service_type, subservice_type, branch_id) {

    counter += 1;
    return {
        appId,
        id: counter,
        customer_name,
        cb_number,
        account_number,
        appUid,
        service_type,
        subservice_type,
        branch_id
    };
}

function createDataXcell(customer_name, cb_number, account_number, appUid, service_type, subservice_type, branch_id) {

    counter += 1;
    return {id: counter, customer_name, cb_number, account_number, appUid, service_type, subservice_type, branch_id};
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [

    {id: 'id', numeric: false, disablePadding: true, label: 'Serial No'},
    {id: 'customer_name', numeric: true, disablePadding: false, label: 'Name'},
    {id: 'cb_number', numeric: true, disablePadding: false, label: 'CB Number'},
    {id: 'account_number', numeric: true, disablePadding: false, label: 'Account Number'},
    {id: 'service_type', numeric: true, disablePadding: false, label: 'Service Type'},
    {id: 'sol_id', numeric: true, disablePadding: false, label: 'Sol Id'},

];

class TableContentHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {order, orderBy} = this.props;

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">

                    </TableCell>
                    {rows.map(
                        row => (
                            <TableCell
                                key={row.id}
                                align={row.numeric ? 'right' : 'left'}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        ),
                        this,
                    )}
                </TableRow>
            </TableHead>
        );
    }
}

TableContentHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
});

class DebitCardList extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'id',
        selected: [],
        data: [],
        xcellData: [],
        getInboxCaseData: [],
        page: 0,
        rowsPerPage: 25,
        renderModal: false,
        appUid: '',
        inboxModal: false,
        serviceType: '',
        subserviceType: '',
        redirectLogin: false,
        title: "",
        notificationMessage: "",

        alert: false,
    };


    componentDidMount() {


        const data = [];
        const xcellData = [];

        let url = backEndServerURL + '/claimable';
        axios.get(url, {
            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
        })
            .then(response => {
                console.log(response.data);


                response.data.map((message) => {

                    data.push(createData(message.appId, message.name, message.document, message.accountNo, message.appId, message.serviceType, message.subServiceType, message.solId));

                });

                this.setState({
                    data: data,
                    getInboxCaseData: data,
                    xcellData: xcellData,

                });


            })

            .catch(error => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
            });


    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({order, orderBy});
    };

    handleSelectAllClick = event => {
        if (event.target.checked) {
            this.setState(state => ({selected: state.data.map(n => n.id)}));
            return;
        }
        this.setState({selected: []});
    };

    handleClick = (event, id) => {
        const {selected} = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({selected: newSelected});
    };

    handleChangePage = (event, page) => {
        this.setState({page});
    };

    handleChangeRowsPerPage = event => {
        this.setState({rowsPerPage: event.target.value});
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;
    viewCase = (appUid, serviceType, subserviceType) => {
        let url = backEndServerURL + "/case/claim/" + appUid;
        axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Claimed!",
                    alert: true
                });
            })
            .catch((error) => {
                console.log(error);
            })


    };
    closeModal = () => {
        this.setState({
            inboxModal: false,


        })
    };

    renderInboxCase = () => {


    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    /* getSubmitedForm = (object) => {
         counter = 0;
         let objectTable = {};
         let tableArray = [];

         for (let variable in object) {
             let trimData = object[variable].trim();
             if (trimData !== '')
                 objectTable[variable] = trimData;
         }

         this.state.getInboxCaseData.map((inboxCase) => {
             let showable = true;
             for (let variable in objectTable) {
                 if (objectTable[variable] !== inboxCase[variable])
                     showable = false;
             }

             if (showable)

                 tableArray.push(createData(inboxCase.customer_name, inboxCase.cb_number, inboxCase.account_number, inboxCase.appUid, inboxCase.service_type, inboxCase.subservice_type, inboxCase.branch_id));


         })
         this.setState({
             data: tableArray
         })
     }*/
    lockMultipleCase = (event) => {
        event.preventDefault();
        let url = backEndServerURL + "/case/claim";
        axios.post(url, this.state.selected, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Lock!",
                    alert: true
                });
            })
            .catch((error) => {
                console.log((error))
            })

    };

    render() {
        const {classes} = this.props;
        const {data, xcellData, order, orderBy, selected, rowsPerPage, page} = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
        {

            Functions.redirectToLogin(this.state)

        }
        return (
            <section>


                <Paper className={classes.root}>
                    <GridContainer>
                        {this.renderNotification()}
                        <GridItem xs={12} sm={12} md={12}>

                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>Your Debit Card Request List</h4>

                            </CardHeader>
                            <br/>

                            <center>
                                <button
                                    className="btn btn-outline-primary"
                                    style={{
                                        verticalAlign: 'middle',
                                        width: 100,
                                    }}
                                    onClick={(event) => this.lockMultipleCase(event)}>
                                    Lock
                                </button>

                            </center>
                            <br/>
                            <div className={classes.tableWrapper}>
                                <Table className={classes.table} aria-labelledby="tableTitle">
                                    <TableContentHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={this.handleSelectAllClick}
                                        onRequestSort={this.handleRequestSort}
                                        rowCount={data.length}
                                    />
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="xl"
                                        open={this.state.inboxModal}
                                        style={{
                                            maxWidth: `${80}%`,
                                            maxHeight: `${100}%`,
                                            margin: 'auto'

                                        }}
                                    >
                                        <DialogContent>

                                            {this.renderInboxCase()}
                                        </DialogContent>
                                    </Dialog>
                                    <TableBody>


                                        {stableSort(data, getSorting(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map(n => {
                                                const isSelected = this.isSelected(n.id);
                                                return (
                                                    <TableRow
                                                        hover
                                                        /* onClick={event => this.handleClick(event, n.id)}*/
                                                        role="checkbox"
                                                        aria-checked={isSelected}
                                                        tabIndex={-1}
                                                        key={n.id}
                                                        selected={isSelected}
                                                    >
                                                        <TableCell padding="none">
                                                            <Checkbox
                                                                onChange={event => this.handleClick(event, n.appId)}
                                                                role="checkbox"
                                                                aria-checked={isSelected}
                                                                tabIndex={-1}
                                                                key={n.appId}
                                                                selected={isSelected}
                                                                inputProps={{
                                                                    'aria-label': 'primary checkbox',
                                                                }}
                                                            />
                                                        </TableCell>
                                                        {/*  <TableCell padding="none">

                                                        </TableCell>*/}

                                                        <TableCell padding="none">

                                                        </TableCell>
                                                        <TableCell padding="none">
                                                            {n.id}
                                                        </TableCell>

                                                        <TableCell align="right">
                                                            {n.customer_name}
                                                        </TableCell>
                                                        <TableCell align="right">{n.cb_number}</TableCell>
                                                        <TableCell align="right">{n.account_number}</TableCell>
                                                        <TableCell align="right">{n.service_type}</TableCell>
                                                        <TableCell align="right">{n.branch_id}</TableCell>
                                                    </TableRow>
                                                );
                                            })

                                        }
                                        {emptyRows > 0 && (
                                            <TableRow style={{height: 49 * emptyRows}}>
                                                <TableCell colSpan={6}/>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </div>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />

                        </GridItem>
                    </GridContainer>
                </Paper>
            </section>
        );

    }
}

DebitCardList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DebitCardList);
