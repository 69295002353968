import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../Grid/GridItem.jsx";
import GridContainer from "../../Grid/GridContainer.jsx";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Notification from "../../NotificationMessage/Notification";
import UploadComponent from "../Maintenance/casa/UploadComponent";
import MyValidation from "../../JsonForm/MyValidation";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import {Dialog} from "@material-ui/core";
import CBNotFound from "../CASA/CBNotFound";
import SignatureButton from "../Maintenance/SignatureButton";
import FunctionForGetAcDetails from "../CommonComponent/FunctionForGetAcDetails";
import CommonApi from "../Maintenance/common/CommonApi";
import CommonFunctions from "../REMITENCE/CommonFunctions";

const fileIndexList = [
    {label: 'Document 1'},
    {label: 'Document 2'},
    {label: 'Document 3'},
    {label: 'Document 4'},
    {label: 'Document 5'},
    {label: 'Document 6'},
    {label: 'Document 7'},
    {label: 'Document 8'},
    {label: 'Document 9'},
    {label: 'Document 10'},
    {label: 'Document 11 '},
    {label: 'Document 12'},
    {label: 'Document 13 '},
    {label: 'Document 14'},
    {label: 'Document 15'},
    {label: 'Document 16'},
    {label: 'Document 17'},
    {label: 'Document 18'},
    {label: 'Document 19'},
    {label: 'Document 20'},

];

let RemittanceServiceOtherJsonForm = [
    {
        "varName": "caseId",
        "type": "text",
        "label": "Case Id",
        "readOnly": true,
        "multiline": true,
        "grid": 2,
    },
    {
        "varName": "source",
        "type": "select",
        "required": true,
        "enum": ["FINACLE", "ABABIL"],
        "onKeyDown": true,
        "label": "Source",
        "grid": 2,
    },
    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "label": "Account Number",
        "validation": "numeric",
        "required": true,
        "multiline": true,
        "grid": 2,
    },
    {
        "varName": "name",
        "type": "text",
        "label": "Name",
        "readOnly": true,
        "multiline": true,
        "grid": 2,
    },
    {
        "label": "A/C Balance",
        "type": "text",
        "varName": "accountBalance",
        "grid": 2,
        "readOnly": true,
        // "required": true,
        "conditional": true,
        "conditionalVarName": "showAccountBalance",
        "conditionalVarValue": true,
    },
    {
        "varName": "addressOne",
        "type": "text",
        "label": "Address Line 1",
        // "required": true,
        "grid": 2,
    },
    {
        "varName": "addressTwo",
        "type": "text",
        "label": "Address Line 2",
        // "required": true,
        "grid": 2,
    },
    {
        "varName": "contactNumber",
        "type": "text",
        "label": "Contact Number",
        "validation": "phone",
        // "required": true,
        "grid": 2,
    },
    {
        "varName": "adBranch",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "AD Branch",
        "required": true,
        "grid": 2,
    },

];
let accountNumber = [
    {
        "varName": "accountNumber",
        "type": "text",
        "validation": "numeric",
        "required": true
    },
];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};
let abhMaker = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];
let selectServiceType = [
    {
        "varName": "selectServiceType",
        "type": "select",
        "enum": [
            "Cancellation",
            "Incentive claim",
            "Encashment Certificate",
            "Others",
        ],
        "label": "Select Other Service",
        "grid": 2,
    },
];


class Other extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: {},
            appId: 0,
            getCheckerList: [],
            checkerListShow: false,
            fileUploadData: [],
            getMappingAllImage: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            uploadModal: false,
            selectType: "",
            title: "",
            cbNotFound: false,
            inputData: {
                csDeferal: "NO",
                tutionFee: "NO",
                livingExpence: "NO"
            },
            alert: false,
            message: "",
            notificationMessage: "",
            csDeferalPage: "",
            values: [],
            valuesTutionFee: [],
            valuesLivingExpence: [],
            varValue: [],
            loading: true,
            AddDeferal: false,
            getDeferalList: [],
            deferalNeeded: false,
            accountError: false,
            accountErrorMessage: "",
            accountDataBoolean: false,
            searchDataBoolean: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    componentDidMount() {

        // let url = backEndServerURL + "/startCase/cs_data_capture";
        let url = backEndServerURL + "/startCase/bond_case_start";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            let inputData = {};
            inputData.caseId = response.data.caseId;
            let appId = response.data.id;
            let adBranchUrl = backEndServerURL + "/finMasterData/adBranchRemittance";
            axios.get(adBranchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                for (let item of RemittanceServiceOtherJsonForm) {
                    if (item.varName === "adBranch") {
                        item.enum = response.data
                    }
                }
                this.setState({
                    inputData: inputData,
                    varValue: this.copyJson(inputData),
                    appId: appId,
                    getNewCase: true,
                    showValue: true,
                    getData: true,
                    loaderNeeded: true,
                    loading: false
                });
            }).catch((error) => {
                console.log(error);
            });
        }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false
            })
        })
    }
    onKeyDownChange = () => {

    }
    accountSearch = (event, data) => {
        event.preventDefault();
        if (data === "accountNumber" && this.state.inputData.source==="FINACLE") {
            this.setState({
                loading: true
            });
            this.state.inputData.accountNumber = event.target.value;
            this.state.inputData.showSignature = false;
            this.state.inputData.showAccountBalance = false;
            this.state.varValue["accountNumber"] = this.state.inputData.accountNumber;
            this.state.varValue["selectServiceType"] = this.state.inputData.selectServiceType;

            let getAccountUrl = backEndServerURL + "/getAccountInfo/" + this.state.inputData.accountNumber;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    if (response.data.message === "No Account Found!") {
                        this.state.varValue["accountNumber"] = this.state.inputData.accountNumber;
                        this.state.varValue["selectServiceType"] = this.state.inputData.selectServiceType;
                        this.setState({
                            showValue: true,
                            loading: false,
                            cbNotFound: true,
                            title: 'No Account Found!'
                        });
                    } else {
                        let inputData = this.state.inputData;

                        FunctionForGetAcDetails.getAccountInfo(this.state.inputData.accountNumber).then((custResponse) => {
                            inputData.accountBalance = custResponse.data.acctEftvBalance;
                            let contactNumber = response.data.contactNumber ? response.data.contactNumber.replace(/\D/g, '') : "";
                            inputData.contactNumber = response.data.contactNumber ? contactNumber.substr(contactNumber.length - 11) : "";
                            inputData.name = response.data.customerName;
                            inputData.showAccountBalance = response.data.schemeCode !== "SBSTF";
                            inputData.customerName = response.data.customerName;
                            inputData.cbNumber = response.data.cbNumber;
                            inputData.showSignature = true;
                            if (response.data.mailingAddress) {
                                inputData.addressOne = response.data.mailingAddress.addrLine1;
                                inputData.addressTwo = response.data.mailingAddress.addrLine2
                            }
                            this.setState({
                                inputData: inputData,
                                varValue: this.copyJson(inputData),
                                showValue: true,
                                loading: false,
                                searchDataBoolean:true
                            });
                        }).catch((error) => {
                            console.log(error)
                            let inputData = this.state.inputData;
                            inputData.accountNumber = "";
                            inputData.showSignature = false;
                            inputData.showAccountBalance = false;
                            this.setState({
                                inputData: inputData,
                                varValue: this.copyJson(inputData),
                                showValue: true,
                                loading: false,
                                cbNotFound: true,
                                accountDataBoolean: true
                            });
                        });
                    }

                })
                .catch((error) => {
                    console.log(error)
                    let inputData = this.state.inputData;
                    inputData.accountNumber = "";
                    inputData.showSignature = false;
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        showValue: true,
                        loading: false,
                        cbNotFound: true,
                        accountDataBoolean: true
                    });
                });
        }

        else if (data === "accountNumber" && this.state.inputData.source==="ABABIL") {
            this.setState({
                loading: true
            });
            this.state.inputData.accountNumber = event.target.value;
            this.state.inputData.showSignature = false;
            this.state.inputData.showAccountBalance = false;
            this.state.varValue["accountNumber"] = this.state.inputData.accountNumber;
            this.state.varValue["selectServiceType"] = this.state.inputData.selectServiceType;

            CommonApi.ababilAccountDetails(this.state.inputData.accountNumber)
                .then((response) => {

                    if (response.data.message === "No Account Found!") {
                        this.state.varValue["accountNumber"] = this.state.inputData.accountNumber;
                        this.state.varValue["selectServiceType"] = this.state.inputData.selectServiceType;
                        this.setState({
                            showValue: true,
                            loading: false,
                            cbNotFound: true,
                            title: 'No Account Found!'
                        });
                    } else {
                        let inputData = this.state.inputData;
                        inputData.accountBalance = response.data.availableBalance;
                        let contactNumber = response.data.conatactNumber ? response.data.conatactNumber.replace(/\D/g, '') : "";
                        inputData.contactNumber = response.data.conatactNumber ? contactNumber.substr(contactNumber.length - 11) : "";
                        inputData.name = response.data.acctName;
                        inputData.showAccountBalance = response.data.productCode !== "SBSTF";
                        inputData.customerName = response.data.accountName;
                        inputData.cbNumber = response.data.customerId;
                        inputData.showSignature = true;
                        inputData.addressOne = response.data.addressLineOne;
                        inputData.addressTwo = response.data.addressLineTwo;
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                            showValue: true,
                            loading: false,
                            searchDataBoolean:true
                        });
                    }

                })
                .catch((error) => {
                    console.log(error);
                    let inputData = this.state.inputData;
                    inputData.accountNumber = "";
                    inputData.showSignature = false;
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        showValue: true,
                        loading: false,
                        cbNotFound: true,
                        accountDataBoolean: true
                    });
                });
        }

    };

    generateFinacleAbabilErrorModal=()=>{
        if(this.state.accountDataBoolean){

            this.setState({
                cbNotFound: true,
                title: "Invalid Account Number",
                accountDataBoolean:false,
            });
        }
    }


    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    renderJsonForm = () => {

        if (this.state.inputData.selectServiceType !== undefined && this.state.inputData.selectServiceType !== null) {

            if(this.state.searchDataBoolean){
                return (
                    <React.Fragment>
                        {CommonJsonFormComponent.renderJsonForm(this.state, CommonFunctions.makeReadOnlyObjectForRemittance(RemittanceServiceOtherJsonForm), this.updateComponent, this.accountSearch, this.onKeyDownChange)}
                    </React.Fragment>
                )
            }
            else{
                return (
                    <React.Fragment>
                        {CommonJsonFormComponent.renderJsonForm(this.state, RemittanceServiceOtherJsonForm, this.updateComponent, this.accountSearch, this.onKeyDownChange)}
                    </React.Fragment>
                )
            }
        }

    };

    renderStudentFileType = () => {

        return (
            <React.Fragment>
                {
                    CommonJsonFormComponent.renderJsonForm(this.state, selectServiceType, this.updateComponent)
                }
            </React.Fragment>
        )
    };


    handleSubmit = (event) => {
        event.preventDefault();
        let error = MyValidation.defaultValidation(RemittanceServiceOtherJsonForm, this.state);
        let errorTwo = MyValidation.defaultValidation(accountNumber, this.state);
        this.forceUpdate();

        if (error === true || errorTwo === true) {
            return 0;
        } else {
            this.setState({
                loading: true
            });
            let imageUrl = backEndServerURL + "/case/files/" + this.state.appId;
            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.data.length > 0) {
                        if (this.state.values.length > 0) {
                            this.state.inputData["csDeferal"] = "YES";
                        } else {
                            this.state.inputData["csDeferal"] = "NO";
                        }
                        let data = this.state.inputData;
                        data.cs_deferal = this.state.inputData["csDeferal"];
                        data.dueDate = this.state.dueDate;
                        data.type = "";
                        data.category = 'Remittance';
                        data.subCategory = 'Other - ' + this.state.inputData.selectServiceType;
                        data.serviceType = "Remetance";
                        data.subServiceType = "Other Service";
                        data.cs_deferal = "NO";
                        let remarksData = {};
                        remarksData.remark = this.state.inputData.csRemarks;
                        remarksData.map = {...data, csRemarks: undefined};
                        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
                        axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                            this.setState({
                                title: "Successful!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                loading: false
                            });
                            setTimeout(function () {
                                window.location.reload()
                            }, 500);
                        }).catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false
                            })
                        });
                    } else {
                        let inputData = this.state.inputData;
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                            showValue: true,
                            title: "File upload is mandatory!",
                            cbNotFound: true,
                            loading: false
                        })
                    }
                }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
        }
    };
    handleSubmitDraft = (event) => {
        event.preventDefault();
        // let error = MyValidation.defaultValidation(RemittanceServiceOtherJsonForm, this.state);
        // let errorTwo = MyValidation.defaultValidation(accountNumber, this.state);
        // this.forceUpdate();
        // if (error === true || errorTwo === true) {
        //     return 0;
        // } else {
        this.setState({
            loading: true
        });
        var variableSetUrl = backEndServerURL + "/save/" + this.state.appId;

        let data = this.state.inputData;
        data.cs_deferal = this.state.inputData["csDeferal"];
        data.category = 'Remittance';
        data.subCategory = 'Other - ' + this.state.inputData.selectServiceType;
        data.serviceType = "Remetance";
        data.subServiceType = "Other Service";

        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);

                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Save!",
                    alert: true

                });
                setTimeout(function () {
                    window.location.reload()
                }, 500);

                this.setState({
                    loading: false
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
        // }
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };
    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    uploadFunction = () => {
        const {classes} = this.props;
        if (this.state.inputData.selectServiceType !== undefined && this.state.inputData.selectServiceType !== null) {

            return <UploadComponent indexList={fileIndexList} appId={this.state.appId} classes={classes}/>
        }
    };


    renderRemarks = () => {

        if (this.state.inputData.selectServiceType !== undefined && this.state.inputData.selectServiceType !== null) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, abhMaker, this.updateComponent)
            )
        }

    };

    renderSubmitButon = () => {
        if (this.state.inputData.selectServiceType !== undefined && this.state.inputData.selectServiceType !== null) {

            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{}}
                    onClick={this.handleSubmit}
                >
                    Submit
                </button>
            )

        }
    };

    renderSaveButton = () => {
        if (this.state.inputData.selectServiceType !== undefined && this.state.inputData.selectServiceType !== null) {

            return (
                <button
                    className="btn btn-outline-info btn-sm"
                    style={{}}
                    onClick={this.handleSubmitDraft}
                >
                    Save
                </button>
            )
        }

    };

    signature = () => {
        if (this.state.inputData.showSignature) {
            return <SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
                                    classes={this.props}/>;
        }
    };

    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >Remittance Service Other File</h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <ThemeProvider theme={theme}>
                                        <Grid container spacing={1}>
                                            {this.renderNotification()}
                                            <br/>
                                            {
                                                this.renderStudentFileType()
                                            }
                                            <br/>
                                            {this.renderJsonForm()}
                                            {this.generateFinacleAbabilErrorModal()}
                                            <br/>
                                            <Grid item xs={12}>
                                                {this.signature()}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {
                                                    this.uploadFunction()
                                                }
                                            </Grid>
                                            <Dialog
                                                fullWidth="true"
                                                maxWidth="sm"
                                                className={classes.modal}
                                                classes={{paper: classes.dialogPaper}}
                                                open={this.state.cbNotFound}>
                                                <DialogContent className={classes.dialogPaper}>

                                                    <CBNotFound
                                                        closeModal={this.closeModalCBNotFound}
                                                        title={this.state.title}
                                                    />
                                                </DialogContent>
                                            </Dialog>
                                            <br/>
                                            <Grid xs='12'></Grid>
                                            {this.renderRemarks()}
                                            <br/>

                                            <Grid item xs={12}>
                                                {this.renderSubmitButon()}
                                                &nbsp;
                                                {this.renderSaveButton()}
                                            </Grid>
                                        </Grid>
                                    </ThemeProvider>
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }
    }
}

export default withStyles(styles)(Other);