import React from 'react';
import validator from "validator";

class MyValidation {
    static trimFunction = (emptyString) => {
        let typeOfString = typeof emptyString;
        if (typeOfString === "string") {
            if (emptyString !== null && emptyString !== undefined && emptyString !== "") {
                let string = emptyString.trim();
                return string;
            } else {
                return ""
            }
        } else {
            if (emptyString !== null && emptyString !== undefined && emptyString !== "") {
                return "object"
            } else {
                return ""
            }
        }


    };

    static defaultValidation(jsonForm, state) {
        console.log(state);
        let error = false;

        jsonForm.map((field) => {
            let localError = false;
            let getData = state.inputData[field.varName];
            if (field.readOnly !== true) {
                if (field.required) {
                    if (field.conditional === true) {
                        if (state.inputData[field.conditionalVarName] === field.conditionalVarValue) {
                            if (getData === "__ERROR__" || (this.trimFunction(getData) === "__ERROR__") || getData  === "undefined" || getData === "null") {
                                localError = true;
                                state.errorMessages[field.varName] = "Invalid Input";
                                console.log(field.varName);
                                error = true;
                            }
                            else if (getData === "Invalid Date" || (this.trimFunction(getData) === "Invalid Date")) {
                                localError = true;
                                state.errorMessages[field.varName] = "Invalid Input";
                                console.log(field.varName);
                                error = true;
                            } else if (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData))) {
                                localError = true;
                                state.errorMessages[field.varName] = "Required";
                                console.log(field.varName);
                                error = true;
                            } else if (getData !== undefined && getData !== null && validator.isEmpty(this.trimFunction(getData))) {
                                localError = true;
                                state.errorMessages[field.varName] = "Required";
                                console.log(field.varName);
                                error = true;
                            }
                        } else {
                            return;
                        }
                    } else if (field.masterData === true && field.masterDataArray === true) {
                        let tempError = false;
                        for (let i = 0; i < field.conditionalArrayList.length; i++) {
                            let varName, varValue = "";
                            if (state.inputData[field.conditionalArrayList[i]] && state.inputData[field.conditionalArrayList[i]].key) {
                                varName = (state.inputData[field.conditionalArrayList[i]].key).replace("/", "_");
                                varValue = (field.conditionalArrayValue[i]).replace("/", "_")
                            } else {
                                varName = state.inputData[field.conditionalArrayList[i]];
                                varValue = field.conditionalArrayValue[i];
                            }
                            if (varName === varValue) {
                                if (getData === undefined || getData === null || getData  === "undefined" || getData === "null" || validator.isEmpty(this.trimFunction(getData))) {
                                    tempError = true;
                                    console.log(field.varName);
                                }
                            } else {
                                tempError = false;
                                return;
                            }
                        }
                        if (tempError) {
                            localError = true;
                            state.errorMessages[field.varName] = "Required";
                            error = true;
                        }
                    } else if (field.conditionalOrValue === true) {
                        let tempError = false;
                        if (field.conditionalOrList !== undefined) {
                            if (field.conditionalOrList.includes(state.inputData[field.conditionalVarName]) && validator.isEmpty(this.trimFunction(getData))) {
                                tempError = true
                            }
                        }
                        if (tempError) {
                            localError = true;
                            state.errorMessages[field.varName] = "Required";
                            error = true;
                        }
                    } else if (field.masterData === true) {
                        if (state.inputData[field.conditionalVarName] !== null && state.inputData[field.conditionalVarName] !== undefined && state.inputData[field.conditionalVarName].key !== null && state.inputData[field.conditionalVarName].key !== undefined && (state.inputData[field.conditionalVarName].key).replace("/", "_") === (field.conditionalVarValue).replace("/", "_")) {
                            if (getData === undefined || getData === null || getData  === "undefined" || getData === "null" || validator.isEmpty(this.trimFunction(getData))) {
                                localError = true;
                                state.errorMessages[field.varName] = "Required";
                                console.log(field.varName);
                                error = true;
                            } else if (getData !== undefined && getData !== null && validator.isEmpty(this.trimFunction(getData))) {
                                localError = true;
                                state.errorMessages[field.varName] = "Required";
                                console.log(field.varName);
                                error = true;
                            } else if (getData === "__ERROR__" || (this.trimFunction(getData) === "__ERROR__")) {
                                localError = true;
                                state.errorMessages[field.varName] = "Invalid Input";
                                console.log(field.varName);
                                error = true;
                            }
                            else if (getData === "Invalid Date" || (this.trimFunction(getData) === "Invalid Date")) {
                                localError = true;
                                state.errorMessages[field.varName] = "Invalid Input";
                                console.log(field.varName);
                                error = true;
                            }
                        } else {
                            return;
                        }
                    } else if (field.conditionalArray === true) {
                        let tempError = false;
                        let invalidInput = false;
                        for (let i = 0; i < field.conditionalArrayList.length; i++) {


                            if (state.inputData[field.conditionalArrayList[i]] === field.conditionalArrayValue[i]) {
                                /*    if (getData === "__ERROR__" || (this.trimFunction(getData)==="__ERROR__")) {
                                        localError = true;
                                        state.errorMessages[field.varName] = "Invalid Input";
                                        console.log(field.varName);
                                        error = true;
                                    }*/

                                if (getData === "__ERROR__" || this.trimFunction(getData) === "__ERROR__") {
                                    invalidInput = true;
                                    console.log(field.varName);
                                }
                                else if (getData === "Invalid Date" || (this.trimFunction(getData) === "Invalid Date")) {
                                    invalidInput = true;
                                }
                                else if (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData))) {
                                    tempError = true;
                                    console.log(field.varName);
                                }

                            } else {
                                tempError = false;
                                return;
                            }
                        }
                        if (invalidInput) {
                            localError = true;
                            state.errorMessages[field.varName] = "Invalid Input";
                            error = true;
                        } else if (tempError) {
                            localError = true;
                            state.errorMessages[field.varName] = "Required";
                            error = true;
                        }

                    } else {
                        if (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData))) {
                            localError = true;
                            state.errorMessages[field.varName] = "Required";
                            console.log(field.varName);
                            error = true;
                        } else if (getData !== undefined && getData !== null && validator.isEmpty(this.trimFunction(getData))) {
                            localError = true;
                            state.errorMessages[field.varName] = "Required";
                            console.log(field.varName);
                            error = true;
                        } else if (getData === "__ERROR__" || (this.trimFunction(getData) === "__ERROR__")) {
                            localError = true;
                            state.errorMessages[field.varName] = "Invalid Input";
                            console.log(field.varName);
                            error = true;
                        }
                        else if (getData === "Invalid Date" || (this.trimFunction(getData) === "Invalid Date")) {
                            localError = true;
                            state.errorMessages[field.varName] = "Invalid Input";
                            console.log(field.varName);
                            error = true;
                        }


                    }
                }
                if (getData !== undefined && field.type === "textArea") {
                    if (field.required === true && (getData === undefined || getData === null)) {


                        localError = true;
                        state.errorMessages[field.varName] = "Remarks Should Be Upto 1500 digit";
                        //console.log(field.varName);
                        error = true;
                    } else if (getData !== "" && getData !== undefined && getData !== null && (
                        !validator.isLength(this.trimFunction(getData), {
                            min: 0,
                            max: 1500
                        }))) {
                        localError = true;
                        state.errorMessages[field.varName] = "Remarks Should Be Upto 1500 digit";
                        //console.log(field.varName);
                        error = true;
                    }

                }

                if (getData !== undefined && field.validation !== undefined && field.validation === "tin") {
                    if (field.required === true && (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData)))) {


                        localError = true;
                        state.errorMessages[field.varName] = "TIN should be 12 digit";
                        //console.log(field.varName);
                        error = true;
                    } else if (getData !== "" && getData !== undefined && getData !== null && (
                        !validator.isLength(this.trimFunction(getData), {
                            min: 12,
                            max: 12
                        }))) {
                        localError = true;
                        state.errorMessages[field.varName] = "TIN should be 12 digit";
                        //console.log(field.varName);
                        error = true;
                    } else if (getData !== undefined && getData !== null && getData !== "" && (
                        !validator.isNumeric(getData))
                    ) {
                        localError = true;
                        state.errorMessages[field.varName] = "Only Numeric Value Allowed";
                        console.log(field.varName);
                        error = true;
                    }

                }

                if (getData !== undefined && field.validation !== undefined && field.validation === "staffId") {
                    if (field.required === true && (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData)))) {


                        localError = true;
                        state.errorMessages[field.varName] = "Staff ID should be Upto 8 digit";
                        //console.log(field.varName);
                        error = true;
                    } else if (getData !== "" && getData !== undefined && getData !== null && (
                        !validator.isLength(this.trimFunction(getData), {
                            min: 0,
                            max: 8
                        }))) {
                        localError = true;
                        state.errorMessages[field.varName] = "Staff ID should be Upto 8 digit";
                        //console.log(field.varName);
                        error = true;
                    }

                }


                if (getData !== undefined && field.validation !== undefined && field.validation === "nid") {
                    if (field.required === true && (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData)))) {


                        localError = true;
                        state.errorMessages[field.varName] = "NID should be 13/17 digit";
                        //console.log(field.varName);
                        error = true;
                    } else if (getData !== "" && getData !== undefined && getData !== null && (
                        !validator.isLength(this.trimFunction(getData), {
                            min: 13,
                            max: 13
                        })) && !validator.isLength(this.trimFunction(getData), {min: 17, max: 17})
                    ) {
                        localError = true;
                        state.errorMessages[field.varName] = "NID should be 13/17 digit";
                        //console.log(field.varName);
                        error = true;
                    } else if (getData !== undefined && getData !== null && getData !== "" && (
                        !validator.isNumeric(getData))
                    ) {
                        console.log("..................integer................");
                        localError = true;
                        state.errorMessages[field.varName] = "Only Numeric Value Allowed";
                        console.log(field.varName);
                        error = true;
                    }

                }

                if (getData !== undefined && field.validation !== undefined && field.validation === "passport") {
                    if (field.required === true && (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData)))) {


                        localError = true;
                        state.errorMessages[field.varName] = "Passport should be upto 12  digit";
                        //console.log(field.varName);
                        error = true;
                    } else if (getData !== "" && getData !== undefined && getData !== null && (
                        !validator.isLength(this.trimFunction(getData), {
                            min: 1,
                            max: 12
                        }))) {
                        localError = true;
                        state.errorMessages[field.varName] = "Passport should be upto 12  digit";
                        //console.log(field.varName);
                        error = true;
                    }


                }

                if (getData !== undefined && field.validation !== undefined && field.validation === "birthCertificateNumber") {
                    if (field.required === true && (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData)))) {


                        localError = true;
                        state.errorMessages[field.varName] = "Birth Certificate Number be upto 17  digit";
                        //console.log(field.varName);
                        error = true;
                    } else if (getData !== "" && getData !== undefined && getData !== null && (
                        !validator.isLength(this.trimFunction(getData), {
                            min: 1,
                            max: 17
                        }))) {
                        localError = true;
                        state.errorMessages[field.varName] = "Birth Certificate Number be upto 17  digit";
                        //console.log(field.varName);
                        error = true;
                    }

                }

                if (getData !== undefined && field.validationB !== undefined && field.validationB === "length") {


                    console.log(".... i am in this function");

                    if (field.required === true && (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData)))) {
                        localError = true;
                        state.errorMessages[field.varName] = "Length Should be Upto " + field.maxLength + " digit";
                        //console.log(field.varName);
                        error = true;
                    } else if (getData !== "" && getData !== undefined && getData !== null && (
                        !validator.isLength(this.trimFunction(getData), {
                            min: 0,
                            max: field.maxLength
                        }))) {
                        localError = true;
                        state.errorMessages[field.varName] = "Length Should be Upto " + field.maxLength + " digit";
                        error = true;
                    }

                }

                if (getData !== undefined && field.validation !== undefined && field.validation === "etin") {
                    if (field.required === true && (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData)))) {


                        localError = true;
                        state.errorMessages[field.varName] = "E-TIN  Number should be 12 digit";
                        //console.log(field.varName);
                        error = true;
                    } else if (getData !== "" && getData !== undefined && getData !== null && (
                        !validator.isLength(this.trimFunction(getData), {
                            min: 12,
                            max: 12
                        }))) {
                        localError = true;
                        state.errorMessages[field.varName] = "E-TIN  Number should be 12 digit";
                        //console.log(field.varName);
                        error = true;
                    } else if (getData !== undefined && getData !== null && getData !== "" && (
                        !validator.isNumeric(getData))
                    ) {
                        localError = true;
                        state.errorMessages[field.varName] = "Only Numeric Value Allowed";
                        console.log(field.varName);
                        error = true;
                    }

                }


                if (field.validation !== undefined && field.validation === "numeric") {
                    if (field.conditional === true) {
                        if (state.inputData[field.conditionalVarName] === field.conditionalVarValue) {
                            if (field.required === true && (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData)))) {
                                localError = true;
                                state.errorMessages[field.varName] = "Required";
                                error = true;
                            } else if (getData !== undefined && getData !== null && getData !== "" && (
                                !validator.isNumeric(getData))
                            ) {
                                localError = true;
                                state.errorMessages[field.varName] = "Only Numeric Value Allowed";
                                console.log(field.varName);
                                error = true;
                            }
                        } else {
                            return;
                        }
                    } else {
                        if (field.required === true && (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData)))) {
                            localError = true;
                            state.errorMessages[field.varName] = "Required";
                            error = true;
                        } else if (getData !== undefined && getData !== null && getData !== "" && (
                            !validator.isNumeric(getData))
                        ) {
                            localError = true;
                            state.errorMessages[field.varName] = "Only Numeric Value Allowed";
                            console.log(field.varName);
                            error = true;
                        }
                    }

                }

                if (field.validation !== undefined && field.validation === "length") {
                    if (field.required === true && (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData)))) {
                        localError = true;
                        state.errorMessages[field.varName] = "Required";
                        error = true;
                    } else if (getData !== undefined && getData !== null && getData !== "" && (
                        !validator.isNumeric(getData) && field.validationLengthType !== "string")
                    ) {
                        localError = true;
                        state.errorMessages[field.varName] = "Only Numeric Value Allowed";
                        error = true;
                    } else if (getData !== undefined && getData !== null && getData !== "" && (
                        !validator.isLength(this.trimFunction(getData), {
                            max: field.maxLength
                        }))
                    ) {
                        localError = true;
                        state.errorMessages[field.varName] = `field max length ${field.maxLength}`;
                        console.log(field.varName);
                        error = true;
                    } else if (getData !== undefined && getData !== null && getData !== "" && (
                        !validator.isLength(this.trimFunction(getData), {
                            min: field.minLength
                        }))
                    ) {
                        localError = true;
                        state.errorMessages[field.varName] = `field min length ${field.minLength}`;
                        console.log(field.varName);
                        error = true;
                    }

                }

                if (field.validation !== undefined && field.validation === "NID") {
                    if (field.required === true && (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData)))) {
                        localError = true;
                        state.errorMessages[field.varName] = "Required";
                        error = true;
                    }
                    else if (getData !== undefined && getData !== null && getData !== "" && (
                        !validator.isNumeric(getData) && field.validationLengthType !== "string")
                    ) {
                        localError = true;
                        state.errorMessages[field.varName] = "Only Numeric Value Allowed";
                        error = true;
                    }
                    else if (getData !== undefined && getData !== null && getData !== "" && !validator.isLength(this.trimFunction(getData), {min: 13, max: 13}) && !validator.isLength(this.trimFunction(getData), {min: 17, max: 17})
                    ) {
                        localError = true;
                        state.errorMessages[field.varName] = "NID should be 13/17 digit";
                        console.log(field.varName);
                        error = true;
                    }
                }

                if (field.validation !== undefined && field.validation === "alphaNumeric") {
                    if (field.required === true && (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData)))) {
                        localError = true;
                        state.errorMessages[field.varName] = "Required";
                        error = true;
                    } else if (getData !== undefined && getData !== null && getData !== "" && (
                        !validator.isAlphanumeric(getData))
                    ) {
                        localError = true;
                        state.errorMessages[field.varName] = "Only Alpha Numeric Value Allowed";
                        console.log(field.varName);
                        error = true;
                    }

                }
                if (field.validation !== undefined && field.validation === "percent") {
                    if (field.required === true && (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData)))) {
                        localError = true;
                        state.errorMessages[field.varName] = "Required";
                        error = true;
                    } else if (getData !== undefined && getData !== null && getData !== "" && (
                        !validator.isNumeric(getData) || !validator.isFloat(getData))
                    ) {
                        if (!(getData.indexOf('%') > -1)) {
                            localError = true;
                            state.errorMessages[field.varName] = "Only Numeric or Decimel Value Allowed";
                            console.log(field.varName);
                            error = true;
                        }
                    }

                }
                if (field.validation !== undefined && field.validation === "string") {
                    if (field.required === true && (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData)))) {
                        localError = true;
                        state.errorMessages[field.varName] = "Required";
                        error = true;
                    } else if (getData !== undefined && getData !== null && getData !== "" && (
                        validator.isNumeric(getData))
                    ) {
                        localError = true;
                        state.errorMessages[field.varName] = "Only String Value Allowed";
                        console.log(field.varName);
                        error = true;
                    }

                }

                if (field.validation !== undefined && field.validation === "email") {
                    if (field.required === true && (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData)))) {

                        localError = true;
                        state.errorMessages[field.varName] = "Required";
                        //console.log(field.varName);
                        error = true;
                    } else if (getData !== undefined && getData !== null && getData !== "" && (
                        !validator.isEmail(getData))
                    ) {
                        localError = true;
                        state.errorMessages[field.varName] = "Email is Not Valid";
                        console.log(field.varName);
                        error = true;
                    }

                }
                if (field.validation !== undefined && field.validation === "beneficiaryAccount") {
                    if (field.required === true && (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData)))) {
                        localError = true;
                        state.errorMessages[field.varName] = "Required";
                        error = true;
                    } else if (getData !== undefined && getData !== null && getData !== "" && (/[^.0-9-]/gi.test(getData))
                    ) {
                        localError = true;
                        state.errorMessages[field.varName] = "Only numeric character and special character (Dash - and Dot .) allowed";
                        console.log(getData);
                        error = true;
                    }

                }

                if (field.validation !== undefined && field.validation === "compareValue") {
                    if (field.required === true && (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData)))) {

                        localError = true;
                        state.errorMessages[field.varName] = "Required";
                        //console.log(field.varName);
                        error = true;
                    } else if (getData !== undefined && getData !== null && getData !== "" && (
                        validator.isNumeric(getData) || validator.isFloat(getData))
                    ) {
                        if ((parseFloat(state.inputData[field.varName]) > parseFloat(state.inputData[field.compareVarName]))) {
                            localError = true;
                            state.errorMessages[field.varName] = `${field.label} Not larger than ${field.compareLabel}`;
                            console.log(field.varName);
                            error = true;
                        } else if (parseFloat(state.inputData[field.varName]) < 0) {
                            localError = true;
                            state.errorMessages[field.varName] = `${field.label} Must larger than Zero`;
                            console.log(field.varName);
                            error = true;
                        }
                    } else {
                        localError = true;
                        state.errorMessages[field.varName] = "Only Numeric or Decimal Value Allowed";
                        console.log(field.varName);
                        error = true;
                    }
                }

                if (field.validation !== undefined && field.validation === "phone") {
                    if (field.conditional === true) {

                        if (state.inputData[field.conditionalVarName] === field.conditionalVarValue) {
                            if (field.required === true && (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData)))) {

                                localError = true;
                                state.errorMessages[field.varName] = "Required";
                                error = true;
                            } else if (getData !== undefined && getData !== null && getData !== "" && (
                                !validator.isLength(this.trimFunction(getData), {min: 11, max: 11}))) {
                                localError = true;
                                state.errorMessages[field.varName] = "Mobile Number should be 11 digit";
                                console.log(field.varName);
                                error = true;
                            } else if (getData !== undefined && getData !== null && getData !== "" && (
                                !validator.isNumeric(getData))
                            ) {
                                localError = true;
                                state.errorMessages[field.varName] = "Only Numeric Value Allowed";
                                console.log(field.varName);
                                error = true;
                            }
                        } else {
                            return;
                        }
                    } else {
                        if (field.required === true && (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData)))) {

                            localError = true;
                            state.errorMessages[field.varName] = "Required";
                            //console.log(field.varName);
                            error = true;
                        } else if (getData !== undefined && getData !== null && getData !== "" && (
                            !validator.isLength(this.trimFunction(getData), {min: 11, max: 11}))) {
                            localError = true;
                            state.errorMessages[field.varName] = "Mobile Number should be 11 digit";
                            console.log(field.varName);
                            error = true;
                        } else if (getData !== undefined && getData !== null && getData !== "" && (
                            !validator.isNumeric(getData))
                        ) {
                            localError = true;
                            state.errorMessages[field.varName] = "Only Numeric Value Allowed";
                            console.log(field.varName);
                            error = true;
                        }
                    }

                }
                state.errorArray[field.varName] = localError;
                if (!localError) {
                    state.errorMessages[field.varName] = ""
                }
            }
            if (field.readOnly === true && field.validateReadOnly === true ) {
                if (field.required) {
                    if (field.conditional === true) {
                        if (state.inputData[field.conditionalVarName] === field.conditionalVarValue) {
                            if (getData === "__ERROR__" || (this.trimFunction(getData) === "__ERROR__")) {
                                localError = true;
                                state.errorMessages[field.varName] = "Invalid Input";
                                console.log(field.varName);
                                error = true;
                            }

                            else if (getData === "Invalid Date" || (this.trimFunction(getData) === "Invalid Date")) {
                                localError = true;
                                state.errorMessages[field.varName] = "Invalid Input";
                                console.log(field.varName);
                                error = true;
                            } else if (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData))) {
                                localError = true;
                                state.errorMessages[field.varName] = "Required";
                                console.log(field.varName);
                                error = true;
                            } else if (validator.isEmpty(this.trimFunction(getData))) {
                                localError = true;
                                state.errorMessages[field.varName] = "Required";
                                console.log(field.varName);
                                error = true;
                            }
                        } else {
                            return;
                        }
                    }
                    else if (field.conditionalArray === true) {
                        let tempError = false;
                        let invalidInput = false;
                        for (let i = 0; i < field.conditionalArrayList.length; i++) {


                            if (state.inputData[field.conditionalArrayList[i]] === field.conditionalArrayValue[i]) {
                                /*    if (getData === "__ERROR__" || (this.trimFunction(getData)==="__ERROR__")) {
                                        localError = true;
                                        state.errorMessages[field.varName] = "Invalid Input";
                                        console.log(field.varName);
                                        error = true;
                                    }*/

                                if (getData === "__ERROR__" || this.trimFunction(getData) === "__ERROR__") {
                                    invalidInput = true;
                                    console.log(field.varName);
                                }
                                else if (getData === "Invalid Date" || (this.trimFunction(getData) === "Invalid Date")) {
                                    invalidInput = true;
                                    console.log(field.varName);
                                } else if (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData))) {
                                    tempError = true;
                                    console.log(field.varName);
                                }

                            } else {
                                tempError = false;
                                return;
                            }
                        }
                        if (invalidInput) {
                            localError = true;
                            state.errorMessages[field.varName] = "Invalid Input";
                            error = true;
                        } else if (tempError) {
                            localError = true;
                            state.errorMessages[field.varName] = "Required";
                            error = true;
                        }

                    }

                    else {
                        if (getData === undefined || getData === null || validator.isEmpty(this.trimFunction(getData))) {
                            localError = true;
                            state.errorMessages[field.varName] = "Required";
                            console.log(field.varName);
                            error = true;
                        } else if (validator.isEmpty(this.trimFunction(getData))) {
                            localError = true;
                            state.errorMessages[field.varName] = "Required";
                            console.log(field.varName);
                            error = true;
                        } else if (getData === "__ERROR__" || (this.trimFunction(getData) === "__ERROR__")) {
                            localError = true;
                            state.errorMessages[field.varName] = "Invalid Input";
                            console.log(field.varName);
                            error = true;
                        }
                        else if (getData === "Invalid Date" || (this.trimFunction(getData) === "Invalid Date")) {
                            localError = true;
                            state.errorMessages[field.varName] = "Invalid Input";
                            console.log(field.varName);
                            error = true;
                        }

                    }
                }
                state.errorArray[field.varName] = localError;
                if (!localError) {
                    state.errorMessages[field.varName] = ""
                }
            }
           //  var format = /[a-zA-Z0-9 `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            let format = /[{}^<>=]/;


            if ((field.type === "text" ||  field.type === "textArea") && format.test(getData) === true) {
                localError = true;
                state.errorMessages[field.varName] = "Second Bracket “ { } ^ < > = “ is not allowed";
                console.log(field.varName);
                state.errorArray[field.varName] = localError;
                error = true;
            }
         /*   let formatData=/[/]/;
            if ((field.onKeyDown === true ||  field.type === "textApiCall") && (field.type ==="textApiCall" ||  field.type ==="text") && formatData.test(getData) === true) {
                localError = true;
                state.errorMessages[field.varName] = " “/“ is not allowed";
                console.log(field.varName);
                state.errorArray[field.varName] = localError;
                error = true;
            }*/
           /* format=/[a-zA-Z0-9 `!@#$%^&*()_+\-\[\];':"\\|,.\/?~]/;
            if ((field.type === "text" ||  field.type === "textArea") && getData!==undefined && getData!==null && getData!=="" &&   format.test(getData) !== true ) {
                localError = true;
                state.errorMessages[field.varName] = "Only English Character language Allow!!";
                console.log(field.varName);
                state.errorArray[field.varName] = localError;
                error = true;
            }*/



        });
        return error;
    }

    static fileValidation(field, state) {
        let error = false;
        let localError = false;
        let multipleUpload = [];

        if (field.readOnly !== true) {
            if (field.fileType === "multiple") {
                if (state.fileUploadData[field.varName] !== undefined) {
                    for (let i = 0; i < state.fileUploadData[field.varName].length; i++) {
                        multipleUpload.push(state.fileUploadData[field.varName][i])
                    }
                    multipleUpload.map((item) => {
                        let uploadedFileName = item.name;
                        let fileName = uploadedFileName.split(".");
                        let lengthFileN = fileName.length;
                        fileName = fileName[lengthFileN - 1];
                        let acceptArray = (field.accept).split(",");

                        if (!acceptArray.includes(`.${fileName.toLowerCase()}`)) {
                            localError = true;
                            state.errorMessages[field.varName] = "Invalid File Type";
                            error = true;
                        }
                    })
                }
            } else {
                if (state.fileUploadData[field.varName] !== undefined) {
                    let fileUploadedName = state.fileUploadData[field.varName].name;
                    let fileN = fileUploadedName.split(".");
                    let lengthFileN = fileN.length;
                    fileN = fileN[lengthFileN - 1];
                    let acceptArray = (field.accept).split(",");

                    if (!acceptArray.includes(`.${fileN.toLowerCase()}`)) {
                        localError = true;
                        state.errorMessages[field.varName] = "Invalid File Type";
                        error = true;
                    }
                }
            }

            state.errorArray[field.varName] = localError;
            if (!localError) {
                state.errorMessages[field.varName] = ""
            }
        }

        return error;
    }

    static deferralValidation(deferralArray, state) {
        let validationField = [];
        let {inputData} = state;
        if (deferralArray.length > 0) {
            for (let item of deferralArray) {
                if (inputData["deferalType" + item] === "Others") {
                    validationField.push({varName: "deferalOther" + item, required: true})
                }
                if (!(("deferalType" + item) in inputData) || ((("deferalType" + item) in inputData) && inputData["deferalType" + item] === null)) {
                    validationField.push({varName: "deferalType" + item, required: true})
                }
            }
            return this.defaultValidation(validationField, state);
        }
    }

    static waiverValidation(waiverArray, state) {
        let validationField = [];
        let {inputData} = state;
        if (waiverArray.length > 0) {
            for (let item of waiverArray) {
                if (inputData["waiverType" + item] === "Others") {
                    validationField.push({varName: "waiverOther" + item, required: true})
                }
                if (!(("typeofWaiverDocs" + item) in inputData) || ((("typeofWaiverDocs" + item) in inputData) && inputData["typeofWaiverDocs" + item] === null)) {
                    validationField.push({varName: "typeofWaiverDocs" + item, required: true})
                }
                if (!(("waiverType" + item) in inputData) || ((("waiverType" + item) in inputData) && inputData["waiverType" + item] === null)) {
                    validationField.push({varName: "waiverType" + item, required: true})
                }
            }
            return this.defaultValidation(validationField, state);
        }
    }
}

export default MyValidation;
