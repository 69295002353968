import React, {Component} from "react";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../JsonForm/CustomeTheme2";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import Notification from "../NotificationMessage/Notification";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import MyValidation from "../JsonForm/MyValidation";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";
import Functions from "../../Common/Functions";

let Form = [
    {
        "varName": "username",
        "type": "text",
        "label": "User Id",
        "grid": 2,
        "required": true,
        "lowerCase": true,
    },
    {
        "varName": "firstName",
        "type": "text",
        "label": "Name",
        "grid": 2,
        "validation": "string",
        "required": true,
    },
    // {
    //     "type": "empty",
    //     "grid": 1
    // },
    // {
    //     "varName": "lastName",
    //     "type": "text",
    //     "label": "Last Name",
    //     "grid": 2,
    //     "required":true,
    // },
    // {
    //     "type": "empty",
    //     "grid": 1
    // },
    // {
    //     "type": "empty",
    //     "grid": 1
    // },
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "grid": 2,
        "validation": "email",
        "required": true,
    },
    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile Number *",
        "grid": 2,
        "validation": "phone",
        "phonePrefix": true,
    },
    {
        "varName": "roleId",
        type: "autoCompleteValueReturn",
        enum: [],
        "label": "Role",
        "grid": 2,
        "required": true,
    },

];

let validation = [
    {
        "varName": "username",
        "type": "text",
        "label": "User Id",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "firstName",
        "type": "text",
        "label": "Name",
        "grid": 2,
        "required": true,
    },
    // {
    //     "type": "empty",
    //     "grid": 1
    // },
    // {
    //     "varName": "lastName",
    //     "type": "text",
    //     "label": "Last Name",
    //     "grid": 2,
    //     "required":true,
    // },
    // {
    //     "type": "empty",
    //     "grid": 1
    // },

    // {
    //     "type": "empty",
    //     "grid": 1
    // },
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "grid": 2,
        "validation": "email",
        "required": true,
    },
    // {
    //     "type": "empty",
    //     "grid": 1
    // },
    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile Number",
        "grid": 2,
        "validation": "phone",
        "required": true,
        "phonePrefix": true,
    },
    {
        "varName": "roleId",
        type: "autoCompleteValueReturn",
        enum: [],
        "label": "Role",
        "grid": 2,
        "required": true,
    },

    /* {
         "varName": "userMobileNumber",
         "type": "text",
         "label": "User Mobile Number",
         "grid": 2
     },
     {
         "type": "empty",
         "grid": 1
     },*/

];

let branchAndBakeOffice = [
    {
        varName: "branchAndBakeOffice",
        type: "select",
        label: "Branch/Back Office",
        grid: 2,
        enum: ["BRANCH", "BACKOFFICE"]
    },
];

let workStationRequestType = [];
let workplaceNames = [];
let bo = [{title: "BRANCH"}, {title: "BACKOFFICE"}];
let roleRequestType = [];
let otherRequestType = [];
let workplaceUnits = [];

class UserRegistration extends Component {
    state = {
        inputData: {},
        selectedDate: {},
        getData: false,
        workstation: false,
        role: false,
        showValue: false,
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        type: "success",
        err: false,
        varValue: [],
        errorArray: {},
        errorMessages: {},
        title: "",
        notificationMessage: "",
        alert: false,
        otherRequestTitle: "",
        loading: false,
        getWorkplaceUnits: false,
        getWorkplaceNames: false,
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };
    findByVarNameKycMasterDataApiData = (searchVarname, getValue) => {
        let kycArrayList = Form;
        for (let i = 0; i < kycArrayList.length; i++) {
          let jsonObjectLast = kycArrayList[i];
          if (jsonObjectLast.varName === searchVarname) {
            Form[i].enum = getValue;
          }
        }
      };

    handleSubmit = (event) => {
        event.preventDefault();
        let data = {};
        this.setState({
            loading: true
        });


        /*let workplaceTypeArray = [
            {
                varName: "workplaceNames",
                type: "text",
                required: true
            },
            {
                varName: "roleId",
                type: "text",
                required: true
            },
/!*            {
                varName: "workplaceUnits",
                type: "text",
                required: true
            }*!/
        ];*/

        /*
                console.log(this.state.inputData);
                let dependencyField = validation.concat(workplaceTypeArray);
                let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
                this.forceUpdate();
        */
        let dependencyField = validation;
        let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
        this.forceUpdate();

        console.log(this.state.inputData);

        console.log("Not working");
        if (error === true || this.state.inputData.roleId === null) {
            this.setState({
                loading: false
            });
            return 0;
        }

        data.username = this.state.inputData.username;
        data.password = "Gd%12345";
        data.firstName = this.state.inputData.firstName;
        data.lastName = '';
        data.email = this.state.inputData.email;
        data.phone = this.state.inputData.phone;
        data.roleId = this.state.inputData.roleId.key;
        data.workplaceUnits = [this.state.inputData.workplaceUnits.key];
        data.status = 'ACTIVE';
        console.log(data);
        //   return 0;
        let url = backEndServerURL + "/user/add";
        axios.post(url, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                if (response.data === true) {
                    this.setState({
                        type: "success",
                        title: "Successfull!",
                        notificationMessage: "Successfully Added!",
                        alert: true,
                        loading: false
                    });
                    setTimeout(function () {
                        window.location.reload()
                    }, 1000);

                } else {
                    this.setState({
                        type: "warning",
                        title: "warning!",
                        notificationMessage: response.data,
                        alert: true,
                        loading: false
                    })
                }

            })
            .catch((error) => {
                this.setState({
                    type: "error",
                    title: "Error!",
                    notificationMessage: Functions.errorObjectCheck(error),
                    alert: true,
                    loading: false
                });
                console.log(error)
            })
    };
    renderButton = () => {
        // if (this.state.role === true) {
        return (

            <button
                className="btn btn-outline-primary  btn-sm"
                style={{
                    marginTop: "18px"
                }}
                onClick={this.handleSubmit}

            >
                Submit
            </button>


        )
        // }


    };

    componentDidMount() {
        this.state.inputData.password = "Gd%12345";
        this.state.varValue.password = "Gd%12345";
        this.setState({
            loading: true,
        });
        this.handleChangWorkPlaceName();
        // let workplaceNamesUrl = backEndServerURL + "/workplaceNames/getAll";
        // axios.get(workplaceNamesUrl, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
        //     .then((response) => {
        //         console.log("workplaceNames")
        //         console.log(response.data)
        //         response.data.map((data,index) => {
        //             workplaceNames.push({
        //             title: data,
        //             key: index
        //             })
        //         })
        //         this.setState({
        //             getData:true,
        //             getWorkplaceNames: true
        //         })

        //     })
        //     .catch((error) => {
        //         console.log(error);
        //         this.setState({
        //             getWorkplaceNames: true
        //         })

        //     })

    }

    updateComponent = () => {
        this.forceUpdate();
    };
    handleChangWorkPlaceName = () => {
        this.state.inputData.roleId = null;
        this.state.inputData.workplaceUnits = null;
        this.setState({
            getWorkplaceUnits: false,
            workstation: false,
        });
        roleRequestType = [];
        workplaceUnits = [];

        this.updateComponent();
        let roleListUrl = backEndServerURL + "/role/get";
        axios.get(roleListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((data) => {
                    roleRequestType.push({
                        value: data.authority,
                        key: data.id
                    })
                });
                this.findByVarNameKycMasterDataApiData("roleId", roleRequestType);
                this.setState({
                    getData: true,
                    workstation: true,
                    getWorkplaceNames: true,
                    loading: false,
                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                })

            })
        let Url = backEndServerURL + "/workplaceUnit/getByBranchOrOps/GDLC";

        axios.get(Url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data[0]);
                this.state.inputData.workplaceUnits=response.data[0];
                response.data.map((data) => {
                    workplaceUnits.push({
                        title: data.value,
                        key: data.key,
                    })
                });

                this.setState({
                    getWorkplaceUnits: true
                });
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error)
            });
    };
    // handleChangWorkStationSelect = (event, option) => {
    //     event.preventDefault();
    //     this.state.inputData.workStation = option.title
    //     this.setState({
    //         workstation: false,
    //         role: false,
    //     })
    //     if (option !== null) {

    //         this.updateComponent();
    //         let roleListUrl = backEndServerURL + "/role/get";
    //         axios.get(roleListUrl, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
    //             .then((response) => {
    //                 console.log(response.data)
    //                 response.data.map((data) => {
    //                     roleRequestType.push({
    //                         title: data.authority,
    //                         key: data.id
    //                     })
    //                 })
    //                 this.setState({
    //                     workstation: true
    //                 })

    //             })
    //             .catch((error) => {
    //                 console.log(error)
    //             })


    //         /*if (option.title === "SD") {
    //             roleRequestType.push(
    //                 {title: 'MAKER'},
    //                 {title: 'CHECKER'},
    //             )
    //         } else if (option.title === "AGENT") {
    //             roleRequestType.push(
    //                 /!* {title: 'CSO'},
    //                  {title: 'BM'},
    //                  {title: 'APPROVAL'},
    //                  {title: 'BOM'},
    //                  {title: 'MAKER'},
    //                  {title: 'CHECKER'},*!/
    //                 {title: 'CSO'},
    //                 {title: 'BM'},
    //             )
    //         } else if (option.title === "CSU") {
    //             roleRequestType.push(
    //                 {title: 'CSO'},
    //                 {title: 'BM'},
    //             )
    //         } else if (option.title === "BRANCH") {
    //             roleRequestType.push(
    //                 {title: 'CSO'},
    //                 {title: 'BM'},
    //                 {title: 'BOM'},
    //             )
    //         }*/

    //     }


    // }
    handleChangRoll = (event, option) => {
        event.preventDefault();
        this.state.inputData.roleId = option;
    };
    handleChangBO = (event, option) => {
        event.preventDefault();
        console.log("workplaceUnitsByRole");
        console.log(option);
        this.state.inputData.workplaceNames = option;
        this.state.inputData.workplaceUnits = "GDLC";
        workplaceUnits = [];
        this.setState({
            getWorkplaceUnits: false,
        });
        /*        if (option !== null) {
                    let Url = backEndServerURL + "/workplaceUnit/getByBranchOrOps/" + option.title;

                    axios.get(Url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);

                            response.data.map((data) => {
                                workplaceUnits.push({
                                    title: data.value,
                                    key: data.key,
                                })
                            });

                            this.setState({
                                getWorkplaceUnits: true
                            });
                            console.log(response.data)
                        })
                        .catch((error) => {
                            console.log(error)
                        });

                    this.setState({
                        role: true,
                    })

                }*/

    };


    renderRole = () => {
        if (this.state.workstation) {
            return (
                <React.Fragment>

                    <Grid item xs={2}>

                        <Grid item>
                            <label className="input-label-common">Role *</label>
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                helperText={this.state.errorMessages["roleId"]}
                                error={this.state.errorArray["roleId"]}
                                required={this.state.inputData.roleId}
                                id="tags-standard"
                                options={roleRequestType}
                                required={true}
                                onChange={(event, option) => this.handleChangRoll(event, option)}
                                getOptionLabel={option => option.title}
                                size="small"
                                renderInput={params => (

                                    <TextField

                                        {...params}
                                        variant="outlined"
                                        error={this.state.errorArray !== undefined && this.state.errorArray["roleId"]}
                                        helperText={this.state.errorArray["roleId"] === true ? this.state.errorMessages["roleId"] : ""}
                                        fullWidth
                                    />

                                )}
                            />

                        </Grid>

                    </Grid>

                </React.Fragment>

            )
        }


    };

    /*
        renderBO = () => {
            if (this.state.getWorkplaceNames) {
                return (
                    <React.Fragment>

                        <Grid item xs={2}>

                            <Grid item>
                                <label className="input-label-common">Branch/Back Office</label>
                            </Grid>
                            <Grid item>
                                <Autocomplete
                                    helperText={this.state.errorMessages["workplaceNames"]}
                                    error={this.state.errorArray["workplaceNames"]}
                                    required={this.state.inputData.workplaceNames}
                                    id="tags-standard"
                                    options={bo}
                                    required={true}
                                    onChange={(event, option) => this.handleChangBO(event, option)}
                                    getOptionLabel={option => option.title}
                                    size="small"
                                    renderInput={params => (

                                        <TextField

                                            {...params}
                                            variant="outlined"
                                            error={this.state.errorArray !== undefined && this.state.errorArray["workplaceNames"]}
                                            helperText={this.state.errorArray["workplaceNames"] === true ? this.state.errorMessages["workplaceNames"] : ""}
                                            fullWidth
                                        />

                                    )}
                                />

                            </Grid>

                        </Grid>

                    </React.Fragment>

                )
            }


        };
    */

    /*
        handleChangWorkPlaceUnit(event, option) {
            event.preventDefault();
            this.state.inputData.workplaceUnits = option;
            if (option != null) {
                console.log(option);

            }
        }
    */

    /*
        renderWorkPlaceUnit = () => {
            if (this.state.getWorkplaceUnits) {
                return (
                    <React.Fragment>

                        <Grid item xs={2}>

                            <Grid item>
                                <label className="input-label-common">WorkPlace Unit</label>
                            </Grid>
                            <Grid item>
                                <Autocomplete
                                    helperText={this.state.errorMessages["workplaceUnits"]}
                                    error={this.state.errorArray["workplaceUnits"]}
                                    required={this.state.inputData.workplaceUnits}
                                    id="tags-standard"
                                    options={workplaceUnits}
                                    required={true}
                                    onChange={(event, option) => this.handleChangWorkPlaceUnit(event, option)}
                                    getOptionLabel={option => option.title}
                                    size="small"
                                    renderInput={params => (

                                        <TextField

                                            {...params}
                                            variant="outlined"
                                            error={this.state.errorArray !== undefined && this.state.errorArray["workplaceUnits"]}
                                            helperText={this.state.errorArray["workplaceUnits"] === true ? this.state.errorMessages["workplaceUnits"] : ""}
                                            fullWidth
                                        />

                                    )}
                                />

                            </Grid>

                        </Grid>

                    </React.Fragment>

                )
            }


        };
    */

    returnJsonForm = () => {

        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, Form, this.updateComponent)
            )
        }
    };

    /*    returnJsonFormBAO = () => {

            if (this.state.getData) {
                return (
                    CommonJsonFormComponent.renderJsonForm(this.state, branchAndBakeOffice, this.updateComponent)
                )
            }
        };*/

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.type} stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h6>Edit User<a><CloseIcon onClick={this.close} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h6>
            )
        } else {
            return (
                <h4 className='text-white'>User Creation </h4>
            )
        }
    };

    render() {
        return (

            <Card>
                <CardHeader style={{
                    backgroundColor: '#142398',
                    //color: '#ffffff'
                }}>
                    {this.renderHeader()}

                </CardHeader>
                <CardBody>
                    <div>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            open={this.state.loading}>
                            <DialogContent>
                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>

                        <ThemeProvider theme={theme}>

                            <Grid container>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    open={this.state.loading}>
                                    <DialogContent>

                                        <center>
                                            <img src={loader} alt=""/>
                                        </center>
                                    </DialogContent>
                                </Dialog>
                                {this.renderNotification()}

                                {this.returnJsonForm()}

                                {/* {this.renderWorkPlaceNames()} */}
                                {/* <Grid item xs={1}>
                                </Grid> */}
                                {/* {this.renderRole()}&nbsp; */}
                                {/*{this.renderBO()}&nbsp;*/}
                                {/*{this.renderWorkPlaceUnit()}&nbsp;*/}

                                <Grid item xs={1}>
                                    {this.renderButton()}
                                </Grid>

                            </Grid>

                        </ThemeProvider>


                    </div>


                </CardBody>
            </Card>


        )
    }

}

export default UserRegistration;