import React, {Component} from "react";
import CloseIcon from "@material-ui/icons/Close";

class MaintenanceUtility extends Component {
    static renderMaintenanceHeader = (freeFlag3, solId, cbNumber, accountNumber, customerName, close, caseId) => {
        return (
            <h4 style={{color: "white"}} >
                {freeFlag3 ? freeFlag3.replaceAll("^", ",") : ""} <b>Case ID:</b>{caseId !== undefined ? caseId : ""}
                <b> SOL Id:</b>{solId !== undefined ? solId : ""} <b> CB
                Number:</b>{cbNumber !== undefined ? cbNumber : ""} <b> A/C
                Number:</b>{accountNumber !== undefined ? accountNumber : ""} <b> A/C
                Name:</b>{customerName !== undefined ? customerName : ""}
                <a><CloseIcon onClick={close} style={{position: 'absolute', right: 10, color: "#000000"}}/></a>
            </h4>
        )
    };
}

export default MaintenanceUtility