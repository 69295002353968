import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import theme from "../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import {backEndServerURL} from "../../Common/Constant";
import CircularProgress from "@material-ui/core/CircularProgress";


class SignatureCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            accountNumber: this.props.id !== undefined ? this.props.id : 0,
        }
    }


    componentDidMount() {

    }

    renderImage = () => {
        if(this.props.signatureSource==="ABABIL"){
            let url = backEndServerURL + '/intgr/signature/' + this.state.accountNumber + "/" + `${sessionStorage.getItem("accessToken")}`;
            return <img onLoad={this.stopLoading} style={{width: '100%', height: 'auto'}} src={url}/>
        }
        else {
            let url = backEndServerURL + '/intgr/signature/' + this.state.accountNumber + "/" + `${sessionStorage.getItem("accessToken")}`;
            return <img onLoad={this.stopLoading} style={{width: '100%', height: 'auto'}} src={url}/>

         }


    };

    stopLoading = () => {
        this.setState({
            loader: false
        })
    };


    render() {

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Preview Signature<a><CloseIcon onClick={this.props.closeModal} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></h4>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <Grid container spacing={3}>
                                    <ThemeProvider theme={theme}>
                                        {this.state.loader && <CircularProgress style={{marginLeft: '50%'}}/>}
                                        {
                                            this.renderImage()
                                        }
                                    </ThemeProvider>
                                </Grid>
                            </div>

                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );

    }


}

export default SignatureCard;