import React, {Component} from "react";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import 'semantic-ui-offline/semantic.min.css';
import {commonInboxButton, commonInboxTabActive, commonInboxTabInactive} from "../configuration";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import TableComponent from "../../workflow/CommonComponent/TableComponent";
import loader from "../../../Static/loader.gif";
import axiosDownload from "../FunctionForFileDownload";
import Button from "@material/react-button";
import {Menu} from "semantic-ui-react";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class BulkDownload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            getData: true,
            inputData: {},
            selectedRowIdArray: [],
            dataPassArray: [],
            notificationMessage: "",
            alert: false,
            activeItem: "NEW",
            pageNumber: 0,
            totalRow: 0,
            rowsPerPage: 100,
            loader: true,
            allData: [],
            allColumns: [
                {title: "Client ID", field: "clientId"},
                {title: "Card Number", field: "cardNumber"},
                {title: "Signature Card Downloaded", field: "signature"},
                {title: "Photo Id Downloaded", field: "photoId"}
            ],
        };
    }

    fetchDataFromAPI = (status, url, pageNumber, bool) => {
        let page = 0;
        if (bool !== undefined) {
            page = pageNumber;
        } else {
            page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        }
        let inbox_url = backEndServerURL + "/" + url + status + "/" + page;
        axios.post(inbox_url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(response => {
            let array = [];
            let rowData;
            for (let i = 0; i < response.data.content.length; i++) {
                let res = response.data.content[i];
                rowData = {
                    id: res.id,
                    appId: res.appDelId,
                    clientId: res.clientId,
                    cardNumber: res.cardNo,
                    // signature: this.downloadSignatureCard(res.id),
                    // photoId: this.downloadPhotoIdCard(res.id),
                    signature: res.signatureCardDownloadFlag === 0 ? "NO" : "YES",
                    photoId: res.photoIdDownloadFlag === 0 ? "NO" : "YES"
                };
                array.push(rowData);
            }

            this.setState({
                activeItem: status,
                allData: array,
                rowsPerPage: response.data.numberOfElements,
                pageNumber: response.data.number,
                totalRow: response.data.totalElements,
                loader: false
            });
        }).catch(error => {
            console.log(error);
            this.setState({
                loader: false
            })
        });
    };

    componentDidMount() {
        this.fetchDataFromAPI("NEW", "getNewPrepaidCardFile/", 0, false)
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    headerSelect = (event, value) => {
        event.preventDefault();
        this.setState({
            loader: true,
            activeItem: value
        });
        this.fetchDataFromAPI(value, "getNewPrepaidCardFile/", 0, false)
    };


    downloadSignatureCard = (id) => {
        return (
            <>
                <Button
                    style={commonInboxButton}
                    onClick={(event) => this.onProceedButtonClick([id], "Signature Card")}
                >
                    Download Signature
                </Button>
            </>
        )
    };

    downloadPhotoIdCard = (id) => {
        return (
            <>
                <Button
                    style={commonInboxButton}
                    onClick={(event) => this.onProceedButtonClick([id], "Photo Id")}
                >
                    Download Photo ID
                </Button>
            </>
        )
    };

    onProceedButtonClick = (id, type) => {
        this.setState({
            loader: true
        });
        let nDate = new Date();
        let d = nDate.getDate() + "_" + nDate.getMonth() + "_" + nDate.getFullYear()
        let downloadXML = backEndServerURL + "/getPrepaidCardFilePdf/" + type;
        let fileName = `${type}_${d}.zip`;
        axiosDownload.downloadFile(downloadXML, 'POST', id, fileName).then(response => {
            this.fetchDataFromAPI('NEW', "getNewPrepaidCardFile/", this.state.pageNumber, false)
        }).catch(error => {
            console.log(error);
            this.setState({
                loader: false
            })
        });

    };


    render() {
        const {classes} = this.props;
        return (
            <div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >Instant Prepaid Card Issuance Tracker</h4>
                    </CardHeader>
                    <CardBody>
                        <div className="deliverable">
                            <React.Fragment>
                                <div>
                                    {this.tableChoice()}
                                </div>
                            </React.Fragment>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }


    renderTopLabelButton = () => {
        if(this.state.activeItem === "NEW"){
            return [
                {
                    name: "Load All Data",
                    triggerFunction: this.renderUrl
                },
                {
                    name: "Download Signature",
                    triggerFunction: this.downloadSignature
                },
                {
                    name: "Download Photo Id",
                    triggerFunction: this.downloadPhotoId
                }
            ]
        }
    };

    downloadSignature = (event, data) => {
        let rowIdArray = [];
        for (let item of data) {
            rowIdArray.push(item.id)
        }
        if (rowIdArray.length > 0) {
            this.onProceedButtonClick(rowIdArray, "Signature Card")
        }
    };

    downloadPhotoId = (event, data) => {
        let rowIdArray = [];
        for (let item of data) {
            rowIdArray.push(item.id)
        }
        if (rowIdArray.length > 0) {
            this.onProceedButtonClick(rowIdArray, "Photo Id")
        }
    };

    handleChangePage = (pageNumber, status, bool) => {
        this.fetchDataFromAPI(this.state.activeItem, "getNewPrepaidCardFile/", pageNumber, false)
    };

    renderMenu = () => {
        return (
            <div style={{marginRight: "8px"}}>
                <Menu pointing style={{overflowX: 'auto', overflowY: 'hidden'}}>
                    <Menu.Item
                        name="New"
                        active={this.state.activeItem === "NEW"}
                        style={this.state.activeItem === "NEW" ? commonInboxTabActive : commonInboxTabInactive}
                        onClick={e => {
                            this.headerSelect(e, "NEW");
                        }}
                    />
                    <Menu.Item
                        name="Downloaded"
                        active={this.state.activeItem === "Downloaded"}
                        style={this.state.activeItem === "Downloaded" ? commonInboxTabActive : commonInboxTabInactive}
                        onClick={e => {
                            this.headerSelect(e, "Downloaded");
                        }}
                    />
                </Menu>
            </div>
        );
    };


    tableChoice() {
        const {classes} = this.props;
        const {activeItem, allColumns} = this.state;
        if (this.state.loader) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loader}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <>
                    {this.renderMenu()}
                    <TableComponent tableData={this.state.allData}
                                    tableColumns={allColumns}
                                    loader={this.state.loading} totalRow={this.state.totalRow}
                                    page={this.state.pageNumber}
                                    whiteSpace={"wrap"} selection={this.state.activeItem === "NEW"}
                                    handleChangePage={this.handleChangePage}
                                    rowsPerPage={this.state.rowsPerPage} tableButton={this.renderTopLabelButton()}
                    />
                </>
            )
        }
    }
}

export default withStyles(styles)(BulkDownload);
