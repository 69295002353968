import React from "react";
import FormSample from '../JsonForm/FormSample';
import CardHeader from "../Card/CardHeader";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from 'axios';
import {processmakerServerURL} from "../../Common/Constant";
import cookie from 'react-cookies';
import Functions from '../../Common/Functions';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
};


const jsonForm = {
    "variables": [


        {
            "varName": "firstName",
            "type": "text",
            "label": "First Name",

        },
        {
            "varName": "lastName",
            "type": "text",
            "label": "Last Name",

        },
        {
            "varName": "email",
            "type": "text",
            "label": "Email",

        },
        {
            "varName": "password",
            "type": "password",
            "label": "Password",

        },


    ],

};
const jsonForm1 = {
    "variables": [


        {
            "varName": "firstName",
            "type": "text",
            "label": "First Name",
            "required": true,

        },
        {
            "varName": "lastName",
            "type": "text",
            "label": "Last Name",
            "required": true,

        },
        {
            "varName": "userName",
            "type": "text",
            "label": "User Name",
            "required": true,

        },
        {
            "varName": "email",
            "type": "text",
            "label": "Email",
            "required": true,

        },
        {
            "varName": "userNewPass",
            "type": "password",
            "label": "New Password",
            "required": true,


        },
        {
            "varName": "userCnfPass",
            "type": "password",
            "label": "Confirm Password",
            "required": true,


        },


    ],

};

class EditUserProfile extends React.Component {
    renderEditForm = (() => {
        if (this.state.editUserProfile) {
            return (

                <FormSample secondButtonFunction={this.props.secondButtonFunction}
                            secondButtonName={this.props.secondButtonName} showValue={true}
                            varValue={this.state.varValue} grid="12"
                            buttonName="Edited"
                            onSubmit={this.getSubmitedForm} jsonForm={jsonForm}/>


            )
        } else if (this.props.userUid === undefined) {
            return (


                <FormSample secondButtonFunction={this.props.secondButtonFunction}
                            secondButtonName={this.props.secondButtonName} grid="12" buttonName="Submit"
                            onSubmit={this.getSubmitedForm}
                            jsonForm={jsonForm1}/>


            )
        } else {

        }
    });

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            userUid: '',
            userName: '',
            firstName: '',
            lastName: '',
            password: '',
            email: '',
            multiline: 'Controlled',
            errorMessage: " ",
            redirect: false,
            editUser: true,
            open: false,
            confirmEdit: false,
            vertical: 'top',
            horizontal: 'center',
            editUserProfile: false,
            varValue: [],
            userNewPass: '',
            userCnfPass: '',
            userDueDate: "2090-12-31",
            userRole: "PROCESSMAKER_MANAGER",
            userStatus: 'ACTIVE',
            showPassword: false,
            redirectLogin: false,
        }
    }

    getSubmitedForm = (object) => {
        if (this.props.userUid !== undefined) {
            var url = processmakerServerURL + "/api/1.0/workflow/user/" + this.props.userUid;

            axios.put(url, {
                    usr_firstname: object.firstName,
                    usr_lastname: object.lastName,
                    usr_email: object.email,
                    usr_new_pass: object.password,
                    usr_cnf_pass: object.password,
                }
                , {headers: {"Authorization": "Bearer " + cookie.load("accessToken")}})
                .then((response) => {

                    this.props.closeModal();
                    this.props.editCloseModal();

                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                });


        } else {
            let url = processmakerServerURL + '/api/1.0/workflow/user/';

            let authHeader = "Bearer " + cookie.load("accessToken");
            axios.post(url,
                {
                    usr_username: object.userName,
                    usr_firstname: object.firstName,
                    usr_lastname: object.lastName,
                    usr_email: object.email,
                    usr_new_pass: object.userNewPass,
                    usr_cnf_pass: object.userCnfPass,
                    usr_due_date: "2040-01-01",
                    usr_status: "ACTIVE",
                    usr_role: "PROCESSMAKER_ADMIN"

                },
                {headers: {"Authorization": authHeader}})
                .then((response) => {

                    this.props.closeModal();
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                });
        }


    };

    componentDidMount() {
        if (this.props.userUid !== undefined) {
            let url = processmakerServerURL + "/api/1.0/workflow/user/" + this.props.userUid;

            axios.get(url, {headers: {"Authorization": "Bearer " + cookie.load("accessToken")}})
                .then(response => {

                    let varValue = [];
                    varValue["firstName"] = response.data.usr_firstname;
                    varValue["lastName"] = response.data.usr_lastname;
                    varValue["email"] = response.data.usr_email;
                    this.setState({
                        varValue: varValue,
                        editUserProfile: true

                    })

                })

                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                });
        }

    }

    render() {
        {

            Functions.redirectToLogin(this.state)

        }

        const {classes} = this.props;
        return (
            <GridContainer>

                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>{this.props.name}</h4>
                        </CardHeader>
                        <CardBody>
                            <div>
                                {this.renderEditForm()}

                            </div>

                        </CardBody>
                    </Card>
                </GridItem>


            </GridContainer>

        )
    }
}

export default withStyles(styles)(EditUserProfile);
