import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Functions from "../../../Common/Functions";
import Table from "../../Table/Table";
import SelectComponent from "../../JsonForm/SelectComponent";
import TextFieldComponent from "../../JsonForm/TextFieldComponent";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Notification from "../../NotificationMessage/Notification";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CloseIcon from '@material-ui/icons/Close';
import CardBody from "../../Card/CardBody";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import {CSinWardJasonFormRemitance,} from '../WorkflowJsonFormArin';
import loader from "../../../Static/loader.gif";
import UploadComponent from "../Maintenance/casa/UploadComponent";
import DateComponentDeferal from "../../JsonForm/DateComponentDeferal";
import CBNotFound from "../CASA/CBNotFound";
import MyValidation from "../../JsonForm/MyValidation";

let abhMaker = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];

const fileIndexList = [
    {label: 'Document 1'},
    {label: 'Document 2'},
    {label: 'Document 3'},
    {label: 'Document 4'},
    {label: 'Document 5'},
    {label: 'Document 6'},
    {label: 'Document 7'},
    {label: 'Document 8'},
    {label: 'Document 9'},
    {label: 'Document 10'},
    {label: 'Document 11 '},
    {label: 'Document 12'},
    {label: 'Document 13 '},
    {label: 'Document 14'},
    {label: 'Document 15'},
    {label: 'Document 16'},
    {label: 'Document 17'},
    {label: 'Document 18'},
    {label: 'Document 19'},
    {label: 'Document 20'},
];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};


var deferalOther = {
    "varName": "deferalOther",
    "type": "text",
    "label": "Remarks",

};

var deferal = {
    "varName": "deferalType",
    "type": "select",
    "label": "Deferral Type",
    "enum": [
        "Applicant Photograph",
        "Nominee Photograph",
        "Passport",
        "Address proof",
        "Transaction profile",
        "Others"
    ],

};

var date = {
    "varName": "expireDate",
    "type": "text",
    "minDate": true,
    "label": "Expire Date",
};

class InwardCs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            values: [],
            appId: 0,
            message: "",
            appData: {},
            getData: false,
            getNewCase: false,
            varValue: {},
            caseId: "",
            title: "",
            notificationMessage: "",
            alert: false,
            redirectLogin: false,
            loading: true,
            type: [],
            cbNotFound: false,
            dueDate: '',
            inputData: {
                cs_deferal: "NO",
            },
            fileUploadData: [],
            selectedDate: {},
            showValue: false,
            getDeferalList: [],
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            uploadButtonClick: false,
            getAllApprovalOfficer: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    addDeferalForm() {
        return this.state.values.map((el, i) =>
            <React.Fragment>
                <Grid item xs={3}>
                    {
                        this.dynamicDeferral(el)
                    }
                </Grid>

                <Grid item xs={3}>
                    {
                        this.dynamicDate(el)
                    }

                </Grid>
                <Grid item xs={3}>
                    {this.dynamicDeferralOther(el)}

                </Grid>
                <Grid item xs={3}>
                    <button
                        style={{float: "left", marginTop: "15px"}}
                        className="btn btn-outline-primary btn-sm"
                        type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                    >
                        Remove
                    </button>
                </Grid>
            </React.Fragment>
        )

    }

    getSearchvalue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '')
                delete clone[prop];
        return clone;
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    componentDidMount() {
        var remarksArray = [];
        let caseClaim = backEndServerURL + "/case/claimReturn/" + this.props.appId;
        axios.post(caseClaim, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((claimResponse) => {
            this.state.inputData["cs_deferal"] = "NO";
            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + claimResponse.data;
            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                response.data.map((data) => {
                    remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                });
                this.setState({
                    getRemarks: remarksArray
                })
            }).catch((error) => {
                console.log(error)
            });

            let url = backEndServerURL + '/variables/' + claimResponse.data;

            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                let deferalListUrl = backEndServerURL + "/case/deferral/" + claimResponse.data;
                axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    let tableArray = [];
                    var status = "";
                    response.data.map((deferal) => {
                        if (deferal.status === "ACTIVE") {
                            status = "Approved"
                        } else {
                            status = deferal.status
                        }
                        tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));
                    });
                    this.setState({
                        getDeferalList: tableArray
                    });
                    console.log(this.state.getDeferalList)
                }).catch((error) => {
                    console.log(error);
                });

                this.state.inputData = response.data;
                this.state.inputData["cs_deferal"] = "NO";
                let varValue = response.data;
                varValue.caseId = this.props.caseId ? this.props.caseId : response.data.caseId;
                this.setState({
                    getData: true,
                    varValue: varValue,
                    appData: response.data,
                    inputData: varValue,
                    showValue: true,
                    appId: claimResponse.data,
                    loading: false
                });

            }).catch((error) => {
                console.log(error);
                if (error.response.status === 252) {
                    Functions.removeCookie();
                    this.setState({
                        redirectLogin: true
                    })
                }
            });
        }).catch((error) => {
            console.log(error)
        });
    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }

    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    renderAddButtonShow = () => {
        return (
            <React.Fragment>
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',
                    }}
                    type='button' value='add more'
                    onClick={this.addClick.bind(this)}
                >Add Deferral
                </button>
            </React.Fragment>
        )
    };

    dynamicDeferral = (i) => {
        let field = JSON.parse(JSON.stringify(deferal));
        field.varName = "deferalType" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };

    dynamicDeferralOther = (i) => {
        if (this.state.inputData["deferalType" + i] === "Others") {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        } else {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        }
    };

    dynamicDate = (i) => {
        let field = JSON.parse(JSON.stringify(date));
        field.varName = "expireDate" + i;
        return DateComponentDeferal.date(this.state, this.updateComponent, field);
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    handleChangeComments = () => {

    };

    returnJsonForm = () => {
        if (this.state.showValue) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, CSinWardJasonFormRemitance, this.updateComponent)
            )
        }

    };


    handleSubmit = (event) => {
        event.preventDefault();
        let deferralError = MyValidation.deferralValidation(this.state.values, this.state);
        this.forceUpdate();

        if (deferralError === true) {
            return 0;
        } else {
            this.setState({
                loading: true
            });
            let imageUrl = backEndServerURL + "/case/files/" + this.state.appId;
            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.data.length > 0) {
                        this.functionForFileCheck()
                    } else {
                        this.setState({
                            loading: false,
                            title: "File upload is mandatory!",
                            cbNotFound: true
                        })
                    }
                }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });
            })
        }
    };


    functionForFileCheck = () => {
        if (this.state.values.length > 0) {
            this.state.inputData["csDeferal"] = "YES";
        } else {
            this.state.inputData["csDeferal"] = "NO";
        }
        if (this.state.inputData["cs_deferal"] === "YES") {
            var defType = [];
            var expDate = [];
            var remarks = [];

            let appId = this.state.appId;
            for (let i = 0; i < this.state.values.length; i++) {
                let value = this.state.values[i];
                let defferalType = this.state.inputData["deferalType" + value];
                let defRemarks = this.state.inputData["deferalOther" + value] ? this.state.inputData["deferalOther" + value] : "";
                remarks.push(defRemarks);
                defType.push(defferalType);
                let expireDate = this.state.inputData["expireDate" + value];
                expDate.push(expireDate);
            }

            let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";
            axios.post(deferalRaisedUrl, {
                appId: appId,
                type: defType,
                dueDate: expDate,
                remarks: remarks
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log('Deferal');
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    });
                })
        }
        let data = this.state.inputData;
        data.cs_deferal = this.state.inputData["cs_deferal"];
        data.serviceType = this.props.serviceType;
        data.subServiceType = this.props.subServiceType;
        data.freeFlag1 = this.state.inputData.iwAccountCurrency;
        data.freeFlag2 = this.state.inputData.iwAmount;
        data.category = 'Remittance';
        data.subCategory = 'Inward';
        data.customerName = this.state.inputData.iwBeneficiaryName;
        let remarksData = {};
        remarksData.remark = this.state.inputData.csRemarks;
        remarksData.map = {...data, csRemarks: undefined};
        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
        axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            this.setState({
                title: "Successful!",
                notificationMessage: "Successfully Routed!",
                alert: true,
                loading: false
            });
            if (this.props.renderTableData !== undefined) {
                this.props.renderTableData(0);
                this.props.closeModal();
            } else {
                this.props.closeModal();
            }
        }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false
            })
        });

    };

    handleSubmitDraft = (event) => {
        event.preventDefault();
        let error = MyValidation.deferralValidation(this.state.values, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true
            });
            if (this.state.values.length > 0) {
                this.state.inputData["csDeferal"] = "YES";
            } else {
                this.state.inputData["csDeferal"] = "NO";
            }

            if (this.state.inputData["cs_deferal"] === "YES") {
                var defType = [];
                var expDate = [];
                var remarks = [];
                let appId = this.state.appId;
                for (let i = 0; i < this.state.values.length; i++) {
                    let value = this.state.values[i];
                    let defferalType = this.state.inputData["deferalType" + value];
                    let defRemarks = this.state.inputData["deferalOther" + value] ? this.state.inputData["deferalOther" + value] : "";
                    remarks.push(defRemarks);
                    defType.push(defferalType);
                    let expireDate = this.state.inputData["expireDate" + value];
                    expDate.push(expireDate);
                }

                let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";
                axios.post(deferalRaisedUrl, {
                    appId: appId,
                    type: defType,
                    dueDate: expDate,
                    remarks: remarks
                }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        });
                    })
            }
            var variableSetUrl = backEndServerURL + "/save/" + this.state.appId;

            let data = this.state.inputData;
            data.cs_deferal = this.state.inputData["cs_deferal"];
            data.serviceType = this.props.serviceType;
            data.subServiceType = this.props.subServiceType;
            data.category = 'Remittance';
            data.freeFlag1 = this.state.inputData.iwAccountCurrency;
            data.freeFlag2 = this.state.inputData.iwAmount;
            data.customerName = this.state.inputData.iwBeneficiaryName;
            data.subCategory = 'Inward';

            axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        loading: false,
                        title: "Successful!",
                        notificationMessage: "Successfully Save!",
                        alert: true
                    });
                    if (this.props.renderTableData !== undefined) {
                        this.props.renderTableData(0);
                        this.close();
                    } else {
                        this.close();
                    }

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    });
                });
        }
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    addClick() {
        let randomNumber = Math.floor(Math.random() * 100000000000);

        this.setState(prevState => ({
            values: [...prevState.values, randomNumber],

        }));

        this.state.inputData["cs_deferal"] = "YES";
    }

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, abhMaker, this.updateComponent)
            )
        }
    };

    removeClick(i, event) {
        event.preventDefault();
        let pos = this.state.values.indexOf(i);
        if (pos > -1) {
            this.state.values.splice(pos, 1);
            this.updateComponent();
            if (this.state.values.length > 0) {
                this.state.inputData["cs_deferal"] = "YES"
            } else {
                this.state.inputData["cs_deferal"] = "NO"
            }
        }


    }


    close = () => {
        this.props.closeModal();
    };

    uploadFunction = () => {
        const {classes} = this.props;
        return <UploadComponent indexList={fileIndexList} appId={this.state.appId} classes={classes}/>
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    render() {

        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >Inward Remittance Information<a><CloseIcon onClick={this.close} style={{
                            position: 'absolute',
                            right: 10,
                            color: "#000000"
                        }}/></a></h4>
                    </CardHeader>
                    <CardBody>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.cbNotFound}>
                            <DialogContent className={classes.dialogPaper}>
                                <CBNotFound
                                    closeModal={this.closeModalCBNotFound}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        <div>
                            <ThemeProvider theme={theme}>

                                <Grid container spacing={1}>
                                    {this.renderNotification()}
                                    {this.returnJsonForm()}
                                    <Grid item xs={12}>

                                    </Grid>
                                    <Grid item xs={12}>
                                        {this.renderAddButtonShow()}
                                    </Grid>
                                    {this.addDeferalForm()}
                                    <Grid item xs={12}>
                                        {
                                            this.uploadFunction()
                                        }
                                    </Grid>
                                    <Grid item xs='12'>
                                        {this.renderRemarks()}
                                    </Grid>
                                    <Grid item xs='12'>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <button
                                            className="btn btn-outline-primary btn-sm"
                                            style={{}}
                                            onClick={this.handleSubmit}
                                        >
                                            Submit
                                        </button>
                                        &nbsp;
                                        <button
                                            className="btn btn-outline-info btn-sm"
                                            style={{}}
                                            onClick={this.handleSubmitDraft}
                                        >
                                            Save
                                        </button>
                                    </Grid>
                                </Grid>
                            </ThemeProvider>
                        </div>
                    </CardBody>
                </Card>
            );
        }

    }

}

export default withStyles(styles)(InwardCs);
