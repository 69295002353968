import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import "semantic-ui-offline/semantic.min.css";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import CBNotFound from "../../workflow/CASA/CBNotFound";
import CloseIcon from "@material-ui/icons/Close";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#000",
        },
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: "auto",
    },
    Tab: {
        flexDirection: "row-reverse",
    },
    progress: {
        margin: 50,
    },
};

let jsonForm = [
    {
        varName: "accountType",
        type: "select",
        enum: [
            "Savings",
            "Current",
            "Ababil",
        ],
        onKeyDown: true,
        label: "Select Account Type",
        grid: 2,
        required: true,
    },
    {
        varName: "schemeCode",
        type: "autoCompleteValueReturn",
        enum: [],
        onKeyDown: true,
        label: "Select Scheme Code",
        grid: 2,
        required: true,
        conditional: true,
        conditionalVarName: "showSchemeCode",
        conditionalVarValue: true
    },
    {
        varName: "leafNumber",
        type: "select",
        enum: ["10", "25", "50", "100"],
        label: "Leaf Number",
        grid: 2,
        required: true,
        conditional: true,
        conditionalVarName: "showLeafNumber",
        conditionalVarValue: true
    }
];

class SchemeAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            showValue: false,
            disabled: false,
            varValue: {},
            inputData: {},
            title: "",
            notificationMessage: "",
            loading: true,
            cbNotFound: false,
            alert: false,
            getData: false,
            bankName: []
        };
    }


    componentDidMount() {
        this.setState({
            getData: true,
            loading: false
        })
    }


    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type="success"
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false,
        });
    };

    copyJson = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === " " ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    handleSubmit = () => {
        let error = MyValidation.defaultValidation(jsonForm, this.state);
        this.forceUpdate();
        if (error === true) {
            return false;
        } else {
            this.setState({
                disabled: true,
                loading: true,
            });
            let url = backEndServerURL + "/schemeLeafConfigurations";
            axios.post(url, this.copyJson(this.state.inputData), {
                headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`},
            })
                .then((response) => {
                    this.props.closeModal();
                    this.setState({
                        disabled: false,
                        loading: false,
                        title: response.data,
                        cbNotFound: true,
                    });
                })
                .catch((error) => {
                    this.setState({
                        disabled: false,
                        loading: false,
                        title: error.response.data.message,
                        cbNotFound: true,
                    });
                });
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
        });
    };

    renderHeader = () => {
        if (this.props.closeModal !== undefined) {
            return (
                <h4 style={{color: "white"}} >
                    Scheme Code and Leaf Add{" "}
                    <a>
                        <CloseIcon
                            onClick={this.props.closeModal}
                            style={{
                                position: "absolute",
                                right: 10,
                                color: "#000000",
                            }}
                        />
                    </a>
                </h4>
            );
        } else {
            return <h4 style={{color: "white"}} >Scheme Code and Leaf Add</h4>;
        }
    };

    onKeyDownChange = (data) => {
        if (data.varName === "accountType") {
            let inputData = {...this.state.inputData};
            inputData.showSchemeCode = false;
            inputData.showLeafNumber = false;
            this.setState({
                inputData: inputData,
                varValue: this.copyJson(inputData),
                loading: true
            });
            let schemeType = this.state.inputData.accountType === "Current" ? "currentSchemeCode" : "savingsSchemeCode";
            let codeUrl;
            if (this.state.inputData.accountType === "Ababil") {
                codeUrl = backEndServerURL + "/getAccountSchemeCodeListAbabil";
            } else {
                codeUrl = backEndServerURL + "/finMasterData/" + schemeType;
            }
            axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    for (let item of jsonForm) {
                        if (item.varName === "schemeCode") {
                            item.enum = response.data;
                            break;
                        }
                    }
                    inputData.showSchemeCode = true;
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        loading: false
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })
        }
        if (data.varName === "schemeCode") {
            let inputData = {...this.state.inputData};
            inputData.showLeafNumber = true;
            this.setState({
                inputData: inputData,
                varValue: this.copyJson(inputData),
            });
        }
    };

    renderForm = () => {
        if (this.state.getData) {
            return (
                <>
                    {CommonJsonFormComponent.renderJsonForm(
                        this.state,
                        jsonForm,
                        this.updateComponent,
                        "",
                        this.onKeyDownChange
                    )}
                    {this.renderNotification()}
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={this.handleSubmit}
                        style={{
                            height: 25,
                            marginTop: "19px"
                        }}
                        disabled={this.state.disabled}
                    >
                        Submit
                    </button>
                </>
            );
        }
    };

    render() {
        const {classes} = this.props;
        return (
            <div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>{this.renderHeader()}</CardHeader>
                    <CardBody>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}
                        >
                            <DialogContent className={classes.dialogPaper}>
                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.cbNotFound}
                        >
                            <DialogContent className={classes.dialogPaper}>
                                <CBNotFound
                                    closeModal={this.closeModalCBNotFound}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        <Grid container>
                            <ThemeProvider theme={theme}>{this.renderForm()}</ThemeProvider>
                        </Grid>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(SchemeAdd);
