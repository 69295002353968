function makeReadOnlyObject(object) {
    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject;

    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["readOnly"] = true;
    }
    return returnObject;
}

let DescendMarkJsonForm = [

    {
        varName: "customerCB",
        type: "textApiCall",
        label: "Customer CB",
        required: true,
        grid: 2,
    },
    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
        grid: 2,
    },

    {
        varName: "loanAccounts",
        type: "select",
        label: "Loan Accounts",
        grid: 3,
        required: true,
        "enum": [],
        "onKeyDown": true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "productName",
        "type": "textApiCall",
        "required": true,
        "label": "Product Name",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "loanOutstanding",
        "type": "textApiCall",
        "required": true,
        "label": "Loan Outstanding",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },

    {
        type: "empty",
        grid: 12,
    },

];

let DescendMarkReadOnlyForm = {};
DescendMarkReadOnlyForm = makeReadOnlyObject(JSON.parse(JSON.stringify(DescendMarkJsonForm)));

module.exports = {
    DescendMarkJsonForm,
    DescendMarkReadOnlyForm
};