import React from "react";
import axios from "axios";
import Table from "../../Table/Table";
import CardHeader from "../../Card/CardHeader";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import MakerCumInput from "../../workflow/CASA/MakerCumInput";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Card from "../../Card/Card";
import withStyles from "@material-ui/core/styles/withStyles";

import SecondaryJointCbTagging from "../../workflow/CASA/SecondaryJointCbTagging";
import {backEndServerURL} from "../../../Common/Constant";
import Grid from "@material-ui/core/Grid";



const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};


class InputDTO extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            appData: {},
            getData: false,
            varValue: [],
            showValue: false,
            redirectLogin: false,
            title: "",
            notificationMessage: "",
            alert: false,
            inputData: {},
            selectedDate: {},
            SelectedDropdownSearchData: null,
            dropdownSearchData: {},
            values: [],
            customerName: [],
            deferalType: [],
            expireDate: [],
            other: [],
            getCheckerList: [],
            getAllDefferal: [],
            getDeferalList: [],
            loading: true,
            jointAccountCustomerNumber: 0,
            getCbNumber: 0,
            objectForJoinAccount: [],
            getgenerateForm: false,
            renderCumModalopen: false,
            renderCumModalopenNew: false,
            relatedPartyType: "",
            CBGenerateModal: false,
            generateAccountNo: '',
            getDedupData: {},
            jointDedupData: {},
            jointSearchTableData: [],
            individualDataList: [],
            jointDataList: [],
            mandateDataList: [],
            beneficiaryDataList: [],
            dedupData: [],
            relatedData: {},
            CustomerModal: false,
            IDENTIFICATION_NO: '',
            searchTableData: false,
            searchTableRelatedData: false,
            getRadioButtonData: {},
            tagingModalCbnumber: '',
            getCustomerNumber: '',
            err: false,
            errorArray: {},
            errorMessages: {},
            SdnAndDedupeResultShowing: false,


        };


    }

    closeCustomerModal = () => {
        this.setState({
            CustomerModal: false
        })
    };


    radioButtonChange = (event, customerId) => {
        let variable = {};
        console.log(event.target.name);
        console.log(event.target.value);
        variable[event.target.name] = event.target.value;
        this.setState({
            getCbNumber: event.target.value,
            cbNumber: customerId,
            getRadioButtonData: variable,
            getCustomerNumber: event.target.name.slice(-1)
        })


    };


    tagingModal = (event, index, customerid, relatedPartyType) => {
        this.setState({
            //cbNumber: customerid,
            generateAccountNo: "",
            renderCumModalopen: true,
            tagingModalCbnumber: index,
            cbNumber: customerid,
            relatedPartyType: relatedPartyType
        })
    };

    taging = (index, data) => {
        let value = index + 1;
        if (data !== undefined) {
            this.props.getTaggingData("tagging" + value, data, value);
        } else {
            this.props.getTaggingData("tagging" + value, this.state.getRadioButtonData["tagging" + value], value);
        }


    };

    createTableDataForIndividual = (cbNumber, accountNumber, customerName, nid, smartCard, passport, birthCer, drivingLicense,dateOfBirth,phone,email,eTin,nationality  ) => {
        return ([cbNumber, accountNumber, customerName, nid, smartCard, passport, birthCer, drivingLicense,dateOfBirth,phone,email,eTin,nationality ])


    };
    createTableDataForJointCbAndAccounts = (cbNumber, accountNumber   ) => {
        return ([cbNumber, accountNumber])


    };



    componentDidMount() {

        /*  this.setState({
              loading: true
          })*/
        console.log("----------------jjj------------")
         let tableArray=[];
        let tableArrayForMandate=[];
        let tableArrayForBeneficiary=[];
        let jointCbOrAccounts=[];
        if (this.props.appId !== undefined) {
            if(this.props.inputData.individualDedupData!==undefined){
                let dedupDataViewUrl = backEndServerURL + "/dedup/get/" +(this.state.appId===undefined?this.props.appId:this.state.appId) +"/"  + this.props.inputData.individualDedupData;
                axios.get(dedupDataViewUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        let getData=response.data.individualDedupData;
                        let allDataList=this.props.inputData;
                        if(getData!==undefined){
                            getData=getData.indDedupDto;
                        }
                        tableArray.push(this.createTableDataForIndividual(getData.cbNumber, getData.accountNumber,getData.customerName,getData.nid,getData.smartCard,getData.passport,getData.registrationNo,getData.drivingLicense,getData.dob,getData.phone!==undefined && getData.phone!=="88"?getData.phone:"",getData.email,getData.tin,getData.nationality));

                        if(this.state.inputData.mandate === "YES") {
                            tableArrayForMandate.push(this.createTableDataForIndividual(allDataList.mandatecbNumber, allDataList.mandateaccountNumber,allDataList.mandatecustomerName,allDataList.mandatenid,allDataList.mandatesmartCard,allDataList.mandatepassport,allDataList.mandateregistrationNo,allDataList.mandatedrivingLicense,allDataList.mandatedob,allDataList.mandatephone!==undefined && allDataList.mandatephone!=="88"?allDataList.mandatephone:"",allDataList.mandateemail,allDataList.mandatetin,allDataList.mandatenationality!==undefined?allDataList.mandatenationality.key!==undefined?allDataList.mandatenationality.key:"":""));
                        }
                        if (this.state.inputData.beneficiary === "YES") {
                            tableArrayForBeneficiary.push(this.createTableDataForIndividual(allDataList.beneficiarycbNumber, allDataList.beneficiaryaccountNumber,allDataList.beneficiarycustomerName,allDataList.beneficiarynid,allDataList.beneficiarysmartCard,allDataList.beneficiarypassport,allDataList.beneficiaryregistrationNo,allDataList.beneficiarydrivingLicense,allDataList.beneficiarydob,allDataList.beneficiaryphone!==undefined && allDataList.beneficiaryphone!=="88"?allDataList.beneficiaryphone:"",allDataList.beneficiaryemail,allDataList.beneficiarytin,allDataList.beneficiarynationality!==undefined?allDataList.beneficiarynationality.key!==undefined?allDataList.beneficiarynationality.key:"":""));
                        }
                        this.setState({

                            individualDataList: tableArray,
                            mandateDataList: tableArrayForMandate,
                            beneficiaryDataList: tableArrayForBeneficiary,
                            //relatedData: relatedTableArray,
                            searchTableData: true,
                            searchTableRelatedData: true,
                            //getsearchValue: object,
                            loading: false,

                        })

                    })
                    .catch((error)=>{
                        console.log(error);
                    })
            }

            else if(this.props.inputData.jointDedupData!==undefined){
                let dedupDataViewUrl = backEndServerURL + "/dedup/get/" +(this.state.appId===undefined?this.props.appId:this.state.appId) +"/"  + this.props.inputData.jointDedupData;
                axios.get(dedupDataViewUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        let getDataList=response.data.jointDedupData;
                         let allDataList=this.props.inputData;
                        if(getDataList!==undefined){
                            getDataList=getDataList.jointDedupDto;
                        }
                        let jointDataList=[];
                        if((allDataList.jointCbNumber===undefined || allDataList.jointCbNumber===null) &&  (allDataList.jointAccountNumber===null || allDataList.jointAccountNumber===undefined)){
                            getDataList.map((getData) => {
                                tableArray=[];
                                tableArray.push(this.createTableDataForIndividual(getData.cbNumber, getData.accountNumber,getData.customerName,getData.nid,getData.smartCard,getData.passport,getData.registrationNo,getData.drivingLicense,getData.dob,getData.phone!==undefined && getData.phone!=="88"?getData.phone:"",getData.email,getData.tin,getData.nationality));
                                jointDataList.push(tableArray);
                            });
                            console.log(jointDataList)
                        }
                        if(this.state.inputData.mandate === "YES") {
                            tableArrayForMandate.push(this.createTableDataForIndividual(allDataList.mandatecbNumber, allDataList.mandateaccountNumber,allDataList.mandatecustomerName,allDataList.mandatenid,allDataList.mandatesmartCard,allDataList.mandatepassport,allDataList.mandateregistrationNo,allDataList.mandatedrivingLicense,allDataList.mandatedob,allDataList.mandatephone!==undefined && allDataList.mandatephone!=="88"?allDataList.mandatephone:"",allDataList.mandateemail,allDataList.mandatetin,allDataList.mandatenationality!==undefined?allDataList.mandatenationality.key!==undefined?allDataList.mandatenationality.key:"":""));
                        }
                        if (this.state.inputData.beneficiary === "YES") {
                            tableArrayForBeneficiary.push(this.createTableDataForIndividual(allDataList.beneficiarycbNumber, allDataList.beneficiaryaccountNumber, allDataList.beneficiarycustomerName, allDataList.beneficiarynid, allDataList.beneficiarysmartCard, allDataList.beneficiarypassport, allDataList.beneficiaryregistrationNo, allDataList.beneficiarydrivingLicense, allDataList.beneficiarydob, allDataList.beneficiaryphone !== undefined && allDataList.beneficiaryphone !== "88" ? allDataList.beneficiaryphone : "", allDataList.beneficiaryemail, allDataList.beneficiarytin, allDataList.beneficiarynationality !== undefined ? allDataList.beneficiarynationality.key !== undefined ? allDataList.beneficiarynationality.key : "" : ""));
                        }
                        if((allDataList.jointCbNumber!==undefined && allDataList.jointCbNumber!==undefined) ||  (allDataList.jointAccountNumber!==null && allDataList.jointAccountNumber!==undefined)){
                            jointCbOrAccounts.push(this.createTableDataForJointCbAndAccounts(allDataList.jointCbNumber, allDataList.jointAccountNumber));

                        }
                        this.setState({

                            mandateDataList: tableArrayForMandate,
                            beneficiaryDataList: tableArrayForBeneficiary,
                            jointDataList: jointDataList,
                            jointCbOrAccounts: jointCbOrAccounts,
                            //relatedData: relatedTableArray,
                            searchTableData: true,
                            searchTableRelatedData: true,
                            //getsearchValue: object,
                            loading: false,

                        })

                    })
                    .catch((error)=>{
                        console.log(error);
                    })
            }
            else if(this.props.inputData.companyDedupData!==undefined){
                let dedupDataViewUrl = backEndServerURL + "/dedup/get/" +(this.state.appId===undefined?this.props.appId:this.state.appId) +"/"  + this.props.inputData.companyDedupData;
                axios.get(dedupDataViewUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        let getData=response.data.individualDedupData;
                        tableArray.push(this.createTableDataForIndividual(getData.cbNumber, getData.accountNumber,getData.customerName,getData.nid,getData.smartCard,getData.passport,getData.registrationNo,getData.drivingLicense,getData.dob,getData.phone!==undefined && getData.phone!=="88"?getData.phone:"",getData.email,getData.tin,getData.nationality));
                        this.setState({

                            individualDataList: tableArray,
                            //relatedData: relatedTableArray,
                            searchTableData: true,
                            searchTableRelatedData: true,
                            //getsearchValue: object,
                            loading: false,

                        })

                    })
                    .catch((error)=>{
                        console.log(error);
                    })
            }










        }
    }


    updateComponent = () => {
        this.forceUpdate();
    };


    close = () => {
        this.props.closeModal();
    };


    renderCumModalopen = () => {
        this.setState({
            renderCumModalopen: true
        })
    };


    closeModal = (account) => {
        if (!account) {
            this.setState({
                renderCumModalopen: false,
                renderCumModalopenNew: false,
            })
        } else {
            this.setState({
                renderCumModalopen: false,
                renderCumModalopenNew: false,
                generateAccountNo: account,
            })
        }
    };
    closeIcon = () => {
        this.setState({
            renderCumModalopen: false,
            renderCumModalopenNew: false,

        })
    };
    closeModalNew = (account) => {

        this.setState({
            renderCumModalopenNew: false,
            // CBGenerateModal: true,
            generateAccountNo: account,

        })
    };

    closeModalCBGenerate = (account) => {

        this.setState({
            CBGenerateModal: false,
        })
    };



    numberToCharacter = (i) => {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    };
    handleChangeSdnResult = (event) => {
        event.preventDefault();
        this.setState({
            SdnAndDedupeResultShowing: true
        })

    };
    closeModalForSdnResult = () => {
        this.setState({
            SdnAndDedupeResultShowing: false
        })
    }


    searchTableData = (style) => {
        if (this.state.searchTableData && this.state.individualDataList.length>0 && this.props.inputData.individualDedupData!==undefined && this.props.inputData.individualDedupData!==null) {
            return (
                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Individual Input Data Set</h4>

                        </CardHeader>
                    </paper>

                    <div>

                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["CB Number", "Account Number", "Customer Name", "NID", "Smart Card", "Passport", "Birth Cer.", "Driving License", "Date Of Birth", "Mobile Number ", "Email", "E-Tin", "Nationality"]}
                            tableData={this.state.individualDataList}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                    </div>

                </div>

            )


        }
        else  if (this.state.searchTableData &&  this.props.inputData.individualDedupData!==undefined &&  this.props.inputData.individualDedupData!==null) {
            return (
                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Individual Input Data Set</h4>

                        </CardHeader>
                    </paper>

                    <div>
                        <br/>
                        <center>
                            <h6 style={{
                                color: '#ff1f28'
                            }}>No Match Found</h6>
                        </center>
                        <br/>

                    </div>
                </div>

            )


        }

    };
    searchTableDataForJoints = (style) => {
        if(this.state.searchTableData && ( this.props.inputData.jointDedupData!==undefined && this.props.inputData.jointDedupData!==null ) || ((this.props.inputData.jointAccountNumber!==undefined && this.props.inputData.jointAccountNumber!==null) || (this.props.inputData.jointCbNumber!==undefined && this.props.inputData.jointCbNumber!==null) )){
            return(
                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>Joint Input Data Set</h5>

                        </CardHeader>
                    </paper>

                    {this.searchTableDataForJoint()}

                </div>
            )
        }

    }
    searchTableDataForJoint = (style) => {
        if (this.state.searchTableData && this.state.jointDataList.length>0 ) {
           /* return (
                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Individual Input Data Set</h4>

                        </CardHeader>
                    </paper>

                    <div>

                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["CB Number", "Account Number", "Customer Name", "NID", "Smart Card", "Passport", "Birth Cer.", "Driving License", "Date Of Birth", "Mobile Number ", "Email", "E-Tin", "Nationality"]}
                            tableData={this.state.jointDataList}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                    </div>

                </div>

            )*/
            return (
                this.state.jointDataList.map((tableData, index) => {
                    return (

                        <div>
                            <center>
                                <br/>
                                <Grid item xs={12}>
                                    <h3>{this.numberToCharacter(index + 1) + " Applicant "}</h3>

                                </Grid>
                            </center>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["CB Number", "Account Number", "Customer Name", "NID", "Smart Card", "Passport", "Birth Cer.", "Driving License", "Date Of Birth", "Mobile Number ", "Email", "E-Tin", "Nationality"]}

                                tableData={tableData}
                                tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', "left"]}
                            />
                        </div>

                    )
                })
            )

        }
        else  if (this.state.searchTableData && ((this.props.inputData.jointAccountNumber!==undefined && this.props.inputData.jointAccountNumber!==null) || (this.props.inputData.jointCbNumber!==undefined && this.props.inputData.jointCbNumber!==null) )) {
            return (

                <div>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["CB Number", "Account Number"]}

                        tableData={this.state.jointCbOrAccounts}
                        tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', "left"]}
                    />
                </div>
            )


        }

    };
    mandateDataList = (style) => {
        if (this.state.searchTableData && this.state.mandateDataList.length>0) {
            return (
                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Mandate Input Data Set</h4>

                        </CardHeader>
                    </paper>

                    <div>

                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["CB Number", "Account Number", "Customer Name", "NID", "Smart Card", "Passport", "Birth Cer.", "Driving License", "Date Of Birth", "Mobile Number ", "Email", "E-Tin", "Nationality"]}
                            tableData={this.state.mandateDataList}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                    </div>

                </div>

            )


        }
        else  if (this.state.searchTableData) {
            return (
                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Mandate Input Data Set</h4>

                        </CardHeader>
                    </paper>

                    <div>
                        <br/>
                        <center>
                            <h6 style={{
                                color: '#ff1f28'
                            }}>No Match Found</h6>
                        </center>
                        <br/>

                    </div>
                </div>

            )


        }

    };
    beneficiaryDataList = (style) => {
        if (this.state.searchTableData && this.state.beneficiaryDataList.length>0) {
            return (
                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Beneficiary Input Data Set</h4>

                        </CardHeader>
                    </paper>

                    <div>

                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["CB Number", "Account Number", "Customer Name", "NID", "Smart Card", "Passport", "Birth Cer.", "Driving License", "Date Of Birth", "Mobile Number ", "Email", "E-Tin", "Nationality"]}
                            tableData={this.state.beneficiaryDataList}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                    </div>

                </div>

            )


        }
        else  if (this.state.searchTableData) {
            return (
                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Beneficiary Input Data Set</h4>

                        </CardHeader>
                    </paper>

                    <div>
                        <br/>
                        <center>
                            <h6 style={{
                                color: '#ff1f28'
                            }}>No Match Found</h6>
                        </center>
                        <br/>

                    </div>
                </div>

            )


        }

    };


    renderMakerCumInput = () => {
        if (this.props.WithJointCb === true) {
            return (<SecondaryJointCbTagging editMode={true} WithJointCb={this.props.WithJointCb} makerEnd={true}
                                             introducerId={this.props.introducerId}
                                             introducerName={this.props.introducerName}
                                             freeFlag1={this.props.freeFlag1} customerName={this.props.customerName}
                                             accountNumber={this.props.accountNumber} solId={this.props.solId}
                                             freeFlag4={this.props.freeFlag4}
                                             processType={this.props.processType}
                                             appId={this.props.appId} closeIcon={this.closeIcon}
                                             taggingForm={this.props.taggingForm}
                                             customerNumber={this.state.getCustomerNumber}
                                             appId={this.props.appId}
                                             hideButton={this.props.hideButton}
                                             generateAccountNo={this.state.generateAccountNo}
                                             getCbNumber={this.state.getCbNumber}
                                             relatedPartyType={this.state.relatedPartyType}
                                             tagingModalCbnumber={this.state.tagingModalCbnumber} taging={this.taging}
                                             cbNumber={this.state.cbNumber}
                                             serviceType={this.props.serviceType}
                                             closeModal={this.closeModal}/>)
        } else {
            return (
                <MakerCumInput introducerId={this.props.introducerId} introducerName={this.props.introducerName}
                               freeFlag1={this.props.freeFlag1} customerName={this.props.customerName}
                               accountNumber={this.props.accountNumber} solId={this.props.solId}
                               freeFlag4={this.props.freeFlag4}
                               appId={this.props.appId} closeIcon={this.closeIcon} taggingForm={this.props.taggingForm}
                               processType={this.props.processType}
                               customerNumber={this.state.getCustomerNumber} appId={this.props.appId}
                               hideButton={this.props.hideButton}
                               generateAccountNo={this.state.generateAccountNo}
                               getCbNumber={this.state.getCbNumber}
                               relatedPartyType={this.state.relatedPartyType}
                               tagingModalCbnumber={this.state.tagingModalCbnumber} taging={this.taging}
                               cbNumber={this.state.cbNumber}
                               serviceType={this.props.serviceType}
                               closeModal={this.closeModal}/>
            )
        }
    };

    render() {
        const {classes} = this.props;

        return (

            <div>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>

                                {this.searchTableData()}
                            </div>
                        </Card>
                    </GridItem>

                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>

                                {this.searchTableDataForJoints()}
                            </div>
                        </Card>
                    </GridItem>

                </GridContainer>

                {  this.state.inputData.mandate === "YES" &&   <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>

                                {this.mandateDataList()}
                            </div>
                        </Card>
                    </GridItem>

                </GridContainer>}
                {this.state.inputData.beneficiary === "YES" && <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>

                                {this.beneficiaryDataList()}
                            </div>
                        </Card>
                    </GridItem>

                </GridContainer>}


            </div>

        )

    }


}

export default withStyles(styles)(InputDTO);
