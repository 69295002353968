function makeReadOnlyObject(object) {
    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject;

    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["readOnly"] = true;
    }
    return returnObject;
}

let EmiDataChangeCsoForm = [
    {
        varName: "customerCB",
        type: "textApiCall",
        label: "Customer CB",
        required: true,
        grid: 2,
    },
    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
        grid: 2,
    },

    {
        varName: "loanAccounts",
        type: "select",
        label: "Loan Accounts",
        grid: 3,
        required: true,
        "enum": [],
        "onKeyDown": true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
    },

    {
        "varName": "productName",
        "type": "textApiCall",
        "required": true,
        "label": "Product Name",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "loanOutstanding",
        "type": "textApiCall",
        "required": true,
        "label": "Loan Outstanding",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "casaAccountStatus",
        "type": "text",
        "label": "Casa Account Status",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "existingEmiDate",
        "type": "date",
        "required": true,
        "label": "Existing Emi Date",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },

    {
        "label": "Expected date of New EMI",
        "type": "select",
        "varName": "expectedEMIDate",
        "enum": [
            "1",
            "8",
            "16",
            "22",
            "25"
        ],
        "grid": 2,
        "required": true,
    },
    {
        type: "empty",
        grid: 12,
    },

];

let EmiDataChangeReadOnlyForm = {};
EmiDataChangeReadOnlyForm = makeReadOnlyObject(JSON.parse(JSON.stringify(EmiDataChangeCsoForm)));

module.exports = {
    EmiDataChangeCsoForm,
    EmiDataChangeReadOnlyForm
};