import React, {Component} from "react";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import CloseIcon from "@material-ui/icons/Close";
import Notification from "../../NotificationMessage/Notification";
import CBNotFound from "../../workflow/CASA/CBNotFound";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import MyValidation from "../../JsonForm/MyValidation";
import loader from "../../../Static/loader.gif";
import Table from "../../Table/Table";

let populateForm = [
    {
        varName: "accountSource",
        type: "select",
        label: "Account Source",
        required: true,
        grid: 2,
        errorMessage: "Error",
        enum: ["Finacle", "Ababil"]
    },
    {
        varName: "accountType",
        type: "select",
        label: "Account Type",
        required: true,
        grid: 2,
        errorMessage: "Error",
        enum: ["Savings", "City Alo","Current"]
    },
    {
        varName: "pageOfChequebook",
        type: "text",
        label: "Page of Chequebook",
        readOnly: true,
        grid: 2,
    },
    {
        varName: "quantity",
        type: "text",
        label: "Quantity",
        required: true,
        validation: "numeric",
        grid: 2,
        errorMessage: "Error",
    },
];

let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];

// const upload = [
//     {
//         varName: "fileUpload",
//         type: "file",
//         label: "Upload",
//         accept: ".pdf",
//         required: true,
//         grid: 2,
//         errorMessage: "Error"
//     },
// ];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class InstaPackRequisition extends Component {
    constructor() {
        super();
        this.state = {
            appData: "",
            fileUploadData: {},
            appId: "",
            err: false,
            inputData: {},
            errorArray: {},
            errorMessages: {},
            showValue: false,
            varValue: {},
            getData: false,
            notificationMessage: "",
            alert: false,
            loading: true,
            title: "",
            cbNotFound: false,
            getImageLink: [],
            getImageBoolean: false,
            imageName: [],
            disabled: false,
            getRemarks: [],
        };
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    handleChange = event => {
        this.setState({appData: event.target.value});
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false

        })
    };

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    functionForGetRemarks = () => {
        let remarksArray = [];
        let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
        axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            response.data.map((data) => {
                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
            });
            this.setState({
                getRemarks: remarksArray
            })
        })
            .catch((error) => {
                console.log(error)
            })
    };

    componentDidMount() {
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + "/variables/" + this.props.appId;

            axios
                .get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    this.functionForGetRemarks();
                    let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                    axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            this.setState({
                                getImageLink: response.data,
                                getImageBoolean: true,
                                imageName: response.data
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    this.setState({
                        varValue: response.data,
                        inputData: this.copyJson(response.data),
                        showValue: true,
                        getData: true,
                        loading: false,
                        appId: this.props.appId,
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            let start_url = backEndServerURL + "/startCase/cheque_book_request_start";
            axios
                .get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    let inputData = {};
                    inputData.pageOfChequebook = "10";
                    if (this.props.branchConfiguration !== undefined) {
                        this.setState({
                            inputData: {...this.props.branchConfiguration, ...inputData},
                            varValue: {...this.props.branchConfiguration, ...inputData},
                            showValue: true
                        });
                    } else {
                        this.setState({
                            inputData: inputData,
                            varValue: inputData,
                            showValue: true
                        });
                    }
                    this.setState({
                        appId: response.data.id,
                        loading: false
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    submitHandler = event => {
        event.preventDefault();

        let error = MyValidation.defaultValidation(populateForm, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true,
                disabled: true,
            });
            let postData = this.state.inputData;
            postData.category = "Chequebook";
            postData.subCategory = "Bulk Requisition";
            postData.serviceType = "Debit Card";
            postData.subServiceType = "Instapack";
            postData.customerName = this.state.inputData.customerName;
            postData.appId = this.state.appId;
            let remarksData = {};
            remarksData.remark = this.state.inputData.csRemarks;
            remarksData.map = {...postData, csRemarks: undefined};
            let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
            axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                    // disabled: false,
                    loading: false
                });
                if (this.props.appId !== undefined) {
                    this.props.closeModal()
                } else {
                    setTimeout(function () {
                        window.location.reload()
                    }, 1000);
                }
            }).catch((error) => {
                console.log(error);
                this.setState({
                    disabled: false,
                    loading: false
                })
            });
        }
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderHeader = () => {
        if (this.props.appId !== undefined || this.props.branchConfiguration !== undefined) {
            return (
                <h4 style={{color: "white"}} >
                    Instapack Requisition
                    <a><CloseIcon onClick={this.props.closeModal} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a>
                </h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Instapack Requisition
                </h4>
            )
        }
    };

    handleClose = (event) => {
        event.preventDefault();
        this.setState({
            disabled: true,
        });
        let variableSetUrl = backEndServerURL + "/case/close/" + this.props.appId;
        axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        disabled: false,
                        title: "Successful!",
                        notificationMessage: "Successfully Close!",
                        alert: true
                    });
                    this.props.closeModal();
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    disabled: false,
                });
            });

    };

    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px", "margin-top": "10px"}}>
                        <b>Remarks</b>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };


    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <React.Fragment>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            {this.renderHeader()}
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.cbNotFound}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <CBNotFound
                                                closeModal={this.closeModalCBNotFound}
                                                title={this.state.title}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        populateForm,
                                        this.updateComponent
                                    )}
                                    <Grid item xs={12}>
                                        {this.renderRemarksData()}
                                        <br/>
                                        {this.renderRemarks()}
                                    </Grid>
                                </ThemeProvider>
                            </Grid>
                            <Grid item xs="3" style={{marginTop: "20px"}}>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={this.submitHandler}
                                    disabled={this.state.disabled}
                                >
                                    Submit
                                </button>
                                &nbsp;
                                {this.props.appId !== undefined && <button
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={this.handleClose}
                                    disabled={this.state.disabled}
                                >
                                    Close
                                </button>}
                            </Grid>
                        </CardBody>
                        {this.renderNotification()}
                    </Card>
                </React.Fragment>
            );
        }


    }
}

export default withStyles(styles)(InstaPackRequisition);
