import React, {Component} from "react";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import 'semantic-ui-offline/semantic.min.css';
import GridItem from "../../Grid/GridItem";
import GridContainer from "../../Grid/GridContainer";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import CBNotFound from "../../workflow/CASA/CBNotFound";
import {Dialog} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import FailedListModal from "../WelcomeLetter/FailedListModal";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: "auto"
    }
};
let populateForm = [
    {
        varName: "fileName",
        type: "file",
        accept: ".xlsx,.xls",
        label: "Upload File"
    }
];


class InstaPackFileUploadForTag extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            fileUploadData: {},
            responseData: [],
            activeItem: "Generate",
            backgroundColor: "red",
            showValue: true,
            uploadModal: false,
            merchantModal: false,
            disabled: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            caseId: "",
            title: "",
            dataPassArray: [],
            buttonName: "Claim",
            notificationMessage: "",
            alert: false,
            showData: true,
            selectedCount: 0,
            failedModal: false,
            failedList: [],
            cbNotFound: false,
        };
    }

    closeModalCBNotFound = () => {
        this.props.fetchData("New");
        this.setState({
            cbNotFound: false,
            failedModal: false,
        });
        this.props.closeModal();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type="success"
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        });
    };

    handleSubmit = () => {
        if (this.state.fileUploadData.fileName !== undefined) {
            this.setState({
                disabled: true
            });
            const {fileName} = this.state.fileUploadData;

            const formData = new FormData();
            formData.append("file", fileName);

            axios({
                method: "post",
                url: backEndServerURL + "/instaPackExcelUploadBySchemeCodeAndSolId",
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    "content-type": "multipart/form-data"
                }
            })
                .then(response => {
                    console.log(response);
                    if (response.data.FailedList.length > 0) {
                        this.setState({
                            failedList: response.data.FailedList,
                            failedModal: true,
                            title: "Successful!",
                            notificationMessage: `${response.data.Success} Successful and ${response.data.Failed} Failed `,
                            alert: true,
                            disabled: false
                        });
                    } else if (response.data.SuccessList.length > 0 && response.data.FailedList.length === 0) {
                        this.setState({
                            title: "Successful!",
                            notificationMessage: `${response.data.Success} Successful and ${response.data.Failed} Failed `,
                            alert: true,
                            disabled: false
                        });
                        this.props.fetchData("New");
                        this.props.closeModal();
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        title: "Failed!",
                        type: "warning",
                        notificationMessage: error.response.data.message,
                        alert: true,
                        disabled: false
                    }, this.props.closeModal())
                });
        }
    };


    componentDidMount() {

    }

    updateComponent = () => {
        this.forceUpdate();
    };


    render() {
        const {classes} = this.props;
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    <h4 style={{color: "white"}} >File Upload (Instapack File Card) <a><CloseIcon onClick={() => this.props.closeModal()} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></h4>
                </CardHeader>
                <CardBody>
                    <div>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardBody>
                                        {this.renderNotification()}
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.cbNotFound}>
                                            <DialogContent className={classes.dialogPaper}>

                                                <CBNotFound
                                                    closeModal={this.closeModalCBNotFound}
                                                    title={this.state.title}
                                                />
                                            </DialogContent>
                                        </Dialog>

                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.failedModal}>
                                            <DialogContent className={classes.dialogPaper}>
                                                <FailedListModal
                                                    titleHeader="Failed List"
                                                    closeModal={this.closeModalCBNotFound}
                                                    failedList={this.state.failedList}
                                                />
                                            </DialogContent>
                                        </Dialog>
                                        <div>
                                            {CommonJsonFormComponent.renderJsonForm(
                                                this.state,
                                                populateForm,
                                                this.updateComponent
                                            )}

                                            <br/>
                                            <button
                                                className="btn btn-outline-primary btn-sm"
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                                disabled={this.state.disabled}
                                                onClick={this.handleSubmit}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </div>
                    <div></div>
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(styles)(InstaPackFileUploadForTag);
