import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import CommonJsonFormComponent from '../../JsonForm/CommonJsonFormComponent';
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from 'axios';
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import {Menu} from 'semantic-ui-react'
import 'semantic-ui-offline/semantic.min.css';
import Button from '@material/react-button';
import MaterialTable from 'material-table';
import {createMuiTheme} from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import CardMaintenanceRequest from "./CardMaintenanceRequest";
import theme from "../../JsonForm/CustomeTheme2";
import loader from "../../../Static/loader.gif";
import TablePagination from "@material-ui/core/TablePagination";
import {buttonStyle, localization, options, tableIcons} from "../../DeliverableManagement/configuration";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ConfirmationModal from "../FundTransfer/ConfirmationModal";
import ChangeTimeFormat from "../CommonComponent/ChangeTimeFormat";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";

const filteringJsonForm = [
    {
        varName: "startDate",
        type: "date",
        label: "Start Date",
        grid: 2,
        errorMessage: "Error",
    },

    {
        varName: "endDate",
        type: "date",
        label: "End Date",
        grid: 2,
        errorMessage: "Error",
    },
    {
        "varName": "caseId",
        "type": "text",
        "grid": 2,
        "label": "Case Id",
    },
    {
        "varName": "clientId",
        "type": "text",
        "grid": 3,
        "label": "Client Id",
    },
    // {
    //     "varName": "branchName",
    //     "type": "autoCompleteValueReturn",
    //     "grid": 2,
    //     "label": "Branch Name",
    // },
    {
        "varName": "customerCategory",
        "type": "select",
        "onKeyDown": true,
        "label": "Customer Category",
        "enum": [
            "Account",
            "AMEX",
            "VISA/Master",
            "Others"
        ],
        "grid": 3,
    },
    {
        "varName": "callCategory",
        "type": "autoComplete",
        "label": "Call Category",
        "grid": 3,
    },
    {
        "varName": "priority",
        "type": "select",
        "label": "Priority",
        "enum": [
            "High",
            "Regular"
        ],
        "grid": 3,
    },
    {
        "varName": "executorId",
        "type": "text",
        "lowerCase": true,
        "label": "Executor Id",
        "grid": 2,
    },
];
let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '1px 4px',
            }
        },

    }
});

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    },
};


class CardDivision extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            selectedDate: {},
            fileUploadData: {},
            responseData: [],
            responseActualArray: [],
            activeItem: 'Open',
            backgroundColor: 'red',
            showValue: false,
            uploadModal: false,
            detailsModal: false,
            viewModal: false,
            caseId: "",
            title: "",
            dataPassArray: [],
            buttonName: "View",
            notificationType: "",
            notificationTitle: "",
            notificationMessage: "",
            alert: false,
            loader: null,
            dialogHeader: "",
            err: false,
            supportDocumentArray: [],
            loading: false,
            confirmationAlert: false,
            errorArray: {},
            errorMessages: {},
            autoComplete: {},
            pageNumber: 0,
            totalRow: 0,
            showData: true,
            varValue: {},
            selectedCount: 0,
            rowsPerPage: 50,
            searchItem: "",
            confirmationType: "",
            confirmationData: [],
            allData: [],
            openColumns: [
                {title: 'Case Id', field: 'appId',},
                {title: 'Category', field: 'customerCategory',},
                {title: 'Call Type', field: 'calltype', export: false},
                {title: 'Created BY', field: 'createdby'},
                {title: 'Assign To', field: 'assignto'},
                {title: 'Client Id', field: 'clientId'},
                {title: 'SLA Time', field: 'slaTime'},
                {title: 'SLA Violation Status', field: 'slaViolated'},
                {title: 'Creation Date', field: 'date'},
                {title: 'Priority', field: 'priority'},
                {title: 'Modified Date', field: 'modifiedDate'},
                {title: 'Status', field: 'status'},
                {
                    title: 'Action', field: 'claimAction', render: rowData => (
                        <div>
                            <Button onClick={(e) => {
                                this.getRow(e, this.viewDetails(rowData.appId), "claim")
                            }} style={{
                                backgroundColor: "red",
                                color: "white",
                                padding: "3px 2px",
                                borderRadius: 3,
                                border: 1
                            }}>Lock</Button>
                            &nbsp;<Button onClick={(e) => {
                            this.getRow(e, this.viewDetails(rowData.appId), "details")
                        }} style={{
                            backgroundColor: "red",
                            color: "white",
                            padding: "3px 2px",
                            borderRadius: 3,
                            border: 1
                        }}>Details</Button>
                        </div>
                    )

                },
            ],
            lockedColumns: [
                {title: 'Case Id', field: 'appId',},
                {title: 'Category', field: 'customerCategory',},
                {title: 'Call Type', field: 'calltype', export: false},
                {title: 'Created BY', field: 'createdby', removable: false},
                {title: 'Assign To', field: 'assignto'},
                {title: 'SLA Time', field: 'slaTime'},
                {title: 'SLA Violation Status', field: 'slaViolated'},
                {title: 'Client Id', field: 'clientId'},
                {title: 'Creation Date', field: 'date'},
                {title: 'Priority', field: 'priority'},
                {title: 'Modified Date', field: 'modifiedDate'},
                {title: 'Status', field: 'status'},
                {title: 'Action', field: 'action', type: 'Button'},
            ],
            totalPages: 0,
            pageForOnChange: 1
        };
    }

    handleItemClick = (e, {name, style}) => this.setState({
        activeItem: name,
        backgroundColor: style

    });

    renderInboxStatus = (delStatus, inboxStatus, freeFlag4) => {
        let userId = LocalstorageEncrypt.encryptStorageFunction.getItem('username');
        let forwardStatus = "";
        let forwardedBy = "";
        if (freeFlag4) {
            forwardStatus = freeFlag4.split(" ")[0];
            forwardedBy = freeFlag4.split(" ")[1];
        }
        if (forwardStatus === "Forwarded" && userId === forwardedBy) {
            return "FORWARDED"
        }
        if (delStatus === "OPEN" || delStatus === "LOCKED") {
            return delStatus
        } else if (delStatus === "SAVED") {
            return "In Progress"
        } else if (delStatus === "CLOSED") {
            return inboxStatus
        } else {
            return inboxStatus
        }
    };

    dateConverter = (value) => {
        let date = value.split("T");
        return date[0] + " " + date[1]
    };

    functionForLock = (status, pageNumber) => {
        let page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        if (this.state.searchItem !== "") {
            this.searchData(this, status, page)
        } else {
            this.setState({
                loader: true
            });

            let open_url;
            if (status === "All") {
                open_url = backEndServerURL + "/getCardMaintenance/All/" + page;
            } else if (status === "saved") {
                open_url = backEndServerURL + "/inbox/opened/In Progress/" + page;
            } else {
                open_url = backEndServerURL + "/inbox/" + status + "/" + page;
            }
            axios.get(open_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let array = [];
                    let rowData;
                    let responseArray = [];
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        responseRowData = response.data.content[i].appId;
                        rowData = {
                            id: response.data.content[i].id,
                            appId: response.data.content[i].caseId,
                            slaTime: response.data.content[i].slaViolation,
                            clientId: response.data.content[i].clientId,
                            slaViolated: response.data.content[i].slaViolated,
                            uniqueId: response.data.content[i].appId,
                            customerCategory: response.data.content[i].freeFlag1,
                            calltype: response.data.content[i].freeFlag2,
                            createdby: response.data.content[i].initiatedBy,
                            assignto: response.data.content[i].currentUserName === "Despatch" ? "X" : response.data.content[i].currentUserName,
                            date: ChangeTimeFormat.convertTime(response.data.content[i].appInitTime),
                            modifiedDate: ChangeTimeFormat.convertTime(response.data.content[i].delInitTime),
                            priority: response.data.content[i].priority,
                            status: this.renderInboxStatus(response.data.content[i].delStatus, response.data.content[i].delInboxStatus, response.data.content[i].freeFlag4),
                            action: <Button onClick={(e) => {
                                this.getRow(e, response.data.content[i], "view")
                            }} style={{
                                backgroundColor: "green",
                                color: "white",
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 3,
                                paddingBottom: 3,
                                borderRadius: 3,
                                border: 1
                            }}>{this.state.buttonName}</Button>
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData)
                    }


                    this.setState(
                        {
                            allData: array,
                            loader: false,
                            pageNumber: page,
                            responseData: responseArray,
                            confirmationData: [],
                            totalRow: response.data.totalElements,
                            totalPages: response.data.totalPages,
                        }
                    );
                    console.log(this.state.allData)

                })
                .catch((error) => {
                    console.log(error)
                })
        }
    };

    headerSelect = (event, value) => {
        event.preventDefault();
        // this.setState({
        //     activeItem: value
        // });
        // window.open('http://localhost:3000/#/CardDivision', '_blank');

        this.setState(
            {
                dialogHeader: value.toString()
            }
        );

        if (value === 'All') {
            //open call
            this.setState({
                activeItem: value,
                buttonName: "View",
                loader: true,
                allData: [],
                responseData: [],
                pageForOnChange: 1
            });
            this.functionForLock("All", 0);
        } else if (value === 'Open') {
            //open call
            this.setState({
                activeItem: value,
                buttonName: "Claim",
                loader: true,
                allData: [],
                responseData: [],
                pageForOnChange: 1
            });
            this.functionForOpen(0);
        } else if (value === 'Locked') {

            //inbox call
            this.setState({
                loader: true,
                activeItem: value,
                buttonName: "View",
                allData: [],
                pageForOnChange: 1
            });
            this.functionForLock('waiting', 0)

        } else if (value === 'Progress') {

            //inbox call
            this.setState({
                loader: true,
                activeItem: value,
                buttonName: "View",
                allData: [],
                pageForOnChange: 1
            });
            this.functionForLock('saved', 0)

        } else if (value === 'Forwarded') {

            //inbox call
            this.setState({
                loader: true,
                activeItem: value,
                buttonName: "View",
                allData: [],
                pageForOnChange: 1
            });
            this.perticipated(0)

        } else if (value === 'Solved') {

            this.setState({
                loader: true,
                activeItem: value,
                buttonName: "View",
                allData: [],
                pageForOnChange: 1
            });
            this.functionForLock('solved', 0)

        } else if (value === 'Cancel') {
            this.setState({
                loader: true,
                activeItem: value,
                buttonName: "View",
                allData: [],
                pageForOnChange: 1
            });
            this.functionForLock('cancel', 0)
        }
    };

    getRow = (event, data, buttonName) => {
        event.preventDefault();
        console.log(data.appId);
        if (this.state.activeItem === "Open") {
            if (buttonName === "claim") {

                //******************single claim******************
                this.setState({
                    loader: true,
                    buttonName: "Claim"
                });
                console.log(this.state.activeItem);
                let single_url = backEndServerURL + "/case/claim/" + data.appId;
                axios.post(single_url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.setState({
                            notificationType: "success",
                            notificationTitle: "Massage",
                            notificationMessage: "Successfully Locked",
                            alert: true,
                            loader: false

                        });
                        this.setState({
                            allData: []
                        });
                        //open call
                        this.functionForOpen(0)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } else if (buttonName === "details") {
                console.log(data);
                this.open(data);
            }
        } else if (this.state.activeItem === "All" || this.state.activeItem === "Locked" || this.state.activeItem === "Solved" || this.state.activeItem === "Progress" || this.state.activeItem === "Forwarded" || this.state.activeItem === "Cancel") {
            this.open(data);
        }
    };

    viewDetails = (caseId) => {
        console.log(caseId);
        for (let i = 0; i < this.state.responseActualArray.length; i++) {
            if (this.state.responseActualArray[i].caseId === caseId) {
                return this.state.responseActualArray[i];
            }
        }
        return null;
    };

    onKeyDownChange = (data) => {
        if (data.varName === "customerCategory" && this.state.inputData.customerCategory) {
            let cCategory = this.state.inputData.customerCategory === "VISA/Master" ? "visaMaster" : this.state.inputData.customerCategory === "Others" ? "other" : this.state.inputData.customerCategory.toLowerCase();
            let url = backEndServerURL + "/getCallCategory/" + cCategory;
            let callCategory = [];
            let autoComplete = {};
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    if (response.status === 200) {

                        response.data.map((item) => {
                            callCategory.push({label: item.label})
                        });
                        autoComplete.callCategory = callCategory;
                        this.setState({
                            autoComplete: {...this.state.autoComplete, callCategory},
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                });

        }
    };


    // getCallCategory() {
    //     let url = backEndServerURL + "/card/call_category";
    //     let autoComplete = {};
    //     let callCategory = [];
    //     axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
    //         .then((response) => {
    //             response.data.map((item) => {
    //                 callCategory.push({label: item.label})
    //             });
    //
    //             autoComplete.callCategory = callCategory;
    //             this.setState({
    //                 autoComplete: {...this.state.autoComplete, callCategory},
    //             });
    //
    //
    //         })
    //         .catch((error) => {
    //             console.log(error)
    //         });
    // }

    getBranchAll() {
        let branchUrl = backEndServerURL + "/finMasterData/branch";
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                for (let item of filteringJsonForm) {
                    if (item.varName === "branchName" && item.type === "autoCompleteValueReturn") {
                        item.enum = response.data
                    }
                }
                this.setState({
                    loading: false
                });
            })
            .catch((error) => {
                console.log(error)
            })
    }


    componentDidMount() {
        // this.getBranchAll();
        // this.functionForLock("All", 0);
        this.functionForOpen(0);
    }

    updateComponent = () => {
        this.forceUpdate();

    };

    functionForOpen = (pageNumber) => {
        let page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;

        if (this.state.searchItem !== "") {
            this.searchData(this, "Open")
        } else {
            this.setState({
                loader: true,
                allData: []
            });

            let inbox_url = backEndServerURL + "/cardClaimable/" + page;
            axios.get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {

                        responseRowData = response.data.content[i].appId;
                        rowData = {
                            id: response.data.content[i].id,
                            appId: response.data.content[i].caseId,
                            customerCategory: response.data.content[i].freeFlag1,
                            calltype: response.data.content[i].freeFlag2,
                            slaTime: response.data.content[i].slaViolation,
                            clientId: response.data.content[i].clientId,
                            uniqueId: response.data.content[i].appId,
                            slaViolated: response.data.content[i].slaViolated,
                            createdby: response.data.content[i].initiatedBy,
                            assignto: response.data.content[i].currentUserName === "Despatch" ? "X" : response.data.content[i].currentUserName,
                            date: ChangeTimeFormat.convertTime(response.data.content[i].appInitTime),
                            modifiedDate: ChangeTimeFormat.convertTime(response.data.content[i].delInitTime),
                            priority: response.data.content[i].priority,
                            status: this.renderInboxStatus(response.data.content[i].delStatus, response.data.content[i].delInboxStatus, response.data.content[i].freeFlag4),
                            claimAction: <Button onClick={(e) => {
                                this.getRow(e, response.data.content[i])
                            }} style={{
                                backgroundColor: "green",
                                color: "white",
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 3,
                                paddingBottom: 3,
                                borderRadius: 3,
                                border: 1,
                                marginRight: -300
                            }}>{this.state.buttonName}</Button>
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData)
                    }

                    this.setState(
                        {
                            allData: array,
                            responseData: responseArray,
                            responseActualArray: response.data.content,
                            loader: false,
                            pageNumber: page,
                            confirmationData: [],
                            totalRow: response.data.totalElements,
                            totalPages: response.data.totalPages,
                        }
                    );
                    console.log(this.state.totalRow)

                })
                .catch((error) => {
                    console.log(error)
                })
        }
    };


    open = (data) => {
        this.setState({
            dataPassArray: data,
            viewModal: true
        })
    };

    closeModal = (value) => {
        this.setState({
            viewModal: false
        });
        if (value === 'submit') {
            if (this.state.activeItem === "Locked") {
                this.setState({
                    allData: [],
                    loader: true
                });
                this.functionForLock('waiting', 0)
            } else if (this.state.activeItem === "Solved") {
                this.setState({
                    allData: [],
                    loader: true
                });
                this.functionForLock('solved', 0)
            } else if (this.state.activeItem === "Progress") {
                this.setState({
                    buttonName: "View",
                    allData: [],
                    loader: true
                });
                this.functionForLock('saved', 0)
            } else if (this.state.activeItem === "Forwarded") {
                this.setState({
                    buttonName: "View",
                    allData: [],
                    loader: true
                });
                this.perticipated(0)
            }
        }
    };

    perticipated = (page) => {
        if (this.state.searchItem !== "") {
            this.searchData(this, "Forwarded")
        } else {
            this.setState({
                loader: true
            });
            let open_url = backEndServerURL + "/forwardedCaseList/" + page;
            axios.get(open_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    let array = [];
                    let rowData;
                    for (let i = 0; i < response.data.content.length; i++) {

                        rowData = {
                            id: response.data.content[i].id,
                            appId: response.data.content[i].caseId,
                            customerCategory: response.data.content[i].freeFlag1,
                            clientId: response.data.content[i].clientId,
                            uniqueId: response.data.content[i].appId,
                            calltype: response.data.content[i].freeFlag2,
                            createdby: response.data.content[i].initiatedBy,
                            assignto: response.data.content[i].currentUserName === "Despatch" ? "X" : response.data.content[i].currentUserName,
                            date: ChangeTimeFormat.convertTime(response.data.content[i].appInitTime),
                            modifiedDate: ChangeTimeFormat.convertTime(response.data.content[i].delInitTime),
                            priority: response.data.content[i].priority,
                            status: this.renderInboxStatus(response.data.content[i].delStatus, response.data.content[i].delInboxStatus, response.data.content[i].freeFlag4),
                            action: <Button onClick={(e) => {
                                this.getRow(e, response.data.content[i], "view")
                            }} style={{
                                backgroundColor: "green",
                                color: "white",
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 3,
                                paddingBottom: 3,
                                borderRadius: 3,
                                border: 1
                            }}>{this.state.buttonName}</Button>
                        };
                        array.push(rowData)

                    }


                    this.setState(
                        {
                            allData: array,
                            pageNumber: page,
                            totalRow: response.data.totalElements,
                            totalPages: response.data.totalPages,
                            loader: false,
                        }
                    );
                    console.log(this.state.allData)

                })
                .catch((error) => {
                    console.log(error)
                })
        }
    };

    searchData = (e, activeItem, page) => {
        // e.preventDefault();

        this.setState({
            loader: true,
        });

        let status;
        if (activeItem === "Locked") {
            status = "waiting";
        } else if (activeItem === "saved") {
            status = "In Progress";
        }
        else if (activeItem === "Progress") {
            status = "In Progress";
        }
        else {
            status = activeItem.toLowerCase();
        }
        let inbox_url;
        if (page === undefined) {
            this.setState({
                loader: true,
                pageNumber: 0
            });
            inbox_url = backEndServerURL + "/filterApplication/" + status + "/0";
        } else {
            this.setState({
                loader: true,
                pageNumber: page,
            });
            inbox_url = backEndServerURL + "/filterApplication/" + status + "/" + page;
        }


        let postData = {
            fromDate: this.state.inputData.startDate,
            toDate: this.state.inputData.endDate,
            caseId: this.state.inputData.caseId,
            branchName: this.state.inputData.branchName,
            customerCategory: this.state.inputData.customerCategory,
            callCategory: this.state.inputData.callCategory,
            clientId: this.state.inputData.clientId,
            priority: this.state.inputData.priority,
            userType: "Executor",
            executorId: this.state.inputData.executorId
        };


        axios
            .post(inbox_url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {

                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].appId;
                    rowData = {
                        id: response.data.content[i].id,
                        appId: response.data.content[i].caseId,
                        slaTime: response.data.content[i].slaViolation,
                        slaViolated: response.data.content[i].slaViolated,
                        clientId: response.data.content[i].clientId,
                        uniqueId: response.data.content[i].appId,
                        customerCategory: response.data.content[i].freeFlag1,
                        calltype: response.data.content[i].freeFlag2,
                        createdby: response.data.content[i].initiatedBy,
                        assignto: response.data.content[i].currentUserName === "Despatch" ? "X" : response.data.content[i].currentUserName,
                        date: ChangeTimeFormat.convertTime(response.data.content[i].appInitTime),
                        modifiedDate: ChangeTimeFormat.convertTime(response.data.content[i].delInitTime),
                        priority: response.data.content[i].priority,
                        status: this.renderInboxStatus(response.data.content[i].delStatus, response.data.content[i].delInboxStatus, response.data.content[i].freeFlag4),
                        action: <Button onClick={(e) => {
                            this.getRow(e, response.data.content[i], "view")
                        }} style={{
                            backgroundColor: "green",
                            color: "white",
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 3,
                            paddingBottom: 3,
                            borderRadius: 3,
                            border: 1
                        }}>{this.state.buttonName}</Button>
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData)
                }
                this.setState(
                    {
                        allData: array,
                        loading: false,
                        responseData: responseArray,
                        pageNumber: this.state.pageNumber,
                        responseActualArray: response.data.content,
                        totalRow: response.data.totalElements,
                        totalPages: response.data.totalPages,
                        searchItem: this.state.activeItem,
                        loader: false,
                    }
                );
                console.log(this.state.allData)
            })
            .catch(error => {
                console.log(error);
            });
    };

    render() {
        const {activeItem} = this.state;
        const {classes} = this.props;

        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <React.Fragment>

                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        margin: ' ',
                    }}>
                        <h5 style={{color: "black"}}>Card Maintenance</h5>
                    </CardHeader>
                    <CardBody style={{
                        backgroundColor: "white"
                    }}>
                        <Grid container spacing={1}>
                            <ThemeProvider theme={theme}>
                                {this.renderFilterForm()}
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'middle',
                                        display: "block",
                                        height: 30,
                                        marginTop: 20,
                                        paddingBottom: 5
                                    }}
                                    onClick={e => {
                                        this.searchData(e, this.state.activeItem)
                                    }}
                                >
                                    Search
                                </button>
                                &nbsp;
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'middle',
                                        display: "block",
                                        height: 30,
                                        marginTop: 20,
                                        paddingBottom: 5
                                    }}
                                    onClick={e => window.location.reload()}
                                >
                                    Reset
                                </button>
                            </ThemeProvider>

                        </Grid>
                        <br></br>
                        <React.Fragment>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.confirmationAlert}>
                                <DialogContent className={classes.dialogPaper}>
                                    <ConfirmationModal
                                        closeModal={this.closeConfirmation}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <div>
                                <Menu pointing>
                                    <Menu.Item
                                        name='All'
                                        active={activeItem === 'All'}
                                        style={activeItem === 'All' ? {
                                            backgroundColor: "red",
                                            color: "white",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        } : {
                                            backgroundColor: "white",
                                            color: "black",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        }}
                                        //     onClick={this.handleItemClick}
                                        onClick={(e) => {
                                            this.headerSelect(e, 'All')
                                        }}
                                    />
                                    <Menu.Item
                                        name='Open'
                                        active={activeItem === 'Open'}
                                        style={activeItem === 'Open' ? {
                                            backgroundColor: "red",
                                            color: "white",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        } : {
                                            backgroundColor: "white",
                                            color: "black",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        }}
                                        //     onClick={this.handleItemClick}
                                        onClick={(e) => {
                                            this.headerSelect(e, 'Open')
                                        }}
                                    />
                                    <Menu.Item
                                        name='Locked'
                                        active={activeItem === 'Locked'}
                                        style={activeItem === 'Locked' ? {
                                            backgroundColor: "red",
                                            color: "white",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        } : {
                                            backgroundColor: "white",
                                            color: "black",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        }}
                                        onClick={(e) => {
                                            this.headerSelect(e, 'Locked')
                                        }}
                                    />
                                    <Menu.Item
                                        name='In Progress'
                                        active={activeItem === 'Progress'}
                                        style={activeItem === 'Progress' ? {
                                            backgroundColor: "red",
                                            color: "white",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        } : {
                                            backgroundColor: "white",
                                            color: "black",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        }}
                                        onClick={(e) => this.headerSelect(e, 'Progress')}
                                    />
                                    <Menu.Item
                                        name='Forwarded'
                                        active={activeItem === 'Forwarded'}
                                        style={activeItem === 'Forwarded' ? {
                                            backgroundColor: "red",
                                            color: "white",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        } : {
                                            backgroundColor: "white",
                                            color: "black",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        }}
                                        onClick={(e) => this.headerSelect(e, 'Forwarded')}
                                    />

                                    <Menu.Item
                                        name='Solved'
                                        active={activeItem === 'Solved'}
                                        style={activeItem === 'Solved' ? {
                                            backgroundColor: "red",
                                            color: "white",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        } : {
                                            backgroundColor: "white",
                                            color: "black",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        }}
                                        onClick={(e) => this.headerSelect(e, 'Solved')}
                                    />
                                    <Menu.Item
                                        name='Cancel'
                                        active={activeItem === 'Cancel'}
                                        style={activeItem === 'Cancel' ? {
                                            backgroundColor: "red",
                                            color: "white",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        } : {
                                            backgroundColor: "white",
                                            color: "black",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        }}
                                        onClick={(e) => this.headerSelect(e, 'Cancel')}
                                    />
                                </Menu>
                                {this.tableShow(this.state.activeItem)}
                                {this.renderNotification()}
                                {this.dialogBoxShow()}
                            </div>
                        </React.Fragment>
                    </CardBody>
                </React.Fragment>
            );
        }
    }

    renderFilterForm = () => {
        return (
            CommonJsonFormComponent.renderJsonForm(this.state, filteringJsonForm, this.updateComponent, "", this.onKeyDownChange)
        )
    };

    dialogBoxShow = () => {
        return (
            <div>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    open={this.state.viewModal}>
                    <DialogContent>
                        <CardMaintenanceRequest appId={this.state.dataPassArray.appId}
                                                arrayValue={this.state.dataPassArray} closeModal={this.closeModal}
                                                headerValue={this.state.activeItem}/>
                    </DialogContent>
                </Dialog>
            </div>
        )
    };

    unlockFunction = (event, rowData, props) => {

        if (this.state.activeItem === 'Locked') {
            let allSelectValue = (rowData, props.data);

            console.log(allSelectValue);

            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].uniqueId)
                }

                this.setState({
                    title: "Do you want to confirm?",
                    confirmationAlert: true,
                    confirmationType: "Unlock",
                    confirmationData: rowIdArray
                });

            }

        }
    };


    handleChangePage = (pageNumber, status) => {

        let page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        if (this.state.searchItem !== "") {
            this.searchData(this, status, page);
        } else {
            let currentStatus;
            if (status === "Locked") {
                currentStatus = "waiting";
            } else if (status === "Progress") {
                currentStatus = "saved";
            } else {
                currentStatus = status.toLowerCase();
            }

            if (status === "Forwarded") {
                this.perticipated(page)
            } else {
                this.functionForLock(currentStatus, page)
            }
        }


    };

    rowColor = (rowData) => {
        if (rowData.slaViolated === "YES") {
            return {backgroundColor: "rgba(249, 0, 11, 0.3)"};
        } else if (rowData.tableData.checked) {
            return {backgroundColor: "rgba(55, 177, 89, 0.2)"};
        }
    };

    handleChangePageNumber = (event) => {
        let page = event.target.value;
        this.setState({
            pageForOnChange: page
        });
        if (page > 1) {
            if (this.state.activeItem === "All") {
                this.functionForLock(this.state.activeItem,(page - 1))
            } else if (this.state.activeItem === "Open") {
                this.functionForOpen((page - 1))
            } else {
                this.handleChangePage((page - 1), this.state.activeItem)
            }
        } else if (page === 1) {
            if (this.state.activeItem === "All") {
                this.functionForLock('0')
            } else if (this.state.activeItem === "Open") {
                this.functionForOpen('0')
            } else {
                this.handleChangePage('0', this.state.activeItem)
            }
        } else {
            return 0;
        }
    };

    renderPageNumberForm = () => {

        let pageArray = [];
        for (let i = 0; i < parseInt(this.state.totalPages); i++) {
            pageArray.push(i + 1)
        }
        return (
            <>
                <ThemeProvider theme={theme}>
                    <Select
                        variant="outlined"
                        style={{height: "30px", margin: "auto 15px auto 0", borderRadius: "0"}}
                        value={this.state.pageForOnChange}
                        onChange={this.handleChangePageNumber}
                    >
                        {pageArray.map((number) => (<MenuItem value={number}>{number}</MenuItem>))}
                    </Select>
                </ThemeProvider>
            </>
        )
    };

    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                confirmationAlert: false,
                disabled: true,
                loader: true,
            });
            if (this.state.confirmationType === "Lock") {
                let single_url = backEndServerURL + "/case/claim/";
                axios.post(single_url, this.state.confirmationData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.functionForOpen(0)
                    }).catch((error) => {
                    console.log(error)
                })
            }

            if (this.state.confirmationType === "Unlock") {
                let single_url = backEndServerURL + "/case/unClaim/";
                axios.post(single_url, this.state.confirmationData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.functionForLock("waiting", 0)
                    }).catch((error) => {
                    console.log(error)
                })
            }

            if (this.state.confirmationType === "Solved") {
                console.log(this.state.confirmationData);
                let single_url = backEndServerURL + "/changeToSolved/byIdList";
                axios.post(single_url, this.state.confirmationData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.functionForLock('waiting', 0)
                    }).catch((error) => {
                    console.log(error)
                })
            }


        } else {
            this.setState({
                confirmationAlert: false
            })
        }
    };


    functionForOpenToChangeStatus = (e, rowData, props, status) => {
        if (this.state.activeItem === 'Open') {
            let allSelectValue = (rowData, props.data);
            let rowIdArray = [];
            console.log(allSelectValue);
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].tableData.id)
                }
                console.log(rowIdArray);
                let appsIdArray = [];

                for (let i = 0; i < rowIdArray.length; i++) {
                    appsIdArray[i] = this.state.responseData[rowIdArray[i]];
                }

                console.log(appsIdArray);

                this.setState({
                    title: "Do you want to confirm?",
                    confirmationAlert: true,
                    confirmationType: "Lock",
                    confirmationData: appsIdArray
                });
            }

        }
    };

    functionForLockToChangeStatus = (e, rowData, props, status) => {
        if (this.state.activeItem === 'Locked') {
            let allSelectValue = (rowData, props.data);
            let postDataForSolved = [];
            let rowIdArrayWithPriority = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArrayWithPriority.push({
                        id: allSelectValue[i].tableData.id,
                        priority: allSelectValue[i].priority
                    })
                }

                for (let i = 0; i < rowIdArrayWithPriority.length; i++) {
                    postDataForSolved.push({
                        id: this.state.responseData[rowIdArrayWithPriority[i].id],
                        priority: rowIdArrayWithPriority[i].priority,
                        approval: "SOLVED"
                    });
                }

                console.log(postDataForSolved);

                this.setState({
                    title: "Do you want to confirm?",
                    confirmationAlert: true,
                    confirmationType: "Solved",
                    confirmationData: postDataForSolved
                });

            }

        }
    };

    tableShow = (value) => {
        if (this.state.loader) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    open={this.state.loader}>
                    <DialogContent>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else if (value === "All") {
            return (
                <div>
                    <ThemeProvider theme={themeO}>
                        <div style={{maxWidth: '100%'}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.lockedColumns}
                                data={this.state.allData}
                                title={""}
                                options={{
                                    ...options,
                                    maxBodyHeight: "450px",
                                    pageSize: this.state.rowsPerPage,
                                    rowStyle: rowData => {
                                        return this.rowColor(rowData)
                                    }
                                }}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <>
                                            <TablePagination
                                                {...props}
                                                rowsPerPageOptions={[this.state.rowsPerPage]}
                                                count={this.state.totalRow}
                                                page={this.state.pageNumber}
                                                onChangePage={(e, page) => {
                                                    console.log("clicked");
                                                    this.functionForLock("All", page)
                                                }
                                                }
                                            />
                                            {this.renderPageNumberForm()}
                                        </>
                                    ),
                                }}

                            />
                        </div>
                    </ThemeProvider>
                </div>
            )
        } else if (value === "Open") {
            return (
                <div>
                    <ThemeProvider theme={themeO}>
                        <div style={{maxWidth: '100%'}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.openColumns}
                                data={this.state.allData}
                                title={""}
                                options={{
                                    ...options,
                                    maxBodyHeight: "450px",
                                    pageSize: this.state.rowsPerPage,
                                    rowStyle: rowData => {
                                        return this.rowColor(rowData)
                                    }
                                }}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <>
                                            <TablePagination
                                                {...props}
                                                rowsPerPageOptions={[this.state.rowsPerPage]}
                                                count={this.state.totalRow}
                                                page={this.state.pageNumber}
                                                onChangePage={(e, page) => {
                                                    this.functionForOpen(page)
                                                }
                                                }
                                            />
                                            {this.renderPageNumberForm()}
                                        </>
                                    ),
                                    Actions: props => (
                                        <>
                                            <Button style={buttonStyle}
                                                    onClick={(event, rowData) => this.functionForOpenToChangeStatus(event, rowData, props, "claim")}>Lock</Button>
                                        </>

                                    ),


                                }}

                            />
                        </div>
                    </ThemeProvider>
                </div>
            )
        } else if (value === "Locked") {

            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: '100%'}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.lockedColumns}
                                data={this.state.allData}
                                title={this.state.activeItem + " List"}
                                options={{
                                    ...options,
                                    maxBodyHeight: "450px",
                                    pageSize: this.state.rowsPerPage,
                                    rowStyle: rowData => {
                                        return this.rowColor(rowData)
                                    }
                                }}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <>
                                            <TablePagination
                                                {...props}
                                                rowsPerPageOptions={[this.state.rowsPerPage]}
                                                count={this.state.totalRow}
                                                page={this.state.pageNumber}
                                                onChangePage={(e, page) => {
                                                    this.handleChangePage(page, this.state.activeItem)
                                                }
                                                }
                                            />
                                            {this.renderPageNumberForm()}
                                        </>
                                    ),
                                    Actions: props => (
                                        <>
                                            <Button style={buttonStyle}
                                                    onClick={(event, rowData) => this.unlockFunction(event, rowData, props)}
                                            >
                                                Unlock
                                            </Button>
                                            &nbsp;
                                            <Button style={buttonStyle}
                                                    onClick={(event, rowData) => this.functionForLockToChangeStatus(event, rowData, props, "solved")}>Solved</Button>
                                        </>
                                    ),

                                }}

                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            )
        } else if (value === "Solved") {

            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: '100%'}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.lockedColumns}
                                data={this.state.allData}
                                title={this.state.activeItem + " List"}
                                options={{
                                    ...options,
                                    maxBodyHeight: "450px",
                                    pageSize: this.state.rowsPerPage,
                                    rowStyle: rowData => {
                                        return this.rowColor(rowData)
                                    }
                                }}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <>
                                            <TablePagination
                                                {...props}
                                                rowsPerPageOptions={[this.state.rowsPerPage]}
                                                count={this.state.totalRow}
                                                page={this.state.pageNumber}
                                                onChangePage={(e, page) => {
                                                    this.handleChangePage(page, this.state.activeItem)
                                                }
                                                }
                                            />
                                            {this.renderPageNumberForm()}
                                        </>
                                    ),

                                }}

                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            )
        } else if (value === "Progress") {

            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: '100%'}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.lockedColumns}
                                data={this.state.allData}
                                title={this.state.activeItem + " List"}
                                options={{
                                    ...options,
                                    maxBodyHeight: "450px",
                                    pageSize: this.state.rowsPerPage,
                                    rowStyle: rowData => {
                                        return this.rowColor(rowData)
                                    }
                                }}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <>
                                            <TablePagination
                                                {...props}
                                                rowsPerPageOptions={[this.state.rowsPerPage]}
                                                count={this.state.totalRow}
                                                page={this.state.pageNumber}
                                                onChangePage={(e, page) => {
                                                    this.handleChangePage(page, this.state.activeItem)
                                                }
                                                }
                                            />
                                            {this.renderPageNumberForm()}
                                        </>
                                    ),

                                }}

                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            )
        } else if (value === "Forwarded" || value === "Cancel") {

            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: '100%'}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.lockedColumns}
                                data={this.state.allData}
                                title={this.state.activeItem + " List"}
                                options={{
                                    ...options,
                                    maxBodyHeight: "450px",
                                    pageSize: this.state.rowsPerPage,
                                    rowStyle: rowData => {
                                        return this.rowColor(rowData)
                                    }
                                }}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <>
                                            <TablePagination
                                                {...props}
                                                rowsPerPageOptions={[this.state.rowsPerPage]}
                                                count={this.state.totalRow}
                                                page={this.state.pageNumber}
                                                onChangePage={(e, page) => {
                                                    this.handleChangePage(page, this.state.activeItem)
                                                }
                                                }
                                            />
                                            {this.renderPageNumberForm()}
                                        </>
                                    ),

                                }}

                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            )
        }

    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.notificationType} stopNotification={this.stopNotification}
                              title={this.state.notificationTitle}
                              message={this.state.notificationMessage}/>
            )
        }
    }


}

export default withStyles(styles)(CardDivision);
