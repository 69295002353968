import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import "../../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Grow from "@material-ui/core/Grow";

import {ThemeProvider} from "@material-ui/styles";

import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";

import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";

import Notification from "../../../NotificationMessage/Notification";

import loader from "../../../../Static/loader.gif";

import ErrorModal from "../../CommonComponent/ErrorModal";
import FileReqInitiatorFormDialog from "./FileReqInitiatorFormDialog";

const jsonForm = [
    {
        varName: "fileReferenceNo",
        type: "text",
        label: "File Reference No",
        required: true,
        grid: 4,
    },
    {
        type: "empty",
        grid: 12,
    },
];


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

function Transition(props) {
    return <Grow in={true} timeout="auto" {...props} />;
}


class FileRequisitionInitiatorForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            SelectedData: false,
            csDeferalPage: "",
            values: [],
            appId: '',
            csDataCapture: '',
            message: "",
            appData: {},
            getData: false,
            getNewCase: false,
            varValue: [],
            caseId: "",
            title: "",
            notificationMessage: "",
            app_uid: "-1",
            alert: false,
            loading: false,
            redirectLogin: false,
            type: [],
            typeMessage: '',
            dueDate: '',
            inputData: {},
            fileUploadData: {},
            selectedDate: {},
            dropdownSearchData: {},

            debitCard: "",
            showValue: false,
            AddDeferal: false,
            getDeferalList: [],
            deferalNeeded: false,
            uploadModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            getInitiateData: false,

            IndividualDedupModal: false,
            JointDedupModal: false,
            individualDataSaveId: '',
            jointDataSaveId: '',
            companyDataSaveId: '',
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            errorModalBoolean: false,
            errorTittle: "",

            accountNumber: "",
            applicantName: "",
            assetOpsNumber: "",
            fileReferenceNo: "",
            requisitionBy: "",
            requisitionDate: '',
            inboxModal: false,


        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    updateComponent = () => {
        this.forceUpdate();
    };
    handleChangeComments = () => {
        alert("Ok")
    };
    returnJsonForm = () => {
        return (
            CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent)
        )

    };


    handleSubmit = (event) => {
        event.preventDefault();
        console.log(this.state.inputData);
        let object = this.state.inputData.fileReferenceNo;
        console.log(object);
        this.setState({
            loading: true
        });

        if (object !== undefined) {
            let url = backEndServerURL + "/archiveFile/requisitionInitiateGetInfo/" + object;

            let getAllAssetManager = [];
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    },
                })
                .then((response) => {
                    console.log(response);

                    if (response.data === 'FileReferenceNo Not Available') {
                        this.setState({
                            loading: false,
                            alert: true,
                            typeMessage: 'error',
                            title: "Not Found",
                            notificationMessage: response.data
                        });
                    } else if (response.data === 'FileReferenceNo flow donot completed') {
                        this.setState({
                            loading: false,
                            alert: true,
                            typeMessage: 'error',
                            title: "Flow Already exit",
                            notificationMessage: response.data
                        });
                    } else {
                        this.setState({
                            accountNumber: response.data.accountNumber,
                            applicantName: response.data.applicantName,
                            assetOpsNumber: response.data.assetOpsNumber,
                            fileReferenceNo: response.data.fileReferenceNo,
                            requisitionBy: response.data.requisitionBy,
                            requisitionDate: response.data.requisitionDate,
                            loading: false,
                            inboxModal: true,
                        });
                    }

                    console.log(this.state.accountNumber + ' ' + this.state.applicantName + ' ' + this.state.assetOpsNumber + ' ' + this.state.requisitionBy + ' ');
                })
                .catch((error) => {
                    console.log(error);
                });

        }

    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.typeMessage} stopNotification={this.stopNotification}
                              title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };
    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    close = () => {
        this.props.closeModal();
    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };


    closeModal = () => {
        this.setState({
            getMappingAllImage: false,
            IndividualDedupModal: false,
            JointDedupModal: false,
            errorModalBoolean: false,

        })
    };
    closeUploadModal = (data) => {
        this.setState({
            uploadButtonClick: true,
            uploadModal: false,
            getMappingAllImage: false
        })
    };


    renderButton = () => {

        return (
            <div>
                <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={this.handleSubmit}
                >
                    Search
                </button>

            </div>
        )

    };

    closeModal = () => {
        this.setState({
            inboxModal: false,
        });

    };


    renderInboxCase = () => {
        //alert("please view component Add")
        //Api Call

        return (
            <FileReqInitiatorFormDialog closeModal={this.closeModal}
                                        fileReferenceNo={this.state.fileReferenceNo}
                                        applicantName={this.state.applicantName}
                                        assetOpsNumber={this.state.assetOpsNumber}
                                        accountNumber={this.state.accountNumber}
                                        requisitionBy={this.state.requisitionBy}
                                        requisitionDate={this.state.requisitionDate}

            />
        )

    };

    render() {

        const {classes} = this.props;
        return (
            <section>
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    fullScreen={true}
                    open={this.state.inboxModal}>
                    <DialogContent>

                        {this.renderInboxCase()}
                    </DialogContent>
                </Dialog>


                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >
                            Initiate File Requisition</h4>
                    </CardHeader>
                    <CardBody>
                        <div>
                            <ThemeProvider theme={theme}>
                                <Grid container spacing={1}>
                                    {this.renderNotification()}
                                    {this.returnJsonForm()}

                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.loading}>
                                        <DialogContent className={classes.dialogPaper}>

                                            <center>
                                                <img src={loader} alt=""/>
                                            </center>
                                        </DialogContent>
                                    </Dialog>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="mD"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.errorModalBoolean}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <ErrorModal title={this.state.errorTittle}
                                                        closeModal={this.closeModal}
                                                        subServiceType={this.props.subServiceType}
                                                        appId={this.props.appId}
                                            />

                                        </DialogContent>
                                    </Dialog>

                                    {this.renderButton()}

                                </Grid>
                            </ThemeProvider>
                        </div>
                    </CardBody>
                </Card>
            </section>

        );
    }

}

export default withStyles(styles)(FileRequisitionInitiatorForm);

