import React, {Component} from "react";

class FunctionForInputGetAndSet extends Component {
    static setInputData(value) {
        let inputData = {...value};
        inputData.ccy = value.currency;
        inputData.valueDate = value.fdValueDate;
        inputData.depositTenure = value.tenureType;
        inputData.depositPeriodMonthsdays = value.tenure;
        inputData.autoRenewal = value.autoRenewals;
        inputData.maturity = value.maturity;
        inputData.interestCreditAccount = value.interestCreditAccount;
        inputData.interestCreditName = value.interestCreditName;
        inputData.repaymentAcId = value.repaymentAccount;
        inputData.repaymentName = value.repaymentName;
        inputData.depositinstallAmount = value.amount;
        inputData.schemeCode = value.fdrSchemeCode;
        inputData.maturityDate = value.fdMaturityDate;
        inputData.etin = value.etin;
        inputData.depositeType = value.depositeType;
        inputData.occupationCode = value.occupationCode;
        inputData.sectorCode = value.sectorCode;
        inputData.subSectorCode = value.subSectorCode;
        inputData.rmCode = value.rmCode;
        inputData.rmCodeName = value.rmCodeName;
        inputData.nomineeType = value.nomineeType;
        inputData.nomineeExistNot = value.nomineeExistNot;
        return inputData;
    }

    static getInputData(inputData) {
        let value = {...inputData};
        value.currency = inputData.ccy;
        value.fdValueDate = inputData.valueDate;
        value.tenureType = inputData.depositTenure;
        value.tenure = inputData.depositPeriodMonthsdays;
        value.autoRenewals = inputData.autoRenewal;
        value.maturity = inputData.maturity;
        value.interestCreditAccount = inputData.interestCreditAccount;
        value.interestCreditName = inputData.interestCreditName;
        value.repaymentAccount = inputData.repaymentAcId;
        value.repaymentName = inputData.repaymentName;
        value.amount = inputData.depositinstallAmount;
        value.fdrSchemeCode = inputData.schemeCode;
        value.fdMaturityDate = inputData.maturityDate;
        value.sectorCode = inputData.sectorCode;
        value.subSectorCode = inputData.subSectorCode;
        value.occupationCode = inputData.occupationCode;
        value.rmCode = inputData.rmCode;
        value.rmCodeName = inputData.rmCodeName;
        value.etin = inputData.etin;
        value.depositeType = inputData.depositeType;
        value.nomineeType = inputData.nomineeType;
        value.nomineeExistNot = inputData.nomineeExistNot;

        return value;
    }

    static setInputDataForDPS(value) {
        let inputData = {...value};
        inputData.dpsccy = value.currency;
        inputData.dpspaymentAccountId = value.repaymentAccounts;
        inputData.dpspaymentAccountName = value.repaymentsName;
        inputData.dpspaymentAccountIdApiCall = "YES";
        inputData.repaymentsAccountApiCall = "YES";
        inputData.dpsvalueDate = value.fdValueDate;
        inputData.dpsmaturityDate = value.fdMaturityDate;
        inputData.dpsdepositTenure = value.tenureType;
        inputData.dpsdepositPeriodMonthsdays = value.tenure;
        inputData.dpsschemeCode = value.dpsSchemeCode;
        inputData.dpsdepositAmount = value.amount;
        inputData.dpsetin = value.etin;
        inputData.dpsnextExecutionDate = value.nextExecutionDate;
        inputData.dpsdepositeType = value.depositeType;
        inputData.dpsoccupationCode = value.occupationCode;
        inputData.dpssectorCode = value.sectorCode;
        inputData.dpssubSectorCode = value.subSectorCode;
        inputData.dpsrmCode = value.rmCode;
        inputData.dpsrmCodeName = value.rmCodeName;
        inputData.dpsnomineeType = value.nomineeType;
        inputData.dpsnomineeExistNot = value.nomineeExistNot;
        return inputData;
    }

    static getInputDataForDPS(inputData) {
        let value = {...inputData};
        value.currency = inputData.dpsccy;
        value.repaymentAccounts = inputData.dpspaymentAccountId;
        value.repaymentsName = inputData.dpspaymentAccountName;
        value.dpspaymentAccountIdApiCall = "YES";
        value.repaymentsAccountApiCall = "YES";
        value.fdValueDate = inputData.dpsvalueDate;
        value.fdMaturityDate = inputData.dpsmaturityDate;
        value.tenureType = inputData.dpsdepositTenure;
        value.tenure = inputData.dpsdepositPeriodMonthsdays;
        value.dpsSchemeCode = inputData.dpsschemeCode;
        value.amount = inputData.dpsdepositAmount;
        value.etin = inputData.dpsetin;
        value.nextExecutionDate = inputData.dpsnextExecutionDate;
        value.depositeType = inputData.dpsdepositeType;
        value.occupationCode = inputData.dpsoccupationCode;
        value.sectorCode = inputData.dpssectorCode;
        value.subSectorCode = inputData.dpssubSectorCode;
        value.rmCode = inputData.dpsrmCode;
        value.rmCodeName = inputData.dpsrmCodeName;
        value.nomineeType = inputData.dpsnomineeType;
        value.nomineeExistNot = inputData.dpsnomineeExistNot;
        return value;
    }

    static setInputDataForDPSFromCasa(value) {
        let inputData = {...value};
        inputData.dpsccy = value.currency;
        inputData.dpspaymentAccountId = value.repaymentAccounts;
        inputData.dpspaymentAccountName = value.repaymentsName;
        inputData.dpspaymentAccountIdApiCall = "YES";
        inputData.repaymentsAccountApiCall = "YES";
        inputData.dpsvalueDate = value.fdValueDate;
        inputData.dpsmaturityDate = value.dpsfdMaturityDate;
        inputData.dpsvalueDate = value.dpsfdValueDate;
        inputData.dpsdepositTenure = value.dpstenureType;
        inputData.dpsdepositPeriodMonthsdays = value.dpstenure;
        inputData.dpsschemeCode = value.dpsdpsSchemeCode;
        inputData.dpsdepositAmount = value.dpsamount;
        inputData.dpsetin = value.etin;
        inputData.dpsnextExecutionDate = value.nextExecutionDate;
        inputData.dpsdepositeType = value.dpsdepositType;
        inputData.dpsoccupationCode = value.occupationCode;
        inputData.dpssectorCode = value.sectorCode;
        inputData.dpssubSectorCode = value.subSectorCode;
        inputData.dpsrmCode = value.rmCodeDps;
        inputData.dpsrmCodeName = value.rmCodeDpsName;
        inputData.dpsnomineeType = value.nomineeTypes;
        inputData.dpsnomineeExistNot = value.nomineeExistNots;
        return inputData;
    }

    static getInputDataForDPSFromCasa(inputData) {
        let value = {...inputData};
        value.currency = inputData.dpsccy;
        value.repaymentAccounts = inputData.dpspaymentAccountId;
        value.repaymentsName = inputData.dpspaymentAccountName;
        value.dpspaymentAccountIdApiCall = "YES";
        value.repaymentsAccountApiCall = "YES";
        value.dpsfdValueDate = inputData.dpsvalueDate;
        value.dpsfdMaturityDate = inputData.dpsmaturityDate;
        value.dpstenureType = inputData.dpsdepositTenure;
        value.dpstenure = inputData.dpsdepositPeriodMonthsdays;
        value.dpsdpsSchemeCode = inputData.dpsschemeCode;
        value.dpsamount = inputData.dpsdepositAmount;
        value.etin = inputData.dpsetin;
        value.nextExecutionDate = inputData.dpsnextExecutionDate;
        value.dpsdepositType = inputData.dpsdepositeType;
        value.occupationCode = inputData.dpsoccupationCode;
        value.sectorCode = inputData.dpssectorCode;
        value.subSectorCode = inputData.dpssubSectorCode;
        value.rmCodeDps = inputData.dpsrmCode;
        value.rmCodeDpsName = inputData.dpsrmCodeName;
        value.nomineeTypes = inputData.dpsnomineeType;
        value.nomineeExistNots = inputData.dpsnomineeExistNot;
        return value;
    }
}

export default FunctionForInputGetAndSet
