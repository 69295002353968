import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import axios from 'axios';
import '../../Static/css/login.css';
import {backEndServerURL} from '../../Common/Constant';
import Notification from "../NotificationMessage/Notification";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import logo from "../../Static/green-delta.png";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";
import Functions from "../../Common/Functions";


const styles = theme => ({

    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {

        marginTop: theme.spacing.unit * 1,

    },
    Buttoncolorchange: {
        backgroundColor: '#ff3443',
        '&:hover': {
            backgroundColor: "#ff3443"
        }
    },
    Errorr: {
        color: '#ff3443',


    }

});
let Form = [
    {
        "varName": "username",
        "type": "text",
        "label": "User Name",
        "grid": 2,
        "required": true,
    },

];

class ForgetPassword extends Component {
    state = {
        inputData: {},
        selectedDate: {},
        getData: false,
        workstation: false,
        role: false,
        showValue: false,
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},

        err: false,
        varValue: [],
        errorArray: {},
        errorMessages: {},
        title: "",
        notificationMessage: "",
        errorType: "success",
        alert: false,
        redirectLoginPage: false,
        otherRequestTitle: "",
        username: "",
        loading: false,
        errorMessage: "",

    };


    handlesubmit = event => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        let url = backEndServerURL + "/forgetPass/" + this.state.username;
        console.log(url);
        axios.get(url, {})
            .then((response) => {
                console.log(response.data);
                if (response.status === 252) {
                    this.setState({
                        alert: true,
                        title: "Error!",
                        errorType: "error",
                        errorMessage: "Back-End server Issue",
                        notificationMessage: "Back-End server Issue",
                        loading: false
                    })
                } else {
                    this.setState({
                        alert: true,
                        title: "Success!",
                        errorType: "success",
                        errorMessage: "Please check your Email",
                        notificationMessage: "Please Check Your Email",
                        loading: false
                    })
                }


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    alert: true,
                    title: "Error!",
                    errorType: "error",
                    errorMessage: Functions.errorObjectCheck(error),
                    notificationMessage: Functions.errorObjectCheck(error),
                    loading: false
                })
            })


    };

    returnJsonForm = () => {
        return (
            CommonJsonFormComponent.renderJsonForm(this.state, Form, this.updateComponent)
        )

    };
    updateComponent = () => {
        this.forceUpdate();
    };
    handleChangeUsername = (event) => {
        this.setState({
            username: event.target.value
        })
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.errorType} stopNotification={this.stopNotification}
                              title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    render() {


        const {classes} = this.props;

        return (
            <div>

                {
                    this.renderNotification()

                }

                <center>


                    <main className={classes.main}>
                        <CssBaseline/>
                        <Paper className={classes.paper}>

                            <img height={120} src={logo}/>
                            <Typography component="h1" variant="h5">
                                Forget Password
                            </Typography>
                            <p className={classes.Errorr}>{this.state.errorMessage}</p>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                open={this.state.loading}>
                                <DialogContent>

                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>
                            <form onSubmit={this.handlesubmit} className={classes.form}>
                                <FormControl margin="normal" required fullWidth>
                                    <InputLabel htmlFor="username">User Name</InputLabel>
                                    <Input onChange={this.handleChangeUsername} type="text" id="username"
                                           name="username"
                                           autoComplete="User name" autoFocus/>
                                </FormControl>


                                <br/>


                                <center>
                                    <button
                                        className="btn btn-outline-primary  btn-sm"
                                        style={{
                                            marginTop: "18px"
                                        }}


                                    >
                                        Submit
                                    </button>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <p><b>Powered By Business Accelerate BD LTD</b></p>
                                </center>


                            </form>


                        </Paper>
                    </main>
                </center>
            </div>
        );
    }
}

ForgetPassword.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ForgetPassword);