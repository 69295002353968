import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import Table from "./Table.jsx";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import image from "../../Static/css/download.png";
import "../../Static/css/RelationShipView.css";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

class TableList extends Component {
    render() {
        const {classes} = this.props;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>Customer Basics</h4>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <p>Name : Rezaul Huq</p>
                                <p>Father's name : Atiar Rahman</p>
                                <p>NID : 2893847439832</p>
                                <p>Date of Birth : 22/08/1996</p>
                            </div>

                            <div className="photo-div">
                                <img src={image}/>
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>Linked Accounts</h4>
                        </CardHeader>
                        <CardBody>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={["Account Number", "Account Type", "Available balance", "Ledger Balance", "Currency", "Status", "Interest Accrued", "Interest Rate", "Average Balance (Monthly)"]}
                                tableData={[
                                    ["CB123456789", "Savings", "20000", "36789", "BD", "Active", "32763", "15%", "2398338"],
                                    ["CB123456789", "Current", "998000", "8347938", "DOLLAR", "Active", "3487", "7.5%", "901298392"]
                                ]}
                                tableAllign={['left', 'left', 'right', 'right', 'left', 'left', 'right', 'right', 'right']}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>Loan Summary</h4>
                        </CardHeader>
                        <CardBody>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={["Account Number", "Product Name", "Currency", "Tenor", "Outstanding Balance",
                                    "Next Installment Date", "Current Cycle Interest Accrued", "Number of Installment Remained", "Amount of Unrealized Due Installments"]}
                                tableData={[
                                    ["CB123456789", "Savings", "20000", "36789", "BD", "Active", "32763", "15%", "2398338"],
                                    ["CB123456789", "Current", "998000", "8347938", "DOLLAR", "Active", "3487", "7.5%", "901298392"]
                                ]}
                                tableAllign={['left', 'left', 'right', 'right', 'left', 'left', 'right', 'right', 'right']}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>Term Deposit Summary</h4>
                        </CardHeader>
                        <CardBody>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={["Account Number", "Account Type", "Tenor", "Branch", "Currency", "Balance", "Interest Rate"]}
                                tableData={[
                                    ["CB123456789", "Savings", "20000", "36789", "BD", "32763", "15%"],
                                    ["CB123456789", "Current", "998000", "8347938", "DOLLAR", "3487", "7.5%"]
                                ]}
                                tableAllign={['left', 'left', 'right', 'right', 'left', 'left', 'right', 'right']}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>Credit Card Summary</h4>
                        </CardHeader>
                        <CardBody>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={["Card Number", "Card Type", "Card Holder Name", "Type", "Status", "Outstanding Balance", "Minimum Payment Due", "Non Payment of Minimum Payment", "Interest Accrued"]}
                                tableData={[
                                    ["CB123456789", "Savings", "20000", "36789", "BD", "Active", "32763", "15%", "2398338"],
                                    ["CB123456789", "Current", "998000", "8347938", "DOLLAR", "Active", "3487", "7.5%", "901298392"]
                                ]}
                                tableAllign={['left', 'left', 'right', 'right', 'left', 'left', 'right', 'right', 'right']}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>Debit Card / Prepaid Card Summary</h4>
                        </CardHeader>
                        <CardBody>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={["Card Number", "Card Type", "Card Holder Name", "Type", "Status"]}
                                tableData={[
                                    ["CB123456789", "Savings", "20000", "36789", "Active"],
                                    ["CB123456789", "Current", "998000", "8347938", "Active"]
                                ]}
                                tableAllign={['left', 'left', 'right', 'right', 'left']}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>Locker Facility</h4>
                        </CardHeader>
                        <CardBody>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={["Locker Type", "Last Charge Realization", "Charge Pending Amount", "Charge Pending Months"]}
                                tableData={[
                                    ["CB123456789", "328993", "20000", "36789"],
                                    ["CB123456789", "767755", "998000", "8347938"]
                                ]}
                                tableAllign={['left', 'right', 'right', 'right']}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>Standing Instructions</h4>
                        </CardHeader>
                        <CardBody>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={["Number of SI", "Type of SI", "Number of SI Failure", "Number of Successful SI"]}
                                tableData={[
                                    ["CB123456789", "328993", "20000", "36789"],
                                    ["CB123456789", "767755", "998000", "8347938"]
                                ]}
                                tableAllign={['left', 'right', 'right', 'right']}
                            />
                        </CardBody>
                    </Card>
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>Freeze Accounts</h4>
                        </CardHeader>
                        <CardBody>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={["Account Number", "Account Type", "Freeze Status from Date", "Freeze Instruction By"]}
                                tableData={[
                                    ["CB123456789", "328993", "20000", "36789"],
                                    ["CB123456789", "767755", "998000", "8347938"]
                                ]}
                                tableAllign={['left', 'right', 'right', 'right']}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

export default withStyles(styles)(TableList);
