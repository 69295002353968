import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles/index";
import CommonJsonFormComponent from '../../JsonForm/CommonJsonFormComponent';
import Card from "../../Card/Card.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from 'axios/index';
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import DialogContent from "@material-ui/core/DialogContent/index";
import {Dialog} from "@material-ui/core/index";
import Table from "../../Table/Table";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import SignatureButton from "../Maintenance/SignatureButton";
import MyValidation from "../../JsonForm/MyValidation";
import GridList from "@material-ui/core/GridList";
import CBNotFound from "../CASA/CBNotFound";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

let csRemarks = [
    {
        "varName": "bomRemarks",
        "type": "textArea",
        "label": "BOM Remarks",
        "grid": 12,
        "required": true,
    }];

let lockerJsonForm = [
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6,
        "readOnly": true
    },
    {
        "varName": "lockerNumber",
        "type": "text",
        "label": "Locker Number",
        "grid": 6,
        "readOnly": true
    },
    {
        "varName": "lockerName",
        "type": "text",
        "label": "Locker Name",
        "grid": 6,
        "readOnly": true
    },
    {
        "varName": "lockerSize",
        "type": "text",
        "label": "Locker Size",
        "grid": 6,
        "readOnly": true
    }
];


class BOMLockerSurrander extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: true,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            loading: true,
            getData: false,
            signature: false,
            documentList: [],
            getDocument: false,
            getRemarks: [],
            cbNotFound: false
        }


    }


    signatureOpen = () => {
        this.setState({
            signature: true
        })
    };

    signatureClose = () => {
        this.setState({
            signature: false
        })
    };


    updateComponent = () => {
        this.forceUpdate();
    };

    previewButton = () => {

        return (
            <React.Fragment>
                <Grid container spacing={0}>
                    <SignatureButton accountNumber={this.state.inputData.accountNumber} classes={this.props}/>
                </Grid>
            </React.Fragment>
        )

    };

    componentDidMount() {

        console.log(this.props.appId);

        let url = backEndServerURL + "/variables/" + this.props.appId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.bomRemarks = null;

                this.setState({
                    inputData: response.data,
                    varValue: response.data,
                    getData: true,
                    loading: false
                });


            })
            .catch((error) => {
                console.log(error);
            });

        let documentsUrl = backEndServerURL + "/case/files/" + this.props.appId;
        axios.get(documentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                this.setState({
                    documentList: response.data,
                    getDocument: true
                })


            })
            .catch((error) => {
                console.log(error);
            });

        var remarksArray = [];
        let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
        axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            console.log(response.data);
            response.data.map((data) => {
                if (data.remarks !== 'undefined') {
                    remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                }
            });
            this.setState({
                getRemarks: remarksArray
            })
        })
            .catch((error) => {
                console.log(error)
            })

    }


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    formWithData = () => {
        if (this.state.getData) {
            return (
                <ThemeProvider theme={theme}>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, lockerJsonForm, this.updateComponent)
                    }
                </ThemeProvider>
            )
        }
    };


    approveHandler = (e) => {
        // alert(this.state.inputData.lockerNumber)
        e.preventDefault();

        this.state.inputData.bom_approval = "APPROVED";
        var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                var surrenderSetUrl = backEndServerURL + "/locker/surrender/" + this.state.inputData.lockerNumber;
                axios.post(surrenderSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.bomRemarks + "/" + this.props.appId;
                        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                            console.log(response.data)
                        })
                            .catch((error) => {
                                console.log(error)
                            });
                        this.state.inputData.bomRemarks = undefined;

                        var url = backEndServerURL + "/case/route/" + this.props.appId;

                        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                if (response.status === 200) {
                                    this.setState({
                                        title: "Successful!",
                                        notificationMessage: "Successfully Routed!",
                                        alert: true
                                    });
                                    this.props.closeModal()
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            title: error.response.data.message,
                            cbNotFound: true,
                        })
                    });
            })
            .catch((error) => {
                console.log(error)
            });
    };

    returnHandler = (e) => {
        e.preventDefault();

        let error = MyValidation.defaultValidation(csRemarks, this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        } else {

            this.state.inputData.bom_approval = "RETURN";
            var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
            axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.bomRemarks + "/" + this.props.appId;
                    axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                        console.log(response.data)
                    })
                        .catch((error) => {
                            console.log(error)
                        });
                    this.state.inputData.bomRemarks = undefined;

                    var lockerStatusUrl = backEndServerURL + "/changeLockerStatus/" + (this.state.inputData.lockerNumber).toString() + "/Rented";
                    axios.get(lockerStatusUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                        var url = backEndServerURL + "/case/route/" + this.props.appId;

                        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {

                                console.log(response.data);
                                this.setState({
                                    title: "Successful!",
                                    notificationMessage: "Successfully Routed!",
                                    alert: true
                                });
                                this.props.closeModal();
                            })
                            .catch((error) => {
                                console.log(error);

                            });
                    })
                        .catch((error) => {
                            console.log(error)
                        });
                })
                .catch((error) => {
                    console.log(error)
                });
        }
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "Raised By", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />
                    <br/>
                </div>
            )
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
            )
        }
    };


    render() {
        const {classes} = this.props;
        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Card>
                        <CardBody>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.loading}>
                                <DialogContent className={classes.dialogPaper}>
                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <Grid container>

                                {
                                    this.formWithData()
                                }

                                {
                                    this.renderNotification()
                                }

                                <Grid item xs={12}></Grid>
                                {
                                    this.previewButton()
                                }
                                <Grid item xs='12'>
                                    {this.renderRemarksData()}
                                </Grid>
                            </Grid>
                            <br/>
                            <ThemeProvider theme={theme}>
                                <Grid container>
                                    {this.renderRemarks()}
                                </Grid>
                            </ThemeProvider>
                            <center>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'middle',
                                        marginTop: '12px',
                                    }}
                                    onClick={this.approveHandler}
                                    type="submit"
                                >
                                    Submit
                                </button>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'middle',
                                        marginTop: '12px',
                                        marginLeft: '10px'
                                    }}
                                    onClick={this.returnHandler}
                                    type="submit"
                                >
                                    Return
                                </button>
                            </center>
                        </CardBody>
                    </Card>
                </div>
            </GridList>
        )
    }

}

export default withStyles(styles)(BOMLockerSurrander);
