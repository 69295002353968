import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import 'semantic-ui-offline/semantic.min.css';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import functionForFileDownload from "../../DeliverableManagement/FunctionForFileDownload";
import CBNotFound from "../CASA/CBNotFound";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let jsonForm = [
    {
        varName: "fromDate",
        type: "date",
        label: "From Date",
        grid: 2,
        required: true
    },
    {
        varName: "toDate",
        type: "date",
        label: "To Date",
        grid: 2,
        required: true
    },
    // {
    //     varName: "category",
    //     type: "select",
    //     enum: [
    //         "CASA",
    //         "FDR",
    //         "DPS"
    //     ],
    //     label: "Select Category",
    //     grid: 2,
    //     required: true
    // },
    // {
    //     varName: "subCategory",
    //     type: "select",
    //     enum: [
    //         "A/C Opening",
    //         "Opening"
    //     ],
    //     label: "Select Subcategory",
    //     grid: 2,
    //     required: true
    // },
];
let jsonFormForBMBulkApproval = [
    {
        varName: "fromDate",
        type: "date",
        label: "From Date",
        grid: 2,
    },
    {
        varName: "toDate",
        type: "date",
        label: "To Date",
        grid: 2,
    },

];


class Report extends Component {

    constructor(props) {
        super(props);
        this.state = {
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            inputData: {},
            title: "",
            notificationMessage: "",
            loading: true,
            cbNotFound: false,
        };
    }


    componentDidMount() {
        let branchUrl = backEndServerURL + "/finMasterData/branch";
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                for (let item of jsonForm) {
                    if (item.varName === "branch") {
                        item.enum = response.data
                    }
                }
                this.setState({
                    loading: false
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            })
    }

    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    handleSubmit = () => {
        let error = false;
        if (this.props.match.params.requestType === "bmBulkApprovalReport") {
            error = MyValidation.defaultValidation(jsonFormForBMBulkApproval, this.state);
        } else {
            error = MyValidation.defaultValidation(jsonForm, this.state);
        }

        this.forceUpdate();
        if (error === true) {
            return false
        } else {
            this.setState({
                loading: true
            });
            let postData = {...this.state.inputData};
            if (this.props.match.params.requestType === "fdr") {
                postData.category = "FDR";
                postData.subCategory = "Opening"
            } else if (this.props.match.params.requestType === "dps") {
                postData.category = "DPS";
                postData.subCategory = "Opening"
            } else if (this.props.match.params.requestType === "casa") {
                postData.category = "CASA";
                postData.subCategory = "A/C Opening"
            } else if (this.props.match.params.requestType === "bmBulkApprovalReport") {
                postData.category = "BM Bulk Approval Report";
                postData.subCategory = "BM Bulk Approval Report"
            }
            let url = backEndServerURL + "/GetCategoryWiseReport";
            if (this.props.match.params.requestType === "bmBulkApprovalReport") {
                url = backEndServerURL + "/bmBulkApprovalReport";
            }


            functionForFileDownload.downloadFile(url, "POST", postData, "report.xlsx").then((response) => {
                this.setState({
                    loading: false
                })
            }).catch((error) => {
                this.setState({
                    loading: false,
                    title: "Something Went Wrong!",
                    cbNotFound: true,
                })
            })
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.requestType !== this.props.match.params.requestType) {
            window.location.reload()
        }
    }

    renderHeader = () => {
        if (this.props.match.params.requestType === "fdr") {
            return (
                <h4 style={{color: "white"}} >
                    Daily volume of FDR Opening
                </h4>
            )
        } else if (this.props.match.params.requestType === "dps") {
            return (
                <h4 style={{color: "white"}} >
                    Daily volume of DPS Opening
                </h4>
            )
        } else if (this.props.match.params.requestType === "casa") {
            return (
                <h4 style={{color: "white"}} >
                    Daily volume of CASA Opening
                </h4>
            )
        } else if (this.props.match.params.requestType === "bmBulkApprovalReport") {
            return (
                <h4 style={{color: "white"}} >
                    BM Bulk Approval Report
                </h4>
            )
        }
    };
    renderJsonForm = () => {
        return (
            CommonJsonFormComponent.renderJsonForm(
                this.state,
                jsonFormForBMBulkApproval,
                this.updateComponent
            )
        )

    };

    render() {
        const {classes} = this.props;
        return (
            <div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {this.renderHeader()}
                    </CardHeader>
                    <CardBody>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>
                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.cbNotFound}>
                            <DialogContent className={classes.dialogPaper}>
                                <CBNotFound
                                    closeModal={this.closeModalCBNotFound}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        <Grid container>
                            <ThemeProvider theme={theme}>
                                {this.renderJsonForm()}
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={this.handleSubmit}
                                    style={{
                                        height: 25,
                                        marginTop: 20,
                                    }}
                                >
                                    Submit
                                </button>
                            </ThemeProvider>
                        </Grid>
                    </CardBody>
                </Card>
            </div>
        );
    }

}

export default withStyles(styles)(Report);
