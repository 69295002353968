import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Functions from '../../../Common/Functions';
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import Notification from "../../NotificationMessage/Notification";
import loading from "../../../Static/loader.gif";
import CBNotFound from "../CASA/CBNotFound";
import TableComponent from "../CommonComponent/TableComponent";
import ClusterTagForm from "./ClusterTagForm";

const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

class TagCluster extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            loading: true,
            cbNotFound: false,
            getData: false,
            varValue: {},
            title: "",
            page: 0,
            notificationMessage: "",
            alert: false,
            selectedDate: {},
            showValue: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            tableData: [],
            openClusterModal: false,
            rowClickedId: 0,
            rowClickedName: "",
            rowsPerPage: 100,
            tableColumns: [
                {title: "SL", field: "sl"},
                {title: "Hierarchy Name", field: "hierarchy_name"},
                {title: "Parent Name", field: "parent_name"},
                {title: "Cluster Head ID", field: "cluster_head_id"},
                {title: "Status", field: "status"},
                {title: "Action", field: "action"},
            ]
        }
    }

    componentDidMount() {
        this.fetchData()
    };

    fetchData = () => {
        let url = backEndServerURL + "/workplaceUnit/getClustersTagged";
        axios.get(url, {withCredentials: true})
            .then((response) => {
                let data = [];
                response.data.map((item, index) => {
                    data.push({
                        sl: index + 1,
                        hierarchy_name: item.unitName,
                        parent_name: item.parentName,
                        cluster_head_id: item.clusterHeadUsername,
                        status: item.status,
                        action: this.functionForTriggerTag(item.id, item.unitName)
                    })
                });
                this.setState({
                    tableData: data,
                    getData: true,
                    loading: false
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    getData: false
                })
            });
    };

    functionForTriggerTag = (id, clusterName) => {
        return (
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'middle',
                }}

                onClick={() => this.openClusterModal(id, clusterName)}
            >
                Tag Cluster
            </button>
        )
    };

    openClusterModal = (id, clusterName) => {
        this.setState({
            openClusterModal: true,
            rowClickedId: id,
            rowClickedName: clusterName
        });
    };

    closeClusterModal = () => {
        this.setState({
            openClusterModal: false,
        }, this.fetchData);
    };

    renderTagModal = () => {
        const {classes} = this.props;
        return (
            <Dialog
                fullWidth="true"
                maxWidth="md"
                className={classes.modal}
                classes={{paper: classes.dialogPaper}}
                open={this.state.openClusterModal}>
                <DialogContent className={classes.dialogPaper}>
                    <ClusterTagForm
                        clusterName={this.state.rowClickedName}
                        clusterId={this.state.rowClickedId}
                        activeItem={this.state.activeItem}
                        closeModal={this.closeClusterModal}
                    />
                </DialogContent>
            </Dialog>
        )
    };


    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderCloseIcon = () => {
        return (
            <h4 style={{color: "white"}} >Business Hierarchy</h4>
        )
    };

    closeModalCBNotFound = () => {
        this.setState({
            openHierarchy: false,
            cbNotFound: false
        })
    };


    handleChangePage = (pageNumber) => {

    };


    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderCloseIcon()}
                </CardHeader>
                <CardBody>
                    <ThemeProvider theme={theme}>
                        {this.renderTagModal()}
                        {this.state.getData &&
                        <TableComponent tableData={this.state.tableData} rowsPerPage={this.state.rowsPerPage}
                                        tableColumns={this.state.tableColumns}
                                        totalRow={100} page={this.state.page} selection={false}
                                        handleChangePage={this.handleChangePage}
                        />}
                        <Grid container>
                            {this.renderNotification()}
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.loading}>
                                <DialogContent className={classes.dialogPaper}>
                                    <center>
                                        <img src={loading} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>
                        </Grid>
                    </ThemeProvider>
                </CardBody>
            </Card>

        );


    }

}

export default withStyles(styles)(TagCluster);
