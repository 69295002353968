import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import Table from "../Table/Table";
import Card from "../Card/Card.jsx";
import Button from '@material-ui/core/Button';
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import Modal from 'react-awesome-modal';
import ExistingCustomer from "./ExistingCustomer";
import NewAccount from "./NewAccount";
import {Dialog, Grow} from "@material-ui/core";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },

};

function Transition(props) {

    return <Grow in={true}   {...props} />;
}

class AccountSearchResult extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newCustomarCreateModal: false,
            existCustomarCreateModal: false
        }

    }

    newCustomarCreateModal = () => {

        this.setState({
            newCustomarCreateModal: true
        })
    };

    existCustomarCreateModal() {
        this.setState({
            existCustomarCreateModal: true,


        });
    }

    closeModal = () => {
        this.setState({
            existCustomarCreateModal: false,
            newCustomarCreateModal: false,


        });
    };

    render() {
        const {classes} = this.props;
        return (
            <>
                <Dialog
                    open={this.state.existCustomarCreateModal}
                    fullWidth="true"
                    maxWidth="md"
                    onClose={this.closeModal}
                    TransitionComponent={Transition}
                >
                    <div style={{'max-height': 'calc(100vh )', 'overflow-y': 'auto'}}>
                        <center>
                            <ExistingCustomer/>
                        </center>

                    </div>
                </Dialog>
                <Modal
                    visible={this.state.newCustomarCreateModal}
                    width="1050"

                    effect="fadeInUp"
                    onClickAway={() => this.closeModal()}
                    TransitionComponent={Transition}
                >
                    <div style={{'max-height': 'calc(100vh )', 'overflow-y': 'auto'}}>
                        <center>
                            <NewAccount/>
                        </center>

                    </div>
                </Modal>,
                <GridContainer>


                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>Account Opening Search Result</h4>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    tableHovor="yes"
                                    tableHeaderColor="primary"
                                    tableHead={["Unique Id", "Cb Number", "Source", "Customer Name", "Nid", "Birth Certificate", "Passport", "Action"]}

                                    tableData={[
                                        ["001", "345432", "Finacle", "biplob", "1234567890", "1995462145", "32763",
                                            <Button onClick={() => this.existCustomarCreateModal()} color="primary">
                                                New
                                            </Button>],

                                    ]}
                                    tableAllign={['left', 'left', 'right', 'left', 'left', 'left', 'right', 'right', 'right']}
                                /><br/><br/><br/>
                                <center>
                                    <Button onClick={() => this.newCustomarCreateModal()} color="primary">
                                        New Customer Creation


                                    </Button>


                                </center>

                            </CardBody>
                        </Card>
                    </GridItem>

                </GridContainer>
            </>
        );
    }
}

export default withStyles(styles)(AccountSearchResult);
