import React, {Component} from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog, MenuItem} from "@material-ui/core";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CloseIcon from '@material-ui/icons/Close';
import CardBody from "../Card/CardBody";
import GridContainer from "../Grid/GridContainer";
import AccountOpeningForm from "./AccountOpeningForm";
import AccountList from "./fdr/AccountList";
import {
    CSJsonFormForCasaIndividualDPS,
    CSJsonFormForCasaIndividualTagDPS,
    CSJsonFormForDPSService,
    CSJsonFormForFDRServiceForExistCbOnly,
    CSJsonFormForFDRServiceLast,
    CSJsonFormForNewWithExistCB,
} from "./WorkflowJsonFormArin";
import ErrorModal from "./CommonComponent/ErrorModal";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios/index";
import withStyles from "@material-ui/core/styles/withStyles";
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

class FinacleService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMenu: "NOTSELECTED",
            dstFlag: "",
            agentBankingFlag: "",
            getData: false,
            errorTittle: "",
            errorModalBoolean: false,
        }
    }

    handleMenuSelect(menu) {

        this.setState({selectedMenu: menu})
    }

    componentDidMount() {

        if (LocalstorageEncrypt.encryptStorageFunction.getItem("solId")==="777" || LocalstorageEncrypt.encryptStorageFunction.getItem("solId")===777 || LocalstorageEncrypt.encryptStorageFunction.getItem("solId")==='777') {
            this.setState({
                agentBankingFlag: true,
            })
        }
        else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace").indexOf('CSU') !== -1) {
            this.setState({
                dstFlag: true
            })
        }
        let dedupeTypeObject = {};
        dedupeTypeObject.cbNumber = this.props.getCustomerId;
        dedupeTypeObject.constitution = this.props.cbTypeForAllowAccountOpening;
        if (this.props.subServiceType === "Joint Account") {
            dedupeTypeObject.dedupeType = "JOINT";
            dedupeTypeObject.customerName = this.props.dedupeCustomerName;

        } else if (this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "INDIVIDUAL") {
            dedupeTypeObject.dedupeType = "INDIVIDUAL";
            dedupeTypeObject.customerName = this.props.dedupeCustomerName;
        } else {
            dedupeTypeObject.dedupeType = "CORPORATE";
            dedupeTypeObject.customerName = this.props.dedupeCustomerName;
        }
        let alowOpeningUrl = backEndServerURL + "/dedupe/isCBAllowed";
        axios.post(alowOpeningUrl, dedupeTypeObject, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.data.isAllowed === true) {
                    this.setState({
                        getData: true,
                    })
                } else {
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: response.data.message !== undefined ? response.data.message : "",
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    closeModal = () => {
        this.setState({
            errorModalBoolean: false,
        });
        this.props.closeModal()
    };

    renderServiceMenu(classes) {

        /* if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace").indexOf('CSU') !== -1) {
             return (
                 <Dialog
                     fullWidth="true"
                     maxWidth="xl"
                     fullScreen={true}
                     open={true}>
                     <DialogContent>
                         <AccountOpeningForm
                             closeModal={this.props.closeModal} businessSegment={this.props.businessSegment}
                             getAccountType={this.props.getAccountType}
                             accountType={this.props.accountType}
                             serviceType={this.props.serviceType}
                             dstFlag={true}
                             agentBankingFlag={true}
                             workplace={this.props.workplace}
                             accountOpeningType={this.props.accountOpeningType}
                             getCustomerId={this.props.getCustomerId}
                             jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                             individualDedupData={this.props.individualDedupData}
                             mandateindividualDedupData={this.props.mandateindividualDedupData}
                             beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}

                             jointDedupData={this.props.jointDedupData}
                             companyDedupData={this.props.companyDedupData}
                             sdnData={this.props.sdnData}
                             commonJsonForm={this.props.commonJsonForm}
                             subServiceType={this.props.subServiceType}
                             getType={this.props.getType}
                             searchValue={this.props.searchValue}/>

                     </DialogContent>
                 </Dialog>


             )
         } else {*/
        if (this.state.getData) {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >Customer Services<a><CloseIcon onClick={this.props.closeModal} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a></h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <MenuItem onClick={(event) => {
                                        this.handleMenuSelect("accountOpening")
                                    }}>Account Opening</MenuItem>
                                    <MenuItem onClick={(event) => {
                                        this.handleMenuSelect("fdrOpening")
                                    }}>FDR Opening</MenuItem>
                                    <MenuItem onClick={(event) => {
                                        this.handleMenuSelect("dpsOpening")
                                    }}>DPS Opening</MenuItem>
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            )
        }
        // }
    }

    render() {
        const {classes} = this.props;
        if (this.state.selectedMenu === "accountOpening") {
            return (
                <div>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        fullScreen={true}
                        open={true}>
                        <DialogContent>

                            <AccountOpeningForm
                                closeModal={this.props.closeModal} businessSegment={this.props.businessSegment}
                                getAccountType={this.props.getAccountType}
                                accountType={this.props.accountType}
                                serviceType={this.props.serviceType}
                                workplace={this.props.workplace}
                                dstFlag={this.state.dstFlag}
                                agentBankingFlag={this.state.agentBankingFlag}
                                accountOpeningType={this.props.accountOpeningType}
                                getCustomerId={this.props.getCustomerId}
                                jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                                individualDedupData={this.props.individualDedupData}
                                mandateindividualDedupData={this.props.mandateindividualDedupData}
                                beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}

                                jointDedupData={this.props.jointDedupData}
                                companyDedupData={this.props.companyDedupData}
                                sdnData={this.props.sdnData}
                                commonJsonForm={this.props.commonJsonForm}
                                subServiceType={this.props.subServiceType}
                                getType={this.props.getType}
                                searchValue={this.props.searchValue}/>
                        </DialogContent>
                    </Dialog>

                </div>
            );

        } else if (this.state.selectedMenu === "fdrOpening") {
            return (
                <div>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        fullScreen={true}
                        open={true}>
                        <DialogContent>
                            <AccountList
                                closeModal={this.props.closeModal} businessSegment={this.props.businessSegment}
                                getAccountType={this.props.getAccountType}
                                accountType={this.props.accountType}
                                freeFlag3={this.props.freeFlag3}
                                customerId={this.props.getCustomerId}
                                customerType={this.props.customerType}
                                serviceType="FDR Opening"
                                dstFlag={this.state.dstFlag}
                                agentBankingFlag={this.state.agentBankingFlag}
                                serviceList={CSJsonFormForFDRServiceForExistCbOnly}
                                serviceListLast={CSJsonFormForFDRServiceLast}
                                commonJsonForm={CSJsonFormForNewWithExistCB}
                                subServiceType={this.props.subServiceType}
                                type={this.props.subServiceType}
                                searchValue={this.props.searchValue}
                                jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                                individualDedupData={this.props.individualDedupData}
                                mandateindividualDedupData={this.props.mandateindividualDedupData}
                                beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}

                                jointDedupData={this.props.jointDedupData}
                                companyDedupData={this.props.companyDedupData}
                                sdnData={this.props.sdnData}
                            />
                        </DialogContent>
                    </Dialog>

                </div>
            );

        } else if (this.state.selectedMenu === "dpsOpening") {
            return (
                <div>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        fullScreen={true}
                        open={true}>
                        <DialogContent>
                            <AccountList
                                closeModal={this.props.closeModal} businessSegment={this.props.businessSegment}
                                getAccountType={this.props.getAccountType}
                                accountType={this.props.accountType}
                                freeFlag3={this.props.freeFlag3}
                                customerId={this.props.getCustomerId}
                                customerType={this.props.customerType}
                                dstFlag={this.state.dstFlag}
                                agentBankingFlag={this.state.agentBankingFlag}
                                serviceType="DPS Opening"
                                title="DPS Account Opening -with Existing Operative Account"
                                freeFlag1="DPS Account Opening -with Existing Operative Account"
                                commonJsonForm={CSJsonFormForCasaIndividualDPS}
                                serviceListLast={CSJsonFormForDPSService}
                                serviceList={CSJsonFormForCasaIndividualTagDPS}
                                subServiceType={this.props.subServiceType}
                                type={this.props.subServiceType}
                                searchValue={this.props.searchValue}
                                jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                                individualDedupData={this.props.individualDedupData}
                                mandateindividualDedupData={this.props.mandateindividualDedupData}
                                beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}

                                jointDedupData={this.props.jointDedupData}
                                companyDedupData={this.props.companyDedupData}
                                sdnData={this.props.sdnData}
                            />
                        </DialogContent>
                    </Dialog>

                </div>
            );

        } else {
            return (
                <div>
                    {this.renderServiceMenu(classes)}
                    <Dialog

                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.errorModalBoolean}>
                        <DialogContent className={classes.dialogPaper}>
                            <ErrorModal title={this.state.errorTittle}
                                        closeModal={this.closeModal}
                            />

                        </DialogContent>
                    </Dialog>

                </div>
            );
        }
    }
}

export default withStyles(styles)

(
    FinacleService
)
;
