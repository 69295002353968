const {startReturnSubmission} = require("../../Common/Constant");

function makeReadOnlyObject(object) {
    if (object.hide !== true) {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            returnObjectVariables[i]["readOnly"] = true;
        }
        return returnObject;
    }

}
let day=[
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",

];
function makeGridObject(object) {
    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject;

    for (let i = 0; i < returnObjectVariables.length; i++) {
        if(returnObjectVariables[i]["type"]==="title"){
            returnObjectVariables[i]["grid"] = 12;

        }
        else{
            returnObjectVariables[i]["grid"] = 6;

        }
    }
    return returnObject;


}
function listOfReturnSubmission(date) {
 /*     let currentDate=new Date();
      let dataList=[];
      for (let i = date; i < currentDate.getFullYear(); i++) {
          dataList.push(date+"-"+Number(date+1));
          i=date+1;
       }*/
    let dataList=[
        "2017-2018",
        "2018-2019",
        "2019-2020",
        "2020-2021",
        "2021-2022",
        "2022-2023",
    ];
     return dataList;


}
function makeGridObjectReadOnlyFalse(object) {
    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject;

    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["readOnly"] = false;
    }
    return returnObject;


}

//#####Customer search#############
let CSjsonFormIndividualAccountOpeningCustomerSearch = [
    {
        "varName": "cbNumber",
        "type": "textDedup",
        "label": "Existing CB Number",
        "errorMessage": "Error",
        "grid": 2,


    },

    /*
        {
            "varName": "customerName",
            "type": "textDedup",
            "label": "Customer Name",
            "multiline": true,
            "grid": 2,


        },*/
    {
        "varName": "nid",
        "type": "textDedup",
        "label": "NID",
        "errorMessage": "Error",
        "grid": 2,

    },
    {
        "varName": "smartCard",
        "type": "textDedup",
        "validation": "smartCard",
        "label": "Smart Card",
        "errorMessage": "Error",
        "grid": 2,


    },
    {
        "varName": "passport",
        "type": "textDedup",
        "label": "Passport",
        "grid": 2,


    },
    {
        "varName": "registrationNo",
        "type": "textDedup",
        "label": "Birth Cer.",
        "grid": 2,
    },
    {
        "varName": "drivingLicense",
        "type": "textDedup",
        "label": "Driving License",
        "grid": 2,
    },
    /*  {
          "varName": "dob",
          "type": "date",
          "label": "Date Of Birth",
          "maxDate": true,
          "grid": 2,


      },*/
    {
        "varName": "phone",
        "type": "textDedup",
        "phonePrefix": true,
        "label": "Mobile Number",
        "grid": 2,


    },
    {
        "varName": "email",
        "type": "textDedup",
        "multiline": true,
        "label": "Email",
        "grid": 2,


    },


    {
        "varName": "tin",
        "type": "textDedup",
        "etin": true,
        "label": "E-Tin",
        "grid": 2,


    },
    {
        "varName": "certificate",
        "type": "textDedup",
        "label": "Certificate Of Incorporation",
        "grid": 2,


    },
    {
        "varName": "tradeLicense",
        "type": "textDedup",
        "label": "Trade license",
        "grid": 2,


    },


];
//#####Customer searchs#############
let CSjsonFormIndividualAccountOpeningCustomerNameSearch = [
    {
        "varName": "cbNumber",
        "type": "textDedup",
        "label": "Existing CB Number",
        "errorMessage": "Error",
        "grid": 2,


    },


    {
        "varName": "customerName",
        "type": "textDedup",
        "label": "Customer Name",
        "multiline": true,
        "grid": 2,


    },
    {
        "varName": "nid",
        "type": "textDedup",
        "label": "NID",
        "errorMessage": "Error",
        "grid": 2,

    },
    {
        "varName": "passport",
        "type": "textDedup",
        "label": "Passport",
        "grid": 2,


    },
    {
        "varName": "registrationNo",
        "type": "textDedup",
        "label": "Birth Cer.",
        "grid": 2,
    },
    {
        "varName": "drivingLicense",
        "type": "textDedup",
        "label": "Driving License",
        "grid": 2,
    },
    /*  {
          "varName": "dob",
          "type": "date",
          "label": "Date Of Birth",
          "maxDate": true,
          "grid": 2,


      },*/
    {
        "varName": "phone",
        "type": "textDedup",
        "phonePrefix": true,
        "label": "Mobile Number",
        "grid": 2,


    },
    {
        "varName": "email",
        "type": "textDedup",
        "multiline": true,
        "label": "Email",
        "grid": 2,


    },


    {
        "varName": "tin",
        "type": "textDedup",
        "etin": true,
        "label": "E-Tin",
        "grid": 2,


    },
    {
        "varName": "certificate",
        "type": "textDedup",
        "label": "Certificate Of Incorporation",
        "grid": 2,


    },
    {
        "varName": "tradeLicense",
        "type": "textDedup",
        "label": "Trade license",
        "grid": 2,


    },


];


//#####DDeup Search Individual Form#############
let CSjsonFormIndividualAccountOpeningSearch = [
    {
        "varName": "cbNumber",
        "type": "textDedup",
        "label": "Existing CB Number",
        "errorMessage": "Error",
        "grid": 2,


    },
     {
        "varName": "accountNumber",
        "type": "textDedup",
        "label": "Account Number",
        "errorMessage": "Error",
        "grid": 2,


    },


    {
        "varName": "customerName",
        "type": "textDedup",
        "label": "Customer Name *",
        "asteriks": true,
        "multiline": true,
        "grid": 2,


    },
    {
        "varName": "nid",
        "type": "textDedup",
        "validation": "nid",
        "label": "NID +",
        "errorMessage": "Error",
        "grid": 2,

    },
    {
        "varName": "smartCard",
        "type": "textDedup",
        "validation": "smartCard",
        "label": "Smart Card +",
        "errorMessage": "Error",
        "grid": 2,


    },
    {
        "varName": "passport",
        "type": "textDedup",
        "validation": "passport",
        "label": "Passport +",
        "grid": 2,


    },
    {
        "varName": "registrationNo",
        "type": "textDedup",
        "validation": "birthCertificateNumber",
        "label": "Birth Cer. +",
        "grid": 2,
    },
    {
        "varName": "drivingLicense",
        "type": "textDedup",
        "label": "Driving License",
        "grid": 2,
    },
    {
        "varName": "dob",
        "type": "date",
        "label": "Date Of Birth *",
        "asteriks": true,
        "maxDate": true,
        "grid": 2,


    },
    {
        "varName": "mobileTypeIndividual",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        // "required":true,
        "label": "Mobile Type ",
        "asteriks": true,

        "grid": 2,
    },

    {
        "varName": "countryCodeIndividual",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code ",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "phone",
        "type": "textDedup",
        "phonePrefix": true,
        "validation": "phone",
        "label": "Mobile Number ",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Local",
        "grid": 2,


    },
    {
        "varName": "phone",
        "type": "textDedup",
        "label": "Mobile Number",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",
        "grid": 2,


    },
    {
        "varName": "email",
        "type": "textDedup",
        "validation": "email",
        "multiline": true,
        "label": "Email",
        "grid": 2,


    },


    {
        "varName": "tin",
        "type": "textDedup",
        "etin": true,
        "validation": "etin",
        "label": "E-Tin",
        "grid": 2,


    },

    {
        "varName": "nationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Nationality *",
        "asteriks": true,

        "grid": 2,
    },
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "varName": "mandate",
        "type": "select",
        "enum": [
            "YES",
            "NO"
        ],
        "label": "Mandate ",
        "conditional": true,
        "conditionalVarName": "nidBlockShowing",
        "conditionalVarValue": true,
        "grid": 2,

    },
    {
        "varName": "beneficiary",
        "type": "select",
        "enum": [
            "YES",
            "NO"
        ],
        "label": "Beneficial Owner",
        "conditional": true,
        "conditionalVarName": "nidBlockShowing",
        "conditionalVarValue": true,
        "grid": 2,

    },


];
let CSjsonFormIndividualAccountOpeningSearchmandate = [

    {
        "type": "empty",
        "grid": 12,

    },
    {
        "varName": "applicant",
        "type": "titleLeft",
        "label": "Mandate Dedupe",
        "grid": 12,

    },
    {
        "varName": "mandatecbNumber",
        "type": "textDedup",
        "label": "Existing CB Number",
        "errorMessage": "Error",
        "grid": 2,


    },
    {
        "varName": "mandateaccountNumber",
        "type": "textDedup",
        "label": "Account Number",
        "errorMessage": "Error",
        "grid": 2,


    },

    {
        "varName": "mandatecustomerName",
        "type": "textDedup",
        "label": "Customer Name *",
        "asteriks": true,
        "multiline": true,
        "grid": 2,


    },
    {
        "varName": "mandatenid",
        "type": "textDedup",
        "validation": "nid",
        "label": "NID +",
        "errorMessage": "Error",
        "grid": 2,


    },
    {
        "varName": "mandatesmartCard",
        "type": "textDedup",
        "validation": "smartCard",
        "label": "Smart Card +",
        "errorMessage": "Error",
        "grid": 2,


    },
    {
        "varName": "mandatepassport",
        "type": "textDedup",
        "validation": "passport",
        "label": "Passport +",
        "grid": 2,


    },
    {
        "varName": "mandateregistrationNo",
        "type": "textDedup",
        "validation": "birthCertificateNumber",
        "label": "Birth Cer. +",
        "grid": 2,

    },
    {
        "varName": "mandateDrivingLicense",
        "type": "textDedup",
        "label": "Driving License +",
        "grid": 2,

    },
    {
        "varName": "mandatedob",
        "type": "date",
        "label": "Date Of Birth *",
        "asteriks": true,
        "maxDate": true,
        "grid": 2,


    },
    {
        "varName": "mobileTypeMandate",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        // "required":true,
        "label": "Mobile Type ",
        "asteriks": true,

        "grid": 2,
    },

    {
        "varName": "countryCodeMandate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code ",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeMandate",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "mandatephone",
        "type": "textDedup",
        "phonePrefix": true,
        "validation": "phone",
        "label": "Mobile Number ",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeMandate",
        "conditionalVarValue": "Local",
        "grid": 2,


    },
    {
        "varName": "mandatephone",
        "type": "text",
        "label": "Mobile Number",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeMandate",
        "conditionalVarValue": "Overseas",
        "grid": 2,


    },
    /*{
        "varName": "mandatephone",
        "type": "textDedup",
        "phonePrefix": true,
        "validation": "phone",
        "label": "Mobile Number ",
        "asteriks": true,
        "grid": 2,


    },*/
    {
        "varName": "mandateemail",
        "type": "textDedup",
        "validation": "email",
        "multiline": true,
        "label": "Email",
        "grid": 2,


    },


    {
        "varName": "mandatetin",
        "type": "textDedup",
        "etin": true,
        "validation": "etin",
        "label": "E-Tin",
        "grid": 2,


    },

    {
        "varName": "mandatenationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Nationality *",
        "asteriks": true,


        "grid": 2,
    },

];
let CSjsonFormIndividualAccountOpeningSearchBeneficiary = [

    {
        "type": "empty",
        "grid": 12,

    },

    {
        "varName": "applicant",
        "type": "titleLeft",
        "label": "Beneficial Owner Dedupe",
        "grid": 12,

    },
    {
        "varName": "beneficiarycbNumber",
        "type": "textDedup",
        "label": "Existing CB Number",
        "errorMessage": "Error",
        "grid": 2,


    },
    {
        "varName": "beneficiaryaccountNumber",
        "type": "textDedup",
        "label": "Account Number",
        "errorMessage": "Error",
        "grid": 2,


    },

    {
        "varName": "beneficiarycustomerName",
        "type": "textDedup",
        "label": "Customer Name *",
        "asteriks": true,
        "multiline": true,
        "grid": 2,


    },
    {
        "varName": "beneficiarynid",
        "type": "textDedup",
        "validation": "nid",
        "label": "NID +",
        "errorMessage": "Error",
        "grid": 2,


    },
    {
        "varName": "beneficiarysmartCard",
        "type": "textDedup",
        "validation": "smartCard",
        "label": "Smart Card +",
        "errorMessage": "Error",
        "grid": 2,


    },
    {
        "varName": "beneficiarypassport",
        "type": "textDedup",
        "validation": "passport",
        "label": "Passport +",
        "grid": 2,


    },
    {
        "varName": "beneficiaryregistrationNo",
        "type": "textDedup",
        "validation": "birthCertificateNumber",
        "label": "Birth Cer. +",
        "grid": 2,

    },
    {
        "varName": "beneficiaryDrivingLicense",
        "type": "textDedup",
        "label": "Driving License +",
        "grid": 2,

    },
    {
        "varName": "beneficiarydob",
        "type": "date",
        "label": "Date Of Birth *",
        "asteriks": true,
        "maxDate": true,
        "grid": 2,


    },
    {
        "varName": "mobileTypeBeneficiary",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        // "required":true,
        "label": "Mobile Type ",
        "asteriks": true,

        "grid": 2,
    },
    {
        "varName": "countryCodeBeneficiary",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code ",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeBeneficiary",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "beneficiaryphone",
        "type": "textDedup",
        "phonePrefix": true,
        "validation": "phone",
        "label": "Mobile Number ",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeBeneficiary",
        "conditionalVarValue": "Local",
        "grid": 2,


    },
    {
        "varName": "beneficiaryphone",
        "type": "text",
        "label": "Mobile Number",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeBeneficiary",
        "conditionalVarValue": "Overseas",
        "grid": 2,


    },
    /*{
        "varName": "beneficiaryphone",
        "type": "textDedup",
        "phonePrefix": true,
        "validation": "phone",
        "label": "Mobile Number ",
        "asteriks": true,
        "grid": 2,


    },*/
    {
        "varName": "beneficiaryemail",
        "type": "textDedup",
        "validation": "email",
        "multiline": true,
        "label": "Email",
        "grid": 2,


    },


    {
        "varName": "beneficiarytin",
        "type": "textDedup",
        "etin": true,
        "validation": "etin",
        "label": "E-Tin",
        "grid": 2,


    },

    {
        "varName": "beneficiarynationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Nationality *",
        "asteriks": true,


        "grid": 2,
    },


];
//#####DDeup Search NonIndividual/Propritorship Form#############final
let CSjsonFormNonIndividualProprietorshipAccountOpeningSearch = [
    {
        "varName": "companyTitle",
        "type": "titleLeft",
        "label": "Company/Business",
        "grid": 12,

    },
    {
        "varName": "companyCbNumber",
        "type": "textDedup",
        "label": "Existing CB Number",
        "grid": 2,
    },
    {
        "varName": "companyAccountNumber",
        "type": "textDedup",
        "label": "Account Number",
        "errorMessage": "Error",
        "grid": 2,


    },
    {
        "varName": "companyName",
        "type": "textDedup",
        "multiline": true,
        "label": "Company Name  *",
        "asteriks": true,
        "grid": 2,


    },

    {
        "varName": "companyEmail",
        "type": "textDedup",
        "validation": "email",
        "label": "Email",
        "grid": 2,


    },
    {
        "varName": "mobileTypeProprietorship",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        // "required":true,
        "label": "Mobile Type ",
        "asteriks": true,

        "grid": 2,
    },
    {
        "varName": "countryCodeCompanyProprietorship",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code ",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeProprietorship",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "companyMobileNo",
        "type": "text",
        "phonePrefix": true,
        "validation": "phone",
        "label": "Mobile Number ",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeProprietorship",
        "conditionalVarValue": "Local",
        "grid": 2,


    },
    {
        "varName": "companyMobileNo",
        "type": "text",
        "label": "Mobile Number",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeProprietorship",
        "conditionalVarValue": "Overseas",
        "grid": 2,


    },
    /*{
        "varName": "companyMobileNo",
        "type": "textDedup",
        "validation": "phone",
        "label": "Mobile Number",
        "grid": 2,

    },*/

    {
        "varName": "companyEtin",
        "type": "textDedup",
        //"validation": "etin",
        //"etin": true,
        "label": "Company E-TIN ",
        "asteriks": true,
        "grid": 2,

    },
    {
        "varName": "companyTradeLicense",
        "type": "textDedup",
        "label": "Trade License *",
        "asteriks": true,
        "grid": 2,


    },
    {
        "varName": "registrationNumber",
        "type": "textDedup",
        "label": "Registration Number *",
        "asteriks": true,
        "grid": 2,


    },
    {
        "varName": "certificateOfIncorporation",
        "type": "textDedup",
        "label": "Certificate Of Incorporation *",
        "asteriks": true,
        "grid": 2,


    },
    {
        "varName": "certificateOfCommencement",
        "type": "textDedup",
        "label": "Certificate Of Commencement *",
        "asteriks": true,
        "grid": 2,


    },
    {
        "varName": "ngoBureauCertificate",
        "type": "textDedup",
        "label": "NGO Bureau Certificate *",
        "asteriks": true,
        "grid": 2,


    },
    {
        "varName": "societyRegistrationNumber",
        "type": "textDedup",
        "label": "Society Registration Number *",
        "asteriks": true,
        "grid": 2,


    },
    {
        "varName": "clubRegistrationNumber",
        "type": "textDedup",
        "label": "Club Registration Number *",
        "asteriks": true,
        "grid": 2,


    },
    {
        "varName": "governmentPermissionLetter",
        "type": "textDedup",
        "label": "Government Permission Letter *",
        "asteriks": true,
        "grid": 2,


    },
    {
        "varName": "otherIdCorporate",
        "type": "textDedup",
        "label": "other Id Corporate *",
        "asteriks": true,
        "grid": 2,


    },
    {
        "varName": "nationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Nationality",
        "conditional": true,
        "conditionalVarName": "companyDedupeBlockBoolean",
        "conditionalVarValue": true,
        "grid": 2,
    }

];
let CSjsonFormNonIndividualWithoutProprietorshipAccountOpeningSearch = [
    {
        "varName": "companyTitle",
        "type": "titleLeft",
        "label": "Company/Business",
        "grid": 12,

    },
    {
        "varName": "companyCbNumber",
        "type": "textDedup",
        "label": "Existing CB Number",
        "grid": 2,
    },
    {
        "varName": "companyAccountNumber",
        "type": "textDedup",
        "label": "Account Number",
        "errorMessage": "Error",
        "grid": 2,


    },
    {
        "varName": "companyName",
        "type": "textDedup",
        "multiline": true,
        "label": "Company Name *",
        "grid": 2,


    },

    {
        "varName": "companyEmail",
        "type": "textDedup",
        "validation": "email",
        "label": "Email",
        "grid": 2,


    },
    {
        "varName": "mobileTypeWithoutProprietorshipCompany",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        // "required":true,
        "label": "Mobile Type ",
        "asteriks": true,

        "grid": 2,
    },
    {
        "varName": "countryCodeWithoutProprietorshipCompany",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code ",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeWithoutProprietorshipCompany",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "companyMobileNo",
        "type": "text",
        "phonePrefix": true,
        "validation": "phone",
        "label": "Mobile Number ",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeWithoutProprietorshipCompany",
        "conditionalVarValue": "Local",
        "grid": 2,


    },
    {
        "varName": "companyMobileNo",
        "type": "text",
        "label": "Mobile Number",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeWithoutProprietorshipCompany",
        "conditionalVarValue": "Overseas",
        "grid": 2,


    },
    /* {
         "varName": "companyMobileNo",
         "type": "textDedup",
         "validation": "phone",
         "label": "Mobile Number",
         "grid": 2,

     },*/

    {
        "varName": "companyEtin",
        "type": "textDedup",
        "validation": "etin",
        "label": "Company E-TIN",
        "grid": 2,

    },
    {
        "varName": "companyTradeLicense",
        "type": "textDedup",
        "label": "Trade License *",
        "grid": 2,


    },
    {
        "varName": "certificate",
        "type": "textDedup",
        "label": "Certificate Of Incorporation",
        "grid": 2,


    },
    {
        "varName": "companyIndividualTitle",
        "type": "titleLeft",
        "label": "Proprietor Dedupe",
        "grid": 12,


    },
    {
        "varName": "cbNumber",
        "type": "textDedup",
        "label": "CB Number",
        "grid": 2,


    },

    {
        "varName": "nid",
        "type": "textDedup",
        "validation": "nid",
        "label": "NID +",
        "grid": 2,

    },
    {
        "varName": "smartCard",
        "type": "textDedup",
        "validation": "smartCard",
        "label": "Smart Card",
        "errorMessage": "Error",
        "grid": 2,


    },
    {
        "varName": "passport",
        "type": "textDedup",
        "validation": "passport",
        "label": "Passport +",
        "grid": 2,


    },
    {
        "varName": "customerName",
        "type": "textDedup",
        "label": "Customer Name *",
        "multiline": true,
        "grid": 2,


    },
    {
        "varName": "dob",
        "type": "date",
        "label": "Date Of Birth *",
        "grid": 2,


    },
    {
        "varName": "email",
        "type": "textDedup",
        "validation": "email",
        "multiline": true,
        "label": "Email",
        "grid": 2,


    },
    {
        "varName": "mobileTypeWithoutProprietorship",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        // "required":true,
        "label": "Mobile Type ",
        "asteriks": true,

        "grid": 2,
    },
    {
        "varName": "countryCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code ",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeWithoutProprietorship",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "phone",
        "type": "text",
        "phonePrefix": true,
        "validation": "phone",
        "label": "Mobile Number ",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeWithoutProprietorship",
        "conditionalVarValue": "Local",
        "grid": 2,


    },
    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile Number",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeWithoutProprietorship",
        "conditionalVarValue": "Overseas",
        "grid": 2,


    },
    /* {
         "varName": "phone",
         "type": "textDedup",
         "validation": "phone",
         "phonePrefix": true,
         "label": "Mobile Number ",
         "grid": 2,


     },*/

    {
        "varName": "tin",
        "type": "textDedup",
        "validation": "etin",
        "label": "E-Tin",
        "grid": 2,


    },
    {
        "varName": "registrationNo",
        "type": "textDedup",
        "validation": "birthCertificateNumber",
        "label": "Birth Cer.+",
        "grid": 2,

    },
    {
        "varName": "drivingLicense",
        "type": "textDedup",
        "label": "Driving License +",
        "grid": 2,

    },
    {
        "varName": "nationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Nationality *",
        "grid": 2,
    },


];
let CSjsonFormNonIndividualCompanyAccountOpeningSearch = [
    {
        "varName": "companyTitle",
        "type": "titleLeft",
        "label": "Company dedupe",
        "grid": 12,

    },
    {
        "varName": "companyCbNumber",
        "type": "textDedup",
        "label": "Existing CB Number",
        "grid": 2,
    },
    {
        "varName": "companyAccountNumber",
        "type": "textDedup",
        "label": "Account Number",
        "errorMessage": "Error",
        "grid": 2,


    },
    {
        "varName": "companyName",
        "type": "textDedup",
        "multiline": true,
        "label": "Company Name *",
        "asteriks": true,
        "grid": 2,
    },

    {
        "varName": "companyEmail",
        "type": "textDedup",
        "validation": "email",
        "label": "Email",

        "grid": 2,


    },
    {
        "varName": "mobileTypeNonIndividualCompany",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        // "required":true,
        "label": "Mobile Type ",
        "asteriks": true,

        "grid": 2,
    },
    {
        "varName": "countryCodeNonIndividualCompany",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code ",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeNonIndividualCompany",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "companyMobileNo",
        "type": "text",
        "phonePrefix": true,
        "validation": "phone",
        "label": "Mobile Number ",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeNonIndividualCompany",
        "conditionalVarValue": "Local",
        "grid": 2,


    },
    {
        "varName": "companyMobileNo",
        "type": "text",
        "label": "Mobile Number",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeNonIndividualCompany",
        "conditionalVarValue": "Overseas",
        "grid": 2,


    },
    /* {
         "varName": "companyMobileNo",
         "type": "textDedup",
         "validation": "phone",
         "label": "Mobile Number",

         "grid": 2,

     },*/

    {
        "varName": "companyEtin",
        "type": "textDedup",
        "validation": "etin",
        "label": "Company E-TIN",
        "asteriks": true,
        "grid": 2,


    },
    {
        "varName": "companyTradeLicense",
        "type": "textDedup",
        "label": "Trade License *",
        "asteriks": true,
        "grid": 2,


    },

    {
        "varName": "certificate",
        "type": "textDedup",
        "label": "Certificate Of Incorporation",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "withoutpartnershipFlag",
        "conditionalVarValue": true,

    },
    /* {
         "varName": "individualDedupe",
         "type": "checkbox",
         "label": "Individual Dedupe?",
         "asteriks": true,
         "grid": 2,


     },*/

];
let CSjsonFormNonIndividualNGOSOCIETYAccountOpeningSearch = [

    {
        "varName": "companyTitle",
        "type": "titleLeft",
        "label": "NGO Society Company dedupe",
        "grid": 12,

    },


    {
        "varName": "companyName",
        "type": "textDedup",
        "multiline": true,
        "label": "Company Name *",
        "asteriks": true,
        "grid": 2,
    },

    {
        "varName": "companyEmail",
        "type": "textDedup",
        "validation": "email",
        "label": "Email",

        "grid": 2,


    },
    {
        "varName": "mobileTypeNGOSOCIETY",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        // "required":true,
        "label": "Mobile Type ",
        "asteriks": true,

        "grid": 2,
    },
    {
        "varName": "countryCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code ",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeNGOSOCIETY",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "companyMobileNo",
        "type": "text",
        "phonePrefix": true,
        "validation": "phone",
        "label": "Mobile Number ",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeNGOSOCIETY",
        "conditionalVarValue": "Local",
        "grid": 2,


    },
    {
        "varName": "companyMobileNo",
        "type": "text",

        "label": "Mobile Number",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeNGOSOCIETY",
        "conditionalVarValue": "Overseas",
        "grid": 2,


    },
    /* {
         "varName": "companyMobileNo",
         "type": "textDedup",
         "validation": "phone",
         "label": "Mobile Number",

         "grid": 2,

     },*/
    {
        "varName": "ngoBureauPermission",
        "type": "textDedup",
        "validation": "phone",
        "label": "NGO Bureau Permission",

        "grid": 2,

    },


    {
        "varName": "certificate",
        "type": "textDedup",
        "label": "Certificate of Registration under Societies Act",
        "grid": 2,

    },
    {
        "varName": "nationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Nationality",
        "grid": 2,
    },

];
//#####################CASA New Individual#####################################################
/*let CBDataJsonForm = [
    {
        "type": "title",
        "label": "CB Information",
        "grid": 12,
    },
    {
        "varName": "acCummprimarySolId",
        "type": "text",
        "label": "Primary Sol ID",
        "grid": 6,
    },

    {
        "varName": "acCummcustomerIdCifId",
        "type": "text",
        "label": "Customer ID/CIF ID",
        "grid": 6,
    },

    {
        "varName": "acCummtitle",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Title",
        "grid": 6,
    },

    {
        "varName": "acCummcustomerNameLastName",
        "type": "text",
        "label": "Customer Name/Last Name",
        "grid": 6,
    },

    {
        "varName": "acCummshortName",
        "type": "text",
        "label": "Short Name",
        "grid": 6,
    },

    {
        "varName": "acCummgender",
        "type": "select",
        "enum": [
            "M",
            "F",
            "Other"
        ],
        "label": "Gender",
        "grid": 6,
    },

    {
        "varName": "acCummcustomerStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Customer Status",
        "grid": 6,
    },

    {
        "varName": "acCummoccupationCodePriorityCodeStaff",
        "type": "text",
        "label": "Occupation Code/Priority Code/Staff",
        "grid": 6,
    },

    {
        "varName": "acCummconstitution",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Constitution",
        "grid": 6,
    },

    {
        "varName": "acCummstaffIndicatorStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Staff Indicator/Status",
        "grid": 6,
    },

    {
        "varName": "acCummstaffIdNumber",
        "type": "text",
         "label": "Staff ID Number",
        "validation":"staffId",
        "grid": 6,
    },

    {
        "varName": "acCummpriorityCenterCodeFreeCode1",
        "type": "text",
        "label": "Priority Center Code/Free Code 1",
        "grid": 6,
    },

    {
        "varName": "acCummpriorityMonitoringRmCodeFreeCode3",
        "type": "text",
        "label": "Priority Monitoring  RM Code/Free Code 3",
        "grid": 6,
    },

    {
        "varName": "acCummprimaryCbHolderForPriorityFreeText8",
        "type": "text",
        "label": "Primary CB Holder for Priority/Free Text 8",
        "grid": 6,
    },

    {
        "varName": "acCummrelationshipWithPrimaryCbHolderFreeText9",
        "type": "text",
        "label": "Relationship with Primary CB Holder/Free Text 9",
        "grid": 6,
    },

    {
        "varName": "acCummbbkSegmentCodeFreeCode7",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "BBK Segment Code/Free Code 7",
        "grid": 6,
    },

    {
        "varName": "acCummdebitCardIssueFreeCode10",
        "type": "text",
        "label": "Debit Card Issue/Free Code 10",
        "grid": 6,
    },

    {
        "varName": "acCummmigratedNidVerificationFreeText2",
        "type": "text",
        "label": "Migrated NID Verification/Free Text 2",
        "grid": 6,
    },

    {
        "varName": "acCummmigratedSmartCardNumberFreeText5",
        "type": "text",
        "label": "Migrated Smart Card Number Free Text 5",
        "grid": 6,
    },

    {
        "varName": "acCummpepStatusFreeText10",
        "type": "text",
        "label": "PEP Status/Free Text 10",
        "grid": 6,
    },

    {
        "varName": "acCummmigratedCifsDocumentNoFreeText13",
        "type": "text",
        "label": "Migrated CIFs Document No/Free Text 13",
        "grid": 6,
    },

    {
        "varName": "acCummfreeCodeFreeText",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "acCummfreeCodeFreeText",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "acCummfreeCodeFreeText",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "acCummavailedTradeServices",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Availed Trade services",
        "grid": 6,
    },

    {
        "varName": "acCummcurrencyCodeCcy",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Currency Code/CCY",
        "grid": 6,
    },

    {
        "varName": "acCummcommAddressSelection",
        "type": "select",
        "enum": [
            "Present",
            "Permanent",
            "Office",
        ],
        "label": "Comm. Address Selection ",
        "grid": 6,
    },

    {
        "varName": "acCummemailDesignatedEmailAddress",
        "type": "text",
        "label": "Email/Designated Email Address",
        "grid": 6,
    },

    {
        "varName": "acCummdesignatedMobileNumberPhoneNo1",
        "type": "text",
        "label": "Designated Mobile Number/Phone No 1",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Introducer Details",
        "grid": 12,
    },
    {
        "varName": "introducerintroducerCustomerIdCifId",
        "type": "text",
        "label": "Introducer Customer Id/CIF ID",
        "grid": 6,
    },

    {
        "varName": "introducerintroducerNameLastName",
        "type": "text",
        "label": "Introducer Name/Last Name",
        "grid": 6,
    },

    {
        "varName": "introducerintroducerStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Introducer Status",
        "grid": 6,
    },

    {
        "varName": "introducercifTypePrimaryIntroducerDetails",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "CIF Type/Primary Introducer Details",
        "grid": 6,
    },


    {
        "varName": "otherBankbankName",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Bank Name",
        "grid": 6,
    },

    {
        "varName": "otherBankbranchName",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Branch Name",
        "grid": 6,
    },

    {
        "varName": "otherBanksubSegment",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Segment",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Basic Information Update",
        "grid": 12,
    },

    {
        "varName": "basicInformationprimarySolId",
        "type": "text",
        "label": "Primary Sol ID",
        "grid": 6,
    },

    {
        "varName": "basicInformationgender",
        "type": "select",
        "enum": [
            "M",
            "L",
            "OTHER",
        ],
        "label": "Gender",
        "grid": 6,
    },

    {
        "varName": "basicInformationfatherNamePrefferedName",
        "type": "text",
        "label": "Father Name/Preffered Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationmotherNameMothersMaidenName",
        "type": "text",
        "label": "Mother Name/Mothers Maiden Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationmaritalStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Marital Status",
        "grid": 6,
    },

    {
        "varName": "basicInformationspouseNamePurgeRemarks",
        "type": "text",
        "label": "Spouse Name/Purge Remarks",
        "grid": 6,
    },

    {
        "varName": "basicInformationdob",
        "type": "date",
        "label": "DOB",
        "grid": 6,
    },

    {
        "varName": "basicInformationminorStatusMinorIndicator",
        "type": "select",
        "enum":[
            "YES",
            "NO",
        ],
        "label": "Minor Status/Minor Indicator",
        "grid": 6,
    },

    {
        "varName": "basicInformationseniorCitizenIndicator",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Senior Citizen Indicator",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Guardian Details",
        "grid": 12,
    },
    {
        "varName": "guardianRelationrelation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation",
        "grid": 6,
    },

    {
        "varName": "guardianRelationbankRelationType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Bank Relation Type",
        "grid": 6,
    },

    {
        "varName": "guardianRelationrelation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation",
        "grid": 6,
    },

    {
        "varName": "guardianRelationguardianSelectionGurdian",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Guardian Selection/Gurdian",
        "grid": 6,
    },

    {
        "varName": "guardianRelationgurdianCifNumberCustomer",
        "type": "text",
        "label": "Gurdian CIF Number/Customer ",
        "grid": 6,
    },

    {
        "varName": "guardianRelationlastName",
        "type": "text",
        "label": "Last Name",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "National ID Card",
        "grid": 12,
    },

    {
        "varName": "nationalIdCarddocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "nationalIdCarddocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "nationalIdCarddocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "nationalIdCardnationalIdCardNumberUniqueId",
        "type": "text",
        "label": "National ID Card Number/Unique ID",
        "grid": 6,
    },

    {
        "varName": "nationalIdCardnidVerificationConfirmationIsDocumentVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
    },

    {
        "varName": "cdissueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Smart ID Card",
        "grid": 12,
    },
    {
        "varName": "smartlIdCarddocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "smartlIdCarddocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "smartlIdCarddocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "smartlIdCardsmartCardNumberUniqueId",
        "type": "text",
        "label": "Smart Card Number/Unique ID",
        "grid": 6,
    },

    {
        "varName": "smartlIdCardnidVerificationConfirmationIsDocumentVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
    },

    {
        "varName": "smartlIdCardissueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Passport Number",
        "grid": 12,
    },
    {
        "varName": "passportNumberdocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "passportNumberdocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "passportNumberdocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "passportNumberpassportNumberUniqueId",
        "type": "text",
        "label": "Passport Number/Unique ID",
        "grid": 6,
    },

    {
        "varName": "passportNumberplaceOfIssue",
        "type": "text",
        "label": "Place of Issue",
        "grid": 6,
    },

    {
        "varName": "passportNumberissueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
    },

    {
        "varName": "passportNumbercountryOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country of Issue",
        "grid": 6,
    },

    {
        "varName": "passportNumberexpiryDate",
        "type": "date",
        "label": "Expiry Date",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Birth Certificate",
        "grid": 12,
    },
    {
        "varName": "birthCertificatedocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "birthCertificatedocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "birthCertificatedocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "birthCertificatebirthCertificateNumberUniqueId",
        "type": "text",
        "label": "Birth Certificate Number/Unique ID",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Chairman Certificate",
        "grid": 12,
    },
    {
        "varName": "chairmanCertificatedocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificatedocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificatedocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificatechairmanCertificateUniqueId",
        "type": "text",
        "label": "Chairman Certificate/Unique ID",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificateplaceOfBirthFreeText14",
        "type": "text",
        "label": "Place of Birth/Free Text 14",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificatecountryOfBirthFreeText15",
        "type": "text",
        "label": "Country of Birth/Free Text 15",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificatenationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Nationality",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificatenonResident",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Non Resident",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificateturnedNonResidentOn",
        "type": "date",
        "label": "Turned Non Resident On",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "E-Tin",
        "grid": 12,
    },
    {
        "varName": "etindocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "etindocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "etindocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "etinetinNumber",
        "type": "text",
        "label": "E-Tin Number/Unique ID",
        "grid": 6,
    },

    {
        "varName": "etinprofessionEmploymentType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Profession /Employment Type",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Employer Data",
        "grid": 12,
    },
    {
        "varName": "employerDataemploymentType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Employment Type",
        "grid": 6,
    },

    {
        "varName": "employerDatanameOfEmployerEmployerName",
        "type": "text",
        "label": "Name of Employer/Employer Name",
        "grid": 6,
    },

    {
        "varName": "employerDatamonthlyIncomeGrossIncome",
        "type": "text",
        "label": "Monthly Income/Gross income",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Contact Number - Phone",
        "grid": 12,
    },
    {
        "varName": "contactNumberpreferredContactNoType1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Preferred Contact no type",
        "grid": 6,
    },

    {
        "varName": "contactNumbercontactType1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNoEmailId1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNo1",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },
    {
        "varName": "contactNumberpreferredContactNoType2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Preferred Contact no type",
        "grid": 6,
    },
    {
        "varName": "contactNumbercontactType2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNoEmailId2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNo2",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },
    {
        "varName": "contactNumberpreferredContactNoType3",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Preferred Contact no type",
        "grid": 6,
    },
    {
        "varName": "contactNumbercontactType3",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNoEmailId3",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNo3",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Contact Number - Email",
        "grid": 12,
    },
    {
        "varName": "contactNumberEmailpreferedEmailIdType1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Prefered Email ID type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailType1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmaildesignatedEmailAddressEmailId11",
        "type": "text",
        "label": "Designated Email Address/Email ID 1",
        "grid": 6,
    },
    {
        "varName": "contactNumberEmailpreferedEmailIdType2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Prefered Email ID type",
        "grid": 6,
    },
    {
        "varName": "contactNumberEmailemailType2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailId22",
        "type": "text",
        "label": "Email ID 2",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Mailing Address",
        "grid": 12,
    },
    {
        "varName": "mailingAddresspreferredAddressType1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Preferred Address type",
        "grid": 6,
    },

    {
        "varName": "mailingAddressaddressFormat1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "mailingAddressaddrssType1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "mailingAddressaddressLabel1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "mailingAddressmailingAddressField11",
        "type": "text",
        "label": "Mailing Address Field 1",
        "grid": 6,
    },

    {
        "varName": "mailingAddressmailingAddressField21",
        "type": "text",
        "label": "Mailing Address Field 2",
        "grid": 6,
    },

    {
        "varName": "mailingAddresscity1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "mailingAddressstate1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
    },

    {
        "varName": "mailingAddresspostalCode1",
        "type": "text",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 6,
    },

    {
        "varName": "mailingAddresscountry1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Home Address",
        "grid": 12,
    },
    {
        "varName": "homeAddresspreferredAddressType1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Preferred Address type",
        "grid": 6,
    },

    {
        "varName": "homeAddressaddressFormat1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "homeAddressaddrssType1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "vaddressLabel1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "homeAddresshomeAddressField11",
        "type": "text",
        "label": "Home Address Field 1",
        "grid": 6,
    },

    {
        "varName": "homeAddresshomeAddressField21",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 6,
    },

    {
        "varName": "homeAddresscity1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "homeAddressstate1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
    },

    {
        "varName": "homeAddresspostalCode1",
        "type": "text",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 6,
    },

    {
        "varName": "homeAddresscountry1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Work Address",
        "grid": 12,
    },
    {
        "varName": "workAddresspreferredAddressType1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Preferred Address type",
        "grid": 6,
    },
    {
        "varName": "workAddressaddressFormat1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "workAddressaddrssType1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "workAddressaddressLabel1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "workAddresworkAddressField11",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 6,
    },

    {
        "varName": "workAddressworkAddressField21",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 6,
    },

    {
        "varName": "workAddresscity1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "workAddressstate1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
    },

    {
        "varName": "workAddresspostalCode1",
        "type": "text",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 6,
    },

    {
        "varName": "workAddresscountry1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
    },
    {
        "varName": "workAddresspreferredAddressType2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Preferred Address type",
        "grid": 6,
    },
    {
        "varName": "workAddressaddressFormat2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "workAddressaddrssType2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "workAddressaddressLabel2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "workAddresspresentAddressField12",
        "type": "text",
        "label": "Present Address Field 1",
        "grid": 6,
    },

    {
        "varName": "workAddresspresentAddressField22",
        "type": "text",
        "label": "Present Address Field 2",
        "grid": 6,
    },

    {
        "varName": "workAddresscity2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "workAddressstate2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
    },

    {
        "varName": "workAddresspostalCode2",
        "type": "text",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 6,
    },

    {
        "varName": "workAddresscountry2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
    },


];*/
/*let CSJsonFormForFDRService=[
    /!* {
         "varName": "fdValueDate",
         "type": "date",
         "required": true,
         "label": "FD Value Date",
         "grid": 2,
         "multiline": true
     },*!/
    {
        "varName": "fdrRequest",
        "type": "checkbox",
        "label": "FDR Request",
        "grid": 12
    },
    {
        "varName": "accountType",
        "type": "select",
        "grid": 2,
        "enum": [
            "FDR",
            // "Double Money FD Account",
            "Monthly Interest Paying FD",
        ],
        "label": "Type Of Account",
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true,
    },
    {
        "varName": "fdValueDate",
        "type": "dateAdd",
        "dayAdd":0,
        "grid": 2,
        "onKeyDown":true,
        "label": "FD Value Date",
        "required":true,
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true


    },



    /////Tenure Type ///
    {
        "varName": "tenureType",
        "type": "select",
        "grid": 2,
        "enum": [
            "Monthly"
        ],
        "label": "Tenure Type",
        "conditional": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "Double Money FD Account",
    },
    {
        "varName": "tenure",
        "type": "select",
        "enum":[
            "1",
            "3",
            "6",
            "12",
            "24",
            "36",
        ],
        "grid": 2,
        "label": "Tenure",
        "onKeyDown":true,
        "conditionalArray": true,
        "conditionalArrayList": ["accountType", "tenureType"],
        "conditionalArrayValue": ["Double Money FD Account", "Monthly"],
    },
    {
        "varName": "tenureType",
        "type": "select",
        "grid": 2,
        "enum": [
            "Day",
            "Monthly"
        ],
        "label": "Tenure Type",
        "conditional": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "FDR",
    },
    {
        "varName": "tenure",
        "type": "select",
        "enum":[
            "90",
            "180",
            "182",
            "362",
            "365",
        ],
        "grid": 2,
        "label": "Tenure",
        "onKeyDown":true,
        "conditionalArray": true,
        "conditionalArrayList": ["accountType", "tenureType"],
        "conditionalArrayValue": ["FDR", "Day"],
    },
    {
        "varName": "tenure",
        "type": "select",
        "enum":[
            "1",
            "3",
            "6",
            "12",
            "24",
            "36",
        ],
        "grid": 2,
        "label": "Tenure",
        "onKeyDown":true,
        "conditionalArray": true,
        "conditionalArrayList": ["accountType", "tenureType"],
        "conditionalArrayValue": ["FDR", "Monthly"],
    },
    {
        "varName": "tenureType",
        "type": "select",
        "grid": 2,
        "enum": [
            "Monthly"
        ],
        "label": "Tenure Type",
        "conditional": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "Monthly Interest Paying FD",
    },
    {
        "varName": "tenure",
        "type": "select",
        "enum":[
            "1",
            "3",
            "6",
            "12",
            "24",
            "36",
        ],
        "grid": 2,
        "label": "Tenure",
        "onKeyDown":true,
        "conditionalArray": true,
        "conditionalArrayList": ["accountType", "tenureType"],
        "conditionalArrayValue": ["Monthly Interest Paying FD", "Monthly"],
    },
    /!*  {
          "varName": "doubleSiMaturity",
          "type": "select",
          "grid": 2,
          "enum": [
              "No"
          ],
          "label": "Auto Renewal",
          "conditional": true,
          "conditionalVarName": "accountType",
          "conditionalVarValue": "Double Money FD Account",
      },
      {
          "varName": "fixGSiMaturity",
          "type": "select",
          "grid": 2,
          "enum": [
              "Yes",
              "No",
              "Encash at Maturity to my / Our Account No",
          ],
          "label": "Renewal / Maturity Instruction",
          "conditional": true,
          "conditionalVarName": "accountType",
          "conditionalVarValue": "FDR",
      },
      {
          "varName": "Repayment Account",
          "type": "text",
          "grid": 2,
          "label": "Repayment Account",
          "conditional": true,
          "conditionalVarName": "fixGSiMaturity",
          "conditionalVarValue": "Encash at Maturity to my / Our Account No",

      },
      {
          "varName": "monthSiMaturity",
          "type": "select",
          "grid": 2,
          "enum": [
              "Yes",
          ],
          "label": "Renewal / Maturity Instruction",
          "conditional": true,
          "conditionalVarName": "accountType",
          "conditionalVarValue": "Monthly Interest Paying FD",

      },
   *!/
    {
        "varName":"autoRenewal",
        "type":"select",
        "enum":[
            "YES",
            "NO",
        ],
        "label":"Auto Renewal",
        "grid":2,
        "conditional": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "FDR"


    },
    {
        "varName":"autoRenewal",
        "type":"select",
        "enum":[
            "YES",
            "NO",
        ],
        "label":"Auto Renewal",
        "grid":2,
        "conditional": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "Monthly Interest Paying FD"


    },
    {
        "varName":"maturity",
        "type":"select",
        "enum":[
            "Renew Both Principal & Interest",
            "Renew Principal only & Credit Interest to Account No",
            "Encashment at Maturity",
        ],
        "label":"Maturity /Disposal Instruction",
        "grid":2,
        "conditional": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "Monthly Interest Paying FD"
        /!*     "conditional": true,
             "conditionalVarName": "autoRenewal",
             "conditionalVarValue": "YES",*!/
    },

    {
        "varName":"interestCreditAccount",
        "type":"text",
        "label":"Interest Credit Account",
        "grid":2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "maturity"],
        "conditionalArrayValue": [true, "Renew Principal only & Credit Interest to Account No"],
    },

    {
        "varName":"repaymentAccount",
        "type":"text",
        "label":"Repayment Account",
        "grid":2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "maturity"],
        "conditionalArrayValue": [true, "Encashment at Maturity"],
    },
    {
        "varName":"amount",
        "type": "text",
        "label":"FD Amount",
        "onKeyDown":true,
        "required":true,
        "grid":2,
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true
    },

    {
        "varName":"fdrSchemeCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "onKeyDown":true,
        "label":"FD Scheme Code",
        "required":true,
        "grid":2,
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true
    },

    {
        "varName":"interestRate",
        "type":"text",
        "label":"Rate %",
        "readOnly":true,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "interestRateApicall"],
        "conditionalArrayValue": [true, "YES"],
        "grid":2,

    },

    {
        "varName":"fdMaturityDate",
        "type":"text",
        "label":"FD Maturity Date",
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "fdMaturityDateApicall"],
        "conditionalArrayValue": [true, "YES"],
        "readOnly":true,
        "grid":2,
    },

    /!*{
        "varName":"etin",
        "type":"text",
        "label":"E-TIN",
        "grid":2,
    },*!/
    {
        "varName":"depositeType",
        "type":"text",
        "readOnly":true,
        "label":"Deposit Type",
        "grid":2,
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true
    },


    /!*  {
          "varName":"occupationCode",
          "type": "autoCompleteValueReturn",
          "enum": [],
          "label":"Occupation Code",
          "grid":2,
      },*!/
    {
        "varName":"sbsCode",
        "type":"text",
        "required":true,
        "label":"SBS Code",
        "grid":2,
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true
    },


    {
        "varName":"rmCode",
        "type":"text",
        "required":true,
        "label":"RM Code",
        "grid":2,
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true
    },
    {
        "varName":"rmCodeName",
        "type":"text",
        "readOnly":true,
        "label":"RM Name",
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true,
        /!*  "conditional": true,
          "conditionalVarName": "rmCodeApicall",
          "conditionalVarValue": "YES",*!/
        "grid":2,
    },

    /!* {
         "varName":"nomineeType",
         "type":"select",
         "enum":[
             "Nominee Same As Operative Account",
             "New Nominee Assignment",
         ],
         "label":"Nominee Type",
         "grid":2,
         /!*  "conditional": true,
           "conditionalVarName": "tenureType",
           "conditionalVarValue": "Monthly"*!/
     },*!/
    /!*{
        "varName":"nomineeName",
        "type":"text",
        "readOnly":true,
        "label":"Nominee Name",
        "grid":2,
    },

    {
        "varName":"relationshipNominee",
        "type":"text",
        "label":"Relationship with Nominee",
        "grid":2,
        "readOnly":true,

    },*!/
];*/
/*let CBDataJsonForm=   [
    {
        "type": "title",
        "label": "MIS RELATED",
        "grid": 12,
    },
    {
        "varName": "missolId",
        "type": "text",
        "label": "SOL ID",
        "readOnly":true,
        "grid": 6,
    },

    {
        "varName": "misprimarySolId",
        "type": "text",
        "label": "Primary Sol ID",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "misagentBankingOutletFreeCode1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Agent Banking Outlet/ Free Code 1",
        "grid": 6,
    },

    {
        "varName": "miscustomerIdCifId",
        "type": "text",
        //"required": true,
        "label": "Customer ID/CIF ID",
        "grid": 6,
        "readOnly":true,
        // "required": true,
    },

    {
        "varName": "misaCNumber",
        "type": "text",
        //"required": true,
        "label": "A/C Number",
        //misaCNumber=inputData.accountNumber
        "grid": 6,
        "readOnly":true,
        //"required": true,
    },

    {
        "varName": "mistitle",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //mistitle=inputData.customerTitle
        "label": "Title",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "miscustomerNameLastName",
        "type": "text",
        //"required": true,
        //inputData.miscustomerNameLastName=inputData.customerName;

        "label": "Customer Name/Last Name",
        "grid": 6,
        "readOnly":true,
    },

    {
        "varName": "misshortName",
        "type": "text",
        //"required": true,
        //inputData.misshortName=inputData.shortName;

        "label": "Short Name",
        "grid": 6,
        "readOnly":true,
    },

    {
        "varName": "misgender",
        "type": "select",
        //"required": true,
        "enum": [
            "M",
            "F",
            "TRANSGENDER",
            "OTHER"
        ],
        "label": "Gender",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "miscustomerStatusNormalStaffPriorityEtc",
        "type": "select",
        "enum": [
            "Normal Customer",
            "Staff",
            "Priority",
        ],
        "label": "Customer Status",
        //inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.customerStatus
        "grid": 6,
        "required": true,
    },

    {
        "varName": "misoccupationCodePriorityCodeStaff",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 6,
        //
        /!*  if(this.state.inputData.occupationCodeNormal!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodeNormal
      }
      else if(this.state.inputData.occupationCodePriority!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodePriority
      }
      else if(this.state.inputData.occupationCodeStaff!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodeStaff
      }*!/
    },

    {
        "varName": "misconstitution",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //master data cbl provide
        "label": "Constitution",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "misstaffIndicatorStatus",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "label": "Staff Indicator/Status",
        //inputData.misstaffIndicatorStatus=inputData.staffIndicator
        "grid": 6,
        "required":true,

    },

    {
        "varName": "misstaffIdNumber",
        "type": "text",
         "label": "Staff ID Number",
        "validation":"staffId",
        //inputData.misstaffIdNumber=inputData.staffIdNumber
        "grid": 6,
    },

    {
        "varName": "mispriorityCenterCodeFreeCode1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.mispriorityCenterCodeFreeCode1=inputData.priorityCenterCode;
        "label": "Priority Center Code/Free Code 1",
        "grid": 6,
    },

    {
        "varName": "mispriorityMonitoringRmCodeFreeCode3",
        "type": "textApiCall",
        "enum": [],
        //inputData.mispriorityMonitoringRmCodeFreeCode3=inputData.monitoringRMCode
        "label": "Priority Monitoring  RM Code/Free Code 3",
        "grid": 6,
    },

    {
        "varName": "misprimaryCbHolderForPriorityFreeText8",
        "type": "text",
        //inputData.misprimaryCbHolderForPriorityFreeText8=inputData.primaryCBHolderforPriority
        "label": "Primary CB Holder for Priority/Free Text 8",
        "grid": 6,
    },

    {
        "varName": "misrelationshipWithPrimaryCbHolderFreeText9",
        "type": "text",
        //inputData.misrelationshipWithPrimaryCbHolderFreeText9=inputData.relationshipwithPrimaryCBHolder;
        "label": "Relationship with Primary CB Holder/Free Text 9",
        "grid": 6,
    },

    {
        "varName": "misfunctionCodeForChequeBookWaiver",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.misfunctionCodeForChequeBookWaiver=inputData.functionCodeforChequeBookWaiver
        "label": "Function Code for Cheque Book Waiver",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },

    {
        "varName": "miswaiverTypeForChequeBookWaiver",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.waiverTypeforChequeBookWaiver=inputData.functionCodeforChequeBookWaiver
        "label": "Waiver Type  for Cheque Book Waiver",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },

    {
        "varName": "misbbkSegmentCodeFreeCode7",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "BBK Segment Code/Free Code 7",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "misdebitCardIssueFreeCode10",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Debit Card Issue/Free Code 10",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "mismigratedNidVerificationFreeText2",
        "type": "text",
        "label": "Migrated NID Verification/Free Text 2",
        "grid": 6,
    },

    {
        "varName": "mismigratedSmartCardNumberFreeText5",
        "type": "text",
        "label": "Migrated Smart Card Number Free Text 5",
        "grid": 6,
    },

    {
        "varName": "mispepStatusFreeText10",
        "type": "text",
        "label": "PEP Status/Free Text 10",
        "grid": 6,
    },

    {
        "varName": "mismigratedCifsDocumentNoFreeText13",
        "type": "text",
        "label": "Migrated CIFs Document No/Free Text 13",
        "grid": 6,
    },

    {
        "varName": "miswaiverFieldFreeCode10",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.miswaiverFieldFreeCode10=inputData.waiverField
        "label": "Waiver Field/Free Code 10",
        "grid": 6,
    },

    {
        "varName": "misccepCodeFreeCode9",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.misccepCodeFreeCode9=inputData.ccepCode
        "label": "CCEP Code/Free Code 9",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText1",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText2",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText3",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "misavailedTradeServices",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Availed Trade services",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Currency Code",
        "grid": 12,
    },

    {
        "varName": "aof1currencyCodeCcy",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.aof1currencyCodeCcy=inputData.currency
        "label": "Currency Code/CCY",
        "grid": 6,
    },

    {
        "varName": "aof1commAddressSelection",
        "type": "select",
        "enum": [
            "Present",
            "Permanent",
            "Office",
        ],
        "label": "Comm. Address Selection ",
        "grid": 6,
    },

    {
        "varName": "aof1emailDesignatedEmailAddress",
        "type": "text",
        //inputData.aof1emailDesignatedEmailAddress=inputData.designatedEmail
        "label": "Email/Designated Email Address",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "aof1designatedMobileNumberPhoneNo1",
        "type": "text",
        "validation": "numeric",
        //inputData.aof1designatedMobileNumberPhoneNo1=inputData.designatedMobileNumber
        "label": "Designated Mobile Number/Phone No 1",
        "grid": 6,
        "required": true,
    },


    {
        "varName": "aof1statement",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Statement ",
        "grid": 6,
    },

    {
        "varName": "aof1despatchModePrintedEStatementBoth",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Despatch Mode (Printed/E-Statement/Both)",
        "grid": 6,
    },
    {
        "varName": "aof1smsAlertFreeText11",
        "type": "select",
        "enum":[
            "Y",
            "N",
        ],
        "label": "SMS Alert/Free Text 11",
        "grid": 6,
    },

    {
        "type": "title",
        "label": "Primary Introducer details",
        "grid": 12,
    },

    {
        "varName": "primryintroducerCustomerIdCifId",
        "type": "text",
        //inputData.primryintroducerCustomerIdCifId=inputData.introducerId
        "label": "Introducer Customer Id/CIF ID",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "primryiintroducerNameLastName",
        "type": "text",
        //inputData.primryiintroducerNameLastName=inputData.introducerName
        "label": "Introducer Name/Last Name",
        "grid": 6,
    },

    {
        "varName": "primryiintroducerStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Introducer Status",
        "grid": 6,
    },

    {
        "varName": "primryicifTypePrimaryIntroducerDetails",
        "type": "select",
        "enum": [
            "Retail",
            "Corporate",
        ],
        "label": "CIF Type/Primary Introducer Details",
        "grid": 6,
    },

    /!*  {
          "varName":"noOfNominee",
         "type": "autoCompleteValueReturn",
        "enum": [],
          "label":"No of Nominee",
          "grid":6,
      },

      {
          "varName":"registrationNo",
         "type":"text",
        "validation":"numeric",
          "label":"Registration No",
          "grid":6,
      },

      {
          "varName":"cifIdIndividualId",
         "type": "autoCompleteValueReturn",
        "enum": [],
          "label":"CIF ID/Individual ID",
          "grid":6,
      },

      {
          "varName":"nomineeName",
          "type":"text",
          "label":"Nominee Name",
          "grid":6,
      },

      {
          "varName":"relationship",
         "type": "autoCompleteValueReturn",
        "enum": [],
          "label":"Relationship",
          "grid":6,
      },

      {
          "varName":"percentage",
         "type":"text",
        "validation":"numeric",
          "label":"Percentage",
          "grid":6,
      },

      {
          "varName":"addressField1",
          "type":"text",
          "label":"Address Field 1 ",
          "grid":6,
      },

      {
          "varName":"addressField2",
          "type":"text",
          "label":"Address Field 2",
          "grid":6,
      },

      {
          "varName":"city",
         "type": "autoCompleteValueReturn",
        "enum": [],
          "label":"City",
          "grid":6,
      },

      {
          "varName":"state",
         "type": "autoCompleteValueReturn",
        "enum": [],
          "label":"State",
          "grid":6,
      },

      {
          "varName":"postalCode",
         "type":"text",
        "validation":"numeric",
          "label":"Postal Code",
          "maxLength":6,
          "grid":6,
      },

      {
          "varName":"country",
         "type": "autoCompleteValueReturn",
        "enum": [],
          "label":"Country",
          "grid":6,
      },

      {
          "varName":"photoIdNumberRegNo",
          "type":"text",
          "label":"Photo Id Number/Reg No",
          "grid":6,
      },

      {
          "varName":"nomineeDob",
          "type":"date",
          "label":"Nominee DOB",
          "grid":6,
      },

      {
          "varName":"nomineeMinor",
         "type": "autoCompleteValueReturn",
        "enum": [],
          "label":"Nominee Minor",
          "grid":6,
      },

      {
          "varName":"guardiansName",
          "type":"text",
          "label":"Guardian's Name",
          "grid":6,
      },

      {
          "varName":"guardianCode",
         "type": "autoCompleteValueReturn",
        "enum": [],
          "label":"Guardian Code",
          "grid":6,
      },

      {
          "varName":"guardianPhotoId",
          "type":"text",
          "label":"Guardian Photo Id",
          "grid":6,
      },

      {
          "varName":"gAddressField1",
          "type":"text",
          "label":"Address Field 1",
          "grid":6,
      },

      {
          "varName":"gAddressField2",
          "type":"text",
          "label":"Address Field 2",
          "grid":6,
      },

      {
          "varName":"gCity",
         "type": "autoCompleteValueReturn",
        "enum": [],
          "label":"City",
          "grid":6,
      },

      {
          "varName":"gState",
         "type": "autoCompleteValueReturn",
        "enum": [],
          "label":"State",
          "grid":6,
      },

      {
          "varName":"postal",
         "type":"text",
        "validation":"numeric",
          "label":"Postal ",
          "grid":6,
      },

      {
          "varName":"gCountry",
         "type": "autoCompleteValueReturn",
        "enum": [],
          "label":"Country",
          "grid":6,
      },
   *!/
    {
        "type": "title",
        "label": "Nominee",
        "grid": 12,
    },

    {
        "type": "title",
        "label": "Other Bank Information",
        "grid": 12,
    },
    {
        "varName": "otherBankbankName",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Bank Name",
        "grid": 6,
    },

    {
        "varName": "otherBankbranchName",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Branch Name",
        "grid": 6,
    },
    {
        "varName": "schemeCodeChangemodeOfOperation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Mode of Operation",
        "grid": 6,
    },
    {
        "type": "title",
        "label": " Scheme Change for Insta Pack",
        "grid": 12,
    },


    {
        "varName": "schemeCodeChangefunctionForSchemeChangeInCaseOfInstaPack",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Function for Scheme Change in case of Insta Pack",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountTypes",
        "conditionalVarValue": "Insta Pack",
    },

    {
        "varName": "schemeCodeChangetargetSchemeCodeInCaseOfInstaPack",
        "type": "text",
        "label": "Target Scheme Code in case of Insta Pack",
        "grid": 6,
    },

    {
        "varName": "schemeCodeChangetrialMode",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Trial Mode",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "CRM MIS",
        "grid": 12,
    },


    {
        "varName": "crmMissubSegment",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Segment",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Basic Information Update",
        "grid": 12,
    },
    /!*  {
        "varName": "basicInformationUpdateprimarySolId",
        "type": "text",
        "enum": [],
        "readOnly":true,
        "label": "Primary Sol ID",
        "grid": 6,
        "required": true,
    },*!/

    {
        "varName": "basicInformationUpdategender",
        "type": "select",
        "enum": [
            "M",
            "F",
            "TRANSGENDER",
            "OTHER"
        ],
        "label": "Gender",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdatefatherNamePrefferedName",
        "type": "text",
        "label": "Father Name/Preffered Name",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdatemotherNameMothersMaidenName",
        "type": "text",
        "label": "Mother Name/Mothers Maiden Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdatemaritalStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Marital Status",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "basicInformationUpdatespouseNamePurgeRemarks",
        "type": "text",
        "label": "Spouse Name/Purge Remarks",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdatespouseNamePurgeRemarks",
        "conditionalVarValue": {"masterDataName":"maritalStatus","key":"MARID","value":"MARRIED"}

    },

    {
        "varName": "basicInformationUpdatedob",
        "type": "date",
        "maxDate": true,
        //inputData.basicInformationUpdatedob=inputData.gender
        "label": "DOB",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdateminorStatusMinorIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "readOnly":true,
        "label": "Minor Status/Minor Indicator",
        "conditional": true,
        "conditionalVarName": "basicInformationUpdatedobApiCall",
        "conditionalVarValue": "YES",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateseniorCitizenIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Senior Citizen Indicator",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Gurdian Details - Only Visible In Case Minor Account Holder",
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",

        "grid": 12,
    },


    {
        "varName": "guardianDetailsrelation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation *",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsbankRelationType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Bank Relation Type *",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsrelation1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation *",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsguardianSelectionGurdian",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Guardian Selection/Gurdian",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsgurdianCifNumberCustomer",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Gurdian CIF Number/Customer *",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailslastName",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Last Name *",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },
    {
        "type": "title",
        "label": "National ID Card",
        "grid": 12,
    },
    {
        "varName": "natioanlIdCarddocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "natioanlIdCarddocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "natioanlIdCarddocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Code ",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "nationalIdCardNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "National ID Card Number/Unique ID",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "natioanlIdCardnidVerificationConfirmationIsDocumentVerified1",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
    },

    {
        "varName": "natioanlIdCardissueDateN",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Smart ID Card",
        "grid": 12,
    },
    {
        "varName": "smartIdCarddocumentTypeS",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "smartIdCarddocumentTypeDescriptionS",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "smartIdCarddocumentCodeS",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Code ",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "smartCardNumberUniqueId",
        "type": "text",
        "label": "Smart Card Number/Unique ID",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "smartIdCardnidVerificationConfirmationIsDocumentVerified",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
    },

    {
        "varName": "smartIdCardissueDateS",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Passport Number",
        "grid": 12,
    },
    {
        "varName": "passportNumberdocumentTypeP",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "passportNumberdocumentTypeDescriptionP",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.passportNumberdocumentTypeDescriptionP=inputData.passport
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "passportNumberdocumentCodeP",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "passportNumberpassportNumberUniqueId",
        "type": "text",
        "label": "Passport Number/Unique ID",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "passportNumberplaceOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Place of Issue",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "passportNumberissueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "passportNumbercountryOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country of Issue",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "passportNumberexpiryDate",
        "type": "date",
        "label": "Expiry Date",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Birth Certificate",
        "grid": 12,
    },
    {
        "varName": "birthCertificatedocumentTypeB",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "birthCertificatedocumentTypeDescriptionB",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "birthCertificatedocumentCodeB",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "birthCertificatebirthCertificateNumberUniqueId",
        "type": "text",
        "label": "Birth Certificate Number/Unique ID",
        //inputData.birthCertificatebirthCertificateNumberUniqueId=inputData.birthCertificateNo
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Chairman Certificate",
        "grid": 12,
    },
    {
        "varName": "chairmanCertificatedocumentTypeCC",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "chairmanCertificatedocumentTypeDescriptionCC",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "chairmanCertificatedocumentCodeCC",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "chairmanCertificateUniqueId",
        "type": "text",
        "label": "Chairman Certificate/Unique ID",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "chairmanCertificateplaceOfBirthFreeText14",
        "type": "text",
        "label": "Place of Birth/Free Text 14",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificatecountryOfBirthFreeText15",
        "type": "text",
        "label": "Country of Birth/Free Text 15",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificatenationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Nationality",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "chairmanCertificatenonResident",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Non Resident",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "chairmanCertificateturnedNonResidentOn",
        "type": "date",
        "label": "Turned Non Resident On",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": {"masterDataName":"nonResident","key":"Y","value":"Y"},
    },
    {
        "type": "title",
        "label": "E-Tin",
        "grid": 12,
    },
    {
        "varName": "etindocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "etindocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "etindocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "etineTinNumber",
        "type": "text",
        //inputData.etineTinNumber=inputData.eTin
        "label": "E-Tin Number/Unique ID",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Employer Data",
        "grid": 12,
    },
    {
        "varName": "employerDataprofessionEmploymentType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Profession /Employment Type",
        "grid": 6,
    },

    {
        "varName": "employerDataemploymentType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Employment Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "employerDatanameOfEmployerEmployerName",
        "type": "text",
        "label": "Name of Employer/Employer Name",
        "grid": 6,
    },

    {
        "varName": "employerDatamonthlyIncomeGrossIncome",
        "type": "text",
        "label": "Monthly Income/Gross income",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Contact Number - Phone",
        "grid": 12,
    },
    {
        "varName": "contactNumberpreferredContactNoType1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Preferred Contact no type",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 1",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Contact Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "contactNumberphoneNoEmailId1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Phone No.",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNo1",
        "type": "text",
        "validation": "numeric",
        "label": "Phone No",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 2",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Contact Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "contactNumberphoneNoEmailId2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Phone No.",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNo2",
        "type": "text",
        "validation": "numeric",
        "label": "Phone No",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 3",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType3",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Contact Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "contactNumberphoneNoEmailId3",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Phone No.",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNo3",
        "type": "text",
        "validation": "numeric",
        "label": "Phone No",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Contact Number - Email",
        "grid": 12,
    },
    {
        "varName": "contactNumberEmailpreferedEmailIdType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Prefered Email ID type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailType1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId4",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmaildesignatedEmailAddressEmailId1",
        "type": "text",
        "label": "Designated Email Address/Email ID 1",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailId2",
        "type": "text",
        "label": "Email ID 2",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Mailing Address",
        "grid": 12,
    },
    {
        "varName": "maillingAdresspreferredAddressType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdresspreferredAddressType=inputData.SelectCommunicationAddress

        "label": "Preferred Address type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Address Format",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Addrss type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //"readOnly":true,
        "label": "Address label",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressmailingAddressField1",
        "type": "text",
        //inputData.maillingAdressmailingAddressField1=inputData.communicationAddress1
        "label": "Mailing Address Field 1",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressmailingAddressField2",
        "type": "text",
        //inputData.maillingAdressmailingAddressField2=inputData.communicationAddress2
        "label": "Mailing Address Field 2",
        "grid": 6,
    },

    {
        "varName": "maillingAdresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdresscity=inputData.city2

        "label": "City",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdressstate=inputData.state2

        "label": "Division(State)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdresspostalCode",
        "type": "text",
        "validation": "numeric",
        "label": "Postal Code",
        "maxLength":6,
        //inputData.maillingAdresspostalCode=inputData.postalCode3
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdresscountry=inputData.nationality
        "label": "Country",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Home Address",
        "grid": 12,
    },
    {
        "varName": "homeAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Address Format",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "homeAddressFieldShow",
        "conditionalVarValue": true,
    },

    {
        "varName": "homeAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "onKeyDown": true,
        "label": "Addrss type",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "homeAddressFieldShow",
        "conditionalVarValue": true,
    },

    {
        "varName": "homeAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //"readOnly":true,
        "label": "Address label",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "homeAddressFieldShow",
        "conditionalVarValue": true,
    },

    {
        "varName": "homeAddresshomeAddressField1",
        "type": "text",
        "label": "Home Address Field 1",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "homeAddressFieldShow",
        "conditionalVarValue": true,
    },

    {
        "varName": "homeAddresshomeAddressField2",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "homeAddressFieldShow",
        "conditionalVarValue": true,
    },

    {
        "varName": "homeAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],

        "label": "City",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "homeAddressFieldShow",
        "conditionalVarValue": true,
    },

    {
        "varName": "homeAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "homeAddressFieldShow",
        "conditionalVarValue": true,
    },

    {
        "varName": "homeAddresspostalCode",
        "type": "text",
        "validation": "numeric",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "homeAddressFieldShow",
        "conditionalVarValue": true,
    },

    {
        "varName": "homeAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "homeAddressFieldShow",
        "conditionalVarValue": true,
    },
    {
        "type": "title",
        "label": "Work Address",
        "grid": 12,
    },
    {
        "varName": "workAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Address Format",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "workAddressaddressType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Address type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "workAddressaddressType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //"readOnly":true,
        "label": "Address label",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "workAddressworkAddressField1",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "workAddressworkAddressField2",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 6,
    },

    {
        "varName": "workAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "workAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "validation": "numeric",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 6,
        "required": true,
    },

    {
        "varName": "workAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Present Address",
        "grid": 12,
    },
    {
        "varName": "presentAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Address Format",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Addrss type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //"readOnly":true,
        "label": "Address label",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "presentAddresspresentAddressField1",
        "type": "text",
        "label": "Present Address Field 1",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "presentAddresspresentAddressField2",
        "type": "text",
        "label": "Present Address Field 2",
        "grid": 6,
    },

    {
        "varName": "presentAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "presentAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "presentAddresspostalCode",
        "type": "text",
        "validation": "numeric",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 6,
        "required": true,
    },

    {
        "varName": "presentAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Related CB Tagging :",
        "grid": 12,
    },
    {
        "varName": "relatedCbTaggingrelationType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "relatedCbTaggingrelationCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation Code",
        "grid": 6,
    },

    {
        "varName": "relatedCbTaggingdesignationCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Designation Code",
        "grid": 6,
    },

    {
        "varName": "relatedCbTaggingcifId",
        "type": "text",
        "label": "CIF ID",
        "grid": 6,
    },

    {
        "varName": "relatedCbTaggingtitle",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Title",
        "grid": 6,
    },

    {
        "varName": "relatedCbTaggingname",
        "type": "text",
        "label": "Name",
        "grid": 6,
    },


    {
        "type": "title",
        "label": "Fincore Data Update - Account Level",
        "grid": 12,
    },
    /!* {
         "varName": "fincoreDataUpdateaCReportCode",
         "type": "text",
         "label": "A/C Report Code",
        "grid": 6,
        "required": true,
     },*!/

    /!* {
         "varName": "fincoreDataUpdatecustomerRelationshipACManager",
         "type": "text",
         "label": "Customer Relationship A/C Manager",
        "grid": 6,
        "required": true,
     },*!/

    /!* {
         "varName": "fincoreDataUpdateaCManagerId",
         "type": "text",
         "label": "A/C Manager ID",
        "grid": 6,
        "required": true,
     },*!/

    /!* {
         "varName": "fincoreDataUpdatecontactPhoneNo",
         "type": "text",
         "label": "Contact Phone No.",
        "grid": 6,
        "required": true,
     },*!/

    {
        "varName": "fincoreDataUpdatecashLimit(Dr)",
        "type": "text",
        "validation":"numeric",
        "label": "Cash Limit (Dr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatecashLimit(Cr)",
        "type": "text",
        "validation":"numeric",
        "label": "Cash Limit (Cr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdateclearingLimit(Dr)",
        "type": "text",
        "validation":"numeric",
        "label": "Clearing Limit (Dr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdateclearingLimit(Cr)",
        "type": "text",
        "validation":"numeric",
        "label": "Clearing Limit (Cr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatetransferLimit(Dr)",
        "type": "text",
        "validation":"numeric",
        "label": "Transfer Limit (Dr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatetransferLimit(Cr)",
        "type": "text",
        "validation":"numeric",
        "label": "Transfer Limit (Cr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatenotes",
        "type": "text",
        "label": "Notes",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdateaccountStatement",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Account Statement",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatestatementFrequency",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Statement Frequency",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatedispatchMode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Dispatch Mode",
        "grid": 6,
    },

     {
        "varName": "fincoreDataUpdatenextPrintDate",
        "type": "date",
        "label": "Next Print Date",
        "grid": 6,
    },

    /!* {
         "varName": "fincoreDataUpdateinterestCreditAC",
         "type": "text",
         "label": "Interest Credit A/c.",
        "grid": 6,
        "required": true,
     },*!/

    /!*  {
          "varName": "fincoreDataUpdatewithholdingTaxBorneBy",
          "type": "text",
          "label": "Withholding Tax Borne By",
         "grid": 6,
        "required": true,
      },
  *!/
    /!*  {
          "varName": "fincoreDataUpdatewithholdingTaxLevel",
          "type": "text",
          "label": "Withholding Tax Level",
         "grid": 6,
        "required": true,
      },*!/

    /!* {
         "varName": "fincoreDataUpdatewithholdingTaxPcnt",
         "type": "text",
         "label": "Withholding Tax Pcnt.",
        "grid": 6,
        "required": true,
     },*!/

    {
        "varName": "fincoreDataUpdateaCStatus",
        "type": "select",
        "enum":[
            "Active",
            "Dormant",
            "Inactive",
        ],
        "label": "A/C Status",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatedormantActivationCharge",
        "type": "select",
        "enum":[
            "Y",
            "N",
        ],
        "label": "Dormant Activation Charge",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatenomineeSelect",
        "type": "text",
        "label": "Nominee Select",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdateaCInformation",
        "type": "text",
        "label": "A/c. Information ",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatesectorCode",
        "type": "autoCompletewValueReturn",
        "enum": [],
        "onKeyDown": true,
        //inputData.fincoreDataUpdatesectorCode=inputData.nationality
        "label": "Sector Code",
        "grid": 6,
    },


    {
        "varName": "fincoreDataUpdatesubSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //subsector code
        "label": "Sub Sector Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "sectorCodeApicall",
        "conditionalVarValue": true,
    },

    {
        "varName": "fincoreDataUpdateoccupationCode",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3",
        "type": "textApiCall",
        //rm code
        "label": "RM Code/ Wealth Manager Code/Free Code 3",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeText3",
        "type": "text",
        "label": "Free Text 3 ",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCode6",
        "type": "text",
        "label": "Free Code 6",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatedepositCodeFreeCode7",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Deposit Code/Free Code 7",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCode8",
        "type": "text",
        "label": "Free Code 8",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeText10",
        "type": "text",
        "label": "Free Text 10",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCodeFreeText1",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCodeFreeText2",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCodeFreeText3",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },




];*/

let CBDataJsonForm = [
    {
        "type": "title",
        "label": "MIS RELATED",
        "grid": 12,
    },

    /* {
         "varName": "misprimarySolId",
         "type": "text",
         "label": "Primary Sol ID",
         "grid": 6,
         "required": true,
     },*/


    {
        "varName": "miscustomerIdCifId",
        "type": "text",
        //"required": true,
        "label": "Customer ID/CIF ID",
        "grid": 6,
        "readOnly": true,
        // "required": true,
    },


    {
        "varName": "mistitle",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //mistitle=inputData.customerTitle
        "label": "Title",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "miscustomerNameLastName",
        "type": "text",
        "required": true,
        "validationB":"length",
        "maxLength": 80,
        //inputData.miscustomerNameLastName=inputData.customerName;
        "label": "Customer Name/Last Name",
        "grid": 6,
    },

    {
        "varName": "misshortName",
        "type": "text",
        "required": true,
        "validationB":"length",
        "maxLength": 10,
        //inputData.misshortName=inputData.shortName;

        "label": "Short Name",
        "grid": 6,
    },

    /*  {
          "varName": "misgender",
          "type": "select",
          //"required": true,
          "enum": [
              "M",
              "F",
              "TRANSGENDER",
              "OTHER"
          ],
          "label": "Gender",
          "grid": 6,
          "required": true,
      },*/

    {
        "varName": "miscustomerStatusNormalStaffPriorityEtc",
        "type": "autoCompleteValueReturn",
        "enum": [

        ],
        "label": "Customer Status",
        //inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.customerStatus
        "grid": 6,
        "required": true,
    },

    {
        "varName": "misoccupationCodePriorityCodeStaff",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 6,
        "required":true,
        //
        /*  if(this.state.inputData.occupationCodeNormal!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodeNormal
      }
      else if(this.state.inputData.occupationCodePriority!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodePriority
      }
      else if(this.state.inputData.occupationCodeStaff!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodeStaff
      }*/
    },

    {
        "varName": "misconstitution",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //master data cbl provide
        "label": "Constitution",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "misstaffIndicatorStatus",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "label": "Staff Indicator/Status",
        //inputData.misstaffIndicatorStatus=inputData.staffIndicator
        "grid": 6,



    },

    {
        "varName": "misstaffIdNumber",
        "type": "text",
        "label": "Staff ID Number",
        "validation": "staffId",
        //inputData.misstaffIdNumber=inputData.staffIdNumber
        "grid": 6,

    },

    {
        "varName": "mispriorityCenterCodeFreeCode1",
        "type": "select",
        "enum": [
           "PR104",
            "PR142",
            "PR177",
            "PR186",
            "PR187",
            "PR195",
            "PR208",
            "PR169",
        ],
        //inputData.mispriorityCenterCodeFreeCode1=inputData.priorityCenterCode;
        "label": "Priority Center Code/Free Code 1",
        "grid": 6,
    },

    {
        "varName": "mispriorityMonitoringRmCodeFreeCode3",
        "type": "textApiCall",
        "enum": [],
        //inputData.mispriorityMonitoringRmCodeFreeCode3=inputData.monitoringRMCode
        "label": "Priority Monitoring  RM Code",
        "grid": 6,
    },
    {
        "varName": "mispriorityMonitoringRmCodeFreeCode3Name",
        "type": "text",
        //inputData.mispriorityMonitoringRmCodeFreeCode3=inputData.monitoringRMCode
        "label": "Priority Monitoring  RM Code Name",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "mispriorityMonitoringRmCodeFreeCode3ApiCall",
        "conditionalVarValue": true,
        "grid": 6,
    },

    {
        "varName": "misprimaryCbHolderForPriorityFreeText8",
        "type": "text",
        //inputData.misprimaryCbHolderForPriorityFreeText8=inputData.primaryCBHolderforPriority
        "label": "Primary CB Holder for Priority",
        "grid": 6,
    },

    {
        "varName": "misrelationshipWithPrimaryCbHolderFreeText9",
        "type": "text",
        //inputData.misrelationshipWithPrimaryCbHolderFreeText9=inputData.relationshipwithPrimaryCBHolder;
        "label": "Relationship with Primary CB Holder",
        "grid": 6,
    },


    {
        "varName": "misbbkSegmentCodeFreeCode7",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "BBK Segment Code",
        "grid": 6,
        "required": true,
    },


    {
        "varName": "mismigratedNidVerificationFreeText2",
        "type": "text",
        "label": "Migrated NID Verification",
        "grid": 6,
    },

    {
        "varName": "mismigratedSmartCardNumberFreeText5",
        "type": "text",
        "label": "Migrated Smart Card Number",
        "grid": 6,
    },

    {
        "varName": "mispepStatusFreeText10",
        "type": "text",
        "label": "PEP Status",
        "grid": 6,
    },

    {
        "varName": "mismigratedCifsDocumentNoFreeText13",
        "type": "text",
        "label": "Migrated CIFs Document No",
        "grid": 6,
    },


    {
        "varName": "misfreeCodeFreeText1",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText2",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText3",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misavailedTradeServices",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Availed Trade services",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Currency Code",
        "grid": 12,
    },

    {
        "varName": "aof1currencyCodeCcy",
        "type": "text",
        //inputData.aof1currencyCodeCcy=inputData.currency
        "label": "Currency Code/CCY",
        "grid": 6,
        "required": true,
        //"readOnly":true,
        "conditional": true,
        "conditionalVarName": "aof1currencyCodeCcyExist",
        "conditionalVarValue": true,
    },
    {
        "varName": "aof1currencyCodeCcy",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.aof1currencyCodeCcy=inputData.currency
        "label": "Currency Code/CCY",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "aof1currencyCodeCcyExist",
        "conditionalVarValue": false,
        //"readOnly":true,
    },
    {
        "type": "title",
        "label": "Contact Number - Email",
        "grid": 12,
    },
    {
        "varName": "contactNumberEmailpreferedEmailIdType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "COMMEML",
            "value": "COMMEML"
        }],
        "readOnly": true,
        "label": "Prefered Email ID type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailType1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "COMMEML",
            "value": "COMMEML"
        }],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId4",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "EMAIL",
            "value": "EMAIL"
        }],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmaildesignatedEmailAddressEmailId1",
        "type": "text",
        "label": "Designated Email Address/Email ID 1",
         "validation": "email",
        "grid": 6,
    },
    {
        "varName": "contactNumberEmaildesignatedEmailAddressEmailId1Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,


    },
    {
        "type": "title",
        "label": "Contact Number - Phone",
        "grid": 12,
    },
    {
        "varName": "contactNumberpreferredContactNoType1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH1",
            "value": "COMMPH1"
        }],
        "label": "Preferred Contact no type",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 1",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType1",
        "type": "autoCompleteValueReturn",
        "enum": [ {
            "masterDataName": "contactType",
            "key": "COMMPH1",
            "value": "COMMPH1"
        }],
        "label": "Contact Type",
        "grid": 6,
        "readOnly":true,
    },

    {
        "varName": "contactNumberphoneNoEmailId1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No./Email ID",
        "grid": 6,
        "readOnly":true,
    },
    {
        "varName": "mobileTypecontactNumberphoneNo1",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required":true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType1",
        "conditionalVarValue": "COMMPH1",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Overseas",
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo1",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo1",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Overseas",

    },


    {
        "type": "title",
        "label": "Primary Introducer details",
        "grid": 12,
    },
    {
        "varName": "nidVerified",
        "type": "select",
        "enum":[
            "YES",
            "NO",
        ],
        "required": true,
        "label": "Is All NID/Smart Card Document Verified?",
        "grid": 6,

    },
    {
        "varName": "primryintroducerCustomerIdCifId",
        "type": "textApiCall",
        //inputData.primryintroducerCustomerIdCifId=inputData.introducerId
        "label": "Introducer Customer Id/CIF ID",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },


    {
        "varName": "primryiintroducerNameLastName",
        "type": "text",
        //inputData.primryiintroducerNameLastName=inputData.introducerName
        "label": "Introducer Name/Last Name",
        "grid": 6,
        "readOnly":true,
        "conditionalArray": true,
        "conditionalArrayList": ["introducerIdApiCall", "nidVerified"],
        "conditionalArrayValue": [true, "NO"]

    },

    {
        "varName": "primryiintroducerStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Introducer Status",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },

    {
        "varName": "primryicifTypePrimaryIntroducerDetails",
        "type": "select",
        "enum": [
            "Retail",
            "Corporate",
        ],
        "label": "CIF Type/Primary Introducer Details",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "type": "title",
        "label": "Related CB Tagging :",
        "grid": 12,
    },
    {
        "varName": "relatedCbTaggingrelationType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "relatedCbTaggingrelationCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation Code",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "relatedCbTaggingdesignationCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Designation Code",
        "grid": 6,
        "required": true,
    },



    {
        "varName": "crmMissubSegment",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Segment",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Basic Information Update",
        "grid": 12,
    },
    {
        "varName": "basicInformationUpdateprimarySolId",
        "type": "text",
        "enum": [],
        //"readOnly":true,
        "label": "Primary Sol ID",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdategender",
        "type": "select",
        "enum": [
            "M",
            "F",
            "TRANSGENDER",
            "OTHER"
        ],
        "label": "Gender",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdatefatherNamePrefferedName",
        "type": "text",
        "label": "Father Name/Preffered Name",
        "validationB":"length",
        "maxLength":50,
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdatemotherNameMothersMaidenName",
        "type": "text",
        "label": "Mother Name/Mothers Maiden Name",
        "grid": 6,
        "validationB":"length",
        "maxLength":50,
    },

    {
        "varName": "basicInformationUpdatemaritalStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Marital Status",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "basicInformationUpdatespouseNamePurgeRemarks",
        "type": "text",
        "label": "Spouse Name/Purge Remarks",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "basicInformationUpdatemaritalStatus",
        "conditionalVarValue":"MARID",
        "validationB":"length",
        "maxLength":240,

    },

    {
        "varName": "basicInformationUpdatedob",
        "type": "date",
        "maxDate": true,
        "onKeyDown": true,
        //inputData.basicInformationUpdatedob=inputData.gender
        "label": "DOB",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdateminorStatusMinorIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "readOnly":true,
        "label": "Minor Status/Minor Indicator",
        "conditional": true,
        "conditionalVarName": "basicInformationUpdatedobApiCall",
        "conditionalVarValue": "YES",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateseniorCitizenIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "readOnly":true,
        "label": "Senior Citizen Indicator",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdatedobApiCall",
        "conditionalVarValue": "YES",
        "required": true,
    },
    {
        "type": "title",
        "label": "Gurdian Details - Only Visible In Case Minor Account Holder",
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",

        "grid": 12,
    },


    {
        "varName": "guardianDetailsrelation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation *",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsbankRelationType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Bank Relation Type *",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsrelation1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation *",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsguardianSelectionGurdian",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Guardian Selection/Gurdian",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsgurdianCifNumberCustomer",
        "type": "textApiCall",
        "label": "Gurdian CIF Number/Customer *",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailslastName",
        "type": "text",
        "enum": [],
        "label": "Last Name *",
        "grid": 6,
        "required":true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "guardianDetailsgurdianCifNumberCustomerApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "type": "title",
        "label": "National ID Card",
        "grid": 12,
    },
    {
        "varName": "natioanlIdCarddocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "natioanlIdCarddocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "natioanlIdCarddocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "NID",
            "value": "NID CARD IDENTITY"
        }],
        "label": "Document Code ",
        "grid": 6,

    },

    {
        "varName": "nationalIdCardNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "National ID Card Number/Unique ID",
        "grid": 6,
        "validation": "nid",
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "varName": "natioanlIdCardnidVerificationConfirmationIsDocumentVerified1",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "varName": "natioanlIdCardissueDateN",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },
    {
        "varName": "natioanlIdCardDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "type": "title",
        "label": "Smart ID Card",
        "grid": 12,
    },
    {
        "varName": "smartIdCarddocumentTypeS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "smartIdCarddocumentTypeDescriptionS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "smartIdCarddocumentCodeS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "RSNID",
            "value": "RSNID"
        }],
        "label": "Document Code ",
        "grid": 6,
        //"required": true,
    },

    {
        "varName": "smartCardNumberUniqueId",
        "type": "text",
        "label": "Smart Card Number/Unique ID",
        "grid": 6,
        //"required": true,
        "validation": "smartCard",
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"

    },

    {
        "varName": "smartIdCardnidVerificationConfirmationIsDocumentVerified",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },

    {
        "varName": "smartIdCardissueDateS",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },
    {
        "varName": "smartIdCardDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },
    {
        "type": "title",
        "label": "Passport Number",
        "grid": 12,
    },
    {
        "varName": "passportNumberdocumentTypeP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "passportNumberdocumentTypeDescriptionP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        //inputData.passportNumberdocumentTypeDescriptionP=inputData.passport
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "passportNumberdocumentCodeP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "PASSPORT",
            "value": "PASSPORT IDENTITY"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "passportNumberpassportNumberUniqueId",
        "type": "textApiCall",
        "label": "Passport Number/Unique ID",
        "grid": 6,
        "validation": "passport",
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberplaceOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Place of Issue",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberissueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumbercountryOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country of Issue",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberexpiryDate",
        "type": "date",
        "label": "Expiry Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },
    {
        "varName": "passportNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },
    {
        "type": "title",
        "label": "Driving License",
        "grid": 12,
    },
    {
        "varName": "drivingLicensedocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "drivingLicensedocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "drivingLicensedocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName":"documentCode",
            "key":"DL",
            "value":"DRIVING LICENSE"
        },],
        "label": "Document Code",
        "grid": 6,

    },

    {
        "varName": "drivingLicenseNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "Driving ID Card Number/Unique ID",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "drivingLicensedocumentCodeN",
        "conditionalVarValue": "DL"
    },
    {
        "varName": "drivingLicenseNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "drivingLicensedocumentCodeN",
        "conditionalVarValue": "DL"
    },

    {
        "type": "title",
        "label": "Resident ID",
        "grid": 12,
    },
    {
        "varName": "residentdocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "residentdocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "residentdocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName":"documentCode",
            "key":"RESID",
            "value":"RESIDENT ID CARD"
        },],
        "label": "Document Code ",
        "grid": 6,

    },

    {
        "varName": "residentNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "Resident ID",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "residentdocumentCodeN",
        "conditionalVarValue": "RESID"
    },
    {
        "varName": "residentNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "residentdocumentCodeN",
        "conditionalVarValue": "RESID"
    },
    {
        "type": "title",
        "label": "Birth Certificate",
        "grid": 12,
    },
    {
        "varName": "birthCertificatedocumentTypeB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "birthCertificatedocumentTypeDescriptionB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "birthCertificatedocumentCodeB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "BC",
            "value": "BIRTH CERTIFICATE"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "birthCertificatebirthCertificateNumberUniqueId",
        "type": "text",
        "label": "Birth Certificate Number/Unique ID",
        //inputData.birthCertificatebirthCertificateNumberUniqueId=inputData.birthCertificateNo
        "grid": 6,
        "validation": "birthCertificateNumber",
        "required": true,
        "masterData": true,
        "conditionalVarName": "birthCertificatedocumentCodeB",
        "conditionalVarValue": "BC"
    },
    {
        "varName": "birthCertificateDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "birthCertificatedocumentCodeB",
        "conditionalVarValue": "BC"
    },
    {
        "type": "title",
        "label": "Chairman Certificate",
        "grid": 12,
    },
    {
        "varName": "chairmanCertificatedocumentTypeCC",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "chairmanCertificatedocumentTypeDescriptionCC",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "chairmanCertificatedocumentCodeCC",
        "type": "autoCompleteValueReturn",
        "enum": [{"masterDataName":"documentCode","key":"CHAC","value":"CHAIRMAN CERTIFICATE"}],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificateUniqueId",
        "type": "text",
        "label": "Chairman Certificate/Unique ID",
        "grid": 6,
        "validationB":"length",
        "maxLength":20,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatedocumentCodeCC",
        "conditionalVarValue": "CHAC"
    },
    {
        "varName": "chairmanCertificateDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatedocumentCodeCC",
        "conditionalVarValue": "CHAC"
    },



    {
        "type": "title",
        "label": "Other Photo ID",
        "grid": 12,
    },


    {
        "varName": "photoIddocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "photoIddocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "photoIddocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "photoIdUniqueId",
        "type": "text",
        "label": "Unique ID",
        "grid": 6,
    },
    {
        "varName": "photoIdDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
    },
    {
        "type": "empty",
        "grid":12,

    },
    {
        "varName": "chairmanCertificateplaceOfBirthFreeText14",
        "type": "text",
        "label": "Place of Birth/Free Text 14",
        "maxLength":15,
        "validationB":"length",
        "grid": 6,

    },

    {
        "varName": "chairmanCertificatecountryOfBirthFreeText15",
        "type": "text",
        "label": "Country of Birth/Free Text 15",
        "grid": 6,

    },

    {
        "varName": "Customersnationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Nationality",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "chairmanCertificatenonResident",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Non Resident",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "chairmanCertificateturnedNonResidentOn",
        "type": "date",
        "label": "Turned Non Resident On",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },
    {
        "type": "title",
        "label": "E-Tin",
        "grid": 12,
    },
    {
        "varName": "etindocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "etindocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "etindocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "etineTinNumber",
        "type": "text",
        //inputData.etineTinNumber=inputData.eTin
        "label": "E-Tin Number/Unique ID",
        "grid": 6,
        "validation": "etin",
        "required": true,
        "masterData": true,
        "conditionalVarName": "etindocumentCode",
        "conditionalVarValue": "ETIN"
    },
    {
        "varName":"etinDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "etindocumentCode",
        "conditionalVarValue": "ETIN"
    },
    {
        "type": "title",
        "label": "Employer Data",
        "grid": 12,
    },
    {
        "varName": "employerDataprofessionEmploymentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "employmentType",
            "key": "MEDIA CELEBRITY",
            "value": "MEDIA CELEBRITY"
        }],
        "label": "Profession /Employment Type",
        "grid": 6,
    },



    {
        "varName": "employerDatanameOfEmployerEmployerName",
        "type": "text",
        "label": "Name of Employer/Employer Name",
        "validationB":"length",
        "maxLength": 50,
        "grid": 6,
    },

    {
        "varName": "employerDatamonthlyIncomeGrossIncome",
        "type": "text",
        "label": "Monthly Income/Gross income",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 2",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType2",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH2",
            "value": "COMMPH2"
        }],
        "label": "Contact Type",
        "grid": 6,

    },

    {
        "varName": "contactNumberphoneNoEmailId2",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No.",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2"
    },
    {
        "varName": "mobileTypecontactNumberphoneNo2",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required":true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Overseas",
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo2",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo2",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Overseas",

    },
    {
        "varName": "contactNumber2Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2"
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 3",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType3",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "WORKPH1",
            "value": "WORKPH1"
        }],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNoEmailId3",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No.",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1"
    },

    /*  {
          "varName": "contactNumberphoneNo3",
          "type": "text",
          "validation": "numeric",
          "label": "Phone No",
          "grid": 6,
          "required": true,
          "masterData": true,
          "conditionalVarName": "contactNumbercontactType3",
          "conditionalVarValue": "WORKPH1"
      },*/

    {
        "varName": "mobileTypecontactNumbercontactType3",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required":true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo3",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Overseas",
        "grid":6,
    },
    {
        "varName": "contactNumberphoneNo3",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo3",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Overseas",

    },


    {
        "varName": "contactNumber3Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1"
    },

    {
        "type": "title",
        "label": "Contact Number - Email",
        "grid": 12,
    },

    {
        "varName": "contactNumberEmailemailType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "HOMEEML",
            "value": "HOMEEML"
        }],
        "required":true,
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "EMAIL",
            "value": "EMAIL"
        }],
        "label": "Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailId2",
        "type": "text",
        "label": "Email ID 2",
        "validation": "email",
        "grid": 6,
    },
    {
        "varName": "contactNumberEmailemailId2Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "required":true,
        "label": "Delete Flag",
        "grid": 6,
    },

    {
        "type": "title",
        "label": "Mailing Address",
        "grid": 12,
    },
    {
        "varName": "maillingAdresspreferredAddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        //inputData.maillingAdresspreferredAddressType=inputData.SelectCommunicationAddress

        "label": "Preferred Address type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        "label": "Addrss type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressmailingAddressField1",
        "type": "text",
        //inputData.maillingAdressmailingAddressField1=inputData.communicationAddress1
        "label": "Mailing Address Field 1",
        "validationB":"length",
        "maxLength":45,
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressmailingAddressField2",
        "type": "text",
        //inputData.maillingAdressmailingAddressField2=inputData.communicationAddress2
        "label": "Mailing Address Field 2",
        "validationB":"length",
        "maxLength":45,
        "grid": 6,
    },

    {
        "varName": "maillingAdresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdresscity=inputData.city2

        "label": "City",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdressstate=inputData.state2

        "label": "Division(State)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdresspostalCode",
        "type": "text",
        "validationB":"length",
        "maxLength":6,
        "label": "Postal Code",
        //inputData.maillingAdresspostalCode=inputData.postalCode3
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdresscountry=inputData.nationality
        "label": "Country",
        "grid": 6,
        "required": true,
    },

    {
        "type": "title",
        "label": "Home Address",
        "grid": 12,
    },
    {
        "varName": "homeAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "required":true,
        "label": "Address Format",
        "grid": 6,

    },

    {
        "varName": "homeAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Home",
            "value": "Home"
        }],
        "onKeyDown": true,
        "label": "Address type",
        "grid": 6,

    },

    {
        "varName": "homeAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Home",
            "value": "Home"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,

    },

    {
        "varName": "homeAddresshomeAddressField1",
        "type": "text",
        "label": "Home Address Field 1",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "required":true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresshomeAddressField2",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],

        "label": "City",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresspostalCode",
        "type": "text",
        "validationB":"length",
        "maxLength":6,
        "label": "Postal Code",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },
    {
        "varName": "homeAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },
    {
        "type": "title",
        "label": "Work Address",
        "grid": 12,
    },
    {
        "varName": "workAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "workAddressaddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Work",
            "value": "Work"
        }],
        "label": "Address type",
        "grid": 6,
    },

    {
        "varName": "workAddressaddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Work",
            "value": "Work"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "workAddressworkAddressField1",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "required":true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddressworkAddressField2",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresspostalCode",
        "type": "text",
        "validationB":"length",
        "maxLength":6,
        "label": "Postal Code",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "masterData": true,
        "required":true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },
    //1present address
    {
        "type": "title",
        "label": "Present Address",
        "grid": 12,
    },
    {
        "varName": "presentAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "required":true,
        "grid": 6,
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Future/OnSite",
            "value": "Future/OnSite"
        }],
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [ {
            "masterDataName": "addressType",
            "key": "Future/OnSite",
            "value": "Future/OnSite"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "presentAddresspresentAddressField1",
        "type": "text",
        "label": "Present Address Field 1",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "required":true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"

    },

    {
        "varName": "presentAddresspresentAddressField2",
        "type": "text",
        "label": "Present Address Field 2",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresspostalCode",
        "type": "text",
        "validationB":"length",
        "maxLength":6,
        "label": "Postal Code",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },
    {
        "varName": "presentAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    }, //1NRE address
    {
        "type": "title",
        "label": "NRE Address",
        "grid": 12,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },
    {
        "varName": "nreAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [
            {"masterDataName": "addressType",
                "key": "NRERelative",
                "value": "NRERelative"
            }
        ],
        "label": "Addrss type",
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
        "grid": 6,
    },

    {
        "varName": "nreAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [ {"masterDataName": "addressType",
            "key": "NRERelative",
            "value": "NRERelative"
        }],
        "label": "Address label",
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
        "grid": 6,
    },

    {
        "varName": "nreAddressnreAddressField1",
        "type": "text",
        "label": "NRE Address Field 1",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "required":true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"

    },

    {
        "varName": "nreAddressnreAddressField2",
        "type": "text",
        "label": "NRE Address Field 2",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresspostalCode",
        "type": "text",
        "validationB":"length",
        "maxLength":6,
        "label": "Postal Code",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"

    },
    {
        "varName": "nreAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },


];
let CBDataJsonFormNew= [
    {
        "type": "title",
        "label": "MIS RELATED",
        "grid": 12,
    },

    /*  {
          "varName": "misprimarySolId",
          "type": "text",
          "label": "Primary Sol ID",
          "grid": 6,
          "required": true,
      },*/


    {
        "varName": "miscustomerIdCifId",
        "type": "text",
        //"required": true,
        "label": "Customer ID/CIF ID",
        "grid": 6,
        "readOnly": true,
        // "required": true,
    },


    {
        "varName": "mistitle",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //mistitle=inputData.customerTitle
        "label": "Title",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "miscustomerNameLastName",
        "type": "text",
        "required": true,
        "validationB":"length",
        "maxLength": 80,
        //inputData.miscustomerNameLastName=inputData.customerName;
        "label": "Customer Name/Last Name",
        "grid": 6,
    },

    {
        "varName": "misshortName",
        "type": "text",
        "required": true,
        "validationB":"length",
        "maxLength": 10,
        //inputData.misshortName=inputData.shortName;

        "label": "Short Name",
        "grid": 6,
    },

    /*  {
          "varName": "misgender",
          "type": "select",
          //"required": true,
          "enum": [
              "M",
              "F",
              "TRANSGENDER",
              "OTHER"
          ],
          "label": "Gender",
          "grid": 6,
          "required": true,
      },*/

    {
        "varName": "miscustomerStatusNormalStaffPriorityEtc",
        "type": "autoCompleteValueReturn",
        "enum": [

        ],
        "label": "Customer Status",
        //inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.customerStatus
        "grid": 6,
        "required": true,
    },

    {
        "varName": "misoccupationCodePriorityCodeStaff",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 6,
        "required":true,
        //
        /*  if(this.state.inputData.occupationCodeNormal!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodeNormal
      }
      else if(this.state.inputData.occupationCodePriority!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodePriority
      }
      else if(this.state.inputData.occupationCodeStaff!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodeStaff
      }*/
    },

    {
        "varName": "misconstitution",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //master data cbl provide
        "label": "Constitution",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "misstaffIndicatorStatus",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "label": "Staff Indicator/Status",
        //inputData.misstaffIndicatorStatus=inputData.staffIndicator
        "grid": 6,



    },

    {
        "varName": "misstaffIdNumber",
        "type": "text",
        "label": "Staff ID Number",
        "validation": "staffId",
        //inputData.misstaffIdNumber=inputData.staffIdNumber
        "grid": 6,

    },

    {
        "varName": "mispriorityCenterCodeFreeCode1",
        "type": "select",
        "enum": [
           "PR104",
            "PR142",
            "PR177",
            "PR186",
            "PR187",
            "PR195",
            "PR208",
            "PR169",
        ],
        //inputData.mispriorityCenterCodeFreeCode1=inputData.priorityCenterCode;
        "label": "Priority Center Code/Free Code 1",
        "grid": 6,
    },

    {
        "varName": "mispriorityMonitoringRmCodeFreeCode3",
        "type": "textApiCall",
        "enum": [],
        //inputData.mispriorityMonitoringRmCodeFreeCode3=inputData.monitoringRMCode
        "label": "Priority Monitoring  RM Code",
        "grid": 6,
    },
    {
        "varName": "mispriorityMonitoringRmCodeFreeCode3Name",
        "type": "text",
        //inputData.mispriorityMonitoringRmCodeFreeCode3=inputData.monitoringRMCode
        "label": "Priority Monitoring  RM Code Name",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "mispriorityMonitoringRmCodeFreeCode3ApiCall",
        "conditionalVarValue": true,
        "grid": 6,
    },

    {
        "varName": "misprimaryCbHolderForPriorityFreeText8",
        "type": "text",
        //inputData.misprimaryCbHolderForPriorityFreeText8=inputData.primaryCBHolderforPriority
        "label": "Primary CB Holder for Priority",
        "grid": 6,
    },

    {
        "varName": "misrelationshipWithPrimaryCbHolderFreeText9",
        "type": "text",
        //inputData.misrelationshipWithPrimaryCbHolderFreeText9=inputData.relationshipwithPrimaryCBHolder;
        "label": "Relationship with Primary CB Holder",
        "grid": 6,
    },


    {
        "varName": "misbbkSegmentCodeFreeCode7",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "BBK Segment Code",
        "grid": 6,
        "required": true,
    },


    {
        "varName": "mismigratedNidVerificationFreeText2",
        "type": "text",
        "label": "Migrated NID Verification",
        "grid": 6,
    },

    {
        "varName": "mismigratedSmartCardNumberFreeText5",
        "type": "text",
        "label": "Migrated Smart Card Number",
        "grid": 6,
    },

    {
        "varName": "mispepStatusFreeText10",
        "type": "text",
        "label": "PEP Status",
        "grid": 6,
    },

    {
        "varName": "mismigratedCifsDocumentNoFreeText13",
        "type": "text",
        "label": "Migrated CIFs Document No",
        "grid": 6,
    },


    {
        "varName": "misfreeCodeFreeText1",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText2",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText3",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misavailedTradeServices",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Availed Trade services",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Currency Code",
        "grid": 12,
    },


    {
        "varName": "aof1currencyCodeCcy",
        "type": "text",
        //inputData.aof1currencyCodeCcy=inputData.currency
        "label": "Currency Code/CCY",
        "grid": 6,
        "required": true,
        //"readOnly":true,
        "conditional": true,
        "conditionalVarName": "aof1currencyCodeCcyExist",
        "conditionalVarValue": true,
    },
    {
        "varName": "aof1currencyCodeCcy",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.aof1currencyCodeCcy=inputData.currency
        "label": "Currency Code/CCY",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "aof1currencyCodeCcyExist",
        "conditionalVarValue": false,
        //"readOnly":true,
    },
    {
        "type": "title",
        "label": "Contact Number - Email",
        "grid": 12,
    },
    {
        "varName": "contactNumberEmailpreferedEmailIdType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "COMMEML",
            "value": "COMMEML"
        }],
        "readOnly": true,
        "label": "Prefered Email ID type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailType1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "COMMEML",
            "value": "COMMEML"
        }],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId4",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "EMAIL",
            "value": "EMAIL"
        }],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmaildesignatedEmailAddressEmailId1",
        "type": "text",
        "label": "Designated Email Address/Email ID 1",
         "validation": "email",
        "grid": 6,
    },
    {
        "varName": "contactNumberEmaildesignatedEmailAddressEmailId1Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,


    },
    {
        "type": "title",
        "label": "Contact Number - Phone",
        "grid": 12,
    },
    {
        "varName": "contactNumberpreferredContactNoType1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH1",
            "value": "COMMPH1"
        }],
        "label": "Preferred Contact no type",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 1",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType1",
        "type": "autoCompleteValueReturn",
        "enum": [ {
            "masterDataName": "contactType",
            "key": "COMMPH1",
            "value": "COMMPH1"
        }],
        "label": "Contact Type",
        "grid": 6,
        "readOnly":true,
    },

    {
        "varName": "contactNumberphoneNoEmailId1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No./Email ID",
        "grid": 6,
        "readOnly":true,
    },
    {
        "varName": "mobileTypecontactNumberphoneNo1",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required":true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType1",
        "conditionalVarValue": "COMMPH1",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Overseas",
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo1",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo1",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Overseas",

    },


    {
        "type": "title",
        "label": "Primary Introducer details",
        "grid": 12,
    },
    {
        "varName": "nidVerified",
        "type": "select",
        "enum":[
            "YES",
            "NO",
        ],
        "required": true,
        "label": "Is All NID/Smart Card Document Verified?",
        "grid": 6,

    },
    {
        "varName": "primryintroducerCustomerIdCifId",
        "type": "textApiCall",
        //inputData.primryintroducerCustomerIdCifId=inputData.introducerId
        "label": "Introducer Customer Id/CIF ID",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },


    {
        "varName": "primryiintroducerNameLastName",
        "type": "text",
        //inputData.primryiintroducerNameLastName=inputData.introducerName
        "label": "Introducer Name/Last Name",
        "grid": 6,
        "readOnly":true,
        "conditionalArray": true,
        "conditionalArrayList": ["introducerIdApiCall", "nidVerified"],
        "conditionalArrayValue": [true, "NO"]

    },

    {
        "varName": "primryiintroducerStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Introducer Status",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },

    {
        "varName": "primryicifTypePrimaryIntroducerDetails",
        "type": "select",
        "enum": [
            "Retail",
            "Corporate",
        ],
        "label": "CIF Type/Primary Introducer Details",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "type": "title",
        "label": "Related CB Tagging :",
        "grid": 12,
    },
    {
        "varName": "relatedCbTaggingrelationType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "relatedCbTaggingrelationCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation Code",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "relatedCbTaggingdesignationCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Designation Code",
        "grid": 6,
        "required": true,
    },



    {
        "varName": "crmMissubSegment",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Segment",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Basic Information Update",
        "grid": 12,
    },
    {
        "varName": "basicInformationUpdateprimarySolId",
        "type": "text",
        "enum": [],
        //"readOnly":true,
        "label": "Primary Sol ID",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdategender",
        "type": "select",
        "enum": [
            "M",
            "F",
            "TRANSGENDER",
            "OTHER"
        ],
        "label": "Gender",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdatefatherNamePrefferedName",
        "type": "text",
        "label": "Father Name/Preffered Name",
        "validationB":"length",
        "maxLength":50,
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdatemotherNameMothersMaidenName",
        "type": "text",
        "label": "Mother Name/Mothers Maiden Name",
        "grid": 6,
        "validationB":"length",
        "maxLength":50,
    },

    {
        "varName": "basicInformationUpdatemaritalStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Marital Status",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "basicInformationUpdatespouseNamePurgeRemarks",
        "type": "text",
        "label": "Spouse Name/Purge Remarks",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "basicInformationUpdatemaritalStatus",
        "conditionalVarValue":"MARID",
        "validationB":"length",
        "maxLength":240,

    },

    {
        "varName": "basicInformationUpdatedob",
        "type": "date",
        "maxDate": true,
        "onKeyDown": true,
        //inputData.basicInformationUpdatedob=inputData.gender
        "label": "DOB",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdateminorStatusMinorIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "readOnly":true,
        "label": "Minor Status/Minor Indicator",
        "conditional": true,
        "conditionalVarName": "basicInformationUpdatedobApiCall",
        "conditionalVarValue": "YES",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateseniorCitizenIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "readOnly":true,
        "label": "Senior Citizen Indicator",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdatedobApiCall",
        "conditionalVarValue": "YES",
        "required": true,
    },
    {
        "type": "title",
        "label": "Gurdian Details - Only Visible In Case Minor Account Holder",
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",

        "grid": 12,
    },


    {
        "varName": "guardianDetailsrelation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation *",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsbankRelationType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Bank Relation Type *",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsrelation1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation *",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsguardianSelectionGurdian",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Guardian Selection/Gurdian",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsgurdianCifNumberCustomer",
        "type": "textApiCall",
        "label": "Gurdian CIF Number/Customer *",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailslastName",
        "type": "text",
        "enum": [],
        "label": "Last Name *",
        "grid": 6,
        "required":true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "guardianDetailsgurdianCifNumberCustomerApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "type": "title",
        "label": "National ID Card",
        "grid": 12,
    },
    {
        "varName": "natioanlIdCarddocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "natioanlIdCarddocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "natioanlIdCarddocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "NID",
            "value": "NID CARD IDENTITY"
        }],
        "label": "Document Code ",
        "grid": 6,

    },

    {
        "varName": "nationalIdCardNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "National ID Card Number/Unique ID",
        "grid": 6,
        "validation": "nid",
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "varName": "natioanlIdCardnidVerificationConfirmationIsDocumentVerified1",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "varName": "natioanlIdCardissueDateN",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },
    {
        "varName": "natioanlIdCardDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "type": "title",
        "label": "Smart ID Card",
        "grid": 12,
    },
    {
        "varName": "smartIdCarddocumentTypeS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "smartIdCarddocumentTypeDescriptionS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "smartIdCarddocumentCodeS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "RSNID",
            "value": "RSNID"
        }],
        "label": "Document Code ",
        "grid": 6,
        //"required": true,
    },

    {
        "varName": "smartCardNumberUniqueId",
        "type": "text",
        "label": "Smart Card Number/Unique ID",
        "grid": 6,
        //"required": true,
        "validation": "smartCard",
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"

    },

    {
        "varName": "smartIdCardnidVerificationConfirmationIsDocumentVerified",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },

    {
        "varName": "smartIdCardissueDateS",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },
    {
        "varName": "smartIdCardDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },
    {
        "type": "title",
        "label": "Passport Number",
        "grid": 12,
    },
    {
        "varName": "passportNumberdocumentTypeP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "passportNumberdocumentTypeDescriptionP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        //inputData.passportNumberdocumentTypeDescriptionP=inputData.passport
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "passportNumberdocumentCodeP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "PASSPORT",
            "value": "PASSPORT IDENTITY"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "passportNumberpassportNumberUniqueId",
        "type": "textApiCall",
        "label": "Passport Number/Unique ID",
        "grid": 6,
        "validation": "passport",
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberplaceOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Place of Issue",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberissueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumbercountryOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country of Issue",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberexpiryDate",
        "type": "date",
        "label": "Expiry Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },
    {
        "varName": "passportNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },
    {
        "type": "title",
        "label": "Driving License",
        "grid": 12,
    },
    {
        "varName": "drivingLicensedocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "drivingLicensedocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "drivingLicensedocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName":"documentCode",
            "key":"DL",
            "value":"DRIVING LICENSE"
        },],
        "label": "Document Code",
        "grid": 6,

    },

    {
        "varName": "drivingLicenseNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "Driving ID Card Number/Unique ID",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "drivingLicensedocumentCodeN",
        "conditionalVarValue": "DL"
    },
    {
        "varName": "drivingLicenseNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "drivingLicensedocumentCodeN",
        "conditionalVarValue": "DL"
    },

    {
        "type": "title",
        "label": "Resident ID",
        "grid": 12,
    },
    {
        "varName": "residentdocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "residentdocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "residentdocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName":"documentCode",
            "key":"RESID",
            "value":"RESIDENT ID CARD"
        },],
        "label": "Document Code ",
        "grid": 6,

    },

    {
        "varName": "residentNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "Resident ID",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "residentdocumentCodeN",
        "conditionalVarValue": "RESID"
    },
    {
        "varName": "residentNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "residentdocumentCodeN",
        "conditionalVarValue": "RESID"
    },
    {
        "type": "title",
        "label": "Birth Certificate",
        "grid": 12,
    },
    {
        "varName": "birthCertificatedocumentTypeB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "birthCertificatedocumentTypeDescriptionB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "birthCertificatedocumentCodeB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "BC",
            "value": "BIRTH CERTIFICATE"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "birthCertificatebirthCertificateNumberUniqueId",
        "type": "text",
        "label": "Birth Certificate Number/Unique ID",
        //inputData.birthCertificatebirthCertificateNumberUniqueId=inputData.birthCertificateNo
        "grid": 6,
        "validation": "birthCertificateNumber",
        "required": true,
        "masterData": true,
        "conditionalVarName": "birthCertificatedocumentCodeB",
        "conditionalVarValue": "BC"
    },
    {
        "varName": "birthCertificateDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "birthCertificatedocumentCodeB",
        "conditionalVarValue": "BC"
    },
    {
        "type": "title",
        "label": "Chairman Certificate",
        "grid": 12,
    },
    {
        "varName": "chairmanCertificatedocumentTypeCC",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "chairmanCertificatedocumentTypeDescriptionCC",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "chairmanCertificatedocumentCodeCC",
        "type": "autoCompleteValueReturn",
        "enum": [{"masterDataName":"documentCode","key":"CHAC","value":"CHAIRMAN CERTIFICATE"}],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificateUniqueId",
        "type": "text",
        "label": "Chairman Certificate/Unique ID",
        "grid": 6,
        "validationB":"length",
        "maxLength":20,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatedocumentCodeCC",
        "conditionalVarValue": "CHAC"
    },
    {
        "varName": "chairmanCertificateDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatedocumentCodeCC",
        "conditionalVarValue": "CHAC"
    },



    {
        "type": "title",
        "label": "Other Photo ID",
        "grid": 12,
    },


    {
        "varName": "photoIddocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "photoIddocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "photoIddocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "photoIdUniqueId",
        "type": "text",
        "label": "Unique ID",
        "grid": 6,
    },
    {
        "varName": "photoIdDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
    },
    {
        "type": "empty",
        "grid":12,

    },
    {
        "varName": "chairmanCertificateplaceOfBirthFreeText14",
        "type": "text",
        "label": "Place of Birth/Free Text 14",
        "maxLength":15,
        "validationB":"length",
        "grid": 6,

    },

    {
        "varName": "chairmanCertificatecountryOfBirthFreeText15",
        "type": "text",
        "label": "Country of Birth/Free Text 15",
        "grid": 6,

    },

    {
        "varName": "Customersnationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Nationality",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "chairmanCertificatenonResident",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Non Resident",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "chairmanCertificateturnedNonResidentOn",
        "type": "date",
        "label": "Turned Non Resident On",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },
    {
        "type": "title",
        "label": "E-Tin",
        "grid": 12,
    },
    {
        "varName": "etindocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "etindocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "etindocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "etineTinNumber",
        "type": "text",
        //inputData.etineTinNumber=inputData.eTin
        "label": "E-Tin Number/Unique ID",
        "grid": 6,
        "validation": "etin",
        "required": true,
        "masterData": true,
        "conditionalVarName": "etindocumentCode",
        "conditionalVarValue": "ETIN"
    },
    {
        "varName":"etinDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "etindocumentCode",
        "conditionalVarValue": "ETIN"
    },
    {
        "type": "title",
        "label": "Employer Data",
        "grid": 12,
    },
    {
        "varName": "employerDataprofessionEmploymentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "employmentType",
            "key": "MEDIA CELEBRITY",
            "value": "MEDIA CELEBRITY"
        }],
        "label": "Profession /Employment Type",
        "grid": 6,
    },



    {
        "varName": "employerDatanameOfEmployerEmployerName",
        "type": "text",
        "label": "Name of Employer/Employer Name",
        "validationB":"length",
        "maxLength": 50,
        "grid": 6,
    },

    {
        "varName": "employerDatamonthlyIncomeGrossIncome",
        "type": "text",
        "label": "Monthly Income/Gross income",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 2",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType2",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH2",
            "value": "COMMPH2"
        }],
        "label": "Contact Type",
        "grid": 6,

    },

    {
        "varName": "contactNumberphoneNoEmailId2",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No.",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2"
    },
    {
        "varName": "mobileTypecontactNumberphoneNo2",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required":true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Overseas",
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo2",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo2",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Overseas",

    },
    {
        "varName": "contactNumber2Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2"
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 3",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType3",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "WORKPH1",
            "value": "WORKPH1"
        }],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNoEmailId3",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No.",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1"
    },

    /*  {
          "varName": "contactNumberphoneNo3",
          "type": "text",
          "validation": "numeric",
          "label": "Phone No",
          "grid": 6,
          "required": true,
          "masterData": true,
          "conditionalVarName": "contactNumbercontactType3",
          "conditionalVarValue": "WORKPH1"
      },*/

    {
        "varName": "mobileTypecontactNumbercontactType3",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required":true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo3",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Overseas",
        "grid":6,
    },
    {
        "varName": "contactNumberphoneNo3",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo3",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Overseas",

    },




    {
        "type": "title",
        "label": "Contact Number - Email",
        "grid": 12,
    },

    {
        "varName": "contactNumberEmailemailType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "HOMEEML",
            "value": "HOMEEML"
        }],
        "required":true,
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "EMAIL",
            "value": "EMAIL"
        }],
        "label": "Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailId2",
        "type": "text",
        "label": "Email ID 2",
        "validation": "email",
        "grid": 6,
    },
    {
        "varName": "contactNumberEmailemailId2Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "required":true,
        "label": "Delete Flag",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Mailing Address",
        "grid": 12,
    },
    {
        "varName": "maillingAdresspreferredAddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        //inputData.maillingAdresspreferredAddressType=inputData.SelectCommunicationAddress

        "label": "Preferred Address type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        "label": "Addrss type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressmailingAddressField1",
        "type": "text",
        //inputData.maillingAdressmailingAddressField1=inputData.communicationAddress1
        "label": "Mailing Address Field 1",
        "validationB":"length",
        "maxLength":45,
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressmailingAddressField2",
        "type": "text",
        //inputData.maillingAdressmailingAddressField2=inputData.communicationAddress2
        "label": "Mailing Address Field 2",
        "validationB":"length",
        "maxLength":45,
        "grid": 6,
    },

    {
        "varName": "maillingAdresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdresscity=inputData.city2

        "label": "City",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdressstate=inputData.state2

        "label": "Division(State)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdresspostalCode",
        "type": "text",
        "validationB":"length",
        "maxLength":6,
        "label": "Postal Code",
        //inputData.maillingAdresspostalCode=inputData.postalCode3
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdresscountry=inputData.nationality
        "label": "Country",
        "grid": 6,
        "required": true,
    },

    {
        "type": "title",
        "label": "Home Address",
        "grid": 12,
    },
    {
        "varName": "homeAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "required":true,
        "label": "Address Format",
        "grid": 6,

    },

    {
        "varName": "homeAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Home",
            "value": "Home"
        }],
        "onKeyDown": true,
        "label": "Address type",
        "grid": 6,

    },

    {
        "varName": "homeAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Home",
            "value": "Home"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,

    },

    {
        "varName": "homeAddresshomeAddressField1",
        "type": "text",
        "label": "Home Address Field 1",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "required":true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresshomeAddressField2",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],

        "label": "City",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresspostalCode",
        "type": "text",
        "validationB":"length",
        "maxLength":6,
        "label": "Postal Code",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },
    {
        "varName": "homeAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },
    {
        "type": "title",
        "label": "Work Address",
        "grid": 12,
    },
    {
        "varName": "workAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "workAddressaddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Work",
            "value": "Work"
        }],
        "label": "Address type",
        "grid": 6,
    },

    {
        "varName": "workAddressaddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Work",
            "value": "Work"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "workAddressworkAddressField1",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "required":true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddressworkAddressField2",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresspostalCode",
        "type": "text",
        "validationB":"length",
        "maxLength":6,
        "label": "Postal Code",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "masterData": true,
        "required":true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },
    //1present address
    {
        "type": "title",
        "label": "Present Address",
        "grid": 12,
    },
    {
        "varName": "presentAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "required":true,
        "grid": 6,
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Future/OnSite",
            "value": "Future/OnSite"
        }],
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [ {
            "masterDataName": "addressType",
            "key": "Future/OnSite",
            "value": "Future/OnSite"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "presentAddresspresentAddressField1",
        "type": "text",
        "label": "Present Address Field 1",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "required":true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"

    },

    {
        "varName": "presentAddresspresentAddressField2",
        "type": "text",
        "label": "Present Address Field 2",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresspostalCode",
        "type": "text",
        "validationB":"length",
        "maxLength":6,
        "label": "Postal Code",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },
    {
        "varName": "presentAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    }, //1NRE address
    {
        "type": "title",
        "label": "NRE Address",
        "grid": 12,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
    },
    {
        "varName": "nreAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "nreAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [ {"masterDataName": "addressType",
            "key": "NRERelative",
            "value": "NRERelative"
        }],
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "nreAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [ {"masterDataName": "addressType",
            "key": "NRERelative",
            "value": "NRERelative"
        }],
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "nreAddressnreAddressField1",
        "type": "text",
        "label": "NRE Address Field 1",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "required":true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"

    },

    {
        "varName": "nreAddressnreAddressField2",
        "type": "text",
        "label": "NRE Address Field 2",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresspostalCode",
        "type": "text",
        "validationB":"length",
        "maxLength":6,
        "label": "Postal Code",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"

    },
    {
        "varName": "nreAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },


];
let FdrAndDpsTagForm = [
    {
        "type": "title",
        "label": "Related CB Tagging :",
        "grid": 12,
    },
    {
        "varName": "miscustomerIdCifId",
        "type": "text",
        //"required": true,
        "label": "Customer ID/CIF ID",
        "grid": 6,
        "readOnly": true,
        // "required": true,
    },



    {
        "varName": "miscustomerNameLastName",
        "type": "text",
        "required": true,
        "validationB":"length",
        "maxLength": 80,
        //inputData.miscustomerNameLastName=inputData.customerName;
        "label": "Customer Name/Last Name",
        "grid": 6,
    },



    {
        "varName": "relatedCbTaggingrelationType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "relatedCbTaggingrelationCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation Code",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "relatedCbTaggingdesignationCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Designation Code",
        "grid": 6,
        "required": true,
    }

];
let CBDataJsonFormForFDR = [
    {
        "type": "title",
        "label": "MIS RELATED",
        "grid": 12,
    },

    /*  {
          "varName": "misprimarySolId",
          "type": "text",
          "readOnly":true,
          "label": "Primary SOL ID",
          "grid": 6,
          "required": true,
      },*/


    {
        "varName": "miscustomerIdCifId",
        "type": "text",
        //"required": true,
        "label": "Customer ID/CIF ID",
        "grid": 6,
        "readOnly": true,
        // "required": true,
    },


    {
        "varName": "mistitle",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //mistitle=inputData.customerTitle
        "label": "Title",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "miscustomerNameLastName",
        "type": "text",
        "required": true,
        "validationB":"length",
        "maxLength": 80,
        //inputData.miscustomerNameLastName=inputData.customerName;
        "label": "Customer Name/Last Name",
        "grid": 6,
    },

    {
        "varName": "misshortName",
        "type": "text",
        "required": true,
        "validationB":"length",
        "maxLength": 10,
        //inputData.misshortName=inputData.shortName;

        "label": "Short Name",
        "grid": 6,
    },

    /*  {
          "varName": "misgender",
          "type": "select",
          //"required": true,
          "enum": [
              "M",
              "F",
              "TRANSGENDER",
              "OTHER"
          ],
          "label": "Gender",
          "grid": 6,
          "required": true,
      },*/

    {
        "varName": "miscustomerStatusNormalStaffPriorityEtc",
        "type": "autoCompleteValueReturn",
        "enum": [

        ],
        "label": "Customer Status",
        //inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.customerStatus
        "grid": 6,
        "required": true,
    },

    {
        "varName": "misoccupationCodePriorityCodeStaff",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 6,
        "required":true,
        //
        /*  if(this.state.inputData.occupationCodeNormal!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodeNormal
      }
      else if(this.state.inputData.occupationCodePriority!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodePriority
      }
      else if(this.state.inputData.occupationCodeStaff!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodeStaff
      }*/
    },

    {
        "varName": "misconstitution",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //master data cbl provide
        "label": "Constitution",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "misstaffIndicatorStatus",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "label": "Staff Indicator/Status",
        //inputData.misstaffIndicatorStatus=inputData.staffIndicator
        "grid": 6,



    },

    {
        "varName": "misstaffIdNumber",
        "type": "text",
        "label": "Staff ID Number",
        "validation": "staffId",
        //inputData.misstaffIdNumber=inputData.staffIdNumber
        "grid": 6,

    },

    {
        "varName": "mispriorityCenterCodeFreeCode1",
        "type": "select",
        "enum": [
           "PR104",
            "PR142",
            "PR177",
            "PR186",
            "PR187",
            "PR195",
            "PR208",
            "PR169",
        ],
        //inputData.mispriorityCenterCodeFreeCode1=inputData.priorityCenterCode;
        "label": "Priority Center Code/Free Code 1",
        "grid": 6,
    },

    {
        "varName": "mispriorityMonitoringRmCodeFreeCode3",
        "type": "textApiCall",
        "enum": [],
        //inputData.mispriorityMonitoringRmCodeFreeCode3=inputData.monitoringRMCode
        "label": "Priority Monitoring  RM Code",
        "grid": 6,
    },
    {
        "varName": "mispriorityMonitoringRmCodeFreeCode3Name",
        "type": "text",
        //inputData.mispriorityMonitoringRmCodeFreeCode3=inputData.monitoringRMCode
        "label": "Priority Monitoring  RM Code Name",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "mispriorityMonitoringRmCodeFreeCode3ApiCall",
        "conditionalVarValue": true,
        "grid": 6,
    },

    {
        "varName": "misprimaryCbHolderForPriorityFreeText8",
        "type": "text",
        //inputData.misprimaryCbHolderForPriorityFreeText8=inputData.primaryCBHolderforPriority
        "label": "Primary CB Holder for Priority",
        "grid": 6,
    },

    {
        "varName": "misrelationshipWithPrimaryCbHolderFreeText9",
        "type": "text",
        //inputData.misrelationshipWithPrimaryCbHolderFreeText9=inputData.relationshipwithPrimaryCBHolder;
        "label": "Relationship with Primary CB Holder",
        "grid": 6,
    },


    {
        "varName": "misbbkSegmentCodeFreeCode7",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "BBK Segment Code",
        "grid": 6,
        "required": true,
    },


    {
        "varName": "mismigratedNidVerificationFreeText2",
        "type": "text",
        "label": "Migrated NID Verification",
        "grid": 6,
    },

    {
        "varName": "mismigratedSmartCardNumberFreeText5",
        "type": "text",
        "label": "Migrated Smart Card Number",
        "grid": 6,
    },

    {
        "varName": "mispepStatusFreeText10",
        "type": "text",
        "label": "PEP Status",
        "grid": 6,
    },

    {
        "varName": "mismigratedCifsDocumentNoFreeText13",
        "type": "text",
        "label": "Migrated CIFs Document No",
        "grid": 6,
    },


    {
        "varName": "misfreeCodeFreeText1",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText2",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText3",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misavailedTradeServices",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Availed Trade services",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Currency Code",
        "grid": 12,
    },

    {
        "varName": "aof1currencyCodeCcy",
        "type": "text",
        //inputData.aof1currencyCodeCcy=inputData.currency
        "label": "Currency Code/CCY",
        "grid": 6,
        "required": true,
        //"readOnly":true,
    },
    {
        "type": "title",
        "label": "Contact Number - Email",
        "grid": 12,
    },
    {
        "varName": "contactNumberEmailpreferedEmailIdType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "COMMEML",
            "value": "COMMEML"
        }],
        "readOnly": true,
        "label": "Prefered Email ID type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailType1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "COMMEML",
            "value": "COMMEML"
        }],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId4",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "EMAIL",
            "value": "EMAIL"
        }],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmaildesignatedEmailAddressEmailId1",
        "type": "text",
        "label": "Designated Email Address/Email ID 1",
         "validation": "email",
        "grid": 6,
    },

    {
        "type": "title",
        "label": "Contact Number - Phone",
        "grid": 12,
    },
    {
        "varName": "contactNumberpreferredContactNoType1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH1",
            "value": "COMMPH1"
        }],
        "label": "Preferred Contact no type",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 1",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType1",
        "type": "autoCompleteValueReturn",
        "enum": [ {
            "masterDataName": "contactType",
            "key": "COMMPH1",
            "value": "COMMPH1"
        }],
        "label": "Contact Type",
        "grid": 6,
        "readOnly":true,
    },

    {
        "varName": "contactNumberphoneNoEmailId1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No./Email ID",
        "grid": 6,
        "readOnly":true,
    },
    {
        "varName": "mobileTypecontactNumberphoneNo1",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required":true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType1",
        "conditionalVarValue": "COMMPH1",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Overseas",
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo1",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo1",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Overseas",

    },


    {
        "type": "title",
        "label": "Primary Introducer details",
        "grid": 12,
    },
    {
        "varName": "nidVerified",
        "type": "select",
        "enum":[
            "YES",
            "NO",
        ],
        "required": true,
        "label": "Is All NID/Smart Card Document Verified?",
        "grid": 6,

    },
    {
        "varName": "primryintroducerCustomerIdCifId",
        "type": "textApiCall",
        //inputData.primryintroducerCustomerIdCifId=inputData.introducerId
        "label": "Introducer Customer Id/CIF ID",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },


    {
        "varName": "primryiintroducerNameLastName",
        "type": "text",
        //inputData.primryiintroducerNameLastName=inputData.introducerName
        "label": "Introducer Name/Last Name",
        "grid": 6,
        "readOnly":true,
        "conditionalArray": true,
        "conditionalArrayList": ["introducerIdApiCall", "nidVerified"],
        "conditionalArrayValue": [true, "NO"]

    },

    {
        "varName": "primryiintroducerStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Introducer Status",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },

    {
        "varName": "primryicifTypePrimaryIntroducerDetails",
        "type": "select",
        "enum": [
            "Retail",
            "Corporate",
        ],
        "label": "CIF Type/Primary Introducer Details",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },
    {
        "type": "empty",
        "grid": 12,
    },
    /* {
            "type": "title",
            "label": "Related CB Tagging :",
            "grid": 12,
        },
        {
            "varName": "relatedCbTaggingrelationType",
            "type": "autoCompleteValueReturn",
            "enum": [],
            "label": "Relation Type",
            "grid": 6,
            "required": true,
        },

        {
            "varName": "relatedCbTaggingrelationCode",
            "type": "autoCompleteValueReturn",
            "enum": [],
            "label": "Relation Code",
            "grid": 6,
            "required": true,
        },

        {
            "varName": "relatedCbTaggingdesignationCode",
            "type": "autoCompleteValueReturn",
            "enum": [],
            "label": "Designation Code",
            "grid": 6,
            "required": true,
        },



        {
            "varName": "crmMissubSegment",
            "type": "autoCompleteValueReturn",
            "enum": [],
            "label": "Sub Segment",
            "grid": 6,
            "required": true,
        },*/
    {
        "varName": "crmMissubSegment",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Segment",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Basic Information Update",
        "grid": 12,
    },
    {
        "varName": "basicInformationUpdateprimarySolId",
        "type": "text",
        "enum": [],
        "readOnly":true,
        "label": "Primary SOL ID",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdategender",
        "type": "select",
        "enum": [
            "M",
            "F",
            "TRANSGENDER",
            "OTHER"
        ],
        "label": "Gender",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdatefatherNamePrefferedName",
        "type": "text",
        "label": "Father Name/Preffered Name",
        "validationB":"length",
        "maxLength":50,
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdatemotherNameMothersMaidenName",
        "type": "text",
        "label": "Mother Name/Mothers Maiden Name",
        "grid": 6,
        "validationB":"length",
        "maxLength":50,
    },

    {
        "varName": "basicInformationUpdatemaritalStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Marital Status",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "basicInformationUpdatespouseNamePurgeRemarks",
        "type": "text",
        "label": "Spouse Name/Purge Remarks",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "basicInformationUpdatemaritalStatus",
        "conditionalVarValue":"MARID",
        "validationB":"length",
        "maxLength":240,

    },

    {
        "varName": "basicInformationUpdatedob",
        "type": "date",
        "maxDate": true,
        "onKeyDown": true,
        //inputData.basicInformationUpdatedob=inputData.gender
        "label": "DOB",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdateminorStatusMinorIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "readOnly":true,
        "label": "Minor Status/Minor Indicator",
        "conditional": true,
        "conditionalVarName": "basicInformationUpdatedobApiCall",
        "conditionalVarValue": "YES",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateseniorCitizenIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "readOnly":true,
        "label": "Senior Citizen Indicator",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdatedobApiCall",
        "conditionalVarValue": "YES",
        "required": true,
    },
    {
        "type": "title",
        "label": "Gurdian Details - Only Visible In Case Minor Account Holder",
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",

        "grid": 12,
    },


    {
        "varName": "guardianDetailsrelation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation *",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsbankRelationType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Bank Relation Type *",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsrelation1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation *",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsguardianSelectionGurdian",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Guardian Selection/Gurdian",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsgurdianCifNumberCustomer",
        "type": "textApiCall",
        "label": "Gurdian CIF Number/Customer *",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailslastName",
        "type": "text",
        "enum": [],
        "label": "Last Name *",
        "grid": 6,
        "required":true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "guardianDetailsgurdianCifNumberCustomerApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "type": "title",
        "label": "National ID Card",
        "grid": 12,
    },
    {
        "varName": "natioanlIdCarddocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "natioanlIdCarddocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "natioanlIdCarddocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "NID",
            "value": "NID CARD IDENTITY"
        }],
        "label": "Document Code ",
        "grid": 6,

    },

    {
        "varName": "nationalIdCardNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "National ID Card Number/Unique ID",
        "grid": 6,
        "validation": "nid",
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "varName": "natioanlIdCardnidVerificationConfirmationIsDocumentVerified1",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "varName": "natioanlIdCardissueDateN",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },
    {
        "type": "title",
        "label": "Smart ID Card",
        "grid": 12,
    },
    {
        "varName": "smartIdCarddocumentTypeS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "smartIdCarddocumentTypeDescriptionS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "smartIdCarddocumentCodeS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "RSNID",
            "value": "RSNID"
        }],
        "label": "Document Code ",
        "grid": 6,
        //"required": true,
    },

    {
        "varName": "smartCardNumberUniqueId",
        "type": "text",
        "label": "Smart Card Number/Unique ID",
        "grid": 6,
        //"required": true,
        "validation": "smartCard",
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"

    },

    {
        "varName": "smartIdCardnidVerificationConfirmationIsDocumentVerified",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },

    {
        "varName": "smartIdCardissueDateS",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },
    {
        "type": "title",
        "label": "Passport Number",
        "grid": 12,
    },
    {
        "varName": "passportNumberdocumentTypeP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "passportNumberdocumentTypeDescriptionP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        //inputData.passportNumberdocumentTypeDescriptionP=inputData.passport
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "passportNumberdocumentCodeP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "PASSPORT",
            "value": "PASSPORT IDENTITY"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "passportNumberpassportNumberUniqueId",
        "type": "textApiCall",
        "label": "Passport Number/Unique ID",
        "grid": 6,
        "validation": "passport",
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberplaceOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Place of Issue",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberissueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumbercountryOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country of Issue",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberexpiryDate",
        "type": "date",
        "label": "Expiry Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },
    {
        "type": "title",
        "label": "Driving License",
        "grid": 12,
    },
    {
        "varName": "drivingLicensedocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "drivingLicensedocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "drivingLicensedocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName":"documentCode",
            "key":"DL",
            "value":"DRIVING LICENSE"
        },],
        "label": "Document Code",
        "grid": 6,

    },

    {
        "varName": "drivingLicenseNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "Driving ID Card Number/Unique ID",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "drivingLicensedocumentCodeN",
        "conditionalVarValue": "DL"
    },


    {
        "type": "title",
        "label": "Resident ID",
        "grid": 12,
    },
    {
        "varName": "residentdocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "residentdocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "residentdocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName":"documentCode",
            "key":"RESID",
            "value":"RESIDENT ID CARD"
        },],
        "label": "Document Code ",
        "grid": 6,

    },

    {
        "varName": "residentNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "Resident ID",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "residentdocumentCodeN",
        "conditionalVarValue": "RESID"
    },
    {
        "type": "title",
        "label": "Birth Certificate",
        "grid": 12,
    },
    {
        "varName": "birthCertificatedocumentTypeB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "birthCertificatedocumentTypeDescriptionB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "birthCertificatedocumentCodeB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "BC",
            "value": "BIRTH CERTIFICATE"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "birthCertificatebirthCertificateNumberUniqueId",
        "type": "text",
        "label": "Birth Certificate Number/Unique ID",
        //inputData.birthCertificatebirthCertificateNumberUniqueId=inputData.birthCertificateNo
        "grid": 6,
        "validation": "birthCertificateNumber",
        "required": true,
        "masterData": true,
        "conditionalVarName": "birthCertificatedocumentCodeB",
        "conditionalVarValue": "BC"
    },
    {
        "type": "title",
        "label": "Chairman Certificate",
        "grid": 12,
    },
    {
        "varName": "chairmanCertificatedocumentTypeCC",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "chairmanCertificatedocumentTypeDescriptionCC",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "chairmanCertificatedocumentCodeCC",
        "type": "autoCompleteValueReturn",
        "enum": [{"masterDataName":"documentCode","key":"CHAC","value":"CHAIRMAN CERTIFICATE"}],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificateUniqueId",
        "type": "text",
        "label": "Chairman Certificate/Unique ID",
        "grid": 6,
        "validationB":"length",
        "maxLength":20,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatedocumentCodeCC",
        "conditionalVarValue": "CHAC"
    },




    {
        "type": "title",
        "label": "Other Photo ID",
        "grid": 12,
    },


    {
        "varName": "photoIddocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "photoIddocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "photoIddocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "photoIdUniqueId",
        "type": "text",
        "label": "Unique ID",
        "grid": 6,
    },
    {
        "type": "empty",
        "grid":12,

    },
    {
        "varName": "chairmanCertificateplaceOfBirthFreeText14",
        "type": "text",
        "label": "Place of Birth/Free Text 14",
        "maxLength":15,
        "validationB":"length",
        "grid": 6,

    },

    {
        "varName": "chairmanCertificatecountryOfBirthFreeText15",
        "type": "text",
        "label": "Country of Birth/Free Text 15",
        "grid": 6,

    },

    {
        "varName": "Customersnationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Nationality",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "chairmanCertificatenonResident",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Non Resident",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "chairmanCertificateturnedNonResidentOn",
        "type": "date",
        "label": "Turned Non Resident On",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },
    {
        "type": "title",
        "label": "E-Tin",
        "grid": 12,
    },
    {
        "varName": "etindocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "etindocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "etindocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "etineTinNumber",
        "type": "text",
        //inputData.etineTinNumber=inputData.eTin
        "label": "E-TIN Number/Unique ID",
        "grid": 6,
        "validation": "etin",
        "required": true,
        "masterData": true,
        "conditionalVarName": "etindocumentCode",
        "conditionalVarValue": "ETIN"
    },
    {
        "type": "title",
        "label": "Employer Data",
        "grid": 12,
    },
    {
        "varName": "employerDataprofessionEmploymentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "employmentType",
            "key": "MEDIA CELEBRITY",
            "value": "MEDIA CELEBRITY"
        }],
        "label": "Profession /Employment Type",
        "grid": 6,
    },



    {
        "varName": "employerDatanameOfEmployerEmployerName",
        "type": "text",
        "label": "Name of Employer/Employer Name",
        "validationB":"length",
        "maxLength": 50,
        "grid": 6,
    },

    {
        "varName": "employerDatamonthlyIncomeGrossIncome",
        "type": "text",
        "label": "Monthly Income/Gross income",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 2",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType2",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH2",
            "value": "COMMPH2"
        }],
        "label": "Contact Type",
        "grid": 6,

    },

    {
        "varName": "contactNumberphoneNoEmailId2",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No.",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2"
    },
    {
        "varName": "mobileTypecontactNumberphoneNo2",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required":true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Overseas",
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo2",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo2",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Overseas",

    },

    {
        "type": "title",
        "label": "Contact Number - Phone 3",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType3",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "WORKPH1",
            "value": "WORKPH1"
        }],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNoEmailId3",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No.",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1"
    },

    /*  {
          "varName": "contactNumberphoneNo3",
          "type": "text",
          "validation": "numeric",
          "label": "Phone No",
          "grid": 6,
          "required": true,
          "masterData": true,
          "conditionalVarName": "contactNumbercontactType3",
          "conditionalVarValue": "WORKPH1"
      },*/

    {
        "varName": "mobileTypecontactNumbercontactType3",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required":true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo3",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Overseas",
        "grid":6,
    },
    {
        "varName": "contactNumberphoneNo3",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo3",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Overseas",

    },




    {
        "type": "title",
        "label": "Contact Number - Email",
        "grid": 12,
    },

    {
        "varName": "contactNumberEmailemailType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "HOMEEML",
            "value": "HOMEEML"
        }],
        "required":true,
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "EMAIL",
            "value": "EMAIL"
        }],
        "label": "Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailId2",
        "type": "text",
        "label": "Email ID 2",
        "validation": "email",
        "grid": 6,
    },

    {
        "type": "title",
        "label": "Mailing Address",
        "grid": 12,
    },
    {
        "varName": "maillingAdresspreferredAddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        //inputData.maillingAdresspreferredAddressType=inputData.SelectCommunicationAddress

        "label": "Preferred Address type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        "label": "Addrss type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressmailingAddressField1",
        "type": "text",
        //inputData.maillingAdressmailingAddressField1=inputData.communicationAddress1
        "label": "Mailing Address Field 1",
        "validationB":"length",
        "maxLength":45,
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressmailingAddressField2",
        "type": "text",
        //inputData.maillingAdressmailingAddressField2=inputData.communicationAddress2
        "label": "Mailing Address Field 2",
        "validationB":"length",
        "maxLength":45,
        "grid": 6,
    },

    {
        "varName": "maillingAdresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdresscity=inputData.city2

        "label": "City",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdressstate=inputData.state2

        "label": "Division(State)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdresspostalCode",
        "type": "text",
        "validationB":"length",
        "maxLength":6,
        "label": "Postal Code",
        //inputData.maillingAdresspostalCode=inputData.postalCode3
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdresscountry=inputData.nationality
        "label": "Country",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Home Address",
        "grid": 12,
    },
    {
        "varName": "homeAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "required":true,
        "label": "Address Format",
        "grid": 6,

    },

    {
        "varName": "homeAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Home",
            "value": "Home"
        }],
        "onKeyDown": true,
        "label": "Address type",
        "grid": 6,

    },

    {
        "varName": "homeAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Home",
            "value": "Home"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,

    },

    {
        "varName": "homeAddresshomeAddressField1",
        "type": "text",
        "label": "Home Address Field 1",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "required":true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresshomeAddressField2",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],

        "label": "City",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresspostalCode",
        "type": "text",
        "validationB":"length",
        "maxLength":6,
        "label": "Postal Code",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },
    {
        "type": "title",
        "label": "Work Address",
        "grid": 12,
    },
    {
        "varName": "workAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "workAddressaddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Work",
            "value": "Work"
        }],
        "label": "Address type",
        "grid": 6,
    },

    {
        "varName": "workAddressaddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Work",
            "value": "Work"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "workAddressworkAddressField1",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "required":true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddressworkAddressField2",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresspostalCode",
        "type": "text",
        "validationB":"length",
        "maxLength":6,
        "label": "Postal Code",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "masterData": true,
        "required":true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },
    //1present address
    {
        "type": "title",
        "label": "Present Address",
        "grid": 12,
    },
    {
        "varName": "presentAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "required":true,
        "grid": 6,
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Future/OnSite",
            "value": "Future/OnSite"
        }],
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [ {
            "masterDataName": "addressType",
            "key": "Future/OnSite",
            "value": "Future/OnSite"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "presentAddresspresentAddressField1",
        "type": "text",
        "label": "Present Address Field 1",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "required":true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"

    },

    {
        "varName": "presentAddresspresentAddressField2",
        "type": "text",
        "label": "Present Address Field 2",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresspostalCode",
        "type": "text",
        "validationB":"length",
        "maxLength":6,
        "label": "Postal Code",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    }, //1NRE address
    {
        "type": "title",
        "label": "NRE Address",
        "grid": 12,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
    },
    {
        "varName": "nreAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "nreAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [ {"masterDataName": "addressType",
            "key": "NRERelative",
            "value": "NRERelative"
        }],
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "nreAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [ {"masterDataName": "addressType",
            "key": "NRERelative",
            "value": "NRERelative"
        }],
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "nreAddressnreAddressField1",
        "type": "text",
        "label": "NRE Address Field 1",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "required":true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"

    },

    {
        "varName": "nreAddressnreAddressField2",
        "type": "text",
        "label": "NRE Address Field 2",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresspostalCode",
        "type": "text",
        "validationB":"length",
        "maxLength":6,
        "label": "Postal Code",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"

    },


];
let CBDataJsonFormForFDRReadOnly =  makeReadOnlyObject(JSON.parse(JSON.stringify(CBDataJsonFormForFDR)));

/*makeGridObjectReadOnlyFalse(JSON.parse(JSON.stringify(CBDataJsonForm)));*/

//Uat
let CSJsonFormForFDRService = [
    /* {
         "varName": "fdValueDate",
         "type": "date",
         "required": true,
         "label": "FD Value Date",
         "grid": 2,
         "multiline": true
     },*/
    {
        "varName": "fdrRequest",
        "type": "checkbox",
        "label": "FDR Request",
        "grid": 12
    },
    {
        "type": "title",
        "label": "FDR Information:",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true
    },
    {
        "varName": "debitAccountStatement",
        "type": "submit",
        "label": "Debit Account Statement",
        "mt": 15,
        "event": false,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true
    },
    {
        "varName": "fdValueDate",
        "type": "date",
        "request":"FDR",
        "dayAdd": 0,
        "grid": 2,
        "maxDate": true,
        "onKeyDown": true,
        "label": "FD Value Date",
        "required": true,
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true
    },


    {
        "varName": "accountsType",
        "type": "select",
        "request":"FDR",
        "grid": 2,
        "required": true,
        "enum": [],
        "onKeyDown": true,
        "label": "Type of FDR Account",
        "conditionalArray": true,
        "conditionalArrayList": ["accountTypeApiCall", "fdrRequest"],
        "conditionalArrayValue": [true, true]


    },
    {
        "varName":"accountAcquisition",
        "type": "select",
        "request":"FDR",
        "required": true,
        "enum": [],
        "onKeyDown": true,
        "label":"Account Acquisition By",
        "grid":2,
        "conditionalArray": true,
        "conditionalArrayList": ["accountAcquisitionApiCall", "fdrRequest"],
        "conditionalArrayValue": [true, true]

    },
    /////Tenure Type ///
    {
        "varName": "tenureType",
        "type": "select",
        "request":"FDR",
        "required": true,
        "grid": 2,
        "enum": [],
        "onKeyDown": true,
        "label": "Tenure Type",
        "conditionalArray": true,
        "conditionalArrayList": ["tenureTypeApiCall", "fdrRequest"],
        "conditionalArrayValue": [true, true]
    },
    {
        "varName": "tenure",
        "type": "select",
        "request":"FDR",
        "enum": [],
        "grid": 2,
        "label": "Tenure",
        "onKeyDown": true,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["tenureApiCall", "fdrRequest"],
        "conditionalArrayValue": [true, true]
    },


    {
        "varName": "autoRenewals",
        "type": "select",
        "request":"FDR",
        "enum": [],
        "label": "Auto Renewal",
        "grid": 2,
        "required": true,
        "onKeyDown": true,
        "conditionalArray": true,
        "conditionalArrayList": ["autoRenewalsApiCall", "fdrRequest"],
        "conditionalArrayValue": [true, true]
    },

    {
        "varName": "maturity",
        "type": "select",
        "request":"FDR",
        "enum": [],
        "required": true,
        "onKeyDown": true,
        "label": "Maturity /Disposal Instruction",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["maturityApiCall", "fdrRequest"],
        "conditionalArrayValue": [true, true]
    },

    // {
    //     "varName": "interestCreditAccount",
    //     "type": "text",
    //     "request":"FDR",
    //     "label": "Interest Credit Account",
    //     "grid": 2,
    //     "required": true,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["maturity", "fdrRequest"],
    //     "conditionalArrayValue": ["Renew Principal Only and Credit Interest to", true]
    // },
    // {
    //     "varName": "repaymentAccount",
    //     "type": "text",
    //     "request":"FDR",
    //     "label": "Repayment Account",
    //     "grid": 2,
    //     "required": true,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["maturity", "fdrRequest"],
    //     "conditionalArrayValue": ["Encashment at Maturity", true]
    // },



    {
        "varName": "interestCreditAccount",
        "type": "textApiCall",
        "label": "Interest Credit Account",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity","fdrRequest"],
        "conditionalArrayValue": ["Renew Principal Only and Credit Interest to",true],

    },
    {
        "varName": "interestCreditName",
        "type": "text",
        "label": "Interest Credit Name",
        "grid": 2,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity","fdrRequest","interestCreditAccountApiCall"],
        "conditionalArrayValue": ["Renew Principal Only and Credit Interest to",true,"YES"],

    },
    {
        "varName": "repaymentAccount",
        "type": "textApiCall",
        "label": "Repayment Account",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity","fdrRequest"],
        "conditionalArrayValue": ["Encashment at Maturity",true],
    },
    {
        "varName": "repaymentName",
        "type": "text",
        "label": "Repayment Name",
        "grid": 2,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity","fdrRequest","repaymentAccountApiCall"],
        "conditionalArrayValue": ["Encashment at Maturity",true,"YES"],
    },


    {
        "varName": "availableAmount",
        "type": "text",
        "readOnly":true,
        "label": "Available Amount",
        "request":"FDR",
        "required": true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "availableAmountShow"],
        "conditionalArrayValue": [true, true],


    },
    {
        "varName": "amount",
        "type": "text",
        "wordFromAmount":true,
        "label": "FD Amount",
        "validation": "numeric",
        "request":"FDR",
        "onKeyDown": true,
        "required": true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrSchemeCodeApiCall", "fdrRequest"],
        "conditionalArrayValue": [true, true]

    },
    {
        "varName": "amountWithWord",
        "type": "text",
        "readOnly":true,
        "label": "FD Amount In Word",
         "request":"FDR",
         "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrSchemeCodeApiCall", "fdrRequest","amountWithWordApiFlag"],
        "conditionalArrayValue": [true, true,true]

    },

    {
        "varName": "fdrSchemeCode",
        "type": "autoCompleteValueReturn",
        "enum":[],
        "request":"FDR",
        "multiline":true,
        "readOnly": true,
        "label": "FD Scheme Code",
        "required": true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrSchemeCodeApiCall", "fdrRequest"],
        "conditionalArrayValue": [true, true]
    },
    {
        "varName": "interestRate",
        "type": "text",
        "request":"FDR",
        "label": "Rate %",
        "readOnly": true,
        "grid": 2,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["interestRateApicall", "fdrRequest"],
        "conditionalArrayValue": ["YES", true]
    },
    {
        "varName": "preventialRateChange",
        "type": "select",
        "enum":[
            "Y",
            "N"
        ],
        "required":true,
        "request":"FDR",
        "label": "Prevential Rate Change",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["interestRateApicall", "fdrRequest"],
        "conditionalArrayValue": ["YES", true]
    },
    {
        "varName": "fdMaturityDate",
        "type": "text",
        "request":"FDR",
        "label": "FD Maturity Date",
        "readOnly": true,
        "grid": 2,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["fdMaturityDateApicall", "fdrRequest"],
        "conditionalArrayValue": ["YES", true]
    },
    {
        "varName": "eTin",
        "type": "text",
        "validation":"etin",
        "etinBlock":true,
        "request":"FDR",
        "readOnly": true,
        "label": "Tin No",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 2,
        "required":true,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "eTinopen"],
        "conditionalArrayValue": [true, true],
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "required":true,
        "varName": "exceptionSubmission",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "eTinopen"],
        "conditionalArrayValue": [true, true],
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission","fdrRequest", "eTinopen"],
        "conditionalArrayValue": ["Y",true, true],
        "grid":2,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "required":true,
        "label": "Tax Submission Year",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission","fdrRequest", "eTinopen"],
        "conditionalArrayValue": ["N",true, true],
        "grid": 2,
    },

    {
        "varName": "taxWaiverChange",
        "type": "select",
        "enum":[
            "Y",
            "N",
        ],
        "request":"FDR",
        "label": "Tax Waiver Change",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true
    },
    {
        "varName": "depositeType",
        "type": "text",
        "request":"FDR",
        "readOnly": true,
        "label": "Deposit Type",
        "grid": 2,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["depositeTypeApicall", "fdrRequest"],
        "conditionalArrayValue": [true, true]
    },

    /* {
         "varName": "occupationCode",
         "type": "select",
         "enum": [],
         "request":"FDR",
         "label": "Occupation Code",
         "grid": 2,
         "conditional": true,
         "conditionalVarName": "fdrRequest",
         "conditionalVarValue": true
     },
     {
         "varName": "sectorCode",
         "type": "autoCompleteValueReturn",
         "onKeyDown": true,
         "enum": [],
         "request":"FDR",
         "label": "Sector Code",
         "grid": 2,
         required: true,
         "conditional": true,
         "conditionalVarName": "fdrRequest",
         "conditionalVarValue": true
     },

     {
         "varName": "subSectorCode",
         "type": "autoCompleteValueReturn",
         "enum": [],
         "request":"FDR",
         "label": "Sub Sector Code",
         "grid": 2,
         required: true,
              "conditionalArray": true,
         "conditionalArrayList": ["sectorCodeApicall", "fdrRequest"],
         "conditionalArrayValue": [true, true]
     },

     {
         "varName": "rmCode",
         "type": "textApiCall",
         "required": true,
         "request":"FDR",
         "label": "RM Code",
         "grid": 2,
         "conditional": true,
         "conditionalVarName": "fdrRequest",
         "conditionalVarValue": true
     },
     {
         "varName": "rmCodeName",
         "type": "text",
         "request":"FDR",
         "readOnly": true,
         "label": "RM Code Name",
         "grid": 2,
         "conditionalArray": true,
         "conditionalArrayList": ["rmCodeApicall", "fdrRequest"],
         "conditionalArrayValue": ["YES", true]
     },
 */


    {
        "varName": "occupationCode",
        "type": "text",
        "readOnly": true,
        "request":"FDR",
        "required": true,
        "label": "Occupation Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "readOnly": true,
        "enum": [],
        "request":"FDR",
        "label": "Sector Code",
        "grid": 2,
        required: true,
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Sector Code",
        "grid": 2,
        "request":"FDR",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["sectorCodeApicall", "fdrRequest"],
        "conditionalArrayValue": [true, true]
    },
    {
        "varName": "rmCodeFdr",
        "type": "textApiCall",
        "required": true,
        "label": "RM Code",
        "grid": 2,
        "onKeyDown":true,
        "request":"FDR",
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "varName": "rmCodeFdrName",
        "type": "text",
        "readOnly": true,
        "label": "RM Code Name",
        "grid": 2,
        "request":"FDR",
        "conditionalArray": true,
        "conditionalArrayList": ["rmCodeFdrApiCall", "fdrRequest"],
        "conditionalArrayValue": [true, true]

    }
    ,
    {
        "varName": "freeCode1SpecialTaxagentOutlet",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Agent Banking Outlet",
        "grid": 2,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "agentBankingFlag"],
        "conditionalArrayValue": [true,true]
    },
    {
        "varName": "nomineeType",
        "type": "select",
        "request":"FDR",
        "required": true,
        "enum": [
            "Nominee Same As Operative Account",
            "New Nominee Assignment",
        ],
        "label": "Nominee Type",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["nomineeInformationVisible", "fdrRequest"],
        "conditionalArrayValue": ["YES", true]
    },

    {
        "varName": "nomineeExistNot",
        "type": "select",
        "enum":[
            "Y",
            "N",
        ],
        "request":"FDR",
        "readOnly":true,
        "label": "Nominee Exist/Not",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["nomineeType", "fdrRequest"],
        "conditionalArrayValue": ["Nominee Same As Operative Account", true]

    },
];

//Live Server
/*let CSJsonFormForFDRService = [
    /!* {
         "varName": "fdValueDate",
         "type": "date",
         "required": true,
         "label": "FD Value Date",
         "grid": 2,
         "multiline": true
     },*!/
  /!*  {
        "varName": "fdrRequest",
        "type": "checkbox",
        "label": "FDR Request",
        "grid": 12
    },*!/
    {
        "type": "title",
        "label": "FDR Information:",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true
    },
    {
        "varName": "fdValueDate",
        "type": "date",
        "request":"FDR",
        "dayAdd": 0,
        "grid": 2,
        "onKeyDown": true,
        "label": "FD Value Date",
        "required": true,
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true
    },


    {
        "varName": "accountsType",
        "type": "select",
        "request":"FDR",
        "grid": 2,
        "required": true,
        "enum": [],
        "onKeyDown": true,
        "label": "Type of FDR Account",
        "conditionalArray": true,
        "conditionalArrayList": ["accountTypeApiCall", "fdrRequest"],
        "conditionalArrayValue": [true, true]


    },
    {
        "varName":"accountAcquisition",
        "type": "select",
        "request":"FDR",
        "required": true,
        "enum": [],
        "onKeyDown": true,
        "label":"Account Acquisition By",
        "grid":2,
        "conditionalArray": true,
        "conditionalArrayList": ["accountAcquisitionApiCall", "fdrRequest"],
        "conditionalArrayValue": [true, true]

    },
    /////Tenure Type ///
    {
        "varName": "tenureType",
        "type": "select",
        "request":"FDR",
        "required": true,
        "grid": 2,
        "enum": [],
        "onKeyDown": true,
        "label": "Tenure Type",
        "conditionalArray": true,
        "conditionalArrayList": ["tenureTypeApiCall", "fdrRequest"],
        "conditionalArrayValue": [true, true]
    },
    {
        "varName": "tenure",
        "type": "select",
        "request":"FDR",
        "enum": [],
        "grid": 2,
        "label": "Tenure",
        "onKeyDown": true,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["tenureApiCall", "fdrRequest"],
        "conditionalArrayValue": [true, true]
    },


    {
        "varName": "autoRenewals",
        "type": "select",
        "request":"FDR",
        "enum": [],
        "label": "Auto Renewal",
        "grid": 2,
        "required": true,
        "onKeyDown": true,
        "conditionalArray": true,
        "conditionalArrayList": ["autoRenewalsApiCall", "fdrRequest"],
        "conditionalArrayValue": [true, true]
    },

    {
        "varName": "maturity",
        "type": "select",
        "request":"FDR",
        "enum": [],
        "required": true,
        "onKeyDown": true,
        "label": "Maturity /Disposal Instruction",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["maturityApiCall", "fdrRequest"],
        "conditionalArrayValue": [true, true]
    },

    {
        "varName": "interestCreditAccount",
        "type": "text",
        "request":"FDR",
        "label": "Interest Credit Account",
        "grid": 2,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity", "fdrRequest"],
        "conditionalArrayValue": ["Renew Principal Only and Credit Interest to", true]
    },
    {
        "varName": "repaymentAccount",
        "type": "text",
        "request":"FDR",
        "label": "Repayment Account",
        "grid": 2,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity", "fdrRequest"],
        "conditionalArrayValue": ["Encashment at Maturity", true]
    },
    {
        "varName": "availableAmount",
        "type": "text",
        "readOnly":true,
        "label": "Available Amount",
        "request":"FDR",
        "required": true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "availableAmountShow"],
        "conditionalArrayValue": [true, true],

    },
    {
        "varName": "amount",
        "type": "text",
        "label": "FD Amount",
        "request":"FDR",
        "onKeyDown": true,
        "required": true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrSchemeCodeApiCall", "fdrRequest"],
        "conditionalArrayValue": [true, true]

    },

    {
        "varName": "fdrSchemeCode",
        "type": "autoCompleteValueReturn",
        "enum":[],
        "request":"FDR",
        "multiline":true,
        "readOnly": true,
        "label": "FD Scheme Code",
        "required": true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrSchemeCodeApiCall", "fdrRequest"],
        "conditionalArrayValue": [true, true]
    },
    {
        "varName": "interestRate",
        "type": "text",
        "request":"FDR",
        "label": "Rate %",
        "readOnly": true,
        "grid": 2,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["interestRateApicall", "fdrRequest"],
        "conditionalArrayValue": ["YES", true]
    },
    {
        "varName": "preventialRateChange",
        "type": "select",
        "enum":[
            "Y",
            "N"
        ],
        "request":"FDR",
        "label": "Prevential Rate Change",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["interestRateApicall", "fdrRequest"],
        "conditionalArrayValue": ["YES", true]
    },
    {
        "varName": "fdMaturityDate",
        "type": "text",
        "request":"FDR",
        "label": "FD Maturity Date",
        "readOnly": true,
        "grid": 2,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["fdMaturityDateApicall", "fdrRequest"],
        "conditionalArrayValue": ["YES", true]
    },
    {
        "varName": "eTin",
        "type": "text",
        "validation":"etin",
        "request":"DPS",
        "readOnly": true,
        "label": "Tin No",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true

    }
    ,

    {
        "varName": "taxWaiverChange",
        "type": "select",
        "enum":[
            "Y",
            "N",
        ],
        "request":"FDR",
        "label": "Tax Waiver Change",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true
    },
    {
        "varName": "depositeType",
        "type": "text",
        "request":"FDR",
        "readOnly": true,
        "label": "Deposit Type",
        "grid": 2,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["depositeTypeApicall", "fdrRequest"],
        "conditionalArrayValue": [true, true]
    },

    /!* {
         "varName": "occupationCode",
         "type": "select",
         "enum": [],
         "request":"FDR",
         "label": "Occupation Code",
         "grid": 2,
         "conditional": true,
         "conditionalVarName": "fdrRequest",
         "conditionalVarValue": true
     },
     {
         "varName": "sectorCode",
         "type": "autoCompleteValueReturn",
         "onKeyDown": true,
         "enum": [],
         "request":"FDR",
         "label": "Sector Code",
         "grid": 2,
         required: true,
         "conditional": true,
         "conditionalVarName": "fdrRequest",
         "conditionalVarValue": true
     },

     {
         "varName": "subSectorCode",
         "type": "autoCompleteValueReturn",
         "enum": [],
         "request":"FDR",
         "label": "Sub Sector Code",
         "grid": 2,
         required: true,
              "conditionalArray": true,
         "conditionalArrayList": ["sectorCodeApicall", "fdrRequest"],
         "conditionalArrayValue": [true, true]
     },

     {
         "varName": "rmCode",
         "type": "textApiCall",
         "required": true,
         "request":"FDR",
         "label": "RM Code",
         "grid": 2,
         "conditional": true,
         "conditionalVarName": "fdrRequest",
         "conditionalVarValue": true
     },
     {
         "varName": "rmCodeName",
         "type": "text",
         "request":"FDR",
         "readOnly": true,
         "label": "RM Code Name",
         "grid": 2,
         "conditionalArray": true,
         "conditionalArrayList": ["rmCodeApicall", "fdrRequest"],
         "conditionalArrayValue": ["YES", true]
     },
 *!/


    {
        "varName": "occupationCode",
        "type": "text",
        "readOnly": true,
        "request":"DPS",
        "required": true,
        "label": "Occupation Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "readOnly": true,
        "enum": [],
        "request":"DPS",
        "label": "Sector Code",
        "grid": 2,
        required: true,
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Sector Code",
        "grid": 2,
        "request":"DPS",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["sectorCodeApicall", "fdrRequest"],
        "conditionalArrayValue": [true, true]
    },
  {
        "varName": "rmCode",
        "type": "text",
        "required": true,
        "label": "RM Code",
        "grid": 2,
        "onKeyDown":true,
        "request":"DPS",
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "varName": "rmCodeName",
        "type": "text",
        "readOnly": true,
        "label": "RM Code Name",
        "grid": 2,
        "request":"DPS",
        "conditionalArray": true,
        "conditionalArrayList": ["rmCodeApiCall", "fdrRequest"],
        "conditionalArrayValue": [true, true]

    }
    ,
    {
        "varName": "nomineeType",
        "type": "select",
        "request":"FDR",
        "required": true,
        "enum": [
            "Nominee Same As Operative Account",
            "New Nominee Assignment",
        ],
        "label": "Nominee Type",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["nomineeInformationVisible", "fdrRequest"],
        "conditionalArrayValue": ["YES", true]
    },

    {
        "varName": "nomineeExistNot",
        "type": "select",
        "enum":[
            "Y",
            "N",
        ],
        "request":"FDR",
        "readOnly":true,
        "label": "Nominee Exist/Not",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["nomineeType", "fdrRequest"],
        "conditionalArrayValue": ["Nominee Same As Operative Account", true]

    },
];*/

let CBDataJsonFormReadOnly = makeReadOnlyObject(JSON.parse(JSON.stringify(CBDataJsonForm)));

let CSJsonFormForCasaServiceDPS =[
    {
        "varName": "priority",
        "type": "select",
        "label": "Priority",
        "enum": [
            "GENERAL",
            "HIGH",
        ],
        "grid": 2,
    },
    {
        "varName": "statementFacility",
        "type": "select",
        "enum": [
            "Printed Statement",
            "E-Statement",
            "NO"
        ],
        "grid": 2,
        "label": "Statement *",

    },
    /*{
        "varName": "statementFacilityEmailAddress",
        "type": "text",
        "validation": "email",
        "label": "Email Address *",
    },






    /*   {
           "varName": "lockerFacility",
           "type": "checkbox",
           "label": "Locker Facility",


       },*/
    {
        "varName": "loanRequest",
        "type": "checkbox",
        "label": "Loan Request",

    },
    {
        "varName": "cityTouchRequest",
        "type": "checkbox",
        "label": "City Touch",

    },
    //tem
    {
        "varName": "dpsRequest",
        "type": "checkbox",
        "label": "DPS Request",

        "grid": 12,

    },

    {
        "type": "title",
        "grid": 12,
        "request":"DPS",
        "label": "DPS Information",
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true
    },
    {
        "varName": "debitAccountStatement",
        "type": "submit",
        "label": "Debit Account Statement",
        "mt": 15,
        "event": false,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true
    },
    {
        "varName": "dpsfdValueDate",
        "type": "date",
        "dayAdd": 0,
        "request":"DPS",
        "required": true,
        "grid": 2,
        "maxDate": true,
        "onKeyDown": true,
        "label": "DPS Value Date",
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,

    {
        "varName": "dpsnameOfScheme",
        "type": "select",
        "grid": 2,
        "enum": [],
        "request":"DPS",
        "required": true,
        "onKeyDown":true,
        "label": "Name Of Scheme",
        "conditionalArray": true,
        "conditionalArrayList": ["dpsnameOfSchemeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    },
    {
        "varName":"dpsaccountAcquisition",
        "type":"select",
        "enum":[],
        "onKeyDown":true,
        "required": true,
        "request":"DPS",
        "label":"Account Acquisition By",
        "grid":2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsaccountAcquisitionApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]


    },
    {
        "varName": "dpstenureType",
        "type": "select",
        "grid": 2,
        "enum": [],
        "required": true,
        "request":"DPS",
        "onKeyDown":true,
        "label": "Scheme Type",
        "conditionalArray": true,
        "conditionalArrayList": ["dpstenureTypeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    },
    {
        "varName": "dpstenure",
        "type": "select",
        "enum": [],
        "grid": 2,
        "required": true,
        "request":"DPS",
        "label": "Period Of Scheme",
        "onKeyDown":true,
        "conditionalArray": true,
        "conditionalArrayList": ["dpstenureApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]
    },
    {
        "varName": "repaymentAccounts",
        "type": "textApiCall",
        "label": "Repayment Account",
        "required":true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsSchemeCodeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]
    },
    {
        "varName": "repaymentsName",
        "type": "text",
        "label": "Repayment Name",
        "grid": 2,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsdpsSchemeCodeApiCall","repaymentsAccountApiCall", "dpsRequest"],
        "conditionalArrayValue": [true,"YES", true],
    },
    {
        "varName": "dpsdpsSchemeCode",
        "type": "autoCompleteValueReturn",
        "enum":[],
        "required": true,
        "request":"DPS",
        "label": "DPS Scheme Code",
        "multiline":true,
        "readOnly":true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsdpsSchemeCodeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    }
    ,
    {
        "varName": "availableAmount",
        "type": "text",
        "label": "Available Amount",
        "request":"DPS",
        "readOnly":true,
        "required": true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsRequest", "availableAmountShow"],
        "conditionalArrayValue": [true, true],


    },
    {
        "varName": "dpsamount",
        "type": "text",
        "wordFromAmount":true,
        "validation":"numeric",
        "required": true,
        "request":"DPS",
        "onKeyDown": true,
        "label": "Amount Per Installment",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsdpsSchemeCodeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]


    }
    ,
    {
        "varName": "dpsamountWithWord",
        "type": "text",
        "readOnly":true,
        "request":"DPS",
         "label": "Amount In Word Per Installment",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsdpsSchemeCodeApiCall", "dpsRequest","dpsamountWithWordApiFlag"],
        "conditionalArrayValue": [true, true,true]


    }
    ,
    {
        "varName": "nextExecutionDate",
        "type": "date",
        "required": true,
        "request":"DPS",
        "label": "Installment Start Date",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true


    }
    ,
    {
        "varName": "dpspayableAtMaturity",
        "type": "text",
        "readOnly": true,
        "request":"DPS",
        "label": "Payable At Maturity",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "varName": "dpsfdMaturityDate",
        "type": "text",
        "label": "Maturity Date",
        "readOnly": true,
        "request":"DPS",
        "required": true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsfdMaturityDateApicall", "dpsRequest"],
        "conditionalArrayValue": ["YES", true]
    },
    {
        "varName": "dpsinterestRate",
        "type": "text",
        "label": "Rate %",
        "readOnly": true,
        "request":"DPS",
        "grid": 2,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsinterestRateApicall", "dpsRequest"],
        "conditionalArrayValue": ["YES", true]
    },


    {
        "varName": "eTin",
        "type": "text",
        "validation":"etin",
        "request":"DPS",
        "readOnly": true,
        "label": "Tin No",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "varName": "taxWaiverChanges",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "required":true,
        "label": "Tax Waiver Applicable",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true
    },
    {
        "varName": "dpsdepositType",
        "type": "text",
        "readOnly": true,
        "label": "Deposit Type",
        "request":"DPS",
        "grid": 2,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["depositTypeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    }
    ,
    {
        "varName": "occupationCode",
        "type": "text",
        "required": true,
        "readOnly": true,
        "request":"DPS",
        "label": "Occupation Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "readOnly": true,
        "enum": [],
        "request":"DPS",
        "label": "Sector Code",
        "grid": 2,
        required: true,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Sector Code",
        "grid": 2,
        "request":"DPS",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["sectorCodeApicall", "dpsRequest"],
        "conditionalArrayValue": [true, true]
    },
    {
        "varName": "rmCodeDps",
        "type": "textApiCall",
        "required": true,
        "onKeyDown":true,
        "label": "RM Code",
        "grid": 2,
        "request":"DPS",
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "varName": "rmCodeDpsName",
        "type": "text",
        "readOnly": true,
        "label": "RM Code Name",
        "grid": 2,
        "request":"DPS",
        "conditionalArray": true,
        "conditionalArrayList": ["rmCodeDpsApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    }
    ,

    {
        "varName": "dpsfreeCode1SpecialTaxagentOutlet",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Agent Banking Outlet",
        "grid": 2,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsRequest", "agentBankingFlag"],
        "conditionalArrayValue": [true,true]
    },
    {
        "varName": "nomineeTypes",
        "type": "select",
        "required": true,
        "enum": [
            "Nominee Same As Operative Account",
            "New Nominee Assignment",
        ],
        "request":"DPS",
        "label": "Nominee Type",
        "grid": 2,

        "conditionalArray": true,
        "conditionalArrayList": ["nomineeInformationVisible", "dpsRequest"],
        "conditionalArrayValue": ["YES", true]
    },

    {
        "varName": "nomineeExistNots",
        "type": "select",
        "enum":[
            "Y",
            "N",
        ],
        "request":"DPS",
        "readOnly":true,
        "label": "Nominee Exist/Not",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["nomineeTypes", "dpsRequest"],
        "conditionalArrayValue": ["Nominee Same As Operative Account", true]

    },

    /* {
         "varName": "cityTouchEmailAddress",
         "type": "text",
         "validation":"email",
         "label": "Email Address *",
         "conditional": true,
         "conditionalVarName": "cityTouchRequest",
         "conditionalVarValue": true,

     },*/

];

//uAT
let CSJsonFormForCasaService =[
    {
        "varName": "priority",
        "type": "select",
        "label": "Priority",
        "enum": [
            "GENERAL",
            "HIGH",
        ],
        "grid": 2,
    },
    {
        "varName": "statementFacility",
        "type": "select",
        "enum": [
            "Printed Statement",
            "E-Statement",
            "NO"
        ],
        "grid": 2,
        "label": "Statement *",

    },
  /*  {
        "varName": "statementFacilityEmailAddress",
        "type": "text",
        "validation": "email",
        "label": "Email Address *",
    },*/






    /*   {
           "varName": "lockerFacility",
           "type": "checkbox",
           "label": "Locker Facility",


       },*/
    {
        "varName": "loanRequest",
        "type": "checkbox",
        "label": "Loan Request",

    },
    {
        "varName": "cityTouchRequest",
        "type": "checkbox",
        "label": "City Touch",

    },
    //tem
    {
        "varName": "dpsRequest",
        "type": "checkbox",
        "label": "DPS Request",

        "grid": 12,

    },

    {
        "type": "title",
        "grid": 12,
        "request":"DPS",
        "label": "DPS Information",
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true
    },
    {
        "varName": "debitAccountStatement",
        "type": "submit",
        "label": "Debit Account Statement",
        "mt": 15,
        "event": false,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true
    },
    {
        "varName": "dpsfdValueDate",
        "type": "date",
        "dayAdd": 0,
        "request":"DPS",
        "required": true,
        "maxDate": true,
        "grid": 2,
        "onKeyDown": true,
        "label": "DPS Value Date",
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,

    {
        "varName": "dpsnameOfScheme",
        "type": "select",
        "grid": 2,
        "enum": [],
        "request":"DPS",
        "required": true,
        "onKeyDown":true,
        "label": "Name Of Scheme",
        "conditionalArray": true,
        "conditionalArrayList": ["dpsnameOfSchemeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    },
    {
        "varName":"dpsaccountAcquisition",
        "type":"select",
        "enum":[],
        "onKeyDown":true,
        "required": true,
        "request":"DPS",
        "label":"Account Acquisition By",
        "grid":2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsaccountAcquisitionApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]


    },
    {
        "varName": "dpstenureType",
        "type": "select",
        "grid": 2,
        "enum": [],
        "required": true,
        "request":"DPS",
        "onKeyDown":true,
        "label": "Scheme Type",
        "conditionalArray": true,
        "conditionalArrayList": ["dpstenureTypeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    },
    {
        "varName": "dpstenure",
        "type": "select",
        "enum": [],
        "grid": 2,
        "required": true,
        "request":"DPS",
        "label": "Period Of Scheme",
        "onKeyDown":true,
        "conditionalArray": true,
        "conditionalArrayList": ["dpstenureApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]
    },
    {
        "varName": "repaymentAccounts",
        "type": "textApiCall",
        "label": "Repayment Account",
        "required":true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsSchemeCodeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]
    },
    {
        "varName": "repaymentsName",
        "type": "text",
        "label": "Repayment Name",
        "grid": 2,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsdpsSchemeCodeApiCall","repaymentsAccountApiCall", "dpsRequest"],
        "conditionalArrayValue": [true,"YES", true],
    },
    {
        "varName": "dpsdpsSchemeCode",
        "type": "autoCompleteValueReturn",
        "enum":[],
        "required": true,
        "request":"DPS",
        "label": "DPS Scheme Code",
        "multiline":true,
        "readOnly":true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsdpsSchemeCodeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    }
    ,
    {
        "varName": "availableAmount",
        "type": "text",
        "label": "Available Amount",
        "request":"DPS",
        "readOnly":true,
        "required": true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsRequest", "availableAmountShow"],
        "conditionalArrayValue": [true, true],


    },
    {
        "varName": "dpsamount",
        "type": "text",
        "wordFromAmount":true,
        "validation":"numeric",
        "required": true,
        "request":"DPS",
        "onKeyDown": true,
        "label": "Amount Per Installment",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsdpsSchemeCodeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]


    }
    ,
    {
        "varName": "dpsamountWithWord",
        "type": "text",
        "readOnly":true,
        "request":"DPS",
        "label": "Amount In Word Per Installment",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsdpsSchemeCodeApiCall", "dpsRequest","dpsamountWithWordApiFlag"],
        "conditionalArrayValue": [true, true,true]


    },
    {
        "varName": "nextExecutionDate",
        "type": "date",
        "required": true,
        "request":"DPS",
        "label": "Installment Start Date",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true


    }
    ,
    {
        "varName": "dpspayableAtMaturity",
        "type": "text",
        "readOnly": true,
        "request":"DPS",
        "label": "Payable At Maturity",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "varName": "dpsfdMaturityDate",
        "type": "text",
        "label": "Maturity Date",
        "readOnly": true,
        "request":"DPS",
        "required": true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsfdMaturityDateApicall", "dpsRequest"],
        "conditionalArrayValue": ["YES", true]
    },
    {
        "varName": "dpsinterestRate",
        "type": "text",
        "label": "Rate %",
        "readOnly": true,
        "request":"DPS",
        "grid": 2,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsinterestRateApicall", "dpsRequest"],
        "conditionalArrayValue": ["YES", true]
    },


    {
        "varName": "eTin",
        "type": "text",
        "etinBlock":true,
        "validation":"etin",
        "request":"DPS",
        "readOnly": true,
        "label": "Tin No",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 2,
        "required":true,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsRequest", "eTinopen"],
        "conditionalArrayValue": [true, true],
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid": 2,
        "required":true,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsRequest", "eTinopen"],
        "conditionalArrayValue": [true, true],
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission","dpsRequest", "eTinopen"],
        "conditionalArrayValue": ["Y",true, true],
        "grid":2,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "label": "Tax Submission Year",
        "required":true,
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission","dpsRequest", "eTinopen"],
        "conditionalArrayValue": ["N",true, true],
        "grid": 2,
    },

    {
        "varName": "taxWaiverChanges",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "required":true,
        "label": "Tax Waiver Applicable",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true
    },
    {
        "varName": "dpsdepositType",
        "type": "text",
        "readOnly": true,
        "label": "Deposit Type",
        "request":"DPS",
        "grid": 2,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["depositTypeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    }
    ,
    {
        "varName": "occupationCode",
        "type": "text",
        "required": true,
        "readOnly": true,
        "request":"DPS",
        "label": "Occupation Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "readOnly": true,
        "enum": [],
        "request":"DPS",
        "label": "Sector Code",
        "grid": 2,
        required: true,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Sector Code",
        "grid": 2,
        "request":"DPS",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["sectorCodeApicall", "dpsRequest"],
        "conditionalArrayValue": [true, true]
    },
    {
        "varName": "rmCodeDps",
        "type": "textApiCall",
        "required": true,
        "onKeyDown":true,
        "label": "RM Code",
        "grid": 2,
        "request":"DPS",
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "varName": "rmCodeDpsName",
        "type": "text",
        "readOnly": true,
        "label": "RM Code Name",
        "grid": 2,
        "request":"DPS",
        "conditionalArray": true,
        "conditionalArrayList": ["rmCodeDpsApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    }
    ,
    {
        "varName": "dpsfreeCode1SpecialTaxagentOutlet",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Agent Banking Outlet",
        "grid": 2,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsRequest", "agentBankingFlag"],
        "conditionalArrayValue": [true,true]
    },
    {
        "varName": "nomineeTypes",
        "type": "select",
        "required": true,
        "enum": [
            "Nominee Same As Operative Account",
            "New Nominee Assignment",
        ],
        "request":"DPS",
        "label": "Nominee Type",
        "grid": 2,

        "conditionalArray": true,
        "conditionalArrayList": ["nomineeInformationVisible", "dpsRequest"],
        "conditionalArrayValue": ["YES", true]
    },

    {
        "varName": "nomineeExistNots",
        "type": "select",
        "enum":[
            "Y",
            "N",
        ],
        "request":"DPS",
        "readOnly":true,
        "label": "Nominee Exist/Not",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["nomineeTypes", "dpsRequest"],
        "conditionalArrayValue": ["Nominee Same As Operative Account", true]

    },

    /* {
         "varName": "cityTouchEmailAddress",
         "type": "text",
         "validation":"email",
         "label": "Email Address *",
         "conditional": true,
         "conditionalVarName": "cityTouchRequest",
         "conditionalVarValue": true,

     },*/

].concat(CSJsonFormForFDRService);
let CSJsonFormForCasaServiceLast = [
    {
        "varName": "chequeBookRequest",
        "type": "checkbox",
        "onkeyDown":true,
        "label": "Cheque Book Request",
        "grid": 12,

    },
    /* {
        varName: "accountStatus",
        type: "text",
        label: "Account Status",
        //  readOnly: true,
        grid: 2,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
    },
    {
        varName: "accountType",
        type: "text",
        label: "Account Type",
        //required: true,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
        //  readOnly: true,
        grid: 2,
    },*/
    /*{
        varName: "mobileNumber",
        type: "text",
        label: "Mobile Number",
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
        //required: true,
        //  readOnly: true,
        grid: 2,
    },*/
    /*  {
          varName: "numberOfChequeBook",
          type: "select",
          label: "No. of Cheque Book",
          //required: true,
          grid: 2,
          enum: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": true,
      },
      {
          varName: "pageofChequebook",
          type: "select",
          label: "Page of Chequebook",
          //required: true,
          grid: 2,
          enum: ["10", "25"],
          "conditionalArray": true,
          "conditionalArrayList": ["accountType", "accountType","accountType","chequeBookRequest"],
          "conditionalArrayValue": ["Savings","Savings(Alo)", "Savings(Ababil Alo)",true],

      },
      {
          varName: "pageofChequebook",
          type: "select",
          label: "Page of Chequebook",
          //required: true,
          grid: 2,
          "conditionalArray": true,
          "conditionalArrayList": ["accountType","chequeBookRequest"],
          "conditionalArrayValue": ["Current",true],
          enum: ["25", "50", "100"]
      },
      {
         varName: "deliveryType",
          type: "select",
          enum:[
            "Branch"
          ],
          label: "Delivery Type",
          //required: true,
          //  readOnly: true,
          grid: 2,
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": true,
      },

      {
          varName: "branchName",
          type: "autoComplete",
          label: "Branch Name",
          //required: true,
          "conditionalArray": true,
          "conditionalArrayList": ["deliveryType","chequeBookRequest"],
          "conditionalArrayValue": ["Branch",true],
          enum: ["25", "50", "100"],
          grid: 2,
      },
      {
          varName: "customerAddress",
          type: "text",
          label: "Customer Address",
          //required: true,
          "conditionalArray": true,
          "conditionalArrayList": ["deliveryType","chequeBookRequest"],
          "conditionalArrayValue": ["Courier",true],
          grid: 2,
      },
      {
          varName: "customerType",
          type: "select",
          label: "Customer Type",
          enum: ["General", "Priority", "Sapphire"],
          grid: 2,
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": true,
      },
      {
          varName: "checqueBookDesign",
          type: "select",
          label: "Chequebook Design",
          grid: 2,
          enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5"],
          "conditionalArray": true,
          "conditionalArrayList": ["customerType","chequeBookRequest"],
          "conditionalArrayValue": ["Priority",true],
      },
      {
          varName: "checqueBookDesign",
          type: "select",
          label: "Chequebook Design",
          grid: 2,
          enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5", "Design Code 6"],
          "conditionalArray": true,
          "conditionalArrayList": ["customerType","chequeBookRequest"],
          "conditionalArrayValue": ["Sapphire",true],

      },
      {
          varName: "chequeBookType",
          type: "select",
          label: "Cheque Book Type",
          enum: ["Normal", "A4"],
          grid: 2,
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": true,
      },
      {
          varName: "requisitionBranch",
          type: "autoCompleteValueReturn",
          enum:[],
          label: "Requisition branch",
          grid: 2,
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": true,
      },*/
    //cheque book
    {
        varName: "numberOfChequeBook",
        type: "select",
        label: "No. of Cheque Book *",
        required: true,
        // asterisk: true,
        grid: 2,
        errorMessage: "Error",
        enum: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,

    },
    {
        varName: "pageofChequebook",
        type: "select",
        label: "Page of Chequebook *",
        required: true,
        // asterisk: true,
        grid: 2,
        errorMessage: "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "accType"],
        "conditionalArrayValue": [true, "SAVINGS"],
        enum: ["10", "25"]
    },
    {
        varName: "pageofChequebook",
        type: "select",
        label: "Page of Chequebook *",
        required: true,
        // asterisk: true,
        grid: 2,
        errorMessage: "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "accType"],
        "conditionalArrayValue": [true, "CURRENT"],
        enum: ["25", "50", "100"]
    },

    {
        varName: "chequeBookDeliveryType",
        type: "select",
        label: "Delivery Type *",
        required: true,
        // asterisk: true,
        grid: 2,
        errorMessage: "Error",
        enum: ["Branch",],
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
    },

    {
        varName: "checkbookBranchName",
        "type": "autoCompleteValueReturn",
        "enum": [],
        label: "Branch Name *",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "chequeBookDeliveryType"],
        "conditionalArrayValue": [true, "Branch"],
        grid: 2,
        errorMessage: "Error",
        // enum: ["Gulshan", "Banani", "Dhanmondi"]
    },
    {
        varName: "chequeBookType",
        type: "select",
        label: "Cheque Book Type *",
        enum: ["Normal", "A4"],
        grid: 2,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
    },
    /*{
        varName: "requisitionBranch",
        type: "autoCompleteValueReturn",
        enum:[],
        label: "Requisition branch",
        grid: 2,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
    },*/
    /* {
         varName: "customerAddress",
         type: "text",
         label: "Customer Address",
         required: true,
         // asterisk: true,
         "conditionalArray": true,
         "conditionalArrayList": ["chequeBookRequest", "deliveryType"],
         "conditionalArrayValue": [true, ],
         grid: 2,
         errorMessage: "Error"
     },*/
    {
        varName: "customerType",
        type: "text",
        "readOnly":true,
        label: "Customer Type *",
        grid: 2,
        errorMessage: "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "customerTypeFlag"],
        "conditionalArrayValue": [true, true],

    },
    {
        varName: "checqueBookDesign",
        type: "select",
        label: "Chequebook Design *",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "customerType"],
        "conditionalArrayValue": [true, "Priority"],

        grid: 2,
        enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5"],
        errorMessage: "Error"
    },
    {
        varName: "checqueBookDesign",
        type: "select",
        label: "Chequebook Design *",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "customerType"],
        "conditionalArrayValue": [true, "Sapphire"],
        grid: 2,
        enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5", "Design Code 6"],
        errorMessage: "Error"
    },
//Debit Card
  /*  {
        "varName": "debitCard",
        "type": "checkbox",
        "onKeyDown":true,
        "label": "Debit Card",
        "grid": 12
    },
    {
        varName: "cardType",
        type: "select",
        label: "Card Type",
        onKeyDown: true,
        required: true,
        grid: 2,
        errorMessage: "Error",
        enum: [],
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,
    },


    {
        varName: "productType",
        type: "select",
        label: "Product Type",
        required: true,
        grid: 2,
        errorMessage: "Error",
        enum: [],
        "conditionalArray": true,
        "conditionalArrayList": ["debitCard", "showProductType"],
        "conditionalArrayValue": [true, true],
    },
    {
        varName: "nameOnCard",
        type: "text",
        label: "Name on Card",
        required: true,
        grid: 2,
        "validationB":"length",
        "maxLength":19,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,
    },

    {
        varName: "debitCardDeliveryTypes",
        type: "select",
        label: "Delivery Branch Name",
        required: true,
        grid: 2,
        errorMessage: "Error",
        enum: ["Branch",],
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,
    },

    {
        varName: "debitCardBranchName",
        "type": "autoCompleteValueReturn",
        "enum": [],
        label: "Branch Name",
        grid: 2,
        errorMessage: "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["debitCard", "debitCardDeliveryTypes"],
        "conditionalArrayValue": [true, "Branch"],
    },

   */
    /*{
        varName: "customerAddress",
        type: "text",
        label: "Customer Address",
        multiline: true,
        required: true,
        grid: 2,
        errorMessage: "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["debitCard", "deliveryType"],
        "conditionalArrayValue": [true, ],
    },*/
    {
        "varName": "instaDebitCard",
        "type": "checkbox",
        "label": "Insta Debit Card",
        "grid": 12
    },
    {
        "varName": "instaDebitCardName",
        "type": "text",
        "label": "Name On Card *",
        "grid": 2,
        "validationB":"length",
        "maxLength":19,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "instaDebitCard",
        "conditionalVarValue": true,

    },

    {
        "varName": "instaCardType",
        "type": "select",
        "label": "Card Type *",
        "enum": [
            "CITYMAXX"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "instaDebitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "instaDebitCardProductType",
        "type": "select",
        "label": "Product Type *",
        "enum":[],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "instaDebitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "instaClientId",
        "type": "textApiCall",
        "label": "Client ID *",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "instaDebitCard",
        "conditionalVarValue": true,

    }, {
        "varName": "instaCardNumber",
        "type": "text",
        "label": "Card Number *",
        "readOnly":true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["instaDebitCard", "instaClientIdApiCall"],
        "conditionalArrayValue": [true, true],

    }
];

//lIVE SERVER
/*let CSJsonFormForCasaService =[
    {
        "varName": "priority",
        "type": "select",
        "label": "Priority",
        "enum": [
            "GENERAL",
            "HIGH",
        ],
        "grid": 2,
    },
    {
        "varName": "statementFacility",
        "type": "select",
        "enum": [
            "Printed Statement",
            "E-Statement",
            "NO"
        ],
        "grid": 2,
        "label": "Statement *",

    },
    {
        "varName": "statementFacilityEmailAddress",
        "type": "text",
        "validation": "email",
        "label": "Email Address *",
    },

    {
        "varName": "smsAlertRequest",
        "type": "select",
        "enum": [
            "Y",
            "N",
            "AB",
            "CCEP",
        ],
        "onKeyDown":true,
        "label": "SMS Alert Request",
        "grid": 2,
    },





    /!*   {
           "varName": "lockerFacility",
           "type": "checkbox",
           "label": "Locker Facility",


       },*!/
    {
        "varName": "loanRequest",
        "type": "checkbox",
        "label": "Loan Request",

    },
    {
        "varName": "cityTouchRequest",
        "type": "checkbox",
        "label": "City Touch",

    },
    //tem
    /!*{
        "varName": "dpsRequest",
        "type": "checkbox",
        "label": "DPS Request",

        "grid": 12,

    },*!/

    {
        "type": "title",
        "grid": 12,
        "request":"DPS",
        "label": "DPS Information",
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true
    },

    {
        "varName": "dpsfdValueDate",
        "type": "date",
        "dayAdd": 0,
        "request":"DPS",
        "required": true,
        "grid": 2,
        "onKeyDown": true,
        "label": "DPS Value Date",
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,

    {
        "varName": "dpsnameOfScheme",
        "type": "select",
        "grid": 2,
        "enum": [],
        "request":"DPS",
        "required": true,
        "onKeyDown":true,
        "label": "Name Of Scheme",
        "conditionalArray": true,
        "conditionalArrayList": ["dpsnameOfSchemeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    },
    {
        "varName":"dpsaccountAcquisition",
        "type":"select",
        "enum":[],
        "onKeyDown":true,
        "required": true,
        "request":"DPS",
        "label":"Account Acquisition By",
        "grid":2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsaccountAcquisitionApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]


    },
    {
        "varName": "dpstenureType",
        "type": "select",
        "grid": 2,
        "enum": [],
        "required": true,
        "request":"DPS",
        "onKeyDown":true,
        "label": "Scheme Type",
        "conditionalArray": true,
        "conditionalArrayList": ["dpstenureTypeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    },
    {
        "varName": "dpstenure",
        "type": "select",
        "enum": [],
        "grid": 2,
        "required": true,
        "request":"DPS",
        "label": "Period Of Scheme",
        "onKeyDown":true,
        "conditionalArray": true,
        "conditionalArrayList": ["dpstenureApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]
    },

    {
        "varName": "dpsdpsSchemeCode",
        "type": "autoCompleteValueReturn",
        "enum":[],
        "required": true,
        "request":"DPS",
        "label": "DPS Scheme Code",
        "multiline":true,
        "readOnly":true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsdpsSchemeCodeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    }
    ,
    {
        "varName": "availableAmount",
        "type": "text",
        "label": "Available Amount",
        "request":"FDR",
        "readOnly":true,
        "required": true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsRequest", "availableAmountShow"],
        "conditionalArrayValue": [true, true],

    },
    {
        "varName": "dpsamount",
        "type": "text",
        "validation":"numeric",
        "required": true,
        "request":"DPS",
        "onKeyDown": true,
        "label": "Amount Per Installment",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsdpsSchemeCodeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]


    }
    ,
    {
        "varName": "nextExecutionDate",
        "type": "date",
        "required": true,
        "request":"DPS",
        "label": "Installment Start Date",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true


    }
    ,
    {
        "varName": "dpspayableAtMaturity",
        "type": "text",
        "readOnly": true,
        "request":"DPS",
        "label": "Payable At Maturity",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "varName": "dpsfdMaturityDate",
        "type": "text",
        "label": "Maturity Date",
        "readOnly": true,
        "request":"DPS",
        "required": true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsfdMaturityDateApicall", "dpsRequest"],
        "conditionalArrayValue": ["YES", true]
    },
    {
        "varName": "dpsinterestRate",
        "type": "text",
        "label": "Rate %",
        "readOnly": true,
        "request":"DPS",
        "grid": 2,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsinterestRateApicall", "dpsRequest"],
        "conditionalArrayValue": ["YES", true]
    },


    {
        "varName": "eTin",
        "type": "text",
        "validation":"etin",
        "request":"DPS",
        "readOnly": true,
        "label": "Tin No",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "varName": "dpsdepositType",
        "type": "text",
        "readOnly": true,
        "label": "Deposit Type",
        "request":"DPS",
        "grid": 2,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["depositTypeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    }
    ,
    {
        "varName": "occupationCode",
        "type": "text",
        "required": true,
        "readOnly": true,
        "request":"DPS",
        "label": "Occupation Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "readOnly": true,
        "enum": [],
        "request":"DPS",
        "label": "Sector Code",
        "grid": 2,
        required: true,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Sector Code",
        "grid": 2,
        "request":"DPS",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["sectorCodeApicall", "dpsRequest"],
        "conditionalArrayValue": [true, true]
    },
    {
        "varName": "rmCode",
        "type": "text",
        "required": true,
          "onKeyDown":true,
        "label": "RM Code",
        "grid": 2,
        "request":"DPS",
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "varName": "rmCodeName",
        "type": "text",
        "readOnly": true,
        "label": "RM Code Name",
        "grid": 2,
        "request":"DPS",
        "conditionalArray": true,
        "conditionalArrayList": ["rmCodeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    }
    ,
    {
        "varName": "nomineeTypes",
        "type": "select",
        "required": true,
        "enum": [
            "Nominee Same As Operative Account",
            "New Nominee Assignment",
        ],
        "request":"DPS",
        "label": "Nominee Type",
        "grid": 2,

        "conditionalArray": true,
        "conditionalArrayList": ["nomineeInformationVisible", "dpsRequest"],
        "conditionalArrayValue": ["YES", true]
    },

    {
        "varName": "nomineeExistNots",
        "type": "select",
        "enum":[
            "Y",
            "N",
        ],
        "request":"DPS",
        "readOnly":true,
        "label": "Nominee Exist/Not",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["nomineeTypes", "dpsRequest"],
        "conditionalArrayValue": ["Nominee Same As Operative Account", true]

    },


    /!* {
         "varName": "cityTouchEmailAddress",
         "type": "text",
         "validation":"email",
         "label": "Email Address *",
         "conditional": true,
         "conditionalVarName": "cityTouchRequest",
         "conditionalVarValue": true,

     },*!/

].concat(CSJsonFormForFDRService);
let CSJsonFormForCasaServiceLast = [
    {
        "varName": "chequeBookRequest",
        "type": "checkbox",
        "onkeyDown":true,
        "label": "Cheque Book Request",
        "grid": 12,

    },
    /!* {
        varName: "accountStatus",
        type: "text",
        label: "Account Status",
        //  readOnly: true,
        grid: 2,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
    },
    {
        varName: "accountType",
        type: "text",
        label: "Account Type",
        //required: true,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
        //  readOnly: true,
        grid: 2,
    },*!/
    /!*{
        varName: "mobileNumber",
        type: "text",
        label: "Mobile Number",
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
        //required: true,
        //  readOnly: true,
        grid: 2,
    },*!/
    /!*  {
          varName: "numberOfChequeBook",
          type: "select",
          label: "No. of Cheque Book",
          //required: true,
          grid: 2,
          enum: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": true,
      },
      {
          varName: "pageofChequebook",
          type: "select",
          label: "Page of Chequebook",
          //required: true,
          grid: 2,
          enum: ["10", "25"],
          "conditionalArray": true,
          "conditionalArrayList": ["accountType", "accountType","accountType","chequeBookRequest"],
          "conditionalArrayValue": ["Savings","Savings(Alo)", "Savings(Ababil Alo)",true],

      },
      {
          varName: "pageofChequebook",
          type: "select",
          label: "Page of Chequebook",
          //required: true,
          grid: 2,
          "conditionalArray": true,
          "conditionalArrayList": ["accountType","chequeBookRequest"],
          "conditionalArrayValue": ["Current",true],
          enum: ["25", "50", "100"]
      },
      {
         varName: "deliveryType",
          type: "select",
          enum:[
            "Branch"
          ],
          label: "Delivery Type",
          //required: true,
          //  readOnly: true,
          grid: 2,
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": true,
      },

      {
          varName: "branchName",
          type: "autoComplete",
          label: "Branch Name",
          //required: true,
          "conditionalArray": true,
          "conditionalArrayList": ["deliveryType","chequeBookRequest"],
          "conditionalArrayValue": ["Branch",true],
          enum: ["25", "50", "100"],
          grid: 2,
      },
      {
          varName: "customerAddress",
          type: "text",
          label: "Customer Address",
          //required: true,
          "conditionalArray": true,
          "conditionalArrayList": ["deliveryType","chequeBookRequest"],
          "conditionalArrayValue": ["Courier",true],
          grid: 2,
      },
      {
          varName: "customerType",
          type: "select",
          label: "Customer Type",
          enum: ["General", "Priority", "Sapphire"],
          grid: 2,
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": true,
      },
      {
          varName: "checqueBookDesign",
          type: "select",
          label: "Chequebook Design",
          grid: 2,
          enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5"],
          "conditionalArray": true,
          "conditionalArrayList": ["customerType","chequeBookRequest"],
          "conditionalArrayValue": ["Priority",true],
      },
      {
          varName: "checqueBookDesign",
          type: "select",
          label: "Chequebook Design",
          grid: 2,
          enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5", "Design Code 6"],
          "conditionalArray": true,
          "conditionalArrayList": ["customerType","chequeBookRequest"],
          "conditionalArrayValue": ["Sapphire",true],

      },
      {
          varName: "chequeBookType",
          type: "select",
          label: "Cheque Book Type",
          enum: ["Normal", "A4"],
          grid: 2,
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": true,
      },
      {
          varName: "requisitionBranch",
          type: "autoCompleteValueReturn",
          enum:[],
          label: "Requisition branch",
          grid: 2,
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": true,
      },*!/
    //cheque book
    {
        varName: "numberOfChequeBook",
        type: "select",
        label: "No. of Cheque Book *",
        required: true,
        // asterisk: true,
        grid: 2,
        errorMessage: "Error",
        enum: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,

    },
    {
        varName: "pageofChequebook",
        type: "select",
        label: "Page of Chequebook *",
        required: true,
        // asterisk: true,
        grid: 2,
        errorMessage: "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "accType"],
        "conditionalArrayValue": [true, "SAVINGS"],
        enum: ["10", "25"]
    },
    {
        varName: "pageofChequebook",
        type: "select",
        label: "Page of Chequebook *",
        required: true,
        // asterisk: true,
        grid: 2,
        errorMessage: "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "accType"],
        "conditionalArrayValue": [true, "CURRENT"],
        enum: ["25", "50", "100"]
    },

    {
        varName: "chequeBookDeliveryType",
        type: "select",
        label: "Delivery Type *",
        required: true,
        // asterisk: true,
        grid: 2,
        errorMessage: "Error",
        enum: ["Branch",],
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
    },

    {
        varName: "checkbookBranchName",
        "type": "autoCompleteValueReturn",
        "enum": [],
        label: "Branch Name *",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "chequeBookDeliveryType"],
        "conditionalArrayValue": [true, "Branch"],
        grid: 2,
        errorMessage: "Error",
        // enum: ["Gulshan", "Banani", "Dhanmondi"]
    },
    {
        varName: "chequeBookType",
        type: "select",
        label: "Cheque Book Type *",
        enum: ["Normal", "A4"],
        grid: 2,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
    },
    /!*{
        varName: "requisitionBranch",
        type: "autoCompleteValueReturn",
        enum:[],
        label: "Requisition branch",
        grid: 2,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
    },*!/
    /!* {
         varName: "customerAddress",
         type: "text",
         label: "Customer Address",
         required: true,
         // asterisk: true,
         "conditionalArray": true,
         "conditionalArrayList": ["chequeBookRequest", "deliveryType"],
         "conditionalArrayValue": [true, ],
         grid: 2,
         errorMessage: "Error"
     },*!/
    {
        varName: "customerType",
        type: "text",
        "readOnly":true,
        label: "Customer Type *",
        grid: 2,
        errorMessage: "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "customerTypeFlag"],
        "conditionalArrayValue": [true, true],

    },
    {
        varName: "checqueBookDesign",
        type: "select",
        label: "Chequebook Design *",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "customerType"],
        "conditionalArrayValue": [true, "Priority"],

        grid: 2,
        enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5"],
        errorMessage: "Error"
    },
    {
        varName: "checqueBookDesign",
        type: "select",
        label: "Chequebook Design *",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "customerType"],
        "conditionalArrayValue": [true, "Sapphire"],
        grid: 2,
        enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5", "Design Code 6"],
        errorMessage: "Error"
    },
//Debit Card
     /!*{
        "varName": "debitCard",
        "type": "checkbox",
        "onKeyDown":true,
        "label": "Debit Card",
        "grid": 12
    }, *!/
    {
        varName: "cardType",
        type: "select",
        label: "Card Type *",
        required: true,
        grid: 2,
        errorMessage: "Error",
        enum: ["VISA DEBIT CARD", "MASTER CARD DEBIT CARD", "CITY MAXX DEBIT CARD"],
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,
    },


    {
        varName: "productType",
        type: "select",
        label: "Product Type *",
        required: true,
        grid: 2,
        errorMessage: "Error",
        enum: [
            "VISA DEBIT CARD STAFF",
            "VISA DEBIT CARD GENERAL",
            "VISA CORPORATE 1st year free",
            "VISA DEBIT CARD YOUTH",
            "VISA Debit Card corporate 2nd year tk 300",
            "VISA Debit Card corporate lifetime free",
            "VISA Debit Card - City Alo",
            "Easy VISA Debit card",
            "Easy Plus VISA Debit card",
            "Visa Corporate 1st Year & 2nd Year Free",
            "Debit Card Fee - University Campaign [172.5,345]"
        ],
        "conditionalArray": true,
        "conditionalArrayList": ["debitCard", "cardType"],
        "conditionalArrayValue": [true, "VISA DEBIT CARD"],
    },
    {
        varName: "productType",
        type: "select",
        label: "Product Type *",
        required: true,
        grid: 2,
        errorMessage: "Error",
        conditional: true,
        conditionalVarName: "cardType",
        conditionalVarValue: "MASTER CARD DEBIT CARD",
        enum: [
            "MASTER CARD PLATINUM STAFF",
            "MASTER CARD PLATINUM GENERAL",
            "MASTER CARD DEBIT CARD STAFF",
            "MASTER CARD DEBIT CARD GENERAL",
            "MASTER DEBIT CARD CORPORATE 1st year free",
            "MASTER CARD DEBIT CARD YOUTH",
            "MASTER CARD DEBIT CARD PLATINUM CITY GEMS",
            "Master Debit Card Corporate 2nd year tk 300",
            "Master Debit Card corporate lifetime free",
            "Master Platinum Gems staff card",
            "MC Platinum Debit Card - City Gems - Sapphire",
            "Master Manarah Debit card general",
            "Master Manarah Debit card Corporate",
            "Master Card Corporate 1st Year & 2nd Year Free",
        ],
        "conditionalArray": true,
        "conditionalArrayList": ["debitCard", "cardType"],
        "conditionalArrayValue": [true, "MASTER CARD DEBIT CARD"],
    },
    {
        varName: "productType",
        type: "select",
        label: "Product Type *",
        required: true,
        grid: 2,
        errorMessage: "Error",
        enum: [
            "CITY MAXX STAFF",
            "CITY MAXX GENERAL",
            "CITY MAXX CORPORATE 1st year free",
            "CITY MAXX KIOSK CAMPAIGN",
            "CITY MAXX CITY GEMS (PRIORITY BANKING)",
            "CITY MAXX YOUTH",
            "Citymaxx Corporate 2nd Year Tk. 300",
            "CITYMAXX corporate lifetime free",
            "MaxX City Gems Sapphire",
            "Easy City Maxx Debit Card",
            "Easy Plus City Maxx Debit Card",
            "City MaxX Corporate 1st Year & 2nd Year Free"
        ],
        "conditionalArray": true,
        "conditionalArrayList": ["debitCard", "cardType"],
        "conditionalArrayValue": [true, "CITY MAXX DEBIT CARD"],
    },
    {
        varName: "nameOnCard",
        type: "text",
        label: "Name on Card *",
        required: true,
        grid: 2,
        errorMessage: "Error",
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,
    },

    {
        varName: "debitCardDeliveryTypes",
        type: "select",
        label: "Delivery Branch Name *",
        required: true,
        grid: 2,
        errorMessage: "Error",
        enum: ["Branch",],
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,
    },

    {
        varName: "debitCardBranchName",
        "type": "autoCompleteValueReturn",
        "enum": [],
        label: "Branch Name *",
        grid: 2,
        errorMessage: "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["debitCard", "debitCardDeliveryTypes"],
        "conditionalArrayValue": [true, "Branch"],
    },
    /!*{
        varName: "customerAddress",
        type: "text",
        label: "Customer Address",
        multiline: true,
        required: true,
        grid: 2,
        errorMessage: "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["debitCard", "deliveryType"],
        "conditionalArrayValue": [true, ],
    },*!/
    {
        "varName": "instaDebitCard",
        "type": "checkbox",
        "label": "Insta Debit Card",
        "grid": 12
    },
    {
        "varName": "instaDebitCardName",
        "type": "text",
        "label": "Name On Card *",
        "grid": 2,
        "validationB":"length",
        "maxLength":19,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "instaDebitCard",
        "conditionalVarValue": true,

    },

    {
        "varName": "instaCardType",
        "type": "select",
        "label": "Card Type *",
        "enum": [
            "CITYMAXX"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "instaDebitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "instaDebitCardProductType",
        "type": "select",
        "label": "Product Type *",
        "enum":[],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "instaDebitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "instaClientId",
        "type": "textApiCall",
        "label": "Client ID *",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "instaDebitCard",
        "conditionalVarValue": true,

    }, {
        "varName": "instaCardNumber",
        "type": "text",
        "label": "Card Number *",
        "readOnly":true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["instaDebitCard", "instaClientIdApiCall"],
        "conditionalArrayValue": [true, true],

    }
];*/

let CSJsonFormForCasaServiceAll = CSJsonFormForCasaService.concat(CSJsonFormForCasaServiceLast);
/*let CSJsonFormForCasaIndividual = [
    {
        "varName": "eqmTicketNumber",
        "type": "text",
        "validation": "numeric",
        "label": "EQM Ticket Number",
        "grid": 2,
    },
    {
        "varName": "accountSource",
        "type": "select",
        "label": "Account Source ",
        "grid": 2,
        "errorMessage": "Error",
        "required": true,
        "enum": [
            "Conventional",
            "Islamic"
        ],


    },
    {
        "varName": "customerStatus",
        "type": "select",
        "label": "Customer Status ",
        "grid": 2,
        "errorMessage": "Error",
        "required": true,
        "enum": [
            "Normal Customer",
            "Staff",
            "Priority",
        ],


    },
    {
        "varName": "accountType",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type *",
        "enum": [
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
    }, {
        "varName": "accountType",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type *",
        "enum": [
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "accountTypes",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type *",
        "enum": [
            "Insta Pack",
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
    },
    {
        "varName": "accType",
        "type": "select",
        "required": true,
        "errorMessage": "Error",
        "label": "Account Type ",
        "enum": [
            "SAVINGS",
            "CURRENT",
            /!* "FCY",
             "RFCD"*!/,
        ],
        "grid": 2,
    },

    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number *",
        "validation": "numeric",
        "errorMessage": "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["accountTypes", "customerStatus"],
        "conditionalArrayValue": ["Insta Pack", "Normal Customer"],

        "grid": 2,
    },


    {
        "varName": "customerTitle",
        "type": "select",
        "enum": [
            "CAPT.",
            "DR.",
            "ENGG.",
            "LAW",
            "MAST.",
            "MINOR",
            "MR",
            "MR.",
            "MRS.",
            "MS."

        ],
        "required": true,
        "label": "Customer Title ",
        "grid": 2,

    },
    {
        "varName": "customerName",
        "type": "text",
        "readOnly": true,
        "validation": "string",
        "multiline": true,
        "label": "Customer Name ",
        "grid": 2,

    },
    {
        "varName": "shortName",
        "type": "text",
        "multiline": true,
        "validation": "string",
        "required": true,
        "label": "Short Name",
        "grid": 2,

    },
    {
        "varName": "gender",
        "type": "select",
        "enum": [
            "M",
            "F",
            "O",
        ],
        "required": true,
        "label": "Gender",
        "grid": 2,

    },
    {
        "varName": "occupationCodeNormal",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
        "grid": 2,

    },
    {
        "varName": "occupationCodePriority",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
        "grid": 2,

    },
    {
        "varName": "occupationCodeStaff",
        "type": "select",
        "enum": [
            "E",
        ],
        "label": "Occupation Code",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",

        "grid": 2,

    },
    {
        "varName": "staffIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "label": "Staff Indicator",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
        "grid": 2,

    },
    {
        "varName": "staffIdNumber",
        "type": "text",
        "label": "Staff ID Number",
        "validation":"staffId",
        asterisk: true,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
        "grid": 2,

    },
    {
        "varName": "dob",
        "type": "date",
        "maxDate": true,
        "readOnly": true,
        "label": "Date Of Birth ",
        "grid": 2,


    },
    {
        "varName": "designatedEmail",
        "type": "text",
        "validation": "email",
        "readOnly": true,
        "label": "Designated Email ",
        "multiline": true,
        "errorMessage": "Error", "grid": 2,


    },

    {
        "varName": "designatedMobileNumber",
        "type": "text",
        "readOnly": true,
        "label": "Designated Mobile Number ",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",

    },
    {
        "varName": "designatedMobileNumber",
        "type": "text",
        "validation": "phone",
        "phonePrefix": true,
        "readOnly": true,
        "label": "Designated Mobile Number ",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "phone2",
        "type": "text",
        "validation": "phone",
        "phonePrefix": true,
        "label": "Mobile Number 2",
        "grid": 2,


    },
    {
        "varName": "nid",
        "type": "text",
        "errorMessage": "Error",
        "readOnly": true,
        "validation": "nid",
        "label": "NID",
        "grid": 2,

    },
    {
        "varName": "smartCard",
        "type": "text",
        "errorMessage": "Error",
        "readOnly": true,
        "validation": "smartCard",
        "label": "Smart Card",
        "grid": 2,

    },
    {
        "varName": "passport",
        "type": "text",
        "validation": "passport",
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Passport",
        "grid": 2,


    },
    {
        "varName": "eTin",
        "type": "text",
        "readOnly": true,
        "validation": "etin",
        "label": "E-Tin",
        "grid": 2,

    },


    {
        "varName": "birthCertificateNo",
        "type": "text",
        "readOnly": true,
        "validation": "birthCertificateNumber",
        "label": "Birth Cer.",
        "grid": 2,

    },
    {
        "varName": "drivingLicense",
        "type": "text",
        "readOnly": true,
        "label": "Driving License",
        "grid": 2,

    },
    {
        "varName": "nationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Nationality ",
        "required": true,
        "grid": 2,


    },
    {
        "varName": "SelectCommunicationAddress",
        "type": "select",
        "enum": [
            "Present",
            "Permanent",
            "Office",
        ],
        "required": true,
        "label": "Select Communication Address ",
        "grid": 2
    },
    {
        "varName": "communicationAddress1",
        "type": "text",
        "multiline": true,
        "label": "Communication Address 1 ",
        "grid": 3,
        "required": true,
    },

    {
        "varName": "communicationAddress2",
        "type": "text",
        "multiline": true,
        "label": "Communication Address 2",
        "grid": 3,
    },

    {
        "varName": "city2",
        "label": "City",
        "required": true,
        "type": "autoCompleteValueReturn",
        "enum": [],
        "grid": 2
    },

    {
        "varName": "state2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Division(State)",
        "grid": 2
    },

    {
        "varName": "postalCode3",
        "type": "text",
        "validation": "numeric",
        "required": true,
        "label": "Postal Code",
        "maxLength":6,
        "grid": 2
    },
    {
        "varName": "country",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Country",
        "grid": 2
    },
    {
  "varName": "schemeCodeForSavings",
  "type": "autoCompleteValueReturn",
  "enum": [],
  "required": true,
  "label": "Scheme Code ",
  "grid": 2,
  "conditional": true,
  "conditionalVarName": "accType",
  "conditionalVarValue": "SAVINGS",
 },
 {
  "varName": "schemeCodeForCurrent",
  "type": "autoCompleteValueReturn",
  "enum": [],
  "required": true,
  "label": "Scheme Code ",
  "grid": 2,
  "conditional": true,
  "conditionalVarName": "accType",
  "conditionalVarValue": "CURRENT",
 },
    {
        "varName": "schemeCodeForSavings",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Scheme Code *",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "FCY",
    },
    {
        "varName": "schemeCodeForSavings",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Scheme Code *",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "RFCD",
    },
    {
        "varName": "currency",
        "type": "autoCompleteValueReturn",
        "label": "Currency ",
        "required": true,
        "grid": 2,
        "enum": []
    },
    {
        "varName": "rmCode",
        "type": "textApiCall",
        "label": "RM Code ",
        "required":true,
        //"validation":"rmCode", //no need
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
        "grid": 2,
    },
    {
        "varName": "rmCode",
        "type": "textApiCall",
        "label": "RM Code ",
        "required":true,
        //"validation":"rmCode", //no need
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
        "grid": 2,
    },

    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "RM Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["rmCodeApiCall", "customerStatus"],
        "conditionalArrayValue": [true, "Normal Customer"],
        "grid": 2,
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "RM Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["rmCodeApiCall", "customerStatus"],
        "conditionalArrayValue": [true, "Staff"],
        "grid": 2,
    },
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "onKeyDown": true,
        "enum": [],
        "label": "Sector Code",
        "grid": 2,
        required: true,
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Sector Code",
        "grid": 2,
        required: true,
        "conditional": true,
        "conditionalVarName": "sectorCodeApicall",
        "conditionalVarValue": true,
    },


    {
        "varName": "priorityCenterCode",
        "type": "select",
        "enum": [
           "PR104",
            "PR142",
            "PR177",
            "PR186",
            "PR187",
            "PR195",
            "PR208",
            "PR169",
        ],
        "errorMessage": "Error",
        "label": "Priority Center Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRMCode",
        "type": "textApiCall",
        "errorMessage": "Error",
        //"validation":"rmCode", //no need
        "label": "Monitoring RM Code",
        "required":true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRmCodeName",
        "type": "textApiCall",
        "errorMessage": "Error",
        //"validation":"rmCode", //no need
        "label": "Monitoring RM Name",
        "required":true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["customerStatus", "monitoringRMCodeApiCall"],
        "conditionalArrayValue": ["Priority", true],
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "errorMessage": "Error",
        "label": "Acquiring RM Code",
        "required":true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "Acquiring Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["customerStatus", "rmCodeApiCall"],
        "conditionalArrayValue": ["Priority", true],
        "grid": 2,
    },
    {
        "varName": "primaryCBHolderforPriority",
        "type": "text",
        "errorMessage": "Error",
        "label": "Primary CB Holder for Priority",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "relationshipwithPrimaryCBHolder",
        "type": "text",
        "errorMessage": "Error",
        "label": "Relationship with Primary CB Holder",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "functionCodeforChequeBookWaiver",
        "type": "text",
        "errorMessage": "Error",
        "readOnly": true,
        "label": "Function Code for Cheque Book Waiver",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "waiverTypeforChequeBookWaiver",
        "type": "text",
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Waiver Type  for Cheque Book Waiver",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "waiverField",
        "type": "text",
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Waiver Field",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },


    {
        "varName": "introducerId",
        "type": "textApiCall",
        "required": true,
        "label": "Introducer ID",
        "grid": 2,

    },
    {
        "varName": "introducerName",
        "type": "text",
        "multiline": true,
        "readOnly": true,
        "label": "Introducer Name",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "introducerIdApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "ccepCode",
        "type": "textApiCall",
        "label": "CCEP Code",
        "grid": 2,

    },



];*/
let CSJsonFormForCasaIndividual = [
    {
        "varName": "eqmTicketNumber",
        "type": "text",
        "validation": "numeric",
        "label": "EQM Ticket Number",
        "grid": 2,
    },
    {
        "varName": "branchSolId",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "SOL ID",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "dstFlag",
        "conditionalVarValue": true,

    },
    {
        "varName": "branchSolId",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "SOL ID",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "dstFlag",
        "conditionalVarValue": "YES",

    },

    {
        "varName": "accountSource",
        "type": "select",
        "label": "Account Source ",
        "grid": 2,
        "errorMessage": "Error",
        "required": true,
        "enum": [
            "Conventional"
           // "Islamic"
        ],


    },
    {
        "varName": "customerStatus",
        "type": "select",
        "label": "Customer Status ",
        "grid": 2,
        "errorMessage": "Error",
        "required": true,
        "enum": [
            "Normal Customer",
            "Staff",
            "Priority",
        ],


    },
    {
        "varName": "accountType",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type *",
        "enum": [
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
    }, {
        "varName": "accountType",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type *",
        "enum": [
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "accountTypes",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type *",
        "enum": [
            "Insta Pack",
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
    },
    {
        "varName": "accType",
        "type": "select",
        "required": true,
        "errorMessage": "Error",
        "label": "Account Type ",
        "enum": [
            "SAVINGS",
            "CURRENT",
            /* "FCY",
             "RFCD"*/,
        ],
        "grid": 2,
    },

    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "label": "Account Number *",
        "validation": "numeric",
        "errorMessage": "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["accountTypes", "customerStatus"],
        "conditionalArrayValue": ["Insta Pack", "Normal Customer"],

        "grid": 2,
    },


    {
        "varName": "customerTitle",
        "type": "select",
        "enum": [
          /*  "CAPT.",
            "DR.",
            "ENGG.",
            "LAW",
            "MAST.",
            "MINOR",
            "MR",
            "MR.",
            "MRS.",
            "MS.",
            "BANK",
            "JOINT",
            "M/S."*/
            "MR.",
            "MRS.",
            "MS."

        ],
        "required": true,
        "label": "Customer Title ",
        "grid": 2,

    },
    {
        "varName": "customerName",
        "type": "text",
        "validationB":"length",
        "maxLength":80,
        "readOnly": true,
        "multiline": true,
        "label": "Customer Name ",
        "grid": 2,

    },
    {
        "varName": "shortName",
        "type": "text",
        "multiline": true,
        "validationB":"length",
        "maxLength":10,
        "required": true,
        "label": "Short Name",
        "grid": 2,

    },
    {
        "varName": "gender",
        "type": "select",
        "enum": [
            "M",
            "F",
            "O",
        ],
        "required": true,
        "label": "Gender",
        "grid": 2,

    },
    {
        "varName": "occupationCodeNormal",
        "type": "select",
        "enum": [],
        "required":true,
        "label": "Occupation Code",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
        "grid": 2,

    },
    {
        "varName": "occupationCodePriority",
        "type": "select",
        "enum": [],
        "required":true,
        "label": "Occupation Code",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
        "grid": 2,

    },
    {
        "varName": "occupationCodeStaff",
        "type": "select",
        "enum": [
            "E",
        ],
        "required":true,
        "label": "Occupation Code",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",

        "grid": 2,

    },
    {
        "varName": "staffIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "label": "Staff Indicator",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
        "grid": 2,

    },
    {
        "varName": "staffIdNumber",
        "type": "text",
        "label": "Staff ID Number",
        "validation":"staffId",
        asterisk: true,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
        "grid": 2,

    },
    {
        "varName": "dob",
        "type": "date",
        "maxDate": true,
        "readOnly": true,
        "label": "Date Of Birth ",
        "grid": 2,


    },
    {
        "varName": "designatedEmail",
        "type": "text",
        //"validation": "email",
        "readOnly": true,
        "label": "Designated Email ",
        "multiline": true,
        "errorMessage": "Error", "grid": 2,


    },
    {
        "varName": "mobileTypeIndividual",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        // "required":true,
        "label": "Designated Mobile Type *",
        "readOnly":true,
        "grid": 2,
    },
    {
        "varName": "countryCodeIndividual",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Designated Mobile Country Code",
        "asteriks": true,
        "conditional": true,
        "readOnly":true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "designatedMobileNumber",
        "type": "text",
        "readOnly": true,
        "label": "Designated Mobile Number ",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",

    },


    {
        "varName": "designatedMobileNumber",
        "type": "text",
        // "validation": "phone",
        "phonePrefix": true,
        "readOnly": true,
        "label": "Designated Mobile Number ",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Local",

    },

    {
        "varName": "mobileTypeIndividual2",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        // "required":true,
        "label": "Second Mobile Type",
        "grid": 2,
    },
    {
        "varName": "countryCodeIndividual2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Second Mobile Country Code",
        "asteriks": true,
        //"required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "phone2",
        "type": "text",
        "validation": "phone",
        "phonePrefix": true,
        "label": "Mobile Number 2",
        // "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Local",
        "grid": 2,


    },
    {
        "varName": "phone2",
        "type": "text",
        "label": "Mobile Number 2",
        //"required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Overseas",
        "grid": 2,


    },
    {
        "varName": "nid",
        "type": "text",
        "errorMessage": "Error",
        "readOnly": true,
        //"validation": "nid",
        "label": "NID",
        "grid": 2,

    },
    {
        "varName": "smartCard",
        "type": "text",
        "errorMessage": "Error",
        "readOnly": true,
        //"validation": "smartCard",
        "label": "Smart Card",
        "grid": 2,

    },
    {
        "varName": "passport",
        "type": "text",
        //"validation": "passport",
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Passport",
        "grid": 2,


    },
    {
        "varName": "eTin",
        "type": "text",
        "etinBlock":true,
        "readOnly": true,
        //"validation": "etin",
        "label": "E-Tin",
        "grid": 2,

    },
    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "eTinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "eTinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "eTinopen"],
        "conditionalArrayValue": ["Y", true],
        "grid":2,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "label": "Tax Submission Year",
        "required":true,
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "eTinopen"],
        "conditionalArrayValue": ["N", true],
        "grid": 2,
    },


    {
        "varName": "birthCertificateNo",
        "type": "text",
        "readOnly": true,
        //"validation": "birthCertificateNumber",
        "label": "Birth Cer.",
        "grid": 2,

    },
    {
        "varName": "drivingLicense",
        "type": "text",
        "readOnly": true,
        "label": "Driving License",
        "grid": 2,

    },
    {
        "varName": "nationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Nationality ",
        "required": true,
        "grid": 2,


    },
    {
        "varName": "SelectCommunicationAddress",
        "type": "select",
        "enum": [
            "Present",
            "Permanent",
            "Office",
        ],
        "required": true,
        "label": "Select Communication Address ",
        "grid": 2
    },
    {
        "varName": "communicationAddress1",
        "type": "text",
        "multiline": true,
        "instatntValidation":true,
        "label": "Communication Address 1 ",
        "grid": 3,
        "validationB":"length",
        "maxLength":45,
        "required": true,
    },

    {
        "varName": "communicationAddress2",
        "type": "text",
        "multiline": true,
        "instatntValidation":true,
        "label": "Communication Address 2",
        "grid": 3,
        "validationB":"length",
        "maxLength":45,
    },

    {
        "varName": "city2",
        "label": "City",
        "required": true,
        "type": "autoCompleteValueReturn",
        "enum": [],
        "grid": 2
    },

    {
        "varName": "state2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Division(State)",
        "grid": 2
    },

    {
        "varName": "postalCode3",
        "type": "text",
        "validationB":"length",
        "maxLength":6,
        "required": true,
        "label": "Postal Code",
        "grid": 2
    },
    {
        "varName": "country",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Country",
        "grid": 2
    },
    {
  "varName": "schemeCodeForSavings",
  "type": "autoCompleteValueReturn",
  "enum": [],
  "required": true,
  "label": "Scheme Code ",
  "grid": 2,
  "conditional": true,
  "conditionalVarName": "accType",
  "conditionalVarValue": "SAVINGS",
 },
 {
  "varName": "schemeCodeForCurrent",
  "type": "autoCompleteValueReturn",
  "enum": [],
  "required": true,
  "label": "Scheme Code ",
  "grid": 2,
  "conditional": true,
  "conditionalVarName": "accType",
  "conditionalVarValue": "CURRENT",
 },
    {
        "varName": "schemeCodeForSavings",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Scheme Code *",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "FCY",
    },
    {
        "varName": "schemeCodeForSavings",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Scheme Code *",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "RFCD",
    },
    {
        "varName": "currency",
        "type": "autoCompleteValueReturn",
        "label": "Currency ",
        "required": true,
        "grid": 2,
        "enum": []
    },
    {
        "varName": "rmCode",
        "type": "textApiCall",
        "label": "RM Code ",
        "required":true,
        //"validation":"rmCode", //no need
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
        "grid": 2,
    },
    {
        "varName": "rmCode",
        "type": "textApiCall",
        "label": "RM Code ",
        "required":true,
        //"validation":"rmCode", //no need
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
        "grid": 2,
    },

    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "RM Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["rmCodeApiCall", "customerStatus"],
        "conditionalArrayValue": [true, "Normal Customer"],
        "grid": 2,
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "RM Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["rmCodeApiCall", "customerStatus"],
        "conditionalArrayValue": [true, "Staff"],
        "grid": 2,
    },
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "onKeyDown": true,
        "enum": [],
        "label": "Sector Code",
        "grid": 2,
        required: true,
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Sector Code",
        "grid": 2,
        required: true,
        "conditional": true,
        "conditionalVarName": "sectorCodeApicall",
        "conditionalVarValue": true,
    },


    {
        "varName": "priorityCenterCode",
        "type": "select",
        "enum": [
           "PR104",
            "PR142",
            "PR177",
            "PR186",
            "PR187",
            "PR195",
            "PR208",
            "PR169",
        ],
        "errorMessage": "Error",
        "label": "Priority Center Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRMCode",
        "type": "textApiCall",
        "errorMessage": "Error",
        //"validation":"rmCode", //no need
        "label": "Monitoring RM Code",
        "required":true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRmCodeName",
        "type": "textApiCall",
        "errorMessage": "Error",
        //"validation":"rmCode", //no need
        "label": "Monitoring RM Name",
        "required":true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["customerStatus", "monitoringRMCodeApiCall"],
        "conditionalArrayValue": ["Priority", true],
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "errorMessage": "Error",
        "label": "Acquiring RM Code",
        "required":true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "Acquiring Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["customerStatus", "rmCodeApiCall"],
        "conditionalArrayValue": ["Priority", true],
        "grid": 2,
    },
    {
        "varName": "primaryCBHolderforPriority",
        "type": "text",
        "errorMessage": "Error",
        "label": "Primary CB Holder for Priority",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "relationshipwithPrimaryCBHolder",
        "type": "text",
        "errorMessage": "Error",
        "label": "Relationship with Primary CB Holder",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "functionCodeforChequeBookWaiver",
        "type": "text",
        "errorMessage": "Error",
        "readOnly": true,
        "label": "Function Code for Cheque Book Waiver",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "waiverTypeforChequeBookWaiver",
        "type": "text",
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Waiver Type  for Cheque Book Waiver",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "waiverField",
        "type": "text",
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Waiver Field",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },

    {
        "varName": "nidVerified",
        "type": "select",
        "enum":[
            "YES",
            "NO",
        ],
        "required": true,
        "label": "Is NID/Smart Card Document Verified?",
        "grid": 2,

    },
    {
        "varName": "introducerId",
        "type": "textApiCall",
        "required": true,
        "label": "Introducer ID",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },
    {
        "varName": "introducerName",
        "type": "text",
        "multiline": true,
        "readOnly": true,
        "label": "Introducer Name",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["introducerIdApiCall", "nidVerified"],
        "conditionalArrayValue": ["YES", "NO"]
    },
    {
        "varName": "accountNumberForNidVerified",
        "type": "textApiCall",
        "required": true,
        "label": "Introducer Account Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },

    {
        "varName": "accountNameForNidVerified",
        "type": "text",
        "readOnly": true,
        "label": "Introducer Account Name",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["accountNumberForNidVerifiedApiCall", "nidVerified"],
        "conditionalArrayValue": ["YES", "NO"]
    },
    {
        "varName": "signatureCardView",
        "type": "submit",
        "label": "Signature Card View",
        "mt": 15,
        "event": false,
        "grid":2,
        "conditionalArray": true,
        "conditionalArrayList": ["accountNumberForNidVerifiedApiCall", "nidVerified"],
        "conditionalArrayValue": ["YES", "NO"]
    },

    {
        "varName": "ccepCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "CCEP Code",
        "grid": 2,

    },
    {
        "varName": "smsAlertRequest",
        "type": "select",
        "enum": [
            "Y",
            "N",
            "AB",
            "CCEP",
        ],
        "required":true,
        "onKeyDown":true,
        "label": "SMS Alert Request",
        "grid": 2,
    },



];
let CSJsonFormForCasaIndividualReadOnly = makeReadOnlyObject(JSON.parse(JSON.stringify(CSJsonFormForCasaIndividual)));

let BOMJsonFormForCasaIndividual = makeReadOnlyObject(JSON.parse(JSON.stringify(makeGridObject(CSJsonFormForCasaIndividual.concat(CSJsonFormForCasaServiceAll)))));

let BMJsonFormForCasaIndividual = makeReadOnlyObject(JSON.parse(JSON.stringify(CSJsonFormForCasaIndividual.concat(CSJsonFormForCasaServiceAll))));
/*let TP = [
    {
        "type": "title",
        "label": "TP",
        "grid": 12,
    },
    /!*{
        "varName": "TpFunction",
        "type": "select",
        "enum":[
            "ADD",
            "UPDATE",
            "DELETE",
        ],
        "label": "Function",
        "grid": 6,
    },*!/
    {
        "varName": "TpCustomerAccount",
        "type": "text",
        "label": "Customer Account",
        "readOnly":true,
        "grid": 6,
    },
    {
        "varName": "TpAccountName",
        "type": "text",
        "label": "Account Name",
        "grid": 6,
        "readOnly":true,
    },
    {
        "varName": "TpAccountType",
        "type": "text",
        "label": "Account Type",
        "readOnly":true,
        "grid": 6,
    },
    {
        "varName": "TpAccountNumber",
        "type": "text",
        "label": "Account or Reference No",
        "readOnly":true,
        "grid": 6,
    },
    {
        "varName": "TpSourceofFundForTransaction",
        "type": "text",
        "label": "Source of Fund For Transaction",
        "grid": 6,
        "required":true,
    }, {
        "varName": "TpEntityType",
        "type": "select",
        "enum":[
            "INDIVIDUL",
            "NON IND",
            "GOVT"
        ],
        "label": "Entity Type",
        "grid": 6,
    },
    {
        "varName": "TpMonthlyProbableIncome",
        "type": "text",
        "label": "Monthly Probable Income (Ind)/Monthly Probable Turn Over (Non Ind)",
        "grid": 6,
        "readOnly":true,
    },
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "type": "titleBold",
        "label": "Deposit",
        "grid": 3,
    },

    {
        "type": "titleBold",
        "label": "No. of Deposit (Monthly)",
        "grid": 3,
    },
    {
        "type": "titleBold",
        "label": "Total Deposit Amount (Monthly)",
        "grid": 3,
    },
    {
        "type": "titleBold",
        "label": "Maximum Amount of Deposit (Per Transaction)",
        "grid": 3,
    },
    {
        "type": "straightLine",
        "grid": 12,
    },
    //
    {
        "type": "titleBlack",
        "label": "Cash Deposit (Including Online/ATM Deposit)",
        "grid": 3,
    },
    {
        "varName": "cashDepositNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "cashDepositTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "cashDepositMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Deposit by Transfer/Instrument",
        "grid": 3,
    },
    {
        "varName": "depositbyNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositbyTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositbyMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Foreign Inward Remittance",
        "grid": 3,
    },
    {
        "varName": "foreignInwardNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "foreignInwardTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "foreignInwardMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Deposit of Income from Export",
        "grid": 3,
    },
    {
        "varName": "depositofIncomeNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositofIncomeTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositofIncomeMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Deposit/Transfer from BO Account",
        "grid": 3,
    },
    {
        "varName": "depositTransferNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositTransferTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositTransferMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Others",
        "grid": 3,
    },
    {
        "varName": "othersNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "othersTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "othersMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Total Probable Deposit",
        "grid": 3,
    },
    {
        "varName": "totalProbableNumberOfCash",
        "type": "text",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "totalProbableNumberOfCashApiCall",
        "conditionalVarValue": true,
        "grid": 3,
    },
    {
        "varName": "totalProbableTotalCash",
        "type": "text",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "totalProbableTotalCashApiCall",
        "conditionalVarValue": true,
        "grid": 3,
    },
    {
        "varName": "totalProbableMaximumCash",
        "type": "text",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "totalProbableMaximumCashApiCall",
        "conditionalVarValue": true,
        "grid": 3,
    },
    {
        "type": "empty",
        "grid":12,
    },
    {
        "type": "titleBold",
        "label": "Withdrawal",
        "grid": 3,
    },

    {
        "type": "titleBold",
        "label": "No. of Withdrawal (Monthly)",
        "grid": 3,
    },
    {
        "type": "titleBold",
        "label": "Total Withdrawal Amount (Monthly)",
        "grid": 3,
    },
    {
        "type": "titleBold",
        "label": "Maximum Amount of Withdrawal (Per Transaction)",
        "grid": 3,
    },
    {
        "type": "straightLine",
        "grid": 12,
    },
    //
    {
        "type": "titleBlack",
        "label": "Cash Withdrawal (Including Online/ATM Withdrawal)",
        "grid": 3,
    },

    {
        "varName": "cashWithdrawalNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "cashWithdrawalTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "cashWithdrawalmaximumAmount",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Withdrawal through Transfer/Instrument",
        "grid": 3,
    },

    {
        "varName": "withdrawalthroughNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "withdrawalthroughTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "withdrawalthroughmaximumAmount",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Foreign Outward Remittance",
        "grid": 3,
    },

    {
        "varName": "foreignOutwardNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "foreignOutwardTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "foreignOutwardmaximumAmount",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Payment Against Import",
        "grid": 3,
    },

    {
        "varName": "paymentAgainstNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "paymentAgainstTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "paymentAgainstmaximumAmount",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Deposit/Transfer to BO Account",
        "grid": 3,
    },

    {
        "varName": "depositTransferNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositTransferTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositTransfermaximumAmount",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Others",
        "grid": 3,
    },

    {
        "varName": "othersNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "othersTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "othersmaximumAmount",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Total Probable Withdrawal",
        "grid": 3,
    },

    {
        "varName": "totalProbableNumberofWithdrawal",
        "type": "text",
        "conditional": true,
        "conditionalVarName": "totalProbableNumberofWithdrawalApiCall",
        "conditionalVarValue": true,
        "readOnly": true,
        "grid": 3,
    },
    {
        "varName": "totalProbableTotalWithdrawal",
        "type": "text",
        "conditional": true,
        "conditionalVarName": "totalProbableTotalWithdrawalApiCall",
        "conditionalVarValue": true,
        "readOnly": true,
        "grid": 3,
    },
    {
        "varName": "totalProbablemaximumAmount",
        "type": "text",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "totalProbablemaximumAmountApiCall",
        "conditionalVarValue": true,
        "grid": 3,
    },

];
let KYC=[
    {

        "type": "title",
        "label": "KYC",
        "grid": 12,
    },
    {
        "varName": "functionCode",
        "type": "select",
        "enum": [
            "ADD",
            "UPDATE",
            "DELETE",
        ],
        "label": "Function Code",
        "grid": 6,
        "readOnly": true,
    },

    {
        "varName": "customerType",
        "type": "select",
        "enum": [
            "Individual",
            "Non Individual",
            "Deposit",
            "Govt",
        ],
        "label": "Customer Type",
        "grid": 6,
        "readOnly": true,
    },

    {
        "varName": "customerAccount",
        "type": "text",
        "validation": "numeric",
        "label": "Customer Account",
        "grid": 6,
        "readOnly":true,
    },

    {
        "varName": "aCTitle",
        "type": "text",
        "label": "A/C Title",
        "grid": 6,
        "readOnly": true,
    },

    {
        "varName": "accountType",
        "type": "select",
        "enum":[
            "SAVINGS",
            "CURRENT",
            /!* "FCY",
             "RFCD",*!/

        ],
        "label": "Account Type",
        "grid": 6,
        "readOnly": true,
    },

    {
        "varName": "customersOccupation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Customer's Occupation",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "otherssOccupation",
        "type": "text",
        "label": "Other's Occupation *",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "customersOccupation",
        "conditionalVarValue":"Others"
    },

    {
        "varName": "customerProbableMonthlyIncome",
        "type": "text",

        "label": "Customer probable monthly income",
        "grid": 6,
        "readOnly":true,
    },

    {
        "varName": "accountOrReference",
        "type": "text",
        "label": "Account or Reference",
        "grid": 6,
        "readOnly": true,
    },

    {
        "varName": "nameofOfficerOpeningtheAccount",
        "type": "text",
        "label": "Name of Officer Opening the Account",
        "grid": 6,
        "required":true,
        /!* "conditional": true,
        "conditionalVarName": "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall",
        "conditionalVarValue": true,*!/
    },

    {
        "varName": "sourceOfFundKyc",
        "type": "text",
        "label": "Source of Fund",
        "grid": 6,
        "required": true,
    },


    {
        "varName": "documentsCollectedToEnsureTheSourceOfFund",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Documents Collected to Ensure the Source of Fund",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "collectedDocumentsHaveBeenVerifiedOrNot",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "Collected Documents have been verified or not?",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "howTheAddressOfTheAccountHolderHasBeenVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "How the address (es) of the account holder has been Verified?",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "hasTheBeneficialOwnerOfTheAccountBeenIdentified",
        "type": "select",
        "enum": [
            "YES",
            "NO",
            "NOT APPLICABLE",
        ],
        "label": "Has the Beneficial owner of the account been identified",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "nameOfBeneficiarOwner",
        "type": "text",
        "label": "Name of Beneficiar Owner *",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "hasTheBeneficialOwnerOfTheAccountBeenIdentified",
        "conditionalVarValue": "YES",
    },

    {
        "varName": "passportNo",
        "type": "text",
        "label": "Passport no",
        "grid": 6,
        /!* "conditional": true,
         "conditionalVarName": "passportNumberpassportNumberUniqueIdApiCall",
         "conditionalVarValue": true,*!/
    },

    {
        "varName": "nationalIdCardNo",
        "type": "text",
        "label": "National ID Card No:",
        "grid": 6,
        /!* "conditional": true,
         "conditionalVarName": "nationalIdCardNumberUniqueIdApiCall",
         "conditionalVarValue": true,*!/
    },

    {
        "varName": "birthCertificateNo",
        "type": "text",
        "label": "Birth Certificate No:",
        "grid": 6,
        /!*  "conditional": true,
          "conditionalVarName": "birthCertificatebirthCertificateNumberUniqueIdApiCall",
          "conditionalVarValue": true,*!/
    },

    {
        "varName": "electronicTaxId",
        "type": "textApiCall",
        "label": "Electronic Tax ID",
        "grid": 6,
        /!* "conditional": true,
         "conditionalVarName": "electronicTaxIdApiCall",
         "conditionalVarValue": true,*!/
    },

    {
        "varName": "drivingLicenceNo",
        "type": "text",
        "label": "Driving Licence No:",
        "grid": 6,
    },

    {
        "varName": "otherDocumentation",
        "type": "text",
        "label": "Other Documentation",
        "grid": 6,
    },

    {
        "varName": "reasonForOpeningTheAccountOfNon-ResidentAndForeigner",
        "type": "text",
        "label": "Reason for Opening the Account of Non-resident and Foreigner",
        "grid": 6,
    },

    {
        "varName": "typeOfVisa",
        "type": "text",
        "label": "Type of VISA",
        "grid": 6,
    },

    {
        "varName": "expDate",
        "type": "date",
        "label": "Exp date",
        "grid": 6,
    },

    {
        "varName": "theCustomerAPolitically",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "The customer a Politically Exposed Person (PEP)/ Influential Person (IP)/ Head or high official of international organization and otheir family members or close associates?",
        "grid": 6,
    },

    {
        "varName": "isThereAnyMatchToTheCustomer",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "Is there any match to the customer name with the terrorist activity, funding in terrorist activities or listed person or entity on suspicion for Financing of proliferation of weapons of mass destruction under UN Security Council's different regulations and any listed person or banned entity by the Government of Bangladesh according to related laws, rules and circular?",
        "grid": 6,
    },

    {
        "varName": "whatDoesTheCustomerDoInWhatTypeOfBusineessIsTheCustomerEngaged",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "What does the Customer do/in what type of busineess is the customer engaged?",
        "grid": 6,
        "required": true,
    },


    {
        "varName":"customerMonthlyIncome",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required":true,
        "label":"Customer's monthly income",
        "grid":6,
    }, {
        "varName":"howWasAccountOpened",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label":"How was the account opened",
        "required":true,
        "grid":6,
    },
    {
        "varName":"expectedAmountOfMonthlyTotalTransactions",
        "type":"text",
        "label":"Expected Amount of Monthly Total Transactions?",
        "grid":6,
    },

    {
        "varName":"expectedNumberOfMonthlyTotalTransactions",
        "type":"text",
        "label":"Expected Number of Monthly total Transactions?",
        "grid":6,
    },

    {
        "varName":"expectedAmountOfMonthlyCashTransactions",
        "type":"text",
        "label":"Expected amount of Monthly Cash Transactions?",
        "grid":6,
    },

    {
        "varName":"expectedNumberOfMonthlyCashTransactions",
        "type":"text",
        "label":"Expected Number of Monthly Cash Transactions:",
        "grid":6,
    },

    {
        "varName":"overallRiskAssessment",
        "type":"text",
        "label":"Overall Risk Assessment:",
        "grid":6,
    },

    {
        "varName":"comments",
        "type":"text",
        "label":"Comments",
        "grid":6,
    },



];*/
/*let KYC=[]*/
let TP = [];
let KYC=[];
let TpKycForIndividual = [
    {
        "type": "title",
        "label": "TP and KYC",
        "grid": 12,
        "readOnly": true,
    },
    {
        "varName": "functionCode",
        "type": "select",
        "enum": [
            "ADD",
            "UPDATE",
            "DELETE",
        ],
        "label": "Function Code",
        "grid": 6,
        "readOnly": true,
    },
    {
        "varName": "TPcustomerType",
        "type": "autoCompleteValueReturn",
        "enum": [

        ],
        "label": "Customer Type",
        "grid": 6,
        "readOnly": true,
    },
    {
        "varName": "customerAccount",
        "type": "text",
        "validation": "numeric",
        "label": "Customer Account",
        "grid": 6,
        "readOnly": true,
    },
    {
        "varName": "TpEntityType",
        "type": "select",
        "enum": [
            "INDIVIDUL",
            "NON IND",
            "GOVT"
        ],
        "label": "Entity Type",
        "grid": 6,
        "readOnly": true,
    },

    {
        "varName": "aCTitle",
        "type": "text",
        "label": "A/C Title",
        "grid": 6,
        "readOnly": true,
    },
    {
        "varName": "TPandKycAccountType",
        "type": "autoCompleteValueReturn",
        "enum": [
            /* {
                 "masterDataName": "accountType",
                 "key": "Savings A/C",
                 "value": "Savings A/C Risk-(1)"
             },
             {
                 "masterDataName": "accountType",
                 "key": "Current A/C",
                 "value": "Current A/C Risk-(4)"
             },
             {
                 "masterDataName": "accountType",
                 "key": "Foreign Currency A/C",
                 "value": "Foreign Currency A/C Risk-(5)"
             },
             {
                 "masterDataName": "accountType",
                 "key": "SND A/C",
                 "value": "SND A/C Risk-(3)"
             },
             {
                 "masterDataName": "accountType",
                 "key": "RFCD A/C",
                 "value": "RFCD A/C Risk-(5)"*/

        ],
        "label": "Account Type",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "purposeOfAcOpening",
        "type": "text",
        "label": "Purpose of A/C Opening",
        "grid": 6,
    },
    {
        "varName": "customersOccupation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Customer's Occupation",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "othersOccupation",
        "type": "text",
        "label": "Others Occupation",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "customersOccupation",
        "conditionalVarValue": "others",
    },
    {
        "varName": "customerProbableMonthlyIncome",
        "type": "text",
        "label": "Customer probable monthly income",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "sourceOfFundKyc",
        "type": "text",
        "label": "Source of Fund",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "howTheSourcesOfFundHaveBeenVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "How the Source/Sources of Fund have been verified?",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "othersDocumentsCollectedToEnsureTheSourceOfFund",
        "type": "text",
        "label": "Others Documents Collected to Ensure the Source of Fund",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "howTheSourcesOfFundHaveBeenVerified",
        "conditionalVarValue": "others",
    },
    {
        "varName": "collectedDocumentsHaveBeenVerifiedOrNot",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Collected Documents have been verified or not?",
        "grid": 6,
    },
    {
        "varName": "howTheAddressOfTheAccountHolderHasBeenVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "How the address (es) of the account holder has been Verified?",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "othersDocumentTakenToVerifyAddress",
        "type": "text",
        "enum": [],
        "label": "Others Document taken to Verify Address",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "howTheAddressOfTheAccountHolderHasBeenVerified",
        "conditionalVarValue": "others",
    },
    {
        "varName": "hasTheBeneficialOwnerOfTheAccountBeenIdentified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Has the Beneficial owner of the account been identified",
        "grid": 6,
    },

    {
        "varName": "nameOfBeneficiarOwner",
        "type": "text",
        "label": "Name of Beneficial Owner",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "hasTheBeneficialOwnerOfTheAccountBeenIdentified",
        "conditionalVarValue": "1",
    },
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "varName": "passportNo",
        "type": "text",
        "label": "Passport no",
        "grid": 6,
    },
    {
        "type": "empty",
        "grid": 6,
    },
    {
        "varName": "passportCopyReceived",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Copy Received",
        "grid": 6,
    },
    {
        "varName": "passportVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Verified",
        "grid": 6,
    },

    {
        "varName": "nationalIdCardNo",
        "type": "text",
        "label": "National ID Card No:",
        "grid": 6,

    },
    {
        "type": "empty",
        "grid": 6,
    },
    {
        "varName": "nationalIdCardNoCopyReceived",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Copy Received",
        "grid": 6,
    },
    {
        "varName": "nationalIdCardNoVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Verified",
        "grid": 6,
    },
    {
        "varName": "birthCertificateNo",
        "type": "text",
        "label": "Birth Certificate No:",
        "grid": 6,

    },
    {
        "type": "empty",
        "grid": 6,
    },
    {
        "varName": "birthCertificateNoCopyReceived",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Copy Received",
        "grid": 6,
    },
    {
        "varName": "birthCertificateNoVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Verified",
        "grid": 6,
    },
    {
        "varName": "electronicTaxId",
        "type": "textApiCall",
        "label": "Electronic Tax ID",
        "grid": 6,
    },
    {
        "type": "empty",
        "grid": 6,
    },
    {
        "varName": "electronicTaxIdCopyReceived",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Copy Received",
        "grid": 6,
    },
    {
        "varName": "electronicTaxIdVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Verified",
        "grid": 6,
    },
    {
        "varName": "otherDocumentation",
        "type": "text",
        "label": "Other Documentation",
        "grid": 6,
    },
    {
        "type": "empty",
        "grid": 6,
    },
    {
        "varName": "otherDocumentationCopyReceived",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Copy Received",
        "grid": 6,
    },
    {
        "varName": "otherDocumentationVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Verified",
        "grid": 6,
    },
    {
        "varName": "customerNonresidentForeigner",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Customer is a Non-resident or Foreigner",
        "grid": 6,
    },
    {
        "varName": "reasonForOpeningTheAccountOfNonResidentAndForeigner",
        "type": "text",
        "label": "Reason for Opening the Account of Non-resident and Foreigner",
        "masterData": true,
        "conditionalVarName": "customerNonresidentForeigner",
        "conditionalVarValue": "Y",
        "grid": 6,
    },
    {
        "varName": "typeOfVisa",
        "type": "text",
        "label": "Type of VISA",
        "masterData": true,
        "conditionalVarName": "customerNonresidentForeigner",
        "conditionalVarValue": "Y",
        "grid": 6,
    },

    {
        "varName": "expDate",
        "type": "date",
        "label": "Exp date",
        "masterData": true,
        "conditionalVarName": "customerNonresidentForeigner",
        "conditionalVarValue": "Y",
        "grid": 6,
    },
    {
        "varName": "isWorkPermitPermissionOpenAc",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Is Work permit and permission to open A/C from appropiate authority is taken for opening the A/C of work permit holder?",
        "masterData": true,
        "conditionalVarName": "customerNonresidentForeigner",
        "conditionalVarValue": "Y",
        "grid": 6,
    },
    {
        "varName": "isTheCustomerPepHeadHigOfficialIntlOrg",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Is the customer a PEP/IP/ Head or high official of intl org (as per BFIU Circular defination)? *",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "pepHasTheApproval",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "Has the approval been taken from senior management?",
        "masterData": true,
        "conditionalVarName": "isTheCustomerPepHeadHigOfficialIntlOrg",
        "conditionalVarValue": "5",
        "grid": 6,
    },
    {
        "varName": "pepHasInterviewed",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "Has interviewed the customer face to face?",
        "masterData": true,
        "conditionalVarName": "isTheCustomerPepHeadHigOfficialIntlOrg",
        "conditionalVarValue": "5",
        "grid": 6,
    },
    {
        "varName": "customerFamily",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Is the customer a family members or close associates of any PEP/IP/ Head or high official of intl org? ",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "customerFamilyHasTheApproval",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "Has the approval been taken from senior management?",
        "masterData": true,
        "conditionalVarName": "customerFamily",
        "conditionalVarValue": "5",
        "grid": 6,
    },
    {
        "varName": "customerFamilyHasInterviewed",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "Has interviewed the customer face to face?",
        "masterData": true,
        "conditionalVarName": "customerFamily",
        "conditionalVarValue": "5",
        "grid": 6,
    },
    {
        "varName": "customerFamilyIsThereAnyMatchToTheCustomer",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Is there any match to the customer name with the terrorist activity, funding in terrorist activities or listed person or entity on suspicion for Financing of proliferation of weapons of mass destruction under UN Security Council's different regulations and any listed person or banned entity by the Government of Bangladesh according to related laws, rules and circular?",
        "grid": 6,
    },
    {
        "varName": "regardingThisIssue",
        "type": "text",
        "label": "If Yes, Then Details of action taken regarding this issue:",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "customerFamilyIsThereAnyMatchToTheCustomer",
        "conditionalVarValue": "Yes",
    },
    //TP
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "type": "titleBold",
        "label": "Deposit",
        "grid": 3,
    },

    {
        "type": "titleBold",
        "label": "No. of Deposit (Monthly)",
        "grid": 3,
    },
    {
        "type": "titleBold",
        "label": "Total Deposit Amount (Monthly)",
        "grid": 3,
    },
    {
        "type": "titleBold",
        "label": "Maximum Amount of Deposit (Per Transaction)",
        "grid": 3,
    },
    {
        "type": "straightLine",
        "grid": 12,
    },
    //
    {
        "type": "titleBlack",
        "label": "Cash Deposit (Including Online/ATM Deposit)",
        "grid": 3,
    },
    {
        "varName": "cashDepositNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "cashDepositTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "cashDepositMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Deposit by Transfer/Instrument",
        "grid": 3,
    },
    {
        "varName": "depositbyNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositbyTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositbyMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Foreign Inward Remittance",
        "grid": 3,
    },
    {
        "varName": "foreignInwardNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "foreignInwardTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "foreignInwardMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Deposit of Income from Export",
        "grid": 3,
    },
    {
        "varName": "depositofIncomeNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositofIncomeTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositofIncomeMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Deposit/Transfer from BO Account",
        "grid": 3,
    },
    {
        "varName": "depositTransferNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositTransferTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositTransferMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "others",
        "grid": 3,
    },
    {
        "varName": "othersNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "othersTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "othersMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Total Probable Deposit",
        "grid": 3,
    },
    {
        "varName": "totalProbableNumberOfCash",
        "type": "text",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "totalProbableNumberOfCashApiCall",
        "conditionalVarValue": true,
        "grid": 3,
    },
    {
        "varName": "totalProbableTotalCash",
        "type": "text",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "totalProbableTotalCashApiCall",
        "conditionalVarValue": true,
        "grid": 3,
    },
    {
        "varName": "totalProbableMaximumCash",
        "type": "text",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "totalProbableMaximumCashApiCall",
        "conditionalVarValue": true,
        "grid": 3,
    },
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "type": "titleBold",
        "label": "Withdrawal",
        "grid": 3,
    },

    {
        "type": "titleBold",
        "label": "No. of Withdrawal (Monthly)",
        "grid": 3,
    },
    {
        "type": "titleBold",
        "label": "Total Withdrawal Amount (Monthly)",
        "grid": 3,
    },
    {
        "type": "titleBold",
        "label": "Maximum Amount of Withdrawal (Per Transaction)",
        "grid": 3,
    },
    {
        "type": "straightLine",
        "grid": 12,
    },
    //
    {
        "type": "titleBlack",
        "label": "Cash Withdrawal (Including Online/ATM Withdrawal)",
        "grid": 3,
    },

    {
        "varName": "cashWithdrawalNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "cashWithdrawalTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "cashWithdrawalmaximumAmount",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Withdrawal through Transfer/Instrument",
        "grid": 3,
    },

    {
        "varName": "withdrawalthroughNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "withdrawalthroughTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "withdrawalthroughmaximumAmount",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Foreign Outward Remittance",
        "grid": 3,
    },

    {
        "varName": "foreignOutwardNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "foreignOutwardTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "foreignOutwardmaximumAmount",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Payment Against Import",
        "grid": 3,
    },

    {
        "varName": "paymentAgainstNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "paymentAgainstTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "paymentAgainstmaximumAmount",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Deposit/Transfer to BO Account",
        "grid": 3,
    },

    {
        "varName": "depositTransferNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositTransferTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositTransfermaximumAmount",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "others",
        "grid": 3,
    },

    {
        "varName": "othersNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "othersTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "othersmaximumAmount",
        "type": "text",
        "grid": 3,
    },
    {
        "type": "empty",
        "grid": 12,
    },

    //
    {
        "type": "titleBlack",
        "label": "Total Probable Withdrawal",
        "grid": 3,
    },

    {
        "varName": "totalProbableNumberofWithdrawal",
        "type": "text",
        "conditional": true,
        "conditionalVarName": "totalProbableNumberofWithdrawalApiCall",
        "conditionalVarValue": true,
        "readOnly": true,
        "grid": 3,
    },
    {
        "varName": "totalProbableTotalWithdrawal",
        "type": "text",
        "conditional": true,
        "conditionalVarName": "totalProbableTotalWithdrawalApiCall",
        "conditionalVarValue": true,
        "readOnly": true,
        "grid": 3,
    },
    {
        "varName": "totalProbablemaximumAmount",
        "type": "text",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "totalProbablemaximumAmountApiCall",
        "conditionalVarValue": true,
        "grid": 3,
    },
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "varName": "yearlyPropableTransaction",
        "type": "text",
        "label": "Yearly Propable Transaction",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "totalProbableTotalWithdrawalApiCall",
        "conditionalVarValue": true,
        "readOnly":true
    },
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "varName": "autoPopulationSummation",
        "type": "submit",
        "event": false,
        "label": "Auto Population Summation",
        "conditional": true,
        "conditionalVarName": "makerEndTpAndKyc",
        "conditionalVarValue": true,
        "grid": 6,

    },
    {
        "type": "empty",
        "grid": 12,
    },

    {
        "varName": "riskRatingcalculation",
        "type": "submit",
        "event": false,
        "label": "Risk Rating calculation",
        "conditional": true,
        "conditionalVarName": "makerEndTpAndKyc",
        "conditionalVarValue": true,
        "grid": 6,

    },
    {
        "type": "empty",
        "grid": 6,

    },
    {
        "type": "title",
        "label": "Risk Rating",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
    },
    {
        "type": "title",
        "label": "Product/Service and Channel Risk",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
    },
    {
        "varName": "typeProductService",
        "type": "autoCompleteValueReturn",
        "enum": [
            /*  {
                  "masterDataName": "accountType",
                  "key": "Savings A/C",
                  "value": "Savings A/C Risk-(1)"
              },
              {
                  "masterDataName": "accountType",
                  "key": "Current A/C",
                  "value": "Current A/C Risk-(4)"
              },
              {
                  "masterDataName": "accountType",
                  "key": "Foreign Currency A/C",
                  "value": "Foreign Currency A/C Risk-(5)"
              },
              {
                  "masterDataName": "accountType",
                  "key": "SND A/C",
                  "value": "SND A/C Risk-(3)"
              },
              {
                  "masterDataName": "accountType",
                  "key": "RFCD A/C",
                  "value": "RFCD A/C Risk-(5)"
              }*/
        ],
        "readOnly":true,
        "label": "Type of Product/Service",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "required": true,
    },
    {
        "varName": "typeOnboardingAccountOpening",
        "type": "autoCompleteValueReturn",
        "enum": [

        ],
        "label": "Type of Onboarding/Account Opening",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "required": true,
    },
    {
        "type": "title",
        "label": "Geographical Risk",
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "grid": 12,
    },
    {
        "varName": "nonResidentCustomerRisk",
        "type": "autoCompleteValueReturn",
        "enum": [

        ],
        "label": "Non-Resident Customer Risk",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "required": true,
    },
    {
        "varName": "forForeignNationals",
        "type": "autoCompleteValueReturn",
        "enum": [

        ],
        "label": "For Foreign Resident",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "nonResidentCustomerRisk",
        "conditionalVarValue": "3",
    },
    {
        "type": "title",
        "label": "Relationship Risk",
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "grid": 12,
    },
    {
        "varName": "accordingBfiuCustomer",
        "type": "autoCompleteValueReturn",
        "enum": [

        ],
        "label": " According to BFIU Circular, whether the Customer is a PEP/IP/ Head or high officials of intl. org.? ",
        "grid": 6,
        "readOnly":true,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "required": true,
    },
    {
        "varName": "accordingBfiuFamily",
        "type": "autoCompleteValueReturn",
        "enum": [

        ],
        "label": "According to the BFIU Circular, whether the Customer is a family member or close associate of PEP/IP/ Head or high officials of intl. org.?",
        "grid": 6,
        "readOnly":true,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "required": true,
    },
    {
        "type": "title",
        "label": "Transactional Risk",
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "grid": 12,
    },
    {
        "varName": "averageAmountOfYearlyTransactions",
        "type": "text",
        "label": "Average Amount of Yearly Transactions (in TK)",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
    },
    {
        "type": "title",
        "label": "Transparency Risk",
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "grid": 12,
    },
    {
        "varName": "customerProvidedReliableInformation",
        "type": "autoCompleteValueReturn",
        "enum": [

        ],
        "label": "Has the customer provided reliable information on the source of fund?",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
    },
    {
        "type": "title",
        "label": "Business or Service Related Risk ",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
    },
    {
        "varName": "selectFromTheList",
        "type": "autoCompleteValueReturn",
        "enum": [


        ],
        "label": "Select from the list",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
    },
    {
        "varName": "selectFromTheListOther",
        "type": "text",
        "label": "Other",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "selectFromTheList",
        "conditionalVarValue": "Others",
    },
    {
        "varName": "selectFromTheListNumeric",
        "type": "text",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "selectFromTheList",
        "conditionalVarValue": "Others",
    },
    {
        "varName": "listBusinessService",
        "type": "autoCompleteValueReturn",
        "enum": [


        ],
        "label": "Add from the list for more business or service",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        // "required": true,
    }, {
        "varName": "listBusinessServiceOther",
        "type": "text",
        "label": "Other",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "listBusinessService",
        "conditionalVarValue": "Others",
    },
    {
        "varName": "listBusinessService1",
        "type": "autoCompleteValueReturn",
        "enum": [


        ],
        "label": "Add from the list for more business or service",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        // "required": true,
    }, {
        "varName": "listBusinessService2",
        "type": "autoCompleteValueReturn",
        "enum": [


        ],
        "label": "Add from the list for more business or service",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        // "required": true,
    },

    {
        "varName": "overallRiskAssesment",
        "type": "text",
        "readOnly": true,
        "label": "Overall Risk Assesment",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "overallRiskFieldShow",
        "conditionalVarValue": true,

    },
    {
        "varName": "riskType",
        "type": "text",
        "readOnly": true,
        "label": "Risk Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "overallRiskFieldShow",
        "conditionalVarValue": true,

    },
    {
        "varName": "comments",
        "type": "text",
        "label": "Comments",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,

    },
];
let MAKERJsonFormForCasaIndividual = [
    {
        "type": "title",
        "label": "MIS RELATED",
        "grid": 12,
    },
    {
        "varName": "missolId",
        "type": "text",
        "label": "SOL ID",
        "readOnly":true,
        "grid": 6,
    },

    /*  {
          "varName": "misprimarySolId",
          "type": "text",
          "label": "Primary Sol ID",
         "grid": 6,
        "required": true,
      },*/

    {
        "varName": "misagentBankingOutletFreeCode1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Agent Banking Outlet/ Free Code 1",
        "grid": 6,
    },

    {
        "varName": "miscustomerIdCifId",
        "type": "text",
        //"required": true,
        "label": "Customer ID/CIF ID",
        "grid": 6,
        "readOnly": true,
        // "required": true,
    },

    {
        "varName": "misaCNumber",
        "type": "text",
        //"required": true,
        "label": "A/C Number",
        //misaCNumber=inputData.accountNumber
        "grid": 6,
        "readOnly": true,
        //"required": true,
    },

    {
        "varName": "mistitle",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //mistitle=inputData.customerTitle
        "label": "Title",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "miscustomerNameLastName",
        "type": "text",
        "required": true,
        "validationB":"length",
        "maxLength":80,
        //inputData.miscustomerNameLastName=inputData.customerName;
        "label": "Customer Name/Last Name",
        "grid": 6,
    },

    {
        "varName": "misshortName",
        "type": "text",
        "required": true,
        "validationB":"length",
        "maxLength":10,
        //inputData.misshortName=inputData.shortName;

        "label": "Short Name",
        "grid": 6,
    },



    {
        "varName": "miscustomerStatusNormalStaffPriorityEtc",
        "type": "autoCompleteValueReturn",
        "enum": [

        ],
        "label": "Customer Status",
        //inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.customerStatus
        "grid": 6,
        "required": true,
    },

    {
        "varName": "misoccupationCodePriorityCodeStaff",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 6,
        "required":true,
        //
        /*  if(this.state.inputData.occupationCodeNormal!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodeNormal
      }
      else if(this.state.inputData.occupationCodePriority!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodePriority
      }
      else if(this.state.inputData.occupationCodeStaff!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodeStaff
      }*/
    },

    {
        "varName": "misconstitution",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //master data cbl provide
        "label": "Constitution",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "misstaffIndicatorStatus",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "label": "Staff Indicator/Status",
        //inputData.misstaffIndicatorStatus=inputData.staffIndicator
        "grid": 6,
        /*   "masterData": true,
           "conditionalVarName": "miscustomerStatusNormalStaffPriorityEtc",
           "conditionalVarValue": "STAFF",*/


    },

    {
        "varName": "misstaffIdNumber",
        "type": "text",
        "label": "Staff ID Number",
        "validation":"staffId",
        //inputData.misstaffIdNumber=inputData.staffIdNumber
        "grid": 6,
        /* "masterData": true,
         "conditionalVarName": "miscustomerStatusNormalStaffPriorityEtc",
         "conditionalVarValue": "STAFF",*/
    },

    {
        "varName": "mispriorityCenterCodeFreeCode1",
        "type": "select",
        "enum": [
           "PR104",
            "PR142",
            "PR177",
            "PR186",
            "PR187",
            "PR195",
            "PR208",
            "PR169",
        ],
        //inputData.mispriorityCenterCodeFreeCode1=inputData.priorityCenterCode;
        "label": "Priority Center Code/Free Code 1",
        "grid": 6,
    },

    {
        "varName": "mispriorityMonitoringRmCodeFreeCode3",
        "type": "textApiCall",
        "enum": [],
        //inputData.mispriorityMonitoringRmCodeFreeCode3=inputData.monitoringRMCode
        "label": "Priority Monitoring  RM Code",
        "grid": 6,
    },
    {
        "varName": "mispriorityMonitoringRmCodeFreeCode3Name",
        "type": "text",
        //inputData.mispriorityMonitoringRmCodeFreeCode3=inputData.monitoringRMCode
        "label": "Priority Monitoring  RM Code Name",
        "readOnly":true,
        "conditional": true,
        "conditionalVarName": "mispriorityMonitoringRmCodeFreeCode3ApiCall",
        "conditionalVarValue": true,
        "grid": 6,
    },

    {
        "varName": "misprimaryCbHolderForPriorityFreeText8",
        "type": "text",
        //inputData.misprimaryCbHolderForPriorityFreeText8=inputData.primaryCBHolderforPriority
        "label": "Primary CB Holder for Priority",
        "grid": 6,
    },

    {
        "varName": "misrelationshipWithPrimaryCbHolderFreeText9",
        "type": "text",
        //inputData.misrelationshipWithPrimaryCbHolderFreeText9=inputData.relationshipwithPrimaryCBHolder;
        "label": "Relationship with Primary CB Holder",
        "grid": 6,
    },

    {
        "varName": "misfunctionCodeForChequeBookWaiver",
        "type": "select",
        "enum": [
            "A",
            "M",
        ],
        //inputData.misfunctionCodeForChequeBookWaiver=inputData.functionCodeforChequeBookWaiver
        "label": "Function Code for Cheque Book Waiver",
        "grid": 6,
        /*   "conditional": true,
           "conditionalVarName": "customerStatus",
           "conditionalVarValue": "Priority",*/
    },

    {
        "varName": "miswaiverTypeForChequeBookWaiver",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.waiverTypeforChequeBookWaiver=inputData.functionCodeforChequeBookWaiver
        "label": "Waiver Type  for Cheque Book Waiver",
        "grid": 6,
        /*  "conditional": true,
          "conditionalVarName": "customerStatus",
          "conditionalVarValue": "Priority",*/
    },

    {
        "varName": "misbbkSegmentCodeFreeCode7",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "BBK Segment Code",
        "grid": 6,
        "required": true,
    },


    {
        "varName": "mismigratedNidVerificationFreeText2",
        "type": "text",
        "label": "Migrated NID Verification",
        "grid": 6,
    },

    {
        "varName": "mismigratedSmartCardNumberFreeText5",
        "type": "text",
        "label": "Migrated Smart Card Number",
        "grid": 6,
    },

    {
        "varName": "mispepStatusFreeText10",
        "type": "text",
        "label": "PEP Status",
        "grid": 6,
    },

    {
        "varName": "mismigratedCifsDocumentNoFreeText13",
        "type": "text",
        "label": "Migrated CIFs Document No",
        "grid": 6,
    },

    {
        "varName": "miswaiverFieldFreeCode10",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.miswaiverFieldFreeCode10=inputData.waiverField
        "label": "Waiver Field",
        "grid": 6,
    },

    {
        "varName": "misccepCodeFreeCode9",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.misccepCodeFreeCode9=inputData.ccepCode
        "label": "CCEP Code",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText1",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText2",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText3",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misavailedTradeServices",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Availed Trade services",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Currency Code",
        "grid": 12,
    },

    {
        "varName": "aof1currencyCodeCcy",
        "type": "text",
        //inputData.aof1currencyCodeCcy=inputData.currency
        "label": "Currency Code/CCY",
        "grid": 6,
        "readOnly":true,
    },
    {
        "type": "title",
        "label": "Contact Number - Email",
        "grid": 12,
    },
    {
        "varName": "contactNumberEmailpreferedEmailIdType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "COMMEML",
            "value": "COMMEML"
        }],
        "readOnly":true,
        "label": "Prefered Email ID type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailType1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "COMMEML",
            "value": "COMMEML"
        }],
        "label": "Email Type",
        "readOnly":true,
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId4",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "EMAIL",
            "value": "EMAIL"
        }],
        "label": "Phone No./Email ID",
        "readOnly":true,
        "grid": 6,
    },

    {
        "varName": "contactNumberEmaildesignatedEmailAddressEmailId1",
        "type": "text",
        "label": "Designated Email Address/Email ID 1",
         "validation": "email",
        "grid": 6,
    },
    {
        "varName": "contactNumberEmaildesignatedEmailAddressEmailId1Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,


    },
    {
        "type": "title",
        "label": "Contact Number - Phone",
        "grid": 12,
    },
    {
        "varName": "contactNumberpreferredContactNoType1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH1",
            "value": "COMMPH1"
        }],
        "label": "Preferred Contact no type",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 1",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType1",
        "type": "autoCompleteValueReturn",
        "enum": [ {
            "masterDataName": "contactType",
            "key": "COMMPH1",
            "value": "COMMPH1"
        }],
        "label": "Contact Type",
        "grid": 6,
        "readOnly":true,
    },

    {
        "varName": "contactNumberphoneNoEmailId1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No./Email ID",
        "grid": 6,
        "readOnly":true,
    },
    {
        "varName": "mobileTypecontactNumberphoneNo1",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required":true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType1",
        "conditionalVarValue": "COMMPH1",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Overseas",
        "grid":6,
    },

    {
        "varName": "contactNumberphoneNo1",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo1",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Overseas",

    },

    {
        "type": "empty",
        "grid": 12,
    },

    {
        "varName": "aof1despatchModePrintedEStatementBoth",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required":true,
        "label": "Despatch Mode",
        "grid": 6,
    },
    {
        "varName": "aof1smsAlertFreeText11",
        "type": "select",
        "enum":[
            "Y",
            "N",
            "AB",
            "CCEP",
        ],
        "label": "SMS Alert",
        "grid": 6,
    },

    {
        "type": "title",
        "label": "Primary Introducer details",
        "grid": 12,
    },
    {
        "varName": "nidVerified",
        "type": "select",
        "enum":[
            "YES",
            "NO",
        ],
        "required": true,
        "label": "Is All NID/Smart Card Document Verified?",
        "grid": 6,

    },
    {
        "varName": "primryintroducerCustomerIdCifId",
        "type": "textApiCall",
        //inputData.primryintroducerCustomerIdCifId=inputData.introducerId
        "label": "Introducer Customer Id/CIF ID",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },


    {
        "varName": "primryiintroducerNameLastName",
        "type": "text",
        //inputData.primryiintroducerNameLastName=inputData.introducerName
        "label": "Introducer Name/Last Name",
        "grid": 6,
        "readOnly":true,
        "conditionalArray": true,
        "conditionalArrayList": ["introducerIdApiCall", "nidVerified"],
        "conditionalArrayValue": [true, "NO"]

    },

    {
        "varName": "primryiintroducerStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Introducer Status",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },

    {
        "varName": "primryicifTypePrimaryIntroducerDetails",
        "type": "select",
        "enum": [
            "Retail",
            "Corporate",
        ],
        "label": "CIF Type/Primary Introducer Details",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },
    {
        "type": "empty",
        "grid": 12,
    },





];
let MAKERJsonFormForCasaIndividualLast =[
    {
        "type": "empty",
        "grid": 12,
    },
    /* {
         "varName": "otherBankbankName",
         "type": "autoCompleteValueReturn",
         "enum": [],
         "label": "Bank Name",
         "grid": 6,
     },

     {
         "varName": "otherBankbranchName",
         "type": "autoCompleteValueReturn",
         "enum": [],
         "label": "Branch Name",
         "grid": 6,
     },*/
    {
        "varName": "schemeCodeChangemodeOfOperation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Mode of Operation",
        "required":true,
        "grid": 6,
    },
    {
        "type": "title",
        "label": " Scheme Change for Insta Pack",
        "grid": 12,
    },


    {
        "varName": "schemeCodeChangefunctionForSchemeChangeInCaseOfInstaPack",
        "type": "select",
        "enum": [
            "T"
        ],
        "readOnly":true,
        "label": "Function for Scheme Change in case of Insta Pack",
        "grid": 6,

    },
    {
        "varName": "upDateSchemeCode",
        "type": "text",
        "readOnly":true,
        "label": "Current Scheme Code",
        "grid": 6,

    },

    {
        "varName": "schemeCodeChangetargetSchemeCodeInCaseOfInstaPack",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Target Scheme Code in case of Insta Pack",
        "grid": 6,
    },

    {
        "varName": "schemeCodeChangetrialMode",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Trial Mode",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "CRM MIS",
        "grid": 12,
    },


    {
        "varName": "crmMissubSegment",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Segment",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Basic Information Update",
        "grid": 12,
    },
    {
        "varName": "basicInformationUpdateprimarySolId",
        "type": "text",
        "enum": [],
        "readOnly":true,
        "label": "Primary Sol ID",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdategender",
        "type": "select",
        "enum": [
            "M",
            "F",
            "TRANSGENDER",
            "O"
        ],
        "label": "Gender",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdatefatherNamePrefferedName",
        "type": "text",
        "label": "Father Name/Preffered Name",
        "validationB":"length",
        "maxLength":50,
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdatemotherNameMothersMaidenName",
        "type": "text",
        "label": "Mother Name/Mothers Maiden Name",
        "validationB":"length",
        "maxLength":50,
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdatemaritalStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Marital Status",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "basicInformationUpdatespouseNamePurgeRemarks",
        "type": "text",
        "label": "Spouse Name/Purge Remarks",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "basicInformationUpdatemaritalStatus",
        "conditionalVarValue":"MARID",
        "validationB":"length",
        "maxLength":240,

    },

    {
        "varName": "basicInformationUpdatedob",
        "type": "date",
        "maxDate": true,
        "onKeyDown": true,
        //inputData.basicInformationUpdatedob=inputData.gender
        "label": "DOB",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdateminorStatusMinorIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "readOnly":true,
        "label": "Minor Status/Minor Indicator",
        "conditional": true,
        "conditionalVarName": "basicInformationUpdatedobApiCall",
        "conditionalVarValue": "YES",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateseniorCitizenIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "readOnly":true,
        "label": "Senior Citizen Indicator",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdatedobApiCall",
        "conditionalVarValue": "YES",
        "required": true,
    },
    {
        "type": "title",
        "label": "Gurdian Details - Only Visible In Case Minor Account Holder",
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",

        "grid": 12,
    },


    {
        "varName": "guardianDetailsrelation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation *",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsbankRelationType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Bank Relation Type *",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsrelation1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation *",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsguardianSelectionGurdian",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Guardian Selection/Gurdian",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsgurdianCifNumberCustomer",
        "type": "textApiCall",
        "label": "Gurdian CIF Number/Customer *",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailslastName",
        "type": "text",
        "enum": [],
        "label": "Last Name *",
        "grid": 6,
        "readOnly":true,
        "conditional": true,
        "conditionalVarName": "guardianDetailsgurdianCifNumberCustomerApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "type": "title",
        "label": "National ID Card",
        "grid": 12,
    },
    {
        "varName": "natioanlIdCarddocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "natioanlIdCarddocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "natioanlIdCarddocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "NID",
            "value": "NID CARD IDENTITY"
        }],
        "label": "Document Code ",
        "grid": 6,

    },

    {
        "varName": "nationalIdCardNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "National ID Card Number/Unique ID",
        "grid": 6,
        "validation": "nid",
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "varName": "natioanlIdCardnidVerificationConfirmationIsDocumentVerified1",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "varName": "natioanlIdCardissueDateN",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "varName": "natioanlIdCardDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },
    {
        "type": "title",
        "label": "Smart ID Card",
        "grid": 12,
    },
    {
        "varName": "smartIdCarddocumentTypeS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "smartIdCarddocumentTypeDescriptionS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "smartIdCarddocumentCodeS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "RSNID",
            "value": "RSNID"
        }],
        "label": "Document Code ",
        "grid": 6,
        //"required": true,
    },

    {
        "varName": "smartCardNumberUniqueId",
        "type": "text",
        "label": "Smart Card Number/Unique ID",
        "grid": 6,
        //"required": true,
        "validation": "smartCard",
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"

    },

    {
        "varName": "smartIdCardnidVerificationConfirmationIsDocumentVerified",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },

    {
        "varName": "smartIdCardissueDateS",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },
    {
        "varName": "smartIdCardDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },
    {
        "type": "title",
        "label": "Passport Number",
        "grid": 12,
    },
    {
        "varName": "passportNumberdocumentTypeP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "passportNumberdocumentTypeDescriptionP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        //inputData.passportNumberdocumentTypeDescriptionP=inputData.passport
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "passportNumberdocumentCodeP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "PASSPORT",
            "value": "PASSPORT IDENTITY"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "passportNumberpassportNumberUniqueId",
        "type": "textApiCall",
        "label": "Passport Number/Unique ID",
        "grid": 6,
        "validation": "passport",
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberplaceOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Place of Issue",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberissueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumbercountryOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country of Issue",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberexpiryDate",
        "type": "date",
        "label": "Expiry Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },
    {
        "varName": "passportNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },
    {
        "type": "title",
        "label": "Driving License",
        "grid": 12,
    },
    {
        "varName": "drivingLicensedocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "drivingLicensedocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "drivingLicensedocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName":"documentCode",
            "key":"DL",
            "value":"DRIVING LICENSE"
        },],
        "label": "Document Code",
        "grid": 6,

    },

    {
        "varName": "drivingLicenseNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "Driving ID Card Number/Unique ID",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "drivingLicensedocumentCodeN",
        "conditionalVarValue": "DL"
    },
    {
        "varName": "drivingLicenseNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "drivingLicensedocumentCodeN",
        "conditionalVarValue": "DL"
    },

    {
        "type": "title",
        "label": "Resident ID",
        "grid": 12,
    },
    {
        "varName": "residentdocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "residentdocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "residentdocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName":"documentCode",
            "key":"RESID",
            "value":"RESIDENT ID CARD"
        },],
        "label": "Document Code ",
        "grid": 6,

    },

    {
        "varName": "residentNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "Resident ID",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "residentdocumentCodeN",
        "conditionalVarValue": "RESID"
    },
    {
        "varName": "residentNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "residentdocumentCodeN",
        "conditionalVarValue": "RESID"
    },
    {
        "type": "title",
        "label": "Birth Certificate",
        "grid": 12,
    },
    {
        "varName": "birthCertificatedocumentTypeB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "birthCertificatedocumentTypeDescriptionB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "birthCertificatedocumentCodeB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "BC",
            "value": "BIRTH CERTIFICATE"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "birthCertificatebirthCertificateNumberUniqueId",
        "type": "text",
        "label": "Birth Certificate Number/Unique ID",
        //inputData.birthCertificatebirthCertificateNumberUniqueId=inputData.birthCertificateNo
        "grid": 6,
        "validation": "birthCertificateNumber",
        "required": true,
        "masterData": true,
        "conditionalVarName": "birthCertificatedocumentCodeB",
        "conditionalVarValue": "BC"
    },
    {
        "varName": "birthCertificateDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "birthCertificatedocumentCodeB",
        "conditionalVarValue": "BC"
    },
    {
        "type": "title",
        "label": "Chairman Certificate",
        "grid": 12,
    },
    {
        "varName": "chairmanCertificatedocumentTypeCC",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "chairmanCertificatedocumentTypeDescriptionCC",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "chairmanCertificatedocumentCodeCC",
        "type": "autoCompleteValueReturn",
        "enum": [{"masterDataName":"documentCode","key":"CHAC","value":"CHAIRMAN CERTIFICATE"}],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificateUniqueId",
        "type": "text",
        "label": "Chairman Certificate/Unique ID",
        "grid": 6,
        "validationB":"length",
        "maxLength":20,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatedocumentCodeCC",
        "conditionalVarValue": "CHAC"
    },

    {
        "varName": "chairmanCertificateDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatedocumentCodeCC",
        "conditionalVarValue": "CHAC"
    },


    {
        "type": "title",
        "label": "Other Photo ID",
        "grid": 12,
    },


    {
        "varName": "photoIddocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "photoIddocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "photoIddocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "photoIdUniqueId",
        "type": "text",
        "label": "Unique ID",
        "grid": 6,
    },
    {
        "varName": "photoIdDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
    },
    {
        "type": "empty",
        "grid":12,

    },
    {
        "varName": "chairmanCertificateplaceOfBirthFreeText14",
        "type": "text",
        "label": "Place of Birth/Free Text 14",
        "maxLength":15,
        "validationB":"length",
        "grid": 6,

    },

    {
        "varName": "chairmanCertificatecountryOfBirthFreeText15",
        "type": "text",
        "label": "Country of Birth/Free Text 15",
        "grid": 6,

    },

    {
        "varName": "Customersnationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Nationality",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "chairmanCertificatenonResident",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Non Resident",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "chairmanCertificateturnedNonResidentOn",
        "type": "date",
        "label": "Turned Non Resident On",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },
    {
        "type": "title",
        "label": "E-Tin",
        "grid": 12,
    },
    {
        "varName": "etindocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "etindocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required":true,
    },

    {
        "varName": "etindocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "etineTinNumber",
        "type": "text",
        //inputData.etineTinNumber=inputData.eTin
        "label": "E-Tin Number/Unique ID",
        "grid": 6,
        "etinBlock":true,
        "validation": "etin",
        "required": true,
        "masterData": true,
        "conditionalVarName": "etindocumentCode",
        "conditionalVarValue": "ETIN"
    },
     {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 6,
          "required":true,
          "masterData": true,
         "conditionalVarName": "etindocumentCode",
         "conditionalVarValue": "ETIN"
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "etindocumentCode",
        "conditionalVarValue": "ETIN"
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditional": true,
        "conditionalVarName": "exceptionSubmission",
        "conditionalVarValue": "Y",
        "grid":6,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "required":true,
        "conditional": true,
        "conditionalVarName": "exceptionSubmission",
        "conditionalVarValue": "N",
        "label": "Tax Submission Year",
        "grid": 6,
    },
    {
        "varName":"etinDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "etindocumentCode",
        "conditionalVarValue": "ETIN"
    },
    {
        "type": "title",
        "label": "Employer Data",
        "grid": 12,
    },
    {
        "varName": "employerDataprofessionEmploymentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "employmentType",
            "key": "MEDIA CELEBRITY",
            "value": "MEDIA CELEBRITY"
        }],
        "label": "Profession /Employment Type",
        "grid": 6,
    },



    {
        "varName": "employerDatanameOfEmployerEmployerName",
        "type": "text",
        "label": "Name of Employer/Employer Name",
        "validationB":"length",
        "maxLength": 50,
        "grid": 6,
    },

    {
        "varName": "employerDatamonthlyIncomeGrossIncome",
        "type": "text",
        "label": "Monthly Income/Gross income",
        "grid": 6,
        "required": true,
    },

    {
        "type": "title",
        "label": "Contact Number - Phone 2",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType2",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH2",
            "value": "COMMPH2"
        }],
        "label": "Contact Type",
        "grid": 6,

    },

    {
        "varName": "contactNumberphoneNoEmailId2",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No.",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2"
    },
    {
        "varName": "mobileTypecontactNumberphoneNo2",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required":true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Overseas",
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo2",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo2",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Overseas",

    },
    {
        "varName": "contactNumber2Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2"
    },

    {
        "type": "title",
        "label": "Contact Number - Phone 3",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType3",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "WORKPH1",
            "value": "WORKPH1"
        }],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNoEmailId3",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No.",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1"
    },

    /*  {
          "varName": "contactNumberphoneNo3",
          "type": "text",
          "validation": "numeric",
          "label": "Phone No",
          "grid": 6,
          "required": true,
          "masterData": true,
          "conditionalVarName": "contactNumbercontactType3",
          "conditionalVarValue": "WORKPH1"
      },*/

    {
        "varName": "mobileTypecontactNumbercontactType3",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required":true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo3",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Overseas",
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo3",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo3",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Overseas",

    },
    {
        "varName": "contactNumber3Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1"
    },

    {
        "type": "title",
        "label": "Contact Number - Email",
        "grid": 12,
    },

    {
        "varName": "contactNumberEmailemailType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "HOMEEML",
            "value": "HOMEEML"
        }],
        "required":true,
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "EMAIL",
            "value": "EMAIL"
        }],
        "label": "Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailId2",
        "type": "text",
        "label": "Email ID 2",
        "validation": "email",
        "grid": 6,
    },
    {
        "varName": "contactNumberEmailemailId2Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "required":true,
        "label": "Delete Flag",
        "grid": 6,
    },

    {
        "type": "title",
        "label": "Mailing Address",
        "grid": 12,
    },
    {
        "varName": "maillingAdresspreferredAddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        //inputData.maillingAdresspreferredAddressType=inputData.SelectCommunicationAddress

        "label": "Preferred Address type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        "label": "Addrss type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressmailingAddressField1",
        "type": "text",
        //inputData.maillingAdressmailingAddressField1=inputData.communicationAddress1
        "label": "Mailing Address Field 1",
        "validationB":"length",
        "maxLength":45,
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressmailingAddressField2",
        "type": "text",
        //inputData.maillingAdressmailingAddressField2=inputData.communicationAddress2
        "label": "Mailing Address Field 2",
        "validationB":"length",
        "maxLength":45,
        "grid": 6,
    },

    {
        "varName": "maillingAdresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdresscity=inputData.city2

        "label": "City",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdressstate=inputData.state2

        "label": "Division(State)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdresspostalCode",
        "type": "text",
        "label": "Postal Code",
        "validationB":"length",
        "maxLength":6,
        //inputData.maillingAdresspostalCode=inputData.postalCode3
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdresscountry=inputData.nationality
        "label": "Country",
        "grid": 6,
        "required": true,
    },

    {
        "type": "title",
        "label": "Home Address",
        "grid": 12,
    },
    {
        "varName": "homeAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "required":true,
        "label": "Address Format",
        "grid": 6,

    },

    {
        "varName": "homeAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Home",
            "value": "Home"
        }],
        "onKeyDown": true,
        "label": "Address type",
        "grid": 6,

    },

    {
        "varName": "homeAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Home",
            "value": "Home"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,

    },

    {
        "varName": "homeAddresshomeAddressField1",
        "type": "text",
        "label": "Home Address Field 1",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "required":true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresshomeAddressField2",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],

        "label": "City",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresspostalCode",
        "type": "text",
        "validationB":"length",
        "maxLength":6,
        "label": "Postal Code",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },
    {
        "varName": "homeAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },
    {
        "type": "title",
        "label": "Work Address",
        "grid": 12,
    },
    {
        "varName": "workAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "workAddressaddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Work",
            "value": "Work"
        }],
        "label": "Address type",
        "grid": 6,
    },

    {
        "varName": "workAddressaddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Work",
            "value": "Work"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "workAddressworkAddressField1",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "required":true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddressworkAddressField2",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresspostalCode",
        "type": "text",
        "validationB":"length",
        "maxLength":6,
        "label": "Postal Code",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "masterData": true,
        "required":true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },
    {
        "varName": "workAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },
    //1present address
    {
        "type": "title",
        "label": "Present Address",
        "grid": 12,
    },
    {
        "varName": "presentAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "required":true,
        "grid": 6,
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Future/OnSite",
            "value": "Future/OnSite"
        }],
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [ {
            "masterDataName": "addressType",
            "key": "Future/OnSite",
            "value": "Future/OnSite"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "presentAddresspresentAddressField1",
        "type": "text",
        "label": "Present Address Field 1",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "required":true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"

    },

    {
        "varName": "presentAddresspresentAddressField2",
        "type": "text",
        "label": "Present Address Field 2",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresspostalCode",
        "type": "text",
        "validationB":"length",
        "maxLength":6,
        "label": "Postal Code",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },
    {
        "varName": "presentAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    //1NRE address
    {
        "type": "title",
        "label": "NRE Address",
        "grid": 12,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
    },
    {
        "varName": "nreAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
        "grid": 6,
    },

    {
        "varName": "nreAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [ {"masterDataName": "addressType",
            "key": "NRERelative",
            "value": "NRERelative"
        }],
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "nreAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [ {"masterDataName": "addressType",
            "key": "NRERelative",
            "value": "NRERelative"
        }],
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "nreAddressnreAddressField1",
        "type": "text",
        "label": "NRE Address Field 1",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "required":true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"

    },

    {
        "varName": "nreAddressnreAddressField2",
        "type": "text",
        "label": "NRE Address Field 2",
        "grid": 6,
        "validationB":"length",
        "maxLength":45,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresspostalCode",
        "type": "text",
        "validationB":"length",
        "maxLength":6,
        "label": "Postal Code",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"

    },
    {
        "varName": "nreAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },


    {
        "type": "title",
        "label": "Fincore Data Update - Account Level",
        "grid": 12,
    },
    /* {
         "varName": "fincoreDataUpdateaCReportCode",
         "type": "text",
         "label": "A/C Report Code",
        "grid": 6,
        "required": true,
     },*/

    /* {
         "varName": "fincoreDataUpdatecustomerRelationshipACManager",
         "type": "text",
         "label": "Customer Relationship A/C Manager",
        "grid": 6,
        "required": true,
     },*/

    /* {
         "varName": "fincoreDataUpdateaCManagerId",
         "type": "text",
         "label": "A/C Manager ID",
        "grid": 6,
        "required": true,
     },*/

    /* {
         "varName": "fincoreDataUpdatecontactPhoneNo",
         "type": "text",
         "label": "Contact Phone No.",
        "grid": 6,
        "required": true,
     },*/

    {
        "varName": "fincoreDataUpdatecashLimitDr",
        "type": "text",
        "validation":"numeric",
        "label": "Cash Limit (Dr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatecashLimitCr",
        "type": "text",
        "validation":"numeric",
        "label": "Cash Limit (Cr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdateclearingLimitDr",
        "type": "text",
        "validation":"numeric",
        "label": "Clearing Limit (Dr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdateclearingLimitCr",
        "type": "text",
        "validation":"numeric",
        "label": "Clearing Limit (Cr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatetransferLimitDr",
        "type": "text",
        "validation":"numeric",
        "label": "Transfer Limit (Dr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatetransferLimitCr",
        "type": "text",
        "validation":"numeric",
        "label": "Transfer Limit (Cr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatenotes",
        "type": "text",
        "label": "Notes",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdateaccountStatement",
        "type": "select",
        "enum": [
            "S-STATEMENT",
            "NONE",
        ],
        "label": "Account Statement",
        "grid": 6,
        "required": true,
    },

    {
        "type": "title",
        "label": "Statement Frequency",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdateaccountStatement",
        "conditionalVarValue":"S-STATEMENT",
    },
    {
        "varName": "fincoreDataUpdatestatementFrequency1",
        "type": "select",
        "enum": [
            "Half Yearly",
            "Yearly",
            "Quarterly",
            "Monthly",
        ],
        "grid": 3,
        "required":true,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdateaccountStatement",
        "conditionalVarValue":"S-STATEMENT",
    },
    {
        "varName": "fincoreDataUpdatestatementFrequency2",
        "type": "select",
        "enum": day,
        "grid": 3,
        "required":true,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdateaccountStatement",
        "conditionalVarValue":"S-STATEMENT",
    },
    {
        "varName": "fincoreDataUpdatestatementFrequency3",
        "type": "select",
        "enum": [
            "Next Day",
            "Holiday",
            "Previous Day",
            "Skip",
        ],
        "grid": 3,
        "required":true,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdateaccountStatement",
        "conditionalVarValue":  "S-STATEMENT",
    },

    /*{
        "varName": "fincoreDataUpdatedispatchMode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Dispatch Mode",
        "grid": 6,
    },*/

    /*  {
          "varName": "fincoreDataUpdatenextPrintDate",
          "type": "date",
          "label": "Next Print Date",
          "grid": 6,
      },*/

    /* {
         "varName": "fincoreDataUpdateinterestCreditAC",
         "type": "text",
         "label": "Interest Credit A/c.",
        "grid": 6,
        "required": true,
     },*/

    /*  {
          "varName": "fincoreDataUpdatewithholdingTaxBorneBy",
          "type": "text",
          "label": "Withholding Tax Borne By",
         "grid": 6,
        "required": true,
      },
  */
    /*  {
          "varName": "fincoreDataUpdatewithholdingTaxLevel",
          "type": "text",
          "label": "Withholding Tax Level",
         "grid": 6,
        "required": true,
      },*/

    /* {
         "varName": "fincoreDataUpdatewithholdingTaxPcnt",
         "type": "text",
         "label": "Withholding Tax Pcnt.",
        "grid": 6,
        "required": true,
     },*/

    {
        "varName": "fincoreDataUpdateaCStatus",
        "type": "autoCompleteValueReturn",
        "enum":[
            {
                "masterDataName": "accountStatus",
                "key": "A",
                "value": "Active"
            },
            {
                "masterDataName": "accountStatus",
                "key": "D",
                "value": "Dormant"
            },
            {
                "masterDataName": "accountStatus",
                "key": "I",
                "value": "Inactive"
            },
        ],
        "label": "A/C Status",
        "required":true,
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatedormantActivationCharge",
        "type": "select",
        "enum":[
            "Y",
            "N",
        ],
        "label": "Dormant Activation Charge",
        "grid": 6,
    },

    /* {
         "varName": "fincoreDataUpdatenomineeSelect",
         "type": "text",
         "label": "Nominee Select",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateaCInformation",
         "type": "text",
         "label": "A/c. Information ",
         "grid": 6,
     },
 */
    {
        "varName": "fincoreDataUpdatesectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "onKeyDown": true,
        "required":true,
        //inputData.fincoreDataUpdatesectorCode=inputData.nationality
        "label": "Sector Code",
        "grid": 6,
    },


    {
        "varName": "fincoreDataUpdatesubSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //subsector code
        "label": "Sub Sector Code",
        "required":true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "sectorCodeApicall",
        "conditionalVarValue": true,
    },

    {
        "varName": "fincoreDataUpdateoccupationCode",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3",
        "type": "textApiCall",
        //rm code
        "label": "RM Code/ Wealth Manager Code",
        "grid": 6,
    },
    {
        "varName": "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3RmName",
        "type": "text",
        //rm code
        "label": "RM Name",
        "grid": 6,
        "readOnly":true,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall",
        "conditionalVarValue": true,
    },

    {
        "varName": "fincoreDataUpdatefreeText3",
        "type": "text",
        "label": "Free Text 3 ",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCode6",
        "type": "text",
        "label": "Free Code 6",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatedepositCodeFreeCode7",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Deposit Code/Free Code 7",
        "required": true,
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCode8",
        "type": "text",
        "label": "Free Code 8",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeText10",
        "type": "text",
        "label": "Free Text 10",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCodeFreeText1",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCodeFreeText2",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCodeFreeText3",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    }


];
let CHECKERJsonFormForCasaIndividual = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForCasaIndividual)));
let CHECKERJsonFormForCasaIndividualLast = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForCasaIndividualLast.concat(TP).concat(KYC))));let CJson
let CHECKERJsonFormForCasaIndividualLastForRashed = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForCasaIndividualLast)));
//#####################CASA Exist Individual#####################################################
/*let CSJsonFormForCasaServiceExist = [
    {
        "varName": "priority",
        "type": "select",
        "label": "Priority",
        "enum": [
            "GENERAL",
            "HIGH",
        ],
        "grid": 2,
    },

    {
        "varName": "smsAlertRequest",
        "type": "checkbox",
        "label": "SMS Alert Request",


    },



    {
        "varName": "lockerFacility",
        "type": "checkbox",
        "label": "Locker Facility",


    },
    {
        "varName": "loanRequest",
        "type": "checkbox",
        "label": "Loan Request",

    },
    {
        "varName": "cityTouchRequest",
        "type": "checkbox",
        "label": "City Touch"

    },
    {
        "varName": "statementFacility",
        "type": "checkbox",
        "grid": 12,
        "label": "Statement",

    },

    {
        "varName": "printedStatement",
        "type": "checkbox",
        "label": "Printed Statement",
        "conditional": true,
        "conditionalVarName": "statementFacility",
        "conditionalVarValue": true,

    },
    {
        "varName": "eStatement",
        "type": "checkbox",
        "label": "E-Statement",
        "conditional": true,
        "conditionalVarName": "statementFacility",
        "conditionalVarValue": true,

    },
    {
        "varName": "statementNo",
        "type": "checkbox",
        "label": "NO",
        "conditional": true,
        "conditionalVarName": "statementFacility",
        "conditionalVarValue": true,

    },

    /!* {
         "varName": "cityTouchRequestEmail",
         "type": "text",
         "label": "Email",
         "grid":6,
         "conditional": true,
         "conditionalVarName": "dpsRequest",
         "conditionalVarValue": true,

     },*!/
    {
        "varName": "dpsRequest",
        "type": "checkbox",
        "label": "DPS Request",

        "grid": 12,

    },
    {
        "varName": "dpsAmount",
        "type": "text",
        "label": "Amount",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true,
    },
    {
        "varName": "dpsTenorType",
        "type": "select",
        "label": "Tenure Type",
        "grid": 2,
        "enum": [
            "Monthly",
        ],
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true,
    },
    {
        "varName": "dpsTenor",
        "type": "select",
        "enum": [],
        "label": "Tenure",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true,
    },

    {
        "varName": "dpsInterest",
        "type": "text",
        "label": "Interest",
        "readOnly": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true,
    },
    {
        "varName": "dpsSi",
        "type": "date",
        "label": "SI Date",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true,
    },
    {
        "varName": "fdrRequest",
        "type": "checkbox",
        "label": "FDR Request",
        "grid": 12
    },
    {
        "varName": "accountsType",
        "type": "select",
        "grid": 2,
        "enum": [
            "FDR",
           // "Double Money FD Account",
            "Monthly Interest Paying FD",
        ],
        "label": "Type Of Account",
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true,
    },

///// Account Type Double ///
    {
        "varName": "doubleTennor",
        "type": "select",
        "grid": 2,
        "enum": [
            "Monthly"
        ],
        "label": "Tenure Type",
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "Double Money FD Account"]

    },
    {
        "varName": "tennordDouble",
        "type": "select",
        "enum": [],
        "label": "Tenure",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "Double Money FD Account"]
    },
    {
        "varName": "doubleAmount",
        "type": "text",
        "label": "Amount",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "Double Money FD Account"]
    },
    {
        "varName": "schemeCode",
        "type": "text",
        "label": "Scheme Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true,

    },
    {
        "varName": "doubleInterest",
        "type": "text",
        "label": "Interest",
        "grid": 2,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "Double Money FD Account"]
    }, {
        "varName": "doubleSiMaturity",
        "type": "select",
        "grid": 2,
        "enum": [
            "No"
        ],
        "label": "Renewal / Maturity Instruction",
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "Double Money FD Account"]
    },


/////  Account Type Fix General /////
    {
        "varName": "fixGTennor",
        "type": "select",
        "grid": 2,
        "enum": [
            "Day",
            "Monthly"
        ],
        "label": "Tenure Type",
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "FDR"]
    },
    {
        "varName": "tennorFixG",
        "type": "select",
        "enum": [],
        "label": "Tenure",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "FDR"]
    },
    {
        "varName": "fixGAmount",
        "type": "text",
        "label": "Amount",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "FDR"]
    },
    {
        "varName": "fixGInterest",
        "type": "text",
        "label": "Interest",
        "grid": 2,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "FDR"]
    },
    {
        "varName": "fixGSiMaturity",
        "type": "select",
        "grid": 2,
        "enum": [
            "Yes",
            "No",
            "Encash at Maturity to my / Our Account No",
        ],
        "label": "Renewal / Maturity Instruction",
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "FDR"]
    },
    {
        "varName": "fixdGMaturityYes",
        "type": "select",
        "label": "Maturity Yes",
        "enum": [
            "Renew Principal Only and Credit Interest to the Account No",
            "Renew Both Principal and Interest"
        ],
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "fixGSiMaturity"],
        "conditionalArrayValue": [true, "Yes"]
    },
    {
        "varName": "debitAccountNumber",
        "type": "text",
        "label": "Debit A/C Number",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "fixGSiMaturity", "fixdGMaturityYes"],
        "conditionalArrayValue": [true, "Yes", "Renew Principal Only and Credit Interest to the Account No"]
    },
    {
        "varName": "creditAccountNumber",
        "type": "text",
        "label": "Credit A/C Number",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "fixGSiMaturity", "fixdGMaturityYes"],
        "conditionalArrayValue": [true, "Yes", "Renew Principal Only and Credit Interest to the Account No"]
    },

///////  Month Tenor /////
    {
        "varName": "monthTennor",
        "type": "select",
        "grid": 2,
        "enum": [
            "Monthly"
        ],
        "label": "Tenure Type",
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "Monthly Interest Paying FD"]
    },
    {
        "varName": "tennorMonth",
        "type": "select",
        "enum": [],
        "label": "Tenure",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "Monthly Interest Paying FD"]
    },
    {
        "varName": "monthAmount",
        "type": "text",
        "label": "Amount",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "Monthly Interest Paying FD"]
    },
    {
        "varName": "monthInterest",
        "type": "text",
        "label": "Interest",
        "grid": 2,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "Monthly Interest Paying FD"]
    },
    {
        "varName": "monthSiMaturity",
        "type": "select",
        "grid": 2,
        "enum": [
            "Yes",
        ],
        "label": "Renewal / Maturity Instruction",
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "Monthly Interest Paying FD"]
    },
    {
        "varName": "monthMaturityYes",
        "type": "select",
        "label": "Maturity Yes",
        "enum": [
            "Renew Principal Only and Credit Interest to the Account No"
            // "Renew Principal Only "
        ],
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "Monthly Interest Paying FD"]
    },


];

let CSJsonFormForCasaServiceLastExist = [

    //cheque book
    {
        "varName": "chequeBookRequest",
        "type": "checkbox",
        "label": "Cheque Book Request",
        "grid": 12,

    },
    {
        "varName": "pageOfChequeBookSavings",
        "type": "select",
        "enum": [
            "10",
            "25",
        ],
        "label": "Page Of Cheque Book",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "accType"],
        "conditionalArrayValue": [true, "SAVINGS"],

        "grid": 2

    },
    {
        "varName": "pageOfChequeBookSavings",
        "type": "select",
        "enum": [
            "50",
            "100",
        ],
        "label": "Page Of Cheque Book",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "accTypes"],
        "conditionalArrayValue": [true, "SAVINGS"],

        "grid": 2

    },
    {
        "varName": "pageOfChequeBookCurrent",
        "type": "select",
        "enum": [
            "25",
            "50",
            "100",

        ],
        "label": "Page Of Cheque Book",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "accType"],
        "conditionalArrayValue": [true, "CURRENT"],

        "grid": 2

    },
    {
        "varName": "numberOfChequeBookRequest",
        "type": "select",
        "enum": [
            "1",
            "2",
            "3",
            "4",
            "5",
        ],
        "label": "Number Of Cheque Book",
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
        "grid": 2,


    },
    {
        "varName": "chequeBookDeliveryType",
        "type": "select",
        "label": "Delivery Type",
        "enum": [
            //,
            "Branch"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,

    },
    {
        "varName": "customerAddressCheque",
        "type": "text",
        "multiline": true,
        "label": "Customer Address",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "chequeBookDeliveryType"],
        "conditionalArrayValue": [true, ],


    },
    {
        "varName": "chequeBookBranch",
        "type": "select",
        "label": "Branch Name",
        "enum": [
            "GULSHAN 1",
            "MOTHIJHEEL 1",
            "DHANMONDI",
        ],
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "chequeBookDeliveryType"],
        "conditionalArrayValue": [true, "Branch"],


    },
    {
        "varName": "chequeBookDesign",
        "type": "select",
        "label": "Cheque Book Design",
        "enum": [
            "Sapphire",
            "Citygem",
            "City Alo",
            "Other"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,


    },
    {
        "varName": "chequeBookDesignOther",
        "type": "text",
        "label": "Other",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": "Other",


    },
//Debit Card
    {
        "varName": "debitCard",
        "type": "checkbox",
        "label": "Debit Card",
        "grid": 12
    },
    {
        "varName": "debitCardName",
        "type": "text",
        "label": "Name On Card",
        "grid": 2,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,

    },

    {
        "varName": "cardType",
        "type": "select",
        "label": "Card Type",
        "grid": 2,
        "enum": [
            "VISA",
            "MASTER",
            "CITYMAXX",
        ],
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "debitCardDeliveryType",
        "type": "select",
        "label": "Delivery Type",
        "enum": [
            //,
            "Branch"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "customerAddressDebit",
        "type": "text",
        "multiline": true,
        "label": "Customer Address",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["debitCard", "debitCardDeliveryType"],
        "conditionalArrayValue": [true, ],


    },

    {
        "varName": "debitRequestkBranch",
        "type": "select",
        "label": "Branch Name",
        "enum": [
            "GULSHAN 1",
            "MOTHIJHEEL 1",
            "DHANMONDI",
        ],
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["debitCard", "debitCardDeliveryType"],
        "conditionalArrayValue": [true, "Branch"],
    },
    {
        "varName": "instaDebitCard",
        "type": "checkbox",
        "label": "Insta Debit Card",
        "grid": 12
    },
    {
        "varName": "instaDebitCardName",
        "type": "text",
        "label": "Name On Card",
        "grid": 2,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "instaDebitCard",
        "conditionalVarValue": true,

    },

    {
        "varName": "instaCardType",
        "type": "select",
        "label": "Card Type",
        "enum": [
            "CITYMAXX"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "instaDebitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "instaClientId",
        "type": "text",
        "label": "Client ID",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "instaDebitCard",
        "conditionalVarValue": true,

    }
];*/
/*let CSJsonFormForCasaServiceExist = [
    {
        "varName": "priority",
        "type": "select",
        "label": "Priority",
        "enum": [
            "GENERAL",
            "HIGH",
        ],
        "grid": 2,
    },




    /!* {
         "varName": "lockerFacility",
         "type": "checkbox",
         "label": "Locker Facility",


     },*!/
    {
        "varName": "loanRequest",
        "type": "checkbox",
        "label": "Loan Request",

    },
    {
        "varName": "cityTouchRequest",
        "type": "checkbox",
        "label": "City Touch"

    },
    {
        "varName": "statementFacility",
        "type": "radio",
        "enum": [
            "Printed Statement",
            "E-Statement",
            "NO"
        ],
        "grid": 12,
        "label": "Statement",

    },

    /!* {
         "varName": "printedStatement",
         "type": "checkbox",
         "label": "Printed Statement",
         "conditional": true,
         "conditionalVarName": "statementFacility",
         "conditionalVarValue": true,

     },
     {
         "varName": "eStatement",
         "type": "checkbox",
         "label": "E-Statement",
         "conditional": true,
         "conditionalVarName": "statementFacility",
         "conditionalVarValue": true,

     },
     {
         "varName": "statementNo",
         "type": "checkbox",
         "label": "NO",
         "conditional": true,
         "conditionalVarName": "statementFacility",
         "conditionalVarValue": true,

     },*!/

    /!* {
         "varName": "cityTouchRequestEmail",
         "type": "text",
         "label": "Email",
         "grid":6,
         "conditional": true,
         "conditionalVarName": "dpsRequest",
         "conditionalVarValue": true,

     },*!/
    {
        "varName": "dpsRequest",
        "type": "checkbox",
        "label": "DPS Request",

        "grid": 12,

    },
    {
        "varName": "dpsAmount",
        "type": "text",
        "label": "Amount",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true,
    },
    {
        "varName": "dpsTenorType",
        "type": "select",
        "label": "Tenure Type",
        "grid": 2,
        "enum": [
            "Monthly",
        ],
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true,
    },
    {
        "varName": "dpsTenor",
        "type": "select",
        "enum": [],
        "label": "Tenure",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true,
    },
    {
        "varName": "dpsInterest",
        "type": "text",
        "label": "Interest",
        "readOnly": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true,
    },
    {
        "varName": "dpsSi",
        "type": "date",
        "label": "SI Date",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true,
    },
    {
        "varName": "fdrRequest",
        "type": "checkbox",
        "label": "FDR Request",
        "grid": 12
    },
    {
        "varName": "accountsType",
        "type": "select",
        "grid": 2,
        "enum": [
            "FDR",
            // "Double Money FD Account",
            "Monthly Interest Paying FD",
        ],
        "label": "Type Of Account",
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true,
    },

///// Account Type Double ///
    {
        "varName": "tenureType",
        "type": "select",
        "grid": 2,
        "enum": [
            "Monthly"
        ],
        "label": "Tenure Type",
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "Double Money FD Account"]

    },
    /!*  {
          "varName": "tennordDouble",
          "type": "select",
          "enum": [],
          "label": "Tenure",
          "grid": 2,
          "conditionalArray": true,
          "conditionalArrayList": ["fdrRequest", "accountsType"],
          "conditionalArrayValue": [true, "Double Money FD Account"]
      },
      {
          "varName": "doubleAmount",
          "type": "text",
          "label": "Amount",
          "grid": 2,
          "conditionalArray": true,
          "conditionalArrayList": ["fdrRequest", "accountsType"],
          "conditionalArrayValue": [true, "Double Money FD Account"]
      },*!/
    /!* {
         "varName": "schemeCode",
         "type": "text",
         "label": "Scheme Code",
         "grid": 2,
         "conditional": true,
         "conditionalVarName": "fdrRequest",
         "conditionalVarValue": true,

     },
     {
         "varName": "doubleInterest",
         "type": "text",
         "label": "Interest",
         "grid": 2,
         "readOnly": true,
         "conditionalArray": true,
         "conditionalArrayList": ["fdrRequest", "accountsType"],
         "conditionalArrayValue": [true, "Double Money FD Account"]
     }, *!/{
        "varName": "doubleSiMaturity",
        "type": "select",
        "grid": 2,
        "enum": [
            "No"
        ],
        "label": "Renewal / Maturity Instruction",
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "Double Money FD Account"]
    },


/////  Account Type Fix General /////
    {
        "varName": "tenureType",
        "type": "select",
        "grid": 2,
        "enum": [
            "Day",
            "Monthly"
        ],
        "label": "Tenure Type",
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "FDR"]
    },
    /!* {
         "varName": "tennorFixG",
         "type": "select",
         "enum": [],
         "label": "Tenure",
         "grid": 2,
         "conditionalArray": true,
         "conditionalArrayList": ["fdrRequest", "accountsType"],
         "conditionalArrayValue": [true, "FDR"]
     },*!/
    /!*{
        "varName": "fixGAmount",
        "type": "text",
        "label": "Amount",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "FDR"]
    },
    {
        "varName": "fixGInterest",
        "type": "text",
        "label": "Interest",
        "grid": 2,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "FDR"]
    },*!/
    {
        "varName": "fixGSiMaturity",
        "type": "select",
        "grid": 2,
        "enum": [
            "Yes",
            "No",
            "Encash at Maturity to my / Our Account No",
        ],
        "label": "Renewal / Maturity Instruction",
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "FDR"]
    },
    {
        "varName": "fixdGMaturityYes",
        "type": "select",
        "label": "Maturity Yes",
        "enum": [
            "Renew Principal Only and Credit Interest to the Account No",
            "Renew Both Principal and Interest"
        ],
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "fixGSiMaturity"],
        "conditionalArrayValue": [true, "Yes"]
    },
    {
        "varName": "debitAccountNumber",
        "type": "text",
        "label": "Debit A/C Number",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "fixGSiMaturity", "fixdGMaturityYes"],
        "conditionalArrayValue": [true, "Yes", "Renew Principal Only and Credit Interest to the Account No"]
    },
    {
        "varName": "creditAccountNumber",
        "type": "text",
        "label": "Credit A/C Number",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "fixGSiMaturity", "fixdGMaturityYes"],
        "conditionalArrayValue": [true, "Yes", "Renew Principal Only and Credit Interest to the Account No"]
    },

///////  Month Tenor /////
    {
        "varName": "tenureType",
        "type": "select",
        "grid": 2,
        "enum": [
            "Monthly"
        ],
        "label": "Tenure Type",
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "Monthly Interest Paying FD"]
    },
    /!* {
         "varName": "tennorMonth",
         "type": "select",
         "enum": [],
         "label": "Tenure",
         "grid": 2,
         "conditionalArray": true,
         "conditionalArrayList": ["fdrRequest", "accountsType"],
         "conditionalArrayValue": [true, "Monthly Interest Paying FD"]
     },
     {
         "varName": "monthAmount",
         "type": "text",
         "label": "Amount",
         "grid": 2,
         "conditionalArray": true,
         "conditionalArrayList": ["fdrRequest", "accountsType"],
         "conditionalArrayValue": [true, "Monthly Interest Paying FD"]
     },
     {
         "varName": "monthInterest",
         "type": "text",
         "label": "Interest",
         "grid": 2,
         "readOnly": true,
         "conditionalArray": true,
         "conditionalArrayList": ["fdrRequest", "accountsType"],
         "conditionalArrayValue": [true, "Monthly Interest Paying FD"]
     },*!/
    {
        "varName": "monthSiMaturity",
        "type": "select",
        "grid": 2,
        "enum": [
            "Yes",
        ],
        "label": "Renewal / Maturity Instruction",
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "Monthly Interest Paying FD"]
    },
    {
        "varName": "monthMaturityYes",
        "type": "select",
        "label": "Maturity Yes",
        "enum": [
            "Renew Principal Only and Credit Interest to the Account No"
            // "Renew Principal Only "
        ],
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "Monthly Interest Paying FD"]
    },

    {
        "varName": "depositType",
        "type": "text",
        "label": "Deposit Type",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "tenureType"],
        "conditionalArrayValue": [true, "Day"]
    },
    {
        "varName": "depositType",
        "type": "text",
        "label": "Deposit Type",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "tenureType"],
        "conditionalArrayValue": [true, "Monthly"]
    },
    {
        "varName": "sbsCode",
        "type": "text",
        "label": "SBS Code",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "tenureType"],
        "conditionalArrayValue": [true, "Day"]
    }, {
        "varName": "sbsCode",
        "type": "text",
        "label": "SBS Code",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "tenureType"],
        "conditionalArrayValue": [true, "Monthly"]
    },
    {
        "varName": "rmCode",
        "type": "text",
        "label": "RM Code",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "tenureType"],
        "conditionalArrayValue": [true, "Day"]
    },
    {
        "varName": "rmCode",
        "type": "text",
        "label": "RM Code",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "tenureType"],
        "conditionalArrayValue": [true, "Monthly"]
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "RM Name",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "tenureType"],
        "conditionalArrayValue": [true, "Day"]
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "RM Name",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "tenureType"],
        "conditionalArrayValue": [true, "Monthly"]
    },


];*/

///UAT
let CSJsonFormForCasaServiceExist =[
    {
        "varName": "priority",
        "type": "select",
        "label": "Priority",
        "enum": [
            "GENERAL",
            "HIGH",
        ],
        "grid": 2,
    },
    {
        "varName": "statementFacility",
        "type": "select",
        "enum": [
            "Printed Statement",
            "E-Statement",
            "NO"
        ],
        "grid": 2,
        "label": "Statement *",

    },
   /* {
        "varName": "statementFacilityEmailAddress",
        "type": "text",
        "validation": "email",
        "label": "Email Address *",
    },*/






    /*   {
           "varName": "lockerFacility",
           "type": "checkbox",
           "label": "Locker Facility",


       },*/
    {
        "varName": "loanRequest",
        "type": "checkbox",
        "label": "Loan Request",

    },
    {
        "varName": "cityTouchRequest",
        "type": "checkbox",
        "label": "City Touch",

    },
    //tem
    {
        "varName": "dpsRequest",
        "type": "checkbox",
        "label": "DPS Request",

        "grid": 12,

    },

    {
        "type": "title",
        "grid": 12,
        "request":"DPS",
        "label": "DPS Information",
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true
    },
    {
        "varName": "debitAccountStatement",
        "type": "submit",
        "label": "Debit Account Statement",
        "mt": 15,
        "event": false,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true
    },
    {
        "varName": "dpsfdValueDate",
        "type": "date",
        "dayAdd": 0,
        "request":"DPS",
        "maxDate": true,
        "required": true,
        "grid": 2,
        "onKeyDown": true,
        "label": "DPS Value Date",
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,

    {
        "varName": "dpsnameOfScheme",
        "type": "select",
        "grid": 2,
        "enum": [],
        "request":"DPS",
        "required": true,
        "onKeyDown":true,
        "label": "Name Of Scheme",
        "conditionalArray": true,
        "conditionalArrayList": ["dpsnameOfSchemeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    },
    {
        "varName":"dpsaccountAcquisition",
        "type":"select",
        "enum":[],
        "onKeyDown":true,
        "required": true,
        "request":"DPS",
        "label":"Account Acquisition By",
        "grid":2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsaccountAcquisitionApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]


    },
    {
        "varName": "dpstenureType",
        "type": "select",
        "grid": 2,
        "enum": [],
        "required": true,
        "request":"DPS",
        "onKeyDown":true,
        "label": "Scheme Type",
        "conditionalArray": true,
        "conditionalArrayList": ["dpstenureTypeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    },
    {
        "varName": "dpstenure",
        "type": "select",
        "enum": [],
        "grid": 2,
        "required": true,
        "request":"DPS",
        "label": "Period Of Scheme",
        "onKeyDown":true,
        "conditionalArray": true,
        "conditionalArrayList": ["dpstenureApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]
    },
    {
        "varName": "repaymentAccounts",
        "type": "textApiCall",
        "label": "Repayment Account",
        "required":true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsSchemeCodeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]
    },
    {
        "varName": "repaymentsName",
        "type": "text",
        "label": "Repayment Name",
        "grid": 2,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsdpsSchemeCodeApiCall","repaymentsAccountApiCall", "dpsRequest"],
        "conditionalArrayValue": [true,"YES", true],
    },
    {
        "varName": "dpsdpsSchemeCode",
        "type": "autoCompleteValueReturn",
        "enum":[],
        "required": true,
        "request":"DPS",
        "label": "DPS Scheme Code",
        "multiline":true,
        "readOnly":true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsdpsSchemeCodeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    }
    ,
    {
        "varName": "availableAmount",
        "type": "text",
        "label": "Available Amount",
        "request":"DPS",
        "readOnly":true,
        "required": true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsRequest", "availableAmountShow"],
        "conditionalArrayValue": [true, true],


    },
    {
        "varName": "dpsamount",
        "type": "text",
        "wordFromAmount":true,
        "validation":"numeric",
        "required": true,
        "request":"DPS",
        "onKeyDown": true,
        "label": "Amount Per Installment",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsdpsSchemeCodeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]


    }
    ,
    {
        "varName": "dpsamountWithWord",
        "type": "text",
        "readOnly":true,
        "request":"DPS",
        "label": "Amount In Word Per Installment",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsdpsSchemeCodeApiCall", "dpsRequest","dpsamountWithWordApiFlag"],
        "conditionalArrayValue": [true, true,true]


    },
    {
        "varName": "nextExecutionDate",
        "type": "date",
        "required": true,
        "request":"DPS",
        "label": "Installment Start Date",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true


    }
    ,
    {
        "varName": "dpspayableAtMaturity",
        "type": "text",
        "readOnly": true,
        "request":"DPS",
        "label": "Payable At Maturity",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "varName": "dpsfdMaturityDate",
        "type": "text",
        "label": "Maturity Date",
        "readOnly": true,
        "request":"DPS",
        "required": true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsfdMaturityDateApicall", "dpsRequest"],
        "conditionalArrayValue": ["YES", true]
    },
    {
        "varName": "dpsinterestRate",
        "type": "text",
        "label": "Rate %",
        "readOnly": true,
        "request":"DPS",
        "grid": 2,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsinterestRateApicall", "dpsRequest"],
        "conditionalArrayValue": ["YES", true]
    },


    {
        "varName": "eTin",
        "type": "text",
        "validation":"etin",
        "request":"DPS",
        "readOnly": true,
        "label": "Tin No",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "varName": "taxWaiverChanges",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "required":true,
        "label": "Tax Waiver Applicable",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true
    },
    {
        "varName": "dpsdepositType",
        "type": "text",
        "readOnly": true,
        "label": "Deposit Type",
        "request":"DPS",
        "grid": 2,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["depositTypeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    }
    ,
    {
        "varName": "occupationCode",
        "type": "text",
        "required": true,
        "readOnly": true,
        "request":"DPS",
        "label": "Occupation Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "readOnly": true,
        "enum": [],
        "request":"DPS",
        "label": "Sector Code",
        "grid": 2,
        required: true,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Sector Code",
        "grid": 2,
        "request":"DPS",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["sectorCodeApicall", "dpsRequest"],
        "conditionalArrayValue": [true, true]
    },
    {
        "varName": "rmCodeDps",
        "type": "textApiCall",
        "required": true,
        "onKeyDown":true,
        "label": "RM Code",
        "grid": 2,
        "request":"DPS",
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "varName": "rmCodeDpsName",
        "type": "text",
        "readOnly": true,
        "label": "RM Code Name",
        "grid": 2,
        "request":"DPS",
        "conditionalArray": true,
        "conditionalArrayList": ["rmCodeDpsApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    }
    ,
    {
        "varName": "dpsfreeCode1SpecialTaxagentOutlet",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Agent Banking Outlet",
        "grid": 2,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsRequest", "agentBankingFlag"],
        "conditionalArrayValue": [true,true]
    },
    {
        "varName": "nomineeTypes",
        "type": "select",
        "required": true,
        "enum": [
            "Nominee Same As Operative Account",
            "New Nominee Assignment",
        ],
        "request":"DPS",
        "label": "Nominee Type",
        "grid": 2,

        "conditionalArray": true,
        "conditionalArrayList": ["nomineeInformationVisible", "dpsRequest"],
        "conditionalArrayValue": ["YES", true]
    },

    {
        "varName": "nomineeExistNots",
        "type": "select",
        "enum":[
            "Y",
            "N",
        ],
        "request":"DPS",
        "readOnly":true,
        "label": "Nominee Exist/Not",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["nomineeTypes", "dpsRequest"],
        "conditionalArrayValue": ["Nominee Same As Operative Account", true]

    },

    /* {
         "varName": "cityTouchEmailAddress",
         "type": "text",
         "validation":"email",
         "label": "Email Address *",
         "conditional": true,
         "conditionalVarName": "cityTouchRequest",
         "conditionalVarValue": true,

     },*/

].concat(CSJsonFormForFDRService);
let CSJsonFormForCasaServiceLastExist = [
    {
        "varName": "chequeBookRequest",
        "type": "checkbox",
        "onkeyDown":true,
        "label": "Cheque Book Request",
        "grid": 12,

    },
    /* {
        varName: "accountStatus",
        type: "text",
        label: "Account Status",
        //  readOnly: true,
        grid: 2,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
    },
    {
        varName: "accountType",
        type: "text",
        label: "Account Type",
        //required: true,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
        //  readOnly: true,
        grid: 2,
    },*/
    /*{
        varName: "mobileNumber",
        type: "text",
        label: "Mobile Number",
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
        //required: true,
        //  readOnly: true,
        grid: 2,
    },*/
    /*  {
          varName: "numberOfChequeBook",
          type: "select",
          label: "No. of Cheque Book",
          //required: true,
          grid: 2,
          enum: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": true,
      },
      {
          varName: "pageofChequebook",
          type: "select",
          label: "Page of Chequebook",
          //required: true,
          grid: 2,
          enum: ["10", "25"],
          "conditionalArray": true,
          "conditionalArrayList": ["accountType", "accountType","accountType","chequeBookRequest"],
          "conditionalArrayValue": ["Savings","Savings(Alo)", "Savings(Ababil Alo)",true],

      },
      {
          varName: "pageofChequebook",
          type: "select",
          label: "Page of Chequebook",
          //required: true,
          grid: 2,
          "conditionalArray": true,
          "conditionalArrayList": ["accountType","chequeBookRequest"],
          "conditionalArrayValue": ["Current",true],
          enum: ["25", "50", "100"]
      },
      {
         varName: "deliveryType",
          type: "select",
          enum:[
            "Branch"
          ],
          label: "Delivery Type",
          //required: true,
          //  readOnly: true,
          grid: 2,
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": true,
      },

      {
          varName: "branchName",
          type: "autoComplete",
          label: "Branch Name",
          //required: true,
          "conditionalArray": true,
          "conditionalArrayList": ["deliveryType","chequeBookRequest"],
          "conditionalArrayValue": ["Branch",true],
          enum: ["25", "50", "100"],
          grid: 2,
      },
      {
          varName: "customerAddress",
          type: "text",
          label: "Customer Address",
          //required: true,
          "conditionalArray": true,
          "conditionalArrayList": ["deliveryType","chequeBookRequest"],
          "conditionalArrayValue": ["Courier",true],
          grid: 2,
      },
      {
          varName: "customerType",
          type: "select",
          label: "Customer Type",
          enum: ["General", "Priority", "Sapphire"],
          grid: 2,
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": true,
      },
      {
          varName: "checqueBookDesign",
          type: "select",
          label: "Chequebook Design",
          grid: 2,
          enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5"],
          "conditionalArray": true,
          "conditionalArrayList": ["customerType","chequeBookRequest"],
          "conditionalArrayValue": ["Priority",true],
      },
      {
          varName: "checqueBookDesign",
          type: "select",
          label: "Chequebook Design",
          grid: 2,
          enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5", "Design Code 6"],
          "conditionalArray": true,
          "conditionalArrayList": ["customerType","chequeBookRequest"],
          "conditionalArrayValue": ["Sapphire",true],

      },
      {
          varName: "chequeBookType",
          type: "select",
          label: "Cheque Book Type",
          enum: ["Normal", "A4"],
          grid: 2,
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": true,
      },
      {
          varName: "requisitionBranch",
          type: "autoCompleteValueReturn",
          enum:[],
          label: "Requisition branch",
          grid: 2,
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": true,
      },*/
    //cheque book
    {
        varName: "numberOfChequeBook",
        type: "select",
        label: "No. of Cheque Book *",
        required: true,
        // asterisk: true,
        grid: 2,
        errorMessage: "Error",
        enum: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,

    },
    {
        varName: "pageofChequebook",
        type: "select",
        label: "Page of Chequebook *",
        required: true,
        // asterisk: true,
        grid: 2,
        errorMessage: "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "accType"],
        "conditionalArrayValue": [true, "SAVINGS"],
        enum: ["10", "25"]
    },
    {
        varName: "pageofChequebook",
        type: "select",
        label: "Page of Chequebook *",
        required: true,
        // asterisk: true,
        grid: 2,
        errorMessage: "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "accType"],
        "conditionalArrayValue": [true, "CURRENT"],
        enum: ["25", "50", "100"]
    },

    {
        varName: "chequeBookDeliveryType",
        type: "select",
        label: "Delivery Type *",
        required: true,
        // asterisk: true,
        grid: 2,
        errorMessage: "Error",
        enum: ["Branch",],
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
    },

    {
        varName: "checkbookBranchName",
        "type": "autoCompleteValueReturn",
        "enum": [],
        label: "Branch Name *",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "chequeBookDeliveryType"],
        "conditionalArrayValue": [true, "Branch"],
        grid: 2,
        errorMessage: "Error",
        // enum: ["Gulshan", "Banani", "Dhanmondi"]
    },
    {
        varName: "chequeBookType",
        type: "select",
        label: "Cheque Book Type *",
        enum: ["Normal", "A4"],
        grid: 2,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
    },
    /*{
        varName: "requisitionBranch",
        type: "autoCompleteValueReturn",
        enum:[],
        label: "Requisition branch",
        grid: 2,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
    },*/
    /* {
         varName: "customerAddress",
         type: "text",
         label: "Customer Address",
         required: true,
         // asterisk: true,
         "conditionalArray": true,
         "conditionalArrayList": ["chequeBookRequest", "deliveryType"],
         "conditionalArrayValue": [true, ],
         grid: 2,
         errorMessage: "Error"
     },*/
    {
        varName: "customerType",
        type: "text",
        "readOnly":true,
        label: "Customer Type *",
        grid: 2,
        errorMessage: "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "customerTypeFlag"],
        "conditionalArrayValue": [true, true],

    },
    {
        varName: "checqueBookDesign",
        type: "select",
        label: "Chequebook Design *",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "customerType"],
        "conditionalArrayValue": [true, "Priority"],

        grid: 2,
        enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5"],
        errorMessage: "Error"
    },
    {
        varName: "checqueBookDesign",
        type: "select",
        label: "Chequebook Design *",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "customerType"],
        "conditionalArrayValue": [true, "Sapphire"],
        grid: 2,
        enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5", "Design Code 6"],
        errorMessage: "Error"
    },
//Debit Card
    /*  {
          "varName": "debitCard",
          "type": "checkbox",
          "onKeyDown":true,
          "label": "Debit Card",
          "grid": 12
      },
      {
          varName: "cardType",
          type: "select",
          label: "Card Type",
          onKeyDown: true,
          required: true,
          grid: 2,
          errorMessage: "Error",
          enum: [],
          "conditional": true,
          "conditionalVarName": "debitCard",
          "conditionalVarValue": true,
      },


      {
          varName: "productType",
          type: "select",
          label: "Product Type",
          required: true,
          grid: 2,
          errorMessage: "Error",
          enum: [],
          "conditionalArray": true,
          "conditionalArrayList": ["debitCard", "showProductType"],
          "conditionalArrayValue": [true, true],
      },
      {
          varName: "nameOnCard",
          type: "text",
          label: "Name on Card",
          required: true,
          grid: 2,
          "validationB":"length",
          "maxLength":19,
          "multiline": true,
          "conditional": true,
          "conditionalVarName": "debitCard",
          "conditionalVarValue": true,
      },

      {
          varName: "debitCardDeliveryTypes",
          type: "select",
          label: "Delivery Branch Name",
          required: true,
          grid: 2,
          errorMessage: "Error",
          enum: ["Branch",],
          "conditional": true,
          "conditionalVarName": "debitCard",
          "conditionalVarValue": true,
      },

      {
          varName: "debitCardBranchName",
          "type": "autoCompleteValueReturn",
          "enum": [],
          label: "Branch Name",
          grid: 2,
          errorMessage: "Error",
          "conditionalArray": true,
          "conditionalArrayList": ["debitCard", "debitCardDeliveryTypes"],
          "conditionalArrayValue": [true, "Branch"],
      },

     */
    /*{
        varName: "customerAddress",
        type: "text",
        label: "Customer Address",
        multiline: true,
        required: true,
        grid: 2,
        errorMessage: "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["debitCard", "deliveryType"],
        "conditionalArrayValue": [true, ],
    },*/
    {
        "varName": "instaDebitCard",
        "type": "checkbox",
        "label": "Insta Debit Card",
        "grid": 12
    },
    {
        "varName": "instaDebitCardName",
        "type": "text",
        "label": "Name On Card *",
        "grid": 2,
        "validationB":"length",
        "maxLength":19,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "instaDebitCard",
        "conditionalVarValue": true,

    },

    {
        "varName": "instaCardType",
        "type": "select",
        "label": "Card Type *",
        "enum": [
            "CITYMAXX"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "instaDebitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "instaDebitCardProductType",
        "type": "select",
        "label": "Product Type *",
        "enum":[],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "instaDebitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "instaClientId",
        "type": "textApiCall",
        "label": "Client ID *",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "instaDebitCard",
        "conditionalVarValue": true,

    }, {
        "varName": "instaCardNumber",
        "type": "text",
        "label": "Card Number *",
        "readOnly":true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["instaDebitCard", "instaClientIdApiCall"],
        "conditionalArrayValue": [true, true],

    }
];
//lIVE
/*let CSJsonFormForCasaServiceExist =[
    {
        "varName": "priority",
        "type": "select",
        "label": "Priority",
        "enum": [
            "GENERAL",
            "HIGH",
        ],
        "grid": 2,
    },
    {
        "varName": "statementFacility",
        "type": "select",
        "enum": [
            "Printed Statement",
            "E-Statement",
            "NO"
        ],
        "grid": 2,
        "label": "Statement *",

    },
    {
        "varName": "statementFacilityEmailAddress",
        "type": "text",
        "validation": "email",
        "label": "Email Address *",
    },

    {
        "varName": "smsAlertRequest",
        "type": "select",
        "enum": [
            "Y",
            "N",
            "AB",
            "CCEP",
        ],
        "onKeyDown":true,
        "label": "SMS Alert Request",
        "grid": 2,
    },





    /!*   {
           "varName": "lockerFacility",
           "type": "checkbox",
           "label": "Locker Facility",


       },*!/
    {
        "varName": "loanRequest",
        "type": "checkbox",
        "label": "Loan Request",

    },
    {
        "varName": "cityTouchRequest",
        "type": "checkbox",
        "label": "City Touch",

    },
    //tem
   /!* {
        "varName": "dpsRequest",
        "type": "checkbox",
        "label": "DPS Request",

        "grid": 12,

    },*!/

    {
        "type": "title",
        "grid": 12,
        "request":"DPS",
        "label": "DPS Information",
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true
    },

    {
        "varName": "dpsfdValueDate",
        "type": "date",
        "dayAdd": 0,
        "request":"DPS",
        "required": true,
        "grid": 2,
        "onKeyDown": true,
        "label": "DPS Value Date",
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,

    {
        "varName": "dpsnameOfScheme",
        "type": "select",
        "grid": 2,
        "enum": [],
        "request":"DPS",
        "required": true,
        "onKeyDown":true,
        "label": "Name Of Scheme",
        "conditionalArray": true,
        "conditionalArrayList": ["dpsnameOfSchemeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    },
    {
        "varName":"dpsaccountAcquisition",
        "type":"select",
        "enum":[],
        "onKeyDown":true,
        "required": true,
        "request":"DPS",
        "label":"Account Acquisition By",
        "grid":2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsaccountAcquisitionApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]


    },
    {
        "varName": "dpstenureType",
        "type": "select",
        "grid": 2,
        "enum": [],
        "required": true,
        "request":"DPS",
        "onKeyDown":true,
        "label": "Scheme Type",
        "conditionalArray": true,
        "conditionalArrayList": ["dpstenureTypeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    },
    {
        "varName": "dpstenure",
        "type": "select",
        "enum": [],
        "grid": 2,
        "required": true,
        "request":"DPS",
        "label": "Period Of Scheme",
        "onKeyDown":true,
        "conditionalArray": true,
        "conditionalArrayList": ["dpstenureApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]
    },

    {
        "varName": "dpsdpsSchemeCode",
        "type": "autoCompleteValueReturn",
        "enum":[],
        "required": true,
        "request":"DPS",
        "label": "DPS Scheme Code",
        "multiline":true,
        "readOnly":true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsdpsSchemeCodeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    }
    ,
    {
        "varName": "availableAmount",
        "type": "text",
        "label": "Available Amount",
        "request":"FDR",
        "readOnly":true,
        "required": true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsRequest", "availableAmountShow"],
        "conditionalArrayValue": [true, true],

    },
    {
        "varName": "dpsamount",
        "type": "text",
        "validation":"numeric",
        "required": true,
        "request":"DPS",
        "onKeyDown": true,
        "label": "Amount Per Installment",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsdpsSchemeCodeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]


    }
    ,
    {
        "varName": "nextExecutionDate",
        "type": "date",
        "required": true,
        "request":"DPS",
        "label": "Installment Start Date",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true


    }
    ,
    {
        "varName": "dpspayableAtMaturity",
        "type": "text",
        "readOnly": true,
        "request":"DPS",
        "label": "Payable At Maturity",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "varName": "dpsfdMaturityDate",
        "type": "text",
        "label": "Maturity Date",
        "readOnly": true,
        "request":"DPS",
        "required": true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsfdMaturityDateApicall", "dpsRequest"],
        "conditionalArrayValue": ["YES", true]
    },
    {
        "varName": "dpsinterestRate",
        "type": "text",
        "label": "Rate %",
        "readOnly": true,
        "request":"DPS",
        "grid": 2,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsinterestRateApicall", "dpsRequest"],
        "conditionalArrayValue": ["YES", true]
    },


    {
        "varName": "eTin",
        "type": "text",
        "validation":"etin",
        "request":"DPS",
        "readOnly": true,
        "label": "Tin No",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "varName": "dpsdepositType",
        "type": "text",
        "readOnly": true,
        "label": "Deposit Type",
        "request":"DPS",
        "grid": 2,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["depositTypeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    }
    ,
    {
        "varName": "occupationCode",
        "type": "text",
        "required": true,
        "readOnly": true,
        "request":"DPS",
        "label": "Occupation Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "readOnly": true,
        "enum": [],
        "request":"DPS",
        "label": "Sector Code",
        "grid": 2,
        required: true,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Sector Code",
        "grid": 2,
        "request":"DPS",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["sectorCodeApicall", "dpsRequest"],
        "conditionalArrayValue": [true, true]
    },
    {
        "varName": "rmCode",
        "type": "text",
        "required": true,
          "onKeyDown":true,
        "label": "RM Code",
        "grid": 2,
        "request":"DPS",
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true

    }
    ,
    {
        "varName": "rmCodeName",
        "type": "text",
        "readOnly": true,
        "label": "RM Code Name",
        "grid": 2,
        "request":"DPS",
        "conditionalArray": true,
        "conditionalArrayList": ["rmCodeApiCall", "dpsRequest"],
        "conditionalArrayValue": [true, true]

    }
    ,
    {
        "varName": "nomineeTypes",
        "type": "select",
        "required": true,
        "enum": [
            "Nominee Same As Operative Account",
            "New Nominee Assignment",
        ],
        "request":"DPS",
        "label": "Nominee Type",
        "grid": 2,

        "conditionalArray": true,
        "conditionalArrayList": ["nomineeInformationVisible", "dpsRequest"],
        "conditionalArrayValue": ["YES", true]
    },

    {
        "varName": "nomineeExistNots",
        "type": "select",
        "enum":[
            "Y",
            "N",
        ],
        "request":"DPS",
        "readOnly":true,
        "label": "Nominee Exist/Not",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["nomineeTypes", "dpsRequest"],
        "conditionalArrayValue": ["Nominee Same As Operative Account", true]

    },


    /!* {
         "varName": "cityTouchEmailAddress",
         "type": "text",
         "validation":"email",
         "label": "Email Address *",
         "conditional": true,
         "conditionalVarName": "cityTouchRequest",
         "conditionalVarValue": true,

     },*!/

].concat(CSJsonFormForFDRService);
let CSJsonFormForCasaServiceLastExist = [
    {
        "varName": "chequeBookRequest",
        "type": "checkbox",
        "onkeyDown":true,
        "label": "Cheque Book Request",
        "grid": 12,

    },
    /!* {
        varName: "accountStatus",
        type: "text",
        label: "Account Status",
        //  readOnly: true,
        grid: 2,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
    },
    {
        varName: "accountType",
        type: "text",
        label: "Account Type",
        //required: true,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
        //  readOnly: true,
        grid: 2,
    },*!/
    /!*{
        varName: "mobileNumber",
        type: "text",
        label: "Mobile Number",
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
        //required: true,
        //  readOnly: true,
        grid: 2,
    },*!/
    /!*  {
          varName: "numberOfChequeBook",
          type: "select",
          label: "No. of Cheque Book",
          //required: true,
          grid: 2,
          enum: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": true,
      },
      {
          varName: "pageofChequebook",
          type: "select",
          label: "Page of Chequebook",
          //required: true,
          grid: 2,
          enum: ["10", "25"],
          "conditionalArray": true,
          "conditionalArrayList": ["accountType", "accountType","accountType","chequeBookRequest"],
          "conditionalArrayValue": ["Savings","Savings(Alo)", "Savings(Ababil Alo)",true],

      },
      {
          varName: "pageofChequebook",
          type: "select",
          label: "Page of Chequebook",
          //required: true,
          grid: 2,
          "conditionalArray": true,
          "conditionalArrayList": ["accountType","chequeBookRequest"],
          "conditionalArrayValue": ["Current",true],
          enum: ["25", "50", "100"]
      },
      {
         varName: "deliveryType",
          type: "select",
          enum:[
            "Branch"
          ],
          label: "Delivery Type",
          //required: true,
          //  readOnly: true,
          grid: 2,
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": true,
      },

      {
          varName: "branchName",
          type: "autoComplete",
          label: "Branch Name",
          //required: true,
          "conditionalArray": true,
          "conditionalArrayList": ["deliveryType","chequeBookRequest"],
          "conditionalArrayValue": ["Branch",true],
          enum: ["25", "50", "100"],
          grid: 2,
      },
      {
          varName: "customerAddress",
          type: "text",
          label: "Customer Address",
          //required: true,
          "conditionalArray": true,
          "conditionalArrayList": ["deliveryType","chequeBookRequest"],
          "conditionalArrayValue": ["Courier",true],
          grid: 2,
      },
      {
          varName: "customerType",
          type: "select",
          label: "Customer Type",
          enum: ["General", "Priority", "Sapphire"],
          grid: 2,
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": true,
      },
      {
          varName: "checqueBookDesign",
          type: "select",
          label: "Chequebook Design",
          grid: 2,
          enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5"],
          "conditionalArray": true,
          "conditionalArrayList": ["customerType","chequeBookRequest"],
          "conditionalArrayValue": ["Priority",true],
      },
      {
          varName: "checqueBookDesign",
          type: "select",
          label: "Chequebook Design",
          grid: 2,
          enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5", "Design Code 6"],
          "conditionalArray": true,
          "conditionalArrayList": ["customerType","chequeBookRequest"],
          "conditionalArrayValue": ["Sapphire",true],

      },
      {
          varName: "chequeBookType",
          type: "select",
          label: "Cheque Book Type",
          enum: ["Normal", "A4"],
          grid: 2,
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": true,
      },
      {
          varName: "requisitionBranch",
          type: "autoCompleteValueReturn",
          enum:[],
          label: "Requisition branch",
          grid: 2,
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": true,
      },*!/
    //cheque book
    {
        varName: "numberOfChequeBook",
        type: "select",
        label: "No. of Cheque Book *",
        required: true,
        // asterisk: true,
        grid: 2,
        errorMessage: "Error",
        enum: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,

    },
    {
        varName: "pageofChequebook",
        type: "select",
        label: "Page of Chequebook *",
        required: true,
        // asterisk: true,
        grid: 2,
        errorMessage: "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "accType"],
        "conditionalArrayValue": [true, "SAVINGS"],
        enum: ["10", "25"]
    },
    {
        varName: "pageofChequebook",
        type: "select",
        label: "Page of Chequebook *",
        required: true,
        // asterisk: true,
        grid: 2,
        errorMessage: "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "accType"],
        "conditionalArrayValue": [true, "CURRENT"],
        enum: ["25", "50", "100"]
    },

    {
        varName: "chequeBookDeliveryType",
        type: "select",
        label: "Delivery Type *",
        required: true,
        // asterisk: true,
        grid: 2,
        errorMessage: "Error",
        enum: ["Branch",],
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
    },

    {
        varName: "checkbookBranchName",
        "type": "autoCompleteValueReturn",
        "enum": [],
        label: "Branch Name *",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "chequeBookDeliveryType"],
        "conditionalArrayValue": [true, "Branch"],
        grid: 2,
        errorMessage: "Error",
        // enum: ["Gulshan", "Banani", "Dhanmondi"]
    },
    {
        varName: "chequeBookType",
        type: "select",
        label: "Cheque Book Type *",
        enum: ["Normal", "A4"],
        grid: 2,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
    },
    /!*{
        varName: "requisitionBranch",
        type: "autoCompleteValueReturn",
        enum:[],
        label: "Requisition branch",
        grid: 2,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
    },*!/
    /!* {
         varName: "customerAddress",
         type: "text",
         label: "Customer Address",
         required: true,
         // asterisk: true,
         "conditionalArray": true,
         "conditionalArrayList": ["chequeBookRequest", "deliveryType"],
         "conditionalArrayValue": [true, ],
         grid: 2,
         errorMessage: "Error"
     },*!/
    {
        varName: "customerType",
        type: "text",
        "readOnly":true,
        label: "Customer Type *",
        grid: 2,
        errorMessage: "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "customerTypeFlag"],
        "conditionalArrayValue": [true, true],

    },
    {
        varName: "checqueBookDesign",
        type: "select",
        label: "Chequebook Design *",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "customerType"],
        "conditionalArrayValue": [true, "Priority"],

        grid: 2,
        enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5"],
        errorMessage: "Error"
    },
    {
        varName: "checqueBookDesign",
        type: "select",
        label: "Chequebook Design *",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "customerType"],
        "conditionalArrayValue": [true, "Sapphire"],
        grid: 2,
        enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5", "Design Code 6"],
        errorMessage: "Error"
    },
//Debit Card
   /!* {
        "varName": "debitCard",
        "type": "checkbox",
        "onKeyDown":true,
        "label": "Debit Card",
        "grid": 12
    },*!/
    {
        varName: "cardType",
        type: "select",
        label: "Card Type *",
        required: true,
        grid: 2,
        errorMessage: "Error",
        enum: ["VISA DEBIT CARD", "MASTER CARD DEBIT CARD", "CITY MAXX DEBIT CARD"],
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,
    },


    {
        varName: "productType",
        type: "select",
        label: "Product Type *",
        required: true,
        grid: 2,
        errorMessage: "Error",
        enum: [
            "VISA DEBIT CARD STAFF",
            "VISA DEBIT CARD GENERAL",
            "VISA CORPORATE 1st year free",
            "VISA DEBIT CARD YOUTH",
            "VISA Debit Card corporate 2nd year tk 300",
            "VISA Debit Card corporate lifetime free",
            "VISA Debit Card - City Alo",
            "Easy VISA Debit card",
            "Easy Plus VISA Debit card",
            "Visa Corporate 1st Year & 2nd Year Free",
            "Debit Card Fee - University Campaign [172.5,345]"
        ],
        "conditionalArray": true,
        "conditionalArrayList": ["debitCard", "cardType"],
        "conditionalArrayValue": [true, "VISA DEBIT CARD"],
    },
    {
        varName: "productType",
        type: "select",
        label: "Product Type *",
        required: true,
        grid: 2,
        errorMessage: "Error",
        conditional: true,
        conditionalVarName: "cardType",
        conditionalVarValue: "MASTER CARD DEBIT CARD",
        enum: [
            "MASTER CARD PLATINUM STAFF",
            "MASTER CARD PLATINUM GENERAL",
            "MASTER CARD DEBIT CARD STAFF",
            "MASTER CARD DEBIT CARD GENERAL",
            "MASTER DEBIT CARD CORPORATE 1st year free",
            "MASTER CARD DEBIT CARD YOUTH",
            "MASTER CARD DEBIT CARD PLATINUM CITY GEMS",
            "Master Debit Card Corporate 2nd year tk 300",
            "Master Debit Card corporate lifetime free",
            "Master Platinum Gems staff card",
            "MC Platinum Debit Card - City Gems - Sapphire",
            "Master Manarah Debit card general",
            "Master Manarah Debit card Corporate",
            "Master Card Corporate 1st Year & 2nd Year Free",
        ],
        "conditionalArray": true,
        "conditionalArrayList": ["debitCard", "cardType"],
        "conditionalArrayValue": [true, "MASTER CARD DEBIT CARD"],
    },
    {
        varName: "productType",
        type: "select",
        label: "Product Type *",
        required: true,
        grid: 2,
        errorMessage: "Error",
        enum: [
            "CITY MAXX STAFF",
            "CITY MAXX GENERAL",
            "CITY MAXX CORPORATE 1st year free",
            "CITY MAXX KIOSK CAMPAIGN",
            "CITY MAXX CITY GEMS (PRIORITY BANKING)",
            "CITY MAXX YOUTH",
            "Citymaxx Corporate 2nd Year Tk. 300",
            "CITYMAXX corporate lifetime free",
            "MaxX City Gems Sapphire",
            "Easy City Maxx Debit Card",
            "Easy Plus City Maxx Debit Card",
            "City MaxX Corporate 1st Year & 2nd Year Free"
        ],
        "conditionalArray": true,
        "conditionalArrayList": ["debitCard", "cardType"],
        "conditionalArrayValue": [true, "CITY MAXX DEBIT CARD"],
    },
    {
        varName: "nameOnCard",
        type: "text",
        label: "Name on Card *",
        required: true,
        grid: 2,
        errorMessage: "Error",
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,
    },

    {
        varName: "debitCardDeliveryTypes",
        type: "select",
        label: "Delivery Branch Name *",
        required: true,
        grid: 2,
        errorMessage: "Error",
        enum: ["Branch",],
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,
    },

    {
        varName: "debitCardBranchName",
        "type": "autoCompleteValueReturn",
        "enum": [],
        label: "Branch Name *",
        grid: 2,
        errorMessage: "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["debitCard", "debitCardDeliveryTypes"],
        "conditionalArrayValue": [true, "Branch"],
    },
    /!*{
        varName: "customerAddress",
        type: "text",
        label: "Customer Address",
        multiline: true,
        required: true,
        grid: 2,
        errorMessage: "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["debitCard", "deliveryType"],
        "conditionalArrayValue": [true, ],
    },*!/
    {
        "varName": "instaDebitCard",
        "type": "checkbox",
        "label": "Insta Debit Card",
        "grid": 12
    },
    {
        "varName": "instaDebitCardName",
        "type": "text",
        "label": "Name On Card *",
        "grid": 2,
        "validationB":"length",
        "maxLength":19,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "instaDebitCard",
        "conditionalVarValue": true,

    },

    {
        "varName": "instaCardType",
        "type": "select",
        "label": "Card Type *",
        "enum": [
            "CITYMAXX"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "instaDebitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "instaDebitCardProductType",
        "type": "select",
        "label": "Product Type *",
        "enum":[],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "instaDebitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "instaClientId",
        "type": "textApiCall",
        "label": "Client ID *",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "instaDebitCard",
        "conditionalVarValue": true,

    }, {
        "varName": "instaCardNumber",
        "type": "text",
        "label": "Card Number *",
        "readOnly":true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["instaDebitCard", "instaClientIdApiCall"],
        "conditionalArrayValue": [true, true],

    }
];*/

let CSJsonFormForCasaServiceAllExist = CSJsonFormForCasaServiceExist.concat(CSJsonFormForCasaServiceLastExist);
/*let CSJsonFormForCasaIndividualExist = [
    {
        "varName": "eqmTicketNumber",
        "type": "text",

        "label": "EQM Ticket Number",
        "grid": 2,
    },

    {
        "varName": "cbNumber",
        "type": "text",
        "readOnly": true,
        "label": "CB Number",
        "grid": 2,
    },
    {
        "varName": "customerStatus",
        "type": "select",
        "label": "Customer Status ",
        "grid": 2,
        "errorMessage": "Error",

        "enum": [
            "Normal Customer",
            "Staff",
            "Priority",
        ],


    },
    {
        "varName": "accountType",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type *",
        "enum": [
            "Regular"
        ],
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
    }, {
        "varName": "accountType",
        "type": "select",
        "errorMessage": "Error",
        "readOnly": true,
        "label": "Product Type *",
        "enum": [
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "accountTypes",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type *",
        "readOnly": true,
        "enum": [
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
    },
    {
        "varName": "accType",
        "type": "select",
        "required": true,
        "errorMessage": "Error",
        "label": "Account Type ",
        "enum": [
            "SAVINGS",
            "CURRENT",
            /!* "FCY",
             "RFCD"*!/,
        ],
        "grid": 2,
    },

    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number *",

        "errorMessage": "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["accountTypes", "customerStatus"],
        "conditionalArrayValue": ["Insta Pack", "Normal Customer"],

        "grid": 2,
    },


    {
        "varName": "customerTitle",
        "type": "select",
        "enum": [
            "CAPT.",
            "DR.",
            "ENGG.",
            "LAW",
            "MAST.",
            "MINOR",
            "MR",
            "MR.",
            "MRS.",
            "MS."

        ],
        "readOnly": true,
        "label": "Customer Title ",
        "grid": 2,

    },
    {
        "varName": "customerName",
        "type": "text",

        //"multiline": true,
        "readOnly": true,
        "label": "Customer Name ",
        "grid": 2,

    },
    {
        "varName": "shortName",
        "type": "text",
        "multiline": true,
        //"required": true,
        "readOnly": true,
        "label": "Short Name",
        "grid": 2,

    },
    {
        "varName": "gender",
        "type": "select",
        "enum": [
            "M",
            "F",
            "O",
        ],
        "readOnly": true,
        //
        "label": "Gender",
        "grid": 2,

    },
    {
        "varName": "occupationCodeNormal",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "conditional": true,
        // "readOnly": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
        "grid": 2,

    },
    {
        "varName": "occupationCodePriority",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "conditional": true,
        "readOnly": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
        "grid": 2,

    },
    {
        "varName": "occupationCodeStaff",
        "type": "select",
        "enum": [
            "E",
        ],
        "label": "Occupation Code",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",

        "grid": 2,

    },
    {
        "varName": "staffIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "label": "Staff Indicator",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
        "grid": 2,

    },
    {
        "varName": "staffIdNumber",
        "type": "text",
        "label": "Staff ID Number ",
        "validation":"staffId",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
        "grid": 2,

    },
    {
        "varName": "dob",
        "type": "text",
        "readOnly": true,
        "label": "Date Of Birth ",
        "grid": 2,


    },
    {
        "varName": "designatedEmail",
        "type": "text",

        "label": "Designated Email ",
        "multiline": true,
        "readOnly": true,
        "errorMessage": "Error", "grid": 2,


    },

    {
        "varName": "designatedMobileNumber",
        "type": "text",
        "readOnly": true,
        "label": "Designated Mobile Number ",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",

    },
    {
        "varName": "designatedMobileNumber",
        "type": "text",
        "validation": "phone",
        "phonePrefix": true,
        "readOnly": true,
        "label": "Designated Mobile Number ",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "phone2",
        "type": "text",

        "readOnly": true,
        "phonePrefix": true,
        "label": "Mobile Number 2",
        "grid": 2,


    },
    {
        "varName": "nid",
        "type": "text",
        "errorMessage": "Error",
        "readOnly": true,
        "label": "NID",
        "grid": 2,

    },
    {
        "varName": "smartCard",
        "type": "text",
        "errorMessage": "Error",
        "readOnly": true,
        "validation": "smartCard",
        "label": "Smart Card",
        "grid": 2,

    },
    {
        "varName": "passport",
        "type": "text",
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Passport",
        "grid": 2,


    },
    {
        "varName": "eTin",
        "type": "text",
        "readOnly": true,
        "label": "E-Tin",
        "grid": 2,

    },


    {
        "varName": "birthCertificateNo",
        "type": "text",
        "readOnly": true,
        "label": "Birth Cer.",
        "grid": 2,

    },
    {
        "varName": "drivingLicense",
        "type": "text",
        "readOnly": true,
        "label": "Driving License",
        "grid": 2,

    },
    {
        "varName": "nationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "errorMessage": "Error",
        "readOnly": true,
        "label": "Nationality ",

        "grid": 2,


    },
    {
        "varName": "SelectCommunicationAddress",
        "type": "select",
        "enum": [
            "Present",
            "Permanent",
            "Office",
        ],
        "readOnly": true,
        "label": "Select Communication Address ",
        "grid": 2
    },
    {
        "varName": "communicationAddress1",
        "type": "text",
        "multiline": true,
        "readOnly": true,
        "label": "Communication Address 1 ",
        "grid": 3,
    },

    {
        "varName": "communicationAddress2",
        "type": "text",
        "multiline": true,

        "readOnly": true,
        "label": "Communication Address 2",
        "grid": 3
    },

    {
        "varName": "city2",
        "label": "City",
        "readOnly": true,
        //"required": true,
        "type": "autoCompleteValueReturn",
        "enum": [],
        "grid": 2
    },

    {
        "varName": "state2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "readOnly": true,
        //"required": true,
        "label": "Division(State)",
        "grid": 2
    },

    {
        "varName": "postalCode3",
        "type": "text",
        //
        "readOnly": true,
        "label": "Postal Code",
        "maxLength":6,
        "grid": 2
    },
    {
        "varName": "nationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //"required": true,
        "readOnly": true,
        "label": "Country",
        "grid": 2
    },

    {
        "varName": "schemeCodeForCurrent",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Scheme Code *",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "CURRENT",
    },
    {
        "varName": "schemeCodeForSavings",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Scheme Code *",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "SAVINGS",
    },
    {
        "varName": "schemeCodeForSavings",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Scheme Code *",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "FCY",
    },
    {
        "varName": "schemeCodeForSavings",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Scheme Code *",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "RFCD",
    },

    {
        "varName": "currency",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Currency ",
        "grid": 2,

    },
    {
        "varName": "rmCode",
        "type": "textApiCall",
        "label": "RM Code ",
        "required":true,
        //"validation":"rmCode", //no need
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
        "grid": 2,
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "label": "RM Code ",
        "required":true,
        //"validation":"rmCode", //no need
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
        "grid": 2,
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "RM Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["rmCodeApiCall", "customerStatus"],
        "conditionalArrayValue": [true, "Normal Customer"],
        "grid": 2,
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "RM Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["rmCodeApiCall", "customerStatus"],
        "conditionalArrayValue": [true, "Staff"],
        "grid": 2,
    },
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "onKeyDown": true,
        "enum": [],
        "required": true,
        "label": "Sector Code ",
        "grid": 2,
    },
    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "conditional": true,
        "conditionalVarName": "sectorCodeApicall",
        "conditionalVarValue": true,
        "label": "Sub Sector Code",
        "grid": 2,
    },


    {
        "varName": "priorityCenterCode",
        "type": "select",
        "enum": [
           "PR104",
            "PR142",
            "PR177",
            "PR186",
            "PR187",
            "PR195",
            "PR208",
            "PR169",
        ],
        "errorMessage": "Error",
        "label": "Priority Center Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRMCode",
        "type": "textApiCall",
        "errorMessage": "Error",
        //"validation":"rmCode", //no need
        "label": "Monitoring RM Code",
        "required":true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRmCodeName",
        "type": "textApiCall",
        "errorMessage": "Error",
        //"validation":"rmCode", //no need
        "label": "Monitoring RM Name",
        "required":true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["customerStatus", "monitoringRMCodeApiCall"],
        "conditionalArrayValue": ["Priority", true],
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "errorMessage": "Error",
        "required":true,
        "label": "Acquiring RM Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "Acquiring Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["customerStatus", "rmCodeApiCall"],
        "conditionalArrayValue": ["Priority", true],
        "grid": 2,
    },
    {
        "varName": "primaryCBHolderforPriority",
        "type": "text",
        "errorMessage": "Error",
        "label": "Primary CB Holder for Priority",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "relationshipwithPrimaryCBHolder",
        "type": "text",
        "errorMessage": "Error",
        "label": "Relationship with Primary CB Holder",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "functionCodeforChequeBookWaiver",
        "type": "text",
        "errorMessage": "Error",
        "readOnly": true,
        "label": "Function Code for Cheque Book Waiver",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "waiverTypeforChequeBookWaiver",
        "type": "text",
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Waiver Type  for Cheque Book Waiver",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "waiverField",
        "type": "text",
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Waiver Field",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },


    /!*{
        "varName": "introducerId",
        "type": "textApiCall",

        "label": "Introducer ID",
        "grid": 2,

    },
    {
        "varName": "introducerName",
        "type": "text",
        "multiline": true,
        "readOnly": true,
        "label": "Introducer Name",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "introducerIdApiCall",
        "conditionalVarValue": "YES",
    },
*!/
    {
        "varName": "ccepCode",
        "type": "textApiCall",
        "label": "CCEP Code",
        "grid": 2,

    },



];*/
let CSJsonFormForCasaIndividualExist = [
    {
        "varName": "eqmTicketNumber",
        "type": "text",

        "label": "EQM Ticket Number",
        "grid": 2,
    },
    {
        "varName": "branchSolId",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "SOL ID",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "dstFlag",
        "conditionalVarValue": true,
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "readOnly": true,
        "label": "CB Number",
        "grid": 2,
    },
    {
        "varName": "customerStatus",
        "type": "select",
        "label": "Customer Status ",
        "grid": 2,
        "errorMessage": "Error",
        "readOnly":true,
        "enum": [
            "Normal Customer",
            "Staff",
            "Priority",
        ],


    },
    {
        "varName": "accountType",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type *",
        "enum": [
            "Regular"
        ],
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
    }, {
        "varName": "accountType",
        "type": "select",
        "errorMessage": "Error",
        "readOnly": true,
        "label": "Product Type *",
        "enum": [
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "accountTypes",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type *",
        "readOnly": true,
        "enum": [
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
    },
    {
        "varName": "accType",
        "type": "select",
        "required": true,
        "errorMessage": "Error",
        "label": "Account Type ",
        "enum": [
            "SAVINGS",
            "CURRENT",
            /* "FCY",
             "RFCD"*/,
        ],
        "grid": 2,
    },

    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "label": "Account Number *",
        "errorMessage": "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["accountTypes", "customerStatus"],
        "conditionalArrayValue": ["Insta Pack", "Normal Customer"],

        "grid": 2,
    },


    {
        "varName": "customerTitle",
        "type": "select",
        "enum": [
          /*  "CAPT.",
            "DR.",
            "ENGG.",
            "LAW",
            "MAST.",
            "MINOR",
            "MR",
            "MR.",
            "MRS.",
            "MS.",
            "BANK",
            "JOINT",
            "M/S."*/
            "MR.",
            "MRS.",
            "MS."

        ],
        "readOnly": true,
        "label": "Customer Title ",
        "grid": 2,

    },
    {
        "varName": "customerName",
        "type": "text",

        //"multiline": true,
        "readOnly": true,
        "label": "Customer Name ",
        "grid": 2,

    },
    {
        "varName": "shortName",
        "type": "text",
        "multiline": true,
        //"required": true,
        "readOnly": true,
        "label": "Short Name",
        "grid": 2,

    },
    {
        "varName": "gender",
        "type": "select",
        "enum": [
            "M",
            "F",
            "O",
        ],
        "readOnly": true,
        //
        "label": "Gender",
        "grid": 2,

    },
    {
        "varName": "occupationCodeNormal",
        "type": "select",
        "enum": [],
        "readOnly": true,
        "label": "Occupation Code",
        "conditional": true,
        // "readOnly": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
        "grid": 2,

    },
    {
        "varName": "occupationCodePriority",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "conditional": true,
        "readOnly": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
        "grid": 2,

    },
    {
        "varName": "occupationCodeStaff",
        "type": "select",
        "enum": [
            "E",
        ],
        "label": "Occupation Code",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",

        "grid": 2,

    },
    {
        "varName": "staffIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "label": "Staff Indicator",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
        "grid": 2,

    },
    {
        "varName": "staffIdNumber",
        "type": "text",
        "label": "Staff ID Number ",
        //"validation":"staffId",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
        "grid": 2,

    },
    {
        "varName": "dob",
        "type": "date",
        "readOnly": true,
        "label": "Date Of Birth ",
        "grid": 2,


    },
    {
        "varName": "designatedEmail",
        "type": "text",
        "validation": "email",
        "label": "Designated Email ",
        "multiline": true,
        "readOnly": true,
        "errorMessage": "Error", "grid": 2,


    },
    {
        "varName": "mobileTypeIndividual",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        // "required":true,
        "label": "Designated Mobile Type *",
        "readOnly":true,
        "grid": 2,
    },
    {
        "varName": "countryCodeIndividual",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Designated Mobile Country Code ",
        "asteriks": true,
        "conditional": true,
        "readOnly":true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "designatedMobileNumber",
        "type": "text",
        "readOnly": true,
        "label": "Designated Mobile Number ",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",

    },


    {
        "varName": "designatedMobileNumber",
        "type": "text",
        //"validation": "phone",
        "phonePrefix": true,
        "readOnly": true,
        "label": "Designated Mobile Number ",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "mobileTypeIndividual2",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "readOnly":true,
        // "required":true,
        "label": "Second Mobile Type",
        "grid": 2,
    },
    {
        "varName": "countryCodeIndividual2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Second Mobile Country Code ",
        "asteriks": true,
        "readOnly":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "phone2",
        "type": "text",
        //"validation": "phone",
        "phonePrefix": true,
        "label": "Mobile Number 2",
        "readOnly":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Local",
        "grid": 2,


    },
    {
        "varName": "phone2",
        "type": "text",
        "label": "Mobile Number 2",
        "readOnly":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Overseas",
        "grid": 2,


    },
    {
        "varName": "nid",
        "type": "text",
        "errorMessage": "Error",
        "readOnly": true,
        "label": "NID",
        "grid": 2,

    },
    {
        "varName": "smartCard",
        "type": "text",
        "errorMessage": "Error",
        "readOnly": true,
        //"validation": "smartCard",
        "label": "Smart Card",
        "grid": 2,

    },
    {
        "varName": "passport",
        "type": "text",
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Passport",
        "grid": 2,


    },
    {
        "varName": "eTin",
        "type": "text",
        "etinBlock":true,
        "readOnly": true,
        "label": "E-Tin",
        "grid": 2,

    },
    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "eTinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "eTinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "eTinopen"],
        "conditionalArrayValue": ["Y", true],
        "grid":2,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "label": "Tax Submission Year",
        "required":true,
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "eTinopen"],
        "conditionalArrayValue": ["N", true],
        "grid": 2,
    },


    {
        "varName": "birthCertificateNo",
        "type": "text",
        "readOnly": true,
        "label": "Birth Cer.",
        "grid": 2,

    },
    {
        "varName": "drivingLicense",
        "type": "text",
        "readOnly": true,
        "label": "Driving License",
        "grid": 2,

    },
    {
        "varName": "nationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "errorMessage": "Error",
        "readOnly": true,
        "label": "Nationality ",

        "grid": 2,


    },
    {
        "varName": "SelectCommunicationAddress",
        "type": "select",
        "enum": [
            "Present",
            "Permanent",
            "Office",
        ],
        "readOnly": true,
        "label": "Select Communication Address ",
        "grid": 2
    },
    {
        "varName": "communicationAddress1",
        "type": "text",
        "multiline": true,
        "readOnly": true,
        "label": "Communication Address 1 ",
        "grid": 3,
    },

    {
        "varName": "communicationAddress2",
        "type": "text",
        "multiline": true,

        "readOnly": true,
        "label": "Communication Address 2",
        "grid": 3
    },

    {
        "varName": "city2",
        "label": "City",
        "readOnly": true,
        //"required": true,
        "type": "autoCompleteValueReturn",
        "enum": [],
        "grid": 2
    },

    {
        "varName": "state2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "readOnly": true,
        //"required": true,
        "label": "Division(State)",
        "grid": 2
    },

    {
        "varName": "postalCode3",
        "type": "text",
        //
        "readOnly": true,
        "label": "Postal Code",
        "maxLength":6,
        "grid": 2
    },
    {
        "varName": "nationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //"required": true,
        "readOnly": true,
        "label": "Country",
        "grid": 2
    },

    {
        "varName": "schemeCodeForCurrent",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Scheme Code *",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "CURRENT",
    },
    {
        "varName": "schemeCodeForSavings",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Scheme Code *",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "SAVINGS",
    },
    {
        "varName": "schemeCodeForSavings",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Scheme Code *",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "FCY",
    },
    {
        "varName": "schemeCodeForSavings",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Scheme Code *",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "RFCD",
    },

    {
        "varName": "currency",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Currency ",
        "grid": 2,

    },
    {
        "varName": "rmCode",
        "type": "textApiCall",
        "label": "RM Code ",
        "required":true,
        //"validation":"rmCode", //no need
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
        "grid": 2,
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "label": "RM Code ",
        "required":true,
        //"validation":"rmCode", //no need
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
        "grid": 2,
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "RM Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["rmCodeApiCall", "customerStatus"],
        "conditionalArrayValue": [true, "Normal Customer"],
        "grid": 2,
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "RM Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["rmCodeApiCall", "customerStatus"],
        "conditionalArrayValue": [true, "Staff"],
        "grid": 2,
    },
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "onKeyDown": true,
        "enum": [],
        "required": true,
        "label": "Sector Code ",
        "grid": 2,
    },
    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "conditional": true,
        "conditionalVarName": "sectorCodeApicall",
        "conditionalVarValue": true,
        "label": "Sub Sector Code",
        "grid": 2,
    },


    {
        "varName": "priorityCenterCode",
        "type": "select",
        "enum": [
           "PR104",
            "PR142",
            "PR177",
            "PR186",
            "PR187",
            "PR195",
            "PR208",
            "PR169",
        ],
        "errorMessage": "Error",
        "label": "Priority Center Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRMCode",
        "type": "textApiCall",
        "errorMessage": "Error",
        //"validation":"rmCode", //no need
        "label": "Monitoring RM Code",
        "required":true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRmCodeName",
        "type": "textApiCall",
        "errorMessage": "Error",
        //"validation":"rmCode", //no need
        "label": "Monitoring RM Name",
        "required":true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["customerStatus", "monitoringRMCodeApiCall"],
        "conditionalArrayValue": ["Priority", true],
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "errorMessage": "Error",
        "required":true,
        "label": "Acquiring RM Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "Acquiring Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["customerStatus", "rmCodeApiCall"],
        "conditionalArrayValue": ["Priority", true],
        "grid": 2,
    },
    {
        "varName": "primaryCBHolderforPriority",
        "type": "text",
        "errorMessage": "Error",
        "label": "Primary CB Holder for Priority",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "relationshipwithPrimaryCBHolder",
        "type": "text",
        "errorMessage": "Error",
        "label": "Relationship with Primary CB Holder",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "functionCodeforChequeBookWaiver",
        "type": "text",
        "errorMessage": "Error",
        "readOnly": true,
        "label": "Function Code for Cheque Book Waiver",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "waiverTypeforChequeBookWaiver",
        "type": "text",
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Waiver Type  for Cheque Book Waiver",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "waiverField",
        "type": "text",
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Waiver Field",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },


    /*{
        "varName": "introducerId",
        "type": "textApiCall",

        "label": "Introducer ID",
        "grid": 2,

    },
    {
        "varName": "introducerName",
        "type": "text",
        "multiline": true,
        "readOnly": true,
        "label": "Introducer Name",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "introducerIdApiCall",
        "conditionalVarValue": "YES",
    },
*/
    {
        "varName": "ccepCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "CCEP Code",
        "grid": 2,

    },
    {
        "varName": "smsAlertRequest",
        "type": "select",
        "enum": [
            "Y",
            "N",
            "AB",
            "CCEP",
        ],
        "required":true,
        "onKeyDown":true,
        "label": "SMS Alert Request",
        "grid": 2,
    },



];
let CSJsonFormForCasaIndividualExistReadOnly = makeReadOnlyObject(JSON.parse(JSON.stringify(CSJsonFormForCasaIndividualExist)));

let BOMJsonFormForCasaIndividualExist = makeReadOnlyObject(JSON.parse(JSON.stringify(makeGridObject(CSJsonFormForCasaIndividualExist.concat(CSJsonFormForCasaServiceAllExist)))));

let BMJsonFormForCasaIndividualExist = makeReadOnlyObject(JSON.parse(JSON.stringify(CSJsonFormForCasaIndividualExist.concat(CSJsonFormForCasaServiceAllExist))));

let MAKERJsonFormForCasaIndividualExist = MAKERJsonFormForCasaIndividual;
let MAKERJsonFormForCasaIndividualLastExist = MAKERJsonFormForCasaIndividualLast;
let CHECKERJsonFormForCasaIndividualExist = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForCasaIndividualExist)));
let CHECKERJsonFormForCasaIndividualLastExist = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForCasaIndividualLastExist.concat(TP).concat(KYC))));

let scriptNumber = makeReadOnlyObject(JSON.parse(JSON.stringify([
    {
        "label": "AOF 1",
        "type": "title",
        "grid": 12,
    },

    {
        "varName": "cbNumber",
        "type": "text",
        "label": "Customer ID",
        "grid": 6,
        "length": 9,
        required: true,
    },

    {
        "varName": "customerName",
        "type": "text",
        "label": "Title",
        "grid": 6,
        required: true,
    },

    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name",
        "grid": 6,
        "length": 80,
        required: true,
    },

    {
        "varName": "shortName",
        "type": "text",
        "label": "Short Name",
        "grid": 6,
        "length": 10,
        required: true,
    },

    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "grid": 6,
        required: true,
    },

    {
        "varName": "phone1",
        "type": "text",
        "label": "Phone No 1 ",
        "grid": 6,
    },

    {
        "varName": "statement",
        "type": "select",
        "enum": [
            "Printed Statement",
            "E-Statement",
            "NO",
        ],
        "label": "Statement",
        "grid": 6,
        required: true,
    },

    {
        "varName": "despatchMode",
        "type": "text",
        "label": "Despatch Mode",
        "grid": 6,
        required: true,
    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Contact Phone Number",
        "grid": 6,
        required: true,
    },

    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Ac ID",
        "grid": 6,
        "length": 13,
    },

    {
        "label": "AOF 2",
        "type": "title",
        "grid": 12,
    },

    {
        "varName": "introducerCustomerId",
        "type": "text",
        "label": "Introducer Customer ID",
        "grid": 6,
        "length": 9,
        required: true,
    },

    {
        "varName": "introducerName",
        "type": "text",
        "label": "Introducer Name",
        "grid": 6,
        required: true,
    },

    {
        "varName": "introducerStaff",
        "type": "text",
        "label": "Introducer Staff",
        "grid": 6,
        required: true,
    },

    {
        "varName": "nomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 6,
        required: true,
    },

    {
        "varName": "relationship",
        "type": "text",
        "label": "Relationship",
        "grid": 6,
        required: true,
    },

    {
        "varName": "dob1",
        "type": "date",
        "maxDate": true,
        "label": "D.O.B",
        "grid": 6,
        required: true,
    },

    {
        "varName": "address11",
        "type": "text",
        "label": "Address 1 ",
        "grid": 6,
        required: true,
    },

    {
        "varName": "address22",
        "type": "text",
        "label": "Address 2",
        "grid": 6,
    },

    {
        "varName": "cityCode1",
        "type": "text",
        //"enum": ["DHAKA",,"Chittagong","Barishal","Rajshahi","Khulna"],
        "label": "City Code",
        "grid": 6,
        required: true,
    },

    {
        "varName": "stateCode1",
        "type": "text",
        "label": "State Code",
        "grid": 6,
        required: true,
    },

    {
        "varName": "postalCode1",
        "type": "text",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 6,
        required: true,
    },

    {
        "varName": "country1",
        "type": "text",
        "enum": [],
        "label": "Country",
        "grid": 6,
        required: true,
    },

    {
        "varName": "regNo",
        "type": "text",
        "label": "Reg No",
        "grid": 6,
        required: true,
    },

    {
        "varName": "nomineeMinor",
        "type": "text",
        "label": "Nominee Minor",
        "enum": ["Yes", "No"],
        "grid": 6,
        required: true,
    },

    {
        "varName": "guardiansName",
        "type": "text",
        "label": "Guardians Name",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeMinor",
        "conditionalVarValue": "Yes",

    },

    {
        "varName": "guardianCode",
        "type": "text",
        "label": "Guardian Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "address4",
        "type": "text",
        "label": "Address",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "cityCode2",
        "type": "text",
        "enum": ["DHAKA", , "Chittagong", "Barishal", "Rajshahi", "Khulna"],
        "label": "City Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "stateCode2",
        "type": "text",
        "label": "State Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "postalCode2",
        "type": "text",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "country2",
        "type": "text",
        "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
        "label": "Country",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "applicantMinor",
        "type": "select",
        "label": "Applicant Minor",
        "grid": 6,
        "enum": ["Yes", "No"],
    },
    {
        "varName": "gurdian",
        "type": "text",
        "label": "Gurdian",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "applicantMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "gurdianName",
        "type": "text",
        "label": "Gurdian Name",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "applicantMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "address23",
        "type": "text",
        "label": "Address",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "applicantMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "city1",
        "type": "text",
        "enum": ["DHAKA", , "Chittagong", "Barishal", "Rajshahi", "Khulna"],
        "label": "City",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "applicantMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "state1",
        "type": "text",
        "label": "Division(State)",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "applicantMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "postal",
        "type": "text",
        "label": "Postal",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "applicantMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "country3",
        "type": "text",
        "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
        "label": "Country",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "applicantMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "label": "AOF 3",
        "type": "title",
        "grid": 12,
    },
    {
        "varName": "modeOfOperation",
        "type": "text",
        "label": "Mode Of Operation",
        "grid": 6,
        required: true,
    },

    {
        "label": "AOF 4",
        "type": "title",
        "grid": 12,
    },

    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "onKeyDown": true,
        "enum": [],
        "label": "Sector Code",
        "grid": 2,
        required: true,
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Sector Code",
        "grid": 2,
        required: true,
        "conditional": true,
        "conditionalVarName": "sectorCodeApicall",
        "conditionalVarValue": true,
    },

    {
        "varName": "freeCode31",
        "type": "text",
        "label": "Free Code 3 ",
        "grid": 6,

    },

    {
        "varName": "targetSchemeCode",
        "type": "text",
        "label": "Target Scheme Code",
        "grid": 6,
        required: true,
    },

    {
        "label": "IIF Page 1",
        "type": "title",
        "grid": 12,
    },
    {
        "varName": "gender",
        "type": "select", "enum": ["M", "F", "TRANSGENDER"],
        "label": "Gender",
        "grid": 6,
        required: true,
    },

    {
        "varName": "residentStatus",
        "type": "text",
        "label": "Resident Status",
        "enum": ["Resident", "Non-Resident"],
        "grid": 6,
        required: true,
    },

    {
        "varName": "nationalIdCard",
        "type": "text",
        "label": "National Id Card",
        "grid": 6,
        required: true,
    },

    {
        "varName": "dob2",
        "type": "date",
        "maxDate": true,
        "label": "D.O.B",
        "grid": 6,
        required: true,
    },

    {
        "varName": "father",
        "type": "text",
        "label": "Father ",
        "grid": 6,
        required: true,
    },

    {
        "varName": "mother",
        "type": "text",
        "label": "Mother",
        "grid": 6,
        required: true,
    },

    {
        "varName": "maritialStatus",
        "type": "text",
        "label": "Maritial Status",
        "enum": ["Yes", "No"],
        "grid": 6,
        required: true,
    },
    {
        "varName": "spouse",
        "type": "text",
        "label": "Spouse",
        "conditional": true,
        "conditionalVarName": "maritialStatus",
        "conditionalVarValue": "Yes",
        "grid": 6,
        required: true,
    },

    {
        "varName": "pangirNo",
        "type": "text",
        "label": "PAN GIR No",
        "grid": 6,
    },

    {
        "varName": "passportNo",
        "type": "text",
        "label": "Passport No",
        "grid": 6,
    },

    {
        "varName": "issueDate",
        "type": "date",
        "maxDate": true,
        "label": "Issue Date",
        "grid": 6,
    },

    {
        "varName": "passportDetails",
        "type": "text",
        "label": "Passport Details",
        "grid": 6,
    },

    {
        "varName": "expiryDate",
        "type": "date",
        "minDate": true,
        "label": "Expiry Date",
        "grid": 6,
    },

    {
        "varName": "freeText5",
        "type": "text",
        "label": "Free Text 5",
        "grid": 6,
        "length": 17,
    },

    {
        "varName": "freeText13",
        "type": "text",
        "label": "Free Text 13",
        "grid": 6,

    },

    {
        "varName": "freeText14",
        "type": "text",
        "label": "Free Text 14",
        "maxLength":15,
        "validationB":"length",
        "grid": 6,

    },


    {
        "varName": "freeText15",
        "type": "text",
        "label": "Free Text 15",
        "grid": 6,

    },
    {
        "label": "IIF Page 2",
        "type": "title",
        "grid": 12,
    },
    {
        "varName": "communicationAddress1",
        "type": "text",
        "label": "Communication Address 1",
        "grid": 6,
        required: true,
    },

    {
        "varName": "communicationAddress2",
        "type": "text",
        "label": "Communication Address 2",
        "grid": 6,
    },

    {
        "varName": "city2",
        "label": "City",
        "type": "text",
        "enum": ["DHAKA", , "Chittagong", "Barishal", "Rajshahi", "Khulna"],
        "grid": 6,
        required: true,
    },

    {
        "varName": "state2",
        "type": "text",
        "label": "Division(State)",
        "grid": 6,
        required: true,
    },

    {
        "varName": "postalCode3",
        "type": "text",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 6,
        required: true,
    },

    {
        "varName": "country4",
        "type": "text",
        "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
        "label": "Country",
        "grid": 6,
        required: true,
    },

    {
        "varName": "phoneNo4",
        "type": "text",
        "label": "Phone No 1 ",
        "grid": 6,
        "length": 13,
        required: true,
    },

    {
        "varName": "phoneNo21",
        "type": "text",
        "label": "Phone No 2",
        "grid": 6,
    },

    {
        "varName": "permanentAddress1",
        "type": "text",
        "label": "Permanent Address 1",
        "grid": 6,
        required: true,
    },

    {
        "varName": "permanentAddress2",
        "type": "text",
        "label": "Permanent Address 2",
        "grid": 6,
    },

    {
        "varName": "city3",
        "type": "text",
        "enum": ["DHAKA", , "Chittagong", "Barishal", "Rajshahi", "Khulna"],
        "label": "City",
        "grid": 6,
        required: true,
    },

    {
        "varName": "state3",
        "type": "text",
        "label": "Division(State)",
        "grid": 6,
        required: true,
    },

    {
        "varName": "postalCode4",
        "type": "text",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 6,
        required: true,
    },

    {
        "varName": "country5",
        "type": "text",
        "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
        "label": "Country",
        "grid": 6,
        required: true,
    },

    {
        "varName": "phoneNo22",
        "type": "text",
        "label": "Phone No 2",
        "grid": 6,
    },

    {
        "varName": "email2",
        "type": "text",
        "label": "Email",
        "grid": 6,
    },

    {
        "varName": "employerAddress1",
        "type": "text",
        "label": "Employer Address 1",
        "grid": 6,
        required: true,
    },

    {
        "varName": "employerAddress2",
        "type": "text",
        "label": "Employer Address 2",
        "grid": 6,
    },

    {
        "varName": "city4",
        "type": "text",
        "enum": ["DHAKA", , "Chittagong", "Barishal", "Rajshahi", "Khulna"],
        "label": "City",
        "grid": 6,
        required: true,
    },

    {
        "varName": "state4",
        "type": "text",
        "label": "Division(State)",
        "grid": 6,
        required: true,
    },

    {
        "varName": "postalCode5",
        "type": "text",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 6,
    },

    {
        "varName": "country6",
        "type": "text",
        "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "phoneNo13",
        "type": "text",
        "label": "Phone No 1 ",
        "grid": 6,
        "length": 13,
    },

    {
        "varName": "phoneNo23",
        "type": "text",
        "label": "Phone No 2",
        "grid": 6,
    },

    {
        "varName": "telexNo1",
        "type": "text",
        "label": "Telex No",
        "grid": 6,
    },

    {
        "varName": "email3",
        "type": "text",
        "label": "Email",
        "grid": 6,
    },

    {
        "varName": "faxNo",
        "type": "text",
        "label": "Fax No",
        "grid": 6,
    },

    {
        "label": "KYC",
        "type": "title",
        "grid": 12,
    },
    {
        "varName": "acNo",
        "type": "text",
        "label": "Ac No",
        "grid": 6,
        "length": 13,
        required: true,
    },

    {
        "varName": "acTitle",
        "type": "text",
        "label": "Ac Title",
        "grid": 6,
    },

    {
        "varName": "customerOccupation",
        "type": "text",
        "enum": ["Teacher", "Doctor", "House-Wife", "Privet Job Holder"],
        "label": "Customer Occupation",
        "grid": 6,
        required: true,
    },

    {
        "varName": "docCollectToEnsure",
        "type": "text",
        "label": "Doc Collect To Ensure SOF",
        "grid": 6,
        required: true,
    },

    {
        "varName": "collectedDocHaveBeenVerified",
        "type": "text",
        "enum": ["Yes", "No"],
        "label": "Collected Doc Have Been Verified",
        "grid": 6,
        required: true,
    },

    /* {
    "varName": "howTheAddress",
    "type": "text",
    "enum": ["Yes", "No"],
    "label": "How The Address Is Verified",
    "grid": 6,
    required: true,
},*/

    {
        "varName": "hasTheBeneficialOwner",
        "type": "text",
        "enum": ["Yes", "No"],
        "label": "Beneficial Owner",
        "grid": 6,
        required: true,
    },

    {
        "varName": "whatDoesTheCustomer",
        "type": "text",
        "enum": ["Yes", "No"],
        "label": "Customer's Occupation or Business",
        "grid": 6,
        required: true,
    },

    {
        "varName": "customersMonthlyIncome",
        "type": "text",
        "enum": ["20000", " 50000", "100000"],
        "label": "Customers Monthly Income",
        "grid": 6,
        required: true,
    },

    {
        "label": "TP",
        "type": "title",
        "grid": 12,
    },
    {
        "varName": "acNo",
        "type": "text",
        "label": "Ac No",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "monthlyProbableIncome",
        "type": "text",
        "label": "Monthly Probable Income",
        "grid": 6,
        required: true,
    },

    {
        "varName": "monthlyProbableTournover",
        "type": "text",
        "label": "Monthly Probable Tournover",
        "grid": 6,
        required: true,
    },

    {
        "varName": "sourceOfFund",
        "type": "text",
        "label": "Source Of Fund",
        "grid": 6,
        required: true,
    },

    {
        "varName": "cashDeposit",
        "type": "text",
        "label": "Cash Deposit",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "DepositByTransfer",
        "type": "text",
        "label": "  Deposit By Transfer",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "foreignInwardRemittance",
        "type": "text",
        "label": "Foreign Inward Remittance",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "depositIncomeFromExport",
        "type": "text",
        "label": "Deposit Income From Export",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "deposittransferFromBoAc",
        "type": "text",
        "label": "Deposittransfer From Bo Ac",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "others1",
        "type": "text",
        "label": "Others",
        "grid": 6,
        numeric: true,
    },

    {
        "varName": "totalProbableDeposit",
        "type": "text",
        "label": "Total Probable Deposit",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "cashWithdrawal",
        "type": "text",
        "label": "Cash Withdrawal",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "withdrawalThrough",
        "type": "text",
        "label": "Withdrawal (Transfer/instrument)",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "foreignOutwardRemittance",
        "type": "text",
        "label": "Foreign Outward Remittance",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "paymentAgainstImport",
        "type": "text",
        "label": "Payment Against Import",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "deposittransferToBoAc",
        "type": "text",
        "label": "Deposittransfer To Bo Ac",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "others2",
        "type": "text",
        "label": "Others",
        "grid": 6,
        numeric: true,
    },

    {
        "varName": "totalProbableWithdrawal",
        "type": "text",
        "label": "Total Probable  Withdrawal",
        "grid": 6,
        required: true,
        numeric: true,
    },
    {
        "label": "Others",
        "type": "title",
        "grid": 12,
    },
    {
        "varName": "status",
        "type": "text",
        "label": "Status",
        "grid": 6,
        required: true,
    },

    {
        "varName": "statusAsOnDate",
        "type": "date",
        "maxDate": true,
        "label": "Status As On Date",
        "grid": 6,
        required: true,
    },

    {
        "varName": "acManager",
        "type": "text",
        "label": "Ac Manager",
        "grid": 6,
        required: true,
    },

    {
        "varName": "occuoationCode",
        "type": "text",
        "label": "Occuoation Code",
        "grid": 6,
        required: true,
    },

    {
        "varName": "constitution",
        "type": "text",
        "label": "Constitution",
        "grid": 6,
        required: true,
    },

    {
        "varName": "staffFlag",
        "type": "select",
        "label": "Staff Flag",
        "enum": ["Yes", "No"],
        "grid": 6,
    },

    {
        "varName": "staffNumber",
        "type": "text",
        "label": "Staff Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "staffFlag",
        "conditionalVarValue": "Yes",
    },

    {
        "varName": "minor",
        "type": "text",
        "label": "Minor",
        "grid": 6,
        required: true,
    },

    {
        "varName": "trade",
        "type": "text",
        "label": "Trade",
        "grid": 6,
        required: true,
    },

    {
        "varName": "telexNo2",
        "type": "text",
        "label": "Telex No",
        "grid": 6,
    },

    {
        "varName": "telexNo3",
        "type": "text",
        "label": "Telex No",
        "grid": 6,
    },

    {
        "varName": "combineStatement",
        "type": "text",
        "label": "Combine Statement",
        "grid": 6,
        required: true,
    },

    {
        "varName": "tds",
        "type": "text",
        "label": "Tds",
        "grid": 6,
    },

    {
        "varName": "purgedAllowed",
        "type": "text",
        "label": "Purged Allowed",
        "grid": 6,
    },

    {
        "varName": "freeText2",
        "type": "text",
        "label": "Free Text 2",
        "grid": 6,
    },

    {
        "varName": "freeText8",
        "type": "text",
        "label": "Free Text 8",
        "grid": 6,
    },

    {
        "varName": "freeText9",
        "type": "text",
        "label": "Free Text 9",
        "grid": 6,
    },

    {
        "varName": "freeCode1",
        "type": "text",
        "label": "Free Code 1",
        "grid": 6,
    },

    {
        "varName": "freeCode3",
        "type": "text",
        "label": "Free Code 3",
        "grid": 6,
    },

    {
        "varName": "freeCode71",
        "type": "text",
        "label": "Free Code 7",
        "grid": 6,
    },

    {
        "varName": "accountManager",
        "type": "text",
        "label": "Account Manager",
        "grid": 6,
        required: true,
    },

    {
        "varName": "cashLimit",
        "type": "text",
        "label": "Cash Limit",
        "grid": 6,
    },

    {
        "varName": "clearingLimit",
        "type": "text",
        "label": "Clearing Limit",
        "grid": 6,
    },

    {
        "varName": "transferLimit",
        "type": "text",
        "label": "Transfer Limit",
        "grid": 6,
    },

    {
        "varName": "remarks",
        "type": "text",
        "label": "Remarks",
        "grid": 6,
    },

    {
        "varName": "statementFrequency",
        "type": "text",
        "label": "Statement Frequency",
        "grid": 6,
        required: true,
    },

    {
        "varName": "occupationOode",
        "type": "text",
        "label": "Occupation Oode",
        "grid": 6,
        required: true,
    },

    {
        "varName": "freeCode2",
        "type": "text",
        "label": "Free Code 1 ",
        "grid": 6,
    },

    {
        "varName": "freeCode7",
        "type": "text",
        "label": "Free Code 7",
        "grid": 6,
    },

    {
        "varName": "freeCode9",
        "type": "text",
        "label": "Free Code 9",
        "grid": 6,
    },

    {
        "varName": "freeCode10",
        "type": "text",
        "label": "Free Code 10",
        "grid": 6,
    },

    {
        "varName": "freeText11",
        "type": "text",
        "label": "Free Text 11",
        "grid": 6,
    },

    {
        "varName": "currencyCode",
        "type": "text",
        "label": "Currency Code",
        "grid": 6,
        required: true,
    },

    {
        "varName": "withHoldingTax",
        "type": "text",
        "label": "With Holding Tax % ",
        "grid": 6,
        required: true,
    },

    {
        "varName": "function",
        "type": "text",
        "label": "Function",
        "grid": 6,
        required: true,
    },


    {
        "varName": "freeCode9",
        "type": "text",
        "label": "Free Code 9",
        "grid": 6,
    },

    {
        "varName": "freeCode10",
        "type": "text",
        "label": "Free Code 10",
        "grid": 6,
    },

    {
        "varName": "freeText11",
        "type": "text",
        "label": "Free Text 11",
        "grid": 6,

    }
])));
//###Casa CS ,BM  ,BOM ,MAKER,CHECKER    New JOINT#####################
let CSJsonFormForCasaJoint = [
    {
        "varName": "eqmTicketNumber",
        "type": "text",
        "validation": "numeric",
        "label": "EQM Ticket Number",
        "grid": 2,
    },
    {
        "varName": "branchSolId",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "SOL ID",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "dstFlag",
        "conditionalVarValue": true,
    },
    {
        "varName": "accountSource",
        "type": "select",
        "label": "Account Source ",
        "grid": 2,
        "errorMessage": "Error",
        "required": true,
        "enum": [
            "Conventional"
           // "Islamic"
        ],


    },
    {
        "varName": "customerStatus",
        "type": "select",
        "label": "Customer Status ",
        "grid": 2,
        "errorMessage": "Error",
        "required": true,
        "enum": [
            "Normal Customer",
            "Staff",
            "Priority",
        ],


    },
    {
        "varName": "accountType",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type *",
        "enum": [
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
    }, {
        "varName": "accountType",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type *",
        "enum": [
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "accountTypes",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type *",
        "enum": [
            "Insta Pack",
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
    },
    {
        "varName": "accType",
        "type": "select",
        "required": true,
        "errorMessage": "Error",
        "label": "Account Type ",
        "enum": [
            "SAVINGS",
            "CURRENT",
            /* "FCY",
             "RFCD"*/,
        ],
        "grid": 2,
    },

    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "validation": "numeric",
        "label": "Account Number",
        "errorMessage": "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["accountTypes", "customerStatus"],
        "conditionalArrayValue": ["Insta Pack", "Normal Customer"],

        "grid": 2,
    },

    {
        "varName": "customerTitle",
        "type": "select",
        "enum": [
            "JOINT",
            "MINOR",

        ],
         "required": true,
        //"readOnly": true,
        "label": "Customer Title ",
        "grid": 2,

    },
    {
        "varName": "customerName",
        "type": "text",
        //"readOnly": true,
        "validationB":"length",
        "maxLength":80,
        "multiline": true,
        "required": true,
        "label": "Customer Name ",
        "grid": 2,

    },
    {
        "varName": "shortName",
        "type": "text",
        "required": true,
        "validationB":"length",
        "maxLength":10,
        "multiline": true,
        "label": "Short Name",
        "grid": 2,

    },
    {
        "varName": "occupationCodeNormal",
        "type": "select",
        "enum": [],
        "required":true,
        "label": "Occupation Code *" ,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
        "grid": 2,

    },
    {
        "varName": "occupationCodePriority",
        "type": "select",
        "enum": [],
        "required":true,
        "label": "Occupation Code *",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
        "grid": 2,

    },
    /* {
         "varName": "occupationCodeStaff",
        "type": "select",
        "enum": [
            "E",
        ],
         "label": "Occupation Code",
         "conditional": true,
         "conditionalVarName": "customerStatus",
         "conditionalVarValue": "Staff",

         "grid": 2,

     },
     {
         "varName": "staffIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
         "label": "Staff Indicator",
         "conditional": true,
         "conditionalVarName": "customerStatus",
         "conditionalVarValue": "Staff",
         "grid": 2,

     },
     {
        "varName": "staffIdNumber",
        "type": "text",
        "label": "Staff ID Number",
        asterisk: true,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
        "grid": 2,

    },
     {
         "varName": "dob",
         "type": "date",
         "maxDate": true,
         "required": true,
         "label": "Date Of Birth ",
         "grid": 2,


     },*/
    {
        "varName": "designatedEmail",
        "type": "text",
        "validation": "email",
        //"readOnly": true,
        "label": "Designated Email ",
        "multiline": true,
        "errorMessage": "Error", "grid": 2,


    },

    {
        "varName": "mobileTypeIndividual",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        // "required":true,
        "label": "Designated Mobile Type *",
        //"readOnly":true,
        "grid": 2,
    },
    {
        "varName": "countryCodeIndividual",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Designated Mobile Country Code",
        "asteriks": true,
        "conditional": true,
        //"readOnly":true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "designatedMobileNumber",
        "type": "text",
        //"readOnly": true,
        "label": "Designated Mobile Number ",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",

    },


    {
        "varName": "designatedMobileNumber",
        "type": "text",
        "validation": "phone",
        "phonePrefix": true,
        //"readOnly": true,
        "label": "Designated Mobile Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Local",

    },

    {
        "varName": "mobileTypeIndividual2",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        // "required":true,
        "label": "Second Mobile Type",
        "grid": 2,
    },
    {
        "varName": "countryCodeIndividual2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Second Mobile Country Code ",
        "asteriks": true,
        //"required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "secondePhoneNumber",
        "type": "text",
        "validation": "phone",
        "phonePrefix": true,
        //"required":true,
        "label": "Mobile Number 2",
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Local",
        "grid": 2,


    },
    {
        "varName": "secondePhoneNumber",
        "type": "text",
        "label": "Mobile Number 2",
        //"required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Overseas",
        "grid": 2,


    },

    {
        "varName": "SelectCommunicationAddress",
        "type": "select",
        "enum": [
            "Present",
            "Permanent",
            "Office",
        ],
        "required": true,
        "label": "Select Communication Address ",
        "grid": 2
    },
    {
        "varName": "communicationAddress1",
        "type": "text",
        "instatntValidation":true,
        "multiline": true,
        "validationB":"length",
        "maxLength":45,
        "label": "Communication Address 1 ",
        "grid": 3,
        "required": true,
    },

    {
        "varName": "communicationAddress2",
        "type": "text",
        "instatntValidation":true,
        "multiline": true,
        "validationB":"length",
        "maxLength":45,
        "label": "Communication Address 2",
        "grid": 3,
    },

    {
        "varName": "city2",
        "label": "City",
        "required": true,
        "type": "autoCompleteValueReturn",
        "enum": [],
        "grid": 2
    },

    {
        "varName": "state2",
        "required": true,
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 2
    },

    {
        "varName": "postalCode3",
        "type": "text",
        "validationB":"length",
        "maxLength":6,
        "required": true,
        "label": "Postal Code",
        "grid": 2
    },
    {
        "varName": "country",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //"readOnly": true,
        "required":true,
        "label": "Country",
        "grid": 2
    },
    {
        "varName": "schemeCodeForSavings",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Scheme Code *",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "SAVINGS",
    },
    {
        "varName": "schemeCodeForCurrent",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Scheme Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "CURRENT",
    },
    {
        "varName": "schemeCodeForSavings",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Scheme Code *",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "FCY",
    },
    {
        "varName": "schemeCodeForSavings",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Scheme Code *",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "RFCD",
    },
    {
        "varName": "currency",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Currency ",
        "required": true,
        "grid": 2,

    },
    {
        "varName": "rmCode",
        "type": "textApiCall",
        "required":true,
        //"validation":"rmCode", //no need
        "label": "RM Code ",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
        "grid": 2,
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "label": "RM Code ",
        "required":true,
        //"validation":"rmCode", //no need
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
        "grid": 2,
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "RM Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["rmCodeApiCall", "customerStatus"],
        "conditionalArrayValue": [true, "Normal Customer"],
        "grid": 2,
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "RM Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["rmCodeApiCall", "customerStatus"],
        "conditionalArrayValue": [true, "Staff"],
        "grid": 2,
    },
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "onKeyDown": true,

        "enum": [],
        "required": true,
        "label": "Sector Code ",
        "grid": 2,
    },
    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "conditional": true,
        "conditionalVarName": "sectorCodeApicall",
        "conditionalVarValue": true,
        "label": "Sub Sector Code",
        "grid": 2,
    },

    {
        "varName": "priorityCenterCode",
        "type": "select",
        //Hard Code
        "enum": [
           "PR104",
            "PR142",
            "PR177",
            "PR186",
            "PR187",
            "PR195",
            "PR208",
            "PR169",

        ],
        "errorMessage": "Error",
        "label": "Priority Center Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRMCode",
        "type": "textApiCall",
        "errorMessage": "Error",
        //"validation":"rmCode", //no need
        "label": "Monitoring RM Code",
        "required":true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRmCodeName",
        "type": "textApiCall",
        "errorMessage": "Error",
        //"validation":"rmCode", //no need
        "label": "Monitoring RM Name",
        "required":true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["customerStatus", "monitoringRMCodeApiCall"],
        "conditionalArrayValue": ["Priority", true],
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "errorMessage": "Error",
        "required":true,
        "label": "Acquiring RM Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "Acquiring Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["customerStatus", "rmCodeApiCall"],
        "conditionalArrayValue": ["Priority", true],
        "grid": 2,
    },

    {
        "varName": "primaryCBHolderforPriority",
        "type": "text",
        "errorMessage": "Error",
        "label": "Primary CB Holder for Priority",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "relationshipwithPrimaryCBHolder",
        "type": "text",
        "errorMessage": "Error",
        "label": "Relationship with Primary CB Holder",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "functionCodeforChequeBookWaiver",
        "type": "text",
        "errorMessage": "Error",
        "readOnly": true,
        "label": "Function Code for Cheque Book Waiver",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "waiverTypeforChequeBookWaiver",
        "type": "text",
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Waiver Type  for Cheque Book Waiver",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "waiverField",
        "type": "text",
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Waiver Field",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "nidVerified",
        "type": "select",
        "enum":[
            "YES",
            "NO",
        ],
        "required": true,
        "label": "Is All NID/Smart Card Document Verified?",
        "grid": 2,

    },
    {
        "varName": "introducerId",
        "type": "textApiCall",
        "required": true,
        "label": "Introducer ID",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },
    {
        "varName": "introducerName",
        "type": "text",
        "multiline": true,
        "readOnly": true,
        "label": "Introducer Name",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["introducerIdApiCall", "nidVerified"],
        "conditionalArrayValue": ["YES", "NO"]
    },

    {
        "varName": "accountNumberForNidVerified",
        "type": "textApiCall",
        "required": true,
        "label": "Introducer Account Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },

    {
        "varName": "accountNameForNidVerified",
        "type": "text",
        "readOnly": true,
        "label": "Introducer Account Name",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["accountNumberForNidVerifiedApiCall", "nidVerified"],
        "conditionalArrayValue": ["YES", "NO"]
    },
    {
        "varName": "signatureCardView",
        "type": "submit",
        "label": "Signature Card View",
        "mt": 15,
        "event": false,
        "grid":2,
        "conditionalArray": true,
        "conditionalArrayList": ["accountNumberForNidVerifiedApiCall", "nidVerified"],
        "conditionalArrayValue": ["YES", "NO"]
    },


    {
        "varName": "ccepCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "CCEP Code",
        "grid": 2,

    },

    {
        "varName": "welcomeLetterRecipientSelection",
        "type": "select",
        "enum": [],
        "label": "Welcome Letter Recipient Selection",
        "grid": 2,

    },
    {
        "varName": "smsAlertRequest",
        "type": "select",
        "enum": [
            "Y",
            "N",
            "AB",
            "CCEP",
        ],
        "onKeyDown":true,
        "label": "SMS Alert Request",
        "grid": 2,
    },
];
let CSJsonFormForCasaJointReadOnly = makeReadOnlyObject(JSON.parse(JSON.stringify(CSJsonFormForCasaJoint)));
let BMJsonFormForCasaJoint = makeReadOnlyObject(JSON.parse(JSON.stringify(CSJsonFormForCasaJoint.concat(CSJsonFormForCasaServiceAll))));
let BOMJsonFormForCasaJoint = makeReadOnlyObject(JSON.parse(JSON.stringify(makeGridObject(CSJsonFormForCasaJoint.concat(CSJsonFormForCasaServiceAll)))));

let MAKERJsonFormForCasaJoint = MAKERJsonFormForCasaIndividual;
let MAKERJsonFormForCasaJointLast = MAKERJsonFormForCasaIndividualLast;
let CHECKERJsonFormForCasaJoint = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForCasaJoint)));
let CHECKERJsonFormForCasaJointLast = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForCasaJointLast.concat(TP).concat(KYC))));

//###Casa CS ,BM  ,BOM ,MAKER,CHECKER    Exist JOINT#####################
let CSJsonFormForCasaJointExist = [
    {
        "varName": "eqmTicketNumber",
        "type": "text",

        "label": "EQM Ticket Number",
        "grid": 2,
    },
    {
        "varName": "branchSolId",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "SOL ID",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "dstFlag",
        "conditionalVarValue": true,
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "readOnly": true,
        "label": "CB Number",
        "grid": 2,
    },
    {
        "varName": "accountSource",
        "type": "select",
        "label": "Account Source ",
        "grid": 2,
        "errorMessage": "Error",
        "required": true,
        "enum": [
            "Conventional"
           // "Islamic"
        ],


    },
    {
        "varName": "customerStatus",
        "type": "select",
        "label": "Customer Status ",
        "grid": 2,
        "errorMessage": "Error",
        "required": true,
        "readOnly":true,
        "enum": [
            "Normal Customer",
            "Staff",
            "Priority",
        ],


    },
    {
        "varName": "accountType",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type *",
        "enum": [
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
    }, {
        "varName": "accountType",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type *",
        "enum": [
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "accountTypes",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type *",
        "enum": [
            "Insta Pack",
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
    },
    {
        "varName": "accType",
        "type": "select",
        "required": true,
        "errorMessage": "Error",
        "label": "Account Type ",
        "enum": [
            "SAVINGS",
            "CURRENT",
            /* "FCY",
             "RFCD"*/,
        ],
        "grid": 2,
    },

    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "label": "Account Number",
        "errorMessage": "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["accountTypes", "customerStatus"],
        "conditionalArrayValue": ["Insta Pack", "Normal Customer"],

        "grid": 2,
    },

    {
        "varName": "customerTitle",
        "type": "select",
        "enum": [
            "JOINT",
            "MINOR",

        ],
        "readOnly": true,
        "required": true,
        "label": "Customer Title ",
        "grid": 2,

    },
    {
        "varName": "customerName",
        "type": "text",
        "readOnly": true,

        "multiline": true,
        "label": "Customer Name ",
        "grid": 2,

    },
    {
        "varName": "shortName",
        "type": "text",
        "readOnly": true,

        "multiline": true,
        "label": "Short Name",
        "grid": 2,

    },
    {
        "varName": "occupationCodeNormal",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
        "grid": 2,

    },
    {
        "varName": "occupationCodePriority",
        "type": "select",
        "enum": [],
        "label": "Occupation Code *",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
        "grid": 2,

    },
    /* {
         "varName": "occupationCodeStaff",
        "type": "select",
        "enum": [
            "E",
        ],
         "label": "Occupation Code",
         "conditional": true,
         "conditionalVarName": "customerStatus",
         "conditionalVarValue": "Staff",

         "grid": 2,

     },
     {
          "varName": "staffIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
         "label": "Staff Indicator",
         "conditional": true,
         "conditionalVarName": "customerStatus",
         "conditionalVarValue": "Staff",
         "grid": 2,

     },
     {
        "varName": "staffIdNumber",
        "type": "text",
        "label": "Staff ID Number",
        asterisk: true,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
        "grid": 2,

    },
     {
         "varName": "dob",
         "type": "date",
         "maxDate": true,

         "label": "Date Of Birth ",
         "grid": 2,


     },*/
    {
        "varName": "designatedEmail",
        "type": "text",

        "readOnly": true,
        "label": "Designated Email ",
        "multiline": true,
        "errorMessage": "Error", "grid": 2,


    },
    {
        "varName": "mobileTypeIndividual",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "readOnly":true,
        // "required":true,
        "label": "Designated Mobile Type",
        "grid": 2,
    },
    {
        "varName": "countryCodeIndividual",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Designated Country Code ",
        "asteriks": true,
        "conditional": true,
        "readOnly":true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "designatedMobileNumber",
        "type": "text",
        "readOnly": true,
        "label": "Designated Mobile Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",

    },


    {
        "varName": "designatedMobileNumber",
        "type": "text",
        // "validation": "phone",
        "phonePrefix": true,
        "readOnly": true,
        "label": "Designated Mobile Number ",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Local",

    },

    {
        "varName": "mobileTypeIndividual2",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "readOnly":true,
        // "required":true,
        "label": "Second Mobile Type",
        "grid": 2,
    },
    {
        "varName": "countryCodeIndividual2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Second Mobile Country Code ",
        "asteriks": true,
        "readOnly":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "secondePhoneNumber",
        "type": "text",
        //"validation": "phone",
        "phonePrefix": true,
        "label": "Mobile Number 2",
        "readOnly":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Local",
        "grid": 2,


    },
    {
        "varName": "secondePhoneNumber",
        "type": "text",
        "label": "Mobile Number 2",
        "readOnly":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Overseas",
        "grid": 2,


    },
    {
        "varName": "SelectCommunicationAddress",
        "type": "select",
        "enum": [
            "Present",
            "Permanent",
            "Office",
        ],
        "label": "Select Communication Address ",
        "grid": 2
    },
    {
        "varName": "communicationAddress1",
        "type": "text",
        "multiline": true,
        "readOnly": true,
        "label": "Communication Address 1 ",
        "grid": 3,

    },

    {
        "varName": "communicationAddress2",
        "type": "text",
        "multiline": true,
        "readOnly": true,
        "label": "Communication Address 2",
        "grid": 3
    },

    {
        "varName": "city2",
        "label": "City",
        "readOnly": true,
        "type": "autoCompleteValueReturn",
        "enum": [],
        "grid": 2
    },

    {
        "varName": "state2",
        "readOnly": true,
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 2
    },

    {
        "varName": "postalCode3",
        "type": "text",

        "readOnly": true,
        "label": "Postal Code",
        "maxLength":6,
        "grid": 2
    },
    {
        "varName": "country",
        "type": "autoCompleteValueReturn",
        "enum": [],

        "label": "Country",
        "grid": 2
    },
    {
        "varName": "schemeCodeForSavings",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Scheme Code *",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "SAVINGS",
    },
    {
        "varName": "schemeCodeForCurrent",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Scheme Code *",
        "grid": 2,
        "conditional": true,

        "conditionalVarName": "accType",
        "conditionalVarValue": "CURRENT",
    },
    {
        "varName": "schemeCodeForSavings",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Scheme Code *",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "FCY",
    },
    {
        "varName": "schemeCodeForSavings",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Scheme Code *",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "RFCD",
    },
    {
        "varName": "currency",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Currency ",

        "grid": 2,

    },
    {
        "varName": "rmCode",
        "type": "textApiCall",
        "label": "RM Code ",
        "required":true,
        //"validation":"rmCode", //no need
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
        "grid": 2,
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "label": "RM Code ",
        "required":true,
        //"validation":"rmCode", //no need
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
        "grid": 2,
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "RM Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["rmCodeApiCall", "customerStatus"],
        "conditionalArrayValue": [true, "Normal Customer"],
        "grid": 2,
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "RM Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["rmCodeApiCall", "customerStatus"],
        "conditionalArrayValue": [true, "Staff"],
        "grid": 2,
    },
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "onKeyDown": true,
        "enum": [],
        "required": true,

        "label": "Sector Code ",
        "grid": 2,
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,

        "conditional": true,
        "conditionalVarName": "sectorCodeApicall",
        "conditionalVarValue": true,
        "label": "Sub Sector Code",
        "grid": 2,
    },

    {
        "varName": "priorityCenterCode",
        "type": "select",
        "enum": [
           "PR104",
            "PR142",
            "PR177",
            "PR186",
            "PR187",
            "PR195",
            "PR208",
            "PR169",
        ],
        "errorMessage": "Error",
        "label": "Priority Center Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRMCode",
        "type": "textApiCall",
        "errorMessage": "Error",
        //"validation":"rmCode", //no need
        "label": "Monitoring RM Code",
        "required":true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRmCodeName",
        "type": "textApiCall",
        "errorMessage": "Error",
        //"validation":"rmCode", //no need
        "label": "Monitoring RM Name",
        "required":true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["customerStatus", "monitoringRMCodeApiCall"],
        "conditionalArrayValue": ["Priority", true],
    },
    {
        "varName": "rmCode",
        "type": "textApiCall",
        "errorMessage": "Error",
        "required":true,
        "label": "Acquiring RM Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "Acquiring Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["customerStatus", "rmCodeApiCall"],
        "conditionalArrayValue": ["Priority", true],
        "grid": 2,
    },

    {
        "varName": "primaryCBHolderforPriority",
        "type": "text",
        "errorMessage": "Error",
        "label": "Primary CB Holder for Priority",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "relationshipwithPrimaryCBHolder",
        "type": "text",
        "errorMessage": "Error",
        "label": "Relationship with Primary CB Holder",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "functionCodeforChequeBookWaiver",
        "type": "text",
        "errorMessage": "Error",
        "readOnly": true,
        "label": "Function Code for Cheque Book Waiver",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "waiverTypeforChequeBookWaiver",
        "type": "text",
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Waiver Type  for Cheque Book Waiver",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "waiverField",
        "type": "text",
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Waiver Field",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },


    /* {
         "varName": "introducerId",
         "type": "textApiCall",

         "label": "Introducer ID",
         "grid": 2,

     },
     {
         "varName": "introducerName",
         "type": "text",
         "multiline": true,
         "readOnly": true,
         "label": "Introducer Name",
         "grid": 2,
         "conditional": true,
         "conditionalVarName": "introducerIdApiCall",
         "conditionalVarValue": "YES",
     },
 */
    {
        "varName": "ccepCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "CCEP Code",
        "grid": 2,

    },

    {
        "varName": "welcomeLetterRecipientSelection",
        "type": "select",
        "enum": [],
        "label": "Welcome Letter Recipient Selection",
        "grid": 2,

    },
    {
        "varName": "smsAlertRequest",
        "type": "select",
        "enum": [
            "Y",
            "N",
            "AB",
            "CCEP",
        ],
        "required":true,
        "onKeyDown":true,
        "label": "SMS Alert Request",
        "grid": 2,
    },

];
let CSJsonFormForCasaJointExistReadOnly = makeReadOnlyObject(JSON.parse(JSON.stringify(CSJsonFormForCasaJointExist)));
let BMJsonFormForCasaJointExist = makeReadOnlyObject(JSON.parse(JSON.stringify(CSJsonFormForCasaJointExist.concat(CSJsonFormForCasaServiceAllExist))));
let BOMJsonFormForCasaJointExist = makeReadOnlyObject(JSON.parse(JSON.stringify(makeGridObject(CSJsonFormForCasaJointExist.concat(CSJsonFormForCasaServiceAllExist)))));
;
let MAKERJsonFormForCasaJointExist = MAKERJsonFormForCasaIndividual;
let MAKERJsonFormForCasaJointLastExist = MAKERJsonFormForCasaIndividualLast;
let CHECKERJsonFormForCasaJointExist = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForCasaJointExist)));
let CHECKERJsonFormForCasaJointLastExist = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForCasaJointLastExist.concat(TP).concat(KYC))));

//###Casa CS ,BM  ,BOM ,MAKER,CHECKER   New  PROPRIETORSHIP#####################

let CSJsonFormForCasaProprietorship = [

    {
        "varName": "eqmTicketNumber",
        "type": "text",
        "validation": "numeric",
        "label": "EQM Ticket Number",
        "grid": 2,
    },

    {
        "varName": "branchSolId",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "SOL ID",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "dstFlag",
        "conditionalVarValue": true,

    },
    {
        "varName": "branchSolId",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "SOL ID",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "dstFlag",
        "conditionalVarValue": "YES",

    },

    {
        "varName": "customerStatus",
        "type": "select",
        "label": "Customer Status",
        "grid": 2,
        "errorMessage": "Error",
        "required": true,
        "enum": [
            "Normal Customer",
            "Priority",
        ],


    },
    {
        "varName": "accountType",
        "type": "select",
        "errorMessage": "Error",
        "required":true,
        "label": "Product Type *",
        "enum": [
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "accountTypes",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type *",
        "required":true,
        "enum": [
            "Insta Pack",
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
    },
    {
        "varName": "accType",
        "type": "text",
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Account Type ",

        "grid": 2,
    },

    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "validation": "numeric",
        "required": true,
        "label": "Account Number",
        "errorMessage": "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["accountTypes", "customerStatus"],
        "conditionalArrayValue": ["Insta Pack", "Normal Customer"],

        "grid": 2,
    },
    {
        "varName": "customerTitle",
        "type": "select",
        "enum": [
            "BANK",
            "M/S"
        ],
        "required":true,
        "label": "Customer Title ",
        "grid": 2,

    },
    {
        "varName": "customerName",
        "type": "text",
        "validation": "string",
        "multiline": true,
        "required":true,
        "label": "Company Name",
        "grid": 2,

    },

    {
        "varName": "shortName",
        "type": "text",
        "validation": "string",
        "multiline": true,
        "required":true,
        "label": "Short Name",
        "grid": 2,

    },

    {
        "varName": "occupationCodeNormal",
        "type": "select",
        "enum": [],
        "required":true,
        "label": "Occupation Code",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
        "grid": 2,

    },
    {
        "varName": "occupationCodePriority",
        "type": "select",
        "enum": [],
        "required":true,
        "label": "Occupation Code",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
        "grid": 2,

    },


    {
        "varName": "designatedEmail",
        "type": "text",
        "validation": "email",
        "label": "Designated Email ",
        "required":true,
        "multiline": true,
        "errorMessage": "Error", "grid": 2,


    },
    {
  "varName": "mobileTypeIndividual",
  "type": "select",
  "enum": [
   "Local",
   "Overseas",
  ],
  "required":true,
  "label": "Designated Mobile Type ",
  "grid": 2,
 },
    {
        "varName": "countryCodeIndividual",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required":true,
        "label": "Designated Mobile Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "designatedMobileNumber",
        "type": "text",
        "label": "Designated Mobile Number ",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",

    },


    {
        "varName": "designatedMobileNumber",
        "type": "text",
        // "validation": "phone",
        "phonePrefix": true,
        "label": "Designated Mobile Number ",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Local",

    },

    {
        "varName": "mobileTypeIndividual2",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        // "required":true,
        "label": "Second Mobile Type",
        "grid": 2,
    },
    {
        "varName": "countryCodeIndividual2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Second Mobile Country Code",
        "asteriks": true,
        "required":true,
        //"required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "phone2",
        "type": "text",
        "validation": "phone",
        "phonePrefix": true,
        "label": "Mobile Number 2",
        // "required":true,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Local",
        "grid": 2,


    },
    {
        "varName": "phone2",
        "type": "text",
        "label": "Mobile Number 2",
        //"required":true,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Overseas",
        "grid": 2,


    },

    {
        "varName": "eTin",
        "type": "text",
        "validation": "etin",
        "required":true,
        "label": "Company E-TIN",
        "grid": 2
    },
    {
        "varName": "tradeLicense",
        "type": "text",
        "required":true,
        "label": "Trade License",
        "grid": 2
    },
    {
        "varName": "certificateIncorporation",
        "type": "text",
        "label": "Certificate of Incorporation",
        "grid": 2
    },
    /*{
        "varName": "SelectCommunicationAddress",
        "type": "select",
        "enum": [
            "Company Registered Address",
            "Business/ Office Address",
            "Factory Address"
        ],
        "required": true,
        "label": "Select Communication Address ",
        "grid": 2
    },*/
    {
        "varName": "communicationAddress1",
        "type": "text",
        "validationB":"length",
        "maxLength":45,
        "required":true,
        "label": "Mailing Address 1",
        "multiline": true,
        "errorMessage": "Error",
        "grid": 2,

    },
    {
        "varName": "communicationAddress2",
        "type": "text",
        "validationB":"length",
        "maxLength":45,
        "label": "Mailing Address 2",
        "multiline": true,
        "errorMessage": "Error",
        "grid": 2,


    },
    {
        "varName": "city2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required":true,
        "label": "City",
        "grid": 2,
    },
    {
        "varName": "state2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required":true,
        "label": "Division(State)",
        "grid": 2,
    },
    {
        "varName": "postalCode3",
        "type": "text",
        "required":true,
        "label": "Postal Code",
        "maxLength":6,
        "grid": 2,
    },
    {
        "varName": "country",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required":true,
        "label": "Country",
        "grid": 2,
    },

    {
  "varName": "schemeCodeForSavings",
  "type": "autoCompleteValueReturn",
  "enum": [],
  "required": true,
  "label": "Scheme Code ",
  "grid": 2,
  "conditional": true,
  "conditionalVarName": "accType",
  "conditionalVarValue": "SAVINGS",
 },
 {
  "varName": "schemeCodeForCurrent",
  "type": "autoCompleteValueReturn",
  "enum": [],
  "required": true,
  "label": "Scheme Code ",
  "grid": 2,
  "conditional": true,
  "conditionalVarName": "accType",
  "conditionalVarValue": "CURRENT",
 },

    {
        "varName": "currency",
        "label": "Currency",
        "required": true,
        "grid": 2,
        "type": "autoCompleteValueReturn",
        "enum": [],
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "label": "RM Code ",
        "required":true,
        //"validation":"rmCode", //no need
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
        "grid": 2,
    },

    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "RM Name",
        "readOnly":true,
        "conditionalArray": true,
        "conditionalArrayList": ["rmCodeApiCall", "customerStatus"],
        "conditionalArrayValue": [true, "Normal Customer"],
        "grid": 2,
    },
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "onKeyDown": true,
        "enum": [],
        "label": "Sector Code",
        "grid": 2,
        required: true,
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Sector Code",
        "grid": 2,
        required: true,
        "conditional": true,
        "conditionalVarName": "sectorCodeApicall",
        "conditionalVarValue": true,
    },

    {
        "varName": "priorityCenterCode",
        "type": "select",
        "enum": [
           "PR104",
            "PR142",
            "PR177",
            "PR186",
            "PR187",
            "PR195",
            "PR208",
            "PR169",
        ],
        "errorMessage": "Error",
        "label": "Priority Center Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRMCode",
        "type": "textApiCall",
        "errorMessage": "Error",
        //"validation":"rmCode", //no need
        "label": "Monitoring RM Code",
        "required":true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRmCodeName",
        "type": "textApiCall",
        "errorMessage": "Error",
        //"validation":"rmCode", //no need
        "label": "Monitoring RM Name",
        "readOnly":true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["customerStatus", "monitoringRMCodeApiCall"],
        "conditionalArrayValue": ["Priority", true],
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "errorMessage": "Error",
        "label": "Acquiring RM Code",
        "required":true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "Acquiring Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["customerStatus", "rmCodeApiCall"],
        "conditionalArrayValue": ["Priority", true],
        "grid": 2,
    },
    {
        "varName": "primaryCBHolderforPriority",
        "type": "text",
        "errorMessage": "Error",
        "label": "Primary CB Holder for Priority",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "relationshipwithPrimaryCBHolder",
        "type": "text",
        "errorMessage": "Error",
        "label": "Relationship with Primary CB Holder",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "functionCodeforChequeBookWaiver",
        "type": "text",
        "errorMessage": "Error",
        "label": "Function Code for Cheque Book Waiver",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "waiverTypeforChequeBookWaiver",
        "type": "text",
        "errorMessage": "Error",
        "label": "Waiver Type  for Cheque Book Waiver",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "waiverField",
        "type": "text",
        "errorMessage": "Error",
        "label": "Waiver Field",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "smsAlertRequest",
        "type": "select",
        "enum": [
            "Y",
            "N",
            "AB",
            "CCEP",
        ],
        "required":true,
        "onKeyDown":true,
        "label": "SMS Alert Request",
        "grid": 2,
    },

];

 let BMJsonFormForCasaProprietorship = makeReadOnlyObject(JSON.parse(JSON.stringify(CSJsonFormForCasaProprietorship.concat(CSJsonFormForCasaServiceAll))));
let BOMJsonFormForCasaProprietorship = makeReadOnlyObject(JSON.parse(JSON.stringify(makeGridObject((CSJsonFormForCasaProprietorship.concat(CSJsonFormForCasaServiceAll))))));
let MAKERJsonFormForCasaProprietorship = [
    {
        "type": "title",
        "label": "MIS RELATED",
        "grid": 12,
    },
    {
        "varName": "missolId",
        "type": "text",
        //sol id
        "label": "SOL ID",
        "grid": 6,
    },

    /* {
         "varName": "misprimarySolId",
         "type": "text",
         //sol id
         "label": "Primary Sol ID",
         "grid": 6,
     },
 */
    {
        "varName": "misagentBankingOutletFreeCode1",
        "type": "select",
        "enum": [],
        "label": "Agent Banking Outlet/ Free Code 1",
        "grid": 6,
    },

    {
        "varName": "miscustomerIdCifId",
        "type": "text",
        "required": true,
        //CB Number
        "label": "Customer ID/CIF ID",
        "grid": 6,
    },

    {
        "varName": "misaCNumber",
        "type": "text",
        "required": true,
        //Account  Number
        "label": "A/C Number",
        "grid": 6,
    },

    {
        "varName": "miscorporateName",
        "type": "text",
        "required": true,
        //Company Name
        "label": "Corporate Name",
        "grid": 6,
    },

    {
        "varName": "misshortName",
        "type": "text",
        "required": true,
        //short Name
        "label": "Short Name",
        "grid": 6,
    },

    {
        "varName": "misstatus",
        "type": "select",
        "enum": [],
        //Customer status
        "label": "Status ",
        "grid": 6,
    },

    {
        "varName": "misentityClassOccupationCode",
        "type": "select",
        "enum": [],
        //occupation code
        "label": "Entity Class/Occupation Code",
        "grid": 6,
    },

    {
        "varName": "misconstitution",
        "type": "select",
        "enum": [],
        "label": "Constitution",
        "grid": 6,
    },

    {
        "varName": "misphone",
        "type": "text",
        "required": true,
        "label": "Phone",
        "grid": 6,
    },

    {
        "varName": "mistradeServiceAvailTradeService",
        "type": "select",
        "enum": [],
        "label": "Trade Service/Avail Trade service",
        "grid": 6,
    },

    {
        "varName": "misinlandTradeAllowed",
        "type": "select",
        "enum": [],
        "label": "Inland Trade allowed",
        "grid": 6,
    },

    {
        "varName": "miscustomerNative",
        "type": "select",
        "enum": [],
        "label": "Customer Native",
        "grid": 6,
    },

    {
        "varName": "mispriorityCenterCodeFreeCode1",
        "type": "select",
        "enum": [],
        "label": "Priority Center Code/Free Code 1",
        "grid": 6,
    },

    {
        "varName": "miscorporateRmCodeFreeCode2",
        "type": "select",
        "enum": [],
        "label": "Corporate RM code/Free Code 2",

        "grid": 6,
    },

    {
        "varName": "mispriorityMonitoringRmCodeFreeCode3",
        "type": "select",
        "enum": [],
        "label": "Priority Monitoring  RM Code/Free Code 3",
        "grid": 6,
    },

    {
        "varName": "miscorporateBusinessSegmentCodeFreeCode4",
        "type": "select",
        "enum": [],
        "label": "Corporate Business Segment code/ Free Code 4",
        "grid": 6,
    },

    {
        "varName": "misprimaryCbHolderForPriorityFreeText8",
        "type": "text",
        "label": "Primary CB Holder for Priority/Free Text 8",
        "grid": 6,
    },

    {
        "varName": "misrelationshipWithPrimaryCbHolderFreeText9",
        "type": "text",
        "label": "Relationship with Primary CB Holder/Free Text 9",
        "grid": 6,
    },

    {
        "varName": "misfunctionCodeForChequeBookWaiver",
        "type": "select",
        "enum": [],
        "label": "Function Code for Cheque Book Waiver",
        "grid": 6,
    },

    {
        "varName": "miswaiverTypeForChequeBookWaiver",
        "type": "select",
        "enum": [],
        "label": "Waiver Type  for Cheque Book Waiver",
        "grid": 6,
    },

    {
        "varName": "misbbkSegmentCodeFreeCode7",
        "type": "select",
        "enum": [],
        "label": "BBK Segment Code",
        "grid": 6,
    },



    {
        "varName": "miswaiverFieldFreeCode10",
        "type": "select",
        "enum": [],
        "label": "Waiver Field",
        "grid": 6,
    },

    {
        "varName": "misccepCodeFreeCode9",
        "type": "select",
        "enum": [],
        "label": "CCEP Code/Free Code 9",
        "grid": 6,
    },

    {
        "varName": "mispepStatusFreeText10",
        "type": "text",
        "label": "PEP Status/Free Text 10",
        "grid": 6,
    },

    {
        "varName": "mismigratedCifsDocumentIdsFreeText13",
        "type": "text",
        "label": "Migrated  CIFs Document ID's/Free Text 13",
        "grid": 6,
    },

    {
        "varName": "missourceOfIncome",
        "type": "text",
        "label": "Source of Income",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Currency Code",
        "grid": 12,
    },
    {
        "varName": "currencyCodecurrencyCodeCcy",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Currency Code/CCY",
        //currency
        "grid": 6,
    },

    {
        "type": "title",
        "label": "Mailing Address",
        "grid": 12,
    },
    {
        "varName": "mailingAddresspreferredAddressType>AddAddressDetails",
        "type": "select",
        "enum": [],
        //selection address
        "label": "Preferred Address type",
        "grid": 6,
    },

    {
        "varName": "mailingAddressaddressFormat",
        "type": "select",
        "enum": [],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "mailingAddressaddressType",
        "type": "select",
        "enum": [],
        "label": "Address type",
        "grid": 6,
    },

    {
        "varName": "mailingAddressaddressLabel",
        "type": "select",
        "enum": [],
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "mailingAddressaddressField1",
        "type": "text",
        "label": "Address Field 1",
        //communincation address1
        "grid": 6,
    },

    {
        "varName": "mailingAddressaddressField2",
        "type": "text",
        //communincation address2
        "label": "Address Field 2",
        "grid": 6,
    },

    {
        "varName": "mailingAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",

        //city
        "grid": 6,
    },

    {
        "varName": "mailingAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        //state
        "grid": 6,
    },

    {
        "varName": "mailingAddresspostalCode",
        "type": "text",
        "validation": "numeric",
        "label": "Postal Code",
        "maxLength":6,
        //postal
        "grid": 6,
    },

    {
        "varName": "mailingAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        //country
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Company Registered Address",
        "grid": 12,
    },
    {
        "varName": "companyRegisteredaddressFormat",
        "type": "select",
        "enum": [],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "companyRegisteredaddressType",
        "type": "select",
        "enum": [],
        "label": "Address type",
        "grid": 6,
    },

    {
        "varName": "companyRegisteredaddressLabel",
        "type": "select",
        "enum": [],
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "companyRegisteredaddressField1",
        "type": "text",
        "label": "Address Field 1",
        "grid": 6,
    },

    {
        "varName": "companyRegisteredaddressField2",
        "type": "text",
        "label": "Address Field 2",
        "grid": 6,
    },

    {
        "varName": "companyRegisteredcity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "companyRegisteredstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
    },

    {
        "varName": "companyRegisteredpostalCode",
        "type": "text",
        "validation": "numeric",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 6,
    },

    {
        "varName": "companyRegisteredcountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Factory Address",
        "grid": 12,
    },
    {
        "varName": "factoryAddressaddressFormat",
        "type": "select",
        "enum": [],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "factoryAddressaddressType",
        "type": "select",
        "enum": [],
        "label": "Address type",
        "grid": 6,
    },

    {
        "varName": "factoryAddressaddressLabel",
        "type": "select",
        "enum": [],
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "factoryAddressaddressField1",
        "type": "text",
        "label": "Address Field 1",
        "grid": 6,
    },

    {
        "varName": "factoryAddressaddressField2",
        "type": "text",
        "label": "Address Field 2",
        "grid": 6,
    },

    {
        "varName": "factoryAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "factoryAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
    },

    {
        "varName": "factoryAddresspostalCode",
        "type": "text",
        "validation": "numeric",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 6,
    },

    {
        "varName": "factoryAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Office Address",
        "grid": 12,
    },
    {
        "varName": "officeAddressaddressFormat",
        "type": "select",
        "enum": [],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "officeAddressaddressType",
        "type": "select",
        "enum": [],
        "label": "Address type",
        "grid": 6,
    },

    {
        "varName": "officeAddressaddressLabel",
        "type": "select",
        "enum": [],
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "officeAddressaddressField1",
        "type": "text",
        "label": "Address Field 1",
        "grid": 6,
    },

    {
        "varName": "officeAddressaddressField2",
        "type": "text",
        "label": "Address Field 2",
        "grid": 6,
    },

    {
        "varName": "officeAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "officeAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
    },

    {
        "varName": "officeAddresspostalCode",
        "type": "text",
        "validation": "numeric",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 6,
    },

    {
        "varName": "officeAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Contact Number - Phone : Option For Add new",
        "grid": 12,
    },
    {
        "varName": "contactNumberpreferredContactNoType",
        "type": "select",
        "enum": [],
        "label": "Preferred Contact no type",
        "grid": 6,
    },

    {
        "varName": "contactNumbercontactType",
        "type": "select",
        "enum": [],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNoEmailId",
        "type": "select",
        "enum": [],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNo",
        "type": "text",
        "label": "Phone No",
        //phone number 1
        "grid": 6,
    },

    {
        "varName": "contactNumbercontactType",
        "type": "select",
        "enum": [],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNoEmailId",
        "type": "select",
        "enum": [],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNo",
        "type": "text",
        "label": "Phone No",
        //phone number 2
        "grid": 6,
    },

    {
        "varName": "contactNumbercontactType",
        "type": "select",
        "enum": [],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNoEmailId",
        "type": "select",
        "enum": [],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNo",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Contact Number - Email (Add New Option)",
        "grid": 12,
    },
    {
        "varName": "contactNumberEmailpreferedEmailIdType",
        "type": "select",
        "enum": [],
        "label": "Prefered Email ID type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailType",
        "type": "select",
        "enum": [],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId",
        "type": "select",
        "enum": [],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmaildesignatedEmailAddressEmailId",
        "type": "text",
        "label": "Designated Email Address/Email ID",
        //email
        "grid": 6,
    },

    {
        "type": "title",
        "label": "Trade License",
        "grid": 12,
    },
    {
        "varName": "tradeLicensedocumentType",
        "type": "select",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "tradeLicensedocumentCode",
        "type": "select",
        "enum": [],
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "tradeLicensetlNumberUniqueId",
        "type": "text",
        "validation": "numeric",
        "label": "TL Number/Unique ID",
        //trade
        "grid": 6,
    },

    {
        "varName": "tradeLicenseplaceOfIssue",
        "type": "select",
        "enum": [],
        "label": "Place of issue",
        "grid": 6,
    },

    {
        "varName": "tradeLicensecountryOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country of Issue",
        "grid": 6,
    },

    {
        "varName": "tradeLicenseissueDate",
        "type": "select",
        "enum": [],
        "label": "Issue date",
        "grid": 6,
    },

    {
        "varName": "tradeLicenseexpiryDate",
        "type": "text",
        "label": "Expiry date",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Certificate of Incorporation",
        "grid": 12,
    },
    {
        "varName": "certificateOfIncorporationdocumentType",
        "type": "select",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "certificateOfIncorporationdocumentCode",
        "type": "select",
        "enum": [],
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "certificateOfIncorporationcertificateOfIncorporationNumberUniqueId",
        "type": "text",
        "validation": "numeric",
        "label": "Certificate of Incorporation Number/Unique ID",
        //certificate of incorporation
        "grid": 6,
    },

    {
        "varName": "certificateOfIncorporationplaceOfIssue",
        "type": "select",
        "enum": [],
        "label": "Place of issue",
        "grid": 6,
    },

    {
        "varName": "certificateOfIncorporationcountryOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country of Issue",
        "grid": 6,
    },

    {
        "varName": "certificateOfIncorporationissueDate",
        "type": "select",
        "enum": [],
        "label": "Issue date",
        "grid": 6,
    },

    {
        "varName": "certificateOfIncorporationexpiryDate",
        "type": "text",
        "label": "Expiry date",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Registration Number",
        "grid": 12,
    },
    {
        "varName": "registrationNumberdocumentType",
        "type": "select",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "registrationNumberdocumentCode",
        "type": "select",
        "enum": [],
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "registrationNumbercertificateOfIncorporationNumberUniqueId",
        "type": "text",
        "validation": "numeric",
        "label": "Certificate of Incorporation Number/Unique ID",
        "grid": 6,
    },

    {
        "varName": "registrationNumberplaceOfIssue",
        "type": "select",
        "enum": [],
        "label": "Place of issue",
        "grid": 6,
    },

    {
        "varName": "registrationNumbercountryOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country of Issue",
        "grid": 6,
    },

    {
        "varName": "registrationNumberissueDate",
        "type": "select",
        "enum": [],
        "label": "Issue date",
        "grid": 6,
    },

    {
        "varName": "registrationNumberexpiryDate",
        "type": "text",
        "label": "Expiry date",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "E-Tin Number",
        "grid": 12,
    },


    {
        "varName": "etindocumentType",
        "type": "select",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "etindocumentCode",
        "type": "select",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "etinetinNumber",
        "type": "text",
        "validation":"etin",
        //etin
        "label": "E - Tin Number/Unique ID",
        "grid": 6,
    },
];

let MAKERJsonFormForCasaProprietorshipLast = [
    {
        "type": "title",
        "label": "Other Bank Information",
        "grid": 12,
    },
    {
        "varName": "otherBankInformationModeOfOperation",
        "type": "text",
        "label": "Mode of Operation",
        "grid": 6,
    },

    {
        "varName": "otherBankbankName",
        "type": "select",
        "enum": [],
        "label": "Bank Name",
        "grid": 6,
    },

    {
        "varName": "otherBankbranchName",
        "type": "select",
        "enum": [],
        "label": "Branch Name",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Introducer",
        "grid": 12,
    },
    {
        "varName": "introducerrelationshipType>AddRelationship",
        "type": "select",
        "enum": [],
        "label": "Relationship Type>Add relationship",
        "grid": 6,
    },

    {
        "varName": "introducercifType",
        "type": "select",
        "enum": [],
        "label": "CIF Type",
        "grid": 6,
    },

    {
        "varName": "introducerentityType",
        "type": "select",
        "enum": [],
        "label": "Entity Type",
        "grid": 6,
    },

    {
        "varName": "introducerintroducerCustomerIdLastNameOrCorporateName",
        "type": "select",
        "enum": [],
        "label": "Introducer Customer Id/Last name or Corporate Name",
        //introducer CB
        "grid": 6,
    },

    {
        "varName": "introducerintroducerNameLastNameOrCorporateName",
        "type": "text",
        "label": "Introducer Name/Last name or Corporate Name",
        //introducer Name
        "grid": 6,
    },

    {
        "varName": "introducerintroducerStatus",
        "type": "select",
        "enum": [],
        "label": "Introducer Status",
        "grid": 6,
    },

    {
        "varName": "introducersectorCode",
        "type": "select",
        "enum": [],
        "label": "Sector Code",
        //sector code
        "grid": 6,
    },

    {
        "varName": "introducersubSectorCode",
        "type": "select",
        "enum": [],
        //sub sector code
        "label": "Sub Sector Code",
        "grid": 6,
    },

    {
        "varName": "introducerrmCodeFreeCode3",
        "type": "select",
        "enum": [],
        //rm code
        "label": "RM Code/ Free Code 3",

        "grid": 6,
    },

    {
        "varName": "introducerdepositCodeFreeCode7",
        "type": "select",
        "enum": [],
        "label": "Deposit Code/Free Code 7",
        "required": true,
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Scheme Change for Insta Pack",
        "grid": 12,
    },


    {
        "varName": "schemeChangefunctionForSchemeChangeInCaseOfInstaPack",
        "type": "select",
        "enum": [],
        "label": "Function for Scheme Change in case of Insta Pack",
        "grid": 6,
    },

    {
        "varName": "schemeChangetargetSchemeCodeInCaseOfInstaPack",
        "type": "text",
        "label": "Target Scheme Code in case of Insta Pack",
        "grid": 6,
    },

    {
        "varName": "schemeChangetrialMode",
        "type": "select",
        "enum": [],
        "label": "Trial Mode",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "CRM MIS",
        "grid": 12,
    },


    {
        "varName": "crmMissegment ",
        "type": "select",
        "enum": [],
        "label": "Segment  ",
        "grid": 6,
    },

    {
        "varName": "crmMissubSegment",
        "type": "select",
        "enum": [],
        "label": "Sub Segment",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Basic Information Update",
        "grid": 12,
    },
    {
        "varName": "basicInformationprimarySolId",
        "type": "select",
        "enum": [],
        "label": "Primary Sol ID",
        "grid": 6,
    },

    {
        "varName": "basicInformationgender",
        "type": "select",
        "enum": [
            "M",
            "F",
            "O"
        ],
        "label": "Gender",
        "grid": 6,
    },

    {
        "varName": "basicInformationfatherNamePrefferedName",
        "type": "text",
        "label": "Father Name/Preffered Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationmotherNameMothersMaidenName",
        "type": "text",
        "label": "Mother Name/Mothers Maiden Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationmaritalStatus",
        "type": "select",
        "enum": [],
        "label": "Marital Status",
        "grid": 6,
    },

    {
        "varName": "basicInformationspouseNamePurgeRemarks",
        "type": "text",
        "label": "Spouse Name/Purge Remarks",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "basicInformationmaritalStatus",
        "conditionalVarValue":"MARID",
        "validationB":"length",
        "maxLength":240,
    },

    {
        "varName": "basicInformationdob",
        "type": "date",
        "label": "DOB",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "National ID Card",
        "grid": 12,
    },


    {
        "varName": "nationalIdCarddocumentType",
        "type": "select",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "nationalIdCarddocumentTypeDescription",
        "type": "select",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "nationalIdCarddocumentCode",
        "type": "select",
        "enum": [],
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "nationalIdCardnationalIdCardNumberUniqueId",
        "type": "text",
        "label": "National ID Card Number/Unique ID",
        "grid": 6,
    },

    {
        "varName": "nationalIdCardnidVerificationConfirmationIsDocumentVerified",
        "type": "select",
        "enum": [],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Smart ID Card (RSNID)",
        "grid": 12,
    },


    {
        "varName": "smartIdCarddocumentType",
        "type": "select",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "smartIdCarddocumentTypeDescription",
        "type": "select",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "smartIdCarddocumentCode",
        "type": "select",
        "enum": [],
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "smartIdCardsmartCardNumberUniqueId",
        "type": "text",
        "label": "Smart Card Number/Unique ID",
        "grid": 6,
    },

    {
        "varName": "smartIdCardnidVerificationConfirmationIsDocumentVerified",
        "type": "select",
        "enum": [],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
    },

    {
        "type": "title",
        "label": " Passport Number",
        "grid": 12,
    },
    {
        "varName": "passportNumberdocumentType",
        "type": "select",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "passportNumberdocumentTypeDescription",
        "type": "select",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "passportNumberdocumentCode",
        "type": "select",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "passportNumberpassportNumberUniqueId",
        "type": "text",
        "label": "Passport Number/Unique ID",
        "grid": 6,
    },

    {
        "varName": "passportNumberplaceOfIssue",
        "type": "select",
        "enum": [],
        "label": "Place of issue",
        "grid": 6,
    },

    {
        "varName": "passportNumberissueDate",
        "type": "date",
        "label": "Issue date",
        "grid": 6,
    },

    {
        "varName": "passportNumbercountryOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country of Issue",
        "grid": 6,
    },

    {
        "varName": "passportNumberexpiryDate",
        "type": "date",
        "label": "Expiry date",
        "grid": 6,
    },
    {
        "type": "title",
        "label": " Birth Certificate",
        "grid": 12,
    },


    {
        "varName": "birthCertificatedocumentType",
        "type": "select",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "birthCertificatedocumentTypeDescription",
        "type": "select",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "birthCertificatedocumentCode",
        "type": "select",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "birthCertificatebirthCertificateNumberUniqueId",
        "type": "text",
        "label": "Birth Certificate Number/Unique ID",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Driving License",
        "grid": 12,
    },


    {
        "varName": "drivingLicensedocumentType",
        "type": "select",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "drivingLicensedocumentTypeDescription",
        "type": "select",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "drivingLicensedocumentCode",
        "type": "select",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "drivingLicensedrivingLicenseUniqueId",
        "type": "text",
        "label": "Driving License/Unique ID",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Chairman Certificate",
        "grid": 12,
    },


    {
        "varName": "chairmanCertificatedocumentType",
        "type": "select",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificatedocumentTypeDescription",
        "type": "select",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificatedocumentCode",
        "type": "select",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificatechairmanCertificateUniqueId",
        "type": "text",
        "label": "Chairman Certificate/Unique ID",
        "grid": 6,
    },
    {
        "type": "title",
        "label": " Residence Certificate",
        "grid": 12,
    },


    {
        "varName": "residenceCertificatedocumentType",
        "type": "select",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "residenceCertificatedocumentTypeDescription",
        "type": "select",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "residenceCertificatedocumentCode",
        "type": "select",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "residenceCertificateresidenceCertificateUniqueId",
        "type": "text",
        "label": "Residence Certificate/Unique ID",
        "grid": 6,
    },

    {
        "varName": "residenceCertificateplaceOfBirthFreeText14",
        "type": "text",
        "label": "Place of Birth/Free Text 14",
        "maxLength":15,
        "validationB":"length",
        "grid": 6,
    },

    {
        "varName": "residenceCertificatecountryOfBirthFreeText15",
        "type": "text",
        "label": "Country of Birth/Free Text 15",
        "grid": 6,
    },

    {
        "varName": "residenceCertificatebirthCountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Birth Country",
        "grid": 6,
    },

    {
        "varName": "residenceCertificatenationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Nationality",
        "grid": 6,
    },

    {
        "varName": "residenceCertificateresidingCountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Residing Country",
        "grid": 6,
    },

    {
        "varName": "residenceCertificatenonResident",
        "type": "select",
        "enum": [],
        "label": "Non Resident",
        "grid": 6,
    },

    {
        "varName": "residenceCertificateturnedNonResidentOn",
        "type": "date",
        "label": "Turned Non Resident On",
        "grid": 6,
    },

    {
        "varName": "residenceCertificateminorStatusMinorIndicator",
        "type": "select",
        "enum": [],
        "label": "Minor Status/Minor Indicator",
        "grid": 6,
    },
    {
        "type": "title",
        "label": " E-Tin",
        "grid": 12,
    },


    {
        "varName": "etindocumentType",
        "type": "select",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "etindocumentTypeDescription",
        "type": "select",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "etindocumentCode",
        "type": "select",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "etinetinNumber",
        "type": "text",
        "validation":"etin",
        "label": "E-Tin Number/Unique ID",
        "grid": 6,
    }, {
        "type": "title",
        "label": "Other Photo ID",
        "grid": 12,
    },


    {
        "varName": "photoIddocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "photoIddocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "photoIddocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "photoIdUniqueId",
        "type": "text",
        "label": "Unique ID",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Employer Data",
        "grid": 12,
    },


    {
        "varName": "employerDataprofessionEmploymentType",
        "type": "select",
        "enum": [],
        "label": "Profession /Employment Type",
        "grid": 6,
    },

    {
        "varName": "employerDataemploymentType",
        "type": "select",
        "enum": [],
        "label": "Employment Type",
        "grid": 6,
    },

    {
        "varName": "employerDatanameOfEmployerEmployerName",
        "type": "text",
        "label": "Name of Employer/Employer Name",
        "validationB":"length",
        "maxLength": 50,
        "grid": 6,
    },

    {
        "varName": "employerDatamonthlyIncomeGrossIncome",
        "type": "text",
        "label": "Monthly Income/Gross income",
        "grid": 6,
    },
    {
        "type": "title",
        "label": " Contact Number - Phone : Option For Add new",
        "grid": 12,
    },


    {
        "varName": "contactNumberpreferredContactNoType",
        "type": "select",
        "enum": [],
        "label": "Preferred Contact no type",
        "grid": 6,
    },

    {
        "varName": "contactNumbercontactType",
        "type": "select",
        "enum": [],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNoEmailId",
        "type": "select",
        "enum": [],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNo",
        "type": "text",
        "validation": "numeric",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "contactNumbercontactType",
        "type": "select",
        "enum": [],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNoEmailId",
        "type": "select",
        "enum": [],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNo",
        "type": "text",
        "validation": "numeric",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "contactNumbercontactType",
        "type": "select",
        "enum": [],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNoEmailId",
        "type": "select",
        "enum": [],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNo",
        "type": "text",
        "validation": "numeric",
        "label": "Phone No",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Contact Number - Email",
        "grid": 12,
    },


    {
        "varName": "contactNumberEmailpreferedEmailIdType",
        "type": "select",
        "enum": [],
        "label": "Prefered Email ID type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailType",
        "type": "select",
        "enum": [],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId",
        "type": "select",
        "enum": [],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmaildesignatedEmailAddressEmailId1",
        "type": "text",
        "label": "Designated Email Address/Email ID 1",
         "validation": "email",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailType",
        "type": "select",
        "enum": [],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId",
        "type": "select",
        "enum": [],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailId2",
        "type": "text",
        "label": "Email ID 2",
        "validation": "email",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Mailing Address",
        "grid": 12,
    },


    {
        "varName": "mailingAddresspreferredAddressType>AddAddressDetails",
        "type": "select",
        "enum": [],
        "label": "Preferred Address type>Add Address Details",
        "grid": 6,
    },

    {
        "varName": "mailingAddressaddressFormat",
        "type": "select",
        "enum": [],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "mailingAddressaddrssType",
        "type": "select",
        "enum": [],
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "mailingAddressaddressLabel",
        "type": "select",
        "enum": [],
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "mailingAddressmailingAddressField1",
        "type": "text",
        "label": "Mailing Address Field 1",
        "grid": 6,
    },

    {
        "varName": "mailingAddressmailingAddressField2",
        "type": "text",
        "label": "Mailing Address Field 2",
        "grid": 6,
    },

    {
        "varName": "mailingAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "mailingAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
    },

    {
        "varName": "mailingAddresspostalCode",
        "type": "text",
        "validation": "numeric",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 6,
    },

    {
        "varName": "mailingAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Home Address",
        "grid": 12,
    },


    {
        "varName": "homeAddressaddressFormat",
        "type": "select",
        "enum": [],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "homeAddressaddressaddrssType",
        "type": "select",
        "enum": [],
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "homeAddressaddressaddressLabel",
        "type": "select",
        "enum": [],
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "homeAddressaddresshomeAddressField1",
        "type": "text",
        "label": "Home Address Field 1",
        "grid": 6,
    },

    {
        "varName": "homeAddressaddresshomeAddressField2",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 6,
    },

    {
        "varName": "homeAddressaddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "homeAddressaddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
    },

    {
        "varName": "homeAddressaddresspostalCode",
        "type": "text",
        "validation": "numeric",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 6,
    },

    {
        "varName": "homeAddressaddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Work Address",
        "grid": 12,
    },


    {
        "varName": "workAddressaddressFormat",
        "type": "select",
        "enum": [],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "workAddressaddrssType",
        "type": "select",
        "enum": [],
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "workAddressaddressLabel",
        "type": "select",
        "enum": [],
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "workAddressworkAddressField1",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 6,
    },

    {
        "varName": "workAddressworkAddressField2",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 6,
    },

    {
        "varName": "workAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "workAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
    },

    {
        "varName": "workAddresspostalCode",
        "type": "text",
        "validation": "numeric",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 6,
    },

    {
        "varName": "workAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Present Address",
        "grid": 12,
    },


    {
        "varName": "presentAddressaddressFormat",
        "type": "select",
        "enum": [],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "select",
        "enum": [],
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "presentAddressaddressLabel",
        "type": "select",
        "enum": [],
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "presentAddresspresentAddressField1",
        "type": "text",
        "label": "Present Address Field 1",
        "grid": 6,
    },

    {
        "varName": "presentAddresspresentAddressField2",
        "type": "text",
        "label": "Present Address Field 2",
        "grid": 6,
    },

    {
        "varName": "presentAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "presentAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
    },

    {
        "varName": "presentAddresspostalCode",
        "type": "text",
        "validation": "numeric",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 6,
    },

    {
        "varName": "presentAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Related CB Tagging :",
        "grid": 12,
    },


    {
        "varName": "relatedCbTaggingrelationType",
        "type": "select",
        "enum": [],
        "label": "Relation Type",
        "grid": 6,
    },

    {
        "varName": "relatedCbTaggingrelationCode",
        "type": "select",
        "enum": [],
        "label": "Relation Code",
        "grid": 6,
    },

    {
        "varName": "relatedCbTaggingdesignationCode",
        "type": "select",
        "enum": [],
        "label": "Designation Code",
        "grid": 6,
    },

    {
        "varName": "relatedCbTaggingcifId",
        "type": "select",
        "enum": [],
        "label": "CIF ID",
        "grid": 6,
    },

    {
        "varName": "relatedCbTaggingtitle",
        "type": "text",
        "label": "Title",
        "grid": 6,
    },

    {
        "varName": "relatedCbTaggingname",
        "type": "text",
        "label": "Name",
        "grid": 6,
    },


    {
        "type": "title",
        "label": "Fincore Data Update - Account Level",
        "grid": 12,
    },


    {
        "varName": "FincoreDataUpdatecashLimitDr",
        "type": "text",
        "validation": "numeric",
        "label": "Cash Limit (Dr)",
        "grid": 6,
    },

    {
        "varName": "FincoreDataUpdatecashLimitCr",
        "type": "text",
        "validation": "numeric",
        "label": "Cash Limit (Cr)",
        "grid": 6,
    },

    {
        "varName": "FincoreDataUpdateclearingLimitDr",
        "type": "text",
        "validation": "numeric",
        "label": "Clearing Limit (Dr)",
        "grid": 6,
    },

    {
        "varName": "FincoreDataUpdateclearingLimitCr",
        "type": "text",
        "validation": "numeric",
        "label": "Clearing Limit (Cr)",
        "grid": 6,
    },

    {
        "varName": "FincoreDataUpdatetransferLimitDr",
        "type": "text",
        "validation": "numeric",
        "label": "Transfer Limit (Dr)",
        "grid": 6,
    },

    {
        "varName": "FincoreDataUpdatetransferLimitCr",
        "type": "text",
        "validation": "numeric",
        "label": "Transfer Limit (Cr)",
        "grid": 6,
    },

    {
        "varName": "FincoreDataUpdatenotes",
        "type": "text",
        "label": "Notes",
        "grid": 6,
    },

    {
        "varName": "FincoreDataUpdateaccountStatement",
        "type": "select",
        "enum": [],
        "label": "Account Statement",
        "grid": 6,
    },

    {
        "varName": "FincoreDataUpdatestatementFrequency",
        "type": "select",
        "enum": [],
        "label": "Statement Frequency",
        "grid": 6,
    },

    {
        "varName": "FincoreDataUpdatedispatchMode",
        "type": "select",
        "enum": [],
        "label": "Dispatch Mode",
        "grid": 6,
    },

    {
        "varName": "FincoreDataUpdatenextPrintDate",
        "type": "date",
        "label": "Next Print Date",
        "grid": 6,
    },

    {
        "varName": "FincoreDataUpdateaCStatus",
        "type": "select",
        "enum": [],
        "label": "A/C Status",
        "grid": 6,
    },

    {
        "varName": "FincoreDataUpdatedormantActivationCharge",
        "type": "select",
        "enum": [],
        "label": "Dormant Activation Charge",
        "grid": 6,
    },

    {
        "varName": "FincoreDataUpdatenomineeSelection",
        "type": "select",
        "enum": [],
        "label": "Nominee Selection",
        "grid": 6,
    },

    {
        "varName": "FincoreDataUpdateoccupationCode",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 6,
    },

    {
        "varName": "FincoreDataUpdatefreeText3",
        "type": "text",
        "label": "Free Text 3 ",
        "grid": 6,
    },

    {
        "varName": "FincoreDataUpdatefreeCode6",
        "type": "select",
        "enum": [],
        "label": "Free Code 6",
        "grid": 6,
    },

    {
        "varName": "FincoreDataUpdatefreeCode8",
        "type": "text",
        "label": "Free Code 8",
        "grid": 6,
    },

    {
        "varName": "FincoreDataUpdatefreeText10",
        "type": "text",
        "label": "Free Text 10",
        "grid": 6,
    },

    {
        "varName": "FincoreDataUpdatefreeText12",
        "type": "select",
        "enum": [],
        "label": "Free Text 12",
        "grid": 6,
    },

    {
        "varName": "FincoreDataUpdatefreeCodeFreeText",
        "type": "select",
        "enum": [],
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "FincoreDataUpdatefreeCodeFreeText",
        "type": "select",
        "enum": [],
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "FincoreDataUpdatefreeCodeFreeText",
        "type": "select",
        "enum": [],
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    /*  {
          "varName":"noOfNominee",
         "type": "select",
        "enum": [],
          "label":"No of Nominee",
          "grid":6,
      },

      {
          "varName":"cifIdIndividualId",
         "type": "select",
        "enum": [],
          "label":"CIF ID/Individual ID",
          "grid":6,
      },

      {
          "varName":"nomineeName",
          "type":"text",
          "label":"Nominee Name",
          "grid":6,
      },

      {
          "varName":"relationship",
         "type": "select",
        "enum": [],
          "label":"Relationship",
          "grid":6,
      },

      {
          "varName":"percentage",
         "type":"text",
        "validation":"numeric",
          "label":"Percentage",
          "grid":6,
      },

      {
          "varName":"addressField1",
          "type":"text",
          "label":"Address Field 1 ",
          "grid":6,
      },

      {
          "varName":"addressField2",
          "type":"text",
          "label":"Address Field 2",
          "grid":6,
      },

      {
          "varName":"city",
         "type": "select",
        "enum": [],
          "label":"City",
          "grid":6,
      },

      {
          "varName":"state",
         "type": "select",
        "enum": [],
          "label":"State",
          "grid":6,
      },

      {
          "varName":"postalCode",
         "type":"text",
        "validation":"numeric",
          "label":"Postal Code",
          "maxLength":6,
          "grid":6,
      },

      {
          "varName":"country",
         "type": "select",
        "enum": [],
          "label":"Country",
          "grid":6,
      },

      {
          "varName":"photoIdNumberRegNo",
         "type":"text",
        "validation":"numeric",
          "label":"Photo Id Number/Reg No",
          "grid":6,
      },

      {
          "varName":"nomineeDob",
          "type":"date",
          "label":"Nominee DOB",
          "grid":6,
      },

      {
          "varName":"nomineeMinor",
         "type": "select",
        "enum": [],
          "label":"Nominee Minor",
          "grid":6,
      },

      {
          "varName":"GurdianRequiredOrNot",
         "type": "select",
        "enum": [],
          "label":" Gurdian Required or Not ",
          "grid":6,
      },

      {
          "varName":"guardiansName",
          "type":"text",
          "label":"Guardian's Name",
          "grid":6,
      },

      {
          "varName":"guardianCode",
         "type": "select",
        "enum": [],
          "label":"Guardian Code",
          "grid":6,
      },

      {
          "varName":"guardianPhotoId",
         "type":"text",
        "validation":"numeric",
          "label":"Guardian Photo Id",
          "grid":6,
      },

      {
          "varName":"addressField1",
          "type":"text",
          "label":"Address Field 1",
          "grid":6,
      },

      {
          "varName":"addressField2",
          "type":"text",
          "label":"Address Field 2",
          "grid":6,
      },

      {
          "varName":"city",
         "type": "select",
        "enum": [],
          "label":"City",
          "grid":6,
      },

      {
          "varName":"state",
         "type": "select",
        "enum": [],
          "label":"State",
          "grid":6,
      },

      {
          "varName":"postal",
         "type":"text",
        "validation":"numeric",
          "label":"Postal ",
          "grid":6,
      },

      {
          "varName":"country",
         "type": "select",
        "enum": [],
          "label":"Country",
          "grid":6,
      },*/


    {
        "label": "KYC",
        "type": "title",
        "grid": 12,
    },

    {
        "varName": "functionCode",
        "type": "select",
        "enum": [
            "ADD",
            "UPDATE",
            "DELETE",
        ],
        "label": "Function Code",
        "grid": 6,
    },

    {
        "varName": "customerType",
        "type": "select",
        "enum": [
            "Individual",
            "Non Individual",
            "Deposit",
            "Govt",
        ],
        "label": "Customer Type",
        "grid": 6,
    },

    {
        "varName": "customerAccount",
        "type": "text",
        "validation": "numeric",
        "label": "Customer Account",
        "grid": 6,
    },

    {
        "varName": "aCName",
        "type": "text",
        "label": "A/C Name",
        "grid": 6,
    },

    {
        "varName": "accountType",
        "type": "select",
        "enum": [
            "SAVINGS",
            "CURRENT",
            /* "FCY",
             "RFCD"*/,
        ],
        "label": "Account Type",
        "grid": 6,
    },

    {
        "varName": "accountOrReferenceNo",
        "type": "text",
        "label": "Account or Reference No",
        "grid": 6,
    },

    {
        "varName": "nameOfOfficerOpeningTheAccount",
        "type": "text",
        "label": "Name of Officer Opening the Account:",
        "grid": 6,
    },

    {
        "varName": "natureOfOrganizationDetails",
        "type": "Input",
        "label": "Nature of Organization (Details):",
        "grid": 6,
    },

    {
        "varName": "netWorthOfOrganization",
        "type": "Input",
        "label": "Net Worth of Organization",
        "grid": 6,
    },

    {
        "varName": "sourceSourcesOfFundDetails",
        "type": "text",
        "label": "Source/Sources of Fund (Details):",
        "grid": 6,
    },

    {
        "varName": "documentsToEnsureTheSourceOfFund",
        "type": "select",
        "enum": [],
        "label": "Documents to Ensure the Source of Fund ",
        "grid": 6,
    },

    {
        "varName": "collectedDocumentsHave",
        "type": "select",
        "enum": [],
        "label": "Collected Documents have been verified or not?",
        "grid": 6,
    },

    {
        "varName": "howTheAddressEsOfThe",
        "type": "select",
        "enum": [],
        "label": "How the address (es) of the Organization has been Verified",
        "grid": 6,
    },

    {
        "varName": "hasTheBeneficialOwner",
        "type": "select",
        "enum": [],
        "label": "Has the Beneficial owner of the account been identified",
        "grid": 6,
    },

    {
        "varName": "eTinNo",
        "type": "text",
        "validation":"etin",
        "label": "E-Tin No:",
        "grid": 6,
    },

    {
        "varName": "vatRegistrationNumber",
        "type": "text",
        "label": "Vat Registration number",
        "grid": 6,
    },

    {
        "varName": "companyRegistrationNo",
        "type": "text",
        "label": "Company Registration No",
        "grid": 6,
    },

    {
        "varName": "otherDocumentation",
        "type": "text",
        "label": "Other Documentation",
        "grid": 6,
    },

    {
        "varName": "reasonForOpeningThe",
        "type": "text",
        "label": "Reason for Opening the Account of Foreign Company/Organization",
        "grid": 6,
    },

    {
        "varName": "aNameOfTheRegulatoryAuthority",
        "type": "Input",
        "label": "A) Name of the Regulatory Authority",
        "grid": 6,
    },

    {
        "varName": "bApprovalRelatedInformation",
        "type": "Input",
        "label": "B) Approval Related Information:",
        "grid": 6,
    },

    {
        "varName": "isAnyPoliticallyExposedPerson",
        "type": "select",
        "enum": [],
        "label": "Is any Politically Exposed Person (PEP)/ Influential Person (IP)/ Head or High level official of International Organization and otheir family members or close associates are related with the organization (according to definition of the circular issued by the BFIU)? (If Applicable)",
        "grid": 6,
    },

    {
        "varName": "isThereAnyMatchToTheCustomer",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "Is there any match to the customer's name with the terrorist activity, funding in terrorist activities or listed person or entity on suspicion for Financing of proliferation of weapons of mass destruction under UN Security Council's different regulations and any listed person or banned entity by the Government of Bangladesh according to related laws, rules and circular",
        "grid": 6,
    },

    {
        "varName": "whatDoesTheCustomerDo",
        "type": "select",
        "enum": [],
        "label": "What does the Customer do/in what type of busineess is the customer engaged",
        "grid": 6,
    },

    {
        "varName": "ogranizationNetWorth",
        "type": "select",
        "enum": [],
        "label": "Ogranization Net worth:",
        "grid": 6,
    },

    {
        "varName": "howWasTheAccountOpened",
        "type": "select",
        "enum": [],
        "label": "How was the account opened?  ",
        "grid": 6,
    },

    {
        "varName": "expectedAmountOfMonthlyTotalTransactions",
        "type": "text",
        "label": "Expected Amount of Monthly Total Transactions?",
        "grid": 6,
    },

    {
        "varName": "expectedNumberOfMonthlyTotalTransactions",
        "type": "text",
        "label": "Expected Number of Monthly total Transactions?",
        "grid": 6,
    },

    {
        "varName": "expectedAmountOfMonthlyCashTransactions",
        "type": "text",
        "label": "Expected amount of Monthly Cash Transactions?",
        "grid": 6,
    },

    {
        "varName": "expectedNumberOfMonthlyCashTransactions",
        "type": "text",
        "label": "Expected Number of Monthly Cash Transactions:",
        "grid": 6,
    },

    {
        "varName": "overallRiskAssessment",
        "type": "text",
        "label": "Overall Risk Assessment:",
        "grid": 6,
    },

    {
        "varName": "comments",
        "type": "text",
        "label": "Comments",
        "grid": 6,
    },

    {
        "type": "title",
        "label": "TP",
        "grid": 12,

    },


    {
        "varName": "function",
        "type": "select",
        "enum": [],
        "label": "Function",
        "grid": 6,
    },

    {
        "varName": "customerAccount",
        "type": "text",
        "validation": "numeric",
        "label": "Customer Account",
        "grid": 6,
    },

    {
        "varName": "accountName",
        "type": "text",
        "label": "Account Name",
        "grid": 6,
    },

    {
        "varName": "accountType",
        "type": "text",
        "label": "Account Type",
        "grid": 6,
    },

    {
        "varName": "accountOrReferenceNo",
        "type": "text",
        "label": "Account or Reference No",
        "grid": 6,
    },

    {
        "varName": "sourceOfFundForTransaction",
        "type": "text",
        "label": "Source of Fund For Transaction ",
        "grid": 6,
    },

    {
        "varName": "entityType",
        "type": "text",
        "label": "Entity Type",
        "grid": 6,
    },

    {
        "varName": "monthlyProbableIncomeIndMonthlyProbableTurnOverNonInd",
        "type": "text",
        "label": "Monthly Probable Income (Ind)/Monthly Probable Turn Over (Non Ind)",
        "grid": 6,
    },
    {
        "type": "empty",
        "grid":12,
    },
    {
        "type": "empty",
        "grid":12,
    },
    {
        "varName": "cashDepositIncludingOnlineAtmDeposit",
        "type": "text",
        "validation": "numeric",
        "label": "Cash Deposit ( Including Online/ATM Deposit)",
        "grid": 6,
    },

    {
        "varName": "depositByTransferInstrument",
        "type": "text",
        "validation": "numeric",
        "label": "Deposit By Transfer/Instrument",
        "grid": 6,
    },

    {
        "varName": "foreignInwardRemittance",
        "type": "text",
        "validation": "numeric",
        "label": "Foreign Inward Remittance",
        "grid": 6,
    },

    {
        "varName": "depositOfIncomeFromExport",
        "type": "text",
        "validation": "numeric",
        "label": "Deposit of Income from Export",
        "grid": 6,
    },

    {
        "varName": "depositTransferFromBoShareMarket",
        "type": "text",
        "validation": "numeric",
        "label": "Deposit/Transfer from BO (Share Market)",
        "grid": 6,
    },

    {
        "varName": "othersSpecify",
        "type": "text",
        "validation": "numeric",
        "label": "Others (Specify)",
        "grid": 6,
    },

    {
        "varName": "totalProbableDeposit",
        "type": "text",
        "validation": "numeric",
        "label": "Total Probable Deposit",
        "grid": 6,
    },

    {
        "varName": "cashWithdrawalIncludingOnlineAtmWithdrawal",
        "type": "text",
        "validation": "numeric",
        "label": "Cash Withdrawal ( Including Online/ATM Withdrawal)",
        "grid": 6,
    },

    {
        "varName": "paymentByTransferInstrument",
        "type": "text",
        "validation": "numeric",
        "label": "Payment By Transfer/Instrument",
        "grid": 6,
    },

    {
        "varName": "foreignOutwardRemittance",
        "type": "text",
        "validation": "numeric",
        "label": "Foreign Outward Remittance",
        "grid": 6,
    },

    {
        "varName": "paymentAgainstImport",
        "type": "text",
        "validation": "numeric",
        "label": "Payment Against Import",
        "grid": 6,
    },

    {
        "varName": "depositTransferToBoShareMarket",
        "type": "text",
        "validation": "numeric",
        "label": "Deposit/Transfer TO BO (Share Market)",
        "grid": 6,
    },

    {
        "varName": "othersSpecify",
        "type": "text",
        "validation": "numeric",
        "label": "Others (Specify)",
        "grid": 6,
    },

    {
        "varName": "totalProbableWithdrawal",
        "type": "text",
        "validation": "numeric",
        "label": "Total Probable  Withdrawal",
        "grid": 6,
    },


    /* {
         "type": "title",
         "label": "FINCORE DATA UPDATE",
         "grid": 12,
     },
     {
         "varName": "fincoreDataUpdateCashLimitDrAndCr",
         "type": "text",
         "label": "Cash Limit (Dr And Cr)",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateClearingLimitDrAndCr",
         "type": "text",
         "label": "Clearing Limit (Dr And Cr)",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateTransferLimitDrAndCr",
         "type": "text",
         "label": "Transfer Limit (Dr And Cr)",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateNotes",
         "type": "text",
         "label": "Notes",
         "grid": 6,
     },

     {
         "varName": "statementFacility",
         "type": "select",

         "enum": [
             "Printed Statement",
             "E-Statement",
             "NO"
         ],
         "label": "Account Statement",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateStatementFrequency",
         "type": "select",
         "enum": [],
         "label": "Statement Frequency",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateDispatchMode",
         "type": "select",

         "enum": [
             "DHAKA",
             "kHULNA",
         ],
         "label": "Dispatch Mode",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateNextPrintDate",
         "type": "text",
         "label": "Next Print Date",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateSectorType",
         "type": "text",
         "label": "Sector Type",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateSectorCode",
         "type": "select",

         "enum": [
             "DHAKA",
             "kHULNA",
         ],
         "label": "Sector Code",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateSubSectorCode",
         "type": "select",

         "enum": [
             "DHAKA",
             "kHULNA",
         ],
         "label": "Sub Sector Code",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateOccupationCode",
         "type": "select",

         "enum": [
             "DHAKA",
             "kHULNA",
         ],
         "label": "Occupation Code",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateRmCodeFreeCode3",
         "type": "select",

         "enum": [
             "DHAKA",
             "kHULNA",
         ],
         "label": "RM Code/ Free Code 3",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateDepositCodefreeCode7",
         "type": "select",

         "enum": [
             "DHAKA",
             "kHULNA",
         ],
         "label": "Deposit Code/Free Code 7",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateFreeCodefreeText1",
         "type": "text",
         "label": "Free Code/Free Text 1",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateFreeCodefreeText2",
         "type": "text",
         "label": "Free Code/Free Text 2",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateFreeCodefreeText3",
         "type": "text",
         "label": "Free Code/Free Text 3",
         "grid": 6,
     },

   */
];

let CHECKERJsonFormForCasaProprietorship = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForCasaProprietorship)));
let CHECKERJsonFormForCasaProprietorshipLast = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForCasaProprietorshipLast)));

//###Casa CS ,BM  ,BOM ,MAKER,CHECKER   Exist  PROPRIETORSHIP#####################


let CSJsonFormForCasaProprietorshipExist = [

    {
        "varName": "eqmTicketNumber",
        "type": "text",
        "validation": "numeric",
        "label": "EQM Ticket Number",
        "grid": 2,
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "readOnly": true,
        "label": "CB Number",
        "grid": 2,
    },
    {
        "varName": "branchSolId",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "SOL ID",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "dstFlag",
        "conditionalVarValue": true,

    },
    {
        "varName": "branchSolId",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "SOL ID",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "dstFlag",
        "conditionalVarValue": "YES",

    },

    {
        "varName": "customerStatus",
        "type": "select",
        "label": "Customer Status",
        "grid": 2,
        "errorMessage": "Error",
        "required": true,
        "enum": [
            "Normal Customer",
            "Priority",
        ],


    },
    {
        "varName": "accountType",
        "type": "select",
        "errorMessage": "Error",
        "required":true,
        "label": "Product Type *",
        "enum": [
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "accountTypes",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type *",
        "required":true,
        "enum": [
            "Insta Pack",
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
    },
    {
        "varName": "accType",
        "type": "text",
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Account Type ",

        "grid": 2,
    },

    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "validation": "numeric",
        "readOnly": true,
        "label": "Account Number",
        "errorMessage": "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["accountTypes", "customerStatus"],
        "conditionalArrayValue": ["Insta Pack", "Normal Customer"],

        "grid": 2,
    },
    {
        "varName": "customerTitle",
        "type": "select",
        "enum": [
            "BANK",
            "M/S"
        ],
        "required":true,
        "label": "Customer Title ",
        "grid": 2,

    },
    {
        "varName": "customerName",
        "type": "text",
        "validation": "string",
        "multiline": true,
        "readOnly": true,
        "label": "Company Name",
        "grid": 2,

    },

    {
        "varName": "shortName",
        "type": "text",
        "validation": "string",
        "multiline": true,
        "readOnly": true,
        "label": "Short Name",
        "grid": 2,

    },

    {
        "varName": "occupationCodeNormal",
        "type": "select",
        "enum": [],
        "readOnly": true,
        "label": "Occupation Code",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
        "grid": 2,

    },
    {
        "varName": "occupationCodePriority",
        "type": "select",
        "enum": [],
        "readOnly": true,
        "label": "Occupation Code",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
        "grid": 2,

    },


    {
        "varName": "designatedEmail",
        "type": "text",
        "validation": "email",
        "label": "Designated Email ",
        "readOnly": true,
        "multiline": true,
        "errorMessage": "Error", "grid": 2,


    },
    {
        "varName": "mobileTypeIndividual",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "readOnly": true,
        "label": "Designated Mobile Type ",
        "grid": 2,
    },
    {
        "varName": "countryCodeIndividual",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "readOnly": true,
        "label": "Designated Mobile Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "designatedMobileNumber",
        "type": "text",
        "label": "Designated Mobile Number ",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",

    },


    {
        "varName": "designatedMobileNumber",
        "type": "text",
        // "validation": "phone",
        "phonePrefix": true,
        "label": "Designated Mobile Number ",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Local",

    },

    {
        "varName": "mobileTypeIndividual2",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        // "required":true,
        "label": "Second Mobile Type",
        "grid": 2,
    },
    {
        "varName": "countryCodeIndividual2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Second Mobile Country Code",
        "asteriks": true,
        "required":true,
        //"required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "phone2",
        "type": "text",
        "validation": "phone",
        "phonePrefix": true,
        "label": "Mobile Number 2",
        // "required":true,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Local",
        "grid": 2,


    },
    {
        "varName": "phone2",
        "type": "text",
        "label": "Mobile Number 2",
        //"required":true,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Overseas",
        "grid": 2,


    },

    {
        "varName": "eTin",
        "type": "text",
        "validation": "etin",
        "readOnly": true,
        "label": "Company E-TIN",
        "grid": 2
    },
    {
        "varName": "tradeLicense",
        "type": "text",
        "required":true,
        "label": "Trade License",
        "grid": 2
    },
    {
        "varName": "certificateIncorporation",
        "type": "text",
        "label": "Certificate of Incorporation",
        "grid": 2
    },

    {
        "varName": "communicationAddress1",
        "type": "text",
        "validationB":"length",
        "maxLength":45,
        "readOnly": true,
        "label": "Mailing Address 1",
        "multiline": true,
        "errorMessage": "Error",
        "grid": 2,

    },
    {
        "varName": "communicationAddress2",
        "type": "text",
        "validationB":"length",
        "maxLength":45,
        "label": "Mailing Address 2",
        "multiline": true,
        "errorMessage": "Error",
        "grid": 2,


    },
    {
        "varName": "city2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "readOnly": true,
        "label": "City",
        "grid": 2,
    },
    {
        "varName": "state2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "readOnly": true,
        "label": "Division(State)",
        "grid": 2,
    },
    {
        "varName": "postalCode3",
        "type": "text",
        "readOnly": true,
        "label": "Postal Code",
        "maxLength":6,
        "grid": 2,
    },
    {
        "varName": "country",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "readOnly": true,
        "label": "Country",
        "grid": 2,
    },

    {
        "varName": "schemeCodeForSavings",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Scheme Code ",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "SAVINGS",
    },
    {
        "varName": "schemeCodeForCurrent",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Scheme Code ",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "CURRENT",
    },

    {
        "varName": "currency",
        "label": "Currency",
        "readOnly": true,
        "grid": 2,
        "type": "autoCompleteValueReturn",
        "enum": [],
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "label": "RM Code ",
        "required":true,
        //"validation":"rmCode", //no need
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
        "grid": 2,
    },

    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "RM Name",
        "readOnly":true,
        "conditionalArray": true,
        "conditionalArrayList": ["rmCodeApiCall", "customerStatus"],
        "conditionalArrayValue": [true, "Normal Customer"],
        "grid": 2,
    },
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "onKeyDown": true,
        "enum": [],
        "label": "Sector Code",
        "grid": 2,
        required: true,
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Sector Code",
        "grid": 2,
        required: true,
        "conditional": true,
        "conditionalVarName": "sectorCodeApicall",
        "conditionalVarValue": true,
    },

    {
        "varName": "priorityCenterCode",
        "type": "select",
        "enum": [
           "PR104",
            "PR142",
            "PR177",
            "PR186",
            "PR187",
            "PR195",
            "PR208",
            "PR169",
        ],
        "errorMessage": "Error",
        "label": "Priority Center Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRMCode",
        "type": "textApiCall",
        "errorMessage": "Error",
        //"validation":"rmCode", //no need
        "label": "Monitoring RM Code",
        "required":true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRmCodeName",
        "type": "textApiCall",
        "errorMessage": "Error",
        //"validation":"rmCode", //no need
        "label": "Monitoring RM Name",
        "readOnly":true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["customerStatus", "monitoringRMCodeApiCall"],
        "conditionalArrayValue": ["Priority", true],
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "errorMessage": "Error",
        "label": "Acquiring RM Code",
        "required":true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "Acquiring Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["customerStatus", "rmCodeApiCall"],
        "conditionalArrayValue": ["Priority", true],
        "grid": 2,
    },
    {
        "varName": "primaryCBHolderforPriority",
        "type": "text",
        "errorMessage": "Error",
        "label": "Primary CB Holder for Priority",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "relationshipwithPrimaryCBHolder",
        "type": "text",
        "errorMessage": "Error",
        "label": "Relationship with Primary CB Holder",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "functionCodeforChequeBookWaiver",
        "type": "text",
        "errorMessage": "Error",
        "label": "Function Code for Cheque Book Waiver",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "waiverTypeforChequeBookWaiver",
        "type": "text",
        "errorMessage": "Error",
        "label": "Waiver Type  for Cheque Book Waiver",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "waiverField",
        "type": "text",
        "errorMessage": "Error",
        "label": "Waiver Field",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "smsAlertRequest",
        "type": "select",
        "enum": [
            "Y",
            "N",
            "AB",
            "CCEP",
        ],
        "required":true,
        "onKeyDown":true,
        "label": "SMS Alert Request",
        "grid": 2,
    },

];
let BMJsonFormForCasaProprietorshipExist = makeReadOnlyObject(JSON.parse(JSON.stringify(CSJsonFormForCasaProprietorshipExist.concat(CSJsonFormForCasaServiceAllExist))));
let BOMJsonFormForCasaProprietorshipExist = makeReadOnlyObject(JSON.parse(JSON.stringify(makeGridObject((CSJsonFormForCasaProprietorshipExist.concat(CSJsonFormForCasaServiceAllExist))))));
let MAKERJsonFormForCasaProprietorshipExist = MAKERJsonFormForCasaProprietorship;
let MAKERJsonFormForCasaProprietorshipLastExist = MAKERJsonFormForCasaProprietorshipLast;
let CHECKERJsonFormForCasaProprietorshipExist = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForCasaProprietorshipExist)));
let CHECKERJsonFormForCasaProprietorshipLastExist = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForCasaProprietorshipLastExist)));

//###Casa CS ,BM  ,BOM ,MAKER,CHECKER   New  COMPANY#####################
let CSJsonFormForCasaCompany =  [

    {
        "varName": "eqmTicketNumber",
        "type": "text",
        "validation": "numeric",
        "label": "EQM Ticket Number",
        "grid": 2,
    },

    {
        "varName": "branchSolId",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "SOL ID",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "dstFlag",
        "conditionalVarValue": true,

    },
    {
        "varName": "branchSolId",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "SOL ID",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "dstFlag",
        "conditionalVarValue": "YES",

    },

    {
        "varName": "customerStatus",
        "type": "select",
        "label": "Customer Status",
        "grid": 2,
        "errorMessage": "Error",
        "required": true,
        "enum": [
            "Normal Customer",
            "Priority",
        ],


    },
    {
        "varName": "accountType",
        "type": "select",
        "errorMessage": "Error",
        "required":true,
        "label": "Product Type *",
        "enum": [
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "accountTypes",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type *",
        "required":true,
        "enum": [
            "Insta Pack",
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
    },
    {
        "varName": "accType",
        "type": "text",
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Account Type ",

        "grid": 2,
    },

    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "validation": "numeric",
        "required": true,
        "label": "Account Number",
        "errorMessage": "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["accountTypes", "customerStatus"],
        "conditionalArrayValue": ["Insta Pack", "Normal Customer"],

        "grid": 2,
    },
    {
        "varName": "customerTitle",
        "type": "select",
        "enum": [
            "BANK",
            "M/S"
        ],
        "required":true,
        "label": "Customer Title ",
        "grid": 2,

    },
    {
        "varName": "customerName",
        "type": "text",
        "validation": "string",
        "multiline": true,
        "required":true,
        "label": "Company Name",
        "grid": 2,

    },

    {
        "varName": "shortName",
        "type": "text",
        "validation": "string",
        "multiline": true,
        "required":true,
        "label": "Short Name",
        "grid": 2,

    },

    {
        "varName": "occupationCodeNormal",
        "type": "select",
        "enum": [],
        "required":true,
        "label": "Occupation Code",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
        "grid": 2,

    },
    {
        "varName": "occupationCodePriority",
        "type": "select",
        "enum": [],
        "required":true,
        "label": "Occupation Code",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
        "grid": 2,

    },


    {
        "varName": "designatedEmail",
        "type": "text",
        "validation": "email",
        "label": "Designated Email ",
        "required":true,
        "multiline": true,
        "errorMessage": "Error", "grid": 2,


    },
    {
        "varName": "mobileTypeIndividual",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required":true,
        "label": "Designated Mobile Type ",
        "grid": 2,
    },
    {
        "varName": "countryCodeIndividual",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required":true,
        "label": "Designated Mobile Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "designatedMobileNumber",
        "type": "text",
        "label": "Designated Mobile Number ",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",

    },


    {
        "varName": "designatedMobileNumber",
        "type": "text",
        // "validation": "phone",
        "phonePrefix": true,
        "label": "Designated Mobile Number ",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Local",

    },

    {
        "varName": "mobileTypeIndividual2",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        // "required":true,
        "label": "Second Mobile Type",
        "grid": 2,
    },
    {
        "varName": "countryCodeIndividual2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Second Mobile Country Code",
        "asteriks": true,
        "required":true,
        //"required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "phone2",
        "type": "text",
        "validation": "phone",
        "phonePrefix": true,
        "label": "Mobile Number 2",
        // "required":true,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Local",
        "grid": 2,


    },
    {
        "varName": "phone2",
        "type": "text",
        "label": "Mobile Number 2",
        //"required":true,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Overseas",
        "grid": 2,


    },

    {
        "varName": "eTin",
        "type": "text",
        "validation": "etin",
        "required":true,
        "label": "Company E-TIN",
        "grid": 2
    },
    {
        "varName": "tradeLicense",
        "type": "text",
        "required":true,
        "label": "Trade License",
        "grid": 2
    },
    {
        "varName": "certificateIncorporation",
        "type": "text",
        "label": "Certificate of Incorporation",
        "grid": 2
    },
    /*{
        "varName": "SelectCommunicationAddress",
        "type": "select",
        "enum": [
            "Company Registered Address",
            "Business/ Office Address",
            "Factory Address"
        ],
        "required": true,
        "label": "Select Communication Address ",
        "grid": 2
    },*/
    {
        "varName": "communicationAddress1",
        "type": "text",
        "validationB":"length",
        "maxLength":45,
        "required":true,
        "label": "Mailing Address 1",
        "multiline": true,
        "errorMessage": "Error",
        "grid": 2,

    },
    {
        "varName": "communicationAddress2",
        "type": "text",
        "validationB":"length",
        "maxLength":45,
        "label": "Mailing Address 2",
        "multiline": true,
        "errorMessage": "Error",
        "grid": 2,


    },
    {
        "varName": "city2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required":true,
        "label": "City",
        "grid": 2,
    },
    {
        "varName": "state2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required":true,
        "label": "Division(State)",
        "grid": 2,
    },
    {
        "varName": "postalCode3",
        "type": "text",
        "required":true,
        "label": "Postal Code",
        "maxLength":6,
        "grid": 2,
    },
    {
        "varName": "country",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required":true,
        "label": "Country",
        "grid": 2,
    },

    {
        "varName": "schemeCodeForSavings",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Scheme Code ",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "SAVINGS",
    },
    {
        "varName": "schemeCodeForCurrent",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Scheme Code ",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "CURRENT",
    },

    {
        "varName": "currency",
        "label": "Currency",
        "required": true,
        "grid": 2,
        "type": "autoCompleteValueReturn",
        "enum": [],
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "label": "RM Code ",
        "required":true,
        //"validation":"rmCode", //no need
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
        "grid": 2,
    },

    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "RM Name",
        "readOnly":true,
        "conditionalArray": true,
        "conditionalArrayList": ["rmCodeApiCall", "customerStatus"],
        "conditionalArrayValue": [true, "Normal Customer"],
        "grid": 2,
    },
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "onKeyDown": true,
        "enum": [],
        "label": "Sector Code",
        "grid": 2,
        required: true,
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Sector Code",
        "grid": 2,
        required: true,
        "conditional": true,
        "conditionalVarName": "sectorCodeApicall",
        "conditionalVarValue": true,
    },

    {
        "varName": "priorityCenterCode",
        "type": "select",
        "enum": [
           "PR104",
            "PR142",
            "PR177",
            "PR186",
            "PR187",
            "PR195",
            "PR208",
            "PR169",
        ],
        "errorMessage": "Error",
        "label": "Priority Center Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRMCode",
        "type": "textApiCall",
        "errorMessage": "Error",
        //"validation":"rmCode", //no need
        "label": "Monitoring RM Code",
        "required":true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRmCodeName",
        "type": "textApiCall",
        "errorMessage": "Error",
        //"validation":"rmCode", //no need
        "label": "Monitoring RM Name",
        "readOnly":true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["customerStatus", "monitoringRMCodeApiCall"],
        "conditionalArrayValue": ["Priority", true],
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "errorMessage": "Error",
        "label": "Acquiring RM Code",
        "required":true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "Acquiring Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["customerStatus", "rmCodeApiCall"],
        "conditionalArrayValue": ["Priority", true],
        "grid": 2,
    },
    {
        "varName": "primaryCBHolderforPriority",
        "type": "text",
        "errorMessage": "Error",
        "label": "Primary CB Holder for Priority",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "relationshipwithPrimaryCBHolder",
        "type": "text",
        "errorMessage": "Error",
        "label": "Relationship with Primary CB Holder",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "functionCodeforChequeBookWaiver",
        "type": "text",
        "errorMessage": "Error",
        "label": "Function Code for Cheque Book Waiver",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "waiverTypeforChequeBookWaiver",
        "type": "text",
        "errorMessage": "Error",
        "label": "Waiver Type  for Cheque Book Waiver",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "waiverField",
        "type": "text",
        "errorMessage": "Error",
        "label": "Waiver Field",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "smsAlertRequest",
        "type": "select",
        "enum": [
            "Y",
            "N",
            "AB",
            "CCEP",
        ],
        "required":true,
        "onKeyDown":true,
        "label": "SMS Alert Request",
        "grid": 2,
    },

];
let BMJsonFormForCasaCompany = makeReadOnlyObject(JSON.parse(JSON.stringify(CSJsonFormForCasaCompany.concat(CSJsonFormForCasaServiceAll))));

let BOMJsonFormForCasaCompany = makeReadOnlyObject(JSON.parse(JSON.stringify(makeGridObject((CSJsonFormForCasaCompany.concat(CSJsonFormForCasaServiceAll))))));
let MAKERJsonFormForCasaCompany = MAKERJsonFormForCasaProprietorship;
let MAKERJsonFormForCasaCompanyLast = MAKERJsonFormForCasaProprietorshipLast;
let CHECKERJsonFormForCasaCompany = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForCasaCompany)));
let CHECKERJsonFormForCasaCompanyLast = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForCasaCompanyLast)));

//###Casa CS ,BM  ,BOM ,MAKER,CHECKER  Exist   COMPANY#####################
let CSJsonFormForCasaCompanyExist = [

    {
        "varName": "eqmTicketNumber",
        "type": "text",
        "validation": "numeric",
        "label": "EQM Ticket Number",
        "grid": 2,
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "readOnly": true,
        "label": "CB Number",
        "grid": 2,
    },
    {
        "varName": "branchSolId",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "SOL ID",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "dstFlag",
        "conditionalVarValue": true,

    },
    {
        "varName": "branchSolId",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "SOL ID",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "dstFlag",
        "conditionalVarValue": "YES",

    },

    {
        "varName": "customerStatus",
        "type": "select",
        "label": "Customer Status",
        "grid": 2,
        "errorMessage": "Error",
        "required": true,
        "enum": [
            "Normal Customer",
            "Priority",
        ],


    },
    {
        "varName": "accountType",
        "type": "select",
        "errorMessage": "Error",
        "required":true,
        "label": "Product Type *",
        "enum": [
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "accountTypes",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type *",
        "required":true,
        "enum": [
            "Insta Pack",
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
    },
    {
        "varName": "accType",
        "type": "text",
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Account Type ",

        "grid": 2,
    },

    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "validation": "numeric",
        "readOnly": true,
        "label": "Account Number",
        "errorMessage": "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["accountTypes", "customerStatus"],
        "conditionalArrayValue": ["Insta Pack", "Normal Customer"],

        "grid": 2,
    },
    {
        "varName": "customerTitle",
        "type": "select",
        "enum": [
            "BANK",
            "M/S"
        ],
        "required":true,
        "label": "Customer Title ",
        "grid": 2,

    },
    {
        "varName": "customerName",
        "type": "text",
        "validation": "string",
        "multiline": true,
        "readOnly": true,
        "label": "Company Name",
        "grid": 2,

    },

    {
        "varName": "shortName",
        "type": "text",
        "validation": "string",
        "multiline": true,
        "readOnly": true,
        "label": "Short Name",
        "grid": 2,

    },

    {
        "varName": "occupationCodeNormal",
        "type": "select",
        "enum": [],
        "readOnly": true,
        "label": "Occupation Code",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
        "grid": 2,

    },
    {
        "varName": "occupationCodePriority",
        "type": "select",
        "enum": [],
        "readOnly": true,
        "label": "Occupation Code",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
        "grid": 2,

    },


    {
        "varName": "designatedEmail",
        "type": "text",
        "validation": "email",
        "label": "Designated Email ",
        "readOnly": true,
        "multiline": true,
        "errorMessage": "Error", "grid": 2,


    },
    {
        "varName": "mobileTypeIndividual",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "readOnly": true,
        "label": "Designated Mobile Type ",
        "grid": 2,
    },
    {
        "varName": "countryCodeIndividual",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "readOnly": true,
        "label": "Designated Mobile Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "designatedMobileNumber",
        "type": "text",
        "label": "Designated Mobile Number ",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",

    },


    {
        "varName": "designatedMobileNumber",
        "type": "text",
        // "validation": "phone",
        "phonePrefix": true,
        "label": "Designated Mobile Number ",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Local",

    },

    {
        "varName": "mobileTypeIndividual2",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        // "required":true,
        "label": "Second Mobile Type",
        "grid": 2,
    },
    {
        "varName": "countryCodeIndividual2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Second Mobile Country Code",
        "asteriks": true,
        "required":true,
        //"required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "phone2",
        "type": "text",
        "validation": "phone",
        "phonePrefix": true,
        "label": "Mobile Number 2",
        // "required":true,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Local",
        "grid": 2,


    },
    {
        "varName": "phone2",
        "type": "text",
        "label": "Mobile Number 2",
        //"required":true,
        "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Overseas",
        "grid": 2,


    },

    {
        "varName": "eTin",
        "type": "text",
        "validation": "etin",
        "readOnly": true,
        "label": "Company E-TIN",
        "grid": 2
    },
    {
        "varName": "tradeLicense",
        "type": "text",
        "required":true,
        "label": "Trade License",
        "grid": 2
    },
    {
        "varName": "certificateIncorporation",
        "type": "text",
        "label": "Certificate of Incorporation",
        "grid": 2
    },

    {
        "varName": "communicationAddress1",
        "type": "text",
        "validationB":"length",
        "maxLength":45,
        "readOnly": true,
        "label": "Mailing Address 1",
        "multiline": true,
        "errorMessage": "Error",
        "grid": 2,

    },
    {
        "varName": "communicationAddress2",
        "type": "text",
        "validationB":"length",
        "maxLength":45,
        "label": "Mailing Address 2",
        "multiline": true,
        "errorMessage": "Error",
        "grid": 2,


    },
    {
        "varName": "city2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "readOnly": true,
        "label": "City",
        "grid": 2,
    },
    {
        "varName": "state2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "readOnly": true,
        "label": "Division(State)",
        "grid": 2,
    },
    {
        "varName": "postalCode3",
        "type": "text",
        "readOnly": true,
        "label": "Postal Code",
        "maxLength":6,
        "grid": 2,
    },
    {
        "varName": "country",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "readOnly": true,
        "label": "Country",
        "grid": 2,
    },

    {
        "varName": "schemeCodeForSavings",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Scheme Code ",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "SAVINGS",
    },
    {
        "varName": "schemeCodeForCurrent",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Scheme Code ",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "CURRENT",
    },

    {
        "varName": "currency",
        "label": "Currency",
        "readOnly": true,
        "grid": 2,
        "type": "autoCompleteValueReturn",
        "enum": [],
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "label": "RM Code ",
        "required":true,
        //"validation":"rmCode", //no need
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
        "grid": 2,
    },

    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "RM Name",
        "readOnly":true,
        "conditionalArray": true,
        "conditionalArrayList": ["rmCodeApiCall", "customerStatus"],
        "conditionalArrayValue": [true, "Normal Customer"],
        "grid": 2,
    },
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "onKeyDown": true,
        "enum": [],
        "label": "Sector Code",
        "grid": 2,
        required: true,
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Sector Code",
        "grid": 2,
        required: true,
        "conditional": true,
        "conditionalVarName": "sectorCodeApicall",
        "conditionalVarValue": true,
    },

    {
        "varName": "priorityCenterCode",
        "type": "select",
        "enum": [
           "PR104",
            "PR142",
            "PR177",
            "PR186",
            "PR187",
            "PR195",
            "PR208",
            "PR169",
        ],
        "errorMessage": "Error",
        "label": "Priority Center Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRMCode",
        "type": "textApiCall",
        "errorMessage": "Error",
        //"validation":"rmCode", //no need
        "label": "Monitoring RM Code",
        "required":true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRmCodeName",
        "type": "textApiCall",
        "errorMessage": "Error",
        //"validation":"rmCode", //no need
        "label": "Monitoring RM Name",
        "readOnly":true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["customerStatus", "monitoringRMCodeApiCall"],
        "conditionalArrayValue": ["Priority", true],
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "errorMessage": "Error",
        "label": "Acquiring RM Code",
        "required":true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "Acquiring Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["customerStatus", "rmCodeApiCall"],
        "conditionalArrayValue": ["Priority", true],
        "grid": 2,
    },
    {
        "varName": "primaryCBHolderforPriority",
        "type": "text",
        "errorMessage": "Error",
        "label": "Primary CB Holder for Priority",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "relationshipwithPrimaryCBHolder",
        "type": "text",
        "errorMessage": "Error",
        "label": "Relationship with Primary CB Holder",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "functionCodeforChequeBookWaiver",
        "type": "text",
        "errorMessage": "Error",
        "label": "Function Code for Cheque Book Waiver",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "waiverTypeforChequeBookWaiver",
        "type": "text",
        "errorMessage": "Error",
        "label": "Waiver Type  for Cheque Book Waiver",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "waiverField",
        "type": "text",
        "errorMessage": "Error",
        "label": "Waiver Field",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "smsAlertRequest",
        "type": "select",
        "enum": [
            "Y",
            "N",
            "AB",
            "CCEP",
        ],
        "required":true,
        "onKeyDown":true,
        "label": "SMS Alert Request",
        "grid": 2,
    },

];
let BMJsonFormForCasaCompanyExist = makeReadOnlyObject(JSON.parse(JSON.stringify(CSJsonFormForCasaCompanyExist.concat(CSJsonFormForCasaServiceAllExist))));

let BOMJsonFormForCasaCompanyExist = makeReadOnlyObject(JSON.parse(JSON.stringify(makeGridObject(CSJsonFormForCasaCompanyExist.concat(CSJsonFormForCasaServiceAllExist)))));
let MAKERJsonFormForCasaCompanyExist = MAKERJsonFormForCasaProprietorship;
let MAKERJsonFormForCasaCompanyLastExist = MAKERJsonFormForCasaProprietorshipLast;
let CHECKERJsonFormForCasaCompanyExist = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForCasaCompanyExist)));
let CHECKERJsonFormForCasaCompanyLastExist = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForCasaCompanyLastExist)));


//#####################DST New Individual#####################################################


let CSJsonFormForDSTService = [
    {
        "varName": "priority",
        "type": "select",
        "label": "Priority",
        "enum": [
            "GENERAL",
            "HIGH",
        ],
        "grid": 2,
    },





    /*   {
           "varName": "lockerFacility",
           "type": "checkbox",
           "label": "Locker Facility",


       },*/
    {
        "varName": "loanRequest",
        "type": "checkbox",
        "label": "Loan Request",

    },
    {
        "varName": "cityTouchRequest",
        "type": "checkbox",
        "label": "City Touch"

    },
    {
        "varName": "statementFacility",
        "type": "select",
        "enum": [
            "Printed Statement",
            "E-Statement",
            "NO"
        ],
        "grid": 3,
        "label": "Statement",

    },

    /* {
         "varName": "printedStatement",
         "type": "checkbox",
         "label": "Printed Statement",
         "conditional": true,
         "conditionalVarName": "statementFacility",
         "conditionalVarValue": true,

     },
     {
         "varName": "eStatement",
         "type": "checkbox",
         "label": "E-Statement",
         "conditional": true,
         "conditionalVarName": "statementFacility",
         "conditionalVarValue": true,

     },
     {
         "varName": "statementNo",
         "type": "checkbox",
         "label": "NO",
         "conditional": true,
         "conditionalVarName": "statementFacility",
         "conditionalVarValue": true,

     },*/

    /* {
         "varName": "cityTouchRequestEmail",
         "type": "text",
         "label": "Email",
         "grid":6,
         "conditional": true,
         "conditionalVarName": "dpsRequest",
         "conditionalVarValue": true,

     },*/
    {
        "varName": "dpsRequest",
        "type": "checkbox",
        "label": "DPS Request",

        "grid": 12,

    },
    {
        "varName": "dpsAmount",
        "type": "text",
        "label": "Amount",
        "validation": "numeric",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true,
    },
    {
        "varName": "dpsTenorType",
        "type": "select",
        "label": "Tenure Type",
        "grid": 2,
        "enum": [
            "Monthly",
        ],
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true,
    },
    {
        "varName": "dpsTenor",
        "type": "select",
        "enum": [
            "1",
            "3",
            "6",
            "12",
            "24",
            "36",],
        "label": "Tenure",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true,
    },
    {
        "varName": "dpsInterest",
        "type": "text",
        "label": "Interest",
        "readOnly": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true,
    },
    {
        "varName": "dpsSi",
        "type": "date",
        "label": "SI Date",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true,
    }
].concat(CSJsonFormForFDRService);
let CSJsonFormForDSTServiceLast = [
    /* {
         "varName": "smsAlertRequest",
         "type": "checkbox",
         "label": "SMS Alert Request",


     },*/
    //cheque book
    {
        "varName": "chequeBookRequest",
        "type": "checkbox",
        "label": "Cheque Book Request",
        "grid": 12,

    },
    /*  {
          "varName": "pageOfChequeBookSavings",
          "type": "select",
          "enum": [
              "10",
              "25",
          ],
          "label": "Page Of Cheque Book",
          "conditionalArray": true,
          "conditionalArrayList": ["chequeBookRequest", "accType"],
          "conditionalArrayValue": [true, "SAVINGS"],

          "grid": 2

      },
      {
          "varName": "pageOfChequeBookSavings",
          "type": "select",
          "enum": [
              "50",
              "100",
          ],
          "label": "Page Of Cheque Book",
          "conditionalArray": true,
          "conditionalArrayList": ["chequeBookRequest", "accTypes"],
          "conditionalArrayValue": [true, "SAVINGS"],

          "grid": 2

      },
      {
          "varName": "pageOfChequeBookCurrent",
          "type": "select",
          "enum": [
              "25",
              "50",
              "100",

          ],
          "label": "Page Of Cheque Book",
          "conditionalArray": true,
          "conditionalArrayList": ["chequeBookRequest", "accType"],
          "conditionalArrayValue": [true, "CURRENT"],

          "grid": 2

      },
      {
          "varName": "numberOfChequeBookRequest",
          "type": "select",
          "enum": [
              "1",
              "2",
              "3",
              "4",
              "5",
          ],
          "label": "Number Of Cheque Book",
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": true,
          "grid": 2,


      },
      {
          "varName": "chequeBookDeliveryType",
          "type": "select",
          "label": "Delivery Type",
          "enum": [
              //,
              "Branch"
          ],
          "grid": 2,
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": true,

      },
      {
          "varName": "customerAddressCheque",
          "type": "text",
          "multiline": true,
          "label": "Customer Address",
          "grid": 2,
          "conditionalArray": true,
          "conditionalArrayList": ["chequeBookRequest", "chequeBookDeliveryType"],
          "conditionalArrayValue": [true, ],


      },
      {
          "varName": "chequeBookBranch",
          "type": "select",
          "label": "Branch Name",
          "enum": [
              "GULSHAN 1",
              "MOTHIJHEEL 1",
              "DHANMONDI",
          ],
          "grid": 2,
          "conditionalArray": true,
          "conditionalArrayList": ["chequeBookRequest", "chequeBookDeliveryType"],
          "conditionalArrayValue": [true, "Branch"],


      },
      {
          "varName": "chequeBookDesign",
          "type": "select",
          "label": "Cheque Book Design",
          "enum": [
              "Sapphire",
              "Citygem",
              "City Alo",
              "Other"
          ],
          "grid": 2,
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": true,


      },
      {
          "varName": "chequeBookDesignOther",
          "type": "text",
          "label": "Other",
          "grid": 2,
          "conditional": true,
          "conditionalVarName": "chequeBookRequest",
          "conditionalVarValue": "Other",


      },*/
    {
        varName: "numberOfChequeBook",
        type: "select",
        label: "No. of Cheque Book",
        required: true,
        // asterisk: true,
        grid: 2,
        errorMessage: "Error",
        enum: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,

    },
    {
        varName: "pageofChequebook",
        type: "select",
        label: "Page of Chequebook",
        required: true,
        // asterisk: true,
        grid: 2,
        errorMessage: "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "accType"],
        "conditionalArrayValue": [true, "SAVINGS"],
        enum: ["10", "25"]
    },
    {
        varName: "pageofChequebook",
        type: "select",
        label: "Page of Chequebook",
        required: true,
        // asterisk: true,
        grid: 2,
        errorMessage: "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "accType"],
        "conditionalArrayValue": [true, "CURRENT"],
        enum: ["25", "50", "100"]
    },

    {
        varName: "deliveryType",
        type: "select",
        label: "Delivery Type",
        required: true,
        // asterisk: true,
        grid: 2,
        errorMessage: "Error",
        enum: ["Branch",],
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
    },

    {
        varName: "branchName",
        "type": "autoCompleteValueReturn",
        "enum": [], label: "Delivery Branch Name",
        required: true,
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "deliveryType"],
        "conditionalArrayValue": [true, "Branch"],
        grid: 2,
        errorMessage: "Error",
        // enum: ["Gulshan", "Banani", "Dhanmondi"]
    },
    {
        varName: "customerAddress",
        type: "text",
        label: "Customer Address",
        required: true,
        // asterisk: true,
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "deliveryType"],
        "conditionalArrayValue": [true,],
        grid: 2,
        errorMessage: "Error"
    },
    {
        varName: "customerType",
        type: "select",
        label: "Customer Type",
        enum: ["General", "Priority", "Sapphire"],
        grid: 2,
        errorMessage: "Error",
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
    },
    {
        varName: "checqueBookDesign",
        type: "select",
        label: "Chequebook Design",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "customerType"],
        "conditionalArrayValue": [true, "Priority"],

        grid: 2,
        enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5"],
        errorMessage: "Error"
    },
    {
        varName: "checqueBookDesign",
        type: "select",
        label: "Chequebook Design",
        "conditionalArray": true,
        "conditionalArrayList": ["chequeBookRequest", "customerType"],
        "conditionalArrayValue": [true, "Sapphire"],
        grid: 2,
        enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5", "Design Code 6"],
        errorMessage: "Error"
    },
//Debit Card
    {
        "varName": "debitCard",
        "type": "checkbox",
        "label": "Debit Card",
        "grid": 12
    },
    /*  {
          "varName": "debitCardName",
          "type": "text",
          "label": "Name On Card",
          "grid": 2,
          "multiline": true,
          "conditional": true,
          "conditionalVarName": "debitCard",
          "conditionalVarValue": true,

      },

      {
          "varName": "cardType",
          "type": "select",
          "label": "Card Type",
          "grid": 2,
          "enum": [
              "VISA",
              "MASTER",
              "CITYMAXX",
          ],
          "conditional": true,
          "conditionalVarName": "debitCard",
          "conditionalVarValue": true,

      },
      {
          "varName": "debitCardDeliveryType",
          "type": "select",
          "label": "Delivery Type",
          "enum": [
              //,
              "Branch"
          ],
          "grid": 2,
          "conditional": true,
          "conditionalVarName": "debitCard",
          "conditionalVarValue": true,

      },
      {
          "varName": "customerAddressDebit",
          "type": "text",
          "multiline": true,
          "label": "Customer Address",
          "grid": 2,
          "conditionalArray": true,
          "conditionalArrayList": ["debitCard", "debitCardDeliveryType"],
          "conditionalArrayValue": [true, ],


      },

      {
          "varName": "debitRequestkBranch",
          "type": "select",
          "label": "Branch Name",
          "enum": [
              "GULSHAN 1",
              "MOTHIJHEEL 1",
              "DHANMONDI",
          ],
          "grid": 2,
          "conditionalArray": true,
          "conditionalArrayList": ["debitCard", "debitCardDeliveryType"],
          "conditionalArrayValue": [true, "Branch"],
      },*/
    {
        varName: "cardType",
        type: "select",
        label: "Card Type",
        required: true,
        grid: 2,
        errorMessage: "Error",
        enum: ["VISA DEBIT CARD", "MASTER CARD DEBIT CARD", "CITY MAXX DEBIT CARD"],
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,
    },


    {
        varName: "productType",
        type: "select",
        label: "Product Type",
        required: true,
        grid: 2,
        errorMessage: "Error",
        enum: [
            "VISA DEBIT CARD STAFF",
            "VISA DEBIT CARD GENERAL",
            "VISA CORPORATE 1st year free",
            "VISA DEBIT CARD YOUTH",
            "VISA Debit Card corporate 2nd year tk 300",
            "VISA Debit Card corporate lifetime free",
            "VISA Debit Card - City Alo",
            "Easy VISA Debit card",
            "Easy Plus VISA Debit card",
            "Visa Corporate 1st Year & 2nd Year Free",
            "Debit Card Fee - University Campaign [172.5,345]"
        ],
        "conditionalArray": true,
        "conditionalArrayList": ["debitCard", "cardType"],
        "conditionalArrayValue": [true, "VISA DEBIT CARD"],
    },
    {
        varName: "productType",
        type: "select",
        label: "Product Type",
        required: true,
        grid: 2,
        errorMessage: "Error",
        conditional: true,
        conditionalVarName: "cardType",
        conditionalVarValue: "MASTER CARD DEBIT CARD",
        enum: [
            "MASTER CARD PLATINUM STAFF",
            "MASTER CARD PLATINUM GENERAL",
            "MASTER CARD DEBIT CARD STAFF",
            "MASTER CARD DEBIT CARD GENERAL",
            "MASTER DEBIT CARD CORPORATE 1st year free",
            "MASTER CARD DEBIT CARD YOUTH",
            "MASTER CARD DEBIT CARD PLATINUM CITY GEMS",
            "Master Debit Card Corporate 2nd year tk 300",
            "Master Debit Card corporate lifetime free",
            "Master Platinum Gems staff card",
            "MC Platinum Debit Card - City Gems - Sapphire",
            "Master Manarah Debit card general",
            "Master Manarah Debit card Corporate",
            "Master Card Corporate 1st Year & 2nd Year Free",
        ],
        "conditionalArray": true,
        "conditionalArrayList": ["debitCard", "cardType"],
        "conditionalArrayValue": [true, "MASTER CARD DEBIT CARD"],
    },
    {
        varName: "productType",
        type: "select",
        label: "Product Type",
        required: true,
        grid: 2,
        errorMessage: "Error",
        enum: [
            "CITY MAXX STAFF",
            "CITY MAXX GENERAL",
            "CITY MAXX CORPORATE 1st year free",
            "CITY MAXX KIOSK CAMPAIGN",
            "CITY MAXX CITY GEMS (PRIORITY BANKING)",
            "CITY MAXX YOUTH",
            "Citymaxx Corporate 2nd Year Tk. 300",
            "CITYMAXX corporate lifetime free",
            "MaxX City Gems Sapphire",
            "Easy City Maxx Debit Card",
            "Easy Plus City Maxx Debit Card",
            "City MaxX Corporate 1st Year & 2nd Year Free"
        ],
        "conditionalArray": true,
        "conditionalArrayList": ["debitCard", "cardType"],
        "conditionalArrayValue": [true, "CITY MAXX DEBIT CARD"],
    },
    {
        varName: "nameOnCard",
        type: "text",
        label: "Name on Card",
        required: true,
        grid: 2,
        "validationB":"length",
        "maxLength":19,
        "multiline": true,
        errorMessage: "Error",
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,
    },

    {
        varName: "deliveryTypes",
        type: "select",
        label: "Delivery Type",
        required: true,
        grid: 2,
        errorMessage: "Error",
        enum: ["Branch",],
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,
    },

    {
        varName: "branchName",
        "type": "autoCompleteValueReturn",
        "enum": [],
        label: "Branch Name",
        required: true,
        grid: 2,
        errorMessage: "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["debitCard", "deliveryTypes"],
        "conditionalArrayValue": [true, "Branch"],
    },
    {
        varName: "customerAddress",
        type: "text",
        label: "Customer Address",
        multiline: true,
        required: true,
        grid: 2,
        errorMessage: "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["debitCard", "deliveryType"],
        "conditionalArrayValue": [true,],
    },
    {
        "varName": "instaDebitCard",
        "type": "checkbox",
        "label": "Insta Debit Card",
        "grid": 12
    },
    {
        "varName": "instaDebitCardName",
        "type": "text",
        "label": "Name On Card",
        "grid": 2,
        "validationB":"length",
        "maxLength":19,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "instaDebitCard",
        "conditionalVarValue": true,

    },

    {
        "varName": "instaCardType",
        "type": "select",
        "label": "Card Type",
        "enum": [
            "CITYMAXX"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "instaDebitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "instaClientId",
        "type": "text",
        "label": "Client ID",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "instaDebitCard",
        "conditionalVarValue": true,

    }
];
let CSJsonFormForDSTServiceAll = CSJsonFormForDSTService.concat(CSJsonFormForDSTServiceLast);
let CSJsonFormForDSTIndividual = [
    {
        "varName": "eqmTicketNumber",
        "type": "text",
        "validation": "numeric",
        "label": "EQM Ticket Number",
        "grid": 2,
    },
    {
        "varName": "accountSource",
        "type": "select",
        "label": "Account Source ",
        "grid": 2,
        "errorMessage": "Error",
        "required": true,
        "enum": [
            "Conventional"
            //"Islamic"
        ],


    },
    {
        "varName": "customerStatus",
        "type": "select",
        "label": "Customer Status ",
        "grid": 2,
        "errorMessage": "Error",
        "required": true,
        "enum": [
            "Normal Customer",
            "Staff",
            "Priority",
        ],


    },
    {
        "varName": "accountType",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type *",
        "enum": [
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
    }, {
        "varName": "accountType",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type *",
        "enum": [
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "accountTypes",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type *",
        "enum": [
            "Insta Pack",
            "Regular"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
    },
    {
        "varName": "accType",
        "type": "select",
        "required": true,
        "errorMessage": "Error",
        "label": "Account Type ",
        "enum": [
            "SAVINGS",
            "CURRENT",
            /* "FCY",
             "RFCD"*/,
        ],
        "grid": 2,
    },

    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number *",
        "validation": "numeric",
        "errorMessage": "Error",
        "conditionalArray": true,
        "conditionalArrayList": ["accountTypes", "customerStatus"],
        "conditionalArrayValue": ["Insta Pack", "Normal Customer"],

        "grid": 2,
    },


    {
        "varName": "customerTitle",
        "type": "select",
        "enum": [
           /* "CAPT.",
            "DR.",
            "ENGG.",
            "LAW",
            "MAST.",
            "MINOR",
            "MR",
            "MR.",
            "MRS.",
            "MS.",
            "BANK",
            "JOINT",
            "M/S."*/
            "MR.",
            "MRS.",
            "MS."

        ],
        "required": true,
        "label": "Customer Title ",
        "grid": 2,

    },
    {
        "varName": "customerName",
        "type": "text",
        "required": true,
        "validation": "string",
        "multiline": true,
        "label": "Customer Name ",
        "grid": 2,

    },
    {
        "varName": "shortName",
        "type": "text",
        "multiline": true,
        "validation": "string",
        "required": true,
        "label": "Short Name",
        "grid": 2,

    },
    {
        "varName": "gender",
        "type": "select",
        "enum": [
            "M",
            "F",
            "O",
        ],
        "required": true,
        "label": "Gender",
        "grid": 2,

    },
    {
        "varName": "occupationCodeNormal",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
        "grid": 2,

    },
    {
        "varName": "occupationCodePriority",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
        "grid": 2,

    },
    {
        "varName": "occupationCodeStaff",
        "type": "select",
        "enum": [
            "E",
        ],
        "label": "Occupation Code",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",

        "grid": 2,

    },
    {
        "varName": "staffIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "label": "Staff Indicator",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
        "grid": 2,

    },
    {
        "varName": "staffIdNumber",
        "type": "text",
        "label": "Staff ID Number",
        "validation":"staffId",
        asterisk: true,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
        "grid": 2,

    },
    {
        "varName": "dob",
        "type": "date",
        "maxDate": true,
        "required": true,
        "label": "Date Of Birth ",
        "grid": 2,


    },
    {
        "varName": "designatedEmail",
        "type": "text",
        "validation": "email",
        "label": "Designated Email ",
        "multiline": true,
        "errorMessage": "Error", "grid": 2,


    },

    {
        "varName": "designatedMobileNumber",
        "type": "text",
        "validation": "phone",
        "phonePrefix": true,
        "required": true,
        "label": "Designated Mobile Number ",
        "grid": 2,


    },
    {
        "varName": "phone2",
        "type": "text",
        "validation": "phone",
        "phonePrefix": true,
        "label": "Mobile Number 2",
        "grid": 2,


    },
    {
        "varName": "nid",
        "type": "text",
        "errorMessage": "Error",
        "validation": "nid",
        "label": "NID",
        "grid": 2,

    },
    {
        "varName": "passport",
        "type": "text",
        "validation": "passport",
        "errorMessage": "Error",
        "label": "Passport",
        "grid": 2,


    },
    {
        "varName": "eTin",
        "type": "text",
        "etinBlock":true,
        "validation": "etin",
        "label": "E-Tin",
        "grid": 2,

    },
    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "eTinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "eTinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "eTinopen"],
        "conditionalArrayValue": ["Y", true],
        "grid":2,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "label": "Tax Submission Year",
        "required":true,
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "eTinopen"],
        "conditionalArrayValue": ["N", true],
        "grid": 2,
    },

    {
        "varName": "birthCertificateNo",
        "type": "text",
        "validation": "birthCertificateNumber",
        "label": "Birth Cer.",
        "grid": 2,

    },
    {
        "varName": "drivingLicense",
        "type": "text",
        "label": "Driving License",
        "grid": 2,

    },
    {
        "varName": "nationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "errorMessage": "Error",
        "label": "Nationality ",
        "required": true,
        "grid": 2,


    },
    {
        "varName": "SelectCommunicationAddress",
        "type": "select",
        "enum": [
            "Present",
            "Permanent",
            "Office",
        ],
        "required": true,
        "label": "Select Communication Address ",
        "grid": 2
    },
    {
        "varName": "communicationAddress1",
        "type": "text",
        "multiline": true,
        "label": "Communication Address 1 ",
        "grid": 3,
        "required": true,
    },

    {
        "varName": "communicationAddress2",
        "type": "text",
        "multiline": true,
        "label": "Communication Address 2",
        "grid": 3,
        "required": true,
    },

    {
        "varName": "city2",
        "label": "City",
        "required": true,
        "type": "autoCompleteValueReturn",
        "enum": [],
        "grid": 2
    },

    {
        "varName": "state2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Division(State)",
        "grid": 2
    },

    {
        "varName": "postalCode3",
        "type": "text",
        "validation": "numeric",
        "required": true,
        "label": "Postal Code",
        "maxLength":6,
        "grid": 2
    },
    {
        "varName": "openingBranch",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Opening Branch",
        "grid": 2
    },
    {
        "varName": "nationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Country",
        "grid": 2
    },
    {
  "varName": "schemeCodeForSavings",
  "type": "autoCompleteValueReturn",
  "enum": [],
  "required": true,
  "label": "Scheme Code ",
  "grid": 2,
  "conditional": true,
  "conditionalVarName": "accType",
  "conditionalVarValue": "SAVINGS",
 },
 {
  "varName": "schemeCodeForCurrent",
  "type": "autoCompleteValueReturn",
  "enum": [],
  "required": true,
  "label": "Scheme Code ",
  "grid": 2,
  "conditional": true,
  "conditionalVarName": "accType",
  "conditionalVarValue": "CURRENT",
 },
    {
        "varName": "currency",
        "type": "autoCompleteValueReturn",
        "label": "Currency ",
        "required": true,
        "grid": 2,
        "enum": []
    },
    {
        "varName": "rmCode",
        "type": "text",
        "label": "RM Code ",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Normal Customer",
        "grid": 2,
    }, "Normal Customer",

    {
        "varName": "rmCode",
        "type": "text",
        "label": "RM Code ",
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
        "grid": 2,
    },
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Sector Code ",
        "grid": 2,
    },
    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Sub Sector Code",
        "grid": 2,
    },


    {
        "varName": "priorityCenterCode",
        "type": "select",
        "enum": [
           "PR104",
            "PR142",
            "PR177",
            "PR186",
            "PR187",
            "PR195",
            "PR208",
            "PR169",
        ],
        "errorMessage": "Error",
        "label": "Priority Center Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRMCode",
        "type": "textApiCall",
        "errorMessage": "Error",
        "label": "Monitoring  RM Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRmCodeName",
        "type": "text",
        "label": "Monitoring RM Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["customerStatus", "monitoringRMCodeApiCall"],
        "conditionalArrayValue": ["Priority", true],
        "grid": 2,
    },

    {
        "varName": "wealthManagerCode",
        "type": "textApiCall",
        "errorMessage": "Error",
        "label": "Wealth Manager Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "wealthManagerCodeName",
        "type": "text",
        "label": "Monitoring RM Name",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["customerStatus", "wealthManagerCodeApiCall"],
        "conditionalArrayValue": ["Priority", true],
        "grid": 2,
    },
    {
        "varName": "primaryCBHolderforPriority",
        "type": "text",
        "errorMessage": "Error",
        "label": "Primary CB Holder for Priority",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "relationshipwithPrimaryCBHolder",
        "type": "text",
        "errorMessage": "Error",
        "label": "Relationship with Primary CB Holder",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "functionCodeforChequeBookWaiver",
        "type": "text",
        "errorMessage": "Error",
        "readOnly": true,
        "label": "Function Code for Cheque Book Waiver",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "waiverTypeforChequeBookWaiver",
        "type": "text",
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Waiver Type  for Cheque Book Waiver",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "waiverField",
        "type": "text",
        "readOnly": true,
        "errorMessage": "Error",
        "label": "Waiver Field",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },


    {
        "varName": "introducerId",
        "type": "textApiCall",
        "required": true,
        "label": "Introducer ID",
        "grid": 2,

    },
    {
        "varName": "introducerName",
        "type": "text",
        "multiline": true,
        "readOnly": true,
        "label": "Introducer Name",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "introducerIdApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "accountNumberForNidVerified",
        "type": "textApiCall",
        "required": true,
        "label": "Introducer Account Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },

    {
        "varName": "accountNameForNidVerified",
        "type": "text",
        "readOnly": true,
        "label": "Introducer Account Name",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["accountNumberForNidVerifiedApiCall", "nidVerified"],
        "conditionalArrayValue": ["YES", "NO"]
    },
    {
        "varName": "signatureCardView",
        "type": "submit",
        "label": "Signature Card View",
        "mt": 15,
        "event": false,
        "grid":2,
        "conditionalArray": true,
        "conditionalArrayList": ["accountNumberForNidVerifiedApiCall", "nidVerified"],
        "conditionalArrayValue": ["YES", "NO"]
    },
    {
        "varName": "ccepCode",
        "type": "text",
        "label": "CCEP Code",
        "grid": 2,

    },


];

let BOMJsonFormForDSTIndividual = makeReadOnlyObject(JSON.parse(JSON.stringify(makeGridObject(CSJsonFormForDSTIndividual.concat(CSJsonFormForDSTServiceAll)))));

let BMJsonFormForDSTIndividual = makeReadOnlyObject(JSON.parse(JSON.stringify(CSJsonFormForDSTIndividual.concat(CSJsonFormForDSTServiceAll))));
let MAKERJsonFormForDSTIndividual = [
    {
        "type": "title",
        "label": "MIS RELATED",
        "grid": 12,
    },
    {
        "varName": "missolId",
        "type": "text",
        "label": "SOL ID",
        "grid": 6,
    },

    {
        "varName": "misprimarySolId",
        "type": "text",
        "label": "Primary Sol ID",
        "grid": 6,
    },

    {
        "varName": "misagentBankingOutletFreeCode1",
        "type": "select",
        "enum": [],
        "label": "Agent Banking Outlet/ Free Code 1",
        "grid": 6,
    },

    {
        "varName": "miscustomerIdCifId",
        "type": "text",
        "required": true,
        "label": "Customer ID/CIF ID",
        "grid": 6,
    },

    {
        "varName": "misaCNumber",
        "type": "text",
        "required": true,
        "label": "A/C Number",
        //misaCNumber=inputData.accountNumber
        "grid": 6,
    },

    {
        "varName": "mistitle",
        "type": "select",
        "enum": [],
        //mistitle=inputData.customerTitle
        "label": "Title",
        "grid": 6,
    },

    {
        "varName": "miscustomerNameLastName",
        "type": "text",
        "required": true,
        //inputData.miscustomerNameLastName=inputData.customerName;

        "label": "Customer Name/Last Name",
        "grid": 6,
    },

    {
        "varName": "misshortName",
        "type": "text",
        "required": true,
        //inputData.misshortName=inputData.shortName;

        "label": "Short Name",
        "grid": 6,
    },

    {
        "varName": "misgender",
        "type": "select",
        "required": true,
        "enum": [
            "M",
            "F",
            "TRANSGENDER",
            "O"
        ],
        "label": "Gender",
        "grid": 6,
    },

    {
        "varName": "miscustomerStatusNormalStaffPriorityEtc",
        "type": "select",
        "enum": [
            "Normal Customer",
            "Staff",
            "Priority",
        ],
        "label": "Customer Status",
        //inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.customerStatus
        "grid": 6,
    },

    {
        "varName": "misoccupationCodePriorityCodeStaff",
        "type": "select",
        "enum": [],
        "label": "`Occupation` Code/Priority Code/Staff",
        "grid": 6,
        //
        /*  if(this.state.inputData.occupationCodeNormal!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodeNormal
      }
      else if(this.state.inputData.occupationCodePriority!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodePriority
      }
      else if(this.state.inputData.occupationCodeStaff!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodeStaff
      }*/
    },

    {
        "varName": "misconstitution",
        "type": "select",
        "enum": [],
        //master data cbl provide
        "label": "Constitution",
        "grid": 6,
    },

    {
        "varName": "misstaffIndicatorStatus",
        "type": "select",
        "enum": [],
        "label": "Staff Indicator/Status",
        //inputData.misstaffIndicatorStatus=inputData.staffIndicator
        "grid": 6,
    },

    {
        "varName": "misstaffIdNumber",
        "type": "text",
        "enum": [],
        "label": "Staff ID Number",
        "validation":"staffId",
        //inputData.misstaffIdNumber=inputData.staffIdNumber
        "grid": 6,
    },

    {
        "varName": "mispriorityCenterCodeFreeCode1",
        "type": "select",
        "enum": [],
        //inputData.mispriorityCenterCodeFreeCode1=inputData.priorityCenterCode;
        "label": "Priority Center Code/Free Code 1",
        "grid": 6,
    },

    {
        "varName": "mispriorityMonitoringRmCodeFreeCode3",
        "type": "select",
        "enum": [],
        //inputData.mispriorityMonitoringRmCodeFreeCode3=inputData.monitoringRMCode
        "label": "Priority Monitoring  RM Code/Free Code 3",
        "grid": 6,
    },

    {
        "varName": "misprimaryCbHolderForPriorityFreeText8",
        "type": "text",
        //inputData.misprimaryCbHolderForPriorityFreeText8=inputData.primaryCBHolderforPriority
        "label": "Primary CB Holder for Priority/Free Text 8",
        "grid": 6,
    },

    {
        "varName": "misrelationshipWithPrimaryCbHolderFreeText9",
        "type": "text",
        //inputData.misrelationshipWithPrimaryCbHolderFreeText9=inputData.relationshipwithPrimaryCBHolder;
        "label": "Relationship with Primary CB Holder/Free Text 9",
        "grid": 6,
    },

    {
        "varName": "misfunctionCodeForChequeBookWaiver",
        "type": "select",
        "enum": [],
        //inputData.misfunctionCodeForChequeBookWaiver=inputData.functionCodeforChequeBookWaiver
        "label": "Function Code for Cheque Book Waiver",
        "grid": 6,
    },

    {
        "varName": "miswaiverTypeForChequeBookWaiver",
        "type": "select",
        "enum": [],
        //inputData.waiverTypeforChequeBookWaiver=inputData.functionCodeforChequeBookWaiver
        "label": "Waiver Type  for Cheque Book Waiver",
        "grid": 6,
    },

    {
        "varName": "misbbkSegmentCodeFreeCode7",
        "type": "select",
        "enum": [],
        "label": "BBK Segment Code/Free Code 7",
        "grid": 6,
    },

    {
        "varName": "misdebitCardIssueFreeCode10",
        "type": "select",
        "enum": [],
        "label": "Debit Card Issue/Free Code 10",
        "grid": 6,
    },

    {
        "varName": "mismigratedNidVerificationFreeText2",
        "type": "text",
        "label": "Migrated NID Verification/Free Text 2",
        "grid": 6,
    },

    {
        "varName": "mismigratedSmartCardNumberFreeText5",
        "type": "text",
        "label": "Migrated Smart Card Number Free Text 5",
        "grid": 6,
    },

    {
        "varName": "mispepStatusFreeText10",
        "type": "text",
        "label": "PEP Status/Free Text 10",
        "grid": 6,
    },

    {
        "varName": "mismigratedCifsDocumentNoFreeText13",
        "type": "text",
        "label": "Migrated CIFs Document No/Free Text 13",
        "grid": 6,
    },

    {
        "varName": "miswaiverFieldFreeCode10",
        "type": "select",
        "enum": [],
        //inputData.miswaiverFieldFreeCode10=inputData.waiverField
        "label": "Waiver Field/Free Code 10",
        "grid": 6,
    },

    {
        "varName": "misccepCodeFreeCode9",
        "type": "select",
        "enum": [],
        //inputData.misccepCodeFreeCode9=inputData.ccepCode
        "label": "CCEP Code/Free Code 9",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText1",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText2",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText3",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "misavailedTradeServices",
        "type": "select",
        "enum": [],
        "label": "Availed Trade services",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Currency Code",
        "grid": 6,
    },

    {
        "varName": "aof1currencyCodeCcy",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.aof1currencyCodeCcy=inputData.currency
        "label": "Currency Code/CCY",
        "grid": 6,
    },

    {
        "varName": "aof1commAddressSelection",
        "type": "select",
        "enum": [],
        "label": "Comm. Address Selection ",
        "grid": 6,
    },

    {
        "varName": "aof1emailDesignatedEmailAddress",
        "type": "text",
        //inputData.aof1emailDesignatedEmailAddress=inputData.designatedEmail
        "label": "Email/Designated Email Address",
        "grid": 6,
    },

    {
        "varName": "aof1designatedMobileNumberPhoneNo1",
        "type": "text",
        "validation": "numeric",
        //inputData.aof1designatedMobileNumberPhoneNo1=inputData.designatedMobileNumber
        "label": "Designated Mobile Number/Phone No 1",
        "grid": 6,
    },


    {
        "varName": "aof1statement",
        "type": "select",
        "enum": [
            "Printed Statement",
            "E-Statement",
            "NO"
        ],
        "label": "Statement ",
        "grid": 6,
    },

    {
        "varName": "aof1despatchModePrintedEStatementBoth",
        "type": "select",
        "enum": [],
        "label": "Despatch Mode (Printed/E-Statement/Both)",
        "grid": 6,
    },
    {
        "varName": "aof1smsAlertFreeText11",
        "type": "checkbox",
        "label": "SMS Alert/Free Text 11",
        "grid": 6,
    },

    {
        "type": "title",
        "label": "Primary Introducer details",
        "grid": 12,
    },

    {
        "varName": "primryintroducerCustomerIdCifId",
        "type": "text",
        //inputData.primryintroducerCustomerIdCifId=inputData.introducerId
        "label": "Introducer Customer Id/CIF ID",
        "grid": 6,
    },

    {
        "varName": "primryiintroducerNameLastName",
        "type": "text",
        //inputData.primryiintroducerNameLastName=inputData.introducerName
        "label": "Introducer Name/Last Name",
        "grid": 6,
    },

    {
        "varName": "primryiintroducerStatus",
        "type": "select",
        "enum": [],
        "label": "Introducer Status",
        "grid": 6,
    },

    {
        "varName": "primryicifTypePrimaryIntroducerDetails>CifType",
        "type": "text",
        "enum": [],
        "label": "CIF Type/Primary Introducer Details",
        "grid": 6,
    },

    /*  {
          "varName":"noOfNominee",
         "type": "select",
        "enum": [],
          "label":"No of Nominee",
          "grid":6,
      },

      {
          "varName":"registrationNo",
         "type":"text",
        "validation":"numeric",
          "label":"Registration No",
          "grid":6,
      },

      {
          "varName":"cifIdIndividualId",
         "type": "select",
        "enum": [],
          "label":"CIF ID/Individual ID",
          "grid":6,
      },

      {
          "varName":"nomineeName",
          "type":"text",
          "label":"Nominee Name",
          "grid":6,
      },

      {
          "varName":"relationship",
         "type": "select",
        "enum": [],
          "label":"Relationship",
          "grid":6,
      },

      {
          "varName":"percentage",
         "type":"text",
        "validation":"numeric",
          "label":"Percentage",
          "grid":6,
      },

      {
          "varName":"addressField1",
          "type":"text",
          "label":"Address Field 1 ",
          "grid":6,
      },

      {
          "varName":"addressField2",
          "type":"text",
          "label":"Address Field 2",
          "grid":6,
      },

      {
          "varName":"city",
         "type": "select",
        "enum": [],
          "label":"City",
          "grid":6,
      },

      {
          "varName":"state",
         "type": "select",
        "enum": [],
          "label":"State",
          "grid":6,
      },

      {
          "varName":"postalCode",
         "type":"text",
        "validation":"numeric",
          "label":"Postal Code",
          "maxLength":6,
          "grid":6,
      },

      {
          "varName":"country",
         "type": "select",
        "enum": [],
          "label":"Country",
          "grid":6,
      },

      {
          "varName":"photoIdNumberRegNo",
          "type":"text",
          "label":"Photo Id Number/Reg No",
          "grid":6,
      },

      {
          "varName":"nomineeDob",
          "type":"date",
          "label":"Nominee DOB",
          "grid":6,
      },

      {
          "varName":"nomineeMinor",
         "type": "select",
        "enum": [],
          "label":"Nominee Minor",
          "grid":6,
      },

      {
          "varName":"guardiansName",
          "type":"text",
          "label":"Guardian's Name",
          "grid":6,
      },

      {
          "varName":"guardianCode",
         "type": "select",
        "enum": [],
          "label":"Guardian Code",
          "grid":6,
      },

      {
          "varName":"guardianPhotoId",
          "type":"text",
          "label":"Guardian Photo Id",
          "grid":6,
      },

      {
          "varName":"gAddressField1",
          "type":"text",
          "label":"Address Field 1",
          "grid":6,
      },

      {
          "varName":"gAddressField2",
          "type":"text",
          "label":"Address Field 2",
          "grid":6,
      },

      {
          "varName":"gCity",
         "type": "select",
        "enum": [],
          "label":"City",
          "grid":6,
      },

      {
          "varName":"gState",
         "type": "select",
        "enum": [],
          "label":"State",
          "grid":6,
      },

      {
          "varName":"postal",
         "type":"text",
        "validation":"numeric",
          "label":"Postal ",
          "grid":6,
      },

      {
          "varName":"gCountry",
         "type": "select",
        "enum": [],
          "label":"Country",
          "grid":6,
      },
   */
    {
        "type": "title",
        "label": "Nominee",
        "grid": 12,
    },


];
let MAKERJsonFormForDSTIndividualLast = [
    {
        "type": "title",
        "label": "Other Bank Information",
        "grid": 12,
    },
    {
        "varName": "otherBankbankName",
        "type": "select",
        "enum": [],
        "label": "Bank Name",
        "grid": 6,
    },

    {
        "varName": "otherBankbranchName",
        "type": "select",
        "enum": [],
        "label": "Branch Name",
        "grid": 6,
    },
    {
        "varName": "schemeCodeChangemodeOfOperation",
        "type": "select",
        "enum": [],
        "label": "Mode of Operation",
        "grid": 6,
    },
    {
        "type": "title",
        "label": " Scheme Change for Insta Pack",
        "grid": 12,
    },


    {
        "varName": "schemeCodeChangefunctionForSchemeChangeInCaseOfInstaPack",
        "type": "select",
        "enum": [],
        "label": "Function for Scheme Change in case of Insta Pack",
        "grid": 6,
    },

    {
        "varName": "schemeCodeChangetargetSchemeCodeInCaseOfInstaPack",
        "type": "text",
        "label": "Target Scheme Code in case of Insta Pack",
        "grid": 6,
    },

    {
        "varName": "schemeCodeChangetrialMode",
        "type": "select",
        "enum": [],
        "label": "Trial Mode",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "CRM MIS",
        "grid": 12,
    },


    {
        "varName": "crmMissubSegment",
        "type": "select",
        "enum": [],
        "label": "Sub Segment",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Basic Information Update",
        "grid": 12,
    },
    {
        "varName": "basicInformationUpdateprimarySolId",
        "type": "select",
        "enum": [],
        "label": "Primary Sol ID",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdategender",
        "type": "select",
        "enum": [
            "M",
            "F",
            "TRANSGENDER",
            "O"
        ],
        "label": "Gender",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdatefatherNamePrefferedName",
        "type": "text",
        "label": "Father Name/Preffered Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdatemotherNameMothersMaidenName",
        "type": "text",
        "label": "Mother Name/Mothers Maiden Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdatemaritalStatus",
        "type": "select",
        "enum": [],
        "label": "Marital Status",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdatespouseNamePurgeRemarks",
        "type": "text",
        "label": "Spouse Name/Purge Remarks",
        "grid": 6,
        "required":true,
        "masterData": true,
        "conditionalVarName": "basicInformationUpdatemaritalStatus",
        "conditionalVarValue":"MARID",
        "validationB":"length",
        "maxLength":240,
    },

    {
        "varName": "basicInformationUpdatedob",
        "type": "date",
        //inputData.basicInformationUpdatedob=inputData.gender
        "label": "DOB",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateminorStatusMinorIndicator",
        "type": "select",
        "enum": [
            "YES",
            "NO"
        ],
        "label": "Minor Status/Minor Indicator",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateseniorCitizenIndicator",
        "type": "select",
        "enum": [],
        "label": "Senior Citizen Indicator",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Gurdian Details - Only Visible In Case Minor Account Holder",
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "YES",

        "grid": 12,
    },


    {
        "varName": "guardianDetailsrelation",
        "type": "select",
        "enum": [],
        "label": "Relation",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "YES",
    },

    {
        "varName": "guardianDetailsbankRelationType",
        "type": "select",
        "enum": [],
        "label": "Bank Relation Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "YES",
    },

    {
        "varName": "guardianDetailsrelation1",
        "type": "select",
        "enum": [],
        "label": "Relation",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "YES",
    },

    {
        "varName": "guardianDetailsguardianSelectionGurdian",
        "type": "select",
        "enum": [],
        "label": "Guardian Selection/Gurdian",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "YES",
    },

    {
        "varName": "guardianDetailsgurdianCifNumberCustomer",
        "type": "select",
        "enum": [],
        "label": "Gurdian CIF Number/Customer ",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "YES",
    },

    {
        "varName": "guardianDetailslastName",
        "type": "select",
        "enum": [],
        "label": "Last Name",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "YES",
    },
    {
        "type": "title",
        "label": "National ID Card",
        "grid": 12,
    },
    {
        "varName": "natioanlIdCarddocumentTypeN",
        "type": "select",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "natioanlIdCarddocumentTypeDescriptionN",
        "type": "select",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "natioanlIdCarddocumentCodeN",
        "type": "select",
        "enum": [],
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "nationalIdCardNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "National ID Card Number/Unique ID",
        "grid": 6,
    },

    {
        "varName": "natioanlIdCardnidVerificationConfirmationIsDocumentVerified1",
        "type": "select",
        "enum": [],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
    },

    {
        "varName": "natioanlIdCardissueDateN",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Smart ID Card",
        "grid": 12,
    },
    {
        "varName": "smartIdCarddocumentTypeS",
        "type": "select",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "smartIdCarddocumentTypeDescriptionS",
        "type": "select",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "smartIdCarddocumentCodeS",
        "type": "select",
        "enum": [],
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "smartCardNumberUniqueId",
        "type": "text",
        "label": "Smart Card Number/Unique ID",
        "grid": 6,
    },

    {
        "varName": "smartIdCardnidVerificationConfirmationIsDocumentVerified",
        "type": "select",
        "enum": [],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
    },

    {
        "varName": "smartIdCardissueDateS",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Passport Number",
        "grid": 12,
    },
    {
        "varName": "passportNumberdocumentTypeP",
        "type": "select",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "passportNumberdocumentTypeDescriptionP",
        "type": "select",
        "enum": [],
        //inputData.passportNumberdocumentTypeDescriptionP=inputData.passport
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "passportNumberdocumentCodeP",
        "type": "select",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "passportNumberpassportNumberUniqueId",
        "type": "text",
        "label": "Passport Number/Unique ID",
        "grid": 6,
    },

    {
        "varName": "passportNumberplaceOfIssue",
        "type": "select",
        "enum": [],
        "label": "Place of Issue",
        "grid": 6,
    },

    {
        "varName": "passportNumberissueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
    },

    {
        "varName": "passportNumbercountryOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country of Issue",
        "grid": 6,
    },

    {
        "varName": "passportNumberexpiryDate",
        "type": "date",
        "label": "Expiry Date",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Birth Certificate",
        "grid": 12,
    },
    {
        "varName": "birthCertificatedocumentTypeB",
        "type": "select",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "birthCertificatedocumentTypeDescriptionB",
        "type": "select",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "birthCertificatedocumentCodeB",
        "type": "select",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "birthCertificatebirthCertificateNumberUniqueId",
        "type": "text",
        "label": "Birth Certificate Number/Unique ID",
        //inputData.birthCertificatebirthCertificateNumberUniqueId=inputData.birthCertificateNo
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Chairman Certificate",
        "grid": 12,
    },
    {
        "varName": "chairmanCertificatedocumentTypeCC",
        "type": "select",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificatedocumentTypeDescriptionCC",
        "type": "select",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificatedocumentCodeCC",
        "type": "select",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificateUniqueId",
        "type": "text",
        "label": "Chairman Certificate/Unique ID",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificateplaceOfBirthFreeText14",
        "type": "text",
        "label": "Place of Birth/Free Text 14",
        "maxLength":15,
        "validationB":"length",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificatecountryOfBirthFreeText15",
        "type": "text",
        "label": "Country of Birth/Free Text 15",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificatenationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Nationality",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificatenonResident",
        "type": "select",
        "enum": [],
        "label": "Non Resident",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificateturnedNonResidentOn",
        "type": "date",
        "label": "Turned Non Resident On",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "E-Tin",
        "grid": 12,
    },
    {
        "varName": "etindocumentType",
        "type": "select",
        "enum": [],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "etindocumentTypeDescription",
        "type": "select",
        "enum": [],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "etindocumentCode",
        "type": "select",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "etineTinNumber",
        "type": "text",
        //inputData.etineTinNumber=inputData.eTin
        "label": "E-Tin Number/Unique ID",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Employer Data",
        "grid": 12,
    },
    {
        "varName": "employerDataprofessionEmploymentType",
        "type": "select",
        "enum": [],
        "label": "Profession /Employment Type",
        "grid": 6,
    },

    {
        "varName": "employerDataemploymentType",
        "type": "select",
        "enum": [],
        "label": "Employment Type",
        "grid": 6,
    },

    {
        "varName": "employerDatanameOfEmployerEmployerName",
        "type": "text",
        "label": "Name of Employer/Employer Name",
        "validationB":"length",
        "maxLength": 50,
        "grid": 6,
    },

    {
        "varName": "employerDatamonthlyIncomeGrossIncome",
        "type": "text",
        "label": "Monthly Income/Gross income",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Contact Number - Phone",
        "grid": 12,
    },
    {
        "varName": "contactNumberpreferredContactNoType",
        "type": "select",
        "enum": [],
        "label": "Preferred Contact no type",
        "grid": 6,
    },

    {
        "varName": "contactNumbercontactType1",
        "type": "select",
        "enum": [],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNoEmailId1",
        "type": "select",
        "enum": [],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNo1",
        "type": "text",
        "validation": "numeric",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "contactNumbercontactType2",
        "type": "select",
        "enum": [],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNoEmailId2",
        "type": "select",
        "enum": [],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNo2",
        "type": "text",
        "validation": "numeric",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "contactNumbercontactType",
        "type": "select",
        "enum": [],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNoEmailId3",
        "type": "select",
        "enum": [],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNo",
        "type": "text",
        "validation": "numeric",
        "label": "Phone No",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Contact Number - Email",
        "grid": 12,
    },
    {
        "varName": "contactNumberEmailpreferedEmailIdType",
        "type": "select",
        "enum": [],
        "label": "Prefered Email ID type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailType1",
        "type": "select",
        "enum": [],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId4",
        "type": "select",
        "enum": [],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmaildesignatedEmailAddressEmailId1",
        "type": "text",
        "label": "Designated Email Address/Email ID 1",
         "validation": "email",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailType",
        "type": "select",
        "enum": [],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId",
        "type": "select",
        "enum": [],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailId2",
        "type": "text",
        "label": "Email ID 2",
        "validation": "email",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Mailing Address",
        "grid": 12,
    },
    {
        "varName": "maillingAdresspreferredAddressType",
        "type": "select",
        "enum": [],
        //inputData.maillingAdresspreferredAddressType=inputData.SelectCommunicationAddress

        "label": "Preferred Address type",
        "grid": 6,
    },

    {
        "varName": "maillingAdressaddressFormat",
        "type": "select",
        "enum": [],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "maillingAdressaddrssType",
        "type": "select",
        "enum": [],
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "maillingAdressaddressLabel",
        "type": "select",
        "enum": [],
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "maillingAdressmailingAddressField1",
        "type": "text",
        //inputData.maillingAdressmailingAddressField1=inputData.communicationAddress1
        "label": "Mailing Address Field 1",
        "grid": 6,
    },

    {
        "varName": "maillingAdressmailingAddressField2",
        "type": "text",
        //inputData.maillingAdressmailingAddressField2=inputData.communicationAddress2
        "label": "Mailing Address Field 2",
        "grid": 6,
    },

    {
        "varName": "maillingAdresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdresscity=inputData.city2

        "label": "City",
        "grid": 6,
    },

    {
        "varName": "maillingAdressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdressstate=inputData.state2

        "label": "Division(State)",
        "grid": 6,
    },

    {
        "varName": "maillingAdresspostalCode",
        "type": "text",
        "validation": "numeric",
        "label": "Postal Code",
        "maxLength":6,
        //inputData.maillingAdresspostalCode=inputData.postalCode3
        "grid": 6,
    },

    {
        "varName": "maillingAdresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdresscountry=inputData.nationality
        "label": "Country",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Home Address",
        "grid": 12,
    },
    {
        "varName": "homeAddressaddressFormat",
        "type": "select",
        "enum": [],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "homeAddressaddrssType",
        "type": "select",
        "enum": [],
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "homeAddressaddressLabel",
        "type": "select",
        "enum": [],
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "homeAddresshomeAddressField1",
        "type": "text",
        "label": "Home Address Field 1",
        "grid": 6,
    },

    {
        "varName": "homeAddresshomeAddressField2",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 6,
    },

    {
        "varName": "homeAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],

        "label": "City",
        "grid": 6,
    },

    {
        "varName": "homeAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
    },

    {
        "varName": "homeAddresspostalCode",
        "type": "text",
        "validation": "numeric",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 6,
    },

    {
        "varName": "homeAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Work Address",
        "grid": 12,
    },
    {
        "varName": "workAddressaddressFormat",
        "type": "select",
        "enum": [],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "workAddressaddressType",
        "type": "select",
        "enum": [],
        "label": "Address type",
        "grid": 6,
    },

    {
        "varName": "workAddressaddressLabel",
        "type": "select",
        "enum": [],
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "workAddressworkAddressField1",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 6,
    },

    {
        "varName": "workAddressworkAddressField2",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 6,
    },

    {
        "varName": "workAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "workAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "validation": "numeric",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 6,
    },

    {
        "varName": "workAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Present Address",
        "grid": 12,
    },
    {
        "varName": "presentAddressaddressFormat",
        "type": "select",
        "enum": [],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "select",
        "enum": [],
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "presentAddressaddressLabel",
        "type": "select",
        "enum": [],
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "presentAddresspresentAddressField1",
        "type": "text",
        "label": "Present Address Field 1",
        "grid": 6,
    },

    {
        "varName": "presentAddresspresentAddressField2",
        "type": "text",
        "label": "Present Address Field 2",
        "grid": 6,
    },

    {
        "varName": "presentAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "presentAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
    },

    {
        "varName": "presentAddresspostalCode",
        "type": "text",
        "validation": "numeric",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 6,
    },

    {
        "varName": "presentAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Related CB Tagging :",
        "grid": 12,
    },
    {
        "varName": "relatedCbTaggingrelationType",
        "type": "select",
        "enum": [],
        "label": "Relation Type",
        "grid": 6,
    },

    {
        "varName": "relatedCbTaggingrelationCode",
        "type": "select",
        "enum": [],
        "label": "Relation Code",
        "grid": 6,
    },

    {
        "varName": "relatedCbTaggingdesignationCode",
        "type": "select",
        "enum": [],
        "label": "Designation Code",
        "grid": 6,
    },

    {
        "varName": "relatedCbTaggingcifId",
        "type": "text",
        "label": "CIF ID",
        "grid": 6,
    },

    {
        "varName": "relatedCbTaggingtitle",
        "type": "select",
        "enum": [],
        "label": "Title",
        "grid": 6,
    },

    {
        "varName": "relatedCbTaggingname",
        "type": "text",
        "label": "Name",
        "grid": 6,
    },


    {
        "type": "title",
        "label": "Fincore Data Update - Account Level",
        "grid": 12,
    },
    {
        "varName": "fincoreDataUpdateaCReportCode",
        "type": "text",
        "label": "A/C Report Code",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatecustomerRelationshipACManager",
        "type": "text",
        "label": "Customer Relationship A/C Manager",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdateaCManagerId",
        "type": "text",
        "label": "A/C Manager ID",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatecontactPhoneNo",
        "type": "text",
        "label": "Contact Phone No.",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatecashLimit(Dr)",
        "type": "text",
        "label": "Cash Limit (Dr)",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatecashLimit(Cr)",
        "type": "text",
        "label": "Cash Limit (Cr)",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdateclearingLimit(Dr)",
        "type": "text",
        "label": "Clearing Limit (Dr)",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdateclearingLimit(Cr)",
        "type": "text",
        "label": "Clearing Limit (Cr)",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatetransferLimit(Dr)",
        "type": "text",
        "label": "Transfer Limit (Dr)",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatetransferLimit(Cr)",
        "type": "text",
        "label": "Transfer Limit (Cr)",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatenotes",
        "type": "text",
        "label": "Notes",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdateaccountStatement",
        "type": "text",
        "label": "Account Statement",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatestatementFrequency",
        "type": "text",
        "label": "Statement Frequency",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatedispatchMode",
        "type": "text",
        "label": "Dispatch Mode",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatenextPrintDate",
        "type": "text",
        "label": "Next Print Date",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdateinterestCreditAC",
        "type": "text",
        "label": "Interest Credit A/c.",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatewithholdingTaxBorneBy",
        "type": "text",
        "label": "Withholding Tax Borne By",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatewithholdingTaxLevel",
        "type": "text",
        "label": "Withholding Tax Level",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatewithholdingTaxPcnt",
        "type": "text",
        "label": "Withholding Tax Pcnt.",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdateaCStatus",
        "type": "text",
        "label": "A/C Status",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatedormantActivationCharge",
        "type": "select",
        "enum":[
            "Y",
            "N",
        ],
        "label": "Dormant Activation Charge",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatenomineeSelect",
        "type": "text",
        "label": "Nominee Select",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdateaCInformation",
        "type": "text",
        "label": "A/c. Information ",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatesectorCode",
        "type": "text",
        //inputData.fincoreDataUpdatesectorCode=inputData.nationality
        "label": "Sector Code",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatesubSectorCode",
        "type": "text",
        //subsector code
        "label": "Sub Sector Code",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdateoccupationCode",
        "type": "text",
        "label": "Occupation Code",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3",
        "type": "text",
        //rm code
        "label": "RM Code/ Wealth Manager Code/Free Code 3",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeText3",
        "type": "text",
        "label": "Free Text 3 ",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCode6",
        "type": "text",
        "label": "Free Code 6",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatedepositCodeFreeCode7",
        "type": "text",
        "label": "Deposit Code/Free Code 7",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCode8",
        "type": "text",
        "label": "Free Code 8",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeText10",
        "type": "text",
        "label": "Free Text 10",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCodeFreeText1",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCodeFreeText2",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCodeFreeText3",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },


    {

        "type": "title",
        "label": "KYC",
        "grid": 12,
    },
    {
        "varName": "functionCode",
        "type": "select",
        "enum": [
            "ADD",
            "UPDATE",
            "DELETE",
        ],
        "label": "Function Code",
        "grid": 6,
    },

    {
        "varName": "customerType",
        "type": "select",
        "enum": [
            "Individual",
            "Non Individual",
            "Deposit",
            "Govt",
        ],
        "label": "Customer Type",
        "grid": 6,
    },

    {
        "varName": "customerAccount",
        "type": "text",
        "validation": "numeric",
        "label": "Customer Account",
        "grid": 6,
    },

    {
        "varName": "aCTitle",
        "type": "text",
        "label": "A/C Title",
        "grid": 6,
    },

    {
        "varName": "accountType",
        "type": "select",
        "enum": [
            "SAVINGS",
            "CURRENT",
            /* "FCY",
             "RFCD"*/,
        ],
        "label": "Account Type",
        "grid": 6,
    },

    {
        "varName": "customersOccupation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Customer's Occupation",
        "grid": 6,
    },

    {
        "varName": "customerProbableMonthlyIncome",
        "type": "text",
        "label": "Customer probable monthly income",
        "grid": 6,
    },

    {
        "varName": "accountOrReference",
        "type": "text",
        "label": "Account or Reference",
        "grid": 6,
    },

    {
        "varName": "nameOfOfficerOpeningTheAccount",
        "type": "text",
        "label": "Name of Officer Opening the Account:",
        "grid": 6,
    },

    {
        "varName": "sourceOfFund",
        "type": "text",
        "label": "Source of Fund",
        "grid": 6,
    },

    {
        "varName": "documentsCollectedToEnsureTheSourceOfFund",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "label": "Documents Collected to Ensure the Source of Fund *",
        "grid": 6,
    },

    {
        "varName": "collectedDocumentsHaveBeenVerifiedOrNot",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "Collected Documents have been verified or not?",
        "grid": 6,
    },

    {
        "varName": "howTheAddressOfTheAccountHolderHasBeenVerified",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "label": "How the address (es) of the account holder has been Verified?",
        "grid": 6,
    },

    {
        "varName": "hasTheBeneficialOwnerOfTheAccountBeenIdentified",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "Has the Beneficial owner of the account been identified",
        "grid": 6,
    },

    {
        "varName": "nameOfBeneficiarOwner",
        "type": "text",
        "label": "Name of Beneficiar Owner",
        "grid": 6,
    },

    {
        "varName": "passportNo",
        "type": "text",
        "label": "Passport no",
        "grid": 6,
    },

    {
        "varName": "nationalIdCardNo",
        "type": "text",
        "label": "National ID Card No:",
        "grid": 6,
    },

    {
        "varName": "birthCertificateNo",
        "type": "text",
        "label": "Birth Certificate No:",
        "grid": 6,
    },

    {
        "varName": "electronicTaxId(E-Tin)",
        "type": "text",
        "label": "Electronic Tax ID(E-TIN)",
        "grid": 6,
    },

    {
        "varName": "drivingLicenceNo",
        "type": "text",
        "label": "Driving Licence No:",
        "grid": 6,
    },

    {
        "varName": "otherDocumentation",
        "type": "text",
        "label": "Other Documentation",
        "grid": 6,
    },

    {
        "varName": "reasonForOpeningTheAccountOfNon-ResidentAndForeigner",
        "type": "text",
        "label": "Reason for Opening the Account of Non-resident and Foreigner",
        "grid": 6,
    },

    {
        "varName": "typeOfVisa",
        "type": "text",
        "label": "Type of VISA",
        "grid": 6,
    },

    {
        "varName": "expDate",
        "type": "date",
        "label": "Exp date",
        "grid": 6,
    },

    {
        "varName": "theCustomerAPolitically",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "The customer a Politically Exposed Person (PEP)/ Influential Person (IP)/ Head or high official of international organization and otheir family members or close associates?",
        "grid": 6,
    },

    {
        "varName": "isThereAnyMatchToTheCustomer",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "Is there any match to the customer name with the terrorist activity, funding in terrorist activities or listed person or entity on suspicion for Financing of proliferation of weapons of mass destruction under UN Security Council's different regulations and any listed person or banned entity by the Government of Bangladesh according to related laws, rules and circular?",
        "grid": 6,
    },

    {
        "varName": "whatDoesTheCustomerDoInWhatTypeOfBusineessIsTheCustomerEngaged",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "What does the Customer do/in what type of busineess is the customer engaged?",
        "grid": 6,
    },


    {
        "label": "TP",
        "type": "title",
        "grid": 12,
    },


    {
        "varName": "function",
        "type": "select",
        "enum": [],
        "label": "Function",
        "grid": 6,
    },

    {
        "varName": "customerAccount",
        "type": "text",
        "validation": "numeric",
        "label": "Customer Account",
        "grid": 6,
    },

    {
        "varName": "accountName",
        "type": "text",
        "label": "Account Name",
        "grid": 6,
    },

    {
        "varName": "accountType",
        "type": "text",
        "label": "Account Type",
        "grid": 6,
    },

    {
        "varName": "accountOrReferenceNo",
        "type": "text",
        "label": "Account or Reference No",
        "grid": 6,
    },

    {
        "varName": "sourceOfFundForTransaction",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Source of Fund For Transaction ",
        "grid": 6,
    },

    {
        "varName": "entityType",
        "type": "text",
        "label": "Entity Type",
        "grid": 6,
    },

    {
        "varName": "monthlyProbableIncomeIndMonthlyProbableTurnOverNonInd",
        "type": "text",
        "label": "Monthly Probable Income (Ind)/Monthly Probable Turn Over (Non Ind)",
        "grid": 6,
    },
    {
        "type": "empty",
        "grid":12,
    },
    {
        "type": "empty",
        "grid":12,
    },
    {
        "varName": "cashDepositIncludingOnlineAtmDeposit",
        "type": "text",
        "validation": "numeric",
        "label": "Cash Deposit ( Including Online/ATM Deposit)",
        "grid": 6,
    },

    {
        "varName": "depositByTransferInstrument",
        "type": "text",
        "validation": "numeric",
        "label": "Deposit By Transfer/Instrument",
        "grid": 6,
    },

    {
        "varName": "foreignInwardRemittance",
        "type": "text",
        "validation": "numeric",
        "label": "Foreign Inward Remittance",
        "grid": 6,
    },

    {
        "varName": "depositOfIncomeFromExport",
        "type": "text",
        "validation": "numeric",
        "label": "Deposit of Income from Export",
        "grid": 6,
    },

    {
        "varName": "depositTransferFromBoShareMarket",
        "type": "text",
        "validation": "numeric",
        "label": "Deposit/Transfer from BO (Share Market)",
        "grid": 6,
    },

    {
        "varName": "othersSpecify",
        "type": "text",
        "validation": "numeric",
        "label": "Others (Specify)",
        "grid": 6,
    },

    {
        "varName": "totalProbableDeposit",
        "type": "text",
        "validation": "numeric",
        "label": "Total Probable Deposit",
        "grid": 6,
    },

    {
        "varName": "cashWithdrawalIncludingOnlineAtmWithdrawal",
        "type": "text",
        "validation": "numeric",
        "label": "Cash Withdrawal ( Including Online/ATM Withdrawal)",
        "grid": 6,
    },

    {
        "varName": "paymentByTransferInstrument",
        "type": "text",
        "validation": "numeric",
        "label": "Payment By Transfer/Instrument",
        "grid": 6,
    },

    {
        "varName": "foreignOutwardRemittance",
        "type": "text",
        "validation": "numeric",
        "label": "Foreign Outward Remittance",
        "grid": 6,
    },

    {
        "varName": "paymentAgainstImport",
        "type": "text",
        "validation": "numeric",
        "label": "Payment Against Import",
        "grid": 6,
    },

    {
        "varName": "depositTransferToBoShareMarket",
        "type": "text",
        "validation": "numeric",
        "label": "Deposit/Transfer TO BO (Share Market)",
        "grid": 6,
    },

    {
        "varName": "othersSpecify",
        "type": "text",
        "validation": "numeric",
        "label": "Others (Specify)",
        "grid": 6,
    },

    {
        "varName": "totalProbableWithdrawal",
        "type": "text",
        "validation": "numeric",
        "label": "Total Probable  Withdrawal",
        "grid": 6,
    },


    /* {
         "type": "title",
         "label": "FINCORE DATA UPDATE",
         "grid": 12,
     },
     {
         "varName": "fincoreDataUpdateCashLimit",
         "type": "text",
         "label": "Cash Limit (Dr And Cr)",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateClearingLimit",
         "type": "text",
         "label": "Clearing Limit (Dr And Cr)",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateTransferLimit",
         "type": "text",
         "label": "Transfer Limit (Dr And Cr)",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateNotes",
         "type": "text",
         "label": "Notes",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateAccountStatement",
         "type": "text",
         "label": "Account Statement",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateStatementFrequency",
         "type": "text",
         "label": "Statement Frequency",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateDispatchMode",
         "type": "text",
         "label": "Dispatch Mode",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateNextPrintDate",
         "type": "date",
         "label": "Next Print Date",
         "grid": 6,
     },

     {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
         "label": "Sector Code",
         "grid": 6,
     },

     {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
         "label": "Sub Sector Code",
         "grid": 6,
     },

     {
         "varName": "occupationCodeNormal",
         "type": "select",
         "enum": [],
         "label": "Occupation Code",
         "grid": 6,
     },

     {
         "varName": "rmCode",
         "type": "text",
         "label": "RM Code",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateDepositCode",
         "type": "text",
         "label": "Deposit Code",
         "grid": 6,
     },*/


];
let CHECKERJsonFormForDSTIndividual = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForDSTIndividual)));
let CHECKERJsonFormForDSTIndividualLast = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForDSTIndividualLast)));
//#####################DST Exist Individual#####################################################




//#####################Agent Banking#####################################################

//###CS ,BM  ,BOM ,MAKER,CHECKER   INDIVIDUAL#####################
let AbhMakerJsonFormForAgentIndividual = [
    {
        "varName": "accountSource",
        "type": "select",
        "label": "Account Source",
        "grid": 2,
        "errorMessage": "Error",
        "required": true,
        "enum": [
            "Conventional"
            //"Islamic"
        ],


    },
    {
        "varName": "accountType",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type",
        "enum": [
            "Insta Pack",
            "Regular"
        ],
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "accountSource",
        "conditionalVarValue": "Conventional",
    },
    {
        "varName": "accType",
        "type": "select",
        "required": true,
        "errorMessage": "Error",
        "label": "Account Type",
        "enum": [
            "SAVINGS",
            "CURRENT",
            /* "FCY",
             "RFCD"*/,
        ],
        "grid": 2,
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "errorMessage": "Error",

        "grid": 2,
    },


    {
        "varName": "nid",
        "type": "text",
        "errorMessage": "Error",
        "required": true,
        "validation": "nid",
        "label": "NID",
        "grid": 2,

    },
    {
        "varName": "passport",
        "type": "text",
        "validation": "passport",
        "errorMessage": "Error",
        "label": "Passport",
        "grid": 2,


    },
    {
        "varName": "customerName",
        "type": "text",
        "errorMessage": "Error",
        "label": "Customer Name",
        "required": true,
        "grid": 2,

    },
    {
        "varName": "dob",
        "type": "date",
        "maxDate": true,
        "label": "Date Of Birth",
        "required": true,
        "grid": 2,


    },
    {
        "varName": "gender",
        "type": "select",
        "label": "Gender",
        "enum": [
            "M",
            "F",
            "O"
        ],
        "required": true,
        "grid": 2,


    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email ",
        "errorMessage": "Error",
        "validation": "email",
        "grid": 2,


    },


    {
        "varName": "phone",
        "type": "text",
        "errorMessage": "Error",
        "validation": "phone",
        "phonePrefix": true,
        "required": true,
        "label": "Mobile Number",
        "grid": 2,


    },

    {
        "varName": "tin",
        "type": "text",
        "etinBlock":true,
        "validation": "etin",
        "label": "E-Tin",
        "grid": 2,

    },
    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "eTinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "required":true,
        "varName": "exceptionSubmission",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "eTinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "eTinopen"],
        "conditionalArrayValue": ["Y", true],
        "grid":2,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "label": "Tax Submission Year",
        "required":true,
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "eTinopen"],
        "conditionalArrayValue": ["N", true],
        "grid": 2,
    },

    {
        "varName": "registrationNo",
        "type": "text",
        "validation": "birthCertificateNumber",
        "label": "Birth Cer.",
        "grid": 2,

    },
    {
        "varName": "drivingLicense",
        "type": "text",
        "label": "Driving License",
        "grid": 2,

    },
    {
        "varName": "nationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "errorMessage": "Error",
        "label": "Nationality",
        "required": true,
        "grid": 2,


    },
    {
        "varName": "communicationAddress1",
        "type": "text",
        "label": "Communication Address 1",
        "grid": 2,

    },

    {
        "varName": "communicationAddress2",
        "type": "text",
        "label": "Communication Address 2",
        "grid": 2,
    },

    {
        "varName": "city2",
        "label": "City",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "grid": 2,

    },

    {
        "varName": "state2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 2,

    },

    {
        "varName": "postalCode3",
        "type": "text",
        "validation": "numeric",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 2,

    },
    {
        "varName": "schemeCodeForCurrent",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Scheme Code *",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "CURRENT",
    },
    {
        "varName": "schemeCodeForSavings",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Scheme Code *",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "SAVINGS",
    },
    {
        "varName": "currency",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Currency",
        "required": true,
        "grid": 2,

    },
    {
        "varName": "rmCode",
        "type": "text",

        "required": true,
        "label": "RM Code",
        "grid": 2,
    },
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],

        "required": true,
        "label": "Sector Code",
        "grid": 2,
    }, {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],

        "required": true,
        "label": "Sub Sector Code",
        "grid": 2,
    },
    /*{
        "varName": "occupationCode",
        "type": "text",
        "label": "Occupation Code",
        "grid": 6,

    },*/
    {
        "varName": "ccepCompanyCode",
        "type": "text",
        "label": "CCEP Company Code",
        "grid": 2,

    },
    {
        "varName": "priority",
        "type": "select",
        "label": "Priority",
        "enum": [
            "GENERAL",
            "HIGH",
        ],
        "grid": 2,
    },
    {
        "varName": "statementFacility",
        "type": "select",
        "grid": 3,
        "enum": [
            "Printed Statement",
            "E-Statement",
            "NO",
        ],
        "label": "Statement",

    },
    {
        "varName": "smsAlertRequest",
        "type": "checkbox",
        "label": "SMS Alert Request",
        "grid": 2

    },
    {
        "varName": "callCenterRegistration",
        "type": "checkbox",
        "label": "Call Center Registration",
        "grid": 2

    },


    {
        "varName": "lockerFacility",
        "type": "checkbox",
        "label": "Locker Facility",
        "grid": 2

    },
    {
        "varName": "loanRequest",
        "type": "checkbox",
        "label": "Loan Request",
        "grid": 2
    },


    /*
{
    "varName": "dpsAmount",
    "type": "text",
    "label": "Amount",
    "grid": 2,
    "conditional": true,
    "conditionalVarName": "dpsRequest",
    "conditionalVarValue": true,
},
{
    "varName": "dpsTenor",
    "type": "text",
    "label": "Tenor",
    "grid": 2,
    "conditional": true,
    "conditionalVarName": "dpsRequest",
    "conditionalVarValue": true,
},
{
    "varName": "dpsInterest",
    "type": "text",
    "label": "Interest %",
    "grid": 2,
    "conditional": true,
    "conditionalVarName": "dpsRequest",
    "conditionalVarValue": true,
},
{
    "varName": "dpsSi",
    "type": "text",
    "label": "SI Instruction",

    "grid": 2,
    "conditional": true,
    "conditionalVarName": "dpsRequest",
    "conditionalVarValue": true,
},
{
    "varName": "dpsSchemeCode",
    "type": "text",
    "label": "Scheme Code",
    "grid": 2,
    "conditional": true,
    "conditionalVarName": "dpsRequest",
    "conditionalVarValue": true,
},*/
    {
        "varName": "dpsRequest",
        "type": "checkbox",
        "label": "DPS Request",

        "grid": 12,

    },
    {
        "varName": "dpsAmount",
        "type": "text",
        "label": "Amount",
        "validation": "numeric",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true,
    },
    {
        "varName": "dpsTenorType",
        "type": "select",
        "label": "Tenure Type",
        "grid": 2,
        "enum": [
            "Monthly",
        ],
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true,
    },
    {
        "varName": "dpsTenor",
        "type": "select",
        "enum": [],
        "label": "Tenure",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true,
    },
    {
        "varName": "dpsInterest",
        "type": "text",
        "label": "Interest",
        "readOnly": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true,
    },
    {
        "varName": "dpsSi",
        "type": "date",
        "label": "SI Date",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsRequest",
        "conditionalVarValue": true,
    },
    {
        "varName": "fdrRequest",
        "type": "checkbox",
        "label": "FDR Request",
        "grid": 12
    },
    {
        "varName": "accountsType",
        "type": "select",
        "grid": 2,
        "enum": [
            "Fix General",
            "Double",
            "Monthly",
        ],
        "label": "Type Of Account",
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true,

    },
    {
        "varName": "doubleTennor",
        "type": "select",
        "grid": 2,
        "enum": [
            "Monthly"
        ],
        "label": "Tennor Type",
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Double",
    }, {
        "varName": "tennordDouble",
        "type": "text",
        "label": "Tennor",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Double",
    }, {
        "varName": "doubleAmount",
        "type": "text",
        "label": "Amount",
        "validation": "numeric",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Double",
    }, {
        "varName": "doubleInterest",
        "type": "text",
        "label": "Interest",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Double",
    }, {
        "varName": "doubleSiMaturity",
        "type": "select",
        "grid": 2,
        "enum": [
            "No"
        ],
        "label": "SI Maturity",
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Double",
    },

/////  Account Type Fix General /////
    {
        "varName": "fixGTennor",
        "type": "select",
        "grid": 2,
        "enum": [
            "Day",
            "Monthly"
        ],
        "label": "Tennor Type",
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Fix General",
    }, {
        "varName": "tennorFixG",
        "type": "text",
        "label": "Tennor",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Fix General",
    }, {
        "varName": "fixGAmount",
        "type": "text",
        "label": "Amount",
        "validation": "numeric",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Fix General",
    }, {
        "varName": "fixGInterest",
        "type": "text",
        "label": "Interest",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Fix General",
    }, {
        "varName": "fixGSiMaturity",
        "type": "select",
        "grid": 2,
        "enum": [
            "Yes",
            "No",
            "Encash at Maturity to my / Our Account No",
        ],
        "label": "SI Maturity",
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Fix General",
    }, {
        "varName": "fixdGMaturityYes",
        "type": "select",
        "label": "Maturity Yes",
        "enum": [
            "Renew Principal Only and Credit Interest to the Account No",
            "Renew Both Principal and Interest"
        ],
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "fixGSiMaturity",
        "conditionalVarValue": "Yes",
    },

///////  Month Tenor /////
    {
        "varName": "monthTennor",
        "type": "select",
        "grid": 2,
        "enum": [
            "Monthly"
        ],
        "label": "Tennor Type",
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Monthly",
    }, {
        "varName": "tennorMonth",
        "type": "text",
        "label": "Tennor",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Monthly",
    }, {
        "varName": "monthAmount",
        "type": "text",
        "label": "Amount",
        "validation": "numeric",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Monthly",
    }, {
        "varName": "monthInterest",
        "type": "text",
        "label": "Interest",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Monthly",
    }, {
        "varName": "monthSiMaturity",
        "type": "select",
        "grid": 2,
        "enum": [
            "Yes",
        ],
        "label": "SI Maturity",
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Monthly",
    }, {
        "varName": "monthMaturityYes",
        "type": "select",
        "label": "Maturity Yes",
        "enum": [
            "Renew Principal Only and Credit Interest to the Account No"
            // "Renew Principal Only "
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Monthly",
    },
    {
        "varName": "cityTouchRequest",
        "type": "checkbox",
        "label": "City Touch",
        "grid": 12

    },
    /* {
         "varName": "email",
         "type": "text",
         "label": "Email",
         "grid": 2,
         "conditional": true,
         "conditionalVarName": "cityTouchRequest",
         "conditionalVarValue": true,

     },
 */

    //cheque book
    {
        "varName": "chequeBookRequest",
        "type": "checkbox",
        "label": "Cheque Book Request",
        "grid": 12,

    },
    {
        "varName": "pageOfChequeBook",
        "type": "select",
        "enum": [],
        "label": "Page Of Cheque Book",
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
        "grid": 2

    },
    {
        "varName": "numberOfChequeBookRequest",
        "type": "select",
        "enum": [
            "1",
            "2",
            "3",
            "4",
            "5",
        ],
        "label": "Number Of Cheque Book",
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
        "grid": 2,


    },
    {
        "varName": "chequeBookDeliveryType",
        "type": "select",
        "label": "Delivery Type",
        "enum": [
            //,
            "Branch"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,

    },
    {
        "varName": "chequeBookBranch",
        "type": "select",
        "label": "Branch Name",
        "enum": [
            "GULSHAN 1",
            "MOTHIJHEEL 1",
            "DHANMONDI",
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "chequeBookDeliveryType",
        "conditionalVarValue": "Branch",

    },
    {
        "varName": "chequeBookDesign",
        "type": "select",
        "label": "Cheque Book Design",
        "enum": [
            "Sapphire",
            "Citygem",
            "City Alo",
            "Other"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,


    },
    {
        "varName": "chequeBookDesignOther",
        "type": "text",
        "label": "Other",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": "Other",


    },
//Debit Card
    {
        "varName": "debitCard",
        "type": "checkbox",
        "label": "Debit Card",
        "grid": 12
    },
    {
        "varName": "customerName",
        "type": "text",
        "label": "Name On Card",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,

    },

    {
        "varName": "cardType",
        "type": "select",
        "label": "Card Type",
        "grid": 2,
        "enum": [
            "VISA",
            "MASTER",
            "CITYMAXX",
        ],
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "debitCardDeliveryType",
        "type": "select",
        "label": "Delivery Type",
        "enum": [
            //,
            "Branch"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,

    },

    {
        "varName": "debitRequestkBranch",
        "type": "select",
        "label": "Branch Name",
        "enum": [,
            "GULSHAN 1",
            "MOTHIJHEEL 1",
            "DHANMONDI",
        ],
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "debitCardDeliveryType",
        "conditionalVarValue": "Branch",

    },

];
let AbhMakerJsonFormForAgentNonIndividual = [
    {
        "varName": "accountSource",
        "type": "select",
        "label": "Account Source",
        "grid": 2,
        "errorMessage": "Error",
        "required": true,
        "enum": [
            "Conventional"
           // "Islamic"
        ],


    },
    {
        "varName": "accountType",
        "type": "select",
        "errorMessage": "Error",
        "label": "Product Type",
        "enum": [
            "Insta Pack",
            "Regular"
        ],
        "grid": 2,

        "required": true,
        "conditional": true,
        "conditionalVarName": "accountSource",
        "conditionalVarValue": "Conventional",
    },
    {
        "varName": "accType",
        "type": "select",
        "required": true,
        "errorMessage": "Error",
        "label": "Account Type",
        "enum": [
            "SAVINGS",
            "CURRENT",
            /* "FCY",
             "RFCD"*/,
        ],
        "grid": 2,

    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",

        "grid": 2,
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "CB Number",
        "required": true,

        "grid": 2,
    },

    {
        "varName": "customerName",
        "type": "text",
        "label": "Company Name",
        "required": false,


        "grid": 2


    },

    {
        "varName": "email",
        "type": "text",
        "label": "Email",

        "required": false,


        "grid": 2


    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile Number",
        "required": false,

        "grid": 2

    },

    {
        "varName": "companyEtin",
        "type": "text",
        "label": "Company E-TIN",
        "required": false,

        "grid": 2


    },
    {
        "varName": "tradeLicense",
        "type": "text",
        "label": "Trade License",
        "required": true,


        "grid": 2


    },
    {
        "varName": "certificate",
        "type": "text",

        "label": "Certificate Of Incorporation",
        "required": false,
        "grid": 2


    },


    {
        "varName": "communicationAddress1",
        "type": "text",
        "label": "Communication Address 1",
        "grid": 2,

    },

    {
        "varName": "communicationAddress2",
        "type": "text",
        "label": "Communication Address 2",
        "grid": 2,
    },

    {
        "varName": "city2",
        "label": "City",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "grid": 2,

    },

    {
        "varName": "state2",
        "type": "autoCompleteValueReturn",
        "enum": [], "label": "Division(State)",
        "grid": 2,

    },

    {
        "varName": "postalCode3",
        "type": "text",
        "label": "Postal Code",
        "maxLength":6,
        "grid": 2,

    },
    {
        "varName": "schemeCodeForCurrent",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Scheme Code *",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "CURRENT",
    },
    {
        "varName": "schemeCodeForSavings",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Scheme Code *",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accType",
        "conditionalVarValue": "SAVINGS",
    },
    {
        "varName": "currency",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Currency",
        "required": true,
        "grid": 2,

    },

    {
        "varName": "rmCode",
        "type": "text",
        "required": true,
        "label": "RM Code",
        "grid": 2,
    },
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Sector Code",
        "grid": 2,
    }, {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Sub Sector Code",
        "grid": 2,
    },
    /*{
        "varName": "occupationCode",
        "type": "text",
        "label": "Occupation Code",
        "grid": 6,

    },*/
    {
        "varName": "ccepCompanyCode",
        "type": "text",
        "label": "CCEP Company Code",
        "grid": 2,

    },
    {
        "varName": "priority",
        "type": "select",
        "label": "Priority",
        "enum": [
            "GENERAL",
            "HIGH",
        ],
        "grid": 2,
    },
    {
        "varName": "statementFacility",
        "type": "select",
        "grid": 2,
        "enum": [
            "Printed Statement",
            "E-Statement",
            "NO",
        ],
        "label": "Statement",

    },
    {
        "varName": "smsAlertRequest",
        "type": "checkbox",
        "label": "SMS Alert Request",
        "grid": 2

    },
    {
        "varName": "callCenterRegistration",
        "type": "checkbox",
        "label": "Call Center Registration",
        "grid": 2

    },


    {
        "varName": "lockerFacility",
        "type": "checkbox",
        "label": "Locker Facility",
        "grid": 2

    },
    {
        "varName": "loanRequest",
        "type": "checkbox",
        "label": "Loan Request",
        "grid": 2
    },


    /*
{
    "varName": "dpsAmount",
    "type": "text",
    "label": "Amount",
    "grid": 2,
    "conditional": true,
    "conditionalVarName": "dpsRequest",
    "conditionalVarValue": true,
},
{
    "varName": "dpsTenor",
    "type": "text",
    "label": "Tenor",
    "grid": 2,
    "conditional": true,
    "conditionalVarName": "dpsRequest",
    "conditionalVarValue": true,
},
{
    "varName": "dpsInterest",
    "type": "text",
    "label": "Interest %",
    "grid": 2,
    "conditional": true,
    "conditionalVarName": "dpsRequest",
    "conditionalVarValue": true,
},
{
    "varName": "dpsSi",
    "type": "text",
    "label": "SI Instruction",

    "grid": 2,
    "conditional": true,
    "conditionalVarName": "dpsRequest",
    "conditionalVarValue": true,
},
{
    "varName": "dpsSchemeCode",
    "type": "text",
    "label": "Scheme Code",
    "grid": 2,
    "conditional": true,
    "conditionalVarName": "dpsRequest",
    "conditionalVarValue": true,
},*/

    {
        "varName": "fdrRequest",
        "type": "checkbox",
        "label": "FDR Request",
        "grid": 12
    },
    {
        "varName": "accountsType",
        "type": "select",
        "grid": 2,
        "enum": [
            "Fix General",
            "Double",
            "Monthly",
        ],
        "label": "Type Of Account",
        "conditional": true,
        "conditionalVarName": "fdrRequest",
        "conditionalVarValue": true,

    },
    {
        "varName": "doubleTennor",
        "type": "select",
        "grid": 2,
        "enum": [
            "Monthly"
        ],
        "label": "Tennor Type",
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Double",
    }, {
        "varName": "tennordDouble",
        "type": "text",
        "label": "Tennor",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Double",
    }, {
        "varName": "doubleAmount",
        "type": "text",
        "label": "Amount",
        "validation": "numeric",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Double",
    }, {
        "varName": "doubleInterest",
        "type": "text",
        "label": "Interest",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Double",
    }, {
        "varName": "doubleSiMaturity",
        "type": "select",
        "grid": 2,
        "enum": [
            "No"
        ],
        "label": "SI Maturity",
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Double",
    },

/////  Account Type Fix General /////
    {
        "varName": "fixGTennor",
        "type": "select",
        "grid": 2,
        "enum": [
            "Day",
            "Monthly"
        ],
        "label": "Tennor Type",
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Fix General",
    }, {
        "varName": "tennorFixG",
        "type": "text",
        "label": "Tennor",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Fix General",
    }, {
        "varName": "fixGAmount",
        "type": "text",
        "label": "Amount",
        "validation": "numeric",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Fix General",
    }, {
        "varName": "fixGInterest",
        "type": "text",
        "label": "Interest",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Fix General",
    }, {
        "varName": "fixGSiMaturity",
        "type": "select",
        "grid": 2,
        "enum": [
            "Yes",
            "No",
            "Encash at Maturity to my / Our Account No",
        ],
        "label": "SI Maturity",
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Fix General",
    }, {
        "varName": "fixdGMaturityYes",
        "type": "select",
        "label": "Maturity Yes",
        "enum": [
            "Renew Principal Only and Credit Interest to the Account No",
            "Renew Both Principal and Interest"
        ],
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "fixGSiMaturity",
        "conditionalVarValue": "Yes",
    },

///////  Month Tenor /////
    {
        "varName": "monthTennor",
        "type": "select",
        "grid": 2,
        "enum": [
            "Monthly"
        ],
        "label": "Tennor Type",
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Monthly",
    }, {
        "varName": "tennorMonth",
        "type": "text",
        "label": "Tennor",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Monthly",
    }, {
        "varName": "monthAmount",
        "type": "text",
        "label": "Amount",
        "validation": "numeric",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Monthly",
    }, {
        "varName": "monthInterest",
        "type": "text",
        "label": "Interest",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Monthly",
    }, {
        "varName": "monthSiMaturity",
        "type": "select",
        "grid": 2,
        "enum": [
            "Yes",
        ],
        "label": "SI Maturity",
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Monthly",
    }, {
        "varName": "monthMaturityYes",
        "type": "select",
        "label": "Maturity Yes",
        "enum": [
            "Renew Principal Only and Credit Interest to the Account No"
            // "Renew Principal Only "
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountsType",
        "conditionalVarValue": "Monthly",
    },
    {
        "varName": "cityTouchRequest",
        "type": "checkbox",
        "label": "City Touch",
        "grid": 12

    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "cityTouchRequest",
        "conditionalVarValue": true,

    },


    //cheque book
    {
        "varName": "chequeBookRequest",
        "type": "checkbox",
        "label": "Cheque Book Request",
        "grid": 12,

    },
    {
        "varName": "pageOfChequeBook",
        "type": "select",
        "enum": [
            "25",
            "50",
            "100",
        ],
        "label": "Page Of Cheque Book",
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
        "grid": 2

    },
    {
        "varName": "numberOfChequeBookRequest",
        "type": "select",
        "enum": [
            "1",
            "2",
            "3",
            "4",
            "5",
        ],
        "label": "Number Of Cheque Book",
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
        "grid": 2,


    },
    {
        "varName": "chequeBookDeliveryType",
        "type": "select",
        "label": "Delivery Type",
        "enum": [
            //,
            "Branch"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,

    },
    {
        "varName": "chequeBookBranch",
        "type": "select",
        "label": "Branch Name",
        "enum": [
            "GULSHAN 1",
            "MOTHIJHEEL 1",
            "DHANMONDI",
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "chequeBookDeliveryType",
        "conditionalVarValue": "Branch",

    },
    {
        "varName": "chequeBookDesign",
        "type": "select",
        "label": "Cheque Book Design",
        "enum": [
            "Sapphire",
            "Citygem",
            "City Alo",
            "Other"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,


    },
    {
        "varName": "chequeBookDesignOther",
        "type": "text",
        "label": "Other",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": "Other",


    },
//Debit Card
    {
        "varName": "debitCard",
        "type": "checkbox",
        "label": "Debit Card",
        "grid": 12
    },
    {
        "varName": "customerName",
        "type": "text",
        "label": "Name On Card",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,

    },

    {
        "varName": "cardType",
        "type": "select",
        "label": "Card Type",
        "grid": 2,
        "enum": [
            "VISA",
            "MASTER",
            "CITYMAXX",
        ],
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "debitCardDeliveryType",
        "type": "select",
        "label": "Delivery Type",
        "enum": [
            //,
            "Branch"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,

    },

    {
        "varName": "debitRequestkBranch",
        "type": "select",
        "label": "Branch Name",
        "enum": [
            "GULSHAN 1",
            "MOTHIJHEEL 1",
            "DHANMONDI",
        ],
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "debitCardDeliveryType",
        "conditionalVarValue": "Branch",

    },

];

let AbhApprovalJsonFormForAgentIndividual = makeReadOnlyObject(JSON.parse(JSON.stringify(AbhMakerJsonFormForAgentIndividual)));
let AbhApprovalJsonFormForAgentNonIndividual = makeReadOnlyObject(JSON.parse(JSON.stringify(AbhMakerJsonFormForAgentNonIndividual)));

let AbhCheckerJsonFormForAgentIndividual = makeReadOnlyObject(JSON.parse(JSON.stringify(makeGridObject(AbhMakerJsonFormForAgentIndividual))));
let AbhCheckerJsonFormForAgentNonIndividual = makeReadOnlyObject(JSON.parse(JSON.stringify(makeGridObject(AbhMakerJsonFormForAgentNonIndividual))));
//ADc
// const AdcJsonFormForCS = [
//     {
//         "varName": "accountNumber",
//         "type": "textApiCall",
//         "label": "Account Number",
//         "grid": 2,
//     },
//     {
//         "varName": "customerName",
//         "type": "text",
//         "required": true,
//         "readOnly":true,
//         "label": "Customer Name",
//         "grid": 2,
//     },


//     {
//         "varName": "mobileNumber",
//         "type": "text",
//         "required": true,
//         "readOnly":true,
//         "label": "Mobile Number",
//         "grid": 2,
//     },
//     // {
//     //     "varName": "smsAlertRequest",
//     //     "type": "checkbox",
//     //     "label": "Sms Alert Request",
//     // },
//     // {
//     //     "varName": "smsAlertEmailAddress",
//     //     "type": "text",
//     //     "required": true,
//     //     "conditional": true,
//     //     "conditionalVarName": "smsAlertRequest",
//     //     "conditionalVarValue": true,
//     //     "label": "Email Address",
//     //     "grid": 2,
//     // },

//     // {
//     //     "varName": "cityTouchRequest",
//     //     "type": "checkbox",
//     //     "label": "City Toych Request",
//     //     "grid": 2,
//     // },

//     {
//         "varName": "email",
//         "type": "text",
//         "required": true,
//         "conditional": true,
//         "conditionalVarName": "cityTouchRequest",
//         "conditionalVarValue": true,
//         "label": "Email Address",
//         "grid": 2,
//     },

//     /*{
//         "varName": "debitCard",
//         "type": "text",
//         "required":true,
//         "conditional": true,
//         "conditionalVarName": "cityTouchRequest",
//         "conditionalVarValue": true,
//         "label": "Debit Card",
//         "grid": 2,
//     },*/
//     // {
//     //     "varName": "cityTouchUserId",
//     //     "type": "text",
//     //     "required": true,
//     //     "conditional": true,
//     //     "conditionalVarName": "cityTouchRequest",
//     //     "conditionalVarValue": true,
//     //     "label": "CityTouch UserID",
//     //     "grid": 2,
//     // },


// ];
const AdcJsonFormForCS=[
    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "required":true,
        "label": "Account Number",
        "grid": 2,
    },
    {
        "varName": "customerName",
        "type": "text",
        "required":true,
        "readOnly":true,
        "label": "Customer Name",
        "conditional": true,
        "conditionalVarName": "accountNumberShow",
        "conditionalVarValue": true,
        "grid": 2,
    },


    {
        "varName": "mobileNumber",
        "type": "text",
        "required":true,
        "readOnly":true,
        "label": "Mobile Number",
        "conditional": true,
        "conditionalVarName": "accountNumberShow",
        "conditionalVarValue": true,
        "grid": 2,
    },

];
const AdcJsonFormForVerify =[
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 2,
        "readOnly":true,
    },
    {
        "varName": "customerName",
        "type": "text",
        "required": true,
        "label": "Customer Name",
        "grid": 2,
        "readOnly":true,
    },


    {
        "varName": "mobileNumber",
        "type": "text",
        "required": true,
        "label": "Mobile Number",
        "grid": 2,
        "readOnly":true,
    },
    /*  {
          "varName": "smsAlertRequestsmsAlertRequestsmsAlertRequest",
          "type": "checkbox",
          "label": "Sms Alert Request",
          "grid": 12,
          "readOnly":true,
      },*/
    /* {
         "varName": "cityTouchRequest",
         "type": "checkbox",
         "label": "City Toych Request",
         "grid": 12,
         "readOnly":true,
     },*/

    {
        "varName": "email",
        "type": "text",
        "required": true,
        "conditional": true,
        "conditionalVarName": "cityTouchRequest",
        "conditionalVarValue": true,
        "label": "Email",
        "grid": 2,
        "readOnly":true,
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "required": true,
        "conditional": true,
        "conditionalVarName": "cityTouchRequest",
        "conditionalVarValue": true,
        "label": "CB Number",
        "grid": 2,
        "readOnly":true,
    },
    {
        "varName": "rmCode",
        "type": "text",
        "required": true,
        "conditional": true,
        "conditionalVarName": "cityTouchRequest",
        "conditionalVarValue": true,
        "label": "RM Code",
        "grid": 2,
        "readOnly":true,
    },

    /* {
         "varName": "debitCard",
         "type": "text",
         "required":true,
         "conditional": true,
         "conditionalVarName": "cityTouchRequest",
         "conditionalVarValue": true,
         "label": "Debit Card",
         "grid": 2,
     },*/
    /*  {
          "varName": "cityTouchUserId",
          "type": "text",
          //"required": true,
          "conditional": true,
          "conditionalVarName": "cityTouchRequest",
          "conditionalVarValue": true,
          "label": "CityTouch UserID",
          "grid": 2,
      },*/


];
const smsRequestJsonFormForVerify =[
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 2,
        "readOnly":true,
    },
    {
        "varName": "customerName",
        "type": "text",
        "required": true,
        "label": "Customer Name",
        "grid": 2,
        "readOnly":true,
    },


    {
        "varName": "mobileNumber",
        "type": "text",
        "required": true,
        "label": "Mobile Number",
        "grid": 2,
        "readOnly":true,
    },
    {
        "varName": "smsAlertRequestsmsAlertRequestsmsAlertRequest",
        "type": "checkbox",
        "label": "Sms Alert Request",
        "grid": 12,
        "readOnly":true,
    },
    /* {
         "varName": "cityTouchRequest",
         "type": "checkbox",
         "label": "City Toych Request",
         "grid": 12,
         "readOnly":true,
     },

     {
         "varName": "email",
         "type": "text",
         "required": true,
         "conditional": true,
         "conditionalVarName": "cityTouchRequest",
         "conditionalVarValue": true,
         "label": "Email",
         "grid": 2,
         "readOnly":true,
     },

     /!* {
          "varName": "debitCard",
          "type": "text",
          "required":true,
          "conditional": true,
          "conditionalVarName": "cityTouchRequest",
          "conditionalVarValue": true,
          "label": "Debit Card",
          "grid": 2,
      },*!/
     {
         "varName": "cityTouchUserId",
         "type": "text",
         //"required": true,
         "conditional": true,
         "conditionalVarName": "cityTouchRequest",
         "conditionalVarValue": true,
         "label": "CityTouch UserID",
         "grid": 2,
     },
 */

];

//#####################BOND#####################################################

//###Weg Development Bond#####################
const commonBond = [
    ////

    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "required": true,
        "validation": "numeric",
        "label": "Account Number",
        "grid": 2,
    },
    {
        "varName": "amount",
        "label" : "Amount",
        "type": "textApiCall",
        "validation": "numeric",
        "conditionalVarName": "accountNumber",
        "conditionalVarValue": true,
        "grid": 2,
        "multiline": true,
        "required": true
    },
    {
        "label": "Amount in Word",
        "type": "text",
        "varName": "amountInWord",
        "grid": 2,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "showAmountConvertField",
        "conditionalVarValue": true,
        "readOnly": true,
    },
    {
        "varName": "customerName",
        "type": "text",
        "readOnly": true,
        // "required": true,
        "multiline": true,
        "label": "Name",
        "grid": 2,
    },
    {
        "varName": "urgencyValue",
        "type": "select",
        "required": true,
        "enum": [
            "Urgent",
            "Regular"
        ],
        "label": "Urgency",
        "grid": 2,
    },
    /* {
         "varName": "customerName",
         "multiline": true,
         "type": "text",
         "readOnly": true,
         "label": "Customer Name",
         "grid": 2
     }, {
         "varName": "fatherName",
         "type": "text", "multiline": true,
         "label": "Father Name",
         "readOnly": true,
         "grid": 2
     },
     {
         "varName": "motherName",
         "type": "text", "multiline": true,
         "label": "Mother Name",
         "readOnly": true,
         "grid": 2
     },
     {
         "varName": "mobileNo",
         "type": "text",
         "readOnly": true,
         "label": "Mobile No",
         "grid": 2
     },
     {
         "varName": "email",
         "multiline": true,
         "type": "text",
         "readOnly": true,
         "label": "Email",
         "grid": 2
     },
     {
         "varName": "dateOfBirth",
         "type": "date",
         "maxDate": true,
         "label": "Date of Birth",
         "grid": 2
     },
     {
         "varName": "nid",
         "type": "text",
         "label": "NID",
         "grid": 2
     },
     {
         "varName": "etin",
         "type": "text",
         "label": "E-Tin",
         "grid": 2
     },
     {
         "varName": "nomineeId",
         "type": "text",
         "required": true,
         "errorMessage": "Error",
         "label": "Nominee NID",
         "grid": 2
     },
     {
         "varName": "nomineDateOfBirth",
         "type": "date",
         "maxDate": true,
         "label": "Nominee Date Of Birth",
         "grid": 2
     },
     {
         "varName": "amount",
         "type": "text",
         "required": true,
         "errorMessage": "Error",
         "label": "Amount",
         "grid": 2
     },*/
    /*{
        "varName": "inputDebitNo",
        "type": "text",
        "label": "DEBIT AC NO",
        "grid": 2
    },*/
    /*
   {
       "varName": "inputDebitAmount",
       "type": "text",
       "label": "INPUT DEBIT AMOUNT",
       "grid": 2

   },


   {
       "varName": "permanentAddress",
       "type": "text",
       "label": "Permanent Address",
       "grid": 2
   },
   {
       "varName": "presentAddress",
       "type": "text",
       "label": "Present Address",
       "grid": 2
   },
   {
       "varName": "nationalId",
       "type": "text",
       "label": "National ID",
       "grid": 2
   },

   {
       "varName": "gender",
       "type": "select",
       "label": "Gender",
       "enum": [
           "M",
           "F","TRANSGENDER",
           "OTHER"
       ],
       "grid": 2
   },*/

];
let CSJsonFormForBondWegDevelopmentBond = commonBond;
let CSJsonFormForBondDib = commonBond;
let CSJsonFormForBondDpb = commonBond;

const commonBondSD = [
    ////
    {
        "varName": "selectTypeOfRequest",
        "type": "text",
        "readOnly": true,
        "enum": [
            "BOND",
            "SANCHAYPATRA"
        ],
        "label": "SELECT TYPE OF REQUEST",
        "grid": 6,
    },
    {
        "varName": "selectTypeOfProject",
        "type": "text",
        "readOnly": true,
        "enum": [
            "Wage Earners Development Bond",
            "U.S. Dollar Investment Bond",
            "U.S. Dollar Premium Bond"
        ],
        "label": "SELECT TYPE OF PROJECT",
        "grid": 6,
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "readOnly": true,
        "label": "Customer ID",
        "grid": 6
    },
    {
        "varName": "customerName",
        "type": "text",
        "readOnly": true,
        "label": "Customer Name",
        "grid": 6
    },
    {
        "varName": "dateOfBirth",
        "type": "date",
        "maxDate": true,
        "readOnly": true,
        "label": "Date of Birth",
        "grid": 6
    },
    {
        "varName": "permanentAddress",
        "type": "text",
        "readOnly": true,
        "label": "Permanent Address",
        "grid": 6
    },
    {
        "varName": "presentAddress",
        "type": "text",
        "readOnly": true,
        "label": "Present Address",
        "grid": 6
    },
    {
        "varName": "nid",
        "type": "text",
        "readOnly": true,
        "label": "National ID",
        "grid": 6
    },
    {
        "varName": "fatherName",
        "type": "text",
        "readOnly": true,
        "label": "Father Name",
        "grid": 6
    },
    {
        "varName": "motherName",
        "type": "text",
        "readOnly": true,
        "label": "Mother Name",
        "grid": 6
    },
    {
        "varName": "gender",
        "type": "select",
        "label": "Gender",
        "enum": [
            "M",
            "F", "TRANSGENDER",
            "OTHER"
        ],
        "grid": 6
    },
    {
        "varName": "mobileNo",
        "type": "text",
        "label": "Mobile No",
        "grid": 6
    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "grid": 6
    },
    {
        "varName": "foreignAddress",
        "type": "text",
        "label": "Foreign Address",
        "grid": 6
    },
    {
        "varName": "fcBankName",
        "type": "text",
        "label": "FCBank Name",
        "grid": 6
    },
    {
        "varName": "fcBankAccountNumber",
        "type": "text",
        "label": "FCBank Account Number",
        "grid": 6
    },
    {
        "varName": "foreignCompanyName",
        "type": "text",
        "label": "Foreign Company Name",
        "grid": 6
    },
    {
        "varName": "foreignCompanyAddress",
        "type": "text",
        "label": "Foreign Company Address",
        "grid": 6
    },
    {
        "varName": "passportNumber",
        "type": "text",
        "label": "Passport Number",
        "grid": 6
    },
    {
        "varName": "passportIssueDate",
        "type": "date",
        "maxDate": true,
        "label": "Passport Issue Date",
        "grid": 6
    },
    {
        "varName": "passportIssueFrom",
        "type": "date",
        "maxDate": true,
        "label": "Passport Issue From",
        "grid": 6
    },
    {
        "varName": "nomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 6

    },
    {
        "varName": "nomineeRelation",
        "type": "text",
        "label": "Relation",
        "grid": 6,

    },
    {
        "varName": "nomineePermanentAddress",
        "type": "text",
        "label": "Permanent Address",
        "grid": 6,

    },
    {
        "varName": "nomineePresentAddress",
        "type": "text",
        "label": "Present Address",
        "grid": 6,

    },
    {
        "varName": "nomineeAmount",
        "type": "text",
        "label": "Nominee Amount",
        "grid": 6,

    },

    /* //other bond and scheme
     {
         "varName": "acNo",
         "type": "text",
         "label": "AC No",
         "grid": 6,

     },

     {
         "varName": "accCurrency",
         "type": "text",
         "label": "Acc Currency",
         "grid": 6,

     },
     {
         "varName": "rate",
         "type": "text",
         "label": "Rate",
         "grid": 6,

     },
     {
         "varName": "faceValue",
         "type": "text",
         "label": "Face Value",
         "grid": 6,

     },
     {
         "varName": "solId",
         "type": "text",
         "label": "SOL ID",
         "grid": 6,

     },*/

];
let MAKERJsonFormForBondWegDevelopmentBond = commonBondSD;
//Issuance Sanchaypatra Start SD Part
let MakerJsonFormForIssuanceSanchaypatra = [
    {
        "varName": "selectTypeOfRequest",
        "type": "text",
        "readOnly": true,
        "label": "SELECT TYPE OF REQUEST",
        "grid": 6,
    },
    {
        "varName": "selectTypeOfProject",
        "type": "text",
        "readOnly": true,
        "label": "SELECT TYPE OF PROJECT",
        "grid": 6,
    },
    {
        "varName": "urgencyValue",
        "type": "text",
        "readOnly": true,
        "label": "Urgency",
        "grid": 6,
    },
    {
        "varName": "nationalId",
        "type": "text",
        //"required": true,
        "validation": "numeric",
        "label": "Customer NID",
        "grid": 6,
    },
    {
        "varName": "amount",
        "label" : "Amount",
        "type": "textApiCall",
        "validation": "numeric",
        "grid": 6,
        "multiline": true,
        "required": true
    },
     {
        "label": "Amount in Word",
        "type": "text",
        "varName": "amountInWord",
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "showAmountConvertField",
        "conditionalVarValue": true,
        "readOnly": true,
    },
    {
        "varName": "dateOfBirth",
        "type": "date",
        //"required": true,
        "maxDate": true,
        "label": "Customer DOB",
        "grid": 6,
    },
    {
        "varName": "etin",
        "type": "text",
        //"required": true,
        "validation": "etin",
        "label": "ETIN",
        "grid": 6,
    },
    {
        "varName": "accountTitleInEnglish",
        "type": "text",
        "validation": "string",
        //"required": true,
        "label": "Account Title In English",
        "grid": 6,
    },
    {
        "varName": "source",
        "type": "text",
        "readOnly": true,
        "label": "Account Source",
        "grid": 6,
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "validation": "numeric",
        //"required": true,
        "label": "Account Number",
        "grid": 6,
    },
    {
        "varName": "typeOfBankAccount",
        "type": "text",
        //"required": true,
        "label": "Type Of Bank Account",
        "grid": 6,
    },
    {
        "varName": "bankName",
        "type": "text",
        "validation": "string",
        "readOnly": true,
        "label": "Bank Name",
        "grid": 6,
    },
    {
        "varName": "branchName",
        "type": "select",
        "onKeyDown": true,
        // "required": true,
        "enum": [],
        "label": "Branch Name",
        "grid": 6,
    },
    {
        "varName": "routingNumber",
        "type": "text",
        "validation": "numeric",
        //"required": true,
        "label": "Routing Number",
        "grid": 6,
    },
    {
        "varName": "ifCustomerDisability",
        "type": "text",
        "validation": "string",
        //"required": true,
        "label": "If Customer Disability",
        "grid": 6,
    },
    {
        "varName": "mobileNumber",
        "type": "text",
        "validation": "phone",
        //"required": true,
        "label": "Mobile Number",
        "grid": 6,
    },
    {
        "varName": "email",
        "type": "text",
        "validation": "email",
        "multiline": true,
        "label": "Email ID",
        "grid": 6,
    },
    {
        "varName": "placeOfCustomer",
        "type": "text",
        "validation": "string",
        //"required": true,
        "label": "Place Of Customer",
        "grid": 6,
    },
    {
        "varName": "sex",
        "type": "text",
        //"required": true,
        "label": "Sex",
        "grid": 6,
    },
    {
        "varName": "typeOfOwnership",
        "type": "text",
        "validation": "string",
        //"required": true,
        "label": "Type Of Ownership",
        "grid": 6,
    },
    {
        "varName": "typeOfNominee",
        "type": "text",
        "validation": "string",
        //"required": true,
        "label": "Type Of Nominee",
        "grid": 6,
    },
    {
        "varName": "nomineeNid",
        "type": "text",
        "validation": "numeric",
        //"required": true,
        "label": "Nominee NID",
        "grid": 6,
    },
    {
        "varName": "nomineeDob",
        "type": "date",
        "maxDate": true,
        // "required": true,
        "label": "Nominee DOB",
        "grid": 6,
    },
    {
        "varName": "nomineePercentage",
        "type": "text",
        "validation": "numeric",
        //"required": true,
        "label": "Nominee Percentage",
        "grid": 6,
    },
    {
        "varName": "nomineeMobile",
        "type": "text",
        "validation": "phone",
        //"required": true,
        "label": "Nominee Mobile",
        "grid": 6,
    },
    {
        "varName": "relationship",
        "type": "text",
        "validation": "string",
        //"required": true,
        "label": "Relationship",
        "grid": 6,
    },
    {
        "varName": "registrationNumber",
        "type": "text",
        //"required": true,
        "label": "Registration Number",
        "grid": 6,
    },
];
let CheckerJsonFormForIssuanceSanchaypatra = makeReadOnlyObject(JSON.parse(JSON.stringify(makeGridObject(MakerJsonFormForIssuanceSanchaypatra))));
//
//Issuance Bond Start SD Part
let MakerJsonFormForIssuanceBond = [
    {
        "varName": "selectTypeOfRequest",
        "type": "text",
        "readOnly": true,
        "label": "SELECT TYPE OF REQUEST",
        "grid": 6,
    },
    {
        "varName": "selectTypeOfProject",
        "type": "text",
        "readOnly": true,
        "label": "SELECT TYPE OF PROJECT",
        "grid": 6,
    },
    {
        "varName": "urgencyValue",
        "type": "text",
        "readOnly": true,
        "label": "Urgency",
        "grid": 6,
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "readOnly": true,
        "label": "Customer ID/CB Number",
        "grid": 6,
    },
    {
        "varName": "source",
        "type": "text",
        "readOnly": true,
        "label": "Account Source",
        "grid": 6,
    },

    {
        "varName": "accountNumber",
        "type": "text",
        "readOnly": true,
        "validation": "numeric",
        "label": "Customer Account Number",
        "grid": 6,
    },

    /*  {
          "varName": "customerNid",
          "type": "text",
          //"required": true,
          "validation":"nid",
          "label": "Customer Nid",
          "grid": 6,
      },

      {
          "varName": "customerDob",
          "type": "date",
          //"required": true,
          "maxDate":true,
          "label": "Customer Dob",
          "grid": 6,
      },
      {
          "varName": "amount",
          "type": "text",
          //"required": true,
          "label": "Amount",
          "grid": 6,
      },
      {
          "varName": "etin",
          "type": "text",
          //"required": true,
          "validation":"etin",
          "label": "Etin",
          "grid": 6,
      },
      {
          "varName": "accountTitleInEnglish",
          "type": "text",
          //"required": true,
          "label": "Account Title In English",
          "grid": 6,
      },
      {
          "varName": "accountNumber",
          "type": "text",
          "validation":"numeric",
          //"required": true,
          "label": "Account Number",
          "grid": 6,
      },
      {
          "varName": "typeOfBankAccount",
          "type": "text",
          //"required": true,
          "label": "Type Of Bank Account",
          "grid": 6,
      },*/
    {
        "varName": "bankName",
        "type": "text",
        "readOnly": true,
        "label": "Bank Name",
        "grid": 6,
    },
    {
        "varName": "branchName",
        "type": "select",
        //"required": true,
        "label": "Branch Name",
        "enum": [],
        "grid": 6,
    },
    {
        "varName": "amount",
        "label" : "Amount",
        "type": "textApiCall",
        "validation": "numeric",
        "grid": 6,
        "multiline": true,
        "required": true
    },
    {
        "label": "Amount in Word",
        "type": "text",
        "varName": "amountInWord",
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "showAmountConvertField",
        "conditionalVarValue": true,
        "readOnly": true,
    },
    {
        "varName": "currency",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //"required": true,
        "label": "Currency",
        "grid": 6,
    },
    {
        "varName": "customerName",
        "type": "text",
        "multiline": true,
        "label": "Customer Name",
        "grid": 6,
    },
    {
        "varName": "dateOfBirth",
        "type": "date",
        "maxDate": true,
        //"required": true,
        "label": "Date Of Birth",
        "grid": 6,
    },
    {
        "varName": "permanentAddress",
        "type": "text",
        //"required": true,
        "label": "Permanent Address",
        "grid": 6,
    },
    {
        "varName": "presentAddress",
        "type": "text",
        //"required": true,
        "label": "Present Address",
        "grid": 6,
    },
    {
        "varName": "foreignAddress",
        "type": "text",
        //"required": true,
        "label": "Foreign Address",
        "grid": 6,
    },
    {
        "varName": "foreignCompanyName",
        "type": "text",
        //"required": true,
        "label": "Foreign Company Name",
        "grid": 6,
    },
    {
        "varName": "foreignCompanyAddress",
        "type": "text",
        //"required": true,
        "label": "Foreign Company Address",
        "grid": 6,
    },
    {
        "varName": "passportNumber",
        "type": "text",
        "validation": "passport",
        //"required": true,
        "label": "Passport Number",
        "grid": 6,
    },
    {
        "varName": "passportIssuanceDate",
        "type": "date",
        "maxDate": true,
        //"required": true,
        "label": "Passport Issuance Date",
        "grid": 6,
    },
    {
        "varName": "passportIssuanceForm",
        "type": "text",
        "validation": "string",
        //"required": true,
        "label": "Passport Issuance Form",
        "grid": 6,
    },
    {
        "varName": "nationalId",
        "type": "text",
        "validation": "numeric",
        //"required": true,
        "label": "National ID",
        "grid": 6,
    },
    {
        "varName": "nomineeName",
        "type": "text",
        "validation": "string",
        //"required": true,
        "label": "Nominee Name",
        "grid": 6,
    },
    {
        "varName": "nomineeRelationWithApplicant",
        "type": "text",
        "validation": "string",
        //"required": true,
        "label": "Nominee Relation With Applicant",
        "grid": 6,
    },
    {
        "varName": "nomineeAmount",
        "type": "text",
        "validation": "numeric",
        //"required": true,
        "label": "Nominee Amount",
        "grid": 6,
    },
    {
        "varName": "registrationNumber",
        "type": "text",
        //"required": true,
        "label": "Registration Number",
        "grid": 6,
    },

];
let CheckerJsonFormForIssuanceBond = makeReadOnlyObject(JSON.parse(JSON.stringify(makeGridObject(MakerJsonFormForIssuanceBond))));

//EncashMent Bond Start SD Part
let MakerJsonFormForEncashmentBond = [
    {
        "varName": "selectTypeOfRequest",
        "type": "text",
        "readOnly": true,
        "label": "SELECT TYPE OF REQUEST",
        "grid": 6,
    },
    {
        "varName": "selectTypeOfProject",
        "type": "text",
        "readOnly": true,
        "label": "SELECT TYPE OF PROJECT",
        "grid": 6,
    },
    {
        "varName": "typeOfPayment",
        "type": "select",
        "enum": [
            "ENCASHMENT PAYMENT",
            "INTEREST PAYMENT",
        ],
        "conditionalOrValue": true,
        "conditionalOrList": ["U.S. Dollar Investment Bond", "U.S. Dollar Premium Bond", "Wage Earners Development Bond", "5 Years Bangladesh Sanchaya Patra", "3 Months Interest Based Sanchaya Patra", "Family Sanchaya Patra", "Pensioners Savings Certificate"],
        "conditionalVarName": "selectTypeOfProject",
        "label": "SELECT TYPE OF PAYMENT",
        "grid": 6,
    },
    {
        "varName": "registrationNumber",
        "type": "text",
        "required": true,
        "errorMessage": "Error",
        "label": "Registration NO.",
        "grid": 6
    },
    /*
    {
        "varName": "nid",
        "type": "text",
        "label": "National ID",
        "grid": 2
    },
    {
        "varName": "dateOfBirth",
        "type": "date",
        "maxDate": true,
        "label": "Date Of  Birth",
        "grid": 2
    },*/
    {
        "varName": "source",
        "type": "text",
        "readOnly": true,
        "label": "Account Source",
        "grid": 6,
    },
    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "required": true,
        "validation": "numeric",
        "label": "Account Number",
        "grid": 6
    },
    {
        "varName": "customerName",
        "type": "text",
        "readOnly": true,
        "label": "Customer Name",
        "grid": 6
    },
    {
        "varName": "numberOfCoupon",
        "type": "text",
        "required": true,
        "validation": "numeric",
        "label": "Number Of Coupon",
        "grid": 6,

    },
    {
        "varName": "urgencyValue",
        "type": "text",
        "readOnly": true,
        "label": "Urgency",
        "grid": 6,
    },
    /* {
         "varName": "issueDate",
         "type": "date",
         "maxDate": true,
         "label": "Issue Date",
         "grid": 2,

     },
     {
         "varName": "scriptNumber",
         "type": "text",
         "readOnly": true,
         "label": "Script Serial",
         "grid": 2,

     },
     {
         "varName": "netPayment",
         "type": "text",
         "readOnly": true,
         "label": "Net Payment",
         "grid": 2,

     },
 */
];
let CheckerJsonFormForEncashmentBond = makeReadOnlyObject(JSON.parse(JSON.stringify(MakerJsonFormForEncashmentBond)));
//
//EncashMent Sanchaypatra Start SD Part
let MakerJsonFormForEncashmentSanchaypatra = [
    {
        "varName": "selectTypeOfRequest",
        "type": "text",
        "readOnly": true,
        "label": "SELECT TYPE OF REQUEST",
        "grid": 6,
    },
    {
        "varName": "selectTypeOfProject",
        "type": "text",
        "readOnly": true,
        "label": "SELECT TYPE OF PROJECT",
        "grid": 6,
    },
    {
        "varName": "typeOfPayment",
        "type": "select",
        "enum": [
            "ENCASHMENT PAYMENT",
            "INTEREST PAYMENT",
        ],
        // "conditional": true,
        "conditionalOrValue": true,
        "conditionalVarName": "selectTypeOfProject",
        "conditionalOrList": ["U.S. Dollar Investment Bond", "U.S. Dollar Premium Bond", "Wage Earners Development Bond", "5 Years Bangladesh Sanchaya Patra", "3 Months Interest Based Sanchaya Patra", "Family Sanchaya Patra", "Pensioners Savings Certificate"],
        "label": "SELECT TYPE OF PAYMENT",
        "grid": 6,
    },
    {
        "varName": "registrationNumber",
        "type": "text",
        "required": true,
        "errorMessage": "Error",
        "label": "Registration NO.",
        "grid": 6
    },
    /*
    {
        "varName": "nid",
        "type": "text",
        "label": "National ID",
        "grid": 2
    },
    {
        "varName": "dateOfBirth",
        "type": "date",
        "maxDate": true,
        "label": "Date Of  Birth",
        "grid": 2
    },*/
    {
        "varName": "source",
        "type": "text",
        "readOnly": true,
        "label": "Account Source",
        "grid": 6,
    },
    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "required": true,
        "validation": "numeric",
        "label": "Account Number",
        "grid": 6
    },

    {
        "varName": "numberOfCoupon",
        "type": "text",
        "required": true,
        "validation": "numeric",
        "label": "Number Of Coupon",
        "grid": 6,

    },
    {
        "varName": "urgencyValue",
        "type": "text",
        "readOnly": true,
        "label": "Urgency",
        "grid": 6,
    },
    /* {
         "varName": "issueDate",
         "type": "date",
         "maxDate": true,
         "label": "Issue Date",
         "grid": 2,

     },
     {
         "varName": "scriptNumber",
         "type": "text",
         "readOnly": true,
         "label": "Script Serial",
         "grid": 2,

     },
     {
         "varName": "netPayment",
         "type": "text",
         "readOnly": true,
         "label": "Net Payment",
         "grid": 2,

     },
 */
];
let CheckerJsonFormForEncashmentSanchaypatra = makeReadOnlyObject(JSON.parse(JSON.stringify(makeGridObject(MakerJsonFormForEncashmentSanchaypatra))));
//
//Aces Enrollment Sanchaypatra Start SD Part
let MakerJsonFormForAcesEnrollmentSanchaypatra = [
    {
        "varName": "selectTypeOfRequest",
        "type": "text",
        "readOnly": true,
        "label": "SELECT TYPE OF REQUEST",
        "grid": 6,
    },
    {
        "varName": "selectTypeOfProject",
        "type": "text",
        "readOnly": true,
        "label": "SELECT TYPE OF PROJECT",
        "grid": 6,
    },
    {
        "varName": "registrationNumber",
        "type": "text",
        "required": true,
        "label": "Registration Number",
        "grid": 6,
    },
    {
        "varName": "source",
        "type": "text",
        "readOnly": true,
        "label": "Account Source",
        "grid": 6,
    },
    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "required": true,
        "validation": "numeric",
        "label": "Account Number",
        "grid": 6,
    },
    {
        "varName": "urgencyValue",
        "type": "text",
        "readOnly": true,
        "label": "Urgency",
        "grid": 6,
    },
    /* {
         "varName": "amount",
         "type": "text",
         "readOnly": true,
         "label": "SP Amount",
         "grid": 2,
     },
     {
         "varName": "customerName",
         "type": "text",
         "multiline": true,
         "readOnly": true,
         "label": "Customer Name",
         "grid": 2,
     },
     {
         "varName": "debitAccountNumber",
         "type": "text",
         "label": "Debit A/C Number",
         "grid": 2,
     }*/

];
let CheckerJsonFormForAcesEnrollmentSanchaypatra = makeReadOnlyObject(JSON.parse(JSON.stringify(makeGridObject(MakerJsonFormForAcesEnrollmentSanchaypatra))));
////Aces Enrollment Bond Start SD Part
let MakerJsonFormForAcesEnrollmentBond = [
    {
        "varName": "selectTypeOfRequest",
        "type": "text",
        "readOnly": true,
        "label": "SELECT TYPE OF REQUEST",
        "grid": 6,
    },
    {
        "varName": "selectTypeOfProject",
        "type": "text",
        "readOnly": true,
        "label": "SELECT TYPE OF PROJECT",
        "grid": 6,
    },
    {
        "varName": "registrationNumber",
        "type": "text",
        "required": true,
        "label": "Registration Number",
        "grid": 6,
    },
    {
        "varName": "source",
        "type": "text",
        "readOnly": true,
        "label": "Account Source",
        "grid": 6,
    },
    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "required": true,
        "validation": "numeric",
        "label": "Account Number",
        "grid": 6,
    },
    {
        "varName": "customerName",
        "type": "text",
        "readOnly": true,
        "label": "Customer Name",
        "grid": 6
    },
    {
        "varName": "urgencyValue",
        "type": "text",
        "readOnly": true,
        "label": "Urgency",
        "grid": 6,
    },
    {
        "varName": "Issue Date",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
    },
    {
        "varName": "couponNumbers",
        "type": "text",
        "label": "Scripts/Coupon Numbers",
        "grid": 6,
    },
    {
        "varName": "couponAmount",
        "type": "text",
        "label": "Scripts/Coupon Amount",
        "grid": 6,
    },
    {
        "varName": "qty",
        "type": "text",
        "label": "Qty",
        "grid": 6,
    },
    {
        "varName": "couponSequence",
        "type": "text",
        "label": "Coupon Sequence",
        "grid": 6,
    },
    /* {
         "varName": "amount",
         "type": "text",
         "readOnly": true,
         "label": "SP Amount",
         "grid": 2,
     },
     {
         "varName": "customerName",
         "type": "text",
         "multiline": true,
         "readOnly": true,
         "label": "Customer Name",
         "grid": 2,
     },
     {
         "varName": "debitAccountNumber",
         "type": "text",
         "label": "Debit A/C Number",
         "grid": 2,
     }*/

];
let CheckerJsonFormForAcesEnrollmentBond = makeReadOnlyObject(JSON.parse(JSON.stringify(makeGridObject(MakerJsonFormForAcesEnrollmentBond))));
//
let CHECKERJsonFormForBondWegDevelopmentBond = makeReadOnlyObject(JSON.parse(JSON.stringify(commonBondSD)));
let MAKERJsonFormForBondDib = commonBondSD;
let CHECKERJsonFormForBondDib = makeReadOnlyObject(JSON.parse(JSON.stringify(commonBondSD)));
let MAKERJsonFormForBondDpb = commonBondSD;
let CHECKERJsonFormForBondDpb = makeReadOnlyObject(JSON.parse(JSON.stringify(commonBondSD)));

//#####################SANCHOYPATRA #####################################################

let CSJsonFormForFsp = commonBond;
let CSJsonFormFor3ms = commonBond;
let CSJsonFormForBsp = commonBond;
let CSJsonFormForPsc = commonBond;
let commonSDSanchoy = [
    {
        "varName": "selectTypeOfRequest",
        "type": "text",
        "label": "SELECT TYPE OF REQUEST",
        "grid": 6,
    },
    {
        "varName": "selectTypeOfProject",
        "type": "select",
        "enum": [
            "5 Years Bangladesh Sanchaya Patra",
            "3 Months Interest Based Sanchaya Patra",
            "Family Sanchaya Patra",
            "Pensioners Savings Certificate",
        ],
        "label": "SELECT TYPE OF PROJECT",
        "grid": 6,
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "Customer ID",
        "grid": 6
    },
    {
        "varName": "branchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 6
    },
    /* {
         "varName": "inputDebitNo",
         "type": "text",
         "label": "INPUT DEBIT AC NO",
         "grid": 6
     },*/
    {
        "varName": "inputDebitAmount",
        "type": "text",
        "label": "INPUT DEBIT AMOUNT",
        "grid": 6

    },
    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name",
        "grid": 6
    },
    {
        "varName": "dateOfBirth",
        "type": "date",
        "maxDate": true,
        "label": "Date of Birth",
        "grid": 6
    },
    {
        "varName": "permanentAddress",
        "type": "text",
        "label": "Permanent Address",
        "grid": 6
    },
    {
        "varName": "presentAddress",
        "type": "text",
        "label": "Present Address",
        "grid": 6
    },
    {
        "varName": "nid",
        "type": "text",
        "label": "National ID",
        "grid": 6
    },
    {
        "varName": "fatherName",
        "type": "text",
        "label": "Father Name",
        "grid": 6
    },
    {
        "varName": "motherName",
        "type": "text",
        "label": "Mother Name",
        "grid": 6
    },
    {
        "varName": "gender",
        "type": "select",
        "label": "Gender",
        "enum": [
            "M",
            "F", "TRANSGENDER",
            "OTHER"
        ],
        "grid": 6
    },
    {
        "varName": "area",
        "type": "text",
        "label": "Area",
        "grid": 6
    },
    {
        "varName": "mobileNo",
        "type": "text",
        "label": "Mobile No",
        "grid": 6
    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "grid": 6
    },
    {
        "varName": "minor",
        "type": "select",
        "label": "Major/Minor",
        "enum": [
            "YES",
            "NO"
        ],
        "grid": 6
    },
    {
        "varName": "guardianName",
        "type": "text",
        "label": "Guardian Name",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "minor",
        "conditionalVarValue": "YES",

    },
    {
        "varName": "relation",
        "type": "text",
        "label": "Relation",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "minor",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "guardianPermanentAddress",
        "type": "text",
        "label": "Permanent Address",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "minor",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "guardianPresentAddress",
        "type": "text",
        "label": "Present Address",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "minor",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "nomineeInformation",
        "type": "title",
        "label": "Nominee Information",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "minor",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "nomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 6

    },
    {
        "varName": "nomineeRelation",
        "type": "text",
        "label": "Relation",
        "grid": 6,

    },
    {
        "varName": "nomineePermanentAddress",
        "type": "text",
        "label": "Permanent Address",
        "grid": 6,

    },
    {
        "varName": "nomineePresentAddress",
        "type": "text",
        "label": "Present Address",
        "grid": 6,

    },
    {
        "varName": "nomineeAmount",
        "type": "text",
        "label": "Nominee Amount",
        "grid": 6,

    },
    /* //other bond and scheme
     {
         "varName": "acNo",
         "type": "text",
         "label": "AC No",
         "grid": 6,

     },

     {
         "varName": "accCurrency",
         "type": "text",
         "label": "Acc Currency",
         "grid": 6,

     },
     {
         "varName": "rate",
         "type": "text",
         "label": "Rate",
         "grid": 6,

     },
     {
         "varName": "faceValue",
         "type": "text",
         "label": "Face Value",
         "grid": 6,

     },
     {
         "varName": "solId",
         "type": "text",
         "label": "SOL ID",
         "grid": 6,

     },*/
];
let commonSDSanchaypatraPsc = [
    {
        "varName": "selectTypeOfRequest",
        "type": "text",
        "label": "SELECT TYPE OF REQUEST",
        "grid": 6,
    },
    {
        "varName": "selectTypeOfProject",
        "type": "text",
        "enum": [
            "5 Years Bangladesh Sanchaya Patra",
            "3 Months Interest Based Sanchaya Patra",
            "Family Sanchaya Patra",
        ],
        "label": "SELECT TYPE OF PROJECT",
        "grid": 6,
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "Customer ID",
        "grid": 6
    },
    {
        "varName": "branchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 6
    },

    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name",
        "grid": 6
    },
    {
        "varName": "dateOfBirth",
        "type": "date",
        "maxDate": true,
        "label": "Date of Birth",
        "grid": 6
    },
    {
        "varName": "permanentAddress",
        "type": "text",
        "label": "Permanent Address",
        "grid": 6
    },
    {
        "varName": "presentAddress",
        "type": "text",
        "label": "Present Address",
        "grid": 6
    },
    {
        "varName": "nid",
        "type": "text",
        "label": "National ID",
        "grid": 6
    },
    {
        "varName": "fatherName",
        "type": "text",
        "label": "Father Name",
        "grid": 6
    },
    {
        "varName": "motherName",
        "type": "text",
        "label": "Mother Name",
        "grid": 6
    },
    {
        "varName": "gender",
        "type": "select",
        "label": "Gender",
        "enum": [
            "M",
            "F", "TRANSGENDER",
            "OTHER"
        ],
        "grid": 6
    },
    {
        "varName": "mobileNo",
        "type": "text",
        "label": "Mobile No",
        "grid": 6
    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "grid": 6
    },
    {
        "varName": "pensionerRepresentativeName",
        "type": "text",
        "multiline": true,
        "label": "Pensioner Representative Name",
        "grid": 6
    },
    {
        "varName": "pensionerRepresentativeName",
        "type": "date",
        "maxDate": true,
        "label": "Pensioner Representative DOB",
        "grid": 6
    },
    {
        "varName": "designation",
        "type": "text",
        "multiline": true,
        "label": "Designation",
        "grid": 6
    },
    {
        "varName": "officeName",
        "type": "text",
        "multiline": true,
        "label": "Office Name",
        "grid": 6
    },
    {
        "varName": "officeAddress",
        "type": "text",
        "multiline": true,
        "label": "Office Address",
        "grid": 6
    },
    {
        "varName": "joiningDate",
        "type": "date",
        "maxDate": true,
        "label": "Joining Date",
        "grid": 6
    },
    {
        "varName": "retirementgDate",
        "type": "date",
        "maxDate": true,
        "label": "Retirement Date",
        "grid": 6
    },
    {
        "varName": "pensionReceiveDateDate",
        "type": "date",
        "maxDate": true,
        "label": "Pension Receive Date",
        "grid": 6
    },
    {
        "varName": "gratuity",
        "type": "text",
        "label": "Gratuity",
        "grid": 6
    },
    {
        "varName": "providentFund",
        "type": "text",
        "label": "Provident Fund",
        "grid": 6
    },
    {
        "varName": "providentFundSource",
        "type": "text",
        "label": "Provident Fund Source",
        "grid": 6
    },

    {
        "varName": "nomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 6

    },
    {
        "varName": "nomineeRelation",
        "type": "text",
        "label": "Relation",
        "grid": 6,

    },
    {
        "varName": "nomineePermanentAddress",
        "type": "text",
        "label": "Permanent Address",
        "grid": 6,

    },
    {
        "varName": "nomineePresentAddress",
        "type": "text",
        "label": "Present Address",
        "grid": 6,

    },
    {
        "varName": "nomineeAmount",
        "type": "text",
        "label": "Nominee Amount",
        "grid": 6,

    },

];

let MAKERJsonFormForBsp = commonSDSanchoy;
let CHECKERJsonFormForBsp = makeReadOnlyObject(JSON.parse(JSON.stringify(commonSDSanchoy)));

let MAKERJsonFormFor3ms = commonSDSanchoy;
let CHECKERJsonFormFor3ms = makeReadOnlyObject(JSON.parse(JSON.stringify(commonSDSanchoy)));
let MAKERJsonFormForFsp = commonSDSanchoy;
let CHECKERJsonFormForFsp = makeReadOnlyObject(JSON.parse(JSON.stringify(commonSDSanchoy)));
let MAKERJsonFormForPsc = commonSDSanchaypatraPsc;
let CHECKERJsonFormForPsc = makeReadOnlyObject(JSON.parse(JSON.stringify(commonSDSanchaypatraPsc)));
//Encashment
let commonSDPsc = [
    {
        "varName": "selectTypeOfRequest",
        "type": "text",
        "label": "SELECT TYPE OF REQUEST",
        "grid": 6,
    },
    {
        "varName": "selectTypeOfProject",
        "type": "text",
        "enum": [
            "5 Years Bangladesh Sanchaya Patra",
            "3 Months Interest Based Sanchaya Patra",
            "Family Sanchaya Patra",
            "Pensioners Savings Certificate",
        ],
        "label": "SELECT TYPE OF PROJECT",
        "grid": 6,
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "Customer IDs",
        "grid": 6
    },
    {
        "varName": "branchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 6
    },

    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name",
        "grid": 6
    },
    {
        "varName": "dateOfBirth",
        "type": "date",
        "maxDate": true,
        "label": "Date of Birth",
        "grid": 6
    },
    {
        "varName": "permanentAddress",
        "type": "text",
        "label": "Permanent Address",
        "grid": 6
    },
    {
        "varName": "presentAddress",
        "type": "text",
        "label": "Present Address",
        "grid": 6
    },
    {
        "varName": "nid",
        "type": "text",
        "label": "National ID",
        "grid": 6
    },
    {
        "varName": "fatherName",
        "type": "text",
        "label": "Father Name",
        "grid": 6
    },
    {
        "varName": "motherName",
        "type": "text",
        "label": "Mother Name",
        "grid": 6
    },
    {
        "varName": "gender",
        "type": "select",
        "label": "Gender",
        "enum": [
            "M",
            "F", "TRANSGENDER",
            "OTHER"
        ],
        "grid": 6
    },
    {
        "varName": "mobileNo",
        "type": "text",
        "label": "Mobile No",
        "grid": 6
    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "grid": 6
    },
    {
        "varName": "pensionerRepresentativeName",
        "type": "text",
        "multiline": true,
        "label": "Pensioner Representative Name",
        "grid": 6
    },
    {
        "varName": "pensionerRepresentativeName",
        "type": "date",
        "maxDate": true,
        "label": "Pensioner Representative DOB",
        "grid": 6
    },
    {
        "varName": "designation",
        "type": "text",
        "multiline": true,
        "label": "Designation",
        "grid": 6
    },
    {
        "varName": "officeName",
        "type": "text",
        "multiline": true,
        "label": "Office Name",
        "grid": 6
    },
    {
        "varName": "officeAddress",
        "type": "text",
        "multiline": true,
        "label": "Office Address",
        "grid": 6
    },
    {
        "varName": "joiningDate",
        "type": "date",
        "maxDate": true,
        "label": "Joining Date",
        "grid": 6
    },
    {
        "varName": "retirementgDate",
        "type": "date",
        "maxDate": true,
        "label": "Retirement Date",
        "grid": 6
    },
    {
        "varName": "pensionReceiveDateDate",
        "type": "date",
        "maxDate": true,
        "label": "Pension Receive Date",
        "grid": 6
    },
    {
        "varName": "gratuity",
        "type": "text",
        "label": "Gratuity",
        "grid": 6
    },
    {
        "varName": "providentFund",
        "type": "text",
        "label": "Provident Fund",
        "grid": 6
    },
    {
        "varName": "providentFundSource",
        "type": "text",
        "label": "Provident Fund Source",
        "grid": 6
    },

    {
        "varName": "nomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 6

    },
    {
        "varName": "nomineeRelation",
        "type": "text",
        "label": "Relation",
        "grid": 6,

    },
    {
        "varName": "nomineePermanentAddress",
        "type": "text",
        "label": "Permanent Address",
        "grid": 6,

    },
    {
        "varName": "nomineePresentAddress",
        "type": "text",
        "label": "Present Address",
        "grid": 6,

    },
    {
        "varName": "nomineeAmount",
        "type": "text",
        "label": "Nominee Amount",
        "grid": 6,

    },

];
let CSInterestPayment = [
    {
        "varName": "registrationNumber",
        "type": "text",
        "required": true,
        "errorMessage": "Error",
        "label": "Registration NO.",
        "grid": 2
    },
    /*
    {
        "varName": "nid",
        "type": "text",
        "label": "National ID",
        "grid": 2
    },
    {
        "varName": "dateOfBirth",
        "type": "date",
        "maxDate": true,
        "label": "Date Of  Birth",
        "grid": 2
    },*/
    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "required": true,
        "validation": "numeric",
        "label": "Account Number",
        "grid": 2
    },
    {
        "varName": "customerName",
        "type": "text",
        "readOnly": true,
        "label": "Customer Name",
        "grid": 2
    },
    {
        "varName": "numberOfCoupon",
        "type": "text",
        "required": true,
        "validation": "numeric",
        "label": "Number Of Coupon",
        "grid": 2,

    },
    {
        "varName": "urgencyValue",
        "type": "select",
        "required": true,
        "enum": [
            "Urgent",
            "Regular"
        ],
        "label": "Urgency",
        "grid": 2,
    },
    /* {
         "varName": "issueDate",
         "type": "date",
         "maxDate": true,
         "label": "Issue Date",
         "grid": 2,

     },
     {
         "varName": "scriptNumber",
         "type": "text",
         "readOnly": true,
         "label": "Script Serial",
         "grid": 2,

     },
     {
         "varName": "netPayment",
         "type": "text",
         "readOnly": true,
         "label": "Net Payment",
         "grid": 2,

     },
 */
];
let MAKERInterestPaymentSDPSC = commonSDPsc;
let CHECKERInterestPaymentSDPSC = makeReadOnlyObject(JSON.parse(JSON.stringify(commonSDPsc)));
let MAKERInterestInterestPayment = [
    {
        "varName": "selectTypeOfRequest",
        "type": "text",
        "label": "SELECT TYPE OF REQUEST",
        "grid": 6,
    },
    {
        "varName": "selectTypeOfProject",
        "type": "select",
        "enum": [
            "5 Years Bangladesh Sanchaya Patra",
            "3 Months Interest Based Sanchaya Patra",
            "Family Sanchaya Patra",
            "Pensioners Savings Certificate",
            "Wage Earners Development Bond",
            "U.S. Dollar Investment Bond",
            "U.S. Dollar Premium Bond"
        ],
        "label": "SELECT TYPE OF PROJECT",
        "grid": 6,
    },
    {
        "varName": "registrationNumber",
        "type": "text",
        "required": true,
        "label": "Registration Number",
        "grid": 6
    },


];
let CHECKERInterestInterestPayment = makeReadOnlyObject(JSON.parse(JSON.stringify([
    {
        "varName": "selectTypeOfRequest",
        "type": "text",
        "label": "SELECT TYPE OF REQUEST",
        "grid": 6,
    },
    {
        "varName": "selectTypeOfProject",
        "type": "select",
        "enum": [
            "5 Years Bangladesh Sanchaya Patra",
            "3 Months Interest Based Sanchaya Patra",
            "Family Sanchaya Patra",
            "Pensioners Savings Certificate",
            "Wage Earners Development Bond",
            "U.S. Dollar Investment Bond",
            "U.S. Dollar Premium Bond"
        ],
        "label": "SELECT TYPE OF PROJECT",
        "grid": 6,
    },
    {
        "varName": "registrationNumber",
        "type": "text",
        "required": true,
        "errorMessage": "Error",
        "label": "Registration Number",
        "grid": 6
    },
    {
        "varName": "nid",
        "type": "text",
        "label": "National ID",
        "grid": 6
    },
    {
        "varName": "dateOfBirth",
        "type": "date",
        "maxDate": true,
        "label": "Date Of  Birth",
        "grid": 6
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Customer A/C Number",
        "grid": 6
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "Customer CB",
        "grid": 6
    },

    {
        "varName": "amount",
        "type": "text",
        "label": "Amount",
        "grid": 6,

    },
    {
        "varName": "scriptNumber",
        "type": "text",
        "label": "Script Number",
        "grid": 6,

    },
    {
        "varName": "scriptQuantity",
        "type": "text",
        "label": "Script Quantity",
        "grid": 6,

    },
    {
        "varName": "couponInstallments",
        "type": "text",
        "label": "Coupon Installments",
        "grid": 6,

    },
    {
        "varName": "couponQuantity",
        "type": "text",
        "label": "Coupon Quantity",
        "grid": 6,

    },

])));

//ACES ENROLLMENT
let CSJsonFromForAcesEnrollemt = [
    {
        "varName": "registrationNumber",
        "type": "text",
        "required": true,
        "label": "Registration Number",
        "grid": 2,
    },
    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "required": true,
        "validation": "numeric",
        "label": "Account Number",
        "grid": 2,
    },
    {
        "varName": "customerName",
        "type": "text",
        "readOnly": true,
        "label": "Customer Name",
        "grid": 2
    },
    {
        "varName": "urgencyValue",
        "type": "select",
        "required": true,
        "enum": [
            "Urgent",
            "Regular"
        ],
        "label": "Urgency",
        "grid": 2,
    },
    /* {
         "varName": "amount",
         "type": "text",
         "readOnly": true,
         "label": "SP Amount",
         "grid": 2,
     },
     {
         "varName": "customerName",
         "type": "text",
         "multiline": true,
         "readOnly": true,
         "label": "Customer Name",
         "grid": 2,
     },
     {
         "varName": "debitAccountNumber",
         "type": "text",
         "label": "Debit A/C Number",
         "grid": 2,
     }*/

];
let CSJsonFromForAccountNumberChange = CSJsonFromForAcesEnrollemt;
let CSJsonFromForNomineeChange = CSJsonFromForAcesEnrollemt;
let CSJsonFromForDuplicateIssueCertificate = CSJsonFromForAcesEnrollemt;
let MAKERJsonFromForAcesEnrollemt = CSJsonFromForAcesEnrollemt;
let MAKERJsonFromForAccountNumberChange = CSJsonFromForAcesEnrollemt;
let MAKERJsonFromForNomineeChange = [
    {
        "varName": "registrationNumber",
        "type": "text",
        "label": "Registration Number",
        "grid": 6,
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6,
    },
    {
        "varName": "amount",
        "type": "text",
        "readOnly": true,
        "label": "SP Amount",
        "grid": 6,
    },
    {
        "varName": "customerName",
        "type": "text",
        "multiline": true,
        "readOnly": true,
        "label": "Customer Name",
        "grid": 6,
    },
    {
        "varName": "nomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 6

    },
    {
        "varName": "nomineeRelation",
        "type": "text",
        "label": "Relation",
        "grid": 6,

    },
    {
        "varName": "nomineePermanentAddress",
        "type": "text",
        "label": "Permanent Address",
        "grid": 6,

    },
    {
        "varName": "nomineePresentAddress",
        "type": "text",
        "label": "Present Address",
        "grid": 6,

    },
    {
        "varName": "nomineeAmount",
        "type": "text",
        "label": "Nominee Amount",
        "grid": 6,

    },
];
let MAKERJsonFromForDuplicateIssueCertificate = [
    {
        "varName": "registrationNumber",
        "type": "text",
        "label": "Registration Number",
        "grid": 6,
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6,
    },
    {
        "varName": "amount",
        "type": "text",
        "readOnly": true,
        "label": "SP Amount",
        "grid": 6,
    },
    {
        "varName": "customerName",
        "type": "text",
        "multiline": true,
        "readOnly": true,
        "label": "Customer Name",
        "grid": 6,
    }
];

let CHECKERJsonFromForAcesEnrollemt = makeReadOnlyObject(JSON.parse(JSON.stringify([
    {
        "varName": "registrationNumber",
        "type": "text",
        "label": "Registration Number",
        "grid": 6,
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6,
    },
    {
        "varName": "amount",
        "type": "text",
        "readOnly": true,
        "label": "SP Amount",
        "grid": 6,
    },
    {
        "varName": "customerName",
        "type": "text",
        "multiline": true,
        "readOnly": true,
        "label": "Customer Name",
        "grid": 6,
    },
    {
        "varName": "debitAccountNumber",
        "type": "textApiCall",
        "label": "Debit A/C Number",
        "grid": 6,
    },
    {
        "varName": "debitAccountName",
        "type": "text",
        "readOnly": true,
        "label": "Debit A/C Name",
        "conditional": true,
        "conditionalVarName": "debitAccountNumberApiCall",
        "conditionalVarValue": true,
        "grid": 6,
    },
])));
let CHECKERJsonFromForAccountNumberChange = makeReadOnlyObject(JSON.parse(JSON.stringify([
    {
        "varName": "registrationNumber",
        "type": "text",
        "label": "Registration Number",
        "grid": 6,
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6,
    },
    {
        "varName": "amount",
        "type": "text",
        "readOnly": true,
        "label": "SP Amount",
        "grid": 6,
    },
    {
        "varName": "customerName",
        "type": "text",
        "multiline": true,
        "readOnly": true,
        "label": "Customer Name",
        "grid": 6,
    },
    {
        "varName": "debitAccountNumber",
        "type": "textApiCall",
        "label": "Debit A/C Number",
        "grid": 6,
    },
    {
        "varName": "debitAccountName",
        "type": "text",
        "readOnly": true,
        "label": "Debit A/C Name",
        "conditional": true,
        "conditionalVarName": "debitAccountNumberApiCall",
        "conditionalVarValue": true,
        "grid": 6,
    },
    {
        "varName": "existingCreditAMount",
        "type": "text",
        "label": "Existing Crebit A/c",
        "grid": 6,
    },
    {
        "varName": "newCreditAMount",
        "type": "text",
        "label": "New Crebit A/c",
        "grid": 6,
    }

])));
let CHECKERJsonFromForNomineeChange = makeReadOnlyObject(JSON.parse(JSON.stringify([
    {
        "varName": "registrationNumber",
        "type": "text",
        "label": "Registration Number",
        "grid": 6,
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6,
    },
    {
        "varName": "amount",
        "type": "text",
        "readOnly": true,
        "label": "SP Amount",
        "grid": 6,
    },
    {
        "varName": "customerName",
        "type": "text",
        "multiline": true,
        "readOnly": true,
        "label": "Customer Name",
        "grid": 6,
    },
    {
        "varName": "nomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 6

    },
    {
        "varName": "nomineeRelation",
        "type": "text",
        "label": "Relation",
        "grid": 6,

    },
    {
        "varName": "nomineePermanentAddress",
        "type": "text",
        "label": "Permanent Address",
        "grid": 6,

    },
    {
        "varName": "nomineePresentAddress",
        "type": "text",
        "label": "Present Address",
        "grid": 6,

    },
    {
        "varName": "nomineeAmount",
        "type": "text",
        "label": "Nominee Amount",
        "grid": 6,

    },
])));
let CHECKERJsonFromForDuplicateIssueCertificate = makeReadOnlyObject(JSON.parse(JSON.stringify([
    {
        "varName": "registrationNumber",
        "type": "text",
        "label": "Registration Number",
        "grid": 6,
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6,
    },
    {
        "varName": "amount",
        "type": "text",
        "readOnly": true,
        "label": "SP Amount",
        "grid": 6,
    },
    {
        "varName": "customerName",
        "type": "text",
        "multiline": true,
        "readOnly": true,
        "label": "Customer Name",
        "grid": 6,
    }
])));
let CSJsonFormForInstaDebitCard = [
    {
        "varName": "accountNumber",
        "type": "text",
        "readOnly": true,
        "label": "Account No",
        "grid": 2,

    },
    {
        "varName": "clientId",
        "type": "text",
        "readOnly": true,
        "label": "Client ID",
        "grid": 2,

    },
    {
        "varName": "customerName",
        "type": "text",
        "readOnly": true,
        "label": "Customer Name",
        "grid": 2,

    },
    {
        "varName": "accountStatus",
        "type": "text",
        "readOnly": true,
        "label": "A/C Status",
        "grid": 2,

    },
    {
        "varName": "accountStatus",
        "type": "text",
        "readOnly": true,
        "label": "Mobile Number",
        "grid": 2,

    },
    {
        "varName": "accountStatus",
        "type": "text",
        "readOnly": true,
        "label": "Email",
        "grid": 2,

    },
    {
        "varName": "clientId",
        "type": "text",
        "readOnly": true,
        "label": "Client ID",
        "grid": 2,

    },
    {
        "varName": "nameOnCard",
        "type": "text",
        "readOnly": true,
        "label": "Name On Card",
        "grid": 2,

    },
    {
        "varName": "cardStatus",
        "type": "text",
        "readOnly": true,
        "label": "Card Status",
        "grid": 2,

    },
    {
        "varName": "cardNumber",
        "type": "text",
        "readOnly": true,
        "label": "Card Number",
        "grid": 2,

    },
    {
        "varName": "cardAccount",
        "type": "text",
        "readOnly": true,
        "label": "Card A/C",
        "grid": 2,

    },
    {
        "varName": "cardType",
        "type": "select",
        "readOnly": true,
        "enum": [
            "City Maxx",
            "Debit Card",
        ],
        "label": "Card Type",
        "grid": 2,

    },
    {
        "varName": "productType",
        "type": "select",
        "readOnly": true,
        "enum": [
            "General",
            "Savings Delight",
            "Secured Overdraft",
            "Easy Current",
            "Easy Olus Current Account",
        ],
        "label": "Product Type",
        "grid": 2,

    },
];
let BOMJsonFormForInstaDebitCard = [
    {
        "varName": "accountNumber",
        "type": "text",
        "readOnly": true,
        // "multiline": true,
        "label": "Account No",
        "grid": 2,

    },
    {
        "varName": "customerName",
        "type": "text",
        "readOnly": true,
        "multiline": true,
        "label": "Customer Name",
        "grid": 2,
    },
    {
        "varName": "nameOnCard",
        "type": "text",
        "readOnly": true,
        "multiline": true,
        "label": "Name On Card",
        "grid": 2,
    },
    {
        "varName": "clientId",
        "type": "text",
        "multiline": true,
        "readOnly": true,
        "label": "Client ID",
        "grid": 2,

    },
    {
        "varName": "cardNumber",
        "type": "text",
        "multiline": true,
        "readOnly": true,
        "label": "Card Number",
        "grid": 2,

    },
    {
        "varName": "accountStatus",
        "type": "text",
        "readOnly": true,
        "label": "A/C Status",
        "grid": 2,

    },
    {
        varName: "rmCode",
        type: "textApiCall",
        label: "RM Code",
        grid: 2,
        "readOnly": true,
    },
    {
        varName: "rmName",
        type: "text",
        label: "RM Name",
        multiline: true,
        conditional: true,
        conditionalVarName: "showRmName",
        conditionalVarValue: true,
        readOnly: true,
        grid: 2,
    },
    {
        "varName": "email",
        "type": "text",
        "multiline": true,
        "readOnly": true,
        "label": "Email",
        "grid": 2,

    },
    {
        "varName": "mobileNumber",
        "type": "text",
        "readOnly": true,
        "label": "Mobile Number",
        "grid": 2,

    },
    {
        varName: "cardType",
        type: "text",
        label: "Card Type",
        "readOnly": true,
        grid: 2,
    },
    {
        varName: "productType",
        type: "select",
        label: "Product Type",
        "readOnly": true,
        grid: 3,
        enum: []
    },
    {
        varName: "ccep",
        type: "text",
        label: "CCEP",
        readOnly: true,
        grid: 2,
    },
    {
        varName: "passportNumber",
        type: "text",
        label: "Passport Number",
        readOnly: true,
        grid: 2,
    },
    {
        varName: "passportExpiryDate",
        type: "date",
        label: "Passport Exp Date",
        readOnly: true,
        grid: 2,
    },
    {
        varName: "passportIssueDate",
        type: "date",
        label: "Passport Issue Date",
        readOnly: true,
        grid: 2,
    },
    {
        varName: "passportIssuePlace",
        type: "text",
        label: "Passport Issue Place",
        readOnly: true,
        multiline: true,
        grid: 2,
    },
];
module.exports = {
    //CB DAta
    CBDataJsonForm,
    CBDataJsonFormNew,
    FdrAndDpsTagForm,
    CBDataJsonFormForFDR,
    CBDataJsonFormForFDRReadOnly,
    CBDataJsonFormReadOnly,
    //Customer Form
    CSjsonFormIndividualAccountOpeningCustomerSearch,
    CSjsonFormIndividualAccountOpeningCustomerNameSearch,

    //Dedup Form
    CSjsonFormIndividualAccountOpeningSearch,
    CSjsonFormIndividualAccountOpeningSearchmandate,
    CSjsonFormIndividualAccountOpeningSearchBeneficiary,
    CSjsonFormNonIndividualProprietorshipAccountOpeningSearch,
    CSjsonFormNonIndividualWithoutProprietorshipAccountOpeningSearch,
    CSjsonFormNonIndividualCompanyAccountOpeningSearch,
    CSjsonFormNonIndividualNGOSOCIETYAccountOpeningSearch,
    ///casa New Individual
    CSJsonFormForFDRService,
    CSJsonFormForCasaServiceDPS,
    CSJsonFormForCasaService,
    CSJsonFormForCasaServiceLast,
    CSJsonFormForCasaIndividual,
    CSJsonFormForCasaIndividualReadOnly,
    BMJsonFormForCasaIndividual,
    BOMJsonFormForCasaIndividual,
    MAKERJsonFormForCasaIndividual,
    MAKERJsonFormForCasaIndividualLast,
    KYC,
    TP,
    TpKycForIndividual,
    CHECKERJsonFormForCasaIndividual,
    CHECKERJsonFormForCasaIndividualLast,
    scriptNumber,
    ///casa Exist Individual
    CSJsonFormForCasaServiceExist,
    CSJsonFormForCasaServiceLastExist,
    CSJsonFormForCasaServiceAllExist,
    CSJsonFormForCasaIndividualExist,
    CSJsonFormForCasaIndividualExistReadOnly,
    BMJsonFormForCasaIndividualExist,
    BOMJsonFormForCasaIndividualExist,
    MAKERJsonFormForCasaIndividualExist,
    MAKERJsonFormForCasaIndividualLastExist,
    CHECKERJsonFormForCasaIndividualExist,
    CHECKERJsonFormForCasaIndividualLastExist,

    ///casa New Joint
    CSJsonFormForCasaJoint,
    CSJsonFormForCasaJointReadOnly,
    BMJsonFormForCasaJoint,
    BOMJsonFormForCasaJoint,
    MAKERJsonFormForCasaJoint,
    MAKERJsonFormForCasaJointLast,
    CHECKERJsonFormForCasaJoint,
    CHECKERJsonFormForCasaJointLast,

    ///casa Exist Joint
    CSJsonFormForCasaJointExist,
    CSJsonFormForCasaJointExistReadOnly,
    BMJsonFormForCasaJointExist,
    BOMJsonFormForCasaJointExist,
    MAKERJsonFormForCasaJointExist,
    MAKERJsonFormForCasaJointLastExist,
    CHECKERJsonFormForCasaJointExist,
    CHECKERJsonFormForCasaJointLastExist,

    ///casa New Proprietorship
    CSJsonFormForCasaProprietorship,
    BMJsonFormForCasaProprietorship,
    BOMJsonFormForCasaProprietorship,
    MAKERJsonFormForCasaProprietorship,
    MAKERJsonFormForCasaProprietorshipLast,
    CHECKERJsonFormForCasaProprietorship,
    CHECKERJsonFormForCasaProprietorshipLast,

    ///casa Exist Proprietorship
    CSJsonFormForCasaProprietorshipExist,
    BMJsonFormForCasaProprietorshipExist,
    BOMJsonFormForCasaProprietorshipExist,
    MAKERJsonFormForCasaProprietorshipExist,
    MAKERJsonFormForCasaProprietorshipLastExist,
    CHECKERJsonFormForCasaProprietorshipExist,
    CHECKERJsonFormForCasaProprietorshipLastExist,

    ///casa New Company
    CSJsonFormForCasaCompany,
    BMJsonFormForCasaCompany,
    BOMJsonFormForCasaCompany,
    MAKERJsonFormForCasaCompany,
    MAKERJsonFormForCasaCompanyLast,
    CHECKERJsonFormForCasaCompany,
    CHECKERJsonFormForCasaCompanyLast,

    ///casa Exist Company
    CSJsonFormForCasaCompanyExist,
    BMJsonFormForCasaCompanyExist,
    BOMJsonFormForCasaCompanyExist,
    MAKERJsonFormForCasaCompanyExist,
    MAKERJsonFormForCasaCompanyLastExist,
    CHECKERJsonFormForCasaCompanyExist,
    CHECKERJsonFormForCasaCompanyLastExist,





///Agent
    AbhMakerJsonFormForAgentIndividual,
    AbhMakerJsonFormForAgentNonIndividual,
    AbhApprovalJsonFormForAgentIndividual,
    AbhApprovalJsonFormForAgentNonIndividual,
    AbhCheckerJsonFormForAgentIndividual,
    AbhCheckerJsonFormForAgentNonIndividual,

///BOND
    CSJsonFormForBondWegDevelopmentBond,
    MAKERJsonFormForBondWegDevelopmentBond,
    CHECKERJsonFormForBondWegDevelopmentBond,
    CSJsonFormForBondDib,
    MAKERJsonFormForBondDib,
    CHECKERJsonFormForBondDib,
    CSJsonFormForBondDpb,
    MAKERJsonFormForBondDpb,
    CHECKERJsonFormForBondDpb,

//Issuance Sanchaypatra Start SD Part
    MakerJsonFormForIssuanceSanchaypatra,
    CheckerJsonFormForIssuanceSanchaypatra,
    //Issuance Bond Start SD Part
    MakerJsonFormForIssuanceBond,
    CheckerJsonFormForIssuanceBond,
    //EncashMent Bond Start SD Part
    MakerJsonFormForEncashmentBond,
    CheckerJsonFormForEncashmentBond,
//EncashMent Sanchaypatra Start SD Part
    MakerJsonFormForEncashmentSanchaypatra,
    CheckerJsonFormForEncashmentSanchaypatra,
    //Aces Enrollment Sanchaypatra Start SD Part
    MakerJsonFormForAcesEnrollmentSanchaypatra,
    CheckerJsonFormForAcesEnrollmentSanchaypatra,
////Aces Enrollment Bond Start SD Part
    MakerJsonFormForAcesEnrollmentBond,
    CheckerJsonFormForAcesEnrollmentBond,
//
    ///sanchaypatra
    CSJsonFormForPsc,
    CSJsonFormForBsp,
    CSJsonFormForFsp,
    CSJsonFormFor3ms,
    MAKERJsonFormForBsp,
    CHECKERJsonFormForBsp,
    MAKERJsonFormFor3ms,
    CHECKERJsonFormFor3ms,
    MAKERJsonFormForFsp,
    CHECKERJsonFormForFsp,
    MAKERJsonFormForPsc,
    CHECKERJsonFormForPsc,

    //Encash Payment
    CSInterestPayment,
    MAKERInterestPaymentSDPSC,
    CHECKERInterestPaymentSDPSC,
    MAKERInterestInterestPayment,
    CHECKERInterestInterestPayment,

    //ACES ENROLLMENT
    CSJsonFromForAcesEnrollemt,
    CSJsonFromForAccountNumberChange,
    CSJsonFromForNomineeChange,
    CSJsonFromForDuplicateIssueCertificate,
    MAKERJsonFromForAcesEnrollemt,
    MAKERJsonFromForAccountNumberChange,
    MAKERJsonFromForNomineeChange,
    MAKERJsonFromForDuplicateIssueCertificate,
    CHECKERJsonFromForAcesEnrollemt,
    CHECKERJsonFromForAccountNumberChange,
    CHECKERJsonFromForNomineeChange,
    CHECKERJsonFromForDuplicateIssueCertificate,
    //adc
    AdcJsonFormForVerify,
    smsRequestJsonFormForVerify,
    AdcJsonFormForCS,
    /*  "2251401933001"*/

    //Insta Debit card
    BOMJsonFormForInstaDebitCard,
    CHECKERJsonFormForCasaIndividualLastForRashed
}
