import React, {useEffect, useState} from "react";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import CardHeader from "../../../Card/CardHeader";
import CardBody from "../../../Card/CardBody";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import CBNotFound from "../../CASA/CBNotFound";
import Grid from "@material-ui/core/Grid";
import Card from "../../../Card/Card";
import loader from "../../../../Static/loader.gif";
import Notification from "../../../NotificationMessage/Notification";
import theme from "../../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import CommonApi from "../../Maintenance/common/CommonApi";
import GridContainer from "../../../Grid/GridContainer";
import CheckBox from "@material-ui/core/Checkbox/Checkbox";
import UploadComponent from "../../Maintenance/casa/UploadComponent";
import axios from "axios";
import {backEndServerURL} from "../../../../Common/Constant";
import MaintenanceUtility from "../../Maintenance/static/MaintenanceUtility";
import Table from "../../../Table/Table";
import SelectComponent from "../../../JsonForm/SelectComponent";
import TextFieldComponent from "../../../JsonForm/TextFieldComponent";
import DateComponentDeferal from "../../../JsonForm/DateComponentDeferal";
import {csBearer} from "./JsonForCustomerStatusUpdate";
import MyValidation from "../../../JsonForm/MyValidation";
import {buttonStyle} from "../../../DeliverableManagement/configuration";

const documentType = [

    {label: 'Page 1'},
    {label: 'Page 2'},
    {label: 'Page 3'},
    {label: 'Page 4'},
    {label: 'Page 5'},
    {label: 'Page 6'},
    {label: 'Page 7'},
    {label: 'Page 8'},
    {label: 'Page 9'},
    {label: 'Page 10'},
    {label: 'Page 11'},
    {label: 'Page 12'},
    {label: 'Page 13'},
    {label: 'Page 14'},
    {label: 'Page 15'},
    {label: 'Page 16'},
    {label: 'Page 17'},
    {label: 'Page 18'},
    {label: 'Page 19'},
    {label: 'Page 20'},

];

let deferalOther = {
    "varName": "deferalOther",
    "type": "text",
    "label": "Others",
};

let deferal = {
    "varName": "deferalType",
    "type": "select",
    "label": "Deferral Type",
    "enum": [
        "Applicant Photograph",
        "Nominee Photograph",
        "Passport",
        "Address proof",
        "Transaction profile",
        "Others"
    ],
};

let date = {
    "varName": "expireDate",
    "type": "text",
    "minDate": true,
    "label": "Expire Date"
};

let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];

let jsonForm = [
    {
        "varName": "eqmNumber",
        "label": "EQM Number",
        "type": "text",
        "grid": 2,
    },
    {
        "varName": "cbNumber",
        "label": "CB Number",
        "type": "text",
        "grid": 2,
        "multiline": true,
        "required": true
    },
];

const CsRequisition = (props) => {
    const [stateValue, setStateValue] = useState({
        showSearchField: false,
        inputData: {},
        varValue: {},
        showValue: false,
        showRequisitionField: false,
        loading: true,
        title: "",
        notificationMessage: "",
        cbNotFound: false,
        disabled: false,
        SdnForFundTransfer: false,
        getData: true,
        errorArray: {},
        selectedDate: {},
        errorMessages: {},
        appId: 0,
        getRemarks: [],
        getDeferralList: [],
        values: []
    });


    useEffect(() => {
        if (props.appId !== undefined) {
            CommonApi.getRemarkDetails(props.appId)
                .then((remarksArray) => {
                    setStateValue((prevState => {
                        return {
                            ...prevState,
                            getRemarks: remarksArray
                        }
                    }));
                })
                .catch((error) => {
                    console.log(error)
                });

            CommonApi.getDeferralDetails(props.appId)
                .then((tableArray) => {
                    CommonApi.getFiles(props.appId).then((fileData) => {
                        setStateValue((prevState => {
                            return {
                                ...prevState,
                                getDeferralList: tableArray,
                            }
                        }));
                    })
                })
                .catch((error) => {
                    console.log(error);
                });
            CommonApi.getVariables(props.appId)
                .then((response) => {
                    let inputData = {...response.data};
                    setStateValue((prevState => {
                        return {
                            ...prevState,
                            appId: props.appId,
                            inputData: inputData,
                            varValue: inputData,
                            showValue: true,
                            getData: true,
                            showRequisitionField: true,
                            loading: false
                        }
                    }));
                })
                .catch((error) => {
                    errorModal(error)
                })
        } else {
            setStateValue((prevState => {
                return {
                    ...prevState,
                    showSearchField: true,
                    loading: false
                }
            }));
        }
    }, [props.appId]);

    const errorModal = (error) => {
        setStateValue((prevState => {
            return {
                ...prevState,
                cbNotFound: true,
                title: error.response.data.message,
                loading: false,
            }
        }));
    };


    const updateComponent = () => {

    };


    const resetState = (title, bool, showValue, inputData, loading, showRequisitionField) => {
        setStateValue((prevState => {
            return {
                ...prevState,
                inputData: inputData || stateValue.inputData,
                varValue: inputData || stateValue.inputData,
                showValue: showValue || stateValue.showValue,
                getData: true,
                showRequisitionField: showRequisitionField,
                loading: loading || false,
                disabled: false,
                title: title,
                cbNotFound: bool,
            }
        }));
    };

    const renderNotification = () => {
        if (stateValue.alert) {
            return (
                <Notification type="success" stopNotification={stopNotification} title={stateValue.title}
                              message={stateValue.notificationMessage}/>
            )
        }
    };


    const stopNotification = () => {
        setStateValue((prevState => {
            return {
                ...prevState,
                alert: false,
            }
        }));
    };


    const searchHandler = () => {
        setStateValue((prevState => {
            return {
                ...prevState,
                loading: true,
            }
        }));
        let inputData = {...stateValue.inputData};
        CommonApi.getCbDetails(stateValue.inputData.cbNumber).then((response) => {
            inputData.customerName = response.data.customerName;
            inputData.uploadFileAlertRequest = true;
            inputData.customerStatusUpdate = true;
            startCase(inputData)
        }).catch((error) => {
            errorModal(error)
        });

    };


    const startCase = (inputData) => {
        let url = backEndServerURL + "/startCase/cs_maintenance";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((caseResponse) => {
                inputData.caseId = caseResponse.caseId;
                setTimeout(() => {
                    setStateValue((prevState => {
                        return {
                            ...prevState,
                            appId: caseResponse.data.id,
                        }
                    }));
                    resetState(undefined, false, true, inputData, false, true);
                }, 1000)
            })
            .catch((error) => {
                errorModal(error)
            });
    };

    const renderSearchField = () => {
        if (stateValue.showSearchField === true && stateValue.getData) {
            return (
                <>
                    {CommonJsonFormComponent.renderJsonForm(stateValue, jsonForm, updateComponent)}
                    <Grid item xs={6}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                marginTop: '15px',
                            }}
                            onClick={searchHandler}
                            type="submit"
                        >
                            Search
                        </button>
                        &nbsp;&nbsp;
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                marginTop: '15px',
                            }}
                            onClick={() => window.location.reload()}
                            type="submit"
                        >
                            Reset
                        </button>
                    </Grid>
                    <Grid item xs={12}><br/></Grid>
                </>
            )
        }
    };

    const handleChangeUploadFileAlertRequest = (event) => {
        let data = event.target.checked;
        let inputData = {...stateValue.inputData};
        inputData.uploadFileAlertRequest = data;
        console.log(data);
        setStateValue((prevState => {
            return {
                ...prevState,
                inputData: inputData,
                varValue: inputData,
                cbNotFound: data === false,
                title: data === false ? "Without Document Process!!" : "",
            }
        }));
    };

    const previewImage = (value) => {
        let inputData = {...stateValue.inputData};
        inputData.previewImage = value === "submit";
        setStateValue((prevState => {
            return {
                ...prevState,
                inputData: inputData,
                varValue: inputData,
            }
        }));
    };

    const uploadFunction = () => {
        const {classes} = props;
        if (stateValue.getData) {
            return (
                <GridContainer>
                    <Grid item xs={.5}>
                        <CheckBox
                            style={{marginTop: "-6px"}}
                            checked={stateValue.inputData.uploadFileAlertRequest}
                            defaultValue={stateValue.inputData.uploadFileAlertRequest}
                            onChange={(event) => handleChangeUploadFileAlertRequest(event)}/>
                    </Grid>
                    <Grid item xs={11}>
                        <UploadComponent indexList={documentType} previewImage={previewImage}
                                         appId={stateValue.appId} classes={classes}/>
                    </Grid>
                </GridContainer>
            )
        }
    };

    const handleSubmit = () => {
        setStateValue((prevState => {
            return {
                ...prevState,
                getData: false,
                loading: true,
                disabled: true,
            }
        }));
        let error = MyValidation.defaultValidation(csBearer, stateValue);
        if (error === true) {
            setStateValue((prevState => {
                return {
                    ...prevState,
                    getData: true,
                    loading: false,
                    disabled: false,
                }
            }));
            return false;
        } else if (stateValue.inputData.uploadFileAlertRequest && stateValue.inputData.previewImage === undefined) {
            setStateValue((prevState => {
                return {
                    ...prevState,
                    getData: true,
                    loading: false,
                    disabled: false,
                    cbNotFound: true,
                    title: "Please Upload File!"
                }
            }));
            return false;
        } else {
            if (stateValue.inputData["csDeferal"] === "YES") {
                let defType = [];
                let expDate = [];
                var deferralData = {};
                let appId = stateValue.appId;
                for (let i = 0; i < stateValue.values.length; i++) {
                    let value = stateValue.values[i];
                    let deferalType = stateValue.inputData["deferalType" + value];
                    if (deferalType === "other") {
                        deferalType = stateValue.inputData["deferalOther" + value];
                    }
                    defType.push(deferalType);
                    let expireDate = stateValue.inputData["expireDate" + value];
                    expDate.push(expireDate);

                }
                deferralData.appId = appId;
                deferralData.type = defType;
                deferralData.dueDate = expDate
            }
            let postData = {...stateValue.inputData};
            if (stateValue.inputData.bearerApproval === "BM Approval") {
                postData.cs_bearer = "bm_approval";
            }
            if (stateValue.inputData.bearerApproval === "BM & Call Center Approval") {
                postData.cs_bearer = "both_approval";
            }
            if (stateValue.inputData.bearerApproval === "Call Center Approval") {
                postData.cs_bearer = "call_center_approval";
            }
            if (stateValue.inputData.csDeferal === "NO" || stateValue.inputData.csDeferal === undefined) {
                postData.cs_deferal = "NO";
            }
            if (stateValue.inputData.csBearer === "NO" || ((stateValue.inputData.bearerApproval === stateValue.return_bearer_approval) && stateValue.return_cs_bearer === "NO")) {
                postData.cs_bearer = "NO";
            }
            let remarksData = {};
            postData.serviceType = "Maintenance";
            postData.category = "Maintenance";
            postData.subCategory = "Customer Status Update";
            postData.subServiceType = "AccountMaintenance";
            postData.cs_deferal = stateValue.inputData["csDeferal"] === "YES" ? "YES" : "NO";
            remarksData.remark = postData.csRemarks;
            remarksData.map = {...postData, csRemarks: undefined};
            remarksData.bulkDeferralDTO = deferralData;
            let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + stateValue.appId;
            axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                setStateValue((prevState => {
                    return {
                        ...prevState,
                        getData: true,
                        loading: false,
                        disabled: false,
                        title: "Successful!",
                        notificationMessage: "Successfully Routed!",
                        alert: true,
                    }
                }));
                if (props.appId !== undefined) {
                    props.closeModal()
                } else {
                    setTimeout(function () {
                        window.location.reload()
                    }, 1000);
                }
            }).catch((error) => {
                console.log(error);
                errorModal(error)
            });
        }
    };

    const renderRemarksData = () => {
        if (stateValue.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks</b>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={stateValue.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    const renderDeferralData = () => {
        if (stateValue.getDeferralList.length > 0) {
            return (
                <>
                    <br/>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Deferral List:</b>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                                tableData={stateValue.getDeferralList}
                                tableAllign={['left', 'left']}
                            />
                        </div>
                    </div>
                </>
            )
        }
    };

    const renderAddButtonShow = () => {
        return (
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    width: 100,
                    float: 'left',
                    verticalAlign: 'right',
                }}
                type='button' value='add more'
                onClick={addClick}
            >Add Deferral</button>
        )
    };

    const addClick = () => {
        let randomNumber = Math.floor(Math.random() * 100000000000);
        let inputData = {...stateValue.inputData};
        inputData["csDeferal"] = "YES";
        setStateValue((prevState => {
            return {
                ...prevState,
                values: [...prevState.values, randomNumber],
                inputData: inputData,
                varValue: inputData
            }
        }));
    };

    const dynamicDeferral = (i) => {
        let field = JSON.parse(JSON.stringify(deferal));
        field.varName = "deferalType" + i;
        return SelectComponent.select(stateValue, updateComponent, field);
    };

    const dynamicDeferralOther = (i) => {
        if (stateValue.inputData["deferalType" + i] === "Others") {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(stateValue, updateComponent, field);
        } else {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(stateValue, updateComponent, field);
        }
    };

    const onKeyDownChange = (data) => {
        let postData = {...stateValue.inputData};
        postData[data] = stateValue.selectedDate[data];
        setStateValue((prevState => {
            return {
                ...prevState,
                inputData: postData,
                varValue: postData
            }
        }));
    };

    const dynamicDate = (i) => {
        let field = JSON.parse(JSON.stringify(date));
        field.varName = "expireDate" + i;
        field.onKeyDown = true;
        return DateComponentDeferal.date(stateValue, updateComponent, field, onKeyDownChange);
    };

    const removeClick = (i) => {
        let pos = stateValue.values.indexOf(i);
        let inputData = {...stateValue.inputData};
        if (pos > -1) {
            stateValue.values.splice(pos, 1);
            if (stateValue.values.length > 0) {
                inputData["csDeferal"] = "YES";
                setStateValue((prevState => {
                    return {
                        ...prevState,
                        inputData: inputData,
                        varValue: inputData
                    }
                }));
            } else {
                inputData["csDeferal"] = "NO";
                setStateValue((prevState => {
                    return {
                        ...prevState,
                        inputData: inputData,
                        varValue: inputData
                    }
                }));
            }
        }
    };

    const addDeferralForm = () => {
        return stateValue.values.map((el, i) =>
            <GridContainer item xs={12} key={"deferral" + el} style={{padding: "0 15px"}}>
                <Grid item xs={3}>
                    {dynamicDeferral(el)}
                </Grid>
                <Grid item xs={3}>
                    {dynamicDate(el)}
                </Grid>
                <Grid item xs={3}>
                    {dynamicDeferralOther(el)}
                </Grid>
                <Grid item xs={3}>
                    <button
                        style={{
                            float: "left",
                            marginTop: "15px"
                        }}
                        className="btn btn-outline-primary btn-sm"
                        type='button' value='remove' onClick={() => removeClick(el)}
                    >
                        Remove
                    </button>
                </Grid>
            </GridContainer>
        )
    };

    const keyDownForCheck = (event, data) => {

    };

    const keyDownForChange = (data) => {
        if (data.varName === "csBearer") {
            let postData = {...stateValue.inputData};
            if (stateValue.inputData["csBearer"] === "YES") {
                postData["bearerApproval"] = "BM Approval";
                postData["showBearerField"] = true;
            } else {
                postData["bearerApproval"] = "";
                postData["showBearerField"] = false;
            }

            setStateValue((prevState => {
                return {
                    ...prevState,
                    inputData: postData,
                    varValue: postData
                }
            }));
        }
    };


    const renderRequisitionField = () => {
        if (stateValue.getData && stateValue.showRequisitionField) {
            return <>
                {CommonJsonFormComponent.renderJsonForm(stateValue, csBearer, updateComponent, keyDownForCheck, keyDownForChange)}
                <Grid item xs={12}>{uploadFunction()}</Grid>
                <Grid item xs={12}></Grid>
                {renderAddButtonShow()}
                <Grid item xs={12}></Grid>
                {addDeferralForm()}
                <Grid item xs={12}>
                    {renderDeferralData()}
                </Grid>
                <Grid item xs={12}>
                    {renderRemarksData()}
                </Grid>
                <Grid item
                      xs={12}>{CommonJsonFormComponent.renderJsonForm(stateValue, csRemarks, updateComponent)}
                </Grid>
                <button
                      style={buttonStyle}
                    onClick={handleSubmit}
                    type="submit"
                >
                    Submit
                </button>
            </>
        }
    };

    const renderHeader = () => {
        if (props.appId !== undefined) {
            return MaintenanceUtility.renderCustomStatusUpdate(props.freeFlag1, props.caseId, props.solId, props.cbNumber, props.accountNumber, props.customerName, props.closeIcon)
        } else {
            return <h4 style={{color: "white"}} >Customer Status Update</h4>
        }
    };

    const closeCBNotFound = () => {
        setStateValue((prevState => {
            return {
                ...prevState,
                cbNotFound: false
            }
        }));
    };


    return (
        <>
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {renderHeader()}
                </CardHeader>
                <br/>
                <CardBody>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        open={stateValue.cbNotFound}>
                        <DialogContent>
                            <CBNotFound
                                closeModal={closeCBNotFound}
                                title={stateValue.title}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        open={stateValue.loading}>
                        <DialogContent>
                            <center><img src={loader} alt=""/></center>
                        </DialogContent>
                    </Dialog>
                    <Grid container spacing={0}>
                        <ThemeProvider theme={theme}>
                            {renderSearchField()}
                            {renderRequisitionField()}
                        </ThemeProvider>
                    </Grid>
                    <br/>
                    {renderNotification()}
                </CardBody>
            </Card>
        </>
    )
};

export default CsRequisition;