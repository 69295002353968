import React, {Component,} from "react";


class ChangeTimeFormat extends Component {
    static convertTime(value) {
        let dt = new Date(value);
        let month = dt.getMonth();
        let newDate = dt.getFullYear() + "-" + parseInt(month + 1) + "-" + dt.getDate();
        let hours = dt.getHours();
        let seconds = dt.getSeconds();
        let AmOrPm = hours >= 12 ? 'pm' : 'am';
        hours = (hours % 12) || 12;
        let minutes = dt.getMinutes();
        return newDate + " " + hours + ":" + minutes + ":" + seconds + " " + AmOrPm;
    }
}

export default ChangeTimeFormat
