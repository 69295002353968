import React, {Component} from "react";
import Card from "../Card/Card";
import Notification from "../NotificationMessage/Notification";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import Table from "../Table/Table";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";
import {Dialog} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FileDownload from "../workflow/CommonComponent/FileDownload";

class UsersAllAudit extends Component {
    state = {
        inputData: {
            searchType: "Login Log",
        },
        selectedDate: {},
        getData: false,
        workstation: false,
        role: false,
        showValue: true,
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        tableArray: [],
        err: false,
        loading: false,
        varValue: {
            searchType: "Login Log",
        },
        errorArray: {},
        errorMessages: {},
        usersAllAuditLog: {},
        title: "",
        notificationMessage: "",
        alert: false,
        otherRequestTitle: "",
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === " " ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    createTableData = (
        branchName,
        username,
        lastLoginAt,
        lastLoginIp,
        passwordExpiryDate,
        role,
        sol,
        status
    ) => {
        return [
            username,
            lastLoginAt,
            passwordExpiryDate,
            role,
          
            status,
        ];
    };

    renderUserDetails = () => {
        if (this.state.getData === true) {
            return (
                <div>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={[
                            "User Name",
                            "Last Login",
                            "Password Expiry Date",
                            "Role",
                            "User Status",
                        ]}
                        tableData={this.state.tableArray}
                        tableAllign={[
                            "left",
                            "left",
                            "left",
                            "left",
                            "left",
                            "left",
                            "left",
                            "left",
                          
                        ]}
                    />
                </div>
            );
        } else if (this.state.getData === true) {
            return (
                <div>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={[
                            "User Name",
                            "Role",
                            "Branch Name",
                            "Sol ID",
                            "Pc Host name",
                            "Remote IP",
                            "Event Type",
                            "Event Description",
                            "Event Time",
                            "Prev Data",
                            "Post Data",
                            "User Status",
                        ]}
                        tableData={this.state.tableArray}
                        tableAllign={[
                            "left",
                            "left",
                            "left",
                            "left",
                            "left",
                            "left",
                            "left",
                            "left",
                            "left",
                            "left",
                            "left",
                            "left",
                        ]}
                    />
                </div>
            );
        }
    };

    componentDidMount() {
        let data = {};
        this.setState({
            loading: true,
        });
        let tableArray = [];
        console.log(this.state.inputData);
        let url = backEndServerURL + "/user/get/all";
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                },
            })
            .then((response) => {
                console.log(response.data);

                let lastLoginAt = [];
                let passwordExpiryDate = [];
                response.data.map((data) => {
                    if (data.lastLoginAt !== null && data.lastLoginAt !== undefined) {
                        lastLoginAt = data.lastLoginAt.replace("T", " ");
                    } else {
                        lastLoginAt = data.lastLoginAt;
                    }
                    if (
                        data.passwordExpiryDate !== null &&
                        data.passwordExpiryDate !== undefined
                    ) {
                        passwordExpiryDate = data.passwordExpiryDate.replace("T", " ");
                    } else {
                        passwordExpiryDate = data.passwordExpiryDate;
                    }
                    tableArray.push(
                        this.createTableData(
                            data.branchName,
                            data.userName,
                            lastLoginAt,
                            data.lastLoginIP,
                            passwordExpiryDate,
                            data.userRoleName,
                            data.solId,
                            data.status
                        )
                    );
                });
                console.log(tableArray);
                this.setState({
                    usersAllAuditLog: response.data,
                    tableArray: tableArray,
                    getData: true,
                    loading: false,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    tableArray: tableArray,
                    loading: false,
                });
            });
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type="success"
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false,
        });
    };

    DedupDataDownloadApi = (event) => {
        event.preventDefault();
        let data = {};
        let downloadurl = backEndServerURL + "/users/all/downloadExcel";
        data.usersAllAuditLog = this.state.usersAllAuditLog;
        FileDownload.downloadFile(
            downloadurl,
            "GET",
            data,
            "UsersAllAuditLog.xlsx"
        );
    };

    downloadDedupdata = () => {
        if (this.state.getData === true) {
            return (
                <Button
                    style={{
                        backgroundColor: "green",
                        color: "white",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 3,
                        paddingBottom: 3,
                        borderRadius: 3,
                        border: 1,
                        verticalAlign: "left",
                        position: "absolute",
                        marginTop: "5px",
                        right: 10,
                    }}
                    onClick={(event) => this.DedupDataDownloadApi(event)}
                >
                    Download Excel
                </Button>
            );
        }
    };

    render() {
        return (
            <section>
                <Card>

                    <div style={{"margin-bottom": "40px", "margin-top": "12px"}}>{this.downloadDedupdata()}</div>
                    <br/>
                    <br/>
                    <Dialog fullWidth="true" maxWidth="sm" open={this.state.loading}>
                        <DialogContent>
                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    {this.renderUserDetails()}
                </Card>
            </section>
        );
    }
}

export default UsersAllAudit;
