import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../Common/Functions';
import Notification from "../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme";
import Table from "../../Table/Table";
import DialogContent from "@material-ui/core/DialogContent/index";
import loader from "../../../Static/loader.gif";
import {Dialog} from "@material-ui/core/index";
import GridList from "@material-ui/core/GridList/index";
import MyValidation from "../../JsonForm/MyValidation";
import UploadComponent from "../Maintenance/casa/UploadComponent";
import CaseWiseFiles from "../Maintenance/common/CaseWiseFiles";
import CommonApi from "../Maintenance/common/CommonApi";
import CBNotFound from "../CASA/CBNotFound";

let makerRemarks = [
    {
        "varName": "makerRemarks",
        "type": "textArea",
        "label": "Maker Remarks",
        "grid": 12
    }];


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        transform: 'translateZ(0)',
    },

};


class VerifyMakerSanchoypatraInboxCase extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            message: "",
            getData: false,
            varValue: {},
            showValue: false,
            title: "",
            notificationMessage: "",
            alert: false,
            inputData: {},
            selectedDate: {},
            getRemarks: [],
            err: false,
            errorArray: {},
            errorMessages: {},
            loading: true,
            cbNotFound: false
        };
    }

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    componentDidMount() {
        if (this.props.appId !== undefined) {
            CommonApi.getVariables(this.props.appId).then((response) => {
                let inputData = {...response.data};
                let jsonForm = this.props.commonJsonForm;
                for (let i = 0; i < jsonForm.length; i++) {
                    if (jsonForm[i].varName === "accountNumber") {
                        jsonForm[i].type = "text";
                        jsonForm[i].readOnly = true
                    }
                }

                CommonApi.getRemarkDetails(this.props.appId).then((remarksArray) => {
                    this.setState({
                        getRemarks: remarksArray
                    })
                }).catch((error) => {
                    console.log(error.response.data.message);
                });

                if ( inputData.source!==undefined && inputData.source==="FINACLE" && inputData.cbNumber !== undefined) {
                    CommonApi.getCbDetails(inputData.cbNumber).then((response) => {
                        if (response.data.COMMPH1BLOCK) {
                            inputData.mobileNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                        }
                        if (response.data.COMMEMLBLOCK) {
                            inputData.email = response.data.COMMEMLBLOCK.emailInfo;
                        }
                        this.setState({
                            inputData: inputData,
                            varValue: this.emptyValueRemove(inputData),
                            getData: true,
                            showValue: true,
                            loading: false
                        });
                    }).catch((error) => {
                        console.log(error);
                        this.setState({
                            cbNotFound: true,
                            title: error.response.data.message,
                            loading: false
                        })
                    });
                }
               else if ( inputData.source!==undefined && inputData.source==="ABABIL" && inputData.cbNumber !== undefined) {
                    CommonApi.getCbDetailsAbabil(inputData.cbNumber).then((response) => {
                        inputData.mobileNumber = response.data.mobileNumber;
                        inputData.email = response.data.email;

                        this.setState({
                            inputData: inputData,
                            varValue: this.emptyValueRemove(inputData),
                            getData: true,
                            showValue: true,
                            loading: false
                        });
                    }).catch((error) => {
                        console.log(error);
                        this.setState({
                            cbNotFound: true,
                            title: error.response.data.message,
                            loading: false
                        })
                    });
                }
                else {
                    this.setState({
                        inputData: inputData,
                        varValue: this.emptyValueRemove(inputData),
                        getData: true,
                        showValue: true,
                        loading: false
                    });
                }
            }).catch((error) => {
                console.log(error);
                this.setState({
                    cbNotFound: true,
                    title: error.response.data.message,
                    loading: false
                })
            });
        }
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === undefined || clone[prop] === null || clone[prop] === "undefined" || clone[prop] === "null" || clone[prop] === "-1")
                delete clone[prop];
        return clone;
    };

    handleSubmit = (event, data) => {
        event.preventDefault();
        let dependencyField = [];
        if (data === "RETURN") {
            dependencyField.push({
                "varName": "makerRemarks",
                "type": "textArea",
                "required": true,
                "label": "Maker Remarks",
                "grid": 12
            })
        } else {
            dependencyField = this.props.commonJsonForm;
        }
        let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }

        this.setState({
            loading: true
        });

        let postData ={...this.state.inputData};
        postData.bond_maker_approval = data;
        postData.freeFlag4 = this.state.inputData.registrationNumber;
        postData.freeFlag5 = this.state.inputData.selectTypeOfProject;
        postData.freeFlag6 = this.state.inputData.amount;
        let remarksData = {};
        remarksData.remark = this.state.inputData.makerRemarks;
        remarksData.map = {...postData, makerRemarks: undefined};
        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            this.setState({
                title: "Successful!",
                notificationMessage: "Successfully Routed!",
                alert: true,
                loading: false
            });
            this.props.closeModal()
        }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false
            })
        });
    };

    renderJsonFormFirst = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent)
            )
        }
    };

    handleSubmitSave = (event) => {
        event.preventDefault();
        let variableSetUrl = backEndServerURL + "/save/" + this.props.appId;
        let data = this.state.inputData;
        // data.makerRemarks = undefined;
        data.freeFlag4 = this.state.inputData.registrationNumber;
        data.freeFlag5 = this.state.inputData.selectTypeOfProject;
        data.freeFlag6 = this.state.inputData.amount;
        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Saved!",
                    alert: true
                });
                this.props.closeModal()
            })
            .catch((error) => {
                console.log(error)
            });
    };

    renderButton = () => {
        if (this.state.getData) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "APPROVE")}
                    >Approve
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={this.handleSubmitSave}
                    >Save
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "RETURN")}
                    >Return
                    </button>
                </div>
            )
        }
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, makerRemarks, this.updateComponent)
            )
        }
    };

    uploadFunction = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return <UploadComponent appId={this.props.appId} classes={classes} processName="Bond"
                                    eadviceFlag={true}/>
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);

        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Grid container spacing={1}>
                        <ThemeProvider theme={theme}>
                            {this.renderNotification()}
                            {this.renderJsonFormFirst()}
                        </ThemeProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <br/>
                        {this.uploadFunction()}
                        <br/><br/>
                    </Grid>
                    <CaseWiseFiles appId={this.props.appId}/>
                    <Grid item xs={12}>
                        {this.renderRemarksData()}
                    </Grid>
                    <br/>
                    <ThemeProvider theme={theme}>
                        {this.renderRemarks()}
                        <br/>
                    </ThemeProvider>
                    {this.renderButton()}
                </div>
            </GridList>
        )
    }
}

export default withStyles(styles)(VerifyMakerSanchoypatraInboxCase);

