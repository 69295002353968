import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import MenuList from "./MenuList";


const styles = theme => ({
    root: {
        display: 'flex',
    },

    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        height: '100vh',
        overflow: 'auto',
        //content color
        backgroundColor: "#f0f0fe"

    },
    tableContainer: {
        height: 320,
    },
    h5: {
        marginBottom: theme.spacing.unit * 2,
    },
});

class MenuListDashboard extends React.Component {
    state = {
        open: true,
    };


    render() {


        return (

            <MenuList/>

        );
    }
}

MenuListDashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenuListDashboard);
