import React, {Component} from 'react';
import axios from 'axios';
import {Dialog, Grow} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import DialogContent from "@material-ui/core/DialogContent";
import Table from "../../Table/Table";
import {backEndServerURL} from "../../../Common/Constant";

import GridContainer from "../../Grid/GridContainer.jsx";
import GridItem from "../../Grid/GridItem.jsx";
import AccountEdit from './AccountEdit';

import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import 'antd/dist/antd.css';
import CloseIcon from '@material-ui/icons/Close';
import OpeningCS from "./OpeningCS";

import loader from "../../../Static/loader.gif";
import ErrorModal from "../CommonComponent/ErrorModal";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {
    CSJsonFormForCasaIndividualTagFdr,
    CSJsonFormForFDRService,
    CSJsonFormForFDRServiceLast
} from "../WorkflowJsonFormArin";
import OpeningCSLocker from "../LOCKER/OpeningCSLocker";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import Grid from "@material-ui/core/Grid";

let JsonForm = []
;
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'
    },
    dialogPaper: {
        // overflow: "visible"
    }
};

function f(state, fn) {
    console.log(state.appData);
    state.err = true;
    fn();
}

function Transition(props) {

    return <Grow in={true} timeout="auto" {...props} />;
}

class AccountList extends Component {
    state = {
        appData: "",
        err: false,
        inputData: {},
        customerType: ""
    };

    constructor(props) {
        super(props);
        this.state = {
            addRole: false,
            roleView: false,
            addUser: false,
            userView: false,
            getsearchValue: [],
            newAcoountOpeningModal: false,
            tableData: [[" ", " "]],
            roleId: '',
            assignMenuToRole: '',
            viewMenu: '',
            notificationFlag: false,
            alert: false,
            redirectLogin: false,
            loading: true,
            editAccount: false,
            lienStatus: "",
            freezeStatus: "",
            accountStatusData: "",
            accountRestriction: false,
            errorModalBoolean: false,
            errorTittle: "",
            withKyc: false,
            withoutKyc: false,
            conditionalNewButton: false,
            fdrNewFlowButton: false,
            customerType: "",
            inputData: { },
            varValue: { },
            errorArray: {},
            errorMessages: {},
            showValue: false,
            SelectedData: false,
         };
    }

    createTableData = (customerName,cbNumber) => {
        return ([customerName,cbNumber,
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'middle',
                }}
                onClick={(event) => this.handleCommon(event,cbNumber, "handleSubmit")}
            >
                Select
            </button>
        ]);
    };
    componentDidMount() {

        let taggingCB = this.props.customerId;
        let cbList = [];
        cbList.push(taggingCB)
        JsonForm=[];
        let s1 = (this.props.accountNo).toString();
        let s2 = s1.substr(3);
        let cbData = s2.substring(0, s2.length - 3);
        let accountTocbNumber = "CB" + cbData;

        let tableData=[];
        let cbUrl = backEndServerURL + "/cbsCustomerGet/" + taggingCB;
            axios.get(cbUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    tableData.push(this.createTableData(response.data.customerName,taggingCB));
                    if(taggingCB!==accountTocbNumber){
                        let cbUrl = backEndServerURL + "/cbsCustomerGet/" + accountTocbNumber;
                        axios.get(cbUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                tableData.push(this.createTableData( response.data.customerName,accountTocbNumber));
                                this.setState({
                                    tableData: tableData,
                                    getData: true,
                                    loading: false,
                                })
                            })
                            .catch((error)=>{
                                console.log(error)
                            })
                    }
                    else{
                        this.setState({
                            tableData: tableData,
                            getData: true,
                            loading: false,
                        })
                    }
                })
                .catch((error) => {

                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid CB!!",

                    })
                })




    }

    updateComponent = () => {
        this.forceUpdate();
    };
    onKeyDownChange = (data) => {

        if (data.varName === "newTaggingcbNumber") {
            this.setState({
                loading: true
            });
            this.state.inputData.cbNameApiCall = "NO";
            this.state.varValue.cbNameApiCall = "NO";

            let cbUrl = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData[data.varName];
            axios.get(cbUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.newTaggingcbName = response.data.customerName;
                    this.state.varValue.newTaggingcbName = response.data.customerName
                    this.state.inputData.cbNameApiCall = "YES";
                    this.state.varValue.cbNameApiCall = "YES";
                    this.forceUpdate();
                        setTimeout(() => {
                            this.setState({
                                inputData: this.state.inputData,
                                varValue: this.state.varValue,
                                showValue:true,
                                getData:true,
                                loading: false
                            })
                        }, 500)


                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.newTaggingcbNumber = "__ERROR__";
                    this.state.varValue.newTaggingcbNumber = "__ERROR__";
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid CB!!",

                    })
                })
        }
    }
    returnJsonFrom = () => {
        if (this.state.getData /*&& (this.props.freeFlag1 !== 'FDR Account Opening - with Existing CB Only' || this.state.cbDataMapping)*/) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, (JsonForm), this.updateComponent,"",this.onKeyDownChange)
            )

        }
    }
    accountEditModal = () => {
        if (this.props.serviceType === 'FDR Opening') {
            return (
                <AccountEdit
                    getmatchBy={this.props.getmatchBy}
                    fdrNewFlowButton={this.props.fdrNewFlowButton}
                    conditionalNewButton={this.props.conditionalNewButton}
                    customerType={this.props.customerType}
                    data={this.props.tableData}
                    getAccountType={this.props.getAccountType}
                    freeFlag3={this.props.freeFlag3}
                    accountNo={this.props.accountNo}
                    searchValue={this.props.searchValue}
                    dstFlag={this.props.dstFlag}
                    agentBankingFlag={this.props.agentBankingFlag}
                    closeModal={this.closeModal}
                    closeModalOnlySubModal={this.closeModalOnlySubModal}
                    businessSegment={this.props.businessSegment}
                    serviceType='FDR Opening'
                    customerId={this.state.inputData.newTaggingcbNumber}
                    subServiceType={this.props.subServiceType}
                    AccountType="FDR Account Opening - with Existing Operative Account"
                    freeFlag1="FDR Account Opening - with Existing Operative Account"
                    title="FDR Account Opening - CS View (with Existing Operative Account)"
                    serviceList={CSJsonFormForFDRService}
                    serviceListLast={CSJsonFormForFDRServiceLast}
                    commonJsonForm={CSJsonFormForCasaIndividualTagFdr}
                    serviceJsonForm={this.props.serviceList}
                    secondButtonName="close"
                    type={this.props.type}
                    jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                    individualDedupData={this.props.individualDedupData}
                    mandateindividualDedupData={this.props.mandateindividualDedupData}
                    beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}

                    jointDedupData={this.props.jointDedupData}
                    companyDedupData={this.props.companyDedupData}
                    name="Edit Account"/>
            )

        }
        if (this.props.serviceType === 'Locker Opening') {
            return (
                <OpeningCSLocker
                    data={this.props.tableData}
                    getAccountType={this.props.getAccountType}
                    accountNo={this.props.accountNo}
                    searchValue={this.props.searchValue}
                    closeModal={this.closeModal} businessSegment={this.props.businessSegment}
                    serviceType={this.props.serviceType}
                    subServiceType={this.props.subServiceType}
                    title="Locker Opening"
                    dstFlag={this.props.dstFlag}
                    agentBankingFlag={this.props.agentBankingFlag}
                    commonJsonForm={this.props.lockerOpening}
                    serviceJsonForm={this.props.serviceJsonForm}
                    secondButtonName="close"
                    type={this.props.type}
                    jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                    individualDedupData={this.props.individualDedupData}
                    mandateindividualDedupData={this.props.mandateindividualDedupData}
                    beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}

                    jointDedupData={this.props.jointDedupData}
                    companyDedupData={this.props.companyDedupData}
                    name="Edit Account"/>
            )
        }
        if (this.props.serviceType === 'DPS Opening') {
            return (
                <AccountEdit
                    getmatchBy={this.props.getmatchBy}
                    fdrNewFlowButton={this.props.fdrNewFlowButton}
                    conditionalNewButton={this.props.conditionalNewButton}
                    customerType={this.props.customerType}
                    data={this.props.tableData}
                    getAccountType={this.props.getAccountType}
                    accountNo={this.props.accountNo}
                    searchValue={this.props.searchValue}
                    freeFlag3={this.props.freeFlag3}
                    closeModal={this.closeModal}
                    dstFlag={this.props.dstFlag}
                    agentBankingFlag={this.props.agentBankingFlag}
                    closeModalOnlySubModal={this.closeModalOnlySubModal} businessSegment={this.props.businessSegment}
                    customerId={this.state.inputData.newTaggingcbNumber}
                    title="DPS Account Opening -with Existing Operative Account"
                    serviceType='DPS Opening'
                    subServiceType={this.props.subServiceType}
                    AccountType="DPS Account Opening -with Existing Operative Account"
                    freeFlag1="DPS Account Opening -with Existing Operative Account"
                    commonJsonForm={this.props.commonJsonForm}
                    serviceList={this.props.serviceList}
                    serviceListLast={this.props.serviceListLast}
                    serviceJsonForm={this.props.serviceJsonForm}
                    jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                    individualDedupData={this.props.individualDedupData}
                    mandateindividualDedupData={this.props.mandateindividualDedupData}
                    beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}

                    jointDedupData={this.props.jointDedupData}
                    companyDedupData={this.props.companyDedupData}
                    secondButtonName="close"
                    type={this.props.type}
                    name="Edit Account"/>
            )
        }
    };
    closeModalOnlySubModal = () => {
        this.setState({
            editAccount: false,
            conditionalNewButton: false,
            newAcoountOpeningModal: false,
            withoutKyc: false,
            withKyc: false,
        });
    };
    closeModal = () => {
        this.setState({
            editAccount: false,
            conditionalNewButton: false,
            errorModalBoolean: false,
            newAcoountOpeningModal: false,
            withoutKyc: false,
            withKyc: false,
        });
        this.props.closeModal();
    };
    handleCommon = (event,cbNumber) => {
        event.preventDefault();
        this.state.inputData.newTaggingcbNumber=cbNumber;
        this.forceUpdate();
      if( cbNumber!==undefined && cbNumber!==null){
          this.setState({
              loading: false,
              editAccount: true,
          });
      }
      else{

      }

    }
     close = () => {
        if (this.props.appId !== undefined) {
            this.props.closeModal();
        } else {
            this.props.closeModalOnlySubModal();
        }

    };
    render() {
        const {classes} = this.props;
        return (
            <section>

                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>

                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    fullScreen={true}
                    open={this.state.editAccount}
                    TransitionComponent={Transition}
                    autoScrollBodyContent={true}
                >
                    <DialogContent className={classes.dialogPaper}>
                        {this.accountEditModal()}
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.accountRestriction}>
                    <DialogContent className={classes.dialogPaper}>
                        <ErrorModal title={this.state.errorTittle}
                                    closeModal={this.accountRestrictionCloseModal}
                                    subServiceType={this.props.subServiceType}
                                    appId={this.props.appId}
                        />

                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.errorModalBoolean}>
                    <DialogContent className={classes.dialogPaper}>
                        <ErrorModal title={this.state.errorTittle}
                                    closeModal={this.closeModal}
                                    subServiceType={this.props.subServiceType}
                                    appId={this.props.appId}
                        />

                    </DialogContent>
                </Dialog>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >Account Title and CB Selection for Opening {this.props.serviceType==='FDR Opening'?"FDR":"DPS"} Account:<a><CloseIcon onClick={this.close} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a></h4>

                            </CardHeader>
                            <CardBody>
                                <br/>
                                <ThemeProvider theme={theme}>
                                    <Grid container spacing={1}>
                                {this.state.getData &&
                                <Table
                                    tableHovor="yes"
                                    tableHeaderColor="primary"
                                    tableHead={["Account Title", "CB No","Action"]}
                                    tableData={this.state.tableData}
                                    tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                                />
                                }
                             {/*   {this.renderButton()}*/}
                                    </Grid>
                                </ThemeProvider>

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </section>
        )
    }
}

export default withStyles(styles)(AccountList);