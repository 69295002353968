import React, {Component} from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {Redirect} from "react-router-dom";
import axios from 'axios';
import '../Static/css/login.css';
import {backEndServerURL} from '../Common/Constant';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../Static/loader.gif";
import Notification from "./NotificationMessage/Notification";
import LogoutComponent from "./LogoutComponent";
import LocalstorageEncrypt from "./JsonForm/LocalstorageEncrypt";


const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {

        marginTop: theme.spacing.unit * 1,

    },
    Buttoncolorchange: {
        backgroundColor: '#ff3443',
        '&:hover': {
            backgroundColor: "#ff3443"
        }
    },
    Errorr: {
        color: '#ff3443',
        size: "46px"

    }

});

class RoleChangeLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            redirect: false,
            renderFirstLoginPage: false,
            errorMessage: "",
            id: 100,
            tableData: [[" ", " "]],
            roleId: '',
            title: "Successfull!",
            notificationMessage: "Successfully Logined!",
            alert: false,
            errorType: "success",
            firstLoginResponse: false,
            redirectLogoutComponent: false
        }

    };

    handleusername = (event) => {
        this.setState({
            username: event.target.value
        })
    };

    handlepassword = event => {
        this.setState({
            password: event.target.value
        })
    };
    createTableData = (roles, menus) => {
        return ([roles, menus
        ]);


    };
    userPermission = () => {
        let url = backEndServerURL + "/get/roleAndMenu/" + this.state.username;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let currentDate = new Date();
                LocalstorageEncrypt.encryptStorageFunction.setItem("previousSessionTime", currentDate.getMinutes());
                // cookie.save("spring_session", response.headers.spring_session);
                LocalstorageEncrypt.encryptStorageFunction.setItem("username", this.state.username);
                LocalstorageEncrypt.encryptStorageFunction.setItem("roles", response.data.roles);
                LocalstorageEncrypt.encryptStorageFunction.setItem("solId", response.data.solId);
                LocalstorageEncrypt.encryptStorageFunction.setItem("workplace", response.data.workplace);
                LocalstorageEncrypt.encryptStorageFunction.setItem("workflowGroup", response.data.workflowGroup);
                LocalstorageEncrypt.encryptStorageFunction.setItem("menus", JSON.stringify(response.data.menus));
                LocalstorageEncrypt.encryptStorageFunction.setItem("workplaceUnits", response.data.workplaceUnits);
                LocalstorageEncrypt.encryptStorageFunction.setItem("fullName", response.data.fullName);

                if (this.state.firstLoginResponse === true) {
                    this.setState({
                        loading: false,
                        renderFirstLoginPage: true,

                    })
                } else {
                    LocalstorageEncrypt.encryptStorageFunction.setItem("reload", "true");
                    this.setState({
                        loading: false,
                        redirect: true,

                    })
                }


            })
            .catch((error) => {
                this.setState({
                    loading: false,

                });
                console.log(error);
            });


    };
    errorObjectCheck = (response) => {
        if (response !== undefined) {
            if (response.data !== undefined) {
                if (response.data.message !== undefined) {
                    return response.data.message
                } else {
                    return "Username or password invalid"
                }
            } else {
                return "Username or password invalid"
            }
        } else {
            return "Username or password invalid"
        }
    };
    userLogout = () => {

        let url = backEndServerURL + "/logMeOut";

        axios.get(url, {
            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`},
        })
            .then((response) => {
                LocalstorageEncrypt.encryptStorageFunction.removeItem("username");
                LocalstorageEncrypt.encryptStorageFunction.removeItem("roles");
                LocalstorageEncrypt.encryptStorageFunction.removeItem("menus");
                // Functions.removeCookie();
                let url = backEndServerURL + "/logMeOut";
                axios.get(url, {
                    headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`},
                })
                    .then((response) => {

                    })
            })
            .catch((error) => {

            })

        // cookie.remove("spring_session");

    };

    handlesubmit = (username) => {
        this.setState({
            loading: true
        });
        let data = {};
        data.username = username;
         this.setState({
            username :username
         })
        data.group =(this.props.group);
        data.unitName =(this.props.unitName);
         let url = backEndServerURL +  "/ChangeRoleOfUserSameBranch";
        axios.post(url,data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
              /*  url = backEndServerURL + "/logMeInForAnotherRole";
                axios.post(url, data, {})
                    .then((response) => {
                        let url = backEndServerURL + "/get/roleAndMenu/" + this.state.username;
                        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                let currentDate = new Date();
                                LocalstorageEncrypt.encryptStorageFunction.setItem("previousSessionTime", currentDate.getMinutes());
                                // cookie.save("spring_session", response.headers.spring_session);
                                LocalstorageEncrypt.encryptStorageFunction.setItem("username", this.state.username);
                                LocalstorageEncrypt.encryptStorageFunction.setItem("roles", response.data.roles);
                                LocalstorageEncrypt.encryptStorageFunction.setItem("solId", response.data.solId);
                                LocalstorageEncrypt.encryptStorageFunction.setItem("workplace", response.data.workplace);
                                LocalstorageEncrypt.encryptStorageFunction.setItem("workflowGroup", response.data.workflowGroup);
                                LocalstorageEncrypt.encryptStorageFunction.setItem("menus", JSON.stringify(response.data.menus));
                                LocalstorageEncrypt.encryptStorageFunction.setItem("workplaceUnits", response.data.workplaceUnits);
                                LocalstorageEncrypt.encryptStorageFunction.setItem("fullName", response.data.fullName);
                                LocalstorageEncrypt.encryptStorageFunction.setItem("roleSwithPermission", response.data.roleSwithPermission);
                                LocalstorageEncrypt.encryptStorageFunction.setItem("reload", "true");
                                    LocalstorageEncrypt.encryptStorageFunction.setItem("getUrlAndMenuList", "true");
                                    this.setState({
                                        loading: false,
                                        redirect: true,

                                    })



                            })
                            .catch((error) => {
                                this.setState({
                                    loading: false,

                                });
                                console.log(error);
                            });*/
                let url = backEndServerURL + "/logMeOut";

                axios.get(url, {
                    headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`},
                })
                    .then((response) => {
                        LocalstorageEncrypt.encryptStorageFunction.removeItem("username");
                        LocalstorageEncrypt.encryptStorageFunction.removeItem("roles");
                        LocalstorageEncrypt.encryptStorageFunction.removeItem("menus");
                        sessionStorage.removeItem("accessToken");
                        // Functions.removeCookie();
                        this.setState({
                            loading: false,
                            redirect: true,
                            title:"Successfully Switch",
                            alert:true,
                            notificationMessage:"Successfully Switch"

                        });

                    })
                    .catch((error) => {

                    })

            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false,
                    title:"Failed Switch",
                    alert:true,
                    notificationMessage:"Failed Switch"

                });

            })




    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.errorType} stopNotification={this.stopNotification}
                              title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderRedirect = () => {

        const menuList = LocalstorageEncrypt.encryptStorageFunction.getItem("menus");

        if (this.state.redirect) {

                return (<Redirect to={{
                    /*pathname: '/CustomerDedupSearch',*/
                    pathname: '/login',
                    state: {roles: this.state.tableData}
                }}/>);



        }

    };
    renderFirstLoginPage = () => {
        if (this.state.renderFirstLoginPage === true) {
            return (<Redirect to={{
                pathname: '/PasswordChange',
            }}/>);
        }
    };

    componentDidMount() {
         this.setState({
            loading:true
        })
        this.handlesubmit(this.props.userName)

    }

    render() {
        const {classes} = this.props;
        if (this.state.redirectLogoutComponent === true) {
            return <LogoutComponent classes={this.props} username={this.state.username} password={this.state.password}/>
        } else {
            return (
                <div>

                    {
                        this.renderRedirect()

                    }
                    {
                        this.renderFirstLoginPage()

                    }
                    {
                        this.renderNotification()

                    }
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>

                </div>
            );
        }
    }
}

RoleChangeLogin.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RoleChangeLogin);