import React, {Component} from "react";
import {ThemeProvider} from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import CheckBox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import Radio from "@material-ui/core/Radio";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {FormHelperText} from "@material-ui/core";
import deburr from 'lodash/deburr';
import Downshift from "downshift";
import Paper from "@material-ui/core/Paper";
import "../../Static/css/CommonJsonForm.css";

import theme from "./CustomeTheme";
import FormValidation from "./FormValidation";

let popperNode;

class FormSample extends Component {


    handleChangeCheckbox = ((event) => {
        this.updateErrorMessageOnChange(event.target.name);
        this.state.inputData[event.target.name] = event.target.checked;

    });
    onSubmit = (() => {
        //this.setState({onSubmitToFormValidation: true})
        //console.log(this.state.inputData);
        let error = false;


        if (this.props.validation === "INDIVIDUAL") {

            error = FormValidation.customValidationIndividual(this.props, this.state, this.updateComponent)
        } else if (this.props.validation === "JOINT") {
            error = FormValidation.customValidationJoint(this.props, this.state, this.updateComponent)
        } else if (this.props.validation === "PROPRIETORSHIP") {
            error = FormValidation.customValidationProprietorship(this.props, this.state, this.updateComponent);
        } else if (this.props.validation === "COMPANY") {
            error = FormValidation.customValidationCompany(this.props, this.state, this.updateComponent);
        } else {
            error = FormValidation.defaultValidation(this.state, this.props, this.updateComponent);

        }
        console.log(this.state.errorArray);
        console.log(this.state.errorMessage);


        /*if ((this.props.validation === "PROPRIETORSHIP" || this.props.validation === "COMPANY")) {
            error = FormValidation.customValidationNonIndividual(this.props, this.state, this.updateComponent);


        }*/

        if (error === false) {
            (this.props.onSubmit(this.state.inputData));
        }

    });

    constructor(props) {
        super(props);
        let inputData = {};
        let errorArray = {};
        let errorMessage = {};
        let selectedDate = {};
        this.props.jsonForm.variables.forEach((field) => {

            if (this.props.showValue) {
                inputData[field.varName] = this.props.varValue[field.varName];
            } else
                inputData[field.varName] = "";


            errorArray[field.varName] = false;
            errorMessage[field.varName] = "";

            if (field.type === "date") {
                selectedDate[field.varName] = null
            }

        });

        this.state = {
            inputData: inputData,
            selectedDate: selectedDate,
            SelectedData: null,
            dropdownSearchDefaultValue: "",
            defaultValue: false,
            SelectedDropdownSearchData: null,
            error: false,
            errorArray: errorArray,
            errorMessages: errorMessage,
            onSubmitToFormValidation: false
        };

    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderInput = (inputProps) => {
        const {InputProps, ref, ...other} = inputProps;


        return (
            <TextField

                InputProps={{
                    inputRef: ref,

                    ...InputProps,
                }}
                {...other}
            />
        );
    };

    renderSuggestion = (suggestionProps) => {
        const {suggestion, index, itemProps, highlightedIndex, selectedItem} = suggestionProps;
        const isHighlighted = highlightedIndex === index;
        const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;


        return (
            <MenuItem
                {...itemProps}
                key={suggestion.value}
                selected={isHighlighted}
                component="div"
                style={{
                    fontWeight: isSelected ? 500 : 400,
                }}
            >
                {suggestion.label}
            </MenuItem>
        );
    };

    getSuggestions = (value, val, varName, {showEmpty = false} = {}) => {
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;

        let count = 0;
        let searchArray = [];
        if (this.props.dropdownSearchData[varName] !== undefined && this.props.dropdownSearchData[varName] !== null)
            searchArray = this.props.dropdownSearchData[varName];

        return inputLength === 0 && !showEmpty
            ? []
            : searchArray.filter(suggestion => {
                const keep =
                    count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

                if (keep) {
                    count += 1;
                }

                return keep;
            });
    };

    updateErrorMessageOnChange = (name) => {
        this.state.errorArray[name] = false;
        this.state.errorMessages[name] = "";
        this.updateComponent();
    };

    handleChange = (event) => {
        this.updateErrorMessageOnChange(event.target.name);
        if (this.props.upper === true) {
            this.state.inputData[event.target.name] = ((event.target.value).toUpperCase()).trim();

        } else {
            this.state.inputData[event.target.name] = event.target.value.trim();

        }


    };

    handleChangeFile = (event) => {

        this.state.inputData[event.target.name] = event.target.files[0];


    };

    handleChangeSelected = (name, value) => {

        this.setState({
            SelectedData: value,
        });
        this.updateErrorMessageOnChange(name);
        this.state.inputData[name] = value;

    };

    handleDropdownSelect = (varName, selectedItem) => {
        let len = this.props.dropdownSearchData[varName].length;
        for (let i = 0; i < len; i++) {
            let object = this.props.dropdownSearchData[varName][i];

            if (object.label === selectedItem) {
                this.state.inputData[varName] = object.value;
            }
        }
        this.updateErrorMessageOnChange(varName);
    };

    removeErrorMessage = () => {
        if (this.props.initialization === true) {
            this.setState({
                errorArray: {},
                errorMessages: {}
            });
            this.props.closeInitialization();

        }


    };

    returnDefaultValue = (field) => {
        //console.log(this.props.showValue);

        if (this.props.showValue) {

            return this.props.varValue[field.varName];
        } else {
            return "";
        }
    };
    returnDefaultValue1 = (field) => {

        if (this.props.showValue) {

            return this.props.varValue["dropdownCountryName"];
        } else {
            return "";
        }
    };
    renderSecondButton = () => {
        if (this.props.secondButtonName) {


            return (
                <button className="btn btn-primary" onClick={() => this.props.secondButtonFunction()}>
                    {this.props.secondButtonName}
                </button>
            );
        }
    };

    render() {

        return (
            <div>
                <Grid container>

                    {/*  {this.onSubmitToFormValidation()}*/}
                    <ThemeProvider theme={theme}>

                        {
                            this.props.jsonForm.variables.map((field) => {
                                if (field.type === "title") {
                                    return (

                                        <Grid item xs={12}>
                                            <center><h4 style={{color: "#FF5733"}}>{field.label}</h4></center>
                                        </Grid>
                                    );
                                } else if (field.type === "empty") {
                                    return (

                                        <Grid item xs={12}>
                                            &nbsp;&nbsp;&nbsp; img <img width='90%'
                                                                        src="http://192.168.10.211:8095/file/261_nid.jpg"
                                                                        alt=""/>
                                        </Grid>
                                    );
                                } else if (field.type === "emptyData") {
                                    return (

                                        <Grid item xs={12}>

                                        </Grid>
                                    );
                                } else if (field.type === "text") {
                                    return (
                                        <Grid item xs={this.props.grid}>
                                            <Grid item>
                                                <label className="input-label-common">{field.label}</label>
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    style={{
                                                        marginTop: field.marginTop,
                                                        paddingRight: 20,
                                                        marginBottom: field.marginBottom
                                                    }}
                                                    defaultValue={this.returnDefaultValue(field)}
                                                    helperText={this.state.errorMessages[field.varName]}
                                                    error={this.state.errorArray[field.varName]}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline={field.multiline === true ? true : false}
                                                    required={field.required} key={field.varName}
                                                    name={field.varName}
                                                    onChange={this.handleChange}
                                                    InputProps={{
                                                        readOnly: field.readOnly
                                                    }}
                                                />
                                            </Grid>

                                        </Grid>

                                    );
                                } else if (field.type === "password") {
                                    return (
                                        <Grid item xs={this.props.grid}>
                                            <TextField
                                                style={{marginTop: field.marginTop, marginBottom: field.marginBottom}}
                                                type='password'
                                                defaultValue={this.returnDefaultValue(field)}
                                                helperText={this.state.errorMessages[field.varName]}
                                                // variant="outlined"
                                                error={this.state.errorArray[field.varName]}
                                                required={field.required} key={field.varName}
                                                name={field.varName} label={field.label}
                                                onChange={this.handleChange}
                                                InputProps={{
                                                    readOnly: field.readOnly
                                                }}
                                            />


                                        </Grid>

                                    );
                                } else if (field.type === "checkbox") {
                                    if (field.readOnly === true) {
                                        return (
                                            <TextField
                                                style={{marginTop: field.marginTop, marginBottom: field.marginBottom}}
                                                defaultValue={this.returnDefaultValue(field)}
                                                helperText={this.state.errorMessages[field.varName]}
                                                error={this.state.errorArray[field.varName]}
                                                //variant="outlined"
                                                required={field.required} key={field.varName}
                                                name={field.varName} label={field.label}
                                                onChange={this.handleChange}
                                                InputProps={{
                                                    readOnly: field.readOnly
                                                }}
                                            />
                                        )
                                    } else {


                                        return (

                                            <Grid item xs={this.props.grid}>

                                                <FormControlLabel

                                                    control={

                                                        <CheckBox defaultValue={this.returnDefaultValue(field)}
                                                                  style={{
                                                                      marginTop: field.marginTop,
                                                                      marginBottom: field.marginBottom
                                                                  }}
                                                                  helperText={this.state.errorMessages[field.varName]}
                                                                  error={this.state.errorArray[field.varName]}
                                                                  required={field.required} key={field.varName}
                                                            //variant="outlined"
                                                                  name={field.varName}
                                                                  label={field.label}
                                                                  onChange={this.handleChangeCheckbox}/>
                                                    }

                                                    label={field.label}

                                                />
                                            </Grid>


                                        );
                                    }


                                } else if (field.type === "select") {
                                    if (field.readOnly === true) {
                                        return (
                                            <Grid item xs={this.props.grid}>
                                                <Grid item>
                                                    <label className="input-label-common">{field.label}</label>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        style={{
                                                            paddingRight: 20,
                                                            marginTop: field.marginTop,
                                                            marginBottom: field.marginBottom
                                                        }}
                                                        defaultValue={this.returnDefaultValue(field)}
                                                        helperText={this.state.errorMessages[field.varName]}
                                                        error={this.state.errorArray[field.varName]}
                                                        required={field.required} key={field.varName}
                                                        variant="outlined"
                                                        fullWidth
                                                        name={field.varName}
                                                        onChange={this.handleChange}
                                                        InputProps={{
                                                            readOnly: field.readOnly
                                                        }}
                                                    />
                                                </Grid>

                                            </Grid>
                                        )
                                    } else {


                                        return (
                                            <Grid item xs={this.props.grid}>

                                                <Grid item>
                                                    <label className="input-label-common">{field.label}</label>
                                                </Grid>
                                                <Grid item>
                                                    <FormControl fullWidth style={{paddingRight: 20}}>
                                                        {/* <FormControl  variant="outlined">*/}
                                                        {/* <InputLabel>{field.label}</InputLabel> */}
                                                        <Select style={{
                                                            marginTop: field.marginTop,
                                                            marginBottom: field.marginBottom
                                                        }} defaultValue={this.returnDefaultValue(field)}
                                                                variant="outlined"
                                                                value={this.state.inputData[field.varName]}
                                                                error={this.state.errorArray[field.varName]}
                                                            // htmlFor="outlined-age-simple"
                                                                required={field.required}
                                                                name={field.varName}
                                                                onChange={event => this.handleChangeSelected(event.target.name, event.target.value)}>
                                                            {
                                                                field.enum.map((option) => {
                                                                    return (<MenuItem key={option}
                                                                                      value={option}>{option}</MenuItem>)
                                                                })
                                                            }

                                                        </Select>

                                                        <FormHelperText>{this.state.errorMessages[field.varName]}</FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                            </Grid>


                                        );
                                    }
                                } else if (field.type === "dropdownSearch") {
                                    const options = this.props.dropdownSearchData[field.varName];

                                    return (
                                        <Grid item xs={this.props.grid}>
                                            {/*<Downshift initialInputValue={this.props.dropdownSearchData[field.varName]!==undefined?this.props.varValue[field.varName]:""}>*/}
                                            <Downshift onSelect={selectedItem => {
                                                this.handleDropdownSelect(field.varName, selectedItem)
                                            }}
                                                       initialInputValue={this.returnDefaultValue(field)}>

                                                {({

                                                      getInputProps,
                                                      getItemProps,
                                                      getMenuProps,
                                                      highlightedIndex,
                                                      inputValue,
                                                      isOpen,
                                                      selectedItem,
                                                  }) => {
                                                    const {onBlur, onFocus, ...inputProps} = getInputProps({});

                                                    return (
                                                        <div>
                                                            {this.renderInput({
                                                                label: field.label,
                                                                varName: field.varName,
                                                                InputProps: {onBlur, onFocus},
                                                                inputProps,

                                                                ref: node => {
                                                                    popperNode = node;
                                                                },
                                                            })}

                                                            <div {...getMenuProps()}>
                                                                {isOpen ? (

                                                                    <Paper
                                                                        style={{
                                                                            position: "absolute",
                                                                            zIndex: 1,
                                                                            marginTop: theme.spacing.unit,
                                                                            width: popperNode.clientWidth,
                                                                            flexWrap: "wrap"


                                                                        }} square

                                                                    > {this.getSuggestions(inputValue, field.value, field.varName).map((suggestion, index) =>
                                                                        this.renderSuggestion({
                                                                            suggestion,
                                                                            index,
                                                                            //initialInputValue:"Bangladesh",
                                                                            val: suggestion.value,
                                                                            varName: field.varName,
                                                                            itemProps: getItemProps({item: suggestion.label}),
                                                                            highlightedIndex,
                                                                            selectedItem,

                                                                        }),
                                                                    )}
                                                                    </Paper>
                                                                ) : null}
                                                            </div>

                                                        </div>
                                                    );
                                                }}
                                            </Downshift>

                                        </Grid>
                                    );


                                } else if (field.type === "radio") {
                                    if (field.readOnly === true) {
                                        return (
                                            <TextField
                                                style={{marginTop: field.marginTop, marginBottom: field.marginBottom}}
                                                defaultValue={this.returnDefaultValue(field)}
                                                helperText={this.state.errorMessages[field.varName]}

                                                error={this.state.errorArray[field.varName]}
                                                required={field.required} key={field.varName}
                                                name={field.varName} label={field.label}
                                                onChange={this.handleChange}
                                                InputProps={{
                                                    readOnly: field.readOnly
                                                }}
                                            />
                                        )
                                    } else {


                                        return (
                                            <Grid item xs={this.props.grid}>
                                                <FormControl>
                                                    <FormLabel>{field.label}</FormLabel>
                                                    <RadioGroup
                                                        style={{
                                                            marginTop: field.marginTop,
                                                            marginBottom: field.marginBottom
                                                        }}
                                                        defaultValue={this.returnDefaultValue(field)}
                                                        helperText={this.state.errorMessages[field.varName]}
                                                        error={this.state.errorArray[field.varName]}
                                                        required={field.required}
                                                        name={field.varName}
                                                        onChange={this.handleChange}
                                                    >
                                                        {
                                                            field.enum.map((radios) => {
                                                                return (<FormControlLabel
                                                                    control={<Radio/>}
                                                                    value={radios}
                                                                    label={radios}

                                                                />)
                                                            })
                                                        }


                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>

                                        );
                                    }
                                } else if (field.type === "date") {
                                    if (field.readOnly === true) {
                                        return (
                                            <Grid item xs={this.props.grid}>
                                                <Grid item>
                                                    <label className="input-label-common">{field.label}</label>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        style={{
                                                            paddingRight: 20,
                                                            marginTop: field.marginTop,
                                                            marginBottom: field.marginBottom
                                                        }}
                                                        defaultValue={this.returnDefaultValue(field)}
                                                        helperText={this.state.errorMessages[field.varName]}
                                                        error={this.state.errorArray[field.varName]}
                                                        variant="outlined"
                                                        required={field.required} key={field.varName}
                                                        name={field.varName}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: field.readOnly
                                                        }}
                                                    />
                                                </Grid>

                                            </Grid>
                                        )
                                    } else {
                                        return (
                                            <Grid item xs={this.props.grid}>
                                                <Grid item>
                                                    <label className="input-label-common">{field.label}</label>
                                                </Grid>
                                                <Grid item>

                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                                        <KeyboardDatePicker
                                                            /*variant="inline"*/
                                                            style={{
                                                                paddingRight: 20,
                                                                marginTop: field.marginTop,
                                                                marginBottom: field.marginBottom
                                                            }}
                                                            defaultValue={this.returnDefaultValue(field)}
                                                            value={this.state.selectedDate[field.varName]}
                                                            inputVariant="outlined"
                                                            maxDate={new Date()}
                                                            helperText={this.state.errorMessages[field.varName]}
                                                            error={this.state.errorArray[field.varName]}
                                                            required={field.required}
                                                            //label={field.label}
                                                            fullWidth

                                                            onChange={(date) => {
                                                                let changedDate = this.state.selectedDate;
                                                                changedDate[field.varName] = date;
                                                                this.state.errorArray[field.varName] = false;
                                                                this.state.errorMessages[field.varName] = "";
                                                                this.setState({selectedDate: changedDate});
                                                                this.state.inputData[field.varName] = (date !== null) ? date.toLocaleString('en-US') : "";
                                                            }}
                                                            format="dd-MM-yyyy"
                                                        />

                                                    </MuiPickersUtilsProvider>
                                                </Grid>

                                            </Grid>

                                        );
                                    }
                                } else if (field.type === "file") {
                                    return (
                                        <Grid item xs={this.props.grid}>
                                            <FormLabel>{field.label} </FormLabel>&nbsp;&nbsp;
                                            <input defaultValue={this.returnDefaultValue(field)}
                                                   helperText={this.state.errorMessages[field.varName]}
                                                   style={{
                                                       marginTop: field.marginTop,
                                                       marginBottom: field.marginBottom
                                                   }}
                                                   multiple
                                                   error={this.state.errorArray[field.varName]}
                                                //variant="outlined"
                                                   required={field.required} name={field.varName}
                                                   onChange={this.handleChangeFile} type="file"/>
                                        </Grid>
                                    );
                                } else {
                                    return "";
                                }
                            })
                        }

                        <Grid style={{paddingTop: "15px"}} item xs={2}>
                            <button className="btn btn-primary btn-sm" onClick={() => this.onSubmit()}>
                                {this.props.buttonName}
                            </button>
                        </Grid>
                        {this.removeErrorMessage()}
                        {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        {this.renderSecondButton()}*/}
                    </ThemeProvider>


                </Grid>


            </div>


        );
    }
}

export default FormSample;
