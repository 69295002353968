import {assetOpsBackEndServerURL, backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";

class CommonApi {

    static getRmDetails(value) {
        return new Promise((resolve, reject) => {
            let accountUrl = backEndServerURL + "/finMasterData/wealthManagerCode/" + value;
            axios.get(accountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        })
    }

    static getAccountDetails(accountNumber) {
        return new Promise((resolve, reject) => {
            let accountUrl = backEndServerURL + "/getAccountInfo/" + accountNumber;
            axios.get(accountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response))
                .catch(error => reject(error));
        })
    }
    static wordFromDecimalNumber(amount) {
        let url = backEndServerURL + "/GetWordFromDecimalNumber";
        return new Promise((resolve, reject) => {
            let postData = {};
            postData.number =amount;
            axios.post(url, postData, {
                headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,},
            })
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }
    static getCustAccountDetails(acNumber) {
        let url = backEndServerURL + "/GetCustomAcctDetailsRequest/" + acNumber;
        return new Promise((resolve, reject) => {
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }

    static ababilAccountDetails = (accountNumber) => {
        return new Promise((resolve, reject) => {
            let accountInquiryUrl = backEndServerURL + "/accountInquiryAbabil/" + accountNumber;
            axios.get(accountInquiryUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response))
                .catch(error => reject(error));
        })
    };

    static getAbabilAccountInfo = (accountNumber) => {
        return new Promise((resolve, reject) => {
            let accountInfoUrl = backEndServerURL + "/getAccountInfoAbabil/" + accountNumber;
            axios.get(accountInfoUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response))
                .catch(error => reject(error));
        })
    };

    static getCbDetails(cbNumber) {
        return new Promise((resolve, reject) => {
            let cbUrl = backEndServerURL + "/cbsCustomerGet/" + cbNumber;
            axios.get(cbUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response))
                .catch(error => reject(error));
        })
    }

    static getCbDetailsAbabil(cbNumber) {
        return new Promise((resolve, reject) => {
            let cbUrl = backEndServerURL + "/getCustomerInfoAbabilModified/" + cbNumber;
            axios.get(cbUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response))
                .catch(error => reject(error));
        })
    }

    static getModeOfOperationDescription(value) {
        return new Promise((resolve, reject) => {
            if (value) {
                let cbUrl = backEndServerURL + "/getModeOfOperationDescriptionByValue";
                let postData = {};
                postData.key = value;
                axios.post(cbUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            } else {
                resolve("")
            }
        })
    }

    static getAbabilCbDetails(cbNumber) {
        return new Promise((resolve, reject) => {
            let cbUrl = backEndServerURL + "/getCustomerInfoAbabil/" + cbNumber;
            axios.get(cbUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response))
                .catch(error => reject(error));
        })
    }

    static getCifDetails(cbNumber) {
        return new Promise((resolve, reject) => {
            let cifUrl = backEndServerURL + "/getCustomerCIFDetails/" + cbNumber;
            axios.get(cifUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response))
                .catch(error => reject(error));
        })
    }

    static getAccountStatus(accountNumber) {
        return new Promise((resolve, reject) => {
            let acUrl = backEndServerURL + "/GetCustomAcctStatus/" + accountNumber;
            axios.get(acUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response))
                .catch(error => reject(error));
        })
    }

    static getAccountOtherIno(accountNumber) {
        return new Promise((resolve, reject) => {
            let acUrl = backEndServerURL + "/accCifOthrInfo/" + accountNumber;
            axios.get(acUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response))
                .catch(error => reject(error));
        })
    }

    static getBranchList() {
        return new Promise((resolve, reject) => {
            let branch = [];
            let branchUrl = backEndServerURL + "/workplaceUnit/getInitialUnits";
            axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    response.data.map((item) => {
                        branch.push({key: item.solId, value: item.name})
                    });
                    resolve(branch)
                })
                .catch(error => reject(error))
        })
    }

    static getProcessWiseVendorName(processName) {
        return new Promise((resolve, reject) => {
            let url = backEndServerURL + "/getVendorEmailInfo/" + processName;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let vendorName = [];
                    let responseData = response.data;
                    for (let item in responseData) {
                        vendorName.push(responseData[item].vendorName);
                    }
                    resolve(vendorName);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error)
                });
        })
    }

    static functionForFileGet(appId, type) {
        return new Promise((resolve, reject) => {
            let imageUrl = backEndServerURL + "/case/files/" + type + "/" + appId;
            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response))
                .catch(error => reject(error));
        })
    };

    static getFiles = (appId) => {
        return new Promise((resolve, reject) => {
            let fileUrl = backEndServerURL + "/case/files/" + appId;
            axios.get(fileUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        })
    };
    static getFilesNew = (appId,url) => {
        return new Promise((resolve, reject) => {
            let fileUrl = url + "/case/files/" + appId;
            axios.get(fileUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        })
    };

    static getTpInquiry = (accountNumber) => {
        return new Promise((resolve, reject) => {
            let imageUrl = backEndServerURL + "/getAccountTpInquiry/" + accountNumber;
            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response))
                .catch(error => reject(error));
        })
    };

    static getKycInquiry = (accountNumber) => {
        return new Promise((resolve, reject) => {
            let kycUrl = backEndServerURL + "/getKYCInfoAccountInfo/" + accountNumber;
            axios.get(kycUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response))
                .catch(error => reject(error));
        })
    };

    static checkEtbOrNtb = (postData) => {
        return new Promise((resolve, reject) => {
            let getAccountUrl = backEndServerURL + "/getEtbStatusbyNidAndSmartCard";
            axios.post(getAccountUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(etbResponse => resolve(etbResponse))
                .catch(error => reject(error));
        })
    };

    static setCaseInquiry = (postData, appId) => {
        return new Promise((resolve, reject) => {
            let setCaseInquiry = backEndServerURL + "/case/setInquiryActivity/" + appId;
            axios.post(setCaseInquiry, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response))
                .catch(error => reject(error));
        })
    };

    static getDeferralDetails = (appId) => {
        return new Promise((resolve, reject) => {
            let deferralUrl = backEndServerURL + "/case/deferral/" + appId;
            axios.get(deferralUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let tableArray = [];
                    let status = "";
                    response.data.map((deferal) => {
                        if (deferal.status === "APPROVAL_WAITING") {
                            status = "Waiting For Approval"
                        } else if (deferal.status === "NOTAPPROVED") {
                            status = "NOT APPROVED"
                        } else {
                            status = deferal.status;
                        }
                        tableArray.push(this.deferralTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));
                    });
                    resolve(tableArray)
                })
                .catch(error => reject(error));
        })
    };

    static getRemarkDetails = (appId) => {
        return new Promise((resolve, reject) => {
            let remarkUrl = backEndServerURL + "/appRemarkGet/" + appId;
            axios.get(remarkUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let remarksArray = [];
                    response.data.map((data) => {
                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                    });
                    resolve(remarksArray)
                })
                .catch(error => reject(error));
        })
    };

    static getRemarkDetailsNew = (appId, url) => {
        return new Promise((resolve, reject) => {
            let remarkUrl = url + "/appRemarkGet/" + appId;
            axios.get(remarkUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let remarksArray = [];
                    response.data.map((data) => {
                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                    });
                    resolve(remarksArray)
                })
                .catch(error => reject(error));
        })
    };

    static getVariables = (appId) => {
        return new Promise((resolve, reject) => {
            let url = backEndServerURL + "/variables/" + appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response))
                .catch(error => reject(error));
        })
    };

    static getVariablesNew = (appId, url) => {
        return new Promise((resolve, reject) => {
            axios.get(url + "/variables/" + appId, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response))
                .catch(error => reject(error));
        })
    };
    static setVariablesAwf = (postData,appId) => {
        return new Promise((resolve, reject) => {
            axios.post(assetOpsBackEndServerURL + "/variables/" + appId, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response))
                .catch(error => reject(error));
        })
    };

    static searchDedupWithSDN = (accountNumber) => {
        return new Promise((resolve, reject) => {
            let tableArray = [];
            let postData = {
                "cbNumber": "",
                "nid": "",
                "passport": "",
                "customerName": "",
                "dob": "1000-01-01",
                "gender": "",
                "fathersName": "",
                "mothersName": "",
                "email": "",
                "nationality": "",
                "phone": "",
                "tin": "",
                "registrationNo": "",
                "constitutionType": "",
                "accountNumber": "",
                "sources": ["FINACLE", "ABABIL", "TRANZWARE"],
            };
            postData.accountNumber = accountNumber;
            let stateValue = {};
            let url = backEndServerURL + "/dedupWithSdn";
            axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.data.sdnResult !== null && Array.isArray(response.data.sdnResult)) {
                        let Dedupurl = backEndServerURL + "/dedup/save";
                        axios.post(Dedupurl, {
                            "individualDedupData": response.data,
                            "dedupType": "INDIVIDUAL"
                        }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                stateValue.individualDedupData = response.data
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                        response.data.sdnResult.map((data) => {
                            tableArray.push(this.createSdnTableData(data.country, data.dob, data.matchType, data.name, data.type));
                            stateValue.sdnData = tableArray;
                        });
                    }
                    resolve(stateValue)
                })
                .catch(error => reject(error));
        })
    };

    static deferralTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {
        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])
    };

    static createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    static createSdnTableData = (country, dob, matchType, name, type) => {
        return ([name, dob, matchType, type, country])
    };

}

export default CommonApi
