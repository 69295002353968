import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import CommonJsonFormComponent from '../../JsonForm/CommonJsonFormComponent';
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import 'semantic-ui-offline/semantic.min.css';
import theme from "../../JsonForm/CustomeTheme2";
import loader from "../../../Static/loader.gif";
import functionForFileDownload from '../../DeliverableManagement/FunctionForFileDownload';

const accountOpeningReportJsonForm = [
    {
        varName: "user",
        type: "text",
        label: "User Id",
        grid: 2,
        lowerCase: true,
        readOnly: false
    },
    {
        varName: "fromDate",
        type: "date",
        label: "Start Date",
        grid: 2,
        errorMessage: "Error",
    },

    {
        varName: "toDate",
        type: "date",
        label: "End Date",
        grid: 2,
        errorMessage: "Error",
    },
    {
        varName: "format",
        type: "select",
        enum:[
          "pdf",
          "xlsx"
         ],
        label: "Format",
        grid: 2,
        errorMessage: "Error",
    },

];
const bondSonchoyForm = [
    {
        varName: "user",
        type: "text",
        label: "User Id",
        grid: 2,
        lowerCase: true,
        readOnly: false
    },
    {
        varName: "fromDate",
        type: "date",
        label: "Start Date",
        grid: 2,
        errorMessage: "Error",
    },

    {
        varName: "toDate",
        type: "date",
        label: "End Date",
        grid: 2,
        errorMessage: "Error",
    },
    // {
    //     varName: "format",
    //     type: "select",
    //     enum:[
    //         "xlsx"
    //     ],
    //     label: "Format",
    //     grid: 2,
    //     errorMessage: "Error",
    // },

];



const jsonFormList = [accountOpeningReportJsonForm, accountOpeningReportJsonForm, accountOpeningReportJsonForm,
    accountOpeningReportJsonForm, accountOpeningReportJsonForm, accountOpeningReportJsonForm, accountOpeningReportJsonForm,
    bondSonchoyForm, accountOpeningReportJsonForm, accountOpeningReportJsonForm, accountOpeningReportJsonForm,
    accountOpeningReportJsonForm, accountOpeningReportJsonForm];
const urlNameList = ['accountOpeningReport', 'fdrDpsOpeningReport', 'accountMaintenanceReport',
    'dormantAccountActivationReport', 'instantDebitCardAllocationReport', 'instaPackAllocationReport', 'pendingCaseReport',
    'bondSanchayPatraReport', 'accountWithAvailedFacilitiesReport', 'pendingDeferralReport', 'fundTransferReport',
    'chequebookAcknowledgementReport', 'dormantReactivationReport'];
const headerNameList = ['Account Opening Report', 'FDR OR DPS Opening Report', 'Account Maintenance Report',
    'Dormant Account Activation Report', 'Instant DebitCard Allocation Report', 'Insta Pack Allocation Report', 'Pending Case Report',
    'Bond SanchayPatra Report', 'Account With Availed Facilities Report', 'Pending Deferral Report', 'Fund Transfer Report',
    'Chequebook Acknowledgement Report', 'Dormant Reactivation Report'];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};


class LWFExcelReport extends Component {
    constructor(props) {
        super(props);
        console.log("0");
        this.state = {
            inputData: {},
            selectedDate: {},
            fileUploadData: {},
            responseData: [],
            responseActualArray: [],
            activeItem: 'All',
            backgroundColor: 'red',
            showValue: false,
            uploadModal: false,
            detailsModal: false,
            viewModal: false,
            caseId: "",
            title: "",
            dataPassArray: [],
            notificationType: "",
            notificationTitle: "",
            notificationMessage: "",
            alert: false,
            loader: null,
            dialogHeader: "",
            err: false,
            supportDocumentArray: [],
            loading: true,
            errorArray: {},
            errorMessages: {},
            autoComplete: {},
            pageNumber: 0,
            totalRow: 0,
            showData: true,
            varValue: {},
            selectedCount: 0,
            searchItem: "",
            baseUrl: backEndServerURL + '/',
            whichViewPosition: urlNameList.indexOf(this.props.match.params.position)

        };
    }

    componentDidMount() {
        console.log(this.state.whichViewPosition);
        console.log("2");
        this.setState({
            loading: false,
        })
        // if(this.state.whichViewPosition === -1) return 0;
       this.state.inputData.format="xlsx"

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.match !== undefined) {
            if (this.props.match.params.position !== prevProps.match.params.position) {
                this.setState({
                    inputData: {},
                    varValue: {},
                    selectedDate: {},
                    err: false,
                    errorArray: {},
                    errorMessages: {},
                    whichViewPosition: urlNameList.indexOf(this.props.match.params.position),
                    loading: true,
                    loader: true,
                });
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    })
                }, 1000);
            }
        }

    }

    updateComponent = () => {
        this.forceUpdate();

    };


    open = (data) => {
        this.setState({
            dataPassArray: data,
            viewModal: true
        })
    };

    closeModal = () => {
        this.setState({
            viewModal: false
        });
    };


    render() {

        const {rows, columns} = this.state;
        const {activeItem} = this.state;
        const {classes, onClose} = this.props;

        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <React.Fragment>

                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        margin: ' ',
                    }}>
                        <h5 style={{color: "black"}}>
                            {headerNameList[this.state.whichViewPosition]}
                        </h5>
                    </CardHeader>
                    <CardBody style={{
                        backgroundColor: "white"
                    }}>
                        <Grid container spacing={1}>
                            <ThemeProvider theme={theme}>

                                {this.renderFilterForm()}

                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'middle',
                                        display: "block",
                                        height: 30,
                                        marginTop: 20,
                                        paddingBottom: 5
                                    }}

                                    onClick={e => {
                                        this.searchData()
                                    }}

                                >
                                    Download
                                </button>
                            </ThemeProvider>

                        </Grid>
                        <br></br>
                    </CardBody>

                </React.Fragment>
            );
        }
    }

    searchData() {
        this.setState({
            loading: true
        });
        let inputObj = this.state.inputData;

        console.log(this.removeNullValue(inputObj));

        let download_url = this.state.baseUrl + urlNameList[this.state.whichViewPosition];
        let excelName = urlNameList[this.state.whichViewPosition] +"."+this.state.inputData.format;

        if (Object.keys(inputObj).length === 0 && inputObj.constructor === Object) {
            console.log('object null');
            this.setState({
                loading: false,
                alert: true,
                notificationType: 'error',
                notificationTitle: 'Data Not Available',
                notificationMessage: 'Please Give a Searching Data'
            });
            return 0
        }


        functionForFileDownload.downloadFile(download_url, 'post', this.removeNullValue(inputObj), excelName)
            .then((response) => {
                console.log('success');
                this.setState({
                    loading: false,
                });
                setTimeout(function () {
                    window.location.reload();
                })
            }).catch((error) => {
            console.log('error');
            this.setState({
                loading: false
            })
        });
    }

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === undefined || clone[prop] === "NaN-unNaNefined-d")
                delete clone[prop];
        return clone;
    };

    getDateString(date, format) {
        var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            getPaddedComp = function (comp) {
                return ((parseInt(comp) < 10) ? ('0' + comp) : comp)
            },
            formattedDate = format,
            o = {
                "y+": date.getFullYear(), // year
                "M+": months[date.getMonth()], //month
                "d+": getPaddedComp(date.getDate()), //day
                "h+": getPaddedComp((date.getHours() > 12) ? date.getHours() % 12 : date.getHours()), //hour
                "H+": getPaddedComp(date.getHours()), //hour
                "m+": getPaddedComp(date.getMinutes()), //minute
                "s+": getPaddedComp(date.getSeconds()), //second
                "S+": getPaddedComp(date.getMilliseconds()), //millisecond,
                "b+": (date.getHours() >= 12) ? 'PM' : 'AM'
            };

        for (var k in o) {
            if (new RegExp("(" + k + ")").test(format)) {
                formattedDate = formattedDate.replace(RegExp.$1, o[k]);
            }
        }
        return formattedDate;
    }

    renderFilterForm = () => {
        if (this.state.whichViewPosition !== -1) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, jsonFormList[this.state.whichViewPosition], this.updateComponent)
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.notificationType} stopNotification={this.stopNotification}
                              title={this.state.notificationTitle}
                              message={this.state.notificationMessage}/>
            )
        }
    }


}

export default withStyles(styles)(LWFExcelReport);
