import React, {Component} from "react";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../JsonForm/CustomeTheme2";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import Notification from "../NotificationMessage/Notification";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import MyValidation from "../JsonForm/MyValidation";
import Functions from "../../Common/Functions";

let Form = [
    {
        "varName": "username",
        "type": "text",
        "label": "User ID",
        "grid": 2,
        "lowerCase" : true,
        "required": true,
    },
    {
        "type": "empty",
        "grid": 12
    },
    {
        "varName": "password",
        "type": "password",
        "label": "Password",
        "grid": 2,
        "required": true,
    },
    {
        "type": "empty",
        "grid": 12
    }

];
let validation = [
    {
        "varName": "firstName",
        "type": "text",
        "label": "First Name",
        "grid": 2,
        "required": true,
    },
    {
        "type": "empty",
        "grid": 1
    },
    {
        "varName": "lastName",
        "type": "text",
        "label": "Last Name",
        "grid": 2,
        "required": true,
    },
    {
        "type": "empty",
        "grid": 1
    },
    {
        "varName": "username",
        "type": "text",
        "label": "User Name",
        "grid": 2,
        "required": true,
    },
    {
        "type": "empty",
        "grid": 1
    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "grid": 2,
        "validation": "email",
        "required": true,
    },
    {
        "type": "empty",
        "grid": 1
    },
    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile Number",
        "grid": 2,
        "validation": "phone",
        "required": true,
        "phonePrefix": true,
    },
    {
        "type": "empty",
        "grid": 1
    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "grid": 2,
        "validation": "email",
        "required": true,
    },
    {
        "type": "empty",
        "grid": 1
    },

    /* {
         "varName": "userMobileNumber",
         "type": "text",
         "label": "User Mobile Number",
         "grid": 2
     },
     {
         "type": "empty",
         "grid": 1
     },*/

];

let workStationRequestType = [];
let roleRequestType = [];
let otherRequestType = [];

class ResetPasswordAdmin extends Component {
    state = {
        inputData: {},
        selectedDate: {},
        getData: false,
        workstation: false,
        role: false,
        showValue: false,
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},

        err: false,
        varValue: [],
        errorArray: {},
        errorMessages: {},
        title: "",
        notificationMessage: "",
        errorType: "success",
        alert: false,
        otherRequestTitle: ""
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };
    handleSubmit = (event) => {
        event.preventDefault();
        let data = {};
        console.log(this.state.inputData);
        let dependencyField = Form;
        let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
        this.forceUpdate();
        console.log(this.state.inputData);

        console.log("Not working");
        if (error === true) {
            return 0;
        }

        data.username = this.state.inputData.username;
        data.password = this.state.inputData.password;

        console.log(data);
        let url = backEndServerURL + "/resetPasswordAdmin";
        axios.post(url, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                if (response.data === true) {
                    this.setState({
                        title: "Successfull!",
                        notificationMessage: "Successfully Change!",
                        alert: true
                    });
                    window.location.reload()

                } else {
                    this.setState({
                        title: "Error!",
                        errorType: "error",
                        notificationMessage: response.data,
                        alert: true
                    })

                }


            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    title: "Error!",
                    errorType: "error",
                    notificationMessage: Functions.errorObjectCheck(error),
                    alert: true
                })
            })
    };
    renderButton = () => {

        return (

            <button
                className="btn btn-outline-primary  btn-sm"
                style={{
                    marginTop: "18px"
                }}
                onClick={this.handleSubmit}

            >
                Reset Password
            </button>


        )


    };


    updateComponent = () => {
        this.forceUpdate();
    };

    returnJsonForm = () => {
        return (
            CommonJsonFormComponent.renderJsonForm(this.state, Form, this.updateComponent)
        )

    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.errorType} stopNotification={this.stopNotification}
                              title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h6>Edit User<a><CloseIcon onClick={this.close} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h6>
            )
        } else {
            return (
                <h4 className="text-white"> Password Reset </h4>
            )
        }
    };

    render() {
        return (

            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderHeader()}

                </CardHeader>
                <CardBody>
                    <div>

                        <ThemeProvider theme={theme}>

                            <Grid container>

                                {this.renderNotification()}


                                {this.returnJsonForm()}


                                <Grid item xs={2}>
                                    {this.renderButton()}
                                </Grid>

                            </Grid>

                        </ThemeProvider>


                    </div>


                </CardBody>
            </Card>


        )
    }

}

export default ResetPasswordAdmin;