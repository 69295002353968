import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import theme from "../../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import LabelDetailsModal from "../../Maintenance/LabelDetails";
import {Dialog} from "@material-ui/core";

class FileRequisitionLabel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            inputData: {
                ...this.props.data,
            },
            showValue: true,
            labelGrid: this.props.labelGrid
        }
    }


    componentDidMount() {

    }

    labelDetails = (status) => {
        this.setState({
            labelName: status,
            labelOpen: true
        })
    };

    labelCloseModal = () => {
        this.setState({
            labelOpen: false
        })
    };

    render() {
        const {classes} = this.props.classes;

        return (

            <div style={{
                "border-top-style": "ridge",
                "border-bottom-style": "ridge",
                paddingLeft: this.props.padding === 10 ? "15px" : '5px'
            }}>
                <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        <Grid item xs={12}>

                        </Grid>

                        <Grid item xs={this.state.labelGrid[0]}>
                            <label><font size="2"><b> File Reference No :</b></font></label>

                            {this.state.inputData.fileReferenceNo}
                        </Grid>

                        <Grid item xs={this.state.labelGrid[1]}>
                            <label><font size="2"><b> Applicant Name :</b></font></label>

                            {this.state.inputData.applicantName}
                        </Grid>
                        <Grid item xs={this.state.labelGrid[2]}>
                            <label><b><font size="2">Asset Ops Number :</font></b> </label>
                            {this.state.inputData.assetOpsNumber}
                        </Grid>

                        <Grid item xs={this.state.labelGrid[3]}>
                            <label><font size="2"><b> Account Number :</b></font></label>

                            {this.props.accountNumber}
                        </Grid>

                        <Grid item xs={this.state.labelGrid[4]}>
                            <label><b><font size="2">Requisition Initiator :</font></b> </label>
                            {this.state.inputData.requisitionBy}
                        </Grid>

                        <Grid item xs={this.state.labelGrid[5]}>
                            <label><font size="2"><b> Requisition Date :</b></font></label>

                            {this.state.inputData.requisitionDate}
                        </Grid>


                    </ThemeProvider>
                </Grid>

                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.labelOpen}>
                    <DialogContent className={classes.dialogPaper}>
                        <LabelDetailsModal closeModal={this.labelCloseModal} appId={this.state.appId}
                                           labelName={this.state.labelName} data={this.state.inputData}/>

                    </DialogContent>
                </Dialog>
            </div>

        );
    }


}

export default FileRequisitionLabel;