import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Grow from "@material-ui/core/Grow";
import {ThemeProvider} from "@material-ui/styles";
import TextFieldComponent from "../../JsonForm/TextFieldComponent";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import SelectComponent from "../../JsonForm/SelectComponent";
import CloseIcon from '@material-ui/icons/Close';
import Table from "../../Table/Table";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import AccountNoGenerate from "../AccountNoGenerate";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import FileMappingEdit from "../CommonComponent/FileMappingEdit";
import FileMapping from "../CommonComponent/FileMapping";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import loader from "../../../Static/loader.gif";
import CheckBox from "@material-ui/core/Checkbox/Checkbox";
import ErrorModal from "../CommonComponent/ErrorModal";
import DateComponentDeferal from "../../JsonForm/DateComponentDeferal";
import WaiverList from "../CommonComponent/WaiverList";
import SignatureButton from "../Maintenance/SignatureButton";
import SubmittedCaseHistory from "../CommonComponent/SubmittedCaseHistory";
import ConfirmAlertImage from "../CommonComponent/ConfirmAlertImage";
import AccountStatement from "./AccountStatement";
import FunctionForInputGetAndSet from "./FunctionForInputGetAndSet";
import functionForFileDownload from "../../DeliverableManagement/FunctionForFileDownload";
import Button from "@material/react-button";
import SdnAndDedupeResultShowing from "../CommonComponent/SdnAndDedupeResultShowing";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";


let dependencyFieldJsonForm = [
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "Cb Number",
        "grid": 2,
        "readOnly": true,
    },
    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name",
        "multiline": true,
        "grid": 2,
        "readOnly": true,
    },
    {
        "varName": "nameOfScheme",
        "type": "text",
        "required": true,
        "label": "Name Of Scheme",
        "grid": 2,
    },
    {
        "varName": "schemeProductCode",
        "type": "text",
        "required": true,
        "label": "Scheme/Product Code",
        "grid": 2,
    },
    {
        "varName": "periodOfScheme",
        "type": "text",
        "required": true,
        "label": "Period Of Scheme",
        "grid": 2,
    },
    {
        "varName": "amountPerInstallment",
        "type": "text",
        "required": true,
        "label": "Amount Per Installment",
        "grid": 2,
    },
    {
        "varName": "installmentStartDate",
        "type": "date",
        "label": "Installment Start Date",
        "grid": 2,
    },
    {
        "varName": "acOpenDate",
        "type": "date",
        "minDate": true,
        "label": "Ac Open Date",
        "grid": 2,
    },
    {
        "varName": "interestRate",
        "type": "text",
        "readOnly": true,
        "label": "Interest Rate",
        "grid": 2,
    },
    {
        "varName": "payableAtMaturity",
        "type": "text",
        "readOnly": true,
        "label": "Payable At Maturity",
        "grid": 2,
    },
    {
        "varName": "maturityDate",
        "type": "date",
        "label": "Maturity Date",
        "grid": 2,
    },
    {
        "varName": "debitAccountNumber",
        "type": "text",
        "required": true,
        "label": "Debit Account Number",
        "grid": 2,
    },
    {
        "varName": "balance",
        "type": "text",
        "label": "Balance ",
        "grid": 2,
    },
    {
        "varName": "eTin",
        "type": "text",
        "readOnly": true,
        "label": "E Tin",
        "grid": 2,
    },
    {
        "varName": "occupationCode",
        "type": "text",
        "readOnly": true,
        "label": "Occupation Code",
        "grid": 2,
    },
    {
        "varName": "sectoreCode",
        "type": "text",
        "label": "Sectore Code",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "rmCode",
        "type": "text",
        "label": "Rm Code",
        "grid": 2,
    },
    {
        "varName": "depositTypeCode",
        "type": "text",
        "label": "Deposit Type Code",
        "grid": 2,
    },
    {
        "varName": "noOfNewNominee",
        "type": "text",
        "label": "No Of New Nominee",
        "grid": 2,
    },
    {
        "varName": "sameAsOperativeAccount",
        "type": "checkbox",
        "label": "Same As Operative Account",
        "grid": 2,
    },
    {
        "varName": "standingAccountFrequency",
        "type": "date",
        "label": "Standing Account Frequency",
        "grid": 2,
    },
    {
        "varName": "nextExecutionDate",
        "type": "date",
        "label": "Next Execution Date",
        "grid": 2,
    },
];
const tenureDay = [

    {title: '90'},
    {title: '180'},
    {title: '182'},
    {title: '362'},
    {title: '365'},


];
const tenureMonth = [

    {title: '1'},
    {title: '3'},
    {title: '6'},
    {title: '12'},
    {title: '24'},
    {title: '36'}


];
const arrayListIndividual = [
    {label: 'AOF1'},
    {label: 'AOF2'},
    {label: 'AOF3'},
    {label: 'AOF4'},
    {label: 'AOF5'},
    {label: 'AOF6'},
    {label: 'AOF7'},
    {label: 'AOF8'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'TP'},
    {label: 'IIF1'},
    {label: 'IIF2'},
    {label: 'NID'},
    {label: 'PASSPORT'},
    {label: 'DRIVING LICENSE'},
    {label: 'BIRTH CERTIFICATE'},
    {label: 'NOMINEE NID 1'},
    {label: 'NOMINEE NID 2'},
    {label: 'NOMINEE NID 3'},
    {label: 'NOMINEE NID 4'},
    {label: 'NOMINEE NID 5'},
    {label: 'NOMINEE NID 6'},
    {label: 'NOMINEE PASSPORT 1'},
    {label: 'NOMINEE PASSPORT 2'},
    {label: 'NOMINEE PASSPORT 3'},
    {label: 'NOMINEE PASSPORT 4'},
    {label: 'NOMINEE PASSPORT 5'},
    {label: 'NOMINEE DRIVING LICENSE 1'},
    {label: 'NOMINEE DRIVING LICENSE 2'},
    {label: 'NOMINEE DRIVING LICENSE 3'},
    {label: 'NOMINEE DRIVING LICENSE 4'},
    {label: 'NOMINEE DRIVING LICENSE 5'},
    {label: 'NOMINEE BIRTH CERTIFICATE 1'},
    {label: 'NOMINEE BIRTH CERTIFICATE 2'},
    {label: 'NOMINEE BIRTH CERTIFICATE 3'},
    {label: 'NOMINEE BIRTH CERTIFICATE 4'},
    {label: 'NOMINEE BIRTH CERTIFICATE 5'},
    {label: 'OTHERS'},

];
let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];
var deferalOther =
    {
        "varName": "deferalOther",
        "type": "text",
        "label": "Remarks",

    };
var waiverOther =
    {
        "varName": "waiverOther",
        "type": "text",
        "label": "Remarks",

    };

let typeofDeferralDocs = {
    "varName": "typeofDeferralDocs",
    "type": "select",
    "label": "Type of Deferral Docs",
    "enum": [
        "Regulatory",
        "Non Regulatory",
    ],
};
let typeofWaiverDocs = {
    "varName": "typeofWaiverDocs",
    "type": "select",
    "label": "Type of Waiver Docs",
    "enum": [
        "Regulatory",
        "Non Regulatory",
    ],
};
let deferal = {};
let waiver = {};

var date = {
    "varName": "expireDate",
    "type": "text",
    "minDate": true,
    "label": "Expire Date",

};
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

function Transition(props) {
    return <Grow in={true} timeout="auto" {...props} />;
}

function makeRequiredObject(object) {
    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject;

    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["required"] = true;
    }
    return returnObject;


}


class AccountEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            SelectedData: false,
            csDeferalPage: "",
            values: [],
            waiverValues: [],
            appId: '',
            csDataCapture: '',
            message: "",
            appData: {},
            getData: false,
            getNewCase: false,
            varValue: {
                csDeferal: "NO",
                accountType: "INSTAPACK",
                priority: "GENERAL"
            },
            caseId: "",
            title: "",
            notificationMessage: "",
            app_uid: "-1",
            alert: false,
            redirectLogin: false,
            accountStatement: false,
            type: [],
            phoneNumCountryCodeList: [],
            dueDate: '',
            inputData: {
                csDeferal: "NO",
                accountType: "INSTAPACK",
                priority: "GENERAL"
            },
            fileUploadData: {},
            selectedDate: {},
            dropdownSearchData: {},
            AddDeferal: false,
            debitCard: "",
            showValue: false,
            getDeferalList: [],
            deferalNeeded: false,
            uploadModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            getInitiateData: false,
            loaderNeeded: null,
            IndividualDedupModal: false,
            branchAllSolId: [],
            JointDedupModal: false,
            individualDataSaveId: '',
            jointDataSaveId: '',
            companyDataSaveId: '',
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            errorModalBoolean: false,
            errorTittle: "",
            loading: false,
            getSchemeFlag: false,
            getAllSchemeCode: [],
            city: [],
            state: [],
            currency: [],
            country: [],
            cbDataBoolean: false,
            debitDataBoolean: false,
            generateNewAccountNumber: "",
            SDRegulatory: [],
            SDNonRegulatory: [],
            confirmAlert: false,
            fdrImageShowPopUp: false,
            getAccountInfo: false,
            nameOfSchemeApiCall: false,
            actionType: "",
            notificationMessgaeCommon: "",
            notificationMessgaeCommonFlag: false,
            subsectorList: [],
            sectorList: [],
            accountBalanceInfo: false,
            getAccountType: false,
            cbsCustomerGet: false,
            history: false,
            accountNumber: "",
            solId: "",
            customerName: "",
             getcustomerId: "",
            SdnAndDedupeResultShowing: false,

        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (event, value) => {

        this.state.inputData["csDeferal"] = value;
        this.updateComponent();
        if (value === "YES") {

            let values = [];
            values.push(Math.floor(Math.random() * 100000000000));

            this.setState({values: values, deferalNeeded: true});

        } else {
            this.setState({
                values: [],
                deferalNeeded: false
            })
        }
    };


    addDeferalForm() {

        return this.state.values.map((el, i) =>
            <React.Fragment>
                <Grid item xs={2}>
                    {
                        this.dynamicTypeOfDeferral(el)
                    }
                </Grid>
                <Grid item xs={4}>
                    {
                        this.dynamicDeferral(el)
                    }
                </Grid>
                <Grid item xs={2}>
                    {
                        this.dynamicDate(el)
                    }

                </Grid>
                <Grid item xs={2}>
                    {this.dynamicDeferralOther(el)}

                </Grid>
                <Grid item xs={2}>
                    <button
                        style={{
                            float: "left",
                            marginTop: "8px"
                        }}
                        className="btn btn-outline-primary"
                        type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                    >
                        Remove
                    </button>


                </Grid>
            </React.Fragment>
        )


    };

    addWaiverForm() {

        return this.state.waiverValues.map((el, i) =>
            <React.Fragment>
                <Grid item xs={2}>
                    {
                        this.dynamicTypeOfWaiver(el)
                    }
                </Grid>
                <Grid item xs={4}>
                    {
                        this.dynamicWaiver(el)
                    }
                </Grid>

                <Grid item xs={3}>
                    {this.dynamicWaiverOther(el)}

                </Grid>

                <Grid item xs={3}>
                    <button
                        style={{
                            float: "left",
                            marginTop: "8px"
                        }}
                        className="btn btn-outline-primary"
                        type='button' value='remove' onClick={this.removeClickWaiver.bind(this, el)}
                    >
                        Remove
                    </button>


                </Grid>

            </React.Fragment>
        )


    };

    getSearchvalue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'null' || clone[prop] === 'undefined')
                delete clone[prop];
        return clone;
    };
    DedupDataSaveApi = (subServiceType) => {
        subServiceType = this.subServiceTypeDataSet();
        if (subServiceType === "INDIVIDUAL" || subServiceType === "Individual A/C") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "mandateDedupData": this.props.mandateindividualDedupData,
                "beneficiaryDedupData": this.props.beneficiaryindividualDedupData,
                "individualDedupData": this.props.individualDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(" dedup save data");
                    console.log(response.data);
                    this.setState({
                        individualDataSaveId: response.data
                    })
                })
                .catch((error) => {
                    console.log(error)
                })

        } else if (subServiceType === "Joint Account") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {

                "jointDedupData": this.props.jointDedupData,
                "mandateDedupData": this.props.mandateindividualDedupData,
                "beneficiaryDedupData": this.props.beneficiaryindividualDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(" dedup save data");
                    console.log(response.data);
                    this.setState({
                        jointDataSaveId: response.data
                    })
                })
                .catch((error) => {
                    console.log(error)
                })

        } else if (subServiceType === "Proprietorship A/C" || subServiceType === "NONINDIVIDUAL") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "mandateDedupData": this.props.mandateindividualDedupData,
                "beneficiaryDedupData": this.props.beneficiaryindividualDedupData,
                "individualDedupData": this.props.individualDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    /*axios.post(Dedupurl, {
                        "companyDedupData": this.props.companyDedupData,
                        "dedupType": subServiceType
                    }, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
                        .then((response) => {
                            this.setState({
                                companyDataSaveId: response.data
                            })
                        })
                        .catch((error) => {
                             console.log(error)
                        })*/
                    console.log(" dedup save data r");
                    console.log(response.data);
                    this.setState({
                        individualDataSaveId: response.data
                    })
                })
                .catch((error) => {
                    console.log(error)
                })

        } else if (subServiceType === "Company Account") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "jointDedupData": this.props.jointDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    axios.post(Dedupurl, {
                        "companyDedupData": this.props.companyDedupData,
                        "dedupType": subServiceType
                    }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            this.setState({
                                companyDataSaveId: response.data
                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        });
                    console.log(" dedup save data");
                    console.log(response.data);
                    this.setState({
                        jointDataSaveId: response.data
                    })
                })
                .catch((error) => {
                    console.log(error)
                })

        } else {
            alert("please type select")
        }
    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    apiDataSet = (data, inputData) => {
        /* if (this.props.AccountType === 'FDR Account Opening - with Existing CB Only') {
              inputData.cbNumber= ""
             inputData.customerName= ""
             inputData.nid= ""
             inputData.passport= ""
             inputData.registrationNo= ""
             inputData.tin= ""
             inputData.mobileNumber= ""
             inputData.email= ""
             inputData.communicationAddress1= ""
             inputData.communicationAddress2= ""
             inputData.city= ""
             inputData.state= ""
             inputData.postalCode= ""
             inputData.country= ""
             inputData.debitAccountNumber= ""
             inputData.debitAccountName= ""
             inputData.transactionID= ""
             inputData.transactionDate= ""
             inputData.availableAmount= ""
             inputData.currency= ""
             inputData.accountRestriction= ""
             inputData.businessSegment= ""

         }
         else*/
        if (this.props.AccountType === 'FDR Account Opening - with Existing Operative Account') {
            inputData.cbNumber = "";
            inputData.customerName = "";
            //inputData.debitAccountNumber=this.props.accountNo
            inputData.accountNumber = this.props.accountNo;
            inputData.customerId = "";
            inputData.debitAccountName = "";
            inputData.availableAmount = "";
            inputData.accountRestriction = "";
            inputData.currency = ""
            /*   inputData["communicationAddress"] = (response.data.address === null || response.data.address === 'null') ? '' : response.data.address;
              inputData["occupationCode"] = (response.data.occupation === null || response.data.occupation === 'null') ? '' : response.data.occupation;
              inputData["phoneNumber"] = (response.data.phone === null || response.data.phone === 'null') ? '' : response.data.phone;
              inputData["customerName"] = (response.data.name === null || response.data.name === 'null') ? '' : response.data.name;
              inputData["etinNo"] = (response.data.tinNumber === null || response.data.tinNumber === 'null') ? '' : response.data.tinNumber;
              inputData["subSectorCode"] = (response.data.subSector === null || response.data.subSector === 'null') ? '' : response.data.subSector;
              inputData["sectorCode"] = (response.data.sector === null || response.data.sector === 'null') ? '' : response.data.sector;
              inputData["availableBalance"] = (response.data.availableBalance === null || response.data.availableBalance === 'null') ? '' : response.data.availableBalance;
              inputData["csDeferal"] = "NO";
              inputData["accountSource"] = "NO";
              inputData["doubleTennor"] = "Monthly";
              inputData["doubleSiMaturity"] = "No";
              inputData["monthSiMaturity"] = "Yes";
              inputData["monthMaturityYes"] = "Renew Principal Only and Credit Interest to the Account No";
              inputData["tennor"] = "Day";
              inputData["customerId"] = "CB5984143";
              inputData["cbNumber"] = "CB5984143";
              inputData["monthTennor"] = "Monthly";
              inputData["doubleSiMaturity"] = "No";
              inputData["monthSiMaturity"] = "Yes";
              inputData["tennor"] = "Day";
              inputData["monthMaturityYes"] = "Renew Principal Only and Credit Interest to the Account No";
              inputData.depositTypeCode = "181";
              inputData.debitAccountNumber = "23456789";
              inputData.debitAccountNumberPre = "23456789";
              inputData.depositTypeCode = "181";
              inputData.debitAccountNumber = "23456789";
              inputData.debitAccountNumberPre = "23456789";*/
        } else if (this.props.AccountType === "DPS Account Opening -with Existing Operative Account") {
            inputData.cbNumber = "";
            inputData.customerName = "";
            inputData.accountNumber = this.props.accountNo;
            inputData.debitAccountName = "";
            inputData.availableAmount = "";
            inputData.accountRestriction = "";
            inputData.currency = "";
            inputData.businessSegment = "";
            inputData.dpsValueDate = "";
            inputData.nameOfScheme = "";
            inputData.periodOfScheme = "";
            inputData.dpsSchemeCode = "";
            inputData.amountPerInstallment = "";
            inputData.installmentStartDate = "";
            inputData.payableAtMaturity = "";
            inputData.maturityDate = "";
            inputData.rate = "";
            inputData.tinNo = "";
            inputData.depositType = "";
            inputData.occupationCode = "";
            inputData.sbsCode = "";
            inputData.rmCode = "";
            inputData.rmCodeName = "";
            inputData.nomineeSameOperativeAccount = "";
            inputData.nomineeName = "";
            inputData.relationshipWithNominee = ""
        }
        return inputData;
    };


    dynamicApiCall = (fieldName, apiType) => {
        let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                if (apiType === "fdrSchemeCode") {
                    let jsonArrayServiceList = this.props.serviceList;

                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "fdrSchemeCode") {
                            this.props.serviceList[i].enum = response.data;
                            console.log("cccccccccccccccccccccccccccc");
                            console.log(this.props.serviceList[i])

                        }
                    }
                } else if (apiType === "sectorCode") {
                    let jsonArrayServiceList = this.props.serviceList;
                    if (this.props.freeFlag1 !== "DPS Account Opening -with Existing Operative Account") {

                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "sectorCode") {
                                this.props.serviceList[i].enum = response.data;
                                console.log("cccccccccccccccccccccccccccc");
                                console.log(this.props.serviceList[i])

                            }
                        }
                    } else {
                        jsonArrayServiceList = this.props.commonJsonForm;

                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "sectorCode") {
                                this.props.commonJsonForm[i].enum = response.data;
                                console.log("cccccccccccccccccccccccccccc");
                                console.log(this.props.commonJsonForm[i])

                            }
                        }
                    }

                } else if (fieldName === "phoneCountryCode") {

                    {
                        this.findByVarNameApiData("countryCodeIndividual", response.data)
                    }
                    {
                        this.findByVarNameApiData("countryCodeIndividual2", response.data)
                    }
                    this.setState({
                        phoneNumCountryCodeList: response.data
                    })
                } else if (apiType === "agentBankingOutlet") {
                    this.findByVarNameApiData("freeCode1SpecialTaxagentOutlet", response.data);
                    this.findByVarNameApiData("dpsfreeCode1SpecialTaxagentOutlet", response.data);
                } else {
                    {
                        this.findByVarNameApiData(fieldName, response.data)
                    }
                    {
                        this.setStateapiEnumValue(apiType, response.data)
                    }
                }


            })
            .catch((error) => {
                console.log(error);
            })
    };
    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = this.props.commonJsonForm;

        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                this.props.commonJsonForm[i].enum = getValue;

            }
        }

        /*   jsonArray = this.props.commonJsonFormLast;
           for (let i = 0; i < jsonArray.length; i++) {
               let jsonObject = jsonArray[i];
               if (jsonObject.varName === searchVarname) {
                   this.props.commonJsonFormLast[i].enum = getValue;

               }
           }*/
        jsonArray = this.props.serviceList;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                this.props.serviceList[i].enum = getValue;

            }
        }
        jsonArray = this.props.serviceListLast;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                this.props.serviceListLast[i].enum = getValue;

            }
        }


    };

    apiDataSet = (data, inputData, accountRelated, category) => {
        if (data !== undefined && data !== null && data !== "") {

            if (category === 'CASA' && accountRelated === "DPS Account Opening -with Existing Operative Account") {
                inputData.solId = this.props.solId;
                inputData.cifId = inputData.cbNumber;
                inputData.schemeCode = inputData.dpsdpsSchemeCode;
                inputData.ccy = inputData.currency;
                inputData.accountName = inputData.customerName;
                inputData.accountOpeningDate = inputData.dpsfdValueDate;

                if (data.ETINBLOCK !== undefined) {
                    inputData.withholdingTaxPcnt = 10
                } else {
                    inputData.withholdingTaxPcnt = 15
                }
                if (inputData.nomineeExistNot === "Y") {
                    this.setState({
                        nomineeExistNot: true
                    })
                }
                inputData.nomineeExistNot = inputData.nomineeTypes;
                inputData.nomineeType = inputData.nomineeTypes;
                inputData.clearingLimitDR = inputData.clearingLimitDRs;
                inputData.clearingLimitCR = inputData.clearingLimitCRs;
                inputData.TransferLimitCR = inputData.TransferLimitCRs;
                inputData.maturity = inputData.maturitys;
                inputData.valueDate = inputData.dpsfdValueDate;
                inputData.depositAmount = inputData.dpsamount;
                inputData.depositTenure = inputData.dpstenureType;
                inputData.depositPeriodMonthsdays = inputData.dpstenure;
                inputData.maturityDate = inputData.dpsfdMaturityDate;
                //inputData.maturityValue =    //input field text
                inputData.paymentAccountId = inputData.debitAccountNumber;  //repayment account id
                inputData.sectorCode = inputData.sectorCode;
                inputData.sectorCodeApiCall = true;
                inputData.subSectorCode = inputData.subSectorCode;
                inputData.occupationCode = inputData.occupationCode;
                //inputData.freeCode1SpecialTaxagentOutlet= inputData.
                inputData.freeCode3RmCode = inputData.rmCode;
                inputData.rmCodeApiCall = "YES";
                inputData.rmCodeName = inputData.rmCodeName;
                inputData.repaymentAccounts = inputData.debitAccountNumber;
                inputData.repaymentsName = inputData.customerName;
                inputData.repaymentsAccountApiCall = "YES";
                if (inputData.accountType === "Monthly Interest Paying FD") {
                    inputData.freeCode6 = 8
                } else {
                    inputData.freeCode6 = 4
                }

                inputData.rmCodeName = inputData.rmCodeName;
                inputData.freeCode7DepositCode = inputData.dpsdepositType;
                if (data.ETINBLOCK !== undefined) {
                    inputData.etinNumber = data.ETINBLOCK.referenceNum;
                    inputData.etin = data.ETINBLOCK.referenceNum
                }
            }
            else if (accountRelated !== "DPS Account Opening -with Existing Operative Account") {

                inputData.solId = this.props.solId;
                inputData.cifId = inputData.cbNumber;
                if (inputData.currencyNtb !== undefined) {
                    inputData.ccy = this.findByVarNameGetKeyValue(inputData.currencyNtb, this.state.currency)

                } else if (category === 'CASA') {
                    inputData.ccy = inputData.currency
                } else {
                    inputData.ccy = this.findByVarNameGetKeyValue(inputData.currency, this.state.currency)

                }

                inputData.schemeCode = inputData.fdrSchemeCode;
                inputData.acName = inputData.customerName;
                inputData.shortName = data.shortName;
                inputData.acOpeningDate = inputData.fdValueDate;
                inputData.interestCreditAccount = inputData.interestCreditAccount;

                if (data.ETINBLOCK !== undefined) {
                    inputData.withholdingTaxPcnt = 10
                } else {
                    inputData.withholdingTaxPcnt = 15
                }
                inputData.valueDate = inputData.fdValueDate;
                inputData.depositinstallAmount = inputData.amount;
                inputData.depositTenure = inputData.tenureType;
                inputData.depositPeriodMonthsdays = inputData.tenure;
                inputData.maturityDate = inputData.fdMaturityDate;
                // inputData.maturityValue= inputData.
                inputData.repaymentAcId = inputData.repaymentAccount;
                if (inputData.nomineeExistNot === "Y") {
                    this.setState({
                        nomineeExistNot: true
                    })
                }
                inputData.autoRenewal = inputData.autoRenewals;

                if (inputData.autoRenewals === "YES") {
                    inputData.autoClosure = "NO"
                } else if (inputData.autoRenewals === "NO") {
                    inputData.autoClosure = "YES"
                }
                inputData.sectorCode = inputData.sectorCode;
                inputData.sectorCodeApiCall = true;
                inputData.subSectorCode = inputData.subSectorCode;
                inputData.occupationCode = inputData.occupationCode;
                //inputData.freeCode1SpecialTaxagentOutlet= inputData.
                inputData.freeCode3RmCode = inputData.rmCode;
                inputData.rmCodeApiCall = "YES";
                inputData.rmCodeName = inputData.rmCodeName;
                if (inputData.accountType === "Monthly Interest Paying FD") {
                    inputData.freeCode6 = 8
                } else {
                    inputData.freeCode6 = 4
                }

                inputData.freeCode7DepositCode = inputData.depositType;
                inputData.flowCode = "TO";
                inputData.accountId = this.props.solId + "0149148001";
                inputData.amtType = "F";
                // inputData.amt= inputData.
                //inputData.particulars= inputData.
                inputData.tranCreationDuring = "VERIFICATION";
                inputData.transactionType = "TRANSFER";
                inputData.debitAccountId = inputData.debitAccountNumber;
                if (data.ETINBLOCK !== undefined) {
                    inputData.etinNumber = data.ETINBLOCK.referenceNum;
                    inputData.etin = data.ETINBLOCK.referenceNum
                }

            }
            else if (accountRelated === 'DPS Account Opening -with Existing Operative Account') {

                inputData.solId = this.props.solId;
                inputData.cifId = inputData.cbNumber;
                inputData.dpsSchemeCode = inputData.schemeCode;//
                //inputData.currency
                inputData.ccy = this.findByVarNameGetKeyValue(inputData.currency, this.state.currency);

                inputData.accountName = inputData.customerName;
                inputData.shortName = data.shortName;
                inputData.accountOpeningDate = inputData.fdValueDate;

                if (data.ETINBLOCK !== undefined) {
                    inputData.withholdingTaxPcnt = 10
                } else {
                    inputData.withholdingTaxPcnt = 15
                }
                if (inputData.nomineeExistNot === "Y") {
                    this.setState({
                        nomineeExistNot: true
                    })
                }
                inputData.valueDate = inputData.fdValueDate;
                inputData.depositAmount = inputData.amount;
                inputData.depositTenure = inputData.tenureType;
                inputData.depositPeriodMonthsdays = inputData.tenure;
                inputData.maturityDate = inputData.fdMaturityDate;
                //inputData.maturityValue =    //input field text
                inputData.paymentAccountId = inputData.debitAccountNumber;  //repayment account id
                inputData.sectorCode = inputData.sectorCode;
                inputData.sectorCodeApiCall = true;
                inputData.subSectorCode = inputData.subSectorCode;
                inputData.occupationCode = inputData.occupationCode;
                //inputData.freeCode1SpecialTaxagentOutlet= inputData.
                inputData.freeCode3RmCode = inputData.rmCode;
                inputData.rmCodeApiCall = "YES";
                inputData.rmCodeName = inputData.rmCodeName;
                if (inputData.accountType === "Monthly Interest Paying FD") {
                    inputData.freeCode6 = 8
                } else {
                    inputData.freeCode6 = 4
                }
                inputData.repaymentAccounts = inputData.debitAccountNumber;
                inputData.repaymentsName = inputData.customerName;
                inputData.repaymentsAccountApiCall = "YES";
                inputData.rmCodeName = inputData.rmCodeName;
                inputData.freeCode7DepositCode = inputData.depositType;
                if (data.ETINBLOCK !== undefined) {
                    inputData.etinNumber = data.ETINBLOCK.referenceNum;
                    inputData.etin = data.ETINBLOCK.referenceNum
                }

            } else if (accountRelated === "FDR Account Opening - with Existing CB Only" || accountRelated === "FDR Account Opening -Without Operative Account-NTB") {
                inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = false;
                inputData.passportNumberpassportNumberUniqueIdApiCall = true;
                inputData.nationalIdCardNumberUniqueIdApiCall = true;
                inputData.birthCertificatebirthCertificateNumberUniqueIdApiCall = true;
                inputData.electronicTaxIdApiCall = true;
                inputData.misoccupationCodePriorityCodeStaff = data.occupationcode;
                inputData.missolId = data.branchCode;
                inputData.misstaffIdNumber = data.staffEmployeeID;
                inputData.misstaffIndicatorStatus = data.staffFlag;
                inputData.misccepCodeFreeCode9 = this.findByVarNameGetKeyValue(data.ccepcode, this.state.ccepCodeList);
                inputData.misprimarySolId = data.branchCode;
                inputData.basicInformationUpdateprimarySolId = data.branchCode;
                inputData.miscustomerNameLastName = data.customerName;
                inputData.miscustomerNameLastName = data.customerName;
                inputData.misshortName = data.shortName;
                inputData.misaCNumber = data.accountNumber;
                inputData.customerAccount = data.accountNumber;
                inputData.crmMissubSegment = this.findByVarNameGetKeyValue(data.subSegment, this.state.subSegmentList);
                inputData.mistitle = this.findByVarNameGetKeyValue(data.salutation, this.state.titleList);
                inputData.aCTitle = data.salutation;
                inputData.miscustomerStatusNormalStaffPriorityEtc = this.findByVarNameGetKeyValue(data.custStatus, this.state.customerStatusList);
                inputData.misconstitution = this.findByVarNameGetKeyValue(data.constitutionCode, this.state.constitutionList);
                inputData.mispriorityMonitoringRmCodeFreeCode3 = data.monitoringRMCode;
                inputData.misfunctionCodeForChequeBookWaiver = "A";
                inputData.aof1currencyCodeCcy = data.currency;
                inputData.misoccupationCodePriorityCodeStaff = data.occupationCode;
                inputData.aof1smsAlertFreeText11 = this.findByVarNameGetKeyValue(data.currencyCode, this.state.currency);
                inputData.schemeCodeChangefunctionForSchemeChangeInCaseOfInstaPack = "T";
                inputData.primryiintroducerStatus = this.findByVarNameGetKeyValue(data.statusOfIntroducer, this.state.introducerStatus);
                inputData.primryiintroducerNameLastName = data.nameOfIntroducer;
                inputData.basicInformationUpdategender = data.gender;
                inputData.basicInformationUpdatefatherNamePrefferedName = data.fatherName;
                inputData.basicInformationUpdatemotherNameMothersMaidenName = data.motherName;
                inputData.basicInformationUpdatemotherNameMothersMaidenName = data.motherName;
                inputData.basicInformationUpdatemaritalStatus = this.findByVarNameGetKeyValue(data.maritalStatus, this.state.maritalStatusList);
                inputData.basicInformationUpdatespouseNamePurgeRemarks = data.spouseName;
                let birthDate = data.birthDate;
                if (birthDate && birthDate !== -1) {
                    let date = (birthDate).split('T')[0].split("-");
                    inputData.basicInformationUpdatedob = `${date[1]}/${date[2]}/${date[0]}`;
                    setTimeout(() => {
                        let x = 18;
                        var currentDate = new Date();

                        let someDate = new Date();
                        let numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        let date = someDate.getDate();
                        let month = someDate.getMonth() + 1;
                        let year = someDate.getFullYear();
                        let convertDate = year + "-" + month + "-" + date;
                        let newDate = new Date(convertDate);
                        let stringToDate = new Date(newDate.toDateString());

                        let inputDate = new Date(birthDate);

                        if (stringToDate >= inputDate) {
                            inputData.basicInformationUpdatedobApiCall = "YES";
                            inputData.basicInformationUpdateminorStatusMinorIndicator = "N"

                            //varValue.basicInformationUpdateminorStatusMinorIndicator = "Y"
                        } else {
                            inputData.basicInformationUpdatedobApiCall = "YES";
                            inputData.basicInformationUpdateminorStatusMinorIndicator = "Y"
                            //this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
                        }
                        x = 60;
                        currentDate = new Date();
                        someDate = new Date();
                        numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        date = someDate.getDate();
                        month = someDate.getMonth() + 1;
                        year = someDate.getFullYear();
                        convertDate = year + "-" + month + "-" + date;
                        newDate = new Date(convertDate);
                        stringToDate = new Date(newDate.toDateString());

                        inputDate = new Date(birthDate);
                        if (stringToDate >= inputDate) {
                            inputData.basicInformationUpdateseniorCitizenIndicator = "Y"


                        } else {

                            inputData.basicInformationUpdateseniorCitizenIndicator = "N"
                        }
                    }, 500)
                }
                inputData.basicInformationUpdateminorStatusMinorIndicator = data.isMinor;
                inputData.basicInformationUpdateseniorCitizenIndicator = data.seniorCitizen;
                inputData.fincoreDataUpdatefreeText10 = data.waiverfield;


                inputData.introducerIdApiCall = true;

                inputData.Customersnationality = this.findByVarNameGetKeyValue(data.nationality, this.state.country);
                if (data.NIDBLOCK !== undefined) {
                    inputData.nationalIdCardNumberUniqueId = data.NIDBLOCK.referenceNum;
                    inputData.nationalIdCardNo = data.NIDBLOCK.referenceNum;
                    inputData.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = data.NIDBLOCK.isDocumentVerified;
                    inputData.nationalIdCardNo = data.NIDBLOCK.referenceNum;
                    let natioanlIdCardissueDateN = data.NIDBLOCK.issueDt;
                    if (natioanlIdCardissueDateN && natioanlIdCardissueDateN !== -1) {
                        let date = (natioanlIdCardissueDateN).split('T')[0].split("-");
                        inputData.natioanlIdCardissueDateN = `${date[1]}/${date[2]}/${date[0]}`
                    }
                }
                if (data.RSNIDBLOCK !== undefined) {

                    inputData.smartCardNumberUniqueId = data.RSNIDBLOCK.referenceNum;
                    let smartCardissueDateN = data.RSNIDBLOCK.issueDt;
                    if (smartCardissueDateN && smartCardissueDateN !== -1) {
                        let date = (smartCardissueDateN).split('T')[0].split("-");
                        inputData.smartIdCardissueDateS = `${date[1]}/${date[2]}/${date[0]}`
                    }


                }

                if (data.RESIDBLOCK !== undefined) {
                    inputData.residentNumberUniqueId = data.RESIDBLOCK.referenceNum;
                }
                if (data.DLBLOCK !== undefined) {
                    inputData.drivingLicenseNumberUniqueId = data.DLBLOCK.referenceNum;
                }
                if (data.FutureOnSiteBLOCK !== undefined) {

                    inputData.presentAddresspresentAddressField1 = data.FutureOnSiteBLOCK.addrLine1;
                    inputData.presentAddresspresentAddressField2 = data.FutureOnSiteBLOCK.addrLine2;
                    inputData.presentAddresscity = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.city, this.state.city);
                    inputData.presentAddressstate = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.state, this.state.stateCode);
                    inputData.presentAddresspostalCode = data.FutureOnSiteBLOCK.postalCode;
                    inputData.presentAddresscountry = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.country, this.state.country);
                }
                if (data.NRERelativeBLOCK !== undefined) {

                    inputData.nreAddressnreAddressField1 = data.NRERelativeBLOCK.addrLine1;
                    inputData.nreAddressnreAddressField2 = data.NRERelativeBLOCK.addrLine2;
                    inputData.nreAddresscity = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.city, this.state.city);
                    inputData.nreAddressstate = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.state, this.state.stateCode);
                    inputData.nreAddresspostalCode = data.NRERelativeBLOCK.postalCode;
                    inputData.nreAddresscountry = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.country, this.state.country);
                }

                if (data.PASSPORTBLOCK !== undefined) {

                    inputData.passportNumberpassportNumberUniqueId = data.PASSPORTBLOCK.referenceNum;
                    inputData.passportNo = data.PASSPORTBLOCK.referenceNum;
                    inputData.passportNo = data.PASSPORTBLOCK.referenceNum;
                    inputData.passportNumberplaceOfIssue = this.findByVarNameGetKeyValue(data.PASSPORTBLOCK.placeOfIssue, this.state.placeOfIssueList);
                    let passportNumberissueDate = data.PASSPORTBLOCK.issueDt;
                    if (passportNumberissueDate && passportNumberissueDate !== -1) {
                        let date = (passportNumberissueDate).split('T')[0].split("-");
                        inputData.passportNumberissueDate = `${date[1]}/${date[2]}/${date[0]}`
                    }
                    inputData.passportNumbercountryOfIssue = this.findByVarNameGetKeyValue(data.PASSPORTBLOCK.countryOfIssue, this.state.country);
                }


                //inputData.passportNumberexpiryDate=data. ;
                if (data.BCBLOCK !== undefined) {

                    inputData.birthCertificatebirthCertificateNumberUniqueId = data.BCBLOCK.referenceNum;
                    inputData.birthCertificateNo = data.BCBLOCK.referenceNum;
                    inputData.birthCertificateNo = data.BCBLOCK.referenceNum;

                }
                if (data.chairmanCertificate !== undefined) {

                    inputData.chairmanCertificateUniqueId = data.chairmanCertificate;
                }

                if (data.ETINBLOCK !== undefined) {

                    inputData.etineTinNumber = data.ETINBLOCK.referenceNum;
                    inputData.electronicTaxId = data.ETINBLOCK.referenceNum;


                }

                if (data.nameOfEmployer !== undefined) {
                    inputData.employerDataprofessionEmploymentType = this.findByVarNameGetKeyValue(data.employmentStatus, this.state.employmentTypeList);

                    inputData.employerDatanameOfEmployerEmployerName = data.nameOfEmployer;
                }
                if (data.COMMEMLBLOCK !== undefined) {
                    //inputData.employerDatamonthlyIncomeGrossIncome=data. ;

                    inputData.contactNumberEmaildesignatedEmailAddressEmailId1 = data.COMMEMLBLOCK.emailInfo

                }
                if (data.COMMPH1BLOCK !== undefined) {

                    inputData.contactNumberphoneNo1 = data.COMMPH1BLOCK.phoneNumLocalCode;
                    if (data.COMMPH1BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypecontactNumberphoneNo1 = "Local";
                    } else {
                        inputData.mobileTypecontactNumberphoneNo1 = "Overseas";
                        inputData.countryCodecontactNumberphoneNo1 = this.findByVarNameGetKeyValue(data.COMMPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)
                    }
                }
                if (data.WORKPH1BLOCK !== undefined) {

                    inputData.contactNumberphoneNo3 = data.WORKPH1BLOCK.phoneNumLocalCode;
                    if (data.WORKPH1BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypecontactNumbercontactType3 = "Local";
                    } else {
                        inputData.mobileTypecontactNumbercontactType3 = "Overseas";
                        inputData.countryCodecontactNumberphoneNo3 = this.findByVarNameGetKeyValue(data.WORKPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)

                    }
                }
                if (data.COMMPH2BLOCK !== undefined) {

                    inputData.contactNumberphoneNo2 = data.COMMPH2BLOCK.phoneNumLocalCode;

                    if (data.COMMPH2BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypecontactNumberphoneNo2 = "Local";
                    } else {
                        inputData.mobileTypecontactNumberphoneNo2 = "Overseas";
                        inputData.countryCodecontactNumberphoneNo2 = this.findByVarNameGetKeyValue(data.COMMPH2BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)

                    }
                }
                if (data.HOMEEMLBLOCK !== undefined) {


                    inputData.contactNumberEmailemailId2 = data.HOMEEMLBLOCK.emailInfo;
                }
                if (data.MailingBLOCK !== undefined) {


                    inputData.maillingAdressmailingAddressField1 = data.MailingBLOCK.addrLine1;
                    inputData.maillingAdressmailingAddressField2 = data.MailingBLOCK.addrLine2;
                    inputData.maillingAdresscity = this.findByVarNameGetKeyValue(data.MailingBLOCK.city, this.state.city);
                    inputData.maillingAdressstate = this.findByVarNameGetKeyValue(data.MailingBLOCK.state, this.state.stateCode);
                    inputData.maillingAdresspostalCode = data.MailingBLOCK.postalCode;
                    inputData.maillingAdresscountry = this.findByVarNameGetKeyValue(data.MailingBLOCK.country, this.state.country);

                }
                if (data.HomeBLOCK !== undefined) {

                    inputData.homeAddresshomeAddressField1 = data.HomeBLOCK.addrLine1;
                    inputData.homeAddresshomeAddressField2 = data.HomeBLOCK.addrLine2;
                    inputData.homeAddresscity = this.findByVarNameGetKeyValue(data.HomeBLOCK.city, this.state.city);

                    inputData.homeAddressstate = this.findByVarNameGetKeyValue(data.HomeBLOCK.state, this.state.stateCode);
                    inputData.homeAddresspostalCode = data.HomeBLOCK.postalCode;
                    inputData.homeAddresscountry = this.findByVarNameGetKeyValue(data.HomeBLOCK.country, this.state.country);
                }
                if (data.WorkBLOCK !== undefined) {

                    inputData.workAddressworkAddressField1 = data.WorkBLOCK.addrLine1;
                    inputData.workAddressworkAddressField2 = data.WorkBLOCK.addrLine2;
                    inputData.workAddresscity = this.findByVarNameGetKeyValue(data.WorkBLOCK.city, this.state.city);
                    inputData.workAddressstate = this.findByVarNameGetKeyValue(data.WorkBLOCK.state, this.state.stateCode);
                    inputData.workAddresspostalCode = data.WorkBLOCK.postalCode;
                    inputData.workAddresscountry = this.findByVarNameGetKeyValue(data.WorkBLOCK.countryCode, this.state.country);

                }

            }
        }


        return inputData;
    };

    functionForGetWhenRequestGetReturn = (sectorCode, currency, accountType, accountAcquisition, tenureType, tenure, autoRenewals) => {
        let codeUrl = backEndServerURL + "/finMasterData/custom/subSector/" + sectorCode;//{sectorCode}
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let jsonArrayServiceList = this.props.serviceList;
                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                    let jsonObjects = jsonArrayServiceList[i];
                    if (jsonObjects.varName === "subSectorCode") {
                        this.props.serviceList[i].enum = response.data;
                    }
                }
                this.forceUpdate();
            })
            .catch((error) => {
                console.log(error);
            });

        let object = {
            currency: typeof currency === "object" ? currency.key : currency
        };
        if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
            object.natureAccount = "Individual-Retail";
        } else {
            object.natureAccount = "Non-Individual"
        }

        let Urls = backEndServerURL + "/FDRDPSSchemeCode/getAccountType";
        axios.post(Urls, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((getAccountType) => {
                let jsonArrayServiceList = this.props.serviceList;
                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                    let jsonObjects = jsonArrayServiceList[i];
                    if (jsonObjects.varName === "accountType") {
                        this.props.serviceList[i].enum = getAccountType.data;
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });

        let acqUrl = backEndServerURL + "/FDRDPSSchemeCode/getAccountAcquisition";
        axios.post(acqUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let jsonArrayServiceList = this.props.serviceList;
                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                    let jsonObjects = jsonArrayServiceList[i];
                    if (jsonObjects.varName === "accountAcquisition") {
                        this.props.serviceList[i].enum = response.data;
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });

        object.accountType = accountType;
        object.accountAcquisition = accountAcquisition;
        let tenureUrl = backEndServerURL + "/FDRDPSSchemeCode/getTenureType";//{sectorCode}
        axios.post(tenureUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let jsonArrayServiceList = this.props.serviceList;
                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                    let jsonObjects = jsonArrayServiceList[i];
                    if (jsonObjects.varName === "tenureType") {
                        this.props.serviceList[i].enum = response.data;
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });

        object.tenureType = tenureType;
        let tenorUrl = backEndServerURL + "/FDRDPSSchemeCode/getTenor";
        axios.post(tenorUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let jsonArrayServiceList = this.props.serviceList;
                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                    let jsonObjects = jsonArrayServiceList[i];
                    if (jsonObjects.varName === "tenure") {
                        this.props.serviceList[i].enum = response.data;
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            });

        object.tenure = tenure;
        let renewalUrl = backEndServerURL + "/FDRDPSSchemeCode/getRenewal";//{sectorCode}
        axios.post(renewalUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let jsonArrayServiceList = this.props.serviceList;
                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                    let jsonObjects = jsonArrayServiceList[i];
                    if (jsonObjects.varName === "autoRenewals") {
                        this.props.serviceList[i].enum = response.data.renewal;
                    }

                }
            })
            .catch((error) => {
                console.log(error)
            });
        object.autoRenewals = autoRenewals;
        let maturityUrl = backEndServerURL + "/FDRDPSSchemeCode/getMaturity";
        axios.post(maturityUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response);
                let jsonArrayServiceList = this.props.serviceList;
                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                    let jsonObjects = jsonArrayServiceList[i];
                    if (jsonObjects.varName === "maturity") {
                        this.props.serviceList[i].enum = response.data;
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    };

    functionForDpsWhenRequestGetReturn = (currency, nameOfScheme, accountAcquisition, tenureType, sectorCode) => {
        let object = {
            currency: typeof currency === "object" ? currency.key : currency,
        };
        if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
            object.natureAccount = "Individual-Retail";
        } else {
            object.natureAccount = "Non-Individual"
        }
        let Urls = backEndServerURL + "/FDRDPSSchemeCode/getDPSNameOfScheme";
        axios.post(Urls, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((getAccountType) => {
                let jsonArrayServiceList = this.props.commonJsonForm;
                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                    let jsonObjects = jsonArrayServiceList[i];
                    if (jsonObjects.varName === "nameOfScheme") {
                        this.props.commonJsonForm[i].enum = getAccountType.data;
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });

        if (nameOfScheme !== undefined) {
            object.nameOfScheme = nameOfScheme;
            let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getDPSAccountAcquisition";
            axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log("res...accq", object, response.data);
                    let jsonArrayServiceList = this.props.commonJsonForm;
                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "accountAcquisition") {
                            this.props.commonJsonForm[i].enum = response.data;
                        }
                    }

                    this.forceUpdate();
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        if (accountAcquisition !== undefined) {
            object.accountAcquisition = accountAcquisition;
            let tenureTypeUrl = backEndServerURL + "/FDRDPSSchemeCode/getDPSTenureType";//{sectorCode}
            axios.post(tenureTypeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log("res...tenure type", object, response.data);
                    let jsonArrayServiceList = this.props.commonJsonForm;
                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "tenureType") {
                            this.props.commonJsonForm[i].enum = response.data;
                        }
                    }
                    this.forceUpdate();
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        if (tenureType !== undefined) {
            object.tenureType = tenureType;
            let tenureUrl = backEndServerURL + "/FDRDPSSchemeCode/getDPSTenure";//{sectorCode}
            axios.post(tenureUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log("res...tenure", object, response.data);
                    let jsonArrayServiceList = this.props.commonJsonForm;
                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "tenure") {
                            this.props.commonJsonForm[i].enum = response.data;
                        }
                    }
                    this.forceUpdate();
                })
                .catch((error) => {
                    console.log(error);
                })
        }

        if (sectorCode !== undefined) {
            let codeUrl = backEndServerURL + "/finMasterData/custom/subSector/" + sectorCode;
            axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.findByVarNameApiData("subSectorCode", response.data);
                    this.forceUpdate();
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    };
    makeReadOnlyObject = (object) => {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            returnObjectVariables[i]["readOnly"] = true;
        }
        return returnObject;


    };
    checkReadOnlyField = (jsonForm) => {
        if (this.state.inputData.allFieldReadOnly === "YES") {
            return this.makeReadOnlyObject(jsonForm)
        } else {
            return jsonForm
        }
    };
    dynamicApiCallBranchName = (fieldName, apiType, setData) => {
        let codeUrl = backEndServerURL + "/finMasterData/custom/branchNameSorted";
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response);
                this.findByVarNameApiData("branchSolId", response.data);
                this.setState({
                    branchAllSolId: response.data
                })
            })
            .catch((error) => {
                console.log(error)
            })
    };

    componentDidMount() {
        if(this.props.getmatchBy==="AC Number" && this.props.accountNo!==undefined){
            let s1 = (this.props.accountNo.trim()).toString();
            let s2 = s1.substr(3);
            let cbData = s2.substring(0, s2.length - 3);
             this.setState({
                 getcustomerId: "CB" + cbData
            })
        }
        else{
            this.setState({
                getcustomerId: this.props.customerId
            })
        }
        this.setState({
            loaderNeeded: false,
            loading: true,
        });

        for (let item of this.props.commonJsonForm) {
            if (item.varName === "businessSegement") {
                item.readOnly = false;
            }
        }

        {
            this.dynamicApiCall("city", "city")
        }
        {
            this.dynamicApiCall("agentBankingOutlet", "agentBankingOutlet")
        }
        {
            this.dynamicApiCall("state", "state")
        }
        {
            this.dynamicApiCall("currency", "currency")
        }
        {
            this.dynamicApiCall("nationality", "country")
        }
        {
            this.dynamicApiCall("country", "country")
        }
        {
            this.dynamicApiCall("schemeCode", "fdrSchemeCode")
        }
        {
            this.dynamicApiCall("phoneCountryCode", "phoneCountryCode")
        }
        {
            this.dynamicApiCall("occupationCode", "occupationCode")
        }
        {
            this.dynamicApiCall("dpsSchemeCode", "dpsSchemeCode")
        }
        {
            this.dynamicApiCall("sectorCode", "sectorCode")
        }
        {
            this.dynamicApiCallBranchName("branchName", "branch")
        }
        let docsTypeUrl = backEndServerURL + "/deferralList/get/SDRegulatory";
        //let docsTypeUrl=backEndServerURL+"/deferralList/get/SDNonRegulatory";
        axios.get(docsTypeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    SDRegulatory: response.data
                })
            })
            .catch((error) => {
                console.log(error)
            });

        docsTypeUrl = backEndServerURL + "/deferralList/get/SDNonRegulatory";
        //let docsTypeUrl=backEndServerURL+"/deferralList/get/SDNonRegulatory";
        axios.get(docsTypeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    SDNonRegulatory: response.data
                })
            })
            .catch((error) => {
                console.log(error)
            });
        let varValue = [];
        let inputData = [];
        if (this.props.appId !== undefined) {

            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let url = backEndServerURL + '/TermDepositAccountAllowedForSD/' + this.props.appId;

                    if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                        url = backEndServerURL + '/DPSAccountAllowedForSD/' + this.props.appId;

                    }
                    if (this.props.freeFlag1 === "FDR Account Opening - with Existing Operative Account" || this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {

                        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                if (response.data === false) {
                                    this.state.inputData.allFieldReadOnly = "YES";
                                    inputData.allFieldReadOnly = "YES";
                                    this.forceUpdate()
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            })
                    }
                    let iData = this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account" ? FunctionForInputGetAndSet.getInputDataForDPS(response.data) : FunctionForInputGetAndSet.getInputData(response.data);
                    this.functionForGetWhenRequestGetReturn(response.data.sectorCode["key"], response.data.currency, response.data.accountType, response.data.accountAcquisition, response.data.tenureType, response.data.tenure, response.data.autoRenewals);
                    if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                        this.functionForDpsWhenRequestGetReturn(response.data.currency, response.data.nameOfScheme, response.data.accountAcquisition, response.data.tenureType, response.data.sectorCode["key"]);
                    }
                    inputData = this.emptyValueRemove(iData);
                    varValue = this.emptyValueRemove(iData);
                    let occupationCodeUrl = backEndServerURL + "/occupationCode/getAll";
                    axios.get(occupationCodeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            let jsonArrayServiceList = this.props.serviceList;
                            for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                let jsonObjects = jsonArrayServiceList[i];
                                if (jsonObjects.varName === "occupationCode") {
                                    this.props.serviceList[i].enum = response.data;
                                    console.log("cccccccccccccccccccccccccccc");
                                    console.log(this.props.serviceList[i])

                                }
                            }
                            jsonArrayServiceList = this.props.serviceListLast;
                            for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                let jsonObjects = jsonArrayServiceList[i];
                                if (jsonObjects.varName === "occupationCode") {
                                    this.props.serviceListLast[i].enum = response.data;
                                    console.log("cccccccccccccccccccccccccccc");
                                    console.log(this.props.serviceListLast[i])

                                }
                            }
                            jsonArrayServiceList = this.props.commonJsonForm;
                            for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                let jsonObjects = jsonArrayServiceList[i];
                                if (jsonObjects.varName === "occupationCode") {
                                    this.props.commonJsonForm[i].enum = response.data;
                                    console.log("cccccccccccccccccccccccccccc");
                                    console.log(this.props.commonJsonForm[i])

                                }
                            }


                        })
                        .catch((error) => {
                            console.log(error);
                        });


                    let docsTypeUrl = backEndServerURL + "/deferralList/get/SDRegulatory";
                    //let docsTypeUrl=backEndServerURL+"/deferralList/get/SDNonRegulatory";
                    axios.get(docsTypeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            this.setState({
                                SDRegulatory: response.data
                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        });
                    docsTypeUrl = backEndServerURL + "/deferralList/get/SDNonRegulatory";
                    //let docsTypeUrl=backEndServerURL+"/deferralList/get/SDNonRegulatory";
                    axios.get(docsTypeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            this.setState({
                                SDNonRegulatory: response.data
                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        });

                    var remarksArray = [];
                    this.state.inputData["csDeferal"] = "NO";
                    if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                        let object = {
                            currency: typeof response.data.currency === "object" ? response.data.currency.key : response.data.currency,
                        };
                        if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                            object.natureAccount = "Individual-Retail";
                        } else {
                            object.natureAccount = "Non-Individual"
                        }
                        let Urls = backEndServerURL + "/FDRDPSSchemeCode/getDPSNameOfScheme";
                        axios.post(Urls, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((getAccountType) => {
                                let jsonArrayServiceList = this.props.commonJsonForm;
                                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                    let jsonObjects = jsonArrayServiceList[i];
                                    if (jsonObjects.varName === "nameOfScheme") {
                                        this.props.commonJsonForm[i].enum = getAccountType.data;
                                        console.log(this.props.commonJsonForm[i])
                                    }
                                }
                                inputData.nameOfSchemeApiCall = true;
                                varValue.nameOfSchemeApiCall = true;
                                inputData.csRemarks = undefined;
                                varValue.csRemarks = undefined;
                                this.setState({
                                    accountBalanceInfo: true,
                                    getAccountType: true,
                                    cbsCustomerGet: true,
                                    inputData: inputData,
                                    varValue: varValue,
                                    showValue: true,
                                    //loading: false,
                                    getData: true,
                                    nameOfSchemeApiCall: true,

                                })
                            })
                            .catch((error) => {
                                this.setState({
                                    accountBalanceInfo: true,
                                    nameOfSchemeApiCall: true,
                                    cbsCustomerGet: true,
                                });
                                console.log(error);
                            })
                    } else {
                        let object = {
                            currency: typeof response.data.currency === "object" ? response.data.currency.key : response.data.currency,
                        };
                        if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                            object.natureAccount = "Individual-Retail";
                        } else {
                            object.natureAccount = "Non-Individual"
                        }
                        let Urls = backEndServerURL + "/FDRDPSSchemeCode/getAccountType";
                        axios.post(Urls, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((getAccountType) => {
                                let jsonArrayServiceList = this.props.serviceList;
                                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                    let jsonObjects = jsonArrayServiceList[i];
                                    if (jsonObjects.varName === "accountType") {
                                        this.props.serviceList[i].enum = getAccountType.data;
                                        console.log(this.props.serviceList[i])
                                    }
                                }
                                this.setState({
                                    accountBalanceInfo: true,
                                    getAccountType: true,
                                    cbsCustomerGet: true,
                                    inputData: inputData,
                                    varValue: varValue,
                                    showValue: true,
                                    //loading: false,
                                    getData: true,

                                })
                            })
                            .catch((error) => {
                                this.setState({
                                    accountBalanceInfo: true,
                                    getAccountType: true,
                                    cbsCustomerGet: true,
                                });
                                console.log(error);
                            })
                    }

                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                        console.log(response.data);
                        let tableArray = [];
                        var status = "";
                        response.data.map((deferal) => {
                            if (deferal.status === "ACTIVE") {
                                status = "Approved"
                            } else if (deferal.status === "NOTAPPROVED") {
                                status = "NOT APPROVED"

                            } else {
                                status = deferal.status;
                            }
                            tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));

                        });
                        this.setState({
                            getDeferalList: tableArray
                        });


                        let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                        axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                            console.log(response.data);
                            response.data.map((data) => {
                                if (data.remarks !== 'undefined') {
                                    remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                }
                            });
                            this.setState({
                                getRemarks: remarksArray
                            })
                        })
                            .catch((error) => {
                                console.log(error)
                            })
                    })
                        .catch((error) => {
                            console.log(error);
                        });
                    console.log(remarksArray);
                    this.state.inputData["csDeferal"] = "YES";
                    console.log(inputData);
                    this.setState({

                        getData: true,
                        loading: false,
                        varValue: varValue,
                        appData: response.data,
                        inputData: inputData,
                        showValue: true,
                        appId: this.props.appId,
                        loaderNeeded: true,
                        cbDataBoolean: true,
                        debitDataBoolean: true,
                    });

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        accountBalanceInfo: true,
                        getAccountType: true,
                        cbsCustomerGet: true,
                        loading: false
                    })
                });
        }
        else {
            let occupationCodeUrl = backEndServerURL + "/occupationCode/getAll";
            axios.get(occupationCodeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let jsonArrayServiceList = this.props.serviceList;
                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "occupationCode") {
                            this.props.serviceList[i].enum = response.data;
                            console.log("cccccccccccccccccccccccccccc");
                            console.log(this.props.serviceList[i])

                        }
                    }
                    jsonArrayServiceList = this.props.serviceListLast;
                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "occupationCode") {
                            this.props.serviceListLast[i].enum = response.data;
                            console.log("cccccccccccccccccccccccccccc");
                            console.log(this.props.serviceListLast[i])

                        }
                    }
                    jsonArrayServiceList = this.props.commonJsonForm;
                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "occupationCode") {
                            this.props.commonJsonForm[i].enum = response.data;
                            console.log("cccccccccccccccccccccccccccc");
                            console.log(this.props.commonJsonForm[i])

                        }
                    }


                })
                .catch((error) => {
                    console.log(error);
                });

            let inputData = this.emptyValueRemove(this.props.searchValue);
            let varValue = this.emptyValueRemove(this.props.searchValue);

            let someDate = new Date();
            let x = 0;
            let numberOfDaysToAdd = parseInt(x, 10);
            someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            let date = someDate.getDate();
            let month = someDate.getMonth() + 1;
            let year = someDate.getFullYear();
            let newDate = new Date(year, month - 1, date,);
            inputData.fdValueDate = (newDate !== null) ? newDate.toLocaleString('en-US') : "";
            inputData.nextExecutionDate = (newDate !== null) ? newDate.toLocaleString('en-US') : "";
            inputData.dpsfdValueDate = (newDate !== null) ? newDate.toLocaleString('en-US') : "";
            varValue.fdValueDate = (newDate !== null) ? newDate.toLocaleString('en-US') : "";
            varValue.nextExecutionDate = (newDate !== null) ? newDate.toLocaleString('en-US') : "";
            varValue.dpsfdValueDate = (newDate !== null) ? newDate.toLocaleString('en-US') : "";
            inputData.uploadFileAlertRequest = true;
            varValue.uploadFileAlertRequest = true;
            inputData.businessSegment = this.props.businessSegment;
            varValue.businessSegment = this.props.businessSegment;
            if (this.props.accountType === "Proprietorship A/C" || this.props.accountType === "NONINDIVIDUAL") {
                inputData.nomineeInformationVisible = "YES"
            }
            inputData.businessSegment = this.props.businessSegment;
            varValue.businessSegment = this.props.businessSegment;
            inputData.taxWaiverChanges = "N";
            varValue.taxWaiverChanges = "N";

            inputData.taxWaiverChange = "N";
            varValue.taxWaiverChange = "N";


            inputData.preventialRateChange = "N";


            varValue.preventialRateChange = "N";
            /*  inputData.tenureType = "Monthly";
             varValue.tenureType = "Monthly";
             inputData.autoRenewalS = "YES";
             varValue.autoRenewalS = "YES";
             inputData.maturityS = "Renew Principal only & Credit Interest to Account No";
             varValue.maturityS = "Renew Principal only & Credit Interest to Account No";*/
            if (this.props.AccountType === "DPS Account Opening -with Existing Operative Account") {
                inputData.depositType = 181;
                varValue.depositType = 181;

            }
            let url = backEndServerURL + "/startCase/cs_data_capture";
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                this.setState({
                    appId: response.data.id
                });
                {
                    this.DedupDataSaveApi(this.props.freeFlag3)
                }
                if (this.props.dstFlag === true) {
                    inputData.dstFlag = true;
                    varValue.dstFlag = true;
                }
                if (this.props.agentBankingFlag === true) {
                    inputData.agentBankingFlag = true;
                    varValue.agentBankingFlag = true;
                }
                inputData.priority = "GENERAL";
                varValue.priority = "GENERAL";
                inputData.accountNumber = undefined;
                varValue.accountNumber = undefined;
                inputData.freeFlag6 = this.props.freeFlag1;
                varValue.freeFlag6 = this.props.freeFlag1;
                inputData.conditionalNewButton = this.props.conditionalNewButton;
                varValue.conditionalNewButton = this.props.conditionalNewButton;
                if (this.props.serviceType === 'DPS Opening') {
                    inputData.freeFlag5 = 'DPS Opening';
                    varValue.freeFlag5 = 'DPS Opening'
                } else {
                    inputData.freeFlag5 = "FDR Opening";
                    varValue.freeFlag5 = "FDR Opening";
                }

                inputData.currency = {masterDataName: "currency", key: "BDT", value: "BDT"};
                varValue.currency = {masterDataName: "currency", key: "BDT", value: "BDT"};
                inputData.debitAccountNumber = this.props.accountNo;
                varValue.debitAccountNumber = this.props.accountNo;
                inputData.cbNumber = this.state.getcustomerId;
                varValue.cbNumber = this.state.getcustomerId;
                inputData.customerType = this.props.customerType;
                if (this.props.conditionalNewButton !== true) {
                    if (this.props.freeFlag1 === "FDR Account Opening - with Existing Operative Account") {
                        this.state.inputData.debitAccountNumberApiCall = "NO";
                        this.state.inputData.repaymentAccountApiCall = "NO";
                        this.state.inputData.interestCreditAccountApiCall = "NO";
                        let accountUrls = backEndServerURL + "/GetCustomAcctDetailsRequest/" + (this.props.accountNo).trim();
                        axios.get(accountUrls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                this.state.inputData.repaymentAccountApiCall = "YES";
                                this.state.inputData.interestCreditAccountApiCall = "YES";
                                inputData.repaymentAccountApiCall = "YES";
                                inputData.interestCreditAccountApiCall = "YES";

                                varValue.repaymentAccountApiCall = "YES";
                                varValue.interestCreditAccountApiCall = "YES";

                                inputData.interestCreditName = response.data.acctName;
                                varValue.interestCreditName = response.data.acctName;
                                inputData.repaymentName = response.data.acctName;
                                varValue.repaymentName = response.data.acctName;
                                if (this.props.freeFlag1 === "FDR Account Opening - with Existing Operative Account" || this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                                    inputData.nomineeInformationVisible = "YES";
                                    varValue.nomineeInformationVisible = "YES";
                                    let url = backEndServerURL + "/cbsCustomerGet/" + this.state.getcustomerId;
                                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response) => {
                                            if( response.data.nationality!==undefined && response.data.nationality!==null  && response.data.nationality!=="BD"){
                                                this.setState({
                                                    showValue: false,
                                                    getData: false,
                                                    loading: false,

                                                });
                                                alert("Nationality BD Required")
                                                return 0;
                                            }
                                            console.log(response.data);
                                            inputData.customerName = response.data.customerName;
                                            varValue.customerName = response.data.customerName;
                                            inputData.customerStatus = response.data.customerStatus;
                                            varValue.customerStatus = response.data.customerStatus;
                                            inputData.customerTitle = response.data.salutation;
                                            varValue.customerTitle = response.data.salutation;
                                            inputData.shortName = response.data.shortName;
                                            varValue.shortName = response.data.shortName;
                                            inputData.nomineeName = response.data.customerName;
                                            varValue.nomineeName = response.data.customerName;
                                            inputData.occupationCode = response.data.occupationCode;
                                            varValue.occupationCode = response.data.occupationCode;
                                            inputData.gender = response.data.gender;
                                            varValue.gender = response.data.gender;
                                            inputData.nidVerified = "NO";
                                            varValue.nidVerified = "NO";
                                            inputData.occupationCodePriority = response.data.occupationCode;
                                            varValue.occupationCodePriority = response.data.occupationCode;
                                            inputData.occupationCodeStaff = response.data.occupationCode;
                                            varValue.occupationCodeStaff = response.data.occupationCode;

                                            inputData.sbsCode = response.data.sbsCode;
                                            varValue.sbsCode = response.data.sbsCode;
                                            if (response.data.NIDBLOCK !== undefined) {
                                                inputData.nid = response.data.NIDBLOCK.referenceNum;
                                                varValue.nid = response.data.NIDBLOCK.referenceNum;
                                            }

                                            if (response.data.PASSPORTBLOCK !== undefined) {
                                                inputData.passport = response.data.PASSPORTBLOCK.referenceNum;
                                                varValue.passport = response.data.PASSPORTBLOCK.referenceNum;
                                            }
                                            if (response.data.COMMEMLBLOCK !== undefined) {
                                                inputData.email = response.data.COMMEMLBLOCK.emailInfo;
                                                varValue.email = response.data.COMMEMLBLOCK.emailInfo
                                            }
                                            if (response.data.ETINBLOCK !== undefined) {
                                                inputData.eTin = response.data.ETINBLOCK.referenceNum;
                                                inputData.tin = response.data.ETINBLOCK.referenceNum;
                                                inputData.etin = response.data.ETINBLOCK.referenceNum;
                                                varValue.eTin = response.data.ETINBLOCK.referenceNum;
                                                varValue.tin = response.data.ETINBLOCK.referenceNum;
                                                varValue.etin = response.data.ETINBLOCK.referenceNum;
                                            }
                                            if (response.data.DLBLOCK !== undefined) {
                                                inputData.drivingLicense = response.data.DLBLOCK.referenceNum;
                                                varValue.drivingLicense = response.data.DLBLOCK.referenceNum;
                                            }
                                            if (response.data.BCBLOCK !== undefined) {
                                                inputData.registrationNo = response.data.BCBLOCK.referenceNum;
                                                varValue.registrationNo = response.data.BCBLOCK.referenceNum;
                                            }
                                            if (response.data.COMMPH1BLOCK !== undefined) {
                                                if (response.data.COMMPH1BLOCK.phoneNumCountryCode === "88") {
                                                    inputData.mobileTypeIndividual = "Local";
                                                    varValue.mobileTypeIndividual = "Local"
                                                } else if (response.data.COMMPH1BLOCK.phoneNumCountryCode === "0") {
                                                    inputData.mobileTypeIndividual = "Overseas";
                                                    inputData.countryCodeIndividual = this.findByVarNameGetKeyValue(response.data.COMMPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList);
                                                    varValue.mobileTypeIndividual = "Overseas";
                                                    varValue.countryCodeIndividual = this.findByVarNameGetKeyValue(response.data.COMMPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)
                                                }

                                            }
                                            if (response.data.COMMPH1BLOCK !== undefined) {
                                                inputData.mobileNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                                inputData.designatedMobileNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                                inputData.phone = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                                varValue.mobileNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                                varValue.designatedMobileNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                                varValue.phone = response.data.COMMPH1BLOCK.phoneNumLocalCode
                                            }
                                            if (response.data.MailingBLOCK !== undefined) {
                                                inputData.SelectCommunicationAddress = response.data.MailingBLOCK.addrCategory;
                                                inputData.communicationAddress1 = response.data.MailingBLOCK.addrLine1;
                                                inputData.communicationAddress2 = response.data.MailingBLOCK.addrLine2;
                                                inputData.postalCode = response.data.MailingBLOCK.postalCode;
                                                inputData.state = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.city, this.state.state);
                                                inputData.city = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.state, this.state.city);
                                                inputData.country = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.country, this.state.country);

                                                varValue.SelectCommunicationAddress = response.data.MailingBLOCK.addrCategory;
                                                varValue.communicationAddress1 = response.data.MailingBLOCK.addrLine1;
                                                varValue.communicationAddress2 = response.data.MailingBLOCK.addrLine2;
                                                varValue.postalCode = response.data.MailingBLOCK.postalCode;
                                                varValue.state = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.city, this.state.state);
                                                varValue.city = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.state, this.state.city);
                                                varValue.country = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.country, this.state.country)
                                            }


                                            let getDob = response.data.birthDate;
                                            if (getDob !== null & getDob !== undefined) {
                                                getDob = getDob.split('T')[0];
                                            }
                                            //inputData.currency = this.findByVarNameGetKeyValue(response.data.currencyCode, this.state.currency);
                                            //varValue.currency = this.findByVarNameGetKeyValue(response.data.currencyCode, this.state.currency);
                                            inputData.dob = getDob;
                                            inputData.selectedDate = getDob;
                                            varValue.dob = getDob;
                                            varValue.selectedDate = getDob;
                                            inputData.cbNumberApiCall = "YES";
                                            //Account inquiry start
                                            let accountUrls = backEndServerURL + "/getAccountInfo/" + (this.props.accountNo).trim();
                                            axios.get(accountUrls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                .then((response) => {
                                                    inputData.clearingLimitDR = response.data.drclrlim;
                                                    inputData.clearingLimitCR = response.data.crclrlim;
                                                    inputData.TransferLimitCR = response.data.crtxlim;
                                                    if (response.data.nomineeInfoDetails !== undefined && response.data.nomineeInfoDetails !== null) {
                                                        inputData.nomineeExistNot = "Y";
                                                        varValue.nomineeExistNot = "Y";
                                                        inputData.existingNomineeNumber = response.data.nomineeInfoDetails.length;
                                                        varValue.existingNomineeNumber = response.data.nomineeInfoDetails.length;
                                                    } else {
                                                        inputData.nomineeExistNot = "N";
                                                        varValue.nomineeExistNot = "N";
                                                    }
                                                    if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                                                        let object = {
                                                            currency: typeof response.data.currency === "object" ? response.data.currency.key : response.data.currency,
                                                        };
                                                        if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                                                            object.natureAccount = "Individual-Retail";
                                                        } else {
                                                            object.natureAccount = "Non-Individual"
                                                        }
                                                        let Urls = backEndServerURL + "/FDRDPSSchemeCode/getDPSNameOfScheme";
                                                        axios.post(Urls, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                            .then((getAccountType) => {
                                                                inputData.repaymentAccount = response.data.accountNumber;
                                                                inputData.interestCreditAccount = response.data.accountNumber;
                                                                inputData.accountCbNumber = response.data.custId;
                                                                inputData.debitAccountName = response.data.accountName;
                                                                //inputData.availableAmount = response.data.availableBalance
                                                                inputData.accountRestriction = response.data.accountRestriction;
                                                                inputData.currency = response.data.currency;
                                                                console.log(response.data.sectorCode);
                                                                console.log(this.state.sectorList);
                                                                console.log(this.state.subsectorList);
                                                                inputData.sectorCode = this.findByVarNameGetKeyValue(response.data.sectorCode, this.state.sectorList);
                                                                inputData.subSectorCode = this.findByVarNameGetKeyValue(response.data.subsectorcode, this.state.subsectorList);
                                                                inputData.repaymentAccounts = response.data.accountNumber;
                                                                inputData.repaymentsName = response.data.accountName;
                                                                inputData.repaymentsAccountApiCall = "YES";


                                                                varValue.repaymentAccounts = response.data.accountNumber;
                                                                varValue.repaymentsName = response.data.accountName;
                                                                varValue.repaymentsAccountApiCall = "YES";
                                                                varValue.repaymentAccount = response.data.accountNumber;
                                                                varValue.interestCreditAccount = response.data.accountNumber;
                                                                varValue.accountCbNumber = response.data.custId;
                                                                varValue.accountRestriction = response.data.accountRestriction;
                                                                varValue.currency = response.data.currency;
                                                                varValue.debitAccountName = response.data.accountName;
                                                                inputData.sectorCodeApicall = true;
                                                                varValue.sectorCodeApicall = true;
                                                                varValue.sectorCode = this.findByVarNameGetKeyValue(response.data.sectorCode, this.state.sectorList);
                                                                varValue.subSectorCode = this.findByVarNameGetKeyValue(response.data.subsectorcode, this.state.subsectorList);
                                                                let jsonArrayServiceList = this.props.commonJsonForm;
                                                                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                                                    let jsonObjects = jsonArrayServiceList[i];
                                                                    if (jsonObjects.varName === "nameOfScheme") {
                                                                        this.props.commonJsonForm[i].enum = getAccountType.data;
                                                                        console.log(this.props.commonJsonForm[i])
                                                                    }
                                                                }
                                                                inputData.nameOfSchemeApiCall = true;
                                                                varValue.nameOfSchemeApiCall = true;
                                                                let urls = backEndServerURL + "/intgr/accountBalanceInfo/" + (this.props.accountNo).trim();
                                                                axios.get(urls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                                    .then((response) => {
                                                                        console.log(response.data);

                                                                        if (response.data.schemeCode === "SBSTF") {
                                                                            inputData.availableAmountShow = false;
                                                                            varValue.availableAmountShow = false

                                                                        } else {
                                                                            inputData.availableAmountShow = true;
                                                                            varValue.availableAmountShow = true
                                                                        }
                                                                        inputData.availableAmount = response.data.availableBalance;
                                                                        varValue.availableAmount = response.data.availableBalance;

                                                                        inputData.debitAccountNumberApiCall = "YES";
                                                                        this.setState({
                                                                            //loading: false,
                                                                            cbsCustomerGet: true,
                                                                            cbDataBoolean: true,
                                                                            //loading: false,
                                                                            nameOfSchemeApiCall: true,
                                                                            getAccountInfo: true,
                                                                            inputData: inputData,
                                                                            varValue: varValue,
                                                                            showValue: true,
                                                                            loading: false,
                                                                            getData: true,
                                                                            accountBalanceInfo: true,
                                                                            debitDataBoolean: true,


                                                                        })
                                                                    })
                                                                    .catch((error) => {
                                                                        console.log(error);
                                                                        this.setState({
                                                                            accountBalanceInfo: true,
                                                                            loading: false,
                                                                            getData: false,
                                                                            errorModalBoolean: true,
                                                                            errorTittle: "Finacle Error",
                                                                            debitDataBoolean: true,
                                                                        })
                                                                    })

                                                            })
                                                            .catch((error) => {
                                                                this.setState({
                                                                    nameOfSchemeApiCall: true,
                                                                    getData: true,
                                                                });
                                                                console.log(error);
                                                            })
                                                    }
                                                    //new
                                                    else {
                                                        let object = {
                                                            currency: typeof response.data.currency === "object" ? response.data.currency.key : response.data.currency,
                                                        };
                                                        if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                                                            object.natureAccount = "Individual-Retail";
                                                        } else {
                                                            object.natureAccount = "Non-Individual";
                                                        }
                                                        let Urls = backEndServerURL + "/FDRDPSSchemeCode/getAccountType";
                                                        axios.post(Urls, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                            .then((getAccountType) => {
                                                                inputData.repaymentAccount = response.data.accountNumber;
                                                                inputData.interestCreditAccount = response.data.accountNumber;
                                                                inputData.accountCbNumber = response.data.custId;
                                                                inputData.debitAccountName = response.data.accountName;
                                                                //inputData.availableAmount = response.data.availableBalance
                                                                inputData.accountRestriction = response.data.accountRestriction;
                                                                console.log(this.props.freeFlag1);
                                                                inputData.currency = this.findByVarNameGetKeyValue(response.data.acctCurrency, this.state.currency);
                                                                varValue.currency = this.findByVarNameGetKeyValue(response.data.acctCurrency, this.state.currency);
                                                                console.log(this.props.commonJsonForm);
                                                                console.log(varValue.currency);


                                                                inputData.sectorCode = this.findByVarNameGetKeyValue(response.data.sectorCode, this.state.sectorList);
                                                                inputData.subSectorCode = this.findByVarNameGetKeyValue(response.data.subsectorcode, this.state.subsectorList);
                                                                varValue.repaymentAccount = response.data.accountNumber;
                                                                varValue.interestCreditAccount = response.data.accountNumber;
                                                                varValue.accountCbNumber = response.data.custId;
                                                                varValue.accountRestriction = response.data.accountRestriction;
                                                                // varValue.currency = response.data.currency;
                                                                varValue.debitAccountName = response.data.accountName;
                                                                varValue.sectorCode = this.findByVarNameGetKeyValue(response.data.sectorCode, this.state.sectorList);
                                                                varValue.subSectorCode = this.findByVarNameGetKeyValue(response.data.subsectorcode, this.state.subsectorList);
                                                                let jsonArrayServiceList = this.props.serviceList;
                                                                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                                                    let jsonObjects = jsonArrayServiceList[i];
                                                                    if (jsonObjects.varName === "accountType") {
                                                                        this.props.serviceList[i].enum = getAccountType.data;
                                                                        console.log(this.props.serviceList[i])
                                                                    }
                                                                }
                                                                inputData.accountTypeApiCall = true;
                                                                varValue.accountTypeApiCall = true;
                                                                let urls = backEndServerURL + "/intgr/accountBalanceInfo/" + (this.props.accountNo).trim();
                                                                axios.get(urls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                                    .then((response) => {
                                                                        console.log(response.data);

                                                                        if (response.data.schemeCode === "SBSTF") {
                                                                            inputData.availableAmountShow = false;
                                                                            varValue.availableAmountShow = false

                                                                        } else {
                                                                            inputData.availableAmountShow = true;
                                                                            varValue.availableAmountShow = true
                                                                        }
                                                                        inputData.availableAmount = response.data.availableBalance;
                                                                        varValue.availableAmount = response.data.availableBalance;

                                                                        inputData.debitAccountNumberApiCall = "YES";
                                                                        this.setState({
                                                                            //loading: false,
                                                                            cbsCustomerGet: true,
                                                                            cbDataBoolean: true,
                                                                            //loading: false,
                                                                            getAccountType: true,
                                                                            //loading: false,
                                                                            nameOfSchemeApiCall: true,
                                                                            getAccountInfo: true,
                                                                            inputData: inputData,
                                                                            varValue: varValue,
                                                                            showValue: true,
                                                                            loading: false,
                                                                            getData: true,
                                                                            accountBalanceInfo: true,
                                                                            debitDataBoolean: true,
                                                                        })
                                                                    })
                                                                    .catch((error) => {
                                                                        console.log(error);
                                                                        this.setState({
                                                                            accountBalanceInfo: true,
                                                                            loading: false,
                                                                            getData: false,
                                                                            errorModalBoolean: true,
                                                                            errorTittle: "Finacle Error",
                                                                            debitDataBoolean: true,
                                                                        })
                                                                    })

                                                            })
                                                            .catch((error) => {
                                                                this.setState({
                                                                    getAccountType: true,
                                                                });
                                                                console.log(error);
                                                            })

                                                    }

                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    this.setState({
                                                        getData: false,
                                                        loading: false,
                                                        errorModalBoolean: true,
                                                        errorTittle: "Finacle Error",
                                                        getAccountInfo: true,
                                                    })
                                                })

                                            //Account inquiry End


                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            this.setState({
                                                cbsCustomerGet: true,
                                                loading: false,
                                                getData: false,
                                                errorModalBoolean: true,
                                                cbDataBoolean: true,
                                                errorTittle: "Invalid CB Number"
                                            })
                                        })

                                }

                            })
                            .catch((error) => {
                                this.forceUpdate();
                                this.setState({
                                    loading: false,
                                    getData: false,
                                    errorModalBoolean: true,
                                    errorTittle: this.errorObjectCheck(error),
                                })
                            });

                    } else {
                        if (this.props.freeFlag1 === "FDR Account Opening - with Existing Operative Account" || this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                            inputData.nomineeInformationVisible = "YES";
                            varValue.nomineeInformationVisible = "YES";
                            let url = backEndServerURL + "/cbsCustomerGet/" + this.state.getcustomerId;
                            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    if( response.data.nationality!==undefined && response.data.nationality!==null  && response.data.nationality!=="BD"){
                                        this.setState({
                                            showValue: false,
                                            getData: false,
                                            loading: false,

                                        });
                                        alert("Nationality BD Required")
                                        return 0;
                                    }
                                    console.log(response.data);
                                    inputData.customerName = response.data.customerName;
                                    varValue.customerName = response.data.customerName;
                                    inputData.customerStatus = response.data.customerStatus;
                                    varValue.customerStatus = response.data.customerStatus;
                                    inputData.customerTitle = response.data.salutation;
                                    varValue.customerTitle = response.data.salutation;
                                    inputData.shortName = response.data.shortName;
                                    varValue.shortName = response.data.shortName;
                                    inputData.nomineeName = response.data.customerName;
                                    varValue.nomineeName = response.data.customerName;
                                    inputData.occupationCode = response.data.occupationCode;
                                    varValue.occupationCode = response.data.occupationCode;
                                    inputData.gender = response.data.gender;
                                    varValue.gender = response.data.gender;
                                    inputData.nidVerified = "NO";
                                    varValue.nidVerified = "NO";
                                    inputData.occupationCodePriority = response.data.occupationCode;
                                    varValue.occupationCodePriority = response.data.occupationCode;
                                    inputData.occupationCodeStaff = response.data.occupationCode;
                                    varValue.occupationCodeStaff = response.data.occupationCode;

                                    inputData.sbsCode = response.data.sbsCode;
                                    varValue.sbsCode = response.data.sbsCode;
                                    if (response.data.NIDBLOCK !== undefined) {
                                        inputData.nid = response.data.NIDBLOCK.referenceNum;
                                        varValue.nid = response.data.NIDBLOCK.referenceNum;
                                    }

                                    if (response.data.PASSPORTBLOCK !== undefined) {
                                        inputData.passport = response.data.PASSPORTBLOCK.referenceNum;
                                        varValue.passport = response.data.PASSPORTBLOCK.referenceNum;
                                    }
                                    if (response.data.COMMEMLBLOCK !== undefined) {
                                        inputData.email = response.data.COMMEMLBLOCK.emailInfo;
                                        varValue.email = response.data.COMMEMLBLOCK.emailInfo
                                    }
                                    if (response.data.ETINBLOCK !== undefined) {
                                        inputData.eTin = response.data.ETINBLOCK.referenceNum;
                                        inputData.tin = response.data.ETINBLOCK.referenceNum;
                                        inputData.etin = response.data.ETINBLOCK.referenceNum;
                                        varValue.eTin = response.data.ETINBLOCK.referenceNum;
                                        varValue.tin = response.data.ETINBLOCK.referenceNum;
                                        varValue.etin = response.data.ETINBLOCK.referenceNum;
                                    }
                                    if (response.data.DLBLOCK !== undefined) {
                                        inputData.drivingLicense = response.data.DLBLOCK.referenceNum;
                                        varValue.drivingLicense = response.data.DLBLOCK.referenceNum;
                                    }
                                    if (response.data.BCBLOCK !== undefined) {
                                        inputData.registrationNo = response.data.BCBLOCK.referenceNum;
                                        varValue.registrationNo = response.data.BCBLOCK.referenceNum;
                                    }
                                    if (response.data.COMMPH1BLOCK !== undefined) {
                                        if (response.data.COMMPH1BLOCK.phoneNumCountryCode === "88") {
                                            inputData.mobileTypeIndividual = "Local";
                                            varValue.mobileTypeIndividual = "Local"
                                        } else if (response.data.COMMPH1BLOCK.phoneNumCountryCode === "0") {
                                            inputData.mobileTypeIndividual = "Overseas";
                                            inputData.countryCodeIndividual = this.findByVarNameGetKeyValue(response.data.COMMPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList);
                                            varValue.mobileTypeIndividual = "Overseas";
                                            varValue.countryCodeIndividual = this.findByVarNameGetKeyValue(response.data.COMMPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)
                                        }

                                    }
                                    if (response.data.COMMPH1BLOCK !== undefined) {
                                        inputData.mobileNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                        inputData.designatedMobileNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                        inputData.phone = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                        varValue.mobileNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                        varValue.designatedMobileNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                        varValue.phone = response.data.COMMPH1BLOCK.phoneNumLocalCode
                                    }
                                    if (response.data.MailingBLOCK !== undefined) {
                                        inputData.SelectCommunicationAddress = response.data.MailingBLOCK.addrCategory;
                                        inputData.communicationAddress1 = response.data.MailingBLOCK.addrLine1;
                                        inputData.communicationAddress2 = response.data.MailingBLOCK.addrLine2;
                                        inputData.postalCode = response.data.MailingBLOCK.postalCode;
                                        inputData.state = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.city, this.state.state);
                                        inputData.city = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.state, this.state.city);
                                        inputData.country = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.country, this.state.country);

                                        varValue.SelectCommunicationAddress = response.data.MailingBLOCK.addrCategory;
                                        varValue.communicationAddress1 = response.data.MailingBLOCK.addrLine1;
                                        varValue.communicationAddress2 = response.data.MailingBLOCK.addrLine2;
                                        varValue.postalCode = response.data.MailingBLOCK.postalCode;
                                        varValue.state = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.city, this.state.state);
                                        varValue.city = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.state, this.state.city);
                                        varValue.country = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.country, this.state.country)
                                    }


                                    let getDob = response.data.birthDate;
                                    if (getDob !== null & getDob !== undefined) {
                                        getDob = getDob.split('T')[0];
                                    }
                                    //inputData.currency = this.findByVarNameGetKeyValue(response.data.currencyCode, this.state.currency);
                                    //varValue.currency = this.findByVarNameGetKeyValue(response.data.currencyCode, this.state.currency);
                                    inputData.dob = getDob;
                                    inputData.selectedDate = getDob;
                                    varValue.dob = getDob;
                                    varValue.selectedDate = getDob;
                                    inputData.cbNumberApiCall = "YES";
                                    //Account inquiry start
                                    let accountUrls = backEndServerURL + "/getAccountInfo/" + (this.props.accountNo).trim();
                                    axios.get(accountUrls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response) => {
                                            inputData.clearingLimitDR = response.data.drclrlim;
                                            inputData.clearingLimitCR = response.data.crclrlim;
                                            inputData.TransferLimitCR = response.data.crtxlim;
                                            if (response.data.nomineeInfoDetails !== undefined && response.data.nomineeInfoDetails !== null) {
                                                inputData.nomineeExistNot = "Y";
                                                varValue.nomineeExistNot = "Y";

                                                inputData.existingNomineeNumber = response.data.nomineeInfoDetails.length;
                                                varValue.existingNomineeNumber = response.data.nomineeInfoDetails.length;
                                            } else {
                                                inputData.nomineeExistNot = "N";
                                                varValue.nomineeExistNot = "N"
                                            }
                                            if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                                                let object = {
                                                    currency: typeof response.data.currency === "object" ? response.data.currency.key : response.data.currency,
                                                };
                                                if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                                                    object.natureAccount = "Individual-Retail";
                                                } else {
                                                    object.natureAccount = "Non-Individual"
                                                }
                                                let Urls = backEndServerURL + "/FDRDPSSchemeCode/getDPSNameOfScheme";
                                                axios.post(Urls, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                    .then((getAccountType) => {
                                                        inputData.repaymentAccount = response.data.accountNumber;
                                                        inputData.interestCreditAccount = response.data.accountNumber;
                                                        inputData.accountCbNumber = response.data.custId;
                                                        inputData.debitAccountName = response.data.accountName;
                                                        //inputData.availableAmount = response.data.availableBalance
                                                        inputData.accountRestriction = response.data.accountRestriction;
                                                        inputData.currency = response.data.currency;
                                                        console.log(response.data.sectorCode);
                                                        console.log(this.state.sectorList);
                                                        console.log(this.state.subsectorList);
                                                        inputData.sectorCode = this.findByVarNameGetKeyValue(response.data.sectorCode, this.state.sectorList);
                                                        inputData.subSectorCode = this.findByVarNameGetKeyValue(response.data.subsectorcode, this.state.subsectorList);
                                                        inputData.repaymentAccounts = response.data.accountNumber;
                                                        inputData.repaymentsName = response.data.accountName;
                                                        inputData.repaymentsAccountApiCall = "YES";


                                                        varValue.repaymentAccounts = response.data.accountNumber;
                                                        varValue.repaymentsName = response.data.accountName;
                                                        varValue.repaymentsAccountApiCall = "YES";
                                                        varValue.repaymentAccount = response.data.accountNumber;
                                                        varValue.interestCreditAccount = response.data.accountNumber;
                                                        varValue.accountCbNumber = response.data.custId;
                                                        varValue.accountRestriction = response.data.accountRestriction;
                                                        varValue.currency = response.data.currency;
                                                        varValue.debitAccountName = response.data.accountName;
                                                        inputData.sectorCodeApicall = true;
                                                        varValue.sectorCodeApicall = true;
                                                        varValue.sectorCode = this.findByVarNameGetKeyValue(response.data.sectorCode, this.state.sectorList);
                                                        varValue.subSectorCode = this.findByVarNameGetKeyValue(response.data.subsectorcode, this.state.subsectorList);
                                                        let jsonArrayServiceList = this.props.commonJsonForm;
                                                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                                            let jsonObjects = jsonArrayServiceList[i];
                                                            if (jsonObjects.varName === "nameOfScheme") {
                                                                this.props.commonJsonForm[i].enum = getAccountType.data;
                                                                console.log(this.props.commonJsonForm[i])
                                                            }
                                                        }
                                                        inputData.nameOfSchemeApiCall = true;
                                                        varValue.nameOfSchemeApiCall = true;
                                                        let urls = backEndServerURL + "/intgr/accountBalanceInfo/" + (this.props.accountNo).trim();
                                                        axios.get(urls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                            .then((response) => {
                                                                console.log(response.data);

                                                                if (response.data.schemeCode === "SBSTF") {
                                                                    inputData.availableAmountShow = false;
                                                                    varValue.availableAmountShow = false

                                                                } else {
                                                                    inputData.availableAmountShow = true;
                                                                    varValue.availableAmountShow = true
                                                                }
                                                                inputData.availableAmount = response.data.availableBalance;
                                                                varValue.availableAmount = response.data.availableBalance;

                                                                inputData.debitAccountNumberApiCall = "YES";
                                                                this.setState({
                                                                    //loading: false,
                                                                    cbsCustomerGet: true,
                                                                    cbDataBoolean: true,
                                                                    //loading: false,
                                                                    nameOfSchemeApiCall: true,
                                                                    getAccountInfo: true,
                                                                    inputData: inputData,
                                                                    varValue: varValue,
                                                                    showValue: true,
                                                                    loading: false,
                                                                    getData: true,
                                                                    accountBalanceInfo: true,
                                                                    debitDataBoolean: true,


                                                                })
                                                            })
                                                            .catch((error) => {
                                                                console.log(error);
                                                                this.setState({
                                                                    accountBalanceInfo: true,
                                                                    loading: false,
                                                                    getData: false,
                                                                    errorModalBoolean: true,
                                                                    errorTittle: "Finacle Error",
                                                                    debitDataBoolean: true,
                                                                })
                                                            })

                                                    })
                                                    .catch((error) => {
                                                        this.setState({
                                                            nameOfSchemeApiCall: true,
                                                            getData: true,
                                                        });
                                                        console.log(error);
                                                    })
                                            }
                                            //new
                                            else {
                                                let object = {
                                                    currency: typeof response.data.currency === "object" ? response.data.currency.key : response.data.currency,
                                                };
                                                if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                                                    object.natureAccount = "Individual-Retail";
                                                } else {
                                                    object.natureAccount = "Non-Individual";
                                                }
                                                let Urls = backEndServerURL + "/FDRDPSSchemeCode/getAccountType";
                                                axios.post(Urls, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                    .then((getAccountType) => {
                                                        inputData.repaymentAccount = response.data.accountNumber;
                                                        inputData.interestCreditAccount = response.data.accountNumber;
                                                        inputData.accountCbNumber = response.data.custId;
                                                        inputData.debitAccountName = response.data.accountName;
                                                        //inputData.availableAmount = response.data.availableBalance
                                                        inputData.accountRestriction = response.data.accountRestriction;
                                                        console.log(this.props.freeFlag1);
                                                        inputData.currency = this.findByVarNameGetKeyValue(response.data.acctCurrency, this.state.currency);
                                                        varValue.currency = this.findByVarNameGetKeyValue(response.data.acctCurrency, this.state.currency);
                                                        console.log(this.props.commonJsonForm);
                                                        console.log(varValue.currency);


                                                        inputData.sectorCode = this.findByVarNameGetKeyValue(response.data.sectorCode, this.state.sectorList);
                                                        inputData.subSectorCode = this.findByVarNameGetKeyValue(response.data.subsectorcode, this.state.subsectorList);
                                                        varValue.repaymentAccount = response.data.accountNumber;
                                                        varValue.interestCreditAccount = response.data.accountNumber;
                                                        varValue.accountCbNumber = response.data.custId;
                                                        varValue.accountRestriction = response.data.accountRestriction;
                                                        // varValue.currency = response.data.currency;
                                                        varValue.debitAccountName = response.data.accountName;
                                                        varValue.sectorCode = this.findByVarNameGetKeyValue(response.data.sectorCode, this.state.sectorList);
                                                        varValue.subSectorCode = this.findByVarNameGetKeyValue(response.data.subsectorcode, this.state.subsectorList);
                                                        let jsonArrayServiceList = this.props.serviceList;
                                                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                                            let jsonObjects = jsonArrayServiceList[i];
                                                            if (jsonObjects.varName === "accountType") {
                                                                this.props.serviceList[i].enum = getAccountType.data;
                                                                console.log(this.props.serviceList[i])
                                                            }
                                                        }
                                                        inputData.accountTypeApiCall = true;
                                                        varValue.accountTypeApiCall = true;
                                                        let urls = backEndServerURL + "/intgr/accountBalanceInfo/" + (this.props.accountNo).trim();
                                                        axios.get(urls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                            .then((response) => {
                                                                console.log(response.data);

                                                                if (response.data.schemeCode === "SBSTF") {
                                                                    inputData.availableAmountShow = false;
                                                                    varValue.availableAmountShow = false

                                                                } else {
                                                                    inputData.availableAmountShow = true;
                                                                    varValue.availableAmountShow = true
                                                                }
                                                                inputData.availableAmount = response.data.availableBalance;
                                                                varValue.availableAmount = response.data.availableBalance;

                                                                inputData.debitAccountNumberApiCall = "YES";
                                                                this.setState({
                                                                    //loading: false,
                                                                    cbsCustomerGet: true,
                                                                    cbDataBoolean: true,
                                                                    //loading: false,
                                                                    getAccountType: true,
                                                                    //loading: false,
                                                                    nameOfSchemeApiCall: true,
                                                                    getAccountInfo: true,
                                                                    inputData: inputData,
                                                                    varValue: varValue,
                                                                    showValue: true,
                                                                    loading: false,
                                                                    getData: true,
                                                                    accountBalanceInfo: true,
                                                                    debitDataBoolean: true,
                                                                })
                                                            })
                                                            .catch((error) => {
                                                                console.log(error);
                                                                this.setState({
                                                                    accountBalanceInfo: true,
                                                                    loading: false,
                                                                    getData: false,
                                                                    errorModalBoolean: true,
                                                                    errorTittle: "Finacle Error",
                                                                    debitDataBoolean: true,
                                                                })
                                                            })

                                                    })
                                                    .catch((error) => {
                                                        this.setState({
                                                            getAccountType: true,
                                                        });
                                                        console.log(error);
                                                    })

                                            }

                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            this.setState({
                                                getData: false,
                                                loading: false,
                                                errorModalBoolean: true,
                                                errorTittle: "Finacle Error",
                                                getAccountInfo: true,
                                            })
                                        })

                                    //Account inquiry End


                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.setState({
                                        cbsCustomerGet: true,
                                        loading: false,
                                        getData: false,
                                        errorModalBoolean: true,
                                        cbDataBoolean: true,
                                        errorTittle: "Invalid CB Number"
                                    })
                                })

                        }

                    }
                } else {
                    if (this.props.freeFlag1 === "FDR Account Opening - with Existing Operative Account") {
                        this.state.inputData.debitAccountNumberApiCall = "NO";
                        this.state.inputData.repaymentAccountApiCall = "NO";
                        this.state.inputData.interestCreditAccountApiCall = "NO";
                        /* let accountUrls = backEndServerURL + "/GetCustomAcctDetailsRequest/" + (this.props.accountNo).trim();
                         axios.get(accountUrls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                             .then((response) => {*/
                        this.state.inputData.repaymentAccountApiCall = "YES";
                        this.state.inputData.interestCreditAccountApiCall = "YES";
                        inputData.repaymentAccountApiCall = "YES";
                        inputData.interestCreditAccountApiCall = "YES";

                        varValue.repaymentAccountApiCall = "YES";
                        varValue.interestCreditAccountApiCall = "YES";

                        //inputData.interestCreditName = response.data.acctName;
                        // varValue.interestCreditName = response.data.acctName;
                        //inputData.repaymentName = response.data.acctName;
                        //varValue.repaymentName = response.data.acctName;
                        if (this.props.freeFlag1 === "FDR Account Opening - with Existing Operative Account" || this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                            inputData.nomineeInformationVisible = "YES";
                            varValue.nomineeInformationVisible = "YES";
                            let url = backEndServerURL + "/cbsCustomerGet/" + this.state.getcustomerId;
                            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    if( response.data.nationality!==undefined && response.data.nationality!==null  && response.data.nationality!=="BD"){
                                        this.setState({
                                            showValue: false,
                                            getData: false,
                                            loading: false,

                                        });
                                        alert("Nationality BD Required")
                                        return 0;
                                    }
                                    console.log(response.data);
                                    inputData.customerName = response.data.customerName;
                                    varValue.customerName = response.data.customerName;
                                    inputData.customerStatus = response.data.customerStatus;
                                    varValue.customerStatus = response.data.customerStatus;
                                    inputData.customerTitle = response.data.salutation;
                                    varValue.customerTitle = response.data.salutation;
                                    inputData.shortName = response.data.shortName;
                                    varValue.shortName = response.data.shortName;
                                    inputData.nomineeName = response.data.customerName;
                                    varValue.nomineeName = response.data.customerName;
                                    inputData.occupationCode = response.data.occupationCode;
                                    varValue.occupationCode = response.data.occupationCode;
                                    inputData.gender = response.data.gender;
                                    varValue.gender = response.data.gender;
                                    inputData.nidVerified = "NO";
                                    varValue.nidVerified = "NO";
                                    inputData.occupationCodePriority = response.data.occupationCode;
                                    varValue.occupationCodePriority = response.data.occupationCode;
                                    inputData.occupationCodeStaff = response.data.occupationCode;
                                    varValue.occupationCodeStaff = response.data.occupationCode;

                                    inputData.sbsCode = response.data.sbsCode;
                                    varValue.sbsCode = response.data.sbsCode;
                                    if (response.data.NIDBLOCK !== undefined) {
                                        inputData.nid = response.data.NIDBLOCK.referenceNum;
                                        varValue.nid = response.data.NIDBLOCK.referenceNum;
                                    }

                                    if (response.data.PASSPORTBLOCK !== undefined) {
                                        inputData.passport = response.data.PASSPORTBLOCK.referenceNum;
                                        varValue.passport = response.data.PASSPORTBLOCK.referenceNum;
                                    }
                                    if (response.data.COMMEMLBLOCK !== undefined) {
                                        inputData.email = response.data.COMMEMLBLOCK.emailInfo;
                                        varValue.email = response.data.COMMEMLBLOCK.emailInfo
                                    }
                                    if (response.data.ETINBLOCK !== undefined) {
                                        inputData.eTin = response.data.ETINBLOCK.referenceNum;
                                        inputData.tin = response.data.ETINBLOCK.referenceNum;
                                        inputData.etin = response.data.ETINBLOCK.referenceNum;
                                        varValue.eTin = response.data.ETINBLOCK.referenceNum;
                                        varValue.tin = response.data.ETINBLOCK.referenceNum;
                                        varValue.etin = response.data.ETINBLOCK.referenceNum;
                                    }
                                    if (response.data.DLBLOCK !== undefined) {
                                        inputData.drivingLicense = response.data.DLBLOCK.referenceNum;
                                        varValue.drivingLicense = response.data.DLBLOCK.referenceNum;
                                    }
                                    if (response.data.BCBLOCK !== undefined) {
                                        inputData.registrationNo = response.data.BCBLOCK.referenceNum;
                                        varValue.registrationNo = response.data.BCBLOCK.referenceNum;
                                    }
                                    if (response.data.COMMPH1BLOCK !== undefined) {
                                        if (response.data.COMMPH1BLOCK.phoneNumCountryCode === "88") {
                                            inputData.mobileTypeIndividual = "Local";
                                            varValue.mobileTypeIndividual = "Local"
                                        } else if (response.data.COMMPH1BLOCK.phoneNumCountryCode === "0") {
                                            inputData.mobileTypeIndividual = "Overseas";
                                            inputData.countryCodeIndividual = this.findByVarNameGetKeyValue(response.data.COMMPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList);
                                            varValue.mobileTypeIndividual = "Overseas";
                                            varValue.countryCodeIndividual = this.findByVarNameGetKeyValue(response.data.COMMPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)
                                        }

                                    }
                                    if (response.data.COMMPH1BLOCK !== undefined) {
                                        inputData.mobileNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                        inputData.designatedMobileNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                        inputData.phone = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                        varValue.mobileNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                        varValue.designatedMobileNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                        varValue.phone = response.data.COMMPH1BLOCK.phoneNumLocalCode
                                    }
                                    if (response.data.MailingBLOCK !== undefined) {
                                        inputData.SelectCommunicationAddress = response.data.MailingBLOCK.addrCategory;
                                        inputData.communicationAddress1 = response.data.MailingBLOCK.addrLine1;
                                        inputData.communicationAddress2 = response.data.MailingBLOCK.addrLine2;
                                        inputData.postalCode = response.data.MailingBLOCK.postalCode;
                                        inputData.state = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.city, this.state.state);
                                        inputData.city = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.state, this.state.city);
                                        inputData.country = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.country, this.state.country);

                                        varValue.SelectCommunicationAddress = response.data.MailingBLOCK.addrCategory;
                                        varValue.communicationAddress1 = response.data.MailingBLOCK.addrLine1;
                                        varValue.communicationAddress2 = response.data.MailingBLOCK.addrLine2;
                                        varValue.postalCode = response.data.MailingBLOCK.postalCode;
                                        varValue.state = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.city, this.state.state);
                                        varValue.city = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.state, this.state.city);
                                        varValue.country = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.country, this.state.country)
                                    }


                                    let getDob = response.data.birthDate;
                                    if (getDob !== null & getDob !== undefined) {
                                        getDob = getDob.split('T')[0];
                                    }
                                    //inputData.currency = this.findByVarNameGetKeyValue(response.data.currencyCode, this.state.currency);
                                    //varValue.currency = this.findByVarNameGetKeyValue(response.data.currencyCode, this.state.currency);
                                    inputData.dob = getDob;
                                    inputData.selectedDate = getDob;
                                    varValue.dob = getDob;
                                    varValue.selectedDate = getDob;
                                    inputData.cbNumberApiCall = "YES";
                                    this.setState({
                                        //loading: false,
                                        cbsCustomerGet: true,
                                        cbDataBoolean: true,
                                        //loading: false,
                                        nameOfSchemeApiCall: true,
                                        getAccountInfo: true,
                                        inputData: inputData,
                                        varValue: varValue,
                                        showValue: true,
                                        loading: false,
                                        getData: true,
                                        accountBalanceInfo: true,
                                        debitDataBoolean: true,


                                    })
                                    //Account inquiry start
                                    /* let accountUrls = backEndServerURL + "/getAccountInfo/" + (this.props.accountNo).trim();
                                     axios.get(accountUrls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                         .then((response) => {
                                             inputData.clearingLimitDR = response.data.drclrlim;
                                             inputData.clearingLimitCR = response.data.crclrlim;
                                             inputData.TransferLimitCR = response.data.crtxlim;
                                             if (response.data.nomineeInfoDetails !== undefined && response.data.nomineeInfoDetails !== null) {
                                                 inputData.nomineeExistNot = "Y";
                                                 varValue.nomineeExistNot = "Y";
                                                 inputData.existingNomineeNumber = response.data.nomineeInfoDetails.length;
                                                 varValue.existingNomineeNumber = response.data.nomineeInfoDetails.length;
                                             } else {
                                                 inputData.nomineeExistNot = "N";
                                                 varValue.nomineeExistNot = "N";
                                             }
                                             if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                                                 let object = {
                                                     currency: typeof response.data.currency === "object" ? response.data.currency.key : response.data.currency,
                                                 };
                                                 if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                                                     object.natureAccount = "Individual-Retail";
                                                 } else {
                                                     object.natureAccount = "Non-Individual"
                                                 }
                                                 let Urls = backEndServerURL + "/FDRDPSSchemeCode/getDPSNameOfScheme";
                                                 axios.post(Urls, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                     .then((getAccountType) => {
                                                         inputData.repaymentAccount = response.data.accountNumber;
                                                         inputData.interestCreditAccount = response.data.accountNumber;
                                                         inputData.accountCbNumber = response.data.custId;
                                                         inputData.debitAccountName = response.data.accountName;
                                                         //inputData.availableAmount = response.data.availableBalance
                                                         inputData.accountRestriction = response.data.accountRestriction;
                                                         inputData.currency = response.data.currency;
                                                         console.log(response.data.sectorCode);
                                                         console.log(this.state.sectorList);
                                                         console.log(this.state.subsectorList);
                                                         inputData.sectorCode = this.findByVarNameGetKeyValue(response.data.sectorCode, this.state.sectorList);
                                                         inputData.subSectorCode = this.findByVarNameGetKeyValue(response.data.subsectorcode, this.state.subsectorList);
                                                         inputData.repaymentAccounts = response.data.accountNumber;
                                                         inputData.repaymentsName = response.data.accountName;
                                                         inputData.repaymentsAccountApiCall = "YES";


                                                         varValue.repaymentAccounts = response.data.accountNumber;
                                                         varValue.repaymentsName = response.data.accountName;
                                                         varValue.repaymentsAccountApiCall = "YES";
                                                         varValue.repaymentAccount = response.data.accountNumber;
                                                         varValue.interestCreditAccount = response.data.accountNumber;
                                                         varValue.accountCbNumber = response.data.custId;
                                                         varValue.accountRestriction = response.data.accountRestriction;
                                                         varValue.currency = response.data.currency;
                                                         varValue.debitAccountName = response.data.accountName;
                                                         inputData.sectorCodeApicall = true;
                                                         varValue.sectorCodeApicall = true;
                                                         varValue.sectorCode = this.findByVarNameGetKeyValue(response.data.sectorCode, this.state.sectorList);
                                                         varValue.subSectorCode = this.findByVarNameGetKeyValue(response.data.subsectorcode, this.state.subsectorList);
                                                         let jsonArrayServiceList = this.props.commonJsonForm;
                                                         for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                                             let jsonObjects = jsonArrayServiceList[i];
                                                             if (jsonObjects.varName === "nameOfScheme") {
                                                                 this.props.commonJsonForm[i].enum = getAccountType.data;
                                                                 console.log(this.props.commonJsonForm[i])
                                                             }
                                                         }
                                                         inputData.nameOfSchemeApiCall = true;
                                                         varValue.nameOfSchemeApiCall = true;
                                                         let urls = backEndServerURL + "/intgr/accountBalanceInfo/" + (this.props.accountNo).trim();
                                                         axios.get(urls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                             .then((response) => {
                                                                 console.log(response.data);

                                                                 if (response.data.schemeCode === "SBSTF") {
                                                                     inputData.availableAmountShow = false;
                                                                     varValue.availableAmountShow = false

                                                                 } else {
                                                                     inputData.availableAmountShow = true;
                                                                     varValue.availableAmountShow = true
                                                                 }
                                                                 inputData.availableAmount = response.data.availableBalance;
                                                                 varValue.availableAmount = response.data.availableBalance;

                                                                 inputData.debitAccountNumberApiCall = "YES";
                                                                 this.setState({
                                                                     //loading: false,
                                                                     cbsCustomerGet: true,
                                                                     cbDataBoolean: true,
                                                                     //loading: false,
                                                                     nameOfSchemeApiCall: true,
                                                                     getAccountInfo: true,
                                                                     inputData: inputData,
                                                                     varValue: varValue,
                                                                     showValue: true,
                                                                     loading: false,
                                                                     getData: true,
                                                                     accountBalanceInfo: true,
                                                                     debitDataBoolean: true,


                                                                 })
                                                             })
                                                             .catch((error) => {
                                                                 console.log(error);
                                                                 this.setState({
                                                                     accountBalanceInfo: true,
                                                                     loading: false,
                                                                     getData: false,
                                                                     errorModalBoolean: true,
                                                                     errorTittle: "Finacle Error",
                                                                     debitDataBoolean: true,
                                                                 })
                                                             })

                                                     })
                                                     .catch((error) => {
                                                         this.setState({
                                                             nameOfSchemeApiCall: true,
                                                             getData: true,
                                                         });
                                                         console.log(error);
                                                     })
                                             }
                                             //new
                                             else {
                                                 let object = {
                                                     currency: typeof response.data.currency === "object" ? response.data.currency.key : response.data.currency,
                                                 };
                                                 if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                                                     object.natureAccount = "Individual-Retail";
                                                 } else {
                                                     object.natureAccount = "Non-Individual";
                                                 }
                                                 let Urls = backEndServerURL + "/FDRDPSSchemeCode/getAccountType";
                                                 axios.post(Urls, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                     .then((getAccountType) => {
                                                         inputData.repaymentAccount = response.data.accountNumber;
                                                         inputData.interestCreditAccount = response.data.accountNumber;
                                                         inputData.accountCbNumber = response.data.custId;
                                                         inputData.debitAccountName = response.data.accountName;
                                                         //inputData.availableAmount = response.data.availableBalance
                                                         inputData.accountRestriction = response.data.accountRestriction;
                                                         console.log(this.props.freeFlag1);
                                                         inputData.currency = this.findByVarNameGetKeyValue(response.data.acctCurrency, this.state.currency);
                                                         varValue.currency = this.findByVarNameGetKeyValue(response.data.acctCurrency, this.state.currency);
                                                         console.log(this.props.commonJsonForm);
                                                         console.log(varValue.currency);


                                                         inputData.sectorCode = this.findByVarNameGetKeyValue(response.data.sectorCode, this.state.sectorList);
                                                         inputData.subSectorCode = this.findByVarNameGetKeyValue(response.data.subsectorcode, this.state.subsectorList);
                                                         varValue.repaymentAccount = response.data.accountNumber;
                                                         varValue.interestCreditAccount = response.data.accountNumber;
                                                         varValue.accountCbNumber = response.data.custId;
                                                         varValue.accountRestriction = response.data.accountRestriction;
                                                         // varValue.currency = response.data.currency;
                                                         varValue.debitAccountName = response.data.accountName;
                                                         varValue.sectorCode = this.findByVarNameGetKeyValue(response.data.sectorCode, this.state.sectorList);
                                                         varValue.subSectorCode = this.findByVarNameGetKeyValue(response.data.subsectorcode, this.state.subsectorList);
                                                         let jsonArrayServiceList = this.props.serviceList;
                                                         for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                                             let jsonObjects = jsonArrayServiceList[i];
                                                             if (jsonObjects.varName === "accountType") {
                                                                 this.props.serviceList[i].enum = getAccountType.data;
                                                                 console.log(this.props.serviceList[i])
                                                             }
                                                         }
                                                         inputData.accountTypeApiCall = true;
                                                         varValue.accountTypeApiCall = true;
                                                         let urls = backEndServerURL + "/intgr/accountBalanceInfo/" + (this.props.accountNo).trim();
                                                         axios.get(urls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                             .then((response) => {
                                                                 console.log(response.data);

                                                                 if (response.data.schemeCode === "SBSTF") {
                                                                     inputData.availableAmountShow = false;
                                                                     varValue.availableAmountShow = false

                                                                 } else {
                                                                     inputData.availableAmountShow = true;
                                                                     varValue.availableAmountShow = true
                                                                 }
                                                                 inputData.availableAmount = response.data.availableBalance;
                                                                 varValue.availableAmount = response.data.availableBalance;

                                                                 inputData.debitAccountNumberApiCall = "YES";
                                                                 this.setState({
                                                                     //loading: false,
                                                                     cbsCustomerGet: true,
                                                                     cbDataBoolean: true,
                                                                     //loading: false,
                                                                     getAccountType: true,
                                                                     //loading: false,
                                                                     nameOfSchemeApiCall: true,
                                                                     getAccountInfo: true,
                                                                     inputData: inputData,
                                                                     varValue: varValue,
                                                                     showValue: true,
                                                                     loading: false,
                                                                     getData: true,
                                                                     accountBalanceInfo: true,
                                                                     debitDataBoolean: true,
                                                                 })
                                                             })
                                                             .catch((error) => {
                                                                 console.log(error);
                                                                 this.setState({
                                                                     accountBalanceInfo: true,
                                                                     loading: false,
                                                                     getData: false,
                                                                     errorModalBoolean: true,
                                                                     errorTittle: "Finacle Error",
                                                                     debitDataBoolean: true,
                                                                 })
                                                             })

                                                     })
                                                     .catch((error) => {
                                                         this.setState({
                                                             getAccountType: true,
                                                         });
                                                         console.log(error);
                                                     })

                                             }

                                         })
                                         .catch((error) => {
                                             console.log(error);
                                             this.setState({
                                                 getData: false,
                                                 loading: false,
                                                 errorModalBoolean: true,
                                                 errorTittle: "Finacle Error",
                                                 getAccountInfo: true,
                                             })
                                         })
*/
                                    //Account inquiry End


                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.setState({
                                        cbsCustomerGet: true,
                                        loading: false,
                                        getData: false,
                                        errorModalBoolean: true,
                                        cbDataBoolean: true,
                                        errorTittle: "Invalid CB Number"
                                    })
                                })

                        }

                        /*  })*/


                    } else {
                        if (this.props.freeFlag1 === "FDR Account Opening - with Existing Operative Account" || this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                            inputData.nomineeInformationVisible = "YES";
                            varValue.nomineeInformationVisible = "YES";
                            let url = backEndServerURL + "/cbsCustomerGet/" + this.state.getcustomerId;
                            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    if( response.data.nationality!==undefined && response.data.nationality!==null  && response.data.nationality!=="BD"){
                                        this.setState({
                                            showValue: false,
                                            getData: false,
                                            loading: false,

                                        });
                                        alert("Nationality BD Required")
                                        return 0;
                                    }
                                    console.log(response.data);
                                    inputData.customerName = response.data.customerName;
                                    varValue.customerName = response.data.customerName;
                                    inputData.customerStatus = response.data.customerStatus;
                                    varValue.customerStatus = response.data.customerStatus;
                                    inputData.customerTitle = response.data.salutation;
                                    varValue.customerTitle = response.data.salutation;
                                    inputData.shortName = response.data.shortName;
                                    varValue.shortName = response.data.shortName;
                                    inputData.nomineeName = response.data.customerName;
                                    varValue.nomineeName = response.data.customerName;
                                    inputData.occupationCode = response.data.occupationCode;
                                    varValue.occupationCode = response.data.occupationCode;
                                    inputData.gender = response.data.gender;
                                    varValue.gender = response.data.gender;
                                    inputData.nidVerified = "NO";
                                    varValue.nidVerified = "NO";
                                    inputData.occupationCodePriority = response.data.occupationCode;
                                    varValue.occupationCodePriority = response.data.occupationCode;
                                    inputData.occupationCodeStaff = response.data.occupationCode;
                                    varValue.occupationCodeStaff = response.data.occupationCode;

                                    inputData.sbsCode = response.data.sbsCode;
                                    varValue.sbsCode = response.data.sbsCode;
                                    if (response.data.NIDBLOCK !== undefined) {
                                        inputData.nid = response.data.NIDBLOCK.referenceNum;
                                        varValue.nid = response.data.NIDBLOCK.referenceNum;
                                    }

                                    if (response.data.PASSPORTBLOCK !== undefined) {
                                        inputData.passport = response.data.PASSPORTBLOCK.referenceNum;
                                        varValue.passport = response.data.PASSPORTBLOCK.referenceNum;
                                    }
                                    if (response.data.COMMEMLBLOCK !== undefined) {
                                        inputData.email = response.data.COMMEMLBLOCK.emailInfo;
                                        varValue.email = response.data.COMMEMLBLOCK.emailInfo
                                    }
                                    if (response.data.ETINBLOCK !== undefined) {
                                        inputData.eTin = response.data.ETINBLOCK.referenceNum;
                                        inputData.tin = response.data.ETINBLOCK.referenceNum;
                                        inputData.etin = response.data.ETINBLOCK.referenceNum;
                                        varValue.eTin = response.data.ETINBLOCK.referenceNum;
                                        varValue.tin = response.data.ETINBLOCK.referenceNum;
                                        varValue.etin = response.data.ETINBLOCK.referenceNum;
                                    }
                                    if (response.data.DLBLOCK !== undefined) {
                                        inputData.drivingLicense = response.data.DLBLOCK.referenceNum;
                                        varValue.drivingLicense = response.data.DLBLOCK.referenceNum;
                                    }
                                    if (response.data.BCBLOCK !== undefined) {
                                        inputData.registrationNo = response.data.BCBLOCK.referenceNum;
                                        varValue.registrationNo = response.data.BCBLOCK.referenceNum;
                                    }
                                    if (response.data.COMMPH1BLOCK !== undefined) {
                                        if (response.data.COMMPH1BLOCK.phoneNumCountryCode === "88") {
                                            inputData.mobileTypeIndividual = "Local";
                                            varValue.mobileTypeIndividual = "Local"
                                        } else if (response.data.COMMPH1BLOCK.phoneNumCountryCode === "0") {
                                            inputData.mobileTypeIndividual = "Overseas";
                                            inputData.countryCodeIndividual = this.findByVarNameGetKeyValue(response.data.COMMPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList);
                                            varValue.mobileTypeIndividual = "Overseas";
                                            varValue.countryCodeIndividual = this.findByVarNameGetKeyValue(response.data.COMMPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)
                                        }

                                    }
                                    if (response.data.COMMPH1BLOCK !== undefined) {
                                        inputData.mobileNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                        inputData.designatedMobileNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                        inputData.phone = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                        varValue.mobileNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                        varValue.designatedMobileNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                        varValue.phone = response.data.COMMPH1BLOCK.phoneNumLocalCode
                                    }
                                    if (response.data.MailingBLOCK !== undefined) {
                                        inputData.SelectCommunicationAddress = response.data.MailingBLOCK.addrCategory;
                                        inputData.communicationAddress1 = response.data.MailingBLOCK.addrLine1;
                                        inputData.communicationAddress2 = response.data.MailingBLOCK.addrLine2;
                                        inputData.postalCode = response.data.MailingBLOCK.postalCode;
                                        inputData.state = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.city, this.state.state);
                                        inputData.city = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.state, this.state.city);
                                        inputData.country = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.country, this.state.country);

                                        varValue.SelectCommunicationAddress = response.data.MailingBLOCK.addrCategory;
                                        varValue.communicationAddress1 = response.data.MailingBLOCK.addrLine1;
                                        varValue.communicationAddress2 = response.data.MailingBLOCK.addrLine2;
                                        varValue.postalCode = response.data.MailingBLOCK.postalCode;
                                        varValue.state = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.city, this.state.state);
                                        varValue.city = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.state, this.state.city);
                                        varValue.country = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.country, this.state.country)
                                    }


                                    let getDob = response.data.birthDate;
                                    if (getDob !== null & getDob !== undefined) {
                                        getDob = getDob.split('T')[0];
                                    }
                                    //inputData.currency = this.findByVarNameGetKeyValue(response.data.currencyCode, this.state.currency);
                                    //varValue.currency = this.findByVarNameGetKeyValue(response.data.currencyCode, this.state.currency);
                                    inputData.dob = getDob;
                                    inputData.selectedDate = getDob;
                                    varValue.dob = getDob;
                                    varValue.selectedDate = getDob;
                                    inputData.cbNumberApiCall = "YES";
                                    this.setState({
                                        //loading: false,
                                        cbsCustomerGet: true,
                                        cbDataBoolean: true,
                                        //loading: false,
                                        getAccountType: true,
                                        //loading: false,
                                        nameOfSchemeApiCall: true,
                                        getAccountInfo: true,
                                        inputData: inputData,
                                        varValue: varValue,
                                        showValue: true,
                                        loading: false,
                                        getData: true,
                                        accountBalanceInfo: true,
                                        debitDataBoolean: true,
                                    })
                                    //Account inquiry start
                                    /* let accountUrls = backEndServerURL + "/getAccountInfo/" + (this.props.accountNo).trim();
                                     axios.get(accountUrls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                         .then((response) => {
                                             inputData.clearingLimitDR = response.data.drclrlim;
                                             inputData.clearingLimitCR = response.data.crclrlim;
                                             inputData.TransferLimitCR = response.data.crtxlim;
                                             if (response.data.nomineeInfoDetails !== undefined && response.data.nomineeInfoDetails !== null) {
                                                 inputData.nomineeExistNot = "Y";
                                                 varValue.nomineeExistNot = "Y";

                                                 inputData.existingNomineeNumber = response.data.nomineeInfoDetails.length;
                                                 varValue.existingNomineeNumber = response.data.nomineeInfoDetails.length;
                                             } else {
                                                 inputData.nomineeExistNot = "N";
                                                 varValue.nomineeExistNot = "N"
                                             }
                                             if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                                                 let object = {
                                                     currency: typeof response.data.currency === "object" ? response.data.currency.key : response.data.currency,
                                                 };
                                                 if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                                                     object.natureAccount = "Individual-Retail";
                                                 } else {
                                                     object.natureAccount = "Non-Individual"
                                                 }
                                                 let Urls = backEndServerURL + "/FDRDPSSchemeCode/getDPSNameOfScheme";
                                                 axios.post(Urls, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                     .then((getAccountType) => {
                                                         inputData.repaymentAccount = response.data.accountNumber;
                                                         inputData.interestCreditAccount = response.data.accountNumber;
                                                         inputData.accountCbNumber = response.data.custId;
                                                         inputData.debitAccountName = response.data.accountName;
                                                         //inputData.availableAmount = response.data.availableBalance
                                                         inputData.accountRestriction = response.data.accountRestriction;
                                                         inputData.currency = response.data.currency;
                                                         console.log(response.data.sectorCode);
                                                         console.log(this.state.sectorList);
                                                         console.log(this.state.subsectorList);
                                                         inputData.sectorCode = this.findByVarNameGetKeyValue(response.data.sectorCode, this.state.sectorList);
                                                         inputData.subSectorCode = this.findByVarNameGetKeyValue(response.data.subsectorcode, this.state.subsectorList);
                                                         inputData.repaymentAccounts = response.data.accountNumber;
                                                         inputData.repaymentsName = response.data.accountName;
                                                         inputData.repaymentsAccountApiCall = "YES";


                                                         varValue.repaymentAccounts = response.data.accountNumber;
                                                         varValue.repaymentsName = response.data.accountName;
                                                         varValue.repaymentsAccountApiCall = "YES";
                                                         varValue.repaymentAccount = response.data.accountNumber;
                                                         varValue.interestCreditAccount = response.data.accountNumber;
                                                         varValue.accountCbNumber = response.data.custId;
                                                         varValue.accountRestriction = response.data.accountRestriction;
                                                         varValue.currency = response.data.currency;
                                                         varValue.debitAccountName = response.data.accountName;
                                                         inputData.sectorCodeApicall = true;
                                                         varValue.sectorCodeApicall = true;
                                                         varValue.sectorCode = this.findByVarNameGetKeyValue(response.data.sectorCode, this.state.sectorList);
                                                         varValue.subSectorCode = this.findByVarNameGetKeyValue(response.data.subsectorcode, this.state.subsectorList);
                                                         let jsonArrayServiceList = this.props.commonJsonForm;
                                                         for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                                             let jsonObjects = jsonArrayServiceList[i];
                                                             if (jsonObjects.varName === "nameOfScheme") {
                                                                 this.props.commonJsonForm[i].enum = getAccountType.data;
                                                                 console.log(this.props.commonJsonForm[i])
                                                             }
                                                         }
                                                         inputData.nameOfSchemeApiCall = true;
                                                         varValue.nameOfSchemeApiCall = true;
                                                         let urls = backEndServerURL + "/intgr/accountBalanceInfo/" + (this.props.accountNo).trim();
                                                         axios.get(urls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                             .then((response) => {
                                                                 console.log(response.data);

                                                                 if (response.data.schemeCode === "SBSTF") {
                                                                     inputData.availableAmountShow = false;
                                                                     varValue.availableAmountShow = false

                                                                 } else {
                                                                     inputData.availableAmountShow = true;
                                                                     varValue.availableAmountShow = true
                                                                 }
                                                                 inputData.availableAmount = response.data.availableBalance;
                                                                 varValue.availableAmount = response.data.availableBalance;

                                                                 inputData.debitAccountNumberApiCall = "YES";
                                                                 this.setState({
                                                                     //loading: false,
                                                                     cbsCustomerGet: true,
                                                                     cbDataBoolean: true,
                                                                     //loading: false,
                                                                     nameOfSchemeApiCall: true,
                                                                     getAccountInfo: true,
                                                                     inputData: inputData,
                                                                     varValue: varValue,
                                                                     showValue: true,
                                                                     loading: false,
                                                                     getData: true,
                                                                     accountBalanceInfo: true,
                                                                     debitDataBoolean: true,


                                                                 })
                                                             })
                                                             .catch((error) => {
                                                                 console.log(error);
                                                                 this.setState({
                                                                     accountBalanceInfo: true,
                                                                     loading: false,
                                                                     getData: false,
                                                                     errorModalBoolean: true,
                                                                     errorTittle: "Finacle Error",
                                                                     debitDataBoolean: true,
                                                                 })
                                                             })

                                                     })
                                                     .catch((error) => {
                                                         this.setState({
                                                             nameOfSchemeApiCall: true,
                                                             getData: true,
                                                         });
                                                         console.log(error);
                                                     })
                                             }
                                             //new
                                             else {
                                                 let object = {
                                                     currency: typeof response.data.currency === "object" ? response.data.currency.key : response.data.currency,
                                                 };
                                                 if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                                                     object.natureAccount = "Individual-Retail";
                                                 } else {
                                                     object.natureAccount = "Non-Individual";
                                                 }
                                                 let Urls = backEndServerURL + "/FDRDPSSchemeCode/getAccountType";
                                                 axios.post(Urls, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                     .then((getAccountType) => {
                                                         inputData.repaymentAccount = response.data.accountNumber;
                                                         inputData.interestCreditAccount = response.data.accountNumber;
                                                         inputData.accountCbNumber = response.data.custId;
                                                         inputData.debitAccountName = response.data.accountName;
                                                         //inputData.availableAmount = response.data.availableBalance
                                                         inputData.accountRestriction = response.data.accountRestriction;
                                                         console.log(this.props.freeFlag1);
                                                         inputData.currency = this.findByVarNameGetKeyValue(response.data.acctCurrency, this.state.currency);
                                                         varValue.currency = this.findByVarNameGetKeyValue(response.data.acctCurrency, this.state.currency);
                                                         console.log(this.props.commonJsonForm);
                                                         console.log(varValue.currency);


                                                         inputData.sectorCode = this.findByVarNameGetKeyValue(response.data.sectorCode, this.state.sectorList);
                                                         inputData.subSectorCode = this.findByVarNameGetKeyValue(response.data.subsectorcode, this.state.subsectorList);
                                                         varValue.repaymentAccount = response.data.accountNumber;
                                                         varValue.interestCreditAccount = response.data.accountNumber;
                                                         varValue.accountCbNumber = response.data.custId;
                                                         varValue.accountRestriction = response.data.accountRestriction;
                                                         // varValue.currency = response.data.currency;
                                                         varValue.debitAccountName = response.data.accountName;
                                                         varValue.sectorCode = this.findByVarNameGetKeyValue(response.data.sectorCode, this.state.sectorList);
                                                         varValue.subSectorCode = this.findByVarNameGetKeyValue(response.data.subsectorcode, this.state.subsectorList);
                                                         let jsonArrayServiceList = this.props.serviceList;
                                                         for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                                             let jsonObjects = jsonArrayServiceList[i];
                                                             if (jsonObjects.varName === "accountType") {
                                                                 this.props.serviceList[i].enum = getAccountType.data;
                                                                 console.log(this.props.serviceList[i])
                                                             }
                                                         }
                                                         inputData.accountTypeApiCall = true;
                                                         varValue.accountTypeApiCall = true;
                                                         let urls = backEndServerURL + "/intgr/accountBalanceInfo/" + (this.props.accountNo).trim();
                                                         axios.get(urls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                             .then((response) => {
                                                                 console.log(response.data);

                                                                 if (response.data.schemeCode === "SBSTF") {
                                                                     inputData.availableAmountShow = false;
                                                                     varValue.availableAmountShow = false

                                                                 } else {
                                                                     inputData.availableAmountShow = true;
                                                                     varValue.availableAmountShow = true
                                                                 }
                                                                 inputData.availableAmount = response.data.availableBalance;
                                                                 varValue.availableAmount = response.data.availableBalance;

                                                                 inputData.debitAccountNumberApiCall = "YES";
                                                                 this.setState({
                                                                     //loading: false,
                                                                     cbsCustomerGet: true,
                                                                     cbDataBoolean: true,
                                                                     //loading: false,
                                                                     getAccountType: true,
                                                                     //loading: false,
                                                                     nameOfSchemeApiCall: true,
                                                                     getAccountInfo: true,
                                                                     inputData: inputData,
                                                                     varValue: varValue,
                                                                     showValue: true,
                                                                     loading: false,
                                                                     getData: true,
                                                                     accountBalanceInfo: true,
                                                                     debitDataBoolean: true,
                                                                 })
                                                             })
                                                             .catch((error) => {
                                                                 console.log(error);
                                                                 this.setState({
                                                                     accountBalanceInfo: true,
                                                                     loading: false,
                                                                     getData: false,
                                                                     errorModalBoolean: true,
                                                                     errorTittle: "Finacle Error",
                                                                     debitDataBoolean: true,
                                                                 })
                                                             })

                                                     })
                                                     .catch((error) => {
                                                         this.setState({
                                                             getAccountType: true,
                                                         });
                                                         console.log(error);
                                                     })

                                             }

                                         })
                                         .catch((error) => {
                                             console.log(error);
                                             this.setState({
                                                 getData: false,
                                                 loading: false,
                                                 errorModalBoolean: true,
                                                 errorTittle: "Finacle Error",
                                                 getAccountInfo: true,
                                             })
                                         })*/

                                    //Account inquiry End


                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.setState({
                                        cbsCustomerGet: true,
                                        loading: false,
                                        getData: false,
                                        errorModalBoolean: true,
                                        cbDataBoolean: true,
                                        errorTittle: "Invalid CB Number"
                                    })
                                })

                        }

                    }
                }

                /*
                                else if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                                    let url = backEndServerURL + "/fdr/info/" + this.state.getcustomerId + "/" + this.props.accountNo;
                                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response) => {
                                            console.log(response.data);
                                            this.setState({
                                                inputData: this.apiDataSet(response.data, inputData),
                                                varValue: this.apiDataSet(response.data, varValue),
                                                showValue: true,
                                                loading: false,
                                                getData: true
                                            })

                                            this.state.inputData["csDeferal"] = "NO";

                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            this.setState({
                                                loading: false,
                                            })

                                        })
                                }
                */

                console.log("..................", inputData.etin);
                console.log("..................", varValue.etin)
            })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                    })
                })


        }
    }

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    renderDefferalData = () => {
        if (this.state.getData && (this.state.getInitiateData || this.props.appId !== undefined) && this.state.getDeferalList.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Deferral List:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferal Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />
                        <br/>
                    </div>
                </div>

            )
        }

    };
    renderRemarksData = () => {
        if (this.state.getData && (this.state.getInitiateData || this.props.appId !== undefined) && this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }

    };
    renderAddButtonShow = () => {
        if (this.state.getData && (this.state.getInitiateData || this.props.appId !== undefined)) {
            return (
                <React.Fragment>
                    <button
                        className="btn btn-outline-primary btn-sm"

                        style={{
                            width: 100,
                            float: 'right',
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={this.addClick.bind(this)}


                    >Add Deferral
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"

                        style={{
                            width: 100,
                            float: 'right',
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={this.addClickWaiver.bind(this)}


                    >Add Waiver
                    </button>
                </React.Fragment>

            )
        }


    };
    //Deferal
    dynamicTypeOfDeferral = (i) => {
        let field = JSON.parse(JSON.stringify(typeofDeferralDocs));
        field.varName = "typeofDeferralDocs" + i;
        // field.removeDefaultValue=true
        // field.setVariable="deferalType" + i
        return SelectComponent.select(this.state, this.updateComponent, field);
    };
    dynamicDeferral = (i) => {
        if ((this.state.inputData["typeofDeferralDocs" + i]) === "Regulatory") {
            let field = JSON.parse(JSON.stringify(
                {
                    "varName": "deferalType",
                    "type": "select",
                    "label": "Document Type",
                    "enum": this.state.SDRegulatory,
                    "conditional": true,
                    "conditionalVarName": "typeofDeferralDocs" + i,
                    "conditionalVarValue": "Regulatory",

                }
            ));
            field.varName = "deferalType" + i;
            return SelectComponent.select(this.state, this.updateComponent, field);
        } else if ((this.state.inputData["typeofDeferralDocs" + i]) === "Non Regulatory") {
            let field = JSON.parse(JSON.stringify(
                {
                    "varName": "deferalType",
                    "type": "select",
                    "label": "Document Type",
                    "enum": this.state.SDNonRegulatory,
                    "conditional": true,
                    "conditionalVarName": "typeofDeferralDocs" + i,
                    "conditionalVarValue": "Non Regulatory",
                }
            ));
            field.varName = "deferalType" + i;
            return SelectComponent.select(this.state, this.updateComponent, field);

        }
    };

    dynamicDeferralOther = (i) => {
        if (this.state.inputData["typeofDeferralDocs" + i] === "Regulatory" || this.state.inputData["typeofDeferralDocs" + i] === "Non Regulatory") {

            if (this.state.inputData["deferalType" + i] === "Others") {
                let field = JSON.parse(JSON.stringify(deferalOther));
                field.varName = "deferalOther" + i;
                return TextFieldComponent.text(this.state, this.updateComponent, field);
            }
        }
    };
    dynamicDate = (i) => {
        if (this.state.inputData["deferalType" + i] !== undefined) {
            let field = JSON.parse(JSON.stringify(date));
            field.varName = "expireDate" + i;
            return DateComponentDeferal.date(this.state, this.updateComponent, field);
        }
    };

    //Waiver
    dynamicTypeOfWaiver = (i) => {

        let field = JSON.parse(JSON.stringify(typeofWaiverDocs));
        field.varName = "typeofWaiverDocs" + i;
        // field.removeDefaultValue=true
        //         // field.setVariable="waiverType" + i
        return SelectComponent.select(this.state, this.updateComponent, field);
    };
    dynamicWaiver = (i) => {
        if (this.state.inputData["typeofWaiverDocs" + i] === "Regulatory") {
            let field = JSON.parse(JSON.stringify(
                {
                    "varName": "waiverType",
                    "type": "select",
                    "label": "Waiver Type",
                    "enum": this.state.SDRegulatory,
                    "conditional": true,
                    "conditionalVarName": "typeofWaiverDocs" + i,
                    "conditionalVarValue": "Regulatory",
                }
            ));
            field.varName = "waiverType" + i;
            return SelectComponent.select(this.state, this.updateComponent, field);
        } else if (this.state.inputData["typeofWaiverDocs" + i] === "Non Regulatory") {
            let field = JSON.parse(JSON.stringify(
                {
                    "varName": "waiverType",
                    "type": "select",
                    "label": "Waiver Type",
                    "enum": this.state.SDNonRegulatory,
                    "conditional": true,
                    "conditionalVarName": "typeofWaiverDocs" + i,
                    "conditionalVarValue": "Non Regulatory",
                }
            ));
            field.varName = "waiverType" + i;
            return SelectComponent.select(this.state, this.updateComponent, field);
        }

    };

    dynamicWaiverOther = (i) => {
        if (this.state.inputData["typeofWaiverDocs" + i] === "Regulatory" || this.state.inputData["typeofWaiverDocs" + i] === "Non Regulatory") {

            if (this.state.inputData["waiverType" + i] === "Others") {
                let field = JSON.parse(JSON.stringify(waiverOther));
                field.varName = "waiverOther" + i;
                return TextFieldComponent.text(this.state, this.updateComponent, field);
            }
        }
    };


    updateComponent = () => {
        this.forceUpdate();
    };
    handleChangeComments = () => {

    };
    makeReadOnlyObjec = (object) => {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            returnObjectVariables[i]["readOnly"] = true;
        }
        return returnObject;


    };
    returnJsonForm = () => {
        if (this.state.getData && (this.props.appId !== undefined || this.state.getInitiateData)) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.checkReadOnlyField(this.props.commonJsonForm), this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)
            )


        } else if (this.state.getData && (this.state.accountBalanceInfo && (this.state.getAccountType || this.state.nameOfSchemeApiCall) && this.state.cbsCustomerGet && this.state.getData && (this.state.debitDataBoolean && this.state.getAccountInfo) || (this.props.appId !== undefined) /*&&  this.state.cbDataBoolean*/)) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.checkReadOnlyField(this.props.commonJsonForm), this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)
            )


        }
    };
    returnServiceList = () => {
        if (this.state.getData && (this.props.appId !== undefined || this.state.getInitiateData)) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.checkReadOnlyField(this.props.serviceList), this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)
            )
        } else if (this.state.getData && (this.state.accountBalanceInfo && (this.state.getAccountType || this.state.nameOfSchemeApiCall) && this.state.cbsCustomerGet && this.state.getData && (this.state.debitDataBoolean && this.state.getAccountInfo) || (this.props.appId !== undefined) /*&&  this.state.cbDataBoolean*/)) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.checkReadOnlyField(this.props.serviceList), this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)
            )
        } else if (this.state.getData && (this.state.accountBalanceInfo && (this.state.getAccountType || this.state.nameOfSchemeApiCall) && this.state.cbsCustomerGet && this.state.getData && (this.state.debitDataBoolean && this.state.getAccountInfo) || (this.props.appId !== undefined) /*&&  this.state.cbDataBoolean*/)) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.checkReadOnlyField(this.props.serviceList), this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)
            )
        } else {

        }
    };

    valueCheck = (data, date) => {
        let num = data;
        if (date === false) {
            if (typeof num === 'number' && date === false) {
                return data;
            } else {

            }
        } else if (date === true) {

            if (num !== 'NaN-NaN-NaN' && date === true) {
                return data;
            } else {

            }
        }

    };

    handleChangeTenure = (event, option) => {
        event.preventDefault();
        if (option !== null) {
            this.state.inputData["tenure"] = option.title;

            this.forceUpdate()


        }
        if (this.state.inputData.tenureType !== undefined && this.state.inputData.tenure !== undefined && this.state.inputData.schemeCode !== undefined && this.state.inputData.amount !== undefined) {
            let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
            let data = {};
            data.schemeCode = this.state.inputData.schemeCode.key;
            data.amount = this.state.inputData.amount;
            data.tenor = this.state.inputData.tenure;
            data.tenorType = this.state.inputData.tenureType;
            data.currency = this.state.inputData.currency;
            if(this.state.inputData.fdValueDate!==undefined){
                data.inputDate = this.state.inputData.fdValueDate;
            }
            else if(this.state.inputData.dpsValueDate!==undefined){
                data.inputDate = this.state.inputData.dpsvalueDate;
            }

            console.log(data);
            this.state.inputData.interestRateApicall = "NO";
            this.state.inputData.interestRate = "";
            this.state.varValue.interestRate = "";
            this.forceUpdate();
            axios.post(interestCalculateAPi, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.interestRate = response.data;
                    this.state.varValue.interestRate = response.data;
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate()
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate()
                })
        }

    };
    TenureFieldDayANdMonth = () => {
        if (this.state.inputData.tenureType === "Day") {
            return (
                <Grid item xs={2}>

                    <React.Fragment>
                        <Grid item>
                            <label className="input-label-common">Tenure</label>
                        </Grid>
                        <Grid item>
                            <Autocomplete onChange={(event, option) => this.handleChangeTenure(event, option)}
                                //defaultValue={this.state.inputData["numberOfNominee"]}
                                          options={tenureDay} getOptionLabel={option => option.title}
                                          renderInput={(params) => <TextField {...params} variant="outlined"

                                                                              style={{paddingRight: 20}} fullWidth/>}
                            />
                        </Grid>
                    </React.Fragment>
                </Grid>
            )
        } else if (this.state.inputData.tenureType === "Monthly") {
            return (
                <Grid item xs={2}>

                    <React.Fragment>
                        <Grid item>
                            <label className="input-label-common">Tenure</label>
                        </Grid>
                        <Grid item>
                            <Autocomplete onChange={(event, option) => this.handleChangeTenure(event, option)}
                                //defaultValue={this.state.inputData["numberOfNominee"]}
                                          options={tenureMonth} getOptionLabel={option => option.title}
                                          renderInput={(params) => <TextField {...params} variant="outlined"

                                                                              style={{paddingRight: 20}} fullWidth/>}
                            />
                        </Grid>
                    </React.Fragment>
                </Grid>
            )
        }
    };
    handleChangeAutoComplete = (event, option, fieldName) => {

        if (fieldName === "schemeCode") {
            if (option !== null) {
                this.state.inputData[fieldName] = option.key;
                console.log(option);
                this.forceUpdate()
            } else if (option === null) {
                this.state.inputData[fieldName] = option;
                console.log(option);
                this.forceUpdate();
                return 0;
            }

        }
        if (this.state.inputData.tenureType !== undefined && this.state.inputData.tenure !== undefined && this.state.inputData.schemeCode !== undefined && this.state.inputData.amount !== undefined) {
            let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
            let data = {};
            data.schemeCode = this.state.inputData.schemeCode.key;
            data.amount = this.state.inputData.amount;
            data.tenor = this.state.inputData.tenure;
            data.tenorType = this.state.inputData.tenureType;
            data.currency = this.state.inputData.currency;
            if(this.state.inputData.fdValueDate!==undefined){
                data.inputDate = this.state.inputData.fdValueDate;
            }
            else if(this.state.inputData.dpsValueDate!==undefined){
                data.inputDate = this.state.inputData.dpsvalueDate;
            }
            console.log(data);
            this.state.inputData.interestRateApicall = "NO";
            this.state.inputData.interestRate = "";
            this.state.varValue.interestRate = "";
            this.forceUpdate();
            axios.post(interestCalculateAPi, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.interestRate = response.data;
                    this.state.varValue.interestRate = response.data;
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate()
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate()
                })
        }
    };
    handleChangeTextField = (event, fieldName) => {

        if (fieldName === "schemeCode") {
            this.state.inputData.schemeCode = event.target.value
        } else if (fieldName === "amount") {
            this.state.inputData.amount = event.target.value

        }
        if (this.state.inputData.tenureType !== undefined && this.state.inputData.tenure !== undefined && this.state.inputData.schemeCode !== undefined && this.state.inputData.amount !== undefined) {
            let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
            let data = {};
            data.schemeCode = this.state.inputData.schemeCode.key;
            data.amount = this.state.inputData.amount;
            data.tenor = this.state.inputData.tenure;
            data.tenorType = this.state.inputData.tenureType;
            data.currency = this.state.inputData.currency;
            if(this.state.inputData.fdValueDate!==undefined){
                data.inputDate = this.state.inputData.fdValueDate;
            }
            else if(this.state.inputData.dpsValueDate!==undefined){
                data.inputDate = this.state.inputData.dpsvalueDate;
            }
            console.log(data);
            this.state.inputData.interestRateApicall = "NO";
            this.state.inputData.interestRate = "";
            this.state.varValue.interestRate = "";
            this.forceUpdate();
            axios.post(interestCalculateAPi, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.interestRate = response.data;
                    this.state.varValue.interestRate = response.data;
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate()
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate()
                })
        }
    };
    fdrInterestRateCalculator = () => {
        if (this.state.getData && this.state.inputData.accountType !== undefined && this.state.getSchemeFlag) {
            //this.state.inputData.fdMaturityDate = finalDate;
            //this.state.varValue.fdMaturityDate = someDate;
            return (
                <React.Fragment>
                    {/*   <Grid item xs={2}>
                    <React.Fragment>
                        <Grid item>
                            <label className="input-label-common">Scheme Codeeeeee</label>
                        </Grid>
                        <Grid item>
                            <TextField

                                variant="outlined"

                                defaultValue={this.state.inputData["schemeCode"]}

                                onBlur={(event) => this.handleChangeTextField(event, "schemeCode")}

                                InputProps={{

                                    readOnly: false
                                }}

                                //InputLabelProps={{shrink:true}}
                                fullWidth
                                style={{paddingRight: 20}}
                            />

                        </Grid>
                    </React.Fragment>
                </Grid>*/}
                    <Grid item xs={2}>
                        <React.Fragment>
                            <Grid item>
                                <label className="input-label-common">Scheme Code *</label>
                            </Grid>
                            <Grid item>
                                <Autocomplete
                                    onChange={(event, option) => this.handleChangeAutoComplete(event, option, "schemeCode")}
                                    //defaultValue={this.state.inputData["numberOfNominee"]}
                                    options={this.state.getAllSchemeCode} getOptionLabel={option => option.value}
                                    //style={ field.readOnly===true?{paddingRight: 20}:{paddingRight: 20}}
                                    style={{paddingRight: 20}}
                                    //style={ {paddingRight: 20}}
                                    renderInput={(params) => /*<TextField {...params} variant="outlined"

                                                                      style={{paddingRight: 20}} fullWidth/>}*/

                                        <TextField {...params}
                                                   variant="outlined"

                                                   fullWidth/>}
                                />


                            </Grid>
                        </React.Fragment>
                    </Grid>
                    <Grid item xs={2}>
                        <React.Fragment>
                            <Grid item>
                                <label className="input-label-common">Amount</label>
                            </Grid>
                            <Grid item>
                                <TextField

                                    variant="outlined"

                                    defaultValue={this.state.inputData["amount"]}
                                    onBlur={(event) => this.handleChangeTextField(event, "amount")}


                                    InputProps={{

                                        readOnly: false
                                    }}

                                    //InputLabelProps={{shrink:true}}
                                    fullWidth
                                    style={{paddingRight: 20}}
                                />

                            </Grid>
                        </React.Fragment>
                    </Grid>
                    <Grid item xs={2}>
                        <React.Fragment>
                            <Grid item>
                                <label className="input-label-common">Interest Rate %</label>
                            </Grid>
                            <Grid item>
                                <TextField

                                    variant="outlined"
                                    value={this.valueCheck(this.state.inputData["interestRate"], false)}

                                    InputProps={{

                                        readOnly: true
                                    }}

                                    //InputLabelProps={{shrink:true}}
                                    fullWidth
                                    style={{color: '#212F3D', background: '#f8f9fa'}}

                                />

                            </Grid>
                        </React.Fragment>
                    </Grid>
                </React.Fragment>

            )


        }
    };
    returnJsonFormForFDR = () => {
        if (this.state.getData && (this.state.inputData.tenure !== undefined && this.state.inputData.tenureType !== undefined)) {
            //this.state.inputData.fdMaturityDate = finalDate;
            //this.state.varValue.fdMaturityDate = someDate;
            return (
                <React.Fragment>
                    <Grid item xs={2}>
                        <React.Fragment>
                            <Grid item>
                                <label className="input-label-common">FD Value Date</label>
                            </Grid>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        style={{
                                            paddingRight: 20
                                        }}

                                        /*  minDate={field.minDate===true?new Date():undefined} maxDate = {field.maxDate===true?new Date():undefined}*/
                                        value={this.state.inputData["fdValueDate"]}
                                        inputVariant="outlined"
                                        // helperText={this.state.errorMessages[field.varName]}
                                        //error={this.state.errorArray[field.varName]}
                                        //label={field.label}
                                        fullWidth
                                        onChange={(date) => {

                                            this.state.selectedDate["fdValueDate"] = date;
                                            this.state.inputData["fdValueDate"] = (date !== null) ? date.toLocaleString('en-US') : "";
                                            /*  let someDate = date;
                                              let x = 6;
                                              let numberOfMonthsToAdd = parseInt(x, 10)
                                              someDate.setMonth(someDate.getMonth() + numberOfMonthsToAdd);
                                              let dates = someDate.getDate();
                                              let month = someDate.getMonth() + 1;
                                              let year = someDate.getFullYear();
                                              let finalDate = dates + '-' + month + '-' + year
                                              this.state.selectedDate["fdMaturityDate"] = someDate.toLocaleString('en-US')
                                              this.state.inputData["fdMaturityDate"] = finalDate.toLocaleString('en-US')*/
                                            this.forceUpdate();
                                        }}
                                        format="dd-MM-yyyy"
                                    />

                                </MuiPickersUtilsProvider>
                            </Grid>
                        </React.Fragment>
                    </Grid>
                    {
                        this.maturitydateCalculate(this.state.inputData.tenureType, this.state.inputData.tenure)

                    }
                    <Grid item xs={2}>
                        <React.Fragment>
                            <Grid item>
                                <label className="input-label-common">FD Maturity Date</label>
                            </Grid>
                            <Grid item>
                                <TextField

                                    variant="outlined"
                                    value={this.valueCheck(this.state.inputData["fdMaturityDate"], true)}
                                    InputProps={{

                                        readOnly: true
                                    }}

                                    //InputLabelProps={{shrink:true}}
                                    fullWidth
                                    style={{color: '#212F3D', background: '#f8f9fa'}}
                                />

                            </Grid>
                        </React.Fragment>
                    </Grid>
                </React.Fragment>

            )


        }
    };
    returnServiceListLast = () => {
        if (this.state.getData && (this.props.appId !== undefined || this.state.getInitiateData) /*&&  this.state.cbDataBoolean*/) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.checkReadOnlyField(this.props.serviceListLast), this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)
            )


        }
    };
    validationForHandleConfirm = (actionType) => {
        let errors = MyValidation.defaultValidation(csRemarks, this.state);
        this.forceUpdate();

        if (errors === true) {
            console.log("Not working");
            return 0;
        }
        if (actionType === "handleConfirm") {

            if (this.props.serviceType === "DPS Opening") {
                if (Number(this.state.inputData.availableAmount) < Number(this.state.inputData.amount)) {
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Insufficient Fund",
                    });
                    return 0;
                } else if (this.state.inputData.amount < 500) {
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Minimum DPS amount should be BDT 500.00 and onward deposit amount must be in multiple of BDT 500.00",
                    });
                    return 0;
                }
            } else if (this.props.serviceType === "FDR Opening") {

                /*    if (Number(this.state.inputData.availableAmount) < Number(this.state.inputData.amount)) {
                        this.setState({
                            loading: false,
                            errorModalBoolean: true,
                            errorTittle: "Insufficient Fund",
                        })
                        return 0;
                    }
                    else if (this.state.inputData.amount < 10000) {
                        this.setState({
                            loading: false,
                            errorModalBoolean: true,
                            errorTittle: "Minimum balance Should be 10000",
                        })
                        return 0;
                    }*/
                if (this.state.inputData.currency !== undefined && this.state.inputData.currency.key !== "BDT" && this.state.inputData.currency !== "BDT") {
                    if (Number(this.state.inputData.availableAmount) < Number(this.state.inputData.amount)) {
                        this.setState({
                            loading: false,
                            errorModalBoolean: true,
                            errorTittle: "Insufficient Fund",
                        });
                        return 0;
                    } else if (this.state.inputData.amount < 500) {
                        this.setState({
                            loading: false,
                            errorModalBoolean: true,
                            errorTittle: "Minimum balance should be 500",
                        });
                        return 0;
                    }
                } else {
                    if (Number(this.state.inputData.availableAmount) < Number(this.state.inputData.amount)) {
                        this.setState({
                            loading: false,
                            errorModalBoolean: true,
                            errorTittle: "Insufficient Fund",
                        });
                        return 0;
                    } else if (this.state.inputData.amount < 10000) {
                        this.setState({
                            loading: false,
                            errorModalBoolean: true,
                            errorTittle: "Minimum balance Should be 10000",
                        });
                        return 0;
                    }
                }
            }

            let dependencyField = ((this.props.commonJsonForm).concat(this.props.serviceList));
            let error = null;
            console.log(this.state.inputData);
            if (this.state.inputData.debitAccountNumberApiCall === "NO") {
                this.state.inputData.debitAccountNumber = "";
                this.updateComponent()
            }
            if (this.state.inputData.interestRateApicall === "NO") {
                this.state.inputData.interestRate = "";
                this.updateComponent()
            }
            if (this.state.inputData.cbNumberApiCall === "NO") {
                this.state.inputData.cbNumber = "";
                this.updateComponent()
            }
            if (this.props.serviceType !== 'DPS Opening') {
                error = MyValidation.defaultValidation(this.emptyValueRemove(this.checkReadOnlyField(dependencyField)), this.state);
                this.forceUpdate();

                if (error === true) {
                    console.log("Not working");
                    return 0;
                }

            } else {
                error = MyValidation.defaultValidation(this.emptyValueRemove(this.checkReadOnlyField(this.props.commonJsonForm)), this.state);
                this.forceUpdate();

                if (error === true) {
                    console.log("Not working");
                    return 0;
                }
            }


            if (this.state.inputData.accountType !== undefined && this.state.inputData["interestRate"] === 0) {
                this.setState({
                    errorModalBoolean: true,
                    errorTittle: "Your Scheme Code Wrong,Please Exist Scheme Code Input"
                });
                return 0;
            }

            if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                this.setState({
                    notificationMessgaeCommonFlag: true,
                    notificationMessgaeCommon: "Minimum DPS amount should be BDT 500.00 and onward deposit amount must be in multiple of BDT 500.00",
                    actionType: actionType,
                    confirmAlert: true,

                })
            } else {
                if (this.state.inputData.currency !== undefined && (this.state.inputData.currency.key !== "BDT" && this.state.inputData.currency !== "BDT")) {
                    this.setState({
                        notificationMessgaeCommon: "USD",
                        notificationMessgaeCommonFlag: true,
                        actionType: actionType,
                        confirmAlert: true,
                        fdrImageShowPopUp: true

                    })
                } else {
                    this.setState({
                        notificationMessgaeCommon: "BDT",
                        notificationMessgaeCommonFlag: true,
                        actionType: actionType,
                        confirmAlert: true,
                        fdrImageShowPopUp: true

                    })

                }


            }


        } else if (actionType === "handleSubmit") {
            /*     if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                     if (Number(this.state.inputData.availableAmount) < Number(this.state.inputData.amount)) {
                         this.setState({
                             loading: false,
                             errorModalBoolean: true,
                             errorTittle: "Insufficient Fund",
                         })
                         return 0;
                     } else if (this.state.inputData.amount < 500) {
                         this.setState({
                             loading: false,
                             errorModalBoolean: true,
                             errorTittle: "Minimum balance Should be 500",
                         })
                         return 0;
                     }
                 }
                 else {
                     if (Number(this.state.inputData.availableAmount) < Number(this.state.inputData.amount)) {
                         this.setState({
                             errorModalBoolean: true,
                             errorTittle: "Insufficient Fund",
                         })
                         return 0;
                     } else if (this.state.inputData.amount < 10000) {
                         this.setState({
                             errorModalBoolean: true,
                             errorTittle: "Minimum balance Should be 10000",
                         })
                         return 0;
                     }
                 }*/
            let dependencyField = (this.props.commonJsonForm.concat(this.props.serviceList, this.props.serviceListLast));
            let error = null;
            console.log(this.state.inputData);
            if (this.state.inputData.debitAccountNumberApiCall === "NO") {
                this.state.inputData.debitAccountNumber = "";
                this.updateComponent()
            }
            if (this.state.inputData["csWaiver"] === "YES") {
                for (let i = 0; i < this.state.waiverValues.length; i++) {
                    let value = this.state.waiverValues[i];
                    let waiverType = this.state.inputData["waiverType" + value];
                    dependencyField.push(
                        {
                            "varName": "typeofWaiverDocs" + value,
                            "type": "select",
                            "grid": 2,
                            "required": true,

                        }, {
                            "varName": "waiverType" + value,
                            "type": "select",
                            "grid": 2,
                            "required": true,

                        },
                    );
                    if (waiverType === "Others") {
                        dependencyField.push({
                            "varName": "waiverOther" + value,
                            "type": "text",
                            "grid": 2,
                            "required": true,

                        },)
                    }
                }
            }


            if (this.state.inputData["csDeferal"] === "YES") {
                for (let i = 0; i < this.state.values.length; i++) {
                    let value = this.state.values[i];
                    let defferalType = this.state.inputData["deferalType" + value];
                    dependencyField.push({
                            "varName": "typeofDeferralDocs" + value,
                            "type": "select",
                            "grid": 2,
                            "required": true,

                        },
                        {
                            "varName": "deferalType" + value,
                            "type": "select",
                            "grid": 2,
                            "required": true,

                        }
                    );
                    if (defferalType === "Others") {
                        dependencyField.push({
                            "varName": "deferalOther" + value,
                            "type": "text",
                            "grid": 2,
                            "required": true,

                        },)
                    }
                    dependencyField.push({
                        "varName": "expireDate" + value,
                        "type": "date",
                        "grid": 2,
                        "required": true,

                    },)

                }

            }

            if (this.props.serviceType !== 'DPS Opening') {
                error = MyValidation.defaultValidation(this.emptyValueRemove(this.checkReadOnlyField(dependencyField)), this.state);
                this.forceUpdate();

                if (error === true) {
                    console.log("Not working");
                    return 0;
                }

            } else {
                error = MyValidation.defaultValidation(this.emptyValueRemove(this.checkReadOnlyField(this.props.commonJsonForm)), this.state);
                this.forceUpdate();

                if (error === true) {
                    console.log("Not working");
                    return 0;
                }
            }
            if (this.props.serviceType === "DPS Opening") {
                this.setState({
                    notificationMessgaeCommon: "Do You Confirm?",
                    errorTittle: "Do You Confirm?",
                    actionType: actionType,
                    confirmAlert: true,
                })
            } else {
                this.setState({
                    notificationMessgaeCommon: "",
                    actionType: actionType,
                    confirmAlert: true,
                })
            }


        } else if (actionType === "handleSubmitDraft") {
            this.setState({
                notificationMessgaeCommon: "Do You Confirm?",
                errorTittle: "Do You Confirm?",
                actionType: actionType,
                confirmAlert: true,
            })
        } else if (actionType === "handleCaseClose") {
            this.setState({
                notificationMessgaeCommon: "Do You Confirm?",
                errorTittle: "Do You Confirm?",
                actionType: actionType,
                confirmAlert: true,
            })
        }
    };
    closeConfirmAlert = (data) => {
        this.setState({
            confirmAlert: false,
            notificationMessgaeCommonFlag: false
        });
        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "handleConfirm") {
            this.handleConfirm()
        } else if (data === "YES" && this.state.actionType === "handleSubmit") {
            this.handleSubmit()
        } else if (data === "YES" && this.state.actionType === "handleSubmitDraft") {
            this.handleSubmitDraft()
        } else if (data === "YES" && this.state.actionType === "handleCaseClose") {
            this.handleCaseClose()
        }
    };
    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()

        {
            this.validationForHandleConfirm(type)
        }


    };
    handleSignatureAndDebitAccount = (event, type) => {
        event.preventDefault();
        if (type === "handleDebitAccount") {
            alert("Api Need")
        } else {
            this.setState({
                signatureCardView: true
            })
        }


    };
    handleSubmit = () => {
        console.log(this.state.inputData);
        let saveDataDeferralRemarksAndRoute = {};
        let dependencyField = (this.props.commonJsonForm.concat(this.props.serviceList).concat(this.props.serviceListLast));
        let error = null;
        console.log(this.state.inputData);
        if (this.state.inputData.debitAccountNumberApiCall === "NO") {
            this.state.inputData.debitAccountNumber = "";
            this.updateComponent()
        }
        if (this.state.inputData["csWaiver"] === "YES") {
            for (let i = 0; i < this.state.waiverValues.length; i++) {
                let value = this.state.waiverValues[i];
                let waiverType = this.state.inputData["waiverType" + value];
                dependencyField.push(
                    {
                        "varName": "typeofWaiverDocs" + value,
                        "type": "select",
                        "grid": 2,
                        "required": true,

                    }, {
                        "varName": "waiverType" + value,
                        "type": "select",
                        "grid": 2,
                        "required": true,

                    },
                );
                if (waiverType === "Others") {
                    dependencyField.push({
                        "varName": "waiverOther" + value,
                        "type": "text",
                        "grid": 2,
                        "required": true,

                    },)
                }
            }
        }


        if (this.state.inputData["csDeferal"] === "YES") {
            for (let i = 0; i < this.state.values.length; i++) {
                let value = this.state.values[i];
                let defferalType = this.state.inputData["deferalType" + value];
                dependencyField.push({
                        "varName": "typeofDeferralDocs" + value,
                        "type": "select",
                        "grid": 2,
                        "required": true,

                    },
                    {
                        "varName": "deferalType" + value,
                        "type": "select",
                        "grid": 2,
                        "required": true,

                    }
                );
                if (defferalType === "Others") {
                    dependencyField.push({
                        "varName": "deferalOther" + value,
                        "type": "text",
                        "grid": 2,
                        "required": true,

                    },)
                }
                dependencyField.push({
                    "varName": "expireDate" + value,
                    "type": "date",
                    "grid": 2,
                    "required": true,

                },);

                console.log(expDate)
            }

        }

        if (this.props.serviceType !== 'DPS Opening') {
            error = MyValidation.defaultValidation(this.emptyValueRemove(this.checkReadOnlyField(dependencyField)), this.state);
            this.forceUpdate();

            if (error === true) {
                console.log("Not working");
                return 0;
            }

        } else {
            error = MyValidation.defaultValidation(this.emptyValueRemove(this.checkReadOnlyField(this.props.commonJsonForm)), this.state);
            this.forceUpdate();

            if (error === true) {
                console.log("Not working");
                return 0;
            }
        }
        if (this.state.inputData.uploadFileAlertRequest === true && this.state.inputData.previewImage !== true) {

            this.setState({
                errorModalBoolean: true,
                errorTittle: "Please Upload The File!"
            });
            return 0;
        }
        // return false;
        //let error = MyValidation.defaultValidation(this.props.commonJsonForm, this.state)
        //this.forceUpdate();
        this.setState({
            loading: true
        });
        if (this.state.values.length > 0) {
            this.state.inputData["csDeferal"] = "YES";
        } else if (this.state.waiverValues.length > 0) {
            this.state.inputData["csDeferal"] = "YES";
        } else {
            this.state.inputData["csDeferal"] = "NO";
        }
        if (this.state.inputData["csDeferal"] === "YES") {

            var defType = [];
            var expDate = [];
            var typeofDeferralDocsList = [];

            let appId = this.state.appId;
            for (let i = 0; i < this.state.values.length; i++) {
                let value = this.state.values[i];
                let defferalType = this.state.inputData["deferalType" + value];
                let deferralDocs = this.state.inputData["typeofDeferralDocs" + value];
                if (defferalType === "Others") {
                    defferalType = this.state.inputData["deferalOther" + value];
                }
                typeofDeferralDocsList.push(deferralDocs);
                defType.push(defferalType);
                let expireDate = this.state.inputData["expireDate" + value];
                expDate.push(expireDate);

                console.log(expDate)
            }
            saveDataDeferralRemarksAndRoute.bulkDeferralDTO = {
                appId: appId,
                type: defType,
                dueDate: expDate,
                docType: typeofDeferralDocsList,
            };

        }
        if (this.state.inputData["csWaiver"] === "YES") {

            var waiverType = [];
            var waiverOther = [];
            var wavType = [];
            for (let i = 0; i < this.state.waiverValues.length; i++) {
                let waiverValues = this.state.waiverValues[i];
                let docType = this.state.inputData["waiverType" + waiverValues];
                if (docType === "Others") {
                    docType = this.state.inputData["waiverOther" + waiverValues];
                }
                waiverType.push(docType);
            }
            saveDataDeferralRemarksAndRoute.bulkWaiverDTO = {
                appId: this.state.appId,
                type: waiverType,
            }
        }
        let data = this.emptyValueRemove(this.state.inputData);

        if ((this.state.inputData.csRemarks !== undefined && this.state.inputData.csRemarks !== null)) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.csRemarks;
        }
        var variableSetUrl = backEndServerURL + "/variables/" + this.state.appId;


        if (data.maturity !== "Renew Principal Only and Credit Interest to") {
            data.interestCreditAccount = "";
        }
        data.cs_deferal = this.state.inputData["csDeferal"];
        data.serviceType = this.props.serviceType;
        data.subServiceType = this.subServiceTypeDataSet();

        // data.dueDate=this.state.dueDate;
        // data.type=this.state.type;
        if (this.props.freeFlag1 !== 'DPS Account Opening -with Existing Operative Account') {
            data.freeFlag4 = data.fdrSchemeCode.value;
        } else {
            data.freeFlag4 = data.dpsSchemeCode.value;
        }
        if (this.state.inputData.priority === "HIGH")
            data.urgency = 1;
        else
            data.urgency = 0;
        console.log(data);

        let postData = this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account" ? FunctionForInputGetAndSet.setInputDataForDPS(data) : FunctionForInputGetAndSet.setInputData(data);
        postData.csHandleSubmit="YES";
        postData.employeeBranchSolId= LocalstorageEncrypt.encryptStorageFunction.getItem("solId")
        axios.post(variableSetUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            console.log(response.data);
            // this.setState({
            //     accountDetailsModal: true
            // })
            if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account" || this.props.freeFlag1 === "FDR Account Opening - with Existing Operative Account") {
                let accountType = "FDR";
                if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                    accountType = "DPS"
                }
                var url = backEndServerURL + "/tdAccountRouteToBM/" + this.state.appId + "/" + accountType;
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        var url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
                        axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                console.log(response.data);
                                console.log("Successfully Routed!");
                                //closeModal={this.closeModal}
                                //closeModalOnlySubModal={this.closeModalOnlySubModal}
                                if (this.props.appId !== undefined) {


                                    this.props.closeModal();


                                } else {

                                    this.props.closeModal();

                                }

                                this.setState({
                                    title: "Successfull!",
                                    notificationMessage: "Successfully Routed!",
                                    alert: true,
                                    loading: false
                                })
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    loading: false
                                })
                            });
                    })
                    .catch((error) => {

                    })
            } else {
                var url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
                axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    console.log(response.data);
                    console.log("Successfully Routed!");
                    //closeModal={this.closeModal}
                    //closeModalOnlySubModal={this.closeModalOnlySubModal}
                    if (this.props.appId !== undefined) {


                        this.props.closeModal();


                    } else {

                        this.props.closeModal();

                    }

                    this.setState({
                        title: "Successfull!",
                        notificationMessage: "Successfully Routed!",
                        alert: true,
                        loading: false
                    })
                })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    });
            }


        })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
    };
    handleSubmitDraftAfterConfirm = () => {


        if (this.state.values.length > 0) {
            this.state.inputData["csDeferal"] = "YES";
        } else if (this.state.waiverValues.length > 0) {
            this.state.inputData["csDeferal"] = "YES";
        } else {
            this.state.inputData["csDeferal"] = "NO";
        }
        if (this.state.inputData["csDeferal"] === "YES") {
            var defType = [];
            var expDate = [];
            var typeofDeferralDocsList = [];

            let appId = this.state.appId;
            for (let i = 0; i < this.state.values.length; i++) {
                let value = this.state.values[i];
                let defferalType = this.state.inputData["deferalType" + value];
                let deferralDocs = this.state.inputData["typeofDeferralDocs" + value];
                if (defferalType === "Others") {
                    defferalType = this.state.inputData["deferalOther" + value];
                }
                typeofDeferralDocsList.push(deferralDocs);
                defType.push(defferalType);
                let expireDate = this.state.inputData["expireDate" + value];
                expDate.push(expireDate);

                console.log(expDate)
            }
            let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";
            axios.post(deferalRaisedUrl, {
                appId: appId,
                type: defType,
                dueDate: expDate,
                docType: typeofDeferralDocsList,
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        var variableSetUrl = backEndServerURL + "/save/" + this.state.appId;
        let data = this.state.inputData;
        data.cs_deferal = this.state.inputData["csDeferal"];
        data.serviceType = this.props.serviceType;
        data.individualDedupData = this.state.individualDataSaveId;
        data.jointDedupData = this.state.jointDataSaveId;
        data.companyDedupData = this.state.companyDataSaveId;
        data.jointAccountCustomerNumber = this.props.jointAccountCustomerNumber;
        data.subServiceType = this.subServiceTypeDataSet();

        if (this.props.serviceType === 'FDR Opening') {
            data.category = 'FDR';
            data.subCategory = 'Opening';
        } else if (this.props.serviceType === 'DPS Opening') {
            data.category = 'DPS';
            data.subCategory = 'Opening';
        }
        // data.dueDate=this.state.dueDate;
        // data.type=this.state.type;

        if (this.state.inputData.priority === "HIGH")
            data.urgency = 1;
        else
            data.urgency = 0;

        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);

                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Save!",
                    alert: true
                })
            })
            .catch((error) => {
                console.log(error)
            });
    };
    handleSubmitDraft = () => {

        this.setState({
            loading: true,
        });
        if (this.state.values.length > 0) {
            this.state.inputData["csDeferal"] = "YES";
        } else if (this.state.waiverValues.length > 0) {
            this.state.inputData["csDeferal"] = "YES";
        } else {
            this.state.inputData["csDeferal"] = "NO";
        }
        if (this.state.inputData["csDeferal"] === "YES") {
            var defType = [];
            var expDate = [];
            var typeofDeferralDocsList = [];

            let appId = this.state.appId;
            for (let i = 0; i < this.state.values.length; i++) {
                let value = this.state.values[i];
                let defferalType = this.state.inputData["deferalType" + value];
                let deferralDocs = this.state.inputData["typeofDeferralDocs" + value];
                if (defferalType === "Others") {
                    defferalType = this.state.inputData["deferalOther" + value];
                }
                typeofDeferralDocsList.push(deferralDocs);
                defType.push(defferalType);
                let expireDate = this.state.inputData["expireDate" + value];
                expDate.push(expireDate);

                console.log(expDate)
            }
            let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";
            axios.post(deferalRaisedUrl, {
                appId: appId,
                type: defType,
                dueDate: expDate,
                docType: typeofDeferralDocsList,
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        console.log(this.state.inputData);
        var variableSetUrl = backEndServerURL + "/save/" + this.state.appId;
        let data = this.state.inputData;
        data.cs_deferal = this.state.inputData["csDeferal"];
        data.serviceType = this.props.serviceType;
        data.individualDedupData = this.state.individualDataSaveId;
        data.jointDedupData = this.state.jointDataSaveId;
        data.companyDedupData = this.state.companyDataSaveId;
        data.jointAccountCustomerNumber = this.props.jointAccountCustomerNumber;
        data.subServiceType = this.subServiceTypeDataSet()

        if (this.props.serviceType === 'FDR Opening') {
            data.category = 'FDR';
            data.subCategory = 'Opening';
        } else if (this.props.serviceType === 'DPS Opening') {
            data.category = 'DPS';
            data.subCategory = 'Opening';
        }
        // data.dueDate=this.state.dueDate;
        // data.type=this.state.type;

        if (this.state.inputData.priority === "HIGH")
            data.urgency = 1;
        else
            data.urgency = 0;
        let postData = this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account" ? FunctionForInputGetAndSet.setInputDataForDPS(data) : FunctionForInputGetAndSet.setInputData(data);
        axios.post(variableSetUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);

                this.setState({
                    loading: false,
                    title: "Successfull!",
                    notificationMessage: "Successfully Save!",
                    alert: true
                });
                this.props.closeModal();
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                })
            });
    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    addClick() {
        let randomNumber = Math.floor(Math.random() * 100000000000);
        this.setState(prevState => ({
            setAllDeferalData: true,
            values: [...prevState.values, randomNumber],

        }));

        this.state.inputData["csDeferal"] = "YES";
    };

    addClickWaiver() {
        let randomNumber = Math.floor(Math.random() * 100000000000);

        this.setState(prevState => ({
            waiverValues: [...prevState.waiverValues, randomNumber],

        }));

        this.state.inputData["csDeferal"] = "YES";
        this.state.inputData["csWaiver"] = "YES";
    };


    renderRemarks = () => {
        if (this.state.getData && (this.state.getInitiateData || this.props.appId !== undefined) && this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
            )
        }

    };

    removeClick(i, event) {
        event.preventDefault();
        let pos = this.state.values.indexOf(i);
        if (pos > -1) {
            this.state.values.splice(pos, 1);
            this.updateComponent();
            if (this.state.values.length > 0) {
                this.state.inputData["csDeferal"] = "YES"
            } else {
                this.state.inputData["csDeferal"] = "NO"
            }
        }
    }

    removeClickWaiver(i, event) {
        event.preventDefault();
        let pos = this.state.waiverValues.indexOf(i);
        if (pos > -1) {
            this.state.waiverValues.splice(pos, 1);
            this.updateComponent();
            if (this.state.waiverValues.length > 0) {
                this.state.inputData["csDeferal"] = "YES";
                this.state.inputData["csWaiver"] = "YES"
            } else {
                this.state.inputData["csDeferal"] = "NO";
                this.state.inputData["csWaiver"] = "NO"
            }
        }
    }

    close = () => {
        if (this.props.appId !== undefined) {
            this.props.closeModal();
        } else {
            this.props.closeModalOnlySubModal();
        }

    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };

    renderUploadButton = () => {
        if (this.state.getData && (this.state.getInitiateData || this.props.appId !== undefined)) {
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={this.uploadModal}
                >
                    Upload File
                </button>
            )
        }
    };
    digitalAof = (event) => {
        event.preventDefault();
        let inputData = this.state.inputData;
        inputData.subServiceType = this.subServiceTypeDataSet()
        let url = backEndServerURL + "/variables/" + this.state.appId;
        axios.post(url, inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let url = backEndServerURL + "/fdAgentReport/" + this.state.appId;
                functionForFileDownload.downloadFile(url, "GET", "", "Download_Digital_TD_AOF.pdf").then((response) => {
                    this.setState({
                        loading: false
                    })
                }).catch((error) => {
                    this.setState({
                        loading: false,
                        title: "Something Went Wrong!",
                        cbNotFound: true,
                    })
                })

            })
            .catch((error) => {
                console.log(error);
            })
    }
    renderDownloadGenerateForm = () => {
        if (this.state.getData && (this.state.getInitiateData || this.props.appId !== undefined)) {
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={this.digitalAof}
                >
                    Download Digital TD AOF
                </button>
            )
        }
    };
    handleChangeuploadFileAlertRequest = (event) => {
        let data = event.target.checked;
        this.state.inputData.uploadFileAlertRequest = data;
        this.updateComponent();
        if (data === false) {
            this.setState({
                errorModalBoolean: true,
                errorTittle: "Without Document Process!!",
            })
        }
    };
    renderUploadCheckbox = () => {
        if (this.state.getData && (this.state.getInitiateData || this.props.appId !== undefined)) {
            return (
                <CheckBox
                    checked={this.state.inputData.uploadFileAlertRequest}
                    defaultValue={this.state.inputData.uploadFileAlertRequest}
                    name="smsAlertRequest"
                    label="SMS Alert Request"
                    onChange={(event) => this.handleChangeuploadFileAlertRequest(event)}/>


            )
        }
    };
    closeModal = () => {
        this.setState({
            getMappingAllImage: false,
            IndividualDedupModal: false,
            JointDedupModal: false,
            errorModalBoolean: false,

        })
    };
    closeUploadModal = (data) => {
        this.setState({
            uploadButtonClick: true,
            uploadModal: false,
            getMappingAllImage: false

        })
    };
    submitModal = (data) => {
        if (data === "SUBMIT") {
            this.state.inputData.previewImage = true;
            this.updateComponent()
        }
        this.setState({
            uploadModal: false,
            getMappingAllImage: false
        })

    };
    accountDetailsModal = () => {
        this.setState({
            accountDetailsModal: false
        });
        var url = backEndServerURL + "/case/route/" + this.state.appId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                console.log("Successfully Routed!");
                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Routed!",
                    alert: true
                });
                {
                    this.props.closeModal()
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    IndividualDedupModal = () => {
        this.setState({
            IndividualDedupModal: true
        })
    };
    JointDedupModal = () => {
        this.setState({
            JointDedupModal: true
        })
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    renderAssignedImage = () => {
        if (this.state.getData && this.state.inputData.previewImage === true) {
            return (
                <button
                    style={{
                        width: 120,
                    }}
                    className="btn btn-outline-primary btn-sm"
                    onClick={this.mappingAllImage}
                >
                    Preview Document
                </button>
            )
        }
    };
    renderMappingImageEditModal = () => {

        if ((this.state.getInitiateData || this.props.appId !== undefined) && this.props.subServiceType === "INDIVIDUAL" || this.state.inputData.subServiceType === "Individual A/C") {

            return (
                <FileMappingEdit dropDownOption={arrayListIndividual}
                                 appId={this.state.appId}
                                 submitModal={this.submitModal}
                                 closeModal={this.closeUploadModal}/>
            )
        } else {

            return (
                <FileMappingEdit dropDownOption={arrayListIndividual}
                                 appId={this.state.appId}
                                 submitModal={this.submitModal}
                                 closeModal={this.closeUploadModal}/>
            )
        }
    };
    renderMappingImageModal = () => {
        if ((this.state.getInitiateData || this.props.appId !== undefined) && this.props.subServiceType === "INDIVIDUAL" || this.state.inputData.subServiceType === "Individual A/C") {

            return (
                <FileMapping dropDownOption={arrayListIndividual}
                             submitModal={this.submitModal}
                             appId={this.state.appId}
                             closeModal={this.closeUploadModal}/>
            )
        } else {

            return (
                <FileMapping dropDownOption={arrayListIndividual}
                             submitModal={this.submitModal}
                             appId={this.state.appId}
                             closeModal={this.closeUploadModal}/>
            )
        }
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        /* for (let prop in clone)
             if (clone[prop] === '' || clone[prop] === ' '   || clone[prop] === "null"   ||  clone[prop] === "undefined" )
 delete clone[prop];*/
        return clone;
    };
    subServiceTypeDataSet = () => {
        if (this.props.subServiceType === "Joint CB") {
            return "Joint Account"
        } else if (this.props.subServiceType === 'Partnership A/C' || this.props.subServiceType === 'Private/Public Limited Company Account' || this.props.subServiceType === 'NGO/NPO/Club/Society Account' || this.props.subServiceType === 'Educational/Religious Institution Account' || this.props.subServiceType === 'Government Account' || this.props.subServiceType === 'Other Account') {
            return "Company Account"
        } else if (this.props.subServiceType === 'Individual A/C') {
            return "INDIVIDUAL";
        } else if (this.props.subServiceType === 'NONINDIVIDUAL' || this.props.subServiceType === 'Proprietorship A/C') {
            return "Proprietorship A/C";
        } else {
            return this.props.subServiceType;
        }
    }
    handleConfirm = () => {

        console.log(this.state.inputData);
        let dependencyField = (this.props.commonJsonForm);
        let error = null;
        console.log(this.state.inputData);
        if (this.state.inputData.debitAccountNumberApiCall === "NO") {
            this.state.inputData.debitAccountNumber = "";
            this.updateComponent()
        }
        if (this.state.inputData.interestRateApicall === "NO") {
            this.state.inputData.interestRate = "";
            this.updateComponent()
        }
        if (this.state.inputData.cbNumberApiCall === "NO") {
            this.state.inputData.cbNumber = "";
            this.updateComponent()
        }


        if (this.props.serviceType !== 'DPS Opening') {

            error = MyValidation.defaultValidation(this.emptyValueRemove(this.checkReadOnlyField(dependencyField)), this.state);
            this.forceUpdate();

            if (error === true) {
                console.log("Not working");
                return 0;
            }

        } else {

            error = MyValidation.defaultValidation(this.emptyValueRemove(this.checkReadOnlyField(this.props.commonJsonForm)), this.state);
            this.forceUpdate();

            if (error === true) {
                console.log("Not working");
                return 0;
            }
        }


        /*if(this.state.inputData.debitAccountNumber!==this.state.inputData.debitAccountNumberPre){
              error= MyValidation.defaultValidation(this.emptyValueRemove(this.props.commonJsonForm), this.state)

          }
          else if(this.state.inputData.debitAccountNumber===this.state.inputData.debitAccountNumberPre){

              error= MyValidation.defaultValidation(this.emptyValueRemove(makeRequiredObject(dependencyFieldJsonForm)), this.state)

          }*/

        if (this.state.inputData.accountType !== undefined && this.state.inputData["interestRate"] === 0) {
            this.setState({
                errorModalBoolean: true,
                errorTittle: "Your Scheme Code Wrong,Please Exist Scheme Code Input"
            });
            return 0;
        }

        let data = this.emptyValueRemove(this.state.inputData);
        data.csRemarks = undefined;
        // data.accountNumber = this.state.inputData.debitAccountNumber;

        data.cs_deferal = this.state.inputData["csDeferal"];
        data.serviceType = this.props.serviceType;
        data.individualDedupData = this.state.individualDataSaveId;
        data.jointDedupData = this.state.jointDataSaveId;
        data.subServiceType = this.subServiceTypeDataSet()

        data.customerId = this.state.getcustomerId;
        data.accountNo = this.props.accountNo!==undefined?this.props.accountNo:this.state.inputData.debitAccountNumber;
        data.freeFlag1 = this.props.freeFlag1;
        if (this.props.freeFlag1 === 'FDR Account Opening - with Existing Operative Account') {
            data.category = 'FDR';
            data.subCategory = 'Opening';
        } else if (this.props.serviceType === 'DPS Opening') {
            data.category = 'DPS';
            data.subCategory = 'Opening';
        }


        let varValue = this.emptyValueRemove(this.state.inputData);

        if (this.state.inputData.priority === "HIGH")
            data.urgency = 1;
        else
            data.urgency = 0;
        this.setState({
            loading: true
        });
        let postData = this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account" ? FunctionForInputGetAndSet.setInputDataForDPS(data) : FunctionForInputGetAndSet.setInputData(data);
        let variableSetUrl = backEndServerURL + "/changeStatus/" + this.state.appId + "/ACCOUNT_OPENED";
        axios.post(variableSetUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    //accountDetailsModal: true,
                    getInitiateData: true,
                    loading: false,
                    cbDataBoolean: true,
                    debitDataBoolean: true,
                });
                {
                    this.handleSubmitDraftAfterConfirm()
                }
                /*this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Saved!",
                    alert: true,
                    inputData: data,
                    varValue: varValue,
                    loading: false,
                    cbDataBoolean: true,
                    debitDataBoolean: true,

                })
*/

            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    cbDataBoolean: true,
                    debitDataBoolean: true,
                });
                console.log(error)
            });


    };
    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "Finacle System Error!"
                }
            } else {
                return "Finacle System Error!"
            }
        } else {
            return "Finacle System Error!"
        }
    };
    accountDetailsModal = () => {
        this.setState({
            accountDetailsModal: false,
            getInitiateData: true,
        })


    };
    SignatureCardViewCloseModal = () => {
        this.setState({
            signatureCardView: false,
        })


    };
    renderButton = () => {
        if (this.state.loading === false) {
            if (this.state.getData && (this.state.getInitiateData || this.props.appId !== undefined)) {
                return (
                    <div>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            //onClick={this.handleSubmit}
                            onClick={(event) => this.handleCommon(event, "handleSubmit")}

                        >
                            Submit
                        </button>
                        &nbsp;&nbsp;
                        <button
                            className="btn btn-outline-info btn-sm"
                            onClick={(event) => this.handleCommon(event, "handleSubmitDraft")}
                            //onClick={this.handleSubmitDraft}
                        >
                            Save
                        </button>
                        {/* &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-info btn-sm"
                        style={{}}
                        onClick={(event) => this.handleCommon(event, "handleCaseClose")}


                    >
                        Delete
                    </button>*/}
                    </div>
                )
            } else if (this.state.getData && (this.state.getAccountType || this.state.nameOfSchemeApiCall)) {

                return (

                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={(event) => this.handleCommon(event, "handleConfirm")}
                        //onClick={this.handleConfirm}
                    >
                        Confirm & Save
                    </button>


                )
            }
        }

    };
    handleCaseClose = () => {
        let variableSetUrl = backEndServerURL + "/case/close/" + this.state.appId;
        axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.props.closeModal();
                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Close!",
                    alert: true,
                    redirectPage: true,

                })

            })
            .catch((error) => {
                console.log(error)
            });

    };
    renderSignatureAndDebitAccount = () => {
        if (this.state.getData && ((this.state.debitDataBoolean && this.state.getAccountInfo) || this.props.appId !== undefined)) {
            return (
                <SignatureButton accountNumber={this.state.inputData.debitAccountNumber} signatureType="multiple"
                                 classes={this.props}/>
            )

        }

    };
    renderSiCbNumber = () => {
        if (this.state.inputData.recpmtid !== undefined && this.state.inputData.recpmtid !== null) {
            return (
                <><b> SI CB number:</b>{this.state.inputData.recpmtid}</>
            )
        } else {
            return ""

        }
    };
    masterdataToString = (data) => {
        if (data !== undefined && data.key !== undefined) {
            return data.value;
        } else {
            return data;
        }
    }
    renderHeader = () => {
        if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
            return (
                <h4 style={{color: "white"}} ><b>{this.props.freeFlag1}</b><p style={{fontSize: "16px"}}> (<b> Customer
                    Name:</b>{this.state.inputData.customerName}
                    <b> A/C:</b>{this.state.inputData.accountNumber} <b> CB
                        Number:</b>{this.state.inputData.cbNumber}{this.renderSiCbNumber()}
                    <b> SOLID:</b>{this.masterdataToString(this.state.inputData.solId)}<b> Scheme
                        Code:</b>{this.state.inputData.fdrSchemeCode ? this.state.inputData.fdrSchemeCode.value : (this.state.inputData.dpsSchemeCode !== undefined ? this.state.inputData.dpsSchemeCode.value : "")}<b> Case
                        ID:</b>{this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                        onClick={this.close} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>

            )
        } else if (this.props.freeFlag1 === "FDR Account Opening - with Existing Operative Account") {
            return (
                <h4 style={{color: "white"}} ><b>{this.props.freeFlag1}</b><p style={{fontSize: "16px"}}> (<b> Customer
                    Name:</b>{this.state.inputData.customerName}
                    <b> A/C:</b>{this.state.inputData.accountNumber} <b> CB
                        Number:</b>{this.state.inputData.cbNumber}{this.renderSiCbNumber()}
                    <b> SOLID:</b>{this.masterdataToString(this.state.inputData.solId)}<b> Scheme
                        Code:</b>{this.state.inputData.fdrSchemeCode ? this.state.inputData.fdrSchemeCode.value : (this.state.inputData.dpsSchemeCode !== undefined ? this.state.inputData.dpsSchemeCode.value : "")}<b> Case
                        ID:</b>{this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                        onClick={this.close} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>

            )
        } else if (this.props.freeFlag1 === "FDR Account Opening - with Existing CB Only") {
            return (
                <h4 style={{color: "white"}} ><b>{this.props.freeFlag1}</b><p style={{fontSize: "16px"}}> (<b> Customer
                    Name:</b>{this.state.inputData.customerName}
                    <b> A/C:</b>{this.state.inputData.accountNumber} <b> CB
                        Number:</b>{this.state.inputData.cbNumber}{this.renderSiCbNumber()}
                    <b> SOLID:</b>{this.masterdataToString(this.state.inputData.solId)}<b> Scheme
                        Code:</b>{this.state.inputData.fdrSchemeCode ? this.state.inputData.fdrSchemeCode.value : (this.state.inputData.dpsSchemeCode !== undefined ? this.state.inputData.dpsSchemeCode.value : "")}<b> Case
                        ID:</b>{this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                        onClick={this.close} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>

            )
        } else if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
            return (
                <h4 style={{color: "white"}} ><b>{this.props.freeFlag1}</b><p style={{fontSize: "16px"}}> (<b> Customer
                    Name:</b>{this.state.inputData.customerName}
                    <b> A/C:</b>{this.state.inputData.accountNumber} <b> CB
                        Number:</b>{this.state.inputData.cbNumber}{this.renderSiCbNumber()}
                    <b> SOLID:</b>{this.masterdataToString(this.state.inputData.solId)}<b> Scheme
                        Code:</b>{this.state.inputData.fdrSchemeCode ? this.state.inputData.fdrSchemeCode.value : (this.state.inputData.dpsSchemeCode !== undefined ? this.state.inputData.dpsSchemeCode.value : "")}<b> Case
                        ID:</b>{this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                        onClick={this.close} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>

            )
        } else {
            return (


                <h4 style={{color: "white"}} >
                    <b>{this.props.title}</b><p
                    style={{fontSize: "16px"}}> ( <b> A/C:</b>{this.state.inputData.accountNumber} <b> CB
                    Number:</b>{this.state.inputData.cbNumber})
                    <a><CloseIcon onClick={this.close} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>


            )
        }
    };
    setStateapiEnumValue = (setVarname, data) => {
        if (setVarname === "city") {
            this.setState({
                city: data
            })
        } else if (setVarname === "state") {
            this.setState({
                state: data
            })
        } else if (setVarname === "currency") {
            this.setState({
                currency: data
            })
        } else if (setVarname === "country") {
            this.setState({
                country: data
            })
        } else if (setVarname === "fdrSchemeCode") {
            this.setState({
                fdrSchemeCode: data
            })
        }

    };
    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;
        console.log(value);
        console.log(getKeyValue);


        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            console.log(jsonObject);
            if (value === jsonObject.key) {
                return jsonObject;
                i = jsonArray.length;

            }
        }


    };
    onBlurFunctionForApiCall = (event, varName, secondVarName) => {
        event.preventDefault();
        let inputData = this.state.inputData;
        let varValue = this.state.varValue;
        //this.state.inputData.debitAccountNumberApiCall = "NO";
        let allInputData = this.state.inputData;

        if (varName === "rmCode") {
            this.setState({
                loading: true,
            });
            this.state.inputData.rmCodeApicall = "NO";
            this.state.varValue.rmCodeApicall = "NO";
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.rmCodeName = response.data;
                    this.state.varValue.rmCodeName = response.data;
                    console.log(response.data);
                    this.state.inputData.rmCodeApicall = "YES";
                    this.state.varValue.rmCodeApicall = "YES";
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false,
                    })
                })
                .catch((error) => {
                    this.state.inputData.rmCode = "__ERROR__";
                    this.state.varValue.rmCode = "__ERROR__";
                    this.forceUpdate();
                    console.log(error);
                    this.setState({

                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Number"
                    })
                })
        } else if (varName === "repaymentAccounts") {
            this.state.inputData.repaymentsAccountApiCall = "NO";
            this.setState({
                loading: true
            });
            let accountUrls = backEndServerURL + "/GetCustomAcctDetailsRequest/" + (this.state.inputData[secondVarName]);
            axios.get(accountUrls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.repaymentsAccountApiCall = "YES";
                    this.state.inputData.repaymentsName = response.data.acctName;
                    this.state.varValue.repaymentsName = response.data.acctName;
                    this.updateComponent();
                    this.setState({
                        showValue: true,
                        getData: true,
                        loading: false,
                    })

                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                    })
                })
        } else if (varName === "interestCreditAccount") {
            this.state.inputData.interestCreditAccountApiCall = "NO";
            this.setState({
                loading: true
            });
            let accountUrls = backEndServerURL + "/GetCustomAcctDetailsRequest/" + (this.state.inputData[secondVarName]);
            axios.get(accountUrls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.interestCreditAccountApiCall = "YES";
                    inputData.interestCreditName = response.data.acctName;
                    varValue.interestCreditName = response.data.acctName;
                    this.updateComponent();
                    this.setState({
                        inputData: this.emptyValueRemove(inputData),
                        varValue: this.emptyValueRemove(varValue),
                        showValue: true,
                        getData: true,
                        loading: false,
                    })

                })
                .catch((error) => {
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: this.errorObjectCheck(error),
                    })
                })
        } else if (varName === "repaymentAccount") {
            this.state.inputData.repaymentAccountApiCall = "NO";
            this.setState({
                loading: true
            });
            let accountUrls = backEndServerURL + "/GetCustomAcctDetailsRequest/" + (this.state.inputData[secondVarName]);
            axios.get(accountUrls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.repaymentAccountApiCall = "YES";
                    inputData.repaymentName = response.data.acctName;
                    varValue.repaymentName = response.data.acctName;
                    this.updateComponent();
                    this.setState({
                        inputData: this.emptyValueRemove(inputData),
                        varValue: this.emptyValueRemove(varValue),
                        showValue: true,
                        getData: true,
                        loading: false,
                    })

                })
                .catch((error) => {
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: this.errorObjectCheck(error),
                    })
                })
        } else if (varName === "cbNumber") {
            this.setState({
                loading: true
            });
            this.state.inputData.cbNumberApiCall = "NO";
            this.updateComponent();
            let url = backEndServerURL + "/cbsCustomerGet/" + (inputData[secondVarName]);
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    inputData.customerName = response.data.customerName;
                    varValue.customerName = response.data.customerName;

                    inputData.occupationCode = response.data.occupationCode;
                    varValue.occupationCode = response.data.occupationCode;

                    inputData.sbsCode = response.data.sbsCode;
                    varValue.sbsCode = response.data.sbsCode;
                    if (response.data.ETINBLOCK !== undefined) {
                        inputData.etin = response.data.ETINBLOCK.referenceNum;
                        varValue.etin = response.data.ETINBLOCK.referenceNum;
                    }

                    inputData.cbNumberApiCall = "YES";
                    this.setState({
                        inputData: inputData,
                        varValue: varValue,
                        showValue: true,
                        loading: false,
                        getData: true,
                        cbDataBoolean: true,

                    })
                })
                .catch((error) => {
                    this.state.inputData.cbNumber = "__ERROR__";
                    this.state.varValue.cbNumber = "__ERROR__";
                    this.forceUpdate();
                    this.setState({

                        loading: false,
                        getData: true,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Number",
                        cbDataBoolean: true
                    })
                })

        } else if (varName === "debitAccountNumber") {
            this.state.inputData.debitAccountNumberApiCall = "NO";
            this.state.inputData.repaymentAccountApiCall = "NO";
            this.state.inputData.interestCreditAccountApiCall = "NO";
            this.setState({
                loading: true
            });
            inputData.accountTypeApiCall = false;
            inputData.accountAcquisitionApiCall = false;
            inputData.tenureTypeApiCall = false;
            inputData.tenureApiCall = false;
            inputData.autoRenewalsApiCall = false;
            inputData.maturityApiCall = false;
            inputData.fdrSchemeCodeApiCall = false;
            varValue.accountTypeApiCall = false;
            varValue.accountAcquisitionApiCall = false;
            varValue.tenureTypeApiCall = false;
            varValue.tenureApiCall = false;
            varValue.autoRenewalsApiCall = false;
            varValue.maturityApiCall = false;
            varValue.fdrSchemeCodeApiCall = false;
            let accountUrls = backEndServerURL + "/GetCustomAcctDetailsRequest/" + (this.state.inputData[secondVarName]);
            axios.get(accountUrls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    let data = response.data;
                    this.state.inputData.repaymentAccountApiCall = "YES";
                    this.state.inputData.interestCreditAccountApiCall = "YES";
                    inputData.interestCreditName = response.data.acctName;
                    varValue.interestCreditName = response.data.acctName;
                    inputData.repaymentName = response.data.acctName;
                    varValue.repaymentName = response.data.acctName;
                    let message = "";
                    inputData.debitAccountNumberSchemeType = data.schmType;
                    varValue.debitAccountNumberSchemeType = data.schmType;
                    if (data.schmType !== "OAB" && data.schmType !== "OAP") {
                        inputData.gelAccount = "NO";
                        varValue.gelAccount = "NO";
                        inputData.tranCreationDuring = "V";
                        varValue.tranCreationDuring = "V";
                    }
                    if (data.schmType === "OAB" || data.schmType === "OAP") {
                        inputData.tranCreationDuring = "O";
                        varValue.tranCreationDuring = "O";
                        inputData.gelAccount = "YES";
                        varValue.gelAccount = "YES";
                    } else {
                        if (data.freezeCode !== "" || data.acctStatus !== "A" || (data.lienDetails && data.lienDetails.length > 0)) {

                            if (data.freezeCode !== "") {
                                message = "Freeze Exists "
                            }
                            /* if(lienStatus === "YES"){
                                 message=message+", Lien Exists "
                             }
                             if(accountStatus!=="Open"){
                                 message=message+", Account is Closed "
                             }*/
                            if (data.acctStatus !== "A") {
                                message = message + ",Account is not Active"
                            }
                            if (data.lienDetails && data.lienDetails.length > 0) {
                                message = message + ", Lien Exists "
                            }
                            if (message === ", Lien Exists ") {
                                message = "";
                                this.setState({
                                    accountDetailsButton: true,
                                    errorModalBoolean: true,
                                    errorTittle: "Lien Exists",
                                    loading: false
                                })
                            }

                        }
                    }
                    let accountUrls = backEndServerURL + "/GetAccountStatusFromAccountNumber/" + (this.state.inputData[secondVarName]);
                    axios.get(accountUrls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((responseData) => {
                            if (data.schmType === "OAB" || data.schmType === "OAP") {

                            } else {
                                if (responseData.data !== "Open") {
                                    message = "Account is Closed ";
                                    this.state.inputData.debitAccountNumber = "__ERROR__";
                                    this.state.varValue.debitAccountNumber = "__ERROR__";
                                    this.forceUpdate();
                                }
                                if (message !== "") {
                                    this.state.inputData.debitAccountNumber = "__ERROR__";
                                    this.state.varValue.debitAccountNumber = "__ERROR__";
                                    this.forceUpdate();
                                    this.setState({
                                        accountDetailsButton: true,
                                        errorModalBoolean: true,
                                        errorTittle: message,
                                        loading: false
                                    });
                                    return 0;
                                }
                            }
                            if (data.schmType === "OAB" || data.schmType === "OAP") {
                                inputData.repaymentAccount = (this.state.inputData.debitAccountNumber).trim();
                                varValue.repaymentAccount = (this.state.inputData.debitAccountNumber).trim();
                                inputData.interestCreditAccount = (this.state.inputData.debitAccountNumber).trim();
                                varValue.interestCreditAccount = (this.state.inputData.debitAccountNumber).trim();
                                let url = backEndServerURL + "/intgr/accountBalanceInfo/" + (this.state.inputData.debitAccountNumber).trim();
                                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {

                                        let object = {

                                            currency: response.data.accountCurrency,
                                        };
                                        if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
                                            object.currency = response.data.accountCurrency;
                                            this.state.inputData.currencyNtb = response.data.accountCurrency;
                                        }
                                        if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                                            object.natureAccount = "Individual-Retail";
                                        } else {
                                            object.natureAccount = "Non-Individual"
                                        }
                                        if (message !== "") {
                                            object.currency = "";
                                            this.state.inputData.currencyNtb = "";
                                        }

                                        let Urls = backEndServerURL + "/FDRDPSSchemeCode/getAccountType";
                                        axios.post(Urls, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                            .then((getAccountType) => {

                                                let jsonArrayServiceList = this.props.serviceList;
                                                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                                    let jsonObjects = jsonArrayServiceList[i];
                                                    if (jsonObjects.varName === "accountType") {
                                                        this.props.serviceList[i].enum = getAccountType.data;
                                                        console.log(this.props.serviceList[i])
                                                    }
                                                }

                                                inputData.accountTypeApiCall = true;
                                                varValue.accountTypeApiCall = true;
                                                console.log(response.data);
                                                inputData.accountCbNumber = response.data.custId;
                                                //inputData.accountNumber = (this.state.inputData.debitAccountNumber).trim();
                                                inputData.debitAccountName = response.data.name;
                                                if (response.data.schemeCode === "SBSTF") {
                                                    inputData.availableAmountShow = false;
                                                    varValue.availableAmountShow = false

                                                } else {
                                                    inputData.availableAmountShow = true;
                                                    varValue.availableAmountShow = true
                                                }
                                                inputData.availableAmount = response.data.availableBalance;
                                                varValue.availableAmount = response.data.availableBalance;
                                                inputData.accountRestriction = response.data.accountRestriction;

                                                inputData.currency = this.findByVarNameGetKeyValue(response.data.accountCurrency, this.state.currency)
                                                varValue.currency = this.findByVarNameGetKeyValue(response.data.accountCurrency, this.state.currency)
                                                varValue.debitAccountName = response.data.name;
                                                //varValue.accountNumber = (this.state.inputData.debitAccountNumber).trim();
                                                if (response.data.schemeCode === "SBSTF") {
                                                    inputData.availableAmountShow = false;
                                                    varValue.availableAmountShow = false

                                                } else {
                                                    inputData.availableAmountShow = true;
                                                    varValue.availableAmountShow = true
                                                }
                                                inputData.availableAmount = response.data.availableBalance;
                                                varValue.availableAmount = response.data.availableBalance;
                                                varValue.accountRestriction = response.data.accountRestriction;
                                                varValue.currency = response.data.accountCurrency;
                                                if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
                                                    varValue.currency = this.findByVarNameGetKeyValue(response.data.accountCurrency, this.state.currency);
                                                } else {
                                                    varValue.currency = response.data.accountCurrency
                                                }
                                                this.state.inputData.debitAccountNumberApiCall = "YES";
                                                this.updateComponent();
                                                this.setState({
                                                    inputData: this.emptyValueRemove(inputData),
                                                    varValue: this.emptyValueRemove(varValue),
                                                    showValue: true,
                                                    getData: true,
                                                    getAccountType: true,
                                                    getAccountInfo: true,
                                                    loading: false,
                                                    debitDataBoolean: true,
                                                })


                                            })
                                            .catch((error) => {
                                                this.setState({
                                                    getAccountType: true,
                                                    loading: false
                                                });
                                                console.log(error);
                                            })
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        this.setState({
                                            errorModalBoolean: true,
                                            errorTittle: this.errorObjectCheck(error),
                                            loading: false
                                        })
                                    })


                            } else {
                                accountUrls = backEndServerURL + "/getAccountInfo/" + (this.state.inputData.debitAccountNumber).trim();
                                axios.get(accountUrls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        //inputData.currency = this.findByVarNameGetKeyValue(response.data.acctCurrency, this.state.currency)
                                      //  varValue.currency = this.findByVarNameGetKeyValue(response.data.acctCurrency, this.state.currency)
                                        /* if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                                             let object = {
                                                 currency: typeof response.data.currency === "object" ? response.data.currency.key : response.data.currency,
                                             };
                                             if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                                                 object.natureAccount = "Individual-Retail";
                                             } else {
                                                 object.natureAccount = "Non-Individual"
                                             }

                                         }
                                         //new
                                         else {
                                             let object = {
                                                 currency: typeof response.data.currency === "object" ? response.data.currency.key : response.data.currency,
                                             };
                                         }*/
                                        inputData.repaymentAccount = (this.state.inputData.debitAccountNumber).trim();
                                        varValue.repaymentAccount = (this.state.inputData.debitAccountNumber).trim();
                                        inputData.interestCreditAccount = (this.state.inputData.debitAccountNumber).trim();
                                        varValue.interestCreditAccount = (this.state.inputData.debitAccountNumber).trim();

                                        inputData.clearingLimitDR = response.data.drclrlim;
                                        inputData.clearingLimitCR = response.data.crclrlim;
                                        inputData.TransferLimitCR = response.data.crtxlim;
                                        if (response.data.nomineeInfoDetails !== undefined && response.data.nomineeInfoDetails !== null) {
                                            inputData.nomineeExistNot = "Y";
                                            varValue.nomineeExistNot = "Y";

                                            inputData.existingNomineeNumber = response.data.nomineeInfoDetails.length;
                                            varValue.existingNomineeNumber = response.data.nomineeInfoDetails.length;
                                        } else {
                                            inputData.nomineeExistNot = "N";
                                            varValue.nomineeExistNot = "N"
                                        }

                                        let url = backEndServerURL + "/intgr/accountBalanceInfo/" + (this.state.inputData.debitAccountNumber).trim();
                                        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                            .then((response) => {
                                                let object = {

                                                    currency: response.data.accountCurrency,
                                                };
                                                if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
                                                    object.currency = response.data.accountCurrency;
                                                    this.state.inputData.currencyNtb = response.data.accountCurrency;
                                                }
                                                if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                                                    object.natureAccount = "Individual-Retail";
                                                } else {
                                                    object.natureAccount = "Non-Individual"
                                                }
                                                if (message !== "") {
                                                    object.currency = "";
                                                    this.state.inputData.currencyNtb = "";
                                                }

                                                let Urls = backEndServerURL + "/FDRDPSSchemeCode/getAccountType";
                                                axios.post(Urls, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                    .then((getAccountType) => {

                                                        let jsonArrayServiceList = this.props.serviceList;
                                                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                                            let jsonObjects = jsonArrayServiceList[i];
                                                            if (jsonObjects.varName === "accountType") {
                                                                this.props.serviceList[i].enum = getAccountType.data;
                                                                console.log(this.props.serviceList[i])
                                                            }
                                                        }

                                                        inputData.accountTypeApiCall = true;
                                                        varValue.accountTypeApiCall = true;
                                                        console.log(response.data);
                                                        inputData.accountCbNumber = response.data.custId;
                                                        //inputData.accountNumber = (this.state.inputData.debitAccountNumber).trim();
                                                        inputData.debitAccountName = response.data.name;
                                                        if (response.data.schemeCode === "SBSTF") {
                                                            inputData.availableAmountShow = false;
                                                            varValue.availableAmountShow = false

                                                        } else {
                                                            inputData.availableAmountShow = true;
                                                            varValue.availableAmountShow = true
                                                        }
                                                        inputData.availableAmount = response.data.availableBalance;
                                                        varValue.availableAmount = response.data.availableBalance;
                                                        inputData.accountRestriction = response.data.accountRestriction;
                                                        inputData.currency = this.findByVarNameGetKeyValue(response.data.accountCurrency, this.state.currency);

                                                        varValue.debitAccountName = response.data.name;

                                                        //varValue.accountNumber = (this.state.inputData.debitAccountNumber).trim();
                                                        if (response.data.schemeCode === "SBSTF") {
                                                            inputData.availableAmountShow = false;
                                                            varValue.availableAmountShow = false

                                                        } else {
                                                            inputData.availableAmountShow = true;
                                                            varValue.availableAmountShow = true
                                                        }
                                                        inputData.repaymentAccounts=inputData.repaymentAccount;
                                                        varValue.repaymentAccounts=inputData.repaymentAccount;
                                                        inputData.repaymentsName = response.data.name;
                                                        varValue.repaymentsName = response.data.name;
                                                        inputData.availableAmount = response.data.availableBalance;
                                                        varValue.availableAmount = response.data.availableBalance;
                                                        varValue.accountRestriction = response.data.accountRestriction;
                                                        varValue.currency = response.data.accountCurrency;
                                                        varValue.currency = this.findByVarNameGetKeyValue(response.data.accountCurrency, this.state.currency);
                                                        if(this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account"){
                                                            varValue.currency = response.data.accountCurrency;
                                                            inputData.currency = response.data.accountCurrency;
                                                        }
                                                        if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                                                            let object = {
                                                                currency: typeof response.data.accountCurrency === "object" ? response.data.accountCurrency.key : response.data.accountCurrency,
                                                            };
                                                            if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                                                                object.natureAccount = "Individual-Retail";
                                                            } else {
                                                                object.natureAccount = "Non-Individual"
                                                            }
                                                            let Urls = backEndServerURL + "/FDRDPSSchemeCode/getDPSNameOfScheme";
                                                            axios.post(Urls, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                                .then((getAccountType) => {
                                                                    let jsonArrayServiceList = this.props.commonJsonForm;
                                                                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                                                        let jsonObjects = jsonArrayServiceList[i];
                                                                        if (jsonObjects.varName === "nameOfScheme") {
                                                                            this.props.commonJsonForm[i].enum = getAccountType.data;
                                                                            console.log(this.props.commonJsonForm[i])
                                                                        }
                                                                    }
                                                                    inputData.nameOfSchemeApiCall = true;
                                                                    varValue.nameOfSchemeApiCall = true;
                                                                    this.state.inputData.debitAccountNumberApiCall = "YES";
                                                                    this.updateComponent();
                                                                    this.setState({
                                                                        inputData: this.emptyValueRemove(inputData),
                                                                        varValue: this.emptyValueRemove(varValue),
                                                                        showValue: true,
                                                                        getData: true,
                                                                        getAccountType: true,
                                                                        getAccountInfo: true,
                                                                        loading: false,
                                                                        debitDataBoolean: true,
                                                                        nameOfSchemeApiCall: true,
                                                                    })
                                                                  /*  this.setState({
                                                                        accountBalanceInfo: true,
                                                                        getAccountType: true,
                                                                        cbsCustomerGet: true,
                                                                        inputData: inputData,
                                                                        varValue: varValue,
                                                                        showValue: true,
                                                                        //loading: false,
                                                                        getData: true,
                                                                        nameOfSchemeApiCall: true,

                                                                    })*/
                                                                })
                                                                .catch((error) => {
                                                                    this.setState({
                                                                        accountBalanceInfo: true,
                                                                        nameOfSchemeApiCall: true,
                                                                        cbsCustomerGet: true,
                                                                    });
                                                                    console.log(error);
                                                                })
                                                        }
                                                        else{
                                                            this.state.inputData.debitAccountNumberApiCall = "YES";
                                                            this.updateComponent();
                                                            this.setState({
                                                                inputData: this.emptyValueRemove(inputData),
                                                                varValue: this.emptyValueRemove(varValue),
                                                                showValue: true,
                                                                getData: true,
                                                                getAccountType: true,
                                                                getAccountInfo: true,
                                                                loading: false,
                                                                debitDataBoolean: true,
                                                            })

                                                        }


                                                    })
                                                    .catch((error) => {
                                                        this.setState({
                                                            getAccountType: true,
                                                            loading: false
                                                        });
                                                        console.log(error);
                                                    })
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                this.setState({
                                                    errorModalBoolean: true,
                                                    errorTittle: this.errorObjectCheck(error),
                                                    loading: false
                                                })
                                            })
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        this.setState({
                                            loading: false
                                        })
                                    })
                            }


                        })

                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false,
                                errorModalBoolean: true,
                                errorTittle: this.errorObjectCheck(error),
                                debitDataBoolean: true,

                            })
                        })

                })
                .catch((error) => {
                    this.state.inputData.debitAccountNumber = "__ERROR__";
                    this.state.varValue.debitAccountNumber = "__ERROR__";
                    this.forceUpdate();
                    console.log(error);

                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: this.errorObjectCheck(error),
                        debitDataBoolean: true,

                    })
                })

        }

        /*   if (data.varName === "tenure" || data.varName === "tenureType" || data.varName === "fdValueDate" || data.varName === "fdrSchemeCode" || allInputData[data.varName] !==undefined) {
             this.maturitydateCalculate()
         }
        else if (data.varName === "debitAccountNumber") {
             if (this.state.inputData.debitAccountNumber !== undefined && this.state.inputData.debitAccountNumber !== null && (this.state.inputData.debitAccountNumber).trim() !== "") {
                 this.setState({
                     loading: true
                 })
                 let url = backEndServerURL + "/intgr/accountBalanceInfo/" + (this.state.inputData.debitAccountNumber).trim();
                 axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                     .then((response) => {
                         console.log(response.data)
                        inputData.debitAccountName=response.data.name
                     inputData.availableAmount=response.data.availableBalance
                     inputData.accountRestriction=response.data.accountRestriction;
                     inputData.currency=this.findByVarNameGetKeyValue(response.data.accountCurrency, this.state.currency);

                     varValue.debitAccountName=response.data.name
                     varValue.availableAmount=response.data.availableBalance
                     varValue.accountRestriction=response.data.accountRestriction;
                     varValue.currency=response.data.accountCurrency;
                     varValue.currency=this.findByVarNameGetKeyValue(response.data.accountCurrency, this.state.currency);

                         this.state.inputData.debitAccountNumberApiCall = "YES";
                         this.updateComponent();
                         this.setState({
                             inputData: inputData,
                             varValue: varValue,
                             showValue: true,
                             loading: false,
                         })
                     })
                     .catch((error) => {
                         console.log(error)
                         this.setState({

                             loading: false,
                             errorModalBoolean: true,
                             errorTittle: "Invalid Number"
                         })
                     })

             }

         }
      /!*  else if (data.varName === "cbNumber") {

             if (this.state.inputData.cbNumber !== undefined && this.state.inputData.cbNumber !== null && (this.state.inputData.cbNumber).trim() !== "") {
                 this.setState({
                     loading: true
                 })
                 let url = backEndServerURL + "/customerInfo/" + (this.state.inputData.cbNumber).trim();
                 axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                     .then((response) => {
                         console.log(response.data)
                         inputData.customerName = response.data.fullName;
                         varValue.customerName = response.data.fullName
                         this.state.inputData.cbNumberApiCall = "YES";
                         this.updateComponent();
                         this.setState({
                             inputData: inputData,
                             varValue: varValue,
                             showValue: true,
                             loading: false,
                         })
                     })
                     .catch((error) => {
                         console.log(error)
                         this.setState({

                             loading: false,
                             errorModalBoolean: true,
                             errorTittle: "Invalid Number"
                         })
                     })

             }
         }*!/*/
    };
    /*  maturitydateCalculate = () => {
          let fdDate = (this.state.inputData.fdValueDate);
          let type = (this.state.inputData.tenureType);
          let tenure = (this.state.inputData.tenure);
          console.log(type)
          console.log(tenure)
          console.log(this.state.inputData.fdrSchemeCode)
          console.log(this.state.inputData.amount)
          if (type !== undefined && tenure !== undefined && (this.state.inputData.fdrSchemeCode !== undefined || this.state.inputData.dpsSchemeCode !== undefined) && this.state.inputData.amount !== undefined) {
              let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
              let data = {};
              if (this.state.inputData.dpsSchemeCode !== undefined) {
                  data.schemeCode = this.state.inputData.dpsSchemeCode.value;

              } else {
                  data.schemeCode = this.state.inputData.fdrSchemeCode.value;

              }
              data.amount = this.state.inputData.amount;
              data.tenor = this.state.inputData.tenure;
              data.tenorType = this.state.inputData.tenureType;
              console.log(data)
              this.state.inputData.interestRateApicall = "NO";

              axios.post(interestCalculateAPi, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                  .then((response) => {
                      console.log(response.data);
                      this.state.inputData.interestRate = response.data;
                      this.state.varValue.interestRate = response.data;
                      this.state.inputData.interestRateApicall = "YES";

                      this.forceUpdate()
                  })
                  .catch((error) => {
                      console.log(error);
                      this.state.inputData.interestRateApicall = "YES";

                  })
          }

          if ((fdDate !== undefined && fdDate !== null && fdDate !== "") && type === "Monthly" && Number(tenure) !== 0 && Number(tenure) !== undefined && Number(tenure) !== null) {

              this.state.inputData.fdMaturityDateApicall = "NO";
              this.forceUpdate()
              let someDate = new Date();
              if (this.state.inputData.fdValueDate !== undefined && this.state.inputData.fdValueDate !== null && this.state.inputData.fdValueDate !== -1) {
                  someDate = new Date(this.state.inputData.fdValueDate);
              }
              let x = tenure;
              let numberOfMonthsToAdd = parseInt(x, 10)
              someDate.setMonth(someDate.getMonth() + numberOfMonthsToAdd);
              let dates = someDate.getDate();
              let month = someDate.getMonth() + 1;
              if (month <= 9) {
                  month = "0" + month;
              }
              if (dates <= 9) {
                  dates = "0" + dates;
              }
              /!*  let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
                axios.post(interestCalculateAPi, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        let year = someDate.getFullYear();
                        this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                        this.state.inputData.fdMaturityDateApicall="YES";
                        this.forceUpdate()

                        console.log(this.state.inputData["fdMaturityDate"])
                    })
                    .catch((error)=>{
                        let year = someDate.getFullYear();
                        this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                        this.state.inputData.fdMaturityDateApicall="YES";
                        this.forceUpdate()

                        console.log(this.state.inputData["fdMaturityDate"])
                    })*!/
                setTimeout( ()=> {
                  let year = someDate.getFullYear();
                  this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                  this.state.inputData.fdMaturityDateApicall = "YES";
                  this.forceUpdate()
             }, 1000);

          }
          if ((fdDate !== undefined && fdDate !== null && fdDate !== "") && type === "Yearly" && Number(tenure) !== 0 && Number(tenure) !== undefined && Number(tenure) !== null) {

              this.state.inputData.fdMaturityDateApicall = "NO";
              this.forceUpdate()
              let someDate = new Date();
              if (this.state.inputData.fdValueDate !== undefined && this.state.inputData.fdValueDate !== null && this.state.inputData.fdValueDate !== -1) {
                  someDate = new Date(this.state.inputData.fdValueDate);
              }
              let x = (tenure)*12;
              let numberOfMonthsToAdd = parseInt(x, 10)
              someDate.setMonth(someDate.getMonth() + numberOfMonthsToAdd);
              let dates = someDate.getDate();
              let month = someDate.getMonth() + 1;
              if (month <= 9) {
                  month = "0" + month;
              }
              if (dates <= 9) {
                  dates = "0" + dates;
              }
              /!*  let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
                axios.post(interestCalculateAPi, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        let year = someDate.getFullYear();
                        this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                        this.state.inputData.fdMaturityDateApicall="YES";
                        this.forceUpdate()

                        console.log(this.state.inputData["fdMaturityDate"])
                    })
                    .catch((error)=>{
                        let year = someDate.getFullYear();
                        this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                        this.state.inputData.fdMaturityDateApicall="YES";
                        this.forceUpdate()

                        console.log(this.state.inputData["fdMaturityDate"])
                    })*!/
              Functions.sleep(1500).then(() => {
                  let year = someDate.getFullYear();
                  this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                  this.state.inputData.fdMaturityDateApicall = "YES";
                  this.forceUpdate()
              })

          }
          else if ((fdDate !== undefined && fdDate !== null && fdDate !== "") && type === "Day" && Number(tenure) !== 0 && Number(tenure) !== undefined && Number(tenure) !== null) {

              this.state.inputData.fdMaturityDateApicall = "NO";
              this.forceUpdate()

              let someDate = new Date();
              if (this.state.inputData.fdValueDate !== undefined && this.state.inputData.fdValueDate !== null && this.state.inputData.fdValueDate !== -1) {
                  someDate = new Date(this.state.inputData.fdValueDate);
              }
              let x = tenure;
              let numberOfDaysToAdd = parseInt(x, 10)

              someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
              let date = someDate.getDate();
              let month = someDate.getMonth() + 1;
              let year = someDate.getFullYear();
              if (month <= 9) {
                  month = "0" + month;
              }
              if (date <= 9) {
                  date = "0" + date;
              }
                 setTimeout( ()=> {
                  this.state.inputData["fdMaturityDate"] = date + '-' + month + '-' + year;
                  this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;

                  //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                  //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                  this.state.inputData.fdMaturityDateApicall = "YES";
                  this.forceUpdate()
                }, 1000);
              /!*   let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";

                 axios.post(interestCalculateAPi, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                     .then((response) => {
                         this.state.inputData["fdMaturityDate"] = date + '-' + month + '-' + year;
                         this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;

                         //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                         //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                         this.state.inputData.fdMaturityDateApicall="YES";
                         this.forceUpdate()

                         console.log(this.state.inputData["fdMaturityDate"])
                     })
                     .catch((error)=>{
                         this.state.inputData["fdMaturityDate"] = date + '-' + month + '-' + year;
                         this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;

                         //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                         //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                         this.state.inputData.fdMaturityDateApicall="YES";
                         this.forceUpdate()

                         console.log(this.state.inputData["fdMaturityDate"])
                     })*!/


          } else {
              this.state.inputData["fdMaturityDate"] = "";
          }


      }*/

    maturitydateCalculate = (data) => {
        let fdDate = (this.state.inputData.fdValueDate);
        let type = (this.state.inputData.tenureType);
        let tenure = (this.state.inputData.tenure);
        console.log(type);
        console.log(tenure);
        console.log(this.state.inputData.fdrSchemeCode);
        console.log(this.state.inputData.amount);
        // if (data.varName === "tenure" || data.varName === "tenureType" || data.varName === "fdValueDate" || data.varName === "fdrSchemeCode" || data.varName === "amount") {
        if (type !== undefined && tenure !== undefined && (this.state.inputData.fdrSchemeCode !== undefined || this.state.inputData.dpsSchemeCode !== undefined) && this.state.inputData.amount !== undefined) {
            let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
            let data = {};
            if (this.state.inputData.dpsSchemeCode !== undefined) {
                data.schemeCode = this.state.inputData.dpsSchemeCode.key;

            } else {
                data.schemeCode = this.state.inputData.fdrSchemeCode.key;

            }
            data.amount = this.state.inputData.amount;
            data.tenure = this.state.inputData.tenure;
            data.tenureType = this.state.inputData.tenureType;
            data.tenorType = this.state.inputData.tenureType;
            data.currency = this.state.inputData.currency;
            if(this.state.inputData.fdValueDate!==undefined){
                data.inputDate = this.state.inputData.fdValueDate;
            }
            else if(this.state.inputData.dpsValueDate!==undefined){
                data.inputDate = this.state.inputData.dpsvalueDate;
            }
            console.log(data);
            this.state.inputData.interestRateApicall = "NO";
            this.state.inputData.interestRate = "";
            this.state.varValue.interestRate = "";
            this.forceUpdate();
            axios.post(interestCalculateAPi, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.interestRate = response.data;
                    this.state.varValue.interestRate = response.data;
                    this.state.inputData.interestRateApicall = "YES";

                    this.forceUpdate()
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate()

                })
        }
        if ((data.varName === "tenure" || data.varName === "tenureType" || data.varName === "fdValueDate" || data.varName === "fdrSchemeCode" || data.varName === "amount") && type === "Month") {
            this.state.inputData.fdMaturityDateApicall = "NO";
            this.state.varValue.fdMaturityDateApicall = "NO";
            this.forceUpdate();
            let someDate = new Date();
            if (this.state.inputData.fdValueDate !== undefined && this.state.inputData.fdValueDate !== null && this.state.inputData.fdValueDate !== -1) {
                someDate = new Date(this.state.inputData.fdValueDate);
            }
            let x = tenure;
            let numberOfMonthsToAdd = parseInt(x, 10);
            someDate.setMonth(someDate.getMonth() + numberOfMonthsToAdd);
            let dates = someDate.getDate();
            let month = someDate.getMonth() + 1;
            if (month <= 9) {
                month = "0" + month;
            }
            if (dates <= 9) {
                dates = "0" + dates;
            }
            /*  let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
             axios.post(interestCalculateAPi, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                 .then((response) => {
                     let year = someDate.getFullYear();
                     this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                     this.state.inputData.fdMaturityDateApicall="YES";
                     this.forceUpdate()

                     console.log(this.state.inputData["fdMaturityDate"])
                 })
                 .catch((error)=>{
                     let year = someDate.getFullYear();
                     this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                     this.state.inputData.fdMaturityDateApicall="YES";
                     this.forceUpdate()

                     console.log(this.state.inputData["fdMaturityDate"])
                 })*/
            let year = someDate.getFullYear();
            let calculateDate = dates + '-' + month + '-' + year;
            if (calculateDate === "NaN-NaN-NaN") {
                calculateDate = ""
            }
            setTimeout(() => {
                this.state.inputData.fdMaturityDate = calculateDate;
                this.state.varValue.fdMaturityDate = calculateDate;
                this.state.inputData.fdMaturityDateApicall = "YES";
                this.state.varValue.fdMaturityDateApicall = "YES";
                this.forceUpdate()
            }, 1000)
        } else if ((data.varName === "tenure" || data.varName === "tenureType" || data.varName === "fdValueDate" || data.varName === "fdrSchemeCode" || data.varName === "amount") && type === "Year") {

            this.state.inputData.fdMaturityDateApicall = "NO";
            this.state.varValue.fdMaturityDateApicall = "NO";
            this.forceUpdate();
            let someDate = new Date();
            if (this.state.inputData.fdValueDate !== undefined && this.state.inputData.fdValueDate !== null && this.state.inputData.fdValueDate !== -1) {
                someDate = new Date(this.state.inputData.fdValueDate);
            }
            let x = tenure;
            let numberOfMonthsToAdd = parseInt(x, 10);
            someDate.setFullYear(someDate.getFullYear() + numberOfMonthsToAdd);
            let dates = someDate.getDate();
            let month = someDate.getMonth() + 1;
            if (month <= 9) {
                month = "0" + month;
            }
            if (dates <= 9) {
                dates = "0" + dates;
            }
            /*  let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
              axios.post(interestCalculateAPi, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                  .then((response) => {
                      let year = someDate.getFullYear();
                      this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                      this.state.inputData.fdMaturityDateApicall="YES";
                      this.forceUpdate()

                      console.log(this.state.inputData["fdMaturityDate"])
                  })
                  .catch((error)=>{
                      let year = someDate.getFullYear();
                      this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                      this.state.inputData.fdMaturityDateApicall="YES";
                      this.forceUpdate()

                      console.log(this.state.inputData["fdMaturityDate"])
                  })*/
            let year = someDate.getFullYear();
            let calculateDate = dates + '-' + month + '-' + year;
            if (calculateDate === "NaN-NaN-NaN") {
                calculateDate = ""
            }
            setTimeout(() => {

                this.state.inputData.fdMaturityDate = calculateDate;
                this.state.varValue.fdMaturityDate = calculateDate;
                this.state.inputData.fdMaturityDateApicall = "YES";
                this.state.varValue.fdMaturityDateApicall = "YES";
                this.forceUpdate()
            }, 1000)

        } else if ((data.varName === "tenure" || data.varName === "tenureType" || data.varName === "fdValueDate" || data.varName === "fdrSchemeCode" || data.varName === "amount") && type === "Day") {

            this.state.inputData.fdMaturityDateApicall = "NO";
            this.state.varValue.fdMaturityDateApicall = "NO";
            this.forceUpdate();

            let someDate = new Date();
            if (this.state.inputData.fdValueDate !== undefined && this.state.inputData.fdValueDate !== null && this.state.inputData.fdValueDate !== -1) {
                someDate = new Date(this.state.inputData.fdValueDate);
            }
            let x = tenure;
            let numberOfDaysToAdd = parseInt(x, 10);

            someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            let date = someDate.getDate();
            let month = someDate.getMonth() + 1;
            let year = someDate.getFullYear();
            if (month <= 9) {
                month = "0" + month;
            }
            if (date <= 9) {
                date = "0" + date;
            }
            let calculateDate = date + '-' + month + '-' + year;
            if (calculateDate === "NaN-NaN-NaN") {
                calculateDate = ""
            }
            setTimeout(() => {
                this.state.inputData.fdMaturityDate = calculateDate;
                this.state.varValue.fdMaturityDate = calculateDate;

                //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                this.state.inputData.fdMaturityDateApicall = "YES";
                this.state.varValue.fdMaturityDateApicall = "YES";
                this.forceUpdate()
            }, 1000)
            /*   let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";

               axios.post(interestCalculateAPi, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                   .then((response) => {
                       this.state.inputData["fdMaturityDate"] = date + '-' + month + '-' + year;
                       this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;

                       //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                       //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                       this.state.inputData.fdMaturityDateApicall="YES";
                       this.forceUpdate()

                       console.log(this.state.inputData["fdMaturityDate"])
                   })
                   .catch((error)=>{
                       this.state.inputData["fdMaturityDate"] = date + '-' + month + '-' + year;
                       this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;

                       //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                       //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                       this.state.inputData.fdMaturityDateApicall="YES";
                       this.forceUpdate()

                       console.log(this.state.inputData["fdMaturityDate"])
                   })*/


        } else {
            this.state.inputData["fdMaturityDate"] = "";
        }


    };

    onKeyDownChange = (data) => {
        let inputData = this.state.inputData;
        let varValue = this.state.varValue;
        this.updateComponent();
        let allInputData = this.state.inputData;
        if (data.varName === "tenure" || data.varName === "tenureType" || data.varName === "fdValueDate" || data.varName === "fdrSchemeCode" || data.varName === "amount") {
            this.maturitydateCalculate(data)
        } else if (data.varName === "debitAccountStatement") {
            this.setState({
                accountStatement: true
            })
        }
        if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
            if (data.varName === "nameOfScheme") {
                this.setState({
                    loading: true
                });
                this.state.inputData.accountAcquisitionApiCall = false;
                this.state.inputData.accountAcquisition = undefined;
                this.state.varValue.accountAcquisition = undefined;


                let object = {
                    currency: typeof this.state.inputData.currency === "object" ? this.state.inputData.currency.key : this.state.inputData.currency,
                };
                if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                    object.natureAccount = "Individual-Retail";
                } else {
                    object.natureAccount = "Non-Individual"
                }
                object.nameOfScheme = this.state.inputData.nameOfScheme;
                let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getDPSAccountAcquisition";//{sectorCode}
                axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);
                        let jsonArrayServiceList = this.props.commonJsonForm;
                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "accountAcquisition") {
                                this.props.commonJsonForm[i].enum = response.data;
                                console.log(this.props.commonJsonForm[i])
                            }
                        }
                        this.state.inputData.accountAcquisitionApiCall = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            } else if (data.varName === "accountAcquisition") {
                this.setState({
                    loading: true
                });
                this.state.inputData.tenureTypeApiCall = false;
                this.state.inputData.tenureApiCall = false;
                this.state.inputData.depositeTypeApiCall = false;
                this.state.inputData.dpsSchemeCodeApiCall = false;
                this.state.inputData.repaymentsAccountApiCall = "NO";
                this.state.inputData.tenureType = undefined;
                this.state.varValue.tenureType = undefined;
                let object = {
                    currency: typeof this.state.inputData.currency === "object" ? this.state.inputData.currency.key : this.state.inputData.currency,
                };
                if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                    object.natureAccount = "Individual-Retail";
                } else {
                    object.natureAccount = "Non-Individual"
                }
                object.nameOfScheme = this.state.inputData.nameOfScheme;
                object.accountAcquisition = this.state.inputData.accountAcquisition;
                let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getDPSTenureType";//{sectorCode}

                axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);
                        let jsonArrayServiceList = this.props.commonJsonForm;
                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "tenureType") {
                                this.props.commonJsonForm[i].enum = response.data;
                                console.log(this.props.commonJsonForm[i])
                            }
                        }
                        this.state.inputData.tenureTypeApiCall = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            } else if (data.varName === "tenureType") {
                this.setState({
                    loading: true
                });

                this.state.inputData.tenureApiCall = false;
                this.state.inputData.depositeTypeApiCall = false;
                this.state.inputData.dpsSchemeCodeApiCall = false;
                this.state.inputData.repaymentsAccountApiCall = "NO";
                this.state.inputData.tenure = undefined;
                this.state.varValue.tenure = undefined;
                let object = {
                    currency: typeof this.state.inputData.currency === "object" ? this.state.inputData.currency.key : this.state.inputData.currency,
                };
                if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                    object.natureAccount = "Individual-Retail";
                } else {
                    object.natureAccount = "Non-Individual"
                }
                object.nameOfScheme = this.state.inputData.nameOfScheme;
                object.accountAcquisition = this.state.inputData.accountAcquisition;
                object.tenureType = this.state.inputData.tenureType;
                let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getDPSTenure";//{sectorCode}
                axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);
                        let jsonArrayServiceList = this.props.commonJsonForm;
                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "tenure") {
                                this.props.commonJsonForm[i].enum = response.data;
                                console.log(this.props.commonJsonForm[i])
                            }
                        }
                        this.state.inputData.tenureApiCall = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            } else if (data.varName === "tenure") {
                this.setState({
                    loading: true
                });
                this.state.inputData.depositeTypeApiCall = false;
                this.state.inputData.dpsSchemeCodeApiCall = false;
                this.state.inputData.repaymentsAccountApiCall = "NO";
                this.state.inputData.depositeTypeApiCall = undefined;
                this.state.varValue.depositeTypeApiCall = undefined;

                this.state.inputData.amount = undefined;
                this.state.varValue.amount = undefined;
                let object = {
                    currency: typeof this.state.inputData.currency === "object" ? this.state.inputData.currency.key : this.state.inputData.currency,
                };
                if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                    object.natureAccount = "Individual-Retail";
                } else {
                    object.natureAccount = "Non-Individual"
                }
                object.nameOfScheme = this.state.inputData.nameOfScheme;
                object.accountAcquisition = this.state.inputData.accountAcquisition;
                object.tenureType = this.state.inputData.tenureType;
                object.tenure = this.state.inputData.tenure;
                let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getDPSSchemeCode";//{sectorCode}
                axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.state.inputData.dpsSchemeCode = response.data;
                        this.state.inputData.depositeType = "181";
                        this.state.varValue.dpsSchemeCode = response.data;
                        this.state.varValue.depositeType = "181";
                        this.state.inputData.depositeTypeApiCall = true;
                        this.state.inputData.dpsSchemeCodeApiCall = true;
                        this.state.inputData.repaymentsAccountApiCall = "YES";
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            }
        } else {
            if (data.varName === "accountType") {
                this.setState({
                    loading: true
                });
                this.state.inputData.accountAcquisitionApiCall = false;
                //this.state.inputData.accountTypeApiCall= false
                this.state.inputData.tenureTypeApiCall = false;
                this.state.inputData.tenureApiCall = false;
                this.state.inputData.autoRenewalsApiCall = false;
                this.state.inputData.maturityApiCall = false;
                this.state.inputData.fdrSchemeCodeApiCall = false;


                this.state.inputData.accountAcquisition = undefined;
                this.state.varValue.accountAcquisition = undefined;
                //this.state.inputData.tenureType=undefined;
                let object = {
                    currency: typeof this.state.inputData.currency === "object" ? this.state.inputData.currency.key : this.state.inputData.currency,
                };
                if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                    object.natureAccount = "Individual-Retail";
                } else {
                    object.natureAccount = "Non-Individual"
                }
                object.accountType = this.state.inputData.accountType;
                let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getAccountAcquisition";//{sectorCode}
                axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);
                        let jsonArrayServiceList = this.props.serviceList;
                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "accountAcquisition") {
                                this.props.serviceList[i].enum = response.data;
                                console.log(this.props.serviceList[i])
                            }
                        }
                        this.state.inputData.accountAcquisitionApiCall = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            } else if (data.varName === "accountAcquisition") {
                this.setState({
                    loading: true
                });
                this.state.inputData.tenureTypeApiCall = false;
                this.state.inputData.tenureApiCall = false;
                this.state.inputData.autoRenewalsApiCall = false;
                this.state.inputData.maturityApiCall = false;
                this.state.inputData.fdrSchemeCodeApiCall = false;

                this.state.inputData.tenureType = undefined;
                this.state.varValue.tenureType = undefined;
                let object = {
                    currency: typeof this.state.inputData.currency === "object" ? this.state.inputData.currency.key : this.state.inputData.currency,
                };
                if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                    object.natureAccount = "Individual-Retail";
                } else {
                    object.natureAccount = "Non-Individual"
                }
                object.accountType = this.state.inputData.accountType;
                object.accountAcquisition = this.state.inputData.accountAcquisition;
                let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getTenureType";//{sectorCode}
                axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);
                        let jsonArrayServiceList = this.props.serviceList;
                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "tenureType") {
                                this.props.serviceList[i].enum = response.data;
                                console.log(this.props.serviceList[i])
                            }
                        }
                        this.state.inputData.tenureTypeApiCall = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            } else if (data.varName === "tenureType") {
                this.setState({
                    loading: true
                });
                this.state.inputData.tenureApiCall = false;
                this.state.inputData.autoRenewalsApiCall = false;
                this.state.inputData.maturityApiCall = false;
                this.state.inputData.fdrSchemeCodeApiCall = false;

                this.state.inputData.tenure = undefined;
                this.state.varValue.tenure = undefined;
                let object = {
                    currency: typeof this.state.inputData.currency === "object" ? this.state.inputData.currency.key : this.state.inputData.currency,
                };
                if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                    object.natureAccount = "Individual-Retail";
                } else {
                    object.natureAccount = "Non-Individual"
                }
                object.accountType = this.state.inputData.accountType;
                object.accountAcquisition = this.state.inputData.accountAcquisition;
                object.tenureType = this.state.inputData.tenureType;
                let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getTenor";//{sectorCode}
                axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);
                        let jsonArrayServiceList = this.props.serviceList;
                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "tenure") {
                                this.props.serviceList[i].enum = response.data;
                                console.log(this.props.serviceList[i])
                            }
                        }
                        this.state.inputData.tenureApiCall = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            } else if (data.varName === "tenure") {
                this.setState({
                    loading: true
                });
                this.state.inputData.autoRenewalsApiCall = false;
                this.state.inputData.maturityApiCall = false;
                this.state.inputData.fdrSchemeCodeApiCall = false;
                this.state.inputData.depositeTypeApicall = false;


                this.state.inputData.autoRenewals = undefined;
                this.state.varValue.autoRenewals = undefined;
                let object = {
                    currency: typeof this.state.inputData.currency === "object" ? this.state.inputData.currency.key : this.state.inputData.currency,
                };
                if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                    object.natureAccount = "Individual-Retail";
                } else {
                    object.natureAccount = "Non-Individual"
                }
                object.accountType = this.state.inputData.accountType;
                object.accountAcquisition = this.state.inputData.accountAcquisition;
                object.tenureType = this.state.inputData.tenureType;
                object.tenure = this.state.inputData.tenure;
                let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getRenewal";//{sectorCode}
                axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);
                        let jsonArrayServiceList = this.props.serviceList;
                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "autoRenewals") {
                                this.props.serviceList[i].enum = response.data.renewal;
                                console.log(this.props.serviceList[i])
                            }

                        }
                        this.state.inputData.depositeType = response.data.depositCode;
                        this.state.varValue.depositeType = response.data.depositCode;
                        this.state.inputData.autoRenewalsApiCall = true;
                        this.state.inputData.depositeTypeApicall = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            } else if (data.varName === "autoRenewals") {
                this.setState({
                    loading: true
                });
                this.state.inputData.maturityApiCall = false;
                this.state.inputData.fdrSchemeCodeApiCall = false;

                this.state.inputData.maturity = undefined;
                this.state.varValue.maturity = undefined;
                let object = {
                    currency: typeof this.state.inputData.currency === "object" ? this.state.inputData.currency.key : this.state.inputData.currency,
                };
                if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                    object.natureAccount = "Individual-Retail";
                } else {
                    object.natureAccount = "Non-Individual"
                }
                object.accountType = this.state.inputData.accountType;
                object.accountAcquisition = this.state.inputData.accountAcquisition;
                object.tenureType = this.state.inputData.tenureType;
                object.tenure = this.state.inputData.tenure;
                object.autoRenewals = this.state.inputData.autoRenewals;
                let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getMaturity";//{sectorCode}
                axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);
                        let jsonArrayServiceList = this.props.serviceList;
                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "maturity") {
                                this.props.serviceList[i].enum = response.data;
                                console.log(this.props.serviceList[i])
                            }
                        }
                        this.state.inputData.maturityApiCall = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            } else if (data.varName === "maturity") {
                this.setState({
                    loading: true
                });
                this.state.inputData.fdrSchemeCodeApiCall = false;

                this.state.inputData.fdrSchemeCode = undefined;
                this.state.varValue.fdrSchemeCode = undefined;

                this.state.inputData.amount = undefined;
                this.state.varValue.amount = undefined;
                let object = {
                    currency: typeof this.state.inputData.currency === "object" ? this.state.inputData.currency.key : this.state.inputData.currency,
                };
                if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                    object.natureAccount = "Individual-Retail";
                } else {
                    object.natureAccount = "Non-Individual"
                }
                object.accountType = this.state.inputData.accountType;
                object.accountAcquisition = this.state.inputData.accountAcquisition;
                object.tenureType = this.state.inputData.tenureType;
                object.tenure = this.state.inputData.tenure;
                object.autoRenewals = this.state.inputData.autoRenewals;
                object.maturity = this.state.inputData.maturity;
                let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getSchemeCode";//{sectorCode}
                axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);
                        this.state.inputData.fdrSchemeCode = response.data;
                        this.state.varValue.fdrSchemeCode = response.data;
                        this.state.inputData.fdrSchemeCodeApiCall = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            }
        }

        /*   else if (data.varName === "interestCreditAccount") {

           }
           else if (data.varName === "repaymentAccount") {

           }
           else if (data.varName === "fdrSchemeCode") {

           }
           else if (data.varName === "interestRate") {

           }*/
        if (data.varName === "sectorCode") {
            this.setState({
                loading: true
            });
            this.state.inputData.sectorCodeApicall = false;
            let codeUrl = backEndServerURL + "/finMasterData/custom/subSector/" + this.state.inputData.sectorCode["key"];//{sectorCode}
            axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    let jsonArrayServiceList = this.props.commonJsonForm;
                    if (this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account') {
                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "subSectorCode") {
                                this.props.commonJsonForm[i].enum = response.data;
                                console.log("cccccccccccccccccccccccccccc");
                                console.log(this.props.commonJsonForm[i])

                            }
                        }
                    } else {
                        jsonArrayServiceList = this.props.serviceList;

                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "subSectorCode") {
                                this.props.serviceList[i].enum = response.data;
                                console.log("cccccccccccccccccccccccccccc");
                                console.log(this.props.serviceList[i])

                            }
                        }
                    }


                    this.state.inputData.sectorCodeApicall = true;
                    this.forceUpdate();
                    this.setState({
                        subsectorList: response.data,
                        loading: false
                    })

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })
        }

    };
    renderWaiverList = () => {
        if (this.state.getData) {
            return (
                <WaiverList appId={this.props.appId}/>
            )
        }
    };

    closeStatement = () => {
        this.setState({
            accountStatement: false
        })
    };
    handleChangeSdnResult = (event) => {
        event.preventDefault();
        this.setState({
            SdnAndDedupeResultShowing: true
        })

    };
    closeModalForSdnResult = () => {
        this.setState({
            SdnAndDedupeResultShowing: false
        })
    }

    renderSDNDedupeResult = () => {
        if (this.state.getData && (this.state.getInitiateData || this.props.appId !== undefined)) {

            return (
                <Button
                    className="btn btn-outline-primary btn-sm"
                    style={{}}

                    type='button' value='add more'
                    onClick={(event) => this.handleChangeSdnResult(event)}
                >
                    Dedupe & SDN Full Result
                </Button>
            )
        }

    };
    render() {

        const {classes} = this.props;
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderHeader()}
                </CardHeader>
                <CardBody>
                    <div>
                        <ThemeProvider theme={theme}>
                            <Grid container spacing={1}>
                                {this.renderNotification()}
                                {this.renderSDNDedupeResult()}
                                <Grid item xs='12'>
                                    <br/>
                                    <br/>
                                    <br/>
                                </Grid>
                                <Grid item xs={12}>
                                    {this.renderSignatureAndDebitAccount()}
                                </Grid>
                                {this.returnJsonForm()}

                                {this.returnServiceList()}
                                {this.returnServiceListLast()}
                                <Grid item xs='12'>
                                </Grid>
                                {this.renderAddButtonShow()}
                                <Grid item xs='12'>
                                </Grid>
                                {this.addDeferalForm()}
                                <Grid item xs='12'>
                                </Grid>
                                {
                                    this.addWaiverForm()
                                }
                                <Grid item xs='12'>
                                    {this.renderDefferalData()}

                                </Grid>
                                <Grid item xs={12}>
                                    {this.renderWaiverList()}
                                </Grid>
                                <Grid item xs='12'>
                                    {this.renderRemarksData()}
                                </Grid>


                                {this.renderUploadCheckbox()}
                                <Grid item xs={1}>
                                    {this.renderUploadButton()}
                                </Grid>
                                <Grid item xs={1}>
                                    {this.renderAssignedImage()}
                                </Grid>

                                <Grid item xs={12}>
                                    {this.renderDownloadGenerateForm()}
                                </Grid>
                                <Grid item xs='12'>
                                    {this.renderRemarks()}
                                </Grid>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    open={this.state.getMappingAllImage}>
                                    <DialogContent>
                                        {this.renderMappingImageEditModal()}
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.loading}>
                                    <DialogContent className={classes.dialogPaper}>

                                        <center>
                                            <img src={loader} alt=""/>
                                        </center>
                                    </DialogContent>
                                </Dialog>

                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    open={this.state.uploadModal}>
                                    <DialogContent>
                                        {this.renderMappingImageModal()}
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.errorModalBoolean}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ErrorModal title={this.state.errorTittle}
                                                    closeModal={this.closeModal}
                                                    subServiceType={this.props.subServiceType}
                                                    appId={this.props.appId}
                                        />

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="lg"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.confirmAlert}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ConfirmAlertImage
                                            fdrImageShowPopUp={this.state.fdrImageShowPopUp}
                                            notificationMessgaeCommonFlag={this.state.notificationMessgaeCommonFlag}
                                            notificationMessgaeCommon={this.state.notificationMessgaeCommon}
                                            validationForHandleConfirm={this.validationForHandleConfirm}
                                            closeModal={this.closeConfirmAlert}/>

                                    </DialogContent>
                                </Dialog>

                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    open={this.state.accountDetailsModal}>
                                    <DialogContent>
                                        <AccountNoGenerate
                                            generateNewAccountNumber={this.state.generateNewAccountNumber}
                                            generateNewCB={this.state.generateNewCB}
                                            customerName={this.state.inputData.customerName}
                                            solId={this.state.inputData.solId}
                                            closeModal={this.accountDetailsModal}/>
                                    </DialogContent>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="md"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.history}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <SubmittedCaseHistory customerName={this.state.customerName}
                                                                  accountNumber={this.state.accountNumber}
                                                                  solId={this.state.solId} globalSearch={true}
                                                                  closeIcon={this.closeHistoryModal}
                                                                  appId={this.state.appId}
                                                                  closeModal={this.closeHistoryModal}/>
                                        </DialogContent>
                                    </Dialog>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="md"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.SdnAndDedupeResultShowing}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <SdnAndDedupeResultShowing closeModal={this.closeModalForSdnResult}
                                                                   branchName={this.props.branchName}

                                                                   subServiceType={this.props.subServiceType!==undefined?this.props.subServiceType:this.state.inputData.subServiceType}
                                                                   category={this.props.category!==undefined?this.props.category:this.state.inputData.category}
                                                                   serviceType={this.props.serviceType!==undefined?this.props.serviceType:this.state.inputData.serviceType}
                                                                   subCategory={this.props.subCategory!==undefined?this.props.subCategory:this.state.inputData.subCategory}
                                                                   freeFlag2={this.props.freeFlag2 === "externalUserClosedRequest" ? true : false}
                                                                   serviceType={this.props.serviceType!==undefined?this.props.serviceType:this.state.inputData.serviceType}
                                                                   customerName={this.props.customerName}
                                                                   accountNumber={this.props.accountNumber}
                                                                   cbNumber={this.props.cbNumber}
                                                                   solId={this.props.solId} globalSearch={true}
                                                                   closeIcon={this.closeModalForSdnResult}
                                                                   appId={this.props.appId!==undefined?this.props.appId:this.state.appId}/>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.accountStatement}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <AccountStatement closeModal={this.closeStatement}
                                                          accountNumber={this.state.inputData.debitAccountNumber}/>
                                    </DialogContent>
                                </Dialog>


                                <Grid item xs='12'>
                                </Grid>
                                {this.renderButton()}
                            </Grid>
                        </ThemeProvider>
                    </div>
                </CardBody>
            </Card>
        );
    }

}

export default withStyles(styles)(AccountEdit);
