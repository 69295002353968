import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../../Common/Functions';
import Notification from "../../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../../JsonForm/CustomeTheme";
import Table from "../../../Table/Table";
import {Dialog} from "@material-ui/core/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import loader from "../../../../Static/loader.gif";
import Label from "./Label"
import SignatureButton from "../SignatureButton";
import PreviewDocument from "../casa/PreviewDocument";
import PreviewMappingImage from "../casa/PreviewMappingImage";
import MyValidation from "../../../JsonForm/MyValidation";

let bmRemarks = [
    {
        "varName": "bmRemarks",
        "type": "textArea",
        "label": "BM Remarks",
        "required": true,
        "grid": 12
    }]
;

let newNominee = [
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "readOnly": true,
        "grid": 12
    },
    {
        "varName": "numberOfNominee",
        "type": "text",
        "label": "Number of New Nominee",
        "readOnly": true,
        "grid": 2
    }]
;


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};
let boxLabels;

class CloserBmApproval extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            getData: false,
            varValue: {},
            title: "",
            notificationMessage: "",
            alert: false,
            inputData: {},
            selectedDate: {},
            values: [],
            showValue: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            getDeferalList: [],
            loading: true,
            getImageLink: [],
            getImageBoolean: false,
            getRemarks: [],
            previewDocument: false
        };

    }


    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    componentDidMount() {
        var remarksArray = [];
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            let tableArray = [];
                            var status = "";
                            response.data.map((deferal) => {
                                if (deferal.status === "APPROVAL_WAITING") {
                                    status = "Waiting For Approval"
                                }
                                tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));
                            });
                            let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    this.setState({
                                        getImageLink: response.data,
                                        getImageBoolean: true
                                    });
                                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response) => {
                                            response.data.map((data) => {
                                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                            });
                                            this.setState({
                                                getRemarks: remarksArray
                                            })
                                        })
                                        .catch((error) => {
                                            console.log(error)
                                        })
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                            this.setState({
                                getDeferalList: tableArray
                            })

                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    this.setState({
                        getRemarks: remarksArray
                    });
                    this.setState({
                        getData: true,
                        showValue: true,
                        varValue: this.removeNullValue(response.data),
                        inputData: this.removeNullValue(response.data),
                        // loading: false
                    });

                })
                .catch((error) => {
                    console.log(error);
                });
        }

    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };

    handleSubmit = (event, data) => {
        event.preventDefault();

        if (data === "RETURN") {
            let error = MyValidation.defaultValidation(bmRemarks, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
        }

        this.state.inputData.returnDataBm = true;
        var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.bmRemarks + "/" + this.props.appId;
        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                console.log(response.data)
            })
            .catch((error) => {
                console.log(error)
            });


        this.state.inputData.bm_approval = data;
        if (data === "APPROVED") {
            this.state.inputData.cs_bearer = "NO";
        }
        this.state.inputData.cs_deferal = "NO";
        this.state.inputData.csDeferal = "NO";

        this.state.inputData.bmRemarks = undefined;
        var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                var url = backEndServerURL + "/case/route/" + this.props.appId;

                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {


                        console.log(response.data);
                        this.setState({
                            title: "Successful!",
                            notificationMessage: "Successfully Routed!",
                            alert: true
                        });

                        this.props.closeModal();
                        //
                    })
                    .catch((error) => {
                        console.log('case route error');
                        console.log(error);

                    });

            })
            .catch((error) => {
                console.log(error)
            });
    };

    renderForm = () => {
        if (this.state.getData) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.jsonForm, this.updateComponent)

            )
        }
    };

    viewAttachedImages = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return <PreviewMappingImage appId={this.props.appId} classes={classes}/>
        }
    };

    previewDocument = () => {
        this.setState({
            previewDocument: true
        })
    };

    closePreviewDocument = () => {
        this.setState({
            previewDocument: false
        })
    };


    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, bmRemarks, this.updateComponent)
            )
        }
    };

    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Deferral List:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };


    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "APPROVED")}
                    >Approve
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "RETURN")}
                    >Return
                    </button>
                </div>
            )
        }
    };

    renderExistingNomineeForm = () => {
        if (this.state.getData && this.state.inputData.dpsCloserRequest === "DPS Nominee Change") {
            var sl;
            let objectForExistingNominee = [];
            for (var i = 0; i < this.state.inputData.nomineeNumberOld; i++) {
                sl = i + 1;
                objectForExistingNominee.push(
                    {
                        "varName": "Nominee " + i,
                        "type": "title",
                        "label": "Nominee  " + sl,
                        "grid": 12,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "dpsCloserRequest",
                        "conditionalVarValue": "DPS Nominee Change"
                    },
                    {
                        "varName": "registrationNumber" + sl,
                        "type": "text",
                        "label": "Registration No",
                        "grid": 3,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "dpsCloserRequest",
                        "conditionalVarValue": "DPS Nominee Change"
                    },
                    {
                        "varName": "individualId" + sl,
                        "type": "text",
                        "label": "CIF ID/Individual Id",
                        "grid": 3,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "dpsCloserRequest",
                        "conditionalVarValue": "DPS Nominee Change"
                    },
                    {
                        "varName": "nomineeNameOld" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 3,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "dpsCloserRequest",
                        "conditionalVarValue": "DPS Nominee Change"
                    },
                    {
                        "varName": "relationship" + sl,
                        "type": "date",
                        "label": "Relationship",
                        "grid": 3,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "dpsCloserRequest",
                        "conditionalVarValue": "DPS Nominee Change"
                    },
                    {
                        "varName": "percentageNomineeOld" + sl,
                        "type": "text",
                        "label": "Percentage %",
                        "grid": 3,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "dpsCloserRequest",
                        "conditionalVarValue": "DPS Nominee Change"
                    },
                    {
                        "varName": "addressFieldOne" + sl,
                        "type": "text",
                        "label": "Address Field 1",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "dpsCloserRequest",
                        "conditionalVarValue": "DPS Nominee Change"
                    },
                    {
                        "varName": "addressFieldTwo" + sl,
                        "type": "text",
                        "label": "Address Field 2",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "dpsCloserRequest",
                        "conditionalVarValue": "DPS Nominee Change"
                    },
                    {
                        "varName": "city" + sl,
                        "type": "text",
                        "label": "City",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "dpsCloserRequest",
                        "conditionalVarValue": "DPS Nominee Change"
                    },
                    {
                        "varName": "state" + sl,
                        "type": "text",
                        "label": "State",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "dpsCloserRequest",
                        "conditionalVarValue": "DPS Nominee Change"
                    },
                    {
                        "varName": "postalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "dpsCloserRequest",
                        "conditionalVarValue": "DPS Nominee Change"
                    },
                    {
                        "varName": "country" + sl,
                        "type": "text",
                        "label": "Country",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "dpsCloserRequest",
                        "conditionalVarValue": "DPS Nominee Change"
                    },
                    {
                        "varName": "photoIdNumberOrRegistrationNumber" + sl,
                        "type": "text",
                        "label": "Photo Id No/Registration No.",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "dpsCloserRequest",
                        "conditionalVarValue": "DPS Nominee Change"
                    },
                    {
                        "varName": "dob1Old" + sl,
                        "type": "date",
                        "label": "D.O.B",
                        "grid": 3,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "dpsCloserRequest",
                        "conditionalVarValue": "DPS Nominee Change"
                    },
                    {
                        "varName": "nomineeMinor" + sl,
                        "type": "text",
                        "label": "Nominee Minor",
                        "grid": 3,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "dpsCloserRequest",
                        "conditionalVarValue": "DPS Nominee Change"
                    },
                    {
                        "varName": "minorGuardiansName" + sl,
                        "type": "text",
                        "label": "Guardian's name",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["dpsCloserRequest", "nomineeMinor" + sl,],
                        "conditionalArrayValue": ["DPS Nominee Change", "Yes"],
                    },
                    {
                        "varName": "minorGuardianCode" + sl,
                        "type": "text",
                        "label": "Guardian Code",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["dpsCloserRequest", "nomineeMinor" + sl,],
                        "conditionalArrayValue": ["DPS Nominee Change", "Yes"],
                    },
                    {
                        "varName": "minorGuardianPhotoId" + sl,
                        "type": "text",
                        "label": "Guardian Photo Id",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["dpsCloserRequest", "nomineeMinor" + sl,],
                        "conditionalArrayValue": ["DPS Nominee Change", "Yes"],
                    },
                    {
                        "varName": "minorGuardianAddressOne" + sl,
                        "type": "text",
                        "label": "Address 1",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["dpsCloserRequest", "nomineeMinor" + sl,],
                        "conditionalArrayValue": ["DPS Nominee Change", "Yes"],
                    },
                    {
                        "varName": "minorGuardianAddressTwo" + sl,
                        "type": "text",
                        "label": "Address Two",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["dpsCloserRequest", "nomineeMinor" + sl,],
                        "conditionalArrayValue": ["DPS Nominee Change", "Yes"],
                    },
                    {
                        "varName": "minorGuardianCity" + sl,
                        "type": "text",
                        "label": "City",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["dpsCloserRequest", "nomineeMinor" + sl,],
                        "conditionalArrayValue": ["DPS Nominee Change", "Yes"],
                    },
                    {
                        "varName": "minorGuardianState" + sl,
                        "type": "text",
                        "label": "State",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["dpsCloserRequest", "nomineeMinor" + sl,],
                        "conditionalArrayValue": ["DPS Nominee Change", "Yes"],
                    },
                    {
                        "varName": "minorGuardianPostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["dpsCloserRequest", "nomineeMinor" + sl,],
                        "conditionalArrayValue": ["DPS Nominee Change", "Yes"],
                    },
                    {
                        "varName": "minorGuardianCountry" + sl,
                        "type": "text",
                        "label": "Country",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["dpsCloserRequest", "nomineeMinor" + sl,],
                        "conditionalArrayValue": ["DPS Nominee Change", "Yes"],
                    },
                )

            }


            return (
                <>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, objectForExistingNominee, this.updateComponent)
                    }
                </>
            )

        }
    };


    renderNewNomineeForm = () => {
        if (this.state.getData && this.state.inputData.dpsCloserRequest === "DPS Nominee Change") {
            console.log("i am in condition");

            var sl;
            let objectForNominee = [];
            for (var i = 0; i < this.state.inputData.numberOfNominee; i++) {
                sl = i + 1;
                objectForNominee.push(
                    {
                        "varName": "Nominee " + i,
                        "type": "title",
                        "label": "New Nominee  " + sl,
                        "grid": 12,

                    },
                    {
                        "varName": "nomineeNameNew" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 2,
                        "readOnly": true,
                        required: true,
                    },

                    {
                        "varName": "dobNew" + sl,
                        "type": "date",
                        "label": "D.O.B",
                        "grid": 2,
                        "readOnly": true,
                        required: true,
                    },


                    {
                        "varName": "percentageNominee" + sl,
                        "type": "text",
                        "label": "Percentage %",
                        "grid": 2,
                        "readOnly": true,
                        required: true,
                    },
                    {
                        "varName": "nomineeMinorNew" + sl,
                        "type": "select",
                        "label": "Nominee Minor",
                        "enum": ["Yes", "No"],
                        "readOnly": true,
                        "grid": 2,
                        required: true,
                    },
                )


            }


            return (
                <>
                    <br/>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, newNominee, this.updateComponent)
                    }

                    <br/>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, objectForNominee, this.updateComponent)
                    }

                </>
            )

        }
    };

    stopLoading = (value) => {
        if (value === "yes") {
            setTimeout(() => {
                this.setState({
                    loading: false,
                });
            }, 1000)
        } else {
            this.setState({
                getData: false,
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };


    renderCustomerDetails = () => {
        if (this.state.inputData.accountNumber && this.state.getData) {
            return (
                <Label cbNumber={this.state.inputData.cbNumber} classes={this.props}
                       accountNumber={this.state.inputData.accountNumber} stopLoading={this.stopLoading} create={true}/>
            )
        }
    };


    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);

        if (this.state.loading === true) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>

            )
        } else {
            return (
                <div>
                    <Dialog
                        fullWidth="true"
                        maxWidth="md"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.previewDocument}>
                        <DialogContent className={classes.dialogPaper}>
                            <PreviewDocument closeModal={this.closePreviewDocument} appId={this.state.appId}/>
                        </DialogContent>
                    </Dialog>
                    {this.renderCustomerDetails()}
                    <ThemeProvider theme={theme}>
                        <br/>
                        <Grid container spacing={0}>
                            <SignatureButton accountNumber={this.state.inputData.accountNumber}
                                             classes={this.props}/>&nbsp;
                            <button
                                onClick={this.previewDocument}
                                className="btn btn-outline-primary btn-sm">
                                Preview All Document
                            </button>
                        </Grid>
                        <br/>
                        <Grid container spacing={1}>
                            {this.renderNotification()}
                            <br/>
                            {this.renderForm()}
                            {this.renderExistingNomineeForm()}
                            {this.renderNewNomineeForm()}
                        </Grid>
                        <br/>
                        <Grid item={12}>
                            {this.renderRemarksData()}
                        </Grid>
                        <br/>
                        {this.viewAttachedImages()}
                        <br/>
                        <br/>
                        {this.renderRemarks()}
                        <br/>
                        <div>
                            {this.renderSubmitButton()}
                        </div>
                    </ThemeProvider>

                </div>


            )
        }
    }

}

export default withStyles(styles)(CloserBmApproval);
