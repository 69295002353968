import React, {Component} from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import LabelDetailsModal from "./LabelDetails";
import {Dialog} from "@material-ui/core";
import Box from '@material-ui/core/Box';
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";

class Label extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            inputData: {},
            showValue: true,
            labelData: [],
            inputValue: {},
            subCategory: this.props.subCategory,
            getData: false,
        }
    }


    componentDidMount() {
        let apiUrl = backEndServerURL + "/getTermDepositAccountFDR/" + this.props.accountNumber;
        axios.get(apiUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let matured = "";
                if (response.data.depositAccount !== undefined) {
                    let currentDate = new Date();
                    let today = currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getDay();
                    if (today === response.data.depositAccount.maturityDt.split("T")[0]) {
                        matured = "Matured"
                    } else if (today !== response.data.depositAccount.maturityDt.split("T")[0]) {
                        matured = "Premature Encashment"
                    }
                }
                let cbUrl = backEndServerURL + "/cbsCustomerGet/" + this.props.cbNumber;
                axios.get(cbUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((cbResponse) => {
                        let acUrl = backEndServerURL + "/GetCustomAcctDetailsRequest/" + this.props.accountNumber;
                        axios.get(acUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((acResponse) => {
                                let cifUrl = backEndServerURL + "/getCustomerCIFDetails/" + this.props.cbNumber;
                                axios.get(cifUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((cifResponse) => {
                                        let deferralUrl = backEndServerURL + "/deferral/getByAccountAndStatus/" + this.props.accountNumber;
                                        axios.post(deferralUrl, ["ACTIVE", "CLOSE_WAITING"], {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                            .then((deferralResponse) => {
                                                let FdrBoxLabels = [
                                                    {
                                                        name: 'CB Number', value: this.props.cbNumber
                                                    },
                                                    {
                                                        name: 'Customer Name',
                                                        value: acResponse.data.acctName
                                                    },
                                                    {
                                                        name: 'Mother Branch',
                                                        value: acResponse.data.solId
                                                    },
                                                    {
                                                        name: 'Citygem Center Name', value: cifResponse.data.freecode1
                                                    },
                                                    {
                                                        name: 'FD Open (value) Date',
                                                        value: response.data.depositAccount !== undefined ? response.data.depositAccount.accountOpenDate.split("T")[0] : ""
                                                    },
                                                    {
                                                        name: 'FD Scheme (Product) Code',
                                                        value: acResponse.data.schmCode
                                                    },
                                                    {
                                                        name: 'Principal Amount',
                                                        value: response.data.depositAccount !== undefined ? response.data.depositAccount.initialDeposit_amountValue : ""
                                                    },
                                                    {
                                                        name: 'Tenor (Days/ Months)',
                                                        value: (response.data.depositAccount !== undefined && response.data.depositAccount.depositTerm_days !== undefined && response.data.depositAccount.depositTerm_days > 0) ? "Days" : "Month"
                                                    },
                                                ];
                                                if (this.state.subCategory === "FDR Encashment" || this.state.subCategory === "FD Nominee Change" || this.state.subCategory === "FDR Interest Payment" || this.state.subCategory === "Deceased FDR Encashment") {
                                                    if (response.data.depositAccount !== undefined) {
                                                        FdrBoxLabels.push({
                                                            name: 'FD Current Balance',
                                                            value: response.data.depositAccount.currentDeposit_amountValue
                                                        });

                                                        FdrBoxLabels.push({
                                                            name: 'Link /Debit Account Number',
                                                            value: ""
                                                        });

                                                        FdrBoxLabels.push({
                                                            name: 'Last Renewal (Value) Date',
                                                            value: ""
                                                        });

                                                        FdrBoxLabels.push({
                                                            name: 'Maturity Date',
                                                            value: response.data.depositAccount.maturityDt.split("T")[0]
                                                        });

                                                        FdrBoxLabels.push({
                                                            name: 'Matured/ Premature Encashment',
                                                            value: matured
                                                        });

                                                        FdrBoxLabels.push({
                                                            name: 'Auto Closure',
                                                            value: response.data.depositAccount !== undefined ? response.data.depositAccount.autoCloseOnMaturityFlg : ""
                                                        });

                                                        FdrBoxLabels.push({
                                                            name: 'E-TIN status',
                                                            value: response.data.eTinNumber
                                                        });

                                                        FdrBoxLabels.push({
                                                            name: 'Deferral Status',
                                                            value: deferralResponse.data.length > 0 ? "Yes" : "No"
                                                        });
                                                    }
                                                } else if (this.state.subCategory === "Present FDR Encashment Value" || this.state.subCategory === "FDR Encashment Certificate") {
                                                    FdrBoxLabels.push({
                                                            name: 'Last Renewal (Value) Date', value: ""
                                                        },
                                                        {
                                                            name: 'FD Close Status',
                                                            value: "",
                                                            popup: true,
                                                            popupValue: 'FDR'
                                                        },
                                                        {
                                                            name: 'TDS Period',
                                                            value: (response.data.depositAccount !== undefined && response.data.depositAccount.depositTerm_days !== undefined && response.data.depositAccount.depositTerm_days > 0) ? response.data.depositAccount.depositTerm_days : response.data.depositAccount.depositTerm_months
                                                        });
                                                } else if (this.state.subCategory === "Link ACC Change") {
                                                    FdrBoxLabels.push({
                                                            name: 'Link /Debit Account Number', value: ""
                                                        },
                                                        {
                                                            name: 'FD Close Status', value: ""
                                                        },);
                                                } else if (this.state.subCategory === "Tenor or Scheme Change" || this.state.subCategory === "S. Tax Reversal") {
                                                    FdrBoxLabels.push({
                                                            name: 'AC status', value: acResponse.data.acctStatus
                                                        },
                                                        {
                                                            name: 'Link /Debit Account Number', value: ""
                                                        },
                                                        {
                                                            name: 'FD Close Date', value: ""
                                                        },
                                                        {
                                                            name: 'E-TIN Status', value: response.data.eTinNumber
                                                        },
                                                    );
                                                } else if (this.state.subCategory === "Dupl. Advice (Special)") {
                                                    FdrBoxLabels.push({
                                                            name: 'Link /Debit Account Number', value: ""
                                                        },
                                                        {
                                                            name: 'FD Opening Rate', value: ""
                                                        },
                                                        {
                                                            name: 'Maturity Date',
                                                            value: response.data.depositAccount !== undefined ? response.data.depositAccount.maturityDt.split("T")[0] : ""
                                                        },
                                                        {
                                                            name: 'Renewal Instruction',
                                                            value: response.data.depositAccount !== undefined ? response.data.depositAccount.autoRenewalflg : ""
                                                        },
                                                        {
                                                            name: 'FD Close Status', value: ""
                                                        },
                                                        {
                                                            name: 'Customer Communication Address',
                                                            value: cbResponse.data.addrLine1 !== undefined ? cbResponse.data.MailingBLOCK.addrLine1 : ""
                                                        },
                                                        {
                                                            name: 'Phone Number',
                                                            value: cbResponse.data.COMMPH1BLOCK !== undefined ? cbResponse.data.COMMPH1BLOCK.phoneNumLocalCode : ""
                                                        });
                                                } else if (this.state.subCategory === "Auxiliary Task") {
                                                    FdrBoxLabels.push({
                                                            name: 'Email Address (City Touch)',
                                                            value: cbResponse.data.COMMEMLBLOCK !== undefined ? cbResponse.data.COMMEMLBLOCK.emailInfo : ""
                                                        },
                                                        {
                                                            name: 'Mobile Number (City Touch)',
                                                            value: cbResponse.data.COMMPH1BLOCK !== undefined ? cbResponse.data.COMMPH1BLOCK.phoneNumLocalCode : ""
                                                        },
                                                        {
                                                            name: 'Occupation Code',
                                                            value: cbResponse.data.occupationCode
                                                        },
                                                        {
                                                            name: 'SBS Code', value: ""
                                                        },
                                                        {
                                                            name: 'FD Close Status', value: ""
                                                        },
                                                        {
                                                            name: 'RM Code',
                                                            value: response.data.customDataList !== undefined ? response.data.customDataList[0].rmcode : ""
                                                        },);
                                                }
                                                if (acResponse.data.lienDetails && acResponse.data.lienDetails.length > 0) {
                                                    FdrBoxLabels.push({
                                                        name: 'Lien status',
                                                        popup: true,
                                                        popupValue: 'Lien',
                                                        value: ""
                                                    });
                                                }
                                                if (acResponse.data.freezeCode || acResponse.data.freezeReasonCode) {
                                                    FdrBoxLabels.push({
                                                        name: 'Freeze status',
                                                        popup: true,
                                                        popupValue: 'Freeze',
                                                        value: ""
                                                    });
                                                }
                                                this.setState({
                                                    labelData: FdrBoxLabels,
                                                    inputData: acResponse.data,
                                                    showValue: true,
                                                    getData: true,
                                                }, this.parentLoad);
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                this.props.stopLoading(error)
                                            });
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        this.props.stopLoading(error)
                                    });
                            })
                            .catch((error) => {
                                console.log(error);
                                this.props.stopLoading(error)
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                        this.props.stopLoading(error)
                    });
            })
            .catch((error) => {
                console.log(error);
                this.props.stopLoading(error)
            });
    }

    parentLoad = () => {
        if (this.props.stopLoading !== undefined) {
            this.props.stopLoading("yes")
        }
    };

    labelDetails = (status) => {
        this.setState({
            labelName: status,
            labelOpen: true
        })
    };

    labelCloseModal = () => {
        this.setState({
            labelOpen: false
        })
    };

    render() {
        const {classes} = this.props.classes;

        if (this.state.labelData.length > 0 && this.state.getData) {
            return (

                <div style={{width: '100%'}}>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="flex-start"
                         bgcolor="background.paper"
                         style={{marginTop: "15px", borderTop: "2px solid #000", borderBottom: "2px solid #000"}}>

                        {
                            this.state.labelData.map((label) => {
                                return (
                                    <Box p={1} bgcolor="background.paper">
                                        <label><font size="2"><b
                                            style={{color: label.popupValue ? "red" : "black"}}>
                                            {label.popupValue ? <a onClick={(event) => {
                                                this.labelDetails(label.popupValue)
                                            }}>{label.name}</a> : label.name} {label.popupValue ? "" : ": "}
                                        </b></font></label>
                                        {label.popupValue ? "" : label.value}
                                    </Box>

                                )
                            })
                        }
                    </Box>
                    <Dialog
                        fullWidth="true"
                        maxWidth="md"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.labelOpen}>
                        <DialogContent className={classes.dialogPaper}>
                            <LabelDetailsModal closeModal={this.labelCloseModal} appId={this.state.appId}
                                               inputValue={this.state.inputData}
                                               labelName={this.state.labelName} data={this.state.labelData}/>

                        </DialogContent>
                    </Dialog>
                </div>

            );
        } else {
            return ""
        }
    }


}

export default Label;
