import React, {Component} from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import LabelDetailsModal from "./LabelDetails";
import {Dialog} from "@material-ui/core";
import Box from '@material-ui/core/Box';
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import loader from "../../../../Static/loader.gif";
import AccountStatement from "../../fdr/AccountStatement";

class Label extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            inputData: {},
            inputValue: {},
            showValue: false,
            labelData: [],
            linkedCB: [],
            getData: false,
            name: "",
            loading: true,
            accountStatement: false
        }
    }

    convertDate = (value) => {
        if (value && value !== -1) {
            let date = value.split('T')[0].split("-");
            return `${date[1]}/${date[2]}/${date[0]}`
        }
    };


    componentDidMount() {
        let labelData = [];
        let getDataByAc = backEndServerURL + "/GetCustomAcctDetailsRequestWithAccountNumberList/" + this.props.accountNumber;
        axios.get(getDataByAc, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.data.solId) {
                    labelData.push({
                        name: 'Mother SOL Id',
                        value: response.data.branchName ? response.data.branchName + " - " + response.data.solId : response.data.solId
                    })
                }
                labelData.push({name: 'CB Number', value: this.props.cbNumber});
                labelData.push({name: 'A/C Number', value: this.props.accountNumber});
                let url;
                if ((this.props.parameterAccountNumber !== undefined && this.props.parameterAccountNumber !== null) && this.props.linkedCB === true) {
                    url = backEndServerURL + "/cbsCustomerGet/" + this.props.cbNumber;
                } else {
                    url = backEndServerURL + "/cbsCustomerGet/" + response.data.cifId
                }

                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((cbDatResponse) => {
                        labelData.push(
                            {
                                name: 'Customer Name', value: cbDatResponse.data.customerName
                            }
                        );
                        if (response.data.acctStatus) {
                            labelData.push({name: 'Customer Status', value: "", popup: true, popupValue: 'ADUP'})
                        }
                        if (cbDatResponse.data.PASSPORTBLOCK !== undefined) {
                            labelData.push({
                                name: 'Passport Number',
                                value: cbDatResponse.data.PASSPORTBLOCK.referenceNum
                            });
                            labelData.push({
                                name: 'Passport Expiry Date',
                                value: this.convertDate(cbDatResponse.data.PASSPORTBLOCK.expireDt)
                            })
                        }
                        if (response.data.acctStatus === "D") {
                            labelData.push({name: 'Dormancy', value: "", popup: true, popupValue: 'Dormancy'})
                        }
                        // if (response.status === 200) {
                        // if (response.data.acctStatus) {
                        //     labelData.push({name: 'Ac Status', value: response.data.acctStatus})
                        // }
                        if (response.data.acctBalance) {
                            let balance = response.data.schmCode === "SBSTF" ? "N/A" : response.data.crncyCode + " " + response.data.acctEftvBalance;
                            labelData.push({name: 'Ac Balance', value: balance})
                        }
                        labelData.push({name: 'A/C Statement', value: "", popup: true, popupValue: 'acStatement'});
                        if (response.data.accountInfoList.casaAccountList && response.data.accountInfoList.casaAccountList.length > 0) {
                            labelData.push({
                                name: "Related Account",
                                value: "",
                                popup: true,
                                popupValue: 'relatedAccount'
                            })
                        }
                        if (response.data.accountInfoList.tdAccountList && response.data.accountInfoList.tdAccountList.length > 0) {
                            labelData.push({name: "FDR/DPS", value: "", popup: true, popupValue: 'FDR'})
                        }
                        if (response.data.linkedLoanDetails && response.data.linkedLoanDetails.length > 0) {
                            labelData.push({name: 'Loan Account', value: "", popup: true, popupValue: 'Loan'})
                        }
                        if (response.data.lienDetails && response.data.lienDetails.length > 0) {
                            labelData.push({name: 'Lien', value: "", popup: true, popupValue: 'Lien'})
                        }
                        if (response.data.freezeCode || response.data.freezeReasonCode) {
                            labelData.push({name: 'Freeze status', value: "", popup: true, popupValue: 'Freeze'})
                        }
                        if (!this.props.linkedCB && response.data.relPartyList && response.data.relPartyList.length > 0) {
                            let relDataArray = response.data.relPartyList.filter((item, index) => {
                                return item.relPartyType !== "M"
                            });

                            if (relDataArray.length > 0) {
                                labelData.push({
                                    name: 'Secondary CB',
                                    value: response.data.relPartyList,
                                    popup: true,
                                    popupValue: 'linkedCB'
                                },);
                            }
                        }

                        // if (response.data.freeText12) {
                        //     labelData.push({name: 'CTR', value: "", popup: true, popupValue: 'CTR'})
                        // }
                        setTimeout(() => {
                            this.setState({
                                labelData: labelData,
                                inputValue: response.data,
                                showValue: true,
                                getData: true,
                                loading: false,
                            }, this.parentLoad);
                        }, 1000)
                        // }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                        })
                    });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                });
                this.props.stopLoading(error)
            });
    }


    parentLoad = () => {
        if (this.props.stopLoading !== undefined) {
            this.props.stopLoading("yes")
        }
    };

    labelDetails = (status, name) => {
        if (status === "acStatement") {
            this.setState({
                accountStatement: true
            })
        } else {
            this.setState({
                labelName: status,
                name: name,
                labelOpen: true
            })
        }
    };

    labelCloseModal = () => {
        this.setState({
            accountStatement: false,
            labelOpen: false
        })
    };

    render() {
        const {classes} = this.props.classes;

        if (this.state.labelData.length > 0 && this.state.getData) {
            return (

                <div style={{width: '100%'}}>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="flex-start"
                         bgcolor="background.paper"
                         style={{marginTop: "10px", borderTop: "2px solid #000", borderBottom: "2px solid #000"}}>
                        {
                            this.state.labelData.map((label) => {
                                return (
                                    <Box p={1} bgcolor="background.paper">
                                        <label><font size="2"><b
                                            style={{color: label.popupValue ? "red" : "black"}}>
                                            {label.popupValue ? <a onClick={(event) => {
                                                this.labelDetails(label.popupValue, label.name)
                                            }}>{label.name}</a> : label.name} {label.popupValue ? "" : ": "}
                                        </b></font></label>
                                        {label.popupValue ? "" : label.value}
                                    </Box>

                                )
                            })
                        }
                    </Box>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>

                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.accountStatement}>
                        <DialogContent className={classes.dialogPaper}>
                            <AccountStatement closeModal={this.labelCloseModal}
                                              accountNumber={this.props.accountNumber}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="md"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.labelOpen}>
                        <DialogContent className={classes.dialogPaper}>
                            <LabelDetailsModal closeModal={this.labelCloseModal} name={this.state.name}
                                               appId={this.state.appId} labelName={this.state.labelName}
                                               data={this.state.labelData} inputValue={this.state.inputValue}
                                               accountNumber={this.props.accountNumber} crmStatus={this.props.crmStatus}
                                               linkedCB={this.props.linkedCB}
                                               cbNumber={this.props.cbNumber} create={this.props.create}/>
                        </DialogContent>
                    </Dialog>
                </div>
            );
        } else {

            return <Dialog
                fullWidth="true"
                maxWidth="sm"
                className={classes.modal}
                classes={{paper: classes.dialogPaper}}
                open={this.state.loading}>
                <DialogContent className={classes.dialogPaper}>

                    <center>
                        <img src={loader} alt=""/>
                    </center>
                </DialogContent>
            </Dialog>
        }
    }


}

export default Label;