import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import theme from "../../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import GridItem from "../../../Grid/GridItem.jsx";
import GridContainer from "../../../Grid/GridContainer.jsx";
import signature from "../../../../assets/image.png";
import {frontEndServerURL} from "../../../../Common/Constant";


class LabelDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            labelData: [
                ...this.props.data,
            ],
            inputData: {
                freeText: "",
                accountStatus: '',
                dormantActivationCharge: "",
                freezeCode: "",
                freezeReasonCode: "",
                freezeRemarks: "",
                lienAmount: "",
                lienRemarks: "",
                loanAmount: "",
                typeOfLoan: "",
                fdrOrDpsCloseStatus: "",
                fdrOrDpsRelationship: "",
            },
            showValue: true,
            getDeferalList: [],
            defferalData: false,
            getData: false,
            images: [],
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
        }
    }


    componentDidMount() {

        console.log(this.state.inputData);
        console.log(this.props.data)

    }

    renderImage = () => {
        return <img style={{width: '100%', height: 'auto'}} src={signature}/>
    };


    detailsInformationlabelWise = () => {
        if (this.props.labelName === "CTR") {
            return (
                <ThemeProvider theme={theme}>
                    <Grid item xs={12}>

                    </Grid>

                    <Grid item xs={3}>
                        <label><font size="2"><b> Account Number :</b></font></label>

                        {this.state.inputData.accountNumber}
                    </Grid>

                    <Grid item xs={3}>
                        <label><b><font size="2">Account Name :</font></b> </label>

                        {this.state.inputData.customerName}
                    </Grid>
                    <Grid item xs={6}>
                        <label><font size="2"><b> Free Text 12 (Fincore) :</b></font></label>

                        {this.state.inputData.freeText}
                    </Grid>


                </ThemeProvider>
            )
        } else if (this.props.labelName === "ADUP") {
            return (
                <ThemeProvider theme={theme}>
                    <Grid item xs={12}>

                    </Grid>

                    <Grid item xs={3}>
                        <label><font size="2"><b> Account Number :</b></font></label>

                        {this.state.inputData.accountNumber}
                    </Grid>

                    <Grid item xs={3}>
                        <label><b><font size="2">Account Name :</font></b> </label>

                        {this.state.inputData.customerName}
                    </Grid>
                    <Grid item xs={6}>
                        <label><font size="2"><b> A/C Status (CRM) :</b></font></label>

                        {this.state.inputData.accountStatus}
                    </Grid>


                </ThemeProvider>
            )
        } else if (this.props.labelName === "Dormancy") {
            return (
                <ThemeProvider theme={theme}>
                    <Grid item xs={12}>

                    </Grid>

                    <Grid item xs={3}>
                        <label><font size="2"><b> Account Number :</b></font></label>

                        {this.state.inputData.accountNumber}
                    </Grid>

                    <Grid item xs={3}>
                        <label><b><font size="2">Account Name :</font></b> </label>

                        {this.state.inputData.customerName}
                    </Grid>
                    <Grid item xs={3}>
                        <label><font size="2"><b> A/C Status:</b></font></label>

                        {this.state.inputData.accountStatus}
                    </Grid>
                    <Grid item xs={3}>
                        <label><font size="2"><b> Dormant Activation Charge:</b></font></label>

                        {this.state.inputData.dormantActivationCharge}
                    </Grid>


                </ThemeProvider>
            )
        } else if (this.props.labelName === "Freeze") {
            return (
                <ThemeProvider theme={theme}>
                    <Grid item xs={12}>

                    </Grid>

                    <Grid item xs={3}>
                        <label><font size="2"><b> Account Number :</b></font></label>

                        {this.state.inputData.accountNumber}
                    </Grid>

                    <Grid item xs={3}>
                        <label><b><font size="2">Account Name :</font></b> </label>

                        {this.state.inputData.customerName}
                    </Grid>
                    <Grid item xs={3}>
                        <label><font size="2"><b>Freeze Code:</b></font></label>

                        {this.state.inputData.freezeCode}
                    </Grid>
                    <Grid item xs={3}>
                        <label><font size="2"><b>Freeze Reason Code:</b></font></label>

                        {this.state.inputData.freezeReasonCode}
                    </Grid>
                    <Grid item xs={6}>
                        <label><font size="2"><b>Freeze Remarks:</b></font></label>

                        {this.state.inputData.freezeRemarks}
                    </Grid>


                </ThemeProvider>
            )
        } else if (this.props.labelName === "Lien") {
            return (
                <ThemeProvider theme={theme}>
                    <Grid item xs={12}>

                    </Grid>

                    <Grid item xs={3}>
                        <label><font size="2"><b> Account Number :</b></font></label>

                        {this.state.inputData.accountNumber}
                    </Grid>

                    <Grid item xs={3}>
                        <label><b><font size="2">Account Name :</font></b> </label>

                        {this.state.inputData.customerName}
                    </Grid>
                    <Grid item xs={3}>
                        <label><font size="2"><b>Lien Amount:</b></font></label>

                        {this.state.inputData.lienAmount}
                    </Grid>
                    <Grid item xs={3}>
                        <label><font size="2"><b>Lien Remarks:</b></font></label>

                        {this.state.inputData.lienRemarks}
                    </Grid>


                </ThemeProvider>
            )
        } else if (this.props.labelName === "Loan") {
            return (
                <ThemeProvider theme={theme}>
                    <Grid item xs={12}>

                    </Grid>

                    <Grid item xs={3}>
                        <label><font size="2"><b> Account Number :</b></font></label>

                        {this.state.inputData.accountNumber}
                    </Grid>

                    <Grid item xs={3}>
                        <label><b><font size="2">Account Name :</font></b> </label>

                        {this.state.inputData.customerName}
                    </Grid>
                    <Grid item xs={3}>
                        <label><font size="2"><b>Amount:</b></font></label>

                        {this.state.inputData.loanAmount}
                    </Grid>
                    <Grid item xs={3}>
                        <label><font size="2"><b>Type of Loan:</b></font></label>

                        {this.state.inputData.typeOfLoan}
                    </Grid>

                    <Grid item xs={3}>
                        <label><font size="2"><b>Close Status:</b></font></label>

                        {this.state.inputData.closeStatus}
                    </Grid>
                </ThemeProvider>
            )
        } else if (this.props.labelName === "FDR" || this.props.labelName === "DPS") {
            return (
                <ThemeProvider theme={theme}>
                    <Grid item xs={12}>

                    </Grid>

                    <Grid item xs={3}>
                        <label><font size="2"><b> Account Number :</b></font></label>

                        {this.state.inputData.accountNumber}
                    </Grid>

                    <Grid item xs={3}>
                        <label><b><font size="2">Account Name :</font></b> </label>

                        {this.state.inputData.customerName}
                    </Grid>
                    <Grid item xs={3}>
                        <label><font size="2"><b>Amount:</b></font></label>

                        {this.state.inputData.loanAmount}
                    </Grid>
                    <Grid item xs={3}>
                        <label><font size="2"><b>Close Status:</b></font></label>

                        {this.state.inputData.fdrOrDpsCloseStatus}
                    </Grid>
                    <Grid item xs={3}>
                        <label><font size="2"><b>Relationship:</b></font></label>

                        {this.state.inputData.fdrOrDpsRelationship}
                    </Grid>


                </ThemeProvider>
            )
        } else if (this.props.labelName === "linkedCB") {

            let labelData;
            if (Array.isArray(this.state.labelData[1].value) === false) {
                let data = this.state.labelData[1].value.replace(/[\[\]']+/g, '');
                console.log("...........", data);
                labelData = data.split(",");
                console.log("...........", labelData);
            } else {
                labelData = this.state.labelData[1].value
            }

            return (
                <ThemeProvider theme={theme}>
                    {labelData.map((item) => {
                        console.log(item);
                        return (
                            <>
                                <Grid item xs={12}>

                                </Grid>
                                <Grid item xs={4}>
                                    <a onClick={() => {
                                        this.detailsView(item)
                                    }}>
                                        <label style={{cursor: "pointer"}}><font size="2"><b> CB Number
                                            :</b></font></label>
                                        {item.trim()}
                                    </a>
                                </Grid>

                                <Grid item xs={4}>
                                    <label><b><font size="2">Customer Name :</font></b> </label>
                                    {/*{item.customerName}*/}
                                </Grid>
                                <Grid item xs={4}>
                                    <label><font size="2"><b>A/c Status:</b></font></label>
                                    {/*{item.accountStatus}*/}
                                </Grid>
                            </>
                        )
                    })}
                </ThemeProvider>
            )
        } else {
            return "";
        }
    };

    detailsView = (cbNumber) => {
        let url = "/LinkedAccountMaintenance/" + cbNumber + "/" + this.state.labelData[3].value;
        window.open(frontEndServerURL + url)
    };


    render() {
        return (

            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Details Information<a><CloseIcon onClick={this.props.closeModal} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></h4>
                        </CardHeader>
                        <CardBody>
                            <div style={{
                                "border-top-style": "ridge",
                                "border-bottom-style": "ridge",
                            }}>
                                <Grid container spacing={1}>
                                    {
                                        this.detailsInformationlabelWise()
                                    }
                                </Grid>
                            </div>

                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

        );
    }


}

export default LabelDetails;
