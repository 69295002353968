import React from 'react';
import PropTypes from 'prop-types';
import {ThemeProvider, withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import GridItem from "../../Grid/GridItem";
import CardHeader from "../../Card/CardHeader";
import CardBody from "../../Card/CardBody";
import GridContainer from "../../Grid/GridContainer";
import Functions from '../../../Common/Functions';
import Notification from "../../NotificationMessage/Notification";
import theme from "../../JsonForm/CustomeTheme";
import Button from "@material-ui/core/Button";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Grid from "@material-ui/core/Grid";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";

const filteringJsonForm = [
    {
        "varName": "merchantName",
        "type": "text",
        "label": "Merchant Name",
        "grid": 2,

    },
    {
        "varName": "merchantType",
        "type": "select",
        "enum": [
            "Sole Proprietorship",
            "Limited Liability",
            "Partnership",
            "Others"
        ],
        "grid": 2,
        "label": "Merchant Type",
    },


];

let counter = 0;

function createData(index, proprietor_name, merchant_name, merchant_type, category, sub_category, appUid, service_type, subservice_type, date, branch_id) {

    // counter += 1;
    return {
        id: index + 1,
        proprietor_name,
        merchant_name,
        merchant_type,
        category,
        sub_category,
        appUid,
        service_type,
        subservice_type,
        date,
        branch_id
    };
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
    /*    {id: 'checkbox', numeric: false, disablePadding: true, label: ''},*/
    {id: 'id', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'proprietor_name', numeric: false, disablePadding: false, label: 'Proprietor Name'},
    {id: 'merchant_name', numeric: false, disablePadding: false, label: 'Merchant Name'},
    {id: 'merchant_type', numeric: false, disablePadding: false, label: 'Merchant Type'},
    {id: 'category', numeric: false, disablePadding: false, label: 'Category'},
    {id: 'sub_category', numeric: false, disablePadding: false, label: 'Sub Category'},
    {id: 'Date', numeric: false, disablePadding: false, label: 'Date of Activity'},
    // { id: 'sol_id', numeric: true, disablePadding: false, label: 'SOL Id' },
    {id: 'View', numeric: false, disablePadding: false, label: 'Action'}
];

class TableContentHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {order, orderBy} = this.props;

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">

                    </TableCell>
                    {rows.map(
                        row => (
                            <TableCell
                                key={row.id}
                                align={row.numeric ? 'right' : 'left'}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        ),
                        this,
                    )}
                </TableRow>
            </TableHead>
        );
    }
}

TableContentHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
});

class MerchantClaimCase extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'id',
        selected: [],
        data: [],
        getClaimCaseCaseData: [],
        page: 0,
        rowsPerPage: 25,
        renderModal: false,
        appUid: '',
        ClaimCaseModal: false,
        serviceType: '',
        subserviceType: '',
        redirectLogin: false,
        title: "",
        notificationMessage: "",

        alert: false,
        getData: false,
        err: false,
        errorArray: {},
        errorMessages: {},
        inputData: {},
    };


    componentDidMount() {


        const data = [];


        if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") == "MERCHANT") {
            console.log("success");
            var url = backEndServerURL + '/workspaceClaimable';

            // console.log()
        } else {
            var url = backEndServerURL + '/claimable';
        }

        axios.get(url, {
            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
        })
            .then(response => {
                console.log(response.data);


                response.data.map((message, index) => {
                    let date = message.delInitTime.split("T")[0];
                    data.push(createData(index, message.proprietorName, message.merchantName, message.merchantType, message.category, message.subCategory, message.appId, message.serviceType, message.subServiceType, date, message.solId));

                });

                this.setState({
                    data: data,
                    getClaimCaseCaseData: data,
                    getData: true

                });


            })

            .catch(error => {
                console.log(error);

            });


    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({order, orderBy});
    };

    handleSelectAllClick = event => {
        if (event.target.checked) {
            this.setState(state => ({selected: state.data.map(n => n.id)}));
            return;
        }
        this.setState({selected: []});
    };

    handleClick = (event, id) => {
        console.log(id);
        const {selected} = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({selected: newSelected});
        console.log(this.state.selected)
    };

    handleChangePage = (event, page) => {
        this.setState({page});
    };

    handleChangeRowsPerPage = event => {
        this.setState({rowsPerPage: event.target.value});
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;
    viewCase = (appUid, serviceType, subserviceType) => {
        let url = backEndServerURL + "/case/claim/" + appUid;
        axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Claimed!",
                    alert: true
                });
                const data = [];


                if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") == "MERCHANT") {
                    console.log("success");
                    var url = backEndServerURL + '/workspaceClaimable';
                } else {
                    var url = backEndServerURL + '/claimable';
                }


                axios.get(url, {
                    headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                })
                    .then(response => {
                        console.log(response.data);


                        response.data.map((message, index) => {
                            data.push(createData(index, message.proprietorName, message.merchantName, message.merchantType, message.category, message.subCategory, message.appId, message.serviceType, message.subServiceType, message.solId));

                        });

                        this.setState({
                            data: data,
                            getClaimCaseCaseData: data,

                        });


                    })

                    .catch(error => {
                        console.log(error);

                    });
            })
            .catch((error) => {
                console.log(error);
            })


    };
    closeModal = () => {
        this.setState({
            ClaimCaseModal: false,


        })
    };

    renderClaimCaseCase = () => {


    };
    updateComponent = () => {
        this.forceUpdate()
    };
    renderFilterForm = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, filteringJsonForm, this.updateComponent)
            )
        }
    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    getFilterSubmited = (event) => {
        event.preventDefault();
        counter = 0;
        let objectTable = {};
        let tableArray = [];
        console.log(this.state.inputData);
        for (let variable in this.state.inputData) {
            let trimData = this.state.inputData[variable].trim();
            if (trimData !== '')
                objectTable[variable] = trimData;
        }
        console.log(objectTable);
        this.state.getInboxCaseData.map((inboxCase) => {
            let showable = true;
            for (let variable in objectTable) {
                if (objectTable[variable] !== inboxCase[variable])
                    showable = false;
            }

            if (showable)

                tableArray.push(createData(inboxCase.appUid, inboxCase.proprietorName, inboxCase.merchantName, inboxCase.merchantType, inboxCase.category, inboxCase.subCategory, inboxCase.appUid, inboxCase.service_type, inboxCase.subservice_type, inboxCase.branch_id, inboxCase.urgency));
        });
        this.setState({
            data: tableArray
        })
    };

    render() {
        const {classes} = this.props;
        const {data, order, orderBy, selected, rowsPerPage, page} = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
        {

            Functions.redirectToLogin(this.state)

        }
        return (
            <section>

                <Paper className={classes.root}>
                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>

                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>Search</h4>

                            </CardHeader>

                            <CardBody>

                                <Grid container spacing={3}>
                                    <ThemeProvider theme={theme}>

                                        {this.renderFilterForm()}

                                    </ThemeProvider>


                                </Grid>

                                {/* <center> */}
                                <button
                                    className="btn btn-outline-primary"
                                    style={{
                                        // verticalAlign: 'middle',
                                    }}
                                    onClick={this.getFilterSubmited}

                                >
                                    Search
                                </button>
                                {/* </center> */}
                                {this.renderNotification()}


                            </CardBody>

                            <br/>
                        </GridItem>
                    </GridContainer>
                </Paper>
                <br/>
                <br/>
                <Paper className={classes.root}>
                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>

                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>New Request</h4>

                            </CardHeader>
                            <br/>

                            <div className={classes.tableWrapper}>
                                <Table className={classes.table} aria-labelledby="tableTitle">
                                    <TableContentHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={this.handleSelectAllClick}
                                        onRequestSort={this.handleRequestSort}
                                        rowCount={data.length}
                                    />
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="xl"
                                        open={this.state.ClaimCaseModal}
                                        style={{
                                            maxWidth: `${80}%`,
                                            maxHeight: `${100}%`,
                                            margin: 'auto'

                                        }}
                                    >
                                        <DialogContent>

                                            {this.renderClaimCaseCase()}
                                        </DialogContent>
                                    </Dialog>
                                    <TableBody>
                                        {stableSort(data, getSorting(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map(n => {
                                                const isSelected = this.isSelected(n.id);
                                                return (
                                                    <TableRow
                                                        hover
                                                        onClick={event => this.handleClick(event, n.id)}
                                                        role="checkbox"
                                                        aria-checked={isSelected}
                                                        tabIndex={-1}
                                                        key={n.id}
                                                        selected={isSelected}
                                                    >
                                                        {/*  <TableCell padding="none">

                                                        </TableCell>*/}
                                                        {/* <TableCell padding="none">
                                                            <Checkbox
                                                                //onClick={event => this.handleClick(event, n.id)}
                                                                role="checkbox"
                                                                aria-checked={isSelected}
                                                                tabIndex={-1}
                                                                key={n.id}
                                                                selected={isSelected}
                                                                inputProps={{
                                                                    'aria-label': 'primary checkbox',
                                                                }}
                                                            />
                                                        </TableCell>*/}
                                                        <TableCell padding="none">

                                                        </TableCell>
                                                        <TableCell padding="none">
                                                            {n.id}
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            {n.proprietor_name}
                                                        </TableCell>
                                                        <TableCell align="left">{n.merchant_name}</TableCell>
                                                        <TableCell align="left">{n.merchant_type}</TableCell>
                                                        <TableCell align="left">{n.category}</TableCell>
                                                        <TableCell align="left">{n.sub_category}</TableCell>
                                                        <TableCell align="left">{n.date}</TableCell>
                                                        {/* <TableCell align="right">{n.branch_id}</TableCell> */}
                                                        <TableCell align="left">

                                                            <ThemeProvider theme={theme}>
                                                                <Button
                                                                    onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type)}
                                                                    variant="contained" color="secondary"
                                                                    className={classes.margin}>
                                                                    Lock
                                                                </Button>
                                                            </ThemeProvider>

                                                        </TableCell>


                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{height: 49 * emptyRows}}>
                                                <TableCell colSpan={6}/>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </div>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />

                        </GridItem>
                    </GridContainer>
                </Paper>
            </section>
        );

    }
}

MerchantClaimCase.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MerchantClaimCase);
