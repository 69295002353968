import React, {Component} from "react";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../JsonForm/CustomeTheme2";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import Notification from "../NotificationMessage/Notification";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";

let Form = [

    {
        "varName": "username",
        "type": "text",
        "label": "User Name",
        "grid": 2,
        "required": true,
    },

];
let validation = [

    {
        "varName": "username",
        "type": "text",
        "label": "User Name",
        "grid": 2,
        "required": true,
    },


];
let searchResultJsonForm = [

    {
        "varName": "firstName",
        "type": "text",
        "label": "First Name",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "lastName",
        "type": "text",
        "label": "Last Name",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "username",
        "type": "text",
        "label": "User Name",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "grid": 2,
        "validation": "email",
        "required": true,
    },
    {
        "varName": "mobileNumber",
        "type": "text",
        "label": "Mobile Number",
        "grid": 2,
        "validation": "phone",
        "required": true,
    },
    /*{
        "varName": "role",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "SD Department",
        "grid": 2,
        "required": true,
    },*/

];
let searchResultDepartmentTeam = [
    {
        "varName": "departmentTeamName",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Department Team",
        "grid": 2,
        "required": true,
    },
];
let sdDepartmentList = [];
let roleRequestType = [];
let getAllBranchType = [];

class SdDepartmentAndTeamUpdate extends Component {
    state = {
        inputData: {},
        selectedDate: {},
        getData: false,
        workstation: false,
        role: false,
        showValue: false,
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},

        err: false,
        getuser: false,
        varValue: {},
        errorArray: {},
        errorMessages: {},
        title: "",
        notificationMessage: "",
        alert: false,
        otherRequestTitle: "",
        getBranch: false,
        getRole: false,
        getSearchResult: false,
        loading: false,
        getSdTeam: false
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };
    findByVarNameGet = (roleName, AllRole) => {
        let jsonArray = AllRole;

        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            console.log(jsonObject);
            if (roleName === jsonObject.value) {
                return jsonObject;
                i = jsonArray.length;

            }
        }


    };
    findByVarNameGetBranch = (branchName, AllBranch) => {
        let jsonArray = AllBranch;

        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            console.log(jsonObject);
            if (branchName === jsonObject.key) {
                return jsonObject;
                i = jsonArray.length;

            }
        }


    };

    userSearch = (event) => {
        if (event.key === 'Enter') {
            this.setState({
                getSearchResult: false,
                loading: true
            });
            let inputData = {};
            let varValue = {};
            let searchText = event.target.value;
            let url = backEndServerURL + '/user/get/' + searchText;
            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let data = response.data;
                    console.log(response);
                    console.log("kkk");
                    console.log(data);

                    inputData.email = data.email;
                    inputData.firstName = data.firstName;
                    inputData.lastName = data.lastName;
                    inputData.role = this.findByVarNameGet(data.role, roleRequestType);
                    inputData.solId = this.findByVarNameGetBranch(data.solId, getAllBranchType);
                    inputData.username = data.username;

                    varValue.email = data.email;
                    varValue.firstName = data.firstName;
                    varValue.lastName = data.lastName;
                    varValue.role = this.findByVarNameGet(data.role, roleRequestType);
                    varValue.solId = this.findByVarNameGetBranch(data.solId, getAllBranchType);
                    varValue.username = data.username;
                    this.setState({
                        inputData: inputData,
                        varValue: varValue,
                        getData: true,
                        showValue: true,
                        getSearchResult: true,
                        loading: false

                    })

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        getSearchResult: true,
                        loading: false
                    });

                    this.setState({errorMessage: "Not Found"});
                })
        }
    };
    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({
            loading: true
        });
        console.log(this.state.inputData);
        let inputData = this.state.inputData;


        let data = {};
        let urlt = backEndServerURL + "/user/update/SdDepartmentTeamName";
        data.userName = inputData.username;
        data.workplace = "SD";
        data.sdDepartmentName = inputData.sdDepartmentName.value;
        data.sdDepartmentTeamName = inputData.departmentTeamName.value;
        axios.post(urlt, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                if (response.data === true) {
                    this.setState({
                        title: "Successfull!",
                        notificationMessage: "Successfully Edited!",
                        alert: true,
                        loading: false
                    });
                    window.location.reload()

                } else {
                    this.setState({
                        title: "Error!",
                        notificationMessage: "Unsuccessfully Edited!",
                        alert: true,
                        loading: false
                    })
                }
                // window.location.reload()

            })
            .catch((error) => {
                this.setState({
                    title: "Error!",
                    notificationMessage: "Unsuccessfully Edited!",
                    alert: true,
                    loading: false
                });
                console.log(error);
            });

        let dependencyField = searchResultJsonForm;
        /*   let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state)
            this.forceUpdate();
           console.log(this.state.inputData);

           console.log("Not working");
           if (error === true) {
               return 0;
           }*/
        /*let inputData = this.state.inputData

        data.workplace = "BRANCH";
        data.email = inputData.email;
        data.firstName = inputData.firstName;
        data.lastName = inputData.lastName;
        data.roleId = inputData.role.key;
        data.solId = inputData.solId.key;
        data.branch = inputData.solId.key;
        data.username = inputData.username;
        console.log(data)
        let url = backEndServerURL + "/user/update360";
        axios.post(url, data, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Edited!",
                    alert: true,
                    loading: false
                })
                window.location.reload()
            })
            .catch((error) => {
                this.setState({
                    loading: false
                })
                console.log(error)
            })*/
    };
    renderButton = () => {
        if (this.state.getSearchResult) {
            return (

                <button
                    className="btn btn-outline-primary  btn-sm"
                    style={{
                        marginTop: "18px"
                    }}
                    onClick={this.handleSubmit}

                >
                    Update
                </button>


            )
        }


    };
    renderSdDepartmentList = () => {
        if (this.state.getData && this.state.getSearchResult) {
            return (
                <React.Fragment>


                    <Grid item xs={2}>

                        <Grid item>
                            <label className="input-label-common">SD Department</label>
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                id="tags-standard"
                                options={sdDepartmentList}
                                required={true}
                                onChange={(event, option) => this.handleChangeTeam(event, option)}
                                getOptionLabel={option => option.value}
                                size="small"
                                renderInput={params => (

                                    <TextField

                                        {...params}
                                        variant="outlined" fullWidth
                                    />

                                )}
                            />

                        </Grid>

                    </Grid>

                </React.Fragment>

            )
        }


    };
    findByVarNameApiData = (searchVarname, getValue, jsonForm) => {
        let jsonArray = jsonForm;

        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                console.log(getValue.key);
                let data = [];
                jsonForm[i].enum = getValue;
                console.log(jsonForm[i]);
                this.setState({
                    loading: false,
                    getRole: true,
                    getBranch: true,
                })
            }
        }


    };
    handleChangeTeam = (event, option) => {
        console.log(option);
        if (option !== null) {
            this.setState({
                getSdTeam: false
            });
            let inputData = this.state.inputData;
            inputData.sdDepartmentName = option;
            let urlS1 = backEndServerURL + "/sdDepartmentTeam/getAll/" + option.value;
            axios.get(urlS1, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let getAllsdDepartmentTeam = [];
                    console.log(response.data);
                    response.data.map((data) => {
                        getAllsdDepartmentTeam.push({
                            value: data.teamName,
                            key: data.id

                        })
                    });

                    searchResultDepartmentTeam[0].enum = getAllsdDepartmentTeam;
                    this.setState({
                        getSdTeam: true,
                        inputData: inputData
                    })

                })
                .catch((error) => {
                    console.log(error)
                })
        }
    };

    componentDidMount() {

        let urlS = backEndServerURL + "/sdDepartmentMaster/getAll";
        axios.get(urlS, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log("KKKK");
                let departmentList = [];
                response.data.map((data) => {
                    departmentList.push({
                            key: data.id,
                            value: data.departmentName
                        }
                    )
                });
                sdDepartmentList = departmentList;
                console.log(response.data);
                console.log(departmentList);

            })
            .catch((error) => {
                console.log(error)
            });

        let url = backEndServerURL + "/branchMaster/getAll";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((data) => {
                    getAllBranchType.push({
                        value: data.branchName,
                        key: data.solId
                    })
                });
                {
                    this.findByVarNameApiData("soleId", getAllBranchType, searchResultJsonForm)
                }


            })
            .catch((error) => {
                console.log(error)
            });
        let roleListUrl = backEndServerURL + "/role/get";
        axios.get(roleListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((data) => {
                    roleRequestType.push({
                        value: data.authority,
                        key: data.id
                    })
                });
                {
                    this.findByVarNameApiData("role", roleRequestType, searchResultJsonForm)
                }


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    getRole: true
                })

            });

        this.setState({
            getData: true,
            showValue: true,
        })
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    returnJsonForm = () => {
        if (this.state.getData) {
            return (
                <React.Fragment>

                    <Grid item xs={2}>

                        <Grid item>
                            <label className="input-label-common">User Name</label>
                        </Grid>
                        <Grid item>

                            <TextField

                                variant="outlined"

                                onChange={this.userSearch}
                                onKeyDown={(event) => {
                                    this.userSearch(event)
                                }}
                                name="username"
                                InputProps={{
                                    readOnly: false
                                }}
                            />

                        </Grid>

                    </Grid>

                </React.Fragment>

            )
        }
    };
    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();


        }

    };
    returnSearchResultJsonForm = () => {
        if (this.state.getSearchResult) {

            console.log(this.state.inputData);
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, searchResultJsonForm, this.updateComponent, this.onKeyDownForDedup)

            )
        }
    };
    renderSearchResultDepartmentTeam = () => {
        if (this.state.getSearchResult && this.state.getSdTeam) {

            console.log(this.state.inputData);
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, searchResultDepartmentTeam, this.updateComponent, this.onKeyDownForDedup)

            )
        }
    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h6>Edit SD Department And Team<a><CloseIcon onClick={this.close} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h6>
            )
        } else {
            return (
                <h6>Edit SD Department And Team</h6>
            )
        }
    };

    render() {
        return (

            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderHeader()}

                </CardHeader>
                <CardBody>
                    <div>

                        <ThemeProvider theme={theme}>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                open={this.state.loading}>
                                <DialogContent>

                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>
                            <Grid container>
                                {this.renderNotification()}
                                {this.returnJsonForm()}

                                <Grid item xs={12}>
                                </Grid>
                                {this.returnSearchResultJsonForm()}
                                {this.renderSdDepartmentList()}
                                {this.renderSearchResultDepartmentTeam()}

                                <Grid item xs={12}>
                                </Grid>
                                <Grid item xs={1}>
                                    {this.renderButton()}
                                </Grid>

                            </Grid>

                        </ThemeProvider>


                    </div>


                </CardBody>
            </Card>


        )
    }

}

export default SdDepartmentAndTeamUpdate;