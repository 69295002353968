import React, {Component} from "react";
import theme from "../../JsonForm/CustomeTheme";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import Grid from "@material-ui/core/Grid/index";
import "../../../Static/css/RelationShipView.css";
import Table from "../../Table/Table";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import withStyles from "@material-ui/core/styles/withStyles";
import CardHeader from "../../Card/CardHeader";
import Notification from "../../NotificationMessage/Notification";
import CloseIcon from '@material-ui/icons/Close';


let deferalSearchForm = [

    {
        "varName": "cbNumber",
        "type": "text",
        "label": "CB Number",
        "required": false,
        "readOnly": true,
        "grid": 6,
    },

    {
        "varName": "nid",
        "type": "text",
        "label": "Nid",
        "required": false,
        "readOnly": true,
        "grid": 6,

    },

    {
        "varName": "passport",
        "type": "text",
        "label": "Passport",
        "required": false,
        "email": true,
        "readOnly": true,
        "grid": 6,


    },

    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name",
        "required": false,
        "readOnly": true,
        "grid": 6,
    },

    {
        "varName": "dob",
        "type": "text",
        "label": "Date Of Birth",
        "required": false,
        "readOnly": true,
        "grid": 6,

    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "required": false,
        "readOnly": true,
        "grid": 6,


    },
    {
        "varName": "phone",
        "type": "text",
        "label": "Phone No",
        "required": false,
        "readOnly": true,
        "grid": 6,

    },
    {
        "varName": "tin",
        "type": "text",
        "label": "e-tin",
        "required": false,
        "readOnly": true,
        "grid": 6,
    },
    {
        "varName": "registrationNo",
        "type": "text",
        "label": "Registration No",
        "required": false,
        "readOnly": true,
        "grid": 6,

    },
    {
        "varName": "nationality",
        "type": "text",
        "label": "Nationality",
        "required": false,
        "readOnly": true,
        "grid": 6,
    },

];


let deferalForm = [
    {
        "varName": "type",
        "value": "Deferal",
        "type": "text",
        "readOnly": true,
        "grid": 6,
    },

    {
        "varName": "dueDate",
        "type": "text",
        "readOnly": true,
        "grid": 6,
    },

];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "400",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },
        Tab: {
            flexDirection: "row-reverse"
        },
        input: {
            display: 'none',
        },
        button: {
            margin: theme.spacing(1),
        },

    }
};

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

class CSRejectedPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inputData: {},
            showValue: false,
            varValue: [],
            SearchTableData: [],
            cbNumber: '',
            tableData: [[" ", " "]],
            deferalData: [[" ", " "]],
            csDeferal: '',
            bmApproval: '',
            dob: '',
            phone: '',
            tin: '',
            nationality: '',
            registrationNo: '',
            email: '',
            customerName: '',
            passport: '',
            nid: '',
            bomApproval: '',
            title: "",
            notificationMessage: "",
            alert: false,
            appId: '',

        }
    }

    componentDidMount() {

        // if (this.props.appId !== undefined) {
        console.log(this.props.appId);
        let url = backEndServerURL + '/variables/' + this.props.appId;

        axios.get(url,
            {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                console.log(response.data);

                let dateString;

                let dob = response.data.dob;
                if (dob !== undefined) {
                    let da = dob.split(",");

                    if (da != "" && da.length > 0) {
                        let monthDateYear = da[0].split("/");
                        let month, date;

                        if (monthDateYear[0].length === 1)
                            month = "0" + monthDateYear[0];
                        else
                            month = monthDateYear[0];
                        if (monthDateYear[1].length === 1)
                            date = "0" + monthDateYear[1];
                        else date = monthDateYear[1];
                        dateString = monthDateYear[2] + "-" + month + "-" + date;
                    }
                }
                this.setState({
                    getData: true,
                    bmApproval: response.data.bm_approval,
                    bomApproval: response.data.bomApproval,
                    dob: dateString,
                    tin: response.data.tin,
                    phone: response.data.phone,
                    cbNumber: response.data.cbNumber,
                    nid: response.data.nid,
                    passport: response.data.passport,
                    customerName: response.data.customerName,
                    email: response.data.email,
                    registrationNo: response.data.registrationNo,
                    nationality: response.data.nationality,
                    nextUser: response.data.next_user,
                    showValue: true

                });
                let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        let tableArray = [];

                        response.data.map((upload) => {
                            tableArray.push(this.createTableData(upload.id, upload.type, upload.dueDate, upload.approvedBy, upload.approvalDate, upload.status));

                        });
                        this.setState({
                            tableData: tableArray,

                            showValue: true
                        });

                    })
                    .catch((error) => {
                        console.log(error);
                    });

                this.setState({
                    getData: true,
                    showValue: true,
                    varValue: response.data,
                    appData: response.data
                });

            })
            .catch((error) => {
                console.log(error);

            });
        // }
    }

    createTableData = (id, type, dueDate, approvedBy, approvalDate, status) => {

        return ([type, dueDate, approvedBy, approvalDate, status])

    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    handleSubmit = (event) => {
        event.preventDefault();
        let url = backEndServerURL + "/case/route/" + this.props.appId;

        console.log(url);

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Routed!",
                    alert: true
                });
                this.props.closeModal();


            })
            .catch((error) => {
                console.log(error);

            });

    };

    renderDeferal = () => {
        if (this.state.bmApproval === "REJECT" || this.state.bomApproval === "REJECT") {
            return (
                <div>

                    <Grid container spacing={3}>

                        <Grid item xs='6'>CB Number</Grid>
                        <Grid item xs='6'>
                            {this.state.cbNumber}
                        </Grid>
                        <Grid item xs='6'>NID</Grid>
                        <Grid item xs='6'>
                            {this.state.nid}
                        </Grid>
                        <Grid item xs='6'>Passport</Grid>
                        <Grid item xs='6'>
                            {this.state.passport}
                        </Grid>
                        <Grid item xs='6'>Customer Name</Grid>
                        <Grid item xs='6'>
                            {this.state.customerName}
                        </Grid>
                        <Grid item xs='6'>Date Of Birth</Grid>
                        <Grid item xs='6'>
                            {this.state.dob}
                        </Grid>
                        <Grid item xs='6'>Email</Grid>
                        <Grid item xs='6'>
                            {this.state.email}
                        </Grid>
                        <Grid item xs='6'>Phone Number</Grid>
                        <Grid item xs='6'>
                            {this.state.phone}
                        </Grid>
                        <Grid item xs='6'>eTin</Grid>
                        <Grid item xs='6'>
                            {this.state.tin}
                        </Grid>
                        <Grid item xs='6'>Birth Certificate/Driving License</Grid>
                        <Grid item xs='6'>
                            {this.state.registrationNo}
                        </Grid>
                        <Grid item xs='6'>Nationality</Grid>
                        <Grid item xs='6'>
                            {this.state.nationality}
                        </Grid>


                    </Grid>
                    <Table
                        tableHeaderColor="primary"
                        tableHead={["Deferal Type", "Expire Date", "Rejected By", "Rejection Date", "Status"]}
                        tableData={this.state.tableData}
                        tableAllign={['left', 'left', 'left', 'left', 'left']}
                    />
                </div>
            )
        } else {

        }
    };

    close = () => {
        this.props.closeModal();
    };

    render() {
        const {classes} = this.props;

        return (
            <section>
                <Card>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Rejected Case List<a><CloseIcon onClick={this.close} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></h4>

                        </CardHeader>
                    </paper>
                    <br/>
                    <CardBody>


                        {this.renderDeferal()}
                        <br/>
                        <br/>
                        <center>
                            <button
                                className="btn btn-outline-primary"
                                style={{
                                    verticalAlign: 'middle',
                                }}
                                onClick={this.handleSubmit}

                            >
                                Ok
                            </button>
                        </center>
                    </CardBody>
                </Card>

            </section>

        );
    }
}

export default withStyles(styles)(CSRejectedPage);