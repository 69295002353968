import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../Common/Constant";
import Functions from '../../Common/Functions';
import GridList from "@material-ui/core/GridList";
import Grid from "@material-ui/core/Grid";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};


class VerifyMakerPhoto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            appData: {},
            getData: false,
            varValue: [],
            showValue: false,
            redirectLogin: false,
            title: "",
            notificationMessage: "",
            alert: false,
            inputData: {},
            selectedDate: {},
            SelectedDropdownSearchData: null,
            dropdownSearchData: {},
            values: [],
            customerName: [],
            deferalType: [],
            expireDate: [],
            other: [],
            getCheckerList: [],
            getAllDefferal: [],
            getDeferalList: [],
            loading: false,
            jointAccountCustomerNumber: 0,
            objectForJoinAccount: [],
            getgenerateForm: false,
        };


    }


    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    /* undefinedCheck=(data)=>{
         if(data!==undefined){
             return (
                 <img width='100%' src={stringParam} alt=""/>
         );
         }
     }
     renderImage=()=>{
         let aof1, aof2, aof3, aof4, tp, iif1, iif2, kyc1, kyc2,am1,am2,
             bond1,bond2,bond3,bond4,bond5,sanchay1,sanchay2,sanchay3,sanchay4,sanchay5,doc1,doc2,doc3,doc4,doc5,doc6,doc7,doc8,doc9,doc10,doc11,doc12,doc13,doc14,doc15,doc16,doc17,doc18,doc19,doc20,lockerMaintenanceForm,nomineePhotoID,nomineeAssignForm,rtgs,beftn;

         this.props.documentList.map((document) => {
            if (document.indexOf("AOF1") > -1)
                 aof1 = document;
             else if (document.indexOf("AOF2") > -1)
                 aof2 = document;
             else if (document.indexOf("AOF3") > -1)
                 aof3 = document;
             else if (document.indexOf("AOF4") > -1)
                 aof4 = document;
             else if (document.indexOf("TP") > -1)
                 tp = document;
             else if (document.indexOf("KYC1") > -1)
                 kyc1 = document;
             else if (document.indexOf("KYC2") > -1)
                 kyc2 = document;
             else if (document.indexOf("IIF1") > -1)
                 iif1 = document;
             else if (document.indexOf("IIF2") > -1)
                 iif2 = document;
             else if (document.indexOf("AM01") > -1)
                 am1 = document;
             else if (document.indexOf("AM02") > -1)
                 am2 = document;
             else if (document.indexOf("APPLICANT PASSPORT") > -1)
                 bond1 = document;
             else if (document.indexOf("APPLICANT EMPLOYMENT AGREEMENTS") > -1)
                 bond2 = document;
             else if (document.indexOf("APPLICANT WORK PERMITS") > -1)
                 bond3 = document;
             else if (document.indexOf("APPLICANT ETN") > -1)
                 bond4 = document;
             else if (document.indexOf("OTHERS") > -1)
                 bond5 = document;
             else if (document.indexOf("APPLICANT BB FORM") > -1)
                 sanchay1 = document;
             else if (document.indexOf("APPLICANT DEBIT INSTRUCTION") > -1)
                 sanchay2 = document;
             else if (document.indexOf("APPLICANT NID") > -1)
                 sanchay3 = document;
             else if (document.indexOf("APPLICANT ETN") > -1)
                 sanchay4 = document;
             else if (document.indexOf("APPLICANT NOMINEE NID") > -1)
                 sanchay5 = document;
             else if (document.indexOf("Document 1.jpg") > -1)
                 doc1 = document;
             else if (document.indexOf("Document 2.jpg") > -1)
                 doc2 = document;
             else if (document.indexOf("Document 3.jpg") > -1)
                 doc3 = document;
             else if (document.indexOf("Document 4.jpg") > -1)
                 doc4 = document;
             else if (document.indexOf("Document 5.jpg") > -1)
                 doc5 = document;
             else if (document.indexOf("Document 6.jpg") > -1)
                 doc6 = document;
             else if (document.indexOf("Document 7.jpg") > -1)
                 doc7 = document;
             else if (document.indexOf("Document 8.jpg") > -1)
                 doc8 = document;
             else if (document.indexOf("Document 9.jpg") > -1)
                 doc9 = document;
             else if (document.indexOf("Document 10.jpg") > -1)
                 doc10 = document;
             else if (document.indexOf("Document 11.jpg") > -1)
                 doc11 = document;
             else if (document.indexOf("Document 12.jpg") > -1)
                 doc12 = document;
             else if (document.indexOf("Document 13.jpg") > -1)
                 doc13 = document;
             else if (document.indexOf("Document 14.jpg") > -1)
                 doc14 = document;
             else if (document.indexOf("Document 15.jpg") > -1)
                 doc15 = document;
             else if (document.indexOf("Document 16.jpg") > -1)
                 doc16 = document;
             else if (document.indexOf("Document 17.jpg") > -1)
                 doc17 = document;
             else if (document.indexOf("Document 18.jpg") > -1)
                 doc18 = document;
             else if (document.indexOf("Document 19.jpg") > -1)
                 doc19 = document;
             else if (document.indexOf("Document 20.jpg") > -1)
                 doc20 = document;
             else if (document.indexOf("Locker Maintenance Form.jpg") > -1)
                 lockerMaintenanceForm = document;
             else if (document.indexOf("Nominee Photo ID.jpg") > -1)
                 nomineePhotoID = document;
             else if (document.indexOf("Nominee Assign Form.jpg") > -1)
                 nomineeAssignForm = document;
             else if (document.indexOf("RTGS FORM.jpg") > -1)
                 rtgs = document;
             else if (document.indexOf("BEFTN FORM.jpg") > -1)
                 beftn = document;


             return (

                 <React.Fragment>
                     {this.undefinedCheck(document)}
                      {this.undefinedCheck(nomineePhotoID)}
                     {this.undefinedCheck(nomineeAssignForm)}
                     {this.undefinedCheck(aof1)}
                     {this.undefinedCheck(aof2)}
                     {this.undefinedCheck(aof3)}
                     {this.undefinedCheck(aof4)}
                     {this.undefinedCheck(tp)}
                     {this.undefinedCheck(kyc1)}
                     {this.undefinedCheck(kyc2)}
                     {this.undefinedCheck(iif1)}
                     {this.undefinedCheck(iif2)}
                     {this.undefinedCheck(am1)}
                     {this.undefinedCheck(am2)}
                     {this.undefinedCheck(bond1)}
                     {this.undefinedCheck(bond2)}
                     {this.undefinedCheck(aof1)}
                     {this.undefinedCheck(bond4)}
                     {this.undefinedCheck(bond5)}
                     {this.undefinedCheck(sanchay1)}
                     {this.undefinedCheck(sanchay2)}
                     {this.undefinedCheck(sanchay3)}
                     {this.undefinedCheck(sanchay4)}
                     {this.undefinedCheck(sanchay5)}
                     {this.undefinedCheck(doc1)}
                     {this.undefinedCheck(doc2)}
                     {this.undefinedCheck(doc3)}
                     {this.undefinedCheck(doc4)}
                     {this.undefinedCheck(doc5)}
                     {this.undefinedCheck(doc6)}
                     {this.undefinedCheck(doc7)}
                     {this.undefinedCheck(doc8)}
                     {this.undefinedCheck(doc9)}
                     {this.undefinedCheck(doc10)}
                     {this.undefinedCheck(doc11)}
                     {this.undefinedCheck(doc12)}
                     {this.undefinedCheck(doc13)}
                     {this.undefinedCheck(doc14)}
                     {this.undefinedCheck(doc15)}
                     {this.undefinedCheck(doc16)}
                     {this.undefinedCheck(doc17)}
                     {this.undefinedCheck(doc18)}
                     {this.undefinedCheck(doc19)}
                     {this.undefinedCheck(doc20)}
                     {this.undefinedCheck(rtgs)}
                     {this.undefinedCheck(beftn)}
                 </React.Fragment>

             )
         })


     }
 */

    renderDataShow = (data, index) => {
        let splitData = (data.split("_")[1]).split(".")[0];
        return (
            <div>
                <center>
                    <b>{splitData}</b>
                </center>


                <img width='90%' src={backEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")+"?"+Math.floor(Math.random() * 100000000000)}
                     alt=""/>
            </div>


        )


    };

    renderImage = () => {
        return (
            <Grid container spacing={1}>
                {
                    this.props.documentList.map((data, index) => {
                        //console.log(index)
                        return (

                            this.renderDataShow(data, index)
                        )

                    })

                }
            </Grid>
        )

    };


    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    {this.renderImage()}
                </div>
            </GridList>

        )

    }


}

export default withStyles(styles)(VerifyMakerPhoto);
