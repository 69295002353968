import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import Functions from '../../Common/Functions';
import theme from "../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import Notification from "../NotificationMessage/Notification";
import loading from "../../Static/loader.gif";
import CBNotFound from "./CASA/CBNotFound";
import TableComponent from "./CommonComponent/TableComponent";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import {Menu} from "semantic-ui-react";
import {commonInboxButton, commonInboxTabActive, commonInboxTabInactive} from "../DeliverableManagement/configuration";
import Badge from "@material-ui/core/Badge";
import {rowsWithBulkApproval} from "./ColumnNameForInbox"
import Button from "@material/react-button";
import FileMappingReadOnly from "./CommonComponent/FileMappingReadOnly";
import ConfirmAlert from "./CommonComponent/ConfirmAlert";
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";

const filterField = [
    {
            "varName": "caseId",
        "type": "textDedup",
        "label": "LWF Case Number",
        "grid": 2,
    },
    {
        "varName": "category",
        "type": "select",
        "enum": [
            "CASA",
            "FDR",
            "DPS",
            "Chequebook",
            "RTGS",
            "BEFTN",
            "Salary",
            "Debit Card Issuance",
            "BOND",
            "SANCHAYPATRA",
            "Debit Card",
            "Credit Card",
            "CARDS",
            "ADC",
            "Internet Banking",
            "Remittance",
            "Pay Order and FDD Bulk",
            "Locker",
            "Delivarable",
            "Deferral",
        ],
        "label": "Category",
        "grid": 2,
    },
    {
        "varName": "subCategory",
        "type": "select",
        "enum": [
            "A/C Opening",
            "Opening",
            "Schem Account opeing",
            "TD Open/FDR",
            "Maintenance",
            "Single Requisition",
            "Opening",
            "Service",
            "Single Transaction",
            "Bulk Transaction",
            "Requisition",
            "Chequebook",
            "Bulk Insta Debit Card",
            "Bulk Requisition",
            "CASA Closer",
            "FDR Closer",
            "FDR Maintenace",
            "CARD Against FDR/ DPS",
            "Tracker",
            "Instant Card",
            "General Card Requisition",
            "CARD Cheque Requisition",
            "City Touch Enrollment",
            "SMS Discountinue",
            "Outward",
            "Inward",
            "Student File",
            "Medical File",
            "Searcher",
            "Others",
            "Pay Order Bulk Requisition",
            "FDD Bulk Requisition",
            "Others",
            "Requistion",
            "Surrender",
            "Locker Maintenace",
            "Reconcilation Branch",
            "Insurance Upload",
            "Insta Pack Requisition",
            "Insta Card Requisition",
            "Insta Pack Tracker",
            "Insta Card Tracker",
            "WL Priority",
            "WL Non Priority",
            "CASA Defferal",
            "FD Deferal",
            "Others",
            "FDR",
            "Encashment",
            "ISSUANCE - Wage Earners Development Bond",
            "ISSUANCE - U.S. Dollar Investment Bond",
            "ISSUANCE - U.S. Dollar Premium Bond",
            "ISSUANCE - 5 Years Bangladesh Sanchaya Patra",
            "ISSUANCE - 3 Months Interest Based Sanchaya Patra",
            "ISSUANCE - Family Sanchaya Patra",
            "ISSUANCE - Pensioners Savings Certificate",
            "ENCASHMENT - Wage Earners Development Bond - ENCASHMENT PAYMENT",
            "ENCASHMENT - Wage Earners Development Bond - INTEREST PAYMENT",
            "ENCASHMENT - U.S. Dollar Investment Bond - ENCASHMENT PAYMENT",
            "ENCASHMENT - U.S. Dollar Investment Bond - INTEREST PAYMENT",
            "ENCASHMENT - U.S. Dollar Premium Bond - ENCASHMENT PAYMENT",
            "ENCASHMENT - U.S. Dollar Premium Bond - INTEREST PAYMENT",
            "ENCASHMENT - 5 Years Bangladesh Sanchaya Patra - ENCASHMENT PAYMENT",
            "ENCASHMENT - 5 Years Bangladesh Sanchaya Patra - INTEREST PAYMENT",
            "ENCASHMENT - 3 Months Interest Based Sanchaya Patra - ENCASHMENT PAYMENT",
            "ENCASHMENT - 3 Months Interest Based Sanchaya Patra - INTEREST PAYMENT",
            "ENCASHMENT - Family Sanchaya Patra - ENCASHMENT PAYMENT",
            "ENCASHMENT - Family Sanchaya Patra - INTEREST PAYMENT",
            "ENCASHMENT - Pensioners Savings Certificate - ENCASHMENT PAYMENT",
            "ENCASHMENT - Pensioners Savings Certificate - INTEREST PAYMENT",
            "MAINTENANCE - Wage Earners Development Bond - NOMINEE CHANG",
            "MAINTENANCE - Wage Earners Development Bond - ACES ENROLLMENT",
            "MAINTENANCE - Wage Earners Development Bond - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - Wage Earners Development Bond - DUPLICATE ISSUANCE",
            "MAINTENANCE - Wage Earners Development Bond - CERTIFICATE",
            "MAINTENANCE - U.S. Dollar Investment Bond - NOMINEE CHANG",
            "MAINTENANCE - U.S. Dollar Investment Bond - ACES ENROLLMENT",
            "MAINTENANCE - U.S. Dollar Investment Bond - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - U.S. Dollar Investment Bond - DUPLICATE ISSUANCE",
            "MAINTENANCE - U.S. Dollar Investment Bond - CERTIFICATE",
            "MAINTENANCE - U.S. Dollar Premium Bond - NOMINEE CHANG",
            "MAINTENANCE - U.S. Dollar Premium Bond - ACES ENROLLMENT",
            "MAINTENANCE - U.S. Dollar Premium Bond - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - U.S. Dollar Premium Bond - DUPLICATE ISSUANCE",
            "MAINTENANCE - U.S. Dollar Premium Bond - CERTIFICATE",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - NOMINEE CHANG",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - ACES ENROLLMENT",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - DUPLICATE ISSUANCE",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - CERTIFICATE",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - NOMINEE CHANG",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - ACES ENROLLMENT",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - DUPLICATE ISSUANCE",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - CERTIFICATE",
            "MAINTENANCE - Family Sanchaya Patra - NOMINEE CHANG",
            "MAINTENANCE - Family Sanchaya Patra - ACES ENROLLMENT",
            "MAINTENANCE - Family Sanchaya Patra - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - Family Sanchaya Patra - DUPLICATE ISSUANCE",
            "MAINTENANCE - Family Sanchaya Patra - CERTIFICATE",
            "MAINTENANCE - Pensioners Savings Certificate - NOMINEE CHANG",
            "MAINTENANCE - Pensioners Savings Certificate - ACES ENROLLMENT",
            "MAINTENANCE - Pensioners Savings Certificate - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - Pensioners Savings Certificate - DUPLICATE ISSUANCE",
            "MAINTENANCE - Pensioners Savings Certificate - CERTIFICATE",
        ],
        "label": "Sub Category",
        "grid": 2,
    },
    {
        "varName": "branch_id",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Branch",
        "grid": 2,
    },
    {
        "varName": "cbNumber",
        "type": "textDedup",
        "label": "CB Number",
        "grid": 2,
    },
    {
        "varName": "accountNumber",
        "type": "textDedup",
        "label": "Account No.",
        "grid": 2,
    },
    {
        "varName": "customerName",
        "type": "textDedup",
        "label": "Customer name",
        "grid": 2,
    },
    {
        "varName": "fromDate",
        "type": "date",
        "label": "From Date",
        "grid": 2,
    },
    {
        "varName": "toDate",
        "type": "date",
        "label": "To Date",
        "grid": 2,
    },
    {
        "varName": "urgency",
        "type": "select",
        "enum": [
            "High",
            "Medium",
            "Low",
        ],
        "label": "Urgency",
        "grid": 2,
    },
    {
        "varName": "cutOffTime",
        "type": "select",
        "enum": [
            "Before",
            "After",
        ],
        "label": "Cut Off Time",
        "grid": 2,
    },
];


const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

class BMBulkAccountOpening extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            loading: true,
            cbNotFound: false,
            getData: false,
            varValue: {},
            title: "",
            notificationMessage: "",
            alert: false,
            selectedDate: {},
            showValue: false,
            openHierarchy: false,
            getMappingAllImage: false,
            err: false,
            searchData: false,
            activeItem: "Group Inbox",
            showTable: false,
            errorArray: {},
            errorMessages: {},
            tableData: [],
            selected: [],
            totalRow: 0,
            rowsPerPage: 100,
            tableColumns: [],
            page: 0,
            getCountPending: 0,
            getCountReturn: 0,
            getCountInbox: 0,
            getCountRectified: 0,
            getCountGroupInbox: 0,
            showInbox: false,
            serviceType: "",
            subServiceType: "",
            category: "",
            subCategory: "",
            solId: "",
            appUid: "",
            delStatus: "",
            taskTitle: "",
            status: "",
            freeFlag1: "",
            freeFlag2: "",
            freeFlag3: "",
            freeFlag4: "",
            recpmtid: "",
            cb_number: "",
            account_number: "",
            customer_name: "",
            urgency: "",
            sentByUsername: "",
            jointAccountCustomerNumber: "",
            actionAppId: "",
            actionType: "",
            confirmAlert: false,
        }
    }


    renderTableColumn = () => {
        let tableColumn = [];
        tableColumn = [...rowsWithBulkApproval];
        return tableColumn;
    };


    getApiUrl = () => {
        let url = backEndServerURL + '/bmBulkAccountOpening/0';


        return url;
    };

    functionForCategoryCount = () => {
        let countUrl = backEndServerURL + "/inbox/getCountCategoryWise";
        axios.get(countUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let getData = response.data;
                this.setState({
                    getCountInbox: getData.inbox,
                    getCountReturn: getData.return,
                    getCountRectified: getData.rectified,
                    getCountSubmit: getData.inbox,
                    getCountPending: getData.draft,
                    getCountGroupInbox: getData.teamGroup,
                    getCountCertificateInbox: getData.inbox,
                    getCountCompleteInbox: getData.inbox,
                });
                LocalstorageEncrypt.encryptStorageFunction.setItem("inboxNumberOfCount", Number(getData.inbox) + Number(getData.return) + Number(getData.draft));
            })
            .catch((error) => {
                console.log(error);
            });
    };


    componentDidMount() {
        let branch=[];
        let branchUrl = backEndServerURL + "/workplaceUnit/getInitialUnits";
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((item) => {
                    branch.push({key: item.solId, value: item.name})
                });
                for (let form of filterField) {
                    if (form.type === "autoCompleteValueReturn" && form.varName === "branch_id") {
                        form.enum = branch
                    }
                }
                /*  // this.functionForCategoryCount();*/
                this.fetchTableData(this.getApiUrl())
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    getData: true,
                    showTable: true,
                    loading: false,
                });
            });
    }


    fetchTableData = (url) => {
       // this.functionForCategoryCount();
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(response => {
            let tableData = [];
            response.data.content.map((message, index) => {
                tableData.push({
                    SL: index + 1,
                    CaseId: message.caseId,
                    customer_name: message.customerName,
                    cb_number: message.cbNumber,
                    account_number: message.accountNumber,
                    businessSegment: message.businessSegment,
                    category_type: this.returnCategory(message.branchingCategory, message.freeFlag5, message.category),
                    sub_category_type: this.returnSubCategory(message.branchingSubCategory, message.freeFlag6, message.subCategory),
                    Service: message.priorityDesc,
                    Date: message.delInitTime.replace("T", " "),
                    currentUserName: message.currentUserName,
                    branch_name: message.branchName,
                    Status: message.delStatus,
                    freeFlag1: message.freeFlag1,
                    freeFlag2: message.freeFlag2,
                    freeFlag3: message.freeFlag3,
                    freeFlag4: message.freeFlag4,
                    recpmtid: message.recpmtid,
                    freeFlag5: message.freeFlag5,
                    freeFlag6: message.freeFlag6,
                    appId: message.appId,
                    serviceType: message.serviceType,
                    subServiceType: message.subServiceType,
                    taskTitle: message.taskTitle,
                    solId: message.solId,
                    urgency: message.urgency,
                    returnedBack: message.returnBackTo ? message.returnBackTo : "",
                    returnedBy: message.sentByUsername,
                    jointAccountCustomerNumber: message.jointAccountCustomerNumber,
                    requestStatus: message.status,
                    // makerId: message.lastMakerUser,
                    previewImage: this.renderPreviewImage({message})
                    // reject: this.renderReject({message}),
                });
            });
            this.setState({
                page: response.data.number,
                totalRow: response.data.totalElements,
                tableData: tableData,
                getData: true,
                showTable: true,
                loading: false
            })

        }).catch(error => {
            console.log(error);
            this.setState({
                loading: false,
                showTable: true,
                searchData: false,
            })
        });
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderCloseIcon = () => {
        return (
            <h4 style={{color: "white"}} >Filter</h4>
        )
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    handleChangePage = (pageNumber) => {
        this.setState({
            loading: true,
            getData: false,
            showTable: false
        });
        if (this.state.searchData) {
            this.searchHandler("", pageNumber)
        } else {
            this.functionForGetTabWiseUrl(this.state.activeItem, pageNumber);
        }
    };


    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'null' || clone[prop] === null || clone[prop] === 'undefined')
                delete clone[prop];
        return clone;
    };


    returnCategory = (branchingCategory, freeFlag5, category) => {
        if (branchingCategory !== undefined && branchingCategory !== null && branchingCategory !== "") {
            return branchingCategory
        } else if (freeFlag5 !== undefined && freeFlag5 !== null && freeFlag5 !== "") {
            return freeFlag5
        } else {
            return category
        }
    };

    returnSubCategory = (branchingSubCategory, freeFlag6, subCategory) => {
        if (branchingSubCategory !== undefined && branchingSubCategory !== null && branchingSubCategory !== "") {
            return branchingSubCategory
        } else if (freeFlag6 !== undefined && freeFlag6 !== null && freeFlag6 !== "") {
            return freeFlag6
        } else {
            return subCategory
        }
    };

    searchHandler = (event, pageNumber) => {
        this.setState({
            page: 0,
            totalRow: 0,
            tableData: [],
            loading: true,
            showTable: false,
        });

        let pageN = pageNumber ? pageNumber : 0;
        let postData = this.removeNullValue(this.state.inputData);
        postData.tabName = "BulkAccountOpening";
        let status = "BulkAccountOpening";
        let url = backEndServerURL + "/filterUserInbox/" + status + "/" + pageN;
        axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(response => {
            let tableData = [];
            response.data.content.map((message, index) => {
                tableData.push({
                    SL: index + 1,
                    CaseId: message.caseId,
                    customer_name: message.customerName,
                    cb_number: message.cbNumber,
                    account_number: message.accountNumber,
                    businessSegment: message.businessSegment,
                    category_type: this.returnCategory(message.branchingCategory, message.freeFlag5, message.category),
                    sub_category_type: this.returnSubCategory(message.branchingSubCategory, message.freeFlag6, message.subCategory),
                    Service: message.priorityDesc,
                    Date: message.delInitTime.replace("T", " "),
                    currentUserName: message.currentUserName,
                    branch_name: message.branchName,
                    Status: message.delStatus,
                    freeFlag1: message.freeFlag1,
                    freeFlag2: message.freeFlag2,
                    freeFlag3: message.freeFlag3,
                    freeFlag4: message.freeFlag4,
                    recpmtid: message.recpmtid,
                    freeFlag5: message.freeFlag5,
                    freeFlag6: message.freeFlag6,
                    appId: message.appId,
                    serviceType: message.serviceType,
                    subServiceType: message.subServiceType,
                    taskTitle: message.taskTitle,
                    solId: message.solId,
                    urgency: message.urgency,
                    returnedBack: message.returnBackTo ? message.returnBackTo : "",
                    returnedBy: message.sentByUsername,
                    jointAccountCustomerNumber: message.jointAccountCustomerNumber,
                    requestStatus: message.status,
                    // makerId: message.lastMakerUser,
                    previewImage: this.renderPreviewImage({message}),
                    reject: this.renderReject({message}),
                });
            });
            this.setState({
                page: response.data.number,
                totalRow: response.data.totalElements,
                tableData: tableData,
                getData: true,
                showTable: true,
                searchData: true,
                loading: false
            })

        }).catch(error => {
            console.log(error);
            this.setState({
                loading: false,
                showTable: true,
                searchData: false,
            })
        });
    };

    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.searchHandler(event, 0);
        }
    };


    renderFilterField = () => {
        return (
            <Grid container>
                {CommonJsonFormComponent.renderJsonForm(this.state, filterField, this.updateComponent, this.onKeyDownForDedup)}
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: '15px',
                    }}
                    onClick={(e) => this.searchHandler(e, 0)}
                    type="submit"
                >
                    Search
                </button>
                &nbsp;
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: '15px',
                    }}
                    onClick={() => window.location.reload()}
                    type="submit"
                >
                    Reset
                </button>
            </Grid>
        );
    };


    headerSelect = (event, value) => {
        event.preventDefault();
        this.setState({
            searchData: false,
            activeItem: value,
            loading: true,
            getData: false,
            showTable: false,
            page: 0,
            tableData: [],
        }, this.functionForGetTabWiseUrl(value, 0));
    };

    functionForGetTabWiseUrl = (value, page) => {
        this.forceUpdate();
        let urls = backEndServerURL + '/bmBulkAccountOpening/' + page;
        this.fetchTableData(urls)
    };

    renderMenu = () => {
        return (
            <div style={{marginRight: "8px"}}>
                <Menu pointing>
                    {this.renderMenuItem("GROUP LOCK", "Group Inbox", this.state.getCountGroupInbox, "Group Inbox")}
                    {this.renderMenuItem("RECTIFIED REQUEST", "RECTIFIED REQUEST", this.state.getCountRectified, "Corrected Requests")}
                    {this.renderMenuItem("INBOX", "Inbox", this.state.getCountInbox, "Self-Inbox")}
                    {this.renderMenuItem("SD PENDING CASE", "SD PENDING CASE", "", "SD Pending Case")}
                    {this.renderMenuItem("RETURN", "Return", this.state.getCountReturn, "Returned Requests")}
                    {this.renderMenuItem("PENDING", "Pending", this.state.getCountPending, "Pending Requests")}
                    {this.renderMenuItem("SUBMIT", "Submit", undefined, "Submit")}
                    {this.renderMenuItem("COMPLETE", "Complete", undefined, "Complete")}
                    {this.renderMenuItem("CERTIFICATE", "Certificate", undefined, "Certificate")}
                </Menu>
            </div>
        );
    };

    renderMenuItem = (permission, activeItem, badge, name) => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("menus").indexOf(permission) !== -1) {
            return (
                <Menu.Item
                    name={activeItem}
                    active={this.state.activeItem === activeItem}
                    style={this.state.activeItem === activeItem ? commonInboxTabActive : commonInboxTabInactive}
                    onClick={e => {
                        this.headerSelect(e, activeItem);
                    }}
                >
                    {this.renderBadge(badge, name)}
                </Menu.Item>
            )
        }
    };

    renderBadge = (value, name) => {
        if (value !== undefined && value > 0) {
            return (
                <Badge  color="primary">
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        } else {
            return (
                <Badge color="primary">
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        }
    };

    renderInboxCaseSubmitted = () => {

    };
    renderInboxCase = () => {

    };


    onRowClick = (event, rowData) => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        this.setInboxProps({...rowData, appId: rowData.appId})

    };

    setInboxProps = (rowData) => {
        console.log(rowData);
        this.setState({
            serviceType: rowData.serviceType,
            subServiceType: rowData.subServiceType,
            category: rowData.category_type,
            subCategory: rowData.sub_category_type,
            solId: rowData.solId,
            appUid: rowData.appId,
            delStatus: rowData.Status,
            taskTitle: rowData.taskTitle,
            status: rowData.requestStatus,
            freeFlag1: rowData.freeFlag1,
            freeFlag2: rowData.freeFlag2,
            freeFlag3: rowData.freeFlag3,
            freeFlag4: rowData.freeFlag4,
            recpmtid: rowData.recpmtid,
            cb_number: rowData.cb_number,
            account_number: rowData.account_number,
            customer_name: rowData.customer_name,
            urgency: rowData.urgency,
            sentByUsername: rowData.returnedBy,
            jointAccountCustomerNumber: rowData.jointAccountCustomerNumber,
            showInbox: true,
            loading: false
        });
    };
    functionForCaseReject = (event, data) => {
        event.preventDefault();
        if(data !== undefined){
            let selectedAppId = [];
            let object = {};
            for (let item of data) {
                selectedAppId.push(item.appId)
            }
            object.inputData = {bmBulkApproval: "REJECTED"};
            object.appIdList = selectedAppId;
            this.callBulkRouteApi(object)

        }

    };

    functionForCaseApprove = (event, data) => {
        event.preventDefault();
        if(data !== undefined){
            let selectedAppId = [];
            let object = {};
            for (let item of data) {
                selectedAppId.push(item.appId)
            }
            object.inputData = {bmBulkApproval: "APPROVED"};
            object.appIdList = selectedAppId;
            this.callBulkRouteApi(object)

        }

    };
    callBulkRouteApi=(object)=>{
        this.setState({
            loading: true,
            getData: false,
            showTable: false
        });
        let single_url = "";

        single_url = backEndServerURL + "/case/bulk/route";


        axios.post(single_url, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.functionForGetTabWiseUrl(this.state.activeItem, this.state.page);
            }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false,
                getData: true,
                showTable: true
            })
        })
    }

    renderTopLabelButton = () => {
        return [{
            name: "Approve",
            style: {...commonInboxButton},
            triggerFunction: this.functionForCaseApprove
        },
            {
                name: "Reject",
                style: {...commonInboxButton},
                triggerFunction: this.functionForCaseReject
            }]

    };

    renderTable = () => {
        return (
            (this.state.getData && this.state.showTable) &&
            <TableComponent bmBulkApproval={true} tableData={this.state.tableData}
                            tableColumns={this.renderTableColumn()}
                            onRowClick={this.onRowClick} loader={this.state.loading} maxBodyHeight="450px"
                            totalRow={this.state.totalRow} page={this.state.page} whiteSpace={"wrap"}
                            selection={LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('BM') !== -1 ? true : false}
                            handleChangePage={this.handleChangePage} rowsPerPage={this.state.rowsPerPage}
                            tableButton={LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('BM') !== -1 ? this.renderTopLabelButton() : undefined}
            />
        )
    };

    closeModal = () => {
        this.setState({
            showInbox: false,
            getMappingAllImage: false,
            getData: true,
            showTable: true,
            loading: false

        })
    };

    renderInboxComponent = () => {
        return ""
    };


    handleChangePreviewImage = (event, rowData) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true,
            appUid: rowData.appId,
        });
    };
    handleChangeReject = (event, rowData) => {
        event.preventDefault();
        console.log(rowData);
        this.setState({
            appUid: rowData.appId,
            actionAppId: rowData.appId,
            actionType: "Delete",
            confirmAlert: true,
        });
    };
    renderPreviewImage = (rowData) => {
        return (
            <>
                <Button
                    style={commonInboxButton}
                    onClick={(event) => this.handleChangePreviewImage(event, rowData)}
                >
                    Preview Image
                </Button>


            </>
        )

    };
    renderReject = (rowData) => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('BM') !== -1) {
            return (
                <>
                    <Button
                        style={commonInboxButton}
                        onClick={(event) => this.handleChangeReject(event, rowData)}
                    >
                        Reject
                    </Button>


                </>
            )
        }

    };
    viewCaseDelete = (appUid, serviceType, subserviceType, taskTitle, jointAccountCustomerNumber, category, subCategory, delStatus, status, solId, freeFlag1, freeFlag2, freeFlag3, freeFlag4, urgency, sentByUsername, cb_number, account_number, customer_name) => {
        this.setState({
            inboxModal: false,
            inboxModalSubmitted: false,
            loading: true,
            actionFlag: "Delete",
        });
        let url = backEndServerURL + "/case/close/" + appUid;
        axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    showTable: false,
                });
                this.fetchTableData(this.getApiUrl())
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                })
            })


    };
    closeConfirmAlert = (data) => {
        this.setState({
            confirmAlert: false
        });
        if (data === "YES" && this.state.actionType === "Delete") {
            this.viewCaseDelete(this.state.appUid)
        }
    };

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderCloseIcon()}
                </CardHeader>
                <CardBody>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loading} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>

                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        open={this.state.getMappingAllImage}>
                        <DialogContent>
                            <FileMappingReadOnly appId={this.state.appUid} closeModal={this.closeModal}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.confirmAlert}>
                        <DialogContent className={classes.dialogPaper}>
                            <ConfirmAlert actionFlag="Delete" remarksAdd={true}
                                          appUid={this.state.appUid} closeModal={this.closeConfirmAlert}/>

                        </DialogContent>
                    </Dialog>
                    <ThemeProvider theme={theme}>
                        {this.renderFilterField()}

                        <Grid item xs={12}><br/></Grid>
                        <Grid item xs={12}><br/></Grid>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >BM Pending Approval List</h4>
                        </CardHeader>
                        {this.renderTable()}
                        {this.renderNotification()}
                    </ThemeProvider>
                </CardBody>
            </Card>
        );
    }

}

export default withStyles(styles)(BMBulkAccountOpening);
