import React, {useEffect, useState} from 'react';
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";

function CaseWiseFiles({appId}) {

    const [getFiles, setFiles] = useState([]);

    useEffect(() => {
        let downLoadFileList = [];
        let fileUrl = backEndServerURL + "/case/files/pdfFilesFromImages/" + appId;
        axios.get(fileUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((data, index) => {
                    downLoadFileList.push(createDownloadFileLink(`Document ${index + 1}`, data))
                });
                setFiles(downLoadFileList);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const createDownloadFileLink = (sl, data) => {
        return (
            <React.Fragment>
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}
                    onClick={() => downloadDocument(data)}
                >
                    {sl}
                </button>
                &nbsp;&nbsp;
            </React.Fragment>

        )
    };

    const downloadDocument = (name) => {
        let url = backEndServerURL + "/file/" + name + "/" + sessionStorage.getItem("accessToken");
        window.open(url)
    };

    console.log(getFiles);
    return getFiles.map(button => button)
}

export default CaseWiseFiles;