import React, {Component} from 'react';
import OpeningCS from "./fdr/OpeningCS";
import {Dialog, MenuItem} from "@material-ui/core";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CloseIcon from '@material-ui/icons/Close';
import CardBody from "../Card/CardBody";
import GridContainer from "../Grid/GridContainer";
import AccountOpeningForm from "./AccountOpeningForm";
import {
    CSJsonFormForCasaIndividualFdr,
    CSJsonFormForCasaIndividualFdrLast,
    CSJsonFormForFDRServiceLast,
    CSJsonFormForFDRServiceNew,
} from "./WorkflowJsonFormArin";
import DialogContent from "@material-ui/core/DialogContent";
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";

class DedupLiabilityService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMenu: "NOTSELECTED",
            dstFlag: "",
            agentBankingFlag: "",
        }
    }

    componentDidMount() {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("solId")==="777" || LocalstorageEncrypt.encryptStorageFunction.getItem("solId")===777 || LocalstorageEncrypt.encryptStorageFunction.getItem("solId")==='777') {
            this.setState({
                 agentBankingFlag: true,
            })
        }
       else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace").indexOf('CSU') !== -1) {
            this.setState({
                dstFlag: true
            })
        }

    }

    handleMenuSelect(menu) {
        this.setState({selectedMenu: menu})
    }

    renderServiceMenu() {
        /*  if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace").indexOf('CSU') !== -1) {

              return (
                  <Dialog
                      fullWidth="true"
                      maxWidth="xl"
                      fullScreen={true}
                      open={true}>
                      <DialogContent>
                          <AccountOpeningForm closeModal={this.props.closeModal}
                                              getAccountType={this.props.getAccountType}
                                              accountType={this.props.accountType}
                                              jointRadioGetName={this.props.jointRadioGetName}
                                              checkboxjointData={this.state.checkboxjointData}
                                              getInputData={this.props.getInputData}
                                              dstFlag={true}
                                              agentBankingFlag={true}
                                              jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                                              workplace={this.props.workplace}
                                              serviceType={this.props.serviceType}
                                              subServiceType={this.props.subServiceType}
                                              individualDedupData={this.props.individualDedupData}
                                              mandateindividualDedupData={this.props.mandateindividualDedupData}
                                              beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}
                                              jointDedupData={this.props.jointDedupData}
                                              companyDedupData={this.props.companyDedupData}
                                              sdnData={this.props.sdnData}
                                              searchValue={this.props.searchValue}/>
                      </DialogContent>
                  </Dialog>


              )
          } else {*/
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Customer Services<a><CloseIcon onClick={this.props.closeModal} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></h4>

                        </CardHeader>
                        <CardBody>
                            <div>
                                <MenuItem onClick={(event) => {
                                    this.handleMenuSelect("accountOpening")
                                }}>Account Opening</MenuItem>
                              {/*  <MenuItem onClick={(event) => {
                                    this.handleMenuSelect("fdrOpening")
                                }}>FDR Opening</MenuItem>*/}
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
        // }
    }

    render() {
        if (this.state.selectedMenu === "accountOpening") {
            return (
                <div>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        fullScreen={true}
                        open={true}>
                        <DialogContent>

                            <AccountOpeningForm closeModal={this.props.closeModal}
                                                getAccountType={this.props.getAccountType}
                                                accountType={this.props.accountType}
                                                jointRadioGetName={this.props.jointRadioGetName}
                                                checkboxjointData={this.state.checkboxjointData}
                                                getInputData={this.props.getInputData}
                                                dstFlag={this.state.dstFlag}
                                                agentBankingFlag={this.state.agentBankingFlag}
                                                jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                                                workplace={this.props.workplace}
                                                serviceType={this.props.serviceType}
                                                subServiceType={this.props.subServiceType}
                                                individualDedupData={this.props.individualDedupData}
                                                mandateindividualDedupData={this.props.mandateindividualDedupData}
                                                beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}
                                                jointDedupData={this.props.jointDedupData}
                                                companyDedupData={this.props.companyDedupData}
                                                sdnData={this.props.sdnData}
                                                searchValue={this.props.searchValue}/>
                        </DialogContent>
                    </Dialog>

                </div>
            );

        } else if (this.state.selectedMenu === "fdrOpening") {
            if (this.state.selectedMenu === 'fdrOpening' && (this.props.subServiceType === 'Joint Account' || this.props.subServiceType === 'Company Account' || this.props.subServiceType === 'NONINDIVIDUAL' || this.props.subServiceType === 'Proprietorship A/C')) {
                return (
                    <div>
                        <Dialog
                            fullWidth="true"
                            maxWidth="xl"
                            fullScreen={true}
                            open={true}>
                            <DialogContent>

                                <OpeningCS closeModal={this.props.closeModal}
                                           closeModalOnlySubModal={this.props.closeModalOnlySubModal}
                                           getAccountType={this.props.getAccountType}
                                           freeFlag3={this.props.freeFlag3}
                                           accountType={this.props.accountType}
                                           dstFlag={this.state.dstFlag}
                                           agentBankingFlag={this.state.agentBankingFlag}
                                           jointRadioGetName={this.props.jointRadioGetName}
                                           jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                                           workplace={this.props.workplace}
                                           serviceType='FDR Opening'
                                           title="FDR Opening"
                                           freeFlag1="FDR Account Opening -Without Operative Account-NTB"
                                           accountType={this.props.accountType}
                                           jointRadioGetName={this.props.jointRadioGetName}
                                           subServiceType={this.props.subServiceType}
                                           serviceList={CSJsonFormForFDRServiceNew}
                                           serviceListLast={CSJsonFormForFDRServiceLast}
                                           commonJsonForm={CSJsonFormForCasaIndividualFdr}
                                           commonJsonFormLast={CSJsonFormForCasaIndividualFdrLast}
                                           individualDedupData={this.props.individualDedupData}
                                           mandateindividualDedupData={this.props.mandateindividualDedupData}
                                           beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}
                                           jointDedupData={this.props.jointDedupData}
                                           companyDedupData={this.props.companyDedupData}
                                           sdnData={this.props.sdnData}
                                           searchValue={this.props.searchValue}/>
                            </DialogContent>
                        </Dialog>

                    </div>
                );
            } else {
                return (
                    <div>
                        <Dialog
                            fullWidth="true"
                            maxWidth="xl"
                            fullScreen={true}
                            open={true}>
                            <DialogContent>
                                <OpeningCS closeModal={this.props.closeModal}
                                           closeModalOnlySubModal={this.props.closeModalOnlySubModal}
                                           getAccountType={this.props.getAccountType}
                                           accountType={this.props.accountType}
                                           freeFlag3={this.props.freeFlag3}
                                           dstFlag={this.state.dstFlag}
                                           agentBankingFlag={this.state.agentBankingFlag}
                                           jointRadioGetName={this.props.jointRadioGetName}
                                           jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                                           workplace={this.props.workplace}
                                           serviceType='FDR Opening'
                                           title="FDR Opening"
                                           freeFlag1="FDR Account Opening -Without Operative Account-NTB"

                                           accountType={this.props.accountType}
                                           jointRadioGetName={this.props.jointRadioGetName}
                                           subServiceType={this.props.subServiceType}
                                           serviceList={CSJsonFormForFDRServiceNew}
                                           serviceListLast={CSJsonFormForFDRServiceLast}
                                           commonJsonForm={CSJsonFormForCasaIndividualFdr}
                                           commonJsonFormLast={CSJsonFormForCasaIndividualFdrLast}
                                           individualDedupData={this.props.individualDedupData}
                                           mandateindividualDedupData={this.props.mandateindividualDedupData}
                                           beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}
                                           jointDedupData={this.props.jointDedupData}
                                           companyDedupData={this.props.companyDedupData}
                                           sdnData={this.props.sdnData}
                                           searchValue={this.props.searchValue}/>
                            </DialogContent>
                        </Dialog>

                    </div>
                );
            }


        } else {
            return this.renderServiceMenu();
        }
    }
}

export default DedupLiabilityService;