import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../Common/Constant";
import Functions from '../../../Common/Functions';
import GridList from "@material-ui/core/GridList/index";
import Grid from "@material-ui/core/Grid/index";
import CommonJsonFormComponent from '../../JsonForm/CommonJsonFormComponent';
import axios from 'axios/index';
import Notification from "../../NotificationMessage/Notification";
import {FundTransferJsonFoMaker, FundTransferJsonFormTwo} from '../WorkflowJsonFormRashed'
import DialogContent from "@material-ui/core/DialogContent/index";
import {Dialog} from "@material-ui/core/index";
import FileTypeComponent from '../../JsonForm/FileTypeComponent';
import Table from "../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import loader from "../../../Static/loader.gif";
import MyValidation from "../../JsonForm/MyValidation";
import theme from "../../JsonForm/CustomeTheme2";
import CBNotFound from "../CASA/CBNotFound";
import axiosDownload from "../../DeliverableManagement/FunctionForFileDownload";
import SignatureButton from "../Maintenance/SignatureButton";
import ConfirmationModal from "./ConfirmationModal";
import CommonApi from "../Maintenance/common/CommonApi";
import FullSdnDedupeResult from "../../FullSdnDedupeResult";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};
let makerRemarks = [
    {
        "varName": "makerRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];

var fileUpload = {
    "varName": "scanningFile",
    "type": "file",
    "accept": ".xlsx,.xls,.exl,xlsb,xlxb",
    "label": "Upload Excel",
    "grid": 12
};

class splitForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            inputData: {},
            varValue: {},
            showValue: true,
            getDeferalList: [],
            defferalData: false,
            getData: false,
            images: [],
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            confirmation: false,
            errorArray: {},
            errorMessages: {},
            pastDocumentModal: false,
            bulkUpload: false,
            signature: false,
            fileUploadData: {},
            getRemarks: [],
            errorAmount: false,
            errorAmountMessage: "",
            disabled: false,
            getDownloadListFlag: false,
            downLoadFileList: [],
            errorTittle: "",
            fileNotFoundTittle: "",
            selectedDate: {},
            SdnForFundTransfer: false
        };
    }

    signatureOpen = () => {
        this.setState({
            signature: true
        })
    };

    signatureClose = () => {
        this.setState({
            signature: false
        })
    };

    modalClose = () => {
        this.props.closeModal();
    };

    functionForDocument = () => {
        let downLoadFileList = [];
        let url = backEndServerURL + "/case/files/pdfFilesFromImages/" + this.props.appId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((data, index) => {
                    downLoadFileList.push(this.createDownloadFileLink(`Document ${index + 1}`, data))
                });
                console.log(response.data);
                this.setState({
                    getDownloadListFlag: true,
                    downLoadFileList: downLoadFileList
                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    fileNotFoundTittle: error.response.data.message
                })
            })
    };

    createDownloadFileLink = (sl, data) => {
        return (
            <React.Fragment>
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}
                    onClick={() => this.downloadDocument(data)}
                >
                    {sl}
                </button>
                &nbsp;&nbsp;
            </React.Fragment>

        )
    };

    downloadDocument = (name) => {
        let url = backEndServerURL + "/file/" + name + "/" + sessionStorage.getItem("accessToken");
        window.open(url)
    };

    renderDownLoadFileLink = () => {
        if (this.state.getData && this.state.getDownloadListFlag) {
            return (this.state.downLoadFileList.map((button) => {
                    return button
                })
            )
        } else {
            return <h5><b>{this.state.fileNotFoundTittle}</b></h5>
        }
    };

    componentDidMount() {
        CommonApi.getRemarkDetails(this.props.appId)
            .then((remarksArray) => {
                this.setState({
                    getRemarks: remarksArray
                })
            })
            .catch((error) => {
                console.log(error)
            });

        CommonApi.getDeferralDetails(this.props.appId)
            .then((tableArray) => {
                this.setState({
                    getDeferalList: tableArray
                });
                CommonApi.getFiles(this.props.appId).then((fileData) => {
                    this.setState({
                        getImageLink: fileData,
                        getImageBoolean: true
                    });
                })
            })
            .catch((error) => {
                console.log(error);
            });

        this.functionForDocument();

        CommonApi.getVariables(this.props.appId)
            .then((response) => {
                this.functionForFileGet();
                let inputData = {...response.data};
                this.functionForGetBankName(response.data.transferType).then((res) => {
                    inputData.source = "Finacle"
                    if (inputData.source === "Ababil") {
                        CommonApi.ababilAccountDetails(inputData.accountNumber).then((response) => {
                            inputData.accountBalance = response.data.acctEftvBalance;
                            this.setState({
                                inputData: inputData,
                                varValue: inputData,
                                getData: true,
                                loading: false
                            });
                        }).catch((error) => {
                            this.setState({
                                cbNotFound: true,
                                title: error.response.data.message,
                                loading: false
                            })
                        });
                    } else {
                        CommonApi.getCustAccountDetails(inputData.accountNumber).then((response) => {
                            inputData.accountBalance = response.data.acctEftvBalance;
                            this.setState({
                                inputData: inputData,
                                varValue: inputData,
                                getData: true,
                                loading: false
                            });
                        }).catch((error) => {
                            this.setState({
                                cbNotFound: true,
                                title: error.response.data.message,
                                loading: false
                            })
                        });
                    }
                }).catch((err) => {
                    this.setState({
                        varValue: inputData,
                        inputData: inputData,
                        getData: true,
                        loading: false
                    })
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            })

    }

    onKeyDownChange = (data) => {
        if (data.varName === "transferType") {
            this.setState({
                loading: true
            });
            this.functionForGetBankName(this.state.inputData.transferType).then((res) => {
                this.setState({
                    loading: false
                })
            }).catch((err) => {
                this.setState({
                    loading: false
                })
            })
        }
    };

    functionForGetBankName = (transferType) => {
        return new Promise((resolve, reject) => {
            let url = backEndServerURL + "/finMasterData/custom/otherBanksWithTransfer/" + transferType;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let bankName = [];
                    for (let bank of response.data) {
                        bankName.push(bank.value)
                    }
                    for (let field of FundTransferJsonFormTwo) {
                        if (field.varName === "beneficiaryBankName") {
                            field.enum = bankName
                        }
                    }
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    };

    functionForFileGet = () => {
        let imageUrl = backEndServerURL + "/case/files/fundTransfer-cs-attachment/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    documentList: response.data,
                    getDocument: true
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    functionForExlDownload = (document) => {
        let download_url = backEndServerURL + "/file/" + document;
        axiosDownload.downloadFile(download_url, 'GET', {}, document);
    };

    renderBulkFile = () => {
        if (this.state.getDocument && this.state.documentList.length > 0 && this.state.inputData.customerType) {
            return (
                this.state.documentList.map((document, index) => {
                    return (
                        <React.Fragment>
                            <button className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'middle',
                                        marginTop: '12px',
                                        marginRight: '10px',
                                    }}
                                    onClick={() => this.functionForExlDownload(document)}
                            >Branch File {index + 1}</button>
                        </React.Fragment>
                    )
                })
            );
        }
    };





    updateComponent = () => {
        this.forceUpdate();
    };

    functionForGettingRoutingNumber = () => {
        this.setState({
            loading: true
        });

        var url = backEndServerURL + "/finMasterData/custom/otherBranchesWithTransfer/" + this.state.inputData.beneficiaryBankName + "/" + this.state.inputData.transferType;
        let inputData = this.state.inputData;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    let branchName = [];
                    for (let item of response.data) {
                        branchName.push(item.value)
                    }
                    for (let field of FundTransferJsonFormTwo) {
                        if (field.varName === "branchName") {
                            field.enum = branchName
                        }
                    }
                    if (this.state.inputData.beneficiaryBankName && this.state.inputData.branchName) {
                        let routingUrl = backEndServerURL + "/finMasterData/custom/routingNo/" + this.state.inputData.beneficiaryBankName + "/" + this.state.inputData.branchName;

                        axios.get(routingUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                if (response.status === 200) {
                                    inputData.routingNumber = response.data.toString();

                                    this.setState({
                                        inputData: inputData,
                                        varValue: inputData,
                                        loading: false
                                    });
                                }
                            })
                            .catch((error) => {
                                this.setState({
                                    inputData: inputData,
                                    varValue: inputData,
                                    loading: false
                                });
                                console.log(error)
                            });
                    } else {
                        this.setState({
                            inputData: inputData,
                            varValue: inputData,
                            loading: false
                        });
                    }
                }
            })
            .catch((error) => {
                this.setState({
                    inputData: inputData,
                    varValue: inputData,
                    loading: false
                });
                console.log(error)
            });
        this.forceUpdate();
    };

    renderFormWithData = () => {
        if (this.state.getData) {
            return (
                <Grid container>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, FundTransferJsonFoMaker, this.updateComponent, this.amountConvert, this.onKeyDownChange)
                    }
                    {this.state.inputData.requestType === "Single Transaction" && CommonJsonFormComponent.renderJsonForm(this.state, FundTransferJsonFormTwo, this.updateComponent, "", this.functionForGettingRoutingNumber)}
                </Grid>
            )
        }
    };

    amountConvert = (event, data) => {
        this.setState({
            loading: true
        });
        this.state.inputData.showAmountConvertField = false;
        if (data === "transferAmount") {
            let form = [
                {
                    "varName": "transferAmount",
                    "type": "text",
                    "validation": "length",
                    "maxLength": 14,
                    "required": true
                }
            ];
            let error = MyValidation.defaultValidation(form, this.state);
            this.forceUpdate();
            if (error === true) {
                this.setState({
                    loading: false
                });
                return 0;
            } else {
                let inputData = this.state.inputData;
                let amount = this.state.inputData.transferAmount.split(".");

                if (2 < amount.length) {
                    this.setState({
                        inputData: inputData,
                        varValue: inputData,
                        loading: false,
                        cbNotFound: true,
                        title: "Invalid Amount!"
                    })
                } else {
                    let url = backEndServerURL + "/GetWordFromDecimalNumber";
                    let postData = {};
                    postData.number = this.state.inputData.transferAmount;
                    axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            inputData.amountInWord = response.data;
                            this.state.inputData.showAmountConvertField = true;
                            setTimeout(() => {
                                this.setState({
                                    inputData: inputData,
                                    varValue: inputData,
                                    loading: false
                                })
                            }, 1000)
                        })
                        .catch((error) => {
                            this.setState({
                                inputData: inputData,
                                varValue: inputData,
                                loading: false
                            });
                        });
                }
            }
        }
    };

    approveHandler = () => {
        let error = false;
        let errorFileType = false;
        if (this.state.inputData.requestType === "Single Transaction") {
            error = MyValidation.defaultValidation(FundTransferJsonFormTwo.concat(FundTransferJsonFoMaker), this.state);
            errorFileType = MyValidation.fileValidation(fileUpload, this.state);
            this.forceUpdate();
        } else {
            let fieldArray = [...FundTransferJsonFoMaker];
            for (let item of fieldArray) {
                if (item.varName === "transferAmount") {
                    delete item.validation;
                    delete item.compareVarName;
                    delete item.compareLabel;
                }
            }
            errorFileType = MyValidation.fileValidation(fileUpload, this.state);
            error = MyValidation.defaultValidation(fieldArray, this.state);
            this.forceUpdate();
        }

        var amountNotApplicable = false;
        if (this.state.inputData.transferType === "RTGS" && parseInt(this.state.inputData.transferAmount) < 100000) {
            amountNotApplicable = true;
            this.setState({
                cbNotFound: true,
                disabled: false,
                title: "Minimum RTGS Payment is 1 Lac"
            });
        }
        if (error === true || amountNotApplicable === true || errorFileType === true) {
            return 0;
        } else {
            if ((this.state.inputData.transferType === "RTGS" || this.state.inputData.transferType === "BEFTN") && parseInt(this.state.inputData.transferAmount) >= 50000000) {
                this.setState({
                    disabled: false,
                    title: "For payment of Tk. 5 crore and above, please take approval from Treasury Division.Did you take the approval ?",
                    confirmation: true,
                });
            } else if (this.state.inputData.transferType === "RTGS") {
                this.setState({
                    title: "Charge and VAT applicable as per Schedule of Charges",
                    confirmation: true,
                })
            } else {
                this.functionForRoute();
            }
        }
    };

    functionForRoute = () => {
        this.setState({
            disabled: true,
        });
        if (this.state.fileUploadData.scanningFile !== undefined) {
            let fileUploadPath = backEndServerURL + "/case/upload";
            let types = "fund-transfer-maker-upload";
            let files = this.state.fileUploadData.scanningFile;
            let formData = new FormData();
            formData.append("appId", this.props.appId);
            formData.append("file", files);
            formData.append("type", types);
            axios({
                method: 'post',
                url: fileUploadPath,
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'content-type': 'multipart/form-data'
                }
            })
                .then((response) => {


                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        disabled: false,
                    });

                })
        }
        this.functionForCaseRoute("CHECKER")
    };

    functionForCaseRoute = (value) => {
        let postData = {...this.state.inputData};
        postData.maker_update_all_info_send_to = value;
        let remarksData = {};
        remarksData.remark = postData.makerRemarks;
        remarksData.map = {...postData, makerRemarks: undefined};
        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            this.setState({
                disabled: false,
                title: "Successful!",
                notificationMessage: "Successfully Routed!",
                alert: true,
                loading: false
            });
            this.props.closeModal()
        }).catch((error) => {
            console.log(error);
            this.setState({
                disabled: false,
                loading: false
            })
        });
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title} message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    documentModal = () => {
        this.setState({
            pastDocumentModal: false
        })
    };

    excelUpload = () => {
        this.setState({
            bulkUpload: true
        })
    };


    returnHandler = (e) => {
        e.preventDefault();
        let dependencyField = [
            {varName: "makerRemarks", type: "textArea", required: true}
        ];
        let error = MyValidation.defaultValidation(dependencyField, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                disabled: false,
            });
           this.functionForCaseRoute("CS")
        }
    };

    previewExcelFile = () => {
        if (this.state.inputData.customerType !== undefined && this.state.inputData.customerType !== null) {
            return (
                <div>
                    {FileTypeComponent.restrictedFile(this.state, this.updateComponent, fileUpload)}
                </div>
            )
        }
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, makerRemarks, this.updateComponent)
            )
        }
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks</b>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    signature = () => {
        if (this.state.getData) {
            return <SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple" requestType={this.state.inputData.source} classes={this.props}/>;
        }
    };

    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <>
                    <br/>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Deferral List:</b>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                                tableData={this.state.getDeferalList}
                                tableAllign={['left', 'left']}
                            />
                        </div>
                    </div>
                </>
            )
        }
    };

    confirmationClose = (value) => {
        if (value === "ok") {
            this.functionForRoute()
        } else {
            this.setState({
                confirmation: false
            })
        }
    };
    handleSDNSearchButton = () => {
        if (this.state.inputData.transferType === "RTGS") {
            return (<div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={(event) => this.handleChangeSdnResult(event)}
                    >
                        SDN View
                    </button>
                </div>
            )
        }
    }
    handleChangeSdnResult = (event) => {
        event.preventDefault();
        this.setState({
            SdnForFundTransfer: true
        })

    };
    handleClose = (event) => {
        event.preventDefault();
        this.closeModalForSdnResult()
    }
    closeModalForSdnResult = () => {
        this.setState({
            SdnForFundTransfer: false
        })
    }

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);

        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <GridList cellHeight={window.innerHeight} cols={1}>
                    <div>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.cbNotFound}>
                            <DialogContent className={classes.dialogPaper}>
                                <CBNotFound
                                    closeModal={this.closeModalCBNotFound}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="md"
                            open={this.state.confirmation}>
                            <DialogContent>
                                <ConfirmationModal closeModal={this.confirmationClose}
                                                   title={this.state.title}/>
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            fullWidth="true"
                            maxWidth="md"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.SdnForFundTransfer}>
                            <DialogContent className={classes.dialogPaper}>
                                <FullSdnDedupeResult category={this.props.category}
                                                     subServiceType={'INDIVIDUAL'} appId={this.props.appId}/>
                            </DialogContent>
                            <center>
                                <button
                                    className="btn btn-outline-primary  btn-sm"
                                    style={{}}
                                    onClick={(event) => this.handleClose(event)}
                                >Close
                                </button>
                            </center>
                        </Dialog>

                        <ThemeProvider theme={theme}>
                            <Grid container spacing={0}>
                                {this.renderFormWithData()}
                                <Grid item xs={12}>
                                    {this.signature()}
                                </Grid>
                                <Grid item xs={12}>
                                    {this.handleSDNSearchButton()}
                                </Grid>
                                <Grid item xs={12}>
                                    {this.renderDownLoadFileLink()}
                                </Grid>
                                <Grid item xs={12}>
                                    {this.renderBulkFile()}
                                </Grid>
                                <Grid item xs={12}></Grid>

                                <Grid item xs={12}>
                                    {this.previewExcelFile()}
                                </Grid>
                                <Grid item xs={12}>
                                    {this.renderDefferalData()}
                                </Grid>
                                <Grid item xs={12}>
                                    {this.renderRemarksData()}
                                    <br/>
                                    {this.renderRemarks()}
                                </Grid>
                                <Grid item xs={12}></Grid>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        marginRight: '10px',
                                        marginTop: '10px',
                                    }}
                                    disabled={this.state.disabled}
                                    onClick={this.approveHandler}
                                >
                                    Submit
                                </button>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        marginTop: '10px',
                                    }}
                                    disabled={this.state.disabled}
                                    onClick={this.returnHandler}
                                >
                                    Return
                                </button>
                                {this.renderNotification()}
                            </Grid>
                        </ThemeProvider>
                    </div>
                </GridList>
            )
        }
    }
}

export default withStyles(styles)(splitForm);
