import React, {Component} from "react";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../JsonForm/CustomeTheme2";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import Notification from "../NotificationMessage/Notification";
import {backEndServerURL} from "../../Common/Constant";
import MyValidation from "../JsonForm/MyValidation";
import axios from "axios";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";
import ReferenceModal from "../DeliverableManagement/ReferenceModal";

let Remarks = [
    {
        "varName": "remarks",
        "type": "textArea",
        "label": "Remarks",
        "required": true,
        "grid": 12
    }];

    let receivedForm = [
        {
            "varName": "cnNumber",
            "type": "text",
            "label": "CN Number",
            "required": false,
            "grid": 6
        },
        {
            "varName": "amount",
            "type": "text",
            "label": "Amount",
            "required": false,
            "grid": 6
        }
    ];

class RequestInitialization extends Component {
    state = {
        inputData: {},
        selectedDate: {},
        getData: false,
        workstation: false,
        role: false,
        showValue: false,
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        values: [],
        err: false,
        varValue: [],
        errorArray: {},
        errorMessages: {},
        errorWorkPlace: false,
        errorgroup: false,
        title: "",
        notificationMessage: "",
        alert: false,
        getGroupListData: false,
        getworkplaceTypeListData: false,
        workplace: "",
        type: "success",
        submitButton: false,
    };
    emptyValueRemove = (data) => {
        if (data === '' || data === ' ' || data === "null" || data === "undefined")
            data = "";
        return data;
    };
    updateStatus = (event, rowData, activeItem, status, remarks) => {

        let postData = {};
        let url;
        postData.rowIdArray = this.props.rowIdArray;
        if(status==="Delivered"){
            postData.cnNumber = this.state.inputData.cnNumber
            postData.amount = this.state.inputData.amount
            url="/changeToDelivery/";
        } else {
            postData.remarks = {remarks};           
            if(this.props.type === 'OUTGOING'){
                url="/cancelOutgoingRequisitions/";
            } else if(this.props.type === 'INCOMING'){
                url="/cancelIncomingRequisitions/";
            }
        }
       
        if (rowData != null) {
            let single_url =
                backEndServerURL +
                url +
                activeItem + "/" + status;
            axios
                .post(single_url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.props.closeModal();
                    this.setState({
                        title: "Successful!",
                        notificationMessage: status,
                        alert: true,
                        loading: false,
                    })
                    //if(status!=="Delivered"){
                        let mail_url =
                        backEndServerURL + "/mail-notification/outGoing/" + activeItem + "/" + status;
                    axios
                        .post(mail_url, this.props.rowIdArray, {
                            headers: {
                                Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                            },
                        })
                        .then((res) => {
                           
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                   // }
                   
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
    handleSubmit = (event, rowData, props, activeItem, status) => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        let error = MyValidation.defaultValidation(this.emptyValueRemove(this.props.status==="Delivered" ? receivedForm : Remarks), this.state);
        this.forceUpdate();
        if (error === true) {
            console.log("Not working");
            this.setState({
                loading: false
            });
            return 0;
        }
        this.updateStatus(event, this.props.rowIdArray, this.props.activeItem, this.props.status, this.state.inputData.remarks);
    };
    renderButton = () => {
        return (
            <button
                className="btn btn-outline-primary  btn-sm"
                style={{
                    marginTop: "18px"
                }}
                onClick={this.handleSubmit}

            >
                Submit
            </button>
        )
    };
    componentDidMount() {
        if (this.props.getId !== undefined) {
            this.setState({
                submitButton: false,

            });
            this.setState({
                showValue: true,
                getData: true
            })
        } else {
            this.setState({
                submitButton: false,
            });
        }
    }
    updateComponent = () => {
        this.forceUpdate();
    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.type} stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };
    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    close = () => {
        this.props.closeModal()
    }
    renderHeader = () => {
        return (
            <h4 className="text-white">Remarks<a><CloseIcon onClick={this.close} style={{
                position: 'absolute',
                right: 10,
                color: "white"
            }}/></a></h4>
        )
    };
    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
            signatureModal: false,
            reference: false
        })
    };
    jsonForm = () => {
        if(this.props.status==="Delivered" && this.props.activeItem === "Picked"){
            return (CommonJsonFormComponent.renderJsonForm(this.state, receivedForm, this.updateComponent))
        } else {
            return (CommonJsonFormComponent.renderJsonForm(this.state, Remarks, this.updateComponent))
        }
        
    };

    render() {
        return (

            <Card>
                <CardHeader style={{
                    backgroundColor: '#142398',
                }}>
                    {this.renderHeader()}
                </CardHeader>
                <CardBody>
                    <div>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            open={this.state.loading}>
                            <DialogContent>

                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <ThemeProvider theme={theme}>
                            <Grid container>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"

                                    open={this.state.reference}>
                                    <DialogContent>
                                        <ReferenceModal
                                            closeModal={this.closeModalCBNotFound}
                                            referenceTitle={this.state.referenceTitle}
                                        />
                                    </DialogContent>
                                </Dialog>
                                {this.renderNotification()}
                                {this.jsonForm()}
                                <br/>
                                <Grid item xs={12}>
                                    {this.renderButton()}
                                </Grid>
                            </Grid>
                        </ThemeProvider>
                    </div>
                </CardBody>
            </Card>
        )
    }
}
export default RequestInitialization;