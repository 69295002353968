import React, {Component} from "react";
import Liability from "./CASA/Liability";

import {
    CSJsonFormForCasaCompany,
    CSJsonFormForCasaCompanyExist,
    CSJsonFormForCasaIndividual,
    CSJsonFormForCasaIndividualExist,
    CSJsonFormForCasaIndividualExistReadOnly,
    CSJsonFormForCasaIndividualReadOnly,
    CSJsonFormForCasaJoint,
    CSJsonFormForCasaJointExist,
    CSJsonFormForCasaJointExistReadOnly,
    CSJsonFormForCasaJointReadOnly,
    CSJsonFormForCasaProprietorship,
    CSJsonFormForCasaProprietorshipExist,
    CSJsonFormForCasaService,
    CSJsonFormForCasaServiceExist,
    CSJsonFormForCasaServiceLast,
    CSJsonFormForCasaServiceLastExist,
} from "./WorkflowJsonForm4";

class AccountOpeningForm extends Component {
    state = {
        inputData: {},
        selectedDate: {},
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        accountGenerateModal: null
    };
    updateComponent = () => {
        this.forceUpdate();
    };

    close = () => {
        this.props.closeModal();
    };
    closeModal = () => {
        this.setState({
            accountGenerateModal: false
        });
        this.props.closeModal();
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            accountGenerateModal: true
        })

    };


    casa = () => {
        if (this.props.serviceType === "Account Opening" && (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C")) {
            return (
                <Liability agentBanking={this.props.agentBanking} closeModal={this.props.closeModal}
                           businessSegment={this.props.businessSegment}
                           getAccountType={this.props.getAccountType}
                           accountType={this.props.accountType}
                           dstFlag={this.props.dstFlag}
                           agentBankingFlag={this.props.agentBankingFlag}
                           jointRadioGetName={this.props.jointRadioGetName}
                           getInputData={this.props.getInputData}
                           jointRadioGetName={this.props.jointRadioGetName}
                           commonJsonForm={CSJsonFormForCasaIndividual}
                           commonJsonFormAfterCreation={CSJsonFormForCasaIndividualReadOnly}
                           serviceList={CSJsonFormForCasaService}
                           accountOpeningType={this.props.accountOpeningType}
                           serviceListLast={CSJsonFormForCasaServiceLast}
                           getCustomerId={this.props.getCustomerId}
                           jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                           serviceType={this.props.serviceType}
                           subServiceType={this.props.subServiceType}
                           getType={this.props.getType}
                           individualDedupData={this.props.individualDedupData}
                           mandateindividualDedupData={this.props.mandateindividualDedupData}
                           beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}
                           mandateindividualDedupData={this.props.mandateindividualDedupData}
                           beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}

                           jointDedupData={this.props.jointDedupData}
                           companyDedupData={this.props.companyDedupData}
                           searchValue={this.props.searchValue}
                />
            )
        } else if (this.props.serviceType === "Account Opening" && this.props.subServiceType === "Joint Account") {
            return (
                <Liability agentBanking={this.props.agentBanking} closeModal={this.props.closeModal}
                           businessSegment={this.props.businessSegment}
                           getAccountType={this.props.getAccountType}
                           accountType={this.props.accountType}
                           dstFlag={this.props.dstFlag}
                           agentBankingFlag={this.props.agentBankingFlag}
                           jointRadioGetName={this.props.jointRadioGetName}
                           getInputData={this.props.getInputData}
                           jointRadioGetName={this.props.jointRadioGetName}
                           jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                           commonJsonForm={CSJsonFormForCasaJoint}
                           commonJsonFormAfterCreation={CSJsonFormForCasaJointReadOnly}
                           serviceList={CSJsonFormForCasaService}
                           serviceListLast={CSJsonFormForCasaServiceLast}
                           serviceType={this.props.serviceType}
                           accountOpeningType={this.props.accountOpeningType}
                           getCustomerId={this.props.getCustomerId}
                           individualDedupData={this.props.individualDedupData}
                           mandateindividualDedupData={this.props.mandateindividualDedupData}
                           beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}
                           mandateindividualDedupData={this.props.mandateindividualDedupData}
                           beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}

                           jointDedupData={this.props.jointDedupData}
                           companyDedupData={this.props.companyDedupData}
                           subServiceType={this.props.subServiceType}
                           getType={this.props.getType}
                           searchValue={this.props.searchValue}
                />
            )
        } else if (this.props.serviceType === "Account Opening" && (this.props.subServiceType === "Proprietorship A/C" || this.props.subServiceType === "NONINDIVIDUAL")) {
            return (
                <Liability agentBanking={this.props.agentBanking} closeModal={this.props.closeModal}
                           businessSegment={this.props.businessSegment}
                           getAccountType={this.props.getAccountType}
                           jointRadioGetName={this.props.jointRadioGetName}
                           accountType={this.props.accountType}
                           dstFlag={this.props.dstFlag}
                           agentBankingFlag={this.props.agentBankingFlag}
                           jointRadioGetName={this.props.jointRadioGetName}
                           getInputData={this.props.getInputData}
                           commonJsonForm={CSJsonFormForCasaProprietorship}
                           serviceList={CSJsonFormForCasaService}
                           serviceListLast={CSJsonFormForCasaServiceLast}
                           serviceType={this.props.serviceType}
                           accountOpeningType={this.props.accountOpeningType}
                           getCustomerId={this.props.getCustomerId}
                           jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                           individualDedupData={this.props.individualDedupData}
                           mandateindividualDedupData={this.props.mandateindividualDedupData}
                           beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}
                           mandateindividualDedupData={this.props.mandateindividualDedupData}
                           beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}

                           jointDedupData={this.props.jointDedupData}
                           companyDedupData={this.props.companyDedupData}
                           subServiceType={this.props.subServiceType}
                           getType={this.props.getType}
                           searchValue={this.props.searchValue}
                />
            )
        } else if (this.props.serviceType === "Account Opening" && (this.props.subServiceType === "Company Account")) {
            return (
                <Liability agentBanking={this.props.agentBanking} closeModal={this.props.closeModal}
                           businessSegment={this.props.businessSegment}
                           getAccountType={this.props.getAccountType}
                           jointRadioGetName={this.props.jointRadioGetName}
                           accountType={this.props.accountType}
                           dstFlag={this.props.dstFlag}
                           agentBankingFlag={this.props.agentBankingFlag}
                           jointRadioGetName={this.props.jointRadioGetName}
                           getInputData={this.props.getInputData}
                           commonJsonForm={CSJsonFormForCasaCompany}
                           serviceList={CSJsonFormForCasaService}
                           accountOpeningType={this.props.accountOpeningType}
                           serviceListLast={CSJsonFormForCasaServiceLast}
                           serviceType={this.props.serviceType}
                           getCustomerId={this.props.getCustomerId}
                           jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                           individualDedupData={this.props.individualDedupData}
                           mandateindividualDedupData={this.props.mandateindividualDedupData}
                           beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}
                           mandateindividualDedupData={this.props.mandateindividualDedupData}
                           beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}

                           jointDedupData={this.props.jointDedupData}
                           companyDedupData={this.props.companyDedupData}
                           subServiceType={this.props.subServiceType}
                           getType={this.props.getType}
                           searchValue={this.props.searchValue}
                />
            )
        } else {
            alert("Please select Tab")
        }
    };

    casaExist = () => {

        if (this.props.serviceType === "ExistAccountOpening" && (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C")) {

            return (
                <Liability closeModal={this.props.closeModal} businessSegment={this.props.businessSegment}
                           getAccountType={this.props.getAccountType}
                           accountType={this.props.accountType}
                           dstFlag={this.props.dstFlag}
                           agentBankingFlag={this.props.agentBankingFlag}
                           jointRadioGetName={this.props.jointRadioGetName}
                           getInputData={this.props.getInputData}
                           commonJsonForm={CSJsonFormForCasaIndividualExist}
                           commonJsonFormAfterCreation={CSJsonFormForCasaIndividualExistReadOnly}
                           serviceList={CSJsonFormForCasaServiceExist}
                           serviceListLast={CSJsonFormForCasaServiceLastExist}
                           accountOpeningType={this.props.accountOpeningType}
                           getCustomerId={this.props.getCustomerId}
                           serviceType={this.props.serviceType}
                           subServiceType={this.props.subServiceType}
                           getType={this.props.getType}
                           individualDedupData={this.props.individualDedupData}
                           mandateindividualDedupData={this.props.mandateindividualDedupData}
                           beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}
                           mandateindividualDedupData={this.props.mandateindividualDedupData}
                           beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}

                           jointDedupData={this.props.jointDedupData}
                           companyDedupData={this.props.companyDedupData}
                           searchValue={this.props.searchValue}
                />
            )
        } else if (this.props.serviceType === "ExistAccountOpening" && this.props.subServiceType === "Joint Account") {
            return (
                <Liability closeModal={this.props.closeModal} businessSegment={this.props.businessSegment}
                           getAccountType={this.props.getAccountType}
                           accountType={this.props.accountType}
                           dstFlag={this.props.dstFlag}
                           agentBankingFlag={this.props.agentBankingFlag}
                           jointRadioGetName={this.props.jointRadioGetName}
                           getInputData={this.props.getInputData}
                           getCustomerId={this.props.getCustomerId}
                           jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                           commonJsonForm={CSJsonFormForCasaJointExist}
                           commonJsonFormAfterCreation={CSJsonFormForCasaJointExistReadOnly}
                           accountOpeningType={this.props.accountOpeningType}
                           serviceList={CSJsonFormForCasaServiceExist}
                           serviceListLast={CSJsonFormForCasaServiceLastExist}
                           serviceType={this.props.serviceType}
                           individualDedupData={this.props.individualDedupData}
                           mandateindividualDedupData={this.props.mandateindividualDedupData}
                           beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}
                           mandateindividualDedupData={this.props.mandateindividualDedupData}
                           beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}

                           jointDedupData={this.props.jointDedupData}
                           companyDedupData={this.props.companyDedupData}
                           subServiceType={this.props.subServiceType}
                           getType={this.props.getType}
                           searchValue={this.props.searchValue}
                />
            )
        } else if (this.props.serviceType === "ExistAccountOpening" && (this.props.subServiceType === "Proprietorship A/C" || this.props.subServiceType === "NONINDIVIDUAL")) {
            return (
                <Liability closeModal={this.props.closeModal} businessSegment={this.props.businessSegment}
                           getAccountType={this.props.getAccountType}
                           accountType={this.props.accountType}
                           jointRadioGetName={this.props.jointRadioGetName}
                           getInputData={this.props.getInputData}
                           dstFlag={this.props.dstFlag}
                           agentBankingFlag={this.props.agentBankingFlag}
                           accountOpeningType={this.props.accountOpeningType}
                           getCustomerId={this.props.getCustomerId}
                           commonJsonForm={CSJsonFormForCasaProprietorshipExist}
                           serviceList={CSJsonFormForCasaServiceExist}
                           serviceListLast={CSJsonFormForCasaServiceLastExist}
                           serviceType={this.props.serviceType}
                           jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                           individualDedupData={this.props.individualDedupData}
                           mandateindividualDedupData={this.props.mandateindividualDedupData}
                           beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}
                           mandateindividualDedupData={this.props.mandateindividualDedupData}
                           beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}

                           jointDedupData={this.props.jointDedupData}
                           companyDedupData={this.props.companyDedupData}
                           subServiceType={this.props.subServiceType}
                           getType={this.props.getType}
                           searchValue={this.props.searchValue}
                />
            )
        } else if (this.props.serviceType === "ExistAccountOpening" && (this.props.subServiceType === "Company Account")) {
            return (
                <Liability closeModal={this.props.closeModal} businessSegment={this.props.businessSegment}
                           getAccountType={this.props.getAccountType}
                           accountType={this.props.accountType}
                           jointRadioGetName={this.props.jointRadioGetName}
                           getInputData={this.props.getInputData}
                           dstFlag={this.props.dstFlag}
                           agentBankingFlag={this.props.agentBankingFlag}
                           getCustomerId={this.props.getCustomerId}
                           accountOpeningType={this.props.accountOpeningType}
                           commonJsonForm={CSJsonFormForCasaCompanyExist}
                           serviceList={CSJsonFormForCasaServiceExist}
                           serviceListLast={CSJsonFormForCasaServiceLastExist}
                           serviceType={this.props.serviceType}
                           jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                           individualDedupData={this.props.individualDedupData}
                           mandateindividualDedupData={this.props.mandateindividualDedupData}
                           beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}
                           mandateindividualDedupData={this.props.mandateindividualDedupData}
                           beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}

                           jointDedupData={this.props.jointDedupData}
                           companyDedupData={this.props.companyDedupData}
                           subServiceType={this.props.subServiceType}
                           getType={this.props.getType}
                           searchValue={this.props.searchValue}
                />
            )
        } else {
            alert("Please select Tab")
        }
    };


    renderInboxCase = () => {

        //alert(this.props.serviceType)
        //alert(this.props.subServiceType)
        if (this.props.serviceType === "Account Opening" && this.props.workplace === "CSU") {

            return (
                this.casa()
            )
        } else if (this.props.serviceType === "Account Opening") {

            return (
                this.casa()
            )
        } else if (this.props.serviceType === "ExistAccountOpening" && this.props.workplace === "CSU") {
            return (
                this.casaExist()
            )
        } else if (this.props.serviceType === "ExistAccountOpening") {
            return (
                this.casaExist()
            )
        }
    };

    render() {
        return (
            this.renderInboxCase()
        )
    }

}

export default AccountOpeningForm;