import React from "react";


class ButtonComponent {


    static renderButton(state, update, field) {
        return (
            <a
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: "middle",
                    display: "block",
                    marginTop: 20,
                }}
                target="_blank"
                href="https://192.168.249.10/partner-portal/login"
            >
                {field.label}
            </a>
        )
    }

}

export default ButtonComponent;