import React from 'react';
import TextFieldComponent from "./TextFieldComponent";
import SelectComponent from "./SelectComponent";
import RadioButtonComponent from "./RadioButtonComponent";
import DateComponent from "./DateComponent";
import DropdownComponent from "./DropdownComponent";
import Grid from "@material-ui/core/Grid";
import PasswordComponent from "./PasswordComponent";
import CheckboxComponent from "./CheckboxComponent";
import FileTypeComponent from "./FileTypeComponent";
import TextAreaComponent from "./TextAreaComponent";
import AutoCompleteComponent from "./AutoCompleteComponent";
import AutoCompleteComponentValueReturn from "./AutoCompleteComponentValueReturn";
import TextFieldForDedup from "./TextFieldForDedup";
import AutoCompleteComponentForKey from "./AutoCompleteComponentForKey";
import TextFieldApiCall from "./TextFieldApiCall";
import DateComponentDeferal from "./DateComponentDeferal";
import Divider from "@material-ui/core/Divider";
import {blackColor} from "../../Static/jss/material-dashboard-react";
import ButtonComponent from "./ButtonComponent";
import SubmitButtonComponent from "./SubmitButtonComponent";
import SignatureButton from "../workflow/Maintenance/SignatureButton";
import TextFieldComponentForAutoComplete from "./TextFieldComponentForAutoComplete";

class CommonJsonFormComponent {

    static renderField(state, field, update, onKeyDownForDedup, onKeyDownChange) {

        if (field.type === "title") {
            return (

                <Grid item xs={field.grid}>

                    <h6 style={{color: "#FF5733"}}>{field.label}</h6>

                </Grid>

            )

        } else if (field.type === "titleBlack") {
            return (

                <Grid item xs={field.grid}>

                    <h6 style={{color: "#2f3d1b"}}>{field.label}</h6>

                </Grid>

            )

        } else if (field.type === "titleBold") {
            return (

                <Grid item xs={field.grid}>

                    <h6 style={{color: "#2f3d1b"}}><b>{field.label}</b></h6>

                </Grid>

            )

        } else if (field.type === "straightLine") {
            return (

                <Grid item xs={field.grid}>

                    <Divider color={blackColor}/>

                </Grid>

            )

        } else if (field.type === "titleLeft") {
            return (

                <Grid item xs={field.grid}>
                    <center><h6 style={{color: "#FF5733"}}>{field.label}</h6></center>
                </Grid>
            );

        } else if (field.type === "text") {

            return (
                <Grid item xs={field.grid}>
                    {
                        TextFieldComponent.text(state, update, field, onKeyDownChange)
                    }

                    {/* <img name={field.varName}  src={comment} alt=""/>*/}
                </Grid>
            )

        } else if (field.type === "textDedup") {

            return (
                <Grid item xs={field.grid}>
                    {
                        TextFieldForDedup.text(state, update, field, onKeyDownForDedup)
                    }

                    {/* <img name={field.varName}  src={comment} alt=""/>*/}
                </Grid>
            )

        } else if (field.type === "textApiCall") {
            if (field.readOnly === true) {
                return (
                    <Grid item xs={field.grid}>
                        {

                            TextFieldComponent.text(state, update, field)}
                    </Grid>
                )
            } else {
                return (
                    <Grid item xs={field.grid}>
                        {
                            TextFieldApiCall.text(state, update, field, onKeyDownForDedup)
                        }

                        {/* <img name={field.varName}  src={comment} alt=""/>*/}
                    </Grid>
                )
            }

        } else if (field.type === "empty") {

            return (
                <Grid item xs={field.grid}>
                </Grid>
            )

        } else if (field.type === "textArea") {

            return (
                <Grid item xs={field.grid}>
                    {
                        TextAreaComponent.text(state, update, field)
                    }

                </Grid>
            )

        } else if (field.type === "file") {

            if (field.accept !== undefined) {
                return (
                    <Grid item xs={field.grid}>
                        {
                            FileTypeComponent.restrictedFile(state, update, field)
                        }


                    </Grid>
                )
            } else {
                return (
                    <Grid item xs={field.grid}>
                        {
                            FileTypeComponent.file(state, update, field)
                        }


                    </Grid>
                )
            }

        } else if (field.type === "password") {
            if (field.readOnly === true) {
                return (
                    <Grid item xs={field.grid}>
                        {
                            PasswordComponent.password(state, update, field)}

                    </Grid>
                )
            } else {
                return (
                    <Grid item xs={field.grid}>
                        {
                            PasswordComponent.password(state, update, field)}

                    </Grid>
                )
            }
        } else if (field.type === "dropdown") {
            if (field.readOnly === true) {
                return (
                    <Grid item xs={field.grid}>
                        {
                            DropdownComponent.readOnly(state, update, field)
                        }

                    </Grid>
                )
            } else {
                return (
                    <Grid item xs={field.grid}>
                        {
                            DropdownComponent.dropdown(state, update, field)
                        }

                    </Grid>
                )
            }
        } else if (field.type === "date") {
            /* if (field.readOnly === true) {
                 return (
                     <Grid item xs={field.grid}>
                         {

                             TextFieldComponent.text(state, update, field)}
                     </Grid>
                 )
             } else {*/
            return (
                <Grid item xs={field.grid}>
                    {
                        DateComponent.date(state, update, field, onKeyDownChange)
                    }
                </Grid>
            )
            /*  }*/
        } else if (field.type === "dateAdd") {
            /*  if (field.readOnly === true) {
                  return (
                      <Grid item xs={field.grid}>
                          {

                              TextFieldComponent.text(state, update, field)}
                      </Grid>
                  )
              }
              else{*/
            return (
                <Grid item xs={field.grid}>
                    {
                        DateComponentDeferal.date(state, update, field, onKeyDownChange)
                    }
                </Grid>
            )
            /* }*/

        } else if (field.type === "radio") {
            return (
                <Grid item xs={field.grid}>
                    {
                        RadioButtonComponent.radio(state, update, field)
                    }
                    {/*<img src={comment} alt="" />*/}
                </Grid>
            )
        } else if (field.type === "select") {
            if (field.readOnly === true) {
                return (
                    <Grid item xs={field.grid}>
                        {

                            TextFieldComponentForAutoComplete.text(state, update, field)}
                    </Grid>
                )
            }
            else {
                return (
                    <Grid item xs={field.grid}>
                        {
                            SelectComponent.select(state, update, field, onKeyDownChange)
                        }
                    </Grid>
                )
            }
        } else if (field.type === "autoComplete") {
            return (
                <Grid item xs={field.grid}>
                    {
                        AutoCompleteComponent.renderSelect(state, update, field)
                    }
                </Grid>
            )
        } else if (field.type === "autoCompleteValueReturn") {
            if (field.readOnly === true) {
                return (
                    <Grid item xs={field.grid}>
                        {

                            TextFieldComponentForAutoComplete.text(state, update, field)}
                    </Grid>
                )
            }
            else {
                return (
                    <Grid item xs={field.grid}>
                        {
                            AutoCompleteComponentValueReturn.select(state, update, field, onKeyDownChange)
                        }
                    </Grid>
                )
            }
        }

        else if (field.type === "autoCompleteKeyReturn") {
            return (
                    <Grid item xs={field.grid}>
                        {
                            AutoCompleteComponentForKey.select(state, update, field, onKeyDownChange)
                        }
                    </Grid>
                )
        } else if (field.type === "checkbox") {
            return (
                <Grid item xs={field.grid}>
                    {
                        CheckboxComponent.checkbox(state, update, field, onKeyDownForDedup)
                    }

                </Grid>
            )
        } else if (field.type === "button") {
            return (
                <Grid item xs={field.grid}>
                    {
                        ButtonComponent.renderButton(state, update, field)
                    }

                </Grid>
            )
        } else if (field.type === "submit") {
            return (
                <Grid item xs={field.grid}>
                    {
                        SubmitButtonComponent.renderButton(state, update, field, onKeyDownChange)
                    }

                </Grid>
            )
        }
    }

    static renderJsonForm(state, jsonForm, update, onKeyDownForDedup, onKeyDownChange) {


        return (
            <React.Fragment>
                {
                    jsonForm.map((field) => {
                        if (field.conditional === true) {
                            if (state.inputData[field.conditionalVarName] === field.conditionalVarValue) {

                                return this.renderField(state, field, update, onKeyDownForDedup, onKeyDownChange);
                            }
                        } else if (field.masterData === true && field.masterDataArray === true) {
                            let condition = true;
                            for (let i = 0; i < field.conditionalArrayList.length; i++) {
                                let varName, varValue = "";
                                if (state.inputData[field.conditionalArrayList[i]] && state.inputData[field.conditionalArrayList[i]].key) {
                                    varName = (state.inputData[field.conditionalArrayList[i]].key).replace("/", "_");
                                    varValue = (field.conditionalArrayValue[i]).replace("/", "_")
                                } else {
                                    varName = state.inputData[field.conditionalArrayList[i]];
                                    varValue = field.conditionalArrayValue[i];
                                }
                                condition = condition && (varName === varValue);
                            }
                            if (condition) {
                                return this.renderField(state, field, update, onKeyDownForDedup, onKeyDownChange);
                            }
                        } else if (field.masterData === true) {
                            let conditionVarName = state.inputData[field.conditionalVarName];
                            if (conditionVarName !== null && conditionVarName !== undefined && conditionVarName.key !== null && conditionVarName.key !== undefined && (conditionVarName.key).replace("/", "_") === (field.conditionalVarValue).replace("/", "_")) {

                                return this.renderField(state, field, update, onKeyDownForDedup, onKeyDownChange);
                            }

                        } else if (field.conditionalArray === true) {
                            let condition = true;
                            for (let i = 0; i < field.conditionalArrayList.length; i++) {
                                condition = condition && (state.inputData[field.conditionalArrayList[i]] === field.conditionalArrayValue[i]);
                            }
                            if (condition) {
                                return this.renderField(state, field, update, onKeyDownForDedup, onKeyDownChange);
                            }
                        } else if (field.conditionalOrValue === true) {
                            let condition = true;
                            condition = condition && field.conditionalOrList.includes(state.inputData[field.conditionalVarName]);
                            if (condition) {
                                return this.renderField(state, field, update, onKeyDownForDedup, onKeyDownChange);
                            }
                        } else {
                            return this.renderField(state, field, update, onKeyDownForDedup, onKeyDownChange);
                        }


                    })
                }
            </React.Fragment>
        )


    }


}

export default CommonJsonFormComponent;