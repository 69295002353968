import React, {Component} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import '../../style.css'
import {Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';
import theme from "../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles/index";
import CardBody from "../Card/CardBody";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import {backEndServerURL} from "../../Common/Constant";
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";


// const dataForPieChart = [
//     {name: 'Deferral (Pending)', value: 10, fill: '#dc3545'},
//     {name: 'Deferral (Expired)', value: 16, fill: '#28a745'},
//     {name: 'Deferral (To be Closed)', value: 20, fill: '#17a2b8'},

// ];

// const data = [
//     {
//         name: 'Sunday', customer: 15,
//     },
//     {
//         name: 'Monday', customer: 20,
//     },
//     {
//         name: 'Tuesday', customer: 10,
//     },
//     {
//         name: 'Wednesday', customer: 18,
//     },
//     {
//         name: 'Thursday', customer: 22,
//     },
//];


class CsDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initiatorAccOpTitel: ['Account Opening', 'FDR Opening', 'DPS Opening',
                'Account Closing', 'FDR Closing', 'DPS Closing'],
            initiatorAccOpData: ['0', '0', '0', '0', '0', '0'],
            otherServiceTitel: ['Sanchaypatra', 'Remittance Inward', 'Remittance Outward', 'Total Number Of Service', 'RTGS', 'BEFTN', 'Salary Transfer'],
            otherServiceData: ['0', '0', '0', '0', '0', '0', '0'],
            subServiceRequestTitel: ['Cheque Book', 'Debit Card', 'Instant Debit Card', 'Maintenance', 'City Touch', 'SMS Alert'],
            subServiceRequestData: ['0', '0', '0', '0', '0', '0'],
            numberOfPendingTitel: ['Account Opened but Pending', 'FDR Opened but Pending', 'DPS Opened but Pending',
                'Account Closing but Pending', 'FDR Closing but Pending', 'DPS Closing but Pending',
                'Sanchaypatra Opened but Pending', 'Remittance Inward Opened but Pending', 'Remittance Outward Opened but Pending',
                'Cheque Book Opened but Pending', 'Debit Card Opened but Pending', 'Instant Debit Card Opened but Pending',
                'Maintenance Opened but Pending', 'City Touch Opened but Pending', 'SMS Alert Opened but Pending'],
            numberOfPendingData: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],


            initiatorCardTitel: ['Number of Activity/Customer Attened', 'Number of Request Submitted(Call Catagory Wise)',
                'Number of Request Pending at Executor End', 'Ratio of Return Request'],
            initiatorCardData: ['10', '11', '13', '18'],
            executorTitel1: ['Number of Execution in Open Status', 'Number of Pending Execution (Locked)', 'Number of Pending Execution (In Progress)'],
            executorTitel2: ['Number of Execution (Solved)', 'Number of Execution (Canceled)'],
            executorData1: ['20', '14', '10'],
            executorData2: ['22', '9'],
            unitHeadTitel1: ['Number of Execution in Open Status', 'Number of Pending Execution (Locked)', 'Number of Pending Execution (In Progress)'],
            unitHeadTitel2: ['Number of Execution (Solved)', 'Number of Execution (Canceled)', 'Number of Monthly Execution'],
            unitHeadData1: ['14', '21', '16'],
            unitHeadData2: ['15', '2', '20'],
            initiatorErrorTitel: ['Error Rate:', 'Error Rate:', '', ''],
            initiatorErrorData: ['6', '2', '', ''],
            boxColor: ['bg-info', 'bg-success', 'bg-warning', 'bg-danger', 'bg-secondary', 'bg-dark'],
            showView: LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup"),
            getData: false,
            backEndUrl: backEndServerURL + '/dashboard/',
            dataForPieChart: [
                // {name: 'Deferral(Pending)', value: 10, fill: '#dc3545'},
                {name: 'To be Closed', value: 0, fill: '#28a745'},
                {name: 'Expired', value: 0, fill: '#CC1624'},

            ],
            data: [
                {
                    name: 'Sunday', customer: 15,
                },
                {
                    name: 'Monday', customer: 20,
                },
                {
                    name: 'Tuesday', customer: 10,
                },
                {
                    name: 'Wednesday', customer: 18,
                },
                {
                    name: 'Thursday', customer: 22,
                },
            ],
        }

    }

    componentDidMount() {
        console.log(LocalstorageEncrypt.encryptStorageFunction.getItem("reload"));
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("reload") !== null && LocalstorageEncrypt.encryptStorageFunction.getItem("reload") !== undefined) {
            LocalstorageEncrypt.encryptStorageFunction.removeItem("reload");
            window.location.reload();
        }
        let url = '';
        if (this.state.showView === 'CS' || this.state.showView === 'BOM' || this.state.showView === 'BM' || this.state.showView === 'HEAD_OFFICE') {
            url = this.state.backEndUrl + 'cs'
        } else if (this.state.showView === 'CARD_DIVISION' || this.state.showView === 'CARD_DIVISION_LINE_MANAGER') {
            url = this.state.backEndUrl + 'card'
        }
        axios
            .get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                console.log(response.data);
                if (this.state.showView === 'CARD_DIVISION' || this.state.showView === 'CARD_DIVISION_LINE_MANAGER') {
                    this.setState({
                        executorData1: [response.data.openCount, response.data.lockedCount, response.data.inProgressCount],
                        executorData2: [response.data.solvedCount, response.data.canceledCount],
                        getData: true,

                    })
                } else if (this.state.showView === 'CS' || this.state.showView === 'BOM' || this.state.showView === 'BM' || this.state.showView === 'HEAD_OFFICE') {
                    this.setState({
                        initiatorAccOpData: [response.data.casa, response.data.fdr, response.data.dps,
                            response.data.casaClosing, response.data.fdrClosing, response.data.dpsClosing],
                        otherServiceData: [response.data.san, response.data.rmtIn, response.data.rmtOut,
                            response.data.totalCount, response.data.rtgsCount, response.data.bftnCount, response.data.salaryCount],
                        subServiceRequestData: [response.data.chequeBook, response.data.debitCard,
                            response.data.dcI, response.data.main, response.data.cityTouch, response.data.smsAlert],

                        numberOfPendingData: [response.data.till_casa, response.data.till_fdr, response.data.till_dps,
                            response.data.till_casaClosing, response.data.till_fdrClosing, response.data.till_dpsClosing,
                            response.data.till_san, response.data.till_rmtIn, response.data.till_rmtOut, response.data.till_chequeBook, response.data.till_debitCard,
                            response.data.till_dcI, response.data.till_main, response.data.till_cityTouch, response.data.till_smsAlert],
                        dataForPieChart: [
                            {name: 'To be Closed', value: response.data.toBeClosed, fill: '#28a745'},
                            {name: 'Expired', value: response.data.expiredCount, fill: '#CC1624'},
                        ],
                        getData: true,
                    })
                }

            }).catch(error => {
            console.log(error);
            this.setState({
                getData: true,
            })
        });
    }

    clickPie = () => {
        // alert('hi')
    };

    clickBar = () => {
        //alert('Clicked')
    };

    handleLabel = ({cx, cy, midAngle, innerRadius, outerRadius, value, index}) => {
        console.log("handling label?");
        const RADIAN = Math.PI / 180;
        // eslint-disable-next-line
        const radius = 25 + innerRadius + (outerRadius - innerRadius);
        // eslint-disable-next-line
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        // eslint-disable-next-line
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill="#8884d8"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
            >
                {this.state.dataForPieChart[index].name} ({value})
            </text>
        );
    };


    render() {
        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        date = date.toString().length === 1 ? '0' + date : date + '';
        month = month.toString().length === 1 ? '0' + month : month + '';

        return (
            <Paper square>
                <CardBody>
                    {/* <GridContainer> */}
                    <ThemeProvider theme={theme}>
                        {this.state.showView === 'CARD_DIVISION' || this.state.showView === 'CARD_DIVISION_LINE_MANAGER' ?
                            ""
                            :
                            <Row style={{margin: '10px', border: '0.5px solid', padding: '5px', background: '#DCDCDC'}}>
                                <Col style={{textAlign: 'left'}} md={6}><h4 style={{color: "white"}} ><strong>DASHBOARD</strong></h4></Col>
                                <Col style={{textAlign: 'right'}} md={6}><h4 style={{color: "white"}} >
                                    <strong>Date:{" " + date + "-" + month + "-" + year}</strong></h4></Col>
                            </Row>}


                        {this.viewRender()}
                    </ThemeProvider>
                    {/* </GridContainer> */}
                </CardBody>
            </Paper>
        )


    }

    viewRender() {
        if (this.state.getData === true) {
            return (
                <div>
                    {this.state.showView === 'CARD_DIVISION' || this.state.showView === 'CARD_DIVISION_LINE_MANAGER' ? this.executorView() : this.initiatorView()}
                </div>
            )
        }
    }

    initiatorView() {
        return (
            <div style={{padding: '10px'}}>
                {/* {this.boxOneRow(this.state.initiatorTitel1,this.state.initiatorData1,this.state.initiatorErrorTitel,this.state.initiatorErrorData,this.state.boxColor)} */}
                {/* {this.boxOneRow(this.state.initiatorTitel2,this.state.initiatorData2,undefined,undefined,this.state.boxColor.reverse())} */}
                <Row>
                    {this.rendarCol(4, this.state.initiatorAccOpTitel, this.state.initiatorAccOpData, 'CASA & TD Opening/Closing', '#CC1624', '243px')}
                    {this.rendarCol(4, this.state.subServiceRequestTitel, this.state.subServiceRequestData, 'Sub Service Request', '#CC1624', '243px')}
                    {this.rendarCol(4, this.state.otherServiceTitel, this.state.otherServiceData, 'Other Service', '#CC1624', '243px')}

                </Row>
                {/* <Row>
               {this.rendarCol(6,this.state.initiatorCardTitel,this.state.initiatorCardData,'Card Maintenance','#7F5A58')}
               {this.rendarCol(3,this.state.initiatorDebitCardTitel,this.state.initiatorDebitCardData,'','#C5C34D')}
               {this.rendarCol(3,this.state.initiatorCityTouchTitel,this.state.initiatorCityTouchData,'','#0D95C1')}
            style={{border: '0.5px solid'}}
            </Row> */}

                <Row>
                    {this.rendarCol(6, this.state.numberOfPendingTitel, this.state.numberOfPendingData, 'Number of Pending Request', '#CC1624', '395px')}
                    <Col md={6}>
                        <div style={{
                            minHeight: '395px',
                            border: '7px solid',
                            borderColor: '#CC1624',
                            borderRadius: '1px'
                        }}>
                            <h5 style={{color: '#000000', fontSize: '20px', textAlign: 'center'}}><strong><u>Deferral
                                Summary</u></strong></h5>
                            <ResponsiveContainer width="100%" minHeight='395px'>
                                <PieChart>
                                    <Pie dataKey="value" isAnimationActive={true} data={this.state.dataForPieChart}
                                        //  cx={300} cy={150}
                                         margin={{
                                             top: 0, right: 0, left: 0, bottom: 0,
                                         }}
                                        //outerRadius="100%"
                                         outerRadius={130}
                                         fill="#8884d8" label={this.handleLabel} onClick={this.clickPie}/>
                                    <Tooltip/>
                                </PieChart>

                            </ResponsiveContainer>

                        </div>

                    </Col>
                    {/* <Col md={6}>
                    <h3 style={{textAlign: 'center'}}>Number of Customer Served on a Day</h3>
                    
                    <BarChart
                        width={500}
                        height={350}
                        data={this.state.data}
                        margin={{
                            top: 0, right: 0, left: 0, bottom: 0,
                        }}
                        padding={{top: 0, right: 0, left: 0, bottom: 0,}}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="name"/>
                        <YAxis/>
                        <Tooltip/>
                        <Legend/>
                        <Bar dataKey="customer" fill="#8884d8" onClick={this.clickBar}/>
                    </BarChart>
                </Col> */}
                </Row>
                <Row>
                </Row>
            </div>
        )
    }

    executorView() {
        return (
            <Container>
                {this.boxOneRow(this.state.executorTitel1, this.state.executorData1, undefined, undefined, this.state.boxColor)}
                {this.boxOneRow(this.state.executorTitel2, this.state.executorData2, undefined, undefined, this.state.boxColor.reverse())}
            </Container>
        )
    }

    unitHeadView() {
        return (
            <Container>
                {this.boxOneRow(this.state.unitHeadTitel1, this.state.unitHeadData1, undefined, undefined, this.state.boxColor)}
                {this.boxOneRow(this.state.unitHeadTitel2, this.state.unitHeadData2, undefined, undefined, this.state.boxColor.reverse())}
            </Container>
        )
    }

    boxOneRow(mapTitel, mapData, mapErrorTitel, mapErrorData, mapColor) {
        return (
            <Row>
                {this.boxFillUp(mapTitel, mapData, mapErrorTitel, mapErrorData, mapColor)}
            </Row>
        )
    }

    boxFillUp(mapTitel, mapData, mapErrorTitel, mapErrorData, mapColor) {

        return mapTitel.map((data, index) => {
            return (
                <Col>
                    <div className={"small-box " + mapColor[index]}>
                        <div className="inner">
                            {/* {mapErrorTitel!=undefined?this.view1(data,mapData[index]):this.view2(data,mapData[index])} */}
                            <h5 style={{color: '#FFFFFF', fontSize: '18px'}}>
                                <strong>{data} : {mapData[index] != null ? mapData[index] : '0'}</strong></h5>
                            <h3>
                                <span>{mapErrorTitel != undefined ? mapErrorTitel[index] : ''}</span> {mapErrorData != undefined ? mapErrorData[index] : ''}
                            </h3>
                        </div>
                    </div>
                </Col>
            )
        });
    }

    view1(titel, data) {
        return (
            <h5 style={{color: '#FFFFFF', fontSize: '18px'}}><strong>{titel} : {data}</strong></h5>
        )
    }

    view2(titel, data) {
        return (
            <h3><span>{titel}</span> : {data}</h3>
        )
    }

    rendarCol(colSpace, titel, data, headerName, backgroundColor, height) {
        const items = [];
        for (const [index, value] of titel.entries()) {
            items.push(
                <div style={{color: '#000000', fontSize: '18px', fontWeight: 'bold', width: '100%'}}>
                    <div style={{float: 'left', width: '70%', overflow: 'hidden'}}>{value}</div>
                    <div style={{float: 'right', width: '30%', overflow: 'hidden'}}>: {data[index]}</div>
                </div>)
        }
        return (
            <Col md={colSpace}>
                <div style={{
                    padding: '10px',
                    width: '100%',
                    minHeight: height,
                    overflow: 'hidden', /* Add vertical scrollbar */
                    marginBottom: '10px',
                    border: '7px solid',
                    borderColor: backgroundColor,
                    borderRadius: '1px'
                }}>
                    <h5 style={{color: '#000000', fontSize: '20px'}}><strong><u>{headerName}</u></strong></h5>
                    {items}
                </div>

            </Col>
        )
    }
}

export default CsDashboard