import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../Common/Constant";
import Functions from '../../../Common/Functions';
import GridList from "@material-ui/core/GridList/index";
import Grid from "@material-ui/core/Grid/index";
import CommonJsonFormComponent from '../../JsonForm/CommonJsonFormComponent';
import axios from 'axios/index';
import Notification from "../../NotificationMessage/Notification";
import {FundTransferJsonFormOne, FundTransferJsonFormTwoReadOnly} from '../WorkflowJsonFormRashed';
import DialogContent from "@material-ui/core/DialogContent/index";
import {Dialog} from "@material-ui/core/index";
import Table from "../../Table/Table";
import CBNotFound from "../CASA/CBNotFound";
import loader from "../../../Static/loader.gif";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import axiosDownload from "../../DeliverableManagement/FunctionForFileDownload";
import SignatureButton from "../Maintenance/SignatureButton";
import MyValidation from "../../JsonForm/MyValidation";
import CommonApi from "../Maintenance/common/CommonApi";
import FullSdnDedupeResult from "../../FullSdnDedupeResult";

let checkerRemarks = [
    {
        "varName": "checkerRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};


class CheckerSplitForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            inputData: {},
            getDeferalList: [],
            varValue: {},
            showValue: true,
            getData: false,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            selectedDate: {},
            errorArray: {},
            errorMessages: {},
            signature: false,
            getRemarks: [],
            cbNotFound: false,
            disabled: false,
            getDownloadListFlag: false,
            downLoadFileList: [],
            fileNotFoundTittle: "",
            errorTittle: "",
            SdnForFundTransfer: false,

        };
    }

    modalClose = () => {
        this.props.closeModal();
    };

    functionForDocument = () => {
        let downLoadFileList = [];
        let url = backEndServerURL + "/case/files/pdfFilesFromImages/" + this.props.appId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((data, index) => {
                    downLoadFileList.push(this.createDownloadFileLink(`Document ${index + 1}`, data))
                });
                console.log(response.data);
                this.setState({
                    getDownloadListFlag: true,
                    downLoadFileList: downLoadFileList
                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    fileNotFoundTittle: error.response.data.message
                })
            })
    };

    createDownloadFileLink = (sl, data) => {
        return (
            <React.Fragment>
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}
                    onClick={() => this.downloadDocument(data)}
                >
                    {sl}
                </button>
                &nbsp;&nbsp;
            </React.Fragment>
        )
    };

    downloadDocument = (name) => {
        let url = backEndServerURL + "/file/" + name + "/" + sessionStorage.getItem("accessToken");
        window.open(url)
    };

    renderDownLoadFileLink = () => {
        if (this.state.getData && this.state.getDownloadListFlag) {
            return (this.state.downLoadFileList.map((button) => {
                    return button
                })
            )
        } else {
            return <h5><b>{this.state.fileNotFoundTittle}</b></h5>
        }
    };

    componentDidMount() {
        CommonApi.getRemarkDetails(this.props.appId)
            .then((remarksArray) => {
                this.setState({
                    getRemarks: remarksArray
                })
            })
            .catch((error) => {
                console.log(error)
            });

        CommonApi.getDeferralDetails(this.props.appId)
            .then((tableArray) => {
                this.setState({
                    getDeferalList: tableArray
                });
                CommonApi.getFiles(this.props.appId).then((fileData) => {
                    this.setState({
                        getImageLink: fileData,
                        getImageBoolean: true
                    });
                })
            })
            .catch((error) => {
                console.log(error);
            });

        this.functionForDocument();

        CommonApi.getVariables(this.props.appId)
            .then((response) => {
                this.functionForFileGet();
                let inputData = {...response.data};
                inputData.source = "Finacle"
                if (inputData.source === "Ababil") {
                    CommonApi.ababilAccountDetails(response.data.accountNumber).then((response) => {
                        inputData.accountBalance = response.data.acctEftvBalance;
                        this.setState({
                            inputData: inputData,
                            varValue: inputData,
                            getData: true,
                            loading: false
                        });
                    }).catch((error) => {
                        this.setState({
                            cbNotFound: true,
                            title: error.response.data.message,
                            loading: false
                        })
                    });
                } else {
                    CommonApi.getCustAccountDetails(response.data.accountNumber).then((response) => {
                        inputData.accountBalance = response.data.acctEftvBalance;
                        this.setState({
                            inputData: inputData,
                            varValue: inputData,
                            getData: true,
                            loading: false
                        });
                    }).catch((error) => {
                        this.setState({
                            cbNotFound: true,
                            title: error.response.data.message,
                            loading: false
                        })
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    cbNotFound: true,
                    title: error.response.data.message,
                    loading: false
                })
            })
    }

    functionForFileGet = () => {
        let imageUrl = backEndServerURL + "/case/files/fund-transfer-maker-upload/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    documentList: response.data,
                    getDocument: true
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, checkerRemarks, this.updateComponent)
            )
        }
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks</b>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>

                </div>
            )
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderFormWithData = () => {
        if (this.state.getData) {
            return (
                <Grid container>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, FundTransferJsonFormOne, this.updateComponent)
                    }
                    {
                        this.state.inputData.requestType === "Single Transaction" && CommonJsonFormComponent.renderJsonForm(this.state, FundTransferJsonFormTwoReadOnly, this.updateComponent)
                    }
                </Grid>
            )
        }
    };

    functionForHandleSubmit = () => {
        this.setState({
            loading: true,
            disabled: true
        });
        let variablesUrl = backEndServerURL + "/variables/" + this.props.appId;

        let postData = this.state.inputData;
        postData.checker_approval = "APPROVED";

        axios.post(variablesUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.checkerRemarks + "/" + this.props.appId;
                axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data)
                    })
                    .catch((error) => {
                        this.setState({
                            loading: false,
                            disabled: false
                        });
                        console.log(error)
                    });
                this.state.inputData.checkerRemarks = undefined;
                if (this.state.inputData.requestType === "Single Transaction") {
                    let apiUrl = this.state.inputData.transferType === "BEFTN" ? backEndServerURL + "/intgr/beftnTransaction/" + this.props.appId : backEndServerURL + "/intgr/rtgsTransaction/" + this.props.appId;
                    axios.post(apiUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            if (response.status === 200) {
                                this.caseRoute()
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false,
                                cbNotFound: true,
                                disabled: false,
                                title: error.response.data.message
                            });
                        });
                } else {
                    this.caseRoute();
                }

            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    disabled: false
                });
                console.log(error)
            })
    };

    approveHandler = () => {

        if (this.state.inputData.requestType === "Single Transaction") {
            let error = MyValidation.defaultValidation(FundTransferJsonFormOne, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            } else {
                this.functionForHandleSubmit()
            }
        } else {
            this.functionForHandleSubmit()
        }

    };

    caseRoute = () => {
        let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
        axios.get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.modalClose();
                    this.setState({
                        loading: false,
                        title: "Successful!",
                        notificationMessage: "Approved Successfully!",
                        alert: true,
                        disabled: false
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    disabled: false,
                    loading: false
                });
                console.log(error)
            })
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    returnHandler = (e) => {
        e.preventDefault();
        let dependencyField = [
            {varName: "checkerRemarks", type: "textArea", required: true}
        ];
        let error = MyValidation.defaultValidation(dependencyField, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                disabled: true
            });

            let postData = {...this.state.inputData};
            postData.checker_approval = "NOTAPPROVED";
            let remarksData = {};
            remarksData.remark = postData.checkerRemarks;
            remarksData.map = {...postData, checkerRemarks: undefined};
            let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
            axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                this.setState({
                    disabled: false,
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                    loading: false
                });
                this.props.closeModal()
            }).catch((error) => {
                console.log(error);
                this.setState({
                    disabled: false,
                    loading: false
                })
            });
        }
    };

    functionForExlDownload = (document) => {
        let download_url = backEndServerURL + "/file/" + document;
        axiosDownload.downloadFile(download_url, 'GET', {}, document);
    };

    renderBulkFileTwo = () => {
        if (this.state.getDocument && this.state.documentList.length > 0 && this.state.inputData.customerType) {
            return (
                this.state.documentList.map((document, index) => {
                    return (
                        <React.Fragment>
                            <button className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'middle',
                                        marginTop: '12px',
                                        marginRight: '10px',
                                    }}
                                    onClick={() => this.functionForExlDownload(document)}
                            >Maker File {index + 1}</button>

                        </React.Fragment>
                    )
                })
            );
        }
    };


    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    signature = () => {
        if (this.state.getData) {
            return <SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
                                    requestType={this.state.inputData.source} classes={this.props}/>;
        }
    };

    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <>
                    <br/>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Deferral List:</b>
                            <Table

                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                                tableData={this.state.getDeferalList}
                                tableAllign={['left', 'left']}
                            />
                        </div>
                    </div>
                </>
            )
        }
    };

    handleSDNSearchButton = () => {
        if (this.state.inputData.transferType === "RTGS") {
            return (<div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={(event) => this.handleChangeSdnResult(event)}
                    >
                        SDN View
                    </button>
                </div>
            )
        }
    }
    handleChangeSdnResult = (event) => {
        event.preventDefault();
        this.setState({
            SdnForFundTransfer: true
        })

    };
    handleClose = (event) => {
        event.preventDefault();
        this.closeModalForSdnResult()
    }
    closeModalForSdnResult = () => {
        this.setState({
            SdnForFundTransfer: false
        })
    }

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);

        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>

                    <Dialog
                        fullWidth="true"
                        maxWidth="md"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.SdnForFundTransfer}>
                        <DialogContent className={classes.dialogPaper}>
                            <FullSdnDedupeResult category={this.props.category}
                                                 subServiceType={'INDIVIDUAL'} appId={this.props.appId}/>
                        </DialogContent>
                        <center>
                            <button
                                className="btn btn-outline-primary  btn-sm"
                                style={{}}
                                onClick={(event) => this.handleClose(event)}
                            >Close
                            </button>
                        </center>
                    </Dialog>

                    <ThemeProvider theme={theme}>
                        <Grid container spacing={0}>
                            {this.renderFormWithData()}

                            <Grid item xs={12}>
                                {this.signature()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.handleSDNSearchButton()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderDownLoadFileLink()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderBulkFileTwo()}
                            </Grid>

                            <Grid item xs={12}>
                                {this.renderDefferalData()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderRemarksData()}
                                <br/>
                                {this.renderRemarks()}
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    marginRight: '10px',
                                    marginTop: '10px',
                                }}
                                disabled={this.state.disabled}
                                onClick={this.approveHandler}
                            >
                                Submit
                            </button>
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    marginTop: '10px',
                                }}
                                disabled={this.state.disabled}
                                onClick={this.returnHandler}
                            >
                                Return
                            </button>
                            {this.renderNotification()}
                        </Grid>
                    </ThemeProvider>
                </div>
            </GridList>
        )
    }
}

export default withStyles(styles)(CheckerSplitForm);
