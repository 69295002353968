import React, {Component} from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import LabelDetailsModal from "../static/LabelDetails";
import {Dialog} from "@material-ui/core";
import Box from '@material-ui/core/Box';
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";

class Label extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            inputData: {},
            inputValue: {},
            showValue: false,
            labelData: [],
            linkedCB: [],
            getData: false,
            citygem: "",
            deferralStatus: "",
            unusedChequeBook: ""
        }
    }


    componentDidMount() {
        console.log(this.props.input);
        let url = backEndServerURL + "/getCustomerCIFDetails/" + this.props.input.cbNumber;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.state.citygem = response.data.freecode1;
            })
            .catch((error) => {
                console.log(error);
            });

        let unusedChequeBook = backEndServerURL + "/chequebooks/getUnusedNumber/" + this.props.input.accountNumber;
        axios.get(unusedChequeBook, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.state.unusedChequeBook = (response.data && response.data > 0) ? "Yes" : "No";
            })
            .catch((error) => {
                console.log(error);
            });
        let deferralUrl = backEndServerURL + "/deferral/getByAccount/" + this.props.input.accountNumber;
        axios.get(deferralUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.state.deferralStatus = response.data.length > 0 ? "Yes" : "No";
            })
            .catch((error) => {
                console.log(error);
                this.props.stopLoading(error);
            });

        let getDataByAc = backEndServerURL + "/GetCustomAcctDetailsRequestWithAccountNumberList/" + this.props.input.accountNumber;
        axios.get(getDataByAc, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let labelData = [
                    {
                        name: 'CB Number', value: this.props.input.cbNumber
                    },
                ];

                if (response.status === 200) {
                    if (response.data.acctName) {
                        labelData.push({name: 'Customer Name', value: response.data.acctName})
                    }
                    if (this.props.input.schemeCode) {
                        labelData.push({name: 'CASA Schema (Product Code)', value: this.props.input.schemeCode})
                    }
                    if (response.data.solId) {
                        labelData.push({name: 'Mother Branch', value: response.data.solId})
                    }
                    if (this.state.citygem) {
                        labelData.push({name: 'Citygem Center Name', value: this.state.citygem})
                    }
                    if (response.data.acctBalance && this.props.input.schemeCode && this.props.input.schemeCode !== "SBSTF") {
                        labelData.push({name: 'CASA Balance', value: response.data.acctBalance})
                    }
                    if (this.props.input.eTinNumber) {
                        labelData.push({name: 'E-TIN status', value: "Yes"})
                    }
                    if (response.data.lienDetails && response.data.lienDetails.length > 0) {
                        labelData.push({name: 'Lien', value: "", popup: true, popupValue: 'Lien'})
                    }
                    if (response.data.freezeCode || response.data.freezeReasonCode) {
                        labelData.push({name: 'Freeze status', value: "", popup: true, popupValue: 'Freeze'})
                    }
                    if (response.data.acctStatus === "D") {
                        labelData.push({name: 'Dormancy', value: "", popup: true, popupValue: 'Dormancy'})
                    }
                    if (response.data.freeText12) {
                        labelData.push({name: 'CTR', value: "", popup: true, popupValue: 'CTR'})
                    }
                    if (response.data.LinkedTDDetails && response.data.LinkedTDDetails.length > 0) {
                        labelData.push({name: "FDR/DPS", value: "", popup: true, popupValue: 'FDR'})
                    }
                    if (response.data.acctStatus) {
                        labelData.push({name: 'Customer Status', value: "", popup: true, popupValue: 'ADUP'})
                    }
                    if (response.data.linkedLoanDetails && response.data.linkedLoanDetails.length > 0) {
                        labelData.push({name: 'Loan Account', value: "", popup: true, popupValue: 'Loan'})
                    }
                    labelData.push({name: 'SI', value: ""});
                    if (this.state.deferralStatus) {
                        labelData.push({name: 'Deferral Status', value: this.state.deferralStatus});
                    }
                    if (this.state.unusedChequeBook) {
                        labelData.push({name: 'Unused Cheque Book', value: this.state.unusedChequeBook});
                    }
                    setTimeout(() => {
                        this.setState({
                            labelData: labelData,
                            inputValue: response.data,
                            showValue: true,
                            getData: true,
                        }, this.parentLoad);
                    }, 1000)
                }
            })
            .catch((error) => {
                console.log(error);
                this.props.stopLoading(error)
            });
    }


    parentLoad = () => {
        if (this.props.stopLoading !== undefined) {
            this.props.stopLoading("yes")
        }
    };

    labelDetails = (status) => {
        this.setState({
            labelName: status,
            labelOpen: true
        })
    };

    labelCloseModal = () => {
        this.setState({
            labelOpen: false
        })
    };

    render() {
        const {classes} = this.props.classes;

        if (this.state.labelData.length > 0 && this.state.getData) {
            return (

                <div style={{width: '100%'}}>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="flex-start"
                         bgcolor="background.paper"
                         style={{marginTop: "10px", borderTop: "2px solid #000", borderBottom: "2px solid #000"}}>
                        {
                            this.state.labelData.map((label) => {
                                return (
                                    <Box p={1} bgcolor="background.paper">
                                        <label><font size="2"><b
                                            style={{color: label.popupValue ? "red" : "black"}}>
                                            {label.popupValue ? <a onClick={(event) => {
                                                this.labelDetails(label.popupValue)
                                            }}>{label.name}</a> : label.name} {label.popupValue ? "" : ": "}
                                        </b></font></label>
                                        {label.popupValue ? "" : label.value}
                                    </Box>

                                )
                            })
                        }
                    </Box>
                    <Dialog
                        fullWidth="true"
                        maxWidth="md"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.labelOpen}>
                        <DialogContent className={classes.dialogPaper}>
                            <LabelDetailsModal closeModal={this.labelCloseModal} appId={this.state.appId}
                                               labelName={this.state.labelName} data={this.state.labelData}
                                               inputValue={this.state.inputValue}
                                               accountNumber={this.props.accountNumber}
                                               crmStatus={this.props.input.crmCustomerStatus}
                                               cbNumber={this.props.cbNumber} create={this.props.create}/>
                        </DialogContent>
                    </Dialog>
                </div>
            );
        } else {
            return ""
        }
    }


}

export default Label;