import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import {Redirect} from "react-router-dom";
import '../../Static/css/login.css';
import FormSample from "../JsonForm/FormSample";
import Notification from "../NotificationMessage/Notification";
import {backEndServerURL} from "../../Common/Constant";
import axios from 'axios';
import Functions from '../../Common/Functions';

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        background: '#f6f1ff'
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 400,
    },
});


const jsonForm = {
    "variables": [


        {
            "varName": "userSearch",
            "type": "dropdownSearch",
            "label": "User Search",

        },
        {
            "varName": "selectBranch",
            "type": "dropdownSearch",
            "label": "Select Branch",


        },
        {
            "varName": "selectGroup",
            "type": "select",
            "label": "Select Group",

            "enum": [
                "CS",
                "BOM",
                "BM"
            ]

        },
        {
            "varName": "region",
            "type": "select",
            "label": "Region",

            "enum": [
                "DHAKA",
                "CHITTAGONG",

            ]

        },


    ],

};

class BranchFlowConfigureUpdateDelete extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            existGroup: false,
            dropdownSearchData: [],
            branchGetData: false,
            branchAllData: [],
            userALlData: [],
            title: "",
            notificationMessage: "",

            alert: false,
            redirectLogin: false,

        }

    }

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    getSubmitedForm = (object) => {


        console.log(object);


        /*   //Existing All Group
           let urlExistGroup = backEndServerURL + '/api/1.0/workflow/groups';
           let authHeaderExistGroup = "Bearer " + cookie.load("accessToken");

           axios.get(urlExistGroup,
               {headers: {"Authorization": authHeaderExistGroup}})
               .then((response) => {


                   let filteredData = response.data.filter(function (group) {
                       return (group.grp_title === "BRANCH_" + object.selectBranch + "_" + object.selectGroup);
                   });
                   let groupUid;
                   filteredData.map((g)=>{
                       groupUid=g.grp_uid;
                   })

                  /!* if (filteredData.length != 0) {

                       let urls = backEndServerURL + '/api/1.0/workflow/group/' + groupUid+ '/user';
                       let authHeader = "Bearer " + cookie.load("accessToken");

                       axios.post(urls, {

                               "usr_uid": object.userSearch
                           },
                           {headers: {"Authorization": authHeader}})
                           .then((response) => {
                               this.setState({
                                   existGroup: true
                               })
                               //console.log("successfully assign user to group");


                           })
                           .catch((error) => {
                               console.log(error);
                           })

                   }




                   //Create new group
                   else if (filteredData.length === 0) {

                       let urls = backEndServerURL + '/api/1.0/workflow/groups';
                       let authHeader = "Bearer " + cookie.load("accessToken");

                       axios.post(urls, {
                               "grp_title": "BRANCH_" + object.selectBranch + "_" + object.selectGroup,
                               "grp_status": "ACTIVE"
                           },
                           {headers: {"Authorization": authHeader}})
                           .then((response) => {
                               //assign user to group

                               let groupUid = response.data.grp_uid;
                               let urls = backEndServerURL + '/api/1.0/workflow/group/' + groupUid + '/user';
                               let authHeader = "Bearer " + cookie.load("accessToken");

                               axios.post(urls, {

                                       "usr_uid": object.userSearch
                                   },
                                   {headers: {"Authorization": authHeader}})
                                   .then((response) => {
                                       //console.log("successfully assign user to group");


                                   })
                                   .catch((error) => {
                                       console.log(error);
                                   })


                               console.log("successfully group created");
                               //assign user to group

                           })

                   } else {

                   }
   *!/

               })
   */

        //Assign user to cs,Bom,Bm group
        /*  let GroupUid;
          if (object.selectGroup === "CS") {
              GroupUid = "3601057905cc1a272f0d777027912947";
          } else if (object.selectGroup === "BOM") {
              GroupUid = "4437645805cc1a277d6c462094429464";
          } else if (object.selectGroup === "BM") {
              GroupUid = "2348915135cc1a27cb92932023967414";
          }
          let urls = backEndServerURL + '/api/1.0/workflow/group/' + GroupUid + '/user';
          let authHeader = "Bearer " + cookie.load("accessToken");

          axios.post(urls, {

                  "usr_uid": object.userSearch
              },
              {headers: {"Authorization": authHeader}})
              .then((response) => {
                  //console.log("successfully assign user to group");


              })
              .catch((error) => {
                  console.log("Not assign,,already exist");
              })
  */


        let getbranchId;
        let getbranchName;
        if (!(Array.isArray(object.selectBranch))) {

            this.state.branchAllData.map((data) => {
                if (data.label === object.selectBranch) {
                    getbranchId = data.value[0];
                    getbranchName = data.value[1]
                } else {

                }
            })
        }
        if (Array.isArray(object.selectBranch)) {
            this.state.branchAllData.map((data) => {
                if (data.label === object.selectBranch[1]) {
                    getbranchId = data.value[0];
                    getbranchName = data.value[1]
                } else {

                }
            })
        }

        let getUserId;
        let getUserName;
        if (!(Array.isArray(object.userSearch))) {
            this.state.userALlData.map((user) => {

                if (user.user === object.userSearch) {
                    getUserId = user.value[0];
                    getUserName = user.value[1]
                } else {

                }
            })
        }
        if (Array.isArray(object.userSearch)) {

            this.state.userALlData.map((user) => {

                if (user.user === object.userSearch[0]) {

                    alert(user.value[0]);
                    getUserId = user.value[1];
                    getUserName = user.value[0]
                } else {

                }
            })
        }


        //backend api call
        let url = backEndServerURL + "/processFlowMaster/update/" + this.props.id;

        axios.post(url, {
            "globalGroupName": object.selectGroup,
            "localGroupId": "BRANCH_" + object.selectBranch[0] + "_" + object.selectGroup,
            "userUid": object.userSearch[0],
            "userName": object.userSearch[1],
            "region": object.region,
            "branchId": object.selectBranch[0],
            "branchName": object.selectBranch[1],
            "status": "ACTIVE"

        }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.props.close();
                this.setState({
                    title: "Sucessfully!!",
                    notificationMessage: "Sucessfully Updated",
                    alert: true
                });

                console.log("Succesffully added");


            })
            .catch((error) => {
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
                console.log("Not assign,already exist");
            })


    };

    handlesubmit = () => {
        this.setState({
            redirect: true
        });

    };
    renderRedirect = () => {
        if (this.state.redirect) {
            return (<Redirect to="/newaccountsearchResult"/>);
        }

    };

    componentDidMount() {
//get single edit data
        let urlget = backEndServerURL + "/getProcessFlowMaster/get/" + this.props.id;
        axios.get(urlget, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                let varValue = [];

                varValue["selectGroup"] = response.data.globalGroupName;
                varValue["userSearch"] = response.data.userName;
                varValue["region"] = response.data.region;
                varValue["selectBranch"] = response.data.branchName;
                varValue["status"] = response.data.status;
                this.setState({
                    varValue: varValue,
                    branchGetData: true

                })
            })
            .catch((error) => {
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
                console.log(error);
            });


        const getBranch = [];
        let dropdownData = [];
        let url = backEndServerURL + "/branchMaster/getAll";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                let data = [];

                response.data.map((branch) => {
                    getBranch.push(branch.id, branch.branchName);

                    let object = {
                        "label": branch.branchName,
                        "value": [branch.id, branch.branchName]
                    };
                    data.push(object);

                });


                dropdownData["selectBranch"] = data;


            })
            .catch((error) => {
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
                console.log(error);
            });


        let urls = backEndServerURL + '/user/get/all';

        axios.get(urls,
            {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                let useData = [];
                response.data.map((user) => {
                    let objectUser = {
                        "label": user.username,
                        "value": [user.id, user.username]
                    };
                    useData.push(objectUser);
                });


                dropdownData["userSearch"] = useData;

                this.setState({
                    dropdownSearchData: dropdownData
                })

            })
            .catch((error) => {
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
                console.log(error);

            })
    }

    renderEditForm = () => {
        if (this.state.branchGetData) {
            return (
                <FormSample dropdownSearchData={this.state.dropdownSearchData} showValue={true}
                            secondButtonFunction={this.props.secondButtonFunction}
                            secondButtonName={this.props.secondButtonName} varValue={this.state.varValue} grid="12"
                            buttonName="Submit"
                            onSubmit={this.getSubmitedForm} jsonForm={jsonForm}/>
            )
        }

    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (

            <GridContainer>
                {
                    this.renderRedirect()

                }
                {
                    this.renderNotification()

                }

                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>Branch Process Flow</h4>
                        </CardHeader>
                        <CardBody>

                            <div className={classes.root}>
                                {this.renderEditForm()}
                            </div>


                        </CardBody>
                    </Card>
                </GridItem>

            </GridContainer>

        );
    }
}

export default withStyles(styles)(BranchFlowConfigureUpdateDelete);
