import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import TextFieldComponent from "../../../JsonForm/TextFieldComponent";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import SelectComponent from "../../../JsonForm/SelectComponent";
import Table from "../../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import Notification from "../../../NotificationMessage/Notification";
import loader from "../../../../Static/loader.gif";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import MyValidation from "../../../JsonForm/MyValidation";
import SignatureButton from "../SignatureButton";
import Label from "./Label";
import UploadComponent from "../casa/UploadComponent";
import CBNotFound from "../../CASA/CBNotFound";
import DateComponentDeferal from "../../../JsonForm/DateComponentDeferal";
import CheckBox from "@material-ui/core/Checkbox/Checkbox";
import GridContainer from "../../../Grid/GridContainer";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";
import {csBearer, maintenanceFirst} from "./JsonForCasaMaintenance"
import CheckList from "./CheckList";
import MaintenanceUtility from "./MaintenanceUtility";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButton";
import axiosDownload from "../../../DeliverableManagement/FunctionForFileDownload";
import OtpComponent from "../common/OtpComponent";

const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};
var deferalOther = {
    "varName": "deferalOther",
    "type": "text",
    "label": "Others",
};
var deferal = {
    "varName": "deferalType",
    "type": "select",
    "label": "Deferral Type",
    "enum": [
        "Applicant Photograph",
        "Nominee Photograph",
        "Passport",
        "Address proof",
        "Transaction profile",
        "Others"
    ],

};
var date = {
    "varName": "expireDate",
    "type": "text",
    "minDate": true,
    "label": "Expire Date",

};
let accountMaintenanceSearch = [
    {
        "varName": "eqmNumber",
        "type": "text",
        "validation": "numeric",
        "label": "EQM Number",
        "grid": 2,

    },
    {
        "varName": "accountNumber",
        "type": "textDedup",
        "validation": "numeric",
        "required": true,
        "label": "Account No",
        "grid": 2,

    }
];
const maintenanceRequestType = [
    {title: 'E Tin Update'},
    {title: 'NID Card Number update'},
    {title: 'Smart Card Number update'},
    {title: 'Designated Contact Number Change'},
    {title: 'Email Address Update'},
    {title: 'E-statement Enrollment'},
    // {title: 'Currency Add'},
    {title: 'Nominee Update'},
    {title: 'Address Change'},
    {title: 'Title Change and Signature Card Update'},
    {title: 'Other Photo ID Change'},
    {title: 'Other Contact Number Change'},
    {title: 'Other Information Change'},
    {title: 'Data Update'},
    {title: 'Signature Card Update'},
    {title: 'TP KYC'},
     {title: 'New SI Creation'},
    {title: 'Modify Existing SI'},
    {title: 'Delete Existing SI'},
    {title: 'Account Freeze Mark'},
    {title: 'Account Unfreeze Mark'},
    {title: 'Deceased Mark'},
    {title: 'Proof of Submission of Return (PSR) Update'}
];
const maintenanceRequestTypeForDormant = [
    {title: 'Dormant Account Activation'},
    {title: 'Dormant Account Activation And Data Update'},
];

let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];

class AccountMaintenance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: null,
            tableData: [],
            cbNotFound: false,
            inputData: {},
            values: [],
            appId: '',
            loading: true,
            getData: false,
            getNewCase: false,
            varValue: {},
            caseId: 0,
            title: "",
            notificationMessage: "",
            alert: false,
            redirectLogin: false,
            selectedDate: {},
            showValue: false,
            getDeferalList: [],
            deferalNeeded: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            selectedRequest: [],
            returnData: false,
            returnDataBm: false,
            getNumberofNominee: false,
            objectForNominee: [],
            objectForExistingNominee: [],
            getNominee: false,
            getExistingNominee: false,
            numberOfMultipleSelect: 0,
            labelData: [],
            objectForInitialNewNominee: [],
            getInitialNewNominee: 0,
            customerDetails: {},
            disabled: false,
            fileUploadData: [],
            city: [],
            state: [],
            country: [],
            guardianCode: [],
            relationCode: [],
            currency: [],
            masterDataTitle: [],
            constitution: [],
            maritalStatus: [],
            emailType: [],
            phoneNumCountryCodeList: [],
            sectorCode: [],
            subSectorCode: [],
            confirmation: false,
            checkList: false,
            placeOfIssueList: [],
            constitutionList: [],
            occupationCodeList: [],
            employmentType: [],
            return_cs_bearer: "",
            return_bearer_approval: ""
        }
    }

    getSearchvalue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === undefined || clone[prop] === null || clone[prop] === "undefined" || clone[prop] === "null" || clone[prop] === "-1")
                delete clone[prop];
        return clone;
    };

    handleChange = (event, value) => {

        this.state.inputData["csDeferal"] = value;
        this.updateComponent();
        if (value === "YES") {

            let values = [];
            values.push(Math.floor(Math.random() * 100000000000));

            this.setState({values: values, deferalNeeded: true});

        } else {
            this.setState({
                values: [],
                deferalNeeded: false
            })
        }
    };

    addDeferalForm() {


        return this.state.values.map((el, i) =>
            <React.Fragment>
                <Grid item xs={3}>
                    {
                        this.dynamicDeferral(el)
                    }
                </Grid>

                <Grid item xs={3}>
                    {
                        this.dynamicDate(el)
                    }

                </Grid>
                <Grid item xs={3}>
                    {this.dynamicDeferralOther(el)}

                </Grid>
                <Grid item xs={3}>
                    <button
                        style={{
                            float: "left",
                            marginTop: "15px"
                        }}

                        className="btn btn-outline-primary btn-sm"
                        type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                    >
                        Remove
                    </button>


                </Grid>
            </React.Fragment>
        )

    }

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    componentDidMount() {
        var remarksArray = [];
        let occupationCodeUrl = backEndServerURL + "/occupationCode/getAll";
        axios.get(occupationCodeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.findByVarNameApiData("occupationCodeCB", response.data);
                this.findByVarNameApiData("occupationCodeAccount", response.data);
                this.setState({
                    occupationCodeList: response.data
                })
            })
            .catch((error) => {
                console.log(error);
            });

        this.dynamicApiCallForNomineeUpdate("city", "city");
        this.dynamicApiCallForNomineeUpdate("state", "state");
        this.dynamicApiCallForNomineeUpdate("country", "country");
        this.dynamicApiCallForNomineeUpdate("guardianCode", "guardianCode");
        this.dynamicApiCallForNomineeUpdate("relationCode", "relationCode");
        this.dynamicApiCallForNomineeUpdate("currency", "currency");
        this.dynamicApiCallForNomineeUpdate("emailType", "emailType");
        this.dynamicApiCallForNomineeUpdate("phoneCountryCode", "phoneCountryCode");
        this.dynamicApiCallForNomineeUpdate("title", "title");
        this.dynamicApiCallForNomineeUpdate("constitution", "constitution");
        this.dynamicApiCallForNomineeUpdate("maritalStatus", "maritalStatus");
        this.dynamicApiCallForNomineeUpdate("sectorCode", "sectorCode");
        this.dynamicApiCallForNomineeUpdate("subSectorCode", "subSectorCode");
        this.dynamicApiCallForNomineeUpdate("placeOfIssue", "placeOfIssue");
        this.dynamicApiCallForNomineeUpdate("constitution", "constitution");
        this.dynamicApiCallForNomineeUpdate("employmentType", "employmentType");
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    if (response.data.csBearer === "YES" && response.data.cs_bearer === "NO") {
                        this.setState({
                            return_cs_bearer: "NO",
                            return_bearer_approval: response.data.bearerApproval
                        });
                    }
                    let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                    axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            if (response.data.length > 0) {
                                this.setState({
                                    fileUploadData: response.data,
                                })
                            }

                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            let tableArray = [];
                            var status = "";
                            response.data.map((deferal) => {
                                if (deferal.status === "ACTIVE") {
                                    status = "Approved"
                                } else if (deferal.status === "NOTAPPROVED") {
                                    status = "NOT APPROVED"
                                } else {
                                    status = deferal.status;
                                }
                                tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));
                            });
                            this.setState({
                                getDeferalList: tableArray
                            });

                            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {


                                    response.data.map((data) => {

                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                    });
                                    this.setState({
                                        getRemarks: remarksArray
                                    })
                                })
                                .catch((error) => {
                                    console.log(error)
                                })


                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    this.state.inputData["csDeferal"] = "NO";
                    let varValue = response.data;
                    if (response.data.checker_approval === "SEND_TO_CS" || response.data.maker_approval === "RETURN") {
                        response.data.freeFlag1.indexOf("Nominee Update") !== -1 ? varValue.nomineeChange = response.data.nomineeChange : varValue.nomineeChange = false;
                    }
                    this.setState({
                        inputData: varValue,
                        varValue: this.getSearchvalue(varValue),
                        numberOfMultipleSelect: response.data.numberOfMultipleSelect,
                        appData: response.data,
                        getData: true,
                        showValue: true,
                        appId: this.props.appId,
                        searchData: true,
                        getNewCase: true,
                        loader: false,
                        // loading: false
                    });

                })
                .catch((error) => {
                    console.log(error);

                });
        } else {
            this.setState({
                loading: false
            })
        }
    }

    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = maintenanceFirst;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                maintenanceFirst[i].enum = getValue;
            }
        }
    };

    dynamicApiCallForNomineeUpdate = (enumType, apiType) => {
        let apiUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(apiUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    if (enumType === "city") {
                        this.setState({
                            city: response.data
                        })
                    }
                    if (apiType === "phoneCountryCode") {
                        this.findByVarNameApiData("commPhoneLocalCode", response.data);
                        this.setState({
                            phoneNumCountryCodeList: response.data
                        })
                    }
                    if (enumType === "emailType") {
                        this.setState({
                            emailType: response.data
                        })
                    }
                    if (enumType === "state") {
                        this.setState({
                            state: response.data
                        })
                    }
                    if (enumType === "country") {
                        this.setState({
                            country: response.data
                        })
                    }
                    if (enumType === "guardianCode") {
                        this.setState({
                            guardianCode: response.data
                        })
                    }
                    if (enumType === "relationCode") {
                        this.setState({
                            relationCode: response.data
                        })
                    }
                    if (enumType === "title") {
                        this.setState({
                            masterDataTitle: response.data
                        })
                    }
                    if (enumType === "constitution") {
                        this.setState({
                            constitution: response.data
                        })
                    }
                    if (enumType === "maritalStatus") {
                        this.findByVarNameApiData("maritalStatus", response.data);
                        this.setState({
                            maritalStatus: response.data
                        })
                    }
                    if (enumType === "currency") {
                        this.findByVarNameApiData("newCurrency", response.data);
                        this.setState({
                            currency: response.data
                        })
                    }
                    if (enumType === "sectorCode") {
                        this.findByVarNameApiData("sectorCode", response.data);
                        this.setState({
                            sectorCode: response.data
                        })
                    }
                    if (enumType === "subSectorCode") {
                        this.findByVarNameApiData("subSectorCode", response.data);
                        this.setState({
                            subSectorCode: response.data
                        })
                    }
                    if (apiType === "placeOfIssue") {
                        this.findByVarNameApiData("passportNumberplaceOfIssue", response.data);
                        this.setState({
                            placeOfIssueList: response.data
                        })
                    }
                    if (apiType === "constitution") {
                        this.findByVarNameApiData("constitutionCode", response.data);
                        this.setState({
                            constitutionList: response.data
                        })

                    }
                    if (apiType === "employmentType") {
                        this.findByVarNameApiData("profession", response.data);
                        this.setState({
                            employmentType: response.data
                        })
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
    };

    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.renderStartCase(event);

        }

    };

    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;

        if (value && (typeof value !== "string")) {
            return value
        } else {
            for (let i = 0; i < jsonArray.length; i++) {
                let jsonObject = jsonArray[i];
                if (value === jsonObject.key) {
                    return jsonObject;
                }
            }
        }
    };

    renderStartCase = (e) => {
        e.preventDefault();
        let error = MyValidation.defaultValidation(accountMaintenanceSearch, this.state);
        this.forceUpdate();
        if (error) {
            return 0;
        } else {
            this.setState({
                numberOfMultipleSelect: 0,
                disabled: false,
                err: false,
                errorArray: {},
                errorMessages: {},
                searchData: false,
                getData: false,
                loader: true,
                loading: true,
            });

            let getAccountUrl = backEndServerURL + "/getAccountInfo/" + this.state.inputData.accountNumber;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.data.message === "No Account Found!") {
                        this.state.varValue['eqmNumber'] = this.state.inputData.eqmNumber;
                        this.state.varValue['accountNumber'] = this.state.inputData.accountNumber;
                        this.setState({
                            searchData: false,
                            getData: false,
                            loader: false,
                            loading: false,
                            cbNotFound: true,
                            title: 'No Account Found!'
                        });
                    }
                    let inputData = {...response.data};
                    let getCbType = backEndServerURL + "/getCbTypes/" + inputData.cbNumber;
                    axios.get(getCbType, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((cbTypeResponse) => {
                            if (cbTypeResponse.data === "RETAIL") {
                                inputData.uploadFileAlertRequest = true;
                                inputData.customerName = response.data.customerName;
                                inputData.freeFlag3 = response.data.solid;
                                inputData.oldEStatementFacility = response.data.despatchmode === "E" ? "YES" : "NO";
                                inputData.eStatementFacility = response.data.despatchmode === "E" ? "YES" : "NO";
                                inputData.sectorCode = this.findByVarNameGetKeyValue(response.data.sectorcode, this.state.sectorCode);
                                inputData.subSectorCode = this.findByVarNameGetKeyValue(response.data.subsectorcode, this.state.subSectorCode);
                                inputData.occupationCodeAccount = response.data.occupationcode;
                                if (response.data.nomineeInfoDetails) {
                                    inputData.nomineeNumberOld = response.data.nomineeInfoDetails.length;
                                    let sl = 0;
                                    let nomineeInfo = response.data.nomineeInfoDetails;
                                    for (let i = 0; i < nomineeInfo.length; i++) {
                                        sl = i + 1;
                                        inputData["nomineecifId" + sl] = (response.data.nominationInfoList !== undefined && response.data.nominationInfoList.length > 0) ? response.data.nominationInfoList[i].nomineecifid : "";
                                        inputData["nomineeRegNo"] = nomineeInfo[i].regNum;
                                        inputData["nomineeName" + sl] = nomineeInfo[i].nomineeName;
                                        inputData["oldNomineeName" + sl] = nomineeInfo[i].nomineeName;
                                        inputData["nomineeRelationship" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].relType, this.state.relationCode);
                                        inputData["percentageNominee" + sl] = nomineeInfo[i].nomineePercent;
                                        inputData["nomineeAddress1" + sl] = nomineeInfo[i].addr1;
                                        inputData["nomineeAddress2" + sl] = nomineeInfo[i].addr2;
                                        inputData["nomineeCityCode" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].city, this.state.city);
                                        inputData["nomineeStateCode" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].stateProv, this.state.state);
                                        inputData["nomineePostalCode" + sl] = nomineeInfo[i].postalCode;
                                        inputData["nomineeCountry" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].country, this.state.country);
                                        inputData["photoIdNumberRegNo" + sl] = nomineeInfo[i].addr3;
                                        let date = nomineeInfo[i].nomineeBirthDt ? nomineeInfo[i].nomineeBirthDt.split("T")[0].split("-") : "";
                                        inputData["nomineeDob" + sl] = date ? `${date[1]}/${date[2]}/${date[0]}` : "";
                                        inputData["nomineeMinor" + sl] = nomineeInfo[i].nomineeMinorFlg === "Y" ? "YES" : "NO";
                                        inputData["nomineeGuardiansName" + sl] = nomineeInfo[i].nomineeGuardianName;
                                        inputData["nomineeGuardianCode" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].nomineeGuardianCode, this.state.guardianCode);
                                        inputData["guardianPhotoId" + sl] = nomineeInfo[i].nomineeGuardianAddr3;
                                        inputData["nomineeGuardianAddress" + sl] = nomineeInfo[i].nomineeGuardianAddr1;
                                        inputData["nomineeGuardianAddress2" + sl] = nomineeInfo[i].nomineeGuardianAddr2;
                                        inputData["nomineeGuardianCityCode" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].nomineeGuardianCity, this.state.city);
                                        inputData["nomineeGuardianStateCode" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].nomineeGuardianStateProv, this.state.state);
                                        inputData["nomineeGuardianPostalCode" + sl] = nomineeInfo[i].nomineeGuardianPostalCode;
                                        inputData["nomineeGuardianCountry2" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].nomineeGuardianCountry, this.state.country);
                                    }
                                    if (response.data.nominationInfoList !== undefined && response.data.nominationInfoList.length > 0) {
                                        let sl = 0;
                                        let nominationList = response.data.nominationInfoList;
                                        for (let i = 0; i < nominationList.length; i++) {
                                            sl = i + 1;
                                            inputData["nomineeidnum" + sl] = nominationList[i].nomineeidnum;
                                            inputData["oldNomineeSlNumber" + sl] = nominationList[i].nomineeslno;
                                            inputData["nomineecifId" + sl] = nominationList[i].nomineecifid;
                                            inputData["oldNomineecifIdExist" + sl] = nominationList[i].nomineecifid !== "";
                                        }
                                    }
                                }

                                let getAccountUrl = backEndServerURL + "/GetCustomAcctDetailsRequest/" + this.state.inputData.accountNumber;
                                axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        if (response.data.acctStatus === "D" && this.props.match.params.requestType === undefined) {
                                            this.setState({
                                                loading: false,
                                                cbNotFound: true,
                                                title: "Dormant account is not acceptable!"
                                            });
                                        } else if (this.props.match.params.requestType === "dormant" && response.data.acctStatus === "A") {
                                            this.setState({
                                                loading: false,
                                                cbNotFound: true,
                                                title: "Account is Already Active"
                                            });
                                        } else {
                                            inputData.accountStatus = response.data.acctStatus;
                                            let getDataByCB = backEndServerURL + "/cbsCustomerGet/" + inputData.cbNumber;
                                            axios.get(getDataByCB, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                .then((response) => {
                                                    if (response.status === 200) {
                                                        inputData.emailTypeOne = {
                                                            "masterDataName": "emailType",
                                                            "key": "COMMEML",
                                                            "value": "COMMEML"
                                                        };
                                                        inputData.emailTypeTwo = {
                                                            "masterDataName": "emailType",
                                                            "key": "HOMEEML",
                                                            "value": "HOMEEML"
                                                        };
                                                        if (response.data.HOMEEMLBLOCK !== undefined) {
                                                            inputData.existingEmailIdTwo = response.data.HOMEEMLBLOCK.emailInfo;
                                                        }
                                                        if (response.data.COMMEMLBLOCK !== undefined) {
                                                            inputData.existingEmailId = response.data.COMMEMLBLOCK.emailInfo;
                                                            inputData.emailType = this.findByVarNameGetKeyValue(response.data.COMMEMLBLOCK.phoneEmailType, this.state.emailType);
                                                        }
                                                        if (response.data.COMMPH1BLOCK !== undefined) {
                                                            inputData.mobileType = "Local";
                                                            inputData.existingPhoneNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                                        }
                                                        if (response.data.NIDBLOCK !== undefined) {
                                                            inputData.nidCardOld = response.data.NIDBLOCK.referenceNum;
                                                            inputData.nidCardVerification = response.data.NIDBLOCK.isDocumentVerified;
                                                            inputData.nidNumberVerification = response.data.NIDBLOCK.isDocumentVerified;
                                                        }
                                                        if (response.data.RSNIDBLOCK !== undefined) {
                                                            inputData.smartcardOld = response.data.RSNIDBLOCK.referenceNum;
                                                            inputData.smartCardVerification = response.data.RSNIDBLOCK.isDocumentVerified;
                                                        }
                                                        if (response.data.ETINBLOCK !== undefined) {
                                                            inputData.tinUpdateOld = response.data.ETINBLOCK.referenceNum;
                                                        }

                                                        if (response.data.HOMEEMLBLOCK !== undefined) {


                                                            inputData.contactNumberEmailemailId2 = response.data.HOMEEMLBLOCK.emailInfo;
                                                        }
                                                        if (response.data.MailingBLOCK !== undefined) {


                                                            inputData.maillingAdressmailingAddressField1 = response.data.MailingBLOCK.addrLine1;
                                                            inputData.maillingAdressmailingAddressField2 = response.data.MailingBLOCK.addrLine2;
                                                            inputData.maillingAdresscity = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.city, this.state.city);
                                                            inputData.maillingAdressstate = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.state, this.state.state);
                                                            inputData.maillingAdresspostalCode = response.data.MailingBLOCK.postalCode;
                                                            inputData.maillingAdresscountry = this.findByVarNameGetKeyValue(response.data.MailingBLOCK.country, this.state.country);

                                                        }
                                                        if (response.data.HomeBLOCK !== undefined) {

                                                            inputData.homeAddresshomeAddressField1 = response.data.HomeBLOCK.addrLine1;
                                                            inputData.homeAddresshomeAddressField2 = response.data.HomeBLOCK.addrLine2;
                                                            inputData.homeAddresscity = this.findByVarNameGetKeyValue(response.data.HomeBLOCK.city, this.state.city);

                                                            inputData.homeAddressstate = this.findByVarNameGetKeyValue(response.data.HomeBLOCK.state, this.state.state);
                                                            inputData.homeAddresspostalCode = response.data.HomeBLOCK.postalCode;
                                                            inputData.homeAddresscountry = this.findByVarNameGetKeyValue(response.data.HomeBLOCK.country, this.state.country);
                                                        }
                                                        if (response.data.WorkBLOCK !== undefined) {

                                                            inputData.workAddressworkAddressField1 = response.data.WorkBLOCK.addrLine1;
                                                            inputData.workAddressworkAddressField2 = response.data.WorkBLOCK.addrLine2;
                                                            inputData.workAddresscity = this.findByVarNameGetKeyValue(response.data.WorkBLOCK.city, this.state.city);
                                                            inputData.workAddressstate = this.findByVarNameGetKeyValue(response.data.WorkBLOCK.state, this.state.state);
                                                            inputData.workAddresspostalCode = response.data.WorkBLOCK.postalCode;
                                                            inputData.workAddresscountry = this.findByVarNameGetKeyValue(response.data.WorkBLOCK.country, this.state.country);

                                                        }

                                                        if (response.data.FutureOnSiteBLOCK !== undefined) {

                                                            inputData.presentAddresspresentAddressField1 = response.data.FutureOnSiteBLOCK.addrLine1;
                                                            inputData.presentAddresspresentAddressField2 = response.data.FutureOnSiteBLOCK.addrLine2;
                                                            inputData.presentAddresscity = this.findByVarNameGetKeyValue(response.data.FutureOnSiteBLOCK.city, this.state.city);
                                                            inputData.presentAddressstate = this.findByVarNameGetKeyValue(response.data.FutureOnSiteBLOCK.state, this.state.state);
                                                            inputData.presentAddresspostalCode = response.data.FutureOnSiteBLOCK.postalCode;
                                                            inputData.presentAddresscountry = this.findByVarNameGetKeyValue(response.data.FutureOnSiteBLOCK.country, this.state.country);
                                                        }


                                                        if (response.data.WORKPH1BLOCK !== undefined) {
                                                            inputData.existingOfficeCountryCode = response.data.WORKPH1BLOCK.phoneNumCountryCode;
                                                            inputData.existingOfficeNumber = response.data.WORKPH1BLOCK.phoneNumLocalCode;
                                                        }
                                                        if (response.data.COMMPH2BLOCK !== undefined) {
                                                            inputData.existingResidenceNumber = response.data.COMMPH2BLOCK.phoneNumLocalCode;
                                                            inputData.existingResidenceCountryCode = response.data.COMMPH2BLOCK.phoneNumCountryCode;
                                                        }
                                                        if (response.data.RESIDBLOCK !== undefined) {
                                                            inputData.existingResidentId = response.data.RESIDBLOCK.referenceNum;
                                                        }
                                                        if (response.data.isNRE === "Y") {
                                                            inputData.chairmanCertificatenonResident = "Y"
                                                        }
                                                        if (response.data.NRERelativeBLOCK !== undefined) {
                                                            inputData.nreAddressnreAddressField1 = response.data.NRERelativeBLOCK.addrLine1;
                                                        }
                                                        // inputData.existingCustomerStatus = this.findByVarNameGetKeyValue(response.data.custStatus, this.state.customerStatusList);
                                                        inputData.titleOld = this.findByVarNameGetKeyValue(response.data.salutation, this.state.masterDataTitle);
                                                        inputData.existingNameOld = response.data.customerName;
                                                        inputData.existingShortName = response.data.shortName;
                                                        inputData.gender = response.data.gender;
                                                        inputData.constitutionCode = this.findByVarNameGetKeyValue(response.data.constitutionCode, this.state.constitutionList);
                                                        inputData.profession = this.findByVarNameGetKeyValue(response.data.employmentStatus, this.state.employmentType);
                                                        inputData.occupationCodeCB = response.data.occupationCode;
                                                        inputData.crmStatus = response.data.custStatus;
                                                        inputData.motherAccountSolId = response.data.branchCode;
                                                        // inputData.existingResidenceNumber = response.data.HOMEPH1;
                                                        inputData.cityTouchEmailId = response.data.COMMEML;
                                                        inputData.mobileAccounts = response.data.HOMEPH1;
                                                        inputData.fatherName = response.data.fatherName;
                                                        inputData.motherName = response.data.motherName;
                                                        inputData.maritalStatus = this.findByVarNameGetKeyValue(response.data.maritalStatus, this.state.maritalStatus);
                                                        inputData.spouseName = response.data.spouseName;
                                                        inputData.employerName = response.data.nameOfEmployer;
                                                        let date = response.data.birthDate.split("T")[0].split("-");
                                                        inputData.dobOld = `${date[1]}/${date[2]}/${date[0]}`;
                                                        if (response.data.PASSPORTBLOCK !== undefined) {
                                                            inputData.passportBlockExist = true;
                                                            inputData.passportNumberpassportNumberUniqueId = response.data.PASSPORTBLOCK.referenceNum;
                                                            inputData.passportNumberplaceOfIssue = this.findByVarNameGetKeyValue(response.data.PASSPORTBLOCK.placeOfIssue, this.state.placeOfIssueList);
                                                            let passportNumberissueDate = response.data.PASSPORTBLOCK.issueDt;
                                                            if (passportNumberissueDate && passportNumberissueDate !== -1) {
                                                                let date = (passportNumberissueDate).split('T')[0].split("-");
                                                                inputData.passportNumberissueDate = `${date[1]}/${date[2]}/${date[0]}`
                                                            }
                                                            let passExpDate = response.data.PASSPORTBLOCK.expireDt;
                                                            if (passExpDate && passExpDate !== -1) {
                                                                let date = (passExpDate).split('T')[0].split("-");
                                                                inputData.passportExpiryDate = `${date[1]}/${date[2]}/${date[0]}`
                                                            }
                                                            inputData.passportNumbercountryOfIssue = this.findByVarNameGetKeyValue(response.data.PASSPORTBLOCK.countryOfIssue, this.state.country);
                                                        }


                                                        //inputData.passportNumberexpiryDate=data. ;
                                                        if (response.data.BCBLOCK !== undefined) {

                                                            inputData.birthCertificatebirthCertificateNumberUniqueId = response.data.BCBLOCK.referenceNum;
                                                        }
                                                        if (response.data.chairmanCertificate !== undefined) {

                                                            inputData.chairmanCertificateUniqueId = response.data.chairmanCertificate;
                                                        }
                                                        if (response.data.DLBLOCK !== undefined) {
                                                            inputData.existingDrivingLicense = response.data.DLBLOCK.referenceNum;
                                                        }

                                                        inputData.existingCurrency = response.data.currency;

                                                        this.setState({
                                                            inputData: inputData,
                                                            varValue: this.getSearchvalue(inputData)
                                                        });
                                                        this.renderCaseStart();

                                                    }
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    this.state.varValue['eqmNumber'] = this.state.inputData.eqmNumber;
                                                    this.state.varValue['accountNumber'] = this.state.inputData.accountNumber;
                                                    this.setState({
                                                        loader: false,
                                                        loading: false,
                                                        cbNotFound: true,
                                                        title: error.response.data.message
                                                    });
                                                });
                                        }
                                    }).catch((error) => {
                                    this.state.varValue['eqmNumber'] = this.state.inputData.eqmNumber;
                                    this.state.varValue['accountNumber'] = this.state.inputData.accountNumber;
                                    this.setState({
                                        loader: false,
                                        loading: false,
                                        cbNotFound: true,
                                        title: error.response.data.message
                                    });
                                });
                            } else {
                                this.setState({
                                    loading: false,
                                    cbNotFound: true,
                                    title: 'Not a Retail Account!'
                                });
                            }
                        }).catch((error) => {
                        this.state.varValue['eqmNumber'] = this.state.inputData.eqmNumber;
                        this.state.varValue['accountNumber'] = this.state.inputData.accountNumber;
                        this.setState({
                            loader: false,
                            loading: false,
                            cbNotFound: true,
                            title: error.response.data.message
                        });
                    });


                })
                .catch((error) => {
                    console.log(error);
                    this.state.varValue['eqmNumber'] = this.state.inputData.eqmNumber;
                    this.state.varValue['accountNumber'] = this.state.inputData.accountNumber;
                    this.setState({
                        loader: false,
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    });
                })
        }

    };

    renderCaseStart = () => {
        setTimeout(() => {
            if (this.state.inputData.accountNumber) {
                let url = backEndServerURL + "/startCase/cs_maintenance";
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        for(let item of accountMaintenanceSearch){
                            item.readOnly = true
                        }

                        this.state.varValue['eqmNumber'] = this.state.inputData.eqmNumber;
                        this.state.varValue['accountNumber'] = this.state.inputData.accountNumber;
                        this.setState({
                            caseId: response.data.caseId,
                            appId: response.data.id,
                            appData: response.data.inputData,
                            getNewCase: true,
                            showValue: true,
                            getData: true,
                            loader: false,
                            searchData: true
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loader: false,
                            loading: false
                        })
                    })
            }
        }, 2000)
    };

    stopLoading = (value) => {
        if (value === "yes") {
            setTimeout(() => {
                this.setState({
                    loading: false,
                });
            }, 1000)
        } else {
            this.setState({
                getData: false,
                searchData: false,
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px", "width": '100%'}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    };

    renderDefferalData = () => {


        if (this.state.getDeferalList.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px", "width": "100%"}}>
                        <b>Deferral List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>

                </div>
            )
        }

    };

    renderAddButtonShow = () => {

        return (
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    width: 100,
                    float: 'left',
                    verticalAlign: 'right',

                }}

                type='button' value='add more'
                onClick={this.addClick.bind(this)}


            >Add Deferral</button>
        )

    };

    dynamicDeferral = (i) => {

        let field = JSON.parse(JSON.stringify(deferal));
        field.varName = "deferalType" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };

    dynamicDeferralOther = (i) => {
        if (this.state.inputData["deferalType" + i] === "Others") {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        } else {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        }
    };

    dynamicDate = (i) => {
        let field = JSON.parse(JSON.stringify(date));
        field.varName = "expireDate" + i;
        return DateComponentDeferal.date(this.state, this.updateComponent, field);
    };

    removeClick(i, event) {
        event.preventDefault();
        let pos = this.state.values.indexOf(i);
        if (pos > -1) {
            this.state.values.splice(pos, 1);
            this.updateComponent();
            if (this.state.values.length > 0) {
                this.state.inputData["csDeferal"] = "YES"
            } else {
                this.state.inputData["csDeferal"] = "NO"
            }
        }


    }


    renderExistingNomineeForm = () => {
        if (this.state.getData) {

            var sl;
            let objectForExistingNominee = [];
            for (var i = 0; i < this.state.inputData.nomineeNumberOld; i++) {
                sl = i + 1;
                objectForExistingNominee.push(
                    {
                        "varName": "Nominee " + i,
                        "type": "title",
                        "label": "Nominee  " + sl,
                        "grid": 12,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeName" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 3,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeRelationship" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": [],
                        "label": "Relationship",
                        "grid": 3,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeDob" + sl,
                        "type": "date",
                        "label": "D.O.B",
                        "grid": 3,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "percentageNominee" + sl,
                        "type": "text",
                        "label": "Percentage %",
                        "grid": 3,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeAddress1" + sl,
                        "type": "text",
                        "label": "Address Field 1",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeAddress2" + sl,
                        "type": "text",
                        "label": "Address Field 2",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "label": "City",
                        "grid": 3,
                        "readOnly": true,
                        "enumType": "city",
                        "enum": [],
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "label": "State",
                        "grid": 3,
                        "readOnly": true,
                        "enumType": "state",
                        "enum": [],
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineePostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeCountry" + sl,
                        "type": "autoCompleteValueReturn",
                        "label": "Country",
                        "grid": 3,
                        "readOnly": true,
                        "enumType": "country",
                        "enum": [],
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "photoIdNumberRegNo" + sl,
                        "type": "text",
                        "label": "Photo Id No/Registration No.",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeMinor" + sl,
                        "type": "text",
                        "label": "Nominee Minor",
                        "grid": 3,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "type": "title",
                        "label": "Guardian Details  " + sl,
                        "grid": 12,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },
                    {
                        "varName": "nomineeGuardiansName" + sl,
                        "type": "text",
                        "label": "Guardian's name",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },
                    {
                        "varName": "nomineeGuardianCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "guardianCode",
                        "enum": [],
                        "label": "Guardian Code",
                        "grid": 3,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },
                    {
                        "varName": "guardianPhotoId" + sl,
                        "type": "text",
                        "label": "Guardian Photo Id",
                        "grid": 3,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },

                    {
                        "varName": "nomineeGuardianAddress" + sl,
                        "type": "text",
                        "label": "Address 1",
                        "grid": 3,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },
                    {
                        "varName": "nomineeGuardianAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "grid": 3,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },

                    {
                        "varName": "nomineeGuardianCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "city",
                        "enum": [],
                        "readOnly": true,
                        "label": "City Code",
                        "grid": 3,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },

                    {
                        "varName": "nomineeGuardianStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "state",
                        "enum": [],
                        "label": "State Code",
                        "grid": 3,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },

                    {
                        "varName": "nomineeGuardianPostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 3,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },

                    {
                        "varName": "nomineeGuardianCountry2" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "country",
                        "enum": [],
                        "readOnly": true,
                        "label": "Country",
                        "grid": 3,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },
                )
            }

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, objectForExistingNominee, this.updateComponent)
            )

        }
    };

    renderExistingNomineeFormReturn = () => {
        if (this.state.getData && this.props.delStatus === "RETURN") {
            var sl;
            let objectForExistingNominee = [];
            for (var i = 0; i < this.state.inputData.nomineeNumberOld; i++) {
                sl = i + 1;
                objectForExistingNominee.push(
                    {
                        "varName": "Nominee " + i,
                        "type": "title",
                        "label": "Nominee  " + sl,
                        "grid": 12,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeName" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 3,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeRelationship" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": [],
                        "label": "Relationship",
                        "grid": 3,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                )
            }
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, objectForExistingNominee, this.updateComponent)
            )
        }
    };


    renderCustomerDetails = () => {
        if (this.state.inputData.accountNumber && this.state.searchData) {
            return (
                <Label cbNumber={this.state.inputData.cbNumber} classes={this.props}
                       accountNumber={this.state.inputData.accountNumber} stopLoading={this.stopLoading} create={true}
                       crmStatus={this.state.inputData.crmStatus}/>
            )
        }
    };


    renderRemarks = () => {
        if (this.state.getData) {

            return (

                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)

            )
        }

    };

    handleChangeuploadFileAlertRequest = (event) => {
        let data = event.target.checked;
        this.state.inputData.uploadFileAlertRequest = data;
        this.updateComponent();
        if (data === false) {
            this.setState({
                cbNotFound: true,
                title: "Without Document Process!!",
            })
        }
    };

    uploadFunction = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return (
                <GridContainer>
                    <Grid item xs={.5}>
                        <CheckBox
                            style={{marginTop: "-6px"}}
                            checked={this.state.inputData.uploadFileAlertRequest}
                            defaultValue={this.state.inputData.uploadFileAlertRequest}
                            onChange={(event) => this.handleChangeuploadFileAlertRequest(event)}/>
                    </Grid>
                    <Grid item xs={11}>
                        <UploadComponent previewImage={this.previewImage} showSignature={true} appId={this.state.appId}
                                         classes={classes}/>
                    </Grid>
                </GridContainer>
            )
        }
    };

    previewImage = (value) => {
        this.state.inputData["previewImage"] = value === "submit";
        this.forceUpdate();
    };

    addClick() {
        let randomNumber = Math.floor(Math.random() * 100000000000);
        this.setState(prevState => ({
            values: [...prevState.values, randomNumber],
        }));
        this.state.inputData["csDeferal"] = "YES";
    }

    renderMaintenanceRequest = () => {
        if (this.state.getData && this.props.appId === undefined) {
            let optionField = this.props.match.params.requestType === "dormant" ? maintenanceRequestTypeForDormant : maintenanceRequestType;
            return (
                <React.Fragment>
                    <Grid container spacing={1}>
                        <ThemeProvider theme={theme}>
                            <Grid xs={12}></Grid>
                            <Grid item xs={5}>
                                <Grid item>
                                    <label className="input-label-common">Select Maintenance Request</label>
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                       // multiple={this.props.match.params.requestType !== "dormant"}
                                       // id="tags-standard"
                                        options={optionField}
                                        onChange={(event, option) => this.handleChangeMultipleSelect(event, option)}
                                        getOptionLabel={option => option.title}
                                        size="small"
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined" fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </ThemeProvider>
                    </Grid>
                </React.Fragment>
            )
        }
    };

    renderSearchForm = () => {
        if (this.props.taskTitle === undefined) {
            return (
                <Grid item xs={12}>
                    <Grid container>
                        <ThemeProvider theme={theme}>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, accountMaintenanceSearch, this.updateComponent, this.onKeyDownForDedup)
                            }
                            <button
                                style={{
                                    marginTop: "18px",
                                    height: '30px'
                                }}
                                onClick={this.renderStartCase}
                                className="btn btn-danger btn-sm">
                                Search
                            </button>
                            <Grid item xs={12}>
                            </Grid>
                        </ThemeProvider>
                        <br/>
                    </Grid>
                </Grid>
            )
        }
    };


    renderSearchData = () => {
        if (this.state.inputData.accountNumber && this.state.numberOfMultipleSelect > 0) {
            return (
                <div>
                    <ThemeProvider theme={theme}>
                        <Grid container>
                            <br/>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, maintenanceFirst, this.updateComponent, this.keyDownForCheck, this.keyDownForChange)
                            }
                        </Grid>
                    </ThemeProvider>
                </div>
            )
        }
    };

    keyDownForCheck = (event, data) => {

    };

    keyDownForChange = (data) => {
        console.log(".....change");
        if (data.varName === "mobileType") {
            this.state.varValue["newPhoneNumber"] = this.state.inputData["newPhoneNumber"];
            this.forceUpdate();
        }
        if (data.varName === "csBearer") {
            if (this.state.inputData["csBearer"] === "YES") {
                this.state.varValue["bearerApproval"] = "BM Approval";
                this.state.inputData["bearerApproval"] = "BM Approval";
                this.state.inputData["showBearerField"] = true;
            } else {
                this.state.varValue["bearerApproval"] = "";
                this.state.inputData["bearerApproval"] = "";
                this.state.inputData["showBearerField"] = false;
            }
            this.forceUpdate();
        }
    };

    renderDigitalForm = () => {
        // return (
        //     <button
        //         className="btn btn-outline-primary btn-sm"
        //         onClick={this.downloadForm}
        //     >
        //         Download Digital Form
        //     </button>
        // )

    };

    downloadForm = () => {
        this.setState({
            loading: true
        });
        let download_url = backEndServerURL + "/casa/maintenance/informationUpdateForm/" + this.state.inputData.accountNumber;
        axiosDownload.downloadFile(download_url, 'POST', this.state.inputData, `DigitalForm.pdf`).then((result) => {
            if (result) {
                this.setState({
                    loading: false
                })
            }
        }).catch((error) => {
            this.setState({
                title: "Digital Form Download Failed!",
                loading: false,
                cbNotFound: true
            })
        })
    };

    commonData = () => {
        if (this.state.getData && this.state.inputData.accountNumber && this.state.numberOfMultipleSelect > 0) {
            return (
                <Grid container spacing={0}>
                    <ThemeProvider theme={theme}>
                        {this.renderExistingNomineeForm()}
                        {/*{this.renderExistingNomineeFormReturn()}*/}
                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, csBearer, this.updateComponent, this.keyDownForCheck, this.keyDownForChange)
                        }
                        <Grid item xs={12}>
                            <br/>
                        </Grid>
                        {this.renderAddButtonShow()}
                        <Grid item xs={12}></Grid>
                        {this.addDeferalForm()}
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}>
                            {this.renderDefferalData()}
                        </Grid>
                        <Grid item xs={12}>
                            {this.renderRemarksData()}
                        </Grid>
                        <Grid item xs={12}>
                            {this.uploadFunction()}
                        </Grid>
                        {this.renderRemarks()}
                    </ThemeProvider>
                    <br/>
                </Grid>
            )
        }
    };

    returnCommonData = () => {

        if (this.state.inputData.maker_approval === "RETURN" || this.state.inputData.returnDataBm === true || this.state.inputData.bom_maintenance_approval === "RETURN") {

            return (
                <>
                    {CommonJsonFormComponent.renderJsonForm(this.state, maintenanceFirst, this.updateComponent)}
                    {this.renderExistingNomineeForm()}
                    {/*{this.renderExistingNomineeFormReturn()}*/}
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, csBearer, this.updateComponent, this.keyDownForCheck, this.keyDownForChange)
                    }
                    <Grid item xs={12}><br/></Grid>
                    {this.renderAddButtonShow()}
                    <Grid item xs={12}><br/></Grid>
                    {this.addDeferalForm()}
                    <Grid item xs={12}>
                        {this.renderDefferalData()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.uploadFunction()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderFlowSummeryButton()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderRemarksData()}
                    </Grid>
                    <Grid item xs={12}>
                    </Grid>
                    {this.renderRemarks()}
                    <Grid item xs='12'>
                    </Grid>
                    {this.handleSubmitButton()}
                </>
            )
        }

    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    handleSubmitButton = () => {
        if (this.state.getData && (this.state.numberOfMultipleSelect > 0 || (this.state.inputData.maker_approval === "RETURN" || this.state.inputData.returnDataBm === true))) {
            return (
                <Grid container spacing={0}>
                    <ThemeProvider theme={theme}>
                        <Grid item xs={12}>
                            <button
                                className="btn btn-outline-primary btn-sm"
                                disabled={this.state.disabled}
                                onClick={this.handleSubmit}
                            >
                                Submit
                            </button>
                            &nbsp;&nbsp;&nbsp;
                            <button
                                className="btn btn-outline-info btn-sm"
                                disabled={this.state.disabled}
                                onClick={this.handleSubmitDraft}
                            >
                                Save
                            </button>
                        </Grid>
                    </ThemeProvider>
                </Grid>
            )
        }
    };

    confirmationClose = (value) => {
        if (value === "ok") {
            this.setState({
                disabled: false,
                confirmation: false,
                checkList: true
            });
        } else {
            this.setState({
                disabled: false,
                confirmation: false
            })
        }
    };

    handleSubmit = (event) => {
        event.preventDefault();
        let dependencyDeferral = [];
        if (this.state.inputData["csDeferal"] === "YES") {
            for (let i = 0; i < this.state.values.length; i++) {
                let value = this.state.values[i];
                let defferalType = this.state.inputData["deferalType" + value];
                dependencyDeferral.push(
                    {
                        "varName": "deferalType" + value,
                        "type": "select",
                        "grid": 2,
                        "required": true,

                    }
                );
                if (defferalType === "Others") {
                    dependencyDeferral.push({
                        "varName": "deferalOther" + value,
                        "type": "text",
                        "grid": 2,
                        "required": true,

                    },)
                }
                dependencyDeferral.push({
                    "varName": "expireDate" + value,
                    "type": "date",
                    "grid": 2,
                    "required": true,

                },)

            }

        }
         let error = MyValidation.defaultValidation(maintenanceFirst.concat(csBearer, this.state.objectForInitialNewNominee, this.state.objectForNominee,dependencyDeferral), this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        } else {
            if (this.state.inputData.mailingContactNumberChange) {
                if (this.state.inputData.mobileType === "Local") {
                    this.state.inputData.commPhoneLocalCode = "88";
                }
            }
            if (this.state.inputData.uploadFileAlertRequest && (this.state.fileUploadData.length === 0 && this.state.inputData.previewImage === undefined)) {
                this.setState({
                    cbNotFound: true,
                    title: "Please Upload File!"
                })
            } else {
                if (!this.state.inputData.uploadSignature && this.state.inputData.titleChange) {
                    this.setState({
                        confirmation: true
                    })
                } else {
                    if (this.state.inputData.titleChange) {
                        this.setState({
                            checkList: true
                        });
                    } else {
                        this.functionForRoute(this.state.inputData)
                    }
                }
            }
        }

    };

    checkListClose = () => {
        this.setState({
            checkList: false
        })
    };

    checkListHandleSubmit = (value) => {
        this.setState({
            checkList: false
        }, this.functionForRoute(value));
    };

    functionForRoute = (value) => {
        this.setState({
            disabled: true
        });

        let imageUrl = backEndServerURL + "/case/files/" + this.state.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (this.state.inputData["csDeferal"] === "YES") {
                    let defType = [];
                    let expDate = [];
                    var deferralData = {};
                    let appId = this.state.appId;
                    for (let i = 0; i < this.state.values.length; i++) {
                        let value = this.state.values[i];
                        let deferalType = this.state.inputData["deferalType" + value];
                        if (deferalType === "other") {
                            deferalType = this.state.inputData["deferalOther" + value];
                        }
                        defType.push(deferalType);
                        let expireDate = this.state.inputData["expireDate" + value];
                        expDate.push(expireDate);

                    }
                    deferralData.appId = appId;
                    deferralData.type = defType;
                    deferralData.dueDate = expDate
                }
                let data = {...this.state.inputData, ...value};
                if (this.props.appId === undefined) {
                    let requestData = "";
                    for (let item of [...new Set(this.state.selectedRequest)]) {
                        let temp = requestData;
                        requestData = requestData === "" ? item.title : temp + ", " + item.title;
                    }
                    data.freeFlag1 = `${requestData}`;
                }
                data.cs_deferal = this.state.inputData["csDeferal"];
                data.csRemarks = undefined;
                data.serviceType = "Maintenance";
                data.subServiceType = "AccountMaintenance";
                data.category = "CASA";
                data.freeFlag5 = "Maintenance";
                if (this.props.appId === undefined) {
                    data.subCategory = this.props.match.params.requestType === "dormant" ? "Dormant Activation" : "Maintenance";
                    data.freeFlag6 =  this.props.match.params.requestType === "dormant" ? "Dormant Activation & Maintenance" : "Customer Information Update";
                } else {
                    data.subCategory = this.state.inputData.subCategory === "Dormant Activation" ? "Dormant Activation" : "Maintenance";
                    data.freeFlag6 =  this.state.inputData.subCategory === "Dormant Activation" ? "Dormant Activation & Maintenance" : "Customer Information Update";
                }

                data.numberOfMultipleSelect = this.state.numberOfMultipleSelect;
                if (this.state.inputData.bearerApproval === "BM Approval") {
                    data.cs_bearer = "bm_approval";
                }
                if (this.state.inputData.bearerApproval === "BM & Call Center Approval") {
                    data.cs_bearer = "both_approval";
                }
                if (this.state.inputData.bearerApproval === "Call Center Approval") {
                    data.cs_bearer = "call_center_approval";
                }
                if (this.state.inputData.csDeferal === "NO" || this.state.inputData.csDeferal === undefined) {
                    data.cs_deferal = "NO";
                }
                if (this.state.inputData.csBearer === "NO" || ((this.state.inputData.bearerApproval === this.state.return_bearer_approval) && this.state.return_cs_bearer === "NO")) {
                    data.cs_bearer = "NO";
                }
                data.freeFlag4 = data.cs_bearer;
                let remarksData = {};
                remarksData.remark = this.state.inputData.csRemarks;
                remarksData.map = {...data, csRemarks: undefined};
                remarksData.bulkDeferralDTO = deferralData;
                let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
                axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    this.setState({
                        inputData: {},
                        varValue: {},
                        getData: false,
                        title: "Successful!",
                        loading: false,
                        notificationMessage: "Successfully Routed!",
                        alert: true,
                        disabled: false
                    });
                    if (this.props.appId !== undefined) {
                        this.props.closeModal();
                    } else {
                        setTimeout(function () {
                            window.location.reload()
                        }, 1000);
                    }
                }).catch((error) => {
                    console.log(error);
                    this.setState({
                        disabled: false,
                        loading: false
                    })
                });
            })
            .catch((error) => {
                console.log(error);
            })
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    handleSubmitDraft = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
        });

        if (this.state.inputData["csDeferal"] === "YES") {
            var defType = [];
            var expDate = [];

            let appId = this.state.appId;
            for (let i = 0; i < this.state.values.length; i++) {
                let value = this.state.values[i];
                let defferalType = this.state.inputData["deferalType" + value];
                if (defferalType === "other") {
                    defferalType = this.state.inputData["deferalOther" + value];
                }
                defType.push(defferalType);
                let expireDate = this.state.inputData["expireDate" + value];
                expDate.push(expireDate);

                console.log(expDate)
            }

            let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";
            axios.post(deferalRaisedUrl, {
                appId: appId,
                type: defType,
                dueDate: expDate
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        disabled: false,
                    })
                })
        }

        var variableSetUrl = backEndServerURL + "/save/" + this.state.appId;
        let data = this.state.inputData;
        if (this.props.appId === undefined) {
            let requestData = "";
            for (let item of [...new Set(this.state.selectedRequest)]) {
                let temp = requestData;
                requestData = requestData === "" ? item.title : temp + ", " + item.title;
            }
            data.freeFlag1 = `${requestData}`;
        }
        data.cs_deferal = this.state.inputData["csDeferal"];
        data.serviceType = "Maintenance";
        data.subServiceType = "AccountMaintenance";
        data.category = "CASA";
        if (this.props.appId === undefined) {
            data.subCategory = this.props.match.params.requestType === "dormant" ? "Dormant Activation" : "Maintenance";
            data.freeFlag6 =  this.props.match.params.requestType === "dormant" ? "Dormant Activation & Maintenance" : "Customer Information Update";
        }
        data.numberOfMultipleSelect = this.state.numberOfMultipleSelect;
        data.freeFlag4 = this.state.inputData.cs_bearer;

        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Draft!",
                    alert: true,
                    loading: false,
                    disabled: false,
                });
                if (this.props.appId !== undefined) {
                    this.props.closeModal();
                } else {
                    setTimeout(function () {
                        window.location.reload()
                    }, 1000);
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    disabled: false,
                    loading: false
                })
            });
    };


    close = () => {
        this.props.closeModal();
    };

    handleChangeMultipleSelect = (event, option) => {
        event.preventDefault();
        let selectedArray = [];
        let selectedData = {...this.state.inputData};
        if (this.props.match.params.requestType === "dormant") {
            selectedArray.push(option)
        } else {
            selectedArray = option
        }

        if (selectedArray.length > 0) {
            selectedData.digitTinChange = false;
            selectedData.nidChange = false;
            selectedData.nidOrsmartcardChange = false;
            selectedData.titleChange = false;
            selectedData.nomineeChange = false;
            selectedData.photoIdChange = false;
            selectedData.mailingContactNumberChange = false;
            selectedData.otherContactNumberChange = false;
            selectedData.emailAddressChange = false;
            selectedData.addressChange = false;
            selectedData.otherInformationChange = false;
            selectedData.signatureCard = false;
            selectedData.onlyDormantAccountActivation = false;
            selectedData.dormantAccountActivation = false;
            selectedData.projectRelatedDataUpdateADUP = false;
            selectedData.currencyAdd = false;
            selectedData.eStatementEnrollment = false;
            selectedData.tpKyc = false;
            selectedData.siCreation = false;
            selectedData.modifyExistingSi = false;
            selectedData.deleteExistingSi = false;
            selectedData.newExistingSi = false;
            selectedData.proofOfSubmissionOfReturn = false;
            selectedData.accountFreezeMark = false;
            selectedData.accountUnfreezeMark = false;
            selectedData.decreasedMark = false;


            for (let i = 0; i < selectedArray.length; i++) {
                if (selectedArray[i].title === "E Tin Update") {
                    selectedData.digitTinChange = true
                }
                if (selectedArray[i].title === "NID Card Number update") {
                    selectedData.nidChange = true
                }
                if (selectedArray[i].title === "Smart Card Number update") {
                    selectedData.nidOrsmartcardChange = true
                }
                if (selectedArray[i].title === "Other Photo ID Change") {
                    selectedData.photoIdChange = true
                }
                if (selectedArray[i].title === "Designated Contact Number Change") {
                    selectedData.mailingContactNumberChange = true
                }
                if (selectedArray[i].title === "Other Contact Number Change") {
                    selectedData.otherContactNumberChange = true
                }
                if (selectedArray[i].title === "Email Address Update") {
                    selectedData.emailAddressChange = true
                }
                if (selectedArray[i].title === "E-statement Enrollment") {
                    selectedData.eStatementEnrollment = true
                }
                if (selectedArray[i].title === "Title Change and Signature Card Update") {
                    selectedData.titleChange = true
                }
                if (selectedArray[i].title === "Nominee Update") {
                    selectedData.nomineeChange = true
                }
                if (selectedArray[i].title === "Data Update") {
                    selectedData.projectRelatedDataUpdateADUP = true
                }
                if (selectedArray[i].title === "Address Change") {
                    selectedData.addressChange = true
                }
                if (selectedArray[i].title === "Other Information Change") {
                    selectedData.otherInformationChange = true;
                    selectedData.sectorCodeApicall = true;
                    selectedData.showSecCode = true;
                }
                if (selectedArray[i].title === "Signature Card Update") {
                    selectedData.signatureCard = true
                }
                if (selectedArray[i].title === "Dormant Account Activation") {
                    selectedData.onlyDormantAccountActivation = true
                }
                if (selectedArray[i].title === "Dormant Account Activation And Data Update") {
                    selectedData.dormantAccountActivation = true
                }
                if (selectedArray[i].title === "Currency Add") {
                    selectedData.currencyAdd = true
                }
                if (selectedArray[i].title === "TP KYC") {
                    selectedData.tpKyc = true
                }
                if (selectedArray[i].title === "SI creation/Modification") {
                    selectedData.siCreation = true
                }
                if (selectedArray[i].title === "Modify Existing SI") {
                    selectedData.modifyExistingSi = true
                }
                if (selectedArray[i].title === "Delete Existing SI") {
                    selectedData.deleteExistingSi = true
                }
                if (selectedArray[i].title === "New SI Creation") {
                    selectedData.newExistingSi = true
                }
                if (selectedArray[i].title === "Proof of Submission of Return (PSR) Update") {
                    selectedData.proofOfSubmissionOfReturn = true
                }
                if (selectedArray[i].title === "Account Freeze Mark") {
                    selectedData.accountFreezeMark = true
                }
                if (selectedArray[i].title === "Account Unfreeze Mark") {
                    selectedData.accountUnfreezeMark = true
                }
                if (selectedArray[i].title === "Deceased Mark") {
                    selectedData.decreasedMark = true
                }
            }
        }
        this.setState({
            inputData: selectedData,
            selectedRequest: selectedArray,
            numberOfMultipleSelect: selectedArray.length
        })
    };

    signature = () => {
        if (this.state.getData && (this.state.searchData === true || (this.state.inputData.maker_approval === "RETURN" || this.state.inputData.returnDataBm === true))) {
            return (
                <div>
                    <br/>
                    <div style={{"float": "left"}}>
                        <SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
                                         classes={this.props}/>
                    </div>
                    <div style={{"float": "right"}}>
                        <Grid item xs={12}>
                            {this.renderDigitalForm()}
                        </Grid>
                    </div>
                </div>
            )
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.appId === undefined) {
            if (prevProps.match.params.requestType !== this.props.match.params.requestType) {
                window.location.reload();
            }
        }
    }

    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return MaintenanceUtility.renderMaintenanceHeader(this.props.freeFlag1, this.props.caseId, this.props.solId, this.props.cbNumber, this.props.accountNumber, this.props.customerName, this.close)
        } else {
            return (
                <h4 style={{color: "white"}} >{this.props.match.params.requestType === "dormant" ? "Dormant Activation & Maintenance" : "Customer Information Update"}</h4>
            )
        }
    };

    renderAllDialoag = () => {
        const {classes} = this.props;
        return (
            <>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.cbNotFound}>
                    <DialogContent className={classes.dialogPaper}>
                        <CBNotFound
                            closeModal={this.closeModalCBNotFound}
                            title={this.state.title}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    open={this.state.confirmation}>
                    <DialogContent>
                        <ConfirmationModal closeModal={this.confirmationClose} title="Without Signature Card Upload!"/>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    open={this.state.checkList}>
                    <DialogContent>
                        <CheckList inputData={this.state.inputData} closeModal={this.checkListClose}
                                   handleSubmit={this.checkListHandleSubmit}/>
                    </DialogContent>
                </Dialog>
            </>
        )
    };

    renderFlowSummeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                   customerName={this.state.inputData.customerName}
                                   accountNumber={this.state.inputData.accountNumber}
                                   solId={this.state.inputData.freeFlag3}/>
            )
        }
    };

    render() {

        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        if ((this.state.inputData.maker_approval === "RETURN" || this.state.inputData.returnDataBm === true || this.state.inputData.call_center_approval === "RETURN" || this.state.inputData.bom_maintenance_approval === "RETURN") && this.state.getData) {
            return (
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {this.renderHeader()}
                    </CardHeader>
                    <CardBody>
                        {this.renderAllDialoag()}
                        {this.renderNotification()}
                        {this.renderCustomerDetails()}
                        {this.signature()}
                        <Grid container spacing={0}>
                            <ThemeProvider theme={theme}>
                                {this.returnCommonData()}
                            </ThemeProvider>
                        </Grid>
                    </CardBody>
                </Card>
            );
        } else {
            return (
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {this.renderHeader()}
                    </CardHeader>
                    <CardBody>
                        {this.renderAllDialoag()}
                        {this.renderSearchForm()}
                        {this.renderNotification()}
                        {this.renderCustomerDetails()}
                        {this.signature()}
                        {this.renderMaintenanceRequest()}
                        {this.renderSearchData()}
                        {this.commonData()}
                        {this.handleSubmitButton()}
                    </CardBody>
                </Card>
            );
        }

    }

}

export default withStyles(styles)(AccountMaintenance);
