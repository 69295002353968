import React, {Component} from "react";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Notification from "../../NotificationMessage/Notification";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "../../Table/Table";
import {giftCardRequisitionBom, prepaidCardRequisitionBom} from "./JsonForm"
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import {Dialog} from "@material-ui/core";
import CBNotFound from "../../workflow/CASA/CBNotFound";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

let bomRemarks = [
    {
        "varName": "bomRemarks",
        "type": "textArea",
        "label": "BOM Remarks",
        "grid": 12
    }];

class CardTagging extends Component {
    constructor() {
        super();
        this.state = {
            appData: "",
            fileUploadData: {},
            appId: "",
            err: false,
            inputData: {},
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            showValue: true,
            varValue: {},
            getData: false,
            searchData: false,
            notificationMessage: "",
            alert: false,
            title: '',
            getImageLink: [],
            getImageBoolean: false,
            imageName: [],
            loading: true,
            getRemarks: [],
            cbNotFound: false
        };
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };


    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };


    functionForGetRemarks = () => {
        let remarksArray = [];
        let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
        axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            response.data.map((data) => {
                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
            });
            this.setState({
                getRemarks: remarksArray
            })
        })
            .catch((error) => {
                console.log(error)
            })
    };

    functionForFileGet = (type) => {
        this.setState({
            loading: true
        });
        let imageUrl = backEndServerURL + "/case/files/" + type + "/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.data.length > 0) {
                    let url = backEndServerURL + "/file/" + response.data.toString() + "/" + sessionStorage.getItem("accessToken");
                    window.open(url);
                    this.setState({
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: "File Not Found!"
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                })
            });
    };


    componentDidMount() {
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + "/variables/" + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    this.functionForGetRemarks();
                    this.setState({
                        varValue: response.data,
                        inputData: this.copyJson(response.data),
                        showValue: true,
                        getData: true,
                        loading: false,
                        appId: this.props.appId,
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    functionForValidityCheck = () => {
        let postData = {};
        postData.nid = this.state.inputData.nationalId;
        postData.clientId = this.state.inputData.clientId;
        postData.smartCard = this.state.inputData.smartCard;
        return new Promise((resolve, reject) => {
            let url = backEndServerURL + "/giftcardAvailabilityCheck";
            axios.post(url, this.copyJson(postData), {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    console.log(error);
                    reject(error)
                });
        })
    };


    functionForValidityCheckforPrepaidCard = () => {
        let postData = {};
        postData.nid = this.state.inputData.nationalId;
        postData.smartCard = this.state.inputData.smartCard;
        postData.clientId = this.state.inputData.clientId;
        return new Promise((resolve, reject) => {
            let url = backEndServerURL + "/prepaidCardAvailabilityCheckByNid";
            axios.post(url, this.copyJson(postData), {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    console.log(error);
                    reject(error)
                });
        })
    };


    handleSubmit = event => {
        event.preventDefault();
        this.setState({
            loading: true,
            disabled: true
        });
        let url;
        if (this.props.subServiceType === "Prepaid Card Issuance") {
            this.functionForValidityCheckforPrepaidCard().then((res) => {
                if (res.data !== "Prepaid Card Exists") {
                    url = backEndServerURL + "/prepaidCardTagging/" + this.state.appId;
                    this.functionForTag(url)
                } else {
                    this.setState({
                        cbNotFound: true,
                        title: "Prepaid card already Exist for this NID!",
                        disabled: false,
                        loading: false
                    })
                }
            }).catch((err) => {
                this.setState({
                    cbNotFound: true,
                    title: err.response.data.message,
                    disabled: false,
                    loading: false
                })
            })

        } else {
            this.functionForValidityCheck().then((response) => {
                if (response.data === "Y") {
                    url = backEndServerURL + "/giftCardTagging/" + this.state.appId;
                    this.functionForTag(url)
                } else {
                    this.setState({
                        cbNotFound: true,
                        title: "Daily Limit Expired for this NID!",
                        disabled: false,
                        loading: false
                    })
                }
            }).catch((error) => {
                this.setState({
                    cbNotFound: true,
                    title: error.response.data.message,
                    disabled: false,
                    loading: false
                })
            });
        }
    };

    functionForTag = (url) => {
        axios.post(url, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            if (this.props.subServiceType === "Prepaid Card Issuance") {
                let postData = {};
                postData.appId = this.props.appId;
                postData.cardNo = this.state.inputData.cardNumber;
                postData.clientId = this.state.inputData.clientId;
                let fileSave = backEndServerURL + "/prepaidCardFileSave";
                axios.post(fileSave, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.functionForCaseRoute({bom_approval: "APPROVED"})
                    }).catch(error => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message,
                        disabled: false
                    });
                });
            } else {
                this.functionForCaseRoute({bom_approval: "APPROVED"})
            }
        }).catch(error => {
            console.log(error);
            this.setState({
                loading: false,
                cbNotFound: true,
                title: error.response.data.message,
                disabled: false
            });
        });
    };

    functionForCaseRoute = (postData) => {
        let remarksData = {};
        remarksData.remark = this.state.inputData.bomRemarks;
        remarksData.map = {...postData, bomRemarks: undefined};
        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
        axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                this.setState({
                    disabled: false,
                    alert: true,
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                    loading: false,
                });
                this.props.closeModal();
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false,
                    cbNotFound: true,
                    title: error.response.data.message,
                    disabled: false
                });
            });
    };


    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, bomRemarks, this.updateComponent)
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px", "margin-top": "10px"}}>
                        <b>Remarks</b>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>

                </div>
            )
        }
    };

    handleReturn = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
            disabled: true
        });
        this.functionForCaseRoute({bom_approval: "RETURN"});
    };

    functionForFileDownload = () => {
        if (this.props.subServiceType === "Prepaid Card Issuance") {
            return (
                <Grid item xs={12} style={{marginTop: "15px"}}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => this.functionForFileGet("Photo Upload")}
                    >
                        Download Photo
                    </button>
                    &nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => this.functionForFileGet("Application Form Upload")}
                    >
                        Download Application Form
                    </button>
                    &nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => this.functionForFileGet("National ID Upload")}
                    >
                        Download National ID
                    </button>
                    &nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => this.functionForFileGet("KYC Upload")}
                    >
                        Download KYC
                    </button>
                    &nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => this.functionForFileGet("Signature Upload")}
                    >
                        Download Signature
                    </button>
                </Grid>
            )
        } else if (this.props.subServiceType === "Gift Card Issuance") {
            return (
                <Grid item xs={12} style={{marginTop: "15px"}}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => this.functionForFileGet("NID Upload")}
                    >
                        Download National ID
                    </button>
                </Grid>
            )
        }
    };

    renderSearchData = () => {

        if (this.state.getData === true) {
            return (
                <>
                    {this.props.subServiceType === "Prepaid Card Issuance" && CommonJsonFormComponent.renderJsonForm(
                        this.state,
                        prepaidCardRequisitionBom,
                        this.updateComponent
                    )}
                    {this.props.subServiceType === "Gift Card Issuance" && CommonJsonFormComponent.renderJsonForm(
                        this.state,
                        giftCardRequisitionBom,
                        this.updateComponent
                    )}
                    {this.functionForFileDownload()}
                    <Grid item xs={12}>
                        {this.renderRemarksData()}
                        <br/>
                        {
                            this.renderRemarks()
                        }
                    </Grid>
                    <Grid item xs={3} style={{marginTop: "20px"}}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={this.handleSubmit}
                        >
                            Submit
                        </button>
                        &nbsp;&nbsp;
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={this.handleReturn}
                        >
                            Return
                        </button>
                    </Grid>
                </>
            )
        }
    };


    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };


    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.cbNotFound}>
                    <DialogContent className={classes.dialogPaper}>
                        <CBNotFound
                            closeModal={this.closeModalCBNotFound}
                            title={this.state.title}
                        />
                    </DialogContent>
                </Dialog>
                <Grid container>
                    <ThemeProvider theme={theme}>
                        {this.renderSearchData()}
                    </ThemeProvider>
                </Grid>
                {this.renderNotification()}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(CardTagging);
