import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import {Dialog, Grow} from "@material-ui/core";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import 'antd/dist/antd.css';
import FormSample from "../JsonForm/FormSample";
import DialogContent from "@material-ui/core/DialogContent";
import GroupView from "../grouping/GroupView";
import Grid from "@material-ui/core/Grid";
import Notification from "../NotificationMessage/Notification";
import CBNotFound from "../workflow/CASA/CBNotFound";
import loader from "../../Static/loader.gif";
import CloseIcon from "@material-ui/icons/Close";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import MyValidation from "../JsonForm/MyValidation";
import theme from "../JsonForm/CustomeTheme";
import {ThemeProvider} from "@material-ui/styles";
import Functions from "../../Common/Functions";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    dialogPaper: {
        overflow: "visible"
    }
};
const jsonForm = {
    "variables": [
        {
            "varName": "username",
            "type": "text",
            "label": "User ID",

        }


    ],

};
const permissionRoleList =[

    {
        "varName": "bmGroup",
        "type": "select",
        "grid": 2,
        "enum": [
            "BM"
        ],
        "onKeyDown": true,
        "label": "BM Group",

    },

    {
        "varName": "bmRoleList",
        "type": "select",
        "required": true,
        "grid": 3,
        "enum": [
        ],
        "label": "Role",
        "conditionalArray": true,
        "conditionalArrayList": ["bmGroupApi", "bmGroup"],
        "conditionalArrayValue": ["YES", "BM"],


    },
    {
        "type": "empty",
        "grid": 12,


    },
    {
        "varName": "bomGroup",
        "type": "select",
        "grid": 2,
        "enum": [
            "BOM"
        ],
        "onKeyDown": true,
        "label": "BOM Group",

    },
    {
        "varName": "bomRoleList",
        "type": "select",
        "required": true,
        "grid": 3,
        "enum": [],
        "label": "Role",
        "conditionalArray": true,
        "conditionalArrayList": ["bomGroupApi", "bomGroup"],
        "conditionalArrayValue": ["YES", "BOM"],

    },
    {
        "type": "empty",
        "grid": 8,


    },

];

function Transition(props) {

    return <Grow in={true} timeout="auto" {...props} />;
}

class AddRoleSwitchPermission extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addGroup: false,
            addMember: false,
            deactiveMember: false,
            tableData: [[" ", " "]],
            groupId: "",
            memberId: "",
            groupData: [[" ", " "]],
            open: true,
            inputData: {},
            varValue: {},
            showValue: true,
             searchTableData: false,
            singleGroupUid: '',
            singleDescription: '',
            loading: false,
            err: false,
            errorArray: {},
            errorMessages: {},
        }
    }

    createTableData = (id, username, firstName, lastName, email, phone, status) => {

        return ([username, firstName, lastName, email, phone, status, <button
            className="btn btn-outline-primary"
            style={{
                verticalAlign: 'middle',
            }}
            onClick={() => this.activate(username)}>
            Add Permission</button>


        ]);

    };


    activate = (username,roleName) => {
        let jsonList=[];
        if((this.state.inputData.bmGroup===undefined || this.state.inputData.bmGroup===null) && (this.state.inputData.bomGroup===undefined || this.state.inputData.bomGroup===null)){
            jsonList.push( {
                    "varName": "bmGroup",
                    "type": "select",
                    "required":true,
                    "grid": 2,
                    "enum": [
                        "BM"
                    ],
                    "onKeyDown": true,
                    "label": "BM Group",

                },


                {
                    "varName": "bomGroup",
                    "type": "select",
                    "grid": 2,
                    "required":true,
                    "enum": [
                        "BOM"
                    ],
                    "onKeyDown": true,
                    "label": "BOM Group",

                },)
            let error = MyValidation.defaultValidation(jsonList, this.state);
            this.forceUpdate();
            if(error===true){
                return 0;
            }
        }
        let error = MyValidation.defaultValidation(permissionRoleList, this.state);
        this.forceUpdate();
        if(error===true){
            return 0;
        }
        let roleList="";
        let flag=false;
        if(this.state.inputData.bmRoleList!==undefined && this.state.inputData.bmRoleList!==null && (this.state.inputData.bmRoleList).trim()!==""){
            roleList=this.state.inputData.bmRoleList;
            flag=true;
        }
        if(this.state.inputData.bomRoleList!==undefined && this.state.inputData.bomRoleList!==null && (this.state.inputData.bomRoleList).trim()!==""){
            if(flag===true){
                roleList+="&&";
            }
            roleList+=this.state.inputData.bomRoleList;
        }
        let data={};
        data.roleList=roleList;

        let url = backEndServerURL + "/addAnotherRoleChangePermission/" + username;
        axios.post(url, {roleList}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                if (response.data === true) {
                    this.setState({
                        title: "Successfull!",
                        notificationMessage: "Successfully Permission!",
                        alert: true
                    });
                    this.props.afterSuccessFullyAdd()

                } else {
                    this.setState({
                        title: "Error!",
                        errorType: "error",
                        notificationMessage: "Something Went Wrong!",
                        alert: true
                    })

                }


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    title: "Error!",
                    errorType: "error",
                    notificationMessage: Functions.errorObjectCheck(error),
                    alert: true
                })
            })
    };


    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    closeModal = () => {

        this.setState({
            addGroup: false,
            addMember: false,
            editSubSector: false,
            cbNotFound: false


        });

    };

    memberAddModal = () => {

        return (
            <GroupView member="yes" groupUid={this.state.singleGroupUid} onAdd={this.onAdd}
                       secondButtonFunction={this.closeModal} secondButtonName="close" name="Add New Member"/>
        )
    };
    reRenderUserData = (username) => {
        let string = username;

        let url = backEndServerURL + "/getUserStaticData/" + string;

        let tableArray = [];

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                tableArray = response.data;
                this.setState({
                    tableData: tableArray,
                    searchTableData: true,
                    loading: false,
                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    tableData: tableArray,
                    cbNotFound: true,
                    loading: false,

                })
            });

    };
    getSubmitedForm = (object) => {

        this.setState({
            loading: true,
            searchTableData: false

        });
        let string = object.username;

        let url = backEndServerURL + "/getUserStaticData/" + string;

        let tableArray = [];

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                tableArray = response.data;
                this.setState({
                    tableData: tableArray,
                    searchTableData: true,
                    loading: false,
                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    tableData: tableArray,
                    cbNotFound: true,
                    loading: false,

                })
            });


    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    updateComponent = () => {
        this.forceUpdate();
    };
    renderFilterForm = () => {
        return (
            <FormSample close={this.closeModal} grid="2" buttonName="search" onSubmit={this.getSubmitedForm}
                        jsonForm={jsonForm}/>
        )
    };

    onBlurFunctionForApiCall = (event, varName, secondVarName) => {
        event.preventDefault();
    }
    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = permissionRoleList ;
            for (let i = 0; i < jsonArray.length; i++) {
                let jsonObject = jsonArray[i];
                if (jsonObject.varName === searchVarname) {
                    permissionRoleList [i].enum = getValue;


                }
            }
        }
    onKeyDownChange = (data) => {
        if (data.varName === "bmGroup" || data.varName === "bomGroup") {
            this.setState({
                loading: true
            });

            if(this.state.inputData[data.varName]==="BM"){
                this.state.inputData.bmGroupApi = "NO";
            }
            else if(this.state.inputData[data.varName]==="BOM"){
                this.state.inputData.bomGroupApi = "NO";
            }
            this.updateComponent();
            let codeUrl = backEndServerURL + "/roleOfGroup/" + this.state.inputData[data.varName];
            axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    if(this.state.inputData[data.varName]==="BM"){
                        {
                            this.findByVarNameApiData( "bmRoleList", response.data)
                        }
                        this.state.inputData.bmGroupApi = "YES";
                    }
                    else if(this.state.inputData[data.varName]==="BOM"){
                        {
                            this.findByVarNameApiData( "bomRoleList", response.data)
                        }
                        this.state.inputData.bomGroupApi = "YES";
                    }
                    this.forceUpdate();
                    this.setState({
                        loading: false
                    })

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })
        }
    }
    renderJsonForm = () => {
        return (
            CommonJsonFormComponent.renderJsonForm(this.state, permissionRoleList, this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)
        )
    };
    renderActivateButton = (username, status) => {
        if (this.state.searchTableData && status !== "YES") {
            return (
                <button
                    className="btn btn-outline-primary"

                    onClick={() => this.activate(username)}>
                    Add Permission</button>
            )
        }
    };
    searchTableData = () => {

        if (this.state.searchTableData) {


            return (

                <Grid item xs={6}>
                    <h6><b>User ID </b> :{this.state.tableData.username}</h6>

                    <h6><b>Name </b> :{this.state.tableData.firstName} </h6>
                    {/*<h6><b>Last Name </b> :{this.state.tableData.lastName} </h6>*/}
                    <h6><b>Email </b> :{this.state.tableData.email}</h6>
                    <h6><b>Mobile Number </b> :{this.state.tableData.phone}</h6>
                    <h6><b>Status </b>:{this.state.tableData.status}</h6>

                </Grid>


            )

        } else {
            return "";
        }
    };
    closeIcon = () => {
          this.props.closeModal();


    };

    render() {
        const {classes} = this.props;
        return (
            <section>

                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.cbNotFound}>
                    <DialogContent className={classes.dialogPaper}>

                        <CBNotFound
                            closeModal={this.closeModal}
                            title="User Name Not Found"
                        />
                    </DialogContent>
                </Dialog>

                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color:'white'}} className={classes.cardTitleWhite}>Add User Permission<a><CloseIcon
                                    onClick={this.closeIcon}
                                    style={{
                                        position: 'absolute',
                                        right: 10,
                                        color: "#000000"
                                    }}/></a></h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    {this.renderNotification()}
                                    {this.renderFilterForm()}
                                </div>
                                <br/>
                                <div>
                                    {this.searchTableData()}
                                    {  this.state.searchTableData && this.state.tableData.status !== "YES"   &&
                                    <Grid container spacing={0}>
                                    <ThemeProvider theme={theme}>
                                    {this.renderJsonForm()}
                                    </ThemeProvider>
                                    </Grid>}
                                    <br/>
                                    {this.renderActivateButton(this.state.tableData.username, this.state.tableData.status)}
                                </div>


                            </CardBody>
                        </Card>
                    </GridItem>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>

                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>

                </GridContainer>
                {/*  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        {this.searchTableData()}
                    </GridItem>

                </GridContainer>*/}
            </section>
        );
    }
}

export default withStyles(styles)(AddRoleSwitchPermission);
