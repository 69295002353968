import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import TextFieldComponent from "../../../JsonForm/TextFieldComponent";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import SelectComponent from "../../../JsonForm/SelectComponent";
import Table from "../../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import Notification from "../../../NotificationMessage/Notification";
import MyValidation from "../../../JsonForm/MyValidation";
import Label from './Label';
import PreviewButton from "../PreviewButton";
import UploadComponent from "../casa/UploadComponent";
import loader from "../../../../Static/loader.gif";
import CBNotFound from "../../CASA/CBNotFound";
import DateComponentDeferal from "../../../JsonForm/DateComponentDeferal";
import {fdrMaintenance as encashment} from "./JsonForFdrMaintenance";
import FunctionForGetAcDetails from "../../CommonComponent/FunctionForGetAcDetails";
import FDRMaintenanceUtility from "./MaintenanceUtility";
import functionForFileDownload from "../../../DeliverableManagement/FunctionForFileDownload";
import CheckBox from "@material-ui/core/Checkbox/Checkbox";
import GridContainer from "../../../Grid/GridContainer";

var deferalOther = {
    "varName": "deferalOther",
    "type": "text",
    "label": "Remarks",
};

var deferal = {
    "varName": "deferalType",
    "type": "select",
    "label": "Deferral Type",
    "enum": [
        "Applicant Photograph",
        "Nominee Photograph",
        "Passport",
        "Address proof",
        "Transaction profile",
        "other"
    ],

};

var date = {
    "varName": "expireDate",
    "type": "text",
    "minDate": true,
    "label": "Expire Date",

};

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};
let searchFieldList = [
    {
        "varName": "eqmNumber",
        "type": "text",
        "label": "EQM Number",
        "grid": 2,

    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account No",
        "required": true,
        "grid": 2,

    },
    {
        "varName": "subCategory",
        "type": "select",
        "enum": [
            'FDR Encashment',
            'FDR Interest Payment',
            'Deceased FDR Encashment',
            'FDR Encashment Certificate',
            'Present FDR Encashment Value',
        ],
        "label": "FDR Maintenance Category",
        "required": true,
        "grid": 2,

    },
];

let searchFieldListReturn = [
    {
        "varName": "eqmNumber",
        "type": "text",
        "label": "EQM Number",
        "grid": 3,

    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account No",
        "required": true,
        "readOnly": true,
        "grid": 3,

    },
    {
        "varName": "subCategory",
        "type": "text",
        "label": "FDR Maintenance Category",
        "required": true,
        "readOnly": true,
        "grid": 3,

    },
];

let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];

class FdrEncashment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cbNotFound: false,
            searchTableData: false,
            inputData: {},
            values: [],
            appId: '',
            message: "",
            getData: false,
            varValue: {},
            title: "",
            notificationMessage: "",
            alert: false,
            type: [],
            fileUploadData: [],
            selectedDate: {},
            AddDeferal: false,
            showValue: false,
            getDeferalList: [],
            loading: true,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            caseId: 0,
        }
    }

    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };

    addDeferalForm() {
        return this.state.values.map((el, i) =>
            <React.Fragment>
                <Grid item xs={3}>
                    {
                        this.dynamicDeferral(el)
                    }
                </Grid>
                <Grid item xs={3}>
                    {
                        this.dynamicDate(el)
                    }
                </Grid>
                <Grid item xs={3}>
                    {this.dynamicDeferralOther(el)}
                </Grid>
                <Grid item xs={3}>
                    <button
                        style={{
                            float: "left",
                            marginTop: "15px"
                        }}
                        className="btn btn-outline-primary btn-sm"
                        type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                    >
                        Remove
                    </button>
                </Grid>
            </React.Fragment>
        )

    }

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    componentDidMount() {
        this.dynamicApiCall("fdrSchemeCodes", "fdrSchemeCode");
        var remarksArray = [];
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((vresponse) => {
                    for (let item of searchFieldList) {
                        item.readOnly = true
                    }
                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {

                                    this.setState({
                                        fileUploadData: response.data,
                                    })
                                })
                                .catch((error) => {
                                    console.log(error);
                                });

                            console.log(response.data);
                            let tableArray = [];
                            var status = "";
                            response.data.map((deferal) => {
                                if (deferal.status === "ACTIVE") {
                                    status = "Approved"
                                } else if (deferal.status === "NOTAPPROVED") {
                                    status = "NOT APPROVED"
                                } else {
                                    status = deferal.status;
                                }
                                tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));
                            });
                            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    response.data.map((data) => {
                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                    });
                                    let inputData = vresponse.data;
                                    inputData.csDeferal = "NO";
                                    this.setState({
                                        varValue: this.copyJson(inputData),
                                        inputData: inputData,
                                        searchTableData: true,
                                        getDeferalList: tableArray,
                                        showValue: true,
                                        getData: true,
                                        getRemarks: remarksArray,
                                        appId: this.props.appId,
                                    });
                                })
                                .catch((error) => {
                                    console.log(error)
                                })

                        })
                        .catch((error) => {
                            console.log(error);
                        });
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            let url = backEndServerURL + "/startCase/cs_maintenance";
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    for (let item of searchFieldList) {
                        item.readOnly = false
                    }
                    this.setState({
                        caseId: response.data.caseId,
                        appId: response.data.id,
                        showValue: true,
                        getData: true,
                        loading: false
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    dynamicApiCall = (fieldName, apiType) => {
        let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (apiType === "fdrSchemeCode") {
                    let jsonArrayServiceList = encashment;

                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "fdrSchemeCode") {
                            encashment[i].enum = response.data;

                        }
                    }
                }

            })
            .catch((error) => {
                console.log(error);
            })
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    renderAddButtonShow = () => {
        if (this.state.inputData.checker_approval !== "APPROVED") {
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        width: 100,
                        float: 'left',
                        verticalAlign: 'right',
                    }}
                    type='button' value='add more'
                    onClick={this.addClick.bind(this)}
                >Add Deferral</button>
            )
        }
    };

    dynamicDeferral = (i) => {
        let field = JSON.parse(JSON.stringify(deferal));
        field.varName = "deferalType" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };

    dynamicDeferralOther = (i) => {
        if (this.state.inputData["deferalType" + i] === "Others") {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        } else {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        }
    };

    dynamicDate = (i) => {
        let field = JSON.parse(JSON.stringify(date));
        field.varName = "expireDate" + i;
        return DateComponentDeferal.date(this.state, this.updateComponent, field);
    };

    removeClick(i, event) {
        event.preventDefault();
        let pos = this.state.values.indexOf(i);
        if (pos > -1) {
            this.state.values.splice(pos, 1);
            this.updateComponent();
            if (this.state.values.length > 0) {
                this.state.inputData["csDeferal"] = "YES"
            } else {
                this.state.inputData["csDeferal"] = "NO"
            }
        }
    }

    renderRemarks = () => {
        if (this.state.getData && this.state.inputData.checker_approval !== "APPROVED") {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
            )
        }
    };

    uploadFunction = () => {
        const {classes} = this.props;
        if (this.state.getData && this.state.inputData.checker_approval !== "APPROVED") {
            return (
                <GridContainer>
                    <Grid item xs={.5}>
                        <CheckBox
                            style={{marginTop: "-6px"}}
                            checked={this.state.inputData.uploadFileAlertRequest}
                            defaultValue={this.state.inputData.uploadFileAlertRequest}
                            onChange={(event) => this.handleChangeuploadFileAlertRequest(event)}/>
                    </Grid>
                    <Grid item xs={11}>
                        <UploadComponent previewImage={this.previewImage} showSignature={true} appId={this.state.appId} classes={classes}/>
                    </Grid>
                </GridContainer>
            )
        }
    };

    handleChangeuploadFileAlertRequest = (event) => {
        let data = event.target.checked;
        this.state.inputData.uploadFileAlertRequest = data;
        this.updateComponent();
        if (data === false) {
            this.setState({
                cbNotFound: true,
                title: "Without Document Process!!",
            })
        }
    };

    addClick() {
        let randomNumber = Math.floor(Math.random() * 100000000000);

        this.setState(prevState => ({
            values: [...prevState.values, randomNumber],

        }));

        this.state.inputData["csDeferal"] = "YES";
    }

    renderSearchFormReturn = () => {
        if (this.state.inputData.maker_approval === "RETURN" || this.state.inputData.returnDataBm === true || this.state.inputData.bom_maintenance_approval === "RETURN") {
            return (
                <React.Fragment>
                    <Grid container spacing={1}>
                        <ThemeProvider theme={theme}>
                            <Grid item xs={12}><br/></Grid>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, searchFieldListReturn, this.updateComponent)
                            }
                            <Grid item xs={12}><br/></Grid>
                        </ThemeProvider>
                    </Grid>
                </React.Fragment>
            )
        }
    };

    renderSearchForm = () => {
        return (
            <React.Fragment>
                <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, searchFieldList, this.updateComponent)
                        }
                        <Grid item xs={3}>
                            <button
                                style={{
                                    marginTop: "18px"
                                }}
                                onClick={this.handleSearch}
                                className="btn btn-danger btn-sm">
                                Search
                            </button>
                            &nbsp;
                            <button
                                style={{
                                    marginTop: "18px"
                                }}
                                onClick={() => window.location.reload()}
                                className="btn btn-danger btn-sm">
                                Reset
                            </button>
                        </Grid>
                    </ThemeProvider>
                </Grid>
            </React.Fragment>
        )

    };

    handleSearch = () => {
        let error = MyValidation.defaultValidation(searchFieldList, this.state);
        this.forceUpdate();
        if (error) {
            return 0;
        } else {
            this.setState({
                getData: false,
                searchTableData: false,
                loading: true,
            });
            let inputData = this.state.inputData;
            let checkAccount = backEndServerURL + "/GetAccountStatusFromAccountNumber/" + this.state.inputData.accountNumber;
            axios.get(checkAccount, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((getResponse) => {
                    console.log(getResponse.data);
                    if (getResponse.data === "Closed") {
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                            showValue: true,
                            cbNotFound: true,
                            title: "Account is Closed!",
                            loading: false,
                        });
                    } else {
                        let getAccountUrl = backEndServerURL + "/getTermDepositAccountFDR/" + this.state.inputData.accountNumber;
                        axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((acResponse) => {
                                inputData.csDeferal = "NO";
                                let url = backEndServerURL + "/GetCustomAcctDetailsRequest/" + this.state.inputData.accountNumber;
                                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        inputData.uploadFileAlertRequest = true;
                                        inputData.encashmentAcId = this.state.inputData.accountNumber;
                                        inputData.motherBranchSolId = response.data.solId;
                                        inputData.solId = response.data.solId;
                                        inputData.mainAccountCurrency = response.data.crncyCode;
                                        inputData.csDeferal = "NO";
                                        inputData.cbNumber = response.data.cifId;
                                        inputData.cifId = response.data.cifId;
                                        inputData.customerName = response.data.acctName;
                                        for (let item of searchFieldList) {
                                            if (item.varName === "subCategory") {
                                                item.readOnly = true
                                            }
                                        }
                                        this.setState({
                                            getData: true,
                                            searchTableData: true,
                                            inputData: inputData,
                                            varValue: this.copyJson(inputData),
                                            showValue: true,
                                            subCategory: this.state.inputData.subCategory,
                                        });
                                    })
                                    .catch((error) => {
                                        this.setState({
                                            inputData: inputData,
                                            varValue: this.copyJson(inputData),
                                            showValue: true,
                                            cbNotFound: true,
                                            title: error.response.data.message,
                                            loading: false,
                                        });
                                        console.log(error)
                                    });
                            })
                            .catch((error) => {
                                console.log(error);
                                this.state.varValue['eqmNumber'] = this.state.inputData.eqmNumber;
                                this.state.varValue['accountNumber'] = this.state.inputData.accountNumber;
                                this.state.varValue['subCategory'] = this.state.inputData.subCategory;
                                this.setState({
                                    loading: false,
                                    cbNotFound: true,
                                    title: error.response.data.message
                                });
                            })
                    }
                })
                .catch((error) => {
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        showValue: true,
                        cbNotFound: true,
                        title: error.response.data.message,
                        loading: false,
                    });
                    console.log(error)
                });
        }

    };


    renderData = () => {
        if (this.state.inputData.subCategory) {
            return (
                <React.Fragment>
                    <Grid container>
                        <ThemeProvider theme={theme}>
                            <Grid item xs={12}>{this.renderEncashmentCertificate()}</Grid>
                            <Grid item xs={12}>
                                {this.renderAddButtonShow()}
                            </Grid>
                            {this.addDeferalForm()}
                            <Grid item xs={12}>
                                {this.renderDefferalData()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderRemarksData()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.uploadFunction()}
                            </Grid>
                            {this.renderRemarks()}
                            <Grid item xs={12}>
                                {this.state.inputData.checker_approval !== "APPROVED" && <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{}}
                                    onClick={this.handleSubmit}
                                >
                                    Submit
                                </button>}
                                {/*&nbsp;&nbsp;&nbsp;*/}
                                {/*<button*/}
                                {/*    className="btn btn-outline-info btn-sm"*/}
                                {/*    style={{}}*/}
                                {/*    onClick={this.handleSubmitDraft}*/}
                                {/*>*/}
                                {/*    Draft*/}
                                {/*</button>*/}
                            </Grid>
                        </ThemeProvider>
                    </Grid>
                </React.Fragment>
            )
        }
    };

    functionForOnKeyDown = (event, data) => {
        if (data === "acNumber") {
            this.state.inputData.showAcName = false;
            let inputData = this.state.inputData;
            this.setState({
                loading: true
            });
            FunctionForGetAcDetails.getAccountInfo(this.state.inputData["acNumber"]).then((response) => {
                inputData.acName = response.data.acctName;
                setTimeout(() => {
                    inputData.showAcName = true;
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        showValue: true,
                        loading: false
                    });
                }, 1000)
            }).catch((error) => {
                this.setState({
                    inputData: inputData,
                    varValue: this.copyJson(inputData),
                    loading: false,
                    title: error.response.data.message,
                    cbNotFound: true,
                })
            })
        }
    };

    renderSearchData = () => {
        if (this.state.getData && this.state.searchTableData) {
            return (
                <React.Fragment>
                    <Grid container spacing={1}>
                        <ThemeProvider theme={theme}>
                            {CommonJsonFormComponent.renderJsonForm(this.state, encashment, this.updateComponent, this.functionForOnKeyDown)}
                            {this.renderData()}
                        </ThemeProvider>
                    </Grid>
                </React.Fragment>
            )
        }
    };

    stopLoading = (value) => {
        if (value === "yes") {
            setTimeout(() => {
                this.setState({
                    loading: false,
                });
            }, 1000)
        } else {
            this.setState({
                getData: false,
                searchData: false,
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    renderCustomerDetails = () => {
        if (this.state.getData && this.state.searchTableData) {
            return (
                <Label cbNumber={this.state.inputData.cbNumber} stopLoading={this.stopLoading}
                       subCategory={this.state.inputData.subCategory} accountNumber={this.state.inputData.accountNumber}
                       classes={this.props}/>
            )
        }
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    handleSubmit = (event) => {
        event.preventDefault();

        let error = MyValidation.defaultValidation(encashment, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }
        else{
            if (this.state.inputData.uploadFileAlertRequest && (this.state.fileUploadData.length === 0 && this.state.inputData.previewImage === undefined)) {
                this.setState({
                    cbNotFound: true,
                    title: "Please Upload File!"
                })
            } else {
                if (this.state.inputData["csDeferal"] === "YES") {
                    var defType = [];
                    var expDate = [];

                    let appId = this.state.appId;
                    for (let i = 0; i < this.state.values.length; i++) {
                        let value = this.state.values[i];
                        let deferalType = this.state.inputData["deferalType" + value];
                        if (deferalType === "other") {
                            deferalType = this.state.inputData["deferalOther" + value];
                        }
                        defType.push(deferalType);
                        let expireDate = this.state.inputData["expireDate" + value];
                        expDate.push(expireDate);

                    }
                    let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";
                    axios.post(deferalRaisedUrl, {
                        appId: appId,
                        type: defType,
                        dueDate: expDate
                    }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log("deferal created")
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                }

                let data = this.state.inputData;
                data.csRemarks = undefined;
                data.serviceType = "Maintenance";
                data.subServiceType = "FDRMaintenance";
                data.category = "FDR";
                data.subCategory = this.state.inputData.subCategory;
                data.cs_bearer = "NO";
                data.freeFlag2 = "FDR Encashment";
                data.freeFlag3 = `${this.state.inputData.subCategory} Case ID: ${this.state.caseId}`;

                if (this.state.inputData.csDeferal === "NO" || this.state.inputData.csDeferal === undefined) {
                    data.cs_deferal = "NO";
                } else if (this.state.inputData.csDeferal === "YES") {
                    data.cs_deferal = "YES";
                }

                let remarksData = {};
                remarksData.remark = this.state.inputData.csRemarks;
                remarksData.map = {...data, csRemarks: undefined};
                let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
                axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Routed!",
                        alert: true,
                        loading: false
                    });
                    setTimeout(function () {
                        window.location.reload()
                    }, 1000);
                }).catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
            }
        }
    };

    handleSubmitDraft = (event) => {
        event.preventDefault();

        if (this.state.inputData["csDeferal"] === "YES") {
            var defType = [];
            var expDate = [];

            let appId = this.state.appId;
            for (let i = 0; i < this.state.values.length; i++) {
                let value = this.state.values[i];
                let defferalType = this.state.inputData["deferalType" + value];
                if (defferalType === "other") {
                    defferalType = this.state.inputData["deferalOther" + value];
                }
                defType.push(defferalType);
                let expireDate = this.state.inputData["expireDate" + value];
                expDate.push(expireDate);

            }

            let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";
            axios.post(deferalRaisedUrl, {
                appId: appId,
                type: defType,
                dueDate: expDate
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        var variableSetUrl = backEndServerURL + "/save/" + this.state.appId;
        let data = this.state.inputData;
        data.serviceType = "Maintenance";
        data.subServiceType = "FDRMaintenance";
        data.category = "FDR";
        data.subCategory = this.state.inputData.subCategory;
        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Draft!",
                    alert: true
                });
                this.close()
            })
            .catch((error) => {
                console.log(error)
            });

    };

    close = () => {
        if (this.props.appId !== undefined) {
            this.props.closeModal();
        }
    };

    previewButton = () => {
        if (this.state.searchTableData && this.state.inputData.subCategory) {
            return (
                <React.Fragment>
                    <br/>
                    <Grid container spacing={0}>
                        <PreviewButton appId={this.state.appId} classes={this.props}/>
                    </Grid>
                </React.Fragment>
            )
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return FDRMaintenanceUtility.renderMaintenanceHeader(this.props.freeFlag3, this.props.solId, this.props.cbNumber, this.props.accountNumber, this.props.customerName, this.close);
        } else {
            return (
                <h4 style={{color: "white"}} >FDR Encashment</h4>
            )
        }
    };

    renderAllDialog = () => {
        const {classes} = this.props;
        return (
            <>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.cbNotFound}>
                    <DialogContent className={classes.dialogPaper}>
                        <CBNotFound
                            closeModal={this.closeModalCBNotFound}
                            title={this.state.title}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            </>
        )
    };

    renderEncashmentCertificate = () => {
        if (this.state.inputData.subCategory === "FDR Encashment Certificate" || this.state.inputData.checker_approval === "APPROVED") {
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',
                        marginTop: 20
                    }}
                    disabled={this.state.disabled}
                    type='button' value='add more'
                    onClick={this.downloadCertificate}
                >Download Certificate
                </button>
            )
        }
    };

    downloadCertificate = () => {
        this.setState({
            loading: true
        });
        let url = backEndServerURL + "/getFDREncashmentCertificate";
        functionForFileDownload.downloadFile(url, "POST", this.state.inputData, "Encashment Certificate.pdf").then((response) => {
            this.setState({
                loading: false
            })
        }).catch((error) => {
            this.setState({
                loading: false
            })
        })
    };


    render() {
        Functions.redirectToLogin(this.state);
        if (this.state.inputData.maker_approval === "RETURN" || this.state.inputData.returnDataBm === true || this.state.inputData.checker_approval === "APPROVED" || this.state.inputData.bom_maintenance_approval === "RETURN") {
            return (
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {this.renderHeader()}
                    </CardHeader>
                    <CardBody>
                        {this.renderAllDialog()}
                        {this.renderSearchFormReturn()}
                        {this.renderNotification()}
                        {this.renderCustomerDetails()}
                        {this.previewButton()}
                        {this.renderSearchData()}
                    </CardBody>
                </Card>
            );
        } else {
            return (
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {this.renderHeader()}
                    </CardHeader>
                    <CardBody>
                        {this.renderAllDialog()}
                        {this.renderSearchForm()}
                        {this.renderNotification()}
                        {this.renderCustomerDetails()}
                        {this.previewButton()}
                        {this.renderSearchData()}
                    </CardBody>
                </Card>
            );
        }
    }

}

export default withStyles(styles)(FdrEncashment);
