import React, {Component} from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import Grid from "@material-ui/core/Grid";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";

let ImageRemarks = [
    {
        "varName": "imageRemarks",
        "type": "textArea",
        "grid": 6,
    }
];

class SubImageCrop extends Component {
    state = {
        selectedImageURL: null,
        selectedImageURL1: null,
        //selectedImageURL: sample,
        crop: {
            x: 20,
            y: 10,
            width: 30,
            height: 10,
            //aspect: 16 / 9,
        },
        selectedFile: null,
        croppedImage: null,
        croppedImage1: null,
        modalWidth: 0,
        modalHeight: 0,
        errorMessages: {},
        errorArray: {},
        inputData: {},
        varValue: {},
        showValue: false,
        getData: false,
    };
    updateComponent = () => {
        this.forceUpdate();
    };

    componentDidMount() {
      if(this.props.signatureButtonShowInImageCropedPage){
          let fileReader = new FileReader();
          axios.get(backEndServerURL + "/file/" + this.props.imageLink, {
              headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`},
              responseType: 'blob'
          })
              .then((response) => {
                  console.log(response);
                  fileReader.addEventListener('load', () =>
                      this.setState({
                          selectedImageURL: fileReader.result,
                      })
                  );
                  fileReader.readAsDataURL(response.data);
                  let inputData = this.state.inputData;
                  inputData.deleteFlag[this.props.imageNumber] = "YES";
                  this.setState({
                      inputData: inputData,
                      getData: true,
                      showValue: true,
                      getDocument: true
                  })
              })
              .catch((error) => {
                  console.log("error");
              })

      }
      else{
          let fileReader = new FileReader();
          axios.get(backEndServerURL + "/file/" + this.props.imageLink, {
              headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`},
              responseType: 'blob'
          })
              .then((response) => {
                  console.log(response);
                  fileReader.addEventListener('load', () =>
                      this.setState({
                          selectedImageURL: fileReader.result,
                      })
                  );
                  fileReader.readAsDataURL(response.data);
                  let inputData = this.state.inputData;
                  inputData.deleteFlag[this.props.imageNumber] = "YES";
                  this.setState({
                      inputData: inputData,
                      getData: true,
                      showValue: true,
                      getDocument: true
                  })
              })
              .catch((error) => {
                  console.log("error");
              })

      }

    }


    onCropComplete = (crop, pixels) => {
    };


    onCropChange = (crop) => {
        let modalWidth = document.getElementById("image-crop").offsetWidth;
        let modalHeight = document.getElementById("image-crop").offsetHeight;
        this.setState({crop, modalHeight, modalWidth});
        const croppedImg = this.getCroppedImg(this.refImageCrop, crop);
        this.setState({showImageCropper: false, croppedImage: croppedImg})
    };


    getCroppedImg(image, pixelCrop) {
        /* the parameters: - the image element - the new width - the new height - the x point we start taking pixels - the y point we start taking pixels - the ratio */
        // Set up canvas for thumbnail
        // console.log(imgObj);
        // let img = new Image();
        // img.src = this.state.selectedImageURL;
        // let tempCanvas = document.createElement('canvas');
        // let tnCanvas = tempCanvas;
        // tnCanvas.width = newWidth;
        // tnCanvas.height = newHeight;
        // tnCanvas.getContext('2d').drawImage(img, startX, startY, newWidth, newHeight);
        // return tnCanvas;

        //let image = new Image();
        var image = new Image();
        image.src = this.state.selectedImageURL;
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / this.state.modalWidth;
        const scaleY = (image.naturalHeight / this.state.modalHeight);
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');

        console.log(image.naturalWidth, image.naturalHeight);
        console.log(scaleY, scaleY, pixelCrop.width, pixelCrop.height);

        ctx.drawImage(
            image,
            Math.floor(pixelCrop.x * scaleX),
            Math.floor(pixelCrop.y * scaleY),
            Math.ceil(pixelCrop.width * scaleX),
            Math.ceil(pixelCrop.height * scaleY + 15),
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        );

        return canvas.toDataURL('image/jpeg');
    }


    close = () => {
        this.props.closeModal();
    };
    handleSubmit = () => {


        if (this.props.handleSubmit) {
            this.props.handleSubmitClose();

            /*     let dependency=[
                    {
                        "varName": "deleteFlag"+this.props.imageNumber,
                        "type": "select",
                        "enum":[
                            "YES",
                            "NO",
                        ],
                        "required":true,
                        "label":"Delete Flag",
                        "multiline": true,
                        "grid": 6,
                    }
                ]
                let error = MyValidation.defaultValidation(dependency, this.state)
                this.forceUpdate();

                if (error === true) {
                    console.log("Not working")
                    return 0;
                }*/
            let variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
            axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);

                })
                .catch((error) => {
                    console.log(error);
                });
            let object = {
                appId: this.props.appId,
                image: this.state.croppedImage,
                type: "CARD" + this.props.imageNumber

            };

            console.log(object.type);

            let fileUploadPath = backEndServerURL + "/upload/signature/photo";
            axios({
                method: 'post',
                url: fileUploadPath,
                data: object,
                headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}

            })
                .then((response) => {
                    console.log(response);
                    console.log("api call");
                    //this.props.closeModal();
                    console.log("pppp");
                    console.log(response);


                })
                .catch((error) => {
                    console.log(error)
                })

        }


    };
    close = () => {
        this.props.closeModal()
    };
    renderImageRemarks = () => {
        return (

            CommonJsonFormComponent.renderJsonForm(this.state, ImageRemarks, this.updateComponent)
        )


    };
    renderDeleteFlag = () => {
        if (this.state.getData === true) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, [{
                    "varName": "deleteFlag" + this.props.imageNumber,
                    "type": "select",
                    "enum": [
                        "YES",
                        "NO",
                    ],
                    "required": true,
                    "label": "Delete Flag",
                    "multiline": true,
                    "grid": 6,
                }], this.updateComponent)
            )
        }

    };

    render() {
        return (
            <div>

                <Card>

                    <CardBody>
                        <Grid container spacing={1}>
                            <Grid item xs={1}>
                            </Grid>
                            <Grid item xs={10}>
                                <div className="App">

                                    <div>
                                        {this.handleSubmit()}
                                        <h4 style={{color: "white"}} >{this.props.imageName}</h4>

                                        <div id='image-crop'>
                                            <ReactCrop
                                                src={this.state.selectedImageURL}
                                                crop={this.state.crop}
                                                // onImageLoaded={this.onImageLoaded}
                                                onComplete={this.onCropComplete}
                                                onChange={this.onCropChange}
                                            />
                                        </div>
                                    </div>
                                    {/*    <div>
                                        {this.renderDeleteFlag()}
                                    </div>*/}
                                    <div>
                                        <table border="4">

                                            <img src={this.state.croppedImage} alt=""/>
                                        </table>

                                    </div>

                                </div>
                            </Grid>
                            <Grid item xs={1}>
                            </Grid>


                        </Grid>

                    </CardBody>
                </Card>
            </div>

        )


    }
}

export default SubImageCrop
