import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Table from "../../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import Notification from "../../../NotificationMessage/Notification";
import loader from "../../../../Static/loader.gif";
import MyValidation from "../../../JsonForm/MyValidation";
import SignatureButton from "../SignatureButton";
import Label from "./CasaCloserLabel";
import PreviewButton from "../PreviewButton";
import UploadComponent from "./UploadComponent";
import MaintenanceUtility from "../static/MaintenanceUtility";

const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};


let accountMaintenanceSearch = [
    {
        "varName": "eqmNumber",
        "type": "text",
        "validation": "numeric",
        "label": "EQM Number",
        "readOnly": true,
        "grid": 2,

    },
    {
        "varName": "accountNumber",
        "type": "text",
        "readOnly": true,
        "validation": "numeric",
        "label": "Account No",
        "grid": 2,

    }
];

let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];

let requestType = [
    {
        "varName": "closerRequestName",
        "type": "select",
        "label": "Input Transfer Account Number",
        "grid": 3,
        "readOnly": true,
        "enum": [
            'Own AC',
            'Third Party AC',
            'Payment to GL AC',
            'Payorder',
            'EFT Payment',
            'RTGS Payment'
        ]
    }];

let closerRequestForm = [

    {
        "varName": "inputAccountNumber",
        "type": "text",
        "validation": "numeric",
        "label": "Account Number",
        "grid": 3,
    },
    {
        "varName": "inputAccountName",
        "type": "text",
        "label": "Account Name",
        "validation": "string",
        "readOnly": true,
        "grid": 3,
    }
];


class AccountMaintenance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tableData: [],
            inputData: {},
            appId: 0,
            getData: false,
            varValue: {},
            title: "",
            notificationMessage: "",
            alert: false,
            type: [],
            searchData: false,
            fileUploadData: [],
            selectedDate: {},
            dropdownSearchData: {},
            showValue: true,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
        };

    }

    getSearchvalue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    componentDidMount() {
        if (this.state.inputData["csBearer"] === "NO") {
            this.state.inputData["csBearer"] = "";
        }
        let remarksArray = [];
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            response.data.map((data) => {
                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                            });
                        })
                        .catch((error) => {
                            console.log(error)
                        });

                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    this.setState({
                                        fileUploadData: response.data,
                                        getDocument: true,
                                        searchData: true
                                    })
                                })
                                .catch((error) => {
                                    console.log(error);
                                });

                            let tableArray = [];
                            var status = "";
                            response.data.map((deferal) => {
                                if (deferal.status === "ACTIVE") {
                                    status = "Approved"
                                } else if (deferal.status === "NOTAPPROVED") {
                                    status = "NOT APPROVED"
                                } else {
                                    status = deferal.status;
                                }
                                tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));
                            });
                            this.setState({
                                getDeferalList: tableArray
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    this.state.inputData["csDeferal"] = "NO";
                    let varValue = response.data;
                    this.setState({
                        appId: this.props.appId,
                        inputData: this.getSearchvalue(response.data),
                        varValue: varValue,
                        getRemarks: remarksArray,
                        showValue: true,
                        getData: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        }


    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderCustomerDetails = () => {
        if (this.state.inputData.accountNumber && this.state.searchData === true) {
            return (
                <Label input={this.state.inputData} stopLoading={this.stopLoading} classes={this.props}/>
            )
        }
    };


    stopLoading = (value) => {
        if (value === "yes") {
            setTimeout(() => {
                this.setState({
                    loading: false,
                });
            }, 1000)
        } else {
            this.setState({
                getData: false,
                searchData: false,
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        <React.Fragment>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
                            }
                        </React.Fragment>
                    </ThemeProvider>
                </Grid>
            )
        }
    };
    renderSearchForm = () => {
        if (this.state.getData) {
            return (
                <Grid item xs={12}>
                    <Grid container>
                        <ThemeProvider theme={theme}>

                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, accountMaintenanceSearch, this.updateComponent)
                            }
                            <Grid item xs={12}>
                            </Grid>
                        </ThemeProvider>
                    </Grid>
                </Grid>
            )
        }
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    handleClose = (event) => {
        event.preventDefault();
        let variableSetUrl = backEndServerURL + "/case/close/" + this.props.appId;
        axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Close!",
                        alert: true
                    });
                    this.props.closeModal();
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };

    handleSubmitButton = () => {
        if (this.state.getData) {
            return (
                <ThemeProvider theme={theme}>
                    <Grid item xs={12}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{}}
                            onClick={this.handleSubmit}
                        >
                            Submit
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                            className="btn btn-outline-info btn-sm"
                            style={{}}
                            onClick={this.handleClose}
                        >
                            Close
                        </button>
                    </Grid>
                </ThemeProvider>
            )
        }
    };


    handleSubmit = (event) => {
        event.preventDefault();
        let error = MyValidation.defaultValidation(requestType, this.state);
        let errorTwo = MyValidation.defaultValidation(closerRequestForm, this.state);
        this.forceUpdate();
        if (error === true || errorTwo === true) {
            return 0;
        } else {
            let data = this.state.inputData;
            data.serviceType = "Maintenance";
            data.subServiceType = "AccountMaintenance";
            data.cs_deferal = "NO";
            data.category = "CASA Closer";
            data.subCategory = "Maintenance";
            data.maintenanceValueRequestType = "Closer";
            let remarksData = {};
            remarksData.remark = this.state.inputData.csRemarks;
            remarksData.map = {...data, csRemarks: undefined};
            let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
            axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                    loading: false
                });
                this.props.closeModal();
            }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
        }
    };


    signature = () => {
        if (this.state.searchData === true && this.state.getData) {
            return (
                <React.Fragment>
                    {
                        this.previewButton()
                    }
                </React.Fragment>
            )
        }
    };

    requestForm = () => {
        if (this.state.getData) {
            return (
                <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        <React.Fragment>
                            <br/>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, closerRequestForm, this.updateComponent)
                            }
                        </React.Fragment>
                    </ThemeProvider>
                </Grid>
            )
        }
    };

    renderMaintenanceRequest = () => {
        if (this.state.getData) {
            return (
                <React.Fragment>
                    <Grid container spacing={1}>
                        <ThemeProvider theme={theme}>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, requestType, this.updateComponent)
                            }
                        </ThemeProvider>
                    </Grid>
                </React.Fragment>

            )
        }
    };

    previewButton = () => {

        return (
            <React.Fragment>
                <Grid container spacing={0}>
                    <Grid item xs={12}><br/></Grid>
                    <SignatureButton accountNumber={this.state.inputData.accountNumber} classes={this.props}/>
                    &nbsp;&nbsp;
                    <PreviewButton appId={this.props.appId} classes={this.props}/>
                    <Grid item xs={12}><br/></Grid>
                </Grid>
            </React.Fragment>
        )

    };
    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <br/>
                    <br/>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    uploadFunction = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return <UploadComponent appId={this.props.appId} classes={classes}/>
        }
    };


    close = () => {
        if (this.props.appId !== undefined) {
            this.props.closeModal();
        }
    };

    renderHeader = () => {
        return MaintenanceUtility.renderCloserMaintenanceHeader(this.props.freeFlag3, this.props.solId, this.props.cbNumber, this.props.accountNumber, this.props.customerName, this.close)
    };


    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);

        return (
            <Card>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderHeader()}
                </CardHeader>
                <CardBody>
                    {this.renderSearchForm(classes.root)}
                    {this.renderCustomerDetails()}
                    {this.signature()}
                    {this.renderMaintenanceRequest()}
                    {this.requestForm()}
                    <Grid item xs={12}><br/></Grid>
                    {this.uploadFunction()}
                    {this.renderRemarksData()}
                    {this.renderRemarks()}
                    <Grid item xs={12}><br/></Grid>
                    {this.handleSubmitButton()}
                    {this.renderNotification()}
                </CardBody>
            </Card>
        );
    }

}

export default withStyles(styles)(AccountMaintenance);
