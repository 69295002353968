import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../Grid/GridItem.jsx";
import GridContainer from "../../Grid/GridContainer.jsx";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CloseIcon from '@material-ui/icons/Close';
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Notification from "../../NotificationMessage/Notification";
import {BomCreditTransactionJsonFormForCard, BomDebitCardJsonFormForCard} from '../../workflow/WorkflowJsonFormRashed';
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import ShowDocument from './ShowDocuments'
import loader from "../../../Static/loader.gif";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};


class CsOpening extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDate: {},
            inputData: {},
            varValue: {},
            showValue: true,
            appId: 0,
            getCheckerList: [],
            checkerListShow: false,
            fileUploadData: {},
            errorArray: {},
            errorMessages: {},
            selectType: "",
            title: "",
            alert: false,
            message: "",
            notificationMessage: "",
            getData: false,
            documentModal: false,
            loading: true

        };
        this.verifyHandler = this.verifyHandler.bind(this);
    }

    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };

    componentDidMount() {

        console.log(this.props.appId);

        let url = backEndServerURL + "/variables/" + this.props.appId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);

                this.setState({
                    inputData: this.copyJson(response.data),
                    varValue: response.data,
                    getData: true,
                    loading: false
                });


            })
            .catch((error) => {
                console.log(error);
            })


    }

    updateComponent = () => {
        this.forceUpdate();
    };
    renderJsonFormForDebit = () => {
        if (this.state.getData) {
            return (

                CommonJsonFormComponent.renderJsonForm(this.state, BomDebitCardJsonFormForCard, this.updateComponent)

            )
        }
    };

    renderJsonFormForCredit = () => {
        if (this.state.getData) {
            return (

                CommonJsonFormComponent.renderJsonForm(this.state, BomCreditTransactionJsonFormForCard, this.updateComponent)

            )
        }
    };

    close = () => {
        this.props.closeModal()
    };

    verifyHandler = (event) => {
        event.preventDefault();
        console.log(this.state.inputData);


        var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;

        let data = this.state.inputData;
        data.serviceType = "Card Cheque";
        data.subServiceType = "Card Cheque Request";
        data.bom_approval = "APPROVED";

        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                var url = backEndServerURL + "/case/route/" + this.props.appId;
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);
                        console.log("Successfully Routed!");

                        this.close();
                        this.setState({
                            title: "Successful!",
                            notificationMessage: "Successfully Routed!",
                            alert: true

                        })
                    })
                    .catch((error) => {
                        console.log(error);

                    });
            })
            .catch((error) => {
                console.log(error)
            });
    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };

    returnHandler = (e) => {
        e.preventDefault();

        let veriablesUrl = backEndServerURL + "/variables/" + this.props.appId;

        let data = this.state.inputData;
        data.serviceType = "Card Cheque";
        data.subServiceType = "Card Cheque Request";
        data.bom_approval = "RETURN";

        axios.post(veriablesUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                axios.get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.close();
                        this.setState({
                            title: "Successfull!",
                            notificationMessage: "Return Successfully!",
                            alert: true
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    };
    open = (e) => {
        e.preventDefault();
        this.setState({
            documentModal: true
        })
    };

    documentModal = () => {
        this.setState({
            documentModal: false
        })
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    render() {

        const {classes} = this.props;
        if (this.state.loading === true) {
            return (
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h6>New
                            Customer
                            Account Opening<a><CloseIcon onClick={this.close} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></h6>

                    </CardHeader>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>

                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                </Card>


            )
        } else {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardBody>
                                <div>

                                    <h4 style={{
                                        fontSize: '14px',
                                        marginTop: "15px",
                                        marginBottom: '15px',
                                        textTransform: "capitalize",
                                        fontWeight: 'bold'
                                    }}>Debit Transaction</h4>

                                </div>

                                <div>
                                    <Grid container>
                                        <ThemeProvider theme={theme}>

                                            {this.renderNotification()}
                                            {this.renderJsonFormForDebit()}


                                        </ThemeProvider>


                                    </Grid>
                                </div>

                                <div>
                                    <button
                                        style={{
                                            marginTop: '10px'
                                        }}
                                        className="btn btn-outline-primary"
                                        type='submit'
                                        onClick={this.open}
                                    >
                                        Signature
                                    </button>

                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="md"
                                        open={this.state.documentModal}>
                                        <DialogContent>
                                            <ShowDocument closeModal={this.documentModal} appId={this.props.appId}/>
                                        </DialogContent>
                                    </Dialog>

                                    <h4 style={{
                                        fontSize: '14px',
                                        marginTop: "20px",
                                        marginBottom: '15px',
                                        textTransform: "capitalize",
                                        fontWeight: 'bold'
                                    }}>Credit Transaction</h4>

                                </div>

                                <div>
                                    <Grid container>
                                        <ThemeProvider theme={theme}>


                                            {
                                                this.renderJsonFormForCredit()
                                            }

                                        </ThemeProvider>

                                    </Grid>
                                </div>


                                <button
                                    className="btn btn-outline-primary"
                                    style={{

                                        marginTop: "10px",
                                        marginRight: '5px'
                                    }}
                                    onClick={this.verifyHandler}

                                >
                                    Approve
                                </button>
                                <button
                                    className="btn btn-outline-primary"
                                    style={{
                                        marginTop: "10px",
                                        marginLeft: '5px',
                                        backgroundColor: 'red',
                                        color: '#ffffff'
                                    }}
                                    onClick={this.returnHandler}

                                >
                                    Return
                                </button>

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>

            );
        }

    }


}

export default withStyles(styles)(CsOpening);
