import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";

import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import '../../Static/css/login.css';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    fab: {
        backgroundColor: '#ff3443',
        '&:hover': {
            backgroundColor: "#ff3443"
        },
        marginLeft: 0
    },
    inputStyle: {
        color: '#4352d8',
        '&:hover': {
            color: "#ff3443"
        }
    }

};

class ExistingCustomer extends Component {


    render() {
        const {classes} = this.props;
        return (

            <GridContainer>

                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>Existing Customer Account opening</h4>
                        </CardHeader>
                        <CardBody>
                            <div className={classes.root}>
                                <form onSubmit={this.handlesubmit} className={classes.form}>
                                    <Grid container spacing={24}>

                                        <Grid item xs={12} sm={3}>
                                            <Fab variant="extended" aria-label="Delete" className={classes.fab}>

                                                Exist
                                            </Fab>


                                            <FormControl className={classes.inputStyle} margin="normal" fullWidth>
                                                <InputLabel htmlFor="cb">Cb Number</InputLabel>
                                                <Input readOnly defaultValue="2347876" onChange={this.handlecb} id="cb"
                                                       name="cb" autoComplete="cb" autoFocus/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                Date of Birth
                                                <Input onChange={this.handledob} name="dob" readOnly
                                                       defaultValue="5/15/1993" id="dob" autoComplete="dob"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="nid">Nid No</InputLabel>
                                                <Input onChange={this.handlenid} name="nid" readOnly
                                                       defaultValue="1234567876543" id="nid" autoComplete="nid"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="passport">Passport No</InputLabel>
                                                <Input onChange={this.handlepassport} name="passport" id="passport"
                                                       readOnly defaultValue="19934567654" autoComplete="passport"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="phone">Phone No</InputLabel>
                                                <Input onChange={this.handlephone} name="phone" id="phone" readOnly
                                                       defaultValue="017134567876" autoComplete="phone"/>
                                            </FormControl>


                                        </Grid>

                                        <Grid item xs={12} sm={3}>
                                            <Fab variant="extended" aria-label="Delete" className={classes.fab}>

                                                New
                                            </Fab>

                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="cb">Cb Number</InputLabel>
                                                <Input onChange={this.handlecb} id="cb" name="cb" autoComplete="cb"
                                                       autoFocus/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                Date of Birth
                                                <Input onChange={this.handledob} name="dob" type="Date" id="dob"
                                                       autoComplete="dob"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="nid">Nid No</InputLabel>
                                                <Input onChange={this.handlenid} name="nid" id="nid"
                                                       autoComplete="nid"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="passport">Passport No</InputLabel>
                                                <Input onChange={this.handlepassport} name="passport" id="passport"
                                                       autoComplete="passport"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="phone">Phone No</InputLabel>
                                                <Input onChange={this.handlephone} name="phone" id="phone"
                                                       autoComplete="phone"/>
                                            </FormControl>


                                        </Grid>
                                        <Grid item xs={12} sm={3}>

                                            <Fab variant="extended" aria-label="Delete" className={classes.fab}>

                                                Exist
                                            </Fab>


                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="bCertificate">Birth Crtificate No</InputLabel>
                                                <Input onChange={this.handlebCertificate} name="bCertificate" id="nid"
                                                       readOnly defaultValue="19923456543" autoComplete="bCertificate"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="drivingLicense">Driving License</InputLabel>
                                                <Input onChange={this.handledrivingLicense} name="drivingLicense"
                                                       id="drivingLicense" readOnly defaultValue="e234576543"
                                                       autoComplete="drivingLicense"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="email">Email</InputLabel>
                                                <Input onChange={this.handleemail} name="email" id="email" readOnly
                                                       defaultValue="nbiplob15@gmail.com" autoComplete="email"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="cAddress">Communication Address</InputLabel>
                                                <Input onChange={this.handlebcAddress} name="cAddress" id="cAddress"
                                                       autoComplete="cAddress" readOnly defaultValue="Dhanmondi,dhaka"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="pAddress">Permanent Address</InputLabel>
                                                <Input onChange={this.handlepAddress} name="pAddress"
                                                       id="pAddress" autoComplete="pAddress" readOnly
                                                       defaultValue="khordo,kalaroa,satkhira"/>
                                            </FormControl>


                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <Fab variant="extended" aria-label="Delete" className={classes.fab}>

                                                New
                                            </Fab>


                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="bCertificate">Birth Crtificate No</InputLabel>
                                                <Input onChange={this.handlebCertificate} name="bCertificate" id="nid"
                                                       autoComplete="bCertificate"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="drivingLicense">Driving License</InputLabel>
                                                <Input onChange={this.handledrivingLicense} name="drivingLicense"
                                                       id="drivingLicense" autoComplete="drivingLicense"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="email">Email</InputLabel>
                                                <Input onChange={this.handleemail} name="email" id="email"
                                                       autoComplete="email"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="cAddress">Communication Address</InputLabel>
                                                <Input onChange={this.handlebcAddress} name="cAddress" id="cAddress"
                                                       autoComplete="cAddress"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="pAddress">Permanent Address</InputLabel>
                                                <Input onChange={this.handlepAddress} name="pAddress"
                                                       id="pAddress" autoComplete="pAddress"/>
                                            </FormControl>


                                        </Grid>

                                    </Grid>
                                    <center>
                                        <Button
                                            type="primary" htmlType="submit" className={classes.Buttoncolorchange}


                                            variant="contained"
                                            color="secondary"

                                        >

                                            Account Submit
                                        </Button>
                                    </center>
                                </form>
                            </div>


                        </CardBody>
                    </Card>
                </GridItem>

            </GridContainer>

        );
    }
}

export default withStyles(styles)(ExistingCustomer);
