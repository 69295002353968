import {createMuiTheme} from "@material-ui/core";

let theme = createMuiTheme({
    overrides: {
        /* MuiInputLabel: {
             root: {
                 color: "LIGHTCORAL",
                 "&$focused": {
                     color: "LIGHTCORAL",
                 }
             }
         },
         MuiInput: {
             underline: {
                 '&:before': {
                     borderBottom: '1px solid red',
                 },
                 '&:hover:not($disabled):before': {
                     borderBottom: '2px solid red',
                 },
                 '&:focused:after': {
                     borderBottom: '2px solid red',
                 },
                 '&:after': {
                     borderBottom: '2px solid red',
                 }

             },
         },*/
        MuiInputLabel: {
            root: {
                color: "#4d0000",
                "&$focused": {
                    color: "#4d1329",
                },
            },
        },

        MuiAutocomplete: {
            inputRoot: {
                flexWrap: 'wrap',
                paddingRight: 3,
                paddingLeft: 10,
                '&[class*="MuiOutlinedInput-root"]': {
                    padding: 0,
                    paddingRight: 3,
                    paddingLeft: 10,
                    '& $input': {
                        padding: '0px 0px',
                    },
                },
            }
        },
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottom: '1px solid black',
                },
                '&:hover:not($disabled):before': {
                    borderBottom: '2px solid black',
                },
                '&:focused:after': {
                    borderBottom: '2px solid black',
                },
                '&:after': {
                    borderBottom: '2px solid black',
                }

            },
            formControl: {
                margin: 0
            },
            input: {
                padding: 0
            }
        },
        MuiOutlinedInput: {
            input: {
                padding: 2
            },
            multiline: {
                padding: 2
            }
        },
        // MuiFormControl: {
        //     root: {
        //         width: 200
        //     }
        // },
        MuiGrid: {
            item: {
                padding: 1
            }
        }

    },

});

export default theme;