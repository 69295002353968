import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import theme from "../../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import GridItem from "../../../Grid/GridItem.jsx";
import GridContainer from "../../../Grid/GridContainer.jsx";
import {backEndServerURL} from "../../../../Common/Constant";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import DialogContent from "@material-ui/core/DialogContent";
import CBNotFound from "../../CASA/CBNotFound";
import {Dialog} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import SelectComponent from "../../../JsonForm/SelectComponent";
import MyValidation from "../../../JsonForm/MyValidation";

const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

let deleteField = {
    "varName": "deleteFiled",
    "type": "select",
    "required": true,
    "onKeyDown": true,
    "label": "Delete Flag",
    "enum": ["Y", "N"],

};

class SignatureCardDelete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            accountNumber: this.props.accountNumber !== undefined ? this.props.accountNumber : 0,
            multipleSignatureValue: [],
            showSignature: false,
            cbNotFound: false,
            title: "",
            err: false,
            errorArray: {},
            inputData: this.props.inputData,
            varValue: this.props.inputData,
            showValue: true,
            errorMessages: {},
            signatureDeleteFlag: this.props.inputData.signatureDeleteFlag || []
        }
    }


    componentDidMount() {
        console.log(this.props.inputData.signatureDeleteFlag);
        let start_url = backEndServerURL + "/intgr/signatureMultiple/Finacle/" + this.state.accountNumber + "/" + `${sessionStorage.getItem("accessToken")}`;
        axios
            .get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        multipleSignatureValue: response.data,
                        showSignature: true
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loader: false,
                    cbNotFound: true,
                    title: error.response.data.message
                })
            });
    }

    renderImage = () => {
        if (this.state.showSignature && this.state.multipleSignatureValue.length > 0) {
            return this.state.multipleSignatureValue.map((item) => {
                let url = backEndServerURL + '/intgr/signaturePart/Finacle/' + item.id + "/" + `${sessionStorage.getItem("accessToken")}`;
                return (
                    <div style={{width: "100%", textAlign: "center"}}>
                        <div>
                            <img onLoad={this.stopLoading} style={{
                                width: '80%',
                                height: '80%',
                                border: "2px solid #000000",
                                padding: "5px",
                                marginTop: "50px"
                            }} src={url}/>
                        </div>
                        <p
                            style={{
                                fontSize: "17px",
                                fontWeight: "bold",
                                marginTop: "25px",
                                marginBottom: "25px"
                            }}>Remarks
                            :- {item.remarks}</p>
                        <Grid item xs={4} style={{"margin": "0 auto"}}>
                            {this.dynamicDeleteFlag(item.signatureId)}
                        </Grid>
                    </div>
                )
            })
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    dynamicDeleteFlag = (name) => {
        let field = JSON.parse(JSON.stringify(deleteField));
        field.varName = name;
        field.readOnly = this.props.editMode === false;
        return SelectComponent.select(this.state, this.updateComponent, field, this.onKeyDownChange);
    };

    onKeyDownChange = (data) => {
        if (data !== undefined) {
            let tempArray = this.state.signatureDeleteFlag;
            if (this.state.inputData[data.varName] !== undefined && this.state.inputData[data.varName] === "Y") {
                tempArray.push(data.varName);
                this.setState({
                    signatureDeleteFlag: [...new Set(tempArray)]
                })
            } else if (this.state.inputData[data.varName] !== undefined && this.state.inputData[data.varName] === "N") {
                let currentIndex = tempArray.findIndex(item => item === data.varName);
                if (currentIndex > -1) {
                    tempArray.splice(currentIndex, 1);
                    this.setState({
                        signatureDeleteFlag: [...new Set(tempArray)]
                    })
                }
            }
        }
    };

    stopLoading = () => {
        this.setState({
            loader: false
        })
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        });
        this.props.handleDeleteSignature()
    };

    handleSubmit = () => {
        let dependencyList = [];
        if (this.state.multipleSignatureValue.length > 0) {
            this.state.multipleSignatureValue.map(item => dependencyList.push({
                varName: item.signatureId,
                required: true
            }))
        }
        let error = MyValidation.defaultValidation(dependencyList, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            let selectedItem = this.state.signatureDeleteFlag.filter(item => item !== "");
            console.log(selectedItem);
            this.props.handleSignatureDelete(this.state.inputData, selectedItem)
        }
    };

    renderButton = () => {
        if (this.state.showSignature && this.state.multipleSignatureValue.length > 0) {
            return (
                <div style={{margin: "10px auto"}}>
                    {(this.props.editMode && this.props.handleSignatureDelete !== undefined) && <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',

                            }}
                            disabled={this.state.disabled}
                            type='button' value='add more'
                            onClick={this.handleSubmit}
                        >Submit
                        </button>
                        &nbsp;&nbsp;&nbsp;</>}
                    {(this.props.showDeleteSignature && this.props.deleteCheckedSignature !== undefined) && <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',

                            }}
                            disabled={this.state.disabled}
                            type='button' value='add more'
                            onClick={this.props.deleteCheckedSignature}
                        >Delete Signature Card
                        </button>
                        &nbsp;&nbsp;&nbsp;</>}
                    {!this.props.showDeleteSignature && <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={this.props.handleDeleteSignature}
                    >Close
                    </button>}
                </div>
            )
        }
    };


    render() {
        const {classes} = this.props;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Preview Existing Signature<a><CloseIcon onClick={this.props.handleDeleteSignature}
                                                                        style={{
                                                                            position: 'absolute',
                                                                            right: 10,
                                                                            color: "#000000"
                                                                        }}/></a></h4>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.cbNotFound}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <CBNotFound
                                            closeModal={this.closeModalCBNotFound}
                                            title={this.state.title}
                                        />
                                    </DialogContent>
                                </Dialog>
                                <ThemeProvider theme={theme}>
                                    {this.state.loader && <CircularProgress style={{marginLeft: '50%'}}/>}
                                    <Grid container spacing={3}>
                                        {this.renderImage()}
                                        {this.renderButton()}
                                    </Grid>
                                </ThemeProvider>
                            </div>

                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );

    }


}

export default withStyles(styles)(SignatureCardDelete);