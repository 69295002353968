import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "./Grid/GridItem.jsx";
import GridContainer from "./Grid/GridContainer.jsx";
import Card from "./Card/Card.jsx";
import CardHeader from "./Card/CardHeader.jsx";
import CardBody from "./Card/CardBody.jsx";
import "./../Static/css/RelationShipView.css";
import './../Static/css/login.css';
import {backEndServerURL} from "./../Common/Constant";
import axios from "axios";
import 'antd/dist/antd.css';
import {Input} from 'antd';
import Functions from './../Common/Functions';

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        background: '#f6f1ff'
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 400,
    },
});


class CBGlobalSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cbnumber: '',
            errorMessage: " ",
            datas: [],
            redirectLogin: false,
        }
    }

    handleChange = (event) => {
        this.setState({
            [event.target.cbnumber]: event.target.value
        });
    };
    handleKeyDown = (event) => {

        if (event.key === 'Enter') {
            let cbNumber = event.target.value;
            axios.get(backEndServerURL + '/##/' + cbNumber, {
                headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
            })
                .then(response => {
                    console.log(response.data);
                    let responseData = [];
                    if (Array.isArray(response.data))
                        responseData = response.data;
                    this.setState({
                        datas: responseData
                    })
                })
                .catch(error => {

                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                    this.setState({errorMessage: "Not Found"});

                })

        }
    };
    /*  handlecbnumber = event => {
          this.setState({
              cbnumber: event.target.value
          })
      }*/

    handlesubmit = event => {

        event.preventDefault();

        axios.get(backEndServerURL + '/##/' + this.state.cbnumber, {
            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
        })
            .then(response => {
                let responseData = [];
                if (Array.isArray(response.data))
                    responseData = response.data;
                this.setState({
                    datas: responseData
                })
            })
            .catch(error => {

                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
                this.setState({errorMessage: "Not Found"});

            })
    };

    render() {

        const {classes} = this.props;
        const {Search} = Input;

        const {datas} = this.state;
        {

            Functions.redirectToLogin(this.state)

        }

        return (

            <GridContainer>

                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>Customer CB Search</h4>
                        </CardHeader>
                        <CardBody>

                            <div className={classes.root}>

                                <form onSubmit={this.handlesubmit}>
                                    <Search

                                        placeholder="input search text"

                                        style={{marginLeft: 750, width: 300}}
                                        onKeyDown={this.handleKeyDown}
                                        onChange={this.handleChange}
                                        onClick="submit"

                                    />

                                </form>
                                <br/><br/>

                                <center>
                                    <table className="table table-bordered table-definition mb-10">
                                        <thead style={{backgroundColor: "#FF5733", color: "#FFFFFF"}}>
                                        <tr>
                                            <th>Current User</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            datas.map((searchData) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            {searchData.acu}

                                                        </td>
                                                        <td>
                                                            {searchData.dit}

                                                        </td>
                                                        <td>
                                                            {searchData.dft}

                                                        </td>
                                                        <td>
                                                            {searchData.aps}

                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                        </tbody>
                                    </table>
                                </center>
                            </div>


                        </CardBody>
                    </Card>
                </GridItem>

            </GridContainer>

        );
    }
}

export default withStyles(styles)(CBGlobalSearch);
