import React, {Component} from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import FileMapping from "../../CommonComponent/FileMapping";
import FileMappingEdit from "../../CommonComponent/FileMappingEdit";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";

let arrayListNonIndividual = [
    {label: 'AM01'},
    {label: 'AM02'},
    {label: 'E-TIN'},
    {label: 'SIGNATURE CARD 1'},
    {label: 'SIGNATURE CARD 2'},
    {label: 'SIGNATURE CARD 3'},
    {label: 'SIGNATURE CARD 4'},
    {label: 'SIGNATURE CARD 5'},
    {label: 'CUSTOMER DECLARATION'},
    {label: 'OLD FDR RECEIPT'},
    {label: 'UNUSED CHEQUE BOOK'},
    {label: 'NID'},
    {label: 'PASSPORT'},
    {label: 'BIRTH CERTIFICATE'},
    {label: 'TRADE LICENSE'},
    {label: 'CERTIFICATE OF INCORPORATE'},
    {label: 'CERTIFICATE OF COMMENCEMEN'},
    {label: 'CONTACT POINT VERIFICATION'},
    {label: 'OFFICE ID'},
    {label: 'MANDATE PHOTO ID'},
    {label: 'MANDATE IIF'},
    {label: 'NOMINEE PHOTO ID'},
];

let arrayListSignatureCard = [
    {label: 'SIGNATURE CARD 1'},
    {label: 'SIGNATURE CARD 2'},
    {label: 'SIGNATURE CARD 3'},
    {label: 'SIGNATURE CARD 4'},
    {label: 'SIGNATURE CARD 5'},
    {label: 'SIGNATURE CARD 6'},
    {label: 'SIGNATURE CARD 7'},
    {label: 'SIGNATURE CARD 8'},
    {label: 'SIGNATURE CARD 9'},
    {label: 'SIGNATURE CARD 10'},
    {label: 'SIGNATURE CARD 11'},
    {label: 'SIGNATURE CARD 12'},
    {label: 'SIGNATURE CARD 13'},
    {label: 'SIGNATURE CARD 14'},
    {label: 'SIGNATURE CARD 15'},

];
let arrayListAllSignatureCard = [
    {label: 'SIGNATURE CARD 1'},
    {label: 'SIGNATURE CARD 2'},
    {label: 'SIGNATURE CARD 3'},
    {label: 'SIGNATURE CARD 4'},
    {label: 'SIGNATURE CARD 5'},
    {label: 'SIGNATURE CARD 6'},
    {label: 'SIGNATURE CARD 7'},
    {label: 'SIGNATURE CARD 8'},
    {label: 'SIGNATURE CARD 9'},
    {label: 'SIGNATURE CARD 10'},
    {label: 'SIGNATURE CARD 11'},
    {label: 'SIGNATURE CARD 12'},
    {label: 'SIGNATURE CARD 13'},
    {label: 'SIGNATURE CARD 14'},
    {label: 'SIGNATURE CARD 15'},
];

class UploadComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            showValue: true,
            uploadButtonClick: false,
            uploadModal: false,
            getMappingAllImage: false,
            fileUploadData: [],
            fileExist: false,
            PreviewFile: false,
            uploadSignatureModal: false,
        }
    }


    componentDidMount() {

        if(this.props.processName === "Bond"){
            arrayListNonIndividual.push(
                {label: 'Supporting Doc 1'},
                {label: 'Supporting Doc 2'},
                {label: 'Supporting Doc 3'},
                {label: 'Supporting Doc 4'},
                {label: 'Supporting Doc 5'},
                {label: 'Supporting Doc 6'},
                {label: 'Supporting Doc 7'},
                {label: 'Supporting Doc 8'},
                {label: 'Supporting Doc 9'},
                {label: 'Supporting Doc 10'},
                {label: 'Instruments'},
                {label: 'Tax Certificate'},
                {label: 'SP/Bonds Details 1'},
                {label: 'SP/Bonds Details 2'},
                {label: 'SP/Bonds Details 3'},
                {label: 'SP/Bonds Details 4'},
                {label: 'SP/Bonds Details 5'},
                {label: 'SP/Bonds Details 6'},
                {label: 'SP/Bonds Details 7'},
                {label: 'SP/Bonds Details 8'},
                {label: 'SP/Bonds Details 9'},
                {label: 'SP/Bonds Details 10'},
            )
        }

        let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.data.length > 0) {
                    this.setState({
                        fileUploadData: response.data,
                        uploadButtonClick: true,
                        fileExist: true
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }


    closeModal = () => {
        this.setState({
            getMappingAllImage: false,
        })
    };

    renderUploadButton = () => {
        if (!this.state.deferalNeeded) {
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        float: 'left',
                        verticalAlign: 'left',
                    }}
                    onClick={this.uploadModal}
                >
                    {this.props.name !== undefined ? this.props.name : "Upload File"}
                </button>
            )
        }
    };

    uploadSignatureModal = () => {
        this.setState({
            uploadSignatureModal: true
        })
    };

    renderSignatureUploadButton = () => {
        if (this.props.showSignature === true) {
            return (
                <>
                    &nbsp;&nbsp;
                    <button
                        className="btn btn-outline-info btn-sm"
                        onClick={this.uploadSignatureModal}
                    >
                        Signature Card Upload
                    </button>
                </>
            )
        }
    };

    renderAssignedImage = () => {
        if (this.state.PreviewFile || this.state.fileUploadData.length > 1 || this.state.fileExist) {
            return (
                <button
                    style={{
                        // width: 130,
                        marginLeft: '12px'
                    }}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.mappingAllImage}

                >
                    Preview Document.
                </button>
            )
        }
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        });
        this.closeModal();
    };

    submitModal = (data) => {
        if (data === "SUBMIT") {
            console.log(this.props.previewImage);
            console.log(data);
            if (this.props.previewImage !== undefined) {
                this.props.previewImage("submit")
            }
            this.setState({
                PreviewFile: true
            });
            this.forceUpdate()
        }
        this.setState({
            uploadModal: false,
            getMappingAllImage: false,
            uploadSignatureModal: false
        })

    };
    renderMappingImageModal = () => {

        return (<FileMapping
                dropDownOption={this.props.indexList ? this.props.indexList : arrayListNonIndividual}
                appId={this.props.appId}
                eadviceFlag={this.props.eadviceFlag}
                submitModal={this.submitModal}
                closeModal={this.closeUploadModal}/>
        )

    };
    renderMappingImageEditModal = () => {


        return (<FileMappingEdit dropDownOption={this.props.indexList ? this.props.indexList : arrayListNonIndividual}
                                 appId={this.props.appId}
                                 closeModal={this.closeUploadModal}/>
        )

    };

    renderMappingSignatureImageModal = () => {
        return (
            <FileMapping autoMapping={true} condition={true} dropDownConditionOption={arrayListAllSignatureCard}
                         dropDownOption={arrayListSignatureCard}
                         appId={this.props.appId}
                         submitModal={this.submitModal}
                         closeModal={this.closeUploadModal}/>
        )
    };

    closeUploadModal = (data) => {
        this.setState({
            uploadButtonClick: true,
            uploadModal: false,
            getMappingAllImage: false,
            uploadSignatureModal: false,
        })
    };

    render() {
        return (

            <React.Fragment>
                {
                    this.renderUploadButton()
                }
                {this.renderSignatureUploadButton()}
                {
                    this.renderAssignedImage()
                }
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    open={this.state.getMappingAllImage}>
                    <DialogContent>
                        {this.renderMappingImageEditModal()}

                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    open={this.state.uploadModal}>
                    <DialogContent>
                        {this.renderMappingImageModal()}

                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    open={this.state.uploadSignatureModal}>
                    <DialogContent>
                        {this.renderMappingSignatureImageModal()}

                    </DialogContent>
                </Dialog>
            </React.Fragment>

        );
    }


}

export default UploadComponent;