import React, {Component, createRef} from "react";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import "../../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Notification from "../../../NotificationMessage/Notification";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "../../../Table/Table";
import loader from "../../../../Static/loader.gif";
import MyValidation from "../../../JsonForm/MyValidation";
import FileTypeComponent from "../../../JsonForm/FileTypeComponent";
import CsExcelFile from "./CsExcelFile"
import ConfirmAlert from "../../CommonComponent/ConfirmAlert";
import {recoveryRequestJsonForm} from "../../WorkflowjsonFormForAssetOps";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";
import AssetCommonFunctions from "../AssetCommonFunctions";
import CBNotFound from "../../CASA/CBNotFound";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];

let csFile = {
    "varName": "csFile",
    "type": "file",
    "label": "Upload File",
    "accept": ".xlsx,.xls",
    "required": true
};

class CsRecoveryAmount extends Component {
    constructor() {
        super();
        this.state = {
            appId: 0,
            dormant: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            fileUploadData: {},
            inputData: {},
            showValue: false,
            varValue: {},
            getData: false,
            title: "",
            notificationMessage: "",
            alert: false,
            getRemarks: [],
            getImageLink: [],
            getImageBoolean: false,
            imageName: [],
            loading: true,
            dynamicFile: [],
            uploadStatus: "",
            excelFile: [],
            excelUpload: false,
            actionType: "",
            confirmAlert: false,
            renderExcel:false,
            getExcelArray: [],
            showFormData:false,
            showExcel:false
        };

        this.file = createRef();
        this.functionForCheck = this.functionForCheck.bind(this)
    }

    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };


    componentDidMount() {

        if (this.props.appId !== undefined) {
            let url = assetOpsBackEndServerURL + "/variables/" + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    let customAcctStatusUrl = assetOpsBackEndServerURL + "/GetCustomAcctStatus/" + response.data.accountNumber;
                    axios.get(customAcctStatusUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((customAcctStatusResponse) => {
                            let getCommentsUrl = assetOpsBackEndServerURL + "/appRemarkGet/" + this.props.appId;
                            let remarksArray=[];
                            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((remarksApiResponse) => {
                                    let inputData={};
                                    inputData.serviceType=response.data.serviceType;
                                    inputData.subCategory=response.data.subCategory;
                                    inputData.subServiceType=response.data.subServiceType;
                                    inputData.category=response.data.category;
                                    inputData.solId=response.data.solId;
                                    inputData.loanAccountNumber=response.data.loanAccountNumber;
                                    inputData.linkAccountNo=response.data.accountNumber;
                                    inputData.customerName=response.data.customerName;
                                    inputData.realization=response.data.realization;
                                    inputData.lienAmount=response.data.lienAmount;
                                    inputData.caseId=response.data.caseId;
                                    if(response.data.closing_maker_approved !==undefined){
                                        inputData.purpose=response.data.purpose
                                    }

                                    inputData.casaAccountStatus=customAcctStatusResponse.data.acctStatus;
                                    console.log(remarksApiResponse.data);
                                    remarksApiResponse.data.map((data) => {
                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                    });
                                    this.setState({
                                        varValue: this.copyJson(inputData),
                                        inputData: this.copyJson(inputData),
                                        showFormData:true,
                                        showExcel:false,
                                        appId: this.props.appId,
                                        getRemarks: remarksArray,
                                        getData: true,
                                        showValue: true,
                                        loading:false
                                    });

                                })
                                .catch((error) => {
                                    this.setState({
                                        getRemarks: remarksArray,
                                        getData: true,
                                        showValue: true,
                                        loading:false

                                    })
                                })

                        })
                        .catch((error) => {
                            console.log(error);
                        });

                })
                .catch(error => {
                    console.log(error);
                });
        }
        else {
            let start_url = assetOpsBackEndServerURL + "/startCase/cr_case_create_lien_marking";

            axios
                .get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {

                    this.setState({
                        showFormData: false,
                        showExcel:true,
                        getData: true,
                        showValue: true,
                        appId: response.data.id,
                        loading: false
                    });


                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };


    renderRemarksData = () => {

        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{width: "100%"}}>
                    <br/>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                    </div>

                </div>

            )
        }

    };

    renderRemarks = () => {
        if(this.state.showFormData){
            return CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
        }
    };

    functionForFileUpload = () => {

    };

    saveHandler = () => {
        if(this.props.appId!==undefined){
            let saveDataDeferralRemarksAndRoute = {};
            if (this.state.inputData.csRemarks !== undefined && this.state.inputData.csRemarks !== null) {
                saveDataDeferralRemarksAndRoute.remark = this.state.inputData.csRemarks;
            }
            let postData = this.state.inputData;
            postData.serviceType = "Recovery Amount";
            postData.category = "Recovery Amount";
            postData.subCategory = "Recovery Amount";
            postData.subServiceType = "Recovery Amount";
            postData.csRemarks = undefined;
            saveDataDeferralRemarksAndRoute.map = postData;

            AssetCommonFunctions.doRoute(saveDataDeferralRemarksAndRoute, this.state.appId).then((response)=>{
                this.setState({
                    title: "Successful!",
                    notificationMessage: response.data,
                    alert: true,
                    excelUpload: false,
                });
                if (this.props.appId === undefined) {
                    setTimeout(function () {
                        window.location.reload()
                    }, 100);
                } else {
                    this.props.closeModal();
                }
            })
            .catch((error)=>{
                console.log(error);
            })
        }
        else if (this.state.fileUploadData["csFile"] !== undefined && this.state.fileUploadData["csFile"] !== null) {
            this.setState({
                loading:true,
            })
            let uploadUrl = assetOpsBackEndServerURL + "/createRecoveryAmountCasesFromExcel";
            let formData = new FormData();
            formData.append("file", this.state.fileUploadData["csFile"]);
            formData.append("appId", this.state.appId);
            console.log(formData);
            axios({
                method: "post",
                url: uploadUrl,
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    "content-type": "multipart/form-data"
                }
            })
                .then(response => {
                    console.log(response.data)
                    if (response.data.length === 0) {
                        this.setState({
                            loading:false,
                            excelUpload: true,
                            excelFile: response.data,
                            title: "Not Standard Format"
                        })
                    } else {
                        this.setState({
                            loading:false,
                            excelUpload: true,
                            excelFile: response.data,
                        })
                    }
                })
                .catch(error => {
                });
            return 0;
        }
    };
    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b>Recovery Amount Realization/Manual Realization</b>
                    <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.linkAccountNo !== "__ERROR__" ? this.state.inputData.linkAccountNo : ""}
                        <b> SOLID:</b>{this.state.inputData.solId} <b> Case
                            ID:</b> {this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                            onClick={this.props.closeModal}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Recovery Amount Realization/Manual Realization{this.props.appId !== undefined ?
                    <a><CloseIcon onClick={this.props.closeModal} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a> : ""}</h4>
            )
        }
    };

    functionForCheck = (value) => {
        console.log(value)
    };

    closeModalExcelFile = (value) => {
        console.log("value set in - " , value);
        if (value === "OK" && this.state.excelFile.length > 0) {
            this.setState({
                title: "Successful!",
                notificationMessage: "Successfully Routed!",
                alert: true,
                excelUpload: false
            });

            if (this.props.appId === undefined) {
                setTimeout(function () {
                    window.location.reload()
                }, 1000);
            } else {
                this.props.closeModal();
            }
        } else {
            this.setState({
                excelUpload: false
            })
        }
    };
    closeConfirmAlert = (data, appId) => {
        this.setState({
            confirmAlert: false,
        });

        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "saveHandler") {
            console.log("value call")
           this.saveHandler();

        }
    };
    validationForHandleConfirm = (actionType) => {
        if(this.props.appId!==undefined){
            let errorRemarks = MyValidation.defaultValidation(csRemarks, this.state);
            this.forceUpdate();
            if (errorRemarks === true) {
                return 0;
            }
        }
        this.setState({
            appId: this.state.appId,
            confirmAlert: false,
        });

        if (actionType === "saveHandler") {
            let error = MyValidation.defaultValidation(recoveryRequestJsonForm, this.state);
            let error2 = MyValidation.fileValidation(csFile, this.state);
            this.forceUpdate();
            if(this.state.showFormData){
                if (error === true) {
                    console.log('Error Occurred');
                    return 0;

                }
            }
            else{
                if (error2 === true) {
                    console.log('Error Occurred');
                    return 0;
                }
            }
            console.log(this.state.inputData.casaAccountStatus)
            if(this.state.inputData.casaAccountStatus==="D"){
                this.setState({
                    dormant:true,
                    title: "Account is Dormant"
                })
            }
            else{
                this.setState({
                    actionType: actionType,
                    confirmAlert: true,
                })
            }
        }
    };

    handleCommon = (event, type) => {
        event.preventDefault();
        {this.validationForHandleConfirm(type)}
    };
    renderSubmitButton = () => {
        if (this.state.getData && this.state.loading === false) {
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginRight: "10px",
                    }}
                    // onClick={this.saveHandler}
                    onClick={(event) => this.handleCommon(event, "saveHandler")}

                >
                    Submit
                </button>
            )
        }
    }
    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = recoveryRequestJsonForm;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                recoveryRequestJsonForm[i].enum = getValue;
            }
        }


    };

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        return clone;
    };

    stopLoading = (value) => {
        if (value === "yes") {
            this.setState({
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    renderJsonForm = () => {
        if (this.state.getData && this.state.showFormData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, recoveryRequestJsonForm, this.updateComponent, undefined, undefined)
            )
        }
    }

    renderSampleFileButton = () => {
        if (this.state.showFormData === false) {
            return (
                <a href={process.env.PUBLIC_URL + '/sample upload files/Recovery Amount Excel Format.xlsx'} download="Sample File.xlsx">
                    <button
                        style={{
                            backgroundColor: "green",
                            color: "white",
                            fontSize: 13,
                            paddingLeft: 13,
                            paddingRight: 13,
                            paddingTop: 5,
                            paddingBottom: 5,
                            borderRadius: 5,
                            border: 1,
                            marginRight: 10,
                            display: "block",
                            float: "right"
                        }}
                    >
                       Download Sample File
                    </button></a>            )
        }
    }

    renderFlowSUmmeryButton = () => {

        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                   caseId={this.props.caseId}
                                   customerName={this.state.inputData.customerName}
                                   accountNumber={this.state.inputData.linkAccountNo}
                                   serviceType={this.state.inputData.serviceType}
                                   category={this.state.inputData.category}
                                   subCategory={this.state.inputData.subCategory}
                                   solId={this.props.solId}/>
            )
        }
    }

    closeModalCBNotFound = () => {
        this.setState({
            dormant: false
        })
    };

    cancelHandler = (event) => {
        event.preventDefault();
        let url = assetOpsBackEndServerURL + "/case/close/" + this.state.appId;
        axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Closed!",
                    alert: true,

                });
                this.props.closeModal();
            })
            .catch((error) => {
                console.log(error)
            });
    };

    renderCancel = () => {
        if (this.state.getData && this.state.loading === false && this.state.showFormData) {
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginRight: "10px",
                    }}
                    onClick={this.cancelHandler}

                >
                    Close
                </button>
            )
        }
    };

    render() {
        const {classes} = this.props;

        return (
            <React.Fragment>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {this.renderHeader()}
                    </CardHeader>
                    <CardBody>
                        <Grid item xs={12}>

                            {this.renderSampleFileButton()}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <ThemeProvider theme={theme} style={{
                                    margin: 0,
                                }}>
                                    {this.renderJsonForm()}
                                    <Grid item xs={12}>
                                     </Grid>
                                    {this.state.showExcel === true && FileTypeComponent.restrictedFile(this.state, this.updateComponent, csFile)}
                                    <br/><br/><br/>
                                    <Grid item xs={12}>
                                        {this.props.flowSummery === true && this.renderFlowSUmmeryButton()}
                                    </Grid>
                                </ThemeProvider>
                            </Grid>
                        </Grid>

                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.excelUpload}>
                            <DialogContent className={classes.dialogPaper}>
                                <CsExcelFile
                                    closeModalExcelFile={this.closeModalExcelFile}
                                    title={this.state.title}
                                    data={this.state.excelFile}
                                />
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>

                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.confirmAlert}>
                            <DialogContent className={classes.dialogPaper}>
                                <ConfirmAlert validationForHandleConfirm={this.validationForHandleConfirm}
                                              closeModal={this.closeConfirmAlert}

                                />

                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.dormant}>
                            <DialogContent className={classes.dialogPaper}>
                                <CBNotFound
                                    closeModal={this.closeModalCBNotFound}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <ThemeProvider theme={theme}>
                                    {this.renderRemarksData()}
                                    <Grid item xs={12}>

                                    </Grid>

                                    {this.renderRemarks()}
                                </ThemeProvider>
                            </Grid>
                        </Grid>

                        <Grid item xs="3" style={{marginTop: "10px"}}>
                            {this.renderSubmitButton()}
                            &nbsp;&nbsp;
                            {this.renderCancel()}
                        </Grid>
                        {
                            this.renderNotification()
                        }


                    </CardBody>
                </Card>
            </React.Fragment>
        );


    }
}

export default withStyles(styles)(CsRecoveryAmount);
