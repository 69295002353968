import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Functions from '../../../Common/Functions';
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Table from "../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import Notification from "../../NotificationMessage/Notification";
import loading from "../../../Static/loader.gif";
import MyValidation from "../../JsonForm/MyValidation";
import CBNotFound from "../CASA/CBNotFound";
import FunctionForGetAcStatus from "../CommonComponent/FunctionForGetAcStatus";
import ConfirmationModal from "../../DeliverableManagement/DebitCard/ConfirmationModal";
import SignatureButton from "../Maintenance/SignatureButton";

const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

const fileUpload = [
    {
        varName: "fileUpload",
        type: "file",
        label: "Upload",
        accept: ".pdf",
        grid: 2,
    }
];

let searchInput = [

    {
        "varName": "sourceType",
        "type": "select",
        "enum":[
            "Finacle",
            "Ababil",
        ],
        "required": true,
        // "multiline": true,
        "label": "Source",
        "grid": 2,

    },
    {
        "varName": "accountNumber",
        "type": "text",
        "required": true,
        // "multiline": true,
        "label": "Account No",
        "grid": 2,
        "conditionalOrValue": true,
        "conditionalVarName": "sourceType",
        "conditionalOrList": ["Finacle", "Ababil"]

    },

    {
        "varName": "clientId",
        "type": "text",
        "required": true,
        // "multiline": true,
        "label": "Client ID",
        "grid": 2,
        "conditionalOrValue": true,
        "conditionalVarName": "sourceType",
        "conditionalOrList": ["Finacle", "Ababil"]

    }
];
const jsonForm = [
    {
        "varName": "customerName",
        "type": "text",
        "readOnly": true,
        "multiline": true,
        "label": "Customer Name",
        "grid": 2,
    },
    {
        "varName": "nameOnCard",
        "type": "text",
        "multiline": true,
        "label": "Name On Card",
        "grid": 2,
    },
    {
        "varName": "clientId",
        "type": "text",
        "multiline": true,
        "readOnly": true,
        "label": "Client ID",
        "grid": 2,

    },
    {
        "varName": "cardNumber",
        "type": "text",
        "multiline": true,
        "readOnly": true,
        "label": "Card Number",
        "grid": 2,

    },
    {
        "varName": "accountStatus",
        "type": "text",
        "readOnly": true,
        "label": "A/C Status",
        "grid": 2,

    },
    {
        varName: "rmCode",
        type: "textApiCall",
        label: "RM Code",
        grid: 2,
        required: true
    },
    {
        varName: "rmName",
        type: "text",
        label: "RM Name",
        multiline: true,
        conditional: true,
        conditionalVarName: "showRmName",
        conditionalVarValue: true,
        readOnly: true,
        grid: 2,
    },
    {
        "varName": "email",
        "type": "text",
        "multiline": true,
        "readOnly": true,
        "label": "Email",
        "grid": 2,

    },
    {
        "varName": "mobileNumber",
        "type": "text",
        "readOnly": true,
        "required": true,
        "label": "Mobile Number",
        "grid": 2,

    },
    {
        varName: "cardType",
        type: "text",
        label: "Card Type",
        readOnly: true,
        grid: 2,
    },
    {
        varName: "productType",
        type: "select",
        label: "Product Type",
        required: true,
        grid: 3,
        enum: []
    },
    {
        varName: "ccep",
        type: "text",
        label: "CCEP",
        readOnly: true,
        grid: 2,
        "conditional": true,
        "conditionalVarName": "sourceType",
        "conditionalVarValue": "Finacle",

    },
    {
        varName: "passportNumber",
        type: "text",
        label: "Passport Number",
        readOnly: true,
        grid: 2,
    },
    {
        varName: "passportExpiryDate",
        type: "date",
        label: "Passport Exp Date",
        readOnly: true,
        grid: 2,
        "conditional": true,
        "conditionalVarName": "sourceType",
        "conditionalVarValue": "Finacle",
    },
    {
        varName: "passportIssueDate",
        type: "date",
        label: "Passport Issue Date",
        readOnly: true,
        grid: 2,
    },
    {
        varName: "passportIssuePlace",
        type: "text",
        label: "Passport Issue Place",
        readOnly: true,
        multiline: true,
        grid: 2,
    },
];
let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];

class InstaDebitCardRequest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            appId: '',
            loading: false,
            cbNotFound: false,
            getData: false,
            varValue: {},
            title: "",
            fileUploadData: {},
            notificationMessage: "",
            alert: false,
            type: [],
            selectedDate: {},
            showValue: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            instaDebitCardProductType: [],
            invalidRm: false,
            confirmation: false,
            getImageLink: [],
            getImageBoolean: false,
            searchDataBoolean: false,
        }
    }


    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    findByVarNameApiData = (searchVarname, getValue) => {
        if (searchVarname === "instaCardNumber" || searchVarname === "instaDebitCardProductType") {

            let jsonArray = this.props.serviceListLast;
            for (let i = 0; i < jsonArray.length; i++) {
                let jsonObject = jsonArray[i];
                if (jsonObject.varName === searchVarname) {
                    console.log(getValue.key);
                    let data = [];
                    this.props.serviceListLast[i].enum = getValue;


                }
            }
        }
    };

    componentDidMount() {
        let instaDebitCardUrl = backEndServerURL + "/instantDebitCard/productTypes";
        axios.get(instaDebitCardUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    for (let i = 0; i < jsonForm.length; i++) {
                        if (jsonForm[i].varName === "productType") {
                            jsonForm[i].enum = response.data;
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            });
        let remarksArray = [];
        if (this.props.appId !== undefined) {
            this.setState({
                loading: true
            });
            let url = backEndServerURL + '/variables/' + this.props.appId;

            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                    axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            this.setState({
                                getImageLink: response.data,
                                getImageBoolean: true
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            response.data.map((data) => {
                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                            });
                            this.setState({
                                getRemarks: remarksArray
                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        });
                    let inputData = {...response.data};
                    inputData.cardType = response.data.cardType.toString();
                    this.setState({
                        inputData: this.emptyValueRemove(inputData),
                        varValue: this.emptyValueRemove(inputData),
                        getData: true,
                        showValue: true,
                        appId: this.props.appId,
                        loading: false
                    });

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
        } else {
            this.setState({
                getData: true
            })
        }
    }

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    handleSubmit = (event) => {
        event.preventDefault();

        let error = MyValidation.defaultValidation(jsonForm, this.state);
        let fileError = MyValidation.fileValidation(fileUpload[0], this.state);
        this.forceUpdate();

        if (error === true || fileError === true) {
            return 0;
        } else if (this.state.invalidRm) {
            this.setState({
                cbNotFound: true,
                title: 'Invalid RM Code!'
            });
            return 0;
        } else if (this.state.inputData.nameOnCard.length > 19) {
            this.setState({
                cbNotFound: true,
                title: 'Name on Card Not More Than 19 Character'
            });
            return 0;
        } else {

            if (this.state.fileUploadData["fileUpload"] !== undefined) {
                let uploadUrl = backEndServerURL + "/case/upload";
                let formData = new FormData();
                formData.append("appId", this.state.appId);
                formData.append("file", this.state.fileUploadData["fileUpload"]);
                formData.append("type", "file");

                axios({
                    method: "post",
                    url: uploadUrl,
                    data: formData,
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                        "content-type": "multipart/form-data"
                    }

                })
                    .then(response => {
                        this.setState({
                            loading:false,
                        })
                    })
                    .catch(error => {
                        this.setState({
                            title: 'warning',
                            notificationMessage: "Failed to Upload File!",
                            alert: true
                        });
                    });
            }
            if (this.state.inputData.constitutionType === "JNT") {
                this.setState({
                    confirmation: true
                })
            } else {
                this.functionForCaseRoute();
            }
        }
    };

    functionForCaseRoute = () => {
        this.setState({
            loading:true,
        })
        let commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.csRemarks + "/" + this.state.appId;
        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error);
                Functions.loginRedirection(error);
            });
        let variableSetUrl = backEndServerURL + "/variables/" + this.state.appId;


        let data = this.state.inputData;
        data.cs_deferal = "NO";
        data.csRemarks = undefined;
        data.serviceType = "Debit Card";
        data.subServiceType = "Chequebook";
        data.category = "Debit Card Issuance";
        data.subCategory = "Opening";
        axios.post(variableSetUrl, this.emptyValueRemove(data), {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                var url = backEndServerURL + "/case/route/" + this.state.appId;
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        if (response.status === 200) {
                            this.setState({
                                title: "Successful!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                getData: false,
                                loading:false
                            });
                            if (this.props.appId !== undefined) {
                                this.props.closeModal()
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading:false
                        })
                    });

            })
            .catch((error) => {
                Functions.loginRedirection(error);
                console.log(error)
                this.setState({
                    loading:false
                })
            });
    };
    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };
    renderStartCase = () => {
        let error = MyValidation.defaultValidation(this.emptyValueRemove(searchInput), this.state);
        this.forceUpdate();
        if (error) {
            return 0;
        }
        this.setState({
            loading: true,
            getData: false
        });
        if(this.state.inputData.sourceType==="Ababil"){
            let accountInquiryUrl = backEndServerURL + "/accountInquiryAbabil/" + this.state.inputData.accountNumber;
            axios.get(accountInquiryUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let inputData = this.state.inputData;
                    if (response.data.acctStatus !== "A") {
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                           loading: false,
                            cbNotFound: true,
                            searchData: false,
                            title: 'Account is not Active!'
                        })
                    }
                    else {
                        inputData.sourceType=this.state.inputData.sourceType
                        inputData.accountStatus = response.data.acctStatus;
                        inputData.accountNumber = response.data.acctNumber;
                        inputData.customerName = response.data.acctName;
                        inputData.nameOnCard = response.data.acctName;
                        inputData.currency=response.data.crncyCode;
                        inputData.schemeCode = response.data.productCode;
                        //inputData.ccep = response.data.ccepcode;
                        inputData.cbNumber = response.data.cifId;
                        inputData.casaSolId = response.data.solId;
                        inputData.solId = response.data.solId;

                        /*  inputData.homeBranchSolId = response.data.solId;
                          inputData.accountBalance = response.data.acctEftvBalance;
                          inputData.showAccountBalance = true;
                           inputData.accountType = response.data.accountType;
                          inputData.acctCurrency = response.data.crncyCode;

                          inputData.deliveryType = "Branch";
                          inputData.productCode = response.data.productCode;
                          inputData.schemeCode = response.data.productCode;*/
                        inputData.occupationCode = "R";
                        inputData.customerType = response.data.customerType;
                        //  inputData.schemeCode = response.data.schmCode;
                        let getDataByCB = backEndServerURL + "/getCustomerInfoAbabil/" + response.data.customerId;
                        axios.get(getDataByCB, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                inputData.mobileNumber = response.data.mobileNumber
                                if (response.data.owners !== undefined) {
                                    inputData.passportIssuePlace = response.data.owners.passportIssuePlace;
                                    inputData.passportIssueDate = this.convertDate(response.data.owners.passportIssueDate);
                                    inputData.passportNumber = response.data.owners.passportNo;
                                }
                                inputData.email = response.data.email;
                                let getCustomerType = backEndServerURL + "/getCustomerType/" + inputData.schemeCode + "/" + inputData.occupationCode;
                                axios.get(getCustomerType, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        // inputData.customerType = response.data.customerType;
                                        if (response.data.instantDebitCardAllowed === "NO") {
                                            this.state.varValue["accountNumber"] = this.state.inputData.accountNumber;
                                            this.state.varValue["clientId"] = this.state.inputData.clientId;
                                            this.setState({
                                                showValue: true,
                                                loading: false,
                                                cbNotFound: true,
                                                title: `Insta Debit Card Not Allowed for this Account(Scheme Code: ${inputData.schemeCode})!`
                                            });
                                        } else {
                                            let clientId = backEndServerURL + "/intgr/instantDebitcard/" + this.state.inputData.clientId;
                                            axios.get(clientId, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                .then((response) => {
                                                    inputData.cardNumber = response.data.cardNo;
                                                    inputData.cardType = response.data.cardType;
                                                    let url = backEndServerURL + "/startCase/cheque_book_request_start";

                                                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                        .then((response) => {
                                                            this.setState({
                                                                    appId: response.data.id,
                                                                    inputData: inputData,
                                                                    varValue: this.copyJson(inputData),
                                                                    getData: true,
                                                                    loading: false,
                                                                    showValue: true,
                                                                    searchData: true,
                                                                searchDataBoolean: true
                                                                })

                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                            this.functionForError(error)
                                                        })

                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    this.functionForError(error);
                                                });

                                        }

                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        this.setState({
                                            inputData: inputData,
                                            varValue: this.copyJson(inputData),
                                            showValue: true,
                                            loading: false,
                                            cbNotFound: true,
                                            title: error.response.data.message
                                        });
                                    })

                            })
                            .catch((error) => {
                                this.setState({
                                    inputData: inputData,
                                    varValue: this.copyJson(inputData),
                                    showValue: true,
                                    loading: false,
                                    cbNotFound: true,
                                    title: error.response.data.message
                                });
                            });
                    }
                })
                .catch((error) => {
                    this.setState({
                        showValue: true,
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    })
                });

        }
        else{
            let getAccountUrl = backEndServerURL + "/getAccountInfo/" + this.state.inputData.accountNumber;

        axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                if (response.status === 200) {
                    if (response.data.message === "No Account Found!") {
                        this.setState({
                            loading: false,
                            cbNotFound: true,
                            title: 'No Account Found!'
                        });
                    } else {
                        let inputData = this.state.inputData;
                        FunctionForGetAcStatus.getAccountStatus(response.data.accountNumber)
                            .then((acStatus) => {
                            if (acStatus.status === "found" && acStatus.value === "A") {
                                inputData.accountStatus = acStatus.value;
                                inputData.accountNumber = response.data.accountNumber;
                                inputData.customerName = response.data.customerName;
                                inputData.nameOnCard = response.data.customerName;
                                inputData.currency = response.data.acctCurrency;
                                inputData.schemeCode = response.data.schemeCode;
                                inputData.ccep = response.data.ccepcode;
                                inputData.cbNumber = response.data.cbNumber;
                                inputData.casaSolId = response.data.solid;
                                inputData.solId = response.data.solid;
                                // inputData.mobileNumber = response.data.contactNumber ? response.data.contactNumber.substr(response.data.contactNumber.length - 11) : "";
                                // inputData.email = response.data.emailAddr;

                                let occupation = response.data.occupationcode ? response.data.occupationcode : "NA";
                                let getCustomerType = backEndServerURL + "/getCustomerType/" + response.data.schemeCode + "/" + occupation;
                                axios.get(getCustomerType, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        if (response.status === 200) {
                                            if (response.data.instantDebitCardAllowed === "NO") {
                                                this.state.varValue["accountNumber"] = this.state.inputData.accountNumber;
                                                this.state.varValue["clientId"] = this.state.inputData.clientId;
                                                this.setState({
                                                    showValue: true,
                                                    loading: false,
                                                    cbNotFound: true,
                                                    title: `Insta Debit Card Not Allowed for this Account(Scheme Code: ${inputData.schemeCode})!`
                                                });
                                            } else {
                                                let clientId = backEndServerURL + "/intgr/instantDebitcard/" + this.state.inputData.clientId;
                                                axios.get(clientId, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                    .then((response) => {
                                                        if (response.status === 200) {
                                                            inputData.cardNumber = response.data.cardNo;
                                                            inputData.cardType = response.data.cardType;
                                                            let getDataByCB = backEndServerURL + "/cbsCustomerGet/" + inputData.cbNumber;
                                                            axios.get(getDataByCB, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                                .then((response) => {
                                                                    if (response.status === 200) {
                                                                        if (response.data.COMMPH1BLOCK !== undefined) {
                                                                            inputData.mobileNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode
                                                                        }

                                                                        if (response.data.COMMEMLBLOCK !== undefined) {
                                                                            inputData.email = response.data.COMMEMLBLOCK.emailInfo;
                                                                        }

                                                                        if (response.data.PASSPORTBLOCK !== undefined) {
                                                                            inputData.passportNumber = response.data.PASSPORTBLOCK.referenceNum;
                                                                            inputData.passportIssuePlace = response.data.PASSPORTBLOCK.placeOfIssue;
                                                                            inputData.passportIssueDate = this.convertDate(response.data.PASSPORTBLOCK.issueDt);
                                                                            inputData.passportExpiryDate = this.convertDate(response.data.passportExpDt);
                                                                        }

                                                                        // inputData.passportNumber = response.data.passportNum;
                                                                        // inputData.passportExpiryDate = this.convertDate(response.data.passportExpDt);
                                                                        // inputData.passportIssueDate = this.convertDate(response.data.passportIssueDt);
                                                                        // inputData.passportIssuePlace = response.data.passportDtls;
                                                                        inputData.constitutionType = response.data.constitutionCode;
                                                                        let url = backEndServerURL + "/startCase/cheque_book_request_start";

                                                                        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                                            .then((response) => {
                                                                                this.setState({
                                                                                    appId: response.data.id,
                                                                                    inputData: inputData,
                                                                                    varValue: inputData,
                                                                                    showValue: true,
                                                                                    getData: true,
                                                                                    searchData: true,
                                                                                    loading: false,
                                                                                    searchDataBoolean: true

                                                                                });

                                                                            })
                                                                            .catch((error) => {
                                                                                console.log(error);
                                                                                this.functionForError(error)
                                                                            })
                                                                    }
                                                                })
                                                                .catch((error) => {
                                                                    this.functionForError(error)
                                                                });
                                                        }
                                                    })
                                                    .catch((error) => {
                                                        console.log(error);
                                                        this.functionForError(error);
                                                    });
                                            }
                                        }
                                    })

                                    .catch((error) => {
                                        this.functionForError(error)
                                    });
                            } else if (acStatus.status === "Not Found!" || (acStatus.status === "found" && acStatus.value !== "A")) {
                                this.state.varValue["accountNumber"] = this.state.inputData.accountNumber;
                                this.state.varValue["clientId"] = this.state.inputData.clientId;
                                this.setState({
                                    getData: false,
                                    searchData: false,
                                    showValue: true,
                                    loading: false,
                                    cbNotFound: true,
                                    title: 'Account is not Active!'
                                })
                            } else {
                                this.functionForError(acStatus.value)
                            }
                        })
                    }
                }

            })
            .catch((error) => {
                console.log(error);
                this.functionForError(error)
            });
        }
    };

    functionForError = (error) => {
        this.state.varValue["accountNumber"] = this.state.inputData.accountNumber;
        this.state.varValue["clientId"] = this.state.inputData.clientId;
        this.setState({
            getData: false,
            searchData: false,
            showValue: true,
            loading: false,
            cbNotFound: true,
            title: error.response.data.message,
        })
    };

    convertDate = (value) => {
        if (value && value !== -1) {
            let date = value.split('T')[0].split("-");
            return `${date[1]}/${date[2]}/${date[0]}`
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    renderRemarks = () => {
        if (this.state.getData && (this.state.searchData || this.props.appId)) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
            )
        }

    };

    handleClose = (event) => {
        event.preventDefault();
        let variableSetUrl = backEndServerURL + "/case/close/" + this.props.appId;
        axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Close!",
                        alert: true
                    });
                    this.props.closeModal();
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };
    makeReadOnlyObjec = (object) => {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            returnObjectVariables[i]["readOnly"] = true;
        }
        return returnObject;
    };
    resetHandler = () => {
        window.location.reload();
    };
    renderSearchForm = (root) => {
        if (this.props.appId === undefined) {
            return (
                <Grid item xs={12}>
                    <Grid container>
                        <ThemeProvider theme={theme}>
                            {
                               this.state.searchDataBoolean===true? CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObjec(searchInput), this.updateComponent):CommonJsonFormComponent.renderJsonForm(this.state, searchInput, this.updateComponent)
                            }
                            <Grid item xs={6}>

                                { this.state.inputData.sourceType!==undefined && <button
                                    style={{
                                        marginTop: "18px"
                                    }}
                                    onClick={this.renderStartCase}
                                    className="btn btn-danger btn-sm">
                                    Search
                                </button>}
                                &nbsp;
                                &nbsp;
                                <button
                                    style={{
                                        marginTop: "18px"
                                    }}
                                    className="btn btn-danger btn-sm"
                                    onClick={this.resetHandler}
                                >
                                    Reset
                                </button>
                            </Grid>
                        </ThemeProvider>
                    </Grid>
                </Grid>
            )
        }
    };


    renderSearchData = () => {

        if (this.state.getData && (this.state.searchData || this.props.appId)) {
            return (
                <>
                    {CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent, this.functionForGettingRmName)}
                    <Grid item xs={12}>
                    </Grid>
                    {this.signature()}
                    <Grid item xs={12}>
                    </Grid>
                    {CommonJsonFormComponent.renderJsonForm(this.state, fileUpload, this.updateComponent)}
                    <Grid item xs={12}>
                    </Grid>
                    {this.mappingPhoto()}
                </>
            )
        }
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    functionForGettingRmName = (event, data) => {
        if (data === "rmCode") {
            this.setState({
                loading: true
            });
            this.state.inputData.showRmName = false;
            this.forceUpdate();
            let getRmName = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData.rmCode;
            axios.get(getRmName, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.rmName = response.data;
                    this.state.varValue.rmName = response.data;
                    this.state.varValue.rmCode = this.state.inputData.rmCode;
                    this.state.inputData.showRmName = true;
                    this.forceUpdate();
                    this.setState({
                        invalidRm: false,
                        loading: false
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.rmCode = "";
                    this.state.varValue.rmCode = "";
                    this.setState({
                        invalidRm: true,
                        cbNotFound: true,
                        title: "Invalid RM Code!",
                        loading: false
                    });
                })
        }
    };

    handleSubmitButton = () => {
        if (this.state.getData && (this.state.searchData || this.props.appId)) {
            return (
                <ThemeProvider theme={theme}>
                    <Grid>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{}}
                            onClick={this.handleSubmit}
                        >
                            Submit
                        </button>
                        {this.props.appId !== undefined && <>&nbsp;
                            <button
                                className="btn btn-outline-primary btn-sm"
                                onClick={this.handleClose}
                            >
                                Close
                            </button>
                        </>}
                    </Grid>
                </ThemeProvider>
            )
        }
    };

    close = () => {
        this.props.closeModal();
    };

    renderCloseIcon = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} >Insta Debit Card Issuance (<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                    <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                    <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}
                    <b> SOLID:</b>{this.props.solId !== undefined ? this.props.solId : ""})<a><CloseIcon
                        onClick={this.close} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >Insta Debit Card Issuance </h4>
            )
        }
    };
    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };


    confirmationModal = (value) => {
        if (value === "ok") {
            this.functionForCaseRoute();
            this.setState({
                confirmation: false
            })
        } else {
            this.setState({
                confirmation: false
            })
        }
    };

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                return (
                    <React.Fragment>
                        <a
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: "middle",
                                display: "block",
                                marginTop: "5px",
                            }}
                            target="_blank"
                            href={backEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                        >
                            Document {index + 1}
                        </a>
                        &nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };

    signature = () => {
        if (this.state.getData) {
            return (
                <>
                    <br/>
                    <SignatureButton signatureSource={this.state.inputData.sourceType} accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
                                     classes={this.props}/>
                    <br/>
                </>
            )
        }
    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loading} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {this.renderCloseIcon()}
                    </CardHeader>
                    <CardBody>
                        <ThemeProvider theme={theme}>
                            <Grid container>
                                {this.renderSearchForm(classes.root)}
                                <br/>
                                {this.renderNotification()}

                                {this.renderSearchData()}

                                <Grid item xs='12'>
                                </Grid>
                                <Grid item xs={12}>
                                    {this.renderRemarksData()}
                                </Grid>
                                <Grid item xs={12}>
                                    {this.renderRemarks()}
                                </Grid>
                                <Grid item xs={12}>
                                </Grid>
                                {this.handleSubmitButton()}
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.cbNotFound}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <CBNotFound
                                            closeModal={this.closeModalCBNotFound}
                                            title={this.state.title}
                                        />
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="md"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.confirmation}>
                                    <DialogContent className={classes.dialogPaper}>

                                        <ConfirmationModal
                                            closeModal={this.confirmationModal}
                                            title="Is the Joint account's mode of operation Anyone/ Either or Survivor?"
                                        />
                                    </DialogContent>
                                </Dialog>
                            </Grid>
                        </ThemeProvider>
                    </CardBody>
                </Card>

            );

        }

    }

}

export default withStyles(styles)(InstaDebitCardRequest);
