import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import "../../Static/css/CommonJsonForm.css";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import LocalstorageEncrypt from "./LocalstorageEncrypt";


class AutoCompleteComponentValueReturn {
    static onKeyDownChange(event, field, onKeyDownChange) {
        onKeyDownChange(event);
    }

    static handleChange(event, onKeyDownChange, option, state, update, field) {
        event.preventDefault();

        console.log(option);
        if (option !== null) {
            state.inputData[field.varName] = option;
            console.log(option);
            update();
            let currentDate = new Date();

            if (currentDate.getMinutes()-LocalstorageEncrypt.encryptStorageFunction.getItem("previousSessionTime") >= 10) {
                 let updateUrl = backEndServerURL + "/updateSessionTimeout";
                axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        LocalstorageEncrypt.encryptStorageFunction.setItem("previousSessionTime", currentDate.getMinutes());
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }

        } else if (option === null) {
            state.inputData[field.varName] = option;
            console.log(option);
            update();
        }

    }

    static asteriksFunction = (field) => {
        if (field.required === true && field.asteriks === undefined) {
            return (
                <Grid item>
                    <label className="input-label-common">{field.label} *</label>
                </Grid>

            )
        } else {
            return (
                <Grid item>
                    <label className="input-label-common">{field.label}</label>
                </Grid>
            )
        }
    };

    static returnDefaultValue(field, state) {
        if (state.showValue) {
            if (state.varValue === undefined || state.varValue == null)
                return "";

            else{
                if(state.varValue[field.varName] === undefined || state.varValue[field.varName] == null || state.varValue[field.varName] === "undefined" || state.varValue[field.varName] == "null"){
                    return "";
                }
                else{
                    return state.varValue[field.varName];
                }

            }


        }
    }


    static select(state, update, field, onKeyDownChange) {
        let defaultProps = {
            options: field.enum,
        };
        let styles = { paddingRight: 20};
        if (field.readOnly === true && field.highlight === true) {
            const secondStyle = {
                color: '#ffffff',
                background: 'red'
            };
            styles = Object.assign(styles, secondStyle)
        } else if (field.readOnly === true) {
            const firstStyle = {
                color: '#212F3D',
                background: '#f8f9fa'
            };
            styles = Object.assign(styles, firstStyle)
        } else if (field.highlight === true) {
            const secondStyle = {
                color: '#ffffff',
                background: 'red'
            };
            styles = Object.assign(styles, secondStyle)
        }

        return (
            <React.Fragment>
                {this.asteriksFunction(field)}
                <Grid item>
                    <Autocomplete
                        multiple={field.multiple!==undefined?true:false}
                        onChange={(event, option) => this.handleChange(event, onKeyDownChange, option, state, update, field)}
                        options={field.enum} getOptionLabel={option => option.value}
                        {...defaultProps} disabled={field.readOnly}
                        defaultValue={this.returnDefaultValue(field, state)}
                        onBlur={(event, option) => {
                            if (option !== null) {
                                if (field.onKeyDown === true) {
                                    let data = {};
                                    data.varName = field.varName;
                                    if (state.inputData[field.varName] !== undefined && state.inputData[field.varName] !== null) {
                                        if (state.inputData[field.varName].key !== undefined && state.inputData[field.varName].key !== null) {
                                            onKeyDownChange(data);
                                        }
                                    }
                                }
                            }
                        }}
                        style={styles}
                        className={field.highlight === true ? "highLight-color" : ""}
                        renderInput={(params) =>
                            <TextField {...params}
                                       error={state.errorArray !== undefined && state.errorArray[field.varName]}
                                       helperText={state.errorArray[field.varName] === true ? state.errorMessages[field.varName] : ""}
                                       variant="outlined"
                                       style={field.readOnly === true ? {color: "#ffffff"} : {color: "#000000"}}
                                       fullWidth/>}
                    />
                </Grid>
            </React.Fragment>
        )
    }
}

export default AutoCompleteComponentValueReturn;