function makeReadOnlyObject(object) {
    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject;

    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["readOnly"] = true;
    }
    return returnObject;
}

let InterestTransferCsoForm = [
    {
        varName: "customerCB",
        type: "textApiCall",
        label: "Customer CB",
        required: true,
        grid: 2,
    },
    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
        grid: 2,
    },

    {
        varName: "loanAccounts",
        type: "select",
        label: "Loan Accounts",
        grid: 3,
        required: true,
        "enum": [],
        "onKeyDown": true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        varName: "loanAccountStatus",
        type: "text",
        label: "Loan Account Status",
        grid: 3,
        required: true,
        readOnly: true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "loanOutstanding",
        "type": "textApiCall",
        "required": true,
        "label": "Loan Outstanding",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Request Type",
        "type": "select",
        "varName": "interestRequestType",
        "enum": [
            "Interest transfer request",
            "Security replacement",
            "Partial Security release",
            "Interest rate Changes",
            "Link account Changes",
            "EFTN Account Change",
            "Duplicate NOC",
            "Duplicate Sanctioned Letter"
        ],
        "grid": 4,
        "required": true
    },
    {
        type: "empty",
        grid: 12
    },

];

let InterestTransferReadOnlyForm = {};
InterestTransferReadOnlyForm = makeReadOnlyObject(JSON.parse(JSON.stringify(InterestTransferCsoForm)));

module.exports = {
    InterestTransferCsoForm,
    InterestTransferReadOnlyForm
};