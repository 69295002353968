import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../../Common/Functions';
import Notification from "../../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../../JsonForm/CustomeTheme";
import Table from "../../../Table/Table";
import FileTypeComponent from "../../../JsonForm/FileTypeComponent";
import DialogContent from "@material-ui/core/DialogContent/index";
import {Dialog} from "@material-ui/core/index";
import loader from "../../../../Static/loader.gif";
import Label from "./CasaCloserLabel";
import SignatureButton from "../SignatureButton";
import Card from "../../../Card/Card";
import CardBody from "../../../Card/CardBody";
import {
    casaSiDeleteMaker,
    dormantActivationMaker,
    manualFundTransferMaker,
    signatureAndPhotographMaker,
    unfreezeMarkingMaker
} from "../../WorkflowJsonFormRashed"
import PreviewMappingImage from "./PreviewMappingImage";
import GridList from "@material-ui/core/GridList";
import MyValidation from "../../../JsonForm/MyValidation";
import CBNotFound from "../../CASA/CBNotFound";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";

let checkerRemarks = [
    {
        "varName": "makerRemarks",
        "type": "textArea",
        "label": "Remarks",
        "required": true,
        "grid": 12
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};
var fileUpload = {
    "varName": "scanningFile",
    "type": "file",
    "label": "Upload File",
    "grid": 12
};
let jsonForm = [
    {
        "varName": "eqmNumber",
        "type": "text",
        "readOnly": true,
        "label": "EQM Number",
        "grid": 6,
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "required": true,
        "readOnly": true,
        "label": "Account Number",
        "grid": 6,
    },
];
let dataArray = {};

class CloserRequestForMaker extends React.Component {
    state = {
        message: "",
        appData: {},
        getData: false,
        varValue: [],
        redirectLogin: false,
        title: "",
        notificationMessage: "",
        alert: false,
        inputData: {},
        selectedDate: {},
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        values: [],
        showValue: false,
        customerName: [],
        deferalType: [],
        expireDate: [],
        other: [],
        appId: 0,
        getCheckerList: [],
        getDeferalList: [],
        fileUploadData: {},
        loaderNeeded: null,
        objectForJoinAccount: [],
        getgenerateForm: false,
        getTagList: [],
        tagCbDetailsModal: false,
        customerNumber: "",
        loading: true,
        getRemarks: [],
        err: false,
        errorArray: {},
        errorMessages: {},
        getCropedImage: false,
        previewDocument: false,
        cbNotFound: false,
        lienTableArray: [],
        showExistingLien: true,
        confirmationAlert: false,
        buttonType: ""
    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{marginTop: "5px"}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>

                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        }

    };
    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    componentDidMount() {


        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;

            let remarksArray = [];
            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        appId: this.props.appId,
                        getData: true,
                        showValue: true,
                        varValue: this.removeNullValue(response.data),
                        inputData: this.removeNullValue(response.data),
                    });
                    if (response.data.freeFlag2 === "lien") {
                        dataArray = response.data.existingLienFlag ? response.data.existingLienFlag : {};
                    }

                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            console.log(response.data);
                            response.data.map((data) => {

                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                            });
                            this.setState({
                                getRemarks: remarksArray
                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        })

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
        }

    }


    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };

    handleSubmit = (event, data) => {
        event.preventDefault();
        if (data === "CLOSED") {
            this.setState({
                buttonType: "CLOSED",
                title: "Do you want to confirm?",
                confirmationAlert: true
            });
        } else {
            let error = false;
            if (this.props.freeFlag2 === "dormant") {
                error = MyValidation.defaultValidation(dormantActivationMaker, this.state)
            } else if (this.props.freeFlag2 === "unfreeze") {
                error = MyValidation.defaultValidation(unfreezeMarkingMaker, this.state)
            } else if (this.props.freeFlag2 === "siDelete") {
                error = MyValidation.defaultValidation(casaSiDeleteMaker, this.state)
            } else if (this.props.freeFlag2 === "fundTransfer") {
                error = MyValidation.defaultValidation(manualFundTransferMaker, this.state)
            } else if (this.props.freeFlag2 === "signature") {
                error = MyValidation.defaultValidation(signatureAndPhotographMaker, this.state)
            }
            this.forceUpdate();
            if (error === true) {
                return 0;
            } else {
                this.setState({
                    buttonType: "SUBMIT",
                    title: "Do you want to confirm?",
                    confirmationAlert: true
                });
            }
        }
    };

    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                confirmationAlert: false,
                loading: true
            });
            if (this.state.buttonType === "CLOSED") {
                this.handleClose()
            } else {
                this.functionForCaseRoute()
            }
        } else {
            this.setState({
                confirmationAlert: false,
            })
        }
    };

    functionForCaseRoute = () => {
        let data = this.state.inputData;
        data.serviceType = "Maintenance";
        data.subServiceType = "AccountMaintenance";
        data.category = "CASA Closer";
        data.subCategory = "Maintenance";
        data.maker_update_all_info_send_to = "CHECKER";
        data.maker_approval = "APPROVED";
        data.freeFlag1 = "CloserRequest";
        let remarksData = {};
        remarksData.remark = this.state.inputData.makerRemarks;
        remarksData.map = {...data, makerRemarks: undefined};
        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            this.setState({
                title: "Successful!",
                notificationMessage: "Successfully Routed!",
                alert: true,
                routeCase: true,
                loading: false
            });
            if (this.state.inputData.checker_approval === "RETURN") {
                this.props.closeModal();
            } else {
                setTimeout(function () {
                    window.location.reload()
                }, 1000);
            }
        }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false
            })
        });
    };

    renderFileUpload = () => {
        if (this.state.getData) {
            return (

                <Grid item xs={12}>
                    {FileTypeComponent.file(this.state, this.updateComponent, fileUpload)}
                </Grid>

            )
        }
    };

    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <Grid item xs={12}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={(e) => this.handleSubmit(e, "APPROVED")}
                    >
                        Submit
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-info btn-sm"
                        onClick={(e) => this.handleSubmit(e, "CLOSED")}
                    >
                        Close
                    </button>
                </Grid>
            )
        }
    };

    handleClose = (event) => {
        event.preventDefault();
        let variableSetUrl = backEndServerURL + "/case/close/" + this.props.appId;
        axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Close!",
                        alert: true
                    });
                    this.props.closeModal();
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };

    previewDocument = () => {
        this.setState({
            previewDocument: true
        })
    };

    closePreviewDocument = () => {
        this.setState({
            previewDocument: false
        })
    };

    viewAttachedImages = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return <PreviewMappingImage appId={this.props.appId} classes={classes}/>
        }
    };

    renderParamWiseJson = () => {
        if (this.props.freeFlag2 === "dormant") {
            return CommonJsonFormComponent.renderJsonForm(this.state, dormantActivationMaker, this.updateComponent)
        } else if (this.props.freeFlag2 === "unfreeze") {
            return CommonJsonFormComponent.renderJsonForm(this.state, unfreezeMarkingMaker, this.updateComponent, this.onKeyDownFunction, this.onKeyDownChangeFunction)
        } else if (this.props.freeFlag2 === "siDelete") {
            return CommonJsonFormComponent.renderJsonForm(this.state, casaSiDeleteMaker, this.updateComponent)
        } else if (this.props.freeFlag2 === "fundTransfer") {
            return CommonJsonFormComponent.renderJsonForm(this.state, manualFundTransferMaker, this.updateComponent)
        } else if (this.props.freeFlag2 === "signature") {
            return CommonJsonFormComponent.renderJsonForm(this.state, signatureAndPhotographMaker, this.updateComponent, this.onKeyDownFunction, this.onKeyDownChangeFunction)
        }
    };

    onKeyDownFunction = (event, data) => {

    };

    onKeyDownChangeFunction = (data) => {

    };


    renderJsonFormFirst = () => {
        if (this.state.getData) {
            return (
                <React.Fragment>
                    <Label input={this.state.inputData} stopLoading={this.stopLoading} classes={this.props}/>
                    <br/>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <br/>
                        </Grid>
                        <SignatureButton accountNumber={this.state.inputData.accountNumber} classes={this.props}/>
                    </Grid>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent)
                    }
                    {this.renderParamWiseJson()}
                </React.Fragment>
            )
        }
    };

    stopLoading = (value) => {
        if (value === "yes") {
            setTimeout(() => {
                this.setState({
                    loading: false,
                });
            }, 1000)
        } else {
            this.setState({
                getData: false,
                searchData: false,
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, checkerRemarks, this.updateComponent)
            )
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    functionForGetLienList = (value) => {
        let lienTableArray = [];
        let lienUrl = backEndServerURL + "/accounts/common/getLienList/" + this.state.inputData.accountNumber;
        axios.get(lienUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((item, index) => {
                    lienTableArray.push(this.createLienTableData(index + 1, item.newLienAmt, item.reasonCode, item.remarks, this.triggerExistingLienForDelete(item.lienId, index)))
                });

                if (value !== undefined) {
                    this.setState({
                        lienTableArray: lienTableArray,
                        showExistingLien: true,
                        loading: false
                    })
                } else {
                    this.setState({
                        lienTableArray: lienTableArray,
                        showExistingLien: true
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    cbNotFound: true,
                    title: error.response.data.message
                });
            });
    };

    renderExistingLienData = () => {
        if (this.props.freeFlag2 === "lien" && this.state.lienTableArray.length > 0 && this.state.showExistingLien) {
            return (
                <>
                    <br/>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Existing Lien Details:</b>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Sl", "CB Number", "Customer Name", "Relationship Code", "Delete"]}
                                tableData={this.state.lienTableArray}
                                tableAllign={['left', 'left', 'left', 'left', 'left']}
                            />
                            <br/>
                        </div>
                    </div>
                    <br/>
                </>
            )
        }
    };

    createLienTableData = (sl, amount, reasonCode, remarks, lienId) => {
        return ([
            sl, amount, reasonCode, remarks, lienId
        ])
    };

    triggerExistingLienForDelete = (id, index) => {
        let checkedValue = false;
        if (this.state.inputData.existingLienFlag !== undefined) {
            checkedValue = this.state.inputData.existingLienFlag["lienForDelete" + index] !== undefined
        }
        return (
            <input type="checkbox" onChange={(event) => this.functionForUnlien(event)} checked={checkedValue}
                   name={"lienForDelete" + index} value={id}/>
        )
    };

    functionForUnlien = (event, id, index) => {
        this.setState({
            loading: true,
            showExistingLien: false
        });
        let indexName = (event.target.name).toString();
        let indexValue = (event.target.value).toString();
        this.forceUpdate();
        if (event.target.checked) {
            dataArray[indexName] = indexValue;
        } else {
            delete dataArray[indexName]
        }
        this.state.inputData.existingLienFlag = dataArray;
        this.functionForGetLienList("stop");
        this.forceUpdate();
    };


    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Card>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>
                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.confirmationAlert}>
                            <DialogContent className={classes.dialogPaper}>
                                <ConfirmationModal
                                    closeModal={this.closeConfirmation}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.cbNotFound}>
                            <DialogContent className={classes.dialogPaper}>
                                <CBNotFound
                                    closeModal={this.closeModalCBNotFound}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        <CardBody>
                            <ThemeProvider theme={theme}>
                                <Grid container spacing={0}>
                                    {this.renderNotification()}
                                    {this.renderJsonFormFirst()}
                                </Grid>
                                {this.renderExistingLienData()}
                                <br/>
                                {this.renderRemarksData()}
                                {this.viewAttachedImages()}
                                {this.renderRemarks()}
                                {this.renderSubmitButton()}
                            </ThemeProvider>
                        </CardBody>
                    </Card>
                </div>
            </GridList>

        )
    }
}

export default withStyles(styles)(CloserRequestForMaker);
