import React, {Component} from 'react';
import '../Static/css/Listitem.css'
import HeaderContent from "./Layouts/HeaderContent";
import MenuSelected from '../Common/MenuSelected';


import 'antd/dist/antd.css';

import {Breadcrumb, Layout} from 'antd';
import Sidebar from "./Layouts/Sidebar";

import FooterContent from "./Layouts/FooterContent";

import TableContent from "./Layouts/Inbox";


const {Content} = Layout;

class ParentDashboard extends Component {

    render() {
        console.log(MenuSelected.menuId);
        return (
            <Layout>
                <HeaderContent/>
                <Content style={{padding: '0 50px'}}>
                    <Breadcrumb style={{margin: '16px 0'}}>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>List</Breadcrumb.Item>
                        <Breadcrumb.Item>App</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout style={{background: '#fff'}}>
                        <Sidebar/>
                        <Content style={{padding: '0 24px', minHeight: 280}}>
                            <TableContent/>
                        </Content>
                    </Layout>
                </Content>
                <FooterContent/>
            </Layout>
        )
    }
}

export default ParentDashboard