import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import React, {Component} from "react";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CloseIcon from '@material-ui/icons/Close';
import CardBody from "../../Card/CardBody";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import loader from "../../../Static/loader.gif";
import ImageCrop from "./ImageCrop";

let ImageRemarks = [
    {
        "varName": "imageRemarks",
        "type": "text",
        "label": "Signature card Signing Instruction",
        "multiline": true,
        "readOnly": true,
        "grid": 6,
    }
];

class AssignedCropImageEdit extends Component {
    state = {
        getImageLink: [],
        getImageBoolean: false,
        imageModalBoolean: false,
        selectImage: "",
        inputData: {},
        varValue: {},
        getData: false,
        loading: true,
        showValue: false,
        errorMessages: {},
        errorArray: {},
        numberOfSignatureCard: 2,
        numberOfSignatureCardArrayList: [],
        signatureCard: false,
        imageCropModal: false,
        imageName: "",
        imageIndex: 0,

    };

    close = () => {
        this.props.closeModal();
    };
    closeModal = () => {
        this.setState({
            imageModalBoolean: false,
        })
    };
    closeModalForSingleImgaeEdit = () => {
        this.setState({
            imageCropModal: false,
            imageModalBoolean: false,
        })
        this.props.closeModal()
    };
    viewImageModal = (event) => {
        event.preventDefault();

        this.setState({
            selectImage: event.target.value,
            imageModalBoolean: true
        })


    };
    renderDataShow = (data,i) => {
        return (
            <React.Fragment>

                <Grid item xs={1}>
                </Grid>
                <Grid item xs={10}>
                    <img width='100%'
                         src={backEndServerURL + "/signaturePhoto/" + this.props.appId + "/" + data + "/" + sessionStorage.getItem("accessToken")}
                         alt=""/>
                </Grid>

            </React.Fragment>

        )


    };
    editSignatureCard=(event,data,i)=>{
          event.preventDefault();
        this.setState({
            imageCropModal:true,
            imageName: data ,
            imageLink: data ,
            imageIndex:i,
        })
    }
    renderEditButton=(i,data)=>{
        return(
            <button
                style={{
                    marginTop: '15px',
                }}
                className="btn btn-outline-primary btn-sm"

                onClick={(event)=>this.editSignatureCard(event,data,i)}

            >
                Edit Existing Signature Card
            </button>
        )
    }
    mappingPhoto = () => {


        if (this.state.getData && this.state.signatureCard && this.state.numberOfSignatureCardArrayList.length > 0) {
            return (
                <Grid container spacing={1}>
                    {

                        this.state.numberOfSignatureCardArrayList.map((data, i) => {
                            return (
                                <React.Fragment>
                                    {this.renderDataShow(data,i)}
                                    {this.renderImageRemarks(i,data)}

                                </React.Fragment>

                            )

                        })

                    }
                </Grid>
            )
        }
        return (
            <center>
                <h6 style={{
                    color: '#ff1f28'
                }}>Image Not Found</h6>
            </center>
        )

    };

    componentDidMount() {

        let url = backEndServerURL + '/variables/' + this.props.appId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                console.log("Get all data");
                let inputData = response.data;
                let varValue = response.data;
                let fileUrl = backEndServerURL + "/applicationKeyValue/get";
                let data = {};
                data.appId = this.props.appId;
                data.key = "getSignatureCardNumber";
                axios.post(fileUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.setState({
                            numberOfSignatureCard: Number(response.data.value),
                            inputData: inputData,
                            varValue: varValue,
                            getData: true,
                            showValue: true,
                        });
                        let numberOfSignatureCardArrayList = [];
                        for (let i = 0; i < Number(response.data.value); i++) {
                            numberOfSignatureCardArrayList.push("CARD" + (i + 1))
                        }
                        console.log("numberOfSignatureCardArrayList");
                        console.log(numberOfSignatureCardArrayList);
                        this.setState({
                            numberOfSignatureCardArrayList: numberOfSignatureCardArrayList,
                            signatureCard: true,
                            loading: false,

                        })
                    })
                    .catch((error) => {
                        console.log("error");
                        this.setState({
                            getData: true,
                            loading: false,
                        })
                    })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    getData: true,
                    loading: false,
                })
            })


    }

    renderImageRemarks = (i,data) => {
        if (this.state.getData === true) {
            return (
                <React.Fragment>

                    {CommonJsonFormComponent.renderJsonForm(this.state, [
                        {
                            "type": "empty",
                            "grid": 2,
                        },
                        {
                            "varName": "imageRemarks" + i,
                            "type": "text",
                            "label": "Signature card Signing Instruction",
                            "multiline": true,
                            "readOnly": true,
                            "grid": 3,
                        },
                        {
                            "varName": "deleteFlag" + i,
                            "type": "select",
                            "enum": [
                                "YES",
                                "NO",
                            ],
                            "label": "Delete Flag",
                            "multiline": true,
                            "readOnly": true,
                            "grid": 3,
                        }
                        ], this.updateComponent)}
                    <Grid item xs={3}>
                        {this.renderEditButton(i,data)}
                    </Grid>
                    <Grid item xs={1}>
                     </Grid>

                </React.Fragment>
            )
        }

    };
    handleSignatureCardUpdate = (event) => {
        event.preventDefault();
        if (this.props.signatureButtonShowInImageCropedPage === true) {
            this.props.handleSignatureCard(event, "APPROVED");
            this.props.closeModal();
        }
    };
    renderButton = () => {
        if (this.props.signatureButtonShowInImageCropedPage === true) {
            return (
                <Grid item xs={12}>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={6}>

                        <button
                            className="btn btn-outline-primary btn-sm"

                            type='button' value='add more'
                            onClick={this.handleSignatureCardUpdate}
                        >Signature Card Upload Confirm
                        </button>
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>
                </Grid>

            )
        }

    };

    render() {
        return (
            <div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >Cropped Image<a><CloseIcon onClick={this.close}
                                                       style={{position: 'absolute', right: 10, color: "#000000"}}/></a>
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <Dialog
                            fullWidth="true"
                            maxWidth="lg"
                            open={this.state.imageCropModal}>
                            <DialogContent >
                                <ImageCrop caseId={this.props.caseId} singleImageCrop={true}     imageName={this.state.imageName} imageLink={this.state.imageLink} imageIndex={this.state.imageIndex} signatureButtonShowInImageCropedPage={ true} subServiceType={this.props.subServiceType} appId={this.props.appId}
                                           closeModal={this.closeModalForSingleImgaeEdit}/>

                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"


                            open={this.state.loading}>
                            <DialogContent>

                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <ThemeProvider theme={theme}>
                            <Grid container spacing={1}>

                                {this.mappingPhoto()}
                            </Grid>

                        </ThemeProvider>


                    </CardBody>
                </Card>
                <center>
                    {this.renderButton()}
                </center>
            </div>
        )
    }
}

export default AssignedCropImageEdit;
