import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles/index";
import CommonJsonFormComponent from '../../JsonForm/CommonJsonFormComponent';
import Card from "../../Card/Card.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from 'axios/index';
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import DialogContent from "@material-ui/core/DialogContent/index";
import {Dialog} from "@material-ui/core/index";
import Table from "../../Table/Table";
import Signature from "./Signature";
import {
    linkAccountChangeRequestReadOnly,
    lockerJsonFormOneReadOnly,
    lockerJsonFormThreeReadOnly,
    lockerJsonFormTwoReadOnly,
    siDateChangeRequestReadOnly
} from '../WorkflowJsonFormRashed';
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import MyValidation from "../../JsonForm/MyValidation";
import GridList from "@material-ui/core/GridList";

let csRemarks = [
    {
        "varName": "bomRemarks",
        "type": "textArea",
        "label": "BOM Remarks",
        "grid": 12,
        "required": true,
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

class BOMLockerMaintenance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: true,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            getData: false,
            signature: false,
            documentList: [],
            getDocument: false,
            selectedDate: {},
            getRemarks: [],
            loading: true
        }


    }


    signatureOpen = () => {
        this.setState({
            signature: true
        })
    };

    signatureClose = () => {
        this.setState({
            signature: false
        })
    };


    updateComponent = () => {
        this.forceUpdate();
    };

    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };

    convertDate = (varValue) => {
        if (varValue !== undefined) {

            console.log(("numberOfOldNominee" in varValue));
            if (("numberOfOldNominee" in varValue)) {
                for (let i = 0; parseInt(varValue.numberOfOldNominee) > i; i++) {
                    let sl = i + 1;
                    let trimDate = varValue["dob1New" + sl].split(",");
                    let mDY = trimDate[0].split("/");
                    let month, date;
                    if (mDY[0].length === "1") {
                        month = "0" + mDY[0]
                    } else {
                        month = mDY[0];
                    }
                    if (mDY[1].length === "1") {
                        date = "0" + mDY[1]
                    } else {
                        date = mDY[1];
                    }

                    varValue["dob1New" + sl] = mDY[2] + "-" + month + "-" + date;
                }
                this.forceUpdate();
                return varValue;

            }

        }
    };


    componentDidMount() {

        console.log(this.props.appId);

        let url = backEndServerURL + "/variables/" + this.props.appId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.bomRemarks = null;

                let inputData = this.copyJson(response.data);
                let varValue = this.copyJson(response.data);

                if (("numberOfOldNominee" in varValue)) {
                    for (let i = 0; parseInt(varValue.numberOfOldNominee) > i; i++) {
                        let sl = i + 1;
                        if (inputData["dob1New" + sl]) {
                            inputData["dob1New" + sl] = (inputData["dob1New" + sl]).split(',')[0];
                            varValue["dob1New" + sl] = (varValue["dob1New" + sl]).split(',')[0];
                        }
                    }
                }
                if (("numberOfNewNominee" in varValue)) {
                    for (let i = 0; parseInt(varValue.numberOfNewNominee) > i; i++) {
                        let sl = i + 1;
                        if (inputData["dob1" + sl]) {
                            inputData["dob1" + sl] = (inputData["dob1" + sl]).split(',')[0];
                            varValue["dob1" + sl] = (varValue["dob1" + sl]).split(',')[0];
                        }
                    }
                }

                this.setState({
                    inputData: inputData,
                    varValue: varValue,
                    getData: true,
                    loading: false
                });

            })
            .catch((error) => {
                console.log(error);
            });

        let documentsUrl = backEndServerURL + "/case/files/" + this.props.appId;
        axios.get(documentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                this.setState({
                    documentList: response.data,
                    getDocument: true
                })


            })
            .catch((error) => {
                console.log(error);
            });

        var remarksArray = [];
        let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
        axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            console.log(response.data);
            response.data.map((data) => {
                if (data.remarks !== 'undefined') {
                    remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                }
            });
            this.setState({
                getRemarks: remarksArray
            })
        })
            .catch((error) => {
                console.log(error)
            })

    }


    LockerSearchOne = () => {
        if (this.state.getData) {
            return (
                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, lockerJsonFormOneReadOnly, this.updateComponent)
                        }
                    </Grid>

                </Grid>
            )
        }
    };

    LockerSearchTwo = () => {
        if (this.state.getData) {
            return (
                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, lockerJsonFormTwoReadOnly, this.updateComponent)
                        }
                    </Grid>

                </Grid>
            )
        }
    };

    LockerSearchThree = () => {
        if (this.state.getData) {
            return (
                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, lockerJsonFormThreeReadOnly, this.updateComponent)
                        }
                    </Grid>

                </Grid>
            )
        }
    };


    renderExistingNomineeForm = () => {

        if (this.state.getData) {
            var sl;
            var objectForExistingNominee = [];
            for (var i = 0; i < this.state.inputData.numberOfOldNominee; i++) {
                sl = i + 1;
                objectForExistingNominee.push(
                    {
                        "varName": "Nominee " + i,
                        "type": "title",
                        "label": "Existing  Nominee  " + sl,
                        "grid": 12,
                        "readOnly": true
                    },
                    {
                        "varName": "nomineeNameOld" + sl,
                        "type": "text",
                        "label": "Existing Nominee Name",
                        "grid": 6,
                        "readOnly": true
                    },

                    {
                        "varName": "nomineeNameNew" + sl,
                        "type": "text",
                        "label": "New Nominee Name",
                        "grid": 6,
                        "readOnly": true
                    },
                    {
                        "varName": "dob1Old" + sl,
                        "type": "date",
                        "label": "Existing D.O.B",
                        "grid": 6,
                        "readOnly": true
                    },
                    {
                        "varName": "dob1New" + sl,
                        "type": "date",
                        "label": "New D.O.B",
                        "grid": 6,
                        "readOnly": true
                    },

                    {
                        "varName": "percentageNomineeOld" + sl,
                        "type": "text",
                        "label": "Existing Percentage %",
                        "grid": 6,
                        "readOnly": true
                    },
                    {
                        "varName": "percentageNomineeNew" + sl,
                        "type": "text",
                        "label": "New Percentage %",
                        "grid": 6,
                        "readOnly": true
                    },
                    {
                        "varName": "nomineeMinorOld" + sl,
                        "type": "text",
                        "label": "Existing Nominee Minor",
                        "grid": 6,
                    },
                    {
                        "varName": "nomineeMinorNew" + sl,
                        "type": "text",
                        "label": "New Nominee Minor",
                        "grid": 6,
                        "readOnly": true
                    },
                    {
                        "varName": "nomineeDelete" + sl,
                        "type": "checkbox",
                        "label": "Delete This Nominee",
                        "grid": 12,
                        "readOnly": true
                    },
                )

            }

            return (

                CommonJsonFormComponent.renderJsonForm(this.state, objectForExistingNominee, this.updateComponent)

            )
        }
    };

    renderNewNominee = () => {
        if (this.state.inputData.numberOfNewNominee > 0) {
            var sl;
            var objectForNominee = [];
            for (var i = 0; i < this.state.inputData.numberOfNewNominee; i++) {
                sl = i + 1;
                objectForNominee.push(
                    {
                        "varName": "Nominee " + i,
                        "type": "title",
                        "label": "New Nominee  " + sl,
                        "grid": 12,

                    },
                    {
                        "varName": "nomineeName" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 6,
                        required: true,
                    },

                    {
                        "varName": "dob1" + sl,
                        "type": "date",
                        "label": "D.O.B",
                        "grid": 6,
                        required: true,
                    },


                    {
                        "varName": "percentageNominee" + sl,
                        "type": "text",
                        "label": "Percentage %",
                        "grid": 6,
                        required: true,
                    },
                    {
                        "varName": "nomineeMinor" + sl,
                        "type": "select",
                        "label": "Nominee Minor",
                        "enum": ["Yes", "No"],
                        "grid": 6,
                        required: true,
                    },
                )

            }

            return (

                CommonJsonFormComponent.renderJsonForm(this.state, objectForNominee, this.updateComponent)

            )
        }
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    linkAccount = () => {
        if (this.state.getData && this.state.inputData.linkAccountChangeRequest === true) {
            return (
                <ThemeProvider theme={theme}>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, linkAccountChangeRequestReadOnly, this.updateComponent)
                    }
                </ThemeProvider>
            )
        }
    };

    changeNomineeRequest = () => {
        if (this.state.getData && this.state.inputData.nomineeChangeRequest === true) {
            return (
                <ThemeProvider theme={theme}>
                    {
                        this.renderExistingNomineeForm()
                    }

                    {
                        this.renderNewNominee()
                    }
                </ThemeProvider>
            )
        }
    };

    changeSiDateRequest = () => {
        if (this.state.getData && this.state.inputData.siDateChangeRequest === true) {
            return (
                <ThemeProvider theme={theme}>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, siDateChangeRequestReadOnly, this.updateComponent)
                    }
                </ThemeProvider>
            )
        }
    };


    approveHandler = (e) => {
        // alert(this.state.inputData.lockerNumber)
        e.preventDefault();
        console.log(this.state.inputData);
        // return false;

        this.state.inputData.bom_approval = "APPROVED";
        let variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, this.copyJson(this.state.inputData), {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.bomRemarks + "/" + this.props.appId;
                axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    console.log(response.data)
                })
                    .catch((error) => {
                        console.log(error)
                    });
                this.state.inputData.bomRemarks = undefined;

                var url = backEndServerURL + "/case/route/" + this.props.appId;

                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        console.log(response.data);
                        this.setState({
                            title: "Successful!",
                            notificationMessage: "Successfully Routed!",
                            alert: true
                        });
                        this.props.closeModal()
                        //

                    })
                    .catch((error) => {
                        console.log(error);

                    });
            })
            .catch((error) => {
                console.log(error)
            });
    };

    returnHandler = (e) => {
        e.preventDefault();

        let error = MyValidation.defaultValidation(csRemarks, this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        } else {

            this.state.inputData.bom_approval = "RETURN";
            var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
            axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.bomRemarks + "/" + this.props.appId;
                    axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                        console.log(response.data)
                    })
                        .catch((error) => {
                            console.log(error)
                        });
                    this.state.inputData.bomRemarks = undefined;

                    var url = backEndServerURL + "/case/route/" + this.props.appId;

                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            console.log(response.data);
                            this.setState({
                                title: "Successfull!",
                                notificationMessage: "Successfully Routed!",
                                alert: true
                            });
                            this.props.closeModal()
                            //

                        })
                        .catch((error) => {
                            console.log(error);

                        });
                })
                .catch((error) => {
                    console.log(error)
                });
        }
    };


    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "Raised By", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />
                    <br/>
                </div>
            )
        }
    };
    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
            )
        }

    };


    render() {
        console.log('BOMLockerMaintenance');
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <GridList cellHeight={window.innerHeight} cols={1}>
                    <div>
                        <Card>

                            <CardBody>

                                <Grid container>

                                    {
                                        this.LockerSearchOne()
                                    }

                                    {
                                        this.linkAccount()
                                    }

                                    {
                                        this.LockerSearchTwo()
                                    }

                                    {
                                        this.changeNomineeRequest()
                                    }

                                    {
                                        this.LockerSearchThree()
                                    }

                                    {
                                        this.changeSiDateRequest()
                                    }


                                    {
                                        this.renderNotification()
                                    }
                                    <Grid item xs={12}>

                                    </Grid>

                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            verticalAlign: 'middle',
                                            marginTop: '12px',
                                            width: '140px'
                                        }}
                                        type="submit"
                                        onClick={this.signatureOpen}


                                    >
                                        Signature Card

                                    </button>

                                    <Grid item xs={12}>

                                    </Grid>

                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="md"
                                        open={this.state.signature}>
                                        <DialogContent>
                                            <Signature closeModal={this.signatureClose} appId={this.props.appId}/>
                                        </DialogContent>
                                    </Dialog>
                                    <br/>
                                    <Grid item xs='12'>
                                        {this.renderRemarksData()}
                                    </Grid>

                                </Grid>
                                <br/>
                                <ThemeProvider theme={theme}>
                                    <Grid container>
                                        {this.renderRemarks()}

                                    </Grid>
                                </ThemeProvider>


                                <center>
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            verticalAlign: 'middle',
                                            marginTop: '12px',
                                        }}
                                        onClick={this.approveHandler}
                                        type="submit"

                                    >
                                        Submit

                                    </button>
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            verticalAlign: 'middle',
                                            marginTop: '12px',
                                            marginLeft: '10px'
                                        }}
                                        onClick={this.returnHandler}
                                        type="submit"

                                    >
                                        Return

                                    </button>
                                </center>
                            </CardBody>


                        </Card>
                    </div>
                </GridList>

            )
        }

    }

}

export default withStyles(styles)(BOMLockerMaintenance);
