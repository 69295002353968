import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../../Static/css/RelationShipView.css";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Table from "../../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CloseIcon from '@material-ui/icons/Close';
import Notification from "../../../NotificationMessage/Notification";
import loader from "../../../../Static/loader.gif";
import DynamicFileAttachment from "../Common/DynamicFileAttachment";
import MyValidation from "../../../JsonForm/MyValidation";
import {CheckerLoanClosingJsonForm} from "./JsonFormLoanClosing";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";
import CardBody from "../../../Card/CardBody";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";
import CBNotFound from "../../CASA/CBNotFound";
import CommonApi from "../../Maintenance/common/CommonApi";
import Label from "../Common/Label";
import ErrorModal from "../../CommonComponent/ErrorModal";
import ConfirmAlert from "../../CommonComponent/ConfirmAlert";


const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};


let assetOpsCheckerRemarks = [
    {
        "varName": "assetOpsCheckerRemarks",
        "type": "textArea",
        "label": "Asset Ops Checker Remarks",
        "grid": 12
    }]
;


class ClosingCheckerFormLoanClosing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            imageLink: {},
            tableData: [],
            inputData: {},
            varValue: {},
            getImageLink: [],
            getImageBoolean: false,
            appId: '',
            getData: false,
            caseId: "",
            title: "",
            notificationMessage: "",
            alert: false,
            type: [],
            fileUploadData: [],
            selectedDate: {},
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            cbNotFound: false,
            errorModalBoolean: false,
            errorTittle: "",
            confirmAlertReturn: false,
            insufficientBalance: false,
            shortageAmount : undefined,

        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };


    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "Raised By", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />
                    <br/>
                </div>
            )
        }
    };

    renderFlowSUmmeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.props.cbNumber} appId={this.props.appId}
                                   caseId={this.props.caseId}
                                   customerName={this.props.customerName} accountNumber={this.props.accountNumber}
                                   serviceType={this.state.inputData.serviceType}
                                   category={this.state.inputData.category}
                                   subCategory={this.state.inputData.subCategory}
                                   solId={this.props.solId}/>
            )
        }
    };

    componentDidMount() {
        if (this.props.appId !== undefined) {
            CommonApi.getVariablesNew(this.props.appId, assetOpsBackEndServerURL).then((response) => {
                let inputData = {...response.data};
                inputData.assetOpsCheckerRemarks = undefined;
                CommonApi.getFilesNew(this.props.appId, assetOpsBackEndServerURL).then((fileResponse) => {
                    CommonApi.getRemarkDetailsNew(this.props.appId, assetOpsBackEndServerURL).then((remarksArray) => {
                        CommonApi.getCustAccountDetails(inputData.casaAccount).then((response) => {
                            inputData.casaBalance = response.data.acctBalance;
                            this.setState({
                                getRemarks: remarksArray,
                                getData: true,
                                showValue: true,
                                varValue: this.removeNullValue(inputData),
                                inputData: this.removeNullValue(inputData),
                                appId: this.props.appId,
                                getImageLink: fileResponse,
                                getImageBoolean: true,
                                loanExciseDuty: true,
                                loading: false
                            })
                            this.handleInsufficientBalance();
                        }).catch((error) => {
                            console.log(error);
                            this.setState({
                                cbNotFound: true,
                                title: error.response.data.message,
                                loading: false
                            })
                        })

                    }).catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    });
                }).catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
            }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
        }
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderForm = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, CheckerLoanClosingJsonForm, this.updateComponent)
            )
        }
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    validationForHandleConfirm = (actionType) => {
        let errorRemarks = MyValidation.defaultValidation(assetOpsCheckerRemarks, this.state);
        this.forceUpdate();
        if (errorRemarks===true) {
            return 0;
        }
        this.setState({
            appId: this.props.appId,
            confirmAlert: true,
            actionType: actionType,
            title: "Do you want to confirm?"
        });
    };
    closeConfirmAlert = (value) => {
        this.setState({
            confirmAlert: false
        });
        if (value === "ok" && this.state.actionType === "APPROVED") {
            this.handleSubmit("APPROVED")
        }

    };


    handleCommon = (event, type) => {
        event.preventDefault();
        (type === "handleReturn") ? this.validationForHandleConfirmForReturn(type) : this.validationForHandleConfirm(type);

    };

    closeModal = () => {
        this.setState({
            errorModalBoolean: false,
        })
    };

    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "Finacle System Error!"
                }
            } else {
                return "Finacle System Error!"
            }
        } else {
            return "Finacle System Error!"
        }
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    handleSubmit = (checker_approval) => {
        if (checker_approval === "APPROVED") {
            if (this.state.inputData.apiButton === "loanExciseDuty") {

                let inputData = {...this.state.inputData};
                let postData = {};
                postData.exciseDuty = this.state.inputData.exciseDuty;
                postData.loanAccounts = this.state.inputData.loanAccounts;
                this.setState({
                    loading: true,
                })
                let url = assetOpsBackEndServerURL + "/getLoanExciseDuty";
                axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response)
                        inputData.apiButton = "loanFeeCollection";
                        this.setState({
                            loading: false,
                            varValue: this.removeNullValue(inputData),
                            inputData: this.removeNullValue(inputData),

                        })
                        let variablesData = {...this.state.inputData}
                        let variablesUrl = assetOpsBackEndServerURL + "/variables/" + this.props.appId;
                        axios.post(variablesUrl, variablesData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                console.log(response);
                                this.setState({
                                    loading: false,
                                })

                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    loading: false,
                                })

                            })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                            errorModalBoolean: true,
                            errorTittle: this.errorObjectCheck(error),
                        })

                    })
            } else if (this.state.inputData.apiButton === "loanFeeCollection") {

                let inputData = {...this.state.inputData};
                let postData = {};
                postData.loanAccounts = this.state.inputData.loanAccounts;
                postData.casaAccount = this.state.inputData.casaAccount;
                postData.earlySettlementFee = this.state.inputData.earlySettlementFee;
                postData.vat = this.state.inputData.vat;
                console.log(postData)
                this.setState({
                    loading: true,
                })
                let url = assetOpsBackEndServerURL + "/getLoanFeeCollection";
                axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);
                        inputData.apiButton = "loanPayOff";
                        this.setState({
                            loading: false,
                            varValue: this.removeNullValue(inputData),
                            inputData: this.removeNullValue(inputData),
                        })

                        let variablesData = {...this.state.inputData}
                        let variablesUrl = assetOpsBackEndServerURL + "/variables/" + this.props.appId;
                        axios.post(variablesUrl, variablesData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                console.log(response);
                                this.setState({
                                    loading: false,
                                })

                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    loading: false,
                                })

                            })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                            varValue: this.removeNullValue(inputData),
                            inputData: this.removeNullValue(inputData),
                            errorModalBoolean: true,
                            errorTittle: this.errorObjectCheck(error),
                        })

                    })

            } else if (this.state.inputData.apiButton === "loanPayOff") {
                let inputData = {...this.state.inputData};
                let postData = {};
                postData.collAcct = this.state.inputData.casaAccount;
                postData.loanAccounts = this.state.inputData.loanAccounts;
                this.setState({
                    loading: true,
                })
                let url = assetOpsBackEndServerURL + "/doLoanPayOff/";
                axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);
                        inputData.apiButton = "finalApproval";

                        this.setState({
                            loading: false,
                            varValue: this.removeNullValue(inputData),
                            inputData: this.removeNullValue(inputData),
                        })

                        let variablesData = {...this.state.inputData}
                        let variablesUrl = assetOpsBackEndServerURL + "/variables/" + this.props.appId;
                        axios.post(variablesUrl, variablesData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                console.log(response);
                                this.setState({
                                    loading: false,
                                })

                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    loading: false,
                                })

                            })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                            errorModalBoolean: true,
                            errorTittle: this.errorObjectCheck(error),
                        })
                    })

            } else if (this.state.inputData.apiButton === "finalApproval") {
                let loanClosingData = {};
                let accountNumber = this.state.inputData.accountNumber;
                this.setState({
                    loading: true,
                })
                let loanclosingurl = assetOpsBackEndServerURL + "/closeLoanAccount/" + accountNumber;
                axios.post(loanclosingurl, loanClosingData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);

                        let saveDataDeferralRemarksAndRoute = {};
                        if (this.state.inputData.assetOpsCheckerRemarks !== undefined && this.state.inputData.assetOpsCheckerRemarks !== null) {
                            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.assetOpsCheckerRemarks;
                        }
                        this.state.inputData.case_discrepancy = "NO";
                        saveDataDeferralRemarksAndRoute.map = {...this.state.inputData};
                        let url = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
                        axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                console.log(response.data);
                                this.setState({
                                    title: "Successfull!",
                                    notificationMessage: response.data,
                                    alert: true,
                                    loading: false
                                });
                                this.props.closeModal()
                                //
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    loading: false
                                })
                            })


                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            loading: false,
                            cbNotFound: true,
                            title: error.response.data.message,
                            errorModalBoolean: true,
                            errorTittle: this.errorObjectCheck(error),
                            disabled: false
                        });
                    });


            }

        }
    }

    close = () => {
        this.props.closeModal();
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, assetOpsCheckerRemarks, this.updateComponent)
            )
        }
    };
    dateModifier=(date)=>{
        if(date!==undefined && date!=null){
            let splittedDate = (date).split("/");
            return`${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`;
        }
    }

    renderFormTable = () => {
        return (
            <div style={{padding: "10px 5px 20px", width: '100%'}}>
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <table className="assetTable" style={{borderCollapse: 'collapse', "width": "100%"}}>
                        <tr>
                            <th scope="row">Product Name</th>
                            <td>{this.state.inputData.productName}</td>
                            <th scope="row">Loan Amount</th>
                            <td>{this.state.inputData.loanAmt}</td>
                        </tr>
                        <tr>
                            <th scope="row">Scheme Code</th>
                            <td>{this.state.inputData.schemeCode}</td>
                            <th scope="row">Pay Of Balance</th>
                            <td>{this.state.inputData.loanAccountBalance}</td>
                        </tr>
                        <tr>
                            <th scope="row">Date of Disbursement</th>
                            <td>{this.dateModifier(this.state.inputData.disbursementDate)}</td>
                            <th scope="row">Early Settlement Fee</th>
                            <td>{this.state.inputData.earlySettlementFee}</td>
                        </tr>
                        <tr>
                            <th scope="row">Loan Expiry Date</th>
                            <td>{this.dateModifier(this.state.inputData.loanAccountExpiryDate)}</td>
                            <th scope="row">VAT</th>
                            <td>{this.state.inputData.vat}</td>
                        </tr>
                        <tr>
                            <th scope="row">Maturity Status</th>
                            <td>{this.state.inputData.maturityStatus}</td>
                            <th scope="row">Excise Duty</th>
                            <td>{this.state.inputData.exciseDuty}</td>
                        </tr>

                        <tr>
                            <th scope="row">Total Settlement Amount</th>
                            <td>{this.state.inputData.totalAmount}</td>
                            <th scope="row">CASA BALANCE</th>
                            <td>{this.state.inputData.casaBalance}</td>
                        </tr>

                    </table>
                </div>
            </div>
        )
    };

    renderDynamicFileUpload = () => {
        if (!this.state.loading && this.state.getData) {
            return <DynamicFileAttachment appId={this.props.appId}
                                          submitButtonClick={this.state.submitButtonClick}
                                          functionFoFile={this.functionFoFile}/>
        }
    };


    functionFoFile = (status) => {
        this.setState({
            submitButtonClick: false,
            loading: true,
        });

        if (status === "success" || status === "No File Found") {
            this.setState({
                loading: true
            });
            let saveDataDeferralRemarksAndRoute = {};
            if (this.state.inputData.assetOpsCheckerRemarks !== undefined && this.state.inputData.assetOpsCheckerRemarks !== null) {
                saveDataDeferralRemarksAndRoute.remark = this.state.inputData.assetOpsCheckerRemarks;
            }
            this.state.inputData.case_discrepancy = this.state.passingData;
            saveDataDeferralRemarksAndRoute.map = {...this.state.inputData};
            let url = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
            axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.props.closeModal();
                    this.setState({
                        title: "Successful!",
                        notificationMessage: response.data,
                        alert: true,
                        loading: false
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
        } else {
            this.setState({
                cbNotFound: true,
                loading: false,
                title: "Failed to Upload File."
            })
        }
    };


    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <Button target={"_blank"}
                                href={assetOpsBackEndServerURL + "/file/" + data + '/' + sessionStorage.getItem("accessToken")}
                                style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    borderRadius: 3,
                                    border: 1,
                                    textDecoration: "none"
                                }}
                                target={"_blank"}>{splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </Button>&nbsp;&nbsp;
                    </React.Fragment>
                )
            })
        }
    };


    renderHeader = () => {

        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b>{this.state.inputData.serviceType}</b>
                    <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber || ""}
                        <b> CB Number:</b>{this.state.inputData.cbNumber}
                        <b> SOLID:</b>{this.props.solId} <b> Case
                            ID:</b> {this.props.caseId || ""})<a><CloseIcon
                            onClick={this.close}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Closing Request(secured Loan){this.props.appId !== undefined ?
                    <a><CloseIcon onClick={this.close} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a> : ""}</h4>
            )
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    stopLoading = (value) => {
        if (value === "yes") {
            this.setState({
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    renderCustomerDetails = () => {
        if (this.state.inputData.showCasaDetails === "YES") {
            return (
                <>
                    <Label accountNumber={this.state.inputData.casaAccount} stopLoading={this.stopLoading}
                           classes={this.props}/>
                    <Grid item xs={12}><br/></Grid>
                </>
            )
        }
    };

    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <Grid item xs={12}>
                    <div>
                        {!this.state.insufficientBalance && this.state.inputData.apiButton === "loanFeeCollection" && this.state.loading === false &&
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20
                                }}
                                type='button' value='add more'
                                onClick={(event) => this.handleCommon(event, "APPROVED")}

                            >Loan Fee Collection
                            </button>}

                        {!this.state.insufficientBalance && this.state.inputData.apiButton === "loanExciseDuty" && this.state.loading === false &&
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}

                                type='button' value='add more'
                                onClick={(event) => this.handleCommon(event, "APPROVED")}

                            >Loan Excise Duty
                            </button>}

                        {!this.state.insufficientBalance && this.state.inputData.apiButton === "loanPayOff" && this.state.loading === false &&
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}

                                type='button' value='add more'
                                onClick={(event) => this.handleCommon(event, "APPROVED")}

                            >Loan Pay Off
                            </button>}

                        {!this.state.insufficientBalance && this.state.inputData.apiButton === "finalApproval" && this.state.loading === false &&
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}

                                type='button' value='add more'
                                onClick={(event) => this.handleCommon(event, "APPROVED")}

                            >Submit
                            </button>}

                        &nbsp;&nbsp;&nbsp;
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                                marginTop: 20
                            }}

                            type='button' value='add more'
                            // onClick={(event) => this.handleSubmit(event, "NO")}
                            onClick={(event) => this.handleCommon(event, "handleReturn")}
                        >Return
                        </button>

                        &nbsp;&nbsp;&nbsp;
                        {this.state.insufficientBalance && this.state.shortageAmount!==undefined && this.state.loading === false &&
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20
                                }}
                                type='button' value='add more'
                                onClick={(event) => this.handleSms(event)}

                            >Send Sms(Insufficient Balance)
                            </button>}

                    </div>
                </Grid>
            )
        }
    };
    handleSms = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
        });
        if (this.state.getData && this.state.insufficientBalance && this.state.shortageAmount!==undefined) {
            let postData={
                "cbNumber":this.state.inputData.cbNumber,
                "msg": " Dear Sir/ Madam,\n We are unable to process the closure request due to insufficient fund. Please arrange to deposit "+ this.state.shortageAmount+" (Shortage amount) to close the account today. This amount will vary in future date. ",
                "mbNo" : "01684538832",
            };
            let url = assetOpsBackEndServerURL + "/insufficientBalanceSms";
            axios.post(url, postData, {
                headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,},
            }).then((response) => {
                this.setState({
                    loading: false,
                    cbNotFound: true,
                    title: response.data,
                });
            }).catch((error) => {
                console.log(error);
                this.setState({
                    cbNotFound: true,
                    title: error.response.data.message,
                    loading: false
                })
            });

        }
    };

    validationForHandleConfirmForReturn = (actionType) => {
        let errorRemarks = MyValidation.defaultValidation(assetOpsCheckerRemarks, this.state);
        this.forceUpdate();
        if (errorRemarks===true) {
            return 0;
        }
        this.setState({
            appId: this.props.appId,
            confirmAlertReturn: false,
        });
        if (actionType === "handleSubmit") {
            this.setState({
                actionType: actionType,
                confirmAlert: true,
                title: "Do you want to confirm?"
            })
        } else if (actionType === "handleReturn") {
            let dependencyField = [];
            dependencyField.push({
                "varName": "assetOpsCheckerRemarks",
                "type": "textArea",
                "required": true,
                "label": "Asset Ops Checker Remarks",
                "grid": 12
            });

            let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlertReturn: true,
                // title: "Do you want to confirm?"
            })
        }

    };
    handleInsufficientBalance = () => {
        if (this.state.getData) {
            this.setState({
                insufficientBalance: false
            })
            let data = {...this.state.inputData};
            let total = parseFloat(data.loanAccountBalance) + parseFloat(data.vat) + parseFloat(data.earlySettlementFee) + parseFloat(data.exciseDuty);
            if (total > parseFloat(data.casaBalance)) {
                let shortageAmount=total-parseFloat(data.casaBalance);
                this.forceUpdate();
                this.setState({
                    insufficientBalance: true,
                    shortageAmount: shortageAmount,
                })
                console.log("Shortage Amount is "+shortageAmount)
            }
        }
    };

    closeConfirmAlertForReturn = (data, appId) => {
        this.setState({
            confirmAlertReturn: false,
        });
        console.log(data)
        if (data === "YES" && this.state.actionType === "handleReturn") {

            this.handleReturn("YES")
        }
    };

    handleReturn = (data) => {
        this.setState({
            passingData: data,
            submitButtonClick: true
        });


    };

    render() {
        const {classes} = this.props;
        {
            Functions.redirectToLogin(this.state)
        }


        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderHeader()}
                </CardHeader>
                <CardBody>
                    <ThemeProvider theme={theme}>
                        <Grid container spacing={1}>
                            {this.renderNotification()}
                            {this.renderCustomerDetails()}
                            {this.renderForm()}
                            {this.renderDynamicFileUpload()}
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.loading}>
                                <DialogContent className={classes.dialogPaper}>
                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.confirmAlert}>
                                <DialogContent className={classes.dialogPaper}>
                                    <ConfirmationModal
                                        closeModal={this.closeConfirmAlert}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="xl"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.errorModalBoolean}>
                                <DialogContent className={classes.dialogPaper}>
                                    <ErrorModal title={this.state.errorTittle} closeModal={this.closeModal}
                                                subServiceType={this.props.subServiceType} appId={this.props.appId}
                                    />

                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.confirmAlertReturn}>
                                <DialogContent className={classes.dialogPaper}>
                                    <ConfirmAlert validationForHandleConfirm={this.validationForHandleConfirmForReturn}
                                                  closeModal={this.closeConfirmAlertForReturn}
                                                  asset={true}

                                    />

                                </DialogContent>
                            </Dialog>
                            {this.state.getImageBoolean && <Grid item xs={12}></Grid>}
                            {this.mappingPhoto()}
                            {this.state.getImageBoolean && <Grid item xs={12}></Grid>}
                            {this.renderFormTable()}
                        </Grid>
                        {this.renderRemarksData()}
                        <Grid item xs={12}><br/></Grid>
                        {this.renderFlowSUmmeryButton()}
                        {this.renderRemarks()}
                        {this.renderSubmitButton()}

                    </ThemeProvider>
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(styles)(ClosingCheckerFormLoanClosing);
