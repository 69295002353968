import React, {Component} from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Box from '@material-ui/core/Box';
import axios from "axios";
import LabelDetails from "../Maintenance/static/LabelDetails";
import {backEndServerURL} from "../../../Common/Constant";
import loader from "../../../Static/loader.gif";

class AccountDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            inputData: {},
            inputValue: {},
            showValue: false,
            labelData: [],
            loading: true,
            linkedCB: [],
            name: "",
            getData: false,
        }
    }


    componentDidMount() {

        if (this.props.accountNumber !== undefined && this.props.accountNumber !== null) {
            let getDataByAc = backEndServerURL + "/GetCustomAcctDetailsRequest/" + this.props.accountNumber;
            axios.get(getDataByAc, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let getAccountUrl = backEndServerURL + "/getAccountInfo/" + response.data.acctNumber;
                    axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((accountDataResponse) => {
                            let labelData = [];
                            if (this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account') {
                                labelData = [

                                    {
                                        name: 'Debit Account Number', value: response.data.acctNumber
                                    },
                                    {
                                        name: 'Debit Account Name', value: response.data.acctName
                                    },

                                    {
                                        name: 'Available Amount', value: response.data.acctEftvBalance
                                    },

                                    {
                                        name: 'Cash Limit (Dr)*', value: accountDataResponse.data.drclrlim
                                    },
                                    {
                                        name: 'Clearing Limit (Dr)*', value: accountDataResponse.data.crclrlim
                                    },
                                    {
                                        name: 'Transfer Limit (Dr)*', value: accountDataResponse.data.crtxlim
                                    },
                                    {
                                        name: 'E-TIN', value: this.props.tin
                                    },
                                    {
                                        name: 'Rate', value: this.props.interestRate + "%"
                                    },
                                    {
                                        name: 'Tax Waiver Applicable', value: this.props.taxWaiverChanges
                                    },
                                    {
                                        name: 'Preferential Rate Applicable', value: this.props.preventialRateChanges
                                    }

                                ];
                            } else if (this.props.accountDetailsButton !== true) {
                                labelData = [
                                    /* {
                                         name: 'CB Number', value: this.props.cbNumber
                                     },*/
                                    {
                                        name: 'Debit Account Number', value: response.data.acctNumber
                                    },
                                    {
                                        name: 'Debit Account Name', value: response.data.acctName
                                    },

                                    {
                                        name: 'Available Amount', value: response.data.acctEftvBalance
                                    },
                                    {
                                        name: 'Transaction ID/Cheque Number', value: this.props.transactionID
                                    },
                                    {
                                        name: 'Transaction Date', value: this.props.transactionDate
                                    },

                                    {
                                        name: 'Cash Limit (Dr)*', value: accountDataResponse.data.drclrlim
                                    },
                                    {
                                        name: 'Clearing Limit (Dr)*', value: accountDataResponse.data.crclrlim
                                    },
                                    {
                                        name: 'Transfer Limit (Dr)*', value: accountDataResponse.data.crtxlim
                                    },
                                    {
                                        name: 'E-TIN', value: this.props.tin
                                    },
                                    {
                                        name: 'Maturity/Disposal Instruction*', value: this.props.maturity
                                    },
                                    {
                                        name: 'Rate', value: this.props.interestRate + "%"
                                    },
                                    {
                                        name: 'Tax Waiver Applicable', value: this.props.taxWaiverChange
                                    },
                                    {
                                        name: 'Preferential Rate Applicable', value: this.props.preventialRateChange
                                    }

                                ];
                            }


                            if (response.status === 200) {
                                if (response.data.relPartyList && response.data.relPartyList.length > 0 && this.props.accountDetailsButton !== true) {
                                    labelData.push({
                                        name: 'Secondary CB',
                                        value: response.data.relPartyList,
                                        popup: true,
                                        popupValue: 'linkedCB'
                                    },);
                                }
                                if (response.data.solId && this.props.accountDetailsButton !== true) {
                                    labelData.push({name: 'SOL Id', value: response.data.solId})
                                }
                                if (response.data.lienDetails && response.data.lienDetails.length > 0) {
                                    labelData.push({name: 'Lien', value: "", popup: true, popupValue: 'Lien'})//
                                }
                                if (response.data.LinkedTDDetails && response.data.LinkedTDDetails.length > 0 && this.props.accountDetailsButton !== true) {
                                    labelData.push({name: "FDR/DPS", value: "", popup: true, popupValue: 'FDR'})
                                }
                                if (response.data.linkedLoanDetails && response.data.linkedLoanDetails.length > 0 && this.props.accountDetailsButton !== true) {
                                    labelData.push({name: 'Loan Account', value: "", popup: true, popupValue: 'Loan'})
                                }
                                if (response.data.freezeCode || response.data.freezeReasonCode) {
                                    labelData.push({
                                        name: 'Freeze status',
                                        value: "",
                                        popup: true,
                                        popupValue: 'Freeze'
                                    })//
                                }
                                if (response.data.acctStatus === "D") {
                                    labelData.push({name: 'Dormancy', value: "", popup: true, popupValue: 'Dormancy'})//
                                }
                                if (response.data.acctStatus && this.props.accountDetailsButton !== true) {
                                    labelData.push({
                                        name: 'Customer Status',
                                        value: "",
                                        popup: true,
                                        popupValue: 'ADUP'
                                    })
                                }
                                if (response.data.freeText12 && this.props.accountDetailsButton !== true) {
                                    labelData.push({name: 'CTR', value: "", popup: true, popupValue: 'CTR'})
                                }
                                setTimeout(() => {
                                    this.setState({
                                        labelData: labelData,
                                        inputValue: response.data,
                                        showValue: true,
                                        loading: false,
                                        getData: true,
                                    }, this.parentLoad);
                                }, 1000)
                            }
                        })
                        .catch((error) => {
                            //console.log(error);
                            this.setState({
                                loading: false,
                                showValue: true,
                                getData: true,

                            })
                        })
                })
                .catch((error) => {
                    //console.log(error);
                    this.setState({
                        loading: false,
                        showValue: true,
                        getData: true,

                    });
                    if (this.props.accountDetailsButton !== true) {
                        this.props.stopLoading(error)
                    }

                });
        } else if (this.props.casaAccountNumber !== undefined && this.props.casaAccountNumber !== null) {
            let getDataByAc = backEndServerURL + "/GetCustomAcctDetailsRequest/" + this.props.casaAccountNumber;
            axios.get(getDataByAc, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let getAccountUrl = backEndServerURL + "/getAccountInfo/" + response.data.acctNumber;
                    axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((accountDataResponse) => {
                            let labelData = [];
                            if (this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account') {
                                labelData = [

                                    {
                                        name: 'Debit Account Number', value: response.data.acctNumber
                                    },
                                    {
                                        name: 'Debit Account Name', value: response.data.acctName
                                    },

                                    {
                                        name: 'Available Amount', value: response.data.acctEftvBalance
                                    },

                                    {
                                        name: 'Cash Limit (Dr)*', value: accountDataResponse.data.drclrlim
                                    },
                                    {
                                        name: 'Clearing Limit (Dr)*', value: accountDataResponse.data.crclrlim
                                    },
                                    {
                                        name: 'Transfer Limit (Dr)*', value: accountDataResponse.data.crtxlim
                                    },
                                    {
                                        name: 'E-TIN', value: this.props.tin
                                    },
                                    {
                                        name: 'Rate', value: this.props.interestRate + "%"
                                    },
                                    {
                                        name: 'Tax Waiver Applicable', value: this.props.taxWaiverChanges
                                    },
                                    {
                                        name: 'Preferential Rate Applicable', value: this.props.preventialRateChanges
                                    }

                                ];
                            } else if (this.props.accountDetailsButton !== true) {
                                labelData = [
                                    /* {
                                         name: 'CB Number', value: this.props.cbNumber
                                     },*/
                                    {
                                        name: 'Debit Account Number', value: response.data.acctNumber
                                    },
                                    {
                                        name: 'Debit Account Name', value: response.data.acctName
                                    },

                                    {
                                        name: 'Available Amount', value: response.data.acctEftvBalance
                                    },
                                    {
                                        name: 'Transaction ID/Cheque Number', value: this.props.transactionID
                                    },
                                    {
                                        name: 'Transaction Date', value: this.props.transactionDate
                                    },

                                    {
                                        name: 'Cash Limit (Dr)*', value: accountDataResponse.data.drclrlim
                                    },
                                    {
                                        name: 'Clearing Limit (Dr)*', value: accountDataResponse.data.crclrlim
                                    },
                                    {
                                        name: 'Transfer Limit (Dr)*', value: accountDataResponse.data.crtxlim
                                    },
                                    {
                                        name: 'E-TIN', value: this.props.tin
                                    },
                                    {
                                        name: 'Maturity/Disposal Instruction*', value: this.props.maturity
                                    },
                                    {
                                        name: 'Rate', value: this.props.interestRate + "%"
                                    },
                                    {
                                        name: 'Tax Waiver Applicable', value: this.props.taxWaiverChange
                                    },
                                    {
                                        name: 'Preferential Rate Applicable', value: this.props.preventialRateChange
                                    }
                                ];
                            }


                            if (response.status === 200) {
                                if (response.data.relPartyList && response.data.relPartyList.length > 0 && this.props.accountDetailsButton !== true) {
                                    labelData.push({
                                        name: 'Secondary CB',
                                        value: response.data.relPartyList,
                                        popup: true,
                                        popupValue: 'linkedCB'
                                    },);
                                }
                                if (response.data.solId && this.props.accountDetailsButton !== true) {
                                    labelData.push({name: 'SOL Id', value: response.data.solId})
                                }
                                if (response.data.lienDetails && response.data.lienDetails.length > 0) {
                                    labelData.push({name: 'Lien', value: "", popup: true, popupValue: 'Lien'})//
                                }
                                if (response.data.LinkedTDDetails && response.data.LinkedTDDetails.length > 0 && this.props.accountDetailsButton !== true) {
                                    labelData.push({name: "FDR/DPS", value: "", popup: true, popupValue: 'FDR'})
                                }
                                if (response.data.linkedLoanDetails && response.data.linkedLoanDetails.length > 0 && this.props.accountDetailsButton !== true) {
                                    labelData.push({name: 'Loan Account', value: "", popup: true, popupValue: 'Loan'})
                                }
                                if (response.data.freezeCode || response.data.freezeReasonCode) {
                                    labelData.push({
                                        name: 'Freeze status',
                                        value: "",
                                        popup: true,
                                        popupValue: 'Freeze'
                                    })//
                                }
                                if (response.data.acctStatus === "D") {
                                    labelData.push({name: 'Dormancy', value: "", popup: true, popupValue: 'Dormancy'})//
                                }
                                if (response.data.acctStatus && this.props.accountDetailsButton !== true) {
                                    labelData.push({
                                        name: 'Customer Status',
                                        value: "",
                                        popup: true,
                                        popupValue: 'ADUP'
                                    })
                                }
                                if (response.data.freeText12 && this.props.accountDetailsButton !== true) {
                                    labelData.push({name: 'CTR', value: "", popup: true, popupValue: 'CTR'})
                                }
                                setTimeout(() => {
                                    this.setState({
                                        labelData: labelData,
                                        inputValue: response.data,
                                        showValue: true,
                                        loading: false,
                                        getData: true,
                                    }, this.parentLoad);
                                }, 1000)
                            }
                        })
                        .catch((error) => {
                            //console.log(error);
                            this.setState({
                                loading: false,
                                showValue: true,
                                getData: true,

                            })
                        })
                })
                .catch((error) => {
                    //console.log(error);
                    this.setState({
                        loading: false,
                        showValue: true,
                        getData: true,

                    });
                    if (this.props.accountDetailsButton !== true) {
                        this.props.stopLoading(error)
                    }

                });
        } else {

            let labelData = [];
            if (this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account') {
                labelData = [

                    {
                        name: 'Debit Account Number', value: "",
                    },
                    {
                        name: 'Debit Account Name', value: "",
                    },

                    {
                        name: 'Available Amount', value: this.props.amount
                    },

                    {
                        name: 'Cash Limit (Dr)*', value: ""
                    },
                    {
                        name: 'Clearing Limit (Dr)*', value: ""
                    },
                    {
                        name: 'Transfer Limit (Dr)*', value: ""
                    },
                    {
                        name: 'E-TIN', value: this.props.tin
                    },
                    {
                        name: 'Rate', value: this.props.interestRate + "%"
                    }
                    ,
                    {
                        name: 'Tax Waiver Applicable', value: this.props.taxWaiverChange
                    },
                    {
                        name: 'Preferential Rate Applicable', value: this.props.preventialRateChange
                    }
                ];
            } else if (this.props.accountDetailsButton !== true) {
                labelData = [
                    /* {
                         name: 'CB Number', value: this.props.cbNumber
                     },*/
                    {
                        name: 'Debit Account Number', value: "",
                    },
                    {
                        name: 'Debit Account Name', value: "",
                    },

                    {
                        name: 'Available Amount', value: this.props.amount
                    },
                    {
                        name: 'Transaction ID/Cheque Number', value: this.props.transactionID
                    },
                    {
                        name: 'Transaction Date', value: this.props.transactionDate
                    },

                    {
                        name: 'Cash Limit (Dr)*', value: ""
                    },
                    {
                        name: 'Clearing Limit (Dr)*', value: ""
                    },
                    {
                        name: 'Transfer Limit (Dr)*', value: ""
                    },
                    {
                        name: 'E-TIN', value: this.props.tin
                    },
                    {
                        name: 'Maturity/Disposal Instruction*', value: this.props.maturity
                    },
                    {
                        name: 'Rate', value: this.props.interestRate + "%"
                    },
                    {
                        name: 'Tax Waiver Applicable', value: this.props.taxWaiverChange
                    },
                    {
                        name: 'Preferential Rate Applicable', value: this.props.preventialRateChange
                    }
                ];
            }


            setTimeout(() => {
                this.setState({
                    labelData: labelData,
                    showValue: true,
                    loading: false,
                    getData: true,
                }, this.parentLoad);
            }, 1000)
        }

    }


    parentLoad = () => {
        if (this.props.stopLoading !== undefined && this.props.accountDetailsButton !== true) {
            this.props.stopLoading("yes")
        }
    };

    labelDetails = (status, name) => {
        this.setState({
            labelName: status,
            name: name,
            labelOpen: true
        })
    };

    labelCloseModal = () => {
        this.setState({
            labelOpen: false
        })
    };

    render() {
        const {classes} = this.props.classes;

        if (this.state.labelData.length > 0 && this.state.getData) {
            return (
                <center>
                    <div style={{width: '100%'}}>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="flex-start"
                             bgcolor="background.paper"
                             style={{marginTop: "10px", borderTop: "2px solid #000", borderBottom: "2px solid #000"}}>
                            {
                                this.state.labelData.map((label) => {
                                    return (
                                        <Box p={1} bgcolor="background.paper">
                                            <label><font size="2"><b
                                                style={{color: label.popupValue ? "red" : "black"}}>
                                                {label.popupValue ? <a onClick={(event) => {
                                                    this.labelDetails(label.popupValue, label.name)
                                                }}>{label.name}</a> : label.name} {label.popupValue ? "" : ": "}
                                            </b></font></label>
                                            {label.popupValue ? "" : label.value}
                                        </Box>

                                    )
                                })
                            }
                        </Box>
                        <Dialog
                            fullWidth="true"
                            maxWidth="md"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.labelOpen}>
                            <DialogContent className={classes.dialogPaper}>
                                <LabelDetails name={this.state.name} closeModal={this.labelCloseModal}
                                              appId={this.state.appId} labelName={this.state.labelName}
                                              data={this.state.labelData} inputValue={this.state.inputValue}
                                              accountNumber={this.props.accountNumber} crmStatus={this.props.crmStatus}
                                              cbNumber={this.props.cbNumber} create={this.props.create}/>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>

                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                    </div>
                </center>

            );
        } else {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        }
    }


}

export default AccountDetails;