import React, {Component} from "react";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../Common/Constant";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import CBNotFound from "../workflow/CASA/CBNotFound";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Notification from "../NotificationMessage/Notification";
import withStyles from "@material-ui/core/styles/withStyles";
import loader from "../../Static/loader.gif";
import axiosDownload from "../DeliverableManagement/FunctionForFileDownload";
import MyValidation from "../JsonForm/MyValidation";
import axios from "axios";
import Reports from "../Greendelta/Reports.js";
let workplaceUnits=[];
let searchFormOne = [


];


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class GreenDeltaRepost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: true,
            selectedDate: {},
            err: false,
            errorArray: {},
            errorMessages: {},
            cbNotFound: false,
            alert: false,
            title: "",
            notificationMessage: "",
            loading: false,
            currentDate: 0,
            SelectedDropdownSearchData: null,
            autoComplete: {},
            reportType: this.props.match.params.reportType,
            userObject: {},
            getData: false,
            dropdownSearchData: {},
        };
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
        })
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    handleChangBO = () => {
       this.setState({
           getWorkplaceUnits:false,
           loading:true,
       })
        let Url = backEndServerURL + "/workplaceUnit/getByBranchOrOps/BRANCH";

            axios.get(Url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    let autoComplete = {};
                    response.data.map((data) => {
                        workplaceUnits.push({
                            title: data.value,
                            key: data.key,
                        })
                    });
                      searchFormOne = [
                        {
                            varName: "fromDate",
                            type: "date",
                            label: "From Date",
                            grid: 2,
                            required: true,
                            maxDate: true
                        },
                        {
                            varName: "toDate",
                            type: "date",
                            label: "To Date",
                            grid: 2,
                            required: true,
                            maxDate: true
                        } ,
                          {
                            varName: "workplaceUnit",
                              "type": "autoCompleteValueReturn",
                            enum:response.data,
                            label: "Branch",
                            grid: 2,
                            required: true,
                         },
                          {
                            varName: "reportName",
                              "type": "select",
                            enum:[
                                "AVAILABLE STOCK",
                                "SOLD",
                            ],
                            label: "Report Type",
                            grid: 2,
                            required: true,
                         },
                        ]
                    autoComplete.workPlaceUtil = workplaceUnits;

                    this.setState({
                        workPlaceUnitList: workplaceUnits,
                        autoComplete: {...this.state.autoComplete, ...autoComplete},
                        getWorkplaceUnits: true,
                        loading:false,
                    });
                    console.log(response.data)
                })
                .catch((error) => {
                    this.setState({
                        getWorkplaceUnits: true,
                        loading:false,
                    });
                });




    };

    componentDidMount() {
        this.fetchTableData(this.getApiUrl(), 0)
        let defaultDate = new Date();
        let isoDate = defaultDate.toISOString();
        let currentDate = isoDate.substr(0, 10);
        this.handleChangBO();
        this.setState({
            currentDate: currentDate
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.reportType !== prevProps.match.params.reportType) {
            this.setState({
                inputData: {},
                varValue: {},
                err: false,
                errorArray: {},
                errorMessages: {},
                reportType: this.props.match.params.reportType,
            })
        }
    }

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "-1" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    downloadReport = () => {
        let jsonFom =   searchFormOne  ;
        let error = MyValidation.defaultValidation(this.copyJson(jsonFom), this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true
            });
            let download_url = this.state.reportType === "branch" ? backEndServerURL + "/getGreenDeltaReport" : backEndServerURL + "/getGreenDeltaReport";
            axiosDownload.downloadFile(download_url, 'POST', this.state.inputData, `${this.state.currentDate-this.state.inputData.reportName}-green-delta-report.xlsx`).then((result) => {
                if (result) {
                    this.setState({
                        loading: false
                    });
                    if (result === "File Not Found") {
                        this.setState({
                            cbNotFound: true,
                            title: "No Data Found!"
                        })
                    }
                }
            }).catch((error) => {
                this.setState({
                    loading: false
                });
            })
        }
    };


    renderLoader = () => {
        if (this.state.loading) {
            const {classes} = this.props;
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>


            )
        }
    };

    renderJsonForm = () => {
        let jsonForm = searchFormOne  ;
        return CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent)
    };

    // table date render start
    getApiUrl = () => {
        let url = "";
        const data = [];
        url = 'https://mocki.io/v1/8b3e49c8-b556-4769-a42e-19405054b496';
        this.setState({
            activeItem: 'ACTIVE',
        });
        return url;
    };
    
     fetchTableData = (url, page) => {
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(response => {
            let tableData = [];
            response.data.map((userData, index) => {
                tableData.push({                     
                branch : userData.branch,
      	 	movementType: userData.movementType,        
         	documentType: userData.documentType,
        	sender: userData.sender,
         	receiver: userData.receiver,
         	details: userData.details,
         	currentStatus: userData.currentStatus,       
 		date: userData.date, 
                   // action:this.disableButton(message.anotherRolePermission,message.username)
                });
            });
            console.log("tableData")
            console.log(tableData)
            this.setState({
                //  page: response.data.number,
                //  totalRow: response.data.totalElements,
                tableData: tableData,
                getData: true,
                showTable: true,
                loading: false
            })

        }).catch(error => {
            console.log(error);
            this.setState({
                loading: false,
                showTable: true,
                searchData: false,
            })
        });
    };
    
    
     renderTableColumn=()=>{
        const columns = [
     
            { title: 'Branch', field: 'branch'},
            { title: 'Movement Type', field: 'movementType'},
            { title: 'Date', field: 'date'},   
            {title: 'Document Type',  field: 'documentType'},
            { title: 'Sender', field: 'sender' },
            { title: 'Receiver', field: 'receiver'},
            { title: 'Details', field: 'details'},
            { title: 'Current status', field: 'currentStatus' },
          ];
          return columns
    }
    
    // table data render end

    render() {
        const {classes} = this.props;

        return (
            <React.Fragment>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >
                            Avavilable Stock/ Sold Report(Download)
                        </h4>
                    </CardHeader>
                    <CardBody>
                        {
                            this.renderLoader()
                        }
                        <Grid item xs={12}>
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {this.renderJsonForm()}
                                    <Grid item xs={4} style={{marginTop: "18px"}}>
                                        <button
                                            className="btn btn-outline-primary btn-sm"
                                            onClick={this.downloadReport}
                                        >
                                            Download Report
                                        </button>
                                    </Grid>
                                </ThemeProvider>
                            </Grid>
                        </Grid>

                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.cbNotFound}>
                            <DialogContent className={classes.dialogPaper}>
                                <CBNotFound
                                    closeModal={this.closeModalCBNotFound}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                    </CardBody>
                    <Reports tableData={this.state.tableData} 
                       tableColumns={this.renderTableColumn()}
                                /*onRowClick={this.onRowClick}*/
                                // loader={this.state.loading} maxBodyHeight="450px"
                               // totalRow={this.state.totalRow} page={this.state.page} whiteSpace={"wrap"}
                                //handleChangePage={this.handleChangePage} rowsPerPage={this.state.rowsPerPage}
                                />  
                </Card>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(GreenDeltaRepost);
