import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import {Redirect} from "react-router-dom";
import '../../Static/css/login.css';
import FormSample from "../JsonForm/FormSample";
import {backEndServerURL} from "../../Common/Constant";
import Notification from "../NotificationMessage/Notification";
import axios from 'axios';
import Functions from '../../Common/Functions';

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        background: '#f6f1ff'
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 400,
    },
});


const jsonForm = {
    "variables": [


        {
            "varName": "userSearch",
            "type": "dropdownSearch",
            "label": "User Search",

        },
        {
            "varName": "selectSdDepartment",
            "type": "dropdownSearch",
            "label": "Select Sd Department",


        },
        {
            "varName": "selectGroup",
            "type": "select",
            "label": "Select Group",

            "enum": [
                "CHECKER",
                "MAKER"
            ]

        },
        {
            "varName": "region",
            "type": "select",
            "label": "Region",

            "enum": [
                "DHAKA",
                "CHITTAGONG",

            ]

        },


    ],

};

class SdProcessFlowMasterAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            existGroup: false,
            dropdownSearchData: [],
            title: "",
            notificationMessage: "",

            alert: false,
            redirectLogin: false,

        }

    }

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }

    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    getSubmitedForm = (object) => {

        //backend api call
        let url = backEndServerURL + "/sdProcessFlowMaster/add";

        axios.post(url, {

            "sdGlobalGroupName": object.selectGroup,
            "userUid": object.userSearch[0],
            "userName": object.userSearch[1],
            "sdLocalGroupId": "SD_DEPARTMENT_" + object.selectSdDepartment[0] + "_" + object.selectGroup,
            "departmentId": object.selectSdDepartment[0],
            "departmentName": object.selectSdDepartment[1],
            "region": object.region,
            "status": "ACTIVE"
        }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log("Succesffully added");
                this.props.close();
                this.setState({
                    title: "Sucessfully!!",
                    notificationMessage: "Sucessfully Added",
                    alert: true
                })
            })
            .catch((error) => {
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
                console.log("Not assign,already exist");
            })


        /*/!*   //Existing All Group
           let urlExistGroup = backEndServerURL + '/api/1.0/workflow/groups';
           let authHeaderExistGroup = "Bearer " + cookie.load("accessToken");

           axios.get(urlExistGroup,
               {headers: {"Authorization": authHeaderExistGroup}})
               .then((response) => {


                   let filteredData = response.data.filter(function (group) {
                       return (group.grp_title === "SD_DEPARTMENT_" + object.selectSdDepartment + "_" + object.selectGroup);
                   });
                   let groupUid;
                   filteredData.map((g)=>{
                       groupUid=g.grp_uid;
                   })

                   if (filteredData.length !== 0) {

                       let urls = backEndServerURL + '/api/1.0/workflow/group/' + groupUid+ '/user';
                       let authHeader = "Bearer " + cookie.load("accessToken");

                       axios.post(urls, {

                               "usr_uid": object.userSearch
                           },
                           {headers: {"Authorization": authHeader}})
                           .then((response) => {
                               this.setState({
                                   existGroup: true
                               })
                               //console.log("successfully assign user to group");


                           })
                           .catch((error) => {
                               console.log(error);
                           })

                   }




                   //Create new group
                   else if (filteredData.length === 0) {

                       let urls = backEndServerURL + '/api/1.0/workflow/groups';
                       let authHeader = "Bearer " + cookie.load("accessToken");

                       axios.post(urls, {
                               "grp_title": "SD_DEPARTMENT_" + object.selectSdDepartment + "_" + object.selectGroup,
                               "grp_status": "ACTIVE"
                           },
                           {headers: {"Authorization": authHeader}})
                           .then((response) => {
                               //assign user to group

                               let groupUid = response.data.grp_uid;
                               let urls = backEndServerURL + '/api/1.0/workflow/group/' + groupUid + '/user';
                               let authHeader = "Bearer " + cookie.load("accessToken");

                               axios.post(urls, {

                                       "usr_uid": object.userSearch
                                   },
                                   {headers: {"Authorization": authHeader}})
                                   .then((response) => {
                                       //console.log("successfully assign user to group");


                                   })
                                   .catch((error) => {
                                       console.log(error);
                                   })


                               console.log("successfully group created");
                               //assign user to group

                           })

                   }*!/ else {

                   }


               })


           //Assign user to CHECKER,MAKER group
           let GroupUid;
           if (object.selectGroup === "CHECKER") {
               GroupUid = "5499543905c8756599bb843052896201";
           } else if (object.selectGroup === "MAKER") {
               GroupUid = "7596220185c87564dc9b6c4037903224";
           }
           else{

           }
           let urls = backEndServerURL + '/api/1.0/workflow/group/' + GroupUid + '/user';
           let authHeader = "Bearer " + cookie.load("accessToken");

           axios.post(urls, {

                   "usr_uid": object.userSearch
               },
               {headers: {"Authorization": authHeader}})
               .then((response) => {
                   //console.log("successfully assign user to group");


               })
               .catch((error) => {
                   console.log("Not assign,,already exist");
               })

   */
    };

    handlesubmit = () => {
        this.setState({
            redirect: true
        });

    };
    renderRedirect = () => {
        if (this.state.redirect) {
            return (<Redirect to="/newaccountsearchResult"/>);
        }

    };

    componentDidMount() {
        const getDepartment = [];
        let dropdownData = [];
        let url = backEndServerURL + "/sdDepartmentMaster/getAll";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let data = [];

                response.data.map((department) => {
                    getDepartment.push(department.id, department.departmentName);

                    let object = {
                        "label": department.departmentName,
                        "value": [department.id, department.departmentName]
                    };
                    data.push(object);

                });


                dropdownData["selectSdDepartment"] = data;


            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
            });


        let urls = backEndServerURL + '/user/get/all';


        axios.get(urls,
            {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                let useData = [];
                response.data.map((user) => {
                    let object1 = {
                        "label": user.username,
                        "value": [user.id, user.username]
                    };
                    useData.push(object1);
                });


                dropdownData["userSearch"] = useData;

                this.setState({
                    dropdownSearchData: dropdownData
                })
            })
            .catch((error) => {
                console.log(error);
                /*if(error.response.status===452){
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin:true
                    })

                }*/
                this.setState({errorMessage: "Not Found"});
            })
    }

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (

            <GridContainer>
                {
                    this.renderRedirect()


                }
                {
                    this.renderNotification()


                }

                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        {/*<CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>SD Process Flow</h4>
                        </CardHeader>*/}
                        <CardBody>

                            <div className={classes.root}>
                                <FormSample grid="12"
                                            buttonName="Submit"
                                            onSubmit={this.getSubmitedForm}
                                            dropdownSearchData={this.state.dropdownSearchData} jsonForm={jsonForm}/>
                            </div>


                        </CardBody>
                    </Card>
                </GridItem>

            </GridContainer>

        );
    }
}

export default withStyles(styles)(SdProcessFlowMasterAdd);
