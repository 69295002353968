import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import Functions from '../../Common/Functions';
import theme from "../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import Notification from "../NotificationMessage/Notification";
import loading from "../../Static/loader.gif";
import CBNotFound from "./CASA/CBNotFound";
import TableComponent from "./CommonComponent/TableComponent";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import {Menu} from "semantic-ui-react";
import {commonInboxButton, commonInboxTabActive, commonInboxTabInactive} from "../DeliverableManagement/configuration";
import Badge from "@material-ui/core/Badge";
import {
    rows,
    rowsBranch,
    rowsCS,
    rowsCSInbox,
    rowsCSWithReturned, rowsMerchantInbox,
    rowsWithGroupInbox,
    rowsWithReturned
} from "./ColumnNameForInbox"
import SubmittedCaseHistory from "./CommonComponent/SubmittedCaseHistory";
import CSInboxCase from "./CSInboxCase";
import BMInboxCase from "./BMInboxCase";
import BOMInboxCase from "./BOMInboxCase";
import MakerInboxCase from "./MakerInboxCase";
import CheckerInboxCase from "./CheckerInboxCase";
import MerchantInboxCase from "./Merchant/MerchantInboxCase";
import AbhApproval from "./AGENT/AbhApproval";
import ABHCheckerInbox from "./AGENT/ABHCheckerInbox";
import AgentBanking from "./AGENT/AgentBanking";
 import MerchantMaintenance from "./CardCallCategory/MerchantMaintenance";
import CallCenterApproval from "./Merchant/CallCenterApproval";
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";

const filterField = [
    {
        "varName": "caseId",
        "type": "textDedup",
        "label": "LWF Case Number",
        "grid": 2,
    },
    {
        "varName": "referenceNumber",
        "type": "text",
        "label": "Reference Number",
        "grid": 2,
    },
    {
        "varName": "category",
        "type": "select",
        "enum": [
            "CASA",
            "FDR",
            "DPS",
            "Mandate",
            "Chequebook",
            "RTGS",
            "BEFTN",
            "Salary",
            "Debit Card Issuance",
            "BOND",
            "SANCHAYPATRA",
            "Debit Card",
            "Credit Card",
            "CARDS",
            "ADC",
            "Internet Banking",
            "Remittance",
            "Pay Order and FDD Bulk",
            "Locker",
            "Delivarable",
            "Deferral",
            "Merchant",
        ],
        "label": "Category",
        "grid": 2,
    },
    {
        "varName": "subCategory",
        "type": "select",
        "enum": [
            "A/C Opening",
            "Opening",
            "Schem Account opeing",
            "TD Open/FDR",
            "Maintenance",
            "Dormant Activation",
            "Mandate Update",
            // "Customer Information Update",
            // "Dormant Activation & Maintenance",
            // "Mandate/Related Party Maintenance",
            "Single Requisition",
            "Opening",
            "Service",
            "Single Transaction",
            "Bulk Transaction",
            "Requisition",
            "Chequebook",
            "Bulk Insta Debit Card",
            "Bulk Requisition",
            "CASA Closer",
            "FDR Closer",
            "FDR Maintenace",
            "CARD Against FDR/ DPS",
            "Tracker",
            "Instant Card",
            "General Card Requisition",
            "CARD Cheque Requisition",
            "City Touch Enrollment",
            "SMS Discountinue",
            "Outward",
            "Inward",
            "Student File",
            "Medical File",
            "Searcher",
            "Others",
            "Pay Order Bulk Requisition",
            "FDD Bulk Requisition",
            "Others",
            "Requistion",
            "Surrender",
            "Locker Maintenace",
            "Reconcilation Branch",
            "Insurance Upload",
            "Insta Pack Requisition",
            "Insta Card Requisition",
            "Insta Pack Tracker",
            "Insta Card Tracker",
            "WL Priority",
            "WL Non Priority",
            "CASA Defferal",
            "FD Deferal",
            "Others",
            "FDR",
            "Encashment",
            "ISSUANCE - Wage Earners Development Bond",
            "ISSUANCE - U.S. Dollar Investment Bond",
            "ISSUANCE - U.S. Dollar Premium Bond",
            "ISSUANCE - 5 Years Bangladesh Sanchaya Patra",
            "ISSUANCE - 3 Months Interest Based Sanchaya Patra",
            "ISSUANCE - Family Sanchaya Patra",
            "ISSUANCE - Pensioners Savings Certificate",
            "ENCASHMENT - Wage Earners Development Bond - ENCASHMENT PAYMENT",
            "ENCASHMENT - Wage Earners Development Bond - INTEREST PAYMENT",
            "ENCASHMENT - U.S. Dollar Investment Bond - ENCASHMENT PAYMENT",
            "ENCASHMENT - U.S. Dollar Investment Bond - INTEREST PAYMENT",
            "ENCASHMENT - U.S. Dollar Premium Bond - ENCASHMENT PAYMENT",
            "ENCASHMENT - U.S. Dollar Premium Bond - INTEREST PAYMENT",
            "ENCASHMENT - 5 Years Bangladesh Sanchaya Patra - ENCASHMENT PAYMENT",
            "ENCASHMENT - 5 Years Bangladesh Sanchaya Patra - INTEREST PAYMENT",
            "ENCASHMENT - 3 Months Interest Based Sanchaya Patra - ENCASHMENT PAYMENT",
            "ENCASHMENT - 3 Months Interest Based Sanchaya Patra - INTEREST PAYMENT",
            "ENCASHMENT - Family Sanchaya Patra - ENCASHMENT PAYMENT",
            "ENCASHMENT - Family Sanchaya Patra - INTEREST PAYMENT",
            "ENCASHMENT - Pensioners Savings Certificate - ENCASHMENT PAYMENT",
            "ENCASHMENT - Pensioners Savings Certificate - INTEREST PAYMENT",
            "MAINTENANCE - Wage Earners Development Bond - NOMINEE CHANG",
            "MAINTENANCE - Wage Earners Development Bond - ACES ENROLLMENT",
            "MAINTENANCE - Wage Earners Development Bond - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - Wage Earners Development Bond - DUPLICATE ISSUANCE",
            "MAINTENANCE - Wage Earners Development Bond - CERTIFICATE",
            "MAINTENANCE - U.S. Dollar Investment Bond - NOMINEE CHANG",
            "MAINTENANCE - U.S. Dollar Investment Bond - ACES ENROLLMENT",
            "MAINTENANCE - U.S. Dollar Investment Bond - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - U.S. Dollar Investment Bond - DUPLICATE ISSUANCE",
            "MAINTENANCE - U.S. Dollar Investment Bond - CERTIFICATE",
            "MAINTENANCE - U.S. Dollar Premium Bond - NOMINEE CHANG",
            "MAINTENANCE - U.S. Dollar Premium Bond - ACES ENROLLMENT",
            "MAINTENANCE - U.S. Dollar Premium Bond - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - U.S. Dollar Premium Bond - DUPLICATE ISSUANCE",
            "MAINTENANCE - U.S. Dollar Premium Bond - CERTIFICATE",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - NOMINEE CHANG",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - ACES ENROLLMENT",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - DUPLICATE ISSUANCE",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - CERTIFICATE",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - NOMINEE CHANG",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - ACES ENROLLMENT",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - DUPLICATE ISSUANCE",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - CERTIFICATE",
            "MAINTENANCE - Family Sanchaya Patra - NOMINEE CHANG",
            "MAINTENANCE - Family Sanchaya Patra - ACES ENROLLMENT",
            "MAINTENANCE - Family Sanchaya Patra - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - Family Sanchaya Patra - DUPLICATE ISSUANCE",
            "MAINTENANCE - Family Sanchaya Patra - CERTIFICATE",
            "MAINTENANCE - Pensioners Savings Certificate - NOMINEE CHANG",
            "MAINTENANCE - Pensioners Savings Certificate - ACES ENROLLMENT",
            "MAINTENANCE - Pensioners Savings Certificate - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - Pensioners Savings Certificate - DUPLICATE ISSUANCE",
            "MAINTENANCE - Pensioners Savings Certificate - CERTIFICATE",
        ],
        "label": "Sub Category",
        "grid": 2,
    },
    {
        "varName": "branch_id",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Branch",
        "grid": 2,
    },
    {
        "varName": "cbNumber",
        "type": "textDedup",
        "label": "CB Number",
        "grid": 2,
    },
    {
        "varName": "accountNumber",
        "type": "textDedup",
        "label": "Account No.",
        "grid": 2,
    },
    {
        "varName": "customerName",
        "type": "textDedup",
        "label": "Customer name",
        "grid": 2,
    },
    {
        "varName": "fromDate",
        "type": "date",
        "label": "From Date",
        "grid": 2,
    },
    {
        "varName": "toDate",
        "type": "date",
        "label": "To Date",
        "grid": 2,
    },
    {
        "varName": "urgency",
        "type": "select",
        "enum": [
            "High",
            "Medium",
            "Low",
        ],
        "label": "Urgency",
        "grid": 2,
    },
    {
        "varName": "cutOffTime",
        "type": "select",
        "enum": [
            "Before",
            "After",
        ],
        "label": "Cut Off Time",
        "grid": 2,
    },
];


const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

class MerchantInbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            loading: true,
            cbNotFound: false,
            getData: false,
            varValue: {},
            title: "",
            notificationMessage: "",
            alert: false,
            selectedDate: {},
            showValue: false,
            openHierarchy: false,
            err: false,
            searchData: false,
            activeItem: "Group Inbox",
            showTable: false,
            errorArray: {},
            errorMessages: {},
            tableData: [],
            totalRow: 0,
            rowsPerPage: 50,
            tableColumns: [],
            page: 0,
            showInbox: false,
            serviceType: "",
            subServiceType: "",
            category: "",
            subCategory: "",
            solId: "",
            appUid: "",
            delStatus: "",
            taskTitle: "",
            status: "",
            freeFlag1: "",
            freeFlag2: "",
            freeFlag3: "",
            freeFlag4: "",
            recpmtid: "",
            cb_number: "",
            account_number: "",
            customer_name: "",
            CaseId: "",
            urgency: "",
            sentByUsername: "",
            jointAccountCustomerNumber: "",
            getCountPending: 0,
            getCountReturn: 0,
            getCountInbox: 0,
            getCountRectified: 0,
            getCountGroupInbox: 0,
            getCountDormant: 0,
            getCountDormantRectified: 0,
            getCountForCasaOpen: 0,
            getCountForCasaOpenRectified: 0,
            getCountMaintenance: 0,
            getCountMaintenanceRectified: 0,
            getRemittanceGroupCount: 0,
            getRemittanceRectifiedCount: 0,
            getRemittanceGroupCountForBranch: 0
        }
    }


    renderTableColumn = () => {
        let tableColumn = [];
        if (this.state.activeItem === "Return" && (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CS" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "DSTCS")) {
            tableColumn = [...rowsMerchantInbox]
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CS" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "DSTCS") {
            tableColumn = [...rowsMerchantInbox]
        } else if (this.state.activeItem === "Inbox") {
            tableColumn = [...rowsMerchantInbox]
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") !== "CS" && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") !== "DSTCS" && LocalstorageEncrypt.encryptStorageFunction.getItem('workplace') === "BRANCH") {
            tableColumn = [...rowsMerchantInbox]
        } else if (this.state.activeItem === "Return") {
            tableColumn = [...rowsMerchantInbox]
        } else if (this.state.activeItem === "Group Inbox" || this.state.activeItem === "Remittance Group Inbox SD" || this.state.activeItem === "RECTIFIED REQUEST" || this.state.activeItem === "Maintenance Rectified Request" || this.state.activeItem === "Dormant Activation Rectified Request" || this.state.activeItem === "Account Opening Rectified Request") {
            tableColumn = [...rowsMerchantInbox]
        } else {
            tableColumn = [...rowsMerchantInbox]
        }
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MAKER" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CHECKER") {
            tableColumn.push({field: 'makerId', title: 'Maker ID'},)
        }
        return tableColumn;
    };


    getApiUrl = (value) => {
        let url;
        this.setState({
            activeItem: "Group Inbox"
        });
        let workFlowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        let workFlowPlace = LocalstorageEncrypt.encryptStorageFunction.getItem("workplace");
        if (workFlowPlace === "MERCHANT_DST" || workFlowGroup === "HOMBU" || workFlowGroup === "S_AND_A" || workFlowGroup === "MBU_DFF") {


        }
        let page=0;
        let urls = "";
        if (workFlowGroup === "CS" || workFlowGroup === "MERCHANT_DST" || workFlowGroup==="MBU" || workFlowGroup==="FRM" || workFlowGroup==="FRM_CHECKER" || workFlowGroup==="FRM_APPROVAL" || workFlowGroup==="MERCHANT_CALL_CENTER") {
            // url = backEndServerURL + '/inbox/return/0';
            urls = backEndServerURL + '/csGroupWiseReturnRequest/' + page;

        }

        if (value === "Inbox") {
            if(workFlowGroup==="CALL_CENTER_MAKER" || workFlowGroup==="CALL_CENTER_CHECKER" || workFlowGroup==="CARD_DIVISION") {
                urls = backEndServerURL + '/inbox/waiting/OnlyMerchant/' + page;
            }
            else {
                urls = backEndServerURL + '/inbox/waiting/' + page;
            }
        } else if (value === "Return") {
            if(workFlowGroup==="CALL_CENTER_MAKER" || workFlowGroup==="CALL_CENTER_CHECKER" || workFlowGroup==="CARD_DIVISION") {
                urls = backEndServerURL + '/csGroupWiseReturnRequest/OnlyMerchant/' + page;
                this.setState({
                    activeItem: 'Return',
                })
            }
            else {
                urls = backEndServerURL + '/csGroupWiseReturnRequest/' + page;
                this.setState({
                    activeItem: 'Return',
                })
            }
        } else if (value === "Pending") {
            urls = backEndServerURL + '/inbox/saved/' + page;
        } else if (value === "Submit") {
            urls = backEndServerURL + '/perticipated/' + page;
        } else if (value === "Certificate") {
            urls = backEndServerURL + '/inbox/closed/ENROLLMENT/' + page;
        } else if (value === "Not Reach") {
            urls = backEndServerURL + '/inboxTabClaimable/CALL_CENTER_NOT_REACHED/' + page;
        } else if (value === "Complete") {
            urls = backEndServerURL + '/perticipatedClosed/' + page;
        } else if (value === "Group Inbox") {
            if(LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup")==="CALL_CENTER_MAKER" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup")==="CARD_DIVISION" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup")==="CALL_CENTER_CHECKER"){
                urls = backEndServerURL + '/workspaceClaimable/OnlyMerchant/' + page;
            }
            else {
                urls = backEndServerURL + '/workspaceClaimable/' + page;
            }
        } else if (value === "Checker Approved") {
            urls = backEndServerURL + '/inboxTabClaimable/APPROVED_BY_CHECKER/' + page;
        } else if (value === "Send to Vendor") {
            urls = backEndServerURL + '/inboxTabClaimable/SEND_TO_VENDOR/' + page;
        } else if (value === "Get Pass") {
            urls = backEndServerURL + '/inboxTabClaimable/GET_PASS/' + page;
        } else if (value === "Delivered") {
            urls = backEndServerURL + '/inboxTabClaimable/DELIVERED/' + page;
        } else if (value === "Undelivered") {
            urls = backEndServerURL + '/inboxTabClaimable/UNDELIVERED/' + page;
        } else if (value === "Deferral") {
            urls = backEndServerURL + '/deferral/CSEnd/' + page;
        } else if (value === "Deferral Close") {
            urls = backEndServerURL + '/deferral/closable/' + page;
        }

        return urls;
    };

    functionForCategoryCount = () => {
        let countUrl = backEndServerURL + "/inbox/getCountCategoryWise";
        axios.get(countUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let getData = response.data;
                this.setState({
                    getCountInbox: getData.inbox,
                    getCountReturn: getData.return,
                    getCountRectified: getData.rectified,
                    getCountPending: getData.draft,
                    getCountGroupInbox: getData.teamGroup,
                    getCountDormantRectified: getData.DORMANT_ACTIVATION_SD_RECTIFIED_COUNT,
                    getCountDormant: getData.DORMANT_ACTIVATION_SD_GROUP_COUNT,
                    getCountForCasaOpen: getData.CASA_OPENING_GROUP_COUNT,
                    getCountForCasaOpenRectified: getData.CASA_OPENING_RECTIFIED_COUNT,
                    getCountMaintenance: getData.MAINTENANCE_NOT_DORMANT_GROUP_COUNT,
                    getCountMaintenanceRectified: getData.MAINTENANCE_NOT_DORMANT_RECTIFIED_COUNT,
                    getRemittanceGroupCountForBranch: getData.remittanceBranch,
                    getRemittanceGroupCount: getData.ONLY_REMITTANCE_GROUP_COUNT,
                    getRemittanceRectifiedCount: getData.ONLY_REMITTANCE_RECTIFIED_COUNT
                });
                LocalstorageEncrypt.encryptStorageFunction.setItem("inboxNumberOfCount", Number(getData.inbox) + Number(getData.return) + Number(getData.draft));
            })
            .catch((error) => {
                console.log(error);
            });
    };


    componentDidMount() {
        let branch = [];
        let branchUrl = backEndServerURL + "/workplaceUnit/getInitialUnits";
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((item) => {
                    branch.push({key: item.solId, value: item.name})
                });
                for (let form of filterField) {
                    if (form.type === "autoCompleteValueReturn" && form.varName === "branch_id") {
                        form.enum = branch
                    }
                }
                // this.functionForCategoryCount();
                this.fetchTableData(this.getApiUrl("Group Inbox"), 0)

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    getData: true,
                    showTable: true,
                    loading: false,
                });
            })
    }


    fetchTableData = (url, page) => {
        // this.functionForCategoryCount();
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(response => {
            let tableData = [];
            response.data.content.map((message, index) => {
                tableData.push({
                    SL: page > 0 ? ((index + 1) + (100 * page)) : index + 1,
                    CaseId: message.caseId,
                    customer_name: message.customerName,
                    cb_number: message.cbNumber,
                    account_number: message.accountNumber,
                    businessSegment: message.businessSegment,
                    category_type: this.returnCategory(message.branchingCategory, message.freeFlag5, message.category),
                    sub_category_type: this.returnSubCategory(message.branchingSubCategory, message.freeFlag6, message.subCategory),
                    Service: message.callCategory,
                    Date: message.delInitTime.replace("T", " "),
                    currentUserName: message.currentUserName,
                    branch_name: message.branchName,
                    Status: message.delStatus,
                    freeFlag1: message.freeFlag1,
                    freeFlag2: message.freeFlag2,
                    freeFlag3: message.freeFlag3,
                    freeFlag4: message.freeFlag4,
                    recpmtid: message.recpmtid,
                    freeFlag5: message.freeFlag5,
                    freeFlag6: message.freeFlag6,
                    appId: message.appId,
                    serviceType: message.serviceType,
                    subServiceType: message.subServiceType,
                    taskTitle: message.taskTitle,
                    solId: message.solId,
                    urgency: message.urgency,
                    returnedBack: message.returnBackTo ? message.returnBackTo : "",
                    returnedBy: message.sentByUsername,
                    jointAccountCustomerNumber: message.jointAccountCustomerNumber,
                    requestStatus: message.status,
                    makerId: message.lastMakerUser,
                });
            });
            this.setState({
                page: response.data.number,
                totalRow: response.data.totalElements,
                tableData: tableData,
                getData: true,
                showTable: true,
                loading: false
            })

        }).catch(error => {
            console.log(error);
            this.setState({
                loading: false,
                showTable: true,
                searchData: false,
            })
        });
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderCloseIcon = () => {
        return (
            <h4 style={{color: "white"}} >All Inbox</h4>
        )
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    handleChangePage = (pageNumber) => {
        this.setState({
            loading: true,
            getData: false,
            showTable: false
        });
        if (this.state.searchData) {
            this.searchHandler("", pageNumber)
        } else {
            this.functionForGetTabWiseUrl(this.state.activeItem, pageNumber);
        }
    };


    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'null' || clone[prop] === null || clone[prop] === 'undefined')
                delete clone[prop];
        return clone;
    };


    returnService = (priorityDesc, freeFlag1, freeFlag6) => {
        if ((freeFlag6 === "Customer Information Update" || freeFlag6 === "Mandate/Related Party Maintenance" || freeFlag6 === "Dormant Activation & Maintenance") && freeFlag1) {
            return freeFlag1.replaceAll("^", ",")
        } else {
            return priorityDesc
        }
    }

    returnCategory = (branchingCategory, freeFlag5, category) => {
        if (branchingCategory !== undefined && branchingCategory !== null && branchingCategory !== "") {
            return branchingCategory
        }
        else if (category !== undefined && category !== null && category !== "") {
            return category
        }
        else if (freeFlag5 !== undefined && freeFlag5 !== null && freeFlag5 !== "") {
            return freeFlag5
        } else {
            return category
        }
    };

    returnSubCategory = (branchingSubCategory, freeFlag6, subCategory) => {
        if (branchingSubCategory !== undefined && branchingSubCategory !== null && branchingSubCategory !== "") {
            return branchingSubCategory
        }
        else if (subCategory !== undefined && subCategory !== null && subCategory !== "") {
            return subCategory
        }
        else if (freeFlag6 !== undefined && freeFlag6 !== null && freeFlag6 !== "") {
            return freeFlag6
        } else {
            return subCategory
        }
    };


    manageStatusForSearch = () => {
        switch (this.state.activeItem) {
            case "Group Inbox":
                return "Open";
            case "Inbox":
                return "Locked";
            case "Pending":
                return "Saved";
            case "Certificate":
                return "Enrollment";
            case "Not Reach":
                return "NotReach";
            default:
                return "Open"
        }
    }

    searchHandler = (event, pageNumber) => {
        this.setState({
            page: 0,
            totalRow: 0,
            tableData: [],
            loading: true,
            showTable: false,
        });
        let pageN = pageNumber ? pageNumber : 0;
        let postData = this.removeNullValue(this.state.inputData);
        postData.requestTypes="MERCHANT";
        if (this.state.activeItem === "Group Inbox" && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf("INTERNET_BANKING") !== -1) {
            postData.tabName = "INTERNET_BANKING"
        } else if (this.manageStatusForSearch() === "Open") {
            postData.tabName = "Group Inbox"
        }else if (this.manageStatusForSearch() === "NotReach") {
            postData.tabName = "NotReach"
        }

        let url = backEndServerURL + "/filterUserInbox/" + this.manageStatusForSearch() + "/" + pageN;
        axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(response => {
            let tableData = [];
            response.data.content.map((message, index) => {
                tableData.push({
                    SL: pageNumber > 0 ? ((index + 1) + (100 * pageNumber)) : index + 1,
                    CaseId: message.caseId,
                    customer_name: message.customerName,
                    cb_number: message.cbNumber,
                    account_number: message.accountNumber,
                    businessSegment: message.businessSegment,
                    category_type: this.returnCategory(message.branchingCategory, message.freeFlag5, message.category),
                    sub_category_type: this.returnSubCategory(message.branchingSubCategory, message.freeFlag6, message.subCategory),
                    Service: message.callCategory,
                    Date: message.delInitTime.replace("T", " "),
                    currentUserName: message.currentUserName,
                    branch_name: message.branchName,
                    Status: message.delStatus,
                    freeFlag1: message.freeFlag1,
                    freeFlag2: message.freeFlag2,
                    freeFlag3: message.freeFlag3,
                    freeFlag4: message.freeFlag4,
                    recpmtid: message.recpmtid,
                    freeFlag5: message.freeFlag5,
                    freeFlag6: message.freeFlag6,
                    appId: message.appId,
                    serviceType: message.serviceType,
                    subServiceType: message.subServiceType,
                    taskTitle: message.taskTitle,
                    solId: message.solId,
                    urgency: message.urgency,
                    returnedBack: message.returnBackTo ? message.returnBackTo : "",
                    returnedBy: message.sentByUsername,
                    jointAccountCustomerNumber: message.jointAccountCustomerNumber,
                    requestStatus: message.status,
                    makerId: message.lastMakerUser,
                });
            });
            this.setState({
                page: response.data.number,
                totalRow: response.data.totalElements,
                tableData: tableData,
                getData: true,
                showTable: true,
                searchData: true,
                loading: false
            })

        }).catch(error => {
            console.log(error);
            this.setState({
                loading: false,
                showTable: true,
                searchData: false,
            })
        });
    };

    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.searchHandler(event, 0);
        }
    };


    renderFilterField = () => {
        return (
            <Grid container>
                {CommonJsonFormComponent.renderJsonForm(this.state, filterField, this.updateComponent, this.onKeyDownForDedup)}
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: '15px',
                    }}
                    onClick={(e) => this.searchHandler(e, 0)}
                    type="submit"
                >
                    Search
                </button>
                &nbsp;
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: '15px',
                    }}
                    onClick={() => window.location.reload()}
                    type="submit"
                >
                    Reset
                </button>
            </Grid>
        );
    };


    headerSelect = (event, value) => {
        event.preventDefault();
        this.setState({
            searchData: false,
            activeItem: value,
            loading: true,
            getData: false,
            showTable: false,
            page: 0,
            tableData: [],
        }, this.functionForGetTabWiseUrl(value, 0));
    };

    functionForGetTabWiseUrl = (value, page) => {
        this.forceUpdate();
        let urls = "";

        let workFlowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        if (workFlowGroup === "CS" || workFlowGroup === "MERCHANT_DST") {
            // url = backEndServerURL + '/inbox/return/0';
            urls = backEndServerURL + '/csGroupWiseReturnRequest/' + page;
            this.setState({
                activeItem: 'Return',
            })
        }


        //if (value === "Submit" /*|| value === "Complete"*/) {

        else if (value === "Inbox") {
            if(workFlowGroup==="CALL_CENTER_MAKER" || workFlowGroup==="CALL_CENTER_CHECKER" || workFlowGroup==="CARD_DIVISION") {
                urls = backEndServerURL + '/inbox/waiting/OnlyMerchant/' + page;
            }
            else {
                urls = backEndServerURL + '/inbox/waiting/' + page;
            }
        }
        else if (value === "Return") {
            if(workFlowGroup==="CALL_CENTER_MAKER" || workFlowGroup==="CALL_CENTER_CHECKER" || workFlowGroup==="CARD_DIVISION") {
                urls = backEndServerURL + '/csGroupWiseReturnRequest/OnlyMerchant/'+page;
                this.setState({
                    activeItem: 'Return',
                })
            }
            else{
                urls = backEndServerURL + '/csGroupWiseReturnRequest/'+page;
                this.setState({
                    activeItem: 'Return',
                })
            }


        } else if (value === "Pending") {
            urls = backEndServerURL + '/inbox/saved/' + page;
        } else if (value === "Submit") {
            urls = backEndServerURL + '/perticipated/' + page;
        } else if (value === "Certificate") {
            urls = backEndServerURL + '/inbox/closed/ENROLLMENT/' + page;
        } else if (value === "Not Reach") {
            urls = backEndServerURL + '/inboxTabClaimable/CALL_CENTER_NOT_REACHED/' + page;
        } else if (value === "Complete") {
            urls = backEndServerURL + '/perticipatedClosed/' + page;
        } else if (value === "Group Inbox") {
            if(LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup")==="CALL_CENTER_MAKER" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup")==="CALL_CENTER_CHECKER" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup")==="CARD_DIVISION"){
                urls = backEndServerURL + '/workspaceClaimable/OnlyMerchant/' + page;
            }
            else {
                urls = backEndServerURL + '/workspaceClaimable/' + page;
            }
        } else if (value === "Checker Approved") {
            urls = backEndServerURL + '/inboxTabClaimable/APPROVED_BY_CHECKER/' + page;
        } else if (value === "Send to Vendor") {
            urls = backEndServerURL + '/inboxTabClaimable/SEND_TO_VENDOR/' + page;
        } else if (value === "Get Pass") {
            urls = backEndServerURL + '/inboxTabClaimable/GET_PASS/' + page;
        } else if (value === "Delivered") {
            urls = backEndServerURL + '/inboxTabClaimable/DELIVERED/' + page;
        } else if (value === "Undelivered") {
            urls = backEndServerURL + '/inboxTabClaimable/UNDELIVERED/' + page;
        } else if (value === "Deferral") {
            urls = backEndServerURL + '/deferral/CSEnd/' + page;
        } else if (value === "Deferral Close") {
            urls = backEndServerURL + '/deferral/closable/' + page;
        }

        this.fetchTableData(urls, page)
    };

    renderInboxCase = () => {
        console.log("...", LocalstorageEncrypt.encryptStorageFunction.getItem("roles"));
        console.log("...", LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup"));
        /* if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles").indexOf('DSTCS') !== -1) {
             return (
                 <CSInbox closeModal={this.closeModal} appUid={this.state.appUid}
                          serviceType={this.state.serviceType} taskTitle={this.state.taskTitle}
                          subServiceType={this.state.subserviceType}/>
             )
         } else*/
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('CARD_DIVISION') !== -1 ) {

            return (<MerchantMaintenance closeModal={this.closeModal} closeIcon={this.closeModal}
                                         appId={this.state.appUid}/>)

        }
        else if (  LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup")==='CALL_CENTER_MAKER') {
            return (
                <MerchantInboxCase closeModal={this.closeModal} appId={this.state.appUid}
                                   serviceType={this.state.serviceType} taskTitle={this.state.taskTitle}
                                   subServiceType={this.state.subServiceType} activeTab={this.state.activeItem}/>
            )

        }
        else if (/*LocalstorageEncrypt.encryptStorageFunction.getItem("roles").indexOf('MERCHANT') !== -1 ||*/ LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MERCHANT_DST') !== -1  || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('DST_LINE_MANAGER') !== -1|| LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MERCHANT_CALL_CENTER') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MBU') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('HOMBU') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MBU_DFF') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MOU_MAKER') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MOU_CHECKER') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('FRM') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('FRM_CHECKER') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('FRM_APPROVAL') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('S_AND_A') !== -1) {
            return (
                <MerchantInboxCase closeModal={this.closeModal} appId={this.state.appUid}
                                   serviceType={this.state.serviceType} taskTitle={this.state.taskTitle}
                                   subServiceType={this.state.subServiceType} activeTab={this.state.activeItem}/>
            )
        }

        else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace").indexOf("BUSINESS_DIVISION") !== -1) {

            return (
                <BMInboxCase closeModal={this.closeModal} appUid={this.state.appUid}
                             serviceType={this.state.serviceType} taskTitle={this.state.taskTitle}
                             subServiceType={this.state.subserviceType}/>
            )
        }

    };
    renderMenu = () => {
        return (

            <div style={{marginRight: "8px"}}>
                <Menu pointing style={{overflowX: 'auto', overflowY: 'hidden'}}>
                    {this.renderMenuItem("GROUP LOCK", "Group Inbox", this.state.getCountGroupInbox, "Group Inbox")}
                    {this.renderMenuItem("INBOX", "Inbox", this.state.getCountInbox, "Self-Inbox")}
                    {this.renderMenuItem("RETURN", "Return", this.state.getCountReturn, "Return")}
                    {this.renderMenuItem("PENDING", "Pending", this.state.getCountPending, "Pending")}
                    {this.renderMenuItem("CERTIFICATE", "Certificate", undefined, "Certificate")}
                    {this.renderMenuItem("Not Reach", "Not Reach", undefined,"Not Reach")}
                    {this.renderMenuItem("COMPLETE", "Complete",undefined,"Complete")}
                    {this.renderMenuItem("Checker Approved", "Checker Approved",  undefined, "Checker Approved")}
                    {this.renderMenuItem("Send to Vendor", "Send to Vendor", undefined, "Send to Vendor")}
                    {this.renderMenuItem("Get Pass", "Get Pass", undefined,"Get Pass")}
                    {this.renderMenuItem("Delivered", "Delivered", undefined, "Delivered")}
                    {this.renderMenuItem("Undelivered", "Undelivered", undefined, "Undelivered")}
                    {this.renderMenuItem("Deferral", "Deferral", undefined, "Deferral")}
                    {this.renderMenuItem("Deferral Close", "Deferral Close", undefined, "Deferral Close")}
                </Menu>
            </div>
        );
    };

    renderMenuItem = (permission, activeItem, badge, name) => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("menus").indexOf(permission) !== -1) {
            return (
                <Menu.Item
                    name={activeItem}
                    active={this.state.activeItem === activeItem}
                    style={this.state.activeItem === activeItem ? commonInboxTabActive : commonInboxTabInactive}
                    onClick={e => {
                        this.headerSelect(e, activeItem);
                    }}
                >
                    {this.renderBadge(badge, name)}
                </Menu.Item>
            )
        }

    };

    renderBadge = (value, name) => {
        if (value !== undefined && value > 0) {
            return (
                <Badge  color="primary">
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        } else {
            return (
                <Badge color="primary">
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        }
    };

    renderInboxCaseSubmitted = () => {
        return (
            <SubmittedCaseHistory delStatus={this.state.delStatus} closeModal={this.closeModal}
                                  closeIcon={this.closeModal}
                                  appId={this.state.appUid}
                                  subCategory={this.state.subCategory}
                                  category={this.state.category} serviceType={this.state.serviceType}
                                  freeFlag1={this.state.freeFlag1}
                                  freeFlag2={this.state.freeFlag2}
                                  freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                                  recpmtid={this.state.recpmtid}
                                  accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                                  customerName={this.state.customer_name}
                                  solId={this.state.solId}
                                  subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
        )
    };


    onRowClick = (event, rowData) => {
        event.preventDefault();
        if(this.state.activeItem === "Group Inbox" || this.state.activeItem === "Return"){

        }


        else if (this.state.activeItem === "RECTIFIED REQUEST" || this.state.activeItem === "Account Opening Group Inbox" || this.state.activeItem === "Account Opening Rectified Request" || this.state.activeItem === "Remittance Group Inbox" || this.state.activeItem === "Dormant Activation Group Inbox" || this.state.activeItem === "Dormant Activation Rectified Request" || this.state.activeItem === 'Maintenance Group Inbox' || this.state.activeItem === "Maintenance Rectified Request" || this.state.activeItem === "Remittance Group Inbox SD") {
            this.setState({
                loading: true
            });
            let url = backEndServerURL + "/case/claimReturn/" + rowData.appId;
            axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setInboxProps({...rowData, appId: response.data})
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                    })
                })
        } else {
            this.setInboxProps(rowData)
        }
    };

    setInboxProps = (rowData) => {
        console.log(rowData);
        this.setState({

            CaseId: rowData.CaseId,
            serviceType: rowData.serviceType,
            subServiceType: rowData.subServiceType,
            category: rowData.category_type,
            subCategory: rowData.sub_category_type,
            solId: rowData.solId,
            appUid: rowData.appId,
            delStatus: rowData.Status,
            taskTitle: rowData.taskTitle,
            status: rowData.requestStatus,
            freeFlag1: rowData.freeFlag1,
            freeFlag2: rowData.freeFlag2,
            freeFlag3: rowData.freeFlag3,
            freeFlag4: rowData.freeFlag4,
            recpmtid: rowData.recpmtid,
            cb_number: rowData.cb_number,
            account_number: rowData.account_number,
            customer_name: rowData.customer_name,
            urgency: rowData.urgency,
            sentByUsername: rowData.returnedBy,
            jointAccountCustomerNumber: rowData.jointAccountCustomerNumber,
            showInbox: true,
            loading: false
        });
    };

    functionForCaseLockOrUnlock = (event, data) => {
        event.preventDefault();
        let selectedAppId = [];
        for (let item of data) {
            selectedAppId.push(item.appId)
        }
        this.setState({
            loading: true,
            getData: false,
            showTable: false
        });
        let single_url = "";
        if (    this.state.activeItem === "Return" || this.state.activeItem === "Group Inbox" || this.state.activeItem === "Remittance Group Inbox" || this.state.activeItem === "Dormant Activation Group Inbox" || this.state.activeItem === "Account Opening Group Inbox" || this.state.activeItem === "Maintenance Group Inbox") {
            single_url = backEndServerURL + "/case/claim/";
        } else {
            single_url = backEndServerURL + "/case/unClaim/";
        }

        axios.post(single_url, selectedAppId, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.functionForGetTabWiseUrl(this.state.activeItem, this.state.page);
            }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false,
                getData: true,
                showTable: true
            })
        })
    };

    renderTopLabelButton = () => {
        if (this.state.activeItem === "Group Inbox" || this.state.activeItem === "Remittance Group Inbox" || this.state.activeItem === "Dormant Activation Group Inbox" || this.state.activeItem === "Maintenance Group Inbox" || this.state.activeItem === "Account Opening Group Inbox"  || this.state.activeItem === "Return") {
            return [{
                name: "LOCK",
                style: {...commonInboxButton},
                triggerFunction: this.functionForCaseLockOrUnlock
            }]
        } else if ((this.state.activeItem === "Inbox" && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") !== "MAKER") ) {
            return [{
                name: "UNLOCK",
                style: {...commonInboxButton},
                triggerFunction: this.functionForCaseLockOrUnlock
            }]
        }
    };

    renderTable = () => {
        return (
            (this.state.getData && this.state.showTable) &&
            <TableComponent tableData={this.state.tableData} tableColumns={this.renderTableColumn()}
                            onRowClick={this.onRowClick} loader={this.state.loading} maxBodyHeight="450px"
                            totalRow={this.state.totalRow} page={this.state.page} whiteSpace={"wrap"}
                            selection={(this.state.activeItem === "Group Inbox" || this.state.activeItem === "Inbox" || this.state.activeItem === "Return" || this.state.activeItem === "Remittance Group Inbox" || this.state.activeItem === "Dormant Activation Group Inbox" || this.state.activeItem === "Maintenance Group Inbox" || this.state.activeItem === "Account Opening Group Inbox")}
                            handleChangePage={this.handleChangePage} rowsPerPage={this.state.rowsPerPage}
                            tableButton={this.renderTopLabelButton()}
            />
        )
    };

    closeModal = () => {
        this.setState({
            showInbox: false,
            getData: false,
            showTable: false,
            loading: true,
        }, this.functionForGetTabWiseUrl(this.state.activeItem, this.state.page))
    };

    renderInboxComponent = () => {
        if (this.state.activeItem === "Submit" || this.state.activeItem === "SD PENDING CASE" || this.state.activeItem === "Complete") {
            return this.renderInboxCaseSubmitted()
        } else {
            return this.renderInboxCase()
        }
    };

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderCloseIcon()}
                </CardHeader>
                <CardBody>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loading} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        fullScreen={true}
                        open={this.state.showInbox}>
                        <DialogContent className={classes.dialogPaper}>
                            {this.renderInboxComponent()}
                        </DialogContent>
                    </Dialog>
                    <ThemeProvider theme={theme}>
                        {this.renderFilterField()}
                        <Grid item xs={12}><br/></Grid>
                        {this.renderMenu()}
                        {this.renderTable()}
                        {this.renderNotification()}
                    </ThemeProvider>
                </CardBody>
            </Card>
        );
    }

}

export default withStyles(styles)(MerchantInbox);
