const rowsForBond = [
    {field: 'SL', title: 'SL'},
    {field: 'CaseId', title: 'Case ID'},
    {field: 'customer_name', title: 'Name'},
    {field: 'cb_number', title: 'CB Number'},
    {field: 'account_number', title: 'Account Number'},
    {field: 'businessSegment', title: 'Business Segment'},
    {field: 'amount', title: 'Amount'},
    {field: 'category_type', title: 'Category'},
    {field: 'sub_category_type', title: 'Sub Category'},
    {field: 'projectName', title: 'Project Name'},
    {field: 'requestType', title: 'Request Type'},
    {field: 'registrationNumber', title: 'Registration Number'},
    {field: 'branchName', title: 'Branch Name'},
    {field: 'currentUserName', title: 'Current User'},
    {field: 'date', title: 'Date of Activity'},
    {field: 'Status', title: 'Status'},
    {field: 'makerId', title: 'Maker ID'},
];

const filterField = [
    {
        "varName": "caseId",
        "type": "textDedup",
        "label": "LWF Case Number",
        "grid": 2,
    },
    {
        "varName": "category",
        "type": "select",
        "enum": [
            "BOND",
            "SANCHAYPATRA",
        ],
        "label": "Category",
        "grid": 2,
    },
    {
        "varName": "subCategory",
        "type": "select",
        "enum": [
            "ISSUANCE - Wage Earners Development Bond",
            "ISSUANCE - U.S. Dollar Investment Bond",
            "ISSUANCE - U.S. Dollar Premium Bond",
            "ISSUANCE - 5 Years Bangladesh Sanchaya Patra",
            "ISSUANCE - 3 Months Interest Based Sanchaya Patra",
            "ISSUANCE - Family Sanchaya Patra",
            "ISSUANCE - Pensioners Savings Certificate",
            "ENCASHMENT - Wage Earners Development Bond - ENCASHMENT PAYMENT",
            "ENCASHMENT - Wage Earners Development Bond - INTEREST PAYMENT",
            "ENCASHMENT - U.S. Dollar Investment Bond - ENCASHMENT PAYMENT",
            "ENCASHMENT - U.S. Dollar Investment Bond - INTEREST PAYMENT",
            "ENCASHMENT - U.S. Dollar Premium Bond - ENCASHMENT PAYMENT",
            "ENCASHMENT - U.S. Dollar Premium Bond - INTEREST PAYMENT",
            "ENCASHMENT - 5 Years Bangladesh Sanchaya Patra - ENCASHMENT PAYMENT",
            "ENCASHMENT - 5 Years Bangladesh Sanchaya Patra - INTEREST PAYMENT",
            "ENCASHMENT - 3 Months Interest Based Sanchaya Patra - ENCASHMENT PAYMENT",
            "ENCASHMENT - 3 Months Interest Based Sanchaya Patra - INTEREST PAYMENT",
            "ENCASHMENT - Family Sanchaya Patra - ENCASHMENT PAYMENT",
            "ENCASHMENT - Family Sanchaya Patra - INTEREST PAYMENT",
            "ENCASHMENT - Pensioners Savings Certificate - ENCASHMENT PAYMENT",
            "ENCASHMENT - Pensioners Savings Certificate - INTEREST PAYMENT",
            "MAINTENANCE - Wage Earners Development Bond - NOMINEE CHANG",
            "MAINTENANCE - Wage Earners Development Bond - ACES ENROLLMENT",
            "MAINTENANCE - Wage Earners Development Bond - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - Wage Earners Development Bond - DUPLICATE ISSUANCE",
            "MAINTENANCE - Wage Earners Development Bond - CERTIFICATE",
            "MAINTENANCE - U.S. Dollar Investment Bond - NOMINEE CHANG",
            "MAINTENANCE - U.S. Dollar Investment Bond - ACES ENROLLMENT",
            "MAINTENANCE - U.S. Dollar Investment Bond - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - U.S. Dollar Investment Bond - DUPLICATE ISSUANCE",
            "MAINTENANCE - U.S. Dollar Investment Bond - CERTIFICATE",
            "MAINTENANCE - U.S. Dollar Premium Bond - NOMINEE CHANG",
            "MAINTENANCE - U.S. Dollar Premium Bond - ACES ENROLLMENT",
            "MAINTENANCE - U.S. Dollar Premium Bond - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - U.S. Dollar Premium Bond - DUPLICATE ISSUANCE",
            "MAINTENANCE - U.S. Dollar Premium Bond - CERTIFICATE",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - NOMINEE CHANG",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - ACES ENROLLMENT",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - DUPLICATE ISSUANCE",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - CERTIFICATE",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - NOMINEE CHANG",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - ACES ENROLLMENT",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - DUPLICATE ISSUANCE",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - CERTIFICATE",
            "MAINTENANCE - Family Sanchaya Patra - NOMINEE CHANG",
            "MAINTENANCE - Family Sanchaya Patra - ACES ENROLLMENT",
            "MAINTENANCE - Family Sanchaya Patra - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - Family Sanchaya Patra - DUPLICATE ISSUANCE",
            "MAINTENANCE - Family Sanchaya Patra - CERTIFICATE",
            "MAINTENANCE - Pensioners Savings Certificate - NOMINEE CHANG",
            "MAINTENANCE - Pensioners Savings Certificate - ACES ENROLLMENT",
            "MAINTENANCE - Pensioners Savings Certificate - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - Pensioners Savings Certificate - DUPLICATE ISSUANCE",
            "MAINTENANCE - Pensioners Savings Certificate - CERTIFICATE",
        ],
        "label": "Sub Category",
        "grid": 2,
    },
    {
        "varName": "projectName",
        "type": "select",
        "enum": [
            "5 Years Bangladesh Sanchaya Patra",
            "3 Months Interest Based Sanchaya Patra",
            "Family Sanchaya Patra",
            "Pensioners Savings Certificate",
            "Wage Earners Development Bond",
            "U.S. Dollar Investment Bond",
            "U.S. Dollar Premium Bond"
        ],
        "label": "Project Name",
        "grid": 2,
    },
    {
        "varName": "cbNumber",
        "type": "textDedup",
        "label": "CB Number",
        "grid": 2,
    },
    {
        "varName": "accountNumber",
        "type": "textDedup",
        "label": "Account No.",
        "grid": 2,
    },
    {
        "varName": "registrationNumber",
        "type": "text",
        "label": "Registration Number",
        "grid": 2,
    },
    {
        "varName": "fromDate",
        "type": "date",
        "label": "From Date",
        "grid": 2,
    },
    {
        "varName": "toDate",
        "type": "date",
        "label": "To Date",
        "grid": 2,
    },
    {
        "varName": "branch_id",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Branch",
        "grid": 2,
    },
    {
        "varName": "urgency",
        "type": "select",
        "enum": [
            "High",
            "Medium",
            "Low",
        ],
        "label": "Urgency",
        "grid": 2,
    },
    {
        "varName": "cutOffTime",
        "type": "select",
        "enum": [
            "Before",
            "After",
        ],
        "label": "Cut Off Time",
        "grid": 2,
    },
];

export {
    rowsForBond,
    filterField
}