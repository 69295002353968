import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Functions from '../../../Common/Functions';
import {ThemeProvider} from "@material-ui/styles";
import TextFieldComponent from "../../JsonForm/TextFieldComponent";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import SelectComponent from "../../JsonForm/SelectComponent";
import CloseIcon from '@material-ui/icons/Close';
import Table from "../../Table/Table";
import Notification from "../../NotificationMessage/Notification";
import UploadComponent from "../Maintenance/casa/UploadComponent";
import MyValidation from "../../JsonForm/MyValidation";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import CBNotFound from "../CASA/CBNotFound";
import loader from "../../../Static/loader.gif";
import DateComponentDeferal from "../../JsonForm/DateComponentDeferal";
import axiosDownload from "../../DeliverableManagement/FunctionForFileDownload";
import SignatureButton from "../Maintenance/SignatureButton";
import FunctionForGetAcDetails from "../CommonComponent/FunctionForGetAcDetails";


const fileIndexList = [
    {label: 'Document 1'},
    {label: 'Document 2'},
    {label: 'Document 3'},
    {label: 'Document 4'},
    {label: 'Document 5'},
    {label: 'Document 6'},
    {label: 'Document 7'},
    {label: 'Document 8'},
    {label: 'Document 9'},
    {label: 'Document 10'},
    {label: 'Document 11 '},
    {label: 'Document 12'},
    {label: 'Document 13 '},
    {label: 'Document 14'},
    {label: 'Document 15'},
    {label: 'Document 16'},
    {label: 'Document 17'},
    {label: 'Document 18'},
    {label: 'Document 19'},
    {label: 'Document 20'},

];

let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];


var deferalOther = {
    "varName": "deferalOther",
    "type": "text",
    "label": "Remarks",

};

var deferal = {
    "varName": "deferalType",
    "type": "select",
    "label": "Deferal Type",
    "enum": [
        "Applicant Photograph",
        "Nominee Photograph",
        "Passport",
        "Address proof",
        "Transaction profile",
        "Others"
    ],

};

var date = {
    "varName": "expireDate",
    "type": "date",
    "label": "Expire Date",

};

var accountNo = {
    "varName": "outwardObBanAcNo",
    "type": "text",
    "label": "Currency Type",
};
var beneficiaryBank = {
    "varName": "beneficiaryBank",
    "type": "text",
    "label": "Course Type",
};
var beneficiaryAcNo = {
    "varName": "beneficiaryAcNo",
    "type": "text",
    "label": "Program Name",
};
var swiftDetails = {
    "varName": "ObswiftDetails",
    "type": "text",
    "label": "Referred by",
};
var obAmmount = {
    "varName": "obAmmount",
    "type": "text",
    "label": "Ammount",
};

var accountNoLivingExpence = {
    "varName": "outwardObBanAcNoLivingExpence",
    "type": "text",
    "label": "Account No",
};
var beneficiaryBankLivingExpence = {
    "varName": "beneficiaryBankLivingExpence",
    "type": "text",
    "label": "Beneficiary Bank",
};
var beneficiaryAcNoLivingExpence = {
    "varName": "beneficiaryAcNoLivingExpence",
    "type": "text",
    "label": "Beneficiary Ac No",
};
var swiftDetailsLivingExpence = {
    "varName": "ObswiftDetailsLivingExpence",
    "type": "text",
    "label": "Swift Details",
};
var obAmmountLivingExpence = {
    "varName": "obAmmountLivingExpence",
    "type": "text",
    "label": "Ammount",
};
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};


class CSApproval extends Component {

    constructor(props) {
        super(props);
        this.state = {
            SelectedData: false,
            values: [],
            appId: '',
            message: "",
            getData: false,
            loading: true,
            varValue: [],
            currentScheme: [],
            savingScheme: [],
            title: "",
            notificationMessage: "",
            alert: false,
            type: [],
            dueDate: '',
            inputData: {
                csDeferal: "NO",
                tutionFee: "NO",
                livingExpence: "NO"
            },
            valuesTutionFee: [],
            valuesLivingExpence: [],
            fileUploadData: {},
            selectedDate: {},
            AddDeferal: false,
            showValue: false,
            getDeferalList: [],
            getTutionFeeList: [],
            getLivingExpenceList: [],
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            cbNotFound: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '')
                delete clone[prop];
        return clone;
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={this.submitApprove}
                    > Approve
                    </button>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <button
                        className="btn btn-outline-info"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={this.submitReturn}
                    > Return
                    </button>
                </div>

            )
        }
    };

    componentDidMount() {
        var remarksArray = [];
        if (this.props.appId !== undefined) {
            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                console.log(response.data);
                response.data.map((data) => {
                    remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                });
                this.setState({
                    getRemarks: remarksArray
                })
            }).catch((error) => {
                console.log(error)
            });

            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                // for (let item of this.props.commonJsonForm) {
                //     if (item.varName === "accountNumber" || item.varName === "remittanceAccountNumber") {
                //         item.readOnly = true;
                //     }
                // }

                if (response.data.subServiceType === 'Student File') {
                    this.dynamicApiCall("schemeCodeForCurrent", "currentSchemeCode");
                    this.dynamicApiCall("schemeCodeForSavings", "savingsSchemeCode");
                }

                let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    let tableArray = [];
                    var status = "";
                    response.data.map((deferal) => {
                        if (deferal.status === "ACTIVE") {
                            status = "Approved"
                        } else if (deferal.status === "NOTAPPROVED") {
                            status = "NOT APPROVED"
                        } else {
                            status = deferal.status;
                        }
                        tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status, deferal.remarks));

                    });
                    this.setState({
                        getDeferalList: tableArray
                    })
                }).catch((error) => {
                    console.log(error);
                });
                var url = backEndServerURL + "/remittence/" + this.props.appId + "/Tution Fee";
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    let tableArray = [];
                    response.data.map((tutionFee) => {
                        tableArray.push(this.createTutionFeeTable(tutionFee.beneficiaryBank, tutionFee.beneficiaryAcNo, tutionFee.AcNo, tutionFee.SwieftDetails, tutionFee.ammount));
                    });
                    this.setState({
                        getTutionFeeList: tableArray
                    })
                }).catch((error) => {
                    console.log(error);
                });

                url = backEndServerURL + "/remittence/" + this.props.appId + "/Living Expense";
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    let tableArray = [];
                    response.data.map((livingExpence) => {
                        tableArray.push(this.createLivingExpenceTable(livingExpence.beneficiaryBankLivingExpence, livingExpence.beneficiaryAcNoLivingExpence, livingExpence.AcNoLivingExpence, livingExpence.SwieftDetailsLivingExpence, livingExpence.ammountLivingExpence));
                    });
                    this.setState({
                        getLivingExpenceList: tableArray
                    })
                }).catch((error) => {
                    console.log(error);
                });

                let varValue = response.data;
                FunctionForGetAcDetails.getAccountInfo(response.data.accountNumber).then((acResponse) => {
                    varValue.accountBalance = acResponse.data.acctEftvBalance;
                }).catch((error) => {
                    this.setState({
                        loading: false,
                    });
                });
                let adBranchUrl = backEndServerURL + "/finMasterData/adBranchRemittance";
                axios.get(adBranchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    for (let item of this.props.commonJsonForm) {
                        if (item.varName === "adBranch" && item.type === "autoCompleteValueReturn") {
                            item.enum = response.data
                        }
                    }

                    if (this.props.subServiceType === "FTT" || this.props.subServiceType === "FDD") {
                        let countryUrl = backEndServerURL + "/finMasterData/SwiftCodeCountryList";
                        axios.get(countryUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                for (let item of this.props.commonJsonForm) {
                                    if (item.varName === "accountNumber") {
                                        item.readOnly = true;
                                    }
                                    if (item.varName === "beneficiaryCountry") {
                                        item.enum = response.data
                                    }

                                    if (varValue.selectRemType === "FTT" && varValue.swiftCode === "Code") {
                                        if (item.varName === "beneficiaryBankName" || item.varName === "beneficiaryBankAddress") {
                                            item.readOnly = false;
                                        }
                                    } else {
                                        if (item.varName === "beneficiaryBankName" || item.varName === "beneficiaryBankAddress") {
                                            item.readOnly = true;
                                        }
                                    }
                                }
                                this.setState({
                                    getData: true,
                                    inputData: varValue,
                                    varValue: this.copyJson(varValue),
                                    showValue: true,
                                    loading: false,
                                    appId: this.props.appId,
                                });
                            })
                            .catch(error => {
                                this.setState({
                                    getData: true,
                                    inputData: varValue,
                                    varValue: this.copyJson(varValue),
                                    showValue: true,
                                    loading: false,
                                    appId: this.props.appId,
                                });
                            });
                    } else {
                        this.setState({
                            getData: true,
                            inputData: varValue,
                            varValue: this.copyJson(varValue),
                            showValue: true,
                            loading: false,
                            appId: this.props.appId,
                        });
                    }
                }).catch((error) => {
                    console.log(error);
                });

            })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 652) {
                        Functions.removeCookie();
                    }
                });
        }
    }

    dynamicApiCall = (fieldName, apiType) => {
        let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (apiType === "currentSchemeCode") {
                    this.setState({
                        currentScheme: response.data
                    })
                } else {
                    this.setState({
                        savingScheme: response.data
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            })
    };

    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (value === jsonObject.key) {
                return jsonObject.value;
            }
        }
    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status, remarks) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status, remarks
        ])

    };

    createTutionFeeTable = (beneficiaryBank, beneficiaryAcNo, AcNo, SwieftDetails, ammount) => {

        return ([
            beneficiaryBank, beneficiaryAcNo, AcNo, SwieftDetails, ammount
        ])

    };

    createLivingExpenceTable = (beneficiaryBankLivingExpence, beneficiaryAcNoLivingExpence, AcNoLivingExpence, SwieftDetailsLivingExpence, ammountLivingExpence) => {

        return ([
            beneficiaryBankLivingExpence, beneficiaryAcNoLivingExpence, AcNoLivingExpence, SwieftDetailsLivingExpence, ammountLivingExpence
        ])

    };

    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <Grid item xs={12}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Deferral Data </b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status", "Remarks"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />
                        <br/>
                    </div>
                </Grid>
            )
        }

    };

    renderTutionFeeData = () => {
        if ((this.props.subServiceType === 'Student File' || this.props.subServiceType === 'Student File-Renew') && this.state.getTutionFeeList.length > 0) {
            return (
                <Grid item xs={12}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Tuition Fee Data</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Course Type", "Program Name", "Currency Type", "Referred by", "Amount"]}
                            tableData={this.state.getTutionFeeList}
                            tableAllign={['left', 'left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </Grid>
            )
        }

    };

    renderLivingExpenceData = () => {
        if ((this.props.subServiceType === 'Student File' || this.props.subServiceType === 'Student File-Renew') && this.state.getLivingExpenceList.length > 0) {
            return (
                <Grid item xs={12}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Living Expense Data</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Beneficiary Bank", "Beneficiary A/C No", "A/C No", "Swift Details", "Amount"]}
                            tableData={this.state.getLivingExpenceList}
                            tableAllign={['left', 'left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </Grid>
            )
        }

    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <Grid item xs={12}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Data</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </Grid>
            )
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    returnJsonForm = () => {
        if (this.state.showValue) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent, this.onBlurApiCall, this.onKeyDownChange)
            )
        }
    };

    renderOutwardButton = () => {
        if (this.props.subServiceType === "FTT" || this.props.subServiceType === "FDD") {
            return (
                <Grid item xs={12}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        type='button' value='add more'
                        onClick={() => this.functionForDownload("TM")}
                    >Download TM File
                    </button>
                    &nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        type='button' value='add more'
                        onClick={() => this.functionForDownload("Request Form")}
                    >Download Request Form
                    </button>
                </Grid>
            )
        }
    };

    functionForDownload = (document) => {
        let postData = {...this.state.inputData};
        if (this.state.inputData.selectRemType === "FDD") {
            if ('swiftCode' in postData) {
                delete postData.swiftCode;
                delete postData.swiftCodeValue
            }
        }
        let url;
        let fileName;
        if (document === "TM") {
            url = backEndServerURL + "/GetInwardRemittanceTMForm";
            fileName = "TM.pdf"
        } else if (document === "Request Form") {
            fileName = "Request Form.pdf";
            url = backEndServerURL + "/GetForeignCurrencyRemittanceRequestForm";
        }
        this.setState({
            loading: true,
            inputData: postData,
            varValue: this.copyJson(postData),
        });

        axiosDownload.downloadFile(url, "POST", postData, fileName).then((res) => {
            this.setState({
                loading: false
            });
        }).catch((err) => {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: "File Download Fail!"
            });
        });
    };


    onKeyDownChange = (data) => {
        if (data.varName === "adBranch" && (this.props.subServiceType === "Medical File" || (this.props.subServiceType === "Student File" && this.state.inputData.selectFileType === "Open"))) {
            this.setState({
                loading: true
            });
            let type = this.props.subServiceType === "Medical File" ? "CMF" : "CSF";
            let inputData = this.state.inputData;
            this.state.inputData.fileNumberApiCall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/StudentAccountReferenceNumber/" + this.state.inputData.adBranch.key + "/" + type;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                inputData.transactionParticularReferenceNumber = response.data;
                inputData.fileNumber = response.data;
                inputData.studentFileNumber = response.data;
                inputData.fileNumberApiCall = true;
                this.setState({
                    inputData: inputData,
                    varValue: this.copyJson(inputData),
                    loading: false,
                    showValue: true,
                })
            }).catch((error) => {
                console.log(error);
                this.setState({
                    showValue: true,
                    loading: false,
                    title: "Student File Number Generate Failed!",
                    cbNotFound: true
                })
            });
        }
        if (data.varName === "swiftCode" && this.state.inputData.swiftCode === "Code") {
            this.setState({
                loading: true
            });
            let inputData = this.state.inputData;
            inputData.showSwiftValue = true;
            let countryUrl = backEndServerURL + "/finMasterData/SwiftCodeCountryList";
            axios.get(countryUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    for (let item of this.props.commonJsonForm) {
                        if (item.varName === "beneficiaryCountry") {
                            item.enum = response.data
                        }
                        if (item.varName === "beneficiaryBankName" || item.varName === "beneficiaryBankAddress") {
                            item.readOnly = false;
                        }
                    }

                    setTimeout(() => {
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                            showValue: true,
                            loading: false
                        });
                    }, 300)
                })
                .catch(error => {
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        showValue: true,
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    });
                });
        }
        if (data.varName === "swiftCode" && this.state.inputData.swiftCode === "SWIFT") {
            this.setState({
                loading: true
            });
            let inputData = this.state.inputData;
            for (let item of this.props.commonJsonForm) {
                if (item.varName === "beneficiaryBankName" || item.varName === "beneficiaryBankAddress") {
                    item.readOnly = true;
                }
            }
            setTimeout(() => {
                this.setState({
                    inputData: inputData,
                    varValue: this.copyJson(inputData),
                    showValue: true,
                    loading: false
                });
            }, 300)
        }
        if (data.varName === "selectRemType") {
            this.setState({
                loading: true
            });
            let inputData = this.state.inputData;
            if (this.state.inputData.selectRemType === "FDD") {
                // inputData.swiftCode = undefined;
                for (let item of this.props.commonJsonForm) {
                    if (item.varName === "beneficiaryBankName" || item.varName === "beneficiaryBankAddress") {
                        item.readOnly = false;
                    }
                }
            } else {
                for (let item of this.props.commonJsonForm) {
                    if (item.varName === "beneficiaryBankName" || item.varName === "beneficiaryBankAddress") {
                        item.readOnly = true;
                    }
                }
            }
            setTimeout(() => {
                this.setState({
                    inputData: inputData,
                    varValue: this.copyJson(inputData),
                    showValue: true,
                    loading: false
                })
            }, 300)
        }
    };

    onBlurApiCall = (event, data) => {
        if (data === "remittanceAccountNumber") {
            this.setState({
                loading: true
            });
            this.state.inputData.remittanceAccountNumber = event.target.value;
            this.state.inputData.showSignature = false;
            this.state.inputData.showAccountBalance = false;
            this.state.inputData.accountNumber = this.state.inputData.remittanceAccountNumber;
            let getAccountUrl = backEndServerURL + "/getAccountInfo/" + this.state.inputData.accountNumber;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.data.message === "No Account Found!") {
                        let inputData = this.state.inputData;
                        inputData.showSignature = false;
                        inputData.showAccountBalance = false;
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                            showValue: true,
                            loading: false,
                            cbNotFound: true,
                            title: 'No Account Found!'
                        });
                    } else {
                        let inputData = this.state.inputData;
                        FunctionForGetAcDetails.getAccountInfo(this.state.inputData.remittanceAccountNumber).then((custResponse) => {
                            inputData.accountBalance = custResponse.data.acctEftvBalance;
                            inputData.showSignature = true;
                            inputData.name = response.data.customerName;
                            inputData.showAccountBalance = custResponse.data.schemeCode !== "SBSTF";
                            inputData.customerName = response.data.customerName;
                            inputData.cbNumber = response.data.cbNumber;
                            inputData.email = response.data.emailAddr;
                            let contactNumber = response.data.contactNumber ? response.data.contactNumber.replace(/\D/g, '') : "";
                            inputData.contactNumber = response.data.contactNumber ? contactNumber.substr(contactNumber.length - 11) : "";

                            if (response.data.mailingAddress) {
                                inputData.addressOne = response.data.mailingAddress.addrLine1;
                                inputData.addressTwo = response.data.mailingAddress.addrLine2
                            }
                            this.setState({
                                inputData: inputData,
                                varValue: this.copyJson(inputData),
                                showValue: true,
                                loading: false,
                            });
                        }).catch((error) => {
                            let inputData = this.state.inputData;
                            inputData.showSignature = false;
                            inputData.showAccountBalance = false;
                            this.setState({
                                inputData: inputData,
                                varValue: this.copyJson(inputData),
                                showValue: true,
                                loading: false,
                                cbNotFound: true,
                                title: error.response.data.message
                            });
                        });
                    }
                })
                .catch(error => {
                    let inputData = this.state.inputData;
                    inputData.showSignature = false;
                    inputData.showAccountBalance = false;
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        showValue: true,
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    });
                });
        }
        if (data === "accountNumber" && this.props.subServiceType === 'Medical File') {
            this.setState({
                loading: true
            });
            this.state.inputData.accountNumber = event.target.value;
            this.state.inputData.showSignature = false;
            this.state.inputData.showAccountBalance = false;
            let getAccountUrl = backEndServerURL + "/getAccountInfo/" + this.state.inputData.accountNumber;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    if (response.data.message === "No Account Found!") {
                        let inputData = this.state.inputData;
                        inputData.showSignature = false;
                        inputData.showAccountBalance = false;
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                            showValue: true,
                            loading: false,
                            cbNotFound: true,
                            title: 'No Account Found!'
                        });
                    } else {

                        let inputData = this.state.inputData;
                        FunctionForGetAcDetails.getAccountInfo(this.state.inputData.accountNumber).then((custResponse) => {
                            inputData.accountBalance = custResponse.data.acctEftvBalance;
                            inputData.showSignature = true;
                            inputData.name = response.data.customerName;
                            inputData.showAccountBalance = custResponse.data.schemeCode !== "SBSTF";
                            inputData.customerName = response.data.customerName;
                            let contactNumber = response.data.contactNumber ? response.data.contactNumber.replace(/\D/g, '') : "";
                            inputData.contactNumber = response.data.contactNumber ? contactNumber.substr(contactNumber.length - 11) : "";
                            inputData.cbNumber = response.data.cbNumber;
                            if (response.data.mailingAddress) {
                                inputData.addressOne = response.data.mailingAddress.addrLine1;
                                inputData.addressTwo = response.data.mailingAddress.addrLine2
                            }

                            this.setState({
                                inputData: inputData,
                                varValue: this.copyJson(inputData),
                                showValue: true,
                                loading: false,
                            });
                        }).catch((error) => {
                            let inputData = this.state.inputData;
                            inputData.showSignature = false;
                            inputData.showAccountBalance = false;
                            this.setState({
                                inputData: inputData,
                                varValue: this.copyJson(inputData),
                                showValue: true,
                                loading: false,
                                cbNotFound: true,
                                title: error.response.data.message
                            });
                        });
                    }

                })
                .catch(error => {
                    let inputData = this.state.inputData;
                    inputData.showSignature = false;
                    inputData.showAccountBalance = false;
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        showValue: true,
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    });
                });
        }
        if (data === "accountNumber" && this.props.subServiceType === 'Student File') {
            this.setState({
                loading: true
            });
            this.state.inputData.accountNumber = event.target.value;
            this.state.inputData.showSignature = false;
            this.state.inputData.showAccountBalance = false;
            let getAccountUrl = backEndServerURL + "/getAccountInfo/" + this.state.inputData.accountNumber;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    if (response.data.message === "No Account Found!") {
                        let inputData = this.state.inputData;
                        inputData.showSignature = false;
                        inputData.showAccountBalance = false;
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                            showValue: true,
                            loading: false,
                            cbNotFound: true,
                            title: 'No Account Found!'
                        });
                    } else {
                        let inputData = this.state.inputData;
                        if (response.data.accountType === "Current") {
                            inputData.schemeCodeDescription = this.findByVarNameGetKeyValue(response.data.schemeCode, this.state.currentScheme);
                        } else {
                            inputData.schemeCodeDescription = this.findByVarNameGetKeyValue(response.data.schemeCode, this.state.savingScheme);
                        }
                        FunctionForGetAcDetails.getAccountInfo(this.state.inputData.accountNumber).then((custResponse) => {
                            inputData.accountBalance = custResponse.data.acctEftvBalance;
                            inputData.showSignature = true;
                            inputData.name = response.data.customerName;
                            inputData.showAccountBalance = custResponse.data.schemeCode !== "SBSTF";
                            inputData.customerName = response.data.customerName;
                            inputData.accountType = response.data.accountType;
                            inputData.schemeCode = response.data.schemeCode;
                            let contactNumber = response.data.contactNumber ? response.data.contactNumber.replace(/\D/g, '') : "";
                            inputData.contactNumber = response.data.contactNumber ? contactNumber.substr(contactNumber.length - 11) : "";
                            inputData.cbNumber = response.data.cbNumber;
                            inputData.solId = response.data.solid;
                            if (response.data.mailingAddress) {
                                inputData.addressOne = response.data.mailingAddress.addrLine1;
                                inputData.addressTwo = response.data.mailingAddress.addrLine2
                            }
                            this.setState({
                                inputData: inputData,
                                varValue: this.copyJson(inputData),
                                showValue: true,
                                loading: false,
                            });
                        }).catch((error) => {
                            let inputData = this.state.inputData;
                            inputData.showSignature = false;
                            inputData.showAccountBalance = false;
                            this.setState({
                                inputData: inputData,
                                varValue: this.copyJson(inputData),
                                showValue: true,
                                loading: false,
                                cbNotFound: true,
                                title: error.response.data.message
                            });
                        });
                    }

                })
                .catch(error => {
                    let inputData = this.state.inputData;
                    inputData.showSignature = false;
                    inputData.showAccountBalance = false;
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        showValue: true,
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    });
                });
        }
        if (data === "accountNumber" && this.props.subServiceType === "Other Service") {
            this.setState({
                loading: true
            });
            this.state.inputData.accountNumber = event.target.value;
            this.state.inputData.showSignature = false;
            this.state.inputData.showAccountBalance = false;
            this.state.varValue["accountNumber"] = this.state.inputData.accountNumber;
            this.state.varValue["selectServiceType"] = this.state.inputData.selectServiceType;

            let getAccountUrl = backEndServerURL + "/getAccountInfo/" + this.state.inputData.accountNumber;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    if (response.data.message === "No Account Found!") {
                        this.state.varValue["accountNumber"] = this.state.inputData.accountNumber;
                        this.state.varValue["selectServiceType"] = this.state.inputData.selectServiceType;
                        this.setState({
                            showValue: true,
                            loading: false,
                            cbNotFound: true,
                            title: 'No Account Found!'
                        });
                    } else {
                        let inputData = this.state.inputData;
                        FunctionForGetAcDetails.getAccountInfo(this.state.inputData.accountNumber).then((custResponse) => {
                            inputData.accountBalance = custResponse.data.acctEftvBalance;
                            inputData.showSignature = true;
                            inputData.showAccountBalance = custResponse.data.schemeCode !== "SBSTF";
                            let contactNumber = response.data.contactNumber ? response.data.contactNumber.replace(/\D/g, '') : "";
                            inputData.contactNumber = response.data.contactNumber ? contactNumber.substr(contactNumber.length - 11) : "";
                            inputData.name = response.data.customerName;
                            inputData.customerName = response.data.customerName;
                            inputData.cbNumber = response.data.cbNumber;
                            if (response.data.mailingAddress) {
                                inputData.addressOne = response.data.mailingAddress.addrLine1;
                                inputData.addressTwo = response.data.mailingAddress.addrLine2
                            }

                            this.setState({
                                inputData: inputData,
                                varValue: this.copyJson(inputData),
                                showValue: true,
                                loading: false,
                            });
                        }).catch((error) => {
                            let inputData = this.state.inputData;
                            inputData.showSignature = false;
                            inputData.showAccountBalance = false;
                            this.setState({
                                inputData: inputData,
                                varValue: this.copyJson(inputData),
                                showValue: true,
                                loading: false,
                                cbNotFound: true,
                                title: error.response.data.message
                            });
                        });
                    }

                })
                .catch(error => {
                    let inputData = this.state.inputData;
                    inputData.showSignature = false;
                    inputData.showAccountBalance = false;
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        showValue: true,
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    });
                });
        }
        if (data === "swiftCodeValue" && this.state.inputData.swiftCode === "SWIFT") {
            this.setState({
                loading: true
            });
            this.state.inputData.swiftCodeValue = event.target.value;
            this.state.inputData.showSwiftValue = false;
            let getAccountUrl = backEndServerURL + "/finMasterData/custom/swiftBranch/" + this.state.inputData.swiftCodeValue;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    let inputData = this.state.inputData;
                    inputData.showSwiftValue = true;
                    inputData.beneficiaryCountry = response.data.country;
                    inputData.beneficiaryBankName = response.data.bankName;
                    inputData.beneficiaryBankAddress = response.data.address;
                    let countryUrl = backEndServerURL + "/finMasterData/SwiftCodeCountryList";
                    axios.get(countryUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            for (let item of this.props.commonJsonForm) {
                                if (item.varName === "beneficiaryCountry") {
                                    item.enum = response.data
                                }
                            }

                            this.setState({
                                inputData: inputData,
                                varValue: this.copyJson(inputData),
                                showValue: true,
                                loading: false,
                            });
                        })
                        .catch(error => {
                            let inputData = this.state.inputData;
                            this.setState({
                                varValue: this.copyJson(inputData),
                                showValue: true,
                                loading: false,
                                cbNotFound: true,
                                title: error.response.data.message
                            });
                        });
                })
                .catch(error => {
                    let inputData = this.state.inputData;
                    this.setState({
                        varValue: this.copyJson(inputData),
                        showValue: true,
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    });
                });
        }
        if (data === "amount") {
            this.setState({
                loading: true
            });
            let form = [
                {
                    "varName": "amount",
                    "type": "text",
                    "validation": "length",
                    "maxLength": 14,
                    "required": true
                }
            ];
            let error = MyValidation.defaultValidation(form, this.state);
            this.forceUpdate();
            if (error === true) {
                this.setState({
                    loading: false
                });
                return 0;
            } else {
                let inputData = this.state.inputData;
                let amount = this.state.inputData.amount.split(".");

                if (2 < amount.length) {
                    this.setState({
                        inputData: inputData,
                        varValue: inputData,
                        loading: false,
                        cbNotFound: true,
                        title: "Invalid Amount!"
                    })
                } else {
                    let url = backEndServerURL + "/GetWordFromDecimalNumber";
                    let postData = {};
                    postData.number = this.state.inputData.amount;
                    postData.isBDT = false;
                    axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            inputData.amountInWord = response.data;
                            setTimeout(() => {
                                this.setState({
                                    inputData: inputData,
                                    varValue: inputData,
                                    loading: false
                                })
                            }, 1000)
                        })
                        .catch((error) => {
                            this.setState({
                                inputData: inputData,
                                varValue: inputData,
                                loading: false
                            });
                        });
                }
            }
        }
    };

    functionForFileCheck = (value) => {

        if (this.state.values.length > 0 || this.state.inputData["csDeferal"] === "YES") {
            this.state.inputData["csDeferal"] = "YES";
        } else {
            this.state.inputData["csDeferal"] = "NO";
        }

        if (this.state.inputData["livingExpence"] === "YES" && this.state.valuesLivingExpence.length > 0) {
            ///// Living Expance ////
            var LivingExpenceArray = [];
            var Type = 'Living Expense';
            for (let i = 0; i < this.state.valuesLivingExpence.length; i++) {
                let object = {};
                let value = this.state.valuesLivingExpence[i];
                object.beneficiaryBankLivingExpence = this.state.inputData["beneficiaryBankLivingExpence" + value];
                object.beneficiaryAcNoLivingExpence = this.state.inputData["beneficiaryAcNoLivingExpence" + value];
                object.AcNoLivingExpence = this.state.inputData["outwardObBanAcNoLivingExpence" + value];
                object.SwieftDetailsLivingExpence = this.state.inputData["ObswiftDetailsLivingExpence" + value];
                object.ammountLivingExpence = this.state.inputData["obAmmountLivingExpence" + value];
                LivingExpenceArray.push(object);
            }
            var remetanceUrl = backEndServerURL + "/remittence";
            axios.post(remetanceUrl, {
                appId: this.props.appId,
                dataType: Type,
                mapList: LivingExpenceArray
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                console.log('api');
                console.log(response.data);
            }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            })

            ///// Tution Feee ////
        }
        if (this.state.inputData["tutionFee"] === "YES" && this.state.valuesTutionFee.length > 0) {
            var TutionFeeArray = [];
            Type = 'Tution Fee';

            for (let i = 0; i < this.state.valuesTutionFee.length; i++) {
                let object = {};
                let value = this.state.valuesTutionFee[i];
                object.beneficiaryBank = this.state.inputData["beneficiaryBank" + value];
                object.beneficiaryAcNo = this.state.inputData["beneficiaryAcNo" + value];
                object.AcNo = this.state.inputData["outwardObBanAcNo" + value];
                object.SwieftDetails = this.state.inputData["ObswiftDetails" + value];
                object.ammount = this.state.inputData["obAmmount" + value];
                TutionFeeArray.push(object);
            }

            axios.post(remetanceUrl, {
                appId: this.props.appId,
                dataType: Type,
                mapList: TutionFeeArray
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                console.log('api');
                console.log(response.data);
            }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            })
        }
        if (this.state.inputData["csDeferal"] === "YES") {
            var defType = [];
            var expDate = [];
            var remarks = [];
            for (let i = 0; i < this.state.values.length; i++) {
                let value = this.state.values[i];
                let defferalType = this.state.inputData["deferalType" + value];
                let defRemarks = this.state.inputData["deferalOther" + value] ? this.state.inputData["deferalOther" + value] : "";
                remarks.push(defRemarks);
                defType.push(defferalType);
                let expireDate = this.state.inputData["expireDate" + value];
                expDate.push(expireDate);
                console.log(expDate)
            }
            let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";
            axios.post(deferalRaisedUrl, {
                appId: this.props.appId,
                type: defType,
                dueDate: expDate,
                remarks: remarks
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                console.log(response.data);
            }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            })
        }
        if (value === "submit") {
            if (this.props.subServiceType !== "Inward") {
                this.resetAccountInfo().then((res) => {
                    this.functionForCaseRoute(Type)
                }).catch((err) => {
                    this.setState({
                        cbNotFound: true,
                        title: "Account Reset Failed!",
                        loading: false,
                    })
                })
            } else {
                this.setState({
                    loading: false
                });
                this.functionForCaseRoute(Type)
            }
        }
    };

    functionForCaseRoute = (type) => {
        let data = this.state.inputData;
        data.cs_deferal = this.state.inputData["csDeferal"];
        data.dueDate = this.state.dueDate;
        if (this.state.inputData["csDeferal"] === "YES") {
            data.bm_approval = "NO"
        }
        data.type = type;
        let remarksData = {};
        if (this.state.inputData.subCategory === "Outward") {
            data.freeFlag1 = this.state.inputData.currency;
            data.freeFlag2 = this.state.inputData.amount;
            data.freeFlag6 = `Outward - ${this.state.inputData.selectRemType}`;
        } else if (this.state.inputData.subCategory === "Student File") {
            data.freeFlag3 = this.state.inputData.studentFileNumber;
            data.freeFlag6 = `Student File - ${this.state.inputData.selectFileType}`;
        } else if (this.state.inputData.subCategory === "Medical File") {
            data.freeFlag3 = this.state.inputData.fileNumber;
        } else if (this.state.inputData.subCategory === "Inward") {
            data.freeFlag1 = this.state.inputData.iwAccountCurrency;
            data.freeFlag2 = this.state.inputData.iwAmount;
        }
        remarksData.remark = this.state.inputData.csRemarks;
        remarksData.map = {...data, csRemarks: undefined};
        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
        axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            this.setState({
                title: "Successful!",
                notificationMessage: "Successfully Routed!",
                alert: true,
                loading: false
            });
            this.close();
        }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false
            })
        });
    };

    makeTuitionFeeFormForOnlyValidation = () => {
        let errorTwo = false;
        if (this.state.inputData.selectFileType !== undefined && this.state.inputData.selectFileType !== null && this.state.valuesTutionFee.length > 0) {
            let objectForTuitionFee = [];
            this.state.valuesTutionFee.map((el, i) => {
                objectForTuitionFee.push(
                    {
                        "varName": "beneficiaryBank" + el,
                        "type": "text",
                        // "required": true,
                        "validation": "string"
                    },
                    {
                        "varName": "beneficiaryAcNo" + el,
                        "type": "text",
                        // "required": true,
                        "validation": "numeric"
                    },
                    {
                        "varName": "outwardObBanAcNo" + el,
                        "type": "text",
                        // "required": true,
                        "validation": "numeric"
                    },
                    {
                        "varName": "ObswiftDetails" + el,
                        "type": "text",
                        // "required": true,
                    },
                    {
                        "varName": "obAmmount" + el,
                        "type": "text",
                        // "required": true,
                        "validation": "numeric"
                    }
                )
            });
            errorTwo = MyValidation.defaultValidation(objectForTuitionFee, this.state);
            this.forceUpdate();
        }
        return errorTwo;
    };

    makeLivingExpenseFormForOnlyValidation = () => {
        let errorTwo = false;
        if (this.state.inputData.selectFileType !== undefined && this.state.inputData.selectFileType !== null && this.state.valuesLivingExpence.length > 0) {
            let objectLivingExpense = [];
            this.state.valuesLivingExpence.map((el, i) => {
                objectLivingExpense.push(
                    {
                        "varName": "beneficiaryBankLivingExpence" + el,
                        "type": "text",
                        "validation": "string",
                        // "required": true,
                    },
                    {
                        "varName": "beneficiaryAcNoLivingExpence" + el,
                        "type": "text",
                        "validation": "numeric",
                        // "required": true,
                    },
                    {
                        "varName": "outwardObBanAcNoLivingExpence" + el,
                        "type": "text",
                        "validation": "numeric",
                        // "required": true,
                    },
                    {
                        "varName": "ObswiftDetailsLivingExpence" + el,
                        "type": "text",
                        // "required": true,
                    },
                    {
                        "varName": "obAmmountLivingExpence" + el,
                        "type": "text",
                        "validation": "numeric",
                        // "required": true,
                    }
                )
            });
            errorTwo = MyValidation.defaultValidation(objectLivingExpense, this.state);
            this.forceUpdate()
        }
        return errorTwo;
    };

    handleSubmit = (event) => {
        event.preventDefault();
        let errorTwo = false;
        let errorThree = false;
        let deferralError = MyValidation.deferralValidation(this.state.values, this.state);
        this.forceUpdate();
        let error = MyValidation.defaultValidation(this.props.commonJsonForm, this.state);
        if (this.state.inputData.subServiceType === "Student File") {
            errorTwo = this.makeTuitionFeeFormForOnlyValidation();
            errorThree = this.makeLivingExpenseFormForOnlyValidation();
        }
        this.forceUpdate();
        if (error === true || deferralError === true || errorTwo === true || errorThree === true) {
            return 0;
        } else {
            let inputData = this.state.inputData;
            this.setState({
                inputData: inputData,
                varValue: this.copyJson(inputData),
                loading: true
            });
            let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.data.length > 0) {
                        this.functionForFileCheck("submit")
                    } else {
                        this.setState({
                            loading: false,
                            title: "File upload is mandatory!",
                            cbNotFound: true
                        })
                    }
                }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            })
        }
    };

    handleSubmitDraft = (event) => {
        event.preventDefault();
        let error = MyValidation.deferralValidation(this.state.values, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true
            });
            if (this.props.subServiceType !== "Inward") {
                this.resetAccountInfo().then((res) => {
                    this.functionForSave()
                }).catch((err) => {
                    this.setState({
                        cbNotFound: true,
                        title: "Account Reset Failed!",
                        loading: false,
                    })
                });
            } else {
                this.functionForSave()
            }
        }
    };

    functionForSave = () => {
        if (this.state.values.length > 0 || this.state.inputData["csDeferal"] === "YES") {
            this.state.inputData["csDeferal"] = "YES";
            this.state.inputData["bm_approval"] = "NO";
        } else {
            this.state.inputData["csDeferal"] = "NO";
        }
        this.functionForFileCheck("save");
        let variableSetUrl = backEndServerURL + "/save/" + this.state.appId;
        let data = this.state.inputData;
        data.cs_deferal = this.state.inputData["cs_deferal"];

        if (this.state.inputData.priority === "HIGH")
            data.urgency = 1;
        else
            data.urgency = 0;

        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    loading: false,
                    title: "Successful!",
                    notificationMessage: "Successfully Draft!",
                    alert: true
                });
                this.close()
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
    }

    addDeferalForm() {
        return this.state.values.map((el, i) =>
            <React.Fragment>
                <Grid xs='12'>
                </Grid>
                <Grid item xs={3}>
                    {
                        this.dynamicDeferral(el)
                    }
                </Grid>
                <Grid item xs={3}>
                    {
                        this.dynamicDate(el)
                    }
                </Grid>
                <Grid item xs={3}>
                    {this.dynamicDeferralOther(el)}
                </Grid>
                <Grid item xs={1}>
                    <button
                        style={{float: "right", marginTop: "15px"}}
                        className="btn btn-outline-primary btn-sm"
                        type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                    >
                        Remove
                    </button>
                </Grid>
            </React.Fragment>
        )
    }

    renderAddButtonShow = () => {
        if (this.props.subServiceType !== "Other Service") {
            return (
                <Grid item xs={12}>
                    <button
                        className="btn btn-outline-primary btn-sm"

                        style={{

                            float: 'left',
                            verticalAlign: 'left',

                        }}
                        type='button' value='add more'
                        onClick={this.addClick.bind(this)}
                    >Add Deferral
                    </button>
                </Grid>
            )
        }

    };

    addClick() {
        let randomNumber = Math.floor(Math.random() * 100000000000);
        this.setState(prevState => ({
            values: [...prevState.values, randomNumber],
        }));
        this.state.inputData["csDeferal"] = "YES";
    }

    removeClick(i, event) {
        event.preventDefault();
        let pos = this.state.values.indexOf(i);
        if (pos > -1) {
            this.state.values.splice(pos, 1);
            this.updateComponent();
            if (this.state.values.length > 0) {
                this.state.inputData["csDeferal"] = "YES"
            } else {
                this.state.inputData["csDeferal"] = "NO"
            }
        }
    }

    renderAddButtonLivingExpacne = () => {
        if (this.props.subServiceType === 'Student File' || this.props.subServiceType === 'Student File-Renew') {
            return (
                <Grid item xs={12}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{

                            float: 'left',
                            verticalAlign: 'left',

                        }}
                        type='button' value='add more'
                        onClick={this.addClickLivingExpence.bind(this)}
                    >Living Expense
                    </button>
                </Grid>
            )
        }

    };

    addClickLivingExpence() {
        let randomNumber = Math.floor(Math.random() * 100000000000);
        this.setState(prevState => ({
            valuesLivingExpence: [...prevState.valuesLivingExpence, randomNumber],
        }));
        this.state.inputData["livingExpence"] = "YES";
    }

    addDeferalFormLivingExpence() {
        return this.state.valuesLivingExpence.map((el, i) =>

            <React.Fragment>
                <Grid xs='12'>
                </Grid>
                <Grid item xs={2}>
                    {this.benificiaryBankLivingExpence(el)}
                </Grid>
                <Grid item xs={2}>
                    {this.benificiaryAcNoLivingExpence(el)}
                </Grid>
                <Grid item xs={2}>
                    {this.accountNoLivingExpence(el)}
                </Grid>
                <Grid item xs={2}>
                    {this.swiftDetailsLivingExpence(el)}
                </Grid>
                <Grid item xs={2}>
                    {this.ammountLivingExpence(el)}
                </Grid>
                <Grid item xs={1.5}>
                    <button
                        style={{float: "right", marginTop: "15px"}}
                        className="btn btn-outline-primary btn-sm"
                        type='button' value='remove' onClick={this.removeClickLivingExpence.bind(this, el)}
                    >
                        Remove
                    </button>
                </Grid>
            </React.Fragment>
        )
    }

    removeClickLivingExpence(i, event) {
        event.preventDefault();
        let pos = this.state.valuesLivingExpence.indexOf(i);
        if (pos > -1) {
            this.state.valuesLivingExpence.splice(pos, 1);
            this.updateComponent();
            if (this.state.valuesLivingExpence.length > 0) {
                this.state.inputData["livingExpence"] = "YES"
            } else {
                this.state.inputData["livingExpence"] = "NO"
            }
        }
    }

    renderAddButtonShowTutionFee = () => {
        if (this.props.subServiceType === 'Student File' || this.props.subServiceType === 'Student File-Renew') {
            return (
                <Grid item xs={12}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            float: 'left',
                            verticalAlign: 'left',
                        }}
                        type='button' value='add more'
                        onClick={this.addClickTutionFee.bind(this)}
                    >Tuition Fee
                    </button>
                </Grid>
            )
        }

    };

    addClickTutionFee() {
        let randomNumber = Math.floor(Math.random() * 100000000000);
        this.setState(prevState => ({
            valuesTutionFee: [...prevState.valuesTutionFee, randomNumber],
        }));
        this.state.inputData["tutionFee"] = "YES";
    }

    addDeferalFormTutionFee() {
        if (this.props.subServiceType === 'Student File' || this.props.subServiceType === 'Student File-Renew') {
            return this.state.valuesTutionFee.map((el, i) =>

                <React.Fragment>
                    <Grid xs='12'>
                    </Grid>
                    <Grid item xs={2}>
                        {this.benificiaryBank(el)}
                    </Grid>
                    <Grid item xs={2}>
                        {this.benificiaryAcNo(el)}
                    </Grid>
                    <Grid item xs={2}>
                        {this.accountNo(el)}
                    </Grid>
                    <Grid item xs={2}>
                        {this.swiftDetails(el)}
                    </Grid>
                    <Grid item xs={2}>
                        {this.ammount(el)}
                    </Grid>
                    <Grid item xs={1.5}>
                        <button
                            style={{float: "right", marginTop: "15px"}}
                            className="btn btn-outline-primary btn-sm"
                            type='button' value='remove' onClick={this.removeClickTutionFee.bind(this, el)}
                        >
                            Remove
                        </button>
                    </Grid>
                </React.Fragment>
            )
        }

    }

    removeClickTutionFee(i, event) {
        event.preventDefault();
        let pos = this.state.valuesTutionFee.indexOf(i);
        if (pos > -1) {
            this.state.valuesTutionFee.splice(pos, 1);
            this.updateComponent();
            if (this.state.valuesTutionFee.length > 0) {
                this.state.inputData["tutionFee"] = "YES"
            } else {
                this.state.inputData["tutionFee"] = "NO"
            }
        }
    }

    dynamicDeferral = (i) => {
        let field = JSON.parse(JSON.stringify(deferal));
        field.varName = "deferalType" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };

    dynamicDeferralOther = (i) => {
        if (this.state.inputData["deferalType" + i] === "Others") {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        } else {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        }
    };

    dynamicDate = (i) => {
        let field = JSON.parse(JSON.stringify(date));
        field.varName = "expireDate" + i;
        return DateComponentDeferal.date(this.state, this.updateComponent, field);
    };


    benificiaryBank = (i) => {
        let field = JSON.parse(JSON.stringify(beneficiaryBank));
        field.varName = "beneficiaryBank" + i;
        return TextFieldComponent.text(this.state, this.updateComponent, field);
    };

    benificiaryBankLivingExpence = (i) => {
        let field = JSON.parse(JSON.stringify(beneficiaryBankLivingExpence));
        field.varName = "beneficiaryBankLivingExpence" + i;
        return TextFieldComponent.text(this.state, this.updateComponent, field);
    };

    benificiaryAcNo = (i) => {
        let field = JSON.parse(JSON.stringify(beneficiaryAcNo));
        field.varName = "beneficiaryAcNo" + i;
        return TextFieldComponent.text(this.state, this.updateComponent, field);
    };

    benificiaryAcNoLivingExpence = (i) => {
        let field = JSON.parse(JSON.stringify(beneficiaryAcNoLivingExpence));
        field.varName = "beneficiaryAcNoLivingExpence" + i;
        return TextFieldComponent.text(this.state, this.updateComponent, field);
    };

    accountNo = (i) => {
        let field = JSON.parse(JSON.stringify(accountNo));
        field.varName = "outwardObBanAcNo" + i;
        return TextFieldComponent.text(this.state, this.updateComponent, field);
    };

    accountNoLivingExpence = (i) => {
        let field = JSON.parse(JSON.stringify(accountNoLivingExpence));
        field.varName = "outwardObBanAcNoLivingExpence" + i;
        return TextFieldComponent.text(this.state, this.updateComponent, field);
    };

    swiftDetails = (i) => {
        let field = JSON.parse(JSON.stringify(swiftDetails));
        field.varName = "ObswiftDetails" + i;
        return TextFieldComponent.text(this.state, this.updateComponent, field);
    };

    swiftDetailsLivingExpence = (i) => {
        let field = JSON.parse(JSON.stringify(swiftDetailsLivingExpence));
        field.varName = "ObswiftDetailsLivingExpence" + i;
        return TextFieldComponent.text(this.state, this.updateComponent, field);
    };

    ammount = (i) => {
        let field = JSON.parse(JSON.stringify(obAmmount));
        field.varName = "obAmmount" + i;
        return TextFieldComponent.text(this.state, this.updateComponent, field);
    };

    ammountLivingExpence = (i) => {
        let field = JSON.parse(JSON.stringify(obAmmountLivingExpence));
        field.varName = "obAmmountLivingExpence" + i;
        return TextFieldComponent.text(this.state, this.updateComponent, field);
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
            )
        }
    };

    close = () => {
        for (let item of this.props.commonJsonForm) {
            if (item.varName === "accountNumber" || item.varName === "remittanceAccountNumber") {
                item.readOnly = false;
            }
        }
        this.props.closeModal();
    };

    uploadFunction = () => {
        const {classes} = this.props;
        return <Grid item xs={12}><UploadComponent indexList={fileIndexList} appId={this.props.appId}
                                                   classes={classes}/></Grid>
    };

    handleClose = (event) => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        let variableSetUrl = backEndServerURL + "/case/close/" + this.props.appId;
        axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        loading: false,
                        title: "Successful!",
                        notificationMessage: "Successfully Close!",
                        alert: true
                    });
                    this.props.closeModal();
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    signature = () => {
        if (this.state.inputData.showSignature) {
            return <SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
                                    classes={this.props}/>;
        }
    };

    resetAccountInfo = () => {
        return new Promise((resolve, reject) => {
            this.setState({
                loading: true
            });
            let url = backEndServerURL + "/resetApplication/" + this.props.appId;
            axios.post(url, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    };

    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >
                            {this.props.titleName}<a><CloseIcon onClick={this.close} style={{
                            position: 'absolute',
                            right: 10,
                            color: "#000000"
                        }}/></a></h4>
                    </CardHeader>
                    <CardBody>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.cbNotFound}>
                            <DialogContent className={classes.dialogPaper}>
                                <CBNotFound
                                    closeModal={this.closeModalCBNotFound}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        <div>
                            <ThemeProvider theme={theme}>
                                <Grid container spacing={1}>
                                    {this.renderNotification()}
                                    {this.returnJsonForm()}
                                    {this.renderOutwardButton()}
                                    {this.renderTutionFeeData()}
                                    {this.renderAddButtonShowTutionFee()}
                                    {this.addDeferalFormTutionFee()}
                                    {this.renderLivingExpenceData()}
                                    {this.renderAddButtonLivingExpacne()}
                                    {this.addDeferalFormLivingExpence()}
                                    <Grid item xs={12}>
                                        {this.signature()}
                                    </Grid>
                                    {this.renderAddButtonShow()}
                                    {this.addDeferalForm()}
                                    {this.uploadFunction()}
                                    {this.renderDefferalData()}
                                    {this.renderRemarksData()}
                                    {this.renderRemarks()}
                                    <br/>
                                    <Grid item xs={12}>
                                        <button
                                            className="btn btn-outline-primary btn-sm"
                                            style={{}}
                                            onClick={this.handleSubmit}
                                        >
                                            Submit
                                        </button>
                                        &nbsp;
                                        <button
                                            className="btn btn-outline-primary btn-sm"
                                            style={{}}
                                            onClick={this.handleSubmitDraft}
                                        >
                                            Save
                                        </button>
                                    </Grid>
                                </Grid>
                            </ThemeProvider>
                        </div>
                    </CardBody>
                </Card>
            );
        }
    }
}

export default withStyles(styles)(CSApproval);
