import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL, frontEndServerURL} from "../../../Common/Constant";
import {Menu} from 'semantic-ui-react';
import 'semantic-ui-offline/semantic.min.css';
import Button from "@material/react-button";
import MaterialTable from "material-table";
import {createMuiTheme} from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import TablePagination from "@material-ui/core/TablePagination";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {buttonStyle, localization, options, tabActive, tabInactive, tableIcons} from "../configuration";
import Notification from "../../NotificationMessage/Notification";
import FailedListModal from "../WelcomeLetter/FailedListModal";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '5',
            }
        },

    }
});

let SearchForm = [
    {
        varName: "fromDate",
        type: "date",
        label: "From Date",
        grid: 2,
    },
    {
        varName: "toDate",
        type: "date",
        label: "To Date",
        grid: 2,
    },
    {
        varName: "type",
        type: "select",
        label: "Type",
        grid: 2,
        enum: [
            "FINACLE",
            "ABABIL",
        ]
    },
    {
        varName: "customerType",
        type: "select",
        label: "Customer type",
        grid: 2,
        enum: [
            "NORMAL",
            "CITYALO",
            "CITYALO(ABABIL)",
            "PRIORITY",
            "SAPPHIRE"
        ]
    },
    {
        varName: "accountNumber",
        type: "text",
        label: "Account Number",
        grid: 2,
    },
    {
        varName: "accountType",
        type: "select",
        label: "Account type",
        grid: 2,
        enum: [
            "CURRENT",
            "SAVINGS",
            "ODA",
            "CCA"
        ]
    },
    {
        varName: "currency",
        type: "autoCompleteValueReturn",
        label: "Currency",
        grid: 2,
        enum: []
    },
    // {
    //     varName: "branch",
    //     type: "autoCompleteValueReturn",
    //     enum: [],
    //     label: "Branch",
    //     grid: 2,
    // },
    {
        varName: "pageOfChequebook",
        type: "select",
        label: "Page of Chequebook",
        grid: 2,
        enum: ["10", "25", "50", "100"],
    },
    {
        varName: "chequeBookType",
        type: "select",
        label: "Cheque Book Type",
        grid: 2,
        enum: ["NORMAL", "A4"],
    },
];

class BomChecqueBookManage extends Component {
    state = {activeItem: "home", backgroundColor: "red"};

    constructor(props) {
        super(props);
        this.state = {
            appData: "",
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            getData: true,
            inputData: {},
            autoComplete: {},
            fileUploadData: {},
            responseData: [],
            activeItem: "All",
            backgroundColor: "red",
            showValue: true,
            uploadModal: false,
            searchItem: "",
            merchantModal: false,
            caseId: "",
            title: "",
            dataPassArray: [],
            buttonName: "Claim",
            notificationMessage: "",
            alert: false,
            showData: true,
            pageNumber: 0,
            loader: true,
            totalRow: 0,
            rowsPerPage: 100,
            selectedCount: 0,
            loading: false,
            disabled:false,
            failedModal: false,
            failedList: [],
            allData: [],
            columns: [
                {title: "Account Number", field: "accountNumber", export: false},
                {title: "Customer Name", field: "customerName", removable: false},
                {title: "Account Status", field: "accountStatus"},
                {title: "Account Type", field: "accountType"},
                {title: "No of Chequebook", field: "numberOfChequebook"},
                {title: "Page of Chequebook", field: "pageofChequebook"},
                {title: "Cheque Series (Begin Leaf Number)", field: "chequeSeries"},
                {title: "Status", field: "status"},
                {title: "Cheque Book Type", field: "chequeBookType"},
                {title: "Requisition Branch", field: "requisitionBranch"},
                {title: "DeliveryType", field: "deliveryType"},
                {title: "Source", field: "source"},
                {title: "Delivery Branch", field: "deliveryBranch"},
                {title: "Date", field: "bomApprovalDate"}
            ],
            branchReceivedColumns: [
                {title: "View Signature", field: "viewSignature"},
                {title: "Account Number", field: "accountNumber"},
                {title: "Customer Name", field: "customerName"},
                {title: "Account Status", field: "accountStatus"},
                {title: "Account Type", field: "accountType"},
                {title: "No of Chequebook", field: "numberOfChequebook"},
                {title: "Page of Chequebook", field: "pageofChequebook"},
                {title: "Cheque Series (Begin Leaf Number)", field: "chequeSeries"},
                {title: "Status", field: "status"},
                {title: "Cheque Book Type", field: "chequeBookType"},
                {title: "Requisition Branch", field: "requisitionBranch"},
                {title: "DeliveryType", field: "deliveryType"},
                {title: "Source", field: "source"},
                {title: "Delivery Branch", field: "deliveryBranch"},
                {title: "Date", field: "bomApprovalDate"},
            ],

        };
    }

    handleItemClick = (e, {name, style}) =>
        this.setState({
            activeItem: name,
            backgroundColor: style
        });

    headerSelect = (event, value) => {
        event.preventDefault();
        console.log(value);
        this.setState({
            loader: true,
            pageNumber: 0
        });
        if (value === "All") {
            this.fetchDataFromAPI('All', 'getBranchAll/page/0')
        } else {
            this.fetchDataFromAPI(value)
        }
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === undefined)
                delete clone[prop];
        return clone;
    };

    searchData = (e, page, status) => {

        let input = {...this.state.inputData};
        input.currency = this.state.inputData.currency ? this.state.inputData.currency.value : "";
        // input.branch = this.state.inputData.branch ? this.state.inputData.branch.value : "";
        let postData = this.removeNullValue(input);
        this.forceUpdate();

        if (Object.keys(postData).length === 0) {
            return 0;
        }

        if (status === "All") {
            this.setState({
                loader: true,
                pageNumber: page,
                activeItem: "All"
            });
        } else {
            this.setState({
                loader: true,
                pageNumber: page,
                activeItem: status
            });
        }


        let inbox_url = backEndServerURL + "/filterBranchChequebookDateCustomerAccount/" + status + "/" + page;

        axios
            .post(inbox_url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].appId;
                    rowData = {
                        id: response.data.content[i].id,
                        accountNumber: response.data.content[i].accountNumber,
                        customerName: response.data.content[i].customerName,
                        accountStatus: response.data.content[i].accountStatus,
                        accountType: response.data.content[i].accountType,
                        pageofChequebook: response.data.content[i].pageofChequebook,
                        numberOfChequebook: response.data.content[i].numberOfChequeBook,
                        chequeSeries: response.data.content[i].chequeNumber,
                        status: response.data.content[i].status,
                        source: response.data.content[i].requestType,
                        chequeBookType: response.data.content[i].chequebookType,
                        requisitionBranch: response.data.content[i].requisitionBranch,
                        deliveryType: response.data.content[i].deliveryType,
                        deliveryBranch: response.data.content[i].deliveryBranch,
                        bomApprovalDate: this.dateConverter(response.data.content[i].modifiedDate),
                        caseId: response.data.content[i].appId,
                        viewSignature: this.viewSignature(response.data.content[i].accountNumber, response.data.content[i].requestType)
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    totalRow: response.data.totalElements,
                    loading: false,
                    loader: false,
                    searchItem: this.state.activeItem,
                });
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false,
                    loader: false,
                })
            });
    };

    dateConverter = (value) => {

        let dateString = value.substr(0, 10);
        return dateString
    };

    viewSignature = (accountNumber,requestType) => {
        return <a className="btn btn-sm btn-danger" style={{"color" : "#ffffff"}} onClick={() => this.openSignature(accountNumber,requestType)}>View Signature</a>
    };

    openSignature = (accountNumber,requestType) => {
        let url = frontEndServerURL + "/signatureCardView/" + accountNumber+"/"+requestType;
        window.open(url)
    };


    fetchDataFromAPI = (status, url) => {
        if (this.state.searchItem !== "") {
            this.searchData(this, this.state.pageNumber, status);
        } else {
            this.setState({
                activeItem: status,
                loader: true,
            });

            let urlSegment;
            let inbox_url;
            if (url === undefined) {
                urlSegment = 'getBranchChequebookStatus';
            } else {
                urlSegment = url;
            }

            if (status === 'All') {
                inbox_url = backEndServerURL + "/" + urlSegment;
            } else {
                inbox_url = backEndServerURL + "/" + urlSegment + "/" + status + "/0";
            }
            axios
                .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    console.log(response);
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        rowData = {
                            id: response.data.content[i].id,
                            accountNumber: response.data.content[i].accountNumber,
                            customerName: response.data.content[i].customerName,
                            accountStatus: response.data.content[i].accountStatus,
                            accountType: response.data.content[i].accountType,
                            pageofChequebook: response.data.content[i].pageofChequebook,
                            numberOfChequebook: response.data.content[i].numberOfChequeBook,
                            chequeSeries: response.data.content[i].chequeNumber,
                            status: response.data.content[i].status,
                            source: response.data.content[i].requestType,
                            chequeBookType: response.data.content[i].chequebookType,
                            requisitionBranch: response.data.content[i].requisitionBranch,
                            deliveryType: response.data.content[i].deliveryType,
                            deliveryBranch: response.data.content[i].deliveryBranch,
                            bomApprovalDate: this.dateConverter(response.data.content[i].modifiedDate),
                            caseId: response.data.content[i].appId,
                            viewSignature: this.viewSignature(response.data.content[i].accountNumber, response.data.content[i].requestType)
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData);
                    }

                    this.setState({
                        allData: array,
                        responseData: responseArray,
                        totalRow: response.data.totalElements,
                        loader: false,
                    });
                    console.log(this.state.allData);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    handleChangePage = (pageNumber, status) => {
        let page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        if (this.state.searchItem !== "") {
            this.searchData(this, page, status);
        } else {
            let inbox_url;
            if (this.state.activeItem === status) {
                if (status === 'All') {
                    inbox_url = backEndServerURL + "/getBranchAll/page/" + page;
                } else {
                    inbox_url = backEndServerURL + "/getBranchChequebookStatus/" + status + "/" + page;
                }
                axios
                    .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => {
                        let array = [];
                        let responseArray = [];
                        let rowData;
                        let responseRowData;
                        for (let i = 0; i < response.data.content.length; i++) {
                            responseRowData = response.data.content[i].appId;
                            rowData = {
                                id: response.data.content[i].id,
                                accountNumber: response.data.content[i].accountNumber,
                                customerName: response.data.content[i].customerName,
                                accountStatus: response.data.content[i].accountStatus,
                                accountType: response.data.content[i].accountType,
                                pageofChequebook: response.data.content[i].pageofChequebook,
                                numberOfChequebook: response.data.content[i].numberOfChequeBook,
                                chequeSeries: response.data.content[i].chequeNumber,
                                chequeBookType: response.data.content[i].chequebookType,
                                requisitionBranch: response.data.content[i].requisitionBranch,
                                deliveryType: response.data.content[i].deliveryType,
                                deliveryBranch: response.data.content[i].deliveryBranch,
                                bomApprovalDate: this.dateConverter(response.data.content[i].modifiedDate),
                                caseId: response.data.content[i].appId,
                                viewSignature: this.viewSignature(response.data.content[i].accountNumber.response.data.content[i].requestType)
                            };
                            array.push(rowData);
                            responseArray.push(responseRowData);
                        }

                        this.setState({
                            allData: array,
                            responseData: responseArray,
                            activeItem: 'All',
                            pageNumber: page,
                            totalRow: response.data.totalElements,
                        });
                        console.log(this.state.allData);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    componentDidMount() {
        //inbox call
        // this.getBranchAll();
        {
            this.dynamicApiCall("currency")
        }
        // {
        //     this.dynamicApiCall("branch")
        // }
        let inbox_url = backEndServerURL + "/getBranchAll/page/0";
        axios
            .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                console.log(response);
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].appId;
                    rowData = {
                        id: response.data.content[i].id,
                        accountNumber: response.data.content[i].accountNumber,
                        customerName: response.data.content[i].customerName,
                        accountStatus: response.data.content[i].accountStatus,
                        accountType: response.data.content[i].accountType,
                        pageofChequebook: response.data.content[i].pageofChequebook,
                        numberOfChequebook: response.data.content[i].numberOfChequeBook,
                        chequeSeries: response.data.content[i].chequeNumber,
                        status: response.data.content[i].status,
                        source: response.data.content[i].requestType,
                        chequeBookType: response.data.content[i].chequebookType,
                        requisitionBranch: response.data.content[i].requisitionBranch,
                        deliveryType: response.data.content[i].deliveryType,
                        deliveryBranch: response.data.content[i].deliveryBranch,
                        bomApprovalDate: this.dateConverter(response.data.content[i].modifiedDate),
                        viewSignature: this.viewSignature(response.data.content[i].accountNumber, response.data.content[i].requestType)
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    totalRow: response.data.totalElements,
                    loader: false,
                });
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
            });
    }

    dynamicApiCall = (apiType) => {
        let branchUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                for (let form of SearchForm) {
                    // if(form.type === "autoCompleteValueReturn" && form.varName === "branch" && apiType === "branch"){
                    //     form.enum = response.data
                    // }
                    if (form.type === "autoCompleteValueReturn" && form.varName === "currency" && apiType === "currency") {
                        form.enum = response.data
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    open = data => {
        this.setState({
            dataPassArray: data,
            merchantModal: true
        });
    };

    resetHandler = () => {
        window.location.reload();
    };

    render() {
        const {activeItem} = this.state;
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >
                                Search Cheque Book
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        SearchForm,
                                        this.updateComponent
                                    )}
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            height: 30,
                                            marginTop: 20,
                                        }}
                                        onClick={e => {
                                            this.searchData(e, 0, 'All')
                                        }}
                                    >
                                        Search
                                    </button>
                                    &nbsp;
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            height: 30,
                                            marginTop: 20,
                                        }}
                                        onClick={this.resetHandler}
                                    >
                                        Reset
                                    </button>
                                </ThemeProvider>
                            </Grid>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Deliverable Management (Cheque Books)</h4>
                        </CardHeader>
                        <CardBody>
                            <div className="deliverable">
                                {this.failedListModal()}
                                <React.Fragment>
                                    <div>
                                        <Menu pointing>
                                            <Menu.Item
                                                name="All"
                                                active={activeItem === "All"}
                                                style={
                                                    activeItem === "All"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                //     onClick={this.handleItemClick}
                                                onClick={e => {
                                                    this.headerSelect(e, "All");
                                                }}
                                            />

                                            <Menu.Item
                                                name="Cheque Acknowledgement"
                                                active={activeItem === "Cheque Acknowledgement"}
                                                style={
                                                    activeItem === "Cheque Acknowledgement"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Cheque Acknowledgement");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Destroy"
                                                active={activeItem === "Waiting for Destroy"}
                                                style={
                                                    activeItem === "Waiting for Destroy"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Waiting for Destroy");
                                                }}
                                            />

                                        </Menu>

                                        {/*<Segment>*/}
                                        {this.tableChoice()}
                                        {/*</Segment>*/}
                                    </div>
                                </React.Fragment>
                                {
                                    this.renderNotification()
                                }
                            </div>
                        </CardBody>
                    </Card>
                </div>
            );
        }

    };

    actionForBranchReceivedToDelivered = (event, rowData, props) => {
        if (this.state.activeItem === "Cheque Acknowledgement") {
            this.setState({
                disabled:true,
                loading: true,
            })
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                let single_url = backEndServerURL + "/acknowledgeChequebook/Delivered to Customer";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            this.setState({
                                disabled:false,
                                loading: false,
                            })
                            if (response.status === 200 && response.data.failedAccountNumberList.length > 0) {
                                this.setState({
                                    failedList: response.data.failedAccountNumberList,
                                    failedModal: true,
                                })
                            }
                            if (response.status === 200 && response.data.successful > 0) {
                                this.functionForNotificationMessage("Successful!", `${response.data.successful} Status Update Successful and ${response.data.failed} Status Update Failed`);
                            }
                            this.fetchDataFromAPI('Cheque Acknowledgement')
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            loading: false,
                        })
                        // this.functionForNotificationMessage("Error!", error.response.data.message);
                    });
            }
        }
    };

    actionForBranchReceivedToReturn = (event, rowData, props) => {
        if (this.state.activeItem === "Cheque Acknowledgement") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                let single_url = backEndServerURL + "/updateChequebookStatus/Branch Received";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            this.functionForNotificationMessage("Successful!", "Status Change Successful");
                            this.fetchDataFromAPI('Cheque Acknowledgement')
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForDestroyToReturn = (event, rowData, props) => {
        if (this.state.activeItem === "Waiting for Destroy") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                let single_url = backEndServerURL + "/updateChequebookStatus/Destroy Request";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            this.functionForNotificationMessage("Successful!", "Status Change Successful");
                            this.fetchDataFromAPI('Waiting for Destroy')
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    functionForNotificationMessage = (title, message) => {
        this.setState({
            title: title,
            notificationMessage: message,
            alert: true,
        })

    };

    closeFailedModal = () => {
        this.setState({
            failedList: [],
            failedModal: false
        })
    };

    failedListModal = () => {
        const {classes} = this.props;
        return (
            <Dialog
                fullWidth="true"
                maxWidth="md"
                className={classes.modal}
                classes={{paper: classes.dialogPaper}}
                open={this.state.failedModal}>
                <DialogContent className={classes.dialogPaper}>
                    <FailedListModal titleHeader="Cheque Book Delivered Failed List" closeModal={this.closeFailedModal}
                                     failedList={this.state.failedList}/>
                </DialogContent>
            </Dialog>
        )
    };

    actionForDestroy = (event, rowData, props) => {
        this.setState({
            disabled:true
        })
        if (this.state.activeItem === "Waiting for Destroy") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let finacleArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    finacleArray.push(allSelectValue[i].id);
                }
                if (finacleArray.length > 0) {
                    let stop_cheque_book = backEndServerURL + "/stopchequenumber/";
                    axios.post(stop_cheque_book, finacleArray, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(response => {
                        if (response.status === 200 && response.data.failedAccountNumberList.length > 0) {
                            this.setState({
                                failedList: response.data.failedAccountNumberList,
                                failedModal: true,
                            })
                        }
                        if (response.status === 200 && response.data.successful > 0) {
                            this.functionForNotificationMessage("Successful!", `${response.data.successful} Status Update Successful and ${response.data.failed} Status Update Failed`);
                        }
                        this.fetchDataFromAPI('Waiting for Destroy')
                    }).catch(error => {
                        this.functionForNotificationMessage("Failed!", "Cheque Book Destroy Failed");
                        console.log(error);
                    });
                }
            }
        }
    };

    tableChoice() {
        const {classes} = this.props;
        if (this.state.loader) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loader}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else if (this.state.activeItem === "All" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'All')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "Cheque Acknowledgement" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.branchReceivedColumns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                disabled={this.state.disabled}
                                                onClick={(event, rowData) => this.actionForBranchReceivedToDelivered(event, rowData, props)}
                                            >
                                                Delivered
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForBranchReceivedToReturn(event, rowData, props)}
                                            >
                                                Return
                                            </Button>
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Cheque Acknowledgement')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Waiting for Destroy" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.branchReceivedColumns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForDestroy(event, rowData, props)}
                                            >
                                                Destroy
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForDestroyToReturn(event, rowData, props)}
                                            >
                                                Return
                                            </Button>
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Destroy')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        }

    }
}

export default withStyles(styles)(BomChecqueBookManage);
