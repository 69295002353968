import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../Common/Constant";
import Functions from '../../../Common/Functions';
import GridList from "@material-ui/core/GridList/index";
import {jsonFormForMerchant} from '../WorkflowJsonFormRashed'
import Grid from "@material-ui/core/Grid/index";
import CommonJsonFormComponent from '../../JsonForm/CommonJsonFormComponent';
import GridItem from "../../Grid/GridItem.jsx";
import Table from "../../Table/Table";
import axios from 'axios/index';
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import ConfirmAlert from "../CommonComponent/ConfirmAlert";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};


let mouRemarks = [
    {
        "varName": "mouRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];

class splitApproval extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            inputData: {},
            varValue: {},
            //showValue: true,
            getDeferalList: [],
            defferalData: false,
            getData: false,
            images: [],
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            hombuRemarks: false,
            getRemarkList: [],
            getDocument: false,
            documentList: [],
            confirmAlert: false,
            actionType: "",
            errorModalBoolean: false,
            errorTittle: "",
        };


    }

    modalClose = () => {
        this.props.closeModal();
    };


    componentDidMount() {
        console.log(this.props.appId);

        let url = backEndServerURL + "/variables/" + this.props.appId;

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let inputData = response.data;
                let varValue = response.data;


                this.setState({
                    varValue: varValue,
                    inputData: inputData,
                    getData: true
                });

                let documentsUrl = backEndServerURL + "/case/files/" + this.props.appId;
                axios.get(documentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        this.setState({
                            documentList: response.data,
                            getDocument: true
                        });

                        let defferalUrl = backEndServerURL + "/case/deferral/" + this.props.appId;

                        axios.get(defferalUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {

                                console.log(response.data);
                                if (response.data == '') {
                                    console.log('empty')
                                } else {
                                    let deferealData = [];

                                    response.data.map((deferal) => {

                                        var status;
                                        if (deferal.status === "ACTIVE") {
                                            status = "Approved"
                                        } else if (deferal.status === "APPROVAL_WAITING") {
                                            status = "NOT APPROVED"
                                        } else {
                                            status = "Not Approved";
                                        }

                                        deferealData.push(this.createTableForDeferal(deferal.id, deferal.type,deferal.deferalCategory,deferal.deferalSubCategory,deferal.dueDate, deferal.appliedBy, deferal.applicationDate,deferal.deferalDstId,deferal.deferalRmCode, status))
                                    });

                                    this.setState({
                                        getDeferalList: deferealData,
                                        defferalData: true
                                    })
                                }

                            })
                            .catch((error) => {
                                console.log(error)
                            })

                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            });

        let remarksUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;

        axios.get(remarksUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                let remarkData = [];

                response.data.map((res) => {

                    remarkData.push(this.createTableForRemarks(res.remarks, res.createByUserName, res.applicationRemarksDate, res.createByUserRole))

                });

                this.setState({
                    getRemarkList: remarkData,
                    hombuRemarks: true
                });

                console.log(this.state.getRemarkList)


            })
            .catch((error) => {
                console.log(error)
            })
    }

    renderRemarks = () => {

        return (

            CommonJsonFormComponent.renderJsonForm(this.state, mouRemarks, this.updateComponent)

        )
    };


    updateComponent = () => {
        this.forceUpdate();
    };

    renderFormWithData = () => {
        if (this.state.getData) {
            return CommonJsonFormComponent.renderJsonForm(this.state, jsonFormForMerchant, this.updateComponent)
        }
    };

    createTableForRemarks = (remarks, name, a, b) => {
        return ([
            remarks, name, a, b
        ])
    };

    renderRemarksTable = () => {

        if (this.state.getRemarkList.length > 0) {
            return (
                <div style={{marginBottom: '20px', marginTop: '20px'}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b style={{padding: '20px 10px 10px'}}>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarkList}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>
            )
        }
    };


    createTableForDeferal = (id, type, category,subCategory,dueDate, appliedBy, applicationDate, dstId,rmCode,status) => {
        return ([
            type, category,subCategory,dueDate, appliedBy, applicationDate, dstId,rmCode,status
        ])
    };

    renderDeferalApprovalTableData = () => {

        if (this.state.defferalData === true && this.state.getDeferalList.length > 0) {

            return (
                <div style={{marginTop: '10px'}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b style={{padding: "10px"}}>Deferral List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type","Category","Expire Days", "Expire Date", "Raise By", "Raise Date","DST ID","RM Code", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        }
    };
    variableSetFunction = (object) => {
        let variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    loading: false,
                    alowApiCall: true
                })
            })
            .catch((error) => {
                console.log(error)
            })

    };

    approveHandler = (actionType) => {
        this.setState({
            loading: true
        });



            let variablesUrl = backEndServerURL + "/variables/" + this.props.appId;

            let postData = this.state.inputData;
            postData.mou_approval = "APPROVED";

            axios.post(variablesUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    console.log('Approved');

                    let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                    axios.get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log('Case Route Successful');
                            this.modalClose();

                            this.setState({
                                title: "Successfull!",
                                // notificationMessage: "Approved Successfully!",
                                notificationMessage: "Successfully Routed!",
                                alert: true

                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        })

                })
                .catch((error) => {
                    console.log(error)
                });

            if (this.state.inputData.mouRemarks !== null) {

                var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.mouRemarks + "/" + this.props.appId;
                axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }


    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    returnHandler = (e) => {
        e.preventDefault();

        let deferalCheckUrl = backEndServerURL + "/variables/" + this.props.appId;
        let mou_approval = "RETURN";

        axios.post(deferalCheckUrl, {mou_approval: mou_approval}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                axios.get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        this.modalClose();
                        this.setState({
                            title: "Successfull!",
                            // notificationMessage: "Return Successfully!",
                            notificationMessage: "Successfully Routed!",
                            alert: true

                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            });

        if (this.state.inputData.mouRemarks !== null) {
            var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.mouRemarks + "/" + this.props.appId;
            axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    };
    validationForHandleConfirm = (actionType) => {
        this.setState({
            loading: true,
        });
        let dependencyField = [];
        if (actionType === "NOTAPPROVED" || actionType === "RETURN_TO_CS") {
            dependencyField.push({

                "varName": "mouRemarks",
                "type": "textArea",
                "required": true,
                "label": "  Remarks",
                "grid": 12
            })

        }
        this.setState({
            loading: false,
        });

        this.setState({
            loading: false,
            confirmAlert: true,
            actionType: actionType
        })


    };
    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()

        {
            this.validationForHandleConfirm(type)
        }


    };
    closeConfirmAlert = (data) => {
        this.setState({
            confirmAlert: false
        });
        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "APPROVED") {
            this.approveHandler("APPROVED")
        } else if (data === "YES" && this.state.actionType === "RETURN_TO_CS") {
            this.returnHandler("RETURN_TO_CS")
        }

    };
    renderSubmitButton = () => {
        if (this.state.getData) {

                return (
                    <Grid item xs={12}>

                        <div>
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}

                                type='button' value='add more'
                                onClick={(event) => this.handleCommon(event, "APPROVED")}

                            >Approve
                            </button>


                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}
                                checkerApproval

                                type='button' value='add more'
                                onClick={(event) => this.handleCommon(event, "RETURN_TO_CS")}

                            >Return
                            </button>
                        </div>


                    </Grid>

                )

        } else {
            return (
                <div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
            )
        }


    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <GridItem xs={12} sm={12} md={12}>
                    <Grid container spacing={0}>
                        {/* <div> */}

                        {
                            this.renderFormWithData()
                        }

                        {
                            this.renderDeferalApprovalTableData()
                        }

                        {
                            this.renderRemarksTable()
                        }


                        {this.renderRemarks()}
                        {/*
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                marginRight: '10px',
                                marginTop: '10px',

                            }}

                            onClick={this.approveHandler}

                        >
                            Approve
                        </button>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                marginTop: '10px',
                            }}
                            onClick={this.returnHandler}

                        >
                            Return
                        </button>*/}
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.confirmAlert}>
                            <DialogContent className={classes.dialogPaper}>
                                <ConfirmAlert closeModal={this.closeConfirmAlert}/>

                            </DialogContent>
                        </Dialog>
                        {this.renderSubmitButton()}
                        {
                            this.renderNotification()
                        }
                    </Grid>
                </GridItem>
            </GridList>

        )

    }


}

export default withStyles(styles)(splitApproval);
