import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles/index";
import CommonJsonFormComponent from '../../JsonForm/CommonJsonFormComponent';
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import GridItem from "../../Grid/GridItem.jsx";
import Table from "../../Table/Table";
import axios from 'axios/index';
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import ShowDocument from "./ShowDocuments";
import DialogContent from "@material-ui/core/DialogContent/index";
import {Dialog} from "@material-ui/core/index";
import loader from "../../../Static/loader.gif";
import MyValidation from "../../JsonForm/MyValidation";
import WaiverList from "./MerchantWaiverList";
import DedupFullResultButton from "../CommonComponent/DedupFullResultButton";

const HombujsonFormReadOnly = [
    {
        "label": "Merchant type",
        "type": "text",
        "varName": "merchantType",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Merchant Name",
        "type": "text",
        "varName": "merchantName",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Proprietor Name",
        "type": "text",
        "varName": "proprietorName",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Key Contact Person Number",
        "type": "text",
        "varName": "mobileNumber",
        "grid": 2,
        "readOnly": true
    },

    {
        "label": "Address",
        "type": "text",
        "varName": "address",
        "grid": 2,
        "multiline": true,
        "readOnly": true
    },
    {
        "label": "DST Name",
        "type": "text",
        "varName": "dstName",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "DST ID",
        "type": "text",
        "varName": "dstID",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "POS Model",
        "type": "text",
        "varName": "posModel",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "SIM",
        "type": "text",
        "varName": "sim",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "POS Serial",
        "type": "text",
        "varName": "posSerial",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "IMEI",
        "type": "text",
        "varName": "imei",
        "grid": 2,
        "readOnly": true
    },
];


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let hombuRemarks = [
    {
        "varName": "hombuRemarks",
        "type": "textArea",
        "required": true,
        "label": "Remarks",
        "grid": 12
    }];


class HombuApproval extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: true,
            getDeferalList: [],
            getData: false,
            title: "",
            notificationMessage: "",
            alert: false,
            defferalData: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            documentModal: false,
            remarks: false,
            getRemarkList: [],
            SelectedData: false,
            selectedDate: {},
            loading: true,
            disabled: false,
        }
    }

    modalClose = () => {
        this.props.closeModal();
    };

    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };

    componentDidMount() {
        console.log(this.props.appId);

        let url = backEndServerURL + "/variables/" + this.props.appId;

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.hombuRemarks = null;
                this.setState({
                    varValue: response.data,
                    inputData: this.copyJson(response.data),
                    getData: true,
                    loading: false
                });


                let defferalUrl = backEndServerURL + "/case/deferral/" + this.props.appId;

                axios.get(defferalUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        console.log(response.data);
                        if (response.data == '') {
                            console.log('empty')
                        } else {
                            let deferealData = [];
                            var status = "";

                            response.data.map((deferal) => {
                                if (deferal.status === "ACTIVE") {
                                    status = "Approved"
                                } else if (deferal.status === "NOTAPPROVED") {
                                    status = "NOT APPROVED"
                                } else {
                                    status = deferal.status;
                                }
                                deferealData.push(this.createTableForDeferal(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));
                            });
                            this.setState({
                                getDeferalList: deferealData,
                                defferalData: true
                            })
                        }

                    })
                    .catch((error) => {
                        console.log(error)
                    });

                console.log(response.data)
            })
            .catch((error) => {
                console.log(error)
            });

        let remarksUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;

        axios.get(remarksUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                if (response.data.length > 0) {
                    let remarkData = [];

                    response.data.map((res) => {

                        remarkData.push(this.createTableForRemarks(res.remarks, res.createByUserName, res.applicationRemarksDate, res.createByUserRole))

                    });

                    this.setState({
                        getRemarkList: remarkData,
                        remarks: true
                    });

                    console.log(this.state.getRemarkList)
                } else {
                    this.setState({
                        remarks: false
                    })
                }


            })
            .catch((error) => {
                console.log(error)
            })
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderFormWithData = () => {
        if (this.state.getData) {
            return CommonJsonFormComponent.renderJsonForm(this.state, HombujsonFormReadOnly, this.updateComponent)
        }
    };

    renderRemarks = () => {

        return (

            CommonJsonFormComponent.renderJsonForm(this.state, hombuRemarks, this.updateComponent)

        )
    };


    createTableForRemarks = (remarks, name, a, b) => {
        return ([
            remarks, name, a, b
        ])
    };

    renderRemarksTable = () => {

        if (this.state.remarks === true && this.state.getRemarkList.length > 0) {
            return (
                <div style={{marginBottom: '20px'}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b style={{padding: '20px 10px 10px'}}>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarkList}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>
            )
        }
    };
    createTableForDeferal = (id, type, category,subCategory,dueDate, appliedBy, applicationDate, dstId,rmCode,status) => {

        return ([
            type, category,subCategory,dueDate, appliedBy, applicationDate, dstId,rmCode,status
        ])

    };




    renderDeferalApprovalTableData = () => {

        if (this.state.defferalData === true && this.state.getDeferalList.length > 0) {

            return (
                <div style={{marginBottom: '10px'}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b style={{padding: "10px"}}>Deferral List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Document Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        }
    };


    hombuApprovalHandler = () => {
        this.setState({
            disabled: true
        });

        if (this.state.inputData.hombuRemarks !== null) {

            var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.hombuRemarks + "/" + this.props.appId;

            axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log('remarks');
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        let deferalCheckUrl = backEndServerURL + "/variables/" + this.props.appId;
        let hombu_approval = "APPROVED";
        axios.post(deferalCheckUrl, {hombu_approval: hombu_approval}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                axios.get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.setState({
                            title: "Successful!",
                          //  notificationMessage: "Approved Successfully!",
                            notificationMessage: "Successfully Routed!",
                            alert: true,
                            disabled: false
                        });
                        this.modalClose();
                    })
                    .catch((error) => {
                        this.setState({
                            disabled: false
                        });
                        console.log(error)
                    })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    disabled: false
                });
            });
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };

    open = (e) => {
        e.preventDefault();
        this.setState({
            documentModal: true
        })
    };

    documentModal = () => {
        this.setState({
            documentModal: false
        })
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    returnHandler = (e) => {
        e.preventDefault();
        let error = MyValidation.defaultValidation(hombuRemarks, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                disabled: true
            });

            if (this.state.inputData.hombuRemarks !== null) {
                var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.hombuRemarks + "/" + this.props.appId;

                axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log('remarks');
                        console.log(response.data)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }

            let deferalCheckUrl = backEndServerURL + "/variables/" + this.props.appId;
            let hombu_approval = "RETURN";

            let deferalApproval = backEndServerURL + "/deferral/approval";
            let appId = this.props.appId;
            let approval = "REJECT";
            let mbu_deferal = "NO";

            axios.post(deferalApproval, {
                appId: appId,
                approval: approval,
                mbu_deferal: mbu_deferal
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    axios.post(deferalCheckUrl, {hombu_approval: hombu_approval}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                            axios.get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    console.log('Case Route Successful');

                                    this.modalClose();
                                    this.setState({
                                        title: "Successful!",
                                       // notificationMessage: "Return Successfully!",
                                        notificationMessage: "Successfully Routed!",
                                        alert: true,
                                        disabled: false
                                    })
                                })
                                .catch((error) => {
                                    this.setState({
                                        disabled: false
                                    });
                                    console.log(error)
                                })
                        })
                        .catch((error) => {
                            this.setState({
                                disabled: false
                            });
                            console.log(error)
                        })

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        disabled: false
                    });
                });
        }
    };

    functionForWaiver = () => {
        if (this.state.getData) {
            return (
                <>
                    <WaiverList appId={this.props.appId}/>
                </>
            )
        }
    };
    renderSDNDedupeResult = () => {
        if (  this.state.getData) {
            return (
                <DedupFullResultButton  merchantDedup={true} closeModal={this.closeModalForSdnResult}
                                       branchName={this.props.branchName}
                                       inputData={this.state.inputData}
                                       subServiceType="Joint Account"
                                       category="CASA"
                                       serviceType="Account Opening"
                                       subCategory="A/C Opening"
                                       freeFlag2={this.props.freeFlag2 === "externalUserClosedRequest" ? true : false}
                                       serviceType={this.props.serviceType!==undefined?this.props.serviceType:this.state.inputData.serviceType}
                                       customerName={this.props.customerName}
                                       accountNumber={this.props.accountNumber}
                                       cbNumber={this.props.cbNumber}
                                       solId={this.props.solId} globalSearch={true}
                                       closeIcon={this.closeModalForSdnResult}
                                       appId={this.props.appId!==undefined?this.props.appId:this.state.caseId}/>


            )
        }

    };
    render() {
        const {classes, onClose} = this.props;
        if (this.state.getData) {
            return (
                <Grid Container spacing={0}>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >Merchant Onboarding Request<a><CloseIcon onClick={this.props.closeModal} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a></h4>
                            </CardHeader>
                            <CardBody>
                                {   this.renderSDNDedupeResult()}
                                <div>
                                    <Grid container>

                                        <ThemeProvider theme={theme}>
                                            {
                                                this.renderFormWithData()
                                            }
                                            <br/>

                                            <Grid item xs={12}>
                                                <button
                                                    style={{
                                                        marginTop: "10px",
                                                        marginBottom: '10px'
                                                    }}
                                                    className="btn btn-outline-primary btn-sm"
                                                    type='submit'
                                                    onClick={this.open}
                                                >
                                                    Preview Document
                                                </button>
                                            </Grid>

                                            <Dialog
                                                fullWidth="true"
                                                maxWidth="md"
                                                open={this.state.documentModal}>
                                                <DialogContent>
                                                    <ShowDocument closeModal={this.documentModal}
                                                                  appId={this.props.appId}/>
                                                </DialogContent>
                                            </Dialog>
                                            <Grid item xs='12'>
                                                {
                                                    this.renderDeferalApprovalTableData()
                                                }
                                            </Grid>
                                            <Grid item xs='12'>
                                                {this.functionForWaiver()}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {
                                                    this.renderRemarksTable()
                                                }
                                            </Grid>

                                            <Grid item xs={12}>

                                                {this.renderRemarks()}

                                            </Grid>

                                            {
                                                this.renderNotification()
                                            }

                                            <Grid item xs='12'>
                                                {/* <center> */}
                                                <button
                                                    className="btn btn-outline-primary btn-sm"
                                                    style={{
                                                        // verticalAlign: 'middle',
                                                        marginRight: '10px',
                                                        marginTop: '10px',
                                                    }}
                                                    onClick={this.hombuApprovalHandler}
                                                    disabled={this.state.disabled}
                                                >
                                                    Approve
                                                </button>
                                                <button
                                                    className="btn btn-outline-primary btn-sm"
                                                    style={{
                                                        marginTop: '10px',
                                                    }}
                                                    onClick={this.returnHandler}
                                                    disabled={this.state.disabled}
                                                >
                                                    Return
                                                </button>
                                                {/* </center> */}
                                            </Grid>
                                        </ThemeProvider>
                                    </Grid>
                                </div>

                            </CardBody>
                        </Card>
                    </GridItem>
                </Grid>
            );
        } else {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        }
    }
}

export default withStyles(styles)(HombuApproval);