import React from "react";
import CheckBox from "@material-ui/core/Checkbox/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";


class CheckboxComponent {
    static handleChange(event, state, update, onKeyDownForDedup, field) {

        state.inputData[event.target.name] = event.target.checked;
        update();
        // console.log(event.target.checked)
        // if( (event.target.checked)===true){
        onKeyDownForDedup(event, field.varName);
        // }
    }

    static returnDefaultValue = (state, field) => {
        if (field.readOnly === true) {
            return state.varValue[field.varName];
        }


        // if (state.showValue){
        //     if (state.varValue[field.varName] === -1)
        //         return state.inputData[field.varName];
        //     state.inputData[field.varName] = state.varValue[field.varName];
        //     state.varValue[field.varName] = -1;
        // }
        return state.inputData[field.varName];
    };
    static asteriksFunction = (field) => {
        if (field.required === true && field.asteriks === undefined) {
            return (
                field.label + " *"

            )
        } else {
            return (
                field.label
            )
        }
    };

    static checkbox(state, update, field, onKeyDownForDedup) {
        return (
            <FormControlLabel

                control={

                    <CheckBox
                        helperText={state.errorMessages[field.varName]}
                        error={state.errorArray[field.varName]}
                        style={field.readOnly === true ? {
                            color: '#212F3D',
                            background: '#f8f9fa',
                            marginTop: "5px"
                        } : {marginTop: "5px"}}

                        //helperText={this.state.errorMessages[field.varName]}
                        //error={this.state.errorArray[field.varName]}
                        checked={this.returnDefaultValue(state, field)}
                        disabled={field.readOnly}
                        required={field.required} key={field.varName}
                        //variant="outlined"
                        defaultValue={this.returnDefaultValue(state, field)}
                        name={field.varName}
                        label={field.label}
                        InputProps={{
                            //readOnly: field.readOnly
                        }}
                        onChange={(event) => this.handleChange(event, state, update, onKeyDownForDedup, field)}/>

                }

                label={this.asteriksFunction(field)}

            />
        )
    }

}

export default CheckboxComponent;