import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import React from "react";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import {Paper} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CardBody from "../Card/CardBody";
import {withStyles} from "@material-ui/core/styles";
import UsersAllAudit from "./UsersAllAudit";
import UsersLoggedIn from "./UsersLoggedIn";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={2}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        backgroundColor: theme.palette.background.paper,
    },
    tabRoot: {
        minWidth: '50%',
        fontSize: 20,
    },
});

class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: true,
            tabMenuSelect: 'LOGGED IN USERS',
            getData: false,


        }
    }


    renderSearchForm = () => {
        if (this.state.tabMenuSelect === 'ALL USERS') {
            return (
                <UsersAllAudit/>
            )
        } else if (this.state.tabMenuSelect === 'LOGGED IN USERS') {
            return (
                <UsersLoggedIn/>
            )

        } else {

        }

    };
    handleChange = (name, value) => {
        this.setState({
            tabMenuSelect: value
        })

    };

    render() {

        const {classes} = this.props;

        return (
            <Card>
                <paper>

                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                        <h4 style={{color: "white"}} ><b>User List</b></h4>

                    </CardHeader>
                </paper>
                <CardHeader color="red">
                    <center>
                        <h4 color="red" className={classes.cardTitleWhite}>
                            <Paper square>
                                <Tabs indicatorColor="secondary"
                                      textColor="secondary"
                                      aria-label="icon label tabs example"
                                      value={this.state.tabMenuSelect}
                                      onChange={this.handleChange}>
                                    <Tab key="LOGGED IN USERS" value="LOGGED IN USERS"
                                         label="LOGGED IN USERS" classes={{root: classes.tabRoot}}/>

                                    <Tab key="ALL USERS" value="ALL USERS"
                                         label="ALL USERS" classes={{root: classes.tabRoot}}/>

                                </Tabs>
                            </Paper>

                        </h4>
                    </center>
                </CardHeader>

                <Paper square>
                    <CardBody>
                        {this.renderSearchForm()}
                    </CardBody>
                </Paper>
            </Card>


        )

    }
}

export default withStyles(styles, {withTheme: true})(UserList);