import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles/index";
import CommonJsonFormComponent from '../../JsonForm/CommonJsonFormComponent';
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios/index';
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import DialogContent from "@material-ui/core/DialogContent/index";
import {Dialog} from "@material-ui/core/index";
import SingleImageShow from "../CASA/SingleImageShow";
import ShowDocument from "./ShowDocuments";
import Table from "../../Table/Table";
import MerchantIndexing from "./MerchantIndexing";
import MyValidation from "../../JsonForm/MyValidation";
import FileMappingEdit from "../CommonComponent/FileMappingEdit";
import {dstJsonForm} from '../WorkflowJsonFormRashed'
import MissingFlies from "./MissingFlies";
import loader from "../../../Static/loader.gif";
import WaiverList from "./MerchantWaiverList";
import {CSJsonFormForFDRService} from "../WorkflowJsonForm4";
import ConfirmAlert from "../CommonComponent/ConfirmAlert";
import DedupFullResultButton from "../CommonComponent/DedupFullResultButton";
import ErrorModal from "../CommonComponent/ErrorModal";


let dstRemarks = [
    {
        "varName": "dstRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];

const proprietorDocuments = [
    {label: 'Trade License'},
    {label: 'National ID or Passport'},
    {label: 'Photo'},
    {label: 'E-TIN'},
    {label: 'Bank Statement'},
    //{label: 'Visiting Card'},
    {label: 'Others'},
    {label: 'Merchant application form 1'},
    {label: 'Merchant application form 2'},
    {label: 'Merchant application form 3'},
    {label: 'Merchant application form 4'},
    {label: 'Merchant application form 5'},
    {label: 'Merchant application form 6'},
    {label: 'Merchant application form 7'},
    {label: 'Merchant application form 8'}
];

const partnershipDocuments = [
    {label: 'Trade License'},
    {label: 'National ID or Passport for all partners'},
    {label: 'Photo of all partners'},
    {label: 'Partnership Deed'},
    {label: 'Partnership Resolution Letter'},
    {label: 'E-TIN Certificates of the partners'},
    {label: 'Bank Statement'},
    //{label: 'Visiting Card'},
    {label: 'Others'},
    {label: 'Merchant application form 1'},
    {label: 'Merchant application form 2'},
    {label: 'Merchant application form 3'},
    {label: 'Merchant application form 4'},
    {label: 'Merchant application form 5'},
    {label: 'Merchant application form 6'},
    {label: 'Merchant application form 7'},
    {label: 'Merchant application form 8'}
];

const llcDocuments = [
    {label: 'Trade License'},
    {label: 'Memorandum, Certificate Incorporation, Articles of Association & Form XII'},
    {label: 'Certificate of commencement of Business (For Public Ltd Company)'},
    {label: 'Photo (2 copies) of the authorized person and Directors having 20% or more shares'},
    {label: 'Board Resolution Letter'},
    {label: 'NID or Passport'},
    {label: 'E-TIN'},
    {label: '18-B from Bangladesh Bank'},
    {label: 'Permission from ministry of industry'},
    {label: 'Certificate of filling with register Joint Stock Company'},
    {label: 'Form QA-22'},
    {label: 'Bank Statement'},
   // {label: 'Visiting Card'},
    {label: 'Others'},
    {label: 'Merchant application form 1'},
    {label: 'Merchant application form 2'},
    {label: 'Merchant application form 3'},
    {label: 'Merchant application form 4'},
    {label: 'Merchant application form 5'},
    {label: 'Merchant application form 6'},
    {label: 'Merchant application form 7'},
    {label: 'Merchant application form 8'}
];

const othersDocuments = [
    {label: 'Certificate of registration'},
    {label: 'Certificate of NGO Bureau or Other Regulatory'},
    {label: 'Certificate of Micro Credit Regulatory Authority or Other regulatory'},
    {label: 'Copy of By-Laws or Constitution'},
    {label: 'List of executive body'},
    {label: 'Memorandum & Article of Association'},
    {label: 'Board Resolution or Extract'},
    {label: 'Photo'},
    {label: 'NID or Passport'},
    {label: 'Bank Statement'},
    //{label: 'Visiting Card'},
    {label: 'Others'},
    {label: 'Merchant application form 1'},
    {label: 'Merchant application form 2'},
    {label: 'Merchant application form 3'},
    {label: 'Merchant application form 4'},
    {label: 'Merchant application form 5'},
    {label: 'Merchant application form 6'},
    {label: 'Merchant application form 7'},
    {label: 'Merchant application form 8'}
];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class DstCaseStart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            inputData: {},
            fileUploadData: {},
            showValue: false,
            caseId: "",
            title: "",
            notificationMessage: "",
            alert: false,
            uploadModal: false,
            getMappingAllImage: false,
            uploadButtonClick: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            getData: false,
            dob: '',
            documentModal: false,
            preview: false,
            getMerchant: false,
            remarks: false,
            getRemarkList: [],
            getDeferalList: [],
            defferalData: false,
            attached: false,
            getSplitFile: [],
            missing: false,
            previewDocument: false,
            confirmation: false,
            handleSubmit: false,
            loading: true,
            disabled: false,
            taskTitle: this.props.taskTitle,
            actionType: false,
            confirmAlert: false,
        };

        this.submitHandler = this.submitHandler.bind(this);
    }

    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null' || clone[prop] === null || clone[prop] === undefined || clone[prop] === '-1')
                delete clone[prop];
        return clone;
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    close = (e) => {
        e.preventDefault();
        this.props.closeModal = false
    };

    modalClose = () => {
        this.props.closeModal();
    };


    componentDidMount() {


        if (this.props.appId !== undefined) {

            if (this.state.taskTitle === "line_manager_approval") {
                dstJsonForm.map((form) => {
                    if (!('readOnly' in form)) {
                        form.readOnly = true;
                        form.addReadOnly = true
                    }
                });
                dstRemarks[0].required = true
            } else {
                dstJsonForm.map((form) => {
                    console.log(form);
                    if ('addReadOnly' in form) {
                        delete form['readOnly'];
                        delete form['addReadOnly'];
                    }
                });
                dstRemarks[0].required = false;
            }

            let url = backEndServerURL + "/variables/" + this.props.appId;

            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    response.data.dstRemarks = null;
                    this.setState({
                        varValue: response.data,
                        inputData: this.copyJson(response.data),
                        showValue: true,
                        caseId: this.props.appId,
                        getData: true,
                        preview: true,
                        loading: false
                    });

                    // this.state.inputData.dstRemarks = null

                    let defferalUrl = backEndServerURL + "/case/deferral/" + this.props.appId;

                    axios.get(defferalUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            console.log(response.data);
                            if (response.data == '') {
                                console.log('empty')
                            } else {
                                let deferealData = [];

                                response.data.map((deferal) => {

                                    var status;
                                    if (deferal.status === "ACTIVE") {
                                        status = "Approved"
                                    } else if (deferal.status === "APPROVAL_WAITING") {
                                        status = "Pending"
                                    } else {
                                        status = "Not Approved";
                                    }

                                    deferealData.push(this.createTableForDeferal(deferal.id, deferal.type,deferal.deferalCategory,deferal.deferalSubCategory,deferal.dueDate, deferal.appliedBy, deferal.applicationDate,deferal.deferalDstId,deferal.deferalRmCode, status))
                                });

                                this.setState({
                                    getDeferalList: deferealData,
                                    defferalData: true
                                })
                            }

                        })
                        .catch((error) => {
                            console.log(error)
                        })

                })
                .catch((error) => {
                    console.log(error)
                });

            let remarksUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;

            axios.get(remarksUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    if (response.data.length > 0) {

                        let remarkData = [];

                        response.data.map((res) => {

                            remarkData.push(this.createTableForRemarks(res.remarks, res.createByUserName, res.applicationRemarksDate, res.createByUserRole))

                        });

                        this.setState({
                            getRemarkList: remarkData,
                            remarks: true,
                        });

                        console.log(this.state.getRemarkList)
                    } else {
                        this.setState({
                            remarks: false
                        })
                    }


                })
                .catch((error) => {
                    console.log(error)
                });

            let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log("console from missing files");
                    console.log(response.data);


                    this.setState({
                        getSplitFile: response.data,
                        attached: false
                    })

                })
                .catch((error) => {
                    console.log(error);
                })
        } else {

            let url = backEndServerURL + "/startCase/dst_case_start";

            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let inputData=this.props.merchantValue;
                    let varValue=this.props.merchantValue;
                    //inputData.freeFlag5=this.props.merchantValue.dba;
                   // varValue.freeFlag5=this.props.merchantValue.dba;
                    console.log(response.data.id);
                    this.DedupDataSaveApi("Joint Account",response.data.id)
                    this.setState({
                        caseId: response.data.id,
                        caseIdOriginal:response.data.caseId,
                         inputData: this.copyJson(inputData),
                        varValue: varValue,
                        showValue: true,
                        getMerchant: true,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error)
                })

        }
    }
    DedupDataSaveApi = (subServiceType,appId) => {

        if (subServiceType === "INDIVIDUAL" || subServiceType === "Individual A/C") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "appId": appId,
                "mandateDedupData": this.props.mandateindividualDedupData,
                "beneficiaryDedupData": this.props.beneficiaryindividualDedupData,
                "individualDedupData": this.props.individualDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(" dedup save data");
                    console.log(response.data);
                    this.setState({
                        individualDataSaveId: response.data
                    });

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Something went wrong,Please Try Again !",
                        loading: false,
                        getData: false,
                    });
                    return 0;
                })

        } else if (subServiceType === "Joint Account") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "appId": appId,
                "jointDedupData": this.props.jointDedupData,
                "mandateDedupData": this.props.mandateindividualDedupData,
                "beneficiaryDedupData": this.props.beneficiaryindividualDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(" dedup save data");
                    console.log(response.data);
                    this.setState({
                        jointDataSaveId: response.data
                    });

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Something went wrong,Please Try Again",
                        loading: false,
                        getData: false,
                    });
                    return 0;
                })

        } else if (subServiceType === "Proprietorship A/C" || subServiceType === "NONINDIVIDUAL") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "appId": appId,
                "mandateDedupData": this.props.mandateindividualDedupData,
                "beneficiaryDedupData": this.props.beneficiaryindividualDedupData,
                "individualDedupData": this.props.individualDedupData,
                "companyDedupData": this.props.companyDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    /*axios.post(Dedupurl, {
                        "companyDedupData": this.props.companyDedupData,
                        "dedupType": subServiceType
                    }, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
                        .then((response) => {
                            this.setState({
                                companyDataSaveId: response.data
                            })
                        })
                        .catch((error) => {
                             console.log(error)
                        })*/
                    console.log(" dedup save data r");
                    console.log(response.data);
                    this.setState({
                        individualDataSaveId: response.data
                    });

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Something went wrong,Please Try Again !",
                        loading: false,
                        getData: false,
                    });
                    return 0;
                })

        } else if (subServiceType === "Company Account") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "appId":appId,
                "jointDedupData": this.props.jointDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    axios.post(Dedupurl, {
                        "appId": appId,
                        "companyDedupData": this.props.companyDedupData,
                        "dedupType": subServiceType
                    }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            this.setState({
                                companyDataSaveId: response.data
                            });

                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                errorModalBoolean: true,
                                errorTittle: "Something went wrong,Please Try Again !",
                                loading: false,
                                getData: false,
                            });
                            return 0;
                        });
                    console.log(" dedup save data");
                    console.log(response.data);
                    this.setState({
                        jointDataSaveId: response.data
                    })
                })
                .catch((error) => {
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Something went wrong,Please Try Again !",
                        loading: false,
                        getData: false,
                    });
                    return 0;
                    console.log(error)
                })

        } else {
            alert("please type select")
        }
    };
    createTableForRemarks = (remarks, name, a, b) => {
        return ([
            remarks, name, a, b
        ])
    };

    renderRemarksTable = () => {

        if (this.state.remarks === true && this.state.getRemarkList.length > 0) {
            return (
                <div style={{marginBottom: '20px'}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b style={{padding: '20px 10px 10px'}}>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarkList}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>
            )
        }
    };


    createTableForDeferal = (id, type, category,subCategory,dueDate, appliedBy, applicationDate, dstId,rmCode,status) => {
        return ([
            type, category,subCategory,dueDate, appliedBy, applicationDate, dstId,rmCode,status
        ])
    };

    renderDeferalApprovalTableData = () => {

        if (this.state.defferalData === true && this.state.getDeferalList.length > 0) {

            return (
                <div style={{marginBottom: '10px'}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b style={{padding: "10px"}}>Deferral List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type","Category","Expire Days", "Expire Date", "Raise By", "Raise Date","DST ID","RM Code", "Status"]}                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        }
    };

    open = (e) => {
        e.preventDefault();
        this.setState({
            documentModal: true
        })
    };

    missingOpen = (e) => {
        e.preventDefault();
        this.setState({
            missingOnlyFileList: true
        })
    };

    documentModal = () => {
        this.setState({
            documentModal: false,
            missing: false,
            missingOnlyFileList: false,
            confirmation: true
        })
    };

    documentMissingModal = () => {
        this.setState({
            missing: false,
            missingOnlyFileList: false,
            confirmation: true
        });
    };

    renderRemarks = () => {

        return (

            CommonJsonFormComponent.renderJsonForm(this.state, dstRemarks, this.updateComponent)

        )
    };

    preview = () => {
        if (this.state.preview) {

            console.log("console from preview function");

            return (
                <div>
                    <button
                        style={{}}
                        className="btn btn-outline-primary btn-sm"
                        type='submit'
                        onClick={this.open}
                    >
                        Preview Attached Document
                    </button>

                    <Dialog
                        fullWidth="true"
                        maxWidth="md"
                        open={this.state.documentModal}>
                        <DialogContent>
                            <ShowDocument closeModal={this.documentModal} appId={this.state.caseId}/>
                        </DialogContent>
                    </Dialog>
                </div>
            )

        }
    };
   caseIdAlertFunction=()=>{
       this.setState({
           missingOnlyFileList:false,
           errorModalBoolean:true,
           errorTittle:"Case Id Is : " +this.state.caseIdOriginal,
       })
   }
    closeCaseIdModal=()=>{
       this.setState({
           errorModalBoolean:false,
        })
        this.handleSubmit()
   }
    submitHandler = (value) => {
       if (this.props.taskTitle === "line_manager_approval") {
            this.setState({
                disabled: true,
                loading: true,
            });
            if (value !== undefined) {
                this.handleSubmit(value)
            } else {
                this.handleSubmit()
            }
        } else {
            this.setState({
                missingOnlyFileList: true,
                 confirmation: false,
                handleSubmit: true
            });
            // this.handleSubmit();
        }


    };


    handleSubmit = (value) => {

        this.setState({
            loading: true
        });
        if (this.state.fileUploadData.scanningFile !== undefined) {
            let fileUploadPath = backEndServerURL + "/case/upload";
            let types = 'Attachments';
            let files = this.state.fileUploadData.scanningFile;
            console.log(files);
            let formData = new FormData();
            formData.append("appId", this.state.appId);
            formData.append("file", files);
            formData.append("type", types);
            axios({
                method: 'post',
                url: fileUploadPath,
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'content-type': 'multipart/form-data'
                }
            })
                .then((response) => {

                })
                .catch((error) => {
                    this.setState({
                        disabled: false,
                        loading:false
                    });
                    console.log(error)
                })
        }

        let postData = this.state.inputData;
        postData.category = "Merchant";
        postData.subCategory = "Onboarding";
        postData.serviceType = "MerchantOnboarding";
        postData.subServiceType = "NewMerchant";
        postData.customerName = this.state.inputData.merchantName;
        if (this.props.getRadioButtonData > 0) {
            postData.selectedCB = this.props.getRadioButtonData;
        }

        postData.appId = this.state.caseId;
        if (this.props.taskTitle === "line_manager_approval" && value !== undefined) {
            postData.line_manager_approval = value
        } else if (this.props.taskTitle === "line_manager_approval" && value === undefined) {
            postData.line_manager_approval = "APPROVED"
        }
        let saveDataDeferralRemarksAndRoute = {};
        saveDataDeferralRemarksAndRoute.map = postData;
        if ((this.state.inputData.dstRemarks !== undefined && this.state.inputData.dstRemarks !== null)) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.dstRemarks;
        }

                let caseRouteUrl = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.caseId;
                axios.post(caseRouteUrl, saveDataDeferralRemarksAndRoute,{headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        if (response.status === 200) {
                            this.modalClose();

                            this.setState({
                                title: "Successful!",
                              //  notificationMessage: "Data Store Successfully!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                disabled: false,
                                loading:false
                            });

                            if (this.props.appId !== undefined) {
                                this.props.closeModal()
                            } else {
                                setTimeout(function () {
                                    window.location.reload()
                                }, 1000);
                            }
                        } else {

                            this.setState({
                                title: "Failed!",
                                notificationMessage: "Something went wrong!",
                                alert: true,
                                disabled: false,
                                loading:false
                            });
                        }
                    })
                    .catch((error) => {
                        this.setState({
                            disabled: false,
                            loading:false
                        });
                        console.log(error)
                    })

    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    missingFiles = () => {

        if (!this.state.uploadModal && this.state.attached) {
            let imageUrl = backEndServerURL + "/case/files/" + this.state.caseId;
            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log("console from missing files");
                    console.log(response.data);


                    this.setState({
                        getSplitFile: response.data,
                        attached: false
                    })

                })
                .catch((error) => {
                    console.log(error);
                })
        }

    };

    missingButton = () => {

        console.log('.....................');
        console.log(this.state.caseId);
        console.log('.....................');
        if (this.state.getSplitFile.length > 0) {

            let documentList = [];

            if (this.state.inputData.merchantType === "Sole Proprietorship") {
                documentList = proprietorDocuments;
            } else if (this.state.inputData.merchantType === "Limited Liability") {

                documentList = llcDocuments
            } else if (this.state.inputData.merchantType === "Partnership") {

                documentList = partnershipDocuments
            } else {

                documentList = othersDocuments;
            }


            return (
                <div>
                    <button
                        style={{}}
                        className="btn btn-outline-primary btn-sm"
                        type='submit'
                        onClick={this.missingOpen}
                    >
                        Missing Files
                    </button>

                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        open={this.state.missingOnlyFileList}>
                        <DialogContent>

                            <MissingFlies caseId={this.state.caseIdOriginal} triggerSubmit={this.caseIdAlertFunction}
                                          triggerConfirmation={this.state.handleSubmit}
                                          closeModal={this.documentMissingModal} documents={documentList}
                                          appId={this.state.caseId}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        open={this.state.missing}>
                        <DialogContent>

                            <MissingFlies caseId={this.state.caseIdOriginal} triggerSubmit={this.documentMissingModal}
                                          triggerConfirmation={this.state.handleSubmit}
                                          closeModal={this.documentMissingModal} documents={documentList}
                                          appId={this.state.caseId}/>
                        </DialogContent>
                    </Dialog>
                </div>
            )

        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };

    closeModal = () => {
        this.setState({

            getMappingAllImage: false,

        })
    };

    uploadModal = () => {
        this.setState({
            uploadModal: true,
            attached: true,
            previewDocument: true,
        })
    };

    closeUploadModal = (data) => {
        this.setState({
            uploadButtonClick: true,
            uploadModal: false,
            getMappingAllImage: false
        })
    };


    renderUploadButton = () => {
        if (this.state.inputData.merchantType !== undefined && this.state.inputData.merchantType !== null && this.state.inputData.merchantType !== "") {

            return (
                <button

                    className="btn btn-outline-primary btn-sm"

                    onClick={this.uploadModal}

                >
                    Upload File
                </button>
            )
        }
    };

    renderAssignedImage = () => {
        if (this.state.previewDocument && this.state.inputData.merchantType !== undefined && this.state.inputData.merchantType !== null && this.state.inputData.merchantType !== "") {
            return (
                <button
                    style={{
                        width: 120,

                    }}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.mappingAllImage}

                >
                    Preview Document
                </button>
            );


        }
    };

    renderMappingImageEditModal = () => {

        console.log("renderMappingImageModal");

        if (this.state.inputData.merchantType === "Sole Proprietorship") {

            return (
                <FileMappingEdit dropDownOption={proprietorDocuments}
                                 serviceType="Merchant"
                                 appId={this.state.caseId}
                                 closeModal={this.closeUploadModal}/>
            )
        } else if (this.state.inputData.merchantType === "Limited Liability") {

            return (
                <FileMappingEdit dropDownOption={llcDocuments}
                                 serviceType="Merchant"
                                 appId={this.state.caseId}
                                 closeModal={this.closeUploadModal}/>
            )
        } else if (this.state.inputData.merchantType === "Partnership") {

            return (
                <FileMappingEdit dropDownOption={partnershipDocuments}
                                 serviceType="Merchant"
                                 appId={this.state.caseId}
                                 closeModal={this.closeUploadModal}/>
            )
        } else {

            return (
                <FileMappingEdit dropDownOption={othersDocuments}
                                 serviceType="Merchant"
                                 appId={this.state.caseId}
                                 closeModal={this.closeUploadModal}/>
            )
        }
    };

    functionForWaiver = () => {
        if (this.state.getData) {
            return (
                <>
                    <br/>
                    <WaiverList appId={this.props.appId}/>
                </>
            )
        }
    };

    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()

        {
            this.validationForHandleConfirm(type)
        }


    };
    closeConfirmAlert = (data, appId) => {
        this.setState({
             confirmAlert: false,
        });

        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "submitHandler") {
             this.submitHandler()
        } else if (data === "YES" && this.state.actionType === "submitHandlerReturn") {

            this.submitHandler("RETURN")
        }
    };
    validationForHandleConfirm = (actionType) => {
        this.setState({
            actionType: false,
            confirmAlert: false,
        });
        if (actionType === "submitHandler")  {
            let error = MyValidation.defaultValidation(dstJsonForm, this.state);
            this.forceUpdate();

            if (error === true) {
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            });
        }
        else if (actionType === "submitHandlerReturn"){
            let error = MyValidation.defaultValidation(dstRemarks, this.state);
                this.forceUpdate();
                if (error === true) {
                    this.setState({disabled: false});
                    return 0
                }


            this.setState({
                actionType: actionType,
                confirmAlert: true,
            });
        }

    };
    renderSDNDedupeResult = () => {
        if (this.state.getMerchant  || this.state.getData) {
            return (
                <DedupFullResultButton  merchantDedup={true}  closeModal={this.closeModalForSdnResult}
                                       branchName={this.props.branchName}
                                       inputData={this.state.inputData}
                                       subServiceType="Joint Account"
                                       category="CASA"
                                       serviceType="Account Opening"
                                       subCategory="A/C Opening"
                                       freeFlag2={this.props.freeFlag2 === "externalUserClosedRequest" ? true : false}
                                       serviceType={this.props.serviceType!==undefined?this.props.serviceType:this.state.inputData.serviceType}
                                       customerName={this.props.customerName}
                                       accountNumber={this.props.accountNumber}
                                       cbNumber={this.props.cbNumber}
                                       solId={this.props.solId} globalSearch={true}
                                       closeIcon={this.closeModalForSdnResult}
                                       appId={this.props.appId!==undefined?this.props.appId:this.state.caseId}/>


            )
        }

    };

    render() {
        const {classes, onClose} = this.props;
        if (this.state.getData) {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Merchant Onboarding Request<a><CloseIcon onClick={this.props.closeModal} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></h4>
                        </CardHeader>
                        <CardBody>
                            { this.renderSDNDedupeResult()}
                            <Grid item xs='12'>
                                <br/>
                            </Grid>
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {
                                        CommonJsonFormComponent.renderJsonForm(this.state, dstJsonForm, this.updateComponent)
                                    }

                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="xl"
                                        open={this.state.getMappingAllImage}>
                                        <DialogContent>
                                            {this.renderMappingImageEditModal()}
                                        </DialogContent>
                                    </Dialog>


                                </ThemeProvider>

                                {this.renderNotification()}
                            </Grid>
                            <br/>


                            <div style={{marginTop: '20px'}}>
                                {
                                    this.renderUploadButton()
                                }

                                &nbsp;&nbsp;

                                {
                                    this.renderAssignedImage()
                                }
                            </div>

                            <div style={{marginTop: '20px'}}>
                                {
                                    this.preview()
                                }

                            </div>

                            <div style={{marginTop: '20px'}}>
                                {
                                    this.missingFiles()
                                }
                                {
                                    this.missingButton()
                                }
                            </div>

                            <Dialog
                                fullWidth="true"
                                maxWidth="xl"
                                open={this.state.uploadModal}>
                                <DialogContent>
                                    <MerchantIndexing
                                        appId={this.state.caseId}
                                        merchantType={this.state.inputData.merchantType}
                                        closeModal={this.closeUploadModal}/>
                                </DialogContent>
                            </Dialog>

                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.confirmAlert}>
                                <DialogContent className={classes.dialogPaper}>
                                    <ConfirmAlert validationForHandleConfirm={this.validationForHandleConfirm}
                                                  closeModal={this.closeConfirmAlert}

                                    />

                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="xl"
                                open={this.state.imageModalBoolean}>
                                <DialogContent>

                                    <SingleImageShow data={this.state.selectImage}
                                                     closeModal={this.closeModal}/>
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.errorModalBoolean}>
                                <DialogContent className={classes.dialogPaper}>
                                    <ErrorModal title={this.state.errorTittle}
                                                closeModal={this.closeCaseIdModal}
                                                subServiceType={this.props.subServiceType}
                                                appId={this.props.appId}
                                    />

                                </DialogContent>
                            </Dialog>
                            <Grid item xs='12'>
                            </Grid>


                            <Grid container>
                                <ThemeProvider theme={theme}>

                                    <Grid item xs={12}>
                                        {
                                            this.renderDeferalApprovalTableData()
                                        }
                                    </Grid>

                                    <Grid item xs={12}>
                                        {this.functionForWaiver()}
                                        <br/>
                                    </Grid>

                                    <Grid item xs={12}>
                                        {
                                            this.renderRemarksTable()
                                        }
                                    </Grid>


                                    <Grid item xs={12}>

                                        {this.renderRemarks()}

                                    </Grid>


                                </ThemeProvider>
                            </Grid>

                            <center>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'middle',
                                        marginTop: '20px',
                                    }}
                                    type="submit"
                                   // onClick={this.submitHandler}
                                    onClick={(event) => this.handleCommon(event, "submitHandler")}
                                    disabled={this.state.disabled}
                                >
                                    Submit
                                </button>
                                &nbsp;
                                {this.props.taskTitle === "line_manager_approval" && <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'middle',
                                        marginTop: '20px',
                                    }}
                                    type="submit"
                                   // onClick={(e) => this.submitHandler(e, 'RETURN')}
                                    onClick={(event) => this.handleCommon(event, "submitHandlerReturn")}

                                    disabled={this.state.disabled}
                                >
                                    Return
                                </button>}
                            </center>
                        </CardBody>


                    </Card>
                </div>
            );
        } else if (this.state.getMerchant) {
            return (<div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >Merchant Onboarding Request<a><CloseIcon onClick={this.props.closeModal} style={{
                            position: 'absolute',
                            right: 10,
                            color: "#000000"
                        }}/></a></h4>
                    </CardHeader>
                    <CardBody>
                        <Grid container>
                            { this.renderSDNDedupeResult()}
                            <Grid item xs='12'>
                                <br/>
                            </Grid>
                            <ThemeProvider theme={theme}>
                                {
                                    CommonJsonFormComponent.renderJsonForm(this.state, dstJsonForm, this.updateComponent)
                                }

                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    open={this.state.getMappingAllImage}>
                                    <DialogContent>
                                        {this.renderMappingImageEditModal()}
                                    </DialogContent>
                                </Dialog>


                            </ThemeProvider>

                            {this.renderNotification()}
                        </Grid>
                        <br/>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.confirmAlert}>
                            <DialogContent className={classes.dialogPaper}>
                                <ConfirmAlert validationForHandleConfirm={this.validationForHandleConfirm}
                                              closeModal={this.closeConfirmAlert}

                                />

                            </DialogContent>
                        </Dialog>

                        <div style={{marginTop: '20px'}}>
                            {
                                this.renderUploadButton()
                            }

                            &nbsp;&nbsp;

                            {
                                this.renderAssignedImage()
                            }
                        </div>

                        <div style={{marginTop: '20px'}}>
                            {
                                this.preview()
                            }

                        </div>
                        <div style={{marginTop: '20px'}}>
                            {
                                this.missingFiles()
                            }
                            {
                                this.missingButton()
                            }
                        </div>

                        <Dialog
                            fullWidth="true"
                            maxWidth="xl"
                            open={this.state.uploadModal}>
                            <DialogContent>
                                <MerchantIndexing
                                    appId={this.state.caseId}
                                    merchantType={this.state.inputData.merchantType}
                                    closeModal={this.closeUploadModal}/>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.errorModalBoolean}>
                            <DialogContent className={classes.dialogPaper}>
                                <ErrorModal title={this.state.errorTittle}
                                            closeModal={this.closeCaseIdModal}
                                            subServiceType={this.props.subServiceType}
                                            appId={this.props.appId}
                                />

                            </DialogContent>
                        </Dialog>

                        <Grid container>
                            <ThemeProvider theme={theme}>

                                <Grid item xs={12}>
                                    {
                                        this.renderRemarksTable()
                                    }
                                </Grid>


                                <Grid item xs={12}>

                                    {this.renderRemarks()}

                                </Grid>


                            </ThemeProvider>

                         </Grid>

                        <center>
                            <button
                                className="btn btn-outline-primary"
                                style={{
                                    verticalAlign: 'middle',
                                    marginTop: '20px',
                                }}
                                type="submit"
                               // onClick={this.submitHandler}
                                onClick={(event) => this.handleCommon(event, "submitHandler")}

                            >
                                Submit
                            </button>
                        </center>
                        {/*{*/}
                        {/*    this.handleSubmit()*/}
                        {/*}*/}
                    </CardBody>
                </Card>
            </div>)
        } else {
            return (<div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >Merchant Onboarding Request<a><CloseIcon onClick={this.props.closeModal} style={{
                            position: 'absolute',
                            right: 10,
                            color: "#000000"
                        }}/></a></h4>
                    </CardHeader>
                    <CardBody>
                        { this.renderSDNDedupeResult()}
                        <Grid item xs='12'>
                            <br/>
                        </Grid>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.confirmAlert}>
                            <DialogContent className={classes.dialogPaper}>
                                <ConfirmAlert validationForHandleConfirm={this.validationForHandleConfirm}
                                              closeModal={this.closeConfirmAlert}

                                />

                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>

                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.errorModalBoolean}>
                            <DialogContent className={classes.dialogPaper}>
                                <ErrorModal title={this.state.errorTittle}
                                            closeModal={this.closeCaseIdModal}
                                            subServiceType={this.props.subServiceType}
                                            appId={this.props.appId}
                                />

                            </DialogContent>
                        </Dialog>
                    </CardBody>
                </Card>
            </div>)
        }

    }

}

export default withStyles(styles)(DstCaseStart);
