import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import GridItem from "../../Grid/GridItem";
import CardHeader from "../../Card/CardHeader";
import GridContainer from "../../Grid/GridContainer";
import Functions from '../../../Common/Functions';
import {Menu} from 'semantic-ui-react';
import 'semantic-ui-offline/semantic.min.css'
import loader from "../../../Static/loader.gif";
import Badge from "@material-ui/core/Badge";

import DisbursementMakerApproval from "./PartialPayment/CommonApprovalPartialPayment";
import ClosingMakerLienMarking from "./LienMarking/ClosingMakerLienMarking";
import MakerRecoveryAmount from "./RecoveryAmount/MakerRecoveryAmount";
import CsPayOrder from "./PayOrder/CsPayOrder";
import AssetOpsCheckerForm from "./LoanClosing/ClosingCheckerFormLoanClosing";
import NocMaker from "./LoanClosing/NocMakerLoanClosing";
import HomeLoanDocumentation from "./LoanClosing/DocumentMakerLoanClosing";
import MakerApproval from "./EmiDataChange/CommonApprovalEmiDateChange";
import CheckerApproval from "./EmiDataChange/CommonApprovalEmiDateChange";

import ClosingMakerSecuredLoanClosing from "./SecuredLoanClosing/ClosingMakerSecuredLoanClosing";
import ClosingCheckerSecuredLoanClosing from "./SecuredLoanClosing/ClosingCheckerSecuredLoanClosing";
import NocMakerSecuredLoanClosing from "./SecuredLoanClosing/NocMakerSecuredLoanClosing";

import LoanAccountTenorMaker from "./LoanAccountTenorChange/MakerApproval";
import LoanAccountTenorChecker from "./LoanAccountTenorChange/CheckerApproval";

import ClosingMakerDeceasedMark from "./DeceasedMark/ClosingMakerDeceasedMark";
import ClosingCheckerDeceasedMark from "./DeceasedMark/ClosingCheckerDeceasedMark";
import ClosingMakerWaverRequest from "./WaiverRequest/CommonApprovalWaiverRequest";
import ClosingCheckerWaverRequest from "./WaiverRequest/CommonApprovalWaiverRequest";
import NocMakerWaverRequest from "./WaiverRequest/CommonApprovalWaiverRequest";
import NocCheckerWaverRequest from "./WaiverRequest/CommonApprovalWaiverRequest";
import HomeLoanDocWaverRequest from "./WaiverRequest/HomeLoanDocWaiverRequest";


import InterestTransferMaker from "./OtherServiceRequest/MakerApproval";
import InterestTransferChecker from "./OtherServiceRequest/CheckerApproval";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";


let getAllBranchType = [];


let counter = 0;

function createData(index, customer_name, cb_number, account_number, appUid, service_type, subservice_type, branch_id, currentUserName, taskTitle, jointAccountCustomerNumber, delInitTime, category, subCategory, delStatus, caseId, status, solId, freeFlag1) {


    return {
        id: index + 1,
        customer_name,
        cb_number,
        account_number,
        appUid,
        service_type,
        subservice_type,
        branch_id,
        currentUserName,
        taskTitle,
        jointAccountCustomerNumber,
        delInitTime,
        category,
        delStatus,
        caseId,
        subCategory,
        status,
        solId,
        freeFlag1
    };
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rowsCS = [
    //{id: 'id', numeric: true, disablePadding: true, label: 'SL'},
    {id: 'SL', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'CaseId', numeric: false, disablePadding: true, label: 'Case ID'},
    {id: 'customer_name', numeric: false, disablePadding: true, label: 'Name'},
    {id: 'cb_number', numeric: false, disablePadding: true, label: 'CB Number'},
    {id: 'account_number', numeric: false, disablePadding: true, label: 'Account Number'},
    {id: 'category_type', numeric: false, disablePadding: true, label: 'Category'},
    {id: 'sub_category_type', numeric: false, disablePadding: true, label: 'Sub Category'},
    {id: 'Date', numeric: false, disablePadding: true, label: 'Date of Activity'},
    {id: 'currentUserName', numeric: false, disablePadding: true, label: 'Current User'},
    {id: 'Status', numeric: false, disablePadding: true, label: 'Status'},

    /*  {id: 'View', numeric: false, disablePadding: false, label: 'Action'}*/
];

class TableContentHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {order, orderBy} = this.props;
        return (
            <TableHead>
                <TableRow style={{height: 30, backgroundColor: "#E8E4E3"}}>
                    {rowsCS.map(
                        row => (
                            <TableCell
                                key={row.id}
                                align={row.numeric ? 'right' : 'left'}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        <b> {row.label}</b>
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        ),
                        this,
                    )}
                </TableRow>
            </TableHead>
        );
    }
}

TableContentHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


const styles = theme => ({
    root: {
        width: '100%',
        border: '1'
        //marginTop: theme.spacing.unit * 2,
    },
    table: {
        minWidth: 1020,
    },
    tableCell: {
        padding: 3,
    },
    tableCells: {
        padding: 0,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    container: {
        overflow: 'auto',
        padding: '10px'
    },
    Tab: {
        flexDirection: "row-reverse"
    },
    left: {
        float: 'left',
        width: '200px'
    },

    right: {
        marginLeft: '210px'
    }
});

class CsGroupInbox extends React.Component {
    state = {
        caseId: '',
        customerName: '',
        customerCB: '',
        accountNumber: '',
        category: '',
        subCategory: '',
        dateOfActivity: '',
        currentUser: '',
        status: '',

        order: 'asc',
        orderBy: 'id',
        selected: [],
        data: [],
        getInboxCaseData: [],
        page: 0,
        rowsPerPage: 25,
        renderModal: false,
        appUid: '',
        inboxModal: false,
        inboxModalSubmitted: false,
        serviceType: '',
        subserviceType: '',
        jointAccountCustomerNumber: '',
        taskTitle: '',
        freeFlag1: '',
        urgency: '',
        redirectLogin: false,
        selectTab: "",
        err: false,
        errorArray: {},
        errorMessages: {},
        errorReturn: {},
        inputData: {},
        getData: false,
        activeItem: 'CS Inbox',
        backgroundColor: 'red',
        delStatus: "",
        solId: "",
        loading: false,
        getCountInbox: 0,
        getCountReturn: 0,
        getCountSubmit: 0,
        getCountPending: 0,
        getCountGroupInbox: 0,
        getCountCertificateInbox: 0,
        getCountCompleteInbox: 0,
        fileReferenceNo: '',
        loanType: '',
        applicantName: '',
        assetOpsNumber: '',
        closingDate: '',
        location: '',
        trunkOrBoxNumber: '',
        loanAccounts: '',
        NOCStatus: '',
        fileDestroyStatus: '',
        docType: '',
        source: '',
        deferralDuration: '',
        deferralDetails: '',
        docTypeEntryCapacity: '',
        sourceEntryCapacity: '',
        requisitionBy: '',
        requisitionDate: '',
        purpose: '',
        departmentName: '',

        fileName: 'AssetOpsInbox',

    };

    componentDidMount() {


        this.setState({
            loading: true
        });


        const data = [];
        let url = backEndServerURL + "/workspaceClaimable";

        axios.get(url, {
            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
        })
            .then(response => {
                console.log(response.data);
                response.data.map((message, index) => {

                    data.push(createData(index, message.customerName, message.cbNumber, message.accountNumber, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1));

                });

                this.setState({
                    getData: true,
                    data: data,
                    loading: false,
                    getInboxCaseData: data
                });


            })

            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false
                })

            });


    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({order, orderBy});
    };

    handleSelectAllClick = event => {
        if (event.target.checked) {
            this.setState(state => ({selected: state.data.map(n => n.id)}));
            return;
        }
        this.setState({selected: []});
    };


    handleChangePage = (event, page) => {
        this.setState({page});
    };

    handleChangeRowsPerPage = event => {
        this.setState({rowsPerPage: event.target.value});
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    viewCase = (appUid, serviceType, subserviceType, taskTitle, jointAccountCustomerNumber, category, subCategory, delStatus, status, solId, freeFlag1) => {

        this.setState({
            serviceType: serviceType,
            subserviceType: subserviceType,
            inboxModal: true,
            category: category,
            subCategory: subCategory,
            solId: solId,
            appUid: appUid,
            delStatus: delStatus,
            taskTitle: taskTitle,
            status: status,
            freeFlag1: freeFlag1,
            jointAccountCustomerNumber: jointAccountCustomerNumber
        })

    };

    closeModal = () => {
        this.setState({
            inboxModal: false,
            inboxModalSubmitted: false,
            loading: true


        });
        console.log(this.state.activeItem);
        let urls = "";
        if (this.state.activeItem === "CS Inbox") {
            urls = backEndServerURL + "/workspaceClaimable";
        }

        let data = [];
        axios.get(urls, {
            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
        })
            .then(response => {
                console.log(response.data);
                response.data.map((message, index) => {

                    data.push(createData(index, message.customerName, message.cbNumber, message.accountNumber, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1));

                });

                this.setState({
                    data: data,
                    loading: false,
                    getInboxCaseData: data
                });


            })

            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false
                })

            });


    };


    renderInboxCase = () => {
        //alert("please view component Add")
        //Api Call

        //this.state.activeItem

        console.log("inside Render Inbox Case");
        console.log(this.state.activeItem);
        console.log(this.state.category);

        if (this.state.activeItem === "CS Inbox") {
            if (this.state.category === "Pay Order") {
                return (
                    <CsPayOrder delStatus={this.state.delStatus}
                                closeModal={this.closeModal}
                                appId={this.state.appUid}
                                subCategory={this.state.subCategory}
                                category={this.state.category}
                                serviceType={this.state.serviceType}
                                subServiceType={this.state.subServiceType}
                    />
                )
            }
        } else if (this.state.activeItem === "Asset Ops Maker") {
            if (this.state.category === "EMI") {
                return (
                    <MakerApproval delStatus={this.state.delStatus}
                                   closeModal={this.closeModal}
                                   appId={this.state.appUid}
                                   subCategory={this.state.subCategory}
                                   category={this.state.category}
                                   serviceType={this.state.serviceType}
                                   subServiceType={this.state.subServiceType}
                    />
                )
            } else if (this.state.subCategory === "Tenor Change") {
                return (
                    <LoanAccountTenorMaker delStatus={this.state.delStatus} closeModal={this.closeModal}
                                           appId={this.state.appUid}
                                           subCategory={this.state.subCategory}
                                           category={this.state.category} serviceType={this.state.serviceType}
                                           subServiceType={this.state.subServiceType}
                    />
                )
            }


        } else if (this.state.activeItem === "Asset Ops Checker") {
            console.log(this.state.activeItem);
            if (this.state.category === "EMI") {
                return (
                    <CheckerApproval delStatus={this.state.delStatus}
                                     closeModal={this.closeModal}
                                     appId={this.state.appUid}
                                     subCategory={this.state.subCategory}
                                     category={this.state.category}
                                     serviceType={this.state.serviceType}
                                     subServiceType={this.state.subServiceType}
                    />
                )
            } else if (this.state.subCategory === "Tenor Change") {
                return (
                    <LoanAccountTenorChecker delStatus={this.state.delStatus} closeModal={this.closeModal}
                                             appId={this.state.appUid}
                                             subCategory={this.state.subCategory}
                                             category={this.state.category} serviceType={this.state.serviceType}
                                             subServiceType={this.state.subServiceType}
                    />
                )
            } else if (this.state.category === "Loan Closing") {
                return (
                    <AssetOpsCheckerForm delStatus={this.state.delStatus} closeModal={this.closeModal}
                                         appId={this.state.appUid}
                                         subCategory={this.state.subCategory}
                                         category={this.state.category} serviceType={this.state.serviceType}
                                         freeFlag1={this.state.freeFlag1}
                                         taskTitle={this.state.taskTitle}
                                         subServiceType={this.state.subserviceType}

                    />
                )
            }

        } else if (this.state.activeItem === "NOC Maker") {
            console.log(this.state.activeItem);

            if (this.state.category === "Loan Closing") {
                return (
                    <NocMaker delStatus={this.state.delStatus} closeModal={this.closeModal}
                              appId={this.state.appUid}
                              subCategory={this.state.subCategory}
                              category={this.state.category} serviceType={this.state.serviceType}
                              freeFlag1={this.state.freeFlag1}
                              taskTitle={this.state.taskTitle}
                              subServiceType={this.state.subserviceType}

                    />
                )
            } else if (this.state.category === "Secured Loan Closing") {
                return (
                    <NocMakerSecuredLoanClosing delStatus={this.state.delStatus} closeModal={this.closeModal}
                                                appId={this.state.appUid}
                                                subCategory={this.state.subCategory}
                                                category={this.state.category} serviceType={this.state.serviceType}
                                                freeFlag1={this.state.freeFlag1}
                                                taskTitle={this.state.taskTitle}
                                                subServiceType={this.state.subserviceType}

                    />
                )
            } else if (this.state.category === "Waiver Request") {
                return (
                    <NocMakerWaverRequest delStatus={this.state.delStatus} closeModal={this.closeModal}
                                          appId={this.state.appUid}
                                          subCategory={this.state.subCategory}
                                          category={this.state.category}
                                          serviceType={this.state.serviceType}
                                          freeFlag1={this.state.freeFlag1}
                                          taskTitle={this.state.taskTitle}
                                          subServiceType={this.state.subserviceType}/>)

            }


        } else if (this.state.activeItem === "NOC Checker") {
            console.log(this.state.activeItem);

            if (this.state.category === "Waiver Request") {
                return (
                    <NocCheckerWaverRequest delStatus={this.state.delStatus} closeModal={this.closeModal}
                                            appId={this.state.appUid}
                                            subCategory={this.state.subCategory}
                                            category={this.state.category} serviceType={this.state.serviceType}
                                            freeFlag1={this.state.freeFlag1}
                                            taskTitle={this.state.taskTitle}
                                            subServiceType={this.state.subserviceType}

                    />
                )
            }

        } else if (this.state.activeItem === "Home Loan Documentation") {


            if (this.state.category === "Loan Closing") {
                return (
                    <HomeLoanDocumentation delStatus={this.state.delStatus} closeModal={this.closeModal}
                                           appId={this.state.appUid}
                                           subCategory={this.state.subCategory}
                                           category={this.state.category} serviceType={this.state.serviceType}
                                           freeFlag1={this.state.freeFlag1}
                                           taskTitle={this.state.taskTitle}
                                           subServiceType={this.state.subserviceType}
                    />
                )
            } else if (this.state.category === "Waiver Request") {
                return (
                    <HomeLoanDocWaverRequest delStatus={this.state.delStatus} closeModal={this.closeModal}
                                             appId={this.state.appUid}
                                             subCategory={this.state.subCategory}
                                             category={this.state.category} serviceType={this.state.serviceType}
                                             freeFlag1={this.state.freeFlag1}
                                             taskTitle={this.state.taskTitle}
                                             subServiceType={this.state.subserviceType}
                    />
                )
            }
        } else if (this.state.activeItem === "Credit MIS") {


        } else if (this.state.activeItem === "Closing Maker") {
            if (this.state.subserviceType === "Other Service Request") {
                return (
                    <InterestTransferMaker delStatus={this.state.delStatus} closeModal={this.closeModal}
                                           appId={this.state.appUid}
                                           subCategory={this.state.subCategory}
                                           category={this.state.category}
                                           serviceType={this.state.serviceType}
                                           subServiceType={this.state.subserviceType}


                    />
                )
            } else if (this.state.category === "Secured Loan Closing") {
                return (
                    <ClosingMakerSecuredLoanClosing delStatus={this.state.delStatus} closeModal={this.closeModal}
                                                    appId={this.state.appUid}
                                                    subCategory={this.state.subCategory}
                                                    category={this.state.category}
                                                    serviceType={this.state.serviceType}
                                                    freeFlag1={this.state.freeFlag1}
                                                    taskTitle={this.state.taskTitle}
                                                    subServiceType={this.state.subserviceType}/>)

            } else if (this.state.category === "Lien Marking") {
                return (
                    <ClosingMakerLienMarking closeModal={this.closeModal}
                                             appId={this.state.appUid}
                                             subCategory={this.state.subCategory}
                                             category={this.state.category}
                                             serviceType={this.state.serviceType}
                                             subServiceType={this.state.subServiceType}
                    />
                )
            } else if (this.state.category === "Recovery Amount") {
                return (
                    <MakerRecoveryAmount closeModal={this.closeModal}
                                         appId={this.state.appUid}
                                         subCategory={this.state.subCategory}
                                         category={this.state.category}
                                         serviceType={this.state.serviceType}
                                         subServiceType={this.state.subServiceType}
                    />
                )
            } else if (this.state.category === "Deceased Mark") {
                return (
                    <ClosingMakerDeceasedMark delStatus={this.state.delStatus} closeModal={this.closeModal}
                                              appId={this.state.appUid}
                                              subCategory={this.state.subCategory}
                                              category={this.state.category}
                                              serviceType={this.state.serviceType}
                                              freeFlag1={this.state.freeFlag1}
                                              taskTitle={this.state.taskTitle}
                                              subServiceType={this.state.subserviceType}/>)

            } else if (this.state.category === "Waiver Request") {
                return (
                    <ClosingMakerWaverRequest delStatus={this.state.delStatus} closeModal={this.closeModal}
                                              appId={this.state.appUid}
                                              subCategory={this.state.subCategory}
                                              category={this.state.category}
                                              serviceType={this.state.serviceType}
                                              freeFlag1={this.state.freeFlag1}
                                              taskTitle={this.state.taskTitle}
                                              subServiceType={this.state.subserviceType}/>)

            }

        } else if (this.state.activeItem === "Closing Checker") {
            if (this.state.subserviceType === "Other Service Request") {
                return (
                    <InterestTransferChecker delStatus={this.state.delStatus} closeModal={this.closeModal}
                                             appId={this.state.appUid}
                                             subCategory={this.state.subCategory}
                                             category={this.state.category}
                                             serviceType={this.state.serviceType}
                                             subServiceType={this.state.subserviceType}


                    />
                )
            } else if (this.state.category === "Secured Loan Closing") {
                return (
                    <ClosingCheckerSecuredLoanClosing delStatus={this.state.delStatus}
                                                      closeModal={this.closeModal}
                                                      appId={this.state.appUid}
                                                      subCategory={this.state.subCategory}
                                                      category={this.state.category}
                                                      serviceType={this.state.serviceType}
                                                      freeFlag1={this.state.freeFlag1}
                                                      taskTitle={this.state.taskTitle}
                                                      subServiceType={this.state.subserviceType}/>)
            } else if (this.state.category === "Lien Marking") {
                return (
                    <ClosingMakerLienMarking closeModal={this.closeModal}
                                             appId={this.state.appUid}
                                             subCategory={this.state.subCategory}
                                             category={this.state.category}
                                             serviceType={this.state.serviceType}
                                             subServiceType={this.state.subServiceType}
                    />
                )
            } else if (this.state.category === "Recovery Amount") {
                return (
                    <MakerRecoveryAmount closeModal={this.closeModal}
                                         appId={this.state.appUid}
                                         subCategory={this.state.subCategory}
                                         category={this.state.category}
                                         serviceType={this.state.serviceType}
                                         subServiceType={this.state.subServiceType}
                    />
                )
            } else if (this.state.category === "Deceased Mark") {
                return (
                    <ClosingCheckerDeceasedMark delStatus={this.state.delStatus} closeModal={this.closeModal}
                                                appId={this.state.appUid}
                                                subCategory={this.state.subCategory}
                                                category={this.state.category}
                                                serviceType={this.state.serviceType}
                                                freeFlag1={this.state.freeFlag1}
                                                taskTitle={this.state.taskTitle}
                                                subServiceType={this.state.subserviceType}/>)

            } else if (this.state.category === "Waiver Request") {
                return (
                    <ClosingCheckerWaverRequest delStatus={this.state.delStatus} closeModal={this.closeModal}
                                                appId={this.state.appUid}
                                                subCategory={this.state.subCategory}
                                                category={this.state.category}
                                                serviceType={this.state.serviceType}
                                                freeFlag1={this.state.freeFlag1}
                                                taskTitle={this.state.taskTitle}
                                                subServiceType={this.state.subserviceType}/>)

            } else if (this.state.category === "Loan Closing") {

                return (
                    <AssetOpsCheckerForm delStatus={this.state.delStatus} closeModal={this.closeModal}
                                         appId={this.state.appUid}
                                         subCategory={this.state.subCategory}
                                         category={this.state.category} serviceType={this.state.serviceType}
                                         freeFlag1={this.state.freeFlag1}
                                         taskTitle={this.state.taskTitle}
                                         subServiceType={this.state.subserviceType}/>)


            }

        } else if (this.state.activeItem === "Disbursement Maker") {
            if (this.state.subserviceType === "Other Service Request") {
                return (
                    <InterestTransferMaker delStatus={this.state.delStatus} closeModal={this.closeModal}
                                           appId={this.state.appUid}
                                           subCategory={this.state.subCategory}
                                           category={this.state.category}
                                           serviceType={this.state.serviceType}
                                           subServiceType={this.state.subserviceType}


                    />
                )
            } else if (this.state.category === "Partial Payment") {
                return (
                    <DisbursementMakerApproval closeModal={this.closeModal}
                                               appId={this.state.appUid}
                                               subCategory={this.state.subCategory}
                                               category={this.state.category}
                                               serviceType={this.state.serviceType}
                                               subServiceType={this.state.subServiceType}
                    />
                )
            } else if (this.state.category === "EMI") {
                return (
                    <MakerApproval delStatus={this.state.delStatus}
                                   closeModal={this.closeModal}
                                   appId={this.state.appUid}
                                   subCategory={this.state.subCategory}
                                   category={this.state.category}
                                   serviceType={this.state.serviceType}
                                   subServiceType={this.state.subServiceType}
                    />
                )
            } else if (this.state.subCategory === "Tenor Change") {
                return (
                    <LoanAccountTenorMaker delStatus={this.state.delStatus} closeModal={this.closeModal}
                                           appId={this.state.appUid}
                                           subCategory={this.state.subCategory}
                                           category={this.state.category} serviceType={this.state.serviceType}
                                           subServiceType={this.state.subServiceType}

                    />
                )
            }
        } else if (this.state.activeItem === "Disbursement Checker") {
            if (this.state.subserviceType === "Other Service Request") {
                return (
                    <InterestTransferChecker delStatus={this.state.delStatus} closeModal={this.closeModal}
                                             appId={this.state.appUid}
                                             subCategory={this.state.subCategory}
                                             category={this.state.category}
                                             serviceType={this.state.serviceType}
                                             subServiceType={this.state.subserviceType}


                    />
                )
            } else if (this.state.category === "Partial Payment") {
                return (
                    <DisbursementMakerApproval closeModal={this.closeModal}
                                               appId={this.state.appUid}
                                               subCategory={this.state.subCategory}
                                               category={this.state.category}
                                               serviceType={this.state.serviceType}
                                               subServiceType={this.state.subServiceType}
                    />
                )
            }
        } else if (this.state.activeItem === "Credit Analyst Maker") {
            if (this.state.subCategory === "Tenor Change") {
                return (
                    <LoanAccountTenorChecker delStatus={this.state.delStatus} closeModal={this.closeModal}
                                             appId={this.state.appUid}
                                             subCategory={this.state.subCategory}
                                             category={this.state.category} serviceType={this.state.serviceType}
                                             subServiceType={this.state.subServiceType}

                    />
                )
            } else if (this.state.category === "EMI") {
                return (
                    <CheckerApproval delStatus={this.state.delStatus}
                                     closeModal={this.closeModal}
                                     appId={this.state.appUid}
                                     subCategory={this.state.subCategory}
                                     category={this.state.category}
                                     serviceType={this.state.serviceType}
                                     subServiceType={this.state.subServiceType}
                    />
                )
            }

        } else if (this.state.activeItem === "Credit Analyst Maker") {


        } else if (this.state.activeItem === "Credit Analyst Checker") {

        }

    };
    updateComponent = () => {
        this.forceUpdate(


        )
    };


    handleItemClick = (e, {name, style}) => {
        this.setState({
            data: [],
            loading: true,
            getInboxCaseData: []
        });
        this.setState({
                activeItem: name,
                backgroundColor: style
            }
        );
        let urls = "";
        if (name === "CS Inbox") {
            urls = backEndServerURL + "/workspaceClaimable";
        }

        let data = [];
        axios.get(urls, {
            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
        })
            .then(response => {
                console.log(response.data);
                response.data.map((message, index) => {

                    data.push(createData(index, message.customerName, message.cbNumber, message.accountNumber, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1));

                });

                this.setState({
                    data: data,
                    loading: false,
                    getInboxCaseData: data
                });


            })

            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false
                })

            });

    };

    renderClosingMakerTabShow = () => {

        return (
            <Menu.Item
                name='Closing Maker'
                active={this.state.activeItem === 'Closing Maker'}
                style={this.state.activeItem === 'Closing Maker' ? {
                    backgroundColor: "red",
                    color: "white",

                } : {backgroundColor: "white", color: "black"}}
                onClick={this.handleItemClick}
            >

                <Badge badgeContent={this.state.getCountReturn} color="primary">
                    <div style={{marginTop: "8px"}}>
                        Closing Maker
                    </div>
                </Badge>

            </Menu.Item>
        )

    };

    renderClosingCheckerTabShow = () => {

        return (
            <Menu.Item
                name='Closing Checker'
                active={this.state.activeItem === 'Closing Checker'}
                style={this.state.activeItem === 'Closing Checker' ? {
                    backgroundColor: "red",
                    color: "white",

                } : {backgroundColor: "white", color: "black"}}
                onClick={this.handleItemClick}
            >

                <Badge badgeContent={this.state.getCountReturn} color="primary">
                    <div style={{marginTop: "8px"}}>
                        Closing Checker
                    </div>
                </Badge>

            </Menu.Item>
        )

    };


    renderDisbursementMakerTabShow = () => {

        return (
            <Menu.Item
                name='Disbursement Maker'
                active={this.state.activeItem === 'Disbursement Maker'}
                style={this.state.activeItem === 'Disbursement Maker' ? {
                    backgroundColor: "red",
                    color: "white",

                } : {backgroundColor: "white", color: "black"}}
                onClick={this.handleItemClick}
            >

                <Badge badgeContent={this.state.getCountReturn} color="primary">
                    <div style={{marginTop: "8px"}}>
                        Disbursement Maker
                    </div>
                </Badge>

            </Menu.Item>
        )

    };


    renderDisbursementCheckerTabShow = () => {

        return (
            <Menu.Item
                name='Disbursement Checker'
                active={this.state.activeItem === 'Disbursement Checker'}
                style={this.state.activeItem === 'Disbursement Checker' ? {
                    backgroundColor: "red",
                    color: "white",

                } : {backgroundColor: "white", color: "black"}}
                onClick={this.handleItemClick}
            >

                <Badge badgeContent={this.state.getCountReturn} color="primary">
                    <div style={{marginTop: "8px"}}>
                        Disbursement Checker
                    </div>
                </Badge>

            </Menu.Item>
        )

    };


    renderAssetOpsMakerTabShow = () => {

        return (
            <Menu.Item
                name='Asset Ops Maker'
                active={this.state.activeItem === 'Asset Ops Maker'}
                style={this.state.activeItem === 'Asset Ops Maker' ? {
                    backgroundColor: "red",
                    color: "white",

                } : {backgroundColor: "white", color: "black"}}
                onClick={this.handleItemClick}
            >

                <Badge badgeContent={this.state.getCountReturn} color="primary">
                    <div style={{marginTop: "8px"}}>
                        Asset Ops Maker
                    </div>
                </Badge>

            </Menu.Item>
        )

    };

    renderAssetOpsCheckerTabShow = () => {

        return (
            <Menu.Item
                name='Asset Ops Checker'
                active={this.state.activeItem === 'Asset Ops Checker'}
                style={this.state.activeItem === 'Asset Ops Checker' ? {
                    backgroundColor: "red",
                    color: "white",

                } : {backgroundColor: "white", color: "black"}}
                onClick={this.handleItemClick}
            >

                <Badge badgeContent={this.state.getCountReturn} color="primary">
                    <div style={{marginTop: "8px"}}>
                        Asset Ops Checker
                    </div>
                </Badge>

            </Menu.Item>
        )

    };
    renderNocMakerTabShow = () => {

        return (
            <Menu.Item
                name='NOC Maker'
                active={this.state.activeItem === 'NOC Maker'}
                style={this.state.activeItem === 'NOC Maker' ? {
                    backgroundColor: "red",
                    color: "white"
                } : {backgroundColor: "white", color: "black"}}
                onClick={this.handleItemClick}
            >
                <Badge badgeContent={this.state.getCountPending} color="primary">
                    <div style={{marginTop: "8px"}}>
                        NOC Maker
                    </div>
                </Badge>
            </Menu.Item>
        )

    };
    renderHomeLoanDocumentationTabShow = () => {

        return (
            <Menu.Item
                name='Home Loan Documentation'
                active={this.state.activeItem === 'Home Loan Documentation'}
                style={this.state.activeItem === 'Home Loan Documentation' ? {
                    backgroundColor: "red",
                    color: "white"
                } : {backgroundColor: "white", color: "black"}}
                onClick={this.handleItemClick}
            >
                <Badge color="primary">
                    <div style={{marginTop: "8px"}}>
                        Home Loan Documentation
                    </div>
                </Badge>

            </Menu.Item>
        )

    };

    renderCreditAnalystCheckerTabShow = () => {

        return (
            <Menu.Item
                name='Credit Analyst Checker'
                active={this.state.activeItem === 'Credit Analyst Checker'}
                style={this.state.activeItem === 'Credit Analyst Checker' ? {
                    backgroundColor: "red",
                    color: "white"
                } : {backgroundColor: "white", color: "black"}}
                onClick={this.handleItemClick}
            >
                <Badge color="primary">
                    <div style={{marginTop: "8px"}}>
                        Credit Analyst Checker
                    </div>
                </Badge>

            </Menu.Item>
        )

    };

    renderCreditAnalystMakerTabShow = () => {

        return (
            <Menu.Item
                name='Credit Analyst Maker'
                active={this.state.activeItem === 'Credit Analyst Maker'}
                style={this.state.activeItem === 'Credit Analyst Maker' ? {
                    backgroundColor: "red",
                    color: "white"
                } : {backgroundColor: "white", color: "black"}}
                onClick={this.handleItemClick}
            >
                <Badge color="primary">
                    <div style={{marginTop: "8px"}}>
                        Credit Analyst Maker
                    </div>
                </Badge>

            </Menu.Item>
        )

    };

    renderCreditMisTabShow = () => {

        return (
            <Menu.Item
                name='Credit MIS'
                active={this.state.activeItem === 'Credit MIS'}
                style={this.state.activeItem === 'Credit MIS' ? {
                    backgroundColor: "red",
                    color: "white"
                } : {backgroundColor: "white", color: "black"}}
                onClick={this.handleItemClick}
            >
                <Badge color="primary">
                    <div style={{marginTop: "8px"}}>
                        Credit MIS
                    </div>
                </Badge>

            </Menu.Item>
        )

    };

    renderNocCheckerTabShow = () => {

        return (
            <Menu.Item
                name='NOC Checker'
                active={this.state.activeItem === 'NOC Checker'}
                style={this.state.activeItem === 'NOC Checker' ? {
                    backgroundColor: "red",
                    color: "white"
                } : {backgroundColor: "white", color: "black"}}
                onClick={this.handleItemClick}
            >
                <Badge color="primary">
                    <div style={{marginTop: "8px"}}>
                        NOC Checker
                    </div>
                </Badge>

            </Menu.Item>
        )

    };

    renderCsInboxTabShow = () => {

        return (
            <Menu.Item
                name='CS Inbox'
                active={this.state.activeItem === 'CS Inbox'}
                style={this.state.activeItem === 'CS Inbox' ? {
                    backgroundColor: "red",
                    color: "white"
                } : {backgroundColor: "white", color: "black"}}
                onClick={this.handleItemClick}
            >
                <Badge color="primary">
                    <div style={{marginTop: "8px"}}>
                        CS Inbox
                    </div>
                </Badge>

            </Menu.Item>
        )

    };

    handleChange = (name, value) => {
        this.setState({
            selectTab: value
        })
    };
    renderLockButton = () => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("menus").indexOf("GROUP LOCK") !== -1) {
            return (
                <Menu.Item
                    name='Group Inbox'
                    active={this.state.activeItem === 'Group Inbox'}
                    style={this.state.activeItem === 'Group Inbox' ? {
                        backgroundColor: "red",
                        color: "white"
                    } : {backgroundColor: "white", color: "black"}}
                    onClick={this.handleItemClick}
                >
                    <Badge color="primary">
                        <div style={{marginTop: "8px"}}>
                            Group Inbox
                        </div>
                    </Badge>

                </Menu.Item>
            )
        }
    };

    renderTab = (classes) => {

        /*{this.renderCreditMisTabShow()}
                    {this.renderCreditAnalystMakerTabShow()}
                    {this.renderCreditAnalystCheckerTabShow()}
                    {this.renderAssetOpsMakerTabShow()}
                    {this.renderAssetOpsCheckerTabShow()}
                    {this.renderNocMakerTabShow()}
                    {this.renderNocCheckerTabShow()}
                    {this.renderHomeLoanDocumentationTabShow()}

                    {this.renderClosingMakerTabShow()}
                    {this.renderClosingCheckerTabShow()}
                    {this.renderDisbursementMakerTabShow()}
                    {this.renderDisbursementCheckerTabShow()}*/
        return (
            <div style={{marginRight: "8px"}}>
                <Menu pointing>


                    {this.renderCsInboxTabShow()}


                </Menu>


            </div>
        );
    };


    render() {
        const {classes} = this.props;
        const {data, order, orderBy, selected, rowsPerPage, page} = this.state;
        const emptyRows = 0;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <section>

                <Paper className={classes.root}>
                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>

                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h5 className={classes.cardTitleWhite}>Loan Closing</h5>

                            </CardHeader>
                            <br/>
                            {this.renderTab(classes.Tab)}

                            <div className={classes.tableWrapper}>
                                <Table className={classes.table} aria-labelledby="tableTitle">
                                    <TableContentHead
                                        sty
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={this.handleSelectAllClick}
                                        onRequestSort={this.handleRequestSort}
                                        rowCount={data.length}
                                    />
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="xl"
                                        fullScreen={true}
                                        open={this.state.inboxModal}>
                                        <DialogContent>

                                            {this.renderInboxCase()}
                                        </DialogContent>
                                    </Dialog>

                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.loading}>
                                        <DialogContent className={classes.dialogPaper}>

                                            <center>
                                                <img src={loader} alt=""/>
                                            </center>
                                        </DialogContent>
                                    </Dialog>

                                    <TableBody>
                                        {stableSort(data, getSorting(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map(n => {
                                                const isSelected = this.isSelected(n.id);
                                                return (
                                                    <TableRow
                                                        hover
                                                        /* onClick={event => this.handleClick(event, n.id)}*/
                                                        role="checkbox"
                                                        aria-checked={isSelected}
                                                        tabIndex={-1}
                                                        key={n.id}
                                                        onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1)}
                                                        selected={isSelected}
                                                    >
                                                        {/*  <TableCell className={classes.tableCells} align="left">

                                                            </TableCell>*/}
                                                        <TableCell className={classes.tableCell} align="left">
                                                            {n.id}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell} align="left">
                                                            {n.caseId}
                                                        </TableCell>

                                                        <TableCell className={classes.tableCell} align="left">
                                                            {n.customer_name}
                                                        </TableCell>

                                                        <TableCell className={classes.tableCell}
                                                                   align="left">{n.cb_number}</TableCell>
                                                        <TableCell className={classes.tableCell}
                                                                   align="left">{n.account_number}</TableCell>
                                                        <TableCell className={classes.tableCell}
                                                                   align="left">{n.category}</TableCell>
                                                        <TableCell className={classes.tableCell}
                                                                   align="left">{n.subCategory}</TableCell>
                                                        <TableCell className={classes.tableCell}
                                                                   align="left">{n.delInitTime}</TableCell>
                                                        <TableCell className={classes.tableCell}
                                                                   align="left">{n.currentUserName}</TableCell>


                                                        <TableCell className={classes.tableCell}
                                                                   align="left">{n.delStatus}</TableCell>


                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{height: 49 * emptyRows}}>
                                                <TableCell colSpan={6}/>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </div>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />

                        </GridItem>
                    </GridContainer>
                </Paper>
            </section>
        );
    }


}

CsGroupInbox.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CsGroupInbox);