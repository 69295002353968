import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../../Common/Functions';
import Notification from "../../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../../JsonForm/CustomeTheme";
import Table from "../../../Table/Table";
import DialogContent from "@material-ui/core/DialogContent/index";
import loader from "../../../../Static/loader.gif";
import {Dialog} from "@material-ui/core/index";
import GridList from "@material-ui/core/GridList/index";
import Label from "./CasaCloserLabel";
import CardBody from "../../../Card/CardBody";
import Card from "../../../Card/Card";
import FileTypeComponent from "../../../JsonForm/FileTypeComponent";
import SignatureButton from "../SignatureButton";
import PreviewMappingImage from "./PreviewMappingImage";
import PreviewButton from "../PreviewButton";
import MyValidation from "../../../JsonForm/MyValidation";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";
import AccountStatement from "../../fdr/AccountStatement";

let makerRemarks = [
    {
        "varName": "makerRemarks",
        "type": "textArea",
        "label": "Maker Remarks",
        "required": true,
        "grid": 12
    }];

var fileUpload = {
    "varName": "scanningFile",
    "type": "file",
    "label": "Upload File",
    "grid": 12
};
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};


class MakerInbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            appData: {},
            getData: false,
            varValue: [],
            showValue: false,
            redirectLogin: false,
            title: "",
            notificationMessage: "",
            alert: false,
            inputData: {tagList: {}},
            selectedDate: {},
            SelectedDropdownSearchData: null,
            dropdownSearchData: {},
            values: [],
            customerName: [],
            deferalType: [],
            expireDate: [],
            other: [],
            getCheckerList: [],
            getAllDefferal: [],
            getDeferalList: [],
            loading: true,
            jointAccountCustomerNumber: 0,
            objectForNominee: [],
            fileUploadData: {},
            getgenerateForm: false,
            renderCumModalopen: false,
            generateAccountNo: '',
            getDedupData: {},
            jointDedupData: {},
            jointSearchTableData: [],
            propritorshipData: [],
            dedupData: [],
            tagClick: false,
            getTaggingList: {},
            getTaggingCustomerList: {},
            taggingData: [],
            relatedData: {},
            searchTableData: false,
            searchTableRelatedData: false,
            imageCropModal: false,
            errorModalBoolean: false,
            getRemarks: [], err: false,
            errorArray: {},
            errorMessages: {},
            getMappingCropImage: false,
            nomineeNumber: 0,
            getNominee: false,
            type: "successs",
            labelOpen: false,
            labelName: "",
            previewDocument: false,
            confirmationAlert: false,
            buttonType: "",
            accountStatement: false
        };


    }


    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{marginTop: "5px"}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>

                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    componentDidMount() {

        var remarksArray = [];

        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            response.data.map((data) => {
                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                            });
                            this.setState({
                                getRemarks: remarksArray
                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        });
                    if (response.data.checker_approval === "RETURN") {
                        let url = backEndServerURL + '/getAccountInfo/' + response.data.accountNumber;
                        axios.get(url,
                            {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((acResponse) => {
                                let data = this.apiDataSet(acResponse);
                                this.setState({
                                    getData: true,
                                    varValue: this.removeNullValue({...response.data, ...data}),
                                    inputData: this.removeNullValue({...response.data, ...data}),
                                    showValue: true,
                                });
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    loading: false
                                })
                            });
                    } else {
                        this.setState({
                            getData: true,
                            varValue: this.removeNullValue(response.data),
                            inputData: this.removeNullValue(response.data),
                            appData: response.data,
                            showValue: true,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
        }
    }


    apiDataSet = (value) => {
        let inputData = {};
        inputData.cashDebitLimitException = value.drcashlim;
        inputData.clearingExceptionLimit = value.drclrlim;
        inputData.transferExceptionLimit = value.drtxlim;
        return inputData;
    };

    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.type} stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };

    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                disabled: true,
                loading: true,
                confirmationAlert: false
            });
            if (this.state.buttonType === "APPROVED") {
                this.state.inputData.maker_update_all_info_send_to = "CHECKER";
                this.state.inputData.maker_approval = "APPROVED";
            } else {
                this.state.inputData.maker_approval = "RETURN";
            }
            let remarksData = {};
            remarksData.remark = this.state.inputData.makerRemarks;
            remarksData.map = {...this.state.inputData, makerRemarks: undefined};
            let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
            axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                    loading: false
                });
                this.close();
            }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
        } else {
            this.setState({
                disabled: false,
                loading: false,
                confirmationAlert: false
            })
        }
    };

    handleSubmit = (event, data) => {
        event.preventDefault();
        let error = data === "APPROVED" ? MyValidation.defaultValidation(this.props.commonJsonForm, this.state) : MyValidation.defaultValidation(makerRemarks, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }
        this.setState({
            title: "Do you want to confirm?",
            confirmationAlert: true,
            buttonType: data
        });
    };


    stopLoading = (value) => {
        if (value === "yes") {
            setTimeout(() => {
                this.setState({
                    loading: false,
                });
            }, 1000)
        } else {
            this.setState({
                getData: false,
                searchData: false,
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    previewButton = () => {
        if (this.state.getData && this.state.inputData.accountNumber) {
            return (
                <Grid container spacing={0}>
                    <Grid item xs={12}><br/></Grid>
                    <SignatureButton accountNumber={this.state.inputData.accountNumber} classes={this.props}/>
                    &nbsp;&nbsp;
                    <PreviewButton appId={this.state.appId} classes={this.props}/>
                </Grid>
            )
        }
    };

    renderJsonFormFirst = () => {

        if (this.state.getData) {
            return (
                <React.Fragment>
                    <Label input={this.state.inputData} stopLoading={this.stopLoading} classes={this.props}/>
                    {this.previewButton()}
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
                    }
                </React.Fragment>
            )
        }
    };

    onKeyDownForDedup = (event, data) => {

    };

    onKeyDownChange = (data) => {
        if (data.varName === "debitAccountStatement") {
            this.setState({
                accountStatement: true
            })
        }
    };

    viewAttachedImages = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return <PreviewMappingImage appId={this.props.appId} classes={classes}/>
        }
    };

    renderFileUpload = () => {
        if (this.state.getData && this.state.inputData.titleChange) {
            return (

                <Grid item xs={12}>
                    {FileTypeComponent.file(this.state, this.updateComponent, fileUpload)}
                </Grid>

            )
        }
    };


    closeStatement = () => {
        this.setState({
            accountStatement: false
        })
    };

    renderButton = () => {
        if (this.state.getData) {
            return (

                <div style={{marginTop: "5px", marginBottom: "5px"}}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={(e) => this.handleSubmit(e, "APPROVED")}
                    >Submit
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={(e) => this.handleSubmit(e, "RETURN")}
                    >Return
                    </button>
                </div>

            )
        }
    };

    renderRemarks = () => {
        if (this.state.getData) {

            return (
                <div style={{marginTop: "5px"}}>
                    {CommonJsonFormComponent.renderJsonForm(this.state, makerRemarks, this.updateComponent)}

                </div>
            )
        }
    };


    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Card>
                        <CardBody>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.loading}>
                                <DialogContent className={classes.dialogPaper}>
                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.confirmationAlert}>
                                <DialogContent className={classes.dialogPaper}>
                                    <ConfirmationModal
                                        closeModal={this.closeConfirmation}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="xl"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.accountStatement}>
                                <DialogContent className={classes.dialogPaper}>
                                    <AccountStatement closeModal={this.closeStatement}
                                                      accountNumber={this.state.inputData.accountNumber}/>
                                </DialogContent>
                            </Dialog>
                            <Grid container spacing={0}>
                                <ThemeProvider theme={theme}>
                                    {this.renderNotification()}
                                    {this.renderJsonFormFirst()}
                                </ThemeProvider>
                            </Grid>
                            <ThemeProvider theme={theme}>
                                {this.renderFileUpload()}
                                {this.renderRemarksData()}
                                <br/>
                                {this.viewAttachedImages()}
                                {this.renderRemarks()}
                            </ThemeProvider>
                            {this.renderButton()}
                        </CardBody>
                    </Card>
                </div>
            </GridList>
        )
    }

}

export default withStyles(styles)(MakerInbox);


