import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../Components/Grid/GridItem.jsx";
import GridContainer from "../Components/Grid/GridContainer.jsx";
import Table from "../Components/Table/Table";
import Card from "../Components/Card/Card.jsx";
import CardHeader from "../Components/Card/CardHeader.jsx";
import CardBody from "../Components/Card/CardBody.jsx";
import "../Static/css/RelationShipView.css";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#142398",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};


class CustomerServedList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: 'null'
        }

    }

    componentDidMount() {

        this.setState({
            selectedCategory: 'daily'
        })

    }

    handleSelected = event => {
        if (event.target.value === 'daily') {
            this.setState({
                selectedCategory: 'daily'
            })
        } else if (event.target.value === 'monthly') {
            this.setState({
                selectedCategory: 'monthly'
            })
        } else if (event.target.value === 'yearly') {
            this.setState({
                selectedCategory: 'yearly'
            })
        } else if (event.target.value === 'quarterly') {
            this.setState({
                selectedCategory: 'quarterly'
            })
        } else {
            this.setState({
                selectedCategory: 'daily'
            })
        }
    };

    render() {
        const {classes} = this.props;

        if (this.state.selectedCategory === 'daily') {


            return (
                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>No of Customer Served</h4>

                            </CardHeader>
                            <center>


                                <br/> <label htmlFor="">Category :</label>
                                <select onChange={this.handleSelected} name="selectedCategory" id="">

                                    <option value="daily">Daily</option>
                                    <option value="monthly">Monthly</option>
                                    <option value="yearly">Yearly</option>
                                    <option value="quarterly">Quarterly</option>
                                </select>


                            </center>

                            <CardBody>

                                {<Table
                                    tableHeaderColor="danger"
                                    tableHead={["Date", "Account Opening", "FD Opening", "Scheme Opening", "AccountMaintenanceCS",
                                        "Locker", "Security Items", "Statement", "Certificate", "Dormant Reactivation", "Unconfirmed Address", "Error"]}

                                    tableData={[
                                        ["01-01-19", "3", "2", "2", "2", "2", "2", "2", "2", "2", "2", "2"],
                                        ["02-01-19", "3", "2", "2", "2", "2", "2", "2", "2", "2", "2", "2"],

                                    ]}
                                    tableAllign={['left', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right']}
                                />}

                            </CardBody>
                        </Card>
                    </GridItem>


                </GridContainer>
            );
        } else if (this.state.selectedCategory === 'monthly') {
            return (
                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>No of Customer Served</h4>

                            </CardHeader>
                            <center>


                                <br/> <label htmlFor="">Category :</label>
                                <select onChange={this.handleSelected} name="selectedCategory" id="">

                                    <option value="daily">Daily</option>
                                    <option value="monthly">Monthly</option>
                                    <option value="yearly">Yearly</option>
                                    <option value="quarterly">Quarterly</option>
                                </select>


                            </center>

                            <CardBody>

                                {<Table
                                    tableHeaderColor="danger"
                                    tableHead={["Month", "Account Opening", "FD Opening", "Scheme Opening", "AccountMaintenanceCS",
                                        "Locker", "Security Items", "Statement", "Certificate", "Dormant Reactivation", "Unconfirmed Address", "Error"]}

                                    tableData={[
                                        ["January", "3", "2", "2", "2", "2", "2", "2", "2", "2", "2", "2"],
                                        ["February", "3", "2", "2", "2", "2", "2", "2", "2", "2", "2", "2"],

                                    ]}
                                    tableAllign={['left', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right']}
                                />}

                            </CardBody>
                        </Card>
                    </GridItem>


                </GridContainer>
            );
        } else if (this.state.selectedCategory === 'yearly') {
            return (
                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>No of Customer Served</h4>

                            </CardHeader>
                            <center>


                                <br/> <label htmlFor="">Category :</label>
                                <select onChange={this.handleSelected} name="selectedCategory" id="">

                                    <option value="daily">Daily</option>
                                    <option value="monthly">Monthly</option>
                                    <option value="yearly">Yearly</option>
                                    <option value="quarterly">Quarterly</option>
                                </select>


                            </center>

                            <CardBody>

                                {<Table
                                    tableHeaderColor="danger"
                                    tableHead={["Year", "Account Opening", "FD Opening", "Scheme Opening", "AccountMaintenanceCS",
                                        "Locker", "Security Items", "Statement", "Certificate", "Dormant Reactivation", "Unconfirmed Address", "Error"]}

                                    tableData={[
                                        ["2018", "3", "2", "2", "2", "2", "2", "2", "2", "2", "2", "2"],
                                        ["2019", "3", "2", "2", "2", "2", "2", "2", "2", "2", "2", "2"],

                                    ]}
                                    tableAllign={['left', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right']}
                                />}

                            </CardBody>
                        </Card>
                    </GridItem>


                </GridContainer>
            );
        } else if (this.state.selectedCategory === 'quarterly') {
            return (
                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>No of Customer Served</h4>

                            </CardHeader>
                            <center>


                                <br/> <label htmlFor="">Category :</label>
                                <select onChange={this.handleSelected} name="selectedCategory" id="">

                                    <option value="daily">Daily</option>
                                    <option value="monthly">Monthly</option>
                                    <option value="yearly">Yearly</option>
                                    <option value="quarterly">Quarterly</option>
                                </select>


                            </center>

                            <CardBody>

                                {<Table
                                    tableHeaderColor="danger"
                                    tableHead={["Quarter", "Account Opening", "FD Opening", "Scheme Opening", "AccountMaintenanceCS",
                                        "Locker", "Security Items", "Statement", "Certificate", "Dormant Reactivation", "Unconfirmed Address", "Error"]}

                                    tableData={[
                                        ["january-june", "3", "2", "2", "2", "2", "2", "2", "2", "2", "2", "2"],
                                        ["july-December", "3", "2", "2", "2", "2", "2", "2", "2", "2", "2", "2"],

                                    ]}
                                    tableAllign={['left', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right']}

                                />}

                            </CardBody>
                        </Card>
                    </GridItem>


                </GridContainer>
            );
        } else {
            return (
                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>No of Customer Served</h4>

                            </CardHeader>
                            <center>


                                <br/> <label htmlFor="">Category :</label>
                                <select onChange={this.handleSelected} name="selectedCategory" id="">

                                    <option value="daily">Daily</option>
                                    <option value="monthly">Monthly</option>
                                    <option value="yearly">Yearly</option>
                                    <option value="quarterly">Quarterly</option>
                                </select>


                            </center>

                            <CardBody>

                                {<Table
                                    tableHeaderColor="danger"
                                    tableHead={["Date", "Account Opening", "FD Opening", "Scheme Opening", "AccountMaintenanceCS",
                                        "Locker", "Security Items", "Statement", "Certificate", "Dormant Reactivation", "Unconfirmed Address", "Error"]}
                                    tableData={[
                                        ["01-01-19", "3", "2", "2", "2", "2", "2", "2", "2", "2", "2", "2"],
                                        ["02-01-19", "3", "2", "2", "2", "2", "2", "2", "2", "2", "2", "2"],

                                    ]}

                                    tableAllign={['left', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right']}
                                />}

                            </CardBody>
                        </Card>
                    </GridItem>


                </GridContainer>
            );
        }
    }

}

export default withStyles(styles)(CustomerServedList);
