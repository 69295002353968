import React, {Component} from "react";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios/index";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import Grid from "@material-ui/core/Grid/index";
import withStyles from "@material-ui/core/styles/withStyles";
import CardHeader from "../../Card/CardHeader";
import CloseIcon from '@material-ui/icons/Close';

import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../JsonForm/CustomeTheme";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {Dialog} from "@material-ui/core/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import AccountNoGenerate from "./AccountNoGenerate";
import GridList from "@material-ui/core/GridList";
import VerifyMakerPhoto from "../VerifyMakerPhoto";
import {CBDataJsonForm, CBDataJsonFormNew, CBDataJsonFormReadOnly} from "../WorkflowJsonForm4";
import ErrorModal from "../CommonComponent/ErrorModal";
import loader from "../../../Static/loader.gif";
import Functions from "../../../Common/Functions";
import MyValidation from "../../JsonForm/MyValidation";


const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

    button: {
        margin: theme.spacing(1),
    }
});

/*const fileUpload =  [
    {
        "varName":"customerId",
        "type":"text",
        "label":"Customer Id",
        "grid":6,
        "length":9,


    },

    {
        "varName":"title",
        "type":"text",
        "label":"Title",
        "grid":6,


        required:true,
    },

    {
        "varName":"customerName",
        "type":"text",
        "label":"Customer Name",
        "grid":6,
        "length":80,


    },

    {
        "varName":"shortName",
        "type":"text",
        "label":"Short Name",
        "grid":6,
        "length":10,


    },

    {
        "varName":"status",
        "type":"text",
        "label":"Status",
        "grid":6,


        required:true,
    },

    {
        "varName":"statusAsOnDate",
        "type":"date",
        "label":"Status as on Date",
        "grid":6
    },

    {
        "varName":"acManager",
        "type":"text",
        "label":"AC Manager",
        "grid":6,



    },

    {
        "varName":"occupationCode",
        "type":"text",
        "label":"Occuoation Code",
        "grid":6,


        required:true,
    },

    {
        "varName":"constitution",
        "type":"text",
        "label":"Constitution",
        "grid":6,


        required:true,
    },

    {
        "varName":"gender",
        "type":"text",
        "label":"Gender",
        "grid":6,


        required:true,
    },

    {
        "varName":"staffFlag",
        "type":"text",
        "label":"Staff Flag",
        "grid":6,


        required:true,
    },

    {
        "varName":"staffNumber",
        "type":"text",
        "label":"Staff Number",
        "grid":6,


        required:true,
    },

    {
        "varName":"minor",
        "type":"text",
        "label":"Minor",
        "grid":6,



    },

    {
        "varName":"nonResident",
        "type":"text",
        "label":"Non Resident",
        "grid":6,


        required:true,
    },

    {
        "varName":"trade",
        "type":"text",
        "label":"Trade",
        "grid":6,


        required:true,
    },

    {
        "varName":"nationalIdCard",
        "type":"text",
        "label":"National ID Card",
        "grid":6,


        required:true,
    },

    {
        "varName":"dateOfBirth",
        "type":"date",
        "label":"Date of Birth",
        "grid":6,



    },

    {
        "varName":"introducerCustomerId",
        "type":"text",
        "label":"Introducer Customer Id",
        "grid":6,
        "length":9,

        required:true,
    },

    {
        "varName":"introducerName",
        "type":"text",
        "label":"Introducer Name",
        "grid":6,



    },

    {
        "varName":"introducerStaff",
        "type":"text",
        "label":"Introducer Staff",
        "grid":6,



    },

    {
        "varName":"maritialStatus",
        "type":"text",
        "label":"Maritial Status",
        "grid":6,


        required:true,
    },

    {
        "varName":"father",
        "type":"text",
        "label":"Father",
        "grid":6,


        required:true,
    },

    {
        "varName":"mother",
        "type":"text",
        "label":"Mother",
        "grid":6,


        required:true,
    },

    {
        "varName":"spouse",
        "type":"text",
        "label":"Spouse",
        "grid":6,


        required:true,
    },

    {
        "varName":"communicationAddress1",
        "type":"text",
        "label":"Communication Address1",
        "grid":6,



    },

    {
        "varName":"communicationAddress2",
        "type":"text",
        "label":"Communication Address2",
        "grid":6,



    },

    {
        "varName":"city1",
        "type":"text",
        "label":"City",
        "grid":6,


        required:true,
    },

    {
        "varName":"state1",
        "type":"text",
        "label":"State",
        "grid":6,


        required:true,
    },

    {
        "varName":"postalCode1",
        "type":"text",
        "label":"Postal Code",
        "grid":6,



    },

    {
        "varName":"country1",
        "type":"text",
        "label":"Country",
        "grid":6,


        required:true,
    },

    {
        "varName":"phoneNo11",
        "type":"text",
        "label":"Phone No1",
        "grid":6,
        "length":11,

        required:true,
    },

    {
        "varName":"phoneNo21",
        "type":"text",
        "label":"Phone No2",
        "grid":6,



    },

    {
        "varName":"telexNo1",
        "type":"text",
        "label":"Telex No",
        "grid":6,



    },

    {
        "varName":"email1",
        "type":"text",
        "label":"Email",
        "grid":6,

        email:true,

    },

    {
        "varName":"permanentAddress1",
        "type":"text",
        "label":"Permanent Address1",
        "grid":6,



    },

    {
        "varName":"permanentAddress2",
        "type":"text",
        "label":"Permanent Address2",
        "grid":6,



    },

    {
        "varName":"city2",
        "type":"text",
        "label":"City",
        "grid":6,


        required:true,
    },

    {
        "varName":"state2",
        "type":"text",
        "label":"State",
        "grid":6,


        required:true,
    },

    {
        "varName":"postalCode2",
        "type":"text",
        "label":"Postal Code",
        "grid":6,



    },

    {
        "varName":"country2",
        "type":"text",
        "label":"Country",
        "grid":6,


        required:true,
    },

    {
        "varName":"phoneNo12",
        "type":"text",
        "label":"Phone No1",
        "grid":6,



    },

    {
        "varName":"phoneNo222",
        "type":"text",
        "label":"Phone No2",
        "grid":6,



    },

    {
        "varName":"telexNo2",
        "type":"text",
        "label":"Telex No",
        "grid":6,



    },

    {
        "varName":"email2",
        "type":"text",
        "label":"Email",
        "grid":6,

        email:true,

    },

    {
        "varName":"employerAddress1",
        "type":"text",
        "label":"Employer Address1",
        "grid":6,



    },

    {
        "varName":"employerAddress2",
        "type":"text",
        "label":"Employer Address2",
        "grid":6,



    },

    {
        "varName":"city3",
        "type":"text",
        "label":"City",
        "grid":6,


        required:true,
    },

    {
        "varName":"state3",
        "type":"text",
        "label":"State",
        "grid":6,


        required:true,
    },

    {
        "varName":"postalCode3",
        "type":"text",
        "label":"Postal Code",
        "grid":6,



    },

    {
        "varName":"country",
        "type":"text",
        "label":"Country",
        "grid":6,


        required:true,
    },

    {
        "varName":"phoneNo13",
        "type":"text",
        "label":"Phone No1",
        "grid":6,



    },

    {
        "varName":"phoneNo23",
        "type":"text",
        "label":"Phone No2",
        "grid":6,



    },

    {
        "varName":"telexNo",
        "type":"text",
        "label":"Telex No",
        "grid":6,



    },

    {
        "varName":"email3",
        "type":"text",
        "label":"Email",
        "grid":6,

        email:true,

    },

    {
        "varName":"faxNo",
        "type":"text",
        "label":"Fax No",
        "grid":6,



    },

    {
        "varName":"combineStatement",
        "type":"text",
        "label":"Combine Statement",
        "grid":6,



    },

    {
        "varName":"tds",
        "type":"text",
        "label":"TDS",
        "grid":6,



    },

    {
        "varName":"pangirNo",
        "type":"text",
        "label":"PANGIR No",
        "grid":6,



    },

    {
        "varName":"passportNo",
        "type":"text",
        "label":"Passport No",
        "grid":6,



    },

    {
        "varName":"issueDate",
        "type":"date",
        "label":"Issue Date",
        "grid":6,



    },

    {
        "varName":"passportDetails",
        "type":"text",
        "label":"Passport Details",
        "grid":6,


        required:true,
    },

    {
        "varName":"expiryDate",
        "type":"date",
        "label":"Expiry Date",
        "grid":6,



    },

    {
        "varName":"purgedAllowed",
        "type":"text",
        "label":"Purged Allowed",
        "grid":6,



    },

    {
        "varName":"freeText2",
        "type":"text",
        "label":"Free Text2",
        "grid":6,



    },

    {
        "varName":"freeText5",
        "type":"text",
        "label":"Free Text 5",
        "grid":6,
        "length":10,


    },

    {
        "varName":"freeText8",
        "type":"text",
        "label":"Free Text 8",
        "grid":6,



    },

    {
        "varName":"freeText9",
        "type":"text",
        "label":"Free Text 9",
        "grid":6,



    },

    {
        "varName":"freeText13",
        "type":"text",
        "label":"Free Text13",
        "grid":6,



    },

    {
        "varName":"freeText14",
        "type":"text",
        "label":"Free Text14",
        "grid":6,


        required:true,
    },

    {
        "varName":"freeText15",
        "type":"text",
        "label":"Free Text15",
        "grid":6,


        required:true,
    },

    {
        "varName":"freeCode1",
        "type":"text",
        "label":"Free Code1",
        "grid":6,


        required:true,
    },

    {
        "varName":"freeCode3",
        "type":"text",
        "label":"Free Code3",
        "grid":6,


        required:true,
    },

    {
        "varName":"freeCode7",
        "type":"text",
        "label":"Free Code 7",
        "grid":6,


        required:true,
    },
];*/
const tagForm = [
    {
        "type": "title",
        "label": "BASIC INFORMATION UPDATE",
        "grid": 6,
    },
    {
        "varName": "basicInformationUpdateGender",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Gender",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateFatherName",
        "type": "text",
        "label": "Father Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateMotherName",
        "type": "text",
        "label": "Mother Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateMaritalStatus",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Marital Status",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateSpouseName",
        "type": "text",
        "label": "Spouse Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateDob",
        "type": "text",
        "label": "DOB",
        "grid": 6,
    },
    {
        "varName": "basicInformationUpdateDobMinor",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "minor",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "GUARDIAN DETAILS",
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "grid": 12,

    },
    {
        "varName": "guardianDetailsBankRelationType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "label": "Bank Relation Type",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsGurdianCifNumber",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "label": "Gurdian CIF Number",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsGuardianSelection",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "label": "Guardian Selection",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsDocumentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsDocumentCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsNationalIdCardnumber",
        "type": "text",
        "label": "National ID CardNumber",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsNidVerificationConfirmationisDocumentVerified",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsDocumentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsDocumentCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsSmartCardNumber",
        "type": "text",
        "label": "Smart Card Number",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsNidVerificationConfirmationisDocumentVerified",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
    },

    {
        "varName": "documentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "documentCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "passportNumber",
        "type": "text",
        "label": "Passport Number",
        "grid": 6,
    },

    {
        "varName": "issueDate",
        "type": "text",
        "label": "Issue Date",
        "grid": 6,
    },

    {
        "varName": "countryOfIssue",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Country of Issue",
        "grid": 6,
    },

    {
        "varName": "expiryDate",
        "type": "text",
        "label": "Expiry Date",
        "grid": 6,
    },

    {
        "varName": "documentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "documentCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "birthCertificateNumber",
        "type": "text",
        "label": "Birth Certificate Number",
        "grid": 6,
    },

    {
        "varName": "placeOfBirthfreeText14",
        "type": "text",
        "label": "Place of Birth/Free Text 14",
        "grid": 6,
    },

    {
        "varName": "countryOfBirthfreeText15",
        "type": "text",
        "label": "Country of Birth/Free Text 15",
        "grid": 6,
    },

    {
        "varName": "nationality",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],

        "label": "Nationality",
        "grid": 6,
    },

    {
        "varName": "residingCountry",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Residing Country",
        "grid": 6,
    },

    {
        "varName": "nonResident",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Non Resident",
        "grid": 6,
    },

    {
        "varName": "minorStatus",
        "type": "text",
        "label": "Minor Status",
        "grid": 6,
    },

    {
        "varName": "eTinNumber(PAN/GIR No)",
        "type": "text",
        "label": "E-Tin Number (PAN/GIR No)",
        "grid": 6,
    },

    {
        "varName": "professionEmploymentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Profession /Employment Type",
        "grid": 6,
    },

    {
        "varName": "nameOfEmployer",
        "type": "text",
        "label": "Name of Employer",
        "grid": 6,
    },

    {
        "varName": "monthlyIncome",
        "type": "text",
        "label": "Monthly Income",
        "grid": 6,
    },

    {
        "varName": "contactType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "phoneNoemailId",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "phoneNo",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "contactType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "phoneNoemailId",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "phoneNo",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "contactType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "phoneNoemailId",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "phoneNo",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "emailType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "emaildesignatedEmailAddress1",
        "type": "text",
        "label": "Email/Designated Email Address 1",
        "grid": 6,
    },

    {
        "varName": "emailType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "emailId2",
        "type": "text",
        "label": "Email ID 2",
        "grid": 6,
    },

    {
        "varName": "addressFormat",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "mailingAddressField1",
        "type": "text",
        "label": "Mailing Address Field 1",
        "grid": 6,
    },

    {
        "varName": "mailingAddressField2",
        "type": "text",
        "label": "Mailing Address Field 2",
        "grid": 6,
    },

    {
        "varName": "mailingAddressField3",
        "type": "text",
        "label": "Mailing Address Field 3",
        "grid": 6,
    },

    {
        "varName": "city",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "state",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "country",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "addressValidForm",
        "type": "text",
        "label": "Address Valid Form",
        "grid": 6,
    },

    {
        "varName": "addressFormat",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "homeAddressField1",
        "type": "text",
        "label": "Home Address Field 1",
        "grid": 6,
    },

    {
        "varName": "homeAddressField2",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 6,
    },

    {
        "varName": "homeAddressField3",
        "type": "text",
        "label": "Home Address Field 3",
        "grid": 6,
    },

    {
        "varName": "city",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "state",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "country",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "addressValidForm",
        "type": "text",
        "label": "Address Valid Form",
        "grid": 6,
    },

    {
        "varName": "addressFormat",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "workAddressField1",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 6,
    },

    {
        "varName": "workAddressField2",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 6,
    },

    {
        "varName": "workAddressField3",
        "type": "text",
        "label": "Work Address Field 3",
        "grid": 6,
    },

    {
        "varName": "city",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "state",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "country",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "addressValidForm",
        "type": "text",
        "label": "Address Valid Form",
        "grid": 6,
    },

    {
        "varName": "relationType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Relation Type",
        "grid": 6,
    },

    {
        "varName": "relationCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Relation Code",
        "grid": 6,
    },

    {
        "varName": "designationCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Designation Code",
        "grid": 6,
    },

    {
        "varName": "cifId",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "CIF ID",
        "grid": 6,
    },

    {
        "varName": "deleteFlag",
        "type": "text",
        "label": "Delete Flag",
        "grid": 6,
    },
];
var selectFileName = {
    "varName": "fileName",
    "type": "dropdown",
    "required": true,
    "label": "This photo name",

    "grid": 6
};

class SecondaryCbFormDetails extends Component {
    state = {
        fileUploadData: {},
        getSplitFile: [],
        multipleScanningphotoShow: null,
        message: "",
        appData: {},
        getData: false,
        placeOfIssueList: "",
        varValue: {},
        showValue: false,
        redirectLogin: false,
        title: "",
        notificationMessage: "",
        alert: false,
        inputData: {tagList: {}},
        selectedDate: {},
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        values: [],
        customerName: [],
        deferalType: [],
        expireDate: [],
        other: [],
        getCheckerList: [],
        getAllDefferal: [],
        getDeferalList: [],
        occupationCodeList: [],
        loading: true,
        jointAccountCustomerNumber: 0,
        objectForNominee: [],
        getgenerateForm: false,
        renderCumModalopen: false,
        generateAccountNo: '',
        getDedupData: {},
        jointDedupData: {},
        jointSearchTableData: [],
        propritorshipData: [],
        dedupData: [],
        tagClick: false,
        getTaggingList: {},
        getTaggingCustomerList: {},
        taggingData: [],
        relatedData: {},
        searchTableData: false,
        searchTableRelatedData: false,
        imageCropModal: false,
        errorModalBoolean: false,
        cbGenerateModal: false,
        getRemarks: [], err: false,
        errorArray: {},
        errorMessages: {},
        getMappingCropImage: false,
        nomineeNumber: 0,
        getNominee: false,
        errorMessage: "Invalid Code",
        type: "successs",
        city: [],
        dispatchMode: [],
        getCity: false,
        stateCode: [],
        getGuardianCode: [],
        savingsSchemeCodeList: [],
        currentSchemeCodeList: [],
        getState: false,
        country: [],
        titleList: [],
        chequebookWaiverType: [],
        rmCodeList: [],
        phoneEmailType: [],
        phoneOrEmail: [],
        getCountry: false,
        currency: [],
        relationshipList: [],
        constitutionList: [],
        customerStatusList: [],
        waiverFieldList: [],
        ccepCodeList: [],
        introducerStatus: [],
        relationCodeList: [],
        getCurrency: false,
        confirmAlert: false,
        actionType: "",
        despatchmodeList: [],
        sectorCodeList: [],
        subsectorCodeList: [],
        depositCodeList: [],
        tradeServiceList: [],
        agentBankingOutletList: [],
        subSegmentList: [],
        maritalStatusList: [],
        cbDataAfterSet: false,
        accountDataAfterSet: false,
        kycAutopopulateFlag: false,
        TpAutopopulateFlag: false,
        phoneNumCountryCodeList: [],
        documentList: [],
        getDocument: false

    };

    constructor(props) {
        super(props);


    }


    updateComponent = () => {
        this.forceUpdate();
    };


    close = () => {
        this.props.closeModal();
    };
    closeModal = (account) => {

        this.props.taging(this.props.tagingModalCbnumber);
        this.props.closeModal(account, "");
        this.setState({
            accountNo: false
        })
    };
    handleSubmit = (event) => {

        event.preventDefault();
        let error = MyValidation.defaultValidation(this.emptyValueRemove(CBDataJsonFormNew), this.state);
        this.forceUpdate();
        if (error === true && this.props.makerEnd === true) {
            return 0;

        }
        let index = this.props.tagingModalCbnumber + 1;
        let object = {};
        object.appId = this.props.appId;
        object.person = this.props.tagingModalCbnumber;
        if (this.props.makerEnd === true && this.state.inputData.apiButton === "COMPLETE") {

            this.state.inputData.apiButton = "CB UPDATE";
        }
        this.state.inputData.requestType = this.props.requestType;
        object.appData = this.state.inputData;
        object.requestType = this.props.requestType;

        console.log(object);
        console.log(this.props.requestType);
        this.setState({
            loading: true,
        });

        console.log(object);
        if (this.props.makerEnd !== true) {
            if (this.props.requestType === "New") {
                if (this.state.inputData.apiButton === "CB CREATION") {
                    let url = backEndServerURL + "/secondaryCBCreation/" + this.props.appId + "/" + this.props.customerNumber;
                    axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            let cb = response.data;
                            object.cb = cb;
                            object.cbNumber = cb;
                            if(this.props.processType !== undefined && this.props.processType === "Mandate"){
                                object.requestType = "CB Created";
                            }
                            object.appData.cbNumber = cb;
                            this.state.inputData.apiButton = "CB UPDATE";
                            this.state.varValue.apiButton = "CB UPDATE";
                            this.state.inputData.newCbNUmber = cb;
                            this.state.varValue.newCbNUmber = cb;
                            this.forceUpdate();
                            let urlsecondaryCB = backEndServerURL + "/secondaryCB";
                            axios.post(urlsecondaryCB, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    console.log(response.data);
                                    /*   let url = backEndServerURL + "/secondaryCBUpdate/" + this.props.appId + "/" + this.props.customerNumber + "/" + cb;
                                       axios.post(url, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                           .then((response) => {
                                               console.log(response.data);
                                               object.requestType = "Completed";
                                               object.cb = cb;
                                               let urlsecondaryCB = backEndServerURL + "/secondaryCB";

                                               axios.post(urlsecondaryCB, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                   .then((response) => {
                                                       console.log(response.data);
                                                       // this.props.mandatorySubmit(object.cb);
                                                       this.props.getTaggingData();
                                                       this.props.closeModal(this.props.customerNumber);
                                                       this.setState({
                                                           loading: false,
                                                       })
                                                   })
                                                   .catch((error) => {
                                                       console.log(error);
                                                       this.setState({
                                                           loading: false,
                                                           errorMessage: "Finacle System Error!",
                                                           errorModalBoolean: true
                                                       })
                                                   })


                                           })
                                           .catch((error) => {
                                               console.log(error);
                                               this.setState({
                                                   loading: false,
                                                   errorMessage: this.errorObjectCheck(error),
                                                   errorModalBoolean: true
                                               })
                                           })
       */
                                    this.props.getTaggingData();
                                    this.setState({
                                        loading: false,
                                    })
                                })
                                .catch((error) => {
                                    this.setState({
                                        loading: false,
                                        errorModalBoolean: true,
                                        errorMessage: this.errorObjectCheck(error),
                                    })
                                })
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false,
                                errorMessage: this.errorObjectCheck(error),
                                errorModalBoolean: true
                            })
                        })
                }
                else if (this.state.inputData.apiButton === "CB UPDATE") {
                    let fileUrl = backEndServerURL + "/secondaryCB/" + this.props.appId + "/" + this.props.customerNumber;
                    axios.get(fileUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            let data = response.data;
                            let url = backEndServerURL + "/secondaryCBUpdate/" + this.props.appId + "/" + this.props.customerNumber + "/" + this.state.inputData.newCbNUmber;
                            axios.post(url, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    console.log(response.data);
                                    this.state.inputData.apiButton = "COMPLETE";
                                    this.state.varValue.apiButton = "COMPLETE";
                                    this.forceUpdate();
                                    object.requestType = "Completed";
                                    object.cb = this.state.inputData.newCbNUmber;
                                    object.cbNumber = this.state.inputData.newCbNUmber;
                                    object.appData.cbNumber = this.state.inputData.newCbNUmber;
                                    let urlsecondaryCB = backEndServerURL + "/secondaryCB";

                                    axios.post(urlsecondaryCB, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response) => {
                                            console.log(response.data);
                                            // this.props.mandatorySubmit(object.cb);
                                            this.props.getTaggingData();
                                            this.props.closeModal(this.props.customerNumber);
                                            this.setState({
                                                loading: false,
                                            })
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            this.setState({
                                                loading: false,
                                                errorMessage: "Finacle System Error!",
                                                errorModalBoolean: true
                                            })
                                        })


                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.setState({
                                        loading: false,
                                        errorMessage: this.errorObjectCheck(error),
                                        errorModalBoolean: true
                                    })
                                })
                        })
                        .catch((error) => {
                            console.log(error);
                        })

                }
            } else {

                object.cb = this.props.cbNumber;
                object.cbNumber = this.props.cbNumber;
                object.appData.cbNumber = this.props.cbNumber;
                let url = backEndServerURL + "/secondaryCBUpdate/" + this.props.appId + "/" + object.person + "/" + this.props.cbNumber;
                axios.post(url, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);
                        this.state.inputData.apiButton = "COMPLETE";
                        this.state.varValue.apiButton = "COMPLETE";
                        this.forceUpdate();
                        object.requestType = "Completed";
                        object.cb = this.props.cbNumber;
                        object.cbNumber = this.props.cbNumber;
                        object.appData.cbNumber = this.props.cbNumber;
                        let urlsecondaryCB = backEndServerURL + "/secondaryCB";
                        axios.post(urlsecondaryCB, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                // this.props.mandatorySubmit(object.cb);
                                this.props.getTaggingData();
                                this.props.closeModal(this.props.customerNumber);
                                this.setState({
                                    loading: false,
                                })
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    loading: false,
                                    errorMessage: "Finacle System Error!",
                                    errorModalBoolean: true
                                })
                            })
                    })
                    .catch((error) => {
                        this.setState({
                            loading: false,
                            errorModalBoolean: true,
                            errorMessage: this.errorObjectCheck(error),
                        })
                    })
            }
        } else {
            console.log(this.state.inputData);
            object.person = this.props.customerNumber;
            this.setState({
                loading: true,
            });

            object.requestType = this.props.requestType;
            object.cb = this.props.cbNumber;
            object.cbNumber = this.props.cbNumber;
            object.appData.cbNumber = this.props.cbNumber;
            let urlsecondaryCB = backEndServerURL + "/secondaryCB";
            axios.post(urlsecondaryCB, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.props.closeModal();
                    this.props.renderTagging();
                })
                .catch((error) => {
                    console.log(error)
                })

        }


    };

    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "System Error!"
                }
            } else {
                return "System Error!"
            }
        } else {
            return "System Error!"
        }
    };

    renderImage = () => {
        if (this.state.getDocument) {
            return (<VerifyMakerPhoto documentList={this.state.documentList}/>)
            // return (
            // <SecondaryCBImage customerNumber={this.props.customerNumber} closeModal={this.props.closeModal}
            //                   documentList={this.state.documentList}
            // />
            // )
        }
    };
    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = CBDataJsonFormReadOnly;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];

            if (jsonObject.varName === searchVarname) {
                CBDataJsonFormReadOnly[i].enum = getValue;

            }
        }

        jsonArray = CBDataJsonForm;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                CBDataJsonForm[i].enum = getValue;

            }
        }


    };
    dynamicApiCallDocumentType = (fieldName, apiType) => {
        let codeUrl = backEndServerURL + "/finMasterData/custom/uniqueDocType/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (apiType === "documentCodeDocumentType") {
                    // this.findByVarNameApiData("natioanlIdCarddocumentTypeN", response.data)
                    //  this.findByVarNameApiData("smartIdCarddocumentTypeS", response.data)
                    //  this.findByVarNameApiData("passportNumberdocumentTypeP", response.data)
                    //  this.findByVarNameApiData("birthCertificatedocumentTypeB", response.data)
                    // this.findByVarNameApiData("chairmanCertificatedocumentTypeCC", response.data)
                    // this.findByVarNameApiData("etindocumentType", response.data)
                    this.findByVarNameApiData("photoIddocumentType", response.data)

                } else if (apiType === "documentCodeDocumentTypeDesc") {
                    // this.findByVarNameApiData("natioanlIdCarddocumentTypeDescriptionN", response.data)
                    //  this.findByVarNameApiData("smartIdCarddocumentTypeDescriptionS", response.data)
                    // this.findByVarNameApiData("passportNumberdocumentTypeDescriptionP", response.data)
                    //  this.findByVarNameApiData("birthCertificatedocumentTypeDescriptionB", response.data)
                    //  this.findByVarNameApiData("chairmanCertificatedocumentTypeDescriptionCC", response.data)
                    //  this.findByVarNameApiData("etindocumentTypeDescription", response.data)
                    this.findByVarNameApiData("photoIddocumentTypeDescription", response.data)

                }

            })
            .catch((error) => {
                console.log(error)
            })
    };
    dynamicApiCall = (fieldName, apiType) => {
        let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(fieldName);
                console.log(response);
                if (apiType === "city") {
                    this.findByVarNameApiData("maillingAdresscity", response.data);
                    this.findByVarNameApiData("homeAddresscity", response.data);
                    this.findByVarNameApiData("workAddresscity", response.data);
                    this.findByVarNameApiData("presentAddresscity", response.data);
                    this.findByVarNameApiData("nreAddresscity", response.data);
                    this.findByVarNameApiData("mailingAddresscity1", response.data);
                    this.findByVarNameApiData("homeAddresscity1", response.data);
                    this.findByVarNameApiData("workAddresscity1", response.data);
                    this.findByVarNameApiData("workAddresscity2", response.data);


                    this.setState({
                        city: response.data,
                        getCity: true
                    })

                } else if (apiType === "state") {

                    this.findByVarNameApiData("maillingAdressstate", response.data);
                    this.findByVarNameApiData("homeAddressstate", response.data);
                    this.findByVarNameApiData("workAddressstate", response.data);
                    this.findByVarNameApiData("presentAddressstate", response.data);
                    this.findByVarNameApiData("nreAddressstate", response.data);
                    this.findByVarNameApiData("mailingAddressstate1", response.data);
                    this.findByVarNameApiData("homeAddressstate1", response.data);
                    this.findByVarNameApiData("workAddressstate1", response.data);
                    this.findByVarNameApiData("workAddressstate2", response.data);

                    this.setState({
                        stateCode: response.data,
                        getState: true,
                    })
                } else if (apiType === "country") {
                    this.findByVarNameApiData("passportNumbercountryOfIssue", response.data);
                    this.findByVarNameApiData("maillingAdresscountry", response.data);
                    this.findByVarNameApiData("homeAddresscountry", response.data);
                    this.findByVarNameApiData("workAddresscountry", response.data);
                    this.findByVarNameApiData("Customersnationality", response.data);
                    this.findByVarNameApiData("presentAddresscountry", response.data);
                    this.findByVarNameApiData("nreAddresscountry", response.data);
                    this.findByVarNameApiData("mailingAddresscountry1", response.data);
                    this.findByVarNameApiData("homeAddresscountry1", response.data);
                    this.findByVarNameApiData("workAddresscountry1", response.data);
                    this.findByVarNameApiData("workAddresscountry2", response.data);


                    this.setState({
                        country: response.data,
                        getCountry: true
                    })
                } else if (apiType === "placeOfIssue") {
                    this.findByVarNameApiData("passportNumberplaceOfIssue", response.data);
                    this.setState({
                        placeOfIssueList: response.data
                    })
                } else if (apiType === "currency") {

                    //this.findByVarNameApiData("workAddressCurrency", response.data)
                    //  this.findByVarNameApiData("homeAddressCurrency", response.data)
                    this.findByVarNameApiData("aof1currencyCodeCcy", response.data);

                    this.setState({
                        currency: response.data,
                        getCurrency: true
                    })
                } else if (apiType === "addressType") {
                    //this.findByVarNameApiData("workAddressaddressType", response.data)
                    // this.findByVarNameApiData("maillingAdressaddrssType", response.data)
                    //this.findByVarNameApiData("maillingAdresspreferredAddressType", response.data)
                    // this.findByVarNameApiData("homeAddressaddrssType", response.data)
                    // this.findByVarNameApiData("presentAddressaddrssType", response.data)
                    //this.findByVarNameApiData("nreAddressaddrssType", response.data)

                } else if (apiType === "phoneCountryCode") {

                    this.findByVarNameApiData("countryCodecontactNumberphoneNo1", response.data);
                    this.findByVarNameApiData("countryCodecontactNumberphoneNo2", response.data);
                    this.findByVarNameApiData("countryCodecontactNumberphoneNo3", response.data);
                    this.setState({
                        phoneNumCountryCodeList: response.data
                    })
                } else if (apiType === "customerStatus") {
                    this.findByVarNameApiData("miscustomerStatusNormalStaffPriorityEtc", response.data);
                    this.setState({
                        customerStatusList: response.data
                    })
                } else if (apiType === "savingsSchemeCode") {

                    this.setState({
                        savingsSchemeCodeList: response.data,
                    })
                } else if (apiType === "currentSchemeCode") {

                    this.setState({
                        currentSchemeCodeList: response.data,
                    })
                } else if (apiType === "sourceOfFund") {
                    this.findByVarNameApiData("sourceOfFund", response.data);
                    this.findByVarNameApiData("documentsCollectedToEnsureTheSourceOfFund", response.data)

                } else if (apiType === "constitution") {
                    this.findByVarNameApiData("misconstitution", response.data);
                    this.setState({
                        constitutionList: response.data
                    })

                } else if (apiType === "monthlyIncome") {
                    this.findByVarNameApiData("customerMonthlyIncome", response.data)


                } else if (apiType === "accountOpen") {
                    this.findByVarNameApiData("howWasAccountOpened", response.data)


                } else if (apiType === "designationCode") {
                    this.findByVarNameApiData("relatedCbTaggingdesignationCode", response.data)

                } else if (apiType === "tradeService") {
                    this.findByVarNameApiData("misavailedTradeServices", response.data);
                    this.setState({
                        tradeServiceList: response.data
                    })

                } else if (apiType === "modOfOperation") {
                    this.findByVarNameApiData("schemeCodeChangemodeOfOperation", response.data);
                    this.setState({
                        despatchmodeList: response.data
                    })

                } else if (apiType === "introducerStatus") {
                    this.findByVarNameApiData("primryiintroducerStatus", response.data);
                    this.setState({
                        introducerStatus: response.data
                    })
                } else if (apiType === "bbkSegmentCode") {
                    this.findByVarNameApiData("misbbkSegmentCodeFreeCode7", response.data)
                } else if (apiType === "priorityCenterCode") {
                    //this.findByVarNameApiData("mispriorityCenterCodeFreeCode1", response.data)
                } else if (apiType === "contactType") {
                    //this.findByVarNameApiData("contactNumbercontactType1", response.data)
                    // this.findByVarNameApiData("contactNumbercontactType2", response.data)
                    //this.findByVarNameApiData("contactNumbercontactType3", response.data)
                    // this.findByVarNameApiData("contactNumberpreferredContactNoType1", response.data)

                } /*else if (apiType === "addressType") {
                    this.findByVarNameApiData("workAddressaddressType", response.data)
                    this.findByVarNameApiData("maillingAdressaddrssType", response.data)
                    this.findByVarNameApiData("maillingAdresspreferredAddressType", response.data)
                    this.findByVarNameApiData("homeAddressaddrssType", response.data)
                    this.findByVarNameApiData("presentAddressaddrssType", response.data)

                } */ else if (apiType === "guardianCode") {
                    this.findByVarNameApiData("guardianDetailsguardianSelectionGurdian", response.data);


                    this.setState({
                        getGuardianCode: response.data
                    })

                } else if (apiType === "accountStatement") {
                    //this.findByVarNameApiData("fincoreDataUpdateaccountStatement", response.data)
                    //  this.findByVarNameApiData("fincoreDataUpdateaccountStatement", response.data)
                } else if (apiType === "subSegment") {
                    this.findByVarNameApiData("crmMissubSegment", response.data);
                    this.setState({
                        subSegmentList: response.data
                    })

                } else if (apiType === "subSectorCode") {
                    this.findByVarNameApiData("fincoreDataUpdatesubSectorCode", response.data);
                    this.setState({
                        subsectorCodeList: response.data
                    })
                } else if (apiType === "sectorCode") {
                    this.findByVarNameApiData("fincoreDataUpdatesectorCode", response.data);
                    this.setState({
                        sectorCodeList: response.data
                    })
                } else if (apiType === "depositCode") {
                    this.findByVarNameApiData("fincoreDataUpdatedepositCodeFreeCode7", response.data);
                    this.setState({
                        depositCodeList: response.data
                    })
                } else if (apiType === "dispatchMode") {
                    this.findByVarNameApiData("aof1despatchModePrintedEStatementBoth", response.data);
                    this.findByVarNameApiData("fincoreDataUpdatedispatchMode", response.data);
                    console.log("llllllllllll");
                    console.log(response.data);
                    this.setState({
                        dispatchMode: response.data
                    })
                } else if (apiType === "rmCode") {
                    this.findByVarNameApiData("mispriorityMonitoringRmCodeFreeCode3", response.data);
                    this.setState({
                        rmCodeList: response.dat
                    })

                } else if (apiType === "accountStatement") {
                    this.findByVarNameApiData("aof1statement", response.data)
                    //this.findByVarNameApiData("fincoreDataUpdateaccountStatement", response.data)
                } else if (apiType === "statementFrequency") {
                    this.findByVarNameApiData("fincoreDataUpdatestatementFrequency", response.data);
                    this.findByVarNameApiData("aof1statement", response.data)
                } else if (apiType === "chequebookWaiverFunctionCode") {
                    // this.findByVarNameApiData("misfunctionCodeForChequeBookWaiver", response.data)
                    // this.findByVarNameApiData("schemeCodeChangefunctionForSchemeChangeInCaseOfInstaPack", response.data)
                } else if (apiType === "chequebookWaiverType") {
                    this.findByVarNameApiData("miswaiverTypeForChequeBookWaiver", response.data);
                    this.setState({
                        chequebookWaiverType: response.data
                    })
                } else if (apiType === "branch") {
                    this.findByVarNameApiData("otherBankbranchName", response.data)

                } else if (apiType === "relationCode") {
                    this.findByVarNameApiData("relatedCbTaggingrelationCode", response.data);
                    this.setState({
                        relationCodeList: response.data
                    })
                } else if (apiType === "addressFormat") {
                    this.findByVarNameApiData("maillingAdressaddressFormat", response.data);
                    this.findByVarNameApiData("homeAddressaddressFormat", response.data);
                    this.findByVarNameApiData("workAddressaddressFormat", response.data);
                    this.findByVarNameApiData("presentAddressaddressFormat", response.data);
                    this.findByVarNameApiData("mailingAddressaddressFormat1", response.data);
                    this.findByVarNameApiData("homeAddressaddressFormat1", response.data);
                    this.findByVarNameApiData("workAddressaddressFormat1", response.data);
                    this.findByVarNameApiData("workAddressaddressFormat2", response.data)

                } else if (apiType === "agentBankingOutlet") {
                    this.findByVarNameApiData("misagentBankingOutletFreeCode1", response.data);
                    this.setState({
                        agentBankingOutletList: response.data
                    })
                } else if (apiType === "waiverField") {
                    this.findByVarNameApiData("miswaiverFieldFreeCode10", response.data);
                    this.setState({
                        waiverFieldList: response.data
                    })
                    // this.findByVarNameApiData("misdebitCardIssueFreeCode10", response.data)

                } else if (apiType === "ccepCode") {
                    this.findByVarNameApiData("misccepCodeFreeCode9", response.data);
                    this.setState({
                        ccepCodeList: response.data
                    })

                } else if (apiType === "nonResident") {
                    this.findByVarNameApiData("chairmanCertificatenonResident", response.data)

                } else if (apiType === "agentBankingOutlet") {
                    this.findByVarNameApiData("misagentBankingOutletFreeCode1", response.data);
                    this.setState({
                        agentBankingOutletList: response.data
                    })
                } else if (apiType === "maritalStatus") {
                    this.findByVarNameApiData("basicInformationUpdatemaritalStatus", response.data);
                    this.findByVarNameApiData("basicInformationmaritalStatus", response.data);
                    this.setState({
                        maritalStatusList: response.data
                    })

                } else if (apiType === "employmentType") {
                    this.findByVarNameApiData("employerDataprofessionEmploymentType", response.data);
                    this.findByVarNameApiData("etinprofessionEmploymentType", response.data);
                    this.findByVarNameApiData("employerDataemploymentType", response.data)
                } else if (apiType === "relationship") {
                    this.findByVarNameApiData("guardianDetailsrelation", response.data);
                    this.setState({
                        relationshipList: response.data
                    })
                } else if (apiType === "relationType") {
                    this.findByVarNameApiData("relatedCbTaggingrelationType", response.data)

                } else if (apiType === "nomineeRelationCode") {
                    this.findByVarNameApiData("guardianDetailsrelation1", response.data)

                } else if (apiType === "contactType") {
                    // this.findByVarNameApiData("contactNumberpreferredContactNoType1", response.data)
                    /*  this.findByVarNameApiData("contactNumbercontactType1", response.data)
                      this.findByVarNameApiData("contactNumbercontactType", response.data)
                      this.findByVarNameApiData("contactNumbercontactType2", response.data)
                      this.findByVarNameApiData("contactNumbercontactType3", response.data)
                      this.findByVarNameApiData("contactNumberpreferredContactNoType1", response.data)
                      this.findByVarNameApiData("contactNumberpreferredContactNoType1", response.data)*/


                } else if (apiType === "title") {
                    this.findByVarNameApiData("mistitle", response.data);
                    this.findByVarNameApiData("relatedCbTaggingtitle", response.data);
                    this.findByVarNameApiData("aCTitle", response.data);
                    this.setState({
                        titleList: response.data
                    })


                } else if (apiType === "documentCode") {
                    //this.findByVarNameApiData("natioanlIdCarddocumentCodeN", response.data)
                    // this.findByVarNameApiData("passportNumberdocumentCodeP", response.data)
                    // this.findByVarNameApiData("birthCertificatedocumentCodeB", response.data)
                    //  this.findByVarNameApiData("chairmanCertificatedocumentCodeCC", response.data)
                    //  this.findByVarNameApiData("smartIdCarddocumentCodeS", response.data)
                    //  this.findByVarNameApiData("nationalIdCarddocumentCode", response.data)
                    //  this.findByVarNameApiData("smartlIdCarddocumentCode", response.data)
                    //  this.findByVarNameApiData("passportNumberdocumentCode", response.data)
                    //   this.findByVarNameApiData("birthCertificatedocumentCode", response.data)
                    // this.findByVarNameApiData("chairmanCertificatedocumentCode", response.data)
                    // this.findByVarNameApiData("etindocumentCode", response.data)
                    this.findByVarNameApiData("photoIddocumentCode", response.data)

                } else if (apiType === "bankName") {
                    this.findByVarNameApiData("otherBankbankName", response.data)


                } else if (apiType === "segment") {
                    this.findByVarNameApiData("misbbkSegmentCodeFreeCode7", response.data)


                } else if (apiType === "phoneOrEmailId") {
                    this.findByVarNameApiData("contactNumberphoneNoEmailId1", response.data);
                    this.findByVarNameApiData("contactNumberphoneNoEmailId2", response.data);
                    this.findByVarNameApiData("contactNumberphoneNoEmailId3", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId4", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId1", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId2", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId", response.data);
                    this.setState({
                        phoneOrEmail: response.data
                    })
                } else if (apiType === "customerOccupation") {

                    this.findByVarNameApiData("customersOccupation", response.data)

                } else if (apiType === "bankRelationType") {

                    this.findByVarNameApiData("guardianDetailsbankRelationType", response.data)

                } else if (apiType === "emailType") {

                    this.findByVarNameApiData("contactNumberEmailemailType1", response.data);
                    this.findByVarNameApiData("contactNumberEmailemailType2", response.data);
                    this.findByVarNameApiData("contactNumberEmailemailType", response.data);
                    this.findByVarNameApiData("contactNumberEmailpreferedEmailIdType", response.data);
                    this.setState({
                        phoneEmailType: response.data
                    })
                } else if (apiType === "addressVariety") {

                    this.findByVarNameApiData("howTheAddressOfTheAccountHolderHasBeenVerified", response.data)

                } else if (apiType === "customerBusiness") {

                    this.findByVarNameApiData("whatDoesTheCustomerDoInWhatTypeOfBusineessIsTheCustomerEngaged", response.data)

                }
                /*  else if (apiType === "accountOpen") {

                      this.findByVarNameApiData("", response.data)

                  }
                  else if (apiType === "monthlyIncome") {

                      this.findByVarNameApiData("", response.data)

                  }*/


            })
            .catch((error) => {
                console.log(error);
            })
    };

    componentDidMount() {
        if(this.props.processType !== undefined){
            let jsonForm = CBDataJsonForm.concat(CBDataJsonFormNew);
            for (let item of jsonForm) {
                if (item.varName === "schemeCodeChangemodeOfOperation" || item.varName === "aof1despatchModePrintedEStatementBoth" || item.varName === "aof1smsAlertFreeText11" || item.varName === "nidVerified") {
                    item.required = false
                }
            }
        }
        this.setState({
            loading: true
        });


        {
            this.dynamicApiCallDocumentType("documentCodeDocumentType", "documentCodeDocumentType")
        }
        {
            this.dynamicApiCallDocumentType("documentCodeDocumentTypeDesc", "documentCodeDocumentTypeDesc")
        }
        {
            this.dynamicApiCall("city2", "city")
        }
        {
            this.dynamicApiCall("state2", "state",)
        }
        {
            this.dynamicApiCall("currency", "currency")
        }
        {
            this.dynamicApiCall("nomineeRelationCode", "nomineeRelationCode")
        }
        {
            this.dynamicApiCall("nationality", "country")
        }
        {
            this.dynamicApiCall("placeOfIssue", "placeOfIssue")
        }
        {
            this.dynamicApiCall("documentsCollectedToEnsureTheSourceOfFund", "sourceOfFund")
        }
        {
            this.dynamicApiCall("customersOccupation", "customerOccupation")
        }
        {
            this.dynamicApiCall("addressVariety", "addressVariety")
        }
        {
            this.dynamicApiCall("whatDoesTheCustomerDoInWhatTypeOfBusineessIsTheCustomerEngaged", "customerBusiness")
        }
        {
            this.dynamicApiCall("monthlyIncome", "monthlyIncome")
        }
        {
            this.dynamicApiCall("howWasAccountOpened", "accountOpen")
        }
        {
            this.dynamicApiCall("constitution", "constitution")
        }
        {
            this.dynamicApiCall("waiverField", "waiverField")
        }
        {
            this.dynamicApiCall("bankRelationType", "bankRelationType")
        }
        /* {
             this.dynamicApiCall("emailType", "emailType")
         }*/
        /*  {
              this.dynamicApiCall("phoneOrEmailId", "phoneOrEmailId")
          }*/

        {
            this.dynamicApiCall("ccepCode", "ccepCode")
        }
        {
            this.dynamicApiCall("maritalStatus", "maritalStatus")
        }

        /* {
             this.dynamicApiCall("bankName", "bankName")
         }*/
        {
            this.dynamicApiCall("title", "title")
        }
        {
            this.dynamicApiCall("documentCode", "documentCode")
        }
        {
            this.dynamicApiCall("relationship", "relationship")
        }
        /*  {
              this.dynamicApiCall("contactType", "contactType")
          }*/
        {
            this.dynamicApiCall("employmentType", "employmentType")
        }
        {
            this.dynamicApiCall("agentBankingOutlet", "agentBankingOutlet")
        }
        {
            this.dynamicApiCall("nonResident", "nonResident")
        }
        {
            this.dynamicApiCall("customerStatus", "customerStatus")
        }
        /*  {
              this.dynamicApiCall("addressFormat", "addressFormat")
          }*/
        /* {
             this.dynamicApiCall("branch", "branch")
         }*/
        {
            this.dynamicApiCall("relationCode", "relationCode")
        }
        {
            this.dynamicApiCall("relationType", "relationType")
        }
        {
            this.dynamicApiCall("dispatchMode", "dispatchMode")
        }
        {
            this.dynamicApiCall("statementFrequency", "statementFrequency")
        }
        {
            this.dynamicApiCall("chequebookWaiverFunctionCode", "chequebookWaiverFunctionCode")
        }
        {
            this.dynamicApiCall("chequebookWaiverType", "chequebookWaiverType")
        }
        /*  {
              this.dynamicApiCall("rmCode", "rmCode")
          }*/
        {
            this.dynamicApiCall("subSegment", "subSegment")
        }

        {
            this.dynamicApiCall("designationCode", "designationCode")
        }
        {
            this.dynamicApiCall("sectorCode", "sectorCode")
        }
        {
            this.dynamicApiCall("subSectorCode", "subSectorCode")
        }
        {
            this.dynamicApiCall("depositCode", "depositCode")
        }
        {
            this.dynamicApiCall("tradeService", "tradeService")
        }
        {
            this.dynamicApiCall("addressType", "addressType")
        }
        {
            this.dynamicApiCall("guardianCode", "guardianCode")
        }
        {
            this.dynamicApiCall("accountStatement", "accountStatement")
        }

        {
            this.dynamicApiCall("modOfOperation", "modOfOperation")
        }
        {
            this.dynamicApiCall("introducerStatus", "introducerStatus")
        }
        {
            this.dynamicApiCall("bbkSegmentCode", "bbkSegmentCode")
        }
        {
            this.dynamicApiCall("priorityCenterCode", "priorityCenterCode")
        }
        {
            this.dynamicApiCall("priorityCenterCode", "priorityCenterCode")
        }
        {
            this.dynamicApiCall("schemeCodeForSavings", "savingsSchemeCode")
        }
        {
            this.dynamicApiCall("schemeCodeForCurrent", "currentSchemeCode")
        }
        {
            this.dynamicApiCall("phoneCountryCode", "phoneCountryCode")
        }


        /*  {
              this.dynamicApiCall("contactType", "contactType")
          }*/

        let occupationCodeUrl = backEndServerURL + "/occupationCode/getAll";
        axios.get(occupationCodeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log("lllllllllllllllllpppp");
                console.log(response.data);
                this.findByVarNameApiData("misoccupationCodePriorityCodeStaff", response.data);
                this.findByVarNameApiData("fincoreDataUpdateoccupationCode", response.data);
                this.setState({
                    occupationCodeList: response.data
                })
                //this.findByVarNameApiData("customersOccupation", response.data)

            })
            .catch((error) => {
                console.log(error)
            });
        if (this.props.rowId !== undefined) {
            let highlightUrl = backEndServerURL + "/secondaryInfo/updatedByMaker/" + this.props.rowId;
            axios.get(highlightUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let fieldVarName = response.data;
                    let jsonForm = CBDataJsonFormReadOnly.concat(CBDataJsonForm);
                    for (let i = 0; i < fieldVarName.length; i++) {
                        let obj = jsonForm.find(item => item.varName === fieldVarName[i]);
                        if (obj !== undefined) {
                            obj.highlight = true
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        let fileUrl = backEndServerURL + "/case/files/" + this.props.appId;
        axios.get(fileUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let fileUrl = backEndServerURL + "/secondaryCB/" + this.props.appId + "/" + this.props.customerNumber;
                axios.get(fileUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);
                        let inputData = this.emptyValueRemove(response.data);
                        let varValue = this.emptyValueRemove(response.data);
                        inputData.miscustomerIdCifId = this.props.cbNumber;
                        varValue.miscustomerIdCifId = this.props.cbNumber;
                        if (this.props.makerEnd !== true && inputData.apiButton === undefined && this.props.requestType === "New") {

                            inputData.apiButton = "CB CREATION";
                            varValue.apiButton = "CB CREATION"
                        } else if (this.props.makerEnd !== true && inputData.apiButton === undefined) {

                            inputData.apiButton = "CB UPDATE";
                            varValue.apiButton = "CB UPDATE";
                        }
                        /* if(response.data.aof1currencyCodeCcy.key!==undefined){
                             inputData.aof1currencyCodeCcy=inputData.currency.key
                             varValue.aof1currencyCodeCcy=varValue.currency.key
                         }*/
                        this.setState({
                            varValue: varValue,
                            inputData: inputData,
                            getData: true,
                            showValue: true,
                            loading: false

                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    });

                this.setState({

                    documentList: response.data,
                    getDocument: true
                })


            })
            .catch((error) => {
                console.log(error);
            })

    }

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        return clone;
        /*  for (let prop in clone)
             if (clone[prop] === '' || clone[prop] === ' '  || clone[prop] === "null"   ||  clone[prop] === "undefined" )
                 delete clone[prop];*/
        return clone;
    };

    onKeyDownChange = (data) => {
        for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
            if (data.varName === "nomineeDob" + (i + 1)) {
                this.setState({
                    loading: true
                });
                this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                //  this.forceUpdate()
                setTimeout(() => {
                    let x = 18;
                    let currentDate = new Date();
                    let someDate = new Date();
                    let numberOfDaysToAdd = x;
                    someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let convertDate = year + "-" + month + "-" + date;
                    let newDate = new Date(convertDate);
                    let stringToDate = new Date(newDate.toDateString());
                    let inputDate = new Date(this.state.inputData["nomineeDob" + (i + 1)]);
                    if (stringToDate >= inputDate) {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "NO";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = true;
                        // this.state.varValue["nomineeMinor" + i] == "NO";
                    } else {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "YES";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = true;


                    }

                    this.forceUpdate()
                }, 1000);
                this.setState({
                    showValue: true,
                    getData: true,
                    loading: false
                })


            }
        }


        let inputData = this.state.inputData;
        let varValue = this.state.varValue;
        if (data.varName === "homeAddressaddrssType") {

            if (this.state.inputData.homeAddressaddrssType === {
                "masterDataName": "addressType",
                "key": "Mailing",
                "value": "Mailing"
            }) {
                this.state.inputData.homeAddressFieldShow = false;
                this.state.varValue.homeAddressFieldShow = false;
                Functions.sleep(500).then(() => {
                    this.state.inputData.homeAddresshomeAddressField1 = this.state.inputData.maillingAdressmailingAddressField1;
                    this.state.varValue.homeAddresshomeAddressField1 = this.state.inputData.maillingAdressmailingAddressField1;

                    this.state.inputData.homeAddresshomeAddressField2 = this.state.inputData.maillingAdressmailingAddressField2;
                    this.state.varValue.homeAddresshomeAddressField2 = this.state.inputData.maillingAdressmailingAddressField2;

                    this.state.inputData.homeAddresscity = this.state.inputData.maillingAdresscity;
                    this.state.varValue.homeAddresscity = this.state.inputData.maillingAdresscity;

                    this.state.inputData.homeAddressstate = this.state.inputData.maillingAdressstate;
                    this.state.varValue.homeAddressstate = this.state.inputData.maillingAdressstate;

                    this.state.inputData.homeAddresspostalCode = this.state.inputData.maillingAdresspostalCode;
                    this.state.varValue.homeAddresspostalCode = this.state.inputData.maillingAdresspostalCode;

                    this.state.inputData.homeAddresscountry = this.state.inputData.maillingAdresscountry;
                    this.state.varValue.homeAddresscountry = this.state.inputData.maillingAdresscountry;
                    this.state.inputData.homeAddressFieldShow = true;
                    this.state.varValue.homeAddressFieldShow = true;
                    this.forceUpdate()
                })
            }


        } else if (data.varName === "fincoreDataUpdatesectorCode") {
            this.state.inputData.sectorCodeApicall = false;
            let codeUrl = backEndServerURL + "/finMasterData/custom/subSector/" + this.state.inputData.fincoreDataUpdatesectorCode["key"];//{sectorCode}
            axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    {
                        this.findByVarNameApiData("fincoreDataUpdatesubSectorCode", response.data)
                    }
                    this.state.inputData.sectorCodeApicall = true;
                    this.forceUpdate()


                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (data.varName === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3" || data.varName === "mispriorityMonitoringRmCodeFreeCode3") {
            let getData = "";
            if (data.varName === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3") {
                getData = "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3"
            } else {
                getData = "mispriorityMonitoringRmCodeFreeCode3"

            }
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + getData;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        errorMessage: "RM Code Is Not Found,Please again Try!",
                        errorModalBoolean: true
                    })
                })
                .catch((error) => {
                    console.log(error)

                })
        } else if (data.varName == "basicInformationUpdatedob") {
            this.state.inputData.basicInformationUpdatedobApiCall = "NO";
            this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N";
            this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N";
            //this.updateComponent()
            //if(data.value==="basicInformationUpdatedob"){
            setTimeout(() => {
                let x = 18;
                var currentDate = new Date();

                let someDate = new Date();
                let numberOfDaysToAdd = x;
                someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                let date = someDate.getDate();
                let month = someDate.getMonth() + 1;
                let year = someDate.getFullYear();
                let convertDate = year + "-" + month + "-" + date;
                let newDate = new Date(convertDate);
                let stringToDate = new Date(newDate.toDateString());

                let inputDate = new Date(this.state.inputData.basicInformationUpdatedob);

                if (stringToDate >= inputDate) {
                    this.state.inputData.basicInformationUpdatedobApiCall = "YES";
                    this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N";
                    this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
                } else {
                    this.state.inputData.basicInformationUpdatedobApiCall = "YES";
                    this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "Y";
                    this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "Y"
                }

                x = 60;
                currentDate = new Date();
                someDate = new Date();
                numberOfDaysToAdd = x;
                someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                date = someDate.getDate();
                month = someDate.getMonth() + 1;
                year = someDate.getFullYear();
                convertDate = year + "-" + month + "-" + date;
                newDate = new Date(convertDate);
                stringToDate = new Date(newDate.toDateString());

                inputDate = new Date(this.state.inputData.basicInformationUpdatedob);
                if (stringToDate >= inputDate) {
                    inputData.basicInformationUpdateseniorCitizenIndicator = "Y";
                    varValue.basicInformationUpdateseniorCitizenIndicator = "Y"


                } else {

                    inputData.basicInformationUpdateseniorCitizenIndicator = "N";
                    varValue.basicInformationUpdateseniorCitizenIndicator = "N"
                }

                this.forceUpdate()

            }, 1000)

        }
        /*    for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
                if (data === "nomineecifId" + i) {
                    this.setState({
                        loading: true
                    })
                    this.state.inputData["nomineecifIdApiCall"+i] = false;
                    this.state.varValue["nomineecifIdApiCall"+i] = false;
                             console.log(response.data)
                             let x = 60;
                            let currentDate = new Date();
                            let someDate = new Date();
                            let numberOfDaysToAdd = x
                            someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                            let date = someDate.getDate();
                            let month = someDate.getMonth() + 1;
                            let year = someDate.getFullYear();
                            let convertDate = year +"-" + month + "-" + date;
                            let newDate = new Date(convertDate);
                            let stringToDate = new Date(newDate.toDateString());

                            let inputDate = new Date(response.data.birthDate);
                            this.state.inputData["nomineeDob" + i]=(response.data.birthDate).split("T")[0]
                            this.state.varValue["nomineeDob" + i]=(response.data.birthDate).split("T")[0]
                            if (stringToDate >= inputDate) {
                                this.state.inputData["nomineeMinor" + i] = "YES";
                                this.state.varValue["nomineeMinor" + i] = "YES";

                                // this.state.varValue["nomineeMinor" + i] == "NO";
                            } else {

                                this.state.inputData["nomineeMinor" + i] = "NO";
                                this.state.varValue["nomineeMinor" + i] = "NO";

                                //this.state.varValue["nomineeMinor" + i] == "YES";
                            }

                            /!*this.state.inputData["nomineeGuardiansName" + i] =response.data.
                            this.state.inputData["nomineeGuardianCode" + i] =response.data.
                            this.state.inputData["guardianPhotoId" + i] =response.data.
                            this.state.inputData["nomineeGuardianAddress" + i] =response.data.
                            this.state.inputData["nomineeGuardianAddress2" + i]=response.data.
                            this.state.inputData["nomineeGuardianCityCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.city)
                            this.state.inputData["nomineeGuardianStateCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.state)
                           this.state.inputData["nomineeGuardianPostalCode" + i]=response.data.
                          this.state.inputData["nomineeGuardianCountry2" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.country)
    *!/
                            this.state.inputData["nomineecifIdApiCall"+i] = true;
                            this.state.varValue["nomineecifIdApiCall"+i] = true;
                            this.forceUpdate()

                            console.log(this.state.inputData)


                }
            }*/


    };
    onKeyDownForDedup = (event, data) => {
        for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {

            if (data === "nomineecifId" + (i + 1)) {
                this.setState({
                    loading: true
                });

                this.state.inputData["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.varValue["nomineecifIdApiCall" + (i + 1)] = false;
                let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData["nomineecifId" + (i + 1)];
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);

                        this.state.inputData["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.inputData["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.inputData["nomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.inputData["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.inputData["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.inputData["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.inputData["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.inputData["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.varValue["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.varValue["nomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.varValue["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.varValue["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.varValue["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.varValue["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        let x = 18;
                        let currentDate = new Date();
                        let someDate = new Date();
                        let numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        let date = someDate.getDate();
                        let month = someDate.getMonth() + 1;
                        let year = someDate.getFullYear();
                        let convertDate = year + "-" + month + "-" + date;
                        let newDate = new Date(convertDate);
                        let stringToDate = new Date(newDate.toDateString());

                        let inputDate = new Date(response.data.birthDate);
                        this.state.inputData["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        this.state.varValue["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        if (stringToDate >= inputDate) {
                            this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "NO";

                            // this.state.varValue["nomineeMinor" + i] == "NO";
                        } else {
                            this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "YES";


                        }

                        /*this.state.inputData["nomineeGuardiansName" + i] =response.data.
                        this.state.inputData["nomineeGuardianCode" + i] =response.data.
                        this.state.inputData["guardianPhotoId" + i] =response.data.
                        this.state.inputData["nomineeGuardianAddress" + i] =response.data.
                        this.state.inputData["nomineeGuardianAddress2" + i]=response.data.
                        this.state.inputData["nomineeGuardianCityCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.city)
                        this.state.inputData["nomineeGuardianStateCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.state)
                       this.state.inputData["nomineeGuardianPostalCode" + i]=response.data.
                      this.state.inputData["nomineeGuardianCountry2" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.country)
*/
                        this.state.inputData["nomineecifIdApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineecifIdApiCall" + (i + 1)] = true;
                        this.forceUpdate();

                        console.log(this.state.inputData);
                        this.setState({
                            //varValue: this.state.varValue,
                            //inputData: this.state.inputData,
                            showValue: true,
                            getData: true,
                            loading: false
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                            errorTittle: "Invalid Introducer ID",
                            errorModalBoolean: true,


                        })
                    })

            }
        }
        if (data === "mispriorityMonitoringRmCodeFreeCode3") {
            this.setState({
                loading: true
            });
            this.state.inputData.mispriorityMonitoringRmCodeFreeCode3ApiCall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData.mispriorityMonitoringRmCodeFreeCode3;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.mispriorityMonitoringRmCodeFreeCode3Name = response.data;
                    this.state.varValue.mispriorityMonitoringRmCodeFreeCode3Name = response.data;
                    console.log(response.data);
                    this.state.inputData.mispriorityMonitoringRmCodeFreeCode3ApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Invalid RM Number!",
                        loading: false
                    })

                })
        } else if (data === "primryintroducerCustomerIdCifId") {
            this.setState({
                loading: true
            });
            this.state.inputData.introducerIdApiCall = "NO";
            this.state.varValue.introducerIdApiCall = "NO";
            let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData.primryintroducerCustomerIdCifId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.primryiintroducerNameLastName = response.data.customerName;
                    this.state.varValue.primryiintroducerNameLastName = response.data.customerName;
                    console.log(response.data);

                    this.state.inputData.introducerIdApiCall = "YES";
                    this.state.varValue.introducerIdApiCall = "YES";
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })


                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Introducer ID",

                    })
                })
        } else if (data === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3") {
            this.setState({
                loading: true
            });
            this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3RmName = response.data;
                    this.state.varValue.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3RmName = response.data;
                    this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Invalid RM Number!",
                        loading: false
                    })

                })
        } else if (data === "guardianDetailsgurdianCifNumberCustomer") {
            this.setState({
                loading: true
            });
            this.state.inputData.guardianDetailsgurdianCifNumberCustomerApiCall = "NO";
            this.forceUpdate();
            let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData.guardianDetailsgurdianCifNumberCustomer;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.guardianDetailslastName = response.data.customerName;
                    this.state.varValue.guardianDetailslastName = response.data.customerName;
                    console.log(response.data);


                    this.state.inputData.guardianDetailsgurdianCifNumberCustomerApiCall = "YES";
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Number"
                    })
                })
        } else if (this.state.inputData.natioanlIdCarddocumentCodeN === {
            "masterDataName": "documentCode",
            "key": "NID",
            "value": "NID CARD IDENTITY"
        } || this.state.inputData.natioanlIdCarddocumentCodeN === {
            "masterDataName": "documentCode",
            "key": "RSNID",
            "value": "RSNID"
        }) {
            this.state.inputData.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = "Y";
            this.state.varValue.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = "Y"
        }

    };
    renderForm = () => {
        if (this.state.getData && ((this.props.requestType === "Completed" && this.props.requestType !== undefined && this.props.makerEnd !== true) || (this.props.makerEnd !== true && this.props.checkerEnd === true))) {


            return (
                CommonJsonFormComponent.renderJsonForm(this.state, CBDataJsonFormReadOnly, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
            )
        } else if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, CBDataJsonForm, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
            )
        }
    };

    renderSubmitButton = () => {
        if (this.state.getData && (this.props.makerEnd === true || this.state.inputData.apiButton === undefined || this.state.inputData.apiButton === "COMPLETE")) {
            return (
                <>{(this.props.requestType !== "Completed" || this.props.makerEnd === true) &&
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',

                    }}

                    type='button' value='add more'
                    onClick={this.handleSubmit}
                >SUBMIT
                </button>}
                    &nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={() => {
                            this.props.closeModal()
                        }}
                    >Close
                    </button>
                </>
            )
        } else if (this.state.inputData.apiButton !== undefined || this.state.inputData.apiButton !== "COMPLETE") {
            return (
                <>
                    {(this.props.requestType !== "Completed") && this.state.inputData.apiButton === "CB CREATION" &&
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={this.handleSubmit}
                    >CB CREATION
                    </button>}
                    &nbsp;

                    {this.state.inputData.apiButton === "CB UPDATE" &&
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={this.handleSubmit}
                    >CB UPDATE
                    </button>}
                    &nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={() => {
                            this.props.closeModal()
                        }}
                    >Close
                    </button>
                </>
            )
        } else {
            return (
                <>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={() => {
                            this.props.closeModal()
                        }}
                    >Close
                    </button>
                </>
            )
        }
    };

    closeModalError = () => {
        this.setState({
            errorModalBoolean: false
        })
    };

    render() {
        const {classes} = this.props;

        return (

            <div>

                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} ><b>{this.props.relationCode !== undefined ? this.props.relationCode : ""}</b><p
                            style={{fontSize: "16px"}}>(<b> Customer
                            Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                            <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                            <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}
                            <b> SOLID:</b>{this.props.solId !== undefined ? this.props.solId : ""} <b> Scheme
                                Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""})<a><CloseIcon
                                onClick={this.close} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
                    </CardHeader>
                    <CardBody>
                        <Grid container spacing={1}>

                            <Grid item xs={8}>

                                {this.renderImage()}

                            </Grid>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.loading}>
                                <DialogContent className={classes.dialogPaper}>

                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>

                            <Grid item xs={4}>


                                <React.Fragment>
                                    <br/>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="md"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.accountNo}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <AccountNoGenerate taging={this.props.taging}
                                                               tagingModalCbnumber={this.props.tagingModalCbnumber}
                                                               closeModal={this.closeModal}/>

                                        </DialogContent>
                                    </Dialog>

                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="xl"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.errorModalBoolean}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <ErrorModal title={this.state.errorMessage}
                                                        closeModal={this.closeModalError}
                                            />

                                        </DialogContent>
                                    </Dialog>


                                    <ThemeProvider theme={theme}>
                                        <GridList cellHeight={window.innerHeight} cols={1}>
                                            <div>
                                                <Grid container>

                                                    {this.renderForm()}
                                                </Grid>
                                            </div>


                                        </GridList>
                                    </ThemeProvider>
                                    <div>
                                        <br/>
                                        <br/>
                                        <center>
                                            {
                                                this.renderSubmitButton()
                                            }
                                        </center>
                                    </div>
                                </React.Fragment>


                            </Grid>


                        </Grid>
                    </CardBody>
                </Card>
            </div>

        )
    }

}

export default withStyles(styles)(SecondaryCbFormDetails);