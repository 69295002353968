import React from "react";
import FormSample from '../JsonForm/FormSample';
import {backEndServerURL} from '../../Common/Constant';
import axios from 'axios';
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import withStyles from "@material-ui/core/styles/withStyles";

import Notification from "../NotificationMessage/Notification";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};
const jsonForm = {
    "variables": [

        {
            "varName": "fileName",
            "type": "file",
            "label": "Upload File",
        },

    ],

};

class UploadFileToAutoRead extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            notificationMessage: "",
            alert: false
        }
    }

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }

    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    getSubmitedForm = (object) => {
        const formData = new FormData();
        formData.append("file", object.fileName);

        axios({
            method: 'post',
            url: backEndServerURL + '/fileUpload/add',
            data: formData,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                'content-type': 'multipart/form-data'
            }
        }).then((response) => {
            this.setState({
                title: "Sucessfully!!",
                notificationMessage: "Sucessfully File Uploaded",
                alert: true
            })

        }).catch((error) => {
            console.log(error);
        })

    };


    render() {
        const {classes} = this.props;


        return (
            <div>
                {this.renderNotification()}

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>Xlxs File upload</h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <center>
                                        <FormSample grid="12" buttonName="Submit" onSubmit={this.getSubmitedForm}
                                                    jsonForm={jsonForm}/>
                                    </center>

                                </div>

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }

}

export default withStyles(styles)(UploadFileToAutoRead);