import React, {Component} from "react";
import EyzyTree from 'eyzy-tree';
import EyzyTreeApi from "eyzy-tree/api"
import 'eyzy-tree/style.css';
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import Button from '@material-ui/core/Button';
import axios from 'axios';
import '../../Static/css/login.css';
import Grid from '@material-ui/core/Grid';
import {backEndServerURL} from '../../Common/Constant';
import Notification from "../NotificationMessage/Notification";
import Functions from '../../Common/Functions';
import CloseIcon from "@material-ui/icons/Close";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        background: '#f6f1ff'
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 400,
    },
});

class ViewMenu extends Component {
    api = null;

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            roleId: ' ',
            menuId: ' ',
            parentMenu: [],
            childMenu: [],
            array: [],
            gotMenuResourceData: false,
            alert: false,
            redirectLogin: false,

        };
    }

    handleTreeReady = (treeApi) => {
        // it is important to pass the Basic API as an argument
        this.api = new EyzyTreeApi(treeApi)
    };

    dataHandle = (checkBoxData) => {

        console.log("data handle");
        console.log(checkBoxData);
        let parentMenuValue = [];
        checkBoxData.map((checkbox) => {
            if (checkbox.depth === 1) {
                let object = {
                    text: checkbox.name,
                    menuId: checkbox.id,
                    expanded: true,
                    checked: true,
                    child: []
                };
                parentMenuValue.push(object);

            } else if (checkbox.depth === 2) {
                let lastObject = parentMenuValue[parentMenuValue.length - 1];
                let newObject = {
                    text: checkbox.name,
                    menuId: checkbox.id,
                    expanded: true,
                    child: []
                };
                lastObject.child.push(newObject);

                parentMenuValue[parentMenuValue.length - 1] = lastObject;
                console.log("lastObject");

            } else {

            }


        });

        return parentMenuValue;
    };

    componentDidMount() {
        let url = backEndServerURL + '/menu/assignedMenu/' + this.props.roleId;
        let checkBoxData = [];
        let parentMenuValue = [];


        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log("hello");
                console.log(response.data);
                checkBoxData = response.data;

                parentMenuValue = this.dataHandle(checkBoxData);

                this.setState({
                    data: parentMenuValue,
                    gotMenuResourceData: true
                });


            })
            .catch((error) => {
                console.log("errors");
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
            });

    }

    notification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.type} stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }

    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    handleSubmit = (event) => {
        event.preventDefault();
        let unassignedArray = [];
        let checkedNodes = this.api.findAll({checked: false});
        checkedNodes._nodes.map((data) => {
            unassignedArray.push(data.menuId);

        });
        let url = backEndServerURL + "/roleMenu/deleteBulk";
        axios.post(url, unassignedArray, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                checkedNodes._nodes.map((data) => {
                    if (data.depth !== 0) {
                        this.api.remove(data.text);
                    }
                });
                checkedNodes = this.api.findAll({checked: false});
                checkedNodes._nodes.map((data) => {
                    if (data.depth === 0 && data.child.length === 0) {
                        this.api.remove(data.text);
                    }
                });
                this.props.closeModal();
                this.setState({
                    alert: true,
                    title: "Unassigned Menu!!!",
                    notificationMessage: "successfully Unassigned!!",
                })
            })
            .catch((error) => {
                console.log("Error");
                if (error.response.status === 452) {
                    Functions.removeCookie();
                    this.setState({
                        redirectLogin: true
                    })

                }
                else {
                    this.props.closeModal()
                    this.setState({
                        alert: true,
                        notificationMessage: Functions.errorObjectCheck(error),
                        title: "Error!",
                    });
                }
            })
    };

    renderEyzyTree = () => {
        if (this.state.gotMenuResourceData) {
            return (
                <EyzyTree
                    data={this.state.data}
                    checkable={true}
                    theme="eyzy-theme-red"
                    multiple={true}
                    onReady={this.handleTreeReady}

                />
            );
        }
    };


    render() {
        Functions.redirectToLogin(this.state);
        const {classes} = this.props;
        return (
            <div>
                {this.notification()}

                <GridContainer>

                    <GridItem xs={6} sm={12} md={12}>
                        <Card>
                        <CardHeader style={{
                            backgroundColor: '#142398',
                        }}>
                            <h4 style={{

                                color: "white",
                            }}>View Menu</h4>
                        </CardHeader>
                            <CardBody>
                                <div className={classes.root}>
                                    <form onSubmit={this.handleSubmit} className={classes.form}>
                                        <Grid container spacing={24}>
                                            <Grid item xs={12} sm={12}>
                                                {
                                                    this.renderEyzyTree()
                                                }

                                                <br/>
                                                <br/>
                                                <br/>
                                            </Grid>
                                        </Grid>
                                        <center>
                                            <Button
                                                type="primary" onClick={this.handleSubmit} htmlType="submit"
                                                className={classes.Buttoncolorchange}
                                                variant="contained"
                                                color="secondary"
                                            >
                                                Unassign
                                            </Button>
                                        </center>
                                    </form>
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(styles)(ViewMenu);