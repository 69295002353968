import React, {Component} from "react";
import MbuDeferalPos from './MbuDefferalPos';
import DefferalApproval from './DefferalApproval';
import HombuApproval from './HombuApproval';
import MouApproval from './MouApproval';
import FrmApproval from './FrmApproval';
import CallCenterApproval from './CallCenterApproval';
import MouMakerUpdateInfo from './MouMakerUpdateInfo';
import MouCheckerApproval from './MouCheckerApproval';
import Dst from './DstCaseStart'
import MerchantMaintenance from "../CardCallCategory/MerchantMaintenance";
import GatePassApproval from "./GatePassApproval";
import DeferralLifeCycleMBU from "./deferralLifeCycle/DeferralLifeCycleMBU";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";

class MerchantInboxCase extends Component {


    inboxCase = () => {

        console.log(this.props.serviceType);
        console.log(this.props.subServiceType);
        console.log(this.props.taskTitle);
        if (this.props.serviceType === 'MerchantOnboarding' && this.props.taskTitle === 'mbu_deferal_pos' && (this.props.activeTab === "Deferral" || this.props.activeTab === "Deferral Close")) {
            return (<DeferralLifeCycleMBU closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                          appId={this.props.appId}/>)
        } else if (this.props.serviceType === 'MerchantOnboarding' && this.props.taskTitle === 'mbu_deferal_pos') {
            return (<MbuDeferalPos closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                   appId={this.props.appId}/>)
        } else if (this.props.serviceType === 'MerchantOnboarding' && this.props.taskTitle === 'dff_approval') {
            return (<DefferalApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                      appId={this.props.appId}/>)
        } else if (this.props.serviceType === 'MerchantOnboarding' && this.props.taskTitle === 'hombu_approval') {
            return (<HombuApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                   appId={this.props.appId}/>)

        } else if (this.props.serviceType === 'MerchantOnboarding' && this.props.taskTitle === 'mou_approval') {
            return (<MouApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                 appId={this.props.appId}/>)

        } else if (this.props.serviceType === 'MerchantOnboarding' && (this.props.taskTitle === 'frm_approval' || this.props.taskTitle === 'frm_checker_approval' || this.props.taskTitle === 'frm_approval_manager')) {
            return (<FrmApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                 appId={this.props.appId} taskTitle={this.props.taskTitle}/>)

        } else if (this.props.serviceType === 'MerchantOnboarding' && (this.props.taskTitle === 'call_center_approval' || this.props.taskTitle === 'call_center_not_reached')) {
            return (<CallCenterApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                        appId={this.props.appId} taskTitle={this.props.taskTitle}
                                        activeTab={this.props.activeTab}/>)

        }
        else if (this.props.serviceType === 'MerchantOnboarding' &&  LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('CALL_CENTER_MAKER') !== -1 ) {
            return (<CallCenterApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                        appId={this.props.appId} taskTitle={this.props.taskTitle}
                                        activeTab={this.props.activeTab}/>)

        }
        else if (this.props.serviceType === 'MerchantOnboarding' && this.props.taskTitle === 'mou_maker_update_info') {
            return (<MouMakerUpdateInfo closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                        appId={this.props.appId}/>)

        } else if (this.props.serviceType === 'MerchantOnboarding' && (this.props.taskTitle === 'mou_checker_approval' || this.props.taskTitle ==="approved_by_mou_checker")) {
            return (<MouCheckerApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                        appId={this.props.appId}/>)

        } else if (this.props.serviceType === 'MerchantOnboarding' && (this.props.taskTitle === 'dst_case_start' || this.props.taskTitle === 'line_manager_approval')) {
            return (<Dst closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                         appId={this.props.appId} taskTitle={this.props.taskTitle}/>)

        } else if (this.props.serviceType === 'MerchantOnboarding' && (this.props.taskTitle === 'get_pass' || this.props.taskTitle === "undelivered")) {
            return (<GatePassApproval taskTitle={this.props.taskTitle} closeModal={this.props.closeModal}
                                      closeIcon={this.props.closeIcon}
                                      appId={this.props.appId}/>)
        } else if (this.props.serviceType === 'merchant' && this.props.subServiceType === 'maintenance') {
            return (<MerchantMaintenance closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                         appId={this.props.appId}/>)
        }

    };
    close = () => {
        this.props.closeModal();
    };

    render() {


        return (

            this.inboxCase()
        );

    }

}

export default MerchantInboxCase;