import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL,frontEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Grow from "@material-ui/core/Grow";
import {ThemeProvider} from "@material-ui/styles";
import TextFieldComponent from "../../JsonForm/TextFieldComponent";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import SelectComponent from "../../JsonForm/SelectComponent";
import CloseIcon from '@material-ui/icons/Close';
import Table from "../../Table/Table";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import AccountNoGenerate from "../AccountNoGenerate";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import FileMapping from "../CommonComponent/FileMapping";
import FileMappingEdit from "../CommonComponent/FileMappingEdit";
import loader from "../../../Static/loader.gif";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import ErrorModal from "../CommonComponent/ErrorModal";
import DateComponentDeferal from "../../JsonForm/DateComponentDeferal";
import CheckBox from "@material-ui/core/Checkbox/Checkbox";
import WaiverList from "../CommonComponent/WaiverList";
import {
    CSJsonFormForCasaIndividualFdr,
    CSJsonFormForFDRServiceLast,
    CSJsonFormForFDRServiceNew
} from "../WorkflowJsonFormArin";
import AccountAndCbNumberGenerate from "../AccountAndCbNumberGenerate";
import SignatureButton from "../Maintenance/SignatureButton";
import SubmittedCaseHistory from "../CommonComponent/SubmittedCaseHistory";
import ConfirmAlertImage from "../CommonComponent/ConfirmAlertImage";
import AccountStatement from "./AccountStatement";
import FunctionForGetAcDetails from "../CommonComponent/FunctionForGetAcDetails";
import FunctionForInputGetAndSet from "./FunctionForInputGetAndSet";
import functionForFileDownload from "../../DeliverableManagement/FunctionForFileDownload";
import SdnAndDedupeResultShowing from "../CommonComponent/SdnAndDedupeResultShowing";
import Button from "@material/react-button";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";

const arrayListIndividual = [

    {label: 'AOF1'},
    {label: 'AOF2'},
    {label: 'AOF3'},
    {label: 'AOF4'},
    {label: 'AOF5'},
    {label: 'AOF6'},
    {label: 'AOF7'},
    {label: 'AOF8'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'TP'},
    {label: 'IIF1'},
    {label: 'IIF2'},
    {label: 'NID'},
    {label: 'PASSPORT'},
    {label: 'DRIVING LICENSE'},
    {label: 'BIRTH CERTIFICATE'},
    {label: ' NID 1'},
    {label: ' NID 2'},
    {label: ' NID 3'},
    {label: ' NID 4'},
    {label: ' NID 5'},
    {label: 'NOMINEE NID 6'},
    {label: 'NOMINEE PASSPORT 1'},
    {label: 'NOMINEE PASSPORT 2'},
    {label: 'NOMINEE PASSPORT 3'},
    {label: 'NOMINEE PASSPORT 4'},
    {label: 'NOMINEE PASSPORT 5'},
    {label: 'NOMINEE DRIVING LICENSE 1'},
    {label: 'NOMINEE DRIVING LICENSE 2'},
    {label: 'NOMINEE DRIVING LICENSE 3'},
    {label: 'NOMINEE DRIVING LICENSE 4'},
    {label: 'NOMINEE DRIVING LICENSE 5'},
    {label: 'NOMINEE BIRTH CERTIFICATE 1'},
    {label: 'NOMINEE BIRTH CERTIFICATE 2'},
    {label: 'NOMINEE BIRTH CERTIFICATE 3'},
    {label: 'NOMINEE BIRTH CERTIFICATE 4'},
    {label: 'NOMINEE BIRTH CERTIFICATE 5'},
    {label: 'OTHERS'},

];


let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];
var deferalOther =
    {
        "varName": "deferalOther",
        "type": "text",
        "label": "Remarks",

    };
var waiverOther =
    {
        "varName": "waiverOther",
        "type": "text",
        "label": "Remarks",

    };

let typeofDeferralDocs = {
    "varName": "typeofDeferralDocs",
    "type": "select",
    "label": "Type of Deferral Docs",
    "enum": [
        "Regulatory",
        "Non Regulatory",
    ],
};
let typeofWaiverDocs = {
    "varName": "typeofWaiverDocs",
    "type": "select",
    "label": "Type of Waiver Docs",
    "enum": [
        "Regulatory",
        "Non Regulatory",
    ],
};
let deferal = {};
let waiver = {};

var date = {
    "varName": "expireDate",
    "type": "text",
    "minDate": true,
    "label": "Expire Date",

};
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

function Transition(props) {
    return <Grow in={true} timeout="auto" {...props} />;
}


const tenureDay = [

    {title: '90'},
    {title: '180'},
    {title: '182'},
    {title: '362'},
    {title: '365'},


];
const tenureMonth = [

    {title: '1'},
    {title: '3'},
    {title: '6'},
    {title: '12'},
    {title: '24'},
    {title: '36'}


];


class OpeningCS extends Component {

    serviceList = {CSJsonFormForFDRServiceNew};
    serviceListLast = {CSJsonFormForFDRServiceLast};
    commonJsonForm = {CSJsonFormForCasaIndividualFdr};

    /*  maturitydateCalculate = () => {
          let fdDate = (this.state.inputData.fdValueDate);
          let type = (this.state.inputData.tenureType);
          let tenure = (this.state.inputData.tenure);
          console.log(type)
          console.log(tenure)
          console.log(this.state.inputData.fdrSchemeCode)
          console.log(this.state.inputData.amount)
          if (type !== undefined && tenure !== undefined && (this.state.inputData.fdrSchemeCode !== undefined || this.state.inputData.dpsSchemeCode !== undefined) && this.state.inputData.amount !== undefined) {
              let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
              let data = {};
              if (this.state.inputData.dpsSchemeCode !== undefined) {
                  data.schemeCode = this.state.inputData.dpsSchemeCode.value;

              } else {
                  data.schemeCode = this.state.inputData.fdrSchemeCode.value;

              }
              data.amount = this.state.inputData.amount;
              data.tenor = this.state.inputData.tenure;
              data.tenorType = this.state.inputData.tenureType;
              console.log(data)
              this.state.inputData.interestRateApicall = "NO";

              axios.post(interestCalculateAPi, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                  .then((response) => {
                      console.log(response.data);
                      this.state.inputData.interestRate = response.data;
                      this.state.varValue.interestRate = response.data;
                      this.state.inputData.interestRateApicall = "YES";

                      this.forceUpdate()
                  })
                  .catch((error) => {
                      console.log(error);
                      this.state.inputData.interestRateApicall = "YES";

                  })
          }

          if ((fdDate !== undefined && fdDate !== null && fdDate !== "") && type === "Monthly" && Number(tenure) !== 0 && Number(tenure) !== undefined && Number(tenure) !== null) {

              this.state.inputData.fdMaturityDateApicall = "NO";
              this.forceUpdate()
              let someDate = new Date();
              if (this.state.inputData.fdValueDate !== undefined && this.state.inputData.fdValueDate !== null && this.state.inputData.fdValueDate !== -1) {
                  someDate = new Date(this.state.inputData.fdValueDate);
              }
              let x = tenure;
              let numberOfMonthsToAdd = parseInt(x, 10)
              someDate.setMonth(someDate.getMonth() + numberOfMonthsToAdd);
              let dates = someDate.getDate();
              let month = someDate.getMonth() + 1;
              if (month <= 9) {
                  month = "0" + month;
              }
              if (dates <= 9) {
                  dates = "0" + dates;
              }
              /!*  let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
                axios.post(interestCalculateAPi, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        let year = someDate.getFullYear();
                        this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                        this.state.inputData.fdMaturityDateApicall="YES";
                        this.forceUpdate()

                        console.log(this.state.inputData["fdMaturityDate"])
                    })
                    .catch((error)=>{
                        let year = someDate.getFullYear();
                        this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                        this.state.inputData.fdMaturityDateApicall="YES";
                        this.forceUpdate()

                        console.log(this.state.inputData["fdMaturityDate"])
                    })*!/
              Functions.sleep(500).then(() => {
                  let year = someDate.getFullYear();
                  this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                  this.state.inputData.fdMaturityDateApicall = "YES";
                  this.forceUpdate()
              })

          }
          if ((fdDate !== undefined && fdDate !== null && fdDate !== "") && type === "Yearly" && Number(tenure) !== 0 && Number(tenure) !== undefined && Number(tenure) !== null) {

              this.state.inputData.fdMaturityDateApicall = "NO";
              this.forceUpdate()
              let someDate = new Date();
              if (this.state.inputData.fdValueDate !== undefined && this.state.inputData.fdValueDate !== null && this.state.inputData.fdValueDate !== -1) {
                  someDate = new Date(this.state.inputData.fdValueDate);
              }
              let x = (tenure)*12;
              let numberOfMonthsToAdd = parseInt(x, 10)
              someDate.setMonth(someDate.getMonth() + numberOfMonthsToAdd);
              let dates = someDate.getDate();
              let month = someDate.getMonth() + 1;
              if (month <= 9) {
                  month = "0" + month;
              }
              if (dates <= 9) {
                  dates = "0" + dates;
              }
              /!*  let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
                axios.post(interestCalculateAPi, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        let year = someDate.getFullYear();
                        this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                        this.state.inputData.fdMaturityDateApicall="YES";
                        this.forceUpdate()

                        console.log(this.state.inputData["fdMaturityDate"])
                    })
                    .catch((error)=>{
                        let year = someDate.getFullYear();
                        this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                        this.state.inputData.fdMaturityDateApicall="YES";
                        this.forceUpdate()

                        console.log(this.state.inputData["fdMaturityDate"])
                    })*!/
              Functions.sleep(1500).then(() => {
                  let year = someDate.getFullYear();
                  this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                  this.state.inputData.fdMaturityDateApicall = "YES";
                  this.forceUpdate()
              })

          }
          else if ((fdDate !== undefined && fdDate !== null && fdDate !== "") && type === "Day" && Number(tenure) !== 0 && Number(tenure) !== undefined && Number(tenure) !== null) {

              this.state.inputData.fdMaturityDateApicall = "NO";
              this.forceUpdate()

              let someDate = new Date();
              if (this.state.inputData.fdValueDate !== undefined && this.state.inputData.fdValueDate !== null && this.state.inputData.fdValueDate !== -1) {
                  someDate = new Date(this.state.inputData.fdValueDate);
              }
              let x = tenure;
              let numberOfDaysToAdd = parseInt(x, 10)

              someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
              let date = someDate.getDate();
              let month = someDate.getMonth() + 1;
              let year = someDate.getFullYear();
              if (month <= 9) {
                  month = "0" + month;
              }
              if (date <= 9) {
                  date = "0" + date;
              }
              Functions.sleep(500).then(() => {
                  this.state.inputData["fdMaturityDate"] = date + '-' + month + '-' + year;
                  this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;

                  //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                  //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                  this.state.inputData.fdMaturityDateApicall = "YES";
                  this.forceUpdate()
              })
              /!*   let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";

                 axios.post(interestCalculateAPi, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                     .then((response) => {
                         this.state.inputData["fdMaturityDate"] = date + '-' + month + '-' + year;
                         this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;

                         //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                         //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                         this.state.inputData.fdMaturityDateApicall="YES";
                         this.forceUpdate()

                         console.log(this.state.inputData["fdMaturityDate"])
                     })
                     .catch((error)=>{
                         this.state.inputData["fdMaturityDate"] = date + '-' + month + '-' + year;
                         this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;

                         //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                         //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                         this.state.inputData.fdMaturityDateApicall="YES";
                         this.forceUpdate()

                         console.log(this.state.inputData["fdMaturityDate"])
                     })*!/


          } else {
              this.state.inputData["fdMaturityDate"] = "";
          }


      }*/

    constructor(props) {
        super(props);
        this.state = {
            SelectedData: false,
            csDeferalPage: "",
            values: [],
            waiverValues: [],
            appId: '',
            csDataCapture: '',
            message: "",
            appData: {},
            getData: false,
            getNewCase: false,
            varValue: {
                csDeferal: "NO",
                accountType: "INSTAPACK",
                priority: "GENERAL"
            },
            caseId: "",
            title: "",
            notificationMessage: "",
            app_uid: "-1",
            alert: false,
            loading: false,
            accountDetailsButton: false,
            redirectLogin: false,
            type: [],
            dueDate: '',
            inputData: {
                csDeferal: "NO",
                accountType: "INSTAPACK",
                priority: "GENERAL"
            },
            fileUploadData: {},
            selectedDate: {},
            dropdownSearchData: {},

            debitCard: "",
            showValue: false,
            AddDeferal: false,
            getDeferalList: [],
            deferalNeeded: false,
            uploadModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            getInitiateData: false,

            IndividualDedupModal: false,
            JointDedupModal: false,
            individualDataSaveId: '',
            jointDataSaveId: '',
            companyDataSaveId: '',
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            errorModalBoolean: false,
            errorTittle: "",
            getAllSchemeCode: [],
            getSchemeFlag: false,
            city: [],
            state: [],
            currency: [],
            country: [],
            fdrSchemeCode: [],
            SDRegulatory: [],
            SDNonRegulatory: [],
            confirmAlert: false,
            actionType: "",
            fdrImageShowPopUp: false,
            accountAndCbDetailsModal: false,
            generateNewAccountNumber: "",
            generateNewCB: "",
            signatureCardView: false,
            accountBalanceInfo: false,
            getAccountType: false,
            cbsCustomerGet: false,
            notificationMessgaeCommon: "",
            notificationMessgaeCommonFlag: false,
            history: false,
            accountNumber: "",
            solId: "",
            customerName: "",
            accountStatement: false,
            SdnAndDedupeResultShowing: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (event, value) => {

        this.state.inputData["csDeferal"] = value;
        this.updateComponent();
        if (value === "YES") {

            let values = [];
            values.push(Math.floor(Math.random() * 100000000000));

            this.setState({values: values, deferalNeeded: true});

        } else {
            this.setState({
                values: [],
                deferalNeeded: false
            })
        }
    };

    onBlurFunctionForApiCall = (event, varName, secondVarName) => {
        event.preventDefault();
        let inputData = this.state.inputData;
        let varValue = this.state.varValue;
        //this.state.inputData.debitAccountNumberApiCall = "NO";
        let allInputData = this.state.inputData;
        if (varName === "rmCode") {
            this.setState({
                loading: true,
            });
            this.state.inputData.rmCodeApicall = "NO";
            this.state.varValue.rmCodeApicall = "NO";
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.rmCodeName = response.data;
                    this.state.varValue.rmCodeName = response.data;
                    this.state.inputData.rmCode = this.state.inputData[varName];
                    this.state.varValue.rmCode = this.state.varValue[varName];
                    console.log(response.data);
                    this.state.inputData.rmCodeApicall = "YES";
                    this.state.varValue.rmCodeApicall = "YES";
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false,
                    })
                })
                .catch((error) => {
                    this.state.inputData.rmCode = "__ERROR__";
                    this.state.varValue.rmCode = "__ERROR__";
                    this.forceUpdate();
                    console.log(error);

                    this.setState({

                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Number"
                    })
                })
        } else if (varName === "monitoringRMCode") {
            this.setState({
                loading: true,
            });
            this.state.inputData.monitoringRMCodeApiCall = false;
            this.state.varValue.monitoringRMCodeApiCall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.monitoringRmCodeName = response.data;
                    this.state.varValue.monitoringRmCodeName = response.data;
                    this.state.inputData.monitoringRMCode = this.state.inputData[secondVarName];
                    this.state.varValue.monitoringRMCode = this.state.varValue[secondVarName];
                    this.state.inputData.monitoringRMCodeApiCall = true;
                    this.state.varValue.monitoringRMCodeApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false,
                    })
                })
                .catch((error) => {
                    this.state.inputData.monitoringRMCode = "__ERROR__";
                    this.state.varValue.monitoringRMCode = "__ERROR__";
                    this.forceUpdate();
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Number"
                    })
                })
        } else if (varName === "cbNumber") {
            this.setState({
                loading: true
            });
            this.state.inputData.cbNumberApiCall = "NO";
            this.updateComponent();
            let url = backEndServerURL + "/cbsCustomerGet/" + (inputData[secondVarName]);
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    inputData.customerName = response.data.customerName;
                    varValue.customerName = response.data.customerName;

                    inputData.occupationCode = response.data.occupationCode;
                    varValue.occupationCode = response.data.occupationCode;

                    inputData.sbsCode = response.data.sbsCode;
                    varValue.sbsCode = response.data.sbsCode;
                    inputData.etin = response.data.eTin;
                    varValue.etin = response.data.eTin;
                    inputData.cbNumberApiCall = "YES";
                    this.setState({
                        inputData: inputData,
                        varValue: varValue,
                        showValue: true,
                        loading: false,
                        getData: true,
                        cbDataBoolean: true,

                    })
                })
                .catch((error) => {
                    this.state.inputData.cbNumber = "__ERROR__";
                    this.state.varValue.cbNumber = "__ERROR__";
                    this.forceUpdate();
                    console.log(error);

                    this.setState({

                        loading: false,
                        getData: true,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Number",
                        cbDataBoolean: true
                    })
                })

        } else if (varName === "sectorCode") {
            this.setState({
                loading: true
            });
            this.state.inputData.sectorCodeApicall = false;
            let codeUrl = backEndServerURL + "/finMasterData/custom/subSector/" + this.state.inputData.sectorCode["key"];//{sectorCode}
            axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    let jsonArrayServiceList = this.props.serviceList;

                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "subSectorCode") {
                            this.props.serviceList[i].enum = response.data;
                            console.log("cccccccccccccccccccccccccccc");
                            console.log(this.props.serviceList[i])

                        }
                    }
                    this.state.inputData.sectorCodeApicall = true;
                    this.forceUpdate();
                    this.setState({
                        subsectorList: response.data,
                        loading: false
                    })

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })
        } else if (varName === "interestCreditAccount") {
            this.state.inputData.interestCreditAccountApiCall = "NO";
            this.setState({
                loading: true
            });
            let accountUrls = backEndServerURL + "/GetCustomAcctDetailsRequest/" + (this.state.inputData[secondVarName]);
            axios.get(accountUrls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.interestCreditAccountApiCall = "YES";
                    inputData.interestCreditName = response.data.acctName;
                    varValue.interestCreditName = response.data.acctName;
                    this.updateComponent();
                    this.setState({
                        inputData: this.emptyValueRemove(inputData),
                        varValue: this.emptyValueRemove(varValue),
                        showValue: true,
                        getData: true,
                        loading: false,
                    })

                })
                .catch((error) => {
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: this.errorObjectCheck(error),
                    })
                })
        } else if (varName === "repaymentAccount") {
            this.state.inputData.repaymentAccountApiCall = "NO";
            this.setState({
                loading: true
            });
            let accountUrls = backEndServerURL + "/GetCustomAcctDetailsRequest/" + (this.state.inputData[secondVarName]);
            axios.get(accountUrls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.repaymentAccountApiCall = "YES";
                    inputData.repaymentName = response.data.acctName;
                    varValue.repaymentName = response.data.acctName;
                    this.updateComponent();
                    this.setState({
                        inputData: this.emptyValueRemove(inputData),
                        varValue: this.emptyValueRemove(varValue),
                        showValue: true,
                        getData: true,
                        loading: false,
                    })

                })
                .catch((error) => {
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: this.errorObjectCheck(error),
                    })
                })
        } else if (varName === "debitAccountNumber") {
            this.state.inputData.debitAccountNumberApiCall = "NO";
            this.state.inputData.repaymentAccountApiCall = "NO";
            this.state.inputData.interestCreditAccountApiCall = "NO";
            this.setState({
                loading: true
            });
            inputData.accountTypeApiCall = false;
            inputData.accountAcquisitionApiCall = false;
            inputData.tenureTypeApiCall = false;
            inputData.tenureApiCall = false;
            inputData.autoRenewalsApiCall = false;
            inputData.maturityApiCall = false;
            inputData.fdrSchemeCodeApiCall = false;
            varValue.accountTypeApiCall = false;
            varValue.accountAcquisitionApiCall = false;
            varValue.tenureTypeApiCall = false;
            varValue.tenureApiCall = false;
            varValue.autoRenewalsApiCall = false;
            varValue.maturityApiCall = false;
            varValue.fdrSchemeCodeApiCall = false;
            let accountUrls = backEndServerURL + "/GetCustomAcctDetailsRequest/" + (this.state.inputData[secondVarName]);
            axios.get(accountUrls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    let data = response.data;
                    this.state.inputData.repaymentAccountApiCall = "YES";
                    this.state.inputData.interestCreditAccountApiCall = "YES";
                    inputData.interestCreditName = response.data.acctName;
                    varValue.interestCreditName = response.data.acctName;
                    inputData.repaymentName = response.data.acctName;
                    varValue.repaymentName = response.data.acctName;
                    let message = "";
                    inputData.debitAccountNumberSchemeType = data.schmType;
                    varValue.debitAccountNumberSchemeType = data.schmType;
                    if (data.schmType !== "OAB" && data.schmType !== "OAP") {
                        inputData.gelAccount = "NO";
                        varValue.gelAccount = "NO";
                        inputData.tranCreationDuring = "V";
                        varValue.tranCreationDuring = "V";
                    }
                    if (data.schmType === "OAB" || data.schmType === "OAP") {
                        inputData.tranCreationDuring = "O";
                        varValue.tranCreationDuring = "O";
                        inputData.gelAccount = "YES";
                        varValue.gelAccount = "YES";
                    } else {
                        if (data.freezeCode !== "" || data.acctStatus !== "A" || (data.lienDetails && data.lienDetails.length > 0)) {

                            if (data.freezeCode !== "") {
                                message = "Freeze Exists "
                            }
                            /* if(lienStatus === "YES"){
                                 message=message+", Lien Exists "
                             }
                             if(accountStatus!=="Open"){
                                 message=message+", Account is Closed "
                             }*/
                            if (data.acctStatus !== "A") {
                                message = message + ",Account is not Active"
                            }
                            if (data.lienDetails && data.lienDetails.length > 0) {
                                message = message + ", Lien Exists "
                            }
                            if (message === ", Lien Exists ") {
                                message = "";
                                this.setState({
                                    accountDetailsButton: true,
                                    errorModalBoolean: true,
                                    errorTittle: "Lien Exists",
                                    loading: false
                                })
                            }

                        }
                    }
                    let accountUrls = backEndServerURL + "/GetAccountStatusFromAccountNumber/" + (this.state.inputData[secondVarName]);
                    axios.get(accountUrls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((responseData) => {
                            if (data.schmType === "OAB" || data.schmType === "OAP") {

                            } else {
                                if (responseData.data !== "Open") {
                                    message = "Account is Closed ";
                                    this.state.inputData.debitAccountNumber = "__ERROR__";
                                    this.state.varValue.debitAccountNumber = "__ERROR__";
                                    this.forceUpdate();
                                }
                                if (message !== "") {
                                    this.state.inputData.debitAccountNumber = "__ERROR__";
                                    this.state.varValue.debitAccountNumber = "__ERROR__";
                                    this.forceUpdate();
                                    this.setState({
                                        accountDetailsButton: true,
                                        errorModalBoolean: true,
                                        errorTittle: message,
                                        loading: false
                                    });
                                    return 0;
                                }
                            }
                            if (data.schmType === "OAB" || data.schmType === "OAP") {
                                inputData.repaymentAccount = (this.state.inputData.debitAccountNumber).trim();
                                varValue.repaymentAccount = (this.state.inputData.debitAccountNumber).trim();
                                inputData.interestCreditAccount = (this.state.inputData.debitAccountNumber).trim();
                                varValue.interestCreditAccount = (this.state.inputData.debitAccountNumber).trim();
                                let url = backEndServerURL + "/intgr/accountBalanceInfo/" + (this.state.inputData.debitAccountNumber).trim();
                                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        let object = {

                                            currency: response.data.accountCurrency,
                                        };
                                        if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
                                            object.currency = response.data.accountCurrency;
                                            this.state.inputData.currencyNtb = response.data.accountCurrency;
                                        }
                                        if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                                            object.natureAccount = "Individual-Retail";
                                        } else {
                                            object.natureAccount = "Non-Individual"
                                        }
                                        if (message !== "") {
                                            object.currency = "";
                                            this.state.inputData.currencyNtb = "";
                                        }

                                        let Urls = backEndServerURL + "/FDRDPSSchemeCode/getAccountType";
                                        axios.post(Urls, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                            .then((getAccountType) => {

                                                let jsonArrayServiceList = this.props.serviceList;
                                                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                                    let jsonObjects = jsonArrayServiceList[i];
                                                    if (jsonObjects.varName === "accountType") {
                                                        this.props.serviceList[i].enum = getAccountType.data;
                                                        console.log(this.props.serviceList[i])
                                                    }
                                                }

                                                inputData.accountTypeApiCall = true;
                                                varValue.accountTypeApiCall = true;
                                                console.log(response.data);
                                                inputData.accountCbNumber = response.data.custId;
                                                //inputData.accountNumber = (this.state.inputData.debitAccountNumber).trim();
                                                inputData.debitAccountName = response.data.name;
                                                if (response.data.schemeCode === "SBSTF") {
                                                    inputData.availableAmountShow = false;
                                                    varValue.availableAmountShow = false

                                                } else {
                                                    inputData.availableAmountShow = true;
                                                    varValue.availableAmountShow = true
                                                }
                                                inputData.availableAmount = response.data.availableBalance;
                                                varValue.availableAmount = response.data.availableBalance;
                                                inputData.accountRestriction = response.data.accountRestriction;
                                                if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
                                                    inputData.currency = this.findByVarNameGetKeyValue(response.data.accountCurrency, this.state.currency);
                                                } else {
                                                    inputData.currency = response.data.accountCurrency
                                                }
                                                varValue.debitAccountName = response.data.name;
                                                //varValue.accountNumber = (this.state.inputData.debitAccountNumber).trim();
                                                if (response.data.schemeCode === "SBSTF") {
                                                    inputData.availableAmountShow = false;
                                                    varValue.availableAmountShow = false

                                                } else {
                                                    inputData.availableAmountShow = true;
                                                    varValue.availableAmountShow = true
                                                }
                                                inputData.availableAmount = response.data.availableBalance;
                                                varValue.availableAmount = response.data.availableBalance;
                                                varValue.accountRestriction = response.data.accountRestriction;
                                                varValue.currency = response.data.accountCurrency;
                                                if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
                                                    varValue.currency = this.findByVarNameGetKeyValue(response.data.accountCurrency, this.state.currency);
                                                } else {
                                                    varValue.currency = response.data.accountCurrency
                                                }
                                                this.state.inputData.debitAccountNumberApiCall = "YES";
                                                this.updateComponent();
                                                this.setState({
                                                    inputData: this.emptyValueRemove(inputData),
                                                    varValue: this.emptyValueRemove(varValue),
                                                    showValue: true,
                                                    getData: true,
                                                    getAccountType: true,
                                                    getAccountInfo: true,
                                                    loading: false,
                                                    debitDataBoolean: true,
                                                })


                                            })
                                            .catch((error) => {
                                                this.setState({
                                                    getAccountType: true,
                                                    loading: false
                                                });
                                                console.log(error);
                                            })
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        this.setState({
                                            errorModalBoolean: true,
                                            errorTittle: this.errorObjectCheck(error),
                                            loading: false
                                        })
                                    })


                            } else {
                                accountUrls = backEndServerURL + "/getAccountInfo/" + (this.state.inputData.debitAccountNumber).trim();
                                axios.get(accountUrls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        inputData.repaymentAccount = (this.state.inputData.debitAccountNumber).trim();
                                        varValue.repaymentAccount = (this.state.inputData.debitAccountNumber).trim();
                                        inputData.interestCreditAccount = (this.state.inputData.debitAccountNumber).trim();
                                        varValue.interestCreditAccount = (this.state.inputData.debitAccountNumber).trim();

                                        inputData.clearingLimitDR = response.data.drclrlim;
                                        inputData.clearingLimitCR = response.data.crclrlim;
                                        inputData.TransferLimitCR = response.data.crtxlim;
                                        if (response.data.nomineeInfoDetails !== undefined && response.data.nomineeInfoDetails !== null) {
                                            inputData.nomineeExistNot = "Y";
                                            varValue.nomineeExistNot = "Y";

                                            inputData.existingNomineeNumber = response.data.nomineeInfoDetails.length;
                                            varValue.existingNomineeNumber = response.data.nomineeInfoDetails.length;
                                        } else {
                                            inputData.nomineeExistNot = "N";
                                            varValue.nomineeExistNot = "N"
                                        }

                                        let url = backEndServerURL + "/intgr/accountBalanceInfo/" + (this.state.inputData.debitAccountNumber).trim();
                                        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                            .then((response) => {
                                                let object = {

                                                    currency: response.data.accountCurrency,
                                                };
                                                if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
                                                    object.currency = response.data.accountCurrency;
                                                    this.state.inputData.currencyNtb = response.data.accountCurrency;
                                                }
                                                if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                                                    object.natureAccount = "Individual-Retail";
                                                } else {
                                                    object.natureAccount = "Non-Individual"
                                                }
                                                if (message !== "") {
                                                    object.currency = "";
                                                    this.state.inputData.currencyNtb = "";
                                                }

                                                let Urls = backEndServerURL + "/FDRDPSSchemeCode/getAccountType";
                                                axios.post(Urls, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                    .then((getAccountType) => {

                                                        let jsonArrayServiceList = this.props.serviceList;
                                                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                                            let jsonObjects = jsonArrayServiceList[i];
                                                            if (jsonObjects.varName === "accountType") {
                                                                this.props.serviceList[i].enum = getAccountType.data;
                                                                console.log(this.props.serviceList[i])
                                                            }
                                                        }

                                                        inputData.accountTypeApiCall = true;
                                                        varValue.accountTypeApiCall = true;
                                                        console.log(response.data);
                                                        inputData.accountCbNumber = response.data.custId;
                                                        //inputData.accountNumber = (this.state.inputData.debitAccountNumber).trim();
                                                        inputData.debitAccountName = response.data.name;
                                                        if (response.data.schemeCode === "SBSTF") {
                                                            inputData.availableAmountShow = false;
                                                            varValue.availableAmountShow = false

                                                        } else {
                                                            inputData.availableAmountShow = true;
                                                            varValue.availableAmountShow = true
                                                        }
                                                        inputData.availableAmount = response.data.availableBalance;
                                                        varValue.availableAmount = response.data.availableBalance;
                                                        inputData.accountRestriction = response.data.accountRestriction;
                                                        if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
                                                            inputData.currency = this.findByVarNameGetKeyValue(response.data.accountCurrency, this.state.currency);
                                                        } else {
                                                            inputData.currency = response.data.accountCurrency
                                                        }
                                                        varValue.debitAccountName = response.data.name;
                                                        //varValue.accountNumber = (this.state.inputData.debitAccountNumber).trim();
                                                        if (response.data.schemeCode === "SBSTF") {
                                                            inputData.availableAmountShow = false;
                                                            varValue.availableAmountShow = false

                                                        } else {
                                                            inputData.availableAmountShow = true;
                                                            varValue.availableAmountShow = true
                                                        }
                                                        inputData.availableAmount = response.data.availableBalance;
                                                        varValue.availableAmount = response.data.availableBalance;
                                                        varValue.accountRestriction = response.data.accountRestriction;
                                                        varValue.currency = response.data.accountCurrency;
                                                        if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
                                                            varValue.currency = this.findByVarNameGetKeyValue(response.data.accountCurrency, this.state.currency);
                                                        } else {
                                                            varValue.currency = response.data.accountCurrency
                                                        }
                                                        this.state.inputData.debitAccountNumberApiCall = "YES";
                                                        this.updateComponent();
                                                        this.setState({
                                                            inputData: this.emptyValueRemove(inputData),
                                                            varValue: this.emptyValueRemove(varValue),
                                                            showValue: true,
                                                            getData: true,
                                                            getAccountType: true,
                                                            getAccountInfo: true,
                                                            loading: false,
                                                            debitDataBoolean: true,
                                                        })


                                                    })
                                                    .catch((error) => {
                                                        this.setState({
                                                            getAccountType: true,
                                                            loading: false
                                                        });
                                                        console.log(error);
                                                    })
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                this.setState({
                                                    errorModalBoolean: true,
                                                    errorTittle: this.errorObjectCheck(error),
                                                    loading: false
                                                })
                                            })
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        this.setState({
                                            loading: false
                                        })
                                    })
                            }


                        })

                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false,
                                errorModalBoolean: true,
                                errorTittle: this.errorObjectCheck(error),
                                debitDataBoolean: true,

                            })
                        })

                })
                .catch((error) => {
                    this.state.inputData.debitAccountNumber = "__ERROR__";
                    this.state.varValue.debitAccountNumber = "__ERROR__";
                    this.forceUpdate();
                    console.log(error);

                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: this.errorObjectCheck(error),
                        debitDataBoolean: true,

                    })
                })

        } else if (varName === "introducerID") {
            this.setState({
                loading: true
            });
            this.state.inputData.introducerIDApiCall = "NO";
            this.updateComponent();
            let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.introducerName = response.data.customerName;
                    this.state.varValue.introducerName = response.data.customerName;
                    this.state.inputData.etin = response.data.eTin;
                    this.state.varValue.etin = response.data.eTin;
                    console.log(response.data);

                    /*if (this.state.inputData.introducerId === this.state.inputData.cbNumber) {

                        this.setState({
                            errorModalBoolean: true,
                            errorTittle: "Applicant CB and Introducer CB can not be same!"
                        })
                        return 0;
                    }*/
                    this.state.inputData.introducerIDApiCall = "YES";
                    this.state.varValue.introducerIDApiCall = "YES";
                    this.updateComponent();
                    this.setState({
                        inputData: inputData,
                        varValue: varValue,
                        showValue: true,
                        loading: false,
                        debitDataBoolean: true,
                    })
                })
                .catch((error) => {
                    this.state.inputData.introducerID = "__ERROR__";
                    this.state.varValue.introducerID = "__ERROR__";
                    this.forceUpdate();
                    console.log(error);

                    this.setState({

                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Introducer ID Is Invalid!",
                        debitDataBoolean: true,

                    })
                })

        }

        /*  if (data.varName === "tenure" || data.varName === "tenureType" || data.varName === "fdValueDate" || data.varName === "fdrSchemeCode" || allInputData[data.varName] !==undefined) {
              this.maturitydateCalculate()
          }*/
        /*
      else if (data.varName === "debitAccountNumber") {
           if (this.state.inputData.debitAccountNumber !== undefined && this.state.inputData.debitAccountNumber !== null && (this.state.inputData.debitAccountNumber).trim() !== "") {
               this.setState({
                   loading: true
               })
               let url = backEndServerURL + "/intgr/accountBalanceInfo/" + (this.state.inputData.debitAccountNumber).trim();
               axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                   .then((response) => {
                       console.log(response.data)
                      inputData.debitAccountName=response.data.name
                   inputData.availableAmount=response.data.availableBalance
                   inputData.accountRestriction=response.data.accountRestriction;
                   inputData.currency=this.findByVarNameGetKeyValue(response.data.accountCurrency, this.state.currency);

                   varValue.debitAccountName=response.data.name
                   varValue.availableAmount=response.data.availableBalance
                   varValue.accountRestriction=response.data.accountRestriction;
                   varValue.currency=response.data.accountCurrency;
                   varValue.currency=this.findByVarNameGetKeyValue(response.data.accountCurrency, this.state.currency);

                       this.state.inputData.debitAccountNumberApiCall = "YES";
                       this.updateComponent();
                       this.setState({
                           inputData: inputData,
                           varValue: varValue,
                           showValue: true,
                           loading: false,
                       })
                   })
                   .catch((error) => {
                       console.log(error)
                       this.setState({

                           loading: false,
                           errorModalBoolean: true,
                           errorTittle: "Invalid Number"
                       })
                   })

           }

       }
    /!*  else if (data.varName === "cbNumber") {

           if (this.state.inputData.cbNumber !== undefined && this.state.inputData.cbNumber !== null && (this.state.inputData.cbNumber).trim() !== "") {
               this.setState({
                   loading: true
               })
               let url = backEndServerURL + "/customerInfo/" + (this.state.inputData.cbNumber).trim();
               axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                   .then((response) => {
                       console.log(response.data)
                       inputData.customerName = response.data.fullName;
                       varValue.customerName = response.data.fullName
                       this.state.inputData.cbNumberApiCall = "YES";
                       this.updateComponent();
                       this.setState({
                           inputData: inputData,
                           varValue: varValue,
                           showValue: true,
                           loading: false,
                       })
                   })
                   .catch((error) => {
                       console.log(error)
                       this.setState({

                           loading: false,
                           errorModalBoolean: true,
                           errorTittle: "Invalid Number"
                       })
                   })

           }
       }*!/*/
    };

    maturitydateCalculate = (data) => {
        let fdDate = (this.state.inputData.fdValueDate);
        let type = (this.state.inputData.tenureType);
        let tenure = (this.state.inputData.tenure);
        console.log(type);
        console.log(tenure);
        console.log(this.state.inputData.fdrSchemeCode);
        console.log(this.state.inputData.amount);
        // if (data.varName === "tenure" || data.varName === "tenureType" || data.varName === "fdValueDate" || data.varName === "fdrSchemeCode" || data.varName === "amount") {
        if (type !== undefined && tenure !== undefined && (this.state.inputData.fdrSchemeCode !== undefined || this.state.inputData.dpsSchemeCode !== undefined) && this.state.inputData.amount !== undefined) {
            let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
            let data = {};
            if (this.state.inputData.dpsSchemeCode !== undefined) {
                data.schemeCode = this.state.inputData.dpsSchemeCode.key;

            } else {
                data.schemeCode = this.state.inputData.fdrSchemeCode.key;

            }
            data.amount = this.state.inputData.amount;
            data.tenure = this.state.inputData.tenure;
            data.tenureType = this.state.inputData.tenureType;
            data.tenorType = this.state.inputData.tenureType;
            data.currency = this.state.inputData.currency;
            if(this.state.inputData.fdValueDate!==undefined){
                data.inputDate = this.state.inputData.fdValueDate;
            }
            else if(this.state.inputData.dpsValueDate!==undefined){
                data.inputDate = this.state.inputData.dpsvalueDate;
            }
            console.log(data);
            this.state.inputData.interestRateApicall = "NO";
            this.state.inputData.interestRate = "";
            this.state.varValue.interestRate = "";
            this.forceUpdate();
            axios.post(interestCalculateAPi, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.interestRate = response.data;
                    this.state.varValue.interestRate = response.data;
                    this.state.inputData.interestRateApicall = "YES";

                    this.forceUpdate()
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.interestRateApicall = "YES";

                })
        }
        if ((data.varName === "tenure" || data.varName === "tenureType" || data.varName === "fdValueDate" || data.varName === "fdrSchemeCode" || data.varName === "amount") && type === "Month") {
            this.state.inputData.fdMaturityDateApicall = "NO";
            this.state.varValue.fdMaturityDateApicall = "NO";
            this.forceUpdate();
            let someDate = new Date();
            if (this.state.inputData.fdValueDate !== undefined && this.state.inputData.fdValueDate !== null && this.state.inputData.fdValueDate !== -1) {
                someDate = new Date(this.state.inputData.fdValueDate);
            }
            let x = tenure;
            let numberOfMonthsToAdd = parseInt(x, 10);
            someDate.setMonth(someDate.getMonth() + numberOfMonthsToAdd);
            let dates = someDate.getDate();
            let month = someDate.getMonth() + 1;
            if (month <= 9) {
                month = "0" + month;
            }
            if (dates <= 9) {
                dates = "0" + dates;
            }
            /*  let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
             axios.post(interestCalculateAPi, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                 .then((response) => {
                     let year = someDate.getFullYear();
                     this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                     this.state.inputData.fdMaturityDateApicall="YES";
                     this.forceUpdate()

                     console.log(this.state.inputData["fdMaturityDate"])
                 })
                 .catch((error)=>{
                     let year = someDate.getFullYear();
                     this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                     this.state.inputData.fdMaturityDateApicall="YES";
                     this.forceUpdate()

                     console.log(this.state.inputData["fdMaturityDate"])
                 })*/
            let year = someDate.getFullYear();
            let calculateDate = dates + '-' + month + '-' + year;
            if (calculateDate === "NaN-NaN-NaN") {
                calculateDate = ""
            }
            setTimeout(() => {
                this.state.inputData.fdMaturityDate = calculateDate;
                this.state.varValue.fdMaturityDate = calculateDate;
                this.state.inputData.fdMaturityDateApicall = "YES";
                this.state.varValue.fdMaturityDateApicall = "YES";
                this.forceUpdate()
            }, 1000)
        } else if ((data.varName === "tenure" || data.varName === "tenureType" || data.varName === "fdValueDate" || data.varName === "fdrSchemeCode" || data.varName === "amount") && type === "Year") {

            this.state.inputData.fdMaturityDateApicall = "NO";
            this.state.varValue.fdMaturityDateApicall = "NO";
            this.forceUpdate();
            let someDate = new Date();
            if (this.state.inputData.fdValueDate !== undefined && this.state.inputData.fdValueDate !== null && this.state.inputData.fdValueDate !== -1) {
                someDate = new Date(this.state.inputData.fdValueDate);
            }
            let x = tenure;
            let numberOfMonthsToAdd = parseInt(x, 10);
            someDate.setFullYear(someDate.getFullYear() + numberOfMonthsToAdd);
            let dates = someDate.getDate();
            let month = someDate.getMonth() + 1;
            if (month <= 9) {
                month = "0" + month;
            }
            if (dates <= 9) {
                dates = "0" + dates;
            }
            /*  let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
              axios.post(interestCalculateAPi, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                  .then((response) => {
                      let year = someDate.getFullYear();
                      this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                      this.state.inputData.fdMaturityDateApicall="YES";
                      this.forceUpdate()

                      console.log(this.state.inputData["fdMaturityDate"])
                  })
                  .catch((error)=>{
                      let year = someDate.getFullYear();
                      this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                      this.state.inputData.fdMaturityDateApicall="YES";
                      this.forceUpdate()

                      console.log(this.state.inputData["fdMaturityDate"])
                  })*/
            let year = someDate.getFullYear();
            let calculateDate = dates + '-' + month + '-' + year;
            if (calculateDate === "NaN-NaN-NaN") {
                calculateDate = ""
            }
            setTimeout(() => {

                this.state.inputData.fdMaturityDate = calculateDate;
                this.state.varValue.fdMaturityDate = calculateDate;
                this.state.inputData.fdMaturityDateApicall = "YES";
                this.state.varValue.fdMaturityDateApicall = "YES";
                this.forceUpdate()
            }, 1000)

        } else if ((data.varName === "tenure" || data.varName === "tenureType" || data.varName === "fdValueDate" || data.varName === "fdrSchemeCode" || data.varName === "amount") && type === "Day") {

            this.state.inputData.fdMaturityDateApicall = "NO";
            this.state.varValue.fdMaturityDateApicall = "NO";
            this.forceUpdate();

            let someDate = new Date();
            if (this.state.inputData.fdValueDate !== undefined && this.state.inputData.fdValueDate !== null && this.state.inputData.fdValueDate !== -1) {
                someDate = new Date(this.state.inputData.fdValueDate);
            }
            let x = tenure;
            let numberOfDaysToAdd = parseInt(x, 10);

            someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            let date = someDate.getDate();
            let month = someDate.getMonth() + 1;
            let year = someDate.getFullYear();
            if (month <= 9) {
                month = "0" + month;
            }
            if (date <= 9) {
                date = "0" + date;
            }
            let calculateDate = date + '-' + month + '-' + year;
            if (calculateDate === "NaN-NaN-NaN") {
                calculateDate = ""
            }
            setTimeout(() => {
                this.state.inputData.fdMaturityDate = calculateDate;
                this.state.varValue.fdMaturityDate = calculateDate;

                //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                this.state.inputData.fdMaturityDateApicall = "YES";
                this.state.varValue.fdMaturityDateApicall = "YES";
                this.forceUpdate()
            }, 1000)
            /*   let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";

               axios.post(interestCalculateAPi, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                   .then((response) => {
                       this.state.inputData["fdMaturityDate"] = date + '-' + month + '-' + year;
                       this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;

                       //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                       //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                       this.state.inputData.fdMaturityDateApicall="YES";
                       this.forceUpdate()

                       console.log(this.state.inputData["fdMaturityDate"])
                   })
                   .catch((error)=>{
                       this.state.inputData["fdMaturityDate"] = date + '-' + month + '-' + year;
                       this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;

                       //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                       //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                       this.state.inputData.fdMaturityDateApicall="YES";
                       this.forceUpdate()

                       console.log(this.state.inputData["fdMaturityDate"])
                   })*/


        } else {
            this.state.inputData["fdMaturityDate"] = "";
        }


    };

    onKeyDownChange = (data) => {

        console.log(data);

        let inputData = this.state.inputData;
        let varValue = this.state.varValue;
        this.updateComponent();
        let allInputData = this.state.inputData;
        if (data.varName === "tenure" || data.varName === "tenureType" || data.varName === "fdValueDate" || data.varName === "fdrSchemeCode" || data.varName === "amount") {
            this.maturitydateCalculate(data)
        }

        if (data.varName === "debitAccountStatement") {
            this.setState({
                accountStatement: true
            })
        }

        if (data.varName === "accountType") {
            this.setState({
                loading: true
            });
            this.state.inputData.accountAcquisitionApiCall = false;
            //this.state.inputData.accountTypeApiCall= false
            this.state.inputData.tenureTypeApiCall = false;
            this.state.inputData.tenureApiCall = false;
            this.state.inputData.autoRenewalsApiCall = false;
            this.state.inputData.maturityApiCall = false;
            this.state.inputData.fdrSchemeCodeApiCall = false;

            this.state.inputData.accountAcquisition = undefined;
            this.state.varValue.accountAcquisition = undefined;
            let object = {
                currency: this.state.inputData.currency,
            };
            if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
                object.currency = this.state.inputData.currencyNtb
            }
            if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                object.natureAccount = "Individual-Retail";
            } else {
                object.natureAccount = "Non-Individual"
            }
            object.accountType = this.state.inputData.accountType;
            let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getAccountAcquisition";//{sectorCode}
            axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    let jsonArrayServiceList = this.props.serviceList;
                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "accountAcquisition") {
                            this.props.serviceList[i].enum = response.data;
                            console.log(this.props.serviceList[i])
                        }
                    }
                    this.state.inputData.accountAcquisitionApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        loading: false
                    })

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })
        } else if (data.varName === "accountAcquisition") {
            this.setState({
                loading: true
            });
            this.state.inputData.tenureTypeApiCall = false;
            this.state.inputData.tenureApiCall = false;
            this.state.inputData.autoRenewalsApiCall = false;
            this.state.inputData.maturityApiCall = false;
            this.state.inputData.fdrSchemeCodeApiCall = false;

            this.state.inputData.tenureType = undefined;
            this.state.varValue.tenureType = undefined;
            let object = {
                currency: this.state.inputData.currency,
            };
            if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
                object.currency = this.state.inputData.currencyNtb
            }
            if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                object.natureAccount = "Individual-Retail";
            } else {
                object.natureAccount = "Non-Individual"
            }

            object.accountType = this.state.inputData.accountType;
            object.accountAcquisition = this.state.inputData.accountAcquisition;
            let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getTenureType";//{sectorCode}
            axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    let jsonArrayServiceList = this.props.serviceList;
                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "tenureType") {
                            this.props.serviceList[i].enum = response.data;
                            console.log(this.props.serviceList[i])
                        }
                    }
                    this.state.inputData.tenureTypeApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        loading: false
                    })

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })
        } else if (data.varName === "tenureType") {
            this.setState({
                loading: true
            });
            this.state.inputData.tenureApiCall = false;
            this.state.inputData.autoRenewalsApiCall = false;
            this.state.inputData.maturityApiCall = false;
            this.state.inputData.fdrSchemeCodeApiCall = false;

            this.state.inputData.tenure = undefined;
            this.state.varValue.tenure = undefined;
            let object = {
                currency: this.state.inputData.currency,
            };
            if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
                object.currency = this.state.inputData.currencyNtb
            }
            if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                object.natureAccount = "Individual-Retail";
            } else {
                object.natureAccount = "Non-Individual"
            }
            object.accountType = this.state.inputData.accountType;
            object.accountAcquisition = this.state.inputData.accountAcquisition;
            object.tenureType = this.state.inputData.tenureType;
            let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getTenor";//{sectorCode}
            axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    let jsonArrayServiceList = this.props.serviceList;
                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "tenure") {
                            this.props.serviceList[i].enum = response.data;
                            console.log(this.props.serviceList[i])
                        }
                    }
                    this.state.inputData.tenureApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        loading: false
                    })

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })
        } else if (data.varName === "tenure") {
            this.setState({
                loading: true
            });
            this.state.inputData.autoRenewalsApiCall = false;
            this.state.inputData.maturityApiCall = false;
            this.state.inputData.fdrSchemeCodeApiCall = false;
            this.state.inputData.depositeTypeApicall = false;

            this.state.inputData.autoRenewals = undefined;
            this.state.varValue.autoRenewals = undefined;
            let object = {
                currency: this.state.inputData.currency,
            };
            if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
                object.currency = this.state.inputData.currencyNtb
            }
            if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                object.natureAccount = "Individual-Retail";
            } else {
                object.natureAccount = "Non-Individual"
            }
            object.accountType = this.state.inputData.accountType;
            object.accountAcquisition = this.state.inputData.accountAcquisition;
            object.tenureType = this.state.inputData.tenureType;
            object.tenure = this.state.inputData.tenure;
            let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getRenewal";//{sectorCode}
            axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    let jsonArrayServiceList = this.props.serviceList;
                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "autoRenewals") {
                            this.props.serviceList[i].enum = response.data.renewal;
                            console.log(this.props.serviceList[i])
                        }

                    }
                    this.state.inputData.depositeType = response.data.depositCode;
                    this.state.varValue.depositeType = response.data.depositCode;
                    this.state.inputData.autoRenewalsApiCall = true;
                    this.state.inputData.depositeTypeApicall = true;
                    this.forceUpdate();
                    this.setState({
                        loading: false
                    })

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })
        } else if (data.varName === "autoRenewals") {
            this.setState({
                loading: true
            });
            this.state.inputData.maturityApiCall = false;
            this.state.inputData.fdrSchemeCodeApiCall = false;

            this.state.inputData.maturity = undefined;
            this.state.varValue.maturity = undefined;
            let object = {
                currency: this.state.inputData.currency,
            };
            if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
                object.currency = this.state.inputData.currencyNtb
            }
            if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                object.natureAccount = "Individual-Retail";
            } else {
                object.natureAccount = "Non-Individual"
            }
            object.accountType = this.state.inputData.accountType;
            object.accountAcquisition = this.state.inputData.accountAcquisition;
            object.tenureType = this.state.inputData.tenureType;
            object.tenure = this.state.inputData.tenure;
            object.autoRenewals = this.state.inputData.autoRenewals;
            let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getMaturity";//{sectorCode}
            axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    let jsonArrayServiceList = this.props.serviceList;
                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "maturity") {
                            this.props.serviceList[i].enum = response.data;
                            console.log(this.props.serviceList[i])
                        }
                    }
                    this.state.inputData.maturityApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        loading: false
                    })

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })
        } else if (data.varName === "maturity") {
            this.setState({
                loading: true
            });
            this.state.inputData.fdrSchemeCodeApiCall = false;

            this.state.inputData.fdrSchemeCode = undefined;
            this.state.varValue.fdrSchemeCode = undefined;
            this.state.inputData.amount = undefined;
            this.state.varValue.amount = undefined;
            let object = {
                currency: this.state.inputData.currency,
            };
            if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
                object.currency = this.state.inputData.currencyNtb
            }
            if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                object.natureAccount = "Individual-Retail";
            } else {
                object.natureAccount = "Non-Individual"
            }
            object.accountType = this.state.inputData.accountType;
            object.accountAcquisition = this.state.inputData.accountAcquisition;
            object.tenureType = this.state.inputData.tenureType;
            object.tenure = this.state.inputData.tenure;
            object.autoRenewals = this.state.inputData.autoRenewals;
            object.maturity = this.state.inputData.maturity;
            let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getSchemeCode";//{sectorCode}
            axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    this.state.inputData.fdrSchemeCode = response.data;
                    this.state.varValue.fdrSchemeCode = response.data;
                    this.state.inputData.fdrSchemeCodeApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        loading: false
                    })

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })
        } else if (data.varName === "sectorCode") {
            this.setState({
                loading: true
            });
            this.state.inputData.sectorCodeApicall = false;
            let codeUrl = backEndServerURL + "/finMasterData/custom/subSector/" + this.state.inputData.sectorCode["key"];//{sectorCode}
            axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    let jsonArrayServiceList = this.props.commonJsonForm;
                    if (this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account') {
                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "subSectorCode") {
                                this.props.commonJsonForm[i].enum = response.data;
                                console.log("cccccccccccccccccccccccccccc");
                                console.log(this.props.commonJsonForm[i])

                            }
                        }
                    } else {
                        jsonArrayServiceList = this.props.serviceList;

                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "subSectorCode") {
                                this.props.serviceList[i].enum = response.data;
                                console.log("cccccccccccccccccccccccccccc");
                                console.log(this.props.serviceList[i])

                            }
                        }
                    }


                    this.state.inputData.sectorCodeApicall = true;
                    this.forceUpdate();
                    this.setState({
                        subsectorList: response.data,
                        loading: false
                    })

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })
        }
    };

    addDeferalForm() {

        return this.state.values.map((el, i) =>
            <React.Fragment>
                <Grid item xs={2}>
                    {
                        this.dynamicTypeOfDeferral(el)
                    }
                </Grid>
                <Grid item xs={4}>
                    {
                        this.dynamicDeferral(el)
                    }
                </Grid>
                <Grid item xs={2}>
                    {
                        this.dynamicDate(el)
                    }

                </Grid>
                <Grid item xs={2}>
                    {this.dynamicDeferralOther(el)}

                </Grid>
                <Grid item xs={2}>
                    <button
                        style={{
                            float: "left",
                            marginTop: "8px"
                        }}
                        className="btn btn-outline-primary"
                        type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                    >
                        Remove
                    </button>


                </Grid>
            </React.Fragment>
        )


    };

    addWaiverForm() {

        return this.state.waiverValues.map((el, i) =>
            <React.Fragment>
                <Grid item xs={2}>
                    {
                        this.dynamicTypeOfWaiver(el)
                    }
                </Grid>
                <Grid item xs={4}>
                    {
                        this.dynamicWaiver(el)
                    }
                </Grid>

                <Grid item xs={3}>
                    {this.dynamicWaiverOther(el)}

                </Grid>

                <Grid item xs={3}>
                    <button
                        style={{
                            float: "left",
                            marginTop: "8px"
                        }}
                        className="btn btn-outline-primary"
                        type='button' value='remove' onClick={this.removeClickWaiver.bind(this, el)}
                    >
                        Remove
                    </button>


                </Grid>

            </React.Fragment>
        )


    };

    getSearchvalue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === "null" || clone[prop] === 'undefined' || clone[prop] === undefined)
                delete clone[prop];
        return clone;
    };

    DedupDataSaveApi = (subServiceType) => {

        if (subServiceType === "INDIVIDUAL" || subServiceType === "Individual A/C") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "mandateDedupData": this.props.mandateindividualDedupData,
                "beneficiaryDedupData": this.props.beneficiaryindividualDedupData,
                "individualDedupData": this.props.individualDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(" dedup save data");
                    console.log(response.data);
                    this.setState({
                        individualDataSaveId: response.data
                    })
                })
                .catch((error) => {
                    console.log(error)
                })

        } else if (subServiceType === "Joint Account") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {

                "jointDedupData": this.props.jointDedupData,
                "mandateDedupData": this.props.mandateindividualDedupData,
                "beneficiaryDedupData": this.props.beneficiaryindividualDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(" dedup save data");
                    console.log(response.data);
                    this.setState({
                        jointDataSaveId: response.data
                    })
                })
                .catch((error) => {
                    console.log(error)
                })

        } else if (subServiceType === "Proprietorship A/C" || subServiceType === "NONINDIVIDUAL") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "mandateDedupData": this.props.mandateindividualDedupData,
                "beneficiaryDedupData": this.props.beneficiaryindividualDedupData,
                "individualDedupData": this.props.individualDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    /*axios.post(Dedupurl, {
                        "companyDedupData": this.props.companyDedupData,
                        "dedupType": subServiceType
                    }, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
                        .then((response) => {
                            this.setState({
                                companyDataSaveId: response.data
                            })
                        })
                        .catch((error) => {
                             console.log(error)
                        })*/
                    console.log(" dedup save data r");
                    console.log(response.data);
                    this.setState({
                        individualDataSaveId: response.data
                    })
                })
                .catch((error) => {
                    console.log(error)
                })

        } else if (subServiceType === "Company Account") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "jointDedupData": this.props.jointDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    axios.post(Dedupurl, {
                        "companyDedupData": this.props.companyDedupData,
                        "dedupType": subServiceType
                    }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            this.setState({
                                companyDataSaveId: response.data
                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        });
                    console.log(" dedup save data");
                    console.log(response.data);
                    this.setState({
                        jointDataSaveId: response.data
                    })
                })
                .catch((error) => {
                    console.log(error)
                })

        } else {

        }
    };

    createRemarksTable = (remarks, name) => {
        return (
            [remarks, name]
        )
    };

    dynamicApiCall = (fieldName, apiType) => {
        console.log("ffff");
        console.log(apiType);
        let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(fieldName);
                /* {
                     this.setStateapiEnumValue(apiType,response.data)
                 }*/
                if(apiType==="agentBankingOutlet"){
                   this.findByVarNameApiData("freeCode1SpecialTaxagentOutlet", response.data);
                   this.findByVarNameApiData("dpsfreeCode1SpecialTaxagentOutlet", response.data);
                }
                if (apiType === "fdrSchemeCode") {
                    let jsonArrayServiceList = this.props.serviceList;

                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "fdrSchemeCode") {
                            this.props.serviceList[i].enum = response.data;
                            console.log("cccccccccccccccccccccccccccc");
                            console.log(this.props.serviceList[i])

                        }
                    }
                }
                if (apiType === "sectorCode") {
                    let jsonArrayServiceList = this.props.serviceList;

                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "sectorCode") {
                            this.props.serviceList[i].enum = response.data;
                            console.log("cccccccccccccccccccccccccccc");
                            console.log(this.props.serviceList[i])

                        }
                    }
                }
                else if (fieldName === "phoneCountryCode") {

                    {
                        this.findByVarNameApiData("countryCodeIndividual", response.data)
                    }
                    {
                        this.findByVarNameApiData("countryCodeIndividual2", response.data)
                    }
                    this.setState({
                        phoneNumCountryCodeList: response.data
                    })
                } else {
                    {
                        this.findByVarNameApiData(fieldName, response.data)
                    }
                    {
                        this.setStateapiEnumValue(apiType, response.data)
                    }
                }

            })
            .catch((error) => {
                console.log(error);
            })
    };

    setStateapiEnumValue = (setVarname, data) => {
        if (setVarname === "city") {
            this.setState({
                city: data
            })
        } else if (setVarname === "state") {
            this.setState({
                state: data
            })
        } else if (setVarname === "currency") {
            this.setState({
                currency: data
            })
        } else if (setVarname === "country") {
            this.setState({
                country: data
            })
        } else if (setVarname === "fdrSchemeCode") {
            this.setState({
                fdrSchemeCode: data
            })
        }

    };

    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = this.props.commonJsonForm;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                console.log(getValue.key);
                let data = [];
                /* for(let j=0;j<getValue.length;j++){
                     let key=getValue[j].key;
                     let value=getValue[j].value;
                     data.push({key,value})
                 }*/
                this.props.commonJsonForm[i].enum = getValue;
                console.log(this.props.commonJsonForm[i]);
                console.log("ddddddddddddddddddddddddddddddddd")

            }
        }
    };

    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;
        console.log(value);
        console.log(getKeyValue);


        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            console.log(jsonObject);
            if (value === jsonObject.key) {
                return jsonObject;
                i = jsonArray.length;

            }
        }


    };

    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;
        console.log(value);
        console.log(getKeyValue);


        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            console.log(jsonObject);
            if (value === jsonObject.key) {
                return jsonObject;
                i = jsonArray.length;

            }
        }


    };

    apiDataSet = (data, inputData) => {
        if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
            inputData.postalCode3 = data.customerPostcode;
            inputData.passport = data.passportNumber;
            inputData.gender = data.gender;

            inputData.customerName = data.customerName;
            inputData.occupationCode = data.occupationCode;
            inputData.customerStatus = data.customerStatus;
            inputData.customerTitle = data.salutation;

            inputData.sbsCode = data.sbsCode;
            if (data.NIDBLOCK !== undefined) {
                inputData.nid = data.NIDBLOCK.referenceNum;
            }

            if (data.PASSPORTBLOCK !== undefined) {
                inputData.passport = data.PASSPORTBLOCK.referenceNum;
            }
            if (data.COMMEMLBLOCK !== undefined) {
                inputData.email = data.COMMEMLBLOCK.emailInfo
            }
            if (data.ETINBLOCK !== undefined) {
                inputData.eTin = data.ETINBLOCK.referenceNum;
                inputData.tin = data.ETINBLOCK.referenceNum;
                inputData.etin = data.ETINBLOCK.referenceNum;
            }
            if (data.DLBLOCK !== undefined) {
                inputData.drivingLicense = data.DLBLOCK.referenceNum;
            }
            if (data.BCBLOCK !== undefined) {

                inputData.registrationNo = data.BCBLOCK.referenceNum;

            }
            if (data.COMMPH1BLOCK !== undefined) {
                if (data.COMMPH1BLOCK.phoneNumCountryCode === "88") {
                    inputData.mobileTypeIndividual = "Local"
                } else if (data.COMMPH1BLOCK.phoneNumCountryCode === "0") {
                    inputData.mobileTypeIndividual = "Overseas";
                    inputData.countryCodeIndividual = this.findByVarNameGetKeyValue(data.COMMPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)
                } else {
                    //inputData.mobileTypeIndividual="Overseas"
                }

            }
            if (data.COMMPH1BLOCK !== undefined) {
                // let getPhoneNumber = data.COMMPH1BLOCK.phoneNumLocalCode;
                /* if (getPhoneNumber !== null && getPhoneNumber !== undefined) {
                     getPhoneNumber = new Array(getPhoneNumber.length - 11 + 1).join('')
                         + getPhoneNumber.slice(-11);
                 }*/
                inputData.mobileNumber = data.COMMPH1BLOCK.phoneNumLocalCode;
                inputData.designatedMobileNumber = data.COMMPH1BLOCK.phoneNumLocalCode;
                inputData.phone = data.COMMPH1BLOCK.phoneNumLocalCode
            }
            if (data.MailingBLOCK !== undefined) {
                inputData.SelectCommunicationAddress = data.MailingBLOCK.addrCategory;
                inputData.communicationAddress1 = data.MailingBLOCK.addrLine1;
                inputData.communicationAddress2 = data.MailingBLOCK.addrLine2;
                inputData.postalCode = data.MailingBLOCK.postalCode;
                inputData.state = this.findByVarNameGetKeyValue(data.MailingBLOCK.city, this.state.state);
                inputData.city = this.findByVarNameGetKeyValue(data.MailingBLOCK.state, this.state.city);
                inputData.country = this.findByVarNameGetKeyValue(data.MailingBLOCK.country, this.state.country)
            }


            let getDob = data.birthDate;
            if (getDob !== null & getDob !== undefined) {
                getDob = getDob.split('T')[0];
            }
            inputData.dob = getDob;
            inputData.selectedDate = getDob;


            inputData.customerName = data.customerName;
            /*   let getPhoneNumber = data.phoneNumber;
               if (getPhoneNumber !== null && getPhoneNumber !== undefined) {
                   getPhoneNumber = new Array(getPhoneNumber.length - 11 + 1).join('')
                       + getPhoneNumber.slice(-11);
               }
               inputData.mobileNumber = getPhoneNumber*/

            inputData.currency = this.findByVarNameGetKeyValue(data.currencyCode, this.state.currency);
            inputData.occupationCodeNormal = data.occupationCode;
            inputData.shortName = data.shortName;
            inputData.occupationCodePriority = data.occupationCode;
            inputData.occupationCodeStaff = data.occupationCode

        } else if (this.props.subServiceType === "Proprietorship A/C" || this.props.subServiceType === "NONINDIVIDUAL") {
            inputData.postalCode = data.customerPostcode;

            inputData.passport = data.passportNumber;
            inputData.gender = data.gender;
            inputData.mailingAddress1 = data.address1;
            inputData.mailingAddress2 = data.address2;
            inputData.city = data.customerCity;
            inputData.designatedEmailAddress = data.email;
            let getDob = data.birthDate;
            if (getDob !== null & getDob !== undefined) {
                getDob = getDob.split('T')[0];
            }
            inputData.dob = getDob;

            inputData.nationality = data.customerCountry;

            inputData.customerName = data.customerName;
            let getPhoneNumber = data.phoneNumber;
            if (getPhoneNumber !== null && getPhoneNumber !== undefined) {
                getPhoneNumber = new Array(getPhoneNumber.length - 11 + 1).join('')
                    + getPhoneNumber.slice(-11);
            }
            inputData.contactNumber1 = getPhoneNumber;
            inputData.nid = data.nationalId;

            inputData.occupationCodes = data.occupationCode


        } else if (this.props.subServiceType === "Company Account") {
            inputData.postalCode = data.customerPostcode;

            inputData.passport = data.passportNumber;
            inputData.gender = data.gender;
            inputData.mailingAddress1 = data.address1;
            inputData.mailingAddress2 = data.address2;
            inputData.city = data.customerCity;
            inputData.designatedEmailAddress = data.email;

            inputData.nationality = data.customerCountry;

            inputData.customerName = data.customerName;
            let getPhoneNumber = data.phoneNumber;

            if (getPhoneNumber !== null && getPhoneNumber !== undefined) {
                getPhoneNumber = new Array(getPhoneNumber.length - 11 + 1).join('')
                    + getPhoneNumber.slice(-11);
            }
            inputData.contactNumber1 = getPhoneNumber;
            inputData.nid = data.nationalId;

            inputData.occupationCodes = data.occupationCode


        }
        return inputData;
    };

    functionForGetWhenRequestGetReturn = (sectorCode, currency, accountType, accountAcquisition, tenureType, tenure, autoRenewals) => {
        let codeUrl = backEndServerURL + "/finMasterData/custom/subSector/" + sectorCode;//{sectorCode}
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let jsonArrayServiceList = this.props.serviceList;
                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                    let jsonObjects = jsonArrayServiceList[i];
                    if (jsonObjects.varName === "subSectorCode") {
                        this.props.serviceList[i].enum = response.data;
                    }
                }
                this.forceUpdate();
            })
            .catch((error) => {
                console.log(error);
            });

        let object = {
            currency: typeof currency === "object" ? currency.key : currency
        };
        if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
            object.natureAccount = "Individual-Retail";
        } else {
            object.natureAccount = "Non-Individual"
        }

        let Urls = backEndServerURL + "/FDRDPSSchemeCode/getAccountType";
        axios.post(Urls, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((getAccountType) => {
                let jsonArrayServiceList = this.props.serviceList;
                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                    let jsonObjects = jsonArrayServiceList[i];
                    if (jsonObjects.varName === "accountType") {
                        this.props.serviceList[i].enum = getAccountType.data;
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });

        let acqUrl = backEndServerURL + "/FDRDPSSchemeCode/getAccountAcquisition";
        axios.post(acqUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let jsonArrayServiceList = this.props.serviceList;
                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                    let jsonObjects = jsonArrayServiceList[i];
                    if (jsonObjects.varName === "accountAcquisition") {
                        this.props.serviceList[i].enum = response.data;
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });

        object.accountType = accountType;
        object.accountAcquisition = accountAcquisition;
        let tenureUrl = backEndServerURL + "/FDRDPSSchemeCode/getTenureType";//{sectorCode}
        axios.post(tenureUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let jsonArrayServiceList = this.props.serviceList;
                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                    let jsonObjects = jsonArrayServiceList[i];
                    if (jsonObjects.varName === "tenureType") {
                        this.props.serviceList[i].enum = response.data;
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        object.tenureType = tenureType;
        let tenorUrl = backEndServerURL + "/FDRDPSSchemeCode/getTenor";
        axios.post(tenorUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let jsonArrayServiceList = this.props.serviceList;
                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                    let jsonObjects = jsonArrayServiceList[i];
                    if (jsonObjects.varName === "tenure") {
                        this.props.serviceList[i].enum = response.data;
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            });

        object.tenure = tenure;
        let renewalUrl = backEndServerURL + "/FDRDPSSchemeCode/getRenewal";//{sectorCode}
        axios.post(renewalUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let jsonArrayServiceList = this.props.serviceList;
                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                    let jsonObjects = jsonArrayServiceList[i];
                    if (jsonObjects.varName === "autoRenewals") {
                        this.props.serviceList[i].enum = response.data.renewal;
                    }

                }
            })
            .catch((error) => {
                console.log(error)
            });
        object.autoRenewals = autoRenewals;
        let maturityUrl = backEndServerURL + "/FDRDPSSchemeCode/getMaturity";
        axios.post(maturityUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response);
                let jsonArrayServiceList = this.props.serviceList;
                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                    let jsonObjects = jsonArrayServiceList[i];
                    if (jsonObjects.varName === "maturity") {
                        this.props.serviceList[i].enum = response.data;
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    };

    dynamicApiCallBranchName = (fieldName, apiType, setData) => {
        let codeUrl = backEndServerURL + "/finMasterData/custom/branchNameSorted";
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response);
                this.findByVarNameApiData("branchSolId", response.data);
                this.setState({
                    branchAllSolId: response.data
                })
            })
            .catch((error) => {
                console.log(error)
            })
    };

    componentDidMount() {
        for (let item of this.props.commonJsonForm) {
            if (item.varName === "businessSegement") {
                item.readOnly = false;
            }
        }

        if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB" && this.props.freeFlag3 === "Joint Account") {
            for (let item of this.props.commonJsonForm) {
                if (item.varName === "cbNumber" || item.varName === "customerName" || item.varName === "nid" || item.varName === "smartCard" || item.varName === "passport" || item.varName === "birthCertificateNo" || item.varName === "drivingLicense" || item.varName === "tin" || item.varName === "mobileTypeIndividual" || item.varName === "designatedMobileNumber" || item.varName === "email" || item.varName === "phone" || item.varName === "dob" || item.varName === "nationality" || item.varName === "mobileTypeIndividual" || item.varName === "countryCodeIndividual" || item.varName === "designatedMobileNumber" || item.varName === "phone" || item.varName === "etin") {
                    item.readOnly = false;
                }
            }
        } else {
            for (let item of this.props.commonJsonForm) {
                if (item.varName === "cbNumber" || item.varName === "customerName" || item.varName === "nid" || item.varName === "smartCard" || item.varName === "passport" || item.varName === "birthCertificateNo" || item.varName === "drivingLicense" || item.varName === "tin" || item.varName === "mobileTypeIndividual" || item.varName === "designatedMobileNumber" || item.varName === "email" || item.varName === "phone" || item.varName === "dob" || item.varName === "nationality" || item.varName === "mobileTypeIndividual" || item.varName === "countryCodeIndividual" || item.varName === "designatedMobileNumber" || item.varName === "phone" || item.varName === "etin") {
                    item.readOnly = true;
                }
            }
        }


        {
            this.dynamicApiCall("city", "city")
        }
        {
            this.dynamicApiCall("agentBankingOutlet", "agentBankingOutlet")
        }
        {
            this.dynamicApiCall("state", "state")
        }
        {
            this.dynamicApiCall("currency", "currency")
        }
        {
            this.dynamicApiCall("nationality", "country")
        }
        {
            this.dynamicApiCall("country", "country")
        }
        {
            this.dynamicApiCall("fdrSchemeCodes", "fdrSchemeCode")
        }
        {
            this.dynamicApiCall("fdrSchemeCodes", "fdrSchemeCode")
        }
        {
            this.dynamicApiCall("phoneCountryCode", "phoneCountryCode")
        }
        {
            this.dynamicApiCall("sectorCode", "sectorCode")
        }
        {
            this.dynamicApiCallBranchName("branchName", "branch")
        }
        this.setState({
            loading: true
        });


        let occupationCodeUrl = backEndServerURL + "/occupationCode/getAll";
        axios.get(occupationCodeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let jsonArrayServiceList = this.props.serviceList;
                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                    let jsonObjects = jsonArrayServiceList[i];
                    if (jsonObjects.varName === "occupationCode") {
                        this.props.serviceList[i].enum = response.data;
                        console.log("cccccccccccccccccccccccccccc");
                        console.log(this.props.serviceList[i])

                    }
                }
                jsonArrayServiceList = this.props.serviceListLast;
                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                    let jsonObjects = jsonArrayServiceList[i];
                    if (jsonObjects.varName === "occupationCode") {
                        this.props.serviceListLast[i].enum = response.data;
                        console.log("cccccccccccccccccccccccccccc");
                        console.log(this.props.serviceListLast[i])

                    }
                }
                jsonArrayServiceList = this.props.commonJsonForm;
                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                    let jsonObjects = jsonArrayServiceList[i];
                    if (jsonObjects.varName === "occupationCode") {
                        this.props.commonJsonForm[i].enum = response.data;
                        console.log("cccccccccccccccccccccccccccc");
                        console.log(this.props.commonJsonForm[i])

                    }
                }


            })
            .catch((error) => {
                console.log(error);
            });

        let docsTypeUrl = backEndServerURL + "/deferralList/get/SDRegulatory";
        //let docsTypeUrl=backEndServerURL+"/deferralList/get/SDNonRegulatory";
        axios.get(docsTypeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    SDRegulatory: response.data
                })
            })
            .catch((error) => {
                console.log(error)
            });
        docsTypeUrl = backEndServerURL + "/deferralList/get/SDNonRegulatory";
        //let docsTypeUrl=backEndServerURL+"/deferralList/get/SDNonRegulatory";
        axios.get(docsTypeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    SDNonRegulatory: response.data
                })
            })
            .catch((error) => {
                console.log(error)
            });
        // alert(this.props.accountType);
        // return false;

        var remarksArray = [];
        this.state.inputData["csDeferal"] = "NO";
        let inputData = {};
        let varValue = {};
        /*
               if(this.props.AccountType === "FDR Account Opening -Without Operative Account-NTB" || this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB"){
                   inputData.etin=inputData.tin
                   varValue.etin=varValue.tin
                   inputData.businessSegement="CONVENTIONAL"
                   varValue.businessSegement="CONVENTIONAL"
               }
       */

        console.log("...appId", this.props.appId);
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;


            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let url = backEndServerURL + '/TermDepositAccountAllowedForSD/' + this.props.appId;


                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            if (response.data === false) {
                                this.state.inputData.allFieldReadOnly = "YES";
                                inputData.allFieldReadOnly = "YES";
                                this.forceUpdate()
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    this.functionForGetWhenRequestGetReturn(response.data.sectorCode["key"], response.data.currency, response.data.accountType, response.data.accountAcquisition, response.data.tenureType, response.data.tenure, response.data.autoRenewals);
                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                        console.log(response.data);
                        let tableArray = [];
                        var status = "";
                        response.data.map((deferal) => {
                            if (deferal.status === "ACTIVE") {
                                status = "Approved"
                            }
                            tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));
                        });
                        this.setState({
                            getDeferalList: tableArray
                        });

                        let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                        axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                            console.log(response.data);
                            response.data.map((data) => {
                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                            });
                            this.setState({
                                getRemarks: remarksArray
                            })
                        })
                            .catch((error) => {
                                console.log(error)
                            })

                    })
                        .catch((error) => {
                            console.log(error);
                        });


                    this.state.inputData["csDeferal"] = "YES";

                    console.log(response.data);
                    let inValue = FunctionForInputGetAndSet.getInputData(response.data);
                    let vValue = FunctionForInputGetAndSet.getInputData(response.data);
                    let varValue = this.getSearchvalue(inValue);
                    let inputData = this.getSearchvalue(vValue);
                    if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
                        inputData.showCBWithoutNTB = false
                    }
                    if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB" && this.props.freeFlag3 !== "Joint Account") {
                        inputData.isDedupIndividual = true
                    } else {
                        inputData.isDedupIndividual = false
                    }
                    FunctionForGetAcDetails.getAccountInfo(inputData.debitAccountNumber)
                        .then((response) => {
                            inputData.availableAmount = response.data.acctEftvBalance;
                            varValue.availableAmount = response.data.acctEftvBalance;
                            inputData.csRemarks = undefined;
                            varValue.csRemarks = undefined;
                            this.setState({
                                varValue: varValue,
                                appData: response.data,
                                inputData: inputData,
                                showValue: true,
                                getData: true,
                                appId: this.props.appId,
                                loading: false
                            });
                        }).catch((error) => {
                        this.setState({
                            loading: false,
                        })
                    });


                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })

                });
        } else {

            let url = backEndServerURL + "/startCase/cs_data_capture";
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let appId = response.data.id;

                    {
                        this.DedupDataSaveApi(this.props.freeFlag3)
                    }
                    let inputData = this.emptyValueRemove(this.props.searchValue);
                    let varValue = this.emptyValueRemove(this.props.searchValue);
                    if (this.props.dstFlag === true) {
                        inputData.dstFlag = true;
                        varValue.dstFlag = true;
                    }
                    if (this.props.agentBankingFlag === true) {
                        inputData.agentBankingFlag = true;
                        varValue.agentBankingFlag = true;
                    }
                    inputData.accountNumber = undefined;
                    varValue.accountNumber = undefined;
                    inputData.priority = "GENERAL";
                    varValue.priority = "GENERAL";
                    if (this.props.freeFlag1 === "FDR Account Opening - with Existing CB Only" && this.props.withKyc === true) {
                        inputData.freeFlag6 = "FDR Account Opening - Without operative CASA";
                        varValue.freeFlag6 = "FDR Account Opening - Without operative CASA";
                    } else if (this.props.freeFlag1 === "FDR Account Opening - with Existing CB Only" && this.props.withoutKyc === true) {
                        inputData.freeFlag6 = "FDR Account Opening - Using Different CASA";
                        varValue.freeFlag6 = "FDR Account Opening - Using Different CASA";
                    } else {
                        inputData.freeFlag6 = this.props.freeFlag1;
                        varValue.freeFlag6 = this.props.freeFlag1;
                    }

                    if (this.props.serviceType === 'DPS Opening') {
                        inputData.freeFlag5 = 'DPS Opening';
                        varValue.freeFlag5 = 'DPS Opening'
                    } else {
                        inputData.freeFlag5 = "FDR Opening";
                        varValue.freeFlag5 = "FDR Opening";
                    }

                    console.log("...searchValue...", inputData);
                         inputData.taxWaiverChanges = "N";
                        varValue.taxWaiverChanges = "N";
                         inputData.taxWaiverChange = "N";
                        varValue.taxWaiverChange = "N";
                      inputData.preventialRateChange = "N";
                     varValue.preventialRateChange = "N";
                    let someDate = new Date();
                    let x = 0;
                    let numberOfDaysToAdd = parseInt(x, 10);
                    someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let newDate = new Date(year, month - 1, date,);
                    inputData.fdValueDate = (newDate !== null) ? newDate.toLocaleString('en-US') : "";
                    inputData.nextExecutionDate = (newDate !== null) ? newDate.toLocaleString('en-US') : "";
                    varValue.fdValueDate = (newDate !== null) ? newDate.toLocaleString('en-US') : "";
                    varValue.nextExecutionDate = (newDate !== null) ? newDate.toLocaleString('en-US') : "";
                    inputData.currency = {masterDataName: "currency", key: "BDT", value: "BDT"};
                    varValue.currency = {masterDataName: "currency", key: "BDT", value: "BDT"};
                    inputData.etin = inputData.tin;
                    varValue.etin = varValue.tin;
                    inputData.nidVerified = "NO";
                    varValue.nidVerified = "NO";
                    inputData.uploadFileAlertRequest = true;
                    varValue.uploadFileAlertRequest = true;
                    inputData.accountNo = this.props.accountNo;
                    varValue.accountNo = this.props.accountNo;
                    inputData.cbNumber = this.props.customerId;
                    varValue.cbNumber = this.props.customerId;
                    inputData.csDeferal = "NO";
                    // inputData.tenureType = "Monthly";
                    // varValue.tenureType ="Monthly";
                    // inputData.autoRenewalS = "YES";
                    // varValue.autoRenewalS ="YES";
                    // inputData.maturityS = "Renew Principal only & Credit Interest to Account No";
                    // varValue.maturityS ="Renew Principal only & Credit Interest to Account No";
                    //inputData.accountSource = "FINACLE";
                    //inputData.accountType = "NON-INSTAPACK";
                    inputData.priority = "GENERAL";
                    if (inputData.dob !== undefined && inputData.dob !== null && inputData.dob !== "" && inputData.dob !== -1) {
                        inputData.dob = (inputData.dob).split(',')[0];
                        varValue.dob = (varValue.dob).split(',')[0];
                    }
                    inputData.doubleTennor = "Monthly";
                    varValue.doubleTennor = "Monthly";
                    //inputData.country = inputData.nationality;
                    //varValue.country = varValue.nationality;
                    inputData.monthTennor = "Monthly";
                    varValue.monthTennor = "Monthly";
                    inputData.doubleSiMaturity = "No";
                    varValue.doubleSiMaturity = "No";
                    inputData.monthSiMaturity = "Yes";
                    inputData.fdValueDate = new Date();
                    varValue.fdValueDate = new Date();

                    /*  let someDate = new Date();
                       let x = 6;
                       let numberOfMonthsToAdd = parseInt(x, 10)
                       someDate.setMonth(someDate.getMonth() + numberOfMonthsToAdd);
                       let dates = someDate.getDate();
                       let month = someDate.getMonth() + 1;
                       let year = someDate.getFullYear();
                       let finalDate = dates + '-' + month + '-' + year
                       inputData.fdMaturityDate = finalDate;
                       varValue.fdMaturityDate = someDate;*/
                    varValue.monthSiMaturity = "Yes";
                    inputData.monthMaturityYes = "Renew Principal Only and Credit Interest to the Account No";
                    varValue.monthMaturityYes = "Renew Principal Only and Credit Interest to the Account No";
                    //inputData.tennor = "Day";
                    //varValue.tennor = "Day";
                    //inputData.currency = "BDT";
                    //varValue.currency = "BDT";
                    inputData.nomineeInformationVisible = "YES";
                    varValue.nomineeInformationVisible = "YES";
                    if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
                        inputData.showCBWithoutNTB = false
                    }
                    if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB" && this.props.freeFlag3 !== "Joint Account") {
                        inputData.isDedupIndividual = true
                    } else {
                        inputData.isDedupIndividual = false
                    }
                    if (this.props.AccountType === "FDR Account Opening - with Existing CB Only") {
                        let exitDataUrl = backEndServerURL + "/cbsCustomerGet/" + this.props.customerId;
                        axios.get(exitDataUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                if( response.data.nationality!==undefined && response.data.nationality!==null  && response.data.nationality!=="BD"){
                                    this.setState({
                                        showValue: false,
                                        getData: false,
                                        loading: false,

                                    });
                                    alert("Nationality BD Required")
                                    return 0;
                                }

                                console.log(response.data);
                                let dataInput = this.emptyValueRemove(inputData);
                                let dataVarValue = this.emptyValueRemove(varValue);
                                this.setState({

                                    getNewCase: true,
                                    //inputData:JSON.parse(JSON.stringify(this.props.searchValue)),
                                    inputData: this.apiDataSet(response.data, dataInput),
                                    varValue: this.apiDataSet(response.data, dataVarValue),
                                    showValue: true,
                                    getData: true,
                                    loading: false,
                                    appId: appId,


                                });
                            })
                            .catch((error) => {
                                console.log(error);

                                this.setState({
                                    errorModalBoolean: true,
                                    errorTittle: "System Error!",
                                    loading: false
                                })

                            })
                    } else {
                        inputData.freeFlag1 = "FDR Account Opening -Without Operative Account-NTB";
                        varValue.freeFlag1 = "FDR Account Opening -Without Operative Account-NTB";
                        this.setState({
                            getNewCase: true,
                            //inputData:JSON.parse(JSON.stringify(this.props.searchValue)),
                            inputData: this.emptyValueRemove(inputData),
                            varValue: this.emptyValueRemove(varValue),
                            showValue: true,
                            getData: true,
                            loading: false,
                            appId: appId,
                        })
                    }

                    //inputData.smsAlertRequest = true;
                    /* this.setState({
                         appId: response.data.id,
                         appData: response.data.inputData,
                         getNewCase: true,
                         inputData: inputData,
                         varValue: varValue,
                         showValue: true,
                         getData: true,
                         loading: false
                     });*/
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })
        }
    }

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    renderDefferalData = () => {
        if ((this.state.getInitiateData || this.props.appId !== undefined) && this.state.getDeferalList.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Deferral List:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferal Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>


                    </div>
                </div>

            )
        }

    };

    renderRemarksData = () => {
        if ((this.state.getInitiateData || this.props.appId !== undefined) && this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Comments", "Comments By"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }

    };

    renderAddButtonShow = () => {
        if ((this.state.getInitiateData || this.props.appId !== undefined) && this.state.getData) {
            return (
                <React.Fragment>
                    <button
                        className="btn btn-outline-primary btn-sm"

                        style={{
                            width: 100,
                            float: 'right',
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={this.addClick.bind(this)}


                    >Add Deferral
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"

                        style={{
                            width: 100,
                            float: 'right',
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={this.addClickWaiver.bind(this)}


                    >Add Waiver
                    </button>
                </React.Fragment>

            )
        }

    };

    //Deferal
    dynamicTypeOfDeferral = (i) => {
        let field = JSON.parse(JSON.stringify(typeofDeferralDocs));
        field.varName = "typeofDeferralDocs" + i;
        // field.removeDefaultValue=true
        // field.setVariable="deferalType" + i
        return SelectComponent.select(this.state, this.updateComponent, field);
    };

    dynamicDeferral = (i) => {
        if ((this.state.inputData["typeofDeferralDocs" + i]) === "Regulatory") {
            let field = JSON.parse(JSON.stringify(
                {
                    "varName": "deferalType",
                    "type": "select",
                    "label": "Document Type",
                    "enum": this.state.SDRegulatory,
                    "conditional": true,
                    "conditionalVarName": "typeofDeferralDocs" + i,
                    "conditionalVarValue": "Regulatory",

                }
            ));
            field.varName = "deferalType" + i;
            return SelectComponent.select(this.state, this.updateComponent, field);
        } else if ((this.state.inputData["typeofDeferralDocs" + i]) === "Non Regulatory") {
            let field = JSON.parse(JSON.stringify(
                {
                    "varName": "deferalType",
                    "type": "select",
                    "label": "Document Type",
                    "enum": this.state.SDNonRegulatory,
                    "conditional": true,
                    "conditionalVarName": "typeofDeferralDocs" + i,
                    "conditionalVarValue": "Non Regulatory",
                }
            ));
            field.varName = "deferalType" + i;
            return SelectComponent.select(this.state, this.updateComponent, field);

        }
    };

    dynamicDeferralOther = (i) => {
        if (this.state.inputData["typeofDeferralDocs" + i] === "Regulatory" || this.state.inputData["typeofDeferralDocs" + i] === "Non Regulatory") {

            if (this.state.inputData["deferalType" + i] === "Others") {
                let field = JSON.parse(JSON.stringify(deferalOther));
                field.varName = "deferalOther" + i;
                return TextFieldComponent.text(this.state, this.updateComponent, field);
            }
        }
    };

    dynamicDate = (i) => {
        if (this.state.inputData["deferalType" + i] !== undefined) {
            let field = JSON.parse(JSON.stringify(date));
            field.varName = "expireDate" + i;
            return DateComponentDeferal.date(this.state, this.updateComponent, field);
        }
    };

    //Waiver
    dynamicTypeOfWaiver = (i) => {

        let field = JSON.parse(JSON.stringify(typeofWaiverDocs));
        field.varName = "typeofWaiverDocs" + i;
        // field.removeDefaultValue=true
        //         // field.setVariable="waiverType" + i
        return SelectComponent.select(this.state, this.updateComponent, field);
    };

    dynamicWaiver = (i) => {
        if (this.state.inputData["typeofWaiverDocs" + i] === "Regulatory") {
            let field = JSON.parse(JSON.stringify(
                {
                    "varName": "waiverType",
                    "type": "select",
                    "label": "Waiver Type",
                    "enum": this.state.SDRegulatory,
                    "conditional": true,
                    "conditionalVarName": "typeofWaiverDocs" + i,
                    "conditionalVarValue": "Regulatory",
                }
            ));
            field.varName = "waiverType" + i;
            return SelectComponent.select(this.state, this.updateComponent, field);
        } else if (this.state.inputData["typeofWaiverDocs" + i] === "Non Regulatory") {
            let field = JSON.parse(JSON.stringify(
                {
                    "varName": "waiverType",
                    "type": "select",
                    "label": "Waiver Type",
                    "enum": this.state.SDNonRegulatory,
                    "conditional": true,
                    "conditionalVarName": "typeofWaiverDocs" + i,
                    "conditionalVarValue": "Non Regulatory",
                }
            ));
            field.varName = "waiverType" + i;
            return SelectComponent.select(this.state, this.updateComponent, field);
        }

    };

    dynamicWaiverOther = (i) => {
        if (this.state.inputData["typeofWaiverDocs" + i] === "Regulatory" || this.state.inputData["typeofWaiverDocs" + i] === "Non Regulatory") {

            if (this.state.inputData["waiverType" + i] === "Others") {
                let field = JSON.parse(JSON.stringify(waiverOther));
                field.varName = "waiverOther" + i;
                return TextFieldComponent.text(this.state, this.updateComponent, field);
            }
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    handleChangeComments = () => {

    };

    makeReadOnlyObjec = (object) => {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            returnObjectVariables[i]["readOnly"] = true;
        }
        return returnObject;


    };
    makeReadOnlyObject = (object) => {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            returnObjectVariables[i]["readOnly"] = true;
        }
        return returnObject;


    };
    checkReadOnlyField = (jsonForm) => {
        if (this.state.inputData.allFieldReadOnly === "YES") {
            return this.makeReadOnlyObject(jsonForm)
        } else {
            return jsonForm
        }
    };
    returnJsonForm = () => {
        if (this.state.getData && (this.props.appId !== undefined || this.state.getInitiateData)) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.checkReadOnlyField(this.props.commonJsonForm), this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)
            )


        } else if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.checkReadOnlyField(this.props.commonJsonForm), this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)
            )
        }
    };
    returnJsonFormLast = () => {
        if (this.state.getData && (this.props.appId !== undefined || this.state.getInitiateData) && this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.checkReadOnlyField(this.props.commonJsonFormLast), this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)
            )
        } else if (this.state.getData && this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.checkReadOnlyField(this.props.commonJsonFormLast), this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)
            )
        }
    };
    renderServiceList = () => {
        if (this.state.getData && (this.props.appId !== undefined || this.state.getInitiateData)) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.checkReadOnlyField(this.props.serviceList), this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)
            )
        } else if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.checkReadOnlyField(this.props.serviceList), this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)
            )
        }
    };
    valueCheck = (data, date) => {
        let num = data;
        if (date === false) {
            if (typeof num === 'number' && date === false) {
                return data;
            } else {

            }
        } else if (date === true) {

            if (num !== 'NaN-NaN-NaN' && date === true) {
                return data;
            } else {

            }
        }

    };

    handleChangeTenure = (event, option) => {
        event.preventDefault();
        if (option !== null) {
            this.state.inputData["tenure"] = option.title;

            this.forceUpdate()


        }
        if (this.state.inputData.tenureType !== undefined && this.state.inputData.tenure !== undefined && this.state.inputData.schemeCode !== undefined && this.state.inputData.amount !== undefined) {
            let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
            let data = {};
            data.schemeCode = this.state.inputData.schemeCode.key;
            data.amount = this.state.inputData.amount;
            data.tenor = this.state.inputData.tenure;
            data.tenorType = this.state.inputData.tenureType;
            data.currency = this.state.inputData.currency;
            if(this.state.inputData.fdValueDate!==undefined){
                data.inputDate = this.state.inputData.fdValueDate;
            }
            else if(this.state.inputData.dpsValueDate!==undefined){
                data.inputDate = this.state.inputData.dpsvalueDate;
            }
            console.log(data);
            this.state.inputData.interestRateApicall = "NO";
            this.state.inputData.interestRate = "";
            this.state.varValue.interestRate = "";
            this.forceUpdate();
            axios.post(interestCalculateAPi, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.interestRate = response.data;
                    this.state.varValue.interestRate = response.data;
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate()
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate()
                })
        }

    };
    TenureFieldDayANdMonth = () => {
        if (this.state.inputData.tenureType === "Day") {
            return (
                <Grid item xs={2}>

                    <React.Fragment>
                        <Grid item>
                            <label className="input-label-common">Tenure</label>
                        </Grid>
                        <Grid item>
                            <Autocomplete onChange={(event, option) => this.handleChangeTenure(event, option)}
                                //defaultValue={this.state.inputData["numberOfNominee"]}
                                          options={tenureDay} getOptionLabel={option => option.title}
                                          renderInput={(params) => <TextField {...params} variant="outlined"

                                                                              style={{paddingRight: 20}} fullWidth/>}
                            />
                        </Grid>
                    </React.Fragment>
                </Grid>
            )
        } else if (this.state.inputData.tenureType === "Monthly") {
            return (
                <Grid item xs={2}>

                    <React.Fragment>
                        <Grid item>
                            <label className="input-label-common">Tenure</label>
                        </Grid>
                        <Grid item>
                            <Autocomplete onChange={(event, option) => this.handleChangeTenure(event, option)}
                                //defaultValue={this.state.inputData["numberOfNominee"]}
                                          options={tenureMonth} getOptionLabel={option => option.title}
                                          renderInput={(params) => <TextField {...params} variant="outlined"

                                                                              style={{paddingRight: 20}} fullWidth/>}
                            />
                        </Grid>
                    </React.Fragment>
                </Grid>
            )
        }
    };
    handleChangeTextField = (event, fieldName) => {

        if (fieldName === "schemeCode") {
            this.state.inputData.schemeCode = event.target.value
        } else if (fieldName === "amount") {
            this.state.inputData.amount = event.target.value

        }
        if (this.state.inputData.tenureType !== undefined && this.state.inputData.tenure !== undefined && this.state.inputData.schemeCode !== undefined && this.state.inputData.amount !== undefined) {
            let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
            let data = {};
            data.schemeCode = this.state.inputData.schemeCode.key;
            data.amount = this.state.inputData.amount;
            data.tenor = this.state.inputData.tenure;
            data.tenorType = this.state.inputData.tenureType;
            data.currency = this.state.inputData.currency;
            if(this.state.inputData.fdValueDate!==undefined){
                data.inputDate = this.state.inputData.fdValueDate;
            }
            else if(this.state.inputData.dpsValueDate!==undefined){
                data.inputDate = this.state.inputData.dpsvalueDate;
            }
            console.log(data);
            this.state.inputData.interestRateApicall = "NO";
            this.state.inputData.interestRate = "";
            this.state.varValue.interestRate = "";
            this.forceUpdate();
            axios.post(interestCalculateAPi, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.interestRate = response.data;
                    this.state.varValue.interestRate = response.data;
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate()
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate()
                })
        }
    };
    handleFdrSchemeCode = (event, option, fieldName) => {
        /*   this.state.inputData.schemeCode = option.value;
            this.updateComponent();*/
        this.state.inputData.schemeCode = event.target.value;
        this.updateComponent();

        if (this.state.inputData.tenureType !== undefined && this.state.inputData.tenure !== undefined && this.state.inputData.schemeCode !== undefined && this.state.inputData.amount !== undefined) {
            let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
            let data = {};
            data.schemeCode = this.state.inputData.schemeCode.key;
            data.amount = this.state.inputData.amount;
            data.tenor = this.state.inputData.tenure;
            data.tenorType = this.state.inputData.tenureType;
            data.currency = this.state.inputData.currency;
            if(this.state.inputData.fdValueDate!==undefined){
                data.inputDate = this.state.inputData.fdValueDate;
            }
            else if(this.state.inputData.dpsValueDate!==undefined){
                data.inputDate = this.state.inputData.dpsvalueDate;
            }
            console.log(data);
            this.state.inputData.interestRateApicall = "NO";
            this.state.inputData.interestRate = "";
            this.state.varValue.interestRate = "";
            this.forceUpdate();
            axios.post(interestCalculateAPi, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.interestRate = response.data;
                    this.state.varValue.interestRate = response.data;
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate()
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate()
                })
        }

    };
    /*handleChangeAutoComplete = (event, option,fieldName) => {

        if (fieldName === "schemeCode") {
            if(option!==null){
                this.state.inputData[fieldName] = option.key;
                console.log(option);
                this.forceUpdate()
            }
            else if(option===null){
                this.state.inputData[fieldName] =option;
                console.log(option);
                this.forceUpdate()
                return 0;
            }

        }
        if (this.state.inputData.tenureType !== undefined && this.state.inputData.tenure !== undefined && this.state.inputData.schemeCode !== undefined && this.state.inputData.amount !== undefined) {
            let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
            let data = {};
            data.schemeCode = this.state.inputData.schemeCode;
            data.amount = this.state.inputData.amount;
            data.tenor = this.state.inputData.tenure;
            data.tenorType = this.state.inputData.tenureType;
            console.log(data)
            axios.post(interestCalculateAPi, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.interestRate = response.data;
                    this.forceUpdate()
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };*/
    handleChangeAutoComplete = (event, option, fieldName) => {

        if (fieldName === "schemeCode") {
            if (option !== null) {
                this.state.inputData[fieldName] = option.key;
                console.log(option);
                this.forceUpdate()
            } else if (option === null) {
                this.state.inputData[fieldName] = option;
                console.log(option);
                this.forceUpdate();
                return 0;
            }

        }
        if (this.state.inputData.tenureType !== undefined && this.state.inputData.tenure !== undefined && this.state.inputData.schemeCode !== undefined && this.state.inputData.amount !== undefined) {
            let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
            let data = {};
            data.schemeCode = this.state.inputData.schemeCode.key;
            data.amount = this.state.inputData.amount;
            data.tenor = this.state.inputData.tenure;
            data.tenorType = this.state.inputData.tenureType;
            data.currency = this.state.inputData.currency;
            if(this.state.inputData.fdValueDate!==undefined){
                data.inputDate = this.state.inputData.fdValueDate;
            }
            else if(this.state.inputData.dpsValueDate!==undefined){
                data.inputDate = this.state.inputData.dpsvalueDate;
            }
            this.state.inputData.interestRateApicall = "NO";
            this.state.inputData.interestRate = "";
            this.state.varValue.interestRate = "";
            this.forceUpdate();
            axios.post(interestCalculateAPi, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.interestRate = response.data;
                    this.state.varValue.interestRate = response.data;
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate()
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate()
                })
        }
    };
    fdrInterestRateCalculator = () => {
        if (this.state.getData && this.state.inputData.accountType !== undefined && this.state.getSchemeFlag) {
            console.log(this.state.inputData.fdrSchemeCodes);
            //this.state.inputData.fdMaturityDate = finalDate;
            //this.state.varValue.fdMaturityDate = someDate;
            return (
                <React.Fragment>
                    <Grid item xs={2}>
                        <React.Fragment>
                            <Grid item>
                                <label className="input-label-common">Scheme Code *</label>
                            </Grid>
                            <Grid item>
                                <Autocomplete
                                    onChange={(event, option) => this.handleChangeAutoComplete(event, option, "schemeCode")}
                                    //defaultValue={this.state.inputData["numberOfNominee"]}
                                    options={this.state.getAllSchemeCode} getOptionLabel={option => option.value}
                                    //style={ field.readOnly===true?{paddingRight: 20}:{paddingRight: 20}}
                                    style={{paddingRight: 20}}
                                    //style={ {paddingRight: 20}}
                                    renderInput={(params) => /*<TextField {...params} variant="outlined"

                                                                      style={{paddingRight: 20}} fullWidth/>}*/

                                        <TextField {...params}
                                                   variant="outlined"

                                                   fullWidth/>}
                                />


                            </Grid>
                        </React.Fragment>
                    </Grid>
                    <Grid item xs={2}>
                        <React.Fragment>
                            <Grid item>
                                <label className="input-label-common">Amount</label>
                            </Grid>
                            <Grid item>
                                <TextField

                                    variant="outlined"

                                    defaultValue={this.state.inputData["amount"]}
                                    onBlur={(event) => this.handleChangeTextField(event, "amount")}


                                    InputProps={{

                                        readOnly: false
                                    }}

                                    //InputLabelProps={{shrink:true}}
                                    fullWidth
                                    style={{paddingRight: 20}}
                                />

                            </Grid>
                        </React.Fragment>
                    </Grid>
                    <Grid item xs={2}>
                        <React.Fragment>
                            <Grid item>
                                <label className="input-label-common">Interest Rate %</label>
                            </Grid>
                            <Grid item>
                                <TextField

                                    variant="outlined"
                                    value={this.valueCheck(this.state.inputData["interestRate"], false)}
                                    InputProps={{

                                        readOnly: true
                                    }}

                                    //InputLabelProps={{shrink:true}}
                                    fullWidth
                                    style={{color: '#212F3D', background: '#f8f9fa'}}
                                />

                            </Grid>
                        </React.Fragment>
                    </Grid>
                </React.Fragment>

            )


        }
    };
    /* returnJsonFormForFDR = () => {
         if (this.state.getData && (this.state.inputData.tenure !== undefined && this.state.inputData.tenureType !== undefined)) {
             //this.state.inputData.fdMaturityDate = finalDate;
             //this.state.varValue.fdMaturityDate = someDate;
             return (
                 <React.Fragment>
                     <Grid item xs={2}>
                         <React.Fragment>
                             <Grid item>
                                 <label className="input-label-common">FD Value Date</label>
                             </Grid>
                             <Grid item>
                                 <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                     <KeyboardDatePicker
                                         style={{
                                             paddingRight: 20
                                         }}

                                         /!*  minDate={field.minDate===true?new Date():undefined} maxDate = {field.maxDate===true?new Date():undefined}*!/
                                         value={this.state.inputData["fdValueDate"]}
                                         inputVariant="outlined"
                                         // helperText={this.state.errorMessages[field.varName]}
                                         //error={this.state.errorArray[field.varName]}
                                         //label={field.label}
                                         fullWidth
                                         onChange={(date) => {

                                             this.state.selectedDate["fdValueDate"] = date;
                                             this.state.inputData["fdValueDate"] = (date !== null) ? date.toLocaleString('en-US') : "";
                                             /!*  let someDate = date;
                                               let x = 6;
                                               let numberOfMonthsToAdd = parseInt(x, 10)
                                               someDate.setMonth(someDate.getMonth() + numberOfMonthsToAdd);
                                               let dates = someDate.getDate();
                                               let month = someDate.getMonth() + 1;
                                               let year = someDate.getFullYear();
                                               let finalDate = dates + '-' + month + '-' + year
                                               this.state.selectedDate["fdMaturityDate"] = someDate.toLocaleString('en-US')
                                               this.state.inputData["fdMaturityDate"] = finalDate.toLocaleString('en-US')*!/
                                             this.forceUpdate();
                                         }}
                                         format="dd-MM-yyyy"
                                     />

                                 </MuiPickersUtilsProvider>
                             </Grid>
                         </React.Fragment>
                     </Grid>
                     {
                         this.maturitydateCalculate(this.state.inputData.tenureType, this.state.inputData.tenure)

                     }
                     <Grid item xs={2}>
                         <React.Fragment>
                             <Grid item>
                                 <label className="input-label-common">FD Maturity Date</label>
                             </Grid>
                             <Grid item>
                                 <TextField

                                     variant="outlined"
                                     value={this.valueCheck(this.state.inputData["fdMaturityDate"], true)}
                                     InputProps={{

                                         readOnly: true
                                     }}

                                     //InputLabelProps={{shrink:true}}
                                     fullWidth
                                     style={{ color:'#212F3D',background: '#f8f9fa'  } }
                                 />

                             </Grid>
                         </React.Fragment>
                     </Grid>
                 </React.Fragment>

             )


         }
     };*/
    renderServiceListLast = () => {
        if ((this.state.getInitiateData || this.props.appId !== undefined) && this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.checkReadOnlyField(this.props.serviceListLast), this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)
            )
        }
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        /*  for (let prop in clone)
              if (clone[prop] === '' || clone[prop] === ' '  || clone[prop] === "null"   ||  clone[prop] === "undefined" )
                  delete clone[prop];*/
        return clone;
    };
    validationForHandleConfirm = (actionType) => {
        let errors = MyValidation.defaultValidation(csRemarks, this.state);
        this.forceUpdate();

        if (errors === true) {
            console.log("Not working");
            return 0;
        }
        if (actionType === "handleConfirm") {

            if (this.props.serviceType === "DPS Opening") {
                if (Number(this.state.inputData.availableAmount) < Number(this.state.inputData.amount)) {
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Insufficient Fund",
                    });
                    return 0;
                } else if (this.state.inputData.amount < 500) {
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Minimum balance Should be 500",
                    });
                    return 0;
                }
            } else if (this.props.serviceType === "FDR Opening") {
                if (this.state.inputData.currency !== undefined && this.state.inputData.currency.key !== "BDT" && this.state.inputData.currency !== "BDT") {
                    if (Number(this.state.inputData.availableAmount) < Number(this.state.inputData.amount)) {
                        this.setState({
                            loading: false,
                            errorModalBoolean: true,
                            errorTittle: "Insufficient Fund",
                        });
                        return 0;
                    } else if (this.state.inputData.amount < 500) {
                        this.setState({
                            loading: false,
                            errorModalBoolean: true,
                            errorTittle: "Minimum balance should be 500",
                        });
                        return 0;
                    }
                } else {
                    if (Number(this.state.inputData.availableAmount) < Number(this.state.inputData.amount)) {
                        this.setState({
                            loading: false,
                            errorModalBoolean: true,
                            errorTittle: "Insufficient Fund",
                        });
                        return 0;
                    } else if (this.state.inputData.amount < 10000) {
                        this.setState({
                            loading: false,
                            errorModalBoolean: true,
                            errorTittle: "Minimum balance Should be 10000",
                        });
                        return 0;
                    }
                }
            }
            let dependencyField = ((this.props.commonJsonForm).concat(this.props.serviceList));
            if (this.props.AccountType === "FDR Account Opening - with Existing CB Only") {
                dependencyField = ((this.props.commonJsonForm.concat(this.props.serviceList)));
            }


            let error = MyValidation.defaultValidation(dependencyField, this.state);
            this.forceUpdate();

            if (error === true) {
                console.log("Not working");
                return 0;
            } else {
                if (this.state.inputData.currency !== undefined && (this.state.inputData.currency.key !== "BDT" && this.state.inputData.currency !== "BDT")) {
                    this.setState({
                        notificationMessgaeCommon: "USD",
                        notificationMessgaeCommonFlag: true,
                        actionType: actionType,
                        confirmAlert: true,
                        fdrImageShowPopUp: true

                    })
                } else {
                    this.setState({
                        notificationMessgaeCommon: "BDT",
                        notificationMessgaeCommonFlag: true,
                        actionType: actionType,
                        confirmAlert: true,
                        fdrImageShowPopUp: true

                    })

                }


            }

        } else if (actionType === "handleSubmit") {
            console.log(this.state.inputData);
            /*   if (this.props.serviceType === "DPS Opening") {
                   if (Number(this.state.inputData.availableAmount) < Number(this.state.inputData.amount)) {
                       this.setState({
                           loading: false,
                           errorModalBoolean: true,
                           errorTittle: "Insufficient Fund",
                       })
                       return 0;
                   } else if (this.state.inputData.amount < 500) {
                       this.setState({
                           loading: false,
                           errorModalBoolean: true,
                           errorTittle: "Minimum balance Should be 500",
                       })
                       return 0;
                   }
               }
               else if (this.props.serviceType === "FDR Opening") {
                   if (Number(this.state.inputData.availableAmount) < Number(this.state.inputData.amount)) {
                       this.setState({
                           loading: false,
                           errorModalBoolean: true,
                           errorTittle: "Insufficient Fund",
                       })
                       return 0;
                   } else if (this.state.inputData.amount < 10000) {
                       this.setState({
                           loading: false,
                           errorModalBoolean: true,
                           errorTittle: "Minimum balance Should be 10000",
                       })
                       return 0;
                   }
               }*/
            let dependencyField = (this.props.commonJsonForm.concat(this.props.serviceList).concat(this.props.serviceListLast));
            let error = MyValidation.defaultValidation(dependencyField, this.state);

            this.forceUpdate();

            if (error === true) {
                console.log("Not working");
                return 0;
            }


            this.setState({
                actionType: actionType,
                confirmAlert: true,
                notificationMessgaeCommon: ""
            })
        } else if (actionType === "handleSubmitDraft") {
            this.setState({
                notificationMessgaeCommon: "Do You Confirm?",
                errorTittle: "Do You Confirm?",
                actionType: actionType,
                confirmAlert: true,
            })
        } else if (actionType === "handleCaseClose") {
            this.setState({
                notificationMessgaeCommon: "Do You Confirm?",
                errorTittle: "Do You Confirm?",
                actionType: actionType,
                confirmAlert: true,
            })
        }
    };
    closeConfirmAlert = (data) => {
        this.setState({
            confirmAlert: false,
            notificationMessgaeCommonFlag: false
        });
        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "handleConfirm") {
            this.handleConfirm()
        } else if (data === "YES" && this.state.actionType === "handleSubmit") {
            this.handleSubmit()
        } else if (data === "YES" && this.state.actionType === "handleSubmitDraft") {
            this.handleSubmitDraft()
        } else if (data === "YES" && this.state.actionType === "handleCaseClose") {
            this.handleCaseClose()
        }
    };
    handleCaseClose = () => {
        let variableSetUrl = backEndServerURL + "/case/close/" + this.state.appId;
        axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);


                this.close();
                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Close!",
                    alert: true,
                    redirectPage: true,

                })

            })
            .catch((error) => {
                console.log(error)
            });

    };
    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()

        {
            this.validationForHandleConfirm(type)
        }


    };
    accountAndCbDetailsModal = () => {
        this.setState({
            accountAndCbDetailsModal: false
        })
    };
    subServiceTypeDataSet = () => {
        if (this.props.subServiceType === "Joint CB") {
            return "Joint Account"
        } else if (this.props.subServiceType === 'Partnership A/C' || this.props.subServiceType === 'Private/Public Limited Company Account' || this.props.subServiceType === 'NGO/NPO/Club/Society Account' || this.props.subServiceType === 'Educational/Religious Institution Account' || this.props.subServiceType === 'Government Account' || this.props.subServiceType === 'Other Account') {
            return "Company Account"
        } else if (this.props.subServiceType === 'Individual A/C') {
            return "INDIVIDUAL";
        } else if (this.props.subServiceType === 'NONINDIVIDUAL' || this.props.subServiceType === 'Proprietorship A/C') {
            return "Proprietorship A/C";
        } else {
            return this.props.subServiceType;
        }
    }
    handleSubmitDraftAfterConfirm = () => {

        this.setState({
            loading: true
        });

        if (this.state.values.length > 0) {
            this.state.inputData["csDeferal"] = "YES";
        } else if (this.state.waiverValues.length > 0) {
            this.state.inputData["csDeferal"] = "YES";
        } else {
            this.state.inputData["csDeferal"] = "NO";
        }
        if (this.state.inputData["csDeferal"] === "YES") {
            var defType = [];
            var expDate = [];
            var typeofDeferralDocsList = [];

            let appId = this.state.appId;
            for (let i = 0; i < this.state.values.length; i++) {
                let value = this.state.values[i];
                let defferalType = this.state.inputData["deferalType" + value];
                let deferralDocs = this.state.inputData["typeofDeferralDocs" + value];
                if (defferalType === "Others") {
                    defferalType = this.state.inputData["deferalOther" + value];
                }
                typeofDeferralDocsList.push(deferralDocs);
                defType.push(defferalType);
                let expireDate = this.state.inputData["expireDate" + value];
                expDate.push(expireDate);

                console.log(expDate)
            }
            let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";
            axios.post(deferalRaisedUrl, {
                appId: appId,
                type: defType,
                dueDate: expDate,
                docType: typeofDeferralDocsList,
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        var variableSetUrl = backEndServerURL + "/save/" + this.state.appId;
        let data = this.state.inputData;

        data.cs_deferal = this.state.inputData["csDeferal"];
        data.serviceType = this.props.serviceType;
        data.individualDedupData = this.state.individualDataSaveId;
        data.jointDedupData = this.state.jointDataSaveId;
        data.companyDedupData = this.state.companyDataSaveId;
        data.jointAccountCustomerNumber = this.props.jointAccountCustomerNumber;
        data.subServiceType = this.subServiceTypeDataSet();

        if (this.props.serviceType === 'FDR Opening') {
            data.category = 'FDR';
            data.subCategory = 'Opening';
        } else if (this.props.serviceType === 'DPS Opening') {
            data.category = 'DPS';
            data.subCategory = 'Opening';
        }
        // data.dueDate=this.state.dueDate;
        // data.type=this.state.type;

        if (this.state.inputData.priority === "HIGH")
            data.urgency = 1;
        else
            data.urgency = 0;

        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);

                this.setState({
                    loading: false,
                    title: "Successfull!",
                    notificationMessage: "Successfully Save!",
                    alert: true
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: true
                })
            });
    };
    variableSetFunction = (object) => {
        let data = object;
        data.readOnlyJsonForm = true;
        let variableSetUrl = backEndServerURL + "/variables/" + this.state.appId;
        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    inputData: this.emptyValueRemove(data),
                    varValue: this.emptyValueRemove(data),
                    getData: true,
                    // loading: false,
                    alowApiCall: true
                })
            })
            .catch((error) => {
                console.log(error)
            })

    };
    handleConfirm = () => {
        console.log(this.state.inputData);
        console.log(this.props.commonJsonForm);
        let dependencyField = (this.props.commonJsonForm);
        let error = null;
        console.log(this.state.inputData);

        error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
        /*if(this.state.inputData.debitAccountNumber!==this.state.inputData.debitAccountNumberPre){
              error= MyValidation.defaultValidation(this.emptyValueRemove(this.props.commonJsonForm), this.state)

          }
          else if(this.state.inputData.debitAccountNumber===this.state.inputData.debitAccountNumberPre){

              error= MyValidation.defaultValidation(this.emptyValueRemove(makeRequiredObject(dependencyFieldJsonForm)), this.state)

          }*/
        this.forceUpdate();

        if (error === true) {
            console.log("Not working");
            return 0;
        }
        /* if(this.state.inputData.introducerID===this.state.inputData.cbNumber){

             this.setState({
                 errorModalBoolean: true,
                 errorTittle: "Applicant CB and Introducer CB can not be same!"
             })
             return 0;
         }*/

        /*let error = MyValidation.defaultValidation(this.emptyValueRemove(this.props.commonJsonForm), this.state)
        this.forceUpdate();
        if (error === true) {
            return 0;
        }*/
        /*  if (this.state.inputData !== undefined && this.state.inputData !== null && this.state.inputData.interestRate === 0) {

          }*/


        if (this.state.inputData.accountType !== undefined && this.state.inputData["interestRate"] === 0) {
            this.setState({
                errorModalBoolean: true,
                errorTittle: "Your Scheme Code Wrong,Please Exist Scheme Code Input"
            });
            return 0;
        }
        this.setState({
            loading: true,
        });
        let data = this.emptyValueRemove(this.state.inputData);

        //data.csDeferal =  this.state.inputData["csDeferal"]
        //data.cs_deferal = this.state.inputData["csDeferal"]
        if (this.props.AccountType === "FDR Account Opening -Without Operative Account-NTB") {
            data.csDeferal = "NO";
            data.cs_deferal = "NO"
        } else {
            data.csDeferal = "YES";
            data.cs_deferal = "YES";
            if (this.props.withoutKyc) {
                data.withoutKyc = true;
                data.freeFlag2 = "withoutKyc";
            } else if (this.props.withKyc) {
                data.withKyc = true;
                data.freeFlag2 = "withKyc"

            } else {
                data.withKyc = true;
                data.freeFlag2 = "withKyc"

            }


        }

        //data.accountNumber = this.state.inputData.debitAccountNumber;
        data.accountNo = this.state.inputData.debitAccountNumber;
        data.serviceType = "FDR Opening";
        data.fdrAccountType = this.subServiceTypeDataSet();
        data.individualDedupData = this.state.individualDataSaveId;
        data.jointDedupData = this.state.jointDataSaveId;
        data.subServiceType =  this.subServiceTypeDataSet();
        data.dueDate = this.state.dueDate;
        data.type = this.state.type;
        if (this.props.freeFlag1 === 'FDR Account Opening - with Existing Operative Account') {
            data.category = 'FDR';
            data.subCategory = 'Opening';
        } else if (this.props.serviceType === 'DPS Opening') {
            data.category = 'DPS';
            data.subCategory = 'Opening';
        }
        if (this.props.AccountType === "FDR Account Opening -Without Operative Account-NTB") {
            data.freeFlag1 = 'FDR Account Opening -Without Operative Account-NTB';
            data.category = 'FDR';
            data.subCategory = 'Opening';
        } else if (this.props.AccountType === "FDR Account Opening - with Existing CB Only") {
            data.freeFlag1 = 'FDR Account Opening - with Existing CB Only';
            data.category = 'FDR';
            data.subCategory = 'Opening';
        }


        let varValue = this.emptyValueRemove(this.state.varValue);

        if (this.state.inputData.priority === "HIGH")
            data.urgency = 1;
        else
            data.urgency = 0;
        let postData = FunctionForInputGetAndSet.setInputData(data);

        let variableSetUrl = backEndServerURL + "/changeStatus/" + this.state.appId + "/ACCOUNT_OPENED";

        axios.post(variableSetUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                if (this.props.cbonly) {
                    this.setState({
                        //accountDetailsModal: true,
                        loading: false,
                        getInitiateData: true,
                    });
                    {
                        this.handleSubmitDraftAfterConfirm()
                    }

                } else {
                    this.setState({
                        loading: true
                    });
                    let cbCreateUrl = backEndServerURL + "/fdrNewCBCreation/" + this.state.appId;
                    axios.get(cbCreateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response);
                            let string = response.data;
                            let typeOfString = typeof string;

                            /*   customerName={this.state.inputData.customerName}
                              solId={this.state.inputData.solId}
                              closeModal={this.accountAndCbDetailsModal}*/

                            data.cbNumber = response.data;
                            //data.accountNumber = this.state.inputData.debitAccountNumber;
                            data.solId = response.data.solId;
                            {
                                this.variableSetFunction(data)
                            }
                            this.setState({
                                // loading: false,
                                inputData: this.emptyValueRemove(data),
                                varValue: this.emptyValueRemove(data),
                                getData: true,
                                showValue: true,
                                getInitiateData: true,
                                handleConfirmBoolean: true,
                                generateNewCB: response.data,
                                generateNewAccountNumber: this.state.inputData.debitAccountNumber,
                                accountAndCbDetailsModal: true,
                                title: "Successful!",
                                notificationMessage: "Successfully Saved!",
                                alert: true,
                            });
                            {
                                this.handleSubmitDraftAfterConfirm()
                            }
                        })
                        .catch((error) => {
                            this.setState({
                                errorModalBoolean: true,
                                errorTittle: this.errorObjectCheck(error),
                                loading: false,
                                getData: true,
                                showValue: true,
                                inputData: this.emptyValueRemove(data),
                                varValue: this.emptyValueRemove(data),
                            })

                        })
                }


                /*  let variableSetUrl = backEndServerURL + "/fdrNewCBCreation/" +this.state.appId;

                  axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                      .then((response) => {



                          this.setState({
                              title: "Successfull!",
                              notificationMessage: "Successfully Saved!",
                              alert: true,
                              inputData: data,
                              varValue: varValue,

                          })

                      })
                      .catch((error)=>{
                          console.log((error));
                      })
   */


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                })
            });

        /*    this.setState({
                //accountDetailsModal: true,
                getInitiateData: true,
            })*/


    };
    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "Finacle System Error!"
                }
            } else {
                return "Finacle System Error!"
            }
        } else {
            return "Finacle System Error!"
        }
    };
    handleSubmit = () => {
        let saveDataDeferralRemarksAndRoute = {};
        let dependencyField = (this.props.commonJsonForm.concat(this.props.serviceList).concat(this.props.serviceListLast));
        let error = null;
        console.log(this.state.inputData);
        if (this.state.inputData["csWaiver"] === "YES") {
            for (let i = 0; i < this.state.waiverValues.length; i++) {
                let value = this.state.waiverValues[i];
                let waiverType = this.state.inputData["waiverType" + value];
                dependencyField.push(
                    {
                        "varName": "typeofWaiverDocs" + value,
                        "type": "select",
                        "grid": 2,
                        "required": true,

                    }, {
                        "varName": "waiverType" + value,
                        "type": "select",
                        "grid": 2,
                        "required": true,

                    },
                    /*  {
                          "varName": "waiverOther" + value,
                          "type": "text",
                          "grid": 2,
                          "required": true,

                      }*/
                );
                if (waiverType === "Others") {
                    dependencyField.push({
                        "varName": "waiverOther" + value,
                        "type": "text",
                        "grid": 2,
                        "required": true,

                    },)
                }
            }
        }


        if (this.state.inputData["csDeferal"] === "YES") {
            for (let i = 0; i < this.state.values.length; i++) {
                let value = this.state.values[i];
                let defferalType = this.state.inputData["deferalType" + value];
                dependencyField.push({
                        "varName": "typeofDeferralDocs" + value,
                        "type": "select",
                        "grid": 2,
                        "required": true,

                    },
                    {
                        "varName": "deferalType" + value,
                        "type": "select",
                        "grid": 2,
                        "required": true,

                    }
                );
                if (defferalType === "Others") {
                    dependencyField.push({
                        "varName": "deferalOther" + value,
                        "type": "text",
                        "grid": 2,
                        "required": true,

                    },)
                }
                dependencyField.push({
                    "varName": "expireDate" + value,
                    "type": "date",
                    "grid": 2,
                    "required": true,

                },);

                console.log(expDate)
            }

        }
        error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);

        this.forceUpdate();

        if (error === true) {
            console.log("Not working");
            return 0;
        }
        if (this.state.inputData.introducerID === this.state.inputData.cbNumber) {

            this.setState({
                errorModalBoolean: true,
                errorTittle: "Applicant CB and Introducer CB can not be same!"
            });
            return 0;
        }
        if (this.state.inputData.uploadFileAlertRequest === true && this.state.inputData.previewImage !== true) {
            this.setState({
                accountDetailsButton: false,
                errorModalBoolean: true,
                errorTittle: "Please Upload The File!"
            });
            return 0;
        }
        this.setState({
            loading: true
        });
        //this.state.inputData["customerId"] = "CB5984143";
        if (this.state.values.length > 0) {
            this.state.inputData["csDeferal"] = "YES";
        } else if (this.state.waiverValues.length > 0) {
            this.state.inputData["csDeferal"] = "YES";
        } else if (this.props.withKyc || this.props.withoutKyc) {
            this.state.inputData["fdr_dps_opening_send_to_bm"] = "YES";
            this.state.inputData["fdr_dps_opening_send_to_bm"] = "YES"
        } else if (this.props.freeFlag1 !== "FDR Account Opening - with Existing CB Only" && this.props.withKyc !== true && this.props.withoutKyc !== true) {
            this.state.inputData["csDeferal"] = "NO";
        }

        if (this.state.inputData["csDeferal"] === "YES") {

            var defType = [];
            var expDate = [];
            var typeofDeferralDocsList = [];

            let appId = this.state.appId;
            for (let i = 0; i < this.state.values.length; i++) {
                let value = this.state.values[i];
                let defferalType = this.state.inputData["deferalType" + value];
                let deferralDocs = this.state.inputData["typeofDeferralDocs" + value];
                if (defferalType === "Others") {
                    defferalType = this.state.inputData["deferalOther" + value];
                }
                typeofDeferralDocsList.push(deferralDocs);
                defType.push(defferalType);
                let expireDate = this.state.inputData["expireDate" + value];
                expDate.push(expireDate);

                console.log(expDate)
            }
            if (this.state.values.length > 0) {
                saveDataDeferralRemarksAndRoute.bulkDeferralDTO = {
                    appId: appId,
                    type: defType,
                    dueDate: expDate,
                    docType: typeofDeferralDocsList,
                }
            }

        }
        if (this.state.inputData["csWaiver"] === "YES") {

            var waiverType = [];
            var waiverOther = [];
            var wavType = [];
            for (let i = 0; i < this.state.waiverValues.length; i++) {
                let waiverValues = this.state.waiverValues[i];
                let docType = this.state.inputData["waiverType" + waiverValues];
                if (docType === "Others") {
                    docType = this.state.inputData["waiverOther" + waiverValues];
                }
                waiverType.push(docType);
            }
            if (this.state.waiverValues.length > 0) {
                saveDataDeferralRemarksAndRoute.bulkWaiverDTO = {
                    appId: this.state.appId,
                    type: waiverType,
                }
            }

        }
        if (this.state.inputData.csRemarks !== undefined && this.state.inputData.csRemarks !== null) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.csRemarks;
        }
        let data = this.emptyValueRemove(this.state.inputData);
        data.csRemarks = undefined;
        if (data.maturity !== "Renew Principal Only and Credit Interest to") {
            data.interestCreditAccount = "";
        }
        data.cs_deferal = this.state.inputData["csDeferal"];
        /*  data.individualDedupData = this.state.individualDataSaveId;
          data.jointDedupData = this.state.jointDataSaveId;*/
        if (this.props.freeFlag1 !== 'DPS Account Opening -with Existing Operative Account') {
            data.freeFlag4 = data.fdrSchemeCode.value;
        } else {
            data.freeFlag4 = data.dpsSchemeCode.value;
        }
        data.dueDate = this.state.dueDate;
        data.type = this.state.type;
        let varValue = this.emptyValueRemove(this.state.inputData);
        if (this.state.inputData.priority === "HIGH")
            data.urgency = 1;
        else
            data.urgency = 0;
        console.log(data);

        let postData = FunctionForInputGetAndSet.setInputData(data);
        postData.employeeBranchSolId= LocalstorageEncrypt.encryptStorageFunction.getItem("solId")

        saveDataDeferralRemarksAndRoute.map = postData;
        var url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
        axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                console.log("Successfully Routed!");

                {
                    this.props.closeModal();
                }
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                    inputData: this.emptyValueRemove(data),
                    varValue: this.emptyValueRemove(data),
                    loading: false

                })
            })
            .catch((error) => {
                this.setState({
                    loading: false
                })


            });

    };

    handleSubmitDraft = () => {
        //let error = MyValidation.defaultValidation(this.props.commonJsonForm, this.state)
        this.setState({
            loading: true
        });

        console.log(this.state.inputData);
        if (this.state.inputData["csDeferal"] === "YES") {

            var defType = [];
            var expDate = [];
            var typeofDeferralDocsList = [];

            let appId = this.state.appId;
            for (let i = 0; i < this.state.values.length; i++) {
                let value = this.state.values[i];
                let defferalType = this.state.inputData["deferalType" + value];
                let deferralDocs = this.state.inputData["typeofDeferralDocs" + value];
                if (defferalType === "Others") {
                    defferalType = this.state.inputData["deferalOther" + value];
                }
                typeofDeferralDocsList.push(deferralDocs);
                defType.push(defferalType);
                let expireDate = this.state.inputData["expireDate" + value];
                expDate.push(expireDate);

                console.log(expDate)
            }
            let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";
            axios.post(deferalRaisedUrl, {
                appId: appId,
                type: defType,
                dueDate: expDate,
                docType: typeofDeferralDocsList,
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        var variableSetUrl = backEndServerURL + "/save/" + this.state.appId;
        let data = this.state.inputData;
        data.cs_deferal = this.state.inputData["csDeferal"];
        data.serviceType = "FDR Opening";
        data.individualDedupData = this.state.individualDataSaveId;
        data.jointDedupData = this.state.jointDataSaveId;
        data.companyDedupData = this.state.companyDataSaveId;
        data.jointAccountCustomerNumber = this.props.jointAccountCustomerNumber;
        data.subServiceType =  this.subServiceTypeDataSet();
        // data.dueDate=this.state.dueDate;
        // data.type=this.state.type;

        data.category = 'FDR';
        data.subCategory = 'Opening';

        if (this.state.inputData.priority === "HIGH")
            data.urgency = 1;
        else
            data.urgency = 0;
        let postData = FunctionForInputGetAndSet.setInputData(data);
        axios.post(variableSetUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            console.log(response.data);
            this.setState({
                getInitiateData: true,
            });
            this.setState({
                loading: false,
                title: "Successfull!",
                notificationMessage: "Successfully Saved!",
                alert: true
            });
            this.props.closeModal()
        })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                })
            });
    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };
    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    addClick() {
        let randomNumber = Math.floor(Math.random() * 100000000000);
        this.setState(prevState => ({
            setAllDeferalData: true,
            values: [...prevState.values, randomNumber],

        }));

        this.state.inputData["csDeferal"] = "YES";
    };

    addClickWaiver() {
        let randomNumber = Math.floor(Math.random() * 100000000000);

        this.setState(prevState => ({
            waiverValues: [...prevState.waiverValues, randomNumber],

        }));

        this.state.inputData["csDeferal"] = "YES";
        this.state.inputData["csWaiver"] = "YES";
    };


    renderRemarks = () => {
        if ((this.state.getInitiateData || this.props.appId !== undefined) && this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
            )
        }

    };

    removeClick(i, event) {
        event.preventDefault();
        let pos = this.state.values.indexOf(i);
        if (pos > -1) {
            this.state.values.splice(pos, 1);
            this.updateComponent();
            if (this.state.values.length > 0) {
                this.state.inputData["csDeferal"] = "YES"
            } else {
                this.state.inputData["csDeferal"] = "NO"
            }
        }
    }

    removeClickWaiver(i, event) {
        event.preventDefault();
        let pos = this.state.waiverValues.indexOf(i);
        if (pos > -1) {
            this.state.waiverValues.splice(pos, 1);
            this.updateComponent();
            if (this.state.waiverValues.length > 0) {
                this.state.inputData["csDeferal"] = "YES";
                this.state.inputData["csWaiver"] = "YES"
            } else {
                this.state.inputData["csDeferal"] = "NO";
                this.state.inputData["csWaiver"] = "NO"
            }
        }
    }

    close = () => {
        if (this.props.appId !== undefined) {
            this.props.closeModal();
        } else if (this.props.freeFlag1 === "FDR Account Opening - with Existing CB Only") {
            this.props.closeModalOnlySubModal();
        } else {
            this.props.closeModal();
        }

    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };

    renderUploadButton = () => {
        if ((this.state.getInitiateData || this.props.appId !== undefined) && this.state.getData) {
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={this.uploadModal}
                >
                    Upload File
                </button>
            )
        }
    };
    handleChangeuploadFileAlertRequest = (event) => {
        let data = event.target.checked;
        this.state.inputData.uploadFileAlertRequest = data;
        this.updateComponent();
        if (data === false) {
            this.setState({
                errorModalBoolean: true,
                errorTittle: "Without Document Process!!",
            })
        }
    };
    renderUploadCheckbox = () => {
        if (this.state.getData && (this.state.getInitiateData || this.props.appId !== undefined)) {
            return (
                <CheckBox
                    checked={this.state.inputData.uploadFileAlertRequest}
                    defaultValue={this.state.inputData.uploadFileAlertRequest}
                    name="smsAlertRequest"
                    label="SMS Alert Request"
                    onChange={(event) => this.handleChangeuploadFileAlertRequest(event)}/>


            )
        }
    };
    renderAssignedImage = () => {
        if (this.state.getData && this.state.inputData.previewImage === true) {
            return (
                <button
                    style={{
                        width: 120,
                    }}
                    className="btn btn-outline-primary btn-sm"
                    onClick={this.mappingAllImage}
                >
                    Preview Document
                </button>
            )
        }
    };
    digitalAof = (event) => {
        event.preventDefault();
        let url = backEndServerURL + "/variables/" + this.state.appId;
        axios.post(url,this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let url = backEndServerURL + "/fdAgentReport/" + this.state.appId;
                functionForFileDownload.downloadFile(url, "GET", "", "Download_Digital_TD_AOF.pdf").then((response) => {
                    this.setState({
                        loading: false
                    })
                }).catch((error) => {
                    this.setState({
                        loading: false,
                        title: "Something Went Wrong!",
                        cbNotFound: true,
                    })
                })

            })
            .catch((error) => {
                console.log(error);
            })
    }
    renderDownloadGenerateForm = () => {
        if (this.state.getData && this.props.freeFlag2 !== "withKyc" && (this.state.getInitiateData || this.props.appId !== undefined)) {
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={this.digitalAof}
                >
                    Download Digital TD AOF
                </button>
            )
        }
    };
    closeModal = () => {
        this.setState({
            accountDetailsButton: false,
            getMappingAllImage: false,
            IndividualDedupModal: false,
            JointDedupModal: false,
            errorModalBoolean: false,

        })

    };
    closeUploadModal = (data) => {
        this.setState({
            uploadButtonClick: true,
            uploadModal: false,
            getMappingAllImage: false
        })
    };
    submitModal = (data) => {
        if (data === "SUBMIT") {
            this.state.inputData.previewImage = true;
            this.updateComponent()
        }
        this.setState({
            uploadModal: false,
            getMappingAllImage: false
        })

    };
    accountDetailsModal = () => {
        this.setState({
            accountDetailsModal: false,
            getInitiateData: true,
        })


    };
    IndividualDedupModal = () => {
        this.setState({
            IndividualDedupModal: true
        })
    };
    JointDedupModal = () => {
        this.setState({
            JointDedupModal: true
        })
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    renderMappingImageEditModal = () => {

        if ((this.state.getInitiateData || this.props.appId !== undefined) && this.props.subServiceType === "INDIVIDUAL" || this.state.inputData.subServiceType === "Individual A/C") {

            return (
                <FileMappingEdit dropDownOption={arrayListIndividual}
                                 appId={this.state.appId}
                                 submitModal={this.submitModal}
                                 closeModal={this.closeUploadModal}/>
            )
        } else {

            return (
                <FileMappingEdit dropDownOption={arrayListIndividual}
                                 appId={this.state.appId}
                                 submitModal={this.submitModal}
                                 closeModal={this.closeUploadModal}/>
            )
        }
    };
    renderMappingImageModal = () => {
        if ((this.state.getInitiateData || this.props.appId !== undefined) && this.props.subServiceType === "INDIVIDUAL" || this.state.inputData.subServiceType === "Individual A/C") {

            return (
                <FileMapping dropDownOption={arrayListIndividual}
                             submitModal={this.submitModal}
                             appId={this.state.appId}
                             closeModal={this.closeUploadModal}/>
            )
        } else {

            return (
                <FileMapping dropDownOption={arrayListIndividual}
                             appId={this.state.appId}
                             submitModal={this.submitModal}
                             closeModal={this.closeUploadModal}/>
            )
        }
    };

    renderButton = () => {
        if (this.state.loading === false) {
            if (this.state.getData && (this.state.getInitiateData || this.props.appId !== undefined)) {
                return (
                    <div>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={(event) => this.handleCommon(event, "handleSubmit")}

                            // onClick={this.handleSubmit}
                        >
                            Submit
                        </button>
                        &nbsp;&nbsp;
                        <button
                            className="btn btn-outline-info btn-sm"
                            // onClick={this.handleSubmitDraft}
                            onClick={(event) => this.handleCommon(event, "handleSubmitDraft")}

                        >
                            Save
                        </button>
                        {/*   &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-info btn-sm"
                        style={{}}
                        onClick={(event) => this.handleCommon(event, "handleCaseClose")}


                    >
                        Delete
                    </button>*/}
                    </div>
                )
            } else if (this.state.getData) {
                return (

                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={(event) => this.handleCommon(event, "handleConfirm")}

                        // onClick={this.handleConfirm}
                    >
                        Confirm & Save
                    </button>


                )
            }
        }


    };
    handleSignatureAndDebitAccount = (event, type) => {
        event.preventDefault();
        if (type === "handleDebitAccount") {
        } else {
            this.setState({
                signatureCardView: true
            })
        }


    };
    renderSignatureAndDebitAccount = () => {
        if (this.state.getData && ((this.state.debitDataBoolean && this.state.getAccountInfo) || this.props.appId !== undefined)) {
            return (
                <SignatureButton accountNumber={this.state.inputData.debitAccountNumber} signatureType="multiple"
                                 classes={this.props}/>
            )

        }

    };
    SignatureCardViewCloseModal = () => {
        this.setState({
            signatureCardView: false,
        })


    };
    renderWaiverList = () => {
        if (this.state.getData) {
            return (
                <WaiverList appId={this.props.appId}/>
            )
        }
    };
    renderSiCbNumber = () => {
        if (this.state.inputData.recpmtid !== undefined && this.state.inputData.recpmtid !== null) {
            return (
                <><b> SI CB number:</b>{this.state.inputData.recpmtid}</>
            )
        } else {
            return ""

        }
    };
    masterdataToString=(data)=>{
        if(data!==undefined && data.key!==undefined){
            return data.value;
        }
        else{
            return data;
        }
    }
    renderTitle = () => {
        if (this.props.appId !== undefined) {
            if (this.props.freeFlag2 === "withKyc") {
                return (
                    <h4 style={{color: "white"}} >
                        <b>FDR Account Opening - Without operative CASA</b>
                        <p style={{fontSize: "16px"}}> ( <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                            <b> CB Number:</b>{this.state.inputData.cbNumber}{this.renderSiCbNumber()}<b> Scheme
                                Code:</b>{this.state.inputData.fdrSchemeCode ? this.state.inputData.fdrSchemeCode.value : (this.state.inputData.dpsSchemeCode !== undefined ? this.state.inputData.dpsSchemeCode.value : "")}<b> Case
                                ID:</b>{this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                                onClick={this.close} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>


                )
            } else if (this.props.freeFlag2 === "withoutKyc") {
                return (
                    <h4 style={{color: "white"}} >
                        <b> FDR Account Opening - Using Different CASA </b>
                        <p style={{fontSize: "16px"}}> ( <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                            <b> CB Number:</b>{this.state.inputData.cbNumber}{this.renderSiCbNumber()}<b> Scheme
                                Code:</b>{this.state.inputData.fdrSchemeCode ? this.state.inputData.fdrSchemeCode.value : (this.state.inputData.dpsSchemeCode !== undefined ? this.state.inputData.dpsSchemeCode.value : "")}<b> Case
                                ID:</b>{this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                                onClick={this.close} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>


                )
            } else {
                return (
                    <h4 style={{color: "white"}} >
                        <b> {this.props.freeFlag1} </b>
                        <p style={{fontSize: "16px"}}> ( <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                            <b> CB Number:</b>{this.state.inputData.cbNumber}<b> Scheme
                                Code:</b>{this.state.inputData.fdrSchemeCode ? this.state.inputData.fdrSchemeCode.value : (this.state.inputData.dpsSchemeCode !== undefined ? this.state.inputData.dpsSchemeCode.value : "")}<b> Case
                                ID:</b>{this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                                onClick={this.close} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>


                )
            }


        } else {
            if (this.props.withoutKyc === true) {
                return (
                    <h4 style={{color: "white"}} >
                        <b>FDR Account Opening - Using Different CASA </b>
                        <p style={{fontSize: "16px"}}> ( <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                            <b> CB Number:</b>{this.state.inputData.cbNumber}{this.renderSiCbNumber()}<b> Scheme
                                Code:</b>{this.state.inputData.fdrSchemeCode ? this.state.inputData.fdrSchemeCode.value : (this.state.inputData.dpsSchemeCode !== undefined ? this.state.inputData.dpsSchemeCode.value : "")}<b> Case
                                ID:</b>{this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                                onClick={this.close} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
                )
            } else if (this.props.withKyc === true) {
                return (
                    <h4 style={{color: "white"}} >
                        <b> FDR Account Opening - Without operative CASA </b>
                        <p style={{fontSize: "16px"}}> ( <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                            <b> CB Number:</b>{this.state.inputData.cbNumber}{this.renderSiCbNumber()}<b> Scheme
                                Code:</b>{this.state.inputData.fdrSchemeCode ? this.state.inputData.fdrSchemeCode.value : (this.state.inputData.dpsSchemeCode !== undefined ? this.state.inputData.dpsSchemeCode.value : "")}<b> Case
                                ID:</b>{this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                                onClick={this.close} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
                )
            } else {
                return (
                    <h4 style={{color: "white"}} >
                        <b> {this.props.freeFlag1} </b>
                        <p style={{fontSize: "16px"}}> ( <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                            <b> CB Number:</b>{this.state.inputData.cbNumber}{this.renderSiCbNumber()}<b> Scheme
                                Code:</b>{this.state.inputData.fdrSchemeCode ? this.state.inputData.fdrSchemeCode.value : (this.state.inputData.dpsSchemeCode !== undefined ? this.state.inputData.dpsSchemeCode.value : "")}<b> Case
                                ID:</b>{this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                                onClick={this.close} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
                )
            }
        }
    };

    closeStatement = () => {
        this.setState({
            accountStatement: false
        })
    };
    handleChangeSdnResult = (event) => {
        event.preventDefault();
        this.setState({
            SdnAndDedupeResultShowing: true
        })

    };
    closeModalForSdnResult = () => {
        this.setState({
            SdnAndDedupeResultShowing: false
        })
    }

    renderSDNDedupeResult = () => {
        if (this.state.getData && (this.state.getInitiateData || this.props.appId !== undefined)) {

            return (
                <Button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        backgroundColor: "red",
                        color: "white",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 3,
                        paddingBottom: 3,
                        borderRadius: 3,
                        border: 1,
                        verticalAlign: 'left',
                        position: "absolute",
                        marginTop: "5px",
                        right: 50,
                    }}

                    type='button' value='add more'
                    onClick={(event) => this.handleChangeSdnResult(event)}
                >
                    Dedupe & SDN Full Result
                </Button>
            )
        }

    };
    render() {

        const {classes} = this.props;
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                    {this.renderTitle()}
                </CardHeader>
                <CardBody>
                    <div>
                        <ThemeProvider theme={theme}>
                            <Grid container spacing={1}>
                                {this.renderNotification()}
                                {this.renderSDNDedupeResult()}
                                <Grid item xs='12'>
                                    <br/>
                                    <br/>
                                    <br/>
                                </Grid>
                                {this.renderSignatureAndDebitAccount()}
                                {this.returnJsonForm()}
                                {this.returnJsonFormLast()}
                                {this.renderServiceList()}
                                {/*  {this.returnJsonFormForFDR()}
                                {this.TenureFieldDayANdMonth()}
                                {this.fdrInterestRateCalculator()}*/}
                                {this.renderServiceListLast()}
                                <Grid item xs='12'>
                                </Grid>
                                {this.renderAddButtonShow()}
                                <Grid item xs='12'>
                                </Grid>
                                {
                                    this.addDeferalForm()
                                }
                                <Grid item xs='12'>
                                </Grid>
                                {
                                    this.addWaiverForm()
                                }
                                <Grid item xs='12'>
                                </Grid>
                                {this.renderUploadCheckbox()}
                                <Grid item xs={1}>
                                    {this.renderUploadButton()}
                                </Grid>
                                <Grid item xs={1}>
                                    {this.renderAssignedImage()}
                                </Grid>
                                <Grid item xs={12}>
                                    {this.renderDownloadGenerateForm()}
                                </Grid>
                                <Grid item xs={12}>

                                    {this.renderDefferalData()}


                                </Grid>
                                <br/>
                                <Grid item xs={12}>

                                    {this.renderWaiverList()}
                                </Grid>
                                <br/>
                                <Grid item xs={12}>

                                    {this.renderRemarksData()}

                                </Grid>
                                {this.renderRemarks()}
                                <Grid item xs='12'>
                                </Grid>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.loading}>
                                    <DialogContent className={classes.dialogPaper}>

                                        <center>
                                            <img src={loader} alt=""/>
                                        </center>
                                    </DialogContent>
                                </Dialog>

                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.errorModalBoolean}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ErrorModal accountNumber={this.state.inputData.debitAccountNumber}
                                                    accountDetailsButton={this.state.accountDetailsButton}
                                                    title={this.state.errorTittle}
                                                    closeModal={this.closeModal}
                                                    subServiceType={this.props.subServiceType}
                                                    appId={this.props.appId}
                                        />

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    open={this.state.getMappingAllImage}>
                                    <DialogContent>
                                        {this.renderMappingImageEditModal()}
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    open={this.state.uploadModal}>
                                    <DialogContent>
                                        {this.renderMappingImageModal()}
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="md"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.confirmAlert}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ConfirmAlertImage
                                            notificationMessgaeCommonFlag={this.state.notificationMessgaeCommonFlag}
                                            notificationMessgaeCommon={this.state.notificationMessgaeCommon}
                                            validationForHandleConfirm={this.validationForHandleConfirm}
                                            closeModal={this.closeConfirmAlert}/>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    open={this.state.accountAndCbDetailsModal}>
                                    <DialogContent>

                                        <AccountAndCbNumberGenerate
                                            onlyCbCreation={true}
                                            generateNewAccountNumber={this.state.generateNewAccountNumber}
                                            generateNewCB={this.state.generateNewCB}
                                            customerName={this.state.inputData.customerName}
                                            solId={this.state.inputData.solId}
                                            closeModal={this.accountAndCbDetailsModal}/>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.accountDetailsModal}>
                                    <DialogContent>
                                        <AccountNoGenerate closeModal={this.accountDetailsModal}/>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="md"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.SdnAndDedupeResultShowing}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <SdnAndDedupeResultShowing closeModal={this.closeModalForSdnResult}
                                                                   branchName={this.props.branchName}

                                                                   subServiceType={this.props.subServiceType!==undefined?this.props.subServiceType:this.state.inputData.subServiceType}
                                                                   category={this.props.category!==undefined?this.props.category:this.state.inputData.category}
                                                                   serviceType={this.props.serviceType!==undefined?this.props.serviceType:this.state.inputData.serviceType}
                                                                   subCategory={this.props.subCategory!==undefined?this.props.subCategory:this.state.inputData.subCategory}
                                                                   freeFlag2={this.props.freeFlag2 === "externalUserClosedRequest" ? true : false}
                                                                   serviceType={this.props.serviceType!==undefined?this.props.serviceType:this.state.inputData.serviceType}
                                                                   customerName={this.props.customerName}
                                                                   accountNumber={this.props.accountNumber}
                                                                   cbNumber={this.props.cbNumber}
                                                                   solId={this.props.solId} globalSearch={true}
                                                                   closeIcon={this.closeModalForSdnResult}
                                                                   appId={this.props.appId!==undefined?this.props.appId:this.state.appId}/>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="md"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.history}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <SubmittedCaseHistory customerName={this.state.customerName}
                                                              accountNumber={this.state.accountNumber}
                                                              solId={this.state.solId} globalSearch={true}
                                                              closeIcon={this.closeHistoryModal}
                                                              appId={this.state.appId}
                                                              closeModal={this.closeHistoryModal}/>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.accountStatement}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <AccountStatement closeModal={this.closeStatement}
                                                          accountNumber={this.state.inputData.debitAccountNumber}/>
                                    </DialogContent>
                                </Dialog>
                                {this.renderButton()}

                            </Grid>
                        </ThemeProvider>
                    </div>
                </CardBody>
            </Card>
        );
    }

}

export default withStyles(styles)(OpeningCS);

