import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ThemeProvider, withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import MenuIcon from "@material-ui/icons/Menu";
import DashboardMenu from "./DashboardMenu";
import { backEndServerURL } from "../Common/Constant";
import logo from "../Static/logo.png";
import Link from "react-router-dom/es/Link";
import axios from "axios";
import profile from "../Static/profile.jpg";
import Functions from "../Common/Functions";
import { Redirect } from "react-router-dom";
import { Avatar, Dialog } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import ConfirmAlert from "./workflow/CommonComponent/ConfirmAlert";
import loader from "../Static/loader.gif";
import RoleChangeLogin from "./RoleChangeLogin";
import LocalstorageEncrypt from "./JsonForm/LocalstorageEncrypt";
import CommonJsonFormComponent from "./JsonForm/CommonJsonFormComponent";
import theme from "./JsonForm/CustomeTheme2";
import Grid from "@material-ui/core/Grid/index";
import MyValidation from "./JsonForm/MyValidation";
import { deepOrange } from "@material-ui/core/colors";

import { Icon } from "semantic-ui-react";


let gridTileStyle = {
  position: "relative",
  float: "left",
  width: "100%",
  minHeight: "400px",
  minWidth: "664px",
  overflow: "hidden",
  height: "100% !important",
};
const drawerWidth = 250;

let jsonForm = [];
const styles = (theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    //header line background Color
    backgroundColor: "#142398",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    //tool ber back ground color
    backgroundColor: "#ffffff",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#FFFFFF",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
      backgroundColor: "white",
    }),
  },
  appBarShift: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
      backgroundColor: "white",
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
    backgroundColor: "#142398",
  },
  menuButtonHidden: {
    display: "none",
    backgroundColor: "#142398",
  },
  title: {
    flexGrow: 2,
    display: "block",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    //sidebar back ground color
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
      backgroundColor: "#ffffff",
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
      backgroundColor: "#142398",
    }),
    width: 0,
    [theme.breakpoints.up("sm")]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto",
    //content color
    backgroundColor: "#f0f0fe",
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing.unit * 2,
  },
  logout: {
    backgroundColor: "#f0f0fe",
    height: 30,
  },
  orangeAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: "#ffbd21",
  },
});

class DashBoardHeader extends React.Component {
  state = {
    open: true,
    redirectLogin: false,
    checkedForSwitch:
      LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "BM"
        ? true
        : false,
    checkedForSwitchTemporary: false,
    confirmAlert: false,
    actionType: "",
    temporaryRoleName: "",
    notificationMessgaeCommon: "",
    loading: false,
    ChangeAnotherRoleBoolean: false,
    anotherRoleChangePermission: false,
    inputData: {},
    varValue: {},
    errorMessages: {},
    errorArray: {},
    getData: true,
    showValue: true,
  };
  validationForHandleConfirm = () => {
    let error = MyValidation.defaultValidation(jsonForm, this.state);
    this.forceUpdate();

    if (error === true) {
      console.log("Not working");
      return 0;
    }
    this.setState({
      actionType: "handleConfirm",
      confirmAlert: true,
    });
  };
  closeConfirmAlert = (data) => {
    this.setState({
      confirmAlert: false,
      //anotherRoleChangePermission: false
    });
    if (data === "NO") {
    } else if (data === "YES" && this.state.actionType === "handleConfirm") {
      this.setState({
        checkedForSwitch: this.state.checkedForSwitchTemporary,
        ChangeAnotherRoleBoolean: true,
      });
    }
  };

  renderChangeAnotherRole = () => {
    if (this.state.ChangeAnotherRoleBoolean === true) {
      return (
        <RoleChangeLogin
          unitName={this.state.inputData.switchBranchName}
          group={this.state.inputData.switchRole}
          userName={LocalstorageEncrypt.encryptStorageFunction.getItem(
            "username"
          )}
        />
      );
    }
  };

  handleCommon = (event) => {
    {
      this.validationForHandleConfirm();
    }
  };
  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  userLogout = () => {
    let url = backEndServerURL + "/logMeOut";

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
      .then((response) => {
        LocalstorageEncrypt.encryptStorageFunction.removeItem("username");
        LocalstorageEncrypt.encryptStorageFunction.removeItem("roles");
        LocalstorageEncrypt.encryptStorageFunction.removeItem("menus");
        sessionStorage.removeItem("accessToken");
        // Functions.removeCookie();

        this.setState({
          redirectLogin: true,
        });
      })
      .catch((error) => {});

    // cookie.remove("spring_session");
  };

  toggleButton = (event) => {
    let open = !this.state.open;
    this.setState({ open });
    this.props.drawerOpenIndicator(open);
  };
  accountType = () => {
    return (
      <>
        
        {/* {LocalstorageEncrypt.encryptStorageFunction.getItem("workplaceUnits")} */}
      </>
    );
  };
  renderDashboard = (event) => {
    // if(LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "CS"){
    //     window.location.replace("/#/csdashboard/CS");
    // }
    // else if(LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "BM" || LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "BOM") {
    //     window.location.replace("/#/bmdashboard");
    // }
  };

  renderBranchName = () => {
    return LocalstorageEncrypt.encryptStorageFunction.getItem("workplaceUnits");
  };

  componentDidMount() {
    jsonForm = [];
    if (
      LocalstorageEncrypt.encryptStorageFunction.getItem(
        "roleSwithPermission"
      ) === "YES"
    ) {
      let url =
        backEndServerURL +
        "/anotherRoleChangePermission/" +
        LocalstorageEncrypt.encryptStorageFunction.getItem("username");
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          },
        })
        .then((response) => {
          /*  let rolePermissionList=(LocalstorageEncrypt.encryptStorageFunction.getItem("rolePermissionName")).split("&&");
                    for (var i = 0; i < rolePermissionList.length; i++) {
                        rolePermissionList.push(rolePermissionList[i]);
                    }*/
          let rolePermissionList = LocalstorageEncrypt.encryptStorageFunction
            .getItem("rolePermissionName")
            .split("&&");
          console.log(rolePermissionList);
          let url =
            backEndServerURL +
            "/userRelatedBranchName/" +
            LocalstorageEncrypt.encryptStorageFunction.getItem(
              "workplaceUnits"
            );
          axios
            .get(url, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem(
                  "accessToken"
                )}`,
              },
            })
            .then((responseBranchList) => {
              jsonForm.push(
                {
                  varName: "empty1",
                  type: "empty",
                  grid: 3,
                },

                {
                  varName: "switchRole",
                  type: "select",
                  enum:
                    // LocalstorageEncrypt.encryptStorageFunction.getItem("rolePermissionName")==="BM & BOM"?["BM","BOM" ]:[LocalstorageEncrypt.encryptStorageFunction.getItem("rolePermissionName")]
                    rolePermissionList,
                  required: true,
                  borderColorChange: "white",
                  label: "Swith Role Name",
                  grid: 2,
                },
                {
                  varName: "switchBranchName",
                  type: "select",
                  enum: responseBranchList.data,
                  borderColorChange: "white",
                  required: true,
                  label: "Swith Branch Name",
                  grid: 2,
                }
              );
              this.setState({
                anotherRoleChangePermission: true,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  updateComponent = () => {
    this.forceUpdate();
  };
  renderJsonForm = () => {
    if (this.state.anotherRoleChangePermission === true) {
      return CommonJsonFormComponent.renderJsonForm(
        this.state,
        jsonForm,
        this.updateComponent,
        "",
        ""
      );
    }
  };

  renderSubmitButton = () => {
    if (this.state.anotherRoleChangePermission === true) {
      return (
        <button
          className="btn btn-light"
          style={{
            //  verticalAlign: 'middle',
            marginTop: "15px",
            marginLeft: "20px",
          }}
          onClick={(event) => this.handleCommon(event, "handleSubmit")}
        >
          Switch
        </button>
      );
    }
  };
  checkAccessToken = () => {
    if (
      LocalstorageEncrypt.encryptStorageFunction.getItem("username") ===
      undefined
    ) {
      window.location = "/cms/#/login";
      window.location.reload();
    }
  };
  render() {
    const { classes } = this.props;
    if (
      LocalstorageEncrypt.encryptStorageFunction.getItem("username") === null
    ) {
      return (
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      );
      //window.location = '/#/login';
    } else {
      return (
        (
          <div>
            {
              //this.renderRedirect()
              //redirectToLogin(this.state)
              Functions.redirectToLogin(this.state)
            }
          </div>
        ),
        (
          <div className={classes.root}>
            <CssBaseline />

            <AppBar
              position="fixed"
              className={classNames(
                classes.appBar,
                this.state.open && classes.appBarShift
              )}
            >
              <Toolbar position="fixed" className={classes.toolbar}>
                <IconButton onClick={this.toggleButton}>
                  <MenuIcon style={{color: "#FFFFFF"}} />
                </IconButton>
                <IconButton color="default">
                  <Badge color="default">
                    <img
                      onClick={(event) => this.renderDashboard(event)}
                      height={30}
                      src={logo}
                    />
                  </Badge>
                </IconButton>
                {/*
                                { this.state.anotherRoleChangePermission !== true &&    <Typography
                                    component="h1"
                                    variant="h6"
                                    color="inherit"
                                    noWrap
                                    className={classes.title}
                                >
                                    {this.accountType()}


                                </Typography>}*/}
               <Typography
                                    component="h1"
                                    variant="h6"
                                    color="inherit"
                                    noWrap
                                    className={classes.title}
                                >
                                    {this.accountType()}


                                </Typography>
                {/*
                                 { (LocalstorageEncrypt.encryptStorageFunction.getItem("roles")==="BOM" || LocalstorageEncrypt.encryptStorageFunction.getItem("roles")==="BM") && this.state.anotherRoleChangePermission===true &&
*/}
                {/*        { this.state.anotherRoleChangePermission === true &&   <ThemeProvider theme={theme}>
                                    <Grid container>
                                        <Typography
                                            component="h1"
                                            variant="h6"
                                            color="inherit"
                                            noWrap
                                            className={classes.title}
                                        >
                                            {this.accountType()}


                                        </Typography>
                                        {
                                            this.renderJsonForm()
                                        }

                                        <Grid item xs={2}>
                                            {
                                                this.renderSubmitButton()
                                            }
                                        </Grid>
                                    </Grid>
                                </ThemeProvider>}*/}
                <Dialog
                  fullWidth="true"
                  maxWidth="sm"
                  className={classes.modal}
                  classes={{ paper: classes.dialogPaper }}
                  open={this.state.confirmAlert}
                >
                  <DialogContent className={classes.dialogPaper}>
                    <ConfirmAlert
                      //  notificationMessgaeCommonFlag={true}
                      // notificationMessgaeCommon={this.state.notificationMessgaeCommon}
                      closeModal={this.closeConfirmAlert}
                    />
                  </DialogContent>
                </Dialog>
                <Dialog
                  fullWidth="true"
                  maxWidth="sm"
                  className={classes.modal}
                  classes={{ paper: classes.dialogPaper }}
                  open={this.state.loading}
                >
                  <DialogContent className={classes.dialogPaper}>
                    <center>
                      <img src={loader} alt="" />
                    </center>
                  </DialogContent>
                </Dialog>
                 
                {this.renderChangeAnotherRole()}
                <div className="d-flex  abs align-items-center j">

                <div className="d-flex     " style={{marginRight: "15px"}}>
                <p   style={{ backgroundColor: "#142398", color: "#FFFFFF" ,fontWeight:'bolder'}}>
                  <center>
                    {LocalstorageEncrypt.encryptStorageFunction.getItem(
                      "fullName"
                    )}
                    <br />
                    {LocalstorageEncrypt.encryptStorageFunction.getItem(
                      "username"
                    )}
                  </center>
                </p>
                {/* <p className="   text-white"> {LocalstorageEncrypt.encryptStorageFunction.getItem(
                      "fullName"
                    )}</p>
                    <br />
            <p className="   text-white">{LocalstorageEncrypt.encryptStorageFunction.getItem(
                      "username"
                    )}</p> */}
                </div>

                {/* <h6 style={{ backgroundColor: "#142398", color: "#FFFFFF" }}>
                  <center>
                    {LocalstorageEncrypt.encryptStorageFunction.getItem(
                      "fullName"
                    )}
                    <br />
                    {LocalstorageEncrypt.encryptStorageFunction.getItem(
                      "username"
                    )}
                  </center>
                </h6> */}
                &nbsp;&nbsp;
                {/* <img
                  style={{ "border-radius": "20%" }}
                  height={40}
                  src={profile}
                />  */}
                 {/* <Avatar className={classes.orangeAvatar}> {LocalstorageEncrypt.encryptStorageFunction.getItem(
                      "fullName"
                    ).charAt(0)}</Avatar> */}
                  &nbsp;&nbsp;  
                <h5 style={{ color: "white" ,fontWeight:'bolder'}}>
                  <Link
                    style={{ backgroundColor: "#142398", color: "#FFFFFF" ,marginRight:'5px'}}
                    onClick={this.userLogout}
                    to="/login"
                  >
                         <Icon name="sign out alternate" style={{
                        
                      }} />
                    Logout 
                  </Link>
                </h5>  </div>
              </Toolbar>
            </AppBar>

            <Drawer
              variant="permanent"
              classes={{
                paper: classNames(
                  classes.drawerPaper,
                  !this.state.open && classes.drawerPaperClose
                ),
              }}
              open={this.state.open}
            >
              <Divider />

              <DashboardMenu
                children={this.props.children}
                permission={this.props.permission}
              />
            </Drawer>
          </div>
        )
      );
    }
  }
}

DashBoardHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DashBoardHeader);
