import React, {Component} from "react";
import Grid from "@material-ui/core/Grid/index";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import axios from "axios/index";
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import CBNotFound from "../../workflow/CASA/CBNotFound";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "../../Table/Table";
import MyValidation from "../../JsonForm/MyValidation";
import {bomJsonForm} from "./JsonForm"

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let bomRemarks = [
    {
        "varName": "bomRemarks",
        "type": "textArea",
        "label": "BOM Remarks",
        "grid": 12
    }];

class BomInbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: [],
            showValue: false,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            searchValue: true,
            appId: 0,
            getMappingAllImage: false,
            fileUploadData: {},
            uploadModal: false,
            preview: false,
            pastDocumentModal: false,
            documentList: [],
            getDocument: false,
            getData: false,
            loading: true,
            cbNotFound: false,
            disabled: false,
            getRemarks: []
        };
    }

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    functionForGetRemarks = () => {
        let remarksArray = [];
        let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
        axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            response.data.map((data) => {
                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
            });
            this.setState({
                getRemarks: remarksArray
            })
        })
            .catch((error) => {
                console.log(error);
                this.setState({
                    disabled: false
                })
            })
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    componentDidMount() {
        let url = backEndServerURL + "/variables/" + this.props.appId;
        axios
            .get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                this.functionForGetRemarks();
                this.setState({
                    varValue: response.data,
                    appData: this.copyJson(response.data),
                    inputData: this.copyJson(response.data),
                    showValue: true,
                    getData: true,
                    loading: false
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type="success"
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        });
    };

    functionForRemarksSave = () => {
        let commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.bomRemarks + "/" + this.props.appId;
        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                this.setState({
                    disabled: false,
                });
                console.log(error)
            });
        this.state.inputData.bomRemarks = undefined;
    };

    approveHandler = event => {
        event.preventDefault();
        this.setState({
            disabled: false
        });
        this.functionForRemarksSave();
        let url = backEndServerURL + "/variables/" + this.props.appId;
        axios
            .post(url, {bom_approval: "APPROVED"}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let postData = this.state.inputData;
                postData.appId = this.props.appId;
                postData.requisitionType = this.state.inputData.category;
                let routeTaskUrl = backEndServerURL + "/saveGiftAndReloadableCardRequisition";
                axios
                    .post(routeTaskUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => {
                        let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                        axios
                            .get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then(response => {
                                this.setState({
                                    alert: true,
                                    title: "Successful!",
                                    notificationMessage: 'Successfully Routed!',
                                    disabled: false
                                });
                                this.props.closeModal()
                            })
                            .catch(error => {
                                console.log(error);
                                this.setState({
                                    title: "Instant Card Requisition Save Failed!",
                                    cbNotFound: true,
                                    disabled: false
                                })
                            });
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            title: "Case Route Failed!",
                            cbNotFound: true,
                            disabled: false
                        })
                    });
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    disabled: false
                })
            });
    };
    returnHandler = event => {
        event.preventDefault();
        let dependencyField = [
            {
                varName: "bomRemarks",
                type: "textArea",
                required: true
            }
        ];

        let error = MyValidation.defaultValidation(dependencyField, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.functionForRemarksSave();
            this.setState({
                disabled: true
            });
            let url = backEndServerURL + "/variables/" + this.props.appId;
            axios
                .post(url, {bom_approval: "RETURN"}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                    axios
                        .get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then(response => {
                            this.setState({
                                disabled: false,
                                alert: true,
                                title: "Successful!",
                                notificationMessage: "Successfully Routed!"
                            });
                            this.props.closeModal()
                        })
                        .catch(error => {
                            console.log(error);
                            this.setState({
                                title: "Case Route Failed!",
                                cbNotFound: true,
                                disabled: false
                            })
                        });
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        disabled: false
                    })
                });
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, bomRemarks, this.updateComponent)
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px", "margin-top": "10px"}}>
                        <b>Remarks</b>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>

                </div>
            )
        }
    };

    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <Grid container>
                        <ThemeProvider theme={theme}>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            {CommonJsonFormComponent.renderJsonForm(
                                this.state,
                                bomJsonForm,
                                this.updateComponent
                            )}
                            <Grid item xs={12}>
                                {this.renderRemarksData()}
                                <br/>
                                {
                                    this.renderRemarks()
                                }
                            </Grid>
                        </ThemeProvider>
                    </Grid>

                    <Grid item xs="3" style={{marginTop: "10px"}}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                marginRight: "10px",
                            }}
                            disabled={this.state.disabled}
                            onClick={this.approveHandler}
                        >
                            Approve
                        </button>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={this.returnHandler}
                            disabled={this.state.disabled}
                        >
                            Return
                        </button>
                    </Grid>
                    {
                        this.renderNotification()
                    }
                </div>
            );
        }
    }
}

export default withStyles(styles)(BomInbox);
