import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import CsList from "./Table/CsList";
import RmList from "./Table/RmList";

const styles = theme => ({

    '@global': {
        body: {
            backgroundColor: theme.palette.common.red,
        },
    },
    appBar: {
        position: 'relative',

    },
    toolbarTitle: {
        flex: 1,

    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            width: 1500,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },

    cardHeader: {
        backgroundColor: "#f7bfb9"
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing.unit * 2,
    },

    footer: {
        marginTop: theme.spacing.unit * 8,
        borderTop: `1px solid ${theme.palette.divider}`,
        padding: `${theme.spacing.unit * 6}px 0`,
    },

});

const list_of_cs = [
    {
        title: 'List View of CS',

        headertop: 'Details',
        description: ['Total Number Of Cs:6,000'],
        buttonText: 'View ',
        buttonVariant: 'outlined',
    }];
const list_of_rm = [
    {
        title: 'List View of RM',

        headertop: 'Details',
        description: ['Total Number Of Rm:3,0000'],
        buttonText: 'View ',
        buttonVariant: 'outlined',
    }];

class ManagerView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            list_of_cs: false,
            list_of_rm: false,
        }
    }

    render() {
        const {classes} = this.props;

        return (
            <section>


                <React.Fragment>

                    <CssBaseline/>
                    <main className={classes.layout}>
                        <Grid container spacing={40}
                              alignItems="flex-end"
                              style={{minHeight: '10vh'}}>
                            {list_of_cs.map(tier => (

                                <Grid item key={tier.title} sm={tier.title === 'Enterprise' ? 12 : 6} md={6}>
                                    <Card>
                                        <CardHeader
                                            title={tier.title}
                                            subheader={tier.subheader}
                                            titleTypographyProps={{align: 'center'}}
                                            subheaderTypographyProps={{align: 'center'}}
                                            action={tier.title === 'Pro' ? <StarIcon/> : null}
                                            className={classes.cardHeader}
                                        />
                                        <CardContent>
                                            <div className={classes.cardPricing}>
                                                <Typography component="h2" variant="h5" color="textPrimary">
                                                    {tier.headertop}
                                                </Typography>
                                                <Typography variant="h6" color="textSecondary">

                                                </Typography>
                                            </div>
                                            {tier.description.map(line => (
                                                <Typography variant="subtitle1" align="center" key={line}>
                                                    {line}
                                                </Typography>
                                            ))}
                                            <CsList/>
                                        </CardContent>

                                    </Card>
                                </Grid>
                            ))}
                            {list_of_rm.map(tier => (

                                <Grid item key={tier.title} sm={tier.title === 'Enterprise' ? 12 : 6} md={6}>
                                    <Card>
                                        <CardHeader
                                            title={tier.title}
                                            subheader={tier.subheader}
                                            titleTypographyProps={{align: 'center'}}
                                            subheaderTypographyProps={{align: 'center'}}
                                            action={tier.title === 'Pro' ? <StarIcon/> : null}
                                            className={classes.cardHeader}
                                        />
                                        <CardContent>
                                            <div className={classes.cardPricing}>
                                                <Typography component="h2" variant="h5" color="textPrimary">
                                                    {tier.headertop}
                                                </Typography>
                                                <Typography variant="h6" color="textSecondary">

                                                </Typography>
                                            </div>
                                            {tier.description.map(line => (
                                                <Typography variant="subtitle1" align="center" key={line}>
                                                    {line}
                                                </Typography>
                                            ))}
                                            <RmList/>
                                        </CardContent>

                                    </Card>
                                </Grid>
                            ))}
                        </Grid>

                    </main>

                </React.Fragment>

            </section>


        );
    }
}

ManagerView.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ManagerView);