import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../Grid/GridItem.jsx";
import GridContainer from "../../Grid/GridContainer.jsx";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import CommonFunctions from "../REMITENCE/CommonFunctions";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import SelectComponent from "../../JsonForm/SelectComponent";
import Notification from "../../NotificationMessage/Notification";
import {StudentFileJsonForm} from "../WorkflowJsonFormArin";
import TextFieldComponent from "../../JsonForm/TextFieldComponent";
import UploadComponent from "../Maintenance/casa/UploadComponent";
import MyValidation from "../../JsonForm/MyValidation";
import DateComponentDeferal from "../../JsonForm/DateComponentDeferal";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import CBNotFound from "../CASA/CBNotFound";
import SignatureButton from "../Maintenance/SignatureButton";
import FunctionForGetAcDetails from "../CommonComponent/FunctionForGetAcDetails";
import CommonApi from "../Maintenance/common/CommonApi";

const fileIndexList = [
    {label: 'Document 1'},
    {label: 'Document 2'},
    {label: 'Document 3'},
    {label: 'Document 4'},
    {label: 'Document 5'},
    {label: 'Document 6'},
    {label: 'Document 7'},
    {label: 'Document 8'},
    {label: 'Document 9'},
    {label: 'Document 10'},
    {label: 'Document 11 '},
    {label: 'Document 12'},
    {label: 'Document 13 '},
    {label: 'Document 14'},
    {label: 'Document 15'},
    {label: 'Document 16'},
    {label: 'Document 17'},
    {label: 'Document 18'},
    {label: 'Document 19'},
    {label: 'Document 20'},

];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};
let abhMaker = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];
let selectFileType = [
    {
        "varName": "selectFileType",
        "type": "select",
        "enum": [
            "Open",
            "Renew",
            "Update",
            "Close",
        ],
        "label": "Select File TYPE",
        "grid": 2,
    },
];

var deferalOther = {
    "varName": "deferalOther",
    "type": "text",
    "label": "Remarks",

};

var deferal = {
    "varName": "deferalType",
    "type": "select",
    "label": "Deferral Type",
    "enum": [
        "Applicant Photograph",
        "Nominee Photograph",
        "Passport",
        "Address proof",
        "Transaction profile",
        "Others"
    ],

};

var date = {
    "varName": "expireDate",
    "type": "text",
    "minDate": true,
    "label": "Expire Date",
};

var accountNo = {
    "varName": "outwardObBanAcNo",
    "type": "text",
    "label": "Currency Type",
    "required": true
};
var beneficiaryBank = {
    "varName": "beneficiaryBank",
    "type": "text",
    "label": "Course Type",
    "required": true
};
var beneficiaryAcNo = {
    "varName": "beneficiaryAcNo",
    "type": "text",
    "label": "Program Name",
    "required": true
};
var swiftDetails = {
    "varName": "ObswiftDetails",
    "type": "text",
    "label": "Referred by",
    "required": true
};
var obAmmount = {
    "varName": "obAmmount",
    "type": "text",
    "label": "Amount",
};

var accountNoLivingExpence = {
    "varName": "outwardObBanAcNoLivingExpence",
    "type": "text",
    "label": "Account No",
};
var beneficiaryBankLivingExpence = {
    "varName": "beneficiaryBankLivingExpence",
    "type": "text",
    "label": "Beneficiary Bank",
};
var beneficiaryAcNoLivingExpence = {
    "varName": "beneficiaryAcNoLivingExpence",
    "type": "text",
    "label": "Beneficiary Ac No",
};
var swiftDetailsLivingExpence = {
    "varName": "ObswiftDetailsLivingExpence",
    "type": "text",
    "label": "Swift Details",
};
var obAmmountLivingExpence = {
    "varName": "obAmmountLivingExpence",
    "type": "text",
    "label": "Amount",
};

class StudentFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: {},
            appId: 0,
            getCheckerList: [],
            checkerListShow: false,
            fileUploadData: [],
            getMappingAllImage: false,
            err: false,
            errorArray: {},
            objectForTuitionFee: [],
            tuitionFeeValidation: false,
            errorMessages: {},
            uploadModal: false,
            selectType: "",
            title: "",
            inputData: {
                csDeferal: "NO",
                tutionFee: "NO",
                livingExpence: "NO"
            },
            alert: false,
            generateReference: false,
            message: "",
            notificationMessage: "",
            csDeferalPage: "",
            values: [],
            valuesTutionFee: [],
            valuesLivingExpence: [],
            varValue: [],
            loading: true,
            AddDeferal: false,
            getDeferalList: [],
            currentScheme: [],
            savingScheme: [],
            deferalNeeded: false,
            cbNotFound: false,
            accountDataBoolean: false,
            searchDataBoolean: false,

        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    componentDidMount() {
        this.dynamicApiCall("schemeCodeForCurrent", "currentSchemeCode");
        this.dynamicApiCall("schemeCodeForSavings", "savingsSchemeCode");
        let url = backEndServerURL + "/startCase/cs_data_capture";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            let inputData = {};
            inputData.caseId = response.data.caseId;
            let appId = response.data.id;

            let adBranchUrl = backEndServerURL + "/finMasterData/adBranchRemittance";
            axios.get(adBranchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                for (let item of StudentFileJsonForm) {
                    if (item.varName === "adBranch") {
                        item.enum = response.data
                    }
                }

                this.setState({
                    inputData: inputData,
                    varValue: this.copyJson(inputData),
                    appId: appId,
                    getNewCase: true,
                    loading: false,
                    showValue: true,
                    getData: true,
                    loaderNeeded: true
                });
            }).catch((error) => {
                console.log(error);
            });
        })
            .catch((error) => {
                console.log(error);
            })


    }

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    dynamicApiCall = (fieldName, apiType) => {
        let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (apiType === "currentSchemeCode") {
                    this.setState({
                        currentScheme: response.data
                    })
                } else {
                    this.setState({
                        savingScheme: response.data
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            })
    };

    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (value === jsonObject.key) {
                return jsonObject.value;
            }
        }
    };

    accountSearch = (event, data) => {
        event.preventDefault();
        if (data === "accountNumber" && this.state.inputData.source==="FINACLE") {
            this.setState({
                loading: true,
            });
            this.state.inputData.accountNumber = event.target.value;
            this.state.inputData.showSignature = false;
            this.state.inputData.showAccountBalance = false;
            let getAccountUrl = backEndServerURL + "/getAccountInfo/" + this.state.inputData.accountNumber;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.data.message === "No Account Found!") {
                        let inputData = this.state.inputData;
                        inputData.showSignature = false;
                        inputData.showAccountBalance = false;
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                            showValue: true,
                            loading: false,
                            cbNotFound: true,
                            title: 'No Account Found!'
                        });
                    } else {
                        let inputData = this.state.inputData;
                        if (response.data.accountType === "Current") {
                            inputData.schemeCodeDescription = this.findByVarNameGetKeyValue(response.data.schemeCode, this.state.currentScheme);
                        } else {
                            inputData.schemeCodeDescription = this.findByVarNameGetKeyValue(response.data.schemeCode, this.state.savingScheme);
                        }
                        FunctionForGetAcDetails.getAccountInfo(this.state.inputData.accountNumber).then((custResponse) => {
                            inputData.accountBalance = custResponse.data.acctEftvBalance;
                            inputData.showAccountBalance = response.data.schemeCode !== "SBSTF";
                            inputData.name = response.data.customerName;
                            inputData.customerName = response.data.customerName;
                            inputData.accountType = response.data.accountType;
                            inputData.schemeCode = response.data.schemeCode;
                            inputData.showSignature = true;
                            let contactNumber = response.data.contactNumber ? response.data.contactNumber.replace(/\D/g, '') : "";
                            inputData.contactNumber = response.data.contactNumber ? contactNumber.substr(contactNumber.length - 11) : "";
                            inputData.cbNumber = response.data.cbNumber;
                            inputData.solId = response.data.solid;
                            if (response.data.mailingAddress) {
                                inputData.addressOne = response.data.mailingAddress.addrLine1;
                                inputData.addressTwo = response.data.mailingAddress.addrLine2
                            }
                            this.setState({
                                inputData: inputData,
                                varValue: this.copyJson(inputData),
                                showValue: true,
                                loading: false,
                                searchDataBoolean:true
                            });
                        }).catch((error) => {
                            let inputData = this.state.inputData;
                            inputData.accountNumber = "";
                            inputData.showSignature = false;
                            inputData.showAccountBalance = false;
                            this.setState({
                                inputData: inputData,
                                varValue: this.copyJson(inputData),
                                showValue: true,
                                loading: false,
                                cbNotFound: true,
                                title: error.response.data.message
                            });
                        });
                    }

                })
                .catch(error => {
                    let inputData = this.state.inputData;
                    inputData.accountNumber = "";
                    inputData.showSignature = false;
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        showValue: true,
                        loading: false,
                        accountDataBoolean: true,
                    });
                });
        }
        else if (data === "accountNumber" && this.state.inputData.source==="ABABIL") {
            this.setState({
                loading: true,
            });
            this.state.inputData.accountNumber = event.target.value;
            this.state.inputData.showSignature = false;
            this.state.inputData.showAccountBalance = false;
            CommonApi.ababilAccountDetails(event.target.value).then((response) => {
                    if (response.data.message === "No Account Found!") {
                        let inputData = this.state.inputData;
                        inputData.showSignature = false;
                        inputData.showAccountBalance = false;
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                            showValue: true,
                            loading: false,
                            cbNotFound: true,
                            title: 'No Account Found!'
                        });
                    } else {
                        let inputData = this.state.inputData;
                        inputData.schemeCodeDescription = response.data.accDetails;
                        inputData.accountBalance = response.data.availableBalance;
                        inputData.showAccountBalance = response.data.productCode !== "SBSTF";
                        inputData.name = response.data.acctName;
                        inputData.customerName = response.data.accountName;
                        inputData.accountType = response.data.accountType;
                        inputData.schemeCode = response.data.productCode;
                        inputData.showSignature = true;
                        let contactNumber = response.data.conatactNumber ? response.data.conatactNumber.replace(/\D/g, '') : "";
                        inputData.contactNumber = response.data.conatactNumber ? contactNumber.substr(contactNumber.length - 11) : "";
                        inputData.cbNumber = response.data.customerId;
                        inputData.solId = response.data.solId;

                        inputData.addressOne = response.data.addressLineOne;
                        inputData.addressTwo = response.data.addressLineTwo

                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                            showValue: true,
                            loading: false,
                            searchDataBoolean:true
                        });
                    }

                })
                .catch(error => {
                    let inputData = this.state.inputData;
                    inputData.accountNumber = "";
                    inputData.showSignature = false;
                    console.log(error.response.data.message)
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        showValue: true,
                        loading: false,
                        accountDataBoolean: true, //end
                    });
        });
        }
    };

    renderJsonForm = () => {
        if (this.state.inputData.selectFileType) {
            if(this.state.searchDataBoolean){
                return (
                    <React.Fragment>
                        {CommonJsonFormComponent.renderJsonForm(this.state, CommonFunctions.makeReadOnlyObjectForRemittance(StudentFileJsonForm) , this.updateComponent, this.accountSearch, this.onKeyDownChange)}
                    </React.Fragment>)}
            else{
                return (
                    <React.Fragment>
                        {CommonJsonFormComponent.renderJsonForm(this.state, StudentFileJsonForm, this.updateComponent, this.accountSearch, this.onKeyDownChange)}
                    </React.Fragment>
                )
            }
        }
    };

    onKeyDownChange = (data) => {
        if (data.varName === "adBranch" && this.state.inputData.selectFileType === "Open") {
            this.setState({
                loading: true
            });
            let inputData = this.state.inputData;
            this.state.inputData.fileNumberApiCall = false;


            this.forceUpdate();
            let url = backEndServerURL + "/StudentAccountReferenceNumber/" + this.state.inputData.adBranch.key + "/CSF";
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                inputData.transactionParticularReferenceNumber = response.data;
                inputData.studentFileNumber = response.data;
                inputData.fileNumberApiCall = true;
                this.setState({
                    inputData: inputData,
                    varValue: this.copyJson(inputData),
                    loading: false,
                    showValue: true,
                    generateReference: true
                })
            }).catch((error) => {
                console.log(error);
                this.setState({
                    showValue: true,
                    loading: false,
                    title: "Student File Number Generate Failed!",
                    cbNotFound: true
                })
            });
        }
    };

    renderStudentFileType = () => {

        return (
            <React.Fragment>
                {
                    CommonJsonFormComponent.renderJsonForm(this.state, selectFileType, this.updateComponent)
                }
            </React.Fragment>
        )
    };

    makeTuitionFeeFormForOnlyValidation = () => {
        let errorTwo = false;
        if (this.state.inputData.selectFileType !== undefined && this.state.inputData.selectFileType !== null && this.state.valuesTutionFee.length > 0) {
            let objectForTuitionFee = [];
            this.state.valuesTutionFee.map((el, i) => {
                objectForTuitionFee.push(
                    {
                        "varName": "beneficiaryBank" + el,
                        "type": "text",
                        "validation": "string",
                        "required":true,
                    },
                    {
                        "varName": "beneficiaryAcNo" + el,
                        "type": "text",
                        "validation": "numeric",
                        "required":true,
                    },
                    {
                        "varName": "outwardObBanAcNo" + el,
                        "type": "text",
                        "validation": "numeric",
                        "required":true,
                    },
                    {
                        "varName": "obAmmount" + el,
                        "type": "text",
                        "validation": "numeric"
                    },
                    {
                        "varName": "ObswiftDetails" + el,
                        "type": "text",
                        "required": true
                    }
                )
            });
            errorTwo = MyValidation.defaultValidation(objectForTuitionFee, this.state);
            this.forceUpdate();
        }
        return errorTwo;
    };


    makeLivingExpenseFormForOnlyValidation = () => {
        let errorTwo = false;
        if (this.state.inputData.selectFileType !== undefined && this.state.inputData.selectFileType !== null && this.state.valuesLivingExpence.length > 0) {
            let objectLivingExpense = [];
            this.state.valuesLivingExpence.map((el, i) => {
                objectLivingExpense.push(
                    {
                        "varName": "beneficiaryBankLivingExpence" + el,
                        "type": "text",
                        "validation": "string"
                    },
                    {
                        "varName": "beneficiaryAcNoLivingExpence" + el,
                        "type": "text",
                        "validation": "numeric"
                    },
                    {
                        "varName": "outwardObBanAcNoLivingExpence" + el,
                        "type": "text",
                        "validation": "numeric"
                    },
                    {
                        "varName": "obAmmountLivingExpence" + el,
                        "type": "text",
                        "validation": "numeric"
                    }
                )
            });
            errorTwo = MyValidation.defaultValidation(objectLivingExpense, this.state);
            this.forceUpdate()
        }
        return errorTwo;
    };
    generateFinacleAbabilErrorModal=()=>{
        if(this.state.accountDataBoolean){

            this.setState({
                cbNotFound: true,
                title: "Invalid Account Number",
                accountDataBoolean:false,
            });
        }
    }
    handleSubmit = (event) => {
        event.preventDefault();
        let deferralError = MyValidation.deferralValidation(this.state.values, this.state);
        this.forceUpdate();
        let error = MyValidation.defaultValidation(StudentFileJsonForm, this.state);
        let errorTwo = this.makeTuitionFeeFormForOnlyValidation();
        let errorThree = this.makeLivingExpenseFormForOnlyValidation();
        this.forceUpdate();

        if (error === true || deferralError === true || errorTwo === true || errorThree === true) {
            return 0;
        }

        else {
            let inputData = {...this.state.inputData};
            this.setState({
                inputData: inputData,
                varValue: this.copyJson(inputData),
                loading: true,
            });
            let imageUrl = backEndServerURL + "/case/files/" + this.state.appId;
            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.data.length > 0) {
                        if (this.state.values.length > 0) {
                            this.state.inputData["csDeferal"] = "YES";
                        } else {
                            this.state.inputData["csDeferal"] = "NO";
                        }
                        if (this.state.inputData["livingExpence"] === "YES") {
                            var LivingExpenceArray = [];
                            var Type = 'Living Expense';
                            for (let i = 0; i < this.state.valuesLivingExpence.length; i++) {
                                let object = {};
                                let value = this.state.valuesLivingExpence[i];
                                object.beneficiaryBankLivingExpence = this.state.inputData["beneficiaryBankLivingExpence" + value];
                                object.beneficiaryAcNoLivingExpence = this.state.inputData["beneficiaryAcNoLivingExpence" + value];
                                object.AcNoLivingExpence = this.state.inputData["outwardObBanAcNoLivingExpence" + value];
                                object.SwieftDetailsLivingExpence = this.state.inputData["ObswiftDetailsLivingExpence" + value];
                                object.ammountLivingExpence = this.state.inputData["obAmmountLivingExpence" + value];
                                LivingExpenceArray.push(object);
                            }
                            let livingUrl = backEndServerURL + "/remittence";
                            axios.post(livingUrl, {
                                appId: this.state.appId,
                                dataType: Type,
                                mapList: LivingExpenceArray
                            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                                console.log(response.data);
                            }).catch((error) => {
                                console.log(error);
                            })
                        }
                        if (this.state.inputData["tutionFee"] === "YES") {
                            var TutionFeeArray = [];
                            Type = 'Tution Fee';

                            for (let i = 0; i < this.state.valuesTutionFee.length; i++) {
                                let object = {};
                                let value = this.state.valuesTutionFee[i];
                                object.beneficiaryBank = this.state.inputData["beneficiaryBank" + value];
                                object.beneficiaryAcNo = this.state.inputData["beneficiaryAcNo" + value];
                                object.AcNo = this.state.inputData["outwardObBanAcNo" + value];
                                object.SwieftDetails = this.state.inputData["ObswiftDetails" + value];
                                object.ammount = this.state.inputData["obAmmount" + value];
                                TutionFeeArray.push(object);
                            }
                            let tutionUrl = backEndServerURL + "/remittence";
                            axios.post(tutionUrl, {
                                appId: this.state.appId,
                                dataType: Type,
                                mapList: TutionFeeArray
                            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                                console.log(response)
                            }).catch((error) => {
                                console.log(error);
                            })
                        }

                        if (this.state.inputData["csDeferal"] === "YES") {
                            let defType = [];
                            let expDate = [];
                            var deferralData = {};
                            let appId = this.state.appId;
                            for (let i = 0; i < this.state.values.length; i++) {
                                let value = this.state.values[i];
                                let deferalType = this.state.inputData["deferalType" + value];
                                if (deferalType === "other") {
                                    deferalType = this.state.inputData["deferalOther" + value];
                                }
                                defType.push(deferalType);
                                let expireDate = this.state.inputData["expireDate" + value];
                                expDate.push(expireDate);

                            }
                            deferralData.appId = appId;
                            deferralData.type = defType;
                            deferralData.dueDate = expDate
                        }
                        let data = this.state.inputData;
                        data.cs_deferal = this.state.inputData["csDeferal"];
                        data.dueDate = this.state.dueDate;
                        data.type = Type;
                        data.freeFlag3 = this.state.inputData.studentFileNumber;
                        data.category = 'Remittance';
                        data.subCategory = 'Student File';
                        data.freeFlag6 = `Student File - ${this.state.inputData.selectFileType}`;
                        data.customerName = this.state.inputData.name;
                        data.serviceType = "Remetance";
                        data.subServiceType = "Student File";
                        data.accountType = this.state.inputData.source;
                        let remarksData = {};
                        remarksData.remark = this.state.inputData.csRemarks;
                        remarksData.map = {...data, csRemarks: undefined};
                        remarksData.bulkDeferralDTO = deferralData;
                        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
                        axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                            this.setState({
                                title: "Successful!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                loading: false
                            });
                            setTimeout(function () {
                                window.location.reload()
                            }, 500);
                        }).catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false
                            })
                        });
                    } else {
                        let inputData = this.state.inputData;
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                            showValue: true,
                            title: "File upload is mandatory!",
                            cbNotFound: true,
                            loading: false
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
        }
    };

    handleSubmitDraft = (event) => {
        event.preventDefault();
        let error = MyValidation.deferralValidation(this.state.values, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true
            });
            if (this.state.inputData["livingExpence"] === "YES") {
                ///// Living Expance ////
                var LivingExpenceArray = [];
                var Type = 'Living Expense';
                for (let i = 0; i < this.state.valuesLivingExpence.length; i++) {
                    let object = {};
                    let value = this.state.valuesLivingExpence[i];
                    object.beneficiaryBankLivingExpence = this.state.inputData["beneficiaryBankLivingExpence" + value];
                    object.beneficiaryAcNoLivingExpence = this.state.inputData["beneficiaryAcNoLivingExpence" + value];
                    object.AcNoLivingExpence = this.state.inputData["outwardObBanAcNoLivingExpence" + value];
                    object.SwieftDetailsLivingExpence = this.state.inputData["ObswiftDetailsLivingExpence" + value];
                    object.ammountLivingExpence = this.state.inputData["obAmmountLivingExpence" + value];
                    LivingExpenceArray.push(object);
                }
                var remetanceUrl = backEndServerURL + "/remittence";
                axios.post(remetanceUrl, {
                    appId: this.state.appId,
                    dataType: Type,
                    mapList: LivingExpenceArray
                }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    console.log('api');
                    console.log(response.data);
                }).catch((error) => {
                    console.log(error);
                })

                ///// Tution Feee ////
            }
            if (this.state.inputData["tutionFee"] === "YES") {
                var TutionFeeArray = [];
                Type = 'Tution Fee';

                for (let i = 0; i < this.state.valuesTutionFee.length; i++) {
                    let object = {};
                    let value = this.state.valuesTutionFee[i];
                    object.beneficiaryBank = this.state.inputData["beneficiaryBank" + value];
                    object.beneficiaryAcNo = this.state.inputData["beneficiaryAcNo" + value];
                    object.AcNo = this.state.inputData["outwardObBanAcNo" + value];
                    object.SwieftDetails = this.state.inputData["ObswiftDetails" + value];
                    object.ammount = this.state.inputData["obAmmount" + value];
                    TutionFeeArray.push(object);
                }

                axios.post(remetanceUrl, {
                    appId: this.state.appId,
                    dataType: Type,
                    mapList: TutionFeeArray
                }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    console.log('api');
                    console.log(response.data);
                }).catch((error) => {
                    console.log(error);
                })
            }

            if (this.state.inputData["csDeferal"] === "YES") {
                var defType = [];
                var expDate = [];
                var remarks = [];

                let appId = this.state.appId;
                for (let i = 0; i < this.state.values.length; i++) {
                    let value = this.state.values[i];
                    let defferalType = this.state.inputData["deferalType" + value];
                    let remarksData = this.state.inputData["deferalOther" + value];
                    remarks.push(remarksData);
                    defType.push(defferalType);
                    let expireDate = this.state.inputData["expireDate" + value];
                    expDate.push(expireDate);

                    console.log(expDate)
                }

                let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";
                axios.post(deferalRaisedUrl, {
                    appId: appId,
                    type: defType,
                    dueDate: expDate,
                    remarks: remarks
                }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    console.log(response.data);
                })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            var variableSetUrl = backEndServerURL + "/save/" + this.state.appId;

            let data = this.state.inputData;
            data.cs_deferal = this.state.inputData["csDeferal"];
            data.category = 'Remittance';
            data.subCategory = 'Student File';
            data.freeFlag3 = this.state.inputData.studentFileNumber;
            data.freeFlag6 = `Student File - ${this.state.inputData.selectFileType}`;
            data.serviceType = "Remetance";
            data.subServiceType = "Student File";

            axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Save!",
                        alert: true
                    });
                    setTimeout(function () {
                        window.location.reload()
                    }, 1000);
                    this.setState({
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
        }
    };

    addClick() {
        let randomNumber = Math.floor(Math.random() * 100000000000);
        this.setState(prevState => ({
            values: [...prevState.values, randomNumber],
        }));
        this.state.inputData["csDeferal"] = "YES";
    }

    addClickTutionFee() {
        let randomNumber = Math.floor(Math.random() * 100000000000);
        this.setState(prevState => ({
            valuesTutionFee: [...prevState.valuesTutionFee, randomNumber],
        }));
        this.state.inputData["tutionFee"] = "YES";
    }

    addClickLivingExpence() {
        let randomNumber = Math.floor(Math.random() * 100000000000);
        this.setState(prevState => ({
            valuesLivingExpence: [...prevState.valuesLivingExpence, randomNumber],
        }));
        this.state.inputData["livingExpence"] = "YES";
    }

    removeClickTutionFee(i, event) {
        event.preventDefault();
        let pos = this.state.valuesTutionFee.indexOf(i);
        if (pos > -1) {
            this.state.valuesTutionFee.splice(pos, 1);
            this.updateComponent();

        }
    }

    removeClickLivingExpence(i, event) {
        event.preventDefault();
        let pos = this.state.valuesLivingExpence.indexOf(i);
        if (pos > -1) {
            this.state.valuesLivingExpence.splice(pos, 1);
            this.updateComponent();
        }
    }

    removeClick(i, event) {
        event.preventDefault();
        let pos = this.state.values.indexOf(i);
        if (pos > -1) {
            this.state.values.splice(pos, 1);
            this.updateComponent();
            if (this.state.values.length > 0) {
                this.state.inputData["csDeferal"] = "YES"
            } else {
                this.state.inputData["csDeferal"] = "NO"
            }
        }
    }

    renderAddButtonShowTutionFee = () => {
        if (this.state.inputData.selectFileType !== undefined && this.state.inputData.selectFileType !== null) {

            return (
                <Grid item xs={12}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            float: 'left',
                            verticalAlign: 'left',

                        }}
                        type='button' value='add more'
                        onClick={this.addClickTutionFee.bind(this)}
                    >Tuition Fee
                    </button>
                </Grid>
            )
        }

    };
    renderAddButtonLivingExpacne = () => {
        if (this.state.inputData.selectFileType !== undefined && this.state.inputData.selectFileType !== null) {

            return (
                <Grid item xs={12}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            float: 'left',
                            verticalAlign: 'left',

                        }}
                        type='button' value='add more'
                        onClick={this.addClickLivingExpence.bind(this)}
                    >Living Expense
                    </button>
                </Grid>
            )
        }
    };
    renderAddButtonShow = () => {
        if (this.state.inputData.selectFileType !== undefined && this.state.inputData.selectFileType !== null) {

            return (
                <Grid item xs={12}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            float: 'left',
                            verticalAlign: 'left',
                        }}
                        type='button' value='add more'
                        onClick={this.addClick.bind(this)}
                    >Add Deferral
                    </button>
                </Grid>
            )
        }
    };

    addDeferalForm() {
        if (this.state.inputData.selectFileType !== undefined && this.state.inputData.selectFileType !== null) {

            return this.state.values.map((el, i) =>
                <React.Fragment>
                    <Grid xs='12'>
                    </Grid>
                    <Grid item xs={3}>
                        {
                            this.dynamicDeferral(el)
                        }
                    </Grid>
                    <Grid item xs={3}>
                        {
                            this.dynamicDate(el)
                        }
                    </Grid>
                    <Grid item xs={3}>
                        {this.dynamicDeferralOther(el)}
                    </Grid>
                    <Grid item xs={1.5}>
                        <button
                            style={{float: "right", marginTop: "15px"}}
                            className="btn btn-outline-primary btn-sm"
                            type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                        >
                            Remove
                        </button>
                    </Grid>
                </React.Fragment>
            )
        }
    }

    addDeferalFormTutionFee() {
        if (this.state.inputData.selectFileType !== undefined && this.state.inputData.selectFileType !== null) {


            return this.state.valuesTutionFee.map((el, i) =>

                <React.Fragment>
                    <Grid xs='12'>
                    </Grid>
                    <Grid item xs={2}>
                        {this.benificiaryBank(el)}
                    </Grid>
                    <Grid item xs={2}>
                        {this.benificiaryAcNo(el)}
                    </Grid>
                    <Grid item xs={2}>
                        {this.accountNo(el)}
                    </Grid>
                    <Grid item xs={2}>
                        {this.swiftDetails(el)}
                    </Grid>
                    <Grid item xs={2}>
                        {this.ammount(el)}
                    </Grid>
                    <Grid item xs={1.5}>
                        <button
                            style={{float: "right", marginTop: '15px'}}
                            className="btn btn-outline-primary btn-sm"
                            type='button' value='remove' onClick={this.removeClickTutionFee.bind(this, el)}
                        >
                            Remove
                        </button>
                    </Grid>
                </React.Fragment>
            )
        }
    }

    addDeferalFormLivingExpence() {
        if (this.state.inputData.selectFileType !== undefined && this.state.inputData.selectFileType !== null) {

            return this.state.valuesLivingExpence.map((el, i) =>

                <React.Fragment>
                    <Grid xs='12'>
                    </Grid>
                    <Grid item xs={2}>
                        {this.benificiaryBankLivingExpence(el)}
                    </Grid>
                    <Grid item xs={2}>
                        {this.benificiaryAcNoLivingExpence(el)}
                    </Grid>
                    <Grid item xs={2}>
                        {this.accountNoLivingExpence(el)}
                    </Grid>
                    <Grid item xs={2}>
                        {this.swiftDetailsLivingExpence(el)}
                    </Grid>
                    <Grid item xs={2}>
                        {this.ammountLivingExpence(el)}
                    </Grid>
                    <Grid item xs={1.5}>
                        <button
                            style={{float: "right", marginTop: "15px"}}
                            className="btn btn-outline-primary btn-sm"
                            type='button' value='remove' onClick={this.removeClickLivingExpence.bind(this, el)}
                        >
                            Remove
                        </button>
                    </Grid>
                </React.Fragment>
            )
        }
    }

    dynamicDeferral = (i) => {
        let field = JSON.parse(JSON.stringify(deferal));
        field.varName = "deferalType" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };

    dynamicDeferralOther = (i) => {
        if (this.state.inputData["deferalType" + i] === "Others") {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        } else {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        }
    };
    dynamicDate = (i) => {
        let field = JSON.parse(JSON.stringify(date));
        field.varName = "expireDate" + i;
        return DateComponentDeferal.date(this.state, this.updateComponent, field);
    };


    benificiaryBank = (i) => {
        let field = JSON.parse(JSON.stringify(beneficiaryBank));
        field.varName = "beneficiaryBank" + i;
        return TextFieldComponent.text(this.state, this.updateComponent, field);
    };
    benificiaryBankLivingExpence = (i) => {
        let field = JSON.parse(JSON.stringify(beneficiaryBankLivingExpence));
        field.varName = "beneficiaryBankLivingExpence" + i;
        return TextFieldComponent.text(this.state, this.updateComponent, field);
    };

    benificiaryAcNo = (i) => {
        let field = JSON.parse(JSON.stringify(beneficiaryAcNo));
        field.varName = "beneficiaryAcNo" + i;
        return TextFieldComponent.text(this.state, this.updateComponent, field);
    };
    benificiaryAcNoLivingExpence = (i) => {
        let field = JSON.parse(JSON.stringify(beneficiaryAcNoLivingExpence));
        field.varName = "beneficiaryAcNoLivingExpence" + i;
        return TextFieldComponent.text(this.state, this.updateComponent, field);
    };

    accountNo = (i) => {
        let field = JSON.parse(JSON.stringify(accountNo));
        field.varName = "outwardObBanAcNo" + i;
        return TextFieldComponent.text(this.state, this.updateComponent, field);
    };
    accountNoLivingExpence = (i) => {
        let field = JSON.parse(JSON.stringify(accountNoLivingExpence));
        field.varName = "outwardObBanAcNoLivingExpence" + i;
        return TextFieldComponent.text(this.state, this.updateComponent, field);
    };

    swiftDetails = (i) => {
        let field = JSON.parse(JSON.stringify(swiftDetails));
        field.varName = "ObswiftDetails" + i;
        return TextFieldComponent.text(this.state, this.updateComponent, field);
    };
    swiftDetailsLivingExpence = (i) => {
        let field = JSON.parse(JSON.stringify(swiftDetailsLivingExpence));
        field.varName = "ObswiftDetailsLivingExpence" + i;
        return TextFieldComponent.text(this.state, this.updateComponent, field);
    };

    ammount = (i) => {
        let field = JSON.parse(JSON.stringify(obAmmount));
        field.varName = "obAmmount" + i;
        return TextFieldComponent.text(this.state, this.updateComponent, field);
    };
    ammountLivingExpence = (i) => {
        let field = JSON.parse(JSON.stringify(obAmmountLivingExpence));
        field.varName = "obAmmountLivingExpence" + i;
        return TextFieldComponent.text(this.state, this.updateComponent, field);
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };
    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    uploadFunction = () => {
        const {classes} = this.props;
        if (this.state.inputData.selectFileType !== undefined && this.state.inputData.selectFileType !== null) {

            return <Grid item xs={12}><UploadComponent indexList={fileIndexList} appId={this.state.appId}
                                                       classes={classes}/></Grid>
        }
    };


    renderRemarks = () => {

        if (this.state.inputData.selectFileType !== undefined && this.state.inputData.selectFileType !== null) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, abhMaker, this.updateComponent)
            )
        }

    };

    renderSubmitButon = () => {
        if (this.state.inputData.selectFileType !== undefined && this.state.inputData.selectFileType !== null) {
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{}}
                    onClick={this.handleSubmit}
                >
                    Submit
                </button>
            )

        }
    };

    renderSaveButton = () => {
        if (this.state.inputData.selectFileType !== undefined && this.state.inputData.selectFileType !== null) {

            return (
                <button
                    className="btn btn-outline-info btn-sm"
                    style={{}}
                    onClick={this.handleSubmitDraft}
                >
                    Save
                </button>
            )
        }

    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    signature = () => {
        if (this.state.inputData.showSignature) {
            return <Grid item xs={12}><SignatureButton accountNumber={this.state.inputData.accountNumber}
                                                       signatureType="multiple"
                                                       classes={this.props}/></Grid>;
        }
    };

    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >Remittance Service Student File</h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <ThemeProvider theme={theme}>
                                        <Grid container spacing={1}>
                                            {this.renderNotification()}
                                            {this.renderStudentFileType()}
                                            {this.renderJsonForm()}
                                            {this.generateFinacleAbabilErrorModal()}
                                            {this.renderAddButtonShowTutionFee()}
                                            {this.addDeferalFormTutionFee()}
                                            {this.renderAddButtonLivingExpacne()}
                                            {this.addDeferalFormLivingExpence()}
                                            {this.signature()}
                                            {this.renderAddButtonShow()}
                                            {this.addDeferalForm()}
                                            {this.uploadFunction()}
                                            <Dialog
                                                fullWidth="true"
                                                maxWidth="sm"
                                                className={classes.modal}
                                                classes={{paper: classes.dialogPaper}}
                                                open={this.state.cbNotFound}>
                                                <DialogContent className={classes.dialogPaper}>
                                                    <CBNotFound
                                                        closeModal={this.closeModalCBNotFound}
                                                        title={this.state.title}
                                                    />
                                                </DialogContent>
                                            </Dialog>
                                            {this.renderRemarks()}
                                            <Grid item xs={12}>
                                                {this.renderSubmitButon()}
                                                &nbsp;
                                                {this.renderSaveButton()}
                                            </Grid>
                                        </Grid>
                                    </ThemeProvider>
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }
    }
}

export default withStyles(styles)(StudentFile);