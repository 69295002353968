import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../../Static/css/RelationShipView.css";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Table from "../../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CloseIcon from '@material-ui/icons/Close';
import Notification from "../../../NotificationMessage/Notification";
import loader from "../../../../Static/loader.gif";
import DynamicFileAttachment from "../Common/DynamicFileAttachment";
import {nocMakerLoanClosingJsonForm} from "./JsonFormLoanClosing";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";
import MyValidation from "../../../JsonForm/MyValidation";
import CardBody from "../../../Card/CardBody";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";
import CBNotFound from "../../CASA/CBNotFound";
import CommonApi from "../../Maintenance/common/CommonApi";
import Label from "../Common/Label";
import functionForFileDownload from "../../../DeliverableManagement/FunctionForFileDownload";


const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

let nocRemarks = [
    {
        "varName": "nocRemarks",
        "type": "textArea",
        "label": "NOC Remarks",
        "grid": 12
    }]
;


class NocMakerLoanClosing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passingData: '',
            loading: true,
            inputData: {},
            getImageLink: [],
            getImageBoolean: false,
            appId: '',
            getData: false,
            varValue: {},
            caseId: "",
            title: "",
            notificationMessage: "",
            alert: false,
            type: [],
            fileUploadData: [],
            selectedDate: {},
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            cbNotFound: false,
            securityDetails:[],

        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    removeNullValue = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "Raised By", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />
                    <br/>
                </div>
            )
        }
    };

    renderFlowSUmmeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                   caseId={this.props.caseId}
                                   customerName={this.state.inputData.customerName}
                                   accountNumber={this.state.inputData.accountNumber}
                                   serviceType={this.props.serviceType}
                                   category={this.state.inputData.category}
                                   subCategory={this.state.inputData.subCategory}
                                   solId={this.props.solId}/>
            )
        }
    };

    componentDidMount() {
        if (this.props.appId !== undefined) {
            CommonApi.getVariablesNew(this.props.appId, assetOpsBackEndServerURL).then((response) => {
                let inputData = {...response.data};
                inputData.nocRemarks = undefined;
                CommonApi.getFilesNew(this.props.appId, assetOpsBackEndServerURL).then((fileResponse) => {
                    CommonApi.getRemarkDetailsNew(this.props.appId, assetOpsBackEndServerURL).then((remarksArray) => {
                        this.setState({
                            getRemarks: remarksArray,
                            getImageLink: fileResponse,
                            getImageBoolean: true,
                            getData: true,
                            showValue: true,
                            varValue: this.removeNullValue(inputData),
                            inputData: this.removeNullValue(inputData),
                            appId: this.props.appId,
                            appData: response.data,
                            loading: false
                        })
                    }).catch((error) => {
                        console.log(error);
                        this.setState({
                            cbNotFound: true,
                            title: error.response.data.message,
                            loading: false
                        })
                    });
                }).catch((error) => {
                    console.log(error);
                    this.setState({
                        cbNotFound: true,
                        title: error.response.data.message,
                        loading: false
                    })
                });
            }).catch((error) => {
                console.log(error);
                this.setState({
                    cbNotFound: true,
                    title: error.response.data.message,
                    loading: false
                })
            });
        }
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = nocMakerLoanClosingJsonForm;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                nocMakerLoanClosingJsonForm[i].enum = getValue;
            }
        }
    };

    onBlurFunctionForApiCall = (event, varName, secondVarName) => {
        event.preventDefault();
    };

    onKeyDownChange = (data) => {

        if(data.varName==="chequeStatus"){
            let sd=[];
            let inputData = {...this.state.inputData};
            inputData.engineNo=undefined;
            inputData.chassisNo=undefined;
            inputData.registrationNo=undefined;
            inputData.brtaAuthority=undefined;
            inputData.bankNamePdc=undefined;
            inputData.bankNameUdc=undefined;
            inputData.pdcNo=undefined;
            inputData.udcNo=undefined;

            inputData.nocIssueDate=undefined;
            inputData.customerTitle=undefined;
            inputData.ownerName=undefined;
            inputData.businessName=undefined;
            inputData.proprietorName=undefined;
            inputData.chequeNO=undefined;
            inputData.numOfSecurityDetails=undefined;

            this.setState({
                securityDetails: sd,
                inputData: inputData,
                varValue: this.emptyValueRemove(inputData),
            });}
        if(data.varName==="numOfSecurityDetails"){
            let sd=[];
            this.setState({
                loading: true,
                securityDetails: sd,
            });
            this.forceUpdate();
            for (let i = 1; i <= parseInt(this.state.inputData.numOfSecurityDetails); i++) {
                sd.push(
                    {
                        varName: "securityDetails"+ i,
                        type: "text",
                        label: "Security Details"+ i,
                        required: true,
                        grid: 4,
                        "conditional": true,
                        "conditionalVarName":"chequeStatus",
                        "conditionalVarValue": "With Security Details",
                    }
                )
            }
            this.forceUpdate();
            this.setState({
                loading: false,
                securityDetails: sd,
            });
        }
        if(data.varName === "nocCheckerId"){
            let inputData = {...this.state.inputData};
            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                loading: true,
            });
            let url = assetOpsBackEndServerURL + "/user/get/" + this.state.inputData.nocCheckerId;
            console.log(url);
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    inputData.checkerUserName = this.castToString(response.data.firstName) +" "+ this.castToString(response.data.lastName);
                    inputData.checkerUserRole = response.data.userRoleName;
                    this.setState({
                        inputData: inputData,
                        varValue: this.removeNullValue(inputData),
                        loading: false,
                    });
                })
                .catch((error)=>{
                    console.log(error);
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: "Invalid User ID",
                    })
                })
        }

    };
    castToString = (str) => {
        let newStr = JSON.stringify(str);
        if (newStr === '' || newStr === "null" || newStr === "undefined" )
            return "";
        return str;
    };
    renderForm = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, nocMakerLoanClosingJsonForm.concat(this.state.securityDetails)
                    , this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)
            )
        }
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    renderDynamicFileUpload = () => {
        if (!this.state.loading && this.state.getData) {
            return <DynamicFileAttachment appId={this.props.appId}
                                          submitButtonClick={this.state.submitButtonClick}
                                          functionFoFile={this.functionFoFile}/>
        }
    };


    functionFoFile = (status) => {
        this.setState({
            submitButtonClick: false,
            loading: true
        });
        if (status === "success" || status === "No File Found") {
            let saveDataDeferralRemarksAndRoute = {};
            if (this.state.inputData.nocRemarks !== undefined && this.state.inputData.nocRemarks !== null) {
                saveDataDeferralRemarksAndRoute.remark = this.state.inputData.nocRemarks;
            }
            this.state.inputData.noc_maker_approved = this.state.passingData;
            saveDataDeferralRemarksAndRoute.map = {...this.state.inputData};
            let url = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
            axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        title: "Successful!",
                        notificationMessage: response.data,
                        alert: true,
                        loading: false
                    });
                    this.props.closeModal();
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })

                });
        } else {
            this.setState({
                cbNotFound: true,
                loading: false,
                title: "Failed to Upload File."
            })
        }
    };

    closeConfirmAlert = (value) => {

        this.setState({
            confirmAlert: false,
        });
        if (value === "ok" && this.state.actionType === "handleSubmit") {
            this.handleSubmit("YES")
        } else if (value === "ok" && this.state.actionType === "handleReject") {
            this.handleSubmit("NO")
        }
    };

    emptyValueRemove = (jsonObject) => {
        return JSON.parse(JSON.stringify(jsonObject));
    };

    validationForHandleConfirm = (actionType) => {
        this.setState({
            appId: this.props.appId,
            confirmAlert: false,
        });
        if (actionType === "handleSubmit") {
            let error = MyValidation.defaultValidation(this.emptyValueRemove(nocMakerLoanClosingJsonForm), this.state);
            let errorRemarks = MyValidation.defaultValidation(nocRemarks, this.state);

            this.forceUpdate();
            if (error === true || errorRemarks===true) {
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,
                title: "Do you want tot confirm?"
            })
        }
    };

    handleCommon = (event, type) => {
        event.preventDefault();
        this.validationForHandleConfirm(type)
    };

    handleSubmitButton = () => {
        return (
            <div>
                <Grid>
                    <div>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                            }}
                            type='button' value='add more'
                            onClick={(event) => this.handleCommon(event, "handleSubmit")}
                        >Submit
                        </button>

                    </div>
                </Grid>
            </div>
        )
    };

    handleSubmit = (data) => {
        this.setState({
            passingData: data,
            submitButtonClick: true
        });
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, nocRemarks, this.updateComponent)
            )
        }
    };
    dateModifier=(date)=>{
        if(date!==undefined && date!=null){
            let splittedDate = (date).split("/");
            return`${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`;
        }
    }

    renderFormTable = () => {
        return (
            <div style={{padding: "10px 5px 20px", width: '100%'}}>
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <table className="assetTable" style={{borderCollapse: 'collapse', "width": "100%"}}>
                        <tr>
                            <th scope="row">Product Name</th>
                            <td>{this.state.inputData.productName}</td>
                            <th scope="row">Loan Amount</th>
                            <td>{this.state.inputData.loanAmt}</td>
                        </tr>
                        <tr>
                            <th scope="row">Scheme Code</th>
                            <td>{this.state.inputData.schemeCode}</td>
                            <th scope="row">Pay Of Balance</th>
                            <td>{this.state.inputData.loanAccountBalance}</td>
                        </tr>
                        <tr>
                            <th scope="row">Date of Disbursement</th>
                            <td>{this.dateModifier(this.state.inputData.disbursementDate)}</td>
                            <th scope="row">Early Settlement Fee</th>
                            <td>{this.state.inputData.earlySettlementFee}</td>
                        </tr>
                        <tr>
                            <th scope="row">Loan Expiry Date</th>
                            <td>{this.dateModifier(this.state.inputData.loanAccountExpiryDate)}</td>
                            <th scope="row">VAT</th>
                            <td>{this.state.inputData.vat}</td>
                        </tr>
                        <tr>
                            <th scope="row">Maturity Status</th>
                            <td>{this.state.inputData.maturityStatus}</td>
                            <th scope="row">Excise Duty</th>
                            <td>{this.state.inputData.exciseDuty}</td>
                        </tr>

                        <tr>
                            <th scope="row">Total Settlement Amount</th>
                            <td>{this.state.inputData.totalAmount}</td>
                            <th scope="row">CASA BALANCE</th>
                            <td>{this.state.inputData.casaBalance}</td>
                        </tr>

                    </table>
                </div>
            </div>
        )
    };


    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <Button target={"_blank"}
                                href={assetOpsBackEndServerURL + "/file/" + data + '/' + sessionStorage.getItem("accessToken")}
                                style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    borderRadius: 3,
                                    border: 1,
                                    textDecoration: "none"
                                }}
                                target={"_blank"}>{splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </Button>&nbsp;&nbsp;
                    </React.Fragment>
                )
            })
        }
    };

    close = () => {
        this.props.closeModal();
    };

    renderHeader = () => {

        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b>{this.props.serviceType}</b>
                    <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber || ""}
                        <b> CB Number:</b>{this.state.inputData.cbNumber}
                        <b> SOLID:</b>{this.props.solId} <b> Case
                            ID:</b> {this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                            onClick={this.close}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    NOC Maker{this.props.appId !== undefined ?
                    <a><CloseIcon onClick={this.close} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a> : ""}</h4>
            )
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    stopLoading = (value) => {
        if (value === "yes") {
            this.setState({
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    renderCustomerDetails = () => {
        if (this.state.inputData.showCasaDetails === "YES") {
            return (
                <>
                    <Label accountNumber={this.state.inputData.casaAccount} stopLoading={this.stopLoading}
                           classes={this.props}/>
                    <Grid item xs={12}><br/></Grid>
                </>
            )
        }
    };
    handleReport = () => {
        if (this.state.getData && this.state.loading === false  && this.state.inputData.productName=="AUTO LOAN") {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={this.nocLetter}
                    >
                        Print Noc Letter
                    </button>
                    &nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={this.hypothicationLetter}
                    >
                        Print Hypothication Letter
                    </button>
                </div>
            )
        }
        else if (this.state.getData && this.state.loading === false ) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={this.nocLetter}
                    >
                        Print Noc Letter
                    </button>

                </div>
            )
        }

    };
    nocLetter = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
        });
        let inputData = this.state.inputData;
        let url = assetOpsBackEndServerURL + "/getNocLetter";
        functionForFileDownload.downloadFile(url, "POST", inputData, "NOC_Letter.pdf").then((response) => {
            this.setState({
                loading: false
            })
        }).catch((error) => {
            this.setState({
                loading: false,
                title: "Something Went Wrong!",
                cbNotFound: true,
            })
        })


    }
    hypothicationLetter = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
        });
        let inputData = this.state.inputData;
        let url = assetOpsBackEndServerURL + "/getHypothicationLetter";
        functionForFileDownload.downloadFile(url, "POST", inputData, "Hypothication_Letter.pdf").then((response) => {
            this.setState({
                loading: false
            })
        }).catch((error) => {
            this.setState({
                loading: false,
                title: "Something Went Wrong!",
                cbNotFound: true,
            })
        })


    }



    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderHeader()}
                </CardHeader>
                <CardBody>
                    <ThemeProvider theme={theme}>
                        <Grid container spacing={1}>
                            {this.renderNotification()}
                            {this.renderCustomerDetails()}
                            {this.renderForm()}
                            {this.renderDynamicFileUpload()}
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.loading}>
                                <DialogContent className={classes.dialogPaper}>
                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.confirmAlert}>
                                <DialogContent className={classes.dialogPaper}>
                                    <ConfirmationModal
                                        closeModal={this.closeConfirmAlert}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            {this.state.getImageBoolean && <Grid item xs={12}></Grid>}
                            {this.mappingPhoto()}
                            {this.state.getImageBoolean && <Grid item xs={12}></Grid>}
                            <Grid item xs={12}></Grid>
                            {this.renderFormTable()}
                        </Grid>
                        <br/>
                        {this.renderRemarksData()}
                        <Grid item xs={12}><br/></Grid>
                        {this.renderFlowSUmmeryButton()}
                        {this.renderRemarks()}

                        <Grid item xs={12}> {this.handleReport()}</Grid>
                        <Grid item xs={12}><br/></Grid>
                        {this.handleSubmitButton()}
                    </ThemeProvider>
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(styles)(NocMakerLoanClosing);
