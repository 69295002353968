import React, {useEffect, useState} from "react";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import CBNotFound from "../../CASA/CBNotFound";
import Grid from "@material-ui/core/Grid";
import loader from "../../../../Static/loader.gif";
import Notification from "../../../NotificationMessage/Notification";
import theme from "../../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import CommonApi from "../../Maintenance/common/CommonApi";
import GridList from "@material-ui/core/GridList";
import Table from "../../../Table/Table";
import MyValidation from "../../../JsonForm/MyValidation";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import {bearerJsonForBOM} from "./JsonForCustomerStatusUpdate";

let bomRemarks = [
    {
        "varName": "bomRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];


const BomInboxView = (props) => {
    const [stateValue, setStateValue] = useState({
        showSearchField: true,
        inputData: {},
        varValue: {},
        showValue: false,
        showRequisitionField: false,
        loading: true,
        title: "",
        notificationMessage: "",
        cbNotFound: false,
        disabled: false,
        getData: false,
        SdnForFundTransfer: false,
        errorArray: {},
        errorMessages: {},
        getDeferralList: [],
        getRemarks: [],
        appId: 0
    });


    useEffect(() => {
        setStateValue((prevState => {
            return {
                ...prevState,
                getData: true
            }
        }))
    }, []);


    const updateComponent = () => {

    };


    const resetState = (title, bool, showValue, inputData, loading, showRequisitionField) => {
        setStateValue((prevState => {
            return {
                ...prevState,
                inputData: inputData || stateValue.inputData,
                varValue: inputData || stateValue.inputData,
                showValue: showValue || stateValue.showValue,
                getData: true,
                showRequisitionField: showRequisitionField,
                loading: loading || false,
                disabled: false,
                title: title,
                cbNotFound: bool,
            }
        }));
    };

    const renderNotification = () => {
        if (stateValue.alert) {
            return (
                <Notification type="success" stopNotification={stopNotification} title={stateValue.title}
                              message={stateValue.notificationMessage}/>
            )
        }
    };


    const stopNotification = () => {
        setStateValue((prevState => {
            return {
                ...prevState,
                alert: false,
            }
        }));
    };

    useEffect(() => {
        CommonApi.getRemarkDetails(props.appId)
            .then((remarksArray) => {
                setStateValue((prevState => {
                    return {
                        ...prevState,
                        getRemarks: remarksArray
                    }
                }));
            })
            .catch((error) => {
                console.log(error)
            });

        CommonApi.getDeferralDetails(props.appId)
            .then((tableArray) => {
                CommonApi.getFiles(props.appId).then((fileData) => {
                    setStateValue((prevState => {
                        return {
                            ...prevState,
                            getDeferralList: tableArray,
                        }
                    }));
                })
            })
            .catch((error) => {
                console.log(error);
            });
        CommonApi.getVariables(props.appId)
            .then((response) => {
                let inputData = {...response.data};
                setStateValue((prevState => {
                    return {
                        ...prevState,
                        inputData: inputData,
                        varValue: inputData,
                        showValue: true,
                        getData: true,
                        showRequisitionField: true,
                        loading: false
                    }
                }));
            })
            .catch((error) => {
                errorModal(error)
            })
    }, [props.appId]);

    const errorModal = (error) => {
        setStateValue((prevState => {
            return {
                ...prevState,
                cbNotFound: true,
                title: error.response.data.message,
                loading: false,
            }
        }));
    };

    const handleSubmit = (data) => {
        setStateValue((prevState => {
            return {
                ...prevState,
                loading: true,
            }
        }));
        if (data === "RETURN") {
            let dependencyField = [
                {varName: "bomRemarks", type: "textArea", required: true}
            ];
            let error = MyValidation.defaultValidation(dependencyField, stateValue);
            if (error === true) {
                setStateValue((prevState => {
                    return {
                        ...prevState,
                        loading: false,
                    }
                }));
                return 0;
            }
        }
        let postData = {...stateValue.inputData};
        postData.bom_maintenance_approval = data;
        let remarksData = {};
        remarksData.remark = postData.bomRemarks;
        remarksData.map = {...postData, bomRemarks: undefined};
        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + props.appId;
        axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            setStateValue((prevState => {
                return {
                    ...prevState,
                    disabled: false,
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                    loading: false
                }
            }));
            props.closeModal()
        }).catch((error) => {
            console.log(error);
            errorModal(error)
        });
    };

    const renderRequisitionField = () => {
        if (stateValue.getData && stateValue.showRequisitionField) {
            return <>
                {CommonJsonFormComponent.renderJsonForm(stateValue, bearerJsonForBOM, updateComponent)}
                <Grid item xs={12}>
                    {renderDeferralData()}
                </Grid>
                <Grid item xs={12}>
                    {renderRemarksData()}
                </Grid>
                <Grid item
                      xs={12}>{CommonJsonFormComponent.renderJsonForm(stateValue, bomRemarks, updateComponent)}
                </Grid>
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: '15px',
                    }}
                    onClick={() => handleSubmit("APPROVED")}
                    type="submit"
                >
                    Approve
                </button>
                &nbsp;
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: '15px',
                    }}
                    onClick={() => handleSubmit("RETURN")}
                    type="submit"
                >
                    Return
                </button>
            </>
        }
    };

    const renderRemarksData = () => {
        if (stateValue.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks</b>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={stateValue.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    const renderDeferralData = () => {
        if (stateValue.getDeferralList.length > 0) {
            return (
                <>
                    <br/>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Deferral List:</b>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                                tableData={stateValue.getDeferralList}
                                tableAllign={['left', 'left']}
                            />
                        </div>
                    </div>
                </>
            )
        }
    };

    return (
        <GridList cellHeight={window.innerHeight} cols={1}>
            <div>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    open={stateValue.cbNotFound}>
                    <DialogContent>
                        <CBNotFound
                            closeModal={() => resetState(undefined, false, true, stateValue.inputData, false, true)}
                            title={stateValue.title}/>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    open={stateValue.loading}>
                    <DialogContent>
                        <center><img src={loader} alt=""/></center>
                    </DialogContent>
                </Dialog>
                <Grid container spacing={0}>
                    <ThemeProvider theme={theme}>
                        {renderRequisitionField()}
                    </ThemeProvider>
                </Grid>
                <br/>
                {renderNotification()}
            </div>
        </GridList>
    )
};

export default BomInboxView;