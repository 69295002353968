import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import {Dialog, Grow} from "@material-ui/core";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import 'antd/dist/antd.css';
import FormSample from "../JsonForm/FormSample";
import DialogContent from "@material-ui/core/DialogContent";
import GroupView from "../grouping/GroupView";
import Grid from "@material-ui/core/Grid";
import Notification from "../NotificationMessage/Notification";
import CBNotFound from "../workflow/CASA/CBNotFound";
import loader from "../../Static/loader.gif";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../JsonForm/CustomeTheme2";
import MyValidation from "../JsonForm/MyValidation";
import Functions from "../../Common/Functions";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    dialogPaper: {
        overflow: "visible"
    }
};
const jsonForm = {
    "variables": [
        {
            "varName": "username",
            "type": "text",
            "label": "User ID",

        }


    ],

};

let Form = [
    /* {
         "varName": "firstName",
         "type": "text",
         "label": "First Name",
         "grid": 2,
         "required": true,
     },
     {
         "type": "empty",
         "grid": 1
     },
     {
         "varName": "lastName",
         "type": "text",
         "label": "Last Name",
         "grid": 2,
         "required": true,
     },
     {
         "type": "empty",
         "grid": 1
     },
     {
         "varName": "username",
         "type": "text",
         "label": "User Name",
         "grid": 2,
         "required": true,
     },
     {
         "type": "empty",
         "grid": 1
     },
     {
         "varName": "email",
         "type": "text",
         "label": "Email",
         "grid": 2,
         "validation": "email",
         "required": true,
     },
     {
         "type": "empty",
         "grid": 1
     },
     {
         "varName": "phone",
         "type": "text",
         "label": "Mobile Number",
         "grid": 2,
         "validation": "phone",
         "phonePrefix": true,
     },
     {
         "type": "empty",
         "grid": 1
     } ,*/
    {
        "varName": "currentStatus",
        "type": "text",
        "readOnly": true,
        "label": "Current Status",
        "grid": 2,
    },
    {
        "type": "empty",
        "grid": 1
    },
    {
        "varName": "changeStatus",
        "type": "select",
        "required": true,
        "enum": [
            "TEMPORARY DISABLE",
            "PERMANENT DISABLE",
        ],
        "label": "Change Status",
        "grid": 3,
    },
    {
        "type": "empty",
        "grid": 1
    },


];

function Transition(props) {

    return <Grow in={true} timeout="auto" {...props} />;
}

class DeactiveUserAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addGroup: false,
            addMember: false,
            deactiveMember: false,
            tableData: [[" ", " "]],
            groupId: "",
            memberId: "",
            groupData: [[" ", " "]],
            open: true,
            searchTableData: false,
            singleGroupUid: '',
            singleDescription: '',
            loading: false,
            inputData: {},
            selectedDate: {},
            getData: false,
            workstation: false,
            role: false,
            showValue: false,
            SelectedDropdownSearchData: null,
            dropdownSearchData: {},

            err: false,
            varValue: [],
            errorArray: {},
            errorMessages: {},
            title: "",
            notificationMessage: "",
            alert: false,
            otherRequestTitle: "",
            username: ""
        }
    }

    createTableData = (id, username, firstName, lastName, email, phone, status) => {

        return ([username, firstName, lastName, email, phone, status, <button
            className="btn btn-outline-primary"
            style={{
                verticalAlign: 'middle',
            }}
            onClick={() => this.activate(username)}>
            Activate</button>


        ]);

    };
    Disable = (username) => {
        let string = this.state.inputData.changeStatus;
        let url = backEndServerURL + "/disableUser/" + username + "/" + string;
        axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                if (response.data === true) {
                    this.setState({
                        title: "Successfull!",
                        notificationMessage: "Successfully Disable!",
                        alert: true
                    })

                } else {
                    this.setState({
                        title: "Error!",
                        errorType: "error",
                        notificationMessage: response.data,
                        alert: true
                    })

                }


            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    title: "Error!",
                    errorType: "error",
                    notificationMessage: Functions.errorObjectCheck(error),
                    alert: true
                })
            })
    };


    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    closeModal = () => {

        this.setState({
            addGroup: false,
            addMember: false,
            editSubSector: false,
            cbNotFound: false


        });

    };

    memberAddModal = () => {

        return (
            <GroupView member="yes" groupUid={this.state.singleGroupUid} onAdd={this.onAdd}
                       secondButtonFunction={this.closeModal} secondButtonName="close" name="Add New Member"/>
        )
    };

    getSubmitedForm = (object) => {

        this.setState({
            loading: true,
            searchTableData: false

        });
        let string = object.username;

        let url = backEndServerURL + "/getUserStaticData/" + string;

        let inputData = {};
        let varValue = {};
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                let tableArray = response.data;
                inputData.firstName = tableArray.firstName;
                varValue.firstName = tableArray.firstName;
                inputData.lastName = tableArray.lastName;
                varValue.lastName = tableArray.lastName;
                inputData.username = tableArray.username;
                varValue.username = tableArray.username;
                inputData.email = tableArray.email;
                varValue.email = tableArray.email;
                inputData.phone = tableArray.phone;
                varValue.phone = tableArray.phone;
                inputData.currentStatus = tableArray.status;
                varValue.currentStatus = tableArray.status;


                console.log("fffffffffffff");
                console.log(inputData);
                this.setState({
                    tableData: tableArray,
                    inputData: inputData,
                    varValue: varValue,
                    showValue: true,
                    searchTableData: true,
                    loading: false,
                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    inputData: inputData,
                    varValue: varValue,
                    cbNotFound: true,
                    loading: false,

                })
            });


    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderFilterForm = () => {
        return (
            <FormSample close={this.closeModal} grid="2" buttonName="search" onSubmit={this.getSubmitedForm}
                        jsonForm={jsonForm}/>
        )
    };
    renderActivateButton = (username, status) => {
        if (status !== "ACTIVE") {
            return (
                <button
                    className="btn btn-outline-primary"
                    style={{
                        verticalAlign: 'left',
                        position: "absolute",
                        marginTop: "5px",
                        right: 800,

                    }}
                    onClick={() => this.Disable(username)}>
                    Disable</button>
            )
        }
    };
    updateComponent = () => {
        this.forceUpdate();
    };
    searchTableData = () => {

        if (this.state.searchTableData === true && this.state.showValue === true) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, Form, this.updateComponent)
            )


        } else {
            return "";
        }
    };
    handleSubmit = (event) => {
        event.preventDefault();
        let data = {};
        let error = MyValidation.defaultValidation(Form, this.state);
        this.forceUpdate();
        console.log(this.state.inputData);

        console.log("Not working");
        if (error === true) {
            return 0;
        }

        data.currentStatus = this.state.inputData.currentStatus;
        data.changeStatus = this.state.inputData.changeStatus;

        let url = backEndServerURL + "/disableUser/" + this.state.inputData.username + "/" + this.state.inputData.changeStatus;
        axios.post(url, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Updated!",
                    alert: true
                });
                window.location.reload()
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    title: "Error!",
                    errorType: "error",
                    notificationMessage: Functions.errorObjectCheck(error),
                    alert: true
                })
            })
    };
    rederReturnButton = () => {
        if (this.state.searchTableData) {
            return (
                <button
                    className="btn btn-outline-primary  btn-sm"
                    style={{}}
                    onClick={this.handleSubmit}
                >
                    Submit
                </button>
            )
        }
    };

    render() {
        const {classes} = this.props;
        return (
            <section>
                {this.renderNotification()}
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.cbNotFound}>
                    <DialogContent className={classes.dialogPaper}>

                        <CBNotFound
                            closeModal={this.closeModal}
                            title="User Name Not Found"
                        />
                    </DialogContent>
                </Dialog>

                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color:'white'}} className={classes.cardTitleWhite}>Disable User</h4>
                            </CardHeader>
                            <CardBody>
                                {this.renderFilterForm()}

                                <ThemeProvider theme={theme}>
                                    <Grid container>


                                        {this.searchTableData()}


                                    </Grid>

                                </ThemeProvider>
                                <Grid item xs={2}>
                                    {this.rederReturnButton()}
                                </Grid>
                            </CardBody>

                        </Card>
                    </GridItem>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>

                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>

                </GridContainer>
                {/*  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        {this.searchTableData()}
                    </GridItem>

                </GridContainer>*/}
            </section>
        );
    }
}

export default withStyles(styles)(DeactiveUserAdmin);
