import React, {Component} from "react";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import 'semantic-ui-offline/semantic.min.css';
import {createMuiTheme} from "@material-ui/core/styles";
import GridItem from "../../Grid/GridItem";
import GridContainer from "../../Grid/GridContainer";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: "auto"
    }
};
let populateForm = [
    {
        varName: "fileName",
        type: "file",
        accept: ".xlsx,.xls",
        label: "Upload File"
    }
];


const theme = createMuiTheme({
    palette: {
        secondary: {
            main: "#37b159",
            light: "#37b159",
            dark: "#37b159"
        }
    }
});

const table_style = {
    row: {
        border: "none",
        height: 10,
        "&:nth-of-type(odd)": {
            backgroundColor: "red"
        }
    }
};

class InstaPackFileUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {
                callStatus: "Call Status"
            },
            fileUploadData: {},
            responseData: [],
            activeItem: "Generate",
            backgroundColor: "red",
            showValue: true,
            uploadModal: false,
            merchantModal: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            caseId: "",
            title: "",
            dataPassArray: [],
            buttonName: "Claim",
            notificationMessage: "",
            alert: false,
            showData: true,

            selectedCount: 0
        };
    }

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type="success"
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        });
    };

    getSubmitedForm = object => {
        const {fileName} = this.state.fileUploadData;

        const formData = new FormData();
        formData.append("file", fileName);

        axios({
            method: "post",
            url: backEndServerURL + "/instaPackFileUpload/add",
            data: formData,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                "content-type": "multipart/form-data"
            }
        })
            .then(response => {
                console.log(response.data);
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully File Uploaded",
                    alert: true
                });
                setTimeout(function () {
                    window.location.reload()
                }, 1000);
            })
            .catch(error => {
                console.log(error);
            });
    };

    componentDidMount() {

    }

    updateComponent = () => {
        this.forceUpdate();
    };

    open = data => {
        this.setState({
            dataPassArray: data,
            merchantModal: true
        });
    };

    render() {
        const {rows, columns} = this.state;
        const {activeItem} = this.state;
        console.log(this.state.inputData.callStatus);
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    <h4 style={{color: "white"}} >File Upload (Instapack File Card)</h4>
                </CardHeader>
                <CardBody>
                    <div>
                        {this.renderNotification()}

                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardBody>
                                        <div>
                                            {CommonJsonFormComponent.renderJsonForm(
                                                this.state,
                                                populateForm,
                                                this.updateComponent
                                            )}

                                            <br/>
                                            <button
                                                className="btn btn-outline-primary btn-sm"
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                                onClick={this.getSubmitedForm}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </div>
                    <div></div>
                </CardBody>
            </Card>
        );
    }
}

export default InstaPackFileUpload;
