import React from "react";
import {backEndServerURL, frontEndServerURL} from "../Common/Constant";
import axios from "axios";
import Functions from "../Common/Functions";
import Table from "./Table/Table";
import Grid from "@material-ui/core/Grid";
import CardHeader from "./Card/CardHeader";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../Static/loader.gif";
import MakerCumInput from "./workflow/CASA/MakerCumInput";
import GridContainer from "./Grid/GridContainer";
import GridItem from "./Grid/GridItem";
import Card from "./Card/Card";
import withStyles from "@material-ui/core/styles/withStyles";
import Fab from '@material-ui/core/Fab';
import Pageview from '@material-ui/icons/Pageview';
import MakerView from "./360View/MakerView";
import SecondaryJointCbTagging from "./workflow/CASA/SecondaryJointCbTagging";
import LocalstorageEncrypt from "./JsonForm/LocalstorageEncrypt";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};


class DedupResultFunction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            appData: {},
            getData: false,
            varValue: [],
            showValue: false,
            redirectLogin: false,
            title: "",
            notificationMessage: "",
            alert: false,
            inputData: {},
            selectedDate: {},
            SelectedDropdownSearchData: null,
            dropdownSearchData: {},
            values: [],
            customerName: [],
            deferalType: [],
            expireDate: [],
            other: [],
            getCheckerList: [],
            getAllDefferal: [],
            getDeferalList: [],
            loading: true,
            jointAccountCustomerNumber: 0,
            getCbNumber: 0,
            objectForJoinAccount: [],
            getgenerateForm: false,
            renderCumModalopen: false,
            renderCumModalopenNew: false,
            relatedPartyType: "",
            CBGenerateModal: false,
            generateAccountNo: '',
            getDedupData: {},
            jointDedupData: {},
            jointSearchTableData: [],
            propritorshipData: [],
            dedupData: [],
            relatedData: {},
            CustomerModal: false,
            IDENTIFICATION_NO: '',
            searchTableData: false,
            searchTableRelatedData: false,
            getRadioButtonData: {},
            tagingModalCbnumber: '',
            getCustomerNumber: '',
            err: false,
            errorArray: {},
            errorMessages: {},


        };


    }

    closeCustomerModal = () => {
        this.setState({
            CustomerModal: false
        })
    };
    CustomerModal = (uniqueId, customerName, customerId, id) => {
        let data = {};
        data.identificationNumber = uniqueId;
        data.cbNumber = customerId;
        data.customerName = customerName;
        data.identity = id;
        LocalstorageEncrypt.encryptStorageFunction.setItem(id, JSON.stringify(data));
        let url = "/Customer/" + id;
        window.open(frontEndServerURL + url)
        /* this.setState({
             CustomerModal: true,
             customerName: customerName,
             cbNumber: customerId,
             IDENTIFICATION_NO: uniqueId

         })*/
    };
    /* CustomerModal = (uniqueId) => {
         this.setState({
     x        CustomerModal: true,
             IDENTIFICATION_NO: uniqueId
         })
     }*/

    radioButtonChange = (event, customerId) => {
        let variable = {};
        console.log(event.target.name);
        console.log(event.target.value);
        variable[event.target.name] = event.target.value;
        this.setState({
            getCbNumber: event.target.value,
            cbNumber: customerId,
            getRadioButtonData: variable,
            getCustomerNumber: event.target.name.slice(-1)
        })


    };


    tagingModal = (event, index, customerid, relatedPartyType) => {
        this.setState({
            renderCumModalopen: true,
            //cbNumber: customerid,
            tagingModalCbnumber: index,
            cbNumber: customerid,
            relatedPartyType: relatedPartyType
        })
    };

    taging = (index, data) => {
        let value = index + 1;
        if (data !== undefined) {
            this.props.getTaggingData("tagging" + value, data, value);
        } else {
            this.props.getTaggingData("tagging" + value, this.state.getRadioButtonData["tagging" + value], value);
        }


    };
    createTableData = (randomNumber, id, uniqueId, customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType, index) => {
        //this.renderCustomerNumber(sl);
        if (this.props.subServiceType === 'Joint Account' || this.props.subServiceType === 'Company Account' || this.props.subServiceType === "MandateMaintenance") {
            if (accountSource === "TRANZWARE") {
                return ([customerId, accountSource, customerName,
                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>])
            } else if (this.props.radioButton === "NO") {
                return (

                    [customerId, accountSource, customerName,
                        <button
                            className="btn"
                            style={{
                                verticalAlign: 'middle',
                            }}

                            onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                        >
                            <Fab size="small" color="secondary" aria-label="pageview">

                                <Pageview/>
                            </Fab>

                        </button>


                    ])
            } else {
                return (

                    [customerId, accountSource, customerName,
                        <button
                            className="btn"
                            style={{
                                verticalAlign: 'middle',
                            }}

                            onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                        >
                            <Fab size="small" color="secondary" aria-label="pageview">

                                <Pageview/>
                            </Fab>

                        </button>, <button
                        className="btn btn-outline-primary"
                        style={{

                            /* verticalAlign: 'right',
                             position: "absolute",
                             right: 80,*/

                        }}
                        type='button' value='add more'
                        onClick={(event) => this.tagingModal(event, index, customerId, "JOINT")}>
                        Tag
                    </button>


                    ])
            }
        } else if (((this.props.serviceType === "FDR Opening" || this.props.serviceType === "DPS Opening"))) {
            return ([customerId, accountSource, customerName,


                <button
                    className="btn"
                    style={{
                        verticalAlign: 'middle',
                    }}

                    onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                >
                    <Fab size="small" color="secondary" aria-label="pageview">

                        <Pageview/>
                    </Fab>

                </button>


            ])
        } else {
            if (accountSource === "TRANZWARE") {
                return ([customerId, accountSource, customerName,
                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>])
            } else if (this.props.subServiceType === 'NONINDIVIDUAL' || this.props.subServiceType === 'Proprietorship A/C') {
                return ([customerId, accountSource, customerName,


                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>


                ])
            } else if (this.props.radioButton === "NO") {
                return ([customerId, accountSource, customerName,


                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>


                ])
            } else {
                return ([customerId, accountSource, customerName,


                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>,
                    <button
                        className="btn btn-outline-primary"
                        style={{

                            /* verticalAlign: 'right',
                             position: "absolute",
                             right: 80,*/

                        }}
                        type='button' value='add more'
                        onClick={(event) => this.tagingModal(event, index, customerId, "JOINT")}>
                        Tag
                    </button>

                    /*   <input type="radio" onChange={(event) => this.radioButtonChange(event)} name={randomNumber}
                                        value={customerId}/>*/


                ])
            }
        }

    };
    createTableDataWithoutProprietorship = (id, uniqueId, customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType) => {
        //this.renderCustomerNumber(sl);
        if (this.props.subServiceType === 'Company Account') {
            if (accountSource === "TRANZWARE") {
                return ([customerId, accountSource, customerName,
                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>])
            } else {
                return (

                    [customerId, accountSource, customerName,
                        <button
                            className="btn"
                            style={{
                                verticalAlign: 'middle',
                            }}

                            onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                        >
                            <Fab size="small" color="secondary" aria-label="pageview">

                                <Pageview/>
                            </Fab>

                        </button>


                    ])
            }
        }


    };
    createTableproAgentBankingjoint = (custId, name, relPartyCode) => {
        return ([custId, name, relPartyCode, <button
            className="btn btn-outline-primary"
            style={{

                /* verticalAlign: 'right',
                 position: "absolute",
                 right: 80,*/

            }}
            type='button' value='add more'
            onClick={(event) => this.tagingModal(event, Math.floor(Math.random() * 100000000000), custId, "JOINT")}>
            Tag
        </button>])


    };
    CustomerModal = (uniqueId, customerName, customerId, id) => {
        let data = {};
        data.identificationNumber = uniqueId;
        data.cbNumber = customerId;
        data.customerName = customerName;
        data.identity = id;
        LocalstorageEncrypt.encryptStorageFunction.setItem(id, JSON.stringify(data));
        let url = "/Customer/" + id;
        window.open(frontEndServerURL + url)
        /* this.setState({
             CustomerModal: true,
             customerName: customerName,
             cbNumber: customerId,
             IDENTIFICATION_NO: uniqueId

         })*/
    };

    componentDidMount() {

        /*  this.setState({
              loading: true
          })*/

        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;


            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log("llll");
                    console.log(response.data.jointDedupData);
                    let objectForJoinAccount = [];
                    var sl;


                    this.setState({
                        getDedupData: response.data,
                        objectForJoinAccount: objectForJoinAccount,
                        getgenerateForm: true

                    });
                    console.log(response.data);
                    if (this.props.appId !== undefined) {

                        let tableArray = [];
                        let relatedTableArray = [];
                        let dedupSaveId = "";
                        let dedupDataViewUrl = backEndServerURL + "/relatedParty/agentAccounts/" + this.props.appId;
                        axios.get(dedupDataViewUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {

                                console.log("lko");
                                console.log(response.data);

                                if (response.data !== null && Array.isArray(response.data)) {

                                    response.data.map((dedup) => {

                                        tableArray.push(this.createTableproAgentBankingjoint(dedup.custId, dedup.name, dedup.relPartyCode));

                                    });

                                }


                                this.setState({

                                    propritorshipData: tableArray,
                                    //relatedData: relatedTableArray,
                                    searchTableData: true,
                                    searchTableRelatedData: true,
                                    //getsearchValue: object,
                                    loading: false,

                                })


                            })
                            .catch((error) => {

                                this.setState({
                                    propritorshipData: [],
                                    relatedData: [],
                                    searchTableData: true,
                                    searchTableRelatedData: true,
                                    loading: false,
                                })

                            });

                    }


                    console.log(response.data);
                    let varValue = response.data;
                    this.setState({
                        jointAccountCustomerNumber: response.data.jointAccountCustomerNumber,
                        getData: true,
                        varValue: varValue,
                        appData: response.data,
                        showValue: true,
                        loading: false
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })

                });
        }


    }


    updateComponent = () => {
        this.forceUpdate();
    };


    close = () => {
        this.props.closeModal();
    };


    renderCumModalopen = () => {
        this.setState({
            renderCumModalopen: true
        })
    };


    closeModal = (account) => {
        if (!account) {
            this.setState({
                renderCumModalopen: false,
                renderCumModalopenNew: false,
            })
        } else {
            this.setState({
                renderCumModalopen: false,
                renderCumModalopenNew: false,
                generateAccountNo: account,
            })
        }
    };
    closeIcon = () => {
        this.setState({
            renderCumModalopen: false,
            renderCumModalopenNew: false,

        })
    };
    closeModalNew = (account) => {

        this.setState({
            renderCumModalopenNew: false,
            // CBGenerateModal: true,
            generateAccountNo: account,

        })
    };

    closeModalCBGenerate = (account) => {

        this.setState({
            CBGenerateModal: false,
        })
    };

    renderNewAccountOpeingForm = (event, index, relatedPartyType) => {
        event.preventDefault();
        this.setState({

            renderCumModalopenNew: true,
            generateAccountNo: "NEW",
            tagingModalCbnumber: index,
            relatedPartyType: relatedPartyType

        })


    };
    /*renderJointSearchtabledata = () => {
        return (
            this.state.jointSearchTableData.map((tableData, index) => {
                return (

                    <div>
                        <center>
                            <br/>
                            <Grid item xs={12}>
                                <h3>Customer {index + 1}</h3>
                            </Grid>
                        </center>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["", "CB No", "Source", "Customer Name", "View"]}

                            tableData={tableData}
                            tableAllign={['left', 'left', 'left', 'left', 'left', 'right',]}
                        />
                    </div>

                )
            })
        )


    };*/
    numberToCharacter = (i) => {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    };
    renderTagButton = (index, customerid, relatedPartyType, tableData) => {
        console.log(this.props.radioButton);
        if (this.props.radioButton !== "NO") {
            return (
                <center>
                    <button
                        className="btn btn-outline-primary"
                        style={{

                            verticalAlign: 'right',
                            position: "absolute",
                            right: 15,
                        }}
                        type='button' value='add more'
                        onClick={(event) => this.renderNewAccountOpeingForm(event, index, relatedPartyType)}>
                        New
                    </button>
                    <br/>
                    {/*<Grid item xs={6}>
                        <Grid container spacing={1}>
                            {Array.isArray(tableData) && tableData.length > 0 &&   <button
                                className="btn btn-outline-primary"
                                style={{

                                    verticalAlign: 'right',
                                    position: "absolute",
                                    right: 80,

                                }}
                                type='button' value='add more'
                                onClick={(event) => this.tagingModal(event,index,customerid,relatedPartyType)}>
                                Tag
                            </button>}
                            &nbsp;&nbsp;&nbsp;&nbsp;*/}


                </center>
            )
        }
    };
    renderWithoutProprietorshipTableOnly = (tableData) => {
        if (Array.isArray(tableData) && tableData.length < 1) {
            return (
                <center>
                    <h6 style={{
                        color: '#ff1f28'
                    }}>No Match Found</h6>
                </center>
            )
        } else {
            return (
                <Table
                    tableHovor="yes"
                    tableHeaderColor="primary"
                    tableHead={["CB No", "Source", "Customer Name", "View", ""]}

                    tableData={tableData}
                    tableAllign={['left', 'left', 'left', 'left', 'left', 'right', 'right']}
                />
            )
        }
    };
    renderWithoutProprietorshipSearchtabledata = () => {
        return (
            this.state.jointSearchTableData.map((tableData, index) => {
                return (

                    <div>
                        <br/>


                        <center>
                            <Grid item xs={6}>
                                <h3>{this.numberToCharacter(index + 1) + " Applicant "}</h3>

                            </Grid>
                        </center>
                        <br/>
                        {this.renderTagButton(index, tableData.szcustomerid, "JOINT", tableData)}

                        <br/>

                        {this.renderWithoutProprietorshipTableOnly(tableData)}

                    </div>

                )
            })
        )


    };

    searchTableData = (style) => {

        if (this.state.searchTableData && (this.props.subServiceType === "Company Account" || this.props.subServiceType === 'Joint Account' || this.props.subServiceType === "MandateMaintenance")) {

            return (

                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Dedup Result</h4>

                        </CardHeader>
                    </paper>

                    {this.renderWithoutProprietorshipSearchtabledata()}
                    <br/>
                </div>


            )
        }
        /* else if (this.state.searchTableData && (this.props.subServiceType === 'Company Account')) {

             return (

                 <div style={{marginBottom: 40}}>
                     <paper>
                         <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                             <h4 style={{color: "white"}} >Dedup Result</h4>

                         </CardHeader>
                     </paper>

                     {this.renderWithoutProprietorshipSearchtabledata()}
                     <br/>
                 </div>

             )
         }*/

        else if (this.state.searchTableData && Array.isArray(this.state.dedupData) && this.state.dedupData.length < 1) {
            return (

                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Dedup Result</h4>

                        </CardHeader>
                    </paper>
                    <br/>
                    <center>
                        <h6 style={{
                            color: '#ff1f28'
                        }}>No Match Found</h6>
                    </center>
                    <br/>


                </div>

            )


        } else if (Array.isArray(this.state.dedupData) && (this.state.searchTableData && (this.props.subServiceType === "Tag FDR Opening" || this.props.subServiceType === 'INDIVIDUAL' || this.props.subServiceType === "Individual A/C"))) {
            return (

                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Dedup Result</h4>

                        </CardHeader>
                    </paper>
                    <br/>
                    {/* <center>
                        <Grid item xs={6}>
                            <Grid container spacing={1}>
                                <button
                                    className="btn btn-outline-primary"
                                    style={{

                                        verticalAlign: 'right',
                                        position: "absolute",
                                        right: 80,

                                    }}
                                    onClick={(event) => this.tagingModal(this.state.getCustomerNumber)}>
                                    Tag
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                    className="btn btn-outline-primary"
                                    style={{

                                        verticalAlign: 'right',
                                        position: "absolute",
                                        right: 15,
                                    }}
                                    onClick={(event) => this.renderNewAccountOpeingForm()}>
                                    New
                                </button>
                            </Grid>

                        </Grid>
                    </center>*/}


                    {/* <div>

                        <button
                            className="btn btn-outline-primary"
                            style={{
                                verticalAlign: 'right',
                                position: "absolute",
                                right: 10,

                            }}
                            onClick={() => this.renderNewAccountOpeingForm(event)}>
                            New Account Creation
                        </button>

                    </div>
                    <br/>
                    <br/>*/}
                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["CB No", "Source", "Customer Name", "View"]}

                        tableData={this.state.dedupData}
                        tableAllign={['left', 'left', 'left', 'left', 'left']}
                    />

                    <br/>


                </div>

            )


        }

        /*
                else if (Array.isArray(this.state.dedupData) && (this.state.searchTableData && this.props.category === "CASA" && (this.props.subServiceType === 'INDIVIDUAL' || this.props.subServiceType === "Individual A/C"))) {
                     return (

                        <div style={{marginBottom: 40}}>
                            <paper>
                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h4 style={{color: "white"}} >Dedup Result</h4>

                                </CardHeader>
                            </paper>
                            <br/>
                            {this.renderTagButton(this.state.getCustomerNumber,"JOINT")}
                       {/!*     <center>
                                <Grid item xs={6}>
                                    <Grid container spacing={1}>
                                        <button
                                            className="btn btn-outline-primary"
                                            style={{

                                                verticalAlign: 'right',
                                                position: "absolute",
                                                right: 80,

                                            }}
                                            onClick={(event) => this.tagingModal(this.state.getCustomerNumber)}>
                                            Tag
                                        </button>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <button
                                            className="btn btn-outline-primary"
                                            style={{

                                                verticalAlign: 'right',
                                                position: "absolute",
                                                right: 15,
                                            }}
                                            onClick={(event) => this.renderNewAccountOpeingForm()}>
                                            New
                                        </button>
                                    </Grid>

                                </Grid>
                            </center>*!/}

                             <br/>
                            {/!* <div>

                                <button
                                    className="btn btn-outline-primary"
                                    style={{
                                        verticalAlign: 'right',
                                        position: "absolute",
                                        right: 10,

                                    }}
                                    onClick={() => this.renderNewAccountOpeingForm(event)}>
                                    New Account Creation
                                </button>

                            </div>
                            <br/>
                            <br/>*!/}
                            <Table

                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Checkbox","CB No", "Source", "Customer Name","View"]}

                                tableData={this.state.dedupData}
                                tableAllign={['left','left', 'left', 'left', 'left']}
                            />

                            <br/>


                        </div>

                    )


                }
        */
        else if (this.state.searchTableData && (this.props.subServiceType === 'NONINDIVIDUAL' || this.props.subServiceType === 'Proprietorship A/C')) {
            return (
                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Dedup Result</h4>

                        </CardHeader>
                    </paper>

                    <div>

                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["CB No", "Source", "Customer Name", "View"]}
                            tableData={this.state.dedupData}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                    </div>

                </div>

            )


        } else {

        }

    };
    searchTableDataForCompany = (classes) => {
        if (this.state.searchTableData && Array.isArray(this.state.propritorshipData) && this.state.propritorshipData.length < 1) {


        } else if (this.state.searchTableData) {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>
                                <div style={{marginBottom: 40}}>
                                    <paper>
                                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                            <h4 style={{color: "white"}} >Agent Banking Result</h4>

                                        </CardHeader>
                                    </paper>

                                    <div>

                                        <Table

                                            tableHovor="yes"
                                            tableHeaderColor="primary"
                                            tableHead={["CB Number", "Customer Name", "Relation Code", "Tag"]}
                                            tableData={this.state.propritorshipData}
                                            tableAllign={['left', 'left', 'left']}
                                        />
                                    </div>

                                </div>
                            </div>
                        </Card>
                    </GridItem>

                </GridContainer>


            )


        } else {

        }

    };


    renderMakerCumInput = () => {
        if (this.props.WithJointCb === true) {
            return (<SecondaryJointCbTagging editMode={true} WithJointCb={this.props.WithJointCb} makerEnd={true}
                                             introducerId={this.props.introducerId}
                                             introducerName={this.props.introducerName}
                                             freeFlag1={this.props.freeFlag1} customerName={this.props.customerName}
                                             accountNumber={this.props.accountNumber} solId={this.props.solId}
                                             freeFlag4={this.props.freeFlag4}
                                             appId={this.props.appId} closeIcon={this.closeIcon}
                                             taggingForm={this.props.taggingForm}
                                             customerNumber={this.state.getCustomerNumber}
                                             appId={this.props.appId}
                                             hideButton={this.props.hideButton}
                                             generateAccountNo={this.state.generateAccountNo}
                                             getCbNumber={this.state.getCbNumber}
                                             relatedPartyType={this.state.relatedPartyType}
                                             tagingModalCbnumber={this.state.tagingModalCbnumber} taging={this.taging}
                                             cbNumber={this.state.cbNumber}
                                             serviceType={this.props.serviceType}
                                             closeModal={this.closeModal}/>)
        } else {
            return (
                <MakerCumInput introducerId={this.props.introducerId} introducerName={this.props.introducerName}
                               freeFlag1={this.props.freeFlag1} customerName={this.props.customerName}
                               accountNumber={this.props.accountNumber} solId={this.props.solId}
                               freeFlag4={this.props.freeFlag4}
                               appId={this.props.appId} closeIcon={this.closeIcon} taggingForm={this.props.taggingForm}
                               customerNumber={this.state.getCustomerNumber} appId={this.props.appId}
                               hideButton={this.props.hideButton}
                               generateAccountNo={this.state.generateAccountNo}
                               getCbNumber={this.state.getCbNumber}
                               relatedPartyType={this.state.relatedPartyType}
                               tagingModalCbnumber={this.state.tagingModalCbnumber} taging={this.taging}
                               cbNumber={this.state.cbNumber}
                               serviceType={this.props.serviceType}
                               closeModal={this.closeModal}/>
            )
        }
    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }


        return (

            <div>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    fullScreen={true}

                    open={this.state.renderCumModalopen}>
                    <DialogContent>
                        {this.renderMakerCumInput()}

                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    fullScreen={true}

                    open={this.state.renderCumModalopenNew}>
                    <DialogContent>

                        <MakerCumInput WithJointCb={this.props.WithJointCb} freeFlag1={this.props.freeFlag1}
                                       customerName={this.props.customerName} accountNumber={this.props.accountNumber}
                                       solId={this.props.solId} freeFlag4={this.props.freeFlag4}
                                       introducerId={this.props.introducerId} introducerName={this.props.introducerName}
                                       appId={this.props.appId} closeIcon={this.closeIcon}
                                       taggingForm={this.props.taggingForm}
                                       customerNumber={this.state.getCustomerNumber} appId={this.props.appId}
                                       hideButton={this.props.hideButton}
                                       generateAccountNo={this.state.generateAccountNo}
                                       relatedPartyType={this.state.relatedPartyType}
                                       tagingModalCbnumber={this.state.tagingModalCbnumber} taging={this.taging}
                            //cbNumber={this.state.cbNumber}
                                       serviceType={this.props.serviceType}
                                       closeModal={this.closeModalNew}/>
                    </DialogContent>
                </Dialog>
                {/* <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.CBGenerateModal}>
                    <DialogContent className={classes.dialogPaper}>
                        <ErrorModal title={"Generate CB Number Is :"+this.state.generateAccountNo} closeModal={this.closeModalCBGenerate}
                                    subServiceType={this.props.subServiceType} appId={this.props.appId}
                        />

                    </DialogContent>
                </Dialog>*/}
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.CustomerModal}

                >
                    <DialogContent className={classes.dialogPaper}>
                        <MakerView appId={this.props.appId} closeModal={this.closeCustomerModal}
                                   IDENTIFICATION_NO={this.state.IDENTIFICATION_NO}/>
                    </DialogContent>
                </Dialog>
                {this.searchTableDataForCompany(classes)}
                {/*<GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>
                                {this.addCustomerIdForm()}
                            </div>
                        </Card>
                    </GridItem>

                </GridContainer>*/}


            </div>

        )

    }


}

export default withStyles(styles)(DedupResultFunction);
