import Table from "../Table/Table.jsx";
import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import {Dialog, Grow} from "@material-ui/core";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import 'antd/dist/antd.css';
import {notification} from 'antd';
import FormSample from "../JsonForm/FormSample";
import DialogContent from "@material-ui/core/DialogContent";
import GroupView from "../grouping/GroupView";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Notification from "../NotificationMessage/Notification";
import CBNotFound from "../workflow/CASA/CBNotFound";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    dialogPaper: {
        overflow: "visible"
    }
};
const jsonForm = {
    "variables": [
        {
            "varName": "customerId",
            "type": "text",
            "label": "Customer Id",

        }


    ],

};

function Transition(props) {

    return <Grow in={true} timeout="auto" {...props} />;
}

class Grouping extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addGroup: false,
            addMember: false,
            deactiveMember: false,
            tableData: [[" ", " "]],
            groupId: "",
            memberId: "",
            groupData: [[" ", " "]],
            open: true,
            searchTableData: false,
            singleGroupUid: '',
            singleDescription: '',
        }
    }

    createTableData = (id, customerId, groupId, customerName, role, status) => {

        return ([customerId, customerName, role, <button
            className="btn btn-outline-primary"
            style={{
                verticalAlign: 'middle',
            }}
            onClick={() => this.deactiveMember(id, customerId, groupId)}>
            Delete</button>


        ]);

    };
    deactiveMember = (id, customerId, groupId) => {
        let url = backEndServerURL + "/groupMember/delete";
        axios.post(url, {
            "customerId": customerId,
            "groupUid": groupId,
        }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);

                window.location.reload();

            })
            .catch((error) => {
                console.log(error);
            })
    };

    addMember() {
        this.setState({
            addMember: true,
        });
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    closeModal = () => {

        this.setState({
            addGroup: false,
            addMember: false,
            editSubSector: false,
            cbNotFound: false


        });

    };

    memberAddModal = () => {

        return (
            <GroupView member="yes" groupUid={this.state.singleGroupUid} onAdd={this.onAdd}
                       secondButtonFunction={this.closeModal} secondButtonName="close" name="Add New Member"/>
        )
    };

    onAdd = (object) => {

        this.setState({
            addGroup: false,
            addMember: false,

        });
        notification.open({
            message: 'Successfully Added'
        });


    };
    onUpdate = (object) => {

        this.setState({
            editSubSector: false

        });
        notification.open({
            message: 'Successfully Edited'
        });


    };
    getSubmitedForm = (object) => {
        this.setState({
            singleGroupUid: "",
            singleDescription: "",
            searchTableData: false

        });
        let url = backEndServerURL + "/getAllGroupMemberByMainCustId/" + object.customerId;

        const tableArray = [];

        axios.get(url, {withCredentials: true})
            .then((response) => {
                console.log(response.data);
                response.data.map((groupMember) => {

                    tableArray.push(this.createTableData(groupMember.id, groupMember.customerId, groupMember.groupUid, groupMember.customerName, groupMember.role, groupMember.status));
                    if (groupMember.customerId === groupMember.groupUid) {
                        this.setState({
                            singleGroupUid: groupMember.groupUid,
                            singleDescription: groupMember.descriptionOfGrouping,

                        })

                    }
                });

                this.setState({

                    tableData: tableArray,
                    searchTableData: true
                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    tableData: tableArray,
                    cbNotFound: true

                })
            });


    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderFilterForm = () => {
        return (
            <FormSample upper={true} close={this.closeModal} grid="2" buttonName="search"
                        onSubmit={this.getSubmitedForm}
                        jsonForm={jsonForm}/>
        )
    };

    searchTableData = () => {

        if (this.state.searchTableData) {


            return (

                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Search Result</h4>

                        </CardHeader>
                        <br/>
                        <CardBody>
                            <Grid container item xs={12} spacing={5}>
                                <Grid item xs={6}>
                                    <p style={{marginLeft: 20}}>Group Uid:{this.state.singleGroupUid} </p>
                                    <p style={{marginLeft: 20}}>Group Description:{this.state.singleDescription} </p>
                                </Grid>
                                <Grid item xs={6}>

                                    <button

                                        className="btn btn-outline-primary"
                                        style={{
                                            marginLeft: 380
                                        }}

                                        onClick={() => {
                                            this.setState({
                                                addMember: true,

                                            })
                                        }
                                        }
                                    >
                                        Add New Member
                                    </button>
                                </Grid>
                            </Grid>


                        </CardBody>
                    </paper>
                    <center>
                        <Grid item xs={6}>

                            <Paper>

                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h4 style={{color: "white"}} >Member List</h4>

                                </CardHeader>
                                <Table
                                    tableHeaderColor="primary"
                                    tableHead={["Customer-Id", "Customer Name", "Type", "Delete"
                                    ]}
                                    tableData={this.state.tableData}
                                    tableAllign={['left', 'left', 'left']}
                                />

                            </Paper>

                        </Grid>
                    </center>

                    <br/>
                    <div>


                    </div>

                </div>

            )

        } else {
            return "";
        }
    };

    render() {
        const {classes} = this.props;
        return (
            <section>


                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.addMember}

                    TransitionComponent={Transition}
                    style={{width: 700}}
                >
                    <DialogContent className={classes.dialogPaper}>
                        {this.memberAddModal()}

                    </DialogContent>


                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.cbNotFound}>
                    <DialogContent className={classes.dialogPaper}>

                        <CBNotFound
                            closeModal={this.closeModal}
                            title="CB Not Found"
                        />
                    </DialogContent>
                </Dialog>

                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            // color: '#ffffff'
                        }}>
                                <h4 style={{color: '#ffffff'}} className={classes.cardTitleWhite}>Customer Group and Member Search</h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    {this.renderFilterForm()}
                                </div>
                                <br/>


                            </CardBody>
                        </Card>
                    </GridItem>


                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>
                                {this.searchTableData()}
                            </div>
                        </Card>
                    </GridItem>

                </GridContainer>
            </section>
        );
    }
}

export default withStyles(styles)(Grouping);
