import React, {Component} from "react";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import CardBody from "../../Card/CardBody";
import withStyles from "@material-ui/core/styles/withStyles";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import MyValidation from "../../JsonForm/MyValidation";


const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

    button: {
        margin: theme.spacing(1),
    }
});

let remarks = [
    {
        "varName": "remarks",
        "type": "textArea",
        "required": true,
        "label": " Remarks",
        "grid": 12
    }];


class ConfirmationModal extends Component {
    constructor(props) {
        super();
        this.state = {
            inputData: {},
            varValue: {},
            err: false,
            errorArray: {},
            errorMessages: {},
            showValue: false
        };
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderRemarks = () => {
        return (
            CommonJsonFormComponent.renderJsonForm(this.state, remarks, this.updateComponent)
        )
    };

    handleSubmit = () => {
          if(this.props.remarksAllow !== undefined){
              let error  = MyValidation.defaultValidation(remarks,this.state);
              this.forceUpdate();
              if(error === true){
                  return false;
              }
              else{
                  this.props.closeModal("ok",this.state.inputData)
              }
          }
    };

    renderBody = () => {
        if (this.props.remarksAllow !== undefined) {
            return (
                <>
                    {this.renderRemarks()}
                    <center>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                                marginTop: 20
                            }}
                            onClick={this.handleSubmit}
                        >OK
                        </button>
                        &nbsp;
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                                marginTop: 20
                            }}
                            onClick={() => this.props.closeModal('cancel')}
                        >Cancel
                        </button>
                    </center>
                </>
            )
        } else {
            return (
                <center>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginTop: 20
                        }}
                        onClick={() => this.props.closeModal('ok')}
                    >OK
                    </button>
                    &nbsp;
                    {!this.props.cancelHide || this.props.cancelHide === undefined &&
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                                marginTop: 20
                            }}
                            onClick={() => this.props.closeModal('cancel')}
                        >Cancel
                        </button>
                    }
                   
                </center>
            )
        }
    };

    render() {
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <center>
                        <h4 style={{color: "black"}} >{this.props.title}</h4>
                        {this.props.alertButtonArray !== undefined && this.props.alertButtonArray.map(item =>
                            <h4 style={{color: "black"}} >{item}</h4>)}
                    </center>
                    <CardBody>
                        {this.renderBody()}
                    </CardBody>
                </GridItem>
            </GridContainer>
        )
    }

}

export default withStyles(styles)(ConfirmationModal);