import React, {Component, createRef} from "react";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import "../../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Notification from "../../../NotificationMessage/Notification";
import withStyles from "@material-ui/core/styles/withStyles";
import {AssetOperationForm, AssetOperationReadOnlyForm} from "./JsonFormPayOrder"
import Table from "../../../Table/Table";
import loader from "../../../../Static/loader.gif";
import MyValidation from "../../../JsonForm/MyValidation";
import ConfirmAlert from "../../CommonComponent/ConfirmAlert";
import CommonApi from "../../Maintenance/common/CommonApi";
import AssetCommonFunctions from "../AssetCommonFunctions";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'
    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },
};

let makerRemarks = [
    {
        "varName": "makerRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];

class CsoRequisition extends Component {
    constructor() {
        super();
        this.state = {
            appId: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            fileUploadData: {},
            inputData: {},
            showValue: false,
            varValue: {},
            getData: false,
            title: "",
            notificationMessage: "",
            alert: false,
            getRemarks: [],
            getImageLink: [],
            getImageBoolean: false,
            imageName: [],
            loading: true,
            dynamicFile: [],
            uploadStatus: "",
            startingCase: true,
            actionType: "",
            confirmAlert: false,
        };
        this.file = createRef();
        this.functionForCheck = this.functionForCheck.bind(this)
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = AssetOperationForm;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                AssetOperationForm[i].enum = getValue;
            }
        }
    };
    getAllBranch = (fieldName, apiType, setData) => {
        let branch = [];
        let codeUrl = assetOpsBackEndServerURL + "/workplaceUnit/getInitialUnits";
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((item) => {
                    branch.push({key: item.solId, value: item.name})
                });
                this.findByVarNameApiData("branchName", branch)
                this.setState({
                    showValue: true,
                })
            })
            .catch((error) => {
                console.log(error);
            })
    };

    componentDidMount() {
        this.getAllBranch();
        if (this.props.appId !== undefined) {
            CommonApi.getVariablesNew(this.props.appId, assetOpsBackEndServerURL).then((response) => {
                let inputData = this.removeNullValue(response.data);
                inputData.makerRemarks = undefined;
                CommonApi.getFilesNew(this.props.appId, assetOpsBackEndServerURL).then((fileResponse) => {
                    CommonApi.getRemarkDetailsNew(this.props.appId, assetOpsBackEndServerURL).then((remarksArray) => {
                        this.setState({
                            getRemarks: remarksArray,
                            getImageLink: fileResponse,
                            getImageBoolean: true,
                            getData: true,
                            showValue: true,
                            currentTask: response.data.currentTask,
                            varValue: this.removeNullValue(inputData),
                            inputData: inputData,
                            appId: this.props.appId,
                            loading: false
                        })
                    }).catch((error) => {
                        console.log(error)
                    });
                }).catch((error) => {
                    console.log(error);
                });
            }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
        } else {
            let start_url = assetOpsBackEndServerURL + "/startCase/disbursement_maker_pay_order";
            axios.get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    this.setState({
                        appId: response.data.id,
                        loading: false,
                        getData: true,
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{width: "100%"}}>
                    <br/>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                    </div>
                </div>
            )
        }
    };

    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, makerRemarks, this.updateComponent)
    };
    saveHandler = (data) => {
        this.setState({
            loading: true,
        })
        let saveDataDeferralRemarksAndRoute = {};
        if (this.state.inputData.makerRemarks !== undefined && this.state.inputData.makerRemarks !== null) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.makerRemarks;
        }
        let postData = this.state.inputData;
        if(this.state.currentTask === undefined || this.state.currentTask ==="disbursement_maker_pay_order"){
            postData.serviceType = "Pay Order";
            postData.category = "Pay Order";
            postData.subCategory = "Pay Order";
            postData.subServiceType = "Pay Order";
        }
        else if (this.state.currentTask === "disbursement_maker_after_rm_pay_order" && this.state.actionType === "returnToCS") {
            postData.disbursement_maker = data;
            postData.forward_to="CS";
        }
        else if (this.state.currentTask === "disbursement_maker_after_rm_pay_order" ) {
            postData.disbursement_maker = data;
            postData.forward_to="RM";
        }
        saveDataDeferralRemarksAndRoute.map = postData;
        let routeUrl = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
        axios.post(routeUrl, saveDataDeferralRemarksAndRoute, {
            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,},
        }).then((response) => {
            this.setState({
                loading: false,
                alert: true,
                title: "Successful!",
                notificationMessage: response.data,
            });
            if(this.props.appId === undefined && (this.state.inputData.productName === "AUTO LOAN" || this.state.inputData.productName === "PERSONAL LOAN" || this.state.inputData.productName === "HOME LOAN")){
                let data={};
                data.lapReference=this.state.inputData.lapReference;
                data.customerName=this.state.inputData.customerName;
                data.loanAmt=this.state.inputData.loanAmt;
                data.customerId=this.state.inputData.customerCB;
                data.casaAccount=this.state.inputData.casaAccount;
                data.beneficiaryName=this.state.inputData.beneficiaryName;
                data.loanAccounts=this.state.inputData.loanAccounts;
                data.bankName=this.state.inputData.bankName;
                data.beneficiaryAccount=this.state.inputData.beneficiaryAccount;
                data.poAmount=this.state.inputData.poAmount;
                data.branchName=this.state.inputData.branchName;
                let url = assetOpsBackEndServerURL + "/sendPayOrderBranchEmail/" + this.state.inputData.productName;
                axios.post(url, data, {
                    headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,},
                }).then((response) => {
                })
                .catch((error) => {
                    console.log(error);
                });
            }
            if (this.props.appId !== undefined) {
                this.props.closeModal();
            } else {
                setTimeout(function () {
                    window.location.reload()
                }, 1000);
            }

        }).catch((error) => {
            console.log(error);
            this.setState({
                cbNotFound: true,
                title: error.response.data.message,
                loading: false
            })
        });
    };
    renderFlowSUmmeryButton = () => {
        if (this.state.getData && this.props.appId !== undefined) {
            return (
                <>
                    <Grid item xs={12}><br/></Grid>
                    <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                       caseId={this.props.caseId}
                                       customerName={this.state.inputData.customerName} accountNumber={this.state.inputData.accountNumber}
                                       serviceType={this.state.inputData.serviceType}
                                       category={this.state.inputData.category}
                                       subCategory={this.state.inputData.subCategory}
                                       solId={this.props.solId}/>
                </>
            )
        }
    };

    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b> Pay Order</b>
                    <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> CB Number:</b>{this.state.inputData.cbNumber}
                        <b> SOLID:</b>{this.props.solId}
                        <b> Case ID:</b> {this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                            onClick={this.props.closeModal}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Pay Order Request{this.props.appId !== undefined ?
                    <a><CloseIcon onClick={this.props.closeModal} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a> : ""}</h4>
            )
        }
    };

    onBlurFunctionForApiCall = (event, varName, secondVarName) => {
        event.preventDefault();
        if (varName === "customerCB" && (this.state.inputData.customerCB === undefined || this.state.inputData.customerCB === null || this.state.inputData.customerCB === "")) {
            console.log(this.state.inputData);
            this.setState({
                loading: true
            });
            let inputData = {};
            inputData.customerCB = this.state.inputData.customerCB;
            inputData.lapReference = this.state.inputData.lapReference;
            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                loading: false,
            });
        } else if (secondVarName === "customerCB") {
            let inputData = {...this.state.inputData};
            inputData.customerCBApiCall = "NO";
            inputData.loanAccountsCBApiCall = "NO";
            inputData.cbNumber = undefined;
            inputData.loanAccounts = undefined;

            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                showValue: false,
                loading: true,
            });
            this.forceUpdate();
            let url = assetOpsBackEndServerURL + "/getActiveLoanAccountList/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    inputData.cbNumber = this.state.inputData[secondVarName];
                    inputData.customerName = response.data.name;
                    this.findByVarNameApiData("loanAccounts", response.data.accounts);
                    inputData.customerCBApiCall = "YES";
                    this.setState({
                        inputData: inputData,
                        varValue: this.removeNullValue(inputData),
                        loading: false,
                        showValue: true,
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message,
                    })
                })
        }
    };


    onKeyDownChange = (data) => {
        if (data.varName === "loanAccounts" && (this.state.inputData[data.varName] === undefined || this.state.inputData[data.varName] === null)) {
            console.log(this.state.inputData);
            this.setState({
                loading: true
            });
            let inputData = {};
            inputData.lapReference = this.state.inputData.lapReference;
            inputData.customerCB = this.state.inputData.customerCB;
            inputData.customerCBApiCall = this.state.inputData.customerCBApiCall;
            inputData.loanAccounts = this.state.inputData.loanAccounts;
            inputData.customerName = this.state.inputData.customerName;
            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                loading: false,
                submission: false,
            });
            console.log(inputData);
        } else if (data.varName === "loanAccounts" && this.state.inputData[data.varName] !== undefined && this.state.inputData[data.varName] !== null) {
            let inputData = {...this.state.inputData};
            inputData.loanAccountsCBApiCall = "NO";
            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                showValue: false,
                loading: true,
            });
            let postData = {
                "loanAccounts": this.state.inputData[data.varName],
                "cbNumber": this.state.inputData.cbNumber,
                "loanAccountDetails": "Y",
                "loanGeneralInquiry": "N",
                "exciseDuty": "N",
                "loanAccountInfo": "Y",
                "getCustomAcctDetails": "N"
            };
            AssetCommonFunctions.getCustLoanAccountDetails(postData)
                .then((custLoanAccountDetailsResponse) => {
                    inputData.casaAccount = custLoanAccountDetailsResponse.data.loanAccountInfoResponse.operAcctId;
                    inputData.acctId = custLoanAccountDetailsResponse.data.loanAccountInfoResponse.acctId;
                    inputData.loanAmt = custLoanAccountDetailsResponse.data.loanAccountInfoResponse.loanAmt;
                    inputData.accountNumber = this.state.inputData[data.varName];
                    inputData.productName = custLoanAccountDetailsResponse.data.loanAccountDetailsResponse.responseData.productName;
                    inputData.loanAccountsCBApiCall = "YES";

                    this.setState({
                        inputData: inputData,
                        varValue: this.removeNullValue(inputData),
                        showValue: true,
                        getData: true,
                        submission: true,
                        loading: false,
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message,
                    })
                })
        }
    };

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <a
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: "middle",
                                display: "block",
                                marginTop: 20,
                            }}
                            target="_blank"
                            href={assetOpsBackEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                        >
                            {splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </a>
                        &nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };

    functionForCheck = (value) => {
        console.log(value)
    };
    removeNullValue = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    renderForm = () => {
        if (this.state.getData && this.state.currentTask ==="disbursement_maker_after_rm_pay_order") {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, AssetOperationReadOnlyForm, this.updateComponent)
            )
        }
        else if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, AssetOperationForm, this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)
            )
        }
    }
    validationForHandleConfirm = (actionType) => {
        let error = MyValidation.defaultValidation(AssetOperationForm, this.state);
        let errorRemarks = MyValidation.defaultValidation(makerRemarks, this.state);

        this.forceUpdate();
        if (error === true || errorRemarks===true) {
            return 0;
        }
        this.setState({
            confirmAlert: false,
        });
        if (actionType === "saveHandler") {
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })
        } else if (actionType === "returnHandler") {
            let dependencyField = [];
            dependencyField.push({
                "varName": "makerRemarks",
                "type": "textArea",
                "label": "Remarks",
                "grid": 12,
                "required":true,
            });
            let error = MyValidation.defaultValidation(dependencyField, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })
        }
        else if (actionType === "returnToCS") {
            let dependencyField = [];
            dependencyField.push({
                "varName": "makerRemarks",
                "type": "textArea",
                "label": "Remarks",
                "grid": 12,
                "required":true,
            });
            let error = MyValidation.defaultValidation(dependencyField, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })
        }
    };
    closeConfirmAlert = (data, appId) => {
        this.setState({
            confirmAlert: false,
        });
        if (data === "NO") {
        } else if (data === "YES" && this.state.actionType === "saveHandler") {
            this.saveHandler("YES")
        } else if (data === "YES" && this.state.actionType === "returnHandler") {
            this.saveHandler("NO")
        } else if (data === "YES" && this.state.actionType === "returnToCS") {
            this.saveHandler("NO")
        }

    };
    handleCommon = (event, type) => {
        event.preventDefault();
        {this.validationForHandleConfirm(type)}
    };
    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <React.Fragment>
                    {(this.state.currentTask === undefined || this.state.currentTask ==="disbursement_maker_pay_order") && <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            marginRight: "10px",
                        }} onClick={(event) => this.handleCommon(event, "saveHandler")}
                    >
                        Submit
                    </button>}
                    {(this.state.currentTask ==="disbursement_maker_after_rm_pay_order") && <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            marginRight: "10px",
                        }} onClick={(event) => this.handleCommon(event, "saveHandler")}
                    >
                        Submit
                    </button>}
                    {(this.state.currentTask ==="disbursement_maker_after_rm_pay_order")  && <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={(event) => this.handleCommon(event, "returnHandler")}
                    >
                        Return To RM
                    </button>}
                    &nbsp;&nbsp;&nbsp;
                    {(this.state.currentTask ==="disbursement_maker_after_rm_pay_order")  && <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={(event) => this.handleCommon(event, "returnToCS")}
                    >
                        Return To CS
                    </button>}
                </React.Fragment>

            )

        }

    };

    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {this.renderHeader()}
                    </CardHeader>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <CardBody>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <ThemeProvider theme={theme}>
                                    {this.renderForm()}
                                    <Grid item xs={12}>
                                    </Grid>
                                    {this.mappingPhoto()}
                                    <Grid item xs={12}>
                                        <br/>
                                    </Grid>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.confirmAlert}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <ConfirmAlert validationForHandleConfirm={this.validationForHandleConfirm}
                                                          closeModal={this.closeConfirmAlert}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                </ThemeProvider>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <ThemeProvider theme={theme}>
                                    {this.renderRemarksData()}
                                    {this.renderFlowSUmmeryButton()}
                                    {this.renderRemarks()}
                                </ThemeProvider>
                            </Grid>
                        </Grid>
                        <Grid item xs="3" style={{marginTop: "10px"}}>
                            {this.renderSubmitButton()}
                        </Grid>
                        {this.renderNotification()}
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(CsoRequisition);
