import React, {Component} from "react";
import functionForFileDownload from "../../DeliverableManagement/FunctionForFileDownload";
import {assetOpsBackEndServerURL} from "../../../Common/Constant";
import axios from "axios";

class DownloadCommonFormJsonPdf extends Component {

    static formJsonPdf(inputformJsonList, inputData, fileName) {
        let jsonFormList = [];
        inputformJsonList.map((data, index) => {
            let obj = {
                label: data.label,
                value: inputData[data.varName]
            };
            jsonFormList.push(obj);
        });
        console.log(jsonFormList);
        // return 0;
        return (
            functionForFileDownload.downloadFile(assetOpsBackEndServerURL + "/generateFromFieldPdf", "POST", jsonFormList, fileName)
        );
    }

    static smsSend(phoneNumber, massage) {
        axios.post(assetOpsBackEndServerURL, {
            phoneNumber: phoneNumber,
            massage: massage
        }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

            })
            .catch((error) => {
                console.log(error)
            })
    }

    static formJsonPdfNew(inputformJsonList, inputData, fileName, url) {
        let jsonFormList = [];
        inputformJsonList.map((data, index) => {
            let obj = {
                label: data.label,
                value: inputData[data.varName]
            };
            jsonFormList.push(obj);
        });
        console.log(jsonFormList);
        // return 0;
        return (
            functionForFileDownload.downloadFile(url + "/generateFromFieldPdf", "POST", jsonFormList, fileName)
        );
    }

    static smsSendNew(phoneNumber, massage, url) {
        axios.post(url, {
            phoneNumber: phoneNumber,
            massage: massage
        }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export default DownloadCommonFormJsonPdf
