import React, {Component} from "react";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import CBNotFound from "../../workflow/CASA/CBNotFound";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Notification from "../../NotificationMessage/Notification";
import withStyles from "@material-ui/core/styles/withStyles";
import MyValidation from "../../JsonForm/MyValidation";
import loader from "../../../Static/loader.gif";
import ReferenceModal from "../ReferenceModal";
import FunctionForGetAcDetails from "../../workflow/CommonComponent/FunctionForGetAcDetails";
import SignatureButton from "../../workflow/Maintenance/SignatureButton";
import SignatureButtonForClientId from "../../workflow/CommonComponent/SignatureButtonForClientId";
import OtherCardDetails from "../CreditCardChequeBook/OtherCardDetails";


let chequeBookType = [
    {
        varName: "process_type",
        type: "select",
        label: "Process Type",
        enum: [
            "Pay Order",
            "FDD"
        ],
        onKeyDown: true,
        grid: 2,
        required: true
    },
    {
        varName: "source",
        type: "select",
        enum: [
            "Finacle",
            "ABABIL"
        ],
        required: true,
        label: "Source",
        grid: 2,
        "onKeyDown": true,
        "conditional": true,
        "conditionalVarName": "process_type",
        "conditionalVarValue": "Pay Order",
    },
    {
        varName: "source",
        type: "select",
        enum: [
            "Finacle",
        ],
        required: true,
        label: "Source",
        grid: 2,
        "onKeyDown": true,
        "conditional": true,
        "conditionalVarName": "process_type",
        "conditionalVarValue": "FDD",
    },
    {
        varName: "currency",
        type: "select",
        enum: [],
        required: true,
        label: "Currency",
        grid: 2,
        onKeyDown: true,
        "conditionalOrValue": true,
        "conditionalVarName": "source",
        "conditionalOrList": ["ABABIL", "Finacle"]
    },

    {
        varName: "quantity",
        type: "select",
        enum: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20"
        ],
        required: true,
        label: "Quantity",
        "conditionalOrValue": true,
        "conditionalVarName": "source",
        "conditionalOrList": ["ABABIL", "Finacle"],
        grid: 2
    },
];

let SearchForm = [
    {
        varName: "eqmNumber",
        type: "text",
        label: "EQM Number",
        grid: 2,
        validation: "numeric"
    },
    {
        varName: "accountNumber",
        type: "textDedup",
        label: "Account Number",
        grid: 2,
        required: true,
        validation: "numeric"
    }
];
let populateForm = [
    {
        varName: "customerName",
        type: "text",
        label: "Customer Name",
        readOnly: true,
        multiline: true,
        grid: 2
    },
    {
        varName: "accountStatus",
        type: "text",
        label: "Account Status",
        readOnly: true,
        grid: 2,
    },
    {
        "label": "A/C Balance",
        "type": "text",
        "varName": "accountBalance",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "showAccountBalance",
        "conditionalVarValue": true,
    },
    {
        varName: "accountType",
        type: "text",
        label: "Account Type",
        required: true,
        readOnly: true,
        grid: 2,
    },
    {
        varName: "mobileNumber",
        type: "text",
        label: "Mobile Number",
        // required: true,
        readOnly: true,
        grid: 2,
    },
    {
        varName: "numberOfChequeBook",
        type: "select",
        label: "No. of Cheque Book",
        required: true,
        grid: 2,
        enum: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]
    },
    {
        varName: "pageofChequebook",
        type: "select",
        label: "Page of Chequebook",
        required: true,
        grid: 2,
        conditionalOrValue: true,
        conditionalVarName: "accountType",
        conditionalOrList: ["Savings", "Savings(Alo)", "Savings(Ababil Alo)"],
        enum: ["10", "25"]
    },
    {
        varName: "pageofChequebook",
        type: "select",
        label: "Page of Chequebook",
        required: true,
        grid: 2,
        conditionalOrValue: true,
        conditionalVarName: "accountType",
        conditionalOrList: ["Current", "ODA", "CCA"],
        enum: ["25", "50", "100"]
    },
    {
        varName: "deliveryType",
        type: "text",
        label: "Delivery Type",
        required: true,
        readOnly: true,
        grid: 2,
    },

    {
        varName: "branchName",
        type: "autoCompleteValueReturn",
        enum: [],
        label: "Branch Name",
        required: true,
        conditional: true,
        conditionalVarName: "deliveryType",
        conditionalVarValue: "Branch",
        grid: 2,
    },
    {
        varName: "customerAddress",
        type: "text",
        label: "Customer Address",
        required: true,
        conditional: true,
        conditionalVarName: "deliveryType",
        conditionalVarValue: "Courier",
        grid: 2,
    },
    {
        varName: "customerType",
        type: "text",
        label: "Customer Type",
        readOnly: true,
        required: true,
        // enum: ["General", "Priority", "Sapphire"],
        grid: 2,
    },
    {
        varName: "checqueBookDesign",
        type: "select",
        label: "Chequebook Design",
        conditional: true,
        conditionalVarName: "customerType",
        conditionalVarValue: "Priority",
        grid: 2,
        enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5"],
    },
    {
        varName: "checqueBookDesign",
        type: "select",
        label: "Chequebook Design",
        conditional: true,
        conditionalVarName: "customerType",
        conditionalVarValue: "Sapphire",
        grid: 2,
        enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5", "Design Code 6"],
    },
    {
        varName: "chequeBookType",
        type: "select",
        label: "Cheque Book Type",
        enum: ["Normal", "A4"],
        grid: 2,
    },
];

let populateFormCard = [
    {
        varName: "eqmNumber",
        type: "text",
        label: "EQM Number",
        grid: 2,
        validation: "numeric"
    },
    {
        varName: "clintId",
        type: "textApiCall",
        required: true,
        validation: "numeric",
        label: "Credit Card Client Id",
        grid: 2
    },
    {
        varName: "cardNumber",
        type: "autoCompleteValueReturn",
        label: "Card Number",
        onKeyDown: true,
        required: true,
        grid: 2
    },
    {
        varName: "customerName",
        type: "text",
        label: "Customer Name",
        readOnly: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "showCustName",
        conditionalVarValue: true
    },
    {
        varName: "cardProductName",
        type: "text",
        label: "Card Product Name",
        readOnly: true,
        validateReadOnly: true,
        required: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "showChequeType",
        conditionalVarValue: true
    },
    {
        varName: "cardChequeType",
        type: "text",
        label: "Card Cheque Type",
        validateReadOnly: true,
        readOnly: true,
        grid: 2,
        required: true,
        conditional: true,
        conditionalVarName: "showChequeType",
        conditionalVarValue: true
    },
    {
        varName: "chequeType",
        type: "text",
        label: "Cheque Type",
        readOnly: true,
        validateReadOnly: true,
        required: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "showChequeType",
        conditionalVarValue: true
    },
    {
        varName: "customerAddress",
        type: "text",
        label: "Customer Address",
        conditional: true,
        conditionalVarName: "showCustAddress",
        conditionalVarValue: true,
        readOnly: true,
        grid: 4
    },
];


let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];

const jsonForFile = [
    {
        varName: "fileUpload",
        type: "file",
        label: "Upload",
        accept: ".pdf",
        grid: 2,
    }
];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class DeliverableChequeBookFrom extends Component {
    constructor() {
        super();
        this.state = {
            appData: "",
            autoComplete: {},
            fileUploadData: {},
            appId: "",
            requestType: "",
            inputData: {},
            errorArray: {},
            selectedDate: {},
            err: false,
            errorMessages: {},
            // signatureModal: false,
            searchData: false,
            showValue: false,
            varValue: {},
            getData: false,
            cbNotFound: false,
            cardModal: false,
            errorClient: false,
            errorClintMessage: "",
            title: "",
            notificationMessage: "",
            alert: false,
            signatureModal: false,
            loading: false,
            caseId: 0,
            reference: false,
            referenceTitle: ""
        };
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    handleChange = event => {
        this.setState({appData: event.target.value});
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
            signatureModal: false,
            reference: false
        })
    };
    onBlurFunctionForApiCall = (event, varName, secondVarName) => {
        event.preventDefault();
        let inputData = this.state.inputData;
        let varValue = this.state.varValue;
        //this.state.inputData.debitAccountNumberApiCall = "NO";
        let allInputData = this.state.inputData;

        if (varName === "rmCode") {
            this.setState({
                loading: true,
            });
            this.state.inputData.rmCodeApicall = "NO";
            this.state.varValue.rmCodeApicall = "NO";
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.rmCodeName = response.data;
                    this.state.varValue.rmCodeName = response.data;
                    console.log(response.data);
                    this.state.inputData.rmCodeApicall = "YES";
                    this.state.varValue.rmCodeApicall = "YES";
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false,
                    })
                })
                .catch((error) => {
                    this.state.inputData.rmCode = "__ERROR__";
                    this.state.varValue.rmCode = "__ERROR__";
                    this.forceUpdate();
                    console.log(error);
                    this.setState({

                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Number"
                    })
                })
        }
    }

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.searchHandler(event);

        }

    };

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    searchHandler = event => {
        event.preventDefault();

        let error = MyValidation.defaultValidation(SearchForm.concat(chequeBookType), this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true,
                getData: false,
                showValue: true,
                searchData: false
            });

            FunctionForGetAcDetails.getAccountInfo(this.state.inputData.accountNumber)
                .then((response) => {
                    let inputData = {...this.state.inputData};
                    if (response.data.acctStatus !== "A") {
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                            loading: false,
                            cbNotFound: true,
                            searchData: false,
                            title: 'Account is not Active!'
                        })
                    } else {
                        inputData.accountStatus = response.data.acctStatus;
                        inputData.accountNumber = response.data.acctNumber;
                        inputData.customerName = response.data.acctName;
                        inputData.homeBranchSolId = response.data.solId;
                        inputData.accountBalance = response.data.acctEftvBalance;
                        inputData.showAccountBalance = response.data.schmCode !== "SBSTF";
                        inputData.solId = response.data.solId;
                        inputData.accountType = response.data.accountType;
                        inputData.acctCurrency = response.data.crncyCode;
                        inputData.cbNumber = response.data.cifId;
                        inputData.deliveryType = "Branch";
                        inputData.schemeCode = response.data.schmCode;
                        let getDataByCB = backEndServerURL + "/cbsCustomerGet/" + inputData.cbNumber;
                        axios.get(getDataByCB, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                if (response.data.COMMPH1BLOCK !== undefined) {
                                    inputData.mobileNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode
                                }

                                if (response.data.COMMEMLBLOCK !== undefined) {
                                    inputData.emailAddress = response.data.COMMEMLBLOCK.emailInfo;
                                }
                                let occupation = response.data.occupationCode ? response.data.occupationCode : "NA";
                                let getCustomerType = backEndServerURL + "/getCustomerType/" + inputData.schemeCode + "/" + occupation;
                                axios.get(getCustomerType, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        inputData.customerType = response.data.customerType;
                                        if (response.data.chequebookAllowed === "NO") {
                                            this.setState({
                                                showValue: true,
                                                loading: false,
                                                cbNotFound: true,
                                                searchData: false,
                                                title: 'Cheque Book Not Allowed for this Account!'
                                            })
                                        } else {
                                            this.setState({
                                                inputData: inputData,
                                                varValue: this.copyJson(inputData),
                                                getData: true,
                                                loading: false,
                                                showValue: true,
                                                searchData: true
                                            })
                                        }

                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        this.setState({
                                            inputData: inputData,
                                            varValue: this.copyJson(inputData),
                                            showValue: true,
                                            loading: false,
                                            cbNotFound: true,
                                            title: error.response.data.message
                                        });
                                    })
                            })
                            .catch((error) => {
                                this.setState({
                                    inputData: inputData,
                                    varValue: this.copyJson(inputData),
                                    showValue: true,
                                    loading: false,
                                    cbNotFound: true,
                                    title: error.response.data.message
                                });
                            });
                    }
                }).catch((error) => {
                this.setState({
                    showValue: true,
                    loading: false,
                    cbNotFound: true,
                    title: error.response.data.message
                })
            });
        }

    };

    signatureCard = () => {
        this.setState({
            signatureModal: true
        })
    };

    getBranchAll() {
        let branch = [];
        let branchUrl = backEndServerURL + "/workplaceUnit/getInitialUnits";
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((item) => {
                    branch.push({key: item.solId, value: item.name})
                });
                for (let form of populateForm) {
                    if (form.type === "autoCompleteValueReturn" && form.varName === "branchName") {
                        form.enum = branch
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    componentDidMount() {
        let start_url = backEndServerURL + "/startCase/cheque_book_request_start";
        axios.get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                this.getBranchAll();
                this.setState({
                    appId: response.data.id,
                    caseId: response.data.caseId
                });
            })
            .catch(error => {
                console.log(error);
            });
    };


    saveHandler = event => {
        event.preventDefault();

        let error = MyValidation.defaultValidation(chequeBookType, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            let DeliveryBranchIfo = this.state.inputData.branchName;
            let postData = this.state.inputData;
            delete postData.clintId;
            delete postData.cardProductName;
            delete postData.cardChequeType;
            delete postData.chequeType;
            delete postData.cardNumber;
            if (postData.deliveryType === "Branch") {
                delete postData.customerAddress;
                postData.deliveryBranchSolId = DeliveryBranchIfo.key;
                postData.branchName = DeliveryBranchIfo.value;
            }
            postData.freeFlag1 = "FDD";
            postData.category = "FDD Payorder";
            postData.subCategory = "Bulk Requisition";
            postData.serviceType = "Debit Card";
            postData.subServiceType = "Chequebook";
            postData.customerName = this.state.inputData.customerName;
            postData.appId = this.state.appId;
            let url = backEndServerURL + "/variables/" + this.state.appId;
            axios
                .post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    let caseRouteUrl =
                        backEndServerURL + "/case/route/" + this.state.appId;
                    axios
                        .get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then(response => {

                            this.setState({
                                reference: true,
                                referenceTitle: "Reference Number : " + this.state.caseId,
                                title: "Successful!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                getData: false,
                                requestType: "",
                                inputData: {},
                                varValue: {},
                                errorArray: {},
                                err: false,
                                errorMessages: {},
                            });

                        })
                        .catch(error => {
                            console.log(error);
                        });
                })
                .catch(error => {

                    console.log('Cheque Book request error');
                });
        }

    };

    renderAccountNumberSearch = () => {
        if (this.state.requestType === "chequebook") {
            return (
                <React.Fragment>
                    {CommonJsonFormComponent.renderJsonForm(
                        this.state,
                        SearchForm,
                        this.updateComponent,
                        this.onKeyDownForDedup
                    )}
                    <Grid item xs={6} style={{marginTop: "15px"}}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={this.searchHandler}
                        >
                            Search
                        </button>
                    </Grid>
                </React.Fragment>
            )
        }
    };
    findByVarNameApiData = (searchVarname, getValue) => {
        if (searchVarname === "currency") {

            let jsonArray = chequeBookType;
            for (let i = 0; i < jsonArray.length; i++) {
                let jsonObject = jsonArray[i];
                if (jsonObject.varName === searchVarname) {
                    let data = [];
                    chequeBookType[i].enum = getValue;


                }
            }
        }
    }
    handleChangeSelect = (data) => {
        // event.preventDefault();
        if (data) {
            // this.state.inputData.requestType = option.title
            /*  if (this.state.inputData.requestType === "Finacle" || this.state.inputData.requestType === "Ababil") {
                  this.setState({
                      requestType: "chequebook",
                      searchData: false,
                      errorArray: {},
                      err: false,
                      errorMessages: {},
                  })
              }
              else {
                  delete this.state.inputData["accountNumber"];
                  delete this.state.inputData["eqmNumber"];
                  this.setState({
                      requestType: "card chequebook",
                      searchData: false,
                      errorArray: {},
                      err: false,
                      errorMessages: {},
                  })
              }*/
            if (data.varName === "source") {
                this.state.inputData.curerncyApiData = false;
                this.forceUpdate();
                let codeUrl = backEndServerURL + "/payorderFddSourceToData";
                axios.post(codeUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data)
                        let currencyList=[];
                        response.data.map((data)=>{
                            currencyList.push(data.currency.trim())
                        })
                        {
                            this.findByVarNameApiData("currency", currencyList)
                        }
                        let inputData = this.state.inputData;

                      /*
                      inputData.curerncy = response.data.curerncy;
                       inputData.transactionCode = response.data.transactionCode;
                        inputData.accountNumber = response.data.accountNumber;
                        inputData.routingNo = response.data.routingNo;
                        inputData.routing = response.data.routing;
                        inputData.Branch_name = response.data.Branch_name;*/
                        inputData.curerncyApiData = true;
                        this.setState({
                            inputData: {...inputData},
                            varValue: inputData,
                            showValue: true,
                            getData: false,
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            }
            else if (data.varName === "currency") {

                let codeUrl = backEndServerURL + "/payorderFddSourceToData";
                axios.post(codeUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data)
                        let inputData = this.state.inputData;
                        //inputData.currency = inputData.currency;
                        response.data.map((data=>{
                            inputData.transactionCode = data.transactionCode;
                            inputData.accountNumber = data.accountNumber;
                            inputData.routingNo = data.routingNo;
                            inputData.routing = data.routing;
                            inputData.Branch_name = data.Branch_name;
                        }))

                        console.log(inputData)
                         this.setState({
                            inputData: inputData,
                            varValue: {...inputData},
                            showValue: true,
                            getData: true,
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            }
            else if (data.varName === "nameOfScheme") {
                this.setState({
                    loading: true
                });
                this.state.inputData.accountAcquisitionApiCall = false;
                this.state.inputData.accountAcquisition = undefined;
                this.state.varValue.accountAcquisition = undefined;


                let object = {
                    currency: typeof this.state.inputData.currency === "object" ? this.state.inputData.currency.key : this.state.inputData.currency,
                };
                if (this.props.freeFlag3 === "INDIVIDUAL" || this.props.freeFlag3 === "Individual A/C" || this.props.freeFlag3 === "Joint Account") {
                    object.natureAccount = "Individual-Retail";
                } else {
                    object.natureAccount = "Non-Individual"
                }
                object.nameOfScheme = this.state.inputData.nameOfScheme;
                let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getDPSAccountAcquisition";//{sectorCode}
                axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);
                        let jsonArrayServiceList = this.props.commonJsonForm;
                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "accountAcquisition") {
                                this.props.commonJsonForm[i].enum = response.data;
                                console.log(this.props.commonJsonForm[i])
                            }
                        }
                        this.state.inputData.accountAcquisitionApiCall = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            }
        } else {
            this.setState({
                requestType: "",
                getData: false,
                inputData: {},
                varValue: {},
                errorArray: {},
                err: false,
                errorMessages: {},
                searchData: false
            })
        }


    };


    renderChequeBookType = () => {
        return (

            <>
                {CommonJsonFormComponent.renderJsonForm(this.state, chequeBookType, this.updateComponent, this.onBlurFunctionForApiCall, this.handleChangeSelect)}
            </>
        )
    };

    renderLoader = () => {
        if (this.state.loading) {
            const {classes} = this.props;
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>


            )
        }
    };

    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
    };

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                return (
                    <React.Fragment>
                        <a
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: "middle",
                                display: "block",
                                marginTop: 20,
                            }}
                            target="_blank"
                            href={backEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                        >
                            Document Preview
                        </a>
                        &nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };


    cardSaveHandler = event => {
        event.preventDefault();
        let error = MyValidation.defaultValidation(chequeBookType, this.state);
        //let errorTwo = MyValidation.fileValidation(jsonForFile[0], this.state);
        this.forceUpdate();
        if (error === true /*|| errorTwo === true*/) {
            return 0;
        } else {
            /*  let uploadUrl = backEndServerURL + "/case/upload";
              let formData = new FormData();
              formData.append("appId", this.state.appId);
              formData.append("file", this.state.fileUploadData["fileUpload"]);
              formData.append("type", "file");

              axios({
                  method: "post",
                  url: uploadUrl,
                  data: formData,
                  headers: {
                      Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                      "content-type": "multipart/form-data"
                  }
              })
                  .then(response => {

                  })
                  .catch(error => {
                      console.log(error)
                  });*/

            let remark_url = backEndServerURL + "/appRemarkSave/" + this.state.inputData.csRemarks + "/" + this.state.appId;
            axios.post(remark_url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log("remark save");

                })
                .catch(error => {
                    console.log(error)
                });

            let postData = {};
            postData.clintId = this.state.inputData.clintId;
            postData.caseId = this.state.caseId;
            postData.cbNumber = this.state.inputData.clintId;
            postData.cardNumber = this.state.inputData.cardNumber;
            //postData.accountNumber = this.state.inputData.cardNumber["value"];
            postData.customerName = this.state.inputData.customerName;
            postData.cardProductName = this.state.inputData.cardProductName;
            //postData.cardChequeType = this.state.inputData.cardNumber["cardChequeType"];
            postData.customerAddress = this.state.inputData.customerAddress;
            postData.chequeType = this.state.inputData.chequeType;
            postData.showChequeType = this.state.inputData.showChequeType;
            postData.showCustAddress = this.state.inputData.showCustAddress;
            postData.showCustName = this.state.inputData.showCustName;
            postData.eqmNumber = this.state.inputData.eqmNumber;
            postData.freeFlag1 = "FDD";
            postData.category = "Credit Card";
            postData.subCategory = "Chequebook";
            postData.serviceType = "Credit Card";
            postData.subServiceType = "Chequebook";
            postData.csRemarks = undefined;
            let url = backEndServerURL + "/variables/" + this.state.appId;
            axios
                .post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {

                    let caseRouteUrl =
                        backEndServerURL + "/case/route/" + this.state.appId;
                    axios
                        .get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then(response => {
                            this.setState({
                                reference: true,
                                referenceTitle: "Reference Number : " + this.state.caseId,
                                title: "Successful!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                getData: false,
                                requestType: "",
                                inputData: {},
                                varValue: {},
                                errorArray: {},
                                err: false,
                                errorMessages: {},
                            });


                        })
                        .catch(error => {
                            console.log(error);
                        });


                })
                .catch(error => {
                    console.log(error)
                });
        }


    };

    clintFunction = (event) => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        this.state.inputData.showCustName = false;
        let card = [];
        let clintUrl = backEndServerURL + "/intgr/cardListForCheque/" + this.state.inputData.clintId;
        axios.get(clintUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                this.state.inputData.clientCardDetailsList = response.data.inactiveCardList;
                this.state.inputData.customerName = response.data.clientName;
                this.state.varValue.customerName = response.data.clientName;
                response.data.clientInfoChequeBookList.map((item) => {
                    card.push({
                        key: item.cardNoOriginal,
                        value: item.cardNoMasked,
                        cardProductName: item.cardType,
                        cardChequeType: item.chequeType
                    })
                });
                for (let item of populateFormCard) {
                    if (item.varName === "cardNumber") {
                        item.enum = card
                    }
                }
                setTimeout(() => {
                    this.state.inputData.showCustName = true;
                    this.setState({
                        showValue: true,
                        loading: false,
                    });
                }, 2000)
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false,
                    cbNotFound: true,
                    title: 'Client Id Not Found'
                });
            });
    };

    renderCreditCardOne = () => {
        if (this.state.requestType === "card chequebook") {
            return (
                <>
                    <Grid item xs={12}></Grid>
                    {CommonJsonFormComponent.renderJsonForm(
                        this.state,
                        populateFormCard,
                        this.updateComponent,
                        this.clintFunction,
                        this.functionForCardDetails
                    )}
                    <Grid item xs={12}></Grid>
                    {CommonJsonFormComponent.renderJsonForm(
                        this.state,
                        jsonForFile,
                        this.updateComponent,
                    )}
                </>
            )
        }
    };

    functionForCardDetails = (data) => {
        if (data.varName === "cardNumber") {
            this.setState({
                loading: true
            });
            this.state.inputData.showChequeType = false;
            this.state.inputData.showCustAddress = false;
            let clintUrl = backEndServerURL + "/intgr/chequeRequisitionType/" + this.state.inputData.cardNumber["key"] + "/" + this.state.inputData.clintId;
            axios.get(clintUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    this.state.inputData.chequeType = response.data.chequeRequisitionType;
                    this.state.varValue.chequeType = response.data.chequeRequisitionType;
                    this.state.inputData.customerAddress = response.data.address;
                    this.state.varValue.customerAddress = response.data.address;
                    this.state.inputData.cardProductName = this.state.inputData.cardNumber["cardProductName"];
                    this.state.varValue.cardProductName = this.state.inputData.cardNumber["cardProductName"];
                    this.state.inputData.cardChequeType = this.state.inputData.cardNumber["cardChequeType"];
                    this.state.varValue.cardChequeType = this.state.inputData.cardNumber["cardChequeType"];
                    setTimeout(() => {
                        if (!this.state.inputData.cardNumber["cardChequeType"]) {
                            this.setState({
                                cbNotFound: true,
                                title: "Card Cheque Type Not Found!"
                            })
                        }
                        this.state.inputData.showChequeType = true;
                        this.state.inputData.showCustAddress = true;
                        this.setState({
                            showValue: true,
                            loading: false,
                        });
                    }, 2000)
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    });
                });
        }
    };

    cardInformation = () => {
        this.setState(prev => ({
            cardModal: !prev.cardModal
        }))
    };


    renderButton = () => {
        const {classes} = this.props;
        if (this.state.inputData.source !== undefined) {
            return (
                <>

                    <Dialog
                        fullWidth="true"
                        maxWidth="md"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cardModal}>
                        <DialogContent className={classes.dialogPaper}>

                            <OtherCardDetails
                                clientCardDetailsList={this.state.inputData.clientCardDetailsList}
                                closeModal={this.cardInformation}
                            />
                        </DialogContent>
                    </Dialog>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <ThemeProvider theme={theme}>
                                {this.renderRemarks()}
                            </ThemeProvider>
                        </Grid>
                    </Grid>


                    <Grid item xs="3" style={{marginTop: "10px"}}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                marginRight: "10px",
                            }}
                            onClick={this.saveHandler}
                        >
                            Submit
                        </button>
                    </Grid>
                </>
            )
        }
    };

    signature = () => {
        if (this.state.getData) {
            return (
                <>
                    <SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
                                     classes={this.props}/>
                </>
            )
        }
    };


    renderChequebookGeData = () => {
        if (this.state.requestType && this.state.requestType === "chequebook" && this.state.searchData) {
            return (
                <React.Fragment>
                    <Grid container spacing={1} style={{marginTop: 10}}>
                        <ThemeProvider theme={theme}>
                            {CommonJsonFormComponent.renderJsonForm(
                                this.state,
                                populateForm,
                                this.updateComponent
                            )}
                        </ThemeProvider>
                    </Grid>

                    {this.signature()}

                    <Grid item xs="3" style={{marginTop: "10px"}}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                marginRight: "10px",
                            }}
                            onClick={this.saveHandler}
                        >
                            Submit
                        </button>
                    </Grid>
                </React.Fragment>
            )
        }
    };


    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >
                            FDD Payorder Requisition
                        </h4>
                    </CardHeader>
                    <CardBody>
                        {this.renderLoader()}
                        <Grid item xs={12}>
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {
                                        this.renderChequeBookType()
                                    }
                                    {/*  {
                                        this.renderAccountNumberSearch()
                                    }
                                    {
                                        this.renderCreditCardOne()
                                    }*/}
                                </ThemeProvider>
                            </Grid>
                        </Grid>
                        {/*   {
                            this.renderChequebookGeData()
                        }*/}
                        {
                            this.renderButton()
                        }
                        {
                            this.renderNotification()
                        }
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.cbNotFound}>
                            <DialogContent className={classes.dialogPaper}>
                                <CBNotFound
                                    closeModal={this.closeModalCBNotFound}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.reference}>
                            <DialogContent className={classes.dialogPaper}>
                                <ReferenceModal
                                    closeModal={this.closeModalCBNotFound}
                                    referenceTitle={this.state.referenceTitle}
                                />
                            </DialogContent>
                        </Dialog>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(DeliverableChequeBookFrom);
