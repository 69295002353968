import {CSJsonFormForDebitCard,} from "./WorkflowJsonForm";
import React, {Component} from "react";
import DataCaptureCS from "./DataCaptureCS";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";

class DebitCard extends Component {

    renderInboxCase = () => {
        return (<DataCaptureCS serviceType="DebitCard" subserviceType="DebitCardOpening" titleName="DebitCard Opening"
                               jsonForm={CSJsonFormForDebitCard}/>)
    };

    render() {


        return (
            <section>
                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >Debit Card Opening</h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    {this.renderInboxCase()}
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </section>
        );

    }

}

export default DebitCard;