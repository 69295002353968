import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import "../../Static/css/CommonJsonForm.css";


class AutoCompleteComponent {
    static onKeyDownChange(event, onKeyDownForDedup, field, state) {
        if (state.inputData[field.varName] && (state.inputData[field.varName]).trim().length > 1) {
            onKeyDownForDedup(event, field.varName);
        }
    }

    static handleChange(event, onKeyDownChange, option, state, update, field) {
        event.preventDefault();
        if (option !== null) {
            state.inputData[field.varName] = option;
            update();
        } else if (option === null) {
            state.inputData[field.varName] = option;
            update();
        }
    }

    static returnDefaultValue(field, state) {
        if (state.showValue) {
            if (state.varValue === undefined || state.varValue == null)
                return "";

            else{
                if(state.varValue[field.varName] === undefined || state.varValue[field.varName] == null || state.varValue[field.varName] === "undefined" || state.varValue[field.varName] == "null"){
                    return "";
                }
                else{
                    return state.varValue[field.varName];
                }

            }


        }
    }

    static asteriksFunction = (field) => {
        if (field.required === true && field.asteriks === undefined) {
            return (
                <Grid item>
                    <label className="input-label-common">{field.label} *</label>
                </Grid>
            )
        } else {
            return (
                <Grid item>
                    <label className="input-label-common">{field.label}</label>
                </Grid>
            )
        }
    };

    static select(state, update, field, onKeyDownChange) {
        let defaultProps = {
            options: field.enum,
            getOptionLabel: option => option,
        };
        let styles = {};
        if (field.readOnly === true) {
            const firstStyle = {
                marginRight: 20,
                background: '#f8f9fa'
            };
            styles = Object.assign(styles, firstStyle)
        } else {
            if(field.borderColorChange==="white"){
                const secondStyle = {
                    color: '#ffffff',
                    background: 'white'
                };
                styles = Object.assign(styles, secondStyle)
            }

            else{
                const firstStyle = {
                    marginRight: 20,
                };
                styles = Object.assign(styles, firstStyle)
            }

        }
        if (field.highlight === true) {
            const secondStyle = {
                color: '#ffffff',
                background: 'red'
            };
            styles = Object.assign(styles, secondStyle)
        }

        return (
            <React.Fragment>
                {
                    this.asteriksFunction(field)
                }
                <Grid item>
                    <Autocomplete
                        className={field.highlight === true ? "highLight-color" : ""}
                        onChange={(event, option) => this.handleChange(event, onKeyDownChange, option, state, update, field)}
                        helperText={state.errorMessages[field.varName]}
                        error={state.errorArray[field.varName]}
                        required={field.readOnly === true ? false : field.required}
                        {...defaultProps} disabled={field.readOnly}
                        defaultValue={this.returnDefaultValue(field, state)}
                        style={styles}
                        onBlur={(event, option) => {
                            if (option !== null) {
                                if (field.onKeyDown === true) {
                                    let data = {};
                                    data.varName = field.varName;
                                    data.request = field.request;
                                    onKeyDownChange(data);
                                }
                            } else if (option === null) {
                                if (field.onKeyDown === true) {
                                    let data = {};
                                    data.varName = field.varName;
                                    data.request = field.request;
                                    onKeyDownChange(data);
                                }
                                update();
                            }
                        }}

                        renderInput={(params) => <TextField {...params}
                                                            error={state.errorArray !== undefined && state.errorArray[field.varName]}
                                                            helperText={state.errorArray[field.varName] === true ? state.errorMessages[field.varName] : ""}
                                                            variant="outlined"
                                                            fullWidth/>}
                    />
                </Grid>
            </React.Fragment>
        )
    }
}

export default AutoCompleteComponent;