import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../Grid/GridItem.jsx";
import GridContainer from "../../Grid/GridContainer.jsx";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Grow from "@material-ui/core/Grow";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Table from "../../Table/Table";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import loader from "../../../Static/loader.gif";
import ErrorModal from "../CommonComponent/ErrorModal";

const jsonForm = [

    {
        varName: "status",
        type: "select",
        label: "Status",
        required: true,
        grid: 4,
        "enum": [
            "inArchive",
            "requisition",
            "sent",
            "received",
            "returned"
        ]
    },
    {
        type: "empty",
        grid: 12,
    },


];


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

function Transition(props) {
    return <Grow in={true} timeout="auto" {...props} />;
}


class ArchiveManagerStatusQuery extends Component {

    constructor(props) {
        super(props);
        this.state = {
            SelectedData: false,
            AssetManagerData: [[" ", " "]],
            csDeferalPage: "",
            values: [],
            appId: '',
            csDataCapture: '',
            message: "",
            appData: {},
            getData: false,
            getNewCase: false,
            varValue: [],
            caseId: "",
            title: "",
            notificationMessage: "",
            app_uid: "-1",
            alert: false,
            loading: false,
            redirectLogin: false,
            type: [],
            dueDate: '',
            inputData: {},
            fileUploadData: {},
            selectedDate: {},
            dropdownSearchData: {},

            debitCard: "",
            showValue: false,
            AddDeferal: false,
            getDeferalList: [],
            deferalNeeded: false,
            uploadModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            getInitiateData: false,

            IndividualDedupModal: false,
            JointDedupModal: false,
            individualDataSaveId: '',
            jointDataSaveId: '',
            companyDataSaveId: '',
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            errorModalBoolean: false,
            errorTittle: "",


        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    createTableData = (
        id,
        fileReferenceNo,
        loanType,
        applicantName,
        assetOpsNumber,
        accountNumber,
        closingDate,
        location,
        trunkOrBoxNumber,
        status,
        customerCB,
        loanAccounts,
        NOCStatus,
        fileDestroyStatus,
        docType,
        source,
        deferralDuration,
        deferralDetails,
        docTypeEntryCapacity,
        sourceEntryCapacity
    ) => {
        return [
            fileReferenceNo,
            loanType,
            applicantName,
            assetOpsNumber,
            accountNumber,
            closingDate,
            location,
            trunkOrBoxNumber,
            status,
            customerCB,
            loanAccounts,
            NOCStatus,
            fileDestroyStatus,
            docType,
            source,
            deferralDuration,
            deferralDetails,
            docTypeEntryCapacity,
            sourceEntryCapacity

            /*<button
              className="btn btn-outline-primary"
              style={{
                verticalAlign: "middle",
              }}
              onClick={() => this.editAssetManager(id)}
            >
              Edit
            </button>,*/
        ];
    };

    getSearchvalue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === 'undefined')
                delete clone[prop];
        return clone;
    };


    updateComponent = () => {
        this.forceUpdate();
    };
    handleChangeComments = () => {
        alert("Ok")
    };
    returnJsonForm = () => {
        return (
            CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent)
        )

    };


    handleSubmit = (event) => {
        event.preventDefault();
        console.log(this.state.inputData);
        let object = this.state.inputData;

        if (object.status !== undefined) {
            let url = backEndServerURL + "/archiveFile/showStatusArchiveManager/" + object.status;

            let getAllAssetManager = [];
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    },
                })
                .then((response) => {
                    console.log(response);
                    response.data.map((assetManager) => {
                        getAllAssetManager.push(
                            this.createTableData(
                                assetManager.id,
                                assetManager.fileReferenceNo,
                                assetManager.loanType,
                                assetManager.applicantName,
                                assetManager.assetOpsNumber,
                                assetManager.accountNumber,
                                assetManager.closingDate,
                                assetManager.location,
                                assetManager.trunkOrBoxNumber,
                                assetManager.status,
                                assetManager.customerCB,
                                assetManager.loanAccounts,
                                assetManager.NOCStatus,
                                assetManager.fileDestroyStatus,
                                assetManager.docType,
                                assetManager.source,
                                assetManager.deferralDuration,
                                assetManager.deferralDetails,
                                assetManager.docTypeEntryCapacity,
                                assetManager.sourceEntryCapacity
                            )
                        );
                    });
                    this.setState({
                        AssetManagerData: getAllAssetManager,
                        alert: true,
                    });
                    console.log("asset data " + this.state.AssetManagerData);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

    };

    renderTable = () => {
        if (this.state.alert) {
            const {classes} = this.props;
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>Archive Manager List</h4>
                        </CardHeader>
                        <CardBody>
                            <div>


                                <Table
                                    tableHeaderColor="primary"
                                    tableHead={[
                                        "File Reference No",
                                        "Loan Type",
                                        "Applicant Name",
                                        "Asset Ops Number",
                                        "Account Number",
                                        "Closing Date",
                                        "Location",
                                        "Trunk Or Box Number",
                                        "Status",
                                        "Customer CB",
                                        "Loan Accounts",
                                        "NOC Status",
                                        "File Destroy Status",
                                        "Docs Type",
                                        "Source",
                                        "Deferral Duration",
                                        "Deferral Details",
                                        "Doc Type Entry Capacity",
                                        "Source Entry Capacity",

                                    ]}
                                    tableData={this.state.AssetManagerData}
                                    tableAllign={[
                                        "left",
                                        "left",
                                        "left",
                                        "left",
                                        "left",
                                        "left",
                                        "left",
                                        "left",
                                        "left",
                                        "left",
                                        "left",
                                        "left",
                                        "left",
                                        "left",
                                        "left",
                                        "left",
                                        "left",
                                        "left",
                                        "left",
                                        "left",
                                    ]}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </div>
            )
        }
    };


    close = () => {
        this.props.closeModal();
    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };


    closeModal = () => {
        this.setState({
            getMappingAllImage: false,
            IndividualDedupModal: false,
            JointDedupModal: false,
            errorModalBoolean: false,

        })
    };
    closeUploadModal = (data) => {
        this.setState({
            uploadButtonClick: true,
            uploadModal: false,
            getMappingAllImage: false
        })
    };


    renderButton = () => {

        return (
            <div>
                <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={this.handleSubmit}
                >
                    Search
                </button>

            </div>
        )

    };

    render() {

        const {classes} = this.props;
        return (
            /*<Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    <h4 style={{color: "white"}} >
                        Query Archive Manager by Status</h4>
                </CardHeader>
                <CardBody>
                    <div>
                        <ThemeProvider theme={theme}>
                            <Grid container spacing={1}>

                                {this.returnJsonForm()}

                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.loading}>
                                    <DialogContent className={classes.dialogPaper}>

                                        <center>
                                            <img src={loader} alt=""/>
                                        </center>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="mD"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.errorModalBoolean}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ErrorModal title={this.state.errorTittle}
                                                    closeModal={this.closeModal}
                                                    subServiceType={this.props.subServiceType}
                                                    appId={this.props.appId}
                                        />

                                    </DialogContent>
                                </Dialog>



                                {this.renderButton()}

                                {this.renderTable()}

                            </Grid>
                        </ThemeProvider>
                    </div>
                </CardBody>
            </Card>*/
            <section>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >
                                    Query Archive Manager by Status</h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <ThemeProvider theme={theme}>
                                        <Grid container spacing={1}>

                                            {this.returnJsonForm()}

                                            <Dialog
                                                fullWidth="true"
                                                maxWidth="sm"
                                                className={classes.modal}
                                                classes={{paper: classes.dialogPaper}}
                                                open={this.state.loading}>
                                                <DialogContent className={classes.dialogPaper}>

                                                    <center>
                                                        <img src={loader} alt=""/>
                                                    </center>
                                                </DialogContent>
                                            </Dialog>
                                            <Dialog
                                                fullWidth="true"
                                                maxWidth="mD"
                                                className={classes.modal}
                                                classes={{paper: classes.dialogPaper}}
                                                open={this.state.errorModalBoolean}>
                                                <DialogContent className={classes.dialogPaper}>
                                                    <ErrorModal title={this.state.errorTittle}
                                                                closeModal={this.closeModal}
                                                                subServiceType={this.props.subServiceType}
                                                                appId={this.props.appId}
                                                    />

                                                </DialogContent>
                                            </Dialog>


                                            {this.renderButton()}

                                        </Grid>
                                    </ThemeProvider>
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>
                                {this.renderTable()}
                            </div>
                        </Card>
                    </GridItem>
                </GridContainer>
            </section>


        );
    }

}

export default withStyles(styles)(ArchiveManagerStatusQuery);

