import React, {Component} from "react";
import Grid from "@material-ui/core/Grid/index";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Card from "../../../Card/Card.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import axios from "axios/index";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import Notification from "../../../NotificationMessage/Notification";
import Table from "../../../Table/Table";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import MyValidation from "../../../JsonForm/MyValidation";
import CardHeader from "../../../Card/CardHeader";
import CloseIcon from "@material-ui/icons/Close";
import DynamicFileAttachment from "../Common/DynamicFileAttachment";
import ErrorModal from "../Common/ErrorModal";
import ConfirmAlert from "../../CommonComponent/ConfirmAlert";
import {LoanAccountChangeReadOnlyForm} from "../../WorkflowjsonFormForAssetOps";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let remarks = [
    {
        "varName": "checkerRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12,
    }];

class CheckerApproval extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: false,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            appId: 0,
            getMappingAllImage: false,
            fileUploadData: {},
            getImageLink: [],
            getImageBoolean: false,
            imageName: [],
            getData: false,
            getRemarks: [],
            loading: true,
            submitButtonClick: false,
            errorModal: false,
            submitFlag: "",
            confirmAlert: false,
            actionType: "",
            selectedDate: {},
        };

    }

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    componentDidMount() {
         let url = assetOpsBackEndServerURL + "/variables/" + this.props.appId;

        axios
            .get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let inputData= response.data;
                inputData.checkerRemarks=undefined
                this.setState({
                    varValue: this.copyJson(inputData),
                    inputData: this.copyJson(inputData),
                });

                let remarksArray = [];
                let getCommentsUrl = assetOpsBackEndServerURL + "/appRemarkGet/" + this.props.appId;
                axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        console.log(response.data);
                        response.data.map((data) => {

                            remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                        });
                        this.setState({
                            getRemarks: remarksArray,
                            getData: true,
                            showValue: true,
                            loading: false
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch(error => {
                console.log(error);
            });

        let imageUrl = assetOpsBackEndServerURL + "/case/files/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    getImageLink: response.data,
                    getImageBoolean: true,
                    imageName: response.data
                })
            })
            .catch((error) => {
                console.log(error);
            })

    }


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type="success"
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        });
    };

    renderDynamicFileUpload = () => {
        if (!this.state.loading) {
            return <DynamicFileAttachment appId={this.props.appId}
                                          submitButtonClick={this.state.submitButtonClick}
                                          functionFoFile={this.functionFoFile}/>
        }
    };


    functionFoFile = (status) => {
        this.setState({
            submitButtonClick: false,
            loading: true,
        });
        if (status === "success" || status === "No File Found") {
            let url = assetOpsBackEndServerURL + "/variables/" + this.props.appId;
            axios
                .post(url, {disbursement_checker_approved: this.state.submitFlag}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    let saveDataDeferralRemarksAndRoute={};
                    if( this.state.inputData.checkerRemarks!==undefined &&  this.state.inputData.checkerRemarks!==null){
                        saveDataDeferralRemarksAndRoute.remark = this.state.inputData.checkerRemarks;
                    }
                    let caseRouteUrl = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
                    axios
                        .post(caseRouteUrl, saveDataDeferralRemarksAndRoute,{headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then(response => {
                            this.setState({
                                alert: true,
                                loading: false,
                                title: "Successful!",
                                notificationMessage: response.data,
                            });
                            this.props.closeModal()

                        })
                        .catch(error => {
                            console.log(error);
                            this.setState({
                                loading: false,
                            })
                        });

                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        loading: false,
                    })
                });

        } else {
            this.setState({
                errorModal: true,
                loading: false,
                title: "Failed to Upload File."
            })
        }
    };

    closeErrorModal = () => {
        this.setState({
            errorModal: false
        })
    };


    approveHandler = () => {
        this.setState({
            submitFlag: "YES",
            submitButtonClick: true
        });
    };

    returnHandler = () => {
        this.setState({
            submitFlag: "NO",
            submitButtonClick: true
        })

    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, remarks, this.updateComponent)
    };


    renderRemarksData = () => {

        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{width: "100%"}}>
                    <br/>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                    </div>

                </div>

            )
        }

    };

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <a
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: "middle",
                                display: "block",
                                marginTop: 20,
                            }}
                            target="_blank"
                            href={assetOpsBackEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                        >
                            {splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </a>
                        &nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };
    closeConfirmAlert = (data, appId) => {
        this.setState({
            confirmAlert: false,
        });

        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "approveHandler") {

            this.approveHandler()
        } else if (data === "YES" && this.state.actionType === "returnHandler") {

            this.returnHandler()
        }
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        return clone;
    };
    validationForHandleConfirm = (actionType) => {
        let error = MyValidation.defaultValidation(remarks, this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        }
        this.setState({
            appId: this.state.appId,
            confirmAlert: false,
        });
        if (actionType === "approveHandler") {
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })


        } else if (actionType === "returnHandler") {
            let dependencyField = [];
            dependencyField.push({
                "varName": "checkerRemarks",
                "type": "textArea",
                "required": true,
                "label": "Remarks",
                "grid": 12
            })
            let error = MyValidation.defaultValidation(dependencyField, this.state);
            this.forceUpdate();

            if (error === true) {
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })


        }


    };
    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()

        {
            this.validationForHandleConfirm(type)
        }


    };
    renderSubmitButton = () => {
        if (this.state.getData && this.state.loading === false) {
            return (
                <React.Fragment>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            marginRight: "10px",
                        }}
                        // onClick={this.approveHandler}
                        onClick={(event) => this.handleCommon(event, "approveHandler")}

                    >
                        Submit
                    </button>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        //onClick={this.returnHandler}
                        onClick={(event) => this.handleCommon(event, "returnHandler")}

                    >
                        Return
                    </button>
                </React.Fragment>
            )
        }
    }
    renderJsonform = () => {
        if (this.state.getData && this.state.loading === false) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, LoanAccountChangeReadOnlyForm, this.updateComponent)

            )
        }

    }

    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b>  Loan Account Change</b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.state.inputData.customerName !== undefined ? this.state.inputData.customerName : ""}
                    <b> A/C:</b>{this.state.inputData.accountNumber !== undefined ? this.state.inputData.accountNumber : ""}
                    <b> CB Number:</b>{this.state.inputData.cbNumber !== undefined ? this.state.inputData.cbNumber : ""}
                    <b> SOLID:</b>{this.props.solId !== undefined ? this.props.solId : ""}  <b> Case
                        ID:</b>{this.props.caseId !== undefined ? this.props.caseId  : ""} )<a><CloseIcon
                        onClick={this.props.closeModal} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Loan Account Change
                </h4>
            )
        }
    };

    renderFlowSUmmeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                   caseId={this.props.caseId}
                                   customerName={this.state.inputData.customerName} accountNumber={this.state.inputData.accountNumber}
                                   serviceType={this.state.inputData.serviceType}
                                   category={this.state.inputData.category}
                                   subCategory={this.state.inputData.subCategory}
                                   solId={this.props.solId}/>
            )
        }
    };

    render() {
        const {classes, onClose} = this.props;

        return (
            <div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {
                            this.renderHeader()
                        }
                    </CardHeader>
                    <CardBody>
                        <Grid container spacing={0}>
                            <ThemeProvider theme={theme}>
                                {this.renderJsonform()}
                                <Grid item xs={12}><br/></Grid>
                                {this.mappingPhoto()}
                                <Grid item xs={12}><br/></Grid>
                                {this.renderDynamicFileUpload()}
                                <Grid item xs={12}><br/></Grid>
                                {this.renderFlowSUmmeryButton()}
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.errorModal}>
                                    <DialogContent className={classes.dialogPaper}>

                                        <ErrorModal
                                            closeModal={this.closeErrorModal}
                                            title={this.state.title}
                                        />
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.loading}>
                                    <DialogContent className={classes.dialogPaper}>

                                        <center>
                                            <img src={loader} alt=""/>
                                        </center>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.confirmAlert}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ConfirmAlert validationForHandleConfirm={this.validationForHandleConfirm}
                                                      closeModal={this.closeConfirmAlert}
                                                      asset={true}


                                        />

                                    </DialogContent>
                                </Dialog>
                                {/*<a
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: "middle",
                                        display: "block",
                                        marginTop: 20,
                                    }}
                                    target="_blank"
                                    href={EMIFile}
                                >
                                    Print Notification Letter
                                </a>*/}
                            </ThemeProvider>
                            <Grid container spacing={0}>
                                <ThemeProvider theme={theme}>
                                    {
                                        this.renderRemarksData()
                                    }
                                    <Grid item xs={12}></Grid>
                                    {this.renderRemarks()}
                                </ThemeProvider>
                            </Grid>
                        </Grid>

                        <Grid item xs="3" style={{marginTop: "20px"}}>
                            {/*<center>*/}
                            {this.renderSubmitButton()}
                        </Grid>
                        {
                            this.renderNotification()
                        }
                    </CardBody>
                </Card>
            </div>
        );

    }

}

export default withStyles(styles)(CheckerApproval);
