//##########MainTenance##############
//CS Maintenance With Print File
function makeReadOnly(object) {
    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject;

    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["readOnly"] = true;
    }
    return returnObject;
}

function makeReadOnlyObject(object) {
    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject;

    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["readOnly"] = true;
    }
    return returnObject;
}

let csSendTo = {
    "varName": "cs_send_to",
    "type": "select",
    "label": "Send to?",
    "enum": [
        "BOM",
        "BM"
    ],
};
let bomSendTo = {
    "varName": "bom_send_to",
    "type": "select",
    "label": "Send to?",

    "enum": [
        "CS",
        "BM"
    ]
};
let bomApproval = {
    "varName": "bom_approval",
    "type": "select",
    "label": "Approve?",

    "enum": [
        "APPROVED",
        "NOTAPPROVED"
    ]
};
let bmSendTo = {
    "varName": "bm_send_to",
    "type": "select",
    "label": "Send to?",

    "enum": [
        "BOM",
        "CS"
    ]
};
let bmApprove = {
    "varName": "bm_approval",
    "type": "select",
    "label": "Approve?",

    "enum": [
        "APPROVED",
        "NOTAPPROVED"
    ]
};

let makerApprove = {
    "varName": "maker_approval",
    "type": "select",
    "label": "Approve?",

    "enum": [
        "APPROVED",
        "NOTAPPROVED"
    ]
};
let checkerApprove = {
    "varName": "checker_approval",
    "type": "select",
    "label": "Approve?",

    "enum": [
        "APPROVED",
        "NOTAPPROVED"
    ]
};
let JsonFormMaintenanceWithPrintFileAddress = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldAddress",
            "type": "text",
            "label": "Old Address",
        },
        {
            "varName": "newAddress",
            "type": "text",
            "label": "New Address",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }
    ],

};
let JsonFormMaintenanceWithPrintFileContact = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldContact",
            "type": "text",
            "label": "Old Contact",
        },
        {
            "varName": "newContact",
            "type": "text",
            "label": "New Contact",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }

    ],

};
let JsonFormMaintenanceWithPrintFileEmail = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldEmail",
            "type": "text",
            "label": "Old Email",
        },
        {
            "varName": "newEmail",
            "type": "text",
            "label": "New Email",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }


    ],

};
let JsonFormMaintenanceWithPrintFileNominee = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldNominee",
            "type": "text",
            "label": "Old Nominee",
        },
        {
            "varName": "newNominee",
            "type": "text",
            "label": "New Nominee",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }


    ],

};
let JsonFormMaintenanceWithPrintFileTenor = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldTenor",
            "type": "text",
            "label": "Old Tenor",
        },
        {
            "varName": "newTenor",
            "type": "text",
            "label": "New Tenor",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }

    ],

};
let JsonFormMaintenanceWithPrintFileScheme = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldScheme",
            "type": "text",
            "label": "Old Scheme",
        },
        {
            "varName": "newScheme",
            "type": "text",
            "label": "New Scheme",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }


    ],

};
let JsonFormMaintenanceWithPrintFileMaturity = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldMaturity",
            "type": "text",
            "label": "Old Maturity",
        },
        {
            "varName": "newMaturity",
            "type": "text",
            "label": "New Maturity",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }


    ],

};
let JsonFormMaintenanceWithPrintFileTitle = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldTitle",
            "type": "text",
            "label": "Old Title",
        },
        {
            "varName": "newTitle",
            "type": "text",
            "label": "New Title",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }


    ],

};
let JsonFormMaintenanceWithPrintFileLink = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldLink",
            "type": "text",
            "label": "Old Link",
        },
        {
            "varName": "newLink",
            "type": "text",
            "label": "New Link",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }


    ],

};
let JsonFormMaintenanceWithPrintFileEtin = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldEtin",
            "type": "text",
            "label": "Old Etin",
        },
        {
            "varName": "newEtin",
            "type": "text",
            "label": "New Etin",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }

    ]

};
let JsonFormMaintenanceWithPrintFileDormant = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldDormant",
            "type": "text",
            "label": "Old Dormant",
        },
        {
            "varName": "newDormant",
            "type": "text",
            "label": "New Dormant",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }

    ],

};
let JsonFormMaintenanceWithPrintFileInputFiled = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldInputFiled",
            "type": "text",
            "label": "Old InputFiled",
        },
        {
            "varName": "newInputFiled",
            "type": "text",
            "label": "New InputFiled",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }


    ],

};
let JsonFormMaintenanceWithFiles = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }


    ],

};


//CS Maintenance with print file
let CSJsonFormMaintenanceWithPrintFileAddress = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileAddress));
CSJsonFormMaintenanceWithPrintFileAddress.variables.push(csSendTo);
let CSJsonFormMaintenanceWithPrintFileContact = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileContact));
CSJsonFormMaintenanceWithPrintFileContact.variables.push(csSendTo);
let CSJsonFormMaintenanceWithPrintFileEmail = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileEmail));
CSJsonFormMaintenanceWithPrintFileEmail.variables.push(csSendTo);
let CSJsonFormMaintenanceWithPrintFileNominee = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileNominee));
CSJsonFormMaintenanceWithPrintFileNominee.variables.push(csSendTo);
let CSJsonFormMaintenanceWithPrintFileTenor = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileTenor));
CSJsonFormMaintenanceWithPrintFileTenor.variables.push(csSendTo);
let CSJsonFormMaintenanceWithPrintFileScheme = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileScheme));
CSJsonFormMaintenanceWithPrintFileScheme.variables.push(csSendTo);
let CSJsonFormMaintenanceWithPrintFileMaturity = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileMaturity));
CSJsonFormMaintenanceWithPrintFileMaturity.variables.push(csSendTo);
let CSJsonFormMaintenanceWithPrintFileTitle = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileTitle));
CSJsonFormMaintenanceWithPrintFileTitle.variables.push(csSendTo);
let CSJsonFormMaintenanceWithPrintFileLink = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileLink));
CSJsonFormMaintenanceWithPrintFileLink.variables.push(csSendTo);
let CSJsonFormMaintenanceWithPrintFileEtin = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileEtin));
CSJsonFormMaintenanceWithPrintFileEtin.variables.push(csSendTo);
let CSJsonFormMaintenanceWithPrintFileDormant = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileDormant));
CSJsonFormMaintenanceWithPrintFileDormant.variables.push(csSendTo);
let CSJsonFormMaintenanceWithPrintFileInputFiled = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileInputFiled));
CSJsonFormMaintenanceWithPrintFileInputFiled.variables.push(csSendTo);

//CS Maintenance With File
let CSJsonFormMaintenanceWithFile = JSON.parse(JSON.stringify(JsonFormMaintenanceWithFiles));
CSJsonFormMaintenanceWithFile.variables.push(csSendTo);

//BOM Maintenance With Print File
let BOMJsonFormMaintenanceWithPrintFileAddress = makeReadOnly(JsonFormMaintenanceWithPrintFileAddress);
BOMJsonFormMaintenanceWithPrintFileAddress.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileAddress.variables.push(bomSendTo);
let BOMJsonFormMaintenanceWithPrintFileContact = makeReadOnly(JsonFormMaintenanceWithPrintFileContact);
BOMJsonFormMaintenanceWithPrintFileContact.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileContact.variables.push(bomSendTo);
let BOMJsonFormMaintenanceWithPrintFileEmail = makeReadOnly(JsonFormMaintenanceWithPrintFileEmail);
BOMJsonFormMaintenanceWithPrintFileEmail.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileEmail.variables.push(bomSendTo);
let BOMJsonFormMaintenanceWithPrintFileNominee = makeReadOnly(JsonFormMaintenanceWithPrintFileNominee);
BOMJsonFormMaintenanceWithPrintFileNominee.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileNominee.variables.push(bomSendTo);
let BOMJsonFormMaintenanceWithPrintFileTenor = makeReadOnly(JsonFormMaintenanceWithPrintFileTenor);
BOMJsonFormMaintenanceWithPrintFileTenor.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileTenor.variables.push(bomSendTo);
let BOMJsonFormMaintenanceWithPrintFileScheme = makeReadOnly(JsonFormMaintenanceWithPrintFileScheme);
BOMJsonFormMaintenanceWithPrintFileScheme.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileScheme.variables.push(bomSendTo);
let BOMJsonFormMaintenanceWithPrintFileMaturity = makeReadOnly(JsonFormMaintenanceWithPrintFileMaturity);
BOMJsonFormMaintenanceWithPrintFileMaturity.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileMaturity.variables.push(bomSendTo);
let BOMJsonFormMaintenanceWithPrintFileTitle = makeReadOnly(JsonFormMaintenanceWithPrintFileTitle);
BOMJsonFormMaintenanceWithPrintFileTitle.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileTitle.variables.push(bomSendTo);
let BOMJsonFormMaintenanceWithPrintFileLink = makeReadOnly(JsonFormMaintenanceWithPrintFileLink);
BOMJsonFormMaintenanceWithPrintFileLink.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileLink.variables.push(bomSendTo);
let BOMJsonFormMaintenanceWithPrintFileEtin = makeReadOnly(JsonFormMaintenanceWithPrintFileEtin);
BOMJsonFormMaintenanceWithPrintFileEtin.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileEtin.variables.push(bomSendTo);
let BOMJsonFormMaintenanceWithPrintFileDormant = makeReadOnly(JsonFormMaintenanceWithPrintFileDormant);
BOMJsonFormMaintenanceWithPrintFileDormant.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileDormant.variables.push(bomSendTo);
let BOMJsonFormMaintenanceWithPrintFileInputFiled = makeReadOnly(JsonFormMaintenanceWithPrintFileInputFiled);
BOMJsonFormMaintenanceWithPrintFileInputFiled.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileInputFiled.variables.push(bomSendTo);
//BOM Maintenance With File
let BOMJsonFormMaintenanceWithFile = JSON.parse(JSON.stringify(JsonFormMaintenanceWithFiles));
BOMJsonFormMaintenanceWithFile.variables.push(bomApproval);
BOMJsonFormMaintenanceWithFile.variables.push(bomSendTo);


//Maker Maintenance With Print File
let MAKERJsonFormMaintenanceWithPrintFileAddress = makeReadOnly(JsonFormMaintenanceWithPrintFileAddress);
let MAKERJsonFormMaintenanceWithPrintFileContact = makeReadOnly(JsonFormMaintenanceWithPrintFileContact);
let MAKERJsonFormMaintenanceWithPrintFileEmail = makeReadOnly(JsonFormMaintenanceWithPrintFileEmail);
let MAKERJsonFormMaintenanceWithPrintFileNominee = makeReadOnly(JsonFormMaintenanceWithPrintFileNominee);
let MAKERJsonFormMaintenanceWithPrintFileTenor = makeReadOnly(JsonFormMaintenanceWithPrintFileTenor);
let MAKERJsonFormMaintenanceWithPrintFileScheme = makeReadOnly(JsonFormMaintenanceWithPrintFileScheme);
let MAKERJsonFormMaintenanceWithPrintFileMaturity = makeReadOnly(JsonFormMaintenanceWithPrintFileMaturity);
let MAKERJsonFormMaintenanceWithPrintFileTitle = makeReadOnly(JsonFormMaintenanceWithPrintFileTitle);
let MAKERJsonFormMaintenanceWithPrintFileLink = makeReadOnly(JsonFormMaintenanceWithPrintFileLink);
let MAKERJsonFormMaintenanceWithPrintFileEtin = makeReadOnly(JsonFormMaintenanceWithPrintFileEtin);
let MAKERJsonFormMaintenanceWithPrintFileDormant = makeReadOnly(JsonFormMaintenanceWithPrintFileDormant);
let MAKERJsonFormMaintenanceWithPrintFileInputFiled = makeReadOnly(JsonFormMaintenanceWithPrintFileInputFiled);
//Maker Maintenance With File
let MAKERJsonFormMaintenanceWithFile = JSON.parse(JSON.stringify(JsonFormMaintenanceWithFiles));


//Checker Maintenance With Print File
let CHECKERJsonFormMaintenanceWithPrintFileAddress = makeReadOnly(JsonFormMaintenanceWithPrintFileAddress);
CHECKERJsonFormMaintenanceWithPrintFileAddress.variables.push(checkerApprove);

let CHECKERJsonFormMaintenanceWithPrintFileContact = makeReadOnly(JsonFormMaintenanceWithPrintFileContact);
CHECKERJsonFormMaintenanceWithPrintFileContact.variables.push(checkerApprove);

let CHECKERJsonFormMaintenanceWithPrintFileEmail = makeReadOnly(JsonFormMaintenanceWithPrintFileEmail);
CHECKERJsonFormMaintenanceWithPrintFileEmail.variables.push(checkerApprove);

let CHECKERJsonFormMaintenanceWithPrintFileNominee = makeReadOnly(JsonFormMaintenanceWithPrintFileNominee);
CHECKERJsonFormMaintenanceWithPrintFileNominee.variables.push(checkerApprove);

let CHECKERJsonFormMaintenanceWithPrintFileTenor = makeReadOnly(JsonFormMaintenanceWithPrintFileTenor);
CHECKERJsonFormMaintenanceWithPrintFileTenor.variables.push(checkerApprove);

let CHECKERJsonFormMaintenanceWithPrintFileScheme = makeReadOnly(JsonFormMaintenanceWithPrintFileScheme);
CHECKERJsonFormMaintenanceWithPrintFileScheme.variables.push(checkerApprove);

let CHECKERJsonFormMaintenanceWithPrintFileMaturity = makeReadOnly(JsonFormMaintenanceWithPrintFileMaturity);
CHECKERJsonFormMaintenanceWithPrintFileMaturity.variables.push(checkerApprove);

let CHECKERJsonFormMaintenanceWithPrintFileTitle = makeReadOnly(JsonFormMaintenanceWithPrintFileTitle);
CHECKERJsonFormMaintenanceWithPrintFileTitle.variables.push(checkerApprove);

let CHECKERJsonFormMaintenanceWithPrintFileLink = makeReadOnly(JsonFormMaintenanceWithPrintFileLink);
CHECKERJsonFormMaintenanceWithPrintFileLink.variables.push(checkerApprove);

let CHECKERJsonFormMaintenanceWithPrintFileEtin = makeReadOnly(JsonFormMaintenanceWithPrintFileEtin);
CHECKERJsonFormMaintenanceWithPrintFileEtin.variables.push(checkerApprove);

let CHECKERJsonFormMaintenanceWithPrintFileDormant = makeReadOnly(JsonFormMaintenanceWithPrintFileDormant);
CHECKERJsonFormMaintenanceWithPrintFileDormant.variables.push(checkerApprove);

let CHECKERJsonFormMaintenanceWithPrintFileInputFiled = makeReadOnly(JsonFormMaintenanceWithPrintFileInputFiled);
CHECKERJsonFormMaintenanceWithPrintFileInputFiled.variables.push(checkerApprove);

//CHECKER Maintenance With File
let CHECKERJsonFormMaintenanceWithFile = JSON.parse(JSON.stringify(JsonFormMaintenanceWithFiles));
CHECKERJsonFormMaintenanceWithFile.variables.push(checkerApprove);


//BM  Maintenance With print File
let BMfromCSJsonFormMaintenanceWithPrintFileAddress = makeReadOnly(JsonFormMaintenanceWithPrintFileAddress);
BMfromCSJsonFormMaintenanceWithPrintFileAddress.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileAddress = makeReadOnly(JsonFormMaintenanceWithPrintFileAddress);
BMfromBOMJsonFormMaintenanceWithPrintFileAddress.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileAddress.variables.push(bmSendTo);
let BMfromCSJsonFormMaintenanceWithPrintFileContact = makeReadOnly(JsonFormMaintenanceWithPrintFileContact);
BMfromCSJsonFormMaintenanceWithPrintFileContact.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileContact = makeReadOnly(JsonFormMaintenanceWithPrintFileContact);
BMfromBOMJsonFormMaintenanceWithPrintFileContact.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileContact.variables.push(bmSendTo);
let BMfromCSJsonFormMaintenanceWithPrintFileEmail = makeReadOnly(JsonFormMaintenanceWithPrintFileEmail);
BMfromCSJsonFormMaintenanceWithPrintFileEmail.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileEmail = makeReadOnly(JsonFormMaintenanceWithPrintFileEmail);
BMfromBOMJsonFormMaintenanceWithPrintFileEmail.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileEmail.variables.push(bmSendTo);
let BMfromCSJsonFormMaintenanceWithPrintFileNominee = makeReadOnly(JsonFormMaintenanceWithPrintFileNominee);
BMfromCSJsonFormMaintenanceWithPrintFileNominee.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileNominee = makeReadOnly(JsonFormMaintenanceWithPrintFileNominee);
BMfromBOMJsonFormMaintenanceWithPrintFileNominee.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileNominee.variables.push(bmSendTo);
let BMfromCSJsonFormMaintenanceWithPrintFileTenor = makeReadOnly(JsonFormMaintenanceWithPrintFileTenor);
BMfromCSJsonFormMaintenanceWithPrintFileTenor.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileTenor = makeReadOnly(JsonFormMaintenanceWithPrintFileTenor);
BMfromBOMJsonFormMaintenanceWithPrintFileTenor.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileTenor.variables.push(bmSendTo);
let BMfromCSJsonFormMaintenanceWithPrintFileScheme = makeReadOnly(JsonFormMaintenanceWithPrintFileScheme);
BMfromCSJsonFormMaintenanceWithPrintFileScheme.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileScheme = makeReadOnly(JsonFormMaintenanceWithPrintFileScheme);
BMfromBOMJsonFormMaintenanceWithPrintFileScheme.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileScheme.variables.push(bmSendTo);
let BMfromCSJsonFormMaintenanceWithPrintFileMaturity = makeReadOnly(JsonFormMaintenanceWithPrintFileMaturity);
BMfromCSJsonFormMaintenanceWithPrintFileMaturity.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileMaturity = makeReadOnly(JsonFormMaintenanceWithPrintFileMaturity);
BMfromBOMJsonFormMaintenanceWithPrintFileMaturity.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileMaturity.variables.push(bmSendTo);
let BMfromCSJsonFormMaintenanceWithPrintFileTitle = makeReadOnly(JsonFormMaintenanceWithPrintFileTitle);
BMfromCSJsonFormMaintenanceWithPrintFileTitle.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileTitle = makeReadOnly(JsonFormMaintenanceWithPrintFileTitle);
BMfromBOMJsonFormMaintenanceWithPrintFileTitle.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileTitle.variables.push(bmSendTo);
let BMfromCSJsonFormMaintenanceWithPrintFileLink = makeReadOnly(JsonFormMaintenanceWithPrintFileLink);
BMfromCSJsonFormMaintenanceWithPrintFileLink.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileLink = makeReadOnly(JsonFormMaintenanceWithPrintFileLink);
BMfromBOMJsonFormMaintenanceWithPrintFileLink.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileLink.variables.push(bmSendTo);
let BMfromCSJsonFormMaintenanceWithPrintFileEtin = makeReadOnly(JsonFormMaintenanceWithPrintFileEtin);
BMfromCSJsonFormMaintenanceWithPrintFileEtin.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileEtin = makeReadOnly(JsonFormMaintenanceWithPrintFileEtin);
BMfromBOMJsonFormMaintenanceWithPrintFileEtin.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileEtin.variables.push(bmSendTo);
let BMfromCSJsonFormMaintenanceWithPrintFileDormant = makeReadOnly(JsonFormMaintenanceWithPrintFileDormant);
BMfromCSJsonFormMaintenanceWithPrintFileDormant.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileDormant = makeReadOnly(JsonFormMaintenanceWithPrintFileDormant);
BMfromBOMJsonFormMaintenanceWithPrintFileDormant.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileDormant.variables.push(bmSendTo);
let BMfromCSJsonFormMaintenanceWithPrintFileInputFiled = makeReadOnly(JsonFormMaintenanceWithPrintFileInputFiled);
BMfromCSJsonFormMaintenanceWithPrintFileInputFiled.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileInputFiled = makeReadOnly(JsonFormMaintenanceWithPrintFileInputFiled);
BMfromBOMJsonFormMaintenanceWithPrintFileInputFiled.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileInputFiled.variables.push(bmSendTo);

//BM Maintenance With File
let BMfromCSJsonFormMaintenanceWithFile = JSON.parse(JSON.stringify(JsonFormMaintenanceWithFiles));
BMfromCSJsonFormMaintenanceWithFile.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithFile = JsonFormMaintenanceWithFiles;
BMfromBOMJsonFormMaintenanceWithFile.variables.push(bmSendTo);
BMfromBOMJsonFormMaintenanceWithFile.variables.push(bmApprove);

//######Existing Account Opening individual#########

let CommonExistJsonFormIndividualAccountOpening = [


    {
        "varName": "customerNameOld",
        "type": "text",
        "label": "Customer Name(Old)",
        "readOnly": true,
        "grid": 6
    },


    {
        "varName": "nidOld",
        "type": "text",
        "label": "NID No(Old)",
        "readOnly": true,
        "grid": 6
    },

    {
        "varName": "passportOld",
        "type": "text",
        "label": "Passport Number(Old)",
        "readOnly": true,
        "grid": 6
    },

    {
        "varName": "phoneOld",
        "type": "text",
        "label": "Mobile Number(Old)",
        "readOnly": true,
        "grid": 6
    },

    {
        "varName": "birthCertificateOld",
        "type": "text",
        "label": "Birth Certificate(Old)",
        "readOnly": true,
        "grid": 6
    },

    {
        "varName": "drivingLicenseOld",
        "type": "text",
        "label": "Driving License(Old)",
        "readOnly": true,
        "grid": 6
    },

    {
        "varName": "emailOld",
        "type": "text",
        "label": "Email(Old)",
        "readOnly": true,
        "grid": 6
    },

    {
        "varName": "comAddressOld",
        "type": "text",
        "label": "Communication Address(Old)",
        "readOnly": true,
        "grid": 6
    },


    {
        "varName": "permanentAddressOld",
        "type": "text",
        "label": "Permanent Address(Old)",
        "readOnly": true,
        "grid": 6
    },

    {
        "varName": "permanentAddress",
        "type": "title",
        "label": "",
        "grid": 12
    },
    {
        "varName": "rmCode",
        "type": "text",
        "label": "RM Code",
        "grid": 6
    },
    {
        "varName": "introducerCB",
        "type": "text",
        "label": "Introducer CB",
        "grid": 6
    }

];

let commonJsonFormIndividualAccountOpeningForm = [
    {
        "varName": "productCode",
        "type": "text",
        "label": "Product Code",
        "grid": 6

    },

    /*{
        "varName": "accountType",
        "type": "select",
        "label": "Account Type",
        "enum": [
            "Instrapack",
            "NonInstrapack"
        ]
    },*/
    {
        "varName": "sbsCode",
        "type": "select",
        "label": "SBS Code",
        "grid": 6,
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "debitCard",
        "type": "select",
        "label": "Debit Card",
        "grid": 6,
        "enum": [
            "YES",
            "NO"
        ]
    },

    {
        "varName": "cityTouchID",
        "type": "select",
        "label": "City Touch ID Request",
        "grid": 6,
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "lockerService",
        "type": "select",
        "label": "Locker Service Request",
        "grid": 6,
        "enum": [
            "YES",
            "NO"
        ]
    }
];
let CSExistJsonFormIndividualAccountOpening = {};
CSExistJsonFormIndividualAccountOpening = JSON.parse(JSON.stringify(CommonExistJsonFormIndividualAccountOpening));

let CommonCasaAccountOpening = [

    {
        "varName": "comAddress",
        "type": "text",
        "label": "Communication Address",
        "grid": 4,
    },
    {
        "varName": "schemeCode",
        "type": "text",
        "label": "Scheme Code",
        "grid": 4,
    },
    {
        "varName": "currency",
        "type": "select",
        "label": "Currency",
        "grid": 4,
        "enum": [
            "BDT",
            "USD",
            "EUR",
            "GBP"
        ]
    },
    {
        "varName": "rmCode",
        "type": "text",
        "label": "RM Code",
        "grid": 4,
    },
    {
        "varName": "sbsCode",
        "type": "text",
        "label": "SBS Code",
        "grid": 4,
    },
    /*{
        "varName": "occupationCode",
        "type": "text",
        "label": "Occupation Code",
        "grid": 6,

    },*/
    {
        "varName": "ccepCompanyCode",
        "type": "text",
        "label": "CCEP Company Code",
        "grid": 4,

    },
    {
        "varName": "priority",
        "type": "select",
        "label": "Priority",
        "enum": [
            "GENERAL",
            "HIGH",
        ],
        "grid": 4,
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Add Service",
        "grid": 12

    },
    {
        "varName": "chequeBookRequest",
        "type": "checkbox",
        "label": "Cheque Book Request",
        "grid": 12,

    },
    {

        "varName": "pageOfChequeBook",
        "type": "select",
        "label": "Page Of Cheque Book",
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
        "grid": 4,
        "enum": [
            "25",
            "50",
            "100"
        ],

    },
    {
        "varName": "currency",
        "type": "select",
        "label": "Currency",
        "enum": [
            "BDT",
            "USD",
            "EUR",
            "GBP"
        ],
        "grid": 4,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,

    },
    {
        "varName": "deliveryType",
        "type": "select",
        "label": "Delivery Type",
        "enum": [
            "Courier",
            "Branch"
        ],
        "grid": 4,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,

    },

    {
        "varName": "chequeBookDesign",
        "type": "select",
        "label": "Cheque Book Design",
        "enum": [
            "Sapphire",
            "Citygem",
            "City Alo",
            "Other"
        ],
        "grid": 4,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,

    },
//Debit Card
    {
        "varName": "debitCard",
        "type": "checkbox",
        "label": "Debit Card",
        "grid": 12
    },
    {
        "varName": "accountsType",
        "type": "text",
        "label": "Account Type",
        "enum": [
            "SAVINGS",
            "CURRENT",
            "FCY"
        ],

        "grid": 4,
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "currency",
        "type": "select",
        "label": "Currency",
        "enum": [
            "BDT",
            "USD",
            "EUR",
            "GBP"
        ],
        "grid": 4,
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "debitCardText",
        "type": "text",
        "label": "Name On Card",
        "grid": 4,
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,

    },

    {
        "varName": "cardType",
        "type": "select",
        "label": "Card Type",
        "grid": 4,
        "enum": [
            "VISA",
            "MASTER",
            "CITYMAXX",
        ],
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "productType",
        "type": "text",
        "label": "Product Type",
        "grid": 4,
        "enum": [
            "#",
            "#",

        ],
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "selectCcep",
        "type": "text",
        "label": "Select CCEP",
        "grid": 4,
        "enum": [
            "#",
            "#",

        ],
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,

    },

    {
        "varName": "deliveryType",
        "type": "select",
        "label": "Delivery Type",
        "enum": [
            "Courier",
            "Branch"
        ],
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "smsAlertRequest",
        "type": "checkbox",
        "label": "SMS Alert Request",
        "grid": 12

    },
    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile Number",
        "grid": 4,
        "conditional": true,
        "conditionalVarName": "smsAlertRequest",
        "conditionalVarValue": true,

    },

    {
        "varName": "callCenterRegistration",
        "type": "checkbox",
        "label": "Call Center Registration",
        "grid": 12

    },

    {
        "varName": "cityTouchRequest",
        "type": "checkbox",
        "label": "City Touch",
        "grid": 12

    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email ",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "cityTouchRequest",
        "conditionalVarValue": true,

    },
    {
        "varName": "cityTouchCustomerId",
        "type": "text",
        "label": "Customer Id ",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "cityTouchRequest",
        "conditionalVarValue": true,

    },
    {
        "varName": "cityTouchCbNumber",
        "type": "text",
        "label": "CB Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "cityTouchRequest",
        "conditionalVarValue": true,

    },

    {
        "varName": "statementFacility",
        "type": "title",
        "label": "Statement Facility",
        "grid": 12

    },
    {
        "varName": "statementFacility",
        "type": "checkbox",
        "label": "E-Statement",
        "grid": 12

    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "conditional": true,
        "conditionalVarName": "statementFacility",
        "conditionalVarValue": true,
        "grid": 6

    },
    {
        "varName": "additionalAccounts",
        "type": "title",
        "label": "Additional Accounts",
        "grid": 12

    },
    {
        "varName": "fdrRequest",
        "type": "checkbox",
        "label": "FDR Request",
        "grid": 12
    },
    {
        "varName": "dpsRequest",
        "type": "checkbox",
        "label": "DPS Request",
        "grid": 12
    },
    {
        "varName": "loanRequest",
        "type": "checkbox",
        "label": "Loan Request",
        "grid": 12
    }

];

//######New Account Opening individual#########

let CommonNewJsonFormIndividualAccountOpening = [

    {
        "varName": "cbNumber",
        "type": "text",
        "label": "CB Number  "
    },
    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name"
    },
    /* {
         "varName": "dob",
         "type": "date",
         "label": "Date OF Birth",
     },*/
    {
        "varName": "nid",
        "type": "text",
        "label": "NID No",
    },
    {
        "varName": "passport",
        "type": "text",
        "label": "Passport Number"
    },
    {
        "varName": "drivingLicense",
        "type": "text",
        "label": "Driving License"
    },
    {
        "varName": "birthCertificate",
        "type": "text",
        "label": "Birth Certificate",
    },
    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile Number",
    },

    {
        "varName": "email",
        "type": "text",
        "label": "Email",
    },
    {
        "varName": "comAddress",
        "type": "text",
        "label": "Communication Address",
    },


    {
        "varName": "permanentAddress",
        "type": "text",
        "label": "Permanent Address"
    },
    {
        "varName": "rmCode",
        "type": "text",
        "label": "RM Code",
    },
    {
        "varName": "introducerCB",
        "type": "text",
        "label": "Introducer CB",
    },
    {
        "varName": "smsAlertRequest",
        "type": "select",
        "label": "Sms Alert Request",
        "enum": [
            "YES",
            "NO"
        ]
    },

    {
        "varName": "e-statement",
        "type": "select",
        "label": "E-Statement",
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "chequeBookRequest",
        "type": "select",
        "label": "Cheque Book Request",
        "enum": [
            "YES",
            "NO"
        ]
    }

];
let CSNewJsonFormIndividualAccountOpening = {};
CSNewJsonFormIndividualAccountOpening["variables"] = JSON.parse(JSON.stringify(CommonNewJsonFormIndividualAccountOpening.concat(commonJsonFormIndividualAccountOpeningForm)));
CSNewJsonFormIndividualAccountOpening.variables.push(csSendTo);

//######BOM Account Opening individual#########
let BOMCommonjsonFormIndividualAccountOpeningSearch = [

    {
        "varName": "nid",
        "type": "text",
        "label": "NID",
        "grid": 4,

    },
    {
        "varName": "passport",
        "type": "text",
        "label": "Passport",
        "grid": 4,


    },
    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name",
        "required": true,
        "grid": 4,

    },
    /* {
         "varName": "dob",
         "type": "date",
         "label": "Date Of Birth",
         "required": true,
         "grid": 6,


     },*/
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "email": true,
        "grid": 4,

    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Phone Number",
        "required": true,
        "number": true,
        "grid": 4,


    },

    {
        "varName": "tin",
        "type": "text",
        "label": "eTin",
        "grid": 4,

    },


    {
        "varName": "registrationNo",
        "type": "text",
        "label": "Birth Cer./Driving License",
        "grid": 4,

    },
    {
        "varName": "nationality",
        "type": "text",
        "label": "Nationality",
        "enum": [
            "Bangladesh",
            "Japan",
            "Other",
        ],
        "required": true,
        "grid": 4,


    },];

let BOMNewJsonFormIndividualAccountOpening = {};
BOMNewJsonFormIndividualAccountOpening = makeReadOnlyObject(JSON.parse(JSON.stringify(BOMCommonjsonFormIndividualAccountOpeningSearch.concat(CommonCasaAccountOpening))));

//######BOM Account Opening Joint#########

let BOMCommonjsonFormJointAccountOpeningSearch = [


    {
        "varName": "accountTitle",
        "type": "text",
        "label": "Account TItle",
        "grid": 4


    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "grid": 4,


    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile Number",
        "required": true,
        "grid": 4


    }
];
let BOMNewJsonFormJointAccountOpening = {};
BOMNewJsonFormJointAccountOpening = makeReadOnlyObject(JSON.parse(JSON.stringify(BOMCommonjsonFormJointAccountOpeningSearch.concat(CommonCasaAccountOpening))));

//######BOM Account Opening NOnindividual#########
let BOMCommonjsonFormNonIndividualAccountOpeningSearch = [

    {
        "varName": "companyName",
        "type": "text",
        "label": "Company Name",
        "required": false,
        "readOnly": false,
        "grid": 4


    },

    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "required": false,

        "readOnly": false,
        "grid": 4


    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile Number",
        "required": false,
        "grid": 4

    },

    {
        "varName": "companyEtin",
        "type": "text",
        "label": "Company ETin",
        "required": false,
        "grid": 4


    },
    {
        "varName": "tradeLicense",
        "type": "text",
        "label": "Trade License",
        "required": false,
        "readOnly": false,
        "grid": 4


    },
    {
        "varName": "certificate",
        "type": "text",
        "label": "Certificate Of Incorporation",
        "required": false,
        "grid": 4


    }


];
let BOMNewCommonjsonFormNonIndividualAccountOpeningSearch = {};
BOMNewCommonjsonFormNonIndividualAccountOpeningSearch = makeReadOnlyObject(JSON.parse(JSON.stringify(BOMCommonjsonFormNonIndividualAccountOpeningSearch.concat(CommonCasaAccountOpening))));


//######BM Account Opening individual#########
let BMJsonFormIndividualAccountOpening = {};
BMJsonFormIndividualAccountOpening["variables"] = makeReadOnlyObject(JSON.parse(JSON.stringify(CommonNewJsonFormIndividualAccountOpening.concat(commonJsonFormIndividualAccountOpeningForm))));
BMJsonFormIndividualAccountOpening.variables.push(bmApprove);
BMJsonFormIndividualAccountOpening.variables.push(bmSendTo);


//####BM Account Opening individual#######last update
let BMCommonjsonFormIndividualAccountOpeningSearch = [
    {
        "varName": "accountSource",
        "type": "select",
        "label": "Account Source",
        "grid": 4,
        "enum": [
            "FINACLE",
            "ABABIL"
        ]

    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 4
    },
    {
        "varName": "nid",
        "type": "text",
        "label": "NID",
        "grid": 4,

    },
    {
        "varName": "passport",
        "type": "text",
        "label": "Passport",
        "grid": 4,


    },
    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name",
        "required": true,
        "grid": 4,

    },
    /* {
         "varName": "dob",
         "type": "date",
         "label": "Date Of Birth",
         "required": true,
         "grid": 6,


     },*/
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "email": true,
        "grid": 4,

    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Phone Number",
        "required": true,
        "grid": 4,


    },

    {
        "varName": "tin",
        "type": "text",
        "label": "eTin",
        "grid": 4,

    },


    {
        "varName": "registrationNo",
        "type": "text",
        "label": "Birth Cer./Driving License",
        "grid": 4,

    },
    {
        "varName": "nationality",
        "type": "text",
        "label": "Nationality",
        "enum": [
            "Bangladesh",
            "Japan",
            "Other",
        ],
        "required": true,
        "grid": 4,


    },
    {
        "varName": "comAddress",
        "type": "text",
        "label": "Communication Address",
        "grid": 4,
    },
    {
        "varName": "schemeCode",
        "type": "text",
        "label": "Scheme Code",
        "grid": 4,
    },
    {
        "varName": "currency",
        "type": "select",
        "label": "Currency",
        "grid": 4,
        "enum": [
            "BDT",
            "USD",
            "EUR",
            "GBP"
        ]
    },
    {
        "varName": "rmCode",
        "type": "text",
        "label": "RM Code",
        "grid": 4,
    },
    {
        "varName": "sbsCode",
        "type": "text",
        "label": "SBS Code",
        "grid": 4,
    },
    /*{
        "varName": "occupationCode",
        "type": "text",
        "label": "Occupation Code",
        "grid": 6,

    },*/
    {
        "varName": "ccepCompanyCode",
        "type": "text",
        "label": "CCEP Company Code",
        "grid": 4,

    },
    {
        "varName": "priority",
        "type": "select",
        "label": "Priority",
        "enum": [
            "GENERAL",
            "HIGH",
        ],
        "grid": 4,
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Add Service",
        "grid": 12

    },
    {
        "varName": "chequeBookRequest",
        "type": "checkbox",
        "label": "Cheque Book Request",
        "grid": 12,

    },
    {

        "varName": "pageOfChequeBook",
        "type": "select",
        "label": "Page Of Cheque Book",
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,
        "grid": 4,
        "enum": [
            "25",
            "50",
            "100"
        ],

    },
    {
        "varName": "currency",
        "type": "select",
        "label": "Currency",
        "enum": [
            "BDT",
            "USD",
            "EUR",
            "GBP"
        ],
        "grid": 4,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,

    },
    {
        "varName": "deliveryType",
        "type": "select",
        "label": "Delivery Type",
        "enum": [
            "Courier",
            "Branch"
        ],
        "grid": 4,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,

    },

    {
        "varName": "chequeBookDesign",
        "type": "select",
        "label": "Cheque Book Design",
        "enum": [
            "Sapphire",
            "Citygem",
            "City Alo",
            "Other"
        ],
        "grid": 4,
        "conditional": true,
        "conditionalVarName": "chequeBookRequest",
        "conditionalVarValue": true,

    },
//Debit Card
    {
        "varName": "debitCard",
        "type": "checkbox",
        "label": "Debit Card",
        "grid": 12
    },
    {
        "varName": "accountsType",
        "type": "text",
        "label": "Account Type",
        "enum": [
            "SAVINGS",
            "CURRENT",
            "FCY"
        ],

        "grid": 4,
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "currency",
        "type": "select",
        "label": "Currency",
        "enum": [
            "BDT",
            "USD",
            "EUR",
            "GBP"
        ],
        "grid": 4,
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "debitCardText",
        "type": "text",
        "label": "Name On Card",
        "grid": 4,
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,

    },

    {
        "varName": "cardType",
        "type": "select",
        "label": "Card Type",
        "grid": 4,
        "enum": [
            "VISA",
            "MASTER",
            "CITYMAXX",
        ],
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "productType",
        "type": "text",
        "label": "Product Type",
        "grid": 4,
        "enum": [
            "#",
            "#",

        ],
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "selectCcep",
        "type": "text",
        "label": "Select CCEP",
        "grid": 4,
        "enum": [
            "#",
            "#",

        ],
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,

    },

    {
        "varName": "deliveryType",
        "type": "select",
        "label": "Delivery Type",
        "enum": [
            "Courier",
            "Branch"
        ],
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "debitCard",
        "conditionalVarValue": true,

    },
    {
        "varName": "smsAlertRequest",
        "type": "checkbox",
        "label": "SMS Alert Request",
        "grid": 12

    },
    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile Number",
        "grid": 4,
        "conditional": true,
        "conditionalVarName": "smsAlertRequest",
        "conditionalVarValue": true,

    },

    {
        "varName": "callCenterRegistration",
        "type": "checkbox",
        "label": "Call Center Registration",
        "grid": 12

    },

    {
        "varName": "cityTouchRequest",
        "type": "checkbox",
        "label": "City Touch",
        "grid": 12

    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email ",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "cityTouchRequest",
        "conditionalVarValue": true,

    },
    {
        "varName": "cityTouchCustomerId",
        "type": "text",
        "label": "Customer Id ",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "cityTouchRequest",
        "conditionalVarValue": true,

    },
    {
        "varName": "cityTouchCbNumber",
        "type": "text",
        "label": "CB Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "cityTouchRequest",
        "conditionalVarValue": true,

    },

    {
        "varName": "statementFacility",
        "type": "title",
        "label": "Statement Facility",
        "grid": 12

    },
    {
        "varName": "statementFacility",
        "type": "checkbox",
        "label": "E-Statement",
        "grid": 12

    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "conditional": true,
        "conditionalVarName": "statementFacility",
        "conditionalVarValue": true,
        "grid": 6

    },
    {
        "varName": "additionalAccounts",
        "type": "title",
        "label": "Additional Accounts",
        "grid": 12

    },
    {
        "varName": "fdrRequest",
        "type": "checkbox",
        "label": "FDR Request",
        "grid": 12
    },
    {
        "varName": "dpsRequest",
        "type": "checkbox",
        "label": "DPS Request",
        "grid": 12
    },
    {
        "varName": "loanRequest",
        "type": "checkbox",
        "label": "Loan Request",
        "grid": 12
    }

];
let BMjsonFormIndividualAccountOpeningSearch = makeReadOnlyObject(JSON.parse(JSON.stringify(BMCommonjsonFormIndividualAccountOpeningSearch)));

//####BM Account Opening individual#######last update
let BMCommonjsonFormJointAccountOpeningSearchs = [


    {
        "varName": "accountSource",
        "type": "text",
        "label": "Account Source",
        "grid": 4


    },
    {
        "varName": "accountTitle",
        "type": "text",
        "label": "Account TItle",
        "grid": 4


    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "grid": 4,


    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile Number",
        "required": true,
        "grid": 4


    }


];
let BMCommonjsonFormJointAccountOpeningSearch = makeReadOnlyObject(JSON.parse(JSON.stringify(BMCommonjsonFormJointAccountOpeningSearchs.concat(CommonCasaAccountOpening))));
let BMjsonFormJointAccountOpeningSearch = makeReadOnlyObject(JSON.parse(JSON.stringify(BMCommonjsonFormJointAccountOpeningSearchs.concat(CommonCasaAccountOpening))));


//####SD Account Opening individual#######last update


let SDCommonNewJsonFormIndividualAccountOpeningFinacle1 = [

    {
        // seal
        "varName": "relationshipBranchName",
        "type": "text",
        "label": "Relationship Branch Name",
        "grid": "12"

    },

    {
        "varName": "aOFDate",
        "type": "date",
        "label": "Date",
        "grid": "12"

    }, {
        "varName": "aOFBranchName",
        "type": "text",
        "label": "Branch Name",
        "grid": "12"

    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "Unique Customer ID",
        "grid": "12"

    },
    {
        "varName": "accountNo",
        "type": "text",
        "label": "Account No",
        "grid": "12"

    },
    {
        "varName": "title",
        "type": "text",
        "label": "Title ",
        "grid": "12"

    },
    {
        "varName": "customerName",
        "type": "text",
        "label": "Short Name",
        "grid": "12"

    },
    {
        // tick
        "varName": "accountType",
        "type": "text",
        "label": "Type Of Account",
        "grid": "12"

    },
    {

        "varName": "purposeOfOpening",
        "type": "text",
        "label": "Purpose Of Opening",
        "grid": "12"

    },
    {
        // tick
        "varName": "natureOfAccount",
        "type": "text",
        "label": "Nature Of Account",
        "grid": "12"

    },
    {
        // tick
        "varName": "currency",
        "type": "text",
        "label": "Currency",
        "grid": "12"

    },
    {
        // Numeric & Check Box
        "varName": "initialDeposit",
        "type": "text",
        "label": "Initial Deposit",
        "grid": "12"

    },
    {
        "varName": "depositType",
        "type": "text",
        "label": "Deposit Type",
        "grid": "12"

    },
    {
        "varName": "commAddressSelection",
        "type": "text",
        "label": "Comm. Address Selection",
        "grid": "12"

    },
    {
        "varName": "email",
        "type": "text",
        "label": "Desig. Email ID",
        "grid": "12"

    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Desig. Mobile Number",
        "grid": "12"

    },
    {

        "varName": "adcRequest",
        "type": "title",
        "label": "ADC Request",
        "grid": "12"
    },
    {
        // tick
        "varName": "requiredService",
        "type": "text",
        "label": "Required Service(s)",
        "grid": "12"

    },
    {
        "varName": "emailForCityTouchService",
        "type": "text",
        "label": "Email for City Touch Service",
        "grid": "12"

    },
    {
        "varName": "preferredCityTouchUserID",
        "type": "text",
        "label": "Preferred City Touch User ID",
        "grid": "12"

    },
    {
        "varName": "desiredCreditCardNumberToBeTaggedWithCitytouch",
        "type": "text",
        "label": "Desired Credit Card Number to be tagged with Citytouch",
        "grid": "12"

    },
    {
        "varName": "facilitiesDetails",
        "type": "title",
        "label": "Facilities Details",
        "grid": "12"
    },
    {
        // tick
        "varName": "chequeBook",
        "type": "text",
        "label": "Cheque Book",
        "grid": "12"

    }, {
        // tick
        "varName": "debitCrad",
        "type": "text",
        "label": "Debit Crad",
        "grid": "12"

    }, {
        // tick
        "varName": "lockerFacility",
        "type": "text",
        "label": "Locker Facility",
        "grid": "12"

    },
    {
        "varName": "statementFacilities",
        "type": "text",
        "label": "Statement Facilities",
        "grid": "12"

    },
    {
        "varName": "cardDetails",
        "type": "title",
        "label": "Card Details",
        "grid": "12"
    },
    {
        // tick
        "varName": "cardApplicant",
        "type": "text",
        "label": "Card Applicant",
        "grid": "12"

    },
    {
        // tick
        "varName": "cardType",
        "type": "text",
        "label": "Card Type",
        "grid": "12"

    },
    {
        // tick
        "varName": "customerCategory",
        "type": "text",
        "label": "Customer Category",
        "grid": "12"

    },
    {
        "varName": "nameOfCard",
        "type": "text",
        "label": "Name of Card",
        "grid": "12"

    },
    {
        "varName": "operatingOn",
        "type": "text",
        "label": "Operating On",
        "grid": "12"

    },
    {
        "varName": "cardReceiveThrough",
        "type": "text",
        "label": "Card Receive Through",
        "grid": "12"

    },
    {
        "varName": "introducerInformation",
        "type": "title",
        "label": "Introducer Information",
        "grid": "12"
    },
    {
        "varName": "introName",
        "type": "text",
        "label": "Introducer Name",
        "grid": "12"

    },
    {
        "varName": "introAccountNumber",
        "type": "text",
        "label": "Introducer Account Number",
        "grid": "12"

    },
    {
        // Alpha Numeric
        "varName": "introducerCB",
        "type": "text",
        "label": "Introducer Customer ID",
        "grid": "12"

    },
    {
        "varName": "relationshipWithApplicant",
        "type": "text",
        "label": "Relationship with Applicant",
        "grid": "12"

    },
    {
        "varName": "nomineeInformation",
        "type": "title",
        "label": "Nominee Information",
        "grid": "12"
    },
    {
        "varName": "nomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": "12"

    },
    {
        "varName": "nomineeFathersName",
        "type": "text",
        "label": "Nominee Fathers Name",
        "grid": "12"

    }, {
        "varName": "nomineeMothersName",
        "type": "text",
        "label": "Nominee Mothers Name",
        "grid": "12"

    },
    {
        "varName": "nomineeSopuseName",
        "type": "text",
        "label": "Nominee Sopuse Name",
        "grid": "12"

    },
    {
        "varName": "nomineeOccupation",
        "type": "text",
        "label": "Nominee Occupation",
        "grid": "12"

    },
    {
        "varName": "nomineeRelationship",
        "type": "text",
        "label": "Relationship",
        "grid": "12"

    },
    {
        "varName": "nomineeDob",
        "type": "date",
        "label": "DOB",
        "grid": "12"

    }, {
        "varName": "nomineeBirthCertificate",
        "type": "text",
        "label": "Birth Certificate",
        "grid": "12"

    }, {
        "varName": "nomineeNid",
        "type": "text",
        "label": "NID",
        "grid": "12"

    },
    {
        "varName": "nomineePassport",
        "type": "text",
        "label": "Passport",
        "grid": "12"

    },
    {
        "varName": "nomineePassportExpDate",
        "type": "text",
        "label": "Passport Exp Date",
        "grid": "12"

    },
    {
        "varName": "nomineeDrivingLicense",
        "type": "text",
        "label": "Driving License",
        "grid": "12"

    },
    {
        "varName": "DLExpDate",
        "type": "text",
        "label": "DL. Exp Date",
        "grid": "12"

    },
    {
        "varName": "nomineeOtherPhotoID",
        "type": "text",
        "label": "Other Photo ID",
        "grid": "12"

    },
    {
        "varName": "nomineePresentAddress",
        "type": "text",
        "label": "Permanent/Present Address",
        "grid": "12"

    },
    {
        "varName": "nomineePostcode",
        "type": "text",
        "label": "Postcode",
        "grid": "12"

    },
    {
        "varName": "nomineePhoneNumber",
        "type": "text",
        "label": "Mobile Number",
        "grid": "12"

    },
    {
        "varName": "gaurdianInformation",
        "type": "title",
        "label": "Gaurdian Information",
        "grid": "12"
    },
    {
        "varName": "gaurdianName",
        "type": "text",
        "label": "Gaurdian Name",
        "grid": "12"

    },
    {
        "varName": "Fathers/HusbandName",
        "type": "text",
        "label": "Fathers/Husband Name",
        "grid": "12"

    },
    {
        "varName": "gaurdianDob",
        "type": "date",
        "label": "DOB",
        "grid": "12"

    },
    {
        "varName": "relationshipWithNominee",
        "type": "text",
        "label": "Relationship With Nominee",
        "grid": "12"

    },
    {
        "varName": "legalGaurdianPhotoInformation",
        "type": "text",
        "label": "Legal Gaurdian Photo Information",
        "grid": "12"

    },
    {
        "varName": "forBankUseOnly",
        "type": "title",
        "label": "For Bank Use Only",
        "grid": "12"
    },
    {
        "varName": "RelationshipNoCustomerID",
        "type": "text",
        "label": "Relationship No/ Customer ID",
        "grid": "12"

    },
    {
        "varName": "forBankUseOnlySectorCode",
        "type": "text",
        "label": "Sector Code",
        "grid": "12"

    },
    {
        "varName": "depositTypeCode",
        "type": "text",
        "label": "Deposit Type Code",
        "grid": "12"

    },
    {
        "varName": "accountOpeningChecklist",
        "type": "text",
        "label": "Account Opening Checklist",
        "grid": "12"

    }, {
        "varName": "identityVerifiedBy",
        "type": "text",
        "label": "Identity Verified By ",
        "grid": "12"

    }, {
        "varName": "addressVerifiedBy",
        "type": "text",
        "label": "Address Verified By",
        "grid": "12"

    },
    {
        "varName": "resultofSanctionBlackListScreening",
        "type": "text",
        "label": "Result of Sanction/Black List Screening",
        "grid": "12"

    },
    {
        // tick
        "varName": "howWasTheACOpened",
        "type": "text",
        "label": "How was the A/C opened",
        "grid": "12"

    },
    {
        "varName": "nameOfDMEBrEmployee",
        "type": "text",
        "label": "Name of DME/Br. Employee",
        "grid": "12"

    },
    {
        // tick
        "varName": "taxApplicable",
        "type": "text",
        "label": "Tax Applicable",
        "grid": "12"

    },
    {
        "varName": "forBranch",
        "type": "title",
        "label": "For Branch",
        "grid": "12"
    },
    {
        "varName": "savings/CurrentProductCode",
        "type": "text",
        "label": "Savings/Current Product Code",
        "grid": "12"

    },
    {
        "varName": "valueDate",
        "type": "date",
        "label": "Value Date",
        "grid": "12"

    },
    {
        "varName": "forBranchOthers",
        "type": "text",
        "label": "Others",
        "grid": "12"

    },
    {
        "varName": "fDProductCode",
        "type": "text",
        "label": "FD Product Code",
        "grid": "12"

    },
    {
        "varName": "branchSol",
        "type": "text",
        "label": "Branch Sol",
        "grid": "12"

    },
    {
        "varName": "workflowInput",
        "type": "title",
        "label": "Workflow Input",
        "grid": "12"
    },
    {
        "varName": "debitCard",
        "type": "text",
        "label": "Debit Card",
        "grid": "12"

    },
    {
        "varName": "phone1",
        "type": "text",
        "label": "Mobile Number 1",
        "grid": "12"

    },
    {
        "varName": "phone2",
        "type": "text",
        "label": "Mobile Number 2",
        "grid": "12"

    },
    {
        "varName": "smsAlertRequest",
        "type": "select",
        "label": "Sms Alert Request",
        "grid": "12",
        "enum": [
            "YES",
            "NO"
        ]

    }, {
        "varName": "companyCode",
        "type": "text",
        "label": "Company Code",
        "grid": "12"

    },
    {
        "varName": "companyName",
        "type": "text",
        "label": "Company Name",
        "grid": "12"

    },
    {
        "varName": "kyc",
        "type": "title",
        "label": "KYC",
        "grid": "12"

    },
    {
        "varName": "kycSourceOfFund",
        "type": "text",
        "label": "Source of Fund",
        "grid": "12"

    }, {
        "varName": "dOCCollect",
        "type": "text",
        "label": "DOC Collect",
        "grid": "12"

    }, {
        "varName": "collectedDOCHaveBeenVerified ",
        "type": "text",
        "label": "Collected DOC have been Verified",
        "grid": "12"

    },
    {
        "varName": "howTheAddressIsVerified",
        "type": "text",
        "label": "How the Address is verified",
        "grid": "12"

    },
    {
        "varName": "hasBeneficialOwnerBeenIdentified",
        "type": "text",
        "label": "Has the Beneficial Owner of the a/c been identified",
        "grid": "12"

    },
    {
        "varName": "identification",
        "type": "title",
        "label": "Identification",
        "grid": "12"

    },
    {
        "varName": "passport",
        "type": "text",
        "label": "Passport No",
        "grid": "12"

    },
    {
        "varName": "nid",
        "type": "text",
        "label": "NID No",
        "grid": "12"

    },
    {
        "varName": "birthCertificateNo",
        "type": "text",
        "label": "Birth Certificate No",
        "grid": "12"

    },
    {
        "varName": "identificationEtin",
        "type": "text",
        "label": "E-TIN",
        "grid": "12"

    },
    {
        "varName": "drivingLicense",
        "type": "text",
        "label": "Driving License No",
        "grid": "12"

    },
    {
        "varName": "identificationOtherDocumentation",
        "type": "text",
        "label": "Other Documentation",
        "grid": "12"

    },
    {

        "varName": "reasonForA/COpeningOfForeignCompany ",
        "type": "text",
        "label": "Reason for A/C opening of Foreign Company",
        "grid": "12"

    },
    {
        "varName": "typeOfVISA",
        "type": "text",
        "label": "Type Of VISA",
        "grid": "12"

    },
    {
        "varName": "identificationExpDate",
        "type": "date",
        "label": "Exp Date",

        "grid": "12"

    },
    {
        "varName": "workPermitAndPermission",
        "type": "text",
        "label": "Work Permit and Permission",
        "grid": "12"

    },

    {
        "varName": "PEP/IP",
        "type": "text",
        "label": "PEP/IP ?",
        "grid": "12"


    },
    {
        "varName": "iFYESThen",
        "type": "text",
        "label": "IF YES Then",
        "grid": "12"


    },

    {
        "varName": "anyMatchOfTerroristActivity",
        "type": "text",
        "label": "Any Match Of Terrorist Activity",
        "grid": "12"

    },
    {
        "varName": "riskRating",
        "type": "title",
        "label": "Risk Rating",
        "grid": "12"
    },
    {
        // tick
        "varName": "businessCustomerEngaged",
        "type": "text",
        "label": "What does the Customer do/in What Type of Business Is The Customer Engaged",
        "grid": "12"
    },
    {
        // tick
        "varName": "customerMonthlyIncome",
        "type": "text",
        "label": "Customer's Monthly Income",
        "grid": "12"

    },
    {
        "varName": "expectedAmountOfMonthlyTotalTransaction",
        "type": "text",
        "label": "Expected Amount of Monthly Total Transaction",
        "grid": "12"

    },
    {
        "varName": "expectedAmountOfMonthlyCashTransaction",
        "type": "text",
        "label": "Expected Amount of Monthly Cash Transaction",
        "grid": "12"

    },
    {
        "varName": "totalRiskScore",
        "type": "text",
        "label": "Total Risk Score",
        "grid": "12"

    },
    {
        "varName": "riskRatingComments",
        "type": "text",
        "label": "Comments",
        "grid": "12"

    },
    {
        "varName": "tp",
        "type": "title",
        "label": "TP",
        "grid": "12"
    },
    {
        "varName": "monthlyProbableTournover",
        "type": "text",
        "label": "Monthly Probable Tournover",
        "grid": "12"

    },
    {
        "varName": "deposit",
        "type": "title",
        "label": "Deposit",
        "grid": "12"
    },
    {
        "varName": "cashDeposit",
        "type": "text",
        "label": "Cash Deposit",
        "grid": "12"

    }, {
        "varName": "depositByTransfer",
        "type": "text",
        "label": "Deposit by Transfer",
        "grid": "12"

    },
    {
        "varName": "foreignInwardRemittance",
        "type": "text",
        "label": "Foreign Inward Remittance",
        "grid": "12"

    },
    {
        "varName": "depositIncomeFromExport",
        "type": "text",
        "label": "Deposit Income from Export",
        "grid": "12"

    },
    {
        "varName": "Deposit/TransferFromBOA/C",
        "type": "text",
        "label": "Deposit/Transfer from BO A/C",
        "grid": "12"

    },
    {
        "varName": "depositOthers",
        "type": "text",
        "label": "Others",
        "grid": "12"

    },
    {
        "varName": "totalProbableDeposit",
        "type": "text",
        "label": "Total Probable Deposit",
        "grid": "12"

    },
    {
        "varName": "withdraw",
        "type": "title",
        "label": "Withdraw",
        "grid": "12"
    },
    {
        "varName": "cashWithdrawal",
        "type": "text",
        "label": "Cash Withdrawal",
        "grid": "12"

    },
    {
        "varName": "withdrawalThroughTransfer/Instrument",
        "type": "text",
        "label": "Withdrawal Through Transfer/Instrument",
        "grid": "12"

    },
    {

        "varName": "foreignOutwardRemittance",
        "type": "text",
        "label": "Foreign Outward Remittance",
        "grid": "12"

    },
    {
        "varName": "paymentAgainstImport",
        "type": "text",
        "label": "Payment Against Import",
        "grid": "12"

    },
    {
        "varName": "deposit/TransferToBO",
        "type": "text",
        "label": "Deposit/Transfer to BO A/C",
        "grid": "12"

    },
    {

        "varName": "withdrawOthers",
        "type": "text",
        "label": "Others",
        "grid": "12"

    },
    {
        "varName": "totalProbableWithdrawal",
        "type": "text",
        "label": "Total Probable  Withdrawal",
        "grid": "12"

    }


];
/*let SDCommonNewJsonFormIndividualAccountOpeningFinacle = [
    {
        // seal
        "varName": "relationshipBranchName",
        "type": "text",
        "label": "Relationship Branch Name",
        "grid":6
    },
    {
        "varName": "aOFDate",
        "type": "date",
        "label": "Date",
        "grid":6
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "Unique Customer ID",
        "grid":6
    },
    {
        "varName": "aOFBranchName",
        "type": "text",
        "label": "Branch Name",
        "grid":6
    },

    {
        "varName": "accountNo",
        "type": "text",
        "label": "Account No",
        "grid":6
    },
    {
        "varName": "title",
        "type": "text",
        "label": "Title ",
        "grid":6
    },
    {
        "varName": "customerName",
        "type": "text",
        "label": "Short Name",
        "grid":6
    },
    {
        // tick
        "varName": "accountType",
        "type": "select",
        "label": "Type Of Account",
        "grid":6,
        "enum": [
            "General Savings A/C",
            "Current A/C",
            "SND A/C",
            "Alo General Savings A/C",
            "Alo Savings Delight",
            "Alo High Value Savings",
            "Seniors' Savings A/C",
            "Savings Delight A/C",
            "Fixed Deposit A/C",
            "RFCD A/C",
            "Foreign Currency A/C",
            "Students Savings A/C School Plan",
            "Students Savings A/C College Plan",
            "Others"
        ],
    },
    {

        "varName": "purposeOfOpening",
        "type": "text",
        "label": "Purpose Of Opening",
        "grid":6
    },
    {
        // tick
        "varName": "natureOfAccount",
        "type": "select",
        "label": "Nature Of Account",
        "grid":6,
        "enum": [
            "Individual",
            "Joint"
        ]
    },
    {
        // tick
        "varName": "currency",
        "type": "select",
        "label": "Currency",
        "grid":6,
        "enum": [
            "BDT",
            "USD",
            "Euro",
            "GBP",
            "Others"
        ]
    },
    {
        // Numeric & Check Box
        "varName": "initialDeposit",
        "type": "text",
        "label": "Initial Deposit",
        "grid":6
    },
    {
        "varName": "depositType",
        "type": "select",
        "label": "Deposit Type",
        "grid":6,
        "enum": [
            "Cash",
            "Cheque",
            "Transfer"
        ]
    },
    {
        "varName": "commAddressSelection",
        "type": "select",
        "label": "Comm. Address Selection",
        "grid":6,
        "enum": [
            "Present Address(Residence)",
            "Office/Business Address",
            "Permanent Address"
        ]
    },
    {
        "varName": "email",
        "type": "text",
        "label": "Desig. Email ID",
        "grid":6
    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Desig. Mobile Number",
        "grid":6
    },
    {

        "varName": "adcRequest",
        "type": "title",
        "label": "ADC Request",
        "grid":12
    },
    {
        // tick
        "varName": "requiredService",
        "type": "select",
        "label": "Required Service(s)",
        "grid":6,
        "enum": [
            "City Touch",
            "Call Center",
            "SMS Alert",
            "Others"
        ],
    },
    {
        "varName": "emailForCityTouchService",
        "type": "text",
        "label": "Email for City Touch Service",
        "grid":6
    },
    {
        "varName": "preferredCityTouchUserID",
        "type": "text",
        "label": "Preferred City Touch User ID",
        "grid":6
    },
    {
        "varName": "desiredCreditCardNumberToBeTaggedWithCitytouch",
        "type": "title",
        "label": "Desired Credit Card Number to be tagged with CityTouch Id",
        "grid":12
    },
    {
        "varName": "creditCardNumber",
        "type": "text",
        "label": "Credit Card Number(s)",
        "grid":6
    },
    {
        "varName": "clientID",
        "type": "text",
        "label": "Client ID",
        "grid":6
    },
    {
        "varName": "cardType",
        "type": "select",
        "label": "Card Type",
        "grid":6,
        "enum": [
            "AMEX",
            "VISA",
            "Others"
        ]
    },
    {
        "varName": "facilitiesDetails",
        "type": "title",
        "label": "Facilities Details",
        "grid":12
    },
    {
        // tick
        "varName": "chequeBook",
        "type": "select",
        "label": "Cheque Book",
        "grid":6,
        "enum": [
            "YES",
            "NO"
        ]
    }, {
        // tick
        "varName": "debitCrad",
        "type": "select",
        "label": "Debit Crad",
        "grid":6,
        "enum": [
            "YES",
            "NO"
        ]
    }, {
        // tick
        "varName": "lockerFacility",
        "type": "select",
        "label": "Locker Facility",
        "grid":6,
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "statementFacilities",
        "type": "select",
        "label": "Statement Facilities",
        "grid":6,
        "enum": [
            "YES",
            "NO"
        ]
    },
    /////////end of 1 page \\\\\\\\\\\\\\\
    {
        "varName": "cardDetails",
        "type": "title",
        "label": "Card Details",
        "grid":12
    },
    {
        // tick
        "varName": "cardApplicant",
        "type": "select",
        "label": "Card Applicant",
        "grid":6,
        "enum": [
            "1st Applicant",
            "2nd Applicant",
            "Others"
        ]
    },
    {
        // tick
        "varName": "cardType",
        "type": "select",
        "label": "Card Type",
        "grid":6,
        "enum": [
            "MC Debit Card",
            "VISA Debit Card",
            "City Max Card",
            "Others"
        ]
    },
    {
        // tick
        "varName": "customerCategory",
        "type": "select",
        "label": "Customer Category",
        "grid":6,
        "enum": [
            "Individual",
            "Staff",
            "Corporate-Name of the Organization"
        ]
    },
    {
        "varName": "nameOfCard",
        "type": "text",
        "label": "Name of Card",
        "grid":6
    },
    {
        "varName": "operatingOn",
        "type": "text",
        "label": "Operating On",
        "grid":6
    },
    {
        "varName": "cardReceiveThrough",
        "type": "text",
        "label": "Card Receive Through",
        "grid":6
    },
    {
        "varName": "introducerInformation",
        "type": "title",
        "label": "Introducer Information",
        "grid":12
    },
    {
        "varName": "introName",
        "type": "text",
        "label": "Introducer Name",
        "grid":6
    },
    {
        "varName": "introAccountNumber",
        "type": "text",
        "label": "Account Number",
        "grid":6
    },
    {
        // Alpha Numeric
        "varName": "introducerCB",
        "type": "text",
        "label": "Customer ID",
        "grid":6
    },
    {
        "varName": "relationshipWithApplicant",
        "type": "text",
        "label": "Relationship with Applicant",
        "grid":6
    },
    {
        "varName": "nomineeInformation",
        "type": "title",
        "label": "Nominee Information",
        "grid":12
    },
    {
        "varName": "nomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid":6
    },
    {
        "varName": "nomineeFathersName",
        "type": "text",
        "label": "Fathers Name",
        "grid":6
    }, {
        "varName": "nomineeMothersName",
        "type": "text",
        "label": "Mothers Name",
        "grid":6
    },
    {
        "varName": "nomineeSopuseName",
        "type": "text",
        "label": "Sopuse Name",
        "grid":6
    },
    {
        "varName": "nomineeOccupation",
        "type": "text",
        "label": "Occupation",
        "grid":6
    },
    {
        "varName": "nomineeRelationship",
        "type": "text",
        "label": "Relationship",
        "grid":6
    },
    {
        "varName": "nomineeDob",
        "type": "date",
        "label": "DOB",
        "grid":6
    }, {
        "varName": "nomineeBirthCertificate",
        "type": "text",
        "label": "Birth Certificate",
        "grid":6
    }, {
        "varName": "nomineeNid",
        "type": "text",
        "label": "NID",
        "grid":6
    },
    {
        "varName": "nomineePassport",
        "type": "text",
        "label": "Passport",
        "grid":6
    },
    {
        "varName": "nomineePassportExpDate",
        "type": "text",
        "label": "Passport Exp Date",
        "grid":6
    },
    {
        "varName": "nomineeDrivingLicense",
        "type": "text",
        "label": "Driving License",
        "grid":6
    },
    {
        "varName": "DLExpDate",
        "type": "text",
        "label": "DL. Exp Date",
        "grid":6
    },
    {
        "varName": "nomineeOtherPhotoID",
        "type": "text",
        "label": "Other Photo ID",
        "grid":6
    },
    {
        "varName": "nomineePresentAddress",
        "type": "text",
        "label": "Permanent/Present Address",
        "grid":6
    },
    {
        "varName": "nomineePostcode",
        "type": "text",
        "label": "Postcode",
        "grid":6
    },
    {
        "varName": "nomineePhoneNumber",
        "type": "text",
        "label": "Mobile Number",
        "grid":6
    },
    {
        "varName": "gaurdianInformation",
        "type": "title",
        "label": "Gaurdian Information",
        "grid":12
    },
    {
        "varName": "gaurdianName",
        "type": "text",
        "label": "Gaurdian Name",
        "grid":6
    },
    {
        "varName": "Fathers/HusbandName",
        "type": "text",
        "label": "Fathers/Husband Name",
        "grid":6
    },
    {
        "varName": "gaurdianDob",
        "type": "date",
        "label": "DOB",
        "grid":6
    },
    {
        "varName": "relationshipWithNominee",
        "type": "text",
        "label": "Relationship With Nominee",
        "grid":6
    },
    {
        "varName": "legalGaurdianPhotoInformation",
        "type": "text",
        "label": "Legal Gaurdian Photo Information",
        "grid":6
    },
    //////////  end 2 page //////////
    {
        "varName": "forBankUseOnly",
        "type": "title",
        "label": "For Bank Use Only",
        "grid":12
    },
    {
        "varName": "RelationshipNoCustomerID",
        "type": "text",
        "label": "Relationship No/ Customer ID",
        "grid":6
    },
    {
        "varName": "forBankUseOnlySectorCode",
        "type": "text",
        "label": "Sector Code",
        "grid":6
    },
    {
        "varName": "depositTypeCode",
        "type": "text",
        "label": "Deposit Type Code",
        "grid":6
    },
    {
        "varName": "accountOpeningChecklist",
        "type": "text",
        "label": "Account Opening Checklist",
        "grid":6
    }, {
        "varName": "identityVerifiedBy",
        "type": "select",
        "label": "Identity Verified By ",
        "grid":6,
        "enum": [
            "Passport",
            "Birth Certificate",
            "National ID Card",
            "Other"
        ],
    }, {
        "varName": "addressVerifiedBy",
        "type": "text",
        "label": "Address Verified By",
        "grid":6
    },
    {
        "varName": "resultofSanctionBlackListScreening",
        "type": "title",
        "label": "Result of Sanction/Black List Screening",
        "grid":12
    },
    {
        "varName": "uNSecurityCouncil",
        "type": "select",
        "label": "UN Security Council Sanction list",
        "grid":6,
        "enum": [
            "Match Found",
            "No Match Found"]
    },
    {
        "varName": "blackListCreditDepartment",
        "type": "select",
        "label": "Black List provided by the Credit Department",
        "grid":6,
        "enum": [
            "Match Found",
            "No Match Found"]
    },
    {
        "varName": "restrictionByLocalLaw",
        "type": "select",
        "label": "Restriction by Local Law/Bangladesh Bank to Open an A/C",
        "grid":6,
        "enum": [
            "Match Found",
            "No Match Found"]
    },
    {
        // tick
        "varName": "howWasTheACOpened",
        "type": "select",
        "label": "How was the A/C opened",
        "grid":6,
        "enum": [
            "By RM/Branch Employee",
            "By DME",
            "Internet",
            "Walk-in/Unsolicited"
        ],
    },
    {
        "varName": "nameOfDMEBrEmployee",
        "type": "text",
        "label": "Name of DME/Br. Employee",
        "grid":6
    },
    {
        // tick
        "varName": "taxApplicable",
        "type": "select",
        "label": "Tax Applicable",
        "grid":6,
        "enum": [
            "YES",
            "NO"
        ]
    },
    ///  for branch  ////////////
    {
        "varName": "forBranch",
        "type": "title",
        "label": "For Branch",
        "grid":12
    },
    {
        "varName": "savings/CurrentProductCode",
        "type": "checkbox",
        "label": "Savings/Current Product Code",
        "grid":6
    },
    {
        "varName": "fDProductCode",
        "type": "checkbox",
        "label": "FD Product Code",
        "grid":6
    },
    {
        "varName": "valueDate",
        "type": "checkbox",
        "label": "Value Date",
        "grid":4
    },
    {
        "varName": "branchSol",
        "type": "checkbox",
        "label": "Branch Sol",
        "grid":4
    },
    {
        "varName": "forBranchOthers",
        "type": "checkbox",
        "label": "Others",
        "grid":4
    },


    /// end 4 page  ///
    {
        "varName": "tp",
        "type": "title",
        "label": "TP",
        "grid":12
    },
    {
        "varName": "date",
        "type": "date",
        "label": "Date",
        "grid":6
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "Unique Customer ID",
        "grid":6
    },
    {
        "varName": "accountNo",
        "type": "text",
        "label": "A/C No.",
        "grid":6
    },
    {
        "varName": "AcTitle",
        "type": "text",
        "label": "A/C Title",
        "grid":6
    },
    {
        "varName": "sourceOfFund",
        "type": "text",
        "label": "Source of Fund",
        "grid":6
    },
    {
        "varName": "monthlyProbableTournover",
        "type": "text",
        "label": "Monthly Probable Tournover",
        "grid":6
    },
    {
        "varName": "monthlyProbableIncome",
        "type": "text",
        "label": "Monthly Probable Income",
        "grid":6
    },
    {
        "varName": "deposit",
        "type": "title",
        "label": "Deposit",
        "grid":12
    },
    {
        "varName": "cashDeposit",
        "type": "text",
        "label": "Cash Deposit",
        "grid":6
    }, {
        "varName": "depositByTransfer",
        "type": "text",
        "label": "Deposit by Transfer",
        "grid":6
    },
    {
        "varName": "foreignInwardRemittance",
        "type": "text",
        "label": "Foreign Inward Remittance",
        "grid":6
    },
    {
        "varName": "depositIncomeFromExport",
        "type": "text",
        "label": "Deposit Income from Export",
        "grid":6
    },
    {
        "varName": "Deposit/TransferFromBOA/C",
        "type": "text",
        "label": "Deposit/Transfer from BO A/C",
        "grid":6
    },
    {
        "varName": "depositOthers",
        "type": "text",
        "label": "Others",
        "grid":6
    },
    {
        "varName": "totalProbableDeposit",
        "type": "text",
        "label": "Total Probable Deposit",
        "grid":6
    },
    {
        "varName": "withdraw",
        "type": "title",
        "label": "Withdraw",
        "grid":12
    },
    {
        "varName": "cashWithdrawal",
        "type": "text",
        "label": "Cash Withdrawal",
        "grid":6
    },
    {
        "varName": "withdrawalThroughTransfer/Instrument",
        "type": "text",
        "label": "Withdrawal Through Transfer/Instrument",
        "grid":6
    },
    {

        "varName": "foreignOutwardRemittance",
        "type": "text",
        "label": "Foreign Outward Remittance",
        "grid":6
    },
    {
        "varName": "paymentAgainstImport",
        "type": "text",
        "label": "Payment Against Import",
        "grid":6
    },
    {
        "varName": "deposit/TransferToBO",
        "type": "text",
        "label": "Deposit/Transfer to BO A/C",
        "grid":6
    },
    {

        "varName": "withdrawOthers",
        "type": "text",
        "label": "Others",
        "grid":6
    },
    {
        "varName": "totalProbableWithdrawal",
        "type": "text",
        "label": "Total Probable  Withdrawal",
        "grid":6
    },
    ////end page//////////////
    {
        "varName": "title",
        "type": "title",
        "label": "Individual Information Form",
        "grid":12
    },
    {
        "varName": "date",
        "type": "date",
        "label": "Date",
        "grid":6
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "Unique Customer ID",
        "grid":6
    },
    {
        "varName": "accountNo",
        "type": "text",
        "label": "A/C No.",
        "grid":6
    },
    {
        "varName": "relationWithTheAC",
        "type": "select",
        "label": "Relation with the A/C",
        "grid":6,
        "enum": [
            "1st Applicant",
            "2nd Applicant",
            "3rd Applicant",
            "director",
            "A/C Operator",
            "Partner",
            "Beneficial Owner",
            "Minor",
            "Guardian",
            "Trustee",
            "Attorney Holder",
            "Proprietor",
            "Others"
        ],
    },
    {
        "varName": "accountName",
        "type": "text",
        "label": "Account Name",
        "grid":6
    },
    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer's Name",
        "grid":6
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Relation",
        "grid":12
    },
    {
        "varName": "fatherName",
        "type": "text",
        "label": "Father's Name",
        "grid":6
    },
    {
        "varName": "motherName",
        "type": "text",
        "label": "Mother's Name",
        "grid":6
    },
    {
        "varName": "spouseName",
        "type": "text",
        "label": "Spouse' Name",
        "grid":6
    }, {
        "varName": "dob",
        "type": "date",
        "label": "DOB",
        "grid":6
    },
    {
        "varName": "religion",
        "type": "text",
        "label": "Religion",
        "grid":6
    },
    {
        "varName": "gender",
        "type": "select",
        "label": "Gender",
        "grid":6,
        "enum": [
            "Male",
            "Female",
            "Third Gender",
            "Address proof"
        ],
    },
    {
        "varName": "nid",
        "type": "text",
        "label": "NID",
        "grid":6
    },
    {
        "varName": "birthCertificate",
        "type": "text",
        "label": "Birth certificate",
        "grid":6
    },
    {
        "varName": "residentStatus",
        "type": "select",
        "label": "Resident status",
        "grid":6,
        "enum": [
            "Resident",
            "Non-Resident"
        ],
    },
    {
        "varName": "passportNo",
        "type": "text",
        "label": "Passport No",
        "grid":6
    },
    {
        "varName": "passportIssueDate",
        "type": "date",
        "label": "Issue Date",
        "grid":6
    },
    {
        "varName": "passportExpDate",
        "type": "date",
        "label": "Exp Date",
        "grid":6
    },
    {
        "varName": "passportPlaceOfIssue",
        "type": "text",
        "label": "Place of issue",
        "grid":6
    },
    {
        "varName": "drivingLicenseNo",
        "type": "text",
        "label": "Driving License no",
        "grid":6
    },
    {
        "varName": "drivingLicenseExpDate",
        "type": "date",
        "label": "Exp Date",
        "grid":6
    },
    {
        "varName": "otherPhotoId",
        "type": "text",
        "label": "Other photo id",
        "grid":6
    },
    {
        "varName": "etin",
        "type": "text",
        "label": "E-TIN",
        "grid":6
    },
    {
        "varName": "nationality",
        "type": "select",
        "label": "Nationality",
        "grid":6,
        "enum": [
            "Bangladeshi",
            "Others"
        ],
    },
    {
        "varName": "placeOfBirth",
        "type": "text",
        "label": "Place of Birth",
        "grid":6
    },
    {
        "varName": "countryOfBirth",
        "type": "text",
        "label": "Country of Birth",
        "grid":6
    },
    {
        "varName": "profession",
        "type": "text",
        "label": "Profession",
        "grid":6
    },
    {
        "varName": "nameOfTheOrganization",
        "type": "text",
        "label": "Name of the Organization",
        "grid":6
    },
    {
        "varName": "designation",
        "type": "text",
        "label": "Designation",
        "grid":6
    },
    {
        "varName": "natureOfBusiness",
        "type": "text",
        "label": "Nature of Business",
        "grid":6
    },
    ////end page//////
    {
        "varName": "presentAddress",
        "type": "text",
        "label": "Present Address",
        "grid":6
    },
    {
        "varName": "presentAddressPostCode",
        "type": "text",
        "label": "Post Code",
        "grid":6
    },
    {
        "varName": "presentAddressCountry",
        "type": "text",
        "label": "Country",
        "grid":6
    },
    {

        "varName": "presentAddressLandmark",
        "type": "text",
        "label": "Landmark",
        "grid":6
    },
    {
        "varName": "professionalAddress",
        "type": "text",
        "label": "Professional Address",
        "grid":6
    },
    {

        "varName": "professionalAddressPostCode",
        "type": "text",
        "label": "Post Code",
        "grid":6
    },
    {
        "varName": "professionalAddressCountry",
        "type": "text",
        "label": "Country",
        "grid":6
    },
    {
        "varName": "professionalAddressLandmark",
        "type": "text",
        "label": "Landmark",
        "grid":6
    },
    {
        "varName": "permanentAddress",
        "type": "text",
        "label": "Permanent Address",
        "grid":6
    },
    {
        "varName": "permanentAddressPostCode",
        "type": "text",
        "label": "Post Code",
        "grid":6
    },
    {
        "varName": "permanentAddressCountry",
        "type": "text",
        "label": "Country",
        "grid":6
    },
    {
        "varName": "permanentAddressLandmark",
        "type": "text",
        "label": "Landmark",
        "grid":6
    },
    {
        "varName": "phoneNumberResidence",
        "type": "text",
        "label": "Mobile Number(Residence)",
        "grid":6
    },
    {
        "varName": "phoneNumberOffice",
        "type": "text",
        "label": "Mobile Number(Office)",
        "grid":6
    },
    {
        "varName": "mobile",
        "type": "text",
        "label": "Mobile",
        "grid":6
    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email ID",
        "grid":6
    },
    {
        "varName": "emergencyContact",
        "type": "title",
        "label": "Emergency Contact",
        "grid":12
    },
    {
        "varName": "emergencyContactName",
        "type": "text",
        "label": "Name",
        "grid":6
    },
    {
        "varName": "emergencyContactAddress",
        "type": "text",
        "label": "Address",
        "grid":6
    },
    {
        "varName": "emergencyContactEmailId",
        "type": "text",
        "label": "Email ID",
        "grid":6
    },
    {
        "varName": "emergencyContactMobile",
        "type": "text",
        "label": "Mobile",
        "grid":6
    },
    {
        "varName": "emergencyContactRelationshipWithAcHolder",
        "type": "text",
        "label": "Relationship With A/C Holder",
        "grid":6
    },
    {
        "varName": "creditCardInformation",
        "type": "title",
        "label": "Credit Card Information of Account Holder",
        "grid":12
    },
    {
        "varName": "issuedByLocal",
        "type": "text",
        "label": "Issued By (Local)",
        "grid":6
    },
    {
        "varName": "issuedByInternational",
        "type": "text",
        "label": "Issued By(International)",
        "grid":6
    },
    {
        "varName": "fatcaInformation",
        "type": "text",
        "label": "FATCA Information",
        "grid":6
    },
    ////end page //////////////
    {
        "varName": "kyc",
        "type": "title",
        "label": "KYC",
        "grid":12
    },
    {
        "varName": "date",
        "type": "date",
        "label": "Date",
        "grid":6
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "Unique Customer ID",
        "grid":6
    },
    {
        "varName": "accountNo",
        "type": "text",
        "label": "A/C No.",
        "grid":6
    },
    {
        "varName": "AcTitle",
        "type": "text",
        "label": "A/C Title",
        "grid":6
    },
    {
        "varName": "typeOfAc",
        "type": "select",
        "label": "Type of A/C",
        "grid":6,
        "enum": [
            "Savings A/C",
            "Current A/C",
            "SND A/C",
            "NFCD A/C",
            "RFCD A/C",
            "Foreign Currency A/c",
            "other"
        ],
    },
    {
        "varName": "customerOccupation",
        "type": "text",
        "label": "Customer occupation",
        "grid":6
    },
    {
        "varName": "customerProbableMonthlyIncome ",
        "type": "text",
        "label": "Customer Probable Monthly Income",
        "grid":6
    },
    {
        "varName": "kycSourceOfFund",
        "type": "text",
        "label": "Source of Fund",
        "grid":6
    },
    {
        "varName": "dOCCollect",
        "type": "text",
        "label": "DOC collect to Ensure Source of Fund",
        "grid":6
    },
    {
        "varName": "collectedDOCHaveBeenVerified ",
        "type": "select",
        "label": "Collected DOC have been Verified",
        "grid":6,
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "hasBeneficialOwnerBeenIdentified",
        "type": "select",
        "label": "Has the Beneficial Owner of the a/c been identified",
        "grid":6,
        "enum": [
            "YES",
            "NO",
            "Not Applicable"
        ]
    },
    {
        "varName": "howTheAddressIsVerified",
        "type": "text",
        "label": "How the Address is verified",
        "grid":6
    },

    {
        "varName": "identification",
        "type": "title",
        "label": "Identification",
        "grid":12
    },
    {
        "varName": "passport",
        "type": "text",
        "label": "Passport No",
        "grid":12
    },
    {
        "varName": "passportReceivedCopy",
        "type": "checkbox",
        "label": "Received Copy",
        "grid":6
    },
    {
        "varName": "passportVerified",
        "type": "checkbox",
        "label": "Verified",
        "grid":6
    },
    {
        "varName": "nid",
        "type": "text",
        "label": "NID No",
        "grid":12
    },
    {
        "varName": "nidReceivedCopy",
        "type": "checkbox",
        "label": "Received Copy",
        "grid":6
    },
    {
        "varName": "nidVerified",
        "type": "checkbox",
        "label": "Verified",
        "grid":6
    },
    {
        "varName": "birthCertificateNo",
        "type": "text",
        "label": "Birth Certificate No",
        "grid":12
    },
    {
        "varName": "birthCertificateReceivedCopy",
        "type": "checkbox",
        "label": "Received Copy",
        "grid":6
    },
    {
        "varName": "birthCertificateVerified",
        "type": "checkbox",
        "label": "Verified",
        "grid":6
    },
    {
        "varName": "etin",
        "type": "text",
        "label": "E-TIN",
        "grid":12
    },
    {
        "varName": "etinReceivedCopy",
        "type": "checkbox",
        "label": "Received Copy",
        "grid":6
    },
    {
        "varName": "etinVerified",
        "type": "checkbox",
        "label": "Verified",
        "grid":6
    },
    {
        "varName": "drivingLicense",
        "type": "text",
        "label": "Driving License No",
        "grid":12
    },
    {
        "varName": "drivingLicenseReceivedCopy",
        "type": "checkbox",
        "label": "Received Copy",
        "grid":6
    },
    {
        "varName": "drivingLicenseVerified",
        "type": "checkbox",
        "label": "Verified",
        "grid":6
    },
    {
        "varName": "otherDocumentation",
        "type": "text",
        "label": "Other Documentation",
        "grid":12
    },
    {
        "varName": "otherDocumentationReceivedCopy",
        "type": "checkbox",
        "label": "Received Copy",
        "grid":6
    },
    {
        "varName": "otherDocumentationVerified",
        "type": "checkbox",
        "label": "Verified",
        "grid":6
    },
    {

        "varName": "reasonForA/COpeningOfForeignCompany ",
        "type": "text",
        "label": "Reason for A/C opening of Foreign Company",
        "grid":6
    },
    {
        "varName": "typeOfVISA",
        "type": "text",
        "label": "Type Of VISA",
        "grid":6
    },
    {
        "varName": "identificationExpDate",
        "type": "date",
        "label": "Exp Date",
        "grid":6
    },
    {
        "varName": "workPermitAndPermission",
        "type": "select",
        "label": "Work Permit and Permission",
        "grid":6,
        "enum": [
            "YES",
            "NO"
        ]
    },

    {
        "varName": "PEP/IP",
        "type": "text",
        "label": "PEP/IP ?",
        "grid":6
    },
    {
        "varName": "iFYESThen",
        "type": "text",
        "label": "IF YES Then",
        "grid":6
    },

    {
        "varName": "anyMatchOfTerroristActivity",
        "type": "text",
        "label": "Any Match Of Terrorist Activity",
        "grid":6,
        "enum": [
            "YES",
            "NO"
        ]
    },
    ///// end page //////////////
    {
        "varName": "riskRating",
        "type": "title",
        "label": "Risk Rating",
        "grid":12,

    },
    {
        // tick
        "varName": "businessCustomerEngaged",
        "type": "select",
        "label": "What does the Customer do/in What Type of Business Is The Customer Engaged",
            "grid":6,
    "enum": [
    "Jewelry/ Gold Business/Valuable Metal Business",
    "Money Changer/ Courier Service/Mobile Banking Agent",
    "Real Estate Developer/Agent",
    "Promoter of Construction Project/Constructor",
    "Art/Antic Dealer",
    "Restaurant/Bar/Night club/Residential Hotel/Parlor Business",
    "Import/Export",
    "Manpower Export Business",
    "Arms Business",
    "Garments Business/Garments Accessories/Buying House",
    "Pilot/Flight Attended",
    "Trusty",
    "Share/Stock Business Investor",
    "Software Business/information & Technology Business",
    "Expatriate(Foreign Working in Bangladesh)",
    "Travel Agent",
    "Investor with Annual More than 1 Crore Investment",
    "Freight/Shipping/Cargo Agent",
    "Auto Business (New/Reconditioned Car) Business",
    "Business(Leather and Leather Related Goods)",
    "House Construction Materials Business",
    "Professional (journalist,Lawyer,Doctor,Engineer,Chartered Accountant)",
"Director(Private/Public Limited Company)",
    "High level Official of Multinational Organization",
    "Housewives",
    "Employment in Information & Technology",
    "Player/Media Celebrity/Producer/Director",
    "Freelance Software Developer",
    "Business Agent",
    "Government Job",
    "Landlord",
    "Thread Business/Jute Business",
    "Transport Operator",
    "Tobacco & Cigarette Business",
    "Amusement Organization/Park",
    "Motor Parts/Workshop Business",
    "Private Service Managerial",
    "Teacher(Government/Private/Autonomous Educational Institution)",
    "Service(Private)",
    "Small Business(Yearly Turnover Under 50 Lakh)",
    "Self-employed Professional",
    "Computer/Mobile Phone Dealer",
    "Manufacturer(Except Firearms)",
    "Student",
    "Retired from Service",
    "Farmer/Worker/Fisher",
    "Other"

]
},
{
    // tick
    "varName": "customerMonthlyIncome",
    "type": "select",
    "label": "Customer's Monthly Income",
    "grid":6,
    "enum": [
    "Upto 1 lakh",
    ">1 to 3 lakh",
    "more than 3 lakh"
]
},
{
    "varName": "expectedAmountOfMonthlyTotalTransaction",
    "type": "title",
    "label": "Expected Amount of Monthly Total Transactions",
    "grid":12,

},
{
    "varName": "expectedAmountOfMonthlyTotalTransaction",
    "type": "select",
    "label": "Amount of Transaction in Current A/C (in Lacs)",
    "grid":6,
    "enum": [
    "0-10",
    ">10-20",
    ">20"
]


},
{
    "varName": "expectedAmountOfMonthlyTotalTransaction",
    "type": "select",
    "label": "Amount of Transaction in Savings A/C (in Lacs)",
    "grid":6,
    "enum": [
    "0-5",
    ">5-10",
    ">10"
]

},
{
    "varName": "expectedAmountOfMonthlyCashTransaction",
    "type": "title",
    "label": "Expected Amount of Monthly Cash Transaction",
    "grid":12
},
{
    "varName": "expectedAmountOfMonthlyTotalTransaction",
    "type": "select",
    "label": "Amount of Cash Transaction in Current A/C (in Lacs)",
    "grid":6,
    "enum": [
    "0-5",
    ">5-10",
    ">10"
]


},
{
    "varName": "expectedAmountOfMonthlyTotalTransaction",
    "type": "select",
    "label": "Amount of Cash Transaction in Saving A/C (in Lacs)",
    "grid":6,
    "enum": [
    "0-2",
    ">2-5",
    ">5"
]


},
{
    "varName": "totalRiskScore",
    "type": "select",
    "label": "Total Risk Score",
    "grid":6,
    "enum": [
    "HIGH",
    "LOW"
]
},
{
    "varName": "riskRatingComments",
    "type": "text",
    "label": "Comments",
    "grid":6
}


];*/

let SDCommonNewJsonFormIndividualAccountOpeningFinacle = [{
    "label": "AOF 1",
    "type": "title",
    "grid": 12,
},

    {
        "varName": "customerId",
        "type": "text",
        "label": "Customer ID",
        "grid": 6,
        "length": 9,
        required: true,
    },

    {
        "varName": "title",
        "type": "text",
        "label": "Title",
        "grid": 6,
        required: true,
    },

    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name",
        "grid": 6,
        "length": 80,
        required: true,
    },

    {
        "varName": "shortName",
        "type": "text",
        "label": "Short Name",
        "grid": 6,
        "length": 10,
        required: true,
    },

    {
        "varName": "email1",
        "type": "text",
        "label": "Email",
        "grid": 6,
        required: true,
    },

    {
        "varName": "phoneNo11",
        "type": "text",
        "label": "Phone No 1 ",
        "grid": 6,
    },

    {
        "varName": "statement",
        "type": "text",
        "label": "Statement",
        "grid": 6,
        required: true,
    },

    {
        "varName": "despatchMode",
        "type": "text",
        "label": "Despatch Mode",
        "grid": 6,
        required: true,
    },

    {
        "varName": "contactPhoneNumber",
        "type": "text",
        "label": "Contact Phone Number",
        "grid": 6,
        required: true,
    },

    {
        "varName": "acId",
        "type": "text",
        "label": "Ac ID",
        "grid": 6,
        "length": 13,
        required: true,
    },

    {
        "label": "AOF 2",
        "type": "title",
        "grid": 12,
    },

    {
        "varName": "introducerCustomerId",
        "type": "text",
        "label": "Introducer Customer ID",
        "grid": 6,
        "length": 9,
        required: true,
    },

    {
        "varName": "introducerName",
        "type": "text",
        "label": "Introducer Name",
        "grid": 6,
        required: true,
    },

    {
        "varName": "introducerStaff",
        "type": "text",
        "label": "Introducer Staff",
        "grid": 6,
        required: true,
    },

    {
        "varName": "nomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 6,
        required: true,
    },

    {
        "varName": "relationship",
        "type": "text",
        "label": "Relationship",
        "grid": 6,
        required: true,
    },

    {
        "varName": "dob1",
        "type": "date",
        "label": "D.O.B",
        "grid": 6,
        required: true,
    },

    {
        "varName": "address11",
        "type": "text",
        "label": "Address 1 ",
        "grid": 6,
        required: true,
    },

    {
        "varName": "address22",
        "type": "text",
        "label": "Address 2",
        "grid": 6,
    },

    {
        "varName": "cityCode1",
        "type": "select",
        "enum": ["Dhaka", "Chittagong", "Barishal", "Rajshahi", "Khulna"],
        "label": "City Code",
        "grid": 6,
        required: true,
    },

    {
        "varName": "stateCode1",
        "type": "text",
        "label": "State Code",
        "grid": 6,
        required: true,
    },

    {
        "varName": "postalCode1",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
        required: true,
    },

    {
        "varName": "country1",
        "type": "select",
        "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
        "label": "Country",
        "grid": 6,
        required: true,
    },

    {
        "varName": "regNo",
        "type": "text",
        "label": "Reg No",
        "grid": 6,
        required: true,
    },

    {
        "varName": "nomineeMinor",
        "type": "select",
        "label": "Nominee Minor",
        "enum": ["Yes", "No"],
        "grid": 12,
        required: true,
    },

    {
        "varName": "guardiansName",
        "type": "text",
        "label": "Guardians Name",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeMinor",
        "conditionalVarValue": "Yes",

    },

    {
        "varName": "guardianCode",
        "type": "text",
        "label": "Guardian Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "address12",
        "type": "text",
        "label": "Address",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "cityCode2",
        "type": "select",
        "enum": ["Dhaka", "Chittagong", "Barishal", "Rajshahi", "Khulna"],
        "label": "City Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "stateCode2",
        "type": "text",
        "label": "State Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "postalCode2",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "country2",
        "type": "select",
        "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
        "label": "Country",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "applicantMinor",
        "type": "select",
        "label": "Applicant Minor",
        "grid": 12,
        "enum": ["Yes", "No"],
    },
    {
        "varName": "gurdian",
        "type": "text",
        "label": "Gurdian",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "applicantMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "gurdianName",
        "type": "text",
        "label": "Gurdian Name",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "applicantMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "address23",
        "type": "text",
        "label": "Address",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "applicantMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "city1",
        "type": "select",
        "enum": ["Dhaka", "Chittagong", "Barishal", "Rajshahi", "Khulna"],
        "label": "City",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "applicantMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "state1",
        "type": "text",
        "label": "State",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "applicantMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "postal",
        "type": "text",
        "label": "Postal",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "applicantMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "varName": "country3",
        "type": "select",
        "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
        "label": "Country",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "applicantMinor",
        "conditionalVarValue": "Yes",
        required: true,
    },

    {
        "label": "AOF 3",
        "type": "title",
        "grid": 12,
    },
    {
        "varName": "modeOfOperation",
        "type": "text",
        "label": "Mode Of Operation",
        "grid": 6,
        required: true,
    },

    {
        "label": "AOF 4",
        "type": "title",
        "grid": 12,
    },

    {
        "varName": "sectorCode",
        "type": "text",
        "label": "Sector Code",
        "grid": 6,
        required: true,
    },

    {
        "varName": "subSectorCode",
        "type": "text",
        "label": "Sub Sector Code",
        "grid": 6,
        required: true,
    },

    {
        "varName": "freeCode31",
        "type": "text",
        "label": "Free Code 3 ",
        "grid": 6,
        required: true,
    },

    {
        "varName": "targetSchemeCode",
        "type": "text",
        "label": "Target Scheme Code",
        "grid": 6,
        required: true,
    },

    {
        "label": "IIF Page 1",
        "type": "title",
        "grid": 12,
    },
    {
        "varName": "gender",
        "type": "text",
        "label": "Gender",
        "grid": 6,
        required: true,
    },

    {
        "varName": "residentStatus",
        "type": "select",
        "label": "Resident Status",
        "enum": ["Resident", "Non-Resident"],
        "grid": 6,
        required: true,
    },

    {
        "varName": "nationalIdCard",
        "type": "text",
        "label": "National Id Card",
        "grid": 6,
        required: true,
    },

    {
        "varName": "dob2",
        "type": "date",
        "label": "D.O.B",
        "grid": 6,
        required: true,
    },

    {
        "varName": "father",
        "type": "text",
        "label": "Father ",
        "grid": 6,
        required: true,
    },

    {
        "varName": "mother",
        "type": "text",
        "label": "Mother",
        "grid": 6,
        required: true,
    },

    {
        "varName": "maritialStatus",
        "type": "select",
        "label": "Maritial Status",
        "enum": ["Yes", "No"],
        "grid": 12,
        required: true,
    },
    {
        "varName": "spouse",
        "type": "text",
        "label": "Spouse",
        "conditional": true,
        "conditionalVarName": "maritialStatus",
        "conditionalVarValue": "Yes",
        "grid": 6,
        required: true,
    },

    {
        "varName": "pangirNo",
        "type": "text",
        "label": "PAN GIR No",
        "grid": 6,
    },

    {
        "varName": "passportNo",
        "type": "text",
        "label": "Passport No",
        "grid": 6,
    },

    {
        "varName": "issueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
    },

    {
        "varName": "passportDetails",
        "type": "text",
        "label": "Passport Details",
        "grid": 6,
    },

    {
        "varName": "expiryDate",
        "type": "date",
        "label": "Expiry Date",
        "grid": 6,
    },

    {
        "varName": "freeText5",
        "type": "text",
        "label": "Free Text 5",
        "grid": 6,
        "length": 17,
    },

    {
        "varName": "freeText13",
        "type": "text",
        "label": "Free Text 13",
        "grid": 6,
        required: true,
    },

    {
        "varName": "freeText14",
        "type": "text",
        "label": "Free Text 14",
        "maxLength":15,
        "grid": 6,
        required: true,
    },


    {
        "varName": "freeText15",
        "type": "text",
        "label": "Free Text 15",
        "grid": 6,
        required: true,
    },
    {
        "label": "IIF Page 2",
        "type": "title",
        "grid": 12,
    },
    {
        "varName": "communicationAddress1",
        "type": "text",
        "label": "Communication Address 1",
        "grid": 6,
        required: true,
    },

    {
        "varName": "communicationAddress2",
        "type": "text",
        "label": "Communication Address 2",
        "grid": 6,
    },

    {
        "varName": "city2",
        "label": "City",
        "type": "select",
        "enum": ["Dhaka", "Chittagong", "Barishal", "Rajshahi", "Khulna"],
        "grid": 6,
        required: true,
    },

    {
        "varName": "state2",
        "type": "text",
        "label": "State",
        "grid": 6,
        required: true,
    },

    {
        "varName": "postalCode3",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
        required: true,
    },

    {
        "varName": "country4",
        "type": "select",
        "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
        "label": "Country",
        "grid": 6,
        required: true,
    },

    {
        "varName": "phoneNo12",
        "type": "text",
        "label": "Phone No 1 ",
        "grid": 6,
        "length": 13,
        required: true,
    },

    {
        "varName": "phoneNo21",
        "type": "text",
        "label": "Phone No 2",
        "grid": 6,
    },

    {
        "varName": "permanentAddress1",
        "type": "text",
        "label": "Permanent Address 1",
        "grid": 6,
        required: true,
    },

    {
        "varName": "permanentAddress2",
        "type": "text",
        "label": "Permanent Address 2",
        "grid": 6,
    },

    {
        "varName": "city3",
        "type": "select",
        "enum": ["Dhaka", "Chittagong", "Barishal", "Rajshahi", "Khulna"],
        "label": "City",
        "grid": 6,
        required: true,
    },

    {
        "varName": "state3",
        "type": "text",
        "label": "State",
        "grid": 6,
        required: true,
    },

    {
        "varName": "postalCode4",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
        required: true,
    },

    {
        "varName": "country5",
        "type": "select",
        "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
        "label": "Country",
        "grid": 6,
        required: true,
    },

    {
        "varName": "phoneNo22",
        "type": "text",
        "label": "Phone No 2",
        "grid": 6,
    },

    {
        "varName": "email2",
        "type": "text",
        "label": "Email",
        "grid": 6,
    },

    {
        "varName": "employerAddress1",
        "type": "text",
        "label": "Employer Address 1",
        "grid": 6,
        required: true,
    },

    {
        "varName": "employerAddress2",
        "type": "text",
        "label": "Employer Address 2",
        "grid": 6,
    },

    {
        "varName": "city4",
        "type": "select",
        "enum": ["Dhaka", "Chittagong", "Barishal", "Rajshahi", "Khulna"],
        "label": "City",
        "grid": 6,
        required: true,
    },

    {
        "varName": "state4",
        "type": "text",
        "label": "State",
        "grid": 6,
        required: true,
    },

    {
        "varName": "postalCode5",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "country6",
        "type": "select",
        "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "phoneNo13",
        "type": "text",
        "label": "Phone No 1 ",
        "grid": 6,
        "length": 13,
    },

    {
        "varName": "phoneNo23",
        "type": "text",
        "label": "Phone No 2",
        "grid": 6,
    },

    {
        "varName": "telexNo1",
        "type": "text",
        "label": "Telex No",
        "grid": 6,
    },

    {
        "varName": "email3",
        "type": "text",
        "label": "Email",
        "grid": 6,
    },

    {
        "varName": "faxNo",
        "type": "text",
        "label": "Fax No",
        "grid": 6,
    },

    {
        "label": "KYC",
        "type": "title",
        "grid": 12,
    },
    {
        "varName": "acNo",
        "type": "text",
        "label": "Ac No",
        "grid": 6,
        "length": 13,
        required: true,
    },

    {
        "varName": "acTitle",
        "type": "text",
        "label": "Ac Title",
        "grid": 6,
    },

    {
        "varName": "customerOccupation",
        "type": "select",
        "enum": ["Teacher", "Doctor", "House-Wife", "Privet Job Holder"],
        "label": "Customer Occupation",
        "grid": 6,
        required: true,
    },

    {
        "varName": "docCollectToEnsure",
        "type": "text",
        "label": "Doc Collect To Ensure Source Of Fund",
        "grid": 6,
        required: true,
    },

    {
        "varName": "collectedDocHaveBeenVerified",
        "type": "select",
        "enum": ["Yes", "No"],
        "label": "Collected Doc Have Been Verified",
        "grid": 6,
        required: true,
    },

    {
        "varName": "howTheAddress",
        "type": "select",
        "enum": ["Yes", "No"],
        "label": "How The Address Is Verified",
        "grid": 6,
        required: true,
    },

    {
        "varName": "hasTheBeneficialOwner",
        "type": "select",
        "enum": ["Yes", "No"],
        "label": "Has The Beneficial Owner Of The Ac Been Identified",
        "grid": 6,
        required: true,
    },

    {
        "varName": "whatDoesTheCustomer",
        "type": "select",
        "enum": ["Yes", "No"],
        "label": "What Does The Customer Do/in What Type Of Business Is The Customer Engaged",
        "grid": 6,
        required: true,
    },

    {
        "varName": "customersMonthlyIncome",
        "type": "select",
        "enum": [20000, 50000, 100000],
        "label": "Customers Monthly Income",
        "grid": 6,
        required: true,
    },

    {
        "label": "TP",
        "type": "title",
        "grid": 12,
    },
    {
        "varName": "acNo",
        "type": "text",
        "label": "Ac No",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "monthlyProbableIncome",
        "type": "text",
        "label": "Monthly Probable Income",
        "grid": 6,
        required: true,
    },

    {
        "varName": "monthlyProbableTournover",
        "type": "text",
        "label": "Monthly Probable Tournover",
        "grid": 6,
        required: true,
    },

    {
        "varName": "sourceOfFund",
        "type": "text",
        "label": "Source Of Fund",
        "grid": 6,
        required: true,
    },

    {
        "varName": "cashDeposit",
        "type": "text",
        "label": "Cash Deposit",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "DepositByTransfer",
        "type": "text",
        "label": "  Deposit By Transfer",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "foreignInwardRemittance",
        "type": "text",
        "label": "Foreign Inward Remittance",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "depositIncomeFromExport",
        "type": "text",
        "label": "Deposit Income From Export",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "deposittransferFromBoAc",
        "type": "text",
        "label": "Deposittransfer From Bo Ac",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "others1",
        "type": "text",
        "label": "Others",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "totalProbableDeposit",
        "type": "text",
        "label": "Total Probable Deposit",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "cashWithdrawal",
        "type": "text",
        "label": "Cash Withdrawal",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "withdrawalThrough",
        "type": "text",
        "label": "Withdrawal Through Transferinstrument",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "foreignOutwardRemittance",
        "type": "text",
        "label": "Foreign Outward Remittance",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "paymentAgainstImport",
        "type": "text",
        "label": "Payment Against Import",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "deposittransferToBoAc",
        "type": "text",
        "label": "Deposittransfer To Bo Ac",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "others2",
        "type": "text",
        "label": "Others",
        "grid": 6,
        required: true,
        numeric: true,
    },

    {
        "varName": "totalProbableWithdrawal",
        "type": "text",
        "label": "Total Probable  Withdrawal",
        "grid": 6,
        required: true,
        numeric: true,
    },
    {
        "label": "Others",
        "type": "title",
        "grid": 12,
    },
    {
        "varName": "status",
        "type": "text",
        "label": "Status",
        "grid": 6,
        required: true,
    },

    {
        "varName": "statusAsOnDate",
        "type": "date",
        "label": "Status As On Date",
        "grid": 6,
        required: true,
    },

    {
        "varName": "acManager",
        "type": "text",
        "label": "Ac Manager",
        "grid": 6,
        required: true,
    },

    {
        "varName": "occuoationCode",
        "type": "text",
        "label": "Occuoation Code",
        "grid": 6,
        required: true,
    },

    {
        "varName": "constitution",
        "type": "text",
        "label": "Constitution",
        "grid": 6,
        required: true,
    },

    {
        "varName": "staffFlag",
        "type": "select",
        "enum": ["Yes", "No"],
        "grid": 6,
    },

    {
        "varName": "staffNumber",
        "type": "text",
        "label": "Staff Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "staffFlag",
        "conditionalVarValue": "Yes",
    },

    {
        "varName": "minor",
        "type": "text",
        "label": "Minor",
        "grid": 6,
        required: true,
    },

    {
        "varName": "trade",
        "type": "text",
        "label": "Trade",
        "grid": 6,
        required: true,
    },

    {
        "varName": "telexNo2",
        "type": "text",
        "label": "Telex No",
        "grid": 6,
    },

    {
        "varName": "telexNo3",
        "type": "text",
        "label": "Telex No",
        "grid": 6,
    },

    {
        "varName": "combineStatement",
        "type": "text",
        "label": "Combine Statement",
        "grid": 6,
        required: true,
    },

    {
        "varName": "tds",
        "type": "text",
        "label": "Tds",
        "grid": 6,
    },

    {
        "varName": "purgedAllowed",
        "type": "date",
        "label": "Purged Allowed",
        "grid": 6,
    },

    {
        "varName": "freeText2",
        "type": "text",
        "label": "Free Text 2",
        "grid": 6,
    },

    {
        "varName": "freeText8",
        "type": "text",
        "label": "Free Text 8",
        "grid": 6,
    },

    {
        "varName": "freeText9",
        "type": "text",
        "label": "Free Text 9",
        "grid": 6,
    },

    {
        "varName": "freeCode1",
        "type": "text",
        "label": "Free Code 1",
        "grid": 6,
    },

    {
        "varName": "freeCode3",
        "type": "text",
        "label": "Free Code 3",
        "grid": 6,
    },

    {
        "varName": "freeCode71",
        "type": "text",
        "label": "Free Code 7",
        "grid": 6,
        required: true,
    },

    {
        "varName": "accountManager",
        "type": "text",
        "label": "Account Manager",
        "grid": 6,
        required: true,
    },

    {
        "varName": "cashLimit",
        "type": "text",
        "label": "Cash Limit",
        "grid": 6,
    },

    {
        "varName": "clearingLimit",
        "type": "text",
        "label": "Clearing Limit",
        "grid": 6,
    },

    {
        "varName": "transferLimit",
        "type": "text",
        "label": "Transfer Limit",
        "grid": 6,
    },

    {
        "varName": "remarks",
        "type": "text",
        "label": "Remarks",
        "grid": 6,
    },

    {
        "varName": "statementFrequency",
        "type": "text",
        "label": "Statement Frequency",
        "grid": 6,
        required: true,
    },

    {
        "varName": "occupationOode",
        "type": "text",
        "label": "Occupation Oode",
        "grid": 6,
        required: true,
    },

    {
        "varName": "freeCode2",
        "type": "text",
        "label": "Free Code 1 ",
        "grid": 6,
        required: true,
    },

    {
        "varName": "freeCode7",
        "type": "text",
        "label": "Free Code 7",
        "grid": 6,
        required: true,
    },

    {
        "varName": "freeCode9",
        "type": "text",
        "label": "Free Code 9",
        "grid": 6,
        required: true,
    },

    {
        "varName": "freeCode10",
        "type": "text",
        "label": "Free Code 10",
        "grid": 6,
        required: true,
    },

    {
        "varName": "freeText11",
        "type": "text",
        "label": "Free Text 11",
        "grid": 6,
        required: true,
    },

    {
        "varName": "currencyCode",
        "type": "text",
        "label": "Currency Code",
        "grid": 6,
        required: true,
    },

    {
        "varName": "withHoldingTax",
        "type": "text",
        "label": "With Holding Tax % ",
        "grid": 6,
        required: true,
    },

    {
        "varName": "function",
        "type": "text",
        "label": "Function",
        "grid": 6,
        required: true,
    },

    {
        "varName": "trialMode",
        "type": "text",
        "label": "Trial Mode",
        "grid": 6,
        required: true,
    },
    {
        "varName": "remarks",
        "type": "text",
        "label": "Remarks",
        "grid": 6,
        required: true,
    },
];

let MAKERJsonFormIndividualAccountOpening = {};

MAKERJsonFormIndividualAccountOpening = JSON.parse(JSON.stringify(SDCommonNewJsonFormIndividualAccountOpeningFinacle));


let CHECKERNewJsonFormIndividualAccountOpeningFinacle = {};
CHECKERNewJsonFormIndividualAccountOpeningFinacle = makeReadOnlyObject(JSON.parse(JSON.stringify(SDCommonNewJsonFormIndividualAccountOpeningFinacle)));
//CHECKERNewJsonFormIndividualAccountOpeningFinacle.variables.push(checkerApprove)


//####SD Account Opening Non-individual#######last update
let BMCommonjsonFormNonIndividualAccountOpeningSearch = [

    {
        "varName": "companyName",
        "type": "text",
        "label": "Company Name",
        "required": false,
        "readOnly": false,
        "grid": 4


    },

    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "required": false,

        "readOnly": false,
        "grid": 4


    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile Number",
        "required": false,
        "grid": 4

    },

    {
        "varName": "companyEtin",
        "type": "text",
        "label": "Company ETin",
        "required": false,
        "grid": 4


    },
    {
        "varName": "tradeLicense",
        "type": "text",
        "label": "Trade License",
        "required": false,
        "readOnly": false,
        "grid": 4


    },
    {
        "varName": "certificate",
        "type": "text",
        "label": "Certificate Of Incorporation",
        "required": false,
        "grid": 4


    }


];
let BMjsonFormNonIndividualProprietorshipAccountOpeningSearch = makeReadOnlyObject(JSON.parse(JSON.stringify(BMCommonjsonFormNonIndividualAccountOpeningSearch.concat(CommonCasaAccountOpening))));
let SDCommonNewJsonFormNonIndividualAccountOpeningFinacle = [


    {
        // seal
        "varName": "relationshipBranchName",
        "type": "text",
        "label": "Relationship Branch Name",
        "grid": 6

    },
    {
        "varName": "date",
        "type": "date",
        "label": "Date",
        "grid": 6

    },
    {
        "varName": "branchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 6
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "Unique Customer ID",
        "grid": 6
    },
    {
        "varName": "accountNo",
        "type": "text",
        "label": "Account No",
        "grid": 6
    },
    {
        "varName": "title",
        "type": "text",
        "label": "Title",
        "grid": 12
    },
    {
        "varName": "shortName",
        "type": "text",
        "label": "Short Name",
        "grid": 12
    },
    {
        "varName": "purposeOfOpening",
        "type": "text",
        "label": "Purpose of Opening",
        "grid": 6
    },
    {
        // tick
        "varName": "accountType",
        "type": "checkbox",
        "label": "Type Of Account",
        "grid": 6
    },
    {

        "varName": "typeOfOrganization",
        "type": "checkbox",
        "label": "Type of Organization",
        "grid": 6
    },
    {

        "varName": "currency",
        "type": "checkbox",
        "label": "Currency",
        "grid": 6
    },
    {
        // tick
        "varName": "natureOfBusiness",
        "type": "checkbox",
        "label": "Nature Of Business",
        "grid": 12
    },
    {
        // seal
        "varName": "typeOfProductService",
        "type": "text",
        "label": "Type of Product/Service",
        "grid": 6

    },
    {
        "varName": "totalManpower",
        "type": "text",
        "label": "Total Manpower",
        "grid": 6

    }, {
        "varName": "yearlyTurnover",
        "type": "text",
        "label": "Yearly Turnover",
        "grid": 6
    },
    {
        "varName": "netAsset",
        "type": "text",
        "label": "Net Asset",
        "grid": 6
    },
    {
        "varName": "otherInformation",
        "type": "text",
        "label": "Other Information(if any)",
        "grid": 6
    },
    {
        "varName": "comRegAddress",
        "type": "text",
        "label": "Com. Reg. Address",
        "grid": 6
    },
    {
        "varName": "comRegUpazila",
        "type": "text",
        "label": "Upazila",
        "grid": 6
    },
    {
        "varName": "comRegDistrict",
        "type": "text",
        "label": "District",
        "grid": 6
    },
    {
        // tick
        "varName": "comRegPostCode",
        "type": "text",
        "label": "Post Code",
        "grid": 6
    },
    {

        "varName": "comRegCountry",
        "type": "text",
        "label": "Country",
        "grid": 6
    },
    {

        "varName": "bussOffcAddress",
        "type": "text",
        "label": "Buss/offc. Address",
        "grid": 6
    },
    {
        // tick
        "varName": "bussOffcUpazila",
        "type": "text",
        "label": "Upazila",
        "grid": 6
    },
    {
        // tick
        "varName": "bussOffcDistrict",
        "type": "text",
        "label": "District",
        "grid": 6
    },
    {
        // Numeric & Check Box
        "varName": "bussOffcPostCode",
        "type": "text",
        "label": "Post Code",
        "grid": 6
    },
    {
        "varName": "bussOffcCountry",
        "type": "text",
        "label": "Country",
        "grid": 6
    },
    {
        "varName": "factAddress",
        "type": "text",
        "label": "Fact. Address",
        "grid": 6
    },
    {
        "varName": "factUpazila",
        "type": "text",
        "label": "Upazila",
        "grid": 6
    },

    {
        "varName": "factDistrict",
        "type": "text",
        "label": "District",
        "grid": 6
    },
    {
        "varName": "factPostCode",
        "type": "text",
        "label": "Post Code",
        "grid": 6
    },
    {
        "varName": "factCountry",
        "type": "text",
        "label": "Country",
        "grid": 6
    },

    {
        "varName": "initialDeposit ",
        "type": "checkbox",
        "label": "Initial Deposit",
        "grid": 6
    },
    {

        "varName": "otherInformation",
        "type": "title",
        "label": "Other Information",
        "grid": 12
    },
    {
        // tick
        "varName": "tradeLicenseNo",
        "type": "text",
        "label": "Trade License No.",
        "grid": 6
    },
    {
        "varName": "tradeLicenseDate",
        "type": "date",
        "label": "Date",
        "grid": 6
    },
    {
        "varName": "issuingAuthority",
        "type": "text",
        "label": "Issuing Authority",
        "grid": 6
    },
    {
        "varName": "registrationNo",
        "type": "text",
        "label": "Registration No",
        "grid": 6
    },
    {
        // tick
        "varName": "registrationNoDate",
        "type": "date",
        "label": "Date",
        "grid": 6
    },
    {
        // Numeric & Check Box
        "varName": "registrationAuthority",
        "type": "text",
        "label": "Registration Authority",
        "grid": 6
    },
    {
        "varName": "country",
        "type": "text",
        "label": "Country",
        "grid": 6
    },
    {
        "varName": "etin",
        "type": "text",
        "label": "E-TIN No.",
        "grid": 6
    },
    {
        "varName": "vATRegistrationNo",
        "type": "text",
        "label": "VAT Registration No",
        "grid": 6
    },
    {

        "varName": "introducerInformation",
        "type": "title",
        "label": "Introducer's Information",
        "grid": 12
    },

    {
        "varName": "introName",
        "type": "text",
        "label": "Introducer's Name",
        "grid": 6
    },
    {
        "varName": "relationshipWithApplicant",
        "type": "text",
        "label": "Relationship with Applicant(s)",
        "grid": 6
    },
    {
        "varName": "introAccountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6
    },
    {
        // Alpha Numeric
        "varName": "introducerCB",
        "type": "text",
        "label": "Customer ID",
        "grid": 6
    },
    {
        "varName": "introducerContactNumber",
        "type": "text",
        "label": "Contact Number",
        "grid": 12
    },
    {
        "varName": "introAccountName",
        "type": "text",
        "label": "Account Name",
        "grid": 6
    },
    {
        // Alpha Numeric
        "varName": "introducerBranchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 6
    },
    {
        // Alpha Numeric
        "varName": "introducerSourceOfFund",
        "type": "text",
        "label": "Source of Fund",
        "grid": 6
    },
    {
        "varName": "forBankUseOnly",
        "type": "title",
        "label": "For Bank Use Only",
        "grid": 12
    },
    {
        "varName": "nameOfDMERMBrStaff",
        "type": "text",
        "label": "Name of DME/RM/Br Staff",
        "grid": 6
    },
    {
        "varName": "sBSSectorCode",
        "type": "text",
        "label": "SBS Sector Code",
        "grid": 6
    },
    {
        "varName": "sBSDepositCode",
        "type": "text",
        "label": "SBS Deposit Code",
        "grid": 6
    },
    {
        "varName": "rMDMEBrStaffCode",
        "type": "text",
        "label": "RM/DME/Br Staff Code",

    }, {
        "varName": "productSchemeCode",
        "type": "text",
        "label": "Product Scheme Code",
        "grid": 6
    },
    {
        "varName": "aCOccupationCode",
        "type": "text",
        "label": "A/C Occupation Code",
        "grid": 6
    },
    {
        "varName": "workflowInput",
        "type": "title",
        "label": "Workflow Input",
        "grid": 12
    },
    {
        "varName": "debitCard",
        "type": "text",
        "label": "Debit Card",
        "grid": 6
    },
    {
        "varName": "phone1",
        "type": "text",
        "label": "Mobile Number 1",
        "grid": 6
    },
    {
        "varName": "phone2",
        "type": "text",
        "label": "Mobile Number 2",
        "grid": 6
    },
    {
        "varName": "smsAlertRequest",
        "type": "select",
        "label": "Sms Alert Request",
        "grid": 6,
        "enum": [
            "YES",
            "NO"
        ]

    }, {
        "varName": "companyCode",
        "type": "text",
        "label": "Company Code",
        "grid": 6
    },
    {
        "varName": "companyName",
        "type": "text",
        "label": "Company Name",
        "grid": 6
    },

    {
        "varName": "title",
        "type": "title",
        "label": "IIF",
        "grid": 12
    },
    {
        "varName": "date",
        "type": "date",
        "label": "Date",
        "grid": 6
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "Unique Customer ID",
        "grid": 6
    },
    {
        "varName": "accountNo",
        "type": "text",
        "label": "A/C No.",
        "grid": 6
    },
    {
        "varName": "relationWithTheAC",
        "type": "text",
        "label": "Relation with the A/C",
        "grid": 6
    },
    {
        "varName": "accountName",
        "type": "text",
        "label": "Account Name",
        "grid": 6
    },
    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer's Name",
        "grid": 6
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Relation",
        "grid": 12
    },
    {
        "varName": "fatherName",
        "type": "text",
        "label": "Father's Name",
        "grid": 6
    },
    {
        "varName": "motherName",
        "type": "text",
        "label": "Mother's Name",
        "grid": 6
    },
    {
        "varName": "spouseName",
        "type": "text",
        "label": "Spouse' Name",
        "grid": 6
    }, {
        "varName": "dob",
        "type": "date",
        "label": "DOB",
        "grid": 6
    },
    {
        "varName": "religion",
        "type": "text",
        "label": "Religion",
        "grid": 6
    },
    {
        "varName": "gender",
        "type": "text",
        "label": "Gender",
        "grid": 6
    },
    {
        "varName": "nid",
        "type": "text",
        "label": "NID",
        "grid": 6
    },
    {
        "varName": "birthCertificate",
        "type": "text",
        "label": "Birth certificate",
        "grid": 6
    },
    {
        "varName": "residentStatus",
        "type": "text",
        "label": "Resident status",
        "grid": 6
    },
    {
        "varName": "passportNo",
        "type": "text",
        "label": "Passport No",
        "grid": 6
    },
    {
        "varName": "passportIssueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6
    },
    {
        "varName": "passportExpDate",
        "type": "date",
        "label": "Exp Date",
        "grid": 6
    },
    {
        "varName": "passportPlaceOfIssue",
        "type": "text",
        "label": "Place of issue",
        "grid": 6
    }
];


let MAKERJsonFormNonIndividualAccountOpening = {};
MAKERJsonFormNonIndividualAccountOpening = JSON.parse(JSON.stringify(SDCommonNewJsonFormNonIndividualAccountOpeningFinacle));


let CHECKERNewJsonFormNonIndividualAccountOpeningFinacle = {};
CHECKERNewJsonFormNonIndividualAccountOpeningFinacle = makeReadOnlyObject(JSON.parse(JSON.stringify(SDCommonNewJsonFormNonIndividualAccountOpeningFinacle)));
//CHECKERNewJsonFormIndividualAccountOpeningFinacle.variables.push(checkerApprove)
//######Existing Account Opening Joint#########


let CSExistJsonFormJointAccountOpening = {};
CSExistJsonFormJointAccountOpening["variables"] = JSON.parse(JSON.stringify(CommonExistJsonFormIndividualAccountOpening.concat(commonJsonFormIndividualAccountOpeningForm)));
CSExistJsonFormJointAccountOpening.variables.push(csSendTo);


let CSNewJsonFormJointAccountOpening = {};
CSNewJsonFormJointAccountOpening["variables"] = JSON.parse(JSON.stringify(CommonNewJsonFormIndividualAccountOpening.concat(commonJsonFormIndividualAccountOpeningForm)));
CSNewJsonFormJointAccountOpening.variables.push(csSendTo);


//######BM Account Opening Joint#########
let BMJsonFormJointAccountOpening = {};
BMJsonFormJointAccountOpening["variables"] = makeReadOnlyObject(JSON.parse(JSON.stringify(CommonNewJsonFormIndividualAccountOpening.concat(commonJsonFormIndividualAccountOpeningForm))));
BMJsonFormJointAccountOpening.variables.push(bmApprove);
BMJsonFormJointAccountOpening.variables.push(bmSendTo);


//######Existing Account Opening Proprietorship#########


let CSExistJsonFormProprietorshipAccountOpening = {};
CSExistJsonFormProprietorshipAccountOpening["variables"] = JSON.parse(JSON.stringify(CommonExistJsonFormIndividualAccountOpening.concat(commonJsonFormIndividualAccountOpeningForm)));
CSExistJsonFormProprietorshipAccountOpening.variables.push(csSendTo);


let CSNewJsonFormProprietorshipAccountOpening = {};
CSNewJsonFormProprietorshipAccountOpening["variables"] = JSON.parse(JSON.stringify(CommonNewJsonFormIndividualAccountOpening.concat(commonJsonFormIndividualAccountOpeningForm)));
CSNewJsonFormProprietorshipAccountOpening.variables.push(csSendTo);

//######BOM Account Opening Proprietorship#########
let BOMNewJsonFormProprietorshipAccountOpening = {};
BOMNewJsonFormProprietorshipAccountOpening["variables"] = makeReadOnlyObject(JSON.parse(JSON.stringify(CommonNewJsonFormIndividualAccountOpening.concat(commonJsonFormIndividualAccountOpeningForm))));
BOMNewJsonFormProprietorshipAccountOpening.variables.push(bomApproval);
BOMNewJsonFormProprietorshipAccountOpening.variables.push(bomSendTo);

//######BM Account Opening Proprietorship#########
let BMJsonFormProprietorshipAccountOpening = {};
BMJsonFormProprietorshipAccountOpening["variables"] = makeReadOnlyObject(JSON.parse(JSON.stringify(CommonNewJsonFormIndividualAccountOpening.concat(commonJsonFormIndividualAccountOpeningForm))));
BMJsonFormProprietorshipAccountOpening.variables.push(bmApprove);
BMJsonFormProprietorshipAccountOpening.variables.push(bmSendTo);

//##DebitCard  Finacle #####
let CommonJsonFormForDebitCard = [


    {
        "varName": "name",
        "type": "text",
        "label": "Customer Name",

    },
    {
        "varName": "gender",
        "type": "select",
        "label": "Gender ?",
        "enum": [
            "MALE",
            "FEMALE",

        ]

    },
    {
        "varName": "document",
        "type": "text",
        "label": "Document",

    },
    {
        "varName": "dateOfBirth",
        "type": "date",
        "label": "Date of birth",

    },
    {
        "varName": "accountNo",
        "type": "text",
        "label": "Account No",

    },

    {
        "varName": "accountType",
        "type": "select",
        "label": "Account Type ?",
        "enum": [
            "SAVINGS",
            "CURRENT",
            "RFCD",
            "OTHER"

        ]

    },
    {
        "varName": "Transliteration",
        "type": "text",
        "label": "Transliteration",

    },
    {
        "varName": "address",
        "type": "text",
        "label": "Address",

    },
    {
        "varName": "email",
        "type": "text",
        "label": "E-mail",

    },
    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile phone"
    },
    {
        "varName": "nationality",
        "type": "text",
        "label": "Nationality"
    },
    {
        "varName": "dse/rm",
        "type": "text",
        "label": "DSE/RM Code"
    },
    {
        "varName": "applicationSource",
        "type": "text",
        "label": "Application Source"
    },
    {
        "varName": "branchNameReceiveCard",
        "type": "text",
        "label": "Branch Name to Receive Card"
    },
    {
        "varName": "corporatename",
        "type": "text",
        "label": "Corporate Name"
    },
    {
        "varName": "passport",
        "type": "text",
        "label": "Passport Number"
    },
    {
        "varName": "passport",
        "type": "text",
        "label": "Passport name"
    },
    {
        "varName": "passportExpiredate",
        "type": "date",
        "label": "Passport Expiry Date"
    },
    {
        "varName": "passportIssuedate",
        "type": "date",
        "label": "Passport Issue Date"
    },
    {
        "varName": "limitProfile",
        "type": "date",
        "label": "Limit profile"
    },
    {
        "varName": "cardType",
        "type": "select",
        "label": "Card type ",
        "enum": [
            "MASTER",
            "VISA",
            "MAXX"
        ]
    },
    {
        "varName": "productType",
        "type": "select",
        "label": "Product type",
        "enum": [
            "GENERAL",
            "STAFF",
            "CORPORATE",
            "OTHER"
        ]
    },
    {
        "varName": "initialBranchSolId",
        "type": "text",
        "label": "Initiating Branch SOL ID"
    },
    {
        "varName": "casaSolId",
        "type": "text",
        "label": "CASA SOL ID "
    }


];
//## CS DebitCard ####
let CSJsonFormForDebitCard = {};
CSJsonFormForDebitCard["variables"] = JSON.parse(JSON.stringify(CommonJsonFormForDebitCard));
CSJsonFormForDebitCard.variables.push(csSendTo);

//## BOM DebitCard ####
let BOMJsonFormForDebitCard = {};
BOMJsonFormForDebitCard["variables"] = makeReadOnlyObject(JSON.parse(JSON.stringify(CommonJsonFormForDebitCard)));
BOMJsonFormForDebitCard.variables.push(bomApproval);
BOMJsonFormForDebitCard.variables.push(bomSendTo);

//## BM DebitCard ####
let BMJsonFormForDebitCard = {};
BMJsonFormForDebitCard["variables"] = makeReadOnlyObject(JSON.parse(JSON.stringify(CommonJsonFormForDebitCard)));
BMJsonFormForDebitCard.variables.push(bmApprove);
BMJsonFormForDebitCard.variables.push(bmSendTo);

//## Maker DebitCard ####
let MAKERJsonFormForDebitCard = {};
MAKERJsonFormForDebitCard["variables"] = makeReadOnlyObject(JSON.parse(JSON.stringify(CommonJsonFormForDebitCard)));

//## Checker DebitCard ####
let CHECKERJsonFormForDebitCard = {};
CHECKERJsonFormForDebitCard["variables"] = makeReadOnlyObject(JSON.parse(JSON.stringify(CommonJsonFormForDebitCard)));
CHECKERJsonFormForDebitCard.variables.push(checkerApprove);

//#####DDeup Search Individual Form#############
let CSjsonFormIndividualAccountOpeningSearch = {
    "variables": [
        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB Number",
            "errorMessage": "Error",
            "min": 9,
            "max": 9,

        },

        {
            "varName": "nid",
            "type": "text",
            "label": "NID",
            "errorMessage": "Error",
            "nid": true

        },
        {
            "varName": "passport",
            "type": "text",
            "label": "Passport",


        },
        {
            "varName": "customerName",
            "type": "text",
            "label": "Customer Name",
            "multiline": true,


        },
        {
            "varName": "dob",
            "type": "date",
            "label": "Date Of Birth",
            "required": true


        },
        {
            "varName": "email",
            "type": "text",
            "multiline": true,
            "errorMessage": "Error",
            "label": "Email",
            "email": true


        },

        {
            "varName": "phone",
            "type": "text",
            "errorMessage": "Error",
            "label": "Mobile Number",
            "required": true,
            "phone": true


        },

        {
            "varName": "tin",
            "type": "text",
            "label": "E-Tin",


        },
        {
            "varName": "registrationNo",
            "type": "text",
            "label": "Birth Cer./Driving License",
        },
        {
            "varName": "nationality",
            "type": "select",
            "label": "Nationality",
            "enum": [
                "BANGLADESH",
                "JAPAN",
                "INDIA"
            ],
            "required": true
        },


    ],

};
//#####DDeup Search Individual Form#############
let CSjsonFormIndividualAccountOpeningSearchForwardTo = [


    {
        "varName": "nid",
        "type": "text",
        "label": "NID",
        "grid": 6

    },
    {
        "varName": "passport",
        "type": "text",
        "label": "Passport",
        "grid": 6


    },
    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name",
        "required": true,
        "grid": 6

    },
    {
        "varName": "dob",
        "type": "date",
        "label": "Date Of Birth",
        "required": true,
        "grid": 6


    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "grid": 6,


    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile Number",
        "required": true,
        "grid": 6


    },

    {
        "varName": "tin",
        "type": "text",
        "label": "eTin",
        "grid": 6


    },


    {
        "varName": "registrationNo",
        "type": "text",
        "label": "Birth Cer./Driving License",
        "grid": 6


    },
    {
        "varName": "nationality",
        "type": "text",
        "label": "Nationality",
        "required": true,
        "grid": 6


    },


];
//#####DDeup Search Joint Form#############
let CSjsonFormJointAccountOpeningSearchForwardTo = [

    {
        "varName": "cbNumber",
        "type": "text",
        "label": "Cb Number",
        "grid": 6


    },
    {
        "varName": "accountSource",
        "type": "text",
        "label": "Account Source",
        "grid": 6


    },
    {
        "varName": "accountTitle",
        "type": "text",
        "label": "Account TItle",
        "grid": 6


    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "grid": 6,


    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile Number",
        "required": true,
        "grid": 6


    }
];


//#####DDeup Search NonIndividual Form#############
let CSjsonFormNonIndividualAccountOpeningSearch = [


    {
        "varName": "cbNumber",
        "type": "text",
        "label": "CB Number",
        "required": false,
        "readOnly": false
    },

    {
        "varName": "companyName",
        "type": "text",
        "label": "Company Name",
        "required": false,
        "readOnly": false

    },

    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "required": false,

        "readOnly": false


    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile Number",
        "required": false

    },

    {
        "varName": "companyEtin",
        "type": "text",
        "label": "Company ETin",
        "required": false,


    },
    {
        "varName": "tradeLicense",
        "type": "text",
        "label": "Trade License",
        "required": false,
        "readOnly": false


    },
    {
        "varName": "certificate",
        "type": "text",
        "label": "Certificate Of Incorporation",
        "required": false,


    }


];
//#####DDeup Search NonIndividual Form#############

let CSjsonFormNonIndividualAccountOpeningSearchForwardTo = [

    {
        "varName": "companyName",
        "type": "text",
        "label": "Company Name",
        "required": false,
        "readOnly": false,
        "grid": 6


    },

    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "required": false,

        "readOnly": false,
        "grid": 6


    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile Number",
        "required": false,
        "grid": 6

    },

    {
        "varName": "companyEtin",
        "type": "text",
        "label": "Company ETin",
        "required": false,
        "grid": 6


    },
    {
        "varName": "tradeLicense",
        "type": "text",
        "label": "Trade License",
        "required": false,
        "readOnly": false,
        "grid": 6


    },
    {
        "varName": "certificate",
        "type": "text",
        "label": "Certificate Of Incorporation",
        "required": false,
        "grid": 6


    }


];
//#####DDeup Search NonIndividual Form#############
let CSjsonFormIndividualJointAccountOpeningSearch = {
    "variables": [

        {
            "varName": "numberOfCustomer",
            "type": "text",
            "label": "Number Of Customer ?",
            "required": true,
            "number": true,
        }
    ]

};


//#####DDeup Search NonIndividual/Propritorship Form#############final
let CSjsonFormNonIndividualProprietorshipAccountOpeningSearch = {
    "variables": [
        {
            "varName": "companyTitle",
            "type": "title",
            "label": "Company/Business",

        },
        {
            "varName": "companyCbNumber",
            "type": "text",
            "label": "CB Number",
            "readOnly": false,
            "min": 9,
            "max": 9,
        },

        {
            "varName": "companyName",
            "type": "text",
            "label": "Company Name",
            "required": true,

        },

        {
            "varName": "companyEmail",
            "type": "text",
            "label": "Email",


        },

        {
            "varName": "companyMobileNo",
            "type": "text",
            "label": "Mobile Number",

            "required": false

        },

        {
            "varName": "companyEtin",
            "type": "text",
            "label": "Company ETin",
            "required": true,


        },
        {
            "varName": "companyTradeLicense",
            "type": "text",
            "label": "Trade License",
            "required": true,


        },
        {
            "varName": "certificate",
            "type": "text",
            "label": "Certificate Of Incorporation",
            "required": true,


        },
        {
            "varName": "companyIndividualTitle",
            "type": "title",
            "label": "Proprietor Dedup",

        },
        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB Number",
            "errorMessage": "Error",
            "min": 9,
            "max": 9,

        },

        {
            "varName": "nid",
            "type": "text",
            "label": "NID",
            "errorMessage": "Error",
            "nid": true

        },
        {
            "varName": "passport",
            "type": "text",
            "label": "Passport",


        },
        {
            "varName": "customerName",
            "type": "text",
            "label": "Customer Name",
            "multiline": true,


        },
        {
            "varName": "dob",
            "type": "date",
            "label": "Date Of Birth",
            "required": true


        },
        {
            "varName": "email",
            "type": "text",
            "multiline": true,
            "errorMessage": "Error",
            "label": "Email",
            "email": true


        },

        {
            "varName": "phone",
            "type": "text",
            "errorMessage": "Error",
            "label": "Mobile Number",
            "required": true,
            "phone": true


        },

        {
            "varName": "tin",
            "type": "text",
            "label": "E-Tin",


        },
        {
            "varName": "registrationNo",
            "type": "text",
            "label": "Birth Cer./Driving License",
        },
        {
            "varName": "nationality",
            "type": "select",
            "label": "Nationality",
            "enum": [
                "BANGLADESH",
                "JAPAN",
                "INDIA"
            ],
            "required": true
        },


    ],

};
//#####DDeup Search NonIndividual/Propritorship Form#############final
let CSjsonFormNonIndividualWithoutProprietorshipAccountOpeningSearch = [
    {
        "varName": "companyTitle",
        "type": "title",
        "label": "Company dedup",

    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "CB Number",
        "required": false,
        "readOnly": false
    },

    {
        "varName": "companyName",
        "type": "text",
        "label": "Company Name",
        "required": false,
        "readOnly": false

    },

    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "required": false,

        "readOnly": false


    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile Number",
        "required": false

    },

    {
        "varName": "companyEtin",
        "type": "text",
        "label": "Company ETin",
        "required": false,


    },
    {
        "varName": "tradeLicense",
        "type": "text",
        "label": "Trade License",
        "required": false,


    },


];

//#####DDeup Search NonIndividual/Partnership Form#############
let CSjsonFormNonIndividualPartnershipAccountOpeningSearch = {
    "variables": [
        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB Number",
            "required": true,
            "readOnly": false
        },

        {
            "varName": "companyName",
            "type": "text",
            "label": "Company Name",
            "required": false,
            "readOnly": false

        },

        {
            "varName": "email",
            "type": "text",
            "label": "Email",
            "required": false,

            "readOnly": false


        },

        {
            "varName": "phone",
            "type": "text",
            "label": "Mobile Number",
            "required": false

        },

        {
            "varName": "tin",
            "type": "text",
            "label": "Company ETin",
            "required": false,


        },
        {
            "varName": "tradeLicense",
            "type": "text",
            "label": "Trade License",
            "required": false,


        },
        {
            "varName": "tradeLicense",
            "type": "text",
            "label": "Certificate Of Incorporation",
            "required": false,


        },


    ],

};

//#####DDeup Search NonIndividual/Limited Company Acc Form#############
let CSjsonFormNonIndividualLimitedAccountOpeningSearch = {
    "variables": [
        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB Number",
            "required": false,
            "readOnly": false
        },

        {
            "varName": "companyName",
            "type": "text",
            "label": "Company Name",
            "required": false,
            "readOnly": false

        },

        {
            "varName": "email",
            "type": "text",
            "label": "Email",
            "required": false,

            "readOnly": false


        },

        {
            "varName": "phone",
            "type": "text",
            "label": "Mobile Number",
            "required": false

        },

        {
            "varName": "tin",
            "type": "text",
            "label": "Company ETin",
            "required": false,


        },
        {
            "varName": "tradeLicense",
            "type": "text",
            "label": "Trade License",
            "required": false,


        },
        {
            "varName": "tradeLicense",
            "type": "text",
            "label": "Certificate Of Incorporation",
            "required": false,


        },


    ],

};

//#####DDeup Search NonIndividual/Others  Form#############
let CSjsonFormNonIndividualOthersAccountOpeningSearch = {
    "variables": [
        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB Number",
            "required": false,
            "readOnly": false
        },

        {
            "varName": "companyName",
            "type": "text",
            "label": "Company Name",
            "required": false,
            "readOnly": false

        },

        {
            "varName": "email",
            "type": "text",
            "label": "Email",
            "required": false,

            "readOnly": false


        },

        {
            "varName": "phone",
            "type": "text",
            "label": "Mobile Number",
            "required": false

        },

        {
            "varName": "tin",
            "type": "text",
            "label": "Company ETin",
            "required": false,


        },
        {
            "varName": "tradeLicense",
            "type": "text",
            "label": "Trade License",
            "required": false,


        },
        {
            "varName": "tradeLicense",
            "type": "text",
            "label": "Certificate Of Incorporation",
            "required": false,


        },


    ],

};


//13-october-2019
//Sd maker Update All information Json Form #######
let MAKERUpdateAllInformation = {
    "variables": [

        {
            "varName": "discripancy",
            "type": "Select",
            "label": "Discripancy",
            "enum": [
                "YES",
                "NO"
            ]
        },

        {
            "varName": "deferal",
            "type": "Select",
            "label": "Deferal",
            "enum": [
                "YES",
                "NO"
            ]
        },

        {
            "varName": "deferalType",
            "type": "text",
            "label": "Deferal Type",
        },
        {
            "varName": "expireDate",
            "type": "date",
            "label": "Expire Date Type",
        },
        {
            "varName": "maker_update_all_information_send_tO",
            "type": "Select",
            "label": "Approved ?",
            "enum": [
                "APPROVED",
                "RETURN"
            ]
        },

    ]

};

//Sd maker Signiture and photo upload Json Form #######
let MAKERsigniturePhotoUpload = {
    "variables": [

        {
            "varName": "MAKERsigniturePhotoUpload",
            "type": "text",
            "label": "Signiture and Photo Upload ?",
        },

    ]

};


//Sd Checker Update All information Json Form #######
let CHECKERupdateAllInformation = {
    "variables": [
        {
            "varName": "checker_update_all_information_approval",
            "type": "Select",
            "label": "Approved ?",
            "enum": [
                "APPROVED",
                "NOAPPROVED"
            ]
        },

    ]

};

//Sd Checker Signiture and photo upload Json Form #######

let checkersigniturePhotoUpload = {
    "variables": [
        {
            "varName": "checker_signiture_photo_upload_approval",
            "type": "Select",
            "label": "Approved ?",
            "enum": [
                "APPROVED",
                "NOAPPROVED"
            ]
        },

    ]

};


module.exports = {
    CSJsonFormMaintenanceWithPrintFileAddress,
    CSJsonFormMaintenanceWithPrintFileContact,
    CSJsonFormMaintenanceWithPrintFileEmail,
    CSJsonFormMaintenanceWithPrintFileNominee,
    CSJsonFormMaintenanceWithPrintFileTenor,
    CSJsonFormMaintenanceWithPrintFileScheme,
    CSJsonFormMaintenanceWithPrintFileMaturity,
    CSJsonFormMaintenanceWithPrintFileTitle,
    CSJsonFormMaintenanceWithPrintFileLink,
    CSJsonFormMaintenanceWithPrintFileEtin,
    CSJsonFormMaintenanceWithPrintFileDormant,
    CSJsonFormMaintenanceWithPrintFileInputFiled,
    CSJsonFormMaintenanceWithFile,


    BOMJsonFormMaintenanceWithPrintFileAddress,
    BOMJsonFormMaintenanceWithPrintFileContact,
    BOMJsonFormMaintenanceWithPrintFileEmail,
    BOMJsonFormMaintenanceWithPrintFileNominee,
    BOMJsonFormMaintenanceWithPrintFileTenor,
    BOMJsonFormMaintenanceWithPrintFileScheme,
    BOMJsonFormMaintenanceWithPrintFileMaturity,
    BOMJsonFormMaintenanceWithPrintFileTitle,
    BOMJsonFormMaintenanceWithPrintFileLink,
    BOMJsonFormMaintenanceWithPrintFileEtin,
    BOMJsonFormMaintenanceWithPrintFileDormant,
    BOMJsonFormMaintenanceWithPrintFileInputFiled,
    BOMJsonFormMaintenanceWithFile,
    BOMNewJsonFormJointAccountOpening,
    BMfromCSJsonFormMaintenanceWithPrintFileAddress,
    BMfromCSJsonFormMaintenanceWithPrintFileContact,
    BMfromCSJsonFormMaintenanceWithPrintFileEmail,
    BMfromCSJsonFormMaintenanceWithPrintFileNominee,
    BMfromCSJsonFormMaintenanceWithPrintFileTenor,
    BMfromCSJsonFormMaintenanceWithPrintFileScheme,
    BMfromCSJsonFormMaintenanceWithPrintFileMaturity,
    BMfromCSJsonFormMaintenanceWithPrintFileTitle,
    BMfromCSJsonFormMaintenanceWithPrintFileLink,
    BMfromCSJsonFormMaintenanceWithPrintFileEtin,
    BMfromCSJsonFormMaintenanceWithPrintFileDormant,
    BMfromCSJsonFormMaintenanceWithPrintFileInputFiled,
    BMfromCSJsonFormMaintenanceWithFile,

    BMfromBOMJsonFormMaintenanceWithPrintFileAddress,
    BMfromBOMJsonFormMaintenanceWithPrintFileContact,
    BMfromBOMJsonFormMaintenanceWithPrintFileEmail,
    BMfromBOMJsonFormMaintenanceWithPrintFileNominee,
    BMfromBOMJsonFormMaintenanceWithPrintFileTenor,
    BMfromBOMJsonFormMaintenanceWithPrintFileScheme,
    BMfromBOMJsonFormMaintenanceWithPrintFileMaturity,
    BMfromBOMJsonFormMaintenanceWithPrintFileTitle,
    BMfromBOMJsonFormMaintenanceWithPrintFileLink,
    BMfromBOMJsonFormMaintenanceWithPrintFileEtin,
    BMfromBOMJsonFormMaintenanceWithPrintFileDormant,
    BMfromBOMJsonFormMaintenanceWithPrintFileInputFiled,
    BMfromBOMJsonFormMaintenanceWithFile,
    BMjsonFormNonIndividualProprietorshipAccountOpeningSearch,
    MAKERJsonFormMaintenanceWithPrintFileAddress,
    MAKERJsonFormMaintenanceWithPrintFileContact,
    MAKERJsonFormMaintenanceWithPrintFileEmail,
    MAKERJsonFormMaintenanceWithPrintFileNominee,
    MAKERJsonFormMaintenanceWithPrintFileTenor,
    MAKERJsonFormMaintenanceWithPrintFileScheme,
    MAKERJsonFormMaintenanceWithPrintFileMaturity,
    MAKERJsonFormMaintenanceWithPrintFileTitle,
    MAKERJsonFormMaintenanceWithPrintFileLink,
    MAKERJsonFormMaintenanceWithPrintFileEtin,
    MAKERJsonFormMaintenanceWithPrintFileDormant,
    MAKERJsonFormMaintenanceWithPrintFileInputFiled,
    MAKERJsonFormMaintenanceWithFile,
    MAKERJsonFormNonIndividualAccountOpening,

    CHECKERJsonFormMaintenanceWithPrintFileAddress,
    CHECKERJsonFormMaintenanceWithPrintFileContact,
    CHECKERJsonFormMaintenanceWithPrintFileEmail,
    CHECKERJsonFormMaintenanceWithPrintFileNominee,
    CHECKERJsonFormMaintenanceWithPrintFileTenor,
    CHECKERJsonFormMaintenanceWithPrintFileScheme,
    CHECKERJsonFormMaintenanceWithPrintFileMaturity,
    CHECKERJsonFormMaintenanceWithPrintFileTitle,
    CHECKERJsonFormMaintenanceWithPrintFileLink,
    CHECKERJsonFormMaintenanceWithPrintFileEtin,
    CHECKERJsonFormMaintenanceWithPrintFileDormant,
    CHECKERJsonFormMaintenanceWithPrintFileInputFiled,
    CHECKERJsonFormMaintenanceWithFile,

    CHECKERNewJsonFormNonIndividualAccountOpeningFinacle,

    MAKERJsonFormIndividualAccountOpening,
    CHECKERNewJsonFormIndividualAccountOpeningFinacle,

    CSExistJsonFormIndividualAccountOpening,
    CSNewJsonFormIndividualAccountOpening,
    BOMNewJsonFormIndividualAccountOpening,
    BMJsonFormIndividualAccountOpening,


    CSExistJsonFormJointAccountOpening,
    CSNewJsonFormJointAccountOpening,

    BMJsonFormJointAccountOpening,


    CSExistJsonFormProprietorshipAccountOpening,
    CSNewJsonFormProprietorshipAccountOpening,
    BOMNewJsonFormProprietorshipAccountOpening,
    BMJsonFormProprietorshipAccountOpening,


    CSjsonFormIndividualAccountOpeningSearch,
    CSjsonFormIndividualAccountOpeningSearchForwardTo,
    CSjsonFormJointAccountOpeningSearchForwardTo,
    CSjsonFormIndividualJointAccountOpeningSearch,
    CSjsonFormNonIndividualAccountOpeningSearch,
    CSjsonFormNonIndividualAccountOpeningSearchForwardTo,
    CSjsonFormNonIndividualProprietorshipAccountOpeningSearch,
    CSjsonFormNonIndividualWithoutProprietorshipAccountOpeningSearch,
    CSjsonFormNonIndividualPartnershipAccountOpeningSearch,
    CSjsonFormNonIndividualLimitedAccountOpeningSearch,
    CSjsonFormNonIndividualOthersAccountOpeningSearch,


    CSJsonFormForDebitCard,
    BOMJsonFormForDebitCard,
    BMJsonFormForDebitCard,
    MAKERJsonFormForDebitCard,
    CHECKERJsonFormForDebitCard,
    BOMNewCommonjsonFormNonIndividualAccountOpeningSearch,


    MAKERUpdateAllInformation,
    MAKERsigniturePhotoUpload,

    CHECKERupdateAllInformation,
    BMjsonFormIndividualAccountOpeningSearch,
    BMCommonjsonFormJointAccountOpeningSearch

};

