import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import Functions from '../../Common/Functions';
import theme from "../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import Notification from "../NotificationMessage/Notification";
import loading from "../../Static/loader.gif";
import CBNotFound from "./CASA/CBNotFound";
import TableComponent from "./CommonComponent/TableComponent";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import {Menu} from "semantic-ui-react";
import {commonInboxButton, commonInboxTabActive, commonInboxTabInactive} from "../DeliverableManagement/configuration";
import Badge from "@material-ui/core/Badge";
import {filterField, rowsForBond} from "./ColumnNameForBondInbox"
import SubmittedCaseHistory from "./CommonComponent/SubmittedCaseHistory";
import CSInboxCase from "./CSInboxCase";
import MakerInboxCase from "./MakerInboxCase";
import CheckerInboxCase from "./CheckerInboxCase";
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";

const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

class BondInbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            loading: true,
            cbNotFound: false,
            getData: false,
            varValue: {},
            title: "",
            notificationMessage: "",
            alert: false,
            selectedDate: {},
            showValue: false,
            err: false,
            searchData: false,
            activeItem: "Bond Group Inbox",
            showTable: false,
            errorArray: {},
            errorMessages: {},
            tableData: [],
            totalRow: 0,
            rowsPerPage: 50,
            tableColumns: [],
            page: 0,
            showInbox: false,
            serviceType: "",
            subServiceType: "",
            category: "",
            subCategory: "",
            solId: "",
            appUid: "",
            delStatus: "",
            taskTitle: "",
            status: "",
            freeFlag1: "",
            freeFlag2: "",
            initBranchName: "",
            freeFlag3: "",
            freeFlag4: "",
            recpmtid: "",
            cb_number: "",
            account_number: "",
            customer_name: "",
            CaseId: "",
            urgency: "",
            sentByUsername: "",
            jointAccountCustomerNumber: "",
            getCountPending: 0,
            getCountReturn: 0,
            getCountInbox: 0,
            getCountRectified: 0,
            getCountGroupInbox: 0,
        }
    }


    renderTableColumn = () => {
        return rowsForBond;
    };


    getApiUrl = () => {
        let url = "";
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CS") {
            // url = backEndServerURL + '/inbox/return/0';
            url = backEndServerURL + '/csGroupWiseReturnRequest/0';
            this.setState({
                activeItem: 'Bond Return',
            })
        } else {
            if ((LocalstorageEncrypt.encryptStorageFunction.getItem("menus")).indexOf("BOND SP GROUP LOCK") !== -1) {
                url = backEndServerURL + '/claimable/withoutRectified/0';
            }
        }
        return url;
    };

    functionForCategoryCount = () => {
        let countUrl = backEndServerURL + "/inbox/getCountCategoryWise";
        axios.get(countUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let getData = response.data;
                this.setState({
                    getCountInbox: getData.inbox,
                    getCountReturn: getData.return,
                    getCountRectified: getData.rectified,
                    getCountPending: getData.draft,
                    getCountGroupInbox: getData.teamGroup,
                });
                LocalstorageEncrypt.encryptStorageFunction.setItem("inboxNumberOfCount", Number(getData.inbox) + Number(getData.return) + Number(getData.draft));
            })
            .catch((error) => {
                console.log(error);
            });
    };


    componentDidMount() {
        let branch = [];
        let branchUrl = backEndServerURL + "/workplaceUnit/getInitialUnits";
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((item) => {
                    branch.push({key: item.solId, value: item.name})
                });
                for (let form of filterField) {
                    if (form.type === "autoCompleteValueReturn" && form.varName === "branch_id") {
                        form.enum = branch
                    }
                }
                this.fetchTableData(this.getApiUrl(), 0)

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    getData: true,
                    showTable: true,
                    loading: false,
                });
            })
    }


    fetchTableData = (url, page) => {
       // this.functionForCategoryCount();
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(response => {
            let tableData = [];
            response.data.content.map((message, index) => {
                tableData.push({
                    SL: page > 0 ? ((index + 1) + (Number(this.state.rowsPerPage) * page)) : index + 1,
                    CaseId: message.caseId,
                    customer_name: message.customerName,
                    cb_number: message.cbNumber,
                    account_number: message.accountNumber,
                    businessSegment: message.businessSegment,
                    category_type: message.category,
                    sub_category_type: message.subCategory,
                    Service: this.returnService(message.priorityDesc, message.freeFlag1, message.freeFlag6),
                    date: message.delInitTime.replace("T", " "),
                    currentUserName: message.currentUserName,
                    branch_name: message.branchName,
                    Status: message.delStatus,
                    freeFlag1: message.freeFlag1,
                    freeFlag2: message.freeFlag2,
                    freeFlag3: message.freeFlag3,
                    freeFlag4: message.freeFlag4,
                    registrationNumber: message.freeFlag4,
                    projectName: message.freeFlag5,
                    amount: message.freeFlag6,
                    recpmtid: message.recpmtid,
                    freeFlag5: message.freeFlag5,
                    freeFlag6: message.freeFlag6,
                    appId: message.appId,
                    serviceType: message.serviceType,
                    subServiceType: message.subServiceType,
                    taskTitle: message.taskTitle,
                    solId: message.solId,
                    urgency: message.urgency,
                    returnedBack: message.returnBackTo ? message.returnBackTo : "",
                    returnedBy: message.sentByUsername,
                    jointAccountCustomerNumber: message.jointAccountCustomerNumber,
                    requestStatus: message.status,
                    makerId: message.lastMakerUser,
                    branchName: message.solId + "-" + message.branchName,
                    requestType: message.proprietorName,
                });
            });
            this.setState({
                page: response.data.number,
                totalRow: response.data.totalElements,
                tableData: tableData,
                getData: true,
                showTable: true,
                loading: false
            })

        }).catch(error => {
            console.log(error);
            this.setState({
                loading: false,
                showTable: true,
                searchData: false,
            })
        });
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderCloseIcon = () => {
        return (
            <h4 style={{color: "white"}} >All Inbox</h4>
        )
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    handleChangePage = (pageNumber) => {
        this.setState({
            loading: true,
            getData: false,
            showTable: false
        });
        if (this.state.searchData) {
            this.searchHandler("", pageNumber)
        } else {
            this.functionForGetTabWiseUrl(this.state.activeItem, pageNumber);
        }
    };


    removeNullValue = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === 'null' || clone[prop] === null || clone[prop] === 'undefined')
                delete clone[prop];
        return clone;
    };


    returnService = (priorityDesc, freeFlag1, freeFlag6) => {
        if ((freeFlag6 === "Customer Information Update" || freeFlag6 === "Mandate/Related Party Maintenance" || freeFlag6 === "Dormant Activation & Maintenance") && freeFlag1) {
            return freeFlag1.replaceAll("^", ",")
        } else {
            return priorityDesc
        }
    };

    manageStatusForSearch = () => {
        switch (this.state.activeItem) {
            case "Bond Group Inbox":
                return "Open";
            case "Bond Self Inbox":
                return "Locked";
            case "Bond Pending Case":
                return "Saved";
            case "Bond Certificate":
                return "Enrollment";
            default:
                return "Open"
        }
    };

    searchHandler = (event, pageNumber) => {
        this.setState({
            page: 0,
            totalRow: 0,
            tableData: [],
            loading: true,
            showTable: false,
        });
        let pageN = pageNumber ? pageNumber : 0;
        let postData = this.removeNullValue(this.state.inputData);
        if (this.manageStatusForSearch() === "Open") {
            postData.tabName = "Group Inbox"
        }

        let url = backEndServerURL + "/filterUserInbox/" + this.manageStatusForSearch() + "/" + pageN;
        axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(response => {
            let tableData = [];
            response.data.content.map((message, index) => {
                tableData.push({
                    SL: pageNumber > 0 ? ((index + 1) + (Number(this.state.rowsPerPage) * pageNumber)) : index + 1,
                    CaseId: message.caseId,
                    customer_name: message.customerName,
                    cb_number: message.cbNumber,
                    account_number: message.accountNumber,
                    businessSegment: message.businessSegment,
                    category_type: message.category,
                    sub_category_type: message.subCategory,
                    Service: this.returnService(message.priorityDesc, message.freeFlag1, message.freeFlag6),
                    date: message.delInitTime.replace("T", " "),
                    currentUserName: message.currentUserName,
                    branch_name: message.branchName,
                    Status: message.delStatus,
                    freeFlag1: message.freeFlag1,
                    freeFlag2: message.freeFlag2,
                    freeFlag3: message.freeFlag3,
                    freeFlag4: message.freeFlag4,
                    recpmtid: message.recpmtid,
                    freeFlag5: message.freeFlag5,
                    freeFlag6: message.freeFlag6,
                    registrationNumber: message.freeFlag4,
                    projectName: message.freeFlag5,
                    amount: message.freeFlag6,
                    appId: message.appId,
                    serviceType: message.serviceType,
                    subServiceType: message.subServiceType,
                    taskTitle: message.taskTitle,
                    solId: message.solId,
                    urgency: message.urgency,
                    returnedBack: message.returnBackTo ? message.returnBackTo : "",
                    returnedBy: message.sentByUsername,
                    jointAccountCustomerNumber: message.jointAccountCustomerNumber,
                    requestStatus: message.status,
                    makerId: message.lastMakerUser,
                    branchName: message.solId + "-" + message.branchName,
                    requestType: message.proprietorName,
                });
            });
            this.setState({
                page: response.data.number,
                totalRow: response.data.totalElements,
                tableData: tableData,
                getData: true,
                showTable: true,
                searchData: true,
                loading: false
            })

        }).catch(error => {
            console.log(error);
            this.setState({
                loading: false,
                showTable: true,
                searchData: false,
            })
        });
    };

    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.searchHandler(event, 0);
        }
    };


    renderFilterField = () => {
        return (
            <Grid container>
                {CommonJsonFormComponent.renderJsonForm(this.state, filterField, this.updateComponent, this.onKeyDownForDedup)}
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: '15px',
                    }}
                    onClick={(e) => this.searchHandler(e, 0)}
                    type="submit"
                >
                    Search
                </button>
                &nbsp;
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: '15px',
                    }}
                    onClick={() => window.location.reload()}
                    type="submit"
                >
                    Reset
                </button>
            </Grid>
        );
    };


    headerSelect = (event, value) => {
        event.preventDefault();
        this.setState({
            searchData: false,
            activeItem: value,
            loading: true,
            getData: false,
            showTable: false,
            page: 0,
            tableData: [],
        }, this.functionForGetTabWiseUrl(value, 0));
    };

    functionForGetTabWiseUrl = (value, page) => {
        this.forceUpdate();
        let urls = "";
        let workflowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        switch (value) {
            case "Bond Group Inbox":
                urls = backEndServerURL + '/claimable/withoutRectified/' + page;
                break;
            case "Bond Rectified Request":
                urls = backEndServerURL + '/claimable/withRectified/' + page;
                break;
            case "Bond Self Inbox":
                urls = backEndServerURL + '/inbox/waiting/' + page;
                break;
            case "Bond Return":
                // urls = backEndServerURL + '/inbox/return/' + page;
                if (workflowGroup === "MAKER" || workflowGroup === "CHECKER") {
                    urls = backEndServerURL + '/getGroupWiseReturnRequest/' + page;
                } else {
                    urls = backEndServerURL + '/csGroupWiseReturnRequest/' + page;
                }
                break;
            case "Bond Pending Case":
                urls = backEndServerURL + '/inbox/saved/' + page;
                break;
            case "Bond Certificate":
                urls = backEndServerURL + '/getCertificates/bond_issuance_serve/Certificate/' + page;
                break;
            case "Bond Complete":
                urls = backEndServerURL + '/perticipatedClosed/' + page;
                break;
            default:
                urls = backEndServerURL + '/perticipated/' + page;
                break;
        }
        this.fetchTableData(urls, page)
    };


    renderMenu = () => {
        return (
            <div style={{marginRight: "8px"}}>
                <Menu pointing style={{overflowX: 'auto', overflowY: 'hidden'}}>

                    {this.renderMenuItem("BOND SP GROUP LOCK", "Bond Group Inbox", this.state.getCountGroupInbox, "Group inbox")}

                    {this.renderMenuItem("BOND SP GROUP LOCK", "Bond Rectified Request", this.state.getCountRectified, "Corrected Request")}

                    {this.renderMenuItem("BOND SELF INBOX", "Bond Self Inbox", this.state.getCountInbox, "Self-Inbox")}

                    {this.renderMenuItem("BOND SP PENDING CASE", "Bond Pending Case", this.state.getCountPending, "Pending Case")}

                    {this.renderMenuItem("BOND SP RETURN", "Bond Return", this.state.getCountReturn, "Returned Requests")}

                    {this.renderMenuItem("BOND SP SUBMIT", "Bond Submit", undefined, "Submit")}

                    {this.renderMenuItem("BOND SP COMPLETE", "Bond Complete", undefined, "Complete")}

                    {this.renderMenuItem("BOND SP CERTIFICATE", "Bond Certificate", undefined, "Certificate")}
                </Menu>
            </div>
        );
    };

    renderMenuItem = (permission, activeItem, badge, name) => {
        if ((LocalstorageEncrypt.encryptStorageFunction.getItem("menus")).indexOf(permission) !== -1) {
            return (
                <Menu.Item
                    name={activeItem}
                    active={this.state.activeItem === activeItem}
                    style={this.state.activeItem === activeItem ? commonInboxTabActive : commonInboxTabInactive}
                    onClick={e => {
                        this.headerSelect(e, activeItem);
                    }}
                >
                    {this.renderBadge(badge, name)}
                </Menu.Item>
            )
        }

    };

    renderBadge = (value, name) => {
        if (value !== undefined && value > 0) {
            return (
                <Badge  color="primary">
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        } else if (value === 0) {
            return (
                <Badge  color="primary" showZero>
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        } else {
            return (
                <Badge color="primary">
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        }
    };

    renderInboxCaseSubmitted = () => {
        return (
            <SubmittedCaseHistory delStatus={this.state.delStatus} closeModal={this.closeModal}
                                  closeIcon={this.closeModal}
                                  appId={this.state.appUid}
                                  subCategory={this.state.subCategory}
                                  category={this.state.category} serviceType={this.state.serviceType}
                                  freeFlag1={this.state.freeFlag1}
                                  freeFlag2={this.state.freeFlag2}
                                  freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                                  recpmtid={this.state.recpmtid}
                                  accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                                  customerName={this.state.customer_name}
                                  solId={this.state.solId}
                                  subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
        )
    };

    renderInboxCase = () => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CS") {
            return (
                <CSInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeModal}
                             appUid={this.state.appUid} CaseId={this.state.CaseId}
                             subCategory={this.state.subCategory} category={this.state.category}
                             serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                             freeFlag2={this.state.freeFlag2}
                             freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                             recpmtid={this.state.recpmtid}
                             accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                             customerName={this.state.customer_name}
                             solId={this.state.solId}
                             initBranchName={this.state.initBranchName}
                             subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MAKER') !== -1) {
            return (
                <MakerInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal}
                                closeIcon={this.closeModal}
                                appUid={this.state.appUid} CaseId={this.state.CaseId}
                                subCategory={this.state.subCategory} category={this.state.category}
                                serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                                freeFlag2={this.state.freeFlag2}
                                freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                                recpmtid={this.state.recpmtid}
                                accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                                customerName={this.state.customer_name}
                                solId={this.state.solId}
                                initBranchName={this.state.initBranchName}
                                subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('CHECKER') !== -1) {
            return (
                <CheckerInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal}
                                  closeIcon={this.closeModal}
                                  appUid={this.state.appUid} CaseId={this.state.CaseId}
                                  subCategory={this.state.subCategory} category={this.state.category}
                                  serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                                  freeFlag2={this.state.freeFlag2}
                                  freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                                  recpmtid={this.state.recpmtid}
                                  accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                                  customerName={this.state.customer_name}
                                  solId={this.state.solId}
                                  initBranchName={this.state.initBranchName}
                                  subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
            )
        }
    };

    onRowClick = (event, rowData) => {
        event.preventDefault();
        let workFlowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        const {activeItem} = this.state;
        if ((workFlowGroup === "MAKER" || workFlowGroup === "CHECKER") && (activeItem === "Bond Group Inbox" || activeItem === "Bond Return")) {

        } else if (activeItem === "Bond Group Inbox" || activeItem === "Bond Rectified Request") {
            this.setState({
                loading: true
            });
            let url = backEndServerURL + "/case/claimReturn/" + rowData.appId;
            axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setInboxProps({...rowData, appId: response.data})
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                    })
                })
        } else {
            this.setInboxProps(rowData)
        }
    };

    setInboxProps = (rowData) => {
        this.setState({
            initBranchName: rowData.branch_name,
            CaseId: rowData.CaseId,
            serviceType: rowData.serviceType,
            subServiceType: rowData.subServiceType,
            category: rowData.category_type,
            subCategory: rowData.sub_category_type,
            solId: rowData.solId,
            appUid: rowData.appId,
            delStatus: rowData.Status,
            taskTitle: rowData.taskTitle,
            status: rowData.requestStatus,
            freeFlag1: rowData.freeFlag1,
            freeFlag2: rowData.freeFlag2,
            freeFlag3: rowData.freeFlag3,
            freeFlag4: rowData.freeFlag4,
            recpmtid: rowData.recpmtid,
            cb_number: rowData.cb_number,
            account_number: rowData.account_number,
            customer_name: rowData.customer_name,
            urgency: rowData.urgency,
            sentByUsername: rowData.returnedBy,
            jointAccountCustomerNumber: rowData.jointAccountCustomerNumber,
            showInbox: true,
            loading: false
        });
    };

    functionForCaseLockOrUnlock = (event, data) => {
        const {activeItem} = this.state;
        event.preventDefault();
        let selectedAppId = [];
        for (let item of data) {
            selectedAppId.push(item.appId)
        }
        this.setState({
            loading: true,
            getData: false,
            showTable: false
        });
        let single_url = "";
        if (activeItem === "Bond Group Inbox" || activeItem === "Bond Return") {
            single_url = backEndServerURL + "/case/claim/";
        } else {
            single_url = backEndServerURL + "/case/unClaim/";
        }
        axios.post(single_url, selectedAppId, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.functionForGetTabWiseUrl(this.state.activeItem, this.state.page);
            }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false,
                getData: true,
                showTable: true
            })
        })
    };

    renderTopLabelButton = () => {
        const {activeItem} = this.state;
        if (activeItem === "Bond Group Inbox" || activeItem === "Bond Return") {
            return [{
                name: "LOCK",
                style: {...commonInboxButton},
                triggerFunction: this.functionForCaseLockOrUnlock
            }]
        } else if ((activeItem === "Bond Self Inbox" && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") !== "MAKER")) {
            return [{
                name: "UNLOCK",
                style: {...commonInboxButton},
                triggerFunction: this.functionForCaseLockOrUnlock
            }]
        }
    };

    renderTable = () => {
        const {activeItem} = this.state;
        return (
            (this.state.getData && this.state.showTable) &&
            <TableComponent tableData={this.state.tableData} tableColumns={this.renderTableColumn()}
                            onRowClick={this.onRowClick} loader={this.state.loading} maxBodyHeight="450px"
                            totalRow={this.state.totalRow} page={this.state.page} whiteSpace={"wrap"}
                            selection={(activeItem === "Bond Group Inbox" || activeItem === "Bond Self Inbox" || activeItem === "Bond Return")}
                            handleChangePage={this.handleChangePage} rowsPerPage={this.state.rowsPerPage}
                            tableButton={this.renderTopLabelButton()}
            />
        )
    };

    closeModal = () => {
        this.setState({
            showInbox: false,
            getData: false,
            showTable: false,
            loading: true,
        }, this.functionForGetTabWiseUrl(this.state.activeItem, this.state.page))
    };

    renderInboxComponent = () => {
        const {activeItem} = this.state;
        if (activeItem === "Bond Submit" || activeItem === "Bond Complete") {
            return this.renderInboxCaseSubmitted()
        } else {
            return this.renderInboxCase()
        }
    };

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderCloseIcon()}
                </CardHeader>
                <CardBody>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loading} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        fullScreen={true}
                        open={this.state.showInbox}>
                        <DialogContent className={classes.dialogPaper}>
                            {this.renderInboxComponent()}
                        </DialogContent>
                    </Dialog>
                    <ThemeProvider theme={theme}>
                        {this.renderFilterField()}
                        <Grid item xs={12}><br/></Grid>
                        {this.renderMenu()}
                        {this.renderTable()}
                        {this.renderNotification()}
                    </ThemeProvider>
                </CardBody>
            </Card>
        );
    }

}

export default withStyles(styles)(BondInbox);
