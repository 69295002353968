import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../../Common/Functions';
import Notification from "../../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../../JsonForm/CustomeTheme";
import Table from "../../../Table/Table";
import {Dialog} from "@material-ui/core/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import loader from "../../../../Static/loader.gif";
import Label from "./CasaCloserLabel"
import SignatureButton from "../SignatureButton";
import PreviewMappingImage from "./PreviewMappingImage";
import MyValidation from "../../../JsonForm/MyValidation";
import PreviewButton from "../PreviewButton";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";

let bmRemarks = [
    {
        "varName": "bmRemarks",
        "type": "textArea",
        "label": "BM Remarks",
        "required": true,
        "grid": 12
    }]
;

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};

class CloserBmApproval extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            appData: {},
            getData: false,
            varValue: [],
            redirectLogin: false,
            title: "",
            notificationMessage: "",
            alert: false,
            inputData: {},
            selectedDate: {},
            SelectedDropdownSearchData: null,
            dropdownSearchData: {},
            values: [],
            showValue: false,
            customerName: [],
            deferalType: [],
            expireDate: [],
            err: false,
            errorArray: {},
            errorMessages: {},
            other: [],
            getDeferalList: [],
            loading: true,
            getImageLink: [],
            getImageBoolean: false,
            imageModalBoolean: false,
            selectImage: "",
            getRemarks: [],
            getMappingAllImage: false,
            previewDocument: false,
            confirmationAlert: false,
            buttonType: ""
        };

    }


    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <Grid item xs={12}>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Remarks Details:</b>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Remarks", "User Name", "Date", "Role"]}
                                tableData={this.state.getRemarks}
                                tableAllign={['left', 'left', 'left', 'left']}
                            />
                            <br/>
                        </div>
                    </div>
                </Grid>
            )
        }
    };

    componentDidMount() {
        var remarksArray = [];

        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            let tableArray = [];
                            var status = "";
                            response.data.map((deferal) => {
                                if (deferal.status === "APPROVAL_WAITING") {
                                    status = "Waiting For Approval"
                                }

                                tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));

                            });
                            let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    console.log(response.data);
                                    this.setState({
                                        getImageLink: response.data,
                                        getImageBoolean: true
                                    });
                                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response) => {
                                            response.data.map((data) => {
                                                console.log(data.createByUserName);
                                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                            });
                                            this.setState({
                                                getRemarks: remarksArray
                                            })
                                        })
                                        .catch((error) => {
                                            console.log(error)
                                        })


                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                            this.setState({
                                getDeferalList: tableArray
                            })

                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    this.setState({
                        getRemarks: remarksArray
                    });
                    console.log(response.data);
                    this.setState({
                        getData: true,
                        showValue: true,
                        varValue: this.removeNullValue(response.data),
                        inputData: this.removeNullValue(response.data),
                        appData: response.data,
                        // loading: false
                    });

                })
                .catch((error) => {
                    console.log(error);
                    /* if(error.response.status===452){
                         Functions.removeCookie();

                         this.setState({
                             redirectLogin:true
                         })

                     }*/
                });
        }

    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };

    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                disabled: true,
                loading: true,
                confirmationAlert: false
            });
            let data = this.state.buttonType;
            this.state.inputData.returnDataBm = true;
            this.state.inputData.bm_approval = data;
            if (data === "APPROVED") {
                this.state.inputData.cs_bearer = "NO";
            }
            this.state.inputData.cs_deferal = "NO";
            this.state.inputData.csDeferal = "NO";
            this.state.inputData.bmRemarks = undefined;
            let remarksData = {};
            remarksData.remark = this.state.inputData.bmRemarks;
            remarksData.map = {...this.state.inputData, bmRemarks: undefined};
            let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
            axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                    loading: false
                });
                this.close();
            }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
        } else {
            this.setState({
                disabled: false,
                loading: false,
                confirmationAlert: false
            })
        }
    };

    handleSubmit = (event, data) => {
        event.preventDefault();
        if (data === "RETURN") {
            let error = MyValidation.defaultValidation(bmRemarks, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
        }
        this.setState({
            title: "Do you want to confirm?",
            confirmationAlert: true,
            buttonType: data
        });
    };

    renderForm = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.jsonForm, this.updateComponent)
            )
        }
    };

    viewAttachedImages = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return <Grid item xs={12}><PreviewMappingImage appId={this.props.appId} classes={classes}/></Grid>
        }
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, bmRemarks, this.updateComponent)
            )
        }
    };

    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Deferral List:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }

    };


    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <Grid item xs={12}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "APPROVED")}
                    >Approve
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "RETURN")}
                    >Return
                    </button>
                </Grid>


            )
        }
    };

    stopLoading = (value) => {
        if (value === "yes") {
            setTimeout(() => {
                this.setState({
                    loading: false,
                });
            }, 1000)
        } else {
            this.setState({
                getData: false,
                searchData: false,
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    renderCustomerDetails = () => {
        if (this.state.inputData.accountNumber && this.state.getData) {
            return (
                <>
                    <Label input={this.state.inputData} stopLoading={this.stopLoading} classes={this.props}/>
                    <br/>
                </>
            )
        }
    };

    previewButton = () => {
        if (this.state.getData && this.state.inputData.accountNumber) {
            return (
                <Grid container spacing={0}>
                    <SignatureButton accountNumber={this.state.inputData.accountNumber} classes={this.props}/>
                    &nbsp;&nbsp;
                    <PreviewButton appId={this.state.appId} classes={this.props}/>
                </Grid>
            )
        }
    };


    render() {
        const {classes} = this.props;
        {
            Functions.redirectToLogin(this.state)
        }

        return (
            <div>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.confirmationAlert}>
                    <DialogContent className={classes.dialogPaper}>
                        <ConfirmationModal
                            closeModal={this.closeConfirmation}
                            title={this.state.title}
                        />
                    </DialogContent>
                </Dialog>
                {this.renderCustomerDetails()}
                <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        {this.previewButton()}
                        {this.renderForm()}
                        {this.renderRemarksData()}
                        {this.viewAttachedImages()}
                        {this.renderRemarks()}
                        {this.renderSubmitButton()}
                        {this.renderNotification()}
                    </ThemeProvider>
                </Grid>
            </div>
        )
    }

}

export default withStyles(styles)(CloserBmApproval);
