import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import {Menu} from 'semantic-ui-react';
import 'semantic-ui-offline/semantic.min.css';
import Button from "@material/react-button";
import MaterialTable from "material-table";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import axiosDownload from '../FunctionForFileDownload';
import TablePagination from "@material-ui/core/TablePagination";
import {createMuiTheme} from "@material-ui/core/styles";
import {buttonStyle, localization, options, tabActive, tabInactive, tableIcons} from "../configuration";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import MailNotification from "../../workflow/CommonComponent/MailNotification";
import withStyles from "@material-ui/core/styles/withStyles";
import FailedListModal from "../WelcomeLetter/FailedListModal";
import loader from "../../../Static/loader.gif";
import TableInModal from "./TableInModal";


let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '5px',
            }
        },

    }
});

let SearchForm = [
    {
        varName: "fromDate",
        type: "date",
        label: "From Date",
        grid: 2,
    },
    {
        varName: "toDate",
        type: "date",
        label: "To Date",
        grid: 2,
    },
];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class ManageCorporatePrepaidCard extends Component {
    state = {activeItem: "home", backgroundColor: "red"};

    constructor(props) {
        super(props);
        this.state = {
            currentDate: 0,
            appData: "",
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            getData: true,
            inputData: {},
            fileUploadData: {},
            responseData: [],
            activeItem: "Uploaded",
            backgroundColor: "red",
            showValue: false,
            uploadModal: false,
            merchantModal: false,
            caseId: "",
            title: "",
            selectedRowIdArray: [],
            dataPassArray: [],
            buttonName: "Claim",
            notificationMessage: "",
            alert: false,
            showData: true,
            pageNumber: 0,
            totalRow: 0,
            searchItem: "",
            rowsPerPage: 100,
            autoComplete: {},
            selectedCount: 0,
            failedModal: false,
            failedList: [],
            loader: true,
            previewData: false,
            allData: [],
            allExcelData: [],

            columns: [
                {title: "ID", field: "id"},
                {title: "Company Name Type", field: "companyName"},
                {title: "Company Code", field: "companycode"},
                {title: "Upload Date", field: "uploadDate"},
                {title: "Uploaded By", field: "uploadBy"},
                {title: "Preview", field: "preview"},

            ],
            columnsOfData: [
                {title: "ID", field: "id"},
                {title: "Name Type", field: "name"},
                {title: "Sex", field: "sex"},
                {title: "Title", field: "title"},
                {title: "Name on Card", field: "nameOnCard"},
                {title: "Birth Date", field: "birthDate"},
                {title: "Tin", field: "tin"},
                {title: "District", field: "district"},
                {title: "City/Area", field: "cityOrArea"},
                {title: "Address", field: "addres"},
                {title: "Postal Code", field: "postalCode"},
                {title: "Email", field: "email"},
                {title: "Mobile Number", field: "mobileNumber"},
                {title: "Father Name", field: "fatherName"},
                {title: "Mother Name", field: "motherName"},
                {title: "NID No", field: "nid"},
                {title: "Company", field: "company"},
            ],


        };
    }

    headerSelect = (event, value) => {
        event.preventDefault();

        this.setState({
            loader: true,
            pageNumber: 0
        });
        this.fetchDataFromAPI(value, 'getCorporatePrepaidCardFile')

    };


    fetchDataFromAPI = (status, url) => {
        if (this.state.searchItem !== "") {
            this.searchData(this, status)
        } else {
            this.setState({
                activeItem: status,
                loader: true
            });

            let urlSegment;
            let inbox_url;
            if (url === undefined) {
                urlSegment = 'getCorporatePrepaidCardFile';
            } else {
                urlSegment = url;
            }

            inbox_url = backEndServerURL + "/" + urlSegment + "/" + status + "/0";

            axios
                .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    console.log(response);
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        rowData = {
                            id: response.data.content[i].id,
                            companyName: response.data.content[i].companyName,
                            companycode: response.data.content[i].companycode,
                            uploadDate: response.data.content[i].createdDate.split("T")[0],
                            uploadBy: response.data.content[i].createdBy,
                            preview: this.renderPreviewButton(response.data.content[i].id),
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData);
                    }

                    this.setState({
                        allData: array,
                        responseData: responseArray,
                        rowsPerPage: response.data.numberOfElements,
                        pageNumber: response.data.number,
                        totalRow: response.data.totalElements,
                        loader: false
                    });
                    console.log(this.state.allData);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    fetchCorporateCardData = (id) => {

            this.setState({
                loader: true
            });

            let url= backEndServerURL + "/getCorporatePrepaidCardData/" + id ;

            axios
                .get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    console.log(response);
                    let array = [];
                    let rowData;
                    console.log();
                    for (let i = 0; i < response.data.length; i++) {
                        rowData = {
                            id: response.data[i].id,
                            name: response.data[i].name,
                            sex: response.data[i].sex,
                            title: response.data[i].title,
                            nameOnCard: response.data[i].nameOnCard,
                            birthDate: response.data[i].birthDate,
                            tin: response.data[i].tin,
                            district: response.data[i].district,
                            country: response.data[i].country,
                            addres: response.data[i].addres,
                            postalCode: response.data[i].postalCode,
                            email: response.data[i].email,
                            mobileNumber: response.data[i].mobileNumber,
                            fatherName: response.data[i].fatherName,
                            motherName: response.data[i].motherName,
                            nid: response.data[i].nid,
                            company: response.data[i].company,
                        };
                        array.push(rowData);
                    }

                    this.setState({
                        allExcelData: array,
                        loader: false,
                        previewData: true,
                    });
                    console.log(this.state.allExcelData);
                })
                .catch(error => {
                    console.log(error);
                });
    };
    closePreviewData = () => {
        this.setState({
            previewData: false,
        });
    };
    renderPreviewButton = (id) => {
        return <a className="btn btn-sm btn-danger" style={{"color": "#ffffff"}}
                  onClick={() => this.fetchCorporateCardData(id)}> Preview </a>
    };


    handleChangePage = (pageNumber, status, bool) => {
        let page = 0;
        if (bool !== undefined) {
            page = pageNumber;
        } else {
            page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        }
        if (this.state.searchItem !== "") {
            this.searchData(this, status, page);
        } else {
            let inbox_url;
            if (this.state.activeItem === status) {
                this.setState({
                    loader: true
                });
                inbox_url = backEndServerURL + "/getCorporatePrepaidCardFile/" + status + "/" + page;

                let array = [];
                let responseArray = [];
                axios
                    .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => {
                        let rowData;
                        let responseRowData;
                        for (let i = 0; i < response.data.content.length; i++) {
                            responseRowData = response.data.content[i].appId;
                            rowData = {
                                id: response.data.content[i].id,
                                companyName: response.data.content[i].companyName,
                                companycode: response.data.content[i].companycode,
                                uploadDate: response.data.content[i].createdDate.split("T")[0],
                                uploadBy: response.data.content[i].createdBy
                            };
                            array.push(rowData);
                            responseArray.push(responseRowData);
                        }

                        this.setState({
                            allData: array,
                            responseData: responseArray,
                            activeItem: this.state.activeItem,
                            rowsPerPage: response.data.numberOfElements,
                            pageNumber: response.data.number,
                            totalRow: response.data.totalElements,
                            loader: false
                        });
                        console.log(this.state.totalRow, this.state.pageNumber, this.state.rowsPerPage);
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            allData: array,
                            loader: false
                        })
                    });
            }
        }
    };

    dateConverter = (value) => {
        return value.substr(0, 10);
    };

    componentDidMount() {
        this.fetchDataFromAPI("Uploaded", 'getCorporatePrepaidCardFile');
        let defaultDate = new Date();
        let isoDate = defaultDate.toISOString();
        let currentDate = isoDate.substr(0, 10);
        this.setState({
            currentDate: currentDate
        });
    }

    updateComponent = () => {
        this.forceUpdate();
    };


    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === undefined)
                delete clone[prop];
        return clone;
    };

    searchData = (e, status, page) => {

        let postData = this.removeNullValue(this.state.inputData);

        if (Object.keys(postData).length === 0) {
            return 0;
        }

        let inbox_url;
        if (page === undefined) {
            this.setState({
                loader: true,
                activeItem: status,
            });
            inbox_url = backEndServerURL + "/filterCorporatePrepaidCardFile/" + status + "/0";
        } else {
            this.setState({
                loader: true,
                activeItem: status,
            });
            inbox_url = backEndServerURL + "/filterCorporatePrepaidCardFile/" + status + "/" + page;
        }


        axios
            .post(inbox_url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {

                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].appId;
                    rowData = {
                        id: response.data.content[i].id,
                        companyName: response.data.content[i].companyName,
                        companycode: response.data.content[i].companycode,
                        uploadDate: response.data.content[i].createdDate.split("T")[0],
                        uploadBy: response.data.content[i].createdBy
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    inputData: {...postData},
                    varValue: {...postData},
                    showValue: true,
                    loader: false,
                    loading: false,
                    rowsPerPage: response.data.numberOfElements,
                    pageNumber: response.data.number,
                    totalRow: response.data.totalElements,
                    searchItem: status,
                });
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
            });
    };

    resetHandler = () => {
        window.location.reload();
    };

    render() {
        const {classes} = this.props;
        const {activeItem} = this.state;
        return (
            <div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >
                            Download Corporate Prepaid Card XML File </h4>
                    </CardHeader>
                    <CardBody>
                        <Grid container>
                            <ThemeProvider theme={theme}>
                                {CommonJsonFormComponent.renderJsonForm(
                                    this.state,
                                    SearchForm,
                                    this.updateComponent
                                )}
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        height: 30,
                                        marginTop: 15,
                                    }}
                                    onClick={e => {
                                        this.searchData(e, this.state.activeItem);
                                    }}
                                >
                                    Search
                                </button>
                                &nbsp;
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        height: 30,
                                        marginTop: 15,
                                    }}
                                    onClick={this.resetHandler}
                                >
                                    Reset
                                </button>
                            </ThemeProvider>
                        </Grid>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >Deliverable Management (Corporate Prepaid Card)</h4>
                    </CardHeader>
                    <CardBody>
                        <div className="deliverable">
                            <React.Fragment>
                                <div>
                                    <Menu pointing>

                                        <Menu.Item
                                            name="Uploaded"
                                            active={activeItem === "Uploaded"}
                                            style={
                                                activeItem === "Uploaded"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Uploaded");
                                            }}
                                        />

                                        <Menu.Item
                                            name="XML Downloaded"
                                            active={activeItem === "XML Downloaded"}
                                            style={
                                                activeItem === "XML Downloaded"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "XML Downloaded");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Canceled"
                                            active={activeItem === "Canceled"}
                                            style={
                                                activeItem === "Canceled"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Canceled");
                                            }}
                                        />
                                    </Menu>

                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="lg"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.previewData}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <TableInModal
                                                closeModal={this.closePreviewData}
                                                subServiceType=""
                                                columns={this.state.columnsOfData}
                                                allData={this.state.allExcelData}
                                                title={"Excel Data Preview"}
                                                rowLength={this.state.allExcelData.length}
                                            />
                                        </DialogContent>
                                    </Dialog>

                                {this.tableChoice()}
                        </div>
                    </React.Fragment>
            </div>
    </CardBody>
    </Card>
    </div>
    )
        ;
    }

    actionForNewToProcced = (event, rowData, props) => {
        if (this.state.activeItem === "Uploaded") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];

            if (allSelectValue != null) {
                this.setState({
                    loader: true
                });
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let downloadXML = backEndServerURL + "/getCorporatePrepaidCardXMLFile";
                axiosDownload.downloadFile(downloadXML, 'POST', rowIdArray, `Corporate_Prepaid_Card_${this.state.currentDate}.xml`)
                    .then(response => {

                        this.fetchDataFromAPI('Uploaded', 'getCorporatePrepaidCardFile')

                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            loader: false
                        })
                    });

            }
        }
    };

    actionForNewToCancel = (event, rowData, props) => {
        if (this.state.activeItem === "Uploaded") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url = backEndServerURL + "/cancelUploadedCorporatePrepaidCard";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.fetchDataFromAPI('Uploaded')
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    renderUrl = () => {
        this.handleChangePage('-5', this.state.activeItem, true)
    };

    tableChoice() {
        const {classes} = this.props;
        if (this.state.loader) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loader}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else if (this.state.activeItem === "Uploaded" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Uploaded')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForNewToProcced(event, rowData, props)}
                                            >
                                                XML Download
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForNewToCancel(event, rowData, props)}
                                            >
                                                Cancel
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Cancel" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Canceled')
                                            }
                                            }
                                        />
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "XML Downloaded" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'XML Downloaded')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>

                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        }
    }
}

export default withStyles(styles)(ManageCorporatePrepaidCard);
