import Table from "../../Table/Table.jsx";
import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../Grid/GridItem.jsx";
import GridContainer from "../../Grid/GridContainer.jsx";
import {Grow} from "@material-ui/core";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody";
import {backEndServerURL} from "../../../Common/Constant";
import AssetManagerAddEdit from "./AssetManagerAddEdit";
import axios from "axios";
import "antd/dist/antd.css";
import Functions from "../../../Common/Functions";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#000",
        },
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: "auto",
    },
    dialogPaper: {
        overflow: "visible",
    },
};


function Transition(props) {
    return <Grow in={true} timeout="auto" {...props} />;
}

class AssetManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addAssetManager: false,
            editAssetManager: false,
            BranchName: "",
            open: true,
            AssetManagerId: "",
            tableData: [[" ", " "]],
            AssetManagerData: [[" ", " "]],
            redirectLogin: false,
        };
    }

    addAssetManager() {
        this.setState({
            addAssetManager: true,
        });
    }

    editAssetManager = (id) => {
        this.setState({
            editAssetManager: true,
            AssetManagerId: id,
        });
    };

    cancelAssetManagerModal = () => {
        this.setState({addAssetManager: false});
    };
    cancelEditAssetManagerIdModal = () => {
        this.setState({editAssetManager: false});
    };
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };
    closeModal = () => {
        this.setState({
            addAssetManager: false,
            editAssetManager: false,
        });
    };
    createTableData = (
        id,
        fileReferenceNo,
        loanType,
        applicantName,
        assetOpsNumber,
        accountNumber,
        closingDate,
        location,
        trunkOrBoxNumber,
        status,
        customerCB,
        loanAccounts,
        NOCStatus,
        fileDestroyStatus,
        docType,
        source,
        deferralDuration,
        deferralDetails,
        docTypeEntryCapacity,
        sourceEntryCapacity
    ) => {
        return [
            fileReferenceNo,
            loanType,
            applicantName,
            assetOpsNumber,
            accountNumber,
            closingDate,
            location,
            trunkOrBoxNumber,
            status,
            customerCB,
            loanAccounts,
            NOCStatus,
            fileDestroyStatus,
            docType,
            source,
            deferralDuration,
            deferralDetails,
            docTypeEntryCapacity,
            sourceEntryCapacity

            /*<button
              className="btn btn-outline-primary"
              style={{
                verticalAlign: "middle",
              }}
              onClick={() => this.editAssetManager(id)}
            >
              Edit
            </button>,*/
        ];
    };


    componentDidMount() {
        console.log("did mount ");
        let url = backEndServerURL + "/archiveManager/getAll";
        let getAllAssetManager = [];
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                },
            })
            .then((response) => {
                console.log(response);
                response.data.map((assetManager) => {
                    getAllAssetManager.push(
                        this.createTableData(
                            assetManager.id,
                            assetManager.fileReferenceNo,
                            assetManager.loanType,
                            assetManager.applicantName,
                            assetManager.assetOpsNumber,
                            assetManager.accountNumber,
                            assetManager.closingDate,
                            assetManager.location,
                            assetManager.trunkOrBoxNumber,
                            assetManager.status,
                            assetManager.customerCB,
                            assetManager.loanAccounts,
                            assetManager.NOCStatus,
                            assetManager.fileDestroyStatus,
                            assetManager.docType,
                            assetManager.source,
                            assetManager.deferralDuration,
                            assetManager.deferralDetails,
                            assetManager.docTypeEntryCapacity,
                            assetManager.sourceEntryCapacity
                        )
                    );
                });
                this.setState({
                    AssetManagerData: getAllAssetManager,
                });
                console.log("asset data " + this.state.AssetManagerData);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    assetManagerAddModal = () => {
        return (
            <AssetManagerAddEdit
                close={this.closeModal}
                data={this.state.tableData}
                name="Add New Asset Manager"
            />
        );
    };
    assetManagerEditModal = () => {
        return (
            <AssetManagerAddEdit
                close={this.closeModal}
                data={this.state.tableData}
                name="Edit Asset Manager"
                id={this.state.AssetManagerId}
            />
        );
    };

    render() {
        const {classes} = this.props;
        {
            Functions.redirectToLogin(this.state);
        }
        return (
            <section>


                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>Asset Manager List</h4>
                            </CardHeader>
                            <CardBody>
                                <div>


                                    <Table
                                        tableHeaderColor="primary"
                                        tableHead={[
                                            "File Reference No",
                                            "Loan Type",
                                            "Applicant Name",
                                            "Asset Ops Number",
                                            "Account Number",
                                            "Closing Date",
                                            "Location",
                                            "Trunk Or Box Number",
                                            "Status",
                                            "Customer CB",
                                            "Loan Accounts",
                                            "NOC Status",
                                            "File Destroy Status",
                                            "Docs Type",
                                            "Source",
                                            "Deferral Duration",
                                            "Deferral Details",
                                            "Doc Type Entry Capacity",
                                            "Source Entry Capacity",

                                        ]}
                                        tableData={this.state.AssetManagerData}
                                        tableAllign={[
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                        ]}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </section>
        );
    }
}

export default withStyles(styles)(AssetManager);
