import React, {Component} from "react";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../JsonForm/CustomeTheme2";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import Notification from "../NotificationMessage/Notification";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";
import MyValidation from "../JsonForm/MyValidation";
import Functions from "../../Common/Functions";

let Form = [

    {
        "varName": "unit_name",
        "type": "text",
        "label": "Unit Name",
        "grid": 2,
        "readOnly": true,
    },
    {
        "varName": "workplace_type",
        "type": "text",
        "label": "Workplace Type",
        "grid": 2,
        "readOnly": true,
    },
    {
        "varName": "branch_type",
        "type": "select",
        "enum":[
            "MAIN",
            "SUBBRANCH"
        ],
        "label": "branch type",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "branch_parent",
        "type": "autoCompleteValueReturn",
        "enum":[],
        "label": "Branch Parent",
        "grid": 2,
        "required": true,
    }

];
 let searchResultJsonForm = [

    {
        "varName": "firstName",
        "type": "text",
        "label": "First Name",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "lastName",
        "type": "text",
        "label": "Last Name",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "username",
        "type": "text",
        "label": "User Name",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "grid": 2,
        "validation": "email",
        "required": true,
    },
    {
        "varName": "role",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Role",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "solId",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sol Id",
        "grid": 2,
        "required": true,
    },
];
let workStationRequestType = [];
let roleRequestType = [];
let getAllBranchType = [];

class UserRoleEdit extends Component {
    state = {
        inputData: {},
        selectedDate: {},
        getData: false,
        workstation: false,
        role: false,
        showValue: false,
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},

        err: false,
        getuser: false,
        varValue: {},
        errorArray: {},
        errorMessages: {},
        title: "",
        notificationMessage: "",
        alert: false,
        otherRequestTitle: "",
        getBranch: false,
        getRole: false,
        getSearchResult: false,
        loading: false
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };
    findByVarNameGet = (roleName, AllRole) => {
        let jsonArray = AllRole;

        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            console.log(jsonObject);
            if (roleName === jsonObject.value) {
                return jsonObject;
                i = jsonArray.length;

            }
        }


    };
    findByVarNameGetBranch = (branchName, AllBranch) => {
        let jsonArray = AllBranch;

        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            console.log(jsonObject);
            if (branchName === jsonObject.key) {
                return jsonObject;
                i = jsonArray.length;

            }
        }


    };

    userSearch = (event) => {
        if (event.key === 'Enter') {
            this.setState({
                getSearchResult: false,
                loading: true
            });
            let inputData = {};
            let varValue = {};
            let searchText = event.target.value;
            let url = backEndServerURL + '/user/search/' + searchText;
            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let data = response.data[0];
                    console.log(response);
                    console.log("kkk");
                    console.log(data);

                    inputData.email = data.email;
                    inputData.firstName = data.firstName;
                    inputData.lastName = data.lastName;
                    inputData.role = this.findByVarNameGet(data.role, roleRequestType);
                    inputData.solId = this.findByVarNameGetBranch(data.solId, getAllBranchType);
                    inputData.username = data.username;

                    varValue.email = data.email;
                    varValue.firstName = data.firstName;
                    varValue.lastName = data.lastName;
                    varValue.role = this.findByVarNameGet(data.role, roleRequestType);
                    varValue.solId = this.findByVarNameGetBranch(data.solId, getAllBranchType);
                    varValue.username = data.username;
                    this.setState({
                        inputData: inputData,
                        varValue: varValue,
                        getData: true,
                        showValue: true,
                        getSearchResult: true,
                        loading: false

                    })

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        getSearchResult: true,
                        loading: false
                    });

                    this.setState({errorMessage: "Not Found"});
                })
        }
    };
    handleSubmit = (event) => {
        event.preventDefault();
        let errors = MyValidation.defaultValidation(Form, this.state);
        this.forceUpdate();

        if (errors === true) {
            console.log("Not working");
            return 0;
        }
        let data = {};
        this.setState({
            loading: true
        });
        console.log(this.state.inputData);


        let dependencyField = searchResultJsonForm;

        let inputData = this.state.inputData;

        data.branchType = inputData.branch_type;
        data.branchParent = inputData.branch_parent!==undefined && inputData.branch_parent.key!==undefined?inputData.branch_parent.key:"";

        let url = backEndServerURL + "/updateWorkplaceUnit/"+this.props.id;
        axios.post(url, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.close()
                console.log(response.data);
                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Edited!",
                    alert: true,
                    loading: false
                });
                window.location.reload()
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    title: "Error!",
                    notificationMessage: Functions.errorObjectCheck(error),
                    alert: true,
                });
                console.log(error)
            })
    };
    renderButton = () => {
        if (this.state.getSearchResult) {
            return (

                <button
                    className="btn btn-outline-primary  btn-sm"
                    style={{
                        marginTop: "18px"
                    }}
                    onClick={this.handleSubmit}

                >
                    Update
                </button>


            )
        }


    };

    componentDidMount() {

        let roleListUrl = backEndServerURL + "/getWorkplaceUnit/"+this.props.id;
        axios.get(roleListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let roleListUrl = backEndServerURL + "/getWorkplaceUnitMasterDataList";
                axios.get(roleListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((responseData) => {
                        let fieldVarName = responseData.data;
                         let selectList=[];

                        for (let i = 0; i < Form.length; i++) {
                            let jsonObject = Form[i];
                            if (jsonObject.varName === "branch_parent") {
                                Form[i].enum = fieldVarName;

                            }
                        }
                        let inputData=response.data;
                        this.setState({
                            inputData: inputData,
                            varValue: {...inputData},
                            getData: true,
                            showValue: true,
                            getSearchResult: true,
                            loading: false

                        })
                    })
                    .catch((error)=>{
                        console.log(error);
                    })

                console.log("Form");
                console.log(Form);


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    getSearchResult: true,
                    loading: false
                });

             })


    }

    updateComponent = () => {
        this.forceUpdate();
    };


    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();


        }

    };
    returnSearchResultJsonForm = () => {
        if (this.state.getSearchResult) {

            console.log(this.state.inputData);
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, Form, this.updateComponent, this.onKeyDownForDedup)

            )
        }
    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    close=()=>{
        this.props.closeModal()
    }
    renderHeader = () => {
       // if (this.props.appId !== undefined) {
            return (
                <h6>Edit Workplace<a><CloseIcon onClick={this.close} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h6>
            )
      /*  } else {
            return (
                <h6>Edit Workplace</h6>
            )
        }*/
    };

    render() {
        return (

            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderHeader()}

                </CardHeader>
                <CardBody>
                    <div>

                        <ThemeProvider theme={theme}>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                open={this.state.loading}>
                                <DialogContent>

                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>
                            <Grid container>
                                {this.renderNotification()}
                                {this.returnSearchResultJsonForm()}


                                <Grid item xs={12}>
                                </Grid>
                                <Grid item xs={1}>
                                    {this.renderButton()}
                                </Grid>

                            </Grid>

                        </ThemeProvider>


                    </div>


                </CardBody>
            </Card>


        )
    }

}

export default UserRoleEdit;