import React, {Component} from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import SubmittedCaseHistory from "./SubmittedCaseHistoryAwf";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class FlowSummeryButtonAwf extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            showValue: true,
            cbNotFound: false,
            title: "",
            dormant: false,
            showButton: false,
            appId: "",
            customerName: "",
            cbNumber: "",
            accountNumber: "",
            solId: "",
            history: false
        }
    }

    caseHistory = (e) => {
        e.preventDefault();
        this.setState({
            appId: this.props.appId,
            customerName: this.props.customerName,
            accountNumber: this.props.accountNumber,
            solId: this.props.solId,
            cbNumber: this.props.cbNumber,
            history: true
        });
    };

    closeHistoryModal = () => {
        this.setState({
            history: false,
        })
    };

    componentDidMount() {

    }


    render() {
        return (
            <div>
                <button
                    onClick={this.caseHistory}
                    className="btn btn-danger btn-sm">
                    Flow Summery
                </button>

                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    open={this.state.history}>
                    <DialogContent>
                        <SubmittedCaseHistory
                            branchName={this.props.branchName}
                            caseId={this.props.caseId}
                            subServiceType={this.props.subServiceType}
                            category={this.props.category}
                            serviceType={this.props.serviceType}
                            subCategory={this.props.subCategory}
                            multiTaskLink={this.props.multiTaskLink} cbNumber={this.props.cbNumber}
                            customerName={this.state.customerName}
                            accountNumber={this.state.accountNumber} solId={this.state.solId}
                            globalSearch={true} closeIcon={this.closeHistoryModal}
                            appId={this.state.appId} closeModal={this.closeHistoryModal}/>
                    </DialogContent>
                </Dialog>
            </div>
        );


    }
}

export default withStyles(styles)(FlowSummeryButtonAwf);