import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import InputBase from '@material-ui/core/InputBase';
import {fade} from '@material-ui/core/styles/colorManipulator';
import {withStyles} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Toolbar from '@material-ui/core/Toolbar';
import axios from 'axios';
import cookie from 'react-cookies';
import {processmakerServerURL} from '../../Common/Constant';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import UserAddEditDelete from '../AdminModule/UserAddEditDelete.jsx';
import Functions from '../../Common/Functions';
import {Dialog} from "@material-ui/core";

const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },

    appBar: {
        position: 'relative',
    },
    toolbarTitle: {
        flex: 1,
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            width: 900,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },

    root: {
        width: '100%',
        // marginTop: theme.spacing.unit * 3,
        // overflowX: 'auto',

    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },

    }, rightToolbar: {
        marginLeft: 'auto',
        marginRight: -12,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: theme.spacing.unit * 2,
        marginRight: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit * 3,
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    inputRoot: {
        color: '#FF0000',
        width: '100%',

    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },

    },

    table: {
        minWidth: 700,

    },
    footer: {
        marginTop: theme.spacing.unit * 8,
        borderTop: `1px solid ${theme.palette.divider}`,
        padding: `${theme.spacing.unit * 6}px 0`,
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${70}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }, textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        }
    }
});

class UserList extends React.Component {
    state = {
        users: [],
        userName: '',
        userNames: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        filterText: '',
        errorMessage: " ",
        open: false,
        visible: false,
        editUser: false,
        AddUser: false,
        username: '',
        userUid: "",
        user: {},
        redirectLogin: false,

    };

    editUser(username) {

        this.setState({
            editUser: true,
            username: username,
        });
    }

    AddUser = () => {

        this.setState({
            AddUser: true,

        });
    };

    deleteUser(uid) {
        this.setState({
            deleteUser: true,
            userUid: uid,
        });
    }

    closeModal = () => {
        this.setState({
            editUser: false,
            AddUser: false
        });
    };
    handleOpen = () => {
        this.setState({open: true});
    };
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    handleDelete = (event) => {
        event.preventDefault();
        let url = processmakerServerURL + "/api/1.0/workflow/user/" + this.state.userUid;
        let authHeader = "Bearer " + cookie.load("accessToken");

        axios.delete(url,
            {headers: {"Authorization": authHeader}}
        )
            .then(response => console.log(response.data))
            .catch((error) => {
                console.log(error);

                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
                this.setState({errorMessage: "Not Found"});
            });
    };

    handleKeyDown = (event) => {

        if (event.key === 'Enter') {
            let searchText = event.target.value;

            let url = processmakerServerURL + '/api/1.0/workflow/users?filter=' + searchText + '&limit=100';
            let authHeader = "Bearer " + cookie.load("accessToken");

            console.log(authHeader);

            axios.get(url,
                {headers: {"Authorization": authHeader}})
                .then((response) => {
                    console.log("Enter pressed");
                    let responseData = [];
                    if (Array.isArray(response.data))
                        responseData = response.data;
                    this.setState({
                        users: responseData
                    });
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error);

                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                    this.setState({errorMessage: "Not Found"});
                })
        }
    };
    returnUserList = (user) => {

        return (<TableRow key={user.usr_uid}>
            <TableCell component="th" scope="row">
                {user.usr_username}

            </TableCell>
            <TableCell align="left">{user.usr_firstname}</TableCell>
            <TableCell align="left">{user.usr_lastname}</TableCell>
            <TableCell align="left">{user.usr_email}</TableCell>

            <TableCell align="left"><Button
                style={{margin: "5px"}}
                variant="contained"
                color="secondary"

                onClick={() => {
                    this.setState({
                        username: user.username,
                        editUser: true
                    })
                }
                }>Edit</Button></TableCell>
            <TableCell align="left"><Button
                style={{margin: "5px"}}
                variant="contained"
                color="secondary"
                onClick={this.handleOpen}>Delete</Button></TableCell>
        </TableRow>);
    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <section>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    open={this.state.editUser}
                    onClose={this.closeModal}
                >
                    <UserAddEditDelete name="Edit user" username={this.state.username} closeModal={this.closeModal}
                    />


                </Dialog>
                <Dialog
                    className={classes.modal}
                    open={this.state.deleteUser}
                    onClose={this.closeModal}
                >


                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    open={this.state.AddUser}
                    onClose={this.closeModal}
                >
                    <UserAddEditDelete/>

                </Dialog>
                <Dialog
                    className={classes.modal}
                    open={this.state.deleteUser}
                    onClose={this.closeModal}
                >


                </Dialog>

                <React.Fragment>
                    {/* <CssBaseline /> */}
                    <div className={classes.root}>
                        <AppBar position="static" style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                            <center>
                                <Button onClick={() => this.AddUser()} color="primary">
                                    Add User
                                </Button>
                            </center>
                            <Toolbar>
                                <section className={classes.rightToolbar}>

                                    <div className={classes.search}>
                                        <div className={classes.searchIcon}>
                                            <SearchIcon/>

                                        </div>


                                        <InputBase
                                            placeholder="Search…"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput,
                                            }}
                                            onKeyDown={this.handleKeyDown}
                                            onChange={this.handleChange}
                                        />

                                    </div>


                                </section>

                            </Toolbar>
                        </AppBar>


                        <div>


                            <Paper className={classes.root}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">User Name</TableCell>
                                            <TableCell align="left">First Name</TableCell>
                                            <TableCell align="left">Last Name</TableCell>
                                            <TableCell align="left">Email</TableCell>
                                            <TableCell align="left">Edit</TableCell>
                                            <TableCell align="left">Delete</TableCell>
                                        </TableRow>
                                    </TableHead>


                                    <TableBody>
                                        {
                                            this.state.users.map((user) => {
                                                return this.returnUserList(user);
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </Paper>
                        </div>

                    </div>
                </React.Fragment>
            </section>
        );
    }
}


UserList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserList);
