import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles/index";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios/index';
import {backEndServerURL} from "../../../Common/Constant";
import GridItem from "../../Grid/GridItem.jsx";
import GridContainer from "../../Grid/GridContainer.jsx";


class ShowDocuments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: true,
            getDeferalList: [],
            defferalData: false,
            getData: false,
            images: [],
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            documentList: [],
            getDocument: false
        }
    }


    componentDidMount() {

        console.log(this.props.appId);

        let fileUrl = backEndServerURL + "/case/files/" + this.props.appId;
        axios.get(fileUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(":d");
                console.log(response.data);
                this.setState({
                    documentList: response.data,
                    getDocument: true
                })
            })
            .catch((error) => {
                console.log(error);
            })
    }

    renderImage = () => {

        if (this.state.getDocument) {


            let rtgs, beftn;

            this.state.documentList.map((document) => {

                if (document.indexOf("AOF1") > -1) {
                    rtgs = document;
                } else if (document.indexOf("AOF2") > -1) {
                    beftn = document;
                }

            });

            return (
                <React.Fragment>
                    <img width='100%' src={backEndServerURL + "/file/" + rtgs} alt=""/>
                    <img width='100%' src={backEndServerURL + "/file/" + beftn} alt=""/>
                </React.Fragment>

            )
        }

    };


    render() {
        return (

            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Preview Document<a><CloseIcon onClick={this.props.closeModal} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></h4>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <Grid container spacing={3}>
                                    <ThemeProvider theme={theme}>

                                        {
                                            this.renderImage()
                                        }


                                    </ThemeProvider>
                                </Grid>
                            </div>

                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

        );
    }


}

export default ShowDocuments;