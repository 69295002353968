import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../../Common/Functions';
import Notification from "../../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../../JsonForm/CustomeTheme";
import Table from "../../../Table/Table";
import {Dialog} from "@material-ui/core/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import loader from "../../../../Static/loader.gif";
import TextField from "@material-ui/core/TextField";
import Label from "../static/Label"
import SignatureButton from "../SignatureButton";
import PreviewMappingImage from "../casa/PreviewMappingImage";
import GridContainer from "../../../Grid/GridContainer";
import GridItem from "../../../Grid/GridItem";
import DedupResultFunction from "../../../DedupResultFunction";
import {CBDataJsonForm} from "../../WorkflowJsonForm4";
import SdnResultFunction from "../../../SdnResultFunction";
import CBNotFound from "../../CASA/CBNotFound";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";
import MyValidation from "../../../JsonForm/MyValidation";

let signatoryForm = [
    {
        "varName": "mandateOption",
        "type": "select",
        "label": "Mandate/Related Party Add or Remove",
        "readOnly": true,
        "onKeyDown": true,
        "required": true,
        "enum": [
            "Add Mandate/Related Party",
            "Remove Mandate/Related Party",
        ],
        "grid": 3,
    },
    {
        "varName": "numberOfNewMandate",
        "type": "select",
        "label": " Number of New Mandate/Beneficial Owner/Signatory",
        // "onKeyDown": true,
        "readOnly": true,
        "enum": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10"
        ],
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "mandateOption",
        "conditionalVarValue": "Add Mandate/Related Party",
    },
];

let bmRemarks = [
    {
        "varName": "bmRemarks",
        "type": "textArea",
        "label": "BM Remarks",
        "required": true,
        "grid": 12
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};


class BMApproval2 extends React.Component {
    state = {
        message: "",
        getData: false,
        varValue: {},
        title: "",
        notificationMessage: "",
        alert: false,
        inputData: {},
        selectedDate: {},
        showValue: false,
        err: false,
        errorArray: {},
        errorMessages: {},
        getDeferalList: [],
        loading: true,
        getImageLink: [],
        getImageBoolean: false,
        selectImage: "",
        getRemarks: [],
        disabled: false,
        mandateTableArray: [],
        cbNotFound: false,
        confirmationAlert: false,
        submitButtonData: ""
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    componentDidMount() {
        var remarksArray = [];
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let getDataByAc = backEndServerURL + "/GetCustomAcctDetailsRequest/" + response.data.accountNumber;
                    axios.get(getDataByAc, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            if (response.status === 200) {
                                let mandateTableArray = [];
                                response.data.relPartyList.map((mandate, index) => {
                                    if (mandate.relPartyType !== "M") {
                                        let exFlag;
                                        if(mandate.recDelFlg === "Y"){
                                            exFlag = "CB Deleted"
                                        }
                                        else if(mandate.recDelFlg === "N"){
                                            exFlag = "CB Existed"
                                        }
                                        else{
                                            exFlag = mandate.recDelFlg;
                                        }
                                        mandateTableArray.push(this.createMandateTableData(index, mandate.custId, mandate.name, mandate.relPartyCode, mandate.relPartyTypeDesc, exFlag))
                                    }
                                });
                                this.setState({
                                    mandateTableArray: mandateTableArray,
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                getData: false,
                                loading: false,
                                cbNotFound: true,
                                title: error.response.data.message
                            })

                        });

                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            let tableArray = [];
                            var status = "";
                            response.data.map((deferal) => {
                                if (deferal.status === "APPROVAL_WAITING") {
                                    status = "Waiting For Approval"
                                }

                                tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));

                            });
                            let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    console.log(response.data);
                                    this.setState({
                                        getImageLink: response.data,
                                        getImageBoolean: true
                                    });
                                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response) => {

                                            console.log(response.data);
                                            response.data.map((data) => {

                                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                            });
                                            this.setState({
                                                getRemarks: remarksArray
                                            })
                                        })
                                        .catch((error) => {
                                            console.log(error)
                                        })


                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                            this.setState({
                                getDeferalList: tableArray
                            })

                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    this.setState({
                        getRemarks: remarksArray
                    });
                    this.setState({
                        getData: true,
                        showValue: true,
                        varValue: this.removeNullValue(response.data),
                        inputData: this.removeNullValue(response.data),
                    });

                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    stopLoading = (value) => {
        if (value === "yes") {
            this.setState({
                loading: false
            })
        } else {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };

    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                confirmationAlert: false,
                disabled: true,
                loading: true,
            });
            let data = this.state.submitButtonData;
            var appId = this.props.appId;
            var approval;
            data === "APPROVED" ? approval = "APPROVE" : approval = "REJECT";
            let deferalUrl = backEndServerURL + "/deferral/approval";
            axios.post(deferalUrl, {
                appId,
                approval
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    this.setState({
                        disabled: false,
                    });
                    console.log(error);
                });


            let postData = this.state.inputData;
            postData.mandate_approval = data;
            postData.cs_deferal = "NO";
            postData.csDeferal = "NO";
            postData.bm_approval = data;
            postData.mandate_bom_approval = "APPROVED";
            let remarksData = {};
            remarksData.remark = this.state.inputData.bmRemarks;
            remarksData.map = {...postData, bmRemarks: undefined};
            let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
            axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                    disabled: false,
                });
                this.props.closeModal();
            }).catch((error) => {
                console.log(error);
                this.setState({
                    disabled: false,
                    loading: false
                })
            });
        } else {
            this.setState({
                confirmationAlert: false
            })
        }
    };

    handleSubmit = (event, data) => {
        event.preventDefault();
        if (data === "RETURN") {
            let error = MyValidation.defaultValidation(bmRemarks, this.state);
            this.forceUpdate();
            if (error === true) {
                return false;
            }
        }
        this.setState({
            submitButtonData: data,
            title: "Do you want to confirm?",
            confirmationAlert: true
        });
    };


    renderRemarks = () => {
        if (this.state.getData) {

            return (

                CommonJsonFormComponent.renderJsonForm(this.state, bmRemarks, this.updateComponent)

            )
        }
    };

    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Deferral List:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferal Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };


    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "APPROVED")}
                    >Approve
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "RETURN")}
                    >Return
                    </button>
                </div>
            )
        }
    };

    renderNomineeNumber = () => {
        if (this.state.getData) {
            return (
                <React.Fragment>
                    <Grid item>
                        <label className="input-label-common">Number Of Debit Card</label>
                    </Grid>
                    <Grid item>
                        <TextField
                            variant="outlined"
                            defaultValue={this.state.inputData["numberOfDebitCard"]}
                            name="numberOfDebitCard"
                            //label={field.label}
                            InputProps={{
                                readOnly: true
                            }}
                            //InputLabelProps={{shrink:true}}
                            fullWidth
                            style={{paddingRight: 20}}
                        />
                    </Grid>
                </React.Fragment>


            )
        }
    };

    viewAttachedImages = () => {
        const {classes} = this.props;
        if (this.state.getData && this.state.inputData.previewImage) {
            return <PreviewMappingImage appId={this.props.appId} classes={classes}/>
        }
    };

    signature = () => {
        if (this.state.getData) {
            return (
                <Grid container spacing={0}>
                    <br/>
                    <SignatureButton accountNumber={this.state.inputData.accountNumber} classes={this.props}
                                     signatureType="multiple"/>
                    <br/>
                </Grid>
            )
        }
    };

    renderDedupComponent = () => {
        if (this.state.getData && this.state.inputData.numberOfNewMandate !== "NA") {
            return (
                <div style={{marginTop: "15px"}}>
                    <DedupResultFunction radioButton="NO" category={this.props.category} taggingForm={CBDataJsonForm}
                                         removingTaggingData={this.removingTaggingData}
                                         getTaggingData={this.getTaggingData} subServiceType={this.props.subServiceType}
                                         appId={this.props.appId}/>
                </div>

            )
        }
    };
    renderSdnComponent = () => {
        if (this.state.getData && this.state.inputData.numberOfNewMandate !== "NA") {
            return (
                <div style={{marginTop: "43px"}}>
                    <SdnResultFunction category={this.props.category} taggingForm={CBDataJsonForm}
                                       removingTaggingData={this.removingTaggingData}
                                       getTaggingData={this.getTaggingData}
                                       subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };

    renderLabel = () => {
        if (this.state.getData) {
            return (
                <>
                    <Label accountNumber={this.state.inputData.accountNumber}
                           cbNumber={this.state.inputData.cbNumber} stopLoading={this.stopLoading} classes={this.props}
                           style={{margin: "0 auto"}} crmStatus={this.state.inputData.crmStatus}/>
                </>
            )
        }
    };

    renderMandateData = () => {
        if (this.state.mandateTableArray.length > 0 && !this.state.loading && this.state.getData) {
            return (
                <>
                    <br/>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Existing Related Party:</b>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Sl", "CB Number", "Customer Name", "Relationship Code", "Designation Code", "Related CB Status"]}
                                tableData={this.state.mandateTableArray}
                                tableAllign={['left', 'left', 'left', 'left', 'left', 'left']}
                            />
                            <br/>
                        </div>
                    </div>
                    <br/>
                </>
            )
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
        })
    };

    createMandateTableData = (sl, cb, name, relationship, designation, existingDeleteFlag) => {

        return ([
            sl, cb, name, relationship, designation, existingDeleteFlag
        ])

    };

    renderSignatoryForm = () => {
        if (this.state.getData) {
            return CommonJsonFormComponent.renderJsonForm(this.state, signatoryForm, this.updateComponent)
        }
    };

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);

        return (
            <div>
                <ThemeProvider theme={theme}>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.confirmationAlert}>
                        <DialogContent className={classes.dialogPaper}>
                            <ConfirmationModal
                                closeModal={this.closeConfirmation}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    {this.renderLabel()}
                    <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        {this.renderSignatoryForm()}
                    </ThemeProvider>
                    </Grid>

                    <Grid item xs={12}>
                        <br/>
                        {this.signature()}
                    </Grid>
                    <Grid item={12}>
                        {this.renderMandateData()}
                    </Grid>
                    {this.renderNotification()}
                    <GridContainer>
                        <GridItem xs={6}>
                            {this.renderDedupComponent()}
                        </GridItem>
                        <GridItem xs={6}>
                            {this.renderSdnComponent()}
                        </GridItem>
                    </GridContainer>
                    <br/>
                    <Grid item={12}>
                        {this.renderDefferalData()}
                    </Grid>

                    <br/>
                    <Grid item={12}>
                        {this.renderRemarksData()}
                    </Grid>
                    {
                        this.viewAttachedImages()
                    }
                    <br/>
                    {this.renderRemarks()}
                    <br/>
                    <div>
                        {this.renderSubmitButton()}
                    </div>
                </ThemeProvider>
            </div>
        )
    }

}

export default withStyles(styles)(BMApproval2);
