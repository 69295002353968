import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import 'semantic-ui-offline/semantic.min.css';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import CloseIcon from "@material-ui/icons/Close";
import Table from "../../Table/Table";
import CBNotFound from "../CASA/CBNotFound";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let jsonForm = [
    {
        varName: "accountNumber",
        type: "text",
        label: "Account Number",
        grid: 3,
        readOnly: true
    },
    {
        varName: "fromDate",
        type: "date",
        label: "From Date",
        grid: 3,
        required: true
    },
    {
        varName: "toDate",
        type: "date",
        label: "To Date",
        grid: 3,
        required: true
    },
];


class Forward extends Component {

    constructor(props) {
        super(props);
        this.state = {
            err: false,
            errorArray: {},
            errorMessages: {},
            varValue: {},
            inputData: {},
            title: "",
            notificationMessage: "",
            loading: true,
            selectedDate: {},
            getStatement: [],
            showValue: false,
            cbNotFound: false
        };
    }

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    componentDidMount() {
        let inputData = {};
        inputData.accountNumber = this.props.accountNumber;
        this.setState({
            inputData: inputData,
            varValue: this.copyJson(inputData),
            showValue: true,
            loading: false
        })
    }


    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    functionForGetStatement = () => {
        let error = MyValidation.defaultValidation(jsonForm, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true
            });
            let url = backEndServerURL + "/getFullAccountStatement/" + this.props.accountNumber;
            // let url = backEndServerURL + "/getFullAccountStatement/1422473788001";
            axios.post(url, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    let getStatement = [];
                    response.data.transactionInfo.map((data) => {
                        let debit = "";
                        let credit = "";
                        if (data.txnType === "C") {
                            credit = data.txnAmt_amountValue;
                            debit = "";
                        } else {
                            credit = "";
                            debit = data.txnAmt_amountValue;
                        }
                        getStatement.push(this.createStatementTable(data.txnId, data.txnDate.split("T")[0], data.valueDate.split("T")[0], data.txnDesc, data.txnAmt_currencyCode, debit, credit, data.txnBalance_amountValue))
                    });
                    this.setState({
                        getStatement: getStatement,
                        loading: false
                    });
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        title: "Operation Not Successful!",
                        cbNotFound: true
                    })
                });
        }
    };

    createStatementTable = (id, transactionDate, valueDate, particulars, CCY, debitAmount, creditAmount, balance) => {
        return (
            [id, transactionDate, valueDate, particulars, CCY, debitAmount, creditAmount, balance]
        )
    };

    renderStatementData = () => {
        if (this.state.getStatement.length > 0) {
            return (
                <div style={{marginTop: "5px", width: "100%"}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Account Statement Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Transaction ID", "Transaction Date", "Transaction Value Date", "Particulars", "CCY", "Debit Amount", "Credit Amount", "Balance"]}
                            tableData={this.state.getStatement}
                            tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }

    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >
                                Search Account Statement
                                <a><CloseIcon onClick={() => this.props.closeModal()} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a>
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        jsonForm,
                                        this.updateComponent
                                    )}
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        onClick={this.functionForGetStatement}
                                        style={{
                                            height: 30,
                                            marginTop: 16,
                                        }}
                                    >
                                        Search
                                    </button>
                                    <Grid item xs={12}><br/></Grid>
                                    {this.renderStatementData()}
                                </ThemeProvider>
                            </Grid>
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

}

export default withStyles(styles)(Forward);
