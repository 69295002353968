import React, {Component} from 'react'


import 'antd/dist/antd.css';

import {Layout} from 'antd';


const {
    Footer
} = Layout;


class FooterContent extends Component {
    render() {
        return (


            <Footer style={{textAlign: 'center'}}>
                Green Delta ©2018 Limited
            </Footer>

        )
    }
}

export default FooterContent;