import { ArrowUpward, FilterList, PictureAsPdf, SaveAlt, Search } from '@material-ui/icons';
import MaterialTable from 'material-table'
import React, { forwardRef, useEffect, useMemo, useState } from 'react'




 
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import { localization } from '../DeliverableManagement/configuration';

// import Remove from '@material-ui/icons/Remove';
// import SaveAlt from '@material-ui/icons/SaveAlt';

// import ViewColumn from '@material-ui/icons/ViewColumn';

// const tableIcons = {
//     Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
//     Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
//     Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//     Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
//     DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
//     Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
//     Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
//     Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
//     FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
//     LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
//     NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
//     PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
//     ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//     Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
//     SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
//     ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
//     ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
//   };
const Reports =(props)=> {

 console.log('props data', props.tableData)
const {tableData,tableColumns}=props
 // const [data, setData] = useState([])
  
  // useEffect(() => {
  //   fetch("https://jsonplaceholder.typicode.com/users")
  //     .then(resp => resp.json())
  //     .then(resp => {
  //       setData(resp)
  //     })
  // }, [])

  //const [remoteData, setRemoteData] = useState([]);
  


// const fetchData = async () => {
//       //setIsLoading(true);
//       const response = await fetch(
//         `https://mocki.io/v1/8b3e49c8-b556-4769-a42e-19405054b496`
       
//         // {
//         //   headers: { Authorization: `Bearer ${token}` }
//         // }
//       )
//       const json = await response.json();
//       setRemoteData(
//         //console.log('json data show',await json.data),
//        setRemoteData(json.data)
//       );
//      // setIsLoading(false);
//     };
//     console.log('besfore data')
//     //fetchData()
//     console.log('json remote data show',remoteData)
//     console.log('after json data show')
//   useEffect(() => {
  
// fetch('https://dummyjson.com/products/1')
// .then(res => res.json())
// .then(json => setRemoteData(json))
//   }, [remoteData])
   
  // const parsedData = useMemo(
  //   console.log('data',remoteData),
  //   () =>
  //     remoteData.map((userData) => ({
  //       branch : userData.branch,
  //       movementType: userData.movementType,        
  //       documentType: userData.documentType,
  //       sender: userData.sender,
  //       receiver: userData.receiver,
  //       details: userData.details,
  //       currentStatus: userData.currentStatus,       
  //       date: userData.date,   
  //     })) ,
  //   [remoteData]
  // );
  // console.log('json data show',parsedData);
  // api call end
   
  const tableIcons = {
    SortArrow: forwardRef((props, ref) => (
      <ArrowUpward {...props} ref={ref} />
    )),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  };
//   const  localization={{
//     pagination: {
//         labelDisplayedRows: '{from}-{to} of {count}'
//     }
// }}
    return (  
       <MaterialTable
        title=""
       icons={tableIcons}
       localization={localization}
        data={tableData }
        columns={tableColumns}
          
        options={{
          search: true,
          sorting: true,
          headerStyle: {
            backgroundColor: "#e8e4e3",
            //"#01579b",
            //color: "#FFF",
          },
          //toolbar :false
          //exportButton: true,
         // filtering: true
        }}
      />
      
    )
  }

export default Reports
