import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";

import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import '../../Static/css/login.css';
import Grid from '@material-ui/core/Grid';


const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        background: '#f6f1ff'
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 400,
    },
});

class NewAccount extends Component {

    render() {
        const {classes} = this.props;
        return (

            <GridContainer>

                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>New Customer Account opening</h4>
                        </CardHeader>
                        <CardBody>
                            <div className={classes.root}>
                                <form onSubmit={this.handlesubmit} className={classes.form}>
                                    <Grid container spacing={24}>

                                        <Grid item xs={12} sm={3}>


                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="cb">Cb Number</InputLabel>
                                                <Input onChange={this.handlecb} id="cb" name="cb" autoComplete="cb"
                                                       autoFocus/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                Date of Birth
                                                <Input onChange={this.handledob} name="dob" type="Date" id="dob"
                                                       autoComplete="dob"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="phone">Phone No</InputLabel>
                                                <Input onChange={this.handlephone} name="phone" id="phone"
                                                       autoComplete="phone"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="email">Email</InputLabel>
                                                <Input onChange={this.handleemail} name="email" id="email"
                                                       autoComplete="email"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="introducerCb">Introducer Cb</InputLabel>
                                                <Input onChange={this.handleintroducerCb} name="introducerCb"
                                                       id="introducerCb"
                                                       autoComplete="introducerCb"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="debitCard">Debit Card</InputLabel>
                                                <Input onChange={this.handledebitCard} name="debitCard" id="debitCard"
                                                       autoComplete="debitCard"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                Cheque Book Request
                                                <select name="cheque" id="">
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                Account Sources
                                                <select name="sources" id="">
                                                    <option value="finacle">Finacle</option>
                                                    <option value="ababil">Ababil</option>
                                                </select>
                                            </FormControl>
                                            <br/>
                                            <br/>
                                            <br/>


                                        </Grid>
                                        <Grid item xs={12} sm={3}>


                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="account">Account Number</InputLabel>
                                                <Input onChange={this.handleaccount} id="account" name="account"
                                                       autoComplete="account" autoFocus/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="nid">Nid No</InputLabel>
                                                <Input onChange={this.handlenid} name="nid" id="nid"
                                                       autoComplete="nid"/>
                                            </FormControl>

                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="bCertificate">Birth Crtificate No</InputLabel>
                                                <Input onChange={this.handlebCertificate} name="bCertificate" id="nid"
                                                       autoComplete="bCertificate"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="rmCode">Rm code</InputLabel>
                                                <Input onChange={this.handlermCode} name="rmCode" id="rmCode"
                                                       autoComplete="bCertificate"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                SBS Code
                                                <select name="sbsCode" id="">
                                                    <option value="E3543">E3543</option>
                                                    <option value="E654345">E654345</option>
                                                </select>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="eStatement">E-Statement</InputLabel>
                                                <Input onChange={this.handleeStatement} name="eStatement"
                                                       id="eStatement"
                                                       autoComplete="eStatement"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                Locker Service Request
                                                <select name="locker" id="">
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            </FormControl>


                                            <FormControl margin="normal" fullWidth>
                                                Forward To
                                                <select name="forward" id="">
                                                    <option value="bom">Bom</option>
                                                    <option value="bm">Bm</option>
                                                </select>
                                            </FormControl>
                                            <br/>
                                            <br/>
                                            <br/>


                                        </Grid>
                                        <Grid item xs={12} sm={3}>


                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="name">Customer Name</InputLabel>
                                                <Input onChange={this.handlename} id="name" name="name"
                                                       autoComplete="name" autoFocus/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="passport">Passport No</InputLabel>
                                                <Input onChange={this.handlepassport} name="passport" id="passport"
                                                       autoComplete="passport"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="drivingLicense">Driving License</InputLabel>
                                                <Input onChange={this.handledrivingLicense} name="drivingLicense"
                                                       id="drivingLicense" autoComplete="drivingLicense"/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                Account Type
                                                <select name="accountType" id="">
                                                    <option value="savings">Savings</option>
                                                    <option value="current">Current</option>
                                                </select>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                Sms Alert Request
                                                <select name="smsAlert" id="">
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                City Touch Id Request
                                                <select name="cityTouch" id="">
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="productCode">Product Code</InputLabel>
                                                <Input onChange={this.handleproductCode} name="productCode"
                                                       id="productCode" autoComplete="productCode"/>
                                            </FormControl>
                                            <br/>
                                            <br/>
                                            <br/>


                                        </Grid>

                                    </Grid>
                                    <center>
                                        <Button
                                            type="primary" htmlType="submit" className={classes.Buttoncolorchange}


                                            variant="contained"
                                            color="secondary"

                                        >

                                            Next
                                        </Button>
                                    </center>
                                </form>
                            </div>


                        </CardBody>
                    </Card>
                </GridItem>

            </GridContainer>

        );
    }
}

export default withStyles(styles)(NewAccount);
