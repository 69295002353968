import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Functions from "../../../Common/Functions";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {Menu} from "semantic-ui-react";
import Paper from "@material-ui/core/Paper";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import CardHeader from "../../Card/CardHeader";
import CardBody from "../../Card/CardBody";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme";
import Table from "@material-ui/core/Table";
import {Dialog, withStyles} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import {AbhMakerJsonFormForAgentIndividual, AbhMakerJsonFormForAgentNonIndividual} from "../WorkflowJsonForm4";
import CSInboxCase from "../CSInboxCase";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";

const filteringJsonForm = [
    {
        "varName": "customer_name",
        "type": "text",
        "label": "Customer Name",
        "grid": 2,

    },
    {
        "varName": "cb_number",
        "type": "text",
        "grid": 2,
        "label": "CB Number",
    },
    {
        "varName": "branchName",
        "type": "select",
        "enum": [
            "MOTHIJHEEL",
            "GULSHAN BRANCH"
        ],
        "grid": 2,
        "label": "Branch Name",
    },
    {
        "varName": "category",
        "type": "select",
        "grid": 2,
        "label": "Category Type",
        "enum": [
            "CASA",
            "FDR",
            "DPS",
            "SANCHOYPATRA",
            "BOND",
            "CARDS"
        ]
    },
    {
        "varName": "subCategory",
        "type": "select",
        "label": "Sub Category Type",
        "grid": 2,
        "enum": [
            "A/C Opening",
            "Maintenance",
            "Closing",
        ]
    },
    {
        "varName": "urgency",
        "type": "select",
        "label": "Urgency",
        "grid": 2,
        "enum": [
            "HIGH",
            "NORMAL",
        ]
    },


];


let counter = 0;

function createData(index, customer_name, cb_number, account_number, appUid, service_type, subservice_type, branch_id, currentUserRole, taskTitle, jointAccountCustomerNumber, delInitTime, category, subCategory, delStatus, caseId) {


    return {
        id: index + 1,
        customer_name,
        cb_number,
        account_number,
        appUid,
        service_type,
        subservice_type,
        branch_id,
        currentUserRole,
        taskTitle,
        jointAccountCustomerNumber,
        delInitTime,
        category,
        delStatus,
        caseId,
        subCategory
    };
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
    //{id: 'id', numeric: true, disablePadding: true, label: 'SL'},
    {id: 'SL', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'CaseId', numeric: false, disablePadding: true, label: 'Case ID'},
    {id: 'customer_name', numeric: false, disablePadding: true, label: 'Name'},
    {id: 'cb_number', numeric: false, disablePadding: true, label: 'CB Number'},
    {id: 'account_number', numeric: false, disablePadding: true, label: 'Account Number'},
    {id: 'category_type', numeric: false, disablePadding: true, label: 'Category'},
    {id: 'sub_category_type', numeric: false, disablePadding: true, label: 'Sub Category'},
    {id: 'Date', numeric: false, disablePadding: true, label: 'Date of Activity'},
    {id: 'currentUserName', numeric: false, disablePadding: true, label: 'Current User'},
    {id: 'branch_name', numeric: false, disablePadding: true, label: 'Branch Name'},
    /*  {id: 'View', numeric: false, disablePadding: false, label: 'Action'}*/
];

class TableContentHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {order, orderBy} = this.props;

        return (
            <TableHead>
                <TableRow style={{height: 30, backgroundColor: "#E8E4E3"}}>
                    {rows.map(
                        row => (
                            <TableCell
                                key={row.id}
                                align={row.numeric ? 'right' : 'left'}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        <b> {row.label}</b>
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        ),
                        this,
                    )}
                </TableRow>
            </TableHead>
        );
    }
}

TableContentHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


const styles = theme => ({
    root: {
        width: '100%',
        border: '1'
        //marginTop: theme.spacing.unit * 2,
    },
    table: {
        minWidth: 1020,
    },
    tableCell: {
        padding: 3,
    },
    tableCells: {
        padding: 0,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    container: {
        overflow: 'auto',
        padding: '10px'
    },
    Tab: {
        flexDirection: "row-reverse"
    },
    left: {
        float: 'left',
        width: '200px'
    },

    right: {
        marginLeft: '210px'
    }
});

class Inbox extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'id',
        selected: [],
        data: [],
        getInboxCaseData: [],
        page: 0,
        rowsPerPage: 25,
        renderModal: false,
        appUid: '',
        inboxModal: false,
        serviceType: '',
        subServiceType: '',
        jointAccountCustomerNumber: '',
        taskTitle: '',
        redirectLogin: false,
        selectTab: "",
        err: false,
        errorArray: {},
        errorMessages: {},
        errorReturn: {},
        inputData: {},
        getData: false,
        activeItem: 'Inbox',
        backgroundColor: 'red',
        category: "",
        delStatus: "",
        getCustomerId: "",
        accountNumber: "",
        subCategory: "",
        loading: false,

    };

    renderContent = () => {
        /*  if (this.state.content === "NGO/SOCIETY ACCOUNT") {
              return "Company Account"
          } else if (this.state.content === "Joint CB") {
              return "Joint Account"
          }
          else if (this.state.content === 'Company Account' || this.state.content === 'Partnership A/C' || this.state.content === 'Limited Company A/C' || this.state.content === 'Others') {
              return "Company Account"
          }

          else {
              return this.state.content;
          }*/
        return "INDIVIDUAL"
    };

    componentDidMount() {


        const data = [];


        data.push(createData(1, "Md Biplob Hossain", "CB1401933", "1234567891011", "", "Account Opening", this.renderContent(), "GULSHAN,DHAKA", "agentmaker", "", "", "10-12-2019", "AGENT", "INDIVIDUAL"));
        data.push(createData(2, "Md Biplob Hossain", "CB1401933", "1234567891011", "", "Account Opening", this.renderContent(), "MOTHIJHEEL,DHAKA", "agentmaker", "", "", "10-12-2019", "AGENT", "Company Account"));


        this.setState({data: data, getInboxCaseData: data});


    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({order, orderBy});
    };

    handleSelectAllClick = event => {
        if (event.target.checked) {
            this.setState(state => ({selected: state.data.map(n => n.id)}));
            return;
        }
        this.setState({selected: []});
    };

    handleClick = (event, id) => {
        const {selected} = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({selected: newSelected});
    };

    handleChangePage = (event, page) => {
        this.setState({page});
    };

    handleChangeRowsPerPage = event => {
        this.setState({rowsPerPage: event.target.value});
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    viewCase = (appUid, serviceType, subServiceType, taskTitle, jointAccountCustomerNumber, category, subCategory, delStatus, cbNumber, accountNumber) => {

        this.setState({
            getCustomerId: cbNumber,
            accountNumber: accountNumber,
            serviceType: serviceType,
            subServiceType: subServiceType,
            inboxModal: true,
            category: category,
            subCategory: subCategory,
            appUid: appUid,
            delStatus: delStatus,
            taskTitle: taskTitle,
            jointAccountCustomerNumber: jointAccountCustomerNumber
        })

    };
    closeModal = () => {
        this.setState({
            inboxModal: false,
            loading: true
        });
        let urls = "";

        if (this.state.activeItem === "Inbox") {
            const data = [];


            data.push(createData(1, "Md Biplob Hossain", "59841433", "1234567891011", "", "INDIVIDUAL", "INDIVIDUAL", "GULSHAN,DHAKA", "agentmaker", "", "", "10-12-2019", "AGENT", "INDIVIDUAL"));
            data.push(createData(2, "Md Biplob Hossain", "59841433", "1234567891011", "", "Company Account", "Company Account", "MOTHIJHEEL,DHAKA", "agentmaker", "", "", "10-12-2019", "AGENT", "Company Account"));


            this.setState({data: data, getInboxCaseData: data});
        } else if (this.state.activeItem === "Return") {
            urls = backEndServerURL + '/inbox/return';
        } else if (this.state.activeItem === "Save") {
            urls = backEndServerURL + '/inbox/saved';
        } else if (this.state.activeItem === "Submitted") {
            urls = backEndServerURL + '/perticipated/0';
        } else if (this.state.activeItem === "Reports") {
            urls = backEndServerURL + '/inbox/closed/ENROLLMENT';
        }

        let data = [];
        axios.get(urls, {
            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
        })
            .then(response => {
                console.log(response.data);


                response.data.map((message, index) => {

                    data.push(createData(index, message.customerName, message.cbNumber, message.accountNumber, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserRole, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId));

                });

                this.setState({
                    data: data,
                    loading: false,
                    getInboxCaseData: data
                });


            })

            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false
                })

            });


    };
    renderInboxCase = () => {
        if (this.state.subServiceType === "INDIVIDUAL") {

            return (
                <CSInboxCase agentBanking={true} category={this.state.category} subCategory={this.state.subCategory}
                             getCustomerId={this.state.getCustomerId} agentBanking={true}
                             serviceType="ExistAccountOpening" accountNumber={this.state.accountNumber}
                             subServiceType={this.state.subServiceType} closeModal={this.closeModal}
                             commonJsonForm={AbhMakerJsonFormForAgentIndividual}
                />
            )
        } else if (this.state.subServiceType === "Company Account") {
            return (
                <CSInboxCase agentBanking={true} category={this.state.category} subCategory={this.state.subCategory}
                             agentBanking={true} getCustomerId={this.state.getCustomerId}
                             accountNumber={this.state.accountNumber} serviceType="ExistAccountOpening"
                             subServiceType={this.state.subServiceType} closeModal={this.closeModal}
                             commonJsonForm={AbhMakerJsonFormForAgentNonIndividual}
                />
            )
        }
    };
    updateComponent = () => {
        this.forceUpdate(


        )
    };
    renderFilterForm = () => {

        return (
            CommonJsonFormComponent.renderJsonForm(this.state, filteringJsonForm, this.updateComponent)
        )


    };


    getFilterSubmited = (event) => {
        event.preventDefault();
        counter = 0;
        let objectTable = {};
        let tableArray = [];
        console.log(this.state.inputData);
        for (let variable in this.state.inputData) {
            let trimData = this.state.inputData[variable].trim();
            if (trimData !== '')
                objectTable[variable] = trimData;
        }
        console.log(objectTable);
        this.state.getInboxCaseData.map((inboxCase, index) => {
            let showable = true;
            for (let variable in objectTable) {
                if (objectTable[variable] !== inboxCase[variable])
                    showable = false;
            }

            if (showable)
                //let date = inboxCase.delInitTime.split("T")[0];
                //tableArray.push(createData(index, inboxCase.customer_name, inboxCase.cb_number, inboxCase.account_number, inboxCase.appUid, inboxCase.service_type, inboxCase.subservice_type, inboxCase.branch_id, inboxCase.taskTitle, inboxCase.jointAccountCustomerNumber, inboxCase.delInitTime, inboxCase.category, inboxCase.subCategory));
                tableArray.push(createData(index, inboxCase.customerName, inboxCase.cb_number, inboxCase.account_number, inboxCase.appUid, inboxCase.service_type, inboxCase.subservice_type, inboxCase.branch_id, inboxCase.currentUserRole, inboxCase.taskTitle, inboxCase.jointAccountCustomerNumber, inboxCase.delInitTime, inboxCase.category, inboxCase.subCategory, inboxCase.delStatus, inboxCase.caseId));

        });
        this.setState({
            data: tableArray
        })
    };
    handleItemClick = (e, {name, style}) => {
        this.setState({
            data: [],
            loading: true,
            getInboxCaseData: []
        });
        this.setState({
                activeItem: name,
                backgroundColor: style
            }
        );
        let urls = "";
        if (name === "Inbox") {
            urls = backEndServerURL + '/inbox/waiting';
        } else if (name === "Return") {
            urls = backEndServerURL + '/inbox/return';
        } else if (name === "Save") {
            urls = backEndServerURL + '/inbox/saved';
        } else if (name === "Submitted") {
            urls = backEndServerURL + '/perticipated/0';
        } else if (name === "Reports") {
            urls = backEndServerURL + '/inbox/closed/ENROLLMENT';
        }
        let data = [];
        axios.get(urls, {
            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
        })
            .then(response => {
                console.log(response.data);


                response.data.map((message, index) => {

                    data.push(createData(index, message.customerName, message.cbNumber, message.accountNumber, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserRole, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId));

                });

                this.setState({
                    data: data,
                    loading: false,
                    getInboxCaseData: data
                });


            })

            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false
                })

            });


    };


    handleChange = (name, value) => {
        this.setState({
            selectTab: value
        })
    };
    renderTab = (classes) => {
        if ((LocalstorageEncrypt.encryptStorageFunction.getItem("roles").indexOf('CS') !== -1) || (LocalstorageEncrypt.encryptStorageFunction.getItem("roles").indexOf('DSTCS') !== -1) || (LocalstorageEncrypt.encryptStorageFunction.getItem("roles").indexOf('ABHMAKER') !== -1)) {
            return (
                <div style={{marginRight: "8px"}}>
                    <Menu pointing>

                        <Menu.Item
                            name='Inbox'
                            active={this.state.activeItem === 'Inbox'}
                            style={this.state.activeItem === 'Inbox' ? {
                                backgroundColor: "red",
                                color: "white",

                            } : {backgroundColor: "white", color: "black"}}
                            onClick={this.handleItemClick}
                        />
                        <Menu.Item
                            name='Return'
                            active={this.state.activeItem === 'Return'}
                            style={this.state.activeItem === 'Return' ? {
                                backgroundColor: "red",
                                color: "white"
                            } : {backgroundColor: "white", color: "black"}}
                            onClick={this.handleItemClick}
                        />
                        <Menu.Item
                            name='Save'
                            active={this.state.activeItem === 'Save'}
                            style={this.state.activeItem === 'Save' ? {
                                backgroundColor: "red",
                                color: "white"
                            } : {backgroundColor: "white", color: "black"}}
                            onClick={this.handleItemClick}
                        />
                        <Menu.Item
                            name='Submitted'
                            active={this.state.activeItem === 'Submitted'}
                            style={this.state.activeItem === 'Submitted' ? {
                                backgroundColor: "red",
                                color: "white"
                            } : {backgroundColor: "white", color: "black"}}
                            onClick={this.handleItemClick}
                        />
                        <Menu.Item
                            name='Reports'
                            active={this.state.activeItem === 'Reports'}
                            style={this.state.activeItem === 'Reports' ? {
                                backgroundColor: "red",
                                color: "white"
                            } : {backgroundColor: "white", color: "black"}}
                            onClick={this.handleItemClick}
                        />
                    </Menu>


                </div>
            );

        } else {
            return (
                <div style={{marginRight: "8px"}}>
                    <Menu pointing>

                        <Menu.Item
                            name='Inbox'
                            active={this.state.activeItem === 'Inbox'}
                            style={this.state.activeItem === 'Inbox' ? {
                                backgroundColor: "red",
                                color: "white",

                            } : {backgroundColor: "white", color: "black"}}
                            onClick={this.handleItemClick}
                        />
                        <Menu.Item
                            name='Return'
                            active={this.state.activeItem === 'Return'}
                            style={this.state.activeItem === 'Return' ? {
                                backgroundColor: "red",
                                color: "white"
                            } : {backgroundColor: "white", color: "black"}}
                            onClick={this.handleItemClick}
                        />

                        <Menu.Item
                            name='Submitted'
                            active={this.state.activeItem === 'Submitted'}
                            style={this.state.activeItem === 'Submitted' ? {
                                backgroundColor: "red",
                                color: "white"
                            } : {backgroundColor: "white", color: "black"}}
                            onClick={this.handleItemClick}
                        />

                    </Menu>


                </div>
            );

        }

    };

    render() {
        const {classes} = this.props;
        const {data, order, orderBy, selected, rowsPerPage, page} = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
        {

            Functions.redirectToLogin(this.state)

        }
        if (this.state.activeItem === "Submitted") {
            return (
                <section>
                    <br/>

                    <Paper className={classes.root}>

                        <GridContainer>

                            <GridItem xs={12} sm={12} md={12}>

                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h5 className={classes.cardTitleWhite}>Search</h5>


                                </CardHeader>

                                <CardBody>
                                    <Grid container spacing={1}>
                                        <ThemeProvider theme={theme}>

                                            {this.renderFilterForm()}

                                        </ThemeProvider>


                                    </Grid>
                                    <br/>


                                    <button
                                        className="btn btn-outline-primary"
                                        style={{
                                            verticalAlign: 'middle',
                                        }}
                                        onClick={this.getFilterSubmited}

                                    >
                                        Search
                                    </button>


                                </CardBody>

                            </GridItem>
                        </GridContainer>

                    </Paper>

                    <br/>
                    <br/>
                    <Paper className={classes.root}>
                        <GridContainer>

                            <GridItem xs={12} sm={12} md={12}>

                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h5 className={classes.cardTitleWhite}>My Inbox</h5>

                                </CardHeader>
                                <br/>
                                {this.renderTab(classes.Tab)}
                                <div className={classes.tableWrapper}>
                                    <Table className={classes.table} aria-labelledby="tableTitle">
                                        <TableContentHead
                                            sty
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={this.handleSelectAllClick}
                                            onRequestSort={this.handleRequestSort}
                                            rowCount={data.length}
                                        />
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="xl"
                                            fullScreen={true}
                                            open={this.state.inboxModal}>
                                            <DialogContent>

                                                {this.renderInboxCase()}
                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.loading}>
                                            <DialogContent className={classes.dialogPaper}>

                                                <center>
                                                    <img src={loader} alt=""/>
                                                </center>
                                            </DialogContent>
                                        </Dialog>
                                        <TableBody>
                                            {stableSort(data, getSorting(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map(n => {
                                                    const isSelected = this.isSelected(n.id);
                                                    return (
                                                        <TableRow
                                                            hover
                                                            /* onClick={event => this.handleClick(event, n.id)}*/
                                                            role="checkbox"
                                                            aria-checked={isSelected}
                                                            tabIndex={-1}
                                                            key={n.id}
                                                            //onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber)}
                                                            selected={isSelected}
                                                        >

                                                            {/*<TableCell className={classes.tableCells} align="left">*/}

                                                            {/*</TableCell>*/}
                                                            <TableCell className={classes.tableCell} align="left">
                                                                {n.id}
                                                            </TableCell>
                                                            <TableCell className={classes.tableCell} align="left">
                                                                {n.caseId}
                                                            </TableCell>
                                                            <TableCell className={classes.tableCell} align="left">
                                                                {n.customer_name}
                                                            </TableCell>

                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.cb_number}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.account_number}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.category}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.subCategory}</TableCell>

                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.delInitTime}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.currentUserRole}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.branch_id}</TableCell>
                                                            <TableCell className={classes.tableCell} align="left">

                                                                {/* <ThemeProvider theme={theme}>
                                                                <Button
                                                                    onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber)}
                                                                    variant="contained" color="secondary"
                                                                    className={classes.margin}>
                                                                    View
                                                                </Button>
                                                            </ThemeProvider>*/}
                                                            </TableCell>


                                                        </TableRow>
                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{height: 49 * emptyRows}}>
                                                    <TableCell colSpan={6}/>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>

                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />

                            </GridItem>
                        </GridContainer>
                    </Paper>
                </section>
            );
        } else {
            return (
                <section>
                    <br/>

                    <Paper className={classes.root}>

                        <GridContainer>

                            <GridItem xs={12} sm={12} md={12}>

                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h5 className={classes.cardTitleWhite}>Search</h5>


                                </CardHeader>

                                <CardBody>
                                    <Grid container spacing={1}>
                                        <ThemeProvider theme={theme}>

                                            {this.renderFilterForm()}

                                        </ThemeProvider>


                                    </Grid>
                                    <br/>


                                    <button
                                        className="btn btn-outline-primary"
                                        style={{
                                            verticalAlign: 'middle',
                                        }}
                                        onClick={this.getFilterSubmited}

                                    >
                                        Search
                                    </button>


                                </CardBody>

                            </GridItem>
                        </GridContainer>

                    </Paper>

                    <br/>
                    <br/>
                    <Paper className={classes.root}>
                        <GridContainer>

                            <GridItem xs={12} sm={12} md={12}>

                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h5 className={classes.cardTitleWhite}>My Inbox</h5>

                                </CardHeader>
                                <br/>
                                {this.renderTab(classes.Tab)}
                                <div className={classes.tableWrapper}>
                                    <Table className={classes.table} aria-labelledby="tableTitle">
                                        <TableContentHead
                                            sty
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={this.handleSelectAllClick}
                                            onRequestSort={this.handleRequestSort}
                                            rowCount={data.length}
                                        />
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="xl"
                                            fullScreen={true}
                                            open={this.state.inboxModal}>
                                            <DialogContent>

                                                {this.renderInboxCase()}
                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.loading}>
                                            <DialogContent className={classes.dialogPaper}>

                                                <center>
                                                    <img src={loader} alt=""/>
                                                </center>
                                            </DialogContent>
                                        </Dialog>

                                        <TableBody>
                                            {stableSort(data, getSorting(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map(n => {
                                                    const isSelected = this.isSelected(n.id);
                                                    return (
                                                        <TableRow
                                                            hover
                                                            /* onClick={event => this.handleClick(event, n.id)}*/
                                                            role="checkbox"
                                                            aria-checked={isSelected}
                                                            tabIndex={-1}
                                                            key={n.id}
                                                            onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.cb_number, n.account_number)}
                                                            selected={isSelected}
                                                        >
                                                            {/*  <TableCell className={classes.tableCells} align="left">

                                                            </TableCell>*/}
                                                            <TableCell className={classes.tableCell} align="left">
                                                                {n.id}
                                                            </TableCell>
                                                            <TableCell className={classes.tableCell} align="left">
                                                                {n.caseId}
                                                            </TableCell>

                                                            <TableCell className={classes.tableCell} align="left">
                                                                {n.customer_name}
                                                            </TableCell>

                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.cb_number}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.account_number}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.category}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.subCategory}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.delInitTime}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.currentUserRole}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.branch_id}</TableCell>
                                                            <TableCell className={classes.tableCell} align="left">

                                                                {/* <ThemeProvider theme={theme}>
                                                                <Button
                                                                    onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber)}
                                                                    variant="contained" color="secondary"
                                                                    className={classes.margin}>
                                                                    View
                                                                </Button>
                                                            </ThemeProvider>*/}
                                                            </TableCell>


                                                        </TableRow>
                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{height: 49 * emptyRows}}>
                                                    <TableCell colSpan={6}/>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>

                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />

                            </GridItem>
                        </GridContainer>
                    </Paper>
                </section>
            );
        }

    }
}

Inbox.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Inbox);
