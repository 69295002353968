import React, {Component} from "react";
import GridContainer from "../../../Grid/GridContainer";
import GridItem from "../../../Grid/GridItem";
import CardBody from "../../../Card/CardBody";
import withStyles from "@material-ui/core/styles/withStyles";
import CloseIcon from "@material-ui/icons/Close";
import CardHeader from "../../../Card/CardHeader";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import CircularProgress from "@material-ui/core/CircularProgress";


const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

    button: {
        margin: theme.spacing(1),
    }
});

const checkPoint = [
    {
        varName: "fdValueDateCheck",
        label: "Please attach all FD Open and maintenance related scan copies",
        type: "checkbox",
        // readOnly: true,
        grid: 12,
        conditional: true,
        conditionalVarName: "showFdValueChecker",
        conditionalVarValue: true
    },
    {
        varName: "motherBranchCheck",
        label: "Mother branch approval required",
        type: "checkbox",
        // readOnly: true,
        grid: 12,
        conditional: true,
        conditionalVarName: "showSolChecker",
        conditionalVarValue: true
    },
    {
        varName: "accountClosedCheck",
        label: "Your account is closed",
        type: "checkbox",
        // readOnly: true,
        grid: 12,
        conditional: true,
        conditionalVarName: "showAccountClose",
        conditionalVarValue: true
    },
    {
        varName: "deferralCheck",
        label: "Deferral",
        type: "checkbox",
        // readOnly: true,
        grid: 12,
        conditional: true,
        conditionalVarName: "showDeferral",
        conditionalVarValue: true
    },
];

const checkPointReadOnly = [
    {
        varName: "fdValueDateCheck",
        label: "Please attach all FD Open and maintenance related scan copies",
        type: "checkbox",
        readOnly: true,
        grid: 12,
        conditional: true,
        conditionalVarName: "showFdValueChecker",
        conditionalVarValue: true
    },
    {
        varName: "motherBranchCheck",
        label: "Mother branch approval required",
        type: "checkbox",
        readOnly: true,
        grid: 12,
        conditional: true,
        conditionalVarName: "showSolChecker",
        conditionalVarValue: true
    },
    {
        varName: "accountClosedCheck",
        label: "Your account is closed",
        type: "checkbox",
        readOnly: true,
        grid: 12,
        conditional: true,
        conditionalVarName: "showAccountClose",
        conditionalVarValue: true
    },
    {
        varName: "deferralCheck",
        label: "Deferral",
        type: "checkbox",
        readOnly: true,
        grid: 12,
        conditional: true,
        conditionalVarName: "showDeferral",
        conditionalVarValue: true
    },
];

class CheckList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            loader: true
        }
    }

    componentDidMount() {
        if (this.props.inputData !== undefined) {
            console.log(this.props.inputData.showFdValueChecker);
            console.log(this.props.inputData.showSolChecker);
            console.log(this.props.inputData.showDeferral);
            this.setState({
                inputData: this.props.inputData,
                varValue: this.props.inputData,
                showValue: true,
                loader: false
            })
        } else {
            this.setState({
                loader: false
            })
        }
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    onKeyDownForDedup = (event, data) => {

    };

    renderButton = () => {
        if (this.props.handleSubmit !== undefined) {
            return (
                <center>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginTop: 20
                        }}
                        onClick={() => this.props.handleSubmit(this.state.inputData)}
                    >OK
                    </button>
                    &nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginTop: 20
                        }}
                        onClick={() => this.props.closeModal()}
                    >Cancel
                    </button>
                </center>
            )
        }
    };

    renderForm = () => {
        if (this.props.handleSubmit !== undefined) {
            return CommonJsonFormComponent.renderJsonForm(this.state, checkPoint, this.updateComponent, this.onKeyDownForDedup)
        } else {
            return CommonJsonFormComponent.renderJsonForm(this.state, checkPointReadOnly, this.updateComponent, this.onKeyDownForDedup)
        }
    };

    render() {
        if (this.state.loader) {
            return <CircularProgress style={{marginLeft: '50%'}}/>
        } else {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Check List<a><CloseIcon
                                onClick={() => this.props.closeModal()} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></h4>
                        </CardHeader>
                        <CardBody>
                            <Grid container spacing={0}>
                                <ThemeProvider theme={theme}>
                                    {this.renderForm()}
                                    {this.renderButton()}
                                </ThemeProvider>
                            </Grid>
                        </CardBody>
                    </GridItem>
                </GridContainer>
            )
        }
    }

}

export default withStyles(styles)(CheckList);