import React from "react";
import FormSample from '../JsonForm/FormSample';
import {backEndServerURL} from '../../Common/Constant';
import axios from 'axios';
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import withStyles from "@material-ui/core/styles/withStyles";
import Notification from "../NotificationMessage/Notification";

import Functions from '../../Common/Functions';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};
const jsonForm = {
    "variables": [
        {
            "varName": "dropdownStateName",
            "type": "dropdownSearch",
            "label": "State Name",
        },
        {
            "varName": "cityName",
            "type": "text",
            "label": "City Name",
            "required": true,
            "number": false,
        },

        {
            "varName": "status",
            "type": "select",
            "label": "Status",
            "required": true,
            "enum": [
                "ACTIVE",
                "INACTIVE"
            ]
        },

    ],

};

class CityAddEditDelete extends React.Component {
    renderEditForm = (() => {
        if (this.state.getCityData) {

            return (

                <FormSample close={this.props.close} showValue={true} varValue={this.state.varValue} grid="12"
                            buttonName="Submit" dropdownSearchData={this.state.dropdownSearchData}
                            onSubmit={this.getSubmitedForm} jsonForm={jsonForm}/>
            )
        } else if (this.props.id === undefined) {
            return (
                <FormSample grid="12" buttonName="Submit" dropdownSearchData={this.state.dropdownSearchData}
                            jsonForm={jsonForm} close={this.props.close} onSubmit={this.getSubmitedForm}/>

            )
        } else {

        }

    });

    constructor(props) {
        super(props);
        this.state = {
            varValue: [],
            dropdownSearchData: [],
            getCityData: false,
            getStateData: false,
            title: "",
            notificationMessage: "",

            alert: false,
            redirectLogin: false,

        }
    }

    firstLetterUpperCase = (string) => {
        var str = string.trim();
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }

    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    getSubmitedForm = (object) => {

        if (this.props.id !== undefined) {
            let url = backEndServerURL + "/cityMaster/update/" + this.props.id;
            axios.post(url, {
                "stateId": object.dropdownStateName,
                "cityName": this.firstLetterUpperCase(object.cityName),
                "status": object.status,
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    this.props.close();
                    this.setState({
                        title: "Sucessfully!!",
                        notificationMessage: "Sucessfully Updated",
                        alert: true
                    });
                    console.log("Successfully Edited");

                })
                .catch((error) => {
                    console.log(error);

                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                })
        } else {
            let url = backEndServerURL + "/cityMaster/add";

            axios.post(url,
                {

                    stateId: object.dropdownStateName,
                    cityName: this.firstLetterUpperCase(object.cityName),

                    status: object.status,

                }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.props.close();
                    this.setState({
                        title: "Sucessfully!!",
                        notificationMessage: "Sucessfully Added",
                        alert: true
                    });
                    console.log("Successfully Added");
                })
                .catch((error) => {
                    console.log(error);

                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                })

        }
    };

    componentDidMount() {
        const getState = [];
        let dropdownData = [];
        let url = backEndServerURL + "/stateMaster/getAll";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let useData = [];
                response.data.map((state) => {
                    getState.push(state.id, state.stateName);

                    let object = {
                        "label": state.stateName,
                        "value": state.id
                    };

                    useData.push(object);

                });
                dropdownData["dropdownStateName"] = useData;
                this.setState({
                    dropdownSearchData: dropdownData,
                    getStateData: true

                })

            })
            .catch((error) => {
                console.log(error);

                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
            });

        if (this.props.id !== undefined) {
            let url = backEndServerURL + "/cityMaster/get/" + this.props.id;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let varValue = [];
                    varValue["cityName"] = response.data.cityName;
                    varValue["status"] = response.data.status;
                    this.setState({
                        varValue: varValue,
                        getCityData: true

                    })
                })
                .catch((error) => {
                    console.log(error);

                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                })
        }

    }

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (

            <GridContainer>
                {this.renderNotification()}
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>{this.props.name}</h4>
                        </CardHeader>
                        <CardBody>
                            <div>
                                {this.renderEditForm()}
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }

}

export default withStyles(styles)(CityAddEditDelete);