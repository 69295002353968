import React, {Component} from 'react';
import axios from 'axios';
import {Dialog, Grow} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import DialogContent from "@material-ui/core/DialogContent";
import Table from "../../Table/Table";
import {backEndServerURL} from "../../../Common/Constant";

import GridContainer from "../../Grid/GridContainer.jsx";
import GridItem from "../../Grid/GridItem.jsx";
import AccountEdit from './AccountEdit';

import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import 'antd/dist/antd.css';
import CloseIcon from '@material-ui/icons/Close';
import OpeningCS from "./OpeningCS";
import {
    CSJsonFormForCasaIndividualTagFdr,
    CSJsonFormForFDRService,
    CSJsonFormForFDRServiceForExistCbOnly,
    CSJsonFormForFDRServiceLast,
    CSJsonFormForNewWithExistCB
} from "../WorkflowJsonFormArin";
import OpeningCSLocker from "../LOCKER/OpeningCSLocker";
import loader from "../../../Static/loader.gif";
import ErrorModal from "../CommonComponent/ErrorModal";
import TaggingCBSelectComponent from "./TaggingCBSelectComponent";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'
    },
    dialogPaper: {
        // overflow: "visible"
    }
};

function f(state, fn) {
    console.log(state.appData);
    state.err = true;
    fn();
}

function Transition(props) {

    return <Grow in={true} timeout="auto" {...props} />;
}

class AccountList extends Component {
    state = {
        appData: "",
        err: false,
        inputData: {},
        customerType: ""
    };

    constructor(props) {
        super(props);
        this.state = {
            addRole: false,
            roleView: false,
            addUser: false,
            userView: false,
            getsearchValue: [],
            newAcoountOpeningModal: false,
            tableData: [[" ", " "]],
            roleId: '',
            assignMenuToRole: '',
            viewMenu: '',
            notificationFlag: false,
            alert: false,
            redirectLogin: false,
            loading: false,
            editAccount: false,
            taggingCBSelect: false,
            TaggingCBSelectComponent: false,
            lienStatus: "",
            freezeStatus: "",
            accountStatusData: "",
            accountRestriction: false,
            errorModalBoolean: false,
            errorTittle: "",
            withKyc: false,
            withoutKyc: false,
            conditionalNewButton: false,
            fdrNewFlowButton: false,
            customerType: "",

        };
    }

    handleChangeRestriction = (event, data, varName) => {
        event.preventDefault();

    };
    editAccount = (event, cb, accountNo, lienStatus, freezeStatus, acctStatus, accountStatus, lienRemarks) => {
        event.preventDefault();
        if (freezeStatus === "YES" || lienStatus === "YES" || acctStatus !== "A" || accountStatus !== "Open") {
            let message = "";

            if (freezeStatus === "YES") {
                message = "Freeze Exists "
            }
            if (lienStatus === "YES") {
                message = message + ", Lien Exists "
            }
            if (accountStatus !== "Open") {
                message = message + ", Account is Closed "
            }
            if (acctStatus !== "A") {
                message = message + ",Account is not Active"
            }
            if (message === ", Lien Exists ") {
                this.setState({
                    customerId: cb,
                     editAccount: true,
                    accountNo: accountNo,
                    accountRestriction: true,
                    errorTittle: "Lien Exists",
                })
            } else {
                this.setState({
                    accountRestriction: true,
                    errorTittle: message,
                    accountNo: accountNo,
                    customerId: cb,
                 })
            }


        } else {
            this.setState({
                customerId: cb,
                 editAccount: true,
                accountNo: accountNo
            })
        }

    };
    editTaggingCBSelect = (event, customerId, accountNo, lienStatus, freezeStatus, acctStatus, accountStatus, lienRemarks) => {
        event.preventDefault();
      /*  let s1 = (accountNo).toString();
        let s2 = s1.substr(3);
        let cbData = s2.substring(0, s2.length - 3);
        let accountTocbNumber = "CB" + cbData;*/

        if (freezeStatus === "YES" || lienStatus === "YES" || acctStatus !== "A" || accountStatus !== "Open") {
            let message = "";

            if (freezeStatus === "YES") {
                message = "Freeze Exists "
            }
            if (lienStatus === "YES") {
                message = message + ", Lien Exists "
            }
            if (accountStatus !== "Open") {
                message = message + ", Account is Closed "
            }
            if (acctStatus !== "A") {
                message = message + ",Account is not Active"
            }
            if (message === ", Lien Exists ") {
                this.setState({
                    customerId: customerId,
                    taggingCBSelect: true,
                    accountNo: accountNo,
                    accountRestriction: true,
                    errorTittle: "Lien Exists",
                })
            } else {
                this.setState({
                    accountRestriction: true,
                    errorTittle: message,
                    accountNo: accountNo,
                    customerId: customerId,
                 })
            }


        } else {
            this.setState({
                customerId: customerId,
                taggingCBSelect: true,
                accountNo: accountNo
            })
        }

    };
    ConditionalNewButton=(event)=>{
        event.preventDefault();
        this.setState({
            customerId: this.props.customerId,
            editAccount: true,
            conditionalNewButton:true
          })
    }
    accountEditModal = () => {
        if (this.props.serviceType === 'FDR Opening') {
            return (
                <AccountEdit
                    getmatchBy={this.props.getmatchBy}
                    fdrNewFlowButton={this.state.fdrNewFlowButton}
                    conditionalNewButton={this.state.conditionalNewButton}
                    customerType={this.state.customerType}
                    data={this.state.tableData}
                    getAccountType={this.props.getAccountType}
                    freeFlag3={this.props.freeFlag3}
                    accountNo={this.state.accountNo}
                    searchValue={this.props.searchValue}
                    dstFlag={this.props.dstFlag}
                    agentBankingFlag={this.props.agentBankingFlag}
                     closeModal={this.closeModal}
                    closeModalOnlySubModal={this.closeModalOnlySubModal}
                    businessSegment={this.props.businessSegment}
                    serviceType='FDR Opening'
                    customerId={this.state.customerId}
                    subServiceType={this.props.subServiceType}
                    AccountType="FDR Account Opening - with Existing Operative Account"
                    freeFlag1="FDR Account Opening - with Existing Operative Account"
                    title="FDR Account Opening - CS View (with Existing Operative Account)"
                    serviceList={CSJsonFormForFDRService}
                    serviceListLast={CSJsonFormForFDRServiceLast}
                    commonJsonForm={CSJsonFormForCasaIndividualTagFdr}
                    serviceJsonForm={this.props.serviceList}
                    secondButtonName="close"
                    type={this.props.type}
                    jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                    individualDedupData={this.props.individualDedupData}
                    mandateindividualDedupData={this.props.mandateindividualDedupData}
                    beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}

                    jointDedupData={this.props.jointDedupData}
                    companyDedupData={this.props.companyDedupData}
                    name="Edit Account"/>
            )

        }
        if (this.props.serviceType === 'Locker Opening') {
            return (
                <OpeningCSLocker
                    data={this.state.tableData}
                    getAccountType={this.props.getAccountType}
                    accountNo={this.state.accountNo}
                    searchValue={this.props.searchValue}
                    closeModal={this.closeModal} businessSegment={this.props.businessSegment}
                    serviceType={this.props.serviceType}
                    subServiceType={this.props.subServiceType}
                    title="Locker Opening"
                    dstFlag={this.props.dstFlag}
                    agentBankingFlag={this.props.agentBankingFlag}
                    commonJsonForm={this.props.lockerOpening}
                    serviceJsonForm={this.props.serviceJsonForm}
                    secondButtonName="close"
                    type={this.props.type}
                    jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                    individualDedupData={this.props.individualDedupData}
                    mandateindividualDedupData={this.props.mandateindividualDedupData}
                    beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}

                    jointDedupData={this.props.jointDedupData}
                    companyDedupData={this.props.companyDedupData}
                    name="Edit Account"/>
            )
        }
        if (this.props.serviceType === 'DPS Opening') {
            return (
                <AccountEdit
                    getmatchBy={this.props.getmatchBy}
                    fdrNewFlowButton={this.state.fdrNewFlowButton}
                    conditionalNewButton={this.state.conditionalNewButton}
                    customerType={this.state.customerType}
                    data={this.state.tableData}
                    getAccountType={this.props.getAccountType}
                    accountNo={this.state.accountNo}
                    searchValue={this.props.searchValue}
                    freeFlag3={this.props.freeFlag3}
                    closeModal={this.closeModal}
                    dstFlag={this.props.dstFlag}
                    agentBankingFlag={this.props.agentBankingFlag}
                    closeModalOnlySubModal={this.closeModalOnlySubModal} businessSegment={this.props.businessSegment}
                    customerId={this.state.customerId}
                    title="DPS Account Opening -with Existing Operative Account"
                    serviceType='DPS Opening'
                    subServiceType={this.props.subServiceType}
                    AccountType="DPS Account Opening -with Existing Operative Account"
                    freeFlag1="DPS Account Opening -with Existing Operative Account"
                    commonJsonForm={this.props.commonJsonForm}
                    serviceList={this.props.serviceList}
                    serviceListLast={this.props.serviceListLast}
                    serviceJsonForm={this.props.serviceJsonForm}
                    jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                    individualDedupData={this.props.individualDedupData}
                    mandateindividualDedupData={this.props.mandateindividualDedupData}
                    beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}

                    jointDedupData={this.props.jointDedupData}
                    companyDedupData={this.props.companyDedupData}
                    secondButtonName="close"
                    type={this.props.type}
                    name="Edit Account"/>
            )
        }
    };
    accounttaggingCBSelect = () => {
        if (this.props.serviceType === 'FDR Opening') {
            return (
                <TaggingCBSelectComponent
                    getmatchBy={this.props.getmatchBy}
                    fdrNewFlowButton={this.state.fdrNewFlowButton}
                    conditionalNewButton={this.state.conditionalNewButton}
                    customerType={this.state.customerType}
                    data={this.state.tableData}
                    getAccountType={this.props.getAccountType}
                    freeFlag3={this.props.freeFlag3}
                    accountNo={this.state.accountNo}
                    searchValue={this.props.searchValue}
                    dstFlag={this.props.dstFlag}
                    agentBankingFlag={this.props.agentBankingFlag}
                     closeModal={this.closeModal}
                    closeModalOnlySubModal={this.closeModalOnlySubModal}
                    businessSegment={this.props.businessSegment}
                    serviceType='FDR Opening'
                    customerId={this.state.customerId}
                    subServiceType={this.props.subServiceType}
                    AccountType="FDR Account Opening - with Existing Operative Account"
                    freeFlag1="FDR Account Opening - with Existing Operative Account"
                    title="FDR Account Opening - CS View (with Existing Operative Account)"
                    serviceList={CSJsonFormForFDRService}
                    serviceListLast={CSJsonFormForFDRServiceLast}
                    commonJsonForm={CSJsonFormForCasaIndividualTagFdr}
                    serviceJsonForm={this.props.serviceList}
                    secondButtonName="close"
                    type={this.props.type}
                    jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                    individualDedupData={this.props.individualDedupData}
                    mandateindividualDedupData={this.props.mandateindividualDedupData}
                    beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}

                    jointDedupData={this.props.jointDedupData}
                    companyDedupData={this.props.companyDedupData}
                    name="Edit Account"/>
            )

        }
        if (this.props.serviceType === 'Locker Opening') {
            return (
                <OpeningCSLocker
                    data={this.state.tableData}
                    getAccountType={this.props.getAccountType}
                    accountNo={this.state.accountNo}
                    searchValue={this.props.searchValue}
                    closeModal={this.closeModal} businessSegment={this.props.businessSegment}
                    serviceType={this.props.serviceType}
                    subServiceType={this.props.subServiceType}
                    title="Locker Opening"
                    dstFlag={this.props.dstFlag}
                    agentBankingFlag={this.props.agentBankingFlag}
                    commonJsonForm={this.props.lockerOpening}
                    serviceJsonForm={this.props.serviceJsonForm}
                    secondButtonName="close"
                    type={this.props.type}
                    jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                    individualDedupData={this.props.individualDedupData}
                    mandateindividualDedupData={this.props.mandateindividualDedupData}
                    beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}

                    jointDedupData={this.props.jointDedupData}
                    companyDedupData={this.props.companyDedupData}
                    name="Edit Account"/>
            )
        }
        if (this.props.serviceType === 'DPS Opening') {
            return (
                <TaggingCBSelectComponent
                    getmatchBy={this.props.getmatchBy}
                    fdrNewFlowButton={this.state.fdrNewFlowButton}
                    conditionalNewButton={this.state.conditionalNewButton}
                    customerType={this.state.customerType}
                    data={this.state.tableData}
                    getAccountType={this.props.getAccountType}
                    accountNo={this.state.accountNo}
                    searchValue={this.props.searchValue}
                    freeFlag3={this.props.freeFlag3}
                    closeModal={this.closeModal}
                    dstFlag={this.props.dstFlag}
                    agentBankingFlag={this.props.agentBankingFlag}
                    closeModalOnlySubModal={this.closeModalOnlySubModal} businessSegment={this.props.businessSegment}
                    customerId={this.state.customerId}
                    title="DPS Account Opening -with Existing Operative Account"
                    serviceType='DPS Opening'
                    subServiceType={this.props.subServiceType}
                    AccountType="DPS Account Opening -with Existing Operative Account"
                    freeFlag1="DPS Account Opening -with Existing Operative Account"
                    commonJsonForm={this.props.commonJsonForm}
                    serviceList={this.props.serviceList}
                    serviceListLast={this.props.serviceListLast}
                    serviceJsonForm={this.props.serviceJsonForm}
                    jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                    individualDedupData={this.props.individualDedupData}
                    mandateindividualDedupData={this.props.mandateindividualDedupData}
                    beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}

                    jointDedupData={this.props.jointDedupData}
                    companyDedupData={this.props.companyDedupData}
                    secondButtonName="close"
                    type={this.props.type}
                    name="Edit Account"/>
            )
        }
    };

    newAccoutntEditModal = () => {
        return (
            <OpeningCS
                closeModal={this.closeModal}
                freeFlag3={this.props.freeFlag3}
                closeModalOnlySubModal={this.closeModalOnlySubModal}
                businessSegment={this.props.businessSegment}
                getAccountType={this.state.getAccountType}
                cbonly={true}
                dstFlag={this.props.dstFlag}
                agentBankingFlag={this.props.agentBankingFlag}
                withKyc={this.state.withKyc}
                withoutKyc={this.state.withoutKyc}
                serviceList={CSJsonFormForFDRServiceForExistCbOnly}
                serviceListLast={CSJsonFormForFDRServiceLast}
                commonJsonForm={CSJsonFormForNewWithExistCB}
                accountType={this.state.tabMenuSelect}
                customerId={this.props.customerId}
                AccountType="FDR Account Opening - with Existing CB Only"
                freeFlag1="FDR Account Opening - with Existing CB Only"
                serviceType="FDR Opening"
                subServiceType={this.props.subServiceType}
                title="FDR Account Opening - with Existing CB Only"
                category="FDR Existing"
                subCategory="FDR ExistingNew"
                searchValue={this.props.searchValue}
                jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                individualDedupData={this.props.individualDedupData}
                mandateindividualDedupData={this.props.mandateindividualDedupData}
                beneficiaryindividualDedupData={this.props.beneficiaryindividualDedupData}

                jointDedupData={this.props.jointDedupData}
                companyDedupData={this.props.companyDedupData}
            />
        )


    };
    returnNewAccountCreation = () => {
        if (this.props.serviceType === 'FDR Opening') {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            position: "absolute",
                            right: 300,
                        }}
                        onClick={() => this.renderNewAccountOpeingForm("withoutKyc")}>
                        FD Opening (Using different CASA)
                    </button>

                    &nbsp;&nbsp;


                  {/*  <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            position: "absolute",
                            right: 10,
                        }}
                        onClick={() => this.renderNewAccountOpeingForm("withKyc")}>
                        FD Opening (Without operative CASA)
                    </button>
                    &nbsp;&nbsp;*/}
                    {this.state.fdrNewFlowButton===true   && <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            position: "absolute",
                            right: 600,
                        }}
                        onClick={(event) => this.ConditionalNewButton(event )}>
                        {
                            this.props.serviceType === 'FDR Opening'?"FD Opening (With Existing Operative Account)":"DPS Opening (With Existing Operative Account)"

                        }
                    </button>}
                </div>
            )
        }
        else if(this.props.serviceType ==='DPS Opening'){
            return(
                this.state.fdrNewFlowButton===true && <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',
                        position: "absolute",
                        right: 600,
                    }}
                    onClick={(event) => this.ConditionalNewButton(event )}>
                    DPS Opening (With Existing Operative Account
                </button>
            )
        }

    };

    getSubmitedForm = (object) => {
        console.log(object);
    };
    handleSubmit = (event) => {
        //console.log(this.state.appData);
    };
    closeModal = () => {
        this.setState({
            editAccount: false,
            taggingCBSelect: false,
            conditionalNewButton: false,
            errorModalBoolean: false,
            newAcoountOpeningModal: false,
            withoutKyc: false,
            withKyc: false,
        });
        this.props.closeModal();
    };
    closeModalOnlySubModal = () => {
        this.setState({
            editAccount: false,
            taggingCBSelect: false,
            conditionalNewButton: false,
            newAcoountOpeningModal: false,
            withoutKyc: false,
            withKyc: false,
        });
    };
    close = () => {
        this.props.closeModal();
    };
    createTableData = (cb, balance, productCode, productName, accountNo, lienStatus, lienAmount, lienRemarks, freezeStatus, freezeCode, freezeReasonCode, freezeRemarks, acctStatus, accountStatus) => {
        return ([accountNo, balance, productCode, productName, lienStatus, lienAmount, lienRemarks, freezeStatus, freezeCode, freezeReasonCode, freezeRemarks, acctStatus, accountStatus,
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'middle',
                }}
                onClick={(event) => this.editTaggingCBSelect(event, cb, accountNo, lienStatus, freezeStatus, acctStatus, accountStatus, lienRemarks)}>
                 Tag
            </button>
        ]);
    };


    renderNewAccountOpeingForm = (data) => {
        if (data === "withKyc") {
            this.setState({
                withKyc: true,
                newAcoountOpeningModal: true,
                getsearchValue: this.props.searchValue,
            })
        } else {
            this.setState({
                withoutKyc: true,
                newAcoountOpeningModal: true,
                getsearchValue: this.props.searchValue,
            })
        }

    };
    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "Finacle System Error!"
                }
            } else {
                return "Finacle System Error!"
            }
        } else {
            return "Finacle System Error!"
        }
    };

    componentDidMount() {
        //let url = backEndServerURL + "/fdr/accounts/" + this.props.customerId;
        this.setState({
                loading: true
            }
        );
        const tableData = [];
        let data = {};
        data.customerId = this.props.customerId;
        if (this.props.customerType === 'INDIVIDUAL' || this.props.customerType === "Individual A/C") {
            this.setState({
                customerType: "INDIVIDUAL"
            })
        }
        if (this.props.customerType === 'INDIVIDUAL' || this.props.customerType === "Individual A/C" || this.props.customerType === "Joint Account") {
            data.customerType = "RETAIL";

        } else {
            data.customerType = "CORPORATE";

        }

        /*     let url = backEndServerURL + "/fdr/accounts/CB1179323";
          axios.get(url,{headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})            .then((response) => {
                  this.setState({roles: response.data});
                  response.data.map((accounts) => {
                      console.log(response.data);
                      tableData.push(this.createTableData(accounts.cb, accounts.productCode, accounts.productName,accounts.accountNo));
                  });
              this.setState({
                  tableData: tableData,
                  loading:false
              });


          })*/
        let url = backEndServerURL + "/cbsAccountListGet";
        axios.post(url, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response);
                this.setState({roles: response.data});
                let fdrNewFlowButton=false;
                let searchingAndListAccountNumberSame=false;
                response.data.map((accounts) => {
                      if(this.props.searchValue.searchingAccountNumber!==undefined && this.props.searchValue.searchingAccountNumber!==null && (accounts.acctId).trim()===(this.props.searchValue.searchingAccountNumber).trim()){
                        searchingAndListAccountNumberSame=true;
                    }
                    tableData.push(this.createTableData(this.props.customerId, accounts.productCode === "SBSTF" ? "N/A" : accounts.accountBalance, accounts.productCode, accounts.productCategory, accounts.acctId, accounts.lienStatus, accounts.lienAmount, accounts.lienRemarks, accounts.freezeStatus, accounts.freezeCode, accounts.freezeReasonCode, accounts.freezeRemarks, accounts.acctStatus, accounts.accountStatus));
                });

               if(searchingAndListAccountNumberSame===true){
                    fdrNewFlowButton=false;
              }
              else if(searchingAndListAccountNumberSame===false){
                    fdrNewFlowButton=true;
              }
                else  if(response.data !==undefined && response.data.length>0){
                    fdrNewFlowButton=false;
                }

                else{
                     fdrNewFlowButton=true;
                }
                this.setState({
                    fdrNewFlowButton:fdrNewFlowButton,
                    tableData: tableData,
                    loading: false
                });

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    errorModalBoolean: true,
                    errorTittle: this.errorObjectCheck(error)
                })

            });
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    handleChange = (event) => {
        this.setState({appData: event.target.value});
    };
    accountRestrictionCloseModal = (accountNo) => {
        this.setState({
            accountRestriction: false,
            //editAccount: true,
            //accountNo: accountNo
        })
    };
    accountRestrictionCloseModalIcon = () => {
        this.setState({
            accountRestriction: false,
        })
    };

    render() {
        const {classes} = this.props;
        return (
            <section>
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    fullScreen={true}
                    open={this.state.editAccount}
                    TransitionComponent={Transition}
                    autoScrollBodyContent={true}
                >
                    <DialogContent className={classes.dialogPaper}>
                        {this.accountEditModal()}
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    fullScreen={true}
                    open={this.state.taggingCBSelect}
                    TransitionComponent={Transition}
                    autoScrollBodyContent={true}
                >
                    <DialogContent className={classes.dialogPaper}>
                        {this.accounttaggingCBSelect()}
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    fullScreen={true}
                    open={this.state.newAcoountOpeningModal}
                    TransitionComponent={Transition}
                    autoScrollBodyContent={true}>
                    <DialogContent className={classes.dialogPaper}>
                        {this.newAccoutntEditModal()}
                    </DialogContent>
                </Dialog>
                {/*  <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    fullScreen={true}
                    open={this.state.accountRestriction}
                    TransitionComponent={Transition}
                    autoScrollBodyContent={true}>
                    <DialogContent className={classes.dialogPaper}>
                      <AccountRestriction
                          closeIcon={this.accountRestrictionCloseModalIcon}
                          closeModal={this.accountRestrictionCloseModal}
                          lienStatus={this.state.lienStatus}
                          accountNo={this.state.accountNo}
                          freezeStatus={this.state.freezeStatus}
                          accountStatusData={this.state.accountStatusData}
                      />
                    </DialogContent>
                </Dialog>*/}
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.accountRestriction}>
                    <DialogContent className={classes.dialogPaper}>
                        <ErrorModal title={this.state.errorTittle}
                                    closeModal={this.accountRestrictionCloseModal}
                                    subServiceType={this.props.subServiceType}
                                    appId={this.props.appId}
                        />

                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.errorModalBoolean}>
                    <DialogContent className={classes.dialogPaper}>
                        <ErrorModal title={this.state.errorTittle}
                                    closeModal={this.closeModal}
                                    subServiceType={this.props.subServiceType}
                                    appId={this.props.appId}
                        />

                    </DialogContent>
                </Dialog>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >Account List and Status Summary(<b>Customer
                                    ID:{this.props.customerId}</b>)<a><CloseIcon onClick={this.close} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a></h4>

                            </CardHeader>
                            <CardBody>
                                <br/>
                                {this.returnNewAccountCreation()}
                                <br/>
                                <br/>
                                <Table
                                    tableHovor="yes"
                                    tableHeaderColor="primary"
                                    tableHead={["Account Number", "Account Balance", "Product Code", "Product Type", "Lien Status", "Lien Amount", "Lien Remarks", "Freeze Status", "Freeze Code", "Freeze Reason Code", "Freeze Remark", "Account Status", "Closed Status", "Action"]}
                                    tableData={this.state.tableData}
                                    tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                                /> <br/><br/><br/>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </section>
        )
    }
}

export default withStyles(styles)(AccountList);