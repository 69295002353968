import React from 'react';
import Grid from "@material-ui/core/Grid";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import {ThemeProvider} from "@material-ui/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import theme from "../JsonForm/CustomeTheme";
import Functions from '../../Common/Functions';


const styles = theme => ({


    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",

        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
        padding: theme.spacing(3),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
        paddingBottom: theme.spacing(3),

    },
    subTextField: {
        marginLeft: theme.spacing(6),
        marginRight: theme.spacing(1),
        width: 350,


    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },


});

class GroupAndMemberAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: [],
            inputData: [],
            customerName: [],
            customerId: [],
            groupNameAndCustomerId: false,
            memberNameAndCustomerId: [],
            allMember: [],
            alert: false,
            redirectLogin: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    addMemberForm(stylesTexField, substylesTexField) {

        return this.state.values.map((el, i) =>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>

                    <div key={i}>


                        <TextField

                            label="CB Number"

                            //onKeyDown={this.memberHandleKeyDown.bind(this, i)}
                            onChange={this.handleChangeCBNumber.bind(this, i)} name="cbNumber"
                            InputProps={{
                                readOnly: false
                            }}
                        />
                        <TextField
                            className={stylesTexField}
                            label="Name"

                            //onKeyDown={this.memberHandleKeyDown.bind(this, i)}
                            onChange={this.handleChangeCBNumber.bind(this, i)} name="cbNumber"
                            InputProps={{
                                readOnly: false
                            }}
                        />

                        <button
                            className="btn btn-outline-primary"
                            style={{
                                position: "absolute",
                                right: 10
                            }}

                            type='button' value='remove' onClick={this.removeClick.bind(this, i)}

                        >
                            Remove Form
                        </button>


                        <br/><br/>
                    </div>

                </GridItem>

            </GridContainer>
        )
    }


    addClick() {

        this.setState(prevState => ({
            values: [...prevState.values, ''],

        }))


    }


    removeClick(i, event) {
        event.preventDefault();
        let values = [...this.state.values];
        values.splice(i, 1);
        this.setState({values});
        let customerName = [...this.state.customerName];
        customerName.splice(i, 1);
        this.setState({customerName});
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({alert: false});

        let object = [];
        for (var i = 0; i < this.state.customerName.length; i++) {
            object.push({"customerName": this.state.customerName[i], "customerId": this.state.customerId[i]});

            console.log(this.state.customerName[i]);
            console.log(this.state.customerId[i])
        }


        let url = backEndServerURL + "/group/create";
        axios.post(url, {
            "keyCustomerName": this.state.inputData['customerName'],
            "keyCustomerId": this.state.inputData['customerId'],
            "groupUid": this.state.inputData['customerId'],
            "descriptionOfGrouping": this.state.inputData['descriptionOfGrouping'],
            "addMemberDTOS": object


        }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                console.log(response.data);
                if (response.data.status !== 'UNSUCESSFULL') {
                    this.setState({
                        alert: true
                    })
                }

            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
            })


    }


    handleChangeCBNumber = (i, event) => {
        event.preventDefault();
        let values = [...this.state.values];
        values[i] = event.target.value;
        this.setState({values: values});


    };

    handleChange = (event) => {

        event.preventDefault();

        this.state.inputData[event.target.name] = event.target.value;


    };


    render() {

        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <div>

                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <center>
                                    <h4 style={{color: "white"}} >Group and Member Add</h4>
                                </center>
                            </CardHeader>
                            <CardBody>

                                <div className={classes.root}>
                                    <Grid container spacing={1}>
                                        <Grid container item xs={12} spacing={3}>
                                            <ThemeProvider theme={theme}>
                                                <form className={classes.container}>

                                                    <div>

                                                        <br/>

                                                        <button
                                                            className="btn btn-outline-primary"
                                                            style={{
                                                                verticalAlign: 'right',
                                                                marginLeft: theme.spacing(100),
                                                            }}

                                                            type='button' value='add more'
                                                            onClick={this.addClick.bind(this)}

                                                        >
                                                            Add Member
                                                        </button>
                                                    </div>

                                                    <br/> <br/>
                                                    {this.addMemberForm(classes.textField, classes.subTextField)}
                                                    <br/> <br/>
                                                    <center>
                                                        <button
                                                            className="btn btn-outline-primary"
                                                            style={{
                                                                verticalAlign: 'middle',
                                                            }}
                                                            onClick={this.handleSubmit}

                                                        >
                                                            Submit
                                                        </button>
                                                    </center>

                                                </form>
                                            </ThemeProvider>
                                        </Grid>
                                    </Grid>
                                </div>


                            </CardBody>
                        </Card>
                    </GridItem>


                </GridContainer>
            </div>

        );
    }
}

export default withStyles(styles)(GroupAndMemberAdd);
