import React, {Component} from "react";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios/index";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "../../Table/Table";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";


const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

    button: {
        margin: theme.spacing(1),
    }
});


class WaiverList extends Component {
    state = {
        getWaiverList: [],
        getData: false,
        inputData: {},

    };

    constructor(props) {
        super(props);


    }

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    componentDidMount() {
        let waiverListUrl = backEndServerURL + "/case/waiver/" + this.props.appId;
        axios.get(waiverListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                let tableArray = [];
                var status = "";
                response.data.map((waiver) => {
                    if (waiver.status === "APPROVAL_WAITING") {
                        status = "Waiting For Approval"
                    } else if (waiver.status === "ACTIVE") {
                        status = "Approved By BM"
                    } else {
                        status = waiver.status
                    }


                    tableArray.push(this.createTableData(waiver.id, waiver.type, waiver.appliedBy, waiver.applicationDate, status));

                });

                this.setState({
                    getWaiverList: tableArray,
                    getData: true,
                })

            })
            .catch((error) => {
                console.log(error);
            })
    }

    renderWaiverList = () => {


        if (this.state.getWaiverList.length > 0 && this.state.getData) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Waiver List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Waiver Type", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getWaiverList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        } else {

        }

    };

    render() {
        const {classes} = this.props;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>

                    {this.renderWaiverList()}
                </GridItem>
            </GridContainer>
        )

    }

}

export default withStyles(styles)(WaiverList);