import {closerRequestFormBm} from "./WorkflowJsonForm3";
import {maintenanceForBm as BMAccountMaintenance} from "../workflow/Maintenance/static/JsonForCasaMaintenance"
import BMApproval2 from "./Maintenance/static/BMApproval2";
import CloserBmApproval from "./Maintenance/casa/CloserBmApproval";
import FdrBmApproval from "./Maintenance/FDR/FdrBmApproval";
import {maintenanceForBm} from "./Maintenance/FDR/JsonForFdrMaintenance"


import {
    AbhApprovalJsonFormForAgentIndividual,
    AbhApprovalJsonFormForAgentNonIndividual,
    AdcJsonFormForVerify,
    BMJsonFormForCasaCompany,
    BMJsonFormForCasaCompanyExist,
    BMJsonFormForCasaIndividual,
    BMJsonFormForCasaIndividualExist,
    BMJsonFormForCasaJoint,
    BMJsonFormForCasaJointExist,
    BMJsonFormForCasaProprietorship,
    BMJsonFormForCasaProprietorshipExist,
} from "./WorkflowJsonForm4";
import {
    BMinWardJasonFormRemitance,
    BMJsonFormForCasaIndividualDPS,
    BMJsonFormForCasaIndividualFdr,
    BMJsonFormForCasaIndividualTagDPS,
    BMJsonFormForCasaIndividualTagFdr,
    BMJsonFormForCasaIndividualTagFdr2,
    BMJsonFormForNewWithExistCB,
    fttJasonFormRemitanceBM,
    MedicalFileJsonFormBM,
    outwardJasonFormRemitanceBM
} from "./WorkflowJsonFormArin";

import {dpsJsonFormReadOnly} from "./WorkflowJsonFormRashed";
import React, {Component} from "react";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import withStyles from "@material-ui/core/styles/withStyles";
import CardHeader from "../Card/CardHeader";
import CloseIcon from '@material-ui/icons/Close';
import BMApproval from "./CASA/BMApproval";
import OpeningBM from "./fdr/OpeningBM";
import ApprovalBM from "./REMITENCE/ApprovalBM";
import AbhApproval from "./AGENT/AbhApproval";
import MandateBMApproval from "./Maintenance/signatory/BmApproval";
//Asset Operation
import BmApprovalForInterestTransfer from "./AssetOps/OtherServiceRequest/BmInterestTransferRequest";
import BmFormLoanClosing from "./AssetOps/LoanClosing/BmFormLoanClosing";
import VerifyBmFormSecuredLoanClosing from "./AssetOps/SecuredLoanClosing/BmFormSecuredLoanClosing";
import BmDeceasedMark from "./AssetOps/DeceasedMark/BmDeceasedMark";

import DpsCloserBMApproval from "./Maintenance/dps/CloserBmApproval";
import DisbursementMakerApproval from "./AssetOps/PartialPayment/CommonApprovalPartialPayment";
import MakerApproval from "./AssetOps/EmiDataChange/CommonApprovalEmiDateChange";
import LoanAccountTenorMaker from "./AssetOps/LoanAccountTenorChange/MakerApproval";
import CityTouchApprovalOfficer from "./Adc/CityTouchApprovalOfficer";
import Others from "./Maintenance/staticOthers/Others";
import AdcServiceADCandIB from "./Adc/AdcServiceADCandIB";
import FundTransfer from "./FundTransfer/BmInbox";
import MaintenanceUtility from "./Maintenance/static/MaintenanceUtility";
import FDRMaintenanceUtility from "./Maintenance/FDR/MaintenanceUtility"
import BmInboxView from "./FundTransfer/customDuty/BmInboxView";
import BmCustomerStatusUpdate from "./Maintenance/CustomerStatusUpdate/BmInboxView"
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";


const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

    button: {
        margin: theme.spacing(1),
    }
});


class BMInboxCase extends Component {
    state = {
        sendTo: false,

        getDocument: false

    };

    constructor(props) {
        super(props);


    }

    getSearchvalue = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };
    casa = () => {

        if (this.props.serviceType === "Account Opening" && (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C")) {
            return (<BMApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}

                                subServiceType={this.props.subServiceType}
                                titleName={this.props.serviceType}
                                jsonForm={BMJsonFormForCasaIndividual}
                                appId={this.props.appUid}/>)
        } else if (this.props.serviceType === "Account Opening" && (this.props.subServiceType === "Joint Account" || this.props.subServiceType === "Individual A/C")) {
            return (<BMApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}

                                subServiceType={this.props.subServiceType}
                                jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                                titleName={this.props.serviceType}
                                jsonForm={BMJsonFormForCasaJoint}
                                appId={this.props.appUid}/>)
        } else if (this.props.serviceType === "Account Opening" && (this.props.subServiceType === "Proprietorship A/C" || this.props.subServiceType === "NONINDIVIDUAL")) {
            return (<BMApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}

                                subServiceType={this.props.subServiceType}
                                titleName={this.props.serviceType}
                                jsonForm={BMJsonFormForCasaProprietorship}
                                appId={this.props.appUid}/>)
        } else if (this.props.serviceType === "Account Opening" && (this.props.subServiceType === "Company Account")) {
            return (<BMApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}

                                subServiceType={this.props.subServiceType}
                                titleName={this.props.serviceType}
                                jsonForm={BMJsonFormForCasaCompany}
                                appId={this.props.appUid}/>)
        } else if (this.props.serviceType === "AccountOpening" && this.props.subServiceType === "Agent Account Opening") {

            return (<BMApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}

                                subServiceType={this.props.subServiceType}
                                titleName={this.props.serviceType}
                                jsonForm={BMJsonFormForCasaIndividual}
                                appId={this.props.appUid}/>)
        }


    };


    interestTransfer = () => {
        if (this.props.subServiceType === "Other Service Request") {
            return (<BmApprovalForInterestTransfer closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                                   serviceType={this.props.serviceType}
                                                   subServiceType={this.props.subServiceType}
                                                   titleName={this.props.serviceType}
                                                   customerName={this.props.customerName}
                                                   subCategory={this.props.subCategory}
                                                   category={this.props.category}
                                                   caseId={this.props.CaseId}
                                                   solId={this.props.solId}
                                                   accountNumber={this.props.accountNumber}
                                                   cbNumber={this.props.cbNumber} appId={this.props.appUid}/>)
        }
    };


    casaExist = () => {

        if (this.props.serviceType === 'ExistAccountOpening' && (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C")) {
            return (<BMApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}

                                subServiceType={this.props.subServiceType}
                                titleName={this.props.serviceType}
                                jsonForm={BMJsonFormForCasaIndividualExist}
                                appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'ExistAccountOpening' && (this.props.subServiceType === "Joint Account" || this.props.subServiceType === "Individual A/C")) {
            return (<BMApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}

                                subServiceType={this.props.subServiceType}
                                jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                                titleName={this.props.serviceType}
                                jsonForm={BMJsonFormForCasaJointExist}
                                appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'ExistAccountOpening' && (this.props.subServiceType === "Proprietorship A/C" || this.props.subServiceType === "NONINDIVIDUAL")) {
            return (<BMApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}

                                subServiceType={this.props.subServiceType}
                                titleName={this.props.serviceType}
                                jsonForm={BMJsonFormForCasaProprietorshipExist}
                                appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'ExistAccountOpening' && (this.props.subServiceType === "Company Account")) {
            return (<BMApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}

                                subServiceType={this.props.subServiceType}
                                titleName={this.props.serviceType}
                                jsonForm={BMJsonFormForCasaCompanyExist}
                                appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'ExistAccountOpening' && this.props.subServiceType === "Agent Account Opening") {

            return (<BMApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}

                                subServiceType={this.props.subServiceType}
                                titleName={this.props.serviceType}
                                jsonForm={BMJsonFormForCasaIndividualExist}
                                appId={this.props.appUid}/>)
        }


    };

    fdr = () => {
        if (this.props.freeFlag1 === 'FDR Account Opening - with Existing CB Only') {
            return (<OpeningBM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType}
                               freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}

                               subserviceType={this.props.subServiceType}
                               commonJsonForm={BMJsonFormForNewWithExistCB}
                               titleName={this.props.serviceType}
                               appId={this.props.appUid}/>)
        } else if (this.props.freeFlag1 === 'FDR Account Opening -Without Operative Account-NTB') {
            return (<OpeningBM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType}
                               freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}

                               subserviceType={this.props.subServiceType}
                               commonJsonForm={BMJsonFormForCasaIndividualFdr}
                               titleName={this.props.serviceType}
                               appId={this.props.appUid}/>)
        } else if (this.props.freeFlag1 === 'FDR Account Opening - with Existing Operative Account') {
            return (<OpeningBM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType}
                               freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}

                               subserviceType={this.props.subServiceType}
                               commonJsonForm={BMJsonFormForCasaIndividualTagFdr}
                               serviceJsonForm={BMJsonFormForCasaIndividualTagFdr2}
                               titleName="Individual FDR Opening"
                               appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'DPS Opening' && this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account') {
            return (<OpeningBM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType}
                               freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}

                               subserviceType={this.props.subServiceType}
                               commonJsonForm={BMJsonFormForCasaIndividualDPS}
                               serviceJsonForm={BMJsonFormForCasaIndividualTagDPS}
                               titleName={this.props.serviceType}
                               appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'DPS Opening' || this.props.serviceType === 'FDR Opening') {
            return (<BMApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}

                                subServiceType={this.props.subServiceType}
                                titleName={this.props.serviceType}
                                jsonForm={BMJsonFormForCasaIndividual}
                                appId={this.props.appUid}/>)
        }

        /*else if (this.props.serviceType === 'FDR Opening' && this.props.subServiceType === 'Joint Account') {
            return (<ExistingBM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType}
   freeFlag1={this.props.freeFlag1}
   freeFlag2={this.props.freeFlag2}
   freeFlag3={this.props.freeFlag3}  freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}  customerName={this.props.customerName}
                               
                                subserviceType={this.props.subServiceType}
                                commonJsonForm={BMJsonFormForCasaIndividualTagFdr}
                                serviceJsonForm={BMJsonFormForCasaIndividualTagFdr2}
                                titleName="Non Individual FDR Opening"
                                appId={this.props.appUid}/>)
        }*/
        else {
            return (<OpeningBM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType}
                               freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}

                               subserviceType={this.props.subServiceType}
                               commonJsonForm={BMJsonFormForCasaIndividualTagFdr}
                               serviceJsonForm={BMJsonFormForCasaIndividualTagFdr2}
                               titleName="Non Individual FDR Opening"
                               appId={this.props.appUid}/>)
        }
    };

    remetance = () => {

        if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'Student File') {
            return (<ApprovalBM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}

                                subServiceType={this.props.subServiceType}
                                titleName="Outward Remittance Information"
                                jsonForm={outwardJasonFormRemitanceBM}
                                appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'Medical File') {
            return (<ApprovalBM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}

                                subServiceType={this.props.subServiceType}
                                titleName="Outward Remittance Information"
                                jsonForm={MedicalFileJsonFormBM}
                                appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'FTT') {
            return (<ApprovalBM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}

                                subServiceType={this.props.subServiceType}
                                titleName="Outward Remittance Information"
                                jsonForm={fttJasonFormRemitanceBM}
                                appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'FDD') {
            return (<ApprovalBM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}

                                subServiceType={this.props.subServiceType}
                                titleName="Outward Remittance Information"
                                jsonForm={fttJasonFormRemitanceBM}
                                appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'Inward') {
            return (<ApprovalBM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}

                                subServiceType={this.props.subServiceType}
                                titleName="Inward Remittance Information"
                                jsonForm={BMinWardJasonFormRemitance}
                                appId={this.props.appUid}/>)
        }
    };
    agent = () => {
        if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C") {
            return (
                <AbhApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                             serviceType={this.props.serviceType}

                             subServiceType={this.props.subServiceType}
                             titleName={this.props.serviceType}
                             jsonForm={AbhApprovalJsonFormForAgentIndividual}
                             appId={this.props.appUid}/>
            )
        } else if (this.props.subServiceType === "Company Account") {
            return (
                <AbhApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                             serviceType={this.props.serviceType}

                             subServiceType={this.props.subServiceType}
                             titleName={this.props.serviceType}
                             jsonForm={AbhApprovalJsonFormForAgentNonIndividual}
                             appId={this.props.appUid}/>
            )
        }

    };

    adc = () => {
        if ((this.props.serviceType === "adc_service" && this.props.freeFlag3 === "city_touch") || this.props.serviceType === "internet_banking") {
            return (<CityTouchApprovalOfficer closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                              serviceType={this.props.serviceType}

                                              subServiceType={this.props.subServiceType}
                                              titleName="Adc Service"
                                              jsonForm={AdcJsonFormForVerify}
                                              appId={this.props.appUid}/>)
        } else if ((this.props.serviceType === "adc_service" && this.props.freeFlag3 === "sms_alert") || (this.props.serviceType === "adc_service" && (this.props.subCategory === "Service" || this.props.subCategory === "A/C Opening"))) {
            return (<AdcServiceADCandIB closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                        serviceType={this.props.serviceType}

                                        subServiceType={this.props.subServiceType}
                                        titleName="Adc Service"
                                        jsonForm={AdcJsonFormForVerify}
                                        appId={this.props.appUid}/>)
        }
    };
    maintenance = () => {
        if ((this.props.serviceType === 'Maintenance' && this.props.subServiceType === 'AccountMaintenance') && (this.props.category === "CASA Closer" || this.props.category === "DPS Closer" || this.props.category === "Deceased DPS Closer")) {
            return (<CloserBmApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                      serviceType={this.props.serviceType}

                                      subServiceType={this.props.subServiceType}
                                      titleName="CASA Closer Maintenance"
                                      maintenanceType={this.props.maintenanceType}
                                      jsonForm={closerRequestFormBm}
                                      appId={this.props.appUid}/>)
        } else if ((this.props.serviceType === 'Maintenance' && this.props.subServiceType === 'AccountMaintenance') && this.props.category === "DPS Maintenance") {
            return (<DpsCloserBMApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                         serviceType={this.props.serviceType}
                                         subServiceType={this.props.subServiceType}
                                         maintenanceType={this.props.maintenanceType}
                                         jsonForm={dpsJsonFormReadOnly}
                                         appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Maintenance' && this.props.subServiceType === 'AccountMaintenance') {
            return (<BMApproval2 closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                 serviceType={this.props.serviceType}

                                 subServiceType={this.props.subServiceType}
                                 titleName="Account Maintenance"
                                 maintenanceType={this.props.maintenanceType}
                                 jsonForm={BMAccountMaintenance}
                                 appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Maintenance' && this.props.subServiceType === 'FDRMaintenance') {
            return (<FdrBmApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                   serviceType={this.props.serviceType}
                                   subServiceType={this.props.subServiceType}
                                   titleName="FDR Maintenance"
                                   subCategory={this.props.subCategory}
                                   maintenanceType={this.props.maintenanceType}
                                   jsonForm={maintenanceForBm}
                                   appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Maintenance' && this.props.subServiceType === 'MandateMaintenance') {
            return (<MandateBMApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                       serviceType={this.props.serviceType}

                                       subServiceType={this.props.subServiceType}
                                       titleName="Mandate/Signatory Update"
                                       subCategory={this.props.subCategory}
                                       maintenanceType={this.props.maintenanceType}
                                       appId={this.props.appUid}/>)
        }
    };


    loan = () => {
        return (
            <BmFormLoanClosing closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType}

                               subServiceType={this.props.subServiceType}
                               titleName="BM Approval"
                               jsonForm={closerRequestFormBm}
                               customerName={this.props.customerName}
                               subCategory={this.props.subCategory}
                               category={this.props.category}
                               subServiceType={this.props.subServiceType}
                               caseId={this.props.CaseId}
                               solId={this.props.solId}
                               accountNumber={this.props.accountNumber}
                               cbNumber={this.props.cbNumber} appId={this.props.appUid}/>
        )
    };

    securedLoan = () => {
        return (
            <VerifyBmFormSecuredLoanClosing closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                            serviceType={this.props.serviceType}

                                            subServiceType={this.props.subServiceType}
                                            titleName="BM Approval"
                                            jsonForm={closerRequestFormBm}
                                            customerName={this.props.customerName}
                                            subCategory={this.props.subCategory}
                                            category={this.props.category}
                                            subServiceType={this.props.subServiceType}
                                            caseId={this.props.CaseId}
                                            solId={this.props.solId}
                                            accountNumber={this.props.accountNumber}
                                            cbNumber={this.props.cbNumber} appId={this.props.appUid}/>
        )
    };

    deceasedMark = () => {
        return (
            <BmDeceasedMark closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}
                                titleName="BM Approval"
                                customerName={this.props.customerName}
                                subCategory={this.props.subCategory}
                                category={this.props.category}
                                subServiceType={this.props.subServiceType}
                                caseId={this.props.CaseId}
                                solId={this.props.solId}
                                accountNumber={this.props.accountNumber}
                                cbNumber={this.props.cbNumber} appId={this.props.appUid}/>
        )
    };

    casaOtherMaintenance = () => {
        if (this.props.freeFlag1 === 'CASA Other Maintenance' && this.props.serviceType === "Maintenance") {
            return (<Others closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                            serviceType={this.props.serviceType}
                            freeFlag1={this.props.freeFlag1}
                            freeFlag2={this.props.freeFlag2}
                            freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                            recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                            taskTitle={this.props.taskTitle}

                            subServiceType={this.props.subServiceType}
                            titleName="CASA Static Other Maintenance"
                            appId={this.props.appUid}/>)
        }
    };

    fundTransfer = () => {
        if (this.props.serviceType === 'FundTransfer' && this.props.subServiceType === 'NewFundTransfer') {
            return (<FundTransfer closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                  serviceType={this.props.serviceType}
                                  freeFlag1={this.props.freeFlag1}
                                  freeFlag2={this.props.freeFlag2}
                                  freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                  recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                  subserviceType={this.props.subServiceType}
                                  titleName="Fund Transfer Request"
                                  appId={this.props.appUid}/>)
        }

    };

    customDutyPayment = () => {
        return (
            <BmInboxView closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                          serviceType={this.props.serviceType}
                          freeFlag1={this.props.freeFlag1}
                          freeFlag2={this.props.freeFlag2}
                          freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                          recpmtid={this.props.recpmtid}
                          customerName={this.props.customerName}
                          subCategory={this.props.subCategory}
                          category={this.props.category}
                          subServiceType={this.props.subServiceType}
                          caseId={this.props.CaseId}
                          solId={this.props.solId}
                          accountNumber={this.props.accountNumber}
                          cbNumber={this.props.cbNumber} appId={this.props.appUid}/>
        )
    };


    customerStatusUpdate = () => {
        return(
            <BmCustomerStatusUpdate closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                    serviceType={this.props.serviceType}
                                    freeFlag1={this.props.freeFlag1}
                                    freeFlag2={this.props.freeFlag2}
                                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                    recpmtid={this.props.recpmtid}
                                    customerName={this.props.customerName}
                                    subCategory={this.props.subCategory}
                                    category={this.props.category}
                                    subServiceType={this.props.subServiceType}
                                    caseId={this.props.CaseId}
                                    solId={this.props.solId}
                                    accountNumber={this.props.accountNumber}
                                    cbNumber={this.props.cbNumber} appId={this.props.appUid}/>
        )
    };



    inboxCase = () => {

        if ((LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BUSINESS_DIVISION")) {

            return (
                this.casa()
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "APPROVALOFFICER") {

            return (
                this.agent()
            )
        }
        else if(this.props.subCategory === "Custom Duty Payment"){
            return (
                this.customDutyPayment()
            )
        }
        else if(this.props.subCategory === "Customer Status Update"){
            return (
                this.customerStatusUpdate()
            )
        }
        else if (this.props.category === "Loan Closing") {
            return (
                this.loan()
            )
        } else if (this.props.category === "Secured Loan Closing") {
            return (
                this.securedLoan()
            )
        } else if (this.props.serviceType === "FundTransfer") {
            return (
                this.fundTransfer()
            )
        } else if (this.props.category === "Deceased Mark") {
            return (
                this.deceasedMark()
            )
        } else if (this.props.subServiceType === "Other Service Request") {
            return (
                this.interestTransfer()
            )
        } else if (((LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BUSINESS_DIVISION" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BM")) && this.props.serviceType === "ExistAccountOpening") {

            return (
                this.casaExist()
            )
        } else if (this.props.freeFlag1 === "CASA Other Maintenance" && this.props.serviceType === "Maintenance") {
            return (
                this.casaOtherMaintenance()
            )
        } else if (this.props.serviceType === "FDR Opening" || this.props.serviceType === "DPS Opening") {

            return (
                this.fdr()
            )
        } else if (this.props.serviceType === "Maintenance") {

            return (
                this.maintenance()
            )
        } else if (this.props.serviceType === "ExistAccountOpening") {
            return (
                this.casaExist()
            )
        } else if (this.props.serviceType === "Remetance") {
            return (
                this.remetance()
            )
        } else if (this.props.serviceType === "Account Opening") {

            return (
                this.casa()
            )
        } else if (this.props.serviceType === "adc_service" || this.props.serviceType === "internet_banking") {
            return (
                this.adc()
            )
        } else if (this.props.category === "Partial Payment") {
            console.log(this.props.appUid);
            return (
                <DisbursementMakerApproval
                    closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                    serviceType={this.props.serviceType}

                    subServiceType={this.props.subServiceType}
                    titleName="bm_process"
                    jsonForm={AbhApprovalJsonFormForAgentNonIndividual}
                    customerName={this.props.customerName}
                    subCategory={this.props.subCategory}
                    category={this.props.category}
                    caseId={this.props.CaseId}
                    solId={this.props.solId}
                    accountNumber={this.props.accountNumber}
                    cbNumber={this.props.cbNumber} appId={this.props.appUid}
                />
            )
        } else if (this.props.category === "EMI") {
            return (
                <MakerApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType}
                               titleName="bm_process"
                               jsonForm={AbhApprovalJsonFormForAgentNonIndividual}
                               customerName={this.props.customerName}
                               subCategory={this.props.subCategory}
                               category={this.props.category}
                               subServiceType={this.props.subServiceType}
                               caseId={this.props.CaseId}
                               solId={this.props.solId}
                               accountNumber={this.props.accountNumber}
                               cbNumber={this.props.cbNumber} appId={this.props.appUid}
                />
            )
        } else if (this.props.subCategory === "Tenor Change") {
            return (
                <LoanAccountTenorMaker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                       serviceType={this.props.serviceType}

                                       titleName="bm_process"
                                       jsonForm={AbhApprovalJsonFormForAgentNonIndividual}
                                       customerName={this.props.customerName}
                                       subCategory={this.props.subCategory}
                                       category={this.props.category}
                                       subServiceType={this.props.subServiceType}
                                       caseId={this.props.CaseId}
                                       solId={this.props.solId}
                                       accountNumber={this.props.accountNumber}
                                       cbNumber={this.props.cbNumber} appId={this.props.appUid}/>
            )
        }

    };
    close = () => {
        this.props.closeModal();
    };
    closeIcon = () => {
        this.props.closeIcon();
    };
    renderSiCbNumber = () => {
        if (this.props.recpmtid !== undefined && this.props.recpmtid !== null) {
            return (
                <><b> SI CB number:</b>{this.props.recpmtid}</>
            )
        } else {
            return ""

        }
    };

masterdataToString=(data)=>{
    if(data!==undefined && data.key!==undefined){
        return data.value;
    }
    else{
        return data;
    }
}
    renderHeader = () => {


        if (this.props.serviceType === "adc_service") {
            return (
                <h4 style={{color: "white"}} ><b>{this.props.subCategory}</b>(<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                    <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                    <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}
                    <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Scheme
                        Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""}<b> Case
                        ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                        onClick={this.closeIcon} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></h4>
            )
        } else if (this.props.serviceType === "internet_banking") {
            return (
                <h4 style={{color: "white"}} ><b>CityTouch Request</b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                    <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                    <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}
                    <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Scheme
                        Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""}<b> Case
                        ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                        onClick={this.closeIcon} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>
            )
        } else if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
            return (
                <h4 style={{color: "white"}} ><b>{this.props.freeFlag1}(<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                    <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                    <b> CB
                        Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}{this.renderSiCbNumber()}
                    <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Scheme
                        Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""}<b> Case
                        ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                        onClick={this.closeIcon} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></b></h4>

            )
        } else if (this.props.freeFlag1 === "FDR Account Opening - with Existing Operative Account") {
            return (
                <h4 style={{color: "white"}} ><b>{this.props.freeFlag1}</b><p style={{fontSize: "16px"}}> (<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                    <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                    <b> CB
                        Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}{this.renderSiCbNumber()}
                    <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Scheme
                        Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""}<b> Case
                        ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                        onClick={this.closeIcon} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>

            )
        } else if (this.props.freeFlag1 === "FDR Account Opening - with Existing CB Only") {
            return (
                <h4 style={{color: "white"}} >
                    <b>{this.props.freeFlag2 === "withKyc" ? "( FDR Account Opening - Without operative CASA )" : "( FDR Account Opening - Using Different CASA )"}</b>
                    <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                        <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                        <b> CB
                            Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}{this.renderSiCbNumber()}
                        <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Scheme
                            Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""}<b> Case
                            ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                            onClick={this.closeIcon} style={{
                            position: 'absolute',
                            right: 10,
                            color: "#000000"
                        }}/></a></p></h4>

            )
        } else if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
            return (
                <h4 style={{color: "white"}} ><b>{this.props.freeFlag1}</b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                    <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                    <b> CB
                        Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}{this.renderSiCbNumber()}
                    <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Scheme
                        Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""}<b> Case
                        ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                        onClick={this.closeIcon} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>

            )
        } else if (this.props.serviceType === "FDR Opening") {
            return (
                <h4 style={{color: "white"}} ><b>FDR Account Opening -with NTB Operative Account </b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}{<>
                    <b> A/C:</b>{this.props.accountNumber !== undefined ? "" : ""}
                    <b> CB
                        Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}{this.renderSiCbNumber()}<b> SOLId:</b>{this.masterdataToString(this.props.solId)}</>}<b> Case
                    ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                    onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></p></h4>

            );
        } else if (this.props.serviceType === "DPS Opening") {
            return (
                <h4 style={{color: "white"}} ><b>DPS Account Opening -with NTB Operative Account </b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}{<>
                    <b> A/C:</b>{this.props.accountNumber !== undefined ? "" : ""}
                    <b> CB
                        Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}{this.renderSiCbNumber()}<b> SOLId:</b>{this.masterdataToString(this.props.solId)}</>}<b> Case
                    ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                    onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></p></h4>

            );
        } else if (this.props.serviceType === 'FundTransfer' && this.props.subServiceType === 'NewFundTransfer') {
            return (
                <h4 style={{color: "white"}} >Fund Transfer-{this.props.subCategory === "SME/Corporate" ? "Corporate" : this.props.subCategory}<a><CloseIcon
                    onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            )
        } else if (this.props.subServiceType === "FDRMaintenance" && this.props.serviceType === "Maintenance") {
            return FDRMaintenanceUtility.renderMaintenanceHeader(this.props.freeFlag3, this.props.solId, this.props.cbNumber, this.props.accountNumber, this.props.customerName, this.closeIcon,this.props.CaseId);
            // return (
            //     <h4 style={{color: "white"}} >FDR Maintenance<a><CloseIcon onClick={this.closeIcon} style={{
            //         position: 'absolute',
            //         right: 10,
            //         color: "#000000"
            //     }}/></a></h4>
            //
            // );
        } else if (this.props.subServiceType === "AccountMaintenance" && this.props.serviceType === "Maintenance" && this.props.category === "CASA Closer") {
            return MaintenanceUtility.renderCloserMaintenanceHeader(this.props.freeFlag3, this.props.solId, this.props.cbNumber, this.props.accountNumber, this.props.customerName, this.closeIcon);
        } else if (this.props.subServiceType === "AccountMaintenance" && this.props.serviceType === "Maintenance" && (this.props.category === "DPS Closer" || this.props.category === "DPS Maintenance")) {
            return (
                <h4 style={{color: "white"}} >DPS/Scheme Closer Request<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            );
        } else if (this.props.subServiceType === "AccountMaintenance" && this.props.serviceType === "Maintenance" && this.props.category === "Deceased DPS Closer") {
            return (
                <h4 style={{color: "white"}} >Deceased DPS/Scheme Closer Request<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            );
        } else if (this.props.subServiceType === "AccountMaintenance" && this.props.serviceType === "Maintenance" && this.props.category === "DPS Closer") {
            return (
                <h4 style={{color: "white"}} >DPS CLoser Request<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            );
        } else if (this.props.subServiceType === "AccountMaintenance" && this.props.serviceType === "Maintenance") {
            return MaintenanceUtility.renderMaintenanceHeader(this.props.freeFlag1, this.props.CaseId, this.props.freeFlag3, this.props.cbNumber, this.props.accountNumber, this.props.customerName, this.closeIcon);
            // return (
            //     <h4 style={{color: "white"}} ><b>CASA Static Data Maintenance</b><p style={{fontSize:"16px"}}> (<b> Customer
            //         Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
            //         {this.props.freeFlag2 === "linkedCB" ? <>
            //             <b> CB:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}</> : <>
            //          <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
            //         <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}<b> AC SOL Id:</b>{this.props.freeFlag3 !== undefined ? this.props.freeFlag3 : ""}</>})<a><CloseIcon onClick={this.closeIcon} style={{
            //         position: 'absolute',
            //         right: 10,
            //         color: "#000000"
            //         }}/></a></p></h4>
            //
            // );
        } else if (this.props.subServiceType === "MandateMaintenance") {
            return MaintenanceUtility.renderMaintenanceHeader(this.props.freeFlag1, this.props.CaseId, this.props.freeFlag3, this.props.cbNumber, this.props.accountNumber, this.props.customerName, this.closeIcon);
            // return (
            //     <h4 style={{color: "white"}} ><b>Mandate/Signatory Update </b><p style={{fontSize:"16px"}}>(<b> Customer
            //         Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}{<>
            //      <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
            //         <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}<b> CB:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}<b> SOLId:</b>{this.props.freeFlag3 !== undefined ? this.props.freeFlag3 : ""}</>})<a><CloseIcon onClick={this.closeIcon} style={{
            //         position: 'absolute',
            //         right: 10,
            //         color: "#000000"
            //     }}/></a></p></h4>
            //
            // );
        } else if (this.props.category === "EMI") {
            return (
                <h4 style={{color: "white"}} ><b>EMI Date Change</b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                    <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                    <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}
                    <b> SOLID:</b>{this.masterdataToString(this.props.solId)}  <b> Case
                        ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                        onClick={this.closeIcon} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>

            );
        } else if (this.props.subServiceType === "Other Service Request") {
            return (
                <h4 style={{color: "white"}} ><b>Other Service Request</b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                    <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                    <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}
                    <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Case
                        ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                        onClick={this.closeIcon} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>


            );
        } else if (this.props.serviceType === "Remetance") {
            return (
                <h4 style={{color: "white"}} >Remittance<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            );
        } else if (this.props.serviceType === 'ExistAccountOpening') {
            return (
                <h4 style={{color: "white"}} ><b>Existing
                    Customer
                    Account Opening
                    - {this.props.subServiceType === "Company Account" ? "Partnership Account" : this.props.subServiceType} </b>
                    <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                        <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                        <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}
                        <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Scheme
                            Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""}<b> Case
                            ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                            onClick={this.closeIcon} style={{
                            position: 'absolute',
                            right: 10,
                            color: "#000000"
                        }}/></a></p></h4>
            )
        } else if (this.props.serviceType === "Account Opening") {
            return (
                <h4 style={{color: "white"}} ><b>New Customer Account Opening
                    - {this.props.subServiceType === "Company Account" ? "Partnership Account" : this.props.subServiceType} </b>
                    <p
                        style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                        <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                        <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}
                        <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Scheme
                            Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""}<b> Case
                            ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                            onClick={this.closeIcon} style={{
                            position: 'absolute',
                            right: 10,
                            color: "#000000"
                        }}/></a></p></h4>
            )
        }else if(this.props.serviceType === "Closing Request(secured Loan)"
            || this.props.serviceType === "Closing Request(Other than secured Loan)" || this.props.serviceType === "Loan AC Tenor Change Request"){

            return (
                <h4 style={{color: "white"}} ><b>{this.props.serviceType}</b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                    <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                    <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}
                    <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Case
                        ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                        onClick={this.closeIcon} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>
            )
        }
        else {

            return (
                <h4 style={{color: "white"}} ><b>{this.props.serviceType}</b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                    <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                    <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}
                    <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Scheme
                        Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""}<b> Case
                        ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                        onClick={this.closeIcon} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>
            )
        }
    };

    render() {
        const {classes} = this.props;

        if (this.props.freeFlag1 === "CASA Other Maintenance" || this.props.subCategory === "Custom Duty Payment" || this.props.subCategory === "Customer Status Update"  || this.props.subCategory === "Deceased Mark"
            || this.props.subCategory === "Partial Payment" ) {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        {this.inboxCase()}
                    </GridItem>
                </GridContainer>
            )
        } else {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                                {this.renderHeader()}
                            </CardHeader>
                            <CardBody>

                                {this.inboxCase()}

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            )
        }
    }

}

export default withStyles(styles)(BMInboxCase);
