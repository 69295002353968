import React, {Component} from "react";
import Liability from "./CASA/Liability";
import CSRejectedPage from "./CASA/CSRejectedPage";
import AgentBanking from "./AGENT/AgentBanking";
import FundInbox from "./FundTransfer/CsSplitForm";
import LockerClosing from "./LOCKER/CSLockerSurrender";
import CsLockerMainentance from "./LOCKER/CsLockerMaintenance";
import CsAfterMakerSecuredLoanClosing from "./AssetOps/SecuredLoanClosing/CsAfterMakerSecuredLoanClosing";
import CsPartialPayment from "./AssetOps/PartialPayment/CsPartialPayment";
import CsPayOrder from "./AssetOps/PayOrder/CsPayOrder";
import CsOutstandingCertificate from "./AssetOps/OutstandingCertificate/CsOutstandingCertificate";
import CsLienMarking from "./AssetOps/LienMarking/CsLienMarking";
import CsRecoveryAmount from "./AssetOps/RecoveryAmount/CsRecoveryAmount";

import {
    AbhMakerJsonFormForAgentIndividual,
    AbhMakerJsonFormForAgentNonIndividual,
    AdcJsonFormForCS,
    AdcJsonFormForVerify,
    CSInterestPayment,
    CSJsonFormFor3ms,
    CSJsonFormForBondWegDevelopmentBond,
    CSJsonFormForBsp,
    CSJsonFormForCasaCompany,
    CSJsonFormForCasaCompanyExist,
    CSJsonFormForCasaIndividual,
    CSJsonFormForCasaIndividualExist,
    CSJsonFormForCasaJoint,
    CSJsonFormForCasaJointExist,
    CSJsonFormForCasaProprietorship,
    CSJsonFormForCasaProprietorshipExist,
    CSJsonFormForCasaService,
    CSJsonFormForCasaServiceExist,
    CSJsonFormForCasaServiceLast,
    CSJsonFormForCasaServiceLastExist,
    CSJsonFormForFsp,
    CSJsonFormForPsc,
    CSJsonFromForNomineeChange,
} from "./WorkflowJsonForm4";
import {
    CSfddJasonFormRemitance,
    CSinWardJasonFormRemitance,
    CSJsonFormForCasaIndividualDPS,
    CSJsonFormForCasaIndividualFdr,
    CSJsonFormForCasaIndividualFdrLast,
    CSJsonFormForCasaIndividualTagDPS,
    CSJsonFormForCasaIndividualTagFdr,
    CSJsonFormForDPSService,
    CSJsonFormForFDRService,
    CSJsonFormForFDRServiceForExistCbOnly,
    CSJsonFormForFDRServiceLast,
    CSJsonFormForFDRServiceNew,
    CSJsonFormForNewWithExistCB,
    CSLockerOpeningForm,
    MedicalFileJsonForm,
    RemittanceServiceOtherJsonForm,
    StudentFileJsonForm
} from "./WorkflowJsonFormArin";
import {closerRequestFormBm,} from "./WorkflowJsonForm3";
import CSInbox from "./BOND/CsInbox";
import CsOpening from './Card/CsOpening'
import CSEnrollmentInbox from "./BOND/Enrollment/CSEnrollmentInbox";
import CSApproval from "./REMITENCE/CSApproval";
import MaintenanceRequest from "./CardCallCategory/MaintenanceRequest";
import AdcService from "./Adc/AdcService";
import OpeningCSLocker from "./LOCKER/OpeningCSLocker";
import InstaDebitCardRequest from "./InstaDebitcard/InstaDebitCardRequest";
import OpeningCS from "./fdr/OpeningCS";
import AccountEdit from "./fdr/AccountEdit";
import AccountMaintenance from "./Maintenance/static/AccountMaintenance";
import FDRMaintenance from "./Maintenance/FDR/FDRMaintenance";
import MandateApproval from "./Maintenance/signatory/SignatoryMaintenance"
import CloserMaintenance from "./Maintenance/casa/CsInbox";
import DPSCloserMaintenance from "./Maintenance/dps/CsInbox";
import DPSCloserMaintenanceReturn from "./Maintenance/dps/CsInbox";
import ChequeBookInbox from "../DeliverableManagement/ChequeBook/ChequeBookInbox"
import ChequeBookInboxs from "../DeliverableManagement/ChequeBookRequisitionService/ChequeBookInbox";
import CreditCardChequeBookRequisition
    from "../DeliverableManagement/CreditCardChequeBook/CreditCardChequeBookRequisition";
import DebitCardRequisition from "../DeliverableManagement/DebitCard/DebitCardRequisition";
import InstaPackRequisition from "../DeliverableManagement/InstaChequeBook/InstaPackRequisition";
import InstaDebitCardRequisition from "../DeliverableManagement/InstaDebitCard/InstaDebitCardRequisition";
import CsoRequisition from "./AssetOps/EmiDataChange/CsEmiDateChange";
import CsoRequisitionTenor from "./AssetOps/LoanAccountTenorChange/CsoRequisition";
import CsoRequisitionInterestTransfer from "./AssetOps/OtherServiceRequest/CsoRequisition";
import CsFormLoanClosing from "./AssetOps/LoanClosing/CsFormLoanClosing";
import CSOAfterHLD from "./AssetOps/CSOAfterHLD";
import CsSecuredLoanClosing from "./AssetOps/SecuredLoanClosing/CsSecuredLoanClosing";
import CsDeceasedMark from "./AssetOps/DeceasedMark/CsDeceasedMark";
import CrFormWaverRequest from "./AssetOps/WaiverRequest/CsWiaverRequest";
import Others from "./Maintenance/staticOthers/Others";
import LinkedAccountMaintenance from "./Maintenance/static/LinkedAccountMaintenance";
import FdrEncashment from "./Maintenance/FDR/FdrEncashment";
import CsRequisition from "../DeliverableManagement/GIftCard/CsRequisition";
import PrepaidCardIssuance from "../DeliverableManagement/GIftCard/PrepaidCardIssuance";
import GiftCardIssuance from "../DeliverableManagement/GIftCard/GiftCardIssuance";
import CustomDutyPayment from "../workflow/FundTransfer/customDuty/CsRequisition";
import CustomerStatusUpdateCs from "./Maintenance/CustomerStatusUpdate/CsRequisition";
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";


let SearchForm = [

    {
        "varName": "nid",
        "type": "text",
        "label": "NID",
        "grid": 3,

    },
    {
        "varName": "passport",
        "type": "text",
        "label": "Passport",
        "grid": 3,


    },
    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name",
        "required": true,
        "grid": 3,

    },
    /* {
         "varName": "dob",
         "type": "date",
         "label": "Date Of Birth",
         "required": true,
         "grid": 6,


     },*/
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "email": true,
        "grid": 3,

    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Phone Number",
        "required": true,
        "grid": 3,


    },

    {
        "varName": "tin",
        "type": "text",
        "label": "eTin",
        "grid": 3,

    },


    {
        "varName": "registrationNo",
        "type": "text",
        "label": "Birth Certificate/Driving License",
        "grid": 3,

    },
    {
        "varName": "nationality",
        "type": "text",
        "label": "Nationality",
        "required": true,
        "grid": 3,


    },
];

let SearchFormIndividual = [

    {
        "varName": "nid",
        "type": "text",
        "label": "NID",
        "grid": 3,

    },
    {
        "varName": "passport",
        "type": "text",
        "label": "Passport",
        "grid": 3,


    },
    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name",
        "required": true,
        "grid": 3,

    },
    /* {
         "varName": "dob",
         "type": "date",
         "label": "Date Of Birth",
         "required": true,
         "grid": 6,


     },*/
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "email": true,
        "grid": 3,

    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Phone Number",
        "required": true,
        "grid": 3,


    },

    {
        "varName": "tin",
        "type": "text",
        "label": "eTin",
        "grid": 3,

    },


    {
        "varName": "registrationNo",
        "type": "text",
        "label": "Birth Certificate/Driving License",
        "grid": 3,

    },
    {
        "varName": "nationality",
        "type": "text",
        "label": "Nationality",
        "enum": [
            "Bangladesh",
            "Japan",
            "Other",
        ],
        "required": true,
        "grid": 3,


    },

];
let SearchFormNonIndividual = [

    {
        "varName": "companyName",
        "type": "text",
        "label": "Company Name",
        "required": false,
        "readOnly": false,
        "grid": 3


    },

    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "required": false,

        "readOnly": false,
        "grid": 3


    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile Number",
        "required": false,
        "grid": 3

    },

    {
        "varName": "companyEtin",
        "type": "text",
        "label": "Company ETin",
        "required": false,
        "grid": 3


    },
    {
        "varName": "tradeLicense",
        "type": "text",
        "label": "Trade License",
        "required": false,
        "readOnly": false,
        "grid": 3


    },
    {
        "varName": "certificate",
        "type": "text",
        "label": "Certificate Of Incorporation",
        "required": false,
        "grid": 3


    }


];
let SearchFormJoint = [

    {
        "varName": "cbNumber",
        "type": "text",
        "label": "Cb Number",
        "grid": 3


    },
    {
        "varName": "accountSource",
        "type": "text",
        "label": "Account Source",
        "grid": 3


    },
    {
        "varName": "accountTitle",
        "type": "text",
        "label": "Account TItle",
        "grid": 3


    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "grid": 3,

        "email": true,


    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile Number",
        "required": true,
        "grid": 3


    }
];

class CSInboxCase extends Component {
    agent = () => {

        if (this.props.serviceType === "Account Opening" && this.props.subServiceType === "Agent Account Opening" && this.props.taskTitle === 'cs_data_capture') {
            return (<AgentBanking closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                  serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                  freeFlag2={this.props.freeFlag2}
                                  freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                  recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                  delStatus={this.props.delStatus}
                                  subCategory={this.props.subCategory} category={this.props.category} department="CASA"
                                  subServiceType={this.props.subServiceType}
                                  subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                                  commonJsonForm={SearchFormNonIndividual}
                                  appId={this.props.appUid} caseId={this.props.CaseId}/>)

        }
    };

    renderLocker = () => {
        if (this.props.serviceType === 'Locker Opening') {
            return (<OpeningCSLocker
                closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                freeFlag2={this.props.freeFlag2}
                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}
                customerName={this.props.customerName}

                delStatus={this.props.delStatus}
                subCategory={this.props.subCategory}
                category={this.props.category}
                subServiceType={this.props.subServiceType}
                titleName="Outward Remitance Information"
                commonJsonForm={CSLockerOpeningForm}
                appId={this.props.appUid} caseId={this.props.CaseId}/>)
        }
    };

    csAssetRejected = () => {
        if (this.props.category === 'Partial Payment') {
            return (<CsPartialPayment closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                  serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                  freeFlag2={this.props.freeFlag2}
                                  freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                  recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                  subServiceType={this.props.subServiceType}
                                  appId={this.props.appUid} solId={this.props.solId}
                                  accountNumber={this.props.accountNumber}
                                  cbNumber={this.props.cbNumber} caseId={this.props.CaseId}/>)

        }
    };

    csPayOrderRejected = () => {
        if (this.props.category === 'Pay Order') {
            return (<CsPayOrder closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                subServiceType={this.props.subServiceType}
                                appId={this.props.appUid} solId={this.props.solId}
                                accountNumber={this.props.accountNumber}
                                cbNumber={this.props.cbNumber} caseId={this.props.CaseId}/>)

        }
    };


    csLienMarkingRejected = () => {
        if (this.props.category === 'Lien Marking') {
            return (<CsLienMarking closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                   serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                   freeFlag2={this.props.freeFlag2}
                                   freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                   recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                   subServiceType={this.props.subServiceType}
                                   appId={this.props.appUid} solId={this.props.solId}
                                   accountNumber={this.props.accountNumber}
                                   cbNumber={this.props.cbNumber} caseId={this.props.CaseId}
                                   flowSummery={true}/>)

        }
    };

    csRecoveryAmountRejected = () => {
        if (this.props.category === 'Recovery Amount') {
            return (<CsRecoveryAmount closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                      serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                      freeFlag2={this.props.freeFlag2}
                                      freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                      recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                      subServiceType={this.props.subServiceType}
                                      appId={this.props.appUid} solId={this.props.solId}
                                      accountNumber={this.props.accountNumber}
                                      cbNumber={this.props.cbNumber} caseId={this.props.CaseId}
                                      flowSummery={true}/>)
        }
    };

    csOutstandingCertificateReturn = () => {
        if (this.props.category === 'Outstanding Certificate') {
            return (<CsOutstandingCertificate closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                              serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                              freeFlag2={this.props.freeFlag2}
                                              freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                              recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                              subServiceType={this.props.subServiceType}
                                              appId={this.props.appUid} solId={this.props.solId}
                                              accountNumber={this.props.accountNumber}
                                              cbNumber={this.props.cbNumber} caseId={this.props.CaseId}/>)

        }
    };

    adc = () => {
        if (this.props.serviceType === "adc_service") {
            return (<AdcService closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                subServiceType={this.props.subServiceType}
                                subCategory={this.props.subCategory}
                                titleName="New Account Opening"
                                commonJsonForm={AdcJsonFormForCS}
                                appId={this.props.appUid} caseId={this.props.CaseId}/>)

        }
    };
    card = () => {
        console.log('i am in card function');

        return (<MaintenanceRequest closeModal={this.props.closeModal}
                                    serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                    freeFlag2={this.props.freeFlag2}
                                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                    recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                    delStatus={this.props.delStatus}
                                    subCategory={this.props.subCategory} category={this.props.category}
                                    department="CASA" subServiceType={this.props.subServiceType}
                                    titleName={this.props.subserviceType}
                                    commonJsonForm={SearchFormNonIndividual}
                                    appId={this.props.appUid} solId={this.props.solId}
                                    accountNumber={this.props.accountNumber}
                                    cbNumber={this.props.cbNumber} caseId={this.props.CaseId} headerValue={"inbox"}/>)
    };
    casa = () => {

        if (this.props.serviceType === "Account Opening" && (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C") && this.props.taskTitle === 'cs_data_capture') {
            return (<Liability serviceList={CSJsonFormForCasaService}
                               serviceListLast={CSJsonFormForCasaServiceLast} closeModal={this.props.closeModal}
                               closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                               delStatus={this.props.delStatus}
                               subCategory={this.props.subCategory} category={this.props.category} department="CASA"
                               subServiceType={this.props.subServiceType}
                               subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                               commonJsonForm={CSJsonFormForCasaCompany}
                               commonJsonFormSave={CSJsonFormForCasaIndividual}
                               appId={this.props.appUid} caseId={this.props.CaseId}/>)

        } else if (this.props.serviceType === "Account Opening" && this.props.subServiceType === "Joint Account" && this.props.taskTitle === 'cs_data_capture') {
            return (<Liability serviceList={CSJsonFormForCasaService}
                               serviceListLast={CSJsonFormForCasaServiceLast} closeModal={this.props.closeModal}
                               closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                               delStatus={this.props.delStatus}
                               subCategory={this.props.subCategory} category={this.props.category} department="CASA"
                               subServiceType={this.props.subServiceType}
                               subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                               commonJsonForm={CSJsonFormForCasaCompany}
                               appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else if (this.props.serviceType === "Account Opening" && (this.props.subServiceType === "Proprietorship A/C" || this.props.subServiceType === "NONINDIVIDUAL") && this.props.taskTitle === 'cs_data_capture') {
            return (<Liability serviceList={CSJsonFormForCasaService}
                               serviceListLast={CSJsonFormForCasaServiceLast} closeModal={this.props.closeModal}
                               closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                               delStatus={this.props.delStatus}
                               subCategory={this.props.subCategory} category={this.props.category} department="CASA"
                               subServiceType={this.props.subServiceType}
                               subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                               commonJsonForm={CSJsonFormForCasaCompany}
                               appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else if (this.props.serviceType === "Account Opening" && this.props.subServiceType === "Company Account" && this.props.taskTitle === 'cs_data_capture') {

            return (<Liability serviceList={CSJsonFormForCasaService}
                               serviceListLast={CSJsonFormForCasaServiceLast} closeModal={this.props.closeModal}
                               closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                               delStatus={this.props.delStatus}
                               subCategory={this.props.subCategory} category={this.props.category} department="CASA"
                               subServiceType={this.props.subServiceType}
                               subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                               commonJsonForm={CSJsonFormForCasaCompany}
                               appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else if (this.props.serviceType === "Account Opening" && this.props.subServiceType === "Others" && this.props.taskTitle === 'cs_data_capture') {
            return (<Liability serviceList={CSJsonFormForCasaService}
                               serviceListLast={CSJsonFormForCasaServiceLast} closeModal={this.props.closeModal}
                               closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                               delStatus={this.props.delStatus}
                               subCategory={this.props.subCategory} category={this.props.category} department="CASA"
                               subServiceType={this.props.subServiceType}
                               subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                               commonJsonForm={SearchFormNonIndividual}
                               appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else if (this.props.serviceType === "Account Opening" && this.props.taskTitle === 'cs_case_rejected') {

            return (<CSRejectedPage serviceList={CSJsonFormForCasaService}
                                    serviceListLast={CSJsonFormForCasaServiceLast} closeModal={this.props.closeModal}
                                    closeIcon={this.props.closeIcon}
                                    serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                    freeFlag2={this.props.freeFlag2}
                                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                    recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                    delStatus={this.props.delStatus}
                                    subCategory={this.props.subCategory} category={this.props.category}
                                    department="CASA" subServiceType={this.props.subServiceType}
                                    subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                                    commonJsonForm={SearchFormNonIndividual}
                                    appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else if (this.props.serviceType === "internet_banking" || (this.props.serviceType === "adc_service" && this.props.freeFlag3 === "city_touch")) {
            return (<Liability serviceList={CSJsonFormForCasaService}
                               serviceListLast={CSJsonFormForCasaServiceLast} closeModal={this.props.closeModal}
                               closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                               delStatus={this.props.delStatus}
                               subCategory={this.props.subCategory} category={this.props.category}
                               department="CASA" subServiceType={this.props.subServiceType}
                               subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                               commonJsonForm={AdcJsonFormForVerify}
                               appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else {
            // alert("Internal Problem")
        }
    };

    casaExist = () => {
        if (this.props.serviceType === 'ExistAccountOpening' && (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C")) {

            return (<Liability getCustomerId={this.props.getCustomerId} accountNumber={this.props.accountNumber}
                               agentBanking={this.props.agentBanking}
                               serviceListLast={CSJsonFormForCasaServiceLastExist}
                               serviceList={CSJsonFormForCasaServiceExist} closeModal={this.props.closeModal}
                               closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                               delStatus={this.props.delStatus}
                               subCategory={this.props.subCategory} category={this.props.category} department="CASA"
                               subServiceType={this.props.subServiceType}
                               subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                               commonJsonForm={CSJsonFormForCasaIndividualExist}
                               appId={this.props.appUid} caseId={this.props.CaseId}/>)

        } else if (this.props.serviceType === 'ExistAccountOpening' && this.props.subServiceType === "Joint Account") {
            return (<Liability getCustomerId={this.props.getCustomerId} accountNumber={this.props.accountNumber}
                               agentBanking={this.props.agentBanking}
                               serviceListLast={CSJsonFormForCasaServiceLastExist}
                               serviceList={CSJsonFormForCasaServiceExist} closeModal={this.props.closeModal}
                               closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                               delStatus={this.props.delStatus}
                               subCategory={this.props.subCategory} category={this.props.category} department="CASA"
                               subServiceType={this.props.subServiceType}
                               subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                               commonJsonForm={CSJsonFormForCasaJointExist}
                               appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else if (this.props.serviceType === 'ExistAccountOpening' && (this.props.subServiceType === "Proprietorship A/C" || this.props.subServiceType === "NONINDIVIDUAL")) {
            return (<Liability getCustomerId={this.props.getCustomerId} accountNumber={this.props.accountNumber}
                               agentBanking={this.props.agentBanking}
                               serviceListLast={CSJsonFormForCasaServiceLastExist}
                               serviceList={CSJsonFormForCasaServiceExist} closeModal={this.props.closeModal}
                               closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                               delStatus={this.props.delStatus}
                               subCategory={this.props.subCategory} category={this.props.category} department="CASA"
                               subServiceType={this.props.subServiceType}
                               subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                               commonJsonForm={CSJsonFormForCasaProprietorshipExist}
                               appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else if (this.props.serviceType === 'ExistAccountOpening' && this.props.subServiceType === "Company Account") {

            return (<Liability getCustomerId={this.props.getCustomerId} accountNumber={this.props.accountNumber}
                               agentBanking={this.props.agentBanking}
                               serviceListLast={CSJsonFormForCasaServiceLastExist}
                               serviceList={CSJsonFormForCasaServiceExist} closeModal={this.props.closeModal}
                               closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                               delStatus={this.props.delStatus}
                               subCategory={this.props.subCategory} category={this.props.category} department="CASA"
                               subServiceType={this.props.subServiceType}
                               subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                               commonJsonForm={CSJsonFormForCasaCompanyExist}
                               appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else if (this.props.serviceType === 'ExistAccountOpening' && this.props.subServiceType === "Others") {
            return (<Liability getCustomerId={this.props.getCustomerId} accountNumber={this.props.accountNumber}
                               agentBanking={this.props.agentBanking}
                               serviceListLast={CSJsonFormForCasaServiceLastExist}
                               serviceList={CSJsonFormForCasaServiceExist} closeModal={this.props.closeModal}
                               closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                               delStatus={this.props.delStatus}
                               subCategory={this.props.subCategory} category={this.props.category} department="CASA"
                               subServiceType={this.props.subServiceType}
                               subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                               commonJsonForm={SearchFormNonIndividual}
                               appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else if (this.props.serviceType === 'ExistAccountOpening' && this.props.taskTitle === 'cs_case_rejected') {

            return (<CSRejectedPage serviceListLast={CSJsonFormForCasaServiceLastExist}
                                    serviceList={CSJsonFormForCasaServiceExist} closeModal={this.props.closeModal}
                                    closeIcon={this.props.closeIcon}
                                    serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                    freeFlag2={this.props.freeFlag2}
                                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                    recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                    delStatus={this.props.delStatus}
                                    subCategory={this.props.subCategory} category={this.props.category}
                                    department="CASA" subServiceType={this.props.subServiceType}
                                    subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                                    commonJsonForm={SearchFormNonIndividual}
                                    appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else if (this.props.serviceType === "internet_banking") {

            return (<Liability getCustomerId={this.props.getCustomerId} accountNumber={this.props.accountNumber}
                               agentBanking={this.props.agentBanking}
                               serviceListLast={CSJsonFormForCasaServiceLastExist}
                               serviceList={CSJsonFormForCasaServiceExist} closeModal={this.props.closeModal}
                               closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                               delStatus={this.props.delStatus}
                               subCategory={this.props.subCategory} category={this.props.category} department="CASA"
                               subServiceType={this.props.subServiceType}
                               subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                               commonJsonForm={AdcJsonFormForVerify}
                               appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else {
            alert("Internal Problem")
        }
    };

    fdr = () => {

        if (this.props.freeFlag1 === 'FDR Account Opening - with Existing CB Only') {
            return (<OpeningCS closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                               cbonly={true}
                               serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                               delStatus={this.props.delStatus}
                               subCategory={this.props.subCategory} category={this.props.category}
                               subserviceType={this.props.subServiceType}
                               serviceList={CSJsonFormForFDRServiceForExistCbOnly}
                               serviceListLast={CSJsonFormForFDRServiceLast}
                               commonJsonForm={CSJsonFormForNewWithExistCB}
                               titleName="Individual FDR Opening"
                               appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else if (this.props.freeFlag1 === 'FDR Account Opening -Without Operative Account-NTB') {
            return (<OpeningCS closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                               delStatus={this.props.delStatus}
                               subCategory={this.props.subCategory} category={this.props.category}
                               subserviceType={this.props.subServiceType}
                               serviceList={CSJsonFormForFDRServiceNew}
                               serviceListLast={CSJsonFormForFDRServiceLast}
                               commonJsonForm={CSJsonFormForCasaIndividualFdr}
                               commonJsonFormLast={CSJsonFormForCasaIndividualFdrLast}
                               titleName="Individual FDR Opening"
                               appId={this.props.appUid} caseId={this.props.CaseId}/>)

        }
        /*  else if (this.props.serviceType === 'FDR Opening' && this.props.subServiceType === 'Joint Account') {
              return (<OpeningCS closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                   serviceType={this.props.serviceType}  freeFlag1={this.props.freeFlag1}
                             freeFlag2={this.props.freeFlag2}
                             freeFlag3={this.props.freeFlag3}  freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}  customerName={this.props.customerName}
                                     delStatus={this.props.delStatus}  subCategory={this.props.subCategory} category={this.props.category}
                                 subserviceType={this.props.subServiceType}
                                 serviceList={CSJsonFormForFDRService}
                                 serviceListLast={CSJsonFormForFDRServiceLast}
                                 commonJsonForm={CSJsonFormForCasaIndividualFdr}
                                 titleName="Non Individual FDR Opening"
                                 appId={this.props.appUid}  caseId={this.props.CaseId}/>)
          } */
        else if (this.props.freeFlag1 === 'FDR Account Opening - with Existing Operative Account') {
            return (<AccountEdit closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                 serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                 freeFlag2={this.props.freeFlag2}
                                 freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                 recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                 delStatus={this.props.delStatus}
                                 subCategory={this.props.subCategory} category={this.props.category}
                                 subserviceType={this.props.subServiceType}
                                 commonJsonForm={CSJsonFormForCasaIndividualTagFdr}
                                 serviceList={CSJsonFormForFDRService}
                                 serviceListLast={CSJsonFormForFDRServiceLast}
                                 title="Existing operative Account"
                                 appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else if (this.props.serviceType === 'DPS Opening' && this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account') {
            return (<AccountEdit closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                 serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                 freeFlag2={this.props.freeFlag2}
                                 freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                 recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                 delStatus={this.props.delStatus}
                                 subCategory={this.props.subCategory} category={this.props.category}
                                 subserviceType={this.props.subServiceType}
                                 title="DPS Account Opening"
                                 commonJsonForm={CSJsonFormForCasaIndividualDPS}
                                 serviceListLast={CSJsonFormForDPSService}
                                 serviceList={CSJsonFormForCasaIndividualTagDPS}
                                 titleName="DPS Opening"
                                 appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else if (this.props.serviceType === 'DPS Opening' || this.props.serviceType === 'FDR Opening') {
            return (<Liability serviceList={CSJsonFormForCasaService}
                               serviceListLast={CSJsonFormForCasaServiceLast} closeModal={this.props.closeModal}
                               closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                               delStatus={this.props.delStatus}
                               subCategory={this.props.subCategory} category={this.props.category} department="CASA"
                               subServiceType={this.props.subServiceType}
                               subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                               commonJsonForm={CSJsonFormForCasaIndividual}
                               commonJsonFormSave={CSJsonFormForCasaIndividual}
                               appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else {
            return (<OpeningCS closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                               delStatus={this.props.delStatus}
                               subCategory={this.props.subCategory} category={this.props.category}
                               subserviceType={this.props.subServiceType}
                               serviceList={CSJsonFormForFDRService}
                               serviceListLast={CSJsonFormForFDRServiceLast}
                               commonJsonForm={CSJsonFormForCasaIndividualFdr}
                               titleName="Non Individual FDR Opening"
                               appId={this.props.appUid} caseId={this.props.CaseId}/>)

        }
    };

    chequeBook = () => {

        if (this.props.serviceType === "Debit Card" && this.props.subServiceType === "Chequebook" && this.props.freeFlag1 === "FDD" ) {
            console.log("i am in cheque book function");
            return (<ChequeBookInboxs closeModal={this.props.closeModal}
                                     serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                     freeFlag2={this.props.freeFlag2}
                                     freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                     recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                     customerName={this.props.customerName}
                                     cbNumber={this.props.cbNumber}
                                     accountNumber={this.props.accountNumber}
                                     solId={this.props.solId}
                                     subCategory={this.props.subCategory} category={this.props.category}
                                     subServiceType={this.props.subServiceType}
                                     appId={this.props.appUid} solId={this.props.solId}
                                     accountNumber={this.props.accountNumber}
                                     cbNumber={this.props.cbNumber} caseId={this.props.CaseId}/>)
        }
        else if (this.props.serviceType === "Debit Card" && this.props.subServiceType === "Chequebook") {
            console.log("i am in cheque book function");
            return (<ChequeBookInbox closeModal={this.props.closeModal}
                                     serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                     freeFlag2={this.props.freeFlag2}
                                     freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                     recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                     customerName={this.props.customerName}
                                     cbNumber={this.props.cbNumber}
                                     accountNumber={this.props.accountNumber}
                                     solId={this.props.solId}
                                     subCategory={this.props.subCategory} category={this.props.category}
                                     subServiceType={this.props.subServiceType}
                                     appId={this.props.appUid} caseId={this.props.CaseId}/>)
        }
    };

    emiChange = () => {
        if (this.props.category === "EMI") {
            return (<CsoRequisition closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                    serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                    freeFlag2={this.props.freeFlag2}
                                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                    recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                    subServiceType={this.props.subServiceType}
                                    titleName={this.props.serviceType}
                                    appId={this.props.appUid} solId={this.props.solId}
                                    accountNumber={this.props.accountNumber}
                                    cbNumber={this.props.cbNumber} caseId={this.props.CaseId}/>)

        }
    };

    loanAccountTenorChange = () => {
        if (this.props.subCategory === "Tenor Change") {
            return (<CsoRequisitionTenor closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                         serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                         freeFlag2={this.props.freeFlag2}
                                         freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                         recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                         subServiceType={this.props.subServiceType}
                                         titleName={this.props.serviceType}
                                         appId={this.props.appUid} solId={this.props.solId}
                                         accountNumber={this.props.accountNumber}
                                         cbNumber={this.props.cbNumber} caseId={this.props.CaseId} flowSummery={true}/>)
        }
    };

    interestTransfer = () => {
        if (this.props.subServiceType === "Other Service Request") {
            return (<CsoRequisitionInterestTransfer closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                                    serviceType={this.props.serviceType}
                                                    freeFlag1={this.props.freeFlag1}
                                                    freeFlag2={this.props.freeFlag2}
                                                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                                    recpmtid={this.props.recpmtid}
                                                    customerName={this.props.customerName}
                                                    subServiceType={this.props.subServiceType}
                                                    titleName={this.props.serviceType}
                                                    appId={this.props.appUid} solId={this.props.solId}
                                                    accountNumber={this.props.accountNumber}
                                                    cbNumber={this.props.cbNumber} caseId={this.props.CaseId}/>)

        }
    };

    cardChequeBook = () => {

        if (this.props.serviceType === "Credit Card" && this.props.subServiceType === "Chequebook") {
            return (<CreditCardChequeBookRequisition closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                                     serviceType={this.props.serviceType}
                                                     freeFlag1={this.props.freeFlag1}
                                                     freeFlag2={this.props.freeFlag2}
                                                     freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                                     recpmtid={this.props.recpmtid}
                                                     customerName={this.props.customerName}
                                                     accountNumber={this.props.accountNumber}
                                                     CaseId={this.props.CaseId}
                                                     subCategory={this.props.subCategory} category={this.props.category}
                                                     subServiceType={this.props.subServiceType}
                                                     appId={this.props.appUid} caseId={this.props.CaseId}/>)

        }
    };

    debitCard = () => {

        if (this.props.serviceType === "Debit Card" && this.props.subServiceType === "Requisition") {
            return (<DebitCardRequisition closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                          serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                          freeFlag2={this.props.freeFlag2}
                                          freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                          recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                          customerName={this.props.customerName}
                                          cbNumber={this.props.cbNumber}
                                          accountNumber={this.props.accountNumber}
                                          solId={this.props.solId}
                                          subCategory={this.props.subCategory} category={this.props.category}
                                          subServiceType={this.props.subServiceType}
                                          appId={this.props.appUid} caseId={this.props.CaseId}/>)
        }
    };

    instaPack = () => {

        if (this.props.serviceType === "Debit Card" && this.props.subServiceType === "Instapack") {
            return (<InstaPackRequisition closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                          serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                          freeFlag2={this.props.freeFlag2}
                                          freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                          recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                          subCategory={this.props.subCategory} category={this.props.category}
                                          subServiceType={this.props.subServiceType}
                                          appId={this.props.appUid} caseId={this.props.CaseId}/>)

        }
    };

    instaDebitCardReq = () => {

        if (this.props.serviceType === "Debit Card" && this.props.subServiceType === "InstaDebitCard" && this.props.subCategory === "Bulk Insta Debit Card") {
            return (<InstaDebitCardRequisition closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                               serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                               freeFlag2={this.props.freeFlag2}
                                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                               subCategory={this.props.subCategory} category={this.props.category}
                                               subServiceType={this.props.subServiceType}
                                               appId={this.props.appUid} caseId={this.props.CaseId}/>)

        }
    };

    cardCheque = () => {
        if (this.props.serviceType === 'Card Cheque' && (this.props.subServiceType === "Card Cheque Request")) {

            return (<CsOpening closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                               delStatus={this.props.delStatus}
                               subCategory={this.props.subCategory} category={this.props.category}
                               subServiceType={this.props.subServiceType}
                               titleName="BOM Opening"
                               appId={this.props.appUid} caseId={this.props.CaseId}/>)

        }
    };
    sanchoypatra = () => {
        if (this.props.serviceType === "bond_issuance_serve" && this.props.subServiceType === "5 Years Bangladesh Sanchaya Patra") {
            return (<CSInbox closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                             serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                             freeFlag2={this.props.freeFlag2}
                             freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                             recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                             delStatus={this.props.delStatus}
                             subCategory={this.props.subCategory} category={this.props.category} department="BOND"
                             subServiceType={this.props.subServiceType}
                             subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                             commonJsonForm={CSJsonFormForBsp}
                             appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else if (this.props.serviceType === "bond_issuance_serve" && this.props.subServiceType === "3 Months Interest Based Sanchaya Patra") {
            return (<CSInbox closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                             serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                             freeFlag2={this.props.freeFlag2}
                             freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                             recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                             delStatus={this.props.delStatus}
                             subCategory={this.props.subCategory} category={this.props.category} department="BOND"
                             subServiceType={this.props.subServiceType}
                             subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                             commonJsonForm={CSJsonFormFor3ms}
                             appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else if (this.props.serviceType === "bond_issuance_serve" && this.props.subServiceType === "Family Sanchaya Patra") {
            return (<CSInbox closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                             serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                             freeFlag2={this.props.freeFlag2}
                             freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                             recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                             delStatus={this.props.delStatus}
                             subCategory={this.props.subCategory} category={this.props.category} department="BOND"
                             subServiceType={this.props.subServiceType}
                             subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                             commonJsonForm={CSJsonFormForFsp}
                             appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else if (this.props.serviceType === "bond_issuance_serve" && this.props.subServiceType === "Pensioners Savings Certificate") {
            return (<CSInbox closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                             serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                             freeFlag2={this.props.freeFlag2}
                             freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                             recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                             delStatus={this.props.delStatus}
                             subCategory={this.props.subCategory} category={this.props.category} department="BOND"
                             subServiceType={this.props.subServiceType}
                             subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                             commonJsonForm={CSJsonFormForPsc}
                             appId={this.props.appUid} caseId={this.props.CaseId}/>)

        }

    };
    maintenance = () => {
        if (this.props.serviceType === 'Maintenance' && this.props.taskTitle === 'cs_maintenance' && this.props.subServiceType === "AccountMaintenance" && this.props.freeFlag2 === "linkedCB") {
            return (<LinkedAccountMaintenance closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                              serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                              freeFlag2={this.props.freeFlag2}
                                              cbNumber={this.props.cbNumber}
                                              accountNumber={this.props.accountNumber}
                                              solId={this.props.freeFlag3}
                                              caseId={this.props.CaseId}
                                              customerName={this.props.customerName}
                                              freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                              recpmtid={this.props.recpmtid} department="CASA"
                                              taskTitle={this.props.taskTitle}
                                              subServiceType={this.props.subServiceType} titleName="Account Maintenance"
                                              maintenanceType={this.props.maintenanceType} appId={this.props.appUid}
                />
            )

        } else if (this.props.serviceType === 'Maintenance' && this.props.taskTitle === 'cs_maintenance' && this.props.subServiceType === "AccountMaintenance" && this.props.category === "CASA Closer") {
            return (<CloserMaintenance closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                       serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                       freeFlag2={this.props.freeFlag2} solId={this.props.solId}
                                       accountNumber={this.props.accountNumber} cbNumber={this.props.cbNumber}
                                       freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                       recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                       department="CASA"
                                       category={this.props.category}
                                       subServiceType={this.props.subServiceType} titleName="Account Maintenance"
                                       maintenanceType={this.props.maintenanceType}
                                       appId={this.props.appUid} caseId={this.props.CaseId}/>
            )

        } else if (this.props.serviceType === 'Maintenance' && this.props.taskTitle === 'cs_maintenance' && this.props.subServiceType === "AccountMaintenance" && (this.props.category === "DPS Closer" || this.props.category === "Deceased DPS Closer")) {
            return (<DPSCloserMaintenance closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                          serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                          freeFlag2={this.props.freeFlag2}
                                          freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                          recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                          category={this.props.category}
                                          subServiceType={this.props.subServiceType} titleName="Account Maintenance"
                                          maintenanceType={this.props.maintenanceType}
                                          appId={this.props.appUid} caseId={this.props.CaseId}/>
            )

        } else if (this.props.serviceType === 'Maintenance' && this.props.taskTitle === 'cs_maintenance' && this.props.subServiceType === "AccountMaintenance" && this.props.category === "DPS Maintenance") {
            return (<DPSCloserMaintenanceReturn closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                                serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                                freeFlag2={this.props.freeFlag2}
                                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                                category={this.props.category}
                                                subServiceType={this.props.subServiceType}
                                                titleName="Account Maintenance"
                                                maintenanceType={this.props.maintenanceType}
                                                appId={this.props.appUid} caseId={this.props.CaseId}/>
            )

        } else if (this.props.serviceType === 'Maintenance' && this.props.taskTitle === 'cs_maintenance' && this.props.subServiceType === "AccountMaintenance") {
            return (<AccountMaintenance closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                        serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                        freeFlag2={this.props.freeFlag2}
                                        freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                        recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                        cbNumber={this.props.cbNumber}
                                        accountNumber={this.props.accountNumber}
                                        solId={this.props.freeFlag3}
                                        caseId={this.props.CaseId}
                                        department="CASA" taskTitle={this.props.taskTitle}
                                        subServiceType={this.props.subServiceType} titleName="Account Maintenance"
                                        maintenanceType={this.props.maintenanceType}
                                        appId={this.props.appUid}/>
            )

        } else if (this.props.serviceType === 'Maintenance' && this.props.taskTitle === 'cs_maintenance' && this.props.subServiceType === "FDRMaintenance" && this.props.freeFlag2 !== "FDR Encashment") {
            return (<FDRMaintenance closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                    serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                    freeFlag2={this.props.freeFlag2}
                                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                    recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                    department="CASA"
                                    subServiceType={this.props.subServiceType} titleName="FDR Maintenance"
                                    appId={this.props.appUid} caseId={this.props.CaseId}/>)

        } else if (this.props.serviceType === 'Maintenance' && this.props.subServiceType === "FDRMaintenance" && this.props.freeFlag2 === "FDR Encashment") {
            return (<FdrEncashment closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                   serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                   freeFlag2={this.props.freeFlag2}
                                   freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                   recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                   department="CASA"
                                   caseId={this.props.CaseId}
                                   subServiceType={this.props.subServiceType} titleName="FDR Maintenance"
                                   appId={this.props.appUid}/>)

        } else if (this.props.serviceType === 'Maintenance' && this.props.taskTitle === 'cs_maintenance' && this.props.subServiceType === "MandateMaintenance") {
            return (<MandateApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                     serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                     freeFlag2={this.props.freeFlag2}
                                     freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                     recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                     cbNumber={this.props.cbNumber}
                                     accountNumber={this.props.accountNumber}
                                     solId={this.props.freeFlag3}
                                     department="CASA" taskTitle={this.props.taskTitle}
                                     subServiceType={this.props.subServiceType} titleName="FDR Maintenance"
                                     appId={this.props.appUid} caseId={this.props.CaseId}/>)

        }
    };
    Encashment = () => {

        if (this.props.serviceType === "bond_issuance_serve") {
            return (<CSInbox closeModal={this.props.closeModal}
                             serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                             freeFlag2={this.props.freeFlag2}
                             freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                             recpmtid={this.props.recpmtid} customerName={this.props.customerName}

                             delStatus={this.props.delStatus}
                             subCategory={this.props.subCategory} category={this.props.category} department="BOND"
                             subServiceType={this.props.subServiceType}
                             titleName={this.props.subServiceType}
                             commonJsonForm={CSInterestPayment}
                             appId={this.props.appUid} caseId={this.props.CaseId}/>)
        }
    };

    acesEnrollmentMaintenance = () => {

        if (this.props.serviceType === "bond_issuance_serve") {
            return (<CSEnrollmentInbox closeModal={this.props.closeModal}
                                       serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                       freeFlag2={this.props.freeFlag2}
                                       freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                       recpmtid={this.props.recpmtid} customerName={this.props.customerName}

                                       delStatus={this.props.delStatus}
                                       subCategory={this.props.subCategory} category={this.props.category}
                                       department="BOND"
                                       subServiceType={this.props.subServiceType}
                                       titleName={this.props.subServiceType}
                                       commonJsonForm={CSJsonFromForNomineeChange}
                                       appId={this.props.appUid} caseId={this.props.CaseId}/>)
        }

    };

    issuance = () => {
        if (this.props.serviceType === "bond_issuance_serve" && this.props.freeFlag3 === "ISSUANCE") {
            return (<CSInbox closeModal={this.props.closeModal}
                             serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                             freeFlag2={this.props.freeFlag2}
                             freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                             recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                             delStatus={this.props.delStatus}
                             subCategory={this.props.subCategory} category={this.props.category} department="BOND"
                             subServiceType={this.props.subServiceType}
                             titleName={this.props.subServiceType}
                             commonJsonForm={CSJsonFormForBondWegDevelopmentBond}
                             appId={this.props.appUid} caseId={this.props.CaseId}/>)
        }
    };

    renderRemitance = () => {
        console.log(this.props.subServiceType);
        if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'Student File') {
            return (<CSApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                delStatus={this.props.delStatus}
                                subCategory={this.props.subCategory} category={this.props.category}
                                subServiceType={this.props.subServiceType}
                                titleName="Outward Remittance Information (Student File)"
                                commonJsonForm={StudentFileJsonForm}
                                appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'Medical File') {
            return (<CSApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                delStatus={this.props.delStatus}
                                subCategory={this.props.subCategory} category={this.props.category}
                                subServiceType={this.props.subServiceType}
                                titleName="Outward Remittance Information (Medical File)"
                                commonJsonForm={MedicalFileJsonForm}
                                appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'Other Service') {
            return (<CSApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                delStatus={this.props.delStatus}
                                subCategory={this.props.subCategory} category={this.props.category}
                                subServiceType={this.props.subServiceType}
                                titleName="Outward Remittance Information (Other Service)"
                                commonJsonForm={RemittanceServiceOtherJsonForm}
                                appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'FTT') {
            return (<CSApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                delStatus={this.props.delStatus}
                                subCategory={this.props.subCategory} category={this.props.category}
                                subServiceType={this.props.subServiceType}
                                commonJsonForm={CSfddJasonFormRemitance}
                                titleName="Outward Remittance Information"
                                appId={this.props.appUid} caseId={this.props.CaseId}/>)

        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'FDD') {
            return (<CSApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                delStatus={this.props.delStatus}
                                subCategory={this.props.subCategory} category={this.props.category}
                                subServiceType={this.props.subServiceType}
                                commonJsonForm={CSfddJasonFormRemitance}
                                titleName="Outward Remittance Information"
                                appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'Inward') {
            return (<CSApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                subServiceType={this.props.subServiceType}
                                commonJsonForm={CSinWardJasonFormRemitance}
                                titleName="Inward Remittance Information"
                                appId={this.props.appUid} caseId={this.props.CaseId}/>)
        }
    };


    agentOpening = () => {

        if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C") {

            return (<AgentBanking closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                  serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                  freeFlag2={this.props.freeFlag2}
                                  freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                  recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                  delStatus={this.props.delStatus}
                                  subCategory={this.props.subCategory} category={this.props.category} department="BOND"
                                  subServiceType={this.props.subServiceType}
                                  subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                                  commonJsonForm={AbhMakerJsonFormForAgentIndividual}
                                  appId={this.props.appUid} caseId={this.props.CaseId}/>)
        } else if (this.props.subServiceType === "Company Account") {
            return (<AgentBanking closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                  serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                  freeFlag2={this.props.freeFlag2}
                                  freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                  recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                  delStatus={this.props.delStatus}
                                  subCategory={this.props.subCategory} category={this.props.category} department="BOND"
                                  subServiceType={this.props.subServiceType}
                                  subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                                  commonJsonForm={AbhMakerJsonFormForAgentNonIndividual}
                                  appId={this.props.appUid} caseId={this.props.CaseId}/>)


        }
    };
    fundTransfer = () => {
        if (this.props.serviceType === 'FundTransfer' && (this.props.subServiceType === "NewFundTransfer")) {
            return (<FundInbox closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                               delStatus={this.props.delStatus}
                               subCategory={this.props.subCategory} category={this.props.category}
                               subServiceType={this.props.subServiceType}
                               titleName="Fund Transfer Request"
                               cbNumber={this.props.cbNumber}
                               accountNumber={this.props.accountNumber}
                               solId={this.props.solId}
                               appId={this.props.appUid} caseId={this.props.CaseId}/>)

        }
    };

    renderLockerClosing = () => {
        if (this.props.subServiceType === 'LockerClosing') {
            return (
                <LockerClosing
                    closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                    serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                    freeFlag2={this.props.freeFlag2}
                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}
                    customerName={this.props.customerName}

                    subCategory={this.props.subCategory}
                    category={this.props.category}
                    subServiceType={this.props.subServiceType}
                    titleName="Locker Closing"
                    appId={this.props.appUid} caseId={this.props.CaseId}/>)
        }
    };

    renderLockerMaintenance = () => {
        if (this.props.subServiceType === 'LockerMaintenance') {
            return (
                <CsLockerMainentance
                    closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                    serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                    freeFlag2={this.props.freeFlag2}
                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}
                    customerName={this.props.customerName}

                    subCategory={this.props.subCategory}
                    category={this.props.category}
                    subServiceType={this.props.subServiceType}
                    titleName="Locker Maintenance"
                    appId={this.props.appUid} caseId={this.props.CaseId}/>)
        }
    };
    instaDebitCard = () => {
        return (<InstaDebitCardRequest closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                       serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                       freeFlag2={this.props.freeFlag2}
                                       freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                       recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                       customerName={this.props.customerName}
                                       cbNumber={this.props.cbNumber}
                                       accountNumber={this.props.accountNumber}
                                       solId={this.props.solId}
                                       delStatus={this.props.delStatus}
                                       subCategory={this.props.subCategory} category={this.props.category}
                                       department="CASA" subServiceType={this.props.subServiceType}
                                       subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                                       commonJsonForm={CSJsonFormForCasaIndividual}
                                       appId={this.props.appUid} caseId={this.props.CaseId}/>)


    };


    loan = () => {

        if (this.props.taskTitle === 'cso_doc_collection') {

            return (
                <CsFormLoanClosing closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                   serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                   freeFlag2={this.props.freeFlag2}
                                   freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                   recpmtid={this.props.recpmtid} customerName={this.props.customerName}

                                   subServiceType={this.props.subServiceType}
                                   titleName="CS after BM Approval"
                                   jsonForm={closerRequestFormBm}
                                   appId={this.props.appUid} solId={this.props.solId}
                                   accountNumber={this.props.accountNumber}
                                   cbNumber={this.props.cbNumber} caseId={this.props.CaseId}/>


            )
        } else if (this.props.taskTitle === 'cso_case_start') {
            return (
                <CsFormLoanClosing closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                   serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                   freeFlag2={this.props.freeFlag2}
                                   freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                   recpmtid={this.props.recpmtid} customerName={this.props.customerName}

                                   subServiceType={this.props.subServiceType}
                                   titleName="CS after BM Approval"
                                   jsonForm={closerRequestFormBm}
                                   appId={this.props.appUid} solId={this.props.solId}
                                   accountNumber={this.props.accountNumber}
                                   cbNumber={this.props.cbNumber} caseId={this.props.CaseId}/>

            )

        } else if (this.props.taskTitle === 'cso_sro_token_generator') {
            return (
                <CSOAfterHLD closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                             serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                             freeFlag2={this.props.freeFlag2}
                             freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                             recpmtid={this.props.recpmtid} customerName={this.props.customerName}

                             subServiceType={this.props.subServiceType}
                             titleName="CS after BM Approval"
                             jsonForm={closerRequestFormBm}
                             appId={this.props.appUid} solId={this.props.solId}
                             accountNumber={this.props.accountNumber}
                             cbNumber={this.props.cbNumber} caseId={this.props.CaseId}/>

            )
        }


    };

    securedLoan = () => {
        console.log(this.props)
        if (this.props.taskTitle === 'cs_case_start_secured_loan_closing') {
            return (
                <CsSecuredLoanClosing closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                      serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                      freeFlag2={this.props.freeFlag2}
                                      freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                      recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                      subServiceType={this.props.subServiceType}
                                      titleName="CS after BM Approval"
                                      jsonForm={closerRequestFormBm}
                                      appId={this.props.appUid} solId={this.props.solId}
                                      accountNumber={this.props.accountNumber}
                                      cbNumber={this.props.cbNumber} caseId={this.props.CaseId}/>

            )
        } else if (this.props.taskTitle === 'cs_after_bm_secured_loan_closing') {
            return (
                <CsSecuredLoanClosing closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                      serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                      freeFlag2={this.props.freeFlag2}
                                      freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                      recpmtid={this.props.recpmtid} customerName={this.props.customerName}

                                      subServiceType={this.props.subServiceType}
                                      titleName="CS after BM Approval"
                                      jsonForm={closerRequestFormBm}
                                      appId={this.props.appUid} solId={this.props.solId}
                                      accountNumber={this.props.accountNumber}
                                      cbNumber={this.props.cbNumber} caseId={this.props.CaseId}/>

            )

        } else if (this.props.taskTitle === 'cs_after_maker_secured_loan_closing') {
            return (
                <CsAfterMakerSecuredLoanClosing closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                                serviceType={this.props.serviceType}

                                                subServiceType={this.props.subServiceType}
                                                titleName="CS Approval after Maker"
                                                jsonForm={closerRequestFormBm}
                                                customerName={this.props.customerName}
                                                subCategory={this.props.subCategory}
                                                category={this.props.category}
                                                subServiceType={this.props.subServiceType}
                                                caseId={this.props.CaseId}
                                                solId={this.props.solId}
                                                accountNumber={this.props.accountNumber}
                                                cbNumber={this.props.cbNumber} appId={this.props.appUid}/>

            )
        }


    };


    deceasedMark = () => {
        return (
            <CsDeceasedMark closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}

                                subServiceType={this.props.subServiceType}
                                titleName="BM Approval"
                                jsonForm={closerRequestFormBm}
                                appId={this.props.appUid} solId={this.props.solId}
                                accountNumber={this.props.accountNumber}
                                cbNumber={this.props.cbNumber} caseId={this.props.CaseId}/>

        )
    };

    waverRequest = () => {
        return (
            <CrFormWaverRequest closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}

                                subServiceType={this.props.subServiceType}
                                titleName="BM Approval"
                                jsonForm={closerRequestFormBm}
                                appId={this.props.appUid} solId={this.props.solId}
                                accountNumber={this.props.accountNumber}
                                cbNumber={this.props.cbNumber} caseId={this.props.CaseId}/>

        )
    };

    casaOtherMaintenance = () => {
        if (this.props.freeFlag1 === 'CASA Other Maintenance' && this.props.serviceType === "Maintenance") {
            return (<Others closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                            serviceType={this.props.serviceType} freeFlag1={this.props.freeFlag1}
                            freeFlag2={this.props.freeFlag2}
                            freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                            recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                            taskTitle={this.props.taskTitle}

                            subServiceType={this.props.subServiceType}
                            titleName="CASA Static Other Maintenance"
                            appId={this.props.appUid} caseId={this.props.CaseId}/>)
        }
    };

    instantCard = () => {
        return (<CsRequisition closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType}
                               subServiceType={this.props.subServiceType}
                               appId={this.props.appUid}/>)

    };

    giftCardIssuance = () => {
        if (this.props.subServiceType === "Prepaid Card Issuance") {
            return (<PrepaidCardIssuance closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                         serviceType={this.props.serviceType}
                                         subServiceType={this.props.subServiceType}
                                         appId={this.props.appUid}/>)
        } else {
            return (<GiftCardIssuance closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                      serviceType={this.props.serviceType}
                                      subServiceType={this.props.subServiceType}
                                      appId={this.props.appUid}/>)
        }

    };


    customDutyPayment = () => {
        return (
            <CustomDutyPayment
                closeModal={this.props.closeModal}
                closeIcon={this.props.closeIcon}
                serviceType={this.props.serviceType}
                freeFlag1={this.props.freeFlag1}
                freeFlag2={this.props.freeFlag2}
                cbNumber={this.props.cbNumber}
                accountNumber={this.props.accountNumber}
                solId={this.props.solId}
                customerName={this.props.customerName}
                freeFlag3={this.props.freeFlag3}
                freeFlag4={this.props.freeFlag4}
                subServiceType={this.props.subServiceType}
                maintenanceType={this.props.maintenanceType}
                appId={this.props.appUid}
                caseId={this.props.CaseId}
            />
        )
    };


    customerStatusUpdateMaintenance = () => {
        return(
            <CustomerStatusUpdateCs
                closeModal={this.props.closeModal}
                closeIcon={this.props.closeIcon}
                serviceType={this.props.serviceType}
                freeFlag1={this.props.freeFlag1}
                freeFlag2={this.props.freeFlag2}
                cbNumber={this.props.cbNumber}
                accountNumber={this.props.accountNumber}
                solId={this.props.solId}
                customerName={this.props.customerName}
                freeFlag3={this.props.freeFlag3}
                freeFlag4={this.props.freeFlag4}
                subServiceType={this.props.subServiceType}
                maintenanceType={this.props.maintenanceType}
                appId={this.props.appUid}
                caseId={this.props.CaseId}
            />
        )
    };



    inboxCase = () => {

        console.log(this.props.serviceType, this.props.subCategory);


        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('ABHMAKER') !== -1) {

            return (
                this.agentOpening()
            )
        } else if (this.props.subCategory === "Custom Duty Payment") {
            return (
                this.customDutyPayment()
            )
        }
        else if(this.props.subCategory === "Customer Status Update"){
            return (
                this.customerStatusUpdateMaintenance()
            )
        }
        else if (this.props.serviceType === "Account Opening" && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('DSTCS') !== -1) {
            return (
                this.casa()
            )
        } else if (this.props.serviceType === "ExistAccountOpening" && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('DSTCS') !== -1) {

            return (
                this.casaExist()
            )
        } else if (this.props.freeFlag1 === "CASA Other Maintenance" && this.props.serviceType === "Maintenance") {
            return (
                this.casaOtherMaintenance()
            )
        } else if (this.props.category === "Loan Closing") {

            return (
                this.loan()
            )
        } else if (this.props.category === "Partial Payment") {
            return (
                this.csAssetRejected()
            )
        } else if (this.props.category === "Pay Order") {
            return (
                this.csPayOrderRejected()
            )
        } else if (this.props.category === "Lien Marking") {
            return (
                this.csLienMarkingRejected()
            )
        } else if (this.props.category === "Recovery Amount") {
            return (
                this.csRecoveryAmountRejected()
            )
        } else if (this.props.category === 'Outstanding Certificate') {
            return (
                this.csOutstandingCertificateReturn()
            )
        } else if (this.props.category === "Secured Loan Closing") {

            return (
                this.securedLoan()
            )
        } else if (this.props.category === "Deceased Mark") {
            return (
                this.deceasedMark()
            )
        } else if (this.props.category === "Waiver Request") {
            return (
                this.waverRequest()
            )
        } else if (this.props.serviceType === "Locker Opening") {

            return (
                this.renderLocker()
            )
        } else if (this.props.category === "Debit Card Issuance") {

            return (
                this.instaDebitCard()
            )
        } else if (this.props.serviceType === "bond_issuance_serve" && (this.props.freeFlag2 === "ENCASHMENT PAYMENT" || this.props.freeFlag2 === "INTEREST PAYMENT")) {

            return (
                this.Encashment()
            )
        } else if (this.props.serviceType === "bond_issuance_serve" && (this.props.freeFlag2 === "NOMINEE CHANGE" || this.props.freeFlag2 === "ACES ENROLLMENT" || this.props.freeFlag2 === "ACCOUNT NUMBER CHANGE" || this.props.freeFlag2 === "DUPLICATE ISSUANCE" || this.props.freeFlag2 === "CERTIFICATE")) {

            return (
                this.acesEnrollmentMaintenance()
            )
        } else if (this.props.serviceType === "bond_issuance_serve" && this.props.freeFlag3 === "ISSUANCE") {

            return (
                this.issuance()
            )
        } else if (this.props.serviceType === "FDR Opening" || this.props.serviceType === "DPS Opening") {

            return (
                this.fdr()
            )
        } else if (this.props.subServiceType === "Instant Debit Card") {
            return (
                this.instantCard()
            )
        } else if (this.props.subServiceType === "Gift Card Issuance" || this.props.subServiceType === "Prepaid Card Issuance") {
            return (
                this.giftCardIssuance()
            )
        } else if (this.props.serviceType === "Credit Card" && this.props.subServiceType === "Chequebook") {
            return (
                this.cardChequeBook()
            )
        } else if (this.props.serviceType === "Debit Card" && this.props.subServiceType === "InstaDebitCard" && this.props.subCategory === "Bulk Insta Debit Card") {
            return (
                this.instaDebitCardReq()
            )
        } else if (this.props.serviceType === "Debit Card" && this.props.subServiceType === "Requisition") {
            return (
                this.debitCard()
            )
        } else if (this.props.serviceType === "Debit Card" && this.props.subServiceType === "Instapack") {
            return (
                this.instaPack()
            )
        } else if (this.props.serviceType === "FundTransfer") {
            return (
                this.fundTransfer()
            )
        } else if (this.props.category === "EMI") {
            return (
                this.emiChange()
            )
        } else if (this.props.subCategory === "Tenor Change") {
            return (
                this.loanAccountTenorChange()
            )
        } else if (this.props.subServiceType === "Other Service Request") {
            return (
                this.interestTransfer()
            )
        } else if (this.props.serviceType === "Maintenance") {

            return (
                this.maintenance()
            )
        } else if (this.props.serviceType === "Account Opening" && this.props.subServiceType === "Agent Account Opening") {

            return (
                this.agent()
            )
        } else if (this.props.category === "CARD" && this.props.subCategory === "Maintenance") {

            return (
                this.card()
            )
        } else if (this.props.serviceType === "Card Cheque") {
            return (
                this.cardCheque()
            )

        } else if (((this.props.serviceType === "adc_service" && this.props.freeFlag3 === "city_touch") || this.props.serviceType === "internet_banking" || this.props.serviceType === "Account Opening") && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('CS') !== -1) {

            return (
                this.casa()
            )
        } else if ((this.props.serviceType === "adc_service" || this.props.freeFlag3 === "sms_alert") && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('CS') !== -1) {

            return (
                this.adc()
            )
        } else if ((this.props.serviceType === "internet_banking" || this.props.serviceType === "ExistAccountOpening") && (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('CS') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem('workflowGroup') === "CS")) {

            return (
                this.casaExist()
            )
        } else if (this.props.subServiceType === 'LockerClosing') {
            return (
                this.renderLockerClosing()
            )
        } else if (this.props.subServiceType === 'LockerMaintenance') {
            return (
                this.renderLockerMaintenance()
            )
        } else if (this.props.serviceType === "Remetance") {
            return (
                this.renderRemitance()
            )
        } else if (this.props.serviceType === "Debit Card" && this.props.subServiceType === "Chequebook") {
            return (
                this.chequeBook()
            )
        }


    };

    close = () => {

        this.props.closeModal();
    };

    render() {

        return (

            this.inboxCase()


        );

    }

}

export default CSInboxCase;
