import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "../../Card/Card.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Grow from "@material-ui/core/Grow";
import {ThemeProvider} from "@material-ui/styles";
import TextFieldComponent from "../../JsonForm/TextFieldComponent";
import DateComponent from "../../JsonForm/DateComponent";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import SelectComponent from "../../JsonForm/SelectComponent";
import Table from "../../Table/Table";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../../MyValidation";
import FileMappingReadOnly from "../CommonComponent/FileMappingReadOnly";
import loader from "../../../Static/loader.gif";
import WaiverList from "../CommonComponent/WaiverList";
import SubmittedCaseHistory from "../CommonComponent/SubmittedCaseHistory";
import AccountStatement from "./AccountStatement";
import FunctionForGetAcDetails from "../CommonComponent/FunctionForGetAcDetails";
import ConfirmAlert from "../CommonComponent/ConfirmAlert";


let csRemarks = [
    {
        "varName": "bmRemarks",
        "type": "textArea",
        "label": "BM Remarks",
        "grid": 12
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

function Transition(props) {
    return <Grow in={true} timeout="auto" {...props} />;
}

var deferalOther =
    {
        "varName": "deferalOther",
        "type": "text",
        "label": "Please Specify",

    };

var deferal =
    {
        "varName": "deferalType",
        "type": "select",
        "label": "Deferal Type",
        "enum": [
            "Applicant Photograph",
            "Nominee Photograph",
            "Passport",
            "Address proof",
            "Transaction profile",
            "Others"
        ],

    };

var date = {
    "varName": "expireDate",
    "type": "date",
    "label": "Expire Date",

};

class OpeningBM extends Component {

    constructor(props) {
        super(props);
        this.state = {
            SelectedData: false,
            csDeferalPage: "",
            values: [],
            appId: '',
            csDataCapture: '',
            message: "",
            appData: {},
            getData: false,
            getNewCase: false,
            varValue: [],
            caseId: "",
            title: "",
            notificationMessage: "",
            app_uid: "-1",
            alert: false,
            redirectLogin: false,
            type: [],
            confirmAlert: false,
            actionType: "",
            dueDate: '',
            inputData: {
                csDeferal: "NO",
                accountType: "INSTAPACK",
                priority: "GENERAL"
            },
            fileUploadData: {},
            selectedDate: {},
            dropdownSearchData: {},
            AddDeferal: false,
            debitCard: "",
            loading: true,
            showValue: false,
            getDeferalList: [],
            deferalNeeded: false,
            uploadModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            loaderNeeded: null,
            IndividualDedupModal: false,
            JointDedupModal: false,
            // individualDataSaveId: '',
            // jointDataSaveId: '',
            // companyDataSaveId: '',
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            history: false,
            accountNumber: "",
            solId: "",
            customerName: "",
            accountStatement: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (event, value) => {

        this.state.inputData["csDeferal"] = value;
        this.updateComponent();
        if (value === "YES") {

            let values = [];
            values.push(Math.floor(Math.random() * 100000000000));

            this.setState({values: values, deferalNeeded: true});

        } else {
            this.setState({
                values: [],
                deferalNeeded: false
            })
        }
    };


    addDeferalForm() {


        return this.state.values.map((el, i) =>
            <React.Fragment>
                <Grid item xs={3.5}>
                    {
                        this.dynamicDeferral(el)
                    }
                </Grid>

                <Grid item xs={3.5}>
                    {
                        this.dynamicDate(el)
                    }

                </Grid>
                <Grid item xs={3.5}>
                    {this.dynamicDeferralOther(el)}

                </Grid>
                <Grid item xs={1.5}>
                    <button
                        style={{float: "right"}}
                        className="btn btn-outline-primary"
                        type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                    >
                        Remove
                    </button>


                </Grid>
            </React.Fragment>
        )

    }

    getSearchvalue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        /*  for (var prop in clone)
              if (clone[prop] === '')
                  delete clone[prop];*/
        return clone;
    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    submitApprove = (event) => {
        event.preventDefault();
        let bm_approval = 'APPROVE';
        this.handleSubmit(event, bm_approval);
    };
    submitReturn = (event) => {
        event.preventDefault();
        let bm_approval = 'REJECT';
        this.handleSubmit(event, bm_approval);
    };
    closeConfirmAlert = (data) => {
        this.setState({
            confirmAlert: false
        });
        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "submitApprove") {
            let bm_approval = 'APPROVE';
            this.handleSubmit(bm_approval);
        } else if (data === "YES" && this.state.actionType === "submitReturn") {
            let bm_approval = 'REJECT';
            this.handleSubmit(bm_approval);
        }
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        /*  for (let prop in clone)
              if (clone[prop] === '' || clone[prop] === ' '   || clone[prop] === "null"   ||  clone[prop] === "undefined" )
  delete clone[prop];*/
        return clone;
    };
    validationForHandleConfirm = (actionType) => {
        let errors = MyValidation.defaultValidation(csRemarks, this.state);
        this.forceUpdate();

        if (errors === true) {
            console.log("Not working");
            return 0;
        }
        if (actionType === "submitApprove") {

            this.setState({
                actionType: actionType,
                confirmAlert: true,

            })

        } else if (actionType === "submitReturn") {
            let dependencyField = [];
            dependencyField.push({

                "varName": "bmRemarks",
                "type": "textArea",
                "required": true,
                "label": "Bm Remarks",
                "grid": 12
            });

            let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
            this.forceUpdate();
            if (error === true) {
                this.setState({
                    loading: false
                });
                return 0;
            }


            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })
        }
    };
    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()

        {
            this.validationForHandleConfirm(type)
        }


    };
    renderSubmitButton = () => {
        if (this.state.getData && this.state.loading === false) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={(event) => this.handleCommon(event, "submitApprove")}

                        //onClick={this.submitApprove}
                    > Approve
                    </button>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <button
                        className="btn btn-outline-info btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={(event) => this.handleCommon(event, "submitReturn")}
                        //onClick={this.submitReturn}
                    > Return
                    </button>
                </div>
            )
        }
    };

    componentDidMount() {
        this.setState({
            loaderNeeded: false
        });
        this.state.inputData["csDeferal"] = "NO";
        this.state.inputData.customerId = "CB5984143";
        let varValue = [];
        var remarksArray = [];
        if (this.props.appId !== undefined) {
            /*   let url = backEndServerURL + '//' + this.props.appId;
               axios.get(url, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
                   .then((response) => {
                       console.log(response.data)
                       if(response.data!==undefined && response.data!==null){
                           this.state.inputData.data="NO"
                       }

                   })
                   .error((error)=>{
                       console.log(error)
                   })*/

            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                console.log(response.data);
                let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    console.log(response.data);
                    let tableArray = [];
                    var status = "";
                    var data = this.state.inputData;
                    response.data.map((deferal) => {
                        if (deferal.status === "ACTIVE") {
                            status = "Approved"
                        } else if (deferal.status === "NOTAPPROVED") {
                            status = "NOT APPROVED"
                        } else {
                            status = deferal.status;
                        }
                        tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));

                    });
                    this.setState({
                        getDeferalList: tableArray
                    });

                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            response.data.map((data) => {
                                if (data.remarks !== 'undefined') {
                                    remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                }
                            });
                            this.setState({
                                getRemarks: remarksArray
                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        })

                })
                    .catch((error) => {
                        console.log(error);
                    });

                console.log(remarksArray);
                this.state.inputData["csDeferal"] = "YES";
                this.state.inputData.customerId = "YES";

                console.log(response.data);
                let varValue = this.getSearchvalue(response.data);
                let inputData = this.getSearchvalue(response.data);
                inputData.bmRemarks = undefined;
                varValue.bmRemarks = undefined;
                if (inputData.fdValueDate !== undefined && inputData.fdValueDate !== null && inputData.fdValueDate !== "" && inputData.fdValueDate !== -1) {
                    inputData.fdValueDate = (inputData.fdValueDate).split(',')[0];
                    varValue.fdValueDate = (varValue.fdValueDate).split(',')[0];
                }
                if (inputData.installmentStartDate !== undefined && inputData.installmentStartDate !== null && inputData.installmentStartDate !== "" && inputData.installmentStartDate !== -1) {
                    inputData.installmentStartDate = (inputData.installmentStartDate).split(',')[0];
                    varValue.installmentStartDate = (varValue.installmentStartDate).split(',')[0];
                }

                FunctionForGetAcDetails.getAccountInfo(inputData.debitAccountNumber).then((response) => {
                    inputData.availableAmount = response.data.acctEftvBalance;
                    varValue.availableAmount = response.data.acctEftvBalance;
                    this.setState({
                        getData: true,
                        varValue: varValue,
                        loading: false,
                        appData: this.getSearchvalue(varValue),
                        inputData: inputData,
                        showValue: true,
                        appId: this.props.appId,
                        loaderNeeded: true
                    });
                }).catch((error) => {
                    this.setState({
                        loading: false,
                    })
                });

            })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
        }
    }

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, appliedBy, applicationDate, dueDate, status
        ])

    };
    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Deferral Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Deferral Type", "Raise By", "Raise Date", "Expire Date", "Status"]}
                        tableData={this.state.getDeferalList}
                        tableAllign={['left', 'left']}
                    />

                    <br/>
                </div>
            )
        }

    };
    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "Raised By", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />
                    <br/>
                </div>
            )
        }
    };
    renderAddButtonShow = () => {
        return (
            <button
                className="btn btn-outline-primary"
                style={{
                    width: 150,
                    float: 'right',
                    verticalAlign: 'right',
                }}
                type='button' value='add more'
                onClick={this.addClick.bind(this)}
            >Add Defferal</button>
        )
    };

    dynamicDeferral = (i) => {
        let deferalType = "deferalType" + i;
        let expireDate = "expireDate" + i;
        let defferalOther = "defferalOther" + i;
        let arrayData = [];
        /*arrayData.push({deferalType,expireDate,defferalOther});
        this.setState({
            getAllDefferal:arrayData
        })*/
        let field = JSON.parse(JSON.stringify(deferal));
        field.varName = "deferalType" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };

    dynamicDeferralOther = (i) => {
        if (this.state.inputData["deferalType" + i] === "Others") {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        }
    };
    dynamicDate = (i) => {
        let field = JSON.parse(JSON.stringify(date));
        field.varName = "expireDate" + i;
        return DateComponent.date(this.state, this.updateComponent, field);
    };

    updateComponent = () => {
        this.forceUpdate();
    };
    handleChangeComments = () => {

    };
    returnJsonForm = () => {
        if (this.state.showValue) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent, "", this.onKeyDownChange)
            )
        }
    };

    onKeyDownChange = (data) => {
        if (data.varName === "debitAccountStatement") {
            this.setState({
                accountStatement: true
            })
        }
    };

    closeStatement = () => {
        this.setState({
            accountStatement: false
        })
    };

    handleSubmit = (data) => {
        let saveDataDeferralRemarksAndRoute = {};
        this.setState({
            loading: true
        });
        var appId = this.props.appId;
        var approval = data;
        if (data === "APPROVE") {
            let deferalUrl = backEndServerURL + "/deferral/approval";
            axios.post(deferalUrl, {
                appId,
                approval
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.bm_approval = data;
                    //this.state.inputData["csDeferal"] = "NO";
                    let inputData = this.state.inputData;
                    if (data === "APPROVE") {
                        inputData.fdr_dps_opening_send_to_bm = "NO";
                        inputData["csDeferal"] = "NO"
                    }
                    saveDataDeferralRemarksAndRoute.map = inputData;
                    if (this.state.inputData.bmRemarks !== undefined && this.state.inputData.bmRemarks !== null) {
                        saveDataDeferralRemarksAndRoute.remark = this.state.inputData.bmRemarks;
                    }
                    var url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
                    axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            this.setState({
                                title: "Successfull!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                loading: false,
                            });
                            this.props.closeModal();
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false
                            })
                        });
                })
                .catch((error) => {
                    console.log(error);
                })
        } else {
            this.state.inputData.bm_approval = data;
            //this.state.inputData["csDeferal"] = "NO";
            let inputData = this.state.inputData;
            if (data === "APPROVE") {
                inputData.fdr_dps_opening_send_to_bm = "NO";
                inputData["csDeferal"] = "NO"
            }
            saveDataDeferralRemarksAndRoute.map = inputData;
            if (this.state.inputData.bmRemarks !== undefined && this.state.inputData.bmRemarks !== null) {
                saveDataDeferralRemarksAndRoute.remark = this.state.inputData.bmRemarks;
            }
            var url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
            axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.setState({
                        title: "Successfull!",
                        notificationMessage: "Successfully Routed!",
                        alert: true,
                        loading: false,
                    });
                    this.props.closeModal();
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
        }


    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };
    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    addClick() {
        let randomNumber = Math.floor(Math.random() * 100000000000);
        this.setState(prevState => ({
            values: [...prevState.values, randomNumber],
        }));
        this.state.inputData["csDeferal"] = "YES";
    }

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
            )
        }

    };

    removeClick(i, event) {
        event.preventDefault();
        let pos = this.state.values.indexOf(i);
        if (pos > -1) {
            this.state.values.splice(pos, 1);
            this.updateComponent();
            if (this.state.values.length > 0) {
                this.state.inputData["csDeferal"] = "YES"
            } else {
                this.state.inputData["csDeferal"] = "NO"
            }
        }
    }


    close = () => {
        this.props.closeModal();
    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };

    closeModal = () => {
        this.setState({
            getMappingAllImage: false,
            IndividualDedupModal: false,
            JointDedupModal: false,

        })
    };
    closeUploadModal = (data) => {
        this.setState({


            uploadModal: false
        })
    };
    accountDetailsModal = () => {
        this.setState({
            accountDetailsModal: false
        });
        this.close()
    };

    IndividualDedupModal = () => {
        this.setState({
            IndividualDedupModal: true
        })
    };
    JointDedupModal = () => {
        this.setState({
            JointDedupModal: true
        })
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };

    render() {

        const {classes} = this.props;
        return (
            <Card>
                <CardBody>
                    <div>
                        <Dialog
                            fullWidth="true"
                            maxWidth="xl"
                            open={this.state.getMappingAllImage}>
                            <DialogContent>
                                <FileMappingReadOnly appId={this.props.appId} closeModal={this.closeModal}/>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>

                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="md"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.history}>
                            <DialogContent className={classes.dialogPaper}>
                                <SubmittedCaseHistory customerName={this.state.customerName}
                                                      accountNumber={this.state.accountNumber} solId={this.state.solId}
                                                      globalSearch={true} closeIcon={this.closeHistoryModal}
                                                      appId={this.state.appId} closeModal={this.closeHistoryModal}/>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="xl"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.accountStatement}>
                            <DialogContent className={classes.dialogPaper}>
                                <AccountStatement closeModal={this.closeStatement}
                                                  accountNumber={this.state.inputData.debitAccountNumber}/>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.confirmAlert}>
                            <DialogContent className={classes.dialogPaper}>
                                <ConfirmAlert
                                    validationForHandleConfirm={this.validationForHandleConfirm}
                                    closeModal={this.closeConfirmAlert}/>

                            </DialogContent>
                        </Dialog>
                        <ThemeProvider theme={theme}>
                            <Grid container spacing={1}>
                                {this.renderNotification()}
                                {this.returnJsonForm()}
                                <Grid item xs='12'>
                                </Grid>
                                <Grid item xs='12'>
                                    {this.renderDefferalData()}
                                    <br/>
                                    {this.renderRemarksData()}
                                    <br/>
                                    <WaiverList appId={this.props.appId}/>
                                </Grid>
                                <Grid item xs={1}>
                                    <Grid item xs={12}>
                                        <button
                                            style={{
                                                width: 150,
                                            }}
                                            className="btn btn-outline-primary btn-sm"
                                            onClick={this.mappingAllImage}
                                        >
                                            Preview Document
                                        </button>
                                    </Grid>
                                </Grid>
                                {this.renderRemarks()}
                                <Grid item xs='12'>
                                </Grid>

                                <Grid item xs='12'>
                                </Grid>
                                <center>
                                    {this.renderSubmitButton()}
                                </center>
                            </Grid>
                        </ThemeProvider>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(styles)(OpeningBM);
