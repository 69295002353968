import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {assetOpsBackEndServerURL} from "../../../Common/Constant";
import 'semantic-ui-offline/semantic.min.css';
import MaterialTable from "material-table";
import {createMuiTheme} from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import TablePagination from "@material-ui/core/TablePagination";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import {localization, options, tableIcons,} from "../../DeliverableManagement/configuration";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import SubmittedCaseHistory from "../CommonComponent/SubmittedCaseHistoryAwf";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '5px',
                fontSize: '0.8rem'
            }
        },

    }
});

const SearchForm = [
    {
        "varName": "caseId",
        "type": "textDedup",
        "label": "Awf Case ID",
        "grid": 2,
    },
    {
        "varName": "cbNumber",
        "type": "textDedup",
        "label": "CB Number",
        "grid": 2,
    },
    {
        "varName": "accountNumber",
        "type": "textDedup",
        "label": "Link Account No.",
        "grid": 2,
    },
    {
        "varName": "category",
        "type": "select",
        "enum": [
            "Loan Closing", "Secured Loan Closing" , "Outstanding Certificate", "Partial Payment", "EMI", "Loan Account","Other Service Request",
            "Lien Marking","Waiver Request","Deceased Mark","Recovery Amount","Pay Order"
        ],
        "label": "Category",
        "grid": 2,
    },
    {
        "varName": "subCategory",
        "type": "select",
        "enum": [
            "Loan Closing", "Secured Loan Closing", "Outstanding Certificate",  "Partial Payment", "Date Change", "Tenor Change",
            "Interest transfer request","Partial Security release","Security replacement","Duplicate Sanctioned Letter","Duplicate NOC","Interest transfer request","Link account Changes","EFTN Account Change",
            "Lien Marking", "Waiver Request", "Deceased Mark", "Recovery Amount", "Pay Order"
        ],
        "label": "Sub Category",
        "grid": 2,
    },

    {
        "varName": "branch_id",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Branch",
        "grid": 2,
    },

    {
        "varName": "fromDate",
        "type": "date",
        "label": "From Date",
        "grid": 2,
    },
    {
        "varName": "toDate",
        "type": "date",
        "label": "To Date",
        "grid": 2,
    },
];

class AwfGlobalSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            inputData: {},
            autoComplete: {},
            allData: [],
            pageNumber: 0,
            totalRow: 0,
            loading: false,
            rowsPerPage: 50,
            showValue: false,
            alert: false,
            history: false,
            appId: 0,
            freeFlag2: "",
            title: "",
            notificationMessage: "",
            customerName: "",
            accountNumber: "",
            cbNumber: "",
            serviceTypes: "",
            solId: "",
            branch_name: "",
            sub_category_type: "",
            category_type: "",
            serviceType: "",
            subServiceType: "",
            caseId: "",
            columns: [
                {field: 'sl', title: 'SL'},
                {field: 'caseId', title: 'Case ID'},
                {field: 'customer_name', title: 'Name'},
                {field: 'cb_number', title: 'CB Number'},
                {field: 'account_number', title: 'Account Number'},
                {field: 'category_type', title: 'Category'},
                {field: 'sub_category_type', title: 'Sub Category'},
                {field: 'Date', title: 'Date of Activity'},
                {field: 'currentUserName', title: 'Current User'},
                {field: 'branch_name', title: 'Branch Name'},
                {field: 'Status', title: 'Status'},
            ],
        };
    }


    dateConverter = (value) => {

        return value.substr(0, 10);
    };

    componentDidMount() {
        this.dynamicApiCall("branch_id", "branch")

    }

    dynamicApiCall = (fieldName, apiType, setData) => {
        let branch = [];
        let codeUrl = assetOpsBackEndServerURL + "/workplaceUnit/getInitialUnits";
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((item) => {
                    branch.push({key: item.solId, value: item.name})
                });
                this.findByVarNameApiData(fieldName, branch)
                this.setState({
                    showValue: true,
                    getData: true,
                })
            })
            .catch((error) => {
                console.log(error);
            })
    };

    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = SearchForm;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                SearchForm[i].enum = getValue;
            }
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };


    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === undefined)
                delete clone[prop];
        return clone;
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.fetchDataFromAPI(event, 0);
        }
    };

    returnCategory = (branchingcategory, freeFlag5, category, serviceType) => {
        if (serviceType === "bond_issuance_serve") {
            return category
        } else if (branchingcategory !== undefined && branchingcategory !== null && branchingcategory !== "") {
            return branchingcategory
        } else if (freeFlag5 !== undefined && freeFlag5 !== null && freeFlag5 !== "") {
            return freeFlag5
        } else {
            return category
        }
    };

    returnSubCategory = (branchingSubCategory, freeFlag6, subCategory, serviceType) => {
        if (serviceType === "bond_issuance_serve") {
            return subCategory
        } else if (branchingSubCategory !== undefined && branchingSubCategory !== null && branchingSubCategory !== "") {
            return branchingSubCategory
        } else if (freeFlag6 !== undefined && freeFlag6 !== null && freeFlag6 !== "") {
            return freeFlag6
        } else {
            return subCategory
        }

    };

    fetchDataFromAPI = (event, pageNumber) => {
        event.preventDefault();
        let page = Math.sign(pageNumber);

        let input = this.removeNullValue(this.state.inputData);
        if (Object.keys(input).length === 0) {
            return 0;
        }
        let error = MyValidation.defaultValidation(SearchForm, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true,
                pageNumber: page,
            });
            let searchUrl = assetOpsBackEndServerURL + "/searchAppDelegations/global/" + page;
            axios.post(searchUrl, input, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    console.log(response.data);
                    if (response.status === 200) {
                        let array = [];
                        let responseArray = [];
                        let rowData;
                        let responseRowData;
                        response.data.content.map((data, index) => {
                            responseRowData = data.appId;
                            rowData = {
                                freeFlag2: data.freeFlag2,
                                sl: page > 0 ? ((index + 1) + (Number(this.state.rowsPerPage) * page)) : index + 1,
                                id: data.id,
                                appId: data.appId,
                                caseId: data.caseId,
                                customer_name: data.customerName,
                                cb_number: data.cbNumber,
                                account_number: data.accountNumber,
                                category_type: this.returnCategory(data.branchingCategory, data.freeFlag5, data.category,data.serviceType),
                                sub_category_type: this.returnSubCategory(data.branchingSubCategory, data.freeFlag6, data.subCategory, data.serviceType),
                                subServiceType: data.subServiceType,
                                serviceType: data.serviceType,
                                Date: this.dateConverter(data.appInitTime),
                                currentUserName: data.currentUserName,
                                branch_name: data.branchName,
                                Status: data.delStatus,
                                solId: data.solId
                            };
                            array.push(rowData);
                            responseArray.push(responseRowData);
                        });
                        this.setState({
                            allData: array,
                            responseData: responseArray,
                            inputData: {...input},
                            varValue: {...input},
                            showValue: true,
                            loading: false,
                            pageNumber: response.data.number,
                            rowsPerPage: response.data.numberOfElements,
                            totalRow: response.data.totalElements,
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        inputData: {...input},
                        varValue: {...input},
                        showValue: true,
                        loading: false
                    })
                });
        }
    };

    caseHistory = (e, rowData) => {
        e.preventDefault();
        console.log(rowData);
        this.setState({
            freeFlag2: rowData.freeFlag2,
            appId: rowData.appId,
            customerName: rowData.customer_name,
            serviceTypes: rowData.serviceType,
            accountNumber: rowData.account_number,
            cbNumber: rowData.cb_number,
            category_type: rowData.category_type,
            sub_category_type: rowData.sub_category_type,
            serviceType: rowData.serviceType,
            subServiceType: rowData.subServiceType,
            solId: rowData.solId,
            caseId: rowData.caseId,
            history: this.props.auditLog!==true?true:false
        });
    };

    closeHistoryModal = () => {
        this.setState({
            history: false,
        })
    };


    resetHandler = () => {
        window.location.reload();
    };

    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >
                                {this.props.auditLog!==true? "Global Search":"Audit Log"}
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        SearchForm,
                                        this.updateComponent, this.onKeyDownForDedup
                                    )}
                                    &nbsp; &nbsp; &nbsp;
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            height: 30,
                                            marginTop: 20,
                                        }}
                                        onClick={e => {
                                            this.fetchDataFromAPI(e, '0')
                                        }}
                                    >
                                        Search
                                    </button>
                                    &nbsp;&nbsp;
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            height: 30,
                                            marginTop: 20,
                                        }}
                                        onClick={this.resetHandler}
                                    >
                                        Reset
                                    </button>
                                </ThemeProvider>
                            </Grid>
                            <br/>

                            <Dialog
                                fullWidth="true"
                                maxWidth="md"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.history}>
                                <DialogContent className={classes.dialogPaper}>
                                    <SubmittedCaseHistory
                                        branchName={this.state.branch_name}
                                        caseId={this.state.caseId}
                                        subServiceType={this.state.subServiceType}
                                        category={this.state.category_type}
                                        serviceType={this.state.serviceType}
                                        subCategory={this.state.sub_category_type}
                                        freeFlag2={this.state.freeFlag2 === "externalUserClosedRequest" ? true : false}
                                        serviceType={this.state.serviceTypes}
                                        customerName={this.state.customerName}
                                        accountNumber={this.state.accountNumber}
                                        cbNumber={this.state.cbNumber}
                                        solId={this.state.solId} globalSearch={true}
                                        closeIcon={this.closeHistoryModal} appId={this.state.appId}
                                        closeModal={this.closeHistoryModal}/>
                                </DialogContent>
                            </Dialog>
                        </CardBody>
                        <CardBody>
                            <div>
                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h4 style={{color: "white"}} >
                                        {this.props.auditLog!==true? "Global Search":"Audit Log"}
                                    </h4>
                                </CardHeader>
                                <MuiThemeProvider theme={themeO}>
                                    <div style={{maxWidth: "100%"}}>
                                        <MaterialTable
                                            columns={this.state.columns}
                                            data={this.state.allData}
                                            title={" "}
                                            options={{...options, selection:false, pageSize: this.state.rowsPerPage}}
                                            localization={localization}
                                            icons={tableIcons}
                                            onRowClick={(e, rowData) => {
                                                this.caseHistory(e, rowData)
                                            }}
                                            components={{
                                                Pagination: props => (
                                                    <TablePagination
                                                        {...props}
                                                        rowsPerPageOptions={[50]}
                                                        count={this.state.totalRow}
                                                        page={this.state.pageNumber}
                                                        onChangePage={(e, page) => {
                                                            this.fetchDataFromAPI(e, page)
                                                        }
                                                        }
                                                    />
                                                ),
                                            }}
                                        />
                                    </div>
                                </MuiThemeProvider>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            );
        }

    }

}

export default withStyles(styles)(AwfGlobalSearch);
