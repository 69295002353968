function makeReadOnlyObject(object) {
    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject;

    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["readOnly"] = true;
    }
    return returnObject;
}

function changeGridObject(object) {
    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject;

    for (let i = 0; i < returnObjectVariables.length; i++) {
        if (returnObjectVariables[i]["type"] !== "title") {
            returnObjectVariables[i]["grid"] = 6;
        }
    }
    return returnObject;
}

//card Cheque
let CsDebitTransactionJsonFormForCard = [
    {
        "label": "Client ID",
        "type": "text",
        "varName": "debitClientId",
        "grid": 2,
        "required": true,
    },
    {
        "label": "Cheque Number ",
        "type": "text",
        "varName": "debitChequeNumber ",
        "grid": 2,
        "required": true,
    },
    {
        "label": "Amount",
        "type": "text",
        "varName": "debitAmount",
        "grid": 2,
        "required": true,
    },
    {
        "label": "Transaction Date",
        "type": "date",
        "varName": "debitTransactionDate",
        "grid": 2,
    },
    {
        "label": "Vat",
        "type": "text",
        "varName": "debitVat",
        "grid": 2,
    },
    {
        "label": "Commission",
        "type": "text",
        "varName": "debitCommission",
        "grid": 2,
    },
    {
        "label": "Card Member Name",
        "type": "text",
        "varName": "debitCardMemberName",
        "grid": 3,
        "required": true,
    },
    {
        "label": "Narration",
        "type": "text",
        "varName": "debitNarration",
        "grid": 3,
        "required": true,
    },

];
let CsCreditTransactionJsonFormForCard = [
    {
        "label": "Account Number",
        "type": "text",
        "varName": "creditAccountNumber",
        "grid": 2,
        "required": true,
    },
    {
        "label": "Amount",
        "type": "text",
        "varName": "creditAmount",
        "grid": 2,
        "required": true,
    },
    {
        "label": "Account Name",
        "type": "text",
        "varName": "creditAccountName",
        "grid": 3,
        "required": true,
    },
    {
        "label": "Narration",
        "type": "text",
        "varName": "creditNarration",
        "grid": 3,
        "required": true,
    },
];
let BomDebitCardJsonFormForCard = [
    {
        "label": "Client ID",
        "type": "text",
        "varName": "debitClientId",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Cheque Number ",
        "type": "text",
        "varName": "debitChequeNumber ",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Amount",
        "type": "text",
        "varName": "debitAmount",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Transaction Date",
        "type": "date",
        "varName": "debitTransactionDate",
        "grid": 2,
    },
    {
        "label": "Vat",
        "type": "text",
        "varName": "debitVat",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Commission",
        "type": "text",
        "varName": "debitCommission",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Card Member Name",
        "type": "text",
        "varName": "debitCardMemberName",
        "grid": 3,
        "readOnly": true
    },
    {
        "label": "Narration",
        "type": "text",
        "varName": "debitNarration",
        "grid": 3,
        "readOnly": true
    },
];

let BomCreditTransactionJsonFormForCard = [
    {
        "label": "Account Number",
        "type": "text",
        "varName": "creditAccountNumber",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Amount",
        "type": "text",
        "varName": "creditAmount",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Account Name",
        "type": "text",
        "varName": "creditAccountName",
        "grid": 3,
        "readOnly": true
    },
    {
        "label": "Narration",
        "type": "text",
        "varName": "creditNarration",
        "grid": 3,
        "readOnly": true
    }

];


//merchant

let jsonFormForMerchant = [
    {
        "label": "Trade License",
        "type": "radio",
        "varName": "tradeLicenseApproval",
        "enum": [
            "Yes",
            "No",
            "Not Updated"
        ],
        "grid": 6,
    },
    {
        "label": "Photo",
        "type": "radio",
        "varName": "photoApproval",
        "grid": 6,
        "enum": [
            "Lab Printed",
            "Not Lab Printed"
        ],
    },
    {
        "label": "TIN or E-TIN",
        "type": "radio",
        "varName": "tinApproval",
        "grid": 6,
        "enum": [
            "Yes",
            "No"
        ],
    },
    {
        "label": "NID or Passport",
        "type": "radio",
        "varName": "nidPassportApproval",
        "grid": 6,
        "enum": [
            "Yes",
            "Online",
            "No"
        ],
    },
    {
        "label": "KYC",
        "type": "radio",
        "varName": "kycApproval",
        "grid": 6,
        "enum": [
            "Yes",
            "No"
        ],
    },
    {
        "label": "TP",
        "type": "radio",
        "varName": "tpApproval",
        "grid": 6,
        "enum": [
            "Yes",
            "No"
        ],
    },

    {
        "label": "Terms & Condition Form",
        "type": "radio",
        "varName": "termsConditionApproval",
        "grid": 6,
        "enum": [
            "Yes",
            "Online",
            "No"
        ],
    },

    {
        "label": "Fraud Awarness From",
        "type": "radio",
        "varName": "fraudAwarnessFormApproval",
        "grid": 6,
        "enum": [
            "Yes",
            "No"
        ],
    },
    {
        "label": "Request Letter",
        "type": "radio",
        "varName": "requestLetterApproval",
        "grid": 6,
        "enum": [
            "Yes",
            "No"
        ],
    },

    {
        "label": "EFTN Form",
        "type": "radio",
        "varName": "eftnFormApproval",
        "grid": 6,
        "enum": [
            "Yes",
            "No"
        ],
    },
    {
        "label": "Bank Statement",
        "type": "radio",
        "varName": "bankStatementApproval",
        "grid": 6,
        "enum": [
            "Yes",
            "No",
            "Not Updated"
        ],
    },
    {
        "label": "Certificate of Incorporation",
        "type": "radio",
        "varName": "certificateOfIncorporationApproval",
        "grid": 6,
        "enum": [
            "Yes",
            "No"
        ],
    },
    {
        "label": "MOA/AOA",
        "type": "radio",
        "varName": "moaAoaApproval",
        "grid": 6,
        "enum": [
            "Yes",
            "No"
        ],
    },
    {
        "label": "From Xll",
        "type": "radio",
        "varName": "fromXllApproval",
        "grid": 6,
        "enum": [
            "Yes",
            "No"
        ],
    },
    {
        "label": "From X",
        "type": "radio",
        "varName": "fromXApproval",
        "grid": 6,
        "enum": [
            "Yes",
            "No",
            "Not Updated"
        ],
    },
    {
        "label": "Board or Partnership Resolution Letter",
        "type": "radio",
        "varName": "boardOrPartnershipResolutionLetterApproval",
        "grid": 6,
        "enum": [
            "Yes",
            "No",
            "Not Updated"
        ],
    },
    {
        "label": "Partnership Deep Copy",
        "type": "radio",
        "varName": "partnershipDeepCopyApproval",
        "grid": 6,
        "enum": [
            "Yes",
            "No"
        ],
    },
    {
        "label": "Supporting Document",
        "type": "radio",
        "varName": "supportingDocumentApproval",
        "grid": 6,
        "enum": [
            "Yes",
            "No",
            "Not Updated"
        ],
    },
    {
        "label": "BIO/BIDA",
        "type": "radio",
        "varName": "fromXApproval",
        "grid": 6,
        "enum": [
            "Yes",
            "No"
        ],
    },
    {
        "label": "Visa Copy",
        "type": "radio",
        "varName": "visaCopyApproval",
        "grid": 6,
        "enum": [
            "Yes",
            "No",
            "Not Updated"
        ],
    },
];


//#####DDeup Search NonIndividual Form#############
let CSjsonFormIndividualJointAccountOpeningSearch = {
    "variables": [
        {
            "varName": "numberOfCustomer",
            "type": "text",
            "label": "Number Of Customer ?",
            "required": true,
            "number": true,
        }
    ]

};

const DedupjsonForm = [
    {
        "label": "Merchant Name",
        "type": "text",
        "varName": "merchantName",
        "grid": 4
    },
    {
        "label": "Merchant type",
        "type": "select",
        "varName": "merchantType",
        "grid": 4,
        "enum": [
            "Sole Proprietorship",
            "Limited Liability",
            "Partnership",
            "Others"
        ]
    },
    {
        "label": "NID",
        "type": "text",
        "varName": "nid",
        "grid": 4
    },
];


//#####DDeup Search NonIndividual/Propritorship Form#############final
let CSjsonFormNonIndividualProprietorshipAccountOpeningSearch = {
    "variables": [
        {
            "varName": "companyTitle",
            "type": "title",
            "label": "Company/Business",

        },
        {
            "varName": "companyCbNumber",
            "type": "text",
            "label": "CB Number",
            "required": false,
            "readOnly": false
        },

        {
            "varName": "companyName",
            "type": "text",
            "label": "Company Name",
            "required": false,
            "readOnly": false

        },

        {
            "varName": "companyEmail",
            "type": "text",
            "label": "Email",
            "required": false,

            "readOnly": false


        },

        {
            "varName": "companyMobileNo",
            "type": "text",
            "label": "Mobile Number",
            "required": false

        },

        {
            "varName": "companyEtin",
            "type": "text",
            "label": "Company ETin",
            "required": false,


        },
        {
            "varName": "companyTradeLicense",
            "type": "text",
            "label": "Trade License",
            "required": false,


        },
        {
            "varName": "certificate",
            "type": "text",
            "label": "Certificate Of Incorporation",
            "required": false,


        },
        {
            "varName": "companyIndividualTitle",
            "type": "title",
            "label": "Proprietor Dedup",

        },
        {
            "varName": "cbNumber",
            "type": "text",
            "label": "Cb Number",
            "grid": 6

        },
        {
            "varName": "nid",
            "type": "text",
            "label": "NID",
            "grid": 6

        },

        {
            "varName": "passport",
            "type": "text",
            "label": "Passport",
            "grid": 6


        },
        {
            "varName": "customerName",
            "type": "text",
            "label": "Customer Name",
            "required": true,
            "grid": 6

        },
        {
            "varName": "dob",
            "type": "date",
            "label": "Date Of Birth",
            "required": true,
            "grid": 6


        },
        {
            "varName": "email",
            "type": "text",
            "label": "Email",
            "grid": 6,


        },

        {
            "varName": "phone",
            "type": "text",
            "label": "Mobile Number",
            "required": true,
            "grid": 6


        },

        {
            "varName": "tin",
            "type": "text",
            "label": "eTin",
            "grid": 6


        },


        {
            "varName": "registrationNo",
            "type": "text",
            "label": "Birth Certificate/Driving License",
            "grid": 6


        },
        {
            "varName": "nationality",
            "type": "text",
            "label": "Nationality",
            "required": true,
            "grid": 6


        },


    ],

};
//#####DDeup Search NonIndividual/Propritorship Form#############final
let CSjsonFormNonIndividualWithoutProprietorshipAccountOpeningSearch = [
    {
        "varName": "companyTitle",
        "type": "title",
        "label": "Company dedup",

    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "CB Number",
        "required": false,
        "readOnly": false
    },

    {
        "varName": "companyName",
        "type": "text",
        "label": "Company Name",
        "required": false,
        "readOnly": false

    },

    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "required": false,

        "readOnly": false


    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile Number",
        "required": false

    },

    {
        "varName": "companyEtin",
        "type": "text",
        "label": "Company ETin",
        "required": false,


    },
    {
        "varName": "tradeLicense",
        "type": "text",
        "label": "Trade License",
        "required": false,


    },


];

//#####DDeup Search NonIndividual/Partnership Form#############
let CSjsonFormNonIndividualPartnershipAccountOpeningSearch = {
    "variables": [
        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB Number",
            "required": true,
            "readOnly": false
        },

        {
            "varName": "companyName",
            "type": "text",
            "label": "Company Name",
            "required": false,
            "readOnly": false

        },

        {
            "varName": "email",
            "type": "text",
            "label": "Email",
            "required": false,

            "readOnly": false


        },

        {
            "varName": "phone",
            "type": "text",
            "label": "Mobile Number",
            "required": false

        },

        {
            "varName": "tin",
            "type": "text",
            "label": "Company ETin",
            "required": false,


        },
        {
            "varName": "tradeLicense",
            "type": "text",
            "label": "Trade License",
            "required": false,


        },
        {
            "varName": "tradeLicense",
            "type": "text",
            "label": "Certificate Of Incorporation",
            "required": false,


        },


    ],

};

//#####DDeup Search NonIndividual/Limited Company Acc Form#############
let CSjsonFormNonIndividualLimitedAccountOpeningSearch = {
    "variables": [
        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB Number",
            "required": false,
            "readOnly": false
        },

        {
            "varName": "companyName",
            "type": "text",
            "label": "Company Name",
            "required": false,
            "readOnly": false

        },

        {
            "varName": "email",
            "type": "text",
            "label": "Email",
            "required": false,

            "readOnly": false


        },

        {
            "varName": "phone",
            "type": "text",
            "label": "Mobile Number",
            "required": false

        },

        {
            "varName": "tin",
            "type": "text",
            "label": "Company ETin",
            "required": false,


        },
        {
            "varName": "tradeLicense",
            "type": "text",
            "label": "Trade License",
            "required": false,


        },
        {
            "varName": "tradeLicense",
            "type": "text",
            "label": "Certificate Of Incorporation",
            "required": false,


        },


    ],

};

//#####DDeup Search NonIndividual/Others  Form#############
let CSjsonFormNonIndividualOthersAccountOpeningSearch = {
    "variables": [
        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB Number",
            "required": false,
            "readOnly": false
        },

        {
            "varName": "companyName",
            "type": "text",
            "label": "Company Name",
            "required": false,
            "readOnly": false

        },

        {
            "varName": "email",
            "type": "text",
            "label": "Email",
            "required": false,

            "readOnly": false


        },

        {
            "varName": "phone",
            "type": "text",
            "label": "Mobile Number",
            "required": false

        },

        {
            "varName": "tin",
            "type": "text",
            "label": "Company ETin",
            "required": false,


        },
        {
            "varName": "tradeLicense",
            "type": "text",
            "label": "Trade License",
            "required": false,


        },
        {
            "varName": "tradeLicense",
            "type": "text",
            "label": "Certificate Of Incorporation",
            "required": false,


        },


    ],

};
const MerchantjsonFormReadOnly = [
    {
        "label": "Merchant type",
        "type": "text",
        "varName": "merchantType",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Merchant Name",
        "type": "text",
        "varName": "merchantName",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Proprietor Name",
        "type": "text",
        "varName": "proprietorName",
        "grid": 2,
        "readOnly": true
    },
    // {
    //     "varName": "dob",
    //     "type": "date",
    //     "label": "DOB",
    //     "grid": 2,
    //     // "readOnly" : true
    // },
    // {
    //     "label": "NID",
    //     "type": "text",
    //     "varName": "nid",
    //     "grid": 2,
    //     "readOnly" : true
    // },
    // {
    //     "label": "Passport",
    //     "type": "text",
    //     "varName": "passport",
    //     "grid": 2,
    //     "readOnly" : true
    // },
    // {
    //     "label": "TIN",
    //     "type": "text",
    //     "varName": "tin",
    //     "grid": 2,
    //     "readOnly" : true
    // },
    // {
    //     "label": "Nationality",
    //     "type": "select",
    //     "varName": "nationality",
    //     "grid": 2,
    //     "enum": [
    //         "Bangladesh",
    //         "Japan",
    //         "India"
    //     ],
    //     "readOnly" : true
    // },

    {
        "label": "Key Contact Person Number",
        "type": "text",
        "varName": "mobileNumber",
        "grid": 2,
        "readOnly": true
    },

    // {
    //     "label": "Email",
    //     "type": "text",
    //     "varName": "email",
    //     "grid": 2,
    //     "multiline": true,
    //     "readOnly" : true
    // },
    // {
    //     "label": "Birth Certificate",
    //     "type": "text",
    //     "varName": "birthCertificate",
    //     "grid": 2,
    //     "readOnly" : true
    // },
    {
        "label": "Address",
        "type": "text",
        "varName": "address",
        "grid": 2,
        "multiline": true,
        "readOnly": true
    },
    {
        "label": "DST Name",
        "type": "text",
        "varName": "dstName",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "DST ID",
        "type": "text",
        "varName": "dstID",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "POS Model",
        "type": "text",
        "varName": "posModel",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "SIM",
        "type": "text",
        "varName": "sim",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "POS Serial",
        "type": "text",
        "varName": "posSerial",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "IMEI",
        "type": "text",
        "varName": "imei",
        "grid": 2,
        "readOnly": true
    },
];


let CustomerServiceSearch = [
    {
        "label": "A/C Number",
        "type": "text",
        "varName": "accountNumber",
        "grid": 3,
    },
];
let CustomerServiceJsonFormOne = [
    {
        "label": "Customer Name",
        "type": "text",
        "varName": "customerName",
        "grid": 3,
    },
    {
        "label": "A/C Balance",
        "type": "text",
        "varName": "accountBalance",
        "grid": 3,
    },
];
let CustomerServiceTransferType = [
    {
        "label": "Transfer Type",
        "type": "select",
        "varName": "transferType",
        "enum": [
            "RTGS",
            "BEFTN"
        ],
        "grid": 3,
    },
];
let CustomerServiceTransferSubType = [
    {
        "label": "Customer Type",
        "type": "select",
        "varName": "customerType",
        "enum": [
            "Retail",
            "SME/Corporate"
        ],
        "grid": 3,
    },
];
let CustomerServiceJsonFormTwo = [
    {
        "label": "Transfer Amount",
        "type": "text",
        "varName": "transferAmount",
        "grid": 3,
    },
];


let FundTransferJsonFormOne = [
    {
        "label": "Request Type",
        "type": "select",
        "varName": "requestType",
        "enum": [
            "Single Transaction",
            "Bulk Transaction"
        ],
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "requisitionType",
        "conditionalVarValue": "sd",
    },
    {
        "label": "A/C Number",
        "type": "text",
        "varName": "accountNumber",
        "grid": 6,
        "readOnly": true
    },
    {
        "label": "Customer Name",
        "type": "text",
        "varName": "customerName",
        "grid": 6,
        "readOnly": true
    },
    {
        "label": "A/C Balance",
        "type": "text",
        "varName": "accountBalance",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "showAccountBalance",
        "conditionalVarValue": true,
    },
    {
        "label": "Transfer Type",
        "type": "text",
        "varName": "transferType",
        "grid": 6,
        "readOnly": true
    },
    // {
    //     "label": "Customer Type",
    //     "type": "text",
    //     "varName": "customerType",
    //     "grid": 6,
    //     "readOnly": true
    // },
    {
        "label": "Transfer Amount",
        "type": "textApiCall",
        "varName": "transferAmount",
        "grid": 6,
        "validation": "compareValue",
        "compareVarName": "accountBalance",
        "compareLabel": "Account Balance",
        "readOnly": true
    },
    {
        "label": "Amount in Word",
        "type": "text",
        "varName": "amountInWord",
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "showAmountConvertField",
        "conditionalVarValue": true,
        "readOnly": true,
    },
];

let FundTransferJsonFoMaker = [
    {
        "label": "Request Type",
        "type": "select",
        "varName": "requestType",
        "enum": [
            "Single Transaction",
            "Bulk Transaction"
        ],
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "requisitionType",
        "conditionalVarValue": "sd",
    },
    {
        "label": "A/C Number",
        "type": "text",
        "varName": "accountNumber",
        "grid": 6,
        "readOnly": true
    },
    {
        "label": "Customer Name",
        "type": "text",
        "varName": "customerName",
        "grid": 6,
        "readOnly": true
    },
    {
        "label": "A/C Balance",
        "type": "text",
        "varName": "accountBalance",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "showAccountBalance",
        "conditionalVarValue": true,
    },
    {
        "label": "Transfer Type",
        "type": "text",
        "onKeyDown": true,
        "varName": "transferType",
        "grid": 6,
        "readOnly": true
    },
    // {
    //     "label": "Customer Type",
    //     "type": "text",
    //     "varName": "customerType",
    //     "grid": 6,
    //     "readOnly": true
    // },
    {
        "label": "Transfer Amount",
        "type": "textApiCall",
        "varName": "transferAmount",
        "grid": 6,
        "validation": "compareValue",
        "compareVarName": "accountBalance",
        "compareLabel": "Account Balance",
        "required": true
    },
    {
        "label": "Amount in Word",
        "type": "text",
        "varName": "amountInWord",
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "showAmountConvertField",
        "conditionalVarValue": true,
        "readOnly": true,
    },
];

let FundTransferJsonFormTwo = [
    {
        "label": "Beneficiary Account Number",
        "type": "text",
        "varName": "creditAccountNumber",
        "required": true,
        "validation": "beneficiaryAccount",
        // "validation" : "numeric",
        "grid": 6,
    },
    {
        "label": "Beneficiary Name",
        "type": "text",
        "varName": "beneficiaryDetails",
        "required": true,
        "grid": 6,
    },
    // {
    //     "label": "Bic Code",
    //     "type": "text",
    //     "varName": "bicCode",
    //     "grid": 6,
    // },
    {
        "label": "Beneficiary Bank Name",
        "type": "select",
        "enum": [],
        "onKeyDown": true,
        "required": true,
        "varName": "beneficiaryBankName",
        "grid": 6,
    },
    {
        "label": "Branch Name",
        "type": "select",
        "onKeyDown": true,
        "enum": [],
        "required": true,
        "varName": "branchName",
        "grid": 6,
    },
    {
        "label": "Routing Number",
        "type": "text",
        "required": true,
        "readOnly": true,
        "validation": "numeric",
        "varName": "routingNumber",
        "grid": 6,
    },
];

let FundTransferJsonFormTwoReadOnly = {};
FundTransferJsonFormTwoReadOnly = makeReadOnlyObject(JSON.parse(JSON.stringify(FundTransferJsonFormTwo)));


let LockerJsonForm = [
    {
        "label": "Applicant Name",
        "type": "text",
        "varName": "applicantName",
        "grid": 3,
        "validation": "string"
    },
    {
        "label": "Account Number",
        "type": "text",
        "varName": "accountNumber",
        "grid": 3,
        "validation": "numeric"
    },
    {
        "label": "Communication Address",
        "type": "text",
        "varName": "communicationAddress",
        "grid": 3,
    },
    {
        "label": "Locker Key",
        "type": "text",
        "varName": "lockerKey",
        "grid": 3,
        "required": true,
        "validation": "numeric"
    },
    {
        "label": "Locker Size",
        "type": "select",
        "varName": "lockerSize",
        "required": true,
        "onKeyDown": true,
        "enum": [
            "Small",
            "Medium",
            "Large"
        ],
        "grid": 2,
    },
    {
        "varName": "availableLockerNo",
        "type": "select",
        "required": true,
        "label": "Available Locker No.",
        "enum": [],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "showLockerNumber",
        "conditionalVarValue": true
    },
    {
        "varName": "numberOfNominee",
        "type": "select",
        "required": true,
        "label": "Number Of Nominee",
        "enum": [
            "Same As CASA A/C",
            "1",
            "2",
            "3",
            "4",
            "5"
        ],
        "grid": 2
    }
];


let linkAccountChangeRequest = [
    {
        "varName": "existingLinkAccountNumber",
        "type": "text",
        "label": "Existing Link Account Number",
        "readOnly": true,
        "grid": 3
    },
    {
        "varName": "newLinkAccountNumber",
        "type": "text",
        "label": "New Link Account Number",
        "validation": "numeric",
        "required": true,
        "grid": 3
    },
    {
        "varName": "newLinkAccountSignature",
        "type": "checkbox",
        "label": "Check Signature",
        "grid": 3
    }
];

let siDateChangeRequest = [
    {
        "varName": "oldSiDate",
        "type": "date",
        "label": "Old SI Date",
        "grid": 3
    },
    {
        "varName": "newSiDate",
        "type": "date",
        "label": "New SI Date",
        "grid": 3
    },
];


let linkAccountChangeRequestReadOnly = [
    {
        "varName": "existingLinkAccountNumber",
        "type": "text",
        "label": "Existing Link Account Number",
        "grid": 6,
        "readOnly": true
    },
    {
        "varName": "newLinkAccountNumber",
        "type": "text",
        "label": "New Link Account Number",
        "grid": 6,
        "readOnly": true
    },
];

let siDateChangeRequestReadOnly = [
    {
        "varName": "oldSiDate",
        "type": "date",
        "label": "Old SI Date",
        "grid": 6,
    },
    {
        "varName": "newSiDate",
        "type": "date",
        "label": "New SI Date",
        "grid": 6,
    },
];

let lockerMaintenanceJsonFormAfterSearch = [
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 3
    },
    {
        "varName": "lockerNumber",
        "type": "text",
        "label": "Locker Number",
        "grid": 3
    },
    {
        "varName": "lockerChangeRequest",
        "type": "text",
        "label": "Locker Change Request",
        "grid": 3
    },
    {
        "varName": "lockerKey",
        "type": "text",
        "label": "Locker Key",
        "grid": 3
    },
    {
        "varName": "lockerSize",
        "type": "text",
        "label": "Locker Size",
        "grid": 3
    }
];


let lockerMaintenanceJsonFormReadOnly = [
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6,
        "readOnly": true
    },
    {
        "varName": "lockerNumber",
        "type": "text",
        "label": "Locker Number",
        "grid": 6,
        "readOnly": true
    },
    {
        "varName": "lockerChangeRequest",
        "type": "text",
        "label": "Locker Change Request",
        "grid": 6,
        "readOnly": true
    },
    {
        "varName": "lockerName",
        "type": "text",
        "label": "Locker Name",
        "grid": 6,
        "readOnly": true
    },
    {
        "varName": "lockerSize",
        "type": "text",
        "label": "Locker Size",
        "grid": 6,
        "readOnly": true
    }
];

let lockerJsonFormOne = [
    {
        "varName": "linkAccountChangeRequest",
        "type": "checkbox",
        "label": "Link Account Change Request",
        "grid": 12,
    },
];

let lockerJsonFormTwo = [
    {
        "varName": "nomineeChangeRequest",
        "type": "checkbox",
        "label": "Nominee Change Request",
        "grid": 12,
    },
];

let lockerJsonFormThree = [
    {
        "varName": "keyReplacement",
        "type": "checkbox",
        "label": "Key Replacement",
        "grid": 12,
    },
];


let lockerJsonFormOneReadOnly = [
    {
        "varName": "linkAccountChangeRequest",
        "type": "checkbox",
        "label": "Link Account Change Request",
        "grid": 12,
        "readOnly": true
    },
];

let lockerJsonFormTwoReadOnly = [
    {
        "varName": "nomineeChangeRequest",
        "type": "checkbox",
        "label": "Nominee Change Request",
        "grid": 12,
        "readOnly": true
    },
];

let lockerJsonFormThreeReadOnly = [
    {
        "varName": "keyReplacement",
        "type": "checkbox",
        "label": "Key Replacement",
        "grid": 12,
        "readOnly": true
    },
];

let accountMaintenanceForm = [
    {
        "varName": "accountNumber",
        "type": "text",
        "required": true,
        "label": "Account No",
        "grid": 2,
    },
    {
        "varName": "accountName",
        "type": "text",
        "required": true,
        "label": "Account Name",
        "grid": 2,
    },
    {
        "varName": "amount",
        "type": "text",
        "required": true,
        "label": "Amount",
        "grid": 2,
    },
    {
        "varName": "closeStatus",
        "type": "text",
        "required": true,
        "label": "Close Status",
        "grid": 2,
    },
    {
        "varName": "relationship",
        "type": "text",
        "required": true,
        "label": "Relationship",
        "grid": 2,
    },
    {
        "varName": "typeOfLoan",
        "type": "text",
        "required": true,
        "label": "Type of Loan",
        "grid": 2,
    },
    {
        "varName": "lienAmount",
        "type": "text",
        "required": true,
        "label": "Lien Amount",
        "grid": 2,
    },
    {
        "varName": "lienRemarks",
        "type": "text",
        "required": true,
        "label": "Lien Remarks",
        "grid": 4,
        "multiline": true
    },
    {
        "varName": "freezeCode",
        "type": "text",
        "required": true,
        "label": "Freeze Code",
        "grid": 2,
    },
    {
        "varName": "freezeReasonCode",
        "type": "text",
        "required": true,
        "label": "Freeze Reason Code",
        "grid": 2,
    },
    {
        "varName": "acStatus",
        "type": "text",
        "required": true,
        "label": "A/C Status",
        "grid": 2,
    },
    {
        "varName": "freezeRemarks",
        "type": "text",
        "required": true,
        "label": "Freeze Remarks",
        "grid": 4,
        "multiline": true
    },
    {
        "varName": "dormantActivationCharge",
        "type": "text",
        "required": true,
        "label": "Dormant Activation Charge",
        "grid": 2,
    },
    {
        "varName": "freeText",
        "type": "text",
        "required": true,
        "label": "Free Text",
        "grid": 4,
        "multiline": true
    }

];
let maintenanceForm = [
    {
        "varName": "accountName",
        "type": "text",
        "readOnly": true,
        "label": "Account Name",
        "grid": 2,
    },
    {
        "varName": "amount",
        "type": "text",
        "readOnly": true,
        "label": "Amount",
        "grid": 2,
    },
    {
        "varName": "closeStatus",
        "type": "text",
        "readOnly": true,
        "label": "Close Status",
        "grid": 2,
    },
    {
        "varName": "relationship",
        "type": "text",
        "readOnly": true,
        "label": "Relationship",
        "grid": 2,
    },
    {
        "varName": "typeOfLoan",
        "type": "text",
        "readOnly": true,
        "label": "Type of Loan",
        "grid": 2,
    },
    {
        "varName": "lienAmount",
        "type": "text",
        "readOnly": true,
        "label": "Lien Amount",
        "grid": 2,
    },
    {
        "varName": "lienRemarks",
        "type": "text",
        "readOnly": true,
        "label": "Lien Remarks",
        "grid": 4,
        "multiline": true
    },
    {
        "varName": "freezeCode",
        "type": "text",
        "readOnly": true,
        "label": "Freeze Code",
        "grid": 2,
    },
    {
        "varName": "freezeReasonCode",
        "type": "text",
        "readOnly": true,
        "label": "Freeze Reason Code",
        "grid": 2,
    },
    {
        "varName": "acStatus",
        "type": "text",
        "readOnly": true,
        "label": "A/C Status",
        "grid": 2,
    },
    {
        "varName": "freezeRemarks",
        "type": "text",
        "readOnly": true,
        "label": "Freeze Remarks",
        "grid": 4,
        "multiline": true
    },
    {
        "varName": "dormantActivationCharge",
        "type": "text",
        "readOnly": true,
        "label": "Dormant Activation Charge",
        "grid": 2,
    },
    {
        "varName": "freeText",
        "type": "text",
        "readOnly": true,
        "label": "Free Text",
        "grid": 4,
        "multiline": true
    }

];


let dstJsonForm = [
    {
        "label": "Merchant type",
        "type": "text",
        "varName": "merchantType",
        "grid": 3,
        "required": true,
        "readOnly": true
    },
    {
        "label": "Merchant Name",
        "type": "text",
        "varName": "merchantName",
        "grid": 3,
        "multiline": true,
        "required": true,
        "readOnly": true
    },
    {
        "label": "Proprietor Name",
        "type": "text",
        "varName": "proprietorName",
        "grid": 3,
        "multiline": true,
        "required": true,
        "readOnly": true
    },
    {
        "label": "DBA Name",
        "type": "text",
        "varName": "dba",
        "grid": 3,
        "multiline": true,
        "required": true,
        "readOnly": true
    },
    {
        "label": "Key Contact Person Number",
        "type": "text",
        "varName": "mobileNumber",
        "grid": 3,
        "required": true,
        "validation": "phone"
    },
    {
        "label": "Address",
        "type": "text",
        "varName": "address",
        "grid": 3,
        "multiline": true,
        "required": true,
    },
    {
        "label": "DST Name",
        "type": "text",
        "varName": "dstName",
        "grid": 3,
        "required": true,
        "validation": "string"
    },
    {
        "label": "DST ID",
        "type": "text",
        "varName": "dstID",
        "grid": 3,
        "required": true,
    },

];


let merchantDstJsonForm = [

    {
        "label": "Proprietor Name",
        "type": "text",
        "varName": "proprietorName",
        "grid": 6,
        "multiline": true,
        "readOnly": true,
        "required": true,
        "maxLength":40,
    },
    {
        "label": "Key Contact Person Number",
        "type": "text",
        "varName": "mobileNumber",
        "grid": 6,
        "validation": "phone",
        "readOnly": true,
        "required": true,
    },
    {
        "label": "MCC",
        "type": "text",
        "varName": "mcc",
        "validation": "numeric",
        // "required": true,
        "grid": 6,
        "required": true,
        "maxLength":4,
    },
    {
        "label": "Business Type",
        "type": "select",
        "enum": [
            "RETAIL",
            "REASTAURANT",
            "ELECTRONICS",
            "JEWELLERY",
            "TRAVEL",
            "LODGING",
            "AIRLINE",
            "CAR BOAT RENTAL"
        ],
        "onKeyDown": true,
        "varName": "businessType",
        "required": true,
        "grid": 6,
    },
    {
        "label": "Create Retailer",
        "type": "title",
        "varName": "empty",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "MID",
        "type": "text",
        "varName": "mid",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "midApiData",
        "conditionalVarValue": true
    },
    {
        "label": "Merchant Type",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "varName": "merchantType",
        "grid": 6,
        "required":true,
    },
    {
        "label": "Merchant Name",
        "type": "text",
        "varName": "merchantName",
        "grid": 6,
        "multiline": true,
        "readOnly": true,
        "required":true,
    },
    {
        "label": "DBA Name",
        "type": "text",
        "varName": "dba",
        "grid": 6,
        "multiline": true,
        "required":true,
    },
    {
        "label": "Payment Facilitator Id",
        "type": "text",
        "varName": "paymentFacilitatorId",
        "grid": 6,
        "validation": "numeric",
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "Sub Merchant Id",
        "type": "text",
        "varName": "subMerchantId",
        "grid": 6,
        "validation": "numeric",
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "Country Code",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "masterDataName": "country",
                "key": "50",
                "value": "BANGLADESH->50"
            }
        ],
        "varName": "countryCode",
        "grid": 6,
        "required":true,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "Region Code",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "varName": "regionCode",

        "grid": 6,
        "required":true,
        "onKeyDown": true,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "City Code",
        "varName": "cityCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        // "readOnly": true,
        "grid": 6,
        "required":true,
        "onKeyDown": true,
        "conditional": true,
        "conditionalVarName": "regionCodeApiCall",
        "conditionalVarValue": true,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "Zip Code",
        "type": "text",
        "varName": "zipCode",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "cityCodeApiCall",
        "conditionalVarValue": true,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "Physical Address",
        "type": "text",
        "varName": "physicalAddress",
        "grid": 6,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
        "required":true,
    },
    {
        "label": "Mobile No",
        "type": "text",
        "varName": "mobileNo",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required":true,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "Email Address",
        "type": "text",
        "validation": "email",
        "varName": "emailAddress",
        "lowerCase":true,
        "grid": 6,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "Location",
        "type": "text",
        "varName": "location",
        "validation": "string",
        "grid": 6,
        "required":true,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "Fin. Profile",
        "type": "autoCompleteValueReturn",
        "varName": "finacleProfile",
        "enum": [],
        "grid": 6,
        "required":true,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "ACQ. Group",
        "type": "autoCompleteValueReturn",
        "enum": [

        ],
        "varName": "acqGroup",
        "grid": 6,
        "required":true,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "Owner Name",
        "type": "text",
        "varName": "ownerName",
        "grid": 6,
        "validation": "string",
        "required":true,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "Position",
        "type": "text",
        "varName": "position",
        "grid": 6,
        "validation": "string",
        "required":true,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "Account Type Note",
        "type": "text",
        "varName": "accountTypeNote",
        "grid": 6,
        "multiline": true,
        "required":true,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "Max Amount Per Txn",
        "type": "text",
        "varName": "maxAmountPerTxn",
        "validation": "numeric",
        "grid": 6,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "Max Txn Per Month",
        "type": "text",
        "varName": "maxTxnPerMonth",
        "grid": 6,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "Max Number Of Tnx Per Month",
        "type": "text",
        "varName": "maxNumberOfTxnPerMonth",
        "validation": "numeric",
        "grid": 6,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "Ababil Account Number",
        "type": "text",
        "onKeyDown": true,
        "varName": "ababilAccountNumber",
        "grid": 6,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "Ababil Account Name",
        "type": "text",
        "varName": "ababilAccountName",
        "grid": 6,
        "readOnly":true,
        "conditional": true,
        "conditionalVarName": "ababilAccountNumberApiCall",
        "conditionalVarValue": true,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "MC facilitator",
        "type": "text",
        "varName": "mcfacilitator",
        "validation": "numeric",
        "grid": 6,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "Create Corporate Customer",
        "type": "title",
        "varName": "empty",
        "grid": 12,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "Full Name",
        "type": "text",
        "varName": "fullName",
        "grid": 6,
        "validation": "string",
        "multiline": true,
        "required": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "Country Code",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "masterDataName": "country",
                "key": "50",
                "value": "BANGLADESH->50"
            }
        ],
        "varName": "generalCountryCode",
        "grid": 6,
        "required": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "Region Code",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "varName": "generalRegionCode",

        "grid": 6,
        "required": true,
        "onKeyDown": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "City Code",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "varName": "generalCityCode",
        "grid": 6,
        "onKeyDown": true,
        "required": true,
        "conditional": true,
        "conditionalVarName": "generalRegionCodeApiCall",
        "conditionalVarValue": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "Zip Code",
        "type": "text",
        "varName": "generalZipCode",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "generalCityCodeApiCall",
        "conditionalVarValue": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "Corporate Address",
        "type": "text",
        "varName": "generalCorporateAddress",
        "grid": 6,
        "required": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "Mobile No",
        "type": "text",
        "varName": "generalMobileNo",
        "grid": 6,
        "phonePrefix": true,
        "validation": "phone",
        "required": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "Email Address",
        "type": "text",
        "validation": "email",
        "varName": "generalEmailAddress",
        "lowerCase":true,
        "grid": 6,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },

    {
        "label": "Company Code",
        "type": "text",
        "validation": "numeric",
        "varName": "additionalCompanyCode",
        "grid": 6,
        "required": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "Agreement Sign Up Date",
        "type": "date",
        "varName": "agreementSignUpDate",
        "grid": 6,
        "required": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "DSC Code",
        "type": "text",
        "varName": "dseCode",
        "validation": "numeric",
        "grid": 6,
        "required": true,
        "maxLength":8,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "Bank Type",
        "type": "select",
        "enum":[
            "CITY BANK",
            "OTHER BANK",
        ],
        "varName": "selectBankType",
        "grid": 6,
        "required": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "Account No",
        "type": "text",
        "varName": "accountNo",
        "onKeyDown": true,
        "grid": 6,
        "required": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "Account Name",
        "type": "text",
        "varName": "accountName",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountNoApiCall",
        "conditionalVarValue": true,



    },
    {
        "label": "POS Deployment Date",
        "type": "date",
        "varName": "posDeploymentDateDate",
        "grid": 6,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "Member Of Se Demographics ",
        "type": "title",
        "varName": "empty",
        "grid": 12,
    },
    {
        "label": "Member Of Se Demographics ",
        "type": "select",
        "onKeyDown": true,
        "enum":[
            "YES" ,
            "NO" ,
        ],
        "required": true,
        "varName": "numberOfSeDemographics",
        "grid": 6,
    },

    {
        "label": "Institution ID",
        "type": "text",
        "varName": "institutionId",
        "grid": 6,
        "readOnly":true,
        "conditional": true,
        "conditionalVarName": "numberOfSeDemographics",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Business Center",
        "type": "text",
        "varName": "businessCenter",
        "grid": 6,
        "readOnly":true,
        "validation": "numeric",
        "conditional": true,
        "conditionalVarName": "numberOfSeDemographics",
        "conditionalVarValue": "YES",
    },
    /* {
         "label": "Descript. Bill. Name",
         "type": "text",
         "varName": "descriptBillName",
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "numberOfSeDemographics",
         "conditionalVarValue": "YES",
     },
     {
         "label": "Address Line",
         "type": "text",
         "multiline": true,
         "varName": "addressLine",
         "grid": 6,
         "maxLength":300,
         "conditional": true,
         "conditionalVarName": "numberOfSeDemographics",
         "conditionalVarValue": "YES",
     },*/
    {
        "label": "AID",
        "type": "text",
        "readOnly":true,
        "varName": "aid",
        "validation": "numeric",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "numberOfSeDemographics",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Mandatory Field Control ",
        "type": "select",
        "enum":[
            "2"
        ],
        "readOnly":true,
        "varName": "mandatoryFiledControl",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "numberOfSeDemographics",
        "conditionalVarValue": "YES",
    },
    /*{
        "label": "Status",
        "type": "select",
        "enum":[
            "A"
        ],
        "varName": "status",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "numberOfSeDemographics",
        "conditionalVarValue": "YES",
    },*/
    {
        "label": "Reason ",
        "type": "text",
        "varName": "reason",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "numberOfSeDemographics",
        "conditionalVarValue": "YES",
    },
    /*  {
          "label": "Active Date ",
          "type": "date",
          "varName": "activeDate",
          "grid": 6,
      },*/
    {
        "label": "Cancelled Date",
        "type": "date",
        "varName": "cancelledDate",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "numberOfSeDemographics",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Reinstated Date ",
        "type": "date",
        "varName": "reinstatedDate",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "numberOfSeDemographics",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Role Type Code ",
        "type": "text",
        "varName": "roleTypeCode",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "numberOfSeDemographics",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Ind. Quality Code",
        "type": "text",
        "varName": "indQualityCode",
        "validation": "numeric",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "numberOfSeDemographics",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Location Code",
        "type": "text",
        "varName": "locationCode",
        "grid": 6,
        "readOnly":true,
        "conditional": true,
        "conditionalVarName": "numberOfSeDemographics",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Submission Ccy",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "varName": "submissionCcy",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "numberOfSeDemographics",
        "conditionalVarValue": "YES",
    },
    {
        "label": "SE Settle Ccy ",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "varName": "seSettleCCY",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "numberOfSeDemographics",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Subm. Code ",
        "type": "text",
        "varName": "submCode",
        "grid": 6,
        "readOnly":true,
        "conditional": true,
        "conditionalVarName": "numberOfSeDemographics",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Settle Code",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "key":"110",
                "value":"Cheque->110",
                "masterDataName":"settleCode",
            },
            {
                "key":"111",
                "value":"Direct Debit->111",
                "masterDataName":"settleCode",
            },
            {
                "key":"112",
                "value":"Direct Credit->112",
                "masterDataName":"settleCode",
            },
            {
                "key":"113",
                "value":"Debit Bank Transfer->113",
                "masterDataName":"settleCode",
            },
            {
                "key":"114",
                "value":"Credit Bank Transfer->114",
                "masterDataName":"settleCode",
            },
            {
                "key":"115",
                "value":"Draft->115",
                "masterDataName":"settleCode",
            },
            {
                "key":"116",
                "value":"Invoice->116",
                "masterDataName":"settleCode",
            },
            {
                "key":"117",
                "value":"Debit Wire->117",
                "masterDataName":"settleCode",
            },
            {
                "key":"118",
                "value":"Credit Wire->118",
                "masterDataName":"settleCode",
            },
        ],
        "required":true,
        "varName": "settleCode",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "numberOfSeDemographics",
        "conditionalVarValue": "YES",
    },
    /* {
         "label": "Resourse Status",
         "type": "text",
         "varName": "resorseStatus",
         "grid": 6,
         "readOnly":true,
        "conditional": true,
         "conditionalVarName": "numberOfSeDemographics",
         "conditionalVarValue": "YES",
     },*/
    /* {
         "label": "Risk Indicator",
         "type": "text",
         "varName": "riskIndicator",
         "grid": 6,
         "readOnly":true,
         "conditional": true,
         "conditionalVarName": "numberOfSeDemographics",
         "conditionalVarValue": "YES",
     },*/
    /* {
         "label": "Account Indicator",
         "type": "text",
         "varName": "accountIndicator",
         "grid": 6,
         "readOnly":true,
         "conditional": true,
         "conditionalVarName": "numberOfSeDemographics",
         "conditionalVarValue": "YES",
     },*/
    /* {
         "label": "Visa Indicator ",
         "type": "text",
         "varName": "visaIndicator",
         "grid": 6,
         "readOnly":true,
         "conditional": true,
         "conditionalVarName": "numberOfSeDemographics",
         "conditionalVarValue": "YES",
     },*/
    /* {
         "label": "Mastercard Indicator",
         "type": "text",
         "varName": "masterCardIndicator",
         "grid": 6,
         "readOnly":true,
         "conditional": true,
         "conditionalVarName": "numberOfSeDemographics",
         "conditionalVarValue": "YES",
     },*/
    /* {
         "label": "Other Card Indicator",
         "type": "text",
         "varName": "otherCardIndicator",
         "grid": 6,
         "readOnly":true,
         "conditional": true,
         "conditionalVarName": "numberOfSeDemographics",
         "conditionalVarValue": "YES",
     },*/
    {
        "label": "Floor Limit CCY",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "varName": "floorLimitCCY",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "numberOfSeDemographics",
        "conditionalVarValue": "YES",
    },
    /* {
         "label": "Hign/Low Indicator",
         "type": "text",
         "varName": "highLowIndicator",
         "grid": 6,
         "readOnly":true,
         "conditional": true,
         "conditionalVarName": "numberOfSeDemographics",
         "conditionalVarValue": "YES",
     },*/
    /* {
         "label": "Effect Type Code",
         "type": "text",
         "varName": "effectTypeCode",
         "grid": 6,
         "readOnly":true,
         "conditional": true,
         "conditionalVarName": "numberOfSeDemographics",
         "conditionalVarValue": "YES",
     },*/
    /* {
         "label": "Term Owner Legal",
         "type": "text",
         "varName": "termOwnerLegal",
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "numberOfSeDemographics",
         "conditionalVarValue": "YES",
     },*/
    /*{
         "label": "Term Location Legal",
         "type": "text",
         "varName": "termLocationLegal",
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "numberOfSeDemographics",
         "conditionalVarValue": "YES",
         "maxLength":100,
     },*/
    /*  {
          "label": "Term Zip Legal",
          "type": "text",
          "varName": "termZipLegal",
          "grid": 6,
          "conditional": true,
          "conditionalVarName": "numberOfSeDemographics",
          "conditionalVarValue": "YES",
      },*/
    /* {
         "label": "Merch. Phone Number",
         "type": "text",
         "phonePrefix": true,
         "validation": "phone",
         "varName": "merchPhoneNumber",
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "numberOfSeDemographics",
         "conditionalVarValue": "YES",
     },*/
    {
        "label": "Phone Number Legal",
        "type": "text",
        "validation": "phone",
        "varName": "phoneNumberLegal",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "numberOfSeDemographics",
        "conditionalVarValue": "YES",
    },
    /* {
         "label": "Region Code",
         "type": "autoCompleteValueReturn",
         "enum": [],
         "varName": "regionCode",
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "numberOfSeDemographics",
         "conditionalVarValue": "YES",
     },*/

    {
        "label": "Settlement Account",
        "type": "title",
        "varName": "empty",
        "grid": 12,
    },
    /* {
         "label": "Auto Populate",
         "type": "checkbox",
         "varName": "autoPopulate",
         "onKeyDown": true,
         "grid": 6,
     },*/
    {
        "label": "Curr.",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "varName": "settlementCurr",
        "grid": 6,
        "required":true,
    },
    /*  {
          "label": "1 Tire ACT",
          "type": "text",
          "readOnly":true,
          "varName": "settlementOneTireAct",
          "grid": 6,
          "required":true,
      },
      {
          "label": "2 Tire ACT",
          "type": "text",
          "readOnly":true,
          "varName": "settlementTwoTireAct",
          "grid": 6,
          "required":true,
      },*/
    {
        "label": "Settlement Number",
        "type": "text",
        "varName": "settlementNumber",
        "grid": 6,
        "required":true,
    },
    /*  {
          "label": "State",
          "type": "text",
          "varName": "settlementState",
          "validation": "numeric",
          "grid": 6,
          "required":true,
      },*/
    {
        "label": "Currency",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "varName": "settlementCurrency",
        "grid": 6,
        "required":true,
    },
    /* {
         "label": "Name",
         "type": "text",
         "varName": "settlementName",
         "grid": 6,
         "required":true,
     },
     {
         "label": "Comment",
         "type": "text",
         "multiline": true,
         "varName": "settlementComment",
         "grid": 6,
         "required":true,
     },*/
    {
        "label": "Transit Account",
        "type": "title",
        "varName": "empty",
        "grid": 12,
    },
    /* {
         "label": "Auto Populate",
         "type": "checkbox",
         "varName": "autoPopulate",
         "onKeyDown": true,
         "grid": 6,
     },*/
    /*  {
          "label": "1 Tire ACT",
          "type": "text",
          "readOnly":true,
          "varName": "transitOneTireAct",
          "grid": 6,
      },
      {
          "label": "2 Tire ACT",
          "type": "text",
          "readOnly":true,
          "varName": "transitTwoTireAct",
          "grid": 6,
      },*/
    {
        "label": "Transit Account Avaailable?",
        "type": "select",
        "enum":[
            "YES",
            "NO"
        ],
        "required":true,
        "varName": "transitAccountAvaailable",
        "grid": 6,
    },
    {
        "label": "Settlement Number",
        "type": "text",
        "varName": "transitNumber",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "transitAccountAvaailable",
        "conditionalVarValue": "YES",
        "required":true,
    },
    /*  {
          "label": "State",
          "type": "text",
          "varName": "transitState",
          "readOnly":true,
          "grid": 6,
      },*/
    {
        "label": "Currency",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "varName": "transitCurrency",
        "grid": 6,
    },
    {
        "label": "Sub Branch",
        "type": "text",
        "varName": "subBranchId",
        "readOnly":true,
        "grid": 6,
    },
    /* {
         "label": "Name",
         "type": "text",
         "validation": "string",
         "varName": "transitName",
         "grid": 6,
     },
     {
         "label": "Comment",
         "type": "text",
         "multiline": true,
         "varName": "transitComment",
         "grid": 6,
     },*/
    {
        "label": "Terminal Creation",
        "type": "title",
        "varName": "empty",
        "grid": 12,
    },
    /*  {
          "label": "Auto Populate",
          "type": "checkbox",
          "varName": "autoPopulate",
          "onKeyDown": true,
          "grid": 6,
      },*/
    {
        "label": "TID",
        "type": "text",
        "varName": "terminalTID",
        "required": true,
        "maxLength":8,
        "grid": 6,
    },
    /*{
        "label": "Name (Dba Name)",
        "type": "text",
        "maxLength":39,
        "varName": "terminalDbaName",
        "required": true,
        "grid": 6,
    },*/
    {
        "label": "Type",
        "type": "text",
        "readOnly":true,
        "varName": "terminalType",
        "required": true,
        "grid": 6,
    },
    {
        "label": "Time Offset",
        "type": "text",
        "varName": "timeOffset",
        "readOnly":true,
        "required": true,
        "grid": 6,
    },
    {
        "label": "Currency",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "varName": "terminalCurrency",
        "required": true,
        "grid": 6,
    },
    /*  {
          "label": "Address",
          "type": "text",
          "multiline": true,
          "varName": "terminalAddress",
          "required": true,
          "grid": 6,
      },*/
    /* {
         "label": "MCC",
         "type": "text",
         "varName": "terminalMcc",
         "required": true,
         "grid": 6,
     },*/
    {
        "label": "Comments",
        "type": "text",
        "varName": "terminalComments",
        "required": true,
        "grid": 6,
    },
    {
        "label": "Prototype",
        "type": "autoCompleteValueReturn",
        "varName": "terminalPrototype",
        "enum":[
            {
                "key": "90000000",
                "value": "PROTOTYPE TID NO MANUAL KEY BATCH UPLOAD->90000000"
            } ,
            {
                "key": "30000000",
                "value": "PROTOTYPE TID FOR QR->30000000"
            }
        ],
        "grid": 6,
    },
    {
        "label": "Contactless Capable",
        "type": "text",
        "varName": "terminalContactlessCapable",
        "grid": 6,
    }
];



let HombujsonFormReadOnly = {};
HombujsonFormReadOnly = makeReadOnlyObject(JSON.parse(JSON.stringify(merchantDstJsonForm)));

let merchantMakerJsonForm = [
    {
        "label": "Merchant type",
        "type": "text",
        "varName": "merchantType",
        "grid": 12,
        "required": true,
        "readOnly": true
    },
    {
        "label": "Merchant Name",
        "type": "text",
        "varName": "merchantName",
        "grid": 12,
        "multiline": true,
        "required": true,
        "readOnly": true
    },
    {
        "label": "Proprietor Name",
        "type": "text",
        "varName": "proprietorName",
        "grid": 12,
        "multiline": true,
        "required": true,
        "readOnly": true
    },
    {
        "label": "DBA",
        "type": "text",
        "varName": "dba",
        "grid": 12, "readOnly": true,
    },
    {
        "label": "Key Contact Person Number",
        "type": "text",
        "varName": "mobileNumber",
        "grid": 12, "readOnly": true,
    },
    {
        "label": "FULL NAME",
        "type": "text",
        "varName": "fullName",
        "grid": 12, "readOnly": true,
        "multiline": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "COUNTRY CODE",
        "type": "text",
        "varName": "generalCountryCode",
        "grid": 12, "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "REGION CODE",
        "type": "select",
        "varName": "generalRegionCode",
        "enum": [
            "1212",
            "1213",
            "1214",
        ],
        "grid": 12, "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "CITY CODE",
        "type": "select",
        "varName": "generalCityCode",
        "enum": [
            "1212",
            "1213",
            "1214",
        ],
        "grid": 12, "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "ZIP CODE",
        "type": "select",
        "varName": "generalZipCode",
        "enum": [
            "1212",
            "1213",
            "1214",
        ],
        "grid": 12, "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "CORPORATE ADDRESS",
        "type": "text",
        "varName": "generalCorporateAddress",
        "grid": 12, "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "MOBILE NO",
        "type": "text",
        "varName": "generalMobileNo",
        "grid": 12, "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "EMAIL ADDRESS",
        "type": "text",
        "varName": "generalEmailAddress",
        "grid": 12, "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "COUNTRY CODE",
        "type": "text",
        "varName": "contactsCountryCode",
        "grid": 12, "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "REGION CODE",
        "type": "select",
        "varName": "contactsRegionCode",
        "enum": [
            "1212",
            "1213",
            "1214",
        ],
        "grid": 12, "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "CITY CODE",
        "type": "select",
        "varName": "contactsCityCode",
        "enum": [
            "1212",
            "1213",
            "1214",
        ],
        "readOnly": true,
        "grid": 12,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "ZIP CODE",
        "type": "select",
        "varName": "contactsZipCode",
        "enum": [
            "1212",
            "1213",
            "1214",
        ],
        "grid": 12, "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "CORPORATE ADDRESS",
        "type": "text",
        "varName": "contactsCorporateAddress",
        "grid": 12, "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "MOBILE NO",
        "type": "text",
        "varName": "contactsMobileNo",
        "grid": 12, "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "EMAIL ADDRESS",
        "type": "text",
        "varName": "contactsEmailAddress",
        "grid": 12, "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "COUNTRY CODE",
        "type": "text",
        "varName": "additionalCountryCode",
        "grid": 12, "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "REGION CODE",
        "type": "select",
        "varName": "additionalRegionCode",
        "enum": [
            "1212",
            "1213",
            "1214",
        ],
        "grid": 12, "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "CITY CODE",
        "type": "select",
        "varName": "additionalCityCode",
        "enum": [
            "1212",
            "1213",
            "1214",
        ],
        "grid": 12, "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "ZIP CODE",
        "type": "select",
        "varName": "additionalZipCode",
        "enum": [
            "1212",
            "1213",
            "1214",
        ],
        "grid": 12, "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "CORPORATE ADDRESS",
        "type": "text",
        "varName": "additionalCorporateAddress",
        "grid": 12, "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "MOBILE NO",
        "type": "text",
        "varName": "additionalMobileNo",
        "grid": 12, "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "EMAIL ADDRESS",
        "type": "text",
        "varName": "additionalEmailAddress",
        "grid": 12, "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "AGREEMENT SIGN UP DATE",
        "type": "date",
        "varName": "agreementSignUpDate",
        "grid": 12, "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "DSE CODE",
        "type": "text",
        "varName": "dseCode",
        "grid": 12, "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "ACCOUNT NO",
        "type": "text",
        "varName": "accountNo",
        "grid": 12, "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "merchantType",
        "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "COUNTRY CODE",
        "type": "text",
        "varName": "countryCode",
        "grid": 12, "readOnly": true,
        "conditional": true,
        "conditionalVarName": "merchantType",
        "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "REGION CODE",
        "type": "select",
        "varName": "regionCode",
        "enum": [
            "1212",
            "1213",
            "1214",
        ],
        "grid": 12, "readOnly": true,
        "conditional": true,
        "conditionalVarName": "merchantType",
        "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "CITY CODE",
        "type": "select",
        "varName": "cityCode",
        "enum": [
            "1212",
            "1213",
            "1214",
        ],
        "readOnly": true,
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "merchantType",
        "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "ZIP CODE",
        "type": "select",
        "varName": "zipCode",
        "enum": [
            "1212",
            "1213",
            "1214",
        ],
        "grid": 12, "readOnly": true,
        "conditional": true,
        "conditionalVarName": "merchantType",
        "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "PHYSICAL ADDRESS",
        "type": "text",
        "varName": "physicalAddress",
        "grid": 12, "readOnly": true,
        "conditional": true,
        "conditionalVarName": "merchantType",
        "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "MOBILE NO",
        "type": "text",
        "varName": "mobileNo",
        "grid": 12, "readOnly": true,
        "conditional": true,
        "conditionalVarName": "merchantType",
        "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "EMAIL ADDRESS",
        "type": "text",
        "varName": "emailAddress",
        "grid": 12, "readOnly": true,
        "conditional": true,
        "conditionalVarName": "merchantType",
        "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "Location",
        "type": "text",
        "varName": "location",
        "grid": 12, "readOnly": true,
        "conditional": true,
        "conditionalVarName": "merchantType",
        "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "FIN. PROFILe",
        "type": "select",
        "varName": "finacleProfile",
        "enum": [
            "AMEX",
            "VISA",
            "MC",
            "CUP",
            "ON US/OFF US"
        ],
        "grid": 12, "readOnly": true,
        "conditional": true,
        "conditionalVarName": "merchantType",
        "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "POS GROUP",
        "type": "text",
        "varName": "posGroup",
        "grid": 12, "readOnly": true,
        "conditional": true,
        "conditionalVarName": "merchantType",
        "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "OWNER NAME",
        "type": "text",
        "varName": "ownerName",
        "grid": 12, "readOnly": true,
        "conditional": true,
        "conditionalVarName": "merchantType",
        "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "ACCOUNT TYPE NOTE",
        "type": "text",
        "varName": "accountTypeNote",
        "grid": 4,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "merchantType",
        "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "MAX AMOUNT PER TXN",
        "type": "text",
        "varName": "maxAmountPerTxn",
        "grid": 12, "readOnly": true,
        "conditional": true,
        "conditionalVarName": "merchantType",
        "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "MAX TXN PER MONTH",
        "type": "text",
        "varName": "maxTxnPerMonth",
        "grid": 12, "readOnly": true,
        "conditional": true,
        "conditionalVarName": "merchantType",
        "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "MAX NUMBER OF TNX PER MONTH",
        "type": "text",
        "varName": "maxNumberOfTxnPerMonth",
        "grid": 12, "readOnly": true,
        "conditional": true,
        "conditionalVarName": "merchantType",
        "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "ABABIL ACCOUNT NUMBER",
        "type": "text",
        "varName": "ababilAccountNumber",
        "grid": 12, "readOnly": true,
        "conditional": true,
        "conditionalVarName": "merchantType",
        "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "MCFACILITATOR",
        "type": "text",
        "varName": "mcfacilitator",
        "grid": 12, "readOnly": true,
        "conditional": true,
        "conditionalVarName": "merchantType",
        "conditionalVarValue": "Sole Proprietorship"
    },

    {
        "label": "MEMBER OF SE DEMOGRAPHICS ",
        "type": "title",
        "varName": "empty",
        "grid": 12,
    },
    {
        "label": "MEMBER OF SE DEMOGRAPHICS ",
        "type": "text",
        "varName": "memberOfSeDemographics",
        "grid": 12, "readOnly": true,
    },


    {
        "label": "INSTITUTION ID ",
        "type": "text",
        "varName": "institutionId",
        "grid": 12, "readOnly": true,
    },
    {
        "label": "BUSINESS CENTER ",
        "type": "text",
        "varName": "businessCenter",
        "grid": 12, "readOnly": true,
    },
    {
        "label": "DESCRIPT. BILL. NAME ",
        "type": "text",
        "varName": "descriptBillName",
        "grid": 12, "readOnly": true,
    },
    {
        "label": "ADDRESS LINE ",
        "type": "text",
        "multiline": true,
        "varName": "addressLine",
        "grid": 12, "readOnly": true,
    },
    {
        "label": "AID ",
        "type": "text",
        "varName": "aid",
        "grid": 12, "readOnly": true,
    },
    {
        "label": "MANDATORY FIELD CONTROL ",
        "type": "text",
        "varName": "mandatoryFiledControl",
        "grid": 12, "readOnly": true,
    },
    {
        "label": "STATUS",
        "type": "text",
        "varName": "status",
        "grid": 12, "readOnly": true,
    },
    {
        "label": "REASON ",
        "type": "text",
        "varName": "reason",
        "grid": 12, "readOnly": true,
    },
    {
        "label": "ACTIVE DATE ",
        "type": "date",
        "varName": "activeDate",
        "grid": 12, "readOnly": true,
    },
    {
        "label": "CANCELLED DATE",
        "type": "date",
        "varName": "cancelledDate",
        "grid": 12, "readOnly": true,
    },
    {
        "label": "REINSTATED DATE ",
        "type": "date",
        "varName": "reinstatedDate",
        "grid": 12, "readOnly": true,
    },
    {
        "label": "ROLE TYPE CODE ",
        "type": "text",
        "varName": "roleTypeCode",
        "grid": 12, "readOnly": true,
    },
    {
        "label": "IND. QUALITY CODE",
        "type": "text",
        "varName": "indQualityCode",
        "grid": 12, "readOnly": true,
    },
    {
        "label": "LOCATION CODE",
        "type": "text",
        "varName": "locationCode",
        "grid": 12, "readOnly": true,
    },
    {
        "label": "SUBMISSION CITY",
        "type": "text",
        "varName": "submissionCity",
        "grid": 12, "readOnly": true,
    }, {
        "label": "SE SETTLE CCY ",
        "type": "text",
        "varName": "seSettleCCY",
        "grid": 12, "readOnly": true,
    }, {
        "label": "SUBM. CODE ",
        "type": "text",
        "varName": "submCode",
        "grid": 12, "readOnly": true,
    }, {
        "label": "SETTLE CODE",
        "type": "text",
        "varName": "settleCode",
        "grid": 12, "readOnly": true,
    }, {
        "label": "RESOURSE STATUS",
        "type": "text",
        "varName": "resorseStatus",
        "grid": 12, "readOnly": true,
    }, {
        "label": "RISK INDICATOR",
        "type": "text",
        "varName": "riskIndicator",
        "grid": 12, "readOnly": true,
    }, {
        "label": "ACCOUNT INDICATOR",
        "type": "text",
        "varName": "accountIndicator",
        "grid": 12, "readOnly": true,
    }, {
        "label": "VISA INDICATOR ",
        "type": "text",
        "varName": "visaIndicator",
        "grid": 12, "readOnly": true,
    }, {
        "label": "MASTERCARD INDICATOR",
        "type": "text",
        "varName": "masterCardIndicator",
        "grid": 12, "readOnly": true,
    }, {
        "label": "OTHER CARD INDICATOR",
        "type": "text",
        "varName": "otherCardIndicator",
        "grid": 12, "readOnly": true,
    }, {
        "label": "FLOOR LIMIT CCY",
        "type": "text",
        "varName": "floorLimitCCY",
        "grid": 12, "readOnly": true,
    }, {
        "label": "HIGN/LOW INDICATOR",
        "type": "text",
        "varName": "highLowIndicator",
        "grid": 12, "readOnly": true,
    }, {
        "label": "EFFECT TYPE CODE",
        "type": "text",
        "varName": "effectTypeCode",
        "grid": 12, "readOnly": true,
    }, {
        "label": "TERM OWNER LEGAL",
        "type": "text",
        "varName": "termOwnerLegal",
        "grid": 12, "readOnly": true,
    }, {
        "label": "TERM LOCATION LEGAL",
        "type": "text",
        "varName": "termLocationLegal",
        "grid": 12, "readOnly": true,
    }, {
        "label": "TERM ZIP LEGAL",
        "type": "text",
        "varName": "termZipLegal",
        "grid": 12, "readOnly": true,
    }, {
        "label": "REGION CODE",
        "type": "text",
        "varName": "regionCode",
        "grid": 12, "readOnly": true,
    }, {
        "label": "CURR.",
        "type": "text",
        "varName": "settlementCurr",
        "grid": 12, "readOnly": true,
    }, {
        "label": "1 TIRE ACT ",
        "type": "text",
        "varName": "settlementOneTireAct",
        "grid": 12, "readOnly": true,
    }, {
        "label": "2 TIRE ACT",
        "type": "text",
        "varName": "settlementTwoTireAct",
        "grid": 12, "readOnly": true,
    }, {
        "label": "SETTLEMENT NUMBER",
        "type": "text",
        "varName": "settlementNumber",
        "grid": 12, "readOnly": true,
    }, {
        "label": "STATE",
        "type": "text",
        "varName": "settlementState",
        "grid": 12, "readOnly": true,
    }, {
        "label": "CURRENCY",
        "type": "text",
        "varName": "settlementCurrency",
        "grid": 12, "readOnly": true,
    }, {
        "label": "NAME",
        "type": "text",
        "varName": "settlementName",
        "grid": 12, "readOnly": true,
    }, {
        "label": "COMMENT",
        "type": "text",
        "multiline": true,
        "varName": "settlementComment",
        "grid": 12, "readOnly": true,
    },
    {
        "label": "1 TIRE ACT ",
        "type": "text",
        "varName": "transitOneTireAct",
        "grid": 12, "readOnly": true,
    }, {
        "label": "2 TIRE ACT",
        "type": "text",
        "varName": "transitTwoTireAct",
        "grid": 12, "readOnly": true,
    }, {
        "label": "SETTLEMENT NUMBER",
        "type": "text",
        "varName": "transitNumber",
        "grid": 12, "readOnly": true,
    }, {
        "label": "STATE",
        "type": "text",
        "varName": "transitState",
        "grid": 12, "readOnly": true,
    }, {
        "label": "CURRENCY",
        "type": "text",
        "varName": "transitCurrency",
        "grid": 12, "readOnly": true,
    }, {
        "label": "NAME",
        "type": "text",
        "varName": "transitName",
        "grid": 12, "readOnly": true,
    }, {
        "label": "COMMENT",
        "type": "text",
        "multiline": true,
        "varName": "transitComment",
        "grid": 12, "readOnly": true,
    },

    {
        "label": "TID",
        "type": "text",
        "varName": "terminalTID",
        "grid": 12, "readOnly": true,
    }, {
        "label": "NAME (DBA NAME)",
        "type": "text",
        "varName": "terminalDbaName",
        "grid": 12, "readOnly": true,
    }, {
        "label": "TYPE",
        "type": "text",
        "varName": "terminalType",
        "grid": 12, "readOnly": true,
    }, {
        "label": "TIME OFFSET",
        "type": "text",
        "varName": "timeOffset",
        "grid": 12, "readOnly": true,
    }, {
        "label": "CURRENCY",
        "type": "text",
        "varName": "terminalCurrency",
        "grid": 12, "readOnly": true,
    }, {
        "label": "ADDRESS",
        "type": "text",
        "multiline": true,
        "varName": "terminalAddress",
        "grid": 12, "readOnly": true,
    }, {
        "label": "MCC ",
        "type": "text",
        "varName": "terminalMcc",
        "grid": 12, "readOnly": true,
    }, {
        "label": "COMMENTS",
        "type": "text",
        "varName": "terminalComments",
        "grid": 12, "readOnly": true,
    }, {
        "label": "PROTOTYPE",
        "type": "text",
        "varName": "terminalPrototype",
        "grid": 12, "readOnly": true,
    }, {
        "label": "CONTACTLESS CAPABLE",
        "type": "text",
        "varName": "terminalContactlessCapable",
        "grid": 12, "readOnly": true,
    }
];

const newSignatoryForm = [
    {
        "varName": "title",
        "type": "title",
        "label": "New Mandate",
        "grid": 12,
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "CB Number",
        "grid": 6,
    },
    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name",
        "grid": 6,
    },
    {
        "varName": "motherAccountSolId",
        "type": "text",
        "label": "Mother Account Sol Id",
        "grid": 6,
    },
    {
        "varName": "fdr",
        "type": "text",
        "label": "FDR",
        "grid": 6,
    },
    {
        "varName": "dps",
        "type": "text",
        "label": "DPS",
        "grid": 6,
    },
    {
        "varName": "loanAc",
        "type": "text",
        "label": "Loan A/c",
        "grid": 6,
    },
    {
        "varName": "freezeStatus",
        "type": "text",
        "label": "Freeze Status",
        "grid": 6,
    },
    {
        "varName": "dormancy",
        "type": "text",
        "label": "Dormancy",
        "grid": 6,
    },
    {
        "varName": "adup",
        "type": "text",
        "label": "ADUP",
        "grid": 6,
    },
    {
        "varName": "ctr",
        "type": "text",
        "label": "CTR",
        "grid": 6,
    },
    {
        "varName": "CustomerIdCifId",
        "type": "text",
        "label": "Customer ID/CIF ID",
        "grid": 6,
    },

    {
        "varName": "title",
        "type": "text",
        "label": "Title",
        "grid": 6,
    },

    {
        "varName": "CustomerNameLastName",
        "type": "text",
        "label": "Customer Name/Last Name",
        "grid": 6,
    },

    {
        "varName": "ShortName",
        "type": "text",
        "label": "Short Name",
        "grid": 6,
    },

    {
        "varName": "Gender",
        "type": "radio",
        "label": "Gender",
        "enum": [
            "Male",
            "Female"
        ],
        "grid": 6,
    },

    {
        "varName": "CustomerStatus(NormalStaffPriorityEtc)",
        "type": "text",
        "label": "Customer Status (Normal/Staff/Priority etc.)",
        "grid": 6,
    },

    {
        "varName": "OccupationCodePriorityCodeStaff",
        "type": "text",
        "label": "Occupation Code/Priority Code/Staff",
        "grid": 6,
    },

    {
        "varName": "Constitution",
        "type": "text",
        "label": "Constitution",
        "grid": 6,
    },

    {
        "varName": "StaffIndicatorStatus",
        "type": "text",
        "label": "Staff Indicator/Status",
        "grid": 6,
    },

    {
        "varName": "StaffIdNumber",
        "type": "text",
        "label": "Staff ID Number",
        "grid": 6,
    },

    {
        "varName": "PriorityCenterCodeFreeCode1",
        "type": "text",
        "label": "Priority Center Code/Free Code 1",
        "grid": 6,
    },

    {
        "varName": "PriorityMonitoringRmCodeFreeCode3",
        "type": "text",
        "label": "Priority Monitoring  RM Code/Free Code 3",
        "grid": 6,
    },

    {
        "varName": "PrimaryCbHolderForPriorityFreeText8",
        "type": "text",
        "label": "Primary CB Holder for Priority/Free Text 8",
        "grid": 6,
    },

    {
        "varName": "RelationshipWithPrimaryCbHolderFreeText9",
        "type": "text",
        "label": "Relationship with Primary CB Holder/Free Text 9",
        "grid": 6,
    },

    {
        "varName": "BbkSegmentCodeFreeCode7",
        "type": "text",
        "label": "BBK Segment Code/Free Code 7",
        "grid": 6,
    },

    {
        "varName": "DebitCardIssueFreeCode10",
        "type": "text",
        "label": "Debit Card Issue/Free Code 10",
        "grid": 6,
    },

    {
        "varName": "MigratedNidVerificationFreeText2",
        "type": "text",
        "label": "Migrated NID Verification/Free Text 2",
        "grid": 6,
    },

    {
        "varName": "MigratedSmartCardNumberFreeText5",
        "type": "text",
        "label": "Migrated Smart Card Number Free Text 5",
        "grid": 6,
    },

    {
        "varName": "PepStatusFreeText10",
        "type": "text",
        "label": "PEP Status/Free Text 10",
        "grid": 6,
    },

    {
        "varName": "MigratedCifsDocumentNoFreeText13",
        "type": "text",
        "label": "Migrated CIFs Document No/Free Text 13",
        "grid": 6,
    },

    {
        "varName": "FreeCodeFreeText",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "FreeCodeFreeText",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "FreeCodeFreeText",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "AvailedTradeServices",
        "type": "text",
        "label": "Availed Trade services",
        "grid": 6,
    },

    {
        "varName": "CurrencyCodeCcy",
        "type": "text",
        "label": "Currency Code/CCY",
        "grid": 6,
    },

    {
        "varName": "CommAddressSelection",
        "type": "text",
        "label": "Comm. Address Selection ",
        "grid": 6,
    },

    {
        "varName": "EmailDesignatedEmailAddress",
        "type": "text",
        "label": "Email/Designated Email Address",
        "grid": 6,
    },

    {
        "varName": "DesignatedMobileNumberPhoneNo1",
        "type": "text",
        "label": "Designated Mobile Number/Phone No 1",
        "grid": 6,
    },

    {
        "varName": "IntroducerCustomerIdCifId",
        "type": "text",
        "label": "Introducer Customer Id/CIF ID",
        "grid": 6,
    },

    {
        "varName": "IntroducerNameLastName",
        "type": "text",
        "label": "Introducer Name/Last Name",
        "grid": 6,
    },

    {
        "varName": "IintroducerStatus",
        "type": "text",
        "label": "Introducer Status",
        "grid": 6,
    },

    {
        "varName": "CifTypePrimaryIntroducerDetails>CifType",
        "type": "text",
        "label": "CIF Type/Primary Introducer Details>CIF Type",
        "grid": 6,
    },

    {
        "varName": "BankName",
        "type": "text",
        "label": "Bank Name",
        "grid": 6,
    },

    {
        "varName": "BranchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 6,
    },

    {
        "varName": "SubSegment",
        "type": "text",
        "label": "Sub Segment",
        "grid": 6,
    },

    {
        "varName": "PrimarySolId",
        "type": "text",
        "label": "Primary Sol ID",
        "grid": 6,
    },

    {
        "varName": "FatherNamePrefferedName",
        "type": "text",
        "label": "Father Name/Preffered Name",
        "grid": 6,
    },

    {
        "varName": "MotherNameMothersMaidenName",
        "type": "text",
        "label": "Mother Name/Mothers Maiden Name",
        "grid": 6,
    },

    {
        "varName": "MaritalStatus",
        "type": "select",
        "enum": ["Married", "Unmarried"],
        "label": "Marital Status",
        "grid": 6,
    },

    {
        "varName": "SpouseNamePurgeRemarks",
        "type": "text",
        "label": "Spouse Name/Purge Remarks",
        "grid": 6,
    },

    {
        "varName": "Dob",
        "type": "date",
        "label": "DOB",
        "grid": 6,
    },

    {
        "varName": "MinorStatusMinorIndicator",
        "type": "text",
        "label": "Minor Status/Minor Indicator",
        "grid": 6,
    },

    {
        "varName": "SeniorCitizenIndicator",
        "type": "text",
        "label": "Senior Citizen Indicator",
        "grid": 6,
    },

    {
        "varName": "Relation",
        "type": "text",
        "label": "Relation",
        "grid": 6,
    },

    {
        "varName": "BankRelationType",
        "type": "text",
        "label": "Bank Relation Type",
        "grid": 6,
    },

    {
        "varName": "GuardianSelectionGurdian",
        "type": "text",
        "label": "Guardian Selection/Gurdian",
        "grid": 6,
    },

    {
        "varName": "GurdianCifNumberCustomer",
        "type": "text",
        "label": "Gurdian CIF Number/Customer ",
        "grid": 6,
    },

    {
        "varName": "LastName",
        "type": "text",
        "label": "Last Name",
        "grid": 6,
    },

    {
        "varName": "DocumentType",
        "type": "text",
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "DocumentTypeDescription",
        "type": "text",
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "DocumentCode",
        "type": "text",
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "NationalIdCardNumberUniqueId",
        "type": "text",
        "label": "National ID Card Number/Unique ID",
        "grid": 6,
    },

    {
        "varName": "NidVerificationConfirmationIsDocumentVerified",
        "type": "text",
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
    },

    {
        "varName": "IssueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
    },

    {
        "varName": "DocumentType",
        "type": "text",
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "DocumentTypeDescription",
        "type": "text",
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "DocumentCode",
        "type": "text",
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "SmartCardNumberUniqueId",
        "type": "text",
        "label": "Smart Card Number/Unique ID",
        "grid": 6,
    },

    {
        "varName": "NidVerificationConfirmationIsDocumentVerified",
        "type": "text",
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
    },

    {
        "varName": "IssueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
    },

    {
        "varName": "DocumentType",
        "type": "text",
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "DocumentTypeDescription",
        "type": "text",
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "DocumentCode",
        "type": "text",
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "PassportNumberUniqueId",
        "type": "text",
        "label": "Passport Number/Unique ID",
        "grid": 6,
    },

    {
        "varName": "PlaceOfIssue",
        "type": "text",
        "label": "Place of Issue",
        "grid": 6,
    },

    {
        "varName": "IssueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
    },

    {
        "varName": "CountryOfIssue",
        "type": "text",
        "label": "Country of Issue",
        "grid": 6,
    },

    {
        "varName": "ExpiryDate",
        "type": "date",
        "label": "Expiry Date",
        "grid": 6,
    },

    {
        "varName": "DocumentType",
        "type": "text",
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "DocumentTypeDescription",
        "type": "text",
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "DocumentCode",
        "type": "text",
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "BirthCertificateNumberUniqueId",
        "type": "text",
        "label": "Birth Certificate Number/Unique ID",
        "grid": 6,
    },

    {
        "varName": "DocumentType",
        "type": "text",
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "DocumentTypeDescription",
        "type": "text",
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "DocumentCode",
        "type": "text",
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "ChairmanCertificateUniqueId",
        "type": "text",
        "label": "Chairman Certificate/Unique ID",
        "grid": 6,
    },

    {
        "varName": "PlaceOfBirthFreeText14",
        "type": "text",
        "label": "Place of Birth/Free Text 14",
        "maxLength":15,
        "validationB":"length",
        "grid": 6,
    },

    {
        "varName": "CountryOfBirthFreeText15",
        "type": "text",
        "label": "Country of Birth/Free Text 15",
        "grid": 6,
    },

    {
        "varName": "Nationality",
        "type": "text",
        "label": "Nationality",
        "grid": 6,
    },

    {
        "varName": "NonResident",
        "type": "text",
        "label": "Non Resident",
        "grid": 6,
    },

    {
        "varName": "TurnedNonResidentOn",
        "type": "date",
        "label": "Turned Non Resident On",
        "grid": 6,
    },

    {
        "varName": "DocumentType",
        "type": "text",
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "DocumentTypeDescription",
        "type": "text",
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "DocumentCode",
        "type": "text",
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "E-Tin Number",
        "type": "text",
        "label": "E-Tin Number/Unique ID",
        "grid": 6,
    },

    {
        "varName": "ProfessionEmploymentType",
        "type": "text",
        "label": "Profession /Employment Type",
        "grid": 6,
    },

    {
        "varName": "EmploymentType",
        "type": "text",
        "label": "Employment Type",
        "grid": 6,
    },

    {
        "varName": "NameOfEmployerEmployerName",
        "type": "text",
        "label": "Name of Employer/Employer Name",
        "grid": 6,
    },

    {
        "varName": "MonthlyIncomeGrossIncome",
        "type": "text",
        "label": "Monthly Income/Gross income",
        "grid": 6,
    },

    {
        "varName": "PreferredContactNoType",
        "type": "text",
        "label": "Preferred Contact no type",
        "grid": 6,
    },

    {
        "varName": "ContactType",
        "type": "text",
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "PhoneNoEmailId",
        "type": "text",
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "PhoneNo",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "ContactType",
        "type": "text",
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "PhoneNoEmailId",
        "type": "text",
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "PhoneNo",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "ContactType",
        "type": "text",
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "PhoneNoEmailId",
        "type": "text",
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "PhoneNo",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "PreferredEmailIdType",
        "type": "text",
        "label": "Preferred Email ID type",
        "grid": 6,
    },

    {
        "varName": "EmailType",
        "type": "text",
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "PhoneNoEmailId",
        "type": "text",
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "DesignatedEmailAddressEmailId1",
        "type": "text",
        "label": "Designated Email Address/Email ID 1",
        "grid": 6,
    },

    {
        "varName": "EmailType",
        "type": "text",
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "PhoneNoEmailId",
        "type": "text",
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "EmailId2",
        "type": "text",
        "label": "Email ID 2",
        "grid": 6,
    },

    {
        "varName": "PreferredAddressType>AddAddressDetails",
        "type": "text",
        "label": "Preferred Address type>Add Address Details",
        "grid": 6,
    },

    {
        "varName": "MailingAddressFormat",
        "type": "text",
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "MailingAddressType",
        "type": "text",
        "label": "Address type",
        "grid": 6,
    },

    {
        "varName": "MailingAddressLabel",
        "type": "text",
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "MailingAddressField1",
        "type": "text",
        "label": "Mailing Address Field 1",
        "grid": 6,
    },

    {
        "varName": "MailingAddressField2",
        "type": "text",
        "label": "Mailing Address Field 2",
        "grid": 6,
    },

    {
        "varName": "HomeCity",
        "type": "text",
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "HomeState",
        "type": "text",
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "HomePostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "HomeCountry",
        "type": "text",
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "HomeAddressFormat",
        "type": "text",
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "HomeAddressType",
        "type": "text",
        "label": "Address type",
        "grid": 6,
    },

    {
        "varName": "HomeAddressLabel",
        "type": "text",
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "HomeAddressField1",
        "type": "text",
        "label": "Home Address Field 1",
        "grid": 6,
    },

    {
        "varName": "HomeAddressField2",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 6,
    },

    {
        "varName": "WorkCity",
        "type": "text",
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "WorkState",
        "type": "text",
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "WorkPostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "WorkCountry",
        "type": "text",
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "WorkAddressFormat",
        "type": "text",
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "WorkAddressType",
        "type": "text",
        "label": "Address type",
        "grid": 6,
    },

    {
        "varName": "WorkAddressLabel",
        "type": "text",
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "WorkAddressField1",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 6,
    },

    {
        "varName": "WorkAddressField2",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 6,
    },

    {
        "varName": "PresentCity",
        "type": "text",
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "PresentState",
        "type": "text",
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "PresentPostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "PresentCountry",
        "type": "text",
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "PresentAddressFormat",
        "type": "text",
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "PresentAddressType",
        "type": "text",
        "label": "Address type",
        "grid": 6,
    },

    {
        "varName": "PresentAddressLabel",
        "type": "text",
        "label": "Address label",
        "grid": 6,
    },
    {
        "varName": "PresentAddressField1",
        "type": "text",
        "label": "Present Address Field 1",
        "grid": 6,
    },
    {
        "varName": "PresentAddressField2",
        "type": "text",
        "label": "Present Address Field 2",
        "grid": 6,
    },
    // {
    //     "varName": "existingCity",
    //     "type": "text",
    //     "label": "City",
    //     "grid": 6,
    // },
    //
    // {
    //     "varName": "existingState",
    //     "type": "text",
    //     "label": "State",
    //     "grid": 6,
    // },
    //
    // {
    //     "varName": "existingPostalCode",
    //     "type": "text",
    //     "label": "Postal Code",
    //     "grid": 6,
    // },
    //
    // {
    //     "varName": "existingCountry",
    //     "type": "text",
    //     "label": "Country",
    //     "grid": 6,
    // },
];
let newSignatoryFormForChecker = {};
newSignatoryFormForChecker = makeReadOnlyObject(JSON.parse(JSON.stringify(newSignatoryForm)));

const signatoryForm = [
    {
        "varName": "title",
        "type": "title",
        "label": "Existing Mandate",
        "grid": 12,
    },
    {
        "varName": "existingCbNumber",
        "type": "text",
        "label": "CB Number",
        "grid": 6,
    },
    {
        "varName": "existingCustomerName",
        "type": "text",
        "label": "Customer Name",
        "grid": 6,
    },
    {
        "varName": "existingPrimarySolId",
        "type": "text",
        "label": "Primary Sol ID",
        "grid": 6,
    },

    {
        "varName": "existingCustomerIdCifId",
        "type": "text",
        "label": "Customer ID/CIF ID",
        "grid": 6,
    },

    {
        "varName": "Existingtitle",
        "type": "text",
        "label": "Title",
        "grid": 6,
    },

    {
        "varName": "existingCustomerNameLastName",
        "type": "text",
        "label": "Customer Name/Last Name",
        "grid": 6,
    },

    {
        "varName": "existingShortName",
        "type": "text",
        "label": "Short Name",
        "grid": 6,
    },

    {
        "varName": "existingGender",
        "type": "radio",
        "label": "Gender",
        "enum": [
            "Male",
            "Female"
        ],
        "grid": 6,
    },

    {
        "varName": "existingCustomerStatus(NormalStaffPriorityEtc)",
        "type": "text",
        "label": "Customer Status (Normal/Staff/Priority etc.)",
        "grid": 6,
    },

    {
        "varName": "existingOccupationCodePriorityCodeStaff",
        "type": "text",
        "label": "Occupation Code/Priority Code/Staff",
        "grid": 6,
    },

    {
        "varName": "existingConstitution",
        "type": "text",
        "label": "Constitution",
        "grid": 6,
    },

    {
        "varName": "existingStaffIndicatorStatus",
        "type": "text",
        "label": "Staff Indicator/Status",
        "grid": 6,
    },

    {
        "varName": "existingStaffIdNumber",
        "type": "text",
        "label": "Staff ID Number",
        "grid": 6,
    },

    {
        "varName": "existingPriorityCenterCodeFreeCode1",
        "type": "text",
        "label": "Priority Center Code/Free Code 1",
        "grid": 6,
    },

    {
        "varName": "existingPriorityMonitoringRmCodeFreeCode3",
        "type": "text",
        "label": "Priority Monitoring  RM Code/Free Code 3",
        "grid": 6,
    },

    {
        "varName": "existingPrimaryCbHolderForPriorityFreeText8",
        "type": "text",
        "label": "Primary CB Holder for Priority/Free Text 8",
        "grid": 6,
    },

    {
        "varName": "existingRelationshipWithPrimaryCbHolderFreeText9",
        "type": "text",
        "label": "Relationship with Primary CB Holder/Free Text 9",
        "grid": 6,
    },

    {
        "varName": "existingBbkSegmentCodeFreeCode7",
        "type": "text",
        "label": "BBK Segment Code/Free Code 7",
        "grid": 6,
    },

    {
        "varName": "existingDebitCardIssueFreeCode10",
        "type": "text",
        "label": "Debit Card Issue/Free Code 10",
        "grid": 6,
    },

    {
        "varName": "existingMigratedNidVerificationFreeText2",
        "type": "text",
        "label": "Migrated NID Verification/Free Text 2",
        "grid": 6,
    },

    {
        "varName": "existingMigratedSmartCardNumberFreeText5",
        "type": "text",
        "label": "Migrated Smart Card Number Free Text 5",
        "grid": 6,
    },

    {
        "varName": "existingPepStatusFreeText10",
        "type": "text",
        "label": "PEP Status/Free Text 10",
        "grid": 6,
    },

    {
        "varName": "existingMigratedCifsDocumentNoFreeText13",
        "type": "text",
        "label": "Migrated CIFs Document No/Free Text 13",
        "grid": 6,
    },

    {
        "varName": "existingFreeCodeFreeText",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "existingFreeCodeFreeText",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "existingFreeCodeFreeText",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "existingAvailedTradeServices",
        "type": "text",
        "label": "Availed Trade services",
        "grid": 6,
    },

    {
        "varName": "existingCurrencyCodeCcy",
        "type": "text",
        "label": "Currency Code/CCY",
        "grid": 6,
    },

    {
        "varName": "existingCommAddressSelection",
        "type": "text",
        "label": "Comm. Address Selection ",
        "grid": 6,
    },

    {
        "varName": "existingEmailDesignatedEmailAddress",
        "type": "text",
        "label": "Email/Designated Email Address",
        "grid": 6,
    },

    {
        "varName": "existingDesignatedMobileNumberPhoneNo1",
        "type": "text",
        "label": "Designated Mobile Number/Phone No 1",
        "grid": 6,
    },

    {
        "varName": "existingIntroducerCustomerIdCifId",
        "type": "text",
        "label": "Introducer Customer Id/CIF ID",
        "grid": 6,
    },

    {
        "varName": "existingIntroducerNameLastName",
        "type": "text",
        "label": "Introducer Name/Last Name",
        "grid": 6,
    },

    {
        "varName": "existinIintroducerStatus",
        "type": "text",
        "label": "Introducer Status",
        "grid": 6,
    },

    {
        "varName": "existingCifTypePrimaryIntroducerDetails>CifType",
        "type": "text",
        "label": "CIF Type/Primary Introducer Details>CIF Type",
        "grid": 6,
    },

    {
        "varName": "existingBankName",
        "type": "text",
        "label": "Bank Name",
        "grid": 6,
    },

    {
        "varName": "existingBranchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 6,
    },

    {
        "varName": "existingSubSegment",
        "type": "text",
        "label": "Sub Segment",
        "grid": 6,
    },

    {
        "varName": "existingPrimarySolId",
        "type": "text",
        "label": "Primary Sol ID",
        "grid": 6,
    },

    {
        "varName": "existingFatherNamePrefferedName",
        "type": "text",
        "label": "Father Name/Preffered Name",
        "grid": 6,
    },

    {
        "varName": "existingMotherNameMothersMaidenName",
        "type": "text",
        "label": "Mother Name/Mothers Maiden Name",
        "grid": 6,
    },

    {
        "varName": "existingMaritalStatus",
        "type": "select",
        "enum": ["Married", "Unmarried"],
        "label": "Marital Status",
        "grid": 6,
    },

    {
        "varName": "existingSpouseNamePurgeRemarks",
        "type": "text",
        "label": "Spouse Name/Purge Remarks",
        "grid": 6,
    },

    {
        "varName": "existingDob",
        "type": "date",
        "label": "DOB",
        "grid": 6,
    },

    {
        "varName": "existingMinorStatusMinorIndicator",
        "type": "text",
        "label": "Minor Status/Minor Indicator",
        "grid": 6,
    },

    {
        "varName": "existingSeniorCitizenIndicator",
        "type": "text",
        "label": "Senior Citizen Indicator",
        "grid": 6,
    },

    {
        "varName": "existingRelation",
        "type": "text",
        "label": "Relation",
        "grid": 6,
    },

    {
        "varName": "existingBankRelationType",
        "type": "text",
        "label": "Bank Relation Type",
        "grid": 6,
    },

    {
        "varName": "existingGuardianSelectionGurdian",
        "type": "text",
        "label": "Guardian Selection/Gurdian",
        "grid": 6,
    },

    {
        "varName": "existingGurdianCifNumberCustomer",
        "type": "text",
        "label": "Gurdian CIF Number/Customer ",
        "grid": 6,
    },

    {
        "varName": "existingLastName",
        "type": "text",
        "label": "Last Name",
        "grid": 6,
    },

    {
        "varName": "existingDocumentType",
        "type": "text",
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "existingDocumentTypeDescription",
        "type": "text",
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "existingDocumentCode",
        "type": "text",
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "existingNationalIdCardNumberUniqueId",
        "type": "text",
        "label": "National ID Card Number/Unique ID",
        "grid": 6,
    },

    {
        "varName": "existingNidVerificationConfirmationIsDocumentVerified",
        "type": "text",
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
    },

    {
        "varName": "existingIssueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
    },

    {
        "varName": "existingDocumentType",
        "type": "text",
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "existingDocumentTypeDescription",
        "type": "text",
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "existingDocumentCode",
        "type": "text",
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "existingSmartCardNumberUniqueId",
        "type": "text",
        "label": "Smart Card Number/Unique ID",
        "grid": 6,
    },

    {
        "varName": "existingNidVerificationConfirmationIsDocumentVerified",
        "type": "text",
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
    },

    {
        "varName": "existingIssueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
    },

    {
        "varName": "existingDocumentType",
        "type": "text",
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "existingDocumentTypeDescription",
        "type": "text",
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "existingDocumentCode",
        "type": "text",
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "existingPassportNumberUniqueId",
        "type": "text",
        "label": "Passport Number/Unique ID",
        "grid": 6,
    },

    {
        "varName": "existingPlaceOfIssue",
        "type": "text",
        "label": "Place of Issue",
        "grid": 6,
    },

    {
        "varName": "existingIssueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
    },

    {
        "varName": "existingCountryOfIssue",
        "type": "text",
        "label": "Country of Issue",
        "grid": 6,
    },

    {
        "varName": "existingExpiryDate",
        "type": "date",
        "label": "Expiry Date",
        "grid": 6,
    },

    {
        "varName": "existingDocumentType",
        "type": "text",
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "existingDocumentTypeDescription",
        "type": "text",
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "existingDocumentCode",
        "type": "text",
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "existingBirthCertificateNumberUniqueId",
        "type": "text",
        "label": "Birth Certificate Number/Unique ID",
        "grid": 6,
    },

    {
        "varName": "existingDocumentType",
        "type": "text",
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "existingDocumentTypeDescription",
        "type": "text",
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "existingDocumentCode",
        "type": "text",
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "existingChairmanCertificateUniqueId",
        "type": "text",
        "label": "Chairman Certificate/Unique ID",
        "grid": 6,
    },

    {
        "varName": "existingPlaceOfBirthFreeText14",
        "type": "text",
        "label": "Place of Birth/Free Text 14",
        "maxLength":15,
        "validationB":"length",
        "grid": 6,
    },

    {
        "varName": "existingCountryOfBirthFreeText15",
        "type": "text",
        "label": "Country of Birth/Free Text 15",
        "grid": 6,
    },

    {
        "varName": "existingNationality",
        "type": "text",
        "label": "Nationality",
        "grid": 6,
    },

    {
        "varName": "existingNonResident",
        "type": "text",
        "label": "Non Resident",
        "grid": 6,
    },

    {
        "varName": "existingTurnedNonResidentOn",
        "type": "date",
        "label": "Turned Non Resident On",
        "grid": 6,
    },

    {
        "varName": "existingDocumentType",
        "type": "text",
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "existingDocumentTypeDescription",
        "type": "text",
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "existingDocumentCode",
        "type": "text",
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "existingE-Tin Number",
        "type": "text",
        "label": "E-Tin Number/Unique ID",
        "grid": 6,
    },

    {
        "varName": "existingProfessionEmploymentType",
        "type": "text",
        "label": "Profession /Employment Type",
        "grid": 6,
    },

    {
        "varName": "existingEmploymentType",
        "type": "text",
        "label": "Employment Type",
        "grid": 6,
    },

    {
        "varName": "existingNameOfEmployerEmployerName",
        "type": "text",
        "label": "Name of Employer/Employer Name",
        "grid": 6,
    },

    {
        "varName": "existingMonthlyIncomeGrossIncome",
        "type": "text",
        "label": "Monthly Income/Gross income",
        "grid": 6,
    },

    {
        "varName": "existingPreferredContactNoType",
        "type": "text",
        "label": "Preferred Contact no type",
        "grid": 6,
    },

    {
        "varName": "existingContactType",
        "type": "text",
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "existingPhoneNoEmailId",
        "type": "text",
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "existingPhoneNo",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "existingContactType",
        "type": "text",
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "existingPhoneNoEmailId",
        "type": "text",
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "existingPhoneNo",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "existingContactType",
        "type": "text",
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "existingPhoneNoEmailId",
        "type": "text",
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "existingPhoneNo",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "existingPreferredEmailIdType",
        "type": "text",
        "label": "Preferred Email ID type",
        "grid": 6,
    },

    {
        "varName": "existingEmailType",
        "type": "text",
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "existingPhoneNoEmailId",
        "type": "text",
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "existingDesignatedEmailAddressEmailId1",
        "type": "text",
        "label": "Designated Email Address/Email ID 1",
        "grid": 6,
    },

    {
        "varName": "existingEmailType",
        "type": "text",
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "existingPhoneNoEmailId",
        "type": "text",
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "existingEmailId2",
        "type": "text",
        "label": "Email ID 2",
        "grid": 6,
    },

    {
        "varName": "existingPreferredAddressType>AddAddressDetails",
        "type": "text",
        "label": "Preferred Address type>Add Address Details",
        "grid": 6,
    },

    {
        "varName": "existingMailingAddressFormat",
        "type": "text",
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "existingMailingAddressType",
        "type": "text",
        "label": "Address type",
        "grid": 6,
    },

    {
        "varName": "existingMailingAddressLabel",
        "type": "text",
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "existingMailingAddressField1",
        "type": "text",
        "label": "Mailing Address Field 1",
        "grid": 6,
    },

    {
        "varName": "existingMailingAddressField2",
        "type": "text",
        "label": "Mailing Address Field 2",
        "grid": 6,
    },

    {
        "varName": "existingHomeCity",
        "type": "text",
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "existingHomeState",
        "type": "text",
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "existingHomePostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "existingHomeCountry",
        "type": "text",
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "existingHomeAddressFormat",
        "type": "text",
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "existingHomeAddressType",
        "type": "text",
        "label": "Address type",
        "grid": 6,
    },

    {
        "varName": "existingHomeAddressLabel",
        "type": "text",
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "existingHomeAddressField1",
        "type": "text",
        "label": "Home Address Field 1",
        "grid": 6,
    },

    {
        "varName": "existingHomeAddressField2",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 6,
    },

    {
        "varName": "existingWorkCity",
        "type": "text",
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "existingWorkState",
        "type": "text",
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "existingWorkPostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "existingWorkCountry",
        "type": "text",
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "existingWorkAddressFormat",
        "type": "text",
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "existingWorkAddressType",
        "type": "text",
        "label": "Address type",
        "grid": 6,
    },

    {
        "varName": "existingWorkAddressLabel",
        "type": "text",
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "existingWorkAddressField1",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 6,
    },

    {
        "varName": "existingWorkAddressField2",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 6,
    },

    {
        "varName": "existingPresentCity",
        "type": "text",
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "existingPresentState",
        "type": "text",
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "existingPresentPostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "existingPresentCountry",
        "type": "text",
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "existingPresentAddressFormat",
        "type": "text",
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "existingPresentAddressType",
        "type": "text",
        "label": "Address type",
        "grid": 6,
    },

    {
        "varName": "existingPresentAddressLabel",
        "type": "text",
        "label": "Address label",
        "grid": 6,
    },
    {
        "varName": "existingPresentAddressField1",
        "type": "text",
        "label": "Present Address Field 1",
        "grid": 6,
    },
    {
        "varName": "existingPresentAddressField2",
        "type": "text",
        "label": "Present Address Field 2",
        "grid": 6,
    },
    // {
    //     "varName": "existingCity",
    //     "type": "text",
    //     "label": "City",
    //     "grid": 6,
    // },
    //
    // {
    //     "varName": "existingState",
    //     "type": "text",
    //     "label": "State",
    //     "grid": 6,
    // },
    //
    // {
    //     "varName": "existingPostalCode",
    //     "type": "text",
    //     "label": "Postal Code",
    //     "grid": 6,
    // },
    //
    // {
    //     "varName": "existingCountry",
    //     "type": "text",
    //     "label": "Country",
    //     "grid": 6,
    // },
];

let signatoryFormForChecker = {};
signatoryFormForChecker = makeReadOnlyObject(JSON.parse(JSON.stringify(signatoryForm)));
const tagForm = [
    {
        "type": "title",
        "label": "BASIC INFORMATION UPDATE",
        "grid": 6,
    },
    {
        "varName": "basicInformationUpdateGender",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Gender",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateFatherName",
        "type": "text",
        "label": "Father Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateMotherName",
        "type": "text",
        "label": "Mother Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateMaritalStatus",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Marital Status",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateSpouseName",
        "type": "text",
        "label": "Spouse Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateDob",
        "type": "text",
        "label": "DOB",
        "grid": 6,
    },
    {
        "varName": "basicInformationUpdateDobMinor",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "minor",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "GUARDIAN DETAILS",
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "grid": 12,

    },
    {
        "varName": "guardianDetailsBankRelationType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "label": "Bank Relation Type",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsGurdianCifNumber",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "label": "Gurdian CIF Number",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsGuardianSelection",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "label": "Guardian Selection",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsDocumentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsDocumentCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsNationalIdCardnumber",
        "type": "text",
        "label": "National ID CardNumber",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsNidVerificationConfirmationisDocumentVerified",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsDocumentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsDocumentCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsSmartCardNumber",
        "type": "text",
        "label": "Smart Card Number",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsNidVerificationConfirmationisDocumentVerified",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
    },

    {
        "varName": "documentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "documentCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "passportNumber",
        "type": "text",
        "label": "Passport Number",
        "grid": 6,
    },

    {
        "varName": "issueDate",
        "type": "text",
        "label": "Issue Date",
        "grid": 6,
    },

    {
        "varName": "countryOfIssue",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Country of Issue",
        "grid": 6,
    },

    {
        "varName": "expiryDate",
        "type": "text",
        "label": "Expiry Date",
        "grid": 6,
    },

    {
        "varName": "documentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "documentCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "birthCertificateNumber",
        "type": "text",
        "label": "Birth Certificate Number",
        "grid": 6,
    },

    {
        "varName": "placeOfBirthfreeText14",
        "type": "text",
        "label": "Place of Birth/Free Text 14",
        "maxLength":15,
        "validationB":"length",
        "grid": 6,
    },

    {
        "varName": "countryOfBirthfreeText15",
        "type": "text",
        "label": "Country of Birth/Free Text 15",
        "grid": 6,
    },

    {
        "varName": "nationality",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],

        "label": "Nationality",
        "grid": 6,
    },

    {
        "varName": "residingCountry",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Residing Country",
        "grid": 6,
    },

    {
        "varName": "nonResident",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Non Resident",
        "grid": 6,
    },

    {
        "varName": "minorStatus",
        "type": "text",
        "label": "Minor Status",
        "grid": 6,
    },

    {
        "varName": "eTinNumber(PAN/GIR No)",
        "type": "text",
        "label": "E-Tin Number (PAN/GIR No)",
        "grid": 6,
    },

    {
        "varName": "professionEmploymentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Profession /Employment Type",
        "grid": 6,
    },

    {
        "varName": "nameOfEmployer",
        "type": "text",
        "label": "Name of Employer",
        "grid": 6,
    },

    {
        "varName": "monthlyIncome",
        "type": "text",
        "label": "Monthly Income",
        "grid": 6,
    },

    {
        "varName": "contactType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "phoneNoemailId",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "phoneNo",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "contactType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "phoneNoemailId",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "phoneNo",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "contactType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "phoneNoemailId",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "phoneNo",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "emailType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "emaildesignatedEmailAddress1",
        "type": "text",
        "label": "Email/Designated Email Address 1",
        "grid": 6,
    },

    {
        "varName": "emailType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "emailId2",
        "type": "text",
        "label": "Email ID 2",
        "grid": 6,
    },

    {
        "varName": "addressFormat",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "mailingAddressField1",
        "type": "text",
        "label": "Mailing Address Field 1",
        "grid": 6,
    },

    {
        "varName": "mailingAddressField2",
        "type": "text",
        "label": "Mailing Address Field 2",
        "grid": 6,
    },

    {
        "varName": "mailingAddressField3",
        "type": "text",
        "label": "Mailing Address Field 3",
        "grid": 6,
    },

    {
        "varName": "city",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "state",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "country",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "addressValidForm",
        "type": "text",
        "label": "Address Valid Form",
        "grid": 6,
    },

    {
        "varName": "addressFormat",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "homeAddressField1",
        "type": "text",
        "label": "Home Address Field 1",
        "grid": 6,
    },

    {
        "varName": "homeAddressField2",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 6,
    },

    {
        "varName": "homeAddressField3",
        "type": "text",
        "label": "Home Address Field 3",
        "grid": 6,
    },

    {
        "varName": "city",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "state",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "country",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "addressValidForm",
        "type": "text",
        "label": "Address Valid Form",
        "grid": 6,
    },

    {
        "varName": "addressFormat",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "workAddressField1",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 6,
    },

    {
        "varName": "workAddressField2",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 6,
    },

    {
        "varName": "workAddressField3",
        "type": "text",
        "label": "Work Address Field 3",
        "grid": 6,
    },

    {
        "varName": "city",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "state",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "country",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "addressValidForm",
        "type": "text",
        "label": "Address Valid Form",
        "grid": 6,
    },

    {
        "varName": "relationType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Relation Type",
        "grid": 6,
    },

    {
        "varName": "relationCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Relation Code",
        "grid": 6,
    },

    {
        "varName": "designationCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Designation Code",
        "grid": 6,
    },

    {
        "varName": "cifId",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "CIF ID",
        "grid": 6,
    },

    {
        "varName": "deleteFlag",
        "type": "text",
        "label": "Delete Flag",
        "grid": 6,
    },
];
const taggingForm = [
    {
        "varName": "customerId",
        "type": "text",
        "label": "Customer Id",
        "grid": 6,
        "length": 9,


    },

    {
        "varName": "title",
        "type": "text",
        "label": "Title",
        "grid": 6,


        required: true,
    },

    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name",
        "grid": 6,
        "length": 80,


    },

    {
        "varName": "shortName",
        "type": "text",
        "label": "Short Name",
        "grid": 6,
        "length": 10,


    },

    {
        "varName": "status",
        "type": "text",
        "label": "Status",
        "grid": 6,


        required: true,
    },

    {
        "varName": "statusAsOnDate",
        "type": "date",
        "label": "Status as on Date",
        "grid": 6
    },

    {
        "varName": "acManager",
        "type": "text",
        "label": "AC Manager",
        "grid": 6,


    },

    {
        "varName": "occupationCode",
        "type": "text",
        "label": "Occuoation Code",
        "grid": 6,


        required: true,
    },

    {
        "varName": "constitution",
        "type": "text",
        "label": "Constitution",
        "grid": 6,


        required: true,
    },

    {
        "varName": "gender",
        "type": "text",
        "label": "Gender",
        "grid": 6,


        required: true,
    },

    {
        "varName": "staffFlag",
        "type": "text",
        "label": "Staff Flag",
        "grid": 6,


        required: true,
    },

    {
        "varName": "staffNumber",
        "type": "text",
        "label": "Staff Number",
        "grid": 6,


        required: true,
    },

    {
        "varName": "minor",
        "type": "text",
        "label": "Minor",
        "grid": 6,


    },

    {
        "varName": "nonResident",
        "type": "text",
        "label": "Non Resident",
        "grid": 6,


        required: true,
    },

    {
        "varName": "trade",
        "type": "text",
        "label": "Trade",
        "grid": 6,


        required: true,
    },

    {
        "varName": "nationalIdCard",
        "type": "text",
        "label": "National ID Card",
        "grid": 6,


        required: true,
    },

    {
        "varName": "dateOfBirth",
        "type": "date",
        "label": "Date of Birth",
        "grid": 6,


    },

    {
        "varName": "introducerCustomerId",
        "type": "text",
        "label": "Introducer Customer Id",
        "grid": 6,
        "length": 9,

        required: true,
    },

    {
        "varName": "introducerName",
        "type": "text",
        "label": "Introducer Name",
        "grid": 6,


    },

    {
        "varName": "introducerStaff",
        "type": "text",
        "label": "Introducer Staff",
        "grid": 6,


    },

    {
        "varName": "maritialStatus",
        "type": "text",
        "label": "Maritial Status",
        "grid": 6,


        required: true,
    },

    {
        "varName": "father",
        "type": "text",
        "label": "Father",
        "grid": 6,


        required: true,
    },

    {
        "varName": "mother",
        "type": "text",
        "label": "Mother",
        "grid": 6,


        required: true,
    },

    {
        "varName": "spouse",
        "type": "text",
        "label": "Spouse",
        "grid": 6,


        required: true,
    },

    {
        "varName": "communicationAddress1",
        "type": "text",
        "label": "Communication Address1",
        "grid": 6,


    },

    {
        "varName": "communicationAddress2",
        "type": "text",
        "label": "Communication Address2",
        "grid": 6,


    },

    {
        "varName": "city1",
        "type": "text",
        "label": "City",
        "grid": 6,


        required: true,
    },

    {
        "varName": "state1",
        "type": "text",
        "label": "State",
        "grid": 6,


        required: true,
    },

    {
        "varName": "postalCode1",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,


    },

    {
        "varName": "country1",
        "type": "text",
        "label": "Country",
        "grid": 6,


        required: true,
    },

    {
        "varName": "phoneNo11",
        "type": "text",
        "label": "Phone No1",
        "grid": 6,
        "length": 11,

        required: true,
    },

    {
        "varName": "phoneNo21",
        "type": "text",
        "label": "Phone No2",
        "grid": 6,


    },

    {
        "varName": "telexNo1",
        "type": "text",
        "label": "Telex No",
        "grid": 6,


    },

    {
        "varName": "email1",
        "type": "text",
        "label": "Email",
        "grid": 6,

        email: true,

    },

    {
        "varName": "permanentAddress1",
        "type": "text",
        "label": "Permanent Address1",
        "grid": 6,


    },

    {
        "varName": "permanentAddress2",
        "type": "text",
        "label": "Permanent Address2",
        "grid": 6,


    },

    {
        "varName": "city2",
        "type": "text",
        "label": "City",
        "grid": 6,


        required: true,
    },

    {
        "varName": "state2",
        "type": "text",
        "label": "State",
        "grid": 6,


        required: true,
    },

    {
        "varName": "postalCode2",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,


    },

    {
        "varName": "country2",
        "type": "text",
        "label": "Country",
        "grid": 6,


        required: true,
    },

    {
        "varName": "phoneNo12",
        "type": "text",
        "label": "Phone No1",
        "grid": 6,


    },

    {
        "varName": "phoneNo222",
        "type": "text",
        "label": "Phone No2",
        "grid": 6,


    },

    {
        "varName": "telexNo2",
        "type": "text",
        "label": "Telex No",
        "grid": 6,


    },

    {
        "varName": "email2",
        "type": "text",
        "label": "Email",
        "grid": 6,

        email: true,

    },

    {
        "varName": "employerAddress1",
        "type": "text",
        "label": "Employer Address1",
        "grid": 6,


    },

    {
        "varName": "employerAddress2",
        "type": "text",
        "label": "Employer Address2",
        "grid": 6,


    },

    {
        "varName": "city3",
        "type": "text",
        "label": "City",
        "grid": 6,


        required: true,
    },

    {
        "varName": "state3",
        "type": "text",
        "label": "State",
        "grid": 6,


        required: true,
    },

    {
        "varName": "postalCode3",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,


    },

    {
        "varName": "country",
        "type": "text",
        "label": "Country",
        "grid": 6,


        required: true,
    },

    {
        "varName": "phoneNo13",
        "type": "text",
        "label": "Phone No1",
        "grid": 6,


    },

    {
        "varName": "phoneNo23",
        "type": "text",
        "label": "Phone No2",
        "grid": 6,


    },

    {
        "varName": "telexNo",
        "type": "text",
        "label": "Telex No",
        "grid": 6,


    },

    {
        "varName": "email3",
        "type": "text",
        "label": "Email",
        "grid": 6,

        email: true,

    },

    {
        "varName": "faxNo",
        "type": "text",
        "label": "Fax No",
        "grid": 6,


    },

    {
        "varName": "combineStatement",
        "type": "text",
        "label": "Combine Statement",
        "grid": 6,


    },

    {
        "varName": "tds",
        "type": "text",
        "label": "TDS",
        "grid": 6,


    },

    {
        "varName": "pangirNo",
        "type": "text",
        "label": "PANGIR No",
        "grid": 6,


    },

    {
        "varName": "passportNo",
        "type": "text",
        "label": "Passport No",
        "grid": 6,


    },

    {
        "varName": "issueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,


    },

    {
        "varName": "passportDetails",
        "type": "text",
        "label": "Passport Details",
        "grid": 6,


        required: true,
    },

    {
        "varName": "expiryDate",
        "type": "date",
        "label": "Expiry Date",
        "grid": 6,


    },

    {
        "varName": "purgedAllowed",
        "type": "text",
        "label": "Purged Allowed",
        "grid": 6,


    },

    {
        "varName": "freeText2",
        "type": "text",
        "label": "Free Text2",
        "grid": 6,


    },

    {
        "varName": "freeText5",
        "type": "text",
        "label": "Free Text 5",
        "grid": 6,
        "length": 10,


    },

    {
        "varName": "freeText8",
        "type": "text",
        "label": "Free Text 8",
        "grid": 6,


    },

    {
        "varName": "freeText9",
        "type": "text",
        "label": "Free Text 9",
        "grid": 6,


    },

    {
        "varName": "freeText13",
        "type": "text",
        "label": "Free Text13",
        "grid": 6,


    },

    {
        "varName": "freeText14",
        "type": "text",
        "label": "Free Text14",
        "grid": 6,


    },

    {
        "varName": "freeText15",
        "type": "text",
        "label": "Free Text15",
        "grid": 6,


    },

    {
        "varName": "freeCode1",
        "type": "text",
        "label": "Free Code1",
        "grid": 6,


    },

    {
        "varName": "freeCode3",
        "type": "text",
        "label": "Free Code3",
        "grid": 6,


    },

    {
        "varName": "freeCode7",
        "type": "text",
        "label": "Free Code 7",
        "grid": 6,


    },
];

let dpsRequestTypeForMaintenance = [
    {
        "varName": "LinkA/CChange",
        "type": "title",
        "label": "Link A/C Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": 'DPS Link AC change',

    },
    {
        "varName": "existingLinkAccount",
        "type": "text",
        "label": "Existing Link Account",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": 'DPS Link AC change',

    },
    {
        "varName": "requestedBranchSolId",
        "type": "text",
        "label": "Requested Branch Sol ID",
        "validation": "numeric",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": 'DPS Link AC change',

    },
    {
        "varName": "schemaAmount",
        "type": "text",
        "label": "Schema Amount",
        "validation": "numeric",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": 'DPS Link AC change',

    },
    {
        "varName": "schemaTenor",
        "type": "text",
        "label": "Schema Tenor",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": 'DPS Link AC change',

    },
    {
        "varName": "schemaValueDate",
        "type": "date",
        "label": "Schema Value Date",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": 'DPS Link AC change',

    },
    {
        "varName": "schemaInstallmentDate",
        "type": "date",
        "label": "Schema Installment Date",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": 'DPS Link AC change',

    },
    {
        "varName": "dpsCloserRequest",
        "type": "title",
        "label": "Installment Date Regularization",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Date Regularization"

    },
    {
        "varName": "solId",
        "type": "text",
        "label": "SOL ID",
        "validation": "numeric",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Date Regularization"

    },
    {
        "varName": "schemaAmount",
        "type": "text",
        "label": "Schema Amount",
        "validation": "numeric",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Date Regularization"

    },
    {
        "varName": "schemaTenor",
        "type": "text",
        "label": "Schema Tenor",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Date Regularization"

    },
    {
        "varName": "schemaValueDate",
        "type": "date",
        "label": "Schema Value Date",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Date Regularization"

    },
    {
        "varName": "schemaInstallmentDate",
        "type": "date",
        "label": "Schema Installment Date",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Date Regularization"

    },
    {
        "varName": "existingMatuirityDate",
        "type": "date",
        "label": "Existing Matuirity Date",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Date Regularization"

    },
    {
        "varName": "InstallmentRegularization",
        "type": "title",
        "label": "Installment Regularization",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Regularization"

    },
    {
        "varName": "numberInstallmentDue",
        "type": "text",
        "label": "Number of Installment(s) Due",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Regularization"
    },
    {
        "varName": "detailsofMonth",
        "type": "text",
        "label": "Details of Month",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Regularization"

    },
    {
        "varName": "amountPerInstallment",
        "type": "text",
        "label": "Amount Per Installment",
        "validation": "numeric",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Regularization"
    },
    {
        "varName": "totalInstallmentAmountDue",
        "type": "text",
        "label": "Total Installment Amount Due",
        "validation": "numeric",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Regularization"

    },
    {
        "varName": "debitDueInstallmentChargeRealization",
        "type": "text",
        "label": "Debit Due Installment & Charge Realization A/C no.",
        "grid": 4,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Regularization"
    },
    {
        "varName": "transactionTypeOrSubtype",
        "type": "text",
        "label": "Transaction Type/Subtype",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Regularization"
    },
    {
        "varName": "nomineeNumberOld",
        "type": "text",
        "label": "Existing Nominee",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Nominee Change"
    }
];

let dpsJsonFormReadOnly = {};
dpsJsonFormReadOnly = makeReadOnlyObject(JSON.parse(JSON.stringify(dpsRequestTypeForMaintenance)));


let dpsBOMRequestType = [
    {
        "varName": "LinkA/CChange",
        "type": "title",
        "label": "Link A/C Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": 'DPS Link AC change',

    },
    {
        "varName": "existingLinkAccount",
        "type": "text",
        "label": "Existing Link Account",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": 'DPS Link AC change',

    },
    {
        "varName": "requestedBranchSolId",
        "type": "text",
        "label": "Requested Branch Sol ID",
        "validation": "numeric",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": 'DPS Link AC change',

    },
    {
        "varName": "schemaAmount",
        "type": "text",
        "label": "Schema Amount",
        "validation": "numeric",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": 'DPS Link AC change',

    },
    {
        "varName": "schemaTenor",
        "type": "text",
        "label": "Schema Tenor",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": 'DPS Link AC change',

    },
    {
        "varName": "schemaValueDate",
        "type": "date",
        "label": "Schema Value Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": 'DPS Link AC change',

    },
    {
        "varName": "schemaInstallmentDate",
        "type": "date",
        "label": "Schema Installment Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": 'DPS Link AC change',

    },
    {
        "varName": "dpsCloserRequest",
        "type": "title",
        "label": "Installment Date Regularization",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Date Regularization"

    },
    {
        "varName": "solId",
        "type": "text",
        "label": "SOL ID",
        "validation": "numeric",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Date Regularization"

    },
    {
        "varName": "schemaAmount",
        "type": "text",
        "label": "Schema Amount",
        "validation": "numeric",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Date Regularization"

    },
    {
        "varName": "schemaTenor",
        "type": "text",
        "label": "Schema Tenor",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Date Regularization"

    },
    {
        "varName": "schemaValueDate",
        "type": "date",
        "label": "Schema Value Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Date Regularization"

    },
    {
        "varName": "schemaInstallmentDate",
        "type": "date",
        "label": "Schema Installment Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Date Regularization"

    },
    {
        "varName": "existingMatuirityDate",
        "type": "date",
        "label": "Existing Matuirity Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Date Regularization"

    },
    {
        "varName": "InstallmentRegularization",
        "type": "title",
        "label": "Installment Regularization",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Regularization"

    },
    {
        "varName": "numberInstallmentDue",
        "type": "text",
        "label": "Number of Installment(s) Due",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Regularization"
    },
    {
        "varName": "detailsofMonth",
        "type": "text",
        "label": "Details of Month",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Regularization"

    },
    {
        "varName": "amountPerInstallment",
        "type": "text",
        "label": "Amount Per Installment",
        "validation": "numeric",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Regularization"
    },
    {
        "varName": "totalInstallmentAmountDue",
        "type": "text",
        "label": "Total Installment Amount Due",
        "validation": "numeric",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Regularization"

    },
    {
        "varName": "debitDueInstallmentChargeRealization",
        "type": "text",
        "label": "Debit Due Installment & Charge Realization A/C no.",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Regularization"
    },
    {
        "varName": "transactionTypeOrSubtype",
        "type": "text",
        "label": "Transaction Type/Subtype",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dpsCloserRequest",
        "conditionalVarValue": "DPS Installment Regularization"
    },
];
let dpsJsonBomFormReadOnly = {};
dpsJsonBomFormReadOnly = makeReadOnlyObject(JSON.parse(JSON.stringify(dpsBOMRequestType)));

let merchantDeferralChecker = [
    {
        "label": "Merchant type",
        "type": "text",
        "varName": "merchantType",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Merchant Name",
        "type": "text",
        "varName": "merchantName",
        "grid": 2,
        "multiline": true,
        "readOnly": true
    },
    {
        "label": "Proprietor Name",
        "type": "text",
        "varName": "proprietorName",
        "grid": 2,
        "multiline": true,
        "readOnly": true
    },
    {
        "label": "DBA",
        "type": "text",
        "varName": "dba",
        "grid": 2,
        "multiline": true,
        "readOnly": true
    },
    {
        "label": "Key Contact Person Number",
        "type": "text",
        "varName": "mobileNumber",
        "grid": 2,
        "validation": "phone",
        "multiline": true,
        "readOnly": true
    },
    {
        "label": "MID",
        "type": "text",
        "varName": "mid",
        "grid": 2,
    },
    {
        "label": "MCC",
        "type": "text",
        "varName": "mcc",
        "validation": "numeric",
        "grid": 2,
    },
    {
        "label": "CREATE RETAILER",
        "type": "title",
        "varName": "empty",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "Payment Facilitator ID",
        "type": "text",
        "varName": "paymentFacilitatorId",
        "grid": 2,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "Sub Merchant ID",
        "type": "text",
        "varName": "subMerchantId",
        "grid": 2,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "COUNTRY CODE",
        "type": "text",
        "varName": "countryCode",
        "grid": 2,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "REGION CODE",
        "type": "select",
        "varName": "regionCode",
        "enum": [
            "1212",
            "1212",
            "1214",
        ],
        "grid": 2,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "CITY CODE",
        "type": "select",
        "varName": "cityCode",
        "enum": [
            "1212",
            "1212",
            "1214",
        ],
        "readOnly": true,
        "grid": 2,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "ZIP CODE",
        "type": "select",
        "varName": "zipCode",
        "enum": [
            "1212",
            "1212",
            "1214",
        ],
        "grid": 2,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "PHYSICAL ADDRESS",
        "type": "text",
        "varName": "physicalAddress",
        "grid": 2,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "MOBILE NO",
        "type": "text",
        "varName": "mobileNo",
        "validation": "phone",
        "grid": 2,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "EMAIL ADDRESS",
        "type": "text",
        "validation": "email",
        "varName": "emailAddress",
        "grid": 2,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "Location",
        "type": "text",
        "varName": "location",
        "grid": 2,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "FIN. PROFILe",
        "type": "select",
        "varName": "finacleProfile",
        "enum": [
            "AMEX",
            "VISA",
            "MC",
            "CUP",
            "ON US/OFF US"
        ],
        "grid": 2,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "ACQ. GROUP",
        "type": "text",
        "varName": "acqGroup",
        "grid": 2,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "OWNER NAME",
        "type": "text",
        "varName": "ownerName",
        "grid": 2,
        "validation": "string",
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "ACCOUNT TYPE NOTE",
        "type": "text",
        "varName": "accountTypeNote",
        "grid": 2,
        "multiline": true,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "MAX AMOUNT PER TXN",
        "type": "text",
        "varName": "maxAmountPerTxn",
        "grid": 2,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "MAX TXN PER MONTH",
        "type": "text",
        "varName": "maxTxnPerMonth",
        "grid": 2,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "MAX NUMBER OF TNX PER MONTH",
        "type": "text",
        "varName": "maxNumberOfTxnPerMonth",
        "grid": 2,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "ABABIL ACCOUNT NUMBER",
        "type": "text",
        "validation": "numeric",
        "varName": "ababilAccountNumber",
        "grid": 2,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "MCFACILITATOR",
        "type": "text",
        "varName": "mcfacilitator",
        "grid": 2,
        // "conditional": true,
        // "conditionalVarName": "merchantType",
        // "conditionalVarValue": "Sole Proprietorship"
    },
    {
        "label": "CREATE CORPORATE CUSTOMER",
        "type": "title",
        "varName": "empty",
        "grid": 12,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "FULL NAME",
        "type": "text",
        "varName": "fullName",
        "grid": 2,
        "validation": "string",
        "multiline": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "COUNTRY CODE",
        "type": "text",
        "varName": "generalCountryCode",
        "grid": 2,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "REGION CODE",
        "type": "select",
        "varName": "generalRegionCode",
        "enum": [
            "1212",
            "1212",
            "1214",
        ],
        "grid": 2,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "CITY CODE",
        "type": "select",
        "varName": "generalCityCode",
        "enum": [
            "1212",
            "1212",
            "1214",
        ],
        "grid": 2,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "ZIP CODE",
        "type": "select",
        "varName": "generalZipCode",
        "enum": [
            "1212",
            "1212",
            "1214",
        ],
        "grid": 2,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "CORPORATE ADDRESS",
        "type": "text",
        "varName": "generalCorporateAddress",
        "grid": 2,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "MOBILE NO",
        "type": "text",
        "varName": "generalMobileNo",
        "grid": 2,
        "validation": "numeric",
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "EMAIL ADDRESS",
        "type": "text",
        "validation": "email",
        "varName": "generalEmailAddress",
        "grid": 2,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "COUNTRY CODE",
        "type": "text",
        "varName": "contactsCountryCode",
        "grid": 2,
        "readOnly": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "REGION CODE",
        "type": "select",
        "varName": "contactsRegionCode",
        "enum": [
            "1212",
            "1212",
            "1214",
        ],
        "grid": 2,
        "readOnly": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "CITY CODE",
        "type": "select",
        "varName": "contactsCityCode",
        "enum": [
            "1212",
            "1212",
            "1214",
        ],
        "grid": 2,
        "readOnly": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "ZIP CODE",
        "type": "select",
        "varName": "contactsZipCode",
        "enum": [
            "1212",
            "1212",
            "1214",
        ],
        "grid": 2,
        "readOnly": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "CORPORATE ADDRESS",
        "type": "text",
        "varName": "contactsCorporateAddress",
        "grid": 2,
        "readOnly": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "MOBILE NO",
        "type": "text",
        "varName": "contactsMobileNo",
        "validation": "phone",
        "grid": 2,
        "readOnly": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "EMAIL ADDRESS",
        "type": "text",
        "validation": "email",
        "varName": "contactsEmailAddress",
        "grid": 2,
        "readOnly": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "COUNTRY CODE",
        "type": "text",
        "varName": "additionalCountryCode",
        "grid": 2,
        "readOnly": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "REGION CODE",
        "type": "select",
        "varName": "additionalRegionCode",
        "enum": [
            "1212",
            "1212",
            "1214",
        ],
        "grid": 2,
        "readOnly": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "CITY CODE",
        "type": "select",
        "varName": "additionalCityCode",
        "enum": [
            "1212",
            "1212",
            "1214",
        ],
        "grid": 2,
        "readOnly": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "ZIP CODE",
        "type": "select",
        "varName": "additionalZipCode",
        "enum": [
            "1212",
            "1212",
            "1214",
        ],
        "grid": 2,
        "readOnly": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "CORPORATE ADDRESS",
        "type": "text",
        "varName": "additionalCorporateAddress",
        "grid": 2,
        "readOnly": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "MOBILE NO",
        "type": "text",
        "varName": "additionalMobileNo",
        "grid": 2,
        "readOnly": true,
        "validation": "phone",
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "EMAIL ADDRESS",
        "type": "text",
        "validation": "email",
        "varName": "additionalEmailAddress",
        "grid": 2,
        "readOnly": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "AGREEMENT SIGN UP DATE",
        "type": "date",
        "varName": "agreementSignUpDate",
        "grid": 2,
        "readOnly": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "DSE CODE",
        "type": "text",
        "varName": "dseCode",
        "grid": 2,
        "readOnly": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "ACCOUNT NO",
        "type": "text",
        "varName": "accountNo",
        "validation": "numeric",
        "grid": 2,
        "readOnly": true,
        // "conditionalOrValue": true,
        // "conditionalVarName": "merchantType",
        // "conditionalOrList": ["Limited Liability", "Partnership", "Others"]
    },
    {
        "label": "MEMBER OF SE DEMOGRAPHICS ",
        "type": "title",
        "varName": "empty",
        "grid": 12,
    },
    {
        "label": "MEMBER OF SE DEMOGRAPHICS",
        "type": "text",
        "varName": "memberOfSeDemographics",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "INSTITUTION ID",
        "type": "text",
        "varName": "institutionId",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "BUSINESS CENTER",
        "type": "text",
        "varName": "businessCenter",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "DESCRIPT. BILL. NAME",
        "type": "text",
        "varName": "descriptBillName",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "ADDRESS LINE",
        "type": "text",
        "multiline": true,
        "varName": "addressLine",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "AID",
        "type": "text",
        "varName": "aid",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "MANDATORY FIELD CONTROL ",
        "type": "text",
        "varName": "mandatoryFiledControl",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "STATUS",
        "type": "text",
        "varName": "status",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "REASON ",
        "type": "text",
        "varName": "reason",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "ACTIVE DATE ",
        "type": "date",
        "varName": "activeDate",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "CANCELLED DATE",
        "type": "date",
        "varName": "cancelledDate",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "REINSTATED DATE ",
        "type": "date",
        "varName": "reinstatedDate",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "ROLE TYPE CODE ",
        "type": "text",
        "varName": "roleTypeCode",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "IND. QUALITY CODE",
        "type": "text",
        "varName": "indQualityCode",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "LOCATION CODE",
        "type": "text",
        "varName": "locationCode",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "SUBMISSION CITY",
        "type": "text",
        "varName": "submissionCity",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "SE SETTLE CCY ",
        "type": "text",
        "varName": "seSettleCCY",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "SUBM. CODE ",
        "type": "text",
        "varName": "submCode",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "SETTLE CODE",
        "type": "text",
        "varName": "settleCode",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "RESOURSE STATUS",
        "type": "text",
        "varName": "resorseStatus",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "RISK INDICATOR",
        "type": "text",
        "varName": "riskIndicator",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "ACCOUNT INDICATOR",
        "type": "text",
        "varName": "accountIndicator",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "VISA INDICATOR ",
        "type": "text",
        "varName": "visaIndicator",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "MASTERCARD INDICATOR",
        "type": "text",
        "varName": "masterCardIndicator",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "OTHER CARD INDICATOR",
        "type": "text",
        "varName": "otherCardIndicator",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "FLOOR LIMIT CCY",
        "type": "text",
        "varName": "floorLimitCCY",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "HIGN/LOW INDICATOR",
        "type": "text",
        "varName": "highLowIndicator",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "EFFECT TYPE CODE",
        "type": "text",
        "varName": "effectTypeCode",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "TERM OWNER LEGAL",
        "type": "text",
        "varName": "termOwnerLegal",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "TERM LOCATION LEGAL",
        "type": "text",
        "varName": "termLocationLegal",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "TERM ZIP LEGAL",
        "type": "text",
        "varName": "termZipLegal",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "MERCH. PHONE NUMBER",
        "type": "text",
        "validation": "phone",
        "varName": "merchPhoneNumber",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "PHONE NUMBER LEGAL",
        "type": "text",
        "validation": "phone",
        "varName": "phoneNumberLegal",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "REGION CODE",
        "type": "text",
        "varName": "regionCode",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "SETTLEMENT ACCOUNT",
        "type": "title",
        "varName": "empty",
        "grid": 12,
    },
    {
        "label": "CURR.",
        "type": "text",
        "varName": "settlementCurr",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "1 TIRE ACT ",
        "type": "text",
        "varName": "settlementOneTireAct",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "2 TIRE ACT",
        "type": "text",
        "varName": "settlementTwoTireAct",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "SETTLEMENT NUMBER",
        "type": "text",
        "varName": "settlementNumber",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "STATE",
        "type": "text",
        "varName": "settlementState",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "CURRENCY",
        "type": "text",
        "varName": "settlementCurrency",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "NAME",
        "type": "text",
        "varName": "settlementName",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "COMMENT",
        "type": "text",
        "multiline": true,
        "varName": "settlementComment",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "TRANSIT ACCOUNT",
        "type": "title",
        "varName": "empty",
        "grid": 12,
        "readOnly": true,
    },
    {
        "label": "1 TIRE ACT ",
        "type": "text",
        "varName": "transitOneTireAct",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "2 TIRE ACT",
        "type": "text",
        "varName": "transitTwoTireAct",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "SETTLEMENT NUMBER",
        "type": "text",
        "varName": "transitNumber",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "STATE",
        "type": "text",
        "varName": "transitState",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "CURRENCY",
        "type": "text",
        "varName": "transitCurrency",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "NAME",
        "type": "text",
        "validation": "string",
        "varName": "transitName",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "COMMENT",
        "type": "text",
        "multiline": true,
        "varName": "transitComment",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "TERMINAL CREATION",
        "type": "title",
        "varName": "empty",
        "grid": 12,
    },
    {
        "label": "TID",
        "type": "text",
        "varName": "terminalTID",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "NAME (DBA NAME)",
        "type": "text",
        "validation": "string",
        "varName": "terminalDbaName",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "TYPE",
        "type": "text",
        "varName": "terminalType",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "TIME OFFSET",
        "type": "text",
        "varName": "timeOffset",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "CURRENCY",
        "type": "text",
        "varName": "terminalCurrency",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "ADDRESS",
        "type": "text",
        "multiline": true,
        "varName": "terminalAddress",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "MCC ",
        "type": "text",
        "varName": "terminalMcc",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "COMMENTS",
        "type": "text",
        "varName": "terminalComments",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "PROTOTYPE",
        "type": "text",
        "varName": "terminalPrototype",
        "grid": 2,
        "readOnly": true,
    },
    {
        "label": "CONTACTLESS CAPABLE",
        "type": "text",
        "varName": "terminalContactlessCapable",
        "grid": 2,
        "readOnly": true,
    }
];


let lienWithdrawal = [
    {
        "type": "title",
        "label": "HALM",
        "grid": 12,
    },
    {
        "varName": "acId",
        "type": "text",
        "label": "A/c. ID",
        "required": true,
        "grid": 3,
    },
    {
        "varName": "newLienAmt",
        "type": "text",
        "label": "New Lien Amt.",
        "required": true,
        "grid": 3,
    },
];
let lienWithdrawalReadOnly = {};
lienWithdrawalReadOnly = makeReadOnlyObject(changeGridObject(JSON.parse(JSON.stringify(lienWithdrawal))));

let lienWithdrawalMaker = {};
lienWithdrawalMaker = changeGridObject(JSON.parse(JSON.stringify(lienWithdrawal)));


let dormantActivation = [
    {
        "type": "title",
        "label": "Scheme",
        "grid": 12,
    },
    {
        "varName": "acStatus",
        "type": "select",
        "enum": [
            "A",
            "D"
        ],
        "label": "A/c. Status",
        "required": true,
        "grid": 3,
    },
    {
        "varName": "dormantActivationCharge",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "label": "Dormant Activation Charge",
        "required": true,
        "grid": 3,
    },
];
let dormantActivationReadOnly = {};
dormantActivationReadOnly = makeReadOnlyObject(changeGridObject(JSON.parse(JSON.stringify(dormantActivation))));

let dormantActivationMaker = {};
dormantActivationMaker = changeGridObject(JSON.parse(JSON.stringify(dormantActivation)));

let unfreezeMarking = [
    {
        "varName": "operation",
        "type": "select",
        "enum": [
            "Freeze",
            "Unfreeze"
        ],
        "label": "Operation",
        "required": true,
        "grid": 3,
    },
    {
        "varName": "freezeCode",
        "type": "text",
        "label": "Freeze Code",
        "required": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "operation",
        "conditionalVarValue": "Freeze"
    },
    {
        "varName": "freezeReasonCode",
        "type": "text",
        "label": "Freeze Reason Code 1",
        "required": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "operation",
        "conditionalVarValue": "Freeze"
    },
    {
        "varName": "freezeRemarks",
        "type": "text",
        "label": "Freeze Remarks 1",
        "required": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "operation",
        "conditionalVarValue": "Freeze"
    },
];
let unfreezeMarkingReadOnly = {};
unfreezeMarkingReadOnly = makeReadOnlyObject(changeGridObject(JSON.parse(JSON.stringify(unfreezeMarking))));

let unfreezeMarkingMaker = {};
unfreezeMarkingMaker = changeGridObject(JSON.parse(JSON.stringify(unfreezeMarking)));

let casaSiDelete = [
    {
        "type": "title",
        "label": "HSIM",
        "grid": 12,
    },
    {
        "varName": "slSrlNo",
        "type": "text",
        "label": "SI Srl. No.",
        "required": true,
        "grid": 3,
    },
];
let casaSiDeleteReadOnly = {};
casaSiDeleteReadOnly = makeReadOnlyObject(changeGridObject(JSON.parse(JSON.stringify(casaSiDelete))));

let casaSiDeleteMaker = {};
casaSiDeleteMaker = changeGridObject(JSON.parse(JSON.stringify(casaSiDelete)));

let manualFundTransfer = [
    {
        "type": "title",
        "label": "HTM",
        "grid": 12,
    },
    {
        "varName": "debitTransactionTypeOrSubtype",
        "type": "select",
        "enum": [
            "Customer Induced",
            "Bank Induced"
        ],
        "label": "Transaction Type/Subtype",
        "required": true,
        "grid": 3,
    },
    {
        "varName": "debitPartTransactionType",
        "type": "text",
        "readOnly": true,
        "label": "Part Transaction Type",
        "required": true,
        "multiline": true,
        "grid": 3,
    },
    {
        "varName": "debitAcId",
        "type": "text",
        "label": "A/c. ID",
        "required": true,
        "multiline": true,
        "grid": 3,
    },
    {
        "varName": "debitRefCCY",
        "type": "text",
        "label": "Ref. CCY/Amt",
        "required": true,
        "multiline": true,
        "grid": 3,
    },
    {
        "varName": "debitTransactionParticularsCode",
        "type": "text",
        "label": "Transaction Particulars Code",
        "required": true,
        "multiline": true,
        "grid": 3,
    },
    {
        "varName": "debitTransactionParticulars",
        "type": "text",
        "label": "Transaction Particulars",
        "required": true,
        "multiline": true,
        "grid": 3,
    },
    {
        "type": "title",
        "label": "Credit Part",
        "grid": 12,
    },
    {
        "varName": "creditTransactionTypeOrSubtype",
        "type": "select",
        "enum": [
            "Customer Induced",
            "Bank Induced"
        ],
        "label": "Transaction Type/Subtype",
        "required": true,
        "grid": 3,
    },
    {
        "varName": "creditPartTransactionType",
        "type": "text",
        "readOnly": true,
        "label": "Part Transaction Type",
        "required": true,
        "multiline": true,
        "grid": 3,
    },
    {
        "varName": "creditAcId",
        "type": "text",
        "label": "A/c. ID",
        "required": true,
        "multiline": true,
        "grid": 3,
    },
    {
        "varName": "creditRefCCY",
        "type": "text",
        "label": "Ref. CCY/Amt",
        "required": true,
        "multiline": true,
        "grid": 3,
    },
    {
        "varName": "creditTransactionParticularsCode",
        "type": "text",
        "label": "Transaction Particulars Code",
        "required": true,
        "multiline": true,
        "grid": 3,
    },
    {
        "varName": "creditTransactionParticulars",
        "type": "text",
        "label": "Transaction Particulars",
        "required": true,
        "multiline": true,
        "grid": 3,
    },
];
let manualFundTransferReadOnly = {};
manualFundTransferReadOnly = makeReadOnlyObject(changeGridObject(JSON.parse(JSON.stringify(manualFundTransfer))));

let manualFundTransferMaker = {};
manualFundTransferMaker = changeGridObject(JSON.parse(JSON.stringify(manualFundTransfer)));

let signatureAndPhotograph = [
    {
        "type": "title",
        "label": "SVS",
        "grid": 12,
    },
    {
        "varName": "acId",
        "type": "text",
        "label": "A/c. ID",
        "required": true,
        "grid": 3,
    },
    {
        "varName": "signatureAndPhotographDetails",
        "type": "checkbox",
        "label": "Signature and Photograph Details",
        "required": true,
        "multiline": true,
        "grid": 3,
    },
    {
        "varName": "signatureGroupNameRecord",
        "type": "checkbox",
        "label": "Signature Group Name Record",
        "required": true,
        "multiline": true,
        "grid": 3,
    },
];
let signatureAndPhotographReadOnly = {};
signatureAndPhotographReadOnly = makeReadOnlyObject(changeGridObject(JSON.parse(JSON.stringify(signatureAndPhotograph))));

let signatureAndPhotographMaker = {};
signatureAndPhotographMaker = changeGridObject(JSON.parse(JSON.stringify(signatureAndPhotograph)));

module.exports = {

//card cheque
    newSignatoryForm,
    tagForm,
    taggingForm,
    signatoryForm,
    dstJsonForm,
    merchantDstJsonForm,
    HombujsonFormReadOnly,
    merchantMakerJsonForm,
    CsDebitTransactionJsonFormForCard,
    CsCreditTransactionJsonFormForCard,
    BomDebitCardJsonFormForCard,
    BomCreditTransactionJsonFormForCard,
    jsonFormForMerchant,
    CSjsonFormNonIndividualWithoutProprietorshipAccountOpeningSearch,
    CSjsonFormNonIndividualPartnershipAccountOpeningSearch,
    CSjsonFormNonIndividualLimitedAccountOpeningSearch,
    CSjsonFormNonIndividualOthersAccountOpeningSearch,
    DedupjsonForm,
    MerchantjsonFormReadOnly,
    CustomerServiceSearch,
    CustomerServiceJsonFormOne,
    CustomerServiceTransferType,
    CustomerServiceTransferSubType,
    CustomerServiceJsonFormTwo,
    FundTransferJsonFormOne,
    FundTransferJsonFormTwo,
    LockerJsonForm,
    lockerMaintenanceJsonFormAfterSearch,
    lockerMaintenanceJsonFormReadOnly,
    linkAccountChangeRequest,
    siDateChangeRequest,
    linkAccountChangeRequestReadOnly,
    siDateChangeRequestReadOnly,
    lockerJsonFormOne,
    lockerJsonFormTwo,
    lockerJsonFormThree,
    lockerJsonFormOneReadOnly,
    lockerJsonFormTwoReadOnly,
    lockerJsonFormThreeReadOnly,
    accountMaintenanceForm,
    maintenanceForm,
    FundTransferJsonFormTwoReadOnly,
    dpsRequestTypeForMaintenance,
    dpsJsonFormReadOnly,
    dpsJsonBomFormReadOnly,
    merchantDeferralChecker,
    newSignatoryFormForChecker,
    signatoryFormForChecker,
    FundTransferJsonFoMaker,
    lienWithdrawal,
    lienWithdrawalReadOnly,
    lienWithdrawalMaker,
    dormantActivation,
    dormantActivationReadOnly,
    dormantActivationMaker,
    unfreezeMarking,
    unfreezeMarkingReadOnly,
    unfreezeMarkingMaker,
    casaSiDelete,
    casaSiDeleteReadOnly,
    casaSiDeleteMaker,
    manualFundTransfer,
    manualFundTransferReadOnly,
    manualFundTransferMaker,
    signatureAndPhotograph,
    signatureAndPhotographReadOnly,
    signatureAndPhotographMaker
};
