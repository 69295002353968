import React, {Component} from "react";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CloseIcon from "@material-ui/icons/Close";
import Notification from "../../NotificationMessage/Notification";
import CBNotFound from "../../workflow/CASA/CBNotFound";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import loader from "../../../Static/loader.gif";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
var jsonForm =[

    {
        "varName": "deliveryType",
        "type": "select",
        "enum":[
            "COURIER",
            "PION"
        ],
        "readOnly":true,
        "label": "Delivery  Type",
        "grid":2
    },
    {
        "varName": "name",
        "type": "text",

        "required":true,
        "label": "Delivery  Type",
        "conditional": true,
        "conditionalVarName": "deliveryType",
        "conditionalVarValue": "COURIER",
        "grid":2
    },
    {
        "varName": "name",
        "type": "text",

        "required":true,
        "label": "Delivery  Type",
        "conditional": true,
        "conditionalVarName": "deliveryType",
        "conditionalVarValue": "PION",
    },


];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class SendTo extends Component {
    constructor() {
        super();
        this.state = {
            err: false,
            inputData: {},
            errorArray: {},
            errorMessages: {},
            showValue: true,
            varValue: {},
            notificationMessage: "",
            alert: false,
            loading: true,
            title: "",
            cityGem: "",
            cupInstant: "",
            cityMaxx: "",
            cbNotFound: false,
        };
    }

    updateComponent = () => {
        this.forceUpdate();
    };


    componentDidMount() {
        let url = backEndServerURL + "/GetCountByProductTypeBranchWise";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(response => {
            if (response.status === 200) {
                this.setState({
                    cityMaxx: response.data.cityMaxx,
                    cupInstant: response.data.cupInstant,
                    cityGem: response.data.cityGem,
                    loading: false
                })
            }
        }).catch(error => {
            console.log(error);
            this.setState({
                loading: false,
                title: "Summery Not Found",
                cbNotFound: true
            })
        });
    }


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderHeader = () => {
        return (
            <h4 style={{color: "white"}} >
                Instant Debit Card Summary Report
                <a><CloseIcon onClick={this.props.closeModal} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a>
            </h4>
        )
    };


    renderSummary = () => {
        if (!this.state.loading) {
            return (

                    CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent, "", "")


            )
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
        })
    };


    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <React.Fragment>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            {
                                this.renderHeader()
                            }
                        </CardHeader>
                        <CardBody>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {this.renderSummary()}
                                </ThemeProvider>
                            </Grid>
                        </CardBody>
                        {
                            this.renderNotification()
                        }
                    </Card>
                </React.Fragment>
            );
        }


    }
}

export default withStyles(styles)(SendTo);
