import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {Redirect} from "react-router-dom";
import axios from 'axios';
import '../../Static/css/login.css';
import {backEndServerURL} from '../../Common/Constant';
import Notification from "../NotificationMessage/Notification";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import logo from "../../Static/green-delta.png";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";
import Functions from "../../Common/Functions";

const styles = theme => ({

    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {

        marginTop: theme.spacing.unit * 1,

    },
    Buttoncolorchange: {
        backgroundColor: '#ff3443',
        '&:hover': {
            backgroundColor: "#ff3443"
        }
    },
    Errorr: {
        color: '#ff3443',


    }

});
let Form = [

    {
        "varName": "newPassword",
        "type": "password",
        "label": "New Password",
        "grid": 2,
        "required": true,
    },
    {
        "type": "empty",
        "grid": 12
    },
    {
        "varName": "newPassword1",
        "type": "password",
        "label": "Re Enter New Password",
        "grid": 2,
        "required": true,
    },
    {
        "type": "empty",
        "grid": 12
    }

];

class ForgetPasswordSetPassword extends Component {
    state = {
        inputData: {},
        selectedDate: {},
        getData: false,
        workstation: false,
        role: false,
        showValue: false,
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},

        err: false,
        varValue: [],
        errorArray: {},
        errorMessages: {},
        title: "",
        notificationMessage: "",
        errorType: "success",
        alert: false,
        redirectLoginPage: false,
        otherRequestTitle: "",
        username: "",
        newPassword: "",
        newPassword1: "",
        loading: true,
        getUrl: ""
    };

    componentDidMount() {
        let getUrl = window.location.href;
        getUrl = getUrl.split("forgetPass/");
        getUrl = getUrl[1];
        let url = backEndServerURL + "/forgetPass/tokenInfo/" + getUrl;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    username: response.data.username,
                    loading: false,
                    getUrl: getUrl
                });
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    getUrl: getUrl
                })
            })
    }

    handlesubmit = event => {
        event.preventDefault();

        let data = {};
        let matchType = "";


        if (this.state.newPassword === this.state.newPassword1) {
            matchType = "MATCH"
        } else {
            matchType = "NOTMATCH";
        }
        if (matchType === "NOTMATCH") {

            this.setState({
                alert: true,
                title: "Error!",
                errorType: "error",
                notificationMessage: "Your Password Not Match"
            });
            return 0;
        }

        data.username = this.state.username;
        data.password = this.state.newPassword;

        console.log(data);
        let url = backEndServerURL + "/forgetPass/changePass/" + this.state.getUrl;
        console.log(url);
        axios.post(url, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                if (response.data === true) {
                    this.setState({
                        title: "Successfull!",
                        errorType: "success",
                        notificationMessage: "Successfully Change!",
                        alert: true
                    });
                    let url = backEndServerURL + "/logMeOut";
                    axios.get(url, {
                        withCredentials: true,
                    })
                        .then((response) => {
                            console.log("oooo");
                            console.log(response);
                            LocalstorageEncrypt.encryptStorageFunction.removeItem("username");
                            LocalstorageEncrypt.encryptStorageFunction.removeItem("roles");
                            LocalstorageEncrypt.encryptStorageFunction.removeItem("menus");
                            this.setState({
                                redirectLoginPage: true
                            })

                        })
                        .catch((error) => {

                        })

                } else {
                    this.setState({
                        title: "Error!",
                        errorType: "error",
                        notificationMessage: response.data,
                        alert: true
                    })

                }


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    title: "Error!",
                    errorType: "error",
                    notificationMessage: Functions.errorObjectCheck(error),
                    alert: true
                })
            })
    };

    returnJsonForm = () => {
        return (
            CommonJsonFormComponent.renderJsonForm(this.state, Form, this.updateComponent)
        )

    };
    updateComponent = () => {
        this.forceUpdate();
    };
    handleChangeUsername = (event) => {
        this.setState({
            username: event.target.value
        })
    };


    handleChangeNewPassword = event => {
        this.setState({
            newPassword: event.target.value
        })
    };
    handleChangeReEnterNewPassword = event => {
        this.setState({
            newPassword1: event.target.value
        })
    };
    renderFirstLoginPage = () => {
        if (this.state.renderFirstLoginPage === true) {
            return (<Redirect to={{
                pathname: '/resetFirstPassword',
            }}/>);
        }
    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.errorType} stopNotification={this.stopNotification}
                              title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    renderloginpage = () => {
        if (this.state.redirectLoginPage === true) {
            return (<Redirect to={{
                pathname: '/login',
            }}/>);
        }
    };

    render() {


        const {classes} = this.props;

        return (
            <div>

                {
                    this.renderNotification()

                }
                {
                    this.renderloginpage()

                }
                <center>


                    <main className={classes.main}>
                        <CssBaseline/>
                        <Paper className={classes.paper}>
                            <img height={120} src={logo}/>

                            <Typography component="h1" variant="h5">
                                Password Change
                            </Typography>

                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                open={this.state.loading}>
                                <DialogContent>

                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>
                            <form onSubmit={this.handlesubmit} className={classes.form}>
                                <FormControl margin="normal" required fullWidth>
                                    <InputLabel htmlFor="username">User Name</InputLabel>
                                    <Input value={this.state.username} onChange={this.handleChangeUsername} type="text"
                                           id="username"
                                           name="username" readOnly={true}/>
                                </FormControl>

                                <FormControl margin="normal" required fullWidth>
                                    <InputLabel htmlFor="newPassword">New Password</InputLabel>
                                    <Input onChange={this.handleChangeNewPassword} name="newPassword" type="password"
                                           id="newPassword"
                                           autoComplete="current-password" autoFocus/>
                                </FormControl>
                                <FormControl margin="normal" required fullWidth>
                                    <InputLabel htmlFor="newPassword1">Re Enter New Password</InputLabel>
                                    <Input onChange={this.handleChangeReEnterNewPassword} name="newPassword1"
                                           type="password" id="newPassword1"
                                           autoComplete="current-password"/>
                                </FormControl>
                                <br/>


                                <center>
                                    <button
                                        className="btn btn-outline-primary  btn-sm"
                                        style={{
                                            marginTop: "18px"
                                        }}


                                    >
                                        Change Password
                                    </button>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <p><b>Powered By Business Accelerate BD LTD</b></p>
                                </center>


                            </form>


                        </Paper>
                    </main>
                </center>
            </div>
        );
    }
}

ForgetPasswordSetPassword.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ForgetPasswordSetPassword);