import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import 'semantic-ui-offline/semantic.min.css';
import MaterialTable from "material-table";
import {createMuiTheme} from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import TablePagination from "@material-ui/core/TablePagination";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import {localization, options, tableIcons,} from "../../DeliverableManagement/configuration";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import SubmittedCaseHistory from "../CommonComponent/SubmittedCaseHistory";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '5px',
                fontSize: '0.8rem'
            }
        },

    }
});

const SearchForm = [
    // {
    //     "varName": "referenceNumber",
    //     "type": "text",
    //     "label": "Reference Number",
    //     "grid": 2,
    // },
    {
        "varName": "caseId",
        "type": "textDedup",
        "label": "Reference Number",
        "grid": 2,
    },
    // {
    //     "varName": "category",
    //     "type": "select",
    //     "enum": [
    //         "CASA",
    //         "FDR",
    //         "DPS",
    //         "Mandate",
    //         "Merchant",
    //         // "Maintenace",
    //         "Chequebook",
    //         "RTGS",
    //         "BEFTN",
    //         "Salary",
    //         "Debit Card Issuance",
    //         "BOND",
    //         "SANCHAYPATRA",
    //         "Debit Card",
    //         "Credit Card",
    //         "CARDS",
    //         "ADC",
    //         "Internet Banking",
    //         "Remittance",
    //         "Pay Order and FDD Bulk",
    //         "Locker",
    //         "Delivarable",
    //         "Deferral",
    //     ],
    //     "label": "Category",
    //     "grid": 2,
    // },
    // {
    //     "varName": "subCategory",
    //     "type": "select",
    //     "enum": [
    //         "A/C Opening",
    //         "Opening",
    //         "Onboarding",
    //         "Schem Account opeing",
    //         "TD Open/FDR",
    //         "Maintenance",
    //         "Dormant Activation",
    //         "Mandate Update",
    //         // "Customer Information Update",
    //         // "Dormant Activation & Maintenance",
    //         // "Mandate/Related Party Maintenance",
    //         "Single Requisition",
    //         "Opening",
    //         "Service",
    //         "Single Transaction",
    //         "Bulk Transaction",
    //         "Requisition",
    //         "Chequebook",
    //         "Bulk Insta Debit Card",
    //         "Bulk Requisition",
    //         "CASA Closer",
    //         "FDR Closer",
    //         "FDR Maintenace",
    //         "CARD Against FDR/ DPS",
    //         "Tracker",
    //         "Instant Card",
    //         "General Card Requisition",
    //         "CARD Cheque Requisition",
    //         "City Touch Enrollment",
    //         "SMS Discountinue",
    //         "Outward",
    //         "Inward",
    //         "Student File",
    //         "Medical File",
    //         "Searcher",
    //         "Others",
    //         "Pay Order Bulk Requisition",
    //         "FDD Bulk Requisition",
    //         "Others",
    //         "Requistion",
    //         "Surrender",
    //         "Locker Maintenace",
    //         "Reconcilation Branch",
    //         "Insurance Upload",
    //         "Insta Pack Requisition",
    //         "Insta Card Requisition",
    //         "Insta Pack Tracker",
    //         "Insta Card Tracker",
    //         "WL Priority",
    //         "WL Non Priority",
    //         "CASA Defferal",
    //         "FD Deferal",
    //         "Others",
    //         "FDR",
    //         "Encashment",
    //         "ISSUANCE - Wage Earners Development Bond",
    //         "ISSUANCE - U.S. Dollar Investment Bond",
    //         "ISSUANCE - U.S. Dollar Premium Bond",
    //         "ISSUANCE - 5 Years Bangladesh Sanchaya Patra",
    //         "ISSUANCE - 3 Months Interest Based Sanchaya Patra",
    //         "ISSUANCE - Family Sanchaya Patra",
    //         "ISSUANCE - Pensioners Savings Certificate",
    //         "ENCASHMENT - Wage Earners Development Bond - ENCASHMENT PAYMENT",
    //         "ENCASHMENT - Wage Earners Development Bond - INTEREST PAYMENT",
    //         "ENCASHMENT - U.S. Dollar Investment Bond - ENCASHMENT PAYMENT",
    //         "ENCASHMENT - U.S. Dollar Investment Bond - INTEREST PAYMENT",
    //         "ENCASHMENT - U.S. Dollar Premium Bond - ENCASHMENT PAYMENT",
    //         "ENCASHMENT - U.S. Dollar Premium Bond - INTEREST PAYMENT",
    //         "ENCASHMENT - 5 Years Bangladesh Sanchaya Patra - ENCASHMENT PAYMENT",
    //         "ENCASHMENT - 5 Years Bangladesh Sanchaya Patra - INTEREST PAYMENT",
    //         "ENCASHMENT - 3 Months Interest Based Sanchaya Patra - ENCASHMENT PAYMENT",
    //         "ENCASHMENT - 3 Months Interest Based Sanchaya Patra - INTEREST PAYMENT",
    //         "ENCASHMENT - Family Sanchaya Patra - ENCASHMENT PAYMENT",
    //         "ENCASHMENT - Family Sanchaya Patra - INTEREST PAYMENT",
    //         "ENCASHMENT - Pensioners Savings Certificate - ENCASHMENT PAYMENT",
    //         "ENCASHMENT - Pensioners Savings Certificate - INTEREST PAYMENT",
    //         "MAINTENANCE - Wage Earners Development Bond - NOMINEE CHANG",
    //         "MAINTENANCE - Wage Earners Development Bond - ACES ENROLLMENT",
    //         "MAINTENANCE - Wage Earners Development Bond - ACCOUNT NUMBER CHANGE",
    //         "MAINTENANCE - Wage Earners Development Bond - DUPLICATE ISSUANCE",
    //         "MAINTENANCE - Wage Earners Development Bond - CERTIFICATE",
    //         "MAINTENANCE - U.S. Dollar Investment Bond - NOMINEE CHANG",
    //         "MAINTENANCE - U.S. Dollar Investment Bond - ACES ENROLLMENT",
    //         "MAINTENANCE - U.S. Dollar Investment Bond - ACCOUNT NUMBER CHANGE",
    //         "MAINTENANCE - U.S. Dollar Investment Bond - DUPLICATE ISSUANCE",
    //         "MAINTENANCE - U.S. Dollar Investment Bond - CERTIFICATE",
    //         "MAINTENANCE - U.S. Dollar Premium Bond - NOMINEE CHANG",
    //         "MAINTENANCE - U.S. Dollar Premium Bond - ACES ENROLLMENT",
    //         "MAINTENANCE - U.S. Dollar Premium Bond - ACCOUNT NUMBER CHANGE",
    //         "MAINTENANCE - U.S. Dollar Premium Bond - DUPLICATE ISSUANCE",
    //         "MAINTENANCE - U.S. Dollar Premium Bond - CERTIFICATE",
    //         "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - NOMINEE CHANG",
    //         "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - ACES ENROLLMENT",
    //         "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - ACCOUNT NUMBER CHANGE",
    //         "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - DUPLICATE ISSUANCE",
    //         "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - CERTIFICATE",
    //         "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - NOMINEE CHANG",
    //         "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - ACES ENROLLMENT",
    //         "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - ACCOUNT NUMBER CHANGE",
    //         "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - DUPLICATE ISSUANCE",
    //         "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - CERTIFICATE",
    //         "MAINTENANCE - Family Sanchaya Patra - NOMINEE CHANG",
    //         "MAINTENANCE - Family Sanchaya Patra - ACES ENROLLMENT",
    //         "MAINTENANCE - Family Sanchaya Patra - ACCOUNT NUMBER CHANGE",
    //         "MAINTENANCE - Family Sanchaya Patra - DUPLICATE ISSUANCE",
    //         "MAINTENANCE - Family Sanchaya Patra - CERTIFICATE",
    //         "MAINTENANCE - Pensioners Savings Certificate - NOMINEE CHANG",
    //         "MAINTENANCE - Pensioners Savings Certificate - ACES ENROLLMENT",
    //         "MAINTENANCE - Pensioners Savings Certificate - ACCOUNT NUMBER CHANGE",
    //         "MAINTENANCE - Pensioners Savings Certificate - DUPLICATE ISSUANCE",
    //         "MAINTENANCE - Pensioners Savings Certificate - CERTIFICATE",
    //     ],
    //     "label": "Sub Category",
    //     "grid": 2,
    // },
    {
        "varName": "branch_id",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Branch",
        "grid": 2,
    },
    // {
    //     "varName": "cbNumber",
    //     "type": "textDedup",
    //     "label": "CB Number",
    //     "grid": 2,
    // },
    // {
    //     "varName": "accountNumber",
    //     "type": "textDedup",
    //     "label": "Account No.",
    //     "grid": 2,
    // },
    // {
    //     "varName": "customerName",
    //     "type": "textDedup",
    //     "label": "Customer name",
    //     "grid": 2,
    // },
    {
        "varName": "fromDate",
        "type": "date",
        "label": "From Date",
        "grid": 2,
    },
    {
        "varName": "toDate",
        "type": "date",
        "label": "To Date",
        "grid": 2,
    },
    // {
    //     "varName": "urgency",
    //     "type": "select",
    //     "enum": [
    //         "High",
    //         "Medium",
    //         "Low",
    //     ],
    //     "label": "Urgency",
    //     "grid": 2,
    // },
    // {
    //     "varName": "cutOffTime",
    //     "type": "select",
    //     "enum": [
    //         "Before",
    //         "After",
    //     ],
    //     "label": "Cut Off Time",
    //     "grid": 2,
    // },

];

class GlobalSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            inputData: {},
            autoComplete: {},
            allData: [],
            pageNumber: 0,
            totalRow: 0,
            loading: false,
            rowsPerPage: 50,
            showValue: false,
            alert: false,
            history: false,
            appId: 0,
            freeFlag2: "",
            title: "",
            notificationMessage: "",
            customerName: "",
            accountNumber: "",
            cbNumber: "",
            serviceTypes: "",
            solId: "",
            branch_name: "",
            sub_category_type: "",
            category_type: "",
            serviceType: "",
            subServiceType: "",
            caseId: "",
            columns: [
                {field: 'sl', title: 'SL'},
                {field: 'caseId', title: 'Reference Number'},
                 // {field: 'cb_number', title: 'CB Number'},
                // {field: 'account_number', title: 'Account Number'},
                // {field: 'businessSegment', title: 'Business Segement'},
                {field: 'category_type', title: 'Category'},
                {field: 'sub_category_type', title: 'Sub Category'},
                // {field: 'referenceNumber', title: 'Reference Number'},
                // {field: 'Service', title: 'Service'},
                {field: 'Date', title: 'Date of Activity'},
                {field: 'currentUserName', title: 'Current User'},
                {field: 'branch_name', title: 'Branch Name'},
                {field: 'Status', title: 'Status'},
            ],
        };
    }


    dateConverter = (value) => {

        return value.substr(0, 10);
    };

    componentDidMount() {
        this.dynamicApiCall("branch_id", "branch")
    }

    dynamicApiCall = (fieldName, apiType, setData) => {
        let branch=[];
        let codeUrl = backEndServerURL + "/workplaceUnit/getInitialUnits";
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((item) => {
                    branch.push({key: item.solId, value: item.name})
                });
                this.findByVarNameApiData(fieldName,branch)
                this.setState({
                    showValue:true,
                    getData:true,
                })
            })
            .catch((error) => {
                console.log(error);
            })
    };
    returnService = (priorityDesc, freeFlag1, freeFlag6) => {
        if ((freeFlag6 === "Customer Information Update" || freeFlag6 === "Mandate/Related Party Maintenance" || freeFlag6 === "Dormant Activation & Maintenance") && freeFlag1) {
            return freeFlag1.replaceAll("^", ",")
        } else {
            return priorityDesc
        }
    }

    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = SearchForm;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                SearchForm[i].enum = getValue;
            }
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };


    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === undefined)
                delete clone[prop];
        return clone;
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.fetchDataFromAPI(event, 0);
        }
    };

    returnCategory = (branchingcategory, freeFlag5, category) => {
        if (branchingcategory !== undefined && branchingcategory !== null && branchingcategory !== "") {
            return branchingcategory
        } else if (freeFlag5 !== undefined && freeFlag5 !== null && freeFlag5 !== "") {
            return freeFlag5
        } else {
            return category
        }
    };

    returnSubCategory = (branchingSubCategory, freeFlag6, subCategory) => {
        if (branchingSubCategory !== undefined && branchingSubCategory !== null && branchingSubCategory !== "") {
            return branchingSubCategory
        } else if (freeFlag6 !== undefined && freeFlag6 !== null && freeFlag6 !== "") {
            return freeFlag6
        } else {
            return subCategory
        }

    };

    fetchDataFromAPI = (event, pageNumber) => {
        event.preventDefault();
        let page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;

        let input = this.removeNullValue(this.state.inputData);
        if (Object.keys(input).length === 0) {
            return 0;
        }
        let error = MyValidation.defaultValidation(SearchForm, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true,
                pageNumber: page,
            });
            let searchUrl = backEndServerURL + "/searchAppDelegations/newglobal/" + page;
            axios.post(searchUrl, input, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    console.log(response.data);
                    if (response.status === 200) {
                        let array = [];
                        let responseArray = [];
                        let rowData;
                        let responseRowData;
                        response.data.content.map((data, index) => {
                            responseRowData = data.appId;
                            rowData = {
                                freeFlag2: data.freeFlag2,
                                sl: index + 1,
                                id: data.id,
                                appId: data.appId,
                                caseId: data.caseId,
                                customer_name: data.customerName,
                                cb_number: data.cbNumber,
                                account_number: data.accountNumber,
                                businessSegment: data.businessSegment,
                                category_type: this.returnCategory(data.branchingCategory, data.freeFlag5, data.category,data.serviceType),
                                sub_category_type: this.returnSubCategory(data.branchingSubCategory, data.freeFlag6, data.subCategory, data.serviceType),
                                referenceNumber: this.getReferenceNumber(data.freeFlag3, data.subServiceType, data.serviceType),
                                registrationNumber: data.freeFlag4,
                                projectName: data.freeFlag5,
                                Service: this.returnService(data.priorityDesc, data.freeFlag1, data.freeFlag6),
                                subServiceType: data.subServiceType,
                                serviceType: data.serviceType,
                                Date: this.dateConverter(data.appInitTime),
                                currentUserName: data.currentUserName,
                                branch_name: data.branchName,
                                Status: data.delStatus,
                                solId: data.solId
                            };
                            array.push(rowData);
                            responseArray.push(responseRowData);
                        });
                        this.setState({
                            allData: array,
                            responseData: responseArray,
                            inputData: {...input},
                            varValue: {...input},
                            showValue: true,
                            loading: false,
                            pageNumber: this.state.pageNumber,
                            totalRow: response.data.totalElements,
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        inputData: {...input},
                        varValue: {...input},
                        showValue: true,
                        loading: false
                    })
                });
        }
    };

    getReferenceNumber = (referenceNumber, subServiceType) => {
        if (referenceNumber && subServiceType === "Inward") {
            return referenceNumber
        } else {
            return "";
        }
    };

    caseHistory = (e, rowData) => {
        e.preventDefault();
        console.log(rowData);
        this.setState({
            freeFlag2: rowData.freeFlag2,
            appId: rowData.appId,
            customerName: rowData.customer_name,
            serviceTypes: rowData.serviceType,
            accountNumber: rowData.account_number,
            cbNumber: rowData.cb_number,
            category_type: rowData.category_type,
            sub_category_type: rowData.sub_category_type,
            serviceType: rowData.serviceType,
            subServiceType: rowData.subServiceType,
            solId: rowData.solId,
            caseId: rowData.caseId,
            history: true
        });
    };

    closeHistoryModal = () => {
        this.setState({
            history: false,
        })
    };


    resetHandler = () => {
        window.location.reload();
    };

    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >
                                Global Search
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        SearchForm,
                                        this.updateComponent, this.onKeyDownForDedup
                                    )}
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            height: 30,
                                            marginTop: 20,
                                        }}
                                        onClick={e => {
                                            this.fetchDataFromAPI(e, 0)
                                        }}
                                    >
                                        Search
                                    </button>
                                    &nbsp;
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            height: 30,
                                            marginTop: 20,
                                        }}
                                        onClick={this.resetHandler}
                                    >
                                        Reset
                                    </button>
                                </ThemeProvider>
                            </Grid>
                            <br/>

                            <Dialog
                                fullWidth="true"
                                maxWidth="md"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.history}>
                                <DialogContent className={classes.dialogPaper}>
                                    <SubmittedCaseHistory
                                        branchName={this.state.branch_name}
                                        caseId={this.state.caseId}
                                        subServiceType={this.state.subServiceType}
                                        category={this.state.category_type}
                                        serviceType={this.state.serviceType}
                                        subCategory={this.state.sub_category_type}
                                        freeFlag2={this.state.freeFlag2 === "externalUserClosedRequest" ? true : false}
                                        serviceType={this.state.serviceTypes}
                                        customerName={this.state.customerName}
                                        accountNumber={this.state.accountNumber}
                                        cbNumber={this.state.cbNumber}
                                        solId={this.state.solId} globalSearch={true}
                                        closeIcon={this.closeHistoryModal} appId={this.state.appId}
                                        closeModal={this.closeHistoryModal}/>
                                </DialogContent>
                            </Dialog>
                        </CardBody>
                        <CardBody>
                            <div>
                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h4 style={{color: "white"}} >
                                        Global Search
                                    </h4>
                                </CardHeader>
                                <MuiThemeProvider theme={themeO}>
                                    <div style={{maxWidth: "100%"}}>
                                        <MaterialTable
                                            columns={this.state.columns}
                                            data={this.state.allData}
                                            title={" "}
                                            options={{...options, selection: false, tableLayout: "auto"}}
                                            localization={localization}
                                            icons={tableIcons}
                                            onRowClick={(e, rowData) => {
                                                this.caseHistory(e, rowData)
                                            }}
                                            components={{
                                                Pagination: props => (
                                                    <TablePagination
                                                        {...props}
                                                        rowsPerPageOptions={[50]}
                                                        count={this.state.totalRow}
                                                        page={this.state.pageNumber}
                                                        onChangePage={(e, page) => {
                                                            this.fetchDataFromAPI(e, page)
                                                        }
                                                        }
                                                    />
                                                ),
                                            }}
                                        />
                                    </div>
                                </MuiThemeProvider>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            );
        }

    }

}

export default withStyles(styles)(GlobalSearch);
