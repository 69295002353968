import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import Functions from '../../Common/Functions';
import theme from "../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import Notification from "../NotificationMessage/Notification";
import loading from "../../Static/loader.gif";
import CBNotFound from "./CASA/CBNotFound";
import TableComponent from "./CommonComponent/TableComponent";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import {Menu} from "semantic-ui-react";
import {
    buttonStyle,
    commonInboxButton,
    commonInboxTabActive,
    commonInboxTabInactive
} from "../DeliverableManagement/configuration";
import Badge from "@material-ui/core/Badge";
import {
    remittanceGroupInbox,
    rows,
    rowsBranch,
    rowsCS,
    rowsCSInbox,
    rowsCSWithReturned,
    rowsWithGroupInbox,
    rowsWithReturned
} from "./ColumnNameForInbox"
import SubmittedCaseHistory from "./CommonComponent/SubmittedCaseHistory";
import CSInboxCase from "./CSInboxCase";
import BMInboxCase from "./BMInboxCase";
import BOMInboxCase from "./BOMInboxCase";
import CallCenterInboxCase from "./Maintenance/CallCenterInboxCase";
import MakerInboxCase from "./MakerInboxCase";
import CheckerInboxCase from "./CheckerInboxCase";
import MerchantInboxCase from "./Merchant/MerchantInboxCase";
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";

const filterField = [
    {
        "varName": "caseId",
        "type": "textDedup",
        "label": "  Case Number",
        "grid": 2,
    },
    /*{
        "varName": "referenceNumber",
        "type": "text",
        "label": "Reference Number",
        "grid": 2,
    },
    {
        "varName": "category",
        "type": "select",
        "enum": [
            "CASA",
            "FDR",
            "DPS",
            "Mandate",
            "Chequebook",
            "RTGS",
            "BEFTN",
            "Salary",
            "Debit Card Issuance",
            "BOND",
            "SANCHAYPATRA",
            "Debit Card",
            "Credit Card",
            "CARDS",
            "ADC",
            "Internet Banking",
            "Remittance",
            "Pay Order and FDD Bulk",
            "Locker",
            "Delivarable",
            "Deferral",
        ],
        "label": "Category",
        "grid": 2,
    },
    {
        "varName": "subCategory",
        "type": "select",
        "enum": [
            "A/C Opening",
            "Opening",
            "Schem Account opeing",
            "TD Open/FDR",
            "Maintenance",
            "Dormant Activation",
            "Mandate Update",
            // "Customer Information Update",
            // "Dormant Activation & Maintenance",
            // "Mandate/Related Party Maintenance",
            "Single Requisition",
            "Opening",
            "Service",
            "Single Transaction",
            "Bulk Transaction",
            "Requisition",
            "Chequebook",
            "Bulk Insta Debit Card",
            "Bulk Requisition",
            "CASA Closer",
            "FDR Closer",
            "FDR Maintenace",
            "CARD Against FDR/ DPS",
            "Tracker",
            "Instant Card",
            "General Card Requisition",
            "CARD Cheque Requisition",
            "City Touch Enrollment",
            "SMS Discountinue",
            "Outward",
            "Inward",
            "Student File",
            "Medical File",
            "Searcher",
            "Others",
            "Pay Order Bulk Requisition",
            "FDD Bulk Requisition",
            "Others",
            "Requistion",
            "Surrender",
            "Locker Maintenace",
            "Reconcilation Branch",
            "Insurance Upload",
            "Insta Pack Requisition",
            "Insta Card Requisition",
            "Insta Pack Tracker",
            "Insta Card Tracker",
            "WL Priority",
            "WL Non Priority",
            "CASA Defferal",
            "FD Deferal",
            "Others",
            "FDR",
            "Encashment",
            "ISSUANCE - Wage Earners Development Bond",
            "ISSUANCE - U.S. Dollar Investment Bond",
            "ISSUANCE - U.S. Dollar Premium Bond",
            "ISSUANCE - 5 Years Bangladesh Sanchaya Patra",
            "ISSUANCE - 3 Months Interest Based Sanchaya Patra",
            "ISSUANCE - Family Sanchaya Patra",
            "ISSUANCE - Pensioners Savings Certificate",
            "ENCASHMENT - Wage Earners Development Bond - ENCASHMENT PAYMENT",
            "ENCASHMENT - Wage Earners Development Bond - INTEREST PAYMENT",
            "ENCASHMENT - U.S. Dollar Investment Bond - ENCASHMENT PAYMENT",
            "ENCASHMENT - U.S. Dollar Investment Bond - INTEREST PAYMENT",
            "ENCASHMENT - U.S. Dollar Premium Bond - ENCASHMENT PAYMENT",
            "ENCASHMENT - U.S. Dollar Premium Bond - INTEREST PAYMENT",
            "ENCASHMENT - 5 Years Bangladesh Sanchaya Patra - ENCASHMENT PAYMENT",
            "ENCASHMENT - 5 Years Bangladesh Sanchaya Patra - INTEREST PAYMENT",
            "ENCASHMENT - 3 Months Interest Based Sanchaya Patra - ENCASHMENT PAYMENT",
            "ENCASHMENT - 3 Months Interest Based Sanchaya Patra - INTEREST PAYMENT",
            "ENCASHMENT - Family Sanchaya Patra - ENCASHMENT PAYMENT",
            "ENCASHMENT - Family Sanchaya Patra - INTEREST PAYMENT",
            "ENCASHMENT - Pensioners Savings Certificate - ENCASHMENT PAYMENT",
            "ENCASHMENT - Pensioners Savings Certificate - INTEREST PAYMENT",
            "MAINTENANCE - Wage Earners Development Bond - NOMINEE CHANG",
            "MAINTENANCE - Wage Earners Development Bond - ACES ENROLLMENT",
            "MAINTENANCE - Wage Earners Development Bond - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - Wage Earners Development Bond - DUPLICATE ISSUANCE",
            "MAINTENANCE - Wage Earners Development Bond - CERTIFICATE",
            "MAINTENANCE - U.S. Dollar Investment Bond - NOMINEE CHANG",
            "MAINTENANCE - U.S. Dollar Investment Bond - ACES ENROLLMENT",
            "MAINTENANCE - U.S. Dollar Investment Bond - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - U.S. Dollar Investment Bond - DUPLICATE ISSUANCE",
            "MAINTENANCE - U.S. Dollar Investment Bond - CERTIFICATE",
            "MAINTENANCE - U.S. Dollar Premium Bond - NOMINEE CHANG",
            "MAINTENANCE - U.S. Dollar Premium Bond - ACES ENROLLMENT",
            "MAINTENANCE - U.S. Dollar Premium Bond - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - U.S. Dollar Premium Bond - DUPLICATE ISSUANCE",
            "MAINTENANCE - U.S. Dollar Premium Bond - CERTIFICATE",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - NOMINEE CHANG",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - ACES ENROLLMENT",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - DUPLICATE ISSUANCE",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - CERTIFICATE",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - NOMINEE CHANG",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - ACES ENROLLMENT",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - DUPLICATE ISSUANCE",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - CERTIFICATE",
            "MAINTENANCE - Family Sanchaya Patra - NOMINEE CHANG",
            "MAINTENANCE - Family Sanchaya Patra - ACES ENROLLMENT",
            "MAINTENANCE - Family Sanchaya Patra - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - Family Sanchaya Patra - DUPLICATE ISSUANCE",
            "MAINTENANCE - Family Sanchaya Patra - CERTIFICATE",
            "MAINTENANCE - Pensioners Savings Certificate - NOMINEE CHANG",
            "MAINTENANCE - Pensioners Savings Certificate - ACES ENROLLMENT",
            "MAINTENANCE - Pensioners Savings Certificate - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - Pensioners Savings Certificate - DUPLICATE ISSUANCE",
            "MAINTENANCE - Pensioners Savings Certificate - CERTIFICATE",
        ],
        "label": "Sub Category",
        "grid": 2,
    },
    {
        "varName": "branch_id",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Branch",
        "grid": 2,
    },
    {
        "varName": "cbNumber",
        "type": "textDedup",
        "label": "CB Number",
        "grid": 2,
    },
    {
        "varName": "accountNumber",
        "type": "textDedup",
        "label": "Account No.",
        "grid": 2,
    },
    {
        "varName": "customerName",
        "type": "textDedup",
        "label": "Customer name",
        "grid": 2,
    },*/
    {
        "varName": "fromDate",
        "type": "date",
        "label": "From Date",
        "grid": 2,
    },
    {
        "varName": "toDate",
        "type": "date",
        "label": "To Date",
        "grid": 2,
    },
   /* {
        "varName": "sdReceiveDate",
        "type": "select",
        "enum":[
            "YES",
            "NO",
        ],
        "label": "SD Receive Date",
        "grid": 2,
    },
    {
        "varName": "urgency",
        "type": "select",
        "enum": [
            "High",
            "Medium",
            "Low",
        ],
        "label": "Urgency",
        "grid": 2,
    },
    {
        "varName": "cutOffTime",
        "type": "select",
        "enum": [
            "Before",
            "After",
        ],
        "label": "Cut Off Time",
        "grid": 2,
    },*/
];


const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

class CommonInbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            loading: true,
            cbNotFound: false,
            getData: false,
            varValue: {},
            title: "",
            notificationMessage: "",
            alert: false,
            selectedDate: {},
            showValue: false,
            openHierarchy: false,
            err: false,
            searchData: false,
            activeItem: "Group Inbox",
            showTable: false,
            errorArray: {},
            errorMessages: {},
            tableData: [],
            totalRow: 0,
            rowsPerPage: 50,
            tableColumns: [],
            page: 0,
            showInbox: false,
            serviceType: "",
            subServiceType: "",
            category: "",
            subCategory: "",
            solId: "",
            appUid: "",
            delStatus: "",
            taskTitle: "",
            status: "",
            freeFlag1: "",
            freeFlag2: "",
            initBranchName: "",
            freeFlag3: "",
            freeFlag4: "",
            recpmtid: "",
            cb_number: "",
            account_number: "",
            customer_name: "",
            CaseId: "",
            urgency: "",
            sentByUsername: "",
            jointAccountCustomerNumber: "",
            getCountPending: 0,
            getCountReturn: 0,
            getCountFileUpload: 0,
            getCountInbox: 0,
            getCountRectified: 0,
            getCountGroupInbox: 0,
            getCountDormant: 0,
            getCountDormantRectified: 0,
            getCountForCasaOpen: 0,
            getCountForCasaOpenRectified: 0,
            getCountMaintenance: 0,
            getCountMaintenanceRectified: 0,
            getRemittanceGroupCount: 0,
            getRemittanceRectifiedCount: 0,
            getRemittanceGroupCountForBranch: 0
        }
    }


    renderTableColumn = () => {
        let tableColumn = [];
        let workflowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        let workflowPlace = LocalstorageEncrypt.encryptStorageFunction.getItem("workplace");
        let workflowPlaceUnit = LocalstorageEncrypt.encryptStorageFunction.getItem("workplaceUnits");
        if (this.state.activeItem === "Return" && (workflowGroup === "CS" || workflowGroup === "DSTCS")) {
            tableColumn = [...rowsCSWithReturned]
        } else if (workflowGroup === "CS" || workflowGroup === "DSTCS") {
            tableColumn = [...rowsCS]
        } else if (this.state.activeItem === "Inbox") {
            if (workflowPlaceUnit === "Remetance" || workflowPlaceUnit === "Remittence SD Dhaka") {
                tableColumn = [...remittanceGroupInbox]
            } else {
                tableColumn = [...rowsCSInbox]
            }
        } else if (workflowGroup !== "CS" && workflowGroup !== "DSTCS" && workflowPlace === "BRANCH") {
            tableColumn = [...rowsBranch]
        } else if (this.state.activeItem === "Return") {
            tableColumn = [...rowsWithReturned]
        } else if (this.state.activeItem === "Remittance Group Inbox SD" || this.state.activeItem === "Remittance Rectified Request") {
            tableColumn = [...remittanceGroupInbox]
        } else if (this.state.activeItem === "Group Inbox" || this.state.activeItem === "RECTIFIED REQUEST" || this.state.activeItem === "Maintenance Rectified Request" || this.state.activeItem === "Dormant Activation Rectified Request" || this.state.activeItem === "Account Opening Rectified Request") {
            tableColumn = [...rowsWithGroupInbox]
        } else {
            tableColumn = [...rows]
        }
        if (workflowGroup === "MAKER" || workflowGroup === "CHECKER") {
            tableColumn.push({field: 'makerId', title: 'Maker ID'},)
        }
        return rowsCSInbox;
    };


    getApiUrl = () => {
        let url = "";
        let workFlowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        if (workFlowGroup === "CALL_CENTER_CHECKER" || workFlowGroup === "CALL_CENTER_MAKER") {
            url = backEndServerURL + '/workspaceClaimable/WithoutMerchant/0';
            this.setState({
                activeItem: 'Group Inbox',
            })
        }
       else if (workFlowGroup === "MERCHANT" || workFlowGroup === "CS" || workFlowGroup === "INTERNET_BANKING" || workFlowGroup === "ADC") {
            url = backEndServerURL + '/workspaceClaimable/0';
        } else if (workFlowGroup === "BM" || workFlowGroup === "BOM") {
            url = backEndServerURL + '/serviceWise/claimable/withoutRectified/' + workFlowGroup + "/NOT_REMITTANCE_BRANCH/" + 0;
            this.setState({
                activeItem: 'Group Inbox',
            })
        } else if (workFlowGroup === "CS" || workFlowGroup === "DSTCS") {
            // url = backEndServerURL + '/inbox/return/0';
            url = backEndServerURL + '/csGroupWiseReturnRequest/0';
            this.setState({
                activeItem: 'Return',
            })
        } else {
            if ((LocalstorageEncrypt.encryptStorageFunction.getItem("menus")).indexOf("GROUP LOCK") !== -1) {
                url = backEndServerURL + '/claimable/withoutRectified/0';
            } else if ((LocalstorageEncrypt.encryptStorageFunction.getItem("menus")).indexOf("ACCOUNT OPENING GROUP LOCK") !== -1) {
                this.setState({
                    activeItem: "Account Opening Group Inbox"
                });
                url = backEndServerURL + '/serviceWise/claimable/withoutRectified/' + workFlowGroup + "/CASA_OPENING/0";
            } else if ((LocalstorageEncrypt.encryptStorageFunction.getItem("menus")).indexOf("REMITTANCE GROUP LOCK SD") !== -1) {
                this.setState({
                    activeItem: "Remittance Group Inbox SD"
                });
                url = backEndServerURL + '/serviceWise/claimable/withoutRectified/' + workFlowGroup + '/ONLY_REMITTANCE/0';
            } else if ((LocalstorageEncrypt.encryptStorageFunction.getItem("menus")).indexOf("MAINTENANCE GROUP LOCK") !== -1) {
                this.setState({
                    activeItem: "Maintenance Group Inbox"
                });
                url = backEndServerURL + '/serviceWise/claimable/withoutRectified/' + workFlowGroup + '/MAINTENANCE_NOT_DORMANT/0';
            } else if ((LocalstorageEncrypt.encryptStorageFunction.getItem("menus")).indexOf("DORMANT ACTIVATION GROUP LOCK") !== -1) {
                this.setState({
                    activeItem: "Dormant Activation Group Inbox"
                });
                url = backEndServerURL + '/serviceWise/claimable/withoutRectified/' + workFlowGroup + '/DORMANT_ACTIVATION_SD/0';
            }
        }

        return url;
    };



    componentDidMount() {
        let branch = [];
        let branchUrl = backEndServerURL + "/workplaceUnit/getInitialUnits";
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((item) => {
                    branch.push({key: item.solId, value: item.name})
                });
                for (let form of filterField) {
                    if (form.type === "autoCompleteValueReturn" && form.varName === "branch_id") {
                        form.enum = branch
                    }
                }

                this.fetchTableData(this.getApiUrl(), 0)

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    getData: true,
                    showTable: true,
                    loading: false,
                });
            })
    }


    fetchTableData = (url, page) => {

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(response => {
            let tableData = [];
            response.data.content.map((message, index) => {
                tableData.push({
                    SL: page > 0 ? ((index + 1) + (Number(this.state.rowsPerPage) * page)) : index + 1,
                    CaseId: message.caseId,
                    currency: message.freeFlag1,
                    amount: message.freeFlag2,
                    reference: message.freeFlag3,
                    customer_name: message.customerName,
                    cb_number: message.cbNumber,
                    account_number: message.accountNumber,
                    businessSegment: message.businessSegment,
                    category_type: this.returnCategory(message.branchingCategory, message.freeFlag5, message.category),
                    sub_category_type: this.returnSubCategory(message.branchingSubCategory, message.freeFlag6, message.subCategory,message.serviceType),
                    Service: this.returnService(message.priorityDesc, message.freeFlag1, message.freeFlag6),
                    Date: message.delInitTime!==undefined && message.delInitTime!==null?message.delInitTime.replace("T", " "):message.delInitTime,
                    currentUserName: message.currentUserName,
                    branch_name: message.branchName,
                    Status: message.delStatus,
                    freeFlag1: message.freeFlag1,
                    freeFlag2: message.freeFlag2,
                    freeFlag3: message.freeFlag3,
                    freeFlag4: message.freeFlag4,
                    recpmtid: message.recpmtid,
                    freeFlag5: message.freeFlag5,
                    freeFlag6: message.freeFlag6,
                    appId: message.appId,
                    serviceType: message.serviceType,
                    subServiceType: message.subServiceType,
                    taskTitle: message.taskTitle,
                    solId: message.solId,
                    urgency: message.urgency,
                    returnedBack: message.returnBackTo ? message.returnBackTo : "",
                    returnedBy: message.sentByUsername,
                    jointAccountCustomerNumber: message.jointAccountCustomerNumber,
                    requestStatus: message.status,
                    makerId: message.lastMakerUser,
                    initUser: message.initiatedBy,
                });
            });
            this.setState({
                page: response.data.number,
                totalRow: response.data.totalElements,
                tableData: tableData,
                getData: true,
                showTable: true,
                loading: false
            })

        }).catch(error => {
            console.log(error);
            this.setState({
                loading: false,
                showTable: true,
                searchData: false,
            })
        });
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderCloseIcon = () => {
        return (
            <h4 style={{color: "white"}} >All Inbox</h4>
        )
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    handleChangePage = (pageNumber) => {
        this.setState({
            loading: true,
            getData: false,
            showTable: false
        });
        if (this.state.searchData) {
            this.searchHandler("", pageNumber)
        } else {
            this.functionForGetTabWiseUrl(this.state.activeItem, pageNumber);
        }
    };


    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'null' || clone[prop] === null || clone[prop] === 'undefined')
                delete clone[prop];
        return clone;
    };


    returnService = (priorityDesc, freeFlag1, freeFlag6) => {
        if ((freeFlag6 === "Customer Information Update" || freeFlag6 === "Mandate/Related Party Maintenance" || freeFlag6 === "Dormant Activation & Maintenance") && freeFlag1) {
            if(freeFlag1!==null && freeFlag1!==undefined){
                return freeFlag1.replaceAll("^", ",")
            }
            else {
                return freeFlag1;
            }
        } else {
            return priorityDesc
        }
    }

    returnCategory = (branchingCategory, freeFlag5, category) => {
        if (branchingCategory !== undefined && branchingCategory !== null && branchingCategory !== "") {
            return branchingCategory
        } else if (freeFlag5 !== undefined && freeFlag5 !== null && freeFlag5 !== "") {
            return freeFlag5
        } else {
            return category
        }
    };

    returnSubCategory = (branchingSubCategory, freeFlag6, subCategory,serviceType) => {
        console.log(serviceType);
        if(serviceType === "bond_issuance_serve"){
            return subCategory
        }
        else if (branchingSubCategory !== undefined && branchingSubCategory !== null && branchingSubCategory !== "") {
            return branchingSubCategory
        } else if (freeFlag6 !== undefined && freeFlag6 !== null && freeFlag6 !== "") {
            return freeFlag6
        } else {
            return subCategory
        }
    };


    manageStatusForSearch = () => {
        switch (this.state.activeItem) {
            case "Group Inbox":
            case "Maintenance Group Inbox":
            case "Remittance Group Inbox":
            case "Remittance Group Inbox SD":
            case "Dormant Activation Group Inbox":
            case "Account Opening Group Inbox":
                return "Open";
            case "Inbox":
                return "Locked";

            case "Account Opening Rectified Request":
            case "Document Tracker Rectified Request":
            case "Dormant Activation Rectified Request":
            case "Maintenance Rectified Request":
            case "RECTIFIED REQUEST":
                return "RECTIFIED REQUEST";

            case "Pending":
                return "Saved";
            case "Return":
                return "Return";
            case "Submit":
                return "Submit";
            case "Complete":
                return "Complete";
            case "DocumentUpload":
                return "DOCUMENTUPLOAD";
            case "Certificate":
                return "Enrollment";
            default:
                return this.state.activeItem;
        }
    };
    getBackendRequestType = () => {
        switch (this.state.activeItem) {
            case "Account Opening Rectified Request":
            case "Account Opening Group Inbox":
                return "CASA_OPENING";
            case "Document Tracker Rectified Request":
            case "Document Tracker Group Inbox":
                return "DOCUMENT_TRACKER";
            case "Dormant Activation Rectified Request":
            case "Dormant Activation Group Inbox":
                return "DORMANT_ACTIVATION_SD";
            case "Maintenance Rectified Request":
            case "Maintenance Group Inbox":
                return "MAINTENANCE_NOT_DORMANT";
            default:
                return "all";
        }
    }

    searchHandler = (event, pageNumber) => {
        this.setState({
            page: 0,
            totalRow: 0,
            tableData: [],
            loading: true,
            showTable: false,
        });
        let pageN = pageNumber ? pageNumber : 0;
        let postData = this.removeNullValue(this.state.inputData);
        if (this.state.activeItem === "Group Inbox" && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf("INTERNET_BANKING") !== -1) {
            postData.tabName = "INTERNET_BANKING"
        } else if (this.manageStatusForSearch() === "Open" ) {
            postData.tabName = "Group Inbox"
            postData.requestType=this.getBackendRequestType();
        }else if (this.manageStatusForSearch() === "Return") {
            postData.tabName = "Group Inbox"
        }
        else if(this.manageStatusForSearch() === "RECTIFIED REQUEST" ){
            postData.tabName=this.getBackendRequestType();
        }

        let url = backEndServerURL + "/filterUserInbox/" + this.manageStatusForSearch() + "/" + pageN;
        axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(response => {
            let tableData = [];
            response.data.content.map((message, index) => {
                tableData.push({
                    SL: pageNumber > 0 ? ((index + 1) + (Number(this.state.rowsPerPage) * pageNumber)) : index + 1,
                    CaseId: message.caseId,
                    customer_name: message.customerName,
                    cb_number: message.cbNumber,
                    currency: message.freeFlag1,
                    amount: message.freeFlag2,
                    reference: message.freeFlag3,
                    account_number: message.accountNumber,
                    businessSegment: message.businessSegment,
                    category_type: this.returnCategory(message.branchingCategory, message.freeFlag5, message.category),
                    sub_category_type: this.returnSubCategory(message.branchingSubCategory, message.freeFlag6, message.subCategory,message.serviceType),
                    Service: this.returnService(message.priorityDesc, message.freeFlag1, message.freeFlag6),
                    Date: message.delInitTime!==undefined && message.delInitTime!==null?message.delInitTime.replace("T", " "):message.delInitTime,
                    currentUserName: message.currentUserName,
                    branch_name: message.branchName,
                    Status: message.delStatus,
                    freeFlag1: message.freeFlag1,
                    freeFlag2: message.freeFlag2,
                    freeFlag3: message.freeFlag3,
                    freeFlag4: message.freeFlag4,
                    recpmtid: message.recpmtid,
                    freeFlag5: message.freeFlag5,
                    freeFlag6: message.freeFlag6,
                    appId: message.appId,
                    serviceType: message.serviceType,
                    subServiceType: message.subServiceType,
                    taskTitle: message.taskTitle,
                    solId: message.solId,
                    urgency: message.urgency,
                    returnedBack: message.returnBackTo ? message.returnBackTo : "",
                    returnedBy: message.sentByUsername,
                    jointAccountCustomerNumber: message.jointAccountCustomerNumber,
                    requestStatus: message.status,
                    makerId: message.lastMakerUser,
                    initUser: message.initiatedBy,
                });
            });
            this.setState({
                page: response.data.number,
                totalRow: response.data.totalElements,
                tableData: tableData,
                getData: true,
                showTable: true,
                searchData: true,
                loading: false
            })

        }).catch(error => {
            console.log(error);
            this.setState({
                loading: false,
                showTable: true,
                searchData: false,
            })
        });
    };

    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.searchHandler(event, 0);
        }
    };


    renderFilterField = () => {
        return (
            <Grid container>
                {CommonJsonFormComponent.renderJsonForm(this.state, filterField, this.updateComponent, this.onKeyDownForDedup)}
                <button
                      style={buttonStyle}
                    onClick={(e) => this.searchHandler(e, 0)}
                    type="submit"
                >
                    Search
                </button>
                &nbsp;
                <button
                      style={buttonStyle}
                    onClick={() => window.location.reload()}
                    type="submit"
                >
                    Reset
                </button>
            </Grid>
        );
    };


    headerSelect = (event, value) => {
        event.preventDefault();
        this.setState({
            searchData: false,
            activeItem: value,
            loading: true,
            getData: false,
            showTable: false,
            page: 0,
            tableData: [],
        }, this.functionForGetTabWiseUrl(value, 0));
    };

    functionForGetTabWiseUrl = (value, page) => {
        this.forceUpdate();
        let urls = "";
        let workflowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        if (value === "Inbox") {
            if(workflowGroup==="CALL_CENTER_MAKER" || workflowGroup==="CALL_CENTER_CHECKER") {
                urls = backEndServerURL + '/inbox/waiting/WithoutMerchant/' + page;
            }
            else {
                urls = backEndServerURL + '/inbox/waiting/' + page;
            }
        } else if (value === "Return") {
            // urls = backEndServerURL + '/inbox/return/' + page;
            if (workflowGroup === "MAKER" || workflowGroup === "CHECKER") {
                urls = backEndServerURL + '/getGroupWiseReturnRequest/' + page;
            } else {
                if(workflowGroup==="CALL_CENTER_MAKER" || workflowGroup==="CALL_CENTER_CHECKER") {
                    urls = backEndServerURL + '/csGroupWiseReturnRequest/WithoutMerchant/' + page;
                }
                else{
                    urls = backEndServerURL + '/csGroupWiseReturnRequest/' + page;
                }

            }
        } else if (value === "Pending") {
            urls = backEndServerURL + '/inbox/saved/' + page;
        } else if (value === "DocumentUpload") {
            urls = backEndServerURL + '/workspaceClaimableFileUpload/' + page;
        } else if (value === "Submit") {
            urls = backEndServerURL + '/perticipated/' + page;
        } else if (value === "SD PENDING CASE") {
            urls = backEndServerURL + '/shadowedCasesTD/' + page;
        } else if (value === "Certificate") {
            urls = backEndServerURL + '/getInboxTabData/Certificate/' + page;
        } else if (value === "Complete") {
            urls = backEndServerURL + '/perticipatedClosed/' + page;
        } else if (value === "Group Inbox") {
            if (workflowGroup === "CALL_CENTER_CHECKER" || workflowGroup === "CALL_CENTER_MAKER") {
                urls = backEndServerURL + '/workspaceClaimable/WithoutMerchant/' + page;

            }
            else if (workflowGroup === "MERCHANT" || workflowGroup === "CS" || workflowGroup === "INTERNET_BANKING" || workflowGroup === "ADC") {
                urls = backEndServerURL + '/workspaceClaimable/' + page;
            } else if (workflowGroup === "BM" || workflowGroup === "BOM") {
                urls = backEndServerURL + '/serviceWise/claimable/withoutRectified/' + workflowGroup + "/NOT_REMITTANCE_BRANCH/" + page;
            } else {
                urls = backEndServerURL + '/claimable/withoutRectified/' + page;
            }

        } else if (value === "Remittance Group Inbox") {
            urls = backEndServerURL + '/serviceWise/claimable/withoutRectified/' + workflowGroup + "/REMITTANCE_BRANCH/" + page;
        } else if (value === "Remittance Group Inbox SD") {
            urls = backEndServerURL + '/serviceWise/claimable/withoutRectified/' + workflowGroup + "/ONLY_REMITTANCE/" + page;
        } else if (value === "Remittance Rectified Request") {
            urls = backEndServerURL + '/serviceWise/claimable/withRectified/' + workflowGroup + "/ONLY_REMITTANCE/" + page;
        } else if (value === "Maintenance Group Inbox") {
            urls = backEndServerURL + '/serviceWise/claimable/withoutRectified/' + workflowGroup + "/MAINTENANCE_NOT_DORMANT/" + page;
        } else if (value === "Dormant Activation Group Inbox") {
            urls = backEndServerURL + '/serviceWise/claimable/withoutRectified/' + workflowGroup + "/DORMANT_ACTIVATION_SD/" + page;
        } else if (value === "Account Opening Group Inbox") {
            urls = backEndServerURL + '/serviceWise/claimable/withoutRectified/' + workflowGroup + "/CASA_OPENING/" + page;
        } else if (value === "Account Opening Rectified Request") {
            urls = backEndServerURL + '/serviceWise/claimable/withRectified/' + workflowGroup + "/CASA_OPENING/" + page;
        } else if (value === "Dormant Activation Rectified Request") {
            urls = backEndServerURL + '/serviceWise/claimable/withRectified/' + workflowGroup + "/DORMANT_ACTIVATION_SD/" + page;
        } else if (value === "Maintenance Rectified Request") {
            urls = backEndServerURL + '/serviceWise/claimable/withRectified/' + workflowGroup + "/MAINTENANCE_NOT_DORMANT/" + page;
        } else if (value === "RECTIFIED REQUEST") {
            urls = backEndServerURL + '/claimable/withRectified/' + page;
        }
        this.fetchTableData(urls, page)
    };


    renderMenu = () => {
        return (
            <div style={{marginRight: "8px"}}>
                <Menu pointing style={{overflowX: 'auto', overflowY: 'hidden'}}>
                    {this.renderMenuItem("GROUP LOCK", "Group Inbox", this.state.getCountGroupInbox, "Group Inbox")}

                 {/*   {this.renderMenuItem("ACCOUNT OPENING GROUP LOCK", "Account Opening Group Inbox", undefined, "Group inbox (Ac Opening)")}

                    {this.renderMenuItem("REMITTANCE GROUP LOCK", "Remittance Group Inbox", undefined, "Group inbox (Remittance)")}

                    {this.renderMenuItem("REMITTANCE GROUP LOCK SD", "Remittance Group Inbox SD", undefined, "Group inbox (Remittance)")}

                    {this.renderMenuItem("MAINTENANCE GROUP LOCK", "Maintenance Group Inbox", undefined, "Group inbox (Maintenance)")}

                    {this.renderMenuItem("DORMANT ACTIVATION GROUP LOCK", "Dormant Activation Group Inbox", undefined, "Group inbox (Dormant Activation)")}

                    {this.renderMenuItem("RECTIFIED REQUEST", "RECTIFIED REQUEST", this.state.getCountRectified, "Corrected Requests")}

                    {this.renderMenuItem("ACCOUNT OPENING GROUP LOCK", "Account Opening Rectified Request", undefined, "Corrected Request(Account Opening)")}

                    {this.renderMenuItem("MAINTENANCE GROUP LOCK", "Maintenance Rectified Request", undefined, "Corrected Request(Maintenance)")}

                    {this.renderMenuItem("REMITTANCE GROUP LOCK SD", "Remittance Rectified Request", undefined, "Corrected Request (Remittance)")}

                    {this.renderMenuItem("DORMANT ACTIVATION GROUP LOCK", "Dormant Activation Rectified Request", undefined, "Corrected Request (Dormant Activation)")}
*/}
                    {this.renderMenuItem("INBOX", "Inbox", this.state.getCountInbox, "Self-Inbox")}

{/*
                    {this.renderMenuItem("SD PENDING CASE", "SD PENDING CASE", "", "SD Pending Case")}
*/}

                    {this.renderMenuItem("RETURN", "Return", undefined, "Returned Requests")}

                  {/*  {this.renderMenuItem("PENDING", "Pending", this.state.getCountPending, "Pending Requests")}
                    {this.renderMenuItem("DOCUMENTUPLOAD", "DocumentUpload", this.state.getCountFileUpload, "DocumentUpload Requests")}
*/}
                    {this.renderMenuItem("SUBMIT", "Submit", undefined, "Submit")}
                    {this.renderMenuItem("COMPLETE", "Complete", undefined, "Complete")}
{/*
                    {this.renderMenuItem("CERTIFICATE", "Certificate", undefined, "Certificate")}
*/}
                </Menu>
            </div>
        );
    };

    renderMenuItem = (permission, activeItem, badge, name) => {
        if ((LocalstorageEncrypt.encryptStorageFunction.getItem("menus")).indexOf(permission) !== -1) {
            return (
                <Menu.Item
                    name={activeItem}
                    active={this.state.activeItem === activeItem}
                    style={this.state.activeItem === activeItem ? commonInboxTabActive : commonInboxTabInactive}
                    onClick={e => {
                        this.headerSelect(e, activeItem);
                    }}
                >
                    {this.renderBadge(badge, name)}
                </Menu.Item>
            )
        }

    };

    renderBadge = (value, name) => {
        if (value !== undefined && value > 0) {
            return (
                <Badge  color="primary">
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        } else if (value === 0) {
            return (
                <Badge   color="primary" showZero>
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        } else {
            return (
                <Badge color="primary">
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        }
    };

    renderInboxCaseSubmitted = () => {
        return (
            <SubmittedCaseHistory delStatus={this.state.delStatus} closeModal={this.closeModal}
                                  closeIcon={this.closeModal}
                                  appId={this.state.appUid}
                                  subCategory={this.state.subCategory}
                                  category={this.state.category} serviceType={this.state.serviceType}
                                  freeFlag1={this.state.freeFlag1}
                                  freeFlag2={this.state.freeFlag2}
                                  freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                                  recpmtid={this.state.recpmtid}
                                  accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                                  customerName={this.state.customer_name}
                                  solId={this.state.solId}
                                  subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
        )
    };

    renderInboxCase = () => {
       if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('CALL_CENTER_CHECKER') !== -1) {
            return (
                <BOMInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeModal}
                              appUid={this.state.appUid} CaseId={this.state.CaseId}
                              subCategory={this.state.subCategory} category={this.state.category}
                              serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                              freeFlag2={this.state.freeFlag2}
                              freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                              recpmtid={this.state.recpmtid}
                              accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                              customerName={this.state.customer_name}
                              solId={this.state.solId}
                              initBranchName={this.state.initBranchName}
                              subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
            )
        }

        else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('CALL_CENTER_MAKER') !== -1) {
            return (
                <CSInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeModal}
                              appUid={this.state.appUid} CaseId={this.state.CaseId}
                              subCategory={this.state.subCategory} category={this.state.category}
                              serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                              freeFlag2={this.state.freeFlag2}
                              freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                              recpmtid={this.state.recpmtid}
                              accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                              customerName={this.state.customer_name}
                              solId={this.state.solId}
                              initBranchName={this.state.initBranchName}
                              subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
            )
        }
        else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('CALL_CENTER') !== -1) {
            return (
                <CallCenterInboxCase closeModal={this.closeModal} closeIcon={this.closeModal} appUid={this.state.appUid}
                                     CaseId={this.state.CaseId}
                                     serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                                     freeFlag2={this.state.freeFlag2}
                                     freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                                     recpmtid={this.state.recpmtid}
                                     accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                                     customerName={this.state.customer_name}
                                     solId={this.state.solId}
                                     initBranchName={this.state.initBranchName}
                                     subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
            )
        }
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "DSTCS" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CALL_CENTER") {
            return (
                <CSInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeModal}
                             appUid={this.state.appUid} CaseId={this.state.CaseId}
                             subCategory={this.state.subCategory}
                             category={this.state.category} serviceType={this.state.serviceType}
                             freeFlag1={this.state.freeFlag1}
                             freeFlag2={this.state.freeFlag2}
                             initBranchName={this.state.initBranchName}
                             freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                             recpmtid={this.state.recpmtid}
                             accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                             customerName={this.state.customer_name}
                             solId={this.state.solId}
                             subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CS" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CALL_CENTER") {
            return (
                <CSInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeModal}
                             appUid={this.state.appUid} CaseId={this.state.CaseId}
                             subCategory={this.state.subCategory} category={this.state.category}
                             serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                             freeFlag2={this.state.freeFlag2}
                             freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                             recpmtid={this.state.recpmtid}
                             accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                             customerName={this.state.customer_name}
                             solId={this.state.solId}
                             initBranchName={this.state.initBranchName}
                             subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('APPROVALOFFICER') !== -1) {
            return (
                <BMInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeModal}
                             appUid={this.state.appUid} CaseId={this.state.CaseId}
                             subCategory={this.state.subCategory} category={this.state.category}
                             serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                             freeFlag2={this.state.freeFlag2}
                             freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                             recpmtid={this.state.recpmtid}
                             accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                             customerName={this.state.customer_name}
                             solId={this.state.solId}
                             initBranchName={this.state.initBranchName}
                             subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('ABHCHECKER') !== -1) {
            return (
                <BOMInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeModal}
                              appUid={this.state.appUid} CaseId={this.state.CaseId}
                              subCategory={this.state.subCategory} category={this.state.category}
                              serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                              freeFlag2={this.state.freeFlag2}
                              freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                              recpmtid={this.state.recpmtid}
                              accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                              customerName={this.state.customer_name}
                              solId={this.state.solId}
                              initBranchName={this.state.initBranchName}
                              subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('INTERNET_BANKING') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('ADC') !== -1) {
            return (
                <BMInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeModal}
                             appUid={this.state.appUid} CaseId={this.state.CaseId}
                             subCategory={this.state.subCategory} category={this.state.category}
                             serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                             freeFlag2={this.state.freeFlag2}
                             freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                             recpmtid={this.state.recpmtid}
                             accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                             customerName={this.state.customer_name}
                             solId={this.state.solId}
                             initBranchName={this.state.initBranchName}
                             subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('ABHMAKER') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('CALL_CENTER') !== -1) {
            return (
                <CSInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeModal}
                             appUid={this.state.appUid} CaseId={this.state.CaseId}
                             subCategory={this.state.subCategory} category={this.state.category}
                             serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                             freeFlag2={this.state.freeFlag2}
                             freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                             recpmtid={this.state.recpmtid}
                             accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                             customerName={this.state.customer_name}
                             solId={this.state.solId}
                             initBranchName={this.state.initBranchName}
                             subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('DSTBOM') !== -1) {
            return (
                <BOMInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeModal}
                              appUid={this.state.appUid} CaseId={this.state.CaseId}
                              subCategory={this.state.subCategory} category={this.state.category}
                              serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                              freeFlag2={this.state.freeFlag2}
                              freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                              recpmtid={this.state.recpmtid}
                              accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                              customerName={this.state.customer_name}
                              solId={this.state.solId}
                              initBranchName={this.state.initBranchName}
                              subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf("BUSINESS_DIVISION") !== -1) {
            return (
                <BMInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeModal}
                             appUid={this.state.appUid} CaseId={this.state.CaseId}
                             subCategory={this.state.subCategory} category={this.state.category}
                             serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                             freeFlag2={this.state.freeFlag2}
                             freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                             recpmtid={this.state.recpmtid}
                             accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                             customerName={this.state.customer_name}
                             solId={this.state.solId}
                             initBranchName={this.state.initBranchName}
                             subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('BOM') !== -1
                    || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('CALL_CENTER_CHECKER') !== -1) {
            return (
                <BOMInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeModal}
                              appUid={this.state.appUid} CaseId={this.state.CaseId}
                              subCategory={this.state.subCategory} category={this.state.category}
                              serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                              freeFlag2={this.state.freeFlag2}
                              freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                              recpmtid={this.state.recpmtid}
                              accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                              customerName={this.state.customer_name}
                              solId={this.state.solId}
                              initBranchName={this.state.initBranchName}
                              subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('BM') !== -1) {
            return (
                <BMInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeModal}
                             appUid={this.state.appUid} CaseId={this.state.CaseId}
                             subCategory={this.state.subCategory} category={this.state.category}
                             serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                             freeFlag2={this.state.freeFlag2}
                             freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                             recpmtid={this.state.recpmtid}
                             accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                             customerName={this.state.customer_name}
                             solId={this.state.solId}
                             initBranchName={this.state.initBranchName}
                             subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MAKER') !== -1) {
            return (
                <MakerInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal}
                                closeIcon={this.closeModal}
                                appUid={this.state.appUid} CaseId={this.state.CaseId}
                                subCategory={this.state.subCategory} category={this.state.category}
                                solId={this.state.solId}
                                serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                                freeFlag2={this.state.freeFlag2}
                                freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                                recpmtid={this.state.recpmtid}
                                accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                                customerName={this.state.customer_name}
                                initBranchName={this.state.initBranchName}
                                subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MAKER') !== -1) {
            return (
                <MakerInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal}
                                closeIcon={this.closeModal}
                                appUid={this.state.appUid} CaseId={this.state.CaseId}
                                subCategory={this.state.subCategory} category={this.state.category}
                                serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                                freeFlag2={this.state.freeFlag2}
                                freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                                recpmtid={this.state.recpmtid}
                                accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                                customerName={this.state.customer_name}
                                solId={this.state.solId}
                                initBranchName={this.state.initBranchName}
                                subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('CHECKER') !== -1) {
            return (
                <CheckerInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal}
                                  closeIcon={this.closeModal}
                                  appUid={this.state.appUid} CaseId={this.state.CaseId}
                                  subCategory={this.state.subCategory} category={this.state.category}
                                  serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                                  freeFlag2={this.state.freeFlag2}
                                  freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                                  recpmtid={this.state.recpmtid}
                                  accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                                  customerName={this.state.customer_name}
                                  solId={this.state.solId}
                                  initBranchName={this.state.initBranchName}
                                  subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MERCHANT') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MERCHANT_DST') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MERCHANT_CALL_CENTER') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MBU') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('HOMBU') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MBU_DFF') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MOU_MAKER') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MOU_CHECKER') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('FRM') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('FRM_CHECKER') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('FRM_APPROVAL') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('S_AND_A') !== -1) {
            return (
                <MerchantInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal}
                                   closeIcon={this.closeModal}
                                   appId={this.state.appUid}
                                   appUid={this.state.appUid} CaseId={this.state.CaseId}
                                   subCategory={this.state.subCategory} category={this.state.category}
                                   serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                                   freeFlag2={this.state.freeFlag2}
                                   freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                                   recpmtid={this.state.recpmtid}
                                   accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                                   customerName={this.state.customer_name}
                                   solId={this.state.solId}
                                   initBranchName={this.state.initBranchName}
                                   subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
            )
        }

    };

    onRowClick = (event, rowData) => {
        event.preventDefault();
        if ((LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MAKER" ||   LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CALL_CENTER_CHECKER" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CALL_CENTER_MAKER" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CHECKER") && (this.state.activeItem === "Group Inbox" || this.state.activeItem === "Account Opening Group Inbox" || this.state.activeItem === "Remittance Group Inbox" || this.state.activeItem === "Dormant Activation Group Inbox" || this.state.activeItem === 'Maintenance Group Inbox' || this.state.activeItem === "Remittance Group Inbox SD" || this.state.activeItem === "Return")) {

        }
        else if ((LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CS") && ( this.state.activeItem === "Return")) {

        } else if (this.state.activeItem === "DocumentUpload" || this.state.activeItem === "Group Inbox" || this.state.activeItem === "RECTIFIED REQUEST" || this.state.activeItem === "Account Opening Group Inbox" || this.state.activeItem === "Account Opening Rectified Request" || this.state.activeItem === "Remittance Group Inbox" || this.state.activeItem === "Dormant Activation Group Inbox" || this.state.activeItem === "Dormant Activation Rectified Request" || this.state.activeItem === 'Maintenance Group Inbox' || this.state.activeItem === "Maintenance Rectified Request" || this.state.activeItem === "Remittance Group Inbox SD" || this.state.activeItem === "Remittance Rectified Request" || this.state.activeItem === "Return" || this.state.activeItem === "DocumentUpload") {
            this.setState({
                loading: true
            });
            let url = backEndServerURL + "/case/claimReturn/" + rowData.appId;
            axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setInboxProps({...rowData, appId: response.data})
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                    })
                })
        } else {
            this.setInboxProps(rowData)
        }
    };

    setInboxProps = (rowData) => {
        console.log(rowData);
        this.setState({
            initBranchName: rowData.branch_name,
            CaseId: rowData.CaseId,
            serviceType: rowData.serviceType,
            subServiceType: rowData.subServiceType,
            category: rowData.category_type,
            subCategory: rowData.sub_category_type,
            solId: rowData.solId,
            appUid: rowData.appId,
            delStatus: rowData.Status,
            taskTitle: rowData.taskTitle,
            status: rowData.requestStatus,
            freeFlag1: rowData.freeFlag1,
            freeFlag2: rowData.freeFlag2,
            freeFlag3: rowData.freeFlag3,
            freeFlag4: rowData.freeFlag4,
            recpmtid: rowData.recpmtid,
            cb_number: rowData.cb_number,
            account_number: rowData.account_number,
            customer_name: rowData.customer_name,
            urgency: rowData.urgency,
            sentByUsername: rowData.returnedBy,
            jointAccountCustomerNumber: rowData.jointAccountCustomerNumber,
            showInbox: true,
            loading: false
        });
    };

    functionForCaseLockOrUnlock = (event, data) => {
        event.preventDefault();
        let selectedAppId = [];
        for (let item of data) {
            selectedAppId.push(item.appId)
        }
        this.setState({
            loading: true,
            getData: false,
            showTable: false
        });
        let single_url = "";
        if (this.state.activeItem === "Return" || this.state.activeItem === "DocumentUpload" || this.state.activeItem === "Group Inbox" || this.state.activeItem === "Remittance Group Inbox" || this.state.activeItem === "Remittance Group Inbox SD" || this.state.activeItem === "Dormant Activation Group Inbox" || this.state.activeItem === "Account Opening Group Inbox" || this.state.activeItem === "Maintenance Group Inbox") {
            single_url = backEndServerURL + "/case/claim/";
        } else {
            single_url = backEndServerURL + "/case/unClaim/";
        }

        axios.post(single_url, selectedAppId, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.functionForGetTabWiseUrl(this.state.activeItem, this.state.page);
            }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false,
                getData: true,
                showTable: true
            })
        })
    };

    renderTopLabelButton = () => {
        if ((LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MAKER" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CHECKER" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CALL_CENTER_CHECKER" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CALL_CENTER_MAKER") && (this.state.activeItem === "Group Inbox" || this.state.activeItem === "Account Opening Group Inbox" || this.state.activeItem === "Remittance Group Inbox" || this.state.activeItem === "Dormant Activation Group Inbox" || this.state.activeItem === 'Maintenance Group Inbox' || this.state.activeItem === "Remittance Group Inbox SD" || this.state.activeItem === "Return")) {
            return [{
                name: "LOCK",
                style: {...commonInboxButton},
                triggerFunction: this.functionForCaseLockOrUnlock
            }]
        } else if (this.state.activeItem === "Group Inbox" || this.state.activeItem === "Remittance Group Inbox" || this.state.activeItem === "Dormant Activation Group Inbox" || this.state.activeItem === "Maintenance Group Inbox" || this.state.activeItem === "Account Opening Group Inbox" || this.state.activeItem === "Return") {
            return [{
                name: "LOCK",
                style: {...commonInboxButton},
                triggerFunction: this.functionForCaseLockOrUnlock
            }]
        } else if (this.state.activeItem === "DocumentUpload" || this.state.activeItem === "Group Inbox" || this.state.activeItem === "Remittance Group Inbox" || this.state.activeItem === "Dormant Activation Group Inbox" || this.state.activeItem === "Maintenance Group Inbox" || this.state.activeItem === "Account Opening Group Inbox" || this.state.activeItem === "Return") {
            return [{
                name: "LOCK",
                style: {...commonInboxButton},
                triggerFunction: this.functionForCaseLockOrUnlock
            }]
        } else if ((this.state.activeItem === "Inbox" && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") !== "MAKER") || (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") !== "MAKER" && this.state.activeItem === "Return")) {
            return [{
                name: "UNLOCK",
                style: {...commonInboxButton},
                triggerFunction: this.functionForCaseLockOrUnlock
            }]
        }
    };

    renderTable = () => {
        return (
            (this.state.getData && this.state.showTable) &&
            <TableComponent tableData={this.state.tableData} tableColumns={this.renderTableColumn()}
                            onRowClick={this.onRowClick} loader={this.state.loading} maxBodyHeight="450px"
                            totalRow={this.state.totalRow} page={this.state.page} whiteSpace={"wrap"}
selection={(this.state.activeItem === "DocumentUpload" || this.state.activeItem === "Group Inbox" || this.state.activeItem === "Inbox" || this.state.activeItem === "Return" || this.state.activeItem === "Remittance Group Inbox" || this.state.activeItem === "Remittance Group Inbox SD" || this.state.activeItem === "Dormant Activation Group Inbox" || this.state.activeItem === "Maintenance Group Inbox" || this.state.activeItem === "Account Opening Group Inbox")}
                            handleChangePage={this.handleChangePage} rowsPerPage={this.state.rowsPerPage}
                            tableButton={this.renderTopLabelButton()}
            />
        )
    };

    closeModal = () => {
        this.setState({
            showInbox: false,
            getData: false,
            showTable: false,
            loading: true,
        }, this.functionForGetTabWiseUrl(this.state.activeItem, this.state.page))
    };

    renderInboxComponent = () => {
        if (this.state.activeItem === "Submit" || this.state.activeItem === "SD PENDING CASE" || this.state.activeItem === "Complete") {
            return this.renderInboxCaseSubmitted()
        } else {
            return this.renderInboxCase()
        }
    };

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderCloseIcon()}
                </CardHeader>
                <CardBody>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loading} alt=""/>
                                
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        fullScreen={true}
                        open={this.state.showInbox}>
                        <DialogContent className={classes.dialogPaper}>
                            {this.renderInboxComponent()}
                        </DialogContent>
                    </Dialog>
                    <ThemeProvider theme={theme}>
                         {this.renderFilterField()}  
                        <Grid item xs={12}><br/></Grid>  
                         {this.renderMenu()}  
                        {this.renderTable()}
                        {this.renderNotification()}
                    </ThemeProvider>
                </CardBody>
            </Card>
        );
    }

}

export default withStyles(styles)(CommonInbox);
