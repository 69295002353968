function makeReadOnlyObject(object) {
    if (object.hide !== true) {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            returnObjectVariables[i]["readOnly"] = true;
        }
        return returnObject;
    }

}

function makeConditionalGridObject(object) {
    if (object.hide !== true) {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            if (returnObjectVariables[i]["grid"] === 4) {
                returnObjectVariables[i]["grid"] = 2;
            }

        }
        return returnObject;
    }

}


function makeGridObject(object) {
    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject;

    for (let i = 0; i < returnObjectVariables.length; i++) {
        if (returnObjectVariables[i]["type"] === "title") {
            returnObjectVariables[i]["grid"] = 12;

        } else {
            returnObjectVariables[i]["grid"] = 6;

        }
    }
    return returnObject;


}

function makeGridObjectReadOnlyFalse(object) {
    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject;

    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["readOnly"] = false;
    }
    return returnObject;


}

//#####Secured Loan Closing#############Asset Operation
const securedLoanClosingJsonForm = [

    {
        varName: "customerCB",
        type: "textApiCall",
        label: "Customer CB",
        required: true,
        grid: 2,
    },
    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
        grid: 2,
    },
    {
        varName: "loanAccounts",
        type: "select",
        label: "Loan Accounts",
        grid: 3,
        "enum": [],
        "onKeyDown": true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "loanCategory",
        "type": "text",
        "required": true,
        "label": "Loan Category",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "loanCategory",
        "type": "textApiCall",
        "required": true,
        "label": "Loan Category",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "odAccountInquiryCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "productName",
        "type": "textApiCall",
        "required": true,
        "label": "Product Name",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },

    {
        "varName": "casaAccount",
        "type": "textApiCall",
        "required": true,
        "label": "Casa Account",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        varName: "casaAccountStatus",
        type: "text",
        label: "Casa Account Status",
        grid: 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "showCasaDetails",
        "conditionalVarValue": "YES",
    },
    {
        varName: "loanAccountBalance",
        type: "text",
        label: "Pay Off Amt",
        grid: 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "dailyInterest",
        "type": "text",
        "label": "Daily Interest",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        varName: "loanAccountExpiryDate",
        type: "date",
        label: "Loan Account Expiry Date",
        grid: 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",

    },
    {
        varName: "casaBalance",
        type: "text",
        label: "Casa Balance",
        grid: 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "showCasaDetails",
        "conditionalVarValue": "YES",
    },
    {
        varName: "rateOfEsf",
        type: "select",
        enum: [
            "0", ".5", "1", "2", "3", "4"
        ],
        label: "Rate of ESF",
        grid: 2,
        "required": true,
        "onKeyDown": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "earlySettlementFee",
        "type": "text",
        "label": "Early Settlement Fee",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "rateOfEsfApiCall",
        "conditionalVarValue": "YES",
    },
    {
        varName: "vat",
        type: "text",
        label: "VAT",
        grid: 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "rateOfEsfApiCall",
        "conditionalVarValue": "YES",
    },
    {
        varName: "totalAmount",
        type: "text",
        label: "Total Payoff Balance",
        grid: 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "rateOfEsfApiCall",
        "conditionalVarValue": "YES",
    },
    {
        varName: "exciseDuty",
        type: "text",
        label: "Excise Duty",
        grid: 2,
        readOnly: true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "loanAccountStatus",
        "type": "text",
        "label": "Loan Account Status",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "solId",
        "type": "text",
        "label": "Sol Id",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "branchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "classificationLoanStatus",
        "type": "text",
        "label": "Classification Loan Status",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },


    {
        "varName": "productName",
        "type": "text",
        "label": "Product Name",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "odAccountInquiryCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "accountStatus",
        "type": "text",
        "label": "Account Status",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "odAccountInquiryCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "sanctionLimit",
        "type": "text",
        "label": "Loan Sanction Amount",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "odAccountInquiryCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "loanOutstanding",
        "type": "text",
        "label": "Loan Oustanding",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "odAccountInquiryCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "unappliedInterest",
        "type": "text",
        "label": "Unapplied Interest",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "odAccountInquiryCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "limitExpiry",
        "type": "text",
        "label": "Limit Expiry",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "odAccountInquiryCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "sol",
        "type": "text",
        "label": "SOL",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "odAccountInquiryCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "branchName",
        "type": "text",
        "label": "Sourcing Branch",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "odAccountInquiryCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "classificationLoanStatus",
        "type": "text",
        "label": "Classification Status",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "odAccountInquiryCall",
        "conditionalVarValue": "YES",
    },

    {
        varName: "closedBy",
        type: "select",
        label: "Closed By",
        grid: 2,
        required: true,
        "enum": [
            "FD Encashment",
            "Cash",
        ],

    },


    {
        type: "empty",
        grid: 12,
    },

];
const CSsecuredLoanClosingJsonForm = securedLoanClosingJsonForm.concat([
    {
        "varName": "loanClosingApplication",
        "type": "file",
        "label": "Loan Closing Application",
        "grid": 3
    },
    {
        type: "empty",
        grid: 12,
    },

]);

const BOMsecuredLoanClosingJsonForm = makeConditionalGridObject(JSON.parse(JSON.stringify([

    {
        varName: "customerCB",
        type: "text",
        label: "Customer CB",
        required: true,
        readOnly:true,
        grid: 2,
    },

    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        grid: 2,
    },
    {
        varName: "loanAccounts",
        type: "text",
        label: "Loan Accounts",
        readOnly:true,
        grid: 2,

    },
    {
        "varName": "loanCategory",
        "type": "text",
        "label": "Loan Category",
        "grid": 2,
        "readOnly": true,
    },

    {
        "varName": "productName",
        "type": "text",
        "label": "Product Name",
        "grid": 2,
        "readOnly": true,

    },
    {
        "varName": "accountStatus",
        "type": "text",
        "label": "Account Status",
        "grid": 2,
        "readOnly": true,
    },
    {
        "varName": "sanctionLimit",
        "type": "text",
        "label": "Loan Sanction Amount",
        "grid": 2,
        "readOnly": true,
    },
    {
        "varName": "loanOutstanding",
        "type": "text",
        "label": "Loan Oustanding",
        "grid": 2,
        "readOnly": true,
    },
    {
        "varName": "unappliedInterest",
        "type": "text",
        "label": "Unapplied Interest",
        "grid": 2,
        "readOnly": true,
    },
    {
        "varName": "limitExpiry",
        "type": "text",
        "label": "Limit Expiry",
        "grid": 2,
        "readOnly": true,
    },
    {
        "varName": "sol",
        "type": "text",
        "label": "SOL",
        "grid": 2,
        "readOnly": true,
    },
    {
        "varName": "branchName",
        "type": "text",
        "label": "Sourcing Branch",
        "grid": 2,
        "readOnly": true,
    },
    {
        "varName": "classificationLoanStatus",
        "type": "text",
        "label": "Classification Status",
        "grid": 2,
        "readOnly": true,
    },
    {
        varName: "closedBy",
        type: "select",
        label: "Closed By",
        readOnly:true,
        grid: 2,

    },
    {
        type: "empty",
        grid: 12,
    },



])));

const BMsecuredLoanClosingJsonForm = makeReadOnlyObject(makeConditionalGridObject(JSON.parse(JSON.stringify(
    [...securedLoanClosingJsonForm]
))));

let csReadOnlyForm = makeReadOnlyObject(JSON.parse(JSON.stringify(securedLoanClosingJsonForm)));
const closingMakerMakersecuredLoanClosingJsonForm = makeConditionalGridObject(JSON.parse(JSON.stringify(csReadOnlyForm.concat([
    // {
    //     varName: "loanCategory",
    //     type: "select",
    //     label: "Loan Category",
    //     grid: 2,
    //     "required": true,
    //     "enum": [
    //         "OD",
    //         "EMI",
    //     ],
    //     "onKeyDown": true,
    //     "conditional": true,
    //     "conditionalVarName": "customerCBApiCall",
    //     "conditionalVarValue": "YES",
    // },
    // {
    //     varName: "closureStatus",
    //     type: "select",
    //     label: "Closure Status",
    //     grid: 2,
    //     "required": true,
    //     "enum": [
    //         "Default",
    //         "Upload for Encashment",
    //         "Encashment Hold",
    //         "Closure Request Cancel",
    //     ],
    //     "conditional": true,
    //     "conditionalVarName": "customerCBApiCall",
    //     "conditionalVarValue": "YES",
    // },

    {
        type: "empty",
        grid: 12,
    },
]))));


const MakersecuredLoanClosingJsonForm = makeConditionalGridObject(JSON.parse(JSON.stringify([

    {
        varName: "customerCB",
        type: "text",
        label: "Customer CB",
        required: true,
        readOnly: true,
        grid: 4,
    },
    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",

        grid: 2,
    },

    // {
    //     varName: "serviceType",
    //     type: "select",
    //     label: "Service Type",
    //     required: true,
    //     readOnly:true,
    //     grid: 4,
    //     "enum": [
    //         "A",
    //         "B",
    //     ]
    // },


    {
        varName: "loanAccounts",
        type: "text",
        label: "Loan Accounts",
        readOnly: true,
        grid: 4,

    },


    {
        varName: "loanType",
        type: "test",
        label: "Loan Type",
        required: true,
        readOnly: true,
        grid: 4,
    },

    {
        varName: "closedBy",
        type: "select",
        label: "Closed By",
        required: true,
        // readOnly:true,
        grid: 4,
        "enum": [
            "FD Encashment",
            "Cash",
        ]
    },


    {
        "varName": "casaAccount",
        "type": "text",
        "label": "Casa Account",
        readOnly: true,
        "grid": 4
    },


    {
        varName: "casaAccountStatus",
        type: "text",
        label: "Casa Account Status",
        readOnly: true,
        grid: 4,
    },


    {
        varName: "loanAccountBalance",
        type: "text",
        label: "Loan Account Balance",
        readOnly: true,
        grid: 4,

    },


    {
        "varName": "dailyInterest",
        "type": "text",
        "label": "Daily Interest",
        readOnly: true,
        "grid": 4
    },


    {
        varName: "loanAccountExpiryDate",
        type: "date",
        label: "Loan Account Expiry Date",
        readOnly: true,
        grid: 4,

    },


    {
        varName: "casaBalance",
        type: "text",
        label: "Casa Balance",
        readOnly: true,
        grid: 4,
    },


    {
        "varName": "earlySettlementFee",
        "type": "text",
        "label": "Early Settlement Fee",
        readOnly: true,
        "grid": 4
    },


    {
        varName: "vat",
        type: "text",
        label: "VAT(15% of ESF)",
        readOnly: true,
        grid: 4,

    },


    {
        varName: "exciseDuty",
        type: "text",
        label: "Excise Duty",
        readOnly: true,
        grid: 4,
    },


    {
        "varName": "waiver",
        "type": "text",
        "label": "Waiver",
        readOnly: true,
        "grid": 4
    },


    //Loan Category

    {
        "varName": "loanCategory",
        "type": "select",
        "required": true,
        "label": "Loan Category",
        "grid": 4,
        "enum": [
            "OD",
            "EMI",
        ]
    },

    {
        type: "empty",
        grid: 12,
    },


])));
const nocMakersecuredLoanClosingJsonForm = makeConditionalGridObject(JSON.parse(JSON.stringify([

    {
        varName: "customerCB",
        type: "text",
        label: "Customer CB",
        required: true,
        readOnly: true,
        grid: 4,
    },
    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",

        grid: 2,
    },

    // {
    //     varName: "serviceType",
    //     type: "select",
    //     label: "Service Type",
    //     required: true,
    //     readOnly:true,
    //     grid: 4,
    //     "enum": [
    //         "A",
    //         "B",
    //     ]
    // },


    {
        varName: "loanAccounts",
        type: "text",
        label: "Loan Account",
        readOnly: true,
        grid: 4,

    },


    {
        varName: "loanType",
        type: "test",
        label: "Loan Type",
        required: true,
        readOnly: true,
        grid: 4,
    },

    {
        varName: "closedBy",
        type: "select",
        label: "Closed By",
        required: true,
        readOnly: true,
        grid: 4,
        "enum": [
            "FD Encashment",
            "Cash",
        ]
    },


    {
        "varName": "casaAccount",
        "type": "text",
        "label": "Casa Account",
        readOnly: true,
        "grid": 4
    },


    {
        varName: "casaAccountStatus",
        type: "text",
        label: "Casa Account Status",
        readOnly: true,
        grid: 4,
    },


    {
        varName: "loanAccountBalance",
        type: "text",
        label: "Loan Account Balance",
        readOnly: true,
        grid: 4,

    },


    {
        "varName": "dailyInterest",
        "type": "text",
        "label": "Daily Interest",
        readOnly: true,
        "grid": 4
    },


    {
        varName: "loanAccountExpiryDate",
        type: "date",
        label: "Loan Account Expiry Date",
        readOnly: true,
        grid: 4,

    },


    {
        varName: "casaBalance",
        type: "text",
        label: "Casa Balance",
        readOnly: true,
        grid: 4,
    },


    {
        "varName": "earlySettlementFee",
        "type": "text",
        "label": "Early Settlement Fee",
        readOnly: true,
        "grid": 4
    },


    {
        varName: "vat",
        type: "text",
        label: "VAT(15% of ESF)",
        readOnly: true,
        grid: 4,

    },


    {
        varName: "exciseDuty",
        type: "text",
        label: "Excise Duty",
        readOnly: true,
        grid: 4,
    },


    {
        "varName": "waiver",
        "type": "text",
        "label": "Waiver",
        readOnly: true,
        "grid": 4
    },
    {
        varName: "nocCheckerId",
        type: "text",
        label: "NOC Checker ID",
        required: true,
        onKeyDown: true,
        lowerCase: true,
        grid: 4,
    },
    {
        "varName": "noOfTypeOfSecurity",
        "type": "select",
        onKeyDown: true,
        enum: ["1","2","3","4","5","6","7"],
        "label": "Number Of Security",
        "grid": 4
    },
    {
        type: "empty",
        grid: 12,
    },


])));
const CheckersecuredLoanClosingJsonForm = makeReadOnlyObject(closingMakerMakersecuredLoanClosingJsonForm);
//##### Loan Closing#############Asset Operation
const CSLoanClosingJsonForm = [
    {
        varName: "customerCB",
        type: "textApiCall",
        label: "Customer CB",
        required: true,
        grid: 2,
    },
    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
        grid: 2,
    },
    {
        varName: "loanAccounts",
        type: "select",
        label: "Loan Accounts",
        grid: 2,
        "enum": [],
        "onKeyDown": true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        varName: "loanType",
        type: "select",
        label: "Loan Type",
        "enum": [
            "Home Loan",
            "Auto Loan", "Personal Loan", "SME Loan", "Secured Loan"
        ],
        grid: 2,
    },
    {
        "varName": "casaAccount",
        "type": "text",
        "label": "Casa Account",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        varName: "casaAccountStatus",
        type: "text",
        label: "Casa Account Status",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
        grid: 2,
    },
    {
        varName: "loanAccountBalance",
        type: "text",
        label: "Loan Account Balance",
        grid: 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "dailyInterest",
        "type": "text",
        "label": "Daily Interest",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        varName: "loanAccountExpiryDate",
        type: "date",
        label: "Loan Account Expiry Date",
        grid: 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",

    },
    {
        varName: "casaBalance",
        type: "text",
        label: "Casa Balance",
        grid: 2,
    },
    {
        varName: "rateOfEsf",
        type: "select",
        enum: [
            "1", "2", "3", "4"
        ],
        label: "Rate of ESF",
        grid: 2,
        "onKeyDown": true,
    },
    {
        "varName": "earlySettlementFee",
        "type": "text",
        "label": "Early Settlement Fee",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "showEarlySettlement",
        "conditionalVarValue": true,
    },
    {
        varName: "vat",
        type: "text",
        label: "VAT",
        grid: 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "showVat",
        "conditionalVarValue": true,
    },
    {
        varName: "exciseDuty",
        type: "text",
        label: "Excise Duty",
        grid: 2,
        readOnly: true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "waiver",
        "type": "text",
        "label": "Waiver",
        "grid": 2
    },
    {
        "varName": "loanAccountStatus",
        "type": "text",
        "label": "Loan Account Status",
        "grid": 2,
        readOnly: true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES"

    },
    {
        type: "empty",
        grid: 12,
    },
    {
        "varName": "loanClosingApplication",
        "type": "file",
        "label": "Loan Closing Application",
        "grid": 3
    },
    {
        type: "empty",
        grid: 12,
    },

];
const BOMLoanClosingJsonForm = makeConditionalGridObject(JSON.parse(JSON.stringify([

    {
        varName: "customerCB",
        type: "text",
        label: "Customer CB",
        required: true,
        readOnly: true,
        grid: 4,
    },
    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",

        grid: 2,
    },

    // {
    //     varName: "serviceType",
    //     type: "select",
    //     label: "Service Type",
    //     required: true,
    //     readOnly:true,
    //     grid: 4,
    //     "enum": [
    //         "A",
    //         "B",
    //     ]
    // },


    {
        varName: "loanAccounts",
        type: "text",
        label: "Loan Accounts",
        readOnly: true,
        grid: 4,

    },


    {
        varName: "loanType",
        type: "test",
        label: "Loan Type",
        required: true,
        readOnly: true,
        grid: 4,
    },

    {
        varName: "closedBy",
        type: "select",
        label: "Closed By",
        required: true,
        readOnly: true,
        grid: 4,
        "enum": [
            "FD Encashment",
            "Cash",
        ]
    },


    {
        "varName": "casaAccount",
        "type": "text",
        "label": "Casa Account",
        readOnly: true,
        "grid": 4
    },


    {
        varName: "casaAccountStatus",
        type: "text",
        label: "Casa Account Status",
        readOnly: true,
        grid: 4,
    },


    {
        varName: "loanAccountBalance",
        type: "text",
        label: "Loan Account Balance",
        readOnly: true,
        grid: 4,

    },


    {
        "varName": "dailyInterest",
        "type": "text",
        "label": "Daily Interest",
        readOnly: true,
        "grid": 4
    },


    {
        varName: "loanAccountExpiryDate",
        type: "date",
        label: "Loan Account Expiry Date",
        readOnly: true,
        grid: 4,

    },


    {
        varName: "casaBalance",
        type: "text",
        label: "Casa Balance",
        readOnly: true,
        grid: 4,
    },


    {
        "varName": "earlySettlementFee",
        "type": "text",
        "label": "Early Settlement Fee",
        readOnly: true,
        "grid": 4
    },


    {
        varName: "vat",
        type: "text",
        label: "VAT(15% of ESF)",
        readOnly: true,
        grid: 4,

    },


    {
        varName: "exciseDuty",
        type: "text",
        label: "Excise Duty",
        readOnly: true,
        grid: 4,
    },


    {
        "varName": "waiver",
        "type": "text",
        "label": "Waiver",
        readOnly: true,
        "grid": 4
    },

    {
        type: "empty",
        grid: 12,
    },


])));
;
const BMClosingJsonForm = makeConditionalGridObject(JSON.parse(JSON.stringify([

    {
        varName: "customerCB",
        type: "text",
        label: "Customer CB",
        required: true,
        readOnly: true,
        grid: 4,
    },
    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",

        grid: 2,
    },

    // {
    //     varName: "serviceType",
    //     type: "select",
    //     label: "Service Type",
    //     required: true,
    //     readOnly:true,
    //     grid: 4,
    //     "enum": [
    //         "A",
    //         "B",
    //     ]
    // },


    {
        varName: "loanAccounts",
        type: "text",
        label: "Loan Accounts",
        readOnly: true,
        grid: 4,

    },


    {
        varName: "loanType",
        type: "text",
        label: "Loan Type",
        readOnly: true,
        grid: 4,
    },


    {
        "varName": "casaAccount",
        "type": "text",
        "label": "Casa Account",
        readOnly: true,
        "grid": 4
    },


    {
        varName: "casaAccountStatus",
        type: "text",
        label: "Casa Account Status",
        readOnly: true,
        grid: 4,
    },


    {
        varName: "loanAccountBalance",
        type: "text",
        label: "Loan Account Balance",
        readOnly: true,
        grid: 4,

    },


    {
        "varName": "dailyInterest",
        "type": "text",
        "label": "Daily Interest",
        readOnly: true,
        "grid": 4
    },


    {
        varName: "loanAccountExpiryDate",
        type: "date",
        label: "Loan Account Expiry Date",
        readOnly: true,
        grid: 4,

    },


    {
        varName: "casaBalance",
        type: "text",
        label: "Casa Balance",
        readOnly: true,
        grid: 4,
    },


    {
        "varName": "earlySettlementFee",
        "type": "text",
        "label": "Early Settlement Fee",
        readOnly: true,
        "grid": 4
    },


    {
        varName: "vat",
        type: "text",
        label: "VAT",
        readOnly: true,
        grid: 4,

    },


    {
        varName: "exciseDuty",
        type: "text",
        label: "Excise Duty",
        readOnly: true,
        grid: 4,
    },


    {
        "varName": "waiver",
        "type": "text",
        "label": "Waiver",
        readOnly: true,
        "grid": 4
    },

    {
        type: "empty",
        grid: 12,
    },


])));
;
const nocMakerLoanClosingJsonForm = makeConditionalGridObject(JSON.parse(JSON.stringify([

    {
        varName: "customerCB",
        type: "text",
        label: "Customer CB",
        required: true,
        readOnly: true,
        grid: 4,
    },
    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",

        grid: 2,
    },

    {
        varName: "loanAccounts",
        type: "text",
        label: "Loan Accounts",
        required: true,
        readOnly: true,
        grid: 4,
    },


    {
        varName: "loanType",
        type: "text",
        label: "Loan Type",
        required: true,
        readOnly: true,
        grid: 4,
    },


    {
        varName: "assetOperationNo",
        type: "text",
        label: "Asset Operation No",
        required: true,
        readOnly: true,
        grid: 4,
    },


    {
        varName: "loanAccountStatus",
        type: "text",
        label: "Loan account Status",
        readOnly: true,
        required: true,
        grid: 4,
    },


    // {
    //     varName: "loanAccountCloseDate",
    //     type: "date",
    //     label: "Loan account Close Date",
    //     required: true,
    //     grid: 4,
    // },

    {
        varName: "fileLocation",
        type: "select",
        label: "File Location",
        required: true,
        grid: 4,
        "enum": [
            "Archived",
            "Collection",
            "Asset Operation Users",
        ]
    },


    {
        varName: "dateOfTheDelivery",
        type: "date",
        label: "Date of the Delivery",
        required: true,
        grid: 4,
    },


    {
        varName: "fileDeliveryStatus",
        type: "select",
        label: "File Delivery Status",
        required: true,
        grid: 4,
        "enum": [
            "Under Process",
            "Ready to Deliver",
            "Delivered",
            "Acknowledgement",
            "Send Back to Archive",
        ]
    },


    {
        varName: "cblChequeNo",
        type: "text",
        label: "CBL Cheque No",
        required: true,
        readOnly: true,
        grid: 4,
    },

    {
        varName: "pdcAcNo",
        type: "text",
        label: "PDC A/C NO",
        required: true,
        readOnly: true,
        grid: 4,
    },

    {
        varName: "pdcNo",
        type: "text",
        label: "PDC NO",
        required: true,
        readOnly: true,
        grid: 4,
    },


    {
        varName: "brtaAuthority",
        type: "text",
        label: "BRTA Authority",
        required: true,
        readOnly: true,
        grid: 4,
    },

    {
        varName: "engineNo",
        type: "text",
        label: "Engine No",
        required: true,
        readOnly: true,
        grid: 4,
    },


    {
        varName: "chassisNo",
        type: "text",
        label: "Chassis No",
        required: true,
        readOnly: true,
        grid: 4,
    },


    {
        varName: "registrationNo",
        type: "text",
        label: "Registration No",
        required: true,
        readOnly: true,
        grid: 4,
    },

    {
        varName: "ownerName ",
        type: "text",
        label: "Owner Name ",
        required: true,
        readOnly: true,
        grid: 4,
    },

    {
        type: "empty",
        grid: 12,
    },


])));
;
const documentMakerLoanClosingJsonForm = makeConditionalGridObject(JSON.parse(JSON.stringify([

    {
        varName: "fileStatus",
        type: "select",
        label: "File Status",
        required: true,
        grid: 4,
        "enum": [
            "Under process",
            "Pending at Legal",
            "Received from Legal",
            "Completed but Yet to Deliver",
        ]
    },
    {
        type: "empty",
        grid: 12,
    },


    {
        varName: "documentReleaseCharge",
        type: "text",
        label: "Document Release -Charge",
        required: true,
        grid: 4,
    },
    {
        type: "empty",
        grid: 12,
    },


    {
        varName: "csmsTerminationStatus",
        type: "select",
        label: "CSMS Termination Status",
        required: true,
        grid: 4,
        "enum": [
            "Active",
            "Inactive",
        ]
    },
    {
        type: "empty",
        grid: 12,
    },


    {
        varName: "uploadSroToken",
        type: "file",
        label: "Upload SRO Token",
        required: true,
        grid: 4,
    },
    {
        type: "empty",
        grid: 12,
    },


])));
;
const CheckerLoanClosingJsonForm = makeConditionalGridObject(JSON.parse(JSON.stringify([

    {
        varName: "customerCB",
        type: "text",
        label: "Customer CB",
        required: true,
        readOnly: true,
        grid: 4,
    },
    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",

        grid: 2,
    },

    // {
    //     varName: "serviceType",
    //     type: "select",
    //     label: "Service Type",
    //     required: true,
    //     readOnly: true,
    //     grid: 4,
    //     "enum": [
    //         "A",
    //         "B",
    //     ]
    // },


    {
        varName: "loanAccounts",
        type: "text",
        label: "Loan Accounts",
        readOnly: true,
        grid: 4,

    },


    {
        varName: "loanType",
        type: "text",
        label: "Loan Type",
        readOnly: true,
        grid: 4,
    },


    {
        "varName": "casaAccount",
        "type": "text",
        "label": "Casa Account",
        readOnly: true,
        "grid": 4
    },


    {
        varName: "casaAccountStatus",
        type: "text",
        label: "Casa Account Status",
        readOnly: true,
        grid: 4,
    },


    {
        varName: "loanAccountBalance",
        type: "text",
        label: "Loan Account Balance",
        readOnly: true,
        grid: 4,

    },


    {
        "varName": "dailyInterest",
        "type": "text",
        "label": "Daily Interest",
        readOnly: true,
        "grid": 4
    },


    {
        varName: "loanAccountExpiryDate",
        type: "date",
        label: "Loan Account Expiry Date",
        readOnly: true,
        grid: 4,

    },


    {
        varName: "casaBalance",
        type: "text",
        label: "Casa Balance",
        readOnly: true,
        grid: 4,
    },


    {
        "varName": "earlySettlementFee",
        "type": "text",
        "label": "Early Settlement Fee",
        readOnly: true,
        "grid": 4
    },


    {
        varName: "vat",
        type: "text",
        label: "VAT",
        readOnly: true,
        grid: 4,

    },


    {
        varName: "exciseDuty",
        type: "text",
        label: "Excise Duty",
        readOnly: true,
        grid: 4,
    },


    {
        "varName": "waiver",
        "type": "text",
        "label": "Waiver",
        readOnly: true,
        "grid": 4
    },

    {
        type: "empty",
        grid: 12,
    },


])));
//Outstannding Certificate//
let CsOutstandingCertificateJsonFrom = [
    {
        varName: "customerCB",
        type: "textApiCall",
        label: "Customer CB",
        required: true,
        grid: 2,
    },
    {
        varName: "customerName",
        type: "text",
        readOnly: true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
        grid: 2,
    },
    {
        varName: "loanAccounts",
        type: "select",
        "required": true,
        "enum": [],
        "onKeyDown": true,
        label: "Loan Accounts",
        grid: 3,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "productName",
        "type": "textApiCall",
        "required": true,
        "label": "Product Name",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },

    {
        "varName": "casaAccount",
        "type": "textApiCall",
        "required": true,
        "label": "Casa Account",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        varName: "casaAccountStatus",
        type: "textApiCall",
        label: "Casa Account Status",
        grid: 2,
        required: true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "showCasaDetails",
        "conditionalVarValue": "YES",
    },
    // {
    //     varName: "presentAddress",
    //     type: "textApiCall",
    //     label: "Present Address",
    //     grid: 4,
    //     "readOnly": true,
    //     "conditional": true,
    //     "conditionalVarName": "showCasaDetails",
    //     "conditionalVarValue": "YES",
    // },

    {
        varName: "disbursementDate",
        type: "date",
        required: true,
        label: "Disbursement Date",
        grid: 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",

    },
    {
        "varName": "dailyInterest",
        "type": "textApiCall",
        "required": true,
        "label": "Daily Interest",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "sanctionedLimit",
        "type": "textApiCall",
        "required": true,
        "label": "Sanctioned Limit",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "tenor",
        "type": "textApiCall",
        "required": true,
        "label": "Tenor",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "monthlyInstallment",
        "type": "textApiCall",
        "required": true,
        "label": "Monthly Installment",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "tenorCompleted",
        "type": "textApiCall",
        "required": true,
        "label": "Tenor completed",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    // {
    //     "varName": "remainingTenor",
    //     "type": "textApiCall",
    //     "required": true,
    //     "label": "Remaining Tenor",
    //     "grid": 2,
    //     "readOnly": true,
    //     "conditional": true,
    //     "conditionalVarName": "loanAccountsCBApiCall",
    //     "conditionalVarValue": "YES",
    // },
    /*{
        "label": "Certificate Type",
        "type": "select",
        "varName": "certificateType",
        "onKeyDown": true,
        "grid": 2,
        "enum": [
            "General",
            "Tax Return Submission",
            "Take Over",
        ],
        "required": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Fiscal Year From",
        "type": "date",
        "varName": "fiscalYearStart",
        "onKeyDown": true,
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "certificateType",
        "conditionalVarValue": "Tax Return Submission",
    },
    {
        "label": "Fiscal Year To",
        "type": "date",
        "varName": "fiscalYearEnd",
        "onKeyDown": true,
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "certificateType",
        "conditionalVarValue": "Tax Return Submission",
    },

    {
        "varName": "principalPaid",
        "type": "textApiCall",
        "required": true,
        "label": "Principal Paid",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "taxReturnApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "interestPaid",
        "type": "textApiCall",
        "required": true,
        "label": "Interest Paid",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "taxReturnApiCall",
        "conditionalVarValue": "YES",
    },*/
    {
        "varName": "loanOutstanding",
        "type": "textApiCall",
        "required": true,
        "label": "Loan Outstanding",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "overDue",
        "type": "textApiCall",
        "required": true,
        "label": "Over Due",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },


    {
        "label": "Date",
        "type": "date",
        "varName": "date",
        "grid": 2,
        "required": true,
    },
    {
        "type": "empty",
        "grid": 12,
    },
];
let CsOutstandingCertificateJsonFromTaxReturn = [

    {
        varName: "customerCB",
        type: "textApiCall",
        label: "Customer CB",
        required: true,
        grid: 2,
    },
    {
        varName: "customerName",
        type: "text",
        readOnly: true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
        grid: 2,
    },
    {
        varName: "loanAccounts",
        type: "select",
        "required": true,
        "enum": [],
        "onKeyDown": true,
        label: "Loan Accounts",
        grid: 3,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Fiscal Year From",
        "type": "date",
        "varName": "fiscalYearStart",
        "onKeyDown": true,
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Fiscal Year To",
        "type": "date",
        "varName": "fiscalYearEnd",
        "onKeyDown": true,
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },

    // {
    //     varName: "presentAddress",
    //     type: "textApiCall",
    //     label: "Present Address",
    //     grid: 4,
    //     "readOnly": true,
    //     "conditional": true,
    //     "conditionalVarName": "taxReturnApiCall",
    //     "conditionalVarValue": "YES",
    // },

    {
        varName: "disbursementDate",
        type: "date",
        required: true,
        label: "Disbursement Date",
        grid: 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "taxReturnApiCall",
        "conditionalVarValue": "YES",

    },

    {
        "varName": "sanctionedLimit",
        "type": "textApiCall",
        "required": true,
        "label": "Sanctioned Limit",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "taxReturnApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "principalPaid",
        "type": "textApiCall",
        "required": true,
        "label": "Principal Paid",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "taxReturnApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "interestPaid",
        "type": "textApiCall",
        "required": true,
        "label": "Interest Paid",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "taxReturnApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "loanOutstanding",
        "type": "textApiCall",
        "required": true,
        "label": "Loan Outstanding",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "taxReturnApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Date",
        "type": "date",
        "varName": "date",
        "grid": 2,
        "required": true,
    },
    {
        "type": "empty",
        "grid": 12,
    },
];


let CsOutstandingCertificateJsonFromReadOnly = makeReadOnlyObject(JSON.parse(JSON.stringify(CsOutstandingCertificateJsonFrom)));
let CsOutstandingCertificateJsonFromTaxReturnReadOnly = makeReadOnlyObject(JSON.parse(JSON.stringify(CsOutstandingCertificateJsonFromTaxReturn)));

//Partial Payment
let CsPartialPaymentJsonFrom = [
    {
        varName: "customerCB",
        type: "textApiCall",
        label: "Customer CB",
        required: true,
        grid: 2,
    },
    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
        grid: 2,
    },
    {
        varName: "loanAccounts",
        type: "select",
        "enum": [],
        "onKeyDown": true,
        label: "Loan Accounts",
        grid: 2,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
    },

    {
        "label": "Loan Type",
        "type": "select",
        "varName": "loanType",
        "grid": 2,
        "enum": [
            "Auto Loan",
            "Personal Loan",
            "SME Loan",
            "Secured Loan"
        ],
    },
    {
        "varName": "loanOutstanding",
        "type": "textApiCall",
        "required": true,
        "label": "Loan Outstanding",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "emiCompleted",
        "type": "textApiCall",
        "required": true,
        "label": "EMI Completed",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },

    {
        "label": "Partial Payment Amount",
        "type": "textApiCall",
        validation: "numeric",
        "varName": "partialPaymentAmount",
        "grid": 2,
        "required": true,
    },
    {

        "label": "Rate of PF",
        type: "select",
        "onKeyDown": true,
        "varName": "rateOfPf",
        "grid": 2,
        "enum": [
            "5",
            "10"
        ],
        "required": true,
        "conditional": true,
        "conditionalVarName": "showRateOfPf",
        "conditionalVarValue": "YES",
    },

    {
        "varName": "fee",
        "type": "text",
        "label": "Fee",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "rateOfpfCBApiCall",
        "conditionalVarValue": "YES",
    },

    {
        "varName": "vat",
        "type": "text",
        "label": "Vat",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "rateOfpfCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "casaAccountStatus",
        "type": "text",
        "label": "Casa Account Status",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },

    {
        "label": "Fund Availability",
        "type": "text",
        "varName": "fundAvailability",
        "grid": 2,
        "readOnly": true,
        "required": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },

    {
        "label": "Mention Changes",
        "type": "select",
        "varName": "mentionChanges",
        "grid": 2,
        "enum": [
            "Reduce EMI Size",
            "Reduce Tenor"
        ],
        "required": true,
    },
    {
        "type": "empty",
        "grid": 12,
    },

    // {
    //     "varName": "tableDataView",
    //     "type": "submit",
    //     "event": false,
    //     "label": "Table Data View",
    //     "grid": 6,
    //
    // },

];
let CsPartialPaymentJsonFromReadOnly = makeReadOnlyObject(JSON.parse(JSON.stringify(CsPartialPaymentJsonFrom)));

// const MakersecuredPartialPaymentJsonFrom =makeConditionalGridObject(JSON.parse(JSON.stringify(  [

//     {
//         varName: "customerCB",
//         type: "text",
//         label: "Customer CB",
//         required: true,
//         readOnly: true,
//         grid: 4,
//     },
//     {
//         varName: "customerName",
//         type: "text",
//         "readOnly": true,
//         label: "Customer Name",
//         required: true,
//         "conditional": true,
//         "conditionalVarName": "customerCBApiCall",
//         "conditionalVarValue": "YES",

//         grid: 2,
//     },

//     // {
//     //     varName: "serviceType",
//     //     type: "select",
//     //     label: "Service Type",
//     //     required: true,
//     //     readOnly:true,
//     //     grid: 4,
//     //     "enum": [
//     //         "A",
//     //         "B",
//     //     ]
//     // },


//     {
//         varName: "loanAccounts",
//         type: "text",
//         label: "Loan Accounts",
//         readOnly: true,
//         grid: 4,

//     },


//     {
//         varName: "loanAccountBalance",
//         type: "text",
//         label: "Loan Account Balance",
//         grid: 2,
//         "readOnly":true,
//         "conditional": true,
//         "conditionalVarName": "loanAccountsCBApiCall",
//         "conditionalVarValue": "YES",
//     },


//     {
//         "varName": "dailyInterest",
//         "type": "text",
//         "label": "Daily Interest",
//         "grid": 2,
//         "readOnly":true,
//         "conditional": true,
//         "conditionalVarName": "loanAccountsCBApiCall",
//         "conditionalVarValue": "YES",
//     },


//     {
//         varName: "loanAccountExpiryDate",
//         type: "date",
//         label: "Loan Account Expiry Date",
//         grid: 2,
//         "readOnly":true,
//         "conditional": true,
//         "conditionalVarName": "loanAccountsCBApiCall",
//         "conditionalVarValue": "YES",

//     },

//     {
//         label: "Loan Accounts SOL",
//         type: "text",
//         varName: "loanAccountsSol",
//         required: true,
//         readOnly: true,
//         grid: 4,
//     },
//     {
//         label: "Case Accounts SOL",
//         type: "text",
//         varName: "caseAccountsSol",
//         required: true,
//         readOnly: true,
//         grid: 4,
//     },

//     {
//         varName: "loanType",
//         type: "test",
//         label: "Loan Type",
//         required: true,
//         readOnly: true,
//         grid: 4,
//     },

//     {
//         label: "Loan OutStanding",
//         type: "text",
//         varName: "loanOutStanding",
//         readOnly: true,
//         grid: 4,
//     },

//     {
//         label: "EMI Completed",
//         type: "text",
//         varName: "emiCompleted",
//         grid: 4,
//         required: true,
//     },

//     {
//         label: "Partial Payment Amount",
//         type: "text",
//         varName: "partialPaymentAmount",
//         grid: 2,
//         required: true,
//     },

//     {

//         "label": "Rate of PF(Partial Payment)",
//         type: "select",
//         "onKeyDown": true,
//         "varName": "rateOfpf",
//         "grid": 2,
//         "enum": [
//             "5%",
//             "10%"
//         ],
//         "required": true,
//     },

//     {  "varName": "fee",
//         "type": "text",
//         "label": "Fee",
//         "grid": 2,
//         "readOnly": true,
//         "conditional": true,
//         "conditionalVarName": "rateOfpfCBApiCall",
//         "conditionalVarValue": "YES",
//     },

//     {    "varName": "vat",
//         "type": "text",
//         "label": "Vat",
//         "grid": 2,
//         "readOnly": true,
//         "conditional": true,
//         "conditionalVarName": "rateOfpfCBApiCall",
//         "conditionalVarValue": "YES",
//     },
//     {
//         "label": "Fund Availability",
//         "type": "text",
//         "varName": "fundAvailability",
//         "grid": 2,
//         "required": true,
//     },
//     {
//         "label": "Link Account Status",
//         "type": "text",
//         "varName": "linkAccountStatus",
//         "grid": 2,
//         "required": true,
//     },
//     {
//         "label": "Mention Changes",
//         "type": "select",
//         "varName": "mentionChanges",
//         "grid": 2,
//         "enum": [
//             "Reduce EMI Size",
//             "Reduce Tenor"
//         ],
//         "required": true,
//     },
//     {
//         "type": "empty",
//         "grid": 12,
//     },

//     {
//         "varName": "tableDataView",
//         "type": "submit",
//         "event": false,
//         "label": "Table Data View",
//         "grid": 6,

//     },

// ])));

//Emi Data Change
let EmiDataChangeCsoForm = [
    {
        varName: "customerCB",
        type: "textApiCall",
        label: "Customer CB",
        required: true,
        grid: 2,
    },
    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",

        grid: 2,
    },
    // {
    //     "label": "Service Type",
    //     "type": "select",
    //     "varName": "serviceType",
    //     "enum": [
    //         "service one",
    //         "service two"
    //     ],
    //     "grid": 2,
    //     "required": true
    // },
    {
        varName: "loanAccounts",
        type: "select",
        "enum": [],
        "onKeyDown": true,
        label: "Loan Accounts",
        grid: 2,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
    },


    {
        "label": "Loan Accounts SOL",
        "type": "text",
        "varName": "loanAccountsSol",
        "grid": 2,
    },
    {
        "label": "Case Accounts SOL",
        "type": "text",
        "varName": "caseAccountsSol",
        "grid": 2,
    },
    {
        "label": "Existing EMI Date",
        "type": "date",
        "varName": "existingEmiDate",
        "grid": 2,
    },
    {
        "label": "Expected EMI Date",
        "type": "select",
        "varName": "expectedEMIDate",
        "enum": [
            "1",
            "8",
            "16",
            "22",
            "25"
        ],
        "grid": 2,
        // "readOnly": true
    },
    {
        "label": "Loan Type",
        "type": "select",
        "varName": "loanType",
        "enum": [
            "Auto Loan",
            "Personal Loan",
            "Loan Type"
        ],
        "grid": 2,
        // "readOnly": true
    },
    {
        "label": "Loan Outstanding",
        "type": "text",
        "varName": "loanOutstanding",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "EMI Completed",
        "type": "text",
        "varName": "emiCompleted",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Link Account Status",
        "type": "text",
        "varName": "linkAccountStatus",
        "grid": 2,
        "readOnly": true
    },
    // {
    //     "label": "Fund availability in link account",
    //     "type": "text",
    //     "varName": "fundAvailabilityInLinkAccount",
    //     "grid": 3,
    //     "readOnly": true
    // },
    {
        "label": "Expected date of New EMI",
        "type": "date",
        "varName": "expectedDateOfNewEmi",
        "grid": 2,
    },
];

let EmiDataChangeReadOnlyForm = makeReadOnlyObject(JSON.parse(JSON.stringify(EmiDataChangeCsoForm)));
//Loan Account Tenor Change
let LoanAccountChangeCsoForm = [
    {
        varName: "customerCB",
        type: "textApiCall",
        label: "Customer CB",
        required: true,
        grid: 2,
    },
    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",

        grid: 2,
    },
    {
        varName: "loanAccounts",
        type: "select",
        "enum": [],
        "onKeyDown": true,
        label: "Loan Accounts",
        grid: 3,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "productName",
        "type": "textApiCall",
        "required": true,
        "label": "Product Name",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "loanOutstanding",
        "type": "textApiCall",
        "required": true,
        "label": "Loan Outstanding",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },

    {
        "varName": "emiCompleted",
        "type": "textApiCall",
        "required": true,
        "label": "EMI Completed",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },

    {
        "varName": "remainingTenor",
        "type": "textApiCall",
        "required": true,
        "label": "Remaining Tenor",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "expectedTenor",
        "type": "text",
        "label": "Expected Tenor",
        "grid":2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",

    },
    {
        "label": "Fund availability in link account",
        "type": "text",
        "varName": "fundAvailability",
        "grid": 2,
        "readOnly": true,
        "required": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Link Account Status",
        "type": "text",
        "varName": "casaAccountStatus",
        "grid": 2,
        "readOnly": true,
        "required": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    }
];
let LoanAccountChangeReadOnlyForm = makeReadOnlyObject(JSON.parse(JSON.stringify(LoanAccountChangeCsoForm)));

//Interest Transfer Json Form
let InterestTransferCsoForm = [
    {
        varName: "customerCB",
        type: "textApiCall",
        label: "Customer CB",
        required: true,
        grid: 2,
    },
    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",

        grid: 2,
    },
    // {
    //     "label": "Service Type",
    //     "type": "select",
    //     "varName": "serviceType",
    //     "enum": [
    //         "service one",
    //         "service two"
    //     ],
    //     "grid": 2,
    //     "required": true
    // },
    {
        varName: "loanAccounts",
        type: "select",
        "enum": [],
        "onKeyDown": true,
        label: "Loan Accounts",
        grid: 2,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Security Accounts",
        "type": "text",
        "varName": "securityAccounts",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Loan Accounts SOL",
        "type": "text",
        "varName": "loanAccountsSol",
        "grid": 2,
    },
    {
        "label": "Case Accounts SOL",
        "type": "text",
        "varName": "caseAccountsSol",
        "grid": 2,
    },
    {
        "label": "Loan Type",
        "type": "select",
        "varName": "loanType",
        "enum": [
            "Auto Loan",
            "Personal Loan",
            "Loan Type"
        ],
        "grid": 2,
        // "readOnly": true
    },
    {
        "label": "Loan Outstanding",
        "type": "text",
        "varName": "loanOutstanding",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Request Type",
        "type": "select",
        "varName": "interestRequestType",
        "enum": [
            "Interest transfer request",
            "Security replacement",
            "Partial Security release",
            "Interest rate Changes",
            "Link account Changes"
        ],
        "grid": 2,
        "required": true
    }
];

let InterestTransferReadOnlyForm = makeReadOnlyObject(JSON.parse(JSON.stringify(InterestTransferCsoForm)));
//Descend Mark
const CSDescendMarkJsonForm = [

    {
        varName: "customerCB",
        type: "textApiCall",
        label: "Customer CB",
        required: true,
        grid: 2,
    },
    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",

        grid: 2,
    },
    // {
    //     varName: "serviceType",
    //     type: "select",
    //     label: "Service Type",
    //     readOnly:true,
    //     grid: 4,

    // },

    {
        varName: "loanAccounts",
        type: "select",
        "enum": [],
        "onKeyDown": true,
        label: "Loan Accounts",
        grid: 2,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
    },

    {
        "label": "Loan Accounts SOL",
        "type": "text",
        "varName": "loanAccountsSol",
        "grid": 2,
    },

    {
        varName: "loanType",
        type: "select",
        label: "Loan Type",
        "enum": [
            "Home Loan",
            "Auto Loan", "Personal Loan", "SME Loan", "Secured Loan"
        ],
        grid: 2,
    },


    {
        varName: "loanOutstanding",
        type: "text",
        label: "Loan Outstanding",
        grid: 2,

    },


    {
        type: "empty",
        grid: 12,
    },

];
//Lien marking json form
let lienMarkingJsonForm = [
    {
        varName: "customerCB",
        type: "textApiCall",
        label: "Customer CB",
        required: true,
        grid: 2,
    },
    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",

        grid: 2,
    },

    {
        varName: "loanAccounts",
        type: "select",
        "enum": [],
        "onKeyDown": true,
        label: "Loan Accounts",
        grid: 3,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "productName",
        "type": "textApiCall",
        "required": true,
        "label": "Product Name",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "loanOutstanding",
        "type": "textApiCall",
        "required": true,
        "label": "Loan Outstanding",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "lienAccount",
        "type": "textApiCall",
        "required": true,
        "label": "Lien Account",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "casaAccountStatus",
        "type": "text",
        "label": "Casa Account Status",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },

    {
        "label": "Reason",
        "type": "text",
        "varName": "reason",
        "grid": 3,
        "required": true,
    },
    {
        "label": "Amount",
        "type": "text",
        "varName": "amount",
        "grid": 3,
        "required": true,
    },
];

let lienMarkingReadOnlyForm = makeReadOnlyObject(JSON.parse(JSON.stringify(lienMarkingJsonForm)));

//Waiver Request
const waverRequestJsonForm = [
    {
        varName: "customerCB",
        type: "textApiCall",
        label: "Customer CB",
        required: true,
        grid: 2,
    },
    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",

        grid: 2,
    },

    {
        varName: "loanAccounts",
        type: "select",
        "enum": [],
        "onKeyDown": true,
        label: "Loan Accounts",
        grid: 2,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
    },

    {
        "label": "Loan Accounts SOL",
        "type": "text",
        "varName": "loanAccountsSol",
        "grid": 2,
    },

    {
        varName: "loanType",
        type: "select",
        label: "Loan Type",
        "enum": [
            "Home Loan",
            "Auto Loan", "Personal Loan", "SME Loan", "Secured Loan"
        ],
        grid: 2,
    },


    {
        varName: "loanOutstanding",
        type: "text",
        label: "Loan Outstanding",
        grid: 2,

    },


];
const waverRequestJsonFormReadonly = makeReadOnlyObject(JSON.parse(JSON.stringify([

    {
        varName: "customerCB",
        type: "text",
        label: "Customer CB",
        required: true,
        readOnly: true,
        grid: 4,
    },


    {
        varName: "loanAccounts",
        type: "text",
        label: "Loan Accounts",
        required: true,
        readOnly: true,
        grid: 4,
    },
    {
        "label": "Loan Accounts SOL",
        "type": "text",
        "varName": "loanAccountsSol",
        "grid": 2,
    },

    {
        varName: "loanType",
        type: "text",
        label: "Loan Type",
        required: true,
        readOnly: true,
        grid: 4,
    },


    {
        varName: "assetOperationNo",
        type: "text",
        label: "Asset Operation No",
        required: true,
        readOnly: true,
        grid: 4,
    },


    {
        varName: "loanAccountStatus",
        type: "text",
        label: "Loan account Status",
        readOnly: true,
        required: true,
        grid: 4,
    },


    {
        varName: "loanAccountCloseDate",
        type: "date",
        label: "Loan account Close Date",
        required: true,
        grid: 4,
    },

    {
        varName: "fileLocation",
        type: "select",
        label: "File Location",
        required: true,
        grid: 4,
        "enum": [
            "Archived",
            "Collection",
            "Asset Operation Users",
        ]
    },


    {
        varName: "dateOfTheDelivery",
        type: "date",
        label: "Date of the Delivery",
        required: true,
        grid: 4,
    },


    {
        varName: "fileDeliveryStatus",
        type: "select",
        label: "File Delivery Status",
        required: true,
        grid: 4,
        "enum": [
            "Under Process",
            "Ready to Deliver",
            "Delivered",
            "Acknowledgement",
            "Send Back to Archive",
        ]
    },


    {
        varName: "cblChequeNo",
        type: "text",
        label: "CBL Cheque No",
        required: true,
        readOnly: true,
        grid: 4,
    },

    {
        varName: "pdcAcNo",
        type: "text",
        label: "PDC A/C NO",
        required: true,
        readOnly: true,
        grid: 4,
    },

    {
        varName: "pdcNo",
        type: "text",
        label: "PDC NO",
        required: true,
        readOnly: true,
        grid: 4,
    },


    {
        varName: "brtaAuthority",
        type: "text",
        label: "BRTA Authority",
        required: true,
        readOnly: true,
        grid: 4,
    },

    {
        varName: "engineNo",
        type: "text",
        label: "Engine No",
        required: true,
        readOnly: true,
        grid: 4,
    },


    {
        varName: "chassisNo",
        type: "text",
        label: "Chassis No",
        required: true,
        readOnly: true,
        grid: 4,
    },


    {
        varName: "registrationNo",
        type: "text",
        label: "Registration No",
        required: true,
        readOnly: true,
        grid: 4,
    },

    {
        varName: "ownerName ",
        type: "text",
        label: "Owner Name ",
        required: true,
        readOnly: true,
        grid: 4,
    },

    {
        type: "empty",
        grid: 12,
    },


])));
//Recovery Request
let recoveryRequestJsonForm = [
    {
        varName: "solId",
        type: "text",
        readOnly: true,
        label: "SOL ID",
        grid: 3,
    },
    {
        varName: "loanAccountNumber",
        type: "text",
        readOnly: true,
        label: "Loan A/C Number",
        grid: 3,
    },
    {
        varName: "customerName",
        type: "text",
        readOnly: true,
        label: "Account Name",
        grid: 3,
    },
    {
        varName: "lienAmount",
        type: "text",
        readOnly: true,
        label: "Lien Amount",
        grid: 3,
    },
    {
        varName: "linkAccountNo",
        type: "text",
        readOnly: true,
        label: "Link A/C No",
        grid: 3,
    },
    {
        varName: "realization",
        type: "text",
        readOnly: true,
        label: "Realization",
        grid: 3,
    },
    {
        varName: "casaAccountStatus",
        type: "text",
        readOnly: true,
        label: "Casa Account Status",
        grid: 3,
    },
    {
        varName: "purpose",
        type: "select",
        required: true,
        label: "Purpose",
        enum:[
            "Recovery",
            "Legal Fee",
            "Manual Realization",
        ],
        grid: 3,
    },
];

let recoveryRequestReadOnlyForm = makeReadOnlyObject(JSON.parse(JSON.stringify(recoveryRequestJsonForm)));
//SME Loan Tracking
const roSmeLoanTracking = [

    {
        varName: "fileReferanceNo",
        type: "text",
        label: "File Referance no.",
        required: true,
        grid: 2,
    },


    {
        varName: "kycDate",
        type: "date",
        label: "KYC Date",
        required: true,
        grid: 2,

    },

    {
        varName: "enterpriseName",
        type: "text",
        label: "Enterprise name",
        required: true,
        grid: 2,

    },


    {
        varName: "proposalName",
        type: "select",
        label: "Proposal Name",
        required: true,
        grid: 2,

        "enum": [
            "A",
            "B",
        ]

    },


    {
        varName: "businessAddress",
        type: "text",
        label: "Business Address",
        required: true,
        grid: 2,

    },


    {
        varName: "productName",
        type: "text",
        label: "Product Name",
        required: true,
        grid: 2,


    },


    {
        varName: "customerType",
        type: "select",
        label: "Customer Type",
        required: true,
        grid: 2,
        "enum": [
            "New",
            "Repeat",
        ]

    },


    {
        type: "empty",
        grid: 12,
    },

    {
        type: "empty",
        grid: 12,
    },
    {
        type: "empty",
        grid: 12,
    },

    {
        type: "empty",
        grid: 12,
    },

    {
        "varName": "appliedAmount",
        "type": "title",
        "label": "Applied Amount",
        "grid": 12,
    },


    {
        varName: "appliedAmountTerm",
        type: "text",
        label: "Applied Amount(Term)",
        required: true,
        grid: 2,


    },

    {
        varName: "appliedAmountOverDraft",
        type: "text",
        label: "Applied Amount(Over Draft)",
        required: true,
        grid: 2,


    },

    {
        varName: "appliedAmountSingleInstallment",
        type: "text",
        label: "Applied Amount(Single Installment)",
        required: true,
        grid: 2,


    },

    {
        varName: "appliedAmountOthers",
        type: "text",
        label: "Applied Amount(Others)",
        required: true,
        grid: 2,


    },

    {
        varName: "appliedTenure",
        type: "text",
        label: "Applied Tenure",
        required: true,
        grid: 2,


    },

    {
        varName: "appliedInterestRate",
        type: "text",
        label: "Applied Interest Rate",
        required: true,
        grid: 2,


    },

    {
        varName: "appliedPurpose",
        type: "text",
        label: "Applied Purpose",
        required: true,
        grid: 2,


    },

    {
        varName: "unitOfficeName",
        type: "text",
        label: "Unit Office Name",
        required: true,
        grid: 2,


    },

    {
        varName: "territoryName",
        type: "text",
        label: "Territory Name",
        required: true,
        grid: 2,


    },

    {
        varName: "regionName",
        type: "text",
        label: "Region Name",
        required: true,
        grid: 2,


    },

    {
        varName: "roId",
        type: "text",
        label: "RO ID",
        required: true,
        grid: 2,


    },

    {
        varName: "ucmId",
        type: "text",
        label: "UCM ID",
        required: true,
        grid: 2,


    },


    {
        type: "empty",
        grid: 12,
    },

    {
        type: "empty",
        grid: 12,
    },
    {
        type: "empty",
        grid: 12,
    },

    {
        type: "empty",
        grid: 12,
    },

    //Business information:
    {
        "varName": "businessInformation",
        "type": "title",
        "label": "Business Information",
        "grid": 12,
    },

    {
        varName: "natureOfBusiness",
        type: "text",
        label: "Nature of the Business",
        required: true,
        grid: 2,


    },

    {
        varName: "productsServicesOfBusiness",
        type: "text",
        label: "Products & Services of the Business",
        required: true,
        grid: 2,


    },

    {
        type: "empty",
        grid: 12,
    },

    {
        type: "empty",
        grid: 12,
    },
    {
        type: "empty",
        grid: 12,
    },

    {
        type: "empty",
        grid: 12,
    },


    {
        type: "empty",
        grid: 12,
    },

    {
        type: "empty",
        grid: 12,
    },
    {
        type: "empty",
        grid: 12,
    },

    {
        type: "empty",
        grid: 12,
    },


    {
        varName: "territoryManagerVisitedDate",
        type: "date",
        label: "Territory Manager Visited Date",
        required: true,
        grid: 3,


    },

    {
        varName: "visitedIdOfTerritoryManager",
        type: "text",
        label: "Visited ID of Territory Manager",
        required: true,
        grid: 2,


    },


];
const tmSmeLosnTracking = makeReadOnlyObject(JSON.parse(JSON.stringify(roSmeLoanTracking))).concat([

    {
        varName: "territoryManagerVisitedDate",
        type: "date",
        label: "Territory Manager Visited Date",
        required: true,
        grid: 2,
    },
    {
        varName: "visitedIdOfTerritoryManager",
        type: "text",
        label: "Visited ID of Territory Manager",
        required: true,
        grid: 2,
    }
]);
const creditMisSmeLosnTracking = makeReadOnlyObject(JSON.parse(JSON.stringify(roSmeLoanTracking))).concat([

    {
        varName: "territoryManagerVisitedDate",
        type: "date",
        label: "Territory Manager Visited Date",
        required: true,
        grid: 2,
    },
    {
        varName: "visitedIdOfTerritoryManager",
        type: "text",
        label: "Visited ID of Territory Manager",
        required: true,
        grid: 2,
    },
    {
        type: "empty",
        grid: 12,
    },
    {
        "varName": "creditMis",
        "type": "title",
        "label": "Credit Mis",
        "grid": 12,
    },

    {
        varName: "cibSubjectCode",
        type: "text",
        label: "CIB Subject Code",
        required: true,
        grid: 4,
    },


    {
        varName: "cibSearchingDate",
        type: "date",
        label: "CIB Searching Date",
        required: true,
        grid: 4,

    },


    {
        varName: "dedupeSearchingDate",
        type: "date",
        label: "Dedupe Searching Date",
        required: true,
        grid: 4,

    },


    {
        type: "empty",
        grid: 12,
    },
]);
const ucmSmeLosnTracking = makeReadOnlyObject(JSON.parse(JSON.stringify(roSmeLoanTracking))).concat([

    {
        varName: "territoryManagerVisitedDate",
        type: "date",
        label: "Territory Manager Visited Date",
        required: true,
        grid: 2,
    },
    {
        varName: "visitedIdOfTerritoryManager",
        type: "text",
        label: "Visited ID of Territory Manager",
        required: true,
        grid: 2,
    },
    {
        type: "empty",
        grid: 12,
    },
    {
        "varName": "creditMis",
        "type": "title",
        "label": "Credit Mis",
        "grid": 12,
    },

    {
        varName: "cibSubjectCode",
        type: "text",
        label: "CIB Subject Code",
        required: true,
        grid: 4,
    },


    {
        varName: "cibSearchingDate",
        type: "date",
        label: "CIB Searching Date",
        required: true,
        grid: 4,

    },


    {
        varName: "dedupeSearchingDate",
        type: "date",
        label: "Dedupe Searching Date",
        required: true,
        grid: 4,

    },


    {
        type: "empty",
        grid: 12,
    },


    //CRM starts here

    {
        "varName": "creditCRM",
        "type": "title",
        "label": "Credit CRM",
        "grid": 12,
    },

    {
        varName: "hardCopyReceivedDate",
        type: "date",
        label: "Hard Copy Received Date",
        required: true,
        readOnly: true,
        grid: 2,
    },

    {
        varName: "fileStatus",
        type: "select",
        label: "File Status",
        required: true,
        readOnly: true,
        grid: 2,
        "enum": [
            "Waiting for Hardcopy",
            "Under Process", "Incompleteness & Query", "Held Up", "Approved", "Declined & Send Back"
        ]
    },

    {
        varName: "fileStatusDate",
        type: "date",
        label: "File Status Date",
        required: true, readOnly: true,
        grid: 2,

    },

    {
        varName: "filesCategoryRemarks",
        type: "text",
        label: "Files Category (Remarks)",
        required: true, readOnly: true,
        grid: 2,

    },

    {
        varName: "proposalVisitDate",
        type: "date",
        label: "Proposal Visit Date",
        required: true, readOnly: true,
        grid: 2,

    },

    {
        varName: "visitedEmployeeId",
        type: "text",
        label: "Visited Employee ID",
        required: true, readOnly: true,
        grid: 2,

    },

    {
        varName: "visitStatus",
        type: "select",
        label: "Visit Status",
        required: true, readOnly: true,
        grid: 2,
        "enum": [
            "Visited", "Not Visited", "Visit Not Req"
        ]

    },

    {
        varName: "guarantorVisitDate",
        type: "date",
        label: "Guarantor Visit Date",
        required: true, readOnly: true,
        grid: 2,

    },
    {
        varName: "guarantorVisitedEmployeeId",
        type: "text",
        label: "Guarantor Visited Employee ID",
        required: true, readOnly: true,
        grid: 2,

    },
    {
        varName: "guarantorVisitStatus",
        type: "text",
        label: "Guarantor Visit Status",
        required: true, readOnly: true,
        grid: 2,

    },
    {
        varName: "reVisitedDate",
        type: "date",
        label: "Re-Visited Date",
        required: true, readOnly: true,
        grid: 2,

    },
    {
        varName: "reVisitedEmployeeId",
        type: "date",
        label: "Re-Visited Employee ID",
        required: true, readOnly: true,
        grid: 2,

    },
]);
module.exports = {
    CSsecuredLoanClosingJsonForm,
    BOMsecuredLoanClosingJsonForm,
    BMsecuredLoanClosingJsonForm,
    nocMakersecuredLoanClosingJsonForm,
    MakersecuredLoanClosingJsonForm,
    closingMakerMakersecuredLoanClosingJsonForm,
    CheckersecuredLoanClosingJsonForm,

    CSLoanClosingJsonForm,
    BOMLoanClosingJsonForm,
    BMClosingJsonForm,
    nocMakerLoanClosingJsonForm,
    documentMakerLoanClosingJsonForm,
    CheckerLoanClosingJsonForm,

    CsOutstandingCertificateJsonFrom,
    CsOutstandingCertificateJsonFromTaxReturn,
    CsOutstandingCertificateJsonFromReadOnly,
    CsOutstandingCertificateJsonFromTaxReturnReadOnly,

    CsPartialPaymentJsonFrom,
    CsPartialPaymentJsonFromReadOnly,
    // MakersecuredPartialPaymentJsonFrom,
    EmiDataChangeCsoForm,
    EmiDataChangeReadOnlyForm,

    LoanAccountChangeCsoForm,
    LoanAccountChangeReadOnlyForm,

    InterestTransferCsoForm,
    InterestTransferReadOnlyForm,
    CSDescendMarkJsonForm,
    lienMarkingJsonForm,
    lienMarkingReadOnlyForm,

    waverRequestJsonForm,
    waverRequestJsonFormReadonly,
    recoveryRequestJsonForm,
    recoveryRequestReadOnlyForm,
    roSmeLoanTracking,
    tmSmeLosnTracking,
    creditMisSmeLosnTracking,
    ucmSmeLosnTracking,
};
