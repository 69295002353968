import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../Common/Functions';
import Notification from "../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme";
import Table from "../../Table/Table";
import FileTypeComponent from "../../JsonForm/FileTypeComponent";
import DialogContent from "@material-ui/core/DialogContent/index";
import {Dialog} from "@material-ui/core/index";
import CheckerTagCBDetails from "../CASA/CheckerTagCBDetails";
import GridList from "@material-ui/core/GridList/index";
import loader from "../../../Static/loader.gif";
import AssignedCropImage from "../CASA/AssignedCropImage";
import {AbhCheckerJsonFormForAgentIndividual} from '../WorkflowJsonForm4';

var fileUpload = {
    "varName": "scanningFile",
    "type": "file",
    "label": "Upload File",
    "grid": 12
};
let abhChecker = [
    {
        "varName": "abhChecker",
        "type": "textArea",
        "label": "Abh Checker Remarks",
        "grid": 12
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};


class AbhChecker extends React.Component {
    state = {
        message: "",
        appData: {},
        getData: false,
        varValue: [],
        redirectLogin: false,
        title: "",
        notificationMessage: "",
        alert: false,
        inputData: {},
        selectedDate: {},
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        values: [],
        showValue: false,
        customerName: [],
        deferalType: [],
        expireDate: [],
        other: [],
        getCheckerList: [],
        getDeferalList: [],
        fileUploadData: {},
        loaderNeeded: null,
        objectForJoinAccount: [],
        getgenerateForm: false,
        getTagList: [],
        tagCbDetailsModal: false,
        tagCb: "",
        loading: false,
        getRemarks: [],
        err: false,
        errorArray: {},
        errorMessages: {},
        getCropedImage: false

    };

    handleChange = (event) => {

        event.preventDefault();

        this.state.inputData[event.target.name] = event.target.value;


    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    };

    componentDidMount() {
        var remarksArray = [];
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;

            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                        console.log(response.data);
                        let tableArray = [];
                        var status = "";
                        response.data.map((deferal) => {
                            if (deferal.status === "APPROVAL_WAITING") {
                                status = "Waiting For Approval"
                            }
                            tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));
                        });
                        this.setState({
                            getDeferalList: tableArray
                        });
                        let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                        axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {

                                console.log(response.data);
                                response.data.map((data) => {

                                    remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                });
                                this.setState({
                                    getRemarks: remarksArray
                                })
                            })
                            .catch((error) => {
                                console.log(error)
                            });
                        let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                console.log(response.data);
                                this.setState({
                                    getImageLink: response.data,
                                    getImageBoolean: true
                                })
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                        console.log(response.data);

                    })
                        .catch((error) => {
                            console.log(error);
                        });

                    this.setState({
                        getData: true,
                        showValue: true,
                        inputData: response.data,
                        varValue: response.data,
                        appData: response.data
                    });


                })
                .catch((error) => {
                    console.log(error);
                    /* if(error.response.status===452){
                         Functions.removeCookie();

                         this.setState({
                             redirectLogin:true
                         })

                     }*/
                });
        }

    }


    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    close = () => {
        this.props.closeModal();
    };
    closeModal = () => {
        this.setState({
            getCropedImage: false
        })
    };
    handleSubmit = (event, checker_approval) => {
        event.preventDefault();

        this.state.inputData.checker_approval = checker_approval;
        if (this.state.fileUploadData.scanningFile !== undefined) {
            let fileUploadPath = backEndServerURL + "/case/upload";
            let types = 'Attachments';
            let files = this.state.fileUploadData.scanningFile;
            console.log("dfghjk");
            console.log(files);
            let formData = new FormData();
            formData.append("appId", this.props.appId);
            formData.append("file", files);
            formData.append("type", types);
            axios({
                method: 'post',
                url: fileUploadPath,
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'content-type': 'multipart/form-data'
                }
            })
                .then((response) => {

                    console.log(response);


                })
                .catch((error) => {
                    console.log(error)
                })
        }

        var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.abhChecker + "/" + this.props.appId;
        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error)
            });
        console.log(this.state.inputData);
        this.state.inputData.abhChecker = undefined;
        var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                var url = backEndServerURL + "/case/route/" + this.props.appId;

                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {


                        console.log(response.data);
                        this.setState({
                            title: "Successfull!",
                            notificationMessage: "Successfully Routed!",
                            alert: true
                        });
                        this.props.closeModal()
                        //
                    })
                    .catch((error) => {
                        console.log(error);

                    });
            })
            .catch((error) => {
                console.log(error)
            });


    };


    /*renderForm = () => {
         if (this.state.getData) {

             return (
                 CommonJsonFormComponent.renderJsonForm(this.state, this.props.jsonForm, this.updateComponent)

             )
         }
         return;
     } */

    renderFileUpload = () => {
        if (this.state.getData) {
            return (

                <Grid item xs={12}>
                    {FileTypeComponent.file(this.state, this.updateComponent, fileUpload)}
                </Grid>

            )
        }

    };
    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <Grid item xs={12}>

                    <div>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                                marginTop: 20

                            }}

                            type='button' value='add more'
                            onClick={(event) => this.handleSubmit(event, "APPROVED")}
                        >Approve
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                                marginTop: 20

                            }}
                            checkerApproval

                            type='button' value='add more'
                            onClick={(event) => this.handleSubmit(event, "RETURN")}
                        >Return
                        </button>
                    </div>


                </Grid>

            )
        }
    };
    renderImageCrop = (event) => {
        event.preventDefault();
        this.setState({
            getCropedImage: true
        })


    };
    renderDefferalData = () => {


        if (this.state.getDeferalList.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Deferral List:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type", "Due Date", "Created By", "Application Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>


                    </div>
                </div>

            )
        }

    };
    tagCbDetailsModalClse = () => {
        this.setState({
            tagCbDetailsModal: false
        })
    };
    tagCbDetailsModal = (event, cb) => {
        event.preventDefault();
        this.setState({
            tagCbDetailsModal: true,
            tagCb: cb
        })

    };
    createTaggingData = (taggingname) => {
        return (
            [taggingname, [<button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'right',
                }}
                type='button' value='add more'
                onClick={(event) => this.tagCbDetailsModal(event, taggingname)}
            >View
            </button>]]


        )
    };


    renderTagList = () => {

        if (this.state.getData && (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C") && this.state.getTagList.length > 0) {

            return (

                <div style={{marginBottom: 40}}>
                    {/*<paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Link CB List</h4>

                        </CardHeader>
                    </paper>
*/}
                    <div>
                        {/*  <center>
                        <h4 style={{color: "white"}} >Link CB List</h4>
                    </center>*/}
                        <b>Tagging List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["CB Number", "View"]}

                            tableData={this.state.getTagList}
                            tableAllign={['left', 'left', 'left']}
                        />

                        <br/>


                    </div>

                </div>

            )
        }

    };


    renderJsonFormFirst = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, AbhCheckerJsonFormForAgentIndividual, this.updateComponent)


            )
        } else {

        }
    };


    renderRemarks = () => {
        if (this.state.getData) {

            return (

                CommonJsonFormComponent.renderJsonForm(this.state, abhChecker, this.updateComponent)

            )
        }

    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }


        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.tagCbDetailsModal}>
                        <DialogContent className={classes.dialogPaper}>
                            <CheckerTagCBDetails tagCb={this.state.tagCb} closeModal={this.tagCbDetailsModalClse}/>
                            {/*  <MakerCumInput customerNumber={this.state.getCustomerNumber} appId={this.props.app_uid} generateAccountNo={this.state.generateAccountNo} tagingModalCbnumber={this.state.tagCb} taging={this.taging} closeModal={this.tagCbDetailsModalClse}/>*/}
                        </DialogContent>

                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.getCropedImage}>
                        <DialogContent className={classes.dialogPaper}>
                            <AssignedCropImage subServiceType={this.props.subServiceType} appId={this.props.appId}
                                               closeModal={this.closeModal}/>

                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>

                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <ThemeProvider theme={theme}>
                        <Grid container>
                            {this.renderNotification()}
                            {this.renderJsonFormFirst()}

                            <Grid item={12}>
                                {this.renderDefferalData()}
                            </Grid>
                            <br/>
                            <Grid item={12}>

                                {this.renderRemarksData()}
                            </Grid>


                            <br/>


                        </Grid>
                        <br/>


                        <br/>
                        {this.renderRemarks()}
                        <br/>
                        {this.renderSubmitButton()}
                    </ThemeProvider>


                </div>
            </GridList>

        )

    }

}

export default withStyles(styles)(AbhChecker);
