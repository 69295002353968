import Grid from "@material-ui/core/Grid";
import Downshift from "downshift";
import Paper from "@material-ui/core/Paper";
import theme from "./CustomeTheme";
import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import deburr from 'lodash/deburr';

let popperNode;

class DropdownComponent {
    static asteriksFunction = (field) => {
        if (field.required === true && field.asteriks === undefined) {
            return (
                <Grid item>
                    <label className="input-label-common">{field.label} *</label>
                </Grid>
            )
        } else {
            return (
                <Grid item>
                    <label className="input-label-common">{field.label}</label>
                </Grid>
            )
        }
    };
    static  renderInput = (inputProps) => {
        const {InputProps, ref, ...other} = inputProps;


        return (
            <TextField

                InputProps={{
                    inputRef: ref,
                    required: true,
                    ...InputProps,
                }}
                {...other}
            />
        );
    };
    static  renderSuggestion = (suggestionProps) => {
        const {suggestion, index, itemProps, highlightedIndex, selectedItem} = suggestionProps;
        const isHighlighted = highlightedIndex === index;
        const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;


        return (
            <MenuItem
                {...itemProps}
                key={suggestion.value}
                selected={isHighlighted}
                component="div"
                style={{
                    fontWeight: isSelected ? 500 : 400,
                }}
            >
                {suggestion.label}
            </MenuItem>
        );
    };
    static getSuggestions = (state, value, val, varName, {showEmpty = false} = {}) => {
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;

        let count = 0;
        let searchArray = [];
        if (state.dropdownSearchData[varName] !== undefined && state.dropdownSearchData[varName] !== null)
            searchArray = state.dropdownSearchData[varName];

        return inputLength === 0 && !showEmpty
            ? []
            : searchArray.filter(suggestion => {
                const keep =
                    count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

                if (keep) {
                    count += 1;
                }

                return keep;
            });
    };


    static handleDropdownSelect = (varName, selectedItem, state, update) => {
        let len = state.dropdownSearchData[varName].length;
        for (let i = 0; i < len; i++) {
            let object = state.dropdownSearchData[varName][i];

            if (object.label === selectedItem) {
                state.inputData[varName] = object.value;
            }
        }
        update()
        //this.updateErrorMessageOnChange(varName);
    };

    static dropdown(state, update, field) {
        return (

            <Downshift onSelect={selectedItem => {
                this.handleDropdownSelect(field.varName, selectedItem, state, update)
            }}
                       initialInputValue="">

                {({

                      getInputProps,
                      getItemProps,
                      getMenuProps,
                      highlightedIndex,
                      inputValue,
                      isOpen,
                      selectedItem,
                  }) => {
                    const {onBlur, onFocus, ...inputProps} = getInputProps({});

                    return (
                        <div>
                            {this.renderInput({
                                label: field.label,
                                varName: field.varName,
                                InputProps: {onBlur, onFocus},
                                inputProps,

                                ref: node => {
                                    popperNode = node;
                                },
                            })}

                            <div {...getMenuProps()}>
                                {isOpen ? (

                                    <Paper
                                        style={{
                                            position: "absolute",
                                            zIndex: 1,
                                            marginTop: theme.spacing.unit,
                                            width: popperNode.clientWidth,
                                            flexWrap: "wrap"


                                        }} square

                                    > {this.getSuggestions(state, inputValue, field.value, field.varName).map((suggestion, index) =>
                                        this.renderSuggestion({
                                            suggestion,
                                            index,
                                            //initialInputValue:"Bangladesh",
                                            val: suggestion.value,
                                            varName: field.varName,
                                            itemProps: getItemProps({item: suggestion.label}),
                                            highlightedIndex,
                                            selectedItem,

                                        }),
                                    )}
                                    </Paper>
                                ) : null}
                            </div>

                        </div>
                    );
                }}
            </Downshift>


        )
    }

}

export default DropdownComponent;