import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import Table from "../Table/Table";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import CloseIcon from '@material-ui/icons/Close';

import Functions from '../../Common/Functions';

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

});

class StandingInstruction extends Component {

    constructor(props) {
        super(props);
        this.state = {
            varValue: [],
            getData: false,
            redirectLogin: false,
        }
    }

    componentDidMount() {
        let url = backEndServerURL + "/view/360";

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                let varValue = [];

                if (response.data.coreValue[0] !== null) {


                    varValue["CUSTOMERNAME"] = response.data.coreValue[0].CUSTOMERNAME;
                    varValue["AUTOLOANOUTSTANDING"] = response.data.coreValue[0].AUTOLOANOUTSTANDING;
                    varValue["SMELOANOUTSTANDING"] = response.data.coreValue[0].SMELOANOUTSTANDING;

                    varValue["CUSTOMERBAND"] = response.data.coreValue[0].CUSTOMERBAND;
                    varValue["HOMEBRANCH"] = response.data.coreValue[0].HOMEBRANCH;
                    varValue["CAAMB"] = response.data.coreValue[0].CAAMB;

                    varValue["SAAMB"] = response.data.coreValue[0].SAAMB;
                    varValue["DPSBALANCE"] = response.data.coreValue[0].DPSBALANCE;
                    varValue["RELATIONSHIPVALUE"] = response.data.coreValue[0].RELATIONSHIPVALUE;
                    if (response.data.coreValue[0].DEBITCARD === "N") {
                        varValue["DEBITCARD"] = "No"
                    } else if (response.data.coreValue[0].DEBITCARD === "Y") {
                        varValue["DEBITCARD"] = "Yes"
                    } else {

                    }

                    varValue["CAABL"] = response.data.coreValue[0].CAABL;
                    varValue["SAABL"] = response.data.coreValue[0].SAABL;

                } else {

                }


                this.setState({
                    varValue: varValue,
                    getData: true
                })

            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
                this.setState({
                    getData: true
                })
            });
    }

    close = () => {
        this.props.closeModal()
    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <section>
                <center>
                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                                    <h4 style={{color: "white"}} >Standing Instruction<a><CloseIcon onClick={this.close} style={{
                                        position: 'absolute',
                                        right: 10,
                                        color: "#000000"
                                    }}/></a></h4>

                                </CardHeader>
                                <CardBody>
                                    <div className={classes.root}>
                                        <Grid container spacing={1}>
                                            <Grid container item xs={12} spacing={5}>
                                                <Grid item xs={12}>
                                                    <Paper className={classes.paper}>

                                                        <Table
                                                            tableHeaderColor="primary"
                                                            tableHead={["View Data", "Value"
                                                            ]}
                                                            tableData={[
                                                                ["Number Of Standing Instruction", this.state.varValue[""]],
                                                                ["Type Of Standing Instruction", this.state.varValue[""]],
                                                                ["Number Of SI Failure", this.state.varValue[""]],
                                                                ["Number Of Successful SI", this.state.varValue[""]],

                                                            ]}
                                                            tableAllign={['left', 'left', 'left', 'left']}
                                                        />
                                                    </Paper>

                                                </Grid>


                                            </Grid>
                                        </Grid>
                                    </div>


                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </center>
            </section>


        );
    }
}

export default withStyles(styles)(StandingInstruction);
