import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles/index";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios/index';
import {backEndServerURL} from "../../../Common/Constant";
import GridItem from "../../Grid/GridItem.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";


class MissingFlies extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: true,
            getDeferalList: [],
            defferalData: false,
            getData: false,
            images: [],
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            missingList: [],
            loader: true
        }
    }


    componentDidMount() {

        console.log(this.props.appId);

        let data = [];

        let documentsUrl = backEndServerURL + "/case/files/" + this.props.appId;
        axios.get(documentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                response.data.map(image => {
                    data.push(image.split('_')[1].split('.')[0]);
                });

                this.setState({
                    images: data,
                    loader: false
                });


            })
            .catch((error) => {
                console.log(error)
            })
    }


    renderImage = () => {
        let results = this.props.documents.filter(item => {
            return !this.state.images.includes(item.label)
        });

        return (
            <div>

                {
                    results.map((item, index) => {
                        // console.log(item.label);
                        return (
                            <div>
                                <h5 style={{
                                    marginTop: '8px',
                                    textTransform: 'capitalize'
                                }}>
                                    {
                                        index + 1 + "." + " " + item.label
                                    }
                                </h5>
                            </div>
                        )
                    })
                }
            </div>
        )

    };

    okHandler = () => {
        if (this.props.triggerConfirmation) {
            this.props.triggerSubmit();
            this.props.closeModal()
        } else {
            this.props.closeModal()
        }

    };


    render() {


        if (this.state.loader) {
            return (
                <Grid container spacing={0}>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >Missing Flies<a><CloseIcon onClick={this.props.closeModal} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a></h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <Grid container spacing={0}>
                                        <ThemeProvider theme={theme}>
                                            <CircularProgress style={{marginLeft: '50%'}}/>
                                        </ThemeProvider>
                                    </Grid>
                                </div>

                            </CardBody>
                        </Card>
                    </GridItem>
                </Grid>
            );
        } else {
            return (
                <Grid container spacing={0}>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >Missing Flies<a><CloseIcon onClick={this.props.closeModal} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a></h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <Grid container spacing={0}>
                                        <ThemeProvider theme={theme}>

                                            {
                                                this.renderImage()
                                            }
                                            <Grid item xs={12}></Grid>
                                        </ThemeProvider>
                                        <ThemeProvider theme={theme}>
                                            <button
                                                className="btn btn-outline-primary"
                                                type='button'
                                                onClick={this.okHandler}
                                            >OK
                                            </button>
                                        </ThemeProvider>
                                    </Grid>
                                </div>

                            </CardBody>
                        </Card>
                    </GridItem>
                </Grid>
            );
        }
    }


}

export default MissingFlies;