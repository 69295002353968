import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import CommonJsonFormComponent from '../../JsonForm/CommonJsonFormComponent';
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import {backEndServerURL} from "../../../Common/Constant";
import Table from "../../Table/Table";
import Notification from "../../NotificationMessage/Notification";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import 'semantic-ui-offline/semantic.min.css';
import Button from "@material/react-button";
import ErrorModal from "../CommonComponent/ErrorModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import MyValidation from "../../JsonForm/MyValidation";
import EmployeeDeatils from "../CommonComponent/EmployeeDeatils";
import ChangeTimeFormat from "../CommonComponent/ChangeTimeFormat";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";

const jsonForm = [
    {
        "label": "Case ID",
        "type": "text",
        "varName": "RelationshipNo",
        "grid": 4,
        "readOnly": true
    },
    {
        "label": "Call Status",
        "type": "select",
        "varName": "callStatus",
        "grid": 4,
        "required": true,
        "enum": [
            "Forward",
            "Return",
            "Cancel",
            "In Progress",
            "Solved"
        ]
    },
    {
        "varName": "customerCategory",
        "type": "select",
        "onKeyDown": true,
        "enum": [
            "Account",
            "AMEX",
            "VISA/Master",
            "Others"
        ],
        "grid": 4,
        "label": "Customer Category",
        "required": true,
        "conditional": true,
        "conditionalVarName": "callStatus",
        "conditionalVarValue": "Forward",

    },
    {
        "varName": "callCategory",
        "type": "autoComplete",
        "grid": 4,
        "label": "Call Category",
        "required": true,
        "conditional": true,
        "conditionalVarName": "callStatus",
        "conditionalVarValue": "Forward",

    },
    // {
    //     "label": "Select Division",
    //     "type": "select",
    //     "varName": "selectDivision",
    //     "grid": 3,
    //     "enum": [],
    //     "required": true,
    //     "conditional": true,
    //     "conditionalVarName": "callStatus",
    //     "conditionalVarValue": "Forward",
    //
    // },
    {
        "label": "Remarks",
        "type": "text",
        "varName": "remarks",
        "grid": 4,
        "multiline": true,
        "required": true,
    },
];
const jsonFormFile = [
    {
        "varName": "attachFile",
        "type": "file",
        "label": "Attach File",
        "grid": 3,
        "accept": ".pdf",
        "conditionalOrValue": true,
        "conditionalVarName": "callStatus",
        "conditionalOrList": ["Forward", "Solved"],
    },
];


const tableData = [
    "Case Id",
    "Call Category",
    "Verify",
    "Account No",
    "Card No",
    "Client ID",
    "Receiving Branch",
    "Com./Home Address",
    "Reason for Reissue",
    "Account No. for Refunding",
    "Travel Quota Limit",
    // "SAARC Value",
    // "Non-SAARC value",
    "Customer Category",
    "Master/Visa Card",
    "Merchant ID",
    "Others",
    "Call Center Code/CSO",
    "Contact Number",
    "Created By",
    "Created Date",
    "Last Updated By",
    "Assigned To",
    "Assigned Date",
    "Required Time",
    "Support Team's Contact No",
    "Status",
    "Further Instruction",
];

class CardMaintenanceRequest extends Component {


    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            getData: false,
            fileUploadData: {},
            //      getDataArray:this.props.arrayValue,
            showValue: true,
            uploadModal: false,
            imageModel: false,
            autoComplete: {},
            secondColumnData: [],
            caseId: "",
            notificationType: "",
            notificationTitle: "",
            notificationMessage: "",
            alert: false,
            showData: true,
            supportDocumentArray: [],
            responseData: [],
            remarkValue: "",
            targetValue: "",
            viewModal: false,
            getRemarks: [],
            callCategory: "",
            getTargets: [],
            getImageLink: [],
            getImageBoolean: false,
            imageModalBoolean: false,
            imageName: [],
            callCategoryCheckResult: "",
            selectImage: "",
            message: "",
            err: false,
            loader: true,
            errorArray: {},
            errorMessages: {},
            disabled: false,
            customerCategory: "",
            userName: "",
            groupName: "",
            userDetails: false,
            varValue:
                {
                    RelationshipNo: this.props.arrayValue.caseId,
                },
            tableData: [
                ["reza", "425234"],
                ["rashed", "154322"]
            ]
        }
    }

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    handleChangeCustomerDetails = (userName, groupName) => {
        this.setState({
            userName: userName,
            groupName: groupName,
            userDetails: true
        })
    };

    createTargetsTable = (customerCategory, callCategory, currentUserName, delStatus, delInitTime, delFinishTime, userRole, activity) => {
        return (
            [customerCategory, callCategory, <span>
              <a href="javascript: void(0)"
                 onClick={() => this.handleChangeCustomerDetails(currentUserName, userRole)}>{currentUserName}</a>
             </span>, delStatus, activity, delInitTime, delFinishTime]
        )
    };

    componentDidMount() {
        var remarksArray = [];
        var targetsArray = [];
        let variables_url = backEndServerURL + "/variables/" + this.props.appId;
        axios.get(variables_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.state.inputData.call_center_maker_approval_to =response.data.call_center_maker_approval_to ;
                this.setState({
                    callCategory: response.data.callCategory,
                    customerCategory: response.data.customerCategory,
                    getData: true,
                    responseData: response.data,
                    secondColumnData: [
                        response.data.caseId === null || response.data.caseId === "null" ? "" : response.data.caseId,
                        response.data.callCategory === null || response.data.callCategory === "null" ? "" : response.data.callCategory,
                        response.data.securityVerified === null || response.data.securityVerified === "null" ? "" : response.data.securityVerified,
                        response.data.accountNo === null || response.data.accountNo === "null" ? "" : response.data.accountNo,
                        response.data.cardNumber === null || response.data.cardNumber === "null" ? "" : response.data.cardNumber,
                        response.data.clientId === null || response.data.clientId === "null" ? "" : response.data.clientId,
                        response.data.receivingBranchName === null || response.data.receivingBranchName === "null" ? "" : response.data.receivingBranchName,
                        response.data.homeAddress === null || response.data.homeAddress === "null" ? "" : response.data.homeAddress,
                        response.data.reasonForReissue === null || response.data.reasonForReissue === "null" ? "" : response.data.reasonForReissue,
                        this.props.arrayValue.accountNumber,
                        response.data.travelQuotaLimit === null || response.data.travelQuotaLimit === "null" ? "" : response.data.travelQuotaLimit,
                        response.data.customerCategory === null || response.data.customerCategory === "null" ? "" : response.data.customerCategory,
                        "",
                        response.data.merchantsId === null || response.data.merchantsId === "null" ? "" : response.data.merchantsId,
                        "",
                        "",
                        response.data.contactNo === null || response.data.contactNo === "null" ? "" : response.data.contactNo,
                        this.props.arrayValue.initiatedBy,
                        ChangeTimeFormat.convertTime(this.props.arrayValue.appInitTime),
                        "",
                        this.props.arrayValue.currentUserName === "Despatch" ? "X" : this.props.arrayValue.currentUserName,
                        ChangeTimeFormat.convertTime(this.props.arrayValue.delInitTime),
                        "",
                        "",
                        this.props.arrayValue.delStatus === "SAVED" ? "In Progress"
                            : this.props.arrayValue.delStatus === "CLOSED" && this.props.arrayValue.delInboxStatus === "SOLVED" ? this.props.arrayValue.delInboxStatus
                                : this.props.arrayValue.delStatus === "CLOSED" && this.props.arrayValue.delInboxStatus === "CANCELLED" ? this.props.arrayValue.delInboxStatus
                                    : this.props.statusFlag === true ? this.props.arrayValue.status
                                        : this.props.arrayValue.delStatus,
                        "",
                        "",
                    ],
                    loader: false
                });
            }).catch((error) => {
            console.log(error)
        });
        //*******remark url***********
        let get_remark_url = backEndServerURL + "/appRemarkGet/" + this.props.appId;
        axios.get(get_remark_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((data) => {

                    remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                });
                this.setState({
                    getRemarks: remarksArray
                })

            })
            .catch((error) => {
                console.log(error)
            });
        let get_target_url = backEndServerURL + "/perticipatedHistory/" + this.props.appId;
        axios.get(get_target_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((data, inbox) => {
                    let dataLength = response.data.length;
                    if ((LocalstorageEncrypt.encryptStorageFunction.getItem('workflowGroup') === "CARD_DIVISION"
                        || LocalstorageEncrypt.encryptStorageFunction.getItem('workflowGroup') === "CARD_DIVISION_LINE_MANAGER") && dataLength !== (inbox + 1)) {
                        let item = response.data[inbox];
                        if (dataLength === (inbox + 2) && item.delStatus === "In Progress" && (response.data[inbox + 1].delStatus === "SOLVED" || response.data[inbox + 1].delStatus === "CANCELLED")) {
                            item.delStatus = "CLOSED";
                        }
                        targetsArray.push(this.createTargetsTable(item.freeFlag1, item.freeFlag2, item.currentUserName === "Despatch" ? "Despatch" : item.currentUserName, item.delStatus === "SAVED" ? "In Progress" : item.delStatus, ChangeTimeFormat.convertTime(item.delInitTime), item.delFinishTime === null ? "" : ChangeTimeFormat.convertTime(item.delFinishTime), item.currentUserRole, item.activity));
                    }
                });
                this.setState({
                    getTargets: targetsArray
                })

            })
            .catch((error) => {
                console.log(error)
            });
        //**************file************
        let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    getImageLink: response.data,
                    getImageBoolean: true,
                    imageName: response.data
                })
            })
            .catch((error) => {
                console.log(error);
            })
    }


    saveHandler = (event) => {
        let error = MyValidation.defaultValidation(jsonForm, this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        } else {
            if (this.state.inputData.callStatus) {
                event.preventDefault();
                this.setState({
                    disabled: true
                });

                if (this.state.inputData.callStatus !== "Forward") {
                    this.anotherFromSubmit();
                    this.setState({
                        disabled: false
                    });
                } else {
                    let url = backEndServerURL + "/checkSameCallCategory";
                    let postData = {};
                    postData.existingCustomerCategory = this.state.customerCategory;
                    postData.existingCallCategory = this.state.callCategory;
                    postData.newCustomerCategory = this.state.inputData.customerCategory;
                    postData.newCallCategory = this.state.inputData.callCategory;
                    axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            if (response.data === "Matched") {
                                this.openErrorModel("Please change the call category or unlocked the request.");
                            } else {
                                this.ForwardFromSubmit();
                            }
                        }).catch((error) => {
                        this.setState({
                            disabled: false
                        });
                    });
                }
            }
        }
    };


    cancelHandler = (event) => {
        event.preventDefault();
        this.props.closeModal();
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    onKeyDownChange = (data) => {
        if (data.varName === "customerCategory" && this.state.inputData.customerCategory) {
            let cCategory = this.state.inputData.customerCategory === "VISA/Master" ? "visaMaster" : this.state.inputData.customerCategory === "Others" ? "other" : this.state.inputData.customerCategory.toLowerCase();
            let url = backEndServerURL + "/getCallCategory/" + cCategory;

            let autoComplete = {};
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        autoComplete.callCategory = response.data;

                        this.setState({
                            supportDocumentArray: response.data,
                            autoComplete: autoComplete,
                            getValue: true,
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                });

        }
    };

    ForwardFromSubmit() {
        this.state.inputData.serviceType = this.state.inputData.callCategory;
        this.state.inputData.freeFlag2 = this.state.inputData.callCategory;
        this.state.inputData.freeFlag4 = "Forwarded " + LocalstorageEncrypt.encryptStorageFunction.getItem('username');
        this.state.inputData.approval = "FORWARD";
        let remark_url = backEndServerURL + "/appRemarkSave/" + this.state.inputData.remarks + "/" + this.props.appId;
        axios.post(remark_url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.state.inputData.remarks = undefined;
                if (Object.values(this.state.fileUploadData).length < 1) {
                    this.functionForSLA()
                } else {

                    let uploadUrl = backEndServerURL + "/case/upload";
                    let formData = new FormData();
                    formData.append("appId", this.props.appId);
                    formData.append("file", this.state.fileUploadData['attachFile']);
                    formData.append("type", "file4");
                    axios({
                        method: 'post',
                        url: uploadUrl,
                        data: formData,
                        headers: {
                            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                            'content-type': 'multipart/form-data'
                        }
                    })
                        .then((response) => {
                            this.functionForSLA()
                        })
                        .catch((error) => {
                            console.log(error);
                        })

                }
            }).catch((error) => {
            console.log(error);
        })
    }


    functionForSLA = () => {
        let variablesTaskUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variablesTaskUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                let url = backEndServerURL + "/SetSlaValueFromCardCallCategory/" + this.props.appId;
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        //***************route task*******************
                        let routeTaskUrl = backEndServerURL + "/case/route/" + this.props.appId;
                        axios.get(routeTaskUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                if (response.status === 200) {
                                    this.setState({
                                        notificationType: "success",
                                        notificationTitle: "Success",
                                        notificationMessage: "Successfully sent to Forward TAB",
                                        alert: true,
                                        disabled: false
                                    });
                                    this.props.closeModal('submit');
                                }
                            }).catch((error) => {
                            this.setState({
                                disabled: false
                            });
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            notificationType: "success",
                            notificationTitle: "Success",
                            notificationMessage: "Failed to Reset SLA!",
                            alert: true
                        });
                    });

            }).catch((error) => {
            this.setState({
                disabled: false
            });
        })
    };

    anotherFromSubmit() {
        let inputData = {...this.state.inputData};
        switch (this.state.inputData.callStatus) {
            case "Cancel":
                inputData.approval = "CLOSE";
                break;
            case "Solved":
                inputData.approval = "SOLVED";
                break;
            case "Return":
                inputData.approval = "RETURN";
                break;
            default:
                inputData.approval = "INPROGRESS";
                break;
        }

        let variablesTaskUrl = backEndServerURL + "/variables/" + this.props.appId;
        let remark_url = backEndServerURL + "/appRemarkSave/" + inputData.remarks + "/" + this.props.appId;
        axios.post(remark_url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                inputData.remarks = undefined;
                axios.post(variablesTaskUrl, inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        if (this.state.inputData.callStatus === "Solved" && Object.values(this.state.fileUploadData).length > 0) {
                            let uploadUrl = backEndServerURL + "/case/upload";
                            let formData = new FormData();
                            formData.append("appId", this.props.appId);
                            formData.append("file", this.state.fileUploadData['attachFile']);
                            formData.append("type", "file4");
                            axios({
                                method: 'post',
                                url: uploadUrl,
                                data: formData,
                                headers: {
                                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                                    'content-type': 'multipart/form-data'
                                }
                            })
                                .then((response) => {

                                })
                                .catch((error) => {

                                })
                        }
                        let routeTaskUrl = backEndServerURL + "/case/route/" + this.props.appId;
                        axios.get(routeTaskUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                this.setState({
                                    notificationType: "success",
                                    notificationTitle: "Success",
                                    notificationMessage: "Successfully sent to " + this.state.inputData.callStatus + " TAB",
                                    alert: true
                                });
                                this.props.closeModal('submit');
                            }).catch((error) => {
                            this.setState({
                                disabled: false
                            });
                        })
                    }).catch((error) => {
                    this.setState({
                        disabled: false
                    });
                })
            })
            .catch((error) => {
                console.log(error);
            })

    }

    updateComponent = () => {
        this.forceUpdate();

    };

    open = (event) => {
        event.preventDefault();
        this.setState({
            imageModel: true
        })
    };

    closeModal = () => {
        this.setState({
            imageModel: false
        })
    };
    closeErrorModal = () => {
        this.setState({
            viewModal: false,
            disabled: false
        })
    };

    openErrorModel = (data) => {
        this.setState({
            message: data,
            viewModal: true,
        })
    };

    closeUserModal = () => {
        this.setState({
            userDetails: false
        })
    };

    renderUserDetails = () => {
        return (
            <Dialog
                fullWidth="true"
                maxWidth="md"
                open={this.state.userDetails}>
                <DialogContent>
                    <EmployeeDeatils closeModal={this.closeUserModal}
                                     closeIcon={this.closeUserModal}
                                     userId={this.state.userName}
                                     groupName={this.state.groupName}
                                     appId={this.props.appId}/>
                </DialogContent>
            </Dialog>
        )
    };


    render() {
        if (this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={theme}>
                        <div style={{maxWidth: "100%"}}>

                            <CircularProgress style={{marginLeft: '50%'}}/>

                        </div>
                    </MuiThemeProvider>
                </div>
            )
        } else if (this.props.headerValue === 'Locked' || this.props.headerValue === 'Progress') {
            return (
                <div>
                    <React.Fragment>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >Call Status<a><CloseIcon onClick={this.props.closeModal} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a></h4>
                            </CardHeader>
                            <CardBody>
                                <Grid container spacing={3}>
                                    <ThemeProvider theme={theme}>
                                        {this.dialogBoxShow()}
                                        {this.renderUserDetails()}
                                        {
                                            CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent, "", this.onKeyDownChange)
                                        }
                                        {this.renderFileShow()}
                                    </ThemeProvider>
                                </Grid>
                                {this.renderNotification()}
                                <Grid item xs='3' style={{marginTop: "20px"}}>
                                    {/*<center>*/}
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            verticalAlign: 'middle',
                                            marginRight: '10px',
                                            //marginLeft: '10px'
                                        }}
                                        disabled={this.state.disabled}
                                        onClick={this.saveHandler}
                                    >
                                        Submit
                                    </button>
                                    <button
                                        className="btn btn-grey btn-sm"
                                        style={{
                                            color: '#ffffff',
                                            background: '#808080',
                                            border: '2px solid #808080',
                                            verticalAlign: 'middle',
                                        }}
                                        disabled={this.state.disabled}
                                        onClick={this.cancelHandler}

                                    >
                                        Cancel
                                    </button>
                                    {/*</center>*/}
                                </Grid>


                            </CardBody>
                        </Card>
                    </React.Fragment>
                    <React.Fragment>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >Call Info</h4>
                            </CardHeader>
                            <CardBody>
                                <Grid container spacing={3}>
                                    <ThemeProvider theme={theme}>
                                        {this.renderUserDetails()}
                                        <table id='callInfo' style={{margin: "10px"}}>
                                            <tbody>

                                            {this.renderTableData()}
                                            </tbody>
                                        </table>

                                    </ThemeProvider>
                                </Grid>
                                <Grid item xs={12}>

                                    {this.renderRemarksData()}
                                    <br/>
                                    {this.renderTargetsData()}
                                    <br></br>
                                    {this.mappingPhoto()}
                                </Grid>

                            </CardBody>
                        </Card>
                    </React.Fragment>
                </div>
            )
        } else {
            return (
                <div>
                    <React.Fragment>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >Call Info <a><CloseIcon onClick={this.props.closeModal} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a></h4>
                            </CardHeader>
                            <CardBody>
                                <Grid container spacing={3}>
                                    <ThemeProvider theme={theme}>
                                        {this.renderUserDetails()}
                                        <table id='callInfo' style={{margin: "10px"}}>
                                            <tbody>

                                            {this.renderTableData()}
                                            </tbody>
                                        </table>

                                    </ThemeProvider>
                                </Grid>
                                {this.renderNotification()}
                                <Grid item xs={12}>

                                    {this.renderRemarksData()}
                                    {this.renderTargetsData()}
                                    <br></br>
                                    {this.mappingPhoto()}
                                </Grid>
                            </CardBody>
                        </Card>
                    </React.Fragment>
                </div>
            );
        }
    }

    renderFileShow() {
        if (this.state.inputData.callStatus === "Forward" || this.state.inputData.callStatus === "Solved") {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, jsonFormFile, this.updateComponent)
            )
        }
    }

    renderTableData() {
        if (this.state.getData) {
            return tableData.map((data, index) => {
                return (
                    <tr key={index}>
                        <td>
                            <td style={{fontSize: "15px", width: 250, fontWeight: "bold"}}>{data}</td>
                            <td style={{fontSize: "15px", fontWeight: "bold"}}>:</td>
                            <td style={{fontSize: "15px"}}>{this.state.secondColumnData[index]}</td>
                        </td>
                        <br/><br/>
                    </tr>
                )
            })
        }

    }


    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    };

    renderTargetsData = () => {
        if (this.state.getTargets.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Track Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Customer Category", "Call Category", "Open By", "Status", "Activity", "Starting Time", "Ending Time"]}
                            tableData={this.state.getTargets}
                            tableAllign={['left', 'left', 'left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    };


    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            //let i=0;
            return this.state.getImageLink.map((data, index) => {
                return (
                    <React.Fragment>
                        <Button target={"_blank"}
                                href={backEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                                style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    borderRadius: 3,
                                    border: 1,
                                    textDecoration: "none"
                                }} target={"_blank"}>Preview Document {index + 1}</Button>&nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };


    dialogBoxShow = () => {
        return (
            <div>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    open={this.state.viewModal}>
                    <DialogContent>
                        <ErrorModal title={this.state.message} closeModal={this.closeErrorModal}/>
                    </DialogContent>
                </Dialog>
            </div>
        )
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.notificationType} stopNotification={this.stopNotification}
                              title={this.state.notificationTitle}
                              message={this.state.notificationMessage}/>
            )
        }
    }
}

export default CardMaintenanceRequest;
