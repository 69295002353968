import React, {Component} from "react";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../JsonForm/CustomeTheme2";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import Notification from "../NotificationMessage/Notification";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {backEndServerURL} from "../../Common/Constant";
import MyValidation from "../JsonForm/MyValidation";
import axios from "axios";
import Functions from "../../Common/Functions";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";

let Form = [
    {
        "varName": "roleName",
        "type": "text",
        "label": "Role Name",
        "required": true,
        "grid": 2
    },
    {
        "varName": "roleDescription",
        "type": "text",
        "label": "Role Description",
        "required": true,
        "grid": 2
    },


];
let workplace = [
    {title: "BRANCH"},
    {title: "OPERATIONS"},
    {title: "CSU"},
];
let groupList = [];
let workplaceTypeList = [];


class RoleAdd extends Component {
    state = {
        inputData: {},
        selectedDate: {},
        getData: false,
        workstation: false,
        role: false,
        showValue: false,
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},

        err: false,
        varValue: [],
        errorArray: {},
        errorMessages: {},
        errorWorkPlace: false,
        errorgroup: false,
        title: "",
        notificationMessage: "",
        alert: false,
        getGroupListData: false,
        getworkplaceTypeListData: false,
        workplace: "",
        type: "success",
        submitButton: false,
    };
    emptyValueRemove = (data) => {
        if (data === '' || data === ' ' || data === "null" || data === "undefined")
            data = "";
        return data;
    };
    handleSubmit = (event) => {

        event.preventDefault();
        this.setState({
            loading: true
        });
        console.log(this.state.inputData);


        // let workplaceTypeArray = [{
        //     varName:"workplaceType",
        //     type:"text",
        //     required:true
        // }]
        let groupNameArray = [{
            varName: "groupName",
            type: "text",
            required: true
        }];

        let dependencyField = Form.concat(groupNameArray);
        let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);

        this.forceUpdate();
        // if(this.state.inputData.workplace === null){
        //     this.setState({
        //         errorWorkPlace:true,
        //     })
        // }
        // if(this.state.inputData.groupName === null){
        //     this.setState({
        //         errorgroup:true,
        //     })
        // }

        // this.forceUpdate();


        if (error === true || this.state.inputData.workplaceType === null || this.state.inputData.groupName === null) {
            console.log("Not working");
            this.setState({
                loading: false
            });
            return 0;
        }
        let url = backEndServerURL + "/role/add";
        let data = {};
        let authority = this.emptyValueRemove((this.state.inputData.roleName));
        let description = this.emptyValueRemove((this.state.inputData.roleDescription));
        // let groupName = this.emptyValueRemove((this.state.inputData.groupName.title));
        let groupName = "GDLC";
        // let workplaceType=this.emptyValueRemove((this.state.inputData.workplaceType.key))

        if ((authority !== undefined && authority !== "") && (description !== undefined && description !== "") && (groupName !== undefined && groupName !== "")) {
            data.authority = authority;
            data.description = description;
            data.description = description;
            data.groupName = groupName;
            // data.workplaceType=workplaceType;
            this.setState({
                loading:true,
            })

            axios.post(url, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status !== 'UNSUCCESSFUL') {
                        this.setState({
                            type: "success",
                            title: "Successfull!",
                            notificationMessage: "Successfully Added!",
                            alert: true,
                            loading:false,
                        });
                        setTimeout(function () {
                            window.location.reload()
                        }, 1000);
                    } else {
                        this.setState({
                            type: "warning",
                            title: "warning!",
                            notificationMessage: "role already exists!",
                            alert: true,
                            loading:false,
                        })
                    }

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        type: "warning",
                        title: "warning!",
                        notificationMessage: Functions.errorObjectCheck(error),
                        alert: true,
                        loading:false,
                    })
                })
        } else {
            this.setState({
                type: "error",
                title: "Error!",
                notificationMessage: error.massage,
                alert: true
            });
            return 0;
        }

    };
    renderButton = () => {
// if(this.state.submitButton){
        return (

            <button
                className="btn btn-outline-primary  btn-sm"
                style={{
                    marginTop: "18px"
                }}
                onClick={this.handleSubmit}

            >
                Add Role
            </button>


        )
        // }

    };

    componentDidMount() {
        this.setState({
            submitButton: false,
        });

        this.handleChangeWorkPlace();
        // let groupUrl = backEndServerURL + "/workplaceType/getAll";
        //     axios.get(groupUrl, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
        //         .then((response) => {
        //             console.log(response.data)

        //             response.data.map((data) => {
        //                 workplaceTypeList.push({
        //                     title: data.value,
        //                     key: data.key
        //                 })
        //             })
        //               this.setState({
        //                   getworkplaceTypeListData: true,
        //               })

        //             console.log(response.data)
        //         })
        //         .catch((error) => {
        //             console.log(error)
        //         })

    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderJsonForm = () => {
        return (
            CommonJsonFormComponent.renderJsonForm(this.state, Form, this.updateComponent)
        )

    };
    handleChangeWorkPlace = () => {
        // this.state.inputData.workplaceType=option
        this.state.inputData.groupName = "GDLC";
        groupList = [];
        this.setState({
            getGroupListData: false
        });

        let groupUrl = backEndServerURL + "/workflow/group/all";
        axios.get(groupUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((data, index) => {
                    groupList.push({
                        title: "GDLC",
                        key: "GDLC"
                    })
                });

                this.setState({
                    getGroupListData: true,
                    showValue: true,
                });

                console.log(response.data)
            })
            .catch((error) => {
                console.log(error)
            })

        // this.setState({
        //     getData: true,
        //     showValue: true,
        //     workplace:option.title
        // })


    };

/*    handleChangeGroup = (event, option) => {
        event.preventDefault();
        this.state.inputData.groupName = option;
        if (option != null) {

            this.setState({
                submitButton: true,
            });
        }


    };*/

    // renderGroupListJsonForm = () => {
    //      if ((this.state.getGroupListData || this.state.getworkplaceTypeListData) && (this.state.workplace==="BRANCH" || this.state.workplace==="OPERATIONS" || this.state.workplace==="CSU")) {

    //          return (
    //             CommonJsonFormComponent.renderJsonForm(this.state, groupList.concat(workplaceTypeList), this.updateComponent)
    //         )
    //     }
    // }
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.type} stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h6>Edit Role<a><CloseIcon onClick={this.close} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h6>
            )
        } else {
            return (
                <h4 className="text-white">Role Creation</h4>
            )
        }
    };
    renderWorkplaceType = () => {
        if (this.state.getworkplaceTypeListData) {
            return (
                <Grid item xs={2}>
                    <React.Fragment>
                        <Grid item>
                            <label className="input-label-common">Workplace Type</label>
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                helperText={this.state.errorMessages["workplaceType"]}
                                error={this.state.errorArray["workplaceType"]}
                                required={this.state.inputData.workplaceType}
                                onChange={(event, option) => this.handleChangeWorkPlace(event, option)}
                                //defaultValue={this.state.inputData["numberOfNominee"]}
                                options={workplaceTypeList} getOptionLabel={option => option.title}
                                //style={ field.readOnly===true?{paddingRight: 20}:{paddingRight: 20}}
                                style={{paddingRight: 20}}
                                //style={ {paddingRight: 20}}
                                renderInput={(params) => /*<TextField {...params} variant="outlined"

                                                                      style={{paddingRight: 20}} fullWidth/>}*/

                                    <TextField {...params}
                                               variant="outlined"
                                               error={this.state.errorArray !== undefined && this.state.errorArray["workplaceType"]}
                                               helperText={this.state.errorArray["workplaceType"] === true ? this.state.errorMessages["workplaceType"] : ""}
                                               fullWidth/>}
                            />


                        </Grid>
                    </React.Fragment>
                </Grid>
            )
        }
    };

/*
    renderGroupList = () => {
        if (this.state.getGroupListData) {
            return (
                <Grid item xs={3}>
                    <React.Fragment>
                        <Grid item>
                            <label className="input-label-common">Department</label>
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                helperText={this.state.errorMessages["groupName"]}
                                error={this.state.errorArray["groupName"]}
                                required={this.state.inputData.groupName}
                                onChange={(event, option) => this.handleChangeGroup(event, option)}
                                //defaultValue={this.state.inputData["numberOfNominee"]}
                                options={groupList} getOptionLabel={option => option.title}
                                //style={ field.readOnly===true?{paddingRight: 20}:{paddingRight: 20}}
                                style={{paddingRight: 20}}
                                //style={ {paddingRight: 20}}
                                renderInput={(params) => /!*<TextField {...params} variant="outlined"

                                                                  style={{paddingRight: 20}} fullWidth/>}*!/

                                    <TextField {...params}
                                               variant="outlined"
                                               error={this.state.errorArray !== undefined && this.state.errorArray["groupName"]}
                                               helperText={this.state.errorArray["groupName"] === true ? this.state.errorMessages["groupName"] : ""}
                                               fullWidth/>}
                            />


                        </Grid>
                    </React.Fragment>
                </Grid>
            )
        }
    };
*/

    render() {
        return (

            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderHeader()}

                </CardHeader>
                <CardBody>
                    <div>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            open={this.state.loading}>
                            <DialogContent>

                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>

                        <ThemeProvider theme={theme}>

                            <Grid container>

                                {this.renderNotification()}


                                {this.renderJsonForm()}
                                {/* {this.renderWorkplaceType()} */}
                                {/*{this.renderGroupList()}*/}
                                {/* {this.renderGroupListJsonForm()} */}


                                <Grid item xs={12}>
                                    {this.renderButton()}
                                </Grid>

                            </Grid>

                        </ThemeProvider>


                    </div>


                </CardBody>
            </Card>


        )
    }

}

export default RoleAdd;