import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import {Menu} from 'semantic-ui-react';
import 'semantic-ui-offline/semantic.min.css';
import Button from "@material/react-button";
import MaterialTable from "material-table";
import {createMuiTheme} from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import axiosDownload from '../FunctionForFileDownload';
import TablePagination from "@material-ui/core/TablePagination";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import {buttonStyle, localization, options, tabActive, tabInactive, tableIcons} from "../configuration";
import Notification from "../../NotificationMessage/Notification";
import MailNotification from "../../workflow/CommonComponent/MailNotification";
import FailedListModal from "../WelcomeLetter/FailedListModal";
import ConfirmationModal from "../../workflow/FundTransfer/ConfirmationModal";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '5px',
                fontSize: '0.8rem'
            }
        },

    }
});

let SearchForm = [
    {
        varName: "fromDate",
        type: "date",
        label: "From Date",
        grid: 2,
    },
    {
        varName: "toDate",
        type: "date",
        label: "To Date",
        grid: 2,
    },
    {
        varName: "process_type",
        type: "select",
        label: "Process Type",
        grid: 2,
        enum: [
            "Pay Order",
            "FDD",
        ]
    },
    {
        varName: "type",
        type: "select",
        label: "Source Type",
        grid: 2,
        enum: [
            "FINACLE",
            "ABABIL",
        ]
    },


    {
        varName: "accountNumber",
        type: "text",
        label: "Account Number",
        grid: 2,
    },

    {
        varName: "currency",
        type: "autoCompleteValueReturn",
        label: "Currency",
        enum: [],
        grid: 2
    },
    {
        varName: "branch",
        type: "autoCompleteValueReturn",
        label: "Branch",
        grid: 2,
    },

    {
        varName: "chequeBookType",
        type: "select",
        label: "Cheque Book Type",
        grid: 2,
        enum: ["NORMAL", "A4"],
    },
];


class ChecqueBookManage extends Component {
    state = {activeItem: "home", backgroundColor: "red"};

    constructor(props) {
        super(props);
        this.state = {
            appData: "",
            err: false,
            rowIdArray: [],
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            searchItem: "",
            getData: true,
            inputData: {},
            autoComplete: {},
            fileUploadData: {},
            responseData: [],
            activeItem: "All",
            backgroundColor: "red",
            showValue: false,
            uploadModal: false,
            merchantModal: false,
            caseId: "",
            mailModal: false,
            selectedRowIdArray: [],
            title: "",
            dataPassArray: [],
            buttonName: "Claim",
            notificationMessage: "",
            actionStatus: "",
            alert: false,
            showData: true,
            pageNumber: 0,
            totalRow: 0,
            loading: false,
            ababil: false,
            finacle: false,
            failedList: [],
            failedModal: false,
            confirmationAlert: false,
            rowsPerPage: 100,
            selectedCount: 0,
            allData: [],
            loader: true,
            columns: [
                {title: "Case ID", field: "caseId", export: false},
             //   {title: "Account Number", field: "accountNumber", export: false},
              //  {title: "Customer Name", field: "customerName", removable: false},
              //  {title: "Account Status", field: "accountStatus"},
              //  {title: "Account Type", field: "accountType"},
                {title: "Number of Chequebook", field: "numberOfChequebook"},
                {title: "Page of Chequebook", field: "pageofChequebook"},
                {title: "Source", field: "source"},
                {title: "Batch No", field: "batchNo"},
                {title: "Status", field: "status"},
               // {title: "Cheque Book Type", field: "chequeBookType"},
                {title: "Requisition Branch", field: "requisitionBranch"},
            //    {title: "DeliveryType", field: "deliveryType"},
            //    {title: "Delivery Branch", field: "deliveryBranch"},
                {title: "Date", field: "bomApprovalDate"}
            ],
            newColumns: [
                {title: "Case ID", field: "caseId", export: false},
               // {title: "Account Number", field: "accountNumber", export: false},
               // {title: "Customer Name", field: "customerName", removable: false},
              //  {title: "Account Status", field: "accountStatus"},
             //   {title: "Account Type", field: "accountType"},
                {title: "Number of Chequebook", field: "numberOfChequebook"},
                {title: "Page of Chequebook", field: "pageofChequebook"},
                {title: "Source", field: "source"},
                {title: "Status", field: "status"},
              //  {title: "Cheque Book Type", field: "chequeBookType"},
                {title: "Requisition Branch", field: "requisitionBranch"},
            //    {title: "DeliveryType", field: "deliveryType"},
             //   {title: "Delivery Branch", field: "deliveryBranch"},
                {title: "Date", field: "bomApprovalDate"}
            ],
        };
    }

    handleItemClick = (e, {name, style}) =>
        this.setState({
            activeItem: name,
            backgroundColor: style
        });

    headerSelect = (event, value) => {
        event.preventDefault();
        this.setState({
            loader: true,
            pageNumber: 0
        });
        console.log(value);
        if (value === "All") {
            this.fetchDataFromAPI('All', 'getAllFddPayOrder/page/0')
        } else {
            this.fetchDataFromAPI(value)
        }
    };

    dateConverter = (value) => {

        let dateString = value.substr(0, 10);
        return dateString
    };

    getBranchAll() {
        let branch = [];
        let branchUrl = backEndServerURL + "/workplaceUnit/getInitialUnits";
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((item) => {
                    branch.push({key: item.solId, value: item.name})
                });
                this.setState({
                    branch: branch
                });
                for (let form of SearchForm) {
                    if (form.type === "autoCompleteValueReturn" && form.varName === "branch") {
                        form.enum = branch
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    componentDidMount() {
        //inbox call

        this.dynamicApiCall("currency");

        this.getBranchAll();

        let inbox_url = backEndServerURL + "/getAllFddPayOrder/page/0";
        axios
            .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                console.log(response);
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].appId;
                    rowData = {
                        id: response.data.content[i].id,
                        accountNumber: response.data.content[i].accountNumber,
                        customerName: response.data.content[i].customerName,
                        accountStatus: response.data.content[i].accountStatus,
                        accountType: response.data.content[i].accountType,
                        pageofChequebook: response.data.content[i].pageofChequebook,
                        numberOfChequebook: response.data.content[i].numberOfChequeBook,
                        batchNo: response.data.content[i].batchNo,
                        source: response.data.content[i].requestType,
                        status: response.data.content[i].status,
                        chequeBookType: response.data.content[i].chequebookType,
                        requisitionBranch: response.data.content[i].requisitionBranch,
                        deliveryType: response.data.content[i].deliveryType,
                        deliveryBranch: response.data.content[i].deliveryBranch,
                        bomApprovalDate: this.dateConverter(response.data.content[i].modifiedDate),
                        caseId: response.data.content[i].appId
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    totalRow: response.data.totalElements,
                    loader: false
                });
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
            });
    }

    dynamicApiCall = (apiType) => {
        let branchUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                for (let form of SearchForm) {
                    // if (form.type === "autoCompleteValueReturn" && form.varName === "branch" && apiType === "branch") {
                    //     form.enum = response.data
                    // }
                    if (form.type === "autoCompleteValueReturn" && form.varName === "currency" && apiType === "currency") {
                        form.enum = response.data
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    open = data => {
        this.setState({
            dataPassArray: data,
            merchantModal: true
        });
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === undefined)
                delete clone[prop];
        return clone;
    };


    searchData = (e, page, status) => {

        let input = {...this.state.inputData};
        input.currency = this.state.inputData.currency ? this.state.inputData.currency.value : "";
        input.branch = this.state.inputData.branch ? this.state.inputData.branch.value : "";
        let postData = this.removeNullValue(input);
        this.forceUpdate();

        if (Object.keys(postData).length === 0) {
            return 0;
        }

        if (status === "All") {
            this.setState({
                loader: true,
                pageNumber: page,
                activeItem: "All"
            });
        } else {
            this.setState({
                loader: true,
                pageNumber: page,
                activeItem: status
            });
        }

        let inbox_url = backEndServerURL + "/filterFddPayOrderDateCustomerAccountBranch/" + status + "/" + 0;

        axios
            .post(inbox_url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].appId;
                    rowData = {
                        id: response.data.content[i].id,
                        accountNumber: response.data.content[i].accountNumber,
                        customerName: response.data.content[i].customerName,
                        accountStatus: response.data.content[i].accountStatus,
                        accountType: response.data.content[i].accountType,
                        pageofChequebook: response.data.content[i].pageofChequebook,
                        numberOfChequebook: response.data.content[i].numberOfChequeBook,
                        batchNo: response.data.content[i].batchNo,
                        source: response.data.content[i].requestType,
                        status: response.data.content[i].status,
                        chequeBookType: response.data.content[i].chequebookType,
                        requisitionBranch: response.data.content[i].requisitionBranch,
                        deliveryType: response.data.content[i].deliveryType,
                        deliveryBranch: response.data.content[i].deliveryBranch,
                        bomApprovalDate: this.dateConverter(response.data.content[i].modifiedDate),
                        caseId: response.data.content[i].appId,
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    totalRow: response.data.totalElements,
                    loading: false,
                    loader: false,
                    searchItem: this.state.activeItem,
                });
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false,
                    loader: false,
                })
            });
    };

    resetHandler = () => {
        window.location.reload();
    };

    closeMailModal = (value) => {
        if (value === "cancel") {
            this.setState({
                mailModal: false
            })
        } else {
            this.setState({
                mailModal: false,
                loader: true
            });
            let postData = value;
            postData.idList = this.state.selectedRowIdArray;
            let mailUrl = backEndServerURL + "/fddPayOrderSendToCourier/" + this.state.actionStatus;
            axios
                .post(mailUrl, postData, {
                    headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                })
                .then(response => {
                    if (response.status === 200) {
                        this.functionForNotificationMessage("Successful!", "Mail Send Successful");
                        let download_url = backEndServerURL + "/getFddPayOrderExcelResource/" + this.state.actionStatus;
                        axiosDownload.downloadFile(download_url, 'POST', this.state.selectedRowIdArray, 'cheque-book.xlsx').then((result) => {
                            if (result) {
                                this.fetchDataFromAPI(this.state.activeItem);
                            }
                        }).catch((error) => {
                            this.fetchDataFromAPI(this.state.activeItem);
                        })
                    }
                })
                .catch(error => {
                    this.functionForNotificationMessage("Failed!", "Mail Send Failed");
                    console.log(error);
                    this.setState({
                        loader: false
                    });
                });
        }
    };


    mailNotification = () => {
        const {classes} = this.props;
        return (
            <Dialog
                fullWidth="true"
                maxWidth="md"
                className={classes.modal}
                classes={{paper: classes.dialogPaper}}
                open={this.state.mailModal}>
                <DialogContent className={classes.dialogPaper}>
                    <MailNotification closeModal={this.closeMailModal} processName="CHEQUE BOOK"/>
                </DialogContent>
            </Dialog>
        )
    };

    closeFailedModal = () => {
        this.setState({
            failedList: [],
            failedModal: false
        })
    };

    failedListModal = () => {
        const {classes} = this.props;
        return (
            <Dialog
                fullWidth="true"
                maxWidth="md"
                className={classes.modal}
                classes={{paper: classes.dialogPaper}}
                open={this.state.failedModal}>
                <DialogContent className={classes.dialogPaper}>
                    <FailedListModal titleHeader="Cheque Book Number Generate Failed List"
                                     closeModal={this.closeFailedModal}
                                     failedList={this.state.failedList}/>
                </DialogContent>
            </Dialog>
        )
    };

    render() {
        const {activeItem} = this.state;
        const {classes} = this.props;
        if (this.state.loading) {

            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >
                                Search FDD Payorder
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        SearchForm,
                                        this.updateComponent
                                    )}
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            height: 30,
                                            marginTop: 20,
                                        }}
                                        onClick={e => {
                                            this.searchData(e, 0, 'All')
                                        }}
                                    >
                                        Search
                                    </button>
                                    &nbsp;
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            height: 30,
                                            marginTop: 20,
                                        }}
                                        onClick={this.resetHandler}
                                    >
                                        Reset
                                    </button>
                                </ThemeProvider>
                            </Grid>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Deliverable Management (FDD Payorder)</h4>
                        </CardHeader>
                        <CardBody>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.confirmationAlert}>
                                <DialogContent className={classes.dialogPaper}>
                                    <ConfirmationModal
                                        closeModal={this.closeConfirmation}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            {this.mailNotification()}
                            {this.failedListModal()}
                            <div className="deliverable">
                                <React.Fragment>
                                    <div>
                                        <Menu pointing style={{overflowX: 'auto', overflowY: 'hidden'}}>
                                            <Menu.Item
                                                name="All"
                                                active={activeItem === "All"}
                                                style={
                                                    activeItem === "All"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "All");
                                                }}
                                            />
                                            <Menu.Item
                                                name="New"
                                                active={activeItem === "New"}
                                                style={
                                                    activeItem === "New"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "New");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Cancel"
                                                active={activeItem === "Cancel"}
                                                style={
                                                    activeItem === "Cancel"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Cancel");
                                                }}
                                            />

                                            <Menu.Item
                                                name="Failed"
                                                active={activeItem === "Failed"}
                                                style={
                                                    activeItem === "Failed"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Failed");
                                                }}
                                            />

                                            <Menu.Item
                                                name="Series Generated"
                                                active={activeItem === "Series Generated"}
                                                style={
                                                    activeItem === "Series Generated"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Series Generated");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Send to Production"
                                                active={activeItem === "Send to Production"}
                                                style={
                                                    activeItem === "Send to Production"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Send to Production");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Send to Courier"
                                                active={activeItem === "Send to Courier"}
                                                style={
                                                    activeItem === "Send to Courier"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Send to Courier");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Returned"
                                                active={activeItem === "Returned"}
                                                style={
                                                    activeItem === "Returned"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Returned");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Deliver to Branch"
                                                active={activeItem === "Deliver to Branch"}
                                                style={
                                                    activeItem === "Deliver to Branch"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Deliver to Branch");
                                                }}
                                            />

                                            <Menu.Item
                                                name="Branch Received"
                                                active={activeItem === "Branch Received"}
                                                style={
                                                    activeItem === "Branch Received"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Branch Received");
                                                }}
                                            />

                                            {/*<Menu.Item*/}
                                            {/*    name="Delivered"*/}
                                            {/*    active={activeItem === "Delivered to Customer"}*/}
                                            {/*    style={*/}
                                            {/*        activeItem === "Delivered to Customer"*/}
                                            {/*            ? tabActive*/}
                                            {/*            : tabInactive*/}
                                            {/*    }*/}
                                            {/*    onClick={e => {*/}
                                            {/*        this.headerSelect(e, "Delivered to Customer");*/}
                                            {/*    }}*/}
                                            {/*/>*/}
                                            {/*<Menu.Item*/}
                                            {/*    name="Destroy Request"*/}
                                            {/*    active={activeItem === "Destroy Request"}*/}
                                            {/*    style={*/}
                                            {/*        activeItem === "Destroy Request"*/}
                                            {/*            ? tabActive*/}
                                            {/*            : tabInactive*/}
                                            {/*    }*/}
                                            {/*    onClick={e => {*/}
                                            {/*        this.headerSelect(e, "Destroy Request");*/}
                                            {/*    }}*/}
                                            {/*/>*/}
                                            {/*<Menu.Item*/}
                                            {/*    name="Destroy"*/}
                                            {/*    active={activeItem === "Destroy"}*/}
                                            {/*    style={*/}
                                            {/*        activeItem === "Destroy"*/}
                                            {/*            ? tabActive*/}
                                            {/*            : tabInactive*/}
                                            {/*    }*/}
                                            {/*    onClick={e => {*/}
                                            {/*        this.headerSelect(e, "Destroy");*/}
                                            {/*    }}*/}
                                            {/*/>*/}
                                        </Menu>

                                        {this.tableChoice()}
                                    </div>
                                </React.Fragment>
                            </div>
                            {
                                this.renderNotification()
                            }
                        </CardBody>
                    </Card>
                </>
            );
        }

    }

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    fetchDataFromAPI = (status, url) => {

        if (this.state.searchItem !== "") {
            this.searchData(this, this.state.pageNumber, status);
        } else {

            this.setState({
                activeItem: status,
                loader: true
            });


            let urlSegment;
            let inbox_url;
            if (url === undefined) {
                urlSegment = 'getFddPayOrderstatus';
            } else {
                urlSegment = url;
            }

            if (status === 'All') {
                inbox_url = backEndServerURL + "/" + urlSegment;
            } else {
                inbox_url = backEndServerURL + "/" + urlSegment + "/" + status + "/0";
            }
            axios
                .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    console.log(response);
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        rowData = {
                            id: response.data.content[i].id,
                            accountNumber: response.data.content[i].accountNumber,
                            customerName: response.data.content[i].customerName,
                            accountStatus: response.data.content[i].accountStatus,
                            accountType: response.data.content[i].accountType,
                            pageofChequebook: response.data.content[i].pageofChequebook,
                            numberOfChequebook: response.data.content[i].numberOfChequeBook,
                            batchNo: response.data.content[i].batchNo,
                            source: response.data.content[i].requestType,
                            status: response.data.content[i].status,
                            chequeBookType: response.data.content[i].chequebookType,
                            requisitionBranch: response.data.content[i].requisitionBranch,
                            deliveryType: response.data.content[i].deliveryType,
                            deliveryBranch: response.data.content[i].deliveryBranch,
                            bomApprovalDate: this.dateConverter(response.data.content[i].modifiedDate),
                            caseId: response.data.content[i].appId,
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData);
                    }

                    this.setState({
                        allData: array,
                        responseData: responseArray,
                        totalRow: response.data.totalElements,
                        loader: false
                    });
                    console.log(this.state.allData);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    handleChangePage = (pageNumber, status) => {
        let page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        if (this.state.searchItem !== "") {
            this.searchData(this, page, status);
        } else {
            let inbox_url;
            if (this.state.activeItem === status) {
                if (status === 'All') {
                    inbox_url = backEndServerURL + "/getAllFddPayOrder/page/" + page;
                } else {
                    inbox_url = backEndServerURL + "/getFddPayOrderstatus/" + status + "/" + page;
                }
                axios
                    .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => {
                        let array = [];
                        let responseArray = [];
                        let rowData;
                        let responseRowData;
                        for (let i = 0; i < response.data.content.length; i++) {
                            responseRowData = response.data.content[i].appId;
                            rowData = {
                                id: response.data.content[i].id,
                                accountNumber: response.data.content[i].accountNumber,
                                customerName: response.data.content[i].customerName,
                                accountStatus: response.data.content[i].accountStatus,
                                accountType: response.data.content[i].accountType,
                                pageofChequebook: response.data.content[i].pageofChequebook,
                                numberOfChequebook: response.data.content[i].numberOfChequeBook,
                                batchNo: response.data.content[i].batchNo,
                                source: response.data.content[i].requestType,
                                status: response.data.content[i].status,
                                chequeBookType: response.data.content[i].chequebookType,
                                requisitionBranch: response.data.content[i].requisitionBranch,
                                deliveryType: response.data.content[i].deliveryType,
                                deliveryBranch: response.data.content[i].deliveryBranch,
                                bomApprovalDate: this.dateConverter(response.data.content[i].modifiedDate),
                                caseId: response.data.content[i].appId,
                            };
                            array.push(rowData);
                            responseArray.push(responseRowData);
                        }

                        this.setState({
                            allData: array,
                            responseData: responseArray,
                            activeItem: 'All',
                            pageNumber: page,
                            totalRow: response.data.totalElements,
                        });
                        console.log(this.state.allData);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }


    };

    actionForSendToCourierForDownload = (event, rowData, props) => {
        if (this.state.activeItem === "Send to Courier") {
            this.setState({
                loader: true
            });
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let download_url =
                    backEndServerURL +
                    "/getFddPayOrderExcelResource/Send to Courier";

                axiosDownload.downloadFile(download_url, 'POST', rowIdArray, 'cheque-book.xlsx').then((response) => {
                    this.setState({
                        loader: false
                    })
                }).catch((err) => {
                    console.log(err);
                    this.setState({
                        loader: false
                    })
                })

            }
        }
    };

    functionForNotificationMessage = (title, message) => {
        this.setState({
            title: title,
            notificationMessage: message,
            alert: true,
        })

    };

    actionForSendToCourierToDeliverdToBranch = (event, rowData, props) => {
        if (this.state.activeItem === "Send to Courier") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    //         console.log(allSelectValue[i].tableData.id)
                    rowIdArray.push(allSelectValue[i].id);
                }
                console.log("Something:" + rowIdArray);

                let single_url =
                    backEndServerURL +
                    "/updateFddPayOrderStatus/Deliver to Branch";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            this.functionForNotificationMessage("Successful!", "Status Change Successful");
                            this.fetchDataFromAPI('Send to Courier')
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
        }
    };

    actionForNewToProcced = (event, rowData, props) => {
        if (this.state.activeItem === "New") {
            this.setState({
                loader: true
            });
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;

            let finacleArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    finacleArray.push(allSelectValue[i].id);
                }

                let single_url = backEndServerURL + "/generateFddPayOrderSeries";
                axios
                    .post(single_url, [...new Set(finacleArray)], {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            if (response.status === 200 && response.data.failedAccountNumberList.length > 0) {
                                this.setState({
                                    failedList: response.data.failedAccountNumberList,
                                    failedModal: true,
                                })
                            }
                            if (response.status === 200 && response.data.successful > 0) {
                                this.functionForNotificationMessage("Successful!", `${response.data.successful} Status Update Successful and ${response.data.failed} Status Update Failed`);
                            }
                            this.fetchDataFromAPI('New')
                        }
                    })
                    .catch(error => {
                        this.functionForNotificationMessage('Failed!', "Uplaod Failed");
                        console.log(error);
                        this.setState({
                            loader: false
                        })
                    })
            }
        }
    };

    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                confirmationAlert: false,
                loader: true,
            });
            let single_url = backEndServerURL + "/updateFddPayOrderStatus/Cancel";
            axios
                .post(single_url, this.state.rowIdArray, {
                    headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                })
                .then(response => {
                    if (response.status === 200) {
                        this.setState({
                            rowIdArray: [],
                        });
                        this.functionForNotificationMessage("Successful!", "Status Change Successful");
                        this.fetchDataFromAPI('New')
                    }
                })
                .catch(error => {
                    console.log(error);
                })

        } else {
            this.setState({
                rowIdArray: [],
                confirmationAlert: false
            })
        }
    };

    actionForNewToCancel = (event, rowData, props) => {
        if (this.state.activeItem === "New") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                this.setState({
                    rowIdArray: rowIdArray,
                    title: "Do you want to cancel?",
                    confirmationAlert: true,
                });
            }
        }
    };

    actionForFinacleToCourier = (event, rowData, props, status) => {
        if (this.state.activeItem === "Series Generated" || this.state.activeItem === "Send to Production") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            this.setState({
                mailModal: true
            });
            let rowIdArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                this.setState({
                    selectedRowIdArray: rowIdArray,
                    actionStatus: status
                })
            }
        }
    };


    actionForReturnToDeliverToBranch = (event, rowData, props) => {
        if (this.state.activeItem === "Returned") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url = backEndServerURL + "/updateFddPayOrderStatus/Deliver to Branch";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            this.functionForNotificationMessage("Successful!", "Status Change Successful");
                            this.fetchDataFromAPI('Returned')
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForReturnToDestroy = (event, rowData, props) => {
        if (this.state.activeItem === "Returned") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    //         console.log(allSelectValue[i].tableData.id)
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url =
                    backEndServerURL +
                    "/updateFddPayOrderStatus/Destroy";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            this.functionForNotificationMessage("Successful!", "Status Change Successful");
                            this.fetchDataFromAPI('Returned')
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForDeliverToBranchToBranchReceived = (event, rowData, props) => {
        if (this.state.activeItem === "Deliver to Branch") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url =
                    backEndServerURL +
                    "/updateFddPayOrderStatus/Branch Received";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            this.functionForNotificationMessage("Successful!", "Status Change Successful");
                            this.fetchDataFromAPI('Deliver to Branch')
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForBranchReceivedToDeliver = (event, rowData, props) => {
        if (this.state.activeItem === "Branch Received") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;

            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url = backEndServerURL + "/updateFddPayOrderStatus/Delivered to Customer";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            this.functionForNotificationMessage("Successful!", "Status Change Successful");
                            this.fetchDataFromAPI('Branch Received')
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForBranchReceivedToDestroyRequest = (event, rowData, props) => {
        if (this.state.activeItem === "Branch Received") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;

            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url = backEndServerURL + "/updateFddPayOrderStatus/Destroy Request";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            this.functionForNotificationMessage("Successful!", "Status Change Successful");
                            this.fetchDataFromAPI('Branch Received')
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForDestroyRequestToDestroy = (event, rowData, props) => {
        if (this.state.activeItem === "Destroy Request") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;

            let ababilArray = [];
            let finacleArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    if (allSelectValue[i].source === "ABABIL") {
                        ababilArray.push(allSelectValue[i].id);
                    } else if (allSelectValue[i].source === "FINACLE") {
                        finacleArray.push(allSelectValue[i].id);
                    }
                }

                if (finacleArray.length > 0) {
                    let stop_cheque_book =
                        backEndServerURL +
                        "/stopchequenumber/";

                    axios
                        .post(stop_cheque_book, finacleArray, {
                            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                        })
                        .then(response => {
                            if (response.status === 200) {
                                this.functionForNotificationMessage("Successful!", "Cheque Book Destroy Successful");
                            }
                        })
                        .catch(error => {
                            this.functionForNotificationMessage("Failed!", "Cheque Book Destroy Failed");
                            console.log(error);
                        });
                }
                if (ababilArray.length > 0) {
                    let single_url = backEndServerURL + "/updateFddPayOrderStatus/Destroy";

                    axios
                        .post(single_url, ababilArray, {
                            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                        })
                        .then(response => {
                            if (response.status === 200) {
                                this.functionForNotificationMessage("Successful!", "Cheque Book Destroy Successful");
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
                this.fetchDataFromAPI('Destroy Request')

            }
        }
    };

    actionForDestroyRequestToDelivered = (event, rowData, props) => {
        if (this.state.activeItem === "Destroy Request") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url =
                    backEndServerURL +
                    "/updateFddPayOrderStatus/Delivered to Customer";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            this.functionForNotificationMessage("Successful!", "Status Change Successful");
                            this.fetchDataFromAPI('Destroy Request')
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    tableChoice() {
        const {classes} = this.props;
        if (this.state.loader) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loader}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else if (this.state.activeItem === "All" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'All')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "New" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.newColumns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'New')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForNewToProcced(event, rowData, props)}
                                            >
                                                Proceed
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForNewToCancel(event, rowData, props)}
                                            >
                                                Cancel
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "Failed" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Failed')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}

                                            >
                                                Proceed
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "Series Generated" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Series Generated')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForFinacleToCourier(event, rowData, props, 'Send to Production')}
                                            >
                                                Sent to Production
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "Send to Production" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Send to Production')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForFinacleToCourier(event, rowData, props, 'Send to Courier')}
                                            >
                                                Sent to Courier
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "Send to Courier" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Send to Courier')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForSendToCourierForDownload(event, rowData, props)}
                                            >
                                                Download Excel
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForSendToCourierToDeliverdToBranch(event, rowData, props)}
                                            >
                                                Delivered to Branch
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "Returned" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Returned')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForReturnToDeliverToBranch(event, rowData, props)}
                                            >
                                                Deliver to Branch
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForReturnToDestroy(event, rowData, props)}
                                            >
                                                Destroy
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Deliver to Branch" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Deliver to Branch')
                                            }
                                            }
                                        />
                                    ),
                                    // Actions: props => (
                                    //     <React.Fragment>
                                    //         &nbsp;
                                    //         <Button
                                    //             style={buttonStyle}
                                    //             onClick={(event, rowData) => this.actionForDeliverToBranchToBranchReceived(event, rowData, props)}
                                    //         >
                                    //             Branch Received
                                    //         </Button>
                                    //
                                    //     </React.Fragment>
                                    // )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Branch Received" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Branch Received')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForBranchReceivedToDeliver(event, rowData, props)}
                                            >
                                                Delivered
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForBranchReceivedToDestroyRequest(event, rowData, props)}
                                            >
                                                Destroy Request
                                            </Button>

                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Destroy Request" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Destroy Request')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForDestroyRequestToDestroy(event, rowData, props)}
                                            >
                                                Destroy
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForDestroyRequestToDelivered(event, rowData, props)}
                                            >
                                                Delivered
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                icons={tableIcons}
                                options={options}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        }
    }
}

export default withStyles(styles)(ChecqueBookManage);
