import React, {Component} from 'react';
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from 'axios';
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import 'semantic-ui-offline/semantic.min.css';
import {createMuiTheme} from '@material-ui/core/styles';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../Grid/GridItem";
import GridContainer from "../../Grid/GridContainer";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import loader from "../../../Static/loader.gif";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};
const jsonForm = [
    // {
    //     "varName": "fileName",
    //     "type": "file",
    //     "accept": ".pdf",
    //     "label": "Upload PDF File",
    // },
    {
        "varName": "fileName",
        "type": "file",
        "accept": ".xlsx,.xls",
        "label": "Upload Excel File",
    },
];

const theme = createMuiTheme({
    palette: {
        secondary: {
            main: '#37b159',
            light: '#37b159',
            dark: '#37b159',
        },
    },

});

const table_style = {
    row: {
        border: "none",
        height: 10,
        "&:nth-of-type(odd)": {
            backgroundColor: "red"
        }
    },
};


class EFTNExcelUpload extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            inputData: {},
            fileUploadData: {},
            responseData: [],
            backgroundColor: 'red',
            showValue: true,
            uploadModal: false,
            merchantModal: false,
            caseId: "",
            title: "",
            err: false,
            errorArray: {},
            errorMessages: {},
            dataPassArray: [],
            notificationMessage: "",
            alert: false,
            showData: true,
            type: "",
        };
    }

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.type} stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }

    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    getSubmitedForm = () => {
        this.setState({
            loading: true
        });
        if (this.state.fileUploadData.fileName === undefined) {
            this.setState({
                type: "warning",
                title: "Warning!",
                notificationMessage: "File Missing",
                loading: false,
                alert: true
            })
        } else {
            const formData = new FormData();
            formData.append("file", this.state.fileUploadData.fileName);
            axios({
                method: 'post',
                url: backEndServerURL + '/eftn',
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'content-type': 'multipart/form-data'
                }
            }).then((response) => {
                this.setState({
                    type: "success",
                    title: "Successfully!!",
                    notificationMessage: "Successfully File Uploaded",
                    alert: true,
                    loading: false,
                    fileUploadData: {},
                })

            }).catch((error) => {
                this.setState({
                    type: "warning",
                    title: "Warning!",
                    notificationMessage: "Something Wrong",
                    alert: true,
                    loading: false,
                })
            })
        }


    };

    componentDidMount() {

        this.setState({
            loading: false
        })

    }

    updateComponent = () => {
        this.forceUpdate();

    };


    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >EFTN Upload ExcelSheet</h4>
                    </CardHeader>
                    <CardBody>
                        <div>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card>

                                        <CardBody>
                                            <div>
                                                {CommonJsonFormComponent.renderJsonForm(
                                                    this.state,
                                                    jsonForm,
                                                    this.updateComponent
                                                )}

                                                <br/>
                                                <button
                                                    className="btn btn-outline-primary btn-sm"
                                                    style={{
                                                        marginRight: "10px",
                                                    }}
                                                    onClick={this.getSubmitedForm}
                                                >
                                                    Submit
                                                </button>
                                            </div>


                                            {
                                                this.renderNotification()
                                            }


                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div>


                        </div>


                    </CardBody>


                </Card>
            );
        }

    }


}

export default withStyles(styles)(EFTNExcelUpload);