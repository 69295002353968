import React, {Component} from "react";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios/index";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import CardBody from "../../Card/CardBody";
import withStyles from "@material-ui/core/styles/withStyles";
import CloseIcon from '@material-ui/icons/Close';
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import MyValidation from "../../JsonForm/MyValidation";
import BDT from "../../../Static/BDT.png";
import USD from "../../../Static/USD.png";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

    button: {
        margin: theme.spacing(1),
    }
});
let remarks = [
    {
        "varName": "remarks",
        "type": "textArea",
        "required": true,
        "label": " Remarks",
        "grid": 12
    }];

class ConfirmAlertImage extends Component {
    state = {
        SelectedData: false,
        values: [],
        waiverValues: [],
        appId: '',
        csDataCapture: '',
        message: "",
        appData: {},
        getData: false,
        getNewCase: false,
        varValue: [],
        caseId: "",
        title: "",
        notificationMessage: "",
        app_uid: "-1",
        alert: false,
        loading: false,
        redirectLogin: false,
        type: [],
        dueDate: '',
        inputData: {},
        fileUploadData: {},
        selectedDate: {},
        dropdownSearchData: {},

        debitCard: "",
        showValue: false,
        AddDeferal: false,
        getDeferalList: [],
        getInitiateData: false,
        numberOfCustomer: 0,
        err: false,
        errorArray: {},
        errorMessages: {},
        getSplitFile: [],
        multipleScanningphotoShow: null,


    };


    constructor(props) {
        super(props);


    }


    updateComponent = () => {
        this.forceUpdate();
    };

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        return clone;
        /* for (let prop in clone)
             if (clone[prop] === '' || clone[prop] === ' '  || clone[prop] === "null"   ||  clone[prop] === "undefined" )
                 delete clone[prop];
         return clone;*/
    };


    close = (event, data) => {
        event.preventDefault();

        //this.props.validationForHandleConfirm()
        if (this.props.remarksAdd === true && this.props.appUid !== undefined && this.props.actionFlag === "Delete") {
            if (data === "YES") {
                let dependencyField = [];
                dependencyField.push({

                    "varName": "remarks",
                    "type": "textArea",
                    "required": true,
                    "label": "Remarks",
                    "grid": 12
                });
                let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
                this.forceUpdate();
                if (error === true) {
                    return 0;
                }

                var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.remarks + "/" + this.props.appUid;
                axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    console.log(response.data);
                    this.props.closeModal(data);
                })
                    .catch((error) => {
                        console.log(error);
                        this.props.closeModal(data);
                    })
            } else {
                this.props.closeModal(data);
            }
        } else {
            this.props.closeModal(data);
        }


    };
    handleSubmit = (event) => {

        event.preventDefault();
        this.setState({
            multipleScanningphotoShow: false
        })


    };
    updateComponent = () => {
        this.forceUpdate();
    };
    renderRemarsks = () => {

        return (
            CommonJsonFormComponent.renderJsonForm(this.state, remarks, this.updateComponent)

        )

    };
    renderCasaDpsMessage = () => {
        if (this.props.notificationMessgaeCommonDps === true) {
            return (
                <center>
                    <h4 style={{color: "white"}} >
                        DPS
                    </h4>
                    <h6>Minimum DPS amount should be BDT 500.00 and onward deposit amount must be in multiple of BDT
                        500.00</h6>
                </center>
            )
        }
    };

    render() {
        const {classes} = this.props;

        if (this.props.notificationMessgaeCommon === "BDT") {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>

                        <center>
                            <h4 style={{color: "white"}} >
                                FDR
                            </h4>
                            <img src={BDT} alt=""/>
                            <br/>
                            {this.renderCasaDpsMessage()}
                        </center>
                        <CardBody>

                            <center>
                                <button
                                    className="btn btn-outline-primary"
                                    style={{
                                        verticalAlign: 'right',
                                        marginTop: 20

                                    }}

                                    type='button' value='add more'
                                    onClick={(event) => this.close(event, "YES")}
                                >Yes
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                    className="btn btn-outline-primary"
                                    style={{
                                        verticalAlign: 'right',
                                        marginTop: 20

                                    }}

                                    type='button' value='add more'
                                    onClick={(event) => this.close(event, "NO")}
                                >No
                                </button>
                            </center>
                        </CardBody>

                    </GridItem>
                </GridContainer>
            )
        } else if (this.props.notificationMessgaeCommon === "USD") {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <center>
                            <h4 style={{color: "white"}} >
                                FDR
                            </h4>
                            <img src={USD} alt=""/>
                            <br/>
                            {this.renderCasaDpsMessage()}
                        </center>

                        <CardBody>

                            <center>
                                <button
                                    className="btn btn-outline-primary"
                                    style={{
                                        verticalAlign: 'right',
                                        marginTop: 20

                                    }}

                                    type='button' value='add more'
                                    onClick={(event) => this.close(event, "YES")}
                                >Yes
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                    className="btn btn-outline-primary"
                                    style={{
                                        verticalAlign: 'right',
                                        marginTop: 20

                                    }}

                                    type='button' value='add more'
                                    onClick={(event) => this.close(event, "NO")}
                                >No
                                </button>
                            </center>
                        </CardBody>

                    </GridItem>
                </GridContainer>
            )
        } else if (this.props.notificationMessgaeCommonDps === true) {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        {this.renderCasaDpsMessage()}
                        <CardBody>

                            <center>
                                <button
                                    className="btn btn-outline-primary"
                                    style={{
                                        verticalAlign: 'right',
                                        marginTop: 20

                                    }}

                                    type='button' value='add more'
                                    onClick={(event) => this.close(event, "YES")}
                                >Yes
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                    className="btn btn-outline-primary"
                                    style={{
                                        verticalAlign: 'right',
                                        marginTop: 20

                                    }}

                                    type='button' value='add more'
                                    onClick={(event) => this.close(event, "NO")}
                                >No
                                </button>
                            </center>
                        </CardBody>

                    </GridItem>
                </GridContainer>

            )
        } else if (this.props.remarksAdd === true && this.props.appUid !== undefined && this.props.actionFlag === "Delete") {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <center>
                            <h4 style={{color: "white"}} >Do You Want To Close The Case? <a><CloseIcon onClick={this.close} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></h4>
                        </center>
                        <CardBody>
                            {this.renderRemarsks()}
                            <center>
                                <button
                                    className="btn btn-outline-primary"
                                    style={{
                                        verticalAlign: 'right',
                                        marginTop: 20

                                    }}

                                    type='button' value='add more'
                                    onClick={(event) => this.close(event, "YES")}
                                >Yes
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                    className="btn btn-outline-primary"
                                    style={{
                                        verticalAlign: 'right',
                                        marginTop: 20

                                    }}

                                    type='button' value='add more'
                                    onClick={(event) => this.close(event, "NO")}
                                >No
                                </button>
                            </center>
                        </CardBody>

                    </GridItem>
                </GridContainer>

            )
        } else if (this.props.notificationMessgaeCommonFlag) {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <center>
                            <h4 style={{color: "white"}} >{this.props.notificationMessgaeCommon}</h4>
                        </center>
                        <CardBody>

                            <center>
                                <button
                                    className="btn btn-outline-primary"
                                    style={{
                                        verticalAlign: 'right',
                                        marginTop: 20

                                    }}

                                    type='button' value='add more'
                                    onClick={(event) => this.close(event, "YES")}
                                >Yes
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                    className="btn btn-outline-primary"
                                    style={{
                                        verticalAlign: 'right',
                                        marginTop: 20

                                    }}

                                    type='button' value='add more'
                                    onClick={(event) => this.close(event, "NO")}
                                >No
                                </button>
                            </center>
                        </CardBody>

                    </GridItem>
                </GridContainer>

            )
        } else if (this.props.notificationMessgaeCommon === "Minimum DPS amount should be BDT 500.00 and onward deposit amount must be in multiple of BDT 500.00") {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <center>
                            <h4 style={{color: "white"}} >{this.props.notificationMessgaeCommon}</h4>
                        </center>
                        <CardBody>

                            <center>
                                <button
                                    className="btn btn-outline-primary"
                                    style={{
                                        verticalAlign: 'right',
                                        marginTop: 20

                                    }}

                                    type='button' value='add more'
                                    onClick={(event) => this.close(event, "YES")}
                                >Yes
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                    className="btn btn-outline-primary"
                                    style={{
                                        verticalAlign: 'right',
                                        marginTop: 20

                                    }}

                                    type='button' value='add more'
                                    onClick={(event) => this.close(event, "NO")}
                                >No
                                </button>
                            </center>
                        </CardBody>

                    </GridItem>
                </GridContainer>

            )
        } else {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <center>
                            <h4 style={{color: "white"}} >Do You Confirm? <a><CloseIcon onClick={this.close} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></h4>
                        </center>
                        <CardBody>

                            <center>
                                <button
                                    className="btn btn-outline-primary"
                                    style={{
                                        verticalAlign: 'right',
                                        marginTop: 20

                                    }}

                                    type='button' value='add more'
                                    onClick={(event) => this.close(event, "YES")}
                                >Yes
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                    className="btn btn-outline-primary"
                                    style={{
                                        verticalAlign: 'right',
                                        marginTop: 20

                                    }}

                                    type='button' value='add more'
                                    onClick={(event) => this.close(event, "NO")}
                                >No
                                </button>
                            </center>
                        </CardBody>

                    </GridItem>
                </GridContainer>

            )
        }

    }

}

export default withStyles(styles)(ConfirmAlertImage);