import React, {Component} from "react";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../JsonForm/CustomeTheme2";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import Notification from "../NotificationMessage/Notification";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import MyValidation from "../JsonForm/MyValidation";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";

let searchForm = [
    {
        "varName": "searchUserName",
        "type": "text",
        "label": "User Id",
        "grid": 2,
        "required": true,
        "lowerCase": true
    },
];
let Form = [
    {
        "varName": "username",
        "type": "text",
        "label": "User Id",
        "grid": 2,
        "readOnly": true,
        "lowerCase": true,
    },
    {
        "varName": "firstName",
        "type": "text",
        "label": "Name",
        "validation": "string",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "grid": 2,
        "validation": "email",
        "readOnly": true,
    },
    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile Number",
        "grid": 2,
        "validation": "phone",
        "phonePrefix": true,
        "readOnly": true,
    },
    {
        "varName": "role",
        "type": "autoComplete",
        "grid": 2,
        "label": "Role",
        "required": true,
    },
    //  {
    //     "varName": "branchAndBakeOffice",
    //     "type": "select",
    //     "label": "Branch/Back Office",
    //     "readOnly":true,
    //     "onKeyDown":true,
    //     "grid": 2,
    //     "enum": ["BRANCH", "BACKOFFICE"],
    //     "required":true,
    // },
    // {
    //     "varName": "workPlaceUtil",
    //     "type": "autoComplete",
    //     "grid": 2,
    //     "label": "WorkPlace Util",
    //     "readOnly":true,
    //     "required": true,
    //     "conditional": true,
    //     "conditionalVarName": "branchAndBakeOffice",
    //     "conditionalVarValue": "BRANCH",

    // },
    // {
    //     "varName": "workPlaceUtil",
    //     "type": "autoComplete",
    //     "grid": 2,
    //     "label": "WorkPlace Util",
    //     "readOnly":true,
    //     "required": true,
    //     "conditional": true,
    //     "conditionalVarName": "branchAndBakeOffice",
    //     "conditionalVarValue": "BACKOFFICE",
    // },

];

class DelegationOfAuthority extends Component {
    state = {
        inputData: {},
        selectedDate: {},
        autoComplete: {},
        userObject: {},
        getData: false,
        showValue: false,
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        type: "success",
        err: false,
        varValue: {},
        errorArray: {},
        errorMessages: {},
        title: "",
        notificationMessage: "",
        alert: false,
        loading: false,
        buttonShow: false,
        roleList: [],
        workPlaceUnitList: [],
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    handleSearch = (event) => {
        event.preventDefault();
        let username = this.state.inputData.searchUserName;
        if (username === undefined || username === null) return 0;
        this.getUserInfo(username);


    };

    handleSubmit = (event) => {
        event.preventDefault();

        console.log(this.state.inputData);

        this.setState({
            loading: true
        });

        let error = MyValidation.defaultValidation(Form, this.state);
        this.forceUpdate();

        console.log(this.state.inputData);
        if (error === true) {
            this.setState({
                loading: false
            });
            return 0;
        }

        let data = {};
        data.userId = this.state.inputData.userId;
        data.username = this.state.inputData.username;
        data.password = "Gd%12345";
        data.firstName = this.state.inputData.firstName;
        data.lastName = '';
        data.email = this.state.inputData.email;
        data.phone = this.state.inputData.phone;
        data.roleId = this.getRollId(this.state.inputData.role);
        data.workplaceUnits = [this.getWorkPlaceUnitId(this.state.inputData.workPlaceUtil)];
        data.status = 'ACTIVE';
        console.log(data);
        if (data.roleId === -1 || data.workplaceUnits[0] === -1) return 0;
        let url = backEndServerURL + "/user/update";
        axios.post(url, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                if (response.data === true) {
                    this.setState({
                        type: "success",
                        title: "Successfull!",
                        notificationMessage: "Successfully Updated!",
                        alert: true,
                        loading: false
                    });

                    this.pageReload();

                } else {
                    this.setState({
                        type: "warning",
                        title: "warning!",
                        notificationMessage: response.data,
                        alert: true,
                        loading: false
                    })
                }

            })
            .catch((error) => {
                this.setState({
                    type: "error",
                    title: "Error!",
                    notificationMessage: error.massage,
                    alert: true,
                    loading: false,
                });
                console.log(error)
            })
    };
    pageReload = () => {
        setTimeout(
            () => window.location.reload(),
            1000
        );
    };
    getUserInfo = (username) => {

        this.setState({
            loading: true,
        });

        let url = backEndServerURL + "/user/get/" + username;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);

                let responseData = response.data;

                this.state.inputData.userId = responseData.userID;
                this.state.inputData.password = "Gd%12345";
                this.state.inputData.branchAndBakeOffice = responseData.branchAndBackOffice;
                this.state.inputData.email = responseData.email;
                this.state.inputData.firstName = responseData.firstName;
                this.state.inputData.phone = responseData.phone;
                this.state.inputData.role = responseData.userRoleName;
                this.state.inputData.username = responseData.userName;
                this.state.inputData.workPlaceUtil = responseData.workPlaceUtil;

                this.state.varValue.userId = responseData.userID;
                this.state.varValue.password = "Gd%12345";
                this.state.varValue.branchAndBakeOffice = responseData.branchAndBackOffice;
                this.state.varValue.email = responseData.email;
                this.state.varValue.firstName = responseData.firstName;
                this.state.varValue.phone = responseData.phone;
                this.state.varValue.role = {label: responseData.userRoleName,};
                this.state.varValue.username = responseData.userName;
                this.state.varValue.workPlaceUtil = {label: responseData.workPlaceUtil,};

                this.getChangeBranchBackOffice({varName: "branchAndBakeOffice"});
                this.getAllRoll();


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    getData: false,
                })
            })
    };
    renderSearchButton = () => {
        return (

            <button
                className="btn btn-outline-primary  btn-sm"
                style={{
                    marginTop: "18px"
                }}
                onClick={this.handleSearch}

            >
                Search
            </button>


        )
    };
    renderButton = () => {
        if (this.state.getData) {
            return (

                <button
                    className="btn btn-outline-primary  btn-sm"
                    style={{
                        marginTop: "18px"
                    }}
                    onClick={this.handleSubmit}

                >
                    Submit
                </button>


            )
        }
    };

    componentDidMount = () => {

        if (this.props.username !== undefined) {
            this.getUserInfo(this.props.username);
        }
    };

    getAllRoll = () => {
        let autoComplete = {};
        let role = [];
        let roleListUrl = backEndServerURL + "/role/get";
        axios.get(roleListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((item) => {
                    role.push({
                        label: item.authority,
                        key: item.id
                    })

                });
                console.log(role);

                autoComplete.role = role;

                this.setState({
                    roleList: role,
                    autoComplete: {...this.state.autoComplete, ...autoComplete},
                    getData: true,
                    showValue: true,
                    loading: false,
                }, console.log(this.state.autoComplete))

            }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false
            })

        })

    };

    getRollId = (roleName) => {
        let role = this.state.roleList.find(element => element.label === roleName);
        if (role == null) {
            this.setState({
                type: "warning",
                title: "warning!",
                notificationMessage: "Role name not found",
                alert: true,
                loading: false
            });
            return -1;
        }
        return role.key;
    };

    getWorkPlaceUnitId = (workPlaceName) => {
        let workPlaceUtil = this.state.workPlaceUnitList.find(element => element.label === workPlaceName);
        if (workPlaceUtil == null) {
            this.setState({
                type: "warning",
                title: "warning!",
                notificationMessage: "workplace name not found",
                alert: true,
                loading: false
            });
            return -1;
        }
        return workPlaceUtil.key;
    };

    getChangeBranchBackOffice = (data) => {
        console.log(data);
        console.log(this.state.inputData);
        if (data.varName === 'branchAndBakeOffice') {

            let branchBackOffice = this.state.inputData.branchAndBakeOffice;
            console.log(branchBackOffice);

            let autoComplete = {};
            let workPlaceUtil = [];
            let Url = backEndServerURL + "/workplaceUnit/getByBranchOrOps/" + branchBackOffice;
            axios.get(Url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    response.data.map((item) => {
                        workPlaceUtil.push({
                            label: item.value,
                            key: item.key,
                        })
                    });
                    console.log(workPlaceUtil);

                    autoComplete.workPlaceUtil = workPlaceUtil;
                    this.setState({
                        workPlaceUnitList: workPlaceUtil,
                        autoComplete: {...this.state.autoComplete, ...autoComplete},
                    })

                }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            })
        }

    };

    updateComponent = () => {
        this.forceUpdate();
    };

    returnJsonSearchForm = () => {

        return (
            CommonJsonFormComponent.renderJsonForm(this.state, searchForm, this.updateComponent)
        )
    };

    returnJsonForm = () => {

        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, Form, this.updateComponent, "", this.getChangeBranchBackOffice)
            )
        }
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.type} stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    renderHeader = () => {

        return (
            <div>
                {this.props.username === undefined ? <h6>Delegation Of Authority</h6> :
                    <h6>Delegation Of Authority<a><CloseIcon onClick={this.props.closeModal} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></h6>}
            </div>

        )

    };

    renderSearchHeader = () => {

        return (
            <h6>Search User</h6>
        )

    };

    render() {
        return (
            <div>
                {this.props.username === undefined ?
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            {this.renderSearchHeader()}

                        </CardHeader>
                        <CardBody>
                            <div>

                                <ThemeProvider theme={theme}>

                                    <Grid container>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            open={this.state.loading}>
                                            <DialogContent>

                                                <center>
                                                    <img src={loader} alt=""/>
                                                </center>
                                            </DialogContent>
                                        </Dialog>
                                        {this.renderNotification()}

                                        {this.returnJsonSearchForm()}

                                        <Grid item xs={1}>
                                            {this.renderSearchButton()}
                                        </Grid>

                                    </Grid>

                                </ThemeProvider>


                            </div>


                        </CardBody>
                    </Card>

                    : ""}


                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {this.renderHeader()}

                    </CardHeader>
                    <CardBody>
                        <div>

                            <ThemeProvider theme={theme}>

                                <Grid container>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        open={this.state.loading}>
                                        <DialogContent>

                                            <center>
                                                <img src={loader} alt=""/>
                                            </center>
                                        </DialogContent>
                                    </Dialog>
                                    {this.renderNotification()}

                                    {this.returnJsonForm()}

                                    <Grid item xs={1}>
                                        {this.renderButton()}
                                    </Grid>

                                </Grid>

                            </ThemeProvider>


                        </div>


                    </CardBody>
                </Card>

            </div>

        )
    }

}

export default DelegationOfAuthority;