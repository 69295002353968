import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles/index";
import CommonJsonFormComponent from '../../JsonForm/CommonJsonFormComponent';
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import GridItem from "../../Grid/GridItem.jsx";
import GridContainer from "../../Grid/GridContainer.jsx";
import Table from "../../Table/Table";
import axios from 'axios/index';
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import MyValidation from "../../JsonForm/MyValidation";
import WaiverList from "./MerchantWaiverList";

const HombujsonFormReadOnly = [
    {
        "label": "Merchant type",
        "type": "text",
        "varName": "merchantType",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Merchant Name",
        "type": "text",
        "varName": "merchantName",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Proprietor Name",
        "type": "text",
        "varName": "proprietorName",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Key Contact Person Number",
        "type": "text",
        "varName": "mobileNumber",
        "grid": 2,
        "readOnly": true
    },

    {
        "label": "Address",
        "type": "text",
        "varName": "address",
        "grid": 2,
        "multiline": true,
        "readOnly": true
    },
    {
        "label": "DST Name",
        "type": "text",
        "varName": "dstName",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "DST ID",
        "type": "text",
        "varName": "dstID",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "POS Model",
        "type": "text",
        "varName": "posModel",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "SIM",
        "type": "text",
        "varName": "sim",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "POS Serial",
        "type": "text",
        "varName": "posSerial",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "IMEI",
        "type": "text",
        "varName": "imei",
        "grid": 2,
        "readOnly": true
    },
];

let dffRemarks = [
    {
        "varName": "dffRemarks",
        "type": "textArea",
        "required": true,
        "label": "Remarks",
        "grid": 12
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class DefferalApproval extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: true,
            getDeferalList: [],
            getData: false,
            dff_approval: "RETURN",
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            remarks: false,
            getRemarkList: [],
            SelectedData: false,
            selectedDate: {},
            disabled: false,
            loading: true,
        }
    }

    modalClose = () => {
        this.props.closeModal();
    };

    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };

    componentDidMount() {
        console.log(this.props.appId);

        let url = backEndServerURL + "/variables/" + this.props.appId;

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                response.data.dffRemarks = null;

                this.setState({
                    varValue: response.data,
                    inputData: this.copyJson(response.data),
                    getData: true,
                    loading: false
                });


                let defferalUrl = backEndServerURL + "/case/deferral/" + this.props.appId;

                axios.get(defferalUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        let deferealData = [];

                        response.data.map((deferal) => {

                            var status;
                            if (deferal.status === "ACTIVE") {
                                status = "Approved"
                            } else if (deferal.status === "APPROVAL_WAITING") {
                                status = "Pending"
                            } else {
                                status = "Not Approved";
                            }

                            deferealData.push(this.createTableForDeferal(deferal.id, deferal.type,deferal.deferalCategory,deferal.deferalSubCategory,deferal.dueDate, deferal.appliedBy, deferal.applicationDate,deferal.deferalDstId,deferal.deferalRmCode, status))
                        });

                        this.setState({
                            getDeferalList: deferealData
                        });

                        console.log(response)

                    })
                    .catch((error) => {
                        console.log(error)
                    })

            })
            .catch((error) => {
                console.log(error)
            });

        let remarksUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;

        axios.get(remarksUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                if (response.data.length > 0) {
                    let remarkData = [];

                    response.data.map((res) => {

                        remarkData.push(this.createTableForRemarks(res.remarks, res.createByUserName, res.applicationRemarksDate, res.createByUserRole))

                    });

                    this.setState({
                        getRemarkList: remarkData,
                        remarks: true
                    });

                    console.log(this.state.getRemarkList)
                } else {
                    this.setState({
                        remarks: false
                    })
                }


            })
            .catch((error) => {
                console.log(error)
            })
    }


    renderRemarks = () => {

        return (

            CommonJsonFormComponent.renderJsonForm(this.state, dffRemarks, this.updateComponent)

        )
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderFormWithData = () => {
        if (this.state.getData) {
            return CommonJsonFormComponent.renderJsonForm(this.state, HombujsonFormReadOnly, this.updateComponent)
        }
    };


    createTableForRemarks = (remarks, name, a, b) => {
        return ([
            remarks, name, a, b
        ])
    };

    renderRemarksTable = () => {

        if (this.state.remarks === true && this.state.getRemarkList.length > 0) {
            return (
                <div style={{marginBottom: '20px'}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b style={{padding: '20px 10px 10px'}}>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarkList}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>
            )
        }
    };


    close = () => {
        this.props.closeModal();
    };


    createTableForDeferal = (id, type, category,subCategory,dueDate, appliedBy, applicationDate, dstId,rmCode,status) => {
        return ([
            type, category,subCategory,dueDate, appliedBy, applicationDate, dstId,rmCode,status
        ])
    };

    renderDeferalApprovalTableData = () => {

        if (this.state.getDeferalList.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px", "margin": "10px 0 20px"}}>
                        <b style={{marginTop: '5px', padding: '10px'}}>Deferral List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type","Category","Expire Days", "Expire Date", "Raise By", "Raise Date","DST ID","RM Code", "Status"]}                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>
                </div>
            )
        }

    };

    approveHandler = (e) => {
        e.preventDefault();
        this.setState({
            disabled: true,
            loading: true,
        });

        let deferalCheckUrl = backEndServerURL + "/variables/" + this.props.appId;

        let dff_approval = "APPROVED";
        axios.post(deferalCheckUrl, {dff_approval: dff_approval}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (this.state.inputData.mbu_deferal === "YES") {

                    let deferalApproval = backEndServerURL + "/deferral/approval";
                    let appId = this.props.appId;
                    let approval = "APPROVE";
                    let mbu_deferal = "NO";

                    axios.post(deferalApproval, {
                        appId: appId,
                        approval: approval,
                        mbu_deferal: mbu_deferal
                    }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            this.setState({
                                deff_approval: "APPROVE"
                            })
                        })
                        .catch((error) => {
                            this.setState({
                                disabled: false,
                                loading: false,
                            });
                            console.log(error);
                        });


                    if (this.state.inputData.dffRemarks !== null) {

                        var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.dffRemarks + "/" + this.props.appId;
                        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                console.log(response.data)
                            })
                            .catch((error) => {
                                console.log(error)
                            })

                    }


                    let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                    axios.get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            this.setState({
                                title: "Successful!",
                               // notificationMessage: "Approved Successfully!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                disabled: false,
                                loading: false,
                            });
                            this.modalClose();
                        })
                        .catch((error) => {
                            this.setState({
                                disabled: false,
                                loading: false,
                            });
                            console.log(error)
                        })
                }
            })
            .catch((error) => {
                this.setState({
                    disabled: false,
                    loading: false,
                });
                console.log(error)
            })


    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    returnHandler = (e) => {
        e.preventDefault();
        let error = MyValidation.defaultValidation(dffRemarks, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                disabled: true
            });
            let deferalCheckUrl = backEndServerURL + "/variables/" + this.props.appId;
            let dff_approval = "RETURN";
            let mbu_deferal = "NO";

            axios.post(deferalCheckUrl, {
                dff_approval: dff_approval,
                mbu_deferal: mbu_deferal
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {


                    if (this.state.inputData.mbu_deferal === "YES") {

                        let deferalApproval = backEndServerURL + "/deferral/approval";
                        let appId = this.props.appId;
                        let approval = "REJECT";
                        let mbu_deferal = "NO";

                        axios.post(deferalApproval, {
                            appId: appId,
                            approval: approval,
                            mbu_deferal: mbu_deferal
                        }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {

                                this.setState({
                                    deff_approval: "REJECT"
                                })
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    disabled: false
                                });
                            })
                    }


                    let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                    axios.get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            this.setState({
                                title: "Successful!",
                               // notificationMessage: "Return Successfully!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                disabled: false
                            });
                            this.modalClose()
                        })
                        .catch((error) => {
                            this.setState({
                                disabled: false
                            });
                            console.log(error)
                        })
                })
                .catch((error) => {
                    this.setState({
                        disabled: false
                    });
                    console.log(error)
                });

            if (this.state.inputData.dffRemarks !== null) {

                var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.dffRemarks + "/" + this.props.appId;
                axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        }
    };

    declineHandler = (e) => {
        e.preventDefault();
        this.setState({
            disabled: true
        });
        let closeurl = backEndServerURL + "/case/close/" + this.props.appId;
        axios.post(closeurl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Close!",
                        alert: true,
                        disabled: false
                    });
                    this.props.closeModal();
                }

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    title: "Failed!",
                    notificationMessage: "Case Close Failed!",
                    alert: true,
                    disabled: false
                });
            });

    };

    functionForWaiver = () => {
        if (this.state.getData) {
            return (
                <>
                    <WaiverList appId={this.props.appId}/>
                </>
            )
        }
    };

    render() {
        const {classes, onClose} = this.props;
        if (this.state.getData) {

            return (

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >Merchant Onboarding Request<a><CloseIcon onClick={this.props.closeModal} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a></h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <Grid container spacing={1}>
                                        <ThemeProvider theme={theme}>
                                            {
                                                this.renderFormWithData()
                                            }
                                            <br/>

                                            <Grid item xs='12'>
                                                {
                                                    this.renderDeferalApprovalTableData()
                                                }
                                            </Grid>

                                            <Grid item xs={12}>
                                                {this.functionForWaiver()}
                                            </Grid>

                                            <Grid item xs={12}>
                                                {
                                                    this.renderRemarksTable()
                                                }
                                            </Grid>

                                            <Grid item xs={12}>

                                                {this.renderRemarks()}

                                            </Grid>

                                            {
                                                this.renderNotification()
                                            }

                                            <Grid item xs='12'>
                                                <button
                                                    className="btn btn-outline-primary btn-sm"
                                                    onClick={this.approveHandler}
                                                    disabled={this.state.disabled}
                                                >
                                                    Approve
                                                </button>
                                                &nbsp;&nbsp;
                                                <button
                                                    className="btn btn-outline-primary btn-sm"
                                                    onClick={this.returnHandler}
                                                    disabled={this.state.disabled}
                                                >
                                                    Return
                                                </button>
                                                &nbsp;&nbsp;
                                                <button
                                                    className="btn btn-outline-primary btn-sm"
                                                    onClick={this.declineHandler}
                                                    disabled={this.state.disabled}
                                                >
                                                    Decline
                                                </button>
                                            </Grid>
                                        </ThemeProvider>
                                    </Grid>
                                </div>

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>

            );
        } else {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        }
    }
}

export default withStyles(styles)(DefferalApproval);