import React, {Component} from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import SignatureCard from "../../DeliverableManagement/SignatureCard";
import MultipleSignatureCard from "../CommonComponent/MultipleSignatureCard";
import {backEndServerURL} from "../../../Common/Constant";
import axios from 'axios/index';
import withStyles from "@material-ui/core/styles/withStyles";
import CBNotFound from "../CASA/CBNotFound";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class SignatureButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            showValue: true,
            cbNotFound: false,
            title: "",
            dormant: false,
            showButton: false
        }
    }


    componentDidMount() {
        if (this.props.signatureSource === "Ababil") {
            let accountInquiryUrl = backEndServerURL + "/accountInquiryAbabil/" + this.props.accountNumber;
            axios.get(accountInquiryUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                        console.log(response.data);
                        if (response.data.acctStatus === "A") {
                            this.setState({
                                showButton: true,
                            })
                        } else if (response.data.acctStatus === "D") {
                            this.setState({
                                dormant: true
                            })
                        } else {
                            this.setState({
                                showButton: false,
                                dormant: false
                            })
                        }
                    })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                showButton: false,
                                dormant: false,
                                loading: false,
                                cbNotFound: true,
                                title: error.response.data.message
                            })
                        });

        } else {
            let url = backEndServerURL + "/GetCustomAcctStatus/" + this.props.accountNumber;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    if (response.data.acctStatus === "A") {
                        this.setState({
                            showButton: true,
                        })
                    } else if (response.data.acctStatus === "D") {
                        this.setState({
                            dormant: true
                        })
                    } else {
                        this.setState({
                            showButton: false,
                            dormant: false
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        showButton: false,
                        dormant: false,
                        loading: false,
                        cbNotFound: true,
                        title: this.errorObjectCheck(error)
                    })
                });
        }
    }
    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "Finacle System Error!"
                }
            } else {
                return "Finacle System Error!"
            }
        } else {
            return "Finacle System Error!"
        }
    };
    signatureCard = () => {
        this.setState({
            signatureCard: true
        })
    };

    closeSignatureCard = () => {
        this.setState({
            signatureCard: false
        })
    };

    renderSignatureImage = () => {
        if (this.props.signatureType === "multiple") {
            return <MultipleSignatureCard signatureSource={this.props.signatureSource} closeModal={this.closeSignatureCard} id={this.props.accountNumber}/>
        } else {
            return <SignatureCard signatureSource={this.props.signatureSource} closeModal={this.closeSignatureCard} id={this.props.accountNumber}/>
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    renderAlertModal = () => {
        return (
            <Dialog
                fullWidth="true"
                maxWidth="sm"
                open={this.state.cbNotFound}>
                <DialogContent>
                    <CBNotFound
                        closeModal={this.closeModalCBNotFound}
                        title={this.state.title}
                    />
                </DialogContent>
            </Dialog>
        )
    };

    render() {
        const {classes} = this.props.classes;
        if (this.state.dormant) {
            return (
                <div>
                    <p style={{color: "#000", fontSize: "14px", fontWeight: "bold"}}>Signature is not Allowed</p>
                    {this.renderAlertModal()}
                </div>
            )
        } else if (this.state.showButton && this.props.casaFlow === true) {
            return (
                <React.Fragment>
                    <button
                        className="btn btn-danger btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginTop: 20

                        }}
                        onClick={this.signatureCard}
                    >
                        Signature Card View
                    </button>

                    {this.renderAlertModal()}
                    <Dialog
                        fullWidth="true"
                        maxWidth="md"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.signatureCard}>
                        <DialogContent className={classes.dialogPaper}>
                            {this.renderSignatureImage()}
                        </DialogContent>
                    </Dialog>
                </React.Fragment>
            );
        } else if (this.state.showButton) {
            return (
                <div>
                    <button
                        onClick={this.signatureCard}
                        className="btn btn-danger btn-sm">
                        Signature Card View
                    </button>
                    {this.renderAlertModal()}
                    <Dialog
                        fullWidth="true"
                        maxWidth="md"
                        open={this.state.signatureCard}>
                        <DialogContent>
                            {this.renderSignatureImage()}
                        </DialogContent>
                    </Dialog>
                </div>
            );
        } else {
            return (
                <>
                    {this.renderAlertModal()}
                </>
            )
        }
    }


}

export default withStyles(styles)(SignatureButton);