const {startReturnSubmission} = require("../../Common/Constant");

function listOfReturnSubmission(date) {
    /*     let currentDate=new Date();
         let dataList=[];
         for (let i = date; i < currentDate.getFullYear(); i++) {
             dataList.push(date+"-"+Number(date+1));
             i=date+1;
          }*/
    let dataList=[
        "2017-2018",
        "2018-2019",
        "2019-2020",
         "2020-2021",
        "2021-2022",
        "2022-2023",
    ];
    return dataList;


}
//##########MainTenance##############
//CS Maintenance With Print File
function makeReadOnly(object) {
    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject;

    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["readOnly"] = true;
    }
    return returnObject;
}

function makeReadOnlyObject(object) {
    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject;

    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["readOnly"] = true;
    }
    return returnObject;
}

const maintenanceListReturn = [
    // {
    //     "varName": "eqmNumber",
    //     "type": "text",
    //     "required": true,
    //     "label": "EQM Number",
    //     "grid": 2,
    //
    // },
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "varName": "12DigitTINChange",
        "type": "title",
        "label": "E Tin Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true


    },
    {
        "varName": "tinUpdateOld",
        "type": "text",
        "label": "Existing TIN No",
        "readOnly": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true


    },
    {
        "varName": "tin",
        "type": "text",
        "label": "New TIN No",
        "etinBlock":true,
        "grid": 2,
        "validation": "tin",
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true

    },

    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 2,
        "required":true,
        "conditionalArray": true,
        "conditionalArrayList": ["digitTinChange", "tinopen"],
        "conditionalArrayValue": [true, true],
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid": 2,
        "required":true,
        "conditionalArray": true,
        "conditionalArrayList": ["digitTinChange", "tinopen"],
        "conditionalArrayValue": [true, true],
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission","digitTinChange", "tinopen"],
        "conditionalArrayValue": ["Y",true, true],
        "grid":2,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "required":true,
        "label": "Tax Submission Year",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission","digitTinChange", "tinopen"],
        "conditionalArrayValue": ["N",true, true],
        "grid": 2,
    },
    {
        "varName": "nidOrsmartcardChange",
        "type": "title",
        "label": "NID/Smart Card Number update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true


    },
    {
        "varName": "nidOrsmartcardOld",
        "type": "text",
        "label": "Existing NID/Smart Card Number",
        "readOnly": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true


    },
    {
        "varName": "newNidOrSmartCardNumber",
        "type": "text",
        "label": "New NID/Smart Card Number",
        "validation": "numeric",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true

    },
    {
        "varName": "TitleChange",
        "type": "title",
        "label": "Title Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true

    },
    {
        "varName": "titleOld",
        "type": "autoCompleteValueReturn",
        "label": "Existing Account Title",
        "readOnly": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "existingNameOld",
        "type": "text",
        "label": "Existing Name",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "NomineeUpdate",
        "type": "title",
        "label": "Nominee Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true

    },
    {
        "varName": "nomineeNumberOld",
        "type": "text",
        "label": "Existing No of Nominee",
        "readOnly": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true
    },
    {
        "varName": "SignatureCard",
        "type": "title",
        "label": "Signature Card Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "signatureCard",
        "conditionalVarValue": true

    },
    {
        "varName": "individualInformationUpdate",
        "type": "title",
        "label": "Individual Information Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "individualInformationUpdate",
        "conditionalVarValue": true,
    },
    {
        "varName": "PhotoIDChange",
        "type": "title",
        "label": "Photo ID Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,
    },
    // {
    //     "varName": "existingNID",
    //     "type": "text",
    //     "label": "Existing NID No.",
    //     "readOnly": true,
    //     "grid": 2,
    //     // "errorMessage": "Error",
    //     // "nid": true,
    //     // "required": true,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "nidNo",
    //     "type": "text",
    //     "label": "New NID No.",
    //     "grid": 2,
    //     // "errorMessage": "Error",
    //     // "nid": true,
    //     // "required": true,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "existingPassport",
        "type": "text",
        "label": "Existing Passport No.",
        // "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "passportNo",
    //     "type": "text",
    //     "label": "New Passport No.",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "existingBirthCertificate",
        "type": "text",
        "label": "Existing  Birth Certificate No.",
        // "validation": "numeric",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "newTradeLicense",
    //     "type": "text",
    //     "label": "New Trade License No.",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "existingChairmanCertificate",
        "type": "text",
        "label": "Existing Chairman Certificate No.",
        "validation": "numeric",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "birthCertificateNo",
    //     "type": "text",
    //     "label": "New Birth Certificate No.",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "existingDrivingLicense",
        "type": "text",
        "label": "Existing Driving License No.",
        "validation": "numeric",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "birthCertificateNo",
    //     "type": "text",
    //     "label": "New Birth Certificate No.",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "existingResidentId",
        "type": "text",
        "label": "Existing Resident Id No.",
        "validation": "numeric",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "birthCertificateNo",
    //     "type": "text",
    //     "label": "New Birth Certificate No.",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "MailingContactNumberChange",
        "type": "title",
        "label": "Existing Mailing Contact Number Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingPhoneNumber",
        "type": "text",
        "label": "Existing Phone Number",
        // "readOnly": true,
        "grid": 4,
        // "phone": true,
        // "errorMessage": "Error",
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 8,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "OtherContactNumberChange",
        "type": "title",
        "label": "Other Contact Number Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingResidenceNumber",
        "type": "text",
        "label": "Existing Residence Number",
        // "readOnly": true,
        "grid": 3,
        // "phone": true,
        // "errorMessage": "Error",
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "residenceNumberNew",
    //     "type": "text",
    //     "label": "New Residence Number",
    //     "grid": 2,
    //     // "phone": true,
    //     // "errorMessage": "Error",
    //     "conditional": true,
    //     "conditionalVarName": "contactNumberChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherContactNumberChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "existingOfficeNumber",
        "type": "text",
        "label": "Existing Office Number",
        // "readOnly": true,
        "grid": 3,
        // "errorMessage": "Error",
        // "phone": true,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    //
    // {
    //     "varName": "officeNumberNew",
    //     "type": "text",
    //     "label": "new Office Number",
    //     "grid": 2,
    //     // "errorMessage": "Error",
    //     // "phone": true,
    //     "conditional": true,
    //     "conditionalVarName": "contactNumberChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherContactNumberChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "existingMobileNumber",
        "type": "text",
        "label": "Existing Mobile Number",
        "readOnly": true,
        // "errorMessage": "Error",
        // "phone": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "mobileNumberNew",
    //     "type": "text",
    //     "label": "New Mobile Number",
    //     // "errorMessage": "Error",
    //     // "phone": true,
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "contactNumberChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherContactNumberChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "EmailAddressChange",
        "type": "title",
        "label": "Email Address Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true

    },
    {
        "varName": "existingEmailId",
        "type": "text",
        "label": "Existing Email ID",
        "readOnly": true,
        "grid": 2,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "emailId",
        "type": "text",
        "label": "New Email ID",
        "grid": 2,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "existingEStatementFacility",
    //     "type": "select",
    //     "label": "Existing E-Statement Facility",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "emailAddressChange",
    //     "conditionalVarValue": true,
    //     "enum": [
    //         "YES",
    //         "NO"
    //     ]
    // },
    {
        "varName": "E -StatementEnrollment",
        "type": "title",
        "label": "E - Statement Enrollment",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true,

    },
    {
        "varName": "eStatementFacility",
        "type": "text",
        "label": "Existing E-Statement Facility",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true,

    },
    {
        "varName": "eStatementFacilityNew",
        "type": "select",
        "label": "New E-Statement Facility",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true,
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "existingEmailId",
        "type": "text",
        "label": "Existing Email ID",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true
    },
    {
        "varName": "newEmailId",
        "type": "text",
        "label": "New Email ID",
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true
    },
    {
        "varName": "AddressChange",
        "type": "title",
        "label": "Address Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true

    },
    {
        "varName": "permanentAddress",
        "type": "text",
        "label": "Permanent Address",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "newPermanentAddress",
    //     "type": "text",
    //     "label": "New Permanent Address",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "presentAddress",
        "type": "text",
        "label": "Present Address",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "newPresentAddress",
    //     "type": "text",
    //     "label": "New Present Address",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "officeAddress",
        "type": "text",
        "label": "Office Address",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "newOfficeAddress",
    //     "type": "text",
    //     "label": "New Office Address",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "communicationAddress",
        "type": "text",
        "label": "Communication Address",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "newCommunicationAddress",
    //     "type": "select",
    //     "label": "New Communication Address",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true,
    //     "enum": [
    //         "Permanent Address",
    //         "Present Address",
    //         "Office Address",
    //     ],
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "OtherInformationChange",
        "type": "title",
        "label": "Other Information Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true

    },
    {
        "varName": "dobOld",
        "type": "text",
        "label": "DOB",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "dobNew",
    //     "type": "date",
    //     "label": "New DOB",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "fatherName",
        "type": "text",
        "label": "Father's Name",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "fatherName",
    //     "type": "text",
    //     "label": "Father Name Update",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "motherName",
        "type": "text",
        "label": "Mother's Name",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "motherName",
    //     "type": "text",
    //     "label": "Mother Name Update",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "spouseName",
        "type": "text",
        "label": "Spouse Name",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "spouseName",
    //     "type": "text",
    //     "label": "Spouse Name Update",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "profession",
    //     "type": "text",
    //     "label": "Profession",
    //     "readOnly": true,
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "professionNew",
    //     "type": "text",
    //     "label": "New Profession",
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    //
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "employerName",
        "type": "text",
        "label": "Employer Name",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "employerNew",
    //     "type": "text",
    //     "label": "New Employer Name",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    //
    // },
    //
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "DormantAccountActivation",
        "type": "title",
        "label": "Dormant Account Activation",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "dormantAccountActivation",
        "conditionalVarValue": true
    },
    {
        "varName": "accountStatus",
        "type": "text",
        "label": "Account Status",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dormantAccountActivation",
        "conditionalVarValue": true
    },
    {
        "varName": "dormantActivationCharge",
        "type": "text",
        "label": "Dormant Activation Charge",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dormantAccountActivation",
        "conditionalVarValue": true
    },
    {
        "varName": "DormantAccountDataUpdate",
        "type": "title",
        "label": "Dormant Account Data Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "dormantAccountDataUpdate",
        "conditionalVarValue": true

    },
    {
        "varName": "SchemeMaintenance-LinkA/CChange",
        "type": "title",
        "label": "Scheme Maintenance-Link A/C Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "existingLinkAccount",
        "type": "text",
        "label": "Existing Link Account",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "requestedBranchSolId",
        "type": "text",
        "label": "Requested Branch Sol ID",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaAmount",
        "type": "text",
        "label": "Schema Amount",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaTenor",
        "type": "text",
        "label": "Schema Tenor",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaValueDate",
        "type": "text",
        "label": "Schema Value Date",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaInstallmentDate",
        "type": "text",
        "label": "Schema Installment Date",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    // {
    //     "varName": "tenorExisting",
    //     "type": "text",
    //     "label": "Existing Tenure",
    //     "grid": 2,
    //     "readOnly": true,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "tenorNew",
    //     "type": "text",
    //     "label": "New Tenure",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "valueDateExisting",
    //     "type": "text",
    //     "label": "Existing Value Date",
    //     "grid": 2,
    //     "readOnly": true,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "valueDateNew",
    //     "type": "date",
    //     "label": "New Value Date",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "maturityDateExisting",
    //     "type": "text",
    //     "label": "Existing Maturity Date",
    //     "grid": 2,
    //     "readOnly": true,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "maturityDateNew",
    //     "type": "date",
    //     "label": "New Maturity Date",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    // },
    {
        "varName": "SchemeMaintenance-InstallmentRegularization",
        "type": "title",
        "label": "Scheme Maintenance - Installment Regularization",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true

    },
    {
        "varName": "numberInstallmentDue",
        "type": "text",
        "label": "Number of Installment(s) Due",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true
    },
    {
        "varName": "detailsofMonth",
        "type": "text",
        "label": "Details of Month",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true

    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenance",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "amountPerInstallment",
        "type": "text",
        "label": "Amount Per Installment",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true
    },
    {
        "varName": "totalInstallmentAmountDue",
        "type": "text",
        "label": "Total Installment Amount Due",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true

    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenance",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "debitDueInstallmentChargeRealization",
        "type": "text",
        "label": "Debit Due Installment & Charge Realization A/C no.",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true
    },
    {
        "varName": "transactionTypeOrSubtype",
        "type": "text",
        "label": "Transaction Type/Subtype",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true
    },
    {
        "varName": "SchemeMaintenanceInstallmentDate",
        "type": "title",
        "label": "Scheme Maintenance - Installment Date Regularization",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "solId",
        "type": "text",
        "label": "SOL ID",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaAmount",
        "type": "text",
        "label": "Schema Amount",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaTenor",
        "type": "text",
        "label": "Schema Tenor",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaValueDate",
        "type": "text",
        "label": "Schema Value Date",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaInstallmentDate",
        "type": "text",
        "label": "Schema Installment Date",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "existingMatuirityDate",
        "type": "text",
        "label": "Existing Matuirity Date",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "SignatoryUpdate",
        "type": "title",
        "label": "Signatory Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "signatoryUpdate",
        "conditionalVarValue": true

    },
    {
        "varName": "existingMandate",
        "type": "text",
        "label": "Existing Mandate",
        "readOnly": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "signatoryUpdate",
        "conditionalVarValue": true
    },
    {
        "varName": "newMandate",
        "type": "text",
        "label": "New Mandate",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "signatoryUpdate",
        "conditionalVarValue": true
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 8,
        "conditional": true,
        "conditionalVarName": "signatoryUpdate",
        "conditionalVarValue": true
    },
    {
        "varName": "CityLive",
        "type": "title",
        "label": "City Live",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "cityLive",
        "conditionalVarValue": true

    },
    {
        "varName": "ProjectRelatedDataUpdateADUP,CIB",
        "type": "title",
        "label": "Project Related Data Update ADUP, CIB",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "projectRelatedDataUpdateADUP",
        "conditionalVarValue": true

    },
    {
        "varName": "CTRLimitUpdate/UnconfirmedAddressChange",
        "type": "title",
        "label": "CTR Limit Update/Unconfirmed Address Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "ctrLimitUpdateUnconfirmedAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "Account&SchemeClose",
        "type": "title",
        "label": "Account & Scheme Close",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "accountTitle",
        "type": "text",
        "label": "Account Title",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "accountBalance",
        "type": "text",
        "label": "Account Balance",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "faceValueForScheme",
        "type": "text",
        "label": "Face Value for scheme",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "maturityValueForScheme",
        "type": "text",
        "label": "Maturity Value for scheme",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "maturityDateForScheme",
        "type": "date",
        "label": "Maturity Date for scheme",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "lien",
        "type": "text",
        "label": "Lien",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "dormancy",
        "type": "text",
        "label": "Dormancy",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "freeze",
        "type": "text",
        "label": "Freeze",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "aDUPNormalCustomer",
        "type": "text",
        "label": "ADUP/Normal Customer",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "proxyTransaction",
        "type": "text",
        "label": "Proxy Transaction",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "eTinStatus",
        "type": "text",
        "label": "E Tin Status",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "sourceTaxCalculation",
        "type": "text",
        "label": "Source Tax Calculation",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "eDCalculation",
        "type": "text",
        "label": "ED Calculation",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "preMatureInterestCalculation",
        "type": "text",
        "label": "Pre mature interest calculation",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "debitAccountNumber",
        "type": "text",
        "label": "Debit Account Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "numberOfInstallmentPendingScheme",
        "type": "text",
        "label": "Number of Installment pending/realized for scheme",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "LockerSIOpen",
        "type": "title",
        "label": "Locker SI Open",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "lockerSIOpen",
        "conditionalVarValue": true

    },
    {
        "varName": "LockerSIClose",
        "type": "title",
        "label": "Locker SI Close",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "lockerSIClose",
        "conditionalVarValue": true

    },
    {
        "varName": "CurrencyAdd",
        "type": "title",
        "label": "Currency Add",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true

    },
    {
        "varName": "existingCurrency",
        "type": "text",
        "label": "Existing Currency",
        "grid": 2,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true
    },
    {
        "varName": "newCurrency",
        "type": "autoCompleteValueReturn",
        "label": "New Currency",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true
    },
    {
        "varName": "TradeFacilitation",
        "type": "title",
        "label": "Trade Facilitation",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "tradeFacilitation",
        "conditionalVarValue": true

    },
    {
        "varName": "StaffAccountGeneration/Generalaization/Block",
        "type": "title",
        "label": "Staff Account Generation/Generalaization/Block",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "staffAccountGenerationGeneralaizationBlock",
        "conditionalVarValue": true

    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "staffAccountGenerationGeneralaizationBlock",
        "conditionalVarValue": true
    },
    {
        "varName": "CTRLimitUpdate",
        "type": "title",
        "label": "CTR Limit Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "cTRLimitUpdate",
        "conditionalVarValue": true

    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "cTRLimitUpdate",
        "conditionalVarValue": true
    },
    {
        "varName": "TemporaryDormantActivation",
        "type": "title",
        "label": "Temporary Dormant Activation",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "temporaryDormantActivation",
        "conditionalVarValue": true

    },
    {
        "varName": "Freeze/UnfreezeMark",
        "type": "title",
        "label": "Freeze/Unfreeze Mark",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "freezeUnfreezeMark",
        "conditionalVarValue": true

    },
    {
        "varName": "CityTouchTagging",
        "type": "title",
        "label": "CityTouch Tagging",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true

    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "CB Number",
        "grid": 2,
        // "errorMessage": "Error",
        // "min": 9,
        // "max": 9,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 8,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "cityTouchEmailId",
        "type": "text",
        "label": "Email",
        "grid": 2,
        // "errorMessage": "Error",
        "multiline": true,
        // "email": true,
        // "required": true,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "mobileAccounts",
        "type": "text",
        "label": "Mobile of the Accounts",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 8,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "PriorityMarking",
        "type": "title",
        "label": "Priority Marking",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "priorityMarking",
        "conditionalVarValue": true

    },
    {
        "varName": "qualifyingCode",
        "type": "text",
        "label": "Qualifying Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "priorityMarking",
        "conditionalVarValue": true
    },
    {
        "varName": "UnconfirmedAddressMarking",
        "type": "title",
        "label": "Unconfirmed Address Marking",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "unconfirmedAddressMarking",
        "conditionalVarValue": true

    },
    {
        "varName": "DeceasedMarking",
        "type": "title",
        "label": "Deceased Marking",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "deceasedMarking",
        "conditionalVarValue": true

    },
    {
        "varName": "WronglyGeneratedACClosing",
        "type": "title",
        "label": "Wrongly Generated A/C Closing",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "wronglyGeneratedACClosing",
        "conditionalVarValue": true

    },
    {
        "varName": "ForceClosing",
        "type": "title",
        "label": "Force Closing",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "forceClosing",
        "conditionalVarValue": true

    },
    {
        "varName": "SBSCodeUpdateCorrection",
        "type": "title",
        "label": "SBS Code Update/Correction",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "sBSCodeUpdateCorrection",
        "conditionalVarValue": true

    },
    {
        "type": "empty",
        "grid": 12
    },
    {
        "varName": "csBearer",
        "type": "select",
        "label": "Bearer",
        "readOnly": true,
        "enum": [
            "YES",
            "NO"
        ],
        "grid": 2
    },
    {
        "varName": "bearerApproval",
        "type": "select",
        "label": "Bearer Approval",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "csBearer",
        "conditionalVarValue": "YES",
        "enum": [
            "BM Approval",
            "Call Center Approval",
            "BM & Call Center Approval"

        ]
    }

];
let AccountMaintenanceReturn = {};
AccountMaintenanceReturn = makeReadOnlyObject(JSON.parse(JSON.stringify(maintenanceListReturn)));

const maintenanceList = [
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "varName": "12DigitTINChange",
        "type": "title",
        "label": "E Tin Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true


    },
    {
        "varName": "tinUpdateOld",
        "type": "text",
        "label": "Existing TIN No",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true


    },
    {
        "varName": "tin",
        "type": "text",
        "label": "New TIN No",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true

    },
    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 2,
        "required":true,
        "conditionalArray": true,
        "conditionalArrayList": ["digitTinChange", "tinopen"],
        "conditionalArrayValue": [true, true],
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid": 2,
        "required":true,
        "conditionalArray": true,
        "conditionalArrayList": ["digitTinChange", "tinopen"],
        "conditionalArrayValue": [true, true],
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission","digitTinChange", "tinopen"],
        "conditionalArrayValue": ["Y",true, true],
        "grid":2,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "required":true,
        "label": "Tax Submission Year",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission","digitTinChange", "tinopen"],
        "conditionalArrayValue": ["N",true, true],
        "grid": 2,
    },
    {
        "varName": "nidOrsmartcardChange",
        "type": "title",
        "label": "NID/Smart Card Number update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "smartcardOld",
        "type": "text",
        "label": "Existing Smart Card Number",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "smartCardNew",
        "type": "text",
        "label": "New Smart Card Number",
        "validation": "numeric",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "smartCardVerification",
        "type": "select",
        "label": "Smart Card Verification Confirmation",
        "enum": ["Y", "N"],
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "nidCardOld",
        "type": "text",
        "label": "Existing NID Number",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidCardNew",
        "type": "text",
        "label": "New NID Number",
        "validation": "numeric",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidCardVerification",
        "type": "select",
        "label": "NID Verification Confirmation",
        "enum": ["Y", "N"],
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "nidVerificationLink",
    //     "type": "button",
    //     "label": "NID Verification Link",
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "nidOrsmartcardChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "TitleChange",
        "type": "title",
        "label": "Title Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true

    },
    {
        "varName": "titleOld",
        "type": "autoCompleteValueReturn",
        "label": "Existing Account Title",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "existingNameOld",
        "type": "text",
        "label": "Existing Name",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "NomineeUpdate",
    //     "type": "title",
    //     "label": "Nominee Update",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "nomineeChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "nomineeNumberOld",
    //     "type": "text",
    //     "label": "Existing No of Nominee",
    //     "readOnly": true,
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "nomineeChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "SignatureCard",
        "type": "title",
        "label": "Signature Card Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "signatureCard",
        "conditionalVarValue": true

    },
    {
        "varName": "individualInformationUpdate",
        "type": "title",
        "label": "Individual Information Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "individualInformationUpdate",
        "conditionalVarValue": true,
    },
    {
        "varName": "PhotoIDChange",
        "type": "title",
        "label": "Photo ID Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,
    },
    // {
    //     "varName": "existingNID",
    //     "type": "text",
    //     "label": "Existing NID No.",
    //     "readOnly": true,
    //     "grid": 2,
    //     // "errorMessage": "Error",
    //     // "nid": true,
    //     // "required": true,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "nidNo",
    //     "type": "text",
    //     "label": "New NID No.",
    //     "grid": 2,
    //     // "errorMessage": "Error",
    //     // "nid": true,
    //     // "required": true,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "existingPassport",
        "type": "text",
        "label": "Existing Passport No.",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "passportNo",
    //     "type": "text",
    //     "label": "New Passport No.",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "existingBirthCertificate",
        "type": "text",
        "label": "Existing  Birth Certificate No.",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "newTradeLicense",
    //     "type": "text",
    //     "label": "New Trade License No.",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "existingChairmanCertificate",
        "type": "text",
        "label": "Existing Chairman Certificate No.",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "birthCertificateNo",
    //     "type": "text",
    //     "label": "New Birth Certificate No.",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "existingDrivingLicense",
        "type": "text",
        "label": "Existing Driving License No.",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "birthCertificateNo",
    //     "type": "text",
    //     "label": "New Birth Certificate No.",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "existingResidentId",
        "type": "text",
        "label": "Existing Resident Id No.",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "birthCertificateNo",
    //     "type": "text",
    //     "label": "New Birth Certificate No.",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "MailingContactNumberChange",
        "type": "title",
        "label": "Mailing Contact Number Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "existingPhoneNumber",
        "type": "text",
        "label": "Existing Phone Number",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "mobileType",
        "type": "select",
        "label": "Mobile Type",
        "enum": [
            "Local",
            "Overseas"
        ],
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "mailingContactNumberChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "commPhoneLocalCode",
        "type": "autoCompleteValueReturn",
        "label": "Country Code",
        "enum": [],
        "grid": 6,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["mailingContactNumberChange", "mobileType"],
        "conditionalArrayValue": [true, "Overseas"],
    },
    {
        "varName": "newPhoneNumber",
        "type": "text",
        "label": "New Phone Number",
        "validation": "phone",
        "phonePrefix": true,
        "required": true,
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["mailingContactNumberChange", "mobileType"],
        "conditionalArrayValue": [true, "Local"],
    },
    {
        "varName": "newPhoneNumber",
        "type": "text",
        "label": "New Phone Number",
        "validation": "phone",
        "required": true,
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["mailingContactNumberChange", "mobileType"],
        "conditionalArrayValue": [true, "Overseas"],
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "OtherContactNumberChange",
        "type": "title",
        "label": "Other Contact Number Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingResidenceNumber",
        "type": "text",
        "label": "Existing Residence Number",
        "readOnly": true,
        "grid": 6,
        // "phone": true,
        // "errorMessage": "Error",
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "residenceNumberNew",
    //     "type": "text",
    //     "label": "New Residence Number",
    //     "grid": 2,
    //     // "phone": true,
    //     // "errorMessage": "Error",
    //     "conditional": true,
    //     "conditionalVarName": "contactNumberChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherContactNumberChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "existingOfficeNumber",
        "type": "text",
        "label": "Existing Office Number",
        "readOnly": true,
        "grid": 6,
        // "errorMessage": "Error",
        // "phone": true,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    //
    // {
    //     "varName": "officeNumberNew",
    //     "type": "text",
    //     "label": "new Office Number",
    //     "grid": 2,
    //     // "errorMessage": "Error",
    //     // "phone": true,
    //     "conditional": true,
    //     "conditionalVarName": "contactNumberChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherContactNumberChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "existingMobileNumber",
        "type": "text",
        "label": "Existing Mobile Number",
        "readOnly": true,
        // "errorMessage": "Error",
        // "phone": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "mobileNumberNew",
    //     "type": "text",
    //     "label": "New Mobile Number",
    //     // "errorMessage": "Error",
    //     // "phone": true,
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "contactNumberChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherContactNumberChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "EmailAddressChange",
        "type": "title",
        "label": "Email Address Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true

    },
    {
        "varName": "emailTypeOne",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "COMMEML",
            "value": "COMMEML"
        }],
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true,
        "readOnly": true,
        "label": "Email Type",
        "grid": 6,
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "existingEmailId",
        "type": "text",
        "label": "Existing Designated Email Address",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "emailId",
        "type": "text",
        "label": "New Designated Email Address",
        "validation": "email",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "eStatementFacility",
        "type": "select",
        "label": "E Statement Enrollment",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true,
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "emailTypeTwo",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true,
        "readOnly": true,
        "label": "Email Type",
        "grid": 6,
    },
    {
        "varName": "existingEmailIdTwo",
        "type": "text",
        "label": "Existing Email Address",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "emailIdTwo",
        "type": "text",
        "label": "New Email ID",
        "validation": "email",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "eStatementFacility",
    //     "type": "select",
    //     "label": "E Statement enrollment",
    //     "readOnly": true,
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "emailAddressChange",
    //     "conditionalVarValue": true,
    //     "enum": [
    //         "YES",
    //         "NO"
    //     ]
    // },
    // {
    //     "varName": "existingEStatementFacility",
    //     "type": "select",
    //     "label": "Existing E-Statement Facility",
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "emailAddressChange",
    //     "conditionalVarValue": true,
    //     "enum": [
    //         "YES",
    //         "NO"
    //     ]
    // },
    {
        "varName": "E -StatementEnrollment",
        "type": "title",
        "label": "E - Statement Enrollment",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true,

    },
    {
        "varName": "eStatementFacility",
        "type": "text",
        "label": "Existing E-Statement Facility",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true,

    },
    {
        "varName": "eStatementFacilityNew",
        "type": "select",
        "label": "New E-Statement Facility",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true,
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "existingEmailId",
        "type": "text",
        "label": "Existing Email ID",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true
    },
    {
        "varName": "newEmailId",
        "type": "text",
        "label": "New Email ID",
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true
    },
    {
        "varName": "AddressChange",
        "type": "title",
        "label": "Address Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true

    },
    {
        "varName": "permanentAddress",
        "type": "text",
        "label": "Permanent Address",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "newPermanentAddress",
    //     "type": "text",
    //     "label": "New Permanent Address",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "presentAddress",
        "type": "text",
        "label": "Present Address",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "newPresentAddress",
    //     "type": "text",
    //     "label": "New Present Address",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "officeAddress",
        "type": "text",
        "label": "Office Address",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "newOfficeAddress",
    //     "type": "text",
    //     "label": "New Office Address",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "communicationAddress",
        "type": "text",
        "label": "Communication Address",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "newCommunicationAddress",
    //     "type": "select",
    //     "label": "New Communication Address",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true,
    //     "enum": [
    //         "Permanent Address",
    //         "Present Address",
    //         "Office Address",
    //     ],
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "OtherInformationChange",
        "type": "title",
        "label": "Other Information Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true

    },
    {
        "varName": "dobOld",
        "type": "text",
        "label": "DOB",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "dobNew",
    //     "type": "date",
    //     "label": "New DOB",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "fatherName",
        "type": "text",
        "label": "Father's Name",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "fatherName",
    //     "type": "text",
    //     "label": "Father Name Update",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "motherName",
        "type": "text",
        "label": "Mother's Name",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "motherName",
    //     "type": "text",
    //     "label": "Mother Name Update",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "spouseName",
        "type": "text",
        "label": "Spouse Name",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "spouseName",
    //     "type": "text",
    //     "label": "Spouse Name Update",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "profession",
    //     "type": "text",
    //     "label": "Profession",
    //     "readOnly": true,
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "professionNew",
    //     "type": "text",
    //     "label": "New Profession",
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    //
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "employerName",
        "type": "text",
        "label": "Employer Name",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "employerNew",
    //     "type": "text",
    //     "label": "New Employer Name",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    //
    // },
    //
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "DormantAccountActivation",
        "type": "title",
        "label": "Dormant Account Activation",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "dormantAccountActivation",
        "conditionalVarValue": true
    },
    {
        "varName": "accountStatus",
        "type": "text",
        "label": "Account Status",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dormantAccountActivation",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "dormantActivationCharge",
    //     "type": "text",
    //     "label": "Dormant Activation Charge",
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "dormantAccountActivation",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "DormantAccountDataUpdate",
        "type": "title",
        "label": "Dormant Account Data Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "dormantAccountDataUpdate",
        "conditionalVarValue": true

    },
    {
        "varName": "SchemeMaintenance-LinkA/CChange",
        "type": "title",
        "label": "Scheme Maintenance-Link A/C Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "existingLinkAccount",
        "type": "text",
        "label": "Existing Link Account",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "requestedBranchSolId",
        "type": "text",
        "label": "Requested Branch Sol ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaAmount",
        "type": "text",
        "label": "Schema Amount",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaTenor",
        "type": "text",
        "label": "Schema Tenor",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaValueDate",
        "type": "text",
        "label": "Schema Value Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaInstallmentDate",
        "type": "text",
        "label": "Schema Installment Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    // {
    //     "varName": "tenorExisting",
    //     "type": "text",
    //     "label": "Existing Tenure",
    //     "grid": 2,
    //     "readOnly": true,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "tenorNew",
    //     "type": "text",
    //     "label": "New Tenure",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "valueDateExisting",
    //     "type": "text",
    //     "label": "Existing Value Date",
    //     "grid": 2,
    //     "readOnly": true,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "valueDateNew",
    //     "type": "date",
    //     "label": "New Value Date",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "maturityDateExisting",
    //     "type": "text",
    //     "label": "Existing Maturity Date",
    //     "grid": 2,
    //     "readOnly": true,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "maturityDateNew",
    //     "type": "date",
    //     "label": "New Maturity Date",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    // },
    {
        "varName": "SchemeMaintenance-InstallmentRegularization",
        "type": "title",
        "label": "Scheme Maintenance - Installment Regularization",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true

    },
    {
        "varName": "numberInstallmentDue",
        "type": "text",
        "label": "Number of Installment(s) Due",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true
    },
    {
        "varName": "detailsofMonth",
        "type": "text",
        "label": "Details of Month",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true

    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenance",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "amountPerInstallment",
        "type": "text",
        "label": "Amount Per Installment",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true
    },
    {
        "varName": "totalInstallmentAmountDue",
        "type": "text",
        "label": "Total Installment Amount Due",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true

    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenance",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "debitDueInstallmentChargeRealization",
        "type": "text",
        "label": "Debit Due Installment & Charge Realization A/C no.",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true
    },
    {
        "varName": "transactionTypeOrSubtype",
        "type": "text",
        "label": "Transaction Type/Subtype",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true
    },
    {
        "varName": "SchemeMaintenanceInstallmentDate",
        "type": "title",
        "label": "Scheme Maintenance - Installment Date Regularization",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "solId",
        "type": "text",
        "label": "SOL ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaAmount",
        "type": "text",
        "label": "Schema Amount",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaTenor",
        "type": "text",
        "label": "Schema Tenor",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaValueDate",
        "type": "text",
        "label": "Schema Value Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaInstallmentDate",
        "type": "text",
        "label": "Schema Installment Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "existingMatuirityDate",
        "type": "text",
        "label": "Existing Matuirity Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "SignatoryUpdate",
        "type": "title",
        "label": "Signatory Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "signatoryUpdate",
        "conditionalVarValue": true

    },
    {
        "varName": "existingMandate",
        "type": "text",
        "label": "Existing Mandate",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "signatoryUpdate",
        "conditionalVarValue": true
    },
    {
        "varName": "newMandate",
        "type": "text",
        "label": "New Mandate",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "signatoryUpdate",
        "conditionalVarValue": true
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "signatoryUpdate",
        "conditionalVarValue": true
    },
    {
        "varName": "CityLive",
        "type": "title",
        "label": "City Live",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "cityLive",
        "conditionalVarValue": true

    },
    {
        "varName": "ProjectRelatedDataUpdateADUP,CIB",
        "type": "title",
        "label": "Project Related Data Update ADUP, CIB",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "projectRelatedDataUpdateADUP",
        "conditionalVarValue": true

    },
    {
        "varName": "CTRLimitUpdate/UnconfirmedAddressChange",
        "type": "title",
        "label": "CTR Limit Update/Unconfirmed Address Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "ctrLimitUpdateUnconfirmedAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "Account&SchemeClose",
        "type": "title",
        "label": "Account & Scheme Close",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "accountTitle",
        "type": "text",
        "label": "Account Title",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "accountBalance",
        "type": "text",
        "label": "Account Balance",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "faceValueForScheme",
        "type": "text",
        "label": "Face Value for scheme",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "maturityValueForScheme",
        "type": "text",
        "label": "Maturity Value for scheme",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "maturityDateForScheme",
        "type": "date",
        "label": "Maturity Date for scheme",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "lien",
        "type": "text",
        "label": "Lien",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "dormancy",
        "type": "text",
        "label": "Dormancy",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "freeze",
        "type": "text",
        "label": "Freeze",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "aDUPNormalCustomer",
        "type": "text",
        "label": "ADUP/Normal Customer",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "proxyTransaction",
        "type": "text",
        "label": "Proxy Transaction",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "eTinStatus",
        "type": "text",
        "label": "E Tin Status",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "sourceTaxCalculation",
        "type": "text",
        "label": "Source Tax Calculation",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "eDCalculation",
        "type": "text",
        "label": "ED Calculation",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "preMatureInterestCalculation",
        "type": "text",
        "label": "Pre mature interest calculation",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "debitAccountNumber",
        "type": "text",
        "label": "Debit Account Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "numberOfInstallmentPendingScheme",
        "type": "text",
        "label": "Number of Installment pending/realized for scheme",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "LockerSIOpen",
        "type": "title",
        "label": "Locker SI Open",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "lockerSIOpen",
        "conditionalVarValue": true

    },
    {
        "varName": "LockerSIClose",
        "type": "title",
        "label": "Locker SI Close",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "lockerSIClose",
        "conditionalVarValue": true

    },
    {
        "varName": "CurrencyAdd",
        "type": "title",
        "label": "Currency Add",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true

    },
    {
        "varName": "existingCurrency",
        "type": "text",
        "label": "Existing Currency",
        "grid": 6,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true
    },
    {
        "varName": "newCurrency",
        "type": "autoCompleteValueReturn",
        "label": "New Currency",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true
    },
    {
        "varName": "TradeFacilitation",
        "type": "title",
        "label": "Trade Facilitation",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "tradeFacilitation",
        "conditionalVarValue": true

    },
    {
        "varName": "StaffAccountGeneration/Generalaization/Block",
        "type": "title",
        "label": "Staff Account Generation/Generalaization/Block",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "staffAccountGenerationGeneralaizationBlock",
        "conditionalVarValue": true

    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "staffAccountGenerationGeneralaizationBlock",
        "conditionalVarValue": true
    },
    {
        "varName": "CTRLimitUpdate",
        "type": "title",
        "label": "CTR Limit Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "cTRLimitUpdate",
        "conditionalVarValue": true

    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "cTRLimitUpdate",
        "conditionalVarValue": true
    },
    {
        "varName": "TemporaryDormantActivation",
        "type": "title",
        "label": "Temporary Dormant Activation",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "temporaryDormantActivation",
        "conditionalVarValue": true

    },
    {
        "varName": "Freeze/UnfreezeMark",
        "type": "title",
        "label": "Freeze/Unfreeze Mark",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "freezeUnfreezeMark",
        "conditionalVarValue": true

    },
    {
        "varName": "CityTouchTagging",
        "type": "title",
        "label": "CityTouch Tagging",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true

    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "CB Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "cityTouchEmailId",
        "type": "text",
        "label": "Email",
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "mobileAccounts",
        "type": "text",
        "label": "Mobile of the Accounts",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 8,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "PriorityMarking",
        "type": "title",
        "label": "Priority Marking",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "priorityMarking",
        "conditionalVarValue": true

    },
    {
        "varName": "qualifyingCode",
        "type": "text",
        "label": "Qualifying Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "priorityMarking",
        "conditionalVarValue": true
    },
    {
        "varName": "UnconfirmedAddressMarking",
        "type": "title",
        "label": "Unconfirmed Address Marking",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "unconfirmedAddressMarking",
        "conditionalVarValue": true

    },
    {
        "varName": "DeceasedMarking",
        "type": "title",
        "label": "Deceased Marking",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "deceasedMarking",
        "conditionalVarValue": true

    },
    {
        "varName": "WronglyGeneratedACClosing",
        "type": "title",
        "label": "Wrongly Generated A/C Closing",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "wronglyGeneratedACClosing",
        "conditionalVarValue": true

    },
    {
        "varName": "ForceClosing",
        "type": "title",
        "label": "Force Closing",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "forceClosing",
        "conditionalVarValue": true

    },
    {
        "varName": "SBSCodeUpdateCorrection",
        "type": "title",
        "label": "SBS Code Update/Correction",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "sBSCodeUpdateCorrection",
        "conditionalVarValue": true

    },
    {
        "type": "empty",
        "grid": 12
    },

];


let BOMAccountMaintenance = {};
BOMAccountMaintenance = makeReadOnlyObject(JSON.parse(JSON.stringify(maintenanceList)));
const maintenanceListBM = [

    {
        "type": "empty",
        "grid": 12,
    },
    {
        "varName": "12DigitTINChange",
        "type": "title",
        "label": "E Tin Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true


    },
    {
        "varName": "tinUpdateOld",
        "type": "text",
        "label": "Existing TIN No",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true


    },
    {
        "varName": "tin",
        "type": "text",
        "label": "New TIN No",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true

    },
    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 2,
        "required":true,
        "conditionalArray": true,
        "conditionalArrayList": ["digitTinChange", "tinopen"],
        "conditionalArrayValue": [true, true],
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid": 2,
        "required":true,
        "conditionalArray": true,
        "conditionalArrayList": ["digitTinChange", "tinopen"],
        "conditionalArrayValue": [true, true],
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission","digitTinChange", "tinopen"],
        "conditionalArrayValue": ["Y",true, true],
        "grid":2,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "required":true,
        "label": "Tax Submission Year",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission","digitTinChange", "tinopen"],
        "conditionalArrayValue": ["N",true, true],
        "grid": 2,
    },
    {
        "varName": "nidOrsmartcardChange",
        "type": "title",
        "label": "NID/Smart Card Number update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "smartcardOld",
        "type": "text",
        "label": "Existing Smart Card Number",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "smartCardNew",
        "type": "text",
        "label": "New Smart Card Number",
        "validation": "numeric",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "smartCardVerification",
        "type": "select",
        "label": "Smart Card Verification Confirmation",
        "enum": ["Y", "N"],
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "empty",
        "type": "empty",
        "grid": 3,
    },
    {
        "varName": "nidCardOld",
        "type": "text",
        "label": "Existing NID Number",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidCardNew",
        "type": "text",
        "label": "New NID Number",
        "validation": "numeric",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidCardVerification",
        "type": "select",
        "label": "NID Verification Confirmation",
        "enum": ["Y", "N"],
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "nidVerificationLink",
    //     "type": "button",
    //     "label": "NID Verification Link",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "nidOrsmartcardChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "TitleChange",
        "type": "title",
        "label": "Title Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true

    },
    {
        "varName": "titleOld",
        "type": "autoCompleteValueReturn",
        "label": "Existing Account Title",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "existingNameOld",
        "type": "text",
        "label": "Existing Name",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "NomineeUpdate",
    //     "type": "title",
    //     "label": "Nominee Update",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "nomineeChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "nomineeNumberOld",
    //     "type": "text",
    //     "label": "Existing No of Nominee",
    //     "readOnly": true,
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "nomineeChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "SignatureCard",
        "type": "title",
        "label": "Signature Card Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "signatureCard",
        "conditionalVarValue": true

    },
    {
        "varName": "individualInformationUpdate",
        "type": "title",
        "label": "Individual Information Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "individualInformationUpdate",
        "conditionalVarValue": true,
    },
    {
        "varName": "PhotoIDChange",
        "type": "title",
        "label": "Photo ID Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,
    },
    // {
    //     "varName": "existingNID",
    //     "type": "text",
    //     "label": "Existing NID No.",
    //     "readOnly": true,
    //     "grid": 2,
    //     // "errorMessage": "Error",
    //     // "nid": true,
    //     // "required": true,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "nidNo",
    //     "type": "text",
    //     "label": "New NID No.",
    //     "grid": 2,
    //     // "errorMessage": "Error",
    //     // "nid": true,
    //     // "required": true,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "existingPassport",
        "type": "text",
        "label": "Existing Passport No.",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "passportNo",
    //     "type": "text",
    //     "label": "New Passport No.",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "existingBirthCertificate",
        "type": "text",
        "label": "Existing  Birth Certificate No.",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "newTradeLicense",
    //     "type": "text",
    //     "label": "New Trade License No.",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "existingChairmanCertificate",
        "type": "text",
        "label": "Existing Chairman Certificate No.",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "birthCertificateNo",
    //     "type": "text",
    //     "label": "New Birth Certificate No.",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "existingDrivingLicense",
        "type": "text",
        "label": "Existing Driving License No.",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "birthCertificateNo",
    //     "type": "text",
    //     "label": "New Birth Certificate No.",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "existingResidentId",
        "type": "text",
        "label": "Existing Resident Id No.",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "birthCertificateNo",
    //     "type": "text",
    //     "label": "New Birth Certificate No.",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "photoIdChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "MailingContactNumberChange",
        "type": "title",
        "label": "Mailing Contact Number Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "existingPhoneNumber",
        "type": "text",
        "label": "Existing Phone Number",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "mobileType",
        "type": "select",
        "label": "Mobile Type",
        "enum": [
            "Local",
            "Overseas"
        ],
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "commPhoneLocalCode",
        "type": "autoCompleteValueReturn",
        "label": "Country Code",
        "enum": [],
        "grid": 3,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["mailingContactNumberChange", "mobileType"],
        "conditionalArrayValue": [true, "Overseas"],
    },
    {
        "varName": "newPhoneNumber",
        "type": "text",
        "label": "New Phone Number",
        "validation": "phone",
        "phonePrefix": true,
        "required": true,
        "grid": 3,
        "conditionalArray": true,
        "conditionalArrayList": ["mailingContactNumberChange", "mobileType"],
        "conditionalArrayValue": [true, "Local"],
    },
    {
        "varName": "newPhoneNumber",
        "type": "text",
        "label": "New Phone Number",
        "validation": "phone",
        "required": true,
        "grid": 3,
        "conditionalArray": true,
        "conditionalArrayList": ["mailingContactNumberChange", "mobileType"],
        "conditionalArrayValue": [true, "Overseas"],
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "OtherContactNumberChange",
        "type": "title",
        "label": "Other Contact Number Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingResidenceNumber",
        "type": "text",
        "label": "Existing Residence Number",
        "readOnly": true,
        "grid": 3,
        // "phone": true,
        // "errorMessage": "Error",
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "residenceNumberNew",
    //     "type": "text",
    //     "label": "New Residence Number",
    //     "grid": 2,
    //     // "phone": true,
    //     // "errorMessage": "Error",
    //     "conditional": true,
    //     "conditionalVarName": "contactNumberChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherContactNumberChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "existingOfficeNumber",
        "type": "text",
        "label": "Existing Office Number",
        "readOnly": true,
        "grid": 3,
        // "errorMessage": "Error",
        // "phone": true,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    //
    // {
    //     "varName": "officeNumberNew",
    //     "type": "text",
    //     "label": "new Office Number",
    //     "grid": 2,
    //     // "errorMessage": "Error",
    //     // "phone": true,
    //     "conditional": true,
    //     "conditionalVarName": "contactNumberChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherContactNumberChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "existingMobileNumber",
        "type": "text",
        "label": "Existing Mobile Number",
        "readOnly": true,
        // "errorMessage": "Error",
        // "phone": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "mobileNumberNew",
    //     "type": "text",
    //     "label": "New Mobile Number",
    //     // "errorMessage": "Error",
    //     // "phone": true,
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "contactNumberChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherContactNumberChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "EmailAddressChange",
        "type": "title",
        "label": "Email Address Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true

    },
    {
        "varName": "emailTypeOne",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "COMMEML",
            "value": "COMMEML"
        }],
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true,
        "readOnly": true,
        "label": "Email Type",
        "grid": 3,
    },
    {
        "varName": "existingEmailId",
        "type": "text",
        "label": "Existing Designated Email Address",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "emailId",
        "type": "text",
        "label": "New Designated Email Address",
        "validation": "email",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "eStatementFacility",
        "type": "select",
        "label": "E Statement Enrollment",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true,
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "emailTypeTwo",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true,
        "readOnly": true,
        "label": "Email Type",
        "grid": 3,
    },
    {
        "varName": "existingEmailIdTwo",
        "type": "text",
        "label": "Existing Email Address",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "emailIdTwo",
        "type": "text",
        "label": "New Email ID",
        "validation": "email",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "eStatementFacility",
    //     "type": "select",
    //     "label": "E Statement enrollment",
    //     "readOnly": true,
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "emailAddressChange",
    //     "conditionalVarValue": true,
    //     "enum": [
    //         "YES",
    //         "NO"
    //     ]
    // },
    // {
    //     "varName": "existingEStatementFacility",
    //     "type": "select",
    //     "label": "Existing E-Statement Facility",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "emailAddressChange",
    //     "conditionalVarValue": true,
    //     "enum": [
    //         "YES",
    //         "NO"
    //     ]
    // },
    {
        "varName": "E -StatementEnrollment",
        "type": "title",
        "label": "E - Statement Enrollment",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true,

    },
    {
        "varName": "eStatementFacility",
        "type": "text",
        "label": "Existing E-Statement Facility",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true,

    },
    {
        "varName": "eStatementFacilityNew",
        "type": "select",
        "label": "New E-Statement Facility",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true,
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "existingEmailId",
        "type": "text",
        "label": "Existing Email ID",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true
    },
    {
        "varName": "newEmailId",
        "type": "text",
        "label": "New Email ID",
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true
    },
    {
        "varName": "AddressChange",
        "type": "title",
        "label": "Address Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true

    },
    {
        "varName": "permanentAddress",
        "type": "text",
        "label": "Permanent Address",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "newPermanentAddress",
    //     "type": "text",
    //     "label": "New Permanent Address",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "presentAddress",
        "type": "text",
        "label": "Present Address",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "newPresentAddress",
    //     "type": "text",
    //     "label": "New Present Address",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "officeAddress",
        "type": "text",
        "label": "Office Address",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "newOfficeAddress",
    //     "type": "text",
    //     "label": "New Office Address",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "communicationAddress",
        "type": "text",
        "label": "Communication Address",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "newCommunicationAddress",
    //     "type": "select",
    //     "label": "New Communication Address",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true,
    //     "enum": [
    //         "Permanent Address",
    //         "Present Address",
    //         "Office Address",
    //     ],
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "OtherInformationChange",
        "type": "title",
        "label": "Other Information Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true

    },
    {
        "varName": "dobOld",
        "type": "text",
        "label": "DOB",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "dobNew",
    //     "type": "date",
    //     "label": "New DOB",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "fatherName",
        "type": "text",
        "label": "Father's Name",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "fatherName",
    //     "type": "text",
    //     "label": "Father Name Update",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "motherName",
        "type": "text",
        "label": "Mother's Name",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "motherName",
    //     "type": "text",
    //     "label": "Mother Name Update",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "spouseName",
        "type": "text",
        "label": "Spouse Name",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "spouseName",
    //     "type": "text",
    //     "label": "Spouse Name Update",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "profession",
    //     "type": "text",
    //     "label": "Profession",
    //     "readOnly": true,
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "professionNew",
    //     "type": "text",
    //     "label": "New Profession",
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    //
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "employerName",
        "type": "text",
        "label": "Employer Name",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "employerNew",
    //     "type": "text",
    //     "label": "New Employer Name",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    //
    // },
    //
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "DormantAccountActivation",
        "type": "title",
        "label": "Dormant Account Activation",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "dormantAccountActivation",
        "conditionalVarValue": true
    },
    {
        "varName": "accountStatus",
        "type": "text",
        "label": "Account Status",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "dormantAccountActivation",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "dormantActivationCharge",
    //     "type": "text",
    //     "label": "Dormant Activation Charge",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "dormantAccountActivation",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "DormantAccountDataUpdate",
        "type": "title",
        "label": "Dormant Account Data Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "dormantAccountDataUpdate",
        "conditionalVarValue": true

    },
    {
        "varName": "SchemeMaintenance-LinkA/CChange",
        "type": "title",
        "label": "Scheme Maintenance-Link A/C Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "existingLinkAccount",
        "type": "text",
        "label": "Existing Link Account",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "requestedBranchSolId",
        "type": "text",
        "label": "Requested Branch Sol ID",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaAmount",
        "type": "text",
        "label": "Schema Amount",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaTenor",
        "type": "text",
        "label": "Schema Tenor",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaValueDate",
        "type": "text",
        "label": "Schema Value Date",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaInstallmentDate",
        "type": "text",
        "label": "Schema Installment Date",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    // {
    //     "varName": "tenorExisting",
    //     "type": "text",
    //     "label": "Existing Tenure",
    //     "grid": 2,
    //     "readOnly": true,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "tenorNew",
    //     "type": "text",
    //     "label": "New Tenure",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "valueDateExisting",
    //     "type": "text",
    //     "label": "Existing Value Date",
    //     "grid": 2,
    //     "readOnly": true,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "valueDateNew",
    //     "type": "date",
    //     "label": "New Value Date",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "maturityDateExisting",
    //     "type": "text",
    //     "label": "Existing Maturity Date",
    //     "grid": 2,
    //     "readOnly": true,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "maturityDateNew",
    //     "type": "date",
    //     "label": "New Maturity Date",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    // },
    {
        "varName": "SchemeMaintenance-InstallmentRegularization",
        "type": "title",
        "label": "Scheme Maintenance - Installment Regularization",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true

    },
    {
        "varName": "numberInstallmentDue",
        "type": "text",
        "label": "Number of Installment(s) Due",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true
    },
    {
        "varName": "detailsofMonth",
        "type": "text",
        "label": "Details of Month",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true

    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenance",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "amountPerInstallment",
        "type": "text",
        "label": "Amount Per Installment",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true
    },
    {
        "varName": "totalInstallmentAmountDue",
        "type": "text",
        "label": "Total Installment Amount Due",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true

    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenance",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "debitDueInstallmentChargeRealization",
        "type": "text",
        "label": "Debit Due Installment & Charge Realization A/C no.",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true
    },
    {
        "varName": "transactionTypeOrSubtype",
        "type": "text",
        "label": "Transaction Type/Subtype",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true
    },
    {
        "varName": "SchemeMaintenanceInstallmentDate",
        "type": "title",
        "label": "Scheme Maintenance - Installment Date Regularization",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "solId",
        "type": "text",
        "label": "SOL ID",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaAmount",
        "type": "text",
        "label": "Schema Amount",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaTenor",
        "type": "text",
        "label": "Schema Tenor",
        "grid": 3,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaValueDate",
        "type": "text",
        "label": "Schema Value Date",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaInstallmentDate",
        "type": "text",
        "label": "Schema Installment Date",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "existingMatuirityDate",
        "type": "text",
        "label": "Existing Matuirity Date",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "SignatoryUpdate",
        "type": "title",
        "label": "Signatory Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "signatoryUpdate",
        "conditionalVarValue": true

    },
    {
        "varName": "existingMandate",
        "type": "text",
        "label": "Existing Mandate",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "signatoryUpdate",
        "conditionalVarValue": true
    },
    {
        "varName": "newMandate",
        "type": "text",
        "label": "New Mandate",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "signatoryUpdate",
        "conditionalVarValue": true
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "signatoryUpdate",
        "conditionalVarValue": true
    },
    {
        "varName": "CityLive",
        "type": "title",
        "label": "City Live",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "cityLive",
        "conditionalVarValue": true

    },
    {
        "varName": "ProjectRelatedDataUpdateADUP,CIB",
        "type": "title",
        "label": "Project Related Data Update ADUP, CIB",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "projectRelatedDataUpdateADUP",
        "conditionalVarValue": true

    },
    {
        "varName": "CTRLimitUpdate/UnconfirmedAddressChange",
        "type": "title",
        "label": "CTR Limit Update/Unconfirmed Address Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "ctrLimitUpdateUnconfirmedAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "Account&SchemeClose",
        "type": "title",
        "label": "Account & Scheme Close",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "accountTitle",
        "type": "text",
        "label": "Account Title",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "accountBalance",
        "type": "text",
        "label": "Account Balance",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "faceValueForScheme",
        "type": "text",
        "label": "Face Value for scheme",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "maturityValueForScheme",
        "type": "text",
        "label": "Maturity Value for scheme",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "maturityDateForScheme",
        "type": "date",
        "label": "Maturity Date for scheme",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "lien",
        "type": "text",
        "label": "Lien",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "dormancy",
        "type": "text",
        "label": "Dormancy",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "freeze",
        "type": "text",
        "label": "Freeze",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "aDUPNormalCustomer",
        "type": "text",
        "label": "ADUP/Normal Customer",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "proxyTransaction",
        "type": "text",
        "label": "Proxy Transaction",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "eTinStatus",
        "type": "text",
        "label": "E Tin Status",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "sourceTaxCalculation",
        "type": "text",
        "label": "Source Tax Calculation",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "eDCalculation",
        "type": "text",
        "label": "ED Calculation",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "preMatureInterestCalculation",
        "type": "text",
        "label": "Pre mature interest calculation",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "debitAccountNumber",
        "type": "text",
        "label": "Debit Account Number",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "numberOfInstallmentPendingScheme",
        "type": "text",
        "label": "Number of Installment pending/realized for scheme",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "LockerSIOpen",
        "type": "title",
        "label": "Locker SI Open",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "lockerSIOpen",
        "conditionalVarValue": true

    },
    {
        "varName": "LockerSIClose",
        "type": "title",
        "label": "Locker SI Close",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "lockerSIClose",
        "conditionalVarValue": true

    },
    {
        "varName": "CurrencyAdd",
        "type": "title",
        "label": "Currency Add",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true

    },
    {
        "varName": "existingCurrency",
        "type": "text",
        "label": "Existing Currency",
        "grid": 3,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true
    },
    {
        "varName": "newCurrency",
        "type": "autoCompleteValueReturn",
        "label": "New Currency",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true
    },
    {
        "varName": "TradeFacilitation",
        "type": "title",
        "label": "Trade Facilitation",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "tradeFacilitation",
        "conditionalVarValue": true

    },
    {
        "varName": "StaffAccountGeneration/Generalaization/Block",
        "type": "title",
        "label": "Staff Account Generation/Generalaization/Block",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "staffAccountGenerationGeneralaizationBlock",
        "conditionalVarValue": true

    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "staffAccountGenerationGeneralaizationBlock",
        "conditionalVarValue": true
    },
    {
        "varName": "CTRLimitUpdate",
        "type": "title",
        "label": "CTR Limit Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "cTRLimitUpdate",
        "conditionalVarValue": true

    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "cTRLimitUpdate",
        "conditionalVarValue": true
    },
    {
        "varName": "TemporaryDormantActivation",
        "type": "title",
        "label": "Temporary Dormant Activation",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "temporaryDormantActivation",
        "conditionalVarValue": true

    },
    {
        "varName": "Freeze/UnfreezeMark",
        "type": "title",
        "label": "Freeze/Unfreeze Mark",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "freezeUnfreezeMark",
        "conditionalVarValue": true

    },
    {
        "varName": "CityTouchTagging",
        "type": "title",
        "label": "CityTouch Tagging",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true

    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "CB Number",
        "grid": 3,
        // "errorMessage": "Error",
        // "min": 9,
        // "max": 9,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "cityTouchEmailId",
        "type": "text",
        "label": "Email",
        "grid": 3,
        "validation": "email",
        // "errorMessage": "Error",
        "multiline": true,
        // "email": true,
        // "required": true,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "mobileAccounts",
        "type": "text",
        "label": "Mobile of the Accounts",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "PriorityMarking",
        "type": "title",
        "label": "Priority Marking",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "priorityMarking",
        "conditionalVarValue": true

    },
    {
        "varName": "qualifyingCode",
        "type": "text",
        "label": "Qualifying Code",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "priorityMarking",
        "conditionalVarValue": true
    },
    {
        "varName": "UnconfirmedAddressMarking",
        "type": "title",
        "label": "Unconfirmed Address Marking",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "unconfirmedAddressMarking",
        "conditionalVarValue": true

    },
    {
        "varName": "DeceasedMarking",
        "type": "title",
        "label": "Deceased Marking",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "deceasedMarking",
        "conditionalVarValue": true

    },
    {
        "varName": "WronglyGeneratedACClosing",
        "type": "title",
        "label": "Wrongly Generated A/C Closing",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "wronglyGeneratedACClosing",
        "conditionalVarValue": true

    },
    {
        "varName": "ForceClosing",
        "type": "title",
        "label": "Force Closing",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "forceClosing",
        "conditionalVarValue": true

    },
    {
        "varName": "SBSCodeUpdateCorrection",
        "type": "title",
        "label": "SBS Code Update/Correction",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "sBSCodeUpdateCorrection",
        "conditionalVarValue": true

    },
    {
        "type": "empty",
        "grid": 12
    },

];
let BMAccountMaintenance = {};
BMAccountMaintenance = makeReadOnlyObject(JSON.parse(JSON.stringify(maintenanceListBM)));
const maintenanceListCall = [
    // {
    //     "varName": "eqmNumber",
    //     "type": "text",
    //     "required": true,
    //     "label": "EQM Number",
    //     "grid": 6,
    //
    // },
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "varName": "12DigitTINChange",
        "type": "title",
        "label": "E Tin Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true


    },
    {
        "varName": "tinUpdateOld",
        "type": "text",
        "label": "Existing TIN No",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true


    },
    {
        "varName": "tin",
        "type": "text",
        "label": "New TIN No",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true

    },
    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 2,
        "required":true,
        "conditionalArray": true,
        "conditionalArrayList": ["digitTinChange", "tinopen"],
        "conditionalArrayValue": [true, true],
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid": 2,
        "required":true,
        "conditionalArray": true,
        "conditionalArrayList": ["digitTinChange", "tinopen"],
        "conditionalArrayValue": [true, true],
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission","digitTinChange", "tinopen"],
        "conditionalArrayValue": ["Y",true, true],
        "grid":2,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "required":true,
        "label": "Tax Submission Year",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission","digitTinChange", "tinopen"],
        "conditionalArrayValue": ["N",true, true],
        "grid": 2,
    },
    {
        "varName": "nidOrsmartcardChange",
        "type": "title",
        "label": "NID/Smart Card Number update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "smartcardOld",
        "type": "text",
        "label": "Existing Smart Card Number",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "smartCardNew",
        "type": "text",
        "label": "New Smart Card Number",
        "validation": "numeric",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "smartCardVerification",
        "type": "select",
        "label": "Smart Card Verification Confirmation",
        "enum": ["Y", "N"],
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "nidCardOld",
        "type": "text",
        "label": "Existing NID Number",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidCardNew",
        "type": "text",
        "label": "New NID Number",
        "validation": "numeric",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nidCardVerification",
        "type": "select",
        "label": "NID Verification Confirmation",
        "enum": ["Y", "N"],
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidOrsmartcardChange",
        "conditionalVarValue": true
    },
    {
        "varName": "TitleChange",
        "type": "title",
        "label": "Title Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true

    },
    {
        "varName": "titleOld",
        "type": "autoCompleteValueReturn",
        "label": "Existing Account Title",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "existingNameOld",
        "type": "text",
        "label": "Existing Name",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "SignatureCard",
        "type": "title",
        "label": "Signature Card Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "signatureCard",
        "conditionalVarValue": true

    },
    {
        "varName": "individualInformationUpdate",
        "type": "title",
        "label": "Individual Information Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "individualInformationUpdate",
        "conditionalVarValue": true,
    },
    {
        "varName": "PhotoIDChange",
        "type": "title",
        "label": "Photo ID Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "existingPassport",
        "type": "text",
        "label": "Existing Passport No.",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingBirthCertificate",
        "type": "text",
        "label": "Existing  Birth Certificate No.",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingChairmanCertificate",
        "type": "text",
        "label": "Existing Chairman Certificate No.",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingDrivingLicense",
        "type": "text",
        "label": "Existing Driving License No.",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingResidentId",
        "type": "text",
        "label": "Existing Resident Id No.",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "MailingContactNumberChange",
        "type": "title",
        "label": "Mailing Contact Number Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "existingPhoneNumber",
        "type": "text",
        "label": "Existing Phone Number",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "mobileType",
        "type": "select",
        "label": "Mobile Type",
        "enum": [
            "Local",
            "Overseas"
        ],
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 6,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["mailingContactNumberChange", "mobileType"],
    //     "conditionalArrayValue": [true, "Local"],
    // },
    {
        "varName": "commPhoneLocalCode",
        "type": "autoCompleteValueReturn",
        "label": "Country Code",
        "enum": [],
        "grid": 6,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["mailingContactNumberChange", "mobileType"],
        "conditionalArrayValue": [true, "Overseas"],
    },
    {
        "varName": "newPhoneNumber",
        "type": "text",
        "label": "New Phone Number",
        "validation": "phone",
        "phonePrefix": true,
        "required": true,
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["mailingContactNumberChange", "mobileType"],
        "conditionalArrayValue": [true, "Local"],
    },
    {
        "varName": "newPhoneNumber",
        "type": "text",
        "label": "New Phone Number",
        "validation": "phone",
        "required": true,
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["mailingContactNumberChange", "mobileType"],
        "conditionalArrayValue": [true, "Overseas"],
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "mailingContactNumberChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "OtherContactNumberChange",
        "type": "title",
        "label": "Other Contact Number Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingResidenceNumber",
        "type": "text",
        "label": "Existing Residence Number",
        "readOnly": true,
        "grid": 6,
        // "phone": true,
        // "errorMessage": "Error",
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "residenceNumberNew",
    //     "type": "text",
    //     "label": "New Residence Number",
    //     "grid": 2,
    //     // "phone": true,
    //     // "errorMessage": "Error",
    //     "conditional": true,
    //     "conditionalVarName": "contactNumberChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherContactNumberChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "existingOfficeNumber",
        "type": "text",
        "label": "Existing Office Number",
        "readOnly": true,
        "grid": 6,
        // "errorMessage": "Error",
        // "phone": true,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingMobileNumber",
        "type": "text",
        "label": "Existing Mobile Number",
        "readOnly": true,
        // "errorMessage": "Error",
        // "phone": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "EmailAddressChange",
        "type": "title",
        "label": "Email Address Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true

    },
    {
        "varName": "emailTypeOne",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "COMMEML",
            "value": "COMMEML"
        }],
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true,
        "readOnly": true,
        "label": "Email Type",
        "grid": 6,
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "existingEmailId",
        "type": "text",
        "label": "Existing Designated Email Address",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "emailId",
        "type": "text",
        "label": "New Designated Email Address",
        "validation": "email",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "eStatementFacility",
        "type": "select",
        "label": "E Statement Enrollment",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true,
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "emailTypeTwo",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true,
        "readOnly": true,
        "label": "Email Type",
        "grid": 6,
    },
    {
        "varName": "existingEmailIdTwo",
        "type": "text",
        "label": "Existing Email Address",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "emailIdTwo",
        "type": "text",
        "label": "New Email ID",
        "validation": "email",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "E -StatementEnrollment",
        "type": "title",
        "label": "E - Statement Enrollment",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true,

    },
    {
        "varName": "eStatementFacility",
        "type": "text",
        "label": "Existing E-Statement Facility",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true,

    },
    {
        "varName": "eStatementFacilityNew",
        "type": "select",
        "label": "New E-Statement Facility",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true,
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "existingEmailId",
        "type": "text",
        "label": "Existing Email ID",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true
    },
    {
        "varName": "newEmailId",
        "type": "text",
        "label": "New Email ID",
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true
    },
    {
        "varName": "AddressChange",
        "type": "title",
        "label": "Address Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true

    },
    {
        "varName": "permanentAddress",
        "type": "text",
        "label": "Permanent Address",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "newPermanentAddress",
    //     "type": "text",
    //     "label": "New Permanent Address",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "presentAddress",
        "type": "text",
        "label": "Present Address",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "newPresentAddress",
    //     "type": "text",
    //     "label": "New Present Address",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "officeAddress",
        "type": "text",
        "label": "Office Address",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "newOfficeAddress",
    //     "type": "text",
    //     "label": "New Office Address",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "communicationAddress",
        "type": "text",
        "label": "Communication Address",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "newCommunicationAddress",
    //     "type": "select",
    //     "label": "New Communication Address",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true,
    //     "enum": [
    //         "Permanent Address",
    //         "Present Address",
    //         "Office Address",
    //     ],
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "addressChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "OtherInformationChange",
        "type": "title",
        "label": "Other Information Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true

    },
    {
        "varName": "dobOld",
        "type": "text",
        "label": "DOB",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "dobNew",
    //     "type": "date",
    //     "label": "New DOB",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "fatherName",
        "type": "text",
        "label": "Father's Name",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "fatherName",
    //     "type": "text",
    //     "label": "Father Name Update",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "motherName",
        "type": "text",
        "label": "Mother's Name",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "motherName",
    //     "type": "text",
    //     "label": "Mother Name Update",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "spouseName",
        "type": "text",
        "label": "Spouse Name",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "spouseName",
    //     "type": "text",
    //     "label": "Spouse Name Update",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "profession",
    //     "type": "text",
    //     "label": "Profession",
    //     "readOnly": true,
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "professionNew",
    //     "type": "text",
    //     "label": "New Profession",
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    //
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "employerName",
        "type": "text",
        "label": "Employer Name",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "employerNew",
    //     "type": "text",
    //     "label": "New Employer Name",
    //     "grid": 2,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    //
    // },
    //
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "DormantAccountActivation",
        "type": "title",
        "label": "Dormant Account Activation",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "dormantAccountActivation",
        "conditionalVarValue": true
    },
    {
        "varName": "accountStatus",
        "type": "text",
        "label": "Account Status",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dormantAccountActivation",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "dormantActivationCharge",
    //     "type": "text",
    //     "label": "Dormant Activation Charge",
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "dormantAccountActivation",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "DormantAccountDataUpdate",
        "type": "title",
        "label": "Dormant Account Data Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "dormantAccountDataUpdate",
        "conditionalVarValue": true

    },
    {
        "varName": "SchemeMaintenance-LinkA/CChange",
        "type": "title",
        "label": "Scheme Maintenance-Link A/C Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "existingLinkAccount",
        "type": "text",
        "label": "Existing Link Account",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "requestedBranchSolId",
        "type": "text",
        "label": "Requested Branch Sol ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaAmount",
        "type": "text",
        "label": "Schema Amount",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaTenor",
        "type": "text",
        "label": "Schema Tenor",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaValueDate",
        "type": "text",
        "label": "Schema Value Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaInstallmentDate",
        "type": "text",
        "label": "Schema Installment Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    // {
    //     "varName": "tenorExisting",
    //     "type": "text",
    //     "label": "Existing Tenure",
    //     "grid": 2,
    //     "readOnly": true,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "tenorNew",
    //     "type": "text",
    //     "label": "New Tenure",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "valueDateExisting",
    //     "type": "text",
    //     "label": "Existing Value Date",
    //     "grid": 2,
    //     "readOnly": true,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "valueDateNew",
    //     "type": "date",
    //     "label": "New Value Date",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "maturityDateExisting",
    //     "type": "text",
    //     "label": "Existing Maturity Date",
    //     "grid": 2,
    //     "readOnly": true,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "maturityDateNew",
    //     "type": "date",
    //     "label": "New Maturity Date",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenanceLinkChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    // },
    {
        "varName": "SchemeMaintenance-InstallmentRegularization",
        "type": "title",
        "label": "Scheme Maintenance - Installment Regularization",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true

    },
    {
        "varName": "numberInstallmentDue",
        "type": "text",
        "label": "Number of Installment(s) Due",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true
    },
    {
        "varName": "detailsofMonth",
        "type": "text",
        "label": "Details of Month",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true

    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenance",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "amountPerInstallment",
        "type": "text",
        "label": "Amount Per Installment",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true
    },
    {
        "varName": "totalInstallmentAmountDue",
        "type": "text",
        "label": "Total Installment Amount Due",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true

    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 8,
    //     "conditional": true,
    //     "conditionalVarName": "schemeMaintenance",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "debitDueInstallmentChargeRealization",
        "type": "text",
        "label": "Debit Due Installment & Charge Realization A/C no.",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true
    },
    {
        "varName": "transactionTypeOrSubtype",
        "type": "text",
        "label": "Transaction Type/Subtype",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true
    },
    {
        "varName": "SchemeMaintenanceInstallmentDate",
        "type": "title",
        "label": "Scheme Maintenance - Installment Date Regularization",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "solId",
        "type": "text",
        "label": "SOL ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaAmount",
        "type": "text",
        "label": "Schema Amount",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaTenor",
        "type": "text",
        "label": "Schema Tenor",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaValueDate",
        "type": "text",
        "label": "Schema Value Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaInstallmentDate",
        "type": "text",
        "label": "Schema Installment Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "existingMatuirityDate",
        "type": "text",
        "label": "Existing Matuirity Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "SignatoryUpdate",
        "type": "title",
        "label": "Signatory Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "signatoryUpdate",
        "conditionalVarValue": true

    },
    {
        "varName": "existingMandate",
        "type": "text",
        "label": "Existing Mandate",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "signatoryUpdate",
        "conditionalVarValue": true
    },
    {
        "varName": "newMandate",
        "type": "text",
        "label": "New Mandate",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "signatoryUpdate",
        "conditionalVarValue": true
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "signatoryUpdate",
        "conditionalVarValue": true
    },
    {
        "varName": "CityLive",
        "type": "title",
        "label": "City Live",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "cityLive",
        "conditionalVarValue": true

    },
    {
        "varName": "ProjectRelatedDataUpdateADUP,CIB",
        "type": "title",
        "label": "Project Related Data Update ADUP, CIB",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "projectRelatedDataUpdateADUP",
        "conditionalVarValue": true

    },
    {
        "varName": "CTRLimitUpdate/UnconfirmedAddressChange",
        "type": "title",
        "label": "CTR Limit Update/Unconfirmed Address Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "ctrLimitUpdateUnconfirmedAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "Account&SchemeClose",
        "type": "title",
        "label": "Account & Scheme Close",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "accountTitle",
        "type": "text",
        "label": "Account Title",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "accountBalance",
        "type": "text",
        "label": "Account Balance",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "faceValueForScheme",
        "type": "text",
        "label": "Face Value for scheme",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "maturityValueForScheme",
        "type": "text",
        "label": "Maturity Value for scheme",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "maturityDateForScheme",
        "type": "date",
        "label": "Maturity Date for scheme",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "lien",
        "type": "text",
        "label": "Lien",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "dormancy",
        "type": "text",
        "label": "Dormancy",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "freeze",
        "type": "text",
        "label": "Freeze",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "aDUPNormalCustomer",
        "type": "text",
        "label": "ADUP/Normal Customer",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "proxyTransaction",
        "type": "text",
        "label": "Proxy Transaction",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "eTinStatus",
        "type": "text",
        "label": "E Tin Status",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "sourceTaxCalculation",
        "type": "text",
        "label": "Source Tax Calculation",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "eDCalculation",
        "type": "text",
        "label": "ED Calculation",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "preMatureInterestCalculation",
        "type": "text",
        "label": "Pre mature interest calculation",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "debitAccountNumber",
        "type": "text",
        "label": "Debit Account Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "numberOfInstallmentPendingScheme",
        "type": "text",
        "label": "Number of Installment pending/realized for scheme",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "LockerSIOpen",
        "type": "title",
        "label": "Locker SI Open",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "lockerSIOpen",
        "conditionalVarValue": true

    },
    {
        "varName": "LockerSIClose",
        "type": "title",
        "label": "Locker SI Close",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "lockerSIClose",
        "conditionalVarValue": true

    },
    {
        "varName": "CurrencyAdd",
        "type": "title",
        "label": "Currency Add",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true

    },
    {
        "varName": "existingCurrency",
        "type": "text",
        "label": "Existing Currency",
        "grid": 6,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true
    },
    {
        "varName": "newCurrency",
        "type": "autoCompleteValueReturn",
        "label": "New Currency",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true
    },
    {
        "varName": "TradeFacilitation",
        "type": "title",
        "label": "Trade Facilitation",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "tradeFacilitation",
        "conditionalVarValue": true

    },
    {
        "varName": "StaffAccountGeneration/Generalaization/Block",
        "type": "title",
        "label": "Staff Account Generation/Generalaization/Block",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "staffAccountGenerationGeneralaizationBlock",
        "conditionalVarValue": true

    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "staffAccountGenerationGeneralaizationBlock",
        "conditionalVarValue": true
    },
    {
        "varName": "CTRLimitUpdate",
        "type": "title",
        "label": "CTR Limit Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "cTRLimitUpdate",
        "conditionalVarValue": true

    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "cTRLimitUpdate",
        "conditionalVarValue": true
    },
    {
        "varName": "TemporaryDormantActivation",
        "type": "title",
        "label": "Temporary Dormant Activation",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "temporaryDormantActivation",
        "conditionalVarValue": true

    },
    {
        "varName": "Freeze/UnfreezeMark",
        "type": "title",
        "label": "Freeze/Unfreeze Mark",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "freezeUnfreezeMark",
        "conditionalVarValue": true

    },
    {
        "varName": "CityTouchTagging",
        "type": "title",
        "label": "CityTouch Tagging",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true

    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "CB Number",
        "grid": 6,
        // "errorMessage": "Error",
        // "min": 9,
        // "max": 9,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "cityTouchEmailId",
        "type": "text",
        "label": "Email",
        "grid": 6,
        // "errorMessage": "Error",
        "multiline": true,
        // "email": true,
        // "required": true,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "mobileAccounts",
        "type": "text",
        "label": "Mobile of the Accounts",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 8,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "PriorityMarking",
        "type": "title",
        "label": "Priority Marking",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "priorityMarking",
        "conditionalVarValue": true

    },
    {
        "varName": "qualifyingCode",
        "type": "text",
        "label": "Qualifying Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "priorityMarking",
        "conditionalVarValue": true
    },
    {
        "varName": "UnconfirmedAddressMarking",
        "type": "title",
        "label": "Unconfirmed Address Marking",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "unconfirmedAddressMarking",
        "conditionalVarValue": true

    },
    {
        "varName": "DeceasedMarking",
        "type": "title",
        "label": "Deceased Marking",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "deceasedMarking",
        "conditionalVarValue": true

    },
    {
        "varName": "WronglyGeneratedACClosing",
        "type": "title",
        "label": "Wrongly Generated A/C Closing",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "wronglyGeneratedACClosing",
        "conditionalVarValue": true

    },
    {
        "varName": "ForceClosing",
        "type": "title",
        "label": "Force Closing",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "forceClosing",
        "conditionalVarValue": true

    },
    {
        "varName": "SBSCodeUpdateCorrection",
        "type": "title",
        "label": "SBS Code Update/Correction",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "sBSCodeUpdateCorrection",
        "conditionalVarValue": true

    },
    {
        "type": "empty",
        "grid": 12
    },
];
let CallCenterAccountMaintenance = {};
CallCenterAccountMaintenance = makeReadOnlyObject(JSON.parse(JSON.stringify(maintenanceListCall)));


/////maintenance chacker/////////////
const maintenanceListMaker = [
    {
        "type": "empty",
        "grid": 12,
    },
    // {
    //     "varName": "12DigitTINChange",
    //     "type": "title",
    //     "label": "E Tin Update",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "digitTinChange",
    //     "conditionalVarValue": true
    //
    //
    // },
    // {
    //     "varName": "tinUpdateOld",
    //     "type": "text",
    //     "label": "Existing TIN No",
    //     "readOnly": true,
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "digitTinChange",
    //     "conditionalVarValue": true
    //
    //
    // },
    // {
    //     "varName": "tin",
    //     "type": "text",
    //     "label": "New TIN No",
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "digitTinChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "nidOrsmartcardChange",
    //     "type": "title",
    //     "label": "NID/Smart Card Number update",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "nidOrsmartcardChange",
    //     "conditionalVarValue": true
    //
    //
    // },
    // {
    //     "varName": "nidOrsmartcardOld",
    //     "type": "text",
    //     "label": "Existing NID/Smart Card Number",
    //     "readOnly": true,
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "nidOrsmartcardChange",
    //     "conditionalVarValue": true
    //
    //
    // },
    // {
    //     "varName": "newNidOrSmartCardNumber",
    //     "type": "text",
    //     "label": "New NID/Smart Card Number",
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "nidOrsmartcardChange",
    //     "conditionalVarValue": true
    //
    // },
    {
        "varName": "TitleChange",
        "type": "title",
        "label": "Title Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true

    },
    {
        "varName": "titleOld",
        "type": "autoCompleteValueReturn",
        "label": "Existing Account Title",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "existingNameOld",
        "type": "text",
        "label": "Existing Name",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "NomineeUpdate",
    //     "type": "title",
    //     "label": "Nominee Update",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "nomineeChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "nomineeNumberOld",
    //     "type": "text",
    //     "label": "Existing No of Nominee",
    //     "readOnly": true,
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "nomineeChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "SignatureCard",
        "type": "title",
        "label": "Signature Card Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "signatureCard",
        "conditionalVarValue": true

    },
    {
        "varName": "individualInformationUpdate",
        "type": "title",
        "label": "Individual Information Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "individualInformationUpdate",
        "conditionalVarValue": true,
    },
    {
        "varName": "PhotoIDChange",
        "type": "title",
        "label": "Photo ID Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,
    },
    {
        "varName": "existingPassport",
        "type": "text",
        "label": "Existing Passport No.",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingBirthCertificate",
        "type": "text",
        "label": "Existing  Birth Certificate No.",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingChairmanCertificate",
        "type": "text",
        "label": "Existing Chairman Certificate No.",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingDrivingLicense",
        "type": "text",
        "label": "Existing Driving License No.",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingResidentId",
        "type": "text",
        "label": "Existing Resident Id No.",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "MailingContactNumberChange",
    //     "type": "title",
    //     "label": "Existing Mailing Contact Number Change",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "mailingContactNumberChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "existingPhoneNumber",
    //     "type": "text",
    //     "label": "Existing Phone Number Change",
    //     "readOnly": true,
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "mailingContactNumberChange",
    //     "conditionalVarValue": true,
    //
    // },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "mailingContactNumberChange",
    //     "conditionalVarValue": true,
    // },
    {
        "varName": "OtherContactNumberChange",
        "type": "title",
        "label": "Other Contact Number Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingResidenceNumber",
        "type": "text",
        "label": "Existing Residence Number",
        "grid": 6,
        // "phone": true,
        // "errorMessage": "Error",
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingOfficeNumber",
        "type": "text",
        "label": "Existing Office Number",
        "grid": 6,
        // "errorMessage": "Error",
        // "phone": true,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingMobileNumber",
        "type": "text",
        "label": "Existing Mobile Number",
        // "errorMessage": "Error",
        // "phone": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "otherContactNumberChange",
        "conditionalVarValue": true,

    },
    // {
    //     "varName": "EmailAddressChange",
    //     "type": "title",
    //     "label": "Email Address Change",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "emailAddressChange",
    //     "conditionalVarValue": true
    //
    // },
    // {
    //     "varName": "existingEmailId",
    //     "type": "text",
    //     "label": "Existing Email ID",
    //     "readOnly": true,
    //     "grid": 6,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "emailAddressChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "email",
    //     "type": "text",
    //     "label": "New Email ID",
    //     "grid": 6,
    //     "multiline": true,
    //     "conditional": true,
    //     "conditionalVarName": "emailAddressChange",
    //     "conditionalVarValue": true
    // },
    // {
    //     "varName": "existingEStatementFacility",
    //     "type": "select",
    //     "label": "Existing E-Statement Facility",
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "emailAddressChange",
    //     "conditionalVarValue": true,
    //     "enum": [
    //         "YES",
    //         "NO"
    //     ]
    // },
    {
        "varName": "E -StatementEnrollment",
        "type": "title",
        "label": "E - Statement Enrollment",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true,

    },
    {
        "varName": "eStatementFacility",
        "type": "text",
        "label": "Existing E-Statement Facility",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true,

    },
    {
        "varName": "eStatementFacilityNew",
        "type": "select",
        "label": "New E-Statement Facility",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true,
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "existingEmailId",
        "type": "text",
        "label": "Existing Email ID",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true
    },
    {
        "varName": "newEmailId",
        "type": "text",
        "label": "New Email ID",
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true
    },
    {
        "varName": "AddressChange",
        "type": "title",
        "label": "Address Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true

    },
    {
        "varName": "permanentAddress",
        "type": "text",
        "label": "Permanent Address",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "presentAddress",
        "type": "text",
        "label": "Present Address",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "officeAddress",
        "type": "text",
        "label": "Office Address",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "communicationAddress",
        "type": "text",
        "label": "Communication Address",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "OtherInformationChange",
        "type": "title",
        "label": "Other Information Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true

    },
    {
        "varName": "dobOld",
        "type": "date",
        "label": "DOB",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "fatherName",
        "type": "text",
        "label": "Father's Name",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "motherName",
        "type": "text",
        "label": "Mother's Name",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "spouseName",
        "type": "text",
        "label": "Spouse Name",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "profession",
    //     "type": "text",
    //     "label": "Profession",
    //     "readOnly": true,
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "otherInformationChange",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "employerName",
        "type": "text",
        "label": "Employer Name",
        "readOnly": true,
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "DormantAccountActivation",
        "type": "title",
        "label": "Dormant Account Activation",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "dormantAccountActivation",
        "conditionalVarValue": true
    },
    {
        "varName": "accountStatus",
        "type": "select",
        "enum": [
            "A",
            "D"
        ],
        "required": true,
        "label": "Account Status",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dormantAccountActivation",
        "conditionalVarValue": true
    },
    {
        "varName": "dormantActivationCharge",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "label": "Dormant Activation Charge",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "dormantAccountActivation",
        "conditionalVarValue": true
    },
    {
        "varName": "DormantAccountDataUpdate",
        "type": "title",
        "label": "Dormant Account Data Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "dormantAccountDataUpdate",
        "conditionalVarValue": true

    },
    {
        "varName": "SchemeMaintenance-LinkA/CChange",
        "type": "title",
        "label": "Scheme Maintenance-Link A/C Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "existingLinkAccount",
        "type": "text",
        "label": "Existing Link Account",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "requestedBranchSolId",
        "type": "text",
        "label": "Requested Branch Sol ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaAmount",
        "type": "text",
        "label": "Schema Amount",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaTenor",
        "type": "text",
        "label": "Schema Tenor",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaValueDate",
        "type": "text",
        "label": "Schema Value Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaInstallmentDate",
        "type": "text",
        "label": "Schema Installment Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "SchemeMaintenance-InstallmentRegularization",
        "type": "title",
        "label": "Scheme Maintenance - Installment Regularization",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true

    },
    {
        "varName": "numberInstallmentDue",
        "type": "text",
        "label": "Number of Installment(s) Due",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true
    },
    {
        "varName": "detailsofMonth",
        "type": "text",
        "label": "Details of Month",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true

    },
    {
        "varName": "amountPerInstallment",
        "type": "text",
        "label": "Amount Per Installment",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true
    },
    {
        "varName": "totalInstallmentAmountDue",
        "type": "text",
        "label": "Total Installment Amount Due",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true

    },
    {
        "varName": "debitDueInstallmentChargeRealization",
        "type": "text",
        "label": "Debit Due Installment & Charge Realization A/C no.",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true
    },
    {
        "varName": "transactionTypeOrSubtype",
        "type": "text",
        "label": "Transaction Type/Subtype",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true
    },
    {
        "varName": "SchemeMaintenanceInstallmentDate",
        "type": "title",
        "label": "Scheme Maintenance - Installment Date Regularization",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "solId",
        "type": "text",
        "label": "SOL ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaAmount",
        "type": "text",
        "label": "Schema Amount",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaTenor",
        "type": "text",
        "label": "Schema Tenor",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaValueDate",
        "type": "text",
        "label": "Schema Value Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "schemaInstallmentDate",
        "type": "text",
        "label": "Schema Installment Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "existingMatuirityDate",
        "type": "text",
        "label": "Existing Matuirity Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceInstallmentDate",
        "conditionalVarValue": true

    },
    {
        "varName": "SignatoryUpdate",
        "type": "title",
        "label": "Signatory Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "signatoryUpdate",
        "conditionalVarValue": true

    },
    {
        "varName": "existingMandate",
        "type": "text",
        "label": "Existing Mandate",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "signatoryUpdate",
        "conditionalVarValue": true
    },
    {
        "varName": "newMandate",
        "type": "text",
        "label": "New Mandate",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "signatoryUpdate",
        "conditionalVarValue": true
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "signatoryUpdate",
        "conditionalVarValue": true
    },
    {
        "varName": "CityLive",
        "type": "title",
        "label": "City Live",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "cityLive",
        "conditionalVarValue": true

    },
    {
        "varName": "CTRLimitUpdate/UnconfirmedAddressChange",
        "type": "title",
        "label": "CTR Limit Update/Unconfirmed Address Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "ctrLimitUpdateUnconfirmedAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "Account&SchemeClose",
        "type": "title",
        "label": "Account & Scheme Close",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "accountTitle",
        "type": "text",
        "label": "Account Title",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "accountBalance",
        "type": "text",
        "label": "Account Balance",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "faceValueForScheme",
        "type": "text",
        "label": "Face Value for scheme",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "maturityValueForScheme",
        "type": "text",
        "label": "Maturity Value for scheme",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "maturityDateForScheme",
        "type": "date",
        "label": "Maturity Date for scheme",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "lien",
        "type": "text",
        "label": "Lien",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "dormancy",
        "type": "text",
        "label": "Dormancy",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "freeze",
        "type": "text",
        "label": "Freeze",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "aDUPNormalCustomer",
        "type": "text",
        "label": "ADUP/Normal Customer",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "proxyTransaction",
        "type": "text",
        "label": "Proxy Transaction",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "eTinStatus",
        "type": "text",
        "label": "E Tin Status",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "sourceTaxCalculation",
        "type": "text",
        "label": "Source Tax Calculation",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "eDCalculation",
        "type": "text",
        "label": "ED Calculation",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "preMatureInterestCalculation",
        "type": "text",
        "label": "Pre mature interest calculation",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "debitAccountNumber",
        "type": "text",
        "label": "Debit Account Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    // {
    //     "varName": "empty",
    //     "type": "empty",
    //     "label": "",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "accountSchemeClose",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "numberOfInstallmentPendingScheme",
        "type": "text",
        "label": "Number of Installment pending/realized for scheme",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "LockerSIOpen",
        "type": "title",
        "label": "Locker SI Open",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "lockerSIOpen",
        "conditionalVarValue": true

    },
    {
        "varName": "LockerSIClose",
        "type": "title",
        "label": "Locker SI Close",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "lockerSIClose",
        "conditionalVarValue": true

    },
    {
        "varName": "CurrencyAdd",
        "type": "title",
        "label": "Currency Add",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true

    },
    {
        "varName": "existingCurrency",
        "type": "text",
        "label": "Existing Currency",
        "grid": 6,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true
    },
    {
        "varName": "newCurrency",
        "type": "autoCompleteValueReturn",
        "label": "New Currency",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true
    },
    {
        "varName": "TradeFacilitation",
        "type": "title",
        "label": "Trade Facilitation",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "tradeFacilitation",
        "conditionalVarValue": true

    },
    {
        "varName": "StaffAccountGeneration/Generalaization/Block",
        "type": "title",
        "label": "Staff Account Generation/Generalaization/Block",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "staffAccountGenerationGeneralaizationBlock",
        "conditionalVarValue": true

    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "staffAccountGenerationGeneralaizationBlock",
        "conditionalVarValue": true
    },
    {
        "varName": "CTRLimitUpdate",
        "type": "title",
        "label": "CTR Limit Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "cTRLimitUpdate",
        "conditionalVarValue": true

    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "cTRLimitUpdate",
        "conditionalVarValue": true
    },
    {
        "varName": "TemporaryDormantActivation",
        "type": "title",
        "label": "Temporary Dormant Activation",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "temporaryDormantActivation",
        "conditionalVarValue": true

    },
    {
        "varName": "Freeze/UnfreezeMark",
        "type": "title",
        "label": "Freeze/Unfreeze Mark",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "freezeUnfreezeMark",
        "conditionalVarValue": true

    },
    {
        "varName": "CityTouchTagging",
        "type": "title",
        "label": "CityTouch Tagging",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true

    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "CB Number",
        "grid": 6,
        // "errorMessage": "Error",
        // "min": 9,
        // "max": 9,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "emailId",
        "type": "text",
        "label": "Email",
        "grid": 6,
        // "errorMessage": "Error",
        "multiline": true,
        // "email": true,
        // "required": true,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "mobileAccounts",
        "type": "text",
        "label": "Mobile of the Accounts",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 8,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "PriorityMarking",
        "type": "title",
        "label": "Priority Marking",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "priorityMarking",
        "conditionalVarValue": true

    },
    {
        "varName": "qualifyingCode",
        "type": "text",
        "label": "Qualifying Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "priorityMarking",
        "conditionalVarValue": true
    },
    {
        "varName": "UnconfirmedAddressMarking",
        "type": "title",
        "label": "Unconfirmed Address Marking",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "unconfirmedAddressMarking",
        "conditionalVarValue": true

    },
    {
        "varName": "DeceasedMarking",
        "type": "title",
        "label": "Deceased Marking",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "deceasedMarking",
        "conditionalVarValue": true

    },
    {
        "varName": "WronglyGeneratedACClosing",
        "type": "title",
        "label": "Wrongly Generated A/C Closing",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "wronglyGeneratedACClosing",
        "conditionalVarValue": true

    },
    {
        "varName": "ForceClosing",
        "type": "title",
        "label": "Force Closing",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "forceClosing",
        "conditionalVarValue": true

    },
    {
        "varName": "SBSCodeUpdateCorrection",
        "type": "title",
        "label": "SBS Code Update/Correction",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "sBSCodeUpdateCorrection",
        "conditionalVarValue": true
    },
    {
        "varName": "ProjectRelatedDataUpdateADUP,CIB",
        "type": "title",
        "label": "Project Related Data Update ADUP, CIB",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "projectRelatedDataUpdateADUP",
        "conditionalVarValue": true

    },

];

const maintenanceListChecker = makeReadOnlyObject(JSON.parse(JSON.stringify(maintenanceListMaker)));


let MAKERAccountMaintenance = {};
MAKERAccountMaintenance = JSON.parse(JSON.stringify(maintenanceListMaker));

let CheckerAccountMaintenance = {};
CheckerAccountMaintenance = makeReadOnlyObject(JSON.parse(JSON.stringify(maintenanceListMaker)));

///FDR Cs/////
const encashmentCs = [
    {
        "varName": "title",
        "type": "title",
        "label": "Encashment",
        "grid": 12,

    },
    {
        "varName": "tenor",
        "type": "text",
        "label": "Tenure",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "principalAmount",
        "type": "text",
        "label": "Principal Amount",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fDOpenDate",
        "type": "text",
        "label": "FD Open Date",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fDSchemeCode",
        "type": "text",
        "label": "FD Scheme Code",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "linkDebitAccountNumber",
        "type": "text",
        "label": "Link /Debit Account Number",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fDCurrentBalance",
        "type": "text",
        "label": "FD Current Balance",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "lastRenewalDate",
        "type": "text",
        "label": "Last Renewal Date",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fundTransferAccountNumber",
        "type": "text",
        "label": "Fund Transfer Account Number",
        "grid": 3,

    },

    {
        "varName": "branchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "eTINStatus",
        "type": "text",
        "label": "E-TIN Status",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "lienStatus",
        "type": "text",
        "label": "Lien Status",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "freezeStatus",
        "type": "text",
        "label": "Freeze Status",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "nomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "nomineeRelationship",
        "type": "text",
        "label": "Nominee Relationship",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "citygemCenterName",
        "type": "text",
        "label": "Citygem Center Name",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "deferralStatus",
        "type": "text",
        "label": "Deferral Status",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "approxEncashmentValue",
        "type": "text",
        "label": "Approx. Encashment Value",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "sourceTaxCalculation",
        "type": "text",
        "label": "Source Tax Calculation",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "eDCalculation",
        "type": "text",
        "label": "ED Calculation",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "csBearer",
        "type": "select",
        "label": "Bearer",
        "enum": [
            "YES",
            "NO"
        ],
        "grid": 4
    },
    {
        "varName": "bearerApproval",
        "type": "select",
        "label": "Bearer Approval",
        "grid": 4,
        "enum": [
            "BM Approval",
            "Call Center Approval",
            "BM & Call Center Approval"

        ]
    }
];
let interestCs = [
    {
        "varName": "title",
        "type": "title",
        "label": "Interest",
        "grid": 12,

    },

    {
        "varName": "tenor",
        "type": "text",
        "label": "Tenure",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "principalAmount",
        "type": "text",
        "label": "Principal Amount",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fDOpenDate",
        "type": "text",
        "label": "FD Open Date",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fDSchemeCode",
        "type": "text",
        "label": "FD Scheme Code",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "linkDebitAccountNumber",
        "type": "text",
        "label": "Link /Debit Account Number",
        "grid": 3,

    },
    {
        "varName": "fDCurrentBalance",
        "type": "text",
        "label": "FD Current Balance",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "lastRenewalDate",
        "type": "text",
        "label": "Last Renewal Date",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fundTransferAccountNumber",
        "type": "text",
        "label": "Fund Transfer Account Number",
        "grid": 3,

    },

    {
        "varName": "branchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "eTINStatus",
        "type": "text",
        "label": "E-TIN Status",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "lienStatus",
        "type": "text",
        "label": "Lien Status",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "freezeStatus",
        "type": "text",
        "label": "Freeze Status",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "nomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "nomineeRelationship",
        "type": "text",
        "label": "Nominee Relationship",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "citygemCenterName",
        "type": "text",
        "label": "Citygem Center Name",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "csBearer",
        "type": "select",
        "label": "Bearer",
        "enum": [
            "YES",
            "NO"
        ],
        "grid": 4
    },
    {
        "varName": "bearerApproval",
        "type": "select",
        "label": "Bearer Approval",
        "grid": 4,
        "enum": [
            "BM Approval",
            "Call Center Approval",
            "BM & Call Center Approval"

        ]
    }
];
let certificateCs = [
    {
        "varName": "title",
        "type": "title",
        "label": "Certificate",
        "grid": 12,

    },


    {
        "varName": "tenor",
        "type": "text",
        "label": "Tenure",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "principalAmount",
        "type": "text",
        "label": "Principal Amount",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fDOpenDate",
        "type": "text",
        "label": "FD Open Date",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fDSchemeCode",
        "type": "text",
        "label": "FD Scheme Code",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "lastRenewalDate",
        "type": "text",
        "label": "Last Renewal Date",
        "grid": 3,
        'readOnly': true,
    },

    {
        "varName": "branchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fDCloseDate",
        "type": "text",
        "label": "FD Close Date",
        "grid": 3,
        'readOnly': true,
    },

    {
        "varName": "citygemCenterName",
        "type": "text",
        "label": "Citygem Center Name",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "encashmentValue",
        "type": "text",
        "label": "Encashment Value",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "csBearer",
        "type": "select",
        "label": "Bearer",
        "enum": [
            "YES",
            "NO"
        ],
        "grid": 4
    },
    {
        "varName": "bearerApproval",
        "type": "select",
        "label": "Bearer Approval",
        "grid": 4,
        "enum": [
            "BM Approval",
            "Call Center Approval",
            "BM & Call Center Approval"

        ]
    }
];
let linkACCCs = [
    {
        "varName": "title",
        "type": "title",
        "label": "Link ACC",
        "grid": 12,

    },


    {
        "varName": "tenor",
        "type": "text",
        "label": "Tenure",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "principalAmount",
        "type": "text",
        "label": "Principal Amount",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fDOpenDate",
        "type": "text",
        "label": "FD Open Date",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fDSchemeCode",
        "type": "text",
        "label": "FD Scheme Code",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "linkDebitAccountNumber",
        "type": "text",
        "label": "Link/Debit Account Number",
        "grid": 3,

    },
    {
        "varName": "newLinkAccountNumber",
        "type": "text",
        "label": "New Link Account Number",
        "grid": 3,

    },
    {
        "varName": "branchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "lienStatus",
        "type": "text",
        "label": "Lien Status",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "freezeStatus",
        "type": "text",
        "label": "Freeze Status",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "citygemCenterName",
        "type": "text",
        "label": "Citygem Center Name",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "csBearer",
        "type": "select",
        "label": "Bearer",
        "enum": [
            "YES",
            "NO"
        ],
        "grid": 4
    },
    {
        "varName": "bearerApproval",
        "type": "select",
        "label": "Bearer Approval",
        "grid": 4,
        "enum": [
            "BM Approval",
            "Call Center Approval",
            "BM & Call Center Approval"

        ]
    }
];
let tenorOrSchemeCs = [
    {
        "varName": "title",
        "type": "title",
        "label": "Tenor Or Scheme",
        "grid": 12,

    },


    {
        "varName": "tenor",
        "type": "text",
        "label": "Tenure",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "principalAmount",
        "type": "text",
        "label": "Principal Amount",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fDOpenDate",
        "type": "text",
        "label": "FD Open Date",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fDSchemeCode",
        "type": "text",
        "label": "FD Scheme Code",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "linkDebitAccountNumber",
        "type": "text",
        "label": "Link/Debit Account Number",
        "grid": 3,

    },
    {
        "varName": "fDCurrentBalance",
        "type": "text",
        "label": "FD Current Balance",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "lastRenewalDate",
        "type": "text",
        "label": "Last Renewal Date",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "newTenor",
        "type": "text",
        "label": "New Tenure",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "newSchemeCode",
        "type": "text",
        "label": "New Scheme Code",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "branchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "etinStatus",
        "type": "text",
        "label": "E-Tin Status",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "tinNumber",
        "type": "text",
        "label": "Tin Number",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "lienStatus",
        "type": "text",
        "label": "Lien Status",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "freezeStatus",
        "type": "text",
        "label": "Freeze Status",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "citygemCenterName",
        "type": "text",
        "label": "Citygem Center Name",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "changeOnlyOnceAllowable",
        "type": "text",
        "label": "Change “Only Once” Allowable",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "deferralStatus",
        "type": "text",
        "label": "Deferral Status",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "csBearer",
        "type": "select",
        "label": "Bearer",
        "enum": [
            "YES",
            "NO"
        ],
        "grid": 4
    },
    {
        "varName": "bearerApproval",
        "type": "select",
        "label": "Bearer Approval",
        "grid": 4,
        "enum": [
            "BM Approval",
            "Call Center Approval",
            "BM & Call Center Approval"

        ]
    }
];
let sTaxCs = [
    {
        "varName": "title",
        "type": "title",
        "label": "S. Tax",
        "grid": 12,

    },


    {
        "varName": "tenor",
        "type": "text",
        "label": "Tenure",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "principalAmount",
        "type": "text",
        "label": "Principal Amount",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fDOpenDate",
        "type": "text",
        "label": "FD Open Date",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fDSchemeCode",
        "type": "text",
        "label": "FD Scheme Code",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "linkDebitAccountNumber",
        "type": "text",
        "label": "Link/Debit Account Number",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fundTransferAccountNumber",
        "type": "text",
        "label": "Fund Transfer Account Number",
        "grid": 3,

    },
    {
        "varName": "branchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fDCloseDate",
        "type": "text",
        "label": "FD Close Date",
        "grid": 3,
        'readOnly': true,
    },

    {
        "varName": "eTinStatus",
        "type": "text",
        "label": "E-Tin Status",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "lienStatus",
        "type": "text",
        "label": "Lien Status",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "freezeStatus",
        "type": "text",
        "label": "Freeze Status",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "citygemCenterName",
        "type": "text",
        "label": "Citygem Center Name",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "sourceTaxCalculation",
        "type": "text",
        "label": "Source Tax calculation",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "csBearer",
        "type": "select",
        "label": "Bearer",
        "enum": [
            "YES",
            "NO"
        ],
        "grid": 4
    },
    {
        "varName": "bearerApproval",
        "type": "select",
        "label": "Bearer Approval",
        "grid": 4,
        "enum": [
            "BM Approval",
            "Call Center Approval",
            "BM & Call Center Approval"

        ]
    }
];
let duplAdviceCs = [
    {
        "varName": "title",
        "type": "title",
        "label": "Dupl Advice",
        "grid": 12,

    },


    {
        "varName": "tenor",
        "type": "text",
        "label": "Tenure",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "principalAmount",
        "type": "text",
        "label": "Principal Amount",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fDOpenDate",
        "type": "text",
        "label": "FD Open Date",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fDSchemeCode",
        "type": "text",
        "label": "FD Scheme Code",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "linkDebitAccountNumber",
        "type": "text",
        "label": "Link /Debit Account Number",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fDOpeningRate",
        "type": "text",
        "label": "FD Opening Rate",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "maturityDate",
        "type": "text",
        "label": "Maturity Date",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "branchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "renewalInstruction",
        "type": "text",
        "label": "Renewal Instruction",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fDCloseStatus",
        "type": "text",
        "label": "FD Close Status",
        "grid": 3,
        'readOnly': true,
    },

    {
        "varName": "lienStatus",
        "type": "text",
        "label": "Lien Status",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "freezeStatus",
        "type": "text",
        "label": "Freeze Status",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "citygemCenterName",
        "type": "text",
        "label": "Citygem Center Name",
        "grid": 3,
        'readOnly': true,
    },

    {
        "varName": "customerCommunicationAddress",
        "type": "text",
        "label": "Customer Communication Address",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "customerPhoneNumber",
        "type": "text",
        "label": "Customer Phone Number",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "csBearer",
        "type": "select",
        "label": "Bearer",
        "enum": [
            "YES",
            "NO"
        ],
        "grid": 4
    },
    {
        "varName": "bearerApproval",
        "type": "select",
        "label": "Bearer Approval",
        "grid": 4,
        "enum": [
            "BM Approval",
            "Call Center Approval",
            "BM & Call Center Approval"

        ]
    }
];
let othersCs = [
    {
        "varName": "title",
        "type": "title",
        "label": "Others",
        "grid": 12,

    },


    {
        "varName": "tenor",
        "type": "text",
        "label": "Tenure",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "principalAmount",
        "type": "text",
        "label": "Principal Amount",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fDOpenDate",
        "type": "text",
        "label": "FD Open Date",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fDSchemeCode",
        "type": "text",
        "label": "FD Scheme Code",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "branchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "emailAddress",
        "type": "text",
        "label": "Email Address",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "mobileNumber",
        "type": "text",
        "label": "Mobile Number",
        "grid": 3,
        'readOnly': true,
    },
    {
        "varName": "fDCloseStatus",
        "type": "text",
        "label": "FD Close Status",
        "grid": 3,
        'readOnly': true,
    },

    {
        "varName": "citygemCenterName",
        "type": "text",
        "label": "Citygem Center Name",
        "grid": 3,
        'readOnly': true,
    },

    {
        "varName": "csBearer",
        "type": "select",
        "label": "Bearer",
        "enum": [
            "YES",
            "NO"
        ],
        "grid": 4
    },
    {
        "varName": "bearerApproval",
        "type": "select",
        "label": "Bearer Approval",
        "grid": 4,
        "enum": [
            "BM Approval",
            "Call Center Approval",
            "BM & Call Center Approval"

        ]
    }
];
let regulatoryACCCs = [
    {
        "varName": "title",
        "type": "title",
        "label": "Regulatory ACC",
        "grid": 12,

    },
    {
        "varName": "specificReferenceNumber",
        "type": "text",
        "label": "Specific Reference Number",
        "grid": 3,

    },
    {
        "varName": "responsiblePerson",
        "type": "select",
        "label": "Responsible Person",
        "grid": 3,
        "enum": [
            "ff",
            "ff",
        ]

    },
    {
        "varName": "senderName",
        "type": "select",
        "label": "Sender Name",
        "grid": 3,
        "enum": [
            "sd",
            "ss",
        ]

    },
    {
        "varName": "priorityMarking",
        "type": "select",
        "label": "Priority Marking",
        "grid": 3,
        "enum": [
            "143 Letter",
            "Freeze Marking",
            "Withdrawal",
            "Information"
        ]

    },
    {
        "varName": "tentativeCompletionDate",
        "type": "date",
        "label": "Tentative Completion Date",

    },
    {
        "varName": "fileAttachmentBrowser",
        "type": "text",
        "label": "File Attachment Browser",
        "grid": 3,

    },
    {
        "varName": "csBearer",
        "type": "select",
        "label": "Bearer",
        "enum": [
            "YES",
            "NO"
        ],
        "grid": 5
    },
    {
        "varName": "bearerApproval",
        "type": "select",
        "label": "Bearer Approval",
        "grid": 5,
        "enum": [
            "BM Approval",
            "Call Center Approval",
            "BM & Call Center Approval"

        ]
    }

];


let CSFdrEncashmentMaintenance = {};
CSFdrEncashmentMaintenance = JSON.parse(JSON.stringify(encashmentCs));

let CSFdrInterestMaintenance = {};
CSFdrInterestMaintenance = JSON.parse(JSON.stringify(interestCs));

let CSFdrCertificateMaintenance = {};
CSFdrCertificateMaintenance = JSON.parse(JSON.stringify(certificateCs));

let CSFdrLinkACCMaintenance = {};
CSFdrLinkACCMaintenance = JSON.parse(JSON.stringify(linkACCCs));

let CSFdrSTaxMaintenance = {};
CSFdrSTaxMaintenance = JSON.parse(JSON.stringify(sTaxCs));

let CSFdrTenorOrSchemeMaintenance = {};
CSFdrTenorOrSchemeMaintenance = JSON.parse(JSON.stringify(tenorOrSchemeCs));

let CSFdrDuplAdviceMaintenance = {};
CSFdrDuplAdviceMaintenance = JSON.parse(JSON.stringify(duplAdviceCs));

let CSFdrOthersMaintenance = {};
CSFdrOthersMaintenance = JSON.parse(JSON.stringify(othersCs));

let CSFdrRegulatoryACCMaintenance = {};
CSFdrRegulatoryACCMaintenance = JSON.parse(JSON.stringify(regulatoryACCCs));


//////fdr maintenance Bom//////


const encashment = [
    // {
    //     "varName": "accountNumber",
    //     "type": "text",
    //     "required": true,
    //     "label": "Account No",
    //     "grid": 2,
    // },
    // {
    //     "varName": "accountName",
    //     "type": "text",
    //     "required": true,
    //     "label": "Account Name",
    //     "grid": 2,
    // },
    // {
    //     "varName": "amount",
    //     "type": "text",
    //     "required": true,
    //     "label": "Amount",
    //     "grid": 2,
    // },
    // {
    //     "varName": "closeStatus",
    //     "type": "text",
    //     "required": true,
    //     "label": "Close Status",
    //     "grid": 2,
    // },
    // {
    //     "varName": "relationship",
    //     "type": "text",
    //     "required": true,
    //     "label": "Relationship",
    //     "grid": 2,
    // },
    // {
    //     "varName": "typeOfLoan",
    //     "type": "text",
    //     "required": true,
    //     "label": "Type of Loan",
    //     "grid": 2,
    // },
    // {
    //     "varName": "lienAmount",
    //     "type": "text",
    //     "required": true,
    //     "label": "Lien Amount",
    //     "grid": 2,
    // },
    // {
    //     "varName": "lienRemarks",
    //     "type": "text",
    //     "required": true,
    //     "label": "Lien Remarks",
    //     "grid": 4,
    //     "multiline": true
    // },
    // {
    //     "varName": "freezeCode",
    //     "type": "text",
    //     "required": true,
    //     "label": "Freeze Code",
    //     "grid": 2,
    // },
    // {
    //     "varName": "freezeReasonCode",
    //     "type": "text",
    //     "required": true,
    //     "label": "Freeze Reason Code",
    //     "grid": 2,
    // },
    // {
    //     "varName": "acStatus",
    //     "type": "text",
    //     "required": true,
    //     "label": "A/C Status",
    //     "grid": 2,
    // },
    // {
    //     "varName": "freezeRemarks",
    //     "type": "text",
    //     "required": true,
    //     "label": "Freeze Remarks",
    //     "grid": 4,
    //     "multiline": true
    // },
    // {
    //     "varName": "dormantActivationCharge",
    //     "type": "text",
    //     "required": true,
    //     "label": "Dormant Activation Charge",
    //     "grid": 2,
    // },
    // {
    //     "varName": "freeText",
    //     "type": "text",
    //     "required": true,
    //     "label": "Free Text",
    //     "grid": 4,
    //     "multiline": true
    // },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FD Nominee Change"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "FD nominee Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FD Nominee Change"
    },
    {
        "varName": "nomineeNumberOld",
        "type": "text",
        "label": "Existing Nominee",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FD Nominee Change"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "FDR Encashment",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "FDR Interest Payment",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Deceased FDR Encashment",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "inputFundTransferAccountNumber",
        "type": "select",
        "label": "Input Fund Transfer Account Number",
        "grid": 3,
        "enum": [
            'Own AC',
            'Third Party AC',
            'Payment to GL AC',
            'Payorder',
            'EFT Payment',
            'RTGS Payment',
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment"
    },
    {
        "varName": "inputFundTransferAccountNumber",
        "type": "select",
        "label": "Input Fund Transfer Account Number",
        "grid": 3,
        "enum": [
            'Own AC',
            'Third Party AC',
            'Payment to GL AC',
            'Payorder',
            'EFT Payment',
            'RTGS Payment',
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "inputFundTransferAccountNumber",
        "type": "select",
        "label": "Input Fund Transfer Account Number",
        "grid": 3,
        "enum": [
            'Own AC',
            'Third Party AC',
            'Payment to GL AC',
            'Payorder',
            'EFT Payment',
            'RTGS Payment',
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "acNumber",
        "type": "text",
        "label": "A/c Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalVarValue": 'Own AC',
    },
    {
        "varName": "acName",
        "type": "text",
        "label": "A/c Name",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalVarValue": 'Own AC',
    },
    {
        "varName": "acNumber",
        "type": "text",
        "label": "A/c Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalVarValue": 'Third Party AC',
    },
    {
        "varName": "acName",
        "type": "text",
        "label": "A/c Name",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalVarValue": 'Third Party AC',
    },
    {
        "varName": "acNumber",
        "type": "text",
        "label": "A/c Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalVarValue": 'Payment to GL AC',
    },
    {
        "varName": "acName",
        "type": "text",
        "label": "A/c Name",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalVarValue": 'Payment to GL AC',
    },
    {
        "varName": "acNumber",
        "type": "text",
        "label": "A/c Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalVarValue": 'Payorder',
    },
    {
        "varName": "acName",
        "type": "text",
        "label": "A/c Name",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalVarValue": 'Payorder',
    },
    {
        "varName": "transferType",
        "type": "select",
        "label": "Transfer Type",
        "enum": [
            "RTGS",
            "BEFTN",
            "Salary"
        ],
        "grid": 2,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "customerType",
        "type": "select",
        "enum": [
            "Retail",
            "SME/Corporate"
        ],
        "label": "Customer Type",
        "grid": 2,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "transferAmount",
        "type": "text",
        "label": "Transfer Amount",
        "grid": 2,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "CASA Link AC Nominee",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "casaLinkAcNomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Deceased FDR Encashment',
    },
    {
        "varName": "casaLinkAcRelationship",
        "type": "text",
        "label": "Relationship",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Deceased FDR Encashment',
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "FD AC Nominee",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "fdAcNomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Deceased FDR Encashment',
    },
    {
        "varName": "fdAcRelationship",
        "type": "text",
        "label": "Relationship",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Deceased FDR Encashment',
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "FDR Encashment Certificate",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Present FDR Encashment Value"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Present FDR Encashment Value",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Present FDR Encashment Value"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Link ACC Change"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Link ACC Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Link ACC Change"
    },
    {
        "varName": "newLinkAccountNumber",
        "type": "text",
        "label": "New Link Account Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Link ACC Change',
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Tenor or Scheme Change"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Tenor or Scheme Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Tenor or Scheme Change"
    },
    {
        "varName": "tenorType",
        "type": "select",
        "enum": [
            "Day",
            "Monthly"
        ],
        "label": "Tenor Type",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Tenor or Scheme Change',
    },
    {
        "varName": "newTenor",
        "type": "text",
        "label": "New Tenor",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Tenor or Scheme Change',
    },
    {
        "varName": "newSchemaCode",
        "type": "text",
        "label": "New Scheme Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Tenor or Scheme Change',
    },
    {
        "varName": "newSchemaCode",
        "type": "select",
        "label": "Renewal /Disposal Instruction ",
        "grid": 2,
        "enum": [
            "Yes",
            "No"
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Tenor or Scheme Change',
    },
    {
        "varName": "reIssueFdrWith",
        "type": "select",
        "label": "Re-Issue FDR with ",
        "grid": 2,
        "enum": [
            "Yes",
            "No"
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Tenor or Scheme Change',
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "S. Tax Reversal"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "S. Tax Reversal",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "S. Tax Reversal"
    },
    {
        "varName": "transactionType",
        "type": "text",
        "label": "Transaction Type/Subtype",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "partTransactionType",
        "type": "text",
        "label": "Part Transaction Type",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "acIdOne",
        "type": "text",
        "label": "A/c. ID ",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "refCCYAmtOne",
        "type": "text",
        "label": "Ref. CCY/Amt",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "transactionParticularsCodeOne",
        "type": "text",
        "label": "Transaction Particulars Code",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "transactionParticularsOne",
        "type": "text",
        "label": "Transaction Particulars",
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "partTransactionTypeOne",
        "type": "text",
        "label": "Part Transaction Type",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "acIdTwo",
        "type": "text",
        "label": "A/c. ID ",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "refCCYAmtTwo",
        "type": "text",
        "label": "Ref. CCY/Amt",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "transactionParticularsCodeTwo",
        "type": "text",
        "label": "Transaction Particulars Code",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "transactionParticularsTwo",
        "type": "text",
        "label": "Transaction Particulars",
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Dupl. Advice (Special)"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Dupl. Advice (Special)",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Dupl. Advice (Special)"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Auxiliary Task",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "selectRequestType",
        "type": "select",
        "label": "Select Request type",
        "enum": [
            "Freeze/Unfreeze Mark ",
            "Deceased Marking ",
            "Priority Marking ",
            "SBS Code Update/Correction",
            "RM Code Update",
            "Citytouch Tagging ",
            "AMLD/Branch Support",
        ],
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Regulatory Account Inquiry",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "specificReferenceNumber",
        "type": "text",
        "label": "Specific Reference Number",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "nameOfInstructedBody",
        "type": "select",
        "label": "Name of Instructed Body (Sender Name)",
        "grid": 4,
        "enum": [
            "A",
            "B",
            "C"
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "priorityMaking",
        "type": "select",
        "label": "Priority Marking",
        "grid": 4,
        "enum": [
            "143 Letter",
            "Freeze Marking",
            "withdrawal",
            "Information",
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "tentetiveCompletionDate",
        "type": "date",
        "label": "Tentetive Completion Date",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },

];
const encashmentSD = [
    // {
    //     "varName": "accountNumber",
    //     "type": "text",
    //     "required": true,
    //     "label": "Account No",
    //     "grid": 6,
    // },
    // {
    //     "varName": "accountName",
    //     "type": "text",
    //     "required": true,
    //     "label": "Account Name",
    //     "grid": 6,
    // },
    // {
    //     "varName": "amount",
    //     "type": "text",
    //     "required": true,
    //     "label": "Amount",
    //     "grid": 6,
    // },
    // {
    //     "varName": "closeStatus",
    //     "type": "text",
    //     "required": true,
    //     "label": "Close Status",
    //     "grid": 6,
    // },
    // {
    //     "varName": "relationship",
    //     "type": "text",
    //     "required": true,
    //     "label": "Relationship",
    //     "grid": 6,
    // },
    // {
    //     "varName": "typeOfLoan",
    //     "type": "text",
    //     "required": true,
    //     "label": "Type of Loan",
    //     "grid": 6,
    // },
    // {
    //     "varName": "lienAmount",
    //     "type": "text",
    //     "required": true,
    //     "label": "Lien Amount",
    //     "grid": 6,
    // },
    // {
    //     "varName": "lienRemarks",
    //     "type": "text",
    //     "required": true,
    //     "label": "Lien Remarks",
    //     "grid": 6,
    //     "multiline": true
    // },
    // {
    //     "varName": "freezeCode",
    //     "type": "text",
    //     "required": true,
    //     "label": "Freeze Code",
    //     "grid": 6,
    // },
    // {
    //     "varName": "freezeReasonCode",
    //     "type": "text",
    //     "required": true,
    //     "label": "Freeze Reason Code",
    //     "grid": 6,
    // },
    // {
    //     "varName": "acStatus",
    //     "type": "text",
    //     "required": true,
    //     "label": "A/C Status",
    //     "grid": 6,
    // },
    // {
    //     "varName": "freezeRemarks",
    //     "type": "text",
    //     "required": true,
    //     "label": "Freeze Remarks",
    //     "grid": 6,
    //     "multiline": true
    // },
    // {
    //     "varName": "dormantActivationCharge",
    //     "type": "text",
    //     "required": true,
    //     "label": "Dormant Activation Charge",
    //     "grid": 6,
    // },
    // {
    //     "varName": "freeText",
    //     "type": "text",
    //     "required": true,
    //     "label": "Free Text",
    //     "grid": 6,
    //     "multiline": true
    // },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FD Nominee Change"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "FD nominee Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FD Nominee Change"
    },
    {
        "varName": "nomineeNumberOld",
        "type": "text",
        "label": "Existing Nominee",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FD Nominee Change"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "FDR Encashment",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "FDR Interest Payment",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Deceased FDR Encashment",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "inputFundTransferAccountNumber",
        "type": "select",
        "label": "Input Fund Transfer Account Number",
        "grid": 12,
        "enum": [
            'Own AC',
            'Third Party AC',
            'Payment to GL AC',
            'Payorder',
            'EFT Payment',
            'RTGS Payment',
        ],
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment", "FDR Interest Payment", "Deceased FDR Encashment"]
    },
    {
        "varName": "acNumber",
        "type": "text",
        "label": "A/c Number",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": ['Own AC', 'Third Party AC', 'Payment to GL AC', 'Payorder'],
    },
    {
        "varName": "acName",
        "type": "text",
        "label": "A/c Name",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": ['Own AC', 'Third Party AC', 'Payment to GL AC', 'Payorder'],
    },
    {
        "varName": "transferType",
        "type": "select",
        "label": "Transfer Type",
        "enum": [
            "RTGS",
            "BEFTN",
            "Salary"
        ],
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "customerType",
        "type": "select",
        "enum": [
            "Retail",
            "SME/Corporate"
        ],
        "label": "Customer Type",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "transferAmount",
        "type": "text",
        "label": "Transfer Amount",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },

    {
        "varName": "title",
        "type": "title",
        "label": "CASA Link AC Nominee",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "casaLinkAcNomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Deceased FDR Encashment',
    },
    {
        "varName": "casaLinkAcRelationship",
        "type": "text",
        "label": "Relationship",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Deceased FDR Encashment',
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "FD AC Nominee",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "fdAcNomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Deceased FDR Encashment',
    },
    {
        "varName": "fdAcRelationship",
        "type": "text",
        "label": "Relationship",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Deceased FDR Encashment',
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "FDR Encashment Certificate",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Present FDR Encashment Value"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Present FDR Encashment Value",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Present FDR Encashment Value"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Link ACC Change"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Link ACC Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Link ACC Change"
    },
    {
        "varName": "newLinkAccountNumber",
        "type": "text",
        "label": "New Link Account Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Link ACC Change',
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Tenor or Scheme Change"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Tenor or Scheme Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Tenor or Scheme Change"
    },
    {
        "varName": "tenorType",
        "type": "select",
        "enum": [
            "Day",
            "Monthly"
        ],
        "label": "Tenor Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Tenor or Scheme Change',
    },
    {
        "varName": "newTenor",
        "type": "text",
        "label": "New Tenor",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Tenor or Scheme Change',
    },
    {
        "varName": "newSchemaCode",
        "type": "text",
        "label": "New Scheme Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Tenor or Scheme Change',
    },
    {
        "varName": "newSchemaCode",
        "type": "select",
        "label": "Renewal /Disposal Instruction ",
        "grid": 6,
        "enum": [
            "Yes",
            "No"
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Tenor or Scheme Change',
    },
    {
        "varName": "reIssueFdrWith",
        "type": "select",
        "label": "Re-Issue FDR with ",
        "grid": 6,
        "enum": [
            "Yes",
            "No"
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Tenor or Scheme Change',
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "S. Tax Reversal"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "S. Tax Reversal",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "S. Tax Reversal"
    },
    {
        "varName": "transactionType",
        "type": "text",
        "label": "Transaction Type/Subtype",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "partTransactionType",
        "type": "text",
        "label": "Part Transaction Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "acIdOne",
        "type": "text",
        "label": "A/c. ID ",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "refCCYAmtOne",
        "type": "text",
        "label": "Ref. CCY/Amt",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "transactionParticularsCodeOne",
        "type": "text",
        "label": "Transaction Particulars Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "transactionParticularsOne",
        "type": "text",
        "label": "Transaction Particulars",
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "partTransactionTypeOne",
        "type": "text",
        "label": "Part Transaction Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "acIdTwo",
        "type": "text",
        "label": "A/c. ID ",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "refCCYAmtTwo",
        "type": "text",
        "label": "Ref. CCY/Amt",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "transactionParticularsCodeTwo",
        "type": "text",
        "label": "Transaction Particulars Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "transactionParticularsTwo",
        "type": "text",
        "label": "Transaction Particulars",
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Dupl. Advice (Special)"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Dupl. Advice (Special)",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Dupl. Advice (Special)"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Auxiliary Task",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "selectRequestType",
        "type": "select",
        "label": "Select Request type",
        "enum": [
            "Freeze/Unfreeze Mark ",
            "Deceased Marking ",
            "Priority Marking ",
            "SBS Code Update/Correction",
            "RM Code Update",
            "Citytouch Tagging ",
            "AMLD/Branch Support",
        ],
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Regulatory Account Inquiry",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "specificReferenceNumber",
        "type": "text",
        "label": "Specific Reference Number",
        "grid": 5,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "nameOfInstructedBody",
        "type": "select",
        "label": "Name of Instructed Body (Sender Name)",
        "grid": 7,
        "enum": [
            "A",
            "B",
            "C"
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "priorityMaking",
        "type": "select",
        "label": "Priority Marking",
        "grid": 5,
        "enum": [
            "143 Letter",
            "Freeze Marking",
            "withdrawal",
            "Information",
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "tentetiveCompletionDate",
        "type": "date",
        "label": "Tentetive Completion Date",
        "grid": 7,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },

];


let interest = [
    {
        "varName": "title",
        "type": "title",
        "label": "Interest",
        "grid": 12,

    },

    {
        "varName": "tenor",
        "type": "text",
        "label": "Tenure",
        "grid": 3,

    },
    {
        "varName": "principalAmount",
        "type": "text",
        "label": "Principal Amount",
        "grid": 3,

    },
    {
        "varName": "fDOpenDate",
        "type": "text",
        "label": "FD Open Date",
        "grid": 3,

    },
    {
        "varName": "fDSchemeCode",
        "type": "text",
        "label": "FD Scheme Code",
        "grid": 3,

    },
    {
        "varName": "linkDebitAccountNumber",
        "type": "text",
        "label": "Link /Debit Account Number",
        "grid": 3,

    },
    {
        "varName": "fDCurrentBalance",
        "type": "text",
        "label": "FD Current Balance",
        "grid": 3,

    },
    {
        "varName": "lastRenewalDate",
        "type": "text",
        "label": "Last Renewal Date",
        "grid": 3,

    },
    {
        "varName": "fundTransferAccountNumber",
        "type": "text",
        "label": "Fund Transfer Account Number",
        "grid": 3,

    },

    {
        "varName": "branchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 3,

    },
    {
        "varName": "eTINStatus",
        "type": "text",
        "label": "E-TIN Status",
        "grid": 3,

    },
    {
        "varName": "lienStatus",
        "type": "text",
        "label": "Lien Status",
        "grid": 3,

    },
    {
        "varName": "freezeStatus",
        "type": "text",
        "label": "Freeze Status",
        "grid": 3,

    },
    {
        "varName": "nomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 3,

    },
    {
        "varName": "nomineeRelationship",
        "type": "text",
        "label": "Nominee Relationship",
        "grid": 3,

    },
    {
        "varName": "citygemCenterName",
        "type": "text",
        "label": "Citygem Center Name",
        "grid": 3,

    },

];
let certificate = [
    {
        "varName": "title",
        "type": "title",
        "label": "Certificate",
        "grid": 12,

    },


    {
        "varName": "tenor",
        "type": "text",
        "label": "Tenure",
        "grid": 3,

    },
    {
        "varName": "principalAmount",
        "type": "text",
        "label": "Principal Amount",
        "grid": 3,

    },
    {
        "varName": "fDOpenDate",
        "type": "text",
        "label": "FD Open Date",
        "grid": 3,

    },
    {
        "varName": "fDSchemeCode",
        "type": "text",
        "label": "FD Scheme Code",
        "grid": 3,

    },
    {
        "varName": "lastRenewalDate",
        "type": "text",
        "label": "Last Renewal Date",
        "grid": 3,

    },

    {
        "varName": "branchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 3,

    },
    {
        "varName": "fDCloseDate",
        "type": "text",
        "label": "FD Close Date",
        "grid": 3,

    },

    {
        "varName": "citygemCenterName",
        "type": "text",
        "label": "Citygem Center Name",
        "grid": 3,

    },
    {
        "varName": "encashmentValue",
        "type": "text",
        "label": "Encashment Value",
        "grid": 3,

    },

];
let linkACC = [
    {
        "varName": "title",
        "type": "title",
        "label": "Link ACC",
        "grid": 12,

    },


    {
        "varName": "tenor",
        "type": "text",
        "label": "Tenure",
        "grid": 3,

    },
    {
        "varName": "principalAmount",
        "type": "text",
        "label": "Principal Amount",
        "grid": 3,

    },
    {
        "varName": "fDOpenDate",
        "type": "text",
        "label": "FD Open Date",
        "grid": 3,

    },
    {
        "varName": "fDSchemeCode",
        "type": "text",
        "label": "FD Scheme Code",
        "grid": 3,

    },
    {
        "varName": "linkDebitAccountNumber",
        "type": "text",
        "label": "Link/Debit Account Number",
        "grid": 3,

    },
    {
        "varName": "newLinkAccountNumber",
        "type": "text",
        "label": "New Link Account Number",
        "grid": 3,

    },
    {
        "varName": "branchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 3,

    },
    {
        "varName": "lienStatus",
        "type": "text",
        "label": "Lien Status",
        "grid": 3,

    },
    {
        "varName": "freezeStatus",
        "type": "text",
        "label": "Freeze Status",
        "grid": 3,

    },
    {
        "varName": "citygemCenterName",
        "type": "text",
        "label": "Citygem Center Name",
        "grid": 3,

    },

];
let tenorOrScheme = [
    {
        "varName": "title",
        "type": "title",
        "label": "Tenor Or Scheme",
        "grid": 12,

    },


    {
        "varName": "tenor",
        "type": "text",
        "label": "Tenure",
        "grid": 3,

    },
    {
        "varName": "principalAmount",
        "type": "text",
        "label": "Principal Amount",
        "grid": 3,

    },
    {
        "varName": "fDOpenDate",
        "type": "text",
        "label": "FD Open Date",
        "grid": 3,

    },
    {
        "varName": "fDSchemeCode",
        "type": "text",
        "label": "FD Scheme Code",
        "grid": 3,

    },
    {
        "varName": "linkDebitAccountNumber",
        "type": "text",
        "label": "Link/Debit Account Number",
        "grid": 3,

    },
    {
        "varName": "fDCurrentBalance",
        "type": "text",
        "label": "FD Current Balance",
        "grid": 3,

    },
    {
        "varName": "lastRenewalDate",
        "type": "text",
        "label": "Last Renewal Date",
        "grid": 3,

    },
    {
        "varName": "newTenor",
        "type": "text",
        "label": "New Tenure",
        "grid": 3,

    },
    {
        "varName": "newSchemeCode",
        "type": "text",
        "label": "New Scheme Code",
        "grid": 3,

    },
    {
        "varName": "branchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 3,

    },
    {
        "varName": "etinStatus",
        "type": "text",
        "label": "E-Tin Status",
        "grid": 3,

    },
    {
        "varName": "tinNumber",
        "type": "text",
        "etinBlock":true,
        "label": "Tin Number",
        "grid": 3,

    },
    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "tinNumberopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "tinNumberopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "tinNumberopen"],
        "conditionalArrayValue": ["Y", true],
        "grid":2,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "required":true,
        "label": "Tax Submission Year",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "tinNumberopen"],
        "conditionalArrayValue": ["N", true],
        "grid": 2,
    },
    {
        "varName": "lienStatus",
        "type": "text",
        "label": "Lien Status",
        "grid": 3,

    },
    {
        "varName": "freezeStatus",
        "type": "text",
        "label": "Freeze Status",
        "grid": 3,

    },
    {
        "varName": "citygemCenterName",
        "type": "text",
        "label": "Citygem Center Name",
        "grid": 3,

    },
    {
        "varName": "changeOnlyOnceAllowable",
        "type": "text",
        "label": "Change “Only Once” Allowable",
        "grid": 3,

    },
    {
        "varName": "deferralStatus",
        "type": "text",
        "label": "Deferral Status",
        "grid": 3,

    },

];
let sTax = [
    {
        "varName": "title",
        "type": "title",
        "label": "S. Tax",
        "grid": 12,

    },


    {
        "varName": "tenor",
        "type": "text",
        "label": "Tenure",
        "grid": 3,

    },
    {
        "varName": "principalAmount",
        "type": "text",
        "label": "Principal Amount",
        "grid": 3,

    },
    {
        "varName": "fDOpenDate",
        "type": "text",
        "label": "FD Open Date",
        "grid": 3,

    },
    {
        "varName": "fDSchemeCode",
        "type": "text",
        "label": "FD Scheme Code",
        "grid": 3,

    },
    {
        "varName": "linkDebitAccountNumber",
        "type": "text",
        "label": "Link/Debit Account Number",
        "grid": 3,

    },
    {
        "varName": "fundTransferAccountNumber",
        "type": "text",
        "label": "Fund Transfer Account Number",
        "grid": 3,

    },
    {
        "varName": "branchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 3,

    },
    {
        "varName": "fDCloseDate",
        "type": "text",
        "label": "FD Close Date",
        "grid": 3,

    },

    {
        "varName": "eTinStatus",
        "type": "text",
        "label": "E-Tin Status",
        "grid": 3,

    },
    {
        "varName": "lienStatus",
        "type": "text",
        "label": "Lien Status",
        "grid": 3,

    },
    {
        "varName": "freezeStatus",
        "type": "text",
        "label": "Freeze Status",
        "grid": 3,

    },
    {
        "varName": "citygemCenterName",
        "type": "text",
        "label": "Citygem Center Name",
        "grid": 3,

    },
    {
        "varName": "sourceTaxCalculation",
        "type": "text",
        "label": "Source Tax calculation",
        "grid": 3,

    },

];
let duplAdvice = [
    {
        "varName": "title",
        "type": "title",
        "label": "Dupl Advice",
        "grid": 12,

    },


    {
        "varName": "tenor",
        "type": "text",
        "label": "Tenure",
        "grid": 3,

    },
    {
        "varName": "principalAmount",
        "type": "text",
        "label": "Principal Amount",
        "grid": 3,

    },
    {
        "varName": "fDOpenDate",
        "type": "text",
        "label": "FD Open Date",
        "grid": 3,

    },
    {
        "varName": "fDSchemeCode",
        "type": "text",
        "label": "FD Scheme Code",
        "grid": 3,

    },
    {
        "varName": "linkDebitAccountNumber",
        "type": "text",
        "label": "Link /Debit Account Number",
        "grid": 3,

    },
    {
        "varName": "fDOpeningRate",
        "type": "text",
        "label": "FD Opening Rate",
        "grid": 3,

    },
    {
        "varName": "maturityDate",
        "type": "text",
        "label": "Maturity Date",
        "grid": 3,

    },
    {
        "varName": "branchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 3,

    },
    {
        "varName": "renewalInstruction",
        "type": "text",
        "label": "Renewal Instruction",
        "grid": 3,

    },
    {
        "varName": "fDCloseStatus",
        "type": "text",
        "label": "FD Close Status",
        "grid": 3,

    },

    {
        "varName": "lienStatus",
        "type": "text",
        "label": "Lien Status",
        "grid": 3,

    },
    {
        "varName": "freezeStatus",
        "type": "text",
        "label": "Freeze Status",
        "grid": 3,

    },
    {
        "varName": "citygemCenterName",
        "type": "text",
        "label": "Citygem Center Name",
        "grid": 3,

    },

    {
        "varName": "customerCommunicationAddress",
        "type": "text",
        "label": "Customer Communication Address",
        "grid": 3,

    },
    {
        "varName": "customerPhoneNumber",
        "type": "text",
        "label": "Customer Phone Number",
        "grid": 3,

    },
];
let others = [
    {
        "varName": "title",
        "type": "title",
        "label": "Others",
        "grid": 12,

    },


    {
        "varName": "tenor",
        "type": "text",
        "label": "Tenure",
        "grid": 3,

    },
    {
        "varName": "principalAmount",
        "type": "text",
        "label": "Principal Amount",
        "grid": 3,

    },
    {
        "varName": "fDOpenDate",
        "type": "text",
        "label": "FD Open Date",
        "grid": 3,

    },
    {
        "varName": "fDSchemeCode",
        "type": "text",
        "label": "FD Scheme Code",
        "grid": 3,

    },
    {
        "varName": "branchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 3,

    },
    {
        "varName": "emailAddress",
        "type": "text",
        "label": "Email Address",
        "grid": 3,

    },
    {
        "varName": "mobileNumber",
        "type": "text",
        "label": "Mobile Number",
        "grid": 3,

    },
    {
        "varName": "fDCloseStatus",
        "type": "text",
        "label": "FD Close Status",
        "grid": 3,

    },

    {
        "varName": "citygemCenterName",
        "type": "text",
        "label": "Citygem Center Name",
        "grid": 3,

    },


];
let regulatoryACC = [
    {
        "varName": "title",
        "type": "title",
        "label": "Regulatory ACC",
        "grid": 12,

    },
    {
        "varName": "specificReferenceNumber",
        "type": "text",
        "label": "Specific Reference Number",
        "grid": 3,

    },
    {
        "varName": "responsiblePerson",
        "type": "select",
        "label": "Responsible Person",
        "grid": 3,
        "enum": [
            "ff",
            "ff",
        ]

    },
    {
        "varName": "senderName",
        "type": "select",
        "label": "Sender Name",
        "grid": 3,
        "enum": [
            "sd",
            "ss",
        ]

    },
    {
        "varName": "priorityMarking",
        "type": "select",
        "label": "Priority Marking",
        "grid": 3,
        "enum": [
            "143 Letter",
            "Freeze Marking",
            "Withdrawal",
            "Information"
        ]

    },
    {
        "varName": "tentativeCompletionDate",
        "type": "date",
        "label": "Tentative Completion Date",
        "grid": 3,
    },
    {
        "varName": "fileAttachmentBrowser",
        "type": "text",
        "label": "File Attachment Browser",
        "grid": 3,

    },
];


let BOMFdrEncashmentMaintenance = {};
BOMFdrEncashmentMaintenance = makeReadOnlyObject(JSON.parse(JSON.stringify(encashmentSD)));


/////Bm///////////////////
let BMFdrEncashmentMaintenance = {};
BMFdrEncashmentMaintenance = makeReadOnlyObject(JSON.parse(JSON.stringify(encashment)));


//////call fdr/////////////////////////
const encashmentSDPart = [
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FD Nominee Change"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "FD nominee Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FD Nominee Change"
    },
    {
        "varName": "nomineeNumberOld",
        "type": "text",
        "label": "Existing Nominee",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FD Nominee Change"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "FDR Encashment",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "FDR Interest Payment",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Deceased FDR Encashment",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "inputFundTransferAccountNumber",
        "type": "select",
        "label": "Input Fund Transfer Account Number",
        "grid": 6,
        "enum": [
            'Own AC',
            'Third Party AC',
            'Payment to GL AC',
            'Payorder',
            'EFT Payment',
            'RTGS Payment',
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment"
    },
    {
        "varName": "inputFundTransferAccountNumber",
        "type": "select",
        "label": "Input Fund Transfer Account Number",
        "grid": 6,
        "enum": [
            'Own AC',
            'Third Party AC',
            'Payment to GL AC',
            'Payorder',
            'EFT Payment',
            'RTGS Payment',
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "inputFundTransferAccountNumber",
        "type": "select",
        "label": "Input Fund Transfer Account Number",
        "grid": 6,
        "enum": [
            'Own AC',
            'Third Party AC',
            'Payment to GL AC',
            'Payorder',
            'EFT Payment',
            'RTGS Payment',
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "acNumber",
        "type": "text",
        "label": "A/c Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalVarValue": 'Own AC',
    },
    {
        "varName": "acName",
        "type": "text",
        "label": "A/c Name",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalVarValue": 'Own AC',
    },
    {
        "varName": "acNumber",
        "type": "text",
        "label": "A/c Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalVarValue": 'Third Party AC',
    },
    {
        "varName": "acName",
        "type": "text",
        "label": "A/c Name",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalVarValue": 'Third Party AC',
    },
    {
        "varName": "acNumber",
        "type": "text",
        "label": "A/c Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalVarValue": 'Payment to GL AC',
    },
    {
        "varName": "acName",
        "type": "text",
        "label": "A/c Name",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalVarValue": 'Payment to GL AC',
    },
    {
        "varName": "acNumber",
        "type": "text",
        "label": "A/c Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalVarValue": 'Payorder',
    },
    {
        "varName": "acName",
        "type": "text",
        "label": "A/c Name",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalVarValue": 'Payorder',
    },
    {
        "varName": "transferAccountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "transferCustomerName",
        "type": "text",
        "label": "Customer Name",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "transferAccountBalance",
        "type": "text",
        "label": "Account Balance",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "transferType",
        "type": "select",
        "label": "Transfer Type",
        "enum": [
            "RTGS",
            "BEFTN",
            "Salary"
        ],
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "customerType",
        "type": "select",
        "enum": [
            "Retail",
            "SME/Corporate"
        ],
        "label": "Customer Type",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "transferAmount",
        "type": "text",
        "label": "Transfer Amount",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "creditAccountNumber",
        "type": "text",
        "label": "Credit Account Number",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "beneficiaryDetails",
        "type": "text",
        "label": "Beneficiary Details",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "beneficiaryBankName",
        "type": "text",
        "label": "Beneficiary Bank Name",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "branchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "routingNumber",
        "type": "text",
        "label": "Routing Number",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "solIdOne",
        "type": "text",
        "label": "SOL ID",
        "grid": 6,
        "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "acIdOne",
        "type": "text",
        "label": "A/c. ID",
        "grid": 6,
        "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "principalOutflow",
        "type": "text",
        "label": "PRINCIPAL OUTFLOW/TOTAL OUTFLOW",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "acIdTwo",
        "type": "text",
        "label": "A/c. ID",
        "grid": 6,
        "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "amtType",
        "type": "select",
        "label": "AMT. TYPE",
        "grid": 6,
        "enum": [
            "Cash",
            "Cheque"
        ],
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "amt",
        "type": "text",
        "label": "Amt",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "particularsOne",
        "type": "text",
        "label": "Particulars",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "record",
        "type": "checkbox",
        "label": "Record",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "taxCategory",
        "type": "text",
        "label": "Tax Category",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "withHoldingTaxLevel",
        "type": "text",
        "label": "Withholding Tax Level",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "withHoldingTaxBorneBy",
        "type": "text",
        "label": "Withholding Tax Borne By",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "withHoldingTaxPcnt",
        "type": "text",
        "label": "WITHHOLDING TAX Pcnt.",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "acIdThree",
        "type": "text",
        "label": "A/C ID",
        "grid": 6,
        "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "freezeCode",
        "type": "text",
        "label": "Freeze Code",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "freezereasonCode1",
        "type": "text",
        "label": "Freeze Reason Code 1",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "freezeRemarks1",
        "type": "text",
        "label": "Freeze Remarks1",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "acIdFour",
        "type": "text",
        "label": "A/C ID",
        "grid": 6,
        "radOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "closureValueDate",
        "type": "date",
        "label": "Closure Value Date",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "withdrawalAmtPrincipal",
        "type": "text",
        "label": "Withdrawal Amt. (Principal)",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "closeMode",
        "type": "select",
        "label": "Close Mode",
        "enum": [
            "A",
            "B"
        ],
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "repaymentAcId",
        "type": "text",
        "label": "REPAYMENT A/C ID",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "operativeAccount",
        "type": "text",
        "label": "OPERATIVE ACCOUNT",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "doYouWantToCollectExciseDuty",
        "type": "text",
        "label": "DO YOU WANT TO COLLECT EXCISE DUTY ",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "acBillDisbursement",
        "type": "text",
        "label": "A/c./Bill/Disbursement",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "acBillDisbursementId",
        "type": "text",
        "label": "A/c./Bill/Disbursement Id",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "adjustedAmt",
        "type": "text",
        "label": "Adjusted Amt. (1ST FIELD OF 1ST RAW)",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "debitCreditInterest",
        "type": "text",
        "label": "Debit/Credit Interest (2ND FIELD OF 1ST RAW)",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "debitCreditInterest",
        "type": "text",
        "label": "Debit/Credit Interest (2ND FIELD OF 1ST RAW)",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "runIndicator",
        "type": "text",
        "label": "Run Indicator (3RD FIELD OF 1ST RAW)",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "remarks",
        "type": "text",
        "label": "REMARKS (4TH FIELD OF 1ST RAW)",
        "grid": 6,
        "multiline": true,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "del",
        "type": "text",
        "label": "DEL (5TH FIELD OF 1ST RAW)",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "transactionTypeOne",
        "type": "text",
        "label": "Transaction Type/Subtype",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "partTransactionTypeOne",
        "type": "text",
        "label": "Part Transaction Type",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "acIdFive",
        "type": "text",
        "label": "A/c. ID",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "refCCYOne",
        "type": "text",
        "label": "Ref. CCY/Amt",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "transactionParticularsCodeTwo",
        "type": "text",
        "label": "Transaction Particulars Code",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "transactionParticularsTwo",
        "type": "text",
        "label": "Transaction Particulars",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "partTransactionTypeTwo",
        "type": "text",
        "label": "Part Transaction Type",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "acIdSix",
        "type": "text",
        "label": "A/c. ID",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "refCCYTwo",
        "type": "text",
        "label": "Ref. CCY/Amt",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "transactionParticularsCodeThree",
        "type": "text",
        "label": "Transaction Particulars Code",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    },
    {
        "varName": "transactionParticularsThree",
        "type": "text",
        "label": "Transaction Particulars",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Deceased FDR Encashment"]
    }
    ,
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "CASA Link AC Nominee",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "casaLinkAcNomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Deceased FDR Encashment',
    },
    {
        "varName": "casaLinkAcRelationship",
        "type": "text",
        "label": "Relationship",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Deceased FDR Encashment',
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "FD AC Nominee",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "fdAcNomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Deceased FDR Encashment',
    },
    {
        "varName": "fdAcRelationship",
        "type": "text",
        "label": "Relationship",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Deceased FDR Encashment',
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "FDR Encashment Certificate",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "encashmentCustomerName",
        "type": "text",
        "label": "Customer Name",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "encashmentAccountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "encashmentBranchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "encashmentPrincipleAmount",
        "type": "text",
        "label": "Principle Amount",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "encashmentTdsPeriod",
        "type": "text",
        "label": "TDS Period",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "encashmentTotalInterest",
        "type": "text",
        "label": "Total Interest",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "encashmentSourceTax",
        "type": "text",
        "label": "Source Tax Deducted",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "encashmentNetInttAmount",
        "type": "text",
        "label": "Net Intt. Amount Credited",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "encashmentExciseDuty",
        "type": "text",
        "label": "Excise Duty",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Present FDR Encashment Value"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Present FDR Encashment Value",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Present FDR Encashment Value"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Link ACC Change"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Link ACC Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Link ACC Change"
    },
    {
        "varName": "newLinkAccountNumber",
        "type": "text",
        "label": "New Link Account Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Link ACC Change',
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Tenor or Scheme Change"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Tenor or Scheme Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Tenor or Scheme Change"
    },
    {
        "varName": "tenorType",
        "type": "select",
        "enum": [
            "Day",
            "Monthly"
        ],
        "label": "Tenor Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Tenor or Scheme Change',
    },
    {
        "varName": "newTenor",
        "type": "select",
        "enum": [
            "90",
            "180",
            "182",
            "362",
            "365"
        ],
        "label": "New Tenor",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "tenorType",
        "conditionalVarValue": "Day",
    },
    {
        "varName": "newTenor",
        "type": "select",
        "enum": [
            "1",
            "3",
            "6",
            "12",
            "24",
            "36"
        ],
        "label": "New Tenor",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "tenorType",
        "conditionalVarValue": "Monthly",
    },
    {
        "varName": "newSchemaCode",
        "type": "text",
        "label": "New Scheme Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Tenor or Scheme Change',
    },
    {
        "varName": "newSchemaCode",
        "type": "select",
        "label": "Renewal /Disposal Instruction ",
        "grid": 6,
        "enum": [
            "Yes",
            "No"
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Tenor or Scheme Change',
    },
    {
        "varName": "reIssueFdrWith",
        "type": "select",
        "label": "Re-Issue FDR with ",
        "grid": 6,
        "enum": [
            "Yes",
            "No"
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Tenor or Scheme Change',
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "S. Tax Reversal"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "S. Tax Reversal",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "S. Tax Reversal"
    },
    {
        "varName": "transactionType",
        "type": "text",
        "label": "Transaction Type/Subtype",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "partTransactionType",
        "type": "text",
        "label": "Part Transaction Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "acIdOne",
        "type": "text",
        "label": "A/c. ID ",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "refCCYAmtOne",
        "type": "text",
        "label": "Ref. CCY/Amt",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "transactionParticularsCodeOne",
        "type": "text",
        "label": "Transaction Particulars Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "transactionParticularsOne",
        "type": "text",
        "label": "Transaction Particulars",
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "partTransactionTypeOne",
        "type": "text",
        "label": "Part Transaction Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "acIdTwo",
        "type": "text",
        "label": "A/c. ID ",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "refCCYAmtTwo",
        "type": "text",
        "label": "Ref. CCY/Amt",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "transactionParticularsCodeTwo",
        "type": "text",
        "label": "Transaction Particulars Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "transactionParticularsTwo",
        "type": "text",
        "label": "Transaction Particulars",
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Dupl. Advice (Special)"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Dupl. Advice (Special)",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Dupl. Advice (Special)"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Auxiliary Task",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "selectRequestType",
        "type": "select",
        "label": "Select Request type",
        "enum": [
            "Freeze/Unfreeze Mark ",
            "Deceased Marking ",
            "Priority Marking ",
            "SBS Code Update/Correction",
            "RM Code Update",
            "Citytouch Tagging ",
            "AMLD/Branch Support",
        ],
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Regulatory Account Inquiry",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "specificReferenceNumber",
        "type": "text",
        "label": "Specific Reference Number",
        "grid": 5,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "nameOfInstructedBody",
        "type": "select",
        "label": "Name of Instructed Body (Sender Name)",
        "grid": 7,
        "enum": [
            "A",
            "B",
            "C"
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "priorityMaking",
        "type": "select",
        "label": "Priority Marking",
        "grid": 5,
        "enum": [
            "143 Letter",
            "Freeze Marking",
            "withdrawal",
            "Information",
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "tentetiveCompletionDate",
        "type": "date",
        "label": "Tentetive Completion Date",
        "grid": 7,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "solIdOne",
        "type": "text",
        "label": "SOL ID",
        "grid": 6,
        "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "acIdOne",
        "type": "text",
        "label": "A/c. ID",
        "grid": 6,
        "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "principalOutflow",
        "type": "text",
        "label": "PRINCIPAL OUTFLOW/TOTAL OUTFLOW",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "acIdTwo",
        "type": "text",
        "label": "A/c. ID",
        "grid": 6,
        "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "amtType",
        "type": "select",
        "label": "AMT. TYPE",
        "grid": 6,
        "enum": [
            "Cash",
            "Cheque"
        ],
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "amt",
        "type": "text",
        "label": "Amt",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "particularsOne",
        "type": "text",
        "label": "Particulars",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "record",
        "type": "checkbox",
        "label": "Record",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "taxCategory",
        "type": "text",
        "label": "Tax Category",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "withHoldingTaxLevel",
        "type": "text",
        "label": "Withholding Tax Level",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "withHoldingTaxBorneBy",
        "type": "text",
        "label": "Withholding Tax Borne By",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "withHoldingTaxPcnt",
        "type": "text",
        "label": "WITHHOLDING TAX Pcnt.",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "acIdThree",
        "type": "text",
        "label": "A/C ID",
        "grid": 6,
        "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "freezeCode",
        "type": "text",
        "label": "Freeze Code",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "freezereasonCode1",
        "type": "text",
        "label": "Freeze Reason Code 1",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "freezeRemarks1",
        "type": "text",
        "label": "Freeze Remarks1",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "acIdFour",
        "type": "text",
        "label": "A/C ID",
        "grid": 6,
        "radOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "closureValueDate",
        "type": "date",
        "label": "Closure Value Date",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "withdrawalAmtPrincipal",
        "type": "text",
        "label": "Withdrawal Amt. (Principal)",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "closeMode",
        "type": "select",
        "label": "Close Mode",
        "enum": [
            "A",
            "B"
        ],
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "repaymentAcId",
        "type": "text",
        "label": "REPAYMENT A/C ID",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "operativeAccount",
        "type": "text",
        "label": "OPERATIVE ACCOUNT",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "doYouWantToCollectExciseDuty",
        "type": "text",
        "label": "DO YOU WANT TO COLLECT EXCISE DUTY ",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "acBillDisbursement",
        "type": "text",
        "label": "A/c./Bill/Disbursement",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "acBillDisbursementId",
        "type": "text",
        "label": "A/c./Bill/Disbursement Id",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "adjustedAmt",
        "type": "text",
        "label": "Adjusted Amt. (1ST FIELD OF 1ST RAW)",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "debitCreditInterest",
        "type": "text",
        "label": "Debit/Credit Interest (2ND FIELD OF 1ST RAW)",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "debitCreditInterest",
        "type": "text",
        "label": "Debit/Credit Interest (2ND FIELD OF 1ST RAW)",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "runIndicator",
        "type": "text",
        "label": "Run Indicator (3RD FIELD OF 1ST RAW)",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "remarks",
        "type": "text",
        "label": "REMARKS (4TH FIELD OF 1ST RAW)",
        "grid": 6,
        "multiline": true,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "del",
        "type": "text",
        "label": "DEL (5TH FIELD OF 1ST RAW)",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "transactionTypeOne",
        "type": "text",
        "label": "Transaction Type/Subtype",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "partTransactionTypeOne",
        "type": "text",
        "label": "Part Transaction Type",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "acIdFive",
        "type": "text",
        "label": "A/c. ID",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "refCCYOne",
        "type": "text",
        "label": "Ref. CCY/Amt",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "transactionParticularsCodeTwo",
        "type": "text",
        "label": "Transaction Particulars Code",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "transactionParticularsTwo",
        "type": "text",
        "label": "Transaction Particulars",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "partTransactionTypeTwo",
        "type": "text",
        "label": "Part Transaction Type",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "acIdSix",
        "type": "text",
        "label": "A/c. ID",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "refCCYTwo",
        "type": "text",
        "label": "Ref. CCY/Amt",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "transactionParticularsCodeThree",
        "type": "text",
        "label": "Transaction Particulars Code",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "transactionParticularsThree",
        "type": "text",
        "label": "Transaction Particulars",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment"]
    },
    {
        "varName": "solId",
        "type": "text",
        "label": "SOL ID",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "acIdOne",
        "type": "text",
        "label": "A/c. ID",
        "grid": 6,
        "conditional": true,
        "readOnly": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "principalOutflow",
        "type": "text",
        "label": "PRINCIPAL OUTFLOW/TOTAL OUTFLOW",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "acIdTwo",
        "type": "text",
        "label": "A/c. ID",
        "grid": 6,
        "conditional": true,
        "readOnly": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "amtType",
        "type": "select",
        "label": "AMT. TYPE",
        "grid": 6,
        "enum": [
            "Cash",
            "Cheque"
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "amt",
        "type": "text",
        "label": "Amt",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "particulars",
        "type": "text",
        "label": "Particulars",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "record",
        "type": "checkbox",
        "label": "Record",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "acIdThree",
        "type": "text",
        "label": "A/C ID",
        "grid": 6,
        "conditional": true,
        "readOnly": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "freezeCode",
        "type": "text",
        "label": "Freeze Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "freezereasonCode1",
        "type": "text",
        "label": "Freeze Reason Code 1",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "freezeRemarks1",
        "type": "text",
        "label": "Freeze Remarks1",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "acId",
        "type": "text",
        "label": "A/C ID",
        "grid": 6,
        "radOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "closureValueDate",
        "type": "date",
        "label": "Closure Value Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "withdrawalAmtPrincipal",
        "type": "text",
        "label": "Withdrawal Amt. (Principal)",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "closeMode",
        "type": "select",
        "label": "Close Mode",
        "enum": [
            "A",
            "B"
        ],
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "repaymentAcId",
        "type": "text",
        "label": "REPAYMENT A/C ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "operativeAccount",
        "type": "text",
        "label": "OPERATIVE ACCOUNT",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "doYouWantToCollectExciseDuty",
        "type": "text",
        "label": "DO YOU WANT TO COLLECT EXCISE DUTY ",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "transactionTypeOne",
        "type": "text",
        "label": "Transaction Type/Subtype",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "partTransactionTypeOne",
        "type": "text",
        "label": "Part Transaction Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "acIdFour",
        "type": "text",
        "label": "A/c. ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "refCCYOne",
        "type": "text",
        "label": "Ref. CCY/Amt",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "transactionParticularsCodeOne",
        "type": "text",
        "label": "Transaction Particulars Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "transactionParticularsOne",
        "type": "text",
        "label": "Transaction Particulars",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "partTransactionTypeTwo",
        "type": "text",
        "label": "Part Transaction Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "acIdSix",
        "type": "text",
        "label": "A/c. ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "refCCYTwo",
        "type": "text",
        "label": "Ref. CCY/Amt",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "transactionParticularsCodeTwo",
        "type": "text",
        "label": "Transaction Particulars Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "transactionParticularsTwo",
        "type": "text",
        "label": "Transaction Particulars",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "solId",
        "type": "text",
        "label": "SOL ID",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Link ACC Change"
    },
    {
        "varName": "acIdOne",
        "type": "text",
        "label": "A/c. ID",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Link ACC Change"
    },
    {
        "varName": "interestCreditAcId",
        "type": "text",
        "label": "INTEREST CREDIT A/C ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Link ACC Change"
    },
    {
        "varName": "repaymentAcId",
        "type": "text",
        "label": "REPAYMENT A/C ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Link ACC Change"
    },
    {
        "varName": "solId",
        "type": "text",
        "label": "SOL ID",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "acIdOne",
        "type": "text",
        "label": "A/c ID",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "acIdTwo",
        "type": "text",
        "label": "A/c ID",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "freezeCode",
        "type": "text",
        "label": "Freeze Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "freezeReasonCode1",
        "type": "text",
        "label": "Freeze Reason Code 1",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "freezeRemarks1",
        "type": "text",
        "label": "Freeze Remarks1",
        "grid": 6,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "sectorCode",
        "type": "text",
        "label": "SECTOR CODE",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "subSectorCode",
        "type": "text",
        "label": "SUB SECTOR CODE",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "occupationCode",
        "type": "text",
        "label": "OCCUPATION CODE",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "freeCode3",
        "type": "text",
        "label": "FREE CODE 3 (RM CODE)",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "emailAddress",
        "type": "text",
        "label": "Email Address",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "mobileNumber",
        "type": "text",
        "label": "Mobile Number",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "fdrAccountNumber",
        "type": "text",
        "label": "FDR Account Number",
        "grid": 6,
        "conditional": true,
        "readOnly": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Present FDR Encashment Value"
    },
    {
        "varName": "fdrAccountName",
        "type": "text",
        "label": "FDR Account Name",
        "grid": 6,
        "conditional": true,
        "readOnly": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Present FDR Encashment Value"
    },
    {
        "varName": "fdrOpenDate",
        "type": "date",
        "label": "FDR Open Date",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Present FDR Encashment Value"
    },
    {
        "varName": "principalAmount",
        "type": "text",
        "label": "Principal Amount",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Present FDR Encashment Value"
    },
    {
        "varName": "prematureNetInterest",
        "type": "text",
        "label": "Premature Net Interest",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Present FDR Encashment Value"
    },
    {
        "varName": "exciseDuty",
        "type": "text",
        "label": "Excise Duty",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Present FDR Encashment Value"
    },
    {
        "varName": "customerNetPayable",
        "type": "text",
        "label": "Customer Net Payable",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Present FDR Encashment Value"
    },
];


let CALLFdrEncashmentMaintenance = {};
CALLFdrEncashmentMaintenance = makeReadOnlyObject(JSON.parse(JSON.stringify(encashmentSDPart)));


////maker/////

let MAKERFdrEncashmentMaintenance = {};
MAKERFdrEncashmentMaintenance = makeReadOnlyObject(JSON.parse(JSON.stringify(encashmentSDPart)));


///checker///
let CHECHERFdrEncashmentMaintenance = {};
CHECHERFdrEncashmentMaintenance = makeReadOnlyObject(JSON.parse(JSON.stringify(encashmentSDPart)));

// last update 11-11-19

const bearer = [
    {
        "varName": "csBearer",
        "type": "select",
        "label": "Bearer",
        "enum": [
            "YES",
            "NO"
        ],
        "grid": 3
    },
    {
        "varName": "bearerApproval",
        "type": "select",
        "label": "Bearer Approval",
        "grid": 3,
        "enum": [
            "BM Approval",
            "Call Center Approval",
            "BM & Call Center Approval"

        ]
    }
];
const maintenanceListCS = [
    {
        "varName": "digitTinChange",
        "type": "checkbox",
        "label": "E Tin Update",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "digitTinChange",
        // "conditionalVarValue": true


    },

    {
        "varName": "tinUpdateOld",
        "type": "text",
        "label": "Existing TIN",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true


    },
    {
        "varName": "tinUpdateNew",
        "type": "text",
        "label": "New TIN",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "digitTinChange",
        "conditionalVarValue": true

    },
    {
        "varName": "titleChange",
        "type": "checkbox",
        "label": "Title Change",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "titleChange",
        // "conditionalVarValue": true

    },


    {
        "varName": "titleOld",
        "type": "autoCompleteValueReturn",
        "label": "Existing Account Title",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "existingNameOld",
        "type": "text",
        "label": "Existing Name",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "titleChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nomineeChange",
        "type": "checkbox",
        "label": "Nominee Update",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "nomineeChange",
        // "conditionalVarValue": true

    },
    {
        "varName": "nomineeNumberOld",
        "type": "text",
        "label": "Existing Number of Nominee",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nomineeNameOld",
        "type": "text",
        "label": "Existing Nominee Name",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nomineeDOBOld",
        "type": "text",
        "label": "Existing Nominee DOB",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nomineePercentageOld",
        "type": "text",
        "label": "Existing Nominee Percentage",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nomineeMinor",
        "type": "select",
        "label": "Nominee Minor",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true,
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "nomineeNameUpdate",
        "type": "text",
        "label": "Nominee Name Update",
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nomineePercentageOne",
        "type": "text",
        "label": "Nominee Percentage",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nomineeDOBOne",
        "type": "text",
        "label": "Nominee DOB",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nomineeMinorOne",
        "type": "select",
        "label": "Nominee Minor",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true,
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "existingNomineeOne",
        "type": "text",
        "label": "Existing Nominee Name",
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true
    },
    {
        "varName": "existingNomineeDOBOne",
        "type": "text",
        "label": "Existing Nominee DOB",
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true
    },
    {
        "varName": "existingNomineePercentageOne",
        "type": "text",
        "label": "Existing Nominee Percentage",
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nomineeMinorTwo",
        "type": "select",
        "label": "Nominee Minor",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true,
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "nomineeNameName",
        "type": "text",
        "label": "New Nominee Name",
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nomineePercentageNew",
        "type": "text",
        "label": "New Nominee Percentage",
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true
    },

    {
        "varName": "newNomineeDOB",
        "type": "text",
        "label": "New Nominee DOB",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nomineeMinorThree",
        "type": "select",
        "label": "Nominee Minor",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true,
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "guardian",
        "type": "select",
        "label": "Guardian",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true,
        "enum": [
            "YES",
            "NO"
        ]
    },

    {
        "varName": "photoIdChange",
        "type": "checkbox",
        "label": "Photo Id Change",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "photoIdChange",
        // "conditionalVarValue": true
    },

    {
        "varName": "existingNID",
        "type": "text",
        "label": "Existing NID No.",
        "readOnly": true,
        "grid": 3,
        "errorMessage": "Error",
        "nid": true,
        // "required": true,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "newNID",
        "type": "text",
        "label": "New NID No.",
        "grid": 3,
        "errorMessage": "Error",
        "nid": true,
        // "required": true,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingPassport",
        "type": "text",
        "label": "Existing Passport No.",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "newPassport",
        "type": "text",
        "label": "New Passport No.",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingTradeLicense",
        "type": "text",
        "label": "Existing Trade License No.",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "newTradeLicense",
        "type": "text",
        "label": "New Trade License No.",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "photoIdChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "contactNumberChange",
        "type": "checkbox",
        "label": "Contact Number Change",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "contactNumberChange",
        // "conditionalVarValue": true

    },

    {
        "varName": "existingResidenceNumber",
        "type": "text",
        "label": "Existing Residence Number",
        "readOnly": true,
        "grid": 3,
        "phone": true,
        "errorMessage": "Error",
        "conditional": true,
        "conditionalVarName": "contactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "newResidenceNumber",
        "type": "text",
        "label": "New Residence Number",
        "grid": 3,
        "errorMessage": "Error",
        "phone": true,
        // "required": true,
        "conditional": true,
        "conditionalVarName": "contactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingOfficeNumber",
        "type": "text",
        "label": "Existing Office Number",
        "readOnly": true,
        "grid": 3,
        "errorMessage": "Error",
        "phone": true,
        "conditional": true,
        "conditionalVarName": "contactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "newOfficeNumber",
        "type": "text",
        "label": "New Office Number",
        "grid": 3,
        "errorMessage": "Error",
        "phone": true,
        // "required": true,
        "conditional": true,
        "conditionalVarName": "contactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "existingMobileNumber",
        "type": "text",
        "label": "Existing Mobile Number",
        "readOnly": true,
        "errorMessage": "Error",
        "phone": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "contactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "newMobileContactNumber",
        "type": "text",
        "label": "New Mobile Number",
        "grid": 3,
        "errorMessage": "Error",
        "phone": true,
        // "required": true,
        "conditional": true,
        "conditionalVarName": "contactNumberChange",
        "conditionalVarValue": true,

    },
    {
        "varName": "emailAddressChange",
        "type": "checkbox",
        "label": "Email Address Change",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "emailAddressChange",
        // "conditionalVarValue": true

    },

    {
        "varName": "existingEmailId",
        "type": "text",
        "label": "Existing Email Id",
        "readOnly": true,
        "grid": 3,
        "errorMessage": "Error",
        "multiline": true,
        "email": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "newEmailId",
        "type": "text",
        "label": "New Email Id",
        "grid": 3,
        "errorMessage": "Error",
        "multiline": true,
        "email": true,
        // "required": true,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "eStatementFacility",
        "type": "select",
        "label": "E Statement enrollment",
        "required": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "emailAddressChange",
        "conditionalVarValue": true,
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "existingEmailId",
        "type": "text",
        "label": "Existing Email Id",
        "readOnly": true,
        "grid": 3,
        "errorMessage": "Error",
        "multiline": true,
        "email": true,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true
    },
    {
        "varName": "newEmailId",
        "type": "text",
        "label": "New Email Id",
        "grid": 3,
        "errorMessage": "Error",
        "multiline": true,
        "email": true,
        // "required": true,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true
    },
    {
        "varName": "eStatementFacility",
        "type": "text",
        "label": "E Statement Facility",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "eStatementEnrollment",
        "conditionalVarValue": true
    },


    {
        "varName": "addressChange",
        "type": "checkbox",
        "label": "Address Change",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "addressChange",
        // "conditionalVarValue": true

    },

    {
        "varName": "existingPermanentAddress",
        "type": "text",
        "label": "Existing Permanent Address",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "newPermanentAddress",
        "type": "text",
        "label": "New Permanent Address",
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "existingPresentAddress",
        "type": "text",
        "label": "Existing Present Address",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "newPresentAddress",
        "type": "text",
        "label": "New Present Address",
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },

    {
        "varName": "existingOfficeAddress",
        "type": "text",
        "label": "Existing Office Address",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "newOfficeAddress",
        "type": "text",
        "label": "New Office Address",
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "existingCommunicationAddress",
        "type": "text",
        "label": "Existing Communication Address",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "newCommunicationAddress",
        "type": "text",
        "label": "New Communication Address",
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "addressChange",
        "conditionalVarValue": true
    },
    {
        "varName": "otherInformationChange",
        "type": "checkbox",
        "label": "Other Information Change",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "otherInformationChange",
        // "conditionalVarValue": true

    },

    {
        "varName": "dobOld",
        "type": "text",
        "label": "Existing DOB",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "dobNew",
        "type": "date",
        "label": "New DOB",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },


    {
        "varName": "fatherOld",
        "type": "text",
        "label": "Father's Name",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "fatherNew",
        "type": "text",
        "label": "Father Name Update",
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },


    {
        "varName": "motherOld",
        "type": "text",
        "label": "Mother's Name",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "motherNew",
        "type": "text",
        "label": "Mother Name Update",
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },

    {
        "varName": "spouseOld",
        "type": "text",
        "label": "Spouse Name",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "spouseNew",
        "type": "text",
        "label": "Spouse Name Update",
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },

    {
        "varName": "professionOld",
        "type": "text",
        "label": "Existing Profession",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "professionNew",
        "type": "text",
        "label": "New Profession",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },


    {
        "varName": "employerOld",
        "type": "text",
        "label": "Existing Employer Name",
        "readOnly": true,
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true
    },
    {
        "varName": "employerNew",
        "type": "text",
        "label": "New Employer Name",
        "grid": 3,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "otherInformationChange",
        "conditionalVarValue": true

    },


    {
        "varName": "signatureCard",
        "type": "checkbox",
        "label": "Signature Card",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "signatureCard",
        // "conditionalVarValue": true

    },


    {
        "varName": "accountStatus",
        "type": "text",
        "label": "Account Status",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "signatureCard",
        "conditionalVarValue": true
    },

    {
        "varName": "dormantAccountActivation",
        "type": "checkbox",
        "label": "Dormant Account Activation",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "dormantAccountActivation",
        // "conditionalVarValue": true
    },
    {
        "varName": "accountStatus",
        "type": "text",
        "label": "Account Status",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "dormantAccountActivation",
        "conditionalVarValue": true
    },
    {
        "varName": "dormantAccountDataUpdate",
        "type": "checkbox",
        "label": "Dormant Account Data Update",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "dormantAccountDataUpdate",
        // "conditionalVarValue": true

    },

    {
        "varName": "schemeMaintenanceLinkChange",
        "type": "checkbox",
        "label": "Scheme Maintenance-Link A/C Change",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "schemeMaintenanceLinkChange",
        // "conditionalVarValue": true

    },

    {
        "varName": "existingLinkAccount",
        "type": "text",
        "label": "Existing Link Account",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "newLinkAccount",
        "type": "text",
        "label": "New Link Account",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "amountExisting",
        "type": "text",
        "label": "Existing Amount",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "amountNew",
        "type": "text",
        "label": "New Amount",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "tenorExisting",
        "type": "text",
        "label": "Existing Tenure",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "tenorNew",
        "type": "text",
        "label": "New Tenure",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "valueDateExisting",
        "type": "text",
        "label": "Existing Value Date",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "valueDateNew",
        "type": "date",
        "label": "New Value Date",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "maturityDateExisting",
        "type": "text",
        "label": "Existing Maturity Date",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "maturityDateNew",
        "type": "date",
        "label": "New Maturity Date",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenanceLinkChange",
        "conditionalVarValue": true

    },
    {
        "varName": "schemeMaintenance",
        "type": "checkbox",
        "label": "Scheme Maintenance - Installment Regularization",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "schemeMaintenance",
        // "conditionalVarValue": true

    },

    {
        "varName": "numberInstallmentDue",
        "type": "text",
        "label": "Number of Installment(s) Due",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true
    },
    {
        "varName": "detailsofMonth",
        "type": "text",
        "label": "Details of Month",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true

    },

    {
        "varName": "amountPerInstallment",
        "type": "text",
        "label": "Amount Per Installment",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true
    },
    {
        "varName": "totalInstallmentAmountDue",
        "type": "text",
        "label": "Total Installment Amount Due",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true

    },

    {
        "varName": "debitDueInstallmentChargeRealization",
        "type": "text",
        "label": "Debit Due Installment & Charge Realization A/C no.",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "schemeMaintenance",
        "conditionalVarValue": true
    },

    {
        "varName": "signatoryUpdate",
        "type": "checkbox",
        "label": "Signatory Update",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "signatoryUpdate",
        // "conditionalVarValue": true

    },
    {
        "varName": "existingMandate",
        "type": "text",
        "label": "Existing Mandate",
        "readOnly": true,
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "signatoryUpdate",
        "conditionalVarValue": true
    },
    {
        "varName": "newMandate",
        "type": "text",
        "label": "New Mandate",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "signatoryUpdate",
        "conditionalVarValue": true
    },
    {
        "varName": "cityLive",
        "type": "checkbox",
        "label": "City Live",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "cityLive",
        // "conditionalVarValue": true

    },

    {
        "varName": "projectRelatedDataUpdateADUP",
        "type": "checkbox",
        "label": "Project Related Data Update ADUP, CIB",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "projectRelatedDataUpdateADUP",
        // "conditionalVarValue": true

    },

    {
        "varName": "ctrLimitUpdateUnconfirmedAddressChange",
        "type": "checkbox",
        "label": "CTR Limit Update/Unconfirmed Address Change",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "ctrLimitUpdateUnconfirmedAddressChange",
        // "conditionalVarValue": true
    },
    {
        "varName": "accountSchemeClose",
        "type": "checkbox",
        "label": "Account & Scheme Close",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "accountSchemeClose",
        // "conditionalVarValue": true
    },


    {
        "varName": "accountBalance",
        "type": "text",
        "label": "Account Balance",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "faceValueForScheme",
        "type": "text",
        "label": "Face Value for scheme",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },

    {
        "varName": "maturityValueForScheme",
        "type": "text",
        "label": "Maturity Value for scheme",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "maturityDateForScheme",
        "type": "date",
        "label": "Maturity Date for scheme",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "numberOfInstallmentPendingScheme",
        "type": "text",
        "label": "Number of Installment pending/realized for scheme",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "lien",
        "type": "text",
        "label": "Lien",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "dormancy",
        "type": "text",
        "label": "Dormancy",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "freeze",
        "type": "text",
        "label": "Freeze",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "aDUPNormalCustomer",
        "type": "text",
        "label": "ADUP/Normal Customer",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "proxyTransaction",
        "type": "text",
        "label": "Proxy Transaction",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "eTinStatus",
        "type": "text",
        "label": "E Tin Status",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "sourceTaxCalculation",
        "type": "text",
        "label": "Source Tax Calculation",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "eDCalculation",
        "type": "text",
        "label": "ED Calculation",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "preMatureInterestCalculation",
        "type": "text",
        "label": "Pre mature interest calculation",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },
    {
        "varName": "debitAccountNumber",
        "type": "text",
        "label": "Debit Account Number",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "accountSchemeClose",
        "conditionalVarValue": true
    },

    {
        "varName": "lockerSIOpen",
        "type": "checkbox",
        "label": "Locker SI Open",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "lockerSIOpen",
        // "conditionalVarValue": true

    },

    {
        "varName": "lockerSIClose",
        "type": "checkbox",
        "label": "Locker SI Close",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "lockerSIClose",
        // "conditionalVarValue": true

    },
    {
        "varName": "titleRectification",
        "type": "checkbox",
        "label": "Title Rectification",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "titleRectification",
        // "conditionalVarValue": true

    },

    {
        "varName": "newTitle",
        "type": "text",
        "label": "New Title",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "titleRectification",
        "conditionalVarValue": true
    },
    {
        "varName": "currencyAdd",
        "type": "checkbox",
        "label": "Currency Add",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "currencyAdd",
        // "conditionalVarValue": true

    },
    {
        "varName": "existingCurrency",
        "type": "text",
        "label": "Existing Currency",
        "grid": 3,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true
    },
    {
        "varName": "newCurrency",
        "type": "autoCompleteValueReturn",
        "label": "New Currency",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "currencyAdd",
        "conditionalVarValue": true
    },
    {
        "varName": "tradeFacilitation",
        "type": "checkbox",
        "label": "Trade Facilitation",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "tradeFacilitation",
        // "conditionalVarValue": true

    },
    {
        "varName": "staffAccountGenerationGeneralaizationBlock",
        "type": "checkbox",
        "label": "Staff Account Generation/Generalaization/Block",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "staffAccountGenerationGeneralaizationBlock",
        // "conditionalVarValue": true

    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "staffAccountGenerationGeneralaizationBlock",
        "conditionalVarValue": true
    },
    {
        "varName": "cTRLimitUpdate",
        "type": "checkbox",
        "label": "CTR Limit Update",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "cTRLimitUpdate",
        // "conditionalVarValue": true

    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "cTRLimitUpdate",
        "conditionalVarValue": true
    },
    {
        "varName": "temporaryDormantActivation",
        "type": "checkbox",
        "label": "Temporary Dormant Activation",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "temporaryDormantActivation",
        // "conditionalVarValue": true

    },
    {
        "varName": "freezeUnfreezeMark",
        "type": "checkbox",
        "label": "Freeze/Unfreeze Mark",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "freezeUnfreezeMark",
        // "conditionalVarValue": true

    },
    {
        "varName": "citytouchTagging",
        "type": "checkbox",
        "label": "CityTouch Tagging",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "citytouchTagging",
        // "conditionalVarValue": true

    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "CB Number",
        "grid": 3,
        "errorMessage": "Error",
        "min": 9,
        "max": 9,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "cityTouchEmailId",
        "type": "text",
        "label": "Email",
        "grid": 3,
        "validation": "email",
        "errorMessage": "Error",
        "multiline": true,
        "email": true,
        // "required": true,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "mobileAccounts",
        "type": "text",
        "label": "Mobile of the Accounts",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "citytouchTagging",
        "conditionalVarValue": true
    },
    {
        "varName": "priorityMarking",
        "type": "checkbox",
        "label": "Priority Marking",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "priorityMarking",
        // "conditionalVarValue": true

    },
    {
        "varName": "qualifyingCode",
        "type": "text",
        "label": "Qualifying Code",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "priorityMarking",
        "conditionalVarValue": true
    },
    {
        "varName": "unconfirmedAddressMarking",
        "type": "checkbox",
        "label": "Unconfirmed Address Marking",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "unconfirmedAddressMarking",
        // "conditionalVarValue": true

    },
    {
        "varName": "deceasedMarking",
        "type": "checkbox",
        "label": "Deceased Marking",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "deceasedMarking",
        // "conditionalVarValue": true

    },
    {
        "varName": "wronglyGeneratedACClosing",
        "type": "checkbox",
        "label": "Wrongly Generated A/C Closing",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "wronglyGeneratedACClosing",
        // "conditionalVarValue": true

    },
    {
        "varName": "forceClosing",
        "type": "checkbox",
        "label": "Force Closing",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "forceClosing",
        // "conditionalVarValue": true

    },
    {
        "varName": "sBSCodeUpdateCorrection",
        "type": "checkbox",
        "label": "SBS Code Update/Correction",
        "grid": 12,
        // "conditional": true,
        // "conditionalVarName": "sBSCodeUpdateCorrection",
        // "conditionalVarValue": true

    },
    {
        "varName": "csBearer",
        "type": "select",
        "label": "Bearer",
        "enum": [
            "YES",
            "NO"
        ],
        "grid": 3
    },
    {
        "varName": "bearerApproval",
        "type": "select",
        "label": "Bearer Approval",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "csBearer",
        "conditionalVarValue": "YES",
        "enum": [
            "BM Approval",
            "Call Center Approval",
            "BM & Call Center Approval"

        ]
    }
];
//FDR Maker

let MAKERJsonFormForFDTenorSchemeChange = [
    {
        "varName": "solId",
        "type": "text",
        "label": "SOL ID",
        "grid": "6"
    },
    {
        "varName": "customerId",
        "type": "text",
        "label": "Customer ID",
        "grid": "6"
    },
    {
        "varName": "schemeCode",
        "type": "text",
        "label": "Scheme Code",
        "grid": "6"
    },
    {
        "varName": "accountOpenDate",
        "type": "date",
        "maxDate": true,
        "label": "A/C Open Date",
        "grid": "6"
    },
    {
        "varName": "accountPrefInt",
        "type": "text",
        "label": "A/C Pref.INT(CR)",
        "grid": "6"
    },
    {
        "varName": "interestCreditAccountId",
        "type": "text",
        "label": "Interest Credit A/C ID",
        "grid": "6"
    },
    {
        "varName": "interestCreditAccountId",
        "type": "text",
        "label": "Interest Credit A/C ID",
        "grid": "6"
    },
    {
        "varName": "withHoldingTaxLevel",
        "type": "text",
        "label": "With Holding Tax Level",
        "grid": "6"
    },

    {
        "varName": "withHoldingTaxPercentage",
        "type": "text",
        "label": "With Holding Tax%",
        "grid": "6"
    },
    {
        "varName": "depositInstallAmount",
        "type": "text",
        "label": "Deposit/Install Amount",
        "grid": "6"
    },
    {
        "varName": "depositPeriod",
        "type": "text",
        "label": "Deposit Period",
        "grid": "6"
    },
    {
        "varName": "valueDate",
        "type": "date",
        "label": "Value date",
        "grid": "6"
    },
    {
        "varName": "rePaymentAccountId",
        "type": "text",
        "label": "RePayment A/C ID",
        "grid": "6"
    },
    {
        "varName": "autoClosure",
        "type": "select",
        "label": "Auto. Closure?",
        "enum": [
            "YES",
            "NO"
        ],
        "grid": "6"
    },
    {
        "varName": "autoRenewal",
        "type": "select",
        "label": "Auto. Renewal?",
        "enum": [
            "YES",
            "NO"
        ],
        "grid": "6"
    },
    {
        "varName": "nomineeName",
        "type": "text",
        "multiline": true,
        "label": "Nominee's Name",
        "grid": "6"
    },
    {
        "varName": "nomineeRelationship",
        "type": "text",
        "label": "Relationship",
        "grid": "6"
    },
    {
        "varName": "nomineeRegistrationNo",
        "type": "text",
        "label": "REG. No",
        "grid": "6"
    },
    {
        "varName": "nomineeAddress",
        "type": "text",
        "multiline": true,
        "label": "Address",
        "grid": "6"
    },
    {
        "varName": "nomineeCityCode",
        "type": "text",
        "label": "City Code",
        "grid": "6"
    },
    {
        "varName": "nomineeStateCode",
        "type": "text",
        "label": "State Code",
        "grid": "6"
    },
    {
        "varName": "nomineeCountryCode",
        "type": "text",
        "label": "Country Code",
        "grid": "6"
    },
    {
        "varName": "nomineePostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": "6"
    },
    {
        "varName": "nomineeDateOfBirth",
        "type": "date",
        "maxDate": true,
        "label": "Date Of Birth",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianName",
        "type": "text",
        "multiline": true,
        "label": "Guardian Name",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianCode",
        "type": "text",
        "label": "Guardian Code",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianAddress",
        "type": "text",
        "multiline": true,
        "label": "Guardian Address",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianCityCode",
        "type": "text",
        "label": "City Code",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianStateCode",
        "type": "text",
        "label": "State Code",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianPostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianSectorCode",
        "type": "text",
        "label": "Sector Code",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianSubSectorCode",
        "type": "text",
        "label": "Sub Sector Code",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianOccupationCode",
        "type": "text",
        "label": "Occupation Code",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianFreeCode3",
        "type": "text",
        "label": "Free Code 3",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianFreeCode6",
        "type": "text",
        "label": "Free Code 6",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianFreeCode7",
        "type": "text",
        "label": "Free Code 7",
        "grid": "6"
    },
    {
        "varName": "tranCreationDuring",
        "type": "text",
        "label": "Tran Creation During(O/V)",
        "grid": "6"
    },
    {
        "varName": "fundingAccount",
        "type": "text",
        "label": "Funding A/C",
        "grid": "6"
    },
    {
        "varName": "typeOfTransaction",
        "type": "text",
        "label": "Type Of Transaction",
        "grid": "6"
    },
    {
        "varName": "accountNo",
        "type": "text",
        "label": "A/C No",
        "grid": "6"
    },
    {
        "varName": "name",
        "type": "text",
        "multiline": true,
        "label": "Name",
        "grid": "6"
    },
    {
        "varName": "nomineeAge",
        "type": "text",
        "label": "Nominee Age",
        "grid": "6"
    },
    {
        "varName": "nomineeSharePercentage",
        "type": "text",
        "label": "Nominee Share(%)",
        "grid": "6"
    },
    {
        "varName": "nomineeRelation",
        "type": "text",
        "label": "Nominee Relation",
        "grid": "6"
    },
    {
        "varName": "nomineeAddress",
        "type": "text",
        "multiline": true,
        "label": "Nominee Address",
        "grid": "6"
    },
    {
        "varName": "nomineeCity",
        "type": "text",
        "label": "Nominee City",
        "grid": "6"
    },
    {
        "varName": "nomineeState",
        "type": "text",
        "label": "Nominee State",
        "grid": "6"
    },
    {
        "varName": "nomineeAccountId",
        "type": "text",
        "label": "Nominee A/C ID",
        "grid": "6"
    },
    {
        "varName": "nomineeRelationType",
        "type": "text",
        "label": "Relation Type",
        "grid": "6"
    },
    {
        "varName": "nomineeRelationCode",
        "type": "text",
        "label": "Relation Code",
        "grid": "6"
    },
    {
        "varName": "nomineeDesignationCode",
        "type": "text",
        "label": "Designation Code",
        "grid": "6"
    },
    {
        "varName": "custId",
        "type": "text",
        "label": "Cust. ID",
        "grid": "6"
    },

    {
        "varName": "accountBillDisb",
        "type": "text",
        "label": "A/C./BILL/DISB",
        "grid": "6"
    },
    {
        "varName": "accountBillDisbId",
        "type": "text",
        "label": "A/C./BILL/DISB ID",
        "grid": "6"
    },
    {
        "varName": "adjustmentAMT1stField",
        "type": "text",
        "label": "Adjustment AMT(1st Field)",
        "grid": "6"
    },
    {
        "varName": "dr/cr2ndField",
        "type": "text",
        "label": "DR/CR(2nd Field)",
        "grid": "6"
    },
    {
        "varName": "runInd3rdField",
        "type": "text",
        "label": "RUN IND(3rd Field)",
        "grid": "6"
    },
    {
        "varName": "remarks4thField",
        "type": "text",
        "label": "Remarks(4th Field)",
        "grid": "6"
    },


    {
        "varName": "del5thField",
        "type": "text",
        "label": "Del(5th Field)",
        "grid": "6"
    },
    {
        "varName": "principalOutflow/Total",
        "type": "text",
        "label": "Principal Outflow/Total",
        "grid": "6"
    },
    {
        "varName": "1stFieldOfFirstLine",
        "type": "text",
        "label": "1st Field Of First Line",
        "grid": "6"
    },
    {
        "varName": "2ndFieldOfFirstLine",
        "type": "text",
        "label": "2nd Field Of First Line",
        "grid": "6"
    },
    {
        "varName": "3rdFieldOfFirstLine",
        "type": "text",
        "label": "3rd Field Of First Line",
        "grid": "6"
    },
    {
        "varName": "withHoldingTaxPercentage",
        "type": "text",
        "label": "WithHolding Tax%",
        "grid": "6"
    },
    {
        "varName": "fdAccountId",
        "type": "text",
        "label": "FD A/C ID",
        "grid": "6"
    },
    {
        "varName": "valueDateOfClosure",
        "type": "date",
        "label": "Value Date Of Closure",
        "grid": "6"
    },
    {
        "varName": "useRepaymentAccountOnly",
        "type": "text",
        "label": "Use Repayment A/C Only",
        "grid": "6"
    },
    {
        "varName": "rePaymentAccountId",
        "type": "text",
        "label": "Repayment A/C ID",
        "grid": "6"
    },
    {
        "varName": "closureRemarks",
        "type": "text",
        "label": "Closure Remarks",
        "grid": "6"
    },
    {
        "varName": "intRate",
        "type": "text",
        "label": "INT. Rate",
        "grid": "6"
    },


];
let MAKERJsonFormForFDNomineeUpdate = [
    {
        "varName": "nomineeName",
        "type": "text",
        "multiline": true,
        "label": "Nominee's Name",
        "grid": "6"
    },
    {
        "varName": "nomineRelationship",
        "type": "text",
        "label": "Relationship",
        "grid": "6"
    },
    {
        "varName": "nomineRegistrationNo",
        "type": "text",
        "label": "REG. No",
        "grid": "6"
    },
    {
        "varName": "nomineAddress",
        "type": "text",
        "multiline": true,
        "label": "Address",
        "grid": "6"
    },
    {
        "varName": "nomineCityCode",
        "type": "text",
        "label": "City Code",
        "grid": "6"
    },
    {
        "varName": "nomineStateCode",
        "type": "text",
        "label": "State Code",
        "grid": "6"
    },
    {
        "varName": "nomineCountryCode",
        "type": "text",
        "label": "Country Code",
        "grid": "6"
    },
    {
        "varName": "nominePostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": "6"
    },
    {
        "varName": "nomineDateOfBirth",
        "type": "date",
        "maxDate": true,
        "label": "Date Of Birth",
        "grid": "6"
    },
    {
        "varName": "nominGguardianName",
        "type": "text",
        "multiline": true,
        "label": "Guardian Name",
        "grid": "6"
    },
    {
        "varName": "nomineGuardianCode",
        "type": "text",
        "label": "Guardian Code",
        "grid": "6"
    },
    {
        "varName": "nomineGuardianAddress",
        "type": "text",
        "multiline": true,
        "label": "Guardian Address",
        "grid": "6"
    },
    {
        "varName": "nomineGuardianCityCode",
        "type": "text",
        "label": "City Code",
        "grid": "6"
    },
    {
        "varName": "nomineGuardianStateCode",
        "type": "text",
        "label": "State Code",
        "grid": "6"
    },
    {
        "varName": "nomineGuardianPostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": "6"
    },
    {
        "varName": "accountNo",
        "type": "text",
        "label": "A/C No",
        "grid": "6"
    },

    {
        "varName": "name",
        "type": "text",
        "multiline": true,
        "label": "Name",
        "grid": "6"
    },
    {
        "varName": "nomineeAge",
        "type": "text",
        "label": "Nominee Age",
        "grid": "6"
    },
    {
        "varName": "nomineeSharePercentage",
        "type": "text",
        "label": "Nominee Share(%)",
        "grid": "6"
    },
    {
        "varName": "nomineeRelation",
        "type": "text",
        "label": "Nominee Relation",
        "grid": "6"
    },
    {
        "varName": "nomineeAddress",
        "type": "text",
        "multiline": true,
        "label": "Nominee Address",
        "grid": "6"
    },
    {
        "varName": "nomineeCity",
        "type": "text",
        "label": "Nominee City",
        "grid": "6"
    },
    {
        "varName": "nomineeState",
        "type": "text",
        "label": "Nominee State",
        "grid": "6"
    },

];
let MAKERJsonFormForFDREncashmentCertificate = [
    {
        "varName": "adjustmentAMT1stField",
        "type": "text",
        "label": "Adjustment AMT(1st Field)",
        "grid": "6"
    },
    {
        "varName": "dr/cr2ndField",
        "type": "text",
        "label": "DR/CR(2nd Field)",
        "grid": "6"
    },
    {
        "varName": "runInd3rdField",
        "type": "text",
        "label": "RUN IND(3rd Field)",
        "grid": "6"
    },
    {
        "varName": "remarks4thField",
        "type": "text",
        "label": "Remarks(4th Field)",
        "grid": "6"
    },
    {
        "varName": "principalOutflow/Total",
        "type": "text",
        "label": "Principal Outflow/Total",
        "grid": "6"
    },
    {
        "varName": "1stFieldOfFirstLine",
        "type": "text",
        "label": "1st Field Of First Line",
        "grid": "6"
    },
    {
        "varName": "2ndFieldOfFirstLine",
        "type": "text",
        "label": "2nd Field Of First Line",
        "grid": "6"
    },
    {
        "varName": "3rdFieldOfFirstLine",
        "type": "text",
        "label": "3rd Field Of First Line",
        "grid": "6"
    },
    {
        "varName": "withHoldingTaxPercentage",
        "type": "text",
        "label": "WithHolding Tax%",
        "grid": "6"
    },
    {
        "varName": "fdAccountId",
        "type": "text",
        "label": "FD A/C ID",
        "grid": "6"
    },
    {
        "varName": "valueDateOfClosure",
        "type": "date",
        "label": "Value Date Of Closure",
        "grid": "6"
    },
    {
        "varName": "useRepaymentAccountOnly",
        "type": "text",
        "label": "Use Repayment A/C Only",
        "grid": "6"
    },
    {
        "varName": "accountId",
        "type": "text",
        "label": "A/C ID",
        "grid": "6"
    },
    {
        "varName": "accountName",
        "type": "text",
        "label": "A/C Name",
        "grid": "6"
    },
    {
        "varName": "solName",
        "type": "text",
        "label": "SOL Name",
        "grid": "6"
    },
    {
        "varName": "valueDateInterestRun/LastRenewalDate",
        "type": "date",
        "label": "Value Date(Interest/Renewel)",
        "grid": "6"
    },
    {
        "varName": "curDep/InslalAmount",
        "type": "text",
        "label": "Cur Dep/Inslal(Amount)",
        "grid": "6"
    },
    {
        "varName": "depPeriodMths/Days",
        "type": "text",
        "label": "Dep Period(Mths/Days)",
        "grid": "6"
    },
    {
        "varName": "schemeCode",
        "type": "text",
        "label": "Scheme Code",
        "grid": "6"
    },
    {
        "varName": "int",
        "type": "text",
        "label": "INT.",
        "grid": "6"
    },
    {
        "varName": "tax",
        "type": "text",
        "label": "Tax",
        "grid": "6"
    },


];
let MAKERJsonFormForFDRLinkAccountChange = [
    {
        "varName": "interestCreditAccountId",
        "type": "text",
        "label": "Interest Credit A/C ID",
        "grid": "6"
    },
    {
        "varName": "rePaymentAccountId",
        "type": "text",
        "label": "RePayment A/C ID",
        "grid": "6"
    },
];
let MAKERJsonFormForFDRSBSCodeUpdate = [
    {
        "varName": "sectorCode",
        "type": "text",
        "label": "Sector Code",
        "grid": "6"
    },
    {
        "varName": "subSectorCode",
        "type": "text",
        "label": "Sub Sector Code",
        "grid": "6"
    },
];
let MAKERJsonFormForFDRRelationShipManager = [
    {
        "varName": "freeCode7",
        "type": "text",
        "label": "Free Code 7",
        "grid": "6"
    },
];
let MAKERJsonFormForFDSchemeChangeWithoutClosing = [
    /*{
        "varName":"function",
        "type":"text",
        "label":"Function",
        "grid":"6"
    },*/
    {
        "varName": "accountId",
        "type": "text",
        "label": "A/C ID",
        "grid": "6"
    },
    {
        "varName": "targetSchemeCode",
        "type": "text",
        "label": "Target Scheme Code",
        "grid": "6"
    },
    {
        "varName": "trialMode",
        "type": "text",
        "label": "Trial Mode",
        "grid": "6"
    },
];
let MAKERJsonFormForFDRCityTouchTagging = [
    {
        "varName": "accountId",
        "type": "text",
        "label": "A/C ID",
        "grid": "6"
    },
    {
        "varName": "customerId",
        "type": "text",
        "label": "Customer ID",
        "grid": "6"
    },
    {
        "varName": "accountNameModification",
        "type": "text",
        "label": "Account Name Modification",
        "grid": "6"
    },
];
let MAKERJsonFormForFDRFreezeUnfreezeMarkingMark = [
    {
        "varName": "accountId",
        "type": "text",
        "label": "A/C ID",
        "grid": "6"
    },
    {
        "varName": "customerId",
        "type": "text",
        "label": "Customer ID",
        "grid": "6"
    },
    {
        "varName": "freezeReasonCode",
        "type": "text",
        "label": "Freeze Reason Code",
        "grid": "6"
    },
    {
        "varName": "freezeCode",
        "type": "text",
        "label": "Freeze Code",
        "grid": "6"
    },
    {
        "varName": "freezeRemarks",
        "type": "text",
        "label": "Freeze Remarks",
        "grid": "6"
    },
];
let MAKERJsonFormForFDRSourceTaxReversal = [
    {
        "varName": "tranId",
        "type": "text",
        "label": "Tran ID",
        "grid": "6"
    },
    {
        "varName": "type/subType",
        "type": "text",
        "label": "Type/Sub Type",
        "grid": "6"
    },
    {
        "varName": "sourceTaxAccount",
        "type": "text",
        "label": "Source Tax A/C",
        "grid": "6"
    },
    {
        "varName": "customerInstructedAccountNumber",
        "type": "text",
        "label": "Customer Instructed A/C Number",
        "grid": "6"
    },
    {
        "varName": "amount",
        "type": "text",
        "label": "Amount",
        "grid": "6"
    },
    {
        "varName": "debitCredit",
        "type": "text",
        "label": "Debit/Credit",
        "grid": "6"
    },
    {
        "varName": "particularCode",
        "type": "text",
        "label": "Particular Code",
        "grid": "6"
    },
    {
        "varName": "enterTransactionsalParticulars",
        "type": "text",
        "label": "Enter Transactionsal Particulars",
        "grid": "6"
    },
];
//FDR Checker
let CHECKERJsonFormForFDTenorSchemeChange = [
    {
        "varName": "solId",
        "type": "text",
        "label": "SOL ID",
        "grid": "6"
    },
    {
        "varName": "customerId",
        "type": "text",
        "label": "Customer ID",
        "grid": "6"
    },
    {
        "varName": "schemeCode",
        "type": "text",
        "label": "Scheme Code",
        "grid": "6"
    },
    {
        "varName": "accountOpenDate",
        "type": "date",
        "maxDate": true,
        "label": "A/C Open Date",
        "grid": "6"
    },
    {
        "varName": "accountPrefInt",
        "type": "text",
        "label": "A/C Pref.INT(CR)",
        "grid": "6"
    },
    {
        "varName": "interestCreditAccountId",
        "type": "text",
        "label": "Interest Credit A/C ID",
        "grid": "6"
    },
    {
        "varName": "interestCreditAccountId",
        "type": "text",
        "label": "Interest Credit A/C ID",
        "grid": "6"
    },
    {
        "varName": "withHoldingTaxLevel",
        "type": "text",
        "label": "With Holding Tax Level",
        "grid": "6"
    },

    {
        "varName": "withHoldingTaxPercentage",
        "type": "text",
        "label": "With Holding Tax%",
        "grid": "6"
    },
    {
        "varName": "depositInstallAmount",
        "type": "text",
        "label": "Deposit/Install Amount",
        "grid": "6"
    },
    {
        "varName": "depositPeriod",
        "type": "text",
        "label": "Deposit Period",
        "grid": "6"
    },
    {
        "varName": "valueDate",
        "type": "date",
        "label": "Value date",
        "grid": "6"
    },
    {
        "varName": "rePaymentAccountId",
        "type": "text",
        "label": "RePayment A/C ID",
        "grid": "6"
    },
    {
        "varName": "autoClosure",
        "type": "select",
        "label": "Auto. Closure?",
        "enum": [
            "YES",
            "NO"
        ],
        "grid": "6"
    },
    {
        "varName": "autoRenewal",
        "type": "select",
        "label": "Auto. Renewal?",
        "enum": [
            "YES",
            "NO"
        ],
        "grid": "6"
    },
    {
        "varName": "nomineeName",
        "type": "text",
        "multiline": true,
        "label": "Nominee's Name",
        "grid": "6"
    },
    {
        "varName": "nomineeRelationship",
        "type": "text",
        "label": "Relationship",
        "grid": "6"
    },
    {
        "varName": "nomineeRegistrationNo",
        "type": "text",
        "label": "REG. No",
        "grid": "6"
    },
    {
        "varName": "nomineeAddress",
        "type": "text",
        "multiline": true,
        "label": "Address",
        "grid": "6"
    },
    {
        "varName": "nomineeCityCode",
        "type": "text",
        "label": "City Code",
        "grid": "6"
    },
    {
        "varName": "nomineeStateCode",
        "type": "text",
        "label": "State Code",
        "grid": "6"
    },
    {
        "varName": "nomineeCountryCode",
        "type": "text",
        "label": "Country Code",
        "grid": "6"
    },
    {
        "varName": "nomineePostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": "6"
    },
    {
        "varName": "nomineeDateOfBirth",
        "type": "date",
        "maxDate": true,
        "label": "Date Of Birth",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianName",
        "type": "text",
        "multiline": true,
        "label": "Guardian Name",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianCode",
        "type": "text",
        "label": "Guardian Code",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianAddress",
        "type": "text",
        "multiline": true,
        "label": "Guardian Address",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianCityCode",
        "type": "text",
        "label": "City Code",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianStateCode",
        "type": "text",
        "label": "State Code",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianPostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianSectorCode",
        "type": "text",
        "label": "Sector Code",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianSubSectorCode",
        "type": "text",
        "label": "Sub Sector Code",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianOccupationCode",
        "type": "text",
        "label": "Occupation Code",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianFreeCode3",
        "type": "text",
        "label": "Free Code 3",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianFreeCode6",
        "type": "text",
        "label": "Free Code 6",
        "grid": "6"
    },
    {
        "varName": "nomineeGuardianFreeCode7",
        "type": "text",
        "label": "Free Code 7",
        "grid": "6"
    },
    {
        "varName": "tranCreationDuring",
        "type": "text",
        "label": "Tran Creation During(O/V)",
        "grid": "6"
    },
    {
        "varName": "fundingAccount",
        "type": "text",
        "label": "Funding A/C",
        "grid": "6"
    },
    {
        "varName": "typeOfTransaction",
        "type": "text",
        "label": "Type Of Transaction",
        "grid": "6"
    },
    {
        "varName": "accountNo",
        "type": "text",
        "label": "A/C No",
        "grid": "6"
    },
    {
        "varName": "name",
        "type": "text",
        "multiline": true,
        "label": "Name",
        "grid": "6"
    },
    {
        "varName": "nomineeAge",
        "type": "text",
        "label": "Nominee Age",
        "grid": "6"
    },
    {
        "varName": "nomineeSharePercentage",
        "type": "text",
        "label": "Nominee Share(%)",
        "grid": "6"
    },
    {
        "varName": "nomineeRelation",
        "type": "text",
        "label": "Nominee Relation",
        "grid": "6"
    },
    {
        "varName": "nomineeAddress",
        "type": "text",
        "multiline": true,
        "label": "Nominee Address",
        "grid": "6"
    },
    {
        "varName": "nomineeCity",
        "type": "text",
        "label": "Nominee City",
        "grid": "6"
    },
    {
        "varName": "nomineeState",
        "type": "text",
        "label": "Nominee State",
        "grid": "6"
    },
    {
        "varName": "nomineeAccountId",
        "type": "text",
        "label": "Nominee A/C ID",
        "grid": "6"
    },
    {
        "varName": "nomineeRelationType",
        "type": "text",
        "label": "Relation Type",
        "grid": "6"
    },
    {
        "varName": "nomineeRelationCode",
        "type": "text",
        "label": "Relation Code",
        "grid": "6"
    },
    {
        "varName": "nomineeDesignationCode",
        "type": "text",
        "label": "Designation Code",
        "grid": "6"
    },
    {
        "varName": "custId",
        "type": "text",
        "label": "Cust. ID",
        "grid": "6"
    },

    {
        "varName": "accountBillDisb",
        "type": "text",
        "label": "A/C./BILL/DISB",
        "grid": "6"
    },
    {
        "varName": "accountBillDisbId",
        "type": "text",
        "label": "A/C./BILL/DISB ID",
        "grid": "6"
    },
    {
        "varName": "adjustmentAMT1stField",
        "type": "text",
        "label": "Adjustment AMT(1st Field)",
        "grid": "6"
    },
    {
        "varName": "dr/cr2ndField",
        "type": "text",
        "label": "DR/CR(2nd Field)",
        "grid": "6"
    },
    {
        "varName": "runInd3rdField",
        "type": "text",
        "label": "RUN IND(3rd Field)",
        "grid": "6"
    },
    {
        "varName": "remarks4thField",
        "type": "text",
        "label": "Remarks(4th Field)",
        "grid": "6"
    },


    {
        "varName": "del5thField",
        "type": "text",
        "label": "Del(5th Field)",
        "grid": "6"
    },
    {
        "varName": "principalOutflow/Total",
        "type": "text",
        "label": "Principal Outflow/Total",
        "grid": "6"
    },
    {
        "varName": "1stFieldOfFirstLine",
        "type": "text",
        "label": "1st Field Of First Line",
        "grid": "6"
    },
    {
        "varName": "2ndFieldOfFirstLine",
        "type": "text",
        "label": "2nd Field Of First Line",
        "grid": "6"
    },
    {
        "varName": "3rdFieldOfFirstLine",
        "type": "text",
        "label": "3rd Field Of First Line",
        "grid": "6"
    },
    {
        "varName": "withHoldingTaxPercentage",
        "type": "text",
        "label": "WithHolding Tax%",
        "grid": "6"
    },
    {
        "varName": "fdAccountId",
        "type": "text",
        "label": "FD A/C ID",
        "grid": "6"
    },
    {
        "varName": "valueDateOfClosure",
        "type": "date",
        "label": "Value Date Of Closure",
        "grid": "6"
    },
    {
        "varName": "useRepaymentAccountOnly",
        "type": "text",
        "label": "Use Repayment A/C Only",
        "grid": "6"
    },
    {
        "varName": "rePaymentAccountId",
        "type": "text",
        "label": "Repayment A/C ID",
        "grid": "6"
    },
    {
        "varName": "closureRemarks",
        "type": "text",
        "label": "Closure Remarks",
        "grid": "6"
    },
    {
        "varName": "intRate",
        "type": "text",
        "label": "INT. Rate",
        "grid": "6"
    },


];
let CHECKERJsonFormForFDNomineeUpdate = [
    {
        "varName": "nomineeName",
        "type": "text",
        "multiline": true,
        "label": "Nominee's Name",
        "grid": "6"
    },
    {
        "varName": "nomineRelationship",
        "type": "text",
        "label": "Relationship",
        "grid": "6"
    },
    {
        "varName": "nomineRegistrationNo",
        "type": "text",
        "label": "REG. No",
        "grid": "6"
    },
    {
        "varName": "nomineAddress",
        "type": "text",
        "multiline": true,
        "label": "Address",
        "grid": "6"
    },
    {
        "varName": "nomineCityCode",
        "type": "text",
        "label": "City Code",
        "grid": "6"
    },
    {
        "varName": "nomineStateCode",
        "type": "text",
        "label": "State Code",
        "grid": "6"
    },
    {
        "varName": "nomineCountryCode",
        "type": "text",
        "label": "Country Code",
        "grid": "6"
    },
    {
        "varName": "nominePostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": "6"
    },
    {
        "varName": "nomineDateOfBirth",
        "type": "date",
        "maxDate": true,
        "label": "Date Of Birth",
        "grid": "6"
    },
    {
        "varName": "nominGguardianName",
        "type": "text",
        "multiline": true,
        "label": "Guardian Name",
        "grid": "6"
    },
    {
        "varName": "nomineGuardianCode",
        "type": "text",
        "label": "Guardian Code",
        "grid": "6"
    },
    {
        "varName": "nomineGuardianAddress",
        "type": "text",
        "multiline": true,
        "label": "Guardian Address",
        "grid": "6"
    },
    {
        "varName": "nomineGuardianCityCode",
        "type": "text",
        "label": "City Code",
        "grid": "6"
    },
    {
        "varName": "nomineGuardianStateCode",
        "type": "text",
        "label": "State Code",
        "grid": "6"
    },
    {
        "varName": "nomineGuardianPostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": "6"
    },
    {
        "varName": "accountNo",
        "type": "text",
        "label": "A/C No",
        "grid": "6"
    },

    {
        "varName": "name",
        "type": "text",
        "multiline": true,
        "label": "Name",
        "grid": "6"
    },
    {
        "varName": "nomineeAge",
        "type": "text",
        "label": "Nominee Age",
        "grid": "6"
    },
    {
        "varName": "nomineeSharePercentage",
        "type": "text",
        "label": "Nominee Share(%)",
        "grid": "6"
    },
    {
        "varName": "nomineeRelation",
        "type": "text",
        "label": "Nominee Relation",
        "grid": "6"
    },
    {
        "varName": "nomineeAddress",
        "type": "text",
        "multiline": true,
        "label": "Nominee Address",
        "grid": "6"
    },
    {
        "varName": "nomineeCity",
        "type": "text",
        "label": "Nominee City",
        "grid": "6"
    },
    {
        "varName": "nomineeState",
        "type": "text",
        "label": "Nominee State",
        "grid": "6"
    },

];
let CHECKERJsonFormForFDREncashmentCertificate = [
    {
        "varName": "adjustmentAMT1stField",
        "type": "text",
        "label": "Adjustment AMT(1st Field)",
        "grid": "6"
    },
    {
        "varName": "dr/cr2ndField",
        "type": "text",
        "label": "DR/CR(2nd Field)",
        "grid": "6"
    },
    {
        "varName": "runInd3rdField",
        "type": "text",
        "label": "RUN IND(3rd Field)",
        "grid": "6"
    },
    {
        "varName": "remarks4thField",
        "type": "text",
        "label": "Remarks(4th Field)",
        "grid": "6"
    },
    {
        "varName": "principalOutflow/Total",
        "type": "text",
        "label": "Principal Outflow/Total",
        "grid": "6"
    },
    {
        "varName": "1stFieldOfFirstLine",
        "type": "text",
        "label": "1st Field Of First Line",
        "grid": "6"
    },
    {
        "varName": "2ndFieldOfFirstLine",
        "type": "text",
        "label": "2nd Field Of First Line",
        "grid": "6"
    },
    {
        "varName": "3rdFieldOfFirstLine",
        "type": "text",
        "label": "3rd Field Of First Line",
        "grid": "6"
    },
    {
        "varName": "withHoldingTaxPercentage",
        "type": "text",
        "label": "WithHolding Tax%",
        "grid": "6"
    },
    {
        "varName": "fdAccountId",
        "type": "text",
        "label": "FD A/C ID",
        "grid": "6"
    },
    {
        "varName": "valueDateOfClosure",
        "type": "date",
        "label": "Value Date Of Closure",
        "grid": "6"
    },
    {
        "varName": "useRepaymentAccountOnly",
        "type": "text",
        "label": "Use Repayment A/C Only",
        "grid": "6"
    },
    {
        "varName": "accountId",
        "type": "text",
        "label": "A/C ID",
        "grid": "6"
    },
    {
        "varName": "accountName",
        "type": "text",
        "label": "A/C Name",
        "grid": "6"
    },
    {
        "varName": "solName",
        "type": "text",
        "label": "SOL Name",
        "grid": "6"
    },
    {
        "varName": "valueDateInterestRun/LastRenewalDate",
        "type": "date",
        "label": "Value Date(Interest/Renewel)",
        "grid": "6"
    },
    {
        "varName": "curDep/InslalAmount",
        "type": "text",
        "label": "Cur Dep/Inslal(Amount)",
        "grid": "6"
    },
    {
        "varName": "depPeriodMths/Days",
        "type": "text",
        "label": "Dep Period(Mths/Days)",
        "grid": "6"
    },
    {
        "varName": "schemeCode",
        "type": "text",
        "label": "Scheme Code",
        "grid": "6"
    },
    {
        "varName": "int",
        "type": "text",
        "label": "INT.",
        "grid": "6"
    },
    {
        "varName": "tax",
        "type": "text",
        "label": "Tax",
        "grid": "6"
    },


];
let CHECKERJsonFormForFDRLinkAccountChange = [
    {
        "varName": "interestCreditAccountId",
        "type": "text",
        "label": "Interest Credit A/C ID",
        "grid": "6"
    },
    {
        "varName": "rePaymentAccountId",
        "type": "text",
        "label": "RePayment A/C ID",
        "grid": "6"
    },
];
let CHECKERJsonFormForFDRSBSCodeUpdate = [
    {
        "varName": "sectorCode",
        "type": "text",
        "label": "Sector Code",
        "grid": "6"
    },
    {
        "varName": "subSectorCode",
        "type": "text",
        "label": "Sub Sector Code",
        "grid": "6"
    },
];
let CHECKERJsonFormForFDRRelationShipManager = [
    {
        "varName": "freeCode7",
        "type": "text",
        "label": "Free Code 7",
        "grid": "6"
    },
];
let CHECKERJsonFormForFDSchemeChangeWithoutClosing = [
    /*{
        "varName":"function",
        "type":"text",
        "label":"Function",
        "grid":"6"
    },*/
    {
        "varName": "accountId",
        "type": "text",
        "label": "A/C ID",
        "grid": "6"
    },
    {
        "varName": "targetSchemeCode",
        "type": "text",
        "label": "Target Scheme Code",
        "grid": "6"
    },
    {
        "varName": "trialMode",
        "type": "text",
        "label": "Trial Mode",
        "grid": "6"
    },
];
let CHECKERJsonFormForFDRCityTouchTagging = [
    {
        "varName": "accountId",
        "type": "text",
        "label": "A/C ID",
        "grid": "6"
    },
    {
        "varName": "customerId",
        "type": "text",
        "label": "Customer ID",
        "grid": "6"
    },
    {
        "varName": "accountNameModification",
        "type": "text",
        "label": "Account Name Modification",
        "grid": "6"
    },
];
let CHECKERJsonFormForFDRFreezeUnfreezeMarkingMark = [
    {
        "varName": "accountId",
        "type": "text",
        "label": "A/C ID",
        "grid": "6"
    },
    {
        "varName": "customerId",
        "type": "text",
        "label": "Customer ID",
        "grid": "6"
    },
    {
        "varName": "freezeReasonCode",
        "type": "text",
        "label": "Freeze Reason Code",
        "grid": "6"
    },
    {
        "varName": "freezeCode",
        "type": "text",
        "label": "Freeze Code",
        "grid": "6"
    },
    {
        "varName": "freezeRemarks",
        "type": "text",
        "label": "Freeze Remarks",
        "grid": "6"
    },
];
let CHECKERJsonFormForFDRSourceTaxReversal = [
    {
        "varName": "tranId",
        "type": "text",
        "label": "Tran ID",
        "grid": "6"
    },
    {
        "varName": "type/subType",
        "type": "text",
        "label": "Type/Sub Type",
        "grid": "6"
    },
    {
        "varName": "sourceTaxAccount",
        "type": "text",
        "label": "Source Tax A/C",
        "grid": "6"
    },
    {
        "varName": "customerInstructedAccountNumber",
        "type": "text",
        "label": "Customer Instructed A/C Number",
        "grid": "6"
    },
    {
        "varName": "amount",
        "type": "text",
        "label": "Amount",
        "grid": "6"
    },
    {
        "varName": "debitCredit",
        "type": "text",
        "label": "Debit/Credit",
        "grid": "6"
    },
    {
        "varName": "particularCode",
        "type": "text",
        "label": "Particular Code",
        "grid": "6"
    },
    {
        "varName": "enterTransactionsalParticulars",
        "type": "text",
        "label": "Enter Transactionsal Particulars",
        "grid": "6"
    },
];

let closerRequestForm = [
    {
        "varName": "eqmNumber",
        "type": "text",
        "label": "EQM Number",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "accountName",
        "type": "text",
        "label": "Account Name",
        "readOnly": true,
        "grid": 3,
    }
];
let closerRequestFormBm = [
    {
        "varName": "closerRequestName",
        "type": "text",
        "label": "Input transfer Account Number",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "inputAccountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 3,
        "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "closerRequestName",
        "conditionalOrList": [
            'Own AC',
            'Third Party AC',
            'Payment to GL AC',
            'Payorder',
            'EFT Payment',
            'RTGS Payment'
        ],
    },
    {
        "varName": "inputAccountName",
        "type": "text",
        "label": "Account Name",
        "readOnly": true,
        "grid": 3,
        "conditionalOrValue": true,
        "conditionalVarName": "closerRequestName",
        "conditionalOrList": [
            'Own AC',
            'Third Party AC',
            'Payment to GL AC',
            'Payorder',
            'EFT Payment',
            'RTGS Payment'
        ],
    }
];
let closerRequestFormBmForDpsCertificate = [
    {
        "varName": "closerRequestName",
        "type": "text",
        "label": "Input transfer Account Number",
        "grid": 3,
        "readOnly": true
    },
];
let closerRequestFormBomForDpsCertificate = [
    {
        "varName": "closerRequestName",
        "type": "text",
        "label": "Input transfer Account Number",
        "grid": 12,
        "readOnly": true
    },
];
let closerRequestFormBom = [
    {
        "varName": "closerRequestName",
        "type": "text",
        "label": "Input transfer Account Number",
        "grid": 12,
        "readOnly": true
    },
    {
        "varName": "inputAccountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6,
        "readOnly": true,
        "conditionalOrValue": true,
        "conditionalVarName": "closerRequestName",
        "conditionalOrList": [
            'Own AC',
            'Third Party AC',
            'Payment to GL AC',
            'Payorder',
            'EFT Payment',
            'RTGS Payment'
        ],
    },
    {
        "varName": "inputAccountName",
        "type": "text",
        "label": "Account Name",
        "readOnly": true,
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "closerRequestName",
        "conditionalOrList": [
            'Own AC',
            'Third Party AC',
            'Payment to GL AC',
            'Payorder',
            'EFT Payment',
            'RTGS Payment'
        ],
    }
];


let CloserRequestFormMaker = [
    {
        "varName": "closerRequestName",
        "type": "text",
        "label": "Input transfer Account Number",
        "grid": 6,
        "readOnly": true
    },
    // {
    //     "type": "title",
    //     "label": "HCSS",
    //     "grid": 12,
    // },
    // {
    //     "varName": "solId",
    //     "type": "text",
    //     "label": "Sol Id",
    //     "readOnly": true,
    //     "grid": 6,
    // },
    // {
    //     "type": "title",
    //     "label": "HACINT",
    //     "grid": 12,
    // },
    // {
    //     "varName": "reportTo",
    //     "type": "text",
    //     "label": "Report To",
    //     "grid": 6,
    // },
    // {
    //     "varName": "fromAcId",
    //     "type": "text",
    //     "label": "From A/c Id",
    //     "grid": 6,
    // },
    // {
    //     "varName": "toAcId",
    //     "type": "text",
    //     "label": "To A/c Id",
    //     "readOnly": true,
    //     "grid": 6,
    // },
    // {
    //     "varName": "fromDate",
    //     "type": "date",
    //     "label": "From Date",
    //     "grid": 6,
    // },
    // {
    //     "varName": "toDate",
    //     "type": "date",
    //     "label": "To Date",
    //     "grid": 6,
    // },
    // {
    //     "varName": "postTransaction",
    //     "type": "checkbox",
    //     "label": "Post Transaction",
    //     "grid": 6,
    // },
    // {
    //     "varName": "frequencyBasedInterestRun",
    //     "type": "checkbox",
    //     "label": "Frequency Based Interest Run",
    //     "grid": 6,
    // },
    // {
    //     "varName": "adHocMode",
    //     "type": "checkbox",
    //     "label": "Ad Hoc Mode",
    //     "grid": 6,
    // },
    // {
    //     "varName": "hacint",
    //     "type": "submit",
    //     "label": "Interest Run",
    //     "grid": 12,
    // },
    // {
    //     "type": "title",
    //     "label": "HALM",
    //     "grid": 12,
    // },
    // {
    //     "varName": "acIdOne",
    //     "type": "text",
    //     "label": "A/c Id",
    //     "radOnly": true,
    //     "grid": 6,
    // },
    // {
    //     "varName": "newLineAmt",
    //     "type": "text",
    //     "label": "New Line Amt",
    //     "grid": 6,
    // },
    {
        "type": "title",
        "label": "Transfer Limit",
        "grid": 12,
    },
    {
        "varName": "cashDebitLimitException",
        "type": "text",
        "label": "Cash Debit Limit Exception",
        "grid": 6,
    },
    {
        "varName": "clearingExceptionLimit",
        "type": "text",
        "label": "Clearing Exception Limit (Dr.)",
        "grid": 6,
    },
    {
        "varName": "transferExceptionLimit",
        "type": "text",
        "label": "Transfer Exception Limit (Dr.)",
        "grid": 6,
    },
    {
        "varName": "acStatement",
        "type": "select",
        "enum": [
            "3 month",
            "6 month"
        ],
        "label": "A/c. Statement",
        "grid": 6,
    },
    // {
    //     "varName": "hacm",
    //     "type": "submit",
    //     "label": "Transfer Limit",
    //     "grid": 12,
    // },
    // {
    //     "type": "title",
    //     "label": "SCHEME",
    //     "grid": 12,
    // },
    // {
    //     "varName": "acStatus",
    //     "type": "text",
    //     "label": "A/c. Status",
    //     "grid": 6,
    // },
    // {
    //     "varName": "dormantActivationCharge",
    //     "type": "text",
    //     "label": "Dormant Activation Charge",
    //     "grid": 6,
    // },
    // {
    //     "type": "title",
    //     "label": "SVS",
    //     "grid": 12,
    // },
    // {
    //     "varName": "acIdTwo",
    //     "type": "text",
    //     "label": "A/c Id",
    //     "grid": 6,
    // },
    // {
    //     "varName": "signatureAndPhotographDetails",
    //     "type": "checkbox",
    //     "label": "Signature And Photograph Details",
    //     "grid": 6,
    // },
    // {
    //     "varName": "signatureGroupNameRecord",
    //     "type": "checkbox",
    //     "label": "Signature Group Name Record",
    //     "grid": 6,
    // },
    {
        "type": "title",
        "label": "ED Transaction",
        "grid": 12,
    },
    // {
    //     "varName": "casaEdtAccountNumber",
    //     "type": "text",
    //     "label": "Account Number",
    //     "grid": 6,
    // },
    // {
    //     "varName": "CASAEDT",
    //     "type": "submit",
    //     "label": "ED",
    //     "grid": 12,
    // },
    {
        "type": "title",
        "label": "Closer Field",
        "grid": 12,
    },
    // {
    //     "varName": "hcaccAcId",
    //     "type": "text",
    //     "label": "A/c Id",
    //     "readOnly": true,
    //     "grid": 6,
    // },
    {
        "varName": "hcaccBalance",
        "type": "checkbox",
        "label": "Balance",
        "grid": 6,
    },
    {
        "type": "empty",
        "label": "",
        "grid": 6,
    },
    {
        "varName": "hcaccTransactionType",
        "type": "select",
        "enum": [
            "Transfer",
            "Close"
        ],
        "label": "Transaction Type",
        "required": true,
        "grid": 6,
    },
    {
        "varName": "hcaccTransferAcId",
        "type": "text",
        "label": "Transfer A/c. ID",
        "required": true,
        "validation": "numeric",
        "grid": 6,
    },
    // {
    //     "varName": "hcacc",
    //     "type": "submit",
    //     "label": "Closure Proceeds",
    //     "grid": 12,
    // },
    {
        "type": "title",
        "label": "Charge Collection",
        "grid": 12,
    },
    {
        "varName": "hccfmCifId",
        "type": "text",
        "label": "CIF ID ",
        "grid": 6,
    },
    {
        "varName": "hccfmPaymentOrderStpAmtCeiling",
        "type": "text",
        "label": "Payment Order STP Amt. Ceiling",
        "grid": 6,
    },
    {
        "varName": "hccfmAcLevelCharge",
        "type": "checkbox",
        "label": "A/C Level Charge",
        "grid": 6,
    },
    // {
    //     "varName": "hccfm",
    //     "type": "submit",
    //     "label": "Charge Collection",
    //     "grid": 12,
    // },
    // {
    //     "type": "title",
    //     "label": "HAFSM",
    //     "grid": 12,
    // },
    // {
    //     "varName": "acIdFour",
    //     "type": "text",
    //     "readOnly": true,
    //     "label": "A/c Id",
    //     "grid": 6,
    // },
    // {
    //     "varName": "freezeCode",
    //     "type": "select",
    //     "enum": [
    //         "111",
    //         "222"
    //     ],
    //     "label": "Freeze Code",
    //     "grid": 6,
    // },
    // {
    //     "varName": "freezeReasonCode1",
    //     "type": "select",
    //     "enum": [
    //         "111",
    //         "2222"
    //     ],
    //     "label": "Freeze Reason Code 1",
    //     "grid": 6,
    // },
    // {
    //     "varName": "freezeRemarks1",
    //     "type": "text",
    //     "multiline": true,
    //     "label": "Freeze Remarks1",
    //     "grid": 6,
    // },
    // {
    //     "type": "title",
    //     "label": "HSIM",
    //     "grid": 12,
    // },
    // {
    //     "varName": "siSrlNo",
    //     "type": "text",
    //     "multiline": true,
    //     "label": "SI Srl. No.",
    //     "grid": 6,
    // },
    // {
    //     "type": "title",
    //     "label": "HCACC",
    //     "grid": 12,
    // },
    // {
    //     "varName": "acIdFive",
    //     "type": "text",
    //     "label": "A/c Id",
    //     "readOnly": true,
    //     "grid": 6,
    // },
    // {
    //     "varName": "balance",
    //     "type": "checkbox",
    //     "label": "Balance",
    //     "grid": 6,
    // },
    // {
    //     "varName": "transactionType",
    //     "type": "select",
    //     "enum": [
    //         "COD",
    //         "Online"
    //     ],
    //     "label": "Transaction Type",
    //     "grid": 6,
    // },
    // {
    //     "varName": "transferAcId",
    //     "type": "text",
    //     "label": "Transfer A/c. ID",
    //     "grid": 6,
    // },
    // {
    //     "type": "title",
    //     "label": "HTM",
    //     "grid": 12,
    // },
    // {
    //     "varName": "transactionSubtype",
    //     "type": "select",
    //     "enum": [
    //         "A",
    //         "B"
    //     ],
    //     "label": "Transaction Subtype",
    //     "grid": 6,
    // },
    // {
    //     "varName": "partTransactionSubtype",
    //     "type": "select",
    //     "enum": [
    //         "A",
    //         "B"
    //     ],
    //     "label": "Part Transaction Type",
    //     "grid": 6,
    // },
    // {
    //     "varName": "acIdSix",
    //     "type": "text",
    //     "label": "A/c Id",
    //     "grid": 6,
    // },
    // {
    //     "varName": "refCCY",
    //     "type": "text",
    //     "label": "Ref. CCY/Amt",
    //     "grid": 6,
    // },
    // {
    //     "varName": "transactionParticularsCode",
    //     "type": "text",
    //     "label": "Transaction Particulars Code",
    //     "grid": 6,
    // },
    // {
    //     "varName": "transactionParticulars",
    //     "type": "text",
    //     "label": "Transaction Particulars",
    //     "grid": 6,
    // },
    // {
    //     "varName": "partTransactionSubtypeTwo",
    //     "type": "select",
    //     "enum": [
    //         "A",
    //         "B"
    //     ],
    //     "label": "Part Transaction Type",
    //     "grid": 6,
    // },
    // {
    //     "varName": "acIdSeven",
    //     "type": "text",
    //     "label": "A/c Id",
    //     "grid": 6,
    // },
    // {
    //     "varName": "refCCYTwo",
    //     "type": "text",
    //     "label": "Ref. CCY/Amt",
    //     "grid": 6,
    // },
    // {
    //     "varName": "transactionParticularsCodeTwo",
    //     "type": "text",
    //     "label": "Transaction Particulars Code",
    //     "grid": 6,
    // },
    // {
    //     "varName": "transactionParticularsTwo",
    //     "type": "text",
    //     "label": "Transaction Particulars",
    //     "grid": 6,
    // },
];


let CloserRequestFormChecker = [
    {
        "varName": "closerRequestName",
        "type": "text",
        "label": "Input transfer Account Number",
        "grid": 6,
        "readOnly": true
    },
    // {
    //     "type": "title",
    //     "label": "HCSS",
    //     "grid": 12,
    // },
    // {
    //     "varName": "solId",
    //     "type": "text",
    //     "label": "Sol Id",
    //     "readOnly": true,
    //     "grid": 6,
    // },
    // {
    //     "type": "title",
    //     "label": "HACINT",
    //     "grid": 12,
    // },
    // {
    //     "varName": "reportTo",
    //     "type": "text",
    //     "label": "Report To",
    //     "grid": 6,
    // },
    // {
    //     "varName": "fromAcId",
    //     "type": "text",
    //     "label": "From A/c Id",
    //     "grid": 6,
    // },
    // {
    //     "varName": "toAcId",
    //     "type": "text",
    //     "label": "To A/c Id",
    //     "readOnly": true,
    //     "grid": 6,
    // },
    // {
    //     "varName": "fromDate",
    //     "type": "date",
    //     "label": "From Date",
    //     "grid": 6,
    // },
    // {
    //     "varName": "toDate",
    //     "type": "date",
    //     "label": "To Date",
    //     "grid": 6,
    // },
    // {
    //     "varName": "postTransaction",
    //     "type": "checkbox",
    //     "label": "Post Transaction",
    //     "grid": 6,
    // },
    // {
    //     "varName": "frequencyBasedInterestRun",
    //     "type": "checkbox",
    //     "label": "Frequency Based Interest Run",
    //     "grid": 6,
    // },
    // {
    //     "varName": "adHocMode",
    //     "type": "checkbox",
    //     "label": "Ad Hoc Mode",
    //     "grid": 6,
    // },
    // {
    //     "varName": "hacint",
    //     "type": "submit",
    //     "label": "Interest Run",
    //     "grid": 12,
    // },
    // {
    //     "type": "title",
    //     "label": "HALM",
    //     "grid": 12,
    // },
    // {
    //     "varName": "acIdOne",
    //     "type": "text",
    //     "label": "A/c Id",
    //     "radOnly": true,
    //     "grid": 6,
    // },
    // {
    //     "varName": "newLineAmt",
    //     "type": "text",
    //     "label": "New Line Amt",
    //     "grid": 6,
    // },
    {
        "type": "title",
        "label": "Transfer Limit",
        "grid": 12,
    },
    {
        "varName": "cashDebitLimitException",
        "type": "text",
        "label": "Cash Debit Limit Exception",
        "grid": 6,
    },
    {
        "varName": "clearingExceptionLimit",
        "type": "text",
        "label": "Clearing Exception Limit (Dr.)",
        "grid": 6,
    },
    {
        "varName": "transferExceptionLimit",
        "type": "text",
        "label": "Transfer Exception Limit (Dr.)",
        "grid": 6,
    },
    {
        "varName": "acStatement",
        "type": "select",
        "enum": [
            "3 month",
            "6 month"
        ],
        "label": "A/c. Statement",
        "grid": 6,
    },
    {
        "varName": "hacm",
        "type": "submit",
        "label": "Transfer Limit",
        "grid": 12,
    },
    // {
    //     "type": "title",
    //     "label": "SCHEME",
    //     "grid": 12,
    // },
    // {
    //     "varName": "acStatus",
    //     "type": "text",
    //     "label": "A/c. Status",
    //     "grid": 6,
    // },
    // {
    //     "varName": "dormantActivationCharge",
    //     "type": "text",
    //     "label": "Dormant Activation Charge",
    //     "grid": 6,
    // },
    // {
    //     "type": "title",
    //     "label": "SVS",
    //     "grid": 12,
    // },
    // {
    //     "varName": "acIdTwo",
    //     "type": "text",
    //     "label": "A/c Id",
    //     "grid": 6,
    // },
    // {
    //     "varName": "signatureAndPhotographDetails",
    //     "type": "checkbox",
    //     "label": "Signature And Photograph Details",
    //     "grid": 6,
    // },
    // {
    //     "varName": "signatureGroupNameRecord",
    //     "type": "checkbox",
    //     "label": "Signature Group Name Record",
    //     "grid": 6,
    // },
    {
        "type": "title",
        "label": "ED Transaction",
        "grid": 12,
    },
    // {
    //     "varName": "casaEdtAccountNumber",
    //     "type": "text",
    //     "label": "Account Number",
    //     "grid": 6,
    // },
    {
        "varName": "CASAEDT",
        "type": "submit",
        "label": "ED",
        "grid": 12,
    },
    {
        "type": "title",
        "label": "Closer Field",
        "grid": 12,
    },
    // {
    //     "varName": "hcaccAcId",
    //     "type": "text",
    //     "label": "A/c Id",
    //     "readOnly": true,
    //     "grid": 6,
    // },
    {
        "varName": "hcaccBalance",
        "type": "checkbox",
        "label": "Balance",
        "grid": 6,
    },
    {
        "type": "empty",
        "label": "",
        "grid": 6,
    },
    {
        "varName": "hcaccTransactionType",
        "type": "select",
        "enum": [
            "Transfer",
            "Close"
        ],
        "label": "Transaction Type",
        "required": true,
        "grid": 6,
    },
    {
        "varName": "hcaccTransferAcId",
        "type": "text",
        "label": "Transfer A/c. ID",
        "required": true,
        "validation": "numeric",
        "grid": 6,
    },
    {
        "varName": "hcacc",
        "type": "submit",
        "label": "Closure Proceeds",
        "grid": 12,
    },
    {
        "type": "title",
        "label": "Charge Collection",
        "grid": 12,
    },
    {
        "varName": "hccfmCifId",
        "type": "text",
        "label": "CIF ID ",
        "grid": 6,
    },
    {
        "varName": "hccfmPaymentOrderStpAmtCeiling",
        "type": "text",
        "label": "Payment Order STP Amt. Ceiling",
        "grid": 6,
    },
    {
        "varName": "hccfmAcLevelCharge",
        "type": "checkbox",
        "label": "A/C Level Charge",
        "grid": 6,
    },
    {
        "varName": "hccfm",
        "type": "submit",
        "label": "Charge Collection",
        "grid": 12,
    },
    // {
    //     "type": "title",
    //     "label": "HAFSM",
    //     "grid": 12,
    // },
    // {
    //     "varName": "acIdFour",
    //     "type": "text",
    //     "readOnly": true,
    //     "label": "A/c Id",
    //     "grid": 6,
    // },
    // {
    //     "varName": "freezeCode",
    //     "type": "select",
    //     "enum": [
    //         "111",
    //         "222"
    //     ],
    //     "label": "Freeze Code",
    //     "grid": 6,
    // },
    // {
    //     "varName": "freezeReasonCode1",
    //     "type": "select",
    //     "enum": [
    //         "111",
    //         "2222"
    //     ],
    //     "label": "Freeze Reason Code 1",
    //     "grid": 6,
    // },
    // {
    //     "varName": "freezeRemarks1",
    //     "type": "text",
    //     "multiline": true,
    //     "label": "Freeze Remarks1",
    //     "grid": 6,
    // },
    // {
    //     "type": "title",
    //     "label": "HSIM",
    //     "grid": 12,
    // },
    // {
    //     "varName": "siSrlNo",
    //     "type": "text",
    //     "multiline": true,
    //     "label": "SI Srl. No.",
    //     "grid": 6,
    // },
    // {
    //     "type": "title",
    //     "label": "HCACC",
    //     "grid": 12,
    // },
    // {
    //     "varName": "acIdFive",
    //     "type": "text",
    //     "label": "A/c Id",
    //     "readOnly": true,
    //     "grid": 6,
    // },
    // {
    //     "varName": "balance",
    //     "type": "checkbox",
    //     "label": "Balance",
    //     "grid": 6,
    // },
    // {
    //     "varName": "transactionType",
    //     "type": "select",
    //     "enum": [
    //         "COD",
    //         "Online"
    //     ],
    //     "label": "Transaction Type",
    //     "grid": 6,
    // },
    // {
    //     "varName": "transferAcId",
    //     "type": "text",
    //     "label": "Transfer A/c. ID",
    //     "grid": 6,
    // },
    // {
    //     "type": "title",
    //     "label": "HTM",
    //     "grid": 12,
    // },
    // {
    //     "varName": "transactionSubtype",
    //     "type": "select",
    //     "enum": [
    //         "A",
    //         "B"
    //     ],
    //     "label": "Transaction Subtype",
    //     "grid": 6,
    // },
    // {
    //     "varName": "partTransactionSubtype",
    //     "type": "select",
    //     "enum": [
    //         "A",
    //         "B"
    //     ],
    //     "label": "Part Transaction Type",
    //     "grid": 6,
    // },
    // {
    //     "varName": "acIdSix",
    //     "type": "text",
    //     "label": "A/c Id",
    //     "grid": 6,
    // },
    // {
    //     "varName": "refCCY",
    //     "type": "text",
    //     "label": "Ref. CCY/Amt",
    //     "grid": 6,
    // },
    // {
    //     "varName": "transactionParticularsCode",
    //     "type": "text",
    //     "label": "Transaction Particulars Code",
    //     "grid": 6,
    // },
    // {
    //     "varName": "transactionParticulars",
    //     "type": "text",
    //     "label": "Transaction Particulars",
    //     "grid": 6,
    // },
    // {
    //     "varName": "partTransactionSubtypeTwo",
    //     "type": "select",
    //     "enum": [
    //         "A",
    //         "B"
    //     ],
    //     "label": "Part Transaction Type",
    //     "grid": 6,
    // },
    // {
    //     "varName": "acIdSeven",
    //     "type": "text",
    //     "label": "A/c Id",
    //     "grid": 6,
    // },
    // {
    //     "varName": "refCCYTwo",
    //     "type": "text",
    //     "label": "Ref. CCY/Amt",
    //     "grid": 6,
    // },
    // {
    //     "varName": "transactionParticularsCodeTwo",
    //     "type": "text",
    //     "label": "Transaction Particulars Code",
    //     "grid": 6,
    // },
    // {
    //     "varName": "transactionParticularsTwo",
    //     "type": "text",
    //     "label": "Transaction Particulars",
    //     "grid": 6,
    // },
];


let casaCloserRequestForChecker = {};
casaCloserRequestForChecker = makeReadOnlyObject(JSON.parse(JSON.stringify(CloserRequestFormChecker)));

let dpsCloserRequestFormMaker = [
    {
        "varName": "closerRequestName",
        "type": "text",
        "label": "Input transfer Account Number",
        "grid": 6,
        "readOnly": true
    },
    {
        "varName": "solId",
        "type": "text",
        "label": "Sol Id",
        "readOnly": true,
        "grid": 6,
    },
    {
        "varName": "acIdOne",
        "type": "text",
        "label": "A/c. ID",
        "readOnly": true,
        "grid": 6,
    },
    {
        "varName": "acStatement",
        "type": "select",
        "enum": [
            "A",
            "B"
        ],
        "label": "A/c. Statement",
        "grid": 6,
    },
    {
        "varName": "principalOutflow",
        "type": "checkbox",
        "label": "PRINCIPAL OUTFLOW/TOTAL OUTFLOW",
        "grid": 6,
    },
    {
        "varName": "acIdTwo",
        "type": "text",
        "label": "A/c. ID",
        "grid": 6,
    },
    {
        "varName": "amtType",
        "type": "select",
        "enum": [
            "A",
            "B"
        ],
        "label": "AMT. TYPE",
        "grid": 6,
    },
    {
        "varName": "amt",
        "type": "text",
        "label": "Amt.",
        "grid": 6,
    },
    {
        "varName": "particulars",
        "type": "text",
        "label": "Particulars",
        "grid": 6,
    },
    {
        "varName": "record",
        "type": "checkbox",
        "label": "Record",
        "grid": 6,
    },
    {
        "varName": "taxCategory",
        "type": "select",
        "enum": [
            "A",
            "B"
        ],
        "label": "Tax Category",
        "grid": 6,
    },
    {
        "varName": "withholdingTaxLevel",
        "type": "select",
        "enum": [
            "A",
            "B"
        ],
        "label": "Withholding Tax Level",
        "grid": 6,
    },
    {
        "varName": "withholdingTaxBorneBy",
        "type": "checkbox",
        "label": "Withholding Tax Borne By",
        "grid": 6,
    },
    {
        "varName": "withholdingTaxPcnt",
        "type": "checkbox",
        "label": "WITHHOLDING TAX Pcnt.",
        "grid": 6,
    },
    {
        "varName": "acIdThree",
        "type": "text",
        "label": "A/c. ID",
        "readOnly": true,
        "grid": 6,
    },
    {
        "varName": "freezeCode",
        "type": "select",
        "enum": [
            "1",
            "2"
        ],
        "label": "Freeze Code",
        "grid": 6,
    },
    {
        "varName": "freezeReasonCode1",
        "type": "select",
        "enum": [
            "1",
            "2"
        ],
        "label": "Freeze Reason Code 1",
        "grid": 6,
    },
    {
        "varName": "freezeRemarks1",
        "type": "text",
        "multiline": true,
        "label": "Freeze Remarks1",
        "grid": 6,
    },
    {
        "varName": "acIdFour",
        "type": "text",
        "label": "A/c. ID",
        "grid": 6,
    },
    {
        "varName": "principle",
        "type": "text",
        "label": "Principle(PI/NI/ID/TI)",
        "grid": 6,
    },
    {
        "varName": "acIdFive",
        "type": "text",
        "label": "A/c. ID",
        "grid": 6,
    },
    {
        "varName": "closerValueDate",
        "type": "date",
        "label": "closerValueDate",
        "grid": 6,
    },
    {
        "varName": "withdrawalAmt",
        "type": "text",
        "label": "Withdrawal Amt. (Principal)",
        "grid": 6,
    },
    {
        "varName": "closeMode",
        "type": "select",
        "enum": [
            "A",
            "B"
        ],
        "label": "Close Mode",
        "grid": 6,
    },
    {
        "varName": "repaymentAcId",
        "type": "text",
        "label": "REPAYMENT A/C ID",
        "grid": 6,
    }, {
        "varName": "doYouWantToCollectExciseDuty",
        "type": "text",
        "label": "DO YOU WANT TO COLLECT EXCISE DUTY ",
        "grid": 6,
    }, {
        "varName": "acBillDisbursement",
        "type": "select",
        "enum": [
            "A",
            "B"
        ],
        "label": "A/c./Bill/Disbursement",
        "grid": 6,
    }, {
        "varName": "acBillDisbursementId",
        "type": "text",
        "readOnly": true,
        "label": "A/c./Bill/Disbursement Id",
        "grid": 6,
    }, {
        "varName": "adjustedAmt",
        "type": "text",
        "label": "Adjusted Amt. (1ST FIELD OF 1ST RAW)",
        "grid": 6,
    }, {
        "varName": "debitorCreditInterest",
        "type": "select",
        "enum": [
            "Debit",
            "Credit"
        ],
        "label": "Debit/Credit Interest (2ND FIELD OF 1ST RAW)",
        "grid": 6,
    }, {
        "varName": "runIndicator",
        "type": "select",
        "enum": [
            "A",
            "B"
        ],
        "label": "Run Indicator (3RD FIELD OF 1ST RAW)",
        "grid": 6,
    }, {
        "varName": "remarks",
        "type": "text",
        "multiline": true,
        "label": "REMARKS (4TH FIELD OF 1ST RAW)",
        "grid": 6,
    }, {
        "varName": "del",
        "type": "select",
        "enum": [
            "Yes",
            "No"
        ],
        "label": "DEL (5TH FIELD OF 1ST RAW)",
        "grid": 6,
    },
    {
        "varName": "transactionType",
        "type": "select",
        "enum": [
            "Yes",
            "No"
        ],
        "label": "Transaction Type/SubType",
        "grid": 6,
    },
    {
        "varName": "partTransactionSubtype",
        "type": "select",
        "enum": [
            "Yes",
            "No"
        ],
        "label": "Part Transaction Type",
        "grid": 6,
    },
    {
        "varName": "acIdFive",
        "type": "text",
        "label": "A/c. ID",
        "grid": 6,
    },
    {
        "varName": "refCCY",
        "type": "text",
        "label": "Ref. CCY/Amt",
        "grid": 6,
    },
    {
        "varName": "transactionParticularsCode",
        "type": "text",
        "label": "Transaction Particulars Code",
        "grid": 6,
    },
    {
        "varName": "transactionParticulars",
        "type": "text",
        "label": "Transaction Particulars",
        "grid": 6,
    },

    {
        "varName": "acIdFive",
        "type": "text",
        "label": "A/c. ID",
        "grid": 6,
    },
    {
        "varName": "refCCYTwo",
        "type": "text",
        "label": "Ref. CCY/Amt",
        "grid": 6,
    },
    {
        "varName": "transactionParticularsCodeTwo",
        "type": "text",
        "label": "Transaction Particulars Code",
        "grid": 6,
    },
    {
        "varName": "transactionParticularsTwo",
        "type": "text",
        "label": "Transaction Particulars",
        "grid": 6,
    },


];

let dpsEncasmentCertificate = [
    {
        "varName": "empty",
        "type": "title",
        "label": "DPS Encashment Certificate",
        "grid": 12,
        "readOnly": true
    },
    {
        "varName": "encashmentCustomerName",
        "type": "text",
        "label": "Customer Name",
        "grid": 6,
    },
    {
        "varName": "encashmentAccountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6,
    },
    {
        "varName": "encashmentBranchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 6,
    },
    {
        "varName": "encashmentPrincipleAmount",
        "type": "text",
        "label": "Principle Amount",
        "grid": 6,
    },
    {
        "varName": "encashmentTdsPeriod",
        "type": "text",
        "label": "TDS Period",
        "grid": 6,
    },
    {
        "varName": "encashmentTotalInterest",
        "type": "text",
        "label": "Total Interest",
        "grid": 6,
    },
    {
        "varName": "encashmentSourceTax",
        "type": "text",
        "label": "Source Tax Deducted",
        "grid": 6,
    },
    {
        "varName": "encashmentNetInttAmount",
        "type": "text",
        "label": "Net Intt. Amount Credited",
        "grid": 6,
    },
    {
        "varName": "encashmentExciseDuty",
        "type": "text",
        "label": "Excise Duty",
        "grid": 6,
    },
    {
        "varName": "encashmentInsurancePremium",
        "type": "text",
        "label": "Insurance Premium",
        "grid": 6,
    },
    {
        "varName": "encashmentNetPayableAmount",
        "type": "text",
        "label": "Net Payable Amount",
        "grid": 6,
    },
];

let dpsEncashmentReadOnly = {};
dpsEncashmentReadOnly = makeReadOnlyObject(JSON.parse(JSON.stringify(dpsEncasmentCertificate)));

let dpsCloserRequestForChecker = {};
dpsCloserRequestForChecker = makeReadOnlyObject(JSON.parse(JSON.stringify(dpsCloserRequestFormMaker)));
module.exports = {
    encashmentSDPart,
    closerRequestFormBm,
    CloserRequestFormMaker,
    dpsCloserRequestFormMaker,
    casaCloserRequestForChecker,
    dpsCloserRequestForChecker,
    BMAccountMaintenance,
    BOMAccountMaintenance,
    CheckerAccountMaintenance,
    maintenanceListChecker,
    MAKERAccountMaintenance,
    CallCenterAccountMaintenance,
    maintenanceList,

    maintenanceListCS,

    BOMFdrEncashmentMaintenance,
    BMFdrEncashmentMaintenance,
    CALLFdrEncashmentMaintenance,
    CHECHERFdrEncashmentMaintenance,
    MAKERFdrEncashmentMaintenance,

    bearer,
    //FDR Maker
    MAKERJsonFormForFDTenorSchemeChange,
    MAKERJsonFormForFDNomineeUpdate,
    MAKERJsonFormForFDREncashmentCertificate,
    MAKERJsonFormForFDRLinkAccountChange,
    MAKERJsonFormForFDRSBSCodeUpdate,
    MAKERJsonFormForFDRRelationShipManager,
    MAKERJsonFormForFDSchemeChangeWithoutClosing,
    MAKERJsonFormForFDRCityTouchTagging,
    MAKERJsonFormForFDRFreezeUnfreezeMarkingMark,
    MAKERJsonFormForFDRSourceTaxReversal,
    //FDR CHECKER
    CHECKERJsonFormForFDTenorSchemeChange,
    CHECKERJsonFormForFDNomineeUpdate,
    CHECKERJsonFormForFDREncashmentCertificate,
    CHECKERJsonFormForFDRLinkAccountChange,
    CHECKERJsonFormForFDRSBSCodeUpdate,
    CHECKERJsonFormForFDRRelationShipManager,
    CHECKERJsonFormForFDSchemeChangeWithoutClosing,
    CHECKERJsonFormForFDRCityTouchTagging,
    closerRequestForm,
    closerRequestFormBom,
    CHECKERJsonFormForFDRFreezeUnfreezeMarkingMark,
    CHECKERJsonFormForFDRSourceTaxReversal,
    AccountMaintenanceReturn,
    maintenanceListReturn,
    closerRequestFormBmForDpsCertificate,
    closerRequestFormBomForDpsCertificate,
    dpsEncasmentCertificate,
    dpsEncashmentReadOnly
};

