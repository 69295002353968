import React from "react";
import CardHeader from "./Card/CardHeader";
import Card from "./Card/Card";
import CardBody from "./Card/CardBody";
import GridItem from "./Grid/GridItem";
import GridContainer from "./Grid/GridContainer";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from 'axios';
import Table from "./Table/Table";
import {backEndServerURL, frontEndServerURL} from "../Common/Constant";
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import Paper from '@material-ui/core/Paper';
import Pageview from '@material-ui/icons/Pageview';
import Customer from "./360View/Customer";
import Notification from "./NotificationMessage/Notification";
import loader from '../Static/loader.gif';
import {CSjsonFormIndividualAccountOpeningCustomerNameSearch} from './workflow/WorkflowJsonForm4';
import CBNotFound from "./workflow/CASA/CBNotFound";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "./JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "./JsonForm/CommonJsonFormComponent";
import validator from "validator";
import FileDownload from "./workflow/CommonComponent/FileDownload";
import Button from "@material-ui/core/Button";
import LocalstorageEncrypt from "./JsonForm/LocalstorageEncrypt";


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


class CustomerSearches extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: [],
            SelectedData: false,
            fileUploadData: [],
            selectedDate: {},
            dropdownSearchData: {},
            getDedupData: false,
            dedupData: [[" ", " "]],
            propritorshipData: [[" ", " "]],
            relatedData: [[" ", " "]],
            tableData: [],
            sourceMappingData: [],
            getsearchValue: [],
            getCustomerId: '',
            getAccountType: '',
            accountOpeningFromModal: false,
            tabMenuSelect: 'INDIVIDUAL',
            existingAcoountOpeningModal: false,
            newAcoountOpeningModal: false,
            searchTableData: null,
            searchTableRelatedData: null,
            oldAccountData: [],
            inputData: {},
            dataNotFound: false,
            CustomerModal: false,
            uniqueId: '',
            IDENTIFICATION_NO: '',
            cbNumber: '',
            customerName: '',
            id: '',
            alert: false,
            value: "INDIVIDUAL",
            NonIndividualabel: "",
            individualLabel: "Individual A/C",
            content: "INDIVIDUAL",
            getType: "INDIVIDUAL",
            anchorEl: null,
            anchorE2: null,
            individualDropdownOpen: null,
            objectForJoinAccount: [],
            handleChangeJoint: 0,
            cbNotFound: false,
            loading: false,
            jointSearchTable: [],
            jointGetTaggingName: [],
            propritorship: false,
            propritor: false,
            individualDedupData: {},
            jointDedupData: {},
            companyDedupData: {},
            err: false,
            errorArray: {},
            errorMessages: {},
            title: "Applicant CB Not Found",
            getError: false,
            getCompnayData: true,
            getPropritorData: true,
            downloadDataForCompany: false,
            downloadDataForCompanyProprietor: false,


            notificationMessage: "CB number / NID / Passport / Birth Certificate or Driving License is Required!!"


        };

        this.handleChange = this.handleChange.bind(this);
        this.handleClickNonIndividual = this.handleClickNonIndividual.bind(this);
        this.handleClickIndividual = this.handleClickIndividual.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
    };

    handleClickNonIndividual(event) {

        event.stopPropagation();
        this.setState({
            anchorEl: event.currentTarget,

        });
    }

    handleClickIndividual(event) {

        event.stopPropagation();

        this.setState({
            anchorE2: event.currentTarget,
            individualDropdownOpen: true
        });
    }

    handleClose() {
        this.setState({

            anchorEl: null,
            anchorE2: null,
            individualDropdownOpen: false,
            err: false,
            errorArray: {},
            errorMessages: {},


        });
    }


    handleMenuItemClick(menuItem) {
        this.handleClose();

        this.setState({
            NonIndividualabel: menuItem,
            content: menuItem,
            getType: menuItem,
            value: "More",
            initialization: true,

        });
    }

    closeInitialization = () => {
        this.setState({
            initialization: false
        });
    };

    handleMenuItemClickIndividual(menuItem) {

        this.handleClose();
        this.setState({
            individualLabel: menuItem,
            content: menuItem,
            getType: menuItem,
            value: "More",
            initialization: true,

        });

    }

    componentDidMount() {

        let data = [];
        data.cbNumber = "";
        data.nid = "";
        data.passport = "";
        data.customerName = "";
        data.email = "";
        data.phone = "";
        data.tin = "";
        data.registrationNo = "";
        this.setState({
            inputData: data
        })


    }

    renderExistingAccountOpeningForm = (customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType) => {

        let arrayData = {
            nid: nid,

            passport: passport,

            customerName: customerName,
            phone: phone,
            dob: dob,
            email: email,
            registration: registration
        };

        this.setState({
            existingAcoountOpeningModal: true,
            oldAccountData: arrayData,
            getCustomerId: customerId,
            getAccountType: accountSource,
            newAcoountOpeningModal: false,

        })
    };
    renderNewAccountOpeingForm = () => {
        this.setState({
            newAcoountOpeningModal: true,

        })
    };
    CustomerModal = (uniqueId, customerName, customerId, id) => {
        let data = {};
        data.identificationNumber = uniqueId;
        data.cbNumber = customerId;
        data.customerName = customerName;
        data.identity = id;
        LocalstorageEncrypt.encryptStorageFunction.setItem(id, JSON.stringify(data));
        let url = "/Customer/" + id;
        window.open(frontEndServerURL + url)
        /* this.setState({
             CustomerModal: true,
             customerName: customerName,
             cbNumber: customerId,
             IDENTIFICATION_NO: uniqueId

         })*/
    };
    closeCustomerModal = () => {
        this.setState({
            CustomerModal: false,

        })
    };
    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false

        })
    };
    removeSearchResult = () => {
        this.setState({

            showValue: false,
            existingAcoountOpeningModal: false,
            newAcoountOpeningModal: false,
        });
        this.handleChange("NEW", "INDIVIDUAL")
    };
    closeModal = () => {
        this.setState({
            existingAcoountOpeningModal: false,
            newAcoountOpeningModal: false,


        })

    };


    createTableData = (randomNumber, id, uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchedBy, matchType) => {
        //this.renderCustomerNumber(sl);
        if (this.state.content === 'Joint Account') {
            if (accountSource === "TRANZWARE") {
                return ([uniqueId, customerId, accountSource, customerName, nid, passport, tin, matchedBy, matchType,
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >
                        {/*<Fab size="small" color="secondary" aria-label="pageview">*/}
                        {/* <a href="/Customer" target="_blank"><Link target="_blank" to={{
                            pathname: '/Customer',
                            state: {
                                IDENTIFICATION_NO: uniqueId
                            }
                        }}> <Pageview/></Link> </a>*/}
                        <Pageview/>

                        {/*</Fab>*/}

                    </button>])
            } else {
                return (

                    [uniqueId, customerId, accountSource, customerName, nid, passport, tin, matchedBy, matchType,
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'middle',
                            }}

                            onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                        >
                            {/*<Fab size="small" color="secondary" aria-label="pageview">*/}

                            {/* {/* <a href="/Customer" target="_blank"><Link target="_blank" to={{
                            pathname: '/Customer',
                            state: {
                                IDENTIFICATION_NO: uniqueId
                            }
                        }}> <Pageview/></Link> </a>*/}
                            <Pageview/>
                            {/*</Fab>*/}

                        </button>


                    ])
            }
        } else {
            if (accountSource === "TRANZWARE") {
                return ([uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, matchedBy, matchType, /*<button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                        width: 113,
                    }}
                    onClick={() => this.renderNewAccountOpeingForm()}>
                    CONVENTIONAL</button>,

                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'middle',
                            width: 113,
                        }}
                        onClick={() => this.renderNewAccountOpeingForm()}>
                        ISLAMIC
                    </button>,*/
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >

                        <Pageview/>


                    </button>])
            } else if (this.state.content === 'NONINDIVIDUAL' || this.state.content === 'Proprietorship A/C') {
                return ([uniqueId, customerId, accountSource, customerName, nid, passport, tin, matchedBy, matchType,


                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >
                        {/*<Fab size="small" color="secondary" aria-label="pageview">*/}


                        {/* <a href="/Customer" target="_blank"><Link target="_blank" to={{
                            pathname: '/Customer',
                            state: {
                                IDENTIFICATION_NO: uniqueId
                            }
                        }}> <Pageview/></Link> </a>*/}
                        <Pageview/>
                        {/*</Fab>*/}

                    </button>


                ])
            } else {
                if (accountSource === "ABABIL") {
                    return ([uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, matchedBy, matchType,
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'middle',
                            }}

                            onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                        >
                            {/*<Fab size="small" color="secondary" aria-label="pageview">*/}
                            {/* <a href="/Customer" target="_blank"><Link target="_blank" to={{
                            pathname: '/Customer',
                            state: {
                                IDENTIFICATION_NO: uniqueId
                            }
                        }}> <Pageview/></Link> </a>*/}
                            <Pageview/>

                            {/*</Fab>*/}

                        </button>

                    ])
                } else {
                    return ([uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, matchedBy, matchType,
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'middle',
                            }}

                            onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                        >
                            {/*<Fab size="small" color="secondary" aria-label="pageview">*/}

                            {/* <a href="/Customer" target="_blank"><Link target="_blank" to={{
                            pathname: '/Customer',
                            state: {
                                IDENTIFICATION_NO: uniqueId
                            }
                        }}> <Pageview/></Link> </a>*/}
                            <Pageview/>
                            {/*</Fab>*/}

                        </button>


                    ])
                }
            }
        }

    };
    createTableDataWithoutProprietorship = (id, uniqueId, customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchby, matchType) => {
        //this.renderCustomerNumber(sl);
        if (this.state.content === 'Company Account') {
            if (accountSource === "TRANZWARE") {
                return ([uniqueId, customerId, accountSource, customerName, nid, passport, tin, matchby, matchType,
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >
                        {/*<Fab size="small" color="secondary" aria-label="pageview">*/}

                        {/* <a href="/Customer" target="_blank"><Link target="_blank" to={{
                            pathname: '/Customer',
                            state: {
                                IDENTIFICATION_NO: uniqueId
                            }
                        }}> <Pageview/></Link> </a>*/}
                        <Pageview/>
                        {/*</Fab>*/}

                    </button>])
            } else {
                return (

                    [uniqueId, customerId, accountSource, customerName, nid, passport, tin, matchType, matchType,
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'middle',
                            }}

                            onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                        >
                            {/*<Fab size="small" color="secondary" aria-label="pageview">*/}

                            {/* <a href="/Customer" target="_blank"><Link target="_blank" to={{
                            pathname: '/Customer',
                            state: {
                                IDENTIFICATION_NO: uniqueId
                            }
                        }}> <Pageview/></Link> </a>*/}
                            <Pageview/>
                            {/*</Fab>*/}

                        </button>


                    ])
            }
        }


    };
    createTableproCompany = (id, uniqueId, customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matched_by, matchType) => {


        if (this.state.content === 'NONINDIVIDUAL' || this.state.content === 'Proprietorship A/C') {
            return ([uniqueId, customerId, accountSource, customerName, nid, passport, tin, matched_by, matchType,
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}

                    onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                >
                    {/*<Fab size="small" color="secondary" aria-label="pageview">*/}

                    {/* <a href="/Customer" target="_blank"><Link target="_blank" to={{
                            pathname: '/Customer',
                            state: {
                                IDENTIFICATION_NO: uniqueId
                            }
                        }}> <Pageview/></Link> </a>*/}
                    <Pageview/>
                    {/*</Fab>*/}

                </button>

            ])

        } else {
            return ([uniqueId, customerId, accountSource, customerName, nid, passport, tin, matched_by, matchType,

                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}

                    onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                >
                    {/*<Fab size="small" color="secondary" aria-label="pageview">*/}

                    {/* <a href="/Customer" target="_blank"><Link target="_blank" to={{
                            pathname: '/Customer',
                            state: {
                                IDENTIFICATION_NO: uniqueId
                            }
                        }}> <Pageview/></Link> </a>*/}
                    <Pageview/>

                    {/*</Fab>*/}

                </button>


            ])
        }

    };

    createRelatedTableData = (id, uniqueId, mainCB, relatedCB, relatedCustomerName, relationship) => {

        if (this.state.content === 'Joint Account') {
            return ([uniqueId, mainCB, relatedCB, relatedCustomerName, relationship

            ])

        } else {
            return ([uniqueId, mainCB, relatedCB, relatedCustomerName, relationship])

        }
    };

    renderReloading(status) {
        this.setState({

            searchTableData: status,
            searchTableRelatedData: status,

        })
    }

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="error" stopNotification={this.stopNotification} title="Required!!"
                              message={this.state.notificationMessage}/>
            )
        }

    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    trimFunction = (emptyString) => {
        let string = emptyString.trim();
        return string;
    };
    emptyStringSet = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let i = 0; i < clone.length; i++) {
            let data = clone[i]["varName"].trim();

            if (this.state.inputData[data] === null || this.state.inputData[data] === undefined || validator.isEmpty(this.trimFunction(this.state.inputData[data]))) {
                this.state.inputData[data] = ""
            }


        }
        return clone;
    };
    reloadOff = () => {
        if (this.state.getCompnayData === false && this.state.getPropritorData === false) {
            this.setState({
                loading: false
            })
        }
    };
    handleSubmit = (event) => {
        this.setState({
            getCompnayData: true,
            getPropritorData: true,
            downloadDataForCompany: false,
            downloadDataForCompanyProprietor: false,
        });
        event.preventDefault();
        let phone = (this.state.inputData.phone).trim();
        if (phone === "") {
            this.state.inputData.phone = "00000000000"

        } else {
            this.state.inputData.phone = "88" + phone;
        }
        var dependencyField = [];
        var error = false;
        let s1 = false;
        /*  if (this.state.content === 'INDIVIDUAL' || this.state.content === 'Individual A/C') {
              if (this.state.inputData.dob !== undefined && this.state.inputData.dob !== null) {
                  let x = 18;
                  var currentDate = new Date();

                  let someDate = new Date();
                  let numberOfDaysToAdd = parseInt(x, 10)
                  someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                  let date = someDate.getDate();
                  let month = someDate.getMonth() + 1;
                  let year = someDate.getFullYear();
                  let convertDate = year + "-" + month + "-" + date;
                  let newDate = new Date(convertDate);
                  let stringToDate = new Date(newDate.toDateString());

                  let inputDate = new Date(this.state.inputData.dob);

                  if (stringToDate < inputDate) {
                      this.setState({
                          /!* title:"Aplicant Minor",
                           cbNotFound:true,*!/
                          alert: true,
                          notificationMessage: "Applicant is Minor.Please Open Joint Account",
                      })
                      return 0;
                  }
              }

              error = MyValidationDedup.CustomValidationIndividual(this.emptyStringSet(CSjsonFormIndividualAccountOpeningCustomerSearch), this.state)

              console.log(this.state.inputData)
          }
          else if (this.state.content === 'Joint Account') {
              error = MyValidationDedup.CustomValidationJoint(this.emptyStringSet(this.state.objectForJoinAccount), this.state)

          } else if (this.state.content === 'NONINDIVIDUAL' || this.state.content === 'Proprietorship A/C') {
              if (this.state.inputData.dob !== undefined && this.state.inputData.dob !== null) {
                  let x = 18;
                  var currentDate = new Date();

                  let someDate = new Date();
                  let numberOfDaysToAdd = parseInt(x, 10)
                  someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                  let date = someDate.getDate();
                  let month = someDate.getMonth() + 1;
                  let year = someDate.getFullYear();
                  let convertDate = year + "-" + month + "-" + date;
                  let newDate = new Date(convertDate);
                  let stringToDate = new Date(newDate.toDateString());

                  let inputDate = new Date(this.state.inputData.dob);

                  if (stringToDate < inputDate) {
                      this.setState({
                          /!* title:"Aplicant Minor",
                           cbNotFound:true,*!/
                          alert: true,
                          notificationMessage: "Your Applicant Minor",
                      })
                      return 0;
                  }
              }
              error = MyValidationDedup.CustomValidationProprietorship(this.emptyStringSet(CSjsonFormNonIndividualProprietorshipAccountOpeningCustomerSearch), this.state)
          } else if (this.state.content === 'Company Account') {
              error = MyValidationDedup.CustomValidationCompany(this.emptyStringSet(this.state.objectForJoinAccount), this.state)

          }
  */
        //this.forceUpdate();


        this.setState({
            loading: true,
            searchTableData: false,
            searchTableRelatedData: false,
            title: "Applicant CB Not Found",
            getError: false,


        });
        console.log(this.state.inputData);
        console.log("fffffffffffffff");
        console.log(this.state.inputData);
        this.renderReloading(null);
        let postData = {};
        let postDataProprietorship = {};

        let dateString;
        if (this.state.inputData.dob !== undefined && this.state.inputData.dob !== -1) {

            let da = this.state.inputData.dob.split(",");


            if (da != "" && da.length > 0) {
                let monthDateYear = da[0].split("/");
                let month, date;

                if (monthDateYear[0].length === 1)
                    month = "0" + monthDateYear[0];
                else
                    month = monthDateYear[0];
                if (monthDateYear[1].length === 1)
                    date = "0" + monthDateYear[1];
                else date = monthDateYear[1];
                dateString = monthDateYear[2] + "-" + month + "-" + date;
            } else {
                dateString = "1000-01-01";
            }
        } else {
            dateString = "1000-01-01";
        }


        postData = {
            "cbNumber": (this.state.inputData.cbNumber).trim(),
            "nid": (this.state.inputData.nid).trim(),
            "passport": (this.state.inputData.passport).trim(),
            "customerName": (this.state.inputData.customerName).trim(),
            "dob": dateString,
            "gender": "",
            "fathersName": "",
            "mothersName": "",
            "email": (this.state.inputData.email).trim(),
            "phone": (this.state.inputData.phone).trim(),
            "tin": (this.state.inputData.tin).trim(),
            "registrationNo": (this.state.inputData.registrationNo).trim(),
            "constitutionType": "",
            "sources": ["FINACLE", "ABABIL", "TRANZWARE"],

        };


        this.renderReloading(false);
        let tableArray = [];
        let relatedTableArray = [];
        //set 02/02/1983, 00:00:00 to 1983-02-02 format

        let url = backEndServerURL + "/nameOnlyDedup";
        console.log("data");
        console.log(postData);
        axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    sourceMappingData: response.data.sourceMapping,
                    individualDedupData: response.data
                });
                if (this.state.inputData.cbNumber !== "" && this.state.inputData.cbNumber !== undefined && !validator.isEmpty(this.trimFunction(this.state.inputData["cbNumber"])) && (response.data.highMatchCustomers.length < 1 && response.data.mediumMatchCustomers.length < 1 && response.data.relatedCustomers.length < 1)) {
                    this.setState({
                        cbNotFound: true,
                        searchTableData: false,
                        searchTableRelatedData: false,
                        loading: false,
                        getError: false,
                        title: "Applicant CB Not Found"
                    })
                } else {

                    if (response.data.highMatchCustomers !== null && Array.isArray(response.data.highMatchCustomers)) {

                        response.data.highMatchCustomers.map((dedup) => {
                            tableArray.push(this.createTableData("", dedup.id, dedup.identification_no_mod, dedup.maincustid, dedup.sz_contitution, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, dedup.matched_by, "Higher-Match"));

                        });

                    }

                    if (response.data.mediumMatchCustomers !== null && Array.isArray(response.data.mediumMatchCustomers)) {

                        response.data.mediumMatchCustomers.map((dedup) => {

                            tableArray.push(this.createTableData("", dedup.id, dedup.identification_no_mod, dedup.maincustid, dedup.sz_contitution, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, dedup.matched_by, "Medium-Match"));

                        });
                    }

                    if (response.data.relatedCustomers !== null && Array.isArray(response.data.relatedCustomers)) {
                        response.data.relatedCustomers.map((dedup) => {

                            relatedTableArray.push(this.createRelatedTableData(dedup.id, dedup.identificationNoMod, dedup.mainCB, dedup.relatedCB, dedup.relatedCustomerName, dedup.relationship, dedup.relationship));

                        });
                    }
                    this.setState({

                        dedupData: tableArray,
                        relatedData: relatedTableArray,
                        searchTableData: true,
                        searchTableRelatedData: true,
                        getsearchValue: this.state.inputData,
                        loading: false,

                    })
                }


            })
            .catch((error) => {

                this.setState({
                    dedupData: [],
                    relatedData: [],
                    searchTableData: true,
                    searchTableRelatedData: true,
                    loading: false,
                })

            });


    };
    handleChangeJoint = (event, option) => {
        event.preventDefault();
        if (option !== null) {
            this.setState({
                handleChangeJoint: option.title
            })
        }

    };
    numberToCharacter = (i) => {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    };


    updateComponent = () => {
        this.forceUpdate();
    };

    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.handleSubmit(event);

        }

    };
    renderSearchForm = () => {

        if (this.state.content === 'INDIVIDUAL' || this.state.content === 'Individual A/C') {
            return (

                CommonJsonFormComponent.renderJsonForm(this.state, CSjsonFormIndividualAccountOpeningCustomerNameSearch, this.updateComponent, this.onKeyDownForDedup)

            )
        } else {
        }
    };
    handleChange = (name, value) => {

        if (value === 'INDIVIDUAL') {
            this.setState({individualLabel: 'Individual A/C', initialization: true})
        }
        if (value === 'NONINDIVIDUAL') {
            this.setState({
                NonIndividualabel: 'Proprietorship A/C',
                "individualLabel": "",
                initialization: true,

            })
        }
        this.setState({

            tabMenuSelect: value,
            content: value,
            getType: value,
            searchTableData: null,
            searchTableRelatedData: null,
            err: false,
            errorArray: {},
            errorMessages: {},

        })
    };
    DedupDataDownloadApi = (event, type) => {
        event.preventDefault();
        let data = {};
        let downloadurl = backEndServerURL + "/dedup/downloadExcel";
        if (type === "INDIVIDUAL" || type === "Individual A/C") {
            data.individualDedupData = this.state.individualDedupData;
            data.dedupType = type;
            this.setState({
                loading: true,
            })
            FileDownload.downloadFile(downloadurl, "POST", data, "DedupresultDownload.xlsx", type)
                .then((res) => {
                    this.setState({
                        loading: false,
                    })
                }).catch((err) => {
                this.setState({
                    loading: false,
                })
            })
        } else {
            alert("please type select")
        }
    };
    downloadDedupdata = () => {
        if (this.state.searchTableData) {


            return (
                <Button
                    style={{
                        backgroundColor: "green",
                        color: "white",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 3,
                        paddingBottom: 3,
                        borderRadius: 3,
                        border: 1,
                        verticalAlign: 'left',
                        position: "absolute",
                        marginTop: "5px",
                        right: 10,
                    }}
                    onClick={(event) => this.DedupDataDownloadApi(event, this.state.content)}
                >
                    Download Excel
                </Button>
            )

        }
    };
    searchTableData = (style) => {
        if (!(this.state.getError) && this.state.searchTableData && (this.state.content === 'INDIVIDUAL' || this.state.content === 'Individual A/C')) {
            return (

                <React.Fragment>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>Search Result </h5>

                        </CardHeader>
                    </paper>

                    <div>
                        {this.downloadDedupdata()}

                    </div>
                    <br/>
                    <br/>
                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Customer ID", "CB No", "CB Type", "Source", "Customer Name", "NID", "Passport", "E-Tin", "Match By", "Match-Type", "360 View"]}

                        tableData={this.state.dedupData}
                        tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', "left", "left", "left"]}
                    />

                    <br/>


                </React.Fragment>

            )


        } else {

        }


    };
    searchTableRelatedData = (style) => {
        if (!(this.state.getError) && this.state.searchTableRelatedData && (this.state.content === 'INDIVIDUAL' || this.state.content === 'Individual A/C')) {

            return (

                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>Related CB</h5>

                        </CardHeader>
                    </paper>
                    <br/>
                    <div>

                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Customer ID", "Main CB", "Related CB", "Customer Name", "RelationShip"]}

                            tableData={this.state.relatedData}
                            tableAllign={['left', 'left', 'left', 'left', 'left']}
                        />

                        <br/>


                    </div>

                </div>

            )
        }

    };


    handleSubmitRefresh = (event) => {
        event.preventDefault();
        window.location.reload()

    };
    renderSearchButton = () => {
        if (this.state.content !== 'Joint A/C' && this.state.content !== 'Partnership A/C' && this.state.content !== 'Limited Company A/C' && this.state.content !== 'Others') {
            return (
                <React.Fragment>
                    <button
                        className="btn btn-outline-primary  btn-sm"
                        style={{
                            marginTop: "15px"
                        }}
                        onClick={this.handleSubmit}

                    >
                        Search
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-warning  btn-sm"
                        style={{
                            marginTop: "15px"
                        }}
                        onClick={this.handleSubmitRefresh}

                    >
                        Refresh
                    </button>
                </React.Fragment>
            )
        }
    };


    render() {

        const {value} = this.state;
        const open = Boolean(this.state.anchorEl);

        const {classes, onClose} = this.props;
        return (
            <section>
                {this.renderNotification()
                }
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.CustomerModal}

                >
                    <DialogContent className={classes.dialogPaper}>

                        <Customer cbNumber={this.state.cbNumber} customerName={this.state.customerName}
                                  closeModal={this.closeCustomerModal}
                                  IDENTIFICATION_NO={this.state.IDENTIFICATION_NO}/>
                    </DialogContent>
                </Dialog>


                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.cbNotFound}>
                    <DialogContent className={classes.dialogPaper}>

                        <CBNotFound
                            closeModal={this.closeModalCBNotFound}
                            getAccountType={this.state.getAccountType}
                            title={this.state.title}
                            accountType={this.state.tabMenuSelect}
                            serviceType="Account Opening"
                            subServiceType={this.state.content}
                            searchValue={this.state.getsearchValue}

                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>


                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>

                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>
                                <paper>

                                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                        <h5>Customer Search</h5>


                                    </CardHeader>
                                </paper>

                            </div>
                            <Paper square>
                                <CardBody>

                                    <div>
                                        <ThemeProvider theme={theme}>

                                            <Grid container>

                                                {this.renderSearchForm()}
                                                <Grid item xs={2}>
                                                    {this.renderSearchButton()}

                                                </Grid>
                                            </Grid>
                                        </ThemeProvider>
                                    </div>
                                </CardBody>
                            </Paper>

                        </Card>
                    </GridItem>

                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>

                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>
                                {this.searchTableData()}
                            </div>
                        </Card>
                    </GridItem>

                </GridContainer>


                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>

                                {this.searchTableRelatedData()}
                            </div>
                        </Card>
                    </GridItem>

                </GridContainer>


            </section>
        )


    }

}


export default withStyles(styles)(CustomerSearches);


