import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Functions from '../../../Common/Functions';
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Table from "../../Table/Table";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Notification from "../../NotificationMessage/Notification";
import loader from "../../../Static/loader.gif";
import PreviewMappingImage from "../Maintenance/casa/PreviewMappingImage";
import MyValidation from "../../JsonForm/MyValidation";
import SignatureButton from "../Maintenance/SignatureButton";
import FunctionForGetAcDetails from "../CommonComponent/FunctionForGetAcDetails";


let csRemarks = [
    {
        "varName": "bmRemarks",
        "type": "textArea",
        "label": "BM Remarks",
        "required": true,
        "grid": 12
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};


class ApprovalBM extends Component {

    constructor(props) {
        super(props);
        this.state = {
            values: [],
            appId: '',
            message: "",
            getData: false,
            varValue: {},
            caseId: "",
            title: "",
            notificationMessage: "",
            alert: false,
            redirectLogin: false,
            type: [],
            inputData: {},
            fileUploadData: {},
            selectedDate: {},
            showValue: false,
            getDeferalList: [],
            getTutionFeeList: [],
            getLivingExpenceList: [],
            getList: [],
            loading: true,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    getSearchvalue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '')
                delete clone[prop];
        return clone;
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    submitApprove = (event) => {
        event.preventDefault();
        let bm_approval = 'APPROVE';
        this.handleSubmit(event, bm_approval);
    };

    submitReturn = (event) => {
        event.preventDefault();
        let error = MyValidation.defaultValidation(csRemarks, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            let bm_approval = 'REJECT';
            this.handleSubmit(event, bm_approval);
        }

    };
    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <Grid item xs={12}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        type='button' value='add more'
                        onClick={this.submitApprove}
                    > Approve
                    </button>
                    &nbsp;
                    <button
                        className="btn btn-outline-info btn-sm"
                        type='button' value='add more'
                        onClick={this.submitReturn}
                    > Return
                    </button>
                </Grid>

            )
        }
    };

    componentDidMount() {
        var remarksArray = [];
        if (this.props.appId !== undefined) {
            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                response.data.map((data) => {
                    remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                });
                this.setState({
                    getRemarks: remarksArray
                })
            }).catch((error) => {
                console.log(error)
            });
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    let tableArray = [];
                    var status = "";
                    response.data.map((deferal) => {
                        if (deferal.status === "ACTIVE") {
                            status = "Approved"
                        } else if (deferal.status === "NOTAPPROVED") {
                            status = "NOT APPROVED"
                        } else {
                            status = deferal.status;
                        }
                        tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status, deferal.remarks));
                    });
                    this.setState({
                        getDeferalList: tableArray,
                    })
                }).catch((error) => {
                    console.log(error);
                });
                var url = backEndServerURL + "/remittence/" + this.props.appId + "/Tution Fee";
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    let tableArray = [];
                    response.data.map((tutionFee) => {
                        tableArray.push(this.createTutionFeeTable(tutionFee.beneficiaryBank, tutionFee.beneficiaryAcNo, tutionFee.AcNo, tutionFee.SwieftDetails, tutionFee.ammount));
                    });
                    this.setState({
                        getTutionFeeList: tableArray
                    })
                }).catch((error) => {
                    console.log(error);
                });
                url = backEndServerURL + "/remittence/" + this.props.appId + "/Living Expense";
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    let tableArray = [];
                    response.data.map((livingExpence) => {
                        tableArray.push(this.createLivingExpenceTable(livingExpence.beneficiaryBankLivingExpence, livingExpence.beneficiaryAcNoLivingExpence, livingExpence.AcNoLivingExpence, livingExpence.SwieftDetailsLivingExpence, livingExpence.ammountLivingExpence));
                    });
                    this.setState({
                        getLivingExpenceList: tableArray
                    })
                }).catch((error) => {
                    console.log(error);
                });
                this.state.inputData["csDeferal"] = "YES";
                this.state.inputData.customerId = "YES";
                let inputData = this.getSearchvalue(response.data);
                let varValue = this.getSearchvalue(response.data);
                if (("courseCommencementDate" in varValue)) {
                    inputData["courseCommencementDate"] = (inputData["courseCommencementDate"]).split(',')[0];
                    varValue["courseCommencementDate"] = (varValue["courseCommencementDate"]).split(',')[0];
                }
                FunctionForGetAcDetails.getAccountInfo(response.data.accountNumber).then((acResponse) => {
                    inputData.accountBalance = acResponse.data.acctEftvBalance;
                    this.setState({
                        getData: true,
                        varValue: varValue,
                        appData: this.getSearchvalue(response.data),
                        inputData: inputData,
                        showValue: true,
                        appId: this.props.appId,
                        loading: false,
                    });
                }).catch((error) => {
                    this.setState({
                        loading: false,
                    });
                });
            }).catch((error) => {
                console.log(error);
                if (error.response.status === 652) {
                    Functions.removeCookie();
                    this.setState({
                        redirectLogin: true
                    })
                }
            });
        }
    }

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status, remarks) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status, remarks
        ])

    };
    createTutionFeeTable = (beneficiaryBank, beneficiaryAcNo, AcNo, SwieftDetails, ammount) => {

        return ([
            beneficiaryBank, beneficiaryAcNo, AcNo, SwieftDetails, ammount
        ])

    };
    createLivingExpenceTable = (beneficiaryBankLivingExpence, beneficiaryAcNoLivingExpence, AcNoLivingExpence, SwieftDetailsLivingExpence, ammountLivingExpence) => {

        return ([
            beneficiaryBankLivingExpence, beneficiaryAcNoLivingExpence, AcNoLivingExpence, SwieftDetailsLivingExpence, ammountLivingExpence
        ])

    };
    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Deferral Data </b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status", "Remarks"]}
                        tableData={this.state.getDeferalList}
                        tableAllign={['left', 'left']}
                    />
                    <br/>
                </div>
            )
        }

    };
    renderTutionFeeData = () => {
        if ((this.props.subServiceType === 'Student File' || this.props.subServiceType === 'Student File-Renew') && this.state.getTutionFeeList.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Tuition Fee Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Course Type", "Program Name", "Currency Type", "Referred by", "Amount"]}
                        tableData={this.state.getTutionFeeList}
                        tableAllign={['left', 'left', 'left', 'left', 'left']}
                    />
                    <br/>
                </div>
            )
        }

    };
    renderLivingExpenceData = () => {
        if ((this.props.subServiceType === 'Student File' || this.props.subServiceType === 'Student File-Renew') && this.state.getLivingExpenceList.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Living Expense Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Beneficiary Bank", "Beneficiary A/C No", "A/C No", "Swift Details", "Amount"]}
                        tableData={this.state.getLivingExpenceList}
                        tableAllign={['left', 'left', 'left', 'left', 'left']}
                    />
                    <br/>
                </div>
            )
        }

    };

    updateComponent = () => {
        this.forceUpdate();
    };
    handleChangeComments = () => {

    };

    returnJsonForm = () => {
        if (this.state.showValue) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.jsonForm, this.updateComponent)
            )
        }
    };

    handleSubmit = (event, data) => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        var appId = this.props.appId;
        var approval = data;
        let deferalUrl = backEndServerURL + "/deferral/approval";
        axios.post(deferalUrl, {
            appId,
            approval
        }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });
            });
        this.state.inputData.bm_approval = data;
        this.state.inputData["csDeferal"] = "NO";
        if (data === "REJECT" && this.props.subServiceType === 'Inward') {
            this.state.inputData.Inward_remittance_current_user = "BM";
        }
        let remarksData = {};
        remarksData.remark = this.state.inputData.bmRemarks;
        remarksData.map = {...this.state.inputData, bmRemarks: undefined};
        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            this.setState({
                title: "Successful!",
                notificationMessage: "Successfully Routed!",
                alert: true,
                loading: false
            });
            this.props.closeModal();
        }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false
            })
        });
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks</b>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>

                </div>
            )
        }
    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };
    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
            )
        }
    };


    close = () => {
        this.props.closeModal();
    };


    viewAttachedImages = () => {
        const {classes} = this.props;
        return <PreviewMappingImage appId={this.props.appId} classes={classes}/>
    };

    signature = () => {
        if (this.state.getData && this.state.inputData.showSignature) {
            return <SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
                                    classes={this.props}/>;
        }
    };


    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <ThemeProvider theme={theme}>
                        <Grid container spacing={1}>
                            {this.renderNotification()}
                            {this.returnJsonForm()}
                            <Grid item xs='12'>
                                {this.signature()}
                            </Grid>
                            <Grid item xs='12'>
                                {this.renderTutionFeeData()}
                            </Grid>
                            <Grid item xs='12'>
                                {this.renderLivingExpenceData()}
                            </Grid>
                            <Grid item xs='12'>
                                {this.renderDefferalData()}
                                <br/>
                                {this.renderRemarksData()}
                                <br/>
                                {
                                    this.viewAttachedImages()
                                }
                                {this.renderRemarks()}
                            </Grid>
                            <center>
                                {this.renderSubmitButton()}
                            </center>
                        </Grid>
                    </ThemeProvider>
                </div>
            );
        }
    }
}

export default withStyles(styles)(ApprovalBM);
