import React from "react";
import {backEndServerURL, frontEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import Grid from "@material-ui/core/Grid";
import FileTypeComponent from "../../JsonForm/FileTypeComponent";
import SignatureButton from "../Maintenance/SignatureButton";
import DedupResultFunction from "../../DedupResultFunction";
import {CBDataJsonForm} from "../WorkflowJsonForm4";
import MandateAndBeneficiaryResult from "../../MandateAndBeneficiaryResult";
import SdnResultFunction from "../../SdnResultFunction";
import FlowSummeryButton from "./FlowSummeryButton";
import Table from "../../Table/Table";
import CardHeader from "../../Card/CardHeader";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import TextField from "@material-ui/core/TextField";
import SecondaryCbFormDetails from "../CASA/SecondaryCbFormDetails";
import Functions from "../../../Common/Functions";
import GridList from "@material-ui/core/GridList";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import AssignedCropImage from "../CASA/AssignedCropImage";
import ErrorModal from "./ErrorModal";
import ConfirmAlert from "./ConfirmAlert";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import CloseIcon from "@material-ui/icons/Close";
import Label from "../Maintenance/static/Label";

let day = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",

];
let jsonFormForCB = [
    {
        "type": "title",
        "label": "MIS RELATED",
        "grid": 12,
    },

    /* {
         "varName": "misprimarySolId",
         "type": "text",
         "label": "Primary Sol ID",
         "grid": 6,
         "required": true,
     },*/


    {
        "varName": "miscustomerIdCifId",
        "type": "text",
        //"required": true,
        "label": "Customer ID/CIF ID",
        "grid": 6,
        "readOnly": true,
        // "required": true,
    },


    {
        "varName": "mistitle",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //mistitle=inputData.customerTitle
        "label": "Title",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "miscustomerNameLastName",
        "type": "text",
        "required": true,
        "validationB": "length",
        "maxLength": 80,
        //inputData.miscustomerNameLastName=inputData.customerName;
        "label": "Customer Name/Last Name",
        "grid": 6,
    },

    {
        "varName": "misshortName",
        "type": "text",
        "required": true,
        "validationB": "length",
        "maxLength": 10,
        //inputData.misshortName=inputData.shortName;

        "label": "Short Name",
        "grid": 6,
    },

    /*  {
          "varName": "misgender",
          "type": "select",
          //"required": true,
          "enum": [
              "M",
              "F",
              "TRANSGENDER",
              "OTHER"
          ],
          "label": "Gender",
          "grid": 6,
          "required": true,
      },*/

    {
        "varName": "miscustomerStatusNormalStaffPriorityEtc",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Customer Status",
        //inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.customerStatus
        "grid": 6,
        "required": true,
    },

    {
        "varName": "misoccupationCodePriorityCodeStaff",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 6,
        "required": true,
        //
        /*  if(this.state.inputData.occupationCodeNormal!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodeNormal
      }
      else if(this.state.inputData.occupationCodePriority!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodePriority
      }
      else if(this.state.inputData.occupationCodeStaff!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodeStaff
      }*/
    },

    {
        "varName": "misconstitution",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //master data cbl provide
        "label": "Constitution",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "misstaffIndicatorStatus",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "label": "Staff Indicator/Status",
        //inputData.misstaffIndicatorStatus=inputData.staffIndicator
        "grid": 6,


    },

    {
        "varName": "misstaffIdNumber",
        "type": "text",
        "label": "Staff ID Number",
        "validation": "staffId",
        //inputData.misstaffIdNumber=inputData.staffIdNumber
        "grid": 6,

    },

    {
        "varName": "mispriorityCenterCodeFreeCode1",
        "type": "select",
        "enum": [
           "PR104",
            "PR142",
            "PR177",
            "PR186",
            "PR187",
            "PR195",
            "PR208",
            "PR169",
        ],
        //inputData.mispriorityCenterCodeFreeCode1=inputData.priorityCenterCode;
        "label": "Priority Center Code/Free Code 1",
        "grid": 6,
    },

    {
        "varName": "mispriorityMonitoringRmCodeFreeCode3",
        "type": "textApiCall",
        "enum": [],
        //inputData.mispriorityMonitoringRmCodeFreeCode3=inputData.monitoringRMCode
        "label": "Priority Monitoring  RM Code",
        "grid": 6,
    },
    {
        "varName": "mispriorityMonitoringRmCodeFreeCode3Name",
        "type": "text",
        //inputData.mispriorityMonitoringRmCodeFreeCode3=inputData.monitoringRMCode
        "label": "Priority Monitoring  RM Code Name",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "mispriorityMonitoringRmCodeFreeCode3ApiCall",
        "conditionalVarValue": true,
        "grid": 6,
    },

    {
        "varName": "misprimaryCbHolderForPriorityFreeText8",
        "type": "text",
        //inputData.misprimaryCbHolderForPriorityFreeText8=inputData.primaryCBHolderforPriority
        "label": "Primary CB Holder for Priority",
        "grid": 6,
    },

    {
        "varName": "misrelationshipWithPrimaryCbHolderFreeText9",
        "type": "text",
        //inputData.misrelationshipWithPrimaryCbHolderFreeText9=inputData.relationshipwithPrimaryCBHolder;
        "label": "Relationship with Primary CB Holder",
        "grid": 6,
    },


    {
        "varName": "misbbkSegmentCodeFreeCode7",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "BBK Segment Code",
        "grid": 6,
        "required": true,
    },


    {
        "varName": "mismigratedNidVerificationFreeText2",
        "type": "text",
        "label": "Migrated NID Verification",
        "grid": 6,
    },

    {
        "varName": "mismigratedSmartCardNumberFreeText5",
        "type": "text",
        "label": "Migrated Smart Card Number",
        "grid": 6,
    },

    {
        "varName": "mispepStatusFreeText10",
        "type": "text",
        "label": "PEP Status",
        "grid": 6,
    },

    {
        "varName": "mismigratedCifsDocumentNoFreeText13",
        "type": "text",
        "label": "Migrated CIFs Document No",
        "grid": 6,
    },


    {
        "varName": "misfreeCodeFreeText1",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText2",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText3",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misavailedTradeServices",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Availed Trade services",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Currency Code",
        "grid": 12,
    },

    {
        "varName": "aof1currencyCodeCcy1",
        "type": "text",
        //inputData.aof1currencyCodeCcy=inputData.currency
        "label": "Existing Currency Code/CCY",
        "grid": 6,
        "readOnly": true,
        //"readOnly":true,
        "conditional": true,
        "conditionalVarName": "aof1currencyCodeCcyExist",
        "conditionalVarValue": true,
    },
    {
        "varName": "aof1currencyCodeCcy1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.aof1currencyCodeCcy=inputData.currency
        "label": "Existing Currency Code/CCY",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "aof1currencyCodeCcyExist",
        "conditionalVarValue": false,
        //"readOnly":true,
    },
    /*  {
          "varName": "aof1currencyCodeCcy",
          "type": "autoCompleteValueReturn",
          "enum": [],
          //inputData.aof1currencyCodeCcy=inputData.currency
          "label": "New Currency Code/CCY",
          "grid": 6,
          "required": true,

          //"readOnly":true,
      },*/
    {
        "type": "title",
        "label": "Contact Number - Email",
        "grid": 12,
    },
    {
        "varName": "contactNumberEmailpreferedEmailIdType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "COMMEML",
            "value": "COMMEML"
        }],
        "readOnly": true,
        "label": "Prefered Email ID type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailType1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "COMMEML",
            "value": "COMMEML"
        }],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId4",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "EMAIL",
            "value": "EMAIL"
        }],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmaildesignatedEmailAddressEmailId1",
        "type": "text",
        "label": "Designated Email Address/Email ID 1",
        "grid": 6,
    },
    {
        "varName": "contactNumberEmaildesignatedEmailAddressEmailId1Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,


    },
    {
        "type": "title",
        "label": "Contact Number - Phone",
        "grid": 12,
    },
    {
        "varName": "contactNumberpreferredContactNoType1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH1",
            "value": "COMMPH1"
        }],
        "label": "Preferred Contact no type",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 1",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH1",
            "value": "COMMPH1"
        }],
        "label": "Contact Type",
        "grid": 6,
        "readOnly": true,
    },

    {
        "varName": "contactNumberphoneNoEmailId1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No./Email ID",
        "grid": 6,
        "readOnly": true,
    },
    {
        "varName": "mobileTypecontactNumberphoneNo1",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required": true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType1",
        "conditionalVarValue": "COMMPH1",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Overseas",
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo1",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo1",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Overseas",

    },


    {
        "type": "title",
        "label": "Primary Introducer details",
        "grid": 12,
    },
    {
        "varName": "nidVerified",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "required": true,
        "label": "Is All NID/Smart Card Document Verified?",
        "grid": 6,

    },
    {
        "varName": "primryintroducerCustomerIdCifId",
        "type": "textApiCall",
        //inputData.primryintroducerCustomerIdCifId=inputData.introducerId
        "label": "Introducer Customer Id/CIF ID",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },


    {
        "varName": "primryiintroducerNameLastName",
        "type": "text",
        //inputData.primryiintroducerNameLastName=inputData.introducerName
        "label": "Introducer Name/Last Name",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["introducerIdApiCall", "nidVerified"],
        "conditionalArrayValue": [true, "NO"]

    },

    {
        "varName": "primryiintroducerStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Introducer Status",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },

    {
        "varName": "primryicifTypePrimaryIntroducerDetails",
        "type": "select",
        "enum": [
            "Retail",
            "Corporate",
        ],
        "label": "CIF Type/Primary Introducer Details",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },
    {
        "type": "empty",
        "grid": 12,
    },

    {
        "varName": "crmMissubSegment",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Segment",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Basic Information Update",
        "grid": 12,
    },
    {
        "varName": "basicInformationUpdateprimarySolId",
        "type": "text",
        "enum": [],
        "readOnly": true,
        "label": "Primary Sol ID",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdategender",
        "type": "select",
        "enum": [
            "M",
            "F",
            "TRANSGENDER",
            "OTHER"
        ],
        "label": "Gender",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdatefatherNamePrefferedName",
        "type": "text",
        "label": "Father Name/Preffered Name",
        "validationB": "length",
        "maxLength": 50,
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdatemotherNameMothersMaidenName",
        "type": "text",
        "label": "Mother Name/Mothers Maiden Name",
        "grid": 6,
        "validationB": "length",
        "maxLength": 50,
    },

    {
        "varName": "basicInformationUpdatemaritalStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Marital Status",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "basicInformationUpdatespouseNamePurgeRemarks",
        "type": "text",
        "label": "Spouse Name/Purge Remarks",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "basicInformationUpdatemaritalStatus",
        "conditionalVarValue": "MARID",
        "validationB": "length",
        "maxLength": 240,

    },

    {
        "varName": "basicInformationUpdatedob",
        "type": "date",
        "maxDate": true,
        "onKeyDown": true,
        //inputData.basicInformationUpdatedob=inputData.gender
        "label": "DOB",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdateminorStatusMinorIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "readOnly": true,
        "label": "Minor Status/Minor Indicator",
        "conditional": true,
        "conditionalVarName": "basicInformationUpdatedobApiCall",
        "conditionalVarValue": "YES",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateseniorCitizenIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "readOnly": true,
        "label": "Senior Citizen Indicator",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdatedobApiCall",
        "conditionalVarValue": "YES",
        "required": true,
    },
    {
        "type": "title",
        "label": "Gurdian Details - Only Visible In Case Minor Account Holder",
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",

        "grid": 12,
    },


    {
        "varName": "guardianDetailsrelation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation *",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsbankRelationType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Bank Relation Type *",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsrelation1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation *",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsguardianSelectionGurdian",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Guardian Selection/Gurdian",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsgurdianCifNumberCustomer",
        "type": "textApiCall",
        "label": "Gurdian CIF Number/Customer *",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailslastName",
        "type": "text",
        "enum": [],
        "label": "Last Name *",
        "grid": 6,
        "required": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "guardianDetailsgurdianCifNumberCustomerApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "type": "title",
        "label": "National ID Card",
        "grid": 12,
    },
    {
        "varName": "natioanlIdCarddocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "natioanlIdCarddocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "natioanlIdCarddocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "NID",
            "value": "NID CARD IDENTITY"
        }],
        "label": "Document Code ",
        "grid": 6,

    },

    {
        "varName": "nationalIdCardNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "National ID Card Number/Unique ID",
        "grid": 6,
        "validation": "nid",
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "varName": "natioanlIdCardnidVerificationConfirmationIsDocumentVerified1",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "varName": "natioanlIdCardissueDateN",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },
    {
        "varName": "natioanlIdCardDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "type": "title",
        "label": "Smart ID Card",
        "grid": 12,
    },
    {
        "varName": "smartIdCarddocumentTypeS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "smartIdCarddocumentTypeDescriptionS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "smartIdCarddocumentCodeS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "RSNID",
            "value": "RSNID"
        }],
        "label": "Document Code ",
        "grid": 6,
        //"required": true,
    },

    {
        "varName": "smartCardNumberUniqueId",
        "type": "text",
        "label": "Smart Card Number/Unique ID",
        "grid": 6,
        //"required": true,
        "validation": "smartCard",
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"

    },

    {
        "varName": "smartIdCardnidVerificationConfirmationIsDocumentVerified",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },

    {
        "varName": "smartIdCardissueDateS",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },
    {
        "varName": "smartIdCardDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },
    {
        "type": "title",
        "label": "Passport Number",
        "grid": 12,
    },
    {
        "varName": "passportNumberdocumentTypeP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "passportNumberdocumentTypeDescriptionP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        //inputData.passportNumberdocumentTypeDescriptionP=inputData.passport
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "passportNumberdocumentCodeP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "PASSPORT",
            "value": "PASSPORT IDENTITY"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "passportNumberpassportNumberUniqueId",
        "type": "textApiCall",
        "label": "Passport Number/Unique ID",
        "grid": 6,
        "validation": "passport",
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberplaceOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Place of Issue",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberissueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumbercountryOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country of Issue",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberexpiryDate",
        "type": "date",
        "label": "Expiry Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },
    {
        "varName": "passportNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },
    {
        "type": "title",
        "label": "Driving License",
        "grid": 12,
    },
    {
        "varName": "drivingLicensedocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "drivingLicensedocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "drivingLicensedocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "DL",
            "value": "DRIVING LICENSE"
        },],
        "label": "Document Code",
        "grid": 6,

    },

    {
        "varName": "drivingLicenseNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "Driving ID Card Number/Unique ID",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "drivingLicensedocumentCodeN",
        "conditionalVarValue": "DL"
    },
    {
        "varName": "drivingLicenseNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "drivingLicensedocumentCodeN",
        "conditionalVarValue": "DL"
    },

    {
        "type": "title",
        "label": "Resident ID",
        "grid": 12,
    },
    {
        "varName": "residentdocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "residentdocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "residentdocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "RESID",
            "value": "RESIDENT ID CARD"
        },],
        "label": "Document Code ",
        "grid": 6,

    },

    {
        "varName": "residentNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "Resident ID",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "residentdocumentCodeN",
        "conditionalVarValue": "RESID"
    },
    {
        "varName": "residentNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "residentdocumentCodeN",
        "conditionalVarValue": "RESID"
    },
    {
        "type": "title",
        "label": "Birth Certificate",
        "grid": 12,
    },
    {
        "varName": "birthCertificatedocumentTypeB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "birthCertificatedocumentTypeDescriptionB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "birthCertificatedocumentCodeB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "BC",
            "value": "BIRTH CERTIFICATE"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "birthCertificatebirthCertificateNumberUniqueId",
        "type": "text",
        "label": "Birth Certificate Number/Unique ID",
        //inputData.birthCertificatebirthCertificateNumberUniqueId=inputData.birthCertificateNo
        "grid": 6,
        "validation": "birthCertificateNumber",
        "required": true,
        "masterData": true,
        "conditionalVarName": "birthCertificatedocumentCodeB",
        "conditionalVarValue": "BC"
    },
    {
        "varName": "birthCertificateDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "birthCertificatedocumentCodeB",
        "conditionalVarValue": "BC"
    },
    {
        "type": "title",
        "label": "Chairman Certificate",
        "grid": 12,
    },
    {
        "varName": "chairmanCertificatedocumentTypeCC",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "chairmanCertificatedocumentTypeDescriptionCC",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "chairmanCertificatedocumentCodeCC",
        "type": "autoCompleteValueReturn",
        "enum": [{"masterDataName": "documentCode", "key": "CHAC", "value": "CHAIRMAN CERTIFICATE"}],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificateUniqueId",
        "type": "text",
        "label": "Chairman Certificate/Unique ID",
        "grid": 6,
        "validationB": "length",
        "maxLength": 20,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatedocumentCodeCC",
        "conditionalVarValue": "CHAC"
    },
    {
        "varName": "chairmanCertificateDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatedocumentCodeCC",
        "conditionalVarValue": "CHAC"
    },


    {
        "type": "title",
        "label": "Other Photo ID",
        "grid": 12,
    },


    {
        "varName": "photoIddocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "photoIddocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "photoIddocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "photoIdUniqueId",
        "type": "text",
        "label": "Unique ID",
        "grid": 6,
    },
    {
        "varName": "photoIdDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
    },
    {
        "type": "empty",
        "grid": 12,

    },
    {
        "varName": "chairmanCertificateplaceOfBirthFreeText14",
        "type": "text",
        "label": "Place of Birth/Free Text 14",
        "grid": 6,

    },

    {
        "varName": "chairmanCertificatecountryOfBirthFreeText15",
        "type": "text",
        "label": "Country of Birth/Free Text 15",
        "grid": 6,

    },

    {
        "varName": "Customersnationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Nationality",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "chairmanCertificatenonResident",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Non Resident",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "chairmanCertificateturnedNonResidentOn",
        "type": "date",
        "label": "Turned Non Resident On",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },
    {
        "type": "title",
        "label": "E-Tin",
        "grid": 12,
    },
    {
        "varName": "etindocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "etindocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "etindocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "etineTinNumber",
        "type": "text",
        //inputData.etineTinNumber=inputData.eTin
        "label": "E-Tin Number/Unique ID",
        "grid": 6,
        "validation": "etin",
        "required": true,
        "masterData": true,
        "conditionalVarName": "etindocumentCode",
        "conditionalVarValue": "ETIN"
    },
    {
        "varName": "etinDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "etindocumentCode",
        "conditionalVarValue": "ETIN"
    },
    {
        "type": "title",
        "label": "Employer Data",
        "grid": 12,
    },
    {
        "varName": "employerDataprofessionEmploymentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "employmentType",
            "key": "MEDIA CELEBRITY",
            "value": "MEDIA CELEBRITY"
        }],
        "label": "Profession /Employment Type",
        "grid": 6,
    },


    {
        "varName": "employerDatanameOfEmployerEmployerName",
        "type": "text",
        "label": "Name of Employer/Employer Name",
        "grid": 6,
    },

    {
        "varName": "employerDatamonthlyIncomeGrossIncome",
        "type": "text",
        "label": "Monthly Income/Gross income",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 2",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType2",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH2",
            "value": "COMMPH2"
        }],
        "label": "Contact Type",
        "grid": 6,

    },

    {
        "varName": "contactNumberphoneNoEmailId2",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No.",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2"
    },
    {
        "varName": "mobileTypecontactNumberphoneNo2",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required": true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Overseas",
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo2",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo2",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Overseas",

    },
    {
        "varName": "contactNumber2Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2"
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 3",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType3",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "WORKPH1",
            "value": "WORKPH1"
        }],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNoEmailId3",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No.",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1"
    },

    /*  {
          "varName": "contactNumberphoneNo3",
          "type": "text",
          "validation": "numeric",
          "label": "Phone No",
          "grid": 6,
          "required": true,
          "masterData": true,
          "conditionalVarName": "contactNumbercontactType3",
          "conditionalVarValue": "WORKPH1"
      },*/

    {
        "varName": "mobileTypecontactNumbercontactType3",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required": true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo3",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Overseas",
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo3",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo3",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Overseas",

    },


    {
        "varName": "contactNumber3Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1"
    },

    {
        "type": "title",
        "label": "Contact Number - Email",
        "grid": 12,
    },

    {
        "varName": "contactNumberEmailemailType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "HOMEEML",
            "value": "HOMEEML"
        }],
        "required": true,
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "EMAIL",
            "value": "EMAIL"
        }],
        "label": "Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailId2",
        "type": "text",
        "label": "Email ID 2",
        "grid": 6,
    },
    {
        "varName": "contactNumberEmailemailId2Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "required": true,
        "label": "Delete Flag",
        "grid": 6,
    },

    {
        "type": "title",
        "label": "Mailing Address",
        "grid": 12,
    },
    {
        "varName": "maillingAdresspreferredAddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        //inputData.maillingAdresspreferredAddressType=inputData.SelectCommunicationAddress

        "label": "Preferred Address type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        "label": "Addrss type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressmailingAddressField1",
        "type": "text",
        //inputData.maillingAdressmailingAddressField1=inputData.communicationAddress1
        "label": "Mailing Address Field 1",
        "validationB": "length",
        "maxLength": 45,
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressmailingAddressField2",
        "type": "text",
        //inputData.maillingAdressmailingAddressField2=inputData.communicationAddress2
        "label": "Mailing Address Field 2",
        "validationB": "length",
        "maxLength": 45,
        "grid": 6,
    },

    {
        "varName": "maillingAdresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdresscity=inputData.city2

        "label": "City",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdressstate=inputData.state2

        "label": "Division(State)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        //inputData.maillingAdresspostalCode=inputData.postalCode3
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdresscountry=inputData.nationality
        "label": "Country",
        "grid": 6,
        "required": true,
    },

    {
        "type": "title",
        "label": "Home Address",
        "grid": 12,
    },
    {
        "varName": "homeAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "required": true,
        "label": "Address Format",
        "grid": 6,

    },

    {
        "varName": "homeAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Home",
            "value": "Home"
        }],
        "onKeyDown": true,
        "label": "Address type",
        "grid": 6,

    },

    {
        "varName": "homeAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Home",
            "value": "Home"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,

    },

    {
        "varName": "homeAddresshomeAddressField1",
        "type": "text",
        "label": "Home Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresshomeAddressField2",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],

        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },
    {
        "varName": "homeAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },
    {
        "type": "title",
        "label": "Work Address",
        "grid": 12,
    },
    {
        "varName": "workAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "workAddressaddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Work",
            "value": "Work"
        }],
        "label": "Address type",
        "grid": 6,
    },

    {
        "varName": "workAddressaddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Work",
            "value": "Work"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "workAddressworkAddressField1",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddressworkAddressField2",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "masterData": true,
        "required": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },
    //1present address
    {
        "type": "title",
        "label": "Present Address",
        "grid": 12,
    },
    {
        "varName": "presentAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "required": true,
        "grid": 6,
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Future/OnSite",
            "value": "Future/OnSite"
        }],
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Future/OnSite",
            "value": "Future/OnSite"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "presentAddresspresentAddressField1",
        "type": "text",
        "label": "Present Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"

    },

    {
        "varName": "presentAddresspresentAddressField2",
        "type": "text",
        "label": "Present Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },
    {
        "varName": "presentAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    }, //1NRE address
    {
        "type": "title",
        "label": "NRE Address",
        "grid": 12,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },
    {
        "varName": "nreAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "masterDataName": "addressType",
                "key": "NRERelative",
                "value": "NRERelative"
            }
        ],
        "label": "Addrss type",
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
        "grid": 6,
    },

    {
        "varName": "nreAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "NRERelative",
            "value": "NRERelative"
        }],
        "label": "Address label",
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
        "grid": 6,
    },

    {
        "varName": "nreAddressnreAddressField1",
        "type": "text",
        "label": "NRE Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"

    },

    {
        "varName": "nreAddressnreAddressField2",
        "type": "text",
        "label": "NRE Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"

    },
    {
        "varName": "nreAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },


];
let jsonFormForAccount = [


    {
        "type": "title",
        "label": "Fincore Data Update - Account Level",
        "grid": 12,
    },


    {
        "varName": "fincoreDataUpdatecashLimitDr",
        "type": "text",
        "validation": "numeric",
        "label": "Cash Limit (Dr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatecashLimitCr",
        "type": "text",
        "validation": "numeric",
        "label": "Cash Limit (Cr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdateclearingLimitDr",
        "type": "text",
        "validation": "numeric",
        "label": "Clearing Limit (Dr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdateclearingLimitCr",
        "type": "text",
        "validation": "numeric",
        "label": "Clearing Limit (Cr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatetransferLimitDr",
        "type": "text",
        "validation": "numeric",
        "label": "Transfer Limit (Dr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatetransferLimitCr",
        "type": "text",
        "validation": "numeric",
        "label": "Transfer Limit (Cr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatenotes",
        "type": "text",
        "label": "Notes",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdateaccountStatement",
        "type": "select",
        "enum": [
            "S-STATEMENT",
            "NONE",
        ],
        "label": "Account Statement",
        "grid": 6,
        "required": true,
    },
    {
        "type": "empty",
        "grid": 12,
    },

    {
        "type": "title",
        "label": "Statement Frequency",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdateaccountStatement",
        "conditionalVarValue": "S-STATEMENT",
    },
    {
        "varName": "fincoreDataUpdatestatementFrequency1",
        "type": "select",
        "enum": [
            "Half Yearly",
            "Yearly",
            "Quarterly",
            "Monthly",
        ],
        "grid": 3,
        "required": true,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdateaccountStatement",
        "conditionalVarValue": "S-STATEMENT",
    },
    {
        "varName": "fincoreDataUpdatestatementFrequency2",
        "type": "select",
        "enum": day,
        "grid": 3,
        "required": true,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdateaccountStatement",
        "conditionalVarValue": "S-STATEMENT",
    },
    {
        "varName": "fincoreDataUpdatestatementFrequency3",
        "type": "select",
        "enum": [
            "Next Day",
            "Holiday",
            "Previous Day",
            "Skip",
        ],
        "grid": 3,
        "required": true,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdateaccountStatement",
        "conditionalVarValue": "S-STATEMENT",
    },


    {
        "varName": "fincoreDataUpdateaCStatus",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "masterDataName": "accountStatus",
                "key": "A",
                "value": "Active"
            },
            {
                "masterDataName": "accountStatus",
                "key": "D",
                "value": "Dormant"
            },
            {
                "masterDataName": "accountStatus",
                "key": "I",
                "value": "Inactive"
            },
        ],
        "label": "A/C Status",
        "required": true,
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatedormantActivationCharge",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Dormant Activation Charge",
        "grid": 6,
    },


    {
        "varName": "fincoreDataUpdatesectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "onKeyDown": true,
        "required": true,
        //inputData.fincoreDataUpdatesectorCode=inputData.nationality
        "label": "Sector Code",
        "grid": 6,
    },


    {
        "varName": "fincoreDataUpdatesubSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //subsector code
        "label": "Sub Sector Code",
        "required": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "sectorCodeApicall",
        "conditionalVarValue": true,
    },

    {
        "varName": "fincoreDataUpdateoccupationCode",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3",
        "type": "textApiCall",
        //rm code
        "label": "RM Code/ Wealth Manager Code",
        "grid": 6,
    },
    {
        "varName": "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3RmName",
        "type": "text",
        //rm code
        "label": "RM Name",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall",
        "conditionalVarValue": true,
    },

    {
        "varName": "fincoreDataUpdatefreeText3",
        "type": "text",
        "label": "Free Text 3 ",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCode6",
        "type": "text",
        "label": "Free Code 6",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatedepositCodeFreeCode7",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Deposit Code/Free Code 7",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCode8",
        "type": "text",
        "label": "Free Code 8",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeText10",
        "type": "text",
        "label": "Free Text 10",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCodeFreeText1",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCodeFreeText2",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCodeFreeText3",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    }


];

let jsonFormForCbAndAccount = [
    {
        "type": "title",
        "label": "MIS RELATED",
        "grid": 12,
    },

    /* {
         "varName": "misprimarySolId",
         "type": "text",
         "label": "Primary Sol ID",
         "grid": 6,
         "required": true,
     },*/


    {
        "varName": "miscustomerIdCifId",
        "type": "text",
        //"required": true,
        "label": "Customer ID/CIF ID",
        "grid": 6,
        "readOnly": true,
        // "required": true,
    },


    {
        "varName": "mistitle",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //mistitle=inputData.customerTitle
        "label": "Title",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "miscustomerNameLastName",
        "type": "text",
        "required": true,
        "validationB": "length",
        "maxLength": 80,
        //inputData.miscustomerNameLastName=inputData.customerName;
        "label": "Customer Name/Last Name",
        "grid": 6,
    },

    {
        "varName": "misshortName",
        "type": "text",
        "required": true,
        "validationB": "length",
        "maxLength": 10,
        //inputData.misshortName=inputData.shortName;

        "label": "Short Name",
        "grid": 6,
    },

    /*  {
          "varName": "misgender",
          "type": "select",
          //"required": true,
          "enum": [
              "M",
              "F",
              "TRANSGENDER",
              "OTHER"
          ],
          "label": "Gender",
          "grid": 6,
          "required": true,
      },*/

    {
        "varName": "miscustomerStatusNormalStaffPriorityEtc",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Customer Status",
        //inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.customerStatus
        "grid": 6,
        "required": true,
    },

    {
        "varName": "misoccupationCodePriorityCodeStaff",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 6,
        "required": true,
        //
        /*  if(this.state.inputData.occupationCodeNormal!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodeNormal
      }
      else if(this.state.inputData.occupationCodePriority!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodePriority
      }
      else if(this.state.inputData.occupationCodeStaff!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodeStaff
      }*/
    },

    {
        "varName": "misconstitution",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //master data cbl provide
        "label": "Constitution",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "misstaffIndicatorStatus",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "label": "Staff Indicator/Status",
        //inputData.misstaffIndicatorStatus=inputData.staffIndicator
        "grid": 6,


    },

    {
        "varName": "misstaffIdNumber",
        "type": "text",
        "label": "Staff ID Number",
        "validation": "staffId",
        //inputData.misstaffIdNumber=inputData.staffIdNumber
        "grid": 6,

    },

    {
        "varName": "mispriorityCenterCodeFreeCode1",
        "type": "select",
        "enum": [
           "PR104",
            "PR142",
            "PR177",
            "PR186",
            "PR187",
            "PR195",
            "PR208",
            "PR169",
        ],
        //inputData.mispriorityCenterCodeFreeCode1=inputData.priorityCenterCode;
        "label": "Priority Center Code/Free Code 1",
        "grid": 6,
    },

    {
        "varName": "mispriorityMonitoringRmCodeFreeCode3",
        "type": "textApiCall",
        "enum": [],
        //inputData.mispriorityMonitoringRmCodeFreeCode3=inputData.monitoringRMCode
        "label": "Priority Monitoring  RM Code",
        "grid": 6,
    },
    {
        "varName": "mispriorityMonitoringRmCodeFreeCode3Name",
        "type": "text",
        //inputData.mispriorityMonitoringRmCodeFreeCode3=inputData.monitoringRMCode
        "label": "Priority Monitoring  RM Code Name",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "mispriorityMonitoringRmCodeFreeCode3ApiCall",
        "conditionalVarValue": true,
        "grid": 6,
    },

    {
        "varName": "misprimaryCbHolderForPriorityFreeText8",
        "type": "text",
        //inputData.misprimaryCbHolderForPriorityFreeText8=inputData.primaryCBHolderforPriority
        "label": "Primary CB Holder for Priority",
        "grid": 6,
    },

    {
        "varName": "misrelationshipWithPrimaryCbHolderFreeText9",
        "type": "text",
        //inputData.misrelationshipWithPrimaryCbHolderFreeText9=inputData.relationshipwithPrimaryCBHolder;
        "label": "Relationship with Primary CB Holder",
        "grid": 6,
    },


    {
        "varName": "misbbkSegmentCodeFreeCode7",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "BBK Segment Code",
        "grid": 6,
        "required": true,
    },


    {
        "varName": "mismigratedNidVerificationFreeText2",
        "type": "text",
        "label": "Migrated NID Verification",
        "grid": 6,
    },

    {
        "varName": "mismigratedSmartCardNumberFreeText5",
        "type": "text",
        "label": "Migrated Smart Card Number",
        "grid": 6,
    },

    {
        "varName": "mispepStatusFreeText10",
        "type": "text",
        "label": "PEP Status",
        "grid": 6,
    },

    {
        "varName": "mismigratedCifsDocumentNoFreeText13",
        "type": "text",
        "label": "Migrated CIFs Document No",
        "grid": 6,
    },


    {
        "varName": "misfreeCodeFreeText1",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText2",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText3",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misavailedTradeServices",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Availed Trade services",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Currency Code",
        "grid": 12,
    },

    {
        "varName": "aof1currencyCodeCcy1",
        "type": "text",
        //inputData.aof1currencyCodeCcy=inputData.currency
        "label": "Existing Currency Code/CCY",
        "grid": 6,
        "readOnly": true,
        //"readOnly":true,
        "conditional": true,
        "conditionalVarName": "aof1currencyCodeCcyExist",
        "conditionalVarValue": true,
    },
    {
        "varName": "aof1currencyCodeCcy1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.aof1currencyCodeCcy=inputData.currency
        "label": "Existing Currency Code/CCY",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "aof1currencyCodeCcyExist",
        "conditionalVarValue": false,
        //"readOnly":true,
    },
    /*  {
          "varName": "aof1currencyCodeCcy",
          "type": "autoCompleteValueReturn",
          "enum": [],
          //inputData.aof1currencyCodeCcy=inputData.currency
          "label": "New Currency Code/CCY",
          "grid": 6,
          "required": true,

          //"readOnly":true,
      },*/
    {
        "type": "title",
        "label": "Contact Number - Email",
        "grid": 12,
    },
    {
        "varName": "contactNumberEmailpreferedEmailIdType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "COMMEML",
            "value": "COMMEML"
        }],
        "readOnly": true,
        "label": "Prefered Email ID type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailType1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "COMMEML",
            "value": "COMMEML"
        }],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId4",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "EMAIL",
            "value": "EMAIL"
        }],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmaildesignatedEmailAddressEmailId1",
        "type": "text",
        "label": "Designated Email Address/Email ID 1",
        "grid": 6,
    },
    {
        "varName": "contactNumberEmaildesignatedEmailAddressEmailId1Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,


    },
    {
        "type": "title",
        "label": "Contact Number - Phone",
        "grid": 12,
    },
    {
        "varName": "contactNumberpreferredContactNoType1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH1",
            "value": "COMMPH1"
        }],
        "label": "Preferred Contact no type",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 1",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH1",
            "value": "COMMPH1"
        }],
        "label": "Contact Type",
        "grid": 6,
        "readOnly": true,
    },

    {
        "varName": "contactNumberphoneNoEmailId1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No./Email ID",
        "grid": 6,
        "readOnly": true,
    },
    {
        "varName": "mobileTypecontactNumberphoneNo1",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required": true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType1",
        "conditionalVarValue": "COMMPH1",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Overseas",
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo1",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo1",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Overseas",

    },


    {
        "type": "title",
        "label": "Primary Introducer details",
        "grid": 12,
    },
    {
        "varName": "nidVerified",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "required": true,
        "label": "Is All NID/Smart Card Document Verified?",
        "grid": 6,

    },
    {
        "varName": "primryintroducerCustomerIdCifId",
        "type": "textApiCall",
        //inputData.primryintroducerCustomerIdCifId=inputData.introducerId
        "label": "Introducer Customer Id/CIF ID",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },


    {
        "varName": "primryiintroducerNameLastName",
        "type": "text",
        //inputData.primryiintroducerNameLastName=inputData.introducerName
        "label": "Introducer Name/Last Name",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["introducerIdApiCall", "nidVerified"],
        "conditionalArrayValue": [true, "NO"]

    },

    {
        "varName": "primryiintroducerStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Introducer Status",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },

    {
        "varName": "primryicifTypePrimaryIntroducerDetails",
        "type": "select",
        "enum": [
            "Retail",
            "Corporate",
        ],
        "label": "CIF Type/Primary Introducer Details",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },
    {
        "type": "empty",
        "grid": 12,
    },

    {
        "varName": "crmMissubSegment",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Segment",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Basic Information Update",
        "grid": 12,
    },
    {
        "varName": "basicInformationUpdateprimarySolId",
        "type": "text",
        "enum": [],
        "readOnly": true,
        "label": "Primary Sol ID",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdategender",
        "type": "select",
        "enum": [
            "M",
            "F",
            "TRANSGENDER",
            "OTHER"
        ],
        "label": "Gender",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdatefatherNamePrefferedName",
        "type": "text",
        "label": "Father Name/Preffered Name",
        "validationB": "length",
        "maxLength": 50,
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdatemotherNameMothersMaidenName",
        "type": "text",
        "label": "Mother Name/Mothers Maiden Name",
        "grid": 6,
        "validationB": "length",
        "maxLength": 50,
    },

    {
        "varName": "basicInformationUpdatemaritalStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Marital Status",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "basicInformationUpdatespouseNamePurgeRemarks",
        "type": "text",
        "label": "Spouse Name/Purge Remarks",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "basicInformationUpdatemaritalStatus",
        "conditionalVarValue": "MARID",
        "validationB": "length",
        "maxLength": 240,

    },

    {
        "varName": "basicInformationUpdatedob",
        "type": "date",
        "maxDate": true,
        "onKeyDown": true,
        //inputData.basicInformationUpdatedob=inputData.gender
        "label": "DOB",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdateminorStatusMinorIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "readOnly": true,
        "label": "Minor Status/Minor Indicator",
        "conditional": true,
        "conditionalVarName": "basicInformationUpdatedobApiCall",
        "conditionalVarValue": "YES",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateseniorCitizenIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "readOnly": true,
        "label": "Senior Citizen Indicator",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdatedobApiCall",
        "conditionalVarValue": "YES",
        "required": true,
    },
    {
        "type": "title",
        "label": "Gurdian Details - Only Visible In Case Minor Account Holder",
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",

        "grid": 12,
    },


    {
        "varName": "guardianDetailsrelation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation *",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsbankRelationType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Bank Relation Type *",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsrelation1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation *",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsguardianSelectionGurdian",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Guardian Selection/Gurdian",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsgurdianCifNumberCustomer",
        "type": "textApiCall",
        "label": "Gurdian CIF Number/Customer *",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailslastName",
        "type": "text",
        "enum": [],
        "label": "Last Name *",
        "grid": 6,
        "required": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "guardianDetailsgurdianCifNumberCustomerApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "type": "title",
        "label": "National ID Card",
        "grid": 12,
    },
    {
        "varName": "natioanlIdCarddocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "natioanlIdCarddocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "natioanlIdCarddocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "NID",
            "value": "NID CARD IDENTITY"
        }],
        "label": "Document Code ",
        "grid": 6,

    },

    {
        "varName": "nationalIdCardNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "National ID Card Number/Unique ID",
        "grid": 6,
        "validation": "nid",
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "varName": "natioanlIdCardnidVerificationConfirmationIsDocumentVerified1",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "varName": "natioanlIdCardissueDateN",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },
    {
        "varName": "natioanlIdCardDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "type": "title",
        "label": "Smart ID Card",
        "grid": 12,
    },
    {
        "varName": "smartIdCarddocumentTypeS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "smartIdCarddocumentTypeDescriptionS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "smartIdCarddocumentCodeS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "RSNID",
            "value": "RSNID"
        }],
        "label": "Document Code ",
        "grid": 6,
        //"required": true,
    },

    {
        "varName": "smartCardNumberUniqueId",
        "type": "text",
        "label": "Smart Card Number/Unique ID",
        "grid": 6,
        //"required": true,
        "validation": "smartCard",
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"

    },

    {
        "varName": "smartIdCardnidVerificationConfirmationIsDocumentVerified",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },

    {
        "varName": "smartIdCardissueDateS",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },
    {
        "varName": "smartIdCardDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },
    {
        "type": "title",
        "label": "Passport Number",
        "grid": 12,
    },
    {
        "varName": "passportNumberdocumentTypeP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "passportNumberdocumentTypeDescriptionP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        //inputData.passportNumberdocumentTypeDescriptionP=inputData.passport
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "passportNumberdocumentCodeP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "PASSPORT",
            "value": "PASSPORT IDENTITY"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "passportNumberpassportNumberUniqueId",
        "type": "textApiCall",
        "label": "Passport Number/Unique ID",
        "grid": 6,
        "validation": "passport",
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberplaceOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Place of Issue",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberissueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumbercountryOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country of Issue",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberexpiryDate",
        "type": "date",
        "label": "Expiry Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },
    {
        "varName": "passportNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },
    {
        "type": "title",
        "label": "Driving License",
        "grid": 12,
    },
    {
        "varName": "drivingLicensedocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "drivingLicensedocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "drivingLicensedocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "DL",
            "value": "DRIVING LICENSE"
        },],
        "label": "Document Code",
        "grid": 6,

    },

    {
        "varName": "drivingLicenseNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "Driving ID Card Number/Unique ID",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "drivingLicensedocumentCodeN",
        "conditionalVarValue": "DL"
    },
    {
        "varName": "drivingLicenseNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "drivingLicensedocumentCodeN",
        "conditionalVarValue": "DL"
    },

    {
        "type": "title",
        "label": "Resident ID",
        "grid": 12,
    },
    {
        "varName": "residentdocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "residentdocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "residentdocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "RESID",
            "value": "RESIDENT ID CARD"
        },],
        "label": "Document Code ",
        "grid": 6,

    },

    {
        "varName": "residentNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "Resident ID",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "residentdocumentCodeN",
        "conditionalVarValue": "RESID"
    },
    {
        "varName": "residentNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "residentdocumentCodeN",
        "conditionalVarValue": "RESID"
    },
    {
        "type": "title",
        "label": "Birth Certificate",
        "grid": 12,
    },
    {
        "varName": "birthCertificatedocumentTypeB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "birthCertificatedocumentTypeDescriptionB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "birthCertificatedocumentCodeB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "BC",
            "value": "BIRTH CERTIFICATE"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "birthCertificatebirthCertificateNumberUniqueId",
        "type": "text",
        "label": "Birth Certificate Number/Unique ID",
        //inputData.birthCertificatebirthCertificateNumberUniqueId=inputData.birthCertificateNo
        "grid": 6,
        "validation": "birthCertificateNumber",
        "required": true,
        "masterData": true,
        "conditionalVarName": "birthCertificatedocumentCodeB",
        "conditionalVarValue": "BC"
    },
    {
        "varName": "birthCertificateDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "birthCertificatedocumentCodeB",
        "conditionalVarValue": "BC"
    },
    {
        "type": "title",
        "label": "Chairman Certificate",
        "grid": 12,
    },
    {
        "varName": "chairmanCertificatedocumentTypeCC",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "chairmanCertificatedocumentTypeDescriptionCC",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "chairmanCertificatedocumentCodeCC",
        "type": "autoCompleteValueReturn",
        "enum": [{"masterDataName": "documentCode", "key": "CHAC", "value": "CHAIRMAN CERTIFICATE"}],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificateUniqueId",
        "type": "text",
        "label": "Chairman Certificate/Unique ID",
        "grid": 6,
        "validationB": "length",
        "maxLength": 20,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatedocumentCodeCC",
        "conditionalVarValue": "CHAC"
    },
    {
        "varName": "chairmanCertificateDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatedocumentCodeCC",
        "conditionalVarValue": "CHAC"
    },


    {
        "type": "title",
        "label": "Other Photo ID",
        "grid": 12,
    },


    {
        "varName": "photoIddocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "photoIddocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "photoIddocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "photoIdUniqueId",
        "type": "text",
        "label": "Unique ID",
        "grid": 6,
    },
    {
        "varName": "photoIdDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
    },
    {
        "type": "empty",
        "grid": 12,

    },
    {
        "varName": "chairmanCertificateplaceOfBirthFreeText14",
        "type": "text",
        "label": "Place of Birth/Free Text 14",
        "grid": 6,

    },

    {
        "varName": "chairmanCertificatecountryOfBirthFreeText15",
        "type": "text",
        "label": "Country of Birth/Free Text 15",
        "grid": 6,

    },

    {
        "varName": "Customersnationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Nationality",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "chairmanCertificatenonResident",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Non Resident",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "chairmanCertificateturnedNonResidentOn",
        "type": "date",
        "label": "Turned Non Resident On",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },
    {
        "type": "title",
        "label": "E-Tin",
        "grid": 12,
    },
    {
        "varName": "etindocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "etindocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "etindocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "etineTinNumber",
        "type": "text",
        //inputData.etineTinNumber=inputData.eTin
        "label": "E-Tin Number/Unique ID",
        "grid": 6,
        "validation": "etin",
        "required": true,
        "masterData": true,
        "conditionalVarName": "etindocumentCode",
        "conditionalVarValue": "ETIN"
    },
    {
        "varName": "etinDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "etindocumentCode",
        "conditionalVarValue": "ETIN"
    },
    {
        "type": "title",
        "label": "Employer Data",
        "grid": 12,
    },
    {
        "varName": "employerDataprofessionEmploymentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "employmentType",
            "key": "MEDIA CELEBRITY",
            "value": "MEDIA CELEBRITY"
        }],
        "label": "Profession /Employment Type",
        "grid": 6,
    },


    {
        "varName": "employerDatanameOfEmployerEmployerName",
        "type": "text",
        "label": "Name of Employer/Employer Name",
        "grid": 6,
    },

    {
        "varName": "employerDatamonthlyIncomeGrossIncome",
        "type": "text",
        "label": "Monthly Income/Gross income",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 2",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType2",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH2",
            "value": "COMMPH2"
        }],
        "label": "Contact Type",
        "grid": 6,

    },

    {
        "varName": "contactNumberphoneNoEmailId2",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No.",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2"
    },
    {
        "varName": "mobileTypecontactNumberphoneNo2",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required": true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Overseas",
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo2",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo2",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Overseas",

    },
    {
        "varName": "contactNumber2Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2"
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 3",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType3",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "WORKPH1",
            "value": "WORKPH1"
        }],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNoEmailId3",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No.",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1"
    },

    /*  {
          "varName": "contactNumberphoneNo3",
          "type": "text",
          "validation": "numeric",
          "label": "Phone No",
          "grid": 6,
          "required": true,
          "masterData": true,
          "conditionalVarName": "contactNumbercontactType3",
          "conditionalVarValue": "WORKPH1"
      },*/

    {
        "varName": "mobileTypecontactNumbercontactType3",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required": true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo3",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Overseas",
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo3",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo3",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Overseas",

    },


    {
        "varName": "contactNumber3Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1"
    },

    {
        "type": "title",
        "label": "Contact Number - Email",
        "grid": 12,
    },

    {
        "varName": "contactNumberEmailemailType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "HOMEEML",
            "value": "HOMEEML"
        }],
        "required": true,
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "EMAIL",
            "value": "EMAIL"
        }],
        "label": "Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailId2",
        "type": "text",
        "label": "Email ID 2",
        "grid": 6,
    },
    {
        "varName": "contactNumberEmailemailId2Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "required": true,
        "label": "Delete Flag",
        "grid": 6,
    },

    {
        "type": "title",
        "label": "Mailing Address",
        "grid": 12,
    },
    {
        "varName": "maillingAdresspreferredAddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        //inputData.maillingAdresspreferredAddressType=inputData.SelectCommunicationAddress

        "label": "Preferred Address type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        "label": "Addrss type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressmailingAddressField1",
        "type": "text",
        //inputData.maillingAdressmailingAddressField1=inputData.communicationAddress1
        "label": "Mailing Address Field 1",
        "validationB": "length",
        "maxLength": 45,
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressmailingAddressField2",
        "type": "text",
        //inputData.maillingAdressmailingAddressField2=inputData.communicationAddress2
        "label": "Mailing Address Field 2",
        "validationB": "length",
        "maxLength": 45,
        "grid": 6,
    },

    {
        "varName": "maillingAdresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdresscity=inputData.city2

        "label": "City",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdressstate=inputData.state2

        "label": "Division(State)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        //inputData.maillingAdresspostalCode=inputData.postalCode3
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdresscountry=inputData.nationality
        "label": "Country",
        "grid": 6,
        "required": true,
    },

    {
        "type": "title",
        "label": "Home Address",
        "grid": 12,
    },
    {
        "varName": "homeAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "required": true,
        "label": "Address Format",
        "grid": 6,

    },

    {
        "varName": "homeAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Home",
            "value": "Home"
        }],
        "onKeyDown": true,
        "label": "Address type",
        "grid": 6,

    },

    {
        "varName": "homeAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Home",
            "value": "Home"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,

    },

    {
        "varName": "homeAddresshomeAddressField1",
        "type": "text",
        "label": "Home Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresshomeAddressField2",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],

        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },
    {
        "varName": "homeAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },
    {
        "type": "title",
        "label": "Work Address",
        "grid": 12,
    },
    {
        "varName": "workAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "workAddressaddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Work",
            "value": "Work"
        }],
        "label": "Address type",
        "grid": 6,
    },

    {
        "varName": "workAddressaddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Work",
            "value": "Work"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "workAddressworkAddressField1",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddressworkAddressField2",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "masterData": true,
        "required": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },
    //1present address
    {
        "type": "title",
        "label": "Present Address",
        "grid": 12,
    },
    {
        "varName": "presentAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "required": true,
        "grid": 6,
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Future/OnSite",
            "value": "Future/OnSite"
        }],
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Future/OnSite",
            "value": "Future/OnSite"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "presentAddresspresentAddressField1",
        "type": "text",
        "label": "Present Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"

    },

    {
        "varName": "presentAddresspresentAddressField2",
        "type": "text",
        "label": "Present Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },
    {
        "varName": "presentAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    }, //1NRE address
    {
        "type": "title",
        "label": "NRE Address",
        "grid": 12,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },
    {
        "varName": "nreAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "masterDataName": "addressType",
                "key": "NRERelative",
                "value": "NRERelative"
            }
        ],
        "label": "Addrss type",
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
        "grid": 6,
    },

    {
        "varName": "nreAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "NRERelative",
            "value": "NRERelative"
        }],
        "label": "Address label",
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
        "grid": 6,
    },

    {
        "varName": "nreAddressnreAddressField1",
        "type": "text",
        "label": "NRE Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"

    },

    {
        "varName": "nreAddressnreAddressField2",
        "type": "text",
        "label": "NRE Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"

    },
    {
        "varName": "nreAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },
    //account

    {
        "type": "title",
        "label": "Fincore Data Update - Account Level",
        "grid": 12,
    },
    /* {
         "varName": "fincoreDataUpdateaCReportCode",
         "type": "text",
         "label": "A/C Report Code",
        "grid": 6,
        "required": true,
     },*/

    /* {
         "varName": "fincoreDataUpdatecustomerRelationshipACManager",
         "type": "text",
         "label": "Customer Relationship A/C Manager",
        "grid": 6,
        "required": true,
     },*/

    /* {
         "varName": "fincoreDataUpdateaCManagerId",
         "type": "text",
         "label": "A/C Manager ID",
        "grid": 6,
        "required": true,
     },*/

    /* {
         "varName": "fincoreDataUpdatecontactPhoneNo",
         "type": "text",
         "label": "Contact Phone No.",
        "grid": 6,
        "required": true,
     },*/

    {
        "varName": "fincoreDataUpdatecashLimitDr",
        "type": "text",
        "validation": "numeric",
        "label": "Cash Limit (Dr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatecashLimitCr",
        "type": "text",
        "validation": "numeric",
        "label": "Cash Limit (Cr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdateclearingLimitDr",
        "type": "text",
        "validation": "numeric",
        "label": "Clearing Limit (Dr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdateclearingLimitCr",
        "type": "text",
        "validation": "numeric",
        "label": "Clearing Limit (Cr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatetransferLimitDr",
        "type": "text",
        "validation": "numeric",
        "label": "Transfer Limit (Dr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatetransferLimitCr",
        "type": "text",
        "validation": "numeric",
        "label": "Transfer Limit (Cr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatenotes",
        "type": "text",
        "label": "Notes",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdateaccountStatement",
        "type": "select",
        "enum": [
            "S-STATEMENT",
            "NONE",
        ],
        "label": "Account Statement",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "grid": 12,
    },

    {
        "type": "title",
        "label": "Statement Frequency",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdateaccountStatement",
        "conditionalVarValue": "S-STATEMENT",
    },
    {
        "varName": "fincoreDataUpdatestatementFrequency1",
        "type": "select",
        "enum": [
            "Half Yearly",
            "Yearly",
            "Quarterly",
            "Monthly",
        ],
        "grid": 3,
        "required": true,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdateaccountStatement",
        "conditionalVarValue": "S-STATEMENT",
    },
    {
        "varName": "fincoreDataUpdatestatementFrequency2",
        "type": "select",
        "enum": day,
        "grid": 3,
        "required": true,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdateaccountStatement",
        "conditionalVarValue": "S-STATEMENT",
    },
    {
        "varName": "fincoreDataUpdatestatementFrequency3",
        "type": "select",
        "enum": [
            "Next Day",
            "Holiday",
            "Previous Day",
            "Skip",
        ],
        "grid": 3,
        "required": true,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdateaccountStatement",
        "conditionalVarValue": "S-STATEMENT",
    },

    /*{
        "varName": "fincoreDataUpdatedispatchMode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Dispatch Mode",
        "grid": 6,
    },*/

    /*  {
          "varName": "fincoreDataUpdatenextPrintDate",
          "type": "date",
          "label": "Next Print Date",
          "grid": 6,
      },*/

    /* {
         "varName": "fincoreDataUpdateinterestCreditAC",
         "type": "text",
         "label": "Interest Credit A/c.",
        "grid": 6,
        "required": true,
     },*/

    /*  {
          "varName": "fincoreDataUpdatewithholdingTaxBorneBy",
          "type": "text",
          "label": "Withholding Tax Borne By",
         "grid": 6,
        "required": true,
      },
  */
    /*  {
          "varName": "fincoreDataUpdatewithholdingTaxLevel",
          "type": "text",
          "label": "Withholding Tax Level",
         "grid": 6,
        "required": true,
      },*/

    /* {
         "varName": "fincoreDataUpdatewithholdingTaxPcnt",
         "type": "text",
         "label": "Withholding Tax Pcnt.",
        "grid": 6,
        "required": true,
     },*/

    {
        "varName": "fincoreDataUpdateaCStatus",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "masterDataName": "accountStatus",
                "key": "A",
                "value": "Active"
            },
            {
                "masterDataName": "accountStatus",
                "key": "D",
                "value": "Dormant"
            },
            {
                "masterDataName": "accountStatus",
                "key": "I",
                "value": "Inactive"
            },
        ],
        "label": "A/C Status",
        "required": true,
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatedormantActivationCharge",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Dormant Activation Charge",
        "grid": 6,
    },

    /* {
         "varName": "fincoreDataUpdatenomineeSelect",
         "type": "text",
         "label": "Nominee Select",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateaCInformation",
         "type": "text",
         "label": "A/c. Information ",
         "grid": 6,
     },
 */
    {
        "varName": "fincoreDataUpdatesectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "onKeyDown": true,
        "required": true,
        //inputData.fincoreDataUpdatesectorCode=inputData.nationality
        "label": "Sector Code",
        "grid": 6,
    },


    {
        "varName": "fincoreDataUpdatesubSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //subsector code
        "label": "Sub Sector Code",
        "required": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "sectorCodeApicall",
        "conditionalVarValue": true,
    },

    {
        "varName": "fincoreDataUpdateoccupationCode",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3",
        "type": "textApiCall",
        //rm code
        "label": "RM Code/ Wealth Manager Code",
        "grid": 6,
    },
    {
        "varName": "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3RmName",
        "type": "text",
        //rm code
        "label": "RM Name",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall",
        "conditionalVarValue": true,
    },

    {
        "varName": "fincoreDataUpdatefreeText3",
        "type": "text",
        "label": "Free Text 3 ",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCode6",
        "type": "text",
        "label": "Free Code 6",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatedepositCodeFreeCode7",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Deposit Code/Free Code 7",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCode8",
        "type": "text",
        "label": "Free Code 8",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeText10",
        "type": "text",
        "label": "Free Text 10",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCodeFreeText1",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCodeFreeText2",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCodeFreeText3",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    }
]
let jsonFormForTpKyc = [

    {
        "type": "title",
        "label": "TP and KYC",
        "grid": 12,
        "readOnly": true,
    },
    {
        "varName": "functionCode",
        "type": "select",
        "enum": [
            "ADD",
            "UPDATE",
            "DELETE",
        ],
        "label": "Function Code",
        "grid": 6,
        "readOnly": true,
    },
    {
        "varName": "TPcustomerType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Customer Type",
        "grid": 6,
        "readOnly": true,
    },
    {
        "varName": "customerAccount",
        "type": "text",
        "validation": "numeric",
        "label": "Customer Account",
        "grid": 6,
        "readOnly": true,
    },
    {
        "varName": "TpEntityType",
        "type": "select",
        "enum": [
            "INDIVIDUL",
            "NON IND",
            "GOVT"
        ],
        "label": "Entity Type",
        "grid": 6,
        "readOnly": true,
    },

    {
        "varName": "aCTitle",
        "type": "text",
        "label": "A/C Title",
        "grid": 6,
        "readOnly": true,
    },
    {
        "varName": "TPandKycAccountType",
        "type": "autoCompleteValueReturn",
        "enum": [
            /* {
                 "masterDataName": "accountType",
                 "key": "Savings A/C",
                 "value": "Savings A/C Risk-(1)"
             },
             {
                 "masterDataName": "accountType",
                 "key": "Current A/C",
                 "value": "Current A/C Risk-(4)"
             },
             {
                 "masterDataName": "accountType",
                 "key": "Foreign Currency A/C",
                 "value": "Foreign Currency A/C Risk-(5)"
             },
             {
                 "masterDataName": "accountType",
                 "key": "SND A/C",
                 "value": "SND A/C Risk-(3)"
             },
             {
                 "masterDataName": "accountType",
                 "key": "RFCD A/C",
                 "value": "RFCD A/C Risk-(5)"*/

        ],
        "label": "Account Type",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "purposeOfAcOpening",
        "type": "text",
        "label": "Purpose of A/C Opening",
        "grid": 6,
    },
    {
        "varName": "customersOccupation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Customer's Occupation",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "othersOccupation",
        "type": "text",
        "label": "Others Occupation",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "customersOccupation",
        "conditionalVarValue": "others",
    },
    {
        "varName": "customerProbableMonthlyIncome",
        "type": "text",
        "label": "Customer probable monthly income",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "sourceOfFundKyc",
        "type": "text",
        "label": "Source of Fund",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "howTheSourcesOfFundHaveBeenVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "How the Source/Sources of Fund have been verified?",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "othersDocumentsCollectedToEnsureTheSourceOfFund",
        "type": "text",
        "label": "Others Documents Collected to Ensure the Source of Fund",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "howTheSourcesOfFundHaveBeenVerified",
        "conditionalVarValue": "others",
    },
    {
        "varName": "collectedDocumentsHaveBeenVerifiedOrNot",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Collected Documents have been verified or not?",
        "grid": 6,
    },
    {
        "varName": "howTheAddressOfTheAccountHolderHasBeenVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "How the address (es) of the account holder has been Verified?",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "othersDocumentTakenToVerifyAddress",
        "type": "text",
        "enum": [],
        "label": "Others Document taken to Verify Address",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "howTheAddressOfTheAccountHolderHasBeenVerified",
        "conditionalVarValue": "others",
    },
    {
        "varName": "hasTheBeneficialOwnerOfTheAccountBeenIdentified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Has the Beneficial owner of the account been identified",
        "grid": 6,
    },

    {
        "varName": "nameOfBeneficiarOwner",
        "type": "text",
        "label": "Name of Beneficial Owner",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "hasTheBeneficialOwnerOfTheAccountBeenIdentified",
        "conditionalVarValue": "1",
    },
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "varName": "passportNo",
        "type": "text",
        "label": "Passport no",
        "grid": 6,
    },
    {
        "type": "empty",
        "grid": 6,
    },
    {
        "varName": "passportCopyReceived",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Copy Received",
        "grid": 6,
    },
    {
        "varName": "passportVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Verified",
        "grid": 6,
    },

    {
        "varName": "nationalIdCardNo",
        "type": "text",
        "label": "National ID Card No:",
        "grid": 6,

    },
    {
        "type": "empty",
        "grid": 6,
    },
    {
        "varName": "nationalIdCardNoCopyReceived",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Copy Received",
        "grid": 6,
    },
    {
        "varName": "nationalIdCardNoVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Verified",
        "grid": 6,
    },
    {
        "varName": "birthCertificateNo",
        "type": "text",
        "label": "Birth Certificate No:",
        "grid": 6,

    },
    {
        "type": "empty",
        "grid": 6,
    },
    {
        "varName": "birthCertificateNoCopyReceived",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Copy Received",
        "grid": 6,
    },
    {
        "varName": "birthCertificateNoVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Verified",
        "grid": 6,
    },
    {
        "varName": "electronicTaxId",
        "type": "textApiCall",
        "label": "Electronic Tax ID",
        "grid": 6,
    },
    {
        "type": "empty",
        "grid": 6,
    },
    {
        "varName": "electronicTaxIdCopyReceived",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Copy Received",
        "grid": 6,
    },
    {
        "varName": "electronicTaxIdVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Verified",
        "grid": 6,
    },
    {
        "varName": "otherDocumentation",
        "type": "text",
        "label": "Other Documentation",
        "grid": 6,
    },
    {
        "type": "empty",
        "grid": 6,
    },
    {
        "varName": "otherDocumentationCopyReceived",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Copy Received",
        "grid": 6,
    },
    {
        "varName": "otherDocumentationVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Verified",
        "grid": 6,
    },
    {
        "varName": "customerNonresidentForeigner",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Customer is a Non-resident or Foreigner",
        "grid": 6,
    },
    {
        "varName": "reasonForOpeningTheAccountOfNonResidentAndForeigner",
        "type": "text",
        "label": "Reason for Opening the Account of Non-resident and Foreigner",
        "masterData": true,
        "conditionalVarName": "customerNonresidentForeigner",
        "conditionalVarValue": "Y",
        "grid": 6,
    },
    {
        "varName": "typeOfVisa",
        "type": "text",
        "label": "Type of VISA",
        "masterData": true,
        "conditionalVarName": "customerNonresidentForeigner",
        "conditionalVarValue": "Y",
        "grid": 6,
    },

    {
        "varName": "expDate",
        "type": "date",
        "label": "Exp date",
        "masterData": true,
        "conditionalVarName": "customerNonresidentForeigner",
        "conditionalVarValue": "Y",
        "grid": 6,
    },
    {
        "varName": "isWorkPermitPermissionOpenAc",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Is Work permit and permission to open A/C from appropiate authority is taken for opening the A/C of work permit holder?",
        "masterData": true,
        "conditionalVarName": "customerNonresidentForeigner",
        "conditionalVarValue": "Y",
        "grid": 6,
    },
    {
        "varName": "isTheCustomerPepHeadHigOfficialIntlOrg",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Is the customer a PEP/IP/ Head or high official of intl org (as per BFIU Circular defination)? *",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "pepHasTheApproval",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "Has the approval been taken from senior management?",
        "masterData": true,
        "conditionalVarName": "isTheCustomerPepHeadHigOfficialIntlOrg",
        "conditionalVarValue": "5",
        "grid": 6,
    },
    {
        "varName": "pepHasInterviewed",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "Has interviewed the customer face to face?",
        "masterData": true,
        "conditionalVarName": "isTheCustomerPepHeadHigOfficialIntlOrg",
        "conditionalVarValue": "5",
        "grid": 6,
    },
    {
        "varName": "customerFamily",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Is the customer a family members or close associates of any PEP/IP/ Head or high official of intl org? ",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "customerFamilyHasTheApproval",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "Has the approval been taken from senior management?",
        "masterData": true,
        "conditionalVarName": "customerFamily",
        "conditionalVarValue": "5",
        "grid": 6,
    },
    {
        "varName": "customerFamilyHasInterviewed",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "Has interviewed the customer face to face?",
        "masterData": true,
        "conditionalVarName": "customerFamily",
        "conditionalVarValue": "5",
        "grid": 6,
    },
    {
        "varName": "customerFamilyIsThereAnyMatchToTheCustomer",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Is there any match to the customer name with the terrorist activity, funding in terrorist activities or listed person or entity on suspicion for Financing of proliferation of weapons of mass destruction under UN Security Council's different regulations and any listed person or banned entity by the Government of Bangladesh according to related laws, rules and circular?",
        "grid": 6,
    },
    {
        "varName": "regardingThisIssue",
        "type": "text",
        "label": "If Yes, Then Details of action taken regarding this issue:",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "customerFamilyIsThereAnyMatchToTheCustomer",
        "conditionalVarValue": "Yes",
    },
    //TP
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "type": "titleBold",
        "label": "Deposit",
        "grid": 3,
    },

    {
        "type": "titleBold",
        "label": "No. of Deposit (Monthly)",
        "grid": 3,
    },
    {
        "type": "titleBold",
        "label": "Total Deposit Amount (Monthly)",
        "grid": 3,
    },
    {
        "type": "titleBold",
        "label": "Maximum Amount of Deposit (Per Transaction)",
        "grid": 3,
    },
    {
        "type": "straightLine",
        "grid": 12,
    },
    //
    {
        "type": "titleBlack",
        "label": "Cash Deposit (Including Online/ATM Deposit)",
        "grid": 3,
    },
    {
        "varName": "cashDepositNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "cashDepositTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "cashDepositMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Deposit by Transfer/Instrument",
        "grid": 3,
    },
    {
        "varName": "depositbyNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositbyTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositbyMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Foreign Inward Remittance",
        "grid": 3,
    },
    {
        "varName": "foreignInwardNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "foreignInwardTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "foreignInwardMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Deposit of Income from Export",
        "grid": 3,
    },
    {
        "varName": "depositofIncomeNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositofIncomeTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositofIncomeMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Deposit/Transfer from BO Account",
        "grid": 3,
    },
    {
        "varName": "depositTransferNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositTransferTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositTransferMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "others",
        "grid": 3,
    },
    {
        "varName": "othersNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "othersTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "othersMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Total Probable Deposit",
        "grid": 3,
    },
    {
        "varName": "totalProbableNumberOfCash",
        "type": "text",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "totalProbableNumberOfCashApiCall",
        "conditionalVarValue": true,
        "grid": 3,
    },
    {
        "varName": "totalProbableTotalCash",
        "type": "text",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "totalProbableTotalCashApiCall",
        "conditionalVarValue": true,
        "grid": 3,
    },
    {
        "varName": "totalProbableMaximumCash",
        "type": "text",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "totalProbableMaximumCashApiCall",
        "conditionalVarValue": true,
        "grid": 3,
    },
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "type": "titleBold",
        "label": "Withdrawal",
        "grid": 3,
    },

    {
        "type": "titleBold",
        "label": "No. of Withdrawal (Monthly)",
        "grid": 3,
    },
    {
        "type": "titleBold",
        "label": "Total Withdrawal Amount (Monthly)",
        "grid": 3,
    },
    {
        "type": "titleBold",
        "label": "Maximum Amount of Withdrawal (Per Transaction)",
        "grid": 3,
    },
    {
        "type": "straightLine",
        "grid": 12,
    },
    //
    {
        "type": "titleBlack",
        "label": "Cash Withdrawal (Including Online/ATM Withdrawal)",
        "grid": 3,
    },

    {
        "varName": "cashWithdrawalNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "cashWithdrawalTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "cashWithdrawalmaximumAmount",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Withdrawal through Transfer/Instrument",
        "grid": 3,
    },

    {
        "varName": "withdrawalthroughNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "withdrawalthroughTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "withdrawalthroughmaximumAmount",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Foreign Outward Remittance",
        "grid": 3,
    },

    {
        "varName": "foreignOutwardNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "foreignOutwardTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "foreignOutwardmaximumAmount",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Payment Against Import",
        "grid": 3,
    },

    {
        "varName": "paymentAgainstNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "paymentAgainstTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "paymentAgainstmaximumAmount",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Deposit/Transfer to BO Account",
        "grid": 3,
    },

    {
        "varName": "depositTransferNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositTransferTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositTransfermaximumAmount",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "others",
        "grid": 3,
    },

    {
        "varName": "othersNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "othersTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "othersmaximumAmount",
        "type": "text",
        "grid": 3,
    },
    {
        "type": "empty",
        "grid": 12,
    },

    //
    {
        "type": "titleBlack",
        "label": "Total Probable Withdrawal",
        "grid": 3,
    },

    {
        "varName": "totalProbableNumberofWithdrawal",
        "type": "text",
        "conditional": true,
        "conditionalVarName": "totalProbableNumberofWithdrawalApiCall",
        "conditionalVarValue": true,
        "readOnly": true,
        "grid": 3,
    },
    {
        "varName": "totalProbableTotalWithdrawal",
        "type": "text",
        "conditional": true,
        "conditionalVarName": "totalProbableTotalWithdrawalApiCall",
        "conditionalVarValue": true,
        "readOnly": true,
        "grid": 3,
    },
    {
        "varName": "totalProbablemaximumAmount",
        "type": "text",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "totalProbablemaximumAmountApiCall",
        "conditionalVarValue": true,
        "grid": 3,
    },
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "varName": "yearlyPropableTransaction",
        "type": "text",
        "label": "Yearly Propable Transaction",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "totalProbableTotalWithdrawalApiCall",
        "conditionalVarValue": true,
        "readOnly": true
    },
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "varName": "autoPopulationSummation",
        "type": "submit",
        "event": false,
        "label": "Auto Population Summation",
        "conditional": true,
        "conditionalVarName": "makerEndTpAndKyc",
        "conditionalVarValue": true,
        "grid": 6,

    },
    {
        "type": "empty",
        "grid": 12,
    },

    {
        "varName": "riskRatingcalculation",
        "type": "submit",
        "event": false,
        "label": "Risk Rating calculation",
        "conditional": true,
        "conditionalVarName": "makerEndTpAndKyc",
        "conditionalVarValue": true,
        "grid": 6,

    },
    {
        "type": "empty",
        "grid": 6,

    },
    {
        "type": "title",
        "label": "Risk Rating",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
    },
    {
        "type": "title",
        "label": "Product/Service and Channel Risk",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
    },
    {
        "varName": "typeProductService",
        "type": "autoCompleteValueReturn",
        "enum": [
            /*  {
                  "masterDataName": "accountType",
                  "key": "Savings A/C",
                  "value": "Savings A/C Risk-(1)"
              },
              {
                  "masterDataName": "accountType",
                  "key": "Current A/C",
                  "value": "Current A/C Risk-(4)"
              },
              {
                  "masterDataName": "accountType",
                  "key": "Foreign Currency A/C",
                  "value": "Foreign Currency A/C Risk-(5)"
              },
              {
                  "masterDataName": "accountType",
                  "key": "SND A/C",
                  "value": "SND A/C Risk-(3)"
              },
              {
                  "masterDataName": "accountType",
                  "key": "RFCD A/C",
                  "value": "RFCD A/C Risk-(5)"
              }*/
        ],
        "readOnly": true,
        "label": "Type of Product/Service",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "required": true,
    },
    {
        "varName": "typeOnboardingAccountOpening",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Type of Onboarding/Account Opening",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "required": true,
    },
    {
        "type": "title",
        "label": "Geographical Risk",
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "grid": 12,
    },
    {
        "varName": "nonResidentCustomerRisk",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Non-Resident Customer Risk",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "required": true,
    },
    {
        "varName": "forForeignNationals",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "For Foreign Resident",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "nonResidentCustomerRisk",
        "conditionalVarValue": "3",
    },
    {
        "type": "title",
        "label": "Relationship Risk",
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "grid": 12,
    },
    {
        "varName": "accordingBfiuCustomer",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": " According to BFIU Circular, whether the Customer is a PEP/IP/ Head or high officials of intl. org.? ",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "required": true,
    },
    {
        "varName": "accordingBfiuFamily",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "According to the BFIU Circular, whether the Customer is a family member or close associate of PEP/IP/ Head or high officials of intl. org.?",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "required": true,
    },
    {
        "type": "title",
        "label": "Transactional Risk",
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "grid": 12,
    },
    {
        "varName": "averageAmountOfYearlyTransactions",
        "type": "text",
        "label": "Average Amount of Yearly Transactions (in TK)",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
    },
    {
        "type": "title",
        "label": "Transparency Risk",
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "grid": 12,
    },
    {
        "varName": "customerProvidedReliableInformation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Has the customer provided reliable information on the source of fund?",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
    },
    {
        "type": "title",
        "label": "Business or Service Related Risk ",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
    },
    {
        "varName": "selectFromTheList",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Select from the list",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
    },
    {
        "varName": "selectFromTheListOther",
        "type": "text",
        "label": "Other",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "selectFromTheList",
        "conditionalVarValue": "Others",
    },
    {
        "varName": "selectFromTheListNumeric",
        "type": "text",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "selectFromTheList",
        "conditionalVarValue": "Others",
    },
    {
        "varName": "listBusinessService",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Add from the list for more business or service",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        // "required": true,
    }, {
        "varName": "listBusinessServiceOther",
        "type": "text",
        "label": "Other",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "listBusinessService",
        "conditionalVarValue": "Others",
    },
    {
        "varName": "listBusinessService1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Add from the list for more business or service",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        // "required": true,
    }, {
        "varName": "listBusinessService2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Add from the list for more business or service",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        // "required": true,
    },

    {
        "varName": "overallRiskAssesment",
        "type": "text",
        "readOnly": true,
        "label": "Overall Risk Assesment",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "overallRiskFieldShow",
        "conditionalVarValue": true,

    },
    {
        "varName": "riskType",
        "type": "text",
        "readOnly": true,
        "label": "Risk Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "overallRiskFieldShow",
        "conditionalVarValue": true,

    },
    {
        "varName": "comments",
        "type": "text",
        "label": "Comments",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,

    },
];

const tagForm = [
    {
        "type": "title",
        "label": "BASIC INFORMATION UPDATE",
        "grid": 6,
    },
    {
        "varName": "basicInformationUpdateGender",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Gender",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateFatherName",
        "type": "text",
        "label": "Father Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateMotherName",
        "type": "text",
        "label": "Mother Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateMaritalStatus",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Marital Status",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateSpouseName",
        "type": "text",
        "label": "Spouse Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateDob",
        "type": "text",
        "label": "DOB",
        "grid": 6,
    },
    {
        "varName": "basicInformationUpdateDobMinor",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "minor",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "GUARDIAN DETAILS",
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "grid": 12,

    },
    {
        "varName": "guardianDetailsBankRelationType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "label": "Bank Relation Type",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsGurdianCifNumber",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "label": "Gurdian CIF Number",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsGuardianSelection",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "label": "Guardian Selection",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsDocumentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsDocumentCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsNationalIdCardnumber",
        "type": "text",
        "label": "National ID CardNumber",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsNidVerificationConfirmationisDocumentVerified",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsDocumentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsDocumentCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsSmartCardNumber",
        "type": "text",
        "label": "Smart Card Number",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsNidVerificationConfirmationisDocumentVerified",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
    },

    {
        "varName": "documentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "documentCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "passportNumber",
        "type": "text",
        "label": "Passport Number",
        "grid": 6,
    },

    {
        "varName": "issueDate",
        "type": "text",
        "label": "Issue Date",
        "grid": 6,
    },

    {
        "varName": "countryOfIssue",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Country of Issue",
        "grid": 6,
    },

    {
        "varName": "expiryDate",
        "type": "text",
        "label": "Expiry Date",
        "grid": 6,
    },

    {
        "varName": "documentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "documentCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "birthCertificateNumber",
        "type": "text",
        "label": "Birth Certificate Number",
        "grid": 6,
    },

    {
        "varName": "placeOfBirthfreeText14",
        "type": "text",
        "label": "Place of Birth/Free Text 14",
        "grid": 6,
    },

    {
        "varName": "countryOfBirthfreeText15",
        "type": "text",
        "label": "Country of Birth/Free Text 15",
        "grid": 6,
    },

    {
        "varName": "nationality",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],

        "label": "Nationality",
        "grid": 6,
    },

    {
        "varName": "residingCountry",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Residing Country",
        "grid": 6,
    },

    {
        "varName": "nonResident",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Non Resident",
        "grid": 6,
    },

    {
        "varName": "minorStatus",
        "type": "text",
        "label": "Minor Status",
        "grid": 6,
    },

    {
        "varName": "eTinNumber(PAN/GIR No)",
        "type": "text",
        "label": "E-Tin Number (PAN/GIR No)",
        "grid": 6,
    },

    {
        "varName": "professionEmploymentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Profession /Employment Type",
        "grid": 6,
    },

    {
        "varName": "nameOfEmployer",
        "type": "text",
        "label": "Name of Employer",
        "grid": 6,
    },

    {
        "varName": "monthlyIncome",
        "type": "text",
        "label": "Monthly Income",
        "grid": 6,
    },

    {
        "varName": "contactType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "phoneNoemailId",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "phoneNo",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "contactType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "phoneNoemailId",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "phoneNo",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "contactType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "phoneNoemailId",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "phoneNo",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "emailType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "emaildesignatedEmailAddress1",
        "type": "text",
        "label": "Email/Designated Email Address 1",
        "grid": 6,
    },

    {
        "varName": "emailType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "emailId2",
        "type": "text",
        "label": "Email ID 2",
        "grid": 6,
    },

    {
        "varName": "addressFormat",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "mailingAddressField1",
        "type": "text",
        "label": "Mailing Address Field 1",
        "grid": 6,
    },

    {
        "varName": "mailingAddressField2",
        "type": "text",
        "label": "Mailing Address Field 2",
        "grid": 6,
    },

    {
        "varName": "mailingAddressField3",
        "type": "text",
        "label": "Mailing Address Field 3",
        "grid": 6,
    },

    {
        "varName": "city",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "state",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "country",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "addressValidForm",
        "type": "text",
        "label": "Address Valid Form",
        "grid": 6,
    },

    {
        "varName": "addressFormat",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "homeAddressField1",
        "type": "text",
        "label": "Home Address Field 1",
        "grid": 6,
    },

    {
        "varName": "homeAddressField2",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 6,
    },

    {
        "varName": "homeAddressField3",
        "type": "text",
        "label": "Home Address Field 3",
        "grid": 6,
    },

    {
        "varName": "city",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "state",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "country",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "addressValidForm",
        "type": "text",
        "label": "Address Valid Form",
        "grid": 6,
    },

    {
        "varName": "addressFormat",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "workAddressField1",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 6,
    },

    {
        "varName": "workAddressField2",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 6,
    },

    {
        "varName": "workAddressField3",
        "type": "text",
        "label": "Work Address Field 3",
        "grid": 6,
    },

    {
        "varName": "city",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "state",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "country",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "addressValidForm",
        "type": "text",
        "label": "Address Valid Form",
        "grid": 6,
    },

    {
        "varName": "relationType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Relation Type",
        "grid": 6,
    },

    {
        "varName": "relationCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Relation Code",
        "grid": 6,
    },

    {
        "varName": "designationCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Designation Code",
        "grid": 6,
    },

    {
        "varName": "cifId",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "CIF ID",
        "grid": 6,
    },

    {
        "varName": "deleteFlag",
        "type": "text",
        "label": "Delete Flag",
        "grid": 6,
    },
];
let checkerRemarks = [
    {
        "varName": "checkerRemarks",
        "type": "textArea",
        "label": "Checker Remarks",
        "grid": 12
    }];
let objectForNominee = [];
let objectForNomineeForNew = [];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};
const firstStyle = {
    color: '#212F3D',
    background: '#f8f9fa'
};
var fileUpload = {
    "varName": "scanningFile",
    "type": "file",
    "label": "Upload File",
    "grid": 12
};


class CBAndAccountUpdateComponentForChecker extends React.Component {
    state = {
        message: "",
        appData: {},
        getData: false,
        varValue: [],
        redirectLogin: false,
        title: "",
        notificationMessage: "",
        alert: false,
        inputData: {},
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        values: [],
        selectedDate: {},
        showValue: false,
        customerName: [],
        deferalType: [],
        expireDate: [],
        other: [],
        getCheckerList: [],
        getDeferalList: [],
        fileUploadData: {},
        loaderNeeded: null,
        objectForJoinAccount: [],
        getgenerateForm: false,
        getTagList: [],
        tagCbDetailsModal: false,
        customerNumber: "",
        linkrelationCode: 0,
        loading: true,
        getRemarks: [],
        objectForNominee: [],
        objectForNomineeForNew: [],
        err: false,
        errorArray: {},
        errorMessages: {},
        numberOfSignatureCard: "",
        signatureCardError: false,
        getCropedImage: false,
        signatureButtonShowInImageCropedPage: false,
        confirmAlert: false,
        actionType: "",
        errorModalBoolean: false,
        errorTittle: "",
        getDownloadListFlag: false,
        downLoadFileList: [],
        needCheckForTag: [],
        fileNotFoundTittle: "",
        requestType: "",
        tagCbNumber: 0,
        signatureButtonFlag: false,
        showSignatureButton: true,
        linkedCB: false,
        apiButtonType: "",
        checkerApproval: "",
    };

    handleChange = (event) => {

        event.preventDefault();

        this.state.inputData[event.target.name] = event.target.value;


    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        /* for (var prop in clone)
             if (clone[prop] === ''|| clone[prop] === "null" || clone[prop] === "undefined")
                 //delete clone[prop];*/
        return clone;
    };
    renderDownLoadFileLink = () => {
        if (this.state.getData && this.state.getDownloadListFlag) {
            return (this.state.downLoadFileList.map((button) => {
                    return button
                })
            )

        } else {
            return <h5><b>{this.state.fileNotFoundTittle}</b></h5>
        }
    };
    downloadDocument = (name) => {
        let url = backEndServerURL + "/file/" + name + "/" + sessionStorage.getItem("accessToken");
        window.open(url)
    };
    createDownloadFileLink = (sl, data) => {
        return (
            <React.Fragment>
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}
                    onClick={() => this.downloadDocument(data)}
                >
                    {sl}
                </button>
                &nbsp;&nbsp;
            </React.Fragment>

        )
    };
    highLightsEmptyObject = (object) => {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            returnObjectVariables[i]["highlight"] = false;
        }
        return returnObject;


    };
    componentDidMount() {
        let remarksArray = [];
        let downLoadFileList = [];
        let url = backEndServerURL + "/case/files/pdfFilesFromImages/" + this.props.appId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((data, index) => {
                    downLoadFileList.push(this.createDownloadFileLink(`Document ${index + 1}`, data))
                });
                this.setState({
                    getDownloadListFlag: true,
                    downLoadFileList: downLoadFileList
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    fileNotFoundTittle: this.errorObjectCheck(error),
                })
            });
        if (this.props.appId !== undefined) {
            let jsonForm = jsonFormForCB.concat(objectForNomineeForNew, objectForNominee, jsonFormForAccount, jsonFormForTpKyc, jsonFormForCbAndAccount);
            this.highLightsEmptyObject(jsonForm)
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let sl;
                    let inputData1 = this.removeNullValue(response.data);

                    if(objectForNominee.length === 0){
                        if (inputData1["nomineeNumberOld"] !== undefined && inputData1["nomineeNumberOld"] !== null) {
                            objectForNominee.push(
                                {
                                    "type": "title",
                                    "label": "Existing Nominee Information",
                                    "grid": 12,
                                },
                                {
                                    "varName": "nomineeNumberOld",
                                    "type": "text",
                                    "readOnly": true,
                                    "label": "Existing Nominee",
                                    "grid": 6,
                                },
                                {
                                    "varName": "nomineeRegNo",
                                    "type": "text",
                                    "label": "Registration No",
                                    "grid": 6,
                                    "required": true,

                                },
                            )
                        }

                        for (let i = 0; i < inputData1["nomineeNumberOld"]; i++) {
                            sl = i + 1;
                            objectForNominee.push(
                                {
                                    "varName": "Nominee " + sl,
                                    "type": "title",
                                    "label": "Nominee  " + sl,
                                    "grid": 12,
                                },
                                {
                                    "varName": "search" + sl,
                                    "type": "select",
                                    "enum": [
                                        "CIF ID",
                                        "INFORMATION",
                                    ],
                                    "label": "Input CIF ID/INFORMATION ?",
                                    "grid": 6,


                                },
                                {
                                    "varName": "nomineecifId" + sl,
                                    "type": "textApiCall",
                                    "label": "CIF ID",
                                    "grid": 6,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "CIF ID",

                                },

                                {
                                    "varName": "nomineeName" + sl,
                                    "type": "text",
                                    "label": "Nominee Name",
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeName" + sl,
                                    "type": "text",
                                    "label": "Nominee Name",
                                    "grid": 6,
                                    required: true,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",

                                },
                                {
                                    "varName": "nomineeRelationship" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.nomineeRelationShipCodeList,
                                    "label": "Relationship",
                                    "grid": 6,
                                    "required": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],


                                },
                                {
                                    "varName": "nomineeRelationship" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.nomineeRelationShipCodeList,
                                    "label": "Relationship",
                                    "grid": 6,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                    required: true,

                                },
                                {
                                    "varName": "percentageNominee" + sl,
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    required: true,

                                },
                                {
                                    "varName": "percentageNominee" + sl,
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 6,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                    required: true,

                                },

                                {
                                    "varName": "nomineeAddress1" + sl,
                                    "type": "text",
                                    "label": "Address 1 ",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeAddress1" + sl,
                                    "type": "text",
                                    "label": "Address 1 ",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    required: true,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "nomineeAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                /*  {
                                        "varName": "nomineenomineephotoIdNo" + i,
                                        "type": "text",
                                        "label": "NOMINEE PHOTO ID NO.",
                                        "grid": 6,
                                        "conditional": true,
                                        "conditionalVarName": "nomineecifIdApiCall" + i,
                                        "conditionalVarValue": true,
                                    },
                    */
                                {
                                    "varName": "nomineeCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "nomineeStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {

                                    "varName": "nomineeStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "nomineePostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "nomineePostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },

                                {
                                    "varName": "nomineeCountry" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeCountry" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "photoIdNumberRegNo" + sl,
                                    "type": "text",
                                    "label": "Photo Id Number/Reg No",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "photoIdNumberRegNo" + sl,
                                    "type": "text",
                                    "label": "Photo Id Number/Reg No",
                                    "grid": 6,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "nomineeDob" + sl,
                                    "type": "text",
                                    "label": "D.O.B(YYYY-MM-DD)",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeDob" + sl,
                                    "type": "date",
                                    "label": "D.O.B",
                                    "grid": 6,
                                    "onKeyDown": true,
                                    "required": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },


                                {
                                    "varName": "nomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],

                                },
                                {
                                    "varName": "nomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    /*  "conditional": true,
                                      "conditionalVarName": "search" + sl,
                                      "conditionalVarValue": "INFORMATION",*/
                                },
                                {
                                    "type": "title",
                                    "label": "Guardian Details  " + sl,
                                    "grid": 12,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["CIF ID", "YES"],
                                },
                                {
                                    "varName": "nomineeGuardiansName" + sl,
                                    "type": "text",
                                    "label": "Guardians Name",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["CIF ID", "YES"],
                                    "multiline": true,
                                    "required": true,


                                },

                                {
                                    "varName": "nomineeGuardianCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.getGuardianCode,
                                    "label": "Guardian Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["CIF ID", "YES"],
                                    "required": true,

                                },
                                {
                                    "varName": "guardianPhotoId" + sl,
                                    "type": "text",
                                    "label": "Guardian Photo Id",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["CIF ID", "YES"],
                                },

                                {
                                    "varName": "nomineeGuardianAddress" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["CIF ID", "YES"],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeGuardianAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["CIF ID", "YES"],
                                    "multiline": true,
                                },

                                {
                                    "varName": "nomineeGuardianCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["CIF ID", "YES"],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["CIF ID", "YES"],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianPostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["CIF ID", "YES"],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianCountry2" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["CIF ID", "YES"],
                                    "required": true,
                                },

                                //Information guardian details
                                {
                                    "type": "title",
                                    "label": "Guardian Details  " + sl,
                                    "grid": 12,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["INFORMATION", "YES"],
                                },
                                {
                                    "varName": "nomineeGuardiansName" + sl,
                                    "type": "text",
                                    "label": "Guardians Name",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["INFORMATION", "YES"],
                                    "multiline": true,
                                    "required": true,


                                },

                                {
                                    "varName": "nomineeGuardianCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.getGuardianCode,
                                    "label": "Guardian Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["INFORMATION", "YES"],
                                    "required": true,

                                },
                                {
                                    "varName": "guardianPhotoId" + sl,
                                    "type": "text",
                                    "label": "Guardian Photo Id",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["INFORMATION", "YES"],
                                },

                                {
                                    "varName": "nomineeGuardianAddress" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["INFORMATION", "YES"],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeGuardianAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["INFORMATION", "YES"],
                                    "multiline": true,
                                },

                                {
                                    "varName": "nomineeGuardianCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["INFORMATION", "YES"],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["INFORMATION", "YES"],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianPostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["INFORMATION", "YES"],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianCountry2" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["INFORMATION", "YES"],
                                    "required": true,
                                },
                            )

                        }

                    }

                    if(objectForNomineeForNew.length === 0) {
                        if (inputData1["numberOfNominee"] !== undefined && inputData1["numberOfNominee"] !== null) {
                            objectForNomineeForNew.push(
                                {
                                    "type": "title",
                                    "label": "New Nominee",
                                    "grid": 12,
                                },

                                {
                                    "varName": "nomineeRegNo",
                                    "type": "text",
                                    "label": "Registration No",
                                    "grid": 6,
                                    "required": true,

                                },
                            )
                        }

                        for (var i = Number(inputData1["nomineeNumberOld"]); i < Number(Number(inputData1["numberOfNominee"]) + Number(inputData1["nomineeNumberOld"])); i++) {
                            sl = i + 1;
                            //inputData["nomineecifIdApiCall"+i]=false
                            //varValue["nomineecifIdApiCall"+i]=false
                            objectForNomineeForNew.push(
                                {
                                    "varName": "Nominee " + sl,
                                    "type": "title",
                                    "label": "Nominee  " + sl,
                                    "grid": 12,
                                },
                                {
                                    "varName": "search" + sl,
                                    "type": "select",
                                    "enum": [
                                        "CIF ID",
                                        "INFORMATION",
                                    ],
                                    "label": "Input CIF ID/INFORMATION ?",
                                    "grid": 6,


                                },
                                {
                                    "varName": "nomineecifId" + sl,
                                    "type": "textApiCall",
                                    "label": "CIF ID",
                                    "grid": 6,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "CIF ID",

                                },

                                {
                                    "varName": "nomineeName" + sl,
                                    "type": "text",
                                    "label": "Nominee Name",
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeName" + sl,
                                    "type": "text",
                                    "label": "Nominee Name",
                                    "grid": 6,
                                    required: true,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",

                                },
                                {
                                    "varName": "nomineeRelationship" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.nomineeRelationShipCodeList,
                                    "label": "Relationship",
                                    "grid": 6,
                                    "required": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],


                                },
                                {
                                    "varName": "nomineeRelationship" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.nomineeRelationShipCodeList,
                                    "label": "Relationship",
                                    "grid": 6,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                    required: true,

                                },
                                {
                                    "varName": "percentageNominee" + sl,
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    required: true,

                                },
                                {
                                    "varName": "percentageNominee" + sl,
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 6,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                    required: true,

                                },

                                {
                                    "varName": "nomineeAddress1" + sl,
                                    "type": "text",
                                    "label": "Address 1 ",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeAddress1" + sl,
                                    "type": "text",
                                    "label": "Address 1 ",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    required: true,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "nomineeAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                /*  {
                                        "varName": "nomineenomineephotoIdNo" + i,
                                        "type": "text",
                                        "label": "NOMINEE PHOTO ID NO.",
                                        "grid": 6,
                                        "conditional": true,
                                        "conditionalVarName": "nomineecifIdApiCall" + i,
                                        "conditionalVarValue": true,
                                    },
                    */
                                {
                                    "varName": "nomineeCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "nomineeStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {

                                    "varName": "nomineeStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "nomineePostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "nomineePostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },

                                {
                                    "varName": "nomineeCountry" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeCountry" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "photoIdNumberRegNo" + sl,
                                    "type": "text",
                                    "label": "Photo Id Number/Reg No",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "photoIdNumberRegNo" + sl,
                                    "type": "text",
                                    "label": "Photo Id Number/Reg No",
                                    "grid": 6,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "nomineeDob" + sl,
                                    "type": "text",
                                    "label": "D.O.B(YYYY-MM-DD)",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeDob" + sl,
                                    "type": "date",
                                    "label": "D.O.B",
                                    "grid": 6,
                                    "onKeyDown": true,
                                    "required": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },


                                {
                                    "varName": "nomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    /*    "conditional": true,
                                        "conditionalVarName": "search" + sl,
                                        "conditionalVarValue": "CIF ID",*/
                                },
                                {
                                    "varName": "nomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    /*  "conditional": true,
                                      "conditionalVarName": "search" + sl,
                                      "conditionalVarValue": "INFORMATION",*/
                                },
                                {
                                    "type": "title",
                                    "label": "Guardian Details  " + sl,
                                    "grid": 12,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["CIF ID", "YES"],
                                },
                                {
                                    "varName": "nomineeGuardiansName" + sl,
                                    "type": "text",
                                    "label": "Guardians Name",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["CIF ID", "YES"],
                                    "multiline": true,
                                    "required": true,


                                },

                                {
                                    "varName": "nomineeGuardianCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.getGuardianCode,
                                    "label": "Guardian Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["CIF ID", "YES"],
                                    "required": true,

                                },
                                {
                                    "varName": "guardianPhotoId" + sl,
                                    "type": "text",
                                    "label": "Guardian Photo Id",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["CIF ID", "YES"],
                                },

                                {
                                    "varName": "nomineeGuardianAddress" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["CIF ID", "YES"],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeGuardianAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["CIF ID", "YES"],
                                    "multiline": true,
                                },

                                {
                                    "varName": "nomineeGuardianCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["CIF ID", "YES"],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["CIF ID", "YES"],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianPostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["CIF ID", "YES"],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianCountry2" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["CIF ID", "YES"],
                                    "required": true,
                                },

                                //Information guardian details
                                {
                                    "type": "title",
                                    "label": "Guardian Details  " + sl,
                                    "grid": 12,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["INFORMATION", "YES"],
                                },
                                {
                                    "varName": "nomineeGuardiansName" + sl,
                                    "type": "text",
                                    "label": "Guardians Name",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["INFORMATION", "YES"],
                                    "multiline": true,
                                    "required": true,


                                },

                                {
                                    "varName": "nomineeGuardianCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.getGuardianCode,
                                    "label": "Guardian Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["INFORMATION", "YES"],
                                    "required": true,

                                },
                                {
                                    "varName": "guardianPhotoId" + sl,
                                    "type": "text",
                                    "label": "Guardian Photo Id",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["INFORMATION", "YES"],
                                },

                                {
                                    "varName": "nomineeGuardianAddress" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["INFORMATION", "YES"],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeGuardianAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["INFORMATION", "YES"],
                                    "multiline": true,
                                },

                                {
                                    "varName": "nomineeGuardianCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["INFORMATION", "YES"],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["INFORMATION", "YES"],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianPostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["INFORMATION", "YES"],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianCountry2" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                                    "conditionalArrayValue": ["INFORMATION", "YES"],
                                    "required": true,
                                },
                            )

                        }
                    }



                    let makerEdit = backEndServerURL + '/case/fields/updatedByMaker/' + this.props.appId;
                    axios.get(makerEdit,
                        {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response4) => {
                            let fieldVarName = response4.data;


                            for (let item of jsonForm) {
                                for (let singleItem of fieldVarName) {
                                    if (singleItem === item.varName) {
                                        item.highlight = true
                                    }
                                }
                            }

                            let fileUrl = backEndServerURL + "/applicationKeyValue/get";
                            let data = {};
                            data.appId = this.props.appId;
                            data.key = "getSignatureCardNumber";
                            axios.post(fileUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response1) => {
                                    let dataList = Number(response1.data.value);
                                    if (dataList !== undefined && dataList !== null && dataList > 0) {
                                        this.setState({
                                            signatureButtonFlag: true,
                                        })
                                    }
                                    this.setState({
                                        numberOfSignatureCard: Number(response1.data.value),
                                    });
                                    let checkerListUrl = backEndServerURL + "/checkers";
                                    axios.get(checkerListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response2) => {
                                            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                                            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                .then((response3) => {
                                                    console.log(response3.data);
                                                    response3.data.map((data) => {
                                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                                    });
                                                    let inputData = this.removeNullValue(response.data);
                                                    inputData.checkerRemarks = undefined;
                                                    this.renderTaggingApi();
                                                    setTimeout(() => {
                                                        this.setState({
                                                            getRemarks: remarksArray,
                                                            getCheckerList: response2.data,
                                                            varValue: this.removeNullValue(inputData),
                                                            inputData: inputData,
                                                            appData: response.data,
                                                            showValue: true,
                                                            getData: true,
                                                            loaderNeeded: true,
                                                            loading: false,
                                                        });
                                                    }, 2000)
                                                console.log(this.state.inputData.cbNumber)
                                                })
                                                .catch((error) => {
                                                    console.log(error)
                                                })
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                })
                                .catch((error) => {
                                    console.log("error");
                                });
                        })

                        .catch((error) => {
                            console.log(error)
                        });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
        }
        if (this.state.updateType === "Signature card Update") {
            this.renderSignature();
        }
    }

    highLightsEmptyObject = (object) => {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            returnObjectVariables[i]["highlight"] = false;
        }
        return returnObject;


    };

    renderTaggingApi = () => {
        let tableArrayData = [];
        let tableArray = [];
        let cbTagUrl = backEndServerURL + "/secondaryCB/" + this.props.appId;
        axios.get(cbTagUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((data, index) => {
                    if (data.requestType === "Completed") {
                        tableArray.push(data)
                    }
                    tableArrayData.push(this.createTaggingData(data));

                });

                this.setState({
                    needCheckForTag: tableArray,
                    getTagList: tableArrayData,
                })
            })
            .catch((error) => {
                console.log(error);
            })
    };


    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    close = () => {
        this.props.closeModal();
    };
    closeModal = () => {
        this.setState({
            getCropedImage: false,
            errorModalBoolean: false,
            signatureButtonShowInImageCropedPage: false,
        })
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };
    closeConfirmAlert = (data) => {
        this.setState({
            confirmAlert: false
        });
        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "APPROVED") {
            this.handleSubmit("APPROVED")


        } else if (data === "YES" && this.state.checkerApproval === "APPROVED") {
            if (this.state.apiButtonType === "Update Signature") {
                this.renderImageCrop();
            } else {
                this.handleSubmit("APPROVED")
            }

        } else if (data === "YES" && this.state.actionType === "NOTAPPROVED") {
            this.handleSubmit("RETURN")
        }

    };
    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()

        {
            this.validationForHandleConfirm(type)
        }


    };
    handleSignatureCard = (event, type) => {
        // event.preventDefault();
        if (this.state.signatureButtonFlag === true) {
            this.setState({
                loading: true,
            });
            let objectList = [];
            for (let i = 0; i < this.state.numberOfSignatureCard; i++) {
                let object = {
                    appId: this.props.appId,
                    customerId: this.state.inputData.cbNumber,
                    type: "CARD" + (i + 1),
                    accountNo: this.state.inputData.accountNumber,
                    remarks: this.state.inputData["imageRemarks" + i]
                };
                if (this.state.inputData["deleteFlag" + i] === "NO") {
                    objectList.push(object)
                }

            }
            console.log(objectList);
            let signatureUpload = backEndServerURL + "/signatureImage/uploadToFinacleBulk";
            axios.post(signatureUpload, objectList, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let inputData = this.state.inputData;
                    inputData.apiButton = "finalApproval";
                    inputData.signatureButtonAction = true;
                    this.variableSetFunction(inputData)
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        signatureCardError: false,
                        errorModalBoolean: true,
                        errorTittle: this.errorObjectCheck(error),
                    })
                })
        }


    };
    validationForHandleConfirm = (actionType) => {
        this.setState({
            loading: true,
        });
        let dependencyField = [];
        if (actionType === "NOTAPPROVED" || actionType === "RETURN_TO_CS") {
            dependencyField.push({

                "varName": "checkerRemarks",
                "type": "textArea",
                "required": true,
                "label": "Checker Remarks",
                "grid": 12
            })

        }
        this.setState({
            loading: false,
        });
        let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }

        this.setState({
            loading: false,
            confirmAlert: true,
            actionType: actionType
        })


    };
    variableSetFunction = (object) => {
        let variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    loading: false,
                    alowApiCall: true
                })
            })
            .catch((error) => {
                console.log(error)
            })

    };
    handleSubmit = (checker_approval) => {
        let saveDataDeferralRemarksAndRoute = {};
        if (checker_approval === "APPROVED") {
            if (this.state.needCheckForTag.length === this.state.getTagList.length) {

            } else {
                this.setState({
                    errorModalBoolean: true,
                    errorTittle: "Please Approve All Related Party Request!",
                });
                return 0;
            }
        }

        this.setState({
            loading: true
        });
        if (this.state.inputData.checkerRemarks !== undefined && this.state.inputData.checkerRemarks !== null) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.checkerRemarks;
        }

        this.state.inputData.checker_approval = checker_approval;

        if (this.state.fileUploadData.scanningFile !== undefined) {
            let fileUploadPath = backEndServerURL + "/case/upload";
            let types = 'Attachments';
            let files = this.state.fileUploadData.scanningFile;
            console.log("dfghjk");
            console.log(files);
            let formData = new FormData();
            formData.append("appId", this.props.appId);
            formData.append("file", files);
            formData.append("type", types);
            axios({
                method: 'post',
                url: fileUploadPath,
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'content-type': 'multipart/form-data'
                }
            })
                .then((response) => {

                    console.log(response);


                })
                .catch((error) => {
                    console.log(error)
                })
        }


        this.state.inputData.checker_approval = checker_approval;

        let variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, this.emptyValueRemove(this.state.inputData), {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let data = {};
                data.accountType = this.state.inputData.accType;
                if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
                    data.accountCategory = "individual";
                    data.accountType = this.state.inputData.accType;
                } else {
                    data.accountCategory = "nonindividual";
                    data.accountType = this.state.inputData.accTypes;
                }

                data.cbNumber = this.state.inputData.cbNumber;


                data.accountNumber = this.props.accountNumber;
                console.log(this.state.inputData);
                console.log(response.data);

                if (checker_approval === "APPROVED") {
                    if (this.state.inputData.apiButton === "updateAccountInfo") {
                        console.log(response.data);
                        let accountUpdateUrl = backEndServerURL + "/updateAccountInfo/" + this.props.appId + "/" + this.state.inputData.accType;
                        axios.get(accountUpdateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                let object = {};
                                object.accountNumber = this.state.inputData.accountNumber;
                                console.log(this.state.inputData.accountNumber)
                                console.log(this.state.inputData.fincoreDataUpdatedormantActivationCharge)
                                object.dormantActivationCharge = this.state.inputData.fincoreDataUpdatedormantActivationCharge;
                                if (this.state.inputData.fincoreDataUpdateaCStatus !== undefined) {
                                    object.accountStatus = (this.state.inputData.fincoreDataUpdateaCStatus.key);
                                }
                                console.log(object.accountStatus)
                                console.log(object)
                                let url = backEndServerURL + "/dormantAccountActivation";
                                axios.post(url, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {

                                    })
                                    .catch((error) => {
                                        console.log(error)
                                    });

                                if (this.state.inputData.misfunctionCodeForChequeBookWaiver === "A" || this.state.inputData.misfunctionCodeForChequeBookWaiver === "M") {
                                    let url = backEndServerURL + "/doAccountCheqeWaiver/" + this.state.inputData.accountNumber + "/" + this.state.inputData.misfunctionCodeForChequeBookWaiver + "/" + this.state.inputData.miswaiverTypeForChequeBookWaiver.key;
                                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response) => {
                                            let object = {};
                                            object.accountNumber = this.state.inputData.accountNumber;
                                            object.currency = this.state.inputData.aof1currencyCodeCcy;
                                            object.sol = (this.state.inputData.basicInformationUpdateprimarySolId);

                                            if (this.state.inputData.schemeCodeChangetargetSchemeCodeInCaseOfInstaPack !== undefined && this.state.inputData.schemeCodeChangetargetSchemeCodeInCaseOfInstaPack !== null) {
                                                object.schemeCode = (this.state.inputData.schemeCodeChangetargetSchemeCodeInCaseOfInstaPack.key);
                                                let url = backEndServerURL + "/account/changeScheme";
                                                axios.post(url, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                    .then((response) => {
                                                        let inputData = this.state.inputData;
                                                        inputData.apiButton = "finalApproval";
                                                        this.variableSetFunction(inputData)
                                                    })
                                                    .catch((error) => {

                                                        this.setState({
                                                            loading: false,
                                                            errorModalBoolean: true,
                                                            errorTittle: this.errorObjectCheck(error),
                                                        })

                                                    })

                                            } else {
                                                let inputData = this.state.inputData;
                                                inputData.apiButton = "finalApproval";
                                                this.variableSetFunction(inputData)
                                            }


                                        })
                                        .catch((error) => {
                                            this.setState({
                                                loading: false,
                                                errorModalBoolean: true,
                                                errorTittle: this.errorObjectCheck(error),
                                            })

                                        })
                                } else {
                                    let object = {};
                                    object.accountNumber = this.state.inputData.accountNumber;
                                    object.currency = this.state.inputData.aof1currencyCodeCcy;
                                    object.sol = (this.state.inputData.basicInformationUpdateprimarySolId);
                                    if (this.state.inputData.schemeCodeChangetargetSchemeCodeInCaseOfInstaPack !== undefined && this.state.inputData.schemeCodeChangetargetSchemeCodeInCaseOfInstaPack !== null) {
                                        object.schemeCode = (this.state.inputData.schemeCodeChangetargetSchemeCodeInCaseOfInstaPack.key);

                                        let url = backEndServerURL + "/account/changeScheme";
                                        axios.post(url, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                            .then((response) => {
                                                let inputData = this.state.inputData;
                                                inputData.apiButton = "finalApproval";
                                                this.variableSetFunction(inputData)
                                            })
                                            .catch((error) => {
                                                this.setState({
                                                    loading: false,
                                                    errorModalBoolean: true,
                                                    errorTittle: this.errorObjectCheck(error),
                                                })

                                            })
                                    } else {
                                        let inputData = this.state.inputData;
                                        inputData.apiButton = "finalApproval";
                                        this.variableSetFunction(inputData)
                                    }

                                }

                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    loading: false,
                                    errorModalBoolean: true,
                                    errorTittle: this.errorObjectCheck(error),
                                })
                            })
                    } else if (this.state.inputData.apiButton === "updateCustomerInfo") {

                        let updateUrl = backEndServerURL + "/updateCustomerInfo/" + this.props.appId;
                        /*  if (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C" && this.props.subServiceType !== "Joint Account") {

                              updateUrl = backEndServerURL + "/updateCorporateCustomer/" + this.props.appId;
                          }*/
                        axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                let inputData = this.state.inputData;
                                inputData.apiButton = "finalApproval";
                                this.variableSetFunction(inputData)
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    loading: false,
                                    errorModalBoolean: true,
                                    errorTittle: this.errorObjectCheck(error),
                                })
                            })


                    } else if (this.state.inputData.apiButton === "addAccountTpThenKYC") {
                        let tpKycApiUrl = backEndServerURL + "/addAccountTpThenKYC/" + this.props.appId;
                        axios.get(tpKycApiUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                let inputData = this.state.inputData;
                                inputData.apiButton = "finalApproval";
                                this.variableSetFunction(inputData)
                            })
                            .catch((error) => {
                                console.log(error);

                                this.setState({
                                    loading: false,
                                    errorModalBoolean: true,
                                    errorTittle: this.errorObjectCheck(error),
                                })
                            })

                    }
                        //working
                        /*else if (this.state.apiButton === "Update Signature") {
                            this.renderImageCrop();
                        }*/
                    //end
                    else if (this.state.inputData.apiButton === "finalApproval") {
                        if (this.state.signatureButtonFlag) {
                            if (this.state.inputData.signatureButtonAction === true) {
                                let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
                                axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        console.log(response.data);
                                        this.setState({
                                            title: "Successfull!",
                                            notificationMessage: "Successfully Routed!",
                                            alert: true,
                                            loading: false
                                        });
                                        this.props.closeModal()
                                        //
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        this.setState({
                                            loading: false
                                        })
                                    })
                            } else {
                                this.setState({
                                    loading: false,
                                    errorModalBoolean: true,
                                    errorTittle: "Please Upload The Signature Card!",
                                });
                                return 0;
                            }


                        } else {
                            let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
                            axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    console.log(response.data);
                                    this.setState({
                                        title: "Successfull!",
                                        notificationMessage: "Successfully Routed!",
                                        alert: true,
                                        loading: false
                                    });
                                    this.props.closeModal()
                                    //
                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.setState({
                                        loading: false
                                    })
                                })
                        }


                    }

                } else {
                    let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;

                    axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            this.setState({
                                title: "Successfull!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                loading: false
                            });
                            this.props.closeModal()
                            //
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false
                            })
                        })
                }
                /* })
                 .catch((error)=>{
                     console.log(error);
                     })
*/
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })

            });


    };
    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "Finacle System Error!"
                }
            } else {
                return "Finacle System Error!"
            }
        } else {
            return "Finacle System Error!"
        }
    };

    /*renderForm = () => {
         if (this.state.getData) {

             return (
                 CommonJsonFormComponent.renderJsonForm(this.state, this.props.jsonForm, this.updateComponent)

             )
         }
         return;
     } */
    handleSubmitSignature = (event, checker_approval, apiButtonType) => {
        console.log(apiButtonType)
        event.preventDefault();
        this.state.checkerApproval = checker_approval;
        this.state.inputData.checker_approval = checker_approval;
        if (checker_approval === "RETURN" || checker_approval === "SEND_TO_CS") {
            let error = MyValidation.defaultValidation(checkerRemarks, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
        }
        this.setState({
            title: "Do you want to confirm?",
            confirmAlert: true,

            apiButtonType: apiButtonType
        })
    };
    renderFileUpload = () => {
        if (this.state.getData) {
            return (

                <Grid item xs={12}>
                    {FileTypeComponent.file(this.state, this.updateComponent, fileUpload)}
                </Grid>

            )
        }

    };
    renderSignatureCardView = () => {
        return (
            <SignatureButton casaFlow={true} accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
                             classes={this.props}/>
        )


    };


    renderApproveButton = () => {
        return (
            <>
                &nbsp;&nbsp;&nbsp;

                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',
                        marginTop: 20
                    }}
                    disabled={this.state.disabled}
                    type='button' value='add more'
                    onClick={(event) => this.handleSubmit(event, "APPROVED", "Update Signature")}
                >Update Signature Card
                </button>
                &nbsp;&nbsp;&nbsp;


            </>
        )
    };

    renderSubmitButton = () => {

        return (
            <Grid item xs={12}>

                <div>


                    {this.state.inputData.apiButton === "updateCustomerInfo" && <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginTop: 20

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleCommon(event, "APPROVED")}

                    >CB update
                    </button>}
                    {this.state.inputData.apiButton === "updateAccountInfo" && this.state.inputData.updateType === "Account Update" &&
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginTop: 20

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleCommon(event, "APPROVED")}

                    >Account Update
                    </button>}
                    {/*this.state.inputData.updateType === "Signature card Update"*/}
                    {this.state.inputData.updateType === "Signature card Update" && <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginTop: 20
                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmitSignature(event, "APPROVED", "Update Signature")}
                    >Update Signature Card
                    </button>}


                    {this.state.inputData.apiButton === "addAccountTpThenKYC" && <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginTop: 20

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleCommon(event, "APPROVED")}

                    >TP & KYC Update
                    </button>}


                    {this.state.inputData.apiButton === "finalApproval" && <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginTop: 20

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleCommon(event, "APPROVED")}

                    >Final Approval
                    </button>}

                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginTop: 20

                        }}
                        checkerApproval

                        type='button' value='add more'
                        onClick={(event) => this.handleCommon(event, "NOTAPPROVED")}

                    >Return To Maker
                    </button>


                </div>


            </Grid>

        )

    };
    renderImageCrop = (event) => {
        // event.preventDefault();
        this.setState({
            loading: false,
            getCropedImage: true,
            signatureButtonShowInImageCropedPage: true
        })


    };
    handleImageCrop = (event) => {
        event.preventDefault();
        this.setState({
            getCropedImage: true,
            signatureButtonShowInImageCropedPage: true,
        })


    };
    renderDedupComponent = () => {
        if (this.state.getData  /* && (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C")*/) {
            return (
                <div style={{marginTop: "15px"}}>
                    <DedupResultFunction radioButton="NO" category={this.props.category} taggingForm={CBDataJsonForm}
                                         removingTaggingData={this.removingTaggingData}
                                         renderTagging={this.renderTagging}
                                         getTaggingData={this.getTaggingData}
                                         subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };
    renderDedupComponentMandateAndBeneficiary = () => {
        if (this.state.getData) {
            return (
                <div style={{marginTop: "15px"}}>
                    <MandateAndBeneficiaryResult radioButton="NO" category={this.props.category}
                                                 taggingForm={CBDataJsonForm}
                                                 removingTaggingData={this.removingTaggingData}
                                                 getTaggingData={this.getTaggingData}
                                                 subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };
    renderSdnComponent = () => {
        if (this.state.getData) {
            return (
                <div style={{marginTop: "15px"}}>
                    <SdnResultFunction radioButton="NO" category={this.props.category} taggingForm={CBDataJsonForm}
                                       removingTaggingData={this.removingTaggingData}
                                       getTaggingData={this.getTaggingData}
                                       subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };
    renderFlowSUmmeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.props.cbNumber} appId={this.props.appId}
                                   customerName={this.props.customerName} accountNumber={this.props.accountNumber}
                                   solId={this.props.solId}/>
            )
        }
    };
    renderDefferalData = () => {


        if (this.state.getDeferalList.length > 0) {

            return (
                <div style={{marginTop: "5px"}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>

                        <b>Deferral List:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferal Type", "Due Date", "Created By", "Application Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        }

    };
    tagCbDetailsModalClse = () => {
        this.setState({
            tagCbDetailsModal: false
        })
    };
    tagCbDetailsModal = (event, customerNumber, requestType, cbNumber, relationCode) => {
        event.preventDefault();
        this.setState({
            tagCbDetailsModal: true,
            customerNumber: customerNumber,
            requestType: requestType,
            tagCbNumber: cbNumber,
            linkrelationCode: relationCode
        });
        console.log(cbNumber)

    };
    createTaggingData = (data) => {
        if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C") {
            return (
                [data.cbNumber, data.customerName, data.relationCode, [<button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',
                    }}
                    type='button' value='add more'
                    onClick={(event) => this.tagCbDetailsModal(event, data.customer, data.requestType, data.cbNumber, data.relationCode)}
                >View
                </button>]]


            )
        } else {
            return (
                [data.cbNumber, data.customerName, data.relationCode, [<button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',
                    }}
                    type='button' value='add more'
                    onClick={(event) => this.tagCbDetailsModal(event, data.customer, data.requestType, data.cbNumber, data.relationCode)}
                >View
                </button>]]


            )
        }
    };


    renderTagList = () => {

        if (this.state.getData /*&& (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C")*/ && this.state.getTagList.length > 0) {

            return (

                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Link CB List</h4>

                        </CardHeader>
                    </paper>
                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["CB Number", "Customer Name", "Relation Code", "Action"]}

                        tableData={this.state.getTagList}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />


                </div>


            )
        }

    };

    renderJsonFormFirst = () => {
        if (this.state.getData) {
            return (
                <React.Fragment>
                    {this.renderLabel()}
                    {/*{this.renderForm()}
                    {this.renderTpKycForm()}
                    {CommonJsonFormComponent.renderJsonForm(this.state, bearer, this.updateComponent)}*/}
                </React.Fragment>
            )
        }
    };

    renderLabel = () => {
        if (this.state.inputData.accountNumber) {
            console.log(this.state.inputData)
            return (
                <React.Fragment>

                    <Label linkedCB={this.state.linkedCB} stopLoading={this.stopLoading} classes={this.props}
                           cbNumber={this.state.inputData.cbNumber}
                           accountNumber={this.state.inputData.accountNumber}
                           crmStatus={this.state.inputData.crmStatus}/>
                    <Grid item xs={12}></Grid>
                    {this.renderSignature()}
                </React.Fragment>
            )
        } else {

            this.renderSignature()
        }
    };

    stopLoading = (value) => {
        if (value === "yes") {
            this.setState({
                // getData: true,
                // showValue: true,
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };


    renderSignature = () => {
        // if (this.state.getData && this.state.showSignatureButton) {
        //     return <SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
        //                             classes={this.props}/>
        // }
        if (this.state.getData && this.state.showSignatureButton) {
            return <a className="btn btn-sm btn-danger"
                      style={{"color": "#6e2929", "margin-top": "0px", "margin-bottom": "0px"}}
                      onClick={() => this.openSignature(this.state.inputData.accountNumber)}>Signature Card View</a>
        }
    };

    openSignature = (accountNumber) => {
        let url = frontEndServerURL + "/signatureCardView/" + accountNumber;
        window.open(url)
    };

    renderJsonFormLast = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonFormLast, this.updateComponent)


            )
        } else {

        }
    };


    renderRemarks = () => {
        if (this.state.getData) {

            return (

                CommonJsonFormComponent.renderJsonForm(this.state, checkerRemarks, this.updateComponent)

            )
        }

    };
    renderPreviewImage = () => {
        if (this.state.getData) {
            return (
                <button
                    style={{}}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.handleImageCrop}>
                    Croped Image
                </button>

            )
        }
    };
    renderExistingNomineeForm = () => {

        if (this.state.getData && (this.state.inputData.updateType === "Account Update" || this.state.inputData.updateType === "CB and Account Update" || this.state.inputData.updateType === "Tp and Kyc Update") && (this.state.accountDataPopulate || this.props.appId !== undefined)) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(objectForNominee), this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
            )
        }
    };
    renderNewNomineeForm = () => {
        if (this.state.getData && (this.state.inputData.updateType === "Account Update" || this.state.inputData.updateType === "CB and Account Update" || this.state.inputData.updateType === "Tp and Kyc Update") && (this.state.accountDataPopulate || this.props.appId !== undefined)) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(objectForNomineeForNew), this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
            )
        }


    };
    onKeyDownChange = (data) => {
        for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
            if (data.varName === "nomineeDob" + (i + 1)) {
                this.setState({
                    loading: true
                });
                this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                //  this.forceUpdate()
                setTimeout(() => {
                    let x = 18;
                    let currentDate = new Date();
                    let someDate = new Date();
                    let numberOfDaysToAdd = x;
                    someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let convertDate = year + "-" + month + "-" + date;
                    let newDate = new Date(convertDate);
                    let stringToDate = new Date(newDate.toDateString());
                    let inputDate = new Date(this.state.inputData["nomineeDob" + (i + 1)]);
                    if (stringToDate >= inputDate) {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "NO";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = true;
                        // this.state.varValue["nomineeMinor" + i] == "NO";
                    } else {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "YES";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = true;


                    }

                    this.forceUpdate()
                }, 500);
                this.setState({
                    showValue: true,
                    getData: true,
                    loading: false
                })


            }
        }

        let inputData = this.state.inputData;
        let varValue = this.state.varValue;
        if (data.varName === "homeAddressaddrssType") {

            if (this.state.inputData.homeAddressaddrssType === {
                "masterDataName": "addressType",
                "key": "Mailing",
                "value": "Mailing"
            }) {
                this.state.inputData.homeAddressFieldShow = false;
                this.state.varValue.homeAddressFieldShow = false;
                Functions.sleep(500).then(() => {
                    this.state.inputData.homeAddresshomeAddressField1 = this.state.inputData.maillingAdressmailingAddressField1;
                    this.state.varValue.homeAddresshomeAddressField1 = this.state.inputData.maillingAdressmailingAddressField1;

                    this.state.inputData.homeAddresshomeAddressField2 = this.state.inputData.maillingAdressmailingAddressField2;
                    this.state.varValue.homeAddresshomeAddressField2 = this.state.inputData.maillingAdressmailingAddressField2;

                    this.state.inputData.homeAddresscity = this.state.inputData.maillingAdresscity;
                    this.state.varValue.homeAddresscity = this.state.inputData.maillingAdresscity;

                    this.state.inputData.homeAddressstate = this.state.inputData.maillingAdressstate;
                    this.state.varValue.homeAddressstate = this.state.inputData.maillingAdressstate;

                    this.state.inputData.homeAddresspostalCode = this.state.inputData.maillingAdresspostalCode;
                    this.state.varValue.homeAddresspostalCode = this.state.inputData.maillingAdresspostalCode;

                    this.state.inputData.homeAddresscountry = this.state.inputData.maillingAdresscountry;
                    this.state.varValue.homeAddresscountry = this.state.inputData.maillingAdresscountry;
                    this.state.inputData.homeAddressFieldShow = true;
                    this.state.varValue.homeAddressFieldShow = true;
                    this.forceUpdate()
                })
            }


        } else if (data.varName === "autoPopulationSummation") {
            this.handleTPPopulateSummation()
        } else if (data.varName === "riskRatingcalculation") {
            this.handleRiskRating()
        } else if (data.varName === "fincoreDataUpdatesectorCode") {
            this.state.inputData.sectorCodeApicall = false;
            let codeUrl = backEndServerURL + "/finMasterData/custom/subSector/" + this.state.inputData.fincoreDataUpdatesectorCode["key"];//{sectorCode}
            axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    {
                        this.findByVarNameApiData("fincoreDataUpdatesubSectorCode", response.data)
                    }
                    this.state.inputData.sectorCodeApicall = true;
                    this.forceUpdate()


                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (data.varName === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3" || data.varName === "mispriorityMonitoringRmCodeFreeCode3") {
            let getData = "";
            if (data.varName === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3") {
                getData = "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3"
            } else {
                getData = "mispriorityMonitoringRmCodeFreeCode3"

            }
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + getData;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        errorMessage: "RM Code Is Not Found,Please again Try!",
                        errorModalBoolean: true
                    })
                })
                .catch((error) => {
                    console.log(error)

                })
        } else if (data.varName == "basicInformationUpdatedob") {
            this.state.inputData.basicInformationUpdatedobApiCall = "NO";
            this.state.varValue.basicInformationUpdatedobApiCall = "NO";
            this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N";
            this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N";
            //this.updateComponent()
            //if(data.value==="basicInformationUpdatedob"){
            setTimeout(() => {
                let x = 18;
                var currentDate = new Date();

                let someDate = new Date();
                let numberOfDaysToAdd = x;
                someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                let date = someDate.getDate();
                let month = someDate.getMonth() + 1;
                let year = someDate.getFullYear();
                let convertDate = year + "-" + month + "-" + date;
                let newDate = new Date(convertDate);
                let stringToDate = new Date(newDate.toDateString());

                let inputDate = new Date(this.state.inputData.basicInformationUpdatedob);
                console.log(stringToDate);
                console.log(this.state.inputData.basicInformationUpdatedob);
                console.log(inputDate);


                if (stringToDate >= inputDate) {
                    this.state.inputData.basicInformationUpdatedobApiCall = "YES";
                    this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N";
                    this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
                } else {
                    this.state.inputData.basicInformationUpdatedobApiCall = "YES";
                    this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "Y";
                    this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "Y"
                }

                x = 60;
                currentDate = new Date();
                someDate = new Date();
                numberOfDaysToAdd = x;
                someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                date = someDate.getDate();
                month = someDate.getMonth() + 1;
                year = someDate.getFullYear();
                convertDate = year + "-" + month + "-" + date;
                newDate = new Date(convertDate);
                stringToDate = new Date(newDate.toDateString());

                inputDate = new Date(this.state.inputData.basicInformationUpdatedob);

                if (stringToDate >= inputDate) {
                    inputData.basicInformationUpdateseniorCitizenIndicator = "Y";
                    varValue.basicInformationUpdateseniorCitizenIndicator = "Y"


                } else {

                    inputData.basicInformationUpdateseniorCitizenIndicator = "N";
                    varValue.basicInformationUpdateseniorCitizenIndicator = "N"
                }


                this.forceUpdate()

            }, 500)

        }
        /*    for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
                if (data === "nomineecifId" + i) {
                    this.setState({
                        loading: true
                    })
                    this.state.inputData["nomineecifIdApiCall"+i] = false;
                    this.state.varValue["nomineecifIdApiCall"+i] = false;
                             console.log(response.data)
                             let x = 60;
                            let currentDate = new Date();
                            let someDate = new Date();
                            let numberOfDaysToAdd = x
                            someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                            let date = someDate.getDate();
                            let month = someDate.getMonth() + 1;
                            let year = someDate.getFullYear();
                            let convertDate = year +"-" + month + "-" + date;
                            let newDate = new Date(convertDate);
                            let stringToDate = new Date(newDate.toDateString());

                            let inputDate = new Date(response.data.birthDate);
                            this.state.inputData["nomineeDob" + i]=(response.data.birthDate).split("T")[0]
                            this.state.varValue["nomineeDob" + i]=(response.data.birthDate).split("T")[0]
                            if (stringToDate >= inputDate) {
                                this.state.inputData["nomineeMinor" + i] = "YES";
                                this.state.varValue["nomineeMinor" + i] = "YES";

                                // this.state.varValue["nomineeMinor" + i] == "NO";
                            } else {

                                this.state.inputData["nomineeMinor" + i] = "NO";
                                this.state.varValue["nomineeMinor" + i] = "NO";

                                //this.state.varValue["nomineeMinor" + i] == "YES";
                            }

                            /!*this.state.inputData["nomineeGuardiansName" + i] =response.data.
                            this.state.inputData["nomineeGuardianCode" + i] =response.data.
                            this.state.inputData["guardianPhotoId" + i] =response.data.
                            this.state.inputData["nomineeGuardianAddress" + i] =response.data.
                            this.state.inputData["nomineeGuardianAddress2" + i]=response.data.
                            this.state.inputData["nomineeGuardianCityCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.city)
                            this.state.inputData["nomineeGuardianStateCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.state)
                           this.state.inputData["nomineeGuardianPostalCode" + i]=response.data.
                          this.state.inputData["nomineeGuardianCountry2" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.country)
    *!/
                            this.state.inputData["nomineecifIdApiCall"+i] = true;
                            this.state.varValue["nomineecifIdApiCall"+i] = true;
                            this.forceUpdate()

                            console.log(this.state.inputData)


                }
            }*/


    };
    onKeyDownForDedup = (event, data, secondVarName) => {
        for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {

            if (data === "nomineecifId" + (i + 1)) {
                this.setState({
                    loading: true
                });

                this.state.inputData["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.varValue["nomineecifIdApiCall" + (i + 1)] = false;
                let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData["nomineecifId" + (i + 1)];
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);

                        this.state.inputData["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.inputData["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.inputData["nomineeAddress2" + (i + 1)] = response.data.mailingAddress2;
                        this.state.inputData["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.inputData["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.inputData["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.inputData["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.inputData["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.varValue["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.varValue["nomineeAddress2" + (i + 1)] = response.data.mailingAddress2;
                        this.state.varValue["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.varValue["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.varValue["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.varValue["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        let x = 18;
                        let currentDate = new Date();
                        let someDate = new Date();
                        let numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        let date = someDate.getDate();
                        let month = someDate.getMonth() + 1;
                        let year = someDate.getFullYear();
                        let convertDate = year + "-" + month + "-" + date;
                        let newDate = new Date(convertDate);
                        let stringToDate = new Date(newDate.toDateString());

                        let inputDate = new Date(response.data.birthDate);
                        this.state.inputData["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        this.state.varValue["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        if (stringToDate >= inputDate) {
                            this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "NO";

                            // this.state.varValue["nomineeMinor" + i] == "NO";
                        } else {
                            this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "YES";


                        }

                        /*this.state.inputData["nomineeGuardiansName" + i] =response.data.
                        this.state.inputData["nomineeGuardianCode" + i] =response.data.
                        this.state.inputData["guardianPhotoId" + i] =response.data.
                        this.state.inputData["nomineeGuardianAddress" + i] =response.data.
                        this.state.inputData["nomineeGuardianAddress2" + i]=response.data.
                        this.state.inputData["nomineeGuardianCityCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.city)
                        this.state.inputData["nomineeGuardianStateCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.state)
                       this.state.inputData["nomineeGuardianPostalCode" + i]=response.data.
                      this.state.inputData["nomineeGuardianCountry2" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.country)
*/
                        this.state.inputData["nomineecifIdApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineecifIdApiCall" + (i + 1)] = true;
                        this.forceUpdate();

                        console.log(this.state.inputData);
                        this.setState({
                            //varValue: this.state.varValue,
                            //inputData: this.state.inputData,
                            showValue: true,
                            getData: true,
                            loading: false
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                            errorTittle: "Invalid Introducer ID",
                            errorModalBoolean: true,


                        })
                    })

            }
        }
        if (data === "mispriorityMonitoringRmCodeFreeCode3") {
            this.setState({
                loading: true
            });
            this.state.inputData.mispriorityMonitoringRmCodeFreeCode3ApiCall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.mispriorityMonitoringRmCodeFreeCode3Name = response.data;
                    this.state.varValue.mispriorityMonitoringRmCodeFreeCode3Name = response.data;
                    console.log(response.data);
                    this.state.inputData.mispriorityMonitoringRmCodeFreeCode3ApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Invalid RM Number!",
                        loading: false
                    })

                })
        } else if (data === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3") {
            this.setState({
                loading: true
            });
            this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3RmName = response.data;
                    this.state.varValue.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3RmName = response.data;
                    this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Invalid RM Number!",
                        loading: false
                    })

                })
        } else if (data === "primryintroducerCustomerIdCifId") {
            this.setState({
                loading: true
            });
            this.state.inputData.introducerIdApiCall = false;
            this.state.varValue.introducerIdApiCall = false;
            let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.primryiintroducerNameLastName = response.data.customerName;
                    this.state.varValue.primryiintroducerNameLastName = response.data.customerName;
                    console.log(response.data);

                    this.state.inputData.introducerIdApiCall = true;
                    this.state.varValue.introducerIdApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })


                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Introducer ID",

                    })
                })
        } else if (data === "guardianDetailsgurdianCifNumberCustomer") {
            this.setState({
                loading: true
            });
            this.state.inputData.guardianDetailsgurdianCifNumberCustomerApiCall = "NO";
            this.forceUpdate();
            let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.guardianDetailslastName = response.data.customerName;
                    this.state.varValue.guardianDetailslastName = response.data.customerName;
                    console.log(response.data);


                    this.state.inputData.guardianDetailsgurdianCifNumberCustomerApiCall = "YES";
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Number"
                    })
                })
        } else if (this.state.inputData.natioanlIdCarddocumentCodeN === {
            "masterDataName": "documentCode",
            "key": "NID",
            "value": "NID CARD IDENTITY"
        } || this.state.inputData.natioanlIdCarddocumentCodeN === {
            "masterDataName": "documentCode",
            "key": "RSNID",
            "value": "RSNID"
        }) {
            this.state.inputData.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = "Y";
            this.state.varValue.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = "Y"
        }

    };
    makeReadOnlyObjec = (object) => {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            if (returnObjectVariables[i]["readOnly"] !== true) {
                returnObjectVariables[i]["readOnly"] = true;
            }
        }
        return returnObject;


    };


    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Details:</b>
                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "User Name", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />

                    <br/>


                </div>

            )
        }

    };
    renderMakerCumInput = () => {
        return (
            <SecondaryCbFormDetails freeFlag1={this.props.freeFlag1}
                                    customerName={this.props.customerName}
                                    accountNumber={this.props.accountNumber}
                                    solId={this.props.solId} freeFlag4={this.props.freeFlag4}
                                    tagForm={tagForm} appId={this.props.appId}
                                    customerNumber={this.state.customerNumber}
                                    relationCode={this.state.linkrelationCode}
                                    tagingModalCbnumber={this.state.customerNumber}
                                    closeModal={this.tagCbDetailsModalClse}
                                    requestType={this.state.requestType}
                                    checkerEnd={true}
                                    cbNumber={this.state.tagCbNumber}
                                    getTaggingData={this.renderTaggingApi}/>
        )

    };
    makeReadOnlyObject = (object) => {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            if (returnObjectVariables[i]["grid"] === 6) {
                if (returnObjectVariables[i]["readOnly"] !== true) {
                    returnObjectVariables[i]["readOnly"] = true;
                }
                // returnObjectVariables[i]["grid"] = 2;
            } else {
                if (returnObjectVariables[i]["readOnly"] !== true) {
                    returnObjectVariables[i]["readOnly"] = true;
                }
            }

        }
        return returnObject;

    };
    renderJsonFormCBandAccount = () => {

        console.log(this.state)
        if (this.state.getData && (this.state.inputData.updateType === "CB Update" || this.state.inputData.updateType === "CB and Account Update")) {
            if (this.state.getData && this.state.inputData.updateType === "CB and Account Update") {
                return (
                    CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(jsonFormForCbAndAccount), this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)

                )
            } else {
                return (
                    CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(jsonFormForCB), this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)

                )
            }

        } else if (this.state.getData && this.state.inputData.updateType === "Account Update" || this.state.inputData.updateType === "CB and Account Update") {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(jsonFormForAccount), this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)

            )
        } else if (this.state.getData && (this.state.inputData.updateType === "Tp and Kyc Update")) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(jsonFormForTpKyc), this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)

            )
        } else {
            {
                this.renderJsonFormFirst()
            }

        }


    };
    closeIcon = () => {
        this.props.closeModal()
    };
    /* renderHeader = () => {
         /!*   if (this.props.appId === undefined) {*!/
         return (
             <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                 <h4 style={{color: "white"}} ><b>CB And Account Update </b> <p style={{fontSize: "16px"}}>(<b> Customer
                     Name:</b>{this.state.inputData.customerName}
                     <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                     <b> CB Number:</b>{this.state.inputData.cbNumber}
                     <b> SOLID:</b>{this.state.inputData.basicInformationUpdateprimarySolId} <b> Scheme
                         Code:</b>{(this.state.inputData.schemeCodeForSavings !== undefined && this.state.inputData.schemeCodeForSavings !== null) ? this.state.inputData.schemeCodeForSavings.value : ((this.state.inputData.schemeCodeForCurrent !== undefined && this.state.inputData.schemeCodeForCurrent !== null) ? this.state.inputData.schemeCodeForCurrent.value : "")})<a><CloseIcon
                         onClick={this.closeIcon}
                         style={{
                             position: 'absolute',
                             right: 10,
                             color: "#000000"
                         }}/></a></p></h4>


             </CardHeader>
         )
         //}
     };*/
     renderHeader = () => {

        if (this.state.getData && this.state.inputData.updateType === "Account Update") {
            return (
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                    <h4 style={{color: "white"}} ><b>Account Update </b> <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> SOLID:</b>{this.state.inputData.basicInformationUpdateprimarySolId} <b> Scheme
                            Code:</b>{(this.state.inputData.upDateSchemeCode !== undefined && this.state.inputData.upDateSchemeCode !== null) ? this.state.inputData.upDateSchemeCode : ""}){/*<a><CloseIcon
                            onClick={this.closeIcon}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a>*/}</p></h4>


                </CardHeader>
            )
        } else if (this.state.getData && this.state.inputData.updateType === "CB Update") {
            return (
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                    <h4 style={{color: "white"}} ><b>CB Update </b> <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> CB Number:</b>{this.state.inputData.cbNumber}
                        <b> SOLID:</b>{this.state.inputData.basicInformationUpdateprimarySolId})
                        {/*<a><CloseIcon
                            onClick={this.closeIcon}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a>*/}</p></h4>


                </CardHeader>
            )
        } else if (this.state.getData && this.state.inputData.updateType === "CB and Account Update") {
            return (
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                    <h4 style={{color: "white"}} ><b>CB and Account Update </b> <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> CB Number:</b>{this.state.inputData.cbNumber}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> SOLID:</b>{this.state.inputData.basicInformationUpdateprimarySolId}
                        <b> Scheme Code:</b>{(this.state.inputData.upDateSchemeCode !== undefined && this.state.inputData.upDateSchemeCode !== null) ? this.state.inputData.upDateSchemeCode : ""})
                        {/*<a><CloseIcon
                            onClick={this.closeIcon}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a>*/}</p></h4>


                </CardHeader>
            )
        }

        else if (this.state.getData && this.state.inputData.updateType === "Tp and Kyc Update") {
            return (
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                    <h4 style={{color: "white"}} ><b>Tp and Kyc Update </b> <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> SOLID:</b>{this.state.inputData.basicInformationUpdateprimarySolId} <b> Scheme
                            Code:</b>{(this.state.inputData.upDateSchemeCode !== undefined && this.state.inputData.upDateSchemeCode !== null) ? this.state.inputData.upDateSchemeCode : ""}){/*<a><CloseIcon
                            onClick={this.closeIcon}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a>*/}</p></h4>


                </CardHeader>
            )
        } else if (this.state.getData && this.state.inputData.updateType === "Signature card Update") {
            return (
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                    <h4 style={{color: "white"}} ><b>Signature Card Update </b> <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> SOLID:</b>{this.state.inputData.basicInformationUpdateprimarySolId} <b> Scheme
                            Code:</b>{(this.state.inputData.upDateSchemeCode !== undefined && this.state.inputData.upDateSchemeCode !== null) ? this.state.inputData.upDateSchemeCode : ""}){/*<a><CloseIcon
                            onClick={this.closeIcon}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a>*/}</p></h4>


                </CardHeader>
            )
        }

    };
    renderNomineeNumber = () => {
        if (this.state.getData && this.state.inputData.updateType === "Account Update") {
            return (

                <React.Fragment>
                    <Grid item>
                        <label className="input-label-common">Number Of Nominee</label>
                    </Grid>
                    <Grid item>
                        <TextField
                            variant="outlined"
                            defaultValue={this.state.inputData["numberOfNominee"]}
                            name="numberOfNominee"
                            //label={field.label}
                            InputProps={{
                                //style:Object.assign(styles,firstStyle),
                                readOnly: true
                            }}
                            //InputLabelProps={{shrink:true}}
                            fullWidth
                            style={{paddingRight: 20}}
                        />
                    </Grid>
                </React.Fragment>


            )
        }
    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (

            <Card>
{/*
                {this.renderHeader()}
*/}
                <CardBody>
                    <div>
                        <GridList cellHeight={window.innerHeight} cols={1}>

                            <div>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    fullScreen={true}
                                    open={this.state.tagCbDetailsModal}>
                                    <DialogContent className={classes.dialogPaper}>
                                        {this.renderMakerCumInput()}

                                    </DialogContent>

                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.loading}>
                                    <DialogContent className={classes.dialogPaper}>

                                        <center>
                                            <img src={loader} alt=""/>
                                        </center>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.getCropedImage}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <AssignedCropImage handleSignatureCard={this.handleSignatureCard}
                                                           signatureButtonShowInImageCropedPage={this.state.signatureButtonShowInImageCropedPage}
                                                           subServiceType={this.props.subServiceType}
                                                           appId={this.props.appId}
                                                           closeModal={this.closeModal}/>

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.errorModalBoolean}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ErrorModal title={this.state.errorTittle} closeModal={this.closeModal}
                                                    subServiceType={this.props.subServiceType} appId={this.props.appId}
                                        />

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.confirmAlert}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ConfirmAlert closeModal={this.closeConfirmAlert}/>

                                    </DialogContent>
                                </Dialog>
                                <ThemeProvider theme={theme}>
                                    <Grid container spacing={1}>
                                        {this.renderNotification()}
                                        {/*  <Grid item xs={12}>
                                {this.renderDownLoadFileLink()}
                            </Grid>*/}
                                        {this.renderJsonFormCBandAccount()}
                                        <Grid item xs={12}>

                                        </Grid>


                                        {this.renderExistingNomineeForm()}


                                        <Grid item xs={12}>

                                        </Grid>
                                        {this.renderNewNomineeForm()}
                                        <Grid item xs={6}>
                                            {this.renderNomineeNumber()}
                                        </Grid>
                                        {/*

                            {this.renderJsonFormLast()}


                            <Grid item xs={12}>


                            </Grid>*/}

                                    </Grid>
                                </ThemeProvider>
                                <center>

                                    {/*  <Grid item xs={12}>
                            {this.renderDefferalData()}

                        </Grid>

                        {this.renderDedupComponent()}

                        {this.renderDedupComponentMandateAndBeneficiary()}
                        <Grid item xs={12}>

                            {this.renderTagList()}
                        </Grid>
                        {this.renderSdnComponent()}*/}


                                </center>
                                {/*   {this.renderFileUpload()}
                    <br/>*/}
                                <ThemeProvider theme={theme}>
                                    {this.renderRemarksData()}
                                    <br/>
                                    {/*{this.renderPreviewImage()}*/}

                                    {/*<br/>*/}
                                    {/*  <br/>
                        {this.renderDefferalData()}
                        {this.renderFlowSUmmeryButton()}

                        <WaiverList appId={this.props.appId}/>
                        <DeferalList appId={this.props.appId}/>
                        <br/>*/}
                                    <br/>
                                    {this.renderRemarks()}
                                    <br/>
                                    {this.renderSubmitButton()}
                                </ThemeProvider>


                            </div>
                        </GridList>
                    </div>
                </CardBody>
            </Card>

        )

    }

}

export default withStyles(styles)(CBAndAccountUpdateComponentForChecker);
