import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import 'semantic-ui-offline/semantic.min.css';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let jsonForm = [
    {
        varName: "customerCategory",
        type: "select",
        enum: ["Account", "AMEX", "VISA/Master", "Others"],
        label: "Customer Category",
        onKeyDown: true,
        grid: 2,
        required: true,
        multiline: true
    },
    {
        varName: "callCategory",
        type: "autoComplete",
        label: "Call Category",
        grid: 2,
        required: true,
    },
    {
        varName: "slaValue",
        type: "text",
        label: "SLA DAY",
        validation: "numeric",
        grid: 2,
        required: true,
        multiline: true
    }
];


class CreateCallCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            err: false,
            errorArray: {},
            autoComplete: {},
            errorMessages: {},
            varValue: {},
            inputData: {},
            showValue: false,
            alert: false,
            title: "",
            notificationMessage: "",
        };
    }


    componentDidMount() {

    }

    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    handleChangeMultipleSelect = (event, option) => {
        event.preventDefault();
        let customerCategory = [];
        if (option !== null) {
            for (let item of option) {
                customerCategory.push(item.title)
            }
        }
        this.state.inputData.customerCategory = customerCategory
    };

    handleSubmit = () => {
        let customerCategory = [
            {
                varName: "customerCategory",
                required: true
            }
        ];
        let error = MyValidation.defaultValidation(jsonForm.concat(customerCategory), this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        } else {
            console.log(this.state.inputData);
            let postData = {...this.state.inputData};
            postData.customerCategory = [this.state.inputData.customerCategory];
            let url = backEndServerURL + "/AddNewCardCallCategory";
            axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        title: "Successful",
                        notificationMessage: "Call Category Create Successful!",
                        alert: true
                    });
                    setTimeout(() => {
                        window.location.reload()
                    }, 300)
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        title: "Warning",
                        notificationMessage: "Failed to Create Call Category!",
                        alert: true
                    })
                })
        }
    };

    onKeyDownChange = (data) => {
        if (data.varName === "customerCategory" && this.state.inputData.customerCategory) {
            let cCategory = this.state.inputData.customerCategory === "VISA/Master" ? "visaMaster" : this.state.inputData.customerCategory === "Others" ? "other" : this.state.inputData.customerCategory.toLowerCase();
            let url = backEndServerURL + "/getCallCategory/" + cCategory;
            let callCategory = [];
            let autoComplete = {};
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    if (response.status === 200) {

                        response.data.map((item) => {
                            callCategory.push({label: item.label})
                        });
                        autoComplete.callCategory = callCategory;
                        this.setState({
                            autoComplete: {...this.state.autoComplete, callCategory},
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                });

        }
    };

    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >
                                Update SLA Configuration
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                {this.renderNotification()}
                                <ThemeProvider theme={theme}>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        jsonForm,
                                        this.updateComponent,
                                        "",
                                        this.onKeyDownChange
                                    )}
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            height: 30,
                                            marginTop: 20,
                                            marginLeft: "12px"
                                        }}
                                        onClick={this.handleSubmit}
                                    >
                                        Submit
                                    </button>
                                </ThemeProvider>
                            </Grid>
                        </CardBody>
                    </Card>
                </div>
            );
        }

    }

}

export default withStyles(styles)(CreateCallCategory);
