import React, {Component} from 'react';
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import axios from 'axios';
import {backEndServerURL} from "../../Common/Constant";
import {Menu} from 'semantic-ui-react'
import 'semantic-ui-offline/semantic.min.css';
import Button from '@material/react-button';
import MaterialTable from 'material-table';
import {createMuiTheme} from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import TablePagination from "@material-ui/core/TablePagination";
import {
    buttonStyle,
    localization,
    options,
    tabActive,
    tabInactive,
    tableIcons
} from "../DeliverableManagement/configuration";


let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '10px',
            }
        },

    }
});

class lockerBranch extends Component {

    state = {activeItem: 'home', backgroundColor: 'red'};

    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            inputData: {
                callStatus: "Call Status"
            },
            fileUploadData: {},
            responseData: [],
            activeItem: 'ALL',
            backgroundColor: 'red',
            showValue: true,
            uploadModal: false,
            merchantModal: false,
            caseId: "",
            title: "",
            dataPassArray: [],
            buttonName: "Claim",
            notificationMessage: "",
            alert: false,
            showData: true,
            pageNumber: 0,
            totalRow: 0,
            selectedCount: 0,
            loader: true,
            allData: [],
            columns: [
                {title: 'Locker Number', field: 'lockerNumber', export: false,},
                {title: 'Account Number', field: 'accountNumber'},
                {title: 'Locker Type', field: 'lockerType'},
                {title: 'Customer Name', field: 'customerName'},
                {title: 'Inssurance Coverage', field: 'inssurranceCoverage'},
                {title: 'Status', field: 'status'},
                {title: 'Sol ID', field: 'solId'},


            ],
            columnsForSURRENDED: [
                {title: 'Action', field: 'action', type: 'checkbox'},
                {title: 'Locker Number', field: 'lockerNumber', export: false,},
                {title: 'Locker Branch', field: 'lockerBranch', removable: false},
                {title: 'Account Number', field: 'accountNumber'},
                {title: 'Locker Type', field: 'lockerType'},
                {title: 'Customer Name', field: 'customerName'},
                {title: 'Status', field: 'status'},
                {title: 'Sol ID', field: 'solId'},

            ]

        };
    }

    handleItemClick = (e, {name, style}) => this.setState({
        activeItem: name,
        backgroundColor: style

    });

    headerSelect = (event, value, page = 0) => {
        event.preventDefault();
        console.log(value);
        this.setState({
            loader: true
        });

        if (value === 'ALL') {
            //open call
            this.setState({
                activeItem: value,
                buttonName: "Download",
                allData: [],
                responseData: []
            });
            let inbox_url = backEndServerURL + "/getBranchLocker/" + page;
            axios.get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        let inssurranceStatus;
                        if (response.data.content[i].inssurranceCoverage === 1) {
                            inssurranceStatus = 'Yes'
                        } else {
                            inssurranceStatus = 'NO'
                        }

                        responseRowData = response.data.content[i].solId;
                        rowData = {
                            id: response.data.content[i].id,
                            lockerNumber: response.data.content[i].lockerNumber,
                            lockerBranch: response.data.content[i].lockerBrunch,
                            accountNumber: response.data.content[i].accountNumber,
                            lockerType: response.data.content[i].lockerType,
                            customerName: response.data.content[i].customerName,
                            inssurranceCoverage: inssurranceStatus,
                            status: response.data.content[i].status,
                            solId: response.data.content[i].solId
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData)
                    }

                    this.setState(
                        {
                            allData: array,
                            responseData: responseArray,
                            pageNumber: page,
                            totalRow: response.data.totalElements,
                            loader: false
                        }
                    );
                    console.log(this.state.allData)

                })
                .catch((error) => {
                    console.log(error)
                })
        } else if (value === 'SURRENDED') {

            this.setState({
                activeItem: value,
                allData: [],
                responseData: []
            });
            let open_url = backEndServerURL + "/lockerResource/SURRENDERED/0/0";
            axios.get(open_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        responseRowData = response.data.content[i].solId;
                        rowData = {
                            action: this.checkbox(response.data.content[i].id),
                            id: response.data.content[i].id,
                            lockerNumber: response.data.content[i].lockerNumber,
                            lockerBranch: response.data.content[i].lockerBrunch,
                            accountNumber: response.data.content[i].accountNumber,
                            lockerType: response.data.content[i].lockerType,
                            customerName: response.data.content[i].customerName,
                            status: response.data.content[i].status,
                            solId: response.data.content[i].solId
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData)
                    }

                    this.setState(
                        {
                            allData: array,
                            responseData: responseArray,
                            pageNumber: page,
                            totalRow: response.data.totalElements,
                            loader: false
                        }
                    );
                    console.log(this.state.allData)

                })
                .catch((error) => {
                    console.log(error)
                })

        }

    };

    checkbox = (id) => {
        return (
            <Checkbox
                onChange={this.handleClick}
                value={id}
                inputProps={{'aria-label': 'select all desserts'}}
            />
        )
    };

    handleClick = (event) => {

        const {selected} = this.state;
        const selectedIndex = selected.indexOf(event.target.value);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, event.target.value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({selected: newSelected});
        console.log(this.state.selected)


    };

    unrentInsurance = () => {
        this.setState({
            loader: true
        });
        var unrentInsuranceUrl = backEndServerURL + "/locker/unrent/";
        axios.post(unrentInsuranceUrl, this.state.selected, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {


                this.setState({
                    activeItem: "SURRENDED",
                    allData: [],
                    responseData: []
                });
                let open_url = backEndServerURL + "/lockerResource/SURRENDERED/0/0";
                axios.get(open_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);
                        let array = [];
                        let responseArray = [];
                        let rowData;
                        let responseRowData;
                        for (let i = 0; i < response.data.content.length; i++) {
                            responseRowData = response.data.content[i].solId;
                            rowData = {
                                action: this.checkbox(response.data.content[i].id),
                                id: response.data.content[i].id,
                                lockerNumber: response.data.content[i].lockerNumber,
                                lockerBranch: response.data.content[i].lockerBrunch,
                                accountNumber: response.data.content[i].accountNumber,
                                lockerType: response.data.content[i].lockerType,
                                customerName: response.data.content[i].customerName,
                                status: response.data.content[i].status,
                                solId: response.data.content[i].solId
                            };
                            array.push(rowData);
                            responseArray.push(responseRowData)
                        }

                        this.setState(
                            {
                                allData: array,
                                responseData: responseArray,
                                pageNumber: this.state.pageNumber,
                                totalRow: response.data.totalElements,
                                loader: false
                            }
                        );
                        console.log(this.state.allData)

                    })
                    .catch((error) => {
                        console.log(error)
                    });

                console.log(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    };

    componentDidMount() {
        let inbox_url = backEndServerURL + "/getBranchLocker/0";
        axios.get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response);
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    let inssurranceStatus;
                    if (response.data.content[i].inssurranceCoverage === 1) {
                        inssurranceStatus = 'Yes'
                    } else {
                        inssurranceStatus = 'NO'
                    }

                    responseRowData = response.data.content[i].solId;
                    rowData = {
                        id: response.data.content[i].id,
                        lockerNumber: response.data.content[i].lockerNumber,
                        lockerBranch: response.data.content[i].lockerBrunch,
                        accountNumber: response.data.content[i].accountNumber,
                        lockerType: response.data.content[i].lockerType,
                        customerName: response.data.content[i].customerName,
                        inssurranceCoverage: inssurranceStatus,
                        status: response.data.content[i].status,
                        solId: response.data.content[i].solId
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData)
                }

                this.setState(
                    {
                        allData: array,
                        totalRow: response.data.totalElements,
                        responseData: responseArray,
                        loader: false
                    }
                );
                console.log(this.state.allData)

            })
            .catch((error) => {
                console.log(error)
            })

    }

    updateComponent = () => {
        this.forceUpdate();

    };


    open = (data) => {
        this.setState({
            dataPassArray: data,
            merchantModal: true
        })
    };


    render() {

        const {rows, columns} = this.state;
        const {activeItem} = this.state;
        console.log(this.state.inputData.callStatus);
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    <h4 style={{color: "white"}} >Locker Management</h4>
                </CardHeader>
                <CardBody>
                    <div>
                        <React.Fragment>
                            <div>
                                <Menu pointing>
                                    <Menu.Item
                                        name='ALL'
                                        active={activeItem === 'ALL'}
                                        style={
                                            activeItem === "ALL"
                                                ? tabActive
                                                : tabInactive
                                        }
                                        onClick={(e) => {
                                            this.headerSelect(e, 'ALL')
                                        }}
                                    />
                                    <Menu.Item
                                        name='SURRENDED'
                                        active={activeItem === 'SURRENDED'}
                                        style={
                                            activeItem === "SURRENDED"
                                                ? tabActive
                                                : tabInactive
                                        }
                                        onClick={(e) => {
                                            this.headerSelect(e, 'SURRENDED')
                                        }}
                                    />

                                </Menu>

                                {/*<Segment>*/}
                                {this.tableChoice()}
                                {/*</Segment>*/}
                            </div>
                        </React.Fragment>


                    </div>
                </CardBody>


            </Card>
        );
    }

    tableChoice() {

        if (this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>

                            <CircularProgress style={{marginLeft: '50%'}}/>

                        </div>
                    </MuiThemeProvider>
                </div>
            )
        } else if (this.state.activeItem === "ALL") {
            return (

                <div>

                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: '100%'}}>
                            <MaterialTable

                                columns={this.state.columns}
                                data={this.state.allData}
                                title={""}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.headerSelect(e, 'ALL', page)
                                            }
                                            }
                                        />
                                    )
                                }}

                            />
                        </div>
                    </MuiThemeProvider>

                </div>


                //new button for microsoft word download


            )
        } else {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: '100%'}}>
                            <MaterialTable

                                columns={this.state.columnsForSURRENDED}
                                data={this.state.allData}
                                title={""}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.headerSelect(e, 'SURRENDED', page)
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            <Button style={buttonStyle} onClick={this.unrentInsurance}>
                                                UNRENT
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}


                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            )
        }
    }


}

export default lockerBranch;
