import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import {Redirect} from "react-router-dom";
import '../../Static/css/login.css';
import FormSample from "../JsonForm/FormSample";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        background: '#f6f1ff'
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 400,
    },
});
const jsonForm = {
    "variables": [


        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB Number",
            "required": true,


        },

        {
            "varName": "dob",
            "type": "text",
            "label": " Date of Birth",
            "required": false,
            "date": true,

        },
        {
            "varName": "phoneNo",
            "type": "text",
            "label": "Phone No",
            "required": false,
            "numer": true,

        },
        {
            "varName": "email",
            "type": "text",
            "label": "Email",
            "required": false,
            "email": true,

        },
        {
            "varName": "nidNo",
            "type": "text",
            "label": "Nid No",
            "required": false,
            "number": true,

        },
        {
            "varName": "birthCertificateNo",
            "type": "text",
            "label": "Birth Crtificate No",
            "required": false,
            "number": true,

        },
        {
            "varName": "accountSources",
            "type": "select",
            "label": "Account Sources",
            "required": false,
            "enum": [
                "Finacle",
                "Ababil"
            ]

        },
        {
            "varName": "customerName",
            "type": "text",
            "label": "Customer Name",
            "required": false,


        },
        {
            "varName": "passportNo",
            "type": "text",
            "label": "Passport No",
            "required": false,
            "number": true


        },
        {
            "varName": "drivingLicense",
            "type": "text",
            "label": "Driving License",
            "required": false,
            "number": true


        },
        {
            "varName": "forwardTo",
            "type": "select",
            "label": "Forward To",
            "required": false,
            "enum": [
                "BOM",
                "BM"
            ]

        },

    ],

};

class BeforeAccountOpening extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        }

    }

    getSubmitedForm = (object) => {
        this.setState({
            redirect: true
        });
    };

    handlesubmit = () => {
        this.setState({
            redirect: true
        });

    };
    renderRedirect = () => {
        if (this.state.redirect) {
            return (<Redirect to="/newaccountsearchResult"/>);
        }

    };

    render() {
        const {classes} = this.props;
        return (

            <GridContainer>
                {
                    this.renderRedirect()

                }

                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>Account opening</h4>
                        </CardHeader>
                        <CardBody>
                            <div className={classes.root}>
                                <FormSample grid="4"
                                            buttonName="Submit"
                                            onSubmit={this.getSubmitedForm} jsonForm={jsonForm}/>
                            </div>


                        </CardBody>
                    </Card>
                </GridItem>

            </GridContainer>

        );
    }
}

export default withStyles(styles)(BeforeAccountOpening);
