import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import Table from "../Table/Table";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';

import Functions from '../../Common/Functions';

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

});

class SavingsCurrentSnd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            varValue: [],

            accountDataFlag: null,
            redirectLogin:false,
            lienNarrationList:[],
            getlienNarrationList:false


        }
    }


    componentDidMount() {
        this.setState({accountDataFlag: false})
        if (this.props.ACCOUNTNO !== undefined && this.props.type !== undefined) {
            let url = backEndServerURL + "/demographic/account/"+this.props.platform+"/" + this.props.type + "/" + this.props.ACCOUNTNO;
             console.log(url);
            let varValue = [];


            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(true);
                    console.log(response.data);
                    /*
                    CUST_ID: "CB1401933"
                    SCHM_CODE: "SBGEN"
                  : []*/
                    if (response.data!== null) {




                        varValue["ACCOUNTNO"] = response.data.ACCOUNTNO;
                        varValue["PRODUCTNAME"] = response.data.PRODUCTNAME;
                        varValue["HOMEBRANCH"] = response.data.HOMEBRANCH;
                        varValue["AVAILABLEBALANCE"] = response.data.AVAILABLEBALANCE;
                        varValue["ACCT_CRNCY_CODE"] = response.data.ACCT_CRNCY_CODE;
                        varValue["RATE"] = response.data.RATE;
                        varValue["LASTMONTHAVGBAL"] = response.data.LASTMONTHAVGBAL;
                         let lienNarrationList=[];

                        if(this.props.platform !== "ABABIL"){
                            response.data.lienNarrationList.map((data)=>{
                                lienNarrationList.push(this.createTableLienNarration(data.lienAmmount,data.lienExpiryDate,data.lienRemarks,data.lienStartDate))
                            })
                        }
                        this.setState({
                            lienNarrationList:lienNarrationList,
                            getlienNarrationList:true
                        })

                    } else {

                    }

                    this.setState({
                        varValue: varValue,
                        accountDataFlag: true

                    })

                })
                .catch((error) => {
                    console.log(error);

                    /*if(error.response.status===452){
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin:true
                        })

                    }*/

                    this.setState({accountDataFlag: true})
                });
        } else {

        }
    }


    createTableLienNarration=(lienAmmount,lienExpiryDate,lienRemarks,lienStartDate)=>{
      return([lienAmmount,lienExpiryDate,lienRemarks,lienStartDate])
    }

    renderLienNarrotion=()=>{

        if(this.state.getlienNarrationList){
            return(
                <React.Fragment>
                    <br/>
                    <center><h3>Lien Amount with Narration</h3></center>
                    <br/>
                    <Table
                        tableHeaderColor="primary"
                        tableHead={["Lien Amount", "Lien Expiry Date","Lien Remarks","Lien Start Date"]}
                        tableData={this.state.lienNarrationList}
                        tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                    />
                </React.Fragment>
            )
        }
    }

    renderaccountData = () => {
        if (this.state.accountDataFlag) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={[" ", " "
                    ]}
                    tableData={[
                        ["Account Number", this.state.varValue["ACCOUNTNO"]],
                        ["Product Name", this.state.varValue["PRODUCTNAME"]],
                        ["Home Branch", this.state.varValue["HOMEBRANCH"]],
                        ["Available Balance", this.state.varValue["AVAILABLEBALANCE"]],
                        ["Currency", this.state.varValue["ACCT_CRNCY_CODE"]],
                        ["Interest Rate", this.state.varValue["RATE"]],
                        ["Monthly Avg. Balance", this.state.varValue["LASTMONTHAVGBAL"]],


                    ]}
                    tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                />
            )
        } else {
            return (<CircularProgress style={{marginLeft: '50%'}}/>)
        }
    }

    close=()=>{
        this.props.closeModal()
    }
    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (
            <section>
                <center>
                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>


                                    <h4 style={{color: "white"}} >Account Summery<a><CloseIcon onClick={this.close} style={{  position: 'absolute', right: 10, color: "#000000"}}/></a></h4>

                                </CardHeader>
                                <CardBody>

                                    <div className={classes.root}>
                                        <Grid container spacing={1}>
                                            <Grid container item xs={12} spacing={5}>
                                                <Grid item xs={12}>
                                                    <Paper className={classes.paper}>
                                                        {this.renderaccountData()}
                                                        {this.renderLienNarrotion()}

                                                    </Paper>

                                                </Grid>


                                            </Grid>
                                        </Grid>
                                    </div>


                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </center>
            </section>

        );
    }
}

export default withStyles(styles)(SavingsCurrentSnd);
