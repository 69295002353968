import {casaCloserRequestForChecker} from "./WorkflowJsonForm3";
import {maintenanceForChecker as CheckerAccountMaintenance} from "./Maintenance/static/JsonForCasaMaintenance"
import {
    CBDataJsonFormForFDR,
    CheckerJsonFormForAcesEnrollmentBond,
    CheckerJsonFormForAcesEnrollmentSanchaypatra,
    CHECKERJsonFormForCasaCompany,
    CHECKERJsonFormForCasaCompanyLast,
    CHECKERJsonFormForCasaIndividual,
    CHECKERJsonFormForCasaIndividualLast,
    CHECKERJsonFormForCasaJoint,
    CHECKERJsonFormForCasaJointLast,
    CHECKERJsonFormForCasaProprietorship,
    CHECKERJsonFormForCasaProprietorshipLast,
    CheckerJsonFormForEncashmentBond,
    CheckerJsonFormForEncashmentSanchaypatra,
    CheckerJsonFormForIssuanceBond,
    CheckerJsonFormForIssuanceSanchaypatra,
    NonIndividualKYC,
    TpKycForIndividual
} from "./WorkflowJsonForm4";
import {
    BOMinWardJasonFormRemitance,
    CheckerJsonFormForCasaIndividualTagDPS,
    CheckerJsonFormForCasaIndividualTagDPSLast,
    CheckerJsonFormForCasaIndividualTagFdr2,
    CheckerJsonFormForCasaIndividualTagFdr2Last,
    CheckerJsonFormForCasaIndividualWithExistingCBTagFdr,
    CheckerJsonFormForCasaIndividualWithExistingCBTagFdrLast,
    CheckerJsonFormForNewFdrForm,
    CheckerJsonFormForNewFdrFormLasts,
    fttJasonFormRemitanceBOM,
    MedicalFileJsonFormBOM,
    outwardJasonFormRemitanceBOM,
    referenceNumber,
    remittanceJsonForStudentReadOnly,
    RemittanceServiceOtherJsonFormBOM,
    SDCLockerForm,
} from "./WorkflowJsonFormArin";

import React, {Component} from "react";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../Card/CardHeader";
import CloseIcon from '@material-ui/icons/Close';
import VerifyCheckerInboxCase from "./CASA/VerifyCheckerInboxCase";
import VerifyMakerPhoto from "./VerifyMakerPhoto";
import OpeningSDC from "./fdr/OpeningSDC";
import AbhChecker from "./AGENT/AbhChecker";
import VerifyChecker from "./BOND/VerifyChecker";
import VerifyCheckerSanchoypatra from "./Sanchoypatra/VerifyCheckerSanchoypatra";
import ServiceDeliveryChecker from "./FundTransfer/CheckerSplitForm";
import SDCLocker from "./LOCKER/SDCLocker";
import VerifyCheckerAccountMaintenance from "./Maintenance/static/VerifyCheckerAccountMaintenance";
import CheckerLockerSurrender from './LOCKER/CheckerLockerSurrander';
import CheckerLockerMaintenance from './LOCKER/CheckerLockerMaintenance';
import CloserCheckerInbox from "./Maintenance/casa/CheckerInbox";
import FdrCheckerApproval from "./Maintenance/FDR/FdrCheckerApproval";

import MandateApproval from "./Maintenance/signatory/CheckerApproval";
import ApprovalChecker from "./REMITENCE/ApprovalChecker";
import {dpsJsonBomFormReadOnly} from './WorkflowJsonFormRashed'
import DpsCheckerApproval from "./Maintenance/dps/CheckerInbox"
import Others from "./Maintenance/staticOthers/Others";
import {fdrEncashmentForChecker, fdrMaintenanceForChecker} from "./Maintenance/FDR/JsonForFdrMaintenance"
import CloserRequestForChecker from "./Maintenance/casa/CloserRequestForChecker";
import MaintenanceUtility from "./Maintenance/static/MaintenanceUtility";
import CBAndAccountUpdateComponentForChecker from "./CommonComponent/CBAndAccountUpdateComponentForChecker";
import FDRMaintenanceUtility from "./Maintenance/FDR/MaintenanceUtility";
import FundTransfer from "./FundTransfer/CheckerCustomerServiceApproval";
import CheckerInboxView from "./Maintenance/CustomerStatusUpdate/CheckerInboxView";
import CheckerCustomInboxView from "./FundTransfer/customDuty/CheckerInboxView";

let initiateBondrequest = [
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "CB Number",
        "grid": 12,
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 12
    },
    {
        "varName": "dob",
        "type": "date",
        "label": "Date Of Birth",
        "grid": 12

    },
    {
        "varName": "nomineeDob",
        "type": "date",
        "label": "Nominee Date Of Birth",
        "grid": 12
    },
    {
        "varName": "amount",
        "type": "text",
        "label": "Amount",
        "grid": 12
    }
];
let comments = [
    {
        "varName": "other",
        "type": "text",
        "label": "Comments",
        "grid": 12,
    },

];

class CheckerInboxCase extends Component {
    state = {
        sendTo: false,

        documentList: [],
        getDocument: false

    };

    constructor(props) {
        super(props);


    }


    componentDidMount() {
        console.log(this.props.freeFlag1);
        console.log(this.props.freeFlag2);
        console.log(this.props.freeFlag3);
        let fileUrl = backEndServerURL + "/case/files/" + this.props.appUid;
        axios.get(fileUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    documentList: response.data,
                    getDocument: true
                })
            })
            .catch((error) => {
                console.log(error);
            })
    }

    inboxCasePhoto = () => {
        return (<VerifyMakerPhoto closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                  serviceType={this.props.serviceType}
                                  freeFlag1={this.props.freeFlag1}
                                  freeFlag2={this.props.freeFlag2}
                                  freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                  recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                  cbNumber={this.props.cbNumber}
                                  accountNumber={this.props.accountNumber}
                                  subServiceType={this.props.subServiceType}
                                  titleName="Maker Update All Information"
                                  documentList={this.state.documentList}/>)


    };
    casa = () => {

        if ((this.props.serviceType === "Account Opening" || this.props.serviceType === 'ExistAccountOpening') && (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C")) {

            return (<VerifyCheckerInboxCase closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                            serviceType={this.props.serviceType}
                                            freeFlag1={this.props.freeFlag1}
                                            freeFlag2={this.props.freeFlag2}
                                            freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                            recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                            cbNumber={this.props.cbNumber}
                                            accountNumber={this.props.accountNumber}
                                            subServiceType={this.props.subServiceType}
                                            commonJsonForm={CHECKERJsonFormForCasaIndividual}
                                            commonJsonFormLast={CHECKERJsonFormForCasaIndividualLast}
                                            tpAndKyc={TpKycForIndividual}
                                            titleName="Checker Update All Information"


                                            appId={this.props.appUid}/>)
        } else if ((this.props.serviceType === "Account Opening" || this.props.serviceType === 'ExistAccountOpening') && this.props.subServiceType === "Joint Account") {

            return (<VerifyCheckerInboxCase closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                            serviceType={this.props.serviceType}
                                            freeFlag1={this.props.freeFlag1}
                                            freeFlag2={this.props.freeFlag2}
                                            freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                            recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                            cbNumber={this.props.cbNumber}
                                            accountNumber={this.props.accountNumber}
                                            subServiceType={this.props.subServiceType}
                                            jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                                            titleName="Checker Update All Information"
                                            commonJsonForm={CHECKERJsonFormForCasaJoint}
                                            commonJsonFormLast={CHECKERJsonFormForCasaJointLast}
                                            tpAndKyc={TpKycForIndividual}
                                            appId={this.props.appUid}/>)
        } else if ((this.props.serviceType === "Account Opening" || this.props.serviceType === 'ExistAccountOpening') && (this.props.subServiceType === "NONINDIVIDUAL" || this.props.subServiceType === "Proprietorship A/C")) {

            return (<VerifyCheckerInboxCase closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                            serviceType={this.props.serviceType}
                                            freeFlag1={this.props.freeFlag1}
                                            freeFlag2={this.props.freeFlag2}
                                            freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                            recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                            cbNumber={this.props.cbNumber}
                                            accountNumber={this.props.accountNumber}
                                            subServiceType={this.props.subServiceType}
                                            titleName="Checker Update All Information"
                                            commonJsonForm={CHECKERJsonFormForCasaProprietorship}
                                            commonJsonFormLast={CHECKERJsonFormForCasaProprietorshipLast}
                                            tpAndKyc={NonIndividualKYC}
                                            appId={this.props.appUid}/>)
        } else if ((this.props.serviceType === "Account Opening" || this.props.serviceType === 'ExistAccountOpening') && this.props.subServiceType === "Company Account") {

            return (<VerifyCheckerInboxCase closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                            serviceType={this.props.serviceType}
                                            freeFlag1={this.props.freeFlag1}
                                            freeFlag2={this.props.freeFlag2}
                                            freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                            recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                            cbNumber={this.props.cbNumber}
                                            accountNumber={this.props.accountNumber}
                                            subServiceType={this.props.subServiceType}
                                            titleName="Checker Update All Information"
                                            commonJsonForm={CHECKERJsonFormForCasaCompany}
                                            commonJsonFormLast={CHECKERJsonFormForCasaCompanyLast}
                                            tpAndKyc={NonIndividualKYC}
                                            appId={this.props.appUid}/>)
        } else if ((this.props.serviceType === "Account Opening" || this.props.serviceType === 'ExistAccountOpening') && this.props.subServiceType === "Agent Account Opening") {

            return (<VerifyCheckerInboxCase closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                            serviceType={this.props.serviceType}
                                            freeFlag1={this.props.freeFlag1}
                                            freeFlag2={this.props.freeFlag2}
                                            freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                            recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                            cbNumber={this.props.cbNumber}
                                            accountNumber={this.props.accountNumber}
                                            subServiceType={this.props.subServiceType}
                                            titleName="Checker Update All Information"
                                            commonJsonForm={CHECKERJsonFormForCasaCompany}
                                            commonJsonFormLast={CHECKERJsonFormForCasaCompanyLast}
                                            tpAndKyc={NonIndividualKYC}
                                            appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'BondRequest') {

            return (<VerifyCheckerInboxCase closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                            serviceType={this.props.serviceType}
                                            freeFlag1={this.props.freeFlag1}
                                            freeFlag2={this.props.freeFlag2}
                                            freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                            recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                            cbNumber={this.props.cbNumber}
                                            accountNumber={this.props.accountNumber}
                                            subServiceType={this.props.subServiceType}
                                            titleName="Bond request"
                                            commonJsonForm={initiateBondrequest}
                                            jsonForm={comments}
                                            appId={this.props.appUid}/>)

        }

    };


    remetance = () => {

        if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'Student File') {
            return (<ApprovalChecker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                     serviceType={this.props.serviceType}
                                     freeFlag1={this.props.freeFlag1}
                                     freeFlag2={this.props.freeFlag2}
                                     freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                     recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                     cbNumber={this.props.cbNumber}
                                     accountNumber={this.props.accountNumber}
                                     subServiceType={this.props.subServiceType}
                                     titleName="Account Maintenance"
                                     commonJsonForm={outwardJasonFormRemitanceBOM.concat(remittanceJsonForStudentReadOnly)}
                                     appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'Medical File') {
            return (<ApprovalChecker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                     serviceType={this.props.serviceType}
                                     freeFlag1={this.props.freeFlag1}
                                     freeFlag2={this.props.freeFlag2}
                                     freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                     recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                     cbNumber={this.props.cbNumber}
                                     accountNumber={this.props.accountNumber}
                                     subServiceType={this.props.subServiceType}
                                     titleName="Account Maintenance"
                                     commonJsonForm={MedicalFileJsonFormBOM}
                                     appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'Other Service') {
            return (<ApprovalChecker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                     serviceType={this.props.serviceType}
                                     freeFlag1={this.props.freeFlag1}
                                     freeFlag2={this.props.freeFlag2}
                                     freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                     recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                     cbNumber={this.props.cbNumber}
                                     accountNumber={this.props.accountNumber}
                                     subServiceType={this.props.subServiceType}
                                     titleName="Account Maintenance"
                                     commonJsonForm={RemittanceServiceOtherJsonFormBOM}
                                     appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'FTT') {
            return (<ApprovalChecker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                     serviceType={this.props.serviceType}
                                     freeFlag1={this.props.freeFlag1}
                                     freeFlag2={this.props.freeFlag2}
                                     freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                     recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                     cbNumber={this.props.cbNumber}
                                     accountNumber={this.props.accountNumber}
                                     subServiceType={this.props.subServiceType}
                                     titleName="Account Maintenance"
                                     commonJsonForm={fttJasonFormRemitanceBOM.concat(referenceNumber)}
                                     appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'FDD') {
            return (<ApprovalChecker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                     serviceType={this.props.serviceType}
                                     freeFlag1={this.props.freeFlag1}
                                     freeFlag2={this.props.freeFlag2}
                                     freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                     recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                     cbNumber={this.props.cbNumber}
                                     accountNumber={this.props.accountNumber}
                                     subServiceType={this.props.subServiceType}
                                     titleName="Account Maintenance"
                                     commonJsonForm={fttJasonFormRemitanceBOM.concat(referenceNumber)}
                                     appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'Inward') {
            return (<ApprovalChecker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                     serviceType={this.props.serviceType}
                                     freeFlag1={this.props.freeFlag1}
                                     freeFlag2={this.props.freeFlag2}
                                     freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                     recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                     cbNumber={this.props.cbNumber}
                                     accountNumber={this.props.accountNumber}
                                     subServiceType={this.props.subServiceType}
                                     titleName="Account Maintenance"
                                     commonJsonForm={BOMinWardJasonFormRemitance}
                                     appId={this.props.appUid}/>)

        }
    };

    locker = () => {

        if (this.props.serviceType === 'Locker Opening')
            return (
                <SDCLocker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                           serviceType={this.props.serviceType}
                           freeFlag1={this.props.freeFlag1}
                           freeFlag2={this.props.freeFlag2}
                           freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                           recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                           cbNumber={this.props.cbNumber}
                           accountNumber={this.props.accountNumber}
                           subServiceType={this.props.subServiceType}
                           titleName="Maker Update All Information"
                           commonJsonForm={SDCLockerForm}
                           appId={this.props.appUid}/>)
    };
    fdr = () => {
        if (this.props.freeFlag1 === 'FDR Account Opening - with Existing Operative Account') {
            return (<OpeningSDC closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                cbNumber={this.props.cbNumber}
                                accountNumber={this.props.accountNumber}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                subserviceType={this.props.subServiceType}
                                titleName="Checker Update All Information"
                                commonJsonForm={CheckerJsonFormForCasaIndividualTagFdr2}
                                commonJsonFormLast={CheckerJsonFormForCasaIndividualTagFdr2Last}
                                appId={this.props.appUid}/>)
        } else if (this.props.freeFlag1 === 'FDR Account Opening - with Existing CB Only') {

            return (<OpeningSDC closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                cbNumber={this.props.cbNumber}
                                accountNumber={this.props.accountNumber}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                subserviceType={this.props.subServiceType}
                                titleName="Checker Update All Information"
                                jsonFormMisRelated={CBDataJsonFormForFDR}
                                commonJsonForm={CheckerJsonFormForCasaIndividualWithExistingCBTagFdr}
                                commonJsonFormLast={CheckerJsonFormForCasaIndividualWithExistingCBTagFdrLast}
                                appId={this.props.appUid}/>)
        } else if (this.props.freeFlag1 === 'FDR Account Opening -Without Operative Account-NTB') {

            return (<OpeningSDC closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                cbNumber={this.props.cbNumber}
                                accountNumber={this.props.accountNumber}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                subserviceType={this.props.subServiceType}
                                titleName="Checker Update All Information"
                                jsonFormMisRelated={CBDataJsonFormForFDR}
                                commonJsonForm={CheckerJsonFormForNewFdrForm}
                                commonJsonFormLast={CheckerJsonFormForNewFdrFormLasts}
                                appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'DPS Opening' && this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account') {
            return (<OpeningSDC closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                cbNumber={this.props.cbNumber}
                                accountNumber={this.props.accountNumber}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                subserviceType={this.props.subServiceType}
                                titleName="Checker Update All Information"
                                commonJsonForm={CheckerJsonFormForCasaIndividualTagDPS}
                                commonJsonFormLast={CheckerJsonFormForCasaIndividualTagDPSLast}
                                appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'DPS Opening') {
            return (<OpeningSDC closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                cbNumber={this.props.cbNumber}
                                accountNumber={this.props.accountNumber}
                                freeFlag1="DPS Account Opening -with Existing Operative Account"
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                subserviceType={this.props.subServiceType}
                                titleName="Checker Update All Information"
                                commonJsonForm={CheckerJsonFormForCasaIndividualTagDPS}
                                commonJsonFormLast={CheckerJsonFormForCasaIndividualTagDPSLast}
                                appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'FDR Opening') {

            return (<OpeningSDC closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                cbNumber={this.props.cbNumber}
                                accountNumber={this.props.accountNumber}
                                freeFlag1="FDR Account Opening - with Existing Operative Account"
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                subserviceType={this.props.subServiceType}
                                titleName="Checker Update All Information"
                                commonJsonForm={CheckerJsonFormForCasaIndividualTagFdr2}
                                commonJsonFormLast={CheckerJsonFormForCasaIndividualTagFdr2Last}
                                appId={this.props.appUid}/>)
        }

    };
    fundTransfer = () => {
        if (this.props.subCategory === "Custom Duty Payment") {
            return (
                <CheckerCustomInboxView
                    closeModal={this.props.closeModal}
                    customerName={this.props.customerName}
                    subCategory={this.props.subCategory}
                    category={this.props.category}
                    caseId={this.props.CaseId}
                    solId={this.props.solId}
                    accountNumber={this.props.accountNumber}
                    cbNumber={this.props.cbNumber}
                    closeIcon={this.props.closeIcon}
                    serviceType={this.props.serviceType}
                    subServiceType={this.props.subServiceType}
                    appId={this.props.appUid}/>
            )
        }

        else if (this.props.serviceType === 'FundTransfer' && this.props.subServiceType === 'NewFundTransfer') {
            return (<FundTransfer closeModal={this.props.closeModal} customerName={this.props.customerName}
                                  subCategory={this.props.subCategory}
                                  category={this.props.category}
                                  caseId={this.props.CaseId}
                                  solId={this.props.solId}
                                  accountNumber={this.props.accountNumber}
                                  cbNumber={this.props.cbNumber} closeIcon={this.props.closeIcon}
                                  serviceType={this.props.serviceType}
                                  freeFlag1={this.props.freeFlag1}
                                  freeFlag2={this.props.freeFlag2}
                                  freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                  recpmtid={this.props.recpmtid}
                                  customerName={this.props.customerName}

                                  subserviceType={this.props.subServiceType}
                                  titleName="Fund Transfer Request"
                                  appId={this.props.appUid}/>)
        }
    };
    agent = () => {

        if (this.props.serviceType === 'Account Opening') {

            return (<AbhChecker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                cbNumber={this.props.cbNumber}
                                accountNumber={this.props.accountNumber}
                                subServiceType={this.props.subServiceType}
                                titleName="Checker Update All Information"
                                commonJsonForm={CHECKERJsonFormForCasaCompany}


                                appId={this.props.appUid}/>)
        }
    };
    casaMaintenance = () => {
        if ((this.props.serviceType === 'Maintenance' && this.props.subServiceType === 'AccountMaintenance') && (this.props.category === "CASA" && (this.props.subCategory === "CB Update"
            || this.props.subCategory === "Account Update" || this.props.subCategory === "CB and Account Update"
            || this.props.subCategory === "Tp and Kyc Update" || this.props.subCategory === "Signature card Update"))) {

            return (
                <CBAndAccountUpdateComponentForChecker closeModal={this.props.closeModal}
                                                       closeIcon={this.props.closeIcon}
                                                       customerName={this.props.customerName}
                                                       subCategory={this.props.subCategory}
                                                       category={this.props.category}
                                                       subServiceType={this.props.subServiceType}
                                                       caseId={this.props.CaseId}
                                                       solId={this.props.solId}
                                                       accountNumber={this.props.accountNumber}
                                                       cbNumber={this.props.cbNumber}
                                                       serviceType={this.props.serviceType}
                                                       freeFlag1={this.props.freeFlag1}
                                                       freeFlag2={this.props.freeFlag2}
                                                       freeFlag3={this.props.freeFlag3}
                                                       freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}
                                                       customerName={this.props.customerName}
                                                       cbNumber={this.props.cbNumber}
                                                       accountNumber={this.props.accountNumber}
                                                       subServiceType={this.props.subServiceType}
                                                       appId={this.props.appUid}/>)
        } else if ((this.props.serviceType === 'Maintenance' && this.props.subServiceType === 'AccountMaintenance') && (this.props.category === "CASA Closer" && this.props.freeFlag1 === "CloserRequest")) {
            return (
                <CloserRequestForChecker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                         serviceType={this.props.serviceType}
                                         freeFlag1={this.props.freeFlag1}
                                         freeFlag2={this.props.freeFlag2}
                                         freeFlag3={this.props.freeFlag3}
                                         freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}
                                         customerName={this.props.customerName}
                                         cbNumber={this.props.cbNumber}
                                         accountNumber={this.props.accountNumber}
                                         subServiceType={this.props.subServiceType}
                                         appId={this.props.appUid}/>)
        } else if ((this.props.serviceType === 'Maintenance' && this.props.subServiceType === 'AccountMaintenance') && (this.props.category === "CASA Closer" || this.props.category === "DPS Closer" || this.props.category === "Deceased DPS Closer")) {
            return (
                <CloserCheckerInbox closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                    serviceType={this.props.serviceType}
                                    freeFlag1={this.props.freeFlag1}
                                    freeFlag2={this.props.freeFlag2}
                                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                    recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                    cbNumber={this.props.cbNumber}
                                    accountNumber={this.props.accountNumber}
                                    subServiceType={this.props.subServiceType}
                                    titleName="Checker Update All Information"
                                    commonJsonForm={casaCloserRequestForChecker}


                                    appId={this.props.appUid}/>)
        } else if ((this.props.serviceType === 'Maintenance' && this.props.subServiceType === 'AccountMaintenance') && this.props.category === "DPS Maintenance") {
            return (
                <DpsCheckerApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                    serviceType={this.props.serviceType}
                                    freeFlag1={this.props.freeFlag1}
                                    freeFlag2={this.props.freeFlag2}
                                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                    recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                    cbNumber={this.props.cbNumber}
                                    accountNumber={this.props.accountNumber}
                                    freeFlag1={this.props.freeFlag1}
                                    freeFlag2={this.props.freeFlag2}
                                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                    recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                    subServiceType={this.props.subServiceType}
                                    commonJsonForm={dpsJsonBomFormReadOnly}
                                    appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Maintenance' && this.props.subServiceType === 'AccountMaintenance') {
            return (
                <VerifyCheckerAccountMaintenance closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                                 serviceType={this.props.serviceType}
                                                 freeFlag1={this.props.freeFlag1}
                                                 freeFlag2={this.props.freeFlag2}
                                                 freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                                 recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                                 cbNumber={this.props.cbNumber}
                                                 accountNumber={this.props.accountNumber}
                                                 subServiceType={this.props.subServiceType}
                                                 titleName="Checker Update All Information"
                                                 commonJsonForm={CheckerAccountMaintenance}
                                                 appId={this.props.appUid}/>)
        }


    };
    fdrMaintenance = () => {

        if (this.props.subServiceType === 'FDRMaintenance' && this.props.serviceType === "Maintenance") {
            return (
                <FdrCheckerApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                    serviceType={this.props.serviceType}
                                    freeFlag1={this.props.freeFlag1}
                                    freeFlag2={this.props.freeFlag2}
                                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                    recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                    cbNumber={this.props.cbNumber}
                                    accountNumber={this.props.accountNumber} subServiceType={this.props.subServiceType}
                                    titleName="Checker Update All Information"
                                    commonJsonForm={this.props.freeFlag2 === "FDR Maintenance" ? fdrMaintenanceForChecker : fdrEncashmentForChecker}
                                    appId={this.props.appUid}/>)

        }
    };

    mandateMaintenance = () => {

        if (this.props.subServiceType === 'MandateMaintenance' && this.props.serviceType === "Maintenance") {
            return (
                <MandateApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                 serviceType={this.props.serviceType}
                                 freeFlag1={this.props.freeFlag1}
                                 freeFlag2={this.props.freeFlag2}
                                 freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                 recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                 cbNumber={this.props.cbNumber}
                                 accountNumber={this.props.accountNumber} subServiceType={this.props.subServiceType}
                                 titleName="Checker Update All Information" appId={this.props.appUid}/>)

        }
    };

    Encashment = () => {
        if (this.props.freeFlag1 === "BOND") {
            return (

                <VerifyCheckerSanchoypatra closeModal={this.props.closeModal}
                                           delStatus={this.props.delStatus}


                                           serviceType={this.props.serviceType}
                                           freeFlag1={this.props.freeFlag1}
                                           freeFlag2={this.props.freeFlag2}
                                           freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                           recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                           cbNumber={this.props.cbNumber}
                                           accountNumber={this.props.accountNumber}
                                           freeFlag1={this.props.freeFlag1}
                                           freeFlag2={this.props.freeFlag2}
                                           freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                           recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                           department="BOND"
                                           subServiceType={this.props.subServiceType}
                                           solId={this.props.solId}
                                           titleName={this.props.subserviceType}
                                           commonJsonForm={CheckerJsonFormForEncashmentBond}
                                           appId={this.props.appUid}/>)
        } else {
            return (
                <VerifyCheckerSanchoypatra closeModal={this.props.closeModal}
                                           delStatus={this.props.delStatus}


                                           serviceType={this.props.serviceType}
                                           freeFlag1={this.props.freeFlag1}
                                           freeFlag2={this.props.freeFlag2}
                                           freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                           recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                           cbNumber={this.props.cbNumber}
                                           accountNumber={this.props.accountNumber}
                                           freeFlag1={this.props.freeFlag1}
                                           freeFlag2={this.props.freeFlag2}
                                           freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                           recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                           department="BOND"
                                           subServiceType={this.props.subServiceType}
                                           solId={this.props.solId}
                                           titleName={this.props.subserviceType}
                                           commonJsonForm={CheckerJsonFormForEncashmentSanchaypatra}
                                           appId={this.props.appUid}/>)
        }

    };

    issuance = () => {
        if (this.props.freeFlag1 === "BOND") {
            return (

                <VerifyChecker closeModal={this.props.closeModal}
                               delStatus={this.props.delStatus}


                               serviceType={this.props.serviceType}
                               freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                               cbNumber={this.props.cbNumber}
                               accountNumber={this.props.accountNumber}
                               freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName} department="BOND"
                               subServiceType={this.props.subServiceType}
                               solId={this.props.solId} titleName={this.props.subserviceType}
                               commonJsonForm={CheckerJsonFormForIssuanceBond}
                               appId={this.props.appUid}/>)
        } else {
            return (
                <VerifyChecker closeModal={this.props.closeModal}
                               delStatus={this.props.delStatus}


                               serviceType={this.props.serviceType}
                               freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                               cbNumber={this.props.cbNumber}
                               accountNumber={this.props.accountNumber}
                               freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName} department="BOND"
                               subServiceType={this.props.subServiceType}
                               solId={this.props.solId} titleName={this.props.subserviceType}
                               commonJsonForm={CheckerJsonFormForIssuanceSanchaypatra}
                               appId={this.props.appUid}/>)
        }


    };
    acesEnrollmentMaintenance = () => {

        if (this.props.freeFlag1 === "BOND") {
            return (

                <VerifyCheckerSanchoypatra closeModal={this.props.closeModal}
                                           delStatus={this.props.delStatus}
                                           serviceType={this.props.serviceType}
                                           freeFlag1={this.props.freeFlag1}
                                           freeFlag2={this.props.freeFlag2}
                                           freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                           recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                           cbNumber={this.props.cbNumber}
                                           accountNumber={this.props.accountNumber}
                                           freeFlag1={this.props.freeFlag1}
                                           freeFlag2={this.props.freeFlag2}
                                           freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                           recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                           department="BOND"
                                           subServiceType={this.props.subServiceType}


                                           solId={this.props.solId} subCategory={this.props.subCategory}
                                           titleName={this.props.subserviceType}
                                           commonJsonForm={CheckerJsonFormForAcesEnrollmentBond}
                                           appId={this.props.appUid}/>)
        } else {
            return (
                <VerifyCheckerSanchoypatra closeModal={this.props.closeModal}
                                           delStatus={this.props.delStatus}
                                           serviceType={this.props.serviceType}
                                           freeFlag1={this.props.freeFlag1}
                                           freeFlag2={this.props.freeFlag2}
                                           freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                           recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                           cbNumber={this.props.cbNumber}
                                           accountNumber={this.props.accountNumber}
                                           freeFlag1={this.props.freeFlag1}
                                           freeFlag2={this.props.freeFlag2}
                                           freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                           recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                           department="BOND"
                                           subServiceType={this.props.subServiceType}


                                           solId={this.props.solId} subCategory={this.props.subCategory}
                                           titleName={this.props.subserviceType}
                                           commonJsonForm={CheckerJsonFormForAcesEnrollmentSanchaypatra}
                                           appId={this.props.appUid}/>)
        }

    };
    fundtransferChecker = () => {
        if (this.props.serviceType === "FundTransfer" && this.props.subServiceType === "NewFundTransfer") {
            return (
                <ServiceDeliveryChecker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                        serviceType={this.props.serviceType}
                                        freeFlag1={this.props.freeFlag1}
                                        freeFlag2={this.props.freeFlag2}
                                        freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                        recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                        cbNumber={this.props.cbNumber}
                                        accountNumber={this.props.accountNumber}
                                        department="SD"
                                        subServiceType={this.props.subServiceType}
                                        subserviceType={this.props.subserviceType} titleName={this.props.subserviceType}
                                        appId={this.props.appUid}/>)

        }
    };
    lockerClosing = () => {

        if (this.props.subServiceType === 'LockerClosing')
            return (
                <CheckerLockerSurrender closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                        serviceType={this.props.serviceType}
                                        freeFlag1={this.props.freeFlag1}
                                        freeFlag2={this.props.freeFlag2}
                                        freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                        recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                        cbNumber={this.props.cbNumber}
                                        accountNumber={this.props.accountNumber}
                                        subServiceType={this.props.subServiceType}
                                        titleName="Checker Update All Information"
                                        appId={this.props.appUid}/>)

    };

    lockerMaintenance = () => {

        if (this.props.subServiceType === 'LockerMaintenance')
            return (
                <CheckerLockerMaintenance closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                          serviceType={this.props.serviceType}
                                          freeFlag1={this.props.freeFlag1}
                                          freeFlag2={this.props.freeFlag2}
                                          freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                          recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                          cbNumber={this.props.cbNumber}
                                          accountNumber={this.props.accountNumber}
                                          subServiceType={this.props.subServiceType}
                                          titleName="Checker Update All Information"
                                          appId={this.props.appUid}/>)

    };

    casaOtherMaintenance = () => {
        if (this.props.freeFlag1 === 'CASA Other Maintenance' && this.props.serviceType === "Maintenance") {
            return (<Others closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                            serviceType={this.props.serviceType}
                            freeFlag1={this.props.freeFlag1}
                            freeFlag2={this.props.freeFlag2}
                            freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                            recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                            cbNumber={this.props.cbNumber}
                            accountNumber={this.props.accountNumber}
                            freeFlag1={this.props.freeFlag1}
                            freeFlag2={this.props.freeFlag2}
                            freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                            recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                            taskTitle={this.props.taskTitle}

                            subServiceType={this.props.subServiceType}
                            titleName="CASA Static Other Maintenance"
                            appId={this.props.appUid}/>)
        }
    };

    customerStatusUpdate = () => {
        return(
            <CheckerInboxView closeModal={this.props.closeModal}
                              closeIcon={this.props.closeIcon}
                              subCategory={this.props.subCategory}
                              caseId={this.props.CaseId}
                              solId={this.props.solId}
                              category={this.props.category}
                              serviceType={this.props.serviceType}
                              freeFlag1={this.props.freeFlag1}
                              freeFlag2={this.props.freeFlag2}
                              freeFlag3={this.props.freeFlag3}
                              freeFlag4={this.props.freeFlag4}
                              customerName={this.props.customerName}
                              cbNumber={this.props.cbNumber}
                              accountNumber={this.props.accountNumber}
                              subServiceType={this.props.subServiceType}
                              appId={this.props.appUid}/>
        )
    };


    inboxCase = () => {

        if (this.props.serviceType === "Account Opening" && this.props.taskTitle === "abs_checker_account_opening") {
            return (
                this.agent()
            )
        }
        else if(this.props.subCategory === "Customer Status Update"){
            return (
                this.customerStatusUpdate()
            )
        }
        else if (this.props.freeFlag1 === "CASA Other Maintenance" && this.props.serviceType === "Maintenance") {
            return (
                this.casaOtherMaintenance()
            )
        } else if (this.props.serviceType === "Account Opening") {

            return (
                this.casa()
            )
        } else if (this.props.serviceType === "ExistAccountOpening") {

            return (
                this.casa()
            )
        }
        else if (this.props.serviceType === "FundTransfer" && this.props.freeFlag1==="Fund Transfer SD End Initilized") {
            return (
                this.fundTransfer()
            )
        }

        else if (this.props.serviceType === "FDR Opening" || this.props.serviceType === "DPS Opening") {

            return (
                this.fdr()
            )
        } else if (this.props.serviceType === "Locker Opening") {
            return (
                this.locker()
            )
        } else if (this.props.serviceType === "bond_issuance_serve" && (this.props.freeFlag2 === "ENCASHMENT PAYMENT" || this.props.freeFlag2 === "INTEREST PAYMENT")) {

            return (
                this.Encashment()
            )
        } else if (this.props.serviceType === "bond_issuance_serve" && (this.props.freeFlag2 === "NOMINEE CHANGE" || this.props.freeFlag2 === "ACES ENROLLMENT" || this.props.freeFlag2 === "ACCOUNT NUMBER CHANGE" || this.props.freeFlag2 === "DUPLICATE ISSUANCE" || this.props.freeFlag2 === "CERTIFICATE")) {
            console.log("...function call");
            return (
                this.acesEnrollmentMaintenance()
            )
        } else if (this.props.serviceType === "bond_issuance_serve" && this.props.freeFlag3 === "ISSUANCE") {

            return (
                this.issuance()
            )
        } else if (this.props.serviceType === "Maintenance" && this.props.subServiceType === "AccountMaintenance") {
            return (
                this.casaMaintenance()
            )
        } else if (this.props.serviceType === "Maintenance" && this.props.subServiceType === "FDRMaintenance") {
            return (
                this.fdrMaintenance()
            )
        } else if (this.props.serviceType === "Maintenance" && this.props.subServiceType === "MandateMaintenance") {
            return (
                this.mandateMaintenance()
            )
        } else if (this.props.serviceType === "Remetance") {
            return (
                this.remetance()
            )
        } else if (this.props.serviceType === "ExistAccountOpening") {
            return (
                this.casa()
            )
        } else if (this.props.serviceType === "FundTransfer") {
            return (
                this.fundtransferChecker()
            )
        } else if (this.props.subServiceType === "LockerClosing") {
            return (
                this.lockerClosing()
            )
        } else if (this.props.subServiceType === "LockerMaintenance") {
            return (
                this.lockerMaintenance()
            )
        }


    };
    close = () => {
        this.props.closeModal();
    };
    closeIcon = () => {
        this.props.closeIcon();
    };
    renderSiCbNumber = () => {
        if (this.props.recpmtid !== undefined && this.props.recpmtid !== null) {
            return (
                <><b> SI CB number:</b>{this.props.recpmtid}</>
            )
        } else {
            return ""

        }
    };
    fundTransfer = () => {
        if (this.props.serviceType === 'FundTransfer' && this.props.subServiceType === 'NewFundTransfer') {
            return (<FundTransfer closeModal={this.props.closeModal} customerName={this.props.customerName}
                                  subCategory={this.props.subCategory}
                                  category={this.props.category}
                                  caseId={this.props.CaseId}
                                  solId={this.props.solId}
                                  accountNumber={this.props.accountNumber}
                                  cbNumber={this.props.cbNumber} closeIcon={this.props.closeIcon}
                                  serviceType={this.props.serviceType}
                                  freeFlag1={this.props.freeFlag1}
                                  freeFlag2={this.props.freeFlag2}
                                  freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                  recpmtid={this.props.recpmtid}
                                  customerName={this.props.customerName}

                                  subserviceType={this.props.subServiceType}
                                  titleName="Fund Transfer Request"
                                  appId={this.props.appUid}/>)
        }

    };
    renderHeaderInformation = () => {
        return (
            <p style={{fontSize: "16px"}}> (<b> Customer
                Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                <b> CB
                    Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}{this.renderSiCbNumber()}
                <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Scheme
                    Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""}<b> Case
                    ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                    onClick={this.closeIcon} style={{

                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></p>
        )
    };
    masterdataToString=(data)=>{
        if(data!==undefined && data.key!==undefined){
            return data.value;
        }
        else{
            return data;
        }
    }
    renderTitle = () => {

        if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
            return (
                <h4 style={{color: "white"}} ><b>{this.props.freeFlag1}</b>{this.renderHeaderInformation()}</h4>

            )
        } else if (this.props.serviceType === 'FundTransfer' && this.props.subServiceType === 'NewFundTransfer') {
            return (
                <h4 style={{color: "white"}} >Fund Transfer-{this.props.subCategory === "SME/Corporate" ? "Corporate" : this.props.subCategory}<a><CloseIcon
                    onClick={this.closeIcon} style={{

                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            )
        } else if (this.props.freeFlag1 === "FDR Account Opening - with Existing Operative Account") {
            return (
                <h4 style={{color: "white"}} ><b>{this.props.freeFlag1}</b>{this.renderHeaderInformation()}</h4>

            )
        } else if (this.props.freeFlag1 === "FDR Account Opening - with Existing CB Only") {
            return (
                <h4 style={{color: "white"}} >
                    <b>{this.props.freeFlag2 === "withKyc" ? "( FDR Account Opening - Without operative CASA )" : "( FDR Account Opening - Using Different CASA )"}</b>{this.renderHeaderInformation()}
                </h4>

            )
        } else if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
            return (
                <h4 style={{color: "white"}} ><b>{this.props.freeFlag1} </b>{this.renderHeaderInformation()}</h4>

            )
        } else if (this.props.subServiceType === "FDRMaintenance" && this.props.serviceType === "Maintenance") {
            return FDRMaintenanceUtility.renderMaintenanceHeader(this.props.freeFlag3, this.props.solId, this.props.cbNumber, this.props.accountNumber, this.props.customerName, this.closeIcon,this.props.CaseId);
        } else if (this.props.subServiceType === "AccountMaintenance" && this.props.serviceType === "Maintenance" && this.props.category === "CASA Closer") {
            return MaintenanceUtility.renderCloserMaintenanceHeader(this.props.freeFlag3, this.props.solId, this.props.cbNumber, this.props.accountNumber, this.props.customerName, this.closeIcon);
            // return (
            //     <h4 style={{color: "white"}} >CASA CLoser Request<a><CloseIcon onClick={this.closeIcon} style={{
            //         position: 'absolute',
            //         right: 10,
            //         color: "#000000"
            //     }}/></a></h4>
            //
            // );
        } else if (this.props.serviceType === "Remetance") {
            return (
                <h4 style={{color: "white"}} >Remittance<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            )
        } else if (this.props.subServiceType === "AccountMaintenance" && this.props.serviceType === "Maintenance" && (this.props.category === "DPS Closer" || this.props.category === "DPS Maintenance")) {
            return (
                <h4 style={{color: "white"}} >DPS/Scheme Closer Request<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            );
        } else if (this.props.subServiceType === "AccountMaintenance" && this.props.serviceType === "Maintenance" && this.props.category === "Deceased DPS Closer") {
            return (
                <h4 style={{color: "white"}} >Deceased DPS/Scheme Closer Request<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            );
        } else if (this.props.subServiceType === "AccountMaintenance" && this.props.serviceType === "Maintenance" && this.props.category === "DPS Closer") {
            return (
                <h4 style={{color: "white"}} >DPS CLoser Request<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            );
        } else if (this.props.subServiceType === "AccountMaintenance" && this.props.serviceType === "Maintenance") {
            if((this.props.category === "CASA" &&
                (this.props.subCategory === "CB Update"
                    || this.props.subCategory === "Account Update"
                    || this.props.subCategory === "CB and Account Update"
                    || this.props.subCategory === "Tp and Kyc Update" ))){
                return this.renderHeader();
            }
            else{
                return MaintenanceUtility.renderMaintenanceHeader(this.props.freeFlag1, this.props.CaseId, this.props.freeFlag3, this.props.cbNumber, this.props.accountNumber, this.props.customerName, this.closeIcon);
            }

        } else if (this.props.subCategory === "Opening Individual") {
            return (
                <h4 style={{color: "white"}} >Individual Locker Opening<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            );
        } else if (this.props.subCategory === "Opening Joint") {
            return (
                <h4 style={{color: "white"}} >Joint Locker Opening<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            );
        } else if (this.props.subServiceType === "MandateMaintenance") {
            return MaintenanceUtility.renderMaintenanceHeader(this.props.freeFlag1, this.props.CaseId, this.props.freeFlag3, this.props.cbNumber, this.props.accountNumber, this.props.customerName, this.closeIcon);
            // return (
            //     <h4 style={{color: "white"}} ><b>Mandate/Signatory Update </b><p style={{fontSize:"16px"}}>(<b> Customer
            //         Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}{<>
            //      <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
            //         <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}<b> CB:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}<b> SOLId:</b>{this.props.freeFlag3 !== undefined ? this.props.freeFlag3 : ""}</>})<a><CloseIcon onClick={this.closeIcon} style={{
            //         position: 'absolute',
            //         right: 10,
            //         color: "#000000"
            //     }}/></a></p></h4>
            //
            // );
        } else if (this.props.serviceType === "FDR Opening") {
            return (
                <h4 style={{color: "white"}} ><b>FDR Account Opening -with NTB Operative Account </b>{this.renderHeaderInformation()}</h4>

            );
        } else if (this.props.serviceType === "DPS Opening") {
            return (
                <h4 style={{color: "white"}} ><b>DPS Account Opening -with NTB Operative Account </b>{this.renderHeaderInformation()}</h4>

            );
        } else if (this.props.serviceType === 'ExistAccountOpening') {
            return (
                <h4 style={{color: "white"}} ><b>Existing
                    Customer
                    Account Opening
                    - {this.props.subServiceType === "Company Account" ? "Partnership Account" : this.props.subServiceType} </b>{this.renderHeaderInformation()}
                </h4>
            )
        } else if (this.props.serviceType === "Account Opening") {
            return (
                <h4 style={{color: "white"}} ><b>New Customer Account Opening
                    - {this.props.subServiceType === "Company Account" ? "Partnership Account" : this.props.subServiceType} </b>{this.renderHeaderInformation()}
                </h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} ><b> {this.props.serviceType} </b>{this.renderHeaderInformation()}</h4>

            )

        }
    };

    renderHeader = () => {

        if (this.props.subCategory === "Account Update") {

            return (
                <>
                    <h3>
                        <b>Account Update  - {this.props.freeFlag1 ? "(" + this.props.freeFlag1.replaceAll("^", ",") + ")" : ""}</b>
                    </h3>
                    <h4 style={{color: "white"}} >

                        <b> A/C
                            Number:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""} <b> A/C
                        Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                        <a><CloseIcon onClick={this.closeIcon} style={{position: 'absolute', right: 10, color: "#000000"}}/></a>
                    </h4>
                </>
            )

        } else if (this.props.subCategory === "CB Update") {
            console.log(this.props.accountNumber)
            return (
                <>
                    <h3>
                        <b>CB Update - {this.props.freeFlag1 ? "(" + this.props.freeFlag1.replaceAll("^", ",") + ")" : ""}</b>
                    </h3>
                    <h4 style={{color: "white"}} >
                        <b> CB
                            Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""} <b> A/C
                        Number:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""} <b> A/C
                        Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                        <a><CloseIcon onClick={this.closeIcon} style={{position: 'absolute', right: 10, color: "#000000"}}/></a>
                    </h4>
                </>
            )
        } else if (this.props.subCategory === "CB and Account Update") {


            return (
                <>
                    <h3>
                        <b>CB and Account Update - {this.props.freeFlag1 ? "(" + this.props.freeFlag1.replaceAll("^", ",") + ")" : ""}</b>
                    </h3>
                    <h4 style={{color: "white"}} >
                        <b> CB
                            Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""} <b> A/C
                        Number:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""} <b> A/C
                        Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                        <a><CloseIcon onClick={this.closeIcon} style={{position: 'absolute', right: 10, color: "#000000"}}/></a>
                    </h4>
                </>
            )

        }

        else if (this.props.subCategory === "Tp and Kyc Update") {

            return (
                <>
                    <h3>
                        <b>Tp and Kyc Update - {this.props.freeFlag1 ? "(" + this.props.freeFlag1.replaceAll("^", ",") + ")" : ""}</b>
                    </h3>
                    <h4 style={{color: "white"}} >
                        <b> A/C
                            Number:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""} <b> A/C
                        Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                        <a><CloseIcon onClick={this.closeIcon} style={{position: 'absolute', right: 10, color: "#000000"}}/></a>
                    </h4>
                </>
            )

        } else if (this.props.updateType === "Signature card Update") {

            return (
                <>
                    <h3>
                        <b>Signature card Update - {this.props.freeFlag1 ? "(" + this.props.freeFlag1.replaceAll("^", ",") + ")" : ""}</b>
                    </h3>
                    <h4 style={{color: "white"}} >
                        <b> A/C
                            Number:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""} <b> A/C
                        Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                        <a><CloseIcon onClick={this.closeIcon} style={{position: 'absolute', right: 10, color: "#000000"}}/></a>
                    </h4>
                </>
            )

        }

    };


    render() {
        const {classes} = this.props;

        if (this.props.freeFlag3 === "ISSUANCE" || this.props.freeFlag3 === "ENCASHMENT" || this.props.freeFlag3 === "MAINTENANCE") {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >{this.props.category + " REQUEST"}<a><CloseIcon onClick={this.closeIcon} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></h4>
                        </CardHeader>
                        <CardBody>
                            <Grid container spacing={1}>
                                <Grid item xs={8}>
                                    {this.inboxCasePhoto()}

                                </Grid>
                                <Grid item xs={4}>
                                    {this.inboxCase()}
                                </Grid>


                            </Grid>
                        </CardBody>
                    </Card>
                </div>


            )
        }else if ((this.props.category === "CASA" && (this.props.subCategory === "CB Update" || this.props.subCategory === "Account Update" || this.props.subCategory === "CB and Account Update" || this.props.subCategory === "Tp and Kyc Update" ))) {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            {this.renderTitle()}
                        </CardHeader>
                        <CardBody>
                            <Grid container spacing={1}>

                                <Grid item xs={7}>
                                    {this.inboxCasePhoto()}

                                </Grid>
                                <Grid item xs={5}>
                                    {this.inboxCase()}
                                </Grid>


                            </Grid>
                        </CardBody>
                    </Card>
                </div>

            )
        } else if (this.props.freeFlag1 === "CASA Other Maintenance") {
            return (
                <div>
                    <Grid item xs={12}>
                        {this.inboxCase()}
                    </Grid>
                </div>

            )
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            {this.renderTitle()}
                        </CardHeader>
                        <CardBody>
                            <Grid container spacing={1}>
                                <Grid item xs={7}>
                                    {this.inboxCasePhoto()}

                                </Grid>
                                <Grid item xs={5}>
                                    {this.inboxCase()}
                                </Grid>


                            </Grid>
                        </CardBody>
                    </Card>
                </div>


            )
        }
    }

}

export default CheckerInboxCase;