import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../../Common/Functions';
import Notification from "../../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../../JsonForm/CustomeTheme";
import Table from "../../../Table/Table";
import DialogContent from "@material-ui/core/DialogContent/index";
import loader from "../../../../Static/loader.gif";
import {Dialog} from "@material-ui/core/index";
import Label from "./Label";
import CardBody from "../../../Card/CardBody";
import Card from "../../../Card/Card";
import PreviewButton from "../PreviewButton";
import GridList from "@material-ui/core/GridList";
import PreviewMappingImage from "../casa/PreviewMappingImage";
import MyValidation from "../../../JsonForm/MyValidation";
import CBNotFound from "../../CASA/CBNotFound";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";
import CheckList from "./CheckList";
import {
    existingFDRFormChecker,
    fdrNewFormChecker,
    newFdrOpeningFormCheckerOne,
    newFdrOpeningFormCheckerTwo
} from "./JsonForFdrMaintenance";
import functionForFileDownload from "../../../DeliverableManagement/FunctionForFileDownload";

let checkerRemarks = [
    {
        "varName": "checkerRemarks",
        "type": "textArea",
        "label": "Remarks",
        "required": true,
        "grid": 12
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};

class FdrCheckerApproval extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            getData: false,
            varValue: {},
            showValue: false,
            title: "",
            notificationMessage: "",
            alert: false,
            inputData: {},
            selectedDate: {},
            values: [],
            getDeferalList: [],
            loading: true,
            objectForNominee: [],
            fileUploadData: {},
            getRemarks: [],
            err: false,
            errorArray: {},
            errorMessages: {},
            nomineeNumber: 0,
            type: "successs",
            documentList: [],
            getExistingNominee: true,
            existingObjectForNominee: [],
            existingNominee: false,
            city: [],
            stateCode: [],
            country: [],
            guardianCode: [],
            relationCode: [],
            cbNotFound: false,
            confirmationAlert: false,
            submitFlag: "",
            disabled: false,
            checkList: false,
            getNominee: false,
            buttonFlag: "",
            exObjectForNominee: [],
            getExNominee: false,
            fundTransfer: false,
            encashmentButton: false
        };
    }

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {
        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{marginTop: "5px"}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    componentDidMount() {
        let remarksArray = [];
        let objectForNominee = [];
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let inputData = response.data;
                    if (parseInt(response.data.numberOfNominee) > 0) {
                        for (let i = 1; i <= parseInt(inputData.numberOfNominee); i++) {
                            let sl = i;
                            objectForNominee.push(
                                {
                                    "varName": "Nominee " + i,
                                    "type": "title",
                                    "label": "Nominee  " + sl,
                                    "grid": 12,
                                },
                                {
                                    "varName": "search" + sl,
                                    "type": "select",
                                    "enum": [
                                        "CIF ID",
                                        "INFORMATION",
                                    ],
                                    "required": true,
                                    "readOnly": true,
                                    "onKeyDown": true,
                                    "label": "Input CIF ID/INFORMATION ?",
                                    "grid": 6,
                                },
                                {
                                    "varName": "nomineecifId" + sl,
                                    "type": "textApiCall",
                                    "label": "CIF ID",
                                    "grid": 6,
                                    "required": true,
                                    "readOnly": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "CIF ID",
                                },
                                {
                                    "varName": "nomineeName" + sl,
                                    "type": "text",
                                    "label": "Nominee Name",
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeName" + sl,
                                    "type": "text",
                                    "label": "Nominee Name",
                                    "grid": 6,
                                    "readOnly": true,
                                    required: true,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "nomineeRelationship" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "relationCode",
                                    "enum": this.state.relationCode,
                                    "label": "Relationship",
                                    "readOnly": true,
                                    "grid": 6,
                                    "required": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeRelationship" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "relationCode",
                                    "enum": this.state.relationCode,
                                    "label": "Relationship",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                    required: true,

                                },
                                {
                                    "varName": "percentageNominee" + sl,
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    required: true,

                                },
                                {
                                    "varName": "percentageNominee" + sl,
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                    required: true,
                                },

                                {
                                    "varName": "nomineeAddress1" + sl,
                                    "type": "text",
                                    "label": "Address 1 ",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeAddress1" + sl,
                                    "type": "text",
                                    "label": "Address 1 ",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "readOnly": true,
                                    required: true,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "nomineeAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "readOnly": true,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "nomineeCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "city",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "city",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "nomineeStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "state",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "state",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "nomineePostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "nomineePostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },

                                {
                                    "varName": "nomineeCountry" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "country",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeCountry" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "country",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    "readOnly": true,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "photoIdNumberRegNo" + sl,
                                    "type": "text",
                                    "label": "Photo Id Number/Reg No",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "photoIdNumberRegNo" + sl,
                                    "type": "text",
                                    "label": "Photo Id Number/Reg No",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "nomineeDob" + sl,
                                    "type": "text",
                                    "label": "D.O.B(YYYY-MM-DD)",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeDob" + sl,
                                    "type": "date",
                                    "label": "D.O.B",
                                    "grid": 6,
                                    "readOnly": true,
                                    "onKeyDown": true,
                                    "required": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },


                                {
                                    "varName": "nomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                },
                                {
                                    "type": "title",
                                    "label": "Guardian Details  " + sl,
                                    "grid": 12,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                },
                                {
                                    "varName": "nomineeGuardiansName" + sl,
                                    "type": "text",
                                    "label": "Guardians Name",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeGuardianCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "guardianCode",
                                    "enum": this.state.guardianCode,
                                    "label": "Guardian Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                    "readOnly": true,
                                },
                                {
                                    "varName": "guardianPhotoId" + sl,
                                    "type": "text",
                                    "label": "Guardian Photo Id",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                },
                                {
                                    "varName": "nomineeGuardianAddress" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "multiline": true,
                                    "required": true,
                                    "readOnly": true,
                                },
                                {
                                    "varName": "nomineeGuardianAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "multiline": true,
                                    "readOnly": true,
                                },

                                {
                                    "varName": "nomineeGuardianCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "city",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                    "readOnly": true,
                                },

                                {
                                    "varName": "nomineeGuardianStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "state",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                    "readOnly": true,
                                },

                                {
                                    "varName": "nomineeGuardianPostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                    "readOnly": true,
                                },

                                {
                                    "varName": "nomineeGuardianCountry2" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "country",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                    "readOnly": true,
                                },
                                {
                                    "varName": "nomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "type": "title",
                                    "label": "Guardian Details  " + sl,
                                    "grid": 12,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeGuardiansName" + sl,
                                    "type": "text",
                                    "label": "Guardians Name",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "multiline": true,
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "guardianCode",
                                    "enum": this.state.guardianCode,
                                    "label": "Guardian Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,

                                },
                                {
                                    "varName": "guardianPhotoId" + sl,
                                    "type": "text",
                                    "label": "Guardian Photo Id",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeGuardianAddress" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeGuardianAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "multiline": true,
                                },
                                {
                                    "varName": "nomineeGuardianCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "city",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "state",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianPostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianCountry2" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "country",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },
                            )

                        }
                        this.setState({
                            getNominee: true,
                        })
                    }

                    if (parseInt(response.data.numberOfNewNominee) > 0 || parseInt(response.data.numberOfOldNominee) > 0) {
                        let newNominee = response.data.numberOfNewNominee ? response.data.numberOfNewNominee : 0;
                        let sl = 0;
                        let existingNominee = [];
                        for (let i = 0; i < (parseInt(newNominee) + parseInt(response.data.numberOfOldNominee)); i++) {
                            sl = i + 1;
                            existingNominee.push(
                                {
                                    "varName": "exNominee " + i,
                                    "type": "title",
                                    "label": "Nominee  " + sl,
                                    "grid": 12,
                                },
                                {
                                    "varName": "exsearch" + sl,
                                    "type": "select",
                                    "enum": [
                                        "CIF ID",
                                        "INFORMATION",
                                    ],
                                    "readOnly": true,
                                    "required": true,
                                    "onKeyDown": true,
                                    "label": "Input CIF ID/INFORMATION ?",
                                    "grid": 6,


                                },
                                {
                                    "varName": "exnomineecifId" + sl,
                                    "type": "textApiCall",
                                    "label": "CIF ID",
                                    "grid": 6,
                                    "readOnly": true,
                                    "required": true,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "CIF ID",
                                },

                                {
                                    "varName": "exnomineeName" + sl,
                                    "type": "text",
                                    "label": "Nominee Name",
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "exnomineeName" + sl,
                                    "type": "text",
                                    "label": "Nominee Name",
                                    "grid": 6,
                                    "readOnly": true,
                                    required: true,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "INFORMATION",

                                },
                                {
                                    "varName": "exnomineeRelationship" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.relationCode,
                                    "label": "Relationship",
                                    "grid": 6,
                                    "readOnly": true,
                                    "required": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "exnomineeRelationship" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.relationCode,
                                    "label": "Relationship",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                    required: true,

                                },
                                {
                                    "varName": "expercentageNominee" + sl,
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    required: true,

                                },
                                {
                                    "varName": "expercentageNominee" + sl,
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                    required: true,

                                },

                                {
                                    "varName": "exnomineeAddress1" + sl,
                                    "type": "text",
                                    "label": "Address 1 ",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "exnomineeAddress1" + sl,
                                    "type": "text",
                                    "label": "Address 1 ",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "readOnly": true,
                                    required: true,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "exnomineeAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "exnomineeAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "readOnly": true,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "exnomineeCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "exnomineeCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "exnomineeStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "exnomineeStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "exnomineePostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "exnomineePostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },

                                {
                                    "varName": "exnomineeCountry" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "exnomineeCountry" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    "readOnly": true,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "exphotoIdNumberRegNo" + sl,
                                    "type": "text",
                                    "label": "Photo Id Number/Reg No",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "exphotoIdNumberRegNo" + sl,
                                    "type": "text",
                                    "label": "Photo Id Number/Reg No",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "exnomineeDob" + sl,
                                    "type": "text",
                                    "label": "D.O.B(YYYY-MM-DD)",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "exnomineeDob" + sl,
                                    "type": "date",
                                    "label": "D.O.B",
                                    "grid": 6,
                                    "readOnly": true,
                                    "onKeyDown": true,
                                    "required": true,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },);
                            if (sl <= parseInt(response.data.numberOfOldNominee)) {
                                existingNominee.push(
                                    {
                                        "varName": "exdeleteOldNominee" + sl,
                                        "type": "select",
                                        "enum": ["Y", "N"],
                                        "label": "Delete This Nominee",
                                        "required": true,
                                        "grid": 6,
                                        "readOnly": true,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],
                                    },
                                    {
                                        "varName": "exdeleteOldNominee" + sl,
                                        "type": "select",
                                        "enum": ["Y", "N"],
                                        "label": "Delete This Nominee",
                                        "required": true,
                                        "grid": 6,
                                        "readOnly": true,
                                        "conditional": true,
                                        "conditionalVarName": "exsearch" + sl,
                                        "conditionalVarValue": "INFORMATION",
                                    },);
                            }
                            existingNominee.push(
                                {
                                    "varName": "exnomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "exnomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                },
                                {
                                    "type": "title",
                                    "label": "Guardian Details  " + sl,
                                    "grid": 12,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                },
                                {
                                    "varName": "exnomineeGuardiansName" + sl,
                                    "type": "text",
                                    "label": "Guardians Name",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "exnomineeGuardianCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.guardianCode,
                                    "label": "Guardian Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },
                                {
                                    "varName": "exguardianPhotoId" + sl,
                                    "type": "text",
                                    "label": "Guardian Photo Id",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                },

                                {
                                    "varName": "exnomineeGuardianAddress" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "exnomineeGuardianAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "multiline": true,
                                },

                                {
                                    "varName": "exnomineeGuardianCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },

                                {
                                    "varName": "exnomineeGuardianStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },

                                {
                                    "varName": "exnomineeGuardianPostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },

                                {
                                    "varName": "exnomineeGuardianCountry2" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },
                                {
                                    "varName": "exnomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "type": "title",
                                    "label": "Guardian Details  " + sl,
                                    "grid": 12,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "exnomineeGuardiansName" + sl,
                                    "type": "text",
                                    "label": "Guardians Name",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "multiline": true,
                                    "required": true,
                                },

                                {
                                    "varName": "exnomineeGuardianCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.guardianCode,
                                    "label": "Guardian Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,

                                },
                                {
                                    "varName": "exguardianPhotoId" + sl,
                                    "type": "text",
                                    "label": "Guardian Photo Id",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },

                                {
                                    "varName": "exnomineeGuardianAddress" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "exnomineeGuardianAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "multiline": true,
                                },

                                {
                                    "varName": "exnomineeGuardianCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },

                                {
                                    "varName": "exnomineeGuardianStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },

                                {
                                    "varName": "exnomineeGuardianPostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },

                                {
                                    "varName": "exnomineeGuardianCountry2" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },
                            )
                        }
                        this.setState({
                            exObjectForNominee: existingNominee,
                            getExNominee: true
                        })
                    }


                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            var status = "";
                            var tableArray = [];
                            response.data.map((deferal) => {
                                if (deferal.status === "ACTIVE") {
                                    status = "Approved By BM"
                                } else {
                                    status = deferal.status
                                }
                                tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));

                            });
                            this.setState({
                                getDeferalList: tableArray
                            });
                            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {

                                    console.log(response.data);
                                    response.data.map((data) => {

                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                    });
                                    this.setState({
                                        getRemarks: remarksArray
                                    })
                                })
                                .catch((error) => {
                                    console.log(error)
                                });


                            console.log(response.data)

                        });

                    let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                    axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            this.setState({
                                documentList: response.data
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    this.setState({
                        objectForNominee: objectForNominee,
                        fundTransfer: response.data.fundTransfer === true,
                        encashmentButton: response.data.encashmentButton === true,
                        varValue: this.removeNullValue(response.data),
                        inputData: response.data,
                        showValue: true,
                        getData: true,
                    }, this.forceUpdate);
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
        }
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.type} stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };

    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                confirmationAlert: false,
                disabled: true,
                loading: true,
            });

            let commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.checkerRemarks + "/" + this.props.appId;
            axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error)
                });
            this.state.inputData.checker_approval = this.state.submitFlag;
            this.state.inputData.checkerRemarks = undefined;
            var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
            axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (this.state.submitFlag === "APPROVED") {
                        if (this.props.freeFlag2 === "FDR Encashment") {
                            this.functionForFDREncashment()
                        } else {
                            if (this.state.buttonFlag === "Final Approve") {
                                this.functionForCaseRoute();
                            } else {
                                if (this.state.inputData.subCategory === "Auxiliary Task") {
                                    if(this.state.buttonFlag === "Freeze/Unfreeze Mark"){
                                        this.functionForAuxilaryTask()
                                    }
                                    else if (this.state.buttonFlag === "Update Existing FDR") {
                                        this.functionForFDRUpdate().then((response) => {
                                            this.setState({
                                                disabled: false,
                                                loading: false
                                            })
                                        }).catch((error) => {
                                            this.setState({
                                                disabled: false,
                                                cbNotFound: true,
                                                title: error.response.data.message,
                                                loading: false
                                            })
                                        })
                                    }
                                    else{
                                        this.functionForCaseRoute()
                                    }
                                } else if (this.state.inputData.subCategory === "S. Tax Reversal") {
                                    if (this.state.buttonFlag === "Encashment of existing FDR") {
                                        this.functionForCloseAccount().then((response) => {
                                            this.setState({
                                                disabled: false,
                                                loading: false
                                            })
                                        }).catch((error) => {
                                            this.setState({
                                                disabled: false,
                                                cbNotFound: true,
                                                title: error.response.data.message,
                                                loading: false
                                            })
                                        })
                                    } else if (this.state.buttonFlag === "Fund Transfer") {
                                        this.functionForSourceTaxReversalUpdate().then((response) => {
                                            this.setState({
                                                disabled: false,
                                                loading: false
                                            })
                                        }).catch((error) => {
                                            this.setState({
                                                disabled: false,
                                                cbNotFound: true,
                                                title: error.response.data.message,
                                                loading: false
                                            })
                                        })
                                    }
                                } else {
                                    if (this.state.buttonFlag === "Encashment of existing FDR") {
                                        this.functionForCloseAccount().then((response) => {
                                            this.setState({
                                                disabled: false,
                                                loading: false
                                            })
                                        }).catch((error) => {
                                            this.setState({
                                                disabled: false,
                                                cbNotFound: true,
                                                title: error.response.data.message,
                                                loading: false
                                            })
                                        })
                                    } else if (this.state.buttonFlag === "Update Existing FDR") {
                                        this.functionForFDRUpdate().then((response) => {
                                            this.setState({
                                                disabled: false,
                                                loading: false
                                            })
                                        }).catch((error) => {
                                            this.setState({
                                                disabled: false,
                                                cbNotFound: true,
                                                title: error.response.data.message,
                                                loading: false
                                            })
                                        })
                                    } else if (this.state.buttonFlag === "New FDR Open") {
                                        this.functionForNewFDROpen().then((response) => {
                                            this.setState({
                                                disabled: false,
                                                loading: false
                                            })
                                        }).catch((error) => {
                                            this.setState({
                                                disabled: false,
                                                cbNotFound: true,
                                                title: error.response.data.message,
                                                loading: false
                                            })
                                        })
                                    }
                                }
                            }
                        }
                    } else if (this.state.submitFlag === "RETURN" || this.state.submitFlag === "SEND_TO_CS") {
                        this.functionForCaseRoute();
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        } else {
            this.setState({
                confirmationAlert: false,
                disabled: false
            })
        }
    };

    functionForNewFDROpen = () => {
        return new Promise((resolve, reject) => {
            let updateUrl = backEndServerURL + "/openTermDepositAccountFDR/" + this.props.appId;
            axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    functionForFDRUpdate = () => {
        return new Promise((resolve, reject) => {
            let updateUrl = backEndServerURL + "/updateFdrMaintance/" + this.props.appId;
            axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    functionForVariableSet = () => {
        return new Promise((resolve, reject) => {
            var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
            axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    };

    functionForFundTransfer = () => {
        return new Promise((resolve, reject) => {
            let apiUrl = this.state.inputData.transferType === "BEFTN" ? backEndServerURL + "/intgr/beftnTransaction" : backEndServerURL + "/intgr/rtgsTransaction";
            let postData = {};
            postData.accountNumber = this.state.inputData.encashmentRepaymentAcId;
            postData.creditAccountNumber = this.state.inputData.creditAccountNumber;
            postData.customerName = this.state.inputData.customerName;
            postData.beneficiaryDetails = this.state.inputData.beneficiaryDetails;
            postData.routingNumber = this.state.inputData.routingNumber;
            postData.transferAmount = this.state.inputData.transferAmount;
            axios.post(apiUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    };

    functionForCloseAccount = () => {
        return new Promise((resolve, reject) => {
            let url = backEndServerURL + "/FDR/getDepositAccountClose/" + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    };

    functionForFDREncashment = () => {
        if (this.state.inputData.subCategory === "FDR Encashment" || this.state.inputData.subCategory === "FDR Interest Payment" || this.state.inputData.subCategory === "Deceased FDR Encashment") {
            if (this.state.buttonFlag === "fundTransfer") {
                this.functionForFundTransfer().then((response) => {
                    this.state.inputData.fundTransfer = true;
                    this.functionForVariableSet().then((response) => {
                        this.setState({
                            fundTransfer: true,
                            disabled: false,
                            loading: false,
                            title: "Fund Transfer Successful!",
                            cbNotFound: true
                        })
                    }).catch((error) => {
                        this.setState({
                            disabled: false,
                            loading: false,
                            title: error.response.data.message,
                            cbNotFound: true
                        })
                    });
                }).catch((error) => {
                    this.setState({
                        disabled: false,
                        loading: false,
                        title: error.response.data.message,
                        cbNotFound: true
                    })
                })
            } else if (this.state.buttonFlag === "encashmentButton") {
                this.functionForCloseAccount().then((response) => {
                    this.state.inputData.encashmentButton = true;
                    this.functionForVariableSet().then((response) => {
                        this.setState({
                            encashmentButton: true,
                            disabled: false,
                            loading: false,
                            title: "Encashment Successful!",
                            cbNotFound: true
                        })
                    }).catch((error) => {
                        this.setState({
                            disabled: false,
                            loading: false,
                            title: error.response.data.message,
                            cbNotFound: true
                        })
                    });
                }).catch((error) => {
                    this.setState({
                        disabled: false,
                        loading: false,
                        title: error.response.data.message,
                        cbNotFound: true
                    })
                })
            } else {
                this.functionForCaseRoute();
            }
        } else {
            this.functionForCaseRoute();
        }
    };

    functionForAuxilaryTask = () => {
        if (this.state.inputData.subCategory === "Auxiliary Task" && this.state.inputData.selectRequestType === "Freeze/Unfreeze Mark" && this.state.inputData.selectFreezeType === "Freeze") {
            let url = backEndServerURL + "/doAcctFreeze";
            axios.post(url, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        disabled: false,
                        loading: false,
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        disabled: false,
                        loading: false,
                        title: error.response.data.message,
                        cbNotFound: true
                    })
                });
        } else if (this.state.inputData.subCategory === "Auxiliary Task" && this.state.inputData.selectRequestType === "Freeze/Unfreeze Mark" && this.state.inputData.selectFreezeType === "Unfreeze") {
            let url = backEndServerURL + "/doAcctUnFreeze/" + this.state.inputData.accountNumber;
            axios.post(url, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        disabled: false,
                        loading: false,
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        disabled: false,
                        loading: false,
                        title: error.response.data.message,
                        cbNotFound: true
                    })
                });
        }
    };

    functionForSourceTaxReversalUpdate = () => {
        return new Promise((resolve, reject) => {
            let url = backEndServerURL + "/intgr/fiTransaction";
            let postData = {};
            postData.creditAccount = this.state.inputData.creditAcIdTwo;
            postData.debitAccount = this.state.inputData.debitacIdOne;
            postData.creditParticulars = this.state.inputData.creditTransactionParticularsTwo;
            postData.debitParticulars = this.state.inputData.debitTransactionParticularsOne;
            postData.amount = this.state.inputData.sTaxAmount;
            postData.currencyCode = this.state.inputData.mainAccountCurrency;
            axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error)
                });
        })
    };

    functionForCaseRoute = () => {
        let url = backEndServerURL + "/case/route/" + this.props.appId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (this.state.inputData.freeFlag2 === "FDR Encashment" && this.state.inputData.subCategory === "FDR Encashment Certificate") {
                    let url = backEndServerURL + "/changeInboxTab/" + this.props.appId + "/Certificate";
                    axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            if (response.status === 200) {
                                this.setState({
                                    title: "Successful!",
                                    notificationMessage: "Successfully Routed!",
                                    alert: true
                                });
                                this.props.closeModal()
                            }
                        })
                        .catch((error) => {
                            this.setState({
                                title: "Failed!",
                                notificationMessage: "Something went wrong!",
                                alert: true
                            });
                            console.log(error);
                        });
                } else {
                    this.setState({
                        loading: false,
                        title: "Successful!",
                        notificationMessage: "Successfully Routed!",
                        alert: true,
                        disabled: false
                    });
                    this.props.closeModal()
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    handleSubmit = (event, checker_approval, buttonFlag) => {
        event.preventDefault();
        if (checker_approval === "RETURN" || checker_approval === "SEND_TO_CS") {
            let error = MyValidation.defaultValidation(checkerRemarks, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
        }
        this.setState({
            buttonFlag: buttonFlag,
            submitFlag: checker_approval,
            title: "Do you want to confirm?",
            confirmationAlert: true
        })
    };

    stopLoading = (value) => {
        if (value === "yes") {
            setTimeout(() => {
                this.setState({
                    loading: false,
                });
            }, 1000)
        } else {
            this.setState({
                getData: false,
                searchData: false,
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    renderJsonFormFirst = () => {
        if (this.state.getData) {
            return (
                <React.Fragment>
                    <Label cbNumber={this.state.inputData.cbNumber} stopLoading={this.stopLoading}
                           subCategory={this.state.inputData.subCategory}
                           accountNumber={this.state.inputData.accountNumber} classes={this.props}/>
                    <Grid container spacing={0} style={{margin: "10px 0"}}>
                        <PreviewButton appId={this.props.appId} classes={this.props}/>
                    </Grid>
                    {CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent)}
                    {this.renderExistingFdrOpenForm()}
                    {this.state.inputData.subCategory === "Tenor or Scheme Change" && CommonJsonFormComponent.renderJsonForm(this.state, fdrNewFormChecker, this.updateComponent)}
                    {this.renderNewFDROpeningFormOne()}
                </React.Fragment>
            )
        }
    };


    renderExistingFdrOpenForm = () => {
        if (this.state.getData && this.state.inputData.changingRenewalMaturityInstructionOnly === true) {
            return (
                <>
                    {CommonJsonFormComponent.renderJsonForm(this.state, existingFDRFormChecker, this.updateComponent)}
                    {this.renderExistingFDRNomineeForm()}
                </>
            )
        }
    };

    renderExistingFDRNomineeForm = () => {
        if (this.state.exObjectForNominee.length > 0 && this.state.getExNominee) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.state.exObjectForNominee, this.updateComponent)
            )
        }
    };


    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <div style={{marginTop: "5px"}}>
                    <div style={{
                        "border-style": "groove", "border-width": "1px"
                    }}>
                        <b>Deferral List:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferal Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }

    };

    viewAttachedImages = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return <PreviewMappingImage appId={this.props.appId} classes={classes}/>
        }
    };

    renderAllButton = () => {
        let finalApprove = (this.state.inputData.transferType === "RTGS" || this.state.inputData.transferType === "BEFTN") ? (this.state.encashmentButton === true && this.state.fundTransfer === true) : true;
        if (this.props.freeFlag2 === "FDR Encashment") {
            return (
                <div>
                    {(this.state.encashmentButton === false) && <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                                marginTop: 20
                            }}
                            disabled={this.state.disabled}
                            type='button' value='add more'
                            onClick={(event) => this.handleSubmit(event, "APPROVED", "encashmentButton")}
                        >FD Encashment
                        </button>
                        &nbsp;&nbsp;&nbsp;</>}
                    {(this.state.encashmentButton === true && this.state.fundTransfer === false && (this.state.inputData.transferType === "RTGS" || this.state.inputData.transferType === "BEFTN")) && <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                                marginTop: 20
                            }}
                            disabled={this.state.disabled}
                            type='button' value='add more'
                            onClick={(event) => this.handleSubmit(event, "APPROVED", "fundTransfer")}
                        >Fund Transfer
                        </button>
                        &nbsp;&nbsp;&nbsp;</>}
                    {finalApprove && <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                                marginTop: 20
                            }}
                            disabled={this.state.disabled}
                            type='button' value='add more'
                            onClick={(event) => this.handleSubmit(event, "APPROVED", "approve")}
                        >Approve
                        </button>
                        &nbsp;&nbsp;&nbsp;</>}
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginTop: 20
                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "RETURN", "return")}
                    >Return
                    </button>
                </div>
            )
        }
        else if (this.state.inputData.subCategory === "Tenor or Scheme Change") {
            return (
                <>
                    {this.state.inputData.encashmentOfExistingFDRandIssuanceOfNewFDR === true && <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                                marginTop: 20
                            }}
                            disabled={this.state.disabled}
                            type='button' value='add more'
                            onClick={(event) => this.handleSubmit(event, "APPROVED", "Encashment of existing FDR")}
                        >Encashment of existing FDR
                        </button>
                        &nbsp;&nbsp;&nbsp;
                    </>}
                    {this.state.inputData.changingRenewalMaturityInstructionOnly === true && <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                                marginTop: 20
                            }}
                            disabled={this.state.disabled}
                            type='button' value='add more'
                            onClick={(event) => this.handleSubmit(event, "APPROVED", "Update Existing FDR")}
                        >Update Existing FDR
                        </button>
                        &nbsp;&nbsp;&nbsp;
                    </>}
                    {this.state.inputData.newFDROpening === true && <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                                marginTop: 20
                            }}
                            disabled={this.state.disabled}
                            type='button' value='add more'
                            onClick={(event) => this.handleSubmit(event, "APPROVED", "New FDR Open")}
                        >New FDR Open
                        </button>
                        &nbsp;&nbsp;&nbsp;</>}
                </>
            )
        }
        else if (this.state.inputData.subCategory === "FD Nominee Change" || this.state.inputData.subCategory === "Link ACC Change") {
            return (
                <>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginTop: 20
                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "APPROVED", "Update Existing FDR")}
                    >Update Existing FDR
                    </button>
                    &nbsp;&nbsp;&nbsp;
                </>
            )
        }
        else if (this.state.inputData.subCategory === "S. Tax Reversal") {
            return (
                <>
                    {this.state.inputData.encashmentOfExistingFDRandIssuanceOfNewFDR === true && <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                                marginTop: 20
                            }}
                            disabled={this.state.disabled}
                            type='button' value='add more'
                            onClick={(event) => this.handleSubmit(event, "APPROVED", "Encashment of existing FDR")}
                        >Encashment of existing FDR
                        </button>
                        &nbsp;&nbsp;&nbsp;</>}
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginTop: 20
                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "APPROVED", "Fund Transfer")}
                    >Fund Transfer
                    </button>
                    &nbsp;&nbsp;&nbsp;
                </>
            )
        }
        else if (this.state.inputData.subCategory === "Auxiliary Task") {
            return (
                <>
                    {this.state.inputData.changingRenewalMaturityInstructionOnly === true && <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                                marginTop: 20
                            }}
                            disabled={this.state.disabled}
                            type='button' value='add more'
                            onClick={(event) => this.handleSubmit(event, "APPROVED", "Update Existing FDR")}
                        >Update Existing FDR
                        </button>
                        &nbsp;&nbsp;&nbsp;
                    </>}
                    {(this.state.inputData.subCategory === "Auxiliary Task" && this.state.inputData.selectRequestType === "Freeze/Unfreeze Mark") && <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                                marginTop: 20
                            }}
                            disabled={this.state.disabled}
                            type='button' value='add more'
                            onClick={(event) => this.handleSubmit(event, "APPROVED", "Freeze/Unfreeze Mark")}
                        >Freeze/Unfreeze Mark
                        </button>
                        &nbsp;&nbsp;&nbsp;
                    </>}
                </>
            )
        }
    };

    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <Grid item xs={12}>
                    {this.renderAllButton()}
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginTop: 20
                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "APPROVED", "Final Approve")}
                    >Approve
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginTop: 20
                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "SEND_TO_CS")}
                    >Return to CS
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginTop: 20
                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "RETURN")}
                    >Return to Maker
                    </button>
                </Grid>
            )
        }
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                <div style={{marginTop: "5px"}}>
                    {CommonJsonFormComponent.renderJsonForm(this.state, checkerRemarks, this.updateComponent)}
                </div>
            )
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    viewCheckList = () => {
        if (this.state.getData && this.props.freeFlag2 !== "FDR Encashment") {
            return (
                <div>
                    <br/>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={this.openCheckList}
                    >View Check List
                    </button>
                </div>
            )
        }
    };

    checkListClose = () => {
        this.setState({
            checkList: false,
        })
    };

    openCheckList = () => {
        this.setState({
            checkList: true
        })
    };

    renderMultiTaskLink = () => {
        if (this.state.inputData.subCategory === "Dupl. Advice (Special)") {
            return (
                <>
                    <br/>
                    <button className="btn btn-outline-primary btn-sm">
                        <a
                            className="anchorButton"
                            target="_blank"
                            href="http://citymultitask.thecitybank.com:1010/LogIn.aspx">
                            Multitask Link</a>
                    </button>
                </>
            )
        }
    };

    renderNewFDROpeningFormOne = () => {
        if (this.state.getData && this.state.inputData.newFDROpening === true) {
            return (
                <>
                    {CommonJsonFormComponent.renderJsonForm(this.state, newFdrOpeningFormCheckerOne, this.updateComponent)}
                    {this.renderNomineeForm()}
                    {CommonJsonFormComponent.renderJsonForm(this.state, newFdrOpeningFormCheckerTwo, this.updateComponent, this.onBlurApiCall, this.onKeyDownChange)}
                </>
            )
        }
    };

    renderNomineeForm = () => {
        if (this.state.objectForNominee.length > 0 && this.state.getNominee) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.state.objectForNominee, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChangeForNominee)
            )
        }
    };

    renderEncashmentCertificate = () => {
        if (this.state.inputData.subCategory === "FDR Encashment Certificate") {
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',
                        marginTop: 20
                    }}
                    disabled={this.state.disabled}
                    type='button' value='add more'
                    onClick={this.downloadCertificate}
                >Download Certificate
                </button>
            )
        }
    };

    downloadCertificate = () => {
        this.setState({
            loading: true
        });
        let url = backEndServerURL + "/getFDREncashmentCertificate";
        functionForFileDownload.downloadFile(url, "POST", this.state.inputData, "Encashment Certificate.pdf").then((response) => {
            this.setState({
                loading: false
            })
        }).catch((error) => {
            this.setState({
                loading: false
            })
        })
    };

    renderAllDialog = () => {
        const {classes} = this.props;
        return (
            <>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.cbNotFound}>
                    <DialogContent className={classes.dialogPaper}>
                        <CBNotFound
                            closeModal={this.closeModalCBNotFound}
                            title={this.state.title}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.confirmationAlert}>
                    <DialogContent className={classes.dialogPaper}>
                        <ConfirmationModal
                            closeModal={this.closeConfirmation}
                            title={this.state.title}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    open={this.state.checkList}>
                    <DialogContent>
                        <CheckList closeModal={this.checkListClose} inputData={this.state.inputData}/>
                    </DialogContent>
                </Dialog>
            </>
        )
    };

    render() {
        Functions.redirectToLogin(this.state);
        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Card>
                        <CardBody>
                            {this.renderAllDialog()}
                            <Grid container spacing={0}>
                                <ThemeProvider theme={theme}>
                                    {this.renderNotification()}
                                    {this.renderJsonFormFirst()}
                                </ThemeProvider>
                            </Grid>
                            {this.renderDefferalData()}
                            <ThemeProvider theme={theme}>
                                {this.renderRemarksData()}
                                <br/>
                                {this.viewAttachedImages()}
                                <Grid item xs={12}>{this.renderEncashmentCertificate()}</Grid>
                                <Grid item xs={12}>
                                    {this.renderMultiTaskLink()}
                                </Grid>
                                {this.viewCheckList()}
                                <br/>
                                {this.renderRemarks()}
                            </ThemeProvider>
                            {this.renderSubmitButton()}
                        </CardBody>
                    </Card>
                </div>
            </GridList>
        )
    }

}

export default withStyles(styles)(FdrCheckerApproval);


