import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import axios from "axios";
import {backEndServerURL} from "../../Common/Constant";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import CSInboxCase from './CSInboxCase';
import BOMInboxCase from './BOMInboxCase';
import BMInboxCase from './BMInboxCase';
import GridItem from "../Grid/GridItem";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import GridContainer from "../Grid/GridContainer";
import Functions from '../../Common/Functions';
import MakerInboxCase from "./MakerInboxCase";
import CheckerInboxCase from "./CheckerInboxCase";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../JsonForm/CustomeTheme";
import Grid from "@material-ui/core/Grid";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import {Menu} from 'semantic-ui-react';
import 'semantic-ui-offline/semantic.min.css';
import MerchantInboxCase from "./Merchant/MerchantInboxCase";
import loader from "../../Static/loader.gif";
import CallCenterInboxCase from "./Maintenance/CallCenterInboxCase";
import SubmittedCaseHistory from "./CommonComponent/SubmittedCaseHistory";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Notification from "../NotificationMessage/Notification";
import Checkbox from "@material-ui/core/Checkbox";
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";

let getAllBranchType = [];

const filteringJsonForm = [
    {
        "varName": "caseId",
        "type": "textDedup",
        "label": "LWF Case Number",
        "grid": 2,
    },
    {
        "varName": "category",
        "type": "select",
        "enum": [
            "CASA",
            "FDR",
            "DPS",
            // "Maintenace",
            "Chequebook",
            "RTGS",
            "BEFTN",
            "Salary",
            "Debit Card Issuance",
            "BOND",
            "SANCHAYPATRA",
            "Debit Card",
            "Credit Card",
            "CARDS",
            "ADC",
            "Internet Banking",
            "Remittance",
            "Pay Order and FDD Bulk",
            "Locker",
            "Delivarable",
            "Deferral",
        ],
        "label": "Category",
        "grid": 2,
    },
    {
        "varName": "subCategory",
        "type": "select",
        "enum": [
            "A/C Opening",
            "Opening",
            "Schem Account opeing",
            "TD Open/FDR",
            "Maintenance",
            "Single Requisition",
            "Opening",
            "Service",
            "Single Transaction",
            "Bulk Transaction",
            "Requisition",
            "Chequebook",
            "Bulk Insta Debit Card",
            "Bulk Requisition",
            "CASA Closer",
            "FDR Closer",
            "FDR Maintenace",
            "CARD Against FDR/ DPS",
            "Tracker",
            "Instant Card",
            "General Card Requisition",
            "CARD Cheque Requisition",
            "City Touch Enrollment",
            "SMS Discountinue",
            "Outward",
            "Inward",
            "Student File",
            "Medical File",
            "Searcher",
            "Others",
            "Pay Order Bulk Requisition",
            "FDD Bulk Requisition",
            "Others",
            "Requistion",
            "Surrender",
            "Locker Maintenace",
            "Reconcilation Branch",
            "Insurance Upload",
            "Insta Pack Requisition",
            "Insta Card Requisition",
            "Insta Pack Tracker",
            "Insta Card Tracker",
            "WL Priority",
            "WL Non Priority",
            "CASA Defferal",
            "FD Deferal",
            "Others",
            "FDR",
            "Encashment",
            "ISSUANCE - Wage Earners Development Bond",
            "ISSUANCE - U.S. Dollar Investment Bond",
            "ISSUANCE - U.S. Dollar Premium Bond",
            "ISSUANCE - 5 Years Bangladesh Sanchaya Patra",
            "ISSUANCE - 3 Months Interest Based Sanchaya Patra",
            "ISSUANCE - Family Sanchaya Patra",
            "ISSUANCE - Pensioners Savings Certificate",
            "ENCASHMENT - Wage Earners Development Bond - ENCASHMENT PAYMENT",
            "ENCASHMENT - Wage Earners Development Bond - INTEREST PAYMENT",
            "ENCASHMENT - U.S. Dollar Investment Bond - ENCASHMENT PAYMENT",
            "ENCASHMENT - U.S. Dollar Investment Bond - INTEREST PAYMENT",
            "ENCASHMENT - U.S. Dollar Premium Bond - ENCASHMENT PAYMENT",
            "ENCASHMENT - U.S. Dollar Premium Bond - INTEREST PAYMENT",
            "ENCASHMENT - 5 Years Bangladesh Sanchaya Patra - ENCASHMENT PAYMENT",
            "ENCASHMENT - 5 Years Bangladesh Sanchaya Patra - INTEREST PAYMENT",
            "ENCASHMENT - 3 Months Interest Based Sanchaya Patra - ENCASHMENT PAYMENT",
            "ENCASHMENT - 3 Months Interest Based Sanchaya Patra - INTEREST PAYMENT",
            "ENCASHMENT - Family Sanchaya Patra - ENCASHMENT PAYMENT",
            "ENCASHMENT - Family Sanchaya Patra - INTEREST PAYMENT",
            "ENCASHMENT - Pensioners Savings Certificate - ENCASHMENT PAYMENT",
            "ENCASHMENT - Pensioners Savings Certificate - INTEREST PAYMENT",
            "MAINTENANCE - Wage Earners Development Bond - NOMINEE CHANG",
            "MAINTENANCE - Wage Earners Development Bond - ACES ENROLLMENT",
            "MAINTENANCE - Wage Earners Development Bond - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - Wage Earners Development Bond - DUPLICATE ISSUANCE",
            "MAINTENANCE - Wage Earners Development Bond - CERTIFICATE",
            "MAINTENANCE - U.S. Dollar Investment Bond - NOMINEE CHANG",
            "MAINTENANCE - U.S. Dollar Investment Bond - ACES ENROLLMENT",
            "MAINTENANCE - U.S. Dollar Investment Bond - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - U.S. Dollar Investment Bond - DUPLICATE ISSUANCE",
            "MAINTENANCE - U.S. Dollar Investment Bond - CERTIFICATE",
            "MAINTENANCE - U.S. Dollar Premium Bond - NOMINEE CHANG",
            "MAINTENANCE - U.S. Dollar Premium Bond - ACES ENROLLMENT",
            "MAINTENANCE - U.S. Dollar Premium Bond - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - U.S. Dollar Premium Bond - DUPLICATE ISSUANCE",
            "MAINTENANCE - U.S. Dollar Premium Bond - CERTIFICATE",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - NOMINEE CHANG",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - ACES ENROLLMENT",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - DUPLICATE ISSUANCE",
            "MAINTENANCE - 5 Years Bangladesh Sanchaya Patra - CERTIFICATE",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - NOMINEE CHANG",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - ACES ENROLLMENT",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - DUPLICATE ISSUANCE",
            "MAINTENANCE - 3 Months Interest Based Sanchaya Patra - CERTIFICATE",
            "MAINTENANCE - Family Sanchaya Patra - NOMINEE CHANG",
            "MAINTENANCE - Family Sanchaya Patra - ACES ENROLLMENT",
            "MAINTENANCE - Family Sanchaya Patra - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - Family Sanchaya Patra - DUPLICATE ISSUANCE",
            "MAINTENANCE - Family Sanchaya Patra - CERTIFICATE",
            "MAINTENANCE - Pensioners Savings Certificate - NOMINEE CHANG",
            "MAINTENANCE - Pensioners Savings Certificate - ACES ENROLLMENT",
            "MAINTENANCE - Pensioners Savings Certificate - ACCOUNT NUMBER CHANGE",
            "MAINTENANCE - Pensioners Savings Certificate - DUPLICATE ISSUANCE",
            "MAINTENANCE - Pensioners Savings Certificate - CERTIFICATE",
        ],
        "label": "Sub Category",
        "grid": 2,
    },
    {
        "varName": "branch_id",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Branch",
        "grid": 2,
    },
    {
        "varName": "cbNumber",
        "type": "textDedup",
        "label": "CB Number",
        "grid": 2,
    },
    {
        "varName": "accountNumber",
        "type": "textDedup",
        "label": "Account No.",
        "grid": 2,
    },
    {
        "varName": "customerName",
        "type": "textDedup",
        "label": "Customer name",
        "grid": 2,
    },
    {
        "varName": "fromDate",
        "type": "date",
        "label": "From Date",
        "grid": 2,
    },
    {
        "varName": "toDate",
        "type": "date",
        "label": "To Date",
        "grid": 2,
    },
    {
        "varName": "urgency",
        "type": "select",
        "enum": [
            "High",
            "Medium",
            "Low",
        ],
        "label": "Urgency",
        "grid": 2,
    },
    {
        "varName": "cutOffTime",
        "type": "select",
        "enum": [
            "Before",
            "After",
        ],
        "label": "Cut Off Time",
        "grid": 2,
    },

];

let counter = 0;

function createData(index, branchingCategory, branchingSubCategory, freeFlag5, freeFlag6, customer_name, cb_number, account_number, businessSegment, appUid, service_type, subservice_type, branch_id, currentUserName, taskTitle, jointAccountCustomerNumber, delInitTime, category, subCategory, delStatus, caseId, status, solId, freeFlag1, freeFlag2, freeFlag3, freeFlag4, recpmtid, urgency, sentByUsername, returnBackTo, priorityDesc) {

    return {
        id: index + 1,
        branchingCategory,
        branchingSubCategory,
        freeFlag5,
        freeFlag6,
        customer_name,
        cb_number,
        account_number,
        businessSegment,
        appUid,
        service_type,
        subservice_type,
        branch_id,
        currentUserName,
        taskTitle,
        jointAccountCustomerNumber,
        delInitTime,
        category,
        delStatus,
        caseId,
        subCategory,
        status,
        solId,
        freeFlag1,
        freeFlag2,
        freeFlag3,
        freeFlag4,
        recpmtid,
        urgency,
        sentByUsername,
        returnBackTo,
        priorityDesc
    };
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
    //{id: 'id', numeric: true, disablePadding: true, label: 'SL'},
    {id: 'SL', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'CaseId', numeric: false, disablePadding: true, label: 'Case ID'},
    {id: 'customer_name', numeric: false, disablePadding: true, label: 'Name'},
    {id: 'cb_number', numeric: false, disablePadding: true, label: 'CB Number'},
    {id: 'account_number', numeric: false, disablePadding: true, label: 'Account Number'},
    {id: 'businessSegment', numeric: false, disablePadding: true, label: 'Business Segement'},
    {id: 'category_type', numeric: false, disablePadding: true, label: 'Category'},
    {id: 'sub_category_type', numeric: false, disablePadding: true, label: 'Sub Category'},
    {id: 'Service', numeric: false, disablePadding: true, label: 'Service'},
    {id: 'Date', numeric: false, disablePadding: true, label: 'Date of Activity'},
    {id: 'currentUserName', numeric: false, disablePadding: true, label: 'Current User'},
    {id: 'branch_name', numeric: false, disablePadding: true, label: 'Branch Name'},
    {id: 'Status', numeric: false, disablePadding: true, label: 'Status'},
    {id: 'Current User', numeric: false, disablePadding: true, label: 'Current User'},
    /*  {id: 'View', numeric: false, disablePadding: false, label: 'Action'}*/
];
const rowsWithGroupInbox = [
    //{id: 'id', numeric: true, disablePadding: true, label: 'SL'},
    {id: 'SL', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'CaseId', numeric: false, disablePadding: true, label: 'Case ID'},
    {id: 'customer_name', numeric: false, disablePadding: true, label: 'Name'},
    {id: 'cb_number', numeric: false, disablePadding: true, label: 'CB Number'},
    {id: 'account_number', numeric: false, disablePadding: true, label: 'Account Number'},
    {id: 'businessSegment', numeric: false, disablePadding: true, label: 'Business Segement'},
    {id: 'category_type', numeric: false, disablePadding: true, label: 'Category'},
    {id: 'sub_category_type', numeric: false, disablePadding: true, label: 'Sub Category'},
    {id: 'Service', numeric: false, disablePadding: true, label: 'Service'},
    {id: 'Date', numeric: false, disablePadding: true, label: 'Date of Activity'},
    {id: 'currentUserName', numeric: false, disablePadding: true, label: 'Current User'},
    {id: 'branch_name', numeric: false, disablePadding: true, label: 'Branch Name'},
    {id: 'Status', numeric: false, disablePadding: true, label: 'Status'},
    {id: 'Returned_Back', numeric: false, disablePadding: true, label: 'Returned Back'},
    /*  {id: 'View', numeric: false, disablePadding: false, label: 'Action'}*/
];
const rowsWithReturned = [
    //{id: 'id', numeric: true, disablePadding: true, label: 'SL'},
    {id: 'SL', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'CaseId', numeric: false, disablePadding: true, label: 'Case ID'},
    {id: 'customer_name', numeric: false, disablePadding: true, label: 'Name'},
    {id: 'cb_number', numeric: false, disablePadding: true, label: 'CB Number'},
    {id: 'account_number', numeric: false, disablePadding: true, label: 'Account Number'},
    {id: 'businessSegment', numeric: false, disablePadding: true, label: 'Business Segement'},
    {id: 'category_type', numeric: false, disablePadding: true, label: 'Category'},
    {id: 'sub_category_type', numeric: false, disablePadding: true, label: 'Sub Category'},
    {id: 'Service', numeric: false, disablePadding: true, label: 'Service'},
    {id: 'Date', numeric: false, disablePadding: true, label: 'Date of Activity'},
    {id: 'currentUserName', numeric: false, disablePadding: true, label: 'Current User'},
    {id: 'branch_name', numeric: false, disablePadding: true, label: 'Branch Name'},
    {id: 'Status', numeric: false, disablePadding: true, label: 'Status'},
    {id: 'Returned By', numeric: false, disablePadding: true, label: 'Returned By'},

    /*  {id: 'View', numeric: false, disablePadding: false, label: 'Action'}*/
];
const rowsCS = [
    //{id: 'id', numeric: true, disablePadding: true, label: 'SL'},
    {id: 'SL', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'CaseId', numeric: false, disablePadding: true, label: 'Case ID'},
    {id: 'customer_name', numeric: false, disablePadding: true, label: 'Name'},
    {id: 'cb_number', numeric: false, disablePadding: true, label: 'CB Number/ClientID'},
    {id: 'account_number', numeric: false, disablePadding: true, label: 'Account/Card Number'},
    {id: 'businessSegment', numeric: false, disablePadding: true, label: 'Business Segement'},
    {id: 'category_type', numeric: false, disablePadding: true, label: 'Category'},
    {id: 'sub_category_type', numeric: false, disablePadding: true, label: 'Sub Category'},
    {id: 'Service', numeric: false, disablePadding: true, label: 'Service'},
    {id: 'Date', numeric: false, disablePadding: true, label: 'Date of Activity'},
    {id: 'currentUserName', numeric: false, disablePadding: true, label: 'Current User'},
    {id: 'Status', numeric: false, disablePadding: true, label: 'Status'},

    /*  {id: 'View', numeric: false, disablePadding: false, label: 'Action'}*/
];
const rowsCSInbox = [
    //{id: 'id', numeric: true, disablePadding: true, label: 'SL'},
    {id: 'SL', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'CaseId', numeric: false, disablePadding: true, label: 'Case ID'},
    {id: 'customer_name', numeric: false, disablePadding: true, label: 'Name'},
    {id: 'cb_number', numeric: false, disablePadding: true, label: 'CB Number/ClientID'},
    {id: 'account_number', numeric: false, disablePadding: true, label: 'Account/Card Number'},
    {id: 'businessSegment', numeric: false, disablePadding: true, label: 'Business Segement'},
    {id: 'category_type', numeric: false, disablePadding: true, label: 'Category'},
    {id: 'sub_category_type', numeric: false, disablePadding: true, label: 'Sub Category'},
    {id: 'Service', numeric: false, disablePadding: true, label: 'Service'},
    {id: 'Date', numeric: false, disablePadding: true, label: 'Date of Activity'},
    {id: 'currentUserName', numeric: false, disablePadding: true, label: 'Current User'},
    {id: 'Branch', numeric: false, disablePadding: true, label: 'Branch'},
    {id: 'Status', numeric: false, disablePadding: true, label: 'Status'},
    {id: 'Action', numeric: false, disablePadding: true, label: 'Action'},

    /*  {id: 'View', numeric: false, disablePadding: false, label: 'Action'}*/
];
const rowsCSWithReturned = [
    //{id: 'id', numeric: true, disablePadding: true, label: 'SL'},
    {id: 'SL', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'CaseId', numeric: false, disablePadding: true, label: 'Case ID'},
    {id: 'customer_name', numeric: false, disablePadding: true, label: 'Name'},
    {id: 'cb_number', numeric: false, disablePadding: true, label: 'CB Number/ClientID'},
    {id: 'account_number', numeric: false, disablePadding: true, label: 'Account/Card Number'},
    {id: 'businessSegment', numeric: false, disablePadding: true, label: 'Business Segement'},
    {id: 'category_type', numeric: false, disablePadding: true, label: 'Category'},
    {id: 'sub_category_type', numeric: false, disablePadding: true, label: 'Sub Category'},
    {id: 'Service', numeric: false, disablePadding: true, label: 'Service'},

    {id: 'Date', numeric: false, disablePadding: true, label: 'Date of Activity'},
    {id: 'currentUserName', numeric: false, disablePadding: true, label: 'Current User'},
    {id: 'Status', numeric: false, disablePadding: true, label: 'Status'},
    {id: 'Returned By', numeric: false, disablePadding: true, label: 'Returned By'},

    /*  {id: 'View', numeric: false, disablePadding: false, label: 'Action'}*/
];
const rowsBranch = [
    //{id: 'id', numeric: true, disablePadding: true, label: 'SL'},
    {id: 'SL', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'CaseId', numeric: false, disablePadding: true, label: 'Case ID'},
    {id: 'customer_name', numeric: false, disablePadding: true, label: 'Name'},
    {id: 'cb_number', numeric: false, disablePadding: true, label: 'CB Number/ClientID'},
    {id: 'account_number', numeric: false, disablePadding: true, label: 'Account/Card Number'},
    {id: 'businessSegment', numeric: false, disablePadding: true, label: 'Business Segement'},
    {id: 'category_type', numeric: false, disablePadding: true, label: 'Category'},
    {id: 'sub_category_type', numeric: false, disablePadding: true, label: 'Sub Category'},
    {id: 'Service', numeric: false, disablePadding: true, label: 'Service'},
    {id: 'Date', numeric: false, disablePadding: true, label: 'Date of Activity'},
    {id: 'currentUserName', numeric: false, disablePadding: true, label: 'Current User'},
    {id: 'branch_name', numeric: false, disablePadding: true, label: 'Branch Name'},
    {id: 'Status', numeric: false, disablePadding: true, label: 'Status'},

    /*  {id: 'View', numeric: false, disablePadding: false, label: 'Action'}*/
];

class TableContentHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    renderCheckbox = (numSelected, rowCount, onSelectAllClick) => {
        if (this.props.checkbox) {
            return (
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{'aria-label': 'select all desserts'}}
                    />
                </TableCell>
            )
        }
    };

    render() {
        const {order, orderBy, getTab, numSelected, rowCount, onSelectAllClick} = this.props;

        if (getTab === "Return" && (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CS" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "DSTCS")) {
            return (
                <TableHead>
                    <TableRow style={{height: 30, backgroundColor: "#E8E4E3"}}>
                        {rowsCSWithReturned.map(
                            row => (
                                <TableCell
                                    key={row.id}
                                    align={row.numeric ? 'right' : 'left'}
                                    padding={row.disablePadding ? 'none' : 'default'}
                                    sortDirection={orderBy === row.id ? order : false}
                                >
                                    <Tooltip
                                        title="Sort"
                                        placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                        enterDelay={300}
                                    >
                                        <TableSortLabel
                                            active={orderBy === row.id}
                                            direction={order}
                                            onClick={this.createSortHandler(row.id)}
                                        >
                                            <b> {row.label}</b>
                                        </TableSortLabel>
                                    </Tooltip>
                                </TableCell>
                            ),
                            this,
                        )}
                    </TableRow>
                </TableHead>
            );
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CS" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "DSTCS") {
            return (
                <TableHead>
                    <TableRow style={{height: 30, backgroundColor: "#E8E4E3"}}>
                        {
                            this.renderCheckbox(numSelected, rowCount, onSelectAllClick)
                        }
                        {rowsCS.map(
                            row => (
                                <TableCell
                                    key={row.id}
                                    align={row.numeric ? 'right' : 'left'}
                                    padding={row.disablePadding ? 'none' : 'default'}
                                    sortDirection={orderBy === row.id ? order : false}
                                >
                                    <Tooltip
                                        title="Sort"
                                        placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                        enterDelay={300}
                                    >
                                        <TableSortLabel
                                            active={orderBy === row.id}
                                            direction={order}
                                            onClick={this.createSortHandler(row.id)}
                                        >
                                            <b> {row.label}</b>
                                        </TableSortLabel>
                                    </Tooltip>
                                </TableCell>
                            ),
                            this,
                        )}
                    </TableRow>
                </TableHead>
            );
        } else if (getTab === "Inbox" /* && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf("INTERNET_BANKING") !== -1*/) {
            return (
                <TableHead>
                    <TableRow style={{height: 30, backgroundColor: "#E8E4E3"}}>
                        {rowsCSInbox.map(
                            row => (
                                <TableCell
                                    key={row.id}
                                    align={row.numeric ? 'right' : 'left'}
                                    padding={row.disablePadding ? 'none' : 'default'}
                                    sortDirection={orderBy === row.id ? order : false}
                                >
                                    <Tooltip
                                        title="Sort"
                                        placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                        enterDelay={300}
                                    >
                                        <TableSortLabel
                                            active={orderBy === row.id}
                                            direction={order}
                                            onClick={this.createSortHandler(row.id)}
                                        >
                                            <b> {row.label}</b>
                                        </TableSortLabel>
                                    </Tooltip>
                                </TableCell>
                            ),
                            this,
                        )}
                    </TableRow>
                </TableHead>
            );
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") !== "CS" && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") !== "DSTCS" && LocalstorageEncrypt.encryptStorageFunction.getItem('workplace') === "BRANCH") {
            return (
                <TableHead>
                    <TableRow style={{height: 30, backgroundColor: "#E8E4E3"}}>
                        {
                            this.renderCheckbox(numSelected, rowCount, onSelectAllClick)
                        }
                        {rowsBranch.map(
                            row => (
                                <TableCell
                                    key={row.id}
                                    align={row.numeric ? 'right' : 'left'}
                                    padding={row.disablePadding ? 'none' : 'default'}
                                    sortDirection={orderBy === row.id ? order : false}
                                >
                                    <Tooltip
                                        title="Sort"
                                        placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                        enterDelay={300}
                                    >
                                        <TableSortLabel
                                            active={orderBy === row.id}
                                            direction={order}
                                            onClick={this.createSortHandler(row.id)}
                                        >
                                            <b> {row.label}</b>
                                        </TableSortLabel>
                                    </Tooltip>
                                </TableCell>
                            ),
                            this,
                        )}
                    </TableRow>
                </TableHead>
            );
        } else if (getTab === "Return") {
            return (
                <TableHead>
                    <TableRow style={{height: 30, backgroundColor: "#E8E4E3"}}>
                        {rowsWithReturned.map(
                            row => (
                                <TableCell
                                    key={row.id}
                                    align={row.numeric ? 'right' : 'left'}
                                    padding={row.disablePadding ? 'none' : 'default'}
                                    sortDirection={orderBy === row.id ? order : false}
                                >
                                    <Tooltip
                                        title="Sort"
                                        placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                        enterDelay={300}
                                    >
                                        <TableSortLabel
                                            active={orderBy === row.id}
                                            direction={order}
                                            onClick={this.createSortHandler(row.id)}
                                        >
                                            <b> {row.label}</b>
                                        </TableSortLabel>
                                    </Tooltip>
                                </TableCell>
                            ),
                            this,
                        )}
                    </TableRow>
                </TableHead>
            );
        } else if (getTab === "Group Inbox") {
            return (
                <TableHead>
                    <TableRow style={{height: 30, backgroundColor: "#E8E4E3"}}>
                        {
                            this.renderCheckbox(numSelected, rowCount, onSelectAllClick)
                        }
                        {rowsWithGroupInbox.map(
                            row => (
                                <TableCell
                                    key={row.id}
                                    align={row.numeric ? 'right' : 'left'}
                                    padding={row.disablePadding ? 'none' : 'default'}
                                    sortDirection={orderBy === row.id ? order : false}
                                >
                                    <Tooltip
                                        title="Sort"
                                        placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                        enterDelay={300}
                                    >
                                        <TableSortLabel
                                            active={orderBy === row.id}
                                            direction={order}
                                            onClick={this.createSortHandler(row.id)}
                                        >
                                            <b> {row.label}</b>
                                        </TableSortLabel>
                                    </Tooltip>
                                </TableCell>
                            ),
                            this,
                        )}
                    </TableRow>
                </TableHead>
            );
        } else if (getTab === "RECTIFIED REQUEST") {
            return (
                <TableHead>
                    <TableRow style={{height: 30, backgroundColor: "#E8E4E3"}}>
                        {rowsWithGroupInbox.map(
                            row => (
                                <TableCell
                                    key={row.id}
                                    align={row.numeric ? 'right' : 'left'}
                                    padding={row.disablePadding ? 'none' : 'default'}
                                    sortDirection={orderBy === row.id ? order : false}
                                >
                                    <Tooltip
                                        title="Sort"
                                        placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                        enterDelay={300}
                                    >
                                        <TableSortLabel
                                            active={orderBy === row.id}
                                            direction={order}
                                            onClick={this.createSortHandler(row.id)}
                                        >
                                            <b> {row.label}</b>
                                        </TableSortLabel>
                                    </Tooltip>
                                </TableCell>
                            ),
                            this,
                        )}
                    </TableRow>
                </TableHead>
            );
        } else {
            return (
                <TableHead>
                    <TableRow style={{height: 30, backgroundColor: "#E8E4E3"}}>
                        {rows.map(
                            row => (
                                <TableCell
                                    key={row.id}
                                    align={row.numeric ? 'right' : 'left'}
                                    padding={row.disablePadding ? 'none' : 'default'}
                                    sortDirection={orderBy === row.id ? order : false}
                                >
                                    <Tooltip
                                        title="Sort"
                                        placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                        enterDelay={300}
                                    >
                                        <TableSortLabel
                                            active={orderBy === row.id}
                                            direction={order}
                                            onClick={this.createSortHandler(row.id)}
                                        >
                                            <b> {row.label}</b>
                                        </TableSortLabel>
                                    </Tooltip>
                                </TableCell>
                            ),
                            this,
                        )}
                    </TableRow>
                </TableHead>
            );
        }

    }
}

TableContentHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    getTab: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


const styles = theme => ({
    root: {
        width: '100%',
        border: '1'
        //marginTop: theme.spacing.unit * 2,
    },
    table: {
        minWidth: 1020,
    },
    tableCell: {
        padding: 3,
    },
    tableCells: {
        padding: 0,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    container: {
        overflow: 'auto',
        padding: '10px'
    },
    Tab: {
        flexDirection: "row-reverse"
    },
    left: {
        float: 'left',
        width: '200px'
    },

    right: {
        marginLeft: '210px'
    }
});

class Inbox extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'id',
        selected: [],
        data: [],
        getInboxCaseData: [],
        page: 0,
        pageNumber: 0,
        totalRow: 0,
        rowsPerPage: 100,
        renderModal: false,
        appUid: '',
        inboxModal: false,
        inboxModalSubmitted: false,
        serviceType: '',
        subserviceType: '',
        jointAccountCustomerNumber: '',
        taskTitle: '',
        status: '',
        freeFlag1: '',
        freeFlag2: '',
        freeFlag3: '',
        freeFlag4: '',
        recpmtid: '',
        cb_number: "",
        account_number: "",
        urgency: '',
        sentByUsername: '',
        redirectLogin: false,
        selectTab: "",
        err: false,
        errorArray: {},
        errorMessages: {},
        errorReturn: {},
        inputData: {},
        getData: false,
        activeItem: 'Group Inbox',
        backgroundColor: 'red',
        category: "",
        delStatus: "",
        subCategory: "",
        solId: "",
        loading: false,
        alowApiCall: true,
        getCountInbox: 0,
        getCountReturn: 0,
        getCountRectified: 0,
        getCountSubmit: 0,
        getCountPending: 0,
        getCountGroupInbox: 0,
        getCountCertificateInbox: 0,
        getCountCompleteInbox: 0,
        returnBackTo: "",
        selectedDate: {},
        businessSegment: "",
        priorityDesc: "",
        customer_name: "",
        title: "",
        notificationMessage: "",
        alert: false,
        branchingCategory: "",
        branchingSubCategory: "",
        freeFlag5: "",
        freeFlag6: "",
        rows: [
            //{id: 'id', numeric: true, disablePadding: true, label: 'SL'},
            {id: 'SL', numeric: false, disablePadding: true, label: 'SL'},
            {id: 'CaseId', numeric: false, disablePadding: true, label: 'Case ID'},
            {id: 'customer_name', numeric: false, disablePadding: true, label: 'Name'},
            {id: 'cb_number', numeric: false, disablePadding: true, label: 'CB Number'},
            {id: 'account_number', numeric: false, disablePadding: true, label: 'Account Number'},
            {id: 'category_type', numeric: false, disablePadding: true, label: 'Category'},
            {id: 'sub_category_type', numeric: false, disablePadding: true, label: 'Sub Category'},
            {id: 'service', numeric: false, disablePadding: true, label: 'Service'},
            {id: 'Date', numeric: false, disablePadding: true, label: 'Date of Activity'},
            {id: 'currentUserName', numeric: false, disablePadding: true, label: 'Current User'},
            {id: 'branch_name', numeric: false, disablePadding: true, label: 'Branch Name'},
            {id: 'Status', numeric: false, disablePadding: true, label: 'Status'},
            /*  {id: 'View', numeric: false, disablePadding: false, label: 'Action'}*/
        ]

    };
    findByVarNameApiData = (searchVarname, getValue) => {
        console.log(searchVarname);
        console.log(getValue);
        let jsonArray = filteringJsonForm;
        for (let i = 0; i < jsonArray.length; i++) {

            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                console.log(getValue);
                let data = [];
                /* for(let j=0;j<getValue.length;j++){
                     let key=getValue[j].key;
                     let value=getValue[j].value;
                     data.push({key,value})
                 }*/
                filteringJsonForm[i].enum = getValue;

            }
        }
    };
    returnAccountNumber = (branchingAccountNumber, account_number) => {
        if (branchingAccountNumber !== undefined && branchingAccountNumber !== null && branchingAccountNumber !== "") {
            return branchingAccountNumber;
        } else {
            return account_number;
        }

    };
    returnCategory = (branchingcategory, freeFlag5, category) => {
        if (branchingcategory !== undefined && branchingcategory !== null && branchingcategory !== "") {
            return branchingcategory
        } else if (freeFlag5 !== undefined && freeFlag5 !== null && freeFlag5 !== "") {
            return freeFlag5
        } else {
            return category
        }

    };
    returnSubCategory = (branchingSubCategory, freeFlag6, subCategory) => {
        if (branchingSubCategory !== undefined && branchingSubCategory !== null && branchingSubCategory !== "") {
            return branchingSubCategory
        } else if (freeFlag6 !== undefined && freeFlag6 !== null && freeFlag6 !== "") {
            return freeFlag6
        } else {
            return subCategory
        }

    };

    componentDidMount() {
        this.setState({
            loading: true
        });
        let url1 = backEndServerURL + "/branchMaster/getAll";
        axios.get(url1, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((data) => {
                    getAllBranchType.push({
                        value: data.branchName,
                        key: data.solId
                    })
                });
                console.log("branchdata");
                console.log(getAllBranchType);
                {
                    this.findByVarNameApiData("branch_id", getAllBranchType)
                }


            })
            .catch((error) => {
                console.log(error)
            });

        const data = [];
        let url = "";
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MERCHANT" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CS" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BOM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "INTERNET_BANKING" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "ADC") {

            url = backEndServerURL + '/workspaceClaimable';
        } else {
            url = backEndServerURL + '/claimable/withoutRectified';
        }
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CS" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "DSTCS") {
            url = backEndServerURL + '/inbox/return';
            this.setState({
                activeItem: 'Return',
            })
        }

        axios.get(url, {
            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
        })
            .then(response => {
                console.log(response.data);

                let countUrl = backEndServerURL + "/inbox/getCountCategoryWise";
                axios.get(countUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        let getData = response.data;
                        this.setState({
                            getCountInbox: getData.inbox,
                            getCountReturn: getData.return,
                            getCountRectified: getData.rectified,
                            getCountSubmit: getData.inbox,
                            getCountPending: getData.draft,
                            getCountGroupInbox: getData.teamGroup,
                            getCountCertificateInbox: getData.inbox,
                            getCountCompleteInbox: getData.inbox,
                        });

                        LocalstorageEncrypt.encryptStorageFunction.setItem("inboxNumberOfCount", Number(getData.inbox) + Number(getData.return) + Number(getData.draft));

                        console.log(response.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                if (this.state.activeItem === "Submit" || this.state.activeItem === "SD PENDING CASE") {

                    response.data.content.map((message, index) => {

                        data.push(createData(index, message.branchingCategory, message.branchingSubCategory, message.freeFlag5, message.freeFlag6, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1, message.freeFlag2, message.freeFlag3, message.freeFlag4, message.recpmtid, message.urgency, "", "", message.priorityDesc));

                    });
                } else if (this.state.activeItem === "Return") {

                    response.data.map((message, index) => {

                        data.push(createData(index, message.branchingCategory, message.branchingSubCategory, message.freeFlag5, message.freeFlag6, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1, message.freeFlag2, message.freeFlag3, message.freeFlag4, message.recpmtid, message.urgency, message.sentByUsername, "", message.priorityDesc));

                    });
                }
                /*else if (this.state.activeItem === "RECTIFIED REQUEST") {

                            response.data.map((message, index) => {

                                data.push(createData(index,message.branchingCategory,message.branchingSubCategory, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1,message.freeFlag2,message.freeFlag3,message.freeFlag4,message.recpmtid, message.urgency, message.sentByUsername, "",message.priorityDesc));

                            });
                        }*/
                else {
                    response.data.map((message, index) => {

                        data.push(createData(index, message.branchingCategory, message.branchingSubCategory, message.freeFlag5, message.freeFlag6, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1, message.freeFlag2, message.freeFlag3, message.freeFlag4, message.recpmtid, message.urgency, "", message.returnBackTo, message.priorityDesc));

                    });
                }

                this.setState({
                    getData: true,
                    data: data,
                    loading: false,
                    getInboxCaseData: data
                });


            })

            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false
                })

            });


    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({order, orderBy});
    };

    handleSelectAllClick = event => {
        if (event.target.checked) {
            this.setState(state => ({selected: state.data.map(n => n.appUid)}));
            return;
        }
        this.setState({selected: []});
    };

    handleClick = (event, id) => {
        const {selected} = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({selected: newSelected});
    };

    handleChangePage = (event, page) => {
        event.preventDefault();
        this.setState({
            page
        });
        if (this.state.activeItem === "Submit" || this.state.activeItem === "SD PENDING CASE") {
            this.setState({
                inboxModal: false,
                inboxModalSubmitted: false,
                loading: true


            });
            let urls = "";
            if (this.state.activeItem === "Inbox") {
                urls = backEndServerURL + '/inbox/waiting';
            } else if (this.state.activeItem === "Return") {
                urls = backEndServerURL + '/inbox/return';

            } else if (this.state.activeItem === "Pending") {
                urls = backEndServerURL + '/inbox/saved';
            } else if (this.state.activeItem === "Submit") {
                urls = backEndServerURL + '/perticipated/' + page;
            } else if (this.state.activeItem === "SD PENDING CASE") {
                urls = backEndServerURL + '/shadowedCasesTD/' + page;
            } else if (this.state.activeItem === "Certificate") {
                urls = backEndServerURL + '/getInboxTabData/Certificate';
            } else if (this.state.activeItem === "Complete") {
                urls = backEndServerURL + '/perticipatedClosed/' + page;
            } else if (this.state.activeItem === "Group Inbox") {
                if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MERCHANT" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CS" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BOM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "INTERNET_BANKING" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "ADC") {

                    urls = backEndServerURL + '/workspaceClaimable';
                } else {
                    urls = backEndServerURL + '/claimable/withoutRectified';
                }

            } else if (this.state.activeItem === "RECTIFIED REQUEST") {
                if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MERCHANT" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BOM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "INTERNET_BANKING" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "ADC") {

                    urls = backEndServerURL + '/workspaceClaimable';
                } else {
                    urls = backEndServerURL + '/claimable/withRectified';
                }

            }

            let data = [];
            axios.get(urls, {
                headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
            })
                .then(response => {
                    console.log(response.data);
                    let totalRow = response.data.totalElements;
                    let countUrl = backEndServerURL + "/inbox/getCountCategoryWise";
                    axios.get(countUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            let getData = response.data;
                            console.log(response.data);
                            this.setState({
                                getCountInbox: getData.inbox,
                                getCountReturn: getData.return,
                                getCountRectified: getData.rectified,
                                getCountSubmit: getData.inbox,
                                getCountPending: getData.draft,
                                getCountGroupInbox: getData.teamGroup,
                                getCountCertificateInbox: getData.inbox,
                                getCountCompleteInbox: getData.inbox,


                            });

                            LocalstorageEncrypt.encryptStorageFunction.setItem("inboxNumberOfCount", Number(getData.inbox) + Number(getData.return) + Number(getData.draft));

                            console.log(response.data);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    if (this.state.activeItem === "Submit" || this.state.activeItem === "SD PENDING CASE") {
                        console.log(response.data.content);
                        response.data.content.map((message, index) => {
                            console.log("pp");
                            data.push(createData(index, message.branchingCategory, message.branchingSubCategory, message.freeFlag5, message.freeFlag6, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1, message.freeFlag2, message.freeFlag3, message.freeFlag4, message.recpmtid, message.urgency, "", "", message.priorityDesc));

                        });
                        console.log(data);
                        this.setState({
                            pageNumber: page,
                            data: data,
                            loading: false,
                            getInboxCaseData: data,
                            totalRow: response.data.totalElements,
                        });
                    } else if (this.state.activeItem === "Return") {
                        response.data.map((message, index) => {
                            console.log(index);
                            data.push(createData(index, message.branchingCategory, message.branchingSubCategory, message.freeFlag5, message.freeFlag6, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1, message.freeFlag2, message.freeFlag3, message.freeFlag4, message.recpmtid, message.urgency, message.sentByUsername, "", message.priorityDesc));

                        });

                        this.setState({
                            pageNumber: page,
                            totalRow: response.data.totalElements,
                            data: data,
                            loading: false,
                            getInboxCaseData: data
                        });
                    } else {
                        response.data.map((message, index) => {

                            data.push(createData(index, message.branchingCategory, message.branchingSubCategory, message.freeFlag5, message.freeFlag6, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1, message.freeFlag2, message.freeFlag3, message.freeFlag4, message.recpmtid, message.urgency, "", message.returnBackTo, message.priorityDesc));

                        });
                        this.setState({
                            data: data,
                            loading: false,
                            getInboxCaseData: data
                        });
                    }


                })

                .catch(error => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })

                });
        }

    };

    handleChangeRowsPerPage = event => {
        this.setState({rowsPerPage: event.target.value});
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    viewCase = (appUid, serviceType, subserviceType, taskTitle, jointAccountCustomerNumber, category, subCategory, delStatus, status, solId, freeFlag1, freeFlag2, freeFlag3, freeFlag4, recpmtid, urgency, sentByUsername, cb_number, account_number, customer_name) => {
        this.setState({
            serviceType: serviceType,
            subserviceType: subserviceType,
            inboxModal: true,
            category: category,
            subCategory: subCategory,
            solId: solId,
            appUid: appUid,
            delStatus: delStatus,
            taskTitle: taskTitle,
            status: status,
            freeFlag1: freeFlag1,
            freeFlag2: freeFlag2,
            freeFlag3: freeFlag3,
            freeFlag4: freeFlag4,
            cb_number: cb_number,
            account_number: account_number,
            customer_name: customer_name,
            urgency: urgency,
            recpmtid: recpmtid,
            sentByUsername: sentByUsername,
            jointAccountCustomerNumber: jointAccountCustomerNumber
        })

    };

    viewCaseSubmitted = (appUid, serviceType, subserviceType, taskTitle, jointAccountCustomerNumber, category, subCategory, delStatus, status, solId, freeFlag1, freeFlag2, freeFlag3, freeFlag4, recpmtid, urgency, cb_number, account_number, customer_name) => {
        this.setState({
            serviceType: serviceType,
            subserviceType: subserviceType,
            inboxModalSubmitted: true,
            category: category,
            subCategory: subCategory,
            solId: solId,
            appUid: appUid,
            delStatus: delStatus,
            taskTitle: taskTitle,
            status: status,
            freeFlag1: freeFlag1,
            freeFlag2: freeFlag2,
            freeFlag3: freeFlag3,
            freeFlag4: freeFlag4,
            cb_number: cb_number,
            account_number: account_number,
            customer_name: customer_name,
            recpmtid: recpmtid,
            urgency: urgency,
            jointAccountCustomerNumber: jointAccountCustomerNumber
        })

    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    viewCaseLockView = (appUid, serviceType, subserviceType, taskTitle, jointAccountCustomerNumber, category, subCategory, delStatus, status, solId, freeFlag1, freeFlag2, freeFlag3, freeFlag4, recpmtid, urgency, sentByUsername, cb_number, account_number, customer_name) => {

        if (this.state.alowApiCall === true) {
            this.setState({
                loading: true,
                alowApiCall: false
            });
            let url = backEndServerURL + "/case/claimReturn/" + appUid;

            axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        loading: false,
                        alowApiCall: true,
                        serviceType: serviceType,
                        subserviceType: subserviceType,
                        inboxModal: true,
                        category: category,
                        subCategory: subCategory,
                        solId: solId,
                        appUid: response.data,
                        delStatus: delStatus,
                        taskTitle: taskTitle,
                        status: status,
                        freeFlag1: freeFlag1,
                        freeFlag2: freeFlag2,
                        freeFlag3: freeFlag3,
                        freeFlag4: freeFlag4,
                        cb_number: cb_number,
                        account_number: account_number,
                        customer_name: customer_name,
                        recpmtid: recpmtid,
                        urgency: urgency,
                        sentByUsername: sentByUsername,
                        jointAccountCustomerNumber: jointAccountCustomerNumber
                    });

                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        alowApiCall: true,

                    })
                })

        }


    };
    viewCaseUnlock = (appUid, serviceType, subserviceType, taskTitle, jointAccountCustomerNumber, category, subCategory, delStatus, status, solId, freeFlag1, freeFlag2, freeFlag3, freeFlag4, recpmtid, urgency, sentByUsername, cb_number, account_number, customer_name) => {
        let url = backEndServerURL + "/case/unClaim";
        axios.post(url, [appUid], {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (this.state.activeItem === "Inbox") {
                    this.setState({
                        inboxModal: false,
                        inboxModalSubmitted: false,
                        loading: true


                    });
                    let urls = "";
                    if (this.state.activeItem === "Inbox") {
                        urls = backEndServerURL + '/inbox/waiting';
                    } else if (this.state.activeItem === "Return") {
                        urls = backEndServerURL + '/inbox/return';
                    } else if (this.state.activeItem === "Pending") {
                        urls = backEndServerURL + '/inbox/saved';
                    } else if (this.state.activeItem === "Submit") {
                        urls = backEndServerURL + '/perticipated/0';
                    } else if (this.state.activeItem === "SD PENDING CASE") {
                        urls = backEndServerURL + '/shadowedCasesTD/0';
                    } else if (this.state.activeItem === "Certificate") {
                        urls = backEndServerURL + '/getInboxTabData/Certificate';
                    } else if (this.state.activeItem === "Complete") {
                        urls = backEndServerURL + '/perticipatedClosed/0'
                    } else if (this.state.activeItem === "Group Inbox") {
                        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MERCHANT" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CS" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BOM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "INTERNET_BANKING" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "ADC") {

                            urls = backEndServerURL + '/workspaceClaimable';
                        } else {
                            urls = backEndServerURL + '/claimable/withoutRectified';
                        }

                    } else if (this.state.activeItem === "RECTIFIED REQUEST") {
                        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MERCHANT" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BOM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "INTERNET_BANKING" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "ADC") {

                            urls = backEndServerURL + '/workspaceClaimable';
                        } else {
                            urls = backEndServerURL + '/claimable/withRectified';
                        }

                    }

                    let data = [];
                    axios.get(urls, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                        .then(response => {
                            console.log(response.data);
                            let countUrl = backEndServerURL + "/inbox/getCountCategoryWise";
                            axios.get(countUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    let getData = response.data;
                                    console.log(response.data);
                                    this.setState({
                                        getCountInbox: getData.inbox,
                                        getCountReturn: getData.return,
                                        getCountRectified: getData.rectified,
                                        getCountSubmit: getData.inbox,
                                        getCountPending: getData.draft,
                                        getCountGroupInbox: getData.teamGroup,
                                        getCountCertificateInbox: getData.inbox,
                                        getCountCompleteInbox: getData.inbox,
                                    });

                                    LocalstorageEncrypt.encryptStorageFunction.setItem("inboxNumberOfCount", Number(getData.inbox) + Number(getData.return) + Number(getData.draft));

                                    console.log(response.data);
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                            if (this.state.activeItem === "Submit" || this.state.activeItem === "SD PENDING CASE") {
                                response.data.content.map((message, index) => {
                                    console.log(index);
                                    data.push(createData(index, message.branchingCategory, message.branchingSubCategory, message.freeFlag5, message.freeFlag6, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1, message.freeFlag2, message.freeFlag3, message.freeFlag4, message.recpmtid, message.urgency, "", "", message.priorityDesc));

                                });
                            } else if (this.state.activeItem === "Return") {
                                response.data.map((message, index) => {

                                    data.push(createData(index, message.branchingCategory, message.branchingSubCategory, message.freeFlag5, message.freeFlag6, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1, message.freeFlag2, message.freeFlag3, message.freeFlag4, message.recpmtid, message.urgency, message.sentByUsername, "", message.priorityDesc));

                                });
                            } /*else if (this.state.activeItem === "RECTIFIED REQUEST") {
                    response.data.map((message, index) => {

                        data.push(createData(index,message.branchingCategory,message.branchingSubCategory, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1,message.freeFlag2,message.freeFlag3,message.freeFlag4,message.recpmtid, message.urgency, message.sentByUsername, "",message.priorityDesc));

                    });
                }*/ else {
                                response.data.map((message, index) => {

                                    data.push(createData(index, message.branchingCategory, message.branchingSubCategory, message.freeFlag5, message.freeFlag6, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1, message.freeFlag2, message.freeFlag3, message.freeFlag4, message.recpmtid, message.urgency, "", message.returnBackTo, message.priorityDesc));

                                });
                            }

                            this.setState({
                                data: data,
                                loading: false,
                                getInboxCaseData: data
                            });


                        })

                        .catch(error => {
                            console.log(error);
                            this.setState({
                                loading: false
                            })

                        });


                }
                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully UnLock!",
                    alert: true,
                    loading: false,
                });


                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            })


    };
    closeIcon = () => {

        if (this.state.activeItem === "Group Inbox" || this.state.activeItem === "RECTIFIED REQUEST") {
            this.setState({
                inboxModal: false,
                inboxModalSubmitted: false,
                loading: true


            });
            let urls = "";
            if (this.state.activeItem === "Inbox") {
                urls = backEndServerURL + '/inbox/waiting';
            } else if (this.state.activeItem === "Return") {
                urls = backEndServerURL + '/inbox/return';
            } else if (this.state.activeItem === "Pending") {
                urls = backEndServerURL + '/inbox/saved';
            } else if (this.state.activeItem === "Submit") {
                urls = backEndServerURL + '/perticipated/0';
            } else if (this.state.activeItem === "SD PENDING CASE") {
                urls = backEndServerURL + '/shadowedCasesTD/0';
            } else if (this.state.activeItem === "Certificate") {
                urls = backEndServerURL + '/getInboxTabData/Certificate';
            } else if (this.state.activeItem === "Complete") {
                urls = backEndServerURL + '/perticipatedClosed/0'
            } else if (this.state.activeItem === "Group Inbox") {
                if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MERCHANT" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CS" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BOM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "INTERNET_BANKING" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "ADC") {

                    urls = backEndServerURL + '/workspaceClaimable';
                } else {
                    urls = backEndServerURL + '/claimable/withoutRectified';
                }

            } else if (this.state.activeItem === "RECTIFIED REQUEST") {
                if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MERCHANT" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BOM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "INTERNET_BANKING" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "ADC") {

                    urls = backEndServerURL + '/workspaceClaimable';
                } else {
                    urls = backEndServerURL + '/claimable/withRectified';
                }

            }

            let data = [];
            axios.get(urls, {
                headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
            })
                .then(response => {
                    console.log(response.data);
                    let countUrl = backEndServerURL + "/inbox/getCountCategoryWise";
                    axios.get(countUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            let getData = response.data;
                            console.log(response.data);
                            this.setState({
                                getCountInbox: getData.inbox,
                                getCountReturn: getData.return,
                                getCountRectified: getData.rectified,
                                getCountSubmit: getData.inbox,
                                getCountPending: getData.draft,
                                getCountGroupInbox: getData.teamGroup,
                                getCountCertificateInbox: getData.inbox,
                                getCountCompleteInbox: getData.inbox,
                            });

                            LocalstorageEncrypt.encryptStorageFunction.setItem("inboxNumberOfCount", Number(getData.inbox) + Number(getData.return) + Number(getData.draft));

                            console.log(response.data);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    if (this.state.activeItem === "Submit" || this.state.activeItem === "SD PENDING CASE") {
                        response.data.content.map((message, index) => {
                            console.log(index);
                            data.push(createData(index, message.branchingCategory, message.branchingSubCategory, message.freeFlag5, message.freeFlag6, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1, message.freeFlag2, message.freeFlag3, message.freeFlag4, message.recpmtid, message.urgency, "", "", message.priorityDesc));

                        });
                    } else if (this.state.activeItem === "Return") {
                        response.data.map((message, index) => {

                            data.push(createData(index, message.branchingCategory, message.branchingSubCategory, message.freeFlag5, message.freeFlag6, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1, message.freeFlag2, message.freeFlag3, message.freeFlag4, message.recpmtid, message.urgency, message.sentByUsername, "", message.priorityDesc));

                        });
                    } /*else if (this.state.activeItem === "RECTIFIED REQUEST") {
                    response.data.map((message, index) => {

                        data.push(createData(index,message.branchingCategory,message.branchingSubCategory, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1,message.freeFlag2,message.freeFlag3,message.freeFlag4,message.recpmtid, message.urgency, message.sentByUsername, "",message.priorityDesc));

                    });
                }*/ else {
                        response.data.map((message, index) => {

                            data.push(createData(index, message.branchingCategory, message.branchingSubCategory, message.freeFlag5, message.freeFlag6, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1, message.freeFlag2, message.freeFlag3, message.freeFlag4, message.recpmtid, message.urgency, "", message.returnBackTo, message.priorityDesc));

                        });
                    }

                    this.setState({
                        data: data,
                        loading: false,
                        getInboxCaseData: data
                    });


                })

                .catch(error => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })

                });


        } else {
            this.setState({
                inboxModal: false,
                inboxModalSubmitted: false
            })
        }
    };
    closeModal = () => {
        this.setState({
            inboxModal: false,
            inboxModalSubmitted: false,
            loading: true


        });
        let urls = "";
        if (this.state.activeItem === "Inbox") {
            urls = backEndServerURL + '/inbox/waiting';
        } else if (this.state.activeItem === "Return") {
            urls = backEndServerURL + '/inbox/return';
        } else if (this.state.activeItem === "Pending") {
            urls = backEndServerURL + '/inbox/saved';
        } else if (this.state.activeItem === "Submit") {
            urls = backEndServerURL + '/perticipated/0';
        } else if (this.state.activeItem === "SD PENDING CASE") {
            urls = backEndServerURL + '/shadowedCasesTD/0';
        } else if (this.state.activeItem === "Certificate") {
            urls = backEndServerURL + '/getInboxTabData/Certificate';
        } else if (this.state.activeItem === "Complete") {
            urls = backEndServerURL + '/perticipatedClosed/0'
        } else if (this.state.activeItem === "Group Inbox") {
            if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MERCHANT" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CS" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BOM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "INTERNET_BANKING" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "ADC") {

                urls = backEndServerURL + '/workspaceClaimable';
            } else {
                urls = backEndServerURL + '/claimable/withoutRectified';
            }

        } else if (this.state.activeItem === "RECTIFIED REQUEST") {
            if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MERCHANT" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BOM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "INTERNET_BANKING" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "ADC") {

                urls = backEndServerURL + '/workspaceClaimable';
            } else {
                urls = backEndServerURL + '/claimable/withRectified';
            }

        }

        let data = [];
        axios.get(urls, {
            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
        })
            .then(response => {
                console.log(response.data);
                let countUrl = backEndServerURL + "/inbox/getCountCategoryWise";
                axios.get(countUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        let getData = response.data;
                        console.log(response.data);
                        this.setState({
                            getCountInbox: getData.inbox,
                            getCountReturn: getData.return,
                            getCountRectified: getData.rectified,
                            getCountSubmit: getData.inbox,
                            getCountPending: getData.draft,
                            getCountGroupInbox: getData.teamGroup,
                            getCountCertificateInbox: getData.inbox,
                            getCountCompleteInbox: getData.inbox,
                        });

                        LocalstorageEncrypt.encryptStorageFunction.setItem("inboxNumberOfCount", Number(getData.inbox) + Number(getData.return) + Number(getData.draft));

                        console.log(response.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                if (this.state.activeItem === "Submit" || this.state.activeItem === "SD PENDING CASE") {
                    response.data.content.map((message, index) => {
                        console.log(index);
                        data.push(createData(index, message.branchingCategory, message.branchingSubCategory, message.freeFlag5, message.freeFlag6, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1, message.freeFlag2, message.freeFlag3, message.freeFlag4, message.recpmtid, message.urgency, "", "", message.priorityDesc));

                    });
                } else if (this.state.activeItem === "Return") {
                    response.data.map((message, index) => {

                        data.push(createData(index, message.branchingCategory, message.branchingSubCategory, message.freeFlag5, message.freeFlag6, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1, message.freeFlag2, message.freeFlag3, message.freeFlag4, message.recpmtid, message.urgency, message.sentByUsername, "", message.priorityDesc));

                    });
                } /*else if (this.state.activeItem === "RECTIFIED REQUEST") {
                    response.data.map((message, index) => {

                        data.push(createData(index,message.branchingCategory,message.branchingSubCategory, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1,message.freeFlag2,message.freeFlag3,message.freeFlag4,message.recpmtid, message.urgency, message.sentByUsername, "",message.priorityDesc));

                    });
                }*/ else {
                    response.data.map((message, index) => {

                        data.push(createData(index, message.branchingCategory, message.branchingSubCategory, message.freeFlag5, message.freeFlag6, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1, message.freeFlag2, message.freeFlag3, message.freeFlag4, message.recpmtid, message.urgency, "", message.returnBackTo, message.priorityDesc));

                    });
                }

                this.setState({
                    data: data,
                    loading: false,
                    getInboxCaseData: data
                });


            })

            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false
                })

            });


    };
    renderInboxCaseSubmitted = () => {
        return (
            <SubmittedCaseHistory delStatus={this.state.delStatus} closeModal={this.closeModal}
                                  closeIcon={this.closeIcon}
                                  appId={this.state.appUid}
                                  subCategory={this.state.subCategory}
                                  category={this.state.category} serviceType={this.state.serviceType}
                                  freeFlag1={this.state.freeFlag1}
                                  freeFlag2={this.state.freeFlag2}
                                  freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                                  recpmtid={this.state.recpmtid}
                                  accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                                  customerName={this.state.customer_name}
                                  solId={this.state.solId}
                                  subServiceType={this.state.subserviceType} taskTitle={this.state.taskTitle}/>
        )


    };
    renderInboxCase = () => {

        console.log(this.state.category);
        console.log(this.state.account_number);
        console.log(this.state.accountNumber);
        console.log(LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup"));

        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "DSTCS" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CALL_CENTER") {
            return (

                <CSInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeIcon}
                             appUid={this.state.appUid}


                             delStatus={this.state.delStatus} subCategory={this.state.subCategory}
                             category={this.state.category} serviceType={this.state.serviceType}
                             freeFlag1={this.state.freeFlag1}
                             freeFlag2={this.state.freeFlag2}
                             freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                             recpmtid={this.state.recpmtid}
                             accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                             customerName={this.state.customer_name}
                             solId={this.state.solId}
                             subServiceType={this.state.subserviceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CS" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CALL_CENTER") {
            return (

                <CSInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeIcon}
                             appUid={this.state.appUid}


                             subCategory={this.state.subCategory} category={this.state.category}
                             serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                             freeFlag2={this.state.freeFlag2}
                             freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                             recpmtid={this.state.recpmtid}
                             accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                             customerName={this.state.customer_name}
                             solId={this.state.solId}
                             subServiceType={this.state.subserviceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('APPROVALOFFICER') !== -1) {
            return (
                <BMInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeIcon}
                             appUid={this.state.appUid}
                             subCategory={this.state.subCategory} category={this.state.category}
                             serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                             freeFlag2={this.state.freeFlag2}
                             freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                             recpmtid={this.state.recpmtid}
                             accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                             customerName={this.state.customer_name}
                             solId={this.state.solId}
                             subServiceType={this.state.subserviceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('ABHCHECKER') !== -1) {
            return (
                <BOMInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeIcon}
                              appUid={this.state.appUid}
                              subCategory={this.state.subCategory} category={this.state.category}
                              serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                              freeFlag2={this.state.freeFlag2}
                              freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                              recpmtid={this.state.recpmtid}
                              accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                              customerName={this.state.customer_name}
                              solId={this.state.solId}
                              subServiceType={this.state.subserviceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('CALLCENTER') !== -1) {

            return (
                <CallCenterInboxCase closeModal={this.closeModal} closeIcon={this.closeIcon} appUid={this.state.appUid}
                                     serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                                     freeFlag2={this.state.freeFlag2}
                                     freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                                     recpmtid={this.state.recpmtid}
                                     accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                                     customerName={this.state.customer_name}
                                     solId={this.state.solId}
                                     subServiceType={this.state.subserviceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('INTERNET_BANKING') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('ADC') !== -1) {
            return (
                <BMInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeIcon}
                             appUid={this.state.appUid}
                             subCategory={this.state.subCategory} category={this.state.category}
                             serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                             freeFlag2={this.state.freeFlag2}
                             freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                             recpmtid={this.state.recpmtid}
                             accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                             customerName={this.state.customer_name}
                             solId={this.state.solId}
                             subServiceType={this.state.subserviceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('ABHMAKER') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('CALL_CENTER') !== -1) {
            return (

                <CSInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeIcon}
                             appUid={this.state.appUid}


                             subCategory={this.state.subCategory} category={this.state.category}
                             serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                             freeFlag2={this.state.freeFlag2}
                             freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                             recpmtid={this.state.recpmtid}
                             accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                             customerName={this.state.customer_name}
                             solId={this.state.solId}
                             subServiceType={this.state.subserviceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('DSTBOM') !== -1) {
            return (
                <BOMInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeIcon}
                              appUid={this.state.appUid}
                              subCategory={this.state.subCategory} category={this.state.category}
                              serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                              freeFlag2={this.state.freeFlag2}
                              freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                              recpmtid={this.state.recpmtid}
                              accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                              customerName={this.state.customer_name}
                              solId={this.state.solId}
                              subServiceType={this.state.subserviceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf("BUSINESS_DIVISION") !== -1) {

            return (
                <BMInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeIcon}
                             appUid={this.state.appUid}
                             subCategory={this.state.subCategory} category={this.state.category}
                             serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                             freeFlag2={this.state.freeFlag2}
                             freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                             recpmtid={this.state.recpmtid}
                             accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                             customerName={this.state.customer_name}
                             solId={this.state.solId}
                             subServiceType={this.state.subserviceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('BOM') !== -1) {

            return (
                <BOMInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeIcon}
                              appUid={this.state.appUid}
                              subCategory={this.state.subCategory} category={this.state.category}
                              serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                              freeFlag2={this.state.freeFlag2}
                              freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                              recpmtid={this.state.recpmtid}
                              accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                              customerName={this.state.customer_name}
                              solId={this.state.solId}
                              subServiceType={this.state.subserviceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('BM') !== -1) {
            return (
                <BMInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeIcon}
                             appUid={this.state.appUid}
                             subCategory={this.state.subCategory} category={this.state.category}
                             serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                             freeFlag2={this.state.freeFlag2}
                             freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                             recpmtid={this.state.recpmtid}
                             accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                             customerName={this.state.customer_name}
                             solId={this.state.solId}
                             subServiceType={this.state.subserviceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MAKER') !== -1) {

            return (

                <MakerInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeIcon}
                                appUid={this.state.appUid}


                                subCategory={this.state.subCategory} category={this.state.category}
                                solId={this.state.solId}
                                serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                                freeFlag2={this.state.freeFlag2}
                                freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                                recpmtid={this.state.recpmtid}
                                accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                                customerName={this.state.customer_name}
                                solId={this.state.solId}
                                subServiceType={this.state.subserviceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MAKER') !== -1) {

            return (

                <MakerInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeIcon}
                                appUid={this.state.appUid}


                                subCategory={this.state.subCategory} category={this.state.category}
                                solId={this.state.solId}
                                serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                                freeFlag2={this.state.freeFlag2}
                                freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                                recpmtid={this.state.recpmtid}
                                accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                                customerName={this.state.customer_name}
                                solId={this.state.solId}
                                subServiceType={this.state.subserviceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('CHECKER') !== -1) {

            return (
                <CheckerInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal}
                                  closeIcon={this.closeIcon}
                                  appUid={this.state.appUid}


                                  subCategory={this.state.subCategory} category={this.state.category}
                                  serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                                  freeFlag2={this.state.freeFlag2}
                                  freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                                  recpmtid={this.state.recpmtid}
                                  accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                                  customerName={this.state.customer_name}
                                  solId={this.state.solId}
                                  subServiceType={this.state.subserviceType} taskTitle={this.state.taskTitle}/>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MERCHANT') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MERCHANT_DST') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MERCHANT_CALL_CENTER') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MBU') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('HOMBU') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MBU_DFF') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MOU_MAKER') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('MOU_CHECKER') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('FRM') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('FRM_CHECKER') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('FRM_APPROVAL') !== -1 || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('S_AND_A') !== -1) {

            return (
                <MerchantInboxCase delStatus={this.state.delStatus} closeModal={this.closeModal}
                                   closeIcon={this.closeIcon}
                                   appId={this.state.appUid}
                                   solId={this.state.solId}


                                   subCategory={this.state.subCategory} category={this.state.category}
                                   serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                                   freeFlag2={this.state.freeFlag2}
                                   freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                                   recpmtid={this.state.recpmtid}
                                   accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                                   customerName={this.state.customer_name}
                                   solId={this.state.solId}
                                   subServiceType={this.state.subserviceType} taskTitle={this.state.taskTitle}/>
            )
        }

    };
    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.getFilterSubmited(event);

        }

    };
    updateComponent = () => {
        this.forceUpdate(


        )
    };
    renderFilterForm = () => {
        return (
            CommonJsonFormComponent.renderJsonForm(this.state, filteringJsonForm, this.updateComponent, this.onKeyDownForDedup)
        )


    };

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === null || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };
    branchSearch = (branchName) => {
        let jsonArray = getAllBranchType;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            console.log(jsonObject.key);
            if (branchName === jsonObject.value) {
                return jsonObject.key;
                i = jsonArray.length;

            }
        }


    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'null' || clone[prop] === null || clone[prop] === 'undefined')
                delete clone[prop];
        return clone;
    };

    getFilterSubmited = (event) => {
        event.preventDefault();

        let postData = this.removeNullValue(this.state.inputData);
        if (this.state.activeItem === "Group Inbox" && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf("INTERNET_BANKING") !== -1) {
            postData.tabName = "INTERNET_BANKING"
        } else if (this.state.activeItem === "Group Inbox") {
            postData.tabName = "Group Inbox"
        }

        if (Object.keys(postData).length === 0) {
            return 0;
        }
        this.setState({
            data: [],
            loading: true,
            getInboxCaseData: []
        });

        let status = this.state.activeItem === "Group Inbox" ? "Open" : this.state.activeItem === "Inbox" ? "Locked" : this.state.activeItem === "Pending" ? "Saved" : this.state.activeItem === "Certificate" ? "Enrollment" : this.state.activeItem;


        let url = backEndServerURL + "/filterUserInbox/" + status;

        let data = [];
        axios.post(url, postData, {
            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
        })
            .then(response => {
                response.data.map((message, index) => {
                    data.push(createData(index, message.branchingCategory, message.branchingSubCategory, message.freeFlag5, message.freeFlag6, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1, message.freeFlag2, message.freeFlag3, message.freeFlag4, message.recpmtid, message.urgency, "", message.returnBackTo ? message.returnBackTo : "", message.priorityDesc));

                });
                this.setState({
                    data: data,
                    loading: false,
                    getInboxCaseData: data,
                    totalRow: response.data.length,
                });


            })

            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false
                })

            });


        // counter = 0;
        // let objectTable = {};
        // let tableArray = [];
        // let trimData = "";
        // let getInputData = this.emptyValueRemove(this.state.inputData);
        // console.log(getInputData)
        // for (let variable in getInputData) {
        //     let inputData = getInputData[variable];
        //     if (inputData !== null && inputData !== 0 && inputData !== 1) {
        //         trimData = inputData;
        //     } else {
        //         trimData = inputData;
        //     }
        //     if (trimData !== '')
        //         objectTable[variable] = trimData;
        //
        // }
        // console.log(objectTable)
        // console.log(this.state.getInboxCaseData)
        // console.log("llll")
        // this.state.getInboxCaseData.map((inboxCase, index) => {
        //     let showable = true;
        //     for (let variable in objectTable) {
        //         if (objectTable[variable] !== inboxCase[variable])
        //             showable = false;
        //     }
        //
        //     if (showable)
        //         //let date = inboxCase.delInitTime.split("T")[0];
        //         //tableArray.push(createData(index,message.branchingCategory,message.branchingSubCategory, inboxCase.customer_name, inboxCase.cb_number, inboxCase.account_number, inboxCase.appUid, inboxCase.service_type, inboxCase.subservice_type, inboxCase.branch_id, inboxCase.taskTitle, inboxCase.jointAccountCustomerNumber, inboxCase.delInitTime, inboxCase.category, inboxCase.subCategory));
        //         tableArray.push(createData(index,message.branchingCategory,message.branchingSubCategory, inboxCase.customer_name, inboxCase.cb_number, inboxCase.account_number, inboxCase.appUid, inboxCase.service_type, inboxCase.subservice_type, inboxCase.branch_id, inboxCase.currentUserName, inboxCase.taskTitle, inboxCase.jointAccountCustomerNumber, inboxCase.delInitTime, inboxCase.category, inboxCase.subCategory, inboxCase.delStatus, inboxCase.caseId, inboxCase.status, inboxCase.solId, inboxCase.freeFlag1, inboxCase.urgency, inboxCase.message.sentByUsername));
        //
        // })
        // this.setState({
        //     data: tableArray
        // })
    };
    handleItemClick = (e, {name, style}) => {
        this.setState({
            data: [],
            loading: true,
            getInboxCaseData: []
        });
        this.setState({
                page: 0,
                pageNumber: 0,
                totalRow: 0,
                rowsPerPage: 100,
                activeItem: name,
                backgroundColor: style
            }
        );
        let urls = "";
        if (name === "Inbox") {
            urls = backEndServerURL + '/inbox/waiting';
        } else if (name === "Return") {
            urls = backEndServerURL + '/inbox/return';
        } else if (name === "RECTIFIED REQUEST") {
            urls = backEndServerURL + '/claimable/withRectified';
        } else if (name === "Pending") {
            urls = backEndServerURL + '/inbox/saved';
        } else if (name === "Submit") {
            urls = backEndServerURL + '/perticipated/0';
        } else if (name === "SD PENDING CASE") {
            urls = backEndServerURL + '/shadowedCasesTD/0';
        } else if (name === "Certificate") {
            urls = backEndServerURL + '/getInboxTabData/Certificate';
        } else if (name === "Complete") {
            urls = backEndServerURL + '/perticipatedClosed/0'
        } else if (name === "Group Inbox") {
            if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MERCHANT" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CS" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BOM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "INTERNET_BANKING" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "ADC") {

                urls = backEndServerURL + '/workspaceClaimable';
            } else {
                urls = backEndServerURL + '/claimable/withoutRectified';
            }

        }

        let data = [];
        axios.get(urls, {
            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
        })
            .then(response => {
                console.log(name);
                console.log(response.data);

                let countUrl = backEndServerURL + "/inbox/getCountCategoryWise";
                axios.get(countUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        let getData = response.data;
                        this.setState({
                            getCountInbox: getData.inbox,
                            getCountReturn: getData.return,
                            getCountRectified: getData.rectified,
                            getCountSubmit: getData.inbox,
                            getCountPending: getData.draft,
                            getCountGroupInbox: getData.teamGroup,
                            getCountCertificateInbox: getData.inbox,
                            getCountCompleteInbox: getData.inbox,
                        });
                        console.log(response.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                if (name === "Submit" || name === "SD PENDING CASE") {
                    response.data.content.map((message, index) => {
                        console.log(index);
                        data.push(createData(index, message.branchingCategory, message.branchingSubCategory, message.freeFlag5, message.freeFlag6, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1, message.freeFlag2, message.freeFlag3, message.freeFlag4, message.recpmtid, message.urgency, "", "", message.priorityDesc));

                    });
                    this.setState({
                        data: data,
                        loading: false,
                        getInboxCaseData: data,
                        totalRow: response.data.totalElements,
                    });
                } else if (this.state.activeItem === "Return") {
                    response.data.map((message, index) => {

                        data.push(createData(index, message.branchingCategory, message.branchingSubCategory, message.freeFlag5, message.freeFlag6, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1, message.freeFlag2, message.freeFlag3, message.freeFlag4, message.recpmtid, message.urgency, message.sentByUsername, ""));

                    });

                    this.setState({
                        data: data,
                        loading: false,
                        getInboxCaseData: data,
                        totalRow: response.data.totalElements,
                    });
                } else if (this.state.activeItem === "RECTIFIED REQUEST") {
                    response.data.map((message, index) => {

                        data.push(createData(index, message.branchingCategory, message.branchingSubCategory, message.freeFlag5, message.freeFlag6, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1, message.freeFlag2, message.freeFlag3, message.freeFlag4, message.recpmtid, message.urgency, message.sentByUsername, message.returnBackTo));

                    });

                    this.setState({
                        data: data,
                        loading: false,
                        getInboxCaseData: data,
                        totalRow: response.data.totalElements,
                    });
                } else {
                    response.data.map((message, index) => {

                        data.push(createData(index, message.branchingCategory, message.branchingSubCategory, message.freeFlag5, message.freeFlag6, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1, message.freeFlag2, message.freeFlag3, message.freeFlag4, message.recpmtid, message.urgency, "", message.returnBackTo, message.priorityDesc));

                    });
                    this.setState({
                        data: data,
                        loading: false,
                        getInboxCaseData: data
                    });
                }


            })

            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false
                })

            });


    };
    renderInboxTabShowTabName = () => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('BM') !== -1) {
            return "Self-Inbox"
        } else {
            return "Self-Inbox"
        }


    };
    renderInboxTabShow = () => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("menus").indexOf("INBOX") !== -1) {
            return (
                <Menu.Item
                    name='Inbox'
                    active={this.state.activeItem === 'Inbox'}
                    style={this.state.activeItem === 'Inbox' ? {
                        backgroundColor: "red",
                        color: "white",

                    } : {backgroundColor: "white", color: "black"}}
                    onClick={this.handleItemClick}
                >
                    <Badge badgeContent={this.state.getCountInbox} color="primary">
                        <div style={{marginTop: "8px"}}>
                            {this.renderInboxTabShowTabName()}
                        </div>
                    </Badge>
                </Menu.Item>
            )
        }
    };

    renderReturnTabShow = () => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("menus").indexOf("RETURN") !== -1) {
            return (
                <Menu.Item
                    name='Return' Sent for Correction
                    active={this.state.activeItem === 'Return'}
                    style={this.state.activeItem === 'Return' ? {
                        backgroundColor: "red",
                        color: "white",

                    } : {backgroundColor: "white", color: "black"}}
                    onClick={this.handleItemClick}
                >

                    <Badge badgeContent={this.state.getCountReturn} color="primary">
                        <div style={{marginTop: "8px"}}>
                            Returned Requests

                        </div>
                    </Badge>

                </Menu.Item>
            )
        }
    };
    renderRectifiedReturnTabShow = () => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("menus").indexOf("RECTIFIED REQUEST") !== -1) {
            return (
                <Menu.Item
                    name='RECTIFIED REQUEST'
                    active={this.state.activeItem === 'RECTIFIED REQUEST'}
                    style={this.state.activeItem === 'RECTIFIED REQUEST' ? {
                        backgroundColor: "red",
                        color: "white",

                    } : {backgroundColor: "white", color: "black"}}
                    onClick={this.handleItemClick}
                >
                    <Badge badgeContent={this.state.getCountRectified} color="primary">
                        <div style={{marginTop: "8px"}}>
                            Corrected Requests
                        </div>
                    </Badge>

                </Menu.Item>
            )
        }
    };
    renderPendingTabShow = () => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("menus").indexOf("PENDING") !== -1) {
            return (
                <Menu.Item
                    name='Pending'
                    active={this.state.activeItem === 'Pending'}
                    style={this.state.activeItem === 'Pending' ? {
                        backgroundColor: "red",
                        color: "white"
                    } : {backgroundColor: "white", color: "black"}}
                    onClick={this.handleItemClick}
                >
                    <Badge badgeContent={this.state.getCountPending} color="primary">
                        <div style={{marginTop: "8px"}}>
                            Pending Requests
                        </div>
                    </Badge>
                </Menu.Item>
            )
        }
    };
    renderSubmitTabShow = () => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("menus").indexOf("SUBMIT") !== -1) {
            return (
                <Menu.Item
                    name='Submit'
                    active={this.state.activeItem === 'Submit'}
                    style={this.state.activeItem === 'Submit' ? {
                        backgroundColor: "red",
                        color: "white"
                    } : {backgroundColor: "white", color: "black"}}
                    onClick={this.handleItemClick}
                >
                    <Badge color="primary">
                        <div style={{marginTop: "8px"}}>
                            Submit
                        </div>
                    </Badge>

                </Menu.Item>
            )
        }
    };
    renderSDPENDINGCASETabShow = () => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("menus").indexOf("SD PENDING CASE") !== -1) {
            return (
                <Menu.Item
                    name='SD PENDING CASE'
                    active={this.state.activeItem === 'SD PENDING CASE'}
                    style={this.state.activeItem === 'SD PENDING CASE' ? {
                        backgroundColor: "red",
                        color: "white"
                    } : {backgroundColor: "white", color: "black"}}
                    onClick={this.handleItemClick}
                >
                    <Badge color="primary">
                        <div style={{marginTop: "8px"}}>
                            SD Pending Case
                        </div>
                    </Badge>

                </Menu.Item>
            )
        }
    };
    handleChange = (name, value) => {
        this.setState({
            selectTab: value
        })
    };
    renderLockButton = () => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("menus").indexOf("GROUP LOCK") !== -1) {
            return (
                <Menu.Item
                    name='Group Inbox'
                    active={this.state.activeItem === 'Group Inbox'}
                    style={this.state.activeItem === 'Group Inbox' ? {
                        backgroundColor: "red",
                        color: "white"
                    } : {backgroundColor: "white", color: "black"}}
                    onClick={this.handleItemClick}
                >

                    <Badge badgeContent={this.state.getCountGroupInbox} color="primary">
                        <div style={{marginTop: "8px"}}>
                            Group Inbox
                        </div>
                    </Badge>

                </Menu.Item>
            )
        }
    };
    renderCompleteButton = () => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("menus").indexOf("COMPLETE") !== -1) {
            return (
                <Menu.Item
                    name='Complete'
                    active={this.state.activeItem === 'Complete'}
                    style={this.state.activeItem === 'Complete' ? {
                        backgroundColor: "red",
                        color: "white"
                    } : {backgroundColor: "white", color: "black"}}
                    onClick={this.handleItemClick}
                >
                    <Badge color="primary">
                        <div style={{marginTop: "8px"}}>
                            Complete
                        </div>
                    </Badge>


                </Menu.Item>
            )
        }


    };
    renderCertificateButton = () => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("menus").indexOf("CERTIFICATE") !== -1) {
            return (
                <Menu.Item
                    name='Certificate'
                    active={this.state.activeItem === 'Certificate'}
                    style={this.state.activeItem === 'Certificate' ? {
                        backgroundColor: "red",
                        color: "white"
                    } : {backgroundColor: "white", color: "black"}}
                    onClick={this.handleItemClick}
                >

                    <Badge color="primary">
                        <div style={{marginTop: "8px"}}>
                            Certificate
                        </div>
                    </Badge>
                </Menu.Item>
            )
        }
    };
    renderTab = (classes) => {
        return (
            <div style={{marginRight: "8px"}}>
                <Menu pointing>
                    {this.renderLockButton()}
                    {this.renderRectifiedReturnTabShow()}
                    {this.renderInboxTabShow()}
                    {this.renderSDPENDINGCASETabShow()}

                    {this.renderReturnTabShow()}

                    {this.renderPendingTabShow()}
                    {this.renderSubmitTabShow()}
                    {this.renderCompleteButton()}
                    {this.renderCertificateButton()}

                </Menu>


            </div>
        );
    };
    branchName = (tag) => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") !== "CS" && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") !== "DSTCS") {
            return tag


        }
    };

    functionForLock = () => {
        this.setState({
            loading: true
        });
        let single_url = backEndServerURL + "/case/claim/";
        axios.post(single_url, this.state.selected, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                const data = [];
                let url;
                if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MERCHANT" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CS" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BOM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "INTERNET_BANKING" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "ADC") {

                    url = backEndServerURL + '/workspaceClaimable';
                } else {
                    url = backEndServerURL + '/claimable/withoutRectified';
                }

                axios.get(url, {
                    headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                })
                    .then(response => {
                        console.log(response.data);
                        response.data.map((message, index) => {
                            data.push(createData(index, message.branchingCategory, message.branchingSubCategory, message.freeFlag5, message.freeFlag6, message.customerName, message.cbNumber, message.accountNumber, message.businessSegment, message.appId, message.serviceType, message.subServiceType, message.branchName, message.currentUserName, message.taskTitle, message.jointAccountCustomerNumber, message.delInitTime.replace("T", " "), message.category, message.subCategory, message.delStatus, message.caseId, message.status, message.solId, message.freeFlag1, message.freeFlag2, message.freeFlag3, message.freeFlag4, message.recpmtid, message.urgency, "", message.returnBackTo, message.priorityDesc));

                        });
                        this.setState({
                            getData: true,
                            data: data,
                            getInboxCaseData: data,
                            selected: [],
                            loading: false
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })

                    });


            }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false
            })
        })
    };


    render() {
        const {classes} = this.props;
        const {data, order, orderBy, selected, rowsPerPage, page} = this.state;
        const emptyRows = 0;
        {

            this.renderNotification()

        }
        {

            Functions.redirectToLogin(this.state)

        }
        if (this.state.activeItem === "Submit" || this.state.activeItem === "SD PENDING CASE") {
            return (
                <section>


                    <Paper className={classes.root}>

                        <GridContainer>

                            <GridItem xs={12} sm={12} md={12}>

                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h5 className={classes.cardTitleWhite}>Search</h5>


                                </CardHeader>

                                <CardBody>
                                    <Grid container spacing={1}>
                                        <ThemeProvider theme={theme}>

                                            {this.renderFilterForm()}

                                        </ThemeProvider>


                                    </Grid>
                                    <br/>


                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            verticalAlign: 'middle',
                                        }}
                                        onClick={this.getFilterSubmited}

                                    >
                                        Search
                                    </button>


                                </CardBody>

                            </GridItem>
                        </GridContainer>

                    </Paper>
                    <br/>
                    <Paper className={classes.root}>
                        <GridContainer>

                            <GridItem xs={12} sm={12} md={12}>

                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h5 className={classes.cardTitleWhite}>All Inbox</h5>

                                </CardHeader>
                                <br/>
                                {this.renderTab(classes.Tab)}

                                <div className={classes.tableWrapper}>
                                    <Table className={classes.table} aria-labelledby="tableTitle">
                                        <TableContentHead
                                            sty
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={this.handleSelectAllClick}
                                            onRequestSort={this.handleRequestSort}
                                            rowCount={data.length}
                                            getTab={this.state.activeItem}
                                        />
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="xl"
                                            fullScreen={true}
                                            open={this.state.inboxModal}>
                                            <DialogContent>

                                                {this.renderInboxCase()}
                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="xl"
                                            fullScreen={true}
                                            open={this.state.inboxModalSubmitted}>
                                            <DialogContent>

                                                {this.renderInboxCaseSubmitted()}
                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.loading}>
                                            <DialogContent className={classes.dialogPaper}>

                                                <center>
                                                    <img src={loader} alt=""/>
                                                </center>
                                            </DialogContent>
                                        </Dialog>
                                        <TableBody>
                                            {stableSort(data, getSorting(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map(n => {
                                                    const isSelected = this.isSelected(n.id);
                                                    return (

                                                        <TableRow
                                                            hover
                                                            style={{cursor: "pointer"}}
                                                            /* onClick={event => this.handleClick(event, n.id)}*/
                                                            role="checkbox"
                                                            aria-checked={isSelected}
                                                            tabIndex={-1}
                                                            key={n.id}
                                                            onClick={() => this.viewCaseSubmitted(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.urgency, n.cb_number, n.account_number, n.customer_name)}
                                                            selected={isSelected}
                                                        >

                                                            {/*<TableCell className={classes.tableCells} align="left">*/}

                                                            {/*</TableCell>*/}
                                                            <TableCell className={classes.tableCell} align="left">
                                                                {n.id}
                                                            </TableCell>
                                                            <TableCell className={classes.tableCell} align="left">
                                                                {n.caseId}
                                                            </TableCell>
                                                            <TableCell className={classes.tableCell} align="left">
                                                                {n.customer_name}
                                                            </TableCell>

                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.cb_number}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.account_number}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.businessSegment}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{this.returnCategory(n.branchingCategory, n.freeFlag5, n.category)}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{this.returnSubCategory(n.branchingSubCategory, n.freeFlag6, n.subCategory)}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.priorityDesc}</TableCell>

                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.delInitTime}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.currentUserName}</TableCell>
                                                            {this.branchName(<TableCell className={classes.tableCell}
                                                                                        align="left">{n.branch_id}</TableCell>)}
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.delStatus}</TableCell>


                                                        </TableRow>

                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{height: 49 * emptyRows}}>
                                                    <TableCell colSpan={6}/>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>

                                <TablePagination
                                    rowsPerPageOptions={[100]}
                                    component="div"
                                    count={this.state.totalRow}
                                    rowsPerPage={rowsPerPage}
                                    page={this.state.pageNumber}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    //  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    onChangePage={(event, page) =>
                                        this.handleChangePage(event, page)
                                    }
                                />
                            </GridItem>
                        </GridContainer>
                    </Paper>
                </section>
            );
        } else if (this.state.activeItem === "Complete") {
            return (
                <section>


                    <Paper className={classes.root}>

                        <GridContainer>

                            <GridItem xs={12} sm={12} md={12}>

                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h5 className={classes.cardTitleWhite}>Search</h5>


                                </CardHeader>

                                <CardBody>
                                    <Grid container spacing={1}>
                                        <ThemeProvider theme={theme}>

                                            {this.renderFilterForm()}

                                        </ThemeProvider>


                                    </Grid>
                                    <br/>


                                    <button
                                        className="btn btn-outline-primary"
                                        style={{
                                            verticalAlign: 'middle',
                                        }}
                                        onClick={this.getFilterSubmited}

                                    >
                                        Search
                                    </button>


                                </CardBody>

                            </GridItem>
                        </GridContainer>

                    </Paper>
                    <br/>
                    <Paper className={classes.root}>
                        <GridContainer>

                            <GridItem xs={12} sm={12} md={12}>

                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h5 className={classes.cardTitleWhite}>All Inbox</h5>

                                </CardHeader>
                                <br/>
                                {this.renderTab(classes.Tab)}

                                <div className={classes.tableWrapper}>
                                    <Table className={classes.table} aria-labelledby="tableTitle">
                                        <TableContentHead
                                            sty
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={this.handleSelectAllClick}
                                            onRequestSort={this.handleRequestSort}
                                            rowCount={data.length}
                                            getTab={this.state.activeItem}
                                        />
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="xl"
                                            fullScreen={true}
                                            open={this.state.inboxModal}>
                                            <DialogContent>

                                                {this.renderInboxCase()}
                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="xl"
                                            fullScreen={true}
                                            open={this.state.inboxModalSubmitted}>
                                            <DialogContent>

                                                {this.renderInboxCaseSubmitted()}
                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.loading}>
                                            <DialogContent className={classes.dialogPaper}>

                                                <center>
                                                    <img src={loader} alt=""/>
                                                </center>
                                            </DialogContent>
                                        </Dialog>
                                        <TableBody>
                                            {stableSort(data, getSorting(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map(n => {
                                                    const isSelected = this.isSelected(n.id);
                                                    return (
                                                        <TableRow
                                                            hover
                                                            style={{cursor: "pointer"}}
                                                            /* onClick={event => this.handleClick(event, n.id)}*/
                                                            role="checkbox"
                                                            aria-checked={isSelected}
                                                            tabIndex={-1}
                                                            key={n.id}
                                                            onClick={() => this.viewCaseSubmitted(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.urgency, n.cb_number, n.account_number, n.customer_name)}

                                                            //  onClick={() => this.viewCaseSubmitted(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.solId, n.freeFlag1,n.freeFlag2,n.freeFlag3,n.freeFlag4, n.urgency, "",n.cb_number,n.account_number,n.customer_name)}
                                                            selected={isSelected}
                                                        >

                                                            {/*<TableCell className={classes.tableCells} align="left">*/}

                                                            {/*</TableCell>*/}
                                                            <TableCell className={classes.tableCell} align="left">
                                                                {n.id}
                                                            </TableCell>
                                                            <TableCell className={classes.tableCell} align="left">
                                                                {n.caseId}
                                                            </TableCell>
                                                            <TableCell className={classes.tableCell} align="left">
                                                                {n.customer_name}
                                                            </TableCell>

                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.cb_number}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.account_number}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.businessSegment}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{this.returnCategory(n.branchingCategory, n.freeFlag5, n.category)}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{this.returnSubCategory(n.branchingSubCategory, n.freeFlag6, n.subCategory)}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.priorityDesc}</TableCell>

                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.delInitTime}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.currentUserName}</TableCell>
                                                            {this.branchName(<TableCell className={classes.tableCell}
                                                                                        align="left">{n.branch_id}</TableCell>)}
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.delStatus}</TableCell>
                                                            <TableCell className={classes.tableCell} align="left">

                                                                {/* <ThemeProvider theme={theme}>
                                                                <Button
                                                                    onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber)}
                                                                    variant="contained" color="secondary"
                                                                    className={classes.margin}>
                                                                    View
                                                                </Button>
                                                            </ThemeProvider>*/}
                                                            </TableCell>


                                                        </TableRow>
                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{height: 49 * emptyRows}}>
                                                    <TableCell colSpan={6}/>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>

                                <TablePagination
                                    rowsPerPageOptions={[100]}
                                    component="div"
                                    count={this.state.totalRow}
                                    rowsPerPage={rowsPerPage}
                                    page={this.state.pageNumber}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    //  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    onChangePage={(event, page) =>
                                        this.handleChangePage(event, page)
                                    }
                                />
                            </GridItem>
                        </GridContainer>
                    </Paper>
                </section>
            );
        }
        /*  else if (this.state.activeItem === "Complete") {
              return (
                  <section>


                      <Paper className={classes.root}>

                          <GridContainer>

                              <GridItem xs={12} sm={12} md={12}>

                                  <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                      <h5 className={classes.cardTitleWhite}>Search</h5>


                                  </CardHeader>

                                  <CardBody>
                                      <Grid container spacing={1}>
                                          <ThemeProvider theme={theme}>

                                              {this.renderFilterForm()}

                                          </ThemeProvider>


                                      </Grid>
                                      <br/>


                                      <button
                                          className="btn btn-outline-primary"
                                          style={{
                                              verticalAlign: 'middle',
                                          }}
                                          onClick={this.getFilterSubmited}

                                      >
                                          Search
                                      </button>


                                  </CardBody>

                              </GridItem>
                          </GridContainer>

                      </Paper>
                      <br/>
                      <Paper className={classes.root}>
                          <GridContainer>

                              <GridItem xs={12} sm={12} md={12}>

                                  <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                      <h5 className={classes.cardTitleWhite}>All Inbox</h5>

                                  </CardHeader>
                                  <br/>
                                  {this.renderTab(classes.Tab)}

                                  <div className={classes.tableWrapper}>
                                      <Table className={classes.table} aria-labelledby="tableTitle">
                                          <TableContentHead
                                              sty
                                              numSelected={selected.length}
                                              order={order}
                                              orderBy={orderBy}
                                              onSelectAllClick={this.handleSelectAllClick}
                                              onRequestSort={this.handleRequestSort}
                                              rowCount={data.length}
                                          />
                                          <Dialog
                                              fullWidth="true"
                                              maxWidth="xl"
                                              fullScreen={true}
                                              open={this.state.inboxModal}>
                                              <DialogContent>

                                                  {this.renderInboxCase()}
                                              </DialogContent>
                                          </Dialog>
                                          <Dialog
                                              fullWidth="true"
                                              maxWidth="xl"
                                              fullScreen={true}
                                              open={this.state.inboxModalSubmitted}>
                                              <DialogContent>

                                                  {this.renderInboxCaseSubmitted()}
                                              </DialogContent>
                                          </Dialog>
                                          <Dialog
                                              fullWidth="true"
                                              maxWidth="sm"
                                              className={classes.modal}
                                              classes={{paper: classes.dialogPaper}}
                                              open={this.state.loading}>
                                              <DialogContent className={classes.dialogPaper}>

                                                  <center>
                                                      <img src={loader} alt=""/>
                                                  </center>
                                              </DialogContent>
                                          </Dialog>
                                          <TableBody>
                                              {stableSort(data, getSorting(order, orderBy))
                                                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                  .map(n => {
                                                      const isSelected = this.isSelected(n.id);
                                                      return (
                                                          <TableRow
                                                              hover
                                                                style={{cursor: "pointer"}}
                                                              /!* onClick={event => this.handleClick(event, n.id)}*!/
                                                              role="checkbox"
                                                              aria-checked={isSelected}
                                                              tabIndex={-1}
                                                              key={n.id}
                                                              onClick={() => this.viewCaseSubmitted(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.solId, n.freeFlag1, n.urgency,n.sentByUsername)}
                                                              selected={isSelected}
                                                          >

                                                              {/!*<TableCell className={classes.tableCells} align="left">*!/}

                                                              {/!*</TableCell>*!/}
                                                              <TableCell className={classes.tableCell} align="left">
                                                                  {n.id}
                                                              </TableCell>
                                                              <TableCell className={classes.tableCell} align="left">
                                                                  {n.caseId}
                                                              </TableCell>
                                                              <TableCell className={classes.tableCell} align="left">
                                                                  {n.customer_name}
                                                              </TableCell>

                                                              <TableCell className={classes.tableCell}
                                                                         align="left">{n.cb_number}</TableCell>
                                                              <TableCell className={classes.tableCell}
                                                                         align="left">{this.returnAccountNumber(n.branchingAccountNumber,n.account_number)}</TableCell>
                                                              <TableCell className={classes.tableCell}
                                                                         align="left">{n.category}</TableCell>
                                                               <TableCell className={classes.tableCell}
                                                                       align="left">{n.subCategory}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.priorityDesc}</TableCell>

                                                              <TableCell className={classes.tableCell}
                                                                         align="left">{n.delInitTime}</TableCell>
                                                              <TableCell className={classes.tableCell}
                                                                         align="left">{n.currentUserName}</TableCell>
                                                              {this.branchName(<TableCell className={classes.tableCell}
                                                                                          align="left">{n.branch_id}</TableCell>)}
                                                              <TableCell className={classes.tableCell}
                                                                         align="left">{n.delStatus}</TableCell>
                                                              <TableCell className={classes.tableCell} align="left">

                                                                  {/!* <ThemeProvider theme={theme}>
                                                                  <Button
                                                                      onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber)}
                                                                      variant="contained" color="secondary"
                                                                      className={classes.margin}>
                                                                      View
                                                                  </Button>
                                                              </ThemeProvider>*!/}
                                                              </TableCell>


                                                          </TableRow>
                                                      );
                                                  })}
                                              {emptyRows > 0 && (
                                                  <TableRow style={{height: 49 * emptyRows}}>
                                                      <TableCell colSpan={6}/>
                                                  </TableRow>
                                              )}
                                          </TableBody>
                                      </Table>
                                  </div>

                                  <TablePagination
                                      rowsPerPageOptions={[5, 10, 15, 20, 25]}
                                      component="div"
                                      count={data.length}
                                      rowsPerPage={rowsPerPage}
                                      page={page}
                                      backIconButtonProps={{
                                          'aria-label': 'Previous Page',
                                      }}
                                      nextIconButtonProps={{
                                          'aria-label': 'Next Page',
                                      }}
                                      onChangePage={this.handleChangePage}
                                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                  />

                              </GridItem>
                          </GridContainer>
                      </Paper>
                  </section>
              );
          } */
        else if (this.state.activeItem === "Group Inbox") {
            return (
                <section>


                    <Paper className={classes.root}>

                        <GridContainer>

                            <GridItem xs={12} sm={12} md={12}>

                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h5 className={classes.cardTitleWhite}>Search</h5>


                                </CardHeader>

                                <CardBody>
                                    <Grid container spacing={1}>
                                        <ThemeProvider theme={theme}>

                                            {this.renderFilterForm()}

                                        </ThemeProvider>


                                    </Grid>
                                    <br/>


                                    <button
                                        className="btn btn-outline-primary"
                                        style={{
                                            verticalAlign: 'middle',
                                        }}
                                        onClick={this.getFilterSubmited}

                                    >
                                        Search
                                    </button>


                                </CardBody>

                            </GridItem>
                        </GridContainer>

                    </Paper>
                    <br/>
                    <Paper className={classes.root}>
                        <GridContainer>

                            <GridItem xs={12} sm={12} md={12}>

                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h5 className={classes.cardTitleWhite}>All Inbox</h5>

                                </CardHeader>
                                <br/>
                                {this.renderTab(classes.Tab)}
                                <div style={{display: "flex", justifyContent: "right"}}>
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            marginTop: "20px",
                                            marginBottom: "10px",
                                            marginRight: "19px",
                                        }}
                                        onClick={this.functionForLock}
                                    >
                                        Lock
                                    </button>
                                </div>
                                <div className={classes.tableWrapper}>
                                    <Table className={classes.table} aria-labelledby="tableTitle">
                                        <TableContentHead
                                            sty
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={this.handleSelectAllClick}
                                            onRequestSort={this.handleRequestSort}
                                            rowCount={data.length}
                                            getTab={this.state.activeItem}
                                            checkbox={true}
                                        />
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="xl"
                                            fullScreen={true}
                                            open={this.state.inboxModal}>
                                            <DialogContent>

                                                {this.renderInboxCase()}
                                            </DialogContent>
                                        </Dialog>

                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.loading}>
                                            <DialogContent className={classes.dialogPaper}>

                                                <center>
                                                    <img src={loader} alt=""/>
                                                </center>
                                            </DialogContent>
                                        </Dialog>
                                        <TableBody>
                                            {stableSort(data, getSorting(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map(n => {
                                                    const isSelected = this.isSelected(n.appUid);
                                                    return (
                                                        <TableRow
                                                            hover
                                                            style={{cursor: "pointer"}}
                                                            role="checkbox"
                                                            aria-checked={isSelected}
                                                            tabIndex={-1}
                                                            key={n.appUid}
                                                            selected={isSelected}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    onClick={event => this.handleClick(event, n.appUid)}
                                                                    checked={isSelected}
                                                                />
                                                            </TableCell>
                                                            {/*<TableCell className={classes.tableCells} align="left">*/}

                                                            {/*</TableCell>*/}
                                                            <TableCell
                                                                onClick={() => this.viewCaseLockView(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.recpmtid, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell} align="left">
                                                                {n.id}
                                                            </TableCell>
                                                            <TableCell
                                                                onClick={() => this.viewCaseLockView(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.recpmtid, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell} align="left">
                                                                {n.caseId}
                                                            </TableCell>
                                                            <TableCell
                                                                onClick={() => this.viewCaseLockView(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.recpmtid, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell} align="left">
                                                                {n.customer_name}
                                                            </TableCell>

                                                            <TableCell
                                                                onClick={() => this.viewCaseLockView(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.recpmtid, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell}
                                                                align="left">{n.cb_number}</TableCell>
                                                            <TableCell
                                                                onClick={() => this.viewCaseLockView(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.recpmtid, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell}
                                                                align="left">{n.account_number}</TableCell>
                                                            <TableCell
                                                                onClick={() => this.viewCaseLockView(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.recpmtid, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell}
                                                                align="left">{n.businessSegment}</TableCell>
                                                            <TableCell
                                                                onClick={() => this.viewCaseLockView(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.recpmtid, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell}
                                                                align="left">{this.returnCategory(n.branchingCategory, n.freeFlag5, n.category)}</TableCell>
                                                            <TableCell
                                                                onClick={() => this.viewCaseLockView(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.recpmtid, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell}
                                                                align="left">{this.returnSubCategory(n.branchingSubCategory, n.freeFlag6, n.subCategory)}</TableCell>
                                                            <TableCell
                                                                onClick={() => this.viewCaseLockView(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.recpmtid, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell}
                                                                align="left">{n.priorityDesc}</TableCell>

                                                            <TableCell
                                                                onClick={() => this.viewCaseLockView(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.recpmtid, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell}
                                                                align="left">{n.delInitTime}</TableCell>
                                                            <TableCell
                                                                onClick={() => this.viewCaseLockView(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.recpmtid, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell}
                                                                align="left">{n.currentUserName}</TableCell>
                                                            {this.branchName(<TableCell
                                                                onClick={() => this.viewCaseLockView(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.recpmtid, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell}
                                                                align="left">{n.branch_id}</TableCell>)}
                                                            <TableCell
                                                                onClick={() => this.viewCaseLockView(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.recpmtid, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell}
                                                                align="left">{n.delStatus}</TableCell>
                                                            <TableCell
                                                                onClick={() => this.viewCaseLockView(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.recpmtid, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell}
                                                                align="left">{n.returnBackTo}</TableCell>
                                                            <TableCell
                                                                onClick={() => this.viewCaseLockView(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.recpmtid, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell} align="left">

                                                                {/* <ThemeProvider theme={theme}>
                                                                <Button
                                                                    onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber)}
                                                                    variant="contained" color="secondary"
                                                                    className={classes.margin}>
                                                                    View
                                                                </Button>
                                                            </ThemeProvider>*/}
                                                            </TableCell>


                                                        </TableRow>
                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{height: 49 * emptyRows}}>
                                                    <TableCell colSpan={6}/>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>

                                <TablePagination
                                    rowsPerPageOptions={[100]}
                                    component="div"
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />

                            </GridItem>
                        </GridContainer>
                    </Paper>
                </section>
            );
        } else if (this.state.activeItem === "RECTIFIED REQUEST") {
            return (
                <section>


                    <Paper className={classes.root}>

                        <GridContainer>

                            <GridItem xs={12} sm={12} md={12}>

                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h5 className={classes.cardTitleWhite}>Search</h5>


                                </CardHeader>

                                <CardBody>
                                    <Grid container spacing={1}>
                                        <ThemeProvider theme={theme}>

                                            {this.renderFilterForm()}

                                        </ThemeProvider>


                                    </Grid>
                                    <br/>


                                    <button
                                        className="btn btn-outline-primary"
                                        style={{
                                            verticalAlign: 'middle',
                                        }}
                                        onClick={this.getFilterSubmited}

                                    >
                                        Search
                                    </button>


                                </CardBody>

                            </GridItem>
                        </GridContainer>

                    </Paper>
                    <br/>
                    <Paper className={classes.root}>
                        <GridContainer>

                            <GridItem xs={12} sm={12} md={12}>

                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h5 className={classes.cardTitleWhite}>All Inbox</h5>

                                </CardHeader>
                                <br/>
                                {this.renderTab(classes.Tab)}

                                <div className={classes.tableWrapper}>
                                    <Table className={classes.table} aria-labelledby="tableTitle">
                                        <TableContentHead
                                            sty
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={this.handleSelectAllClick}
                                            onRequestSort={this.handleRequestSort}
                                            rowCount={data.length}
                                            getTab={this.state.activeItem}
                                        />
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="xl"
                                            fullScreen={true}
                                            open={this.state.inboxModal}>
                                            <DialogContent>

                                                {this.renderInboxCase()}
                                            </DialogContent>
                                        </Dialog>

                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.loading}>
                                            <DialogContent className={classes.dialogPaper}>

                                                <center>
                                                    <img src={loader} alt=""/>
                                                </center>
                                            </DialogContent>
                                        </Dialog>
                                        <TableBody>
                                            {stableSort(data, getSorting(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map(n => {
                                                    const isSelected = this.isSelected(n.id);
                                                    return (
                                                        <TableRow
                                                            hover
                                                            style={{cursor: "pointer"}}
                                                            /* onClick={event => this.handleClick(event, n.id)}*/
                                                            role="checkbox"
                                                            aria-checked={isSelected}
                                                            tabIndex={-1}
                                                            key={n.id}

                                                            onClick={() => this.viewCaseLockView(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.recpmtid, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                            selected={isSelected}
                                                        >

                                                            {/*<TableCell className={classes.tableCells} align="left">*/}

                                                            {/*</TableCell>*/}
                                                            <TableCell className={classes.tableCell} align="left">
                                                                {n.id}
                                                            </TableCell>
                                                            <TableCell className={classes.tableCell} align="left">
                                                                {n.caseId}
                                                            </TableCell>
                                                            <TableCell className={classes.tableCell} align="left">
                                                                {n.customer_name}
                                                            </TableCell>

                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.cb_number}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.account_number}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.businessSegment}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{this.returnCategory(n.branchingCategory, n.freeFlag5, n.category)}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{this.returnSubCategory(n.branchingSubCategory, n.freeFlag6, n.subCategory)}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.priorityDesc}</TableCell>

                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.delInitTime}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.currentUserName}</TableCell>
                                                            {this.branchName(<TableCell className={classes.tableCell}
                                                                                        align="left">{n.branch_id}</TableCell>)}
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.delStatus}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.returnBackTo}</TableCell>

                                                            <TableCell className={classes.tableCell} align="left">

                                                                {/* <ThemeProvider theme={theme}>
                                                                <Button
                                                                    onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber)}
                                                                    variant="contained" color="secondary"
                                                                    className={classes.margin}>
                                                                    View
                                                                </Button>
                                                            </ThemeProvider>*/}
                                                            </TableCell>


                                                        </TableRow>
                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{height: 49 * emptyRows}}>
                                                    <TableCell colSpan={6}/>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>

                                <TablePagination
                                    rowsPerPageOptions={[100]}
                                    component="div"
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />

                            </GridItem>
                        </GridContainer>
                    </Paper>
                </section>
            );
        } else if (this.state.activeItem === "Return") {
            return (
                <section>


                    <Paper className={classes.root}>

                        <GridContainer>

                            <GridItem xs={12} sm={12} md={12}>

                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h5 className={classes.cardTitleWhite}>Search</h5>


                                </CardHeader>

                                <CardBody>
                                    <Grid container spacing={1}>
                                        <ThemeProvider theme={theme}>

                                            {this.renderFilterForm()}

                                        </ThemeProvider>


                                    </Grid>
                                    <br/>


                                    <button
                                        className="btn btn-outline-primary"
                                        style={{
                                            verticalAlign: 'middle',
                                        }}
                                        onClick={this.getFilterSubmited}

                                    >
                                        Search
                                    </button>


                                </CardBody>

                            </GridItem>
                        </GridContainer>

                    </Paper>
                    <br/>

                    <Paper className={classes.root}>
                        <GridContainer>

                            <GridItem xs={12} sm={12} md={12}>

                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h5 className={classes.cardTitleWhite}>All Inbox</h5>

                                </CardHeader>
                                <br/>
                                {this.renderTab(classes.Tab)}

                                <div className={classes.tableWrapper}>
                                    <Table className={classes.table} aria-labelledby="tableTitle">
                                        <TableContentHead
                                            sty
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={this.handleSelectAllClick}
                                            onRequestSort={this.handleRequestSort}
                                            rowCount={data.length}
                                            getTab={this.state.activeItem}
                                        />
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="xl"
                                            fullScreen={true}
                                            open={this.state.inboxModal}>
                                            <DialogContent>

                                                {this.renderInboxCase()}
                                            </DialogContent>
                                        </Dialog>

                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.loading}>
                                            <DialogContent className={classes.dialogPaper}>

                                                <center>
                                                    <img src={loader} alt=""/>
                                                </center>
                                            </DialogContent>
                                        </Dialog>

                                        <TableBody>
                                            {stableSort(data, getSorting(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map(n => {
                                                    const isSelected = this.isSelected(n.id);
                                                    return (
                                                        <TableRow
                                                            hover
                                                            style={{cursor: "pointer"}}
                                                            /* onClick={event => this.handleClick(event, n.id)}*/
                                                            role="checkbox"
                                                            aria-checked={isSelected}
                                                            tabIndex={-1}
                                                            key={n.id}
                                                            onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.recpmtid, n.urgency, n.sentByUsername, n.cb_number, n.account_number, n.customer_name)}
                                                            selected={isSelected}
                                                        >
                                                            {/*  <TableCell className={classes.tableCells} align="left">

                                                            </TableCell>*/}
                                                            <TableCell className={classes.tableCell} align="left">
                                                                {n.id}
                                                            </TableCell>
                                                            <TableCell className={classes.tableCell} align="left">
                                                                {n.caseId}
                                                            </TableCell>

                                                            <TableCell className={classes.tableCell} align="left">
                                                                {n.customer_name}
                                                            </TableCell>

                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.cb_number}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.account_number}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.businessSegment}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{this.returnCategory(n.branchingCategory, n.freeFlag5, n.category)}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{this.returnSubCategory(n.branchingSubCategory, n.freeFlag6, n.subCategory)}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.priorityDesc}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.delInitTime}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.currentUserName}</TableCell>
                                                            {this.branchName(<TableCell className={classes.tableCell}
                                                                                        align="left">{n.branch_id}</TableCell>)}

                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.delStatus}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.sentByUsername}</TableCell>

                                                            <TableCell className={classes.tableCell} align="left">

                                                                {/* <ThemeProvider theme={theme}>
                                                                <Button
                                                                    onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber)}
                                                                    variant="contained" color="secondary"
                                                                    className={classes.margin}>
                                                                    View
                                                                </Button>
                                                            </ThemeProvider>*/}
                                                            </TableCell>


                                                        </TableRow>
                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{height: 49 * emptyRows}}>
                                                    <TableCell colSpan={6}/>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>

                                <TablePagination
                                    rowsPerPageOptions={[100]}
                                    component="div"
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />

                            </GridItem>
                        </GridContainer>
                    </Paper>
                </section>
            );
        } else if (this.state.activeItem === "Inbox" /* && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf("INTERNET_BANKING") !== -1*/) {
            return (
                <section>


                    <Paper className={classes.root}>

                        <GridContainer>

                            <GridItem xs={12} sm={12} md={12}>

                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h5 className={classes.cardTitleWhite}>Search</h5>


                                </CardHeader>

                                <CardBody>
                                    <Grid container spacing={1}>
                                        <ThemeProvider theme={theme}>

                                            {this.renderFilterForm()}

                                        </ThemeProvider>


                                    </Grid>
                                    <br/>


                                    <button
                                        className="btn btn-outline-primary"
                                        style={{
                                            verticalAlign: 'middle',
                                        }}
                                        onClick={this.getFilterSubmited}

                                    >
                                        Search
                                    </button>


                                </CardBody>

                            </GridItem>
                        </GridContainer>

                    </Paper>
                    <br/>

                    <Paper className={classes.root}>
                        <GridContainer>

                            <GridItem xs={12} sm={12} md={12}>

                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h5 className={classes.cardTitleWhite}>All Inbox</h5>

                                </CardHeader>
                                <br/>
                                {this.renderTab(classes.Tab)}

                                <div className={classes.tableWrapper}>
                                    <Table className={classes.table} aria-labelledby="tableTitle">
                                        <TableContentHead
                                            sty
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={this.handleSelectAllClick}
                                            onRequestSort={this.handleRequestSort}
                                            rowCount={data.length}
                                            getTab={this.state.activeItem}
                                        />
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="xl"
                                            fullScreen={true}
                                            open={this.state.inboxModal}>
                                            <DialogContent>

                                                {this.renderInboxCase()}
                                            </DialogContent>
                                        </Dialog>

                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.loading}>
                                            <DialogContent className={classes.dialogPaper}>

                                                <center>
                                                    <img src={loader} alt=""/>
                                                </center>
                                            </DialogContent>
                                        </Dialog>

                                        <TableBody>
                                            {stableSort(data, getSorting(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map(n => {
                                                    const isSelected = this.isSelected(n.id);
                                                    return (
                                                        <TableRow
                                                            hover
                                                            style={{cursor: "pointer"}}
                                                            /* onClick={event => this.handleClick(event, n.id)}*/
                                                            role="checkbox"
                                                            aria-checked={isSelected}
                                                            tabIndex={-1}
                                                            key={n.id}
                                                            //onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1,n.freeFlag2,n.freeFlag3,n.freeFlag4, n.urgency, "",n.cb_number,n.account_number,n.customer_name)}
                                                            selected={isSelected}
                                                        >
                                                            {/*  <TableCell className={classes.tableCells} align="left">

                                                            </TableCell>*/}
                                                            <TableCell
                                                                onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell} align="left">
                                                                {n.id}
                                                            </TableCell>
                                                            <TableCell
                                                                onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell} align="left">
                                                                {n.caseId}
                                                            </TableCell>

                                                            <TableCell
                                                                onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell} align="left">
                                                                {n.customer_name}
                                                            </TableCell>

                                                            <TableCell
                                                                onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell}
                                                                align="left">{n.cb_number}</TableCell>
                                                            <TableCell
                                                                onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell}
                                                                align="left">{n.account_number}</TableCell>
                                                            <TableCell
                                                                onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell}
                                                                align="left">{n.businessSegment}</TableCell>
                                                            <TableCell
                                                                onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell}
                                                                align="left">{this.returnCategory(n.branchingCategory, n.freeFlag5, n.category)}</TableCell>
                                                            <TableCell
                                                                onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell}
                                                                align="left">{this.returnSubCategory(n.branchingSubCategory, n.freeFlag6, n.subCategory)}</TableCell>
                                                            <TableCell
                                                                onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell}
                                                                align="left">{n.priorityDesc}</TableCell>
                                                            <TableCell
                                                                onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell}
                                                                align="left">{n.delInitTime}</TableCell>
                                                            <TableCell
                                                                onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell}
                                                                align="left">{n.currentUserName}</TableCell>
                                                            {this.branchName(<TableCell
                                                                onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell}
                                                                align="left">{n.branch_id}</TableCell>)}

                                                            <TableCell
                                                                onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                className={classes.tableCell}
                                                                align="left">{n.delStatus}</TableCell>

                                                            <Button
                                                                onClick={() => this.viewCaseUnlock(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                                variant="contained" color="secondary"
                                                                className={classes.margin}>
                                                                UnLock
                                                            </Button>

                                                        </TableRow>

                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{height: 49 * emptyRows}}>
                                                    <TableCell colSpan={6}/>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>

                                <TablePagination
                                    rowsPerPageOptions={[100]}
                                    component="div"
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />

                            </GridItem>
                        </GridContainer>
                    </Paper>
                </section>
            );
        } else {
            return (
                <section>


                    <Paper className={classes.root}>

                        <GridContainer>

                            <GridItem xs={12} sm={12} md={12}>

                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h5 className={classes.cardTitleWhite}>Search</h5>


                                </CardHeader>

                                <CardBody>
                                    <Grid container spacing={1}>
                                        <ThemeProvider theme={theme}>

                                            {this.renderFilterForm()}

                                        </ThemeProvider>


                                    </Grid>
                                    <br/>


                                    <button
                                        className="btn btn-outline-primary"
                                        style={{
                                            verticalAlign: 'middle',
                                        }}
                                        onClick={this.getFilterSubmited}

                                    >
                                        Search
                                    </button>


                                </CardBody>

                            </GridItem>
                        </GridContainer>

                    </Paper>
                    <br/>

                    <Paper className={classes.root}>
                        <GridContainer>

                            <GridItem xs={12} sm={12} md={12}>

                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h5 className={classes.cardTitleWhite}>All Inbox</h5>

                                </CardHeader>
                                <br/>
                                {this.renderTab(classes.Tab)}

                                <div className={classes.tableWrapper}>
                                    <Table className={classes.table} aria-labelledby="tableTitle">
                                        <TableContentHead
                                            sty
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={this.handleSelectAllClick}
                                            onRequestSort={this.handleRequestSort}
                                            rowCount={data.length}
                                            getTab={this.state.activeItem}
                                        />
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="xl"
                                            fullScreen={true}
                                            open={this.state.inboxModal}>
                                            <DialogContent>

                                                {this.renderInboxCase()}
                                            </DialogContent>
                                        </Dialog>

                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.loading}>
                                            <DialogContent className={classes.dialogPaper}>

                                                <center>
                                                    <img src={loader} alt=""/>
                                                </center>
                                            </DialogContent>
                                        </Dialog>

                                        <TableBody>
                                            {stableSort(data, getSorting(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map(n => {
                                                    const isSelected = this.isSelected(n.id);
                                                    return (
                                                        <TableRow
                                                            hover
                                                            style={{cursor: "pointer"}}
                                                            /* onClick={event => this.handleClick(event, n.id)}*/
                                                            role="checkbox"
                                                            aria-checked={isSelected}
                                                            tabIndex={-1}
                                                            key={n.id}
                                                            onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber, n.category, n.subCategory, n.delStatus, n.status, n.solId, n.freeFlag1, n.freeFlag2, n.freeFlag3, n.freeFlag4, n.urgency, "", n.cb_number, n.account_number, n.customer_name)}
                                                            selected={isSelected}
                                                        >
                                                            {/*  <TableCell className={classes.tableCells} align="left">

                                                            </TableCell>*/}
                                                            <TableCell className={classes.tableCell} align="left">
                                                                {n.id}
                                                            </TableCell>
                                                            <TableCell className={classes.tableCell} align="left">
                                                                {n.caseId}
                                                            </TableCell>

                                                            <TableCell className={classes.tableCell} align="left">
                                                                {n.customer_name}
                                                            </TableCell>

                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.cb_number}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.account_number}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.businessSegment}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{this.returnCategory(n.branchingCategory, n.freeFlag5, n.category)}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{this.returnSubCategory(n.branchingSubCategory, n.freeFlag6, n.subCategory)}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.priorityDesc}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.delInitTime}</TableCell>
                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.currentUserName}</TableCell>
                                                            {this.branchName(<TableCell className={classes.tableCell}
                                                                                        align="left">{n.branch_id}</TableCell>)}

                                                            <TableCell className={classes.tableCell}
                                                                       align="left">{n.delStatus}</TableCell>
                                                            <TableCell className={classes.tableCell} align="left">

                                                                {/* <ThemeProvider theme={theme}>
                                                                <Button
                                                                    onClick={() => this.viewCase(n.appUid, n.service_type, n.subservice_type, n.taskTitle, n.jointAccountCustomerNumber)}
                                                                    variant="contained" color="secondary"
                                                                    className={classes.margin}>
                                                                    View
                                                                </Button>
                                                            </ThemeProvider>*/}
                                                            </TableCell>


                                                        </TableRow>
                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{height: 49 * emptyRows}}>
                                                    <TableCell colSpan={6}/>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>

                                <TablePagination
                                    rowsPerPageOptions={[100]}
                                    component="div"
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />

                            </GridItem>
                        </GridContainer>
                    </Paper>
                </section>
            );
        }

    }
}

Inbox.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Inbox);
