import React, {Component} from "react";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios/index";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import Grid from "@material-ui/core/Grid/index";
import withStyles from "@material-ui/core/styles/withStyles";
import CardHeader from "../../Card/CardHeader";
import CloseIcon from '@material-ui/icons/Close';

import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../JsonForm/CustomeTheme";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {Dialog} from "@material-ui/core/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import AccountNoGenerate from "./AccountNoGenerate";
import GridList from "@material-ui/core/GridList";
import VerifyMakerPhoto from "../VerifyMakerPhoto";
import MyValidation from "../../JsonForm/MyValidation";
import loader from "../../../Static/loader.gif";
import Functions from "../../../Common/Functions";
import ErrorModal from "../CommonComponent/ErrorModal";
import {
    CBDataJsonForm,
    CBDataJsonFormNew,
} from "../WorkflowJsonForm4";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";


const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

    button: {
        margin: theme.spacing(1),
    }
});

let jsonForm = [
    {
        "varName": "customerId",
        "type": "text",
        "label": "Customer Id",
        "grid": 6,
        "length": 9,


    },

    {
        "varName": "title",
        "type": "text",
        "label": "Title",
        "grid": 6,


        required: true,
    },

    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name",
        "grid": 6,
        "length": 80,


    },

    {
        "varName": "shortName",
        "type": "text",
        "label": "Short Name",
        "grid": 6,
        "length": 10,


    },

    {
        "varName": "status",
        "type": "text",
        "label": "Status",
        "grid": 6,


        required: true,
    },

    {
        "varName": "statusAsOnDate",
        "type": "date",
        "label": "Status as on Date",
        "grid": 6
    },

    {
        "varName": "acManager",
        "type": "text",
        "label": "AC Manager",
        "grid": 6,


    },

    {
        "varName": "occupationCode",
        "type": "text",
        "label": "Occuoation Code",
        "grid": 6,


        required: true,
    },

    {
        "varName": "constitution",
        "type": "text",
        "label": "Constitution",
        "grid": 6,


        required: true,
    },

    {
        "varName": "gender",
        "type": "text",
        "label": "Gender",
        "grid": 6,


        required: true,
    },

    {
        "varName": "staffFlag",
        "type": "text",
        "label": "Staff Flag",
        "grid": 6,


        required: true,
    },

    {
        "varName": "staffNumber",
        "type": "text",
        "label": "Staff Number",
        "grid": 6,


        required: true,
    },

    {
        "varName": "minor",
        "type": "text",
        "label": "Minor",
        "grid": 6,


    },

    {
        "varName": "nonResident",
        "type": "text",
        "label": "Non Resident",
        "grid": 6,


        required: true,
    },

    {
        "varName": "trade",
        "type": "text",
        "label": "Trade",
        "grid": 6,


        required: true,
    },

    {
        "varName": "nationalIdCard",
        "type": "text",
        "label": "National ID Card",
        "grid": 6,


        required: true,
    },

    {
        "varName": "dateOfBirth",
        "type": "date",
        "label": "Date of Birth",
        "grid": 6,


    },

    {
        "varName": "introducerCustomerId",
        "type": "text",
        "label": "Introducer Customer Id",
        "grid": 6,
        "length": 9,

        required: true,
    },

    {
        "varName": "introducerName",
        "type": "text",
        "label": "Introducer Name",
        "grid": 6,


    },

    {
        "varName": "introducerStaff",
        "type": "text",
        "label": "Introducer Staff",
        "grid": 6,


    },

    {
        "varName": "maritialStatus",
        "type": "text",
        "label": "Maritial Status",
        "grid": 6,


        required: true,
    },

    {
        "varName": "father",
        "type": "text",
        "label": "Father",
        "grid": 6,


        required: true,
    },

    {
        "varName": "mother",
        "type": "text",
        "label": "Mother",
        "grid": 6,


        required: true,
    },

    {
        "varName": "spouse",
        "type": "text",
        "label": "Spouse",
        "grid": 6,


        required: true,
    },

    {
        "varName": "communicationAddress1",
        "type": "text",
        "label": "Communication Address1",
        "grid": 6,


    },

    {
        "varName": "communicationAddress2",
        "type": "text",
        "label": "Communication Address2",
        "grid": 6,


    },

    {
        "varName": "city1",
        "type": "text",
        "label": "City",
        "grid": 6,


        required: true,
    },

    {
        "varName": "state1",
        "type": "text",
        "label": "State",
        "grid": 6,


        required: true,
    },

    {
        "varName": "postalCode1",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,


    },

    {
        "varName": "country1",
        "type": "text",
        "label": "Country",
        "grid": 6,


        required: true,
    },

    {
        "varName": "phoneNo11",
        "type": "text",
        "label": "Phone No1",
        "grid": 6,
        "length": 11,

        required: true,
    },

    {
        "varName": "phoneNo21",
        "type": "text",
        "label": "Phone No2",
        "grid": 6,


    },

    {
        "varName": "telexNo1",
        "type": "text",
        "label": "Telex No",
        "grid": 6,


    },

    {
        "varName": "email1",
        "type": "text",
        "label": "Email",
        "grid": 6,

        email: true,

    },

    {
        "varName": "permanentAddress1",
        "type": "text",
        "label": "Permanent Address1",
        "grid": 6,


    },

    {
        "varName": "permanentAddress2",
        "type": "text",
        "label": "Permanent Address2",
        "grid": 6,


    },

    {
        "varName": "city2",
        "type": "text",
        "label": "City",
        "grid": 6,


        required: true,
    },

    {
        "varName": "state2",
        "type": "text",
        "label": "State",
        "grid": 6,


        required: true,
    },

    {
        "varName": "postalCode2",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,


    },

    {
        "varName": "country2",
        "type": "text",
        "label": "Country",
        "grid": 6,


        required: true,
    },

    {
        "varName": "phoneNo12",
        "type": "text",
        "label": "Phone No1",
        "grid": 6,


    },

    {
        "varName": "phoneNo222",
        "type": "text",
        "label": "Phone No2",
        "grid": 6,


    },

    {
        "varName": "telexNo2",
        "type": "text",
        "label": "Telex No",
        "grid": 6,


    },

    {
        "varName": "email2",
        "type": "text",
        "label": "Email",
        "grid": 6,

        email: true,

    },

    {
        "varName": "employerAddress1",
        "type": "text",
        "label": "Employer Address1",
        "grid": 6,


    },

    {
        "varName": "employerAddress2",
        "type": "text",
        "label": "Employer Address2",
        "grid": 6,


    },

    {
        "varName": "city3",
        "type": "text",
        "label": "City",
        "grid": 6,


        required: true,
    },

    {
        "varName": "state3",
        "type": "text",
        "label": "State",
        "grid": 6,


        required: true,
    },

    {
        "varName": "postalCode3",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,


    },

    {
        "varName": "country",
        "type": "text",
        "label": "Country",
        "grid": 6,


        required: true,
    },

    {
        "varName": "phoneNo13",
        "type": "text",
        "label": "Phone No1",
        "grid": 6,


    },

    {
        "varName": "phoneNo23",
        "type": "text",
        "label": "Phone No2",
        "grid": 6,


    },

    {
        "varName": "telexNo",
        "type": "text",
        "label": "Telex No",
        "grid": 6,


    },

    {
        "varName": "email3",
        "type": "text",
        "label": "Email",
        "grid": 6,

        email: true,

    },

    {
        "varName": "faxNo",
        "type": "text",
        "label": "Fax No",
        "grid": 6,


    },

    {
        "varName": "combineStatement",
        "type": "text",
        "label": "Combine Statement",
        "grid": 6,


    },

    {
        "varName": "tds",
        "type": "text",
        "label": "TDS",
        "grid": 6,


    },

    {
        "varName": "pangirNo",
        "type": "text",
        "label": "PANGIR No",
        "grid": 6,


    },

    {
        "varName": "passportNo",
        "type": "text",
        "label": "Passport No",
        "grid": 6,


    },

    {
        "varName": "issueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,


    },

    {
        "varName": "passportDetails",
        "type": "text",
        "label": "Passport Details",
        "grid": 6,


        required: true,
    },

    {
        "varName": "expiryDate",
        "type": "date",
        "label": "Expiry Date",
        "grid": 6,


    },

    {
        "varName": "purgedAllowed",
        "type": "text",
        "label": "Purged Allowed",
        "grid": 6,


    },

    {
        "varName": "freeText2",
        "type": "text",
        "label": "Free Text2",
        "grid": 6,


    },

    {
        "varName": "freeText5",
        "type": "text",
        "label": "Free Text 5",
        "grid": 6,
        "length": 10,


    },

    {
        "varName": "freeText8",
        "type": "text",
        "label": "Free Text 8",
        "grid": 6,


    },

    {
        "varName": "freeText9",
        "type": "text",
        "label": "Free Text 9",
        "grid": 6,


    },

    {
        "varName": "freeText13",
        "type": "text",
        "label": "Free Text13",
        "grid": 6,


    },

    {
        "varName": "freeText14",
        "type": "text",
        "label": "Free Text14",
        "grid": 6,


    },

    {
        "varName": "freeText15",
        "type": "text",
        "label": "Free Text15",
        "grid": 6,


    },

    {
        "varName": "freeCode1",
        "type": "text",
        "label": "Free Code1",
        "grid": 6,


    },

    {
        "varName": "freeCode3",
        "type": "text",
        "label": "Free Code3",
        "grid": 6,


    },

    {
        "varName": "freeCode7",
        "type": "text",
        "label": "Free Code 7",
        "grid": 6,


    },
];
let selectFileName = {
    "varName": "fileName",
    "type": "dropdown",
    "required": true,
    "label": "This photo name",

    "grid": 6
};

class MakerCumInput extends Component {
    state = {
        message: "",
        appData: {},
        getData: false,
        varValue: [],
        showValue: false,
        redirectLogin: false,
        title: "",
        notificationMessage: "",
        alert: false,
        inputData: {tagList: {}},
        selectedDate: {},
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        values: [],
        customerName: [],
        deferalType: [],
        expireDate: [],
        other: [],
        getCheckerList: [],
        getAllDefferal: [],
        getDeferalList: [],
        occupationCodeList: [],
        loading: true,
        jointAccountCustomerNumber: 0,
        objectForNominee: [],
        getgenerateForm: false,
        renderCumModalopen: false,
        generateAccountNo: '',
        getDedupData: {},
        jointDedupData: {},
        jointSearchTableData: [],
        propritorshipData: [],
        dedupData: [],
        tagClick: false,
        getTaggingList: {},
        getTaggingCustomerList: {},
        taggingData: [],
        relatedData: {},
        searchTableData: false,
        searchTableRelatedData: false,
        imageCropModal: false,
        errorModalBoolean: false,
        cbGenerateModal: false,
        getRemarks: [], err: false,
        errorArray: {},
        errorMessages: {},
        getMappingCropImage: false,
        nomineeNumber: 0,
        getNominee: false,
        errorMessage: "Invalid Code",
        type: "successs",
        city: [],
        dispatchMode: [],
        getCity: false,
        stateCode: [],
        getGuardianCode: [],
        savingsSchemeCodeList: [],
        currentSchemeCodeList: [],
        getState: false,
        country: [],
        titleList: [],
        chequebookWaiverType: [],
        rmCodeList: [],
        phoneEmailType: [],
        bankRelationTypeList: [],
        phoneOrEmail: [],
        getCountry: false,
        currency: [],
        relationshipList: [],
        constitutionList: [],
        customerStatusList: [],
        waiverFieldList: [],
        ccepCodeList: [],
        introducerStatus: [],
        relationCodeList: [],
        getCurrency: false,
        confirmAlert: false,
        actionType: "",
        despatchmodeList: [],
        sectorCodeList: [],
        subsectorCodeList: [],
        depositCodeList: [],
        tradeServiceList: [],
        agentBankingOutletList: [],
        subSegmentList: [],
        maritalStatusList: [],
        cbDataAfterSet: false,
        accountDataAfterSet: false,
        kycAutopopulateFlag: false,
        TpAutopopulateFlag: false,
        phoneNumCountryCodeList: [],
        documentList: [],
        getDocument: false,
        getnonResident: [],
        getfreecode7: [],
        employmentTypeList: [],
        placeOfIssueList: [],
        tempDataSave: {}

    };

    constructor(props) {
        super(props);


    }


    updateComponent = () => {
        this.forceUpdate();
    };


    /*  close = () => {
          this.props.closeModal();
      }*/
    close = () => {
        this.props.closeIcon();
    };
    closeModal = (account) => {
        this.props.taging(this.props.tagingModalCbnumber);
        this.props.closeModal(account, "");
        this.setState({
            accountNo: false
        })
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        /*  for (let prop in clone)
              if (clone[prop] === '' || clone[prop] === ' '   || clone[prop] === "null"   ||  clone[prop] === "undefined" )
  delete clone[prop];*/
        return clone;
    };
    handleSubmit = (event) => {

        event.preventDefault();
        /* this.state.inputData={};
          this.state.varValue={}*/
        let inputData = this.emptyValueRemove(this.state.inputData);
        let varValue = this.emptyValueRemove(this.state.varValue);
        inputData.employerDataemploymentType = inputData.employerDataprofessionEmploymentType;
        varValue.employerDataemploymentType = varValue.employerDataprofessionEmploymentType;

        let error = MyValidation.defaultValidation(this.emptyValueRemove(CBDataJsonFormNew), this.state);
        this.forceUpdate();
        console.log(this.state.inputData);

        console.log("Not working");

        if (error === true) {
            return 0;

        }
        let index = this.props.tagingModalCbnumber + 1;

        let object = {};

        object.appId = this.props.appId;
        object.person = this.props.tagingModalCbnumber + 1;
        if (this.props.relatedPartyType === "Mandate" || this.props.relatedPartyType === "MANDATE" || this.props.relatedPartyType === "Mandate1" || this.props.relatedPartyType === "MANDATE1") {
            object.relatedPartyType = this.props.relatedPartyType;
            object.person = "MANDATE"
        } else if (this.props.relatedPartyType === "Beneficial" || this.props.relatedPartyType === "BENEFICIAL" || this.props.relatedPartyType === "Beneficial1" || this.props.relatedPartyType === "BENEFICIAL1") {
            object.relatedPartyType = this.props.relatedPartyType;
            object.person = "BENEFICIAL"
        } else {
            object.relatedPartyType = "JOINT";
        }
        inputData.relationCode = this.state.inputData.relatedCbTaggingrelationCode.value;

        /*  if(this.props.generateAccountNo==="NEW"){


          }
          else if(this.props.cbNumber["tagging"+index]!==undefined){
              object.person = this.props.tagingModalCbnumber+1;
          }
          else{
              object.person =this.props.tagingModalCbnumber+1;
          }*/
        inputData.person = object.person;
        inputData.requestType = this.props.requestType;
        object.appData = inputData;
        object.appData.requestType = this.props.requestType;
        console.log(this.state.inputData);
        this.setState({
            loading: true,
        });
        if (this.props.generateAccountNo === "NEW") {
            object.requestType = "New";
            object.appData.requestType = "New";
            let urlsecondaryCB = backEndServerURL + "/secondaryCB";
            axios.post(urlsecondaryCB, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.props.closeModal();
                    this.props.taging(this.props.tagingModalCbnumber, this.props.cbNumber);
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            let urlsecondaryCB = backEndServerURL + "/secondaryCB";
            object.requestType = "Tag";
            object.appData.requestType = "Tag";

            object.cb = this.props.cbNumber;
            object.cbNumber = this.props.cbNumber;
            object.appData.cbNumber = this.props.cbNumber;
            axios.post(urlsecondaryCB, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.props.closeModal();
                    this.props.taging(this.props.tagingModalCbnumber, this.props.cbNumber);
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorMessage: "Finacle System Error!",
                        errorModalBoolean: true
                    })
                })

        }

        /*  if(this.props.generateAccountNo==="1"){
              this.setState({
                  accountNo:true
              })
          }
          else{*/


    };
    handleSave = (event) => {

        event.preventDefault();
        /* this.state.inputData={};
          this.state.varValue={}*/
        let inputData = this.emptyValueRemove(this.state.inputData);
        let varValue = this.emptyValueRemove(this.state.varValue);
        inputData.employerDataemploymentType = inputData.employerDataprofessionEmploymentType;
        varValue.employerDataemploymentType = varValue.employerDataprofessionEmploymentType;

        // let error = MyValidation.defaultValidation(this.emptyValueRemove(CBDataJsonFormNew), this.state)
        // this.forceUpdate();
        console.log(this.state.inputData);

        console.log("Not working");


        let index = this.props.tagingModalCbnumber + 1;

        let object = {};

        object.appId = this.props.appId;
        object.person = this.props.tagingModalCbnumber + 1;
        if (this.props.relatedPartyType === "Mandate" || this.props.relatedPartyType === "MANDATE" || this.props.relatedPartyType === "Mandate1" || this.props.relatedPartyType === "MANDATE1") {
            object.relatedPartyType = this.props.relatedPartyType;
            object.person = "MANDATE"
        } else if (this.props.relatedPartyType === "Beneficial" || this.props.relatedPartyType === "BENEFICIAL" || this.props.relatedPartyType === "Beneficial1" || this.props.relatedPartyType === "BENEFICIAL1") {
            object.relatedPartyType = this.props.relatedPartyType;
            object.person = "BENEFICIAL"
        } else {
            object.relatedPartyType = "JOINT";
        }
        if (this.state.inputData.relatedCbTaggingrelationCode === undefined || this.state.inputData.relatedCbTaggingrelationCode === null) {
            inputData.relatedCbTaggingrelationCode = {
                key: "",
                masterDataName: "",
                value: ""
            };
        }
        if (this.state.inputData.relatedCbTaggingrelationType === undefined || this.state.inputData.relatedCbTaggingrelationType === null) {
            inputData.relatedCbTaggingrelationType = {
                key: "",
                masterDataName: "",
                value: ""
            };
        }
        if (this.state.inputData.relatedCbTaggingrelationCode === undefined || this.state.inputData.relatedCbTaggingrelationCode === null) {
            inputData.relatedCbTaggingrelationCode = {
                key: "",
                masterDataName: "",
                value: ""
            };
        }
        if (this.state.inputData.relatedCbTaggingdesignationCode === undefined || this.state.inputData.relatedCbTaggingdesignationCode === null) {
            inputData.relatedCbTaggingdesignationCode = {
                key: "",
                masterDataName: "",
                value: ""
            };
        }
        if (this.state.inputData.crmMissubSegment === undefined || this.state.inputData.crmMissubSegment === null) {
            inputData.crmMissubSegment = {
                key: "",
                masterDataName: "",
                value: ""
            };
        }


        /*  if(this.props.generateAccountNo==="NEW"){


          }
          else if(this.props.cbNumber["tagging"+index]!==undefined){
              object.person = this.props.tagingModalCbnumber+1;
          }
          else{
              object.person =this.props.tagingModalCbnumber+1;
          }*/
        inputData.person = object.person;
        object.appData = inputData;
        console.log(this.state.inputData);
        this.setState({
            loading: true,
        });
        if (this.props.generateAccountNo === "NEW") {
            object.requestType = "New";
            object.appData.requestType = "New";
            let urlsecondaryCB = backEndServerURL + "/secondaryCB";
            axios.post(urlsecondaryCB, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        loading: false,
                    });
                    console.log(response.data);
                    //this.props.closeModal();
                    this.props.taging(this.props.tagingModalCbnumber, this.props.cbNumber);
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                    })
                })
        } else {
            let urlsecondaryCB = backEndServerURL + "/secondaryCB";
            object.requestType = "Tag";
            object.appData.requestType = "Tag";
            object.cb = this.props.cbNumber;
            object.cbNumber = this.props.cbNumber;
            object.appData.cbNumber = this.props.cbNumber;
            axios.post(urlsecondaryCB, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        loading: false,
                    });
                    //this.props.closeModal()
                    this.props.taging(this.props.tagingModalCbnumber, this.props.cbNumber);
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorMessage: "Finacle System Error!",
                        errorModalBoolean: true
                    })
                })

        }

    };
    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "System Error!"
                }
            } else {
                return "System Error!"
            }
        } else {
            return "System Error!"
        }
    };
    renderImage = () => {
        if (this.state.getDocument) {
            // return (
            //     <SecondaryCB customerNumber={this.props.customerNumber} closeModal={this.props.closeModal}
            //                  documentList={this.state.documentList}
            //     />
            // )
            return (<VerifyMakerPhoto documentList={this.state.documentList}/>)
        }
    };


    /* componentDidMount() {
         // alert(this.props.taging)
         let inputData = {};
         let varValue = {};
         this.setState({
             loading: true
         })


         {
             this.dynamicApiCallDocumentType("documentCodeDocumentType", "documentCodeDocumentType")
         }
         {
             this.dynamicApiCallDocumentType("documentCodeDocumentTypeDesc", "documentCodeDocumentTypeDesc")
         }

         {
             this.dynamicApiCall("city2", "city")
         }
         {
             this.dynamicApiCall("state2", "state",)
         }
         {
             this.dynamicApiCall("currency", "currency")
         }
         {
             this.dynamicApiCall("nationality", "country")
         }
         {
             this.dynamicApiCall("documentsCollectedToEnsureTheSourceOfFund", "sourceOfFund")
         }
         {
             this.dynamicApiCall("customersOccupation", "customerOccupation")
         }
         {
             this.dynamicApiCall("addressVariety", "addressVariety")
         }
         {
             this.dynamicApiCall("whatDoesTheCustomerDoInWhatTypeOfBusineessIsTheCustomerEngaged", "customerBusiness")
         }
         {
             this.dynamicApiCall("", "monthlyIncome")
         }
         {
             this.dynamicApiCall("", "accountOpen")
         }
         {
             this.dynamicApiCall("constitution", "constitution")
         }
         {
             this.dynamicApiCall("waiverField", "waiverField")
         }
         {
             this.dynamicApiCall("bankRelationType", "bankRelationType")
         }
         {
             this.dynamicApiCall("emailType", "emailType")
         }
         {
             this.dynamicApiCall("phoneOrEmailId", "phoneOrEmailId")
         }

         {
             this.dynamicApiCall("ccepCode", "ccepCode")
         }
         {
             this.dynamicApiCall("maritalStatus", "maritalStatus")
         }

         {
             this.dynamicApiCall("bankName", "bankName")
         }
         {
             this.dynamicApiCall("title", "title")
         }
         {
             this.dynamicApiCall("documentCode", "documentCode")
         }
         {
             this.dynamicApiCall("relationship", "relationship")
         }
         {
             this.dynamicApiCall("contactType", "contactType")
         }
         {
             this.dynamicApiCall("employmentType", "employmentType")
         }
         {
             this.dynamicApiCall("agentBankingOutlet", "agentBankingOutlet")
         }
         {
             this.dynamicApiCall("nonResident", "nonResident")
         }
         {
             this.dynamicApiCall("addressFormat", "addressFormat")
         }
         {
             this.dynamicApiCall("branch", "branch")
         }
         {
             this.dynamicApiCall("relationCode", "relationCode")
         }
         {
             this.dynamicApiCall("relationType", "relationType")
         }
         {
             this.dynamicApiCall("dispatchMode", "dispatchMode")
         }
         {
             this.dynamicApiCall("statementFrequency", "statementFrequency")
         }
         {
             this.dynamicApiCall("chequebookWaiverFunctionCode", "chequebookWaiverFunctionCode")
         }
         {
             this.dynamicApiCall("chequebookWaiverType", "chequebookWaiverType")
         }
         /!*  {
               this.dynamicApiCall("rmCode", "rmCode")
           }*!/
         {
             this.dynamicApiCall("subSegment", "subSegment")
         }

         {
             this.dynamicApiCall("designationCode", "designationCode")
         }
         {
             this.dynamicApiCall("sectorCode", "sectorCode")
         }
         {
             this.dynamicApiCall("subSectorCode", "subSectorCode")
         }
         {
             this.dynamicApiCall("depositCode", "depositCode")
         }
         {
             this.dynamicApiCall("tradeService", "tradeService")
         }
         {
             this.dynamicApiCall("addressType", "addressType")
         }
         {
             this.dynamicApiCall("guardianCode", "guardianCode")
         }
         {
             this.dynamicApiCall("accountStatement", "accountStatement")
         }

         {
             this.dynamicApiCall("modOfOperation", "modOfOperation")
         }
         {
             this.dynamicApiCall("introducerStatus", "introducerStatus")
         }
         {
             this.dynamicApiCall("bbkSegmentCode", "bbkSegmentCode")
         }
         {
             this.dynamicApiCall("priorityCenterCode", "priorityCenterCode")
         }
         {
             this.dynamicApiCall("contactType", "contactType")
         }
         if (this.props.cbNumber !== undefined) {
             let url = backEndServerURL + "/cbsCustomerGet/" + this.props.cbNumber;
             axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                 .then((response) => {
                     this.setState({
                         inputData: this.apiDataSet(response.data, inputData),
                         varValue: this.apiDataSet(response.data, varValue),
                         showValue: true,
                         tagData:true,
                         getData: true,
                         loading: false

                     });
                 })
                 .catch((error) => {
                     console.log(error);
                     this.setState({
                         getData: true,
                         loading: false
                     })
                 })
         } else {
             this.setState({
                 getData: true,
                 loading: false
             })
         }
         let occupationCodeUrl = backEndServerURL + "/occupationCode/getAll";
         axios.get(occupationCodeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
             .then((response) => {
                 console.log("lllllllllllllllllpppp")
                 console.log(response.data)
                 this.findByVarNameApiData("misoccupationCodePriorityCodeStaff", response.data)
                 this.findByVarNameApiData("fincoreDataUpdateoccupationCode", response.data)
                 //this.findByVarNameApiData("customersOccupation", response.data)

             })
             .catch((error) => {
                 console.log(error)
                 this.setState({
                     getData: true,
                     loading: false
                 })
             })
         let fileUrl = backEndServerURL + "/case/files/" + this.props.appId;
         axios.get(fileUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
             .then((response) => {
                 console.log(":d")
                 console.log(response.data)
                 this.setState({
                     documentList: response.data,
                     getDocument: true
                 })
             })
             .catch((error) => {
                 console.log(error);
                 this.setState({
                     getData: true,
                     loading: false
                 })
             })

     }*/
    dynamicApiCallDocumentType = (fieldName, apiType) => {
        let uniqueDocTypeResponse=  LocalstorageEncrypt.encryptStorageFunction.getItem("getMasterData"+apiType);
        console.log(uniqueDocTypeResponse)
        console.log("uniqueDocTypeResponse")
        if(uniqueDocTypeResponse===undefined || uniqueDocTypeResponse===null) {
            let codeUrl = backEndServerURL + "/finMasterData/custom/uniqueDocType/" + apiType;
            axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterData"+apiType, response.data);
                    if (apiType === "documentCodeDocumentType") {
                        // this.findByVarNameApiData("natioanlIdCarddocumentTypeN", response.data)
                        //  this.findByVarNameApiData("smartIdCarddocumentTypeS", response.data)
                        //  this.findByVarNameApiData("passportNumberdocumentTypeP", response.data)
                        //  this.findByVarNameApiData("birthCertificatedocumentTypeB", response.data)
                        // this.findByVarNameApiData("chairmanCertificatedocumentTypeCC", response.data)
                        // this.findByVarNameApiData("etindocumentType", response.data)
                        this.findByVarNameApiData("photoIddocumentType", response.data)

                    } else if (apiType === "documentCodeDocumentTypeDesc") {
                        // this.findByVarNameApiData("natioanlIdCarddocumentTypeDescriptionN", response.data)
                        //  this.findByVarNameApiData("smartIdCarddocumentTypeDescriptionS", response.data)
                        // this.findByVarNameApiData("passportNumberdocumentTypeDescriptionP", response.data)
                        //  this.findByVarNameApiData("birthCertificatedocumentTypeDescriptionB", response.data)
                        //  this.findByVarNameApiData("chairmanCertificatedocumentTypeDescriptionCC", response.data)
                        //  this.findByVarNameApiData("etindocumentTypeDescription", response.data)
                        this.findByVarNameApiData("photoIddocumentTypeDescription", response.data)

                    }

                })
                .catch((error) => {
                    console.log(error)
                })
        }
        else {
            //let uniqueDocTypeResponse = uniqueDocTypeResponse;
            if (apiType === "documentCodeDocumentType") {
                // this.findByVarNameApiData("natioanlIdCarddocumentTypeN", response.data)
                //  this.findByVarNameApiData("smartIdCarddocumentTypeS", response.data)
                //  this.findByVarNameApiData("passportNumberdocumentTypeP", response.data)
                //  this.findByVarNameApiData("birthCertificatedocumentTypeB", response.data)
                // this.findByVarNameApiData("chairmanCertificatedocumentTypeCC", response.data)
                // this.findByVarNameApiData("etindocumentType", response.data)
                this.findByVarNameApiData("photoIddocumentType",uniqueDocTypeResponse)

            } else if (apiType === "documentCodeDocumentTypeDesc") {
                // this.findByVarNameApiData("natioanlIdCarddocumentTypeDescriptionN", response.data)
                //  this.findByVarNameApiData("smartIdCarddocumentTypeDescriptionS", response.data)
                // this.findByVarNameApiData("passportNumberdocumentTypeDescriptionP", response.data)
                //  this.findByVarNameApiData("birthCertificatedocumentTypeDescriptionB", response.data)
                //  this.findByVarNameApiData("chairmanCertificatedocumentTypeDescriptionCC", response.data)
                //  this.findByVarNameApiData("etindocumentTypeDescription", response.data)
                this.findByVarNameApiData("photoIddocumentTypeDescription", uniqueDocTypeResponse)

            }

        }
    };

    functionForSaveInquiry = (value) => {
        let saveInquiryData = backEndServerURL + "/secondaryInfo/setInquiryActivity/" + this.props.appId + "/" + this.props.cbNumber;
        axios.post(saveInquiryData, value, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((saveResponse) => {

            })
            .catch((error) => {
                console.log(error);
            });
    };

    componentDidMount() {
        if(this.props.processType !== undefined){
            let jsonForm = CBDataJsonForm.concat(CBDataJsonFormNew);
            for (let item of jsonForm) {
                if (item.varName === "schemeCodeChangemodeOfOperation" || item.varName === "aof1despatchModePrintedEStatementBoth" || item.varName === "aof1smsAlertFreeText11" || item.varName === "nidVerified") {
                    item.required = false
                }
            }
        }
        this.setState({
            loading: true
        });
        let fileUrl = backEndServerURL + "/case/files/" + this.props.appId;
        axios.get(fileUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    documentList: response.data,
                    getDocument: true
                })
            })
            .catch((error) => {
                console.log(error);
            });

        {
            this.dynamicApiCallDocumentType("documentCodeDocumentType", "documentCodeDocumentType")
        }
        {
            this.dynamicApiCallDocumentType("documentCodeDocumentTypeDesc", "documentCodeDocumentTypeDesc")
        }
        {
            this.dynamicApiCall("city2", "city")
        }
        {
            this.dynamicApiCall("placeOfIssue", "placeOfIssue")
        }
        {
            this.dynamicApiCall("state2", "state",)
        }
        {
            this.dynamicApiCall("currency", "currency")
        }
        {
            this.dynamicApiCall("nationality", "country")
        }
        {
            this.dynamicApiCall("passportNumberplaceOfIssue", "passportNumberplaceOfIssue")
        }
        {
            this.dynamicApiCall("documentsCollectedToEnsureTheSourceOfFund", "sourceOfFund")
        }
        {
            this.dynamicApiCall("customersOccupation", "customerOccupation")
        }
        {
            this.dynamicApiCall("addressVariety", "addressVariety")
        }
        {
            this.dynamicApiCall("whatDoesTheCustomerDoInWhatTypeOfBusineessIsTheCustomerEngaged", "customerBusiness")
        }
        {
            this.dynamicApiCall("monthlyIncome", "monthlyIncome")
        }
        {
            this.dynamicApiCall("howWasAccountOpened", "accountOpen")
        }
        {
            this.dynamicApiCall("constitution", "constitution")
        }
        {
            this.dynamicApiCall("waiverField", "waiverField")
        }
        {
            this.dynamicApiCall("bankRelationType", "bankRelationType")
        }
        /* {
             this.dynamicApiCall("emailType", "emailType")
         }*/
        /*  {
              this.dynamicApiCall("phoneOrEmailId", "phoneOrEmailId")
          }*/

        {
            this.dynamicApiCall("ccepCode", "ccepCode")
        }
        {
            this.dynamicApiCall("maritalStatus", "maritalStatus")
        }
        {
            this.dynamicApiCall("nomineeRelationCode", "nomineeRelationCode")
        }

        /* {
             this.dynamicApiCall("bankName", "bankName")
         }*/
        {
            this.dynamicApiCall("title", "title")
        }
        {
            this.dynamicApiCall("documentCode", "documentCode")
        }
        {
            this.dynamicApiCall("relationship", "relationship")
        }
        /*  {
              this.dynamicApiCall("contactType", "contactType")
          }*/
        {
            this.dynamicApiCall("employmentType", "employmentType")
        }
        {
            this.dynamicApiCall("agentBankingOutlet", "agentBankingOutlet")
        }
        {
            this.dynamicApiCall("nonResident", "nonResident")
        }
        {
            this.dynamicApiCall("customerStatus", "customerStatus")
        }
        /*  {
              this.dynamicApiCall("addressFormat", "addressFormat")
          }*/
        /* {
             this.dynamicApiCall("branch", "branch")
         }*/
        {
            this.dynamicApiCall("relationCode", "relationCode")
        }
        {
            this.dynamicApiCall("relationType", "relationType")
        }
        {
            this.dynamicApiCall("dispatchMode", "dispatchMode")
        }
        {
            this.dynamicApiCall("statementFrequency", "statementFrequency")
        }
        {
            this.dynamicApiCall("chequebookWaiverFunctionCode", "chequebookWaiverFunctionCode")
        }
        {
            this.dynamicApiCall("chequebookWaiverType", "chequebookWaiverType")
        }
        /*  {
              this.dynamicApiCall("rmCode", "rmCode")
          }*/
        {
            this.dynamicApiCall("subSegment", "subSegment")
        }

        {
            this.dynamicApiCall("designationCode", "designationCode")
        }
        {
            this.dynamicApiCall("sectorCode", "sectorCode")
        }
        {
            this.dynamicApiCall("subSectorCode", "subSectorCode")
        }
        {
            this.dynamicApiCall("depositCode", "depositCode")
        }
        {
            this.dynamicApiCall("tradeService", "tradeService")
        }
        {
            this.dynamicApiCall("addressType", "addressType")
        }
        {
            this.dynamicApiCall("guardianCode", "guardianCode")
        }
        {
            this.dynamicApiCall("accountStatement", "accountStatement")
        }

        {
            this.dynamicApiCall("modOfOperation", "modOfOperation")
        }
        {
            this.dynamicApiCall("introducerStatus", "introducerStatus")
        }
        {
            this.dynamicApiCall("bbkSegmentCode", "bbkSegmentCode")
        }
        {
            this.dynamicApiCall("priorityCenterCode", "priorityCenterCode")
        }
        {
            this.dynamicApiCall("priorityCenterCode", "priorityCenterCode")
        }
        {
            this.dynamicApiCall("schemeCodeForSavings", "savingsSchemeCode")
        }
        {
            this.dynamicApiCall("schemeCodeForCurrent", "currentSchemeCode")
        }
        {
            this.dynamicApiCall("phoneCountryCode", "phoneCountryCode")
        }


        /*  {
              this.dynamicApiCall("contactType", "contactType")
          }*/

        let occupationCodeUrl = backEndServerURL + "/occupationCode/getAll";
        axios.get(occupationCodeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log("lllllllllllllllllpppp");
                console.log(response.data);
                this.findByVarNameApiData("misoccupationCodePriorityCodeStaff", response.data);
                this.findByVarNameApiData("fincoreDataUpdateoccupationCode", response.data);
                this.setState({
                    occupationCodeList: response.data
                })
                //this.findByVarNameApiData("customersOccupation", response.data)

            })
            .catch((error) => {
                console.log(error)
            });


        if (this.props.appId !== undefined) {
            this.setState({
                loading: true
            });

            if (this.props.cbNumber !== undefined) {

                let inputData = {};
                let varValue = {};

                let url = backEndServerURL + "/cbsCustomerGet/" + this.props.cbNumber;
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        inputData = this.emptyValueRemove(response.data);
                        varValue = this.emptyValueRemove(response.data);
                        inputData.aof1currencyCodeCcyExist = true;
                        varValue.aof1currencyCodeCcyExist = true;
                        let dataInputs = this.apiDataSet(response.data, inputData);
                        let datavarValues = this.apiDataSet(response.data, varValue);
                        let getCBUrl2 = backEndServerURL + "/getCustomerCIFDetails/" + this.props.cbNumber;/*"2801187408001"*/
                        axios.get(getCBUrl2, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((cb2DataResponse) => {
                                let dataInput = this.apiDataSet(cb2DataResponse.data, dataInputs, "cbExtraInformation");
                                let datavarValue = this.apiDataSet(cb2DataResponse.data, datavarValues, "cbExtraInformation");
                                setTimeout(() => {
                                    this.functionForSaveInquiry(dataInput);
                                    this.setState({
                                        inputData: dataInput,
                                        varValue: datavarValue,
                                        tempDataSave: dataInput,
                                        showValue: true,
                                        tagData: true,
                                        getData: true,
                                        loading: false

                                    });
                                }, 500)
                            })


                            .catch((error) => {
                                console.log(error);
                            })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            getData: true,
                            loading: false
                        })
                    })
            } else {
                let inputData = {};
                let varValue = {};
                let inputDataForBranch = {};
                let varValueForBranch = {};
                var remarksArray = [];
                let url = backEndServerURL + "/getSolIdFromAppId/" + this.props.appId;/*"2801187408001"*/
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((branchData) => {
                        inputDataForBranch.misprimarySolId = branchData.data;
                        inputDataForBranch.basicInformationUpdateprimarySolId = branchData.data;
                        varValueForBranch.misprimarySolId = branchData.data;
                        varValueForBranch.basicInformationUpdateprimarySolId = branchData.data;
                        let variablesUrl = backEndServerURL + '/variables/' + this.props.appId;
                        axios.get(variablesUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                console.log(response.data);
                                inputData = this.apiDataSet(response.data, inputDataForBranch, "New Customer");
                                varValue = this.apiDataSet(response.data, varValueForBranch, "New Customer");
                                inputData.aof1currencyCodeCcyExist = false;
                                varValue.aof1currencyCodeCcyExist = false;
                                inputData.primryintroducerCustomerIdCifId = this.props.introducerId;
                                varValue.primryintroducerCustomerIdCifId = this.props.introducerId;
                                inputData.primryiintroducerNameLastName = this.props.introducerName;
                                varValue.primryiintroducerNameLastName = this.props.introducerName;
                                let i = this.props.tagingModalCbnumber;
                                if (this.props.relatedPartyType === "Mandate" || this.props.relatedPartyType === "MANDATE" || this.props.relatedPartyType === "Mandate1" || this.props.relatedPartyType === "MANDATE1") {

                                    inputData.miscustomerNameLastName = response.data["mandatecustomerName"];
                                    varValue.miscustomerNameLastName = response.data["mandatecustomerName"];
                                    inputData.nationalIdCardNumberUniqueId = response.data["mandatenid"];
                                    inputData.passportNumberpassportNumberUniqueId = response.data["mandatepassport"];
                                    inputData.birthCertificatebirthCertificateNumberUniqueId = response.data["mandateregistrationNo"];
                                    inputData.drivingLicenseNumberUniqueId = response.data["mandatedrivingLicense"];

                                    // this.minorFlagCalculate(response.data["mandatedob"])

                                    inputData.basicInformationUpdatedob = response.data["mandatedob"];
                                    inputData.contactNumberphoneNo1 = response.data["mandatephone"];
                                    if (response.data["mobileTypeMandate"] === "Local") {
                                        inputData.mobileTypecontactNumberphoneNo1 = response.data["mobileTypeMandate"];
                                    } else {
                                        inputData.mobileTypecontactNumberphoneNo1 = response.data["mobileTypeMandate"];
                                        inputData.countryCodecontactNumberphoneNo1 = this.findByVarNameGetKeyValue(response.data["beneficiarycountryCode"], this.state.phoneNumCountryCodeList)
                                    }
                                    inputData.contactNumberEmaildesignatedEmailAddressEmailId1 = response.data["mandateemail"];
                                    inputData.etineTinNumber = response.data["mandatetin"];
                                    inputData.etineTinNumber = response.data["mandatetin"];
                                    inputData.Customersnationality = response.data["mandatenationality"];
                                    varValue.nationalIdCardNumberUniqueId = response.data["mandatenid"];
                                    varValue.passportNumberpassportNumberUniqueId = response.data["mandatepassport"];
                                    varValue.birthCertificatebirthCertificateNumberUniqueId = response.data["mandateregistrationNo"];
                                    varValue.drivingLicenseNumberUniqueId = response.data["mandatedrivingLicense"];
                                    //{this.minorFlagCalculate(response.data["mandatedob"])}
                                    varValue.basicInformationUpdatedob = response.data["mandatedob"];
                                    varValue.contactNumberphoneNo1 = response.data["mandatephone"];
                                    if (response.data["mobileTypeMandate"] === "Local") {
                                        varValue.mobileTypecontactNumberphoneNo1 = response.data["mobileTypeMandate"];
                                    } else {
                                        varValue.mobileTypecontactNumberphoneNo1 = response.data["mobileTypeMandate"];
                                        varValue.countryCodecontactNumberphoneNo1 = this.findByVarNameGetKeyValue(response.data["beneficiarycountryCode"], this.state.phoneNumCountryCodeList)
                                    }
                                    varValue.contactNumberEmaildesignatedEmailAddressEmailId1 = response.data["mandateemail"];
                                    varValue.etineTinNumber = response.data["mandatetin"];
                                    varValue.etineTinNumber = response.data["mandatetin"];
                                    varValue.Customersnationality = response.data["mandatenationality"]
                                }
                                else if (this.props.relatedPartyType === "Beneficial" || this.props.relatedPartyType === "BENEFICIAL" || this.props.relatedPartyType === "Beneficial1" || this.props.relatedPartyType === "BENEFICIAL1") {
                                    console.log("..>>else if")
                                    inputData.miscustomerNameLastName = response.data["beneficiarycustomerName"];
                                    varValue.miscustomerNameLastName = response.data["beneficiarycustomerName"];
                                    inputData.nationalIdCardNumberUniqueId = response.data["beneficiarynid"];
                                    inputData.passportNumberpassportNumberUniqueId = response.data["beneficiarypassport"];
                                    inputData.birthCertificatebirthCertificateNumberUniqueId = response.data["beneficiaryregistrationNo"];
                                    inputData.drivingLicenseNumberUniqueId = response.data["beneficiarydrivingLicense"];

                                    // this.minorFlagCalculate(response.data["beneficiarydob"])


                                    inputData.basicInformationUpdatedob = response.data["beneficiarydob"];
                                    inputData.contactNumberphoneNo1 = response.data["beneficiaryphone"];
                                    if (response.data["mobileTypeBeneficiary"] === "Local") {
                                        inputData.mobileTypecontactNumberphoneNo1 = response.data["mobileTypeBeneficiary"];
                                    } else {
                                        inputData.mobileTypecontactNumberphoneNo1 = response.data["mobileTypeBeneficiary"];
                                        inputData.countryCodecontactNumberphoneNo1 = this.findByVarNameGetKeyValue(response.data["beneficiarycountryCode"], this.state.phoneNumCountryCodeList)
                                    }
                                    inputData.contactNumberEmaildesignatedEmailAddressEmailId1 = response.data["beneficiaryemail"];
                                    inputData.etineTinNumber = response.data["beneficiarytin"];
                                    inputData.etineTinNumber = response.data["beneficiarytin"];
                                    inputData.Customersnationality = response.data["beneficiarynationality"];


                                    varValue.nationalIdCardNumberUniqueId = response.data["beneficiarynid"];
                                    varValue.passportNumberpassportNumberUniqueId = response.data["beneficiarypassport"];
                                    varValue.birthCertificatebirthCertificateNumberUniqueId = response.data["beneficiaryregistrationNo"];
                                    varValue.drivingLicenseNumberUniqueId = response.data["beneficiarydrivingLicense"];
                                    // {this.minorFlagCalculate(response.data["beneficiarydob"])}
                                    varValue.basicInformationUpdatedob = response.data["beneficiarydob"];
                                    varValue.contactNumberphoneNo1 = response.data["beneficiaryphone"];
                                    if (response.data["mobileTypeBeneficiary"] === "Local") {
                                        varValue.mobileTypecontactNumberphoneNo1 = response.data["mobileTypeBeneficiary"];
                                    } else {
                                        varValue.mobileTypecontactNumberphoneNo1 = response.data["mobileTypeBeneficiary"];
                                        varValue.countryCodecontactNumberphoneNo1 = this.findByVarNameGetKeyValue(response.data["beneficiarycountryCode"], this.state.phoneNumCountryCodeList)
                                    }
                                    varValue.contactNumberEmaildesignatedEmailAddressEmailId1 = response.data["beneficiaryemail"];
                                    varValue.etineTinNumber = response.data["beneficiarytin"];
                                    varValue.etineTinNumber = response.data["beneficiarytin"];
                                    varValue.Customersnationality = response.data["beneficiarynationality"]
                                }
                                else {
                                    inputData.miscustomerNameLastName = response.data["customerName" + i];
                                    varValue.miscustomerNameLastName = response.data["customerName" + i];
                                    inputData.nationalIdCardNumberUniqueId = response.data["nid" + i];
                                    inputData.passportNumberpassportNumberUniqueId = response.data["passport" + i];
                                    inputData.birthCertificatebirthCertificateNumberUniqueId = response.data["registrationNo" + i];
                                    inputData.drivingLicenseNumberUniqueId = response.data["drivingLicense" + i];
                                    inputData.smartCardNumberUniqueId = response.data["smartCard" + i];


                                    // this.minorFlagCalculate(response.data["dob" + i])


                                    inputData.basicInformationUpdatedob = response.data["dob" + i];
                                    inputData.contactNumberphoneNo1 = response.data["phone" + i];
                                    if (response.data["mobileTypeJoint" + i] === "Local") {
                                        inputData.mobileTypecontactNumberphoneNo1 = response.data["mobileTypeJoint" + i];
                                    } else {
                                        inputData.mobileTypecontactNumberphoneNo1 = response.data["mobileTypeJoint" + i];
                                        inputData.countryCodecontactNumberphoneNo1 = this.findByVarNameGetKeyValue(response.data["countryCode" + i], this.state.phoneNumCountryCodeList)
                                    }
                                    inputData.contactNumberEmaildesignatedEmailAddressEmailId1 = response.data["email" + i];
                                    inputData.etineTinNumber = response.data["tin" + i];
                                    inputData.etineTinNumber = response.data["tin" + i];
                                    inputData.Customersnationality = response.data["nationality" + i];


                                    varValue.nationalIdCardNumberUniqueId = response.data["nid" + i];
                                    varValue.passportNumberpassportNumberUniqueId = response.data["passport" + i];
                                    varValue.birthCertificatebirthCertificateNumberUniqueId = response.data["registrationNo" + i];
                                    varValue.drivingLicenseNumberUniqueId = response.data["drivingLicense" + i];
                                    varValue.smartCardNumberUniqueId = response.data["smartCard" + i];
                                    // {this.minorFlagCalculate(response.data["dob"])}
                                    varValue.basicInformationUpdatedob = response.data["dob" + i];
                                    varValue.contactNumberphoneNo1 = response.data["phone" + i];
                                    if (response.data["mobileTypeJoint" + i] === "Local") {
                                        varValue.mobileTypecontactNumberphoneNo1 = response.data["mobileTypeJoint" + i];
                                    } else {
                                        varValue.mobileTypecontactNumberphoneNo1 = response.data["mobileTypeJoint" + i];
                                        varValue.countryCodecontactNumberphoneNo1 = this.findByVarNameGetKeyValue(response.data["countryCode" + i], this.state.phoneNumCountryCodeList)
                                    }
                                    varValue.contactNumberEmaildesignatedEmailAddressEmailId1 = response.data["email" + i];
                                    varValue.etineTinNumber = response.data["tin" + i];
                                    varValue.etineTinNumber = response.data["tin" + i];
                                    varValue.Customersnationality = response.data["nationality" + i]
                                }

                                //delete flag start
                                inputData.contactNumberEmaildesignatedEmailAddressEmailId1Delete = "N";

                                inputData.natioanlIdCardDelete = "N";
                                inputData.smartIdCardDelete = "N";
                                inputData.passportNumberDelete = "N";
                                inputData.drivingLicenseNumberDelete = "N";
                                inputData.residentNumberDelete = "N";
                                inputData.birthCertificateDelete = "N";
                                inputData.chairmanCertificateDelete = "N";
                                inputData.photoIdDelete = "N";
                                inputData.etinDelete = "N";
                                inputData.contactNumber2Delete = "N";
                                inputData.contactNumber3Delete = "N";
                                inputData.contactNumberEmailemailId2Delete = "N";

                                inputData.homeAddressDelete = "N";
                                inputData.workAddressDelete = "N";
                                inputData.nreAddressDelete = "N";
                                inputData.presentAddressDelete = "N";
                                //delete flag End
                                inputData.natioanlIdCarddocumentTypeN = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };

                                inputData.natioanlIdCarddocumentTypeDescriptionN = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };
                                inputData.natioanlIdCarddocumentCodeN = {
                                    "masterDataName": "documentCode",
                                    "key": "NID",
                                    "value": "NID CARD IDENTITY"
                                };
                                inputData.drivingLicensedocumentCodeN = {
                                    "masterDataName": "documentCode",
                                    "key": "DL",
                                    "value": "DRIVING LICENSE"
                                };
                                //Passport
                                inputData.passportNumberdocumentTypeP = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };

                                inputData.passportNumberdocumentTypeDescriptionP = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };

                                inputData.passportNumberdocumentCodeP = {
                                    "masterDataName": "documentCode",
                                    "key": "PASSPORT",
                                    "value": "PASSPORT IDENTITY"
                                };

                                inputData.smartIdCarddocumentTypeS = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };
                                inputData.smartIdCarddocumentTypeDescriptionS = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };
                                inputData.smartIdCarddocumentCodeS = {
                                    "masterDataName": "documentCode",
                                    "key": "RSNID",
                                    "value": "RSNID"
                                };

                                inputData.birthCertificatedocumentTypeB = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };
                                inputData.birthCertificatedocumentTypeDescriptionB = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };
                                inputData.birthCertificatedocumentCodeB = {
                                    "masterDataName": "documentCode",
                                    "key": "BC",
                                    "value": "BIRTH CERTIFICATE"
                                };
                                inputData.chairmanCertificatedocumentTypeCC = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };
                                inputData.chairmanCertificatedocumentTypeDescriptionCC = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };
                                inputData.chairmanCertificatedocumentCodeCC = {
                                    "masterDataName": "documentCode",
                                    "key": "CHAC",
                                    "value": "CHAIRMAN CERTIFICATE"
                                };

                                inputData.etindocumentType = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };

                                inputData.etindocumentTypeDescription = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };

                                inputData.etindocumentCode = {
                                    "masterDataName": "documentCode",
                                    "key": "ETIN",
                                    "value": "TIN CARD IDENTITY"
                                };
                                inputData.contactNumberEmailpreferedEmailIdType = {
                                    "masterDataName": "emailType",
                                    "key": "COMMEML",
                                    "value": "COMMEML"
                                };
                                inputData.contactNumberEmailemailType1 = {
                                    "masterDataName": "emailType",
                                    "key": "COMMEML",
                                    "value": "COMMEML"
                                };
                                inputData.contactNumberEmailphoneNoEmailId4 = {
                                    "masterDataName": "phoneOrEmailId",
                                    "key": "EMAIL",
                                    "value": "EMAIL"
                                };
                                inputData.contactNumberpreferredContactNoType1 = {
                                    "masterDataName": "contactType",
                                    "key": "COMMPH1",
                                    "value": "COMMPH1"
                                };
                                inputData.contactNumbercontactType1 = {
                                    "masterDataName": "contactType",
                                    "key": "COMMPH1",
                                    "value": "COMMPH1"
                                };
                                inputData.contactNumberphoneNoEmailId1 = {
                                    "masterDataName": "phoneOrEmailId",
                                    "key": "PHONE",
                                    "value": "PHONE"
                                };
                                inputData.contactNumbercontactType2 = {
                                    "masterDataName": "contactType",
                                    "key": "COMMPH2",
                                    "value": "COMMPH2"
                                };
                                inputData.contactNumberphoneNoEmailId2 = {
                                    "masterDataName": "phoneOrEmailId",
                                    "key": "PHONE",
                                    "value": "PHONE"
                                };
                                inputData.contactNumbercontactType3 = {
                                    "masterDataName": "contactType",
                                    "key": "WORKPH1",
                                    "value": "WORKPH1"
                                };
                                inputData.contactNumberphoneNoEmailId3 = {
                                    "masterDataName": "phoneOrEmailId",
                                    "key": "PHONE",
                                    "value": "PHONE"
                                };
                                //inputData.contactNumberphoneNo3=data. ;

                                inputData.contactNumberEmailemailType = {
                                    "masterDataName": "emailType",
                                    "key": "HOMEEML",
                                    "value": "HOMEEML"
                                };
                                inputData.contactNumberEmailphoneNoEmailId = {
                                    "masterDataName": "phoneOrEmailId",
                                    "key": "EMAIL",
                                    "value": "EMAIL"
                                };
                                /*  inputData.employerDataprofessionEmploymentType = {
                                      "masterDataName": "employmentType",
                                      "key": "MEDIA CELEBRITY",
                                      "value": "MEDIA CELEBRITY"
                                  };
                                  inputData.employerDataemploymentType = {
                                      "masterDataName": "employmentType",
                                      "key": "MEDIA CELEBRITY",
                                      "value": "MEDIA CELEBRITY"
                                  };*/
                                inputData.maillingAdresspreferredAddressType = {
                                    "masterDataName": "addressType",
                                    "key": "Mailing",
                                    "value": "Mailing"
                                };
                                inputData.maillingAdressaddressFormat = {
                                    "masterDataName": "addressFormat",
                                    "key": "FREE_TEXT_FORMAT",
                                    "value": "FREE_TEXT_FORMAT"
                                };
                                inputData.maillingAdressaddrssType = {
                                    "masterDataName": "addressType",
                                    "key": "Mailing",
                                    "value": "Mailing"
                                };
                                inputData.maillingAdressaddrssType = {
                                    "masterDataName": "addressType",
                                    "key": "Mailing",
                                    "value": "Mailing"
                                };
                                inputData.homeAddressaddressFormat = {
                                    "masterDataName": "addressFormat",
                                    "key": "FREE_TEXT_FORMAT",
                                    "value": "FREE_TEXT_FORMAT"
                                };
                                inputData.homeAddressaddrssType = {
                                    "masterDataName": "addressType",
                                    "key": "Home",
                                    "value": "Home"
                                };
                                inputData.homeAddressaddrssType = {
                                    "masterDataName": "addressType",
                                    "key": "Home",
                                    "value": "Home"
                                };
                                inputData.workAddressaddressFormat = {
                                    "masterDataName": "addressFormat",
                                    "key": "FREE_TEXT_FORMAT",
                                    "value": "FREE_TEXT_FORMAT"
                                };
                                inputData.workAddressaddressType = {
                                    "masterDataName": "addressType",
                                    "key": "Work",
                                    "value": "Work"
                                };
                                inputData.workAddressaddressType = {
                                    "masterDataName": "addressType",
                                    "key": "Work",
                                    "value": "Work"
                                };
                                inputData.presentAddressaddressFormat = {
                                    "masterDataName": "addressFormat",
                                    "key": "FREE_TEXT_FORMAT",
                                    "value": "FREE_TEXT_FORMAT"
                                };
                                inputData.nreAddressaddressFormat = {
                                    "masterDataName": "addressFormat",
                                    "key": "FREE_TEXT_FORMAT",
                                    "value": "FREE_TEXT_FORMAT"
                                };
                                inputData.nreAddressaddrssType =
                                    {
                                        "masterDataName": "addressType",
                                        "key": "NRERelative",
                                        "value": "NRERelative"
                                    };
                                inputData.nreAddressaddrssType =
                                    {
                                        "masterDataName": "addressType",
                                        "key": "NRERelative",
                                        "value": "NRERelative"
                                    };

                                inputData.presentAddressaddrssType = {
                                    "masterDataName": "addressType",
                                    "key": "Future/OnSite",
                                    "value": "Future/OnSite"
                                };
                                varValue.natioanlIdCarddocumentTypeN = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };

                                varValue.natioanlIdCarddocumentTypeDescriptionN = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };
                                varValue.drivingLicensedocumentCodeN = {
                                    "masterDataName": "documentCode",
                                    "key": "DL",
                                    "value": "DRIVING LICENSE"
                                };
                                varValue.natioanlIdCarddocumentCodeN = {
                                    "masterDataName": "documentCode",
                                    "key": "NID",
                                    "value": "NID CARD IDENTITY"
                                };
//Passport
                                varValue.passportNumberdocumentTypeP = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };

                                varValue.passportNumberdocumentTypeDescriptionP = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };

                                varValue.passportNumberdocumentCodeP = {
                                    "masterDataName": "documentCode",
                                    "key": "PASSPORT",
                                    "value": "PASSPORT IDENTITY"
                                };

                                varValue.smartIdCarddocumentTypeS = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };
                                varValue.smartIdCarddocumentTypeDescriptionS = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };
                                varValue.smartIdCarddocumentCodeS = {
                                    "masterDataName": "documentCode",
                                    "key": "RSNID",
                                    "value": "RSNID"
                                };

                                varValue.birthCertificatedocumentTypeB = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };
                                varValue.birthCertificatedocumentTypeDescriptionB = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };
                                varValue.birthCertificatedocumentCodeB = {
                                    "masterDataName": "documentCode",
                                    "key": "BC",
                                    "value": "BIRTH CERTIFICATE"
                                };
                                varValue.chairmanCertificatedocumentTypeCC = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };
                                varValue.chairmanCertificatedocumentTypeDescriptionCC = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };
                                varValue.chairmanCertificatedocumentCodeCC = {
                                    "masterDataName": "documentCode",
                                    "key": "CHAC",
                                    "value": "CHAIRMAN CERTIFICATE"
                                };

                                varValue.etindocumentType = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };

                                varValue.etindocumentTypeDescription = {
                                    "masterDataName": "documentCodeDocumentType",
                                    "key": "IDENTIFICATION PROOF",
                                    "value": "IDENTIFICATION PROOF"
                                };

                                varValue.etindocumentCode = {
                                    "masterDataName": "documentCode",
                                    "key": "ETIN",
                                    "value": "TIN CARD IDENTITY"
                                };
                                varValue.contactNumberEmailpreferedEmailIdType = {
                                    "masterDataName": "emailType",
                                    "key": "COMMEML",
                                    "value": "COMMEML"
                                };
                                varValue.contactNumberEmailemailType1 = {
                                    "masterDataName": "emailType",
                                    "key": "COMMEML",
                                    "value": "COMMEML"
                                };
                                varValue.contactNumberEmailphoneNoEmailId4 = {
                                    "masterDataName": "phoneOrEmailId",
                                    "key": "EMAIL",
                                    "value": "EMAIL"
                                };
                                varValue.contactNumberpreferredContactNoType1 = {
                                    "masterDataName": "contactType",
                                    "key": "COMMPH1",
                                    "value": "COMMPH1"
                                };
                                varValue.contactNumbercontactType1 = {
                                    "masterDataName": "contactType",
                                    "key": "COMMPH1",
                                    "value": "COMMPH1"
                                };
                                varValue.contactNumberphoneNoEmailId1 = {
                                    "masterDataName": "phoneOrEmailId",
                                    "key": "PHONE",
                                    "value": "PHONE"
                                };
                                varValue.contactNumbercontactType2 = {
                                    "masterDataName": "contactType",
                                    "key": "COMMPH2",
                                    "value": "COMMPH2"
                                };
                                varValue.contactNumberphoneNoEmailId2 = {
                                    "masterDataName": "phoneOrEmailId",
                                    "key": "PHONE",
                                    "value": "PHONE"
                                };
                                varValue.contactNumbercontactType3 = {
                                    "masterDataName": "contactType",
                                    "key": "WORKPH1",
                                    "value": "WORKPH1"
                                };
                                varValue.contactNumberphoneNoEmailId3 = {
                                    "masterDataName": "phoneOrEmailId",
                                    "key": "PHONE",
                                    "value": "PHONE"
                                };
//varValue.contactNumberphoneNo3=data. ;

                                varValue.contactNumberEmailemailType = {
                                    "masterDataName": "emailType",
                                    "key": "HOMEEML",
                                    "value": "HOMEEML"
                                };
                                varValue.contactNumberEmailphoneNoEmailId = {
                                    "masterDataName": "phoneOrEmailId",
                                    "key": "EMAIL",
                                    "value": "EMAIL"
                                };
                                /*  varValue.employerDataprofessionEmploymentType = {
                                      "masterDataName": "employmentType",
                                      "key": "MEDIA CELEBRITY",
                                      "value": "MEDIA CELEBRITY"
                                  };
                                  varValue.employerDataemploymentType = {
                                      "masterDataName": "employmentType",
                                      "key": "MEDIA CELEBRITY",
                                      "value": "MEDIA CELEBRITY"
                                  };*/
                                varValue.maillingAdresspreferredAddressType = {
                                    "masterDataName": "addressType",
                                    "key": "Mailing",
                                    "value": "Mailing"
                                };
                                varValue.maillingAdressaddressFormat = {
                                    "masterDataName": "addressFormat",
                                    "key": "FREE_TEXT_FORMAT",
                                    "value": "FREE_TEXT_FORMAT"
                                };
                                varValue.maillingAdressaddrssType = {
                                    "masterDataName": "addressType",
                                    "key": "Mailing",
                                    "value": "Mailing"
                                };
                                varValue.maillingAdressaddrssType = {
                                    "masterDataName": "addressType",
                                    "key": "Mailing",
                                    "value": "Mailing"
                                };
                                varValue.homeAddressaddressFormat = {
                                    "masterDataName": "addressFormat",
                                    "key": "FREE_TEXT_FORMAT",
                                    "value": "FREE_TEXT_FORMAT"
                                };
                                varValue.homeAddressaddrssType = {
                                    "masterDataName": "addressType",
                                    "key": "Home",
                                    "value": "Home"
                                };
                                varValue.homeAddressaddrssType = {
                                    "masterDataName": "addressType",
                                    "key": "Home",
                                    "value": "Home"
                                };
                                varValue.workAddressaddressFormat = {
                                    "masterDataName": "addressFormat",
                                    "key": "FREE_TEXT_FORMAT",
                                    "value": "FREE_TEXT_FORMAT"
                                };
                                varValue.workAddressaddressType = {
                                    "masterDataName": "addressType",
                                    "key": "Work",
                                    "value": "Work"
                                };
                                varValue.workAddressaddressType = {
                                    "masterDataName": "addressType",
                                    "key": "Work",
                                    "value": "Work"
                                };
                                varValue.presentAddressaddressFormat = {
                                    "masterDataName": "addressFormat",
                                    "key": "FREE_TEXT_FORMAT",
                                    "value": "FREE_TEXT_FORMAT"
                                };

                                varValue.presentAddressaddrssType = {
                                    "masterDataName": "addressType",
                                    "key": "Future/OnSite",
                                    "value": "Future/OnSite"
                                };
                                if (this.props.relatedPartyType === "Mandate" || this.props.relatedPartyType === "MANDATE" || this.props.relatedPartyType === "Mandate1" || this.props.relatedPartyType === "MANDATE1") {
                                    this.minorFlagCalculate(inputData)
                                } else if (this.props.relatedPartyType === "Beneficial" || this.props.relatedPartyType === "BENEFICIAL" || this.props.relatedPartyType === "Beneficial1" || this.props.relatedPartyType === "BENEFICIAL1") {
                                    this.minorFlagCalculate(inputData)
                                } else {
                                    this.minorFlagCalculate(inputData)
                                };
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    loading: false
                                })
                            })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })


            }


        }


    }

    minorFlagCalculate = (inputData) => {
        // this.state.inputData.basicInformationUpdatedobApiCall = "NO";
        // this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N"
        //this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
        //this.updateComponent()
        //if(data.value==="basicInformationUpdatedob"){
        let x = 18;
        var currentDate = new Date();

        let someDate = new Date();
        let numberOfDaysToAdd = x;
        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
        let date = someDate.getDate();
        let month = someDate.getMonth() + 1;
        let year = someDate.getFullYear();
        let convertDate = year + "-" + month + "-" + date;
        let newDate = new Date(convertDate);
        let stringToDate = new Date(newDate.toDateString());

        let inputDate = new Date(inputData.basicInformationUpdatedob);

        if (stringToDate >= inputDate) {
            inputData.basicInformationUpdatedobApiCall = "YES";
            inputData.basicInformationUpdateminorStatusMinorIndicator = "N"
        } else {
            inputData.basicInformationUpdatedobApiCall = "YES";
            inputData.basicInformationUpdateminorStatusMinorIndicator = "Y"
        }

        x = 60;
        currentDate = new Date();
        someDate = new Date();
        numberOfDaysToAdd = x;
        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
        date = someDate.getDate();
        month = someDate.getMonth() + 1;
        year = someDate.getFullYear();
        convertDate = year + "-" + month + "-" + date;
        newDate = new Date(convertDate);
        stringToDate = new Date(newDate.toDateString());

        inputDate = new Date(inputData.basicInformationUpdatedob);
        if (stringToDate >= inputDate) {
            inputData.basicInformationUpdateseniorCitizenIndicator = "Y"


        } else {

            inputData.basicInformationUpdateseniorCitizenIndicator = "N"
        }
        setTimeout(() => {
            this.setState({
                inputData: this.emptyValueRemove(inputData),
                varValue: this.emptyValueRemove(inputData),
                showValue: true,
                getData: true,
                loading: false
            })
        }, 500)


    };
    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = CBDataJsonFormNew;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];

            if (jsonObject.varName === searchVarname) {
                CBDataJsonFormNew[i].enum = getValue;

            }
        }

        jsonArray = CBDataJsonForm;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                CBDataJsonForm[i].enum = getValue;

            }
        }


    };
    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;
        console.log(value);
        console.log(getKeyValue);


        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            console.log(jsonObject);
            if (value === jsonObject.key) {
                return jsonObject;
                i = jsonArray.length;

            }
        }


    };
    /*  apiDataSet = (data, inputData, accountRelated) => {
          if (data !== undefined && data !== null && data !== "") {
              if(accountRelated==="New Customer"){

                   if (this.props.relatedPartyType === "Mandate") {
                      //inputData.d=mandatecbNumber
                      inputData.miscustomerNameLastName=data.mandatecustomerName
                      inputData.nationalIdCardNumberUniqueId=data.mandatenid
                      inputData.smartCardNumberUniqueId=data.mandatesmartCard
                      inputData.passportNumberpassportNumberUniqueId=data.mandatepassport
                      inputData.birthCertificatebirthCertificateNumberUniqueId=data.mandateregistrationNo
                      //inputData.d=mandateDrivingLicense
                      inputData.basicInformationUpdatedob=data.mandatedob
                      //inputData.d=mobileTypeMandate
                      //inputData.d=countryCodeMandate
                      inputData.contactNumberphoneNo1=data.mandatephone
                      inputData.contactNumberEmaildesignatedEmailAddressEmailId1=data.mandateemail
                      //inputData.d=mandatetin
                      //inputData.d=mandatenationality

                  }
                  if (this.props.relatedPartyType === "Beneficiary") {
                      //inputData.d=beneficiarycbNumber
                        inputData.miscustomerNameLastName=data.beneficiarycustomerName
                      inputData.nationalIdCardNumberUniqueId=data.beneficiarynid
                      inputData.smartCardNumberUniqueId=data.beneficiarysmartCard
                      inputData.passportNumberpassportNumberUniqueId=data.beneficiarypassport
                      inputData.birthCertificatebirthCertificateNumberUniqueId=data.beneficiaryregistrationNo
                      //inputData.d=beneficiaryDrivingLicense
                      inputData.basicInformationUpdatedob=data.beneficiarydob
                      //inputData.d=mobileTypeBeneficiary
                      //inputData.d=countryCodeBeneficiary
                      inputData.contactNumberphoneNo1=data.beneficiaryphone
                       inputData.contactNumberEmaildesignatedEmailAddressEmailId1=data.beneficiaryemail
                      //inputData.d=beneficiarytin
                      //inputData.d=beneficiarynationality

                  }
                  else {
                  /!*     let i=(this.props.tagingModalCbnumber)-1
                     // inputData.d=inputData.cbNumber + i
                   inputData.miscustomerNameLastName=data.customerName0
                      inputData.nationalIdCardNumberUniqueId=data.nid0
                      inputData.smartCardNumberUniqueId=data.smartCard + i
                      inputData.passportNumberpassportNumberUniqueId=data.passport + i
                      inputData.birthCertificatebirthCertificateNumberUniqueId=data.registrationNo + i
                      //inputData.d=inputData.drivingLicense
                  /!*    if((data.dob + i)!==undefined && (data.dob + i)!==null){
                          inputData.basicInformationUpdatedob=data.dob + i
                      }*!/


                       inputData.contactNumberphoneNo1=data.phone + i
                       inputData.contactNumberEmaildesignatedEmailAddressEmailId1=data.email + i*!/
                     // inputData.d=inputData.tin + i
                     // inputData.d=inputData.nationality + i
                  }

              }
              else{
                  inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall=true
                  inputData.hasTheBeneficialOwnerOfTheAccountBeenIdentified=true
                  inputData.passportNumberpassportNumberUniqueIdApiCall=true
                  inputData.nationalIdCardNumberUniqueIdApiCall=true
                  inputData.birthCertificatebirthCertificateNumberUniqueIdApiCall=true
                  inputData.electronicTaxIdApiCall=true
                  inputData.missolId = data.branchCode;
                  inputData.misprimarySolId = data.branchCode;
                  inputData.basicInformationUpdateprimarySolId = data.branchCode;
                  inputData.miscustomerNameLastName = data.customerName
                  inputData.misshortName = data.shortName
                  inputData.misaCNumber = data.accountNumber
                  inputData.customerAccount = data.accountNumber
                  inputData.mistitle = this.findByVarNameGetKeyValue(data.salutation, this.state.titleList)
                  inputData.aCTitle = this.findByVarNameGetKeyValue(data.salutation, this.state.titleList)
                  inputData.miscustomerStatusNormalStaffPriorityEtc = this.findByVarNameGetKeyValue(data.custStatus,this.state.customerStatusList)
                  inputData.misconstitution = this.findByVarNameGetKeyValue(data.constitutionCode, this.state.constitutionList)
                  inputData.mispriorityMonitoringRmCodeFreeCode3 = data.monitoringRMCode
                  inputData.misfunctionCodeForChequeBookWaiver = "A"
                  inputData.aof1currencyCodeCcy = this.findByVarNameGetKeyValue(data.currencyCode, this.state.currency)
                  inputData.aof1smsAlertFreeText11 = this.findByVarNameGetKeyValue(data.currencyCode, this.state.currency)
                  inputData.schemeCodeChangefunctionForSchemeChangeInCaseOfInstaPack = "T"

                  inputData.basicInformationUpdategender = data.gender;
                  inputData.basicInformationUpdatefatherNamePrefferedName = data.fatherName;
                  inputData.basicInformationUpdatemotherNameMothersMaidenName = data.motherName;
                  inputData.basicInformationUpdatemotherNameMothersMaidenName = data.motherName;
                  inputData.basicInformationUpdatemaritalStatus = this.findByVarNameGetKeyValue(data.maritalStatus, this.state.maritalStatusList);
                  inputData.basicInformationUpdatespouseNamePurgeRemarks = data.spouseName;
                  inputData.basicInformationUpdatedob = data.birthDate;
                  let birthDate=data.birthDate
                  if (birthDate && birthDate !== -1) {
                      let date = (birthDate).split('T')[0].split("-");
                      inputData.basicInformationUpdatedob = `${date[1]}/${date[2]}/${date[0]}`
                      /!* this.state.inputData.basicInformationUpdatedobApiCall = "NO";
                       this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N"
                       this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
                       this.updateComponent()
                       Functions.sleep(500).then(() => {
                           let x = 18;
                           var currentDate = new Date();
                           let someDate = new Date();
                           let numberOfDaysToAdd = x
                           someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                           let date = someDate.getDate();
                           let month = someDate.getMonth() + 1;
                           let year = someDate.getFullYear();
                           let convertDate = year + "-" + month + "-" + date;
                           let newDate = new Date(convertDate);
                           let stringToDate = new Date(newDate.toDateString());

                           let inputDate = new Date(`${date[1]}/${date[2]}/${date[0]}`);

                           if (stringToDate <= inputDate) {
                               this.state.inputData.basicInformationUpdatedobApiCall = "YES"
                               this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "Y"
                               this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "Y"
                           } else {
                               this.state.inputData.basicInformationUpdatedobApiCall = "YES"
                               this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N"
                               this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
                           }

                           x = 60;
                           currentDate = new Date();
                           someDate = new Date();
                           numberOfDaysToAdd = x
                           someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                           date = someDate.getDate();
                           month = someDate.getMonth() + 1;
                           year = someDate.getFullYear();
                           convertDate = year + "-" + month + "-" + date;
                           newDate = new Date(convertDate);
                           stringToDate = new Date(newDate.toDateString());

                           inputDate = new Date(this.state.inputData.basicInformationUpdatedob);
                           if (stringToDate >= inputDate) {
                               this.state.inputData.basicInformationUpdateseniorCitizenIndicator = "Y"
                               this.state.varValue.basicInformationUpdateseniorCitizenIndicator = "Y"
                           } else {
                               this.state.inputData.basicInformationUpdateseniorCitizenIndicator = "N"
                               this.state.varValue.basicInformationUpdateseniorCitizenIndicator = "N"
                           }
                           this.forceUpdate()

                       })
   *!/
                  }
                  inputData.basicInformationUpdateminorStatusMinorIndicator = data.isMinor;
                  inputData.basicInformationUpdateseniorCitizenIndicator = data.seniorCitizen;
                  inputData.fincoreDataUpdatefreeText10 = data.waiverfield;

                  inputData.primryintroducerCustomerIdCifId = data.introducerCifId;


                  inputData.natioanlIdCarddocumentTypeN = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  };

                  inputData.natioanlIdCarddocumentTypeDescriptionN = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  }

                  //Passport
                  inputData.passportNumberdocumentTypeP = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  };
                  ;
                  inputData.passportNumberdocumentTypeDescriptionP = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  };

                  inputData.passportNumberdocumentCodeP = {
                      "masterDataName": "documentCode",
                      "key": "PASSPORT",
                      "value": "PASSPORT IDENTITY"
                  };

                  inputData.smartIdCarddocumentTypeS = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  };
                  inputData.smartIdCarddocumentTypeDescriptionS = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  };
                  inputData.smartIdCarddocumentCodeS = {
                      "masterDataName": "documentCode",
                      "key": "RSNID",
                      "value": "RSNID"
                  };

                  inputData.birthCertificatedocumentTypeB = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  };
                  inputData.birthCertificatedocumentTypeDescriptionB = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  };
                  inputData.birthCertificatedocumentCodeB = {
                      "masterDataName": "documentCode",
                      "key": "BC",
                      "value": "BIRTH CERTIFICATE"
                  };
                  inputData.chairmanCertificatedocumentTypeCC = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  };
                  inputData.chairmanCertificatedocumentTypeDescriptionCC = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  };
                  inputData.chairmanCertificatedocumentCodeCC = {
                      "masterDataName": "documentCode",
                      "key": "COMC",
                      "value": "COMMISSIONER CERTIFICATE"
                  };

                  inputData.etindocumentType = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  };
                  ;
                  inputData.etindocumentTypeDescription = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  };
                  ;
                  inputData.etindocumentCode = {
                      "masterDataName": "documentCode",
                      "key": "ETIN",
                      "value": "TIN CARD IDENTITY"
                  }
                  inputData.contactNumberEmailpreferedEmailIdType = {
                      "masterDataName": "emailType",
                      "key": "COMMEML",
                      "value": "COMMEML"
                  }
                  inputData.contactNumberEmailemailType1 = {
                      "masterDataName": "emailType",
                      "key": "COMMEML",
                      "value": "COMMEML"
                  }
                  inputData.contactNumberEmailphoneNoEmailId4 = {
                      "masterDataName": "phoneOrEmailId",
                      "key": "EMAIL",
                      "value": "EMAIL"
                  }
                  inputData.contactNumberpreferredContactNoType1 = {
                      "masterDataName": "contactType",
                      "key": "COMMPH1",
                      "value": "COMMPH1"
                  }
                  inputData.contactNumbercontactType1 = {
                      "masterDataName": "contactType",
                      "key": "COMMPH1",
                      "value": "COMMPH1"
                  }
                  inputData.contactNumberphoneNoEmailId1 = {
                      "masterDataName": "phoneOrEmailId",
                      "key": "PHONE",
                      "value": "PHONE"
                  }
                  inputData.contactNumbercontactType2 = {
                      "masterDataName": "contactType",
                      "key": "COMMPH2",
                      "value": "COMMPH2"
                  };
                  inputData.contactNumberphoneNoEmailId2 = {
                      "masterDataName": "phoneOrEmailId",
                      "key": "PHONE",
                      "value": "PHONE"
                  };
                  inputData.contactNumbercontactType3 = {
                      "masterDataName": "contactType",
                      "key": "WORKPH1",
                      "value": "WORKPH1"
                  };
                  inputData.contactNumberphoneNoEmailId3 = {
                      "masterDataName": "phoneOrEmailId",
                      "key": "PHONE",
                      "value": "PHONE"
                  };
                  //inputData.contactNumberphoneNo3=data. ;

                  inputData.contactNumberEmailemailType = {
                      "masterDataName": "emailType",
                      "key": "HOMEEML",
                      "value": "HOMEEML"
                  };
                  inputData.contactNumberEmailphoneNoEmailId = {
                      "masterDataName": "phoneOrEmailId",
                      "key": "EMAIL",
                      "value": "EMAIL"
                  };
                  inputData.employerDataprofessionEmploymentType = {
                      "masterDataName": "employmentType",
                      "key": "MEDIA CELEBRITY",
                      "value": "MEDIA CELEBRITY"
                  };
                  inputData.employerDataemploymentType = {
                      "masterDataName": "employmentType",
                      "key": "MEDIA CELEBRITY",
                      "value": "MEDIA CELEBRITY"
                  };
                  inputData.maillingAdresspreferredAddressType = {
                      "masterDataName": "addressType",
                      "key": "Mailing",
                      "value": "Mailing"
                  };
                  inputData.maillingAdressaddressFormat = {
                      "masterDataName": "addressFormat",
                      "key": "FREE_TEXT_FORMAT",
                      "value": "FREE_TEXT_FORMAT"
                  };
                  inputData.maillingAdressaddrssType = {
                      "masterDataName": "addressType",
                      "key": "Mailing",
                      "value": "Mailing"
                  };
                  inputData.maillingAdressaddrssType = {
                      "masterDataName": "addressType",
                      "key": "Mailing",
                      "value": "Mailing"
                  };
                  inputData.homeAddressaddressFormat = {
                      "masterDataName": "addressFormat",
                      "key": "FREE_TEXT_FORMAT",
                      "value": "FREE_TEXT_FORMAT"
                  };
                  inputData.homeAddressaddrssType = {
                      "masterDataName": "addressType",
                      "key": "Home",
                      "value": "Home"
                  };
                  inputData.homeAddressaddrssType = {
                      "masterDataName": "addressType",
                      "key": "Home",
                      "value": "Home"
                  };
                  inputData.workAddressaddressFormat = {
                      "masterDataName": "addressFormat",
                      "key": "FREE_TEXT_FORMAT",
                      "value": "FREE_TEXT_FORMAT"
                  };
                  inputData.workAddressaddressType = {
                      "masterDataName": "addressType",
                      "key": "Work",
                      "value": "Work"
                  };
                  inputData.workAddressaddressType = {
                      "masterDataName": "addressType",
                      "key": "Work",
                      "value": "Work"
                  };
                  inputData.presentAddressaddressFormat = {
                      "masterDataName": "addressFormat",
                      "key": "FREE_TEXT_FORMAT",
                      "value": "FREE_TEXT_FORMAT"
                  };

                  inputData.presentAddressaddrssType = {
                      "masterDataName": "addressType",
                      "key": "Future/OnSite",
                      "value": "Future/OnSite"
                  };
                  if (data.NIDBLOCK !== undefined) {


                      inputData.nationalIdCardNumberUniqueId = data.NIDBLOCK.referenceNum;
                      inputData.nationalIdCardNo = data.NIDBLOCK.referenceNum;
                      inputData.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = data.NIDBLOCK.isDocumentVerified;
                      let natioanlIdCardissueDateN=data.NIDBLOCK.issueDt
                      if (natioanlIdCardissueDateN && natioanlIdCardissueDateN !== -1) {
                          let date = (natioanlIdCardissueDateN).split('T')[0].split("-");
                          inputData.natioanlIdCardissueDateN = `${date[1]}/${date[2]}/${date[0]}`
                      }
                  }


                  if (data.RSNIDBLOCK !== undefined) {

                       inputData.smartCardNumberUniqueId = data.RSNIDBLOCK.referenceNum;
                    let smartCardissueDateN = data.RSNIDBLOCK.issueDt
                    if (smartCardissueDateN && smartCardissueDateN !== -1) {
                        let date = (smartCardissueDateN).split('T')[0].split("-");
                        inputData.smartIdCardissueDateS = `${date[1]}/${date[2]}/${date[0]}`
                    }
                      inputData.smartIdCardnidVerificationConfirmationIsDocumentVerified = data.RSNIDBLOCK.isDocumentVerified;
                      let smartIdCardissueDateS=data.RSNIDBLOCK.issueDt
                      if (smartIdCardissueDateS && smartIdCardissueDateS !== -1) {
                          let date = (smartIdCardissueDateS).split('T')[0].split("-");
                          inputData.smartIdCardissueDateS = `${date[1]}/${date[2]}/${date[0]}`
                      }
                  }

                  if (data.PASSPORTBLOCK !== undefined) {

                      inputData.passportNumberpassportNumberUniqueId = data.PASSPORTBLOCK.referenceNum;
                      inputData.passportNo = data.PASSPORTBLOCK.referenceNum;
                      inputData.passportNumberplaceOfIssue = data.PASSPORTBLOCK.isDocumentVerified;
                      let passportNumberissueDate=data.PASSPORTBLOCK.issueDt
                      if (passportNumberissueDate && passportNumberissueDate !== -1) {
                          let date = (passportNumberissueDate).split('T')[0].split("-");
                          inputData.passportNumberissueDate = `${date[1]}/${date[2]}/${date[0]}`
                      }
                      inputData.passportNumbercountryOfIssue = data.PASSPORTBLOCK.countryOfIssue;
                  }


                  //inputData.passportNumberexpiryDate=data. ;
                  if (data.BCBLOCK !== undefined) {

                      inputData.birthCertificatebirthCertificateNumberUniqueId = data.BCBLOCK.referenceNum;
                      inputData.birthCertificateNo = data.BCBLOCK.referenceNum;
                  }
                  if (data.chairmanCertificate !== undefined) {

                      inputData.chairmanCertificateUniqueId = data.chairmanCertificate;
                  }
                  // inputData.chairmanCertificateplaceOfBirthFreeText14=data. ;
                  //inputData.chairmanCertificatecountryOfBirthFreeText15=data. ;
                  //  inputData.chairmanCertificatenationality=data. ;
                  // inputData.chairmanCertificatenonResident=data. ;
                  // inputData.chairmanCertificateturnedNonResidentOn=data. ;
                  if (data.ETINBLOCK !== undefined) {

                      inputData.etineTinNumber = data.ETINBLOCK.referenceNum;
                  }
                  if (data.nameOfEmployer !== undefined) {

                      inputData.employerDatanameOfEmployerEmployerName = data.nameOfEmployer;
                  }
                  if (data.COMMEMLBLOCK !== undefined) {
                      //inputData.employerDatamonthlyIncomeGrossIncome=data. ;

                      inputData.contactNumberEmaildesignatedEmailAddressEmailId1 = data.COMMEMLBLOCK.emailInfo
                  }
                  if (data.COMMPH1BLOCK !== undefined) {

                      inputData.contactNumberphoneNo1 = data.COMMPH1BLOCK.phoneNum
                  }
                  if (data.COMMPH2BLOCK !== undefined) {

                      inputData.contactNumberphoneNo2 = data.COMMPH2BLOCK.phoneNum;
                  }
                  if (data.HOMEEMLBLOCK !== undefined) {


                      inputData.contactNumberEmailemailId2 = data.HOMEEMLBLOCK.emailInfo;
                  }
                  if (data.MailingBLOCK !== undefined) {


                      inputData.maillingAdressmailingAddressField1 = data.MailingBLOCK.addrLine1;
                      inputData.maillingAdressmailingAddressField2 = data.MailingBLOCK.addrLine2;
                      inputData.maillingAdresscity = this.findByVarNameGetKeyValue(data.MailingBLOCK.city, this.state.city);
                      inputData.maillingAdressstate = this.findByVarNameGetKeyValue(data.MailingBLOCK.state, this.state.stateCode);
                      inputData.maillingAdresspostalCode = data.MailingBLOCK.postalCode;
                      inputData.maillingAdresscountry = this.findByVarNameGetKeyValue(data.MailingBLOCK.country, this.state.country);

                  }
                  if (data.HomeBLOCK !== undefined) {

                      inputData.homeAddresshomeAddressField1 = data.HomeBLOCK.addrLine1;
                      inputData.homeAddresshomeAddressField2 = data.HomeBLOCK.addrLine2;
                      inputData.homeAddresscity = this.findByVarNameGetKeyValue(data.HomeBLOCK.city, this.state.city);
                      ;
                      inputData.homeAddressstate = this.findByVarNameGetKeyValue(data.HomeBLOCK.state, this.state.stateCode);
                      inputData.homeAddresspostalCode = data.HomeBLOCK.postalCode;
                      inputData.homeAddresscountry = this.findByVarNameGetKeyValue(data.HomeBLOCK.state, this.state.stateCode);
                  }
                  if (data.WorkBLOCK !== undefined) {

                      inputData.workAddressworkAddressField1 = data.WorkBLOCK.addrLine1;
                      inputData.workAddressworkAddressField2 = data.WorkBLOCK.addrLine2;
                      inputData.workAddresscity = this.findByVarNameGetKeyValue(data.WorkBLOCK.city, this.state.city);
                      inputData.workAddressstate = this.findByVarNameGetKeyValue(data.WorkBLOCK.state, this.state.stateCode);
                      inputData.postalCode = data.WorkBLOCK.postalCode;
                      inputData.workAddresscountry = this.findByVarNameGetKeyValue(data.WorkBLOCK.countryCode, this.state.country);

                  }
                  if (data.FutureOnSiteBLOCK !== undefined) {

                    inputData.presentAddresspresentAddressField1 = data.FutureOnSiteBLOCK.addrLine1;
                    inputData.presentAddresspresentAddressField2 = data.FutureOnSiteBLOCK.addrLine2;
                    inputData.presentAddresscity = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.city, this.state.city);
                    inputData.presentAddressstate = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.state, this.state.stateCode);
                    inputData.presentAddresspostalCode = data.FutureOnSiteBLOCK.postalCode
                    inputData.presentAddresscountry = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.country, this.state.country);
                }
                 if (data.NRERelativeBLOCK !== undefined) {

                    inputData.nreAddressnreAddressField1 = data.NRERelativeBLOCK.addrLine1;
                    inputData.nreAddressnreAddressField2 = data.NRERelativeBLOCK.addrLine2;
                    inputData.nreAddresscity = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.city, this.state.city);
                    inputData.nreAddressstate = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.state, this.state.stateCode);
                    inputData.nreAddresspostalCode = data.NRERelativeBLOCK.postalCode
                    inputData.nreAddresscountry = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.country, this.state.country);
                }
              }

          }



          return inputData;
      }*/
    apiDataSet = (data, inputData, accountRelated) => {
        if (data !== undefined && data !== null && data !== "") {

            if (accountRelated === "waiverType") {
                inputData.miswaiverTypeForChequeBookWaiver = this.findByVarNameGetKeyValue(data, this.state.chequebookWaiverType)

            }
            else if (accountRelated === "cbExtraInformation") {
                inputData.mispriorityCenterCodeFreeCode1 = data.freecode1;
                inputData.mispriorityMonitoringRmCodeFreeCode3 = data.freecode3;
                inputData.misprimaryCbHolderForPriorityFreeText8 = data.free_text_8;
                inputData.misrelationshipWithPrimaryCbHolderFreeText9 = data.free_text_9;
                inputData.misbbkSegmentCodeFreeCode7 = this.findByVarNameGetKeyValue(data.freecode7, this.state.getfreecode7);              //
                inputData.mismigratedNidVerificationFreeText2 = data.free_text_2;
                inputData.mismigratedSmartCardNumberFreeText5 = data.free_text_5;
                inputData.mispepStatusFreeText10 = data.free_text_11;
                inputData.mismigratedCifsDocumentNoFreeText13 = data.free_text_13;
                inputData.misavailedTradeServices = this.findByVarNameGetKeyValue(data.trade_services_availed, this.state.tradeServiceList);                   //
                inputData.primryintroducerCustomerIdCifId = data.intrd_cif_id;
                inputData.primryicifTypePrimaryIntroducerDetails = data.intrd_cif_type;
                inputData.chairmanCertificateplaceOfBirthFreeText14 = data.free_text_14;
                inputData.chairmanCertificatecountryOfBirthFreeText15 = data.free_text_15;
                inputData.employerDatamonthlyIncomeGrossIncome = data.gross_income;
                inputData.chairmanCertificatenonResident = this.findByVarNameGetKeyValue(data.customer_nre_flg, this.state.getnonResident);  //
                inputData.chairmanCertificateturnedNonResidentOn = data.date_of_becoming_nre
            } else {

                console.log("..........acccc........" + accountRelated);


                //inputData.aof1currencyCodeCcyExist=true
                inputData.miscustomerIdCifId = this.props.cbNumber;
                inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = false;
                inputData.passportNumberpassportNumberUniqueIdApiCall = true;
                inputData.nationalIdCardNumberUniqueIdApiCall = true;
                inputData.birthCertificatebirthCertificateNumberUniqueIdApiCall = true;
                inputData.electronicTaxIdApiCall = true;
                if (accountRelated !== "New Customer") {
                    inputData.misoccupationCodePriorityCodeStaff = data.occupationCode;
                    inputData.missolId = data.branchCode;
                    inputData.basicInformationUpdateprimarySolId = data.branchCode;
                }


                inputData.misstaffIdNumber = data.staffEmployeeID;
                inputData.misstaffIndicatorStatus = data.staffFlag;
                inputData.misccepCodeFreeCode9 = this.findByVarNameGetKeyValue(data.ccepcode, this.state.ccepCodeList);
                inputData.misprimarySolId = data.branchCode;

                inputData.miscustomerNameLastName = data.customerName;
                if (accountRelated !== "New Customer") {
                    inputData.misshortName = data.shortName
                }

                inputData.misaCNumber = data.accountNumber;
                inputData.customerAccount = data.accountNumber;
                inputData.crmMissubSegment = this.findByVarNameGetKeyValue(data.subSegment, this.state.subSegmentList);
                inputData.mistitle = this.findByVarNameGetKeyValue(data.salutation, this.state.titleList);
                inputData.aCTitle = data.salutation;
                inputData.miscustomerStatusNormalStaffPriorityEtc = this.findByVarNameGetKeyValue(data.custStatus, this.state.customerStatusList);
                inputData.misconstitution = this.findByVarNameGetKeyValue(data.constitutionCode, this.state.constitutionList);
                inputData.mispriorityMonitoringRmCodeFreeCode3 = data.monitoringRMCode;
                inputData.misfunctionCodeForChequeBookWaiver = "A";
                inputData.aof1currencyCodeCcy = data.currency;
                if (accountRelated !== "New Customer") {
                    inputData.misoccupationCodePriorityCodeStaff = data.occupationCode
                }
                inputData.aof1smsAlertFreeText11 = this.findByVarNameGetKeyValue(data.currencyCode, this.state.currency);
                inputData.schemeCodeChangefunctionForSchemeChangeInCaseOfInstaPack = "T";
                inputData.primryiintroducerStatus = this.findByVarNameGetKeyValue(data.statusOfIntroducer, this.state.introducerStatus);
                inputData.primryiintroducerNameLastName = data.nameOfIntroducer;
                inputData.basicInformationUpdategender = data.gender;
                inputData.basicInformationUpdatefatherNamePrefferedName = data.fatherName;
                inputData.basicInformationUpdatemotherNameMothersMaidenName = data.motherName;
                inputData.basicInformationUpdatemotherNameMothersMaidenName = data.motherName;
                inputData.basicInformationUpdatemaritalStatus = this.findByVarNameGetKeyValue(data.maritalStatus, this.state.maritalStatusList);
                inputData.basicInformationUpdatespouseNamePurgeRemarks = data.spouseName;
                let birthDate = data.birthDate;
                if (birthDate && birthDate !== -1) {
                    let date = (birthDate).split('T')[0].split("-");
                    inputData.basicInformationUpdatedob = `${date[1]}/${date[2]}/${date[0]}`;
                    setTimeout(() => {
                        let x = 18;
                        var currentDate = new Date();

                        let someDate = new Date();
                        let numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        let date = someDate.getDate();
                        let month = someDate.getMonth() + 1;
                        let year = someDate.getFullYear();
                        let convertDate = year + "-" + month + "-" + date;
                        let newDate = new Date(convertDate);
                        let stringToDate = new Date(newDate.toDateString());

                        let inputDate = new Date(birthDate);

                        if (stringToDate >= inputDate) {
                            inputData.basicInformationUpdatedobApiCall = "YES";
                            inputData.basicInformationUpdateminorStatusMinorIndicator = "N"

                            //varValue.basicInformationUpdateminorStatusMinorIndicator = "Y"
                        } else {
                            inputData.basicInformationUpdatedobApiCall = "YES";
                            inputData.basicInformationUpdateminorStatusMinorIndicator = "Y"
                            //this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
                        }
                        x = 60;
                        currentDate = new Date();
                        someDate = new Date();
                        numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        date = someDate.getDate();
                        month = someDate.getMonth() + 1;
                        year = someDate.getFullYear();
                        convertDate = year + "-" + month + "-" + date;
                        newDate = new Date(convertDate);
                        stringToDate = new Date(newDate.toDateString());

                        inputDate = new Date(birthDate);
                        if (stringToDate >= inputDate) {
                            inputData.basicInformationUpdateseniorCitizenIndicator = "Y"


                        } else {

                            inputData.basicInformationUpdateseniorCitizenIndicator = "N"
                        }
                    }, 1000)
                }
                inputData.basicInformationUpdateminorStatusMinorIndicator = data.isMinor;
                inputData.basicInformationUpdateseniorCitizenIndicator = data.seniorCitizen;
                inputData.fincoreDataUpdatefreeText10 = data.waiverfield;
                if (data.GUARDIANBLOCK !== undefined) {
                    inputData.guardianDetailsrelation = this.findByVarNameGetKeyValue(data.GUARDIANBLOCK.relationshipCategory, this.state.relationshipList);
                    inputData.guardianDetailsbankRelationType = this.findByVarNameGetKeyValue(data.GUARDIANBLOCK.parentEntity, this.state.bankRelationTypeList);
                    inputData.guardianDetailsrelation1 = this.findByVarNameGetKeyValue(data.GUARDIANBLOCK.relationShip, this.state.customerStatusList);
                    inputData.guardianDetailsguardianSelectionGurdian = this.findByVarNameGetKeyValue(data.GUARDIANBLOCK.guardCode, this.state.getGuardianCode);
                    // inputData.guardianDetailsgurdianCifNumberCustomerApiCall = "YES";
                    inputData.guardianDetailsgurdianCifNumberCustomer = data.GUARDIANBLOCK.parentCIFID;
                    //inputData.guardianDetailslastName = data.GUARDIANBLOCK.parentCIFID;
                }


                //delete flag start
                inputData.contactNumberEmaildesignatedEmailAddressEmailId1Delete = "N";

                inputData.natioanlIdCardDelete = "N";
                inputData.smartIdCardDelete = "N";
                inputData.passportNumberDelete = "N";
                inputData.drivingLicenseNumberDelete = "N";
                inputData.residentNumberDelete = "N";
                inputData.birthCertificateDelete = "N";
                inputData.chairmanCertificateDelete = "N";
                inputData.photoIdDelete = "N";
                inputData.etinDelete = "N";
                inputData.contactNumber2Delete = "N";
                inputData.contactNumber3Delete = "N";
                inputData.contactNumberEmailemailId2Delete = "N";

                inputData.homeAddressDelete = "N";
                inputData.workAddressDelete = "N";
                inputData.nreAddressDelete = "N";
                inputData.presentAddressDelete = "N";
                //delete flag End

                inputData.introducerIdApiCall = true;
                inputData.drivingLicensedocumentTypeN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.drivingLicensedocumentTypeDescriptionN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.residentdocumentTypeN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.residentdocumentTypeDescriptionN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.natioanlIdCarddocumentTypeN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.natioanlIdCarddocumentTypeDescriptionN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                //Passport
                inputData.passportNumberdocumentTypeP = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.passportNumberdocumentTypeDescriptionP = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };


                inputData.smartIdCarddocumentTypeS = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.smartIdCarddocumentTypeDescriptionS = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };


                inputData.birthCertificatedocumentTypeB = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.birthCertificatedocumentTypeDescriptionB = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.chairmanCertificatedocumentTypeCC = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.chairmanCertificatedocumentTypeDescriptionCC = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.etindocumentType = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.etindocumentTypeDescription = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.contactNumberEmailpreferedEmailIdType = {
                    "masterDataName": "emailType",
                    "key": "COMMEML",
                    "value": "COMMEML"
                };
                inputData.contactNumberEmailemailType1 = {
                    "masterDataName": "emailType",
                    "key": "COMMEML",
                    "value": "COMMEML"
                };
                inputData.contactNumberEmailphoneNoEmailId4 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "EMAIL",
                    "value": "EMAIL"
                };
                inputData.contactNumberpreferredContactNoType1 = {
                    "masterDataName": "contactType",
                    "key": "COMMPH1",
                    "value": "COMMPH1"
                };

                inputData.contactNumberphoneNoEmailId1 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "PHONE",
                    "value": "PHONE"
                };

                inputData.contactNumberphoneNoEmailId2 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "PHONE",
                    "value": "PHONE"
                };

                inputData.contactNumberphoneNoEmailId3 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "PHONE",
                    "value": "PHONE"
                };
                //inputData.contactNumberphoneNo3=data. ;

                inputData.contactNumberEmailemailType = {
                    "masterDataName": "emailType",
                    "key": "HOMEEML",
                    "value": "HOMEEML"
                };
                inputData.contactNumberEmailphoneNoEmailId = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "EMAIL",
                    "value": "EMAIL"
                };
                /*  inputData.employerDataprofessionEmploymentType = {
                      "masterDataName": "employmentType",
                      "key": "MEDIA CELEBRITY",
                      "value": "MEDIA CELEBRITY"
                  };
                  inputData.employerDataemploymentType = {
                      "masterDataName": "employmentType",
                      "key": "MEDIA CELEBRITY",
                      "value": "MEDIA CELEBRITY"
                  };*/
                inputData.maillingAdresspreferredAddressType = {
                    "masterDataName": "addressType",
                    "key": "Mailing",
                    "value": "Mailing"
                };
                inputData.maillingAdressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };
                inputData.maillingAdressaddrssType = {
                    "masterDataName": "addressType",
                    "key": "Mailing",
                    "value": "Mailing"
                };
                inputData.maillingAdressaddrssType = {
                    "masterDataName": "addressType",
                    "key": "Mailing",
                    "value": "Mailing"
                };
                inputData.homeAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };

                inputData.workAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };


                inputData.nreAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };
                inputData.nreAddressaddrssType =
                    {
                        "masterDataName": "addressType",
                        "key": "NRERelative",
                        "value": "NRERelative"
                    };
                inputData.presentAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };

                inputData.Customersnationality = this.findByVarNameGetKeyValue(data.nationality, this.state.country);


                if (data.NIDBLOCK !== undefined) {
                    inputData.natioanlIdCarddocumentCodeN = {
                        "masterDataName": "documentCode",
                        "key": "NID",
                        "value": "NID CARD IDENTITY"
                    };
                    inputData.nationalIdCardNumberUniqueId = data.NIDBLOCK.referenceNum;
                    inputData.nationalIdCardNo = data.NIDBLOCK.referenceNum;
                    inputData.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = data.NIDBLOCK.isDocumentVerified;
                    inputData.nationalIdCardNo = data.NIDBLOCK.referenceNum;
                    let natioanlIdCardissueDateN = data.NIDBLOCK.issueDt;
                    if (natioanlIdCardissueDateN && natioanlIdCardissueDateN !== -1) {
                        let date = (natioanlIdCardissueDateN).split('T')[0].split("-");
                        inputData.natioanlIdCardissueDateN = `${date[1]}/${date[2]}/${date[0]}`
                    }
                } else {
                    inputData.natioanlIdCarddocumentCodeN = null;
                }

                if (data.RSNIDBLOCK !== undefined) {
                    inputData.smartIdCarddocumentCodeS = {
                        "masterDataName": "documentCode",
                        "key": "RSNID",
                        "value": "RSNID"
                    };
                    inputData.smartCardNumberUniqueId = data.RSNIDBLOCK.referenceNum;
                    let smartCardissueDateN = data.RSNIDBLOCK.issueDt;
                    if (smartCardissueDateN && smartCardissueDateN !== -1) {
                        let date = (smartCardissueDateN).split('T')[0].split("-");
                        inputData.smartIdCardissueDateS = `${date[1]}/${date[2]}/${date[0]}`
                    }
                } else {
                    inputData.smartIdCarddocumentCodeS = null;
                }

                if (data.RESIDBLOCK !== undefined) {
                    inputData.residentdocumentCodeN = {
                        "masterDataName": "documentCode",
                        "key": "RESID",
                        "value": "RESIDENT ID CARD"
                    };
                    inputData.residentNumberUniqueId = data.RESIDBLOCK.referenceNum;
                } else {
                    inputData.residentdocumentCodeN = null;
                }

                if (data.DLBLOCK !== undefined) {
                    inputData.drivingLicensedocumentCodeN = {
                        "masterDataName": "documentCode",
                        "key": "DL",
                        "value": "DRIVING LICENSE"

                    };
                    inputData.drivingLicenseNumberUniqueId = data.DLBLOCK.referenceNum;
                } else {
                    inputData.drivingLicensedocumentCodeN = null;
                }

                if (data.FutureOnSiteBLOCK !== undefined) {
                    inputData.presentAddressaddrssType = {
                        "masterDataName": "addressType",
                        "key": "Future/OnSite",
                        "value": "Future/OnSite"
                    };
                    inputData.presentAddresspresentAddressField1 = data.FutureOnSiteBLOCK.addrLine1;
                    inputData.presentAddresspresentAddressField2 = data.FutureOnSiteBLOCK.addrLine2;
                    inputData.presentAddresscity = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.city, this.state.city);
                    inputData.presentAddressstate = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.state, this.state.stateCode);
                    inputData.presentAddresspostalCode = data.FutureOnSiteBLOCK.postalCode;
                    inputData.presentAddresscountry = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.country, this.state.country);
                } else {
                    inputData.presentAddressaddrssType = null
                }
                if (data.NRERelativeBLOCK !== undefined) {

                    inputData.nreAddressnreAddressField1 = data.NRERelativeBLOCK.addrLine1;
                    inputData.nreAddressnreAddressField2 = data.NRERelativeBLOCK.addrLine2;
                    inputData.nreAddresscity = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.city, this.state.city);
                    inputData.nreAddressstate = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.state, this.state.stateCode);
                    inputData.nreAddresspostalCode = data.NRERelativeBLOCK.postalCode;
                    inputData.nreAddresscountry = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.country, this.state.country);
                }

                if (data.PASSPORTBLOCK !== undefined) {
                    inputData.passportNumberdocumentCodeP = {
                        "masterDataName": "documentCode",
                        "key": "PASSPORT",
                        "value": "PASSPORT IDENTITY"
                    };
                    inputData.passportNumberpassportNumberUniqueId = data.PASSPORTBLOCK.referenceNum;
                    inputData.passportNo = data.PASSPORTBLOCK.referenceNum;
                    inputData.passportNo = data.PASSPORTBLOCK.referenceNum;
                    inputData.passportNumberplaceOfIssue = this.findByVarNameGetKeyValue(data.PASSPORTBLOCK.placeOfIssue, this.state.placeOfIssueList);
                    let passportNumberissueDate = data.PASSPORTBLOCK.issueDt;
                    if (passportNumberissueDate && passportNumberissueDate !== -1) {
                        let date = (passportNumberissueDate).split('T')[0].split("-");
                        inputData.passportNumberissueDate = `${date[1]}/${date[2]}/${date[0]}`
                    }
                    inputData.passportNumbercountryOfIssue = this.findByVarNameGetKeyValue(data.PASSPORTBLOCK.countryOfIssue, this.state.country);
                    inputData.passportNumberexpiryDate = data.PASSPORTBLOCK.expireDt;
                } else {
                    inputData.passportNumberdocumentCodeP = null
                }

                //inputData.passportNumberexpiryDate=data. ;
                if (data.BCBLOCK !== undefined) {
                    inputData.birthCertificatedocumentCodeB = {
                        "masterDataName": "documentCode",
                        "key": "BC",
                        "value": "BIRTH CERTIFICATE"
                    };
                    inputData.birthCertificatebirthCertificateNumberUniqueId = data.BCBLOCK.referenceNum;
                    inputData.birthCertificateNo = data.BCBLOCK.referenceNum;
                    inputData.birthCertificateNo = data.BCBLOCK.referenceNum;
                } else {
                    inputData.birthCertificatedocumentCodeB = null;
                }

                if (data.chairmanCertificate !== undefined) {
                    inputData.chairmanCertificatedocumentCodeCC = {
                        "masterDataName": "documentCode",
                        "key": "CHAC",
                        "value": "CHAIRMAN CERTIFICATE"
                    };

                    inputData.chairmanCertificateUniqueId = data.chairmanCertificate;
                } else {
                    inputData.chairmanCertificatedocumentCodeCC = null;
                }


                if (data.ETINBLOCK !== undefined) {
                    inputData.etindocumentCode = {
                        "masterDataName": "documentCode",
                        "key": "ETIN",
                        "value": "TIN CARD IDENTITY"
                    };
                    inputData.etineTinNumber = data.ETINBLOCK.referenceNum;
                    inputData.electronicTaxId = data.ETINBLOCK.referenceNum;
                } else {
                    inputData.etindocumentCode = null
                }


                if (data.HomeBLOCK !== undefined) {
                    inputData.homeAddressaddrssType = {
                        "masterDataName": "addressType",
                        "key": "Home",
                        "value": "Home"
                    };
                    inputData.homeAddresshomeAddressField1 = data.HomeBLOCK.addrLine1;
                    inputData.homeAddresshomeAddressField2 = data.HomeBLOCK.addrLine2;
                    inputData.homeAddresscity = this.findByVarNameGetKeyValue(data.HomeBLOCK.city, this.state.city);

                    inputData.homeAddressstate = this.findByVarNameGetKeyValue(data.HomeBLOCK.state, this.state.stateCode);
                    inputData.homeAddresspostalCode = data.HomeBLOCK.postalCode;
                    inputData.homeAddresscountry = this.findByVarNameGetKeyValue(data.HomeBLOCK.country, this.state.country);
                } else {
                    inputData.homeAddressaddrssType = null
                }

                if (data.WorkBLOCK !== undefined) {
                    inputData.workAddressaddressType = {
                        "masterDataName": "addressType",
                        "key": "Work",
                        "value": "Work"
                    };
                    inputData.workAddressworkAddressField1 = data.WorkBLOCK.addrLine1;
                    inputData.workAddressworkAddressField2 = data.WorkBLOCK.addrLine2;
                    inputData.workAddresscity = this.findByVarNameGetKeyValue(data.WorkBLOCK.city, this.state.city);
                    inputData.workAddressstate = this.findByVarNameGetKeyValue(data.WorkBLOCK.state, this.state.stateCode);
                    inputData.workAddresspostalCode = data.WorkBLOCK.postalCode;
                    inputData.workAddresscountry = this.findByVarNameGetKeyValue(data.WorkBLOCK.countryCode, this.state.country);
                } else {
                    inputData.workAddressaddressType = null
                }

                if (data.COMMPH1BLOCK !== undefined) {
                    inputData.contactNumbercontactType1 = {
                        "masterDataName": "contactType",
                        "key": "COMMPH1",
                        "value": "COMMPH1"
                    };
                    inputData.contactNumberphoneNo1 = data.COMMPH1BLOCK.phoneNumLocalCode;
                    if (data.COMMPH1BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypecontactNumberphoneNo1 = "Local";
                    } else {
                        inputData.mobileTypecontactNumberphoneNo1 = "Overseas";
                        inputData.countryCodecontactNumberphoneNo1 = this.findByVarNameGetKeyValue(data.COMMPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)
                    }
                } else {
                    inputData.contactNumbercontactType1 = null;
                }

                if (data.WORKPH1BLOCK !== undefined) {
                    inputData.contactNumbercontactType3 = {
                        "masterDataName": "contactType",
                        "key": "WORKPH1",
                        "value": "WORKPH1"
                    };
                    inputData.contactNumberphoneNo3 = data.WORKPH1BLOCK.phoneNumLocalCode;
                    if (data.WORKPH1BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypecontactNumbercontactType3 = "Local";
                    } else {
                        inputData.mobileTypecontactNumbercontactType3 = "Overseas";
                        inputData.countryCodecontactNumberphoneNo3 = this.findByVarNameGetKeyValue(data.WORKPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)

                    }
                } else {
                    inputData.contactNumbercontactType3 = null;
                }

                if (data.COMMPH2BLOCK !== undefined) {
                    inputData.contactNumbercontactType2 = {
                        "masterDataName": "contactType",
                        "key": "COMMPH2",
                        "value": "COMMPH2"
                    };
                    inputData.contactNumberphoneNo2 = data.COMMPH2BLOCK.phoneNumLocalCode;

                    if (data.COMMPH2BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypecontactNumberphoneNo2 = "Local";
                    } else {
                        inputData.mobileTypecontactNumberphoneNo2 = "Overseas";
                        inputData.countryCodecontactNumberphoneNo2 = this.findByVarNameGetKeyValue(data.COMMPH2BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)

                    }
                } else {
                    inputData.contactNumbercontactType2 = null;
                }
                if (data.HOMEEMLBLOCK !== undefined) {


                    inputData.contactNumberEmailemailId2 = data.HOMEEMLBLOCK.emailInfo;
                }
                if (data.MailingBLOCK !== undefined) {


                    inputData.maillingAdressmailingAddressField1 = data.MailingBLOCK.addrLine1;
                    inputData.maillingAdressmailingAddressField2 = data.MailingBLOCK.addrLine2;
                    inputData.maillingAdresscity = this.findByVarNameGetKeyValue(data.MailingBLOCK.city, this.state.city);
                    inputData.maillingAdressstate = this.findByVarNameGetKeyValue(data.MailingBLOCK.state, this.state.stateCode);
                    inputData.maillingAdresspostalCode = data.MailingBLOCK.postalCode;
                    inputData.maillingAdresscountry = this.findByVarNameGetKeyValue(data.MailingBLOCK.country, this.state.country);

                }

                if (data.nameOfEmployer !== undefined) {
                    inputData.employerDataprofessionEmploymentType = this.findByVarNameGetKeyValue(data.employmentStatus, this.state.employmentTypeList);

                    inputData.employerDatanameOfEmployerEmployerName = data.nameOfEmployer;
                }
                if (data.COMMEMLBLOCK !== undefined) {
                    //inputData.employerDatamonthlyIncomeGrossIncome=data. ;

                    inputData.contactNumberEmaildesignatedEmailAddressEmailId1 = data.COMMEMLBLOCK.emailInfo
                    /* if(data.COMMEMLBLOCK.phoneNumCountryCode==="88"){
                         inputData.mobileTypecontactNumberphoneNo2 ="88";
                     }
                     else{
                         inputData.mobileTypecontactNumberphoneNo2 ="0";
                     }*/
                }


            }
        }


        return inputData;
    };
    dynamicApiDataSet= (apiType,fieldName,responseData) => {
        if (apiType === "city") {
            this.findByVarNameApiData("maillingAdresscity", responseData);
            this.findByVarNameApiData("homeAddresscity", responseData);
            this.findByVarNameApiData("workAddresscity", responseData);
            this.findByVarNameApiData("presentAddresscity", responseData);
            this.findByVarNameApiData("nreAddresscity", responseData);
            this.findByVarNameApiData("mailingAddresscity1", responseData);
            this.findByVarNameApiData("homeAddresscity1", responseData);
            this.findByVarNameApiData("workAddresscity1", responseData);
            this.findByVarNameApiData("workAddresscity2", responseData);


            this.setState({
                city: responseData,
                getCity: true
            })

        } else if (apiType === "state") {

            this.findByVarNameApiData("maillingAdressstate", responseData);
            this.findByVarNameApiData("homeAddressstate", responseData);
            this.findByVarNameApiData("workAddressstate", responseData);
            this.findByVarNameApiData("presentAddressstate", responseData);
            this.findByVarNameApiData("nreAddressstate", responseData);
            this.findByVarNameApiData("mailingAddressstate1", responseData);
            this.findByVarNameApiData("homeAddressstate1", responseData);
            this.findByVarNameApiData("workAddressstate1", responseData);
            this.findByVarNameApiData("workAddressstate2", responseData);

            this.setState({
                stateCode: responseData,
                getState: true,
            })
        } else if (apiType === "country") {
            this.findByVarNameApiData("passportNumbercountryOfIssue", responseData);
            this.findByVarNameApiData("maillingAdresscountry", responseData);
            this.findByVarNameApiData("homeAddresscountry", responseData);
            this.findByVarNameApiData("workAddresscountry", responseData);
            this.findByVarNameApiData("Customersnationality", responseData);
            this.findByVarNameApiData("presentAddresscountry", responseData);
            this.findByVarNameApiData("nreAddresscountry", responseData);
            this.findByVarNameApiData("mailingAddresscountry1", responseData);
            this.findByVarNameApiData("homeAddresscountry1", responseData);
            this.findByVarNameApiData("workAddresscountry1", responseData);
            this.findByVarNameApiData("workAddresscountry2", responseData);
            // this.findByVarNameApiData("passportNumberplaceOfIssue", responseData)


            this.setState({
                country: responseData,
                getCountry: true
            })
        } else if (apiType === "currency") {

            //this.findByVarNameApiData("workAddressCurrency", responseData)
            //  this.findByVarNameApiData("homeAddressCurrency", responseData)
            this.findByVarNameApiData("aof1currencyCodeCcy", responseData);

            this.setState({
                currency: responseData,
                getCurrency: true
            })
        } else if (apiType === "phoneCountryCode") {

            this.findByVarNameApiData("countryCodecontactNumberphoneNo1", responseData);
            this.findByVarNameApiData("countryCodecontactNumberphoneNo2", responseData);
            this.findByVarNameApiData("countryCodecontactNumberphoneNo3", responseData);
            this.setState({
                phoneNumCountryCodeList: responseData
            })
        } else if (apiType === "customerStatus") {
            this.findByVarNameApiData("miscustomerStatusNormalStaffPriorityEtc", responseData);
            this.setState({
                customerStatusList: responseData
            })
        } else if (apiType === "nomineeRelationCode") {
            this.findByVarNameApiData("guardianDetailsrelation1", responseData);
            this.setState({
                customerStatusList: responseData
            })
        } else if (apiType === "savingsSchemeCode") {

            this.setState({
                savingsSchemeCodeList: responseData,
            })
        } else if (apiType === "placeOfIssue") {
            this.findByVarNameApiData("passportNumberplaceOfIssue", responseData);
            this.setState({
                placeOfIssueList: responseData
            })

        } else if (apiType === "currentSchemeCode") {

            this.setState({
                currentSchemeCodeList: responseData,
            })
        } else if (apiType === "sourceOfFund") {
            this.findByVarNameApiData("sourceOfFund", responseData);
            this.findByVarNameApiData("documentsCollectedToEnsureTheSourceOfFund", responseData)

        } else if (apiType === "constitution") {
            this.findByVarNameApiData("misconstitution", responseData);
            this.setState({
                constitutionList: responseData
            })

        } else if (apiType === "monthlyIncome") {
            this.findByVarNameApiData("customerMonthlyIncome", responseData)


        } else if (apiType === "accountOpen") {
            this.findByVarNameApiData("howWasAccountOpened", responseData)


        } else if (apiType === "designationCode") {
            this.findByVarNameApiData("relatedCbTaggingdesignationCode", responseData)

        } else if (apiType === "tradeService") {
            this.findByVarNameApiData("misavailedTradeServices", responseData);
            this.setState({
                tradeServiceList: responseData
            })

        } else if (apiType === "modOfOperation") {
            this.findByVarNameApiData("schemeCodeChangemodeOfOperation", responseData);
            this.setState({
                despatchmodeList: responseData
            })

        } else if (apiType === "introducerStatus") {
            this.findByVarNameApiData("primryiintroducerStatus", responseData);
            this.setState({
                introducerStatus: responseData
            })
        } else if (apiType === "bbkSegmentCode") {
            this.findByVarNameApiData("misbbkSegmentCodeFreeCode7", responseData);
            this.setState({
                getfreecode7: responseData
            })

        } else if (apiType === "priorityCenterCode") {
            //this.findByVarNameApiData("mispriorityCenterCodeFreeCode1", responseData)
        } else if (apiType === "contactType") {
            //this.findByVarNameApiData("contactNumbercontactType1", responseData)
            // this.findByVarNameApiData("contactNumbercontactType2", responseData)
            //this.findByVarNameApiData("contactNumbercontactType3", responseData)
            // this.findByVarNameApiData("contactNumberpreferredContactNoType1", responseData)

        } else if (apiType === "addressType") {
            //this.findByVarNameApiData("workAddressaddressType", responseData)
            // this.findByVarNameApiData("maillingAdressaddrssType", responseData)
            //this.findByVarNameApiData("maillingAdresspreferredAddressType", responseData)
            // this.findByVarNameApiData("homeAddressaddrssType", responseData)
            // this.findByVarNameApiData("presentAddressaddrssType", responseData)
            //this.findByVarNameApiData("nreAddressaddrssType", responseData)

        } else if (apiType === "guardianCode") {
            this.findByVarNameApiData("guardianDetailsguardianSelectionGurdian", responseData);


            this.setState({
                getGuardianCode: responseData
            })

        } else if (apiType === "accountStatement") {
            //this.findByVarNameApiData("fincoreDataUpdateaccountStatement", responseData)
            //  this.findByVarNameApiData("fincoreDataUpdateaccountStatement", responseData)
        } else if (apiType === "subSegment") {
            this.findByVarNameApiData("crmMissubSegment", responseData);
            this.setState({
                subSegmentList: responseData
            })

        } else if (apiType === "subSectorCode") {
            this.findByVarNameApiData("fincoreDataUpdatesubSectorCode", responseData);
            this.setState({
                subsectorCodeList: responseData
            })
        } else if (apiType === "sectorCode") {
            this.findByVarNameApiData("fincoreDataUpdatesectorCode", responseData);
            this.setState({
                sectorCodeList: responseData
            })
        } else if (apiType === "depositCode") {
            this.findByVarNameApiData("fincoreDataUpdatedepositCodeFreeCode7", responseData);
            this.setState({
                depositCodeList: responseData
            })
        } else if (apiType === "dispatchMode") {
            this.findByVarNameApiData("aof1despatchModePrintedEStatementBoth", responseData);
            this.findByVarNameApiData("fincoreDataUpdatedispatchMode", responseData);
            console.log("llllllllllll");
            console.log(responseData);
            this.setState({
                dispatchMode: responseData
            })
        } else if (apiType === "rmCode") {
            this.findByVarNameApiData("mispriorityMonitoringRmCodeFreeCode3", responseData);
            this.setState({
                rmCodeList: responseData
            })

        } else if (apiType === "accountStatement") {
            this.findByVarNameApiData("aof1statement", responseData)
            //this.findByVarNameApiData("fincoreDataUpdateaccountStatement", responseData)
        } else if (apiType === "statementFrequency") {
            this.findByVarNameApiData("fincoreDataUpdatestatementFrequency", responseData);
            this.findByVarNameApiData("aof1statement", responseData)
        } else if (apiType === "chequebookWaiverFunctionCode") {
            // this.findByVarNameApiData("misfunctionCodeForChequeBookWaiver", responseData)
            // this.findByVarNameApiData("schemeCodeChangefunctionForSchemeChangeInCaseOfInstaPack", responseData)
        } else if (apiType === "chequebookWaiverType") {
            this.findByVarNameApiData("miswaiverTypeForChequeBookWaiver", responseData);
            this.setState({
                chequebookWaiverType: responseData
            })
        } else if (apiType === "branch") {
            this.findByVarNameApiData("otherBankbranchName", responseData)

        } else if (apiType === "relationCode") {
            this.findByVarNameApiData("relatedCbTaggingrelationCode", responseData);
            this.setState({
                relationCodeList: responseData
            })
        } else if (apiType === "addressFormat") {
            this.findByVarNameApiData("maillingAdressaddressFormat", responseData);
            this.findByVarNameApiData("homeAddressaddressFormat", responseData);
            this.findByVarNameApiData("workAddressaddressFormat", responseData);
            this.findByVarNameApiData("presentAddressaddressFormat", responseData);
            this.findByVarNameApiData("mailingAddressaddressFormat1", responseData);
            this.findByVarNameApiData("homeAddressaddressFormat1", responseData);
            this.findByVarNameApiData("workAddressaddressFormat1", responseData);
            this.findByVarNameApiData("workAddressaddressFormat2", responseData)

        } else if (apiType === "agentBankingOutlet") {
            this.findByVarNameApiData("misagentBankingOutletFreeCode1", responseData);
            this.setState({
                agentBankingOutletList: responseData
            })
        } else if (apiType === "waiverField") {
            this.findByVarNameApiData("miswaiverFieldFreeCode10", responseData);
            this.setState({
                waiverFieldList: responseData
            })
            // this.findByVarNameApiData("misdebitCardIssueFreeCode10", responseData)

        } else if (apiType === "ccepCode") {
            this.findByVarNameApiData("misccepCodeFreeCode9", responseData);
            this.setState({
                ccepCodeList: responseData
            })

        } else if (apiType === "nonResident") {
            this.findByVarNameApiData("chairmanCertificatenonResident", responseData);
            this.setState({
                getnonResident: responseData
            })

        } else if (apiType === "agentBankingOutlet") {
            this.findByVarNameApiData("misagentBankingOutletFreeCode1", responseData);
            this.setState({
                agentBankingOutletList: responseData
            })
        } else if (apiType === "maritalStatus") {
            this.findByVarNameApiData("basicInformationUpdatemaritalStatus", responseData);
            this.findByVarNameApiData("basicInformationmaritalStatus", responseData);
            this.setState({
                maritalStatusList: responseData
            })

        } else if (apiType === "employmentType") {
            this.findByVarNameApiData("employerDataprofessionEmploymentType", responseData);
            this.findByVarNameApiData("etinprofessionEmploymentType", responseData);
            this.findByVarNameApiData("employerDataemploymentType", responseData);
            this.setState({
                employmentTypeList: responseData
            })
        } else if (apiType === "relationship") {
            this.findByVarNameApiData("guardianDetailsrelation", responseData);

            this.setState({
                relationshipList: responseData
            })
        } else if (apiType === "relationType") {
            this.findByVarNameApiData("relatedCbTaggingrelationType", responseData)

        } else if (apiType === "contactType") {
            // this.findByVarNameApiData("contactNumberpreferredContactNoType1", responseData)
            /*  this.findByVarNameApiData("contactNumbercontactType1", responseData)
              this.findByVarNameApiData("contactNumbercontactType", responseData)
              this.findByVarNameApiData("contactNumbercontactType2", responseData)
              this.findByVarNameApiData("contactNumbercontactType3", responseData)
              this.findByVarNameApiData("contactNumberpreferredContactNoType1", responseData)
              this.findByVarNameApiData("contactNumberpreferredContactNoType1", responseData)*/


        } else if (apiType === "title") {
            this.findByVarNameApiData("mistitle", responseData);
            this.findByVarNameApiData("relatedCbTaggingtitle", responseData);
            this.findByVarNameApiData("aCTitle", responseData);
            this.setState({
                titleList: responseData
            })


        } else if (apiType === "documentCode") {
            this.findByVarNameApiData("photoIddocumentCode", responseData)

        } else if (apiType === "bankName") {
            this.findByVarNameApiData("otherBankbankName", responseData)


        } else if (apiType === "phoneOrEmailId") {
            this.findByVarNameApiData("contactNumberphoneNoEmailId1", responseData);
            this.findByVarNameApiData("contactNumberphoneNoEmailId2", responseData);
            this.findByVarNameApiData("contactNumberphoneNoEmailId3", responseData);
            this.findByVarNameApiData("contactNumberEmailphoneNoEmailId4", responseData);
            this.findByVarNameApiData("contactNumberEmailphoneNoEmailId1", responseData);
            this.findByVarNameApiData("contactNumberEmailphoneNoEmailId2", responseData);
            this.findByVarNameApiData("contactNumberEmailphoneNoEmailId", responseData);
            this.setState({
                phoneOrEmail: responseData
            })
        } else if (apiType === "customerOccupation") {

            this.findByVarNameApiData("customersOccupation", responseData)

        } else if (apiType === "bankRelationType") {

            this.findByVarNameApiData("guardianDetailsbankRelationType", responseData);
            this.setState({
                bankRelationTypeList: responseData
            })

        } else if (apiType === "emailType") {

            this.findByVarNameApiData("contactNumberEmailemailType1", responseData);
            this.findByVarNameApiData("contactNumberEmailemailType2", responseData);
            this.findByVarNameApiData("contactNumberEmailemailType", responseData);
            this.findByVarNameApiData("contactNumberEmailpreferedEmailIdType", responseData);
            this.setState({
                phoneEmailType: responseData
            })
        } else if (apiType === "addressVariety") {

            this.findByVarNameApiData("howTheAddressOfTheAccountHolderHasBeenVerified", responseData)

        } else if (apiType === "customerBusiness") {

            this.findByVarNameApiData("whatDoesTheCustomerDoInWhatTypeOfBusineessIsTheCustomerEngaged", responseData)

        }

    };
    dynamicApiCall = (fieldName, apiType) => {
        let getLocalstorageData=LocalstorageEncrypt.encryptStorageFunction.getItem("getMasterData"+apiType);
        if (apiType === "city" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "state" && getLocalstorageData!==undefined && getLocalstorageData!==null) {

            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }


        else if (apiType === "country" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "currency" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }

        else if (apiType === "phoneCountryCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "phoneCountryCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "savingsSchemeCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "currentSchemeCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "placeOfIssue" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "despatchMode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "nomineeRelationCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "sourceOfFund" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "constitution" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "monthlyIncome" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "accountOpen" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "designationCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "tradeService" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "modOfOperation" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "introducerStatus" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "bbkSegmentCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "guardianCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "subSegment" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "subSectorCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "sectorCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "depositCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "dispatchMode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "rmCode" &&getLocalstorageData!==undefined &&getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,LocalstorageEncrypt.encryptStorageFunction.getItem("getMasterDatarmCode"));
        }
        else if (apiType === "accountStatement" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "statementFrequency" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "chequebookWaiverType" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "branch" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "relationCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "customerStatus" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "agentBankingOutlet" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "waiverField" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "ccepCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "nonResident" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "agentBankingOutlet" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "maritalStatus" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "employmentType" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "relationship" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "relationType" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "contactType" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "title" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "documentCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "bankName" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }

        else if (apiType === "segment" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "phoneOrEmailId" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "bankRelationType" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "emailType" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "customerBusiness" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "priorityCenterCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "addressType" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "accountStatement" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "chequebookWaiverFunctionCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "addressFormat" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "customerOccupation" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "addressVariety" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }

        else {
            let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
            axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(fieldName);
                    console.log(response);
                    LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterData" + apiType, response.data);
                    if (apiType === "city") {
                        this.findByVarNameApiData("maillingAdresscity", response.data);
                        this.findByVarNameApiData("homeAddresscity", response.data);
                        this.findByVarNameApiData("workAddresscity", response.data);
                        this.findByVarNameApiData("presentAddresscity", response.data);
                        this.findByVarNameApiData("nreAddresscity", response.data);
                        this.findByVarNameApiData("mailingAddresscity1", response.data);
                        this.findByVarNameApiData("homeAddresscity1", response.data);
                        this.findByVarNameApiData("workAddresscity1", response.data);
                        this.findByVarNameApiData("workAddresscity2", response.data);


                        this.setState({
                            city: response.data,
                            getCity: true
                        })

                    } else if (apiType === "state") {

                        this.findByVarNameApiData("maillingAdressstate", response.data);
                        this.findByVarNameApiData("homeAddressstate", response.data);
                        this.findByVarNameApiData("workAddressstate", response.data);
                        this.findByVarNameApiData("presentAddressstate", response.data);
                        this.findByVarNameApiData("nreAddressstate", response.data);
                        this.findByVarNameApiData("mailingAddressstate1", response.data);
                        this.findByVarNameApiData("homeAddressstate1", response.data);
                        this.findByVarNameApiData("workAddressstate1", response.data);
                        this.findByVarNameApiData("workAddressstate2", response.data);

                        this.setState({
                            stateCode: response.data,
                            getState: true,
                        })
                    } else if (apiType === "country") {
                        this.findByVarNameApiData("passportNumbercountryOfIssue", response.data);
                        this.findByVarNameApiData("maillingAdresscountry", response.data);
                        this.findByVarNameApiData("homeAddresscountry", response.data);
                        this.findByVarNameApiData("workAddresscountry", response.data);
                        this.findByVarNameApiData("Customersnationality", response.data);
                        this.findByVarNameApiData("presentAddresscountry", response.data);
                        this.findByVarNameApiData("nreAddresscountry", response.data);
                        this.findByVarNameApiData("mailingAddresscountry1", response.data);
                        this.findByVarNameApiData("homeAddresscountry1", response.data);
                        this.findByVarNameApiData("workAddresscountry1", response.data);
                        this.findByVarNameApiData("workAddresscountry2", response.data);
                        // this.findByVarNameApiData("passportNumberplaceOfIssue", response.data)


                        this.setState({
                            country: response.data,
                            getCountry: true
                        })
                    } else if (apiType === "currency") {

                        //this.findByVarNameApiData("workAddressCurrency", response.data)
                        //  this.findByVarNameApiData("homeAddressCurrency", response.data)
                        this.findByVarNameApiData("aof1currencyCodeCcy", response.data);

                        this.setState({
                            currency: response.data,
                            getCurrency: true
                        })
                    } else if (apiType === "phoneCountryCode") {

                        this.findByVarNameApiData("countryCodecontactNumberphoneNo1", response.data);
                        this.findByVarNameApiData("countryCodecontactNumberphoneNo2", response.data);
                        this.findByVarNameApiData("countryCodecontactNumberphoneNo3", response.data);
                        this.setState({
                            phoneNumCountryCodeList: response.data
                        })
                    } else if (apiType === "customerStatus") {
                        this.findByVarNameApiData("miscustomerStatusNormalStaffPriorityEtc", response.data);
                        this.setState({
                            customerStatusList: response.data
                        })
                    } else if (apiType === "nomineeRelationCode") {
                        this.findByVarNameApiData("guardianDetailsrelation1", response.data);
                        this.setState({
                            customerStatusList: response.data
                        })
                    } else if (apiType === "savingsSchemeCode") {

                        this.setState({
                            savingsSchemeCodeList: response.data,
                        })
                    } else if (apiType === "placeOfIssue") {
                        this.findByVarNameApiData("passportNumberplaceOfIssue", response.data);
                        this.setState({
                            placeOfIssueList: response.data
                        })

                    } else if (apiType === "currentSchemeCode") {

                        this.setState({
                            currentSchemeCodeList: response.data,
                        })
                    } else if (apiType === "sourceOfFund") {
                        this.findByVarNameApiData("sourceOfFund", response.data);
                        this.findByVarNameApiData("documentsCollectedToEnsureTheSourceOfFund", response.data)

                    } else if (apiType === "constitution") {
                        this.findByVarNameApiData("misconstitution", response.data);
                        this.setState({
                            constitutionList: response.data
                        })

                    } else if (apiType === "monthlyIncome") {
                        this.findByVarNameApiData("customerMonthlyIncome", response.data)


                    } else if (apiType === "accountOpen") {
                        this.findByVarNameApiData("howWasAccountOpened", response.data)


                    } else if (apiType === "designationCode") {
                        this.findByVarNameApiData("relatedCbTaggingdesignationCode", response.data)

                    } else if (apiType === "tradeService") {
                        this.findByVarNameApiData("misavailedTradeServices", response.data);
                        this.setState({
                            tradeServiceList: response.data
                        })

                    } else if (apiType === "modOfOperation") {
                        this.findByVarNameApiData("schemeCodeChangemodeOfOperation", response.data);
                        this.setState({
                            despatchmodeList: response.data
                        })

                    } else if (apiType === "introducerStatus") {
                        this.findByVarNameApiData("primryiintroducerStatus", response.data);
                        this.setState({
                            introducerStatus: response.data
                        })
                    } else if (apiType === "bbkSegmentCode") {
                        this.findByVarNameApiData("misbbkSegmentCodeFreeCode7", response.data);
                        this.setState({
                            getfreecode7: response.data
                        })

                    } else if (apiType === "priorityCenterCode") {
                        //this.findByVarNameApiData("mispriorityCenterCodeFreeCode1", response.data)
                    } else if (apiType === "contactType") {
                        //this.findByVarNameApiData("contactNumbercontactType1", response.data)
                        // this.findByVarNameApiData("contactNumbercontactType2", response.data)
                        //this.findByVarNameApiData("contactNumbercontactType3", response.data)
                        // this.findByVarNameApiData("contactNumberpreferredContactNoType1", response.data)

                    } else if (apiType === "addressType") {
                        //this.findByVarNameApiData("workAddressaddressType", response.data)
                        // this.findByVarNameApiData("maillingAdressaddrssType", response.data)
                        //this.findByVarNameApiData("maillingAdresspreferredAddressType", response.data)
                        // this.findByVarNameApiData("homeAddressaddrssType", response.data)
                        // this.findByVarNameApiData("presentAddressaddrssType", response.data)
                        //this.findByVarNameApiData("nreAddressaddrssType", response.data)

                    } else if (apiType === "guardianCode") {
                        this.findByVarNameApiData("guardianDetailsguardianSelectionGurdian", response.data);


                        this.setState({
                            getGuardianCode: response.data
                        })

                    } else if (apiType === "accountStatement") {
                        //this.findByVarNameApiData("fincoreDataUpdateaccountStatement", response.data)
                        //  this.findByVarNameApiData("fincoreDataUpdateaccountStatement", response.data)
                    } else if (apiType === "subSegment") {
                        this.findByVarNameApiData("crmMissubSegment", response.data);
                        this.setState({
                            subSegmentList: response.data
                        })

                    } else if (apiType === "subSectorCode") {
                        this.findByVarNameApiData("fincoreDataUpdatesubSectorCode", response.data);
                        this.setState({
                            subsectorCodeList: response.data
                        })
                    } else if (apiType === "sectorCode") {
                        this.findByVarNameApiData("fincoreDataUpdatesectorCode", response.data);
                        this.setState({
                            sectorCodeList: response.data
                        })
                    } else if (apiType === "depositCode") {
                        this.findByVarNameApiData("fincoreDataUpdatedepositCodeFreeCode7", response.data);
                        this.setState({
                            depositCodeList: response.data
                        })
                    } else if (apiType === "dispatchMode") {
                        this.findByVarNameApiData("aof1despatchModePrintedEStatementBoth", response.data);
                        this.findByVarNameApiData("fincoreDataUpdatedispatchMode", response.data);
                        console.log("llllllllllll");
                        console.log(response.data);
                        this.setState({
                            dispatchMode: response.data
                        })
                    } else if (apiType === "rmCode") {
                        this.findByVarNameApiData("mispriorityMonitoringRmCodeFreeCode3", response.data);
                        this.setState({
                            rmCodeList: response.dat
                        })

                    } else if (apiType === "accountStatement") {
                        this.findByVarNameApiData("aof1statement", response.data)
                        //this.findByVarNameApiData("fincoreDataUpdateaccountStatement", response.data)
                    } else if (apiType === "statementFrequency") {
                        this.findByVarNameApiData("fincoreDataUpdatestatementFrequency", response.data);
                        this.findByVarNameApiData("aof1statement", response.data)
                    } else if (apiType === "chequebookWaiverFunctionCode") {
                        // this.findByVarNameApiData("misfunctionCodeForChequeBookWaiver", response.data)
                        // this.findByVarNameApiData("schemeCodeChangefunctionForSchemeChangeInCaseOfInstaPack", response.data)
                    } else if (apiType === "chequebookWaiverType") {
                        this.findByVarNameApiData("miswaiverTypeForChequeBookWaiver", response.data);
                        this.setState({
                            chequebookWaiverType: response.data
                        })
                    } else if (apiType === "branch") {
                        this.findByVarNameApiData("otherBankbranchName", response.data)

                    } else if (apiType === "relationCode") {
                        this.findByVarNameApiData("relatedCbTaggingrelationCode", response.data);
                        this.setState({
                            relationCodeList: response.data
                        })
                    } else if (apiType === "addressFormat") {
                        this.findByVarNameApiData("maillingAdressaddressFormat", response.data);
                        this.findByVarNameApiData("homeAddressaddressFormat", response.data);
                        this.findByVarNameApiData("workAddressaddressFormat", response.data);
                        this.findByVarNameApiData("presentAddressaddressFormat", response.data);
                        this.findByVarNameApiData("mailingAddressaddressFormat1", response.data);
                        this.findByVarNameApiData("homeAddressaddressFormat1", response.data);
                        this.findByVarNameApiData("workAddressaddressFormat1", response.data);
                        this.findByVarNameApiData("workAddressaddressFormat2", response.data)

                    } else if (apiType === "agentBankingOutlet") {
                        this.findByVarNameApiData("misagentBankingOutletFreeCode1", response.data);
                        this.setState({
                            agentBankingOutletList: response.data
                        })
                    } else if (apiType === "waiverField") {
                        this.findByVarNameApiData("miswaiverFieldFreeCode10", response.data);
                        this.setState({
                            waiverFieldList: response.data
                        })
                        // this.findByVarNameApiData("misdebitCardIssueFreeCode10", response.data)

                    } else if (apiType === "ccepCode") {
                        this.findByVarNameApiData("misccepCodeFreeCode9", response.data);
                        this.setState({
                            ccepCodeList: response.data
                        })

                    } else if (apiType === "nonResident") {
                        this.findByVarNameApiData("chairmanCertificatenonResident", response.data);
                        this.setState({
                            getnonResident: response.data
                        })

                    } else if (apiType === "agentBankingOutlet") {
                        this.findByVarNameApiData("misagentBankingOutletFreeCode1", response.data);
                        this.setState({
                            agentBankingOutletList: response.data
                        })
                    } else if (apiType === "maritalStatus") {
                        this.findByVarNameApiData("basicInformationUpdatemaritalStatus", response.data);
                        this.findByVarNameApiData("basicInformationmaritalStatus", response.data);
                        this.setState({
                            maritalStatusList: response.data
                        })

                    } else if (apiType === "employmentType") {
                        this.findByVarNameApiData("employerDataprofessionEmploymentType", response.data);
                        this.findByVarNameApiData("etinprofessionEmploymentType", response.data);
                        this.findByVarNameApiData("employerDataemploymentType", response.data);
                        this.setState({
                            employmentTypeList: response.data
                        })
                    } else if (apiType === "relationship") {
                        this.findByVarNameApiData("guardianDetailsrelation", response.data);

                        this.setState({
                            relationshipList: response.data
                        })
                    } else if (apiType === "relationType") {
                        this.findByVarNameApiData("relatedCbTaggingrelationType", response.data)

                    } else if (apiType === "contactType") {
                        // this.findByVarNameApiData("contactNumberpreferredContactNoType1", response.data)
                        /*  this.findByVarNameApiData("contactNumbercontactType1", response.data)
                          this.findByVarNameApiData("contactNumbercontactType", response.data)
                          this.findByVarNameApiData("contactNumbercontactType2", response.data)
                          this.findByVarNameApiData("contactNumbercontactType3", response.data)
                          this.findByVarNameApiData("contactNumberpreferredContactNoType1", response.data)
                          this.findByVarNameApiData("contactNumberpreferredContactNoType1", response.data)*/


                    } else if (apiType === "title") {
                        this.findByVarNameApiData("mistitle", response.data);
                        this.findByVarNameApiData("relatedCbTaggingtitle", response.data);
                        this.findByVarNameApiData("aCTitle", response.data);
                        this.setState({
                            titleList: response.data
                        })


                    } else if (apiType === "documentCode") {
                        this.findByVarNameApiData("photoIddocumentCode", response.data)

                    } else if (apiType === "bankName") {
                        this.findByVarNameApiData("otherBankbankName", response.data)


                    } else if (apiType === "phoneOrEmailId") {
                        this.findByVarNameApiData("contactNumberphoneNoEmailId1", response.data);
                        this.findByVarNameApiData("contactNumberphoneNoEmailId2", response.data);
                        this.findByVarNameApiData("contactNumberphoneNoEmailId3", response.data);
                        this.findByVarNameApiData("contactNumberEmailphoneNoEmailId4", response.data);
                        this.findByVarNameApiData("contactNumberEmailphoneNoEmailId1", response.data);
                        this.findByVarNameApiData("contactNumberEmailphoneNoEmailId2", response.data);
                        this.findByVarNameApiData("contactNumberEmailphoneNoEmailId", response.data);
                        this.setState({
                            phoneOrEmail: response.data
                        })
                    } else if (apiType === "customerOccupation") {

                        this.findByVarNameApiData("customersOccupation", response.data)

                    } else if (apiType === "bankRelationType") {

                        this.findByVarNameApiData("guardianDetailsbankRelationType", response.data);
                        this.setState({
                            bankRelationTypeList: response.data
                        })

                    } else if (apiType === "emailType") {

                        this.findByVarNameApiData("contactNumberEmailemailType1", response.data);
                        this.findByVarNameApiData("contactNumberEmailemailType2", response.data);
                        this.findByVarNameApiData("contactNumberEmailemailType", response.data);
                        this.findByVarNameApiData("contactNumberEmailpreferedEmailIdType", response.data);
                        this.setState({
                            phoneEmailType: response.data
                        })
                    } else if (apiType === "addressVariety") {

                        this.findByVarNameApiData("howTheAddressOfTheAccountHolderHasBeenVerified", response.data)

                    } else if (apiType === "customerBusiness") {

                        this.findByVarNameApiData("whatDoesTheCustomerDoInWhatTypeOfBusineessIsTheCustomerEngaged", response.data)

                    }
                    /*  else if (apiType === "accountOpen") {

                          this.findByVarNameApiData("", response.data)

                      }
                      else if (apiType === "monthlyIncome") {

                          this.findByVarNameApiData("", response.data)

                      }*/


                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };
    renderSubmitButton = () => {
        console.log(this.props.hideButton);
        if (this.state.getData) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={this.handleSubmit}
                    >Submit
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={this.handleSave}
                    >Save
                    </button>
                </div>
            )
        }
    };
    onKeyDownChange = (data) => {
        for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
            if (data.varName === "nomineeDob" + (i + 1)) {
                this.setState({
                    loading: true
                });
                this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                //  this.forceUpdate()
                setTimeout(() => {
                    let x = 18;
                    let currentDate = new Date();
                    let someDate = new Date();
                    let numberOfDaysToAdd = x;
                    someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let convertDate = year + "-" + month + "-" + date;
                    let newDate = new Date(convertDate);
                    let stringToDate = new Date(newDate.toDateString());
                    let inputDate = new Date(this.state.inputData["nomineeDob" + (i + 1)]);
                    if (stringToDate >= inputDate) {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "NO";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = true;
                        // this.state.varValue["nomineeMinor" + i] == "NO";
                    } else {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "YES";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = true;


                    }

                    this.forceUpdate()
                }, 1000);
                this.setState({
                    showValue: true,
                    getData: true,
                    loading: false
                })


            }
        }


        let inputData = this.state.inputData;
        let varValue = this.state.varValue;
        if (data.varName === "homeAddressaddrssType") {

            if (this.state.inputData.homeAddressaddrssType === {
                "masterDataName": "addressType",
                "key": "Mailing",
                "value": "Mailing"
            }) {
                this.state.inputData.homeAddressFieldShow = false;
                this.state.varValue.homeAddressFieldShow = false;
                Functions.sleep(500).then(() => {
                    this.state.inputData.homeAddresshomeAddressField1 = this.state.inputData.maillingAdressmailingAddressField1;
                    this.state.varValue.homeAddresshomeAddressField1 = this.state.inputData.maillingAdressmailingAddressField1;

                    this.state.inputData.homeAddresshomeAddressField2 = this.state.inputData.maillingAdressmailingAddressField2;
                    this.state.varValue.homeAddresshomeAddressField2 = this.state.inputData.maillingAdressmailingAddressField2;

                    this.state.inputData.homeAddresscity = this.state.inputData.maillingAdresscity;
                    this.state.varValue.homeAddresscity = this.state.inputData.maillingAdresscity;

                    this.state.inputData.homeAddressstate = this.state.inputData.maillingAdressstate;
                    this.state.varValue.homeAddressstate = this.state.inputData.maillingAdressstate;

                    this.state.inputData.homeAddresspostalCode = this.state.inputData.maillingAdresspostalCode;
                    this.state.varValue.homeAddresspostalCode = this.state.inputData.maillingAdresspostalCode;

                    this.state.inputData.homeAddresscountry = this.state.inputData.maillingAdresscountry;
                    this.state.varValue.homeAddresscountry = this.state.inputData.maillingAdresscountry;
                    this.state.inputData.homeAddressFieldShow = true;
                    this.state.varValue.homeAddressFieldShow = true;
                    this.forceUpdate()
                })
            }


        } else if (data.varName === "fincoreDataUpdatesectorCode") {
            this.state.inputData.sectorCodeApicall = false;
            let codeUrl = backEndServerURL + "/finMasterData/custom/subSector/" + this.state.inputData.fincoreDataUpdatesectorCode["key"];//{sectorCode}
            axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    {
                        this.findByVarNameApiData("fincoreDataUpdatesubSectorCode", response.data)
                    }
                    this.state.inputData.sectorCodeApicall = true;
                    this.forceUpdate()


                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (data.varName === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3" || data.varName === "mispriorityMonitoringRmCodeFreeCode3") {
            let getData = "";
            if (data.varName === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3") {
                getData = "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3"
            } else {
                getData = "mispriorityMonitoringRmCodeFreeCode3"

            }
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + getData;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        errorMessage: "RM Code Is Not Found,Please again Try!",
                        errorModalBoolean: true
                    })
                })
                .catch((error) => {
                    console.log(error)

                })
        } else if (data.varName == "basicInformationUpdatedob") {
            this.state.inputData.basicInformationUpdatedobApiCall = "NO";
            this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N";
            this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N";
            //this.updateComponent()
            //if(data.value==="basicInformationUpdatedob"){
            setTimeout(() => {
                let x = 18;
                var currentDate = new Date();

                let someDate = new Date();
                let numberOfDaysToAdd = x;
                someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                let date = someDate.getDate();
                let month = someDate.getMonth() + 1;
                let year = someDate.getFullYear();
                let convertDate = year + "-" + month + "-" + date;
                let newDate = new Date(convertDate);
                let stringToDate = new Date(newDate.toDateString());

                let inputDate = new Date(this.state.inputData.basicInformationUpdatedob);

                if (stringToDate >= inputDate) {
                    this.state.inputData.basicInformationUpdatedobApiCall = "YES";
                    this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N";
                    this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
                } else {
                    this.state.inputData.basicInformationUpdatedobApiCall = "YES";
                    this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "Y";
                    this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "Y"
                }

                x = 60;
                currentDate = new Date();
                someDate = new Date();
                numberOfDaysToAdd = x;
                someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                date = someDate.getDate();
                month = someDate.getMonth() + 1;
                year = someDate.getFullYear();
                convertDate = year + "-" + month + "-" + date;
                newDate = new Date(convertDate);
                stringToDate = new Date(newDate.toDateString());

                inputDate = new Date(this.state.inputData.basicInformationUpdatedob);
                if (stringToDate >= inputDate) {
                    inputData.basicInformationUpdateseniorCitizenIndicator = "Y";
                    varValue.basicInformationUpdateseniorCitizenIndicator = "Y"


                } else {

                    inputData.basicInformationUpdateseniorCitizenIndicator = "N";
                    varValue.basicInformationUpdateseniorCitizenIndicator = "N"
                }

                this.forceUpdate()

            }, 1000)

        }
        /*    for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
                if (data === "nomineecifId" + i) {
                    this.setState({
                        loading: true
                    })
                    this.state.inputData["nomineecifIdApiCall"+i] = false;
                    this.state.varValue["nomineecifIdApiCall"+i] = false;
                             console.log(response.data)
                             let x = 60;
                            let currentDate = new Date();
                            let someDate = new Date();
                            let numberOfDaysToAdd = x
                            someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                            let date = someDate.getDate();
                            let month = someDate.getMonth() + 1;
                            let year = someDate.getFullYear();
                            let convertDate = year +"-" + month + "-" + date;
                            let newDate = new Date(convertDate);
                            let stringToDate = new Date(newDate.toDateString());

                            let inputDate = new Date(response.data.birthDate);
                            this.state.inputData["nomineeDob" + i]=(response.data.birthDate).split("T")[0]
                            this.state.varValue["nomineeDob" + i]=(response.data.birthDate).split("T")[0]
                            if (stringToDate >= inputDate) {
                                this.state.inputData["nomineeMinor" + i] = "YES";
                                this.state.varValue["nomineeMinor" + i] = "YES";

                                // this.state.varValue["nomineeMinor" + i] == "NO";
                            } else {

                                this.state.inputData["nomineeMinor" + i] = "NO";
                                this.state.varValue["nomineeMinor" + i] = "NO";

                                //this.state.varValue["nomineeMinor" + i] == "YES";
                            }

                            /!*this.state.inputData["nomineeGuardiansName" + i] =response.data.
                            this.state.inputData["nomineeGuardianCode" + i] =response.data.
                            this.state.inputData["guardianPhotoId" + i] =response.data.
                            this.state.inputData["nomineeGuardianAddress" + i] =response.data.
                            this.state.inputData["nomineeGuardianAddress2" + i]=response.data.
                            this.state.inputData["nomineeGuardianCityCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.city)
                            this.state.inputData["nomineeGuardianStateCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.state)
                           this.state.inputData["nomineeGuardianPostalCode" + i]=response.data.
                          this.state.inputData["nomineeGuardianCountry2" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.country)
    *!/
                            this.state.inputData["nomineecifIdApiCall"+i] = true;
                            this.state.varValue["nomineecifIdApiCall"+i] = true;
                            this.forceUpdate()

                            console.log(this.state.inputData)


                }
            }*/


    };
    onKeyDownForDedup = (event, data) => {
        for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {

            if (data === "nomineecifId" + (i + 1)) {
                this.setState({
                    loading: true
                });

                this.state.inputData["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.varValue["nomineecifIdApiCall" + (i + 1)] = false;
                let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData["nomineecifId" + (i + 1)];
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);

                        this.state.inputData["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.inputData["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.inputData["nomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.inputData["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.inputData["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.inputData["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.inputData["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.inputData["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.varValue["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.varValue["nomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.varValue["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.varValue["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.varValue["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.varValue["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        let x = 18;
                        let currentDate = new Date();
                        let someDate = new Date();
                        let numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        let date = someDate.getDate();
                        let month = someDate.getMonth() + 1;
                        let year = someDate.getFullYear();
                        let convertDate = year + "-" + month + "-" + date;
                        let newDate = new Date(convertDate);
                        let stringToDate = new Date(newDate.toDateString());

                        let inputDate = new Date(response.data.birthDate);
                        this.state.inputData["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        this.state.varValue["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        if (stringToDate >= inputDate) {
                            this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "NO";

                            // this.state.varValue["nomineeMinor" + i] == "NO";
                        } else {
                            this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "YES";


                        }

                        /*this.state.inputData["nomineeGuardiansName" + i] =response.data.
                        this.state.inputData["nomineeGuardianCode" + i] =response.data.
                        this.state.inputData["guardianPhotoId" + i] =response.data.
                        this.state.inputData["nomineeGuardianAddress" + i] =response.data.
                        this.state.inputData["nomineeGuardianAddress2" + i]=response.data.
                        this.state.inputData["nomineeGuardianCityCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.city)
                        this.state.inputData["nomineeGuardianStateCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.state)
                       this.state.inputData["nomineeGuardianPostalCode" + i]=response.data.
                      this.state.inputData["nomineeGuardianCountry2" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.country)
*/
                        this.state.inputData["nomineecifIdApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineecifIdApiCall" + (i + 1)] = true;
                        this.forceUpdate();

                        console.log(this.state.inputData);
                        this.setState({
                            //varValue: this.state.varValue,
                            //inputData: this.state.inputData,
                            showValue: true,
                            getData: true,
                            loading: false
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                            errorTittle: "Invalid Introducer ID",
                            errorModalBoolean: true,


                        })
                    })

            }
        }
        if (data === "mispriorityMonitoringRmCodeFreeCode3") {
            this.setState({
                loading: true
            });
            this.state.inputData.mispriorityMonitoringRmCodeFreeCode3ApiCall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData.mispriorityMonitoringRmCodeFreeCode3;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.mispriorityMonitoringRmCodeFreeCode3Name = response.data;
                    this.state.varValue.mispriorityMonitoringRmCodeFreeCode3Name = response.data;
                    console.log(response.data);
                    this.state.inputData.mispriorityMonitoringRmCodeFreeCode3ApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Invalid RM Number!",
                        loading: false
                    })

                })
        } else if (data === "primryintroducerCustomerIdCifId") {
            this.setState({
                loading: true
            });
            this.state.inputData.introducerIdApiCall = false;
            this.state.varValue.introducerIdApiCall = false;
            let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData.primryintroducerCustomerIdCifId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.primryiintroducerNameLastName = response.data.customerName;
                    this.state.varValue.primryiintroducerNameLastName = response.data.customerName;
                    console.log(response.data);

                    this.state.inputData.introducerIdApiCall = true;
                    this.state.varValue.introducerIdApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })


                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Introducer ID",

                    })
                })
        } else if (data === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3") {
            this.setState({
                loading: true
            });
            this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3RmName = response.data;
                    this.state.varValue.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3RmName = response.data;
                    this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Invalid RM Number!",
                        loading: false
                    })

                })
        } else if (data === "guardianDetailsgurdianCifNumberCustomer") {
            this.setState({
                loading: true
            });
            this.state.inputData.guardianDetailsgurdianCifNumberCustomerApiCall = "NO";
            this.forceUpdate();
            let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData.guardianDetailsgurdianCifNumberCustomer;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.guardianDetailslastName = response.data.customerName;
                    this.state.varValue.guardianDetailslastName = response.data.customerName;
                    console.log(response.data);


                    this.state.inputData.guardianDetailsgurdianCifNumberCustomerApiCall = "YES";
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Number"
                    })
                })
        } else if (this.state.inputData.natioanlIdCarddocumentCodeN === {
            "masterDataName": "documentCode",
            "key": "NID",
            "value": "NID CARD IDENTITY"
        } || this.state.inputData.natioanlIdCarddocumentCodeN === {
            "masterDataName": "documentCode",
            "key": "RSNID",
            "value": "RSNID"
        }) {
            this.state.inputData.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = "Y";
            this.state.varValue.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = "Y"
        }

    };
    renderJsonform = () => {
        if (this.state.getData && (this.state.tagData || this.props.cbNumber === undefined && this.props.generateAccountNo === "NEW")) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, CBDataJsonFormNew, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)

            )
        } else if (this.state.getData && (this.state.tagData || this.props.cbNumber === undefined)) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, CBDataJsonForm, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)

            )
        }

    };
    closeModalError = () => {
        this.setState({
            errorModalBoolean: false
        })
    };

    render() {
        const {classes} = this.props;

        return (

            <div>

                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} ><b>{this.props.relationCode !== undefined ? this.props.relationCode : ""}</b><p
                            style={{fontSize: "16px"}}>(<b> Customer
                            Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                            <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                            <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}
                            <b> SOLID:</b>{this.props.solId !== undefined ? this.props.solId : ""} <b> Scheme
                                Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""})<a><CloseIcon
                                onClick={this.close} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
                    </CardHeader>
                    <CardBody>
                        <Grid container spacing={1}>

                            <Grid item xs={8}>

                                {this.renderImage()}

                            </Grid>


                            <Grid item xs={4}>


                                <React.Fragment>
                                    <br/>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="md"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.accountNo}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <AccountNoGenerate taging={this.props.taging}
                                                               tagingModalCbnumber={this.props.tagingModalCbnumber}
                                                               customerName={this.state.customerName}
                                                               solId={this.state.solId}
                                                               closeModal={this.closeModal}/>

                                        </DialogContent>
                                    </Dialog>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="xl"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.errorModalBoolean}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <ErrorModal title={this.state.errorMessage}
                                                        closeModal={this.closeModalError}
                                                        subServiceType={this.props.subServiceType}
                                                        appId={this.props.appId}
                                            />

                                        </DialogContent>
                                    </Dialog>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.loading}>
                                        <DialogContent className={classes.dialogPaper}>

                                            <center>
                                                <img src={loader} alt=""/>
                                            </center>
                                        </DialogContent>
                                    </Dialog>

                                    <ThemeProvider theme={theme}>
                                        <GridList cellHeight={window.innerHeight} cols={1}>
                                            <div>
                                                <Grid container>
                                                    {this.renderJsonform()}

                                                </Grid>
                                            </div>


                                        </GridList>
                                    </ThemeProvider>
                                    <div>

                                        <br/>
                                        <br/>
                                        <center>
                                            {
                                                this.renderSubmitButton()
                                            }
                                        </center>

                                    </div>

                                </React.Fragment>


                            </Grid>


                        </Grid>
                    </CardBody>
                </Card>
            </div>

        )
    }

}

export default withStyles(styles)(MakerCumInput);
