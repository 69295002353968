import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import "semantic-ui-offline/semantic.min.css";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import CBNotFound from "../../workflow/CASA/CBNotFound";
import CloseIcon from "@material-ui/icons/Close";
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#000",
        },
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: "auto",
    },
    Tab: {
        flexDirection: "row-reverse",
    },
    progress: {
        margin: 50,
    },
};

let jsonForm = [
    {
        varName: "branchName",
        type: "text",
        required: true,
        label: "Branch Name",
        grid: 2,
    },
    {
        varName: "description",
        type: "text",
        required: true,
        label: "Description",
        grid: 2,
    },
    {
        varName: "code",
        type: "text",
        required: true,
        label: "Code",
        grid: 2,
    },
    {
        varName: "status",
        type: "select",
        label: "Status",
        required: true,
        grid: 2,
        enum: ["ACTIVE", "INACTIVE"],
    }
];

class BranchUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            showValue: false,
            varValue: {},
            inputData: {},
            title: "",
            notificationMessage: "",
            loading: true,
            cbNotFound: false,
            alert: false,
            getData: false,
        };
    }


/*
    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;
        console.log(value);
        console.log(getKeyValue);

        if (value !== undefined && value !== null && Array.isArray(jsonArray) && jsonArray.length > 0) {
            for (let i = 0; i < jsonArray.length; i++) {
                let jsonObject = jsonArray[i];
                console.log(jsonObject);
                if (value === jsonObject.key) {
                    return jsonObject;
                    i = jsonArray.length;

                }
            }
        }


    };
*/
    componentDidMount() {
        // if (this.props.update) {
      /*      this.setState({
                inputData: {...this.props.rowData},
                varValue: this.copyJson({...this.props.rowData}),
            });
*/
        this.setState({
            varValue: this.copyJson({...this.props.rowData}),
            inputData: {...this.props.rowData},
            showValue: true,
            loading: false,
            getData: true,
        })
            /*let ccepData = [];
            let ccepMasterDataUrl = backEndServerURL + "/finMasterData/ccepCode";
            axios.get(ccepMasterDataUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if(this.props.rowData.processType==="CITYGEM CCEP"){
                        let occupationCodeUrl = backEndServerURL + "/occupationCode/getAll";

                        // axiosInstance.get(occupationCodeUrl)
                        .then((response) => {
                            for (let item of jsonForm) {
                                if (item.varName === "occupationCode") {
                                    item.enum = response.data;
                                    break;
                                }
                            }
            
                            this.setState({
                                inputData: response.data,
                                varValue: this.copyJson(response.data),
                                loading: false
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false
                            })
                        })
                    }  // SCHEME CODE
                    else if (this.props.rowData.processType==="SCHEME CODE"){
                         this.state.inputData.showSchemeCode= true
                        //  for (let item of jsonForm) {
                        //     if (item.varName === "schemeCode") {
                        //         item.enum = this.props.rowData.schemeCode;
                        //         break;
                        //     }
                        // }
                        this.state.inputData.showOldSchemeCode=true;
                         this.state.inputData.oldSchemeCode= this.props.rowData.ccepCode;
                         this.state.varValue.oldSchemeCode= this.props.rowData.ccepCode;
                    }
                
                 
                    for (let form of jsonForm) {
                        if (form.type === "autoCompleteValueReturn" && form.varName === "ccepCode") {
                            form.enum = response.data
                        }
                    }


                   setTimeout(()=>{
                    let url = backEndServerURL + "/getCcepRequestById/" + this.props.rowData.id;
                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((getResponse) => {

                            let inputData = {...this.state.inputData};
                            inputData.id = getResponse.data.id;
                            inputData.ccepCode = this.findByVarNameGetKeyValue(getResponse.data.ccepCode, response.data);

                            inputData.nameOfOrganization = getResponse.data.nameOfOrganization;
                            inputData.productType = getResponse.data.productType;
                            inputData.cardType = getResponse.data.cardType;
                            inputData.status = getResponse.data.status;
                            inputData.processType = getResponse.data.processType;
                            // "SCHEME CODE","CITYGEM CCEP","CCEP"
                            if(getResponse.data.processType==="CITYGEM CCEP"){
                                inputData.occupationCode = getResponse.data.occupationCode;
                            }
                            if(getResponse.data.processType==="SCHEME CODE"){
                                inputData.schemeCode = getResponse.data.ccepCode;
                            }
                            for (let form of jsonForm) {
                                if (form.type === "select" && form.varName === "cardType") {
                                    form.enum = this.props.cardTypeResponseData;
                                }
                                if (form.type === "select" && form.varName === "status") {
                                    form.readOnly = true;
                                }
                            }
                            inputData.showProductType = true;
                            this.setState({
                                varValue: this.copyJson(inputData),
                                inputData: this.copyJson(inputData),
                                showValue: true,
                                loading: false,
                                getData: true,
                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                   },1000)
                })
                .catch((error) => {
                    console.log(error)
                })*/
        // }
        /*else {
            let ccepMasterDataUrl = backEndServerURL + "/finMasterData/ccepCode";
            axios.get(ccepMasterDataUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    let occupationCodeUrl = backEndServerURL + "/occupationCode/getAll";

                    axiosInstance.get(occupationCodeUrl)
                    .then((response) => {
                        for (let item of jsonForm) {
                            if (item.varName === "occupationCode") {
                                item.enum = response.data;
                                break;
                            }
                        }
        
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                            loading: false
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
    

                    for (let form of jsonForm) {
                        if (form.type === "autoCompleteValueReturn" && form.varName === "ccepCode") {
                            form.enum = response.data
                        }
                    }
                    for (let form of jsonForm) {
                        if (form.type === "select" && form.varName === "cardType") {
                            form.enum = this.props.cardTypeResponseData;
                        }
                        if (form.type === "select" && form.varName === "status") {
                            form.readOnly = false;
                        }
                    }
                    let inputData = {...this.state.inputData};
                    //inputData.showProductType = true;
                    this.setState({
                        varValue: this.copyJson(inputData),
                        inputData: this.copyJson(inputData),
                        showValue: true,
                        loading: false,
                        getData: true,
                    })
                })
                .catch((error) => {
                    console.log(error)
                })

               

        }*/
    }

    renderHeader = () => {
        return (
            <h4>
                Branch Configuration Update
                <a>
                    <CloseIcon
                        onClick={this.props.closeModal}
                        style={{
                            position: "absolute",
                            right: 10,
                            color: "white",
                        }}
                    />
                </a>
            </h4>
        );
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type="success"
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false,
        });
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    handleSubmit = () => {
        let error = MyValidation.defaultValidation(jsonForm, this.state);
        this.forceUpdate();
        if (error === true) {
            return false;
        } else {
            this.setState({
                loading: true,
            });
            let url;
            let postData={};
            url=backEndServerURL + "/update-branch";
            postData.id=this.props.rowId;
            postData.branchName = this.state.inputData.branchName;
            postData.description = this.state.inputData.description;
            postData.code = this.state.inputData.code;
            postData.status = this.state.inputData.status;
            console.log(postData);
            axios
                .post(url, postData, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    },
                })
                .then((response) => {
                    console.log(response.data.saveStatus === false)
                    if(response.data.saveStatus === false){
                        this.setState({
                            loading: false,
                            title: "Same Branch already exists!",
                            cbNotFound: true,
                        });
                    }
                    else{
                        this.setState({
                            loading: false,
                            alert: true,
                            title: "Success",
                            notificationMessage: response.data.message,
                        });
                        this.props.closeModal();
                        setTimeout(function () {
                            window.location.reload()
                        }, 2500);
                    }
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        title: "Oops! Something went Wrong",
                        cbNotFound: true,
                    });
                });
            }
    };


    renderForm = () => {
        if (this.state.getData) {
            return (
                <>{CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent)}
                    {this.renderNotification()}
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={this.handleSubmit}
                        style={{
                            height: 25,
                            marginTop: 20,
                        }}
                    >
                        Save
                    </button>
                </>
            );
        }
    };

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

/*
    schemeCodeApiCall=(accountType,inputData)=>{
        let codeUrl;
        if (accountType === "Ababil") {
            codeUrl = backEndServerURL + "/getAccountSchemeCodeListAbabil";
        } else {
            let schemeType = accountType === "Current" ? "currentSchemeCode" : "savingsSchemeCode";

            codeUrl = backEndServerURL + "/finMasterData/" + schemeType;
        }

        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
        .then((response) => {
            for (let item of jsonForm) {
                if (item.varName === "schemeCode") {
                    item.enum = response.data;
                    break;
                }
            }
            inputData.showSchemeCode = true;
            this.setState({
                inputData: inputData,
                varValue: this.copyJson(inputData),
                loading: false
            });
        })
        .catch((error) => {
            console.log(error);
            this.setState({
                loading: false
            })
        })
    }
*/

/*
    functionForGetProductType = (data) => {
        if(data.varName === "cardType"){
            this.setState({
                loading: true
            });
            this.state.inputData.productType = "";
            this.state.inputData.showProductType = false;
            this.forceUpdate();
            let productTypeUrl = backEndServerURL + "/cmsDebitCardMasterDataProductTypes/" + this.state.inputData.cardType;
            axios.get(productTypeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        for (let form of jsonForm) {
                            if (form.type === "select" && form.varName === "productType") {
                                form.enum = response.data
                            }
                        }
                        this.state.inputData.showProductType = true;
                        this.forceUpdate();
                        this.setState({
                            varValue: this.copyJson(this.state.inputData),
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        cbNotFound: true,
                        title: "Product Type Not Found!",
                        loading: false
                    });
                })
        }
        // alert(data.varName)
        if (data.varName==="accountType") {
            let inputData = {...this.state.inputData};

  
            inputData.showSchemeCode = false;

                this.setState({
                    loading: true
                });
 
            // this.schemeCodeApiCall(this.state.inputData.accountType,inputData)
    
         
        }

        //  let occupationCodeUrl = backEndServerURL + "/occupationCode/getAll";

        // if(data.varName==="occupationCode"){
        //     let inputData = {...this.state.inputData};

  
  

        //     this.setState({
        //         loading: true
        //     });
        //     let occupationCodeUrl = backEndServerURL + "/occupationCode/getAll";

        //     axiosInstance.get(occupationCodeUrl)
        //     .then((response) => {
        //         for (let item of jsonForm) {
        //             if (item.varName === "occupationCode") {
        //                 item.enum = response.data;
        //                 break;
        //             }
        //         }

        //         this.setState({
        //             inputData: inputData,
        //             varValue: this.copyJson(inputData),
        //             loading: false
        //         });
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //         this.setState({
        //             loading: false
        //         })
        //     })
        // }
    };
*/


    render() {
        const {classes} = this.props;
        return (
            <div>
                <Card>
                    <CardHeader color="rose">{this.renderHeader()}</CardHeader>
                    <CardBody>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}
                        >
                            <DialogContent className={classes.dialogPaper}>
                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.cbNotFound}
                        >
                            <DialogContent className={classes.dialogPaper}>
                                <CBNotFound
                                    closeModal={this.closeModalCBNotFound}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        <Grid container>
                            <ThemeProvider theme={theme}>
                                {this.renderForm()}
                            </ThemeProvider>
                        </Grid>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(BranchUpdate);
