import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import 'semantic-ui-offline/semantic.min.css';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import Notification from "../../NotificationMessage/Notification";
import Table from "../../Table/Table";
import CBNotFound from "../CASA/CBNotFound";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

let jsonForm = [
    {
        varName: "groupId",
        type: "autoCompleteValueReturn",
        label: "Group Name",
        onKeyDown: true,
        required: true,
        grid: 2,
    }
];


class modifyGroupByUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            err: false,
            errorArray: {},
            errorMessages: {},
            varValue: {},
            inputData: {},
            showValue: false,
            alert: false,
            loading: true,
            title: "",
            autoComplete: {},
            notificationMessage: "",
            dynamicField: [],
            existingCategory: [],
            showExistingCategory: false,
            showLastSegment: false,
            cbNotFound: false,
            existingUser: [],
            showExistingUser: false,
        };
    }

    componentDidMount() {
        let url = backEndServerURL + "/GetAllCallCategoryGroup";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    console.log(response)
                    jsonForm[0].enum = response.data
                }
                this.setState({
                    loading: false
                })
            })
            .catch((error) => {
                console.log(error);
            });
    }


    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };


    functionForGetData = (data) => {
        if (data && this.state.inputData[data.varName]) {
            this.functionForGetUser();
        }
    };

    functionForGetUser = () => {
        this.setState({
            loading: true
        });
        let inputData = this.state.inputData;
        let url = backEndServerURL + "/GetAllUsersByGroupId/" + this.state.inputData.groupId.key;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let existingUser = [];
                response.data.map((item, index) => {
                    existingUser.push(this.createExistingUserTableData(index + 1, item.username, item.userRoleName, this.triggerExistingCategoryDelete(item.username)))
                });
                this.setState({
                    existingUser: existingUser,
                    showExistingUser: true,
                    loading: false
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    varValue: inputData,
                    showValue: true,
                    loading: false
                })
            });

    };

    triggerExistingCategoryDelete = (value) => {
        return (
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'middle',
                }}

                onClick={() => this.deleteCategory(value)}
            >
                Delete
            </button>
        )
    };

    deleteCategory = (value) => {
        this.setState({
            loading: true
        });
        let url = backEndServerURL + "/DeleteUserFromGroup/" + value + "/" + this.state.inputData.groupId.key;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.functionForGetUser();
                this.setState({
                    alert: true,
                    title: "Success",
                    notificationMessage: "User Unassigned Successful!"
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    cbNotFound: true,
                    title: error.response.data.message,
                })
            });
    };


    createExistingUserTableData = (sl, name, role, action) => {
        return ([
            sl, name, role, action
        ])
    };

    renderExistingData = () => {
        if (this.state.existingUser.length > 0 && this.state.showExistingUser) {
            return (
                <>
                    <br/>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Existing User:</b>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Sl", "User Name", "Role", "Action"]}
                                tableData={this.state.existingUser}
                                tableAllign={['left', 'left', 'left', 'left']}
                            />
                            <br/>
                        </div>
                    </div>
                    <br/>
                </>
            )
        }
    };

    renderLastSegments = () => {
        if (this.state.showExistingUser) {
            return (
                <>
                    <Grid item xs={12}>
                        {this.renderExistingData()}
                    </Grid>
                </>
            )
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    render() {
        const {classes} = this.props;
        return (
            <div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >
                            Remove User From Group
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <Grid container>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.loading}>
                                <DialogContent className={classes.dialogPaper}>
                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <ThemeProvider theme={theme}>
                                {CommonJsonFormComponent.renderJsonForm(
                                    this.state,
                                    jsonForm,
                                    this.updateComponent,
                                    "",
                                    this.functionForGetData
                                )}
                                {this.renderLastSegments()}
                            </ThemeProvider>
                            {this.renderNotification()}
                        </Grid>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(modifyGroupByUser);
