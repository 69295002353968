import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "../../Card/Card.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Functions from '../../../Common/Functions';
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Table from "../../Table/Table";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Notification from "../../NotificationMessage/Notification";
import PreviewMappingImage from "../Maintenance/casa/PreviewMappingImage";
import loader from "../../../Static/loader.gif";
import MyValidation from "../../JsonForm/MyValidation";
import GridList from "@material-ui/core/GridList";
import SignatureButton from "../Maintenance/SignatureButton";
import FunctionForGetAcDetails from "../CommonComponent/FunctionForGetAcDetails";
import CommonApi from "../Maintenance/common/CommonApi";


let csRemarks = [
    {
        "varName": "bomRemarks",
        "type": "textArea",
        "label": "BOM Remarks",
        "required": true,
        "grid": 12
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};


class ApprovalBOM extends Component {

    constructor(props) {
        super(props);
        this.state = {
            values: [],
            appId: '',
            message: "",
            appData: {},
            getData: false,
            varValue: {},
            caseId: "",
            title: "",
            notificationMessage: "",
            alert: false,
            redirectLogin: false,
            type: [],
            loading: true,
            inputData: {},
            fileUploadData: {},
            selectedDate: {},
            showValue: false,
            getDeferalList: [],
            getTutionFeeList: [],
            getLivingExpenceList: [],
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    getSearchvalue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '')
                delete clone[prop];
        return clone;
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    submitApprove = (event) => {
        event.preventDefault();
        let bom_approval = 'APPROVED';
        this.handleSubmit(bom_approval);
    };

    submitReturn = (event) => {
        event.preventDefault();
        let error = MyValidation.defaultValidation(csRemarks, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            let bom_approval = 'RETURN';
            this.handleSubmit(bom_approval);
        }
    };

    componentDidMount() {
        var remarksArray = [];
        this.state.inputData["csDeferal"] = "NO";
        if (this.props.appId !== undefined) {
            var tutionUrl = backEndServerURL + "/remittence/" + this.props.appId + "/Tution Fee";
            axios.get(tutionUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                console.log('Tution Free');
                console.log(response.data);
                let tableArray = [];
                response.data.map((tutionFee) => {
                    tableArray.push(this.createTutionFeeTable(tutionFee.beneficiaryBank, tutionFee.beneficiaryAcNo, tutionFee.AcNo, tutionFee.SwieftDetails, tutionFee.ammount));

                });
                this.setState({
                    getTutionFeeList: tableArray
                })
            }).catch((error) => {
                console.log(error);
            });

            var Livingurl = backEndServerURL + "/remittence/" + this.props.appId + "/Living Expense";
            axios.get(Livingurl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                console.log('Living Free');
                console.log(response.data);
                let tableArray = [];
                response.data.map((livingExpence) => {
                    tableArray.push(this.createLivingExpenceTable(livingExpence.beneficiaryBankLivingExpence, livingExpence.beneficiaryAcNoLivingExpence, livingExpence.AcNoLivingExpence, livingExpence.SwieftDetailsLivingExpence, livingExpence.ammountLivingExpence));
                });
                this.setState({
                    getLivingExpenceList: tableArray
                })
            }).catch((error) => {
                console.log(error);
            });
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                let variableData={...response.data};
                let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    let tableArray = [];
                    var status = "";
                    response.data.map((deferal) => {
                        if (deferal.status === "ACTIVE") {
                            status = "Approved"
                        } else if (deferal.status === "NOTAPPROVED") {
                            status = "NOT APPROVED"
                        } else {
                            status = deferal.status;
                        }
                        tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status, deferal.remarks));
                    });
                    this.setState({
                        getDeferalList: tableArray,
                    });

                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                        console.log(response.data);
                        response.data.map((data) => {
                            if (data.remarks !== 'undefined') {
                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                            }

                        });
                        this.setState({
                            getRemarks: remarksArray
                        })
                    }).catch((error) => {
                        console.log(error)
                    })

                }).catch((error) => {
                    console.log(error);
                });
                this.state.inputData["csDeferal"] = "YES";
                let varValue = response.data;
                if(variableData.source === "ABABIL"){
                    CommonApi.ababilAccountDetails(response.data.accountNumber).then((acResponse) => {
                        varValue.accountBalance = acResponse.data.acctEftvBalance;
                        this.setState({
                            getData: true,
                            varValue: this.getSearchvalue(varValue),
                            inputData: response.data,
                            showValue: true,
                            appId: this.props.appId,
                            loading: false
                        });
                    }).catch((error) => {
                        this.setState({
                            loading: false,
                        });
                    });
                }
                else{
                    FunctionForGetAcDetails.getAccountInfo(response.data.accountNumber).then((acResponse) => {
                        varValue.accountBalance = acResponse.data.acctEftvBalance;
                        this.setState({
                            getData: true,
                            varValue: this.getSearchvalue(varValue),
                            inputData: response.data,
                            showValue: true,
                            appId: this.props.appId,
                            loading: false
                        });
                    }).catch((error) => {
                        this.setState({
                            loading: false,
                        });
                    });
                }

            }).catch((error) => {
                console.log(error);
                if (error.response.status === 652) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
            });
        }
    }

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status, remarks) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status, remarks
        ])

    };
    createTutionFeeTable = (beneficiaryBank, beneficiaryAcNo, AcNo, SwieftDetails, ammount) => {

        return ([
            beneficiaryBank, beneficiaryAcNo, AcNo, SwieftDetails, ammount
        ])

    };
    createLivingExpenceTable = (beneficiaryBankLivingExpence, beneficiaryAcNoLivingExpence, AcNoLivingExpence, SwieftDetailsLivingExpence, ammountLivingExpence) => {

        return ([
            beneficiaryBankLivingExpence, beneficiaryAcNoLivingExpence, AcNoLivingExpence, SwieftDetailsLivingExpence, ammountLivingExpence
        ])

    };

    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Deferral Data</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status", "Remarks"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />
                        <br/>
                    </div>
                    <br/>
                </>
            )
        }

    };
    renderTutionFeeData = () => {
        if ((this.props.subServiceType === 'Student File' || this.props.subServiceType === 'Student File-Renew') && this.state.getTutionFeeList.length > 0) {
            return (
                <>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Tuition Fee Data</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Course Type", "Program Name", "Currency Type", "Referred by", "Amount"]}
                            tableData={this.state.getTutionFeeList}
                            tableAllign={['left', 'left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                    <br/>
                </>
            )
        }

    };
    renderLivingExpenceData = () => {
        if ((this.props.subServiceType === 'Student File' || this.props.subServiceType === 'Student File-Renew') && this.state.getLivingExpenceList.length > 0) {
            return (
                <>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Living Expense Data</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Beneficiary Bank", "Beneficiary A/C No", "A/C No", "Swift Details", "Amount"]}
                            tableData={this.state.getLivingExpenceList}
                            tableAllign={['left', 'left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                    <br/>
                </>
            )
        }

    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "Raised By", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />
                    <br/>
                </div>
            )
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };
    handleChangeComments = () => {

    };

    returnJsonForm = () => {
        if (this.state.showValue) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent)
            )


        }
    };

    handleSubmit = (bom_approval) => {
        this.setState({
            loading: true
        });
        this.state.inputData.bom_approval = bom_approval;
        this.state.inputData.cs_bearer = null;
        if (bom_approval === "RETURN" && this.props.subServiceType === 'Inward') {
            this.state.inputData.Inward_remittance_current_user = "BOM";
        }
        let remarksData = {};
        remarksData.remark = this.state.inputData.bomRemarks;
        remarksData.map = {...this.state.inputData, bomRemarks: undefined};
        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            this.setState({
                title: "Successful!",
                notificationMessage: "Successfully Routed!",
                alert: true,
                loading: false
            });
            this.props.closeModal();
        }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false
            })
        });
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
            )
        }
    };

    close = () => {
        this.props.closeModal();
    };


    viewAttachedImages = () => {
        const {classes} = this.props;
        return <PreviewMappingImage appId={this.props.appId} classes={classes}/>

    };

    signature = () => {
        if (this.state.getData && this.state.inputData.showSignature) {
            return <SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
                                    classes={this.props}/>;
        }
    };

    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <GridList cellHeight={window.innerHeight} cols={1}>
                    <div>
                        <Card>
                            <CardBody>
                                <div>
                                    <ThemeProvider theme={theme}>
                                        <Grid container spacing={1}>
                                            {this.renderNotification()}
                                            {this.returnJsonForm()}
                                            <Grid item xs={12}>
                                                {this.signature()}
                                            </Grid>
                                            <Grid item xs='12'>
                                                {this.renderTutionFeeData()}
                                                {this.renderLivingExpenceData()}
                                                {this.renderDefferalData()}
                                                {this.renderRemarksData()}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {this.viewAttachedImages()}
                                            </Grid>
                                            {this.renderRemarks()}
                                            <Grid item xs={12}>
                                                <button
                                                    className="btn btn-outline-primary btn-sm"
                                                    style={{
                                                        verticalAlign: 'right',
                                                    }}
                                                    type='button' value='add more'
                                                    onClick={this.submitApprove}
                                                > Approve
                                                </button>
                                                &nbsp;
                                                <button
                                                    className="btn btn-outline-primary btn-sm"
                                                    style={{
                                                        verticalAlign: 'right',
                                                    }}
                                                    type='button' value='add more'
                                                    onClick={this.submitReturn}
                                                > Return
                                                </button>
                                            </Grid>
                                        </Grid>
                                    </ThemeProvider>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </GridList>
            );
        }
    }
}

export default withStyles(styles)(ApprovalBOM);
