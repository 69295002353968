import React from "react";


import Grid from "@material-ui/core/Grid";

import { CircularProgress, Dialog, MuiThemeProvider } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";


// import theme from '../../../JsonForm/CustomeTheme2';
import { ThemeProvider, withStyles } from '@material-ui/styles';

import MaterialTable from "material-table";

import { createMuiTheme } from "@material-ui/core/styles";


import CloseIcon from "@material-ui/icons/Close";
// import { localization, options, tableIcons } from "../../../DeliverableManagement/configuration";

// import CardHeader from "../../../Card/CardHeader";
// import Card from "../../../Card/Card";
// import CardBody from "../../../Card/CardBody";
import Functions from "../../Common/Functions";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Card from "../Card/Card";
import { localization, tableIcons, options } from "../DeliverableManagement/configuration";
import theme from "../JsonForm/CustomeTheme2";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};
let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '5px',
            }
        },
    }
});


class FlowEFTNModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSearchField: false,
            inputData: {},
            varValue: {},
            showValue: false,
            showRequisitionField: false,
            getImageLink: [],
            title: "",
            notificationMessage: "",
            cbNotFound: false,
            disabled: false,
            SdnForFundTransfer: false,
            getData: false,
            getImageBoolean: false,
            errorArray: {},
            selectedDate: {},
            errorMessages: {},
            appId: 0,
            getRemarks: [],
            getDeferralList: [],
            confirmAlert: false,
            submitButtonClick: false,
            actionType: "",
            currentTask: "",
            loading: true,

        };
    }
    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };
    componentDidMount() {

        if (this.props.formShow !== undefined && this.props.formShow) {
            console.log(this.props.allFormData, "FLOW-ADNAN")
            this.setState({
                varValue: this.removeNullValue(this.props.allFormData),
                inputData: this.removeNullValue(this.props.allFormData),
            })
            setTimeout(() => {
                this.setState({
                    getData: true,
                    loading: false,
                    showValue: true,
                })
            }, 1000)
        } else {
            this.setState({
                loading: false,
                getData: true,
            })
        }

    }


    updateComponent = () => {
        this.forceUpdate();
    };
    tableChoice() {
        console.log(this.props.rowLength);

        if (this.state.getData && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={theme}>
                        <div style={{ maxWidth: "100%" }}>
                            <MaterialTable
                                style={{ whiteSpace: "nowrap" }}
                                columns={this.props.columns}
                                data={this.props.allData}
                                title={""}
                                options={{
                                    ...options, paging: true, selection: false,
                                    pageSize: 10,
                                    pageSizeOptions: [10, 20, 30],
                                    search: true,
                                    sorting: true,
                                }}
                                localization={localization}
                                icons={tableIcons}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        }
    }

    handleClose = (event) => {
        event.preventDefault();
        this.props.closeModalFlow()
    }



    render() {
        const { classes } = this.props;
        { Functions.redirectToLogin(this.state) }
        console.log("Props" + this.props);
        return (
            <div>

                
                <Card>
                    <CardHeader style={{
                    backgroundColor: '#142398',
                }}>
					<h4 className="text-white">{this.props.title}<a><CloseIcon onClick={this.handleClose} style={{
                position: 'absolute',
                right: 10,
                color: "white"
            }}/></a></h4>
                    </CardHeader>

                    <CardBody>
                        {this.state.loading &&
                            <div >
                                <center>
                                    <CircularProgress size="2rem" color="secondary" />
                                </center>
                            </div>
                        }
                        {this.tableChoice()}

                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default withStyles(styles)(FlowEFTNModal);