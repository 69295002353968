import React, {useEffect, useState} from "react";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import CardHeader from "../../../Card/CardHeader";
import CardBody from "../../../Card/CardBody";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import CBNotFound from "../../CASA/CBNotFound";
import Grid from "@material-ui/core/Grid";
import Card from "../../../Card/Card";
import loader from "../../../../Static/loader.gif";
import Notification from "../../../NotificationMessage/Notification";
import theme from "../../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import CommonApi from "../../Maintenance/common/CommonApi";
import axios from "axios";
import {backEndServerURL} from "../../../../Common/Constant";
import MaintenanceUtility from "../../Maintenance/static/MaintenanceUtility";
import Table from "../../../Table/Table";
import MyValidation from "../../../JsonForm/MyValidation";
import {jsonForBearer} from "./JsonForCustomerStatusUpdate";

let bmRemarks = [
    {
        "varName": "bmRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];


const BmInboxView = (props) => {
    const [stateValue, setStateValue] = useState({
        showSearchField: false,
        inputData: {},
        varValue: {},
        showValue: false,
        showRequisitionField: false,
        loading: true,
        title: "",
        notificationMessage: "",
        cbNotFound: false,
        disabled: false,
        SdnForFundTransfer: false,
        getData: true,
        errorArray: {},
        selectedDate: {},
        errorMessages: {},
        appId: 0,
        getRemarks: [],
        getDeferralList: [],
        values: []
    });


    useEffect(() => {
        if (props.appId !== undefined) {
            CommonApi.getRemarkDetails(props.appId)
                .then((remarksArray) => {
                    setStateValue((prevState => {
                        return {
                            ...prevState,
                            getRemarks: remarksArray
                        }
                    }));
                })
                .catch((error) => {
                    console.log(error)
                });

            CommonApi.getDeferralDetails(props.appId)
                .then((tableArray) => {
                    CommonApi.getFiles(props.appId).then((fileData) => {
                        setStateValue((prevState => {
                            return {
                                ...prevState,
                                getDeferralList: tableArray,
                            }
                        }));
                    })
                })
                .catch((error) => {
                    console.log(error);
                });
            CommonApi.getVariables(props.appId)
                .then((response) => {
                    let inputData = {...response.data};
                    setStateValue((prevState => {
                        return {
                            ...prevState,
                            appId: props.appId,
                            inputData: inputData,
                            varValue: inputData,
                            showValue: true,
                            getData: true,
                            showRequisitionField: true,
                            loading: false
                        }
                    }));
                })
                .catch((error) => {
                    errorModal(error)
                })
        }
    }, [props.appId]);

    const errorModal = (error) => {
        setStateValue((prevState => {
            return {
                ...prevState,
                cbNotFound: true,
                title: error.response.data.message,
                loading: false,
            }
        }));
    };


    const updateComponent = () => {

    };


    const resetState = (title, bool, showValue, inputData, loading, showRequisitionField) => {
        setStateValue((prevState => {
            return {
                ...prevState,
                inputData: inputData || stateValue.inputData,
                varValue: inputData || stateValue.inputData,
                showValue: showValue || stateValue.showValue,
                getData: true,
                showRequisitionField: showRequisitionField,
                loading: loading || false,
                disabled: false,
                title: title,
                cbNotFound: bool,
            }
        }));
    };

    const renderNotification = () => {
        if (stateValue.alert) {
            return (
                <Notification type="success" stopNotification={stopNotification} title={stateValue.title}
                              message={stateValue.notificationMessage}/>
            )
        }
    };


    const stopNotification = () => {
        setStateValue((prevState => {
            return {
                ...prevState,
                alert: false,
            }
        }));
    };


    const handleSubmit = (data) => {
        setStateValue((prevState => {
            return {
                ...prevState,
                loading: true,
            }
        }));
        if (data === "REJECT") {
            let dependencyField = [
                {varName: "bmRemarks", type: "textArea", required: true}
            ];
            let error = MyValidation.defaultValidation(dependencyField, stateValue);
            if (error === true) {
                setStateValue((prevState => {
                    return {
                        ...prevState,
                        loading: false,
                    }
                }));
                return 0;
            }
        }
        let appId = props.appId;
        let approval = data === "APPROVED" ? "APPROVE" : "REJECT";
        let deferralUrl = backEndServerURL + "/deferral/approval";
        axios.post(deferralUrl, {
            appId,
            approval
        }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

        let postData = {...stateValue.inputData};
        if (stateValue.inputData.cs_bearer === "both_approval" && data === "APPROVED") {
            postData.cs_bearer = "call_center_approval";
        } else if (stateValue.inputData.cs_bearer === "bm_approval" && data === "APPROVED") {
            postData.cs_bearer = "NO";
        } else if (stateValue.inputData.cs_deferal === "YES" && data === "APPROVED" && stateValue.inputData.cs_bearer === "call_center_approval") {
            postData.cs_bearer = "call_center_approval";
        } else if (data === "APPROVED") {
            postData.cs_bearer = "NO";
        }
        postData.bm_approval = data;
        postData.cs_deferal = "NO";
        postData.csDeferal = "NO";
        let remarksData = {};
        remarksData.remark = postData.bmRemarks;
        remarksData.map = {...postData, bmRemarks: undefined};
        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + props.appId;
        axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            setStateValue((prevState => {
                return {
                    ...prevState,
                    disabled: false,
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                    loading: false
                }
            }));
            props.closeModal()
        }).catch((error) => {
            console.log(error);
            errorModal(error)
        });

    };

    const renderRemarksData = () => {
        if (stateValue.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks</b>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={stateValue.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    const renderDeferralData = () => {
        if (stateValue.getDeferralList.length > 0) {
            return (
                <>
                    <br/>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Deferral List:</b>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                                tableData={stateValue.getDeferralList}
                                tableAllign={['left', 'left']}
                            />
                        </div>
                    </div>
                </>
            )
        }
    };

    const renderRequisitionField = () => {
        if (stateValue.getData && stateValue.showRequisitionField) {
            return <>
                {CommonJsonFormComponent.renderJsonForm(stateValue, jsonForBearer, updateComponent)}
                <Grid item xs={12}>
                    {renderDeferralData()}
                </Grid>
                <Grid item xs={12}>
                    {renderRemarksData()}
                </Grid>
                <Grid item
                      xs={12}>{CommonJsonFormComponent.renderJsonForm(stateValue, bmRemarks, updateComponent)}
                </Grid>
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: '15px',
                    }}
                    onClick={() => handleSubmit("APPROVED")}
                    type="submit"
                >
                    Approve
                </button>
                &nbsp;
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: '15px',
                    }}
                    onClick={() => handleSubmit("REJECT")}
                    type="submit"
                >
                    Return
                </button>
            </>
        }
    };

    const renderHeader = () => {
        if (props.appId !== undefined) {
            return MaintenanceUtility.renderCustomStatusUpdate(props.freeFlag1, props.caseId, props.solId, props.cbNumber, props.accountNumber, props.customerName, props.closeIcon)
        }
    };


    return (
        <>
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {renderHeader()}
                </CardHeader>
                <br/>
                <CardBody>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        open={stateValue.cbNotFound}>
                        <DialogContent>
                            <CBNotFound
                                closeModal={() => resetState(undefined, false, true, stateValue.inputData, false, true)}
                                title={stateValue.title}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        open={stateValue.loading}>
                        <DialogContent>
                            <center><img src={loader} alt=""/></center>
                        </DialogContent>
                    </Dialog>
                    <Grid container spacing={0}>
                        <ThemeProvider theme={theme}>
                            {renderRequisitionField()}
                        </ThemeProvider>
                    </Grid>
                    <br/>
                    {renderNotification()}
                </CardBody>
            </Card>
        </>
    )
};

export default BmInboxView;