import React, {Component} from "react";
import "./Static/css/App.css";
import {HashRouter, Route, Switch} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./Components/Login";
import ForgetPassword from "./Components/UserManagement/ForgetPassword";
import forgetPass from "./Components/UserManagement/ForgetPasswordSetPassword";
import Empty from "./Components/workflow/Hierarcy/Empty";
import SignatureView from "./Components/workflow/CommonComponent/SignatureView";
import PasswordChange from "./Components/UserManagement/PasswordChange";

class App extends Component {
    componentDidMount() {
        window.onpopstate = function (event) {
            window.history.go(1);
        };
    }

    render() {
        return (
            <div className="App">
                <HashRouter>
                    <Switch>
                        <Route path="/signatureCardView/:accountNumber" component={SignatureView}/>
                        <Route path="/signatureCardView/:accountNumber/:requestType" component={SignatureView} />
                        <Route path="/downloadRV/:id" component={Empty}/>
                        <Route path="/login" component={Login}/>
                        <Route path="/PasswordChange" component={PasswordChange}/>
                        <Route path="/ForgetPassword" component={ForgetPassword}/>
                        <Route path="/forgetPass" component={forgetPass}/>
                        <Route path="/" component={ProtectedRoute}/>
                    </Switch>
                </HashRouter>
            </div>
        );
    }
}

export default App;
