import React, {Component} from "react";
import Grid from "@material-ui/core/Grid/index";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Card from "../../../Card/Card.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import axios from "axios/index";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import Notification from "../../../NotificationMessage/Notification";
import Table from "../../../Table/Table";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import MyValidation from "../../../JsonForm/MyValidation";
import {AssetOperationReadOnlyForm} from "./JsonFormPayOrder"
import ConfirmAlert from "../../CommonComponent/ConfirmAlert";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";
import CloseIcon from "@material-ui/icons/Close";
import * as PropTypes from "prop-types";
import CardHeader from "../../../Card/CardHeader";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let bomRemarks = [
    {
        "varName": "bomRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12,
    }];

function CARD(props) {
    return null;
}

CARD.propTypes = {children: PropTypes.node};

class BomPayOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: false,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            appId: 0,
            getMappingAllImage: false,
            fileUploadData: {},
            getImageLink: [],
            getImageBoolean: false,
            imageName: [],
            getData: false,
            getRemarks: [],
            loading: true,
            approvedFromBom: false,
            actionType: "",
            confirmAlert: false,
            selectedDate: {},
        };
    }

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    componentDidMount() {
        let url = assetOpsBackEndServerURL + "/variables/" + this.props.appId;

        axios
            .get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {

                this.setState({
                    varValue: this.copyJson(response.data),
                    inputData: this.copyJson(response.data),
                });

                let remarksArray = [];
                let getCommentsUrl = assetOpsBackEndServerURL + "/appRemarkGet/" + this.props.appId;
                axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        console.log(response.data);
                        response.data.map((data) => {

                            remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                        });
                        this.setState({
                            getRemarks: remarksArray,
                            getData: true,
                            showValue: true,
                            loading: false
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch(error => {
                console.log(error);
            });

        let imageUrl = assetOpsBackEndServerURL + "/case/files/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    getImageLink: response.data,
                    getImageBoolean: true,
                    imageName: response.data
                })
            })
            .catch((error) => {
                console.log(error);
            })
    }

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type="success"
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        });
    };

    approveHandler = () => {
        let url = assetOpsBackEndServerURL + "/variables/" + this.props.appId;
        axios
            .post(url, {bom_approval: "YES"}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let remark_url = assetOpsBackEndServerURL + "/appRemarkSave/" + this.state.inputData.bomRemarks + "/" + this.props.appId;
                axios.post(remark_url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                    })
                    .catch(error => {
                        console.log(error)
                    });

                let caseRouteUrl = assetOpsBackEndServerURL + "/case/route/" + this.props.appId;
                axios
                    .get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => {
                        this.setState({
                            alert: true,
                            title: "Successful!",
                            notificationMessage: response.data,
                        });
                        this.props.closeModal()

                    })
                    .catch(error => {
                        console.log(error);
                    });

            })
            .catch(error => {
                console.log(error);
            });
    };
    returnHandler = () => {
        let url = assetOpsBackEndServerURL + "/variables/" + this.props.appId;
        axios
            .post(url, {bom_approval: "NO"}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let remark_url = assetOpsBackEndServerURL + "/appRemarkSave/" + this.state.inputData.bomRemarks + "/" + this.props.appId;
                axios.post(remark_url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                    })
                    .catch(error => {
                        console.log(error)
                    });
                let caseRouteUrl = assetOpsBackEndServerURL + "/case/route/" + this.props.appId;
                axios
                    .get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => {
                        this.setState({
                            alert: true,
                            title: "Successful!",
                            notificationMessage: response.data,
                        });
                        this.props.closeModal()
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                console.log(error);
            });

    }
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, bomRemarks, this.updateComponent)
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{width: "100%"}}>
                    <br/>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                    </div>
                </div>
            )
        }
    };

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <a
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: "middle",
                                display: "block",
                                marginTop: 20,
                            }}
                            target="_blank"
                            href={assetOpsBackEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                        >
                            {splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </a>
                        &nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };
    renderJsonForm = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, AssetOperationReadOnlyForm, this.updateComponent)
            )
        }
    }
    validationForHandleConfirm = (actionType) => {
        let errorRemarks = MyValidation.defaultValidation(bomRemarks, this.state);
        this.forceUpdate();
        if (errorRemarks === true) {
            return 0;
        }
        this.setState({
            confirmAlert: false,
        });
        if (actionType === "approveHandler") {
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })
        } else if (actionType === "returnHandler") {
            let dependencyField = [];
            dependencyField.push({
                "varName": "bomRemarks",
                "type": "textArea",
                "required": true,
                "label": "Remarks",
                "grid": 12
            })
            let error = MyValidation.defaultValidation(dependencyField, this.state);
            this.forceUpdate();

            if (error === true) {
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })
        }
    };
    closeConfirmAlert = (data, appId) => {
        this.setState({
            confirmAlert: false,
        });
        if (data === "NO") {
        } else if (data === "YES" && this.state.actionType === "approveHandler") {
            this.approveHandler()
        } else if (data === "YES" && this.state.actionType === "returnHandler") {

            this.returnHandler()
        }
    };
    handleCommon = (event, type) => {
        event.preventDefault();
        {
            this.validationForHandleConfirm(type)
        }
    };
    handleSubmitButton = () => {
        if (this.state.getData) {
            return (
                <React.Fragment>
                    <br/>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            marginRight: "10px",
                        }}
                        onClick={(event) => this.handleCommon(event, "approveHandler")}
                    >
                        Submit
                    </button>
                    &nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={(event) => this.handleCommon(event, "returnHandler")}
                    >
                        Return
                    </button>
                </React.Fragment>
            )
        }
    };
    renderFlowSUmmeryButton = () => {
        if (this.state.getData && this.props.appId !== undefined) {
            return (
                <>
                    <Grid item xs={12}><br/></Grid>
                    <FlowSummeryButton cbNumber={this.props.cbNumber} appId={this.props.appId}
                                       caseId={this.props.caseId}
                                       customerName={this.props.customerName} accountNumber={this.props.accountNumber}
                                       serviceType={this.state.inputData.serviceType}
                                       category={this.state.inputData.category}
                                       subCategory={this.state.inputData.subCategory}
                                       solId={this.props.solId}/>
                </>
            )
        }
    };
    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b>{this.state.inputData.serviceType}</b>
                    <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> CB Number:</b>{this.state.inputData.cbNumber}
                        <b> SOLID:</b>{this.props.solId} <b> Case
                            ID:</b> {this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                            onClick={this.props.closeModal}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
            )
        }
    };


    render() {
        const {classes, onClose} = this.props;
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderHeader()}
                </CardHeader>
                <CardBody>
                <Grid container spacing={0}>
                    <ThemeProvider theme={theme}>
                        {this.renderJsonForm()}
                        <Grid item xs={12}></Grid>
                        {this.mappingPhoto()}
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>
                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.confirmAlert}>
                            <DialogContent className={classes.dialogPaper}>
                                <ConfirmAlert validationForHandleConfirm={this.validationForHandleConfirm}
                                              closeModal={this.closeConfirmAlert}
                                />
                            </DialogContent>
                        </Dialog>
                    </ThemeProvider>
                    <Grid container spacing={0}>
                        <ThemeProvider theme={theme}>
                            {this.renderRemarksData()}
                            {this.renderFlowSUmmeryButton()}
                            {this.renderRemarks()}
                        </ThemeProvider>
                    </Grid>
                </Grid>
                <Grid item xs="12">
                    {this.handleSubmitButton()}
                </Grid>
                {this.renderNotification()}
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(styles)(BomPayOrder);
