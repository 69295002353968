import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../Common/Functions';
import Notification from "../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme";
import Table from "../../Table/Table";
import DialogContent from "@material-ui/core/DialogContent/index";
import loader from "../../../Static/loader.gif";
import {Dialog} from "@material-ui/core/index";
import GridList from "@material-ui/core/GridList/index";
import MyValidation from "../../JsonForm/MyValidation";
import CBNotFound from "../CASA/CBNotFound";
import UploadComponent from "../Maintenance/casa/UploadComponent";
import CaseWiseFiles from "../Maintenance/common/CaseWiseFiles";
import CommonApi from "../Maintenance/common/CommonApi";

let makerRemarks = [
    {
        "varName": "makerRemarks",
        "type": "textArea",
        "label": "Maker Remarks",
        "grid": 12
    }];


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};


class VerifyMakerInboxCase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            getData: false,
            varValue: {},
            showValue: false,
            title: "",
            notificationMessage: "",
            alert: false,
            inputData: {},
            selectedDate: {},
            loading: true,
            getRemarks: [],
            err: false,
            errorArray: {},
            errorMessages: {},
            cbNotFound: false,
        };
    }


    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    componentDidMount() {
        if (this.props.appId !== undefined) {
            CommonApi.getVariables(this.props.appId)
                .then((response) => {
                    let inputData = {...response.data};
                    CommonApi.getRemarkDetails(this.props.appId).then((remarksArray) => {
                        this.setState({
                            getRemarks: remarksArray
                        })
                    }).catch((error) => {
                        console.log(error)
                    });

                    let jsonForm = this.props.commonJsonForm;
                    for (let i = 0; i < jsonForm.length; i++) {
                        if (jsonForm[i].varName === "accountNumber") {
                            jsonForm[i].type = "text";
                            jsonForm[i].readOnly = true
                        }
                    }

                    if (this.props.freeFlag3 === "ISSUANCE") {
                        inputData.bankName = "THE CITY BANK LIMITED";

                        let branch = [];
                        let branchUrl = backEndServerURL + "/getBranchList";
                        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                response.data.map((item) => {
                                    branch.push(item)
                                });
                            })
                            .catch((error) => {
                                console.log(error)
                            });

                        let codeUrl = backEndServerURL + "/finMasterData/currency";
                        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                let jsonArray = this.props.commonJsonForm;
                                for (let i = 0; i < jsonArray.length; i++) {
                                    let jsonObject = jsonArray[i];
                                    if (jsonObject.varName === "currency") {
                                        this.props.commonJsonForm[i].enum = response.data;
                                    }
                                    if (jsonObject.varName === "branchName") {
                                        this.props.commonJsonForm[i].enum = branch;
                                    }
                                }
                                this.forceUpdate();
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        if (!response.data.informationGetByCBNumber && inputData.source!==undefined && inputData.source==="FINACLE" && inputData.cbNumber !== undefined) {
                            let getDataByCB = backEndServerURL + "/cbsCustomerGet/" + inputData.cbNumber;
                            axios.get(getDataByCB, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    if (response.status === 200) {
                                        if (inputData.selectTypeOfRequest === "BOND") {
                                            inputData.currency = {
                                                key: response.data.currencyCode,
                                                value: response.data.currencyCode
                                            };
                                            inputData.presentAddress = response.data.mailingAddress1;
                                            inputData.permanentAddress = response.data.homeAddress1;
                                        } else {
                                            inputData.sex = response.data.gender;
                                            inputData.accountTitleInEnglish = response.data.salutation;
                                        }
                                        if (response.data.COMMPH1BLOCK) {
                                            inputData.mobileNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                        }
                                        if (response.data.COMMEMLBLOCK) {
                                            inputData.email = response.data.COMMEMLBLOCK.emailInfo;
                                        }
                                        inputData.dateOfBirth = response.data.birthDate;
                                        inputData.nationalId = response.data.NIDNumber;
                                        inputData.informationGetByCBNumber = true;


                                        if (inputData.passportIssuanceDate && inputData.passportIssuanceDate !== -1) {
                                            let date = (inputData.passportIssuanceDate).split('T')[0].split("-");
                                            inputData.passportIssuanceDate = `${date[1]}/${date[2]}/${date[0]}`
                                        }
                                        if (inputData.dateOfBirth && inputData.dateOfBirth !== -1) {
                                            let date = (inputData.dateOfBirth).split('T')[0].split("-");
                                            inputData.dateOfBirth = `${date[1]}/${date[2]}/${date[0]}`
                                        }
                                        if (inputData.customerDob && inputData.customerDob !== -1) {
                                            let date = (inputData.customerDob).split('T')[0].split("-");
                                            inputData.customerDob = `${date[1]}/${date[2]}/${date[0]}`
                                        }
                                        if (inputData.nomineeDob && inputData.nomineeDob !== -1) {
                                            let date = (inputData.nomineeDob).split('T')[0].split("-");
                                            inputData.nomineeDob = `${date[1]}/${date[2]}/${date[0]}`
                                        }

                                        this.setState({
                                            inputData: inputData,
                                            varValue: this.emptyValueRemove(inputData),
                                            showValue: true,
                                            getData: true,
                                            loading: false
                                        });
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.setState({
                                        inputData: this.emptyValueRemove(inputData),
                                        varValue: this.emptyValueRemove(inputData),
                                        showValue: true,
                                        getData: true,
                                        cbNotFound: true,
                                        title: error.response.data.message,
                                        loading: false
                                    });
                                });
                        }

                        else if (!response.data.informationGetByCBNumber && inputData.source!==undefined && inputData.source==="ABABIL" && inputData.cbNumber !== undefined) {
                            let getDataByCB = backEndServerURL + "/getCustomerInfoAbabilModified/" + inputData.cbNumber;
                            axios.get(getDataByCB, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    if (response.status === 200) {
                                        if (inputData.selectTypeOfRequest === "BOND") {
                                            // inputData.currency = {
                                            //     key: response.data.currencyCode,
                                            //     value: response.data.currencyCode
                                            // };
                                            inputData.presentAddress = response.data.mailingAddress1;
                                            inputData.permanentAddress = response.data.addrLine1;
                                        } else {
                                            inputData.sex = response.data.gender;
                                            inputData.accountTitleInEnglish = response.data.name;
                                        }
                                        inputData.mobileNumber = response.data.mobileNumber;
                                        inputData.email = response.data.email;
                                        inputData.dateOfBirth = response.data.birthDate;
                                        inputData.nationalId = response.data.nidNo;
                                        inputData.informationGetByCBNumber = true;


                                        if (inputData.passportIssuanceDate && inputData.passportIssuanceDate !== -1) {
                                            let date = (inputData.passportIssuanceDate).split('T')[0].split("-");
                                            inputData.passportIssuanceDate = `${date[1]}/${date[2]}/${date[0]}`
                                        }
                                        if (inputData.dateOfBirth && inputData.dateOfBirth !== -1) {
                                            let date = (inputData.dateOfBirth).split('T')[0].split("-");
                                            inputData.dateOfBirth = `${date[1]}/${date[2]}/${date[0]}`
                                        }
                                        if (inputData.customerDob && inputData.customerDob !== -1) {
                                            let date = (inputData.customerDob).split('T')[0].split("-");
                                            inputData.customerDob = `${date[1]}/${date[2]}/${date[0]}`
                                        }
                                        if (inputData.nomineeDob && inputData.nomineeDob !== -1) {
                                            let date = (inputData.nomineeDob).split('T')[0].split("-");
                                            inputData.nomineeDob = `${date[1]}/${date[2]}/${date[0]}`
                                        }

                                        this.setState({
                                            inputData: inputData,
                                            varValue: this.emptyValueRemove(inputData),
                                            showValue: true,
                                            getData: true,
                                            loading: false
                                        });
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.setState({
                                        inputData: this.emptyValueRemove(inputData),
                                        varValue: this.emptyValueRemove(inputData),
                                        showValue: true,
                                        getData: true,
                                        cbNotFound: true,
                                        title: error.response.data.message,
                                        loading: false
                                    });
                                });
                        }

                        else {
                            this.setState({
                                inputData: this.emptyValueRemove(inputData),
                                varValue: this.emptyValueRemove(inputData),
                                showValue: true,
                                getData: true,
                                loading: false
                            });
                        }
                    } else {
                        this.setState({
                            inputData: this.emptyValueRemove(inputData),
                            varValue: this.emptyValueRemove(inputData),
                            showValue: true,
                            getData: true,
                            loading: false
                        });
                    }

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        title: error.response.data.message,
                        cbNotFound: true
                    })
                });
        }
    }


    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined" ||
                clone[prop] === "-1"
            )
                delete clone[prop];
        return clone;
    };

    handleSubmit = (event, data) => {
        event.preventDefault();
        let dependencyField = [];
        if (data === "RETURN") {
            dependencyField.push({

                "varName": "makerRemarks",
                "type": "textArea",
                "required": true,
                "label": "Maker Remarks",
                "grid": 12
            })
        } else {
            dependencyField = this.props.commonJsonForm;
        }
        let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }

        let postData = this.state.inputData;
        postData.bond_maker_approval = data;
        postData.freeFlag4 = this.state.inputData.registrationNumber;
        postData.freeFlag5 = this.state.inputData.selectTypeOfProject;
        postData.freeFlag6 = this.state.inputData.amount;
        let remarksData = {};
        remarksData.remark = this.state.inputData.makerRemarks;
        remarksData.map = {...postData, makerRemarks: undefined};
        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            this.setState({
                title: "Successful!",
                notificationMessage: "Successfully Routed!",
                alert: true
            });
            this.props.closeModal()
        }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false
            })
        });
    };

    amountConvert = (event, data) => {
        let inputData = {...this.state.inputData};
        inputData.showAmountConvertField = false;
         let json=[
             {
             "varName": "amount",
             "label" : "Amount",
             "type": "textApiCall",
             "validation": "numeric",
             "conditionalVarName": "accountNumber",
             "conditionalVarValue": true,
             "grid": 2,
             "multiline": true,
             "required": true
            }
         ];
        let error = MyValidation.defaultValidation(json, this.state);
        this.forceUpdate();
        if (error) {
            return 0;
        }else{
            if (data === "amount" && inputData.amount !== undefined && inputData.amount !== null) {
                this.setState({
                    loading: true
                })
                let amount = inputData.amount.split(".");
                if (2 < amount.length) {
                    this.setState({
                        inputData: inputData,
                        varValue: inputData,
                        getData: true,
                        loading: false,
                        cbNotFound: true,
                        title: "Invalid Amount!"
                    })
                } else {
                    let url = backEndServerURL + "/GetWordFromDecimalNumber";
                    let postData = {};
                    postData.number = inputData.amount;
                    axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            inputData.amountInWord = response.data;
                            inputData.showAmountConvertField = true;
                            this.state.inputData.amountApiCall = "YES";
                            setTimeout(() => {
                                this.setState({
                                    inputData: inputData,
                                    varValue: inputData,
                                    getData: true,
                                    loading: false
                                })
                            }, 1000)
                        })
                        .catch((error) => {
                            inputData.amount = "";
                            inputData.amountInWord = "";
                            this.setState({
                                inputData: inputData,
                                varValue: inputData,
                                getData: true,
                                loading: false,
                                cbNotFound: true,
                                title: error.response.data.message
                            })
                        });


                }

            }
        }
    }

    renderJsonFormFirst = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent, this.amountConvert, this.functionForGettingRoutingNumber)
            )
        }
    };

    handleSubmitSave = (event) => {
        event.preventDefault();
        let variableSetUrl = backEndServerURL + "/save/" + this.props.appId;
        let data = this.state.inputData;
        // data.makerRemarks = undefined;
        data.freeFlag4 = this.state.inputData.registrationNumber;
        data.freeFlag5 = this.state.inputData.selectTypeOfProject;
        data.freeFlag6 = this.state.inputData.amount;
        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Saved!",
                    alert: true
                });
                this.props.closeModal()
            })
            .catch((error) => {
                console.log(error)
            });
    };

    renderButton = () => {
        if (this.state.getData) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "APPROVE")}
                    >Approve
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={this.handleSubmitSave}
                    >Save
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "RETURN")}
                    >Return
                    </button>
                </div>

            )
        }
    };


    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, makerRemarks, this.updateComponent)
            )
        }
    };

    functionForGettingRoutingNumber = () => {
        if (this.props.freeFlag3 === "ISSUANCE" && this.state.varValue.selectTypeOfRequest === "SANCHAYPATRA") {
            this.setState({
                loading: true,
            });

            let url = backEndServerURL + "/getRoutingNum/" + this.state.inputData.branchName;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        let input = this.state.inputData;
                        let varVal = {...this.state.inputData};
                        varVal.customerDob = "-1";
                        input.routingNumber = response.data.toString();
                        varVal.routingNumber = response.data.toString();
                        this.setState({
                            varValue: varVal,
                            inputData: input,
                            getData: true,
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
            this.forceUpdate();
        } else {
            return false;
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    uploadFunction = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return <UploadComponent appId={this.props.appId} classes={classes} processName="Bond"
                                    eadviceFlag={true}/>
        }
    };

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);

        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <GridList cellHeight={window.innerHeight} cols={1}>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <div>
                        <Grid container spacing={1}>
                            <ThemeProvider theme={theme}>
                                {this.renderNotification()}
                                {this.renderJsonFormFirst()}
                            </ThemeProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <br/>
                            {this.uploadFunction()}
                            <br/><br/>
                        </Grid>
                        <CaseWiseFiles appId={this.props.appId}/>
                        <Grid item xs={12}><br/></Grid>
                        <Grid item xs={12}>
                            {this.renderRemarksData()}
                        </Grid>
                        <br/>
                        <ThemeProvider theme={theme}>
                            {this.renderRemarks()}
                            <br/>
                        </ThemeProvider>
                        {this.renderButton()}
                    </div>
                </GridList>
            )
        }
    }
}

export default withStyles(styles)(VerifyMakerInboxCase);

