import React, {Component} from "react";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../JsonForm/CustomeTheme2";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import Notification from "../NotificationMessage/Notification";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {backEndServerURL} from "../../Common/Constant";
import MyValidation from "../JsonForm/MyValidation";
import axios from "axios";
import Functions from "../../Common/Functions";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";
import SelectComponent from "../JsonForm/SelectComponent";
import TextFieldComponent from "../JsonForm/TextFieldComponent";
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";
import ReferenceModal from "../DeliverableManagement/ReferenceModal";
import ConfirmationModal from "../workflow/FundTransfer/ConfirmationModal";
var stampType = [
    {
        varName: "stampType",
        type: "select",
        enum: [],
        required: true,
        label: "Stamp Type",
    },
];
var quantity = [
    {
        varName: "quantity",
        type: "text",
        label: "Quantity",
        required: true,
    },
];

let Form = [];
let workplace = [
    {title: "BRANCH"},
    {title: "OPERATIONS"},
    {title: "CSU"},
];
let groupList = [];
let workplaceTypeList = [];

class OutgoingDispatchIntiator extends Component {
    state = {
        inputData: {},
        selectedDate: {},
        getData: false,
        workstation: false,
        role: false,
        showValue: false,
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        values: [],
        err: false,
        varValue: [],
        errorArray: {},
        errorMessages: {},
        errorWorkPlace: false,
        errorgroup: false,
        title: "",
        cbTitle: "",
        notificationMessage: "",
        alert: false,
        getGroupListData: false,
        getworkplaceTypeListData: false,
        workplace: "",
        loading: true,
        type: "success",
        submitButton: false,
        confirmationAlert: false,
        refNum: null,
    };
    reload = () => {
        if (this.props.appId !== undefined) {
            this.setState({
                submitButton: false,
                appId: this.props.appID,
                getData: false,
                showValue: false,
            });
        } else {
            this.setState({
                submitButton: false,
                getData: false,
                showValue: false,
            });
        }
        let urls = backEndServerURL + "/get-document-types";
        axios.get(urls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,},})
            .then((documentTypes) => {
                let url = backEndServerURL + "/get-branch-names";
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((branch) => {
                        Form = [
                            {
                                varName: "senderName",
                                type: "text",
                                label: "Sender Name",
                                required: true,
                                readOnly: true,
                                grid: 3,
                            },
                            {
                                varName: "receiverAddressType",
                                type: "select",
                                enum: ["INNER", "OUTER"],
                                label: "Receiver Address Type",
                                onKeyDown: true,
                                required: true,
                                grid: 3,
                            },
                            {
                                varName: "receiverDetails",
                                type: "text",
                                label: "Receiver Details",
                                required: true,
                                grid: 3,
                                conditional: true,
                                conditionalVarName: "receiverAddressType",
                                conditionalVarValue: "OUTER",
                            },
                            {
                                varName: "branchName",
                                type: "select",
                                enum: branch.data,
                                label: "Branch Name",
                                required: true,
                                grid: 3,
                                conditional: true,
                                conditionalVarName: "receiverAddressType",
                                conditionalVarValue: "INNER",
                            },
                            {
                                varName: "typeOfDocument",
                                type: "select",
                                enum: documentTypes.data,
                                label: "Type of Document",
                                required: true,
                                grid: 3,
                            },
                            {
                                varName: "documentDetails",
                                type: "text",
                                label: "Document Details",
                                required: true,
                                grid: 3,
                            },
                            // {
                            //     varName: "referenceNumber",
                            //     type: "text",
                            //     label: "Reference Number",
                            //     required: true,
                            //     readOnly: true,
                            //     grid: 3,
                            // },
                        ];
                        this.setState({
                            dataSetFlag: true,
                        });
                    }).catch((error) => {
                    this.setState({
                        type: "error",
                        title: "Oops!! Failed to load Branch Master Data",
                        notificationMessage: "Failed!",
                        alert: true,
                        loading: false,
                    });
                })
            })
            .catch((error) => {
                this.setState({
                    type: "error",
                    title: "Oops!! Failed to load Document Type Master Data",
                    notificationMessage: "Failed!",
                    alert: true,
                    loading: false,
                });
            });
        let start_url =
            backEndServerURL + "/startCase/cheque_book_request_start_initial";
        axios
            .get(start_url, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                },
            })
            .then((response) => {
                this.getMasterdataAll();
                this.setState({
                    appId: response.data.id,
                    caseId: response.data.caseId,
                });
                let inputData = response.data;
                inputData.referenceNumber = response.data.caseId;
                let varValue = this.emptyValueRemove(response.data);
                varValue.referenceNumber = varValue.caseId;
                this.forceUpdate();
                this.setState({
                    inputData: inputData,
                    varValue: varValue,
                    loading: false,
                    getData: true,
                    showValue: true,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }
    emptyValueRemove = (data) => {
        if (data === "" || data === " " || data === "null" || data === "undefined")
            data = "";
        return data;
    };
    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
        });
        let error = MyValidation.defaultValidation(this.emptyValueRemove(Form), this.state);
        this.forceUpdate();
        if (
            error === true ||
            this.state.inputData.workplaceType === null ||
            this.state.inputData.groupName === null
        ) {
            this.setState({
                loading: false,
            });
            return 0;
        }
        this.forceUpdate();

        let start_url =
        backEndServerURL + "/startCase/cheque_book_request_start_initial";
    axios
        .get(start_url, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
            },
        })
        .then((response) => {
           
            this.setState({
                appId: response.data.id,
                caseId: response.data.caseId,
                refNum: response.data.caseId,
            });
            let inputData = {...this.state.inputData}
            inputData.referenceNumber = response.data.caseId;
            this.setState({
                inputData: this.copyJson(inputData),
                varValue: this.copyJson(inputData)
            })
            this.setState({
                title: `Reference Number: ${response.data.caseId}`,
                confirmationAlert: true,
                loading: false,
            });
           
        })
        .catch((error) => {
            console.log(error);
        });

 
    };
    renderButton = () => {
        return (
            <button
                className="btn btn-outline-primary  btn-sm"
                style={{
                    marginTop: "18px",
                }}
                onClick={this.handleSubmit}
            >
                Create
            </button>
        );
    };

    getMasterdataAll = () => {
        let url = backEndServerURL + "/get/insuranceMasterDataList";
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                },
            })
            .then((response) => {
                let stampList = [];
                response.data.map((data) => {
                    stampList.push(data.stampDescription);
                });
                this.setState({
                    stampTypeList: stampList,
                    getData: true,
                    loading: false,
                    showValue: true,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    componentDidMount() {
        if (this.props.appId !== undefined) {
            this.setState({
                submitButton: false,
                appId: this.props.appId,
            });
        } else {
            this.setState({
                submitButton: false,
            });
        }
        let urls = backEndServerURL + "/get-document-types";
        axios.get(urls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,},})
            .then((documentTypes) => {
                let url = backEndServerURL + "/get-branch-names";
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((branch) => {
                        Form = [
                            {
                                varName: "senderName",
                                type: "text",
                                label: "Sender Name",
                                required: true,
                                readOnly: true,
                                grid: 3,
                            },
                            {
                                varName: "receiverAddressType",
                                type: "select",
                                enum: ["INNER", "OUTER"],
                                label: "Receiver Address Type",
                                onKeyDown: true,
                                required: true,
                                grid: 3,
                            },
                            {
                                varName: "receiverDetails",
                                type: "text",
                                label: "Receiver Details",
                                required: true,
                                grid: 3,
                                conditional: true,
                                conditionalVarName: "receiverAddressType",
                                conditionalVarValue: "OUTER",
                            },
                            {
                                varName: "branchName",
                                type: "select",
                                enum: branch.data,
                                label: "Branch Name",
                                required: true,
                                grid: 3,
                                conditional: true,
                                conditionalVarName: "receiverAddressType",
                                conditionalVarValue: "INNER",
                            },
                            {
                                varName: "typeOfDocument",
                                type: "select",
                                enum: documentTypes.data,
                                label: "Type of Document",
                                required: true,
                                grid: 3,
                            },
                            {
                                varName: "documentDetails",
                                type: "text",
                                label: "Document Details",
                                required: true,
                                grid: 3,
                            },
                            // {
                            //     varName: "referenceNumber",
                            //     type: "text",
                            //     label: "Reference Number",
                            //     required: true,
                            //     readOnly: true,
                            //     grid: 3,
                            // },
                        ];
                        let inputData = {}
                        inputData.senderName = LocalstorageEncrypt.encryptStorageFunction.getItem("username");
                        this.setState({
                            inputData: inputData,
                            varValue: inputData
                        })
                        this.setState({
                            dataSetFlag: true,
                        });
                        this.getMasterdataAll();
                    }).catch((error) => {
                    this.setState({
                        type: "error",
                        title: "Oops!! Failed to load Branch Master Data",
                        notificationMessage: "Failed!",
                        alert: true,
                        loading: false,
                    });
                })
            })
            .catch((error) => {
                this.setState({
                    type: "error",
                    title: "Oops!! Failed to load Document Type Master Data",
                    notificationMessage: "Failed!",
                    alert: true,
                    loading: false,
                });
            });
           
     
    }

    updateComponent = () => {
        this.forceUpdate();
    };
    renderJsonForm = () => {
        if (this.state.getData && this.state.dataSetFlag) {
            return CommonJsonFormComponent.renderJsonForm(
                this.state,
                Form,
                this.updateComponent, undefined, this.onKeyDownChange
            );
        }
    };
    onKeyDownChange = (data) => {
    }
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type={this.state.type}
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };
    stopNotification = () => {
        this.setState({
            alert: false,
        });
    };
    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}}>
                    Dispatch Management (Outgoing)
                    <a>
                        <CloseIcon
                            onClick={this.close}
                            style={{
                                position: "absolute",
                                right: 10,
                                color: "white",
                            }}
                        />
                    </a>
                </h4>
            );
        } else {
            return <h4 style={{color: "white"}}>Dispatch Management (Outgoing)</h4>;
        }
    };
    functionAfterSubmit = () =>{
               /// new
               let postData = {...this.state.inputData};
               console.log(postData)
               postData.values = this.state.values;
               postData.freeFlag1 = "FDD";
               postData.category = "Outgoing";
               postData.subCategory = "Dispatch";
               postData.serviceType = "Debit Card";
               postData.subServiceType = "Chequebook";
               postData.customerName = this.state.inputData.customerName;
               postData.appId = this.state.appId;
               postData.referenceNumber = this.state.refNum;
               postData.chequebook_approval = "CLOSED";
               console.log(postData)
               let url = backEndServerURL + "/variables/" + this.state.appId;
               axios
                   .post(url, postData, {
                       headers: {
                           Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                       },
                   })
                   .then((response) => {
                       let url = backEndServerURL + "/add/outgoingRequisitionSave";
                       axios
                           .post(url, postData, {
                               headers: {
                                   Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                               },
                           })
                           .then((response) => {
                               let caseRouteUrl =
                                   backEndServerURL + "/case/route/" + this.state.appId;
                               axios
                                   .get(caseRouteUrl, {
                                       headers: {
                                           Authorization: `Bearer ${sessionStorage.getItem(
                                               "accessToken"
                                           )}`,
                                       },
                                   })
                                   .then((response) => {
                                       console.log("response is", response.data);
                                       this.setState({
                                           type: "success",
                                           title: "Successfully done!",
                                           notificationMessage: "Successfully done!",
                                           alert: true,
                                           loading: false,
                                       });
                                       setTimeout(function () {
                                           window.location.reload()
                                       }, 3000);
                                   })
                                   .catch((error) => {
                                       console.log(error);
                                       this.setState({
                                           loading: false,
                                       });
                                   });
                           })
                           .catch((error) => {
                               console.log(error);
                           });
                   })
                   .catch((error) => {
                       console.log("Cheque Book request error");
                   });
    }
    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                confirmationAlert: false,
                loading: true,
            });
          // all submit
          this.functionAfterSubmit();
        } else {
            this.setState({
                confirmationAlert: false,
                loading: false,
            })
        }
    };
    render() {
        return (
            <Card>
                <CardHeader
                    style={{
                        backgroundColor: "#142398",
                    }}
                >
                    {this.renderHeader()}
                </CardHeader>
                <CardBody>
                    <div>
                        <Dialog fullWidth="true" maxWidth="sm" open={this.state.loading}>
                            <DialogContent>
                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                // className={classes.modal}
                                // classes={{paper: classes.dialogPaper}}
                                open={this.state.confirmationAlert}>
                                <DialogContent 
                                // className={classes.dialogPaper}
                                >
                                    <ConfirmationModal
                                        closeModal={this.closeConfirmation}
                                        title={this.state.title}
                                        cancelHide={true}
                                    />
                                </DialogContent>
                            </Dialog>
                        <ThemeProvider theme={theme}>
                            <Grid container>
                                {this.renderNotification()}
                                {this.renderJsonForm()}
                                <br/>
                                <Grid item xs="12"></Grid>
                                <Grid item xs={12}>
                                    {this.renderButton()}
                                </Grid>
                            </Grid>
                        </ThemeProvider>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

export default OutgoingDispatchIntiator;
