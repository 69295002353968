import React from 'react';
import validator from "validator";
import EncryptStorage from "encrypt-storage";

class LocalstorageEncrypt {
    static encryptStorageFunction = new EncryptStorage('secret-key', {
        prefix: '@instance2',
    });
}

export default LocalstorageEncrypt;
