import React, {Component} from 'react'
import {Col, Row} from 'react-bootstrap'
import '../../style.css'
import {Bar, BarChart, CartesianGrid, Legend, Pie, PieChart, Tooltip, XAxis, YAxis} from 'recharts';
import theme from "../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles/index";
import CardBody from "../Card/CardBody";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "../Table/Table";
import axios from "axios";
import {backEndServerURL} from "../../Common/Constant";

// const dataForPieChart = [
//     {name: 'FDR Issuance', value: 10, fill: '#dc3545'},
//     {name: 'FDR Maintenance', value: 16, fill: '#28a745'},
//     {name: 'FDR Encashment', value: 20, fill: '#17a2b8'},

// ];

const mainStyle = {border: '1px solid black', margin: '0 auto!important', width: '100%'};

// const data = [
//     {
//         name: 'sunday', customer: 50,
//     },
//     {
//         name: 'monday', customer: 20,
//     },
//     {
//         name: 'tuesday', customer: 35,
//     },
//     {
//         name: 'wednesday', customer: 18,
//     },
//     {
//         name: 'thursday', customer: 18,
//     },
// ];


class FDRDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mainObj: {},
            dataForPieChart: [
                {name: 'FDR Issuance', value: 10, fill: '#dc3545'},
                {name: 'FDR Maintenance', value: 16, fill: '#28a745'},
                {name: 'FDR Encashment', value: 20, fill: '#17a2b8'},

            ],
            getTableData: [
                ["Open", "15", "10", "5", "13"],
                ["Locked", "16", "10", "5", "19"],
                ["Saved", "17", "16", "5", "13"],
                ["Forwarded/Assigned", "12", "10", "5", "13"],
                ["In Progress", "15", "10", "5", "13"],
                ["Returned", "16", "10", "5", "19"],
                ["Return Backed", "17", "16", "5", "13"],
                ["Closed", "12", "10", "5", "13"],
                ["Cancelled", "15", "10", "5", "13"],
                ["Solved/Completed", "16", "10", "5", "19"],
            ],
            oneRowBoxColor: ['#98AFC7', '#89C35C', '#EDDA74', '#7A5DC7'],
            twoRowBoxColor: ['#89C35C', '#6AFB92', '#FFE5B4', '#41A317'],
            threeRowBoxColor: ['#C35817', '#7F5A58', '#EDDA74', '#87AFC7'],
            fourRowBoxColor: ['#C36241', '#77BFC7', '#C48793', '#E18B6B'],
            oneBoxtitel: ['FDR Account Issuance', 'FDR Account Encashment', 'FDR Account Maintenance', 'Dependent Request'],
            twoBoxTitel: ['Prioirty Request', 'FDR Opening Without CASA Request', 'Indivisual Customre Request', 'Non-Indivisual Customre Request'],
            threedBoxTitel: ['Deferral/Waiver Request', 'Urgency High Request', 'Deceased Payment Request', 'Old Encashment Request'],
            fourBoxTitel: ['FDR Encashment & Payment thru EFT', 'FDR Encashment & Payment thru RTGS', 'Before Cut Off time Request', 'After Cut Off time Request'],
            oneBoxData: ['10', '18', '30', '12'],
            twoBoxData: ['10', '12', '12', '10'],
            threeBoxData: ['17', '15', '25', '10'],
            fourBoxData: ['102', '10', '130', '140'],
            totalCellWidth: ['25%', '25%', '25%', '25%', '10%'],
            totalData: ["Total", "170", "120", "160", "173"],
            data: [
                {
                    name: 'sunday', customer: 50,
                },
                {
                    name: 'monday', customer: 20,
                },
                {
                    name: 'tuesday', customer: 35,
                },
                {
                    name: 'wednesday', customer: 18,
                },
                {
                    name: 'thursday', customer: 18,
                },
            ],

        }
    }

    clickPie = () => {
        // alert('hi')
    };

    clickBar = () => {
        //alert('Clicked')
    };

    createTable = (a, b, c, d, e, f, g, h, i, j, k) => {
        return ([
            a, b, c, d, e, f, g, h, i, j, k
        ])
    };

    componentDidMount() {
        this.createTable('f', 'f', 'g', 'b');
        console.log(sessionStorage);

        axios
            .get(backEndServerURL + "/dashboard/fdr", {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                console.log(response);
                let data = response.data;
                let encashment = data.encashment;
                let issuance = data.issuance;
                let maintenance = data.maintenance;
                this.setState({
                    mainObj: data,
                    getTableData: [
                        ["Open", issuance.open, encashment.open, maintenance.open, (encashment.open + issuance.open + maintenance.open)],
                        ["Locked", issuance.locked, encashment.locked, maintenance.locked, (encashment.locked + issuance.locked + maintenance.locked)],
                        ["Saved", issuance.save, encashment.save, maintenance.save, (encashment.save + issuance.save + maintenance.save)],
                        ["Forwarded/Assigned", issuance.locked, encashment.locked, maintenance.locked, (encashment.locked + issuance.locked + maintenance.locked)],
                        ["In Progress", 0, 0, 0, 0],
                        ["Returned", issuance.return, encashment.return, maintenance.return, (encashment.return + issuance.return + maintenance.return)],
                        ["Return Backed", 0, 0, 0, 0],
                        ["Closed", issuance.closed, encashment.closed, maintenance.closed, (encashment.closed + issuance.closed + maintenance.closed)],
                        ["Cancelled", 0, 0, 0, 0],
                        ["Solved/Completed", issuance.closed, encashment.closed, maintenance.closed, (encashment.closed + issuance.closed + maintenance.closed)],
                    ],
                    dataForPieChart: [
                        {name: 'FDR Issuance', value: data.totalIssuance, fill: '#dc3545'},
                        {name: 'FDR Maintenance', value: data.totalMaintenance, fill: '#28a745'},
                        {name: 'FDR Encashment', value: data.totalEncashment, fill: '#17a2b8'},

                    ],
                    oneBoxData: [data.totalIssuance, data.totalEncashment, data.totalMaintenance, 0],
                    data: [
                        {
                            name: 'sunday', customer: data.sunday === undefined ? 0 : data.sunday,
                        },
                        {
                            name: 'monday', customer: data.monday === undefined ? 0 : data.monday,
                        },
                        {
                            name: 'tuesday', customer: data.tuesday === undefined ? 0 : data.tuesday,
                        },
                        {
                            name: 'wednesday', customer: data.wednesday === undefined ? 0 : data.wednesday,
                        },
                        {
                            name: 'thursday', customer: data.thursday === undefined ? 0 : data.thursday,
                        },
                    ],
                });
                let fdrOpening = 0, fdrMaintenance = 0, fdrEncashment = 0, total = 0;
                this.state.getTableData.map((ob) => {
                    console.log(ob);
                    fdrOpening = fdrOpening + ob[1];
                    fdrMaintenance = fdrMaintenance + ob[2];
                    fdrEncashment = fdrEncashment + ob[3];
                    total = total + ob[4];
                });
                this.setState({
                    totalData: ["Total", fdrOpening, fdrEncashment, fdrMaintenance, total],
                })

            }).catch(error => {
            console.log(error);
        });


    }

    renderTableData = () => {

        return (
            <div style={{border: '1px solid black', margin: '0 auto', width: '100%'}}>
                <div>
                    <h3 style={{
                        padding: "10px",
                        backgroundColor: '#17a2b8 ',
                        textAlign: 'left',
                        color: '#fff'
                    }}>Statistics</h3>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Status", "FDR Opening Request", "FDR Encashment Request", "FDR Maintenance Request", "Total"]}
                        tableData={this.state.getTableData}
                        style={{paddingRight: '10px'}}
                        tableAllign={['center', 'center', 'center', 'center', 'center']}
                    />

                    <br/>
                    {this.totalRow()}
                </div>
            </div>

        )
    };

    handleLabel = ({cx, cy, midAngle, innerRadius, outerRadius, value, index}) => {
        console.log("handling label?");
        const RADIAN = Math.PI / 180;
        // eslint-disable-next-line
        const radius = 25 + innerRadius + (outerRadius - innerRadius);
        // eslint-disable-next-line
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        // eslint-disable-next-line
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill="#8884d8"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
            >
                {this.state.dataForPieChart[index].name} ({value})
            </text>
        );
    };


    render() {
        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        date = date.toString().length === 1 ? '0' + date : date + '';
        month = month.toString().length === 1 ? '0' + month : month + '';
        return (
            <Paper square>
                <CardBody>
                    <Grid container>
                        <ThemeProvider theme={theme}>
                            <React.Fragment>
                                <Row style={{width: '100%'}}>
                                    <Col style={{textAlign: 'left'}} md={6}><h4 style={{color: "white"}} ><u><strong>DASHBOARD-FDR
                                        Unit</strong></u></h4></Col>
                                    <Col style={{textAlign: 'right'}}
                                         md={6}><strong>Date:</strong>{" " + date + "-" + month + "-" + year}</Col>
                                </Row>
                                <Row style={{paddingLeft: '30px', paddingRight: '30px'}}>
                                    <Col md={5}>
                                        <Row>
                                            <h5 style={{
                                                textAlign: 'center',
                                                marginTop: '5px',
                                                paddingRight: '100px',
                                                width: '100%'
                                            }}>Request Summary</h5>
                                            <PieChart width={600} height={400} margin={0} padding={0}>
                                                <Pie dataKey="value" isAnimationActive={true}
                                                     data={this.state.dataForPieChart}
                                                     cx={250} cy={200}
                                                     outerRadius={80}
                                                     fill="#8884d8" label={this.handleLabel} onClick={this.clickPie}/>
                                                <Tooltip/>
                                            </PieChart>
                                        </Row>
                                        <Row>
                                            <h5 style={{
                                                textAlign: 'center',
                                                marginTop: '5px',
                                                paddingRight: '100px',
                                                width: '100%'
                                            }}>Completed Request Summary</h5>
                                            <BarChart
                                                width={520}
                                                height={300}
                                                data={this.state.data}
                                                margin={{
                                                    top: 5, right: 100, left: 0, bottom: 5,
                                                }}
                                                // style={{border: '1px solid black',width:'100%',margin:'auto'}}
                                            >
                                                <CartesianGrid strokeDasharray="3 3"/>
                                                <XAxis dataKey="name"/>
                                                <YAxis/>
                                                <Tooltip/>
                                                <Legend/>
                                                <Bar dataKey="customer" fill="#8884d8" onClick={this.clickBar}/>
                                            </BarChart>
                                        </Row>
                                    </Col>
                                    <Col md={7}>
                                        <Row>
                                            {
                                                this.renderTableData()
                                            }
                                        </Row>
                                        <br></br>
                                        {this.boxOneRow(this.state.oneBoxtitel, this.state.oneBoxData, this.state.oneRowBoxColor)}
                                        <br></br>
                                        {this.boxOneRow(this.state.twoBoxTitel, this.state.twoBoxData, this.state.twoRowBoxColor)}
                                        <br></br>
                                        {this.boxOneRow(this.state.threedBoxTitel, this.state.threeBoxData, this.state.threeRowBoxColor)}
                                        <br></br>
                                        {this.boxOneRow(this.state.fourBoxTitel, this.state.fourBoxData, this.state.fourRowBoxColor)}
                                    </Col>

                                </Row>

                            </React.Fragment>
                        </ThemeProvider>
                    </Grid>
                </CardBody>
            </Paper>
        )
    }

    boxOneRow(mapTitel, mapData, mapColor) {
        return (
            <Row style={mainStyle}>
                {this.boxFillUp(mapTitel, mapData, mapColor)}
            </Row>
        )
    }

    boxFillUp(mapTitel, mapData, mapColor) {
        console.log(mapTitel);
        console.log(mapData);


        return mapTitel.map((data, index) => {
            return (
                <div style={{
                    padding: '3px',
                    width: '25%',
                    background: mapColor[index],
                    border: '1px solid black'
                }}>
                    <h5><span>{data}</span>- {mapData[index]}</h5>
                </div>
            )
        });
    }

    totalRow() {
        return this.state.totalData.map((data, index) => {

            return (
                <td style={{
                    textAlign: 'center',
                    display: 'black',
                    width: this.state.totalCellWidth[index],
                    background: '#17a2b8',
                    padding: '5px',
                    border: '1px solid black'
                }}><strong>{data}</strong></td>
            )
        });
    }
}

export default FDRDashboard

