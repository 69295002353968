import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Table from "../../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import Notification from "../../../NotificationMessage/Notification";
import loader from "../../../../Static/loader.gif";
import MyValidation from "../../../JsonForm/MyValidation";
import SignatureButton from "../SignatureButton";
import Label from "./Label";
import PreviewButton from "../PreviewButton";
import UploadComponent from "../casa/UploadComponent";
import CBNotFound from "../../CASA/CBNotFound";


let dpsRequestType = [
    {
        "varName": "closerRequestName",
        "type": "select",
        "label": "Input Transfer Account Number",
        "grid": 3,
        "required": true,
        "enum": [
            'Own AC',
            'Third Party AC',
            'Payment to GL AC',
            'Payorder',
            'EFT Payment',
            'RTGS Payment',
        ]
    }];
const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};


let accountMaintenanceSearch = [
    {
        "varName": "eqmNumber",
        "type": "text",
        "label": "EQM Number",
        "validation": "numeric",
        "grid": 2,

    },
    {
        "varName": "accountNumber",
        "type": "textDedup",
        "required": true,
        "label": "Account No",
        "validation": "numeric",
        "grid": 2,
    },
    {
        "varName": "dpsCloserRequest",
        "type": "select",
        "label": "Select Request Type",
        "grid": 2,
        "required": true,
        "enum": [
            'DPS Closure',
            'Deceased DPS Closure',
            'DPS Encashment Certificate',
        ]
    }
];

let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];

let closerRequestForm = [

    {
        "varName": "inputAccountNumber",
        "type": "text",
        "label": "Account Number",
        "validation": "numeric",
        "grid": 3,
        "conditionalOrValue": true,
        "conditionalVarName": "closerRequestName",
        "conditionalOrList": [
            'Own AC',
            'Third Party AC',
            'Payment to GL AC',
            'Payorder',
            'EFT Payment',
            'RTGS Payment'
        ],
    },
    {
        "varName": "inputAccountName",
        "type": "text",
        "label": "Account Name",
        "validation": "string",
        "readOnly": true,
        "grid": 3,
        "conditionalOrValue": true,
        "conditionalVarName": "closerRequestName",
        "conditionalOrList": [
            'Own AC',
            'Third Party AC',
            'Payment to GL AC',
            'Payorder',
            'EFT Payment',
            'RTGS Payment'
        ],
    }
];

class CloserMaintenance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cbNotFound: false,
            inputData: {},
            appId: '',
            message: "",
            getData: false,
            varValue: {},
            caseId: "",
            title: "",
            notificationMessage: "",
            alert: false,
            type: [],
            fileUploadData: [],
            selectedDate: {},
            showValue: false,
            loading: true,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            returnDataBm: false,
            maintenanceRequest: false,
            previewDocument: false,
        }
    }


    getSearchvalue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    componentDidMount() {

        if (this.state.inputData["csBearer"] === "NO") {
            this.state.inputData["csBearer"] = "";
        }

        this.setState({
            loading: false
        });

    }

    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.renderStartCase(event);

        }

    };

    renderStartCase = (e) => {
        e.preventDefault();
        let error = MyValidation.defaultValidation(accountMaintenanceSearch, this.state);
        this.forceUpdate();
        if (error) {
            return 0;
        } else {
            this.setState({
                loading: true,
            });
            let getAccountUrl = backEndServerURL + "/getAccountInfo/" + this.state.inputData.accountNumber;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    if (response.data.message === "No Account Found!") {
                        this.state.varValue['eqmNumber'] = this.state.inputData.eqmNumber;
                        this.state.varValue['accountNumber'] = this.state.inputData.accountNumber;
                        this.state.varValue['dpsCloserRequest'] = this.state.inputData.dpsCloserRequest;
                        this.setState({
                            loading: false,
                            cbNotFound: true,
                            title: 'No Account Found!'
                        });
                    } else {
                        let inputData = response.data;
                        inputData.eqmNumber = this.state.inputData.eqmNumber;
                        inputData.accountNumber = this.state.inputData.accountNumber;
                        inputData.dpsCloserRequest = this.state.inputData.dpsCloserRequest;
                        this.setState({
                            inputData: inputData,
                            varValue: inputData
                        });

                        this.caseStart();
                    }

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    });
                });
        }
    };

    caseStart = () => {
        if (this.state.inputData.accountNumber) {
            let url = backEndServerURL + "/startCase/cs_maintenance";
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        appId: response.data.id,
                        showValue: true,
                        getData: true,
                        getDocument: true,
                        loading: false,
                        searchData: true
                    });

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                    })
                })
        }
    };


    updateComponent = () => {
        this.forceUpdate();
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    renderCustomerDetails = () => {
        if (this.state.inputData.accountNumber && this.state.searchData === true) {
            return (
                <Label cbNumber={this.state.inputData.cbNumber} classes={this.props}
                       accountNumber={this.state.inputData.accountNumber} stopLoading={this.stopLoading} create={true}/>
            )
        }
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        <React.Fragment>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
                            }
                        </React.Fragment>
                    </ThemeProvider>
                </Grid>
            )
        }
    };
    renderSearchForm = (root) => {
        return (
            <Grid item xs={12}>
                <Grid container>
                    <ThemeProvider theme={theme}>

                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, accountMaintenanceSearch, this.updateComponent, this.onKeyDownForDedup)
                        }

                        <button
                            style={{
                                marginTop: "18px",
                                height: '30px'
                            }}
                            onClick={this.renderStartCase}
                            className="btn btn-danger btn-sm">
                            Search
                        </button>
                        <Grid item xs={12}>
                        </Grid>
                    </ThemeProvider>
                </Grid>
            </Grid>
        )
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    handleSubmitButton = () => {
        if (this.state.getData) {
            return (
                <ThemeProvider theme={theme}>
                    <Grid>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{}}
                            onClick={this.handleSubmit}
                        >
                            Submit
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                            className="btn btn-outline-info btn-sm"
                            style={{}}
                            onClick={this.handleSubmitDraft}
                        >
                            Save
                        </button>
                    </Grid>
                </ThemeProvider>
            )
        }
    };


    handleSubmit = (event) => {
        event.preventDefault();
        let errorTwo = MyValidation.defaultValidation(closerRequestForm, this.state);
        this.forceUpdate();
        if (errorTwo === true) {
            return 0;
        } else {

            var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.csRemarks + "/" + this.state.appId;
            axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error)
                });
            var variableSetUrl = backEndServerURL + "/variables/" + this.state.appId;
            let data = this.state.inputData;
            data.cs_bearer = "bm_approval";
            data.csRemarks = undefined;
            data.serviceType = "Maintenance";
            data.subServiceType = "AccountMaintenance";
            data.maintenanceRequest = this.state.maintenanceRequest;

            if ((this.state.inputData.closerRequestType = "DPS Closure") || (this.state.inputData.closerRequestType = "DPS Encashment Certificate")) {
                data.category = "DPS Closer";
            } else if (this.state.inputData.closerRequestType === "Deceased DPS Closure") {
                data.category = "Deceased DPS Closer";
            }

            data.subCategory = "Maintenance";
            data.maintenanceValueRequestType = "Closer";

            axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                        var url = backEndServerURL + "/case/route/" + this.state.appId;

                        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                this.setState({
                                    title: "Successful!",
                                    notificationMessage: "Successfully Routed!",
                                    alert: true,
                                });

                                if (this.state.inputData.maker_approval === "RETURN" || this.state.inputData.returnDataBm === true) {
                                    this.props.closeModal();
                                } else {
                                    setTimeout(function () {
                                        window.location.reload()
                                    }, 1000);
                                }


                            })
                            .catch((error) => {
                                if (error.response && error.response.status === 452) {
                                    Functions.removeCookie();
                                    this.setState({
                                        redirectLogin: true
                                    })
                                }
                            });
                    }
                )
                .catch((error) => {
                    console.log(error)
                });
        }
    };

    handleSubmitDraft = (event) => {
        event.preventDefault();
        let errorTwo = MyValidation.defaultValidation(closerRequestForm, this.state);
        this.forceUpdate();
        if (errorTwo === true) {
            return 0;
        } else {
            var variableSetUrl = backEndServerURL + "/save/" + this.state.appId;
            let data = this.state.inputData;
            data.serviceType = "Maintenance";
            data.subServiceType = "AccountMaintenance";
            if ((this.state.inputData.closerRequestType = "DPS Closure") || (this.state.inputData.closerRequestType = "DPS Encashment Certificate")) {
                data.category = "DPS Closer";
            } else if (this.state.inputData.closerRequestType === "Deceased DPS Closure") {
                data.category = "Deceased DPS Closer";
            }
            data.subCategory = "Maintenance";
            data.maintenanceValueRequestType = "Closer";
            axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    if (this.state.inputData.maker_approval === "RETURN" || this.state.inputData.returnDataBm === true) {
                        this.props.closeModal();
                    }
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Draft!",
                        alert: true
                    });
                    this.close()
                })
                .catch((error) => {
                    console.log(error)
                });
        }

    };

    previewDocument = () => {
        this.setState({
            previewDocument: true
        })
    };


    signature = () => {
        if (this.state.searchData === true && this.state.getData) {
            return (
                <React.Fragment>
                    {
                        this.previewButton()
                    }
                </React.Fragment>
            )
        }
    };

    requestForm = () => {
        if (this.state.getData) {
            return (
                <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        <React.Fragment>
                            <br/>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, closerRequestForm, this.updateComponent)
                            }
                        </React.Fragment>
                    </ThemeProvider>
                </Grid>
            )
        }
    };


    renderMaintenanceRequest = () => {
        if (this.state.getData) {
            return (
                <React.Fragment>
                    <Grid container spacing={1}>
                        <ThemeProvider theme={theme}>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, dpsRequestType, this.updateComponent)
                            }
                        </ThemeProvider>
                    </Grid>
                </React.Fragment>

            )
        }
    };


    previewButton = () => {

        return (
            <React.Fragment>
                <Grid container spacing={0}>
                    <SignatureButton accountNumber={this.state.inputData.accountNumber} classes={this.props}/>
                    &nbsp;&nbsp;
                    <PreviewButton appId={this.state.appId} classes={this.props}/>
                </Grid>
            </React.Fragment>
        )

    };

    uploadFunction = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return <UploadComponent appId={this.state.appId} classes={classes}/>
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    renderCloserInformation = () => {
        if (this.state.inputData.dpsCloserRequest && this.state.inputData.accountNumber && this.state.searchData === true) {
            return (
                <>
                    {this.renderCustomerDetails()}
                    <br/>
                    {this.signature()}
                    <br/>
                    {this.renderMaintenanceRequest()}
                    {this.requestForm()}
                    <br/>
                    {this.uploadFunction()}
                    {this.renderRemarks()}
                    <br/>
                    {this.handleSubmitButton()}
                </>
            )
        }
    };

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);

        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <Card>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >DPS/Scheme Closer Maintenance</h4>
                    </CardHeader>
                    <CardBody>
                        {this.renderSearchForm(classes.root)}
                        <br/>
                        {this.renderCloserInformation()}
                        {this.renderNotification()}
                    </CardBody>
                </Card>
            );
        }
    }
}

export default withStyles(styles)(CloserMaintenance);
