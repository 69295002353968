import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL, frontEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import {ThemeProvider} from "@material-ui/styles";
import TextFieldComponent from "../../JsonForm/TextFieldComponent";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import SelectComponent from "../../JsonForm/SelectComponent";
import CloseIcon from '@material-ui/icons/Close';

import Table from "../../Table/Table";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import AccountNoGenerate from "../AccountNoGenerate";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import loader from "../../../Static/loader.gif";
import FileMapping from "../CommonComponent/FileMapping";
import FileMappingEdit from "../CommonComponent/FileMappingEdit";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CheckBox from "@material-ui/core/Checkbox/Checkbox";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ErrorModal from "../CommonComponent/ErrorModal";
import AccountAndCbNumberGenerate from "../AccountAndCbNumberGenerate";
import DateComponentDeferal from "../../JsonForm/DateComponentDeferal";
import WaiverList from "../CommonComponent/WaiverList";
import ConfirmAlert from "../CommonComponent/ConfirmAlert";
import Redirect from "react-router-dom/Redirect";
import FlowSummeryButton from "../CommonComponent/FlowSummeryButton";
import {CSJsonFormForCasaServiceDPS, CSJsonFormForFDRService} from "../WorkflowJsonForm4";
import ConfirmAlertImage from "../CommonComponent/ConfirmAlertImage";
import AccountStatement from "../fdr/AccountStatement";
import FunctionForInputGetAndSet from "../fdr/FunctionForInputGetAndSet";
import Button from "@material/react-button";
import SdnAndDedupeResultShowing from "../CommonComponent/SdnAndDedupeResultShowing";
import AccountOpeningForm from "../AccountOpeningForm";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";

const tenureDay = [

    {title: '90'},
    {title: '180'},
    {title: '182'},
    {title: '362'},
    {title: '365'},


];
const tenureMonth = [

    {title: '1'},
    {title: '3'},
    {title: '6'},
    {title: '12'},
    {title: '24'},
    {title: '36'}


];
const smsAlertDropdown = [

    {title: 'Y'},
    {title: 'N'},


];
const arrayListIndividual = [

    {label: 'AOF1'},
    {label: 'AOF2'},
    {label: 'AOF3'},
    {label: 'AOF4'},
    {label: 'AOF5'},
    {label: 'AOF6'},
    {label: 'AOF7'},
    {label: 'AOF8'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'TP'},
    {label: 'IIF1'},
    {label: 'IIF2'},
    {label: 'NID'},
    {label: 'PASSPORT'},
    {label: 'DRIVING LICENSE'},
    {label: 'BIRTH CERTIFICATE'},
    {label: 'NOMINEE NID 1'},
    {label: 'NOMINEE NID 2'},
    {label: 'NOMINEE NID 3'},
    {label: 'NOMINEE NID 4'},
    {label: 'NOMINEE NID 5'},
    {label: 'NOMINEE NID 6'},
    {label: 'NOMINEE PASSPORT 1'},
    {label: 'NOMINEE PASSPORT 2'},
    {label: 'NOMINEE PASSPORT 3'},
    {label: 'NOMINEE PASSPORT 4'},
    {label: 'NOMINEE PASSPORT 5'},
    {label: 'NOMINEE DRIVING LICENSE 1'},
    {label: 'NOMINEE DRIVING LICENSE 2'},
    {label: 'NOMINEE DRIVING LICENSE 3'},
    {label: 'NOMINEE DRIVING LICENSE 4'},
    {label: 'NOMINEE DRIVING LICENSE 5'},
    {label: 'NOMINEE BIRTH CERTIFICATE 1'},
    {label: 'NOMINEE BIRTH CERTIFICATE 2'},
    {label: 'NOMINEE BIRTH CERTIFICATE 3'},
    {label: 'NOMINEE BIRTH CERTIFICATE 4'},
    {label: 'NOMINEE BIRTH CERTIFICATE 5'},
    {label: 'SIGNATURE CARD 1'},
    {label: 'SIGNATURE CARD 2'},
    {label: 'SIGNATURE CARD 3'},
    {label: 'SIGNATURE CARD 4'},
    {label: 'SIGNATURE CARD 5'},
    {label: 'OTHERS'},

];
let welcomeReceiptionSelection = {
    "varName": "welcomeLetterRecipientSelection",
    "type": "select",
    "enum": [],
    "label": "Welcome Letter Recipient Selection",
    "grid": 2,

};
const numberOfDebitCard = [

    {title: '1'},
    {title: '2'},
    {title: '3'},
    {title: '4'},
    {title: '5'},
    {title: '6'},
    {title: '7'}


];
const arrayListIndividualCondition = [

    {label: 'AOF1'},
    {label: 'AOF2'},
    {label: 'AOF3'},
    {label: 'AOF4'},
    {label: 'AOF5'},
    {label: 'AOF6'},
    {label: 'AOF7'},
    {label: 'AOF8'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'TP'},
    {label: 'IIF1'},
    {label: 'IIF2'},
    {label: 'NID'},

];

let arrayListNonIndividual = [
    {label: 'AOF1'},
    {label: 'AOF2'},
    {label: 'AOF3'},
    {label: 'AOF4'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'TP'},
    {label: 'SIGNATURE CARD 1'},
    {label: 'SIGNATURE CARD 2'},
    {label: 'SIGNATURE CARD 3'},
    {label: 'SIGNATURE CARD 4'},
    {label: 'SIGNATURE CARD 5'},
    {label: 'SIGNATURE CARD 6'},
    {label: 'SIGNATURE CARD 7'},
    {label: 'SIGNATURE CARD 8'},
    {label: 'SIGNATURE CARD 9'},
    {label: 'SIGNATURE CARD 10'},
    {label: 'SIGNATURE CARD 11'},
    {label: 'SIGNATURE CARD 12'},
    {label: 'SIGNATURE CARD 13'},
    {label: 'SIGNATURE CARD 14'},
    {label: 'SIGNATURE CARD 15'},
    {label: 'OTHERS'},


];
let arrayListSignatureCard = [
    {label: 'SIGNATURE CARD 1'},
    {label: 'SIGNATURE CARD 2'},
    {label: 'SIGNATURE CARD 3'},
    {label: 'SIGNATURE CARD 4'},
    {label: 'SIGNATURE CARD 5'},
    {label: 'SIGNATURE CARD 6'},
    {label: 'SIGNATURE CARD 7'},
    {label: 'SIGNATURE CARD 8'},
    {label: 'SIGNATURE CARD 9'},
    {label: 'SIGNATURE CARD 10'},
    {label: 'SIGNATURE CARD 11'},
    {label: 'SIGNATURE CARD 12'},
    {label: 'SIGNATURE CARD 13'},
    {label: 'SIGNATURE CARD 14'},
    {label: 'SIGNATURE CARD 15'},

];
let arrayListAllSignatureCard = [
    {label: 'SIGNATURE CARD 1'},
    {label: 'SIGNATURE CARD 2'},
    {label: 'SIGNATURE CARD 3'},
    {label: 'SIGNATURE CARD 4'},
    {label: 'SIGNATURE CARD 5'},
    {label: 'SIGNATURE CARD 6'},
    {label: 'SIGNATURE CARD 7'},
    {label: 'SIGNATURE CARD 8'},
    {label: 'SIGNATURE CARD 9'},
    {label: 'SIGNATURE CARD 10'},
    {label: 'SIGNATURE CARD 11'},
    {label: 'SIGNATURE CARD 12'},
    {label: 'SIGNATURE CARD 13'},
    {label: 'SIGNATURE CARD 14'},
    {label: 'SIGNATURE CARD 15'},
];
let arrayListNonIndividualCondition = [
    {label: 'AOF1'},
    {label: 'AOF2'},
    {label: 'AOF3'},
    {label: 'AOF4'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'TP'},


];
let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};


var deferalOther =
    {
        "varName": "deferalOther",
        "type": "text",
        "label": "Remarks",

    };
var waiverOther =
    {
        "varName": "waiverOther",
        "type": "text",
        "label": "Remarks",

    };

let typeofDeferralDocs = {
    "varName": "typeofDeferralDocs",
    "type": "select",
    "label": "Type of Deferral Docs",
    "enum": [
        "Regulatory",
        "Non Regulatory",
    ],
};
let typeofWaiverDocs = {
    "varName": "typeofWaiverDocs",
    "type": "select",
    "label": "Type of Waiver Docs",
    "enum": [
        "Regulatory",
        "Non Regulatory",
    ],
};
let deferal = {};
let waiver = {};

var date = {
    "varName": "expireDate",
    "type": "text",
    "minDate": true,
    "label": "Expire Date",

};

class Liability extends Component {

    constructor(props) {
        super(props);
        this.state = {
            SelectedData: false,
            upper: "UPERCASE",
            csDeferalPage: "",
            values: [],
            waiverValues: [],
            appId: '',
            csDataCapture: '',
            message: "",
            appData: {},
            getData: false, getDataSubServiceFDR: false, getDataSubServiceDPS: false,
            getNewCase: false,
            varValue: [],
            caseId: "",
            title: "",
            notificationMessage: "",
            app_uid: "-1",
            alert: false,
            redirectLogin: false,
            type: [],
            accountStatement: false,
            dueDate: '',
            inputData: {
                csDeferal: "NO",
                accountType: "Insta Pack",
                priority: "GENERAL"
            },
            fileUploadData: [],
            selectedDate: {},
            dropdownSearchData: {},
            AddDeferal: false,
            debitCard: "",
            showValue: false,
            getDeferalList: [],
            deferalNeeded: false,
            uploadModal: false,
            uploadSignatureModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            accountAndCbDetailsModal: false,
            loading: true,
            individualDataSaveId: '',
            sdnData: '',
            jointDataSaveId: '',
            companyDataSaveId: '',
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            uploadButtonClick: false,
            objectForDebitCard: [],
            getDebitCard: false,
            getInitiateData: false,
            handleConfirmBoolean: false,
            generateNewCB: 0,
            errorTittle: "",
            errorModalBoolean: false,
            dateShow: false,
            city: [],
            state: [],
            branchAllSolId: [],
            currency: [],
            country: [],
            getAllSchemeCode: [],
            getSchemeFlag: false,
            savingsSchemeCode: [],
            currentSchemeCode: [],
            fdrSchemeCode: [],
            generateNewAccountNumber: 0,
            setAllDeferalData: false,
            SDRegulatory: [],
            SDNonRegulatory: [],
            phoneNumCountryCodeList: [],
            redirectPage: false,
            confirmAlert: false,
            ConfirmAlertImage: false,
            actionType: "",
            instaDebitCardProductType: [],
            chequebookAllowed: "",
            instaDebitCardAllowed: "",
            alowApiCall: true,
            notificationMessgaeCommonDps: false,
            notificationMessgaeCommon: "",
            notificationMessgaeCommonFlag: true,
            fdrImageShowPopUp: true,
            signatureCardView: false,
            SdnAndDedupeResultShowing: false,

        };
        this.renderJointInformation();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static  dateConverter = (value) => {
        let splitData = (value).split(',')[0];
        return splitData.split('T')[0];

    };

    renderJointInformation = () => {
        for (let i = 0; i < this.props.jointAccountCustomerNumber; i++) {
            arrayListNonIndividual.push(
                {label: "Customer " + (i + 1) + ' IIF1'},
                {label: "Customer " + (i + 1) + ' IIF2'},
                {label: "Customer " + (i + 1) + ' NID'},
                {label: "Customer " + (i + 1) + ' PASSPORT'},
                {label: "Customer " + (i + 1) + ' DRIVING LICENSE'},
                {label: "Customer " + (i + 1) + ' BIRTH CERTIFICATE'},
                {label: "Customer " + (i + 1) + ' NOMINEE NID 1'},
                {label: "Customer " + (i + 1) + ' NOMINEE NID 2'},
                {label: "Customer " + (i + 1) + ' NOMINEE NID 3'},
                {label: "Customer " + (i + 1) + ' NOMINEE NID 4'},
                {label: "Customer " + (i + 1) + ' NOMINEE NID 5'},
                {label: "Customer " + (i + 1) + ' NOMINEE NID 6'},
                {label: "Customer " + (i + 1) + ' NOMINEE NID 7'},
                {label: "Customer " + (i + 1) + ' NOMINEE NID 8'},
                {label: "Customer " + (i + 1) + ' NOMINEE NID 9'},
                {label: "Customer " + (i + 1) + ' NOMINEE NID 10'},
                {label: "Customer " + (i + 1) + ' NOMINEE BIRTH CERTIFICATE 1'},
                {label: "Customer " + (i + 1) + ' NOMINEE BIRTH CERTIFICATE 2'},
                {label: "Customer " + (i + 1) + ' NOMINEE BIRTH CERTIFICATE 3'},
                {label: "Customer " + (i + 1) + ' NOMINEE BIRTH CERTIFICATE 4'},
                {label: "Customer " + (i + 1) + ' NOMINEE BIRTH CERTIFICATE 5'},
                {label: "Customer " + (i + 1) + ' NOMINEE BIRTH CERTIFICATE 6'},
                {label: "Customer " + (i + 1) + ' NOMINEE BIRTH CERTIFICATE 7'},
                {label: "Customer " + (i + 1) + ' NOMINEE BIRTH CERTIFICATE 8'},
                {label: "Customer " + (i + 1) + ' NOMINEE BIRTH CERTIFICATE 9'},
                {label: "Customer " + (i + 1) + ' NOMINEE BIRTH CERTIFICATE 10'},
            )
        }

    };

    handleChange = (event, value) => {

        this.state.inputData["csDeferal"] = value;
        this.updateComponent();
        if (value === "YES") {

            let values = [];
            values.push(Math.floor(Math.random() * 100000000000));

            this.setState({values: values, deferalNeeded: true});

        } else {
            this.setState({
                values: [],
                deferalNeeded: false
            })
        }
    };

    addDeferalForm() {

        return this.state.values.map((el, i) =>
            <React.Fragment>
                <Grid item xs={2}>
                    {
                        this.dynamicTypeOfDeferral(el)
                    }
                </Grid>
                <Grid item xs={4}>
                    {
                        this.dynamicDeferral(el)
                    }
                </Grid>
                <Grid item xs={2}>
                    {
                        this.dynamicDate(el)
                    }

                </Grid>
                <Grid item xs={2}>
                    {this.dynamicDeferralOther(el)}

                </Grid>
                <Grid item xs={2}>
                    <button
                        style={{
                            float: "left",
                            marginTop: "8px"
                        }}
                        className="btn btn-outline-primary"
                        type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                    >
                        Remove
                    </button>


                </Grid>
            </React.Fragment>
        )


    };

    addWaiverForm() {

        return this.state.waiverValues.map((el, i) =>
            <React.Fragment>
                <Grid item xs={2}>
                    {
                        this.dynamicTypeOfWaiver(el)
                    }
                </Grid>
                <Grid item xs={4}>
                    {
                        this.dynamicWaiver(el)
                    }
                </Grid>

                <Grid item xs={3}>
                    {this.dynamicWaiverOther(el)}

                </Grid>

                <Grid item xs={3}>
                    <button
                        style={{
                            float: "left",
                            marginTop: "8px"
                        }}
                        className="btn btn-outline-primary"
                        type='button' value='remove' onClick={this.removeClickWaiver.bind(this, el)}
                    >
                        Remove
                    </button>


                </Grid>

            </React.Fragment>
        )


    };

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        return clone;
        /* for (let prop in clone)
             if (clone[prop] === '' || clone[prop] === ' '  || clone[prop] === "null"   ||  clone[prop] === "undefined" )
                 delete clone[prop];
         return clone;*/
    };

    DedupDataSaveApi = (subServiceType,appId) => {

        if (subServiceType === "INDIVIDUAL" || subServiceType === "Individual A/C") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "appId": appId,
                "mandateDedupData": this.props.mandateindividualDedupData,
                "beneficiaryDedupData": this.props.beneficiaryindividualDedupData,
                "individualDedupData": this.props.individualDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(" dedup save data");
                    console.log(response.data);
                    this.setState({
                        individualDataSaveId: response.data
                    });
                    this.returnCaseStartAfterDataSet()
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Something went wrong,Please Try Again !",
                        loading: false,
                        getData: false,
                    });
                    return 0;
                })

        } else if (subServiceType === "Joint Account") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "appId": appId,
                "jointDedupData": this.props.jointDedupData,
                "mandateDedupData": this.props.mandateindividualDedupData,
                "beneficiaryDedupData": this.props.beneficiaryindividualDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(" dedup save data");
                    console.log(response.data);
                    this.setState({
                        jointDataSaveId: response.data
                    });
                    this.returnCaseStartAfterDataSet()
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Something went wrong,Please Try Again",
                        loading: false,
                        getData: false,
                    });
                    return 0;
                })

        } else if (subServiceType === "Proprietorship A/C" || subServiceType === "NONINDIVIDUAL") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "appId": appId,
                "mandateDedupData": this.props.mandateindividualDedupData,
                "beneficiaryDedupData": this.props.beneficiaryindividualDedupData,
                "individualDedupData": this.props.individualDedupData,
                "companyDedupData": this.props.companyDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    /*axios.post(Dedupurl, {
                        "companyDedupData": this.props.companyDedupData,
                        "dedupType": subServiceType
                    }, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
                        .then((response) => {
                            this.setState({
                                companyDataSaveId: response.data
                            })
                        })
                        .catch((error) => {
                             console.log(error)
                        })*/
                    console.log(" dedup save data r");
                    console.log(response.data);
                    this.setState({
                        individualDataSaveId: response.data
                    });
                    this.returnCaseStartAfterDataSet()
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Something went wrong,Please Try Again !",
                        loading: false,
                        getData: false,
                    });
                    return 0;
                })

        } else if (subServiceType === "Company Account") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "appId":appId,
                "jointDedupData": this.props.jointDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    axios.post(Dedupurl, {
                        "appId": appId,
                        "companyDedupData": this.props.companyDedupData,
                        "dedupType": subServiceType
                    }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            this.setState({
                                companyDataSaveId: response.data
                            });
                            this.returnCaseStartAfterDataSet()
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                errorModalBoolean: true,
                                errorTittle: "Something went wrong,Please Try Again !",
                                loading: false,
                                getData: false,
                            });
                            return 0;
                        });
                    console.log(" dedup save data");
                    console.log(response.data);
                    this.setState({
                        jointDataSaveId: response.data
                    })
                })
                .catch((error) => {
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Something went wrong,Please Try Again !",
                        loading: false,
                        getData: false,
                    });
                    return 0;
                    console.log(error)
                })

        } else {
            alert("please type select")
        }
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    numberToCharacter = (i) => {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    };

    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;
        console.log(value);
        console.log(getKeyValue);


        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            console.log(jsonObject);
            if (value === jsonObject.key) {
                return jsonObject;
                i = jsonArray.length;

            }
        }


    };

    apiDataSet = (data, inputData, type) => {
        if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {

            if (type === "cbExtraInformation") {
                inputData.priorityCenterCode = data.freecode1;
                inputData.primaryCBHolderforPriority = data.free_text_8;
                inputData.relationshipwithPrimaryCBHolder = data.free_text_9
            } else {


                inputData.cbNumber = this.props.getCustomerId;
                inputData.accountType = "Regular";
                inputData.accountTypes = "Regular";

                inputData.passport = data.passportNumber;
                inputData.gender = data.gender;

                if (data.MailingBLOCK !== undefined) {
                    inputData.SelectCommunicationAddress = data.MailingBLOCK.addrCategory;
                    inputData.communicationAddress1 = data.MailingBLOCK.addrLine1;
                    inputData.communicationAddress2 = data.MailingBLOCK.addrLine2;
                    inputData.postalCode3 = data.MailingBLOCK.postalCode;
                    inputData.state2 = this.findByVarNameGetKeyValue(data.MailingBLOCK.state, this.state.state);
                    inputData.city2 = this.findByVarNameGetKeyValue(data.MailingBLOCK.city, this.state.city);
                    inputData.country = this.findByVarNameGetKeyValue(data.MailingBLOCK.country, this.state.country)
                }
                inputData.nationality = this.findByVarNameGetKeyValue(data.nationality, this.state.country);

                if (data.BCBLOCK !== undefined) {

                    inputData.birthCertificateNo = data.BCBLOCK.referenceNum;

                }
                if (data.DLBLOCK !== undefined) {
                    inputData.drivingLicense = data.DLBLOCK.referenceNum;
                }

                /*     if (data.rmCode !== undefined) {

                              inputData.rmCodeApiCall = false;
                              let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + data.rmCode;
                             axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                 .then((response) => {
                                     console.log(response.data)
                                      inputData.rmCodeName = response.data;
                                      console.log(response.data)
                                      inputData.rmCodeApiCall = true;


                                 })
                                 .catch((error) => {
                                     console.log(error)
                                 })
                         }*/

                inputData.monitoringRMCode = data.monitoringRMCode;
                /*      if(data.monitoringRMCode!==undefined){


                               inputData.monitoringRMCodeApiCall = false;
                               let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData.monitoringRMCode;
                              axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                  .then((response) => {
                                      console.log(response.data)
                                       inputData.monitoringRmCodeName = response.data;
                                        inputData.monitoringRMCodeApiCall = true;

                                  })
                                  .catch((error) => {
                                      console.log(error)

                                  })
                          }*/


                //inputData.rmCodeName=;
                //inputData.rmCode = data.rmCode


                inputData.shortName = data.shortName;
                inputData.staffIndicator = data.staffFlag;
                inputData.staffIdNumber = data.staffEmployeeID;
                let custStatusForNormal = data.custStatus === "99999" || data.custStatus === "ADUP" || data.custStatus === "ADUPV" || data.custStatus === "BKP" || data.custStatus === "BLD" || data.custStatus === "HOIO" || data.custStatus === "IP" || data.custStatus === "MIN" || data.custStatus === "NOR" || data.custStatus === "PEP" || data.custStatus === "VIP";
                let custStatusForPriority = data.custStatus === "ADUPP" || data.custStatus === "HOIOP" || data.custStatus === "IPPR" || data.custStatus === "PEPPR" || data.custStatus === "PRIOR";
                let custStatusForStaff = data.custStatus === "ADUPS" || data.custStatus === "STAFF";
                if (data.COMMEMLBLOCK !== undefined) {
                    inputData.designatedEmail = data.COMMEMLBLOCK.emailInfo
                }

                if (custStatusForNormal === true) {
                    inputData.customerStatus = "Normal Customer"
                }
                else if (custStatusForStaff === true) {
                    inputData.customerStatus = "Staff"
                }
               else  if (custStatusForPriority === true) {
                    inputData.customerStatus = "Priority"
                }
               else {
                    inputData.customerStatus = "Normal Customer"
                }
                inputData.customerTitle = data.salutation;

                let getDob = data.birthDate;
                if (getDob !== null & getDob !== undefined) {
                    getDob = getDob.split('T')[0];
                }
                inputData.dob = getDob;
                inputData.selectedDate = getDob;


                inputData.customerName = data.customerName;
                if (data.COMMPH1BLOCK !== undefined) {
                    if (data.COMMPH1BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypeIndividual = "Local"
                    } else if (data.COMMPH1BLOCK.phoneNumCountryCode === "0") {
                        inputData.mobileTypeIndividual = "Overseas";
                        inputData.countryCodeIndividual = this.findByVarNameGetKeyValue(data.COMMPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)
                    } else {
                        //inputData.mobileTypeIndividual="Overseas"
                    }
                } else {
                    //inputData.mobileTypeIndividual="Local"
                }
                if (data.COMMPH2BLOCK !== undefined) {
                    if (data.COMMPH2BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypeIndividual2 = "Local"
                    } else if (data.COMMPH2BLOCK.phoneNumCountryCode === "0") {
                        inputData.mobileTypeIndividual2 = "Overseas";
                        inputData.countryCodeIndividual2 = this.findByVarNameGetKeyValue(data.COMMPH2BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)
                    } else {
                        //inputData.mobileTypeIndividual2="Overseas"
                    }
                } else {
                    //inputData.mobileTypeIndividual2="Local"
                }
                if (data.COMMPH1BLOCK !== undefined) {
                    // let getPhoneNumber = data.COMMPH1BLOCK.phoneNumLocalCode;
                    /* if (getPhoneNumber !== null && getPhoneNumber !== undefined) {
                         getPhoneNumber = new Array(getPhoneNumber.length - 11 + 1).join('')
                             + getPhoneNumber.slice(-11);
                     }*/
                    inputData.designatedMobileNumber = data.COMMPH1BLOCK.phoneNumLocalCode
                }
                if (data.COMMPH2BLOCK !== undefined) {

                    /*   if (getPhoneNumber1 !== null && getPhoneNumber1 !== undefined) {
                           getPhoneNumber1 = new Array(getPhoneNumber1.length - 11 + 1).join('')
                               + getPhoneNumber1.slice(-11);
                       }*/
                    inputData.phone2 = data.COMMPH2BLOCK.phoneNumLocalCode;
                    inputData.secondePhoneNumber = data.COMMPH2BLOCK.phoneNumLocalCode
                }
                if (data.NIDBLOCK !== undefined) {
                    inputData.nid = data.NIDBLOCK.referenceNum;
                }
                if (data.RSNIDBLOCK !== undefined) {
                    inputData.smartCard = data.RSNIDBLOCK.referenceNum;
                }
                if (data.PASSPORTBLOCK !== undefined) {
                    inputData.passport = data.PASSPORTBLOCK.referenceNum;
                }
                if (data.ETINBLOCK !== undefined) {
                    inputData.eTin = data.ETINBLOCK.referenceNum;
                }
                if (data.DLBLOCK !== undefined) {
                    inputData.drivingLicense = data.DLBLOCK.referenceNum;
                }
                if (data.BCBLOCK !== undefined) {

                    inputData.birthCertificateNo = data.BCBLOCK.referenceNum;

                }
                inputData.currency = this.findByVarNameGetKeyValue(data.currencyCode, this.state.currency);
                inputData.occupationCodeNormal = data.occupationCode;
                inputData.occupationCodePriority = data.occupationCode;
                inputData.occupationCodeStaff = data.occupationCode;
                inputData.occupationCode = data.occupationCode;
                inputData.occupationCodes = data.occupationCode
            }
        } else {

            if (type === "cbExtraInformation") {
                inputData.priorityCenterCode = data.freecode1;
                inputData.primaryCBHolderforPriority = data.free_text_8;
                inputData.relationshipwithPrimaryCBHolder = data.free_text_9
            } else {


                inputData.cbNumber = this.props.getCustomerId;
                inputData.accountType = "Regular";
                inputData.accountTypes = "Regular";
                inputData.accType = "CURRENT";
                if (data.TLBLOCK !== undefined) {
                    inputData.companyTradeLicense = data.TLBLOCK.referenceNum;
                }
                if (data.COIBLOCK !== undefined) {
                    inputData.certificateOfIncorporation = data.COIBLOCK.referenceNum;
                }
                if (data.COBBLOCK !== undefined) {
                    inputData.certificateOfCommencement = data.COBBLOCK.referenceNum;
                }
                if (data.REGBLOCK !== undefined) {
                    inputData.registrationNumber = data.REGBLOCK.referenceNum;
                }
                if (data.NGOBLOCK !== undefined) {
                    inputData.ngoBureauCertificate = data.NGOBLOCK.referenceNum;
                }
                if (data.SCTBLOCK !== undefined) {
                    inputData.societyRegistrationNumber = data.SCTBLOCK.referenceNum;
                }
                if (data.CLBBLOCK !== undefined) {
                    inputData.clubRegistrationNumber = data.SCTBLOCK.referenceNum;
                }
                if (data.GOVPBLOCK !== undefined) {
                    inputData.governmentPermissionLetter = data.GOVPBLOCK.referenceNum;
                }
                if (data.OTHCBLOCK !== undefined) {
                    inputData.otherIdCorporate = data.OTHCBLOCK.referenceNum;
                }
                if (data.MailingBLOCK !== undefined) {
                    inputData.communicationAddress1 = data.MailingBLOCK.addrLine1;
                    inputData.communicationAddress2 = data.MailingBLOCK.addrLine2;
                    inputData.postalCode3 = data.MailingBLOCK.postalCode;
                    inputData.state2 = this.findByVarNameGetKeyValue(data.MailingBLOCK.state, this.state.state);
                    inputData.city2 = this.findByVarNameGetKeyValue(data.MailingBLOCK.city, this.state.city);
                    inputData.country = this.findByVarNameGetKeyValue(data.MailingBLOCK.country, this.state.country)
                }

                /* if (data.BCBLOCK !== undefined) {

                     inputData.birthCertificateNo = data.BCBLOCK.referenceNum;

                 }
                 if (data.DLBLOCK !== undefined) {
                     inputData.drivingLicense = data.DLBLOCK.referenceNum;
                 }
         */


                inputData.monitoringRMCode = data.monitoringRMCode;


                inputData.shortName = data.shortName;
                if (data.COMMEMLBLOCK !== undefined) {
                    inputData.designatedEmail = data.COMMEMLBLOCK.emailInfo
                }

                let custStatusForNormal = data.custStatus === "99999" || data.custStatus === "ADUP" || data.custStatus === "ADUPV" || data.custStatus === "BKP" || data.custStatus === "BLD" || data.custStatus === "HOIO" || data.custStatus === "IP" || data.custStatus === "MIN" || data.custStatus === "NOR" || data.custStatus === "PEP" || data.custStatus === "VIP";
                let custStatusForPriority = data.custStatus === "ADUPP" || data.custStatus === "HOIOP" || data.custStatus === "IPPR" || data.custStatus === "PEPPR" || data.custStatus === "PRIOR";
                let custStatusForStaff = data.custStatus === "ADUPS" || data.custStatus === "STAFF";

                if (custStatusForNormal === true) {
                    inputData.customerStatus = "Normal Customer"
                }
               else if (custStatusForStaff === true) {
                    inputData.customerStatus = "Staff"
                }
                else if (custStatusForPriority === true) {
                    inputData.customerStatus = "Priority"
                }
                else {
                    inputData.customerStatus = "Normal Customer"
                }
                inputData.customerTitle = data.salutation;
                inputData.customerName = data.corporateName;
                if (data.COMMPH1BLOCK !== undefined) {
                    if (data.COMMPH1BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypeIndividual = "Local"
                    } else if (data.COMMPH1BLOCK.phoneNumCountryCode === "0") {
                        inputData.mobileTypeIndividual = "Overseas";
                        inputData.countryCodeIndividual = this.findByVarNameGetKeyValue(data.COMMPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)
                    } else {
                        //inputData.mobileTypeIndividual="Overseas"
                    }
                } else {
                    //inputData.mobileTypeIndividual="Local"
                }
                if (data.COMMPH2BLOCK !== undefined) {
                    if (data.COMMPH2BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypeIndividual2 = "Local"
                    } else if (data.COMMPH2BLOCK.phoneNumCountryCode === "0") {
                        inputData.mobileTypeIndividual2 = "Overseas";
                        inputData.countryCodeIndividual2 = this.findByVarNameGetKeyValue(data.COMMPH2BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)
                    } else {
                        //inputData.mobileTypeIndividual2="Overseas"
                    }
                } else {
                    //inputData.mobileTypeIndividual2="Local"
                }
                if (data.COMMPH1BLOCK !== undefined) {
                    // let getPhoneNumber = data.COMMPH1BLOCK.phoneNumLocalCode;
                    /* if (getPhoneNumber !== null && getPhoneNumber !== undefined) {
                         getPhoneNumber = new Array(getPhoneNumber.length - 11 + 1).join('')
                             + getPhoneNumber.slice(-11);
                     }*/
                    inputData.designatedMobileNumber = data.COMMPH1BLOCK.phoneNumLocalCode
                }
                if (data.COMMPH2BLOCK !== undefined) {

                    /*   if (getPhoneNumber1 !== null && getPhoneNumber1 !== undefined) {
                           getPhoneNumber1 = new Array(getPhoneNumber1.length - 11 + 1).join('')
                               + getPhoneNumber1.slice(-11);
                       }*/
                    inputData.phone2 = data.COMMPH2BLOCK.phoneNumLocalCode;
                    inputData.secondePhoneNumber = data.COMMPH2BLOCK.phoneNumLocalCode
                }

                if (data.ETINBLOCK !== undefined) {
                    inputData.eTin = data.ETINBLOCK.referenceNum;
                }
                /*   if (data.DLBLOCK !== undefined) {
                       inputData.drivingLicense = data.DLBLOCK.referenceNum;
                   }
                   if (data.BCBLOCK !== undefined) {

                       inputData.birthCertificateNo = data.BCBLOCK.referenceNum;

                   }*/
                inputData.currency = this.findByVarNameGetKeyValue(data.currency, this.state.currency);
                inputData.occupationCodeNormal = data.occupationCode;
                inputData.occupationCodePriority = data.occupationCode;
                inputData.occupationCodeStaff = data.occupationCode;
                inputData.occupationCode = data.occupationCode;
                inputData.occupationCodes = data.occupationCode
            }
        }

        /*  else if (this.props.subServiceType === "Company Account") {


              if (type === "cbExtraInformation") {
                  inputData.priorityCenterCode = data.freecode1
                  inputData.primaryCBHolderforPriority = data.free_text_8
                  inputData.relationshipwithPrimaryCBHolder = data.free_text_9
              } else {


                  inputData.cbNumber = this.props.getCustomerId;
                  inputData.accountType = "Regular"
                  inputData.accountTypes = "Regular"

                  inputData.passport = data.passportNumber
                  inputData.gender = data.gender

                  if (data.MailingBLOCK !== undefined) {
                      inputData.SelectCommunicationAddress = data.MailingBLOCK.addrCategory
                      inputData.communicationAddress1 = data.MailingBLOCK.addrLine1
                      inputData.communicationAddress2 = data.MailingBLOCK.addrLine2
                      inputData.postalCode3 = data.MailingBLOCK.postalCode
                      inputData.state2 = this.findByVarNameGetKeyValue(data.MailingBLOCK.city, this.state.state)
                      inputData.city2 = this.findByVarNameGetKeyValue(data.MailingBLOCK.state, this.state.city)
                      inputData.country = this.findByVarNameGetKeyValue(data.MailingBLOCK.country, this.state.country)
                  }
                  inputData.nationality = this.findByVarNameGetKeyValue(data.nationality, this.state.country)

                  if (data.BCBLOCK !== undefined) {

                      inputData.birthCertificateNo = data.BCBLOCK.referenceNum;

                  }
                  if (data.DLBLOCK !== undefined) {
                      inputData.drivingLicense = data.DLBLOCK.referenceNum;
                  }



                  inputData.monitoringRMCode = data.monitoringRMCode;


                  inputData.shortName = data.shortName
                  inputData.staffIndicator = data.staffFlag
                  inputData.staffIdNumber = data.staffEmployeeID
                  if (data.COMMEMLBLOCK !== undefined) {
                      inputData.designatedEmail = data.COMMEMLBLOCK.emailInfo
                  }
                  if (data.custStatus === "NOR") {
                      inputData.customerStatus = "Normal Customer"
                  }
                  if (data.custStatus === "STAFF") {
                      inputData.customerStatus = "Staff"
                  }
                  if (data.custStatus === "PRIOR") {
                      inputData.customerStatus = "Priority"
                  }
                  inputData.customerTitles = data.salutation

                  let getDob = data.birthDate;
                  if (getDob !== null & getDob !== undefined) {
                      getDob = getDob.split('T')[0];
                  }
                  inputData.dob = getDob
                  inputData.selectedDate = getDob


                  inputData.customerName = data.corporateName
                  if (data.COMMPH1BLOCK !== undefined) {
                      if (data.COMMPH1BLOCK.phoneNumCountryCode === "88") {
                          inputData.mobileTypeIndividual = "Local"
                      } else if (data.COMMPH1BLOCK.phoneNumCountryCode === "0") {
                          inputData.mobileTypeIndividual = "Overseas"
                          inputData.countryCodeIndividual = this.findByVarNameGetKeyValue(data.COMMPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)
                      } else {
                          //inputData.mobileTypeIndividual="Overseas"
                      }
                  } else {
                      //inputData.mobileTypeIndividual="Local"
                  }
                  if (data.COMMPH2BLOCK !== undefined) {
                      if (data.COMMPH2BLOCK.phoneNumCountryCode === "88") {
                          inputData.mobileTypeIndividual2 = "Local"
                      } else if (data.COMMPH2BLOCK.phoneNumCountryCode === "0") {
                          inputData.mobileTypeIndividual2 = "Overseas"
                          inputData.countryCodeIndividual2 = this.findByVarNameGetKeyValue(data.COMMPH2BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)
                      } else {
                          //inputData.mobileTypeIndividual2="Overseas"
                      }
                  } else {
                      //inputData.mobileTypeIndividual2="Local"
                  }
                  if (data.COMMPH1BLOCK !== undefined) {
                      // let getPhoneNumber = data.COMMPH1BLOCK.phoneNumLocalCode;
                      /!* if (getPhoneNumber !== null && getPhoneNumber !== undefined) {
                           getPhoneNumber = new Array(getPhoneNumber.length - 11 + 1).join('')
                               + getPhoneNumber.slice(-11);
                       }*!/
                      inputData.designatedMobileNumber = data.COMMPH1BLOCK.phoneNumLocalCode
                  }
                  if (data.COMMPH2BLOCK !== undefined) {

                      /!*   if (getPhoneNumber1 !== null && getPhoneNumber1 !== undefined) {
                             getPhoneNumber1 = new Array(getPhoneNumber1.length - 11 + 1).join('')
                                 + getPhoneNumber1.slice(-11);
                         }*!/
                      inputData.phone2 = data.COMMPH2BLOCK.phoneNumLocalCode
                      inputData.secondePhoneNumber = data.COMMPH2BLOCK.phoneNumLocalCode
                  }
                  if (data.NIDBLOCK !== undefined) {
                      inputData.nid = data.NIDBLOCK.referenceNum;
                  }
                  if (data.RSNIDBLOCK !== undefined) {
                      inputData.smartCard = data.RSNIDBLOCK.referenceNum;
                  }
                  if (data.PASSPORTBLOCK !== undefined) {
                      inputData.passport = data.PASSPORTBLOCK.referenceNum;
                  }
                  if (data.ETINBLOCK !== undefined) {
                      inputData.eTin = data.ETINBLOCK.referenceNum;
                  }
                  if (data.DLBLOCK !== undefined) {
                      inputData.drivingLicense = data.DLBLOCK.referenceNum;
                  }
                  if (data.BCBLOCK !== undefined) {

                      inputData.birthCertificateNo = data.BCBLOCK.referenceNum;

                  }
                  inputData.currency = this.findByVarNameGetKeyValue(data.currencyCode, this.state.currency)
                  inputData.occupationCodeNormal = data.occupationCode
                  inputData.occupationCodePriority = data.occupationCode
                  inputData.occupationCodeStaff = data.occupationCode
                  inputData.occupationCode = data.occupationCode
                  inputData.occupationCodes = data.occupationCode
              }
          }*/

        /*  else if (this.props.subServiceType === "Company Account") {
              inputData.postalCode = data.customerPostcode
              inputData.accountType = "Regular"
              inputData.accountTypes = "Regular"
              inputData.passport = data.passportNumber
              inputData.gender = data.gender
              inputData.mailingAddress1 = data.address1
              inputData.mailingAddress2 = data.address2
              inputData.city = data.customerCity
              inputData.designatedEmailAddress = data.email

              inputData.nationality = data.customerCountry

              inputData.customerName = data.customerName
              let getPhoneNumber = data.phoneNumber;

              if (getPhoneNumber !== null && getPhoneNumber !== undefined) {
                  getPhoneNumber = new Array(getPhoneNumber.length - 11 + 1).join('')
                      + getPhoneNumber.slice(-11);
              }
              inputData.contactNumber1 = getPhoneNumber
              inputData.nid = data.nationalId

              inputData.occupationCodes = data.occupationCode


          }*/
        return inputData;
    };

    handleChangeTenure = (event, option) => {
        event.preventDefault();
        if (option !== null) {
            this.state.inputData["tenure"] = option.title;

            this.forceUpdate()


        }
        if (this.state.inputData.tenureType !== undefined && this.state.inputData.tenure !== undefined && this.state.inputData.schemeCode !== undefined && this.state.inputData.amount !== undefined) {
            let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
            let data = {};
            data.schemeCode = this.state.inputData.schemeCode;
            data.amount = this.state.inputData.amount;
            data.tenor = this.state.inputData.tenure;
            data.tenorType = this.state.inputData.tenureType;
            data.currency = this.state.inputData.currency;
            if(this.state.inputData.fdValueDate!==undefined){
                data.inputDate = this.state.inputData.fdValueDate;
            }
            else if(this.state.inputData.dpsfdValueDate!==undefined){
                data.inputDate = this.state.inputData.dpsfdValueDate;
            }
            console.log(data);
            this.state.inputData.interestRate = "";
            this.state.varValue.interestRate = "";
            this.state.inputData.interestRateApicall = "NO";
            this.forceUpdate();
            axios.post(interestCalculateAPi, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.interestRate = response.data;
                    this.state.varValue.interestRate = response.data;
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate()
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate()
                })
        }

    };

    TenureFieldDayANdMonth = () => {
        if (this.state.inputData.tenureType === "Day") {
            return (
                <Grid item xs={2}>

                    <React.Fragment>
                        <Grid item>
                            <label className="input-label-common">Tenure</label>
                        </Grid>
                        <Grid item>
                            <Autocomplete onChange={(event, option) => this.handleChangeTenure(event, option)}
                                //defaultValue={this.state.inputData["numberOfNominee"]}
                                          options={tenureDay} getOptionLabel={option => option.title}
                                          renderInput={(params) => <TextField {...params} variant="outlined"

                                                                              style={{paddingRight: 20}} fullWidth/>}
                            />
                        </Grid>
                    </React.Fragment>
                </Grid>
            )
        } else if (this.state.inputData.tenureType === "Monthly") {
            return (
                <Grid item xs={2}>

                    <React.Fragment>
                        <Grid item>
                            <label className="input-label-common">Tenure</label>
                        </Grid>
                        <Grid item>
                            <Autocomplete onChange={(event, option) => this.handleChangeTenure(event, option)}
                                //defaultValue={this.state.inputData["numberOfNominee"]}
                                          options={tenureMonth} getOptionLabel={option => option.title}
                                          renderInput={(params) => <TextField {...params} variant="outlined"

                                                                              style={{paddingRight: 20}} fullWidth/>}
                            />
                        </Grid>
                    </React.Fragment>
                </Grid>
            )
        }
    };

    handleChangeuploadFileAlertRequest = (event) => {
        let data = event.target.checked;
        this.state.inputData.uploadFileAlertRequest = data;
        this.updateComponent();
        if (data === false) {
            this.setState({
                errorModalBoolean: true,
                errorTittle: "Without Document Process!!",
            })
        }
    };

    handleChangeTextField = (event, fieldName) => {

        if (fieldName === "schemeCode") {
            this.state.inputData.schemeCode = event.target.value
        } else if (fieldName === "amount") {
            this.state.inputData.amount = event.target.value

        }
        if (this.state.inputData.tenureType !== undefined && this.state.inputData.tenure !== undefined && this.state.inputData.schemeCode !== undefined && this.state.inputData.amount !== undefined) {
            let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
            let data = {};
            data.schemeCode = this.state.inputData.schemeCode;
            data.amount = this.state.inputData.amount;
            data.tenor = this.state.inputData.tenure;
            data.tenorType = this.state.inputData.tenureType;
            data.currency = this.state.inputData.currency;
            if(this.state.inputData.fdValueDate!==undefined){
                data.inputDate = this.state.inputData.fdValueDate;
            }
            else if(this.state.inputData.dpsfdValueDate!==undefined){
                data.inputDate = this.state.inputData.dpsfdValueDate;
            }
            console.log(data);
            this.state.inputData.interestRate = "";
            this.state.varValue.interestRate = "";
            this.state.inputData.interestRateApicall = "NO";
            this.forceUpdate();
            axios.post(interestCalculateAPi, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.interestRate = response.data;
                    this.state.varValue.interestRate = response.data;
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate()
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate()
                })
        }
    };

    /*renderSmsAlertField = () => {
        if (this.state.getData && (this.state.getInitiateData || this.props.appId !== undefined)) {
            //this.state.inputData.fdMaturityDate = finalDate;
            //this.state.varValue.fdMaturityDate = someDate;
            return (

                        <React.Fragment>
                            <React.Fragment>
                                <Grid item>
                                    <label className="input-label-common">SMS Alert Request</label>
                                </Grid>
                                <Grid item>
                                    <Autocomplete onChange={(event, option) => this.handleChangeSmsAlertRequest(event, option)}
                                        //defaultValue={this.state.inputData["numberOfNominee"]}
                                                  options={smsAlertDropdown} getOptionLabel={option => option.title}
                                                  renderInput={(params) => <TextField {...params} variant="outlined"

                                                                                      style={{paddingRight: 20}} fullWidth/>}
                                    />
                                </Grid>
                            </React.Fragment>
                         {/!*   <Grid item>
                                <FormControlLabel

                                    control={

                                        <CheckBox
                                            checked={this.state.inputData.smsAlertRequest}
                                            defaultValue={this.state.inputData.smsAlertRequest}
                                            name="smsAlertRequest"
                                            label="SMS Alert Request"
                                            onChange={(event) => this.handleChangeSmsAlertRequest(event)}/>

                                    }

                                    label="SMS Alert Request"

                                />

                            </Grid>*!/}
                        </React.Fragment>


            )


        }
    };*/
    handleChangeAutoComplete = (event, option, fieldName) => {

        if (fieldName === "schemeCode") {
            if (option !== null) {
                this.state.inputData[fieldName] = option.key;
                console.log(option);
                this.forceUpdate()
            } else if (option === null) {
                this.state.inputData[fieldName] = option;
                console.log(option);
                this.forceUpdate();
                return 0;
            }

        }
        if (this.state.inputData.tenureType !== undefined && this.state.inputData.tenure !== undefined && this.state.inputData.schemeCode !== undefined && this.state.inputData.amount !== undefined) {
            let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
            let data = {};
            data.schemeCode = this.state.inputData.schemeCode;
            data.amount = this.state.inputData.amount;
            data.tenor = this.state.inputData.tenure;
            data.tenorType = this.state.inputData.tenureType;
            data.currency = this.state.inputData.currency;
            if(this.state.inputData.fdValueDate!==undefined){
                data.inputDate = this.state.inputData.fdValueDate;
            }
            else if(this.state.inputData.dpsfdValueDate!==undefined){
                data.inputDate = this.state.inputData.dpsfdValueDate;
            }
            console.log(data);
            this.state.inputData.interestRate = "";
            this.state.varValue.interestRate = "";
            this.state.inputData.interestRateApicall = "NO";
            this.forceUpdate();
            axios.post(interestCalculateAPi, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.interestRate = response.data;
                    this.state.varValue.interestRate = response.data;
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate()
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate()
                })
        }
    };

    makeReadOnlyObjec = (object) => {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            returnObjectVariables[i]["readOnly"] = true;
        }
        return returnObject;


    };

    fdrInterestRateCalculator = () => {
        if ((this.state.inputData.accountsType === "FDR" || this.state.inputData.accountsType === "Monthly Interest Paying FD") && this.state.inputData.fdrRequest === true && this.state.getData && this.state.inputData.accountType !== undefined && this.state.getSchemeFlag) {
            //this.state.inputData.fdMaturityDate = finalDate;
            //this.state.varValue.fdMaturityDate = someDate;
            return (
                <React.Fragment>
                    {/* <Grid item xs={2}>
                        <React.Fragment>
                            <Grid item>
                                <label className="input-label-common">Scheme Code</label>
                            </Grid>
                            <Grid item>
                                <TextField

                                    variant="outlined"

                                    defaultValue={this.state.inputData["schemeCode"]}

                                    onBlur={(event) => this.handleChangeTextField(event, "schemeCode")}

                                    InputProps={{

                                        readOnly: false
                                    }}

                                    //InputLabelProps={{shrink:true}}
                                    fullWidth
                                    style={{paddingRight: 20}}
                                />

                            </Grid>
                        </React.Fragment>
                    </Grid>*/}
                    <Grid item xs={2}>
                        <React.Fragment>
                            <Grid item>
                                <label className="input-label-common">Scheme Code *</label>
                            </Grid>
                            <Grid item>
                                <Autocomplete
                                    onChange={(event, option) => this.handleChangeAutoComplete(event, option, "schemeCode")}
                                    //defaultValue={this.state.inputData["numberOfNominee"]}
                                    options={this.state.getAllSchemeCode} getOptionLabel={option => option.value}
                                    //style={ field.readOnly===true?{paddingRight: 20}:{paddingRight: 20}}
                                    style={{paddingRight: 20}}
                                    //style={ {paddingRight: 20}}
                                    renderInput={(params) => /*<TextField {...params} variant="outlined"

                                                                      style={{paddingRight: 20}} fullWidth/>}*/

                                        <TextField {...params}
                                                   variant="outlined"

                                                   fullWidth/>}
                                />


                            </Grid>
                        </React.Fragment>
                    </Grid>
                    {this.TenureFieldDayANdMonth()}
                    <Grid item xs={2}>
                        <React.Fragment>
                            <Grid item>
                                <label className="input-label-common">Amount</label>
                            </Grid>
                            <Grid item>
                                <TextField

                                    variant="outlined"

                                    defaultValue={this.state.inputData["amount"]}
                                    onBlur={(event) => this.handleChangeTextField(event, "amount")}


                                    InputProps={{

                                        readOnly: false
                                    }}

                                    //InputLabelProps={{shrink:true}}
                                    fullWidth
                                    style={{paddingRight: 20}}
                                />

                            </Grid>
                        </React.Fragment>
                    </Grid>
                    <Grid item xs={2}>
                        <React.Fragment>
                            <Grid item>
                                <label className="input-label-common">Interest Rate %</label>
                            </Grid>
                            <Grid item>
                                <TextField

                                    variant="outlined"
                                    value={this.valueCheck(this.state.inputData["interestRate"], false)}
                                    InputProps={{

                                        readOnly: true
                                    }}

                                    //InputLabelProps={{shrink:true}}
                                    fullWidth
                                    style={{paddingRight: 20}}
                                />

                            </Grid>
                        </React.Fragment>
                    </Grid>
                </React.Fragment>

            )


        }
    };

    findByVarName = (searchVarname, getValue) => {
        let jsonArray = this.props.commonJsonForm;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            console.log(jsonObject);
            if (jsonObject.varName === searchVarname) {
                console.log(this.props.commonJsonForm[i]);
                this.props.commonJsonForm[i].enum = getValue;

            }
        }
    };

    findByVarNameApiData = (searchVarname, getValue) => {
        if (searchVarname === "freeCode1SpecialTaxagentOutlet") {

            let jsonArray = this.props.serviceList;
            for (let i = 0; i < jsonArray.length; i++) {
                let jsonObject = jsonArray[i];
                if (jsonObject.varName === searchVarname) {
                    console.log(getValue.key);
                    let data = [];
                    /* for(let j=0;j<getValue.length;j++){
                         let key=getValue[j].key;
                         let value=getValue[j].value;
                         data.push({key,value})
                     }*/
                    this.props.serviceList[i].enum = getValue;


                }
            }
        }
        if (searchVarname === "fdrSchemeCode" || searchVarname==="dpsfreeCode1SpecialTaxagentOutlet") {

            let jsonArray = CSJsonFormForCasaServiceDPS;
            for (let i = 0; i < jsonArray.length; i++) {
                let jsonObject = jsonArray[i];
                if (jsonObject.varName === searchVarname) {
                    console.log(getValue.key);
                    let data = [];
                    /* for(let j=0;j<getValue.length;j++){
                         let key=getValue[j].key;
                         let value=getValue[j].value;
                         data.push({key,value})
                     }*/
                    CSJsonFormForCasaServiceDPS[i].enum = getValue;


                }
            }
        }
        if (searchVarname === "instaCardNumber" || searchVarname === "instaDebitCardProductType") {

            let jsonArray = this.props.serviceListLast;
            for (let i = 0; i < jsonArray.length; i++) {
                let jsonObject = jsonArray[i];
                if (jsonObject.varName === searchVarname) {
                    console.log(getValue.key);
                    let data = [];
                    /* for(let j=0;j<getValue.length;j++){
                         let key=getValue[j].key;
                         let value=getValue[j].value;
                         data.push({key,value})
                     }*/
                    this.props.serviceListLast[i].enum = getValue;


                }
            }
        }
        if (searchVarname === "subSectorCode" || searchVarname === "branchSolId") {

            let jsonArray = this.props.commonJsonForm;
            for (let i = 0; i < jsonArray.length; i++) {
                let jsonObject = jsonArray[i];
                if (jsonObject.varName === searchVarname) {
                    console.log(getValue.key);
                    let data = [];
                    /* for(let j=0;j<getValue.length;j++){
                         let key=getValue[j].key;
                         let value=getValue[j].value;
                         data.push({key,value})
                     }*/
                    this.props.commonJsonForm[i].enum = getValue;


                }
            }
        } else if (searchVarname === "checkbookBranchName" || searchVarname === "debitCardBranchName" || searchVarname == "requisitionBranch") {
            let jsonArray = this.props.serviceListLast;
            for (let i = 0; i < jsonArray.length; i++) {
                let jsonObject = jsonArray[i];
                if (jsonObject.varName === searchVarname) {
                    console.log(getValue.key);
                    let data = [];
                    /* for(let j=0;j<getValue.length;j++){
                         let key=getValue[j].key;
                         let value=getValue[j].value;
                         data.push({key,value})
                     }*/
                    this.props.serviceListLast[i].enum = getValue;


                }
            }
        } else {
            let jsonArray = this.props.commonJsonForm;
            for (let i = 0; i < jsonArray.length; i++) {
                let jsonObject = jsonArray[i];
                if (jsonObject.varName === searchVarname) {
                    console.log(getValue.key);
                    let data = [];
                    /* for(let j=0;j<getValue.length;j++){
                         let key=getValue[j].key;
                         let value=getValue[j].value;
                         data.push({key,value})
                     }*/
                    this.props.commonJsonForm[i].enum = getValue;
                    console.log(this.props.commonJsonForm[i]);
                    console.log("ddddddddddddddddddddddddddddddddd")

                }
            }
        }

    };

    setStateapiEnumValue = (setVarname, data) => {
        if (setVarname === "city") {
            this.setState({
                city: data
            })
        } else if (setVarname === "state") {
            this.setState({
                state: data
            })
        } else if (setVarname === "currency") {
            this.setState({
                currency: data
            })
        } else if (setVarname === "country") {
            this.setState({
                country: data
            })
        } else if (setVarname === "fdrSchemeCode") {
            this.setState({
                fdrSchemeCode: data
            })
        }

    };

    dynamicApiCallBranchName = (fieldName, apiType, setData) => {
        let codeUrl = backEndServerURL + "/finMasterData/custom/branchNameSorted";
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response);
                this.findByVarNameApiData("requisitionBranch", response.data);
                this.findByVarNameApiData("branchSolId", response.data);
                this.setState({
                    branchAllSolId: response.data
                })
            })
            .catch((error) => {
                console.log(error)
            })
    };

    dynamicApiCallgetInitialUnits = (fieldName, apiType, setData) => {
        let branch = [];
        let branchUrl = backEndServerURL + "/workplaceUnit/getInitialUnits";
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((item) => {
                    branch.push({key: item.solId, value: item.name})
                });
                this.findByVarNameApiData("checkbookBranchName", branch);
                this.findByVarNameApiData("debitCardBranchName", branch)


            })
            .catch((error) => {
                console.log(error)
            })
    };

    dynamicApiCall = (fieldName, apiType, setData) => {
        let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(fieldName);
                console.log(response);

                if (apiType === "agentBankingOutlet") {
                    this.findByVarNameApiData("misagentBankingOutletFreeCode1", response.data);
                    this.findByVarNameApiData("freeCode1SpecialTaxagentOutlet", response.data);
                    this.findByVarNameApiData("dpsfreeCode1SpecialTaxagentOutlet", response.data);
                }
                if (fieldName === "phoneCountryCode") {

                    {
                        this.findByVarNameApiData("countryCodeIndividual", response.data)
                    }
                    {
                        this.findByVarNameApiData("countryCodeIndividual2", response.data)
                    }
                    this.setState({
                        phoneNumCountryCodeList: response.data
                    })
                }

                if (fieldName === "nationality") {

                    {
                        this.findByVarNameApiData("country", response.data)
                    }

                }
                {
                    this.findByVarNameApiData(fieldName, response.data)
                }

                {
                    this.setStateapiEnumValue(apiType, response.data)
                }

            })
            .catch((error) => {
                console.log(error)
            })
    };

    functionForGetWhenRequestGetReturn = (currency, accountsType, accountAcquisition, tenureType, tenure, autoRenewals) => {

        let object = {
            currency: typeof currency === "object" ? currency.key : currency
        };
        if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
            object.natureAccount = "Individual-Retail";
        } else {
            object.natureAccount = "Non-Individual"
        }

        let Urls = backEndServerURL + "/FDRDPSSchemeCode/getAccountType";
        axios.post(Urls, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((getAccountType) => {
                let jsonArrayServiceList = CSJsonFormForFDRService;
                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                    let jsonObjects = jsonArrayServiceList[i];
                    if (jsonObjects.varName === "accountsType") {
                        CSJsonFormForFDRService[i].enum = getAccountType.data;
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });

        if (accountsType !== undefined) {
            object.accountType = accountsType;
            let acqUrl = backEndServerURL + "/FDRDPSSchemeCode/getAccountAcquisition";
            axios.post(acqUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let jsonArrayServiceList = CSJsonFormForFDRService;
                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "accountAcquisition") {
                            CSJsonFormForFDRService[i].enum = response.data;
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }


        if (accountAcquisition !== undefined) {
            object.accountAcquisition = accountAcquisition;
            let tenureUrl = backEndServerURL + "/FDRDPSSchemeCode/getTenureType";//{sectorCode}
            axios.post(tenureUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log("....", object, response.data);
                    let jsonArrayServiceList = CSJsonFormForFDRService;
                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "tenureType") {
                            CSJsonFormForFDRService[i].enum = response.data;
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }


        if (tenureType !== undefined) {
            object.tenureType = tenureType;
            let tenorUrl = backEndServerURL + "/FDRDPSSchemeCode/getTenor";
            axios.post(tenorUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let jsonArrayServiceList = CSJsonFormForFDRService;
                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "tenure") {
                            CSJsonFormForFDRService[i].enum = response.data;
                        }
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        }


        if (tenure !== undefined) {
            object.tenure = tenure;
            let renewalUrl = backEndServerURL + "/FDRDPSSchemeCode/getRenewal";//{sectorCode}
            axios.post(renewalUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let jsonArrayServiceList = CSJsonFormForFDRService;
                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "autoRenewals") {
                            CSJsonFormForFDRService[i].enum = response.data.renewal;
                        }

                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        }
        if (autoRenewals !== undefined) {
            object.autoRenewals = autoRenewals;
            let maturityUrl = backEndServerURL + "/FDRDPSSchemeCode/getMaturity";
            axios.post(maturityUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    let jsonArrayServiceList = CSJsonFormForFDRService;
                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "maturity") {
                            CSJsonFormForFDRService[i].enum = response.data;
                        }
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }

    };

    functionForDpsWhenRequestGetReturn = (currency, dpsnameOfScheme, dpsaccountAcquisition, dpstenureType) => {
        let object = {
            currency: typeof currency === "object" ? currency.key : currency,
        };
        if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
            object.natureAccount = "Individual-Retail";
        } else {
            object.natureAccount = "Non-Individual"
        }
        let Urls = backEndServerURL + "/FDRDPSSchemeCode/getDPSNameOfScheme";
        axios.post(Urls, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((getAccountType) => {
                let jsonArrayServiceList = CSJsonFormForCasaServiceDPS;
                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                    let jsonObjects = jsonArrayServiceList[i];
                    if (jsonObjects.varName === "dpsnameOfScheme") {
                        CSJsonFormForCasaServiceDPS[i].enum = getAccountType.data;
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });

        if (dpsnameOfScheme !== undefined) {
            object.nameOfScheme = dpsnameOfScheme;
            let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getDPSAccountAcquisition";
            axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log("res...accq", object, response.data);
                    let jsonArrayServiceList = CSJsonFormForCasaServiceDPS;
                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "dpsaccountAcquisition") {
                            CSJsonFormForCasaServiceDPS[i].enum = response.data;
                        }
                    }

                    this.forceUpdate();
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        if (dpsaccountAcquisition !== undefined) {
            object.accountAcquisition = dpsaccountAcquisition;
            let tenureTypeUrl = backEndServerURL + "/FDRDPSSchemeCode/getDPSTenureType";//{sectorCode}
            axios.post(tenureTypeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log("res...tenure type", object, response.data);
                    let jsonArrayServiceList = CSJsonFormForCasaServiceDPS;
                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "dpstenureType") {
                            CSJsonFormForCasaServiceDPS[i].enum = response.data;
                        }
                    }
                    this.forceUpdate();
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        if (dpstenureType !== undefined) {
            object.tenureType = dpstenureType;
            let tenureUrl = backEndServerURL + "/FDRDPSSchemeCode/getDPSTenure";//{sectorCode}
            axios.post(tenureUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log("res...tenure", object, response.data);
                    let jsonArrayServiceList = CSJsonFormForCasaServiceDPS;
                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "dpstenure") {
                            CSJsonFormForCasaServiceDPS[i].enum = response.data;
                        }
                    }
                    this.forceUpdate();
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };

    componentDidMount() {
        /*   {
             this.dynamicApiCall("SelectCommunicationAddress", "")
         }*/
        {
            this.dynamicApiCall("agentBankingOutlet", "agentBankingOutlet")
        }
        {
            this.dynamicApiCall("city2", "city")
        }
        {
            this.dynamicApiCallBranchName("branchName", "branch")
        }
        {
            this.dynamicApiCallgetInitialUnits("getInitialUnits", "getInitialUnits")
        }
        /*   {
               this.dynamicApiCall("checkbookBranchName", "branch")
           }*/
        {
            this.dynamicApiCall("state2", "state",)
        }
        {
            this.dynamicApiCall("currency", "currency")
        }
        {
            this.dynamicApiCall("sectorCode", "sectorCode")
        }
        {
            this.dynamicApiCall("ccepCode", "ccepCode")
        }
        /* {
             this.dynamicApiCall("subSectorCode", "subSectorCode")
         }*/
        {
            this.dynamicApiCall("nationality", "country")
        }
        {
            this.dynamicApiCall("schemeCodeForSavings", " ")
        }
        {
            this.dynamicApiCall("schemeCodeForCurrent", "currentSchemeCode")
        }
        {
            this.dynamicApiCall("fdrSchemeCode", "fdrSchemeCode")
        }
        {
            this.dynamicApiCall("phoneCountryCode", "phoneCountryCode")
        }
        if (this.props.appId !== undefined) {
            this.setState({
                appId: this.props.appId
            })
        }
        let docsTypeUrl = backEndServerURL + "/deferralList/get/SDRegulatory";
        //let docsTypeUrl=backEndServerURL+"/deferralList/get/SDNonRegulatory";
        axios.get(docsTypeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    SDRegulatory: response.data
                })
            })
            .catch((error) => {
                console.log(error)
            });


        let instaDebitCardUrl = backEndServerURL + "/instantDebitCard/productTypes";
        axios.get(instaDebitCardUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);

                {
                    this.findByVarNameApiData("instaDebitCardProductType", response.data)
                }
                this.setState({
                    instaDebitCardProductType: response.data
                })
            })
            .catch((error) => {
                console.log(error)
            });
        docsTypeUrl = backEndServerURL + "/deferralList/get/SDNonRegulatory";
        //let docsTypeUrl=backEndServerURL+"/deferralList/get/SDNonRegulatory";
        axios.get(docsTypeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    SDNonRegulatory: response.data
                })
            })
            .catch((error) => {
                console.log(error)
            });
        //{this.dynamicApiCall("schemeCode","savingsSchemeCode")}
        this.state.inputData["csDeferal"] = "NO";


        let varValue = [];
        let inputData = [];
        var remarksArray = [];
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;

            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    if (response.data.fdrRequest === true) {
                        this.functionForGetWhenRequestGetReturn(response.data.currency, response.data.accountsType, response.data.accountAcquisition, response.data.tenureType, response.data.tenure, response.data.autoRenewals);
                    }
                    if (response.data.dpsRequest === true) {
                        this.functionForDpsWhenRequestGetReturn(response.data.currency, response.data.dpsnameOfScheme, response.data.dpsaccountAcquisition, response.data.dpstenureType);
                    }

                    if (response.data.debitCard) {
                        this.getCardType();
                        this.functionForGetProductType(response.data.cardType, "Old")
                    }

                    let getData = {};
                    if (this.state.inputData.dpsRequest && this.state.inputData.fdrRequest) {
                        getData = FunctionForInputGetAndSet.setInputDataForDPSFromCasa(response.data);
                        getData = FunctionForInputGetAndSet.setInputData(getData);
                    } else if (this.state.inputData.dpsRequest) {
                        getData = FunctionForInputGetAndSet.setInputDataForDPSFromCasa(response.data);
                    } else if (this.state.inputData.fdrRequest) {
                        getData = FunctionForInputGetAndSet.setInputData(response.data);
                    } else {
                        getData = response.data
                    }
                    inputData = this.emptyValueRemove(getData);
                    varValue = this.emptyValueRemove(getData);
                    inputData.csRemarks = undefined;
                    varValue.csRemarks = undefined;
                    if (inputData.apiButton === undefined || inputData.apiButton === null) {
                        inputData.apiButton = "UPDATE";
                        varValue.apiButton = "UPDATE";
                    }

                    let url = backEndServerURL + "/intgr/accountBalanceInfo/" + inputData.accountNumber;
                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            if (response.data.schemeCode === "SBSTF") {
                                inputData.availableAmountShow = false;
                                varValue.availableAmountShow = false

                            } else {
                                inputData.availableAmountShow = true;
                                varValue.availableAmountShow = true
                            }
                            inputData.availableAmount = response.data.acctEftvBalance;
                            varValue.availableAmount = response.data.acctEftvBalance
                        })
                        .catch((error) => {
                            console.log(error)
                        });

                    let accountUrls = backEndServerURL + "/getAccountInfo/" + inputData.accountNumber;
                    axios.get(accountUrls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            if (response.data.nominationInfoList !== undefined && response.data.nominationInfoList !== null && response.data.nominationInfoList.length > 0) {
                                inputData.nomineeExistNot = "Y";
                                varValue.nomineeExistNot = "Y";
                                inputData.nomineeExistNots = "Y";
                                varValue.nomineeExistNots = "Y"
                            } else {
                                inputData.nomineeExistNot = "N";
                                varValue.nomineeExistNot = "N";
                                inputData.nomineeExistNots = "N";
                                varValue.nomineeExistNots = "N"
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    console.log(response.data);
                                    this.setState({
                                        fileUploadData: response.data,

                                    })
                                })
                                .catch((error) => {
                                    console.log(error)
                                });
                            console.log(response.data);
                            let tableArray = [];
                            var status = "";
                            response.data.map((deferal) => {
                                if (deferal.status === "ACTIVE") {
                                    status = "Approved"
                                } else if (deferal.status === "NOTAPPROVED") {
                                    status = "NOT APPROVED"
                                } else {
                                    status = deferal.status;
                                }
                                tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));
                            });
                            this.setState({
                                getDeferalList: tableArray
                            });

                            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {

                                    console.log(response.data);
                                    response.data.map((data) => {

                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                    });
                                    this.setState({
                                        getRemarks: remarksArray
                                    })
                                })
                                .catch((error) => {
                                    console.log(error)
                                })


                        })
                        .catch((error) => {
                            console.log(error)
                        });


                    console.log(remarksArray);
                    this.state.inputData["csDeferal"] = "NO";
                    let object = {};
                    if (inputData.currency !== undefined && inputData.currency.key !== undefined) {

                        object.currency = inputData.currency.key

                    }


                    if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
                        object.natureAccount = "Individual-Retail";
                    } else {
                        object.natureAccount = "Non-Individual"
                    }
                    let Urls = backEndServerURL + "/FDRDPSSchemeCode/getDPSNameOfScheme";
                    axios.post(Urls, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((getAccountType) => {
                            let jsonArrayServiceList = CSJsonFormForCasaServiceDPS;
                            for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                let jsonObjects = jsonArrayServiceList[i];
                                if (jsonObjects.varName === "dpsnameOfScheme") {
                                    CSJsonFormForCasaServiceDPS[i].enum = getAccountType.data;
                                }
                            }

                            inputData.dpsnameOfSchemeApiCall = true;
                            varValue.dpsnameOfSchemeApiCall = true
                            /* this.setState({
                                 accountBalanceInfo: true,
                                 getAccountType: true,
                                 cbsCustomerGet: true,
                                 inputData: inputData,
                                 varValue: varValue,
                                 showValue: true,
                                 //loading: false,
                                 getData: true,getDataSubServiceFDR:true,getDataSubServiceDPS:true,
                                 nameOfSchemeApiCall: true,

                             })*/
                        })
                        .catch((error) => {
                            /*  this.setState({
                                  accountBalanceInfo: true,
                                  nameOfSchemeApiCall: true,
                                  cbsCustomerGet: true,
                              })*/
                            console.log(error);
                        });


                    let objects = {};
                    if (inputData.currency !== undefined && inputData.currency.key !== undefined) {
                        objects.currency = inputData.currency.key
                    }

                    if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
                        objects.natureAccount = "Individual-Retail";
                    } else {
                        objects.natureAccount = "Non-Individual"
                    }
                    Urls = backEndServerURL + "/FDRDPSSchemeCode/getAccountType";
                    axios.post(Urls, objects, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((getAccountType) => {
                            let jsonArrayServiceList = CSJsonFormForCasaServiceDPS;
                            for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                let jsonObjects = jsonArrayServiceList[i];
                                if (jsonObjects.varName === "accountsType") {
                                    CSJsonFormForCasaServiceDPS[i].enum = getAccountType.data;
                                }
                            }
                            inputData.accountTypeApiCall = true;
                            varValue.accountTypeApiCall = true
                            /*  this.setState({
                                  accountBalanceInfo: true,
                                  getAccountType: true,
                                  cbsCustomerGet: true,
                                  inputData: inputData,
                                  varValue: varValue,
                                  showValue: true,
                                  //loading: false,
                                  getData: true,getDataSubServiceFDR:true,getDataSubServiceDPS:true,

                              })*/
                        })
                        .catch((error) => {
                            /*  this.setState({
                                  accountBalanceInfo: true,
                                  getAccountType: true,
                                  cbsCustomerGet: true,
                              })*/
                            console.log(error);
                        });
                    console.log(response.data);
                    this.setState({
                        getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                        varValue: this.emptyValueRemove(varValue),
                        appData: response.data,
                        inputData: inputData,
                        showValue: true,
                        appId: this.props.appId,
                        loading: false
                    });

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })

                });
            /*  let fileUrl = backEndServerURL + "/applicationKeyValue/get";
              let data={};
              data.appId=this.props.appId;
              data.key="getSignatureCardNumber";
              axios.post(fileUrl, data,{headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                  .then((response) => {

                  })
                  .catch((error)=>{})*/
        } else {
            this.returnCaseStart()
        }

    }

    returnCaseStart = () => {
        let url = backEndServerURL + "/startCase/cs_data_capture";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                this.setState({
                    appId: response.data.id,
                    appData: response.data.inputData,
                });
                if (this.props.agentBanking !== true) {
                    this.DedupDataSaveApi(this.props.subServiceType,response.data.id)
                } else {
                    this.returnCaseStartAfterDataSet()
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            })
    };
    returnCaseStartAfterDataSet = () => {
        let dedupeSearchValue = {};
        if (this.props.agentBanking !== true) {
            dedupeSearchValue = this.props.searchValue;
        }

        console.log(dedupeSearchValue);
        console.log(dedupeSearchValue.customerName1);
        console.log(dedupeSearchValue["customerName1"]);

        let inputData = this.emptyValueRemove(dedupeSearchValue);
        let varValue = this.emptyValueRemove(dedupeSearchValue);

        if (this.props.dstFlag === true) {
            inputData.dstFlag = true;
            varValue.dstFlag = true;
        }
        if (this.props.agentBankingFlag === true) {
            inputData.agentBankingFlag = true;
            varValue.agentBankingFlag = true;
        }
        if (this.props.serviceType === "Account Opening") {
            inputData.apiButton = "CB UPDATE";
            varValue.apiButton = "CB UPDATE";
        } else {
            inputData.apiButton = "ACCOUNT UPDATE";
            varValue.apiButton = "ACCOUNT UPDATE";
        }
        inputData.nomineeInformationVisible = "YES";
        varValue.nomineeInformationVisible = "YES";
        inputData.confirmAndSaveButton = "YES";
        varValue.confirmAndSaveButton = "YES";
        inputData.nidVerified = "NO";
        varValue.nidVerified = "NO";
        inputData.autoRenewal = "N";
        inputData.csDeferal = "NO";
        inputData.nextExecutionDate = new Date();
        inputData.checqueBooApiFlag = "YES";
        inputData.debitCardApiFlag = "YES";
        inputData.instaDebitCardApiFlag = "YES";
        inputData.functionCodeforChequeBookWaiver = "A";
        inputData.waiverTypeforChequeBookWaiver = "F";
        inputData.waiverField = "WAVPR";
        varValue.functionCodeforChequeBookWaiver = "A";
        varValue.waiverTypeforChequeBookWaiver = "F";
        varValue.waiverField = "WAVPR";

        inputData.checkboob = "YES";
        inputData.accountSource = "Conventional";
        varValue.accountSource = "Conventional";
        if (this.props.subServiceType !== "Joint Account") {
            inputData.designatedEmail = inputData.phone0;
            varValue.designatedEmail = varValue.phone0;
            inputData.designatedMobileNumber = inputData.email0;
            varValue.designatedMobileNumber = varValue.email0;
        }

        /*   inputData.currency = {
               key: "BDT",
               masterDataName: "currency",
               value: "BDT"
           };
           varValue.currency = {
               key: "BDT",
               masterDataName: "currency",
               value: "BDT"
           };*/
        inputData.uploadFileAlertRequest = true;
        varValue.uploadFileAlertRequest = true;
        inputData.smsAlertRequest = "Y";
        varValue.smsAlertRequest = "Y";
        if (this.props.subServiceType === "Joint Account") {
            inputData.customerTitle = "JOINT";
            varValue.customerTitle = "JOINT";

            /*   if(inputData.mobileTypecontactNumberJoint){
                   inputData.mobileTypeIndividual=inputData.mobileTypeJoint0
                   varValue.mobileTypeIndividual=inputData.mobileTypeJoint0
                   inputData.countryCodeIndividual=inputData.countryCode0
                   varValue.countryCodeIndividual=inputData.countryCode0
               }*/


        }
        if (this.props.serviceType === "ExistAccountOpening" || this.props.agentBanking === true) {
            //inputData.customerStatus = "Normal Customer";
            // varValue.customerStatus = "Normal Customer";
            let cbNumber = 0;
            let exitDataUrl = backEndServerURL + "/cbsCustomerGet/" + this.props.getCustomerId;
            /*  if (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C" && this.props.subServiceType !== "Joint Account") {
                  exitDataUrl = backEndServerURL + "/getCorporateCustomerInfo/" + dedupeSearchValue.companyCbNumber

              }*/

            axios.get(exitDataUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    setTimeout(() => {
                        let dataInput = this.apiDataSet(this.emptyValueRemove(response.data), inputData);
                        let datavarValue = this.apiDataSet(this.emptyValueRemove(response.data), varValue);
                        let getCBUrl2 = backEndServerURL + "/getCustomerCIFDetails/" + this.props.getCustomerId;/*"2801187408001"*/
                        axios.get(getCBUrl2, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((cb2DataResponse) => {
                                //api 4 then
                                setTimeout(() => {
                                    let dataInputs = this.apiDataSet(this.emptyValueRemove(cb2DataResponse.data), dataInput, "cbExtraInformation");
                                    let datavarValues = this.apiDataSet(this.emptyValueRemove(cb2DataResponse.data), datavarValue, "cbExtraInformation");
                                    this.setState({
                                        getNewCase: true,
                                        //inputData:JSON.parse(JSON.stringify(dedupeSearchValue)),
                                        inputData: dataInputs,
                                        varValue: datavarValues,
                                        showValue: true,
                                        getData: true,
                                        getDataSubServiceFDR: true,
                                        getDataSubServiceDPS: true,
                                        loading: false


                                    });
                                }, 1000)
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    errorModalBoolean: true,
                                    errorTittle: "Finacle System Error!",
                                    loading: false
                                })
                            })
                    }, 1000)

                })
                .catch((error) => {
                    console.log(error);

                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Finacle System Error!",
                        loading: false
                    })

                });
            let occupationCodeUrl = backEndServerURL + "/occupationCode/getAll";
            axios.get(occupationCodeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    console.log(response.data);
                    {
                        this.findByVarName("occupationCodeNormal", response.data)
                    }
                    {
                        this.findByVarName("occupationCodePriority", response.data)
                    }
                    {
                        this.findByVarName("occupationCodes", response.data)
                    }


                })
                .catch((error) => {
                    console.log(error)
                });
            let numberOfCustomer = Number(this.props.jointAccountCustomerNumber);

            let enumData = [];
            if (numberOfCustomer > 0 && (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C" && this.props.subServiceType !== "Proprietorship A/C" && this.props.subServiceType !== "NONINDIVIDUAL")) {
                for (let i = 1; i <= numberOfCustomer; i++) {

                    enumData.push((this.numberToCharacter(i)) + " Applicant");
                    {
                        this.findByVarName("welcomeLetterRecipientSelection", enumData)
                    }

                }


            }
        } else {
            let occupationCodeUrl = backEndServerURL + "/occupationCode/getAll";
            axios.get(occupationCodeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    console.log(response.data);
                    {
                        this.findByVarName("occupationCodeNormal", response.data)
                    }
                    {
                        this.findByVarName("occupationCodePriority", response.data)
                    }
                    {
                        this.findByVarName("occupationCodes", response.data)
                    }


                })
                .catch((error) => {
                    console.log(error)
                });


            let numberOfCustomer = Number(this.props.jointAccountCustomerNumber);

            let enumData = [];
            if (numberOfCustomer > 0 && (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C" && this.props.subServiceType !== "Proprietorship A/C" && this.props.subServiceType !== "NONINDIVIDUAL")) {
                for (let i = 1; i <= numberOfCustomer; i++) {

                    enumData.push((this.numberToCharacter(i)) + " Applicant");
                    {
                        this.findByVarName("welcomeLetterRecipientSelection", enumData)
                    }

                }


            }

            if (this.props.subServiceType === "Proprietorship A/C" || this.props.subServiceType === "NONINDIVIDUAL" || this.props.subServiceType === "Company Account") {
                inputData.cbNumber = dedupeSearchValue.companyCbNumber;
                varValue.cbNumber = dedupeSearchValue.companyCbNumber;
                inputData.customerName = dedupeSearchValue.companyName;
                varValue.customerName = dedupeSearchValue.companyName;
                inputData.designatedEmail = dedupeSearchValue.companyEmail;
                varValue.designatedEmail = dedupeSearchValue.companyEmail;
                inputData.mobileTypeIndividual = dedupeSearchValue.mobileTypeProprietorship;
                varValue.mobileTypeIndividual = dedupeSearchValue.mobileTypeProprietorship;
                inputData.countryCodeIndividual = dedupeSearchValue.countryCodeCompanyProprietorship;
                varValue.countryCodeIndividual = dedupeSearchValue.countryCodeCompanyProprietorship;
                inputData.designatedMobileNumber = dedupeSearchValue.companyMobileNo;
                varValue.designatedMobileNumber = dedupeSearchValue.companyMobileNo;
                inputData.eTin = dedupeSearchValue.companyEtin;
                varValue.eTin = dedupeSearchValue.companyEtin;
                inputData.tradeLicense = dedupeSearchValue.companyTradeLicense;
                varValue.tradeLicense = dedupeSearchValue.companyTradeLicense;
                inputData.certificateIncorporation = dedupeSearchValue.certificate;
                varValue.certificateIncorporation = dedupeSearchValue.certificate;

            }
            inputData.csDeferal = "NO";
            inputData.accountSource = "Conventional";
            //inputData.customerStatus = "Normal Customer";
           // varValue.customerStatus = "Normal Customer";
            inputData.cbNumber = this.props.getCustomerId;
            varValue.cbNumber = this.props.getCustomerId;
            /*   inputData.occupationCodeNormal = "R";
               varValue.occupationCodeNormal = "R";
               inputData.occupationCodeStaff = "E";
               varValue.occupationCodeStaff = "E";
               inputData.occupationCodePriority = "PROO1";
               varValue.occupationCodePriority = "PROO1";*/
            // inputData.priorityCenterCode = "PR186";
            //varValue.priorityCenterCode = "PR186";
            inputData.customerTitles = "M/S";
            varValue.customerTitles = "M/S";
            inputData.staffIndicator = "Y";
            varValue.staffIndicator = "Y";
            /*  if (this.props.serviceType !== "Account Opening") {
                     inputData.accountType = "Regular";
                     varValue.accountType = "Regular";
                     inputData.accountTypes = "Regular";
                     varValue.accountTypes = "Regular";

                 }
               else if (this.props.serviceType === "Account Opening") {
                     inputData.accountType = "Insta Pack";
                     varValue.accountType = "Insta Pack";
                     inputData.accountTypes = "Insta Pack";
                     varValue.accountTypes = "Insta Pack";

                 }
             /!*  else if (this.props.serviceType !== "Account Opening" && this.props.subServiceType === "Joint Account") {
                     inputData.accountType = "Regular";
                     varValue.accountType = "Regular";
                     inputData.accountTypes = "Regular";
                     varValue.accountTypes = "Regular";

                 }
               else if (this.props.serviceType === "Account Opening" && this.props.subServiceType === "Joint Account") {
                     inputData.accountType = "Regular";
                     varValue.accountType = "Regular";
                     inputData.accountTypes = "Insta Pack";
                     varValue.accountTypes = "Insta Pack";

                 }*!/
              else {
                  inputData.accountType = "Regular";
                  varValue.accountType = "Regular";
                  inputData.accountTypes = "Regular";
                  varValue.accountTypes = "Regular";
              }*/
            /*
                else if (this.props.serviceType === "Account Opening" && this.props.subServiceType !== "Joint Account") {
                    inputData.accountType = "Insta Pack";
                    varValue.accountType = "Insta Pack";
                    inputData.accountTypes = "Insta Pack";
                    varValue.accountTypes = "Insta Pack";

                } else if (this.props.subServiceType === "Company Account") {
                    inputData.accountType = "Regular";
                    varValue.accountType = "Regular";
                    inputData.accountTypes = "Regular";
                    varValue.accountTypes = "Regular";

                } else if(this.props.subServiceType === "Joint Account") {
                    inputData.accountType = "Regular";
                    varValue.accountType = "Regular";
                    inputData.accountTypes = "Regular";
                    varValue.accountTypes = "Regular";
                }
                else {
                    inputData.accountType = "Regular";
                    varValue.accountType = "Regular";
                    inputData.accountTypes = "Regular";
                    varValue.accountTypes = "Regular";
                }
*/
            inputData.priority = "GENERAL";
            varValue.priority = "GENERAL";
            /*  inputData.currency = {
                  key: "BDT",
                  masterDataName: "currency",
                  value: "BDT"
              };
              varValue.currency = {
                  key: "BDT",
                  masterDataName: "currency",
                  value: "BDT"
              };*/
            if (inputData.nationality === undefined) {
                inputData.nationality = {
                    key: "BD",
                    masterDataName: "country",
                    value: "BANGLADESH",
                };
                varValue.nationality = {
                    key: "BD",
                    masterDataName: "country",
                    value: "BANGLADESH",
                };
            }


            if (this.props.subServiceType === "Proprietorship A/C" || this.props.subServiceType === "NONINDIVIDUAL" || this.props.subServiceType === "Company Account") {
                /*   companyName
                   companyEmail
                   companyMobileNo
                   companyEtin
                   companyTradeLicense
                   certificate*/

                inputData.customerName = dedupeSearchValue.companyName;
                varValue.customerName = dedupeSearchValue.companyName;


                inputData.designatedEmailAddress = dedupeSearchValue.companyEmail;
                varValue.designatedEmailAddress = dedupeSearchValue.companyEmail;


                inputData.companyEtin = dedupeSearchValue.companyEtin;
                varValue.companyEtin = dedupeSearchValue.companyEtin;

                inputData.tradeLicense = dedupeSearchValue.companyTradeLicense;
                varValue.tradeLicense = dedupeSearchValue.companyTradeLicense;

                inputData.contactNumber1 = dedupeSearchValue.companyMobileNo;
                varValue.contactNumber1 = dedupeSearchValue.companyMobileNo;

                /*   eqmTicketNumber
                   customerName
                   shortName
                   occupationCodes
                   mailingAddress1
                   mailingAddress2
                   nationality
                   designatedEmailAddress
                   contactNumber1
                   companyEtin
                   tradeLicense*/
            } else {
                if (this.props.subServiceType !== "Joint Account") {
                    inputData.designatedMobileNumber = dedupeSearchValue.phone;
                    varValue.designatedMobileNumber = dedupeSearchValue.phone;
                }


                inputData.birthCertificateNo = dedupeSearchValue.registrationNo;
                varValue.birthCertificateNo = dedupeSearchValue.registrationNo;

                inputData.eTin = dedupeSearchValue.tin;
                varValue.eTin = dedupeSearchValue.tin;

                inputData.designatedEmail = dedupeSearchValue.email;
                varValue.designatedEmail = dedupeSearchValue.email;
            }

            inputData.fdValueDate = new Date();
            varValue.fdValueDate = new Date();
            /*  let someDate = new Date();
              let x = 6;
              let numberOfMonthsToAdd = parseInt(x, 10)
              someDate.setMonth(someDate.getMonth() + numberOfMonthsToAdd);
              let dates = someDate.getDate();
              let month = someDate.getMonth() + 1;
              let year = someDate.getFullYear();
              let finalDate = dates + '-' + month + '-' + year
              inputData.fdMaturityDate = finalDate;
              varValue.fdMaturityDate = someDate;*/

            inputData.dpsSi = new Date();
            varValue.dpsSi = new Date();
            inputData.instaCardType = "CITYMAXX";
            varValue.instaCardType = "CITYMAXX";

            if (this.props.subServiceType === "Proprietorship A/C" || this.props.subServiceType === "NONINDIVIDUAL" || this.props.subServiceType === "Company Account") {
                inputData.accType = "CURRENT";
                varValue.accType = "CURRENT";
                inputData.pageOfChequeBookCurrent = "50";
                varValue.pageOfChequeBookCurrent = "50";
            } else {
                inputData.accType = "SAVINGS";
                varValue.accType = "SAVINGS";
                inputData.pageOfChequeBookSavings = "25";
                varValue.pageOfChequeBookSavings = "25";
            }

            /*  if (this.props.subServiceType === "Joint Account" && inputData.mobileTypecontactNumberJoint!==true) {
                  //inputData.customerName = this.props.getInputData.customerName;
                  inputData.designatedEmail = this.props.getInputData.jointEmail;
                  inputData.designatedMobileNumber = this.props.getInputData.jointMobileNumber;
                  varValue.designatedEmail = this.props.getInputData.jointEmail;
                  varValue.designatedMobileNumber = this.props.getInputData.jointMobileNumber;
              }*/
            this.setState({
                getNewCase: true,
                //inputData:JSON.parse(JSON.stringify(dedupeSearchValue)),
                inputData: inputData,
                varValue: varValue,
                showValue: true,
                getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                loading: false


            });
        }


    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    renderDefferalData = () => {


        if (this.state.getDeferalList.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Deferral List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Document Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        }

    };

    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    };

    renderAddButtonShow = () => {
        if (this.props.serviceType !== "internet_banking" && this.props.serviceType !== "adc_service" && this.state.getData && this.state.inputData.apiButton === "UPDATE") {

            return (
                <React.Fragment>
                    <button
                        className="btn btn-outline-primary btn-sm"

                        style={{
                            width: 100,
                            float: 'right',
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={this.addClick.bind(this)}


                    >Add Deferral
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"

                        style={{
                            width: 100,
                            float: 'right',
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={this.addClickWaiver.bind(this)}


                    >Add Waiver
                    </button>
                </React.Fragment>

            )

        }
    };

    docsTypeChange = (data) => {
        console.log(data)

    };

    //Deferal
    dynamicTypeOfDeferral = (i) => {
        let field = JSON.parse(JSON.stringify(typeofDeferralDocs));
        field.varName = "typeofDeferralDocs" + i;
        // field.removeDefaultValue=true
        // field.setVariable="deferalType" + i
        return SelectComponent.select(this.state, this.updateComponent, field);
    };

    dynamicDeferral = (i) => {
        if ((this.state.inputData["typeofDeferralDocs" + i]) === "Regulatory") {
            let field = JSON.parse(JSON.stringify(
                {
                    "varName": "deferalType",
                    "type": "select",
                    "label": "Document Type",
                    "enum": this.state.SDRegulatory,
                    "conditional": true,
                    "conditionalVarName": "typeofDeferralDocs" + i,
                    "conditionalVarValue": "Regulatory",

                }
            ));
            field.varName = "deferalType" + i;
            return SelectComponent.select(this.state, this.updateComponent, field);
        } else if ((this.state.inputData["typeofDeferralDocs" + i]) === "Non Regulatory") {
            let field = JSON.parse(JSON.stringify(
                {
                    "varName": "deferalType",
                    "type": "select",
                    "label": "Document Type",
                    "enum": this.state.SDNonRegulatory,
                    "conditional": true,
                    "conditionalVarName": "typeofDeferralDocs" + i,
                    "conditionalVarValue": "Non Regulatory",
                }
            ));
            field.varName = "deferalType" + i;
            return SelectComponent.select(this.state, this.updateComponent, field);

        }
    };

    dynamicDeferralOther = (i) => {
        if (this.state.inputData["typeofDeferralDocs" + i] === "Regulatory" || this.state.inputData["typeofDeferralDocs" + i] === "Non Regulatory") {

            if (this.state.inputData["deferalType" + i] === "Others") {
                let field = JSON.parse(JSON.stringify(deferalOther));
                field.varName = "deferalOther" + i;
                return TextFieldComponent.text(this.state, this.updateComponent, field);
            }
        }
    };

    dynamicDate = (i) => {
        if (this.state.inputData["deferalType" + i] !== undefined) {
            let field = JSON.parse(JSON.stringify(date));
            field.varName = "expireDate" + i;
            return DateComponentDeferal.date(this.state, this.updateComponent, field);
        }
    };

    //Waiver
    dynamicTypeOfWaiver = (i) => {

        let field = JSON.parse(JSON.stringify(typeofWaiverDocs));
        field.varName = "typeofWaiverDocs" + i;
        // field.removeDefaultValue=true
        //         // field.setVariable="waiverType" + i
        return SelectComponent.select(this.state, this.updateComponent, field);
    };

    dynamicWaiver = (i) => {
        if (this.state.inputData["typeofWaiverDocs" + i] === "Regulatory") {
            let field = JSON.parse(JSON.stringify(
                {
                    "varName": "waiverType",
                    "type": "select",
                    "label": "Waiver Type",
                    "enum": this.state.SDRegulatory,
                    "conditional": true,
                    "conditionalVarName": "typeofWaiverDocs" + i,
                    "conditionalVarValue": "Regulatory",
                }
            ));
            field.varName = "waiverType" + i;
            return SelectComponent.select(this.state, this.updateComponent, field);
        } else if (this.state.inputData["typeofWaiverDocs" + i] === "Non Regulatory") {
            let field = JSON.parse(JSON.stringify(
                {
                    "varName": "waiverType",
                    "type": "select",
                    "label": "Waiver Type",
                    "enum": this.state.SDNonRegulatory,
                    "conditional": true,
                    "conditionalVarName": "typeofWaiverDocs" + i,
                    "conditionalVarValue": "Non Regulatory",
                }
            ));
            field.varName = "waiverType" + i;
            return SelectComponent.select(this.state, this.updateComponent, field);
        }

    };

    dynamicWaiverOther = (i) => {
        if (this.state.inputData["typeofWaiverDocs" + i] === "Regulatory" || this.state.inputData["typeofWaiverDocs" + i] === "Non Regulatory") {

            if (this.state.inputData["waiverType" + i] === "Others") {
                let field = JSON.parse(JSON.stringify(waiverOther));
                field.varName = "waiverOther" + i;
                return TextFieldComponent.text(this.state, this.updateComponent, field);
            }
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    handleChangeComments = () => {

    };

    onBlurFunctionForApiCall = (event, varName, secondVarName) => {
        event.preventDefault();
        if (varName === "fdrRequest") {
            let inputData = this.emptyValueRemove(this.state.inputData);
            let varValue = this.emptyValueRemove(this.state.varValue);
            inputData.interestCreditAccountApiCall = "YES";
            inputData.repaymentAccountApiCall = "YES";
            this.setState({
                loading: true,
                getDataSubServiceFDR: false

            });

            console.log(this.state.appId);

            let url = backEndServerURL + "/intgr/accountBalanceInfo/" + this.state.inputData.accountNumber;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.data.schemeCode === "SBSTF") {
                        inputData.availableAmountShow = false;
                        varValue.availableAmountShow = false

                    } else {
                        inputData.availableAmountShow = true;
                        varValue.availableAmountShow = true
                    }
                    inputData.availableAmount = response.data.acctEftvBalance;
                    varValue.availableAmount = response.data.acctEftvBalance;
                    let accountUrls = backEndServerURL + "/getAccountInfo/" + inputData.accountNumber;
                    axios.get(accountUrls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(this.state.appId);

                            if (response.data.nominationInfoList !== undefined && response.data.nominationInfoList !== null && response.data.nominationInfoList.length > 0) {
                                inputData.nomineeExistNot = "Y";
                                varValue.nomineeExistNot = "Y";
                                inputData.nomineeExistNots = "Y";
                                varValue.nomineeExistNots = "Y"
                            } else {
                                inputData.nomineeExistNot = "N";
                                varValue.nomineeExistNot = "N";
                                inputData.nomineeExistNots = "N";
                                varValue.nomineeExistNots = "N"
                            }
                            let objects = {
                                currency: inputData.currency.key,
                            };
                            if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
                                objects.natureAccount = "Individual-Retail";
                            } else {
                                objects.natureAccount = "Non-Individual"
                            }
                            let Urls = backEndServerURL + "/FDRDPSSchemeCode/getAccountType";
                            axios.post(Urls, objects, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((getAccountType) => {
                                    let jsonArrayServiceList = CSJsonFormForFDRService;
                                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                        let jsonObjects = jsonArrayServiceList[i];
                                        if (jsonObjects.varName === "accountsType") {
                                            CSJsonFormForFDRService[i].enum = getAccountType.data;
                                        }
                                    }
                                    inputData.accountTypeApiCall = true;
                                    varValue.accountTypeApiCall = true;
                                    console.log(inputData);
                                    console.log(varValue);
                                    this.setState({
                                        getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                        varValue: this.emptyValueRemove(varValue),
                                        inputData: this.emptyValueRemove(inputData),
                                        showValue: true,
                                        // appId: this.props.appId,
                                        loading: false
                                    });
                                })
                                .catch((error) => {
                                    this.setState({
                                        getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                        varValue: this.emptyValueRemove(varValue),
                                        inputData: this.emptyValueRemove(inputData),
                                        showValue: true,
                                        // appId: this.props.appId,
                                        loading: false
                                    });
                                    console.log(error);
                                })

                                /*   this.setState({
                                       getData: true,getDataSubServiceFDR:true,getDataSubServiceDPS:true,
                                       varValue: this.emptyValueRemove(varValue),
                                       inputData: this.emptyValueRemove(inputData),
                                       showValue: true,
                                       appId: this.props.appId,
                                       loading: false
                                   });
                               })*/
                                .catch((error) => {
                                    this.setState({
                                        getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                        loading: false,
                                        varValue: this.emptyValueRemove(varValue),
                                        inputData: this.emptyValueRemove(inputData),
                                    });
                                })
                        })
                        .catch((error) => {
                            this.setState({
                                getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                loading: false,
                                varValue: this.emptyValueRemove(varValue),
                                inputData: this.emptyValueRemove(inputData),
                            });
                        })

                })
                .catch((error) => {
                    this.setState({
                        getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                        loading: false,
                        varValue: this.emptyValueRemove(varValue),
                        inputData: this.emptyValueRemove(inputData),
                    });
                })
        } else if (varName === "interestCreditAccount") {
            this.state.inputData.interestCreditAccountApiCall = "NO";
            this.setState({
                loading: true
            });
            let accountUrls = backEndServerURL + "/GetCustomAcctDetailsRequest/" + (this.state.inputData[secondVarName]);
            axios.get(accountUrls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.interestCreditAccountApiCall = "YES";
                    this.state.inputData.interestCreditName = response.data.acctName;
                    this.state.varValue.interestCreditName = response.data.acctName;
                    this.updateComponent();
                    this.setState({
                        showValue: true,
                        getData: true,
                        loading: false,
                    })

                })
                .catch((error) => {
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: this.errorObjectCheck(error),
                    })
                })
        } else if (varName === "repaymentAccount") {
            this.state.inputData.repaymentAccountApiCall = "NO";
            this.setState({
                loading: true
            });
            let accountUrls = backEndServerURL + "/GetCustomAcctDetailsRequest/" + (this.state.inputData[secondVarName]);
            axios.get(accountUrls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.repaymentAccountApiCall = "YES";
                    this.state.inputData.repaymentName = response.data.acctName;
                    this.state.varValue.repaymentName = response.data.acctName;
                    this.updateComponent();
                    this.setState({
                        showValue: true,
                        getData: true,
                        loading: false,
                    })

                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                    })
                })
        } else if (varName === "repaymentAccounts") {
            this.state.inputData.repaymentsAccountApiCall = "NO";
            this.setState({
                loading: true
            });
            let accountUrls = backEndServerURL + "/GetCustomAcctDetailsRequest/" + (this.state.inputData[secondVarName]);
            axios.get(accountUrls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.repaymentsAccountApiCall = "YES";
                    this.state.inputData.repaymentsName = response.data.acctName;
                    this.state.varValue.repaymentsName = response.data.acctName;
                    this.updateComponent();
                    this.setState({
                        showValue: true,
                        getData: true,
                        loading: false,
                    })

                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                    })
                })
        } else if (varName === "dpsRequest") {
            let inputData = this.emptyValueRemove(this.state.inputData);
            let varValue = this.emptyValueRemove(this.state.varValue);
            this.setState({
                loading: true,
                getDataSubServiceDPS: false,

            });

            let url = backEndServerURL + "/intgr/accountBalanceInfo/" + this.state.inputData.accountNumber;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.data.schemeCode === "SBSTF") {
                        inputData.availableAmountShow = false;
                        varValue.availableAmountShow = false

                    } else {
                        inputData.availableAmountShow = true;
                        varValue.availableAmountShow = true
                    }
                    inputData.availableAmount = response.data.acctEftvBalance;
                    varValue.availableAmount = response.data.acctEftvBalance;
                    let accountUrls = backEndServerURL + "/getAccountInfo/" + inputData.accountNumber;
                    axios.get(accountUrls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            varValue.repaymentAccounts = response.data.accountNumber;
                            varValue.repaymentsName = response.data.accountName;
                            varValue.repaymentsAccountApiCall = "YES";
                            if (response.data.nominationInfoList !== undefined && response.data.nominationInfoList !== null && response.data.nominationInfoList.length > 0) {
                                inputData.nomineeExistNot = "Y";
                                varValue.nomineeExistNot = "Y";
                                inputData.nomineeExistNots = "Y";
                                varValue.nomineeExistNots = "Y"
                            } else {
                                inputData.nomineeExistNot = "N";
                                varValue.nomineeExistNot = "N";
                                inputData.nomineeExistNots = "N";
                                varValue.nomineeExistNots = "N"
                            }
                            /*  this.setState({
                                  getData: true,getDataSubServiceFDR:true,getDataSubServiceDPS:true,
                                  varValue: this.emptyValueRemove(varValue),
                                  inputData: this.emptyValueRemove(inputData),
                                  showValue: true,
                                  appId: this.props.appId,
                                  loading: false
                              });*/
                            let object = {
                                currency: inputData.currency.key,
                            };
                            if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
                                object.natureAccount = "Individual-Retail";
                            } else {
                                object.natureAccount = "Non-Individual"
                            }
                            let Urls = backEndServerURL + "/FDRDPSSchemeCode/getDPSNameOfScheme";
                            axios.post(Urls, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((getAccountType) => {
                                    let jsonArrayServiceList = CSJsonFormForCasaServiceDPS;
                                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                                        let jsonObjects = jsonArrayServiceList[i];
                                        if (jsonObjects.varName === "dpsnameOfScheme") {
                                            CSJsonFormForCasaServiceDPS[i].enum = getAccountType.data;
                                        }
                                    }
                                    inputData.dpsnameOfSchemeApiCall = true;
                                    varValue.dpsnameOfSchemeApiCall = true;
                                    this.setState({
                                        getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                        varValue: this.emptyValueRemove(varValue),
                                        inputData: this.emptyValueRemove(inputData),
                                        showValue: true,
                                        // appId: this.props.appId,
                                        loading: false
                                    });
                                })
                                .catch((error) => {
                                    this.setState({
                                        getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                        loading: false,
                                        varValue: this.emptyValueRemove(varValue),
                                        inputData: this.emptyValueRemove(inputData),
                                    });
                                    console.log(error);
                                })

                        })
                        .catch((error) => {
                            this.setState({
                                getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                loading: false,
                                varValue: this.emptyValueRemove(varValue),
                                inputData: this.emptyValueRemove(inputData),
                            });
                        })
                })
                .catch((error) => {
                    this.setState({
                        getData: true, getDataSubServiceFDR: true,
                        getDataSubServiceDPS: true,
                        loading: false,
                        varValue: this.emptyValueRemove(varValue),
                        inputData: this.emptyValueRemove(inputData),
                    });
                })

        } else if (varName === "chequeBookRequest") {

            this.setState({
                loading: true
            });
            let getAccountUrl = backEndServerURL + "/getAccountInfo/" + this.state.inputData.accountNumber;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.customerTypeFlag = false;
                    this.state.varValue.customerTypeFlag = false;
                    this.forceUpdate();
                    let occupation = response.data.occupationcode ? response.data.occupationcode : "NA";
                    let codeUrl = backEndServerURL + "/getCustomerType/" + response.data.schemeCode + "/" + occupation;
                    axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            this.state.inputData.customerType = response.data.customerType;
                            this.state.varValue.customerType = response.data.customerType;
                            this.state.inputData.customerTypeFlag = true;
                            this.state.varValue.customerTypeFlag = true;
                            this.forceUpdate();
                            if (response.data.chequebookAllowed === "NO") {
                                this.setState({

                                    chequebookAllowed: "NO",
                                    errorModalBoolean: true,
                                    errorTittle: "Chequebook Not  Allowed!",
                                    loading: false
                                })
                            } else {

                                this.setState({
                                    loading: false,
                                    chequebookAllowed: "",
                                })
                            }

                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false
                            })
                        })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })


        } else if (varName === "debitCard") {
            this.setState({
                loading: true
            });
            let getAccountUrl = backEndServerURL + "/getAccountInfo/" + this.state.inputData.accountNumber;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let occupation = response.data.occupationcode ? response.data.occupationcode : "NA";
                    let codeUrl = backEndServerURL + "/getCustomerType/" + response.data.schemeCode + "/" + occupation;
                    axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            /*  this.state.inputData.customerType = response.data.customerType;
                              this.state.varValue.customerType = response.data.customerType;
                              this.state.inputData.customerTypeFlag =true;
                              this.state.varValue.customerTypeFlag =true;*/

                            if (response.data.debitCardAllowed === "NO") {
                                this.setState({

                                    debitCardAllowed: "NO",
                                    errorModalBoolean: true,
                                    errorTittle: "Debit card Not  Allowed!",
                                    loading: false
                                })
                            } else {
                                this.getCardType();
                                this.setState({
                                    loading: false,
                                    debitCardAllowed: "",
                                });
                                let start_url = backEndServerURL + "/getCustomerDebitCards/" + this.state.inputData.cbNumber;
                                axios
                                    .get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then(response => {
                                        this.state.inputData.debitCardcustomerType = response.data.clientCardDetailsList.length > 0 ? "Existing Customer" : "New Customer";
                                    })
                                    .catch(error => {
                                        console.log(error);
                                        this.setState({
                                            loading: false,
                                        })
                                    });
                            }

                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false
                            })
                        })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })


        } else if (varName === "instaClientId") {
            this.setState({
                loading: true
            });
            this.state.inputData.instaClientIdApiCall = false;
            this.state.varValue.instaClientIdApiCall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/intgr/instantDebitcard/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data.cardNoList);
                    this.setState({
                        loading: false
                    });
                    this.state.inputData.instaCardNumber = response.data.cardNo;
                    this.state.varValue.instaCardNumber = response.data.cardNo;
                    this.state.inputData.instaClientIdApiCall = true;
                    this.state.varValue.instaClientIdApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        instaDebitCardAllowed: "YES",
                    })

                })
                .catch((error) => {
                    console.log(error);

                    this.setState({
                        instaDebitCardAllowed: "NO",
                        errorModalBoolean: true,
                        errorTittle: this.errorObjectCheck(error),
                        loading: false
                    });
                    this.state.inputData.instaClientIdApiCall = true;
                    this.state.varValue.instaClientIdApiCall = true;
                    this.forceUpdate()

                })
        } else if (varName === "accountNumber") {
            this.setState({
                loading: true
            });
            this.state.inputData.confirmAndSaveButton = "NO";
            this.state.varValue.confirmAndSaveButton = "NO";
            if (this.state.inputData.dstFlag !== undefined && this.state.inputData.dstFlag !== null) {
                this.state.inputData.dstFlag = true;
                this.state.varValue.dstFlag = true;
            }
            if (this.state.inputData.agentBankingFlag !== undefined && this.state.inputData.agentBankingFlag !== null) {
                this.state.inputData.agentBankingFlag = true;
                this.state.varValue.agentBankingFlag = true;
            }
            let variableSetUrl = backEndServerURL + "/variables/" + this.state.appId;
            axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
            let accountupdateUrl = backEndServerURL + "/instaPackModifyAllowed/" + this.state.inputData[secondVarName]+"/"+this.state.appId;
            axios.get(accountupdateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.data.allowedFlag === true) {
                        this.state.inputData.branchSolId = this.findByVarNameGetKeyValue(response.data.solId, this.state.branchAllSolId);
                       this.state.varValue.branchSolId = this.findByVarNameGetKeyValue(response.data.solId, this.state.branchAllSolId);
                        if (this.state.inputData.dstFlag !== undefined && this.state.inputData.dstFlag !== null) {
                            this.state.inputData.dstFlag = "YES";
                            this.state.varValue.dstFlag = "YES";
                        }

                        this.state.inputData.confirmAndSaveButton = "YES";
                        this.state.varValue.confirmAndSaveButton = "YES";
                        this.forceUpdate();
                        setTimeout(() => {
                            this.setState({
                                inputData: this.state.inputData,
                                varValue: this.state.varValue,
                                loading: false
                            })
                        }, 500)

                    } else {
                        this.state.inputData.accountNumber = "__ERROR__";
                        this.state.varValue.accountNumber = "__ERROR__";
                        this.forceUpdate();
                        this.setState({
                            loading: false,
                            errorModalBoolean: true,
                            errorTittle: "Customer info not updated. Please update customer info by Service Delivery",
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.accountNumber = "__ERROR__";
                    this.state.varValue.accountNumber = "__ERROR__";
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: this.errorObjectCheck(error),

                            })
                        })
                })
                .catch((error)=>{
                    console.log(error)
                })
        } else if (varName === "introducerId") {
            this.setState({
                loading: true
            });
            this.state.inputData.introducerIdApiCall = "NO";
            this.state.varValue.introducerIdApiCall = "NO";
            let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.introducerName = response.data.customerName;
                    this.state.varValue.introducerName = response.data.customerName;
                    console.log(response.data);
                    /*  if( (response.data.customerName).length>2){
                          this.setState({
                              loading: false,
                              errorModalBoolean: true,
                              errorTittle: "Invalid Introducer ID",

                          })
                      }
          else{*/
                    this.state.inputData.introducerIdApiCall = "YES";
                    this.state.varValue.introducerIdApiCall = "YES";
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })

                    /*if (this.state.inputData.introducerId === this.state.inputData.cbNumber) {

                        this.setState({
                            errorModalBoolean: true,
                            errorTittle: "Applicant CB and Introducer CB can not be same!"
                        })
                        return 0;
                    }*/

                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.introducerId = "__ERROR__";
                    this.state.varValue.introducerId = "__ERROR__";
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Introducer ID",

                    })
                })
        } else if (varName === "accountNumberForNidVerified") {
            this.setState({
                loading: true
            });
            this.state.inputData.accountNumberForNidVerifiedApiCall = "NO";
            this.state.varValue.accountNumberForNidVerifiedApiCall = "NO";
            let url = backEndServerURL + "/intgr/accountBalanceInfo/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.accountNameForNidVerified = response.data.name;
                    this.state.varValue.accountNameForNidVerified = response.data.name;
                    console.log(response.data);

                    this.state.inputData.accountNumberForNidVerifiedApiCall = "YES";
                    this.state.varValue.accountNumberForNidVerifiedApiCall = "YES";
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })


                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.accountNumberForNidVerified = "__ERROR__";
                    this.state.varValue.accountNumberForNidVerified = "__ERROR__";
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Account Number",

                    })
                })
        } else if (varName === "rmCode") {
            this.setState({
                loading: true
            });
            this.state.inputData.rmCodeApiCall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.rmCodeName = response.data;
                    this.state.varValue.rmCodeName = response.data;
                    console.log(response.data);
                    this.state.inputData.rmCodeApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.rmCode = "__ERROR__";
                    this.state.varValue.rmCode = "__ERROR__";
                    this.forceUpdate();
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Invalid RM Number!",
                        loading: false
                    })

                })
        } else if (varName === "rmCodeFdr") {
            this.setState({
                loading: true
            });
            this.state.inputData.rmCodeFdrApiCall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.rmCodeFdrName = response.data;
                    this.state.varValue.rmCodeFdrName = response.data;
                    console.log(response.data);
                    this.state.inputData.rmCodeFdrApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.rmCodeFdr = "__ERROR__";
                    this.state.varValue.rmCodeFdr = "__ERROR__";
                    this.forceUpdate();
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Invalid RM Number!",
                        loading: false
                    })

                })
        } else if (varName === "rmCodeDps") {
            this.setState({
                loading: true
            });
            this.state.inputData.rmCodeDpsApiCall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.rmCodeDpsName = response.data;
                    this.state.varValue.rmCodeDpsName = response.data;
                    console.log(response.data);
                    this.state.inputData.rmCodeDpsApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.rmCodeDps = "__ERROR__";
                    this.state.varValue.rmCodeDps = "__ERROR__";
                    this.forceUpdate();
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Invalid RM Number!",
                        loading: false
                    })

                })
        } else if (varName === "monitoringRMCode") {
            this.setState({
                loading: true
            });
            this.state.inputData.monitoringRMCodeApiCall = false;
            this.state.varValue.monitoringRMCodeApiCall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.monitoringRmCodeName = response.data;
                    this.state.varValue.monitoringRmCodeName = response.data;
                    console.log(response.data);
                    this.state.inputData.monitoringRMCodeApiCall = true;
                    this.state.varValue.monitoringRMCodeApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.monitoringRMCode = "__ERROR__";
                    this.state.varValue.monitoringRMCode = "__ERROR__";
                    this.forceUpdate();
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Invalid Monitoring RM Code!",
                        loading: false
                    })
                })
        } else if (varName === "wealthManagerCode") {
            this.setState({
                loading: true
            });
            this.state.inputData.wealthManagerCodeApiCall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.wealthManagerCodeName = response.data;
                    this.state.varValue.wealthManagerCodeName = response.data;
                    console.log(response.data);
                    this.state.inputData.wealthManagerCodeApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.wealthManagerCode = "__ERROR__";
                    this.state.varValue.wealthManagerCode = "__ERROR__";
                    this.forceUpdate();
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Invalid Wealth Manager Code!",
                        loading: false
                    })
                })
        }


    };

    getCardType() {
        this.state.showProductType = false;
        let productTypeUrl = backEndServerURL + "/cmsDebitCardMasterDataCardTypes";
        axios.get(productTypeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                for (let form of this.props.serviceListLast) {
                    if (form.type === "select" && form.varName === "cardType") {
                        form.enum = response.data
                    }
                }
                this.state.showProductType = true;
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                })
            })
    }

    maturitydateCalculate = (data) => {
        let fdDate = (this.state.inputData.fdValueDate);
        let type = (this.state.inputData.tenureType);
        let tenure = (this.state.inputData.tenure);
        console.log(type);
        console.log(tenure);
        console.log(this.state.inputData.fdrSchemeCode);
        console.log(this.state.inputData.amount);
        // if (data.varName === "tenure" || data.varName === "tenureType" || data.varName === "fdValueDate" || data.varName === "fdrSchemeCode" || data.varName === "amount") {
        if (type !== undefined && tenure !== undefined && (this.state.inputData.fdrSchemeCode !== undefined || this.state.inputData.dpsSchemeCode !== undefined) && this.state.inputData.amount !== undefined) {
            let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
            let data = {};
            if (this.state.inputData.dpsSchemeCode !== undefined) {
                data.schemeCode = this.state.inputData.dpsSchemeCode.key;

            } else {
                data.schemeCode = this.state.inputData.fdrSchemeCode.key;

            }
            data.amount = this.state.inputData.amount;
            data.tenure = this.state.inputData.tenure;
            data.tenureType = this.state.inputData.tenureType;
            data.tenorType = this.state.inputData.tenureType;
            data.currency = this.state.inputData.currency;
            console.log(data);
            this.state.inputData.interestRate = "";
            this.state.varValue.interestRate = "";
            this.state.inputData.interestRateApicall = "NO";
            this.forceUpdate();
            axios.post(interestCalculateAPi, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.interestRate = response.data;
                    this.state.varValue.interestRate = response.data;
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate()
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.interestRateApicall = "YES";
                    this.forceUpdate()

                })
        }
        if ((data.varName === "tenure" || data.varName === "tenureType" || data.varName === "fdValueDate" || data.varName === "fdrSchemeCode" || data.varName === "amount") && type === "Month") {
            this.state.inputData.fdMaturityDateApicall = "NO";
            this.state.varValue.fdMaturityDateApicall = "NO";
            this.forceUpdate();
            let someDate = new Date();
            if (this.state.inputData.fdValueDate !== undefined && this.state.inputData.fdValueDate !== null && this.state.inputData.fdValueDate !== -1) {
                someDate = new Date(this.state.inputData.fdValueDate);
            }
            let x = tenure;
            let numberOfMonthsToAdd = parseInt(x, 10);
            someDate.setMonth(someDate.getMonth() + numberOfMonthsToAdd);
            let dates = someDate.getDate();
            let month = someDate.getMonth() + 1;
            if (month <= 9) {
                month = "0" + month;
            }
            if (dates <= 9) {
                dates = "0" + dates;
            }
            /*  let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
             axios.post(interestCalculateAPi, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                 .then((response) => {
                     let year = someDate.getFullYear();
                     this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                     this.state.inputData.fdMaturityDateApicall="YES";
                     this.forceUpdate()

                     console.log(this.state.inputData["fdMaturityDate"])
                 })
                 .catch((error)=>{
                     let year = someDate.getFullYear();
                     this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                     this.state.inputData.fdMaturityDateApicall="YES";
                     this.forceUpdate()

                     console.log(this.state.inputData["fdMaturityDate"])
                 })*/
            let year = someDate.getFullYear();
            let calculateDate = dates + '-' + month + '-' + year;
            if (calculateDate === "NaN-NaN-NaN") {
                calculateDate = ""
            }
            setTimeout(() => {
                this.state.inputData.fdMaturityDate = calculateDate;
                this.state.varValue.fdMaturityDate = calculateDate;
                this.state.inputData.fdMaturityDateApicall = "YES";
                this.state.varValue.fdMaturityDateApicall = "YES";
                this.forceUpdate()
            }, 1000)
        } else if ((data.varName === "tenure" || data.varName === "tenureType" || data.varName === "fdValueDate" || data.varName === "fdrSchemeCode" || data.varName === "amount") && type === "Year") {

            this.state.inputData.fdMaturityDateApicall = "NO";
            this.state.varValue.fdMaturityDateApicall = "NO";
            this.forceUpdate();
            let someDate = new Date();
            if (this.state.inputData.fdValueDate !== undefined && this.state.inputData.fdValueDate !== null && this.state.inputData.fdValueDate !== -1) {
                someDate = new Date(this.state.inputData.fdValueDate);
            }
            let x = tenure;
            let numberOfMonthsToAdd = parseInt(x, 10);
            someDate.setFullYear(someDate.getFullYear() + numberOfMonthsToAdd);
            let dates = someDate.getDate();
            let month = someDate.getMonth() + 1;
            if (month <= 9) {
                month = "0" + month;
            }
            if (dates <= 9) {
                dates = "0" + dates;
            }
            /*  let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
              axios.post(interestCalculateAPi, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                  .then((response) => {
                      let year = someDate.getFullYear();
                      this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                      this.state.inputData.fdMaturityDateApicall="YES";
                      this.forceUpdate()

                      console.log(this.state.inputData["fdMaturityDate"])
                  })
                  .catch((error)=>{
                      let year = someDate.getFullYear();
                      this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                      this.state.inputData.fdMaturityDateApicall="YES";
                      this.forceUpdate()

                      console.log(this.state.inputData["fdMaturityDate"])
                  })*/
            let year = someDate.getFullYear();
            let calculateDate = dates + '-' + month + '-' + year;
            if (calculateDate === "NaN-NaN-NaN") {
                calculateDate = ""
            }
            setTimeout(() => {

                this.state.inputData.fdMaturityDate = calculateDate;
                this.state.varValue.fdMaturityDate = calculateDate;
                this.state.inputData.fdMaturityDateApicall = "YES";
                this.state.varValue.fdMaturityDateApicall = "YES";
                this.forceUpdate()
            }, 1000)

        } else if ((data.varName === "tenure" || data.varName === "tenureType" || data.varName === "fdValueDate" || data.varName === "fdrSchemeCode" || data.varName === "amount") && type === "Day") {

            this.state.inputData.fdMaturityDateApicall = "NO";
            this.state.varValue.fdMaturityDateApicall = "NO";
            this.forceUpdate();

            let someDate = new Date();
            if (this.state.inputData.fdValueDate !== undefined && this.state.inputData.fdValueDate !== null && this.state.inputData.fdValueDate !== -1) {
                someDate = new Date(this.state.inputData.fdValueDate);
            }
            let x = tenure;
            let numberOfDaysToAdd = parseInt(x, 10);

            someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            let date = someDate.getDate();
            let month = someDate.getMonth() + 1;
            let year = someDate.getFullYear();
            if (month <= 9) {
                month = "0" + month;
            }
            if (date <= 9) {
                date = "0" + date;
            }
            let calculateDate = date + '-' + month + '-' + year;
            if (calculateDate === "NaN-NaN-NaN") {
                calculateDate = ""
            }
            setTimeout(() => {
                this.state.inputData.fdMaturityDate = calculateDate;
                this.state.varValue.fdMaturityDate = calculateDate;

                //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                this.state.inputData.fdMaturityDateApicall = "YES";
                this.state.varValue.fdMaturityDateApicall = "YES";
                this.forceUpdate()
            }, 1000)
            /*   let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";

               axios.post(interestCalculateAPi, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                   .then((response) => {
                       this.state.inputData["fdMaturityDate"] = date + '-' + month + '-' + year;
                       this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;

                       //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                       //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                       this.state.inputData.fdMaturityDateApicall="YES";
                       this.forceUpdate()

                       console.log(this.state.inputData["fdMaturityDate"])
                   })
                   .catch((error)=>{
                       this.state.inputData["fdMaturityDate"] = date + '-' + month + '-' + year;
                       this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;

                       //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                       //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                       this.state.inputData.fdMaturityDateApicall="YES";
                       this.forceUpdate()

                       console.log(this.state.inputData["fdMaturityDate"])
                   })*/


        } else {
            this.state.inputData["fdMaturityDate"] = "";
        }


    };

    maturitydateCalculateDPS = (data) => {
        let fdDate = (this.state.inputData.dpsfdValueDate);
        let type = (this.state.inputData.dpstenureType);
        let tenure = (this.state.inputData.dpstenure);
        // if (data.varName === "tenure" || data.varName === "tenureType" || data.varName === "fdValueDate" || data.varName === "fdrSchemeCode" || data.varName === "amount") {

        if (type !== undefined && tenure !== undefined && (this.state.inputData.dpsdpsSchemeCode !== undefined) && this.state.inputData.dpsamount !== undefined) {
            let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
            let data = {};
            if (this.state.inputData.dpsdpsSchemeCode !== undefined) {
                data.schemeCode = this.state.inputData.dpsdpsSchemeCode.key;

            }
            data.amount = this.state.inputData.dpsamount;
            data.tenure = this.state.inputData.dpstenure;
            data.tenureType = this.state.inputData.dpstenureType;
            data.tenorType = this.state.inputData.tenureType;
            data.currency = this.state.inputData.currency;
            this.state.inputData.dpsinterestRate = "";
            this.state.varValue.dpsinterestRate = "";
            this.state.inputData.dpsinterestRateApicall = "NO";
            this.forceUpdate();
            axios.post(interestCalculateAPi, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.dpsinterestRate = response.data;
                    this.state.varValue.dpsinterestRate = response.data;
                    this.state.inputData.dpsinterestRateApicall = "YES";
                    this.forceUpdate()
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.dpsinterestRateApicall = "YES";
                    this.forceUpdate()

                })
        }
        if ((data.varName === "dpstenure" || data.varName === "dpstenureType" || data.varName === "dpsfdValueDate" || data.varName === "dpsamount") && type === "Month") {
            this.state.inputData.dpsfdMaturityDateApicall = "NO";
            this.state.varValue.dpsfdMaturityDateApicall = "NO";
            this.forceUpdate();
            let someDate = new Date();
            if (this.state.inputData.dpsfdValueDate !== undefined && this.state.inputData.dpsfdValueDate !== null && this.state.inputData.dpsfdValueDate !== -1) {
                someDate = new Date(this.state.inputData.dpsfdValueDate);
            }
            let x = tenure;
            let numberOfMonthsToAdd = parseInt(x, 10);
            someDate.setMonth(someDate.getMonth() + numberOfMonthsToAdd);
            let dates = someDate.getDate();
            let month = someDate.getMonth() + 1;
            if (month <= 9) {
                month = "0" + month;
            }
            if (dates <= 9) {
                dates = "0" + dates;
            }
            /*  let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
             axios.post(interestCalculateAPi, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                 .then((response) => {
                     let year = someDate.getFullYear();
                     this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                     this.state.inputData.fdMaturityDateApicall="YES";
                     this.forceUpdate()

                     console.log(this.state.inputData["fdMaturityDate"])
                 })
                 .catch((error)=>{
                     let year = someDate.getFullYear();
                     this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                     this.state.inputData.fdMaturityDateApicall="YES";
                     this.forceUpdate()

                     console.log(this.state.inputData["fdMaturityDate"])
                 })*/
            let year = someDate.getFullYear();
            let calculateDate = dates + '-' + month + '-' + year;
            if (calculateDate === "NaN-NaN-NaN") {
                calculateDate = ""
            }
            setTimeout(() => {
                this.state.inputData.dpsfdMaturityDate = calculateDate;
                this.state.varValue.dpsfdMaturityDate = calculateDate;
                this.state.inputData.dpsfdMaturityDateApicall = "YES";
                this.state.varValue.dpsfdMaturityDateApicall = "YES";
                this.forceUpdate()
            }, 1000)
        } else if ((data.varName === "dpstenure" || data.varName === "dpstenureType" || data.varName === "dpsfdValueDate" || data.varName === "dpsamount") && type === "Year") {

            this.state.inputData.dpsfdMaturityDateApicall = "NO";
            this.state.varValue.dpsfdMaturityDateApicall = "NO";
            this.forceUpdate();
            let someDate = new Date();
            if (this.state.inputData.dpsfdValueDate !== undefined && this.state.inputData.dpsfdValueDate !== null && this.state.inputData.dpsfdValueDate !== -1) {
                someDate = new Date(this.state.inputData.dpsfdValueDate);
            }
            let x = tenure;
            let numberOfMonthsToAdd = parseInt(x, 10);
            someDate.setFullYear(someDate.getFullYear() + numberOfMonthsToAdd);
            let dates = someDate.getDate();
            let month = someDate.getMonth() + 1;
            if (month <= 9) {
                month = "0" + month;
            }
            if (dates <= 9) {
                dates = "0" + dates;
            }
            /*  let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
              axios.post(interestCalculateAPi, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                  .then((response) => {
                      let year = someDate.getFullYear();
                      this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                      this.state.inputData.fdMaturityDateApicall="YES";
                      this.forceUpdate()

                      console.log(this.state.inputData["fdMaturityDate"])
                  })
                  .catch((error)=>{
                      let year = someDate.getFullYear();
                      this.state.inputData["fdMaturityDate"] = dates + '-' + month + '-' + year;
                      this.state.inputData.fdMaturityDateApicall="YES";
                      this.forceUpdate()

                      console.log(this.state.inputData["fdMaturityDate"])
                  })*/
            let year = someDate.getFullYear();
            let calculateDate = dates + '-' + month + '-' + year;
            if (calculateDate === "NaN-NaN-NaN") {
                calculateDate = ""
            }
            setTimeout(() => {

                this.state.inputData.dpsfdMaturityDate = calculateDate;
                this.state.varValue.dpsfdMaturityDate = calculateDate;
                this.state.inputData.dpsfdMaturityDateApicall = "YES";
                this.state.varValue.dpsfdMaturityDateApicall = "YES";
                this.forceUpdate()
            }, 1000)

        } else if ((data.varName === "dpstenure" || data.varName === "dpstenureType" || data.varName === "dpsfdValueDate" || data.varName === "fdrSchemeCode" || data.varName === "dpsamount") && type === "Day") {

            this.state.inputData.dpsfdMaturityDateApicall = "NO";
            this.state.varValue.dpsfdMaturityDateApicall = "NO";
            this.forceUpdate();

            let someDate = new Date();
            if (this.state.inputData.dpsfdValueDate !== undefined && this.state.inputData.dpsfdValueDate !== null && this.state.inputData.dpsfdValueDate !== -1) {
                someDate = new Date(this.state.inputData.dpsfdValueDate);
            }
            let x = tenure;
            let numberOfDaysToAdd = parseInt(x, 10);

            someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            let date = someDate.getDate();
            let month = someDate.getMonth() + 1;
            let year = someDate.getFullYear();
            if (month <= 9) {
                month = "0" + month;
            }
            if (date <= 9) {
                date = "0" + date;
            }
            let calculateDate = date + '-' + month + '-' + year;
            if (calculateDate === "NaN-NaN-NaN") {
                calculateDate = ""
            }
            setTimeout(() => {
                this.state.inputData.dpsfdMaturityDate = calculateDate;
                this.state.varValue.dpsfdMaturityDate = calculateDate;

                //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                this.state.inputData.dpsfdMaturityDateApicall = "YES";
                this.state.varValue.dpsfdMaturityDateApicall = "YES";
                this.forceUpdate()
            }, 1000)
            /*   let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";

               axios.post(interestCalculateAPi, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                   .then((response) => {
                       this.state.inputData["fdMaturityDate"] = date + '-' + month + '-' + year;
                       this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;

                       //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                       //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                       this.state.inputData.fdMaturityDateApicall="YES";
                       this.forceUpdate()

                       console.log(this.state.inputData["fdMaturityDate"])
                   })
                   .catch((error)=>{
                       this.state.inputData["fdMaturityDate"] = date + '-' + month + '-' + year;
                       this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;

                       //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                       //this.state.varValue["fdMaturityDate"] = date + '-' + month + '-' + year;
                       this.state.inputData.fdMaturityDateApicall="YES";
                       this.forceUpdate()

                       console.log(this.state.inputData["fdMaturityDate"])
                   })*/


        } else {
            this.state.inputData["dpsfdMaturityDate"] = "";
        }


    };

    functionForGetProductType = (value, type) => {
        this.state.inputData.showProductType = false;
        let productTypeUrl = backEndServerURL + "/cmsDebitCardMasterDataProductTypes/" + value;
        axios.get(productTypeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    for (let form of this.props.serviceListLast) {
                        if (form.type === "select" && form.varName === "productType") {
                            form.enum = response.data
                        }
                    }
                    this.state.inputData.showProductType = true;
                    this.forceUpdate();
                    if (type === "New") {
                        this.setState({
                            loading: false
                        });
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    errorModalBoolean: true,
                    errorTitle: "Product Type Not Found!",
                    loading: false
                });
            })
    };

    onKeyDownChange = (data) => {
        let inputData = this.state.inputData;
        let varValue = this.state.varValue;
        this.updateComponent();
        let allInputData = this.state.inputData;
        if (data.request === "FDR" && (data.varName === "tenure" || data.varName === "tenureType" || data.varName === "fdValueDate" || data.varName === "fdrSchemeCode" || data.varName === "amount")) {
            this.maturitydateCalculate(data)
        }
        if (data.request === "DPS" && (data.varName === "dpstenure" || data.varName === "dpstenureType" || data.varName === "dpsfdValueDate" || data.varName === "dpsfdrSchemeCode" || data.varName === "dpsamount")) {
            this.maturitydateCalculateDPS(data)
        }

        if (data.varName === "debitAccountStatement") {
            this.setState({
                accountStatement: true
            })
        }
        if (data.varName === "signatureCardView") {
            this.setState({
                signatureCardView: true
            })
        }

        if (data.varName === "cardType") {
            this.setState({
                loading: true
            });
            this.functionForGetProductType(this.state.inputData.cardType, "New")
        }

        if (data.varName === "sectorCode") {
            this.setState({
                loading: true
            });
            this.state.inputData.sectorCodeApicall = false;
            let codeUrl = backEndServerURL + "/finMasterData/custom/subSector/" + this.state.inputData.sectorCode["key"];//{sectorCode}
            axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    {
                        this.findByVarNameApiData("subSectorCode", response.data)
                    }
                    this.state.inputData.sectorCodeApicall = true;
                    this.forceUpdate();
                    this.setState({
                        loading: false
                    })

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })
        } else if (data.varName === "smsAlertRequest") {
            if (this.state.inputData.smsAlertRequest === "N") {
                this.setState({
                    errorModalBoolean: true,
                    errorTittle: "please add service discontinous form",
                })
            }
        } else if (data.varName === "ccepCode") {
            this.setState({
                loading: true
            });
            this.state.inputData.ccepCodeIdApiCall = "NO";
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/ccepCode/" + this.state.inputData.ccepCode["key"];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.ccepCompanyName = response.data;
                    this.state.varValue.ccepCompanyName = response.data;
                    console.log(response.data);
                    this.state.inputData.ccepCodeIdApiCall = "YES";
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Invalid CCEP Code!",
                        loading: false
                    })
                })
        } else if (data.request === "FDR" && (data.varName === "accountsType" || data.varName === "accountAcquisition" || data.varName === "tenureType" || data.varName === "tenure" || data.varName === "autoRenewals" || data.varName === "maturity")) {
            if (data.varName === "accountsType") {
                this.setState({
                    loading: true
                });
                this.state.inputData.accountAcquisitionApiCall = false;
                //this.state.inputData.accountTypeApiCall= false
                this.state.inputData.tenureTypeApiCall = false;
                this.state.inputData.tenureApiCall = false;
                this.state.inputData.autoRenewalsApiCall = false;
                this.state.inputData.maturityApiCall = false;
                this.state.inputData.fdrSchemeCodeApiCall = false;

                this.state.inputData.accountAcquisition = undefined;
                this.state.varValue.accountAcquisition = undefined;
                let object = {
                    currency: this.state.inputData.currency.key,
                };
                if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
                    object.natureAccount = "Individual-Retail";
                } else {
                    object.natureAccount = "Non-Individual"
                }
                object.accountType = this.state.inputData.accountsType;
                let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getAccountAcquisition";//{sectorCode}
                axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);
                        let jsonArrayServiceList = CSJsonFormForFDRService;
                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            console.log(jsonObjects.varName);
                            if (jsonObjects.varName === "accountAcquisition") {
                                CSJsonFormForFDRService[i].enum = response.data;
                                console.log(CSJsonFormForFDRService[i])
                            }
                        }

                        this.state.inputData.accountAcquisitionApiCall = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            } else if (data.varName === "accountAcquisition") {
                this.setState({
                    loading: true
                });
                this.state.inputData.tenureTypeApiCall = false;
                this.state.inputData.tenureApiCall = false;
                this.state.inputData.autoRenewalsApiCall = false;
                this.state.inputData.maturityApiCall = false;
                this.state.inputData.fdrSchemeCodeApiCall = false;

                this.state.inputData.tenureType = undefined;
                this.state.varValue.tenureType = undefined;
                let object = {
                    currency: this.state.inputData.currency.key,
                };
                if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
                    object.natureAccount = "Individual-Retail";
                } else {
                    object.natureAccount = "Non-Individual"
                }
                object.accountType = this.state.inputData.accountsType;
                object.accountAcquisition = this.state.inputData.accountAcquisition;
                let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getTenureType";//{sectorCode}
                axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);
                        let jsonArrayServiceList = CSJsonFormForFDRService;
                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "tenureType") {
                                CSJsonFormForFDRService[i].enum = response.data;
                                console.log(CSJsonFormForFDRService[i])
                            }
                        }

                        this.state.inputData.tenureTypeApiCall = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            } else if (data.varName === "tenureType") {
                this.setState({
                    loading: true
                });
                this.state.inputData.tenureApiCall = false;
                this.state.inputData.autoRenewalsApiCall = false;
                this.state.inputData.maturityApiCall = false;
                this.state.inputData.fdrSchemeCodeApiCall = false;

                this.state.inputData.tenure = undefined;
                this.state.varValue.tenure = undefined;
                let object = {
                    currency: this.state.inputData.currency.key,
                };
                if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
                    object.natureAccount = "Individual-Retail";
                } else {
                    object.natureAccount = "Non-Individual"
                }
                object.accountType = this.state.inputData.accountsType;
                object.accountAcquisition = this.state.inputData.accountAcquisition;
                object.tenureType = this.state.inputData.tenureType;
                let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getTenor";//{sectorCode}
                axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);
                        let jsonArrayServiceList = CSJsonFormForFDRService;
                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "tenure") {
                                CSJsonFormForFDRService[i].enum = response.data;
                                console.log(CSJsonFormForFDRService[i])
                            }
                        }

                        this.state.inputData.tenureApiCall = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            } else if (data.varName === "tenure") {
                this.setState({
                    loading: true
                });
                this.state.inputData.autoRenewalsApiCall = false;
                this.state.inputData.maturityApiCall = false;
                this.state.inputData.fdrSchemeCodeApiCall = false;
                this.state.inputData.depositeTypeApicall = false;

                this.state.inputData.autoRenewals = undefined;
                this.state.varValue.autoRenewals = undefined;
                let object = {
                    currency: this.state.inputData.currency.key,
                };
                if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
                    object.natureAccount = "Individual-Retail";
                } else {
                    object.natureAccount = "Non-Individual"
                }
                object.accountType = this.state.inputData.accountsType;
                object.accountAcquisition = this.state.inputData.accountAcquisition;
                object.tenureType = this.state.inputData.tenureType;
                object.tenure = this.state.inputData.tenure;
                let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getRenewal";//{sectorCode}
                axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);
                        let jsonArrayServiceList = CSJsonFormForFDRService;
                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "autoRenewals") {
                                CSJsonFormForFDRService[i].enum = response.data.renewal;
                                console.log(CSJsonFormForFDRService[i])
                            }

                        }

                        this.state.inputData.depositeType = response.data.depositCode;
                        this.state.varValue.depositeType = response.data.depositCode;
                        this.state.inputData.autoRenewalsApiCall = true;
                        this.state.inputData.depositeTypeApicall = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            } else if (data.varName === "autoRenewals") {
                this.setState({
                    loading: true
                });
                this.state.inputData.maturityApiCall = false;
                this.state.inputData.fdrSchemeCodeApiCall = false;

                this.state.inputData.maturity = undefined;
                this.state.varValue.maturity = undefined;
                let object = {
                    currency: this.state.inputData.currency.key,
                };
                if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
                    object.natureAccount = "Individual-Retail";
                } else {
                    object.natureAccount = "Non-Individual"
                }
                object.accountType = this.state.inputData.accountsType;
                object.accountAcquisition = this.state.inputData.accountAcquisition;
                object.tenureType = this.state.inputData.tenureType;
                object.tenure = this.state.inputData.tenure;
                object.autoRenewals = this.state.inputData.autoRenewals;
                let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getMaturity";//{sectorCode}
                axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);
                        let jsonArrayServiceList = CSJsonFormForFDRService;
                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "maturity") {
                                CSJsonFormForFDRService[i].enum = response.data;
                                console.log(CSJsonFormForFDRService[i])
                            }
                        }

                        this.state.inputData.maturityApiCall = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            } else if (data.varName === "maturity") {
                this.setState({
                    loading: true
                });
                this.state.inputData.fdrSchemeCodeApiCall = false;

                this.state.inputData.fdrSchemeCode = undefined;
                this.state.varValue.fdrSchemeCode = undefined;
                this.state.inputData.amount = undefined;
                this.state.varValue.amount = undefined;
                let object = {
                    currency: this.state.inputData.currency.key,
                };
                if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
                    object.natureAccount = "Individual-Retail";
                } else {
                    object.natureAccount = "Non-Individual"
                }
                object.accountType = this.state.inputData.accountsType;
                object.accountAcquisition = this.state.inputData.accountAcquisition;
                object.tenureType = this.state.inputData.tenureType;
                object.tenure = this.state.inputData.tenure;
                object.autoRenewals = this.state.inputData.autoRenewals;
                object.maturity = this.state.inputData.maturity;
                let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getSchemeCode";//{sectorCode}
                axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);
                        this.state.inputData.fdrSchemeCode = response.data;
                        this.state.varValue.fdrSchemeCode = response.data;
                        this.state.inputData.fdrSchemeCodeApiCall = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            }
        } else if (data.request === "DPS" && (data.varName === "dpsnameOfScheme" || data.varName === "dpsaccountAcquisition" || data.varName === "dpstenureType" || data.varName === "dpstenure")) {
            if (data.varName === "dpsnameOfScheme") {
                this.setState({
                    loading: true
                });
                this.state.inputData.dpsaccountAcquisitionApiCall = false;
                this.state.inputData.dpsaccountAcquisition = undefined;
                this.state.varValue.dpsaccountAcquisition = undefined;

                let object = {
                    currency: this.state.inputData.currency.key,
                };
                if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
                    object.natureAccount = "Individual-Retail";
                } else {
                    object.natureAccount = "Non-Individual"
                }
                object.nameOfScheme = this.state.inputData.dpsnameOfScheme;
                let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getDPSAccountAcquisition";//{sectorCode}
                axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);
                        let jsonArrayServiceList = CSJsonFormForCasaServiceDPS;
                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "dpsaccountAcquisition") {
                                CSJsonFormForCasaServiceDPS[i].enum = response.data;
                                console.log(CSJsonFormForCasaServiceDPS[i])
                            }
                        }

                        this.state.inputData.dpsaccountAcquisitionApiCall = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            } else if (data.varName === "dpsaccountAcquisition") {
                this.setState({
                    loading: true
                });
                this.state.inputData.dpstenureTypeApiCall = false;
                this.state.inputData.dpstenureApiCall = false;
                this.state.inputData.dpsdepositTypeApiCall = false;
                this.state.inputData.dpsdpsSchemeCodeApiCall = false;

                this.state.inputData.dpstenureType = undefined;
                this.state.varValue.dpstenureType = undefined;
                let object = {
                    currency: this.state.inputData.currency.key,
                };
                if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
                    object.natureAccount = "Individual-Retail";
                } else {
                    object.natureAccount = "Non-Individual"
                }
                object.nameOfScheme = this.state.inputData.dpsnameOfScheme;
                object.accountAcquisition = this.state.inputData.dpsaccountAcquisition;
                let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getDPSTenureType";//{sectorCode}

                axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);
                        let jsonArrayServiceList = CSJsonFormForCasaServiceDPS;
                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "dpstenureType") {
                                CSJsonFormForCasaServiceDPS[i].enum = response.data;
                                console.log(CSJsonFormForCasaServiceDPS[i])
                            }
                        }

                        this.state.inputData.dpstenureTypeApiCall = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            } else if (data.varName === "dpstenureType") {
                this.setState({
                    loading: true
                });

                this.state.inputData.dpstenureApiCall = false;
                this.state.inputData.dpsdepositTypeApiCall = false;
                this.state.inputData.dpsdpsSchemeCodeApiCall = false;

                this.state.inputData.dpstenure = undefined;
                this.state.varValue.dpstenure = undefined;
                let object = {
                    currency: this.state.inputData.currency.key,
                };
                if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
                    object.natureAccount = "Individual-Retail";
                } else {
                    object.natureAccount = "Non-Individual"
                }
                object.nameOfScheme = this.state.inputData.dpsnameOfScheme;
                object.accountAcquisition = this.state.inputData.dpsaccountAcquisition;
                object.tenureType = this.state.inputData.dpstenureType;
                let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getDPSTenure";//{sectorCode}
                axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);
                        let jsonArrayServiceList = CSJsonFormForCasaServiceDPS;
                        for (let i = 0; i < jsonArrayServiceList.length; i++) {
                            let jsonObjects = jsonArrayServiceList[i];
                            if (jsonObjects.varName === "dpstenure") {
                                CSJsonFormForCasaServiceDPS[i].enum = response.data;
                                console.log(CSJsonFormForCasaServiceDPS[i])
                            }
                        }

                        this.state.inputData.dpstenureApiCall = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            } else if (data.varName === "dpstenure") {
                this.setState({
                    loading: true
                });
                this.state.inputData.dpsdepositTypeApiCall = false;
                this.state.inputData.dpsdpsSchemeCodeApiCall = false;

                this.state.inputData.dpsdpsSchemeCode = undefined;
                this.state.varValue.dpsdpsSchemeCode = undefined;

                this.state.inputData.dpsamount = undefined;
                this.state.varValue.dpsamount = undefined;
                let object = {
                    currency: this.state.inputData.currency.key,
                };
                if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
                    object.natureAccount = "Individual-Retail";
                } else {
                    object.natureAccount = "Non-Individual"
                }
                object.nameOfScheme = this.state.inputData.dpsnameOfScheme;
                object.accountAcquisition = this.state.inputData.dpsaccountAcquisition;
                object.tenureType = this.state.inputData.dpstenureType;
                object.tenure = this.state.inputData.dpstenure;
                let codeUrl = backEndServerURL + "/FDRDPSSchemeCode/getDPSSchemeCode";//{sectorCode}
                axios.post(codeUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.state.inputData.dpsdpsSchemeCode = response.data;
                        this.state.inputData.dpsdepositType = "181";
                        this.state.varValue.dpsdpsSchemeCode = response.data;
                        this.state.varValue.dpsdepositType = "181";
                        this.state.inputData.dpsdepositTypeApiCall = true;
                        this.state.inputData.dpsdpsSchemeCodeApiCall = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            }
        }


    };

    returnJsonForm = () => {
        if (this.state.getData && this.state.inputData.apiButton === "UPDATE" /*&& (this.props.subServiceType === "Joint Account" || this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C")*/) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObjec(this.props.commonJsonForm), this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)

            )
        } else if (this.state.getData && this.state.inputData.readOnlyJsonForm !== true /*&& (this.props.subServiceType === "Joint Account" || this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C")*/) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)

            )
        } else if (this.state.getData && this.state.inputData.readOnlyJsonForm === true /*&& (this.props.subServiceType === "Joint Account" || this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C")*/) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObjec(this.props.commonJsonForm), this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)

            )
        }

        /* else if (this.state.getData && this.state.inputData.newCbNumber!==undefined && (this.props.subServiceType === "Joint Account" || this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C")) {
             return (
                 CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObjec(this.props.commonJsonForm), this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)

             )
         } else if (this.state.getData && this.state.handleConfirmBoolean===false && this.props.appId!==undefined && this.state.inputData.getInitiateData===false) {
             return (
                 CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonFormSave, this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)

             )
         }
         else if (this.state.getData && this.state.handleConfirmBoolean===false) {
             return (
                 CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)

             )
         }*/
    };

    returnJsonFormForService = () => {
        if (this.props.serviceType !== "internet_banking" && this.props.serviceType !== "adc_service" && this.state.getDataSubServiceDPS && this.state.inputData.apiButton === "UPDATE") {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, CSJsonFormForCasaServiceDPS, this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)

            )


        }
    };

    /*{
     "varName": "fdValueDate",
     "type": "date",
     "label": "FD Value Date",
     "currentDate": true,
     "grid": 2,
     "conditionalArray": true,
     "conditionalArrayList": ["fdrRequest", "accountsType"],
     "conditionalArrayValue": [true, "Double Money FD Account"]
 },
 {
     "varName": "fdMaturityDate",
     "type": "date",
     "label": "FD Maturity Date",
     "monthAdd":6,
     "grid": 2,
     "conditionalArray": true,
     "conditionalArrayList": ["fdrRequest", "accountsType"],
     "conditionalArrayValue": [true, "Double Money FD Account"]
 },*/
    /*  {
          "varName": "fdValueDate",
          "type": "date",
          "label": "FD Value Date",
          "currentDate": true,
          "grid": 2,
          "conditionalArray": true,
          "conditionalArrayList": ["fdrRequest", "accountsType"],
          "conditionalArrayValue": [true, "FDR"]
      },
      {
          "varName": "fdMaturityDate",
          "type": "date",
          "monthAdd":6,
          "label": "FD Maturity Date",
          "grid": 2,
          "conditionalArray": true,
          "conditionalArrayList": ["fdrRequest", "accountsType"],
          "conditionalArrayValue": [true, "FDR"]
      },*/

    returnJsonFormForServiceFDR = () => {
        if (this.props.serviceType !== "internet_banking" && this.props.serviceType !== "adc_service" && this.state.getDataSubServiceFDR && this.state.inputData.apiButton === "UPDATE") {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, CSJsonFormForFDRService, this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)

            )


        }
    };

    /*{
        "varName": "fdValueDate",
        "type": "date",
        "label": "FD Value Date",
        "grid": 2,
        "currentDate":true,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "Monthly Interest Paying FD"]
    },
    {
        "varName": "fdMaturityDate",
        "type": "date",
        "label": "FD Maturity Date",
        "grid": 2,
        "monthAdd":6,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrRequest", "accountsType"],
        "conditionalArrayValue": [true, "Monthly Interest Paying FD"]
    },
*/
    returnDefaultValue = (fieldName) => {
        if (this.state.showValue) {
            let dateString = this.state.varValue[fieldName];

            if (dateString === -1)
                return this.state.selectedDate[fieldName];

            if (dateString === null || dateString === undefined || dateString.trim() === '') {

                /*  if(field.currentDate===true){
                       return state.selectedDate[field.varName];
                  }
                 else if(field.dayAdd!==undefined){
                      let someDate = new Date();
                      let x = field.dayAdd;
                      let numberOfDaysToAdd = parseInt(x, 10)

                      someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
                      let date = someDate.getDate();
                      let month = someDate.getMonth() + 1;
                      let year = someDate.getFullYear();
                      return month + '/' + date + '/' + year


                  }
                  else if(field.monthAdd!==undefined){
                      let someDate = new Date();
                      let x = field.monthAdd;
                      let numberOfMonthsToAdd = parseInt(x, 10)

                      someDate.setMonth(someDate.getMonth() + numberOfMonthsToAdd);
                      let date = someDate.getDate();
                      let month = someDate.getMonth() + 1;
                      let year = someDate.getFullYear();
                      return month + '/' + date + '/' + year


                  }
                  else if(field.yearAdd!==undefined){
                      let someDate = new Date();
                      let x = field.yearAdd;
                      let numberOfyearToAdd = parseInt(x, 10)

                      someDate.setFullYear(someDate.getFullYear() + numberOfyearToAdd);
                      let date = someDate.getDate();
                      let month = someDate.getMonth() + 1;
                      let year = someDate.getFullYear();
                      return month + '/' + date + '/' + year


                  }
                  else{*/
                /* this.state.selectedDate[fieldName] = new Date().toLocaleString('en-US');
                  this.state.inputData["fdValueDate"]=new Date().toLocaleString('en-US')
                  return this.state.selectedDate[fieldName];*/
                this.state.selectedDate[fieldName] = new Date();
                this.state.varValue[fieldName] = -1;
                return null;

            }

            let dateMonthYear = dateString.split(",")[0].trim();
            let seperatedDateMonthYear = dateMonthYear.split("/");
            let returnData = seperatedDateMonthYear[2] + "-" + seperatedDateMonthYear[0] + "-" + seperatedDateMonthYear[1];
            this.state.selectedDate[fieldName] = returnData;
            this.state.varValue[fieldName] = -1;
            return returnData;
            //return state.selectedDate[field.varName];
        } else {

            if (this.state.selectedDate[fieldName] === undefined)
                return null;
            else
                return this.state.selectedDate[fieldName];
        }
    };

    valueCheck = (data, date) => {
        let num = data;
        if (date === false) {
            if (typeof num === 'number' && date === false) {
                return data;
            } else {

            }
        } else if (date === true) {

            if (num !== 'NaN-NaN-NaN' && date === true) {
                return data;
            } else {

            }
        }

    };

    returnJsonFormForFDR = () => {
        if ((this.state.inputData.accountsType === "FDR" || this.state.inputData.accountsType === "Monthly Interest Paying FD") && this.state.getData && this.state.inputData.fdrRequest === true && (this.state.inputData.accountsType === "FDR" || this.state.inputData.accountsType === "Double Money FD Account" || this.state.inputData.accountsType === "Monthly Interest Paying FD")) {

            return (
                <React.Fragment>
                    <Grid item xs={2}>
                        <React.Fragment>
                            <Grid item>
                                <label className="input-label-common">FD Value Date</label>
                            </Grid>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        style={{
                                            paddingRight: 20
                                        }}

                                        /*  minDate={field.minDate===true?new Date():undefined} maxDate = {field.maxDate===true?new Date():undefined}*/
                                        value={this.state.inputData["fdValueDate"]}
                                        inputVariant="outlined"
                                        // helperText={this.state.errorMessages[field.varName]}
                                        //error={this.state.errorArray[field.varName]}
                                        //label={field.label}
                                        fullWidth
                                        onChange={(date) => {

                                            this.state.selectedDate["fdValueDate"] = date;
                                            this.state.inputData["fdValueDate"] = (date !== null) ? this.dateConverter(date.toLocaleString('en-US')) : "";
                                            /*  let someDate = date;
                                              let x = 6;
                                              let numberOfMonthsToAdd = parseInt(x, 10)
                                              someDate.setMonth(someDate.getMonth() + numberOfMonthsToAdd);
                                              let dates = someDate.getDate();
                                              let month = someDate.getMonth() + 1;
                                              let year = someDate.getFullYear();
                                              let finalDate = dates + '-' + month + '-' + year
                                              this.state.selectedDate["fdMaturityDate"] = someDate.toLocaleString('en-US')
                                              this.state.inputData["fdMaturityDate"] = finalDate.toLocaleString('en-US')*/
                                            this.forceUpdate();
                                        }}
                                        format="dd-MM-yyyy"
                                    />

                                </MuiPickersUtilsProvider>
                            </Grid>
                        </React.Fragment>
                    </Grid>
                    {this.maturitydateCalculate(this.state.inputData.tenureType, this.state.inputData.tenure)}
                    <Grid item xs={2}>
                        <React.Fragment>
                            <Grid item>
                                <label className="input-label-common">FD Maturity Date</label>
                            </Grid>
                            <Grid item>

                                <TextField

                                    variant="outlined"

                                    value={this.valueCheck(this.state.inputData["fdMaturityDate"], true)}

                                    InputProps={{

                                        readOnly: true
                                    }}

                                    //InputLabelProps={{shrink:true}}
                                    fullWidth
                                    style={{paddingRight: 20}}
                                />

                            </Grid>
                        </React.Fragment>
                    </Grid>
                </React.Fragment>

            )


        }
    };
    returnJsonFormForServiceLast = () => {
        if (this.props.serviceType !== "internet_banking" && this.props.serviceType !== "adc_service" && this.state.getData && this.state.inputData.apiButton === "UPDATE") {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.serviceListLast, this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)

            )


        }
    };
    handleChangeDebitCard = (event, option) => {
        event.preventDefault();
        if (option !== null) {
            this.state.inputData["numberOfDebitCard"] = option.title;
            this.forceUpdate();

            if (option.title > 0) {
                var sl;
                let objectForDebitCard = [];
                for (var i = 0; i < option.title; i++) {
                    sl = i + 1;
                    objectForDebitCard.push(
                        //Debit Card
                        {
                            "varName": "customer" + sl,
                            "type": "title",
                            "label": "Debit Card " + sl,
                            "grid": 12
                        },
                        {
                            "varName": "customerName" + sl,
                            "type": "text",
                            "label": "Name On Card",
                            "grid": 2,


                        },

                        {
                            "varName": "cardType" + sl,
                            "type": "select",
                            "label": "Card Type",
                            "grid": 2,
                            "enum": [
                                "VISA",
                                "MASTER",
                                "CITYMAXX",
                            ],


                        },
                        {
                            "varName": "debitCardDeliveryType" + sl,
                            "type": "select",
                            "label": "Delivery Type",
                            "enum": [
                                "Courier",
                                "Branch"
                            ],
                            "grid": 2,


                        },

                        {
                            "varName": "debitRequestkBranch" + sl,
                            "type": "select",
                            "label": "Branch Name",
                            "enum": [
                                "GULSHAN 1",
                                "MOTHIJHEEL 1",
                                "DHANMONDI",
                            ],
                            "grid": 2,
                            "conditional": true,
                            "conditionalVarName": "debitCardDeliveryType" + sl,
                            "conditionalVarValue": "Branch",

                        },
                    )

                }

                this.setState({
                    objectForDebitCard: objectForDebitCard,
                    getDebitCard: true

                });
                this.renderMultipleDebitCardForm();

            }
        }

    };
    /* handleChangeSmsAlertRequest = (event, data) => {
         event.preventDefault();
         if (data !== null) {
             if (data.title > 0) {
                  this.state.inputData.smsAlertRequest = data.title;
                  this.updateComponent();
                 if (data.title === "N") {
                     this.setState({
                         errorModalBoolean: true,
                         errorTittle: "please add service discontinous form",
                     })
                 }
             }
         }

     };*/
    redirectPage = () => {
        if (this.state.redirectPage) {
            return (<Redirect to={{
                /*pathname: '/CustomerDedupSearch',*/
                pathname: '/inbox',

            }}/>);
        }
    };
    renderMultipleDebitCardForm = () => {
        if (this.state.getDebitCard) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.state.objectForDebitCard, this.updateComponent)
            )
        }
    };
    renderNumberOfDebitCard = () => {
        if (this.state.getData && (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account")) {
            return (
                <React.Fragment>
                    <Grid item>
                        <label className="input-label-common">Number Of Debit Card</label>
                    </Grid>
                    <Grid item>
                        <Autocomplete onChange={(event, option) => this.handleChangeDebitCard(event, option)}
                            //defaultValue={this.state.inputData["numberOfNominee"]}
                                      options={numberOfDebitCard} getOptionLabel={option => option.title}
                                      renderInput={(params) => <TextField {...params} variant="outlined"

                                                                          style={{paddingRight: 20}} fullWidth/>}
                        />
                    </Grid>
                </React.Fragment>
            )
        }
    };

    functionForRemarksSave = () => {
        let commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.csRemarks + "/" + this.props.appId;
        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {

             });
        this.state.inputData.csRemarks = undefined;
    };

    handleSubmit = () => {
        //event.preventDefault();
        //{this.validationForHandleConfirm()}
        this.setState({
            loading: true
        });
        let checkBranchingAccountNumber=  this.state.inputData.branchingAccountNumber===undefined;
         let saveDataDeferralRemarksAndRoute = {};
        if (this.state.chequebookAllowed === "NO" && this.state.inputData.chequeBookRequest) {
            this.setState({
                chequebookAllowed: "NO",
                errorModalBoolean: true,
                errorTittle: "Chequebook Not  Allowed!",
                loading: false
            });
            return 0;
        }
        if (this.state.inputData["dpsRequest"] === true && checkBranchingAccountNumber) {
            if (Number(this.state.inputData.availableAmount) < Number(this.state.inputData.dpsamount)) {
                this.setState({
                    loading: false,
                    errorModalBoolean: true,
                    errorTittle: "Insufficient Fund",
                });
                return 0;
            } else if (this.state.inputData.dpsamount < 500) {
                this.setState({
                    loading: false,
                    errorModalBoolean: true,
                    errorTittle: "Minimum balance Should be 500",
                });
                return 0;
            }

        }
        if (this.state.inputData["fdrRequest"] === true && checkBranchingAccountNumber) {
            if (Number(this.state.inputData.availableAmount) < Number(this.state.inputData.amount)) {
                this.setState({
                    errorModalBoolean: true,
                    errorTittle: "Insufficient Fund",
                    loading: false,
                });
                return 0;
            } else if (this.state.inputData.amount < 10000) {
                this.setState({
                    loading: false,
                    errorModalBoolean: true,
                    errorTittle: "Minimum balance Should be 10000",
                });
                return 0;
            }

        }
        if (this.state.debitCardAllowed === "NO" && this.state.inputData.debitCard) {
            this.setState({
                debitCardAllowed: "NO",
                errorModalBoolean: true,
                errorTittle: "Debit Card Not  Allowed!",
                loading: false
            });
            return 0;
        }
        if (this.state.instaDebitCardAllowed === "NO" && this.state.inputData.instaDebitCard) {
            this.setState({
                instaDebitCardAllowed: "NO",
                errorModalBoolean: true,
                errorTittle: "Insta Debit Card Not  Allowed!",
                loading: false
            });
            return 0;
        }

        console.log(this.state.inputData);
        if (this.state.inputData["csDeferal"] === "YES") {

            var defType = [];
            var expDate = [];
            var typeofDeferralDocsList = [];

            let appId = this.state.appId;
            for (let i = 0; i < this.state.values.length; i++) {
                let value = this.state.values[i];
                let defferalType = this.state.inputData["deferalType" + value];
                let deferralDocs = this.state.inputData["typeofDeferralDocs" + value];
                if (defferalType === "Others") {
                    defferalType = this.state.inputData["deferalOther" + value];
                }
                typeofDeferralDocsList.push(deferralDocs);
                defType.push(defferalType);
                let expireDate = this.state.inputData["expireDate" + value];
                expDate.push(expireDate);

                console.log(expDate)
            }
            if (this.state.values.length > 0) {
                saveDataDeferralRemarksAndRoute.bulkDeferralDTO = {
                    appId: appId,
                    type: defType,
                    dueDate: expDate,
                    docType: typeofDeferralDocsList,
                }
            }

        }
        if (this.state.inputData["csWaiver"] === "YES") {

            var waiverType = [];
            var waiverOther = [];
            var wavType = [];
            for (let i = 0; i < this.state.waiverValues.length; i++) {
                let waiverValues = this.state.waiverValues[i];
                let docType = this.state.inputData["waiverType" + waiverValues];
                if (docType === "Others") {
                    docType = this.state.inputData["waiverOther" + waiverValues];
                }
                waiverType.push(docType);
            }
            if (this.state.waiverValues.length > 0) {
                saveDataDeferralRemarksAndRoute.bulkWaiverDTO = {
                    appId: this.state.appId,
                    type: waiverType,
                }
            }
        }
        let data = this.state.inputData;
        data.cs_deferal = this.state.inputData["csDeferal"];

        if ((this.state.inputData.csRemarks !== undefined && this.state.inputData.csRemarks !== null)) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.csRemarks;
        }

        let variableSetUrl = backEndServerURL + "/variables/" + this.state.appId;


        //data.accountNumber = "1102000952001";
        /*  if(this.props.serviceType === 'DPS Opening' || this.props.serviceType === 'FDR Opening'){
              data.serviceType = this.props.serviceType;
          }*/

        // data.serviceType = this.props.serviceType;
        // data.category = "CASA";
        if (this.state.inputData.accType === "SAVINGS" && data.freeFlag4 === undefined) {
            data.freeFlag4 = data.schemeCodeForSavings.value;
        } else if (this.state.inputData.accType === "CURRENT" && data.freeFlag4 === undefined) {
            data.freeFlag4 = data.schemeCodeForCurrent.value;
        }
        if (this.state.generateNewCB !== 0) {
            data.cbNumber = this.state.generateNewCB;
        }
        if (this.state.generateNewAccountNumber !== 0) {
            data.accountNumber = this.state.generateNewAccountNumber;
        }
        // data.subCategory = "A/C Opening";
        if (this.props.appId === undefined) {
            data.jointAccountCustomerNumber = this.props.jointAccountCustomerNumber;
            data.subServiceType = this.props.subServiceType;

            if (this.state.inputData.priority === "HIGH")
                data.urgency = 1;
            else
                data.urgency = 0;
        }
        console.log(data);
        let postData = {};
        if (this.state.inputData.dpsRequest && this.state.inputData.fdrRequest) {
            postData = FunctionForInputGetAndSet.setInputDataForDPSFromCasa(data);
            postData = FunctionForInputGetAndSet.setInputData(postData);
        } else if (this.state.inputData.dpsRequest) {
            postData = FunctionForInputGetAndSet.setInputDataForDPSFromCasa(postData);
        } else if (this.state.inputData.fdrRequest) {
            postData = FunctionForInputGetAndSet.setInputData(data);
        } else {
            postData = data
        }
        postData.employeeBranchSolId= LocalstorageEncrypt.encryptStorageFunction.getItem("solId")
        saveDataDeferralRemarksAndRoute.map = postData;
        data = {};
        data.accountType = this.state.inputData.accType;
        if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
            data.accountCategory = "individual";
            data.accountType = this.state.inputData.accType;
        } else {
            data.accountCategory = "nonindividual";
            data.accountType = this.state.inputData.accTypes;
        }

        data.cbNumber = this.state.inputData.cbNumber;
       // data.accountNumber = this.props.accountNumber;
        var url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
        axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log("Successfully Routed!");

                {
                    this.close();
                }
                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                    loading: false,
                    redirectPage: true,

                })


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })


            });


    };


    handleSubmitDraftWithoutClose = (accountNumber) => {

        let variableSetUrl = backEndServerURL + "/save/" + this.state.appId;

        let data = this.state.inputData;
        data.cs_deferal = this.state.inputData["csDeferal"];
        data.getInitiateData = true;
        //data.serviceType = "FDR Opening";
        // data.category = "FDR";
        if (data.interestCreditAccount === undefined || data.interestCreditAccount === null) {
            data.interestCreditAccount = accountNumber;
            data.interestCreditName = this.state.inputData.customerName;
            data.interestCreditAccountApiCall = "YES";

        }
        if (data.repaymentAccount === undefined || data.repaymentAccount === null) {
            data.repaymentAccount = accountNumber;
            data.repaymentName = this.state.inputData.customerName;
            data.repaymentAccountApiCall = "YES";
        }
        if (data.repaymentAccounts === undefined || data.repaymentAccounts === null) {
            data.repaymentAccounts = accountNumber;

        }


        // data.subCategory = "Opening";
        data.jointAccountCustomerNumber = this.props.jointAccountCustomerNumber;
        /*   if (this.props.subServiceType === "Individual A/C") {
               data.subServiceType = "INDIVIDUAL";
           } else {
               data.subServiceType = this.props.subServiceType;
           }*/

        // data.dueDate=this.state.dueDate;
        // data.type=this.state.type;

        if (this.state.inputData.priority === "HIGH")
            data.urgency = 1;
        else
            data.urgency = 0;
        console.log(data);
        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.functionForRemarksSave()
                this.setState({
                    inputData: this.emptyValueRemove(data),
                    varValue: this.emptyValueRemove(data),
                    title: "Successfull!",
                    notificationMessage: "Successfully Saved!",
                    alert: true,
                    loading: false
                })


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });

    };
    handleSubmitDraft = () => {
        if (this.state.inputData["csDeferal"] === "YES") {
            var defType = [];
            var expDate = [];
            var typeofDeferralDocsList = [];

            let appId = this.state.appId;
            for (let i = 0; i < this.state.values.length; i++) {
                let value = this.state.values[i];
                let defferalType = this.state.inputData["deferalType" + value];
                let deferralDocs = this.state.inputData["typeofDeferralDocs" + value];
                if (defferalType === "Others") {
                    defferalType = this.state.inputData["deferalOther" + value];
                }
                typeofDeferralDocsList.push(deferralDocs);
                defType.push(defferalType);
                let expireDate = this.state.inputData["expireDate" + value];
                expDate.push(expireDate);

                console.log(expDate)
            }

            let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";
            axios.post(deferalRaisedUrl, {
                appId: appId,
                type: defType,
                dueDate: expDate,
                docType: typeofDeferralDocsList,
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        if (this.state.inputData["csWaiver"] === "YES") {
            let appId = this.state.appId;
            var waiverType = [];
            var waiverOther = [];
            var wavType = [];
            for (let i = 0; i < this.state.waiverValues.length; i++) {
                let waiverValues = this.state.waiverValues[i];
                let docType = this.state.inputData["waiverType" + waiverValues];
                if (docType === "Others") {
                    docType = this.state.inputData["waiverOther" + waiverValues];
                }
                waiverType.push(docType);
            }
            let waiverRaisedUrl = backEndServerURL + "/waiver/create/bulk";
            axios.post(waiverRaisedUrl, {
                appId: appId,
                type: waiverType,
                dueDate: expDate
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        let variableSetUrl = backEndServerURL + "/save/" + this.state.appId;
        this.setState({
            loading: true,
        });
        let data = this.state.inputData;
        data.cs_deferal = this.state.inputData["csDeferal"];
        //  data.serviceType = "Account Opening";
        // data.category = "CASA";
        //data.subCategory = "A/C Opening";
        data.jointAccountCustomerNumber = this.props.jointAccountCustomerNumber;
        data.subServiceType = this.props.subServiceType;
        // data.dueDate=this.state.dueDate;
        // data.type=this.state.type;

        if (this.state.inputData.priority === "HIGH")
            data.urgency = 1;
        else
            data.urgency = 0;
        console.log(data);
        let postData = {};
        if (this.state.inputData.dpsRequest && this.state.inputData.fdrRequest) {
            postData = FunctionForInputGetAndSet.setInputDataForDPSFromCasa(data);
            postData = FunctionForInputGetAndSet.setInputData(postData);
        } else if (this.state.inputData.dpsRequest) {
            postData = FunctionForInputGetAndSet.setInputDataForDPSFromCasa(postData);
        } else if (this.state.inputData.fdrRequest) {
            postData = FunctionForInputGetAndSet.setInputData(data);
        } else {
            postData = data
        }
        axios.post(variableSetUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.functionForRemarksSave()

                if (this.state.actionType === "handleSubmitDraft") {
                    this.close();

                }
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Saved!",
                    alert: true,
                    loading: false,
                    redirectPage: true,

                })

                //


            })
            .catch((error) => {
                console.log(error)
            });

    };
    handleSubmitDocumentUpload = () => {
        if (this.state.inputData["csDeferal"] === "YES") {
            var defType = [];
            var expDate = [];
            var typeofDeferralDocsList = [];

            let appId = this.state.appId;
            for (let i = 0; i < this.state.values.length; i++) {
                let value = this.state.values[i];
                let defferalType = this.state.inputData["deferalType" + value];
                let deferralDocs = this.state.inputData["typeofDeferralDocs" + value];
                if (defferalType === "Others") {
                    defferalType = this.state.inputData["deferalOther" + value];
                }
                typeofDeferralDocsList.push(deferralDocs);
                defType.push(defferalType);
                let expireDate = this.state.inputData["expireDate" + value];
                expDate.push(expireDate);

                console.log(expDate)
            }

            let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";
            axios.post(deferalRaisedUrl, {
                appId: appId,
                type: defType,
                dueDate: expDate,
                docType: typeofDeferralDocsList,
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        if (this.state.inputData["csWaiver"] === "YES") {
            let appId = this.state.appId;
            var waiverType = [];
            var waiverOther = [];
            var wavType = [];
            for (let i = 0; i < this.state.waiverValues.length; i++) {
                let waiverValues = this.state.waiverValues[i];
                let docType = this.state.inputData["waiverType" + waiverValues];
                if (docType === "Others") {
                    docType = this.state.inputData["waiverOther" + waiverValues];
                }
                waiverType.push(docType);
            }
            let waiverRaisedUrl = backEndServerURL + "/waiver/create/bulk";
            axios.post(waiverRaisedUrl, {
                appId: appId,
                type: waiverType,
                dueDate: expDate
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        let variableSetUrl = backEndServerURL + "/save/waitingForFileUpload/" + this.state.appId;
        this.setState({
            loading: true,
        });
        let data = this.state.inputData;
        data.cs_deferal = this.state.inputData["csDeferal"];
        //  data.serviceType = "Account Opening";
        // data.category = "CASA";
        //data.subCategory = "A/C Opening";
        data.jointAccountCustomerNumber = this.props.jointAccountCustomerNumber;
        data.subServiceType = this.props.subServiceType;
        // data.dueDate=this.state.dueDate;
        // data.type=this.state.type;

        if (this.state.inputData.priority === "HIGH")
            data.urgency = 1;
        else
            data.urgency = 0;
        data.documentFileUploadButton=true;
        console.log(data);
        let postData = {};
        if (this.state.inputData.dpsRequest && this.state.inputData.fdrRequest) {
            postData = FunctionForInputGetAndSet.setInputDataForDPSFromCasa(data);
            postData = FunctionForInputGetAndSet.setInputData(postData);
        } else if (this.state.inputData.dpsRequest) {
            postData = FunctionForInputGetAndSet.setInputDataForDPSFromCasa(postData);
        } else if (this.state.inputData.fdrRequest) {
            postData = FunctionForInputGetAndSet.setInputData(data);
        } else {
            postData = data
        }
        axios.post(variableSetUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.functionForRemarksSave()

                if (this.state.actionType === "handleSubmitDocumentUpload") {
                    this.close();

                }
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Forward To Document Upload!",
                    alert: true,
                    loading: false,
                    redirectPage: true,

                })

                //


            })
            .catch((error) => {
                console.log(error)
            });

    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    addClick() {
        let randomNumber = Math.floor(Math.random() * 100000000000);
        this.setState(prevState => ({
            setAllDeferalData: true,
            values: [...prevState.values, randomNumber],

        }));

        this.state.inputData["csDeferal"] = "YES";
    };

    addClickWaiver() {
        let randomNumber = Math.floor(Math.random() * 100000000000);

        this.setState(prevState => ({
            waiverValues: [...prevState.waiverValues, randomNumber],

        }));

        this.state.inputData["csDeferal"] = "YES";
        this.state.inputData["csWaiver"] = "YES";
    };

    renderRemarks = () => {
        if (this.state.inputData.apiButton === "UPDATE" && this.state.getData) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)

            )
        }

    };
    /*  removeClick(i, event) {
          event.preventDefault();




          let customerName = [...this.state.customerName];
          customerName.splice(i, 1);
          this.setState({customerName});
          let customerId = [...this.state.customerId];
          customerId.splice(i, 1);
          this.setState({customerId});
          this.setState({
              values: values,
              customerName: customerName,
              customerId: customerId,
              setAllData: true
          });
          console.log(this.state.values)


      }*/

    /*  removeClick(i, event) {
          event.preventDefault();
          this.setState({
              setAllDeferalData: false
          })
          let values = [...this.state.values];
          values.splice(i, 1)
           let pos = this.state.values.indexOf(i);
          this.setState({
              values: values,
              setAllDeferalData: true

          })

              if (this.state.values.length > 0) {
                  this.state.inputData["csDeferal"] = "YES"
              } else {
                  this.state.inputData["csDeferal"] = "NO"
              }



      }
  */
    removeClick(i, event) {
        event.preventDefault();
        let pos = this.state.values.indexOf(i);
        if (pos > -1) {
            this.state.values.splice(pos, 1);
            this.updateComponent();
            if (this.state.values.length > 0) {
                this.state.inputData["csDeferal"] = "YES"
            } else {
                this.state.inputData["csDeferal"] = "NO"
            }
        }
    }

    removeClickWaiver(i, event) {
        event.preventDefault();
        let pos = this.state.waiverValues.indexOf(i);
        if (pos > -1) {
            this.state.waiverValues.splice(pos, 1);
            this.updateComponent();
            if (this.state.waiverValues.length > 0) {
                this.state.inputData["csDeferal"] = "YES";
                this.state.inputData["csWaiver"] = "YES"
            } else {
                this.state.inputData["csDeferal"] = "NO";
                this.state.inputData["csWaiver"] = "NO"
            }
        }
    }

    close = () => {
        this.props.closeModal();
    };
    closeIcon = () => {
        if (this.props.appId !== undefined) {
            this.props.closeIcon();
        } else {
            this.props.closeModal();
        }

    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };
    uploadSignatureModal = () => {
        this.setState({
            uploadSignatureModal: true
        })
    };

    renderUploadCheckbox = () => {
        if (this.state.getData && this.state.inputData.apiButton === "UPDATE") {
            return (
                <CheckBox
                    checked={this.state.inputData.uploadFileAlertRequest}
                    defaultValue={this.state.inputData.uploadFileAlertRequest}
                  //  name="smsAlertRequest"
                    label="SMS Alert Request"
                    onChange={(event) => this.handleChangeuploadFileAlertRequest(event)}/>


            )
        }
    };
    renderUploadButton = () => {
        if (this.state.getData && this.state.inputData.apiButton === "UPDATE") {
            return (

                <button

                    style={{
                        width: 100,

                    }}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.uploadModal}

                >
                    Upload File
                </button>
            )
        }
    };
    renderSignatureUploadButton = () => {
        if (this.props.serviceType !== "internet_banking" && this.props.serviceType !== "adc_service" && this.state.getData && this.state.inputData.apiButton === "UPDATE") {
            return (

                <button
                    style={{
                        width: 150,

                    }}
                    className="btn btn-outline-info btn-sm"
                    onClick={this.uploadSignatureModal}

                >
                    Signature Card Upload
                </button>
            )
        }
    };
    renderFlowSUmmeryButton = () => {
        if (this.state.appId !== undefined && this.state.inputData.apiButton === "UPDATE") {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.state.appId}
                                   customerName={this.state.inputData.customerName}
                                   accountNumber={this.state.inputData.accountNumber}
                                   solId={this.state.inputData.solId}/>
            )
        }
    };
    renderAssignedImage = () => {
        if (this.state.inputData.previewImage === true /* && (this.props.appId || this.state.getInitiateData) && this.state.uploadButtonClick || this.state.fileUploadData.length > 1*//* && this.state.inputData.getInitiateData === true*/) {
            return (
                <button
                    style={{
                        width: 120,

                    }}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.mappingAllImage}

                >
                    Preview Document
                </button>
            )
        }
    };

    closeModal = () => {
        this.setState({

            getMappingAllImage: false,
            errorModalBoolean: false


        })
    };
    closeUploadModal = (data) => {
        this.setState({
            uploadButtonClick: true,
            uploadModal: false,
            getMappingAllImage: false,
            uploadSignatureModal: false,
        })
    };
    submitModal = (data) => {
        this.state.inputData.previewImage = true;
        this.updateComponent();
        this.setState({
            uploadModal: false,
            getMappingAllImage: false,
            uploadSignatureModal: false,
        })

    };
    accountDetailsModal = () => {
        this.setState({
            accountDetailsModal: false,
            getInitiateData: true,
        })

        /* var url = backEndServerURL + "/case/route/" + this.state.appId;

         axios.get(url, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
             .then((response) => {
                 console.log(response.data);
                 console.log("Successfully Routed!");

                 {
                     this.close();
                 }
                 this.setState({
                     title: "Successfull!",
                     notificationMessage: "Successfully Routed!",
                     alert: true

                 })
                 //


             })
             .catch((error) => {
                  console.log(error)

             });*/


    };
    accountAndCbDetailsModal = () => {
        this.setState({
            accountAndCbDetailsModal: false,
            getInitiateData: true,
        })

    };


    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    renderMappingImageModal = () => {
        if (this.props.subServiceType === "INDIVIDUAL" || this.state.inputData.subServiceType === "Individual A/C") {

            return (
                <FileMapping condition={true} dropDownConditionOption={arrayListIndividualCondition}
                             dropDownOption={arrayListIndividual}
                             submitModal={this.submitModal}
                             appId={this.state.appId}
                             closeModal={this.closeUploadModal}/>
            )
        } else {

            return (
                <FileMapping condition={true} dropDownConditionOption={arrayListNonIndividualCondition}
                             dropDownOption={arrayListNonIndividual}
                             appId={this.state.appId}
                             submitModal={this.submitModal}
                             closeModal={this.closeUploadModal}/>
            )
        }
    };
    renderMappingSignatureImageModal = () => {
        if (this.props.subServiceType === "INDIVIDUAL" || this.state.inputData.subServiceType === "Individual A/C") {

            return (
                <FileMapping autoMapping={true} condition={true} dropDownConditionOption={arrayListAllSignatureCard}
                             dropDownOption={arrayListSignatureCard}
                             submitModal={this.submitModal}
                             appId={this.state.appId}
                             closeModal={this.closeUploadModal}/>
            )
        } else {

            return (
                <FileMapping autoMapping={true} condition={true} dropDownConditionOption={arrayListAllSignatureCard}
                             dropDownOption={arrayListSignatureCard}
                             appId={this.state.appId}
                             submitModal={this.submitModal}
                             closeModal={this.closeUploadModal}/>
            )
        }
    };
    renderMappingImageEditModal = () => {

        if (this.props.subServiceType === "INDIVIDUAL" || this.state.inputData.subServiceType === "Individual A/C") {

            return (
                <FileMappingEdit dropDownOption={arrayListIndividual}
                                 submitModal={this.submitModal}
                                 appId={this.state.appId}
                                 closeModal={this.closeUploadModal}/>
            )
        } else {

            return (
                <FileMappingEdit dropDownOption={arrayListNonIndividual}
                                 submitModal={this.submitModal}
                                 appId={this.state.appId}
                                 closeModal={this.closeUploadModal}/>
            )
        }
    };
    handleCaseClose = () => {
        let variableSetUrl = backEndServerURL + "/case/close/" + this.state.appId;
        axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);


                this.close();
                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Close!",
                    alert: true,
                    redirectPage: true,

                })

            })
            .catch((error) => {
                console.log(error)
            });

    };

    closeConfirmAlert = (data, appId) => {


        this.setState({

            confirmAlert: false,
            ConfirmAlertImage: false,
            /*  ConfirmAlertImage: false,
              notificationMessgaeCommonDps:false,
              notificationMessgaeCommon: "",
              notificationMessgaeCommonFlag: false,
              fdrImageShowPopUp:false*/
        });

        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "handleConfirm") {

            this.handleConfirm()
        } else if (data === "YES" && this.state.actionType === "handleSubmit") {

            this.handleSubmit()
        } else if (data === "YES" && this.state.actionType === "handleSubmitDraft") {

            this.handleSubmitDraft()
        }else if (data === "YES" && this.state.actionType === "handleSubmitDocumentUpload") {

            this.handleSubmitDocumentUpload()
        } else if (data === "YES" && this.state.actionType === "handleCaseClose") {

            this.handleCaseClose()
        }
    };
    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()

        {
            this.validationForHandleConfirm(type)
        }


    };
    validationForHandleConfirm = (actionType) => {


        this.setState({
            appId: this.state.appId,
            confirmAlert: false,
            ConfirmAlertImage: false,
            notificationMessgaeCommonDps: false,
            notificationMessgaeCommon: "",
            notificationMessgaeCommonFlag: false,
            fdrImageShowPopUp: false
        });
        if (actionType === "handleConfirm") {
            if(this.state.inputData.accountTypes !== "Insta Pack"){
                if(this.state.inputData.accountNumber!==undefined && this.state.inputData.accountNumber==="__ERROR__"){
                    this.state.inputData.accountNumber=null;
                    this.forceUpdate();
                }

            }
            let dependencyField = [];

            console.log(this.state.inputData);
            if (this.state.inputData["customerStatus"] === "Priority" || this.state.inputData["customerStatus"] === "Staff") {
                dependencyField.push(
                    {
                        "varName": "accountType",
                        "type": "select",
                        "errorMessage": "Error",
                        "required": true,
                        "label": "Product Type",
                        "enum": [
                            "Regular"
                        ],
                        "grid": 2,

                    })
            }
            if (this.state.inputData["customerStatus"] === "Normal Customer") {
                dependencyField.push(
                    {
                        "varName": "accountTypes",
                        "type": "select",
                        "errorMessage": "Error",
                        "label": "Product Type",
                        "required": true,
                        "enum": [
                            "Insta Pack",
                            "Regular"
                        ],
                        "grid": 2,
                    }
                )
            }


            if (this.state.inputData["customerStatus"] === "Normal Customer" && this.state.inputData["accountTypes"] === "Insta Pack") {
                dependencyField.push({
                    "varName": "accountNumber",
                    "type": "text",
                    "label": "Account Number *",
                    "required": true,
                    "errorMessage": "Error",
                    "conditionalArray": true,
                    "conditionalArrayList": ["accountTypes", "customerStatus"],
                    "conditionalArrayValue": ["Insta Pack", "Normal Customer"],
                    "grid": 2,
                },)
            }
            if (this.props.serviceType !== "ExistAccountOpening" && this.state.inputData["customerStatus"] === "Staff") {
                dependencyField.push({
                    "varName": "staffIdNumber",
                    "type": "text",
                    "label": "Staff ID Number *",
                    "asteriks": true,
                    "conditional": true,
                    "required": true,
                    "conditionalVarName": "customerStatus",
                    "conditionalVarValue": "Staff",
                    "grid": 2,


                },)
            }
            if (this.state.inputData["accType"] === "SAVINGS" || this.state.inputData["accType"] === "FCY" || this.state.inputData["accType"] === "RFCD") {
                dependencyField.push({
                    "varName": "schemeCodeForSavings",
                    "type": "autoCompleteValueReturn",
                    "label": "Scheme Code *",
                    "asteriks": true,
                    "grid": 2,
                    "required": true,

                })

            } else if (this.state.inputData["accType"] === "CURRENT") {
                dependencyField.push({
                    "varName": "schemeCodeForCurrent",
                    "type": "autoCompleteValueReturn",
                    "label": "Scheme Code *",
                    "asteriks": true,
                    "grid": 2,
                    "required": true,
                    "conditional": true,
                    "conditionalVarName": "accType",
                    "conditionalVarValue": "CURRENT",
                },)

            }
            if (this.props.serviceType === "internet_banking" || (this.props.serviceType === "adc_service" && this.props.freeFlag3 === "city_touch")) {

            } else {
                let error = MyValidation.defaultValidation(this.props.commonJsonForm.concat(dependencyField), this.state);
                this.forceUpdate();

                if (error === true) {
                    console.log("Not working");
                    return 0;
                }
            }
            let error = MyValidation.defaultValidation(csRemarks, this.state);
            this.forceUpdate();

            if (error === true) {
                console.log("Not working");
                return 0;
            }
            if(this.state.inputData["accType"] === "SAVINGS" && this.state.inputData.nationality!==undefined && this.state.inputData.nationality!==null  && this.state.inputData.nationality.key!=="BD"){
                alert("Nationality BD Required For Savings Account")

                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,

            })
            //return true
            /*  if (this.props.serviceType !== 'ExistAccountOpening' && this.state.inputData.introducerId === this.state.inputData.cbNumber) {

                  this.setState({
                      errorModalBoolean: true,
                      errorTittle: "Applicant CB and Introducer CB can not be same!"
                  })
                  return 0;
              }*/
        }
        else if (actionType === "handleSubmit") {
            let dependencyField = [];
            console.log(this.state.inputData);
            if (this.state.inputData["cityTouchRequest"] === true) {
                dependencyField.push({
                    "varName": "designatedEmail",
                    "type": "text",
                    "label": "Email Address*",
                    "validateReadOnly": true,
                    "multiline": true,
                    "required": true,
                    "errorMessage": "Error",
                    "validation": "email",
                    "grid": 2,


                },)
            }
            dependencyField.push(
                {
                    "varName": "statementFacility",
                    "type": "select",
                    "enum": [
                        "Printed Statement",
                        "E-Statement",
                        "NO"
                    ],
                    "required": true,
                    "grid": 2,
                    "label": "Statement",

                },);

            if (this.state.inputData["statementFacility"] === "E-Statement") {
                dependencyField.push({
                    "varName": "designatedEmail",
                    "type": "text",
                    "label": "Email Address*",
                    "multiline": true,
                    "required": true,
                    "validateReadOnly": true,
                    "errorMessage": "Error",
                    "validation": "email",
                    "grid": 2,


                },)
            }
            if (this.state.inputData["statementFacility"] === "E-Statement") {
                dependencyField.push({
                    "varName": "designatedEmail",
                    "type": "text",
                    "label": "Email Address*",
                    "multiline": true,
                    "required": true,
                    "validateReadOnly": true,
                    "errorMessage": "Error",
                    "validation": "email",
                    "grid": 2,


                },)
            }
            if (this.state.inputData["debitCardDeliveryTypes"] === "Branch") {
                dependencyField.push({
                    varName: "debitCardBranchName",
                    "type": "autoCompleteValueReturn",
                    "enum": [],
                    label: "Branch Name",
                    grid: 2,
                    required: true,
                    errorMessage: "Error",
                    "conditionalArray": true,
                    "conditionalArrayList": ["debitCard", "debitCardDeliveryTypes"],
                    "conditionalArrayValue": [true, "Branch"],
                },)
            }
            if (this.state.inputData["chequeBookDeliveryType"] === "Branch") {
                dependencyField.push({
                    varName: "checkbookBranchName",
                    "type": "autoCompleteValueReturn",
                    "enum": [],
                    required: true,
                    label: "Branch Name",
                    "conditionalArray": true,
                    "conditionalArrayList": ["chequeBookRequest", "chequeBookDeliveryType"],
                    "conditionalArrayValue": [true, "Branch"],
                    grid: 2,
                    errorMessage: "Error",
                    // enum: ["Gulshan", "Banani", "Dhanmondi"]
                },)
            }
            if (this.state.inputData["chequeBookRequest"] === true) {
                dependencyField.push(
                    {
                        varName: "numberOfChequeBook",
                        type: "select",
                        label: "No. of Cheque Book",
                        required: true,
                        // asterisk: true,
                        grid: 2,
                        errorMessage: "Error",
                        enum: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
                        "conditional": true,
                        "conditionalVarName": "chequeBookRequest",
                        "conditionalVarValue": true,

                    },
                    {
                        varName: "pageofChequebook",
                        type: "select",
                        label: "Page of Chequebook",
                        required: true,
                        // asterisk: true,
                        grid: 2,
                        errorMessage: "Error",
                        "conditionalArray": true,
                        "conditionalArrayList": ["chequeBookRequest", "accType"],
                        "conditionalArrayValue": [true, "SAVINGS"],
                        enum: ["10", "25"]
                    },
                    {
                        varName: "pageofChequebook",
                        type: "select",
                        label: "Page of Chequebook",
                        required: true,
                        // asterisk: true,
                        grid: 2,
                        errorMessage: "Error",
                        "conditionalArray": true,
                        "conditionalArrayList": ["chequeBookRequest", "accType"],
                        "conditionalArrayValue": [true, "CURRENT"],
                        enum: ["25", "50", "100"]
                    },

                    {
                        varName: "chequeBookDeliveryType",
                        type: "select",
                        label: "Delivery Type",
                        required: true,
                        // asterisk: true,
                        grid: 2,
                        errorMessage: "Error",
                        enum: ["Branch",],
                        "conditional": true,
                        "conditionalVarName": "chequeBookRequest",
                        "conditionalVarValue": true,
                    },

                    {
                        varName: "checkbookBranchName",
                        "type": "autoCompleteValueReturn",
                        "enum": [],
                        label: "Branch Name",
                        required: true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["chequeBookRequest", "chequeBookDeliveryType"],
                        "conditionalArrayValue": [true, "Branch"],
                        grid: 2,
                        errorMessage: "Error",
                        // enum: ["Gulshan", "Banani", "Dhanmondi"]
                    },
                    {
                        varName: "chequeBookType",
                        type: "select",
                        label: "Cheque Book Type",
                        required: true,
                        enum: ["Normal", "A4"],
                        grid: 2,
                        "conditional": true,
                        "conditionalVarName": "chequeBookRequest",
                        "conditionalVarValue": true,
                    },
                    {
                        varName: "requisitionBranch",
                        type: "autoCompleteValueReturn",
                        enum: [],
                        label: "Requisition branch",
                        grid: 2,
                        "conditional": true,
                        "conditionalVarName": "chequeBookRequest",
                        "conditionalVarValue": true,
                    },


                );
                if (this.state.inputData.chequeBookRequest === true && this.state.inputData.customerType === "Priority") {
                    dependencyField.push({
                            varName: "checqueBookDesign",
                            type: "select",
                            label: "Chequebook Design",
                            "conditionalArray": true,
                            "conditionalArrayList": ["chequeBookRequest", "customerType"],
                            "conditionalArrayValue": [true, "Priority"],
                            required: true,
                            grid: 2,
                            enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5"],
                            errorMessage: "Error"
                        },
                    )
                } else if (this.state.inputData.chequeBookRequest === true && this.state.inputData.customerType === "Sapphire") {
                    dependencyField.push(
                        {
                            varName: "checqueBookDesign",
                            type: "select",
                            label: "Chequebook Design",
                            required: true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["chequeBookRequest", "customerType"],
                            "conditionalArrayValue": [true, "Sapphire"],
                            grid: 2,
                            enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5", "Design Code 6"],
                            errorMessage: "Error"
                        },
                    )
                }
            }

            if (this.state.inputData["instaDebitCard"] === true) {
                dependencyField.push(
                    {
                        "varName": "instaDebitCardName",
                        "type": "text",
                        "label": "Name On Card",
                        "grid": 2,
                        "required": true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "instaDebitCard",
                        "conditionalVarValue": true,

                    },
                    {
                        "varName": "instaDebitCardProductType",
                        "type": "select",
                        "label": "Product Type *",
                        "enum": this.state.instaDebitCardProductType,
                        "grid": 2,
                        "required": true,
                        "conditional": true,
                        "conditionalVarName": "instaDebitCard",
                        "conditionalVarValue": true,
                    },
                    {
                        "varName": "instaCardType",
                        "type": "select",
                        "label": "Card Type",
                        "enum": [
                            "CITYMAXX"
                        ],
                        "grid": 2,
                        "required": true,
                        "conditional": true,
                        "conditionalVarName": "instaDebitCard",
                        "conditionalVarValue": true,

                    },
                    {
                        "varName": "instaClientId",
                        "type": "textApiCall",
                        "label": "Client ID",
                        "grid": 2,
                        "required": true,
                        "conditional": true,
                        "conditionalVarName": "instaDebitCard",
                        "conditionalVarValue": true,

                    }, {
                        "varName": "instaCardNumber",
                        "type": "autoCompleteValueReturn",
                        "enum": [],
                        "label": "Card Number",
                        "required": true,
                        "grid": 2,
                        "conditional": true,
                        "conditionalVarName": "instaDebitCard",
                        "conditionalVarValue": true,

                    })
            }
            if (this.state.inputData["debitCard"] === true) {
                dependencyField.push(
                    {
                        varName: "cardType",
                        type: "select",
                        label: "Card Type",
                        onKeyDown: true,
                        required: true,
                        grid: 2,
                        errorMessage: "Error",
                        enum: [],
                        "conditional": true,
                        "conditionalVarName": "debitCard",
                        "conditionalVarValue": true,
                    },
                    {
                        varName: "productType",
                        type: "select",
                        label: "Product Type",
                        required: true,
                        grid: 2,
                        errorMessage: "Error",
                        enum: [],
                        "conditionalArray": true,
                        "conditionalArrayList": ["debitCard", "showProductType"],
                        "conditionalArrayValue": [true, true],
                    },
                    {
                        varName: "nameOnCard",
                        type: "text",
                        label: "Name on Card",
                        required: true,
                        grid: 2,
                        "validationB": "length",
                        "maxLength": 19,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "debitCard",
                        "conditionalVarValue": true,
                    },
                    {
                        varName: "debitCardDeliveryTypes",
                        type: "select",
                        label: "Delivery Branch Name",
                        required: true,
                        grid: 2,
                        errorMessage: "Error",
                        enum: ["Branch",],
                        "conditional": true,
                        "conditionalVarName": "debitCard",
                        "conditionalVarValue": true,
                    },

                    {
                        varName: "debitCardBranchName",
                        "type": "autoCompleteValueReturn",
                        "enum": [],
                        required: true,
                        label: "Branch Name",
                        grid: 2,
                        errorMessage: "Error",
                        "conditionalArray": true,
                        "conditionalArrayList": ["debitCard", "debitCardDeliveryTypes"],
                        "conditionalArrayValue": [true, "Branch"],
                    },
                )
            }
            /*     if (this.state.inputData["dpsRequest"] === true) {
                     dependencyField.push(
                         {
                             "varName": "dpsAmount",
                             "type": "text",
                             "label": "Amount",
                             "validation": "numeric",
                             "grid": 2,
                             "required": true,
                             "conditional": true,
                             "conditionalVarName": "dpsRequest",
                             "conditionalVarValue": true,
                         },
                         {
                             "varName": "dpsTenorType",
                             "type": "select",
                             "label": "Tenure Type",
                             "grid": 2,
                             "enum": [
                                 "Monthly",
                             ],
                             "required": true,
                             "conditional": true,
                             "conditionalVarName": "dpsRequest",
                             "conditionalVarValue": true,
                         },
                         {
                             "varName": "dpsTenor",
                             "type": "select",
                             "enum": [
                                 "1",
                                 "3",
                                 "6",
                                 "12",
                                 "24",
                                 "36",],
                             "label": "Tenure",
                             "required": true,
                             "grid": 2,
                             "conditional": true,
                             "conditionalVarName": "dpsRequest",
                             "conditionalVarValue": true,
                         },
                         {
                             "varName": "dpsInterest",
                             "type": "text",
                             "label": "Interest",
                             "readOnly": true,
                             "grid": 2,
                             "conditional": true,
                             "conditionalVarName": "dpsRequest",
                             "conditionalVarValue": true,
                         },
                         {
                             "varName": "dpsSi",
                             "type": "date",
                             "label": "SI Date",
                             "required": true,
                             "grid": 2,
                             "conditional": true,
                             "conditionalVarName": "dpsRequest",
                             "conditionalVarValue": true,
                         },
                     )
                 }*/
            if (this.props.serviceType !== "ExistAccountOpening" && this.state.inputData["customerStatus"] === "Staff") {
                dependencyField.push({
                    "varName": "staffIdNumber",
                    "type": "text",
                    "label": "Staff ID Number *",
                    "asteriks": true,
                    "conditional": true,
                    "required": true,
                    "conditionalVarName": "customerStatus",
                    "conditionalVarValue": "Staff",
                    "grid": 2,


                },)
            }

            if (this.state.inputData["customerStatus"] === "Normal Customer" && this.state.inputData["accountTypes"] === "Insta Pack") {
                dependencyField.push({
                    "varName": "accountNumber",
                    "type": "text",
                    "label": "Account Number *",
                    "required": true,
                    "errorMessage": "Error",
                    "conditionalArray": true,
                    "conditionalArrayList": ["accountTypes", "customerStatus"],
                    "conditionalArrayValue": ["Insta Pack", "Normal Customer"],
                    "grid": 2,
                },)
            }
            if (this.state.inputData["accType"] === "SAVINGS" || this.state.inputData["accType"] === "FCY" || this.state.inputData["accType"] === "RFCD") {
                dependencyField.push({
                    "varName": "schemeCodeForSavings",
                    "type": "autoCompleteValueReturn",
                    "label": "Scheme Code *",
                    "asteriks": true,
                    "grid": 2,
                    "required": true,

                })

            } else if (this.state.inputData["accType"] === "CURRENT") {
                dependencyField.push({
                    "varName": "schemeCodeForCurrent",
                    "type": "autoCompleteValueReturn",
                    "label": "Scheme Code *",
                    "asteriks": true,
                    "grid": 2,
                    "required": true,
                    "conditional": true,
                    "conditionalVarName": "accType",
                    "conditionalVarValue": "CURRENT",
                },)

            }
            if (this.state.inputData["csWaiver"] === "YES") {
                for (let i = 0; i < this.state.waiverValues.length; i++) {
                    let value = this.state.waiverValues[i];
                    let waiverType = this.state.inputData["waiverType" + value];
                    dependencyField.push(
                        {
                            "varName": "typeofWaiverDocs" + value,
                            "type": "select",
                            "grid": 2,
                            "required": true,

                        }, {
                            "varName": "waiverType" + value,
                            "type": "select",
                            "grid": 2,
                            "required": true,

                        },
                        /*  {
                              "varName": "waiverOther" + value,
                              "type": "text",
                              "grid": 2,
                              "required": true,

                          }*/
                    );
                    if (waiverType === "Others") {
                        dependencyField.push({
                            "varName": "waiverOther" + value,
                            "type": "text",
                            "grid": 2,
                            "required": true,

                        },)
                    }
                }
            }


            if (this.state.inputData["csDeferal"] === "YES") {
                for (let i = 0; i < this.state.values.length; i++) {
                    let value = this.state.values[i];
                    let defferalType = this.state.inputData["deferalType" + value];
                    dependencyField.push({
                            "varName": "typeofDeferralDocs" + value,
                            "type": "select",
                            "grid": 2,
                            "required": true,

                        },
                        {
                            "varName": "deferalType" + value,
                            "type": "select",
                            "grid": 2,
                            "required": true,

                        }
                    );
                    if (defferalType === "Others") {
                        dependencyField.push({
                            "varName": "deferalOther" + value,
                            "type": "text",
                            "grid": 2,
                            "required": true,

                        },)
                    }
                    dependencyField.push({
                        "varName": "expireDate" + value,
                        "type": "date",
                        "grid": 2,
                        "required": true,

                    },)

                }

            }
            if (this.props.serviceType === "internet_banking" || (this.props.serviceType === "adc_service" && this.props.freeFlag3 === "city_touch")) {

            } else {
                let error = MyValidation.defaultValidation(dependencyField.concat(CSJsonFormForFDRService), this.state);
                this.forceUpdate();

                if (error === true) {
                    console.log("Not working");
                    return 0;
                }
            }


            /* if (this.props.serviceType !== 'ExistAccountOpening' && this.state.inputData.introducerId === this.state.inputData.cbNumber) {

                 this.setState({
                     errorModalBoolean: true,
                     errorTittle: "Applicant CB and Introducer CB can not be same!"
                 })
                 return 0;
             }*/
            if (this.state.inputData.uploadFileAlertRequest === true && this.state.inputData.previewImage !== true) {

                this.setState({
                    errorModalBoolean: true,
                    errorTittle: "Please Upload The File!"
                });
                return 0;
            }

            if (this.state.inputData["fdrRequest"] === true && this.state.inputData.tenureType !== undefined && this.state.inputData["interestRate"] === 0) {
                this.setState({
                    errorModalBoolean: true,
                    errorTittle: "Your Scheme Code Wrong,Please Exist Scheme Code Input"
                });
                return 0;
            }
            if (this.state.inputData.fdrRequest === true && this.state.inputData.dpsRequest === true) {
                if (this.state.inputData.currency !== undefined && (this.state.inputData.currency.key !== "BDT" && this.state.inputData.currency !== "BDT")) {
                    this.setState({
                        appId: this.state.appId,
                        actionType: actionType,
                        notificationMessgaeCommonDps: true,
                        notificationMessgaeCommon: "USD",
                        notificationMessgaeCommonFlag: true,
                        ConfirmAlertImage: true,
                        fdrImageShowPopUp: true

                    })
                } else {
                    this.setState({
                        appId: this.state.appId,
                        actionType: actionType,
                        notificationMessgaeCommonDps: true,
                        notificationMessgaeCommon: "BDT",
                        notificationMessgaeCommonFlag: true,
                        ConfirmAlertImage: true,
                        fdrImageShowPopUp: true

                    })

                }


            } else if (this.state.inputData.fdrRequest === true) {
                if (this.state.inputData.currency !== undefined && (this.state.inputData.currency.key !== "BDT" && this.state.inputData.currency !== "BDT")) {
                    this.setState({
                        actionType: actionType,
                        notificationMessgaeCommon: "USD",
                        notificationMessgaeCommonFlag: true,
                        ConfirmAlertImage: true,
                        fdrImageShowPopUp: true

                    })
                } else {
                    this.setState({
                        actionType: actionType,
                        notificationMessgaeCommon: "BDT",
                        notificationMessgaeCommonFlag: true,
                        ConfirmAlertImage: true,
                        fdrImageShowPopUp: true

                    })

                }
            } else if (this.state.inputData.dpsRequest === true) {
                this.setState({
                    actionType: actionType,
                    notificationMessgaeCommonDps: true,
                    notificationMessgaeCommonFlag: true,
                    ConfirmAlertImage: true,

                })
            } else {
                let error = MyValidation.defaultValidation(csRemarks, this.state);
                this.forceUpdate();

                if (error === true) {
                    console.log("Not working");
                    return 0;
                }
                this.setState({
                    actionType: actionType,
                    confirmAlert: true,
                })
            }


        }
        else if (actionType === "handleSubmitDraft") {
            let error = MyValidation.defaultValidation(csRemarks, this.state);
            this.forceUpdate();

            if (error === true) {
                console.log("Not working");
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })
        }
        else if (actionType === "handleSubmitDocumentUpload") {
            let error = MyValidation.defaultValidation(csRemarks, this.state);
            this.forceUpdate();

            if (error === true) {
                console.log("Not working");
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })
        }
        else if (actionType === "handleCaseClose") {
            let error = MyValidation.defaultValidation(csRemarks, this.state);
            this.forceUpdate();

            if (error === true) {
                console.log("Not working");
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })
        }
    };
    dateConverter = (value) => {
        if (value !== undefined && value !== null && value !== "") {
            return value.substr(0, 10);
        }

    };
    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "Finacle System Error!"
                }
            } else {
                return "Finacle System Error!"
            }
        } else {
            return "Finacle System Error!"
        }
    };
    variableSetFunction = (object) => {
        let data = object;
        if (data.category === undefined || data.category === null) {
            data.category = "CASA";
        }
        if (data.serviceType === undefined || data.serviceType === null) {
            data.serviceType = this.props.serviceType;
        }
        if (data.subCategory === undefined || data.subCategory === null) {
            data.subCategory = "A/C Opening";
        }
        if (data.subServiceType === undefined || data.subServiceType === null) {
            if (this.props.subServiceType === "Individual A/C") {
                data.subServiceType = "INDIVIDUAL";
            } else {
                data.subServiceType = this.props.subServiceType;
            }
        }
          if(data.accountNumberChangeNeed==="YES"){
              data.readOnlyJsonForm = true;
              let variableSetUrl = backEndServerURL + "/changeStatus/" + this.state.appId + "/ACCOUNT_OPENED";
              axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                  .then((response) => {
                          data.accountNumberChangeNeed=undefined;
                          this.setState({
                              inputData: this.emptyValueRemove(data),
                              varValue: this.emptyValueRemove(data),
                              getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                              loading: false,
                              alowApiCall: true
                          })
                  })
                  .catch((error)=>{
                      console.log(error);
                  })
          }
        else if (data.apiButton === "ACCOUNT UPDATE") {
            data.readOnlyJsonForm = true;
            let variableSetUrl = backEndServerURL + "/changeStatus/" + this.state.appId + "/ACCOUNT_OPENED";
            axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    data.accountNumberChangeNeed=undefined;
                    this.setState({
                        inputData: this.emptyValueRemove(data),
                        varValue: this.emptyValueRemove(data),
                        getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                        loading: false,
                        alowApiCall: true
                    })
                })
                .catch((error) => {
                    console.log(error)
                })
        }
         else {
            data.readOnlyJsonForm = true;
            let variableSetUrl = backEndServerURL + "/variables/" + this.state.appId;
            axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    data.accountNumberChangeNeed=undefined;
                    this.setState({
                        inputData: this.emptyValueRemove(data),
                        varValue: this.emptyValueRemove(data),
                        getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                        loading: false,
                        alowApiCall: true
                    })
                })
                .catch((error) => {
                    console.log(error)
                })
        }

    };
    handleConfirm = () => {
        this.setState({
            loading: true,
            getData: false, getDataSubServiceFDR: false, getDataSubServiceDPS: false,
            handleConfirmBoolean: false,

        });
        console.log(this.state.inputData);

        let inputData = this.state.inputData;

        let variableSetUrl = backEndServerURL + "/variables/" + this.state.appId;
        axios.get(variableSetUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if(response.data.apiButton!==undefined && response.data.apiButton!==null && response.data.apiButton!="CB UPDATE"){
                    this.state.inputData.apiButton=response.data.apiButton
                    this.state.inputData=this.emptyValueRemove(response.data);
                    this.updateComponent()
                }

        inputData.getInitiateData = false;
        inputData.fdValueDate = new Date();
        inputData.dpsfdValueDate = new Date();

        if (inputData.accType === "SAVINGS" || inputData.accType === "FCY" || inputData.accType === "RFCD") {
            inputData.schemeCode = inputData.schemeCodeForSavings;
        } else {
            inputData.schemeCode = inputData.schemeCodeForCurrent;

        }
        if (inputData.designatedEmail !== "" && inputData.designatedEmail !== undefined) {
            inputData.statementFacility = "E-Statement";
        }
        inputData.cs_deferal = this.state.inputData["csDeferal"];
        inputData.dob = this.dateConverter(this.state.inputData.dob);
        //inputData.serviceType = this.props.serviceType
        inputData.sdnData = this.state.sdnData;
        //  inputData.serviceType = this.props.serviceType;

        /* inputData.freeFlag5 = "CASA";
         if(this.props.serviceType === "Account Opening"){
             inputData.freeFlag6 ="New Customer A/C Opening - " +this.props.subServiceType;

         }
         else{
              inputData.freeFlag6 ="Existing Customer A/C Opening - " +this.props.subServiceType;

         }*/
        inputData.rmCodeFdr = inputData.rmCode;
        inputData.rmCodeFdrApiCall = true;
        inputData.rmCodeFdrName = inputData.rmCodeName;
        inputData.rmCodeDps = inputData.rmCode;
        inputData.rmCodeDpsApiCall = true;
        inputData.rmCodeDpsName = inputData.rmCodeName;
        inputData.taxWaiverChange = "N";
        inputData.taxWaiverChanges = "N";
        inputData.preventialRateChange = "N";

        // inputData.ccepCode = inputData.ccepCode.key;
        inputData.individualDedupData = this.state.individualDataSaveId;
        inputData.jointDedupData = this.state.jointDataSaveId;
        inputData.companyDedupData = this.state.companyDataSaveId;
        inputData.jointAccountCustomerNumber = this.props.jointAccountCustomerNumber;
        inputData.statementFacilityEmailAddress = inputData["designatedEmail"];
        inputData.smsAlertEmailAddress = inputData["designatedEmail"];
        inputData.cityTouchEmailAddress = inputData["designatedEmail"];


        inputData.debitCardName = this.state.inputData["customerName"];
        inputData.accountNumber = this.state.inputData["accountNumber"];
        inputData.instaDebitCardName = this.state.inputData["customerName"];
        inputData.nameOnCard = this.state.inputData["customerName"];

        let varValue = this.emptyValueRemove(this.state.inputData);
        inputData.debitCardName = this.state.inputData["customerName"];
        varValue.dob = this.dateConverter(this.state.inputData.dob);
        varValue.debitCardName = this.state.inputData["customerName"];
        varValue.nameOnCard = this.state.inputData["customerName"];
        inputData.instaDebitCardName = this.state.inputData["customerName"];
        varValue.instaDebitCardName = this.state.inputData["customerName"];
        varValue.accountNumber = this.state.inputData["accountNumber"];
        if (this.state.inputData.customerStatus === "Normal Customer") {
            inputData.occupationCode = inputData.occupationCodeNormal;
            inputData.occupationCodes = inputData.occupationCodeNormal;
        } else if (this.state.inputData.customerStatus === "Priority") {
            inputData.occupationCode = inputData.occupationCodePriority;
            inputData.occupationCodes = inputData.occupationCodePriority;
        } else if (this.state.inputData.customerStatus === "Staff") {
            inputData.occupationCode = inputData.occupationCodeStaff;
        }
        varValue.fdValueDate = new Date();
        varValue.dpsfdValueDate = new Date();

        // data.dueDate=this.state.dueDate;
        // data.type=this.state.type;
        console.log(this.state.inputData);
        if (this.state.inputData.priority === "HIGH")
            inputData.urgency = 1;
        else
            inputData.urgency = 0;
        console.log(inputData);
        if (this.props.serviceType === "Account Opening" && this.state.inputData.customerStatus === "Normal Customer" && this.state.inputData.accountTypes === "Insta Pack" && this.state.inputData.bm_approval === undefined && this.state.inputData.bom_approval === undefined && (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account")) {

            let s1 = (this.state.inputData.accountNumber).toString();
            let s2 = s1.substr(3);
            let cbData = s2.substring(0, s2.length - 3);
            inputData.cbNumber = "CB" + cbData;
            inputData.accountNumber = this.state.inputData.accountNumber;
        }

        let object = {
            currency: this.state.inputData.currency.key,
        };
        if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
            object.natureAccount = "Individual-Retail";
        } else {
            object.natureAccount = "Non-Individual"
        }
        let Urls = backEndServerURL + "/FDRDPSSchemeCode/getDPSNameOfScheme";
        axios.post(Urls, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((getAccountType) => {
                let jsonArrayServiceList = CSJsonFormForCasaServiceDPS;
                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                    let jsonObjects = jsonArrayServiceList[i];
                    if (jsonObjects.varName === "dpsnameOfScheme") {
                        CSJsonFormForCasaServiceDPS[i].enum = getAccountType.data;
                    }
                }

                inputData.dpsnameOfSchemeApiCall = true;
                varValue.dpsnameOfSchemeApiCall = true
                /* this.setState({
                     accountBalanceInfo: true,
                     getAccountType: true,
                     cbsCustomerGet: true,
                     inputData: inputData,
                     varValue: varValue,
                     showValue: true,
                     //loading: false,
                     getData: true,getDataSubServiceFDR:true,getDataSubServiceDPS:true,
                     nameOfSchemeApiCall: true,

                 })*/
            })
            .catch((error) => {
                /* this.setState({
                     accountBalanceInfo: true,
                     nameOfSchemeApiCall: true,
                     cbsCustomerGet: true,
                 })*/
                console.log(error);
            });


        let objects = {
            currency: this.state.inputData.currency.key,
        };
        if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
            objects.natureAccount = "Individual-Retail";
        } else {
            objects.natureAccount = "Non-Individual"
        }
        Urls = backEndServerURL + "/FDRDPSSchemeCode/getAccountType";
        axios.post(Urls, objects, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((getAccountType) => {
                let jsonArrayServiceList = CSJsonFormForFDRService;
                for (let i = 0; i < jsonArrayServiceList.length; i++) {
                    let jsonObjects = jsonArrayServiceList[i];
                    if (jsonObjects.varName === "accountsType") {
                        CSJsonFormForFDRService[i].enum = getAccountType.data;
                    }
                }
                inputData.accountTypeApiCall = true;
                varValue.accountTypeApiCall = true

                /* this.setState({
                     accountBalanceInfo: true,
                     getAccountType: true,
                     cbsCustomerGet: true,
                     inputData: inputData,
                     varValue: varValue,
                     showValue: true,
                     //loading: false,
                     getData: true,getDataSubServiceFDR:true,getDataSubServiceDPS:true,

                 })*/
            })
            .catch((error) => {
                /*  this.setState({
                      accountBalanceInfo: true,
                      getAccountType: true,
                      cbsCustomerGet: true,
                  })*/
                console.log(error);
            });
        if (inputData.subServiceType === undefined || inputData.subServiceType === null) {
            if (this.props.subServiceType === "Individual A/C") {
                inputData.temporarySubServiceType = "INDIVIDUAL";
            } else {
                inputData.temporarySubServiceType = this.props.subServiceType;
            }
            if (this.props.serviceType === "ExistAccountOpening") {
                inputData.temporaryServiceType = "ExistAccountOpening";
            } else {
                inputData.temporaryServiceType ="Account Opening";
            }
        }
        let inputDatForFirstTime=inputData;
        if(this.state.inputData.apiButton === "CB UPDATE" ){
            if(this.state.inputData.accountTypes !== "Insta Pack"){
                inputDatForFirstTime.accountNumber=null;
            }
            inputDatForFirstTime.accountNumberChangeNeed="YES";
        }
       /* if(this.state.actionType==="handleConfirm"){
            inputDatForFirstTime.accountNumber=null;
        }*/
        let variableSetUrl = backEndServerURL + "/variables/" + this.state.appId;
        axios.post(variableSetUrl, inputDatForFirstTime, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (this.state.alowApiCall) {
                    if (this.props.businessSegment === "ISLAMIC") {
                        console.log(inputData);
                        console.log(varValue);
                        this.setState({
                            title: "Success!",
                            notificationMessage: "Successfully Save!",
                            alert: true,
                            loading: false,
                            getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                            inputData: inputData,
                            varValue: inputData,
                            showValue: true,
                            getInitiateData: true,
                            handleConfirmBoolean: true,

                        });
                        this.state.inputData.getInitiateData = true;
                        this.forceUpdate()
                        //this.handleSubmitDraft()
                    } else {
                        /*  {
                              "varName": "customerStatus",
                              "type": "select",
                              "label": "Customer Status ",
                              "grid": 2,
                              "errorMessage": "Error",
                              "required": true,
                              "enum": [
                              "Normal Customer",
                              "Staff",
                              "Priority",
                          ],
      */
                        this.setState({
                            alowApiCall: false
                        });
                        //corporate CB Creation
                        if (this.props.serviceType === "Account Opening" && this.state.inputData.customerStatus === "Normal Customer" && this.state.inputData.accountTypes !== "Insta Pack" && (this.state.inputData.accountTypes === "Regular" || this.state.inputData.accountType === "Regular") && this.state.inputData.bm_approval === undefined && this.state.inputData.bom_approval === undefined && (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C" && this.props.subServiceType !== "Joint Account")) {
                            let accountType = "";
                            let cbNumber = "";
                            accountType = this.state.inputData.accTypes;
                            if (this.state.inputData.apiButton === "CB UPDATE") {
                                let cbCreateUrl = backEndServerURL + "/createCorporateCustomer/" + this.state.appId;
                                axios.get(cbCreateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        cbNumber = response.data;
                                        let inputData = this.state.inputData;
                                        inputData.apiButton = "ACCOUNT UPDATE";
                                        // inputData.apiButton = "UPDATE";
                                        inputData.newCbNumber = response.data;
                                        inputData.cbNumber = response.data;
                                        this.variableSetFunction(inputData)
                                    })
                                    .catch((error) => {
                                        this.setState({
                                            alowApiCall: true,
                                            errorModalBoolean: true,
                                            errorTittle: this.errorObjectCheck(error),
                                            loading: false,
                                            getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                            showValue: true,
                                            inputData: this.emptyValueRemove(inputData),
                                            varValue: this.emptyValueRemove(varValue),
                                        })
                                    })
                            } else if (this.state.inputData.apiButton === "ACCOUNT UPDATE") {
                                let accountCreateUrl = backEndServerURL + "/createNewCasaAccount/" + accountType + "/" + this.state.appId + "/" + this.state.inputData.cbNumber;
                                axios.get(accountCreateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        console.log(response);
                                        let string = response.data;
                                        let typeOfString = typeof string;
                                        console.log("New CB Number");
                                        console.log(response.data);
                                        let s1 = (response.data.accountNumber).toString();
                                        let s2 = s1.substr(3);
                                        let cbData = s2.substring(0, s2.length - 3);
                                        inputData.cbNumber = "CB" + cbData;
                                        inputData.accountNumber = response.data.accountNumber;
                                        inputData.solId = response.data.solId;
                                        inputData.apiButton = "UPDATE";
                                        this.variableSetFunction(inputData);
                                        this.setState({
                                            alowApiCall: true,
                                            loading: false,
                                            inputData: this.emptyValueRemove(inputData),
                                            varValue: this.emptyValueRemove(varValue),
                                            getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                            showValue: true,
                                            getInitiateData: true,
                                            handleConfirmBoolean: true,
                                            generateNewCB: "CB" + cbData,
                                            generateNewAccountNumber: response.data.accountNumber,
                                            accountAndCbDetailsModal: true,
                                            title: "Successfull!",
                                            notificationMessage: "Successfully Saved!",
                                            alert: true,


                                        })

                                        //this.handleSubmitDraftWithoutClose(response.data.accountNumber)
                                    })
                                    .catch((error) => {
                                        this.setState({
                                            alowApiCall: true,
                                            errorModalBoolean: true,
                                            errorTittle: this.errorObjectCheck(error),
                                            loading: false,
                                            getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                            showValue: true,
                                            inputData: this.emptyValueRemove(inputData),
                                            varValue: this.emptyValueRemove(varValue),
                                        });
                                        console.log(this.errorObjectCheck(error))

                                    })
                            }

                            /*  let cbCreateUrl = backEndServerURL + "/createNewCasaAccount/" + accountType + "/" + this.state.appId;
                              axios.get(cbCreateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                  .then((response) => {
                                      console.log(response)
                                      let string = response.data;
                                      let typeOfString = typeof string;
                                      /!*    if (typeOfString === "string" || typeOfString === "number") {*!/
                                      console.log("New CB Number")
                                      console.log(response.data)
                                      let s1 = (response.data.accountNumber).toString();
                                      let s2 = s1.substr(3);
                                      let cbData = s2.substring(0, s2.length - 3)
                                      inputData.cbNumber = "CB" + cbData;
                                      inputData.accountNumber = response.data.accountNumber;
                                      inputData.solId = response.data.solId;
                                      this.setState({
                                          alowApiCall:true,
                                          loading: false,
                                          inputData: inputData,
                                          varValue: varValue,
                                          getData: true,getDataSubServiceFDR:true,getDataSubServiceDPS:true,
                                          showValue: true,
                                          getInitiateData: true,
                                          handleConfirmBoolean: true,
                                          generateNewCB: "CB" + cbData,
                                          generateNewAccountNumber: response.data.accountNumber,
                                          accountAndCbDetailsModal: true,
                                          title: "Successfull!",
                                          notificationMessage: "Successfully Saved!",
                                          alert: true,


                                      })

                                      //this.handleSubmitDraftWithoutClose(response.data.accountNumber)
                                  })
                                  .catch((error) => {
                                      this.setState({
                                          alowApiCall:true,
                                          errorModalBoolean: true,
                                          errorTittle: this.errorObjectCheck(error),
                                          loading: false,
                                          getData: true,getDataSubServiceFDR:true,getDataSubServiceDPS:true,
                                          showValue: true,
                                          inputData: inputData,
                                          varValue: varValue,
                                      })
                                      console.log(this.errorObjectCheck(error))

                                  })*/


                        }

                        //corporate Account Creation

                        else if (this.props.serviceType === "Account Opening" && this.state.inputData.customerStatus === "Normal Customer" && this.state.inputData.accountTypes === "Insta Pack" && this.state.inputData.bm_approval === undefined && this.state.inputData.bom_approval === undefined && (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C" && this.props.subServiceType !== "Joint Account")) {
                            let cbNumber = "";
                            if (this.state.inputData.apiButton === "CB UPDATE") {
                                let updateUrl = backEndServerURL + "/UpdateInstaPackCorporateCustomer/" + this.state.appId;
                                axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        cbNumber = response.data;
                                        let inputData = this.state.inputData;
                                        inputData.apiButton = "ACCOUNT UPDATE";
                                        let s1 = (this.state.inputData.accountNumber).toString();
                                        let s2 = s1.substr(3);
                                        let cbData = s2.substring(0, s2.length - 3);
                                        inputData.cbNumber = "CB" + cbData;
                                        inputData.newCbNumber = "CB" + cbData;
                                        this.variableSetFunction(inputData);
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        this.setState({
                                            alowApiCall: true,
                                            inputData: this.emptyValueRemove(inputData),
                                            varValue: this.emptyValueRemove(varValue),
                                            loading: false,
                                            getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                            showValue: true,
                                            errorModalBoolean: true,
                                            errorTittle: this.errorObjectCheck(error)
                                        })

                                    })
                            } else if (this.state.inputData.apiButton === "ACCOUNT UPDATE") {
                                let updateUrl = backEndServerURL + "/updateInstaPackAccountInfo/" + this.state.appId;
                                axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        console.log(response.data);
                                        let inputData = this.state.inputData;
                                        inputData.apiButton = "SCHEME UPDATE";
                                        this.variableSetFunction(inputData);
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        this.setState({
                                            alowApiCall: true,
                                            inputData: this.emptyValueRemove(inputData),
                                            varValue: this.emptyValueRemove(varValue),
                                            loading: false,
                                            getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                            showValue: true,
                                            errorModalBoolean: true,
                                            errorTittle: this.errorObjectCheck(error)
                                        })

                                    })
                            } else if (this.state.inputData.apiButton === "SCHEME UPDATE") {

                                let updateUrl = backEndServerURL + "/updateInstaPackAccountSchemeCode/" + this.state.appId;
                                axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        let s1 = (this.state.inputData.accountNumber).toString();
                                        let s2 = s1.substr(3);
                                        let cbData = s2.substring(0, s2.length - 3);
                                        inputData.cbNumber = "CB" + cbData;
                                        inputData.accountNumber = this.state.inputData.accountNumber;
                                        inputData.solId = response.data.solId;
                                        inputData.freeFlag4 = response.data.schemeCode;
                                        inputData.apiButton = "UPDATE";
                                        inputData.cbNumber = "CB" + cbData;
                                        inputData.newCbNumber = "CB" + cbData;
                                        this.variableSetFunction(inputData);
                                        this.setState({
                                            alowApiCall: true,
                                            title: "Success!",
                                            notificationMessage: "Successfully Save!",
                                            alert: true,
                                            loading: false,
                                            getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                            inputData: this.emptyValueRemove(inputData),
                                            varValue: this.emptyValueRemove(inputData),
                                            showValue: true,
                                            getInitiateData: true,
                                            handleConfirmBoolean: true,

                                        })

                                        //this.handleSubmitDraftWithoutClose(this.state.inputData.accountNumber)

                                    })
                                    .catch((error) => {
                                        let variableSetUrl = backEndServerURL + "/variables/" + this.state.appId;
                                        axios.get(variableSetUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                            .then((response) => {
                                                if (response.data.apiButton !== undefined && response.data.apiButton !== null && response.data.apiButton != "CB UPDATE") {
                                                    this.state.inputData.apiButton = response.data.apiButton
                                                    this.state.inputData = this.emptyValueRemove(response.data);
                                                    this.updateComponent();
                                                    this.variableSetFunction(this.state.inputData);
                                                }
                                                this.setState({
                                                    alowApiCall: true,
                                                    inputData: this.emptyValueRemove(this.state.inputData),
                                                    varValue: {...this.state.inputData},
                                                    loading: false,
                                                    getData: true,
                                                    getDataSubServiceFDR: true,
                                                    getDataSubServiceDPS: true,
                                                    showValue: true,
                                                    errorModalBoolean: true,
                                                    errorTittle: this.errorObjectCheck(error)
                                                })
                                            })
                                            .catch((error)=>{
                                                console.log(error)
                                            })

                                    })
                            }
                            /*  let  accountupdateUrl = backEndServerURL + "/updateInstaPackAcct/" + this.state.appId;
                                      axios.get(accountupdateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                          .then((response) => {
                                              let s1 = (this.state.inputData.accountNumber).toString();
                                              let s2 = s1.substr(3);
                                              let cbData = s2.substring(0, s2.length - 3)
                                              inputData.cbNumber = "CB" + cbData;
                                              inputData.accountNumber = this.state.inputData.accountNumber;
                                              inputData.solId = response.data.solId;
                                              this.setState({
                                                  alowApiCall:true,
                                                  title: "Success!",
                                                  notificationMessage: "Successfully Save!",
                                                  alert: true,
                                                  loading: false,
                                                  getData: true,getDataSubServiceFDR:true,getDataSubServiceDPS:true,
                                                  inputData: inputData,
                                                  varValue: inputData,
                                                  showValue: true,
                                                  getInitiateData: true,
                                                  handleConfirmBoolean: true,

                                              })

                                              //this.handleSubmitDraftWithoutClose(this.state.inputData.accountNumber)

                                          })
                                          .catch((error) => {
                                              this.setState({
                                                  alowApiCall:true,
                                                  inputData: inputData,
                                                  varValue: varValue,
                                                  loading: false,
                                                  getData: true,getDataSubServiceFDR:true,getDataSubServiceDPS:true,
                                                  showValue: true,
                                                  errorModalBoolean: true,
                                                  errorTittle: this.errorObjectCheck(error)
                                              })

                                          })*/


                        }
                        else if (this.props.serviceType !== "Account Opening" && this.state.inputData.bm_approval === undefined && this.state.inputData.bom_approval === undefined && (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C" && this.props.subServiceType !== "Joint Account")) {
                            let data = {};
                            let accountType = "";
                            accountType = this.state.inputData.accType;
                            data.cbNumber = this.props.getCustomerId;
                            let accountCreateUrl = backEndServerURL + "/openAccount/" + accountType + "/" + this.state.appId;
                            axios.get(accountCreateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    let string = response.data;
                                    let typeOfString = typeof string;
                                    inputData.accountNumber = response.data.accountNumber;
                                    inputData.solId = response.data.solId;
                                    inputData.apiButton = "UPDATE";
                                    this.variableSetFunction(inputData);
                                    this.setState({
                                        alowApiCall: true,
                                        generateNewAccountNumber: response.data.accountNumber,
                                        accountDetailsModal: true,
                                        title: "Successfull!",
                                        notificationMessage: "Successfully Saved!",
                                        alert: true,
                                        getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                        inputData: this.emptyValueRemove(inputData),
                                        varValue: this.emptyValueRemove(varValue),
                                        showValue: true,
                                        getInitiateData: true,
                                        handleConfirmBoolean: true,


                                    })

                                    //this.handleSubmitDraftWithoutClose(response.data.accountNumber)

                                })
                                .catch((error) => {
                                    this.setState({
                                        alowApiCall: true,
                                        inputData: this.emptyValueRemove(inputData),
                                        varValue: this.emptyValueRemove(varValue),
                                        loading: false,
                                        getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                        showValue: true,
                                        errorModalBoolean: true,
                                        errorTittle: this.errorObjectCheck(error)
                                    });
                                    console.log(error)
                                    //this.handleSubmitDraftWithoutClose("")

                                })
                            /*     inputData.apiButton = "UPDATE";
                               this.variableSetFunction(inputData);
                               this.setState({
                                   alowApiCall: true,
                                   title: "Successfull!",
                                   notificationMessage: "Successfully Saved!",
                                   alert: true,
                                   getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                   loading: false,
                                   inputData: this.emptyValueRemove(inputData),
                                   varValue: this.emptyValueRemove(varValue),
                                   showValue: true,
                                   getInitiateData: true,
                                   handleConfirmBoolean: true,


                               })*/


                        }

                        //staff and priority Regular
                        else if (this.props.serviceType === "Account Opening" && this.state.inputData.customerStatus !== "Normal Customer" && (this.state.inputData.accountTypes === "Regular" || this.state.inputData.accountType === "Regular") && this.state.inputData.bm_approval === undefined && this.state.inputData.bom_approval === undefined && (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account")) {
                            let accountType = "";
                            let cbNumber = "";
                            if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
                                accountType = this.state.inputData.accType;
                            } else {
                                accountType = this.state.inputData.accType;
                            }

                            if (this.state.inputData.apiButton === "CB UPDATE") {
                                let cbCreateUrl = backEndServerURL + "/cbsCustomerCreation/" + this.state.appId;
                                axios.get(cbCreateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        cbNumber = response.data;
                                        let inputData = this.state.inputData;
                                        inputData.apiButton = "ACCOUNT UPDATE";
                                        inputData.newCbNumber = response.data;
                                        inputData.cbNumber = response.data;
                                        this.variableSetFunction(inputData)
                                    })
                                    .catch((error) => {

                                        this.setState({
                                            alowApiCall: true,
                                            errorModalBoolean: true,
                                            errorTittle: this.errorObjectCheck(error),
                                            loading: false,
                                            getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                            showValue: true,
                                            inputData: this.emptyValueRemove(inputData),
                                            varValue: this.emptyValueRemove(varValue),
                                        })
                                    })
                            } else if (this.state.inputData.apiButton === "ACCOUNT UPDATE") {
                                let accountCreateUrl = backEndServerURL + "/createNewCasaAccount/" + accountType + "/" + this.state.appId + "/" + this.state.inputData.cbNumber;
                                axios.get(accountCreateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        console.log(response);
                                        let string = response.data;
                                        let typeOfString = typeof string;
                                        let data = {};
                                        let s1 = (response.data.accountNumber).toString();
                                        let s2 = s1.substr(3);
                                        let cbData = s2.substring(0, s2.length - 3);
                                        inputData.cbNumber = "CB" + cbData;
                                        inputData.accountNumber = response.data.accountNumber;
                                        inputData.solId = response.data.solId;
                                        inputData.apiButton = "UPDATE";
                                        this.variableSetFunction(inputData);
                                        this.setState({
                                            alowApiCall: true,
                                            loading: false,
                                            inputData: this.emptyValueRemove(inputData),
                                            varValue: this.emptyValueRemove(varValue),
                                            getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                            showValue: true,
                                            getInitiateData: true,
                                            handleConfirmBoolean: true,
                                            generateNewCB: "CB" + cbData,
                                            generateNewAccountNumber: response.data.accountNumber,
                                            accountAndCbDetailsModal: true,
                                            title: "Successfull!",
                                            notificationMessage: "Successfully Saved!",
                                            alert: true,


                                        })

                                        //this.handleSubmitDraftWithoutClose(response.data.accountNumber)


                                    })
                                    .catch((error) => {
                                        this.setState({
                                            alowApiCall: true,
                                            errorModalBoolean: true,
                                            errorTittle: this.errorObjectCheck(error),
                                            loading: false,
                                            getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                            showValue: true,
                                            inputData: this.emptyValueRemove(inputData),
                                            varValue: this.emptyValueRemove(varValue),
                                        });
                                        console.log(this.errorObjectCheck(error))

                                    })
                            }


                        }
                        //Normal Regular
                        else if (this.props.serviceType === "Account Opening" && this.state.inputData.customerStatus === "Normal Customer" && this.state.inputData.accountTypes !== "Insta Pack" && (this.state.inputData.accountTypes === "Regular" || this.state.inputData.accountType === "Regular") && this.state.inputData.bm_approval === undefined && this.state.inputData.bom_approval === undefined && (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account")) {
                            let accountType = "";
                            let cbNumber = "";
                            if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
                                accountType = this.state.inputData.accType;
                            } else {
                                accountType = this.state.inputData.accType;
                            }

                            if (this.state.inputData.apiButton === "CB UPDATE") {
                                let cbCreateUrl = backEndServerURL + "/cbsCustomerCreation/" + this.state.appId;
                                axios.get(cbCreateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        cbNumber = response.data;
                                        let inputData = this.state.inputData;
                                        inputData.apiButton = "ACCOUNT UPDATE";
                                        inputData.newCbNumber = response.data;
                                        inputData.cbNumber = response.data;
                                        this.variableSetFunction(inputData)
                                    })
                                    .catch((error) => {
                                        this.setState({
                                            alowApiCall: true,
                                            errorModalBoolean: true,
                                            errorTittle: this.errorObjectCheck(error),
                                            loading: false,
                                            getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                            showValue: true,
                                            inputData: this.emptyValueRemove(inputData),
                                            varValue: this.emptyValueRemove(varValue),
                                        })
                                    })
                            } else if (this.state.inputData.apiButton === "ACCOUNT UPDATE") {
                                let accountCreateUrl = backEndServerURL + "/createNewCasaAccount/" + accountType + "/" + this.state.appId + "/" + this.state.inputData.cbNumber;
                                axios.get(accountCreateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        console.log(response);
                                        let string = response.data;
                                        let typeOfString = typeof string;
                                        console.log("New CB Number");
                                        console.log(response.data);
                                        let s1 = (response.data.accountNumber).toString();
                                        let s2 = s1.substr(3);
                                        let cbData = s2.substring(0, s2.length - 3);
                                        inputData.cbNumber = "CB" + cbData;
                                        inputData.accountNumber = response.data.accountNumber;
                                        inputData.solId = response.data.solId;
                                        inputData.apiButton = "UPDATE";
                                        this.variableSetFunction(inputData);
                                        this.setState({
                                            alowApiCall: true,
                                            loading: false,
                                            inputData: this.emptyValueRemove(inputData),
                                            varValue: this.emptyValueRemove(varValue),
                                            getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                            showValue: true,
                                            getInitiateData: true,
                                            handleConfirmBoolean: true,
                                            generateNewCB: "CB" + cbData,
                                            generateNewAccountNumber: response.data.accountNumber,
                                            accountAndCbDetailsModal: true,
                                            title: "Successfull!",
                                            notificationMessage: "Successfully Saved!",
                                            alert: true,


                                        })

                                        //this.handleSubmitDraftWithoutClose(response.data.accountNumber)
                                    })
                                    .catch((error) => {
                                        this.setState({
                                            alowApiCall: true,
                                            errorModalBoolean: true,
                                            errorTittle: this.errorObjectCheck(error),
                                            loading: false,
                                            getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                            showValue: true,
                                            inputData: this.emptyValueRemove(inputData),
                                            varValue: this.emptyValueRemove(varValue),
                                        });
                                        console.log(this.errorObjectCheck(error))

                                    })
                            }

                            /*  let cbCreateUrl = backEndServerURL + "/createNewCasaAccount/" + accountType + "/" + this.state.appId;
                              axios.get(cbCreateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                  .then((response) => {
                                      console.log(response)
                                      let string = response.data;
                                      let typeOfString = typeof string;
                                      /!*    if (typeOfString === "string" || typeOfString === "number") {*!/
                                      console.log("New CB Number")
                                      console.log(response.data)
                                      let s1 = (response.data.accountNumber).toString();
                                      let s2 = s1.substr(3);
                                      let cbData = s2.substring(0, s2.length - 3)
                                      inputData.cbNumber = "CB" + cbData;
                                      inputData.accountNumber = response.data.accountNumber;
                                      inputData.solId = response.data.solId;
                                      this.setState({
                                          alowApiCall:true,
                                          loading: false,
                                          inputData: inputData,
                                          varValue: varValue,
                                          getData: true,getDataSubServiceFDR:true,getDataSubServiceDPS:true,
                                          showValue: true,
                                          getInitiateData: true,
                                          handleConfirmBoolean: true,
                                          generateNewCB: "CB" + cbData,
                                          generateNewAccountNumber: response.data.accountNumber,
                                          accountAndCbDetailsModal: true,
                                          title: "Successfull!",
                                          notificationMessage: "Successfully Saved!",
                                          alert: true,


                                      })

                                      //this.handleSubmitDraftWithoutClose(response.data.accountNumber)
                                  })
                                  .catch((error) => {
                                      this.setState({
                                          alowApiCall:true,
                                          errorModalBoolean: true,
                                          errorTittle: this.errorObjectCheck(error),
                                          loading: false,
                                          getData: true,getDataSubServiceFDR:true,getDataSubServiceDPS:true,
                                          showValue: true,
                                          inputData: inputData,
                                          varValue: varValue,
                                      })
                                      console.log(this.errorObjectCheck(error))

                                  })*/


                        }
                        //Normal Regular
                        else if (this.props.serviceType === "Account Opening" && this.state.inputData.customerStatus === "Normal Customer" && this.state.inputData.accountTypes === "Insta Pack" && this.state.inputData.bm_approval === undefined && this.state.inputData.bom_approval === undefined && (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account")) {
                            let cbNumber = "";
                            if (this.state.inputData.apiButton === "CB UPDATE") {
                                let updateUrl = backEndServerURL + "/updateInstaPackAccountNameAndCBInfo/" + this.state.appId;
                                axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        cbNumber = response.data;
                                        let inputData = this.state.inputData;
                                        inputData.apiButton = "ACCOUNT UPDATE";
                                        let s1 = (this.state.inputData.accountNumber).toString();
                                        let s2 = s1.substr(3);
                                        let cbData = s2.substring(0, s2.length - 3);
                                        inputData.cbNumber = "CB" + cbData;
                                        inputData.newCbNumber = "CB" + cbData;
                                        this.variableSetFunction(inputData);
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        this.setState({
                                            alowApiCall: true,
                                            inputData: this.emptyValueRemove(inputData),
                                            varValue: this.emptyValueRemove(varValue),
                                            loading: false,
                                            getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                            showValue: true,
                                            errorModalBoolean: true,
                                            errorTittle: this.errorObjectCheck(error)
                                        })

                                    })
                            } else if (this.state.inputData.apiButton === "ACCOUNT UPDATE") {
                                let updateUrl = backEndServerURL + "/updateInstaPackAccountInfo/" + this.state.appId;
                                axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        console.log(response.data);
                                        let inputData = this.state.inputData;
                                        inputData.apiButton = "SCHEME UPDATE";
                                        this.variableSetFunction(inputData);
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        this.setState({
                                            alowApiCall: true,
                                            inputData: this.emptyValueRemove(inputData),
                                            varValue: this.emptyValueRemove(varValue),
                                            loading: false,
                                            getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                            showValue: true,
                                            errorModalBoolean: true,
                                            errorTittle: this.errorObjectCheck(error)
                                        })

                                    })
                            } else if (this.state.inputData.apiButton === "SCHEME UPDATE") {

                                let updateUrl = backEndServerURL + "/updateInstaPackAccountSchemeCode/" + this.state.appId;
                                axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        let s1 = (this.state.inputData.accountNumber).toString();
                                        let s2 = s1.substr(3);
                                        let cbData = s2.substring(0, s2.length - 3);
                                        inputData.cbNumber = "CB" + cbData;
                                        inputData.accountNumber = this.state.inputData.accountNumber;
                                        inputData.solId = response.data.solId;
                                        inputData.freeFlag4 = response.data.schemeCode;
                                        inputData.apiButton = "UPDATE";
                                        inputData.cbNumber = "CB" + cbData;
                                        inputData.newCbNumber = "CB" + cbData;
                                        this.variableSetFunction(inputData);

                                        this.setState({
                                            alowApiCall: true,
                                            title: "Success!",
                                            notificationMessage: "Successfully Save!",
                                            alert: true,
                                            loading: false,
                                            getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                            inputData: this.emptyValueRemove(inputData),
                                            varValue: this.emptyValueRemove(inputData),
                                            showValue: true,
                                            getInitiateData: true,
                                            handleConfirmBoolean: true,

                                        })

                                        //this.handleSubmitDraftWithoutClose(this.state.inputData.accountNumber)

                                    })
                                    .catch((error) => {
                                        let variableSetUrl = backEndServerURL + "/variables/" + this.state.appId;
                                        axios.get(variableSetUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                            .then((response) => {
                                                if (response.data.apiButton !== undefined && response.data.apiButton !== null && response.data.apiButton != "CB UPDATE") {
                                                    this.state.inputData.apiButton = response.data.apiButton
                                                    this.state.inputData = this.emptyValueRemove(response.data);
                                                    this.updateComponent()
                                                    this.variableSetFunction(this.state.inputData);
                                                }
                                                this.setState({
                                                    alowApiCall: true,
                                                    inputData: this.emptyValueRemove(this.state.inputData),
                                                    varValue: {...this.state.inputData},
                                                    loading: false,
                                                    getData: true,
                                                    getDataSubServiceFDR: true,
                                                    getDataSubServiceDPS: true,
                                                    showValue: true,
                                                    errorModalBoolean: true,
                                                    errorTittle: this.errorObjectCheck(error)
                                                })
                                            })
                                            .catch((error)=>{
                                                console.log(error);
                                            })

                                    })
                            }
                            /*  let  accountupdateUrl = backEndServerURL + "/updateInstaPackAcct/" + this.state.appId;
                                      axios.get(accountupdateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                          .then((response) => {
                                              let s1 = (this.state.inputData.accountNumber).toString();
                                              let s2 = s1.substr(3);
                                              let cbData = s2.substring(0, s2.length - 3)
                                              inputData.cbNumber = "CB" + cbData;
                                              inputData.accountNumber = this.state.inputData.accountNumber;
                                              inputData.solId = response.data.solId;
                                              this.setState({
                                                  alowApiCall:true,
                                                  title: "Success!",
                                                  notificationMessage: "Successfully Save!",
                                                  alert: true,
                                                  loading: false,
                                                  getData: true,getDataSubServiceFDR:true,getDataSubServiceDPS:true,
                                                  inputData: inputData,
                                                  varValue: inputData,
                                                  showValue: true,
                                                  getInitiateData: true,
                                                  handleConfirmBoolean: true,

                                              })

                                              //this.handleSubmitDraftWithoutClose(this.state.inputData.accountNumber)

                                          })
                                          .catch((error) => {
                                              this.setState({
                                                  alowApiCall:true,
                                                  inputData: inputData,
                                                  varValue: varValue,
                                                  loading: false,
                                                  getData: true,getDataSubServiceFDR:true,getDataSubServiceDPS:true,
                                                  showValue: true,
                                                  errorModalBoolean: true,
                                                  errorTittle: this.errorObjectCheck(error)
                                              })

                                          })*/


                        }

                        /*
                        else if (this.props.serviceType === "Account Opening" && this.state.inputData.accountTypes==="Insta Pack" && (this.props.subServiceType === "Joint Account" || this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C"/!* || this.props.subServiceType === "Joint Account"*!/)) {
                                 let updateUrl=backEndServerURL+"/updateInstaPackInfo/"+this.props.appId;
                                axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response)=>{
                                        console.log(response.data);
                                        this.setState({
                                            title: "Success!",
                                            notificationMessage: "Successfully Updated!",
                                            alert: true,
                                            loading: false,
                                            getData: true,getDataSubServiceFDR:true,getDataSubServiceDPS:true,
                                            inputData: inputData,
                                            varValue: inputData,
                                            showValue: true,
                                            getInitiateData: true,
                                            handleConfirmBoolean: true,

                                        })
                                    })
                                    .catch((error)=>{
                                        console.log(error)
                                    })


                        }
    */
                        else if (this.props.serviceType !== "Account Opening" && this.state.inputData.bm_approval === undefined && this.state.inputData.bom_approval === undefined && (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account")) {

                            let data = {};
                            let accountType = "";
                            if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
                                accountType = this.state.inputData.accType;
                            } else {
                                accountType = this.state.inputData.accType;
                            }
                            data.cbNumber = this.props.getCustomerId;
                            let accountCreateUrl = backEndServerURL + "/openAccount/" + accountType + "/" + this.state.appId;
                            axios.get(accountCreateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    let string = response.data;

                                    let typeOfString = typeof string;

                                    /*
                                                                    if (typeOfString === "string" || typeOfString === "number") {
                                    */
                                    inputData.accountNumber = response.data.accountNumber;
                                    inputData.solId = response.data.solId;
                                    inputData.apiButton = "UPDATE";
                                    this.variableSetFunction(inputData);
                                    this.setState({
                                        alowApiCall: true,
                                        generateNewAccountNumber: response.data.accountNumber,
                                        accountDetailsModal: true,
                                        title: "Successfull!",
                                        notificationMessage: "Successfully Saved!",
                                        alert: true,
                                        getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                        loading: false,
                                        inputData: this.emptyValueRemove(inputData),
                                        varValue: this.emptyValueRemove(varValue),
                                        showValue: true,
                                        getInitiateData: true,
                                        handleConfirmBoolean: true,


                                    })

                                    //this.handleSubmitDraftWithoutClose(response.data.accountNumber)

                                })
                                .catch((error) => {
                                    this.setState({
                                        alowApiCall: true,
                                        inputData: this.emptyValueRemove(inputData),
                                        varValue: this.emptyValueRemove(varValue),
                                        loading: false,
                                        getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                        showValue: true,
                                        errorModalBoolean: true,
                                        errorTittle: this.errorObjectCheck(error)
                                    });
                                    console.log(error)

                                })


                        }
                        /*
                                         else if (this.props.serviceType !== "Account Opening" && this.state.inputData.accountTypes === "Regular" && this.state.inputData.bm_approval === undefined && this.state.inputData.bom_approval === undefined && (this.props.subServiceType === "Joint Account" )) {
                                            let data = {};
                                            data.accountType = this.state.inputData.accType;
                                            if (this.props.subServiceType === "Joint Account") {
                                                data.accountCategory = "individual";
                                                data.accountType = this.state.inputData.accType;
                                            } else {
                                                data.accountCategory = "nonindividual";
                                                data.accountType = this.state.inputData.accTypes;
                                            }
                                            data.cbNumber = this.props.getCustomerId
                                            let accountCreateUrl = backEndServerURL + "/openAccountJointRetail/" + this.state.appId;
                                            axios.post(accountCreateUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                .then((response) => {
                                                    let string = response.data;

                                                    let typeOfString = typeof string;

                                                    if (typeOfString === "string" || typeOfString === "number") {
                                                        this.setState({
                                                            generateNewAccountNumber: response.data,
                                                            accountDetailsModal: true,
                                                            title: "Successfull!",
                                                            notificationMessage: "Successfully Saved!",
                                                            alert: true,
                                                            getData: true,getDataSubServiceFDR:true,getDataSubServiceDPS:true,
                                                            loading: false,
                                                            inputData: inputData,
                                                            varValue: varValue,
                                                            showValue: true,
                                                            getInitiateData: true


                                                        })


                                                    } else {
                                                        this.setState({
                                                            inputData: inputData,
                                                            varValue: varValue,
                                                            loading: false,
                                                            getData: true,getDataSubServiceFDR:true,getDataSubServiceDPS:true,
                                                            errorModalBoolean: true,
                                                            errorTittle: "Finacle system error occured,please try after sometime or please contact Admintration Office"
                                                        })
                                                    }


                                                    /!*  this.setState({
                                                          title: "Successfull!",
                                                          notificationMessage: "Successfully Saved!",
                                                          alert: true,
                                                          inputData: data,
                                                          varValue: varValue,
                                                          getInitiateData: true
                                                      })*!/


                                                })
                                                .catch((error) => {
                                                    this.setState({
                                                        inputData: inputData,
                                                        varValue: varValue,
                                                        loading: false,
                                                        getData: true,getDataSubServiceFDR:true,getDataSubServiceDPS:true,
                                                        showValue: true,
                                                        errorModalBoolean: true,
                                                        errorTittle: "Found Error in FINACLE  Data Push"
                                                    })
                                                     console.log(error)

                                                })


                                        }
                        */
                        else {
                            console.log(inputData);
                            console.log(varValue);
                            this.setState({
                                alowApiCall: true,
                                title: "Success!",
                                notificationMessage: "Successfully Save!",
                                alert: true,
                                loading: false,
                                getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                                inputData: this.emptyValueRemove(inputData),
                                varValue: this.emptyValueRemove(inputData),
                                showValue: true,
                                getInitiateData: true,
                                handleConfirmBoolean: true,

                            })

                            //this.handleSubmitDraftWithoutClose()
                            //this.handleSubmitDraft()
                        }
                    }
                }


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    inputData: this.emptyValueRemove(inputData),
                    varValue: this.emptyValueRemove(varValue),
                    loading: false,
                    getData: true, getDataSubServiceFDR: true, getDataSubServiceDPS: true,
                    showValue: true,
                })
            });

    })
.catch((error) => {
    console.log(error);

});
    };

    renderButton = () => {
        if (this.state.loading === false) {
            if (this.state.inputData.apiButton === "UPDATE" && this.state.getData && this.state.inputData.apiButton === "UPDATE") {
                return (
                    <center>
                        <button
                            className="btn btn-outline-primary  btn-sm"
                            style={{}}
                            onClick={(event) => this.handleCommon(event, "handleSubmit")}

                        >
                            Submit
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                            className="btn btn-outline-info btn-sm"
                            style={{}}
                            onClick={(event) => this.handleCommon(event, "handleSubmitDraft")}


                        >
                            Save
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        {LocalstorageEncrypt.encryptStorageFunction.getItem("workplace")==="CSU"   && <button
                            className="btn btn-outline-info btn-sm"
                            style={{
                                verticalAlign: 'left',
                                position: "absolute",
                               // marginTop: "5px",
                                right: 10,

                            }}

                            onClick={(event) => this.handleCommon(event, "handleSubmitDocumentUpload")}


                        >
                            Forward To Document Upload
                        </button>}
                        {/*   &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-info btn-sm"
                        style={{}}
                        onClick={(event) => this.handleCommon(event, "handleCaseClose")}


                    >
                        Delete
                    </button>*/}

                    </center>
                )
            } else if (this.state.getData && this.state.inputData.apiButton !== undefined) {
                return (
                    <div>
                        {this.state.inputData.apiButton === "CB UPDATE" &&
                        <button
                            className="btn btn-outline-primary  btn-sm"
                            style={{}}
                            onClick={(event) => this.handleCommon(event, "handleConfirm")}

                        >

                            {this.renderDynamicButtonName("CB")}


                        </button>}
                        {this.state.inputData.apiButton === "ACCOUNT UPDATE" &&
                        <button
                            className="btn btn-outline-primary  btn-sm"
                            style={{}}
                            onClick={(event) => this.handleCommon(event, "handleConfirm")}


                        >{this.renderDynamicButtonName("ACCOUNT")}
                        </button>
                        }
                        {this.state.inputData.apiButton === "SCHEME UPDATE" &&
                        <button
                            className="btn btn-outline-primary  btn-sm"
                            style={{}}
                            onClick={(event) => this.handleCommon(event, "handleConfirm")}


                        >
                            SCHEME UPDATE
                        </button>
                        }
                    </div>
                )
            }
        }


    };
    renderDynamicButtonName = (data) => {
        if (data === "ACCOUNT") {
            if (this.state.inputData.customerStatus === "Normal Customer" && (this.state.inputData.accountTypes === "Insta Pack" || this.state.inputData.accountType === "Insta Pack")) {
                return "ACCOUNT UPDATE";
            } else {
                return "ACCOUNT CREATION";
            }
        } else {
            if (this.state.inputData.customerStatus === "Normal Customer" && (this.state.inputData.accountTypes === "Insta Pack" || this.state.inputData.accountType === "Insta Pack")) {
                return "CB UPDATE";
            } else {
                return "CB CREATION";
            }
        }


    };
    masterdataToString=(data)=>{
        if(data!==undefined && data.key!==undefined){
            return data.value;
        }
        else{
            return data;
        }
    }
    renderHeader = () => {
        if (this.props.serviceType === 'ExistAccountOpening') {
            return (
                <h4 style={{color: "white"}} ><b>Existing
                    Customer
                    Account Opening
                    - {this.props.subServiceType === "Company Account" ? "Partnership Account" : this.props.subServiceType} </b>
                    <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> CB Number:</b>{this.state.inputData.cbNumber}
                        <b> SOLID:</b>{this.masterdataToString(this.state.inputData.solId)} <b> Scheme
                            Code:</b>{(this.state.inputData.accType!==undefined && this.state.inputData.accType!==null  && this.state.inputData.accType === "SAVINGS" && this.state.inputData.schemeCodeForSavings !== undefined && this.state.inputData.schemeCodeForSavings !== null ) ? this.state.inputData.schemeCodeForSavings.value : ((this.state.inputData.schemeCodeForCurrent !== undefined && this.state.inputData.schemeCodeForCurrent !== null) ? this.state.inputData.schemeCodeForCurrent.value : "")}<b> Case
                            ID:</b>{this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                            onClick={this.closeIcon}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
            )
        } else if (this.props.serviceType === "Account Opening") {
            return (
                <h4 style={{color: "white"}} ><b>New Customer Account Opening
                    - {this.props.subServiceType === "Company Account" ? "Partnership Account" : this.props.subServiceType}</b>
                    <p
                        style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> CB Number:</b>{this.state.inputData.cbNumber}
                        <b> SOLID:</b>{this.masterdataToString(this.state.inputData.solId)}<b> Scheme
                            Code:</b>{(this.state.inputData.accType!==undefined && this.state.inputData.accType!==null  && this.state.inputData.accType === "SAVINGS" && this.state.inputData.schemeCodeForSavings !== undefined && this.state.inputData.schemeCodeForSavings !== null ) ? this.state.inputData.schemeCodeForSavings.value : ((this.state.inputData.schemeCodeForCurrent !== undefined && this.state.inputData.schemeCodeForCurrent !== null) ? this.state.inputData.schemeCodeForCurrent.value : "")}<b> Case
                            ID:</b>{this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                            onClick={this.closeIcon}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
            )
        } else if ((this.props.serviceType === "internet_banking" || this.props.serviceType === "adc_service")) {
            return (
                <h4 style={{color: "white"}} ><b>Internet Banking</b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.state.inputData.customerName}
                    <b> A/C:</b>{this.state.inputData.accountNumber} <b> CB Number:</b>{this.state.inputData.cbNumber}
                    <b> SOLID:</b>{this.masterdataToString(this.state.inputData.solId)}<b> Scheme
                        Code:</b>{(this.state.inputData.accType!==undefined && this.state.inputData.accType!==null  && this.state.inputData.accType === "SAVINGS" && this.state.inputData.schemeCodeForSavings !== undefined && this.state.inputData.schemeCodeForSavings !== null ) ? this.state.inputData.schemeCodeForSavings.value : ((this.state.inputData.schemeCodeForCurrent !== undefined && this.state.inputData.schemeCodeForCurrent !== null) ? this.state.inputData.schemeCodeForCurrent.value : "")}<b> Case
                        ID:</b>{this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                        onClick={this.closeIcon}
                        style={{
                            position: 'absolute',
                            right: 10,
                            color: "#000000"
                        }}/></a></p></h4>
            )
        } else if (this.props.serviceType === "FDR Opening") {
            return (
                <h4 style={{color: "white"}} ><b>FDR Account Opening -with NTB Operative Account</b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.state.inputData.customerName}
                    <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                    <b> CB Number:</b>{this.state.inputData.cbNumber}
                    <b> SOLID:</b>{this.masterdataToString(this.state.inputData.solId)}<b> Scheme
                        Code:</b>{(this.state.inputData.accType!==undefined && this.state.inputData.accType!==null  && this.state.inputData.accType === "SAVINGS" && this.state.inputData.schemeCodeForSavings !== undefined && this.state.inputData.schemeCodeForSavings !== null ) ? this.state.inputData.schemeCodeForSavings.value : ((this.state.inputData.schemeCodeForCurrent !== undefined && this.state.inputData.schemeCodeForCurrent !== null) ? this.state.inputData.schemeCodeForCurrent.value : "")}<b> Case
                        ID:</b>{this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                        onClick={this.closeIcon}
                        style={{
                            position: 'absolute',
                            right: 10,
                            color: "#000000"
                        }}/></a></p></h4>
            )
        } else if (this.props.serviceType === "DPS Opening") {
            return (
                <h4 style={{color: "white"}} ><b>DPS Account Opening -with NTB Operative Account</b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.state.inputData.customerName}
                    <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                    <b> CB Number:</b>{this.state.inputData.cbNumber}
                    <b> SOLID:</b>{this.masterdataToString(this.state.inputData.solId)}<b> Scheme
                        Code:</b>{(this.state.inputData.accType!==undefined && this.state.inputData.accType!==null  && this.state.inputData.accType === "SAVINGS" && this.state.inputData.schemeCodeForSavings !== undefined && this.state.inputData.schemeCodeForSavings !== null ) ? this.state.inputData.schemeCodeForSavings.value : ((this.state.inputData.schemeCodeForCurrent !== undefined && this.state.inputData.schemeCodeForCurrent !== null) ? this.state.inputData.schemeCodeForCurrent.value : "")}<b> Case
                        ID:</b>{this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                        onClick={this.closeIcon}
                        style={{
                            position: 'absolute',
                            right: 10,
                            color: "#000000"
                        }}/></a></p></h4>
            )
        }

    };
    renderWaiverList = () => {
        if (this.state.getData) {
            return (
                <WaiverList appId={this.state.appId}/>
            )
        }
    };

    closeStatement = () => {
        this.setState({
            accountStatement: false,
            signatureCardView: false,
        })
    };
    openSignature = (accountNumber) => {
        if (this.state.signatureCardView) {
            let url = frontEndServerURL + "/signatureCardView/" + accountNumber;
            window.open(url)
            this.setState({
                signatureCardView: false
            })
        }

    };
    handleChangeSdnResult = (event) => {
        event.preventDefault();
        this.setState({
            SdnAndDedupeResultShowing: true
        })

    };
    closeModalForSdnResult = () => {
        this.setState({
            SdnAndDedupeResultShowing: false
        })
    }

    renderSDNDedupeResult = () => {
        if (this.state.getData && this.state.inputData.apiButton === "UPDATE" ) {
            return (
                <Button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        backgroundColor: "red",
                        color: "white",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 3,
                        paddingBottom: 3,
                        borderRadius: 3,
                        border: 1,
                        verticalAlign: 'left',
                        position: "absolute",
                        marginTop: "5px",
                        right: 50,
                    }}

                    type='button' value='add more'
                    onClick={(event) => this.handleChangeSdnResult(event)}
                >
                    Dedupe & SDN Full Result
                </Button>
            )
        }

    };
    render() {

        const {classes} = this.props;
        console.log(this.state.appId);

        /*  if (this.state.loading === true) {
              return (
                  <Card>
                      <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                          {this.renderHeader()}

                      </CardHeader>
                      <Dialog
                          fullWidth="true"
                          maxWidth="sm"
                          className={classes.modal}
                          classes={{paper: classes.dialogPaper}}
                          open={this.state.loading}>
                          <DialogContent className={classes.dialogPaper}>

                              <center>
                                  <img src={loader} alt=""/>
                              </center>
                          </DialogContent>
                      </Dialog>
                  </Card>


              )
          }
          else {*/
        return (


            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderHeader()}

                </CardHeader>
                <CardBody>
                    <div>

                        <ThemeProvider theme={theme}>

                            <Grid container>

                                {this.renderNotification()}

                                {this.renderSDNDedupeResult()}
                                <Grid item xs='12'>
                                    <br/>
                                    <br/>
                                    <br/>
                                </Grid>
                                {this.returnJsonForm()}

                                {this.returnJsonFormForService()}
                                {this.returnJsonFormForServiceFDR()}


                                {/* {this.fdrInterestRateCalculator()}

                                {this.returnJsonFormForFDR()}*/}
                                {/*   {this.renderSmsAlertField()}*/}
                                {this.returnJsonFormForServiceLast()}
                                {/* <Grid item xs={2}>
                                        {this.renderNumberOfDebitCard()}

                                    </Grid>*/}
                                {/* {this.renderMultipleDebitCardForm()}*/}

                                <Grid item xs='12'>
                                </Grid>
                                {this.renderAddButtonShow()}


                                <Grid item xs='12'>

                                </Grid>
                                {
                                    this.addDeferalForm()
                                }
                                <Grid item xs='12'>
                                </Grid>
                                {
                                    this.addWaiverForm()
                                }


                                <Grid item xs='12'>
                                </Grid>


                                <Grid item xs={12}>

                                    {this.renderDefferalData()}


                                </Grid>
                                <Grid item xs={12}>
                                </Grid>
                                {this.renderWaiverList()}
                                <br/>
                                <Grid item xs={12}>

                                    {this.renderRemarksData()}

                                </Grid>
                                <Grid item xs='12'>
                                </Grid>

                                {this.renderUploadCheckbox()}

                                <Grid item xs={1}>
                                    {this.renderUploadButton()}

                                </Grid>&nbsp;&nbsp;
                                <Grid item xs={2}>
                                    {this.renderSignatureUploadButton()}

                                </Grid>
                                <Grid item xs={1}>
                                    {this.renderAssignedImage()}
                                </Grid>
                                <Grid item xs='12'>

                                </Grid>
                                <Grid item xs={1}>
                                    {this.renderFlowSUmmeryButton()}
                                </Grid>
                                <Grid item xs='12'>

                                </Grid>
                                <br/>
                                {this.renderRemarks()}

                                <Grid item xs='12'>
                                </Grid>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="md"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.SdnAndDedupeResultShowing}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <SdnAndDedupeResultShowing closeModal={this.closeModalForSdnResult}
                                                                   branchName={this.props.branchName}
                                                                   inputData={this.state.inputData}
                                                                   subServiceType={this.props.subServiceType!==undefined?this.props.subServiceType:this.state.inputData.subServiceType}
                                                                   category={this.props.category!==undefined?this.props.category:this.state.inputData.category}
                                                                   serviceType={this.props.serviceType!==undefined?this.props.serviceType:this.state.inputData.serviceType}
                                                                   subCategory={this.props.subCategory!==undefined?this.props.subCategory:this.state.inputData.subCategory}
                                                                   freeFlag2={this.props.freeFlag2 === "externalUserClosedRequest" ? true : false}
                                                                   serviceType={this.props.serviceType!==undefined?this.props.serviceType:this.state.inputData.serviceType}
                                                                   customerName={this.props.customerName}
                                                                   accountNumber={this.props.accountNumber}
                                                                   cbNumber={this.props.cbNumber}
                                                                   solId={this.props.solId} globalSearch={true}
                                                                   closeIcon={this.closeModalForSdnResult}
                                                                   appId={this.props.appId!==undefined?this.props.appId:this.state.appId}/>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    open={this.state.accountDetailsModal}>
                                    <DialogContent>

                                        <AccountNoGenerate
                                            generateNewAccountNumber={this.state.generateNewAccountNumber}
                                            generateNewCB={this.state.generateNewCB}
                                            customerName={this.state.inputData.customerName}
                                            solId={this.state.inputData.solId}
                                            closeModal={this.accountDetailsModal}/>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.loading}>
                                    <DialogContent className={classes.dialogPaper}>

                                        <center>
                                            <img src={loader} alt=""/>
                                        </center>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    open={this.state.accountAndCbDetailsModal}>
                                    <DialogContent>

                                        <AccountAndCbNumberGenerate
                                            generateNewAccountNumber={this.state.generateNewAccountNumber}
                                            generateNewCB={this.state.generateNewCB}
                                            customerName={this.state.inputData.customerName}
                                            solId={this.state.inputData.solId}
                                            closeModal={this.accountAndCbDetailsModal}/>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    open={this.state.getMappingAllImage}>
                                    <DialogContent>
                                        {this.renderMappingImageEditModal()}

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.errorModalBoolean}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ErrorModal title={this.state.errorTittle}
                                                    closeModal={this.closeModal}
                                                    subServiceType={this.props.subServiceType}
                                                    appId={this.props.appId}
                                        />

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.confirmAlert}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ConfirmAlert validationForHandleConfirm={this.validationForHandleConfirm}
                                                      closeModal={this.closeConfirmAlert}

                                            /*    notificationMessgaeCommonDps={this.state.notificationMessgaeCommonDps}
                                                fdrImageShowPopUp={this.state.fdrImageShowPopUp}
                                                notificationMessgaeCommonFlag={this.state.notificationMessgaeCommonFlag}
                                                notificationMessgaeCommon={this.state.notificationMessgaeCommon}*/


                                        />

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="md"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.ConfirmAlertImage}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ConfirmAlertImage
                                            // appId={this.state.appId}
                                            notificationMessgaeCommonDps={this.state.notificationMessgaeCommonDps}
                       compon                     fdrImageShowPopUp={this.state.fdrImageShowPopUp}
                                            notificationMessgaeCommonFlag={this.state.notificationMessgaeCommonFlag}
                                            notificationMessgaeCommon={this.state.notificationMessgaeCommon}
                                            validationForHandleConfirm={this.validationForHandleConfirm}
                                            closeModal={this.closeConfirmAlert}/>

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    open={this.state.uploadModal}>
                                    <DialogContent>
                                        {this.renderMappingImageModal()}

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    open={this.state.uploadSignatureModal}>
                                    <DialogContent>
                                        {this.renderMappingSignatureImageModal()}

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="md"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.accountStatement}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <AccountStatement closeModal={this.closeStatement}
                                                          accountNumber={this.state.inputData.accountNumber}/>
                                    </DialogContent>
                                </Dialog>
                                {this.openSignature(this.state.inputData.accountNumberForNidVerified)}
                                <Grid item xs='12'>
                                </Grid>
                                {this.redirectPage()}
                                {this.renderButton()}
                            </Grid>

                        </ThemeProvider>


                    </div>


                </CardBody>
            </Card>


        );

    }


}

export default withStyles(styles)

(
    Liability
)
;
