class CommonFunctions{
    static makeReadOnlyObjectForRemittance(object){
        let returnObject = JSON.parse(JSON.stringify(object));
        let returnObjectVariables = returnObject;
        returnObjectVariables[1]["readOnly"] = true;
        returnObjectVariables[2]["readOnly"] = true;
        return returnObject;
    }
}

export default CommonFunctions;