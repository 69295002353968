import {
    dangerColor,
    defaultFont,
    grayColor,
    infoColor,
    primaryColor,
    roseColor,
    successColor,
    warningColor
} from "../material-dashboard-react.jsx";

const tableStyle = theme => ({
    warningTableHeader: {
        color: warningColor[0]
    },
    primaryTableHeader: {
        color: primaryColor[0]
    },
    dangerTableHeader: {
        color: dangerColor[0]
    },
    successTableHeader: {
        color: successColor[0]
    },
    infoTableHeader: {
        color: infoColor[0]
    },
    roseTableHeader: {
        color: roseColor[0]
    },
    grayTableHeader: {
        color: grayColor[0]
    },
    table: {
        marginBottom: "0",
        width: "100%",
        maxWidth: "100%",

        backgroundColor: "transparent",
        borderSpacing: "0",
        borderCollapse: "collapse"
    },
    tableHeadCell: {
        color: "inherit",
        ...defaultFont,
        fontSize: "1em",

    },
    tableCell: {
        ...defaultFont,
        lineHeight: "1.42857143",
        verticalAlign: "middle",
        paddingTop: 5,

        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 8,
        minWidth: 200
    },
    tableResponsive: {
        width: "100%",
        marginTop: theme.spacing.unit * 0,
        overflowX: "auto"
    }
    , tableData: {
        backgroundColor: '#d82530',
        '&:hover': {
            backgroundColor: "#ff3443"
        }
    }
});

export default tableStyle;
