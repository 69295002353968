import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import Table from "../Table/Table";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import SdProcessFlowMasterAdd from "./SdProcessFlowMasterAdd";
import BranchProcessFlowConfigureAdd from "./BranchProcessFlowConfigureAdd";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import SdFlowConfigureUpdateDelete from "./SdFlowConfigureUpdateDelete";
import BranchFlowConfigureUpdateDelete from "./BranchFlowConfigureUpdateDelete";
import Grow from "@material-ui/core/Grow";
import Functions from '../../Common/Functions';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#142398",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    dialogPaper: {
        overflow: "visible"
    }
};

function Transition(props) {

    return <Grow in={true} timeout="auto" {...props} />;
}

class SdAndBranchFlowConfigureAllData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: 'null',
            branchProcessFlow: false,
            sdProcessFlow: false,
            addSdProcessFlow: false,
            branchProcessFlowAdd: false,
            SelectedData: "",
            branchProcessFlowData: [[" ", " "]],
            sdProcessFlowData: [[" ", " "]],
            sdProcessDataShowInTable: [[" ", " "]],
            branchProcessDataShowInTable: [[" ", " "]],
            brachProcessId: '',
            sdProcessId: '',
            redirectLogin: false,


        }

    }

    componentDidMount() {
        let url = backEndServerURL + "/processFlowMaster/getAll";

        const getAllBranchFlow = [];

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                response.data.map((branch) => {

                    getAllBranchFlow.push(branch);
                });
                this.setState({
                    branchProcessFlowData: getAllBranchFlow
                })


            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
            });


        let url1 = backEndServerURL + "/sdProcessFlowMaster/getAll";

        const getAllSdFlow = [];

        axios.get(url1, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                response.data.map((branch) => {

                    getAllSdFlow.push(branch);
                });
                this.setState({
                    sdProcessFlowData: getAllSdFlow
                })


            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
            });


    }

    closeModal = () => {
        this.setState({
            branchProcessFlow: false,
            sdProcessFlow: false,
            addSdProcessFlow: false,
            branchProcessFlowAdd: false,
        })
    };


    createTableDataBranch = (id, userName, globalGroupName, branchName, region, status) => {

        return ([userName, globalGroupName, branchName, region, status,
            <button
                className="btn btn-outline-primary"
                style={{
                    verticalAlign: 'middle',
                }}
                onClick={() => this.branchFlowMethod(id)}>
                Edit</button>
        ]);

    };
    branchFlowMethod = (id) => {
        this.setState({
            branchProcessFlow: true,
            brachProcessId: id,

        })
    };
    createTableDataSd = (id, userName, sdGlobalGroupName, departmentName, region, status) => {

        return ([userName, sdGlobalGroupName, departmentName, region, status,
            <button
                className="btn btn-outline-primary"
                style={{
                    verticalAlign: 'middle',
                }}
                onClick={() => this.sdFlowMethod(id)}>
                Edit</button>
        ]);

    };
    sdFlowMethod = (id) => {
        this.setState({
            sdProcessFlow: true,
            sdProcessId: id
        })
    };

    handleChangeSelected = (name, value) => {
        this.setState({
            SelectedData: value,
        });
        if (value === 'SdProcessFlow') {

            this.setState({
                selectedCategory: 'SdProcessFlow'
            });

            let tableArray = [];
            this.state.sdProcessFlowData.map((sd) => {
                tableArray.push(this.createTableDataSd(sd.id, sd.userName, sd.sdGlobalGroupName, sd.sdDepartmentMaster['departmentName'], sd.region, sd.status));
            });

            this.setState({
                sdProcessDataShowInTable: tableArray

            });
        } else if (value === 'BranchProcessFlow') {
            this.setState({
                selectedCategory: 'BranchProcessFlow'
            });
            let tableArray = [];
            this.state.branchProcessFlowData.map((branch) => {
                tableArray.push(this.createTableDataBranch(branch.id, branch.userName, branch.globalGroupName, branch.branchMaster['branchName'], branch.region, branch.status));
            });

            this.setState({
                branchProcessDataShowInTable: tableArray
            });
        } else {
            this.setState({
                selectedCategory: 'null'
            });
        }

    };
    renderSelectMenu = () => {
        return (
            <InputLabel>Process FLow Configure</InputLabel>,
                <Select name="select" value={this.state.SelectedData}
                        onChange={event => this.handleChangeSelected(event.target.name, event.target.value)}>
                    <MenuItem key="SdProcessFlow"
                              value="SdProcessFlow">SdProcessFlow</MenuItem>,
                    <MenuItem key="BranchProcessFlow"
                              value="BranchProcessFlow">BranchProcessFlow</MenuItem>


                </Select>
        )
    };

    render() {
        const {classes} = this.props;

        {

            Functions.redirectToLogin(this.state)

        }

        if (this.state.selectedCategory === 'SdProcessFlow') {


            return (
                <section>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.addSdProcessFlow}
                        TransitionComponent={Transition}
                        onClose={this.closeModal}
                        style={{width: 700}}
                    >
                        <DialogContent className={classes.dialogPaper}>
                            <Card>
                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h4 className={classes.cardTitleWhite}>SD FLow Configure Add</h4>

                                </CardHeader>

                                <SdProcessFlowMasterAdd close={this.closeModal}/>
                            </Card>


                        </DialogContent>

                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.sdProcessFlow}
                        TransitionComponent={Transition}
                        onClose={this.closeModal}
                        style={{width: 700}}
                    >

                        <DialogContent className={classes.dialogPaper}>

                            <SdFlowConfigureUpdateDelete close={this.closeModal} name="Edit SD Flow"
                                                         id={this.state.sdProcessId}/>

                        </DialogContent>


                    </Dialog>

                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h4 className={classes.cardTitleWhite}>SD Process Flow Configure</h4>

                                </CardHeader>


                                <CardBody>
                                    <Grid item xs={this.props.grid}>


                                        <div>
                                            <FormControl style={{minWidth: 300}}>
                                                {this.renderSelectMenu()}

                                            </FormControl>
                                        </div>


                                    </Grid>
                                    <br/>
                                    <button
                                        className="btn btn-outline-primary"
                                        style={{
                                            verticalAlign: 'middle',
                                        }}

                                        onClick={() => {
                                            this.setState({
                                                addSdProcessFlow: true
                                            })
                                        }
                                        }
                                    >
                                        Add New Sd Flow

                                    </button>
                                    {<Table
                                        tableHeaderColor="danger"

                                        tableHead={["userName", "SD-GlobalGroupName", "Department Name", "Region", "Status", "Action"]}

                                        tableData={this.state.sdProcessDataShowInTable}
                                        tableAllign={['left', 'left', 'left', 'left', 'left']}
                                    />}

                                </CardBody>
                            </Card>
                        </GridItem>


                    </GridContainer>
                </section>
            );
        } else if (this.state.selectedCategory === 'BranchProcessFlow') {
            return (
                <section>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.branchProcessFlowAdd}
                        TransitionComponent={Transition}
                        onClose={this.closeModal}
                        style={{width: 700}}
                    >
                        <DialogContent className={classes.dialogPaper}>
                            <Card>
                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h4 className={classes.cardTitleWhite}>Branch FLow Configure Add</h4>

                                </CardHeader>

                                <BranchProcessFlowConfigureAdd close={this.closeModal} name="Add Branch Flow"/>
                            </Card>


                        </DialogContent>

                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.branchProcessFlow}
                        TransitionComponent={Transition}
                        onClose={this.closeModal}
                        style={{width: 700}}
                    >
                        <DialogContent className={classes.dialogPaper}>
                            <BranchFlowConfigureUpdateDelete close={this.closeModal} name="Edit Branch Flow"
                                                             id={this.state.brachProcessId}/>

                        </DialogContent>

                    </Dialog>

                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h4 className={classes.cardTitleWhite}>Branch process Flow</h4>

                                </CardHeader>


                                <CardBody>
                                    <div>
                                        <FormControl style={{minWidth: 300}}>
                                            {this.renderSelectMenu()}

                                        </FormControl>
                                    </div>
                                    <br/>
                                    <button
                                        className="btn btn-outline-primary"
                                        style={{
                                            verticalAlign: 'middle',
                                        }}

                                        onClick={() => {
                                            this.setState({
                                                branchProcessFlowAdd: true
                                            })
                                        }
                                        }
                                    >
                                        Add New branch Flow

                                    </button>
                                    {<Table
                                        tableHeaderColor="danger"
                                        tableHead={["UserName", "GroupName", "Branch Name", "Region", "Status", "Action"]}

                                        tableData={this.state.branchProcessDataShowInTable}
                                        tableAllign={['left', 'left', 'left', 'left', 'left', 'left']}
                                    />}

                                </CardBody>
                            </Card>
                        </GridItem>


                    </GridContainer>
                </section>
            );
        } else {
            return (
                <section>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.branchProcessFlow}
                        TransitionComponent={Transition}
                        onClose={this.closeModal}
                        style={{width: 700}}
                    >
                        <DialogContent className={classes.dialogPaper}>
                            <BranchFlowConfigureUpdateDelete name="Edit Branch Flow" id={this.state.brachProcessId}/>

                        </DialogContent>

                    </Dialog>

                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h4 className={classes.cardTitleWhite}>Branch process Flow</h4>

                                </CardHeader>
                                <CardBody>
                                    <div>
                                        <FormControl style={{minWidth: 300}}>
                                            <InputLabel>Process FLow Configure</InputLabel>
                                            {this.renderSelectMenu()}
                                        </FormControl>
                                    </div>


                                    {<Table
                                        tableHeaderColor="danger"
                                        tableHead={[" ", " "]}

                                        tableData={[
                                            [" ", " ", " "],

                                        ]}
                                        tableAllign={['left', 'left', 'left', 'left', 'left']}
                                    />}

                                </CardBody>
                            </Card>
                        </GridItem>


                    </GridContainer>
                </section>
            );
        }
    }

}

export default withStyles(styles)(SdAndBranchFlowConfigureAllData);
