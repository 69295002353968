import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../../Common/Functions';
import Notification from "../../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../../JsonForm/CustomeTheme";
import Table from "../../../Table/Table";
import FileTypeComponent from "../../../JsonForm/FileTypeComponent";
import DialogContent from "@material-ui/core/DialogContent/index";
import {Dialog} from "@material-ui/core/index";
import loader from "../../../../Static/loader.gif";
import Label from "./Label";
import SignatureButton from "../SignatureButton";
import Card from "../../../Card/Card";
import CardBody from "../../../Card/CardBody";
import PreviewDocument from "../PreviewButton";
import PreviewMappingImage from "../casa/PreviewMappingImage";
import GridList from "@material-ui/core/GridList";
import MyValidation from "../../../JsonForm/MyValidation";
import TextField from "@material-ui/core/TextField";
import CBNotFound from "../../CASA/CBNotFound";

let checkerRemarks = [
    {
        "varName": "checkerRemarks",
        "type": "textArea",
        "label": "Checker Remarks",
        "required": true,
        "grid": 12
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};
var fileUpload = {
    "varName": "scanningFile",
    "type": "file",
    "label": "Upload File",
    "grid": 12
};


class CheckerInbox extends React.Component {
    state = {
        message: "",
        getData: false,
        varValue: {},
        title: "",
        notificationMessage: "",
        alert: false,
        inputData: {},
        selectedDate: {},
        values: [],
        showValue: false,
        fileUploadData: {},
        loading: true,
        getRemarks: [],
        err: false,
        errorArray: {},
        errorMessages: {},
        previewDocument: false,
        objectForNominee: [],
        objectForExistingNominee: [],
        cbNotFound: false,
        existingNominee: false,
        oldNomineeData: [],
        oldNominee: true,
        existingObjectForNominee: [],
        getInitialNewNominee: 0,
    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{marginTop: "5px"}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    componentDidMount() {
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            let remarksArray = [];
            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    let inputData = response.data;
                    if (response.data.dpsCloserRequest === "DPS Nominee Change" && parseInt(response.data.nomineeNumberOld)) {
                        let sl;
                        let objectForExistingNominee = [];
                        for (let i = 0; i < parseInt(inputData.nomineeNumberOld); i++) {
                            sl = i + 1;
                            objectForExistingNominee.push(
                                {
                                    "varName": "Nominee " + i,
                                    "type": "title",
                                    "label": "Nominee  " + sl,
                                    "grid": 12,
                                    "readOnly": true,
                                    "conditional": true,
                                    "conditionalVarName": "dpsCloserRequest",
                                    "conditionalVarValue": "DPS Nominee Change"
                                },
                                {
                                    "varName": "nomineecifId" + sl,
                                    "type": "textApiCall",
                                    "label": "CIF ID/Individual Id",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditional": true,
                                    "conditionalVarName": "dpsCloserRequest",
                                    "conditionalVarValue": "DPS Nominee Change"
                                },
                                {
                                    "varName": "nomineeName" + sl,
                                    "type": "text",
                                    "validation": "string",
                                    "label": "Nominee Name",
                                    "grid": 6,
                                    "readOnly": true,
                                    "required": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                {
                                    "varName": "nomineeRelationship" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": [],
                                    "enumType": "relationCode",
                                    "label": "Relationship",
                                    "grid": 6,
                                    "readOnly": true,
                                    "required": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                {
                                    "varName": "percentageNominee" + sl,
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                {
                                    "varName": "nomineeAddress1" + sl,
                                    "type": "text",
                                    "label": "Address Field 1",
                                    "grid": 6,
                                    "readOnly": true,
                                    "required": true,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                {
                                    "varName": "nomineeAddress2" + sl,
                                    "type": "text",
                                    "label": "Address Field 2",
                                    "grid": 6,
                                    "readOnly": true,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                {
                                    "varName": "nomineeCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "label": "City",
                                    "grid": 6,
                                    "required": true,
                                    "readOnly": true,
                                    "enumType": "city",
                                    "enum": [],
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                {
                                    "varName": "nomineeStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "label": "State",
                                    "grid": 6,
                                    "required": true,
                                    "readOnly": true,
                                    "enumType": "state",
                                    "enum": [],
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                {
                                    "varName": "nomineePostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "required": true,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                {
                                    "varName": "nomineeCountry" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "label": "Country",
                                    "grid": 6,
                                    "readOnly": true,
                                    "required": true,
                                    "enumType": "country",
                                    "enum": [],
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                // {
                                //     "varName": "nomineenomineephotoIdNo" + i,
                                //     "type": "text",
                                //     "label": "NOMINEE PHOTO ID NO.",
                                //     "grid": 6,
                                //     "multiline": true,
                                //     "conditional": true,
                                //     "conditionalVarName": "nomineeChange",
                                //     "conditionalVarValue": true
                                // },
                                {
                                    "varName": "photoIdNumberRegNo" + sl,
                                    "type": "text",
                                    "label": "Photo Id No/Registration No.",
                                    "grid": 6,
                                    "readOnly": true,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                {
                                    "varName": "nomineeDob" + sl,
                                    "type": "date",
                                    "label": "D.O.B",
                                    "grid": 6,
                                    "readOnly": true,
                                    "onKeyDown": true,
                                    "maxDate": true,
                                    "required": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                {
                                    "varName": "deleteOldNominee" + sl,
                                    "type": "select",
                                    "enum": ["Y", "N"],
                                    "label": "Delete This Nominee",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                {
                                    "varName": "nomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true],
                                },
                                {
                                    "type": "title",
                                    "label": "Guardian Details  " + sl,
                                    "grid": 12,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true],
                                },
                                {
                                    "varName": "nomineeGuardiansName" + sl,
                                    "type": "text",
                                    "label": "Guardians Name",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true],
                                    "multiline": true,
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "guardianCode",
                                    "enum": [],
                                    "label": "Guardian Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true],
                                    "required": true,

                                },
                                {
                                    "varName": "guardianPhotoId" + sl,
                                    "type": "text",
                                    "label": "Guardian Photo Id",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true],
                                },

                                {
                                    "varName": "nomineeGuardianAddress" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeGuardianAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true],
                                    "multiline": true,
                                },

                                {
                                    "varName": "nomineeGuardianCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "city",
                                    "enum": [],
                                    "readOnly": true,
                                    "label": "City Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "state",
                                    "enum": [],
                                    "label": "State Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianPostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianCountry2" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "country",
                                    "enum": [],
                                    "readOnly": true,
                                    "label": "Country",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true],
                                    "required": true,
                                },
                            )

                        }
                        setTimeout(() => {
                            this.setState({
                                oldNomineeData: objectForExistingNominee,
                                oldNominee: true,
                            });
                        }, 1000)
                    }

                    if (response.data.dpsCloserRequest === "DPS Nominee Change" && parseInt(inputData.numberOfNominee) > 0) {
                        var sl;
                        let newSl;
                        let objectForNominee = [];
                        let oldNominee = response.data.nomineeNumberOld ? parseInt(response.data.nomineeNumberOld) : 0;
                        for (var i = 0; i < parseInt(inputData.numberOfNominee); i++) {
                            newSl = i + 1;
                            sl = oldNominee + (i + 1);
                            objectForNominee.push(
                                {
                                    "varName": "Nominee " + i,
                                    "type": "title",
                                    "label": "Nominee  " + sl,
                                    "grid": 12,
                                },
                                {
                                    "varName": "search" + sl,
                                    "type": "select",
                                    "enum": [
                                        "CIF ID",
                                        "INFORMATION",
                                    ],
                                    "required": true,
                                    "onKeyDown": true,
                                    "label": "Input CIF ID/INFORMATION ?",
                                    "grid": 6,


                                },
                                {
                                    "varName": "nomineecifId" + sl,
                                    "type": "textApiCall",
                                    "label": "CIF ID",
                                    "grid": 6,
                                    "required": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "CIF ID",

                                },

                                {
                                    "varName": "nomineeName" + sl,
                                    "type": "text",
                                    "label": "Nominee Name",
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeName" + sl,
                                    "type": "text",
                                    "label": "Nominee Name",
                                    "grid": 6,
                                    required: true,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",

                                },
                                {
                                    "varName": "nomineeRelationship" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "relationCode",
                                    "enum": [],
                                    "label": "Relationship",
                                    "grid": 6,
                                    "required": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],


                                },
                                {
                                    "varName": "nomineeRelationship" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "relationCode",
                                    "enum": [],
                                    "label": "Relationship",
                                    "grid": 6,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                    required: true,

                                },
                                {
                                    "varName": "percentageNominee" + sl,
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    required: true,

                                },
                                {
                                    "varName": "percentageNominee" + sl,
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 6,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                    required: true,

                                },

                                {
                                    "varName": "nomineeAddress1" + sl,
                                    "type": "text",
                                    "label": "Address 1 ",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeAddress1" + sl,
                                    "type": "text",
                                    "label": "Address 1 ",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    required: true,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "nomineeAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                /*  {
                                        "varName": "nomineenomineephotoIdNo" + i,
                                        "type": "text",
                                        "label": "NOMINEE PHOTO ID NO.",
                                        "grid": 6,
                                        "conditional": true,
                                        "conditionalVarName": "nomineecifIdApiCall" + i,
                                        "conditionalVarValue": true,
                                    },
                    */
                                {
                                    "varName": "nomineeCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "city",
                                    "enum": [],
                                    "label": "City Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "city",
                                    "enum": [],
                                    "label": "City Code",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "nomineeStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "state",
                                    "enum": [],
                                    "label": "State Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "state",
                                    "enum": [],
                                    "label": "State Code",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "nomineePostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "nomineePostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },

                                {
                                    "varName": "nomineeCountry" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "country",
                                    "enum": [],
                                    "label": "Country",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeCountry" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "country",
                                    "enum": [],
                                    "label": "Country",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "photoIdNumberRegNo" + sl,
                                    "type": "text",
                                    "label": "Photo Id Number/Reg No",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "photoIdNumberRegNo" + sl,
                                    "type": "text",
                                    "label": "Photo Id Number/Reg No",
                                    "grid": 6,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "nomineeDob" + sl,
                                    "type": "text",
                                    "label": "D.O.B(YYYY-MM-DD)",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeDob" + sl,
                                    "type": "date",
                                    "label": "D.O.B",
                                    "grid": 6,
                                    "onKeyDown": true,
                                    "required": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },


                                {
                                    "varName": "nomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    /*    "conditional": true,
                                        "conditionalVarName": "search" + sl,
                                        "conditionalVarValue": "CIF ID",*/
                                },
                                {
                                    "varName": "nomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    /*  "conditional": true,
                                      "conditionalVarName": "search" + sl,
                                      "conditionalVarValue": "INFORMATION",*/
                                },
                                {
                                    "type": "title",
                                    "label": "Guardian Details  " + sl,
                                    "grid": 12,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                },
                                {
                                    "varName": "nomineeGuardiansName" + sl,
                                    "type": "text",
                                    "label": "Guardians Name",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "multiline": true,
                                    "required": true,


                                },

                                {
                                    "varName": "nomineeGuardianCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "guardianCode",
                                    "enum": [],
                                    "label": "Guardian Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,

                                },
                                {
                                    "varName": "guardianPhotoId" + sl,
                                    "type": "text",
                                    "label": "Guardian Photo Id",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                },

                                {
                                    "varName": "nomineeGuardianAddress" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeGuardianAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "multiline": true,
                                },

                                {
                                    "varName": "nomineeGuardianCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "city",
                                    "enum": [],
                                    "label": "City Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "state",
                                    "enum": [],
                                    "label": "State Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianPostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianCountry2" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "country",
                                    "enum": [],
                                    "label": "Country",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "type": "title",
                                    "label": "Guardian Details  " + sl,
                                    "grid": 12,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeGuardiansName" + sl,
                                    "type": "text",
                                    "label": "Guardians Name",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "multiline": true,
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "guardianCode",
                                    "enum": [],
                                    "label": "Guardian Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,

                                },
                                {
                                    "varName": "guardianPhotoId" + sl,
                                    "type": "text",
                                    "label": "Guardian Photo Id",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },

                                {
                                    "varName": "nomineeGuardianAddress" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeGuardianAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "multiline": true,
                                },

                                {
                                    "varName": "nomineeGuardianCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "city",
                                    "enum": [],
                                    "label": "City Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "state",
                                    "enum": [],
                                    "label": "State Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianPostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianCountry2" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "country",
                                    "enum": [],
                                    "label": "Country",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },
                            )

                        }
                        this.setState({
                            existingObjectForNominee: objectForNominee,
                            existingNominee: true,
                            getInitialNewNominee: response.data.numberOfNominee ? response.data.numberOfNominee : 0,
                        });
                    }

                    // if (response.data.dpsCloserRequest === "DPS Nominee Change") {
                    //     var sl;
                    //     let newSl;
                    //     let objectForNominee = [];
                    //     let oldNominee = response.data.nomineeNumberOld ? parseInt(response.data.nomineeNumberOld) : 0;
                    //     for (var i = 0; i < response.data.numberOfNominee; i++) {
                    //         newSl = i + 1;
                    //         sl = oldNominee + (i + 1);
                    //         objectForNominee.push(
                    //             {
                    //                 "varName": "Nominee " + i,
                    //                 "type": "title",
                    //                 "label": "Nominee  " + sl,
                    //                 "grid": 12,
                    //             },
                    //             {
                    //                 "varName": "search" + sl,
                    //                 "type": "select",
                    //                 "enum": [
                    //                     "CIF ID",
                    //                     "INFORMATION",
                    //                 ],
                    //                 "readOnly": true,
                    //                 "onKeyDown": true,
                    //                 "label": "Input CIF ID/INFORMATION ?",
                    //                 "grid": 6,
                    //
                    //
                    //             },
                    //             {
                    //                 "varName": "nomineecifId" + sl,
                    //                 "type": "textApiCall",
                    //                 "label": "CIF ID",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditional": true,
                    //                 "conditionalVarName": "search" + sl,
                    //                 "conditionalVarValue": "CIF ID",
                    //
                    //             },
                    //
                    //             {
                    //                 "varName": "nomineeName" + sl,
                    //                 "type": "text",
                    //                 "label": "Nominee Name",
                    //                 "grid": 6,
                    //                 "multiline": true,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    //                 "conditionalArrayValue": ["CIF ID", true],
                    //             },
                    //             {
                    //                 "varName": "nomineeName" + sl,
                    //                 "type": "text",
                    //                 "label": "Nominee Name",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "multiline": true,
                    //                 "conditional": true,
                    //                 "conditionalVarName": "search" + sl,
                    //                 "conditionalVarValue": "INFORMATION",
                    //
                    //             },
                    //             {
                    //                 "varName": "nomineeRelationship" + sl,
                    //                 "type": "autoCompleteValueReturn",
                    //                 "enum": [],
                    //                 "label": "Relationship",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    //                 "conditionalArrayValue": ["CIF ID", true],
                    //
                    //
                    //             },
                    //             {
                    //                 "varName": "nomineeRelationship" + sl,
                    //                 "type": "autoCompleteValueReturn",
                    //                 "enum": [],
                    //                 "label": "Relationship",
                    //                 "grid": 6,
                    //                 "conditional": true,
                    //                 "conditionalVarName": "search" + sl,
                    //                 "conditionalVarValue": "INFORMATION",
                    //                 "readOnly": true,
                    //
                    //             },
                    //             {
                    //                 "varName": "percentageNominee" + sl,
                    //                 "type": "text",
                    //                 "label": "Percentage %",
                    //                 "grid": 6,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    //                 "conditionalArrayValue": ["CIF ID", true],
                    //                 "readOnly": true,
                    //
                    //             },
                    //             {
                    //                 "varName": "percentageNominee" + sl,
                    //                 "type": "text",
                    //                 "label": "Percentage %",
                    //                 "grid": 6,
                    //                 "conditional": true,
                    //                 "conditionalVarName": "search" + sl,
                    //                 "conditionalVarValue": "INFORMATION",
                    //                 "readOnly": true,
                    //
                    //             },
                    //
                    //             {
                    //                 "varName": "nomineeAddress1" + sl,
                    //                 "type": "text",
                    //                 "label": "Address 1 ",
                    //                 "grid": 6,
                    //                 "multiline": true,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    //                 "conditionalArrayValue": ["CIF ID", true],
                    //             },
                    //             {
                    //                 "varName": "nomineeAddress1" + sl,
                    //                 "type": "text",
                    //                 "label": "Address 1 ",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "multiline": true,
                    //                 "conditional": true,
                    //                 "conditionalVarName": "search" + sl,
                    //                 "conditionalVarValue": "INFORMATION",
                    //             },
                    //
                    //             {
                    //                 "varName": "nomineeAddress2" + sl,
                    //                 "type": "text",
                    //                 "label": "Address 2",
                    //                 "grid": 6,
                    //                 "multiline": true,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    //                 "conditionalArrayValue": ["CIF ID", true],
                    //             },
                    //             {
                    //                 "varName": "nomineeAddress2" + sl,
                    //                 "type": "text",
                    //                 "label": "Address 2",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "multiline": true,
                    //                 "conditional": true,
                    //                 "conditionalVarName": "search" + sl,
                    //                 "conditionalVarValue": "INFORMATION",
                    //             },
                    //             /*  {
                    //                     "varName": "nomineenomineephotoIdNo" + i,
                    //                     "type": "text",
                    //                     "label": "NOMINEE PHOTO ID NO.",
                    //                     "grid": 6,
                    //                     "conditional": true,
                    //                     "conditionalVarName": "nomineecifIdApiCall" + i,
                    //                     "conditionalVarValue": true,
                    //                 },
                    // */
                    //             {
                    //                 "varName": "nomineeCityCode" + sl,
                    //                 "type": "autoCompleteValueReturn",
                    //                 "enum": [],
                    //                 "label": "City Code",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    //                 "conditionalArrayValue": ["CIF ID", true],
                    //             },
                    //             {
                    //                 "varName": "nomineeCityCode" + sl,
                    //                 "type": "autoCompleteValueReturn",
                    //                 "enum": [],
                    //                 "label": "City Code",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditional": true,
                    //                 "conditionalVarName": "search" + sl,
                    //                 "conditionalVarValue": "INFORMATION",
                    //             },
                    //
                    //             {
                    //                 "varName": "nomineeStateCode" + sl,
                    //                 "type": "autoCompleteValueReturn",
                    //                 "enum": [],
                    //                 "label": "State Code",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    //                 "conditionalArrayValue": ["CIF ID", true],
                    //             },
                    //             {
                    //                 "varName": "nomineeStateCode" + sl,
                    //                 "type": "autoCompleteValueReturn",
                    //                 "enum": [],
                    //                 "label": "State Code",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditional": true,
                    //                 "conditionalVarName": "search" + sl,
                    //                 "conditionalVarValue": "INFORMATION",
                    //             },
                    //
                    //             {
                    //                 "varName": "nomineePostalCode" + sl,
                    //                 "type": "text",
                    //                 "label": "Postal Code",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditional": true,
                    //                 "conditionalVarName": "search" + sl,
                    //                 "conditionalVarValue": "INFORMATION",
                    //             },
                    //             {
                    //                 "varName": "nomineePostalCode" + sl,
                    //                 "type": "text",
                    //                 "label": "Postal Code",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    //                 "conditionalArrayValue": ["CIF ID", true],
                    //             },
                    //
                    //             {
                    //                 "varName": "nomineeCountry" + sl,
                    //                 "type": "autoCompleteValueReturn",
                    //                 "enum": [],
                    //                 "label": "Country",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    //                 "conditionalArrayValue": ["CIF ID", true],
                    //             },
                    //             {
                    //                 "varName": "nomineeCountry" + sl,
                    //                 "type": "autoCompleteValueReturn",
                    //                 "enum": [],
                    //                 "label": "Country",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditional": true,
                    //                 "conditionalVarName": "search" + sl,
                    //                 "conditionalVarValue": "INFORMATION",
                    //             },
                    //
                    //             {
                    //                 "varName": "photoIdNumberRegNo" + sl,
                    //                 "type": "text",
                    //                 "label": "Photo Id Number/Reg No",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    //                 "conditionalArrayValue": ["CIF ID", true],
                    //             },
                    //             {
                    //                 "varName": "photoIdNumberRegNo" + sl,
                    //                 "type": "text",
                    //                 "label": "Photo Id Number/Reg No",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditional": true,
                    //                 "conditionalVarName": "search" + sl,
                    //                 "conditionalVarValue": "INFORMATION",
                    //             },
                    //             {
                    //                 "varName": "nomineeDob" + sl,
                    //                 "type": "text",
                    //                 "label": "D.O.B(YYYY-MM-DD)",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    //                 "conditionalArrayValue": ["CIF ID", true],
                    //             },
                    //             {
                    //                 "varName": "nomineeDob" + sl,
                    //                 "type": "date",
                    //                 "label": "D.O.B",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "onKeyDown": true,
                    //                 "required": true,
                    //                 "conditional": true,
                    //                 "conditionalVarName": "search" + sl,
                    //                 "conditionalVarValue": "INFORMATION",
                    //             },
                    //
                    //
                    //             {
                    //                 "varName": "nomineeMinor" + sl,
                    //                 "type": "select",
                    //                 "label": "Nominee Minor",
                    //                 "enum": ["YES", "NO"],
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                    //                 "conditionalArrayValue": ["CIF ID", true],
                    //             },
                    //             {
                    //                 "varName": "nomineeMinor" + sl,
                    //                 "type": "select",
                    //                 "label": "Nominee Minor",
                    //                 "enum": ["YES", "NO"],
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                    //                 "conditionalArrayValue": ["INFORMATION", true],
                    //             },
                    //             {
                    //                 "type": "title",
                    //                 "label": "Guardian Details  " + sl,
                    //                 "grid": 12,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                    //                 "conditionalArrayValue": ["INFORMATION", true],
                    //             },
                    //             {
                    //                 "varName": "nomineeGuardiansName" + sl,
                    //                 "type": "text",
                    //                 "label": "Guardians Name",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                    //                 "conditionalArrayValue": ["INFORMATION", true],
                    //                 "multiline": true,
                    //                 "required": true,
                    //
                    //
                    //             },
                    //
                    //             {
                    //                 "varName": "nomineeGuardianCode" + sl,
                    //                 "type": "autoCompleteValueReturn",
                    //                 "enum": [],
                    //                 "label": "Guardian Code",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                    //                 "conditionalArrayValue": ["INFORMATION", true],
                    //                 "required": true,
                    //
                    //             },
                    //             {
                    //                 "varName": "guardianPhotoId" + sl,
                    //                 "type": "text",
                    //                 "label": "Guardian Photo Id",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                    //                 "conditionalArrayValue": ["INFORMATION", true],
                    //             },
                    //
                    //             {
                    //                 "varName": "nomineeGuardianAddress" + sl,
                    //                 "type": "text",
                    //                 "label": "Address 1",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                    //                 "conditionalArrayValue": ["INFORMATION", true],
                    //                 "multiline": true,
                    //                 "required": true,
                    //             },
                    //             {
                    //                 "varName": "nomineeGuardianAddress2" + sl,
                    //                 "type": "text",
                    //                 "label": "Address 2",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                    //                 "conditionalArrayValue": ["INFORMATION", true],
                    //                 "multiline": true,
                    //             },
                    //
                    //             {
                    //                 "varName": "nomineeGuardianCityCode" + sl,
                    //                 "type": "autoCompleteValueReturn",
                    //                 "enum": [],
                    //                 "label": "City Code",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                    //                 "conditionalArrayValue": ["INFORMATION", true],
                    //                 "required": true,
                    //             },
                    //
                    //             {
                    //                 "varName": "nomineeGuardianStateCode" + sl,
                    //                 "type": "autoCompleteValueReturn",
                    //                 "enum": [],
                    //                 "label": "State Code",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                    //                 "conditionalArrayValue": ["INFORMATION", true],
                    //                 "required": true,
                    //             },
                    //
                    //             {
                    //                 "varName": "nomineeGuardianPostalCode" + sl,
                    //                 "type": "text",
                    //                 "label": "Postal Code",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                    //                 "conditionalArrayValue": ["INFORMATION", true],
                    //                 "required": true,
                    //             },
                    //
                    //             {
                    //                 "varName": "nomineeGuardianCountry2" + sl,
                    //                 "type": "autoCompleteValueReturn",
                    //                 "enum": [],
                    //                 "label": "Country",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                    //                 "conditionalArrayValue": ["INFORMATION", true],
                    //                 "required": true,
                    //             },
                    //             {
                    //                 "varName": "nomineeMinor" + sl,
                    //                 "type": "select",
                    //                 "label": "Nominee Minor",
                    //                 "enum": ["YES", "NO"],
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                    //                 "conditionalArrayValue": ["CIF ID", true],
                    //             },
                    //             {
                    //                 "type": "title",
                    //                 "label": "Guardian Details  " + sl,
                    //                 "grid": 12,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                    //                 "conditionalArrayValue": ["CIF ID", true],
                    //             },
                    //             {
                    //                 "varName": "nomineeGuardiansName" + sl,
                    //                 "type": "text",
                    //                 "label": "Guardians Name",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                    //                 "conditionalArrayValue": ["CIF ID", true],
                    //                 "multiline": true,
                    //                 "required": true,
                    //             },
                    //
                    //             {
                    //                 "varName": "nomineeGuardianCode" + sl,
                    //                 "type": "autoCompleteValueReturn",
                    //                 "enum": [],
                    //                 "label": "Guardian Code",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                    //                 "conditionalArrayValue": ["CIF ID", true],
                    //                 "required": true,
                    //
                    //             },
                    //             {
                    //                 "varName": "guardianPhotoId" + sl,
                    //                 "type": "text",
                    //                 "label": "Guardian Photo Id",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                    //                 "conditionalArrayValue": ["CIF ID", true],
                    //             },
                    //
                    //             {
                    //                 "varName": "nomineeGuardianAddress" + sl,
                    //                 "type": "text",
                    //                 "label": "Address 1",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                    //                 "conditionalArrayValue": ["CIF ID", true],
                    //                 "multiline": true,
                    //                 "required": true,
                    //             },
                    //             {
                    //                 "varName": "nomineeGuardianAddress2" + sl,
                    //                 "type": "text",
                    //                 "label": "Address 2",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                    //                 "conditionalArrayValue": ["CIF ID", true],
                    //                 "multiline": true,
                    //             },
                    //
                    //             {
                    //                 "varName": "nomineeGuardianCityCode" + sl,
                    //                 "type": "autoCompleteValueReturn",
                    //                 "enum": [],
                    //                 "label": "City Code",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                    //                 "conditionalArrayValue": ["CIF ID", true],
                    //                 "required": true,
                    //             },
                    //
                    //             {
                    //                 "varName": "nomineeGuardianStateCode" + sl,
                    //                 "type": "autoCompleteValueReturn",
                    //                 "enum": [],
                    //                 "label": "State Code",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                    //                 "conditionalArrayValue": ["CIF ID", true],
                    //                 "required": true,
                    //             },
                    //
                    //             {
                    //                 "varName": "nomineeGuardianPostalCode" + sl,
                    //                 "type": "text",
                    //                 "label": "Postal Code",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                    //                 "conditionalArrayValue": ["CIF ID", true],
                    //                 "required": true,
                    //             },
                    //
                    //             {
                    //                 "varName": "nomineeGuardianCountry2" + sl,
                    //                 "type": "autoCompleteValueReturn",
                    //                 "enum": [],
                    //                 "label": "Country",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //                 "conditionalArray": true,
                    //                 "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                    //                 "conditionalArrayValue": ["CIF ID", true],
                    //                 "required": true,
                    //             },
                    //         )
                    //     }
                    //     setTimeout(() => {
                    //         this.setState({
                    //             objectForNominee: objectForNominee
                    //         })
                    //     })
                    // }


                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            console.log(response.data);
                            response.data.map((data) => {

                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                            });
                            this.setState({
                                getRemarks: remarksArray
                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        });

                    setTimeout(() => {
                        this.setState({
                            inputData: this.removeNullValue(response.data),
                            varValue: this.removeNullValue(response.data),
                            getData: true,
                            showValue: true,
                        });
                    }, 1000)
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
        }

    }


    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };

    handleSubmit = (event, checker_approval) => {
        event.preventDefault();
        if (checker_approval === "RETURN") {
            let error = MyValidation.defaultValidation(checkerRemarks, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
        }
        this.setState({
            loading: true
        });

        var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.checkerRemarks + "/" + this.props.appId;
        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error)
            });
        this.state.inputData.checker_approval = checker_approval;
        if (this.state.fileUploadData.scanningFile !== undefined) {
            let fileUploadPath = backEndServerURL + "/case/upload";
            let types = 'Attachments';
            let files = this.state.fileUploadData.scanningFile;
            console.log("dfghjk");
            console.log(files);
            let formData = new FormData();
            formData.append("appId", this.props.appId);
            formData.append("file", files);
            formData.append("type", types);
            axios({
                method: 'post',
                url: fileUploadPath,
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'content-type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error)
                })
        }


        this.state.inputData.checkerRemarks = undefined;
        var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (this.state.inputData.dpsCloserRequest === "DPS Nominee Change" && checker_approval === "APPROVED") {
                    let updateNominee = backEndServerURL + "/updateTermDepositAccount/nomineeUpdate/" + this.state.inputData.accountNumber + "/" + this.props.appId;
                    axios.get(updateNominee, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            this.functionForCaseRoute()
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false,
                                cbNotFound: true,
                                title: error.response.data.message,
                            });
                        });
                } else {
                    this.functionForCaseRoute()
                }
            })
            .catch((error) => {
                console.log(error)
            });


    };

    functionForCaseRoute = () => {
        var url = backEndServerURL + "/case/route/" + this.props.appId;

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Case Route Successful!",
                    alert: true,
                    loading: false
                });
                this.props.closeModal()
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });
            });
    };


    renderFileUpload = () => {
        if (this.state.getData) {
            return (

                <Grid item xs={12}>
                    {FileTypeComponent.file(this.state, this.updateComponent, fileUpload)}
                </Grid>

            )
        }
    };

    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <Grid item xs={12}>
                    <div>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                                marginTop: 20

                            }}

                            type='button' value='add more'
                            onClick={(event) => this.handleSubmit(event, "APPROVED")}
                        >Approve
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                                marginTop: 20

                            }}
                            type='button' value='add more'
                            onClick={(event) => this.handleSubmit(event, "RETURN")}
                        >Return
                        </button>
                    </div>
                </Grid>
            )
        }
    };

    previewDocument = () => {
        this.setState({
            previewDocument: true
        })
    };

    viewAttachedImages = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return <PreviewMappingImage appId={this.props.appId} classes={classes}/>
        }
    };

    stopLoading = (value) => {
        if (value === "yes") {
            this.setState({
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    renderJsonFormFirst = () => {
        if (this.state.getData && this.state.inputData.accountNumber) {
            return (
                <React.Fragment>
                    <Label stopLoading={this.stopLoading}
                           accountNumber={this.state.inputData.accountNumber}
                           style={{margin: "0 auto"}} classes={this.props}
                           cbNumber={this.state.inputData.cbNumber}/>
                    <br/>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <br/>
                        </Grid>
                        <SignatureButton accountNumber={this.state.inputData.accountNumber} classes={this.props}/>&nbsp;
                        <PreviewDocument appId={this.props.appId} classes={this.props}/>
                        <Grid item xs={12}>
                            <br/>
                        </Grid>
                    </Grid>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent)
                    }
                    {this.renderNomineeUpdateForm()}
                </React.Fragment>
            )
        }
    };


    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, checkerRemarks, this.updateComponent)
            )
        }
    };

    renderNomineeNumber = () => {
        if (this.state.getData && this.state.inputData.dpsCloserRequest === "DPS Nominee Change") {
            return (
                <React.Fragment>
                    <Grid item>
                        <label className="input-label-common">Number Of New Nominee</label>
                    </Grid>
                    <Grid item>
                        <TextField
                            variant="outlined"
                            defaultValue={this.state.inputData["numberOfNominee"]}
                            name="numberOfNominee"
                            //label={field.label}
                            InputProps={{
                                readOnly: true
                            }}
                            //InputLabelProps={{shrink:true}}
                            fullWidth
                            style={{paddingRight: 20}}
                        />
                    </Grid>
                </React.Fragment>


            )
        }
    };


    renderNomineeForm = () => {
        if (this.state.existingObjectForNominee.length > 0 && this.state.existingNominee) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.state.existingObjectForNominee, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
            )
        }
    };

    onKeyDownForDedup = () => {

    };

    onKeyDownChange = () => {

    };

    renderNomineeUpdateForm = () => {
        if (this.state.inputData.dpsCloserRequest === "DPS Nominee Change") {
            return (
                <>
                    {this.renderExistingNomineeForm()}
                    <Grid item xs={12}></Grid>
                    <Grid item xs={6}>
                        {this.renderNomineeNumber()}
                    </Grid>
                    {this.renderNomineeForm()}
                </>
            )
        }
    };

    renderExistingNomineeForm = () => {
        if (this.state.getData && this.state.inputData.dpsCloserRequest === "DPS Nominee Change" && this.state.oldNominee && this.state.oldNomineeData.length > 0) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.state.oldNomineeData, this.updateComponent, this.onKeyDownForExistingNominee, this.onKeyChangeForExistingNominee)
            )

        }
    };

    onKeyChangeForExistingNominee = (data) => {
        console.log(data);
        let oldNominee = this.state.inputData.nomineeNumberOld ? parseInt(this.state.inputData.nomineeNumberOld) : 0;
        for (let i = 0; i < oldNominee; i++) {
            if (data.varName === "nomineeDob" + (i + 1)) {
                this.setState({
                    loading: true
                });
                this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                setTimeout(() => {
                    let x = 18;
                    let currentDate = new Date();
                    let someDate = new Date();
                    let numberOfDaysToAdd = x;
                    someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let convertDate = year + "-" + month + "-" + date;
                    let newDate = new Date(convertDate);
                    let stringToDate = new Date(newDate.toDateString());
                    let inputDate = new Date(this.state.inputData["nomineeDob" + (i + 1)]);
                    console.log(stringToDate);
                    console.log(inputDate);
                    console.log(stringToDate >= inputDate);
                    if (stringToDate >= inputDate) {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "NO";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                        // this.state.varValue["nomineeMinor" + i] == "NO";
                    } else {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "YES";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = true;
                    }

                    this.forceUpdate()
                }, 1000);
                this.setState({
                    showValue: true,
                    getData: true,
                    loading: false
                })


            }
        }
    };

    onKeyDownForExistingNominee = (event, data) => {
        let oldNominee = this.state.inputData.nomineeNumberOld ? parseInt(this.state.inputData.nomineeNumberOld) : 0;

        for (let i = 0; i < oldNominee; i++) {
            if (data === "nomineecifId" + (i + 1)) {
                this.setState({
                    loading: true,
                });
                this.state.inputData["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.varValue["nomineecifIdApiCall" + (i + 1)] = false;
                let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData["nomineecifId" + (i + 1)];
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.state.varValue["nomineecifId" + (i + 1)] = this.state.inputData["nomineecifId" + (i + 1)];
                        this.state.inputData["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.inputData["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.inputData["nomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.inputData["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.inputData["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.inputData["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.inputData["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.inputData["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.varValue["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.varValue["nomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.varValue["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.varValue["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.varValue["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.varValue["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        let x = 60;
                        let currentDate = new Date();
                        let someDate = new Date();
                        let numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        let date = someDate.getDate();
                        let month = someDate.getMonth() + 1;
                        let year = someDate.getFullYear();
                        let convertDate = year + "-" + month + "-" + date;
                        let newDate = new Date(convertDate);
                        let stringToDate = new Date(newDate.toDateString());

                        let inputDate = new Date(response.data.birthDate);
                        this.state.inputData["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        this.state.varValue["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        if (stringToDate >= inputDate) {
                            this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "YES";

                            // this.state.varValue["nomineeMinor" + i] == "NO";
                        } else {

                            this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "NO";
                            this.state.inputData["nomineeGuardiansName" + (i + 1)] = "";
                            this.state.inputData["nomineeGuardianCode" + (i + 1)] = "";
                            this.state.inputData["guardianPhotoId" + (i + 1)] = "";
                            this.state.inputData["nomineeGuardianAddress" + (i + 1)] = "";
                            this.state.inputData["nomineeGuardianAddress2" + (i + 1)] = "";
                            this.state.inputData["nomineeGuardianCityCode" + (i + 1)] = "";
                            this.state.inputData["nomineeGuardianStateCode" + (i + 1)] = "";
                            this.state.inputData["nomineeGuardianPostalCode" + (i + 1)] = "";
                            this.state.inputData["nomineeGuardianCountry2" + (i + 1)] = "";
                        }
                        this.state.inputData["nomineecifIdApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineecifIdApiCall" + (i + 1)] = true;
                        this.forceUpdate();
                        this.setState({
                            showValue: true,
                            getData: true,
                            loading: false
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                            errorTittle: error.response.data.message,
                            errorModalBoolean: true,
                        })
                    })

            }
        }
    };


    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };


    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);

        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Card>
                        <CardBody>
                            <ThemeProvider theme={theme}>
                                <Grid container spacing={0}>
                                    {this.renderNotification()}
                                    {this.renderJsonFormFirst()}
                                </Grid>
                                <br/>
                                {this.renderRemarksData()}
                                <Grid item xs={12}>
                                    <br/>
                                </Grid>
                                {this.viewAttachedImages()}
                                {this.renderRemarks()}
                                {this.renderSubmitButton()}
                            </ThemeProvider>
                        </CardBody>
                    </Card>
                </div>
            </GridList>
        )
    }
}

export default withStyles(styles)(CheckerInbox);
