import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../../Static/css/RelationShipView.css";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Table from "../../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import Notification from "../../../NotificationMessage/Notification";
import loader from "../../../../Static/loader.gif";
import DynamicFileAttachment from "../Common/DynamicFileAttachment";
import MyValidation from "../../../JsonForm/MyValidation";
import SignatureButton from "../../Maintenance/SignatureButton";
import DownloadCommonFormJsonPdf from "../../AssetEFTN/DownloadCommonFormJsonPdf";
import {CSsecuredLoanClosingJsonForm} from "../../WorkflowjsonFormForAssetOps";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";
import CommonApi from "../../Maintenance/common/CommonApi";
import Label from "../Common/Label";
import CBNotFound from "../../CASA/CBNotFound";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";
import AssetCommonFunctions from "../AssetCommonFunctions";
import functionForFileDownload from "../../../DeliverableManagement/FunctionForFileDownload";


const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};


let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }]
;


class CsSecuredLoanClosing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            setOpen: false,
            loader: null,
            imageLink: {},
            confirmAlert: false,
            actionType: "",
            inputData: {},
            getImageLink: [],
            getImageBoolean: false,
            appId: '',
            getData: false,
            varValue: {},
            caseId: "",
            title: "",
            notificationMessage: "",
            alert: false,
            type: [],
            selectedDate: {},
            showValue: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            fileUploadData: {},
            submitButtonClick: false,
            cbNotFound: false,
            submission: false,
            accountMasterData: [],

        }
    }

    renderFlowSUmmeryButton = () => {
        if (this.state.getData && this.props.appId !== undefined) {
            return (
                <FlowSummeryButton cbNumber={this.props.cbNumber} appId={this.props.appId}
                                   customerName={this.props.customerName} accountNumber={this.props.accountNumber}
                                   solId={this.props.solId}/>
            )
        }
    };

    componentDidMount() {
        if (this.props.appId !== undefined) {
            CommonApi.getVariablesNew(this.props.appId, assetOpsBackEndServerURL).then((response) => {
                let inputData = this.removeNullValue(response.data);
                inputData.serviceType = "Closing Request(secured Loan)";
                inputData.csRemarks = undefined;

                CommonApi.getFilesNew(this.props.appId, assetOpsBackEndServerURL).then((fileResponse) => {
                    CommonApi.getRemarkDetailsNew(this.props.appId, assetOpsBackEndServerURL).then((remarksArray) => {
                        this.setState({
                            getRemarks: remarksArray,
                            getImageLink: fileResponse,
                            getImageBoolean: true,
                            getData: true,
                            showValue: true,
                            varValue: inputData,
                            inputData: inputData,
                            appId: this.props.appId,
                            loading: false,
                            submission: true,
                        })
                    }).catch((error) => {
                        console.log(error)
                    });
                }).catch((error) => {
                    console.log(error);
                });
            }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
        } else {
            let url = assetOpsBackEndServerURL + "/startCase/cs_case_start_secured_loan_closing";
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let inputData = {};
                    inputData.serviceType = "Closing Request(secured Loan)";
                    this.setState({
                        getData: true,
                        showValue: true,
                        varValue: inputData,
                        inputData: inputData,
                        appId: response.data.id,
                        loading: false
                    });
                })
                .catch((error) => {
                    this.setState({
                        loading: false
                    });
                })
        }
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        return clone;

    };
    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = CSsecuredLoanClosingJsonForm;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                CSsecuredLoanClosingJsonForm[i].enum = getValue;
            }
        }
    };
    onBlurFunctionForApiCall = (event, varName, secondVarName) => {
        event.preventDefault();
        if (varName === "customerCB" && (this.state.inputData.customerCB === undefined || this.state.inputData.customerCB === null || this.state.inputData.customerCB === "")) {
            this.setState({
                loading: true
            });
            let inputData = {};
            inputData.customerCB = this.state.inputData.customerCB;
            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                loading: false,
                accountMasterData: [],
            });
        } else if (secondVarName === "customerCB") {
            let inputData = {...this.state.inputData};
            inputData.customerCBApiCall = "NO";
            inputData.loanAccountsCBApiCall = "NO";
            inputData.showCasaDetails = "NO";
            inputData.rateOfEsfApiCall = "NO";
            inputData.odAccountInquiryCall = "NO";
            inputData.cbNumber = undefined;
            inputData.loanAccounts = undefined;


            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                showValue: false,
                loading: true,
                accountMasterData: [],
            });
            this.forceUpdate();
            let url = assetOpsBackEndServerURL + "/getActiveLoanAndODAccountList/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    inputData.cbNumber = this.state.inputData[secondVarName];
                    inputData.customerName = response.data.name;
                    this.findByVarNameApiData("loanAccounts", response.data.accounts);
                    inputData.customerCBApiCall = "YES";
                    let accountMasterData = response.data.loanMasterData;

                    this.setState({
                        inputData: inputData,
                        varValue: this.removeNullValue(inputData),
                        loading: false,
                        showValue: true,
                        accountMasterData: accountMasterData,

                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message,
                    })
                })
        }
    };

    findDataFromMasterData = (value, masterData) => {
        let jsonArray = masterData;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (value === jsonObject.loanAccount) {
                return jsonObject;
            }
        }
    };
    onKeyDownChange = (data) => {
        if (data.varName === "loanAccounts" && (this.state.inputData[data.varName] === undefined || this.state.inputData[data.varName] === null)) {
            this.setState({
                loading: true
            });
            let inputData = {};

            inputData.customerCB = this.state.inputData.customerCB;
            inputData.cbNumber=this.state.inputData.cbNumber;
            inputData.customerCBApiCall = this.state.inputData.customerCBApiCall;
            inputData.loanAccounts = this.state.inputData.loanAccounts;
            inputData.customerName = this.state.inputData.customerName;
            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                loading: false,
                submission: false,
            });
            console.log(inputData);
        } else if (data.varName === "loanAccounts" && this.state.inputData[data.varName] !== undefined && this.state.inputData[data.varName] !== null) {
            let inputData = {...this.state.inputData};
            inputData.loanAccountsCBApiCall = "NO";
            inputData.showCasaDetails = "NO";
            inputData.rateOfEsfApiCall = "NO";
            inputData.odAccountInquiryCall = "NO";

            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                showValue: false,
                loading: true,
                submission: false,
            });
            let selectedAccountMasterData = this.findDataFromMasterData(this.state.inputData[data.varName], this.state.accountMasterData);
            if (selectedAccountMasterData.schemeType === "LAA") {
                let postData = {
                    "loanAccounts": this.state.inputData[data.varName],
                    "cbNumber": this.state.inputData.cbNumber,
                    "loanAccountDetails": "Y",
                    "loanGeneralInquiry": "Y",
                    "exciseDuty": "Y",
                    "loanAccountInfo": "Y",
                    "getCustomAcctDetails": "Y"
                };
                AssetCommonFunctions.getCustLoanAccountDetails(postData)
                    .then((custLoanAccountDetailsResponse) => {
                        // if (custLoanAccountDetailsResponse.data.loanAccountDetailsResponse.responseData.productName === "SECURED LOAN") {
                        inputData.loanCategory = selectedAccountMasterData.schemeType;
                        inputData.productName = custLoanAccountDetailsResponse.data.loanAccountDetailsResponse.responseData.productName;
                        inputData.classificationLoanStatus = custLoanAccountDetailsResponse.data.loanAccountDetailsResponse.responseData.loanStatus;
                        inputData.solId = custLoanAccountDetailsResponse.data.customAcctDetailsResponse.solId;
                        inputData.branchName = custLoanAccountDetailsResponse.data.customAcctDetailsResponse.branchName;
                        inputData.casaAccount = custLoanAccountDetailsResponse.data.loanAccountInfoResponse.operAcctId;
                        inputData.schemeCode = custLoanAccountDetailsResponse.data.loanAccountInfoResponse.schmCode;
                        inputData.acctId = custLoanAccountDetailsResponse.data.loanAccountInfoResponse.acctId;
                        inputData.loanAccountStatus = custLoanAccountDetailsResponse.data.loanAccountInfoResponse.accStatus;
                        inputData.loanAmt = custLoanAccountDetailsResponse.data.loanAccountInfoResponse.loanAmt;
                        inputData.loanAmt = custLoanAccountDetailsResponse.data.loanAccountInfoResponse.loanAmt;
                        inputData.dailyInterest = custLoanAccountDetailsResponse.data.loanGeneralInquiryResponse.dlyIntFact;
                        let maturityDate = custLoanAccountDetailsResponse.data.loanGeneralInquiryResponse.matDate;
                        let matDate = new Date();
                        let currentDate = new Date();
                        if (maturityDate) {
                            let date = (maturityDate).split(' ')[0].split("-");
                            inputData.loanAccountExpiryDate = `${date[2]}/${date[1]}/${date[0]}`;
                            matDate = new Date(date[2], date[1], date[0]);
                            if (matDate.getTime() < currentDate.getTime()) {
                                inputData.maturityStatus = "Expired";
                            } else {
                                inputData.maturityStatus = "Not Expired";
                            }
                            console.log(inputData.maturityStatus);
                        }
                        let disbursement = custLoanAccountDetailsResponse.data.loanAccountInfoResponse.acctOpenDt;
                        if (disbursement) {
                            let dateD = (disbursement).split('T')[0].split("-");
                            inputData.disbursementDate = `${dateD[0]}/${dateD[1]}/${dateD[2]}`;
                        }
                        inputData.loanAccountBalance = custLoanAccountDetailsResponse.data.loanGeneralInquiryResponse.payOffAmt;
                        inputData.accountNumber = this.state.inputData[data.varName];
                        inputData.exciseDuty = custLoanAccountDetailsResponse.data.exciseDutyResponse.amount;
                        inputData.casaBalance = custLoanAccountDetailsResponse.data.customAcctDetailsResponse.acctBalance;
                        inputData.casaAccountStatus = custLoanAccountDetailsResponse.data.customAcctDetailsResponse.acctStatus;
                        inputData.showCasaDetails = "YES";
                        inputData.loanAccountsCBApiCall = "YES";

                        this.setState({
                            inputData: inputData,
                            varValue: this.removeNullValue(inputData),
                            showValue: true,
                            getData: true,
                            submission: true,
                            loading: false
                        })

                        // } else {
                        //     this.forceUpdate();
                        //     this.setState({
                        //         loading: false,
                        //         submission: false,
                        //         reloadAfterModal: true,
                        //         title: "Not a Secured Loan",
                        //     })
                        // }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.forceUpdate();
                        this.setState({
                            loading: false,
                            cbNotFound: true,
                            title: error.response.data.message,
                        })
                    })
            } else if (selectedAccountMasterData.schemeType === "ODA") {
                AssetCommonFunctions.custODAccountDetails(this.state.inputData.cbNumber, this.state.inputData[data.varName])
                    .then((custODAccountDetailsResponse) => {
                        // if (custODAccountDetailsResponse.data.loanAccountDetailsResponse.responseData.productName === "SECURED LOAN") {

                            inputData.loanCategory = selectedAccountMasterData.schemeType;
                            inputData.productName = custODAccountDetailsResponse.data.loanAccountDetailsResponse.responseData.productName;
                            inputData.accountStatus = custODAccountDetailsResponse.data.customAcctDetailsResponse.acctStatus;
                            inputData.sanctionLimit = custODAccountDetailsResponse.data.loanAccountDetailsResponse.responseData.sanctionAmount;
                            inputData.loanOutstanding = custODAccountDetailsResponse.data.loanAccountDetailsResponse.responseData.totalOutstanding;
                            inputData.unappliedInterest = custODAccountDetailsResponse.data.unappliedInterest;
                            inputData.limitExpiry = custODAccountDetailsResponse.data.loanAccountDetailsResponse.responseData.loanEndDate;
                            inputData.sol = custODAccountDetailsResponse.data.customAcctDetailsResponse.solId;
                            inputData.branchName = custODAccountDetailsResponse.data.customAcctDetailsResponse.branchName;
                            inputData.classificationLoanStatus = custODAccountDetailsResponse.data.loanAccountDetailsResponse.responseData.loanStatus;
                            inputData.accountNumber = this.state.inputData[data.varName];
                            inputData.odAccountInquiryCall = "YES";
                            this.setState({
                                inputData: inputData,
                                varValue: this.removeNullValue(inputData),
                                showValue: true,
                                getData: true,
                                submission: true,
                                loading: false
                            })
                        // } else {
                        //     this.forceUpdate();
                        //     this.setState({
                        //         loading: false,
                        //         submission: false,
                        //         reloadAfterModal: true,
                        //         title: "Not a Secured Loan",
                        //     })
                        // }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.forceUpdate();
                        this.setState({
                            loading: false,
                            submission: false,
                            cbNotFound: true,
                            title: error.response.data.message,
                        })
                    })
            }

        } else if (data.varName === "rateOfEsf") {
            let inputData = {...this.state.inputData};
            inputData.rateOfEsfApiCall = "NO";
            inputData.earlySettlementFee = undefined;
            inputData.vat = undefined;
            inputData.totalAmount = undefined;
            this.setState({
                loading: true,
                inputData: inputData,
                varValue: this.emptyValueRemove(inputData),
                getData: false,

            });
            this.forceUpdate();
            console.log(inputData.rateOfEsf);
            if (inputData.rateOfEsf !== undefined && inputData.rateOfEsf !== null && inputData.loanAccountBalance !== undefined) {
                let earlySfee = parseFloat(inputData.loanAccountBalance) * (parseFloat(inputData.rateOfEsf) / 100);
                let vat = earlySfee * .15;
                let totalAmount = parseFloat(inputData.loanAccountBalance) + parseFloat(vat) + parseFloat(earlySfee) + parseFloat(inputData.exciseDuty);
                inputData.earlySettlementFee = earlySfee.toFixed(2);
                inputData.vat = vat.toFixed(2);
                inputData.totalAmount = totalAmount.toFixed(2);
                inputData.rateOfEsfApiCall = "YES";
                setTimeout(() => {
                    this.setState({
                        inputData: inputData,
                        varValue: this.emptyValueRemove(inputData),
                        getData: true,
                        loading: false,
                    })
                }, 0)
            } else {
                this.setState({
                    loading: false,
                    getData: true,
                })
            }


        }
    };
    renderForm = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, CSsecuredLoanClosingJsonForm, this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)
            );
        }
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    closeConfirmAlert = (value) => {
        if (value === "ok" && this.state.actionType === "handleSubmit") {
            this.handleSubmit("YES")
        } else if (value === "ok" && this.state.actionType === "handleReject") {
            this.handleSubmit("NO")
        }
        this.setState({
            confirmAlert: false,
        });
    };

    validationForHandleConfirm = (actionType) => {
        let errorRemarks = MyValidation.defaultValidation(csRemarks, this.state);
        this.forceUpdate();
        if (errorRemarks === true) {
            return 0;
        }
        this.setState({
            appId: this.state.appId,
            confirmAlert: false,
        });
        if (actionType === "handleSubmit") {
            let error = MyValidation.defaultValidation(CSsecuredLoanClosingJsonForm, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
            if (this.state.inputData.closedBy === "Cash") {
                let data = {...this.state.inputData};
                let total = parseFloat(data.loanAccountBalance) + parseFloat(data.vat) + parseFloat(data.earlySettlementFee) + parseFloat(data.exciseDuty);
                console.log("done");
                if (total > parseFloat(data.casaBalance)) {
                    this.setState({
                        cbNotFound: true,
                        title: "Not Enough Balance!",
                        loading: false
                    })
                } else {
                    this.setState({
                        actionType: actionType,
                        confirmAlert: true,
                        title: "Do you want to confirm?"

                    })
                }
            } else {
                console.log("done1");
                this.setState({
                    actionType: actionType,
                    confirmAlert: true,
                    title: "Do you want to confirm?"

                })
            }

        } else if (actionType === "handleSubmitDraft") {
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })
        } else if (actionType === "handleCaseClose") {
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })
        }
    };

    handleCommon = (event, type) => {
        event.preventDefault();
        this.validationForHandleConfirm(type)
    };

    handleSubmitButton = () => {
        if (this.state.getData && this.state.loading === false && this.state.submission === true) {

            return (
                <ThemeProvider>
                    <Grid>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                marginTop: "5px",
                                marginLeft: "3px"
                            }}
                            onClick={(event) => this.handleCommon(event, "handleSubmit")}

                        >
                            Submit
                        </button>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                marginTop: "5px",
                                marginLeft: "10px",
                            }}
                            onClick={this.printLoanClosingForm}
                        >
                            Print
                        </button>

                    </Grid>
                </ThemeProvider>
            )
        }
    };

    printHandler = event => {
        event.preventDefault();
        this.setState({
            loading: true,
        });
        DownloadCommonFormJsonPdf.formJsonPdf([...CSsecuredLoanClosingJsonForm, csRemarks[0]], this.state.inputData, "secured-loan-closing-form.pdf");
        this.setState({
            loading: false,
        })
    };
    printLoanClosingForm = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
        });
        let inputData = this.state.inputData;
        let url = assetOpsBackEndServerURL + "/getLoanClosingForm";
        functionForFileDownload.downloadFile(url, "POST", inputData, "secured-loan-closing-form.pdf").then((response) => {
            this.setState({
                loading: false
            })
        }).catch((error) => {
            this.setState({
                loading: false,
                title: "Something Went Wrong!",
                cbNotFound: true,
            })
        })
    };


    handleSubmit = () => {
        this.setState({
            submitButtonClick: true,
        });
    };
    close = () => {
        this.props.closeModal();
    };

    renderRemarks = () => {
        return (
            CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
        )
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{width: '100%'}}>
                    <br/>

                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>

                </div>

            )
        }

    };


    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            //let i=0;
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <Button target={"_blank"}
                                href={assetOpsBackEndServerURL + "/file/" + data + '/' + sessionStorage.getItem("accessToken")}
                                style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    borderRadius: 3,
                                    border: 1,
                                    textDecoration: "none"
                                }}
                                target={"_blank"}>{splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </Button>&nbsp;&nbsp;
                    </React.Fragment>
                )
            })
        }
    };

    renderDynamicFileUpload = () => {
        return <DynamicFileAttachment appId={this.state.appId}
                                      submitButtonClick={this.state.submitButtonClick}
                                      functionFoFile={this.functionFoFile}/>

    };

    functionForCaseRoute = () => {
        let saveDataDeferralRemarksAndRoute = {};
        if (this.state.inputData.csRemarks !== undefined && this.state.inputData.csRemarks !== null) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.csRemarks;
        }
        let data = this.state.inputData;
        //data.cs_deferal = "YES";
        data.serviceType = "Closing Request(secured Loan)";
        data.category = "Secured Loan Closing";
        data.subCategory = "Secured Loan Closing";
        saveDataDeferralRemarksAndRoute.map = data;
        let routeUrl = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
        axios.post(routeUrl, saveDataDeferralRemarksAndRoute, {
            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,},
        }).then((response) => {
            this.setState({
                loading: false,
                alert: true,
                title: "Successful!",
                notificationMessage: response.data,
            });
            if (this.props.appId !== undefined) {
                this.props.closeModal();
            } else {
                setTimeout(function () {
                    window.location.reload()
                }, 1000);
            }

        }).catch((error) => {
            console.log(error);
            this.setState({
                cbNotFound: true,
                title: error.response.data.message,
                loading: false
            })
        });
    };

    functionFoFile = (status) => {
        this.setState({
            submitButtonClick: false,
            loading: true,
        });
        if (status === "success" || status === "No File Found") {
            let uploadUrl = assetOpsBackEndServerURL + "/case/upload";
            if (this.state.fileUploadData.loanClosingApplication !== undefined && this.state.fileUploadData.loanClosingApplication !== null) {
                let formData = new FormData();
                formData.append("appId", this.state.appId);
                if (Object.values(this.state.fileUploadData).length === 1) {
                    formData.append("file", this.state.fileUploadData['loanClosingApplication']);
                    formData.append("type", "file1");
                }
                axios({
                    method: 'post',
                    url: uploadUrl,
                    data: formData,
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                        'content-type': 'multipart/form-data'
                    }
                }).then((response) => {
                    this.functionForCaseRoute();
                }).catch((error) => {
                    console.log(error);
                })
            } else {
                this.functionForCaseRoute()
            }
        } else {
            this.setState({
                cbNotFound: true,
                loading: false,
                title: "Failed to Upload File."
            })
        }
    };

    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b>Closing Request(Secured Loan)</b>
                    <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> CB Number:</b>{this.state.inputData.cbNumber}
                        <b> SOLID:</b>{this.state.inputData.solId} <b> Case
                            ID:</b> {this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                            onClick={this.close}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Closing Request(secured Loan){this.props.appId !== undefined ?
                    <a><CloseIcon onClick={this.close} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a> : ""}</h4>
            )
        }
    };

    renderCustomerDetails = () => {
        if (this.state.inputData.showCasaDetails === "YES") {
            return (
                <>
                    <Label accountNumber={this.state.inputData.casaAccount} stopLoading={this.stopLoading}
                           classes={this.props}/>
                    <Grid item xs={12}><br/></Grid>
                </>
            )
        }
    };
    renderSignature = () => {
        if (this.state.inputData.casaAccount && this.state.getData) {
            return (
                <div>
                    <br/>
                    <SignatureButton accountNumber={this.state.inputData.casaAccount} signatureType="multiple"
                                     classes={this.props}/>
                </div>
            )
        }
    };
    stopLoading = (value) => {
        if (value === "yes") {
            this.setState({
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };
    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    reloadAfterCloseModal = () => {
        this.setState({
            reloadAfterModal: false
        })
    };


    render() {
        const {classes} = this.props;
        {
            Functions.redirectToLogin(this.state)
        }

        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderHeader()}
                </CardHeader>
                <CardBody>
                    {this.renderNotification()}
                    <br/>
                    <ThemeProvider theme={theme}>
                        <Grid container spacing={1}>
                            {this.renderForm()}
                            {this.renderCustomerDetails()}
                            {this.renderSignature()}
                            {this.renderDynamicFileUpload()}
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.confirmAlert}>
                                <DialogContent className={classes.dialogPaper}>
                                    <ConfirmationModal
                                        closeModal={this.closeConfirmAlert}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>

                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>

                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.reloadAfterModal}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CBNotFound
                                        closeModal={this.reloadAfterCloseModal}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>

                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.loading}>
                                <DialogContent className={classes.dialogPaper}>
                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>

                            {this.mappingPhoto()}
                            {this.renderRemarksData()}
                            {this.renderFlowSUmmeryButton()}
                            {this.renderRemarks()}
                            {this.handleSubmitButton()}

                        </Grid>
                    </ThemeProvider>

                </CardBody>

            </Card>
        );
    }

}

export default withStyles(styles)(CsSecuredLoanClosing);
