import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Functions from '../../../Common/Functions';
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import Notification from "../../NotificationMessage/Notification";
import loading from "../../../Static/loader.gif";
import MyValidation from "../../JsonForm/MyValidation";
import CBNotFound from "../CASA/CBNotFound";

const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};
let searchForm = [
    {
        "varName": "hierarchyType",
        "type": "select",
        "required": true,
        "onKeyDown": true,
        "label": "Select Hierarchy",
        "enum": [
            "Region",
            "Cluster",
            "Branch"
        ],
        "grid": 3,
    },
];
let searchInput = [
    {
        "type": "empty",
        "grid": 12
    },
    {
        "varName": "unitName",
        "type": "text",
        "required": true,
        "label": "Region Name",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "hierarchyType",
        "conditionalVarValue": "Region"
    },
    {
        "varName": "parentId",
        "type": "autoCompleteValueReturn",
        "required": true,
        "label": "Select Parent",
        "enum": [],
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "hierarchyType",
        "conditionalVarValue": "Region"
    },
    {
        "varName": "status",
        "type": "select",
        "required": true,
        "label": "Select Status",
        "enum": [
            "Active",
            "Inactive"
        ],
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "hierarchyType",
        "conditionalVarValue": "Region"
    },
    {
        "varName": "unitName",
        "type": "text",
        "required": true,
        "label": "Cluster Name",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "hierarchyType",
        "conditionalVarValue": "Cluster"
    },
    {
        "varName": "parentId",
        "type": "autoCompleteValueReturn",
        "required": true,
        "label": "Select Region",
        "enum": [],
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "hierarchyType",
        "conditionalVarValue": "Cluster"
    },
    {
        "varName": "status",
        "type": "select",
        "required": true,
        "label": "Select Status",
        "enum": [
            "Active",
            "Inactive"
        ],
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "hierarchyType",
        "conditionalVarValue": "Cluster"
    },
    {
        "varName": "branchId",
        "type": "autoCompleteValueReturn",
        "required": true,
        "label": "Select Branch",
        "enum": [],
        "grid": 3,
        "conditionalArray": true,
        "conditionalArrayList": ["hierarchyType", "showField"],
        "conditionalArrayValue": ["Branch", true]
    },
    {
        "varName": "parentId",
        "type": "autoCompleteValueReturn",
        "required": true,
        "label": "Select Cluster",
        "enum": [],
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "hierarchyType",
        "conditionalVarValue": "Branch"
    }
];

class CreateHierarchy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            loading: false,
            cbNotFound: false,
            getData: true,
            varValue: {},
            title: "",
            notificationMessage: "",
            alert: false,
            selectedDate: {},
            showValue: false,
            showForm: false,
            err: false,
            errorArray: {},
            errorMessages: {},
        }
    }


    updateComponent = () => {
        this.forceUpdate();
    };

    functionForFetchData = (data) => {
        if (data.varName === "hierarchyType") {
            this.setState({
                loading: true,
                getData: false,
                showForm: false
            });
            this.state.inputData.showField = false;
            if (this.state.inputData.hierarchyType === "Branch") {
                let url = backEndServerURL + "/workplaceUnit/get/BRANCH";
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        let data = [];
                        response.data.map((item) => {
                            data.push({key: item.id, value: item.unitName})
                        });
                        for (let i = 0; i < searchInput.length; i++) {
                            if (searchInput[i].varName === "branchId") {
                                searchInput[i].enum = data
                            }
                        }
                        this.state.inputData.showField = true
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                        })
                    });
            }

            let segmentValue = this.state.inputData.hierarchyType === "Region" ? "NATIONAL" : this.state.inputData.hierarchyType === "Cluster" ? "REGION" : "CLUSTER";
            let url = backEndServerURL + "/workplaceUnit/get/" + segmentValue;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let data = [];
                    response.data.map((item) => {
                        data.push({key: item.id, value: item.unitName})
                    });
                    for (let i = 0; i < searchInput.length; i++) {
                        if (searchInput[i].varName === "parentId") {
                            searchInput[i].enum = data
                        }
                    }
                    this.setState({
                        loading: false,
                        getData: true,
                        showForm: true
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        getData: true
                    })
                });

        }
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderCloseIcon = () => {
        return (
            <h4 style={{color: "white"}} >Business Hierarchy<a><CloseIcon onClick={() => this.props.closeModal()} style={{
                position: 'absolute',
                right: 10,
                color: "#000000"
            }}/></a></h4>
        )
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    handleSubmit = () => {
        let error = MyValidation.defaultValidation(searchForm.concat(searchInput), this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            if (this.state.inputData.hierarchyType === "Region" || this.state.inputData.hierarchyType === "Cluster") {
                let postData = {};
                let parentId = this.state.inputData.parentId.key;
                postData.unitName = this.state.inputData.unitName;
                postData.parentId = parentId.toString();
                postData.status = this.state.inputData.status;
                postData.workplaceType = this.state.inputData.hierarchyType === "Region" ? "REGION" : "CLUSTER";
                let url = backEndServerURL + "/saveWorkplaceUnit";
                axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.setState({
                            title: "Successful!",
                            notificationMessage: "Data Save Successful!",
                            alert: true,
                        });
                        this.props.closeModal();
                        console.log(response.data)
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            cbNotFound: true,
                            title: "Data Save Failed!",
                        });
                    });
            } else {
                let postData = {};
                let parentId = this.state.inputData.parentId.key;
                postData.unitId = this.state.inputData.branchId.key;
                postData.parentId = parentId;
                let url = backEndServerURL + "/workplaceUnit/updateParent";
                axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.setState({
                            title: "Successful!",
                            notificationMessage: "Data Save Successful!",
                            alert: true,
                        });
                        this.props.closeModal();
                        console.log(response.data)
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            cbNotFound: true,
                            title: "Data Save Failed!",
                        });
                    });
            }
        }
    };

    handleSubmitButton = () => {
        if (this.state.getData && this.state.inputData.hierarchyType && this.state.showForm) {
            return (
                <ThemeProvider theme={theme}>
                    <Grid item xs={12}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{}}
                            onClick={this.handleSubmit}
                        >
                            Submit
                        </button>
                        &nbsp;&nbsp;
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={() => this.props.closeModal()}
                        >
                            Close
                        </button>
                    </Grid>
                </ThemeProvider>
            )
        }
    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderCloseIcon()}
                </CardHeader>
                <CardBody>
                    <ThemeProvider theme={theme}>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>
                                <center>
                                    <img src={loading} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Grid container>
                            {this.renderNotification()}
                            {CommonJsonFormComponent.renderJsonForm(this.state, searchForm, this.updateComponent, "", this.functionForFetchData)}
                            {(this.state.inputData.hierarchyType && this.state.showForm) && CommonJsonFormComponent.renderJsonForm(this.state, searchInput, this.updateComponent)}
                            <Grid item xs={12}>
                            </Grid>
                            {this.handleSubmitButton()}
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                        </Grid>
                    </ThemeProvider>
                </CardBody>
            </Card>

        );


    }

}

export default withStyles(styles)(CreateHierarchy);
