import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import React, {Component} from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import {Grow} from "@material-ui/core";
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody.jsx";
import Table from "../Table/Table.jsx";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import CircularProgress from '@material-ui/core/CircularProgress';
import Functions from '../../Common/Functions';
import CardHeader from "../Card/CardHeader";
import CloseIcon from '@material-ui/icons/Close';


const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

    button: {
        margin: theme.spacing(1),
    }
});

function Transition(props) {

    return <Grow in={true} timeout="auto" {...props} />;
}

class MakerView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ListOfAccounts: false,
            ListOfCards: false,
            StatusOfPropagation: false,
            ListOfLockerFacility: false,
            ListOfStandingInstruction: false,
            ListOfFreezeAccount: false,
            open: true,
            varValue: [],
            tableData: [[" ", " "]],
            getData: false,
            MAINCB: "",
            IDENTIFICATION_NO: '',
            CustomerSegment: '',
            renderCustomerGeneralInfoData: false,
            renderCustomerRelationTableData: false,
            redirectLogin: false,
        }
    }

    createTableData = (id, mainCb, relatedCb, relatedCustomerName, relatedCustomerDesignation) => {

        return ([mainCb, relatedCb, relatedCustomerName, relatedCustomerDesignation]);

    };


    componentDidMount() {


        if (this.props.IDENTIFICATION_NO !== undefined) {
            let url = backEndServerURL + "/view/360/" + this.props.IDENTIFICATION_NO;

            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    let varValue = [];

                    if (response.data.coreValue[0] !== null) {
                        varValue["IDENTIFICATION_NO"] = response.data.coreValue[0].IDENTIFICATION_NO;
                        varValue["CUSTOMERNAME"] = response.data.coreValue[0].CUSTOMERNAME;

                        varValue["HOMEBRANCH"] = response.data.coreValue[0].HOMEBRANCH;

                        varValue["NID"] = response.data.coreValue[0].NID;
                        varValue["TRADELICENSE"] = response.data.coreValue[0].TRADELICENSE;
                        varValue["FATHERNAME"] = response.data.coreValue[0].FATHERNAME;
                        varValue["SPOUSNAME"] = response.data.coreValue[0].SPOUSNAME;
                        varValue["CUSTSTAFF"] = response.data.coreValue[0].CUSTSTAFF;
                        let dataOfBirth = response.data.coreValue[0].DATEOFBIRTH.split('T');
                        varValue["DATEOFBIRTH"] = dataOfBirth[0];
                        varValue["CUSTOPNDATE"] = response.data.coreValue[0].CUSTOPNDATE;

                        varValue["GENDER"] = response.data.coreValue[0].GENDER;

                        if (response.data.coreValue[0].GENDER === "M") {
                            varValue["GENDER"] = "Male"
                        } else if (response.data.coreValue[0].GENDER === "F") {
                            varValue["GENDER"] = "Female"
                        } else {
                            varValue["GENDER"] = ""
                        }

                        varValue["PASSPORT"] = response.data.coreValue[0].PASSPORT;
                        varValue["TRADELICENSE"] = response.data.coreValue[0].TRADELICENSE;
                        varValue["MOTHERNAME"] = response.data.coreValue[0].MOTHERNAME;
                        varValue["INTRODUCERCB"] = response.data.coreValue[0].INTRODUCERCB;
                        varValue["ADDRESS1"] = response.data.coreValue[0].ADDRESS1;
                        varValue["CITY"] = response.data.coreValue[0].CITY;
                        varValue["COUNTRY"] = response.data.coreValue[0].COUNTRY;
                        varValue["MOBNO"] = response.data.coreValue[0].MOBNO;
                        varValue["EMAILADDRESS"] = response.data.coreValue[0].EMAILADDRESS;
                        varValue["ADDRESS2"] = response.data.coreValue[0].ADDRESS2;
                        varValue["STATE"] = response.data.coreValue[0].STATE;

                        varValue["CUSTOMERCONTITUTION"] = response.data.coreValue[0].CUSTOMERCONTITUTION;
                        varValue["CUSTOMERBAND"] = response.data.coreValue[0].CUSTOMERBAND;

                        varValue["OUTOCCUPATION"] = response.data.coreValue[0].OUTOCCUPATION;
                        varValue["SECTOR"] = response.data.coreValue[0].SECTOR;
                        varValue["ACQUIRINGRM"] = response.data.coreValue[0].ACQUIRINGRM;
                        varValue["SUBSECTOR"] = response.data.coreValue[0].SUBSECTOR;
                        varValue["MONITORINGRM"] = response.data.coreValue[0].MONITORINGRM;

                    } else {

                    }


                    this.setState({
                        varValue: varValue,
                        getData: true,
                        IDENTIFICATION_NO: varValue["IDENTIFICATION_NO"],
                        renderCustomerGeneralInfoData: true
                    })

                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                    this.setState({
                        renderCustomerGeneralInfoData: true
                    })
                });
            this.getRealtionshipTableData();
        } else {

        }
    }

    getRealtionshipTableData = () => {
        if (this.props.IDENTIFICATION_NO !== undefined) {
            let url = backEndServerURL + "/demographic/relatedCB/" + this.props.IDENTIFICATION_NO;
            console.log(url);
            let tableData = [];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);


                    response.data.map((demo, i) => {

                        tableData.push(this.createTableData(i, demo.mainCb, demo.relatedCb, demo.relatedCustomerName, demo.relatedCustomerDesignation));

                    });

                    this.setState({
                        tableData: tableData,
                        renderCustomerRelationTableData: true
                    });

                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                    this.setState({
                        renderCustomerRelationTableData: true
                    });
                });
        } else {

        }
    };

    ListOfAccounts(cbNumber) {
        this.setState({
            ListOfAccounts: true,
            IDENTIFICATION_NO: cbNumber
        });
    }

    ListOfCards(cbNumber) {
        this.setState({
            ListOfCards: true,
            IDENTIFICATION_NO: cbNumber
        });
    }

    StatusOfPropagation() {
        this.setState({
            StatusOfPropagation: true,
        });
    }

    ListOfLockerFacility() {
        this.setState({
            ListOfLockerFacility: true,
        });
    }

    ListOfStandingInstruction() {
        this.setState({
            ListOfStandingInstruction: true,
        });
    }

    ListOfFreezeAccount() {
        this.setState({
            ListOfFreezeAccount: true,
        });
    }


    SingleCards() {
        this.setState({
            SingleCards: true,
        });
    }

    close = () => {
        this.props.closeModal()
    };


    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    renderCustomerGeneralInfoData = () => {
        if (this.state.renderCustomerGeneralInfoData === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={["Demographics", "Value"
                    ]}
                    tableData={[
                        ["Customer ID", this.state.varValue["IDENTIFICATION_NO"]],
                        ["Full Name", this.state.varValue["CUSTOMERNAME"]],
                        ["Name On Card", this.state.varValue[""]],
                        ["Home Branch", this.state.varValue["HOMEBRANCH"]],
                        ["NID", this.state.varValue["NID"]],
                        ["TIN", this.state.varValue["TRADELICENSE"]],
                        ["Father Name", this.state.varValue["FATHERNAME"]],
                        ["Spouse Name", this.state.varValue["SPOUSNAME"]],
                        ["Staff Flag", this.state.varValue["CUSTSTAFF"]],


                    ]}
                    tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                />


            )
        } else if (this.state.renderCustomerGeneralInfoData === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    };
    renderCustomerGeneralInfoData2 = () => {
        if (this.state.renderCustomerGeneralInfoData === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={["Demographics", "Value"
                    ]}
                    tableData={[
                        ["Group ID", this.state.varValue[""]],
                        ["DOB", this.state.varValue["DATEOFBIRTH"]],
                        ["Gender", this.state.varValue["GENDER"]],
                        ["Passport", this.state.varValue["PASSPORT"]],
                        ["Trade License", this.state.varValue["TRADELICENSE"]],
                        ["Mother Name", this.state.varValue["MOTHERNAME"]],
                        ["Introducer CB", this.state.varValue["INTRODUCERCB"]],

                    ]}
                    tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                />

            )
        } else if (this.state.renderCustomerGeneralInfoData === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    };
    renderCustomerContactInfoData = () => {
        if (this.state.renderCustomerGeneralInfoData === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={["Demographics", "Value"
                    ]}
                    tableData={[
                        ["Address 1", this.state.varValue["ADDRESS1"]],
                        ["City", this.state.varValue["CITY"]],
                        ["Country", this.state.varValue["COUNTRY"]],
                        ["Mobile No.", this.state.varValue["MOBNO"]],
                        ["Email ID", this.state.varValue["EMAILADDRESS"]],
                    ]}
                    tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                />

            )
        } else if (this.state.renderCustomerGeneralInfoData === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    };
    renderCustomerContactInfoData2 = () => {
        if (this.state.renderCustomerGeneralInfoData === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={["Demographics", "Value"
                    ]}
                    tableData={[
                        ["Address 2", this.state.varValue["ADDRESS2"]],
                        ["State", this.state.varValue["STATE"]],
                        ["Residence Phone", this.state.varValue[""]],
                        ["Office Phone", this.state.varValue[""]],
                    ]}
                    tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                />

            )
        } else if (this.state.renderCustomerGeneralInfoData === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    };
    renderCustomerBusinessRelationData = () => {
        if (this.state.renderCustomerGeneralInfoData === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={["Demographics", "Value"
                    ]}
                    tableData={[
                        ["Customer Constitution", this.state.varValue["CUSTOMERCONTITUTION"]],
                        ["Customer Segment", this.state.varValue["CUSTOMERBAND"]],
                        ["Occupation", this.state.varValue["OUTOCCUPATION"]],
                        ["Sector", this.state.varValue["SECTOR"]],
                        ["Source", this.state.varValue[""]],
                        ["Acquiring RM", this.state.varValue["ACQUIRINGRM"]],
                    ]}
                    tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                />

            )
        } else if (this.state.renderCustomerGeneralInfoData === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    };
    renderCustomerBusinessRelationData2 = () => {
        if (this.state.renderCustomerGeneralInfoData === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={["Demographics", "Value"
                    ]}
                    tableData={[
                        ["Line of Business", this.state.varValue[""]],
                        ["Customer Type", this.state.varValue[""]],
                        ["Sub Sector", this.state.varValue["SUBSECTOR"]],
                        ["Lead Generator", this.state.varValue[""]],
                        ["Monitoring RM", this.state.varValue["MONITORINGRM"]],
                    ]}
                    tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                />
            )
        } else if (this.state.renderCustomerGeneralInfoData === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    };
    renderCustomerRelationTableData = () => {
        if (this.state.renderCustomerRelationTableData === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={["CB", "Related CB", "Related CB Name", "Relationship"]}
                    tableData={this.state.tableData}
                    tableAllign={['left', 'left', 'left', 'left']}
                />
            )
        } else if (this.state.renderCustomerRelationTableData === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (
            <section>
                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <paper>

                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                                    <h4 style={{color: "white"}} >Customer Info<a><CloseIcon onClick={this.close} style={{
                                        position: 'absolute',
                                        right: 10,
                                        color: "#000000"
                                    }}/></a></h4>

                                </CardHeader>
                            </paper>
                            <CardBody>

                                <div className={classes.root}>
                                    <Grid container spacing={5}>
                                        <Grid container item xs={12} spacing={5}>
                                            <Grid item xs={12}>
                                                <center>

                                                    <h4 style={{color: "white"}} >Customer General Info</h4>
                                                </center>

                                            </Grid>

                                            <Grid item xs={6}>
                                                <Paper className={classes.paper}>
                                                    {this.renderCustomerGeneralInfoData()}


                                                </Paper>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Paper className={classes.paper}>
                                                    {this.renderCustomerGeneralInfoData2()}


                                                </Paper>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <center>

                                                    <h4 style={{color: "white"}} >Customer Contact Info</h4>
                                                </center>

                                            </Grid>

                                            <Grid item xs={6}>
                                                <Paper className={classes.paper}>
                                                    {this.renderCustomerContactInfoData()}

                                                </Paper>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Paper className={classes.paper}>
                                                    {this.renderCustomerContactInfoData2()}
                                                </Paper>

                                            </Grid>


                                        </Grid>
                                    </Grid>
                                </div>


                            </CardBody>
                        </Card>
                    </GridItem>


                </GridContainer>
            </section>
        );
    }
}

export default withStyles(styles)(MakerView);