import React from "react";
import {backEndServerURL, frontEndServerURL} from "../Common/Constant";
import axios from "axios";
import Functions from "../Common/Functions";
import Table from "./Table/Table";
import Grid from "@material-ui/core/Grid";
import CardHeader from "./Card/CardHeader";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../Static/loader.gif";
import MakerCumInput from "./workflow/CASA/MakerCumInput";
import GridContainer from "./Grid/GridContainer";
import GridItem from "./Grid/GridItem";
import Card from "./Card/Card";
import withStyles from "@material-ui/core/styles/withStyles";
import Fab from '@material-ui/core/Fab';
import Pageview from '@material-ui/icons/Pageview';
import MakerView from "./360View/MakerView";
import SecondaryJointCbTagging from "./workflow/CASA/SecondaryJointCbTagging";
import WithoutDedupeComponent from "./workflow/CommonComponent/WithoutDedupeComponent";
import {CBDataJsonForm} from "./workflow/WorkflowJsonForm4";
import CustomerDedupSearch from "./CustomerDedupSearch";
import CustomerDedupSearchForMerchant from "./CustomerDedupSearchForMerchant";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};


class MerchantDedups extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            appData: {},
            getData: false,
            varValue: [],
            showValue: false,
            redirectLogin: false,
            title: "",
            notificationMessage: "",
            alert: false,
            inputData: {},
            selectedDate: {},
            SelectedDropdownSearchData: null,
            dropdownSearchData: {},
            values: [],
            customerName: [],
            deferalType: [],
            expireDate: [],
            other: [],
            getCheckerList: [],
            getAllDefferal: [],
            getDeferalList: [],
            loading: true,
            jointAccountCustomerNumber: 0,
            getCbNumber: 0,
            objectForJoinAccount: [],
            getgenerateForm: false,
            renderCumModalopen: false,
            renderCumModalopenNew: false,
            relatedPartyType: "",
            CBGenerateModal: false,
            generateAccountNo: '',
            getDedupData: {},
            jointDedupData: {},
            jointSearchTableData: [],
            propritorshipData: [],
            dedupData: [],
            relatedData: {},
            CustomerModal: false,
            IDENTIFICATION_NO: '',
            searchTableData: false,
            searchTableRelatedData: false,
            getRadioButtonData: {},
            tagingModalCbnumber: '',
            getCustomerNumber: '',
            err: false,
            errorArray: {},
            errorMessages: {},


        };


    }




    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }


        return (

            <CustomerDedupSearchForMerchant merchantDedup={true} /*companyDedupeBlock={true} nidBlockShowing={false}*//>
        )

    }


}

export default withStyles(styles)(MerchantDedups);
