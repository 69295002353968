import React, {Component} from "react";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../Common/Constant";
import {Icon, Menu} from "semantic-ui-react";
import "semantic-ui-offline/semantic.min.css";
import Button from "@material/react-button";
import MaterialTable from "material-table";
import {ThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import TablePagination from "@material-ui/core/TablePagination";
import {Badge, Dialog, Grid} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import axiosDownload from "../DeliverableManagement/FunctionForFileDownload";
import ConfirmationModal from "../workflow/FundTransfer/ConfirmationModal.jsx";

import {
    buttonStyle,
    commonInboxTabActive,
    commonInboxTabInactive,
    localization,
    options,
    tabActive,
    tabInactive,
    tableIcons,
} from "../DeliverableManagement/configuration";
import Notification from "../NotificationMessage/Notification";
import EditRequestInitialization from "../Greendelta/EditRequestInitialization";
import SendToCourierPion from "../Greendelta/SendToCourierPion";
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";
import RemarksModal from "../Greendelta/RemarksModal";
import FlowEFTNModal from "./FlowEFTNModal.jsx";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent.jsx";
import theme from "../JsonForm/CustomeTheme2.jsx";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#000",
        },
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: "auto",
    },
    Tab: {
        flexDirection: "row-reverse",
    },
    progress: {
        margin: 50,
    },
};

let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: "5",
            },
        },
    },
});

let Form = [
    {
        varName: "refNum",
        type: "text",
        label: "Reference Number",
        required: true,
        grid: 3,
    },
];

class OutgoingTrackerManage extends Component {
    state = {activeItem: "home", backgroundColor: "red"};

    constructor(props) {
        super(props);
        this.state = {
            appData: "",
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            getData: true,
            inputData: {},
            autoComplete: {},
            fileUploadData: {},
            responseData: [],
            activeItem: "All",
            backgroundColor: "red",
            showValue: true,
            uploadModal: false,
            searchItem: "",
            merchantModal: false,
            caseId: "",
            title: "",
            dataPassArray: [],
            buttonName: "Claim",
            notificationMessage: "",
            alert: false,
            type:"",
            showData: true,
            pageNumber: 0,
            remarksModalStatus: "",
            loader: true,
            totalRow: 0,
            rowsPerPage: 100,
            selectedCount: 0,
            summary: false,
            remarksModal: false,
            forwardArray: [],
            loading: false,
            failedList: [],
            failedModal: false,
            allData: [],
            allExcelData: [],
            confirmationAlert: false,
            btnName:"",
            previewData: false,
            confirmTitle: "",
            columnsForAllTab: [
                {title: "Reference Number", field: "referenceNumber"},
                {title: "Requisition Date", field: "requisitionDate",},
                {title: "Sender Name", field: "senderName"},
                {title: "Receiver Details", field: "receiverDetails"},
                {title: "Branch Name", field: "branchName"},
                {title: "Type of Document", field: "typeOfDocument"},
                {title: "Document Details", field: "documentDetails"},
                {title: "Status", field: "status"},
                { title: 'History', field: 'flow', type: 'Button' },
            ],
            columnsForNewTab: [
                {title: "Reference Number", field: "referenceNumber"},
                {title: "Requisition Date", field: "requisitionDate"},               
                {title: "Sender Name", field: "senderName"},
                {title: "Receiver Details", field: "receiverDetails"},
                {title: "Branch Name", field: "branchName"},
                {title: "Type of Document", field: "typeOfDocument"},
                {title: "Document Details", field: "documentDetails"},
                {title: "Status", field: "status"},
               
            ],
            columns: [
                {title: "Reference Number", field: "referenceNumber"},
                {title: "Requisition Date", field: "requisitionDate"},
                {title: "Sender Name", field: "senderName"},
                {title: "Receiver Details", field: "receiverDetails"},
                {title: "Branch Name", field: "branchName"},
                {title: "Type of Document", field: "typeOfDocument"},
                {title: "Document Details", field: "documentDetails"},
            ],
            columnsLostCancel: [
                {title: "Reference Number", field: "referenceNumber"},
                {title: "Requisition Date", field: "requisitionDate"},
                {title: "Sender Name", field: "senderName"},
                {title: "Receiver Details", field: "receiverDetails"},
                {title: "Branch Name", field: "branchName"},
                {title: "Type of Document", field: "typeOfDocument"},
                {title: "Document Details", field: "documentDetails"},
                {title: "Remarks", field: "remarks"},
            ],
            columnsOfHistory: [
                { title: "Created By", field: "createdBy" },
                { title: "Ref Number", field: "refNum" },
                { title: "Date", field: "createdDate" },
                { title: "Type", field: "type" },
                { title: "Status", field: "status" }, 
            ]
        };
    }

    closePreviewData = () => {
        this.setState({
            previewData: false,
        });
    };

    renderPreviewButton = (refNum,data) => {
        return (
            <>
                <Badge className="btn btn-sm btn-info p-1 px-2" style={{ "backgroundColor":"#81c784"  , "fontSize":"11px", "color": "#ffffff", "marginBottom": "2px" }}
                    onClick={() => this.handleFlowSummery(refNum)}> History </Badge>
            </>
        )
       
    };

    handleFlowSummery=(refNum)=>{
        this.setState({
            loading: true
        });
        let url = backEndServerURL + "/green-delta/history/" + refNum;
       axios
           .get(url, { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } })
           .then(response => {
               console.log(response);
               let array = [];
               let rowData;
               console.log();
               response.data.length>0 &&
               response.data.map((date,idx)=>{
                rowData={
                    createdBy: date.createdBy,
                    refNum: date.referenceNumber,
                    createdDate: date.createdDate,
                    type: date.type,
                    status: date.status,
                }
                
                 array.push(rowData);
               })
           
               this.setState({
                   allExcelData: array,
                   loading: false,
                   previewData: true,
               });
               console.log(this.state.allExcelData);
           })
           .catch(error => {
               this.setState({
                   allExcelData: [],
                   loading: false,
                   previewData: true,
               });
           });
    }

    handleItemClick = (e, {name, style}) =>
        this.setState({
            activeItem: name,
            backgroundColor: style,
        });
    headerSelect = (event, value) => {
        event.preventDefault();
        console.log(value);
        this.setState({
            loader: true,
            pageNumber: 0,
        });
        if (value === "All") {
            this.fetchDataFromAPI("All", "get/allRequisitions/0");
        } else {
            this.fetchDataFromAPI(value);
        }
    };
    handleRemarksModalOpen = (event, rowData, props, activeItem, status) => {
        let allSelectValue = (rowData, props.data);
        if (allSelectValue === undefined) return 0;
        let rowIdArray = [];
        if (allSelectValue != null) {
            for (let i = 0; i < allSelectValue.length; i++) {
                rowIdArray.push(allSelectValue[i].id);
            }
        }
        this.setState({
            remarksModalStatus: status,
            remarksModal: true,
            rowIdArray: rowIdArray
        })
    };
    handleRemarksModalClose = () => {
        this.setState({
            remarksModal: false,
        })
        this.fetchDataFromAPI(this.state.activeItem);
    }
    handleAllocateModal = (event, rowData, props, activeItem, status) => {
        console.log(rowData)
        console.log(props.data)
        let allSelectValue = (rowData, props.data);
        console.log(allSelectValue)
        if (allSelectValue === undefined) return 0;
        let rowIdArray = [];
        if (allSelectValue != null) {
            for (let i = 0; i < allSelectValue.length; i++) {
                rowIdArray.push(allSelectValue[i].id);
            }
        }
        this.setState({
            summary: true,
            rowIdArray: rowIdArray
        })
    };
    closeAllocateModal = () => {
        this.setState({
            summary: false,
        })
        this.fetchDataFromAPI(this.state.activeItem);
    }
    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === null ||
                clone[prop] === undefined
            )
                delete clone[prop];
        return clone;
    };
    searchData = (e, page, status) => {
        let input = {...this.state.inputData};
        input.currency = this.state.inputData.currency
            ? this.state.inputData.currency.value
            : "";
        let postData = this.removeNullValue(input);
        this.forceUpdate();
        if (Object.keys(postData).length === 0) {
            return 0;
        }
        if (status === "All") {
            this.setState({
                loader: true,
                pageNumber: page,
                activeItem: "All",
            });
        } else {
            this.setState({
                loader: true,
                pageNumber: page,
                activeItem: status,
            });
        }
        let inbox_url;
        if (status === "All") {
            inbox_url =
                backEndServerURL +
                "/filterChequebookDateCustomerAccountBranch/" +
                status +
                "/" +
                page;
        } else {
            inbox_url =
                backEndServerURL +
                "/filterBranchChequebookDateCustomerAccount/" +
                status +
                "/" +
                page;
        }
        axios
            .post(inbox_url, postData, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                },
            })
            .then((response) => {
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].appId;
                    rowData = {
                        id: response.data.content[i].id,
                        requisitionDate: response.data.content[i].requisitionDate.split(".")[0].replace("T", " "),
                        referenceNumber: response.data.content[i].referenceNumber,
                        senderName: response.data.content[i].senderName,
                        receiverDetails: response.data.content[i].receiverDetails,
                        branchName: response.data.content[i].branchName,
                        typeOfDocument: response.data.content[i].typeOfDocument,
                        documentDetails: response.data.content[i].documentDetails,
                        status: response.data.content[i].status,
                        flow: this.renderPreviewButton(response.data.content[i].referenceNumber,response.data.content[i]),

                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }
                this.setState({
                    allData: array,
                    responseData: responseArray,
                    totalRow: response.data.totalElements,
                    loading: false,
                    loader: false,
                    searchItem: this.state.activeItem,
                });
                console.log(this.state.allData);
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    loader: false,
                });
            });
    };

    dateConverter = (value) => {
        let dateString = value.substr(0, 10);
        return dateString;
    };
    fetchDataFromAPI = (status, url) => {
        if (this.state.searchItem !== "") {
            this.searchData(this, this.state.pageNumber, status);
        } else {
            this.setState({
                activeItem: status,
                loader: true,
            });
            let urlSegment;
            let inbox_url;
            if (url === undefined) {
                urlSegment = "get/allRequisitions";
            } else {
                urlSegment = url;
            }
            if (status === "All") {
                inbox_url = backEndServerURL + "/" + urlSegment;
            } else if(status === "Allocated") {
                let role=LocalstorageEncrypt.encryptStorageFunction.getItem("roles")[0];
                inbox_url = backEndServerURL + "/getAllocatedRequisitionsByUser/Allocated/" + role + "/0";
            } else {
                if (status == 'Cancel') {
                    status = 'Cancelled'
                }
                inbox_url = backEndServerURL + "/get/outgoingRequisitionStatusWiseList/" + status + "/0";
            }
            axios.get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,},})
                .then((response) => {
                    console.log(response);
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        rowData = {
                            id: response.data.content[i].id,
                            requisitionDate: response.data.content[i].requisitionDate.split(".")[0].replace("T", " "),
                            referenceNumber: response.data.content[i].referenceNumber,
                            senderName: response.data.content[i].senderName,
                            receiverDetails: response.data.content[i].receiverDetails,
                            branchName: response.data.content[i].branchName,
                            typeOfDocument: response.data.content[i].typeOfDocument,
                            documentDetails: response.data.content[i].documentDetails,
                            status: response.data.content[i].status,
                            remarks: response.data.content[i].extData && response.data.content[i].extData.remarks ,
                            flow: this.renderPreviewButton(response.data.content[i].referenceNumber,response.data.content[i]),
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData);
                    }
                    this.setState({
                        allData: array,
                        responseData: responseArray,
                        totalRow: response.data.totalElements,
                        loader: false,
                    });
                    console.log(this.state.allData);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    handleChangePage = (pageNumber, status) => {
        let page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        
        if (this.state.searchItem !== "") {
            this.searchData(this, page, status);
        } else {
            let inbox_url;
            if (this.state.activeItem === status) {
                this.setState({
                    loader: true,
                });
                let urlSegment;
                if (status === undefined) {
                    urlSegment = "get/allRequisitions/"+ page;
                } else {
                    urlSegment = "get/allRequisitions/"+ page;
                }
                if (status === "All") {
                    inbox_url = backEndServerURL + "/" + urlSegment;
                } else if(status === "Allocated"){
                    let role=LocalstorageEncrypt.encryptStorageFunction.getItem("roles")[0];
                    inbox_url = backEndServerURL + "/getAllocatedRequisitionsByUser/Allocated/" + role + "/"+page;
                } else {
                    inbox_url = backEndServerURL + "/get/outgoingRequisitionStatusWiseList/" + status + "/"+page;
                }
                axios.get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,},})
                    .then((response) => {
                        let array = [];
                        let responseArray = [];
                        let rowData;
                        let responseRowData;
                        for (let i = 0; i < response.data.content.length; i++) {
                            responseRowData = response.data.content[i].appId;
                            rowData = {
                                id: response.data.content[i].id,
                                requisitionDate: response.data.content[i].requisitionDate.split(".")[0].replace("T", " "),
                                referenceNumber: response.data.content[i].referenceNumber,
                                senderName: response.data.content[i].senderName,
                                receiverDetails: response.data.content[i].receiverDetails,
                                branchName: response.data.content[i].branchName,
                                typeOfDocument: response.data.content[i].typeOfDocument,
                                documentDetails: response.data.content[i].documentDetails,
                                status: response.data.content[i].status,
                                remarks: response.data.content[i].extData && response.data.content[i].extData.remarks ,
                            };
                            array.push(rowData);
                            responseArray.push(responseRowData);
                        }
                        this.setState({
                            allData: array,
                            responseData: responseArray,
                            activeItem: "All",
                            pageNumber: page,
                            totalRow: response.data.totalElements,
                            loader: false,
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loader: false,
                        });
                    });
            }
        }
    };
    firstFetchTabandUrl=()=>{

        let totalMenu=["All","New","Received","Allocated","Picked","Cancel","Lost","Acknowledged"]
        let tabUrl=[];
        let checking=0;
        totalMenu.map((status,idx)=>{
           
            if ((LocalstorageEncrypt.encryptStorageFunction.getItem("menus")).indexOf(status) !== -1) {
                if(status==="Acknowledged"){
                    status="Delivered";
                }
                let inbox_url;
              
                if (status === "All") {
                    inbox_url = backEndServerURL + "/get/allRequisitions/0";
                } else if(status === "Allocated") {
                    let role=LocalstorageEncrypt.encryptStorageFunction.getItem("roles")[0];
                    inbox_url = backEndServerURL + "/getAllocatedRequisitionsByUser/Allocated/" + role + "/0";
                } else {
                    if (status == 'Cancel') {
                        status = 'Cancelled'
                    }
                    inbox_url = backEndServerURL + "/get/outgoingRequisitionStatusWiseList/" + status + "/0";
                }
                let obk={}
                obk.tab=status;
                obk.url=inbox_url;
                tabUrl.push(obk)
      
            }
          
        })
        return tabUrl;
    }
    componentDidMount() {

      let tabUrl=  this.firstFetchTabandUrl();
      if(tabUrl.length>0){
        let inbox_url = tabUrl[0].url;
       
        this.setState({
            activeItem: tabUrl[0].tab,
        });
      
        axios
            .get(inbox_url, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                },
            })
            .then((response) => {
                console.log(response);
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].appId;
                    rowData = {
                        id: response.data.content[i].id,
                        requisitionDate: response.data.content[i].requisitionDate.split(".")[0].replace("T", " "),
                        referenceNumber: response.data.content[i].referenceNumber,
                        senderName: response.data.content[i].senderName,
                        receiverDetails: response.data.content[i].receiverDetails,
                        branchName: response.data.content[i].branchName,
                        typeOfDocument: response.data.content[i].typeOfDocument,
                        documentDetails: response.data.content[i].documentDetails,
                        status: response.data.content[i].status,
                        flow: this.renderPreviewButton(response.data.content[i].referenceNumber,response.data.content[i]),

                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }
                this.setState({
                    allData: array,
                    responseData: responseArray,
                    totalRow: response.data.totalElements,
                    loader: false,
                });
                console.log(this.state.allData);
            })
            .catch((error) => {
                console.log(error);
            });
      }else{
        this.setState({
            loader: false,
        });
      }
       
    }
    updateComponent = () => {
        this.forceUpdate();
    };
    open = (data) => {
        this.setState({
            dataPassArray: data,
            merchantModal: true,
        });
    };
    resetHandler = () => {
        window.location.reload();
    };
    closeModal = () => {
        this.setState({
            editMode: false,
        });
        this.fetchDataFromAPI(this.state.activeItem);
    };
    renderMenu = () => {
        return (
            <div style={{marginRight: "8px"}}>
                <Menu  style={{overflowX: 'auto', overflowY: 'hidden'}}>
                    {this.renderMenuItem("All", "All", this.state.getCountGroupInbox, "All Request","th list")}
                    {this.renderMenuItem("New", "New", this.state.getCountGroupInbox, "New","envelope")}
                    {this.renderMenuItem("Received", "Received", this.state.getCountGroupInbox, "Received","bell")}
                    {this.renderMenuItem("Allocated", "Allocated", this.state.getCountGroupInbox, "Allocated","lock")}
                    {this.renderMenuItem("Picked", "Picked", this.state.getCountGroupInbox, "Picked","hourglass end")}
                    {this.renderMenuItem("Cancel", "Cancel", this.state.getCountGroupInbox, "Cancelled","ban")}
                    {this.renderMenuItem("Lost", "Lost", this.state.getCountGroupInbox, "Lost","exclamation triangle")}
                    {this.renderMenuItem("Acknowledged", "Delivered", this.state.getCountGroupInbox, "Delivered","inbox")}
                </Menu>
            </div>
        );
    };
    renderBadge = (value, name) => {
        if (value !== undefined && value > 0) {
            return (
                <Badge color="primary">
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        } else if (value === 0) {
            return (
                <Badge color="primary" showZero>
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        } else {
            return (
                <Badge color="primary">
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        }
    };
    renderMenuItem = (permission, activeItem, badge, name, icon) => {
        if ((LocalstorageEncrypt.encryptStorageFunction.getItem("menus")).indexOf(permission) !== -1) {
            return (
                <Menu.Item
                    name={name}
                    active={this.state.activeItem === activeItem}
                    style={this.state.activeItem === activeItem ? commonInboxTabActive : commonInboxTabInactive}
                    onClick={e => {
                        this.headerSelect(e, activeItem);
                    }}
                >
                      <Icon name={icon} style={{
                         marginTop: "5px"
                      }} />
                    {this.renderBadge(badge, name)}
                </Menu.Item>
            )
        }

    };
    downloadSelectedInfo=(event, rowData, props, activeItem)=>{
        let download_url = backEndServerURL+"/requestSheetReport/"+ activeItem;
        let allSelectValue = (rowData, props.data);
        if (allSelectValue === undefined) return 0;
        let rowIdArray = [];
        if (allSelectValue != null) {
            for (let i = 0; i < allSelectValue.length; i++) {
                rowIdArray.push(allSelectValue[i].id);
            }

            axiosDownload.downloadFile(download_url, 'POST', rowIdArray, `requestSheetCourier.pdf`).then((result) => {
                if (result) {
                    this.setState({
                        loading: false
                    });
                 
                }
            }).catch((error) => {
                this.setState({
                    loading: false
                });
            })

        }

    }

    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                loading: true,
                confirmationAlert: false
            });
            this.updateStatus()
        } else {
            this.setState({
                loading: false,
                confirmationAlert: false
            })
        }
    }

    updateStatusModal = (event, rowData, props, activeItem, status) => {
        let allSelectValue = (rowData, props.data);
        if (allSelectValue === undefined) return 0;
        let rowIdArray = [];
        if (allSelectValue != null) {
            for (let i = 0; i < allSelectValue.length; i++) {
                rowIdArray.push(allSelectValue[i].id);
            }
        this.setState({
            confirmTitle: "Do you want to confirm?",
            btnName: status,
            confirmationAlert: true,
            rowIdArray: rowIdArray,
            
        })
    }
  }


    updateStatus = () => {
        let status = this.state.btnName;
        let rowIdArray = this.state.rowIdArray;
            let single_url =
                backEndServerURL +
                "/updateOutgoingRequisitions/" +
                this.state.activeItem + "/" + status;
            axios
                .post(single_url, rowIdArray, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                       
                       
                        if(status==="Received"){
                            let mail_url =
                            backEndServerURL + "/mail-notification/outGoing/" + this.state.activeItem + "/" + status;
                        axios
                            .post(mail_url, rowIdArray, {
                                headers: {
                                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                                },
                            })
                            .then((res) => {
                               
                            })
                                .catch((error) => {
                                    console.log(error);
                                });

                            this.functionForNotificationMessage(
                                "success",
                                "Successful!",
                                status
                            );
                            this.fetchDataFromAPI(this.state.activeItem);

                        } else {
                            this.functionForNotificationMessage(
                                "success",
                                "Successful!",
                                status
                            );
                            this.fetchDataFromAPI(this.state.activeItem);
                        }
                    
                      
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        
    }
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type={this.state.type}
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };
    stopNotification = () => {
        this.setState({
            alert: false,
        });
    };

    functionForNotificationMessage = (type,title, message) => {
        this.setState({
            type:type,
            title: title,
            notificationMessage: message,
            alert: true,
            loading: false,
        });
    };

    tableChoice() {
        const {classes} = this.props;
        if (this.state.loader) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loader}
                >
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            );
        } else if (this.state.activeItem === "All" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columnsForAllTab}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: (props) => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, "All");
                                            }}
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "New" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columnsForNewTab}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: (props) => (
                                        <React.Fragment>
                                            <Button
                                                style={buttonStyle}
                                                // onClick={(event, rowData) => this.handleModalOpen(event, rowData, props)}
                                                onClick={(event, rowData) => this.updateStatusModal(event, rowData, props, this.state.activeItem, "Received")}
                                            >
                                                Received
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) =>
                                                    this.handleRemarksModalOpen(event, rowData, props, this.state.activeItem, "Cancelled")
                                                }
                                            >
                                                Cancel
                                            </Button>
                                        </React.Fragment>

                                    ),
                                    Pagination: (props) => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, "New");
                                            }}
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Received" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: (props) => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.handleAllocateModal(event, rowData, props, this.state.activeItem, "Allocated")}
                                                open={this.state.loading}
                                            >
                                                Allocate
                                            </Button>
                                        </React.Fragment>
                                    ),
                                    Pagination: (props) => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, "On Transit");
                                            }}
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Allocated" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: (props) => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.updateStatusModal(event, rowData, props, this.state.activeItem, "Picked")}
                                                open={this.state.loading}
                                            >
                                                Picked
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.downloadSelectedInfo(event, rowData, props, this.state.activeItem)}
                                                open={this.state.loading}
                                            >
                                                Download
                                            </Button>
                                        </React.Fragment>
                                    ),
                                    Pagination: (props) => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, "Return");
                                            }}
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Picked" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: (props) => (
                                        <React.Fragment>
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.handleRemarksModalOpen(event, rowData, props, this.state.activeItem, "Delivered")}
                                            >
                                                Delivered
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) =>
                                                    this.handleRemarksModalOpen(event, rowData, props, this.state.activeItem, "Cancelled")
                                                }
                                            >
                                                Cancel
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) =>
                                                    this.handleRemarksModalOpen(event, rowData, props, this.state.activeItem, "Lost")
                                                }
                                            >
                                                Lost
                                            </Button>

                                        </React.Fragment>
                                    ),
                                    Pagination: (props) => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, "Picked");
                                            }}
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Cancel" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columnsLostCancel}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: (props) => (
                                        <React.Fragment>
                                        </React.Fragment>
                                    ),
                                    Pagination: (props) => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, "Delivered");
                                            }}
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Lost" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columnsLostCancel}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: (props) => (
                                        <React.Fragment>
                                        </React.Fragment>
                                    ),
                                    Pagination: (props) => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, "Lost");
                                            }}
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Delivered" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: (props) => <React.Fragment></React.Fragment>,
                                    Pagination: (props) => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, "Delivered");
                                            }}
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: (props) => (
                                        <React.Fragment>
                                            &nbsp;
                                        </React.Fragment>
                                    ),
                                    Pagination: (props) => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem);
                                            }}
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        }
    }
    onKeyDownChange = (data) => {
    }
    updateComponent = () => {
        this.forceUpdate();
    };
    renderJsonForm = () => {
     
            return CommonJsonFormComponent.renderJsonForm(
                this.state,
                Form,
                this.updateComponent, undefined, this.onKeyDownChange
            );
        
    };
    handleSearch= () =>{
        this.setState({
            loader: true,
        });
        let tabName = this.state.activeItem;

        if(this.state.activeItem==="Cancel"){
            tabName= "Cancelled"
        }
        let single_url =
        backEndServerURL + "/filter/outgoing/"+tabName+ "/" + this.state.inputData.refNum;
    axios
        .get(single_url, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
            },
        })
        .then((response) => {
            let array = [];
            let responseArray = [];
            let rowData;
            let responseRowData;
            for (let i = 0; i < response.data.length; i++) {
               // responseRowData = response.data.content[i].appId;
                rowData = {
                    id: response.data[i].id,
                    requisitionDate: response.data[i].requisitionDate.split(".")[0].replace("T", " "),
                    referenceNumber: response.data[i].referenceNumber,
                    senderName: response.data[i].senderName,
                    receiverDetails: response.data[i].receiverDetails,
                    branchName: response.data[i].branchName,
                    typeOfDocument: response.data[i].typeOfDocument,
                    documentDetails: response.data[i].documentDetails,
                    status: response.data[i].status,
                    flow: this.renderPreviewButton(response.data[i].referenceNumber,response.data[i]),

                };
                array.push(rowData);
                // responseArray.push(responseRowData);
            }
            this.setState({
                allData: array,
                // responseData: responseArray,
                loader: false,
            });
          
            
           
        })
        .catch((err)=>{
            this.functionForNotificationMessage(
                "error",
                "Not Found!",
                ""
            );
            this.setState({
                activeItem: this.state.activeItem,
                loader: false,
            });
        })
    }
    renderButton = () => {
        return (
            <button
                className="btn btn-outline-primary  btn-sm"
                style={{
                    marginTop: "18px",
                    marginBottom: "20px",
                }}
                onClick={this.handleSearch}
            >
                Search
            </button>
        );
    };
    render() {
        const {activeItem} = this.state;
        const {classes} = this.props;
        console.log(this.state.inputData.callStatus);
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}
                >
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            );
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader
                            style={{
                                backgroundColor: "#142398",
                            }}
                        >
                            <h4
                                style={{
                                    color: "white",
                                }}
                            >
                                Dispatch Tracker Management (Outgoing)
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <div className="deliverable">
                                <React.Fragment>
                                    <div>
                                        <ThemeProvider theme={theme}>
                                            <Grid container>
                                                {this.renderNotification()}
                                                {this.renderJsonForm()}
                                                {this.renderButton()}
                                                <br />
                                                <Grid item xs="12"></Grid>
                                               
                                            </Grid>
                                        </ThemeProvider>
                                        <Grid item xs="12"></Grid>
                                        <Grid item xs="12"></Grid>
                                        {this.renderMenu()}
                                        {this.tableChoice()}
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.summary}
                                        >
                                            <DialogContent className={classes.dialogPaper}>
                                                <SendToCourierPion rowIdArray={this.state.rowIdArray}
                                                                   activeItem={this.state.activeItem}
                                                                   closeModal={this.closeAllocateModal}
                                                />

                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.remarksModal}
                                        >
                                            <DialogContent className={classes.dialogPaper}>
                                                <RemarksModal rowIdArray={this.state.rowIdArray}
                                                              activeItem={this.state.activeItem}
                                                              status={this.state.remarksModalStatus}
                                                              type={"OUTGOING"}
                                                              closeModal={this.handleRemarksModalClose}
                                                />

                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{ paper: classes.dialogPaper }}
                                            open={this.state.confirmationAlert}>
                                            <DialogContent className={classes.dialogPaper}>
                                                <ConfirmationModal
                                                    closeModal={this.closeConfirmation}
                                                    title={this.state.confirmTitle}
                                                />
                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="md"
                                            open={this.state.editMode}
                                        >
                                            <DialogContent>
                                                <EditRequestInitialization
                                                    close={this.closeModal}
                                                    getId={this.state.getId}
                                                    closeModal={this.closeModal}
                                                />
                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                        fullWidth="true"
                                        maxWidth="lg"
                                        className={classes.modal}
                                        classes={{ paper: classes.dialogPaper }}
                                        open={this.state.previewData}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <FlowEFTNModal
                                                closeModalFlow={this.closePreviewData}
                                                subServiceType=""
                                                columns={this.state.columnsOfHistory}
                                                allData={this.state.allExcelData}
                                                title={"Flow History"}
                                                rowLength={this.state.allExcelData.length}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                    </div>
                                </React.Fragment>
                                {this.renderNotification()}
                            </div>
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }
}
export default withStyles(styles)(OutgoingTrackerManage);
