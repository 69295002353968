import React, {Component} from "react";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../JsonForm/CustomeTheme2";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import Notification from "../NotificationMessage/Notification";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {backEndServerURL} from "../../Common/Constant";
import MyValidation from "../JsonForm/MyValidation";
import axios from "axios";
import Functions from "../../Common/Functions";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";

let Form = [
    {
        "varName": "insurancType",
        "type": "select",
        "label": "Insurance Type",
        "enum":[
    
            "A – Sea Insurance",
            "B – Goods, Merchandise Personal Effects, Crops and Other Property Loss/Damage",
            "C – Accident & Sickness Insurance",
            "D – Against liability to pay compensation",

        ],
        "required": true,
        "grid": 4
    },
    // {
    //     "varName": "insurancType",
    //     "type": "select",
    //     "label": "Insurance Type",
    //     "enum":[
    //         "A – Sea Insurance",
    //         "B – Fire and Other",
    //         "C – Accident & Sickness",
    //         "D – Indemnity against liability",

    //     ],
    //     "required": true,
    //     "grid": 4
    // },
    {
        "varName": "code",
        "type": "text",
        "label": "Code",
        "required": true,
        "grid": 4
    },
    // {
    //      "type": "empty",
    //      "grid": 4
    // },
    {
        "varName": "amountCalculationType",
        "type": "select",
        "enum":[
            "Incremental",
            "Slab Based",
        ],
        "label": "Amount Calculation",
        "required": true,
        "grid": 4
    },
    // {
    //     "type": "empty",
    //      "grid": 12
    // },
    {
        "varName": "baseValueStart",
        "type": "text",
        "label": "Base Value Start",
        "validation":"numeric",
        "required": true,
        "conditional": true,
        "conditionalVarName": "amountCalculationType",
        "conditionalVarValue": "Incremental",
        "grid": 2
    },
   {
        "varName": "baseValueEnd",
        "type": "text",
        "label": "Base Value End",
        "validation":"numeric",
       "conditional": true,
       "conditionalVarName": "amountCalculationType",
       "conditionalVarValue": "Incremental",
        "required": true,
        "grid": 2
    },
   {
        "varName": "stampValue",
        "type": "text",
        "label": "Stamp Value",
        "validation":"numeric",
       "conditional": true,
       "conditionalVarName": "amountCalculationType",
       "conditionalVarValue": "Incremental",
        "required": true,
        "grid": 2
    },
    {
        "varName": "incrementalAmount",
        "type": "text",
        "label": "Incremental Amount",
        "validation":"numeric",
        "conditional": true,
        "conditionalVarName": "amountCalculationType",
        "conditionalVarValue": "Incremental",
        "required": true,
        "grid": 2
    },
   {
        "varName": "incrementalAmountValue",
        "type": "text",
        "label": "Inc. Amount Value",
        "validation":"numeric",
       "conditional": true,
       "conditionalVarName": "amountCalculationType",
       "conditionalVarValue": "Incremental",
        "required": true,
        "grid": 2
    },


    {
        "varName": "baseValueStart",
        "type": "text",
        "label": "Base Value Start",
        "validation":"numeric",
        "required": true,
        "conditional": true,
        "conditionalVarName": "amountCalculationType",
        "conditionalVarValue": "Slab Based",
        "grid": 2
    },
   {
        "varName": "baseValueEnd",
        "type": "text",
        "label": "Base Value End",
        "validation":"numeric",
       "conditional": true,
       "conditionalVarName": "amountCalculationType",
       "conditionalVarValue": "Slab Based",
        "required": true,
        "grid": 2
    },
   {
        "varName": "stampValue",
        "type": "text",
        "label": "Stamp Value",
        "validation":"numeric",
       "conditional": true,
       "conditionalVarName": "amountCalculationType",
       "conditionalVarValue": "Slab Based",
        "required": true,
        "grid": 2
    },

    {
        "varName": "baseValue",
        "type": "text",
        "label": "> Base Value",
        "validation":"numeric",
       "conditional": true,
       "conditionalVarName": "amountCalculationType",
       "conditionalVarValue": "Slab Based",
        "required": true,
        "grid": 2
    },


];
let workplace = [
    {title: "BRANCH"},
    {title: "OPERATIONS"},
    {title: "CSU"},
];
let groupList = [];
let workplaceTypeList = [];


class InsuranceMasterDataValueCalculation extends Component {
    state = {
        inputData: {},
        selectedDate: {},
        getData: false,
        workstation: false,
        role: false,
        showValue: false,
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},

        err: false,
        varValue: [],
        errorArray: {},
        errorMessages: {},
        errorWorkPlace: false,
        errorgroup: false,
        title: "",
        notificationMessage: "",
        alert: false,
        getGroupListData: false,
        getworkplaceTypeListData: false,
        workplace: "",
        type: "success",
        submitButton: false,
    };
    emptyValueRemove = (data) => {
        if (data === '' || data === ' ' || data === "null" || data === "undefined")
            data = "";
        return data;
    };
    handleSubmit = (event) => {

        event.preventDefault();
        this.setState({
            loading: true
        });
        console.log(this.state.inputData);


        // let workplaceTypeArray = [{
        //     varName:"workplaceType",
        //     type:"text",
        //     required:true
        // }]


         let error = MyValidation.defaultValidation(this.emptyValueRemove(Form), this.state);

        this.forceUpdate();

        if (error === true || this.state.inputData.workplaceType === null || this.state.inputData.groupName === null) {
            console.log("Not working");
            this.setState({
                loading: false
            });
            return 0;
        }
        let url = backEndServerURL + "/add/masterDataValueCalculation";
        let data = this.state.inputData;


            this.setState({
                loading:true,
            })

            axios.post(url, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.props.showTableData(backEndServerURL + '/get/insuranceValueCalculationMasterDataList', 0)
                    if (response.data.status !== 'UNSUCESSFULL') {
                        this.setState({
                            type: "success",
                            title: "Successfull!",
                            notificationMessage: "Successfully Added!",
                            alert: true,
                            loading:false,
                        });
                        this.closeIcon()
                        this.props.closeModal();
                        // setTimeout(function () {
                        //   window.location.reload();
                        // }, 10000);
                    } else {
                        this.setState({
                            type: "warning",
                            title: "warning!",
                            notificationMessage: " already exists!",
                            alert: true,
                            loading:false,
                        })
                       ; this.closeIcon()
                    }

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        type: "error",
                        title: "error!!",
                        notificationMessage: Functions.errorObjectCheck(error),
                        alert: true,
                        loading:false,
                    })
                })


    };
    renderButton = () => {
// if(this.state.submitButton){
        return (
 

<button
className="btn btn-outline-primary  btn-sm"
style={{
    marginTop: "18px"
}}
onClick={this.handleSubmit}

>                Create
</button>


        )
        // }

    };

    componentDidMount() {
        this.setState({
            submitButton: false,
        });



    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderJsonForm = () => {
        return (
            CommonJsonFormComponent.renderJsonForm(this.state, Form, this.updateComponent)
        )

    };



    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.type} stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    closeIcon = () => {
        if (this.props.appId !== undefined) {
            this.props.closeModal();
        } else {
            this.props.closeModal();
        }

    };
    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h6  style={{color: "white"}}>Create  Master Data (Value Calculation)<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: 'white'
                }}/></a></h6>
            )
        } else {
            return (
                <h6  style={{color: "white"}}>Create  Master Data (Value Calculation)<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: 'white'
                }}/></a></h6>
            )
        }
    };


    render() {
        return (

            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            color: '#ffffff'
                        }}>
                    {this.renderHeader()}

                </CardHeader>
                <CardBody>
                    <div>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            open={this.state.loading}>
                            <DialogContent>

                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>

                        <ThemeProvider theme={theme}>

                            <Grid container>

                                {this.renderNotification()}


                                {this.renderJsonForm()}


                                <Grid item xs={12}>
                                    {this.renderButton()}
                                </Grid>

                            </Grid>

                        </ThemeProvider>


                    </div>


                </CardBody>
            </Card>


        )
    }

}

export default InsuranceMasterDataValueCalculation;