import React, {Component} from 'react'
import 'antd/dist/antd.css';
import {Link} from 'react-router-dom';
import MenuSelected from '../../Common/MenuSelected';


import {Layout, Menu} from 'antd';


const {
    Sider,
} = Layout;


class Sidebar extends Component {
    render() {
        MenuSelected.menuId = 1;
        return (

            <Sider width={200} style={{background: '#fff'}}>
                <Menu

                    mode="inline"
                    defaultSelectedKeys={['1']}
                    defaultOpenKeys={['sub1']}
                    style={{height: '100%'}}
                >


                    <h6>`</h6>

                    <Menu.Item key="1"><Link to="/menu/ParentInbox">Inbox()</Link></Menu.Item>
                    <Menu.Item key="2"><Link to="/menu/TableContent">Draft()</Link></Menu.Item>
                    <Menu.Item key="3">Unassigned()</Menu.Item>
                    <Menu.Item key="4">Search </Menu.Item>
                    <Menu.Item> </Menu.Item>
                    <Menu.Item> </Menu.Item>
                    <Menu.Item> </Menu.Item>
                    <Menu.Item> </Menu.Item>


                </Menu>
            </Sider>


        )
    }
}

export default Sidebar;