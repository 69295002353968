import React from "react";
import FormSample from '../JsonForm/FormSample';
import CardHeader from "../Card/CardHeader";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../Common/Constant";
import Notification from "../NotificationMessage/Notification";
import axios from 'axios';
import Functions from '../../Common/Functions';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
};


const jsonForm = {
    "variables": [

        {
            "varName": "productCode",
            "type": "text",
            "label": "Product Code",
            "required": true,

        },
        {
            "varName": "productName",
            "type": "text",
            "label": "Product Name",
            "required": true,

        },
        {
            "varName": "description",
            "type": "text",
            "label": "Description",
            "required": true,

        },
        {
            "varName": "productType",
            "type": "select",
            "label": "Product Type",
            "required": false,
            "select": true,
            "enum": [
                "PRODUCT",
                "SERVICE"
            ]


        },
        {
            "varName": "effectiveFrom",
            "type": "date",
            "label": "Effective From",
            "required": true,

        },
        {
            "varName": "validTo",
            "type": "date",
            "label": "Valid To",
            "required": true,

        },

        {
            "varName": "status",
            "type": "select",
            "label": "status",
            "required": false,
            "select": true,
            "enum": [
                "ACTIVE",
                "INACTIVE"
            ]


        },

    ],

};

class ProductAddEditDelete extends React.Component {
    renderEditForm = (() => {
        if (this.state.getProductData) {
            return (

                <FormSample secondButtonFunction={this.props.secondButtonFunction}
                            secondButtonName={this.props.secondButtonName} showValue={true}
                            varValue={this.state.varValue} grid="6"
                            buttonName="Submit"
                            onSubmit={this.getSubmitedForm} jsonForm={jsonForm}/>


            )
        } else if (this.props.id === undefined) {
            return (


                <FormSample secondButtonFunction={this.props.secondButtonFunction}
                            secondButtonName={this.props.secondButtonName} grid="6" buttonName="Submit"
                            onSubmit={this.getSubmitedForm}
                            jsonForm={jsonForm}/>


            )
        } else {

        }
    });

    constructor(props) {
        super(props);
        this.state = {
            varValue: [],
            getData: [],
            getProductData: false,
            title: "",
            notificationMessage: "",

            alert: false,
            redirectLogin: false,


        }
    }

    firstLetterUpperCase = (string) => {
        var str = string.trim();
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }

    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    getSubmitedForm = (object) => {
        if (this.props.id !== undefined) {
            let url = backEndServerURL + "/productMaster/update/" + this.props.id;
            axios.post(url, {
                "productCode": object.productCode,
                "productName": this.firstLetterUpperCase(object.productName),
                "description": object.description,
                "productType": object.productType,
                "effectiveFrom": object.effectiveFrom,
                "validTo": object.validTo,
                "status": object.status,
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    this.props.close();
                    this.setState({
                        title: "Sucessfully!!",
                        notificationMessage: "Sucessfully Updated",
                        alert: true
                    })


                })
                .catch((error) => {
                    console.log(object);
                    console.log(error);
                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }

                })


        } else {
            let url = backEndServerURL + "/productMaster/add";

            axios.post(url, {
                "productCode": object.productCode,
                "productName": this.firstLetterUpperCase(object.productName),
                "description": object.description,
                "productType": object.productType,
                "effectiveFrom": object.effectiveFrom,
                "validTo": object.validTo,
                "status": object.status,
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.props.close();
                    this.setState({
                        title: "Sucessfully!!",
                        notificationMessage: "Sucessfully Added",
                        alert: true
                    })

                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }

                })
        }
    };

    componentDidMount() {
        if (this.props.id !== undefined) {
            let url = backEndServerURL + "/productMaster/get/" + this.props.id;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let varValue = [];
                    varValue["productCode"] = response.data.productCode;
                    varValue["productName"] = response.data.productName;
                    varValue["description"] = response.data.description;
                    varValue["productType"] = response.data.productType;
                    varValue["effectiveFrom"] = response.data.effectiveFrom;
                    varValue["validTo"] = response.data.validTo;
                    varValue["status"] = response.data.status;
                    this.setState({
                        varValue: varValue,
                        getProductData: true

                    })
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }

                })
        }

    }

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <GridContainer>
                {this.renderNotification()}
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>{this.props.name}</h4>
                        </CardHeader>
                        <CardBody>
                            <div>
                                {this.renderEditForm()}

                            </div>

                        </CardBody>
                    </Card>
                </GridItem>


            </GridContainer>

        )
    }
}

export default withStyles(styles)(ProductAddEditDelete);
