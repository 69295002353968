import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import GridItem from "../../Grid/GridItem.jsx";
import GridContainer from "../../Grid/GridContainer.jsx";
import {backEndServerURL} from "../../../Common/Constant";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import DialogContent from "@material-ui/core/DialogContent";
import CBNotFound from "../CASA/CBNotFound";
import {Dialog} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

class MultipleSignatureCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            accountNumber: this.props.id !== undefined ? this.props.id : 0,
            multipleSignatureValue: [],
            showSignature: false,
            cbNotFound: false,
            title: "",
        }
    }


    componentDidMount() {
        if (this.state.accountNumber) {

            let type = "Finacle";
            if (this.props.signatureSource === "Ababil") {
                type = "Ababil";
            }
            let start_url = backEndServerURL + "/intgr/signatureMultiple/" + type + "/" + this.state.accountNumber + "/" + `${sessionStorage.getItem("accessToken")}`;
            axios
                .get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    if (response.status === 200) {
                        this.setState({
                            multipleSignatureValue: response.data,
                            loader: response.data.length > 0,
                            showSignature: true
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        loader: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    })
                });
        } else {
            this.setState({
                loader: false,
                cbNotFound: true,
                title: "Account Number Not Found!"
            })
        }
    }

    renderImage = () => {
        if (this.state.showSignature && this.state.multipleSignatureValue.length > 0) {
            return this.state.multipleSignatureValue.map((item) => {
                let type = "Finacle";
                if (this.props.signatureSource === "Ababil") {
                    type = "Ababil";
                }
                let url = backEndServerURL + '/intgr/signaturePart/' + type + "/" + +item.id + "/" + `${sessionStorage.getItem("accessToken")}`;
                return (
                    <div style={{width: "100%", textAlign: "center"}}>
                        <div>
                            <img onLoad={this.stopLoading} style={{
                                width: '80%',
                                height: '80%',
                                border: "2px solid #000000",
                                padding: "5px"
                            }} src={url}/>
                        </div>
                        <p
                            style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginTop: "25px",
                                marginBottom: "25px"
                            }}>Remarks
                            :- {item.remarks}
                        </p>
                    </div>
                )
            })
        }
    };

    stopLoading = () => {
        this.setState({
            loader: false
        })
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        });
        this.props.closeModal()
    };

    renderHeader = () => {
        if (this.props.closeModal !== undefined) {
            return (
                <h4 style={{color: "white"}} >Preview Signature<a><CloseIcon onClick={this.props.closeModal} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >Preview Signature</h4>
            )
        }
    };


    render() {
        const {classes} = this.props;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            {this.renderHeader()}
                        </CardHeader>
                        <CardBody>
                            <div>
                                <Grid container spacing={3}>
                                    <ThemeProvider theme={theme}>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.cbNotFound}>
                                            <DialogContent className={classes.dialogPaper}>
                                                <CBNotFound
                                                    closeModal={this.closeModalCBNotFound}
                                                    title={this.state.title}
                                                />
                                            </DialogContent>
                                        </Dialog>
                                        {this.state.loader && <CircularProgress style={{marginLeft: '50%'}}/>}
                                        {this.renderImage()}
                                    </ThemeProvider>
                                </Grid>
                            </div>

                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );

    }


}

export default withStyles(styles)(MultipleSignatureCard);