import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../Grid/GridItem.jsx";
import GridContainer from "../../Grid/GridContainer.jsx";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CloseIcon from '@material-ui/icons/Close';
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Notification from "../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import DefferalMaping from "./DefferalMaping";
import DefferalMappingImageReadOnly from "./DefferalMappingImageReadOnly";
import loader from "../../../Static/loader.gif";
import DefferalMapingImageEdit from "./DefferalMapingImageEdit";
import MyValidation from "../../JsonForm/MyValidation";
import ErrorModal from "./ErrorModal";
import Table from "../../Table/Table";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";

let commonInformation = [
    {
        "varName": "dueDate",
        "type": "date",
        "required": true,
        "minDate": true,
        "label": "Due Date",
        "grid": 2,
    }
];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};


class DeferralEditModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDate: {},
            inputData: {},
            appId: 0,
            varValue: {},
            showValue: false,
            getData: false,
            fileUploadData: {},
            errorArray: {},
            errorMessages: {},
            selectType: "",
            title: "",
            alert: false,
            loading: true,
            previewImageForDeferal: false,
            message: "",
            tableData: [],
            file: {},
            data: {},
            getImageData: false,
            arraygetImage: [],
            defferalId: "",
            uploadModal: false,
            uploadImageEditAndReadonly: false,
            getMappingAllImage: false,
            notificationMessage: "",
            fileUpload: false,
            errorModalBoolean: false,
            errorTittle: ""
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    updateComponent = () => {
        this.forceUpdate();
    };
    handleChangeEditDeferralModal = () => {
        this.setState({
            deferralEditModal: true,
        })
    };
    createTableData = (previousDueDate, lastDueDate,modifiedDate) => {


        return ([previousDueDate, lastDueDate,modifiedDate
        ])

    };
    renderExpireDatelList = () => {
        if (this.state.getData && this.state.tableData.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Expire Date Log:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Previous Expire Date", "Last Change Date","Extend Date"]}
                            // tableHead={["Defferal Type", "Expire date", "Remarks", "Approved By", "Status", "Action"]}
                            tableData={this.state.tableData}
                            tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                        />

                        <br/>

                    </div>
                </div>


            )
        }
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        return clone;
        /* for (let prop in clone)
             if (clone[prop] === '' || clone[prop] === ' '  || clone[prop] === "null"   ||  clone[prop] === "undefined" )
                 delete clone[prop];
         return clone;*/
    };

    componentDidMount() {
        let tableArray = [];
        let url = backEndServerURL + "/deferral/expireDateChangeLog/" + this.props.deferralId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((data => {
                    tableArray.push(this.createTableData(data.previousDueDate, data.lastDueDate,data.modifiedDate));
                }))
                url = backEndServerURL + "/deferral/infoForClosing/" + this.props.deferralId;
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        //tableArray.push(this.createTableData(upload.id, upload.type, upload.dueDate, upload.remarks, upload.approvedBy, upload.status));
                        let inputData = response.data;
                        let varValue = response.data;
                        let dueDate = inputData.dueDate
                         if (inputData.dueDate !== undefined && inputData.dueDate !== null) {
                            let splitDate = (inputData.dueDate).split('-');
                            inputData.previousDueDate = splitDate[1] + "/" + splitDate[2] + "/" + splitDate[0];
                            varValue.previousDueDate = splitDate[1] + "/" + splitDate[2] + "/" + splitDate[0];
                        }

                        this.setState({
                            tableData: tableArray,
                            inputData: this.emptyValueRemove(inputData),
                            varValue: varValue,
                            showValue: true,
                            getData: true,
                            loading: false
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    });
            })
            .catch((error) => {
                console.log(error);
            })

        url = backEndServerURL + /*"/deferral/files/"*/ "/deferral/files/" + this.props.deferralId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.data.length > 0) {
                    this.setState({
                        previewImageForDeferal: true,
                        getImageData: true,
                        arraygetImage: response.data,
                    })
                } else {
                    this.setState({
                        getImageData: true,
                        arraygetImage: response.data,


                    })
                }

            })
            .catch((error) => {

            })

    }


    updateComponent = () => {
        this.forceUpdate();
    };


    handleSubmit = (event) => {
        event.preventDefault();

        let error = MyValidation.defaultValidation(commonInformation, this.state);
        this.forceUpdate();

        if (error === true) {
            console.log("Not working");
            return 0;
        }
        if (this.state.fileUpload === true) {

        } else {
            this.setState({
                errorModalBoolean: true,
                errorTittle: "File Upload Mandatory !!",
                loading: false
            });

            return 0;
        }
        this.setState({
            loading: true
        });
        let inputData = {};
        inputData = this.state.inputData;
        console.log(inputData);
        /*  let data = {};
          let url = backEndServerURL + "/case/renameFiles";
          axios.post(url, {
              appId: this.props.appId,
              fileNames: {}
          }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
              .then((response) => {
                  data = {...response.data};
                  if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "CS") {
                      let url = backEndServerURL + "/deferral/closeCS/" + this.props.appId;
                      axios.post(url, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                          .then((response) => {
                              console.log(response);
                              this.props.closeModal();
                              this.setState({
                                  notificationMessage: "Deferal Close!!",
                                  alert: true,
                                  loading: false,
                              })
                          })
                          .catch((error) => {
                              console.log(error);
                              this.setState({
                                  loading: false,
                              })
                          })


                  } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "CHECKER") {

                      let url = backEndServerURL + "/deferral/close/" + this.props.appId;
                      axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                          .then((response) => {
                              console.log(response.data);
                              this.close();
                              this.setState({
                                  title: "Successfull!",
                                  notificationMessage: "Successfully Close!",
                                  alert: true,
                                  loading: false,

                              })
                          })
                          .catch((error) => {
                              console.log(error);
                              this.setState({
                                  loading: false,

                              })
                          })
                  }
              })
              .catch((error) => {

              })*/
        let url = backEndServerURL + "/deferral/infoForUpdate/" + this.props.deferralId;
        axios.post(url, {
            "dueDate": inputData.dueDate,
            "previousDueDate": inputData.previousDueDate
        }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response);
                this.props.closeModal();
                this.setState({
                    notificationMessage: "Sucessfully Deferal Updated!!",
                    alert: true,
                    loading: false,
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    errorModalBoolean: true,
                    errorTittle: "Please Select The Date !!",
                    loading: false

                })

                return 0;
            })


        /* let formData = new FormData();
         formData.append("file", this.state.file[deferralId]);
         formData.append("deferralId", deferralId);

         axios({
             method: 'post',
             url: backEndServerURL + "/deferral/upload/",
             data: formData,
             headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` ,'content-type': 'multipart/form-data'}
         })
             .then((res) => {
                 let url=backEndServerURL+"/deferral/closable";
                 axios.get(url,{headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
                     .then((response)=>{
                         console.log(response.data);
                     })
                     .catch((error)=>{
                         console.log(error);
                     })
                 console.log(res)
                 this.setState({
                     notificationMessage: "Deferal Uploaded!!",
                     alert: true
                 })
             })
             .catch((err) => {
                 console.log(err)
             })

 */
    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    close = () => {
        this.props.closeModal()
    };
    closeIconForHeader = () => {
        this.props.closeIcon()
    };
    renderForm = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, commonInformation, this.updateComponent)
            )
        }
    };

    closeUploadModal = (data) => {

        console.log(data);
        this.setState({
            uploadModal: false,
            getMappingAllImage: false,
            data: data
        })
    };
    renderUploadButton = () => {

        if ((LocalstorageEncrypt.encryptStorageFunction.getItem("roles") !== "CHECKER")) {
            return (
                <Grid item xs={1}>
                    <button

                        className="btn btn-outline-primary btn-sm"

                        onClick={this.uploadModal}

                    >
                        Upload File
                    </button>
                </Grid>
            )
        }
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    handleChangeAssignedImage = (event) => {
        event.preventDefault();
        this.setState({
            uploadImageEditAndReadonly: true,
        })
    };
    renderAssignedImage = () => {
        if (this.state.getData && this.state.previewImageForDeferal) {
            return (
                <Grid item xs={3}>
                    <button

                        className="btn btn-outline-primary btn-sm"
                        onClick={this.handleChangeAssignedImage}

                    >
                        Preview Deferral Document
                    </button>
                </Grid>
            )
        }
        /*  if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "CHECKER") {
              if (this.state.getImageData) {
                  return this.state.arraygetImage.map((data) => {

                      return (
                          <Grid item xs={4}>

                              <h4 style={{color: "white"}} >{data.type}</h4>
                              <img width='90%' src={backEndServerURL + "/file/" + data.fineName} alt=""/>

                              <br/>
                          </Grid>


                      )

                  })

              }*/


    };
    closeModalForImageEditAndReadonly = (data) => {
        this.setState({
            uploadImageEditAndReadonly: false,
            uploadModal: false,
            data: data,
            fileUpload: true,
            previewImageForDeferal: true,
            loading: false,
        })


    };
    closeIcon = (data) => {
        this.setState({
            uploadImageEditAndReadonly: false,
            uploadModal: false,
            loading: false,
        })


    };
    submitModal = (data) => {
        this.setState({
            uploadImageEditAndReadonly: false,
            uploadModal: false,
            previewImageForDeferal: true,
            getMappingAllImage: false,
            uploadSignatureModal: false,
        })

    };
    closeModal = () => {
        this.setState({
            errorModalBoolean: false
        })
    }

    renderDefferalImageEditAndReadonly = () => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "CS") {
            return (<DefferalMappingImageReadOnly subServiceType={this.props.subServiceType} appId={this.props.appId}
                                                  closeIcon={this.closeIcon}
                                                  deferralId={this.props.deferralId}
                                                  closeModal={this.closeModalForImageEditAndReadonly}/>

            )

        } else {
            return (
                <DefferalMappingImageReadOnly subServiceType={this.props.subServiceType}
                                              deferralId={this.props.deferralId}
                                              closeIcon={this.closeIcon} appId={this.props.appId}
                                              closeModal={this.closeModalForImageEditAndReadonly}/>

            )
        }
    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };
    renderUploadButton = () => {

        if ((LocalstorageEncrypt.encryptStorageFunction.getItem("roles") !== "CHECKER")) {
            return (
                <Grid item xs={1}>
                    <button

                        className="btn btn-outline-primary btn-sm"

                        onClick={this.uploadModal}

                    >
                        Upload File
                    </button>
                </Grid>
            )
        }
    };

    render() {

        const {classes} = this.props;


        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                            <h4 style={{color: "white"}} >Defferal Edit <a><CloseIcon onClick={this.closeIconForHeader} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></h4>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <Grid container spacing={3}>
                                    <ThemeProvider theme={theme}>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.loading}>
                                            <DialogContent className={classes.dialogPaper}>
                                                <center>
                                                    <img src={loader} alt=""/>
                                                </center>
                                            </DialogContent>
                                        </Dialog>
                                        {this.renderNotification()}
                                        <Grid item xs={12}>
                                            {this.renderExpireDatelList()}

                                        </Grid>
                                        {this.renderForm()}
                                        <Grid item xs={10}>

                                        </Grid>
                                        {this.renderUploadButton()}
                                        {this.renderAssignedImage()}
                                    </ThemeProvider>


                                </Grid>
                            </div>

                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.errorModalBoolean}>
                                <DialogContent className={classes.dialogPaper}>
                                    <ErrorModal title={this.state.errorTittle}
                                                closeModal={this.closeModal}
                                                subServiceType={this.props.subServiceType}
                                                appId={this.props.appId}
                                    />

                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="xl"
                                open={this.state.uploadModal}>
                                <DialogContent>
                                    <DefferalMaping deferralId={this.props.deferralId}
                                                    subServiceType={this.props.subServiceType} appId={this.props.appId}
                                                    closeIcon={this.closeIcon}
                                                    closeModal={this.closeModalForImageEditAndReadonly}/>

                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="xl"
                                open={this.state.uploadImageEditAndReadonly}>
                                <DialogContent>
                                    {this.renderDefferalImageEditAndReadonly()}
                                </DialogContent>
                            </Dialog>


                            <br/>
                            <button
                                className="btn btn-outline-primary"
                                style={{
                                    verticalAlign: 'middle',
                                }}
                                onClick={this.handleSubmit}

                            >
                                Submit
                            </button>

                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

        );
    }


}

export default withStyles(styles)(DeferralEditModal);
